import React from 'react'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import Loader from '../../atoms/loader/loader'
import styled from 'styled-components'
import ErrorCardDesktop from '../../molecules/errorCards/errorCardDesktop'
import { device } from '../../templates/displays/devices'
import ErrorCardMobile from '../../molecules/errorCards/errorCardMobile'
import LeftAlignLogo from '../../atoms/icons/components/leftAlignLogo'
import { useAppSelector } from '../../../redux/store/hooks'
import { IRootState } from '../../../redux/store'

const LeftAlignLogoWrapper = styled.div`
    height: 50px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;

    @media ${device.smallest_laptops} {
        height: 48px;
    }

    @media ${device.mobile} {
        height: 40px;
    }
`

const SubWrapper = styled.div`
    padding: 80px;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${device.mobile_and_ipad} {
        padding: 80px 0px;
    }
    @media ${device.mobile} {
        padding: 50px 0px;
    }
`

const FlexOneSubWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
`

interface Props {
    error_is?: string | undefined
    entity: 'car' | 'garage'
    onClick: any
}

const ShareErrorManager: React.FC<Props> = ({ error_is, entity, onClick }) => {
    const userLoggedIn = useAppSelector(
        (state: IRootState) => state.user.userLoggedIn
    )
    const isUserLoading = useAppSelector(
        (state: IRootState) => state.user.loading
    )

    return (
        <React.Fragment>
            {<Loader isLoading={isUserLoading} />}

            {userLoggedIn && (
                <React.Fragment>
                    <DesktopDisplayOnly>
                        <SubWrapper>
                            <LeftAlignLogoWrapper>
                                <LeftAlignLogo />
                            </LeftAlignLogoWrapper>
                            <FlexOneSubWrapper>
                                {error_is === 'invalid_entity_access' && (
                                    <ErrorCardDesktop
                                        title="Invalid access"
                                        text={`You do not have permissions to access details of this page. It looks like the owner changed sharing options of this ${entity} and you can no longer access its details.`}
                                        main_btn_text="Back to your garage"
                                        onClick={onClick}
                                    />
                                )}
                                {error_is === 'entity_does_not_exist' && (
                                    <ErrorCardDesktop
                                        title="Invalid access"
                                        text={`You do not have permissions to access details of this page or the ${entity} was removed by its owner.`}
                                        main_btn_text="Back to your garage"
                                        onClick={onClick}
                                    />
                                )}

                                {error_is === 'invite_expired' && (
                                    <ErrorCardDesktop
                                        title="Share expired"
                                        text="An invite has expired. It looks like the owner changed sharing options of this car and you can no longer access its details."
                                        main_btn_text="Back to your garage"
                                        onClick={onClick}
                                    />
                                )}
                                {!error_is && (
                                    <ErrorCardDesktop
                                        title="Something went wrong"
                                        text="We couldn't access the share."
                                        main_btn_text="Back to your garage"
                                        onClick={onClick}
                                    />
                                )}
                            </FlexOneSubWrapper>
                        </SubWrapper>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <SubWrapper>
                            <LeftAlignLogoWrapper>
                                <LeftAlignLogo />
                            </LeftAlignLogoWrapper>
                            <FlexOneSubWrapper>
                                {error_is === 'invalid_entity_access' && (
                                    <ErrorCardMobile
                                        title="Invalid access"
                                        text={`You do not have permissions to access details of this page. It looks like the owner changed sharing options of this ${entity} and you can no longer access its details.`}
                                        main_btn_text="Back to your garage"
                                        onClick={onClick}
                                    />
                                )}

                                {error_is === 'entity_does_not_exist' && (
                                    <ErrorCardMobile
                                        title="Invalid access"
                                        text={`You do not have permissions to access details of this page or the ${entity} was removed by its owner.`}
                                        main_btn_text="Back to your garage"
                                        onClick={onClick}
                                    />
                                )}
                                {error_is === 'invite_expired' && (
                                    <ErrorCardMobile
                                        title="Share expired"
                                        text="An invite has expired. It looks like the owner changed sharing options of this car and you can no longer access its details."
                                        main_btn_text="Back to your garage"
                                        onClick={onClick}
                                    />
                                )}
                                {!error_is && (
                                    <ErrorCardMobile
                                        title="Something went wrong"
                                        text="We couldn't access the share."
                                        main_btn_text="Back to your garage"
                                        onClick={onClick}
                                    />
                                )}
                            </FlexOneSubWrapper>
                        </SubWrapper>
                    </IpadAndMobileDisplay>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default ShareErrorManager
