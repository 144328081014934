import {
    IDropdownItem,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import React, { useState, useEffect } from 'react'

import TimelineGenerateEditFormModeMobile from '../timelineFormGenerators/timelineGenerateEditFormModeMobile'
import { ITimelineEditForms } from '../../../../redux/editForms/types'
import { ITimelineItem, ICostItem } from '../../../../redux/timeline/types'

interface Props {
    item: ITimelineItem | ICostItem | null
    timelineItemEditForm: ITimelineEditForms
    labels_searchable_list?: IDropdownItem[] | null | undefined
    savingEdit: any
    closeForm: () => void
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    onDeleteClick?: () => void
}

const TimelineEditManagerMobile: React.FC<Props> = ({
    item,
    timelineItemEditForm,
    labels_searchable_list,
    savingEdit,
    closeForm,
    dataCyId,
    entityID,
    carID,
    entityType,
    onDeleteClick,
}) => {
    const [edited_item, setEditedItem] = useState<
        ITimelineItem | ICostItem | null
    >(null)
    const [carMileage, setCarMileage] = useState({
        sendToBe: false,
        payload: null as IwriteTechnicalInformationPayloadReq | null,
    })

    const editNewItem = (
        fieldID: keyof ITimelineItem | keyof ICostItem,
        newValue: any
    ) => {
        let updated_item: ITimelineItem | ICostItem | null =
            edited_item !== null
                ? Object.assign({}, edited_item)
                : Object.assign({}, item)

        if (updated_item !== null) {
            if (entityType === 'cost' && fieldID === 'attachment') {
                if (newValue === null) {
                    updated_item.attachmentID = undefined
                } else {
                    updated_item.attachmentID = newValue.id
                    updated_item.attachment = newValue
                }
            } else {
                updated_item[fieldID] = newValue
            }

            setEditedItem(updated_item)
        }
    }

    useEffect(() => {
        setEditedItem(item)
        return () => {
            setEditedItem(null)
        }
    }, [item])

    const updateCarMileage = (payload: {
        updateCarMileage: boolean
        value: number
        user_distance_unit: string
    }) => {
        let payloadFoBe: IwriteTechnicalInformationPayloadReq = {
            tech_info_id: `hf-${carID}`,
            car_id: carID,
            data: {
                id: 'mileage',
                text: 'mileage',
                answer: payload.value,
            },
        }

        setCarMileage({
            ...carMileage,
            sendToBe: payload.updateCarMileage,
            payload: payloadFoBe,
        })
    }

    return (
        <>
            {item && timelineItemEditForm.id && (
                <TimelineGenerateEditFormModeMobile
                    item={item}
                    timelineItemEditForm={timelineItemEditForm}
                    edited_item={edited_item}
                    onChange={editNewItem}
                    onClickSave={() => {
                        savingEdit(edited_item, carMileage)
                    }}
                    closeForm={closeForm}
                    dataCyId={dataCyId}
                    labels_searchable_list={labels_searchable_list}
                    entityID={entityID}
                    carID={carID}
                    entityType={entityType}
                    onDeleteClick={onDeleteClick}
                    updateCarMileage={updateCarMileage}
                />
            )}

            <div />
        </>
    )
}

export default TimelineEditManagerMobile
