import React from 'react'
import Faded from '../../templates/animated/faded'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import ExpandingItemsListDesktop from '../../molecules/expandingItemsList/expandingItemsListDesktop'
import ExpandingItemsListMobile from '../../molecules/expandingItemsList/expandingItemsListMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'

const Faq: React.FC = () => {
    const dispatch = useAppDispatch()
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const faqCopy = useAppSelector(
        (state) => state.localdata.help_page_copy.faqPageCopy
    )

    React.useEffect(() => {
        dispatch(customNavDataActions.setInitialLandingUrl('/account/faq'))
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const renderFAQsDesktop = faqCopy.faqSectionsIDs.map((item, index) => {
        let list = faqCopy.faqSectionsData[item].questionsIDs.map(
            (questionId: string) => faqCopy.faqQuestionsData[questionId]
        )

        return (
            <React.Fragment key={`faq_${index}`}>
                <SectionHeader
                    title={faqCopy.faqSectionsData[item].name}
                    removeEdit={true}
                    key={faqCopy.faqSectionsData[item].id}
                />

                <ExpandingItemsListDesktop list={list} key={index} />
            </React.Fragment>
        )
    })

    const renderFAQsMobile = faqCopy.faqSectionsIDs.map((item, index) => {
        let list = faqCopy.faqSectionsData[item].questionsIDs.map(
            (questionId: string) => faqCopy.faqQuestionsData[questionId]
        )

        return (
            <React.Fragment key={`faq_${index}`}>
                <SectionHeader
                    customPadding={'20px'}
                    title={faqCopy.faqSectionsData[item].name}
                    removeEdit={true}
                    key={faqCopy.faqSectionsData[item].id}
                />
                <div
                    style={{
                        marginTop: '-20px',
                    }}
                >
                    <ExpandingItemsListMobile list={list} key={index} />
                </div>
            </React.Fragment>
        )
    })

    return (
        <CenteredPageWrapper hasMobileSubNav>
            {userLoggedIn && (
                <Faded>
                    <Loader />
                    <DesktopDisplayOnly>
                        <div
                            style={{
                                paddingLeft: '50px',
                                paddingRight: '50px',
                            }}
                        >
                            {faqCopy && renderFAQsDesktop}
                        </div>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <div
                            style={{
                                paddingLeft: '30px',
                                paddingRight: '30px',
                            }}
                        >
                            {faqCopy && renderFAQsMobile}
                        </div>
                        <div style={{ height: '100px' }} />
                    </IpadAndMobileDisplay>
                </Faded>
            )}
        </CenteredPageWrapper>
    )
}

export default Faq
