import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'
import DescriptionEditorDesktop from '../../editorSections/description/editorDescriptionDesktop'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { IUpdateCarGeneralPayloadReq } from '../../../../redux/entities/cars/types'

type IStyle = {
    $theme: ITheme
}

export const DescriptionHeader = styled.div<IStyle>`
    padding-top: 3px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 16px;
    text-align: left;
    padding-right: 5vw;
    font-family: Lato-Light;

    @media ${device.large_desktop} {
        font-size: 17px;
    }
`

export const Error = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].error};
    font-style: italic;
    font-size: 11px;
`

interface Props {
    close: () => void
    carDescription: string
    writeCarDataGeneralRequest: (payload: IUpdateCarGeneralPayloadReq) => void
    carId: string
}

const CarDescriptionEditFormDesktop = ({
    close,
    carDescription,
    writeCarDataGeneralRequest,
    carId,
}: Props) => {
    const { theme } = useThemes()

    const formik = useFormik({
        initialValues: {
            description: carDescription,
        },
        validationSchema: Yup.object().shape({
            description: Yup.string().max(
                30000,
                'Description must be be less than 30000 characters.'
            ),
        }),
        onSubmit: (values) => {
            if (carDescription !== values.description) {
                const payload: IUpdateCarGeneralPayloadReq = {
                    car_id: carId,
                    dataListForm: [
                        {
                            id: 'description',
                            text: 'description',
                            answer: values.description,
                        },
                    ],
                }
                writeCarDataGeneralRequest(payload)
            }
            close()
        },
    })

    return (
        <ModalContentDesktop
            $theme={theme}
            style={{ overflowY: 'auto', marginTop: '12vh', maxHeight: '85vh' }}
        >
            <ModalInnerContentWrapperDesktop
                style={{ paddingBottom: '24px', paddingTop: '24px' }}
            >
                <ModalTitle style={{ paddingBottom: 8 }} $theme={theme}>
                    Description
                </ModalTitle>

                <Expander
                    height={
                        formik.values.description.length > 1 &&
                        formik.errors.description
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error
                            $theme={theme}
                            className="animated fadeIn slower"
                        >
                            {formik.errors.description}
                        </Error>
                    </Faded>
                </Expander>

                <DescriptionHeader $theme={theme}>
                    This is your space to document your car's history, expand on
                    your custodianship, or jot down ideas for future plans.
                </DescriptionHeader>
            </ModalInnerContentWrapperDesktop>
            <ModalInnerContentWrapperDesktop
                style={{
                    backgroundColor: colours[theme].background_neutral_subtle,
                    padding: '16px 30px',
                }}
            >
                <DescriptionEditorDesktop
                    placeholder={`Let's get started...`}
                    valuePassed={formik.values.description}
                    handleChange={(text: any) =>
                        formik.setFieldValue('description', text)
                    }
                    idPassed="description"
                    readOnly={false}
                />
            </ModalInnerContentWrapperDesktop>
            <ModalInnerContentWrapperDesktop>
                <ButtonsStandardBottomRowDesktop
                    cancel={close}
                    submit={formik.handleSubmit}
                    isDisabled={
                        formik.values.description && formik.errors.description
                            ? true
                            : false
                    }
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

export default CarDescriptionEditFormDesktop
