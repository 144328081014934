import CarOverviewEditFormDesktop from '../editForms/carOverviewEditForm/carOverviewEditFormDesktop'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import ModalDisplayFullPageDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import DragNSortManagerEditable from '../dragNSortManager/dragNSortManagerEditable'
import OwnerContactFormDesktop from '../showroom/contactForm/ownerContactFormDesktop'
import WrapperImageFormsDesktop from '../editForms/imageFormWrapper/wrapperImageFormsDesktop'
import WrapperCarBannerImageFormDesktop from '../editForms/imageFormWrapper/wrapperCarBannerImageFormDesktop'
import CarLocationFormDataManagerDesktop from '../../molecules/showroom/locationForm/carLocationDataManagerDesktop'
import ShowroomTechInfoFormDesktop from '../../molecules/showroom/techInfoForm/showroomTechInfoFormDesktop'
import { ICar } from '../../../redux/entities/cars/types'

type Props = {
    current: ICar
    entryid?: string
    showroom_entry_undisclosed_mileage?: boolean
    localFormOpened?: 'contact' | 'showroom_car_location' | 'tech_info' | null
    setActiveLocalForm?: (arg: any) => any
}
const CarOverviewFormsBodyDesktop = (props: Props) => {
    let { current } = props

    let dispatch = useAppDispatch()

    let isCarOverviewFormEditOpened: boolean = useAppSelector((state) => {
        return state.editForms.formsData &&
            state.editForms.formsData.overviewCarEditForm.isOpen
            ? state.editForms.formsData.overviewCarEditForm.isOpen
            : false
    })

    let isCargalleryFormEditOpened: boolean = useAppSelector((state) => {
        return state.editForms.formsData &&
            state.editForms.formsData.carGalleryEditForm.isOpen
            ? state.editForms.formsData.carGalleryEditForm.isOpen
            : false
    })

    let iscarHighlightsFormEditOpened: boolean = useAppSelector((state) => {
        return state.editForms.formsData &&
            state.editForms.formsData.carHighlightsEditForm
            ? state.editForms.formsData.carHighlightsEditForm.isOpen
            : false
    })

    let isCarBannerImageFormOpen: boolean = useAppSelector((state) => {
        return state.editForms.formsData &&
            state.editForms.formsData.carBannerImageForm
            ? state.editForms.formsData.carBannerImageForm.isOpen
            : false
    })

    let closeForm = () => {
        if (isCarOverviewFormEditOpened) {
            return dispatch(editFormsActions.toggleCarOverviewEditForm())
        }
        if (iscarHighlightsFormEditOpened) {
            return dispatch(editFormsActions.toggleCarHighlightsEditForm())
        }

        if (
            (props.localFormOpened && props.localFormOpened === 'contact') ||
            props.localFormOpened === 'showroom_car_location'
        ) {
            props.setActiveLocalForm && props.setActiveLocalForm(null)
        }

        if (isCargalleryFormEditOpened) {
            return dispatch(editFormsActions.toggleCarGalleryEditForm())
        }

        if (isCarBannerImageFormOpen) {
            return dispatch(editFormsActions.toggleCarBannerImageForm())
        }
    }

    let renderForm = () => {
        if (isCarOverviewFormEditOpened) {
            return (
                <CarOverviewEditFormDesktop
                    carData={current}
                    close={() => {
                        dispatch(editFormsActions.toggleCarOverviewEditForm())
                    }}
                    dispatch={dispatch}
                />
            )
        } else if (iscarHighlightsFormEditOpened) {
            return (
                <DragNSortManagerEditable
                    close={() => {
                        dispatch(editFormsActions.toggleCarHighlightsEditForm())
                    }}
                    highlighted_facts_ids={
                        current && current.highlighted_facts
                            ? current.highlighted_facts
                            : []
                    }
                    car_id={current.id}
                />
            )
        } else if (
            props.localFormOpened &&
            props.localFormOpened === 'contact'
        ) {
            return (
                <OwnerContactFormDesktop
                    close={() =>
                        props.setActiveLocalForm &&
                        props.setActiveLocalForm(null)
                    }
                />
            )
        } else if (
            props.localFormOpened &&
            props.localFormOpened === 'showroom_car_location' &&
            props.entryid
        ) {
            return (
                <CarLocationFormDataManagerDesktop
                    close={() =>
                        props.setActiveLocalForm &&
                        props.setActiveLocalForm(null)
                    }
                    current={props.current}
                    entryid={props.entryid}
                    isMobile={false}
                />
            )
        } else if (isCargalleryFormEditOpened) {
            return (
                <WrapperImageFormsDesktop
                    close={() => {
                        dispatch(editFormsActions.toggleCarGalleryEditForm())
                    }}
                    carid={current.id}
                />
            )
        } else if (isCarBannerImageFormOpen) {
            return (
                <WrapperCarBannerImageFormDesktop
                    close={() => {
                        dispatch(editFormsActions.toggleCarBannerImageForm())
                    }}
                    carid={current.id}
                />
            )
        } else if (
            props.localFormOpened &&
            props.localFormOpened === 'tech_info'
        ) {
            return (
                <ShowroomTechInfoFormDesktop
                    car={current}
                    close={() =>
                        props.setActiveLocalForm &&
                        props.setActiveLocalForm(null)
                    }
                    entryid={props.entryid}
                    showroom_entry_undisclosed_mileage={
                        props.showroom_entry_undisclosed_mileage
                    }
                />
            )
        } else return null
    }

    let isAFormOpened =
        props.localFormOpened === 'contact' ||
        props.localFormOpened === 'showroom_car_location' ||
        props.localFormOpened === 'tech_info' ||
        isCarOverviewFormEditOpened ||
        iscarHighlightsFormEditOpened ||
        isCargalleryFormEditOpened ||
        isCarBannerImageFormOpen
            ? true
            : false

    return (
        <ModalDisplayFullPageDesktopExclusive
            toggle={() => {
                closeForm()
            }}
            isOpen={isAFormOpened}
        >
            {renderForm()}
        </ModalDisplayFullPageDesktopExclusive>
    )
}

export default CarOverviewFormsBodyDesktop
