/// <reference types="googlemaps" />
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import AddressFormDesktop from './addressFormDesktop'
import AddressFormMobile from './addressFormMobile'
import { IUserAddressApi } from '../../../redux/services/typedefinitions/apiPayloads'

type Props = {
    addAddress: any
    isDesktop: boolean
}

const AddressForm: React.FC<Props> = ({ addAddress, isDesktop }) => {
    const [state, setState] = useState({
        street_number: '',
        route: '',
        postal_town: '',
        postal_code: '',
        country: '',
    })

    const dispatch = useDispatch()

    useEffect(() => {
        const input: any = document.getElementById(
            isDesktop ? 'autocompletedesktop' : 'autocompletemobile'
        )

        const googleMaps = async () => {
            try {
                const autocomplete = new google.maps.places.Autocomplete(
                    input,
                    {
                        types: ['geocode'],
                    }
                )

                autocomplete.setFields(['address_component'])

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }

                        const circle = new google.maps.Circle({
                            center: geolocation,
                            radius: position.coords.accuracy,
                        })
                        autocomplete.setBounds(circle.getBounds())
                    })
                }
                autocomplete.addListener('place_changed', () => {
                    const place: any = autocomplete.getPlace()

                    place.address_components !== undefined &&
                        place.address_components.map((chunk: any) => {
                            if (chunk.types[0] === 'street_number') {
                                setState((prevState) => ({
                                    ...prevState,
                                    street_number: chunk.long_name,
                                }))
                            } else if (chunk.types[0] === 'route') {
                                setState((prevState) => ({
                                    ...prevState,
                                    route: chunk.long_name,
                                }))
                            } else if (chunk.types[0] === 'postal_code') {
                                setState((prevState) => ({
                                    ...prevState,
                                    postal_code: chunk.long_name,
                                }))
                            } else if (chunk.types[0] === 'locality') {
                                setState((prevState) => ({
                                    ...prevState,
                                    postal_town: chunk.long_name,
                                }))
                            } else if (chunk.types[0] === 'postal_town') {
                                setState((prevState) => ({
                                    ...prevState,
                                    postal_town: chunk.long_name,
                                }))
                            } else if (chunk.types[0] === 'country') {
                                setState((prevState) => ({
                                    ...prevState,
                                    country: chunk.long_name,
                                }))
                            }
                            return false
                        })
                })
            } catch (e) {
                console.error(e)
            }
        }

        googleMaps()
    }, [isDesktop])

    const handleChange: React.ReactEventHandler<HTMLInputElement> = (ev) => {
        let value = ev.currentTarget.value
        const name = ev.currentTarget.name

        if (name === 'postal_code') {
            value = value?.toUpperCase()
        }
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const onSubmit = () => {
        const addressObj: IUserAddressApi = {
            type: 'User Address',
            street_1: `${state.street_number} ${state.route}`,
            locality: state.postal_town,
            postcode: state.postal_code,
            country: state.country,
        }

        dispatch(addAddress(addressObj))
    }

    const isFormValid = () => {
        if (state.street_number.length === 0 && state.route.length === 0) {
            return false
        } else if (
            state.country.length === 0 &&
            state.postal_code.length === 0 &&
            state.postal_town.length === 0
        ) {
            return false
        } else return true
    }

    return (
        <React.Fragment>
            {isDesktop ? (
                <AddressFormDesktop
                    street_number={state.street_number}
                    postal_town={state.postal_town}
                    route={state.route}
                    postal_code={state.postal_code}
                    country={state.country}
                    onSubmit={onSubmit}
                    handleChange={handleChange}
                    isFormValid={isFormValid}
                />
            ) : (
                <AddressFormMobile
                    street_number={state.street_number}
                    postal_town={state.postal_town}
                    route={state.route}
                    postal_code={state.postal_code}
                    country={state.country}
                    onSubmit={onSubmit}
                    handleChange={handleChange}
                    isFormValid={isFormValid}
                />
            )}
        </React.Fragment>
    )
}

export default AddressForm
