import { IRegisterPayloadReq } from '../../../redux/user/sagas/authSagas'
import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import {
    FormContainerNoFormik,
    CheckboxSection,
    BottomSection,
    LinkedText,
    BlueSpan,
    AlreadyRegistered,
} from '../../templates/styledcomponents/landingPagesFormStyles'
import LandingPageMainButton from '../../atoms/Button/landingPageMainButton'
import StyledFormError from '../../templates/styledcomponents/styledformerrormessage'
import Expander from '../../atoms/expander/expander'
import Faded from '../../templates/animated/faded'
import CustomAnimatedCheckboxField from '../../atoms/formField/customAnimatedCheckboxField'
import { RegisterSchema } from './RegisterSchema'
import Turnstile from 'react-turnstile'
import { checkTurnstileToken } from '../../../redux/services/authentication/turnstile'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import InputFieldNoFormikV2 from '../../atoms/Inputfield/inputFieldNoFormikV2'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import AppleSignInButton from '../../atoms/Button/socialAuth/apple/appleSigninButton'
import { GoogleAuthButton } from '../../atoms/Button/socialAuth/google'
import { usersActions } from '../../../redux/user/reducer'

interface Props {
    invite_code?: any
}

const Padding = styled.div`
    padding-bottom: 12px;
    @media ${device.large_desktop} {
        padding-bottom: 16px;
    }
`

const MobileAndIpadPadding = styled.div`
    padding-bottom: 40px;
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

export const CheckboxRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`
export const CheckboxText = styled.div`
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
    color: var(--text-default, #666);
    padding-left: 2px;
    font-size: 14px;
`

const FlexRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    gap: 16px;
`

const RegisterFormLandingPage = (props: Props) => {
    let location = useLocation()
    let params = new URLSearchParams(location.search)
    let first_q_name_param = params.get('first_name')
    let last_name_q__param = params.get('last_name')
    let email_q_param = params.get('email')

    const [firstName, setFirstName] = useState<string | undefined>(
        first_q_name_param ?? undefined
    )

    const [lastName, setLastName] = useState<string | undefined>(
        last_name_q__param ?? undefined
    )
    const [email, setEmail] = useState<string | undefined>(
        email_q_param ?? undefined
    )

    const [password, setPassword] = useState<string | undefined>(undefined)
    const [passwordConfirm, setPasswordConfirm] = useState<string | undefined>(
        undefined
    )
    const [tokenValid, setTokenValid] = useState<boolean>(false)
    const [platformConsent, setPlatformConsent] = useState<boolean>(false)
    const [apexConsent, setApexConsent] = useState<boolean>(false)

    const [errors, setErrors] = useState<any>({})

    const inputerror = useAppSelector((state) => state.user.error)

    const dispatch = useAppDispatch()

    const validateTokenFromBE = async (token: string) => {
        try {
            await checkTurnstileToken(token)
            setTokenValid(true)
        } catch (error: any) {
            setTokenValid(false)
        }
    }

    const validateForm = () => {
        let pa = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            passwordConfirm: passwordConfirm,
            platformConsent: platformConsent,
            apexConsent: apexConsent,
        }

        let errorsLoc: boolean = false

        let p1 = `${password}`
        let p2 = `${passwordConfirm}`
        let isMatching = p1.localeCompare(p2)

        if (isMatching !== 0) {
            errorsLoc = true
            setErrors({
                ...errors,
                passwordConfirm: `Your password confirmation doesn't match your password.`,
            })
        }

        RegisterSchema.validate(pa, { abortEarly: false })
            .then((valid) => {
                if (errorsLoc === false) {
                    handleSubmit()
                }
            })
            .catch(function (errorsss) {
                errorsss?.inner?.forEach((error: any) => {
                    setErrors((prevState: any) => {
                        return {
                            ...prevState,
                            [error.path]: error?.errors[0],
                        }
                    })
                })
            })
    }

    const handleSubmit = async () => {
        let email_val = email?.toLowerCase()

        let isValidToken =
            tokenValid !== true && env === 'local' ? true : tokenValid

        if (email_val && firstName && lastName && password && isValidToken) {
            const params = new URLSearchParams(location.search)
            const fbclid: string | null = params.get('fbclid')
            const next_path = params.get('next')

            let p: IRegisterPayloadReq = {
                firstName: firstName,
                lastName: lastName,
                email: email_val,
                password: password,
                platformConsent: platformConsent,
                apexConsent: apexConsent,
                fbclid: fbclid,
                next_path: next_path,
            }
            dispatch(usersActions.registerRequest(p))
        }
    }

    const env: any = !process.env.REACT_APP_ENV
        ? 'local'
        : process.env.REACT_APP_ENV

    return (
        <FormContainerNoFormik>
            <FlexRow>
                <div style={{ width: '100%' }}>
                    <InputFieldNoFormikV2
                        id="firstName"
                        name="firstName"
                        fieldLabel="first name"
                        width="100%"
                        tabindex={1}
                        aria-invalid={
                            errors.firstName || inputerror !== null
                                ? 'true'
                                : 'false'
                        }
                        aria-live="polite"
                        dataCyId={
                            'registration-form-userFirstName-input-universal'
                        }
                        value={firstName}
                        onChange={(e: any) => {
                            if (errors.firstName !== undefined) {
                                setErrors((prevState: any) => {
                                    return {
                                        ...prevState,
                                        firstName: undefined,
                                    }
                                })
                            }
                            setFirstName(e.target.value)
                        }}
                    />

                    <Expander height={errors.firstName ? 'auto' : 0}>
                        <Faded>
                            <StyledFormError customTopSpacing={4}>
                                First name is required
                            </StyledFormError>
                        </Faded>
                    </Expander>
                    <Padding />
                </div>
                <div style={{ width: '100%' }}>
                    <InputFieldNoFormikV2
                        id="lastName"
                        name="lastName"
                        // placeholder="last name"
                        fieldLabel="last name"
                        width="100%"
                        tabindex={2}
                        aria-invalid={
                            errors.lastName || inputerror !== null
                                ? 'true'
                                : 'false'
                        }
                        aria-live="polite"
                        dataCyId={
                            'registration-form-userLastName-input-universal'
                        }
                        value={lastName}
                        onChange={(e: any) => {
                            if (errors.lastName !== undefined) {
                                setErrors((prevState: any) => {
                                    return {
                                        ...prevState,
                                        lastName: undefined,
                                    }
                                })
                            }

                            setLastName(e.target.value)
                        }}
                    />

                    <Expander height={errors.lastName ? 'auto' : 0}>
                        <Faded>
                            <StyledFormError customTopSpacing={4}>
                                Last name is required
                            </StyledFormError>
                        </Faded>
                    </Expander>
                    <Padding />
                </div>
            </FlexRow>
            <InputFieldNoFormikV2
                id="email"
                name="email"
                // placeholder="email"
                fieldLabel="email"
                width="100%"
                onChange={(e: any) => {
                    if (errors.email !== undefined) {
                        setErrors((prevState: any) => {
                            return {
                                ...prevState,
                                email: undefined,
                            }
                        })
                    }

                    setEmail(e.target.value)
                    inputerror !== null &&
                        dispatch(usersActions.resetErrorStateSuccess())
                }}
                tabindex={3}
                dataCyId={'registration-form-email-input-universal'}
                aria-invalid={
                    errors.email || inputerror !== null ? 'true' : 'false'
                }
                aria-live="polite"
                value={email}
            />
            <Expander height={errors.email ? 'auto' : 0}>
                <Faded>
                    <StyledFormError customTopSpacing={4}>
                        {errors.email}
                    </StyledFormError>
                </Faded>
            </Expander>
            <Padding />

            <FlexRow>
                <div style={{ width: '100%' }}>
                    <InputFieldNoFormikV2
                        id="password"
                        name="password"
                        // placeholder="password"
                        fieldLabel="password"
                        width="100%"
                        type="password"
                        tabindex={4}
                        aria-live="polite"
                        dataCyId={
                            'registration-form-userPassword-input-universal'
                        }
                        onChange={(e: any) => {
                            setPassword(e.target.value)
                            if (errors.password !== undefined) {
                                setErrors((prevState: any) => {
                                    return {
                                        ...prevState,
                                        password: undefined,
                                    }
                                })
                            }
                            if (errors.passwordConfirm) {
                                if (passwordConfirm === e.target.value) {
                                    setErrors((prevState: any) => {
                                        return {
                                            ...prevState,
                                            passwordConfirm: undefined,
                                        }
                                    })
                                }
                            }

                            inputerror !== null &&
                                dispatch(usersActions.resetErrorStateSuccess())
                        }}
                        value={password}
                    />

                    <Expander height={errors.password ? 'auto' : 0}>
                        <Faded>
                            <StyledFormError customTopSpacing={4}>
                                {errors.password}
                            </StyledFormError>
                        </Faded>
                    </Expander>
                    <Padding />
                </div>
                <div style={{ width: '100%' }}>
                    <InputFieldNoFormikV2
                        id="passwordConfirm"
                        name="passwordConfirm"
                        // placeholder="confirm password"
                        fieldLabel="confirm password"
                        width="100%"
                        type="password"
                        tabindex={5}
                        aria-live="polite"
                        dataCyId={
                            'registration-form-userPasswordConfirmation-input-universal'
                        }
                        onChange={(e: any) => {
                            e.preventDefault()
                            setPasswordConfirm(e.target.value)

                            if (errors.passwordConfirm !== undefined) {
                                if (e.target.value === password) {
                                    setErrors((prevState: any) => {
                                        return {
                                            ...prevState,
                                            passwordConfirm: undefined,
                                        }
                                    })
                                }
                            }
                            inputerror !== null &&
                                dispatch(usersActions.resetErrorStateSuccess())
                        }}
                        value={passwordConfirm}
                    />

                    <Expander
                        height={
                            errors.passwordConfirm !== undefined ? 'auto' : 0
                        }
                        dataCyId={
                            'registration-form-expander-error-passwordMatch-universal'
                        }
                    >
                        <Faded>
                            <StyledFormError customTopSpacing={4}>
                                Passwords must match
                            </StyledFormError>
                        </Faded>
                    </Expander>

                    <Expander
                        height={
                            inputerror !== null &&
                            inputerror.status_code !== 404
                                ? 'auto'
                                : 0
                        }
                        dataCyId={
                            'registration-form-expander-error-invalidEmailPassword-universal'
                        }
                    >
                        <Faded>
                            <StyledFormError customTopSpacing={4}>
                                {inputerror &&
                                inputerror.default &&
                                inputerror.default.text
                                    ? `Registration error: ${inputerror.default.text}.`
                                    : `Invalid email or password, or user may already exist.`}
                            </StyledFormError>
                        </Faded>
                    </Expander>
                    <Padding />
                </div>
            </FlexRow>
            <CheckboxSection>
                <CheckboxRow>
                    <CustomAnimatedCheckboxField
                        name={'platformConsent'}
                        checked={platformConsent}
                        onChange={() => {
                            if (errors.platformConsent) {
                                setErrors((prevState: any) => {
                                    return {
                                        ...prevState,
                                        platformConsent: undefined,
                                    }
                                })
                            }
                            setPlatformConsent(!platformConsent)
                        }}
                    />
                    <CheckboxText>
                        I agree to the{' '}
                        <LinkedText
                            href="https://www.custodian.club/terms-of-service"
                            target="_blank"
                        >
                            Terms of Service
                        </LinkedText>{' '}
                        &{' '}
                        <LinkedText
                            href="https://www.custodian.club/privacy"
                            target="_blank"
                        >
                            Privacy Policy
                        </LinkedText>
                        .
                    </CheckboxText>
                </CheckboxRow>
                <Expander height={errors.platformConsent ? 'auto' : 0}>
                    <Faded>
                        <StyledFormError customTopSpacing={4}>
                            {errors.platformConsent}
                        </StyledFormError>
                    </Faded>
                </Expander>
                <div style={{ paddingTop: 8 }} />
                <CheckboxRow>
                    <CustomAnimatedCheckboxField
                        checked={apexConsent}
                        name={'apexConsent'}
                        onChange={() => setApexConsent(!apexConsent)}
                    />
                    <CheckboxText>
                        Subscribe me to Custodian’s weekly podcast and
                        publication,{' '}
                        <LinkedText
                            href="https://apex.privatecollectorsclub.com/"
                            target="_blank"
                        >
                            The Apex
                        </LinkedText>
                        .
                    </CheckboxText>
                </CheckboxRow>
            </CheckboxSection>
            <div style={{ paddingTop: '24px' }} />
            {env !== 'local' ? (
                <Turnstile
                    sitekey={`${process.env.REACT_APP_TURNSTILE_SITE_KEY}`}
                    onVerify={(token) => validateTokenFromBE(token)}
                />
            ) : (
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        maxWidth: 400,
                        height: 64,
                        backgroundColor: 'var(--text-strong, #1a1a1a)',
                        color: 'var(--bg-color, #fff)',
                    }}
                >
                    Turnstile
                </div>
            )}
            <BottomSection style={{ alignItems: 'flex-start' }}>
                <LandingPageMainButton
                    type="submit"
                    disabled={false}
                    aria-live="polite"
                    dataCyId={'registration-form-submit-universal'}
                    onClick={(e: any) => {
                        e.preventDefault()
                        validateForm()
                    }}
                    custom_bg={'var(--text-strong, #1a1a1a)'}
                    normal_height="48px"
                >
                    <span
                        style={{
                            textTransform: 'none',
                            fontSize: 14,
                            fontFamily: 'Poppins-Semibold',
                            color: 'var(--bg-color, #fff)',
                        }}
                    >
                        Create an Account
                    </span>
                </LandingPageMainButton>

                <div style={{ marginTop: '8px' }} />
                <AppleSignInButton
                    from_landing={true}
                    normal_height={'48px'}
                    next_path={
                        params.get('next') &&
                        typeof params.get('next') === 'string'
                            ? `${params.get('next')}`
                            : undefined
                    }
                    fbclid={
                        params.get('fbclid') &&
                        typeof params.get('fbclid') === 'string'
                            ? `${params.get('fbclid')}`
                            : undefined
                    }
                    isValidToken={
                        tokenValid !== true && env === 'local'
                            ? true
                            : tokenValid
                    }
                    is_registration={true}
                    platform_consent={platformConsent}
                    apex_consent={apexConsent}
                />

                <div style={{ marginTop: '5px' }} />

                <GoogleAuthButton
                    next_path={
                        params.get('next') &&
                        typeof params.get('next') === 'string'
                            ? `${params.get('next')}`
                            : undefined
                    }
                    fbclid={
                        params.get('fbclid') &&
                        typeof params.get('fbclid') === 'string'
                            ? `${params.get('fbclid')}`
                            : undefined
                    }
                    isValidToken={
                        tokenValid !== true && env === 'local'
                            ? true
                            : tokenValid
                    }
                    is_registration={true}
                    platform_consent={platformConsent}
                    apex_consent={apexConsent}
                />

                <AlreadyRegistered style={{ fontSize: 14, color: '#666' }}>
                    Already have an account?&nbsp;&nbsp;&nbsp;
                    <Link to="/signin">
                        <BlueSpan data-attr="signin-link-redirect">
                            Sign in
                        </BlueSpan>
                    </Link>
                </AlreadyRegistered>
            </BottomSection>
            <MobileAndIpadPadding />
        </FormContainerNoFormik>
    )
}

export default RegisterFormLandingPage
