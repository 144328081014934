import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

export interface StyledProps {
    align: 'left' | 'center'
}

const H2StyledDefault = styled.h2<StyledProps>`
    text-align: ${(props) => `${props.align}`};
    text-transform: uppercase;

    width: 100%;
    box-sizing: border-box;

    @media ${device.mobile} {
        font-family: Cinzel;
        color: black;
        font-size: 1rem;
    }

    @media ${device.ipad} {
        font-family: Cinzel;
        color: black;
        font-weight: 800;
        font-size: 1.1rem;
    }

    @media ${device.desktop} {
        font-family: CinzelBold;
        font-size: 1.2rem;
        margin-bottom: 1.2rem;
    }
`

interface ITitleProps {
    children: React.ReactNode
    align?: 'left' | 'center'
    theme: 'default' | 'next_theme'
}

const Title = (props: ITitleProps) => (
    <React.Fragment>
        {props.theme === 'default' && (
            <H2StyledDefault align={props.align ? props.align : 'center'}>
                {props.children}
            </H2StyledDefault>
        )}
    </React.Fragment>
)

export default Title
