import React, { useState, useEffect, useCallback } from 'react'
import AddAttachmentEditOrCreateForm from './addAttachmentEditOrCreateFormMobile'
import { IEditOrCreateModeSingleFieldsProps } from '../../types'
import { IUploadImagesPreSignedUrlsPayload } from '../../../../../redux/filestorage/types'
import { IAttachmentRequestPayload } from '../../../../../redux/attachments/types'

interface Props {
    data: IEditOrCreateModeSingleFieldsProps
    close?: any
    presignedurls?: IUploadImagesPreSignedUrlsPayload
    user_id?: string
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
}

const WrapperAttachmentFormMobile: React.FC<Props> = ({
    data,
    close,
    dataCyId,
    entityID,
    carID,
    entityType,
}) => {
    const [progressPercent, setProgressPercent] = useState<number>(0)
    const [file, setFile] = useState<File | undefined>(undefined)

    const [valueStart, setValueStart] = useState<any>(data.value_start)

    const handlePercent = useCallback(() => {
        setProgressPercent((prev) => prev + 10)
    }, [])

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null
        if (progressPercent < 100) {
            interval = setInterval(handlePercent, 50)
        } else if (interval) {
            clearInterval(interval)
        }
        return () => {
            if (interval) clearInterval(interval)
        }
    }, [progressPercent, handlePercent])

    const deleteImageUploaded = () => {
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                null,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, null)
        }
        setValueStart(undefined)
        setFile(undefined)
        setProgressPercent(0)
    }

    const handleFilesGivenInWrapperComponent = (file: any) => {
        let fieldValue: IAttachmentRequestPayload = {
            filename: file.name ? file.name : 'no_file_name_received',
            type: file.type ? file.type : 'no_file_type_received',
            file: file,
        }

        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                fieldValue,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, fieldValue)
        }

        setFile(file)
    }

    return (
        <div data-attr={dataCyId} style={{ width: '100%' }}>
            <AddAttachmentEditOrCreateForm
                close={close}
                deleteFileUploaded={deleteImageUploaded}
                setFileUploaded={handleFilesGivenInWrapperComponent}
                currentFileUploaded={file}
                progressPercent={progressPercent}
                entityID={entityID}
                carID={carID}
                entityType={entityType}
                initialValue={valueStart}
            />
        </div>
    )
}

export default WrapperAttachmentFormMobile
