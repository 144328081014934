import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertUserApitoUserState } from '../../conversions/user/userConversions'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
import { state_select_user } from './getUserData'
import { message } from 'antd'
import { IUser, IUSerGeneralInfoUpdatePayload } from '../types'
import { usersActions } from '../reducer'
import { IExternalURLApi, IUserPayload } from '../../services/user_data/types'
import { IRootState } from '../../store'
import { IReduxError } from '../../entities/cars/types'

export const state_select_dial_code = (state: IRootState) =>
    state.editForms.user_dial_code

function* updateOrCreateUserExternalURLSaga(dataToSend: IExternalURLApi): any {
    try {
        let newURL: IExternalURLApi = {
            ...dataToSend,
        }

        if (dataToSend.uid === 'new') {
            newURL = yield call(api.external_urls.createExternalURL, dataToSend)
        } else {
            newURL = yield call(api.external_urls.updateExternalURL, dataToSend)
        }

        let udata: IUser | null = yield select(state_select_user)

        if (udata) {
            let currExtUrl =
                udata && udata.external_urls
                    ? { ...udata.external_urls }
                    : {
                          WEBSITE: undefined,
                          FACEBOOK: undefined,
                          LINKEDIN: undefined,
                          INSTAGRAM: undefined,
                          TWITTER: undefined,
                      }
            yield put(
                usersActions.updateUserGeneralInfoSuccess({
                    ...udata,
                    external_urls: {
                        ...currExtUrl,
                        [dataToSend.url_type]: {
                            ...newURL,
                        },
                    },
                })
            )

            message.info({
                content: 'Social Media Details Saved!',
            })
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: updateOrCreateUserExternalURLSaga,
                payload: dataToSend,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(usersActions.updateUserGeneralInfoError(typedError))
        }
    }
}

function* updateUserGeneralInfoSaga(
    dataToSend: IUSerGeneralInfoUpdatePayload
): any {
    try {
        if (dataToSend.external_urls) {
            for (const item of dataToSend.external_urls) {
                yield call(updateOrCreateUserExternalURLSaga, item)
            }
        } else if (dataToSend.data) {
            if (dataToSend.data.phone) {
                let dial_code: string | undefined = yield select(
                    state_select_dial_code
                )
                if (dial_code) {
                    dataToSend.data.phone = dial_code + dataToSend.data.phone
                }
            }

            let new_data = { ...dataToSend }

            if (new_data.data!.bio !== undefined) {
                if (new_data.data!.bio.length < 2) {
                    new_data.data!.bio = ' '
                }
            }

            const existingUserData = yield select(state_select_user)

            const userData: IUserPayload = yield call(
                api.userData.user_data.updateUserProfileInformationApi,
                new_data
            )

            let data: IUser = ConvertUserApitoUserState(userData)

            let userUpdated = { ...existingUserData, ...data }

            yield put(usersActions.updateUserGeneralInfoSuccess(userUpdated))

            // Capture event
            posthog.capture('USER INFORMATION UPDATE')

            // if (dataToSend.toggleUserProfileImageFormOnSuccess === true) {
            //     yield put(
            //         toggleFormActionSuccess.toggleUserProfileImageFormSuccess()
            //     )
            // }

            message.info({
                content: 'Details Saved',
            })
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: updateUserGeneralInfoSaga,
                payload: dataToSend,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(usersActions.updateUserGeneralInfoError(typedError))
        }
    }
}

function* watcherUpdateUserGeneralData() {
    while (true) {
        const { payload } = yield take(
            usersActions.updateUserGeneralInfoRequest
        )

        yield call(updateUserGeneralInfoSaga, payload)
    }
}

const update_user_general: any[] = [fork(watcherUpdateUserGeneralData)]

export default update_user_general
