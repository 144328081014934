import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import styled from 'styled-components'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/sellYourCar/1.png'
import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
    JourneyWelcomeNewLineOnMobile,
} from '../../../templates/styledcomponents/journeyStyles'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

const Title2 = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    gap: 16px;
`

const IconWrap = styled.div`
    height: 64px;
    width: 64px;
`

const Svg3 = () => {
    return (
        <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3498_70696)">
                <circle
                    cx="44.7369"
                    cy="52.5471"
                    r="3.4221"
                    stroke="var(--text-strong, #1A1A1A)"
                    stroke-width="2"
                />
                <circle
                    cx="19.2611"
                    cy="52.5471"
                    r="3.4221"
                    stroke="var(--text-strong, #1A1A1A)"
                    stroke-width="2"
                />
                <path
                    d="M15.1228 52.3312H10.5837C9.01597 52.1559 8.23226 51.2787 8.10014 50.7225C8.05868 50.548 7.89689 49.5665 8.10053 49.1462C8.3461 48.6395 9.07431 48.9083 9.22127 48.815C9.54465 48.6097 9.15845 47.623 9.54465 47.3441C18.0278 41.2178 23.629 39.4604 31.8245 39.1406C33.3836 39.1406 34.4434 40.1545 35.1149 40.6878C36.0468 41.4278 37.229 42.6854 37.8032 43.2061C37.9423 43.3323 38.1223 43.3966 38.3098 43.4084L50.2417 44.1645C50.5318 44.1908 51.6388 44.079 51.9553 45.4082L52.5091 46.8325L54.2894 48.138C54.6587 48.5072 55.2864 49.3644 54.8433 49.8391L53.6694 51.8377C53.4898 52.1434 53.1623 52.3312 52.8077 52.3312C51.9251 52.3312 50.5204 52.3312 49.2651 52.3312M40.4033 52.3312H22.9171"
                    stroke="var(--text-strong, #1A1A1A)"
                    stroke-width="2"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M46.5715 36.0837C46.5715 32.3123 47.0033 29.9947 48.4572 28.5408C49.9111 27.087 52.2287 26.6551 56.0001 26.6551C52.2287 26.6551 49.9111 26.2233 48.4572 24.7694C47.0033 23.3155 46.5715 20.998 46.5715 17.2266C46.5715 20.998 46.1397 23.3155 44.6858 24.7694C43.2319 26.2233 40.9144 26.6551 37.1429 26.6551C40.9144 26.6551 43.2319 27.087 44.6858 28.5408C46.1397 29.9947 46.5715 32.3123 46.5715 36.0837Z"
                    fill="var(--journey-sell_your_car-primary_500, #3D8374)"
                    stroke="var(--journey-sell_your_car-primary_500, #3D8374)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.8286 30.6016C13.8286 28.2016 14.0955 26.7268 14.9943 25.8016C15.8931 24.8764 17.3257 24.6016 19.6571 24.6016C17.3257 24.6016 15.8931 24.3268 14.9943 23.4016C14.0955 22.4764 13.8286 21.0016 13.8286 18.6016C13.8286 21.0016 13.5616 22.4764 12.6629 23.4016C11.7641 24.3268 10.3314 24.6016 8 24.6016C10.3314 24.6016 11.7641 24.8764 12.6629 25.8016C13.5616 26.7268 13.8286 28.2016 13.8286 30.6016Z"
                    stroke="var(--text-strong, #1A1A1A)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30.114 16.2C30.114 14.76 30.2789 13.8751 30.834 13.32C31.3892 12.7649 32.274 12.6 33.714 12.6C32.274 12.6 31.3892 12.4351 30.834 11.88C30.2789 11.3249 30.114 10.44 30.114 9C30.114 10.44 29.9492 11.3249 29.394 11.88C28.8389 12.4351 27.954 12.6 26.514 12.6C27.954 12.6 28.8389 12.7649 29.394 13.32C29.9492 13.8751 30.114 14.76 30.114 16.2Z"
                    stroke="var(--text-strong, #1A1A1A)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3498_70696">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
const Svg2 = () => {
    return (
        <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M56 14.8555H8V54.8555H56V14.8555Z"
                stroke="var(--text-strong, #1A1A1A)"
                stroke-width="2"
                strokeMiterlimit="10"
                stroke-linecap="square"
            />
            <path
                d="M17 9H47"
                stroke="var(--text-strong, #1A1A1A)"
                stroke-width="2"
                strokeMiterlimit="10"
                stroke-linecap="square"
            />
            <path
                d="M15 48L22.8462 36.5455L28.9487 42.2727L38.5385 30L49 48H15Z"
                stroke="var(--journey-sell_your_car-primary_500, #3D8374)"
                stroke-width="2"
                strokeMiterlimit="10"
                stroke-linecap="square"
            />
            <path
                d="M23 30C25.2091 30 27 28.2091 27 26C27 23.7909 25.2091 22 23 22C20.7909 22 19 23.7909 19 26C19 28.2091 20.7909 30 23 30Z"
                stroke="var(--text-strong, #1A1A1A)"
                stroke-width="2"
                strokeMiterlimit="10"
                stroke-linecap="square"
            />
        </svg>
    )
}
const Sv1 = () => (
    <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3498_64535)">
            <rect
                x="8"
                y="25"
                width="48"
                height="14"
                stroke="var(--journey-sell_your_car-primary_500, #3D8374)"
                stroke-width="2"
            />
            <path
                d="M36.5 32H22"
                stroke="var(--journey-sell_your_car-primary_500, #3D8374)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M51 32H42"
                stroke="var(--journey-sell_your_car-primary_500, #3D8374)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <circle
                cx="15"
                cy="32"
                r="2"
                stroke="var(--journey-sell_your_car-primary_500, #3D8374)"
                stroke-width="2"
            />
            <line
                x1="14"
                y1="18"
                x2="50"
                y2="18"
                stroke="var(--text-strong, #1A1A1A)"
                stroke-width="2"
                stroke-linecap="square"
            />
            <line
                x1="21"
                y1="11"
                x2="43"
                y2="11"
                stroke="var(--text-strong, #1A1A1A)"
                stroke-width="2"
                stroke-linecap="square"
            />
            <line
                x1="14"
                y1="46"
                x2="50"
                y2="46"
                stroke="var(--text-strong, #1A1A1A)"
                stroke-width="2"
                stroke-linecap="square"
            />
            <line
                x1="21"
                y1="53"
                x2="43"
                y2="53"
                stroke="var(--text-strong, #1A1A1A)"
                stroke-width="2"
                stroke-linecap="square"
            />
        </g>
        <defs>
            <clipPath id="clip0_3498_64535">
                <rect width="64" height="64" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const SectionLeft = () => {
    const { theme } = useThemes()
    return (
        <JourneyColumnContent style={{ gap: 32, paddingTop: '10px' }}>
            <Row>
                <IconWrap>
                    <Sv1 />
                </IconWrap>
                <JourneyColumnContent style={{ gap: '4px' }}>
                    <Title2>History and provenance</Title2>
                    <JourneyText $theme={theme}>
                        Whether its pre-war perfection or a modified
                        masterpiece, everyone loves a great history file. Even
                        better when its browsable and searchable on any device!
                    </JourneyText>
                </JourneyColumnContent>
            </Row>
            <Row>
                <IconWrap>
                    <Svg2 />
                </IconWrap>
                <JourneyColumnContent style={{ gap: '4px' }}>
                    <Title2>A picture is worth a thousand words</Title2>
                    <JourneyText $theme={theme}>
                        Create a rich history file and car gallery, both with
                        fast high quality image distribution and zero file
                        compression. Great images sell cars.
                    </JourneyText>
                </JourneyColumnContent>
            </Row>
            <Row>
                <IconWrap>
                    <Svg3 />
                </IconWrap>
                <JourneyColumnContent style={{ gap: '4px' }}>
                    <Title2>What makes your car special?</Title2>
                    <JourneyText $theme={theme}>
                        Showcase up to 5 key moments and facts about your
                        vehicle. This might be a cool modification, a rare
                        specification, recent restoration, a previous famous
                        driver or maybe just how amazing it makes you feel.
                    </JourneyText>
                </JourneyColumnContent>
            </Row>
        </JourneyColumnContent>
    )
}
export default function SellYourCarIncreaseValueStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section[
                                        'sell_your_car'
                                    ].primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[
                                        `sell_your_car`
                                    ].primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        Activities which increase a car's value
                                    </JourneyStepTitle>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <SectionLeft />

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'sell_your_car'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'sell_your_car'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <FadedSlower>
                                <img
                                    src={image}
                                    // placeholder={grey_placeholder}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '38vw',
                                        maxHeight: '80vh',
                                    }}
                                />
                            </FadedSlower>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingBottom: 200,
                            paddingTop: 48,
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <JourneyStepTitle $theme={theme}>
                                        Activities which increase
                                        <JourneyWelcomeNewLineOnMobile />a car's
                                        value
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        Sell your car in the best possible way
                                    </JourneyText>
                                    <div style={{ paddingBottom: '16px' }} />
                                </JourneyColumnContent>

                                <SectionLeft />
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="sell_your_car"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
