import styled from 'styled-components'
import CarAsSoldConfirmModalDesktop from '../../../molecules/showroom/editModals/desktop/carAsSoldConfirmModalDesktop'
import CarRemovalActionModalDesktop from '../../../molecules/showroom/editModals/desktop/carRemovalActionModal'
import CarUnpublishActionModalDesktop from '../../../molecules/showroom/editModals/desktop/carUnpublishActionModalDesktop'
import CarUnpublishConfirmationModalDesktop from '../../../molecules/showroom/editModals/desktop/carUnpublishConfirmationModalDesktop'
import PublicationConfirmedModalDesktop from '../../../molecules/showroom/editModals/desktop/publicationConfirmedModalDesktop'
import {
    ShowroomEntryParams,
    IEditShowroomModalOptions,
} from '../../../pages/showroom/car/showroomCarProfileEdit'
import EditShowroomEntryBasicInfoFormDesktop from '../../../organisms/showroomForms/editShowroomEntryBasicInfoDesktop'
import { ICar } from '../../../../redux/entities/cars/types'
import { useParams } from 'react-router-dom'

export const ShowroomModalInnerContainer = styled.div`
    height: max-content;
    width: max-content;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 50px;
    min-width: 510px;
    z-index: 20;
    transition: all 100ms ease-in-out;
`

type Props = {
    activeModal: IEditShowroomModalOptions
    handleSetModalOpened: (p: IEditShowroomModalOptions | null) => any
    car: ICar
    entryid: string
}
const ShowroomModalEditManagerDesktop = (props: Props) => {
    let { handleSetModalOpened, car } = props

    const { entryid } = useParams<ShowroomEntryParams>()

    let renderModalBod = () => {
        switch (props.activeModal) {
            case 'edit_basic_info':
                return (
                    <EditShowroomEntryBasicInfoFormDesktop
                        onCancel={() => handleSetModalOpened(null)}
                    />
                )

            case 'publication_confirmed':
                return (
                    <PublicationConfirmedModalDesktop entryid={`${entryid}`} />
                )

            case 'unpublish_action':
                return (
                    <CarUnpublishActionModalDesktop
                        carTitle={car.title}
                        handleSetModalOpened={handleSetModalOpened}
                        cancel={() => {
                            handleSetModalOpened(null)
                        }}
                    />
                )

            case 'unpublished_confirmed':
                return (
                    <CarUnpublishConfirmationModalDesktop
                        cancel={() => {
                            handleSetModalOpened(null)
                            // handleSetModalOpened('publication_confirmed')
                        }}
                        continue={() => {
                            handleSetModalOpened(null)
                        }}
                    />
                )

            case 'removal_action':
                return (
                    <CarRemovalActionModalDesktop
                        handleSetModalOpened={() => {
                            handleSetModalOpened('removal_confirmed')
                        }}
                        cancel={() => {
                            handleSetModalOpened(null)
                        }}
                        carTitle={props.car.title}
                    />
                )

            case 'mark_as_sold_confirmed':
                return (
                    <CarAsSoldConfirmModalDesktop
                        cancel={() => {
                            handleSetModalOpened(null)
                            // handleSetModalOpened('publication_confirmed')
                        }}
                        carID={props.car.id}
                    />
                )
            default:
                return <div />
        }
    }

    let genHeight = () => {
        switch (props.activeModal) {
            case 'publication_confirmed':
                return '550px'

            case 'unpublish_action':
                return '420px'

            case 'unpublished_confirmed':
                return '390px'

            case 'removal_action':
                return '394px'

            case 'removal_confirmed':
                return '386px'

            // case 'mark_as_sold_action':
            //     return '528px'
            case 'mark_as_sold_confirmed':
                return '390px'
            default:
                return 'auto'
        }
    }

    let heightElem = genHeight()
    return (
        <ShowroomModalInnerContainer
            style={{
                height: heightElem,
                padding:
                    props.activeModal === 'edit_basic_info' ? '0px' : '50px',
            }}
        >
            {renderModalBod()}
        </ShowroomModalInnerContainer>
    )
}

export default ShowroomModalEditManagerDesktop
