import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_thick_chevron from '../../../public/assets/icons/grey_thick_chevron.svg'

type Props = {
    children?: React.ReactNode
}

const Wrapper = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const StyledContent = styled.section`
    @media ${device.beyond_ipad_mobile} {
        color: var(--text-strong, #1a1a1a);
        font-family: Lato-Light;
        font-size: 20px;
        padding-left: 8px;
        transform: translateY(-3px);
    }

    @media ${device.mobile} {
    }
`
const StyledSvg = styled.img`
    @media ${device.beyond_ipad_mobile} {
        transform: rotate(90deg);
        height: 10px;
        width: 13px;
    }

    @media ${device.mobile} {
    }
`

const CarDeletionListItemDesktop: React.FC<Props> = ({ children }) => {
    return (
        <Wrapper>
            <StyledSvg src={grey_thick_chevron} alt="chevron" />
            <StyledContent>{children}</StyledContent>
        </Wrapper>
    )
}

export default CarDeletionListItemDesktop
