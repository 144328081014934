import React from 'react'
import CarHighlightsEditFormDesktop from '../editForms/carHighlightsEditForm/carHighlightsEditFormDesktop'
import { IUpdateHF } from 'entityModels'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../../redux/store'
import { highlightedFactsActions } from '../../../redux/entities/highlighted_facts/reducer'

const DragNSortManagerEditable: React.FC<{
    highlighted_facts_ids: string[]
    close: any
    car_id: string
}> = ({ highlighted_facts_ids, close, car_id }) => {
    const dispatch = useDispatch()

    const highlighted_facts_data = useSelector(
        (state: IRootState) =>
            state.entities.highlightedFactsData.highlighted_facts
    )
    const isLoading = useSelector(
        (state: IRootState) => state.entities.highlightedFactsData.loading
    )

    const handleUpdateHighlightedFactsRequest = (data: IUpdateHF) => {
        dispatch(highlightedFactsActions.updatetHighlightedFactsRequest(data))
    }

    return (
        <React.Fragment>
            {isLoading !== true &&
                highlighted_facts_ids.length > 0 &&
                highlighted_facts_data !== null && (
                    <CarHighlightsEditFormDesktop
                        close={close}
                        data_object={highlighted_facts_data}
                        ids_list={highlighted_facts_ids}
                        submitFunc={handleUpdateHighlightedFactsRequest}
                        carid={car_id}
                    />
                )}
        </React.Fragment>
    )
}

export default DragNSortManagerEditable
