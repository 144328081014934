import { call, put, select } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { api } from '../../services'
import { IRootState } from '../../store'
import { customNavDataActions } from '../../localdata/customNav/reducer'
import { ITokenBoolState } from '../types'
import { usersActions } from '../reducer'
import { ILoginPayloadResAPI } from '../../services/typedefinitions/apiPayloads'
import { IReduxError } from '../../entities/cars/types'
import { push } from 'redux-first-history'

export const getTokenBoolChecks = (state: IRootState): ITokenBoolState =>
    state.user.tokenCheck

export type IUnauthHandlerPayload = {
    functionToRepeat: any
    payload?: any
    reduxErrorActionToFire?: any[]
    redirectToLoginIfPublicUser?: boolean
    initialLandingUrlAfterLogin?: string
}

export function* unauthHandlerSaga(data: IUnauthHandlerPayload): any {
    let tokenBoolsChecks: ITokenBoolState = yield select(getTokenBoolChecks)

    if (
        // if refresh token hasn't retried for more than 5 times
        tokenBoolsChecks.isTokenReqInProcess !== true &&
        tokenBoolsChecks.retriesCount < 50
        // && !tokenBoolsChecks.hasSessionRefreshedTokenOnce
    ) {
        // say that its being fetched
        yield put(usersActions.refreshTokenRequest(true))

        // call refresh token
        try {
            let res: ILoginPayloadResAPI = yield call(
                api.authentication.refreshToken
            )

            // set refresh token in local storage
            window?.localStorage?.setItem('user_id', res.user_id)
            window?.localStorage?.setItem('refresh_token', res.refresh_token)

            // set refresh token success in reducer
            yield put(usersActions.refreshTokenSuccess(false))
            // increment refresh token number of tries in reducer
            yield put(usersActions.addToRefreshTokenRetriesCount())

            // // record RT has been refreshed once this session already
            // yield put(actions.default.actions.refreshTokenOnceSuccess(true))

            // recall action
            if (data.payload) {
                yield call(data.functionToRepeat, data.payload)
            } else {
                yield call(data.functionToRepeat)
            }
        } catch (error: any) {
            console.log(' error l 60', error)

            localStorage?.removeItem('refresh_token')

            // alert('ERROR W/TOKEN:')
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(usersActions.refreshTokenSuccess(false))
            // yield put(actions.default.actions.refreshTokenOnceSuccess(false))
            yield put(usersActions.getCurrentUserDataError(typedError))

            if (typedError?.status_code === 503) {
                return
            }

            if (data.reduxErrorActionToFire) {
                // convert error to let IReduxError = {}
                for (const fn of data.reduxErrorActionToFire) {
                    yield put(fn(error))
                }
            }

            let isResetPassPage =
                window.location.pathname.match(/reset-password/g) !== null ||
                window.location.pathname.match(/reset-password/g) !== null
                    ? true
                    : false

            let isRegisterNewLandingPage =
                window.location.pathname.match(/register/g) !== null ||
                window.location.pathname.match(/register/g) !== null
                    ? true
                    : false

            let isShowroomPublicPage =
                (window.location.pathname.match(/showroom/g) !== null ||
                    window.location.pathname.match(/showroom/g) !== null) &&
                (window.location.pathname.match(/edit/g) === null ||
                    window.location.pathname.match(/edit/g) === null)
                    ? true
                    : false

            if (data.redirectToLoginIfPublicUser) {
                if (data.initialLandingUrlAfterLogin) {
                    yield put(
                        customNavDataActions.setInitialLandingUrl(
                            data.initialLandingUrlAfterLogin
                        )
                    )
                }
                yield put(push(`/signin`))
            } else if (isShowroomPublicPage) {
                localStorage?.removeItem('refresh_token')

                if (window.matchMedia('(display-mode: standalone)').matches) {
                    // yield put(push(`/signin`))
                } else {
                    return
                    // if we're on a showroom public page and refresh_token comes back invalid
                    // we don't redirect to login and
                    // we need to remove refresh_token
                    // otherwise all subsequent calls (ex: get showroomEntry)
                    // will go for private endpoint calls and not public ones
                    // as we have separate calls (public or private) depending on whether there is any refresh_token in storage or not
                }
            } else if (
                window.location.pathname === '/login' ||
                window.location.pathname === '/signin' ||
                isResetPassPage === true ||
                isRegisterNewLandingPage === true
            ) {
                return
            } else {
                yield put(push(`/signin`))
            }

            return
        }
    } else if (tokenBoolsChecks.isTokenReqInProcess === true) {
        if (data.payload) {
            yield call(data.functionToRepeat, data.payload)
        } else {
            yield call(data.functionToRepeat)
        }
        return
    } else {
        // if refresh token is looping for any reason clean it up from local storage and push to sign in page

        yield put(push(`/signin`))
    }
}
