import { FormikProps, withFormik } from 'formik'
import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import PickerBtn from '../../../../atoms/Button/pickerBtn'
import InputField from '../../../../atoms/Inputfield/inputField'
import {
    OnboardingText,
    PaddingUnit,
} from '../../../../templates/onboarding/onboardingFormLayoutMobile'
import {
    ModalContentMobile,
    ModalTopBar,
    ModalInnerContentWrapperMobile,
} from '../../../../molecules/technicalInformation/modal'
import ButtonsStandardBottomRowMobile from '../../buttons/buttonsStandardBottomRowMobile'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import { getStandaloneSearchUrl } from '../../technicalInformationEditForm/searchselect/mobile/standaloneSearchSelectMobile'
import * as Yup from 'yup'
import Expander from '../../../../atoms/expander/expander'
import { ErrorText } from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import useThemes from '../../../../../providers/theme/hooks'
import colours from '../../../../../providers/theme/colours'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { carActions } from '../../../../../redux/entities/cars/reducer'

interface Values {
    registration_number?: string
    hasNoRegistrationNumber?: boolean
}

interface OtherProps {
    closeForm: () => void
    carid: string
    dispatch: any
    navigate: any
    selectedCountry: string | null
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { values, closeForm, errors, handleSubmit } = props
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()

    const selectedCountry = useAppSelector(
        (state) => state.localdata.dropdownData.selectedCountry
    )

    const clearSelectedCountry = () =>
        dispatch(dropDownActions.setSelectedCountry(null))

    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            window.scrollTo(0, 0)
        }
    }, [])

    const countryPickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}&form_open=true`
            : `${location.pathname}`,
        path: `/garage/country`,
        formId: 'onboarding_step_2',
        fieldId: 'country',
    })

    const { theme } = useThemes()

    return (
        <ModalContentMobile
            $theme={theme}
            style={{ maxWidth: '100%', height: '100dvh', maxHeight: '100dvh' }}
        >
            <ModalTopBar
                $theme={theme}
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    height: 56,
                    maxHeight: 56,
                    backgroundColor: colours[theme].background_default,
                }}
            />
            <ModalInnerContentWrapperMobile
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: colours[theme].background_default,
                }}
            >
                <div>
                    <PaddingUnit $times={2} />
                    <OnboardingText>
                        Please enter the country where your car is registered
                        and its registration number. If it's a UK registered
                        car, our system will pull the details for you
                        automatically.
                    </OnboardingText>
                    <PaddingUnit $times={5} />
                    <PickerBtn
                        onClick={() => {
                            !!countryPickerUrl && navigate(countryPickerUrl)
                        }}
                        text={selectedCountry ?? 'United Kingdom'}
                        label="Country"
                        fwArrow
                        height="48px"
                    />
                    <PaddingUnit $times={3} />
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        <InputField
                            theme={theme}
                            id="registration_number"
                            name="registration_number"
                            placeholder="registration number"
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            value={values.registration_number}
                            onEnterKeyPress={handleSubmit}
                            height={'48px'}
                            disabled={
                                values.hasNoRegistrationNumber === true
                                    ? true
                                    : false
                            }
                        />
                        <Expander
                            height={errors.registration_number ? 'auto' : 0}
                        >
                            <ErrorText $theme={theme}>
                                {errors.registration_number}
                            </ErrorText>
                        </Expander>
                    </div>
                    <PaddingUnit $times={16} />
                </div>
                <ButtonsStandardBottomRowMobile
                    cancel={() => {
                        closeForm()
                        clearSelectedCountry()
                    }}
                    submit={handleSubmit}
                    confirmationtext="Find my car"
                    disabled={
                        !values.registration_number ||
                        values.registration_number === ''
                            ? true
                            : false
                    }
                />
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const RetrieveCarByRegNbFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: () => ({}),
    validationSchema: () => {
        return Yup.lazy(() => {
            const regexCheck = /^[a-zA-Z0-9_ ]*$/
            return Yup.object().shape({
                registration_number: Yup.string()
                    .max(20, 'Your answer must be under 20 characters')
                    .min(2)
                    .matches(regexCheck, 'Special characters not allowed'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        if (
            (!props.selectedCountry ||
                props.selectedCountry === 'United Kingdom') &&
            values.registration_number &&
            values.registration_number.trim().length > 1 &&
            values.registration_number.length > 1
        ) {
            let reg_no = values.registration_number.trim()
            props.dispatch(dropDownActions.setRegistrationNumber(reg_no))

            let p = {
                car_id: props.carid,
                registration_number: reg_no,
                isOnboarding: true,
            }

            props.dispatch(
                carActions.getExternalCarDataByRegistrationNumberRequest(p)
            )

            setSubmitting(false)

            setTimeout(function () {
                props.closeForm()
            }, 500)
        } else {
            let reg_no =
                values.registration_number &&
                values.registration_number.trim().length > 1 &&
                values.registration_number.length > 1
                    ? values.registration_number
                    : undefined

            if (reg_no) {
                props.navigate(
                    `/onboarding-step-2-2?carid=${props.carid}&from_ves_api=false&reg_no=${reg_no}`
                )
            }
            props.closeForm()
        }
    },
})(InnerForm)

export default RetrieveCarByRegNbFormMobile
