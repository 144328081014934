import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as FieldChoices from '../../../molecules/editOrCreateModeSingleFields'
import CreateFormTitle from '../../../atoms/typography/createFormTitle'
import CreateFormSubtitle from '../../../atoms/typography/createFormSubtitle'
import {
    timeline_category_data,
    currency_data,
    currency_IDS,
} from '../../../../redux/timeline/data'
import WindowPageTemplate from '../../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CreationTopHeaderDesktop from '../../../molecules/entryPageTopHeader/creationTopHeaderDesktop'
import IconAndTextNakedButton from '../../../atoms/Button/iconsAndTextNakedButton'
import CreateFormSubSubtitleDetail from '../../../atoms/typography/createFormSubtitleDetail'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { useAppSelector } from '../../../../redux/store/hooks'
import {
    ICostItem,
    ICreationTimelineSubmitConfigs,
    ITimelineActionType,
    ITimelineCreationOrEditFormFieldItem,
    ITimelineDataToBeSubmittedObj,
    ITimelineEntityType,
    ITimelineItem,
} from '../../../../redux/timeline/types'
import { IEditOrCreateModeSingleFieldsProps } from '../../../molecules/editOrCreateModeSingleFields/types'
import { IRootState } from '../../../../redux/store'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-right: 30px;
`

const SubWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    max-width: 1400px;
`

const ButtonsSubWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
`

const LargePadding = styled.div`
    padding-top: 40px;
`

const SmallPadding = styled.div`
    padding-top: 20px;
`

const TitlesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const ButtonsSection = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid var(--border_muted);
`

interface Props {
    data_to_be_submitted: ITimelineDataToBeSubmittedObj | undefined
    current_step_index: number
    step_list: string[]
    stepFieldsData: { [key: string]: ITimelineCreationOrEditFormFieldItem }
    stepFieldIdsList: string[]
    stepTitle: string
    stepSubtitleDetail?: string
    stepSubtitle: string
    submit: ICreationTimelineSubmitConfigs
    onChange: (
        fieldID: string,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => void
    closeForm: () => void
    manageSteps: () => void
    submitDataRequest?: () => void
    goBackOneStep?: () => void
    showStepTitleAndSubtitle?: boolean
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    activeEntry?: ITimelineItem | null | undefined
}

export class AnyObj {
    [key: string]: any
}

const TimelineEditGenerateCreateFormDesktop: React.FC<Props> = ({
    data_to_be_submitted,
    current_step_index,
    step_list,
    stepFieldsData,
    stepFieldIdsList,
    stepTitle,
    stepSubtitleDetail,
    stepSubtitle,
    submit,
    onChange,
    closeForm,
    manageSteps,
    submitDataRequest,
    goBackOneStep,
    showStepTitleAndSubtitle,
    entityID,
    carID,
    entityType,
    activeEntry,
}) => {
    const navigate = useNavigate()

    const [errorObject, setErrorObject] = React.useState<AnyObj>({})

    const timeline_creation_forms_current_state = useAppSelector(
        (state: IRootState) => state.editForms.timelineCreationForm
    )
    const steps = useAppSelector(
        (state: IRootState) =>
            state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list
    )

    const entryTypes_data = useAppSelector((state) => state.timeline.categories)
    const userCurrency = useAppSelector(
        (state: IRootState) =>
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id
    )
    const isCollapsed = useAppSelector(
        (state: IRootState) => state.menus.desktopMainAppMenu.isCollapsed
    )

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const determineErrors = (fieldId: string, error: boolean) => {
        setErrorObject((prevState) => ({
            ...prevState,
            [fieldId]: error,
        }))
    }

    const checkIfSavedIsDisabled = () => {
        for (const id of stepFieldIdsList) {
            let str = id as keyof AnyObj

            let form_data =
                data_to_be_submitted &&
                data_to_be_submitted[entityType] &&
                data_to_be_submitted[entityType]![id]

            if (errorObject[str] === true) {
                return true
            }

            if (
                form_data &&
                form_data.length === 0 &&
                errorObject[str] === undefined &&
                stepFieldsData[id].optional === false
            ) {
                return true
            }

            if (!form_data && stepFieldsData[id].optional === false) {
                return true
            }
        }
        return false
    }

    const generateFields = (fieldId: string, key: any) => {
        let active_creation =
            timeline_creation_forms_current_state.active_creation as keyof typeof steps

        let active_creation_steps =
            active_creation !== null ? steps[active_creation] : undefined

        let data: IEditOrCreateModeSingleFieldsProps = {
            value_start: determineValueStart(fieldId),
            title: stepFieldsData[fieldId] && stepFieldsData[fieldId].text,
            onChangeFunc: onChange,
            id: fieldId,
            unique_identifier: 'default',
            isCreation: true,
            submitCreationConfigs: {
                entityType: submit.entity,
                action: submit.action,
            },
            placeholder:
                active_creation === 'insurance'
                    ? undefined
                    : generatePlaceholder(fieldId),
            optionTypeID:
                fieldId === 'categoryID'
                    ? 'entryType'
                    : fieldId === 'amount'
                    ? 'currency'
                    : undefined,
        }

        let hasError = (error: boolean) => {
            determineErrors(fieldId, error)
        }

        switch (fieldId) {
            case 'categoryID': {
                data.isMandatory = true
                data.charLimit = [2, 70]

                data.onChangeFunc = (
                    fieldID: any,
                    value: any,
                    entityType: ITimelineEntityType,
                    action: ITimelineActionType
                ) => {
                    if (value === 'insurance') {
                        navigate(`/car/${carID}/history-file/create/insurance`)
                    } else {
                        onChange(fieldID, value, entityType, action)
                        manageSteps()
                    }
                }

                if (data.optionTypeID) {
                    switch (data.optionTypeID) {
                        case 'entryType':
                            return (
                                <React.Fragment key={key}>
                                    <div
                                        style={{ paddingTop: '20px' }}
                                        key={key}
                                    />
                                    <FieldChoices.OptionsWithIconAndDescriptionDesktop
                                        data={data}
                                        dataCyId={
                                            active_creation_steps &&
                                            active_creation_steps[
                                                current_step_index
                                            ]
                                        }
                                        onOptionClick={() => {}}
                                        selectedOption={
                                            data_to_be_submitted &&
                                            data_to_be_submitted.entry &&
                                            data_to_be_submitted.entry
                                                .categoryID
                                        }
                                        optionsData={entryTypes_data.data}
                                        optionsIDArr={entryTypes_data.ids}
                                    />
                                </React.Fragment>
                            )

                        default:
                            return <div style={{ display: 'none' }} key={key} />
                    }
                } else return <div style={{ display: 'none' }} key={key} />
            }

            case 'title': {
                data.isMandatory = true
                data.charLimit = [1, 30]
                data.unique_identifier = uuidv4()
                return stepFieldsData[fieldId].text === 'title' ? (
                    <div style={{ width: '100%' }}>
                        <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                            isentry
                            key={key}
                            data={data}
                            dataCyId={
                                active_creation_steps &&
                                active_creation_steps[current_step_index]
                            }
                            helperText={data.placeholder}
                            hasError={hasError}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </div>
                ) : (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                        placeholder={data.placeholder}
                        hasError={hasError}
                    />
                )
            }

            case 'name': {
                data.isMandatory = true
                data.charLimit = [2, 30]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_broker': {
                data.isMandatory = false
                data.charLimit = [2, 100]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_underwriter': {
                data.isMandatory = true
                data.charLimit = [2, 100]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                        hasError={hasError}
                    />
                )
            }

            case 'company': {
                data.charLimit = [2, 70]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                        hasError={hasError}
                    />
                )
            }

            case 'amount': {
                data.isMandatory = true
                data.optionName = userCurrency ? userCurrency : 'GBP'
                return (
                    <FieldChoices.NumberEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                        optionsIDArr={currency_IDS}
                        optionsData={currency_data}
                        optionsFieldID="currency"
                        hasError={hasError}
                    />
                )
            }

            case 'date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                    />
                )

            case 'date_with_options':
                return (
                    <FieldChoices.DatePickerWithButtons
                        isDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                    />
                )

            case 'insurance_entry_policy_start_date':
                data.isMandatory = true

                data.onChangeFunc = (
                    fieldID: any,
                    value: any,
                    entityType: ITimelineEntityType,
                    action: ITimelineActionType
                ) => {
                    onChange(fieldID, value, entityType, action)
                    const aYearFromNow = new Date(value)
                    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)

                    onChange(
                        'insurance_entry_policy_end_date',
                        aYearFromNow,
                        entityType,
                        action
                    )
                }
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                    />
                )

            case 'insurance_entry_policy_end_date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldDesktop
                        data={{ ...data }}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                    />
                )
            case 'short_description':
                return (
                    <FieldChoices.TextareaEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                        hasError={hasError}
                    />
                )

            case 'attachment': {
                return (
                    <FieldChoices.WrapperAttachmentFormDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps &&
                            active_creation_steps[current_step_index]
                        }
                        entityID={entityID}
                        carID={carID}
                        entityType={entityType}
                    />
                )
            }

            default:
                return <div />
        }
    }

    const determineValueStart = (fieldId: string) => {
        if (data_to_be_submitted && submit.entity) {
            if (submit.entity && data_to_be_submitted[submit.entity]) {
                let obj: ITimelineItem | ICostItem = {
                    id: '',
                    ...data_to_be_submitted[submit.entity],
                }
                if (obj[fieldId]) {
                    return obj[fieldId]
                }
            }

            return stepFieldsData[fieldId].value
        }
        return stepFieldsData[fieldId].value
    }

    const generatePlaceholder = (fieldId: string): string => {
        if (data_to_be_submitted && submit.entity) {
            if (data_to_be_submitted[submit.entity]) {
                if (
                    data_to_be_submitted.entry &&
                    data_to_be_submitted.entry.categoryID &&
                    timeline_category_data[
                        data_to_be_submitted.entry.categoryID
                    ] &&
                    timeline_category_data[
                        data_to_be_submitted.entry.categoryID
                    ].placeholders
                ) {
                    let placeholders =
                        timeline_category_data[
                            data_to_be_submitted.entry.categoryID
                        ].placeholders

                    return placeholders ? placeholders[fieldId] : ''
                }
            }
            return ''
        }
        return ''
    }

    const is_category_specific =
        data_to_be_submitted?.entry?.categoryID === 'insurance'

    const current_creation_step_id = step_list[current_step_index]

    return (
        <WindowPageTemplate
            data-attr={`wizard_step_${current_step_index}_desktop`}
            isCollapsed={isCollapsed}
            stickyHeaderContent={
                <CreationTopHeaderDesktop
                    onClose={() => {
                        if (entityType === 'cost' && activeEntry) {
                            navigate(
                                `/car/${carID}/history-file/entry?entryid=${activeEntry.id}`
                            )
                        } else {
                            navigate(`/car/${carID}/history-file`)
                        }
                        return closeForm()
                    }}
                    categoryID={data_to_be_submitted?.entry?.categoryID}
                    text={
                        is_category_specific
                            ? stepTitle
                            : timeline_creation_forms_current_state.active_creation ===
                              'cost_desktop'
                            ? 'New Cost'
                            : timeline_creation_forms_current_state.active_creation ===
                              'add_new_timeline_intem_desktop'
                            ? 'New Entry'
                            : timeline_creation_forms_current_state.active_creation ===
                              'add_new_attachment'
                            ? 'New Attachment'
                            : ''
                    }
                />
            }
        >
            <Wrapper>
                <SubWrapper>
                    {showStepTitleAndSubtitle && (
                        <>
                            <TitlesContainer>
                                <CreateFormTitle>{stepTitle}</CreateFormTitle>
                                <CreateFormSubtitle>
                                    {stepSubtitle}
                                </CreateFormSubtitle>
                                {stepSubtitleDetail && (
                                    <CreateFormSubSubtitleDetail>
                                        {stepSubtitleDetail}
                                    </CreateFormSubSubtitleDetail>
                                )}
                            </TitlesContainer>
                            <SmallPadding />
                        </>
                    )}

                    <div style={{ width: '100%' }}>
                        {stepFieldIdsList &&
                            stepFieldIdsList.map(
                                (fieldID: string, index: number) => {
                                    return generateFields(fieldID, index)
                                }
                            )}
                    </div>
                    <LargePadding />
                </SubWrapper>

                <ButtonsSection>
                    <ButtonsSubWrapper>
                        {!is_category_specific && (
                            <IconAndTextNakedButton
                                icon="long_green_arrow"
                                iconOnLeft
                                text="Go back"
                                onClick={goBackOneStep}
                            />
                        )}
                        {current_creation_step_id ===
                        'select_category' ? null : (
                            <StandardCtaBtn
                                onClick={
                                    current_step_index < step_list.length - 1
                                        ? manageSteps
                                        : () => {
                                              submitDataRequest &&
                                                  submitDataRequest()
                                              manageSteps()
                                          }
                                }
                                isDisabled={checkIfSavedIsDisabled()}
                                dataCyId={
                                    current_step_index < step_list.length - 1
                                        ? 'confirm_step_desktop'
                                        : submit.entity === 'cost'
                                        ? 'create_history_file_cost_desktop'
                                        : 'create_history_file_entry_desktop'
                                }
                            >
                                {submit.text}
                            </StandardCtaBtn>
                        )}
                    </ButtonsSubWrapper>
                </ButtonsSection>
            </Wrapper>
        </WindowPageTemplate>
    )
}

export default TimelineEditGenerateCreateFormDesktop
