import * as React from 'react'
import styled from 'styled-components'
import { device } from '../displays/devices'
import BottomFixedContainer from './bottomFixedContainer'

type IStyle = {
    $hasRightContainer?: boolean
    $hasPositionRelative?: boolean
    $isExternalCar?: boolean
    $hideOnIpad?: boolean
    $isCollapsed?: boolean
}

interface Props {
    stickyHeaderContent?: React.ReactNode
    stickyBottomContent?: React.ReactNode
    rightSideContent?: React.ReactNode
    children?: React.ReactNode
    hasPositionRelative?: boolean
    isExternalCar?: boolean
    hideOnIpad?: boolean
    isCollapsed?: boolean
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color, #fff);

    @media ${device.smallest_laptops} {
        padding-bottom: 20px;
        height: 88vh;
    }
    @media ${device.mobile_and_ipad} {
        display: none;
    }
`
const SubWrapper = styled.div<IStyle>`
    width: ${(props) => (props.$isExternalCar ? '100%' : '96%')};
    height: 100%;
    display: flex;
    box-shadow: ${(props) =>
        props.$isExternalCar
            ? 'none'
            : '2px 4px 10px 2px var(--box-shadow, rgba(0, 0, 0, 0.08))'};
    border-radius: 5px;
    ${(props) => props.$hasPositionRelative && 'position: relative'};
    @media ${device.smallest_laptops} {
        width: 98%;
    }
    @media ${device.ipad} {
        width: 100%;
        box-sizing: border-box;
        box-shadow: none;
    }
`

const LeftSide = styled.div<IStyle>`
    position: relative;
    width: ${(props) => (props.$hasRightContainer ? '80%' : '100%')};
    padding-top: 0px;
    padding-bottom: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media ${device.large_desktop} {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0px;
        padding-bottom: 10px;
    }
    @media ${device.ipad} {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        padding-top: 10px;
        padding-right: 0px;
        align-items: center;
    }
`

const RightSide = styled.div<IStyle>`
    width: 20%;
    min-width: 280px;
    background-color: var(--off-bg-color, #fafafa);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;
    padding-top: 50px;
    transition: all 200ms;
    @media ${device.large_desktop} {
        padding: 40px;
        padding-top: 50px;
    }
    @media ${device.ipad} {
        ${(props) => props.$hideOnIpad && 'display: none'};
        padding: 30px;
        position: fixed;
        top: 170px;
        right: 50px;
        width: ${(props) => (props.$isCollapsed ? '35%' : '28%')};
        max-width: 350px;
        padding-bottom: 80px;
        box-shadow: 2px 2px 7px 2px var(--box-shadow, rgba(0, 0, 0, 0.08));
    }
    @media ${device.smallest_laptops} {
        padding: 20px;
        padding-top: 30px;
    }
`

const FixedTopContainer = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    @media screen and (min-width: 1100px) and (max-width: 1200px) {
        padding-left: 60px;
        padding-right: 70px;
    }

    @media ${device.ipad} {
        top: ${(props) => (props.$isExternalCar ? '50px' : '10px')};
        height: ${(props) => (props.$isExternalCar ? '180px' : '140px')};
        padding-top: ${(props) => (props.$isExternalCar ? '87px' : '70px')};
        width: ${(props) => (props.$isCollapsed ? '95%' : '80%')};
        align-items: flex-start;
        position: fixed;
        border-bottom: 1px solid var(--border-muted, #e5e5e5);
        background-color: var(--bg-color, #fff);
        z-index: 2;
        padding-left: 0px;
        padding-right: 32px;
    }
    @media screen and (min-width: 881px) and (max-width: 1100px) {
        width: ${(props) => (props.$isCollapsed ? '85%' : '80%')};
    }
`

const HorizontalLine = styled.div`
    width: 95%;
    height: 1px;
    align-self: center;
    background-color: var(--border-muted, #e5e5e5);
    margin-bottom: 20px;
    @media ${device.ipad} {
        display: none;
    }
`
const Container = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    padding-top: 0px;
    padding-left: 32px;
    padding-right: 32px;

    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--scroll-bar-handle-color);
        border-radius: 50px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--scroll-bar-track-color);
    }
    @media ${device.ipad} {
        height: auto;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 88px;
        border: none;
        margin-left: 10px;
    }
`

const WindowPageTemplate: React.FunctionComponent<Props> = (props) => (
    <Wrapper>
        <SubWrapper
            $hasPositionRelative={props.hasPositionRelative}
            $isExternalCar={props.isExternalCar}
        >
            <LeftSide
                $hasRightContainer={props.rightSideContent ? true : false}
            >
                {props.stickyHeaderContent && (
                    <>
                        <FixedTopContainer
                            $isCollapsed={props.isCollapsed}
                            $hasRightContainer={
                                props.rightSideContent ? true : false
                            }
                            $isExternalCar={props.isExternalCar}
                        >
                            {props.stickyHeaderContent}
                        </FixedTopContainer>
                        <HorizontalLine />
                    </>
                )}

                <Container
                    $hasRightContainer={props.rightSideContent ? true : false}
                >
                    {props.children}
                    {props.stickyBottomContent && (
                        <div style={{ paddingTop: '100px' }} />
                    )}
                </Container>
                {props.stickyBottomContent && (
                    <BottomFixedContainer
                        children={props.stickyBottomContent}
                        isExternalCar={props.isExternalCar}
                    />
                )}
            </LeftSide>
            {props.rightSideContent && (
                <RightSide
                    $hideOnIpad={props.hideOnIpad}
                    $isCollapsed={props.isCollapsed}
                >
                    {props.rightSideContent}
                </RightSide>
            )}
        </SubWrapper>
    </Wrapper>
)

export default WindowPageTemplate
