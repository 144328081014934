import { call, fork, put, take } from 'redux-saga/effects'

import { api } from '../../../services'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../reducer'
import { IDropdownItem } from 'entityModels'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'

function* setCountriesCodeDropdownDataApi() {
    try {
        let dataReceived: IDropdownItem[] =
            yield api.dropdownsData.get_countries_code_dropdown_api()

        yield put(
            dropDownActions.setCountriesCodeDropdownDataSuccess(dataReceived)
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: setCountriesCodeDropdownDataApi,
            }
            yield call(unauthHandlerSaga, p)
        } else if (error === Error) {
            let customErrorData: ICustomErrorData = {
                custom_message: `Looks like your internet has stoped working`,
                custom_message_detail: 'Check your connection and refresh',
                custom_user_action_text: 'Refresh page',
            }

            let customError: IReduxError = ConvertToReduxError(
                { status: '500' },
                customErrorData
            )
            put(dropDownActions.setCountriesCodeDropdownDataError(customError))
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)

            put(dropDownActions.setCountriesCodeDropdownDataError(typedError))
        }
    }
}
function* watcherSetMakeDropdownData() {
    while (true) {
        yield take(dropDownActions.setCountriesCodeDropdownDataRequest)
        yield call(setCountriesCodeDropdownDataApi)
    }
}

const set_countries_code_dropdown_data: any[] = [
    fork(watcherSetMakeDropdownData),
]
export default set_countries_code_dropdown_data
