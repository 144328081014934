import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../atoms/Inputfield/inputField'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import Expander from '../../../atoms/expander/expander'
import { IDropdownItem } from 'entityModels'
import { yupVar_NumberBasicSingleField } from '../yupVars'
import { Select } from '../../../atoms/select'
import useThemes from '../../../../providers/theme/hooks'
import { IEditOrCreateModeSingleFieldsProps } from '../types'

const WrapperDesktop = styled.section`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const InputFieldWrapper = styled.div`
    width: 70%;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    max-width: 460px;
`

const AdjustPosition = styled.div`
    margin-left: 20px;
`

// const CurrencyIcon = styled.div`
//     font-family: Lato-light;
//     color: #8f8f8f;
//     font-size: 18px;
//     padding-right: 8px;
//     transform: translateY(-1px);
// `

interface Values {
    single_item_edited_value_number_field_desktop: string
    unit?: string
}

interface OtherProps {
    data: IEditOrCreateModeSingleFieldsProps
    dataCyId?: string
    optionsFieldID?: string
    optionsData?: any
    optionsIDArr?: string[]
    hasError?: (payload: boolean) => void
    isDisabled?: boolean
    backgroundColor?: string
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        data,
        errors,
        setFieldValue,
        hasError,
        isDisabled,
        backgroundColor,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    // useEffect(() => {
    //     setFieldValue(
    //         'single_item_edited_value_number_field_desktop',
    //         values.single_item_edited_value_number_field_desktop
    //     )
    // }, [values])

    const handleAnswerChange = (e: any) => {
        if (isNaN(e.target.value) !== true) {
            setFieldValue(
                'single_item_edited_value_number_field_desktop',
                e.target.value
            )

            if (data.isCreation) {
                data.onChangeFunc(
                    data.id,
                    e.target.value,
                    data.submitCreationConfigs?.entityType,
                    data.submitCreationConfigs?.action
                )
            } else {
                data.onChangeFunc(data.id, e.target.value)
            }
        }
        if (
            errors.single_item_edited_value_number_field_desktop !== undefined
        ) {
            hasError && hasError(true)
        } else {
            hasError && hasError(false)
        }
    }

    const handleUnitChange = (value: string) => {
        setFieldValue('unit', value)
        if (props.optionsFieldID) {
            if (data.isCreation) {
                data.onChangeFunc(
                    props.optionsFieldID,
                    value,
                    data.submitCreationConfigs?.entityType,
                    data.submitCreationConfigs?.action
                )
            } else {
                data.onChangeFunc(props.optionsFieldID, value)
            }
        }
    }

    const { theme } = useThemes()

    return (
        <WrapperDesktop>
            <Row>
                <InputFieldWrapper>
                    <InputField
                        theme={theme}
                        id={'single_item_edited_value_number_field_desktop'}
                        name={'single_item_edited_value_number_field_desktop'}
                        placeholder={data.title}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={
                            values.single_item_edited_value_number_field_desktop
                        }
                        type="number"
                        font_family="Lato"
                        paddingLeft="0px"
                        width="100%"
                        fontSize="14px"
                        onChange={handleAnswerChange}
                        dataCyId={props.dataCyId}
                        linecolor="#C7C7C7"
                        isnarrow
                        disabled={isDisabled}
                        backgroundColor={backgroundColor}
                    />
                    <Expander
                        height={
                            values.single_item_edited_value_number_field_desktop &&
                            errors.single_item_edited_value_number_field_desktop
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {
                                    errors.single_item_edited_value_number_field_desktop
                                }
                            </Error>
                        </Faded>
                    </Expander>
                </InputFieldWrapper>
                {props.optionsFieldID && (
                    <AdjustPosition>
                        <Select
                            defaultValue={
                                data.optionName ? data.optionName : 'GBP'
                            }
                            onChange={handleUnitChange}
                            options={
                                props.optionsIDArr?.map(
                                    (optionID: string, index: number) => {
                                        let option: IDropdownItem | undefined =
                                            props.optionsData[optionID]

                                        return {
                                            value: option?.name.toUpperCase(),
                                            label: option?.name.toUpperCase(),
                                        }
                                    }
                                ) ?? []
                            }
                            backgroundColor={backgroundColor}
                        />
                    </AdjustPosition>
                )}
            </Row>
        </WrapperDesktop>
    )
}

const NumberEditOrCreateModeSingleFieldDesktop = withFormik<OtherProps, Values>(
    {
        mapPropsToValues: (props) => ({
            single_item_edited_value_number_field_desktop: props.data
                .value_start
                ? props.data.value_start
                : '',
            unit: props.data.optionName ? props.data.optionName : 'GBP',
        }),
        enableReinitialize: true,
        // validationSchema: {},
        validationSchema: () => {
            return Yup.lazy((values: Values) => {
                return Yup.object().shape({
                    single_item_edited_value_number_field_desktop:
                        yupVar_NumberBasicSingleField(
                            values.single_item_edited_value_number_field_desktop
                        ),
                })
            })
        },

        handleSubmit: (values, { props, setSubmitting }) => {},
    }
)(InnerForm)

export default NumberEditOrCreateModeSingleFieldDesktop
