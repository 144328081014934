import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import RegisterFormLandingPage from './registerFormLandingPage'
import LeftAlignLogo from '../../atoms/icons/components/leftAlignLogo'

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
`

const TopSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
`

const FieldsSection = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    @media ${device.ipad} {
    }
`

const LeftAlignLogoWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const TopPageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 32px;

    @media ${device.mobile} {
        gap: 16px;
    }
`

const PageTitle = styled.h1`
    padding: 0px;
    margin: 0px;
    color: var(--text-strong, #1a1a1a);
    font-family: Poppins-bold;
    font-size: 24px;

    @media ${device.mobile} {
        font-size: 22px;
        line-height: 28px;
    }
`

const Instructions = styled.p`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    max-width: 480px;
`

const LearnMore = styled.a`
    color: #5ec3ca !important;
    /* font-style: italic; */
    font-family: Lato-semibold;
    cursor: pointer;
    padding-left: 2px;
    transform: translateY(3px);
    padding-left: 8px;
`

type Props = {
    invite_code: any
}

const RegistrationFormWrapperLandingPage = (props: Props) => {
    return (
        <FormContainer>
            <TopSection>
                <LeftAlignLogoWrapper>
                    <a
                        href="https://www.custodian.club"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LeftAlignLogo size={40} />
                    </a>
                </LeftAlignLogoWrapper>
                <TopPageTextWrapper>
                    <PageTitle>The Platform for Car Enthusiasts</PageTitle>
                    <Instructions>
                        Sign up for free and kick-start your digital garage. No
                        hidden costs, just pure car enthusiasm.
                        <LearnMore
                            href="https://www.custodian.club"
                            target="_blank"
                            rel="noreferrer"
                            data-attr={'learn_more_registration'}
                        >
                            Learn more
                        </LearnMore>
                    </Instructions>
                </TopPageTextWrapper>
            </TopSection>
            <FieldsSection>
                <RegisterFormLandingPage invite_code={props.invite_code} />
            </FieldsSection>
        </FormContainer>
    )
}

export default RegistrationFormWrapperLandingPage
