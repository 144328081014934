import { IApexInterviewOverviewItemState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'
import read_apex_interview from '../../../../../../public/assets/icons/read_apex_interview.svg'
import listen_apex_interview from '../../../../../../public/assets/icons/listen_apex_interview.svg'
import Expander from '../../../../../atoms/expander/expander'
import { Link } from 'react-router-dom'

const WrapperRow = styled.div`
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        transition: all 200ms;
        margin-top: 44px;
    }

    @media (max-width: 330px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
`

const ImgStyle = styled.div`
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    height: 245px;
    width: 30vw;

    @media (max-width: 330px) {
        width: 80vw;
        height: 190px;
        background-position: top;
    }
    @media (min-width: 500px) {
        width: 180px;
    }
`
const ContentCol = styled.div`
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 226px;
    font-size: 16px;

    @media (max-width: 330px) {
        padding-left: 0px;
        max-width: none;
    }

    @media (min-width: 500px) {
        width: 330px;
        max-width: 330px;
        padding-left: 40px;
    }
`

const Title = styled(Link)`
    font-family: Lato;

    align-self: flex-start !important;

    padding-bottom: 5px;

    font-size: 16px;

    @media (min-width: 500px) {
        font-size: 17px;
    }

    color: var(--text-strong, #1a1a1a) !important;
`

const Text = styled.div`
    line-height: 22px;
    padding-top: 5px;
    font-family: Lato-Light;
    color: var(--text-strong, #1a1a1a);

    align-self: flex-start !important;

    font-size: 13px;

    @media (max-width: 330px) {
        font-size: 14px;
    }

    @media (min-width: 500px) {
        line-height: 26px;
        font-size: 15px;
    }
`
const Name = styled(Link)`
    font-family: Lato-Light;
    text-transform: uppercase;
    align-self: flex-start !important;
    font-size: 16px;
    @media (max-width: 330px) {
        padding-top: 20px;
    }
    color: var(--text-strong, #1a1a1a) !important;

    padding-bottom: 10px;
`

const BtnRow = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`

const BtnRowBtn = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--primary, #5ec3ca);
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    :focus {
        color: var(--primary, #5ec3ca) !important;
    }
    font-family: lato-light;
    font-size: 14px;
    width: 95px;
    height: 35px;

    @media (max-width: 330px) {
        margin-top: 5px;
        width: 40vw;
        box-sizing: border-box;
        height: 38px;
    }

    cursor: pointer;
    transition: all 200ms;
    color: var(--primary, #5ec3ca) !important;
`

const Padding = styled.div`
    margin-right: 8px;
`

type Props = {
    interview_overview: IApexInterviewOverviewItemState
}

const ApexInterviewOverviewItemMobile: React.FC<Props> = ({
    interview_overview,
}) => {
    return (
        <WrapperRow>
            <Link to={`/apex/interviews/${interview_overview.uid}`}>
                <ImgStyle
                    role="img"
                    aria-label="fullwidth-picture-2"
                    style={{
                        backgroundImage: `url(${
                            interview_overview.headshot
                                ? interview_overview.headshot.url
                                : ''
                        })`,
                    }}
                />
            </Link>
            <ContentCol>
                <Name to={`/apex/interviews/${interview_overview.uid}`}>
                    {interview_overview.name}
                </Name>
                <Title to={`/apex/interviews/${interview_overview.uid}`}>
                    {interview_overview.title}
                </Title>

                <Expander height={'auto'}>
                    <Text>{interview_overview.blurb}</Text>
                </Expander>
                <BtnRow>
                    <BtnRowBtn
                        to={`/apex/interviews/${interview_overview.uid}`}
                    >
                        <img
                            style={{
                                height: '15px',
                                width: '16px',
                            }}
                            src={read_apex_interview}
                            alt="read"
                        />

                        <Padding />
                        <div>Read</div>
                    </BtnRowBtn>

                    <span style={{ paddingRight: '10px' }} />

                    <BtnRowBtn
                        to={`/apex/podcasts#${interview_overview.uid}_m`}
                    >
                        <img
                            style={{
                                height: '15px',
                                width: '15px',
                                marginRight: '6px',
                            }}
                            src={listen_apex_interview}
                            alt="listen"
                        />
                        <div>Listen</div>
                    </BtnRowBtn>
                </BtnRow>
            </ContentCol>
        </WrapperRow>
    )
}

export default ApexInterviewOverviewItemMobile
