import styled from 'styled-components'
import three_dots_outlined from '../../../../public/assets/icons/three_dots_outlined.svg'
import { motion } from 'framer-motion'
import { device } from '../../../templates/displays/devices'
import { useState, useRef } from 'react'
import { useOnClickOutside } from '../../../templates/clickOutside'
import { Checkbox, Spin, Tooltip } from 'antd'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import Expander from '../../../atoms/expander/expander'
import IconAndTextNakedV2 from '../../../atoms/Button/iconAndTextNakedV2'
import VehicleItemDesktop from '../../../atoms/list/vehicleItemDesktop'
import {
    IInsuranceQuoteApplication_OtherDriver,
    IOtherDriver_DriveList,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import VehicleItemMobile from '../../../atoms/list/vehicleItemMobile'
import MobileMenuBottomSheet from '../../../atoms/menu/bottomSheetMenu/mobileMenuBottomSheet'
import React from 'react'
import { useAppSelector } from '../../../../redux/store/hooks'
import { IRootState } from '../../../../redux/store'
import Faded from '../../../templates/animated/faded'
import useThemes from '../../../../providers/theme/hooks'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../../providers/theme/colours'
import { ICarsObject } from '../../../../redux/entities/cars/types'

type IStyledProps = {
    $theme: ITheme
    $isActive?: boolean
    $isDisabled?: boolean
}
const Card = styled.div<IStyledProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    border-radius: 8px;
    border: ${(props) =>
        props.$isActive
            ? `1px solid ${
                  insurance_colours[props.$theme].sections['drivers']
                      .primary_100
              }`
            : `1px solid ${colours[props.$theme].border_muted}`};

    margin-bottom: 24px;
    background-color: ${(props) =>
        colours[props.$theme].background_default} !important;

    .ant-spin-dot-item {
        background-color: ${(props) =>
            insurance_colours[props.$theme].sections['drivers']
                .primary_100} !important;
    }
`

const CardBody = styled.div<IStyledProps>`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const ProgressText = styled.div<IStyledProps>`
    color: ${(props) => colours[props.$theme].text_muted};

    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const HeaderText = styled.p<IStyledProps>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 16px;
    font-family: Lato-bold;
    line-height: 24px;
    letter-spacing: 0.001px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`

const CardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`
const Separator = styled.div<IStyledProps>`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: ${(props) => colours[props.$theme].border_muted};
`
const CardHeaderLeftSide = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 16px;
`
const CardHeaderRightSide = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
    gap: 16px;
`

const StyledCheckbox = styled(Checkbox)<IStyledProps>`
    transform: translateY(-0.2em);
    .ant-checkbox-checked .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: ${(props) =>
            `1px solid ${
                insurance_colours[props.$theme].sections['drivers'].primary_100
            } !important`};
        background-color: ${(props) =>
            `${
                insurance_colours[props.$theme].sections['drivers'].primary_100
            } !important`};
    }
    .ant-checkbox-inner {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: ${(props) =>
            `1px solid ${
                insurance_colours[props.$theme].sections['drivers'].primary_100
            } !important`};
        /* background-color: ${(props) =>
            `${
                insurance_colours[props.$theme].sections['drivers'].primary_100
            } !important`}; */
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${(props) =>
            `${
                insurance_colours[props.$theme].sections['drivers'].primary_100
            } !important`};
    }
    .ant-checkbox-inner:after {
        left: 24%;
        border-color: var(--bg-color, #fff) !important;
    }
    .ant-checkbox-checked::after {
        border: ${(props) =>
            `2px solid ${
                insurance_colours[props.$theme].sections['drivers'].primary_16
            } !important`};
    }
`

const MenuBox = styled.div<IStyledProps>`
    z-index: 10;
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translateX(200px);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    font-family: Lato;
    font-size: 16px;
    background: ${(props) => colours[props.$theme].modal_side_sheet_pop_over};
    box-shadow: ${(props) =>
        `0px 4px 24px ${colours[props.$theme].shadow_strongest_09}`};
    border-radius: 4px;
    height: auto;
    width: max-content;

    :hover {
        color: ${(props) =>
            insurance_colours[props.$theme].sections['drivers'].primary_100};
        transition: all 100ms;
    }
`
const ThreeDots = styled.img`
    width: 32px;
    z-index: 20;

    @media ${device.mobile_and_ipad} {
        width: 30px;
    }
`

export const ThreeDotMenuWrapper = styled(motion.div)`
    position: relative;
    cursor: pointer;
`

export const OptionsMenuItem = styled.div<IStyledProps>`
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;

    width: 100%;
    box-sizing: border-box;
    color: ${(props) =>
        props.$isDisabled
            ? `${colours[props.$theme].border_muted_neutral} !important;`
            : colours[props.$theme].text_default};
    background-color: transparent;
    border-radius: 4px;

    cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};

    :hover {
        transition: all 100ms;
        background-color: ${(props) =>
            props.$isDisabled
                ? 'transparent'
                : insurance_colours[props.$theme].sections['drivers']
                      .primary_04};
        color: ${(props) =>
            props.$isDisabled
                ? `${colours[props.$theme].text_neutral_default} !important`
                : insurance_colours[props.$theme].sections['drivers']
                      .primary_100};
    }
    padding-left: 20px;
    padding-right: 20px;
`

export type IMenuItem = {
    id: string
    text: string
    action: () => void
    isDisabled?: boolean
    disabledTxt?: string
    color?: string
}

export const MenuItem = (props: {
    item: IMenuItem
    toggleMenu?: () => void
    theme: ITheme
}) => {
    const [showTooltip, toggleTooltip] = useState(false)
    let { item, toggleMenu, theme } = props
    return (
        <Tooltip
            title={item.disabledTxt}
            open={showTooltip}
            placement="bottom"
            color="var(--error, #df6f6f)"
        >
            <OptionsMenuItem
                $theme={theme}
                key={item.id}
                $isDisabled={item.isDisabled}
                onMouseEnter={() => {
                    if (item.isDisabled) {
                        toggleTooltip(true)
                    }
                }}
                onMouseLeave={() => {
                    if (item.isDisabled) {
                        toggleTooltip(false)
                    }
                }}
                onClick={() => {
                    item.action()
                    toggleMenu && toggleMenu()
                }}
                style={{
                    color: item.color
                        ? item.color
                        : 'var(--text-default, #666666)',
                }}
            >
                {item.text}
            </OptionsMenuItem>
        </Tooltip>
    )
}

type Props = {
    driver: IInsuranceQuoteApplication_OtherDriver
    isSelected: boolean
    onSelect: (id: boolean) => any
    assignCarsToDriver: () => void
    editDriverDetails: () => void
    menu_items: IMenuItem[]
    carsData: ICarsObject
    isMobile?: boolean
    isDisabled?: boolean
    is_wizard?: boolean
}

const InsuranceDriversCard = (props: Props) => {
    let {
        driver,
        isSelected,
        onSelect,
        assignCarsToDriver,
        menu_items,
        carsData,
        isMobile,
        editDriverDetails,
        isDisabled,
        is_wizard,
    } = props

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const menuRef = useRef<HTMLDivElement>(null)

    useOnClickOutside(menuRef, () => {
        if (!isMobile && isMenuOpen === true) {
            setIsMenuOpen(false)
        }
    })

    const [showTooltip, toggleTooltip] = useState(false)

    let applicationData = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication
    )

    let is_loading = applicationData?.isLoading
    let is_draft = applicationData?.submitted_data?.status === 'DRAFT'

    let vehicles_to_insure_list = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.submitted_data
                .vehicles_to_insure_ids_list
    )

    const { theme } = useThemes()

    return (
        <Card $theme={theme} $isActive={isSelected} key={driver.id}>
            <CardHeader>
                <CardHeaderLeftSide style={isSelected ? { width: '70%' } : {}}>
                    <Tooltip
                        open={isDisabled && showTooltip ? true : false}
                        title="You can select up to 4 drivers. Please de-select another driver in order to be able to select this one."
                        placement="left"
                    >
                        <div
                            onClick={() => {
                                if (isDisabled) {
                                    toggleTooltip(true)
                                    setTimeout(() => toggleTooltip(false), 2000)
                                }
                            }}
                        >
                            {is_loading ? (
                                <Faded>
                                    <Spin
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                        }}
                                    />
                                </Faded>
                            ) : is_draft ? (
                                <Faded>
                                    <StyledCheckbox
                                        checked={isSelected}
                                        $theme={theme}
                                        $isActive={isSelected}
                                        onChange={() => onSelect(!isSelected)}
                                        disabled={isDisabled}
                                    />
                                </Faded>
                            ) : null}
                        </div>
                    </Tooltip>
                    <HeaderText $theme={theme} $isActive={isSelected}>{`${
                        driver.basic_details?.given_name ?? ''
                    } ${driver.basic_details?.family_name ?? ''}`}</HeaderText>
                </CardHeaderLeftSide>

                {isSelected && (
                    <CardHeaderRightSide>
                        <ProgressText $theme={theme} $isActive={isSelected}>
                            {driver?.completion ? `${driver?.completion}%` : ''}
                        </ProgressText>
                        {is_draft && !is_wizard && (
                            <ThreeDotMenuWrapper
                                whileTap={{ scale: 1.02 }}
                                onClick={() => {
                                    setIsMenuOpen(true)
                                }}
                                ref={menuRef}
                            >
                                <ThreeDots
                                    role="button"
                                    src={three_dots_outlined}
                                    alt="three_dots"
                                />
                                {isMenuOpen === true && !isMobile && (
                                    <MenuBox
                                        $theme={theme}
                                        $isActive={isSelected}
                                    >
                                        {menu_items.map((item, index) => {
                                            return (
                                                <React.Fragment
                                                    key={`${item.id}_${index}_${
                                                        isMobile
                                                            ? 'mobile'
                                                            : 'desktop'
                                                    }`}
                                                >
                                                    <MenuItem
                                                        theme={theme}
                                                        item={item}
                                                    />
                                                </React.Fragment>
                                            )
                                        })}
                                    </MenuBox>
                                )}
                            </ThreeDotMenuWrapper>
                        )}
                        <MobileMenuBottomSheet
                            menu_items={menu_items}
                            isOpen={isMobile && isMenuOpen ? true : false}
                            toggle={() => setIsMenuOpen(false)}
                            customHeight={'30vh'}
                        />
                    </CardHeaderRightSide>
                )}
            </CardHeader>

            <Expander height={isSelected ? 'auto' : 0} width="100%">
                {driver.drive_list && driver.drive_list.length > 0 && (
                    <div style={{ paddingTop: 24 }} />
                )}
                {driver.drive_list && driver.drive_list.length > 0 && (
                    <Separator $theme={theme} $isActive={isSelected} />
                )}
                <CardBody
                    style={{ paddingTop: is_wizard ? '0px' : '24px' }}
                    $theme={theme}
                    $isActive={isSelected}
                >
                    {/* <Text>Drive list</Text>
                    <div style={{ paddingTop: 16 }} /> */}
                    {driver.drive_list && driver.drive_list.length > 0 ? (
                        driver.drive_list.map(
                            (item: IOtherDriver_DriveList, index) =>
                                carsData ? (
                                    isMobile ? (
                                        <React.Fragment
                                            key={`${item.carid}_${index}_mobile`}
                                        >
                                            <VehicleItemMobile
                                                garageItem={
                                                    carsData[item.carid]
                                                }
                                                variant="drive_list"
                                                car_id={item.carid}
                                                isDisabled={
                                                    vehicles_to_insure_list.includes(
                                                        item.carid
                                                    )
                                                        ? false
                                                        : true
                                                }
                                                sectionId="drivers"
                                            />
                                            <div style={{ paddingTop: 16 }} />
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment
                                            key={`${item.carid}_${index}_desktop`}
                                        >
                                            <VehicleItemDesktop
                                                garageItem={
                                                    carsData[item.carid]
                                                }
                                                car_id={item.carid}
                                                variant="drive_list"
                                                isDisabled={
                                                    vehicles_to_insure_list.includes(
                                                        item.carid
                                                    )
                                                        ? false
                                                        : true
                                                }
                                                sectionId="drivers"
                                            />
                                        </React.Fragment>
                                    )
                                ) : null
                        )
                    ) : is_wizard ? null : (
                        <ButtonAtom
                            theme="capitalize-white-background"
                            width="100%"
                            height="48px"
                            fontSize={16}
                            onClick={() => editDriverDetails()}
                            color={
                                insurance_colours[theme].sections['drivers']
                                    .primary_100
                            }
                            customBgColor={
                                insurance_colours[theme].sections['drivers']
                                    .primary_100
                            }
                        >
                            <span
                                style={{
                                    textTransform: 'none',
                                    color: 'var(--bg-color, #fff)',
                                }}
                            >
                                Edit driver details
                            </span>
                        </ButtonAtom>
                    )}

                    {is_draft &&
                        driver.drive_list &&
                        driver.drive_list.length > 0 && (
                            <>
                                <div style={{ paddingTop: 16 }} />
                                <IconAndTextNakedV2
                                    hasError={
                                        driver &&
                                        driver.drive_list_errors &&
                                        driver.drive_list_errors?.message
                                            ? true
                                            : false
                                    }
                                    onClick={() => assignCarsToDriver()}
                                    text={'Assign another car'}
                                    custom_colour={
                                        insurance_colours[theme].sections[
                                            'drivers'
                                        ].primary_100
                                    }
                                />
                            </>
                        )}
                </CardBody>
            </Expander>
        </Card>
    )
}

export default InsuranceDriversCard
