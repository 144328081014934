import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../redux/store/hooks'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import BottomUpSlider from '../../templates/animated/bottomUpSlider'
import { editFormsActions } from '../../../redux/editForms/reducer'
import TimelineCreateManagerDesktop from '../../organisms/timelineForms/timelineCreateManager/timelineCreateManagerDesktop'
import TimelineCreateManagerMobile from '../../organisms/timelineForms/timelineCreateManager/timelineCreateManagerMobile'
import { TimelineCreationTypeIdsEnum } from '../../../redux/timeline/timelineEnum'
import Loader from '../../atoms/loader/loader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { timelineActions } from '../../../redux/timeline/reducer'
import { ITimelineCreateForms } from '../../../redux/editForms/types'

const CreateTimelineEntry: React.FC = () => {
    const dispatch = useAppDispatch()

    const { carid } = useParams<{ carid: string }>()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const carsData = useAppSelector((state) => state.entities.carsData.cars)

    const timeline_creation_steps_data = useAppSelector(
        (state) =>
            state.timeline.formsData.creation.timeline_creation_steps_data
    )
    const timeline_creation_forms_current_state = useAppSelector(
        (state) => state.editForms.timelineCreationForm
    )
    const timeline_creation_steps_object_of_indexed_list = useAppSelector(
        (state) =>
            state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list
    )
    const data_to_be_submitted = useAppSelector(
        (state) => state.timeline.formsData.creation.data_to_be_submitted
    )
    const labels_searchable_list = useAppSelector(
        (state) => state.user.userLoggedIn?.labels
    )

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/car/${carid}/history-file/create`
            )
        )

        window.scrollTo(0, 0)

        dispatch(carActions.setCurrentCar(`${carid}`))

        if (!carsData[`${carid}`]) {
            dispatch(carActions.getCarDataByIdRequest(`${carid}`))
        }

        if (window.innerWidth > 880) {
            dispatch(
                editFormsActions.manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: 0,
                    active_creation:
                        TimelineCreationTypeIdsEnum.add_new_desktop,
                })
            )
        } else {
            dispatch(
                editFormsActions.manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: 0,
                    active_creation: TimelineCreationTypeIdsEnum.add_new,
                })
            )
        }

        return () => {
            dispatch(
                editFormsActions.manageTimelineCreationFormState({
                    isOpen: false,
                    current_step_index: 0,
                    active_creation: null,
                })
            )

            dispatch(timelineActions.clearDataToBeSubmittedSuccess(true))
        }
    }, [carid, userLoggedIn])

    const closeFormAndDirectToPrevPath = () => {
        dispatch(
            editFormsActions.manageTimelineCreationFormState({
                isOpen: false,
                current_step_index: 0,
                active_creation: null,
            })
        )

        dispatch(timelineActions.clearDataToBeSubmittedSuccess(true))
    }

    return (
        <Faded>
            <CenteredPageWrapper fullwidth>
                <Loader
                    isLoading={
                        timeline_creation_forms_current_state.isOpen === false
                    }
                />
                <DesktopDisplayOnly>
                    <TimelineCreateManagerDesktop
                        entityID={
                            data_to_be_submitted.entry &&
                            data_to_be_submitted.entry.id
                                ? data_to_be_submitted.entry?.id
                                : 'NEW_entryUID'
                        }
                        carID={`${carid}`}
                        entityType="entry"
                        data_to_be_submitted={data_to_be_submitted}
                        timeline_creation_forms_current_state={
                            timeline_creation_forms_current_state
                        }
                        timeline_creation_steps_data={
                            timeline_creation_steps_data
                        }
                        manageTimelineCreationFormState={(
                            obj: ITimelineCreateForms
                        ) =>
                            dispatch(
                                editFormsActions.manageTimelineCreationFormState(
                                    obj
                                )
                            )
                        }
                        timeline_creation_steps_object_of_indexed_list={
                            timeline_creation_steps_object_of_indexed_list
                        }
                        close_create_form={closeFormAndDirectToPrevPath}
                    />
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    <BottomUpSlider
                        show={timeline_creation_forms_current_state.isOpen}
                    >
                        <TimelineCreateManagerMobile
                            data_to_be_submitted={data_to_be_submitted}
                            timeline_creation_forms_current_state={
                                timeline_creation_forms_current_state
                            }
                            timeline_creation_steps_data={
                                timeline_creation_steps_data
                            }
                            manageTimelineCreationFormState={(
                                obj: ITimelineCreateForms
                            ) => {
                                dispatch(
                                    editFormsActions.manageTimelineCreationFormState(
                                        obj
                                    )
                                )
                            }}
                            timeline_creation_steps_object_of_indexed_list={
                                timeline_creation_steps_object_of_indexed_list
                            }
                            close_create_form={closeFormAndDirectToPrevPath}
                            labels_searchable_list={
                                labels_searchable_list
                                    ? labels_searchable_list
                                    : []
                            }
                            entityID={
                                data_to_be_submitted.entry &&
                                data_to_be_submitted.entry.id
                                    ? data_to_be_submitted.entry?.id
                                    : 'NEW_entryUID'
                            }
                            carID={`${carid}`}
                            entityType="entry"
                        />
                    </BottomUpSlider>
                </IpadAndMobileDisplay>
            </CenteredPageWrapper>
        </Faded>
    )
}

export default CreateTimelineEntry
