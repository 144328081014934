import { useState } from 'react'

import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import GarageBannerView from '../../molecules/ImageListItem/GarageBannerView'
import Faded from '../../templates/animated/faded'
import DuoGrid from '../../molecules/imageGrid/duoGrid'
import img_1 from '../../atoms/skeleton/garage/1.png'
import img_1_dark from '../../atoms/skeleton/garage/1_dark.png'
import AddBox from '../../atoms/Button/addBox'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import Icon from '../../atoms/icons'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import colours, { ITheme } from '../../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import useThemes from '../../../providers/theme/hooks'
import {
    IGalleryImage,
    IGalleryImagesObject,
} from '../../../redux/entities/galleries/types'

type IStyle = {
    $theme: ITheme
}

const Container = styled.div`
    @media ${device.mobile} {
        margin-top: 6px;
    }

    @media ${device.desktop} {
        margin-top: 10px;
    }
`

const GoToBox = styled.div<IStyle>`
    background-color: ${(props) => colours[props.$theme].primary_16};
    position: relative;
    padding-top: 0%;
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;
    :hover {
        transition: all 200ms;
        background-color: ${(props) => colours[props.$theme].primary_30};
        cursor: pointer;
    }
    @media ${device.large_desktop} {
        height: 700px;
    }
`

const PlaceholderWrapper = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    @media ${device.large_desktop} {
        height: 700px;
    }

    @media ${device.desktop} {
        height: 600px;
    }

    @media ${device.smallest_laptops} {
        height: 530px;
    }
`

const Placeholder = styled.img`
    object-fit: cover !important;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

interface Props {
    carsIdList: string[]
    activeView: string

    isExternalGarage?: boolean

    garageid?: string
    addCarForm: () => void
    imagesData: IGalleryImagesObject | null
    dontShowAddBox?: boolean
    dataLoading?: boolean
}

export default function DesktopGarageViewManager(props: Props) {
    const [showAddBox, setShowAddBox] = useState(false)

    const dispatch = useAppDispatch()

    const reset_make_model_temps = () =>
        dispatch(dropDownActions.reset_make_model_temps())

    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const areCarsLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )

    const { theme } = useThemes()

    const car_cover_url = (carObj: any): IGalleryImage | undefined => {
        let imageid =
            carObj.gallery && carObj.gallery.cover && carObj.gallery.cover

        if (imageid && props.imagesData) {
            if (props.imagesData[imageid]) {
                return props.imagesData[imageid]
            }
        } else {
            return undefined
        }
    }

    const renderRightView = (activeView: string) => {
        if (activeView === 'banner_view') {
            let checkIfGarageIsEmpty =
                !props.carsIdList || props.carsIdList.length === 0
                    ? true
                    : false
            return (
                <Faded>
                    {props.carsIdList.map((id: string, index: number) => (
                        <Faded key={index}>
                            <GarageBannerView
                                reset_make_model_temps={reset_make_model_temps}
                                carObject={
                                    props.isExternalGarage && externalCarsData
                                        ? {
                                              ...externalCarsData[id],
                                              banner_url: car_cover_url(
                                                  externalCarsData[id]
                                              ),
                                          }
                                        : {
                                              ...carsData[id],
                                              banner_url: car_cover_url(
                                                  carsData[id]
                                              ),
                                          }
                                }
                                // onClick={
                                //     !props.isExternalGarage
                                //         ? props.addCarForm &&
                                //           props.addCarForm
                                //         : () => {}
                                // }
                                isExternalGarage={props.isExternalGarage}
                            />
                        </Faded>
                    ))}

                    {!props.isExternalGarage &&
                        !props.dataLoading &&
                        (checkIfGarageIsEmpty ? (
                            <PlaceholderWrapper
                                onMouseEnter={() => {
                                    setShowAddBox(true)
                                }}
                                onMouseLeave={() => {
                                    setShowAddBox(false)
                                }}
                                onClick={props.addCarForm && props.addCarForm}
                            >
                                <Placeholder
                                    src={theme === 'dark' ? img_1_dark : img_1}
                                />
                                {showAddBox && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            height: '100%',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            zIndex: 1,
                                        }}
                                    >
                                        <AddBox />
                                    </div>
                                )}
                            </PlaceholderWrapper>
                        ) : (
                            !props.dontShowAddBox &&
                            !props.dataLoading && (
                                <GoToBox
                                    $theme={theme}
                                    onClick={
                                        props.addCarForm && props.addCarForm
                                    }
                                    data-ph="add_car"
                                >
                                    <Icon icon="add_plus" height={'20px'} />
                                </GoToBox>
                            )
                        ))}
                    {props.isExternalGarage && checkIfGarageIsEmpty && (
                        <NoDataRectangle
                            text="This garage is empty"
                            height="300px"
                        />
                    )}
                </Faded>
            )
        } else if (activeView === 'grid_view') {
            let checkIfGarageIsEmptyAndIsExternal =
                props.isExternalGarage &&
                (!props.carsIdList || props.carsIdList.length === 0)
                    ? true
                    : false

            return (
                <Faded>
                    {checkIfGarageIsEmptyAndIsExternal ? (
                        <NoDataRectangle
                            text="This garage is empty"
                            height="300px"
                        />
                    ) : (
                        <DuoGrid
                            reset_make_model_temps={reset_make_model_temps}
                            dataToMap={props.carsIdList}
                            carsdata={
                                props.isExternalGarage && externalCarsData
                                    ? externalCarsData
                                    : carsData
                            }
                            onClick={props.addCarForm}
                            imagesData={props.imagesData}
                            isExternalGarage={props.isExternalGarage}
                            isGarage
                            dontShowAddBox={props.dontShowAddBox}
                            dataLoading={props.dataLoading}
                            dataPHAddBox="add_car"
                        />
                    )}
                </Faded>
            )
        }
    }

    const { activeView } = props

    return (
        <Container>
            {areCarsLoading !== true && renderRightView(activeView)}
        </Container>
    )
}
