import CarGalleryEditFormDesktop from '../carGalleryEditForm/carGalleryEditFormDesktop'
import HistoryFileGalleryEditFormDesktop from '../historyFileGalleryEditForm/historyFileGalleryEditFormDesktop'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'

import axios from 'axios'

interface Props {
    close: any
    carid: string
    entryid?: string
    isOnboarding?: boolean
    display_img_list?: string[]
    step?: number
}

const WrapperImageFormsDesktop: React.FC<Props> = ({
    close,
    carid,
    entryid,
}) => {
    // const [isImageCropperShown, setIsImageCropperShown] = useState(false)
    // const [presignedUrlsState, setPresignedUrlsState] = useState<any>(undefined)
    // const [count, setCount] = useState(0)
    // const [isLastUploaded, setIsLastUploaded] = useState(false)

    let gallerypresignedurls = useAppSelector(
        (state) => state.fileStorage.gallerypresignedurls
    )

    const [imageUploadedData, setImageUploadedData] = useState<any[] | null>(
        null
    )
    const [changeInPresignedURL, setChangeInPresignedURL] = useState(false)
    const [filesArray, setFilesArray] = useState<(File | undefined)[]>([])
    const [isSomethingBeingUploaded, setIsSomethingBeingUploaded] =
        useState(true)
    const [progressState, setProgressState] = useState<{ [key: string]: any }>({
        index: null,
        total: 0,
    })

    useEffect(() => {
        if (gallerypresignedurls) {
            let stateFiles: File[] = filesArray ? filesArray : ([] as any)
            upload_presignedurl_image_to_s3(stateFiles)
            // setPresignedUrlsState(gallerypresignedurls)
            setChangeInPresignedURL(!changeInPresignedURL)
        }
    }, [gallerypresignedurls])

    const upload_presignedurl_image_to_s3 = (files: File[]) => {
        setIsSomethingBeingUploaded(true)
        let filesToSend = filesArray

        filesToSend !== null &&
            filesToSend !== undefined &&
            filesToSend.forEach(async (file, index) => {
                if (
                    file !== undefined &&
                    gallerypresignedurls &&
                    gallerypresignedurls[file?.name]
                ) {
                    setProgressState({
                        total: 0,
                        index: index,
                    })

                    return await axios
                        .request({
                            method: 'put',
                            url: gallerypresignedurls[file.name].url,
                            data: files[index],
                            onUploadProgress: (p: any) => {
                                let new_key = `filesProgressObj-${index}`

                                setProgressState((prevState) => ({
                                    ...prevState,
                                    total: (p.loaded / p.total) * 100,
                                    [new_key]: (p.loaded / p.total) * 100,
                                }))
                            },
                        })
                        .then((data: any) => {
                            setProgressState((prevState) => {
                                if (prevState.total === 100) {
                                    setIsSomethingBeingUploaded(false)
                                }
                                return {
                                    ...prevState,
                                }
                            })
                            return
                        })
                        .catch((e: any) => {
                            apiSentryErrorHandler(
                                e,
                                'Upload presigned url car image to s3 error'
                            )
                            return Promise.reject(e)
                        })
                }
            })
    }

    const handleFilesGivenInWrapperComponent = (filesArrayToUpload: File[]) => {
        setProgressState({
            index: null,
            total: 0,
        })
        // setIsLastUploaded(false)
        // setCount(0)
        setFilesArray(filesArrayToUpload)

        filesArrayToUpload.forEach((file: File, index: number) => {
            let imguploadeddata = {
                fileTitle: file.name,
                file: file,
            }

            let imgdatarray = imageUploadedData
                ? imageUploadedData
                : ([] as any)

            imgdatarray[index] = imguploadeddata

            setImageUploadedData(imgdatarray)
            return
        })
    }

    const deleteImageUploaded = (index: number) => {
        let newFilesArray: (File | undefined)[] = filesArray

        newFilesArray[index] = undefined

        setFilesArray(newFilesArray)
        setProgressState((prevState) => ({
            ...prevState,
            [`filesProgressObj-${index}`]: null,
        }))
    }

    // const toggleShowImageCropper = () => {
    //     setIsImageCropperShown(!isImageCropperShown)
    // }

    const dispatch = useAppDispatch()

    return (
        <div>
            {!entryid && (
                <CarGalleryEditFormDesktop
                    close={close}
                    deleteImageUploaded={deleteImageUploaded}
                    setImageUploaded={handleFilesGivenInWrapperComponent}
                    currentImagesUploaded={filesArray}
                    carid={carid}
                    filesProgressObj={progressState}
                    isSomethingBeingUploaded={isSomethingBeingUploaded}
                    dispatch={dispatch}
                    gallerypresignedurls={gallerypresignedurls}
                />
            )}

            {entryid && (
                <HistoryFileGalleryEditFormDesktop
                    carid={carid}
                    entryid={entryid}
                    close={close}
                    deleteImageUploaded={deleteImageUploaded}
                    setImageUploaded={handleFilesGivenInWrapperComponent}
                    currentImagesUploaded={filesArray}
                    filesProgressObj={progressState}
                    isSomethingBeingUploaded={isSomethingBeingUploaded}
                    dispatch={dispatch}
                    gallerypresignedurls={gallerypresignedurls}
                />
            )}
        </div>
    )
}

export default WrapperImageFormsDesktop
