import { ICar } from '../../redux/entities/cars/types'
import { IGalleryImagesObject } from '../../redux/entities/galleries/types'
import { ITimelineItem } from '../../redux/timeline/types'

export const countFeaturedImages = (
    car: ICar,
    imagesData: IGalleryImagesObject
): number => {
    let carImagesIds: string[] | undefined = car.gallery && car.gallery.images
    let featuredImagesCount: number = 0
    if (carImagesIds) {
        for (let i = 0; i < carImagesIds.length; i++) {
            let imageId: string = carImagesIds[i]
            if (imagesData[imageId] && imagesData[imageId].featured === true) {
                featuredImagesCount++
            }
        }
    }
    return featuredImagesCount
}

export const countEntryFeaturedImages = (
    entry: ITimelineItem,
    imagesData: IGalleryImagesObject
): number => {
    let entryImagesIds: string[] | undefined = entry.gallery_image_ids
    let featuredImagesCount: number = 0
    if (entryImagesIds) {
        for (let i = 0; i < entryImagesIds.length; i++) {
            let imageId: string = entryImagesIds[i]
            if (imagesData[imageId] && imagesData[imageId].featured === true) {
                featuredImagesCount++
            }
        }
    }
    return featuredImagesCount
}
