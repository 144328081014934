import { call, fork, put, take } from 'redux-saga/effects'
import { FilterCarTimelineByCategoryNLabelIdsNTitleQSaga } from './filterCarTimeline'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { timelineActions } from '../reducer'
import { IReduxError } from '../../entities/cars/types'
import { ISetFilterTimelineByCategoryNLabelNTitleQCarID } from '../types'

function* SetFilterTimelineByCategoryNLabelsNTitleQSaga(
    payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
): any {
    try {
        yield put(
            timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNEntryTitleQSuccess(
                payload
            )
        )
        if (
            payload.sendToAPI &&
            ((payload.category_ids && payload.category_ids.length > 0) ||
                (payload.label_ids && payload.label_ids.length > 0) ||
                payload.entry_title)
        ) {
            if (payload.category_ids) {
                let payload2 = {
                    car_id: payload.car_id,
                    category_ids: payload.category_ids,
                    label_ids: payload.label_ids,
                    entry_title: payload.entry_title,
                }

                yield call(
                    FilterCarTimelineByCategoryNLabelIdsNTitleQSaga,
                    payload2
                )
            }
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: SetFilterTimelineByCategoryNLabelsNTitleQSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let err: IReduxError = ConvertToReduxError(error)
            yield put(timelineActions.addTimelineItemToCarError(err))
        }
    }
}

function* setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQ() {
    while (true) {
        const { payload } = yield take(
            timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest
        )

        yield call(SetFilterTimelineByCategoryNLabelsNTitleQSaga, payload)
    }
}

const set_filter_timeline_by_category_and_labels: any[] = [
    fork(setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQ),
]

export default set_filter_timeline_by_category_and_labels
