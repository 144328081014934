import * as React from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    history_file_colours,
} from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import { ITimelineEntryTypes } from '../../../redux/timeline/types'

type IStyle = {
    $theme: ITheme
    $category?: ITimelineEntryTypes
    $isActive?: boolean
    $hasSampleData?: boolean
}

const Wrapper = styled.div`
    flex: 1;
    /* min-width: 500px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 0px;
`

const Container = styled.div<IStyle>`
    flex: 1;
    display: flex;
    position: relative;
    background-color: ${(props) =>
        props.$theme === 'dark' && props.$category
            ? history_file_colours[props.$theme].categories[props.$category]
                  .primary_16
            : colours[props.$theme].background_default};
    border-radius: 8px;
    border: ${(props) =>
        `1px solid ${
            props.$hasSampleData
                ? props.$isActive
                    ? colours[props.$theme].primary
                    : 'transparent'
                : props.$category
                ? props.$isActive
                    ? history_file_colours[props.$theme].categories[
                          props.$category
                      ].primary_100
                    : history_file_colours[props.$theme].categories[
                          props.$category
                      ].primary_30
                : props.$isActive
                ? colours[props.$theme].primary
                : 'transparent'
        }`};
    cursor: pointer;
    box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04),
        0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    min-width: 0px;

    :hover {
        box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.12),
            0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    }
`

const CircleWrapper = styled.div<IStyle>`
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 32px;
    transition: all 200ms;
    position: relative;
`

const Circle = styled.div<IStyle>`
    width: ${(props) => (props.$isActive ? '30px' : '8px')};
    height: ${(props) => (props.$isActive ? '12px' : '8px')};
    background-color: ${(props) =>
        props.$isActive
            ? props.$hasSampleData
                ? colours[props.$theme].primary
                : props.$category
                ? history_file_colours[props.$theme].categories[props.$category]
                      .primary_100
                : colours[props.$theme].primary
            : colours[props.$theme].border_muted};
    border-radius: 8px;
    transition: all 200ms;
    z-index: 1;
`

const CircleTall = styled.div<IStyle>`
    height: ${(props) => (props.$isActive ? '30px' : '8px')};
    width: ${(props) => (props.$isActive ? '12px' : '8px')};
    background-color: ${(props) =>
        props.$isActive
            ? props.$hasSampleData
                ? colours[props.$theme].primary
                : props.$category
                ? history_file_colours[props.$theme].categories[props.$category]
                      .primary_100
                : colours[props.$theme].primary
            : colours[props.$theme].border_muted};
    border-radius: 8px;
    transition: all 200ms;
`

export interface IProps {
    category?: ITimelineEntryTypes
    bubleSide: 'left' | 'right' | 'top'
    children?: React.ReactNode
    dataCyId?: string
    onClick?: any
    hasSampleData?: boolean
}

const BubbleCard: React.FC<IProps> = (props) => {
    const { bubleSide, category, children, dataCyId, hasSampleData } = props
    const { theme } = useThemes()
    const [isActive, setIsActive] = React.useState(false)
    return (
        <Wrapper
            style={
                bubleSide === 'top'
                    ? {
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'stretch',
                      }
                    : {}
            }
        >
            {bubleSide === 'right' ? (
                <CircleWrapper
                    $theme={theme}
                    $category={category}
                    $hasSampleData={hasSampleData}
                    style={{
                        transform: 'translateX(-2px)',
                        width: 36,
                    }}
                >
                    <Circle
                        $theme={theme}
                        $category={category}
                        $hasSampleData={hasSampleData}
                        $isActive={isActive}
                    />
                    <div
                        style={{
                            height: 24,
                            width: 8,
                            backgroundColor: colours[theme].background_default,
                            position: 'absolute',
                            left: 0,
                            zIndex: 0,
                        }}
                    />
                </CircleWrapper>
            ) : null}

            {bubleSide === 'top' ? (
                <CircleWrapper
                    $theme={theme}
                    $category={category}
                    $hasSampleData={hasSampleData}
                    style={{
                        height: 38,
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        backgroundColor: colours[theme].background_default,
                        transform: 'translateY(-8px)',
                        alignSelf: 'center',
                    }}
                >
                    <CircleTall
                        $theme={theme}
                        $category={category}
                        $isActive={isActive}
                        $hasSampleData={hasSampleData}
                    />
                </CircleWrapper>
            ) : null}

            <Container
                $theme={theme}
                $category={category}
                $hasSampleData={hasSampleData}
                $isActive={isActive}
                onClick={props.onClick}
                onMouseEnter={() => {
                    setIsActive(true)
                }}
                onMouseLeave={() => {
                    setIsActive(false)
                }}
                data-attr={dataCyId && dataCyId}
            >
                {children}
            </Container>
            {bubleSide === 'left' ? (
                <CircleWrapper
                    $theme={theme}
                    $category={category}
                    $hasSampleData={hasSampleData}
                    style={{
                        justifyContent: 'flex-end',
                        transform: 'translateX(6px)',
                    }}
                >
                    <Circle
                        $theme={theme}
                        $category={category}
                        $hasSampleData={hasSampleData}
                        $isActive={isActive}
                    />
                    <div
                        style={{
                            height: 24,
                            width: 8,
                            backgroundColor: colours[theme].background_default,
                            position: 'absolute',
                            right: 0,
                            zIndex: 0,
                        }}
                    />
                </CircleWrapper>
            ) : null}
        </Wrapper>
    )
}

export default BubbleCard
