import styled from 'styled-components'
import ShowroomH2 from '../../../atoms/typography/showroom/ShowroomH2'
import AddBoxWithPlaceholderAndPlusDesktop from '../../../atoms/placeholders/addBoxWithPlaceholderAndPlusDesktop'
import placeholder from '../../../atoms/skeleton/keyMomentsFacts/skeleton.jpg'
import placeholder_dark from '../../../atoms/skeleton/keyMomentsFacts/skeleton_dark.png'
import React from 'react'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    keyMomentsList: string[]
    isAdmin?: boolean
    setActiveForm?: () => any
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const ListContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    box-sizing: border-box;
    grid-auto-rows: 1fr;
`

const ListContainer1 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    box-sizing: border-box;
    grid-auto-rows: 1fr;
`
const ListContainer2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    box-sizing: border-box;
    grid-auto-rows: 1fr;
    max-width: 70%;

    @media (min-width: 1600px) {
        max-width: 60%;
    }
`
const ListItem1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: var(--off-bg-color, #fafafa);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 32px;
`

const ListItem2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    flex: 1;
    background-color: var(--off-bg-color, #fafafa);
    height: 100%;

    min-height: 170px;

    padding: 32px;

    width: inherit;
`

const ListContent = styled.div`
    width: inherit;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
    padding-bottom: 20px;
`
const NumberKey = styled.div`
    font-family: Lato;
    font-style: normal;
    font-size: 18px;
    color: var(--text-muted, #b3b3b3);
    padding-right: 32px;
    line-height: 24px;
`

const KeyMomentsSectionDesktop = (props: Props) => {
    const { keyMomentsList } = props

    const { theme } = useThemes()

    return (
        <Container>
            <ShowroomH2
                leftAlign
                textTransform="capitalize"
                hasEdit={props.isAdmin && props.setActiveForm ? true : false}
                onEdit={(e: any) => {
                    e.preventDefault()
                    props.setActiveForm && props.setActiveForm()
                }}
            >
                key moments & facts
            </ShowroomH2>

            {props.isAdmin ? (
                // if it's an admin (so in edit mode), we always have the 5 boxes
                // either with content or with placeholder
                // 3 boxes on the first row and 2 centered boxes on the second row

                <div
                    style={{
                        paddingTop: '3rem',
                        width: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ListContainer1>
                        {keyMomentsList && keyMomentsList.length > 0
                            ? keyMomentsList?.map(
                                  (keyMoment: string, index: number) => {
                                      if (index <= 2) {
                                          return keyMoment !== '' ? (
                                              <ListItem1
                                                  key={`${index}-desktop-kmf`}
                                              >
                                                  <NumberKey>
                                                      {index + 1}
                                                  </NumberKey>
                                                  <ListContent>
                                                      {keyMoment}
                                                  </ListContent>
                                              </ListItem1>
                                          ) : (
                                              <React.Fragment
                                                  key={`${index}-desktop-kmf_empty`}
                                              >
                                                  <AddBoxWithPlaceholderAndPlusDesktop
                                                      height="120px"
                                                      onClickAdd={() => {
                                                          props.setActiveForm &&
                                                              props.setActiveForm()
                                                      }}
                                                      bgColor={
                                                          'var(--off-bg-color, #fafafa)'
                                                      }
                                                      customPlaceholderImg={
                                                          theme === 'dark'
                                                              ? placeholder_dark
                                                              : placeholder
                                                      }
                                                  />
                                              </React.Fragment>
                                          )
                                      } else return null
                                  }
                              )
                            : [1, 2, 3].map((i, index) => (
                                  <React.Fragment
                                      key={`${index * 5}-desktop-kmf_empty`}
                                  >
                                      <AddBoxWithPlaceholderAndPlusDesktop
                                          height="150px"
                                          onClickAdd={() => {
                                              props.setActiveForm &&
                                                  props.setActiveForm()
                                          }}
                                          bgColor={
                                              'var(--off-bg-color, #fafafa)'
                                          }
                                          customPlaceholderImg={
                                              theme === 'dark'
                                                  ? placeholder_dark
                                                  : placeholder
                                          }
                                      />
                                  </React.Fragment>
                              ))}
                    </ListContainer1>

                    <div style={{ paddingTop: '20px' }} />

                    <ListContainer2>
                        {keyMomentsList && keyMomentsList.length > 0
                            ? keyMomentsList.map(
                                  (keyMoment: string, index: number) => {
                                      if (index > 2) {
                                          return keyMoment !== '' ? (
                                              <ListItem2
                                                  key={`${index}-desktop-kmf-2`}
                                                  style={{
                                                      minHeight: '150px',
                                                  }}
                                              >
                                                  <NumberKey>
                                                      {index + 1}
                                                  </NumberKey>
                                                  <ListContent>
                                                      {keyMoment}
                                                  </ListContent>
                                              </ListItem2>
                                          ) : (
                                              <React.Fragment
                                                  key={`${
                                                      index * 10
                                                  }-desktop-kmf_empty`}
                                              >
                                                  <AddBoxWithPlaceholderAndPlusDesktop
                                                      height="150px"
                                                      onClickAdd={() => {
                                                          props.setActiveForm &&
                                                              props.setActiveForm()
                                                      }}
                                                      bgColor={
                                                          'var(--off-bg-color, #fafafa)'
                                                      }
                                                      customPlaceholderImg={
                                                          theme === 'dark'
                                                              ? placeholder_dark
                                                              : placeholder
                                                      }
                                                  />
                                              </React.Fragment>
                                          )
                                      } else return null
                                  }
                              )
                            : [1, 2].map((i, ind) => (
                                  <React.Fragment
                                      key={`${ind * 20}-desktop-kmf_empty`}
                                  >
                                      <AddBoxWithPlaceholderAndPlusDesktop
                                          height="150px"
                                          onClickAdd={() => {
                                              props.setActiveForm &&
                                                  props.setActiveForm()
                                          }}
                                          bgColor={
                                              'var(--off-bg-color, #fafafa)'
                                          }
                                          customPlaceholderImg={
                                              theme === 'dark'
                                                  ? placeholder_dark
                                                  : placeholder
                                          }
                                      />
                                  </React.Fragment>
                              ))}
                    </ListContainer2>
                </div>
            ) : keyMomentsList.length !== 4 ? (
                // if not an admin (so in readonly mode)
                // and we have 3 or 5 boxes with content
                // we have 3 boxes on first row and 2 centered boxes if not empty on the second row
                <div
                    style={{
                        paddingTop: '3rem',
                        width: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ListContainer1>
                        {keyMomentsList && keyMomentsList.length > 0
                            ? keyMomentsList?.map(
                                  (keyMoment: string, index: number) => {
                                      if (index <= 2) {
                                          return keyMoment !== '' ? (
                                              <ListItem1
                                                  key={`${index}-desktop-kmf`}
                                              >
                                                  <NumberKey>
                                                      {index + 1}
                                                  </NumberKey>
                                                  <ListContent>
                                                      {keyMoment}
                                                  </ListContent>
                                              </ListItem1>
                                          ) : null
                                      } else return null
                                  }
                              )
                            : null}
                    </ListContainer1>

                    <div style={{ paddingTop: '20px' }} />

                    <ListContainer2>
                        {keyMomentsList && keyMomentsList.length > 2
                            ? keyMomentsList.map(
                                  (keyMoment: string, index: number) => {
                                      if (index > 2) {
                                          return keyMoment !== '' ? (
                                              <ListItem2
                                                  key={`${index}-desktop-kmf-2`}
                                                  style={{
                                                      minHeight: '150px',
                                                  }}
                                              >
                                                  <NumberKey>
                                                      {index + 1}
                                                  </NumberKey>
                                                  <ListContent>
                                                      {keyMoment}
                                                  </ListContent>
                                              </ListItem2>
                                          ) : null
                                      } else return null
                                  }
                              )
                            : null}
                    </ListContainer2>
                </div>
            ) : (
                // still in readonly mode
                // if we have 4 boxes with content, we have 2 equal rows and 2 equal columns
                <div
                    style={{
                        paddingTop: '3rem',
                        width: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ListContainer>
                        {keyMomentsList && keyMomentsList.length > 0
                            ? keyMomentsList?.map(
                                  (keyMoment: string, index: number) => {
                                      return keyMoment !== '' ? (
                                          <ListItem1
                                              key={`${index}-desktop-kmf`}
                                          >
                                              <NumberKey>{index + 1}</NumberKey>
                                              <ListContent>
                                                  {keyMoment}
                                              </ListContent>
                                          </ListItem1>
                                      ) : null
                                  }
                              )
                            : null}
                    </ListContainer>
                </div>
            )}
        </Container>
    )
}

export default KeyMomentsSectionDesktop
