import * as React from 'react'
import { useSelector } from 'react-redux'
import * as FieldChoices from '../../../molecules/editOrCreateModeSingleFields'
import { TimelineFormTypeIDsEnum } from '../../../../redux/timeline/timelineEnum'
import EditModeTopHeaderDesktop from '../../../atoms/header/editModeTopHeader/editModeTopHeaderDesktop'
import Faded from '../../../templates/animated/faded'
import {
    timeline_category_data,
    currency_data,
    currency_IDS,
} from '../../../../redux/timeline/data'
import BottomFixedContainer from '../../../templates/windowPageTemplate/bottomFixedContainer'
import EditModeFooterDesktop from '../../../atoms/footer/editModeFooterDesktop'
import styled from 'styled-components'
import InputField from '../../../atoms/Inputfield/inputField'
import CalendarIcon from '../../../atoms/icons/components/calendar'
import dayjs from 'dayjs'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { IEditOrCreateModeSingleFieldsProps } from '../../../molecules/editOrCreateModeSingleFields/types'
import { IRootState } from '../../../../redux/store'
import { ITimelineEditForms } from '../../../../redux/editForms/types'
import { ITimelineItem, ICostItem } from '../../../../redux/timeline/types'
import useThemes from '../../../../providers/theme/hooks'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 150px;
`

class AnyObj {
    [key: string]: any
}

type IisMandatoryFieldObj = {
    [key: string]: boolean
}

type Props = {
    item_to_edit: ITimelineItem | ICostItem
    edited_item: ITimelineItem | ICostItem | null
    timelineItemEditForm: ITimelineEditForms
    onClickSave: () => void
    onDeleteClick?: () => void
    onChange: (
        fieldID: keyof ITimelineItem | keyof ICostItem,
        newValue: any
    ) => void
    closeForm: () => void
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    readOnlyMode?: boolean
}

const TimelineGenerateEditFormModeDesktop = (props: Props) => {
    let { theme } = useThemes()

    let {
        item_to_edit,
        onChange,
        readOnlyMode,
        timelineItemEditForm,
        dataCyId,
        closeForm,
        onClickSave,
        onDeleteClick,
    } = props

    const [isHeaderActive, setIsHeaderActive] = React.useState(false)
    const [_, setErrorObject] = React.useState<AnyObj>(new AnyObj())

    const userCurrency = useSelector(
        (state: IRootState) =>
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id
    )

    const fieldsList = useSelector(
        (state: IRootState) => state.timeline.formsData.fieldsList
    )
    const attachmentsObj = useSelector(
        (state: IRootState) => state.attachments.attachmentsById
    )
    const isCollapsed = useSelector(
        (state: IRootState) => state.menus.desktopMainAppMenu.isCollapsed
    )
    const deletionInProgress = useSelector(
        (state: IRootState) => state.timeline.deletionInProgress
    )

    React.useEffect(() => {
        setIsHeaderActive(checkIfDataHasBeenChanged())
        return () => setIsHeaderActive(false)
    }, [props.edited_item, props.item_to_edit])

    const isMandatoryFieldObj: IisMandatoryFieldObj = {
        name: true,
        title: true,
        amount: true,
    }

    const checkIfDataHasBeenChanged = (): boolean => {
        let obj1 = props.item_to_edit
        let obj2 = props.edited_item

        if (obj2 == null) {
            return false
        }

        const obj1Length = Object.keys(obj1).length
        const obj2Length = Object.keys(obj2).length

        if (obj1Length === obj2Length) {
            return !Object.keys(obj1).every(
                (key) =>
                    obj2 !== null &&
                    obj2.hasOwnProperty(key) &&
                    obj2[key] === obj1[key]
            )
        }
        return true
    }

    const generatePlaceholder = (fieldId: string): string => {
        let { item_to_edit } = props

        if (
            timeline_category_data[item_to_edit.categoryID] &&
            timeline_category_data[item_to_edit.categoryID].placeholders
        ) {
            let placeholders =
                timeline_category_data[item_to_edit.categoryID].placeholders

            return placeholders ? placeholders[fieldId] : ''
        } else return ''
    }

    const determineErrors = (fieldId: string, error: boolean) => {
        setErrorObject((prevState) => ({
            ...prevState,
            [fieldId]: error,
        }))
    }

    const generateFields = (fieldId: string, theme: ITheme) => {
        let data: IEditOrCreateModeSingleFieldsProps = {
            value_start:
                fieldId === 'attachment' ||
                fieldId === 'attachment_with_preview'
                    ? attachmentsObj[item_to_edit.attachmentID]
                        ? attachmentsObj[item_to_edit.attachmentID]
                        : undefined
                    : item_to_edit[fieldId],
            title: `${fieldId}`,
            onChangeFunc: onChange,
            id: fieldId,
            placeholder: generatePlaceholder(fieldId),
            isMandatory: isMandatoryFieldObj[fieldId]
                ? isMandatoryFieldObj[fieldId]
                : false,
        }

        let hasError = (error: boolean) => {
            determineErrors(fieldId, error)
        }

        switch (fieldId) {
            case 'title': {
                data.charLimit = [2, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                        isDisabled={readOnlyMode}
                    />
                )
            }

            case 'name': {
                data.charLimit = [2, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                        isDisabled={readOnlyMode}
                    />
                )
            }

            case 'company':
                data.charLimit = [0, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                        isDisabled={readOnlyMode}
                    />
                )

            case 'amount': {
                data.optionName = item_to_edit.currency
                    ? item_to_edit.currency
                    : userCurrency
                    ? userCurrency
                    : 'GBP'
                return (
                    <FieldChoices.NumberEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        optionsIDArr={currency_IDS}
                        optionsData={currency_data}
                        optionsFieldID="currency"
                        hasError={hasError}
                        isDisabled={readOnlyMode}
                    />
                )
            }

            case 'date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        isDisabled={readOnlyMode}
                    />
                )

            case 'description':
                data.charLimit = [0, 450]
                return (
                    <div style={{ paddingTop: '20px' }}>
                        <FieldChoices.TextareaEditOrCreateModeSingleFieldDesktop
                            key={`${fieldId}+${item_to_edit.id}`}
                            data={data}
                            dataCyId={`edit_${fieldId}_field`}
                            placeholder={data.placeholder}
                            hasError={hasError}
                            isDisabled={readOnlyMode}
                        />
                    </div>
                )

            case 'attachment':
                return (
                    <FieldChoices.WrapperAttachmentFormDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        entityID={props.entityID}
                        carID={props.carID}
                        entityType={props.entityType}
                    />
                )

            case 'notes':
                return (
                    <FieldChoices.EntryNotesFormDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        isDisabled={readOnlyMode}
                    />
                )

            case 'insurance_entry_broker': {
                data.isMandatory = false
                data.charLimit = [2, 100]
                data.title = 'Broker (if applicable)'
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={`${fieldId}+${item_to_edit.id}`}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_underwriter': {
                data.isMandatory = true
                data.charLimit = [2, 100]
                data.title = 'Insurer'

                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={`${fieldId}+${item_to_edit.id}`}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_policy_start_date':
                data.title = 'Policy start date'
                return (
                    <div
                        style={{
                            width: '70%',
                            maxWidth: '460px',
                            paddingTop: '30px',
                        }}
                    >
                        <InputField
                            theme={theme}
                            disabled={false}
                            id="insurance_entry_policy_start_date"
                            name="Policy start date"
                            placeholder={'Policy start date'}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            tabindex={1}
                            aria-live="polite"
                            defaultValue={data.value_start}
                            value={
                                props.edited_item
                                    ?.insurance_entry_policy_start_date
                            }
                            font_family="Lato"
                            restrictFlipOfDatePopUp
                            onChange={(dateString: any) => {
                                props.onChange(
                                    'insurance_entry_policy_start_date',
                                    dateString
                                )

                                setTimeout(() => {
                                    let newavall = dayjs(
                                        new Date(dateString)
                                    ).isValid()
                                        ? dayjs(new Date(dateString)).add(
                                              1,
                                              'year'
                                          )
                                        : null
                                    props.onChange(
                                        'insurance_entry_policy_end_date',
                                        newavall
                                    )
                                }, 50)
                            }}
                            type="date"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={'var(--bg-color, #ffffff)'}
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                        />
                    </div>
                )

            case 'insurance_entry_policy_end_date':
                return (
                    <div
                        style={{
                            width: '70%',
                            maxWidth: '460px',
                            paddingTop: '30px',
                            paddingBottom: '10px',
                        }}
                    >
                        <InputField
                            theme={theme}
                            disabled={false}
                            id="insurance_entry_policy_end_date"
                            name="'Policy end date'"
                            placeholder={'Policy end date'}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            tabindex={1}
                            aria-live="polite"
                            defaultValue={data.value_start}
                            value={
                                props.edited_item
                                    ?.insurance_entry_policy_end_date
                            }
                            font_family="Lato"
                            restrictFlipOfDatePopUp
                            onChange={(dateString: any) => {
                                props.onChange(
                                    'insurance_entry_policy_end_date',
                                    dateString
                                )
                            }}
                            type="date"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={'var(--bg-color, #ffffff)'}
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                        />
                    </div>
                )

            default:
                return null
        }
    }

    let field_ids_general_form =
        timelineItemEditForm.id === TimelineFormTypeIDsEnum.general &&
        fieldsList &&
        timelineItemEditForm.category
            ? fieldsList.fieldsListByCategory[
                  timelineItemEditForm.category
              ].map((fieldID: string) => fieldID)
            : undefined

    let field_ids_form =
        timelineItemEditForm.id !== TimelineFormTypeIDsEnum.general &&
        fieldsList &&
        timelineItemEditForm.id! in fieldsList.fieldsListByType
            ? fieldsList.fieldsListByType[timelineItemEditForm.id!].map(
                  (fieldID: string) => fieldID
              )
            : undefined

    const checkIfSaveIsDisabled = () => {
        // If no edited item, disable save
        if (!props.edited_item) return true

        // 1. Check mandatory fields
        const currentFields = props.edited_item
            ? Object.keys(props.edited_item)
            : []

        const mandatoryFields = currentFields.filter(
            (id: any) => isMandatoryFieldObj[id]
        )

        const hasMandatoryEmpty = mandatoryFields.some((field: string) => {
            if (props.edited_item) {
                const value =
                    props.edited_item[field as keyof typeof props.edited_item]
                return !value || (typeof value === 'string' && !value.trim())
            } else return true
        })

        if (hasMandatoryEmpty) return true

        // 2. Check if any values are different from original
        const hasChanges = Object.keys(props.edited_item).some((key) => {
            if (props.edited_item) {
                return props.edited_item[key] !== props.item_to_edit[key]
            } else return false
        })

        // Enable save only if there are changes
        return hasChanges ? false : true
    }

    return (
        <>
            <div
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    height: '100%',
                }}
            >
                <Faded>
                    <EditModeTopHeaderDesktop
                        headerText={
                            timelineItemEditForm.id === 'general'
                                ? 'Entry'
                                : timelineItemEditForm.id === 'cost'
                                ? 'Cost'
                                : timelineItemEditForm.id === 'notes'
                                ? 'Notepad'
                                : timelineItemEditForm.id === 'insurance_fields'
                                ? 'Insurance details'
                                : ''
                        }
                        noBtns
                        removeHorizTopPaddings
                    />

                    <Wrapper data-attr={dataCyId}>
                        {field_ids_general_form &&
                            field_ids_general_form.map((fieldID: string) => {
                                return generateFields(fieldID, theme)
                            })}

                        {field_ids_form &&
                            field_ids_form.map((fieldID: string) => {
                                return generateFields(fieldID, theme)
                            })}
                    </Wrapper>
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            boxSizing: 'border-box',
                            backgroundColor: colours[theme].background_default,
                        }}
                    >
                        <BottomFixedContainer>
                            <EditModeFooterDesktop
                                onCancel={closeForm}
                                onSave={() => {
                                    onClickSave()
                                    setIsHeaderActive(false)
                                }}
                                onDelete={onDeleteClick}
                                isActive={isHeaderActive}
                                isSaveDisabled={checkIfSaveIsDisabled()}
                                dataCyIdSave={
                                    timelineItemEditForm.id === 'cost'
                                        ? 'save_edited_cost_desktop'
                                        : 'save_edited_entry_desktop'
                                }
                                dataCyIdCancel={
                                    timelineItemEditForm.id === 'cost'
                                        ? 'cancel_edited_cost_desktop'
                                        : 'cancel_edit_entry_desktop'
                                }
                                dataCyIdDelete="delete_cost_desktop"
                                isCollapsed={isCollapsed}
                                deletionInProgress={deletionInProgress}
                            />
                        </BottomFixedContainer>
                    </div>
                </Faded>
            </div>
        </>
    )
}

export default TimelineGenerateEditFormModeDesktop
