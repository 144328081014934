import styled from 'styled-components'
import CrossIcon from '../icons/crossIcon'

const Wrap = styled.div`
    position: absolute;
    top: 0;
    transform: translateY(-120%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    transition: all 200ms;
    box-shadow: 0px 24px 32px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
`
const Title = styled.div`
    font-size: 24px;
    line-height: 34px;
    font-family: 'Lato-semibold';
    color: var(--text-strong, #1a1a1a);
    max-width: 90%;
    padding-bottom: 8px;
`
const Text = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato';
    color: var(--text-default, #666666);
`

const Close = styled.button`
    all: unset;
    cursor: pointer;
    padding: 8px;
    padding-top: 8px;
    margin-top: 4px;
    padding-bottom: 0px;
    border-radius: 4px;
    :hover {
        background-color: #5ec3ca1c;
    }
`

type Props = {
    title?: string
    text: React.ReactChild
    isOpen: boolean
    toggle?: (p: boolean) => void
    customPadding?: number
    customBg?: string
    customWidth?: number
    alightLeft?: boolean
}

const CustomTooltip = (props: Props) => {
    return (
        <Wrap
            style={{
                opacity: props.isOpen ? 1 : 0,
                display: props.isOpen ? undefined : 'none',
                padding: props.customPadding ?? 40,
                backgroundColor:
                    props.customBg ??
                    'var(--modal-side-sheet-pop-over-bg, #fff)',
                width: props.customWidth ?? 442,
                left: props.alightLeft ? 0 : undefined,
                right: props.alightLeft ? undefined : 0,
            }}
        >
            {(props.title || props.toggle) && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <Title>{props.title}</Title>
                    {props.toggle && (
                        <Close
                            onClick={() => {
                                props.toggle && props.toggle(false)
                            }}
                        >
                            <CrossIcon />
                        </Close>
                    )}
                </div>
            )}
            <Text>{props.text}</Text>
        </Wrap>
    )
}

export default CustomTooltip
