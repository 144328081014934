import * as React from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../../../redux/store/hooks'
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'
import Faded from '../../animated/faded'
import IpadAndMobileDisplay from '../ipadAndMobileDisplay'
import { device } from '../devices'

interface Props {
    children: React.ReactNode
    isOpen: boolean
    toggle: any
    customZindex?: number
    noMenuAdjust?: boolean
}

interface Istyle {
    isCollapsed?: boolean
    isOpen?: boolean
    $customZindex?: number
}

const DesktopPageWrapperStyle = styled.div<Istyle>`
    z-index: ${(props) => (props.$customZindex ? props.$customZindex : 3)};
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: rgba(0, 0, 0, 0.85);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    top: 0px;
    bottom: 0;
    transition: all 100ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.desktop} {
        left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }
    @media ${device.large_desktop} {
        left: ${(props) => (props.isCollapsed ? '71px' : '271px')};
    }

    @media ${device.smallest_laptops} {
        left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }
`

const MenuAdjust = styled.div<Istyle>`
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    transition: all 100ms;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media ${device.desktop} {
        margin-left: ${(props) => (props.isCollapsed ? '-45px' : '-205px')};
    }
    @media ${device.large_desktop} {
    }

    @media ${device.smallest_laptops} {
        margin-left: ${(props) => (props.isCollapsed ? '-45px' : '-205px')};
    }
`

const MobilePageWrapperStyle = styled.div<Istyle>`
    z-index: ${(props) => (props.$customZindex ? props.$customZindex : 5)};
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    top: 0px;
    left: 0px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ModalDisplay: React.FC<Props> = ({
    children,
    isOpen,
    toggle,
    customZindex,
    noMenuAdjust,
}) => {
    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )

    const handleTouchMove = (e: any) => {
        let targetElement: HTMLElement | null =
            document.querySelector('#scrollable-content') &&
            document.querySelector('#scrollable-content')

        let scrollableContent: HTMLElement[] = []

        targetElement &&
            document
                .querySelectorAll('#scrollable-content *')
                .forEach((node: any) => scrollableContent.push(node))

        const targetIsElement =
            targetElement !== null && e.target.id === targetElement.id
        const modalContainsTarget = scrollableContent.includes(e.target)

        const shouldPreventDefault = !(targetIsElement || modalContainsTarget)

        if (shouldPreventDefault) e.preventDefault()
    }

    React.useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'
            if (window.matchMedia('(display-mode: standalone)').matches) {
                window.addEventListener('touchmove', handleTouchMove, {
                    passive: false,
                })
            }
        }

        return () => {
            document.body.style.overflow = 'auto'
            if (window.matchMedia('(display-mode: standalone)').matches) {
                window.removeEventListener('touchmove', handleTouchMove)
            }
        }
    }, [isOpen])

    return (
        <React.Fragment>
            <IpadAndMobileDisplay>
                <Faded>
                    <MobilePageWrapperStyle
                        isOpen={isOpen}
                        $customZindex={customZindex}
                    >
                        {children}
                    </MobilePageWrapperStyle>
                </Faded>
            </IpadAndMobileDisplay>
            <DesktopDisplayOnly>
                <Faded>
                    <DesktopPageWrapperStyle
                        style={noMenuAdjust ? { left: 0 } : {}}
                        isCollapsed={isCollapsed}
                        isOpen={isOpen}
                        $customZindex={customZindex}
                    >
                        <MenuAdjust
                            style={noMenuAdjust ? { marginLeft: 0 } : {}}
                            isCollapsed={isCollapsed}
                        >
                            {children}
                        </MenuAdjust>
                    </DesktopPageWrapperStyle>
                </Faded>
            </DesktopDisplayOnly>
        </React.Fragment>
    )
}

export default ModalDisplay
