import styled from 'styled-components'
import { IAnyObject } from '../../../../../redux/insuranceQuoteApplication/reducer'
import Expander from '../../../../atoms/expander/expander'
import useWindowSize, {
    WindowSize,
} from '../../../../templates/displays/windowSizeHook'
import { RenderInsuranceQuoteFormFieldDesktop } from '../renderElementDesktop'
import { RenderInsuranceQuoteFormFieldMobile } from '../renderElementMobile'
import { InsuranceQuoteSubFormGenerator } from '../subGenerator'
import { Error } from '../../../editForms/carDescriptionEditForm/carDescriptionEditFormDesktop'
import { useEffect, useState } from 'react'
import { IInsuranceApplicationSectionId } from '../../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { capitalizeFirstLetter } from '../../../../../helpers/strings/capitalizeFirstLetter'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    entityObj: IAnyObject
    uiFieldsObj: any
    setEntityArr: (
        p: IAnyObject[],
        fieldID: string,
        has_error?: {
            entityTypeID: string
            entityUID: string
        }
    ) => any
    titleTxt: string
    entityIndex: number
    entitiesArrDraft: IAnyObject[]
    mobileSearchPickerSectionURL?: string
    entitiesErrNormalised?: IAnyObject
    setError: any
    entityTypeID: string
    sectionID?: IInsuranceApplicationSectionId
}

const Wrapper = styled.div``

export const SelfReplicaMapKeyFields = (props: Props) => {
    let {
        entityObj,
        uiFieldsObj,
        mobileSearchPickerSectionURL,
        setError,
        entitiesArrDraft,
        setEntityArr,
        entityIndex,
        entityTypeID,
        entitiesErrNormalised,
        sectionID,
    } = props

    let entityUID = entityObj.uid ? `${entityObj.uid}` : undefined
    let errors =
        entityUID &&
        entityTypeID &&
        entitiesErrNormalised &&
        entitiesErrNormalised[`${entityTypeID}`] &&
        entitiesErrNormalised[`${entityTypeID}`][`"${entityUID}"`]
            ? entitiesErrNormalised[`${entityTypeID}`][`"${entityUID}"`]
            : undefined

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    const [triggerTotalcostUpdate, setTriggerTotalcostUpdate] = useState(false)

    let onAnswerChange = (id: string, answer: any) => {
        let roota: IAnyObject[] = [...entitiesArrDraft]

        roota[entityIndex] = {
            ...roota[entityIndex],
            [id]: answer,
        }

        let item_err = errors && errors[id] ? errors[id] : undefined

        let has_error = item_err
            ? {
                  entityTypeID: `${entityTypeID}`,
                  entityUID: `"${entityUID}"`,
              }
            : undefined

        setEntityArr([...roota], entityTypeID, has_error)

        if (entityTypeID === 'claims' && id !== 'total_cost') {
            setTriggerTotalcostUpdate(true)
        } else {
            setTriggerTotalcostUpdate(false)
        }
    }

    const updateTotalCost = () => {
        let totalCostUpdated = Object.keys(entityObj).reduce((acc, key) => {
            if (
                key.includes('cost') &&
                key !== 'total_cost' &&
                typeof +entityObj[key] === 'number'
            ) {
                return acc + +entityObj[key]
            } else return acc
        }, 0)
        onAnswerChange('total_cost', totalCostUpdated.toFixed(2))
        setTriggerTotalcostUpdate(false)
    }

    useEffect(() => {
        if (triggerTotalcostUpdate) {
            updateTotalCost()
        }
    }, [triggerTotalcostUpdate])

    const { theme } = useThemes()

    return (
        <>
            {entityObj &&
                Object.keys(entityObj).map(
                    (keyFieldID: string, index: number) => {
                        let UIData = uiFieldsObj && uiFieldsObj[keyFieldID]
                        let item_err =
                            errors && keyFieldID
                                ? UIData?.type === 'dates_range'
                                    ? errors[`${keyFieldID}_start_date`] ||
                                      errors[`${keyFieldID}_end_date`]
                                    : errors[keyFieldID]
                                    ? errors[keyFieldID]
                                    : undefined
                                : undefined

                        let prevItemHadDivider: boolean = false

                        if (!UIData) return null

                        if (
                            entityTypeID === 'claims' &&
                            entityObj.status &&
                            entityObj.status?.uid !== 'SETTLED' &&
                            keyFieldID === 'settled_date'
                        ) {
                            return null
                        }

                        return (
                            <Wrapper
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    position: 'relative',
                                    zIndex: 300 - index,
                                }}
                                key={`keyFields-${keyFieldID}-${index}-${
                                    isMobile ? 'mobile' : 'desktop'
                                }`}
                                id={`generated-sub-${keyFieldID}`}
                            >
                                <Expander
                                    height={
                                        UIData.type === 'date'
                                            ? 'auto'
                                            : entityObj[keyFieldID] !==
                                                  undefined &&
                                              entityObj[keyFieldID] !== null &&
                                              entityObj[keyFieldID] !== '' &&
                                              index !== 0 &&
                                              UIData.type !== 'yes_no' &&
                                              UIData.type !==
                                                  'contact_medium_choice' &&
                                              !prevItemHadDivider
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    <div
                                        style={{
                                            paddingTop: '10px',
                                        }}
                                    />
                                </Expander>
                                {UIData.type === 'yes_no' &&
                                    index !== 0 &&
                                    !prevItemHadDivider && (
                                        <div
                                            style={{
                                                paddingTop: '10px',
                                            }}
                                        />
                                    )}

                                {isMobile ? (
                                    <RenderInsuranceQuoteFormFieldMobile
                                        mobileSearchPickerSectionURL={
                                            mobileSearchPickerSectionURL
                                        }
                                        // obj of a disability entity
                                        data={entityObj}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        item={{
                                            ...UIData,
                                        }}
                                        entityIndex={entityIndex}
                                        sectionID={sectionID}
                                    />
                                ) : (
                                    <RenderInsuranceQuoteFormFieldDesktop
                                        // obj of a disability entity
                                        data={entityObj}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        item={{
                                            ...UIData,
                                        }}
                                        sectionID={sectionID}
                                    />
                                )}
                                <Expander height={item_err ? 'auto' : 0}>
                                    <Error
                                        $theme={theme}
                                        style={{
                                            paddingTop: '8px',
                                        }}
                                    >
                                        {item_err &&
                                        typeof item_err !== 'string' &&
                                        item_err.status
                                            ? `${
                                                  item_err.status?.toLowerCase() !==
                                                  'invalid'
                                                      ? `${capitalizeFirstLetter(
                                                            item_err.status
                                                        )} - `
                                                      : ''
                                              } ${
                                                  item_err?.message
                                                      ? `${item_err?.message}`
                                                      : ''
                                              }`
                                            : item_err &&
                                              typeof item_err === 'string'
                                            ? `${item_err}`
                                            : ''}
                                    </Error>
                                </Expander>
                                <Expander
                                    height={
                                        (UIData.has_subform_if_true &&
                                            entityObj &&
                                            (entityObj[keyFieldID] === true ||
                                                `${entityObj[keyFieldID]?.name}`.toLocaleLowerCase() ===
                                                    'yes')) ||
                                        (UIData.has_subform_if_false &&
                                            entityObj &&
                                            (entityObj[keyFieldID] === true ||
                                                `${entityObj[keyFieldID]?.name}`.toLocaleLowerCase() ===
                                                    'no'))
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    <div
                                        style={{
                                            paddingBottom: '16px',
                                        }}
                                    />
                                    {entityObj && (
                                        <InsuranceQuoteSubFormGenerator
                                            entityTypeID={entityTypeID}
                                            data={entityObj}
                                            errors={errors}
                                            setError={setError}
                                            onAnswerChange={onAnswerChange}
                                            field_id={keyFieldID}
                                            mobileSearchPickerSectionURL={
                                                mobileSearchPickerSectionURL
                                            }
                                            sectionID={sectionID}
                                        />
                                    )}
                                </Expander>
                                <div
                                    style={{
                                        paddingBottom: '16px',
                                    }}
                                />
                            </Wrapper>
                        )
                    }
                )}
        </>
    )
}
