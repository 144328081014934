import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import ReadOnlyCarGalleryImage from '../../../templates/readOnly/car/readOnlyGalleryImage'
import { ICar, ICarsObject } from '../../../../redux/entities/cars/types'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'

type CarParams = {
    carid: string
}

const CarGalleryImageArchive = () => {
    const { carid } = useParams<CarParams>()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const carsData: ICarsObject = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let current: ICar | undefined = carsData[`${carid}`]

    const dispatch = useAppDispatch()
    const location = useLocation()
    let params = new URLSearchParams(location.search)
    let image_id_param = params.get('id')

    useEffect(() => {
        // if (!current) {
        // for now always get on render ( we'll add the has_limited_garage_info check tho)
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/archives/car/${carid}/gallery?id=${image_id_param}`
            )
        )
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            dispatch(carActions.setCurrentCar(`${carid}`))
            dispatch(carActions.getCarDataByIdRequest(`${carid}`))
        }

        // }
    }, [userLoggedIn])

    return (
        <React.Fragment>
            {userLoggedIn && <ReadOnlyCarGalleryImage prevOwned={current} />}
        </React.Fragment>
    )
}

export default CarGalleryImageArchive
