import { IRelativeTimeData, ItimeType } from 'entityModels'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(utc)

export function getRelativeTimeData(
    dueDate: Date,
    reminderDate: Date
): IRelativeTimeData | undefined {
    let dueDateD = dayjs(dueDate).endOf('day').utc().utcOffset(0).startOf('day')
    let reminderDateD = dayjs(reminderDate)
        .endOf('day')
        .utc()
        .utcOffset(0)
        .startOf('day')

    let dayjsFinalDate = reminderDateD.from(dueDateD, true)

    if (dueDateD.isBefore(dayjs())) {
        return undefined
    }

    if (reminderDateD.isBefore(dayjs())) {
        return undefined
    }

    let nValue: number | undefined = undefined
    let timeType: ItimeType | undefined = undefined

    if (dayjsFinalDate.includes('month')) {
        timeType = 'month(s)'
    } else if (dayjsFinalDate.includes('week')) {
        timeType = 'week(s)'
    } else if (dayjsFinalDate.includes('day')) {
        timeType = 'day(s)'
    }

    let nReg = /\d+/g
    let matchedN = nReg.exec(dayjsFinalDate)

    if (matchedN) {
        nValue = parseInt(matchedN[0])
    }

    if (nValue && nValue % 7 === 0 && timeType === 'day(s)') {
        nValue = nValue / 7
        timeType = 'week(s)'
    }

    if (!nValue && timeType !== undefined) {
        nValue = 1
    }

    if (nValue && timeType) {
        return {
            n: nValue,
            timeType: timeType,
        }
    }
}
