import colors from '../../../../../redux/localdata/formslist/car/colors.json'
import vehicle_condition from '../../../../../redux/localdata/formslist/car/vehicle_condition.json'
import powertype from '../../../../../redux/localdata/formslist/car/powertype.json'
import BasicTechInfoEditFormDesktop from '../basic/basicTechInfoEditFormDesktop'
import CarTechInfoEditFormSelectSearchDesktop from '../searchselect/desktop/carTechInfoEditFormSelectSearchDesktop'
import DatePickerTechInfoEditFormDesktop from '../../technicalInformationEditForm/datepicker/desktop/datePickerTechInfoEditFormDesktop'
import NumberTechInfoEditFormDesktop from '../../technicalInformationEditForm/number/desktop/numberTechInfoEditFormDesktop'
import drive_train_options from '../../../../../redux/localdata/formslist/car/drive_train_options.json'
import DuoRadioTechInfoEditFormDesktop from '../../technicalInformationEditForm/duoradio/desktop/duoRadioTechInfoEditFormDesktop'
import CarTechInfoEditFormMultipleSelectSearchDesktop from '../../technicalInformationEditForm/multipleselect/desktop/carTechInfoEditFormMultipleSelectSearchDesktop'
import features from '../../../../../redux/localdata/formslist/car/features.json'
import CarMakeCustomFormDesktop from '../custom/carMakeCustomForm/carMakeCustomFormDesktop'
import CarModelCustomFormDesktop from '../custom/carModelCustomForm/carModelDesktopFormDesktop'
import CarYearCustomFormDesktop from '../custom/carYearCustomForm/carYearDesktopFormDesktop'
import CarBodyTypeCustomDesktop from '../custom/carBodyTypeCustom/carBodyTypeCustomDesktop'
import CarTransmissionTypeCustomDesktop from '../custom/carTransmissionTypeCustom/carTransmissionTypeCustomDesktop'
import {
    IList,
    IwriteMultipleTechnicalInformationPayloadReq,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { IRootState } from '../../../../../redux/store'
import { useEffect, useMemo } from 'react'
import { technicalInformationActions } from '../../../../../redux/entities/technical_information/reducer'

export interface Props {
    item: IList | undefined
    toggle: () => void
    id: string
    allTechInfodata: any
    carid: string
}

const drive_side_options = [
    {
        name: 'LHD',
    },
    {
        name: 'RHD',
    },
    {
        name: 'Center',
    },
]

const road_tax_status_options = [
    {
        name: 'Taxed',
    },
    {
        name: 'Untaxed',
    },
    {
        name: 'SORN',
    },
    {
        name: 'NA',
    },
    {
        name: '-',
    },
]
const mot_status_options = [
    {
        name: 'Valid',
    },
    {
        name: 'Not Valid',
    },
    {
        name: 'NA',
    },
    {
        name: '-',
    },
]

const TechInfoFormManagerDesktop: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch()

    const { item, allTechInfodata, id, carid, toggle } = props

    const yearsdropdowndata = useAppSelector(
        (state: IRootState) => state.localdata.dropdownData.carYears
    )
    const body_types_list = useAppSelector(
        (state: IRootState) => state.localdata.dropdownData.carBodyTypes
    )
    const transmission_types_list = useAppSelector(
        (state: IRootState) => state.localdata.dropdownData.carTransmissionTypes
    )
    const makesdropdowndata = useAppSelector(
        (state: IRootState) => state.localdata.dropdownData.carMakes
    )

    const loading = useAppSelector(
        (state: IRootState) => state.localdata.dropdownData.loading
    )

    const user_fuel_unit_ids_list = useAppSelector(
        (state: IRootState) =>
            state.user.userLoggedIn?.preferences?.data?.units?.data?.fuel_unit
                .ids_list
    )
    const user_fuel_unit_chosen_id = useAppSelector(
        (state: IRootState) =>
            state.user.userLoggedIn?.preferences?.data?.units?.data?.fuel_unit
                .user_choice_id
    )
    const user_fuel_unit_data = useAppSelector(
        (state: IRootState) =>
            state.user.userLoggedIn?.preferences?.data?.units?.data?.fuel_unit
                .data
    )

    const user_distance_unit_ids_list = useAppSelector(
        (state: IRootState) =>
            state.user.userLoggedIn?.preferences?.data?.units?.data
                ?.distance_unit.ids_list
    )
    const user_distance_unit_chosen_id = useAppSelector(
        (state: IRootState) =>
            state.user.userLoggedIn?.preferences?.data?.units?.data
                ?.distance_unit.user_choice_id
    )
    const user_distance_unit_data = useAppSelector(
        (state: IRootState) =>
            state.user.userLoggedIn?.preferences?.data?.units?.data
                ?.distance_unit.data
    )

    useEffect(() => {
        if (item) {
            if (item.id === 'make') {
                if (!makesdropdowndata) {
                    dispatch(dropDownActions.setMakeDropdownDataRequest())
                }

                if (!yearsdropdowndata) {
                    dispatch(
                        dropDownActions.setYearDropdownDataRequest(undefined)
                    )
                }
                if (item.answer && typeof item.answer === 'string') {
                    dispatch(
                        dropDownActions.setModelDropdownDataRequest({
                            makeName: item.answer,
                            make_uid: item.uid_current,
                        })
                    )
                }
            }
            if (item.id === 'model') {
                if (item.answer) {
                    if (allTechInfodata.make.answer) {
                        dispatch(dropDownActions.setMakeDropdownDataRequest())
                        dispatch(
                            dropDownActions.setModelDropdownDataRequest({
                                makeName: allTechInfodata.make.answer,
                                make_uid: item.uid_current,
                            })
                        )
                    }
                }
                if (!yearsdropdowndata) {
                    dispatch(
                        dropDownActions.setYearDropdownDataRequest(undefined)
                    )
                }
            }

            if (item.id === 'year') {
                if (!yearsdropdowndata) {
                    dispatch(
                        dropDownActions.setYearDropdownDataRequest(undefined)
                    )
                }
            }

            if (item.id === 'body_type') {
                dispatch(dropDownActions.setBodyTypeDropdownDataRequest())
            }
            if (item.id === 'transmission') {
                dispatch(
                    dropDownActions.setTransmissionTypesDropdownDataRequest()
                )
            }
        }
    }, [item, allTechInfodata, makesdropdowndata, yearsdropdowndata])

    const distance_units: IList[] | null | undefined = useMemo(
        () =>
            user_distance_unit_ids_list &&
            user_distance_unit_ids_list.map((id: string) => {
                return user_distance_unit_data![id]
            }),
        [user_distance_unit_ids_list, user_distance_unit_data]
    )

    const user_default_distance_unit: IList | null = useMemo(
        () =>
            user_distance_unit_data && user_distance_unit_chosen_id
                ? user_distance_unit_data[user_distance_unit_chosen_id]
                : null,
        [user_distance_unit_data, user_distance_unit_chosen_id]
    )

    const fuel_units: IList[] | null | undefined = useMemo(
        () =>
            user_fuel_unit_ids_list &&
            user_fuel_unit_ids_list.map((id: string) => {
                return user_fuel_unit_data![id]
            }),
        [user_fuel_unit_ids_list, user_fuel_unit_data]
    )

    const user_default_fuel_unit: IList | null = useMemo(
        () =>
            user_fuel_unit_data && user_fuel_unit_chosen_id
                ? user_fuel_unit_data[user_fuel_unit_chosen_id]
                : null,
        [user_fuel_unit_data, user_fuel_unit_chosen_id]
    )

    const renderForm = (
        item: any,
        toggle: any,

        id: string,
        allTechInfodata: any,
        carid: string,
        distance_units: IList[],
        fuel_units: IList[]
    ) => {
        let distanceUnitInfo = {
            list: distance_units,
            id: 'distance_units',
            user_default_unit: user_default_distance_unit?.id,
        }

        let fuelUnitInfo = {
            list: fuel_units,
            id: 'fuel_units',
            user_default_unit: user_default_fuel_unit?.id,
        }

        switch (item.id) {
            case 'mileage':
                return (
                    <NumberTechInfoEditFormDesktop
                        isUnits={true}
                        unitInfo={distanceUnitInfo}
                        unitList={distance_units}
                        item={item}
                        toggle={toggle}
                        tech_info_id={id}
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        carid={carid}
                    />
                )

            case 'engine_capacity':
                return (
                    <NumberTechInfoEditFormDesktop
                        isUnits={true}
                        unitInfo={fuelUnitInfo}
                        unitList={fuel_units}
                        item={item}
                        toggle={toggle}
                        tech_info_id={id}
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        carid={carid}
                    />
                )

            case 'drive_train':
                return (
                    <DuoRadioTechInfoEditFormDesktop
                        optionsList={drive_train_options}
                        item={item}
                        toggle={toggle}
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        carid={carid}
                    />
                )

            case 'transmission':
                return (
                    <CarTransmissionTypeCustomDesktop
                        optionsList={transmission_types_list}
                        item={item}
                        toggle={toggle}
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        carid={carid}
                    />
                )

            case 'make':
                return (
                    <CarMakeCustomFormDesktop
                        submitFunc={(
                            p: IwriteMultipleTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeMultipleTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        toggle={toggle}
                        make_item={
                            allTechInfodata && allTechInfodata.make
                                ? allTechInfodata.make
                                : undefined
                        }
                        model_item={
                            allTechInfodata && allTechInfodata.model
                                ? allTechInfodata.model
                                : undefined
                        }
                        year_item={
                            allTechInfodata && allTechInfodata.year
                                ? allTechInfodata.year
                                : undefined
                        }
                        carid={carid}
                        isLoading={loading}
                        isLoadingNewMakeOrModel={loading}
                    />
                )

            case 'model':
                return (
                    <CarModelCustomFormDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        toggle={toggle}
                        model_item={
                            allTechInfodata && allTechInfodata.model
                                ? allTechInfodata.model
                                : undefined
                        }
                        year_item={
                            allTechInfodata && allTechInfodata.year
                                ? allTechInfodata.year
                                : undefined
                        }
                        make_item={
                            allTechInfodata && allTechInfodata.make
                                ? allTechInfodata.make
                                : undefined
                        }
                        carid={carid}
                    />
                )

            case 'year':
                return (
                    <CarYearCustomFormDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        toggle={toggle}
                        year_item={allTechInfodata.year}
                        model_item={allTechInfodata.model}
                        carid={carid}
                        setCustomItemInSpecifiedTargettedDropdown={undefined}
                    />
                )

            case 'body_type':
                return (
                    <CarBodyTypeCustomDesktop
                        submitFunc={(
                            p: IwriteMultipleTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeMultipleTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={body_types_list}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'color_exterior':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={colors}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
            case 'features':
                return (
                    <CarTechInfoEditFormMultipleSelectSearchDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={features}
                        item={item}
                        toggle={toggle}
                    />
                )
            case 'condition':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={vehicle_condition}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
            case 'color_interior':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={colors}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'tax_status':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={road_tax_status_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'service_check_status':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={mot_status_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'drive_side':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={drive_side_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'fuel_type':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={powertype}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'registration_date':
                return (
                    <DatePickerTechInfoEditFormDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'service_check_expiry':
                return (
                    <DatePickerTechInfoEditFormDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'tax_due':
                return (
                    <DatePickerTechInfoEditFormDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'last_owner_change':
                return (
                    <DatePickerTechInfoEditFormDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            default:
                return (
                    <BasicTechInfoEditFormDesktop
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
        }
    }

    return (
        <>
            {distance_units &&
                fuel_units &&
                renderForm(
                    item,
                    toggle,
                    id,
                    allTechInfodata,
                    carid,
                    distance_units,
                    fuel_units
                )}

            <div />
        </>
    )
}

export default TechInfoFormManagerDesktop
