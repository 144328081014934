import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    useAppSelector,
    useAppDispatch,
    useThunkAppDispatch,
} from '../../../redux/store/hooks'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import CreationTopHeaderDesktop from '../../molecules/entryPageTopHeader/creationTopHeaderDesktop'
import PrivateShareFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareFormManagerDesktop'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import PrivateShareFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareFormManagerMobile'
import BottomSheetPage from '../../templates/bottomSheetPages.tsx/bottomSheetPage'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import {
    ICar,
    ICreateDirectShareRequest,
    IDirectShareOwnedItem,
    ISharingPermissionIDS,
} from '../../../redux/entities/cars/types'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'

const CarShareCreate: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const carsData = useAppSelector((state) => state.entities.carsData.cars)

    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )

    const [share_being_edited, setShare_being_edited] =
        useState<IDirectShareOwnedItem>({
            entity_id: '',
            title: '',
            active_permission_ids: ['car gallery'] as any,
            id: 'share_being_edited',
            type: 'car',
            created_at: '',
            updated_at: '',
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            total_recipients: 0,
        })

    const dispatchThunks = useThunkAppDispatch()

    const [isMobileCreationOpen, setIsMobileCreationOpen] = useState(false)

    useEffect(() => {
        const carid = searchParams.get('carid')
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/sharing/with-others/car/create-share?carid=${carid}`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else if (carid) {
            dispatchThunks(carActions.setCurrentCar(carid))
            dispatch(carActions.getCarDataByIdRequest(`${carid}`))
        }

        const handleResize = () => {
            setIsMobileCreationOpen(window.innerWidth < 881)
        }

        window.addEventListener('resize', handleResize, { passive: true })
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [userLoggedIn, searchParams])

    const handleChangePermissions = (permissionID: ISharingPermissionIDS) => {
        setShare_being_edited((prevState) => {
            const permissions_arr = prevState.active_permission_ids

            if (permissions_arr.includes(permissionID)) {
                return {
                    ...prevState,
                    active_permission_ids: permissions_arr.filter(
                        (id) => permissionID !== id
                    ),
                }
            } else {
                return {
                    ...prevState,
                    active_permission_ids: [...permissions_arr, permissionID],
                }
            }
        })
    }

    const handleSubmitToBE = () => {
        const carid = searchParams.get('carid')
        const current: ICar | undefined =
            carid && carsData[carid] ? carsData[carid] : undefined

        if (carid) {
            const payload: ICreateDirectShareRequest = {
                resource_id: carid,
                permissionIDS: share_being_edited.active_permission_ids,
                title:
                    current && current.title
                        ? `Direct share for ${current.title}`
                        : 'Untitled',
                resource_type: 'car',
                share_type: 'direct_share',
            }

            dispatch(carActions.createCarDirectShareRequest(payload))
        }
    }

    const carid = searchParams.get('carid')
    const current: ICar | undefined =
        carid && carsData[carid] ? carsData[carid] : undefined

    return (
        <React.Fragment>
            <DesktopDisplayOnly>
                <CenteredPageWrapper fullwidth>
                    {current && share_being_edited && (
                        <WindowPageTemplate
                            isCollapsed={isCollapsed}
                            stickyHeaderContent={
                                <CreationTopHeaderDesktop
                                    onClose={() =>
                                        navigate(
                                            `/sharing/with-others/car?carid=${carid}`
                                        )
                                    }
                                    text={'Add Car Direct Share'}
                                />
                            }
                        >
                            <PrivateShareFormManagerDesktop
                                share_being_edited={share_being_edited}
                                handleSubmit={handleSubmitToBE}
                                handlePermissions={handleChangePermissions}
                                formType="create"
                            />
                        </WindowPageTemplate>
                    )}
                </CenteredPageWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <BottomSheetPage
                    isOpen={isMobileCreationOpen}
                    toggle={() => setIsMobileCreationOpen(false)}
                >
                    <PrivateShareReturnNav
                        customText="Add car direct share"
                        chevron
                        linkTo={`/sharing/with-others/car`}
                        params={`?carid=${carid}`}
                    />
                    <div style={{ paddingTop: '60px' }} />
                    <PrivateShareFormManagerMobile
                        share_being_edited={share_being_edited}
                        handleSubmit={() => {
                            handleSubmitToBE()
                            setIsMobileCreationOpen(false)
                            navigate(`/sharing/with-others/car?carid=${carid}`)
                        }}
                        handlePermissions={handleChangePermissions}
                        formType="create"
                    />
                    <div style={{ paddingTop: '150px' }} />
                </BottomSheetPage>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default CarShareCreate
