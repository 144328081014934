import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import Loader from '../../../atoms/loader/loader'
import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'
import { useNavigate, useLocation } from 'react-router-dom'
import OnboardingFormLayoutMobile, {
    OnboardingText,
    PaddingUnit,
} from '../../../templates/onboarding/onboardingFormLayoutMobile'
import { isIOS } from 'react-device-detect'
import OnboardingLayoutDesktop from '../../../templates/onboarding/desktop/onboardingLayoutDesktop'
import OnboardingFormTemplateDesktop from '../../../templates/onboarding/desktop/onboardingFormTemplateDesktop'
import OnboardingLastStepContent from '../../../molecules/onboarding/new/onboardingLastStepContent'
import OnboardingLastStepRightSideDesktop from '../../../molecules/onboarding/new/onboardingLastStepRightSideDesktop'
import { usersActions } from '../../../../redux/user/reducer'

const OnboardingStep_4 = () => {
    const dispatch = useAppDispatch()
    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let carid = queryParams.get('carid')

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    let userLoading = useAppSelector((state) => state.user.loading)

    let onboardingStep4Data = useAppSelector(
        (state) => state.localdata.onboardingContent.content[4]
    )

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/onboarding-step-4?carid=${carid}`
            )
        )
    }, [])

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    let navigate = useNavigate()

    const [mediaActiveIndex, setMediaActiveIndex] = useState(0)

    return userLoading ? (
        <Loader isLoading />
    ) : (
        <React.Fragment>
            <DesktopDisplayOnly>
                <OnboardingLayoutDesktop
                    step={4}
                    title={onboardingStep4Data.title}
                    isStepsBarRemoved={true}
                    rightSideContent={
                        <OnboardingLastStepRightSideDesktop
                            activeIndex={mediaActiveIndex}
                        />
                    }
                >
                    <PaddingUnit $times={3} />
                    <OnboardingText>
                        {onboardingStep4Data.text1}
                        <br />
                        {onboardingStep4Data.text2}
                    </OnboardingText>
                    <OnboardingFormTemplateDesktop
                        isLast={true}
                        submit={() => {
                            navigate(`/car/${carid}?native_app_prompt=true`)
                        }}
                        customSubmitText="Go to your car page"
                        goToWebsite
                    >
                        <PaddingUnit $times={5} />
                        <OnboardingLastStepContent
                            list={onboardingStep4Data.data}
                            setActiveIndex={setMediaActiveIndex}
                            activeIndex={mediaActiveIndex}
                        />
                        <PaddingUnit $times={1} />
                    </OnboardingFormTemplateDesktop>
                </OnboardingLayoutDesktop>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                {carid ? (
                    <SideSliderWithExit
                        isVisible={carid && !userLoading ? true : false}
                        direction={'right'}
                        initial="enter"
                        customDuration={0.5}
                        noOverflow
                    >
                        {carid && (
                            <OnboardingFormLayoutMobile
                                step={4}
                                isLast={true}
                                customSubmitText="Go to your car page"
                                submit={() => {
                                    isIOS
                                        ? navigate(
                                              `/car/${carid}?native_app_prompt=true`
                                          )
                                        : navigate(
                                              `/car/${carid}?tutorial=true`
                                          )
                                }}
                                goToWebsite
                                title={onboardingStep4Data.title}
                                centeredTitle
                            >
                                <PaddingUnit $times={3} />
                                <OnboardingText
                                    style={{
                                        textAlign: 'center',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    {onboardingStep4Data.text1}
                                    <br />
                                    {onboardingStep4Data.text2}
                                </OnboardingText>
                                <PaddingUnit $times={5} />
                                <OnboardingLastStepContent
                                    list={onboardingStep4Data.data}
                                />
                            </OnboardingFormLayoutMobile>
                        )}
                    </SideSliderWithExit>
                ) : null}
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default OnboardingStep_4
