// INSERT_YOUR_REWRITE_HERE

import React from 'react'

import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    box-sizing: border-box;
    @media ${device.beyond_ipad_mobile} {
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100%;
    box-sizing: border-box;
`

const Header = styled.div`
    font-family: lato-light;
    font-size: 23px;
    text-transform: uppercase;
    width: 350px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.large_desktop} {
        font-size: 24px;
        width: 370px;
    }
`

const Title = styled.div`
    font-family: lato;
    font-size: 29px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.large_desktop} {
        font-size: 30px;
    }
`
const Line = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

type Props = {
    title: string
}

const ApexSingleArticleHeaderDesktop: React.FC<Props> = ({ title }) => {
    return (
        <Wrapper>
            <Row>
                <Header>The Apex Articles</Header>
                <Line />
            </Row>
            <div style={{ paddingTop: '25px' }} />

            <Title>{title}</Title>
        </Wrapper>
    )
}

export default ApexSingleArticleHeaderDesktop
