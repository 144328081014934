import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowMobile from '../../buttons/buttonsStandardBottomRowMobile'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../providers/theme/hooks'

const SmallPadding = styled.div`
    padding-top: 30px;
`

const LargePadding = styled.div`
    padding-top: 60px;
`

interface Values {
    value_answer: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: any
    taskid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let checkIfDisabled = () => {
        if (!values.value_answer) {
            return true
        } else if (errors.value_answer !== undefined) {
            return true
        } else return false
    }
    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>{item.text}</ModalTopBar>
            <ModalInnerContentWrapperMobile>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id="value_answer"
                    name="value_answer"
                    placeholder={
                        item.text === 'Edit task title' ? 'Title' : item.text
                    }
                    helperText="[up to 70 characters]"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.value_answer}
                    font_family="Lato"
                    type="text"
                    onEnterKeyPress={props.handleSubmit}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />

                <LargePadding />
                <ButtonsStandardBottomRowMobile
                    cancel={toggle}
                    submit={props.handleSubmit}
                    confirmationtext="save"
                    disabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const BasicTaskFieldFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        value_answer: props.item.answer ? props.item.answer : '-',
    }),
    // validationSchema: {},

    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                value_answer: Yup.string().max(70, 'over70').min(2, 'under2'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
        props.submitFunc &&
            props.submitFunc({
                taskid: props.taskid,
                dataList: [
                    {
                        id: props.item.id,
                        text: props.item.text,
                        answer: values.value_answer,
                    },
                ],
            })
        props.toggle()
    },
})(InnerForm)

export default BasicTaskFieldFormMobile
