import { call, fork, put, take, select } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { api } from '../../services'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
import {
    IDirectSharesReceivedObj,
    IUser_sharesReceived,
    IUser_SharesReceived_byEntity,
} from '../types'
import { usersActions } from '../reducer'
import { IUpdateShareReveivedStatusApi_arg } from '../../services/types'
import { IRootState } from '../../store'
import {
    IPrivateShareReceivedItem,
    IReduxError,
} from '../../entities/cars/types'

const getReceivedSharesState = (state: IRootState) =>
    state.user.userLoggedIn && state.user.userLoggedIn.received_direct_shares

export function* UpdateShareReceivedStatusSaga(
    p: IUpdateShareReveivedStatusApi_arg
) {
    try {
        yield call(api.sharing.updateShareReceivedStatusApi, p)

        let receivedSharesState: IUser_sharesReceived | undefined =
            yield select(getReceivedSharesState)

        let currentReceivedShare: IPrivateShareReceivedItem | undefined =
            undefined

        let receivedSharesData: IDirectSharesReceivedObj | undefined = undefined

        let cars_shares_ids: string[] = []

        let accepted_cars_shares_ids: string[] = []

        let ignored_cars_shares_ids: string[] = []

        let pending_cars_shares_ids: string[] = []

        if (p.entity_type === 'car') {
            receivedSharesData =
                receivedSharesState &&
                receivedSharesState.cars &&
                receivedSharesState.cars.shares_received_data
                    ? { ...receivedSharesState.cars.shares_received_data }
                    : undefined

            currentReceivedShare =
                receivedSharesState &&
                receivedSharesState.cars &&
                receivedSharesState.cars.shares_received_data &&
                receivedSharesState.cars.shares_received_data[p.share_id]
                    ? {
                          ...receivedSharesState.cars.shares_received_data[
                              p.share_id
                          ],
                      }
                    : undefined

            cars_shares_ids = [
                ...(receivedSharesState &&
                receivedSharesState.cars &&
                receivedSharesState.cars.shares_received_ids
                    ? receivedSharesState.cars.shares_received_ids
                    : []),
            ]

            accepted_cars_shares_ids = [
                ...(receivedSharesState &&
                receivedSharesState.cars &&
                receivedSharesState.cars.accepted_shares_received_ids
                    ? receivedSharesState.cars.accepted_shares_received_ids
                    : []),
            ]

            ignored_cars_shares_ids = [
                ...(receivedSharesState &&
                receivedSharesState.cars &&
                receivedSharesState.cars.ignored_shares_received_ids
                    ? receivedSharesState.cars.ignored_shares_received_ids
                    : []),
            ]

            pending_cars_shares_ids = [
                ...(receivedSharesState &&
                receivedSharesState.cars &&
                receivedSharesState.cars.pending_shares_received_ids
                    ? receivedSharesState.cars.pending_shares_received_ids
                    : []),
            ]
        } else {
            receivedSharesData =
                receivedSharesState &&
                receivedSharesState.garages &&
                receivedSharesState.garages.shares_received_data
                    ? { ...receivedSharesState.garages.shares_received_data }
                    : undefined

            currentReceivedShare =
                receivedSharesState &&
                receivedSharesState.garages &&
                receivedSharesState.garages.shares_received_data &&
                receivedSharesState.garages.shares_received_data[p.share_id]
                    ? {
                          ...receivedSharesState.garages.shares_received_data[
                              p.share_id
                          ],
                      }
                    : undefined

            cars_shares_ids = [
                ...(receivedSharesState &&
                receivedSharesState.garages &&
                receivedSharesState.garages.shares_received_ids
                    ? receivedSharesState.garages.shares_received_ids
                    : []),
            ]

            accepted_cars_shares_ids = [
                ...(receivedSharesState &&
                receivedSharesState.garages &&
                receivedSharesState.garages.accepted_shares_received_ids
                    ? receivedSharesState.garages.accepted_shares_received_ids
                    : []),
            ]

            ignored_cars_shares_ids = [
                ...(receivedSharesState &&
                receivedSharesState.garages &&
                receivedSharesState.garages.ignored_shares_received_ids
                    ? receivedSharesState.garages.ignored_shares_received_ids
                    : []),
            ]

            pending_cars_shares_ids = [
                ...(receivedSharesState &&
                receivedSharesState.garages &&
                receivedSharesState.garages.pending_shares_received_ids
                    ? receivedSharesState.garages.pending_shares_received_ids
                    : []),
            ]
        }

        if (currentReceivedShare && receivedSharesData) {
            if (p.status === 'accepted') {
                currentReceivedShare.approvalStatus = 'accepted'
                receivedSharesData[p.share_id] = currentReceivedShare
                accepted_cars_shares_ids.push(p.share_id)

                pending_cars_shares_ids = pending_cars_shares_ids
                    ? pending_cars_shares_ids.filter((id) => id !== p.share_id)
                    : []
                ignored_cars_shares_ids = ignored_cars_shares_ids
                    ? ignored_cars_shares_ids.filter((id) => id !== p.share_id)
                    : []
            } else if (p.status === 'ignored') {
                currentReceivedShare.approvalStatus = 'ignored'
                receivedSharesData[p.share_id] = currentReceivedShare
                // add to ignored ids aray
                ignored_cars_shares_ids.push(p.share_id)

                // remove from pending ids array
                pending_cars_shares_ids = pending_cars_shares_ids
                    ? pending_cars_shares_ids.filter((id) => id !== p.share_id)
                    : []
            } else if (p.status === 'rejected') {
                // remove from data

                if (receivedSharesData && receivedSharesData[p.share_id]) {
                    delete receivedSharesData[p.share_id]

                    // remove from pending ids array
                    pending_cars_shares_ids = pending_cars_shares_ids
                        ? pending_cars_shares_ids.filter(
                              (id) => id !== p.share_id
                          )
                        : []

                    ignored_cars_shares_ids = ignored_cars_shares_ids
                        ? ignored_cars_shares_ids.filter(
                              (id) => id !== p.share_id
                          )
                        : []

                    cars_shares_ids = cars_shares_ids
                        ? cars_shares_ids.filter((id) => id !== p.share_id)
                        : []
                }
            }

            let successPayload: {
                entity_type: string
                shares_received: IUser_SharesReceived_byEntity
            } = {
                entity_type: p.entity_type,
                shares_received: {
                    shares_received_ids: cars_shares_ids ? cars_shares_ids : [],
                    accepted_shares_received_ids: accepted_cars_shares_ids
                        ? accepted_cars_shares_ids
                        : [],
                    pending_shares_received_ids: pending_cars_shares_ids
                        ? pending_cars_shares_ids
                        : [],
                    ignored_shares_received_ids: ignored_cars_shares_ids
                        ? ignored_cars_shares_ids
                        : [],
                    shares_received_data: receivedSharesData
                        ? receivedSharesData
                        : {},
                },
            }

            yield put(
                usersActions.updateShareReceivedStatusSuccess(successPayload)
            )
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: UpdateShareReceivedStatusSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(usersActions.updateShareReceivedStatusError(typedError))
            return
        }
    }
}

function* watcherUpdateShareReceivedStatus() {
    while (true) {
        let { payload } = yield take(
            usersActions.updateShareReceivedStatusRequest
        )
        yield call(UpdateShareReceivedStatusSaga, payload)
    }
}

const update_share_received_status_sagas: any[] = [
    fork(watcherUpdateShareReceivedStatus),
]

export default update_share_received_status_sagas
