import React, { useState, useEffect } from 'react'
import * as FieldChoices from '../../../molecules/editOrCreateModeSingleFields'
import { TimelineFormTypeIDsEnum } from '../../../../redux/timeline/timelineEnum'
import EditModeTopHeaderMobile from '../../../atoms/header/editModeTopHeader/editModeTopHeaderMobile'
import {
    timeline_category_data,
    currency_data,
    currency_IDS,
} from '../../../../redux/timeline/data'
import LabelsFilterByMultipleWSearchWFuseMobile from '../../filterByMultiple/labels/labelsFilterByMultipleWSearchWFuseMobile'
import { IDropdownItem } from 'entityModels'
import { device } from '../../../templates/displays/devices'
import { useParams } from 'react-router-dom'
import * as unitGenerator from '../../../../helpers/units/unitConversion'
import styled from 'styled-components'
import InputField from '../../../atoms/Inputfield/inputField'
import CalendarIcon from '../../../atoms/icons/components/calendar'
import dayjs from 'dayjs'
import { ITheme } from '../../../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { ITimelineEditForms } from '../../../../redux/editForms/types'
import { ITimelineItem, ICostItem } from '../../../../redux/timeline/types'
import { IEditOrCreateModeSingleFieldsProps } from '../../../molecules/editOrCreateModeSingleFields/types'
import { carActions } from '../../../../redux/entities/cars/reducer'
import useThemes from '../../../../providers/theme/hooks'

const Wrapper = styled.div`
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
    min-height: 90vh;
    background-color: var(--bg-color, #fff);
    top: 50px;
    left: 0px;
    z-index: 9;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    /* height: 95vh; */
    @media ${device.ipad} {
        padding-top: 50px;
        padding-right: 50px;
    }
`

const InputWraper = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;

    @media ${device.ipad} {
        padding-left: 25px;
    }
`

interface Props {
    item: ITimelineItem | ICostItem
    edited_item: ITimelineItem | ICostItem | null
    timelineItemEditForm: ITimelineEditForms
    labels_searchable_list?: IDropdownItem[] | null | undefined
    onClickSave: () => void
    onDeleteClick?: () => void
    onChange: (
        fieldID: keyof ITimelineItem | keyof ICostItem,
        newValue: any
    ) => void
    closeForm: () => void
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    updateCarMileage?: (payload: {
        updateCarMileage: boolean
        value: number
        user_distance_unit: string
    }) => void
}

type AnyObj = {
    [key: string]: any
}

type IisMandatoryFieldObj = {
    [key: string]: boolean
}

const TimelineGenerateEditFormModeMobile: React.FC<Props> = ({
    item,
    edited_item,
    timelineItemEditForm,
    labels_searchable_list,
    onClickSave,
    onDeleteClick,
    onChange,
    closeForm,
    dataCyId,
    entityID,
    carID,
    entityType,
    updateCarMileage,
}) => {
    const dispatch = useAppDispatch()
    const { carid } = useParams<{ carid: string }>()

    const [isHeaderActive, setIsHeaderActive] = useState(false)
    const [errorObject, setErrorObject] = useState<AnyObj>({})

    const fieldsList = useAppSelector(
        (state) => state.timeline.formsData.fieldsList
    )
    const attachmentsObj = useAppSelector(
        (state) => state.attachments.attachmentsById
    )
    const userCurrency = useAppSelector(
        (state) =>
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id
    )
    const technicalInformationData = useAppSelector(
        (state) => state.entities.technicalInformationData.technical_information
    )
    const userDistanceUnit = useAppSelector(
        (state) =>
            state.user.userLoggedIn?.preferences.data.units.data.distance_unit
                .user_choice_id
    )
    const dataLoading = useAppSelector((state) => state.timeline.loading)
    const deletionInProgress = useAppSelector(
        (state) => state.timeline.deletionInProgress
    )

    useEffect(() => {
        window.scrollTo(0, 0)

        const tech_info_in_state =
            technicalInformationData && technicalInformationData[`hf-${carid}`]
        if (!tech_info_in_state && carid) {
            dispatch(carActions.getCarDataByIdRequest(`${carid}`))
        }
    }, [])

    useEffect(() => {
        checkIfDataHasBeenChanged()
    }, [edited_item])

    const checkIfDataHasBeenChanged = (): boolean => {
        if (edited_item == null) {
            return false
        } else {
            const obj1Length = Object.keys(item).length
            const obj2Length = Object.keys(edited_item).length

            if (obj1Length === obj2Length) {
                let n = Object.keys(item).every(
                    (key) =>
                        edited_item !== null &&
                        edited_item.hasOwnProperty(key) &&
                        edited_item[key] === item[key]
                )

                setIsHeaderActive(!n)
                return !n
            }
            setIsHeaderActive(true)

            return true
        }
    }

    const generatePlaceholder = (fieldId: string): string => {
        if (
            timeline_category_data[item.categoryID] &&
            timeline_category_data[item.categoryID].placeholders &&
            timeline_category_data[item.categoryID].placeholders
        ) {
            let placeholders =
                timeline_category_data[item.categoryID].placeholders

            return placeholders ? placeholders[fieldId] : ''
        } else return ''
    }

    const determineErrors = (fieldId: string, error: boolean) => {
        setErrorObject((prevState) => ({
            ...prevState,
            [fieldId]: error,
        }))
    }

    const isMandatoryFieldObj: IisMandatoryFieldObj = {
        name: true,
        title: true,
        amount: true,
    }

    const generateFields = (fieldId: string, theme: ITheme) => {
        let data: IEditOrCreateModeSingleFieldsProps = {
            value_start:
                fieldId === 'attachment' ||
                fieldId === 'attachment_with_preview'
                    ? attachmentsObj[item.attachmentID]
                        ? attachmentsObj[item.attachmentID]
                        : undefined
                    : fieldId === 'labels'
                    ? undefined
                    : item[fieldId],
            title: `${fieldId}`,
            onChangeFunc: onChange,
            id: fieldId,
            placeholder: generatePlaceholder(fieldId),
            isMandatory: isMandatoryFieldObj[fieldId]
                ? isMandatoryFieldObj[fieldId]
                : false,
        }

        let hasError = (error: boolean) => {
            determineErrors(fieldId, error)
        }

        let carMileageFromState: number | string | undefined | null =
            carid &&
            technicalInformationData &&
            technicalInformationData[`hf-${carid}`] &&
            technicalInformationData[`hf-${carid}`].mileage &&
            technicalInformationData[`hf-${carid}`].mileage.answer

        let carMileage: number | undefined | null =
            typeof carMileageFromState === 'string'
                ? parseInt(carMileageFromState)
                : carMileageFromState

        let convertedCarMileage =
            carMileage !== undefined && carMileage !== null && userDistanceUnit
                ? unitGenerator.generateFrontEndValueDistanceUnit(
                      userDistanceUnit,
                      carMileage
                  )
                : undefined

        switch (fieldId) {
            case 'title': {
                data.charLimit = [2, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'name': {
                data.charLimit = [2, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_underwriter': {
                data.charLimit = [2, 100]
                data.isMandatory = true
                data.title = 'Insurer'
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_broker': {
                data.title = 'Broker (if applicable)'
                data.charLimit = [2, 100]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'company': {
                data.charLimit = [0, 70]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'mileage': {
                data.charLimit = [0, 70]
                data.title = 'Mileage at the time of entry'
                return (
                    <FieldChoices.MileageEditOrCreateModeMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_entry_${fieldId}_field`}
                        customMileageDataCyId={
                            'edit_car_mileage_from_entry_with_custom_value'
                        }
                        hasError={hasError}
                        carMileage={convertedCarMileage}
                        userDistanceUnits={userDistanceUnit}
                        updateCarMileage={updateCarMileage}
                    />
                )
            }
            case 'amount': {
                data.optionName = item.currency
                    ? item.currency
                    : userCurrency
                    ? userCurrency
                    : 'GBP'
                return (
                    <FieldChoices.NumberEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        optionsIDArr={currency_IDS}
                        optionsData={currency_data}
                        optionsFieldID="currency"
                        hasError={hasError}
                    />
                )
            }

            case 'date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                    />
                )

            case 'description':
                data.charLimit = [0, 450]
                return (
                    <FieldChoices.TextareaEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder={data.placeholder}
                        hasError={hasError}
                    />
                )

            case 'attachment':
                return (
                    <FieldChoices.WrapperAttachmentFormMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        entityID={entityID}
                        carID={carID}
                        entityType={entityType}
                    />
                )

            case 'attachment_with_preview':
                return (
                    <FieldChoices.WrapperAttachmentFormMobile
                        data={data}
                        key={`${fieldId}+${item.id}`}
                        dataCyId={`edit_${fieldId}_field`}
                        entityID={entityID}
                        carID={carID}
                        entityType={entityType}
                    />
                )

            case 'notes':
                return (
                    <FieldChoices.EntryNotesFormMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                    />
                )
            case 'labels':
                return (
                    <LabelsFilterByMultipleWSearchWFuseMobile
                        placeholder="Search for title or tag"
                        labels_searchable_list={
                            labels_searchable_list &&
                            labels_searchable_list.length > 0
                                ? labels_searchable_list.concat(
                                      generatePrePopulatedLabels()
                                  )
                                : generatePrePopulatedLabels()
                        }
                        key={`${fieldId}+${item.id}`}
                        editData={data}
                        addToEntry={onChange}
                    />
                )

            case 'insurance_entry_policy_start_date':
                data.title = 'Policy start date'
                return (
                    <InputWraper>
                        <InputField
                            theme={theme}
                            disabled={false}
                            id="insurance_entry_policy_start_date"
                            name="Policy start date"
                            placeholder={'Policy start date'}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            tabindex={1}
                            aria-live="polite"
                            defaultValue={
                                edited_item?.insurance_entry_policy_start_date
                            }
                            value={
                                edited_item?.insurance_entry_policy_start_date
                            }
                            font_family="Lato"
                            restrictFlipOfDatePopUp
                            onChange={(dateString: any) => {
                                onChange(
                                    'insurance_entry_policy_start_date',
                                    dateString
                                )

                                setTimeout(() => {
                                    let newavall = dayjs(
                                        new Date(dateString)
                                    ).isValid()
                                        ? dayjs(new Date(dateString)).add(
                                              1,
                                              'year'
                                          )
                                        : null
                                    onChange(
                                        'insurance_entry_policy_end_date',
                                        newavall
                                    )
                                }, 50)
                            }}
                            type="date"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={'var(--bg-color, #ffffff)'}
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                        />
                    </InputWraper>
                )

            case 'insurance_entry_policy_end_date':
                return (
                    <InputWraper>
                        <InputField
                            theme={theme}
                            disabled={false}
                            id="insurance_entry_policy_end_date"
                            name="'Policy end date'"
                            placeholder={'Policy end date'}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            tabindex={1}
                            aria-live="polite"
                            defaultValue={
                                edited_item?.insurance_entry_policy_end_date
                            }
                            value={edited_item?.insurance_entry_policy_end_date}
                            font_family="Lato"
                            restrictFlipOfDatePopUp
                            onChange={(dateString: any) => {
                                onChange(
                                    'insurance_entry_policy_end_date',
                                    dateString
                                )
                            }}
                            type="date"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={'var(--bg-color, #ffffff)'}
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                        />
                    </InputWraper>
                )

            default:
                return null
        }
    }

    const generatePrePopulatedLabels = (): IDropdownItem[] => {
        let labels_searchable_list_names = labels_searchable_list?.map(
            (label) => label.name
        )

        if (edited_item && edited_item.categoryID) {
            let pre_populated_labels: IDropdownItem[] = timeline_category_data[
                edited_item.categoryID
            ].pre_populated_labels
                .filter(
                    (label) => !labels_searchable_list_names?.includes(label)
                )
                .map((label: string) => ({
                    uid: label,
                    name: label,
                }))
            return pre_populated_labels
        }
        return []
    }

    const field_ids_general_form: undefined | string[] =
        timelineItemEditForm.id &&
        timelineItemEditForm.id === TimelineFormTypeIDsEnum.general &&
        item &&
        fieldsList &&
        timelineItemEditForm.category
            ? fieldsList.fieldsListByCategory[
                  timelineItemEditForm.category
              ].map((fieldID: string, index: number) => {
                  return fieldID
              })
            : undefined

    const field_ids_form: undefined | string[] =
        timelineItemEditForm.id &&
        timelineItemEditForm.id !== TimelineFormTypeIDsEnum.general &&
        item &&
        fieldsList &&
        fieldsList.fieldsListByType[timelineItemEditForm.id]
            ? fieldsList.fieldsListByType[timelineItemEditForm.id].map(
                  (fieldID: string, index: number) => {
                      return fieldID
                  }
              )
            : undefined

    const checkIfSaveIsDisabled = () => {
        let arr: undefined | string[] = field_ids_general_form
            ? field_ids_general_form
            : field_ids_form
            ? field_ids_form
            : undefined

        if (arr) {
            for (const id of arr) {
                let str = id as keyof AnyObj

                let form_field_data =
                    edited_item && edited_item[id] ? edited_item[id] : undefined

                if (errorObject[str] === true) {
                    return true
                }

                if (
                    form_field_data &&
                    form_field_data.length === 0 &&
                    errorObject[str] === undefined &&
                    isMandatoryFieldObj[id] === true
                ) {
                    return true
                }

                if (!form_field_data && isMandatoryFieldObj[id] === true) {
                    return true
                }
            }

            return false
        }

        return false
    }

    let { theme } = useThemes()

    return (
        <>
            <EditModeTopHeaderMobile
                isActive={isHeaderActive}
                editedItemId={timelineItemEditForm.id}
                onChevronClick={closeForm}
                onCheckedClick={() => {
                    onClickSave()
                    isHeaderActive && setIsHeaderActive(false)
                }}
                onDeleteClick={onDeleteClick}
                isSaveDisabled={checkIfSaveIsDisabled()}
                isDataLoading={dataLoading}
                deletionInProgress={deletionInProgress}
            />
            <Wrapper data-attr={dataCyId}>
                {field_ids_general_form &&
                    field_ids_general_form.map(
                        (fieldID: string, index: number) => {
                            return generateFields(fieldID, theme)
                        }
                    )}
                {field_ids_form &&
                    field_ids_form.map((fieldID: string, index: number) => {
                        return generateFields(fieldID, theme)
                    })}
            </Wrapper>
            <div />
        </>
    )
}

export default TimelineGenerateEditFormModeMobile
