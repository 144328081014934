import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ApexReducerState,
    IApexArticleOverviewItemState,
    IApexCarOfTheWeekState,
    IApexHomepageDataState,
    IApexInterviewOverviewsPageData,
    IApexPodscastState,
    IApexWeeklyNewsHighlightState,
    ITaskNormalisedSingleArticleItemState,
    ITaskNormalisedSingleInterviewItemState,
} from 'apexModels'
import { IReduxError } from '../entities/cars/types'

const initialState: ApexReducerState = {
    homepageData: undefined,
    carsOfTheWeekData: undefined,
    apexPodcastsData: undefined,
    weeklyHighlightsData: undefined,
    singleInterviews: {},
    singleArticles: {},
    articleOverviews: undefined,
    loading: false,
    error: null,
}

const apexSlice = createSlice({
    name: 'apex',
    initialState,
    reducers: {
        getApexHomepageContentRequest: (state) => {
            state.loading = true
            state.error = null
        },
        getApexHomepageContentSuccess: (
            state,
            action: PayloadAction<IApexHomepageDataState>
        ) => {
            state.homepageData = action.payload
            state.loading = false
            state.error = null
        },
        getApexHomepageContentError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getApexCarsOfTheWeekRequest: (state) => {
            state.loading = true
            state.error = null
        },
        getApexCarsOfTheWeekSuccess: (
            state,
            action: PayloadAction<IApexCarOfTheWeekState[]>
        ) => {
            state.carsOfTheWeekData = action.payload
            state.loading = false
            state.error = null
        },
        getApexCarsOfTheWeekError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getApexPodcastsRequest: (state) => {
            state.loading = true
            state.error = null
        },
        getApexPodcastsSuccess: (
            state,
            action: PayloadAction<IApexPodscastState[]>
        ) => {
            state.apexPodcastsData = action.payload
            state.loading = false
            state.error = null
        },
        getApexPodcastsError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        getWeeklyHighlightsRequest: (state) => {
            state.loading = true
            state.error = null
        },
        getWeeklyHighlightsSuccess: (
            state,
            action: PayloadAction<IApexWeeklyNewsHighlightState[]>
        ) => {
            state.weeklyHighlightsData = action.payload
            state.loading = false
            state.error = null
        },
        getWeeklyHighlightsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getApexInterviewOverviewsRequest: (state) => {
            state.loading = true
            state.error = null
        },
        getApexInterviewOverviewsSuccess: (
            state,
            action: PayloadAction<IApexInterviewOverviewsPageData>
        ) => {
            state.interviewOverviewsPageData = action.payload
            state.loading = false
            state.error = null
        },
        getApexInterviewOverviewsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getApexSingleInterviewByUIDRequest: (state) => {
            state.loading = true
            state.error = null
        },
        getApexSingleInterviewByUIDSuccess: (
            state,
            action: PayloadAction<ITaskNormalisedSingleInterviewItemState>
        ) => {
            state.singleInterviews = {
                ...state.singleInterviews,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },
        getApexSingleInterviewByUIDError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getApexSingleArticleByUIDRequest: (state) => {
            state.loading = true
            state.error = null
        },

        getApexSingleArticleyUIDSuccess: (
            state,
            action: PayloadAction<ITaskNormalisedSingleArticleItemState>
        ) => {
            state.singleArticles = {
                ...state.singleArticles,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },
        getApexSingleArticleByUIDError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getApexArticleOverviewsRequest: (state) => {
            state.loading = true
            state.error = null
        },
        getApexArticleOverviewsSuccess: (
            state,
            action: PayloadAction<IApexArticleOverviewItemState[]>
        ) => {
            state.articleOverviews = action.payload
            state.loading = false
            state.error = null
        },
        getApexArticleOverviewsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
    },
})

export const apexActions = apexSlice.actions

export default apexSlice.reducer
