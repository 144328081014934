import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertAPIEntryToTimelineItemSingle } from '../../conversions/timeline/entryToTimelineItem'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { AddLabelToEntrySaga } from './entryLabelsUpdates'
import { convertDateToBeFormat } from '../../conversions/time/dates'
import { IRootState } from '../../store'
import { timelineActions } from '../reducer'
import { carActions } from '../../entities/cars/reducer'
import {
    ICreateEntryPayloadAPI,
    IEntryPayloadAPI,
} from '../../services/typedefinitions/apiPayloads'
import { IApiEntryCREATE_args } from '../../services/types'
import {
    IAddTimelineItemPayloadRequest,
    ITimelineItem,
    IAddTimelineItemPayloadSuccess,
    IUpdateEntryLabelPayload,
} from '../types'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'
import { push } from 'redux-first-history'

const get_active_entry_in_state = (state: IRootState) =>
    state.timeline.activeEntry

export const insurance_history_fiel_specific_fields = [
    'insurance_entry_policy_start_date',
    'insurance_entry_policy_end_date',
    'insurance_entry_underwriter',
    'insurance_entry_broker',
]

export function* AddCarTimelineSaga(
    payload: IAddTimelineItemPayloadRequest
): any {
    try {
        // STEP 1 . build up correct payload to send to api => this is the JSON body
        let convertedDate =
            payload.item.date_with_options &&
            convertDateToBeFormat(payload.item.date_with_options)

        let api_payload_req_entry: ICreateEntryPayloadAPI = {
            title: payload.item.title
                ? payload.item.title
                : `${
                      payload.item.categoryID
                          ? payload.item.categoryID
                          : 'Untitled'
                  } Entry`,
            entry_type: payload.item.categoryID
                ? payload.item.categoryID === 'other'
                    ? 'others'
                    : payload.item.categoryID
                : undefined,
            description: payload.item.short_description
                ? payload.item.short_description
                : undefined,
            notes: payload.item.notes ? payload.item.notes : undefined,
            // entry_labels: payload.item.labels ? payload.item.labels : undefined,
            occurred_at: convertedDate ? convertedDate : undefined,
            // etc
        }

        let fields: string[] | undefined = undefined

        if (
            payload.item.categoryID === 'insurance' &&
            payload.item.insurance_entry_policy_start_date
        ) {
            api_payload_req_entry.occurred_at = convertDateToBeFormat(
                payload.item.insurance_entry_policy_start_date
            )

            fields = [...insurance_history_fiel_specific_fields]
        }

        if (fields) {
            for (let i = 0; i < fields.length; i++) {
                if (payload.item[fields[i]]) {
                    api_payload_req_entry = {
                        ...api_payload_req_entry,
                        [fields[i]]: fields[i].includes('date')
                            ? convertDateToBeFormat(payload.item[fields[i]])
                            : payload.item[fields[i]],
                    }
                }
            }
        }

        // STEP 2 . call the api func and keep data returned in a const
        let api_payload: IApiEntryCREATE_args = {
            newEntryData: api_payload_req_entry,
            car_id: payload.carid,
        }
        const newEntry: IEntryPayloadAPI = yield call(
            api.timeline.entries.createEntry.createEntry,
            api_payload
        )

        // STEP 3 . convert API data to reducer data type
        // check what back end returns on console log or network window inspector:
        // sometimes theres a nested object where you get the item after .data,
        // but sometimes it returns straight on the item returned, depends on the function

        let newEntryTimelineItem: ITimelineItem =
            ConvertAPIEntryToTimelineItemSingle(newEntry)
        // STEP 4 . build up payload to return to reducer
        let res: IAddTimelineItemPayloadSuccess = {
            carid: payload.carid,
            item: newEntryTimelineItem,
        }
        // STEP 5 . send success action with payload above to redux
        yield put(timelineActions.addTimelineItemToCarSuccess(res))

        yield put(timelineActions.setActiveEntrySuccess(res.item))

        //update car entries count
        yield put(
            carActions.updatCarEntriesCount({
                carid: payload.carid,
                update: 'add',
            })
        )

        // make it so that it gets fetched again
        if (payload.item?.categoryID === 'insurance') {
            yield put(
                carActions.getLastCarInsuranceEntrySuccess({
                    carid: payload.carid,
                    timelineItem: undefined,
                })
            )

            posthog.capture('insurance entry created')
        }

        let active_entry_in_state: ITimelineItem = yield select(
            get_active_entry_in_state
        )

        if (
            payload.item.labels &&
            active_entry_in_state &&
            active_entry_in_state.id
        ) {
            let labelsToAddApiPayload: IUpdateEntryLabelPayload = {
                car_id: payload.carid,
                entry_id: active_entry_in_state.id,
                labels: payload.item.labels,
            }
            yield call(AddLabelToEntrySaga, labelsToAddApiPayload)
        }

        if (payload.goToEntryPage === true) {
            yield put(
                push(
                    `/car/${payload.carid}/history-file/entry?entryid=${newEntry.uid}`
                )
            )
        }

        // Capture event
        posthog.capture('add_car_entry')

        return newEntry.uid
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: AddCarTimelineSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try adding a timeline item again.`,
                custom_user_action_text: 'Return to timeline',
                custom_redirect_path: `/car/${payload.carid}/history-file`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(timelineActions.addTimelineItemToCarError(customError))
        }
    }
}

function* watcherAddCarTimeline() {
    while (true) {
        const { payload } = yield take(
            timelineActions.addTimelineItemToCarRequest
        )

        yield call(AddCarTimelineSaga, payload)
    }
}

const add_car_timeline: any[] = [fork(watcherAddCarTimeline)]

export default add_car_timeline
