import './styles.css'

import * as React from 'react'
export interface GlobalLayoutProps {
    children?: React.ReactNode
}

const DesktopDisplay = (props: GlobalLayoutProps) => (
    <div className="desktop-displayed ipad-displayed">{props.children}</div>
)

export default DesktopDisplay
