import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import { device } from '../../../templates/displays/devices'
import {
    isCostAttachmentChecked,
    isEntryAttachmentsChecked,
    isEntryCostDisabled,
    isEntryCostsChecked,
    isHistoryFileChecked,
    isHistoryFileDisabled,
} from './permissionChecksLogic'
import {
    ISharingPermissionIDS,
    IDirectShareOwnedItem,
} from '../../../../redux/entities/cars/types'

export interface Props {
    onChange: (val: ISharingPermissionIDS) => void
    share_being_edited: IDirectShareOwnedItem
    readOnlyMode?: boolean
    noPermissionDetails?: boolean
}

type IStyled = {
    $isDisabled?: boolean
}

const Row = styled.div`
    width: auto;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    min-height: 60px;
    width: 40vw;
    box-sizing: border-box;
    min-width: 750px;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    border-left: 1px solid var(--border-muted, #e5e5e5);
    border-right: 1px solid var(--border-muted, #e5e5e5);
    padding-left: 30px;
    padding-right: 30px;
    @media ${device.ipad} {
        min-width: 650px;
        max-width: 650px;
    }
`

const Col = styled.section`
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
`

const InfoTxt = styled(motion.div)<IStyled>`
    font-family: 'Lato';
    font-size: 16px;
    padding-left: 10px;
    color: ${(props: IStyled) =>
        props.$isDisabled
            ? 'var(--text-muted)'
            : 'var(--text-darker, #616161)'};

    cursor: ${(props: IStyled) =>
        props.$isDisabled ? 'not-allowed' : 'pointer'};

    @media ${device.desktop} {
    }

    @media ${device.large_desktop} {
    }

    @media ${device.smallest_laptops} {
    }
`

const CarSharePermissionTableCheckboxesDesktop: React.FC<Props> = ({
    onChange,
    share_being_edited,
    readOnlyMode,
    noPermissionDetails,
}) => {
    return (
        <Col>
            <Row
                style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                }}
            >
                <CustomAnimatedCheckboxField
                    small
                    onChange={() => {
                        // onChange('history file')
                    }}
                    name={'history_file'}
                    checked={isHistoryFileChecked(
                        share_being_edited,
                        readOnlyMode
                    )}
                    onClick={() => {
                        onChange('history file')
                    }}
                    isDisabled={isHistoryFileDisabled(
                        share_being_edited,
                        readOnlyMode
                    )}
                >
                    <InfoTxt
                        style={{ transform: 'translateY(-2px)' }}
                        $isDisabled={isHistoryFileDisabled(
                            share_being_edited,
                            readOnlyMode
                        )}
                    >
                        {noPermissionDetails
                            ? 'Include Digital History File'
                            : `Include History File - include basic history information`}
                    </InfoTxt>
                </CustomAnimatedCheckboxField>
            </Row>

            <Row
                style={{
                    paddingLeft: '80px',
                }}
            >
                <CustomAnimatedCheckboxField
                    small
                    onChange={() => {
                        // onChange('entry attachments')
                    }}
                    name={'entry_attachments'}
                    checked={isEntryAttachmentsChecked(
                        share_being_edited,
                        readOnlyMode
                    )}
                    onClick={() => {
                        onChange('entry attachments')
                    }}
                    isDisabled={readOnlyMode}
                >
                    <InfoTxt
                        style={{ transform: 'translateY(-2px)' }}
                        $isDisabled={readOnlyMode}
                    >
                        {noPermissionDetails
                            ? 'Include Entry Attachments'
                            : 'Include Entry Attachments - include the attachments on Entries in your history file'}
                    </InfoTxt>
                </CustomAnimatedCheckboxField>
            </Row>

            <Row
                style={{
                    paddingLeft: '80px',
                }}
            >
                <CustomAnimatedCheckboxField
                    small
                    onChange={() => {
                        // onChange('entry costs')
                    }}
                    name={'entry_costs'}
                    checked={isEntryCostsChecked(
                        share_being_edited,
                        readOnlyMode
                    )}
                    onClick={() => {
                        onChange('entry costs')
                    }}
                    isDisabled={isEntryCostDisabled(
                        share_being_edited,
                        readOnlyMode
                    )}
                >
                    <InfoTxt
                        style={{ transform: 'translateY(-2px)' }}
                        $isDisabled={isEntryCostDisabled(
                            share_being_edited,
                            readOnlyMode
                        )}
                    >
                        {noPermissionDetails
                            ? 'Include Costs'
                            : 'Include Costs - include the costs associated with Entries in your history file'}
                    </InfoTxt>
                </CustomAnimatedCheckboxField>
            </Row>

            <Row
                style={{
                    borderBottom: '1px solid var(--border-muted, #e5e5e5)',
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                    paddingLeft: '130px',
                }}
            >
                <CustomAnimatedCheckboxField
                    small
                    onChange={() => {
                        // onChange('entry cost attachments')
                    }}
                    name={'entry_cost_attachments'}
                    checked={isCostAttachmentChecked(
                        share_being_edited,
                        readOnlyMode
                    )}
                    onClick={() => {
                        onChange('entry cost attachments')
                    }}
                    isDisabled={readOnlyMode}
                >
                    <InfoTxt style={{ transform: 'translateY(-2px)' }}>
                        {noPermissionDetails
                            ? 'Include Cost Attachments'
                            : 'Include Cost Attachments - Include files attached to Costs (invoices)'}
                    </InfoTxt>
                </CustomAnimatedCheckboxField>
            </Row>
        </Col>
    )
}

export default CarSharePermissionTableCheckboxesDesktop
