import { useCallback, useEffect, useState } from 'react'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import { showroomActions } from '../../../../redux/showroom/reducer'

import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import CarLocationFormDesktop from './carLocationFormDestkop'
import { getRightSchemaAccountData } from '../../../organisms/editForms/account/validationSchema'
import { ICar } from '../../../../redux/entities/cars/types'
import { IUserAddressApi } from '../../../../redux/services/typedefinitions/apiPayloads'
import { IDropdownItem } from 'entityModels'

type Props = {
    close: () => any
    isMobile?: boolean
    entryid: string
    current: ICar
}

type IGooglePlacesAddress_string =
    | 'street_number'
    | 'route'
    | 'postal_town'
    | 'postal_code'
    | 'country'
    | 'administrative_area_level_1'
    | 'administrative_area_level_2'

const CarLocationFormDataManagerDesktop = (props: Props) => {
    const showroomData = useAppSelector((state) => state.showroom)
    const currentCarAddres =
        showroomData.userShowroomEntries &&
        showroomData.userShowroomEntries[props.entryid] &&
        showroomData.userShowroomEntries[props.entryid].address

    const dealershipAddressInState = useAppSelector(
        (state) => state.user.userLoggedIn?.address
    )

    const usaStatesCodes = useAppSelector(
        (state) => state.localdata.dropdownData.usaStatesCodes
    )

    let dealershipAddress = !dealershipAddressInState?.street_2
        ? { ...dealershipAddressInState, street_2: '' }
        : { ...dealershipAddressInState }

    const [street_number, setStreet_number] = useState<string>(
        currentCarAddres && currentCarAddres.street_1
            ? currentCarAddres.street_1
            : ''
    )
    const [route, setRoute] = useState<string>(
        currentCarAddres && currentCarAddres.street_2
            ? currentCarAddres.street_2
            : ''
    )
    const [postal_town, setPostal_town] = useState<string>(
        currentCarAddres && currentCarAddres.locality
            ? currentCarAddres.locality
            : ''
    )
    const [postal_code, setPostal_code] = useState<string>(
        currentCarAddres && currentCarAddres.postcode
            ? currentCarAddres.postcode
            : ''
    )
    const [country, setCountry] = useState<string>(
        currentCarAddres && currentCarAddres.country
            ? currentCarAddres.country
            : dealershipAddress?.country
            ? dealershipAddress.country
            : 'United Kingdom'
    )

    const [administrative_area_level_1, setAdministrative_area_level_1] =
        useState<string>(
            currentCarAddres && currentCarAddres.administrative_area
                ? currentCarAddres.administrative_area
                : ''
        )

    const [administrative_area_level_2, setAdministrative_area_level_2] =
        useState<string>(
            currentCarAddres && currentCarAddres.administrative_area
                ? currentCarAddres.administrative_area
                : ''
        )

    const [isCountryChangedFromSearch, setIsCountryChangedFromSearch] =
        useState<boolean>(false)

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    const [hasChange, setHasChange] = useState<boolean>(false)

    const validateForm = async (id: string, value: string) => {
        try {
            await getRightSchemaAccountData(id).validate({ [id]: value })
            setError((prevState) => {
                return { ...prevState, [id]: undefined }
            })
        } catch (err: any) {
            setError((prevState) => {
                return { ...prevState, [id]: err.errors[0] }
            })
        }
    }

    const isFormValid = (): boolean => {
        if (error && Object.values(error).some((item) => item !== undefined)) {
            return false
        }
        if (street_number.length === 0 && route.length === 0) {
            return false
        } else if (
            country.length === 0 ||
            postal_code.length === 0 ||
            postal_town.length === 0
        ) {
            return false
        } else return true
    }

    // generate id here with useWindow()
    let input = document.getElementById(
        `autocomplete_car_location_desktop`
    ) as HTMLInputElement

    const googleMaps = useCallback(async () => {
        try {
            let autocomplete = new google.maps.places.Autocomplete(input, {
                types: ['geocode'],
            })

            autocomplete.setFields(['address_component'])

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    let geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }

                    let circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy,
                    })
                    autocomplete.setBounds(circle.getBounds())
                })
            }
            autocomplete.addListener('place_changed', () => {
                let place: any = autocomplete.getPlace()

                if (!hasChange) {
                    setHasChange(true)
                }

                let addressComponents: string[] = []

                place.address_components !== undefined &&
                    place.address_components.forEach((chunk: any) => {
                        addressComponents.push(chunk.types[0])
                        if (chunk.types[0] === 'street_number') {
                            setStreet_number(chunk.long_name)
                            validateForm('street_number', chunk.long_name)
                        }
                        if (chunk.types.includes('route')) {
                            setRoute(chunk.long_name)
                            validateForm('route', chunk.long_name)
                        }
                        if (chunk.types.includes('postal_code')) {
                            setPostal_code(chunk.long_name)
                            validateForm('postal_code', chunk.long_name)
                        }
                        if (chunk.types.includes('locality')) {
                            setPostal_town(chunk.long_name)
                        }
                        if (chunk.types.includes('postal_town')) {
                            setPostal_town(chunk.long_name)
                            validateForm('postal_town', chunk.long_name)
                        }
                        if (
                            chunk.types.includes('administrative_area_level_1')
                        ) {
                            setAdministrative_area_level_1(chunk.short_name)
                        }
                        if (
                            chunk.types.includes('administrative_area_level_2')
                        ) {
                            setAdministrative_area_level_2(chunk.short_name)
                        }
                        if (chunk.types.includes('country')) {
                            setCountry(chunk.long_name)
                            setIsCountryChangedFromSearch(true)
                        }
                    })

                if (!addressComponents.includes('street_number')) {
                    validateForm('street_number', '')
                    setStreet_number('')
                }
                if (!addressComponents.includes('route')) {
                    validateForm('route', '')
                    setRoute('')
                }
                if (!addressComponents.includes('postal_code')) {
                    validateForm('postal_code', '')
                    setPostal_code('')
                }
                if (!addressComponents.includes('postal_town')) {
                    validateForm('postal_town', '')
                    setPostal_town('')
                }
            })
        } catch (e) {
            console.error(e)
        } finally {
        }
    }, [input])

    useEffect(() => {
        if (input) {
            googleMaps()
        }

        if (country === 'United States' && !usaStatesCodes) {
            dispatch(dropDownActions.setUSAStatesDropdownRequest())
        }
    }, [input])

    let handleChange = (value: string, id: IGooglePlacesAddress_string) => {
        id === 'administrative_area_level_1' &&
            setAdministrative_area_level_1(value)
        id === 'postal_code' && setPostal_code(value)
        id === 'postal_town' && setPostal_town(value)
        id === 'route' && setRoute(value)
        id === 'street_number' && setStreet_number(value)
        if (
            ['street_number', 'route', 'postal_town', 'postal_code'].includes(
                id
            )
        ) {
            validateForm(id, value)
        }
    }

    let handleSelectCountry = (answer: string) => {
        setCountry(answer)
        setIsCountryChangedFromSearch(false)

        if (answer === 'United States' && !usaStatesCodes) {
            dispatch(dropDownActions.setUSAStatesDropdownRequest())
        }

        if (answer !== 'United States' && administrative_area_level_1) {
            setAdministrative_area_level_1('')
        }

        if (answer !== 'Spain' && administrative_area_level_2) {
            setAdministrative_area_level_2('')
        }
    }

    let onSubmit = () => {
        let addressConv: IUserAddressApi = {
            locality: postal_town,
            postcode: postal_code,
            country: country,
            street_1: street_number,
            street_2: route,
            administrative_area:
                country === 'Spain'
                    ? administrative_area_level_2
                    : country === 'United States'
                    ? administrative_area_level_1
                    : undefined,
            type: 'Showroom Car Address',
        }

        let hasAddressChange = (): boolean => {
            let hasChange = false

            if (!currentCarAddres) {
                hasChange = true
            }

            if (addressConv.country !== currentCarAddres?.country) {
                hasChange = true
            }
            if (addressConv.postcode !== currentCarAddres?.postcode) {
                hasChange = true
            }
            if (addressConv.locality !== currentCarAddres?.locality) {
                hasChange = true
            }
            if (addressConv.street_1 !== currentCarAddres?.street_1) {
                hasChange = true
            }
            if (addressConv.street_2 !== currentCarAddres?.street_2) {
                hasChange = true
            }

            if (
                addressConv.administrative_area !==
                currentCarAddres?.administrative_area
            ) {
                hasChange = true
            }

            return hasChange
        }

        if (hasAddressChange()) {
            dispatch(
                showroomActions.updateEntryShowroomRequest({
                    uid: props.entryid,
                    car_id: `${props.current?.id}`,
                    data: {
                        address: addressConv,
                    },
                })
            )
        }

        setTimeout(() => {
            dispatch(
                showroomActions.validateEntryForPublicationRequest(
                    props.entryid
                )
            )
        }, 150)
    }

    let countriesOptions: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    let dispatch = useAppDispatch()

    useEffect(() => {
        if (!countriesOptions) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [])

    const hasDataChanged = () => {
        if (!currentCarAddres) {
            return true
        }
        if (
            street_number !== currentCarAddres.street_1 ||
            (route !== '' && route !== currentCarAddres.street_2) ||
            postal_town !== currentCarAddres.locality ||
            postal_code !== currentCarAddres.postcode
        ) {
            return true
        } else return false
    }

    const getRightKey = (
        key: keyof IUserAddressApi
    ): IGooglePlacesAddress_string | undefined => {
        let rightKey: IGooglePlacesAddress_string | undefined = undefined

        if (key === 'street_1') {
            rightKey = 'street_number'
        } else if (key === 'street_2') {
            rightKey = 'route'
        } else if (key === 'locality') {
            rightKey = 'postal_town'
        } else if (key === 'postcode') {
            rightKey = 'postal_code'
        }

        return rightKey
    }

    const checkIfAddressIsSameAsDealership = () => {
        if (dealershipAddress) {
            if (
                dealershipAddress.street_1 === street_number &&
                dealershipAddress.street_2 === route &&
                dealershipAddress.locality === postal_town &&
                dealershipAddress.postcode === postal_code &&
                dealershipAddress.country === country
            ) {
                return true
            }
        }
        return false
    }

    const setAddressSameAsDealership = () => {
        if (dealershipAddress) {
            for (let key in dealershipAddress) {
                let rightKey = getRightKey(key as keyof IUserAddressApi)

                if (!checkIfAddressIsSameAsDealership()) {
                    if (
                        dealershipAddress[key as keyof IUserAddressApi] &&
                        key !== 'type'
                    ) {
                        if (key === 'administrative_area') {
                            if (
                                dealershipAddress.country === 'United States' &&
                                dealershipAddress.administrative_area
                            ) {
                                setAdministrative_area_level_1(
                                    dealershipAddress.administrative_area
                                )
                            } else if (
                                dealershipAddress.country === 'Spain' &&
                                dealershipAddress.administrative_area
                            ) {
                                setAdministrative_area_level_2(
                                    dealershipAddress.administrative_area
                                )
                            }
                        }
                        if (key === 'country' && dealershipAddress.country) {
                            handleSelectCountry(dealershipAddress.country)
                            setIsCountryChangedFromSearch(true)
                        }
                        if (rightKey) {
                            handleChange(
                                dealershipAddress[
                                    key as keyof IUserAddressApi
                                ]!,
                                rightKey
                            )
                        }
                    }
                } else {
                    if (key === 'administrative_area') {
                        setAdministrative_area_level_1('')

                        setAdministrative_area_level_2('')
                    }

                    handleSelectCountry(
                        currentCarAddres && currentCarAddres.country
                            ? currentCarAddres.country
                            : dealershipAddress?.country
                            ? dealershipAddress.country
                            : 'United Kingdom'
                    )

                    setRoute('')

                    if (rightKey) {
                        handleChange('', rightKey)
                    }
                }
            }
        }
    }

    return (
        <CarLocationFormDesktop
            formData={{
                street_number: street_number,
                route: route,
                postal_town: postal_town,
                postal_code: postal_code,
                country: country,
                administrative_area_level_1: administrative_area_level_1,
                administrative_area_level_2: administrative_area_level_2,
            }}
            handleChange={handleChange}
            handleSelectCountry={handleSelectCountry}
            isCountryChangedFromSearch={isCountryChangedFromSearch}
            close={props.close}
            isFormValid={isFormValid}
            onSubmit={onSubmit}
            countriesOptions={countriesOptions}
            hasDataChanged={hasDataChanged()}
            error={error}
            isAddressSameAsDealership={checkIfAddressIsSameAsDealership()}
            onSwitchToggle={setAddressSameAsDealership}
            isToggleVisible={dealershipAddress ? true : false}
        />
    )
}

export default CarLocationFormDataManagerDesktop
