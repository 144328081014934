import { IApexHomepageArticleState } from 'apexModels'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    max-width: 650px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media ${device.smallest_laptops} {
        padding-right: 20px;
        width: 95%;
    }
`

const ImgStyle = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 170px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex-direction: column;
    border-radius: 10px;

    @media ${device.ipad} {
        /* width: 260px; */
        width: 100%;
        box-sizing: border-box;
    }

    @media ${device.smallest_laptops} {
        width: 95%;
    }
`
const Title = styled(Link)`
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
    color: var(--primary, #5ec3ca) !important;

    padding-top: 20px;
    padding-bottom: 10px;
    @media ${device.ipad} {
        font-size: 17px;
    }
    @media ${device.desktop} {
        font-size: 19px;
    }

    @media ${device.smallest_laptops} {
        font-size: 18px;
    }

    @media ${device.large_desktop} {
        font-size: 22px;
    }
`

const Text = styled.div`
    line-height: 25px;
    font-family: Lato-Light;
    width: 100%;
    box-sizing: border-box;
    color: var(--text-strong, #1a1a1a);
    @media ${device.desktop} {
        font-size: 17px;
    }

    @media ${device.smallest_laptops} {
        font-size: 16px;
    }

    @media ${device.large_desktop} {
        font-size: 19px;
    }

    @media ${device.ipad} {
        /* width: 260px; */
        width: 100%;
        box-sizing: border-box;
    }
`

const ReadMoreText = styled(Link)`
    color: var(--primary, #5ec3ca) !important;
    font-family: Lato-Light;
    padding-top: 10px;

    @media ${device.desktop} {
        font-size: 16px;
    }

    @media ${device.smallest_laptops} {
        font-size: 15px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }
`

type Props = {
    article: IApexHomepageArticleState
}

const ApexHomepageArticleDesktop: React.FC<Props> = ({ article }) => {
    return (
        <Wrapper key={article.uid}>
            <Link
                to={`/apex/articles/${article.uid}`}
                style={{ width: '100%' }}
            >
                <ImgStyle
                    role="img"
                    aria-label="fullwidth-picture"
                    style={{
                        backgroundImage: `url(${
                            article.img ? article.img.url : ''
                        })`,
                    }}
                />
            </Link>
            <Title to={`/apex/articles/${article.uid}`}>{article.title}</Title>
            <Text>{article.introduction}</Text>
            <ReadMoreText to={`/apex/articles/${article.uid}`}>
                Read More
            </ReadMoreText>
        </Wrapper>
    )
}

export default ApexHomepageArticleDesktop
