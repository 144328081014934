import { combineReducers } from 'redux'
import userReducer from '../user/reducer'
import { History } from 'history'
import menusReducer from '../menus/reducer'
import localdataReducer from '../localdata/reducer'
import entitiesReducer from '../entities/reducer'
import fileStorageReducer from '../filestorage/reducer'
import taskReducer from '../tasks/reducer'
import apexReducer from '../theapex/reducer'
import timelineReducer from '../timeline/reducer'
import attachmentsReducer from '../attachments/reducer'
import searchUsersData from '../searchUsers/reducer'
import showroomSlice from '../showroom/reducer'
import editFormsSlice from '../editForms/reducer'
import featureFlags from '../featureFlags/reducer'
import insuranceQuoteApplication from '../insuranceQuoteApplication/reducer'

const rootReducer = (history: History) =>
    combineReducers({
        user: userReducer,
        localdata: localdataReducer,
        menus: menusReducer,
        entities: entitiesReducer,
        editForms: editFormsSlice,
        fileStorage: fileStorageReducer,
        tasks: taskReducer,
        timeline: timelineReducer,
        apex: apexReducer,
        attachments: attachmentsReducer,
        searchUsers: searchUsersData,
        showroom: showroomSlice,
        featureFlags: featureFlags,
        insuranceQuoteApplication: insuranceQuoteApplication,
    })

export default rootReducer
