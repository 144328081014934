import styled from 'styled-components'
import * as Yup from 'yup'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'
import InputField from '../../../atoms/Inputfield/inputField'
import SelectSearchBarCarInfoDesktop from '../technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import { IDropdownItem } from 'entityModels'
import SwitchButton from '../../../atoms/Button/switchButton'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
} from '../../../molecules/technicalInformation/modal'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import FadedSlower from '../../../templates/animated/FadedSlower'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { IExternalCarDataRequestPayloadVersion2 } from '../../../../redux/entities/cars/types'
import { carActions } from '../../../../redux/entities/cars/reducer'

type IStyle = {
    $theme: ITheme
    $isActive?: boolean | undefined
}

const FieldDescription = styled.div<IStyle>`
    font-family: Lato-Light;
    font-size: 16px;
    line-height: 26px;
    color: ${(props) => colours[props.$theme].text_darker};

    @media ${device.smallest_laptops} {
        padding-right: 30px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }
`

const Padding32 = styled.div`
    padding-top: 32px;
`

const Padding16 = styled.div`
    padding-top: 16px;
`

const Error = styled.div<IStyle>`
    font-family: 'Lato-light';
    color: ${(props) => colours[props.$theme].error};
    font-style: italic;
    font-size: 14px;
    padding-bottom: 8px;
    @media ${device.ipad} {
        font-size: 12px;
    }
`

const FormTitle = styled.div<IStyle>`
    font-family: Lato;
    font-size: 22px;
    width: 100%;
    box-sizing: border-box;
    text-align: start;
    font-size: 22px;
    padding-bottom: 12px;
    color: ${(props) => colours[props.$theme].text_strong};
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
`

const ToggleText = styled.div<IStyle>`
    padding-left: 16px;
    text-transform: capitalize;
    font-family: lato;
    font-size: 14px;
    letter-spacing: 0px;
    transform: translateY(-4px);
    color: ${(props) =>
        props.$isActive
            ? colours[props.$theme].primary
            : colours[props.$theme].text_muted};
`

interface Values {
    registration_number?: string | undefined
    country?: string | undefined
    hasNoRegistrationNumber?: boolean | undefined
}

interface OtherProps {
    close: () => void
    garage_id: string
    countriesdropdownlist: IDropdownItem[]
    userCountry: string | undefined
    numberOfCarsInGarage: number
    dataCyId: string
    dispatch: any
    navigate: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        close,
        countriesdropdownlist,
        setFieldValue,
        userCountry,
        dataCyId,
        errors,
    } = props

    let checkIsDisabled = (): boolean => {
        if (!values.country) {
            return true
        }

        if (values.country === 'United Kingdom') {
            if (
                !values.registration_number &&
                values.hasNoRegistrationNumber !== true
            ) {
                return true
            } else if (
                values.registration_number &&
                values.registration_number.trim().length < 1
            ) {
                return true
            } else return false
        } else return false
    }

    const { theme } = useThemes()

    return (
        <FadedSlower>
            <ModalContentDesktop
                $theme={theme}
                data-attr={dataCyId}
                style={{ overflowY: 'auto' }}
            >
                <ModalInnerContentWrapperDesktop>
                    <>
                        <FormTitle $theme={theme}>Add your car</FormTitle>

                        <FieldDescription $theme={theme}>
                            Select the country where the car is registered and
                            enter its registration number.
                            <br />
                            If your car is registered in the UK, our DVLA lookup
                            will pull your info automatically.
                        </FieldDescription>

                        <Padding32 />

                        <SelectSearchBarCarInfoDesktop
                            items={countriesdropdownlist}
                            item={{
                                text: 'countries',
                                answer: userCountry
                                    ? userCountry
                                    : 'United Kingdom',
                            }}
                            handleSelectChange={(selected: string) =>
                                setFieldValue('country', selected)
                            }
                            dataCyId="mainaddacar-form-country-input-desktop"
                            placeholder="Country"
                            helperText="Select country where your vehicle is registered"
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        />

                        <div style={{ paddingBottom: '20px' }} />
                        <InputField
                            theme={theme}
                            id="registration_number"
                            name="registration_number"
                            placeholder="registration number"
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            value={values.registration_number}
                            dataCyId={
                                'mainaddacar-form-regnumber-input-desktop'
                            }
                            disabled={
                                values.hasNoRegistrationNumber ? true : false
                            }
                            onEnterKeyPress={props.handleSubmit}
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        />

                        <Expander
                            height={
                                values.registration_number &&
                                values.registration_number.length > 1 &&
                                errors.registration_number
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <Faded>
                                <div style={{ paddingTop: '5px' }} />
                                <Error
                                    $theme={theme}
                                    className="animated fadeIn slower"
                                >
                                    {errors.registration_number}
                                </Error>
                            </Faded>
                        </Expander>

                        <div style={{ paddingTop: '20px' }}>
                            <Row style={{}}>
                                <SwitchButton
                                    checked={
                                        values.hasNoRegistrationNumber === true
                                            ? true
                                            : false
                                    }
                                    toggle={() => {
                                        setFieldValue(
                                            'hasNoRegistrationNumber',
                                            !values.hasNoRegistrationNumber
                                        )
                                    }}
                                    width={34}
                                    circleSize={12}
                                    height={18}
                                    dataCyId={
                                        'mainaddacar-regnumber-toggle-desktop'
                                    }
                                />

                                <ToggleText
                                    $theme={theme}
                                    $isActive={
                                        values.hasNoRegistrationNumber === true
                                            ? true
                                            : false
                                    }
                                >
                                    No Registration number
                                </ToggleText>
                            </Row>
                        </div>
                    </>
                    <Padding32 />
                    <Padding16 />

                    <ButtonsStandardBottomRowDesktop
                        cancel={close}
                        submit={props.handleSubmit}
                        firstText="cancel"
                        secondText={
                            values.country !== 'United Kingdom'
                                ? 'next'
                                : 'submit'
                        }
                        dataCyIdUpdate={'mainaddacar-form-submit-desktop'}
                        isDisabled={checkIsDisabled()}
                    />
                </ModalInnerContentWrapperDesktop>
            </ModalContentDesktop>
        </FadedSlower>
    )
}

const AddACarFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        country: props.userCountry ? props.userCountry : 'United Kingdom',
    }),

    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            const regexCheck = /^[a-zA-Z0-9_ ]*$/
            return Yup.object().shape({
                registration_number: Yup.string()
                    .max(20, 'Your answer must be under 20 characters')
                    .min(2)
                    .matches(regexCheck, 'Special characters not allowed'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        let countryy = values.country ? values.country : 'United Kingdom'
        let egistration_number =
            values.registration_number &&
            values.registration_number.trim().length > 1 &&
            values.registration_number.length > 1
                ? values.registration_number.trim()
                : undefined
        if (egistration_number !== undefined && countryy === 'United Kingdom') {
            let payload: IExternalCarDataRequestPayloadVersion2 = {
                country: values.country ? values.country : 'United Kingdom',
                registration_number: egistration_number,
                isOnboarding: false,
            }

            props.dispatch(
                carActions.getExternalCarDataByRegistrationNumberRequestVersion2(
                    payload
                )
            )
        } else {
            props.navigate(
                `/car/new_car/confirm?from_ves_api=false&country=${countryy}`
            )
        }
        setSubmitting(false)

        let { close } = props

        setTimeout(function () {
            close()
        }, 500)
    },
})(InnerForm)

export default AddACarFormDesktop
