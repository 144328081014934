import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import ButtonsStandardBottomRowMobile from '../../buttons/buttonsStandardBottomRowMobile'
import SelectMultipleSearchBarCarInfoMobile from '../../technicalInformationEditForm/multipleselect/mobile/selectMultipleSearchBarCarInfoMobile'
import { ITask, ITaskRelationsPayload } from 'entityModels'
import SwitchButton from '../../../../atoms/Button/switchButton'
import { OOtherTaskOptions } from '../../../../../ol/ol'
import { device } from '../../../../templates/displays/devices'
import useThemes from '../../../../../providers/theme/hooks'
import colours from '../../../../../providers/theme/colours'

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    padding-top: 8vh;
    overflow-y: scroll;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);
    position: relative;
    animation: fadeIn 0.2s both;
    animation-delay: 0.15s;
    opacity: 1;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 30px;
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 18px;
    padding-right: 20px;
    color: var(--text-strong, #1a1a1a);
`

type IstyledToggleItem = {
    isActive?: boolean
}
const ToggleText = styled.div<IstyledToggleItem>`
    padding-left: 16px;
    text-transform: capitalize;
    font-family: lato;
    font-size: 15px;
    letter-spacing: 0px;
    transform: translateY(-4px);
    color: ${(props) =>
        props.isActive
            ? 'var(--primary, #5ec3ca)'
            : 'var(--text-default, #666666)'};
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
`

const Answers = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: lato-light;
    font-size: 12px;
    max-height: 100px;

    @media (max-height: 650px) {
        max-height: 60px;
    }
    overflow: scroll;
`

const ButtonsWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    /* position: fixed;
    bottom: 0;
    bottom: env(safe-area-inset-bottom); */
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding-top: 32px;
    padding-bottom: 64px;
    @media ${device.ipad} {
        max-width: 600px;
    }
`

const HorizLine = styled.div`
    padding-top: 32px;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid var(--border-muted, #e5e5e5);
`

interface Values {
    multiple_values_array: any
    linkedToAll?: string
}

interface OtherProps {
    toggle: any
    item: any
    task: ITask
    dropdownData: any
    carsData: any
    garage_id: string
    unlinkTaskRequest: any
    linkTaskRequest: any
    garageCarsIds: string[]
    isEveryCar?: boolean
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        item,
        dropdownData,
        setFieldValue,
        values,
        // errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props
    let new_item = {
        ...item,
        text: 'your cars',
        answers: `${item.answer}    ${
            item.answer && item.answer.length > 1
                ? `+ ${item.answer.length + 1}`
                : ''
        }`,
    }

    let checkIfDisabled = () => {
        if (!values.multiple_values_array && !values.linkedToAll) {
            return true
        } else if (
            values.multiple_values_array &&
            values.multiple_values_array[0] === undefined &&
            !values.linkedToAll
        ) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    // console.log('dropdownData', dropdownData)

    return (
        <EditFormContainerMobile>
            <InnerFormPadding>
                <FieldTitle>{item.text}</FieldTitle>

                <div style={{ paddingTop: '5px' }} />

                <div
                    style={{
                        color: colours[theme].text_strong,
                        fontFamily: 'lato-light',
                        fontSize: '14px',
                    }}
                >
                    Search specific car(s) or select what applies
                </div>
                <div style={{ paddingTop: '30px' }} />
                <SelectMultipleSearchBarCarInfoMobile
                    item={new_item}
                    items={dropdownData}
                    handleSelectChange={(name: string[]) =>
                        setFieldValue('multiple_values_array', name)
                    }
                    isDisabled={
                        values.linkedToAll &&
                        (values.linkedToAll === 'all_cars' ||
                            values.linkedToAll ===
                                OOtherTaskOptions.general_task.uid)
                            ? true
                            : false
                    }
                />

                <div style={{ paddingTop: '38px' }} />
                <Row>
                    <SwitchButton
                        checked={
                            values.linkedToAll ===
                            OOtherTaskOptions.general_task.uid
                                ? true
                                : false
                        }
                        toggle={() => {
                            if (
                                values.linkedToAll !==
                                OOtherTaskOptions.general_task.uid
                            ) {
                                setFieldValue(
                                    'linkedToAll',
                                    OOtherTaskOptions.general_task.uid
                                )
                            } else {
                                setFieldValue('linkedToAll', undefined)
                            }
                        }}
                        width={34}
                        circleSize={12}
                        height={18}
                    />

                    <ToggleText
                        isActive={
                            values.linkedToAll ===
                            OOtherTaskOptions.general_task.uid
                                ? true
                                : false
                        }
                    >
                        {OOtherTaskOptions.general_task.single}
                    </ToggleText>
                </Row>
                <div style={{ paddingTop: '10px' }} />
                <Row>
                    <SwitchButton
                        checked={
                            values.linkedToAll === 'all_cars' ? true : false
                        }
                        toggle={() => {
                            if (values.linkedToAll !== 'all_cars') {
                                setFieldValue('linkedToAll', 'all_cars')
                            } else {
                                setFieldValue('linkedToAll', undefined)
                            }
                        }}
                        circleSize={12}
                        height={18}
                        width={34}
                    />

                    <ToggleText
                        isActive={
                            values.linkedToAll === 'all_cars' ? true : false
                        }
                    >
                        Every car
                    </ToggleText>
                </Row>
                <div style={{ paddingTop: '5vh' }} />

                <FieldTitle>
                    <span
                        style={{
                            fontSize: '12px',
                            color: colours[theme].text_strong,
                        }}
                    >
                        {checkIfDisabled() === true
                            ? 'Currently'
                            : 'Previously'}{' '}
                        linked to this task
                    </span>
                </FieldTitle>

                <div style={{ paddingTop: '4px' }} />
                <Answers>{item.answers}</Answers>
            </InnerFormPadding>
            <ButtonsWrapper>
                <HorizLine />
                <InnerFormPadding>
                    <ButtonsStandardBottomRowMobile
                        cancel={toggle}
                        submit={props.handleSubmit}
                        confirmationtext="save"
                        disabled={checkIfDisabled()}
                    />
                </InnerFormPadding>
            </ButtonsWrapper>
        </EditFormContainerMobile>
    )
}

const SearchNselectCarsTaskMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        multiple_values_array: props.item.answer ? props.item.answer : '-',
        linkedToAll: props.isEveryCar
            ? 'all_cars'
            : props.item.answer[0] === OOtherTaskOptions.general_task.name
            ? OOtherTaskOptions.general_task.uid
            : undefined,
    }),
    // validationSchema: {},
    enableReinitialize: true,

    handleSubmit: (values, { props, setSubmitting }) => {
        let carIdsSelected = (names: string[]) => {
            let car_ids: string[] = []

            let { carsData } = props

            let all_car_ids_list: string[] = Object.keys(carsData).filter(
                (id) => id !== 'new_car'
            )

            for (const carid of all_car_ids_list) {
                if (names.includes(carsData[carid].title)) {
                    car_ids.push(carid)
                }
            }
            return car_ids
        }

        setSubmitting(false)

        if (values.linkedToAll) {
            if (values.linkedToAll === OOtherTaskOptions.general_task.uid) {
                // determine payload
                let unlink_payload: ITaskRelationsPayload = {
                    taskid: props.task.id,
                    car_id: props.task.linkedToCar
                        ? props.task.linkedToCar
                        : [],
                }

                // unlink cars
                if (props.task.linkedToCar) {
                    props.task.linkedToCar[0] !== undefined &&
                        props.unlinkTaskRequest({
                            taskid: unlink_payload.taskid,
                            car_id: unlink_payload.car_id,
                        })
                }
            } else if (values.linkedToAll === 'all_cars') {
                // determine payload
                let link_payload: ITaskRelationsPayload = {
                    taskid: props.task.id,
                    car_id: props.garageCarsIds,
                }

                // link all garage cars
                props.linkTaskRequest({
                    taskid: link_payload.taskid,
                    car_id: link_payload.car_id,
                })
            }
        } else if (values.multiple_values_array) {
            let unlink_payload: ITaskRelationsPayload = {
                taskid: props.task.id,
                car_id: props.task.linkedToCar ? props.task.linkedToCar : [],
            }

            let link_payload: ITaskRelationsPayload = {
                taskid: props.task.id,
                car_id: carIdsSelected(values.multiple_values_array),
            }

            // unlink first
            if (props.task.linkedToCar) {
                props.task.linkedToCar[0] !== undefined &&
                    props.unlinkTaskRequest({
                        taskid: unlink_payload.taskid,
                        car_id: unlink_payload.car_id,
                    })
            }

            if (
                values.multiple_values_array[0] !==
                OOtherTaskOptions.general_task.name
            ) {
                // then link
                setTimeout(function () {
                    props.linkTaskRequest({
                        taskid: link_payload.taskid,
                        car_id: link_payload.car_id,
                    })
                }, 100)
            }
        }

        props.toggle()
    },
})(InnerForm)

export default SearchNselectCarsTaskMobile
