import useThemes from '../../../../../providers/theme/hooks'
import Faded from '../../../../templates/animated/faded'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import {
    JourneyColumnContent,
    JourneyText,
} from '../../../../templates/styledcomponents/journeyStyles'
import JourneyPageWrapperMobile from '../../../../templates/wrappers/journeyPageWrapperMobile'
import { CenterImg } from '../memories'
import image_mobile from '../../../../../public/assets/images/journey/pastCars/mobile_archives.png'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function ArchivingCarJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    return (
        <IpadAndMobileDisplay>
            <Faded>
                <JourneyPageWrapperMobile
                    allowScroll={true}
                    title={'Archiving your past'}
                    sectionID="past_cars"
                    subtitle={`Don't forget your past cars`}
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        goToPrevStep()
                    }}
                    onNext={() => {
                        goToNextStep()
                    }}
                >
                    <JourneyColumnContent style={{ gap: 0 }}>
                        <JourneyText
                            $theme={theme}
                            style={{
                                paddingLeft: '24px',
                                paddingRight: '24px',
                            }}
                        >
                            By archiving your past cars, you create a digital
                            scrapbook of your automotive history—a testament to
                            your passion for cars and the memories they hold.
                        </JourneyText>

                        <div style={{ paddingTop: '36px' }} />

                        <CenterImg
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Faded width={'auto'}>
                                <img
                                    src={image_mobile}
                                    // placeholder={grey_placeholder}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '100vw',
                                        maxHeight: '80vh',
                                        alignSelf: 'center',
                                    }}
                                />
                            </Faded>
                        </CenterImg>

                        <JourneyColumnContent
                            style={{
                                paddingLeft: '24px',
                                paddingRight: '24px',
                                gap: 12,
                            }}
                        >
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="32"
                                    height="32"
                                    rx="16"
                                    fill="#B86496"
                                    fill-opacity="0.08"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M16 9.86719C13.6528 9.86719 11.75 11.77 11.75 14.1172C11.75 15.0571 12.0542 15.9238 12.5699 16.6271C13.7918 17.8613 14.3969 19.0336 14.6306 19.8672H17.3567C17.5133 19.2859 17.8198 18.7226 18.121 18.2589C18.5544 17.5915 19.0671 16.9958 19.4298 16.6275C19.9457 15.9241 20.25 15.0572 20.25 14.1172C20.25 11.77 18.3472 9.86719 16 9.86719ZM10.25 14.1172C10.25 10.9416 12.8244 8.36719 16 8.36719C19.1756 8.36719 21.75 10.9416 21.75 14.1172C21.75 15.411 21.3218 16.607 20.5996 17.5682L20.5678 17.6106L20.5303 17.6481C20.2279 17.9504 19.7627 18.485 19.379 19.0759C18.9758 19.6968 18.75 20.248 18.75 20.6172V21.3672H13.25L13.25 20.6172C13.25 20.3793 12.9807 19.1594 11.4696 17.6479L11.4322 17.6105L11.4004 17.5682C10.6782 16.607 10.25 15.411 10.25 14.1172Z"
                                    fill="#B86496"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M13.2115 22.1367L18.7621 22.1367L18.7621 23.6367L13.2115 23.6367L13.2115 22.1367Z"
                                    fill="#B86496"
                                />
                            </svg>

                            <JourneyText
                                $theme={theme}
                                style={{ fontSize: '16px' }}
                            >
                                When a car is no longer in your possession, you
                                can either archive it in your garage or hand it
                                over to its new owner through our platform. Even
                                if a car is handed over to the new owner, you
                                keep the car with your own memories in your
                                “Previously Owned” garage.
                            </JourneyText>
                        </JourneyColumnContent>
                    </JourneyColumnContent>
                    <div style={{ paddingTop: '50px' }} />
                </JourneyPageWrapperMobile>
            </Faded>
        </IpadAndMobileDisplay>
    )
}
