import { ITask, ITaskNormalisedObject } from 'entityModels'
import { ITaskPayloadAPI } from '../../services/typedefinitions/apiPayloads'

export const checkTaskCarLinked = (arr: string[] | undefined) => {
    if (arr !== undefined) {
        if (arr[0] !== undefined) {
            return arr
        } else return undefined
    } else return undefined
}

export const ConvertSingleApiTask = (taskFromAPI: ITaskPayloadAPI): ITask => {
    let task_res: ITask = {
        id: taskFromAPI.uid,
        title: taskFromAPI.title ? taskFromAPI.title : '',
        userid: taskFromAPI.user_id,
        linkedToCar: checkTaskCarLinked(taskFromAPI.car_id),
        status: taskFromAPI.complete ? taskFromAPI.complete : false,
        created_at: taskFromAPI.created_at,
        due: taskFromAPI.due ? taskFromAPI.due : null,
        // reminders: taskFromAPI.reminder ? taskFromAPI.reminder : null,
        body: taskFromAPI.body ? taskFromAPI.body : '',
        has_reminders: taskFromAPI.has_reminders ? true : undefined,
    }

    return task_res
}

export const ConvertAndNormaliseApiTasks = (
    tasksFromAPI: any[]
): ITaskNormalisedObject => {
    let normalised_converted_tasks: ITaskNormalisedObject = {}

    for (const task of tasksFromAPI) {
        let converted_task: ITask = ConvertSingleApiTask(task)

        normalised_converted_tasks[converted_task.id] = converted_task
    }

    return normalised_converted_tasks
}
