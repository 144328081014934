import { Radio } from 'antd'
import styled from 'styled-components'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import Expander from '../../atoms/expander/expander'

type Props = {
    value: string | undefined
    options: {
        uid: string
        name: string
        [key: string]: string | undefined
    }[]
    onChange: (payload: string) => void
    isDisabled?: boolean
    customActiveBgColour?: string
    customPassiveBgColour?: string
    customActiveRadioColour?: string
}

type IStyle = {
    $theme: ITheme
    $isActive?: boolean
    $customActiveRadioColour?: string
    $customPassiveBgColour?: string
}

const CustomRadioGroup = styled(Radio.Group)<IStyle>`
    .ant-radio-inner {
        background-color: ${(props) =>
            props.$customPassiveBgColour ??
            colours[props.$theme].background_default} !important;
        height: 20px !important;
        width: 20px !important;
    }

    .ant-radio-inner::before {
        background-color: ${(props) =>
            colours[props.$theme].background_default} !important;
        height: 14px !important;
        width: 14px !important;
    }

    .ant-radio-inner::after {
        background-color: ${(props) =>
            colours[props.$theme].background_default} !important;
        transform: scale(0.7) !important;
    }
    .ant-radio .ant-radio-inner,
    .ant-radio-input {
        border-color: ${(props) =>
            `${colours[props.$theme].text_darker}`} !important;
    }

    .ant-radio-checked .ant-radio-inner {
        background-color: ${(props) =>
            `${
                props.$customActiveRadioColour ?? colours[props.$theme].primary
            }`} !important;
        border: ${(props) =>
            `1px solid ${
                props.$customActiveRadioColour ?? colours[props.$theme].primary
            }`} !important;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: ${(props) =>
            `${
                props.$customActiveRadioColour ?? colours[props.$theme].primary
            }`} !important;
    }
`

const Option = styled.button`
    width: 100%;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    margin-top: 16px;
    border-radius: 8px;

    :hover {
        background-color: var(
            --core-neutral-darken-100,
            rgba(26, 26, 26, 0.04)
        );
    }
`

const OptionText = styled.p`
    color: var(--text-default, #666);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Desktop/Text/T300 Regular */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 12px;
`

function BgRadioInput({
    options,
    value,
    onChange,
    isDisabled,
    customActiveBgColour,
    customActiveRadioColour,
    customPassiveBgColour,
}: Props) {
    const { theme } = useThemes()
    return (
        <CustomRadioGroup
            $customActiveRadioColour={customActiveRadioColour}
            $customPassiveBgColour={customPassiveBgColour}
            $theme={theme}
            value={value}
            style={{ width: '100%' }}
            disabled={isDisabled}
        >
            {options.map((option, index) => {
                return (
                    <Expander
                        key={`${option.uid}-${index}`}
                        height={option ? 'auto' : 0}
                    >
                        <Option
                            key={`${option.uid}-${index}-child`}
                            style={{
                                backgroundColor:
                                    value && value === option.uid
                                        ? customActiveBgColour ??
                                          colours[theme].primary_04
                                        : customPassiveBgColour ??
                                          colours[theme]
                                              .background_neutral_subtle,
                                justifyContent: 'space-between',
                            }}
                            onClick={() => {
                                onChange(option.uid)
                            }}
                        >
                            <OptionText>{option.name}</OptionText>
                            <Radio
                                id={option.uid}
                                type="radio"
                                value={option.uid}
                                name={option.name}
                                style={{ margin: 0 }}
                            />
                        </Option>
                    </Expander>
                )
            })}
        </CustomRadioGroup>
    )
}

export default BgRadioInput
