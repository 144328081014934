import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import * as NavigationIcons from '../../atoms/statefulicons'
import CarUserCircle from './carUserCircle'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding-bottom: 24px;
    @media ${device.mobile_and_ipad} {
        padding-bottom: 0;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 2px;
    padding-bottom: 2px;
    align-items: center;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    /* width: 46vw; */
    max-width: 750px;
    padding-left: 24px;
    text-align: left !important;
    @media ${device.mobile_and_ipad} {
        padding-left: 8px;
    }
`

const CarTitle = styled.div<IStyle>`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: Lato-semibold;
    text-transform: capitalize;
    color: ${(props) => colours[props.$theme].text_strong};
    line-height: 26px;

    @media ${device.smallest_laptops} {
        font-size: 22px;
    }
    @media ${device.mobile_and_ipad} {
        font-size: 22px;
    }
`

const OwnedBy = styled.div<IStyle>`
    font-family: Lato;
    font-size: 16px;
    color: ${(props) => colours[props.$theme].text_default};
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const Edit = styled.div<IStyle>`
    cursor: pointer;
    height: 100%;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    :hover {
        background-color: ${(props) =>
            colours[props.$theme].primary_underlay} !important;
        transition: background-color 200ms;
    }

    @media ${device.ipad} {
        padding-left: 10px;
    }
`

interface Props {
    url: string | undefined
    car_title: string
    name: string | undefined
    readonlyMode?: boolean
    openForm?: () => void
    dataCyId?: string
    isMobile?: boolean
}

export default function CarUserAndTitleBox(props: Props) {
    let { url, car_title, name, readonlyMode, openForm, dataCyId, isMobile } =
        props

    const { theme } = useThemes()
    return (
        <Wrapper>
            <Row>
                <CarUserCircle
                    url={url && url}
                    size={isMobile ? '24px' : '64px'}
                />

                <Col>
                    {!isMobile && (
                        <CarTitle $theme={theme}>
                            {car_title}
                            {!readonlyMode && (
                                <Edit $theme={theme}>
                                    <NavigationIcons.EditIcon
                                        onClick={(e: any) => {
                                            e.preventDefault()

                                            openForm && openForm()
                                        }}
                                        dataCyId={dataCyId && dataCyId}
                                    />
                                </Edit>
                            )}
                        </CarTitle>
                    )}

                    {!isMobile && <div style={{ paddingTop: '5px' }} />}

                    <OwnedBy $theme={theme}>Owned by {name}</OwnedBy>
                </Col>
            </Row>
        </Wrapper>
    )
}
