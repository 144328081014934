import * as React from 'react'
import { Slider } from 'antd'
import './styles.css'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

type Props = {
    value: any
    min: number
    max: number
    step: number
    onChange: any
}

const SliderStyled = styled.div`
    width: 100%;
    box-sizing: border-box;

    min-width: 500px;

    @media ${device.mobile} {
        min-width: 100%;
        box-sizing: border-box;
    }
`

const SliderAllDevice: React.FC<Props> = ({
    onChange,
    min,
    max,
    value,
    step,
}) => {
    return (
        <SliderStyled>
            <Slider
                defaultValue={0}
                onChange={onChange}
                min={min}
                max={max}
                value={value}
                step={step}
                range={false}
            />
        </SliderStyled>
    )
}

export default SliderAllDevice
