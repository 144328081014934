import { ISearchShowroom_API_car_item } from '../../../../redux/services/showroom/types'
import { IUser } from '../../../../redux/user/types'
import ShowroomCardDesktop from '../../../molecules/showroom/card/showroomCardDesktop'

import Faded from '../../../templates/animated/faded'

type Props = {
    entriesList: ISearchShowroom_API_car_item[]
    userLoggedIn?: IUser | null
    handleChangeSearchEffectsReset: () => any
    anchor_entry_params?: string | undefined | null
    cardAnchoredRefMobile?: any
    anchor_id_redux?: string | undefined
}

const ShowroomCardsContainerMobile = (props: Props) => {
    let {
        entriesList,
        userLoggedIn,
        handleChangeSearchEffectsReset,
        anchor_entry_params,
        anchor_id_redux,
        cardAnchoredRefMobile,
    } = props

    return (
        <>
            {entriesList.map((entry: ISearchShowroom_API_car_item) => {
                return (
                    <Faded width={'100%'} key={`mobile-${entry.uid}-key`}>
                        <ShowroomCardDesktop
                            entry={entry}
                            user={userLoggedIn ?? undefined}
                            reset={handleChangeSearchEffectsReset}
                            refCreated={
                                anchor_entry_params === entry.uid
                                    ? cardAnchoredRefMobile
                                    : anchor_id_redux === entry.uid
                                    ? cardAnchoredRefMobile
                                    : undefined
                            }
                        />
                    </Faded>
                )
            })}
        </>
    )
}

export default ShowroomCardsContainerMobile
