import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { IDeleteTaskReminderAPIReq, IReminderReqPayloadAPI } from '../types'

export const createReminderAPI = (
    taskReminderData: IReminderReqPayloadAPI
): Promise<any> => {
    let finalData = JSON.stringify({
        scheduled_at: taskReminderData.scheduled_at,
    })

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: finalData,
        credentials: 'include',
    }

    return fetch(
        urls.task_reminders_endpoint(taskReminderData.taskUID),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Create task reminder error',
                        finalData
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const getTaskReminders = (task_id: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.task_reminders_endpoint(task_id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get task reminders error')
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const deleteTaskReminder = (
    p: IDeleteTaskReminderAPIReq
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.delete_task_reminder_endpoint(p), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(response, 'Delete task reminder error')
                return Promise.reject(response)
            } else return
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
