import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import colours, {
    insurance_colours,
} from '../../../../../../providers/theme/colours'
import useThemes from '../../../../../../providers/theme/hooks'
import {
    IInsuranceQuoteApplication_OtherDriver,
    insuranceActions,
    IAnyErrorString,
    IInsuranceQuoteApplication,
    IOtherDriver_DriveList,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import IconAndTextNakedV2 from '../../../../../atoms/Button/iconAndTextNakedV2'
import FormMultipleStepsProgress from '../../../../../atoms/FormStepProgress/FormMultipleStepsProgress'
import InsuranceDriversCard from '../../../../../molecules/cards/insurance/driversCard'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import RightSideModalDisplay from '../../../../../templates/displays/pageWrappers/rightSideModalDisplay'
import DriversModalManager from '../../../modalManagers/driversModalManager'
import { IUserAddressApi } from '../../../../../../redux/services/typedefinitions/apiPayloads'

const Title = styled.div`
    width: 100%;
    box-sizing: border-box;
    color: #b3b3b3;
    font-size: 12px;
    font-family: Lato-bold;
    line-height: 16px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
    text-align: left;
    padding-bottom: 16px;
`

const Text = styled.div`
    width: 100%;
    box-sizing: border-box;
    color: var(--text-default, #666);
    /* Mobile/Text/T300 Regular */
    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
    padding-top: 10px;
    padding-bottom: 40px;
`

export type IDriver = {
    id: string
    name: string
    completionLevel: number
    driveListIds: string[]
    assigned: boolean
    address?: IUserAddressApi
}

export type IDriversActiveModal =
    | {
          title?: string
          active_id: 'drive_list' | 'details' | 'new_driver'
      }
    | undefined

type Props = {
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const InsuranceApplicationDriversSelectorWizard = (props: Props) => {
    const navigate = useNavigate()

    const dispatch: any = useAppDispatch()

    const { id } = useParams<{ id?: string }>()

    const carsData = useAppSelector((state) => state.entities.carsData)

    const [activeModal, toggleActiveModal] =
        useState<IDriversActiveModal>(undefined)

    const [activeDriverID, setActiveDriverID] = useState<string | undefined>(
        undefined
    )

    let draft = useAppSelector((state) => state.insuranceQuoteApplication.draft)

    let other_drivers = draft?.other_drivers
    let selected_named_drivers_in_state = draft?.selected_named_drivers

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let {
        getUserAndApplicationData,
        getFirstIncompleteSubsection_OtherDrivers,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let createQuery = queryParams.get('create')

    useEffect(() => {
        if (createQuery === 'true') {
            toggleActiveModal({
                active_id: 'new_driver',
                title: 'Add new driver',
            })
        }
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    const goPrevious = () => {
        navigate(
            `/insurance/application/${applicationData?.id}/wizard?section_id=main_policy_holder&subsection_id=track_record`
        )
    }

    const goNext = () => {
        // here actually we'll want to redirect to application (exit) with validation showing.
        let ids = getFirstIncompleteSubsection_OtherDrivers()

        if (ids && ids.sub_sectionID) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return navigate(
                {
                    search: params2.toString(),
                },
                { replace: true }
            )
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('drivers')

            // here last section, so we expect something next up which isnt a dash
            if (ids2 && ids2.sub_sectionID) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                navigate(
                    {
                        search: params2.toString(),
                    },
                    { replace: true }
                )
            } else {
                dispatch(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...applicationData },
                        activateValidationSheet: true,
                        isWizard: true,
                    })
                )
                navigate(`/insurance/application/${applicationData?.id}`)
            }
        }
        return getToAny()
    }

    useEffect(() => {
        props.setTopWizardCheckActions(null)
    }, [])

    const frequencyErrorMessage = 'Use frequency is mandatory to fill in.'

    const isFrequenciesSaveAllowed = (): true | false => {
        let res: boolean = true
        if (other_drivers) {
            let otherDriversKeys = Object.keys(other_drivers)

            for (let i = 0; i < otherDriversKeys.length; i++) {
                let otherdriverObj: IInsuranceQuoteApplication_OtherDriver =
                    other_drivers[otherDriversKeys[i]]

                // console.log(
                //     'otherdriverObj',
                //     otherdriverObj,
                //     'other_drivers',
                //     other_drivers
                // )

                if (otherdriverObj && otherdriverObj.drive_list) {
                    for (let a = 0; a < otherdriverObj.drive_list.length; a++) {
                        let drivelistItem = otherdriverObj.drive_list[a]

                        if (!drivelistItem.use_frequency) {
                            res = false
                        }
                    }
                }
            }
        }

        setErrorObj(
            res !== true ? { use_frequency: frequencyErrorMessage } : undefined
        )

        return res
    }

    const isNewDriverSaveAllowed = (): true | false => {
        let res: boolean = true
        if (
            other_drivers &&
            other_drivers['new_driver_uid'] &&
            other_drivers['new_driver_uid'].basic_details
        ) {
            if (
                !other_drivers['new_driver_uid'].basic_details.given_name ||
                !other_drivers['new_driver_uid'].basic_details.family_name
            ) {
                if (
                    other_drivers['new_driver_uid'].basic_details &&
                    !other_drivers['new_driver_uid'].basic_details.given_name
                ) {
                    dispatch(
                        insuranceActions.set_error_insurance_other_driver_basic_details(
                            {
                                id: 'given_name',
                                error: 'Missing',
                                userid: 'new_driver_uid',
                            }
                        )
                    )
                }
                if (
                    other_drivers['new_driver_uid'].basic_details &&
                    !other_drivers['new_driver_uid'].basic_details.family_name
                ) {
                    dispatch(
                        insuranceActions.set_error_insurance_other_driver_basic_details(
                            {
                                id: 'family_name',
                                error: 'Missing',
                                userid: 'new_driver_uid',
                            }
                        )
                    )
                }
                res = false
            }
        }

        return res
    }

    const [errorObj, setErrorObj] = useState<IAnyErrorString | undefined>(
        undefined
    )

    let drivers_completion: number[] = selected_named_drivers_in_state
        ? selected_named_drivers_in_state.map(
              (key) => draft?.other_drivers[key]?.completion ?? 0
          )
        : []

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let is_draft = applicationData.status === 'DRAFT'

    const { theme } = useThemes()

    return userLoggedIn ? (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '566px',
                            paddingTop: 40,
                            paddingBottom: 120,
                        }}
                    >
                        <Title>Select up to 4 drivers</Title>
                        <div
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                opacity: drivers_completion.length > 0 ? 1 : 0,
                                transition: 'opacity 200ms',
                            }}
                        >
                            <FormMultipleStepsProgress
                                arrayOfProgressBarsValues={drivers_completion}
                                progress_base_colour={
                                    insurance_colours[theme].sections['drivers']
                                        .primary_16
                                }
                                progress_filled_colour={
                                    insurance_colours[theme].sections['drivers']
                                        .primary_100
                                }
                            />
                        </div>

                        <Text>
                            You can add up to four additional drivers to your
                            insurance policy. Select the drivers who will
                            actively share the joy of driving your insured
                            vehicles. You can always add or update drivers here.
                        </Text>

                        {Object.keys(other_drivers).map((driver_id) => {
                            if (
                                !other_drivers[driver_id].basic_details
                                    ?.family_name &&
                                !other_drivers[driver_id].basic_details
                                    ?.given_name
                            ) {
                                return null
                            } else
                                return (
                                    <InsuranceDriversCard
                                        driver={other_drivers[driver_id]}
                                        carsData={carsData.cars}
                                        assignCarsToDriver={() => {
                                            setActiveDriverID(driver_id)
                                            toggleActiveModal({
                                                active_id: 'drive_list',
                                                title: 'Drive list',
                                            })
                                        }}
                                        is_wizard={true}
                                        editDriverDetails={() => {
                                            is_draft &&
                                                navigate(
                                                    `/insurance/application/${id}/other_driver/${driver_id}`
                                                )
                                        }}
                                        isSelected={
                                            selected_named_drivers_in_state?.indexOf(
                                                driver_id
                                            ) === -1
                                                ? false
                                                : true
                                        }
                                        isDisabled={
                                            !is_draft
                                                ? true
                                                : selected_named_drivers_in_state?.indexOf(
                                                      driver_id
                                                  ) === -1 &&
                                                  selected_named_drivers_in_state &&
                                                  selected_named_drivers_in_state.length ===
                                                      4
                                                ? true
                                                : false
                                        }
                                        onSelect={(p: boolean) => {
                                            if (is_draft) {
                                                dispatch(
                                                    insuranceActions.set_answer_other_driver_assigned(
                                                        {
                                                            id: 'assign_driver',
                                                            userid: driver_id,
                                                            answer: p,
                                                        }
                                                    )
                                                )

                                                setTimeout(() => {
                                                    dispatch(
                                                        insuranceActions.submit_driversDashboard_request()
                                                    )
                                                }, 100)
                                            }
                                        }}
                                        menu_items={[
                                            {
                                                id: 'edit',
                                                text: 'Edit Details',
                                                action: () => {
                                                    navigate(
                                                        `/insurance/application/${id}/other_driver/${driver_id}`
                                                    )
                                                },
                                            },
                                            {
                                                id: 'assign_cars',
                                                text: 'Assign Cars',
                                                action: () => {
                                                    setActiveDriverID(driver_id)
                                                    toggleActiveModal({
                                                        active_id: 'drive_list',
                                                        title: 'Drive list',
                                                    })
                                                },
                                            },
                                            {
                                                id: 'remove',
                                                text: 'Delete Driver',
                                                action: () => {
                                                    dispatch(
                                                        insuranceActions.delete_otherDriver_request(
                                                            {
                                                                driver_id:
                                                                    driver_id,
                                                            }
                                                        )
                                                    )
                                                },
                                                color: '#DF6F6F',
                                            },
                                        ]}
                                    />
                                )
                        })}
                        {applicationData.status === 'DRAFT' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <IconAndTextNakedV2
                                    onClick={() => {
                                        toggleActiveModal({
                                            active_id: 'new_driver',
                                            title: 'Add new driver',
                                        })
                                    }}
                                    text=" Add driver"
                                    custom_colour={
                                        insurance_colours[theme].sections[
                                            'drivers'
                                        ].primary_100
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    onSave={goNext}
                    goBack={() => goPrevious()}
                    sectionId="drivers"
                />
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <div
                    style={{
                        width: '88vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingBottom: 120,
                        paddingTop: 40,
                    }}
                >
                    <Title>Select up to 4 drivers</Title>

                    <FormMultipleStepsProgress
                        arrayOfProgressBarsValues={drivers_completion}
                        progress_base_colour={
                            insurance_colours[theme].sections['drivers']
                                .primary_16
                        }
                        progress_filled_colour={
                            insurance_colours[theme].sections['drivers']
                                .primary_100
                        }
                    />
                    <Text>
                        You can add up to four additional drivers to your
                        insurance policy. Select the drivers who will actively
                        share the joy of driving your insured vehicles. You can
                        always add or update drivers here.
                    </Text>
                    {Object.keys(other_drivers).map((driver_id) => {
                        if (
                            !other_drivers[driver_id].basic_details
                                ?.family_name &&
                            !other_drivers[driver_id].basic_details?.given_name
                        ) {
                            return null
                        } else
                            return (
                                <InsuranceDriversCard
                                    isMobile
                                    driver={other_drivers[driver_id]}
                                    carsData={carsData.cars}
                                    assignCarsToDriver={() => {
                                        setActiveDriverID(driver_id)
                                        toggleActiveModal({
                                            active_id: 'drive_list',
                                            title: 'Drive list',
                                        })
                                    }}
                                    is_wizard={true}
                                    editDriverDetails={() => {
                                        navigate(
                                            `/insurance/application/${id}/other_driver/${driver_id}`
                                        )
                                    }}
                                    isSelected={
                                        selected_named_drivers_in_state?.indexOf(
                                            driver_id
                                        ) === -1
                                            ? false
                                            : true
                                    }
                                    isDisabled={
                                        selected_named_drivers_in_state?.indexOf(
                                            driver_id
                                        ) === -1 &&
                                        selected_named_drivers_in_state &&
                                        selected_named_drivers_in_state.length ===
                                            4
                                            ? true
                                            : false
                                    }
                                    onSelect={(p: boolean) => {
                                        dispatch(
                                            insuranceActions.set_answer_other_driver_assigned(
                                                {
                                                    id: 'assign_driver',
                                                    userid: driver_id,
                                                    answer: p,
                                                }
                                            )
                                        )

                                        setTimeout(() => {
                                            dispatch(
                                                insuranceActions.submit_driversDashboard_request()
                                            )
                                        }, 100)
                                    }}
                                    menu_items={[
                                        {
                                            id: 'edit',
                                            text: 'Edit Details',
                                            action: () => {
                                                navigate(
                                                    `/insurance/application/${id}/other_driver/${driver_id}`
                                                )
                                            },
                                        },
                                        {
                                            id: 'assign_cars',
                                            text: 'Assign Cars',
                                            action: () => {
                                                setActiveDriverID(driver_id)
                                                toggleActiveModal({
                                                    active_id: 'drive_list',
                                                    title: 'Drive list',
                                                })
                                            },
                                        },
                                        {
                                            id: 'remove',
                                            text: 'Delete Driver',
                                            action: () => {
                                                dispatch(
                                                    insuranceActions.delete_otherDriver_request(
                                                        {
                                                            driver_id:
                                                                driver_id,
                                                        }
                                                    )
                                                )
                                            },
                                            color: '#DF6F6F',
                                        },
                                    ]}
                                />
                            )
                    })}
                    {applicationData.status === 'DRAFT' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        >
                            <IconAndTextNakedV2
                                onClick={() => {
                                    toggleActiveModal({
                                        active_id: 'new_driver',
                                        title: 'Add new driver',
                                    })
                                }}
                                text=" Add driver"
                                custom_colour={
                                    insurance_colours[theme].sections['drivers']
                                        .primary_100
                                }
                            />
                        </div>
                    )}
                </div>
                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    onSave={goNext}
                    goBack={() => goPrevious()}
                    sectionId="drivers"
                />
            </IpadAndMobileDisplay>
            {activeModal && (
                <RightSideModalDisplay
                    isOpen={activeModal.active_id ? true : false}
                    cancel={() => {
                        // remove_initialized_new_other_driver
                        let savedID = activeDriverID
                        if (savedID) {
                            setTimeout(() => {
                                dispatch(
                                    insuranceActions.remove_other_driver_edits(
                                        `${savedID}`
                                    )
                                )
                            }, 101)
                        }

                        if (
                            activeModal.active_id === 'drive_list' &&
                            activeDriverID
                        ) {
                            dispatch(
                                insuranceActions.reset_other_driver_drive_list({
                                    userid: activeDriverID,
                                })
                            )
                        }

                        setTimeout(() => {
                            toggleActiveModal(undefined)
                            setActiveDriverID(undefined)
                        }, 100)
                    }}
                    title={activeModal?.title}
                    sectionId={'drivers'}
                    footerBtn={{
                        action: () => {
                            let savedActiveDriverID = activeDriverID

                            if (
                                activeModal.active_id === 'drive_list' &&
                                savedActiveDriverID
                            ) {
                                if (isFrequenciesSaveAllowed() === true) {
                                    dispatch(
                                        insuranceActions.submit_otherDriver_driveList_request(
                                            {
                                                driver_id: `${savedActiveDriverID}`,
                                            }
                                        )
                                    )

                                    setTimeout(() => {
                                        toggleActiveModal(undefined)
                                        setActiveDriverID(undefined)
                                    }, 200)
                                }
                            } else if (
                                activeModal.active_id === 'new_driver' &&
                                savedActiveDriverID
                            ) {
                                if (isNewDriverSaveAllowed()) {
                                    dispatch(
                                        insuranceActions.submit_otherDriver_basicDetails_request(
                                            {
                                                driver_id: savedActiveDriverID,
                                                autoSelect: true,
                                            }
                                        )
                                    )

                                    setTimeout(() => {
                                        toggleActiveModal(undefined)
                                        setActiveDriverID(undefined)
                                    }, 200)
                                }
                            }
                        },
                        text: 'Save',
                    }}
                >
                    <DriversModalManager
                        all_cars={carsData.cars}
                        active_modal={activeModal.active_id}
                        driverID={activeDriverID}
                        initialiseDriver={() => {
                            let initialisedID = 'new_driver_uid'
                            setActiveDriverID(initialisedID)
                            dispatch(
                                insuranceActions.initialize_new_other_driver()
                            )
                        }}
                        errorObj={errorObj}
                        updateDriver={(
                            id: 'drive_list' | string, // details TBC
                            value: IOtherDriver_DriveList[] | any
                        ) => {
                            if (id === 'drive_list' && activeDriverID) {
                                dispatch(
                                    insuranceActions.set_answer_other_driver_drive_list(
                                        {
                                            id: 'drive_list',
                                            userid: activeDriverID,
                                            answer: value,
                                        }
                                    )
                                )
                            } else if (activeDriverID) {
                                dispatch(
                                    insuranceActions.set_answer_other_driver_basicDetails(
                                        {
                                            id: id,
                                            userid: activeDriverID,
                                            answer: value,
                                        }
                                    )
                                )
                            }
                        }}
                    />
                </RightSideModalDisplay>
            )}
        </div>
    ) : (
        <div />
    )
}

export default InsuranceApplicationDriversSelectorWizard
