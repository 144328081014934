import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import blank_user_dark from '../../../public/assets/placeholders_svgs/avatar_dark.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import useThemes from '../../../providers/theme/hooks'

type IStyleProps = {
    hasImage?: boolean
    size?: string
}

const UserImgWrapper = styled.div<IStyleProps>`
    position: absolute;
    width: 116px;
    height: 116px;
    top: -0px;
    border-radius: 16px;
    border: ${(props) => (props.hasImage ? 'none' : 'solid 1px #ececec')};

    @media ${device.desktop} {
    }
    @media ${device.smallest_laptops} {
    }
`

const UserImg = styled.img`
    height: 100%;
    border-radius: 16px;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
`

type Props = {
    url?: string | null | false | undefined
    onClick?: any
}

const UserAccountSquare: React.FC<Props> = ({ url, onClick }) => {
    const [src, setSrc] = React.useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    let { theme } = useThemes()

    React.useEffect(() => {
        const initialSrc =
            url !== undefined && url !== false && url !== null && url.length > 1
                ? url
                : theme === 'dark'
                ? blank_user_dark
                : blank_user
        if (initialSrc) {
            setSrc(initialSrc)
        }
    }, [url])

    return (
        <Wrapper
            style={{
                width: '116px',
            }}
            onClick={() => onClick && onClick()}
        >
            <UserImgWrapper hasImage={src !== undefined}>
                <UserImg
                    src={src}
                    onLoad={() => {
                        setIsLoading(false)
                    }}
                />
                <SkeletonAbsolute
                    isthingloading={isLoading}
                    customBorderRadius={'16px'}
                />
            </UserImgWrapper>
        </Wrapper>
    )
}

export default UserAccountSquare
