import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import blank_user_dark from '../../../public/assets/placeholders_svgs/avatar_dark.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import useThemes from '../../../providers/theme/hooks'

type IStyleProps = {
    hasImage?: boolean
    size?: string
}

const UserImgWrapper = styled.div<IStyleProps>`
    position: absolute;
    width: ${(props) => (props.size ? props.size : '22px')};
    height: ${(props) => (props.size ? props.size : '22px')};
    top: -0px;
    border-radius: 50%;
    border: ${(props) =>
        props.hasImage
            ? 'solid 1px var(--primary, #5ec3ca)'
            : 'solid 1px var(--border-muted, #e5e5e5)'};

    @media ${device.desktop} {
    }
    @media ${device.smallest_laptops} {
    }
`

const UserImg = styled.img`
    height: 100%;
    border-radius: 50%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
`

type Props = {
    size?: string
    url?: string | null | false | undefined
    onClick?: any
}

const UserAccountCircle: React.FC<Props> = ({ size, url, onClick }) => {
    const [src, setSrc] = React.useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    let { theme } = useThemes()

    React.useEffect(() => {
        const initialSrc =
            url !== undefined && url !== false && url !== null && url.length > 1
                ? url
                : theme === 'dark'
                ? blank_user_dark
                : blank_user
        if (initialSrc) {
            setSrc(initialSrc)
        }
    }, [url])

    return (
        <Wrapper
            style={{
                width: size ? size : '22px',
            }}
            onClick={() => onClick && onClick()}
        >
            <UserImgWrapper hasImage={src !== undefined} size={size}>
                <UserImg src={src} onLoad={() => setIsLoading(false)} />
                <SkeletonAbsolute isthingloading={isLoading} isRound={true} />
            </UserImgWrapper>
        </Wrapper>
    )
}

export default UserAccountCircle
