import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertAPIEntryCostToEntryCostSingle } from '../../conversions/timeline/entryCostToCostItem'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ConvertAttachmentAPIToAttachmentState } from '../../conversions/attachments'
import { selectStateUserCurrency } from '../../user/sagas/getUserData'
import { ICurencyUIDsEnum } from '../timelineEnum'
import { attachmentActions } from '../../attachments/reducer'
import { timelineActions } from '../reducer'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'
import { IEntryCostPayloadAPI } from '../../services/typedefinitions/apiPayloads'
import { IApiEntryCostGET_args } from '../../services/types'
import { IGetCostEntryPayloadRequest, ICostItem } from '../types'

function* GetEntryCostByIdSaga(payload: IGetCostEntryPayloadRequest): any {
    try {
        let user_current_curr: ICurencyUIDsEnum = yield call(
            selectStateUserCurrency
        )

        //Step 1 - call the api func and keep data returned in a const
        let api_payload: IApiEntryCostGET_args = {
            entry_id: payload.itemid,
            cost_id: payload.costid,
            car_id: payload.carid,
            user_currency: user_current_curr,
        }
        const entry_cost_from_api: IEntryCostPayloadAPI = yield call(
            api.timeline.costs.getEntryCostData.getEntryCostById,
            api_payload
        )

        // STEP 2 . build up payload to return to reducer
        let res: ICostItem =
            ConvertAPIEntryCostToEntryCostSingle(entry_cost_from_api)

        if (
            entry_cost_from_api.attachments &&
            entry_cost_from_api.attachments.length > 0
        ) {
            let length: number = entry_cost_from_api.attachments.length - 1

            if (entry_cost_from_api.attachments[length].uid) {
                yield put(
                    attachmentActions.addAttachmentsSuccess({
                        [entry_cost_from_api.attachments[length].uid]:
                            ConvertAttachmentAPIToAttachmentState(
                                entry_cost_from_api.attachments[length]
                            ),
                    })
                )
            }
            let payloadWithAttachment: ICostItem | undefined =
                entry_cost_from_api.attachments && {
                    ...res,
                    attachmentID: entry_cost_from_api.attachments[length].uid,
                }
            yield put(
                timelineActions.setActiveCostSuccess(payloadWithAttachment)
            )
            yield put(
                timelineActions.getCostEntryByIdSuccess(payloadWithAttachment)
            )
        } else {
            yield put(timelineActions.setActiveCostSuccess(res))
            yield put(timelineActions.getCostEntryByIdSuccess(res))
        }

        // Capture event
        posthog.capture('GET CAR ENTRY COST')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetEntryCostByIdSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'ok',
                custom_redirect_path: `/car/${payload.carid}/history-file/entry?entryid=${payload.itemid}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(timelineActions.getCostEntryByIdError(customError))
        }
    }
}

function* watcherGetEntryCost() {
    while (true) {
        const { payload } = yield take(timelineActions.getCostEntryByIdRequest)

        yield call(GetEntryCostByIdSaga, payload)
    }
}

const get_entry_cost_by_id: any[] = [fork(watcherGetEntryCost)]

export default get_entry_cost_by_id
