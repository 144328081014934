import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import SelectSearchBarCarInfoMobile from '../../organisms/editForms/technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'

const CountryPicker: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const countriesDropdownList = useAppSelector(
        (state) => state.localdata.dropdownData.countriesCode
    )

    useEffect(() => {
        dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
    }, [])

    const handleChange = (name: string) => {
        dispatch(dropDownActions.setSelectedCountry(name))

        const params = new URLSearchParams(location.search)
        let returnTo = params.get('return_to')

        dispatch(editFormsActions.toggleAddACarForm(true))

        navigate(returnTo ?? '/garage')
    }

    const searchOptionsData = {
        text: 'country',
        id: 'country',
        answer: '',
        unique_answer_id: undefined,
    }

    return (
        <>
            <SelectSearchBarCarInfoMobile
                handleSelectChange={(
                    name: string,
                    other?: string | undefined
                ) => {
                    handleChange(name)
                }}
                items={
                    countriesDropdownList?.map((country) => country.name) ?? []
                }
                item={searchOptionsData}
                isStandalonePage={true}
                isKeyValue={true}
            />
        </>
    )
}

export default CountryPicker
