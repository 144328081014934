import * as React from 'react'
import styled from 'styled-components'
import QrCodeSVG from '../../../atoms/qrCodeSvg/QrCodeSvg'
import { useState } from 'react'
import posthog from 'posthog-js'
import { generateCustomCodeFile } from '../fns'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { journey_colours } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import DownloadIcon from '../../../atoms/icons/components/downloadIcon'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 56px;
    width: 100%;
    box-sizing: border-box;
    gap: 40px;
`

const SVGStickerWrapper = styled.div`
    width: 260px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.05);
`

type ISvgColours = {
    $colour: string
}
const SVGColouredCircle = styled.div<ISvgColours>`
    width: 240px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => (props.$colour ? props.$colour : '#ffe5fb')};
`
const SVGBackgroundWrapper = styled.div`
    width: 205px;
    height: 205px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
`
const SVGBackground = styled.div<ISvgColours>`
    width: 190px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => (props.$colour ? props.$colour : '#fcf8ec')};
`

const BtnsWrapper = styled.div`
    display: flex;
    gap: 24px;
`

type Props = {
    qr_code_id: string
    qr_code_link: string
    title: string
    state: {
        colours: {
            colour_one: string
            colour_two: string
        }
        openColourPicker?: 'one' | 'two' | undefined
    }
    dispatch: any
    activeThemeIndex: number | null
    setActiveThemeIndex: any
}

const QrCodeStickerJourneyFormDesktopRight: React.FunctionComponent<Props> = (
    props
) => {
    let { qr_code_id, qr_code_link, state, title } = props

    const [count, setCount] = useState<number>(0)

    let svg_id: string = `preview_${qr_code_id}_custom_mobile_${count}`

    const { theme } = useThemes()

    return (
        <Wrapper>
            <SVGStickerWrapper>
                <SVGColouredCircle $colour={state.colours.colour_one}>
                    <SVGBackgroundWrapper>
                        <SVGBackground $colour={state.colours.colour_two}>
                            <QrCodeSVG
                                bgColor="transparent"
                                link={qr_code_link}
                                size={130}
                                withLogo
                                id={svg_id}
                                logoSize={32}
                            />
                        </SVGBackground>
                    </SVGBackgroundWrapper>
                </SVGColouredCircle>
            </SVGStickerWrapper>

            <BtnsWrapper>
                <StandardCtaBtn
                    boder={`1px solid ${journey_colours[theme].section.share_your_car.primary_500}`}
                    bg="transparent"
                    customHeight="auto"
                    customWidth="188px"
                    onClick={() => {
                        generateCustomCodeFile({
                            colour_background: state.colours.colour_two,
                            colour_circle: state.colours.colour_one,
                            svg_qr_code_id: svg_id,
                            title: title,
                            format: 'PNG',
                            device: 'desktop',
                        })
                        setCount(count + 1)
                        // capture qr_code custom PNG image generation/download
                        posthog.capture(
                            'custom_qr_code_png_img_download_btn clicked'
                        )
                        return
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                        }}
                    >
                        <DownloadIcon
                            color={
                                journey_colours[theme].section.share_your_car
                                    .primary_500
                            }
                        />
                        <span
                            style={{
                                color: journey_colours[theme].section
                                    .share_your_car.primary_500,
                            }}
                        >
                            Image .png
                        </span>
                    </div>
                </StandardCtaBtn>

                <StandardCtaBtn
                    boder={`1px solid ${journey_colours[theme].section.share_your_car.primary_500}`}
                    bg="transparent"
                    customHeight="auto"
                    customWidth="188px"
                    onClick={() => {
                        generateCustomCodeFile({
                            colour_background: state.colours.colour_two,
                            colour_circle: state.colours.colour_one,
                            svg_qr_code_id: svg_id,
                            title: '1969 Alfa Romeo 1300 GT Junior',
                            format: 'PDF',
                            device: 'desktop',
                        })
                        setCount(count + 1)
                        // capture qr_code custom PDF doc generation/download
                        posthog.capture(
                            'custom_qr_code_pdf_doc_download_btn clicked'
                        )
                        return
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                        }}
                    >
                        <DownloadIcon
                            color={
                                journey_colours[theme].section.share_your_car
                                    .primary_500
                            }
                        />
                        <span
                            style={{
                                color: journey_colours[theme].section
                                    .share_your_car.primary_500,
                            }}
                        >
                            A4 .pdf
                        </span>
                    </div>
                </StandardCtaBtn>
            </BtnsWrapper>
        </Wrapper>
    )
}

export default QrCodeStickerJourneyFormDesktopRight
