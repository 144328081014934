import styled from 'styled-components'
import CustomIconTagQuickFilterItem from '../../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import { device } from '../../../templates/displays/devices'
import close_cross_blue from '../../../../public/assets/icons/v2_add.svg'
import { motion } from 'framer-motion'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'
import { ITimelineEntryTypes } from '../../../../redux/timeline/types'

const BottomRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: auto;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 0px;
    padding-top: 10px;
    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }
    /* scrollbar styles */
    /* width */
    ::-webkit-scrollbar {
        height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--border-muted-lighter);
        border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--border-muted, #e5e5e5);
    }
`

const RowTags = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    transform: translateY(-2px);
`

const ClearFilters = styled.div`
    position: absolute;
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    /* border-right: 1px solid var(--border-muted, #e5e5e5); */
    /* padding-right: 10px; */
`
const Line = styled.div`
    height: 16px;
    width: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

interface Props {
    labels: string[] | null | undefined
    categories: ITimelineEntryTypes[]
    onLabelCrossClick: (item: string) => void
    onCategoryCrossClick: (item: ITimelineEntryTypes) => void
    clearFilters: () => void
}

export default function BottomRowWSelectedLabels(props: Props) {
    const {
        categories,
        labels,
        onLabelCrossClick,
        onCategoryCrossClick,
        clearFilters,
    } = props

    const { theme } = useThemes()

    return (
        <BottomRow>
            <div style={{ marginRight: '58px' }} />
            {categories &&
                categories.map((name: ITimelineEntryTypes, index: number) => (
                    <RowTags key={index}>
                        <div
                            style={{
                                paddingRight: '10px',
                                paddingBottom: '10px',
                            }}
                        >
                            <CustomIconTagQuickFilterItem
                                id={name}
                                height="24px"
                                onCrossClick={() => onCategoryCrossClick(name)}
                            >
                                {name}
                            </CustomIconTagQuickFilterItem>
                        </div>
                    </RowTags>
                ))}
            {labels &&
                labels.map((item: string, index: number) => (
                    <RowTags key={index}>
                        <div
                            style={{
                                paddingRight: '10px',
                                paddingBottom: '10px',
                            }}
                        >
                            <CustomIconTagQuickFilterItem
                                isActive={true}
                                onCrossClick={() => {
                                    onLabelCrossClick(item)
                                }}
                                border={`1px solid ${colours[theme].border_muted}`}
                                height="25px"
                                bgcolor={colours[theme].background_default}
                                isLabel
                            >
                                {item}
                            </CustomIconTagQuickFilterItem>
                        </div>
                    </RowTags>
                ))}
            <ClearFilters onClick={clearFilters}>
                <motion.div whileTap={{ scale: 1.1 }}>
                    <img
                        src={close_cross_blue}
                        style={{
                            transform: 'rotate(45deg)',
                        }}
                        alt="close"
                    />
                </motion.div>
                <Line />
                <div />
            </ClearFilters>
        </BottomRow>
    )
}
