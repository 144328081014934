import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import RemindersFieldsManager from '../../organisms/remindersFieldsManager'
import {
    IReminder,
    IReminderEdited,
    IReminderEditedData,
    ITask,
} from 'entityModels'
import ButtonsStandardBottomRowMobile from '../../organisms/editForms/buttons/buttonsStandardBottomRowMobile'
import styled from 'styled-components'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import RemindersTopText from '../../atoms/typography/remindersTopText'
import ButtonsStandardBottomRowDesktop from '../../organisms/editForms/buttons/buttonsStandardBottomRowDesktop'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import ModalDisplayDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayDesktopExclusive'
import RemindersViewManager from '../../organisms/remindersViewManager/remindersViewManager'
import Expander from '../../atoms/expander/expander'

import {
    fromReminderDateToBackEndFormat,
    IConvertReminderDateToBEPayload,
} from '../../../redux/conversions/time'

import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
} from '../../molecules/technicalInformation/modal'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import dayjs from 'dayjs'
import { tasksActions } from '../../../redux/tasks/reducer'
import useThemes from '../../../providers/theme/hooks'
const { v4: uuidv4 } = require('uuid')

export const isDateValid = (payload: IIsDateValidPayload): boolean => {
    // Validate required fields exist
    if (
        !payload.type_val ||
        payload.nVal === undefined ||
        payload.type_val === '-'
    ) {
        return false
    }

    let p: IConvertReminderDateToBEPayload = {
        type: payload.type_val,
        value: '',
        nvalue: payload.nVal,
        dueDate: payload.dueDate
            ? dayjs(payload.dueDate).isValid()
                ? payload.dueDate
                : dayjs().format()
            : payload.maxDate
            ? dayjs(payload.maxDate).isValid()
                ? payload.maxDate
                : dayjs().format()
            : dayjs().format(),
    }

    try {
        let be_date: string = fromReminderDateToBackEndFormat(p)

        if (payload.maxDate && dayjs(payload.maxDate).isValid()) {
            let isTooLate: boolean = dayjs(be_date).isAfter(payload.maxDate)
            if (isTooLate === true) {
                return false
            }
        }

        if (payload.minDate && dayjs(payload.minDate).isValid()) {
            let isTooEarly: boolean =
                dayjs(be_date).isBefore(payload.minDate) ||
                dayjs(be_date).isSame(payload.minDate)
            if (isTooEarly === true) {
                return false
            }
        }
        return true
    } catch (error) {
        console.error('Error validating date:', error)
        return false
    }
}

const MobileWrapper = styled.div`
    background-color: var(--bg-color, #fff);
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 60px;
    position: absolute;
    top: 0px;
    bottom: 100px;
    overflow-y: auto;
    left: 0;
    right: 0;
    z-index: 5;
    /* max-width: 450px; */
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`

const MobileButtonsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
    padding-top: 32px;
    padding-bottom: 60px;
`

const DesktopButtonsWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 80px;
`
const MicroPaddingTop = styled.div`
    padding-top: 10px;
`
const SmallPaddingTop = styled.div`
    padding-top: 20px;
`

export type IIsDateValidPayload = {
    type_val: string
    nVal: number
    dueDate?: string
    minDate?: string
    maxDate?: string
}

export type ISetEditedReminderChangeInnerPayload = {
    reminder_id: string
    changeDataType?: 'type' | 'datePicked' | 'nvalue'
    val?: string | number
    isDeletion?: boolean
    hasError?: boolean
}

const TaskReminders: React.FC = () => {
    let { theme } = useThemes()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid, taskid: routeTaskId } = useParams<{
        carid?: string
        taskid?: string
    }>()

    const tasksData = useAppSelector((state) => state.tasks.tasks)

    const [isDesktop, setIsDesktop] = useState(false)
    const [existingRemindersBeingDeleted, setExistingRemindersBeingDeleted] =
        useState<string[]>([])
    const [savedClicked, setSavedClicked] = useState(false)

    const params = new URLSearchParams(location.search)
    const taskid = params.get('taskid') || routeTaskId
    const view_id = params.get('viewid')

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 881)
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (
            taskid &&
            tasksData &&
            tasksData[taskid] &&
            !tasksData[taskid].reminders_being_added &&
            view_id === 'create'
        ) {
            const new_item: IReminderEdited = taskReminderEditedEmpty()
            dispatch(
                tasksActions.setTaskRemindersEditedRequest({
                    taskID: taskid,
                    reminders: {
                        [new_item.id]: new_item,
                    },
                })
            )
        }
    }, [taskid, tasksData, view_id])

    const taskReminderEditedEmpty = (): IReminderEdited => ({
        id: uuidv4(),
        type: undefined,
        datePicked: undefined,
        hasError: false,
    })

    const taskItem: ITask | undefined =
        tasksData && taskid && tasksData[taskid] ? tasksData[taskid] : undefined

    const taskRemindersBeingEdited: IReminderEditedData | undefined =
        taskItem && taskItem.reminders_being_added
            ? taskItem.reminders_being_added
            : view_id === 'create'
            ? {
                  [taskReminderEditedEmpty().id]: taskReminderEditedEmpty(),
              }
            : undefined

    const taskRemindersBeingEditedIdsList: string[] | undefined = !taskItem
        ? undefined
        : taskItem.reminders_being_added
        ? Object.keys(taskItem.reminders_being_added)
        : undefined

    const currentReminders: IReminder[] | undefined = !taskItem
        ? undefined
        : taskItem.reminders

    const handleChange = (p: ISetEditedReminderChangeInnerPayload) => {
        let new_reminders_edited_object_: IReminderEditedData = {
            ...taskRemindersBeingEdited,
        }

        if (p.changeDataType) {
            new_reminders_edited_object_ = {
                ...new_reminders_edited_object_,
                [p.reminder_id]: {
                    ...new_reminders_edited_object_[p.reminder_id],
                    [p.changeDataType]:
                        p.changeDataType === 'nvalue' &&
                        typeof p.val === 'string'
                            ? parseInt(p.val)
                            : p.val,
                    hasError: p.hasError === true,
                },
            }
        }

        if (p.isDeletion) {
            delete new_reminders_edited_object_[p.reminder_id]
        }

        setTimeout(() => {
            taskid &&
                dispatch(
                    tasksActions.setTaskRemindersEditedRequest({
                        taskID: taskid,
                        reminders: new_reminders_edited_object_,
                    })
                )
        }, 300)
    }

    const handleDeletionOfExistingReminderBeforeSave = (
        reminderUID: string
    ) => {
        const existingTaskRemindersList: string[] | undefined =
            taskItem && taskItem.reminders
                ? taskItem.reminders.map((reminder: IReminder) => reminder.id)
                : undefined

        if (
            existingTaskRemindersList &&
            existingTaskRemindersList.includes(reminderUID)
        ) {
            setExistingRemindersBeingDeleted((prev) => [...prev, reminderUID])
        }
    }

    const minDate = dayjs().format('YYYY-MM-DD')

    const checkIfSavedDisabled = (): boolean | undefined => {
        let hasErrorV: boolean = false
        if (taskRemindersBeingEditedIdsList && taskRemindersBeingEdited) {
            for (const reminderID of taskRemindersBeingEditedIdsList) {
                if (
                    taskRemindersBeingEdited[reminderID] &&
                    taskRemindersBeingEdited[reminderID].type &&
                    taskRemindersBeingEdited[reminderID].nvalue
                ) {
                    let nVal = taskRemindersBeingEdited[reminderID].nvalue
                    let ntype = taskRemindersBeingEdited[reminderID].type

                    if (nVal && ntype) {
                        let payload: IIsDateValidPayload = {
                            nVal: nVal,
                            type_val: ntype,
                            minDate: minDate,
                            dueDate: taskItem?.due,
                        }
                        let isValid = isDateValid(payload)

                        if (isValid === false) {
                            hasErrorV = true
                        }
                    }
                }
                if (
                    taskRemindersBeingEdited[reminderID].nvalue === undefined &&
                    taskRemindersBeingEdited[reminderID].type === undefined &&
                    taskRemindersBeingEdited[reminderID].datePicked ===
                        undefined
                ) {
                    hasErrorV = true
                }

                if (
                    taskRemindersBeingEdited[reminderID].datePicked !==
                        undefined &&
                    dayjs(
                        taskRemindersBeingEdited[reminderID].datePicked
                    ).format('YYYY-MM-DD') <= dayjs().format('YYYY-MM-DD')
                ) {
                    hasErrorV = true
                }
                if (
                    taskRemindersBeingEdited[reminderID].type ===
                        'custom_date_copy' &&
                    taskRemindersBeingEdited[reminderID].datePicked ===
                        undefined
                ) {
                    hasErrorV = true
                }
            }
        }

        return hasErrorV
    }

    const onSaveClick = () => {
        setSavedClicked(true)
        if (taskItem) {
            if (taskid && existingRemindersBeingDeleted.length > 0) {
                dispatch(
                    tasksActions.deleteReminderTaskRequest({
                        taskUID: taskid,
                        reminderIDs: existingRemindersBeingDeleted,
                    })
                )
            }
            if (taskItem && taskid && taskItem.reminders_being_added) {
                dispatch(
                    tasksActions.addRemindersToTaskRequest({
                        taskID: taskid,
                        reminders: taskRemindersBeingEdited,
                        readyToSendToApi: true,
                    })
                )
            }
        }

        dispatch(
            tasksActions.setTaskRemindersEditedRequest({
                taskID: `${taskid}`,
                reminders: undefined,
            })
        )

        setTimeout(() => {
            navigate(-1)
        }, 150)
    }

    const addRemindersToBeEdited = () => {
        let new_item_to_be_edited: IReminderEdited = taskReminderEditedEmpty()
        taskid &&
            dispatch(
                tasksActions.setTaskRemindersEditedRequest({
                    taskID: taskid,
                    reminders: {
                        ...taskRemindersBeingEdited,
                        [new_item_to_be_edited.id]: new_item_to_be_edited,
                    },
                })
            )
    }

    const currentPath = location.pathname
    const isCarPage = currentPath.match(/car/g) !== null && carid ? true : false

    useEffect(() => {
        if (isDesktop) {
            const baseUrl = isCarPage
                ? `/car/${carid}/tasks/task`
                : '/tasks/task'
            const viewId =
                taskItem?.reminders && taskItem?.reminders?.length > 0
                    ? 'view'
                    : 'create'
            const url = `${baseUrl}?taskid=${taskid}&reminders=true&viewid=${viewId}`
            navigate(url, { replace: true })
        }
    }, [isDesktop, isCarPage, carid, taskid, taskItem, navigate])

    return (
        <>
            {taskid ? (
                <>
                    <DesktopDisplayOnly>
                        <ModalDisplayDesktopExclusive
                            toggle={() => {
                                isCarPage
                                    ? navigate(
                                          `/car/${carid}/tasks/task?taskid=${taskid}`
                                      )
                                    : navigate(`/tasks/task?taskid=${taskid}`)
                            }}
                            isOpen={true}
                        >
                            <ModalContentDesktop $theme={theme}>
                                <ModalInnerContentWrapperDesktop
                                    style={{ paddingBottom: 40 }}
                                >
                                    <>
                                        <RemindersTopText
                                            task={taskItem}
                                            isCreation={view_id === 'create'}
                                            isView={view_id === 'view'}
                                        />
                                        <div
                                            style={{
                                                paddingTop: '30px',
                                            }}
                                        />

                                        {view_id === 'create' && (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                }}
                                            >
                                                {taskRemindersBeingEditedIdsList &&
                                                taskRemindersBeingEdited !==
                                                    undefined &&
                                                taskRemindersBeingEditedIdsList.length >
                                                    0 &&
                                                !savedClicked ? (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            boxSizing:
                                                                'border-box',
                                                        }}
                                                    >
                                                        {taskRemindersBeingEdited !==
                                                            undefined &&
                                                            taskRemindersBeingEditedIdsList.map(
                                                                (
                                                                    reminder_id: string,
                                                                    index: number
                                                                ) => {
                                                                    if (
                                                                        taskRemindersBeingEdited &&
                                                                        taskRemindersBeingEdited[
                                                                            reminder_id
                                                                        ]
                                                                    ) {
                                                                        return (
                                                                            <div
                                                                                key={`create_${reminder_id}_${index}_desktop`}
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                    zIndex:
                                                                                        50 -
                                                                                        index,
                                                                                }}
                                                                            >
                                                                                <RemindersFieldsManager
                                                                                    handleChange={
                                                                                        handleChange
                                                                                    }
                                                                                    item={
                                                                                        taskRemindersBeingEdited[
                                                                                            reminder_id
                                                                                        ]
                                                                                    }
                                                                                    dueDate={
                                                                                        taskItem?.due ??
                                                                                        dayjs().format()
                                                                                    }
                                                                                    minDate={
                                                                                        minDate
                                                                                    }
                                                                                    variant="desktop"
                                                                                />
                                                                                <MicroPaddingTop />
                                                                            </div>
                                                                        )
                                                                    } else
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            )}

                                                        <SmallPaddingTop />

                                                        <div
                                                            style={{
                                                                display:
                                                                    'unset',
                                                            }}
                                                        >
                                                            <ButtonAtom
                                                                theme="naked-text"
                                                                fontSize={18}
                                                                onClick={
                                                                    addRemindersToBeEdited
                                                                }
                                                                disabled={checkIfSavedDisabled()}
                                                                dataCyId="add_another_reminder_desktop"
                                                            >
                                                                Add another
                                                                reminder
                                                            </ButtonAtom>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <ButtonAtom
                                                        theme="capitalize-white-background"
                                                        width="100%"
                                                        onClick={
                                                            addRemindersToBeEdited
                                                        }
                                                        dataCyId="add_first_reminder_mobile"
                                                    >
                                                        Create Reminder
                                                    </ButtonAtom>
                                                )}
                                            </div>
                                        )}
                                        {view_id === 'view' && (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                }}
                                            >
                                                {currentReminders &&
                                                    currentReminders.map(
                                                        (
                                                            item: IReminder,
                                                            key: number
                                                        ) => (
                                                            <React.Fragment
                                                                key={`view_${item.id}_${key}_desktop`}
                                                            >
                                                                <Expander
                                                                    height={
                                                                        item &&
                                                                        existingRemindersBeingDeleted.includes(
                                                                            item.id
                                                                        )
                                                                            ? 0
                                                                            : 'auto'
                                                                    }
                                                                >
                                                                    <RemindersViewManager
                                                                        reminder={
                                                                            item
                                                                        }
                                                                        deleteReminder={(
                                                                            reminderUID: string
                                                                        ) =>
                                                                            handleDeletionOfExistingReminderBeforeSave(
                                                                                reminderUID
                                                                            )
                                                                        }
                                                                        taskDueDate={
                                                                            taskItem?.due
                                                                        }
                                                                    />
                                                                    <MicroPaddingTop />
                                                                    {currentReminders &&
                                                                        key ===
                                                                            currentReminders?.length -
                                                                                1 && (
                                                                            <div
                                                                                style={{
                                                                                    paddingBottom:
                                                                                        '16px',
                                                                                }}
                                                                            />
                                                                        )}
                                                                </Expander>
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                {taskRemindersBeingEditedIdsList &&
                                                    taskRemindersBeingEdited &&
                                                    !savedClicked && (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                boxSizing:
                                                                    'border-box',
                                                            }}
                                                        >
                                                            {taskRemindersBeingEdited !==
                                                                undefined &&
                                                                taskRemindersBeingEditedIdsList.map(
                                                                    (
                                                                        reminder_id: string,
                                                                        index: number
                                                                    ) => {
                                                                        if (
                                                                            taskRemindersBeingEdited &&
                                                                            taskRemindersBeingEdited[
                                                                                reminder_id
                                                                            ]
                                                                        ) {
                                                                            return (
                                                                                <div
                                                                                    key={`edit_${reminder_id}_${index}_desktop`}
                                                                                    style={{
                                                                                        position:
                                                                                            'relative',
                                                                                        zIndex:
                                                                                            50 -
                                                                                            index,
                                                                                    }}
                                                                                >
                                                                                    <RemindersFieldsManager
                                                                                        handleChange={
                                                                                            handleChange
                                                                                        }
                                                                                        item={
                                                                                            taskRemindersBeingEdited[
                                                                                                reminder_id
                                                                                            ]
                                                                                        }
                                                                                        dueDate={
                                                                                            taskItem?.due ??
                                                                                            dayjs().format()
                                                                                        }
                                                                                        minDate={
                                                                                            minDate
                                                                                        }
                                                                                        variant="desktop"
                                                                                    />
                                                                                    <MicroPaddingTop />
                                                                                </div>
                                                                            )
                                                                        } else
                                                                            return (
                                                                                <div
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                />
                                                                            )
                                                                    }
                                                                )}
                                                        </div>
                                                    )}
                                                <SmallPaddingTop />
                                                <div
                                                    style={{
                                                        transform:
                                                            currentReminders !==
                                                                undefined &&
                                                            currentReminders.length >
                                                                0 &&
                                                            (taskRemindersBeingEdited ===
                                                                undefined ||
                                                                Object.keys(
                                                                    taskRemindersBeingEdited
                                                                ).length < 1)
                                                                ? 'translateY(-20px)'
                                                                : 'translateY(0px)',
                                                        display: 'unset',
                                                    }}
                                                >
                                                    <ButtonAtom
                                                        theme="naked-text"
                                                        fontSize={18}
                                                        onClick={
                                                            addRemindersToBeEdited
                                                        }
                                                        disabled={checkIfSavedDisabled()}
                                                        dataCyId="add_another_reminder_desktop"
                                                    >
                                                        Add another reminder
                                                    </ButtonAtom>
                                                </div>
                                            </div>
                                        )}
                                    </>

                                    <DesktopButtonsWrapper>
                                        <ButtonsStandardBottomRowDesktop
                                            cancel={() => {
                                                isCarPage
                                                    ? navigate(
                                                          `/car/${carid}/tasks/task?taskid=${taskid}`
                                                      )
                                                    : navigate(
                                                          `/tasks/task?taskid=${taskid}`
                                                      )
                                            }}
                                            submit={onSaveClick}
                                            isDisabled={checkIfSavedDisabled()}
                                            dataCyIdUpdate="create_multiple_reminders_desktop"
                                            dataCyIdCancel="cancel_creation_of_multiple_reminders_desktop"
                                        />
                                    </DesktopButtonsWrapper>
                                </ModalInnerContentWrapperDesktop>
                            </ModalContentDesktop>
                        </ModalDisplayDesktopExclusive>
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <MobileWrapper>
                            <RemindersTopText
                                task={taskItem}
                                isCreation={view_id === 'create'}
                                isView={view_id === 'view'}
                            />
                            <div style={{ paddingTop: '34px' }} />

                            {view_id === 'create' && (
                                <>
                                    {taskRemindersBeingEditedIdsList &&
                                    taskRemindersBeingEdited &&
                                    taskRemindersBeingEditedIdsList.length >
                                        0 ? (
                                        <>
                                            {taskRemindersBeingEdited &&
                                                taskRemindersBeingEditedIdsList.map(
                                                    (
                                                        reminder_id: string,
                                                        index: number
                                                    ) => {
                                                        if (
                                                            taskRemindersBeingEdited &&
                                                            taskRemindersBeingEdited[
                                                                reminder_id
                                                            ]
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={`create_${reminder_id}_${index}_mobile`}
                                                                    style={{
                                                                        position:
                                                                            'relative',
                                                                        zIndex:
                                                                            50 -
                                                                            index,
                                                                    }}
                                                                >
                                                                    <RemindersFieldsManager
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        item={
                                                                            taskRemindersBeingEdited[
                                                                                reminder_id
                                                                            ]
                                                                        }
                                                                        dueDate={
                                                                            taskItem?.due ??
                                                                            dayjs().format()
                                                                        }
                                                                        minDate={
                                                                            minDate
                                                                        }
                                                                        variant="mobile"
                                                                    />
                                                                    <MicroPaddingTop />
                                                                </div>
                                                            )
                                                        } else
                                                            return (
                                                                <div
                                                                    key={index}
                                                                />
                                                            )
                                                    }
                                                )}

                                            <SmallPaddingTop />

                                            <div
                                                style={{
                                                    display: 'unset',
                                                }}
                                            >
                                                <ButtonAtom
                                                    theme="naked-text"
                                                    fontSize={18}
                                                    onClick={
                                                        addRemindersToBeEdited
                                                    }
                                                    disabled={checkIfSavedDisabled()}
                                                    dataCyId="add_another_reminder_mobile"
                                                >
                                                    Add another reminder
                                                </ButtonAtom>
                                            </div>
                                        </>
                                    ) : (
                                        <ButtonAtom
                                            theme="capitalize-white-background"
                                            width="100%"
                                            onClick={addRemindersToBeEdited}
                                            dataCyId="add_first_reminder_mobile"
                                        >
                                            Create Reminder
                                        </ButtonAtom>
                                    )}
                                </>
                            )}
                            {view_id === 'view' && (
                                <>
                                    {currentReminders &&
                                        currentReminders.map(
                                            (
                                                item: IReminder,
                                                index: number
                                            ) => (
                                                <React.Fragment
                                                    key={`view_${item.id}_${index}_mobile`}
                                                >
                                                    <Expander
                                                        height={
                                                            item &&
                                                            existingRemindersBeingDeleted.includes(
                                                                item.id
                                                            )
                                                                ? 0
                                                                : 'auto'
                                                        }
                                                    >
                                                        <RemindersViewManager
                                                            reminder={item}
                                                            deleteReminder={(
                                                                reminderUID: string
                                                            ) =>
                                                                handleDeletionOfExistingReminderBeforeSave(
                                                                    reminderUID
                                                                )
                                                            }
                                                            taskDueDate={
                                                                taskItem?.due
                                                            }
                                                        />
                                                        <MicroPaddingTop />
                                                        {currentReminders &&
                                                            index ===
                                                                currentReminders?.length -
                                                                    1 && (
                                                                <div
                                                                    style={{
                                                                        paddingBottom:
                                                                            '16px',
                                                                    }}
                                                                />
                                                            )}
                                                    </Expander>
                                                </React.Fragment>
                                            )
                                        )}

                                    {taskRemindersBeingEditedIdsList &&
                                        taskRemindersBeingEdited &&
                                        taskRemindersBeingEditedIdsList.length >
                                            0 && (
                                            <>
                                                {taskRemindersBeingEdited &&
                                                    taskRemindersBeingEditedIdsList.map(
                                                        (
                                                            reminder_id: string,
                                                            index: number
                                                        ) => {
                                                            if (
                                                                taskRemindersBeingEdited &&
                                                                taskRemindersBeingEdited[
                                                                    reminder_id
                                                                ]
                                                            ) {
                                                                return (
                                                                    <div
                                                                        key={`edit_${reminder_id}_${index}_mobile`}
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            zIndex:
                                                                                50 -
                                                                                index,
                                                                        }}
                                                                    >
                                                                        <RemindersFieldsManager
                                                                            handleChange={
                                                                                handleChange
                                                                            }
                                                                            item={
                                                                                taskRemindersBeingEdited[
                                                                                    reminder_id
                                                                                ]
                                                                            }
                                                                            dueDate={
                                                                                taskItem?.due ??
                                                                                dayjs().format()
                                                                            }
                                                                            minDate={
                                                                                minDate
                                                                            }
                                                                            variant="mobile"
                                                                        />
                                                                        <MicroPaddingTop />
                                                                    </div>
                                                                )
                                                            } else
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    />
                                                                )
                                                        }
                                                    )}
                                            </>
                                        )}
                                    <SmallPaddingTop />
                                    <div
                                        style={{
                                            transform:
                                                currentReminders !==
                                                    undefined &&
                                                currentReminders.length > 0 &&
                                                (taskRemindersBeingEdited ===
                                                    undefined ||
                                                    Object.keys(
                                                        taskRemindersBeingEdited
                                                    ).length < 1)
                                                    ? 'translateY(-20px)'
                                                    : 'translateY(0px)',
                                            display: 'unset',
                                        }}
                                    >
                                        <ButtonAtom
                                            theme="naked-text"
                                            fontSize={18}
                                            onClick={addRemindersToBeEdited}
                                            disabled={checkIfSavedDisabled()}
                                        >
                                            Add another reminder
                                        </ButtonAtom>
                                    </div>
                                </>
                            )}

                            <MobileButtonsWrapper>
                                <div
                                    style={{
                                        paddingBottom: 40,
                                        width: '100%',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <ButtonsStandardBottomRowMobile
                                        cancel={() =>
                                            isCarPage
                                                ? navigate(
                                                      `/car/${carid}/tasks/task?taskid=${taskid}`
                                                  )
                                                : navigate(
                                                      `/tasks/task?taskid=${taskid}`
                                                  )
                                        }
                                        submit={onSaveClick}
                                        confirmationtext={'save'}
                                        disabled={checkIfSavedDisabled()}
                                        height="40px"
                                        dataCyIdUpdate="create_multiple_reminders_mobile"
                                        dataCyIdCancel="cancel_creation_of_multiple_reminders_mobile"
                                    />
                                </div>
                            </MobileButtonsWrapper>
                        </MobileWrapper>
                    </IpadAndMobileDisplay>
                </>
            ) : null}
        </>
    )
}

export default TaskReminders
