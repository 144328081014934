import * as React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled from 'styled-components'
import ButtonAtom from '../Button/ButtonAtom'

const StyledContent = styled.div`
    color: inherit;
`

export interface ILinkProps {
    url: string
    children?: React.ReactNode
}

const HelpQuickAccessItemMobile = (props: ILinkProps) => (
    <ButtonAtom theme="help-quick-access">
        <AnchorLink style={{ color: 'inherit' }} href={props.url}>
            <StyledContent>{props.children}</StyledContent>
        </AnchorLink>
    </ButtonAtom>
)

export default HelpQuickAccessItemMobile
