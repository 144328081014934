import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { IShowroomEntry, IShowroomEntryAPI } from '../types'
import { showroomActions } from '../reducer'
import { ConvertShowroomEntryApiToState } from '../../conversions/showroom/entry'
import { SetCarEntryExtraneousReduxData } from './getShowroomEntry'
import { carActions } from '../../entities/cars/reducer'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'
import { push } from 'redux-first-history'

export type I_showroom_entry_create_arg = {
    resource_id: string
    type_of_sale: 'SET_PRICE' | 'PRICE_RANGE' | 'PRICE_ON_ASKING'
    price_min?: number
    price_max?: number
    currency?: 'GBP' | 'USD' | 'EUR' | string
    open_to_offers?: boolean
}

export function* CreateShowroomEntry(
    payload: I_showroom_entry_create_arg
): any {
    try {
        let res: IShowroomEntryAPI = yield call(
            api.showroom.createShowroomEntry,
            {
                ...payload,
                open_to_offers:
                    payload.type_of_sale !== 'SET_PRICE'
                        ? undefined
                        : payload.open_to_offers
                        ? payload.open_to_offers
                        : false,
            }
        )

        let res2: IShowroomEntry = ConvertShowroomEntryApiToState(res)

        // update key moments and facts reducer, image reducer, tech information reducer
        if (res.car) {
            yield call(SetCarEntryExtraneousReduxData, {
                car: res.car,
                entry: res,
            })
            yield put(
                carActions.updateCarWithShowroomData({
                    carid: res.car.uid,
                    showroom_uid: res.uid,
                    // @ts-ignore
                    type_of_sale: res.type_of_sale ? res.type_of_sale : '',
                    state: res.state ? res.state : 'DRAFT',
                })
            )
        }

        yield put(showroomActions.createEntryShowroomSuccess(res2))

        yield put(push(`/showroom/${res.uid}/edit`))

        // Capture event
        posthog.capture('CREATE SHOWROOM ENTRY')
        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: CreateShowroomEntry,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/car/${payload.resource_id}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(showroomActions.createEntryShowroomError(customError))
        }
    }
}

function* watcherCreateShowroomEntry() {
    while (true) {
        const { payload } = yield take(
            showroomActions.createEntryShowroomRequest
        )

        yield call(CreateShowroomEntry, payload)
    }
}

const create_showroom_entry: any[] = [fork(watcherCreateShowroomEntry)]

export default create_showroom_entry
