import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../../services'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { convertToGalleryImage } from '../../../conversions/entities/conversionFromAPI'
import { ResponseGenerator } from 'sagaInterfaces'
import { message } from 'antd'
import { fileStorageActions } from '../../reducer'
import { IReduxError } from '../../../entities/cars/types'
import {
    IAddGalleryImagesIdsToEntry,
    IGalleryImage,
} from '../../../entities/galleries/types'
import { IPayloadCarGalleryImageFromApi } from '../../../services/typedefinitions/apiPayloads'
import {
    IUploadGalleryImagesPreSignedUrlsPayload,
    ICreateCarGalleryImageFields,
} from '../../types'
import { timelineActions } from '../../../timeline/reducer'
import { galleriesActions } from '../../../entities/galleries/reducer'

type IPayloadCreateEntryGalleryImageSagaReq = {
    entryid: string
    carid: string
    gallerypresignedurls: IUploadGalleryImagesPreSignedUrlsPayload
    files: File[]
    fields: ICreateCarGalleryImageFields
    isFromQuickActions: boolean
}

function* createEntryGalleryImage(p: IPayloadCreateEntryGalleryImageSagaReq) {
    try {
        const res: ResponseGenerator =
            yield api.entities.mutateGallery.createEntryGalleryImage(
                p.entryid,
                p.carid,
                p.gallerypresignedurls,
                p.files,
                p.fields
            )

        yield put(fileStorageActions.submitCarGalleryImagesFilestorageSuccess())

        let array_of_img_ids_in_response: string[] =
            res && res.data && res.data.map((img: any) => img.uid)

        let addGalleryImagesIdsToEntryPayload: IAddGalleryImagesIdsToEntry = {
            carid: p.carid,
            entryid: p.entryid,
            image_ids: array_of_img_ids_in_response,
        }

        yield put(
            timelineActions.addGalleryImagesIdsToEntrySuccess(
                addGalleryImagesIdsToEntryPayload
            )
        )

        let convertedImages: IGalleryImage[] = res.data.map(
            (img: IPayloadCarGalleryImageFromApi) => convertToGalleryImage(img)
        )

        yield put(galleriesActions.addToGalleryImageSuccess(convertedImages))

        // Capture event
        if (p.files.length > 0) {
            posthog.capture('ADD ENTRY IMAGE', {
                imagesUploadCount: 1,
            })
        } else {
            posthog.capture('ADD ENTRY MULTIPLE IMAGES', {
                imagesUploadCount: p.files.length,
            })
        }

        const info = (messageText: string) => {
            message.info(messageText)
        }

        info(
            `${convertedImages.length} 
                        images added to gallery`
        )

        yield put(
            fileStorageActions.resetPresignedUrlsCarGalleryImagesSuccess()
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: createEntryGalleryImage,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                fileStorageActions.submitEntryGalleryImagesFilestorageError(
                    typedError
                )
            )
            yield put(
                fileStorageActions.resetPresignedUrlsCarGalleryImagesSuccess()
            )
            return
        }
    }
}

// LIST

function* watcherUploadAndSubmitEntryGalleryImages() {
    while (true) {
        const { payload } = yield take(
            fileStorageActions.submitEntryGalleryImagesFilestorageRequest
        )

        let p: IPayloadCreateEntryGalleryImageSagaReq = {
            entryid: payload.entryid,
            carid: payload.carid,
            gallerypresignedurls: payload.gallerypresignedurls,
            files: payload.files,
            fields: payload.fields,
            isFromQuickActions: payload.isFromQuickActions,
        }

        yield call(createEntryGalleryImage, p)
    }
}

const filestorage_sagas_submission_entry_gallery: any[] = [
    fork(watcherUploadAndSubmitEntryGalleryImages),
]

export default filestorage_sagas_submission_entry_gallery
