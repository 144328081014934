import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import { device } from '../../templates/displays/devices'

type Props = {
    height?: string
    width?: string
    backgroundColor?: string
    onClick?: any
    ipadHeight?: string
    tutorial?: boolean
    placeholder?: string
    action?: 'add' | 'upload'
    dataPH?: string
}

const PlaceHolderStyle = styled.div<Props>`
    cursor: pointer;
    width: ${(props) => (props.width ? props.width : '100vw')};
    height: ${(props) => (props.height ? props.height : '160px')};

    @media ${device.ipad} {
        height: ${(props) => (props.ipadHeight ? props.ipadHeight : '160px')};
        width: ${(props) => (props.width ? props.width : '100%')};
        margin: 0 auto;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    color: #9fbfc0;
    font-family: Lato;
    font-size: 14px;

    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : 'var(--primary_16, #EDF9F9)'};
`

const InfoText = styled.div`
    padding-left: 10px;
`

const AddImageBanner: React.FC<Props> = ({
    height,
    width,
    backgroundColor,
    onClick,
    ipadHeight,
    tutorial,
    placeholder,
    action,
    dataPH,
}) => {
    return (
        <React.Fragment>
            {tutorial === true ? (
                <PlaceHolderStyle
                    height={height}
                    ipadHeight={ipadHeight}
                    width={width}
                    backgroundColor={'#EDF9F9'}
                    onClick={onClick}
                    data-ph={dataPH}
                >
                    {action && (
                        <Icon
                            icon={
                                action === 'upload'
                                    ? 'upload_tuto_mobile'
                                    : 'add'
                            }
                        />
                    )}
                    {placeholder && <InfoText>{placeholder}</InfoText>}
                </PlaceHolderStyle>
            ) : (
                <PlaceHolderStyle
                    height={height}
                    ipadHeight={ipadHeight}
                    width={width}
                    backgroundColor={backgroundColor}
                    onClick={onClick}
                    data-ph={dataPH}
                >
                    <Icon icon="add_plus" />
                </PlaceHolderStyle>
            )}
        </React.Fragment>
    )
}

export default AddImageBanner
