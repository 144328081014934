import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TimelineCreationTypeIdsEnum } from '../../../../redux/timeline/timelineEnum'
import TimelineEditGenerateCreateFormDesktop from '../timelineFormGenerators/timelineEditGenerateCreateFormDesktop'
import { useAppDispatch } from '../../../../redux/store/hooks'
import {
    ITimelineCreationFormFieldsData,
    ITimelineStepsIndexedListByCreationType,
    ITimelineDataToBeSubmittedObj,
    ITimelineItem,
    ITimelineEntityType,
    ITimelineActionType,
    ITimelineEntryTypes,
} from '../../../../redux/timeline/types'
import { ITimelineCreateForms } from '../../../../redux/editForms/types'
import { timelineActions } from '../../../../redux/timeline/reducer'

interface Props {
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => any
    close_create_form: any
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted?: ITimelineDataToBeSubmittedObj
    submitDataRequest?: any
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    activeEntry?: ITimelineItem | null | undefined
}

const TimelineCreateManagerDesktop: React.FC<Props> = ({
    timeline_creation_steps_data,
    timeline_creation_forms_current_state,
    manageTimelineCreationFormState,
    close_create_form,
    timeline_creation_steps_object_of_indexed_list,
    data_to_be_submitted,
    submitDataRequest,
    entityID,
    carID,
    entityType,
    activeEntry,
}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const updateItemBeingCreated = (
        fieldID: any,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => {
        if (fieldID === 'categoryID' && value === 'insurance') {
            navigate(`/car/${carID}/history-file/create/insurance`)
        } else {
            dispatch(
                timelineActions.manageDataToBeSubmittedTimelineCreationRequest({
                    carid: carID,
                    entityType: entityType,
                    action: action,
                    fieldID: fieldID,
                    fieldValue: fieldID.includes('date')
                        ? value.toString()
                        : value,
                })
            )
        }
    }

    const goToNextStep = () => {
        let steps_list: string[] | null = null
        if (timeline_creation_forms_current_state.active_creation) {
            steps_list =
                timeline_creation_steps_object_of_indexed_list[
                    timeline_creation_forms_current_state.active_creation as keyof ITimelineStepsIndexedListByCreationType
                ]
        }

        let next_index: number =
            timeline_creation_forms_current_state.current_step_index + 1

        if (steps_list) {
            if (
                next_index > 0 &&
                next_index < steps_list.length &&
                steps_list.length > 1
            ) {
                return manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: next_index,
                    active_creation:
                        timeline_creation_forms_current_state.active_creation,
                })
            } else {
                // return close_create_form()
            }
        }
    }

    const goToPreviousStep = () => {
        let previous_index: number =
            timeline_creation_forms_current_state.current_step_index - 1

        if (previous_index >= 0) {
            return manageTimelineCreationFormState({
                isOpen: true,
                current_step_index: previous_index,
                active_creation:
                    timeline_creation_forms_current_state.active_creation,
            })
        } else {
            if (entityType === 'cost' && activeEntry) {
                navigate(
                    `/car/${carID}/history-file/entry?entryid=${activeEntry.id}`
                )
            } else {
                navigate(`/car/${carID}/history-file`)
            }

            return close_create_form()
        }
    }

    let current_step_index: number =
        timeline_creation_forms_current_state.current_step_index

    let active_creation:
        | ITimelineEntryTypes
        | TimelineCreationTypeIdsEnum
        | null = timeline_creation_forms_current_state.active_creation as
        | TimelineCreationTypeIdsEnum
        | ITimelineEntryTypes
        | null

    let steps_list: string[] | null =
        timeline_creation_forms_current_state.active_creation
            ? timeline_creation_steps_object_of_indexed_list[
                  timeline_creation_forms_current_state.active_creation as keyof ITimelineStepsIndexedListByCreationType
              ]
            : null

    let current_step_data =
        steps_list &&
        timeline_creation_steps_data[
            steps_list[
                current_step_index
            ] as keyof ITimelineCreationFormFieldsData
        ]

    return (
        <div
            data-attr={`${
                current_step_data && current_step_data.submit.entity
            }-creation-manager_desktop`}
        >
            {steps_list && current_step_data && (
                <TimelineEditGenerateCreateFormDesktop
                    closeForm={close_create_form}
                    step_list={steps_list}
                    current_step_index={
                        timeline_creation_forms_current_state.current_step_index
                    }
                    stepFieldsData={current_step_data.step_fields_data}
                    stepFieldIdsList={current_step_data.step_field_ids}
                    stepTitle={current_step_data.step_title}
                    stepSubtitle={current_step_data.step_subtitle}
                    stepSubtitleDetail={current_step_data.step_subtitle_detail}
                    submit={current_step_data.submit}
                    data_to_be_submitted={data_to_be_submitted}
                    onChange={updateItemBeingCreated}
                    manageSteps={goToNextStep}
                    submitDataRequest={() => {
                        dispatch(
                            timelineActions.manageDataToBeSubmittedTimelineCreationRequest(
                                {
                                    carid: carID,
                                    isReadyToSendToApi: true,
                                }
                            )
                        )
                    }}
                    goBackOneStep={goToPreviousStep}
                    showStepTitleAndSubtitle={
                        active_creation ===
                            TimelineCreationTypeIdsEnum.add_new_desktop &&
                        current_step_index === 0
                    }
                    entityID={entityID}
                    carID={carID}
                    entityType={entityType}
                    activeEntry={activeEntry}
                />
            )}
        </div>
    )
}

export default TimelineCreateManagerDesktop
