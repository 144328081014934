import { IGalleryImagesObject } from '../../redux/entities/galleries/types'

export type IsortImagesIdsByFeaturedArgs = {
    images_data: IGalleryImagesObject
    images_ids: string[]
    max_n: number
}

export const sortImagesIdsByFeatured = (
    p: IsortImagesIdsByFeaturedArgs
): string[] => {
    let resultArray: string[] = []

    let imgs_ids_without_featured: string[] = []

    for (let i = 0; i < p.images_ids.length; i++) {
        if (
            p.images_data[p.images_ids[i]] &&
            p.images_data[p.images_ids[i]] !== undefined &&
            p.images_data[p.images_ids[i]] !== null
        ) {
            if (p.images_data[p.images_ids[i]].featured === true) {
                resultArray = [
                    ...resultArray,
                    p.images_data[p.images_ids[i]].id,
                ]
            } else {
                imgs_ids_without_featured = [
                    ...imgs_ids_without_featured,
                    p.images_data[p.images_ids[i]].id,
                ]
            }
        }
    }

    let diff = p.max_n - resultArray.length

    if (diff > 0) {
        for (let i = 0; i < diff; i++) {
            if (p.images_data[imgs_ids_without_featured[i]]) {
                if (
                    p.images_data[imgs_ids_without_featured[i]].featured !==
                    true
                ) {
                    resultArray = [
                        ...resultArray,
                        p.images_data[imgs_ids_without_featured[i]].id,
                    ]
                }
            }
        }
    }

    return resultArray
}
