import { IApexInterviewOverviewItemState } from 'apexModels'
import React, { useState } from 'react'
import styled from 'styled-components'
import read_apex_interview from '../../../../../public/assets/icons/read_apex_interview.svg'
import listen_apex_interview from '../../../../../public/assets/icons/listen_apex_interview.svg'
import ShowMoreExpandToggleMobile from '../../../../atoms/Button/showMoreExpandToggleMobile'
import Expander from '../../../../atoms/expander/expander'
import { Link } from 'react-router-dom'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 30px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 390px) {
        padding-left: 20px;
    }
`

const ImgStyle = styled.img`
    border-radius: 5px;

    @media (max-width: 390px) {
        width: 80px;
    }

    height: 120px;
    width: 100px;
    object-fit: cover;
`

const Name = styled(Link)`
    font-family: Lato-Light;
    text-transform: uppercase;
    padding-bottom: 5px;
    color: var(--text-strong, #1a1a1a) !important;
`

const Title = styled(Link)`
    font-family: Lato;
    font-weight: bold;
    padding-bottom: 11px;
    color: var(--text-strong, #1a1a1a) !important;
    line-height: 24px;
`

const Intro = styled.div`
    font-family: Lato;
    padding-top: 30px;
    line-height: 2;
    color: var(--text-strong, #1a1a1a);
`

const QuoteRowWrapper = styled.div`
    position: relative;
    font-style: italic;
    height: 100%;
`

const VerticalLine = styled.div`
    position: absolute;
    background-color: var(--text-strong, #1a1a1a);
    width: 1px;
    height: 100%;
    left: 0px;
    top: 0px;
    bottom: 0px;
`

const Quote = styled.div`
    padding-left: 30px;
    display: flex;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 1.8;
    color: var(--text-strong, #1a1a1a);
`

const BtnRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
`

const BtnRowBtn = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #5ec3ca;
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    color: #5ec3ca !important;
    :focus {
        color: #5ec3ca !important;
    }
    font-family: Lato-Light;
    font-size: 14px;
    width: 103px;
    height: 40px;

    @media (max-width: 330px) {
        margin-top: 5px;
        width: 40vw;
        box-sizing: border-box;
        height: 38px;
    }

    cursor: pointer;
    transition: all 200ms;
`

const HideFromSmallestPhones = styled.div`
    @media (max-width: 330px) {
        display: none;
    }
`

const HideFromBiggerPhones = styled.div`
    @media (min-width: 330px) {
        display: none;
    }
`

type Props = {
    latest_interview: IApexInterviewOverviewItemState
}

const ApexLatestInterviewMobile: React.FC<Props> = ({ latest_interview }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <Container>
            <RowWrapper>
                <Link to={`/apex/interviews/${latest_interview.uid}`}>
                    <ImgStyle
                        src={
                            latest_interview.headshot &&
                            latest_interview.headshot.url
                                ? latest_interview.headshot.url
                                : ''
                        }
                        alt={latest_interview.title}
                    />
                </Link>
                <ColumnWrapper>
                    <Name to={`/apex/interviews/${latest_interview.uid}`}>
                        {latest_interview.name}
                    </Name>
                    <Title to={`/apex/interviews/${latest_interview.uid}`}>
                        {latest_interview.title}
                    </Title>
                    <HideFromSmallestPhones>
                        <BtnRow>
                            <BtnRowBtn
                                to={`/apex/interviews/${latest_interview.uid}`}
                            >
                                <img
                                    style={{
                                        height: '15px',
                                        width: '15px',
                                        marginRight: '6px',
                                    }}
                                    src={read_apex_interview}
                                    alt="Read"
                                />
                                <div>Read</div>
                            </BtnRowBtn>

                            <div style={{ paddingRight: '10px' }} />

                            <BtnRowBtn
                                to={`/apex/podcasts#${latest_interview.uid}_m`}
                            >
                                <img
                                    style={{
                                        height: '15px',
                                        width: '15px',
                                        marginRight: '6px',
                                    }}
                                    src={listen_apex_interview}
                                    alt="Listen"
                                />
                                <div>Listen</div>
                            </BtnRowBtn>
                        </BtnRow>
                    </HideFromSmallestPhones>
                </ColumnWrapper>
            </RowWrapper>

            <HideFromBiggerPhones>
                <BtnRow>
                    <BtnRowBtn to={`/apex/podcasts#${latest_interview.uid}_m`}>
                        <img
                            style={{
                                height: '15px',
                                width: '15px',
                                marginRight: '6px',
                            }}
                            src={listen_apex_interview}
                            alt="Listen"
                        />
                        <div>Listen</div>
                    </BtnRowBtn>

                    <div style={{ paddingRight: '10px' }} />

                    <BtnRowBtn to={`/apex/interviews/${latest_interview.uid}`}>
                        <img
                            style={{
                                height: '15px',
                                width: '15px',
                                marginRight: '6px',
                            }}
                            src={read_apex_interview}
                            alt="Read"
                        />
                        <div>Read</div>
                    </BtnRowBtn>
                </BtnRow>
            </HideFromBiggerPhones>

            <Expander height={isExpanded ? 'auto' : 120}>
                <Intro>{latest_interview.introduction}</Intro>
                <div style={{ paddingTop: '20px' }} />
                <QuoteRowWrapper>
                    <VerticalLine />
                    <Quote>"{latest_interview.quote}"</Quote>
                </QuoteRowWrapper>
            </Expander>

            <div style={{ paddingTop: '11px' }} />

            <ShowMoreExpandToggleMobile
                onClick={toggleIsExpanded}
                isExpanded={isExpanded}
            />
        </Container>
    )
}

export default ApexLatestInterviewMobile
