import {
    timeline_category_data,
    timeline_category_ids,
    timeline_category_searchable_items_list_mock,
} from './data'

import {
    create_entry_label_steps_list,
    create_timeline_add_new_desktop_steps_list,
    create_timeline_add_new_steps_list,
    create_timeline_attachment_steps_list,
    create_timeline_cost_item_desktop_steps_list,
    create_timeline_cost_item_steps_list,
    create_timeline_service_steps_list,
    create_timeline_maintenance_steps_list,
    create_timeline_restoration_steps_list,
    create_timeline_admin_steps_list,
    create_timeline_insurance_steps_list,
    create_timeline_history_steps_list,
    create_timeline_events_steps_list,
    create_timeline_others_steps_list,
    timeline_attachment_fields_list,
    timeline_cost_fields_list,
    timeline_fields_data_per_step,
    timeline_general_fields_list,
    timeline_notes_fields_list,
    timeline_labels_fields_list,
    timeline_mileage_fields_list,
    timeline_insurance_general_fields_list,
    timeline_insurance_fields_list,
} from './timelineFieldsList'
import {
    TimelineCreationTypeIdsEnum,
    TimelineFormTypeIDsEnum,
} from './timelineEnum'

const initialState: ITimelineState = {
    timeline_data_normalised_by_car_id: {},

    categories: {
        ids: timeline_category_ids,
        data: timeline_category_data,
        category_searchable_items_list:
            timeline_category_searchable_items_list_mock,
    },
    labels: {
        data: {},
        list: [],
    },
    activeEntry: undefined,
    activeCost: undefined,
    formsData: {
        creation: {
            timeline_creation_steps_data: timeline_fields_data_per_step,
            timeline_creation_steps_object_of_indexed_list: {
                [TimelineCreationTypeIdsEnum.add_new_desktop]:
                    create_timeline_add_new_desktop_steps_list,
                [TimelineCreationTypeIdsEnum.cost]:
                    create_timeline_cost_item_steps_list,
                [TimelineCreationTypeIdsEnum.cost_desktop]:
                    create_timeline_cost_item_desktop_steps_list,
                [TimelineCreationTypeIdsEnum.attachment]:
                    create_timeline_attachment_steps_list,
                [TimelineCreationTypeIdsEnum.label]:
                    create_entry_label_steps_list,
                [TimelineCreationTypeIdsEnum.add_new]:
                    create_timeline_add_new_steps_list,
                service: create_timeline_service_steps_list,
                maintenance: create_timeline_maintenance_steps_list,
                restoration: create_timeline_restoration_steps_list,
                admin: create_timeline_admin_steps_list,
                insurance: create_timeline_insurance_steps_list,
                history: create_timeline_history_steps_list,
                events: create_timeline_events_steps_list,
                other: create_timeline_others_steps_list,
            },
            data_to_be_submitted: {},
        },
        fieldsList: {
            fieldsListByCategory: {
                service: timeline_general_fields_list,
                maintenance: timeline_general_fields_list,
                restoration: timeline_general_fields_list,
                admin: timeline_general_fields_list,
                insurance: timeline_insurance_general_fields_list,
                history: timeline_general_fields_list,
                events: timeline_general_fields_list,
                other: timeline_general_fields_list,
            },
            // @ts-ignore
            fieldsListByType: {
                [TimelineFormTypeIDsEnum.cost]: timeline_cost_fields_list,
                [TimelineFormTypeIDsEnum.attachment]:
                    timeline_attachment_fields_list,
                [TimelineFormTypeIDsEnum.notes]: timeline_notes_fields_list,
                [TimelineFormTypeIDsEnum.labels]: timeline_labels_fields_list,
                [TimelineFormTypeIDsEnum.mileage]: timeline_mileage_fields_list,
                [TimelineFormTypeIDsEnum.insurance_fields]:
                    timeline_insurance_fields_list,
            },
        },
    },

    error: null,
    loading: false,
    deletionInProgress: false,
    isEntryAttachmentLoading: false,
    isCostAttachmentLoading: false,
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAddAttachmentToCostSaga } from './sagas/addAttachmentToCost'
import { IManageAddAttachmentsToEntryRequest } from './actions/requestActions'
import {
    IAddEntryAttachmenstApiRequest,
    IDeleteCostAttachmentPayloadReq,
    IDeleteEntryAttachmentPayloadReq,
} from '../services/types'
import {
    ITimelineState,
    IGetTimelineItemsByCarIDPayloadRequest,
    IGetAllTimelineItemsByCarIDPayloadSuccess,
    IGetTimelineItemByIdPayloadRequest,
    ITimelineItem,
    IFilterTimelineByCategoryNLabelNTitleQCarIDRequest,
    IFilterTimelineByCategoryNLabelCarIDSuccess,
    ITimelineDataByCar,
    ITimelineSearch,
    ISetFilterTimelineByCategoryNLabelNTitleQCarID,
    IAddTimelineItemPayloadRequest,
    ICostItem,
    IUpdateTimelineItemPayloadRequest,
    IDeleteTimelineItemPayloadRequest,
    IDeleteTimelineItemPayloadSuccess,
    IGetCostEntryPayloadRequest,
    IAddCostItemPayloadRequest,
    IAddCostItemPayloadSuccess,
    IUpdateCostItemPayloadRequest,
    IUpdateCostItemPayloadSuccess,
    IDeleteCostItemPayloadRequest,
    IDeleteCostItemPayloadSuccess,
    IActionPayloadTimelineCreationRequest,
    IActionPayloadTimelineCreationSuccess,
    IGetAllUserEntryLabelsRequest,
    IGetAllUserEntryLabelsSuccess,
    IUpdateEntryLabelPayload,
    ILabel,
    IAddTimelineItemsWithCostPayloadRequest,
} from './types'
import { IDropdownItem } from 'entityModels'
import { IReduxError } from '../entities/cars/types'
import {
    IAddGalleryImagesIdsToEntry,
    IDeleteEntryGalleryImagePayload,
} from '../entities/galleries/types'

export const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        getAllTimelineItemsByCarIDRequest: (
            state,
            action: PayloadAction<IGetTimelineItemsByCarIDPayloadRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        getAllTimelineItemsByCarIDSuccess: (
            state,
            action: PayloadAction<IGetAllTimelineItemsByCarIDPayloadSuccess>
        ) => {
            // if (
            //     state.timeline_data_normalised_by_car_id?.[
            //         action.payload.car_id
            //     ]
            // ) {
            //     state.timeline_data_normalised_by_car_id[
            //         action.payload.car_id
            //     ].entries = action.payload.entries
            // } else {
            //     state.timeline_data_normalised_by_car_id = {
            //         ...state.timeline_data_normalised_by_car_id,
            //         [action.payload.car_id]: {
            //             entries: action.payload.entries,
            //         },
            //     }
            // }
            // state.loading = false
            // state.error = null

            state.loading = false

            let shallowState0 = JSON.parse(JSON.stringify(state))

            if (!shallowState0.timeline_data_normalised_by_car_id) {
                state.timeline_data_normalised_by_car_id = {}
            }

            let normd = !shallowState0.timeline_data_normalised_by_car_id
                ? {}
                : shallowState0.timeline_data_normalised_by_car_id

            let normdcar =
                normd &&
                normd[action.payload.car_id] &&
                normd[action.payload.car_id]
                    ? normd[action.payload.car_id]
                    : {}

            state.timeline_data_normalised_by_car_id = {
                ...normd,
                [action.payload.car_id]: {
                    ...normdcar,
                    entries: action.payload.entries,
                },
            }
        },

        getAllTimelineItemsByCarIDError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getTimelineItemByIDRequest: (
            state,
            action: PayloadAction<IGetTimelineItemByIdPayloadRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        getTimelineItemByIDSuccess: (
            state,
            action: PayloadAction<ITimelineItem>
        ) => {
            state.activeEntry = action.payload
            state.loading = false
            state.error = null
        },

        getTimelineItemByIDError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },

        filterTimelineItemsByCarCategoryIdsNLabelIdsRequest: (
            state,
            action: PayloadAction<IFilterTimelineByCategoryNLabelNTitleQCarIDRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        filterTimelineItemsByCarCategoryIdsNLabelIdsSuccess: (
            state,
            action: PayloadAction<IFilterTimelineByCategoryNLabelCarIDSuccess>
        ) => {
            let shallowState0 = JSON.parse(JSON.stringify(state))

            let currentTimeline0 =
                shallowState0.timeline_data_normalised_by_car_id
                    ? shallowState0.timeline_data_normalised_by_car_id
                    : {}

            let currentCarTimeline0: ITimelineDataByCar = currentTimeline0[
                action.payload.car_id
            ]
                ? currentTimeline0[action.payload.car_id]
                : {
                      entries: [],
                  }

            let currentSearch: ITimelineSearch | undefined =
                currentCarTimeline0.search
                    ? {
                          ...currentCarTimeline0.search,
                          results: action.payload.results,
                      }
                    : undefined

            if (currentSearch) {
                state.timeline_data_normalised_by_car_id = {
                    ...currentTimeline0,
                    [action.payload.car_id]: {
                        ...currentCarTimeline0,
                        search: {
                            ...currentSearch,
                        },
                    },
                }
            }

            state.loading = false
            state.error = null

            // if (
            //     state.timeline_data_normalised_by_car_id?.[
            //         action.payload.car_id
            //     ]?.search
            // ) {
            //     state.timeline_data_normalised_by_car_id[
            //         action.payload.car_id
            //     ].search.results = action.payload.results
            // } else {
            //     state.timeline_data_normalised_by_car_id = {
            //         ...state.timeline_data_normalised_by_car_id,
            //         [action.payload.car_id]: {
            //             ...state.timeline_data_normalised_by_car_id?.[
            //                 action.payload.car_id
            //             ],
            //             search: {
            //                 results: action.payload.results,
            //             },
            //         },
            //     }
            // }
            // state.loading = false
            // state.error = null
        },

        filterTimelineItemsByCarCategoryIdsNLabelIdsError: (
            state,
            action: PayloadAction<any>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest: (
            state,
            action: PayloadAction<ISetFilterTimelineByCategoryNLabelNTitleQCarID>
        ) => {
            state.loading = true
            state.error = null
        },

        setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNEntryTitleQSuccess: (
            state,
            action: PayloadAction<ISetFilterTimelineByCategoryNLabelNTitleQCarID>
        ) => {
            state.loading = false
            let obj2 = JSON.parse(JSON.stringify(state))

            let currentTimeline2 = obj2.timeline_data_normalised_by_car_id
                ? obj2.timeline_data_normalised_by_car_id
                : {}

            let currentCarTimeline2: ITimelineDataByCar = currentTimeline2[
                action.payload.car_id
            ]
                ? currentTimeline2[action.payload.car_id]
                : {
                      entries: [],
                  }

            let currentSearch2: ITimelineSearch = currentCarTimeline2.search
                ? {
                      ...currentCarTimeline2.search,
                      activeCategoryIds: action.payload.category_ids
                          ? action.payload.category_ids
                          : [],
                      activeCustomLabelsIds: action.payload.label_ids
                          ? action.payload.label_ids
                          : [],
                      activeEntryTitleQuery: action.payload.entry_title,
                      results:
                          action.payload.resetResults ||
                          (action.payload.category_ids?.length === 0 &&
                              action.payload.label_ids?.length === 0 &&
                              !action.payload.entry_title)
                              ? currentCarTimeline2.entries
                              : currentCarTimeline2.search.results,
                  }
                : {
                      activeCategoryIds: action.payload.category_ids
                          ? action.payload.category_ids
                          : [],
                      activeCustomLabelsIds: action.payload.label_ids
                          ? action.payload.label_ids
                          : [],
                      activeEntryTitleQuery: action.payload.entry_title,
                      results: action.payload.resetResults
                          ? currentCarTimeline2.entries
                          : [],
                  }

            state.timeline_data_normalised_by_car_id = {
                ...currentTimeline2,
                [action.payload.car_id]: {
                    ...currentCarTimeline2,
                    search: currentSearch2,
                },
            }
        },

        setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        addTimelineItemToCarRequest: (
            state,
            action: PayloadAction<IAddTimelineItemPayloadRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        addTimelineItemToCarSuccess: (
            state,
            action: PayloadAction<{ carid: string; item: any }>
        ) => {
            const entries =
                state.timeline_data_normalised_by_car_id?.[action.payload.carid]
                    ?.entries || []

            if (
                state.timeline_data_normalised_by_car_id?.[action.payload.carid]
            ) {
                state.timeline_data_normalised_by_car_id[
                    action.payload.carid
                ].entries = [...entries, action.payload.item]
            } else {
                state.timeline_data_normalised_by_car_id = {
                    ...state.timeline_data_normalised_by_car_id,
                    [action.payload.carid]: {
                        entries: [action.payload.item],
                    },
                }
            }

            state.activeEntry = action.payload.item
            state.loading = false
            state.error = null
        },
        addTimelineItemToCarError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // SET ACTIVE ENTRY ========================

        setActiveEntrySuccess: (
            state,
            action: PayloadAction<ITimelineItem | undefined>
        ) => {
            state.activeEntry = action.payload
            state.loading = false
            state.error = null
        },

        setActiveCostSuccess: (
            state,
            action: PayloadAction<ICostItem | undefined>
        ) => {
            state.activeCost = action.payload
            state.loading = false
            state.error = null
        },

        // CLEAR DATA TO BE SUBMITTED

        clearDataToBeSubmittedSuccess: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.formsData = {
                ...state.formsData,
                creation: {
                    ...state.formsData.creation,
                    data_to_be_submitted: {},
                },
            }
            state.loading = false
        },

        // UPDATE TIMELINE ITEM ========================

        // request sent to API
        updateTimelineEntryRequest: (
            state,
            action: PayloadAction<IUpdateTimelineItemPayloadRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        // from saga, on success
        updateTimelineEntrySuccess: (
            state,
            action: PayloadAction<{ carid: string; item: any }>
        ) => {
            state.activeEntry = action.payload.item
            state.timeline_data_normalised_by_car_id = {
                ...state.timeline_data_normalised_by_car_id,
                [action.payload.carid]: action.payload.item,
            }
            state.loading = false
            state.error = null
        },

        // from saga on error
        updateTimelineEntryError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },

        //DELETE TIMELINE ITEM ========================

        // request sent to API
        deleteTimelineEntryRequest: (
            state,
            action: PayloadAction<IDeleteTimelineItemPayloadRequest>
        ) => {
            state.deletionInProgress = true
            state.error = null
        },

        // from saga, on success
        deleteTimelineEntrySuccess: (
            state,
            action: PayloadAction<IDeleteTimelineItemPayloadSuccess>
        ) => {
            state.deletionInProgress = false
            state.loading = false
            state.error = null
        },

        // send deletion error
        deleteTimelineEntryError: (state, action: PayloadAction<any>) => {
            state.deletionInProgress = false
            state.loading = false
            state.error = action.payload
        },

        // GET COST ITEM BY ID ========================

        // send request to api
        getCostEntryByIdRequest: (
            state,
            action: PayloadAction<IGetCostEntryPayloadRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        // from saga on success
        getCostEntryByIdSuccess: (state, action: PayloadAction<ICostItem>) => {
            state.activeCost = action.payload
            state.loading = false
            state.error = null
        },

        // from saga on error
        getCostEntryByIdError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        // ADD COST ITEM TO ENTRY ========================

        // send request to api
        addCostToTimelineItemRequest: (
            state,
            action: PayloadAction<IAddCostItemPayloadRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        // from saga on success
        addCostToTimelineItemSuccess: (
            state,
            action: PayloadAction<IAddCostItemPayloadSuccess>
        ) => {
            state.activeEntry = action.payload.entry
            state.activeCost = action.payload.cost
            state.loading = false
            state.error = null
        },

        // from saga on error
        addCostToTimelineItemError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // UPDATE COST ITEM TO ENTRY ========================

        // send request to api
        updateTimelineItemCostRequest: (
            state,
            action: PayloadAction<IUpdateCostItemPayloadRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        // from saga on success
        updateTimelineItemCostSuccess: (
            state,
            action: PayloadAction<IUpdateCostItemPayloadSuccess>
        ) => {
            state.activeEntry = action.payload.entry
            state.activeCost = action.payload.cost
            state.loading = false
            state.error = null
        },
        // from saga on error
        updateTimelineItemCostError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },

        // DELETE COST ITEM TO ENTRY ========================

        // send request to api
        deleteTimelineItemCostRequest: (
            state,
            action: PayloadAction<IDeleteCostItemPayloadRequest>
        ) => {
            state.deletionInProgress = true
            state.error = null
        },

        // from saga on success
        deleteTimelineItemCostSuccess: (
            state,
            action: PayloadAction<IDeleteCostItemPayloadSuccess>
        ) => {
            state.activeEntry = action.payload.entry
            state.loading = false
            state.deletionInProgress = false
            state.error = null
        },

        // from saga on error
        deleteTimelineItemCostError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.deletionInProgress = false
            state.error = action.payload
        },

        // MANAGE DATA TO BE SUBMITTED TO API ========================

        // on request
        manageDataToBeSubmittedTimelineCreationRequest: (
            state,
            action: PayloadAction<IActionPayloadTimelineCreationRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        //  on success
        manageDataToBeSubmittedTimelineCreationSuccess: (
            state,
            action: PayloadAction<IActionPayloadTimelineCreationSuccess>
        ) => {
            state.formsData = {
                ...state.formsData,
                creation: {
                    ...state.formsData.creation,
                    data_to_be_submitted: {
                        ...state.formsData.creation.data_to_be_submitted,
                        [action.payload.entityType]: {
                            ...state.formsData.creation.data_to_be_submitted[
                                action.payload.entityType
                            ],
                            ...action.payload.entityObj,
                        },
                    },
                },
            }
            state.loading = false
        },

        // GET ALL USER CAR ENTRY LABELS ========================

        // send request to api
        getAllUserCarEntryLabelsRequest: (
            state,
            action: PayloadAction<IGetAllUserEntryLabelsRequest>
        ) => {
            state.loading = true
            state.error = null
        },

        // from saga on success
        getAllUserCarEntryLabelsSuccess: (
            state,
            action: PayloadAction<IGetAllUserEntryLabelsSuccess>
        ) => {
            state.labels = {
                data: action.payload.data,
                list: action.payload.list,
            }
            state.loading = false
            state.error = null
        },

        // from saga on error
        getAllUserCarEntryLabelsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // from saga on success
        addLabelToCarEntrySuccess: (
            state,
            action: PayloadAction<IUpdateEntryLabelPayload>
        ) => {
            let oj5 = JSON.parse(JSON.stringify(state))
            let current_labels_ids = oj5.labels.list.map(
                (i: IDropdownItem, index: number) => {
                    return i.uid
                }
            )
            let new_labels: ILabel[] = action.payload.labels.filter(
                (label: ILabel, index: number) => {
                    return !current_labels_ids.includes(label.uid)
                }
            )

            state.labels = {
                ...oj5.labels,
                list: [...state.labels.list, ...new_labels],
            }

            if (state.activeEntry) {
                state.activeEntry = {
                    ...state.activeEntry,
                    labels:
                        state.activeEntry && state.activeEntry?.labels
                            ? [
                                  ...state.activeEntry.labels,
                                  ...action.payload.labels,
                              ]
                            : [...action.payload.labels],
                }
            }
            // const current_labels_ids = state.labels.list.map(
            //     (i: IDropdownItem) => i.uid
            // )
            // const new_labels = action.payload.labels.filter(
            //     (label: ILabel) => !current_labels_ids.includes(label.uid)
            // )

            // state.labels.list = [...state.labels.list, ...new_labels]
            // state.activeEntry = {
            //     ...state.activeEntry,
            //     labels:
            //         state.activeEntry && state.activeEntry?.labels
            //             ? [
            //                   ...state.activeEntry.labels,
            //                   ...action.payload.labels,
            //               ]
            //             : [...action.payload.labels],
            // }
            // state.loading = false
            // state.error = null
        },

        // from saga on error
        addLabelToCarEntryError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // from saga on success
        removeLabelFromCarEntrySuccess: (
            state,
            action: PayloadAction<IUpdateEntryLabelPayload>
        ) => {
            state.activeEntry = {
                ...state.activeEntry!,
                labels: action.payload.labels,
            }
            state.error = null
            state.loading = false
        },

        // send request to api
        addAttachmentsToEntryRequest: (
            state,
            action: PayloadAction<IAddEntryAttachmenstApiRequest>
        ) => {
            state.isEntryAttachmentLoading = true
            state.error = null
        },

        // from saga on error
        addAttachmentToEntryError: (state, action: PayloadAction<any>) => {
            state.isEntryAttachmentLoading = false
            state.error = action.payload
        },
        // from saga on success
        addAttachmentToEntrySuccess: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.activeEntry = {
                ...state.activeEntry!,
                attachmentIDs: state.activeEntry?.attachmentIDs && [
                    ...action.payload,
                    ...state.activeEntry?.attachmentIDs,
                ],
            }
            state.error = null
            state.loading = false

            // state.activeEntry = {
            //     ...state.activeEntry,
            //     attachmentIDs: state.activeEntry?.attachmentIDs && [
            //         ...action.payload,
            //         ...state.activeEntry?.attachmentIDs,
            //     ],
            // }
            // // loading: still true, will become false when attachment manager finishes,
            // state.isEntryAttachmentLoading = true
            // state.error = null
        },

        addAttachmentToCostRequest: (
            state,
            action: PayloadAction<IAddAttachmentToCostSaga>
        ) => {
            // loading: true,
            state.isCostAttachmentLoading = true
            state.error = null
        },

        // from saga on error
        addAttachmentToCostError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            // loading: false,
            state.isCostAttachmentLoading = false
            state.error = action.payload
        },

        // from saga on success
        addAttachmentToCostSuccess: (
            state,
            action: PayloadAction<{ cost_id: string; att_id: string }>
        ) => {
            if (state.activeEntry && state.activeEntry.costItemsObj) {
                state.activeEntry = {
                    ...state.activeEntry,
                    costItemsObj: {
                        ...(state.activeEntry?.costItemsObj &&
                            state.activeEntry!.costItemsObj),
                        [action.payload.cost_id]: {
                            ...state.activeEntry?.costItemsObj[
                                action.payload.cost_id
                            ],
                            attachmentID: action.payload.att_id,
                        },
                    },
                }
            }
            state.error = null
            state.isCostAttachmentLoading = false
        },

        // from saga on success
        deleteCostAttachmentSuccess: (state) => {
            state.loading = false
            state.error = null
            state.activeCost = {
                ...state.activeCost!,
                attachmentID: undefined,
            }
        },

        // from saga on success
        deleteEntryAttachmentSuccess: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.loading = false
            state.error = null
            state.activeEntry = {
                ...state.activeEntry!,
                attachmentIDs: [...action.payload],
            }
        },

        // from saga on error
        deleteEntryAttachmentError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // from saga on error
        deleteCostAttachmentError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        deleteCostAttachmentRequest: (
            state,
            action: PayloadAction<IDeleteCostAttachmentPayloadReq>
        ) => {
            state.loading = true
            state.error = null
        },

        deleteEntryAttachmentRequest: (
            state,
            action: PayloadAction<IDeleteEntryAttachmentPayloadReq>
        ) => {
            state.loading = true
            state.error = null
        },

        // from saga, on success
        addGalleryImagesIdsToEntrySuccess: (
            state,
            action: PayloadAction<IAddGalleryImagesIdsToEntry>
        ) => {
            let obj6 = JSON.parse(JSON.stringify(state))
            let existingids: string[] =
                obj6.activeEntry && obj6.activeEntry.gallery_image_ids
                    ? obj6.activeEntry.gallery_image_ids
                    : []

            state.activeEntry = {
                ...state.activeEntry!,
                gallery_image_ids: [
                    ...existingids,
                    ...action.payload.image_ids,
                ],
            }
            state.loading = false
            state.error = null
        },

        deleteGalleryImagesIdFromEntrySuccess: (
            state,
            action: PayloadAction<IDeleteEntryGalleryImagePayload>
        ) => {
            let obj6 = JSON.parse(JSON.stringify(state))

            let active_entry_images: string[] | undefined =
                obj6.activeEntry && obj6.activeEntry.gallery_image_ids
                    ? obj6.activeEntry.gallery_image_ids
                    : undefined

            let new_images_ids_array: string[] | undefined = active_entry_images
                ? [...active_entry_images]
                : undefined

            if (new_images_ids_array) {
                let indexOfImg: number = new_images_ids_array.indexOf(
                    action.payload.imageid
                )
                if (indexOfImg >= 0) {
                    new_images_ids_array.splice(indexOfImg, 1)
                }
            }
            state.activeEntry = {
                ...state.activeEntry!,
                gallery_image_ids: new_images_ids_array,
            }

            state.loading = false
            state.error = null
        },
        manageAddAttachmentsToEntryRequest: (
            state,
            action: PayloadAction<IManageAddAttachmentsToEntryRequest>
        ) => {
            state.isEntryAttachmentLoading = true
            state.error = null
        },

        manageAddAttachmentsToEntryError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.isEntryAttachmentLoading = false
            state.error = action.payload
        },

        manageAddAttachmentsToEntrySuccess: (state) => {
            state.isEntryAttachmentLoading = false
            state.error = null
        },

        cleanUpTimelineDataOnLogoutSuccess: (state) => {
            state.timeline_data_normalised_by_car_id = {}
            state.categories = {
                ids: timeline_category_ids,
                data: timeline_category_data,
                category_searchable_items_list:
                    timeline_category_searchable_items_list_mock,
            }
            state.labels = {
                data: {},
                list: [],
            }
            state.activeEntry = undefined
            state.activeCost = undefined
            state.formsData = {
                creation: {
                    timeline_creation_steps_data: timeline_fields_data_per_step,
                    timeline_creation_steps_object_of_indexed_list: {
                        [TimelineCreationTypeIdsEnum.add_new_desktop]:
                            create_timeline_add_new_desktop_steps_list,
                        [TimelineCreationTypeIdsEnum.cost]:
                            create_timeline_cost_item_steps_list,
                        [TimelineCreationTypeIdsEnum.cost_desktop]:
                            create_timeline_cost_item_desktop_steps_list,
                        [TimelineCreationTypeIdsEnum.attachment]:
                            create_timeline_attachment_steps_list,
                        [TimelineCreationTypeIdsEnum.label]:
                            create_entry_label_steps_list,
                        [TimelineCreationTypeIdsEnum.add_new]:
                            create_timeline_add_new_steps_list,
                        service: create_timeline_service_steps_list,
                        maintenance: create_timeline_maintenance_steps_list,
                        restoration: create_timeline_restoration_steps_list,
                        admin: create_timeline_admin_steps_list,
                        // @ts-ignore
                        insurance: create_timeline_insurance_steps_list,
                        history: create_timeline_history_steps_list,
                        events: create_timeline_events_steps_list,
                        other: create_timeline_others_steps_list,
                    },
                    data_to_be_submitted: {},
                },
                fieldsList: {
                    fieldsListByCategory: {
                        service: timeline_general_fields_list,
                        maintenance: timeline_general_fields_list,
                        restoration: timeline_general_fields_list,
                        admin: timeline_general_fields_list,
                        insurance: timeline_general_fields_list,
                        history: timeline_general_fields_list,
                        events: timeline_general_fields_list,
                        other: timeline_general_fields_list,
                    }, // @ts-ignore
                    fieldsListByType: {
                        [TimelineFormTypeIDsEnum.cost]:
                            timeline_cost_fields_list,
                        [TimelineFormTypeIDsEnum.attachment]:
                            timeline_attachment_fields_list,
                        [TimelineFormTypeIDsEnum.notes]:
                            timeline_notes_fields_list,
                        [TimelineFormTypeIDsEnum.labels]:
                            timeline_labels_fields_list,
                        [TimelineFormTypeIDsEnum.mileage]:
                            timeline_mileage_fields_list,
                    },
                },
            }
            state.error = null
            state.loading = false
            state.isEntryAttachmentLoading = false
            state.isCostAttachmentLoading = false
        },

        addTimelineItemWithCostsToCarRequest: (
            state,
            action: PayloadAction<IAddTimelineItemsWithCostPayloadRequest>
        ) => {
            state.loading = true
            state.error = null
        },
        updateTimelineItemWithCostRequest: (
            state,
            action: PayloadAction<IUpdateTimelineItemPayloadRequest>
        ) => {
            state.loading = true
            state.error = null
        },
        updateTimelineItemWithCostSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        updateTimelineItemWithCostError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        removeLabelToCarEntryError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        addLabelToCarEntryRequest: (
            state,
            action: PayloadAction<IUpdateEntryLabelPayload>
        ) => {},
        removeLabelFromCarEntryRequest: (
            state,
            action: PayloadAction<IUpdateEntryLabelPayload>
        ) => {},
        resetTimelineItems: (state, action: PayloadAction<string>) => {
            state.timeline_data_normalised_by_car_id = undefined
            state.activeEntry = undefined
            state.loading = false
            state.error = null
        },
    },
})
export const timelineActions = timelineSlice.actions

export default timelineSlice.reducer

export type TimelineReducer = ReturnType<typeof timelineSlice.reducer>
