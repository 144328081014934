import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

type IStyle = {
    $isLeftAligned?: boolean
}

const H1Styled = styled.h1<IStyle>`
    text-shadow: none;
    font-family: Lato;
    font-weight: 600;
    color: var(--text-strong, #1a1a1a);
    font-size: 26px;
    line-height: 31px;
    text-align: ${(props) => (props.$isLeftAligned ? 'left' : 'center')};
    width: 100%;
    box-sizing: border-box;
    margin-top: 16px;
    margin-bottom: 16px;

    @media ${device.mobile} {
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    @media ${device.smallest_laptops} {
        font-size: 22px;
    }

    @media ${device.desktop} {
        font-size: 24px;
    }

    @media ${device.large_desktop} {
        font-size: 26px;
    }
`

export interface IH1V3Props {
    children?: React.ReactNode
    leftalign?: boolean
}

const H1V3: React.FunctionComponent<IH1V3Props> = (props) => (
    <H1Styled $isLeftAligned={props.leftalign}>{props.children}</H1Styled>
)

export default H1V3
