import styled from 'styled-components'
import chevron_to_left from '../../../../../public/assets/navigation/chevron_to_left.svg'
import FadedSlower from '../../../animated/FadedSlower'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { AtdConfWarningBox } from './insuranceTopBarWithProgressionRate'
import { useState } from 'react'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { insuranceActions } from '../../../../../redux/insuranceQuoteApplication/reducer'
import CircleLoader from '../../../../atoms/loader/circleLoader'
import { IInsuranceApplicationSectionId } from '../../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import useThemes from '../../../../../providers/theme/hooks'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../../../providers/theme/colours'
import { motion } from 'framer-motion'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import play from '../../../../../public/assets/icons/play.svg'
import { posthog } from 'posthog-js'
import ThemeSwitcherMobile from '../../../../organisms/themeSwitcher/themeSwitcherMobile'
import StandardCtaBtn from '../../../../atoms/Button/standardCtaBtn'
import ActiveThemeIcon from '../../../../atoms/icons/themeIcons'

type IStyle = { $theme: ITheme }

const Container = styled.div<IStyle>`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 80px;
    z-index: 100;
    background-color: ${(props) => colours[props.$theme].background_default};
    border-bottom: ${(props) =>
        `1px solid ${colours[props.$theme].border_muted}`};
    transition: all 100ms;
`

const Row = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const SubContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    width: 100%;
    box-sizing: border-box;
    padding-top: 1rem; // 2rem;
    padding-bottom: 1rem;
    padding-left: 1.2rem;
    padding-right: 1rem;
`

const BreadCrumbs = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 14px;
`
const BreadCrumbsItem = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    align-items: center;
`
const BreadCrumbsItemTextCurrent = styled.p`
    font-size: 18px;
    font-family: Lato-bold;
    color: var(--text-strong, #1a1a1a);
    line-height: 32px;
    letter-spacing: 0.09px;
    width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
`

const BreadCrumbsItemText = styled.p<IStyle>`
    color: ${(props) => colours[props.$theme].text_neutral_default};
    text-align: center;
    /* Mobile/Text/T100 Strong */
    font-size: 12px;
    font-family: Lato-bold;
    line-height: 16px;
    letter-spacing: 0.001px;
    width: 99%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
`

const ProgressRate = styled.div<IStyle>`
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    background: ${(props) => colours[props.$theme].background_neutral_subtle};
    border-radius: 4px;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 16px;
    font-family: Lato-bold;
    line-height: 20px;
    letter-spacing: 0.08px;
`

const ProgressBar = styled.div`
    all: unset;
    height: 4px;
    border-radius: 4px;
`

const GoBackBtn = styled.button<IStyle>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 8px;
    transform: translateY(1px);
    background: ${(props) => colours[props.$theme].background_default};
    color: ${(props) => colours[props.$theme].text_default};
    border: none;
    transition: all 150ms;
`

const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(83%) contrast(84%);
    height: 14px;
    margin-left: 6px;
    transform: rotate(180deg);
`

const LeftSide = styled.div`
    flex: 1;
    min-width: 0;
`

type Props = {
    isMobile?: boolean
    breadCrumbs?: { pageName: string; pageUrl?: () => void; isCaps?: boolean }[]
    progressionRate?: number
    goBack?: () => void
    onSave?: () => void
    hasFormChanged?: boolean
    isDisabled?: boolean
    disabledProgressText?: string
    sectionId?: IInsuranceApplicationSectionId
    wizard_click_play?: () => any
}
const InsuranceTopBarWithProgressionRateMobile = (props: Props) => {
    let {
        progressionRate,
        breadCrumbs,
        goBack,
        onSave,
        hasFormChanged,
        isDisabled,
        disabledProgressText,
        sectionId,
        wizard_click_play,
    } = props
    const dispatch = useAppDispatch()

    let isCurrentPageIndex = breadCrumbs!.length - 1

    const [showGoBackConf, toggleGoBackConf] = useState(false)
    const [breadCrumbActiveIndex, setBreadCrumbActiveIndex] = useState(-1)

    const handleOpenChangeConf = (open: boolean) => {
        if (!open) {
            toggleGoBackConf(open)
            return
        }

        if (hasFormChanged) {
            toggleGoBackConf(open)
        } else {
            goBack && goBack()
        }
    }

    const saveChanges = () => {
        toggleGoBackConf(false)
        onSave && onSave()
    }

    const destroyChanges = (func: (() => void) | undefined) => {
        toggleGoBackConf(false)
        dispatch(insuranceActions.reset_draft_insurance_application())
        func && func()
    }

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )
    let applicationLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isLoading
    )

    let showLoadingUI = !application.id || applicationLoading ? true : false

    const { theme } = useThemes()

    const [isThemeSwitcherOpen, setIsThemeSwitcherOpen] = useState(false)
    const openThemeSwitcher = () => setIsThemeSwitcherOpen(true)
    const closeThemeSwitcher = () => setIsThemeSwitcherOpen(false)

    // let is_100 = application?.overall_completion === 100 ? true : false

    return (
        <>
            <Container $theme={theme}>
                <SubContainer>
                    <LeftSide>
                        {breadCrumbs && (
                            <BreadCrumbsItem
                                onClick={
                                    breadCrumbs &&
                                    breadCrumbs[isCurrentPageIndex].pageUrl
                                }
                            >
                                {goBack && (
                                    <FadedSlower duration={0.3}>
                                        <GoBackBtn
                                            $theme={theme}
                                            onClick={goBack}
                                        >
                                            <Chevron
                                                src={chevron_to_left}
                                                style={{
                                                    transform:
                                                        'rotate(0) translateX(-4px)',
                                                }}
                                            />
                                        </GoBackBtn>
                                    </FadedSlower>
                                )}
                                <BreadCrumbsItemTextCurrent
                                    style={{
                                        color: sectionId
                                            ? insurance_colours[theme].sections[
                                                  sectionId
                                              ].primary_text
                                            : colours[theme].text_strong,

                                        cursor: 'pointer',
                                        textTransform: breadCrumbs[
                                            isCurrentPageIndex
                                        ]?.isCaps
                                            ? 'uppercase'
                                            : undefined,
                                        fontSize: breadCrumbs[
                                            isCurrentPageIndex
                                        ]?.isCaps
                                            ? 14
                                            : 16,
                                    }}
                                >
                                    {breadCrumbs &&
                                        breadCrumbs[isCurrentPageIndex]
                                            .pageName}
                                </BreadCrumbsItemTextCurrent>
                            </BreadCrumbsItem>
                        )}

                        {breadCrumbs && (
                            <Popconfirm
                                title={
                                    <AtdConfWarningBox
                                        title="You have unsaved changes."
                                        theme={theme}
                                    />
                                }
                                open={showGoBackConf}
                                onOpenChange={handleOpenChangeConf}
                                onConfirm={saveChanges}
                                onCancel={() => {
                                    let func =
                                        breadCrumbs &&
                                        breadCrumbActiveIndex >= 0 &&
                                        breadCrumbs[breadCrumbActiveIndex]
                                            .pageUrl
                                            ? breadCrumbs[breadCrumbActiveIndex]
                                                  .pageUrl
                                            : undefined
                                    destroyChanges(func)
                                }}
                                okText="Save"
                                cancelText="Discard changes"
                                okButtonProps={{
                                    style: {
                                        backgroundColor: sectionId
                                            ? insurance_colours[theme].sections[
                                                  sectionId
                                              ].primary_100
                                            : colours[theme].primary,
                                        border: 'none',
                                        borderRadius: 4,
                                        height: 32,
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                    },
                                }}
                                cancelButtonProps={{
                                    danger: true,
                                    style: {
                                        borderRadius: 4,
                                        height: 32,
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                    },
                                }}
                                placement="bottomLeft"
                                style={{
                                    borderRadius: 4,
                                    transform: 'translateY(-8px) !important',
                                }}
                                icon={
                                    <ExclamationCircleFilled
                                        style={{ color: colours[theme].error }}
                                    />
                                }
                            >
                                <BreadCrumbs>
                                    {breadCrumbs.map((item, i) => {
                                        return i < breadCrumbs!.length - 1 ? (
                                            <BreadCrumbsItem
                                                onClick={() => {
                                                    setBreadCrumbActiveIndex(i)
                                                    if (hasFormChanged) {
                                                        toggleGoBackConf(true)
                                                    } else {
                                                        item.pageUrl &&
                                                            item.pageUrl()
                                                    }
                                                }}
                                                key={`progress_bar_nav_mobile_mobile`}
                                            >
                                                <BreadCrumbsItemText
                                                    $theme={theme}
                                                    style={{
                                                        textTransform:
                                                            item?.isCaps
                                                                ? 'uppercase'
                                                                : undefined,
                                                        fontSize: item.isCaps
                                                            ? 12
                                                            : 14,
                                                    }}
                                                >
                                                    {item.pageName}
                                                </BreadCrumbsItemText>
                                                {breadCrumbs &&
                                                    breadCrumbs.length > 2 &&
                                                    breadCrumbs.length > 1 &&
                                                    i < isCurrentPageIndex && (
                                                        <Chevron
                                                            src={
                                                                chevron_to_left
                                                            }
                                                        />
                                                    )}
                                            </BreadCrumbsItem>
                                        ) : null
                                    })}
                                </BreadCrumbs>{' '}
                            </Popconfirm>
                        )}
                    </LeftSide>

                    <Row>
                        {progressionRate ? (
                            showLoadingUI ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        width: 40,
                                        height: 40,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 4,
                                        backgroundColor:
                                            colours[theme]
                                                .background_neutral_subtle,
                                    }}
                                >
                                    <CircleLoader
                                        size={24}
                                        color={
                                            sectionId
                                                ? insurance_colours[theme]
                                                      .sections[sectionId]
                                                      .primary_100
                                                : colours[theme].primary
                                        }
                                    />
                                </div>
                            ) : (
                                <ProgressRate
                                    $theme={theme}
                                    style={
                                        disabledProgressText && isDisabled
                                            ? {
                                                  fontSize: 14,
                                                  whiteSpace: 'nowrap',
                                              }
                                            : {}
                                    }
                                >
                                    {isDisabled && disabledProgressText
                                        ? disabledProgressText
                                        : `${progressionRate}%`}
                                </ProgressRate>
                            )
                        ) : null}
                        <div style={{ paddingLeft: 12 }} />

                        <StandardCtaBtn
                            customWidth="40px"
                            customHeight="40px"
                            removePaddings
                            isSecondary
                            bg={colours[theme].background_neutral_subtle}
                            customHoverColor={
                                colours[theme].background_neutral_subtle
                            }
                            onClick={openThemeSwitcher}
                            removeShadowOnHover
                        >
                            <ActiveThemeIcon />
                        </StandardCtaBtn>

                        {wizard_click_play && !showLoadingUI && (
                            <div
                                style={{ paddingRight: '8px', paddingLeft: 8 }}
                            >
                                <motion.div whileTap={{ scale: 1.1 }}>
                                    <ButtonAtom
                                        theme="naked"
                                        onClick={() => {
                                            if (wizard_click_play) {
                                                posthog.capture(
                                                    'play insurance wizard click'
                                                )
                                                wizard_click_play()
                                            }
                                        }}
                                    >
                                        <img
                                            style={{
                                                cursor: 'pointer',
                                                width: '32px',
                                            }}
                                            src={play}
                                        />
                                    </ButtonAtom>
                                </motion.div>
                            </div>
                        )}
                    </Row>
                </SubContainer>
                {progressionRate ? (
                    <ProgressBar
                        style={{
                            width: `${
                                showLoadingUI || isDisabled
                                    ? '0'
                                    : progressionRate
                            }vw`,
                            backgroundColor: sectionId
                                ? insurance_colours[theme].sections[sectionId]
                                      .primary_100
                                : colours[theme].primary,
                        }}
                    />
                ) : null}
            </Container>
            <ThemeSwitcherMobile
                isOpen={isThemeSwitcherOpen}
                closeList={closeThemeSwitcher}
            />
        </>
    )
}

export default InsuranceTopBarWithProgressionRateMobile
