// INSERT_YOUR_REWRITE_HERE

import React from 'react'

import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    box-sizing: border-box;
    @media ${device.beyond_ipad_mobile} {
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100%;
    box-sizing: border-box;
`

const Header = styled.div`
    font-family: lato-light;
    font-size: 16px;
    text-transform: uppercase;
    min-width: 170px;
    color: var(--text-strong, #1a1a1a);
`

const Title = styled.div`
    font-family: 'Lato-Light';
    font-size: 23px;
    padding-right: 28px;
    color: var(--text-strong, #1a1a1a);
    line-height: 36px;

    @media (max-width: 390px) {
        font-size: 22px;
        line-height: 30px;
    }
`
const Line = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

type Props = {
    title: string
}

const ApexSingleArticleHeaderMobile: React.FC<Props> = ({ title }) => {
    return (
        <Wrapper>
            <Row>
                <Header>The Apex Articles</Header>
                <Line />
            </Row>
            <div style={{ paddingTop: '20px' }} />

            <Title>{title}</Title>
        </Wrapper>
    )
}

export default ApexSingleArticleHeaderMobile
