import gql from 'graphql-tag'
import { client } from '../../../helpers/prismicUtils/prismicHelpers'
import dayjs from 'dayjs'
import { get_check_cookie_name } from '../../../helpers/cookies'

/*
 * Apex homepage query
 */

const apexHomepageQuery = gql`
    query apexArticleHomepageView(
        # $last_week: Date
        # $week_ahead_end: Date
        $week_start: Date
    ) {
        allApex_homepage_slideshows {
            edges {
                node {
                    slides {
                        title
                        blurb
                        quote
                        text_position
                        img
                        link {
                            ... on _ExternalLink {
                                url
                            }
                        }
                    }
                }
            }
        }
        allApex_podcastss(first: 4, sortBy: date_DESC) {
            edges {
                node {
                    date
                    title
                    name
                    short_title
                    spotify_url {
                        ... on _ExternalLink {
                            url
                        }
                    }
                    podcast_embed_link {
                        ... on _ExternalLink {
                            url
                        }
                    }
                }
            }
        }
        allApex_weekly_eventss(
            first: 5
            sortBy: event_date_start_ASC
            where: {
                event_date_start_after: $week_start
                # event_date_start_before: $week_ahead_end
            }
        ) {
            edges {
                node {
                    date
                    title
                    title
                    event_location
                    event_date_end
                    event_date_start
                    link {
                        ... on _ExternalLink {
                            url
                        }
                    }
                    _meta {
                        uid
                    }
                }
            }
        }
        allApex_weekly_quotess(sortBy: date_DESC, first: 1) {
            edges {
                node {
                    quote
                    title
                    name
                    date
                }
            }
        }
        allApex_articless(first: 2, sortBy: date_DESC) {
            edges {
                node {
                    date
                    external_cover_img
                    title
                    introduction
                    _meta {
                        uid
                    }
                }
            }
        }
        allApex_car_of_the_weeks(sortBy: date_DESC, first: 1) {
            edges {
                node {
                    date
                    img
                    car_name
                    title
                    description
                    link {
                        ... on _ExternalLink {
                            url
                        }
                    }
                }
            }
        }
        allApex_weekly_highlightss(sortBy: date_DESC, first: 5) {
            edges {
                node {
                    featured
                    link {
                        ... on _ExternalLink {
                            url
                        }
                    }
                    image
                    image_credit
                    title
                    description
                }
            }
        }
        allInterview_category_highlightss {
            edges {
                node {
                    category_1_name
                    category_1_item {
                        name
                        title
                        date
                        uid
                        blurb
                        headshot
                    }
                    category_2_name
                    category_2_item {
                        name
                        title
                        date
                        uid
                        blurb
                        headshot
                    }
                    category_3_name
                    category_3_item {
                        name
                        title
                        date
                        uid
                        blurb
                        headshot
                    }
                    category_4_name
                    category_4_item {
                        name
                        title
                        date
                        blurb
                        headshot
                    }
                }
            }
        }
    }
`

export const queryApexHomepageContent = async () => {
    const previewCookie = get_check_cookie_name('io.prismic.preview')

    const queryOptions: any = {
        query: apexHomepageQuery,
        variables: {
            // last_week: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
            week_start: dayjs().day(1).format('YYYY-MM-DD'), // Monday is 1 in dayjs
            // week_ahead_end: dayjs().add(7, 'days').format('YYYY-MM-DD'),
        },
    }

    if (previewCookie) {
        queryOptions.context = {
            headers: {
                'Prismic-ref': previewCookie,
            },
        }
    }

    return client.query(queryOptions)
}
