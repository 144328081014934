import React, { useState, useEffect } from 'react'
import AddUserProfileImageFormMobile from '../addUserProfileImageForm/addUserProfileImageFormMobile'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'

import axios from 'axios'

interface Props {
    close: any
    user_id: string
}

const WrapperUserProfileImageMobile: React.FC<Props> = ({ close, user_id }) => {
    const presignedurls = useAppSelector(
        (state) => state.fileStorage.presignedurls
    )

    // const [imageUploadedData, setImageUploadedData] = useState<any | null>(null)
    const [progressPercent, setProgressPercent] = useState<number | null>(null)
    const [changeInPresignedURL, setChangeInPresignedURL] = useState(false)
    const [presignedUrlsState, setPresignedUrlsState] = useState<any>(undefined)
    const [file, setFile] = useState<File | undefined>(undefined)
    const [isSomethingBeingUploaded, setIsSomethingBeingUploaded] =
        useState(true)
    const [isImageCropperShown, setIsImageCropperShown] = useState(false)

    useEffect(() => {
        if (presignedurls !== presignedUrlsState) {
            if (presignedurls && presignedurls[0]) {
                if (file) {
                    uploadPresignedUrlImageToS3(file)
                }
                setPresignedUrlsState(presignedurls)
                setChangeInPresignedURL(!changeInPresignedURL)
            }
        }
    }, [presignedurls, file, presignedUrlsState, changeInPresignedURL])

    const uploadPresignedUrlImageToS3 = async (stateFile: File) => {
        if (stateFile && presignedurls && presignedurls[0]) {
            try {
                await axios.request({
                    method: 'put',
                    url: presignedurls[0].url,
                    data: stateFile,
                    onUploadProgress: (p: any) => {
                        setProgressPercent((p.loaded / p.total) * 100)
                    },
                })
                setIsSomethingBeingUploaded(false)
            } catch (e) {
                apiSentryErrorHandler(
                    e,
                    'Upload presigned url user profile image to s3 error'
                )
                return Promise.reject(e)
            }
        }
    }

    const handleFilesGivenInWrapperComponent = (file: File) => {
        setFile(file)
    }

    const deleteImageUploaded = () => {
        setFile(undefined)
        setProgressPercent(null)
    }

    const toggleShowImageCropper = (res?: boolean) => {
        setIsImageCropperShown(res !== undefined ? res : !isImageCropperShown)
    }
    const dispatch = useAppDispatch()

    return (
        <div>
            <AddUserProfileImageFormMobile
                close={close}
                deleteImageUploaded={deleteImageUploaded}
                setImageUploaded={handleFilesGivenInWrapperComponent}
                currentImagesUploaded={file}
                progressPercent={progressPercent}
                isSomethingBeingUploaded={isSomethingBeingUploaded}
                toggleShowImageCropper={toggleShowImageCropper}
                isImageCropperShown={isImageCropperShown}
                user_id={user_id}
                dispatch={dispatch}
                presignedurls={presignedurls}
            />
        </div>
    )
}

export default WrapperUserProfileImageMobile
