import React from 'react'
import styled from 'styled-components'
import Roadmap from '../../atoms/roadmap/roadmapDesktop'
import { IRoadmapProps } from '../../atoms/roadmap/roadmapDesktop'

const Wrap = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 30px;
`

const Title = styled.h1`
    font-family: 'Lato';
    /* font-size: 16px; */
    text-align: center;
    padding-bottom: 50px;
`

interface IProps {
    title?: string
}

const UpcomingPageS1: React.FunctionComponent<IProps & IRoadmapProps> = (
    props
) => {
    return (
        <Wrap>
            {props.title && <Title>{props.title}</Title>}
            <Roadmap milestoneList={props.milestoneList} />
        </Wrap>
    )
}

export default UpcomingPageS1
