import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import SkeletonAbsolute from '../../skeleton/skeletonAbsolute'

type Props = {
    src: string
    srcSet?: string
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 48vh;
    position: relative;

    max-height: 300px;

    @media (min-width: 1175px) {
        max-height: 400px;
    }

    @media (min-width: 1500px) {
        max-height: 450px;
    }

    @media ${device.large_desktop} {
        height: 35vh;
        max-height: 500px;
    }

    @media (min-width: 2000px) {
        height: 35vh;
        min-height: 600px;
        max-height: 600px;
    }
`

const BannerImageOwner = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;

    object-fit: cover;
`

const ShowroomBannerImageOwnerDesktop = (props: Props) => {
    const [isImgLoading, setIsImgLoading] = useState<boolean>(true)
    const [src, setSrc] = useState<string>('')

    useEffect(() => {
        let url = props.src
        setSrc(url)
    }, [props.src])

    return (
        <Wrapper>
            <SkeletonAbsolute isthingloading={isImgLoading} />
            <BannerImageOwner
                src={src}
                onLoad={() => {
                    setIsImgLoading(false)
                }}
                srcSet={props.srcSet}
            />
        </Wrapper>
    )
}

export default ShowroomBannerImageOwnerDesktop
