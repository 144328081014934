import styled from 'styled-components'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import SquareIconBtn from '../../../../atoms/Button/squareIconBtn'
import chevron_to_left from '../../../../../public/assets/navigation/chevron_to_left.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { showroomActions } from '../../../../../redux/showroom/reducer'
import CrossIcon from '../../../../atoms/icons/crossIcon'
import { customNavDataActions } from '../../../../../redux/localdata/customNav/reducer'
import posthog from 'posthog-js'

// container for action button
const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`

const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(83%) contrast(84%);
    cursor: pointer;
`

type Props = {
    onClickEnquire?: any
    isGoBackMode?: boolean
    entryID: string
    is_watched: boolean
    isReadOnly: boolean
    carID?: string
    onClickShare?: any
    isSold?: boolean
    isOwner?: boolean
    goBack?: () => any
    onClose?: () => any
}

const ShowroomCarTopBarMobile = (props: Props) => {
    let dispatch = useAppDispatch()

    const navigate = useNavigate()
    const location = useLocation()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const determineBackPath = () => {
        if (props.goBack) {
            props.goBack()
            return
        }
        if (props.isReadOnly === true) {
            if (
                props.entryID &&
                location.pathname.includes(props.entryID) &&
                (location.pathname.includes('gallery') ||
                    location.pathname.includes('description'))
            ) {
                navigate(`/showroom/${props.entryID}`)
            } else {
                if (props.isSold) {
                    navigate('/showroom/sold')
                } else navigate('/showroom')
            }
        } else if (props.carID) {
            navigate(`/car/${props.carID}`)
        } else {
            navigate(-1)
        }
    }

    return (
        <div
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    padding: props.onClose ? 0 : props.isGoBackMode ? 0 : 10,
                }}
                onClick={() => {
                    if (props.onClose) {
                        props.onClose()
                    } else determineBackPath()
                }}
            >
                {props.onClose ? (
                    <div style={{ paddingTop: 5 }}>
                        <CrossIcon size="15" color="grey" />
                    </div>
                ) : (
                    <Chevron
                        style={{ height: props.isGoBackMode ? '14px' : '10px' }}
                        src={chevron_to_left}
                    />
                )}
            </div>

            {props.isGoBackMode && (
                <div
                    style={{
                        position: 'absolute',
                        left: '50px',
                        fontSize: '17px',
                        fontFamily: 'Lato',
                        top: '21px',
                        color: 'var(--text-strong, #1a1a1a)',
                    }}
                    onClick={determineBackPath}
                >
                    Go back
                </div>
            )}

            {!props.isGoBackMode && (
                <Actions>
                    {props.isSold && !props.is_watched ? null : (
                        <SquareIconBtn
                            icon="watchlist"
                            padding="12px"
                            size="40px"
                            isActive={
                                props.isReadOnly ? props.is_watched : false
                            }
                            isDisabled={props.isReadOnly ? false : true}
                            onClick={() => {
                                if (!userLoggedIn) {
                                    navigate(`/login`)
                                    dispatch(
                                        customNavDataActions.setInitialLandingUrl(
                                            `/showroom/${props.entryID}`
                                        )
                                    )

                                    posthog.capture(
                                        'toggle_showroom_entry_to_watchlist click from entry - user not logged in'
                                    )
                                } else if (props.is_watched === true) {
                                    dispatch(
                                        showroomActions.removeEntryFromWatchlistRequest(
                                            props.entryID
                                        )
                                    )
                                    posthog.capture(
                                        'remove_showroom_entry_to_watchlist click from entry'
                                    )
                                } else if (!props.is_watched) {
                                    dispatch(
                                        showroomActions.addEntryToWatchlistRequest(
                                            props.entryID
                                        )
                                    )

                                    posthog.capture(
                                        'add_showroom_entry_to_watchlist click from entry'
                                    )
                                }
                            }}
                        />
                    )}

                    <SquareIconBtn
                        icon="share"
                        isDisabled={props.isReadOnly ? false : true}
                        onClick={() => {
                            props.onClickShare && props.onClickShare()
                        }}
                    />

                    {!props.isSold && (
                        <ButtonAtom
                            theme="secondary"
                            width="80px"
                            height="40px"
                            textTransform="capitalize"
                            dataCyId={'showroom-car-enquire'}
                            onClick={() => {
                                if (!userLoggedIn) {
                                    navigate(`/login`)
                                    dispatch(
                                        customNavDataActions.setInitialLandingUrl(
                                            `/showroom/${props.entryID}/enquiry`
                                        )
                                    )
                                } else {
                                    props.onClickEnquire &&
                                        props.onClickEnquire()
                                }
                            }}
                            disabled={
                                props.isReadOnly && !props.isOwner
                                    ? false
                                    : true
                            }
                        >
                            enquire
                        </ButtonAtom>
                    )}
                </Actions>
            )}
        </div>
    )
}

export default ShowroomCarTopBarMobile
