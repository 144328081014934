import { IPrismicFieldPropsToState } from 'apexModels'
import React from 'react'

import styled from 'styled-components'
import { device } from '../../../../../../templates/displays/devices'

const Wrapper = styled.div`
    @media ${device.beyond_ipad_mobile} {
    }

    color: var(--text-strong, #1a1a1a);
`
const Normal = styled.div`
    font-family: Lato-light;
    font-size: 17px;
    line-height: 31px;
    padding-bottom: 18px;
    color: var(--text-strong, #1a1a1a);
`

const Strong = styled.div`
    font-family: Lato;
    font-size: 17px;
    line-height: 28px;
    padding-bottom: 20px;
    padding-top: 22px;
    color: var(--text-strong, #1a1a1a);
`
const Header = styled.h3`
    font-family: Lato-Light;
    font-size: 22px;
    line-height: 28px;
    padding-bottom: 12px;
    padding-top: 28px;
    max-width: 80vw;
    color: var(--text-strong, #1a1a1a);
`
const NormalSpan = styled.span`
    font-family: Lato-light;
    font-size: 17px;
    line-height: 31px;
    padding-bottom: 18px;
    color: var(--text-strong, #1a1a1a);
`

const StrongSpan = styled.span`
    font-family: Lato;
    font-size: 17px;
    line-height: 28px;
    padding-bottom: 20px;
    padding-top: 22px;
    color: var(--text-strong, #1a1a1a);
`
const HeaderSpan = styled.span`
    font-family: Lato-Light;
    font-size: 22px;
    line-height: 28px;
    padding-bottom: 12px;
    padding-top: 28px;
    max-width: 80vw;
    color: var(--text-strong, #1a1a1a);
`
type Props = {
    item: IPrismicFieldPropsToState
}

const ApexBodyRichContentTextItemMobile: React.FC<Props> = ({ item }) => {
    const renderRichContentElem = () => {
        if (item.url && item.text) {
            return (
                <NormalSpan>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={item.url}
                    >{`${item.text} `}</a>
                </NormalSpan>
            )
        }

        if (
            item.text &&
            !item.text.includes('https://') &&
            !item.text.includes('www.')
        ) {
            return (
                <Wrapper>
                    {item.text &&
                        item.textStyle !== 'strong' &&
                        item.textStyle !== 'heading' &&
                        item.textStyle !== 'em' && <Normal>{item.text}</Normal>}

                    {item.text && item.textStyle === 'strong' && (
                        <Strong>{item.text}</Strong>
                    )}

                    {item.text && item.textStyle === 'heading' && (
                        <Header>{item.text}</Header>
                    )}

                    {item.text && item.textStyle === 'em' && (
                        <div style={{ fontStyle: 'italic' }}>
                            <Normal>{item.text}</Normal>
                        </div>
                    )}
                </Wrapper>
            )
        } else if (item.text) {
            return (
                <Wrapper>
                    {item.text
                        .split(/[\u202F\u00A0]/)
                        .join(' ')
                        .split(' ')
                        .map((word) =>
                            word &&
                            (word.includes('https://') ||
                                word.includes('www.')) ? (
                                <span>
                                    {item.textStyle !== 'strong' &&
                                    item.textStyle !== 'heading' &&
                                    item.textStyle !== 'em' ? (
                                        <NormalSpan>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={word}
                                            >{`${word} `}</a>
                                        </NormalSpan>
                                    ) : item.textStyle === 'strong' ? (
                                        <StrongSpan>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={word}
                                            >{`${word} `}</a>
                                        </StrongSpan>
                                    ) : item.textStyle === 'heading' ? (
                                        <HeaderSpan>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={word}
                                            >{`${word} `}</a>
                                        </HeaderSpan>
                                    ) : (
                                        item.textStyle === 'em' && (
                                            <span
                                                style={{
                                                    fontStyle: 'italic',
                                                }}
                                            >
                                                <NormalSpan>
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={word}
                                                    >{`${word} `}</a>
                                                </NormalSpan>
                                            </span>
                                        )
                                    )}
                                </span>
                            ) : (
                                <span>
                                    {item.textStyle !== 'strong' &&
                                    item.textStyle !== 'heading' &&
                                    item.textStyle !== 'em' ? (
                                        <NormalSpan>{`${word} `}</NormalSpan>
                                    ) : item.textStyle === 'strong' ? (
                                        <StrongSpan>{`${word} `}</StrongSpan>
                                    ) : item.textStyle === 'heading' ? (
                                        <HeaderSpan>{word}</HeaderSpan>
                                    ) : (
                                        item.textStyle === 'em' && (
                                            <span
                                                style={{
                                                    fontStyle: 'italic',
                                                }}
                                            >
                                                <NormalSpan>{`${word} `}</NormalSpan>
                                            </span>
                                        )
                                    )}
                                </span>
                            )
                        )}
                </Wrapper>
            )
        }
    }

    return <>{renderRichContentElem()}</>
}

export default ApexBodyRichContentTextItemMobile
