import styled from 'styled-components'
import ButtonAtom, {
    IButtonStyledProps,
} from '../../../atoms/Button/ButtonAtom'
import { device } from '../../../templates/displays/devices'
import LoaderElasticThreeDots from '../../../atoms/loader/loaderElasticThreeDots'
import posthog from 'posthog-js'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type IStyledProps = {
    removePadding?: boolean
    customPadding?: string
}
const FormBtnsBottomRow = styled.div<IStyledProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    padding: ${(props: IStyledProps) =>
        props.removePadding === true
            ? '0px'
            : props.customPadding
            ? `${props.customPadding}`
            : '24px 24px'};

    @media ${device.mobile_and_ipad} {
        padding: 0px;
    }
`

const SideDivider = styled.div`
    padding-left: 16px;
`

export type IFormBtnRowProps = {
    cancel: any

    submit?: any
    submitIcon?: string

    redAction?: any
    redActionCopy?: string
    removeCancel?: boolean
    removeMain?: boolean
    disabled?: boolean
    firstText?: string
    goBackText?: string
    goBackIcon?: string
    confirmationtext: string
    dataCyIdUpdate?: string
    dataCyIdCancel?: string
    height?: string
    hasGoBack?: boolean
    onGoback?: any
    hasClear?: boolean
    onClear?: any
    removePadding?: boolean
    customPadding?: string
    removeLine?: boolean
    customSidePadding?: string
    isSubmitLoading?: boolean
    hrefText?: string
    hasHrefLink?: string
    hrefPosthogMessage?: string
    width?: string
    back_btn_width?: string
    secondCTAText?: string
    onSecondCTAClick?: any
    secondCTAIcon?: string
    removeFixed?: boolean
    customBg?: string
}

const PaddingSecondCTA = styled.div`
    padding-right: 16px;
    @media (max-width: 445px) {
        padding-right: 8px;
    }
`
const HrefBtn = styled.div<IButtonStyledProps>`
    margin-right: 16px;
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    transition: all 200ms;
    :hover {
        ${(props) =>
            props.disabled
                ? ''
                : `outline:  1px solid ${colours[props.uiTheme].primary};`}
    }
    background-color: transparent;
    border-radius: 5px;
    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};
    font-family: Lato;
    font-size: 14px;
    text-transform: capitalize;

    @media ${device.beyond_ipad_mobile} {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
    }

    padding-left: 12px;
    padding-right: 12px;
`

const ButtonsStandardBottomRowMobile = (props: IFormBtnRowProps) => {
    const { theme } = useThemes()
    return (
        <FormBtnsBottomRow
            removePadding={props.removePadding}
            customPadding={props.customPadding}
        >
            {!props.removeCancel && !props.hasHrefLink && (
                <>
                    <ButtonAtom
                        onClick={(e: any) => {
                            e.preventDefault()
                            setTimeout(function () {
                                props.cancel()
                            }, 50)
                        }}
                        height={props.height}
                        theme="modal-action-button-secondary"
                        dataCyId={props.dataCyIdCancel && props.dataCyIdCancel}
                    >
                        {props.firstText ?? 'Close'}
                    </ButtonAtom>
                    <SideDivider />
                </>
            )}

            {props.hasHrefLink && (
                <a
                    href={props.hasHrefLink}
                    target="_blank"
                    onClick={() => {
                        if (props.hrefPosthogMessage) {
                            posthog.capture(props.hrefPosthogMessage)
                        }
                    }}
                >
                    <HrefBtn
                        style={{
                            height: props.height ?? '40px',
                            width: props.width ?? 'auto',
                        }}
                        uiTheme={theme}
                    >
                        {props.hrefText ?? 'Learn more'}
                    </HrefBtn>
                </a>
            )}

            {!props.removeMain &&
                props.onSecondCTAClick &&
                props.secondCTAText && (
                    <PaddingSecondCTA>
                        <ButtonAtom
                            height={props.height}
                            onClick={props.onSecondCTAClick}
                            width={props.width}
                            theme="modal-action-button-primary"
                            disabled={props.disabled ? props.disabled : false}
                            dataCyId={
                                props.dataCyIdUpdate ? props.dataCyIdUpdate : ''
                            }
                            icon={props.secondCTAIcon ?? undefined}
                        >
                            {props.isSubmitLoading ? (
                                <div style={{ width: 40 }}>
                                    <LoaderElasticThreeDots />
                                </div>
                            ) : (
                                props.secondCTAText ?? 'Save'
                            )}
                        </ButtonAtom>
                    </PaddingSecondCTA>
                )}

            {!props.removeMain && props.submit && (
                <ButtonAtom
                    height={props.height}
                    onClick={props.submit}
                    width={props.width}
                    theme="modal-action-button-primary"
                    disabled={props.disabled ? props.disabled : false}
                    dataCyId={props.dataCyIdUpdate ? props.dataCyIdUpdate : ''}
                    icon={props.submitIcon ?? undefined}
                >
                    {props.isSubmitLoading ? (
                        <div style={{ width: 40 }}>
                            <LoaderElasticThreeDots />
                        </div>
                    ) : (
                        props.confirmationtext ?? 'Save'
                    )}
                </ButtonAtom>
            )}

            {props.redAction && props.redActionCopy ? (
                <>
                    <SideDivider />
                    <ButtonAtom
                        height={props.height}
                        width={props.width}
                        onClick={props.redAction}
                        theme="modal-action-button-red"
                        disabled={props.disabled ? props.disabled : false}
                        dataCyId={
                            props.dataCyIdUpdate ? props.dataCyIdUpdate : ''
                        }
                    >
                        {props.redActionCopy}
                    </ButtonAtom>
                </>
            ) : null}
        </FormBtnsBottomRow>
    )
}

export default ButtonsStandardBottomRowMobile
