import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import BlueTextHeader from '../../atoms/typography/blueTextHeader'
import CarDeletionListItemDesktop from '../../atoms/list/carDeletionListItemDesktop'
import CarDeletionFormDesktop from '../../organisms/editForms/carDeletionForm/carDeletionFormDesktop'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CarDeletionListItemMobile from '../../atoms/list/carDeletionListItemMobile'
import CarDeletionFormMobile from '../../organisms/editForms/carDeletionForm/carDeletionFormMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { carActions } from '../../../redux/entities/cars/reducer'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        background-color: var(--bg-color, #fff);
        padding-left: 50px;
        padding-right: 50px;
        min-height: 100vh;
    }

    @media ${device.mobile} {
        padding-bottom: 60px;
    }
`
const HeightTop = styled.section`
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;

    @media ${device.beyond_ipad_mobile} {
        position: relative;
        border-radius: 5px;
        background-color: var(--primary_08);
        height: 410px;
    }
    @media ${device.smallest_laptops} {
        position: relative;
        height: 360px;
    }
    @media ${device.mobile} {
        height: 230px;
        width: 100vw;
        align-items: center;
    }
    @media ${device.ipad} {
        height: 250px;
    }
`

const ImageStyle = styled.div`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    transition: opacity 400ms;

    @media ${device.mobile} {
        height: 160px;
        width: 90vw;
        border-radius: 5px;
    }
`

const MobileColumnBody = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    padding-right: 7vw;
    padding-left: 7vw;
`

const CarDeletionConfirmation: React.FC = () => {
    const { carid } = useParams<{ carid: string }>()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()

    const carsData = useAppSelector((state) => state.entities.carsData.cars)

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(`/car/${carid}/deletion`)
        )
        dispatch(carActions.setCurrentCar(`${carid}`))
        dispatch(carActions.getCarDataByIdRequest(`${carid}`))
    }, [carid])

    const deletionItemsCheckListText = [
        'all images and other assets uploaded on the platform',
        'all the car’s related Tasks',
        'all information provided such as key moments and facts, description, and technical details ',
    ]

    const determineCancelPath = () => {
        if (location.state?.prevPath.includes('previously-owned')) {
            navigate('/garage/previously-owned')
        } else {
            navigate(`/car/${carid}`)
        }
    }

    const current = carid && carsData[`${carid}`]

    return (
        <Wrapper>
            {current !== null && current !== undefined && (
                <CenteredPageWrapper>
                    <DesktopDisplayOnly>
                        {current &&
                            current?.banner_url !== undefined &&
                            current.banner_url.url.length > 1 && (
                                <HeightTop>
                                    <ImageStyle
                                        role="img"
                                        aria-label="car overview banner"
                                        style={{
                                            backgroundImage: `url(${current?.banner_url.url})`,
                                        }}
                                    />
                                </HeightTop>
                            )}
                        <div style={{ paddingTop: '50px' }} />
                        <BlueTextHeader>
                            Deleting the
                            {current && current?.title
                                ? ' ' + current?.title + ' '
                                : ' car '}
                            will also automatically delete...
                        </BlueTextHeader>

                        <div style={{ paddingTop: '50px' }} />

                        {deletionItemsCheckListText.map(
                            (item: string, index: number) => {
                                return (
                                    <CarDeletionListItemDesktop key={index}>
                                        {item}
                                    </CarDeletionListItemDesktop>
                                )
                            }
                        )}

                        <div style={{ paddingTop: '50px' }} />

                        <CarDeletionFormDesktop
                            carid={`${carid}`}
                            cancel={determineCancelPath}
                            submitFunc={(carid: string) => {
                                dispatch(carActions.deleteCarRequest(carid))
                            }}
                            car_title={
                                current && current.title
                                    ? current.title
                                    : 'Untitled Car'
                            }
                        />
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        {current &&
                            current.banner_url !== undefined &&
                            current.banner_url.url.length > 1 && (
                                <HeightTop>
                                    <ImageStyle
                                        role="img"
                                        aria-label="car overview banner"
                                        style={{
                                            backgroundImage: `url(${current.banner_url.url})`,
                                        }}
                                    />
                                </HeightTop>
                            )}
                        <MobileColumnBody>
                            {current && current.banner_url === undefined && (
                                <div style={{ paddingTop: '50px' }} />
                            )}

                            <BlueTextHeader>
                                Deleting the
                                {current && current.title
                                    ? ' ' + current.title + ' '
                                    : ' car '}
                                will also automatically delete...
                            </BlueTextHeader>

                            <div style={{ paddingTop: '30px' }} />

                            {deletionItemsCheckListText.map(
                                (item: string, index: number) => {
                                    return (
                                        <CarDeletionListItemMobile key={index}>
                                            {item}
                                        </CarDeletionListItemMobile>
                                    )
                                }
                            )}

                            <div style={{ paddingTop: '30px' }} />

                            <CarDeletionFormMobile
                                carid={`${carid}`}
                                cancel={determineCancelPath}
                                submitFunc={(carid: string) => {
                                    dispatch(carActions.deleteCarRequest(carid))
                                }}
                                car_title={
                                    current && current.title
                                        ? current.title
                                        : 'Untitled Car'
                                }
                            />
                        </MobileColumnBody>
                    </IpadAndMobileDisplay>
                </CenteredPageWrapper>
            )}
        </Wrapper>
    )
}

export default CarDeletionConfirmation
