import styled from 'styled-components'
import { IAnyObject } from '../../../../../redux/insuranceQuoteApplication/reducer'
import { useAppSelector } from '../../../../../redux/store/hooks'
import BinIcon from '../../../../atoms/icons/components/binIcon'
import { SelfReplicaMapKeyFields } from './keyFieldsMap'
import { IInsuranceApplicationSectionId } from '../../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'

type Props = {
    onClickRemove: (index: number) => any

    uiFieldsObj: any
    setEntityArr: (p: IAnyObject[], fieldID: string) => any
    titleTxt: string

    entitiesArrDraft: IAnyObject[]
    mobileSearchPickerSectionURL?: string

    entitiesErrNormalised: any
    setError: any
    entityTypeID: string
    isMobile?: boolean
    sectionID?: IInsuranceApplicationSectionId
}

const Btn = styled.button`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 150ms;
`

const Line = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-top: 16px;
    margin-bottom: 24px;
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
`

const Title = styled.div`
    font-family: Lato-bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 5e-5em;
    color: var(--text-darker, #616161);
`

export const SelfReplicaMapEntities = (props: Props) => {
    let {
        titleTxt,
        entitiesArrDraft,
        onClickRemove,
        entitiesErrNormalised,
        isMobile,
        sectionID,
    } = props

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    return (
        <>
            {entitiesArrDraft.length > 0 &&
                entitiesArrDraft.map((entityObj: IAnyObject, index: number) => {
                    // inside of 1 element, like disability
                    // need to map through its fields, hence why SelfReplicaMapKeyFields:

                    return (
                        <div
                            style={{
                                position: 'relative',
                                zIndex: 200 - index,
                            }}
                            key={`self_replica_${index}_${
                                isMobile ? 'mobile' : 'desktop'
                            }`}
                        >
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        paddingTop:
                                            index !== 0 ? '16px' : '0px',
                                    }}
                                    key={`${index}-selfreplica`}
                                >
                                    <Title>
                                        {titleTxt}
                                        {' #'}
                                        {index + 1}
                                    </Title>
                                    {application?.status === 'DRAFT' && (
                                        <Btn
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                onClickRemove(index)
                                            }}
                                        >
                                            <BinIcon color="var(--text-strong, #1a1a1a)" />
                                            <Title
                                                style={{
                                                    color: 'var(--text-strong, #1a1a1a)',
                                                }}
                                            >
                                                {' '}
                                                Remove
                                            </Title>
                                        </Btn>
                                    )}
                                </div>
                                <Line />
                            </>

                            <SelfReplicaMapKeyFields
                                {...props}
                                entityIndex={index}
                                entityObj={entityObj}
                                entitiesErrNormalised={entitiesErrNormalised}
                                sectionID={sectionID}
                            />
                        </div>
                    )
                })}
        </>
    )
}
