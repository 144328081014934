import posthog from 'posthog-js'
import { call, fork, put, select, take } from 'redux-saga/effects'
import { convertToCarState } from '../../../conversions/entities/conversionFromAPI'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IRootState } from '../../../store'
import {
    ICarUpdateFieldsPayload,
    ICarPayload,
} from '../../../services/typedefinitions/apiPayloads'
import { IApiUpdateCar_args } from '../../../services/types'
import {
    IUpdateCarGeneralPayloadReq,
    ICarsObject,
    ICar,
    IReduxError,
} from '../types'
import { carActions } from '../reducer'

const getCarsDataState = (state: IRootState) => state.entities.carsData.cars

export function* updateCarGeneralSaga(payload: IUpdateCarGeneralPayloadReq) {
    let elements_to_send: ICarUpdateFieldsPayload = {}

    for (const element of payload.dataListForm) {
        if (element.answer !== undefined) {
            switch (element.id) {
                case 'title':
                    elements_to_send.title = element.answer
                    // code block
                    break
                case 'short_description':
                    if (element.answer !== undefined) {
                        if (element.answer.length > 2) {
                            elements_to_send.short_description = element.answer
                        } else {
                            elements_to_send.short_description = ' '
                        }
                    }
                    break
                case 'description':
                    elements_to_send.description = element.answer
                    break
                default:
                    return
            }
        }
    }

    function isEmpty(obj: any) {
        return Object.keys(obj).length === 0
    }

    if (!isEmpty(elements_to_send)) {
        try {
            let api_payload: IApiUpdateCar_args = {
                car_id: payload.car_id,
                dataToUpdate: elements_to_send,
            }
            let carReceived: ICarPayload =
                yield api.entities.updateCar.updateCar(api_payload)

            let convertedCar: ICarsObject = convertToCarState(carReceived)

            // adding car props that are not in the BE response

            const carsDataState: ICarsObject = yield select(getCarsDataState)

            const currentCarState: ICar | undefined | null =
                carsDataState[payload.car_id]

            if (currentCarState) {
                convertedCar[carReceived.uid] = {
                    ...currentCarState,
                    ...convertedCar[carReceived.uid],
                }
            }

            // update car in Redux

            yield put(carActions.writeCarDataGeneralSuccess(convertedCar))

            posthog.capture('UPDATE CAR GENERAL INFORMATION')
        } catch (e: any) {
            if (e.status === 401) {
                let p: IUnauthHandlerPayload = {
                    functionToRepeat: updateCarGeneralSaga,
                    payload: payload,
                }
                yield call(unauthHandlerSaga, p)
            } else {
                let typedError: IReduxError = ConvertToReduxError(e)
                yield put(carActions.writeCarDataGeneralError(typedError))
            }
        }
    }
}

function* watcherUpdateCarGeneralWatcher() {
    while (true) {
        const { payload } = yield take(carActions.writeCarDataGeneralRequest)
        yield call(updateCarGeneralSaga, payload)
    }
}

const update_car_general: any[] = [fork(watcherUpdateCarGeneralWatcher)]

export default update_car_general
