import { Modal } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import TextArea from '../../../atoms/textarea/textarea'

import './styles.css'

type Props = {
    activeForm: { id: string; name: string; value?: string }
    onClose: () => void
    onSubmit: (t: string) => any
    isDisabled?: boolean
    okText?: string
}

const TxtTopBanner = styled.div`
    font-size: 24px;
    color: #1a1a1a;
    font-family: Lato;
    height: 60px;
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: flex-start;
    border: none;
`

const ClearBtn = styled.button`
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    left: 15px;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    transition: all 200ms;
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    color: #df6f6f;
    border: 1px solid #df6f6f;
    font-family: Lato;
    font-size: 16px;
    text-transform: capitalize;
    padding-left: 16px;
    padding-right: 16px;
    height: 40px;
`
const TextAreaWidth = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    font-size: 16px;
`

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 216px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fafafa;
`
export default function MessageModalDesktop(props: Props) {
    let { activeForm, onClose, onSubmit, okText } = props

    const [message, setMessage] = useState<string>('')

    useEffect(() => {
        setMessage(activeForm.value ? activeForm.value : '')
    }, [activeForm])

    return (
        <>
            <Modal
                title={
                    activeForm && <TxtTopBanner>{activeForm.name}</TxtTopBanner>
                }
                centered
                open={activeForm ? true : false}
                onOk={() => {
                    onSubmit(message)
                }}
                onCancel={() => {
                    onClose()
                }}
                okText={okText ?? 'Save'}
                cancelText="Return"
                style={{
                    borderRadius: '0px',
                    minWidth: '50vw',
                    fontSize: '16px',
                }}
                okButtonProps={{
                    disabled: props.isDisabled,
                    type: 'primary',
                    style: {
                        minWidth: '70px',
                        marginLeft: '12px',
                        marginTop: '8px',
                        marginBlock: '8px',
                        backgroundColor: '#5EC3CA',
                        border: 'none',
                        borderRadius: ' 5px',
                        height: '40px',
                    },
                }}
                cancelButtonProps={{
                    style: {
                        color: '#a0a0a0',
                        marginTop: '8px',
                        marginBlock: '8px',
                        borderRadius: ' 5px',
                        height: '40px',
                    },
                }}
                closable={false}
            >
                <Wrapper>
                    <TextAreaWidth>
                        <TextArea
                            value={message}
                            handleChange={(e: any) => {
                                setMessage(e.target.value)
                            }}
                            name=""
                            placeholder="Write here..."
                        />
                    </TextAreaWidth>
                </Wrapper>
                <ClearBtn
                    onClick={() => {
                        setMessage('')
                    }}
                >
                    clear
                </ClearBtn>
            </Modal>
        </>
    )
}
