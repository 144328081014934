import { IApexWeeklyQuoteState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import LoaderBackground from '../../../../atoms/loader/loaderBackground'
import { device } from '../../../../templates/displays/devices'

const SubColumnRight1Desktop = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    justify-self: flex-start;

    flex: 3;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    min-height: 630px;
    padding-bottom: 70px;

    @media ${device.smallest_laptops} {
        min-height: 600px;
    }

    background: var(--modal-side-sheet-pop-over-bg, #fff);

    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding-bottom: 30px;

    @media ${device.large_desktop} {
        min-height: 56vh;
        padding-bottom: 60px;
    }

    @media ${device.ipad} {
        align-self: flex-end;
    }
`

const SectionTitle = styled.div`
    text-align: left;
    align-self: flex-start;
    font-family: Lato-Light;
    color: var(--primary, #5ec3ca);
    text-transform: uppercase;

    font-size: 20px;
    padding-top: 56px;
    padding-bottom: 54px;
    padding-left: 50px;

    @media ${device.smallest_laptops} {
        font-size: 18px;
    }

    @media ${device.large_desktop} {
        font-size: 21px;
        padding-bottom: 34px;
    }
`

const Quote = styled.div`
    font-family: Lato;
    font-size: 17px;

    padding-left: 20px;
    padding-right: 20px;

    color: var(--text-muted, #b3b3b3);
    line-height: 2;
`

const QuoteWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
    justify-self: flex-start;

    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 20px;

    @media ${device.large_desktop} {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 40px;
    }
`

const QuoteAccentStart = styled.div`
    font-size: 38px;
    @media ${device.large_desktop} {
        font-size: 38px;
    }

    font-family: Lato-Bold;
    line-height: 37px;
    color: var(--text-strong, #1a1a1a);
`

const QuoteAccentEnd = styled.div`
    font-family: Lato-Bold;
    font-size: 38px;

    color: var(--text-strong, #1a1a1a);

    align-self: flex-end !important;
`

const Name = styled.div`
    font-family: Lato-Light;
    font-size: 17px;

    @media ${device.large_desktop} {
        font-size: 19px;
    }

    color: var(--text-strong, #1a1a1a);
    padding-left: 20px;
    padding-right: 20px;
`

const Title = styled.div`
    font-family: Lato-Light;
    font-size: 17px;

    @media ${device.large_desktop} {
        font-size: 18px;
    }

    color: var(--primary, #5ec3ca);
    padding-left: 20px;
    padding-right: 20px;
`

const SmallDash = styled.div`
    background-color: var(--primary, #5ec3ca);
    height: 1px;
    width: 50px;
    margin-top: 15px;
`

type Props = {
    quote: IApexWeeklyQuoteState | undefined
}

const WeeklyApexQuoteDesktop: React.FC<Props> = ({ quote }) => {
    return (
        <SubColumnRight1Desktop>
            <SectionTitle>
                Quote of the week <SmallDash />
            </SectionTitle>

            {!quote && <LoaderBackground height={'300px'} width={'100%'} />}

            {quote && (
                <QuoteWrapper>
                    <QuoteAccentStart>"</QuoteAccentStart>
                    <Quote>{quote.quote}</Quote>
                    <QuoteAccentEnd>"</QuoteAccentEnd>

                    <Name>{quote.name}</Name>
                    <Title>{quote.title}</Title>
                </QuoteWrapper>
            )}
        </SubColumnRight1Desktop>
    )
}

export default WeeklyApexQuoteDesktop
