import styled from 'styled-components'
import { insurance_wizard_sections } from '../../../../providers/insurance/data'
import { IInsuranceQuoteApplication_Car } from '../../../../redux/insuranceQuoteApplication/reducer'
import { IRootState } from '../../../../redux/store'
import { useAppSelector } from '../../../../redux/store/hooks'
import { generatensuranceSectionIcon } from '../../../molecules/cards/insurance/insuranceApplicationCardDesktop'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { device } from '../../../templates/displays/devices'

type Props = {
    section_id: IInsuranceApplicationSectionId | string
    subsection_id?: string | null | undefined
    driver_id?: string | null | undefined
    car_id?: string | null | undefined
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 16px;

    flex-direction: row;

    @media ${device.beyond_ipad_mobile} {
        width: 566px;
    }
`

const Text1 = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const Text2 = styled.div`
    color: var(--text-muted);
    font-family: Lato;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.001px;
`

const Col = styled.div`
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
`

const InsuranceWizardHeader = (props: Props) => {
    let { section_id, subsection_id, driver_id, car_id } = props

    let application = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication?.submitted_data
    )

    const generateTitle = (car: IInsuranceQuoteApplication_Car | undefined) => {
        if (
            car &&
            (car.tech_info?.manufacture_year ||
                car.tech_info?.make?.name ||
                car.tech_info?.model?.name)
        ) {
            return `${
                car.tech_info?.manufacture_year
                    ? `${car.tech_info.manufacture_year} `
                    : ''
            }${car.tech_info?.make?.name ? `${car.tech_info.make.name} ` : ''}${
                car.tech_info?.model?.name ? car.tech_info.model.name : ''
            }`
        } else {
            return 'Untitled car'
        }
    }

    let section_entity_name =
        driver_id && application.other_drivers[`${driver_id}`]
            ? `${
                  application.other_drivers[`${driver_id}`].basic_details
                      ?.given_name ?? ''
              } ${
                  application.other_drivers[`${driver_id}`].basic_details
                      ?.family_name ?? ''
              }`
            : car_id
            ? generateTitle(application.vehicles[car_id])
            : null

    let section_title = insurance_wizard_sections[`${section_id}`]?.name
    let section_subtitle =
        insurance_wizard_sections[`${section_id}`]?.subsections &&
        insurance_wizard_sections[`${section_id}`]?.subsections[
            `${subsection_id}`
        ]?.name
            ? insurance_wizard_sections[`${section_id}`]?.subsections[
                  `${subsection_id}`
              ]?.name
            : 'Your Application'

    return (
        <Wrapper>
            {
                // @ts-ignore
                generatensuranceSectionIcon(section_id)
            }

            <Col>
                <Text1>{section_entity_name ?? section_title}</Text1>
                <Text2>
                    {section_entity_name ? section_title : section_subtitle}
                </Text2>
            </Col>
        </Wrapper>
    )
}

export default InsuranceWizardHeader
