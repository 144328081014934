import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../../services'
import {
    convertToExternalCarState,
    convertToGalleryImagesState,
    convertToHighlightedFactsState,
    convertToTechnicalInformationState,
} from '../../../conversions/entities/conversionFromAPI'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertAPIEntryToTimelineItemSingle } from '../../../conversions/timeline/entryToTimelineItem'
import { ConvertAttachmentAPIToAttachmentState } from '../../../conversions/attachments'
import { convertEntryImagesAPIToGalleryImagesState } from '../../../conversions/timeline/entryImagesToStateImages'
import { ConvertSharePermissionIDsToState } from '../../../conversions/shares/ConvertSharePermissionIds'
import {
    IHighlightedFactsObject,
    ITechnicalInformationObject,
} from 'entityModels'
import {
    INormalisedAttachmentsByID,
    IAttachmentItem,
} from '../../../attachments/types'
import {
    ICarPayload,
    IEntryPayloadAPI,
} from '../../../services/typedefinitions/apiPayloads'
import {
    ITimelineItem,
    IGetAllTimelineItemsByCarIDPayloadSuccess,
} from '../../../timeline/types'
import { IGalleryImagesObject } from '../../galleries/types'
import {
    IExternalCarsObject,
    ISharingPermissionIDS,
    ICustomErrorData,
    IReduxError,
} from '../types'
import { galleriesActions } from '../../galleries/reducer'
import { highlightedFactsActions } from '../../highlighted_facts/reducer'
import { technicalInformationActions } from '../../technical_information/reducer'
import { attachmentActions } from '../../../attachments/reducer'
import { timelineActions } from '../../../timeline/reducer'
import { carActions } from '../reducer'

export function* getExternalCarDataApiPayloadById(carid: string) {
    try {
        const caritem: ICarPayload = yield call(
            api.entities.getData.getCarData_api,
            carid
        )

        let carObjects: IExternalCarsObject = {}

        let galleryImagesObject: IGalleryImagesObject = {}

        let highlightedFactsObject: IHighlightedFactsObject = {}

        let technicalInformationObject: ITechnicalInformationObject = {}

        // convert to car state payload

        let car: IExternalCarsObject = convertToExternalCarState(caritem)
        car[caritem.uid].has_limited_garage_info = false

        if (caritem.policy) {
            let permissions: ISharingPermissionIDS[] = []
            permissions = ConvertSharePermissionIDsToState(caritem.policy)
            car[caritem.uid].policy_statements = permissions
        }

        let gallery_images_object: IGalleryImagesObject =
            convertToGalleryImagesState(caritem)

        let highlighted_facts_object: IHighlightedFactsObject =
            convertToHighlightedFactsState(caritem)

        let technical_information_object: ITechnicalInformationObject =
            convertToTechnicalInformationState(caritem)

        Object.assign(galleryImagesObject, gallery_images_object)

        Object.assign(carObjects, car)

        Object.assign(highlightedFactsObject, highlighted_facts_object)

        Object.assign(technicalInformationObject, technical_information_object)

        const results: IExternalCarsObject = carObjects

        yield put(galleriesActions.setGalleryImagesSuccess(galleryImagesObject))
        yield put(
            highlightedFactsActions.setHighlightedFactsSuccess(
                highlightedFactsObject
            )
        )
        yield put(
            technicalInformationActions.setTechnicalInformationSuccess(
                technicalInformationObject
            )
        )

        // timeline

        let entries_from_api: IEntryPayloadAPI[] | undefined = caritem.car_entry
        let all_entries_by_car_id: ITimelineItem[] = []

        if (entries_from_api) {
            for (const entry of entries_from_api) {
                let converted_entry = ConvertAPIEntryToTimelineItemSingle(entry)
                all_entries_by_car_id.push(converted_entry)
                if (entry.attachments) {
                    if (entry.attachments.length > 0) {
                        let obj: INormalisedAttachmentsByID = {}

                        for (const element of entry.attachments) {
                            let i: IAttachmentItem =
                                ConvertAttachmentAPIToAttachmentState(element)

                            obj = { ...obj, [i.id]: i }
                        }

                        yield put(attachmentActions.addAttachmentsSuccess(obj))
                    }
                }
                if (entry.images) {
                    if (entry.images.length > 0) {
                        let galleryImagesObject =
                            convertEntryImagesAPIToGalleryImagesState(entry)

                        yield put(
                            galleriesActions.setGalleryImagesSuccess(
                                galleryImagesObject
                            )
                        )
                    }
                }
                if (entry.costs) {
                    if (entry.costs.length > 0) {
                        for (const cost of entry.costs) {
                            if (
                                cost.attachments &&
                                cost.attachments.length > 0
                            ) {
                                let length: number = cost.attachments.length - 1
                                if (cost.attachments[length].uid) {
                                    yield put(
                                        attachmentActions.addAttachmentsSuccess(
                                            {
                                                [cost.attachments[length].uid]:
                                                    ConvertAttachmentAPIToAttachmentState(
                                                        cost.attachments[length]
                                                    ),
                                            }
                                        )
                                    )
                                }
                            }
                        }
                    }
                }
            }
            let res: IGetAllTimelineItemsByCarIDPayloadSuccess = {
                car_id: caritem.uid,
                entries: all_entries_by_car_id,
            }
            yield put(timelineActions.getAllTimelineItemsByCarIDSuccess(res))
        }

        yield put(carActions.getExternalCarsSharedWithYouSuccess(results))
        yield put(carActions.setCurrentExternalCarSuccess(carid))
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getExternalCarDataApiPayloadById,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else if (error.status === 403) {
            let customErrorData: ICustomErrorData = {
                custom_message: `You don't have permission to view this car`,
                custom_user_action_text: 'Go back',
                custom_redirect_path: '/sharing/with-you',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'shared_with_you_car',
                true
            )
            yield put(carActions.getCarDataByIdError(customError))
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `This car doesn not exist or you don't have permissions to access details of this page.`,
                custom_user_action_text: 'Go back',
                custom_redirect_path: '/sharing/with-you',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'shared_with_you_car',
                false
            )
            yield put(carActions.getCarDataByIdError(customError))
        }
    }
}

function* watcherGetExternalCarDataById() {
    while (true) {
        const { payload } = yield take(
            carActions.getExternalCarsSharedWithYouRequest
        )

        yield call(getExternalCarDataApiPayloadById, payload)
    }
}

const get_external_car_shared_with_you_sagas: any[] = [
    fork(watcherGetExternalCarDataById),
]

export default get_external_car_shared_with_you_sagas
