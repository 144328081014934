import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../../services'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IRootState } from '../../../store'
import { IApiDeleteCarGalleryImage_args } from '../../../services/types'
import { carActions } from '../../cars/reducer'
import { ICarsObject, ICar, IReduxError } from '../../cars/types'
import { galleriesActions } from '../reducer'

type Iids = {
    carid: string
    imageid: string
}

const get_cars_from_state = (state: IRootState) => state.entities.carsData.cars

function* deleteCarGalleryImageApi(payload: Iids) {
    let p: IApiDeleteCarGalleryImage_args = {
        carid: payload.carid,
        imageid: payload.imageid,
    }
    try {
        yield call(api.entities.mutateGallery.deleteCarGalleryImage, p)

        yield put(
            galleriesActions.deleteCarGalleryImageSuccess(payload.imageid)
        )

        yield put(
            carActions.deleteFromCarImagesIdsArray({
                carid: payload.carid,
                imageid: payload.imageid,
            })
        )

        let carsInState: ICarsObject = yield select(get_cars_from_state)
        let currentCar: ICar = carsInState && carsInState[payload.carid]

        if (
            currentCar.gallery &&
            currentCar.gallery.cover &&
            currentCar.gallery.cover === payload.imageid
        ) {
            let new_cover_id =
                currentCar.gallery &&
                currentCar.gallery.images &&
                currentCar.gallery.images[0]
                    ? currentCar.gallery.images[0]
                    : undefined
            yield put(
                carActions.updateCoverImgId({
                    carid: payload.carid,
                    new_cover_id: new_cover_id,
                })
            )
        }
        // Capture event
        posthog.capture('DELETE CAR IMAGE')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: deleteCarGalleryImageApi,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            if (error.status === 401) {
                let p: IUnauthHandlerPayload = {
                    functionToRepeat: deleteCarGalleryImageApi,
                    payload: payload,
                }
                yield call(unauthHandlerSaga, p)
            } else {
                let typedError: IReduxError = ConvertToReduxError(error)
                yield put(
                    galleriesActions.deleteCarGalleryImageError(typedError)
                )
                return
            }
        }
    }
}

function* watcherDeleteCarGalleryImage() {
    while (true) {
        const { payload } = yield take(
            galleriesActions.deleteCarGalleryImageRequest
        )
        yield call(deleteCarGalleryImageApi, payload)
    }
}

const delete_sagas: any[] = [fork(watcherDeleteCarGalleryImage)]

export default delete_sagas
