import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ITutorialCheck = {
    isFirstSession?: undefined | boolean
    tutorialsCheckByPageId: ITutorialsCheckByPageId
}

export type ITutorialsCheckByPageId = {
    tasks: boolean
    garage: boolean
    car: boolean
}

const initialState: ITutorialCheck = {
    isFirstSession: false,
    tutorialsCheckByPageId: {
        tasks: false,
        garage: false,
        car: false,
    },
}

const tutorialsCheckSlice = createSlice({
    name: 'tutorialsCheck',
    initialState,
    reducers: {
        setIsFirstSessionSuccess: (state) => {
            state.isFirstSession = true
        },
        manageUserTutorialByIdCheck: (state, action: PayloadAction<string>) => {
            // @ts-ignore
            state.tutorialsCheckByPageId[action.payload] = true
        },
    },
})

export const tutorialsActions = tutorialsCheckSlice.actions

export default tutorialsCheckSlice.reducer

export type TutorialsCheckState = ReturnType<typeof tutorialsCheckSlice.reducer>
