import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

type StyledProps = {
    isMainHeaderVisible?: boolean
}

const HeaderWrapper = styled.div<StyledProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--slider-banner-pop-over-bg, #fafafa);
    height: 50px;
    -webkit-box-shadow: ${(props) =>
        props.isMainHeaderVisible
            ? '0px 2px 9px -2px var(--box-shadow,rgba(0, 0, 0, 0.08))'
            : 'none'};
    -moz-box-shadow: ${(props) =>
        props.isMainHeaderVisible
            ? '0px 2px 9px -2px var(--box-shadow,rgba(0, 0, 0, 0.08))'
            : 'none'};
    box-shadow: ${(props) =>
        props.isMainHeaderVisible
            ? '0px 2px 9px -2px var(--box-shadow,rgba(0, 0, 0, 0.08))'
            : 'none'};
    padding-left: 20px;
    padding-right: 25px;
    position: fixed;
    top: ${(props) => (props.isMainHeaderVisible ? '0px' : '-52px')};
    transition: all 0.2s;
    left: 0px;
    right: 0px;
    width: 100vw;
    z-index: 3;
`

const Title = styled.div`
    font-family: Lato-Light;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 200;
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    margin-top: 3px;
    letter-spacing: 1.05px;
`

interface Props {
    text: string
    customStyle: React.CSSProperties | undefined
}

const SlidingUpOnScrollBanner: React.FC<Props> = ({ text, customStyle }) => {
    const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset)
    const [isMainHeaderVisible, setIsMainHeaderVisible] = useState(true)

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset

            const checkVisibility = () => {
                if (currentScrollPos < 10) {
                    return true
                }

                if (prevScrollpos > currentScrollPos) {
                    return true
                } else {
                    return false
                }
            }

            setPrevScrollpos(currentScrollPos)
            setIsMainHeaderVisible(checkVisibility())
        }

        window.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [prevScrollpos])

    return (
        <IpadAndMobileDisplay>
            <HeaderWrapper
                isMainHeaderVisible={isMainHeaderVisible}
                style={customStyle}
            >
                <Title>{text}</Title>
            </HeaderWrapper>
        </IpadAndMobileDisplay>
    )
}

export default SlidingUpOnScrollBanner
