import styled from 'styled-components'
import chevron from '../../../../public/assets/navigation/chevron_to_left.svg'
import close_cross_grey from '../../../../public/assets/icons/close_cross_grey.svg'
import { motion } from 'framer-motion'
import Icon from '../../../atoms/icons'
import {
    IPublishValidationObject,
    IValidation_subObject,
} from '../../../templates/bars/showroomBars/bottomBars/carShowroomEditBottomBar'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import { useEffect, useState } from 'react'
import Expander from '../../../atoms/expander/expander'
import { Tooltip } from 'antd'
import React from 'react'
import { ICar } from '../../../../redux/entities/cars/types'

const Container = styled.div`
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 40px;
    width: 442px;
    transition: all 100ms ease-in-out;
    box-shadow: 0px 4px 24px var(--box-shadow, rgba(0, 0, 0, 0.08));
    @media (max-height: 950px) {
        padding-top: 28px;
        padding-bottom: 16px;
    }

    height: 100%;
    overflow-y: scroll !important;
    max-height: 80vh;
`

const Title = styled.div`
    font-family: Lato;
    color: var(--text-strong, #121212);
    font-size: 24px;
    padding-bottom: 8px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const SubText = styled.div`
    font-family: Lato;
    color: var(--text-default, #666);
    font-size: 16px;
`

const ProgressBar = styled.div`
    height: 4px;
    background-color: var(--border-muted, #e5e5e5);
    width: 100%;
    box-sizing: border-box;
    border-radius: 100px;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    @media (max-height: 950px) {
        margin-top: 24px;
        margin-bottom: 24px;
    }
`

const ProgressBarFull = styled.div`
    height: 4px;
    background-color: var(--primary, #5ec3ca);
    border-radius: 100px;
`

const CheckContainer = styled(motion.div)<{ $isSubItem?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${(props) => (props.$isSubItem ? '34px' : '72px')} !important;
    min-height: ${(props) => (props.$isSubItem ? '34px' : '72px')} !important;

    cursor: pointer;

    @media (max-height: 950px) {
        height: ${(props) => (props.$isSubItem ? '34px' : '64px')} !important;
        min-height: ${(props) =>
            props.$isSubItem ? '34px' : '64px'} !important;
    }
`

const CheckText = styled.div<{ $isDisabled?: boolean }>`
    font-family: Lato;
    color: ${(props) =>
        props.$isDisabled === true
            ? 'var(--text-muted, #B3B3B3)'
            : 'var(--text-default, #666)'};
    font-size: 16px;
    padding-left: 16px;
`

const CheckSquare = styled.div<{ isIncomplete?: boolean }>`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${(props) =>
        props.isIncomplete
            ? 'var(--error, #df6f6f)'
            : 'var(--primary, #5ec3ca)'};
    @media (max-height: 950px) {
        width: 24px;
        height: 24px;
    }
`

const Chevron = styled.img`
    cursor: pointer;
    height: 14px;
    justify-self: end;
    transition: all 200ms;
    transform: rotate(180deg);
`

const MinSquare = styled.div`
    width: 42px;
    height: 32px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--off-bg-color, #fafafa);
    color: var(--text-muted, #b3b3b3);
    border-radius: 4px;
    :hover {
        background-color: var(--border-muted, #e5e5e5);
    }
`

interface Props {
    publishChecksObject: IPublishValidationObject
    currentCar: ICar | undefined
    currentCarId: string | undefined
    onClose: any
}

export type PropsValidationCheck = {
    isIncomplete?: boolean
    text: string
    withoutBottomBorder: boolean
    onClick: any
    isSubItem?: boolean
    hasSubItems?: boolean
    isOpen?: boolean
    hasNitems?: number
    minN?: number
    tooltipText?: string
    isTooltipRemoved?: boolean
    isDisabled?: boolean
}

const PublishCheckItem = (props: PropsValidationCheck) => {
    return (
        <CheckContainer
            style={{
                borderBottom: !props.withoutBottomBorder
                    ? '1px solid var(--border-muted, #e5e5e5)'
                    : 'none',
                cursor: props.isDisabled ? 'not-allowed' : 'pointer',
            }}
            $isSubItem={props.isSubItem}
            whileHover={{ scale: 1.01 }}
            onClick={() => {
                if (!props.isDisabled) {
                    props.onClick()
                }
            }}
            role="button"
            aria-disabled={props.isDisabled}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.isSubItem ? (
                    <div
                        style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            transform: props.isIncomplete
                                ? 'scale(0.85) translateY(-8px)'
                                : 'scale(1) translateY(-7px)',
                            height: '100% !important',
                        }}
                    >
                        <CustomAnimatedCheckboxField
                            checked={!props.isIncomplete}
                            onChange={() => {}}
                            isDisabled={props.isIncomplete || props.isDisabled}
                            extra_small
                        />
                    </div>
                ) : (
                    <CheckSquare isIncomplete={props.isIncomplete}>
                        <Icon
                            icon={
                                props.isIncomplete
                                    ? 'add_plus_white'
                                    : 'white_check'
                            }
                            width={props.isIncomplete ? 'auto' : '15px'}
                        />
                    </CheckSquare>
                )}
                <CheckText $isDisabled={props.isDisabled}>
                    {props.text}
                </CheckText>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.minN && props.isTooltipRemoved !== true && (
                    <Tooltip
                        title={props.tooltipText}
                        placement="bottom"
                        color={'var(--off-bg-color, #fafafa)'}
                        overlayInnerStyle={{
                            fontSize: 12,
                            color: 'var(--text-strong, #1a1a1a)',
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 14,
                        }}
                        overlayStyle={{
                            boxShadow: '2px 4px 7px rgba(0, 0, 0, 0.02)',
                            maxWidth: '200px',
                            borderRadius: 4,
                        }}
                    >
                        <MinSquare>
                            {`${props.hasNitems}/${props.minN}`}
                        </MinSquare>
                    </Tooltip>
                )}
                <Chevron
                    alt={'go_to_car'}
                    src={chevron}
                    style={{
                        filter: props.hasSubItems
                            ? 'none'
                            : 'invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg) brightness(63%) contrast(84%)',
                        transform:
                            props.hasSubItems && props.isOpen
                                ? 'rotate(-90deg)'
                                : 'rotate(180deg)',
                    }}
                />
            </div>
        </CheckContainer>
    )
}

export type ShowroomValidationEntries<IPublishValidationObject> = {
    [K in keyof IPublishValidationObject]: [K, IPublishValidationObject[K]]
}[keyof IPublishValidationObject][]

const PublishChecksManagerDesktop = (props: Props) => {
    const { currentCar, publishChecksObject, onClose } = props

    const [subItemsOpenState, setSubItemsOpenState] = useState<{
        [key: string]: boolean
    }>({})

    useEffect(() => {
        Object.entries(publishChecksObject).forEach((check) => {
            let checkName = check[0]
            let checkValues = check[1]
            if (checkValues.subItems) {
                setSubItemsOpenState({
                    ...subItemsOpenState,
                    [checkName]: false,
                })
            }
        })
    }, [publishChecksObject])

    let checksArray: ShowroomValidationEntries<IPublishValidationObject> =
        Object.entries(publishChecksObject)

    let activePropgressBarLength: number = checksArray.filter(
        (check) => check[1].check === true
    ).length

    return currentCar !== undefined ? (
        <Container>
            <Title>
                Not ready to publish{' '}
                <Chevron
                    alt={'dismiss'}
                    src={close_cross_grey}
                    style={{ width: '12px', height: '12px' }}
                    onClick={onClose}
                />
            </Title>
            <SubText>
                Add missing information to create a perfect listing
            </SubText>
            <ProgressBar>
                <ProgressBarFull
                    style={{
                        width:
                            ((442 - 80) / checksArray.length) *
                            activePropgressBarLength,
                    }}
                />
            </ProgressBar>
            {checksArray.map(
                (check: [string, IPublishValidationObject[string]], i) => {
                    let checkName = check[0]
                    let checkValues = check[1]
                    if (checkValues.subItems) {
                        let subChecksArray: IValidation_subObject[] =
                            Object.values(checkValues.subItems)

                        return (
                            <React.Fragment
                                key={`showroom_valid_sheet_${i}_desktop`}
                            >
                                <PublishCheckItem
                                    isTooltipRemoved={
                                        checkValues.isTooltipRemoved
                                    }
                                    isIncomplete={checkValues.check === false}
                                    hasSubItems
                                    hasNitems={checkValues.hasNitems}
                                    minN={checkValues.minN}
                                    tooltipText={checkValues.tooltipText}
                                    isOpen={subItemsOpenState[checkName]}
                                    text={checkValues.text}
                                    withoutBottomBorder
                                    onClick={() => {
                                        setSubItemsOpenState((prevState) => {
                                            return {
                                                // closing all others and
                                                ...subItemsOpenState,
                                                // keeping only one open
                                                [checkName]:
                                                    !prevState[checkName],
                                            }
                                        })
                                    }}
                                />

                                <Expander
                                    height={
                                        subItemsOpenState[checkName]
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    {subChecksArray.map(
                                        (
                                            subItem: IValidation_subObject,
                                            indexx
                                        ) => (
                                            <React.Fragment
                                                key={`showroom_valid_sheet_sub_item${indexx}_desktop`}
                                            >
                                                <PublishCheckItem
                                                    isIncomplete={
                                                        subItem.check === false
                                                    }
                                                    text={subItem.text}
                                                    isSubItem
                                                    withoutBottomBorder
                                                    onClick={() => {
                                                        subItem.action()
                                                    }}
                                                    isDisabled={
                                                        subItem.isDisabled
                                                    }
                                                    hasNitems={
                                                        subItem.hasNitems
                                                    }
                                                    minN={subItem.minN}
                                                    tooltipText={
                                                        subItem.tooltipText
                                                    }
                                                    isTooltipRemoved={
                                                        subItem.isTooltipRemoved
                                                    }
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                    <div
                                        style={{
                                            paddingTop: '16px',
                                        }}
                                    />
                                </Expander>
                                <div
                                    style={{
                                        height: 1,
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        backgroundColor:
                                            'var(--border-muted, #e5e5e5)',
                                    }}
                                />
                            </React.Fragment>
                        )
                    } else
                        return (
                            <React.Fragment
                                key={`showroom_valid_sheet_sub_item${i}_desktop`}
                            >
                                <PublishCheckItem
                                    isTooltipRemoved={
                                        checkValues.isTooltipRemoved
                                    }
                                    hasNitems={checkValues.hasNitems}
                                    minN={checkValues.minN}
                                    tooltipText={checkValues.tooltipText}
                                    isIncomplete={checkValues.check === false}
                                    text={checkValues.text}
                                    withoutBottomBorder={
                                        i === checksArray.length - 1
                                    }
                                    onClick={() => {
                                        checkValues.action()
                                    }}
                                />
                            </React.Fragment>
                        )
                }
            )}
        </Container>
    ) : (
        <div style={{ display: 'none' }} />
    )
}

export default PublishChecksManagerDesktop
