import React, { useState, useEffect } from 'react'
import { MaintenanceScreen } from './maintenance'
import BackEndError from './backEndError'
import { useAppSelector } from '../../../redux/store/hooks'
import { IReduxError } from '../../../redux/entities/cars/types'

export interface PlatformErrorsState {
    showErrorMessage: boolean
    error: IReduxError | null
    errorStack: {
        offline: IReduxError | null
        userError: IReduxError | null
        garageError: IReduxError | null
        carError: IReduxError | null
        imageError: IReduxError | null
        highlightedFactsError: IReduxError | null
        technicalInformationError: IReduxError | null
        dropDownDataError: IReduxError | null
        fileStorageError: IReduxError | null
        tasksError: IReduxError | null
        timelineError: IReduxError | null
        appexError: IReduxError | null
        showroomError: IReduxError | null
        insuranceError: IReduxError | null
    }
}

const PlatformErrorManager: React.FC = () => {
    let isOnline = window.navigator.onLine

    const userError = useAppSelector((state) => state.user.error)
    const garageError = useAppSelector(
        (state) => state.entities.garagesData.error
    )
    const carError = useAppSelector((state) => state.entities.carsData.error)
    const imageError = useAppSelector(
        (state) => state.entities.galleriesData.error
    )
    const highlightedFactsError = useAppSelector(
        (state) => state.entities.highlightedFactsData.error
    )
    const technicalInformationError = useAppSelector(
        (state) => state.entities.technicalInformationData.error
    )
    const dropDownDataError = useAppSelector(
        (state) => state.localdata.dropdownData.error
    )
    const fileStorageError = useAppSelector((state) => state.fileStorage.error)
    const tasksError = useAppSelector((state) => state.tasks.error)
    const timelineError = useAppSelector((state) => state.timeline.error)
    const apexError = useAppSelector((state) => state.apex.error)
    const showroomError = useAppSelector((state) => state.showroom.error)

    const insuranceError = useAppSelector(
        (state) => state.insuranceQuoteApplication.error
    )

    const [state, setState] = useState<PlatformErrorsState>({
        showErrorMessage: false,
        error: {
            default: undefined,
            status_code: undefined,
            custom: {
                custom_message: '',
                custom_message_detail: '',
                custom_user_action_text: undefined,
                custom_user_action_func: undefined,
                custom_redirect_path: '',
            },
            entity_error: undefined,
            is_permission_denied: false,
        },
        errorStack: {
            offline: null,
            userError: null,
            garageError: null,
            carError: null,
            imageError: null,
            highlightedFactsError: null,
            technicalInformationError: null,
            dropDownDataError: null,
            fileStorageError: null,
            tasksError: null,
            timelineError: null,
            appexError: null,
            showroomError: null,
            insuranceError: null,
        },
    })

    const generateTextError = (
        error: IReduxError
    ): {
        text: string
        detail?: string | undefined
    } => {
        if (error && error.default === Error) {
            return {
                text: 'Looks like your internet has stopped working.',
                detail: 'Please check your connection and try again.',
            }
        }
        if (error && error.custom && error.custom.custom_message) {
            return {
                text: error.custom.custom_message,
                detail: error.custom.custom_message_detail,
            }
        } else
            return {
                text: 'Something went wrong',
                detail: 'Please check your internet connection and try again.',
            }
    }

    const generateRedirect = (error: IReduxError): string => {
        if (
            error &&
            error.custom &&
            error.custom.custom_redirect_path &&
            error.custom.custom_redirect_path !== ''
        ) {
            return error.custom.custom_redirect_path
        } else {
            return window.location.search
                ? `${window.location.pathname}${window.location.search}`
                : window.location.pathname
        }
    }

    const generateCustomUserActionText = (error: IReduxError) => {
        if (error && error.default === Error) {
            return 'Refresh page'
        }
        if (error && error.custom && error.custom.custom_user_action_text) {
            return error.custom.custom_user_action_text
        } else {
            return 'Refresh page'
        }
    }

    const checkIfInSafariOrChromeBrowser = () => {
        const ua = window.navigator.userAgent
        let chromeAgent = ua.indexOf('Chrome') > -1
        let safariAgent = ua.indexOf('Safari') > -1
        return chromeAgent || safariAgent
    }

    const handleSafariOrChromeNOffline = () => {
        if (!isOnline) {
            let offlineError: IReduxError = {
                default: { status: '500' },
                custom: {
                    custom_message:
                        'Looks like you are offline. Connect to the internet and try again.',
                    custom_user_action_text: `I'm connected`,
                    custom_redirect_path: window.location.search
                        ? `${window.location.pathname}${location.search}`
                        : window.location.pathname,
                },
            }
            setState((prevState) => ({
                ...prevState,
                showErrorMessage: true,
                error: offlineError,
                errorStack: {
                    ...prevState.errorStack,
                    offline: offlineError,
                },
            }))
        } else {
            setState((prevState) => ({ ...prevState, showErrorMessage: false }))
        }
    }

    const checkAndHandleAnyOtherError = () => {
        let anyOtherError: boolean =
            userError ||
            garageError ||
            carError ||
            imageError ||
            highlightedFactsError ||
            technicalInformationError ||
            dropDownDataError ||
            fileStorageError ||
            tasksError ||
            timelineError ||
            apexError ||
            showroomError ||
            insuranceError
                ? true
                : false
        if (anyOtherError) {
            setState((prevState) => ({
                ...prevState,
                showErrorMessage: true,
                error:
                    userError ||
                    garageError ||
                    carError ||
                    imageError ||
                    highlightedFactsError ||
                    technicalInformationError ||
                    dropDownDataError ||
                    fileStorageError ||
                    tasksError ||
                    timelineError ||
                    apexError ||
                    showroomError ||
                    insuranceError,
                errorStack: {
                    ...prevState.errorStack,
                    userError,
                    garageError,
                    carError,
                    imageError,
                    highlightedFactsError,
                    technicalInformationError,
                    dropDownDataError,
                    fileStorageError,
                    tasksError,
                    timelineError,
                    appexError: apexError,
                    showroomError,
                    insuranceError,
                },
            }))
        } else {
            setState((prevState) => ({ ...prevState, showErrorMessage: false }))
        }
    }

    useEffect(() => {
        if (checkIfInSafariOrChromeBrowser() && !isOnline) {
            handleSafariOrChromeNOffline()
        } else {
            checkAndHandleAnyOtherError()
        }
    }, [
        isOnline,
        userError,
        garageError,
        carError,
        imageError,
        highlightedFactsError,
        technicalInformationError,
        dropDownDataError,
        fileStorageError,
        tasksError,
        timelineError,
        apexError,
        showroomError,
        insuranceError,
    ])

    const handleUserAction = () => {
        if (checkIfInSafariOrChromeBrowser() && !window.navigator.onLine) {
            setState((prevState) => ({
                ...prevState,
                error: {
                    ...prevState.error,
                    default: prevState?.error?.default || null,
                    custom: {
                        custom_message: 'It looks like you are still offline',
                        custom_user_action_text: 'Try now',
                    },
                },
            }))
        } else {
            setState({
                showErrorMessage: false,
                error: null,
                errorStack: {
                    userError: null,
                    garageError: null,
                    carError: null,
                    imageError: null,
                    highlightedFactsError: null,
                    technicalInformationError: null,
                    dropDownDataError: null,
                    fileStorageError: null,
                    tasksError: null,
                    timelineError: null,
                    appexError: null,
                    showroomError: null,
                    insuranceError: null,
                    offline: null,
                },
            })
        }
    }

    const resetErrorState = () => {
        setState({
            showErrorMessage: false,
            error: null,
            errorStack: {
                userError: null,
                garageError: null,
                carError: null,
                imageError: null,
                highlightedFactsError: null,
                technicalInformationError: null,
                dropDownDataError: null,
                fileStorageError: null,
                tasksError: null,
                timelineError: null,
                appexError: null,
                showroomError: null,
                insuranceError: null,
                offline: null,
            },
        })
    }

    let pathname = window.location.pathname

    // checkIfPwaStandalone()
    //     ? window.location.pathname
    //     : window?.location?.pathname

    let isResetPassPage =
        window.location.pathname.match(/reset-password/g) !== null
            ? true
            : false
    // ||
    // window?.location?.pathname?.match(/reset-password/g) !== null

    return (
        <>
            {state.error?.status_code === 503 && (
                <MaintenanceScreen error={state.error} />
            )}

            {state.error?.status_code !== 503 &&
                pathname !== '/' &&
                pathname !== '/login' &&
                pathname !== '/signin' &&
                pathname !== '/register' &&
                pathname !== '/account-deletion-confirmed' &&
                !isResetPassPage && (
                    <BackEndError
                        resetErrorState={resetErrorState}
                        generateRedirect={generateRedirect}
                        handleUserAction={handleUserAction}
                        generateTextError={generateTextError}
                        generateCustomUserActionText={
                            generateCustomUserActionText
                        }
                        platform_errors_state={state}
                    />
                )}
        </>
    )
}

export default PlatformErrorManager
