import React from 'react'
import './styles.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import SlideTheme2Mobile from '../../../atoms/slider/theme2/slideTheme2Mobile'

type IProps = {
    slides: any[]
}

const SliderApexHomepageMobile: React.FC<IProps> = ({ slides }) => {
    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
                nextEl: '.swiper-button-next-custom',
                prevEl: '.swiper-button-prev-custom',
            }}
            loop={true}
            direction={'horizontal'}
            pagination={{ clickable: true, dynamicBullets: true }}
            scrollbar={{ draggable: true }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <SlideTheme2Mobile height={'36vh'} slide={slide} />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default SliderApexHomepageMobile
