import Faded from '../../templates/animated/faded'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'
import InfoBarMobile from '../../atoms/info/infoBarMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IRootState } from '../../../redux/store'
import { useEffect } from 'react'
import { usersActions } from '../../../redux/user/reducer'
import NotificationsBody from '../../molecules/notifications/body'

const NotificationsPage = () => {
    const dispatch = useAppDispatch()
    const userLoggedIn = useAppSelector(
        (state: IRootState) => state.user.userLoggedIn
    )

    const setInitialLandingUrl = (str: string) =>
        dispatch(customNavDataActions.setInitialLandingUrl(str))

    useEffect(() => {
        setInitialLandingUrl('/account/notifications')

        if (!userLoggedIn) {
            usersActions.getCurrentUserDataRequest()
        }

        if (userLoggedIn && userLoggedIn.mailingGroups === undefined) {
            dispatch(usersActions.getUserMailingGroupsRequest())
        }
    }, [userLoggedIn])

    return (
        <CenteredPageWrapper hasMobileSubNav>
            {userLoggedIn && (
                <Faded>
                    <Loader />
                    <DesktopDisplayOnly>
                        <div
                            style={{
                                paddingLeft: '50px',
                                paddingRight: '50px',
                                paddingTop: '50px',
                            }}
                        >
                            <SectionHeader
                                title={'Manage your notifications'}
                                removeEdit={true}
                                customPadding={'0px'}
                            />

                            <NotificationsBody />
                        </div>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <InfoBarMobile>
                            Please tap the field you wish to edit
                        </InfoBarMobile>
                        <MobileSettingsSectionHeader title="Manage your notifications" />
                        <NotificationsBody />
                        <div style={{ paddingBottom: '150px' }} />
                    </IpadAndMobileDisplay>
                </Faded>
            )}
        </CenteredPageWrapper>
    )
}

export default NotificationsPage
