import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(utc)

export function getRelativeTimeAgoFromToday(refferenceDate: Date): string {
    let today = new Date()
    let refferenceDateD = dayjs(refferenceDate).endOf('day').utc().utcOffset(0)
    refferenceDateD = refferenceDateD
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)

    let todayD = dayjs(today).endOf('day').utc().utcOffset(0)
    todayD = todayD
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)

    let dayjsFinalDate = todayD.from(refferenceDateD, true)

    let nReg = /\d+/g
    let matchedN = nReg.exec(dayjsFinalDate)

    let nValue: number | undefined = undefined

    if (matchedN) {
        nValue = parseInt(matchedN[0])
    }

    if (dayjsFinalDate.includes('day') && nValue && nValue % 7 === 0) {
        return nValue / 7 === 1 ? `1 week` : `${nValue / 7} weeks`
    }

    return dayjsFinalDate
}
