import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import { showroomActions } from '../../../../../redux/showroom/reducer'
import {
    IShowroomFiltersState,
    IFilterID,
    IShowroomFilterRadioOptionItem,
} from '../../../../../redux/showroom/types'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import PickerBtnV2 from '../../../../atoms/Button/pickerBtnV2'
import CustomAnimatedRadioField from '../../../../atoms/formField/customAnimatedRadioField'
import RangeSlider from '../../../../atoms/formField/rangeSlider'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import CustomTagQuickFilterItemDesktop from '../../../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import RangeRowFieldsMobile from '../../../../molecules/editOrCreateModeSingleFields/rangeRow/rangeFieldMobile'
import ShowroomFilterLocationMobileBodyForm from '../../../../molecules/showroom/filter/showroomFilterLocationMobileBodyForm'

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`

const Caption = styled.div`
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
`

const ContentWrapper = styled.div`
    transform: translateY(10px);
    padding-bottom: 10px;
`

type Props = {
    elemID: IFilterID
}

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: start;
    padding-right: 30px;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
`

const ShowroomFiltersFormManagerMobile = (props: Props) => {
    let editFilterState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.editingFilters
    })

    let { elemID } = props

    const dispatch = useAppDispatch()

    const [currentKeywordValue, setCurrentKeywordValue] = useState<string>('')

    let location = useLocation()

    const params = new URLSearchParams(location.search)
    let soldParams: any = params.get('sold')

    let navigate = useNavigate()
    const onClickSearch = () => {
        dispatch(showroomActions.setFilterOpen(elemID))

        if (soldParams) {
            navigate(`/showroom/search?filter=${elemID}&sold=true`)
        } else navigate(`/showroom/search?filter=${elemID}`)
    }

    const renderOptions = () => {
        return (
            <div
                style={{
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                {
                    // @ts-ignore
                    editFilterState[elemID].options &&
                        // @ts-ignore
                        editFilterState[elemID].options!.map(
                            (
                                item: IShowroomFilterRadioOptionItem,
                                index: number
                            ) => {
                                return (
                                    <CustomAnimatedRadioField
                                        name={item.name}
                                        id={item.id}
                                        checked={
                                            // @ts-ignore
                                            editFilterState[elemID].value ===
                                            item.id
                                                ? true
                                                : false
                                        }
                                        onChange={(id: string) => {
                                            dispatch(
                                                showroomActions.setRadioSelect_filter(
                                                    {
                                                        // @ts-ignore
                                                        id: elemID,
                                                        item: item,
                                                    }
                                                )
                                            )
                                        }}
                                        rowHeight={'36px'}
                                        extra_small
                                        hasWhiteBg
                                    />
                                )
                            }
                        )
                }
            </div>
        )
    }

    const renderRanges = () => {
        // if filtersState[elemID].type === ''

        return (
            <div style={{ width: '100%' }}>
                <RangeSlider
                    // @ts-ignore
                    valueFrom={editFilterState[elemID].valueFrom ?? undefined}
                    // @ts-ignore
                    valueTo={editFilterState[elemID].valueTo ?? undefined}
                    onChange={(
                        valueFrom: number | null | undefined,
                        valueTo: number | null | undefined
                    ) => {
                        dispatch(
                            showroomActions.setRange_filter({
                                // @ts-ignore
                                id: elemID,
                                valueFrom: valueFrom,
                                valueTo: valueTo,
                            })
                        )
                    }}
                    defaultValueFrom={
                        // @ts-ignore
                        editFilterState[elemID].min_backend
                            ? // @ts-ignore
                              editFilterState[elemID].min_backend
                            : 0
                    }
                    defaultValueTo={
                        // @ts-ignore
                        editFilterState[elemID].max_backend
                            ? // @ts-ignore
                              editFilterState[elemID].max_backend
                            : 2024
                    }
                    min={
                        // @ts-ignore
                        editFilterState[elemID].min_backend
                            ? // @ts-ignore
                              Math.round(editFilterState[elemID].min_backend)
                            : undefined
                    }
                    max={
                        // @ts-ignore
                        editFilterState[elemID].max_backend
                            ? // @ts-ignore
                              Math.round(editFilterState[elemID].max_backend)
                            : undefined
                    }
                />

                <div style={{ paddingTop: '25px' }} />

                <RangeRowFieldsMobile
                    filterid={elemID}
                    customWidth="90%"
                    valueFrom={
                        // @ts-ignore
                        editFilterState[elemID].valueFrom
                    }
                    valueTo={
                        // @ts-ignore
                        editFilterState[elemID].valueTo
                    }
                    onChange={(
                        valueFrom: number | null | undefined,
                        valueTo: number | null | undefined
                    ) => {
                        dispatch(
                            showroomActions.setRange_filter({
                                // @ts-ignore
                                id: elemID,
                                valueFrom: valueFrom,
                                valueTo: valueTo,
                            })
                        )
                    }}
                    labelFrom={
                        elemID === 'price'
                            ? 'Price from'
                            : elemID === 'mileage'
                            ? 'Mileage from'
                            : 'from'
                    }
                    labelTo={
                        elemID === 'price'
                            ? 'Price to'
                            : elemID === 'mileage'
                            ? 'Mileage to'
                            : 'to'
                    }
                    // @ts-ignore
                    rangeMin={editFilterState[elemID].min_backend}
                    // @ts-ignore
                    rangeMax={editFilterState[elemID].max_backend}
                />
            </div>
        )
    }

    let renderForm = () => {
        switch (elemID) {
            case 'price':
                return <ContentWrapper>{renderRanges()}</ContentWrapper>

            case 'keyword':
                return (
                    <ContentWrapper
                        style={{ maxWidth: '100%', paddingTop: '15px' }}
                    >
                        <InputFieldNoFormikAnimated
                            value={currentKeywordValue}
                            name="keyword"
                            placeholder="keyword"
                            onChange={(e: any) => {
                                setCurrentKeywordValue(e.target.value)
                            }}
                            onEnterKeyPress={() => {
                                dispatch(
                                    showroomActions.setKeyword_filter(
                                        currentKeywordValue
                                    )
                                )
                                setCurrentKeywordValue('')
                            }}
                            enterKeyHint="next"
                        />
                        <div style={{ paddingTop: '10px' }} />

                        <Caption style={{ fontFamily: 'Lato' }}>
                            {editFilterState.keyword.mobileCaption}
                        </Caption>

                        {editFilterState.keyword.values &&
                        editFilterState.keyword.values.length > 0 ? (
                            <Row>
                                {
                                    // @ts-ignore
                                    editFilterState.keyword.values.map(
                                        (val: string, index: number) => {
                                            return (
                                                <div
                                                    key={`${index}-keyword-mobile`}
                                                    style={{
                                                        paddingBottom:
                                                            index !==
                                                            // @ts-ignore
                                                            editFilterState
                                                                .keyword.values
                                                                .length -
                                                                1
                                                                ? '10px'
                                                                : 'none',
                                                        // @ts-ignore
                                                        paddingRight:
                                                            // @ts-ignore
                                                            editFilterState
                                                                .keyword.values
                                                                .length - 1
                                                                ? '10px'
                                                                : 'none',
                                                    }}
                                                >
                                                    <CustomTagQuickFilterItemDesktop
                                                        key={`${index}-keywiord}`}
                                                        height="24px"
                                                        onCrossClick={() => {
                                                            dispatch(
                                                                showroomActions.removeMultiSelectItem_request(
                                                                    {
                                                                        //@ts-ignore
                                                                        id: 'keyword',
                                                                        value: val,
                                                                    }
                                                                )
                                                            )
                                                        }}
                                                        bgcolor={'#5EC3CA'}
                                                        hasBoxShadow={false}
                                                        isActive={true}
                                                    >
                                                        <span
                                                            style={{
                                                                color: '#fff',
                                                                paddingLeft:
                                                                    '2px',
                                                            }}
                                                        >
                                                            {val}
                                                        </span>
                                                    </CustomTagQuickFilterItemDesktop>
                                                    <div
                                                        style={{
                                                            paddingRight:
                                                                '10px',
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </Row>
                        ) : null}
                    </ContentWrapper>
                )
            case 'type_of_sale':
                return renderOptions()

            case 'drive_side':
                //  return renderOptions()
                return (
                    <PickerBtnV2
                        name="Drive Side"
                        value={
                            editFilterState.drive_side.displayValue ?? undefined
                        }
                        placeholder={'Select drive side'}
                        onClick={onClickSearch}
                        isDisabled={false}
                    />
                )

            case 'mileage':
                return <ContentWrapper>{renderRanges()}</ContentWrapper>

            case 'make':
                return (
                    <PickerBtnV2
                        name="Make"
                        value={
                            editFilterState.make.displayValue
                                ? editFilterState.make.displayValue
                                : undefined
                        }
                        customTextTransform={'none'}
                        placeholder={'Select make'}
                        onClick={onClickSearch}
                        isDisabled={false}
                    />
                )
            case 'model':
                return (
                    <PickerBtnV2
                        customTextTransform={'none'}
                        name="Model"
                        value={editFilterState.model.displayValue ?? undefined}
                        placeholder={'Select model'}
                        onClick={onClickSearch}
                        isDisabled={false}
                    />
                )

            case 'year':
                return (
                    <PickerBtnV2
                        name="Year"
                        customTextTransform={'lowercase'}
                        value={editFilterState.year.displayValue ?? undefined}
                        placeholder={'Select year'}
                        onClick={onClickSearch}
                        isDisabled={false}
                    />
                )

            case 'body_type':
                return (
                    <PickerBtnV2
                        name="Body Type"
                        value={
                            editFilterState.body_type.displayValue ?? undefined
                        }
                        placeholder={'Select body type'}
                        onClick={onClickSearch}
                        isDisabled={false}
                    />
                )

            case 'location':
                return <ShowroomFilterLocationMobileBodyForm />

            case 'colour':
                return (
                    <PickerBtnV2
                        name="Colour"
                        value={
                            editFilterState.colour.values
                                ? editFilterState.colour.values[0]
                                : undefined
                        }
                        customTextTransform={'capitalize'}
                        placeholder={'Select colour'}
                        onClick={onClickSearch}
                        isDisabled={false}
                    />
                )
            //  return renderMultiSelectNoSearch()

            default:
                return <div>default form</div>
        }
    }

    return <Container>{renderForm()}</Container>
}

export default ShowroomFiltersFormManagerMobile
