import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

type IStyle = {
    $isLeftAligned?: boolean
}

const H1Styled = styled.h2<IStyle>`
    text-shadow: none;
    font-family: Lato;
    font-weight: 400;
    color: var(--text-strong, #1a1a1a);
    font-size: 18px;
    line-height: 22px;
    text-align: ${(props) => (props.$isLeftAligned ? 'left' : 'center')};

    @media ${device.mobile} {
        padding-bottom: 0px;
        margin-bottom: 0px;
        font-size: 15px;
        padding-left: 10px;
        padding-right: 10px;
    }
    max-width: 350px;
    @media ${device.smallest_laptops} {
        font-size: 15px;
    }

    @media ${device.desktop} {
        font-size: 17px;
    }

    @media ${device.large_desktop} {
        font-size: 18px;
    }
`

const Wrap = styled.div<IStyle>`
    text-align: ${(props) => (props.$isLeftAligned ? 'left' : 'center')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${(props) => (props.$isLeftAligned ? 'flex-start' : 'center')};
    width: 100%;
    box-sizing: border-box;
`

export interface IH2V3Props {
    children?: React.ReactNode
    leftalign?: boolean
}

const H2V3: React.FunctionComponent<IH2V3Props> = (props) => (
    <Wrap $isLeftAligned={props.leftalign}>
        <H1Styled $isLeftAligned={props.leftalign}>{props.children}</H1Styled>
    </Wrap>
)

export default H2V3
