import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import {
    OnboardingText,
    PaddingUnit,
} from '../../../../templates/onboarding/onboardingFormLayoutMobile'
import { generateUserChoiceUnit } from '../../../../../helpers/units/generateUserChoiceUnit'
import {
    useAppSelector,
    useAppDispatch,
} from '../../../../../redux/store/hooks'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import Expander from '../../../../atoms/expander/expander'
import { ErrorText } from '../../account/accountAddressForm/accountAddressformDesktop'
import SelectSearchBarCarInfoDesktop from '../../technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import ListOnboardingDVLdesktop from '../../../../molecules/list/listOnboardingDVLAdesktop'
import { IOnboarding_Car_Data_DVLA } from './confirmCarDataFormOnboardingMobile'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    Notice,
} from '../../../../molecules/technicalInformation/modal'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import ButtonsStandardBottomRowDesktop from '../../buttons/buttonsStandardBottomRowDesktop'
import React from 'react'
import ModalDisplayFullPageDesktopExclusive from '../../../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import { EditIcon } from '../../../../atoms/statefulicons'
import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    max-width: 800px;
    padding-top: 0px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
`

const ModalTitle = styled.h3`
    padding: 0;
    margin: 0;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-bold;
    font-size: 24px;
    line-height: 40px;
    padding-bottom: 24px;
`

const List = styled.ul`
    all: unset;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
`
const Row = styled.li`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    gap: 16px;
`

const Title = styled.h3`
    color: var(--text-muted, #b3b3b3);
    font-family: Lato-bold;
    font-size: 22px;
    line-height: 32px; /* 145.455% */
    letter-spacing: 1.54px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
`

const Answer = styled.p`
    margin: 0;
    padding: 0;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-bold;
    font-size: 22px;
    line-height: 32px; /* 145.455% */
    letter-spacing: 1.54px;
    text-transform: uppercase;
    cursor: pointer;

    :hover {
        color: var(--primary, #5ec3ca);
        transition: all 200ms;
    }
`

const Line = styled.div`
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--base-overlays-neutral-alpha-200);
`

type Props = {
    formData: IOnboarding_Car_Data_DVLA
    handleChange: (id: string, value: string | undefined) => void
    initial_info: any
    isVesApiFormOpen: boolean
    toggleVesApiForm: () => void
}

const ConfirmCarDataFormOnboardingRightDesktop = (props: Props) => {
    let {
        formData,
        initial_info,
        handleChange,
        isVesApiFormOpen,
        toggleVesApiForm,
    } = props

    let appDispatch = useAppDispatch()

    let makesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })
    let modelsDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let displayModel = useAppSelector((state) => {
        return state.localdata.dropdownData.tempModelDisplay
    })

    let displayMake = useAppSelector((state) => {
        return state.localdata.dropdownData.tempMakeDisplay
    })
    let dropdownDataLoading = useAppSelector(
        (state) => state.localdata.dropdownData.loading
    )
    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    let user_fuel_unit_chosen_txt = userLoggedIn
        ? generateUserChoiceUnit(userLoggedIn, 'fuel_unit').unit_text
        : ''
    const car_years_validation = useAppSelector(
        (state) => state.localdata.form_validation_copy.years
    )

    let keysToMap = [
        'tax_status',
        'tax_due',
        'service_check_status',
        'service_check_expiry',
        'registration_date',
        'engine_capacity',
        'fuel_type',
        'body_type',
        'color_exterior',
        'last_owner_change',
    ]

    const [showForm, toggleForm] = useState(false)
    // const [make, setMake] = useState<string | undefined>(undefined)
    const [year, setYear] = useState<string | number | undefined>(undefined)

    useEffect(() => {
        setError(undefined)
        // setMake(formData.make)

        setYear(formData.year)
        if (isVesApiFormOpen) {
            toggleForm(true)
            toggleVesApiForm()
            setTimeout(() => validateOnModalSubmit(), 50)
        }
    }, [formData, isVesApiFormOpen])

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    const isFieldValid = (id: string, value?: string | number): boolean => {
        if (id === 'year' && value !== undefined && car_years_validation) {
            if (+value < 1850) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        year: car_years_validation.car_year.warning_min,
                    }
                })
                return false
            } else if (+value > car_years_validation.car_year.max) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        year: car_years_validation.car_year.warning_max,
                    }
                })
                return false
            }
        } else if ((id === 'make' || id === 'model') && !value) {
            setError((prevState) => {
                return { ...prevState, [id]: 'Required' }
            })
            return false
        }
        return true
    }

    const validateOnModalSubmit = () => {
        if (isFieldValid('make', displayMake) === false) {
            return false
        }
        if (isFieldValid('model', displayModel) === false) {
            return false
        }
        if (isFieldValid('year', year) === false) {
            return false
        }

        return true
    }

    const submitModalData = () => {
        if (validateOnModalSubmit() === true) {
            handleChange('make', displayMake)
            handleChange('model', displayModel)
            handleChange('year', year ? `${year}` : undefined)
            toggleForm(false)
        }
    }

    let isLoadingNewMakeOrModel = useAppSelector((state) => {
        return state.localdata.dropdownData.isLoadingNewMakeOrModel
    })

    const { theme } = useThemes()

    return (
        <Wrapper>
            <OnboardingText>
                Make sure that Make / Model / Year details are correct.
            </OnboardingText>

            <PaddingUnit $times={5} />
            <Line />
            <PaddingUnit $times={5} />

            <List>
                {['make', 'model', 'year'].map((id: string, index) => {
                    return (
                        <React.Fragment key={`onboarding_mobile_${index}`}>
                            <Row>
                                <Title>{id}</Title>
                                <Answer
                                    onClick={() => toggleForm(true)}
                                    style={
                                        (id === 'make' && !displayMake) ||
                                        (id === 'model' && !displayModel)
                                            ? { color: colours[theme].primary }
                                            : {}
                                    }
                                >
                                    {id === 'make'
                                        ? displayMake ?? 'Select Make'
                                        : id === 'model'
                                        ? displayModel ?? 'Select Model'
                                        : formData[
                                              id as keyof IOnboarding_Car_Data_DVLA
                                          ] ?? `SELECT ${id}`}
                                </Answer>
                            </Row>
                        </React.Fragment>
                    )
                })}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        marginTop: -8,
                        right: 16,
                        cursor: 'pointer',
                        width: 40,
                        height: 40,
                        borderRadius: 4,
                        backgroundColor: colours[theme].primary_09,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <EditIcon
                        width="18"
                        height="18"
                        onClick={() => toggleForm(true)}
                    />
                </div>
            </List>

            <PaddingUnit $times={5} />
            <Line />
            <PaddingUnit $times={5} />

            <ListOnboardingDVLdesktop
                data={initial_info}
                list={keysToMap}
                default_fuel_unit_user_text={user_fuel_unit_chosen_txt ?? ''}
            />

            {makesDropdowndata && showForm ? (
                <ModalDisplayFullPageDesktopExclusive
                    isOpen={showForm}
                    toggle={() => {}}
                >
                    <ModalContentDesktop
                        $theme={theme}
                        data-attr={'onboarding-edit-dvla-make-desktop-modal'}
                        style={{ overflowY: 'auto' }}
                    >
                        <ModalInnerContentWrapperDesktop>
                            <ModalTitle>Make | Model | Year</ModalTitle>
                            <PaddingUnit $times={2} />
                            <Notice>
                                If you can't find your car's model or make
                                simply type the name fully, then select "
                                <span style={{ fontWeight: 'bold' }}>add</span>
                                ".
                            </Notice>

                            <PaddingUnit $times={5} />

                            <SelectSearchBarCarInfoDesktop
                                field_id="make"
                                placeholder={displayMake ? 'Make * ' : 'Make'}
                                handleSelectChange={(
                                    name: string,
                                    other?: string
                                ) => {
                                    // setMake(name)

                                    appDispatch(
                                        dropDownActions.setModelDropdownDataRequest(
                                            {
                                                makeName: name,
                                                make_uid: other,
                                            }
                                        )
                                    )
                                }}
                                addAnItemFunc={(id: string, name: string) => {
                                    appDispatch(
                                        dropDownActions.setIsLoadingNewMakeOrModel(
                                            true
                                        )
                                    )
                                    appDispatch(
                                        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                            {
                                                dropdownTargetId: id,
                                                name: name,
                                            }
                                        )
                                    )
                                }}
                                item={{
                                    answer: displayMake,
                                    id: 'make',
                                    text: 'make *',
                                }}
                                items={
                                    makesDropdowndata ? makesDropdowndata : []
                                }
                                formCurrentValue={displayMake}
                                dropdownTargetId="carMakes"
                                allowAdd={true}
                                onlyShowMatch={true}
                                customMaxWidth="100%"
                                borderColor={
                                    error?.make
                                        ? 'rgb(223, 111, 111)'
                                        : undefined
                                }
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                            <Expander
                                height={error?.make ? 'auto' : 0}
                                width={'100%'}
                            >
                                <ErrorText>{error?.make}</ErrorText>
                            </Expander>
                            <PaddingUnit $times={3} />
                            <SelectSearchBarCarInfoDesktop
                                field_id="model"
                                handleSelectChange={(
                                    name: string,
                                    other?: string
                                ) => {
                                    // console.log('changed', name, other)
                                    // setModel(name)
                                    // setError((prevState) => {
                                    //     return {
                                    //         ...prevState,
                                    //         model: undefined,
                                    //     }
                                    // })
                                }}
                                valueUpdatedFromExternalSource={displayModel}
                                addAnItemFunc={(id: string, name: string) => {
                                    appDispatch(
                                        dropDownActions.setIsLoadingNewMakeOrModel(
                                            true
                                        )
                                    )
                                    appDispatch(
                                        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                            {
                                                dropdownTargetId: id,
                                                name: name,
                                                relevantOtherLinkedDataMakeMake:
                                                    displayMake,
                                            }
                                        )
                                    )
                                }}
                                item={{
                                    answer: displayModel,
                                    id: 'model',
                                    text: 'model *',
                                }}
                                placeholder={
                                    dropdownDataLoading
                                        ? 'Models loading ... '
                                        : formData.model
                                        ? 'Model *'
                                        : 'Model'
                                }
                                dropdownTargetId="carModels"
                                items={
                                    modelsDropdowndata ? modelsDropdowndata : []
                                }
                                formCurrentValue={displayModel}
                                allowAdd={true}
                                isDisabled={!displayMake ? true : false}
                                customMaxWidth="100%"
                                borderColor={
                                    error?.model
                                        ? 'rgb(223, 111, 111)'
                                        : undefined
                                }
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                            <Expander
                                height={error?.model ? 'auto' : 0}
                                width={'100%'}
                            >
                                <ErrorText>{error?.model}</ErrorText>
                            </Expander>
                            <PaddingUnit $times={3} />
                            <InputFieldNoFormikAnimated
                                value={year}
                                name={'Year'}
                                placeholder=""
                                type={'number'}
                                onChange={(e: any) => {
                                    setYear(e.target.value)
                                    setError((prevState) => {
                                        return {
                                            ...prevState,
                                            year: undefined,
                                        }
                                    })
                                }}
                                linecolor={
                                    error?.year
                                        ? 'rgb(223, 111, 111)'
                                        : undefined
                                }
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                            <Expander
                                height={error?.year ? 'auto' : 0}
                                width={'100%'}
                            >
                                <ErrorText>{error?.year}</ErrorText>
                            </Expander>
                            <div style={{ paddingTop: 32 }} />
                            <PaddingUnit $times={1} />
                            <ButtonsStandardBottomRowDesktop
                                cancel={() => {
                                    toggleForm(false)
                                    // setMake(formData.make)
                                    // setModel(formData.model)
                                    setYear(formData.year)
                                }}
                                submit={submitModalData}
                                firstText="Cancel"
                                secondText={'Save'}
                                dataCyIdUpdate={
                                    'onboarding-search-by-reg-no-desktop-click'
                                }
                                isDisabled={
                                    !displayMake ||
                                    !displayModel ||
                                    displayModel === '-'
                                }
                                isSecondTextLoading={isLoadingNewMakeOrModel}
                            />
                        </ModalInnerContentWrapperDesktop>
                    </ModalContentDesktop>
                </ModalDisplayFullPageDesktopExclusive>
            ) : null}
        </Wrapper>
    )
}

export default ConfirmCarDataFormOnboardingRightDesktop
