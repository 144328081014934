import { IPrismicImg } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../../../../../templates/displays/devices'
import grey_placeholder from '../../../../../../../../public/assets/placeholder/grey_placeholder.svg'
import ApexImageCreditsDesktop from '../../../../credits/apexImageCreditsDesktop'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 20px;
    @media ${device.beyond_ipad_mobile} {
    }
`
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 208px;
    column-gap: 20px;
    row-gap: 20px;

    @media ${device.large_desktop} {
        grid-template-rows: 302px;
    }
`
const Img1 = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    object-fit: cover;
    @media ${device.large_desktop} {
    }
`
const Img2 = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    object-fit: cover;
    @media ${device.large_desktop} {
    }
`
type Props = {
    img_1: IPrismicImg
    img_2: IPrismicImg
    image_credit?: string
}

const ApexDuoImageLandscapeSliceDesktop: React.FC<Props> = ({
    img_1,
    img_2,
    image_credit,
}) => {
    return (
        <Container>
            <Wrapper>
                <Img1 src={img_1.url ? img_1.url : grey_placeholder} />
                <Img2 src={img_2.url ? img_2.url : grey_placeholder} />
            </Wrapper>

            {image_credit && <ApexImageCreditsDesktop text={image_credit} />}
        </Container>
    )
}

export default ApexDuoImageLandscapeSliceDesktop
