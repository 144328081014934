import * as React from 'react'
import styled from 'styled-components'

import Faded from '../../animated/faded'
import IpadAndMobileDisplay from '../ipadAndMobileDisplay'

interface Props {
    children: React.ReactNode
    customZindex?: number
    isOpen: boolean
    toggle: any
}

interface Istyle {
    isCollapsed?: boolean
    isOpen?: boolean
    $customZindex?: number
}

const MobilePageWrapperStyle = styled.div<Istyle>`
    z-index: ${(props) =>
        props.$customZindex ? `${props.$customZindex}` : '12'};
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    top: 0px;
    left: 0px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ModalDisplayMobileIpad: React.FC<Props> = ({
    children,
    isOpen,
    toggle,
    customZindex,
}) => {
    const handleTouchMove = (e: any) => {
        let targetElement: HTMLElement | null =
            document.querySelector('#scrollable-content') &&
            document.querySelector('#scrollable-content')

        let scrollableContent: HTMLElement[] = []

        targetElement &&
            document
                .querySelectorAll('#scrollable-content *')
                .forEach((node: any) => scrollableContent.push(node))

        const targetIsElement =
            targetElement !== null && e.target.id === targetElement.id
        const modalContainsTarget = scrollableContent.includes(e.target)

        const shouldPreventDefault = !(targetIsElement || modalContainsTarget)

        if (shouldPreventDefault) e.preventDefault()
    }

    React.useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'
            if (window.matchMedia('(display-mode: standalone)').matches) {
                window.addEventListener('touchmove', handleTouchMove, {
                    passive: false,
                })
            }
        }

        return () => {
            document.body.style.overflow = 'auto'
            if (window.matchMedia('(display-mode: standalone)').matches) {
                window.removeEventListener('touchmove', handleTouchMove)
            }
        }
    }, [isOpen])

    return (
        <IpadAndMobileDisplay>
            <Faded>
                {isOpen ? (
                    <MobilePageWrapperStyle
                        onClick={() => toggle()}
                        isOpen={isOpen}
                        $customZindex={customZindex}
                    >
                        {children}
                    </MobilePageWrapperStyle>
                ) : null}
            </Faded>
        </IpadAndMobileDisplay>
    )
}

export default ModalDisplayMobileIpad
