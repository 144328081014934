import { motion, motionValue, MotionValue } from 'framer-motion'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import useWindowSize from '../../templates/displays/windowSizeHook'
import useSwipe from '../../templates/useSwipe'
import 'viewerjs/dist/viewer.css'
import Viewer from 'viewerjs'
import CustomTagQuickFilterItemMobile from '../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemMobile'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import Icon from '../../atoms/icons'
import Faded from '../../templates/animated/faded'
import GalleryImageMobile from '../../atoms/image/gallery/galleryImagemobile'
import { LocationIcon } from '../../atoms/icons/components'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import { IGalleryImagesObject } from '../../../redux/entities/galleries/types'

type ITab = {
    img_id: string
    x_pos: number
    id: GalleryTabNameEnum
}

export type GalleryTabNameEnum = 'zoe' | 'eve' | 'leo'

type ITabObj = {
    [key in GalleryTabNameEnum]: ITab
}

type IProps = {
    indexOfInitialImage: number
    image_data: IGalleryImagesObject | null
    images_ids: string[]
    readOnlyMode?: boolean
    openActionsSheet: any
    read_only?: boolean
    synchroniseActiveIndex: (n: number) => any
    cover_id: string
}

const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
`

const TabBox = styled(motion.div)`
    top: 50px;
    bottom: 0;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
`
const Parent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
`

const OptionsBar = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 220px;
    max-height: 30vh;
    background-color: var(--bg-color, #fff);
    padding-left: 30px;
    padding-right: 30px;
    z-index: 7;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100vw;
    padding-top: 20px;
`

const IconsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const DescriptionBox = styled.div`
    position: absolute;
    bottom: 230px;
    left: 20px;
    right: 20px;
    background-color: var(--bg-color-70);
    border-radius: 2px;
    padding: 16px;
    z-index: 7;
`
const DescriptionTxt = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    font-size: 12px;
    line-height: 16px;
`
const DescriptionOwner = styled.div`
    color: var(--text-strong, #1a1a1a);
    /* font-weight: 600; */
    font-size: 14px;
    line-height: 20px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
`
const DescriptionDetail = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
`
type IAnimProps = MotionValue<number>

export default function GalleryImageCarousel(props: IProps) {
    const {
        image_data,
        openActionsSheet,
        images_ids,
        indexOfInitialImage,
        synchroniseActiveIndex,
    } = props

    const windowWidth = useWindowSize().width ?? 0

    const [xPosArr, setxPosArr] = useState<number[]>([
        -windowWidth,
        0,
        windowWidth,
    ])

    useEffect(() => {
        setxPosArr([-windowWidth, 0, windowWidth])
    }, [windowWidth])

    const tabIds: GalleryTabNameEnum[] = ['zoe', 'eve', 'leo']

    const [tabs, setTabs] = useState<ITabObj>({
        zoe: {
            img_id:
                image_data && image_data[images_ids[indexOfInitialImage - 1]]
                    ? images_ids[indexOfInitialImage - 1]
                    : 'noid',
            x_pos: xPosArr[0],
            id: 'zoe',
        },
        leo: {
            img_id: images_ids[indexOfInitialImage],
            x_pos: xPosArr[1],
            id: 'leo',
        },
        eve: {
            img_id:
                image_data && image_data[images_ids[indexOfInitialImage + 1]]
                    ? images_ids[indexOfInitialImage + 1]
                    : 'noid',
            x_pos: xPosArr[2],
            id: 'eve',
        },
    })

    let currentTabName = (): GalleryTabNameEnum => {
        if (tabs && tabs.eve && tabs.eve.x_pos === 0) {
            return 'eve'
        }

        if (tabs && tabs.zoe && tabs.zoe.x_pos === 0) {
            return 'zoe'
        } else return 'leo'
    }
    let currName = currentTabName()

    useEffect(() => {
        setTabs((prevState: ITabObj) => {
            return {
                zoe: {
                    img_id:
                        image_data &&
                        image_data[images_ids[indexOfInitialImage - 1]]
                            ? images_ids[indexOfInitialImage - 1]
                            : 'noid',
                    x_pos: xPosArr[0],
                    id: 'zoe',
                },
                leo: {
                    img_id: images_ids[indexOfInitialImage],
                    x_pos: xPosArr[1],
                    id: 'leo',
                },
                eve: {
                    img_id:
                        image_data &&
                        image_data[images_ids[indexOfInitialImage + 1]]
                            ? images_ids[indexOfInitialImage + 1]
                            : 'noid',
                    x_pos: xPosArr[2],
                    id: 'eve',
                },
            }
        })

        return () => {}
    }, [indexOfInitialImage, xPosArr])

    const [motionVal_Eve, setMotionVal_Eve] = useState<IAnimProps>(
        motionValue(tabs.eve.x_pos)
    )

    const [motionVal_Leo, setMotionVal_Leo] = useState<IAnimProps>(
        motionValue(tabs.leo.x_pos)
    )

    const [motionVal_Zoe, setMotionVal_Zoe] = useState<IAnimProps>(
        motionValue(tabs.zoe.x_pos)
    )

    const manageTabAnimation = (
        tabName: GalleryTabNameEnum,
        newValue: number
    ) => {
        if (tabName === 'eve') {
            setMotionVal_Eve(motionValue(newValue))
        }

        if (tabName === 'leo') {
            setMotionVal_Leo(motionValue(newValue))
        }

        if (tabName === 'zoe') {
            setMotionVal_Zoe(motionValue(newValue))
        }
    }

    const [currentDirection, setCurrentDirection] = useState<
        'forward' | 'backwards' | undefined
    >(undefined)

    const onSwipeForward = () => {
        setCurrentDirection('forward')
        setTabs((prevState: ITabObj) => {
            let copy: ITabObj = { ...prevState }

            tabIds.forEach((id: GalleryTabNameEnum) => {
                if (xPosArr.indexOf(tabs[id].x_pos) === 0) {
                    copy[id].x_pos = xPosArr[2]
                    manageTabAnimation(id, xPosArr[1])
                    // updating image id
                    let new_image_id_index =
                        images_ids.indexOf(copy[id].img_id) + 3
                    if (images_ids[new_image_id_index]) {
                        copy[id].img_id = images_ids[new_image_id_index]
                    } else {
                        let current_image_index = images_ids.indexOf(
                            tabs[currName].img_id
                        )
                        copy[id].img_id = images_ids[current_image_index - 1]
                    }
                } else {
                    // xpos
                    manageTabAnimation(
                        id,
                        xPosArr[xPosArr.indexOf(copy[id].x_pos) + 1]
                    )
                    copy[id].x_pos =
                        xPosArr[xPosArr.indexOf(copy[id].x_pos) + 1]
                }
            })

            return copy
        })
    }

    const onSwipeBackward = () => {
        setCurrentDirection('backwards')
        setTabs((prevState: ITabObj) => {
            let copy: ITabObj = { ...prevState }

            tabIds.forEach((id: GalleryTabNameEnum) => {
                if (xPosArr.indexOf(tabs[id].x_pos) === 2) {
                    // xpos
                    copy[id].x_pos = xPosArr[0]
                    manageTabAnimation(id, xPosArr[1])
                    // updating image id

                    let new_image_id_index =
                        images_ids.indexOf(copy[id].img_id) - 3

                    if (images_ids[new_image_id_index]) {
                        copy[id].img_id = images_ids[new_image_id_index]
                    } else {
                        // in the event
                        let current_image_index = images_ids.indexOf(
                            tabs[currName].img_id
                        )
                        copy[id].img_id = images_ids[current_image_index + 1]
                    }
                } else {
                    // xpos
                    manageTabAnimation(
                        id,
                        xPosArr[xPosArr.indexOf(copy[id].x_pos) - 1]
                    )
                    copy[id].x_pos =
                        xPosArr[xPosArr.indexOf(copy[id].x_pos) - 1]
                }
            })

            return copy
        })
    }

    const onRight = () => {
        if (images_ids.indexOf(tabs[currName].img_id) !== 0) {
            onSwipeBackward()
            synchroniseActiveIndex(
                images_ids.indexOf(tabs[currName].img_id) - 1
            )
        }
    }
    const onLeft = () => {
        if (
            images_ids.indexOf(tabs[currName].img_id) !==
            images_ids.length - 1
        ) {
            onSwipeForward()
            synchroniseActiveIndex(
                images_ids.indexOf(tabs[currName].img_id) + 1
            )
        }
    }

    const bind = useSwipe({
        onLeft: () => onLeft(),
        onRight: () => onRight(),
    })

    const [viewer, setViewer] = useState<Viewer | null>(null)

    useEffect(() => {
        let element1 = document.getElementById(currName)
        if (element1) {
            setViewer(
                new Viewer(element1 as HTMLElement, {
                    toolbar: false,
                    navbar: false,
                    title: false,
                    inheritedAttributes: [
                        'crossOrigin',
                        'decoding',
                        'isMap',
                        'loading',
                        'referrerPolicy',
                        'sizes',
                        'srcset',
                        'useMap',
                    ],
                    minZoomRatio: 0.1,
                    maxZoomRatio: 10,
                })
            )
        }
    }, [currName])

    const duration = 0.25

    const transition = { ease: 'easeOut', duration: duration }

    const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false)

    let current_image_data =
        image_data &&
        tabs[currentTabName()].img_id &&
        image_data[tabs[currentTabName()].img_id]

    const { theme } = useThemes()

    return (
        <WrapperStyled>
            <div {...bind()} style={{ touchAction: 'none', maxHeight: '90vh' }}>
                <Parent>
                    <TabBox
                        animate={{
                            x: tabs.zoe.x_pos,
                        }}
                        style={{
                            x: motionVal_Zoe,

                            width: windowWidth,
                            transform: `translateX(${tabs.zoe.x_pos}px)`,
                            zIndex:
                                tabs.zoe.x_pos == 0
                                    ? 4
                                    : currentDirection === 'forward' &&
                                      tabs.zoe.x_pos === windowWidth
                                    ? 0
                                    : currentDirection === 'backwards' &&
                                      tabs.zoe.x_pos === -windowWidth
                                    ? 0
                                    : 1,
                        }}
                        transition={transition}
                    >
                        <GalleryImageMobile
                            tabName="zoe"
                            image={
                                image_data
                                    ? image_data[tabs.zoe.img_id]
                                    : undefined
                            }
                            onClickZoom={() => {
                                viewer?.show()
                            }}
                        />
                    </TabBox>

                    <TabBox
                        animate={{ x: tabs.leo.x_pos }}
                        style={{
                            // backgroundColor: 'blue',
                            x: motionVal_Leo,
                            width: windowWidth,
                            transform: `translateX(${tabs.leo.x_pos}px)`,
                            zIndex:
                                tabs.leo.x_pos == 0
                                    ? 3
                                    : currentDirection === 'forward' &&
                                      tabs.leo.x_pos === windowWidth
                                    ? 0
                                    : currentDirection === 'backwards' &&
                                      tabs.leo.x_pos === -windowWidth
                                    ? 0
                                    : 1,
                        }}
                        transition={transition}
                    >
                        <GalleryImageMobile
                            tabName="leo"
                            image={
                                image_data
                                    ? image_data[tabs.leo.img_id]
                                    : undefined
                            }
                            onClickZoom={() => {
                                viewer?.show()
                            }}
                        />
                    </TabBox>

                    <TabBox
                        animate={{
                            x: tabs.eve.x_pos,
                        }}
                        style={{
                            x: motionVal_Eve,
                            width: windowWidth,

                            transform: `translateX(${tabs.eve.x_pos}px)`,
                            zIndex:
                                tabs.eve.x_pos == 0
                                    ? 3
                                    : currentDirection === 'forward' &&
                                      tabs.eve.x_pos === windowWidth
                                    ? 0
                                    : currentDirection === 'backwards' &&
                                      tabs.eve.x_pos === -windowWidth
                                    ? 0
                                    : 1,
                        }}
                        transition={transition}
                    >
                        <GalleryImageMobile
                            tabName="eve"
                            image={
                                image_data
                                    ? image_data[tabs.eve.img_id]
                                    : undefined
                            }
                            onClickZoom={() => {
                                viewer?.show()
                            }}
                        />
                    </TabBox>
                </Parent>
            </div>

            {isDescriptionOpen && (
                <Faded>
                    <DescriptionBox>
                        <DescriptionTxt>
                            {current_image_data &&
                            current_image_data.caption &&
                            current_image_data.caption !== ''
                                ? current_image_data.caption
                                : 'No Caption'}
                        </DescriptionTxt>
                        <div style={{ paddingTop: '16px' }} />
                        <DescriptionOwner>
                            {current_image_data &&
                            current_image_data.uploader &&
                            current_image_data.uploader !== ''
                                ? current_image_data.uploader
                                : 'Unknown'}
                        </DescriptionOwner>
                        <div style={{ paddingTop: '10px' }} />
                        <DescriptionDetail>
                            <span style={{ marginRight: 4 }}>©</span>
                            {current_image_data &&
                            current_image_data.credits &&
                            current_image_data.credits !== ''
                                ? current_image_data.credits
                                : 'Unknown'}
                        </DescriptionDetail>
                        <div style={{ paddingTop: '2px' }} />
                        <DescriptionDetail>
                            <div
                                style={{
                                    transform: 'translate(-2px, 1px)',
                                }}
                            >
                                <LocationIcon size="14" />
                            </div>
                            {current_image_data &&
                            current_image_data.location &&
                            current_image_data.location !== ''
                                ? current_image_data.location
                                : 'Unknown'}
                        </DescriptionDetail>
                    </DescriptionBox>
                </Faded>
            )}

            <OptionsBar>
                <div
                    style={{
                        fontFamily: 'Lato',
                        color: colours[theme].text_default,
                        fontSize: '14px',
                        height: '36px',
                        width: '80px',
                        border: !isDescriptionOpen
                            ? `1px solid ${colours[theme].border_muted}`
                            : `2px solid ${colours[theme].border_muted}`,
                        borderRadius: '3px',
                        backgroundColor: isDescriptionOpen
                            ? colours[theme].border_muted
                            : colours[theme].background_neutral_subtle,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={() => {
                        setIsDescriptionOpen(!isDescriptionOpen)
                    }}
                >
                    Caption
                </div>

                <IconsRow>
                    {image_data &&
                        tabs[currentTabName()].img_id &&
                        image_data[tabs[currentTabName()].img_id] &&
                        image_data[tabs[currentTabName()].img_id].featured && (
                            <CustomTagQuickFilterItemMobile
                                bgcolor={colours[theme].primary}
                                isActive={true}
                            >
                                feature
                            </CustomTagQuickFilterItemMobile>
                        )}

                    {image_data &&
                        image_data[tabs[currentTabName()].img_id] &&
                        image_data[tabs[currentTabName()].img_id].id ===
                            props.cover_id && (
                            <CustomTagQuickFilterItemMobile
                                bgcolor={colours[theme].primary}
                                isActive={true}
                            >
                                Profile Image
                            </CustomTagQuickFilterItemMobile>
                        )}

                    <div style={{ paddingLeft: '20px' }} />

                    {props.readOnlyMode !== true && (
                        <ButtonAtom
                            theme="naked"
                            onClick={(e: any) =>
                                openActionsSheet !== undefined &&
                                openActionsSheet()
                            }
                            height={'50px'}
                            width={'50px'}
                        >
                            <div
                                style={{
                                    borderRadius: '5px',
                                    border: `1px solid ${colours[theme].primary}`,
                                    height: '36px',
                                    width: '36px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    transform: 'rotate(90deg)',
                                }}
                            >
                                <Icon
                                    icon="three_dots_vertical"
                                    height="18px"
                                />
                            </div>
                        </ButtonAtom>
                    )}
                </IconsRow>
            </OptionsBar>
        </WrapperStyled>
    )
}
