import React, { useState } from 'react'
import styled from 'styled-components'
import Expander from '../expander/expander'
// import ExpanderWCheckbox from '../../atoms/expanderWCheckbox/expanderWCheckbox'
import { device } from '../../templates/displays/devices'
import { IConfirmationFunctionality, IIconData } from 'expandingListPropsTypes'
// import { Tooltip } from 'antd'
import reactStringReplace from 'react-string-replace'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import ChevronLeft from '../icons/components/chevronLeft'

const ItemStyleWrapper = styled.div<IItemStyle>`
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid var(--border-muted, #e5e5e5);

    position: relative;
    z-index: 2;

    :hover {
        background: var(--off-bg-color, #fafafa);
    }
`

const FunctionalIcon = styled.div`
    margin-right: 30px;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    height: 100%;
    display: flex;
`

const TextSection = styled.div`
    width: 100%;
    box-sizing: border-box;
    font-family: 'Lato-light';
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 20px 30px;
    cursor: pointer;

    @media ${device.desktop} {
        font-size: 18px;
    }

    @media ${device.ipad} {
        font-size: 16px;
    }
`

const SmallDash = styled.div`
    width: 20px;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    margin-left: 30px;
`

const AnswerSection = styled.div`
    width: 100%;
    box-sizing: border-box;
    font-family: 'Lato';
    font-weight: 400;
    padding: 20px 30px 30px 30px;

    @media ${device.desktop} {
        font-size: 16px;
    }

    @media ${device.mobile} {
        font-size: 14px;
    }
`

const LinksSection = styled.div`
    padding: 0px 30px 20px 30px;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .more {
        color: var(--text-strong, #1a1a1a);
        font-family: 'Lato-light';
        cursor: default;
    }

    .linksIndent {
        padding-left: 30px;
    }
`
const LinksList = styled.div`
    display: flex;
    flex-direction: column;

    .linksInList {
        margin-top: -15px;
    }
`

const MoreText = styled.span`
    font-family: Lato;
    font-style: italic;
    font-weight: 200;
    font-size: 14px;
    color: var(--text-strong, #1a1a1a);
`
const LinkText = styled.a`
    font-family: Lato;
    font-style: italic;
    font-weight: 200;
    font-size: 14px;
    color: var(--primary, #5ec3ca) !important;
`
const ReadText = styled.div`
    font-family: Lato-Light;
    font-size: 13px;
    color: var(--text-strong, #1a1a1a);
    padding-right: 10px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const DateFinished = styled.span`
    color: var(--text-strong, #1a1a1a);
    padding-left: 20px;

    @media ${device.desktop} {
        font-size: 14px;
        height: 16px;
    }

    @media ${device.ipad} {
        font-size: 12px;
        height: 13px;
    }
`

const ReadMoreZone = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0px;
    left: 90px;
`
interface IItemStyle {
    isDark: boolean
}

interface IItemProps {
    id: string
    text: string
    dateFinished?: string
    answer: string
    links?: string[]
    iconData?: IIconData
    confirmationFunctionality?: IConfirmationFunctionality
    theme?: 'upvote' | 'check'
}

// interface IUpvoteThemeProps {
//     iconData?: IIconData
//     confirmationFunctionality?: IConfirmationFunctionality
//     isConfirmationOpen: boolean
//     setIsConfirmationOpen?: any
// }

interface ICheckThemeProps {
    iconData?: IIconData
}

// const UpvoteThemePart1: React.SFC<IUpvoteThemeProps> = (props) => {
//     const [showTooltip, setShowTooltip] = useState(false)
//     return (
//         <>
//             {props.iconData &&
//                 (props.confirmationFunctionality && props.isConfirmationOpen ? (
//                     <Voted>Voted!</Voted>
//                 ) : (
//                     <>
//                         <Tooltip
//                             title={'upvote'}
//                             visible={showTooltip}
//                             placement="left"
//                             color="#5EC3CA"
//                         />
//                         <FunctionalIcon
//                             onMouseEnter={() => setShowTooltip(true)}
//                             onMouseLeave={() => setShowTooltip(false)}
//                             src={props.iconData.icon}
//                             onClick={
//                                 /*props.iconData.onClick*/ () =>
//                                     props.setIsConfirmationOpen(
//                                         !props.isConfirmationOpen
//                                     )
//                             }
//                         />
//                     </>
//                 ))}

//             <div />
//         </>
//     )
// }

// const UpvoteThemePart2: React.SFC<IUpvoteThemeProps> = (props) => {
//     return (
//         <>
//             {props.confirmationFunctionality && (
//                 <ConfirmationContainer>
//                     <ExpanderWCheckbox
//                         text={props.confirmationFunctionality.text}
//                         onChange={props.confirmationFunctionality.onClick}
//                         isOpen={props.isConfirmationOpen}
//                         bgColor="#00adb3"
//                     />
//                 </ConfirmationContainer>
//             )}
//         </>
//     )
// }

type Props2 = {
    size?: number
}

export const CheckIconListItem = (props2: Props2) => {
    const { theme } = useThemes()
    return (
        <svg
            width={props2.size ?? '32'}
            height={props2.size ?? '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="32"
                height="32"
                rx="4"
                fill={colours[theme].primary_04}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.0604 12.4739L14.3334 20.5873L9.9397 15.9739L11.0259 14.9395L14.3334 18.4123L20.9742 11.4395L22.0604 12.4739Z"
                fill={colours[theme].primary}
            />
        </svg>
    )
}

const CheckTheme = (props: ICheckThemeProps) => {
    return (
        <FunctionalIcon>
            <CheckIconListItem />
        </FunctionalIcon>
    )
}

const ExpandingListItemDesktop = (props: IItemProps) => {
    const [isOpen, setIsOpen] = useState(false)
    // const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

    let replaceAnswer: string | React.ReactNode[] = props.answer

    replaceAnswer = reactStringReplace(
        replaceAnswer,
        /(https?:\/\/[^\s]+)/g,
        (match, i) => (
            <a href={match} key={i} target="_blank" rel="noreferrer">
                {match}
            </a>
        )
    )

    replaceAnswer = reactStringReplace(
        replaceAnswer,
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
        (match, i) => (
            <a
                href={`mailto:${match}?subject=Help`}
                key={i}
                target="_blank"
                rel="noreferrer"
            >
                {match}
            </a>
        )
    )

    const { theme } = useThemes()

    return (
        <React.Fragment>
            <ItemStyleWrapper isDark={!isOpen} key={props.id}>
                <TextSection>
                    <Row>
                        {/* {props.theme === 'upvote' && (
                            <UpvoteThemePart1
                                iconData={props.iconData}
                                setIsConfirmationOpen={setIsConfirmationOpen}
                                isConfirmationOpen={isConfirmationOpen}
                                confirmationFunctionality={
                                    props.confirmationFunctionality
                                }
                            />
                        )} */}

                        {props.theme === 'check' && <CheckTheme />}

                        <div style={{ color: colours[theme].text_strong }}>
                            {props.text}
                        </div>

                        {props.theme === 'check' && (
                            <DateFinished>{props.dateFinished}</DateFinished>
                        )}
                    </Row>

                    <ReadMoreZone onClick={() => setIsOpen(!isOpen)} />
                    <Row>
                        <ReadText>
                            {isOpen ? 'read less' : 'read more'}
                        </ReadText>
                        <div
                            style={{
                                transform: `${
                                    isOpen
                                        ? 'rotate(270deg)'
                                        : 'rotate(180deg) translateY(3px)'
                                }`,
                                transition: 'all 100ms',
                            }}
                        >
                            <ChevronLeft />
                        </div>
                    </Row>
                </TextSection>

                <Expander height={isOpen ? 'auto' : 0}>
                    <SmallDash />
                    <AnswerSection>
                        <div style={{ color: colours[theme].text_strong }}>
                            {replaceAnswer}
                        </div>
                    </AnswerSection>
                    <LinksSection>
                        {props.links ? (
                            props.links.length > 1 ? (
                                <LinksList>
                                    <MoreText>More on: </MoreText>
                                    <ul className="linksIndent">
                                        {props.links.map((link, i) => (
                                            <li
                                                className="linksInList"
                                                key={`links_${link}_${i}_desktop`}
                                            >
                                                <LinkText href={link}>
                                                    {link}
                                                </LinkText>
                                            </li>
                                        ))}
                                    </ul>
                                </LinksList>
                            ) : (
                                <React.Fragment>
                                    <MoreText>More on &nbsp;</MoreText>
                                    <LinkText href={props.links[0]}>
                                        {props.links[0]}
                                    </LinkText>
                                </React.Fragment>
                            )
                        ) : null}
                    </LinksSection>
                </Expander>
            </ItemStyleWrapper>
            {/* {props.theme === 'upvote' && (
                <UpvoteThemePart2
                    iconData={props.iconData}
                    setIsConfirmationOpen={setIsConfirmationOpen}
                    isConfirmationOpen={isConfirmationOpen}
                    confirmationFunctionality={props.confirmationFunctionality}
                />
            )} */}
        </React.Fragment>
    )
}

export default ExpandingListItemDesktop
