import React, { useState, useCallback } from 'react'
import Fuse from 'fuse.js'
import { debounce } from 'lodash'

import './styles.css'
import TimelineSearchBarMobileChild from './timelineSearchBarMobileChild'

export type Props = {
    category_searchable_items_list: any
    user_searchable_labels_list: any
    searchFor?: any
    addTagFilter?: any
    addCategoryFilter?: any
    addCriteria?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    clearEntryTitleSearch: any
    finalSearchToApi: any
    currentSearch: string | undefined
    checkIfSelectedEntryOrLabel: any
}

const TimelineSearchBarMobile: React.FC<Props> = ({
    category_searchable_items_list,
    user_searchable_labels_list,
    dataCyId,
    manageissearchactive,
    addCriteria,
    issearchactive,
    clearEntryTitleSearch,
    finalSearchToApi,
    currentSearch,
    checkIfSelectedEntryOrLabel,
}) => {
    // const [data, setData] = useState<any[]>([])
    const [categoryData, setCategoryData] = useState<any[]>([])
    const [labelsData, setLabelsData] = useState<any[]>([])
    const [name, setName] = useState<any[]>([])
    // const [fetching, setFetching] = useState<boolean>(false)
    const [lastFetchId, setLastFetchId] = useState<number>(0)

    const fetchItem = useCallback(
        debounce((name: any) => {
            setLastFetchId((prevId) => prevId + 1)
            const fetchId = lastFetchId + 1
            // setData([])
            // setFetching(true)

            if (fetchId !== lastFetchId + 1) {
                return
            }

            const fuseCategory = new Fuse(category_searchable_items_list, {
                keys: ['name'],
            })

            const resultsCategory = fuseCategory.search(name, { limit: 2 })
            setCategoryData(resultsCategory)
            // setFetching(false)

            const fuseLabels = new Fuse(user_searchable_labels_list, {
                keys: ['name'],
            })

            const resultsLabel = fuseLabels.search(name, { limit: 2 })
            setLabelsData(resultsLabel)
            // setFetching(false)
        }, 180),
        [
            lastFetchId,
            category_searchable_items_list,
            user_searchable_labels_list,
        ]
    )

    const handleChange = (name: any) => {
        setName([name])
        // setData([])
        setCategoryData([])
        setLabelsData([])
        // setFetching(false)
    }

    return (
        <TimelineSearchBarMobileChild
            manageissearchactive={manageissearchactive}
            dataCyId={dataCyId}
            issearchactive={issearchactive}
            value={name}
            handleChange={handleChange}
            fetchItem={fetchItem}
            categoryData={categoryData}
            labelsData={labelsData}
            clearEntryTitleSearch={clearEntryTitleSearch}
            addCriteria={(name: string) => addCriteria(name)}
            finalSearchToApi={finalSearchToApi}
            currentSearch={currentSearch}
            checkIfSelectedEntryOrLabel={checkIfSelectedEntryOrLabel}
        />
    )
}

export default TimelineSearchBarMobile
