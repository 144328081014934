import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import ListManagerDesktop from '../../molecules/list/ListManagerDesktop'
import styled from 'styled-components'
import ListManagerMobile from '../../molecules/list/listManagerMobile'
import SideSlider from '../../templates/animated/sideSlider'
import { IList } from 'entityModels'
import InfoBarMobile from '../../atoms/info/infoBarMobile'
import { editFormsActions } from '../../../redux/editForms/reducer'
import Faded from '../../templates/animated/faded'
import TechInfoFormManagerDesktop from '../../organisms/editForms/technicalInformationEditForm/manager/techInfoFormManagerDesktop'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import TechInfoFormManagerMobile from '../../organisms/editForms/technicalInformationEditForm/manager/techInfoFormManagerMobile'
import TutorialConfigsTechInfoMobile from '../../molecules/tutorialConfigs/techinfo/tutorialConfigsTechInfoMobile'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { device } from '../../templates/displays/devices'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { ICar } from '../../../redux/entities/cars/types'

const Padding = styled.div`
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    box-sizing: border-box;
`

const IpadAndMobileWrapper = styled.div`
    z-index: 3;
    background-color: var(--bg-color, #fff);
    padding-top: 70px;
    width: 100%;
    box-sizing: border-box;
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

const TechnicalInformation: React.FC = () => {
    const [id, setId] = useState('')
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const technical_information = useAppSelector(
        (state) => state.entities.technicalInformationData.technical_information
    )
    const isLoading = useAppSelector(
        (state) => state.entities.technicalInformationData.loading
    )
    const isCarTechInfoEditFormOpened = useAppSelector(
        (state) => state.editForms.formsData.carTechInfoEditForm.isOpen
    )
    const item_to_edit = useAppSelector(
        (state) => state.editForms.formsData.carTechInfoEditForm.item
    )
    const list = useAppSelector(
        (state) => state.entities.technicalInformationData.list
    )
    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const currentcarid = useAppSelector(
        (state) => state.entities.carsData.currentCarID
    )

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/car/${carid}/technical-information`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        dispatch(carActions.setCurrentCar(`${carid}`))

        const newId = `hf-${carid}`
        setId(newId)

        if (userLoggedIn) {
            if (
                !carsData[`${carid}`] ||
                (carsData[`${carid}`] &&
                    carsData[`${carid}`].has_limited_garage_info === true)
            ) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            }
        }

        const search = location.search
        const formId = new URLSearchParams(search).get('form_id')

        if (formId === 'makeModelYear') {
            dispatch(
                editFormsActions.toggleCarTechInfoEditForm({
                    isOpen: true,
                    item: {
                        id: 'make',
                        text: 'make',
                    },
                })
            )
        }

        if (formId === 'model') {
            dispatch(
                editFormsActions.toggleCarTechInfoEditForm({
                    isOpen: true,
                    item: {
                        id: 'model',
                        text: 'model',
                    },
                })
            )
        }
    }, [carid, userLoggedIn, location.search, carsData])

    const toggleCarTechInfoEditForm = (payload: {
        isOpen: boolean
        item?: IList
    }) => {
        dispatch(editFormsActions.toggleCarTechInfoEditForm(payload))
    }

    const params = new URLSearchParams(location.search)

    const user_fuel_units_data =
        userLoggedIn?.preferences?.data?.['units']?.data?.['fuel_unit']
    const user_distance_units_data =
        userLoggedIn?.preferences?.data?.['units']?.data?.['distance_unit']

    const default_fuel_unit_user: string | null =
        user_fuel_units_data?.user_choice_id || null
    const default_fuel_unit_user_text: string | null =
        (default_fuel_unit_user &&
            user_fuel_units_data?.data?.[default_fuel_unit_user]?.text) ||
        null
    const default_fuel_unit_user_text_short: string | null | undefined =
        default_fuel_unit_user &&
        user_fuel_units_data?.data?.[default_fuel_unit_user]?.short

    const default_distance_unit_user: string | null =
        user_distance_units_data?.user_choice_id || null
    const default_distance_unit_user_text: string | null =
        (default_distance_unit_user &&
            user_distance_units_data?.data?.[default_distance_unit_user]
                ?.text) ||
        null
    const default_distance_unit_user_text_short: string | null | undefined =
        default_distance_unit_user &&
        user_distance_units_data?.data?.[default_distance_unit_user]?.short

    const current: ICar | undefined =
        carsData && currentcarid && carsData[currentcarid]
            ? carsData[currentcarid]
            : undefined

    if (current?.archived_on) {
        navigate(`/archives/car/${current.id}/technical-information`)
        return null
    }

    return (
        <FullPageWrapper>
            <FabsMenusMobile hasAdd={false} hasSubNav={true} />

            {params.get('tutorial') === 'true' && (
                <TutorialConfigsTechInfoMobile />
            )}

            <DesktopDisplayOnly>
                <Padding>
                    {!isLoading && technical_information[id] !== undefined && (
                        <ListManagerDesktop
                            toggle={(item: IList) =>
                                toggleCarTechInfoEditForm({
                                    item: item,
                                    isOpen: true,
                                })
                            }
                            data={technical_information[id]}
                            list={list}
                            default_fuel_unit_user_text={
                                default_fuel_unit_user_text_short
                                    ? default_fuel_unit_user_text_short
                                    : default_fuel_unit_user_text
                            }
                            default_fuel_unit_user={default_fuel_unit_user}
                            default_distance_unit_user_text={
                                default_distance_unit_user_text_short ||
                                default_distance_unit_user_text
                            }
                            default_distance_unit_user={
                                default_distance_unit_user
                            }
                        />
                    )}
                </Padding>

                {isCarTechInfoEditFormOpened &&
                    technical_information[id] !== undefined &&
                    currentcarid && (
                        <Faded>
                            <ModalDisplay
                                toggle={() =>
                                    toggleCarTechInfoEditForm({
                                        isOpen: false,
                                    })
                                }
                                isOpen={true}
                            >
                                <TechInfoFormManagerDesktop
                                    id={id}
                                    item={item_to_edit}
                                    toggle={() =>
                                        toggleCarTechInfoEditForm({
                                            isOpen: false,
                                        })
                                    }
                                    allTechInfodata={technical_information[id]}
                                    carid={currentcarid}
                                />
                            </ModalDisplay>
                        </Faded>
                    )}
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <IpadAndMobileWrapper>
                    <div data-tut="tech-info-tuto-mobile">
                        <InfoBarMobile>
                            Please tap the field you wish to edit
                        </InfoBarMobile>
                    </div>

                    <SideSlider direction="right" initial="enter">
                        {!isLoading &&
                            technical_information &&
                            id &&
                            technical_information[id] !== undefined && (
                                <ListManagerMobile
                                    toggle={(item: IList) =>
                                        toggleCarTechInfoEditForm({
                                            item: item,
                                            isOpen: true,
                                        })
                                    }
                                    data={technical_information[id]}
                                    list={list}
                                    default_fuel_unit_user_text={
                                        default_fuel_unit_user_text_short ||
                                        default_fuel_unit_user_text
                                    }
                                    default_fuel_unit_user={
                                        default_fuel_unit_user
                                    }
                                    default_distance_unit_user_text={
                                        default_distance_unit_user_text_short ||
                                        default_distance_unit_user_text
                                    }
                                    default_distance_unit_user={
                                        default_distance_unit_user
                                    }
                                />
                            )}
                    </SideSlider>

                    {isCarTechInfoEditFormOpened &&
                        technical_information &&
                        id &&
                        technical_information[id] !== undefined &&
                        currentcarid && (
                            <Faded>
                                <ModalDisplay
                                    toggle={() =>
                                        toggleCarTechInfoEditForm({
                                            isOpen: false,
                                        })
                                    }
                                    isOpen={true}
                                >
                                    <TechInfoFormManagerMobile
                                        id={id}
                                        item={item_to_edit}
                                        toggle={() =>
                                            toggleCarTechInfoEditForm({
                                                isOpen: false,
                                            })
                                        }
                                        allTechInfodata={
                                            technical_information[id]
                                        }
                                        carid={currentcarid}
                                    />
                                </ModalDisplay>
                            </Faded>
                        )}
                </IpadAndMobileWrapper>
            </IpadAndMobileDisplay>
        </FullPageWrapper>
    )
}

export default TechnicalInformation
