import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IFilterGarageSharesDisplay_change_success,
    IPublicShareSuccessPayloadRedux,
    ISortGarageSharesDisplay_change_success,
} from './actions/actions'

import {
    IFilterGarageSharesDisplay_change_request,
    ISortGarageSharesDisplay_change_request,
} from './actions/loadingActions'
import {
    ICreateShareInviteRequestPayload,
    IDeleteShareReq,
    IDirectShareSuccessPayloadRedux,
    IEntitySharesOwnedDataArg,
    IgetSingleDirectShareRequest_redux,
    IShareInviteDeletion,
    IShareInvitResent,
    IShareRecipientRequest_redux,
    IShareRecipientRequestDeletion_success,
} from '../cars/actions'
import {
    IReduxError,
    IPublicShare,
    IDirectSharesSuccessPayload_redux_arg,
    ICreateDirectShareRequest,
    IDirectShareOwnedItemsData,
    IDirectShareOwnedItem,
    ISortShareRecipientsDisplay_change_request,
    ISortShareRecipientsDisplay_change_success,
} from '../cars/types'
import {
    IGarageState,
    IGarage,
    ITaskGarageIdsPayloads,
    IAddCarToGarageState,
    IExternalGarageObject,
    IUpdateGarageDirectShareSuccess,
    IUpdateGarageDirectShareRequest,
    ISetGarageActiveSort,
    IGarageActiveSort,
} from './types'

const initialState: IGarageState = {
    garages: {},
    directly_shared_external_garages: {},
    error: null,
    loading: false,
    sharesLoading: false,
    tasks: {},
    cars_per_page: 10,
    current_page_number: 0,
    total_cars_number_found: undefined,
}

const garagesSlice = createSlice({
    name: 'garages',
    initialState,
    reducers: {
        setGarageDataRequest: (state) => {
            state.loading = true
            state.error = null
        },
        setGarageDataError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        setCurrentGaragePageNumber: (state, action: PayloadAction<number>) => {
            state.current_page_number = action.payload
        },
        setTotalCarsNumberFound: (state, action: PayloadAction<number>) => {
            state.total_cars_number_found = action.payload
        },
        setGarageDataSuccess: (state, action: PayloadAction<IGarage>) => {
            state.garages[action.payload.uid] = action.payload
            state.loading = false
            state.error = null
        },
        getGarageByUserIdRequest: (state) => {
            state.loading = true
            state.error = null
        },
        getGarageByUserIdError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        getGarageByUserIdSuccess: (state, action: PayloadAction<IGarage>) => {
            state.garages[action.payload.uid] = action.payload
            state.loading = false
            state.error = null
        },
        setGarageTaskIdsRequest: (state) => {
            state.loading = true
            state.error = null
        },
        setGarageTaskIdsError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        setGarageTaskIdsSuccess: (
            state,
            action: PayloadAction<ITaskGarageIdsPayloads>
        ) => {
            state.loading = false
            state.error = null
            state.tasks = {
                ...state.tasks,
                ...action.payload.tasks_normalised_by_garage,
            }
            if (state.garages[action.payload.garage_id]) {
                state.garages[action.payload.garage_id].tasks_garage_level =
                    action.payload.tasks_ids_garage_level_only_list
            }
        },

        deleteGarageDirectShareRequest: (
            state,
            action: PayloadAction<IgetSingleDirectShareRequest_redux>
        ) => {
            state.loading = true
            state.error = null
        },

        addCarToGarageRequest: (state) => {
            state.loading = true
            state.error = null
        },
        addACarToGarageError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        addACarToGarageSuccess: (
            state,
            action: PayloadAction<IAddCarToGarageState>
        ) => {
            state.loading = false
            state.error = null

            const garage = state.garages[action.payload.garage_id]
            if (garage) {
                const cars = garage.cars
                    ? [action.payload.car_id, ...garage.cars].filter(
                          (car_id) => car_id !== 'new_car'
                      )
                    : [action.payload.car_id]

                state.garages[action.payload.garage_id] = {
                    ...garage,
                    cars,
                }
            } else {
                state.garages[action.payload.garage_id] = {
                    uid: action.payload.garage_id,
                    cars: [action.payload.car_id],
                }
            }
        },
        getPublicSharesGarageRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.sharesLoading = true
            state.error = null
        },
        getPublicSharesGarageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.sharesLoading = false
            state.error = action.payload
        },
        getPublicSharesGarageSuccess: (
            state,
            action: PayloadAction<IPublicShareSuccessPayloadRedux>
        ) => {
            let hasNoShares =
                Object.keys(action.payload.public_share).length === 0
                    ? true
                    : false
            let share: IPublicShare | undefined =
                hasNoShares === true
                    ? undefined
                    : { ...action.payload.public_share }

            state.sharesLoading = false
            state.error = null
            state.garages = {
                ...state.garages,
                [action.payload.id]: {
                    ...state.garages[action.payload.id],
                    public_share_owned: share,
                },
            }
        },
        createGaragePublicShareRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.sharesLoading = true
            state.error = null
        },
        createGaragePublicShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.sharesLoading = false
            state.error = action.payload
        },
        createGaragePublicShareSuccess: (
            state,
            action: PayloadAction<IPublicShareSuccessPayloadRedux>
        ) => {
            state.loading = false
            state.sharesLoading = false
            state.error = null

            if (state.garages[action.payload.id]) {
                state.garages[action.payload.id].public_share_owned =
                    action.payload.public_share
            }
        },

        deleteGaragePublicShareRequest: (
            state,
            action: PayloadAction<IDeleteShareReq>
        ) => {
            state.loading = false
            state.sharesLoading = true
            state.error = null
        },
        deleteGaragePublicShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.sharesLoading = false
            state.error = action.payload
        },
        deleteGaragePublicShareSuccess: (
            state,
            action: PayloadAction<IDeleteShareReq>
        ) => {
            state.garages[action.payload.entity_id] = {
                ...state.garages[action.payload.entity_id],
                public_share_owned: undefined,
            }

            state.loading = false
            state.sharesLoading = false
            state.error = null
        },

        filterGarageSharesDisplay_change_request: (
            state,
            action: PayloadAction<IFilterGarageSharesDisplay_change_request>
        ) => {
            state.garages[action.payload.garageid] = {
                ...state.garages[action.payload.garageid],
                private_shares_owned: {
                    ...state.garages[action.payload.garageid]
                        .private_shares_owned!,
                    active_display: {
                        ...state.garages[action.payload.garageid]
                            .private_shares_owned?.active_display!,
                        isLoading: true,
                    },
                },
            }

            state.loading = false
            state.sharesLoading = false
            state.error = null
        },

        sortGarageSharesDisplay_change_request: (
            state,
            action: PayloadAction<ISortGarageSharesDisplay_change_request>
        ) => {
            state.garages[action.payload.garageid] = {
                ...state.garages[action.payload.garageid],
                private_shares_owned: {
                    ...state.garages[action.payload.garageid]
                        .private_shares_owned!,
                    active_display: {
                        ...state.garages[action.payload.garageid]
                            .private_shares_owned?.active_display!,
                        isLoading: true,
                    },
                },
            }

            state.loading = false
            state.sharesLoading = false
            state.error = null
        },
        sortGarageSharesDisplay_change_success: (
            state,
            action: PayloadAction<ISortGarageSharesDisplay_change_success>
        ) => {
            state.garages[action.payload.garageid] = {
                ...state.garages[action.payload.garageid],
                private_shares_owned: {
                    ...state.garages[action.payload.garageid]
                        .private_shares_owned!,
                    active_display: {
                        ...state.garages[action.payload.garageid]
                            .private_shares_owned?.active_display!,
                        activeSort: action.payload.sortID,
                        item_ids_display: [...action.payload.item_ids_display],
                        isLoading: false,
                    },
                },
            }

            state.loading = false
            state.sharesLoading = false
            state.error = null
        },
        filterGarageSharesDisplay_change_success: (
            state,
            action: PayloadAction<IFilterGarageSharesDisplay_change_success>
        ) => {
            state.garages[action.payload.garageid] = {
                ...state.garages[action.payload.garageid],
                private_shares_owned: {
                    ...state.garages[action.payload.garageid]
                        .private_shares_owned!,
                    active_display: {
                        ...state.garages[action.payload.garageid]
                            .private_shares_owned?.active_display!,
                        activeFilter: action.payload.filterIDs,
                        item_ids_display: [...action.payload.item_ids_display],
                        isLoading: false,
                    },
                },
            }

            state.loading = false
            state.sharesLoading = false
            state.error = null
        },
        getExternalGaragesSharedWithYouRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.loading = true
            state.error = null
        },

        getExternalGaragesSharedWithYouSuccess: (
            state,
            action: PayloadAction<IExternalGarageObject>
        ) => {
            state.directly_shared_external_garages = {
                ...state.directly_shared_external_garages,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },
        getExternalGaragesSharedWithYouError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        setCurrentExternalGarageSuccess: (
            state,
            action: PayloadAction<string>
        ) => {
            state.directly_shared_current_external_garage_id = action.payload
        },
        populateSingleGarageAllOwnedSharesSuccess: (
            state,
            action: PayloadAction<IEntitySharesOwnedDataArg>
        ) => {
            state.loading = false
            state.sharesLoading = false
            state.error = null

            if (state.garages[action.payload.entity_id]) {
                state.garages[action.payload.entity_id] = {
                    ...state.garages[action.payload.entity_id],
                    public_share_owned: {
                        ...action.payload.owned_shares_obj.public_share_owned,
                    },
                    // @ts-ignore
                    private_shares_owned: {
                        ...action.payload.owned_shares_obj.private_shares_owned,
                    },
                }
            }
        },
        getOwnedDirectSharesGarageRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.loading = false
            state.sharesLoading = true
            state.error = null
            // state.hasFetchedGarageDirectSharesOwnedOnce = true
        },
        getOwnedDirectSharesGarageSuccess: (
            state,
            action: PayloadAction<IDirectSharesSuccessPayload_redux_arg>
        ) => {
            state.loading = false
            state.error = null

            if (state.garages[action.payload.id]) {
                state.garages[action.payload.id].private_shares_owned =
                    action.payload.private_shares_owned
            }
        },
        getOwnedDirectSharesGarageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        createGarageDirectShareRequest: (
            state,
            action: PayloadAction<ICreateDirectShareRequest>
        ) => {
            state.sharesLoading = true
            state.error = null
        },

        createGarageDirectShareSuccess: (
            state,
            action: PayloadAction<IDirectSharesSuccessPayload_redux_arg>
        ) => {
            state.loading = false
            state.error = null

            if (state.garages[action.payload.id]) {
                state.garages[action.payload.id].private_shares_owned =
                    action.payload.private_shares_owned
            }
        },
        createGarageDirectShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },
        updateGarageDirectShareSuccess: (
            state,
            action: PayloadAction<IUpdateGarageDirectShareSuccess>
        ) => {
            let sharing_data_for_update: IDirectShareOwnedItemsData =
                state.garages[action.payload.garage_id] &&
                state.garages[action.payload.garage_id].private_shares_owned &&
                state.garages[action.payload.garage_id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.garages[action.payload.garage_id]
                          .private_shares_owned!.entity_owned_direct_shares_data
                    : {}

            state.garages[action.payload.garage_id] = {
                ...state.garages[action.payload.garage_id],
                private_shares_owned: {
                    ...state.garages[action.payload.garage_id]
                        .private_shares_owned!,
                    entity_owned_direct_shares_data: {
                        ...sharing_data_for_update,
                        [action.payload.updated_share.id]: {
                            ...action.payload.updated_share,
                        },
                    },
                },
            }

            state.sharesLoading = false
            state.error = null
        },
        updateGarageDirectShareRequest: (
            state,
            action: PayloadAction<IUpdateGarageDirectShareRequest>
        ) => {
            state.sharesLoading = true
            state.error = null
        },

        updateGarageDirectShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.sharesLoading = false
            state.error = action.payload
        },

        getSingleGarageDirectShareSuccess: (
            state,
            action: PayloadAction<IDirectShareSuccessPayloadRedux>
        ) => {
            const sharing_ids_2 =
                state.garages[action.payload.id]?.private_shares_owned
                    ?.entity_owned_direct_shares_ids || []

            const new_sharing_ids = sharing_ids_2.includes(
                action.payload.direct_share.id
            )
                ? [...sharing_ids_2]
                : [...sharing_ids_2, action.payload.direct_share.id]

            const sharing_data_2 =
                state.garages[action.payload.id]?.private_shares_owned
                    ?.entity_owned_direct_shares_data || {}

            if (state.garages[action.payload.id]) {
                state.garages[action.payload.id].private_shares_owned = {
                    entity_owned_direct_shares_ids: new_sharing_ids,
                    entity_owned_direct_shares_data: {
                        ...sharing_data_2,
                        [action.payload.direct_share.id]: {
                            ...action.payload.direct_share,
                        },
                    },
                }
            }
            state.loading = false
            state.error = null
        },
        deleteDirectGarageShareSuccess: (
            state,
            action: PayloadAction<IDeleteShareReq>
        ) => {
            let garage_share_ids_filtered: string[] =
                state.garages[action.payload.entity_id].private_shares_owned &&
                state.garages[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_ids
                    ? state.garages[
                          action.payload.entity_id
                      ].private_shares_owned!.entity_owned_direct_shares_ids.filter(
                          (id: string) => id !== action.payload.share_id
                      )
                    : []

            state.garages[action.payload.entity_id] = {
                ...state.garages[action.payload.entity_id],
                private_shares_owned: {
                    ...state.garages[action.payload.entity_id]
                        .private_shares_owned!,
                    entity_owned_direct_shares_ids: [
                        ...garage_share_ids_filtered,
                    ],
                },
            }

            state.loading = false
            state.sharesLoading = false
            state.error = null
        },

        getSingleGarageDirectShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.sharesLoading = false
            state.error = action.payload
        },

        getSingleGarageDirectShareRequest: (
            state,
            action: PayloadAction<IgetSingleDirectShareRequest_redux>
        ) => {
            state.sharesLoading = true
            state.error = null
        },
        deleteDirectGarageShareError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        addGarageShareRecipientRequest: (
            state,
            action: PayloadAction<IShareRecipientRequest_redux>
        ) => {
            state.loading = false
            state.sharesLoading = true
            state.error = null
        },
        addGarageShareRecipientError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.sharesLoading = false
        },
        addGarageShareRecipientSuccess: (state) => {
            state.sharesLoading = false
            state.error = null
        },
        deleteGarageShareRecipientRequest: (
            state,
            action: PayloadAction<IShareRecipientRequest_redux>
        ) => {
            state.loading = false
            state.sharesLoading = true
            state.error = null
        },
        deleteGarageShareRecipientError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.sharesLoading = false
            state.error = action.payload
        },

        deleteGarageShareRecipientSuccess: (
            state,
            action: PayloadAction<IShareRecipientRequestDeletion_success>
        ) => {
            let current_share_2: IDirectShareOwnedItem =
                state.garages[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_data[action.payload.share_id]

            let current_share_recipients_filtered =
                current_share_2.all_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.recipient_id
                )

            let currentSubData: IDirectShareOwnedItemsData =
                state.garages[action.payload.entity_id].private_shares_owned &&
                state.garages[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.garages[action.payload.entity_id]
                          .private_shares_owned!.entity_owned_direct_shares_data
                    : {}

            if (state.garages[action.payload.entity_id].private_shares_owned) {
                state.garages[action.payload.entity_id] = {
                    ...state.garages[action.payload.entity_id],
                    private_shares_owned: {
                        ...state.garages[action.payload.entity_id]
                            .private_shares_owned!,
                        entity_owned_direct_shares_data: {
                            ...currentSubData,
                            [action.payload.share_id]: {
                                ...current_share_2!,
                                all_share_recipients_ids_list: [
                                    ...current_share_recipients_filtered,
                                ],
                                active_display: {
                                    activeSort: 'added_at',
                                    item_ids_display: [
                                        ...current_share_recipients_filtered,
                                    ],
                                },
                            },
                        },
                    },
                }
            }

            state.loading = false
            state.sharesLoading = false
            state.error = null
        },

        sortGarageShareRecipientsDisplay_change_request: (
            state,
            action: PayloadAction<ISortShareRecipientsDisplay_change_request>
        ) => {
            state.sharesLoading = true
            state.error = null
            state.garages[action.payload.entityid] = {
                ...state.garages[action.payload.entityid],
                private_shares_owned: {
                    ...state.garages[action.payload.entityid]
                        .private_shares_owned!,
                    entity_owned_direct_shares_data: {
                        ...state.garages[action.payload.entityid]
                            .private_shares_owned
                            ?.entity_owned_direct_shares_data!,
                        [action.payload.share.id]: {
                            ...state.garages[action.payload.entityid]
                                .private_shares_owned
                                ?.entity_owned_direct_shares_data[
                                action.payload.share.id
                            ]!,
                            active_display: {
                                ...state.garages[action.payload.entityid]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data[
                                    action.payload.share.id
                                ].active_display!,
                                isLoading: true,
                            },
                        },
                    },
                },
            }
        },
        sortGarageShareRecipientsDisplay_change_success: (
            state,
            action: PayloadAction<ISortShareRecipientsDisplay_change_success>
        ) => {
            state.sharesLoading = false
            state.error = null
            state.garages[action.payload.entityId] = {
                ...state.garages[action.payload.entityId],
                private_shares_owned: {
                    ...state.garages[action.payload.entityId]
                        .private_shares_owned!,
                    entity_owned_direct_shares_data: {
                        ...state.garages[action.payload.entityId]
                            .private_shares_owned
                            ?.entity_owned_direct_shares_data!,
                        [action.payload.shareid]: {
                            ...state.garages[action.payload.entityId]
                                .private_shares_owned
                                ?.entity_owned_direct_shares_data[
                                action.payload.shareid
                            ]!,
                            active_display: {
                                ...state.garages[action.payload.entityId]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data[
                                    action.payload.shareid
                                ].active_display,
                                activeSort: action.payload.sortID,
                                item_ids_display: [
                                    ...action.payload.item_ids_display,
                                ],
                                isLoading: false,
                            },
                        },
                    },
                },
            }
        },
        createGarageShareInviteRequest: (
            state,
            action: PayloadAction<ICreateShareInviteRequestPayload>
        ) => {
            state.loading = true
            state.error = null
        },

        createGarageShareInviteSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        createGarageShareInviteError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        resendGarageShareInviteRequest: (
            state,
            action: PayloadAction<IShareInvitResent>
        ) => {
            state.loading = true
            state.error = null
        },
        resendGarageShareInviteSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        resendGarageShareInviteError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // ACCEPT GARAGE SHARE INVITE
        acceptGarageShareInviteRequest: (
            state,
            action: PayloadAction<{
                invite_code: string
                next_path: string
            }>
        ) => {
            state.loading = true
            state.error = null
        },

        acceptGarageShareInviteSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        acceptGarageShareInviteError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        deleteGarageShareInviteRequest: (
            state,
            action: PayloadAction<IShareInviteDeletion>
        ) => {
            state.loading = true
            state.error = null
        },
        deleteGarageShareInviteSuccess: (
            state,
            action: PayloadAction<IShareInviteDeletion>
        ) => {
            state.loading = false
            state.error = null

            let current_share_for_invites: IDirectShareOwnedItem =
                state.garages[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_data[action.payload.share_id]

            let current_all_share_recipients_filtered_for_invite_deletion: string[] =
                current_share_for_invites.all_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.invite_id
                )

            let current_external_share_recipients_filtered_for_invite_deletion: string[] =
                current_share_for_invites.external_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.invite_id
                )

            if (state.garages[action.payload.entity_id].private_shares_owned) {
                state.garages[action.payload.entity_id] = {
                    ...state.garages[action.payload.entity_id],
                    private_shares_owned: {
                        ...state.garages[action.payload.entity_id]
                            .private_shares_owned!,
                        entity_owned_direct_shares_data: {
                            ...state.garages[action.payload.entity_id]
                                .private_shares_owned
                                ?.entity_owned_direct_shares_data,
                            [action.payload.share_id]: {
                                ...current_share_for_invites,
                                external_share_recipients_ids_list: [
                                    ...current_external_share_recipients_filtered_for_invite_deletion,
                                ],
                                all_share_recipients_ids_list: [
                                    ...current_all_share_recipients_filtered_for_invite_deletion,
                                ],
                                active_display: {
                                    activeSort: 'added_at',
                                    item_ids_display: [
                                        ...current_external_share_recipients_filtered_for_invite_deletion,
                                    ],
                                },
                            },
                        },
                    },
                }
            }
        },
        deleteGarageShareInviteError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        cleanUpGaragesDataOnLogoutSuccess: (state) => {
            state.garages = {}
            state.directly_shared_external_garages = {}
            state.error = null
            state.loading = false
            state.sharesLoading = false
            state.tasks = {}
            state.cars_per_page = 10
            state.current_page_number = 0
            state.total_cars_number_found = undefined
        },
        removeCarFromUserGarage: (
            state,
            action: PayloadAction<{ carID: string; garageID: string }>
        ) => {
            const new_garage_ids = state.garages[action.payload.garageID]?.cars
                ? state.garages[action.payload.garageID].cars.filter(
                      (id: string) => {
                          return id !== action.payload.carID
                      }
                  )
                : undefined
            const new_garage_sorted_ids = state.garages[
                action.payload.garageID
            ].sorted_car_ids?.filter((id: string) => {
                return id !== action.payload.carID
            })

            if (state.garages[action.payload.garageID]) {
                state.garages[action.payload.garageID] = {
                    ...state.garages[action.payload.garageID],
                    cars: new_garage_ids
                        ? [...new_garage_ids]
                        : new_garage_sorted_ids
                        ? [...new_garage_sorted_ids]
                        : [],
                    sorted_car_ids: new_garage_sorted_ids
                        ? [...new_garage_sorted_ids]
                        : undefined,
                }
            }
        },
        setGarageActiveSortingRequest: (
            state,
            action: PayloadAction<ISetGarageActiveSort>
        ) => {
            state.loading = true
        },
        setGarageActiveSortingSuccess: (
            state,
            action: PayloadAction<IGarageActiveSort>
        ) => {
            state.activeSort = action.payload
            state.loading = false
        },
        setGarageActiveSortingError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        resetSortedCarsInGarage: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.error = null

            state.garages = {
                ...state.garages,
                [action.payload]: {
                    ...state.garages[action.payload],
                    sorted_car_ids: undefined,
                },
            }

            state.total_cars_number_found = undefined
            state.current_page_number = 0
        },
    },
})

export const garagesActions = garagesSlice.actions

export default garagesSlice.reducer
