import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    convertFiltersArrayDisplayTxt,
    convertFiltersDisplayTxt,
} from '../conversions/showroom/filtersDisplayTxt'
import { ISearchShowroom_API_car_item } from '../services/showroom/types'
import { default_showroomFilters } from './data'
import { I_showroom_entry_create_arg } from './sagas/createShowroomEntry'
import {
    IShowroomEntrySimilarRecommendationsRequest,
    IShowroomEntrySimilarRecommendationssuccess,
} from './sagas/getRecommendations'
import {
    IGetShowroomByEntry,
    I_SetCarExtraneousReduxData_Overview_payload,
} from './sagas/getShowroomEntry'
import { ISearchSagaProps } from './sagas/searchShowroom'
import { I_showroom_entry_update_req_payload } from './sagas/updateShowroomEntry'
import {
    IEnquiryStructureRequest,
    IFilterID,
    IFilterValidationError,
    ISetOwner_filter,
    IShowroomEntry,
    IShowroomEntryAPI,
    IShowroomFilterRadioOptionItem,
    IShowroomState,
    I_showroomFilter_remove_arg,
    I_showroomFilter_setLocation_arg,
    I_showroomFilter_setMultiselect_arg,
    I_showroomFilter_setMultiselect_arg_replace,
    I_showroomFilter_setRange_arg,
    I_showroomFilter_setSingleSelect_arg,
} from './types'
import { IDropdownItem } from 'entityModels'
import { IReduxError } from '../entities/cars/types'

const initialState: IShowroomState = {
    loading: false,
    error: null,
    filters: default_showroomFilters,
    editingFilters: default_showroomFilters,
    openedFilterID: undefined,
    filterValidationError: undefined,
    isShowroomEntryLoading: false,
    userShowroomEntries: undefined,
    normalisedPublicEntries: undefined,
    itemsNumberPerPage: 36,
    currentPageNumber_sale: 0,
    currentPageNumber_sold: 0,
    currentPageNumber_watchlist: 0,
    sale_results_total_count: undefined,
    similarSoldCarsNumber: undefined,
    sale_results: undefined,
    isSearchLoading: false,
    validationError: null,
    isMoreFiltersOpened: false,
    watchlist_results: undefined,
    watchlist_results_total_count: undefined,
    deletedShowroomEntryId: undefined,
    entry_anchor: undefined,
    similar_recommendations: { dealers: {}, makes: {} },
}

export const showroomSlice = createSlice({
    name: 'showroom',
    initialState: initialState,
    reducers: {
        setIsMoreFiltersOpened: (state, action: PayloadAction<boolean>) => {
            state.isMoreFiltersOpened = action.payload
        },

        validateEntryForPublicationRequest: (
            state,
            action: PayloadAction<string>
        ) => {},

        validateEntryForPublicationSuccess: (state) => {
            state.validationError = null
        },

        set_entry_anchor: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.entry_anchor = action.payload
        },

        setCurrentPageNumber_sold: (state, action: PayloadAction<number>) => {
            state.currentPageNumber_sold = action.payload
        },

        setCurrentPageNumber_sale: (state, action: PayloadAction<number>) => {
            state.currentPageNumber_sale = action.payload
        },
        setCurrentPageNumber_watchlist: (
            state,
            action: PayloadAction<number>
        ) => {
            state.currentPageNumber_watchlist = action.payload
        },

        validateEntryForPublicationError: (
            state,
            action: PayloadAction<any | null>
        ) => {
            state.validationError = action.payload
        },
        createEntryShowroomRequest: (
            state,
            action: PayloadAction<I_showroom_entry_create_arg>
        ) => {
            state.isShowroomEntryLoading = true
        },
        createEntryShowroomSuccess: (
            state,
            action: PayloadAction<IShowroomEntry>
        ) => {
            state.isShowroomEntryLoading = false
            if (state.userShowroomEntries) {
                state.userShowroomEntries[action.payload.id] = {
                    ...action.payload,
                }
            } else {
                state.userShowroomEntries = {
                    [action.payload.id]: {
                        ...action.payload,
                    },
                }
            }
            state.error = null
        },

        createEntryShowroomError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.isShowroomEntryLoading = false
        },

        updateEntryShowroomRequest: (
            state,
            action: PayloadAction<I_showroom_entry_update_req_payload>
        ) => {
            state.isShowroomEntryLoading = true
        },
        updateEntryShowroomSuccess: (state) => {
            state.isShowroomEntryLoading = false
            state.error = null
        },

        deleteEntryShowroomRequest: (
            state,
            action: PayloadAction<{ entryID: string; carID: string }>
        ) => {
            state.isShowroomEntryLoading = true
        },

        deleteEntryShowroomSuccess: (state, action: PayloadAction<string>) => {
            state.isShowroomEntryLoading = false
            if (state.userShowroomEntries) {
                delete state.userShowroomEntries[action.payload]
            } else {
                state.userShowroomEntries = {}
            }
            state.error = null
        },

        deleteEntryShowroomError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.isShowroomEntryLoading = false
        },

        updateEntryShowroomError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.isShowroomEntryLoading = false
        },

        getShowroomEntryByIDRequest: (
            state,
            action: PayloadAction<IGetShowroomByEntry>
        ) => {
            state.isShowroomEntryLoading = true
            state.error = null
        },

        getShowroomEntryByIDError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.isShowroomEntryLoading = false
        },

        getShowroomEntryByIDSuccess: (
            state,
            action: PayloadAction<IShowroomEntry>
        ) => {
            state.isShowroomEntryLoading = false
            if (state.userShowroomEntries) {
                state.userShowroomEntries[action.payload.id] = {
                    ...action.payload,
                }
            } else {
                state.userShowroomEntries = {
                    [action.payload.id]: {
                        ...action.payload,
                    },
                }
            }
            state.error = null
        },
        getPublicLogginInShowroomEntryByIDSuccess: (
            state,
            action: PayloadAction<IShowroomEntry>
        ) => {
            state.isShowroomEntryLoading = false
            if (state.normalisedPublicEntries) {
                state.normalisedPublicEntries[action.payload.id] = {
                    ...action.payload,
                }
            } else {
                state.normalisedPublicEntries = {
                    [action.payload.id]: {
                        ...action.payload,
                    },
                }
            }
            state.error = null
        },

        setShowroomCarGridInfo: (
            state,
            action: PayloadAction<I_SetCarExtraneousReduxData_Overview_payload>
        ) => {
            state.isShowroomEntryLoading = false

            if (
                state.userShowroomEntries &&
                state.userShowroomEntries[action.payload.entryID]
            ) {
                let overviewObj = state.userShowroomEntries[
                    action.payload.entryID
                ]!.car!.overview
                    ? state.userShowroomEntries[action.payload.entryID].car
                          ?.overview
                    : undefined

                let dataa = overviewObj ? overviewObj.data : {}

                if (overviewObj) {
                    state.userShowroomEntries[action.payload.entryID] = {
                        ...state.userShowroomEntries[action.payload.entryID],
                        car: {
                            ...state.userShowroomEntries[action.payload.entryID]
                                .car,
                            // @ts-ignore
                            overview: {
                                ...overviewObj,
                                // @ts-ignore
                                data: {
                                    ...dataa,
                                    ...action.payload.data,
                                },
                            },
                        },
                    }
                }
            }
        },

        getPublicShowroomEntryByIDRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.isShowroomEntryLoading = true
            state.error = null
        },

        getPublicShowroomEntryByIDError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.isShowroomEntryLoading = false
        },

        getPublicShowroomEntryByIDSuccess: (
            state,
            action: PayloadAction<IShowroomEntry>
        ) => {
            state.isShowroomEntryLoading = false
            if (state.normalisedPublicEntries) {
                state.normalisedPublicEntries[action.payload.id] = {
                    ...action.payload,
                }
            } else {
                state.normalisedPublicEntries = {
                    [action.payload.id]: {
                        ...action.payload,
                    },
                }
            }
            state.error = null
        },

        getWatchlistEntriesRequest: (state) => {
            state.isSearchLoading = true
            state.error = null
        },

        getWatchlistEntriesError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.isSearchLoading = false
        },

        getWatchlistEntriesSuccess: (
            state,
            action: PayloadAction<{ cars: IShowroomEntryAPI[]; count: number }>
        ) => {
            state.isSearchLoading = false
            state.watchlist_results = [...action.payload.cars]
            state.watchlist_results_total_count = action.payload.count
            state.error = null
        },

        resetWatchlistEntries: (state) => {
            state.isSearchLoading = false
            state.watchlist_results = undefined
            state.watchlist_results_total_count = undefined
            state.currentPageNumber_watchlist = 0
        },

        addEntryToWatchlistRequest: (state, action: PayloadAction<string>) => {
            // state.isShowroomEntryLoading = true
        },

        addEntryToWatchlistError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
        },

        addEntryToWatchlistSuccess: (state, action: PayloadAction<string>) => {
            if (
                state.normalisedPublicEntries &&
                state.normalisedPublicEntries[action.payload]
            ) {
                state.normalisedPublicEntries[action.payload] = {
                    ...state.normalisedPublicEntries[action.payload],
                    is_watched: true,
                }
            }

            state.watchlist_results_total_count =
                state.watchlist_results_total_count !== undefined
                    ? state.watchlist_results_total_count + 1
                    : 1

            state.error = null
        },

        removeEntryFromWatchlistRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            // state.isShowroomEntryLoading = true
        },

        removeEntryFromWatchlistError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
        },

        removeEntryFromWatchlistSuccess: (
            state,
            action: PayloadAction<string>
        ) => {
            if (state.watchlist_results) {
                const updatedResults = state.watchlist_results.filter(
                    (result) => result.uid !== action.payload
                )
                state.watchlist_results = updatedResults
            }

            if (
                state.normalisedPublicEntries &&
                state.normalisedPublicEntries[action.payload]
            ) {
                state.normalisedPublicEntries[action.payload] = {
                    ...state.normalisedPublicEntries[action.payload],
                    is_watched: false,
                }
            }

            state.watchlist_results_total_count =
                state.watchlist_results_total_count !== undefined
                    ? state.watchlist_results_total_count - 1
                    : undefined
            state.error = null
        },

        resetAllFilters: (state) => {
            // state.filters = showroomFilters
            state.editingFilters = default_showroomFilters
            // state.similarSoldCarsNumber = undefined
        },

        setFilterOpen: (
            state,
            action: PayloadAction<IFilterID | undefined>
        ) => {
            state.openedFilterID = action.payload
        },

        setFilterValidationError: (
            state,
            action: PayloadAction<IFilterValidationError | undefined>
        ) => {
            if (action.payload !== undefined) {
                if (action.payload.error) {
                    state.filterValidationError = {
                        ...state.filterValidationError,
                        [action.payload.id]: action.payload,
                    }
                } else if (
                    action.payload.id &&
                    state.filterValidationError &&
                    state.filterValidationError[action.payload.id]
                ) {
                    delete state.filterValidationError[action.payload.id]
                }
            } else {
                state.filterValidationError = undefined
            }
        },
        setMultiSelect_filter: (
            state,
            action: PayloadAction<I_showroomFilter_setMultiselect_arg>
        ) => {
            // make model colour body_type drive_side
            // let stateFilters = state.filters

            let stateEditingFilters = state.editingFilters

            let multiSelectFilterValues: string[] = stateEditingFilters
                ? [...stateEditingFilters[action.payload.id].values!]
                : []

            let index: number = multiSelectFilterValues.indexOf(
                action.payload.value
            )

            if (index === -1) {
                multiSelectFilterValues = [
                    ...multiSelectFilterValues,
                    action.payload.value,
                ]
            } else {
                multiSelectFilterValues = multiSelectFilterValues.filter(
                    (item: string) => item !== action.payload.value
                )
            }

            state.editingFilters[action.payload.id].values =
                multiSelectFilterValues
            state.editingFilters[action.payload.id].isActive =
                multiSelectFilterValues.length > 0 ? true : false

            let dislayV = convertFiltersDisplayTxt({
                id: action.payload.id,
                state: state.editingFilters,
                optionsListToMatch: state.editingFilters[action.payload.id]
                    .options ?? [
                    {
                        uid: action.payload.value,
                        name: action.payload.value,
                    },
                ],
            })
            state.editingFilters[action.payload.id].displayValue = dislayV

            let valuesName = convertFiltersArrayDisplayTxt({
                id: action.payload.id,
                state: state.editingFilters,
                optionsListToMatch: state.editingFilters[action.payload.id]
                    .options ?? [
                    {
                        uid: action.payload.value,
                        name: action.payload.value,
                    },
                ],
            })
            state.editingFilters[action.payload.id].nameValuesOBJ = valuesName
        },

        setMultiSelect_filter_replace_values: (
            state,
            action: PayloadAction<I_showroomFilter_setMultiselect_arg_replace>
        ) => {
            // make model colour body_type drive_side

            state.editingFilters[action.payload.id].values =
                action.payload.values
            state.editingFilters[action.payload.id].isActive =
                action.payload.values.length > 0 ? true : false

            let dislayV = convertFiltersDisplayTxt({
                id: action.payload.id,
                state: state.editingFilters,
                optionsListToMatch:
                    state.editingFilters[action.payload.id].options,
                values: action.payload.values,
            })
            state.editingFilters[action.payload.id].displayValue = dislayV

            let valuesName = convertFiltersArrayDisplayTxt({
                id: action.payload.id,
                state: state.editingFilters,
                optionsListToMatch:
                    state.editingFilters[action.payload.id].options,
            })
            state.editingFilters[action.payload.id].nameValuesOBJ = valuesName
        },

        setRadioSelect_filter: (
            state,
            action: PayloadAction<I_showroomFilter_setSingleSelect_arg>
        ) => {
            // year, type_of_sale
            // @ts-ignore
            state.editingFilters[action.payload.id].value =
                action.payload.item.id
            state.editingFilters[action.payload.id].displayValue =
                action.payload.item.name

            if (action.payload.id === 'year') {
                state.editingFilters[action.payload.id].valueFrom = undefined
                state.editingFilters[action.payload.id].valueTo = undefined
            }

            state.editingFilters[action.payload.id].isActive = true
            // @ts-ignore
            let dislayV = state.editingFilters[
                action.payload.id
            ].options?.filter(
                (item: IShowroomFilterRadioOptionItem) =>
                    item.id === action.payload.item.id
            )

            // @ts-ignore
            state.editingFilters[action.payload.id].displayValue =
                dislayV && dislayV[0] && dislayV[0].name
                    ? `${dislayV[0].name}`
                    : action.payload.id === 'year'
                    ? `${action.payload.item.id}'s`
                    : action.payload.item.name
                    ? action.payload.item.name
                    : undefined
        },
        setLocation_filter: (
            state,
            action: PayloadAction<I_showroomFilter_setLocation_arg>
        ) => {
            state.editingFilters.location.values = { ...action.payload.values }
            state.editingFilters.location.displayValue =
                action.payload.display_value

            state.editingFilters.location.isActive = action.payload.values
                ? true
                : false
        },
        setRange_filter: (
            state,
            action: PayloadAction<I_showroomFilter_setRange_arg>
        ) => {
            // year price mileage

            state.editingFilters[action.payload.id].valueFrom =
                action.payload.valueFrom
            state.editingFilters[action.payload.id].valueTo =
                action.payload.valueTo

            // setting display values if a max was set but not a min
            let min = state.editingFilters[action.payload.id].valueFrom
            let max = state.editingFilters[action.payload.id].valueTo
            if (max && !min) {
                min = 1
            }

            state.editingFilters[action.payload.id].displayValue = `${
                min ? min : ''
            } - ${max ? max : ''}`

            state.editingFilters[action.payload.id].isActive = action.payload
                .isReset
                ? false
                : true

            if (action.payload.id === 'year') {
                state.editingFilters[action.payload.id].value = undefined
            }
        },
        setKeyword_filter: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            // keyword
            state.editingFilters.keyword = {
                ...state.editingFilters.keyword,
                values:
                    state.editingFilters.keyword.values && action.payload
                        ? [
                              ...state.editingFilters.keyword.values,
                              action.payload,
                          ]
                        : action.payload
                        ? [action.payload]
                        : state.editingFilters.keyword.values,
                isActive: true,
            }
        },

        setOwner_filter: (state, action: PayloadAction<ISetOwner_filter>) => {
            state.editingFilters.owner = {
                id: 'owner',
                name: 'seller',
                owner_id: action.payload.id,
                displayValue: action.payload.display_name,
                isActive: true,
            }
            state.filters.owner = {
                id: 'owner',
                name: 'seller',
                owner_id: action.payload.id,
                displayValue: action.payload.display_name,
                isActive: true,
            }
        },

        removeFilterRequest: (
            state,
            action: PayloadAction<I_showroomFilter_remove_arg>
        ) => {
            if (
                action.payload.id === 'mileage' ||
                action.payload.id === 'year'
            ) {
                // @ts-ignore
                state.editingFilters[action.payload.id] = {
                    ...default_showroomFilters[action.payload.id],
                    min_backend:
                        state.editingFilters[action.payload.id].min_backend,
                    max_backend:
                        state.editingFilters[action.payload.id].max_backend,
                    isActive: false,
                }

                if (action.payload.direct_search) {
                    state.filters[action.payload.id] = {
                        ...default_showroomFilters[action.payload.id],
                        min_backend:
                            state.filters[action.payload.id].min_backend,
                        max_backend:
                            state.filters[action.payload.id].max_backend,
                        isActive: false,
                    }
                }
            } else {
                // @ts-ignore
                state.editingFilters[action.payload.id] = {
                    ...default_showroomFilters[action.payload.id],
                    isActive: false,
                }

                if (action.payload.direct_search) {
                    // @ts-ignore
                    state.filters[action.payload.id] = {
                        ...default_showroomFilters[action.payload.id],
                        isActive: false,
                    }
                }
            }
            state.error = null
        },

        removeMultiSelectItem_request: (
            state,
            action: PayloadAction<I_showroomFilter_setMultiselect_arg>
        ) => {
            // make model colour body_type drive_side
            let stateFilters =
                action.payload.isDirectSearchMode === true
                    ? state.filters
                    : state.editingFilters

            let multiSelectFilterValues: string[] = stateFilters
                ? [...stateFilters[action.payload.id].values!]
                : []

            multiSelectFilterValues = multiSelectFilterValues.filter(
                (item: string) => item !== action.payload.value
            )

            if (action.payload.isDirectSearchMode) {
                state.filters[action.payload.id].values =
                    multiSelectFilterValues
                state.filters[action.payload.id].isActive =
                    multiSelectFilterValues.length !== 0 ? true : false

                let dislayV = convertFiltersDisplayTxt({
                    id: action.payload.id,
                    state: state.filters,
                    optionsListToMatch:
                        state.filters[action.payload.id].options,
                })
                state.filters[action.payload.id].displayValue = dislayV

                let valuesName = convertFiltersArrayDisplayTxt({
                    id: action.payload.id,
                    state: state.filters,
                    optionsListToMatch:
                        state.filters[action.payload.id].options,
                })
                state.filters[action.payload.id].nameValuesOBJ = valuesName
            }

            state.editingFilters[action.payload.id].values =
                multiSelectFilterValues
            state.editingFilters[action.payload.id].isActive =
                multiSelectFilterValues.length !== 0 ? true : false

            let dislayV = convertFiltersDisplayTxt({
                id: action.payload.id,
                state: state.editingFilters,
                optionsListToMatch:
                    state.editingFilters[action.payload.id].options,
            })
            state.editingFilters[action.payload.id].displayValue = dislayV

            let valuesName = convertFiltersArrayDisplayTxt({
                id: action.payload.id,
                state: state.editingFilters,
                optionsListToMatch: state.filters[action.payload.id].options,
            })
            state.editingFilters[action.payload.id].nameValuesOBJ = valuesName
            state.error = null
        },

        setShowroomMakesList: (
            state,
            action: PayloadAction<{
                options: IDropdownItem[]
                editingOnly?: boolean
            }>
        ) => {
            state.loading = false
            state.error = null

            if (!action.payload.editingOnly) {
                state.filters.make.options = [...action.payload.options]
            }

            state.editingFilters.make.options = [...action.payload.options]
        },

        setShowroomModelsList: (
            state,
            action: PayloadAction<{
                options: IDropdownItem[]
                editingOnly?: boolean
            }>
        ) => {
            state.loading = false
            state.error = null
            if (!action.payload.editingOnly) {
                state.filters.model.options = [...action.payload.options]
            }
            state.editingFilters.model.options = [...action.payload.options]
        },

        setShowroomBodyTypesList: (
            state,
            action: PayloadAction<{
                options: IDropdownItem[]
                editingOnly?: boolean
            }>
        ) => {
            state.loading = false
            state.error = null
            if (!action.payload.editingOnly) {
                state.filters.body_type.options = [...action.payload.options]
            }
            state.editingFilters.body_type.options = [...action.payload.options]
        },

        setShowroomYearsRadioOptions: (
            state,
            action: PayloadAction<{
                options: IShowroomFilterRadioOptionItem[]
                editingOnly?: boolean
            }>
        ) => {
            state.loading = false
            state.error = null
            if (!action.payload.editingOnly) {
                state.filters.year.options = [...action.payload.options]
            }
            state.editingFilters.year.options = [...action.payload.options]
        },

        setShowroomColoursList: (
            state,
            action: PayloadAction<{
                options: IShowroomFilterRadioOptionItem[]
                editingOnly?: boolean
            }>
        ) => {
            state.loading = false
            state.error = null
            if (!action.payload.editingOnly) {
                state.filters.colour.options = [...action.payload.options]
            }
            state.editingFilters.colour.options = [...action.payload.options]
        },

        setShowroomMinMaxThresholds: (
            state,
            action: PayloadAction<{
                year?: {
                    min_backend?: number
                    max_backend?: number
                }
                mileage?: {
                    min_backend?: number
                    max_backend?: number
                }
                price?: {
                    min_backend?: number
                    max_backend?: number
                }
            }>
        ) => {
            state.loading = false
            state.error = null

            // FORMAL FILTERS

            // year
            state.filters.year.max_backend = action.payload.year?.max_backend
            state.filters.year.min_backend = action.payload.year?.min_backend

            // price

            state.filters.price.max_backend = action.payload.price?.max_backend
            state.filters.price.min_backend = action.payload.price?.min_backend

            // mileage

            state.filters.mileage.max_backend =
                action.payload.mileage?.max_backend
            state.filters.mileage.min_backend =
                action.payload.mileage?.min_backend

            // EDITED FILTERS

            // year
            state.editingFilters.year.max_backend =
                action.payload.year?.max_backend
            state.editingFilters.year.min_backend =
                action.payload.year?.min_backend

            // mileage

            state.editingFilters.mileage.max_backend =
                action.payload.mileage?.max_backend
            state.editingFilters.mileage.min_backend =
                action.payload.mileage?.min_backend

            // price

            state.editingFilters.price.max_backend =
                action.payload.price?.max_backend
            state.editingFilters.price.min_backend =
                action.payload.price?.min_backend
        },

        showroomEnquiryByEntryIdRequest: (
            state,
            action: PayloadAction<IEnquiryStructureRequest>
        ) => {
            state.loading = true
            state.error = null
        },
        showroomEnquiryByEntryIdSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        showroomEnquiryByEntryIdError: (
            state,
            action: PayloadAction<null | IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // SEARCH

        searchShowroomRequest: (
            state,
            action: PayloadAction<undefined | ISearchSagaProps>
        ) => {
            state.isSearchLoading = true

            if (action.payload?.only_for_filter_results !== true) {
                state.filters = Object.assign({}, state.editingFilters)
            }
            state.error = null
        },

        cancelShowroomFilters: (state) => {
            // state.isSearchLoading = false
            state.editingFilters = { ...state.filters }
        },

        searchShowroom_setCars: (
            state,
            action: PayloadAction<{
                cars: ISearchShowroom_API_car_item[] | undefined
                resultsFoundNumber?: number | undefined
                similarSoldCarsNumber?: number | undefined
                type?: 'sale' | 'sold'
            }>
        ) => {
            if (action.payload.type === 'sold') {
                // if (state.currentPageNumber_sold === 0) {
                //     state.sold_results = action.payload.cars
                //         ? [...action.payload.cars]
                //         : []
                // } else if (action.payload.cars) {
                //     state.sold_results = state.sold_results
                //         ? [...state.sold_results, ...action.payload.cars]
                //         : [...action.payload.cars]
                // } else state.sold_results = undefined

                if (action.payload.cars) {
                    state.sold_results = [...action.payload.cars]
                } else state.sold_results = undefined
            }

            if (action.payload.type === 'sale') {
                // if (state.currentPageNumber_sale === 0) {
                //     state.sale_results = action.payload.cars
                //         ? [...action.payload.cars]
                //         : []
                // } else if (action.payload.cars) {
                //     state.sale_results = state.sale_results
                //         ? [...state.sale_results, ...action.payload.cars]
                //         : [...action.payload.cars]
                // } else state.sale_results = undefined

                if (action.payload.cars) {
                    state.sale_results = [...action.payload.cars]
                } else state.sale_results = undefined
            }

            if (action.payload.type === 'sold') {
                state.sold_results_total_count =
                    action.payload.resultsFoundNumber
            } else {
                state.sale_results_total_count =
                    action.payload.resultsFoundNumber
            }

            state.similarSoldCarsNumber = action.payload.similarSoldCarsNumber
        },

        searchShowroomSuccess: (state) => {
            state.isSearchLoading = false
            state.error = null
        },

        searchShowroomError: (
            state,
            action: PayloadAction<null | IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        setActiveSorting: (
            state,
            action: PayloadAction<
                | 'OLDEST'
                | 'MOST_RECENT'
                | 'PRICE_ASCENDING'
                | 'PRICE_DESCENDING'
                | 'MANUFACTURE_DATE_ASCENDING'
                | 'MANUFACTURE_DATE_DESCENDING'
                | undefined
            >
        ) => {
            state.activeSort = action.payload
        },

        setSimilarSoldCarsNumber: (
            state,
            action: PayloadAction<number | undefined>
        ) => {
            state.similarSoldCarsNumber = action.payload
        },
        showroomEntryDeletionConfirmed: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.deletedShowroomEntryId = action.payload
        },

        getShowroomEntryRecommendationsRequest: (
            state,
            action: PayloadAction<IShowroomEntrySimilarRecommendationsRequest>
        ) => {
            state.isSearchLoading = true
            state.error = null
        },
        getShowroomEntryRecommendationsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.isSearchLoading = false
        },
        getShowroomEntryRecommendationsSuccess: (
            state,
            action: PayloadAction<
                IShowroomEntrySimilarRecommendationssuccess | undefined
            >
        ) => {
            state.isSearchLoading = false
            state.error = null

            if (action.payload) {
                if (
                    state.similar_recommendations.dealers[
                        action.payload.dealer.id
                    ]
                ) {
                    state.similar_recommendations.dealers[
                        action.payload.dealer.id
                    ] = { ...action.payload.dealer }
                } else {
                    state.similar_recommendations.dealers = {
                        ...state.similar_recommendations.dealers,
                        [action.payload.dealer.id]: {
                            ...action.payload.dealer,
                        },
                    }
                }

                // perhaps in future., more rows there

                if (
                    action.payload.make &&
                    state.similar_recommendations.makes[action.payload.make.id]
                ) {
                    state.similar_recommendations.makes[
                        action.payload.dealer.id
                    ] = { ...action.payload.make }
                } else if (action.payload.make) {
                    state.similar_recommendations.makes = {
                        ...state.similar_recommendations.makes,
                        [action.payload.make.id]: {
                            ...action.payload.make,
                        },
                    }
                }
            }
        },

        resetNumberOfForSaleResults: (state) => {
            state.sale_results_total_count = undefined
            state.currentPageNumber_sale = 0
        },
        resetNumberOfSoldResults: (state) => {
            state.sold_results_total_count = undefined
            state.currentPageNumber_sold = 0
        },
        cleanUpShowroom: (state: any) => {
            return initialState
        },
    },
})

export const showroomActions = showroomSlice.actions

export default showroomSlice.reducer
