import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    ConvertSharesOwnedToState,
    I_UserOwnedShares_ByEntityType_ConversionArg,
    I_UserOwnedShares_ByEntityType_ConversionArg_req,
} from '../../conversions/shares/convertSharesOwnedToState'
import { api } from '../../services'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
import { IEntitySharesOwnedDataArg } from '../../entities/cars/actions'
import { usersActions } from '../reducer'
import { carActions } from '../../entities/cars/reducer'
import { garagesActions } from '../../entities/garages/reducer'
import {
    IURL_user_getSharesList_arg,
    IDirectShareOwnedItem_Api_payload,
    IPublicShareAPIRes_item,
} from '../../services/types'
import { IReduxError } from '../../entities/cars/types'

export function* PopulateUserSharesOwnedSaga(): any {
    try {
        let final: I_UserOwnedShares_ByEntityType_ConversionArg = {
            cars: {},
            garage: {},
        }

        let conv_arg: I_UserOwnedShares_ByEntityType_ConversionArg_req = {
            owned_direct_shares: [],
            owned_public_shares: [],
        }

        // IListUserShares_API_res
        // API with car entity_type, direct shares

        const car_apiArgs: IURL_user_getSharesList_arg = {
            share_type: 'direct_share',
            resource_type: 'car',
            selected_type: 'owned',
        }

        let api_res_cars: any = yield call(
            api.sharing.getUserDirectSharesListAPI,
            car_apiArgs
        )

        let cars_res: IDirectShareOwnedItem_Api_payload[] =
            api_res_cars.shares_owned

        conv_arg = {
            ...conv_arg,
            owned_direct_shares: [...conv_arg.owned_direct_shares, ...cars_res],
        }

        // API with garage entity_type, direct shares

        const g_apiArgs: IURL_user_getSharesList_arg = {
            share_type: 'direct_share',
            resource_type: 'garage',
            selected_type: 'owned',
        }

        let api_res_g: any = yield call(
            api.sharing.getUserDirectSharesListAPI,
            g_apiArgs
        )
        let g_res: IDirectShareOwnedItem_Api_payload[] = api_res_g.shares_owned

        conv_arg = {
            ...conv_arg,
            owned_direct_shares: [...conv_arg.owned_direct_shares, ...g_res],
        }

        // IListUserShares_API_res
        // API with car entity_type, link shares

        const c_l_apiArgs: IURL_user_getSharesList_arg = {
            share_type: 'link_share',
            resource_type: 'car',
            selected_type: 'owned',
        }

        let api_res_cl: IPublicShareAPIRes_item[] = yield call(
            api.sharing.getUserDirectSharesListAPI,
            c_l_apiArgs
        )

        conv_arg = {
            ...conv_arg,
            owned_public_shares: [
                ...conv_arg.owned_public_shares,
                ...api_res_cl,
            ],
        }

        // API with garage entity_type, link shares

        const g_l_apiArgs: IURL_user_getSharesList_arg = {
            share_type: 'link_share',
            resource_type: 'garage',
            selected_type: 'owned',
        }

        let api_res_gl: any = yield call(
            api.sharing.getUserDirectSharesListAPI,
            g_l_apiArgs
        )

        conv_arg = {
            ...conv_arg,
            owned_public_shares: [
                ...conv_arg.owned_public_shares,
                ...api_res_gl,
            ],
        }

        // converstion fn

        final = ConvertSharesOwnedToState(conv_arg)

        // success action for cars

        for (const carid in final.cars) {
            let csa: IEntitySharesOwnedDataArg = {
                entity_id: carid,
                owned_shares_obj: final.cars[carid],
            }

            yield put(carActions.populateSingleCarAllOwnedSharesSuccess(csa))
        }

        // success action for garages

        for (const garageid in final.garage) {
            let csa: IEntitySharesOwnedDataArg = {
                entity_id: garageid,
                owned_shares_obj: final.garage[garageid],
            }

            yield put(
                garagesActions.populateSingleGarageAllOwnedSharesSuccess(csa)
            )
        }

        // success action for populateUsr etc fn

        yield put(usersActions.populateOwnedSharesDataPopulationSuccess())
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: PopulateUserSharesOwnedSaga,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(usersActions.populateOwnedSharesDataError(typedError))
            return
        }
    }
}

function* watcherPopulateUserSharesOwned() {
    while (true) {
        yield take(usersActions.populateOwnedSharesDataRequest)

        yield call(PopulateUserSharesOwnedSaga)
    }
}

const populate_user_shares_owned: any[] = [fork(watcherPopulateUserSharesOwned)]

export default populate_user_shares_owned
