import styled from 'styled-components'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import { device } from '../../../../templates/displays/devices'
import { useAppSelector } from '../../../../../redux/store/hooks'
import { Tooltip } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Title = styled.div`
    font-family: Lato-bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: var(--text-strong, #1a1a1a);
    @media ${device.mobile_and_ipad} {
        font-size: 24px;
        line-height: 24px;
    }
`

const Subtitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
    max-width: 400px;

    @media ${device.mobile_and_ipad} {
        line-height: 30px;
    }
`

type Props = {
    cancel: () => any
    carID: string
}

const CarAsSoldConfirmModalDesktop = (props: Props) => {
    let car = useAppSelector(
        (state) =>
            state.entities.carsData.cars &&
            state.entities.carsData.cars[props.carID]
    )

    let carHandoverPermissions = car && car.car_actions_checks?.handover

    const [isTooltipVisible, toggleTooltip] = useState(false)
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)

    const showTooltip = (p: boolean) => {
        toggleTooltip(p)
    }

    const navigate = useNavigate()

    return (
        <>
            <Title>Car marked as sold</Title>
            <div style={{ paddingTop: '20px' }} />
            <Subtitle>
                Would you like to transfer the car from your garage to its new
                owner? You can do that any time from your garage.
            </Subtitle>

            <div style={{ paddingTop: '50px' }} />

            <Tooltip
                overlayStyle={{
                    fontFamily: 'Lato',
                }}
                open={isTooltipVisible ? true : false}
                placement="top"
                title={carHandoverPermissions?.message}
            >
                <ButtonAtom
                    theme="secondary"
                    width="100%"
                    height="48px"
                    textTransform="capitalize"
                    dataCyId={'showroom-car-enquire'}
                    onClick={() => {
                        // redux dispatch from here later\
                        if (carHandoverPermissions?.allowed) {
                            navigate(`/handover/${props.carID}`)
                        } else {
                            setIsBtnDisabled(true)
                            showTooltip(true)
                            setTimeout(() => showTooltip(false), 2000)
                        }
                    }}
                    fontSize={16}
                    customBgColor={isBtnDisabled ? '#c0c0c0' : undefined}
                >
                    Handover car
                </ButtonAtom>
            </Tooltip>
            <div
                style={{
                    paddingTop: 16,
                }}
            />

            <ButtonAtom
                theme="naked-text"
                height="48px"
                dataCyId={'showroom-car-share'}
                icon={''}
                fontSize={16}
                onClick={() => {
                    navigate(`/garage`)
                }}
            >
                Return to garage
            </ButtonAtom>
        </>
    )
}

export default CarAsSoldConfirmModalDesktop
