import * as React from 'react'
import { DatePicker } from 'antd'
import styled from 'styled-components'
import './style.css'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/lib/date-picker'

const Wrapper = styled.div<{ $additionaSlyle: string }>`
    .ant-picker {
        padding-right: 25px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
        background-color: var(--bg-color, #fff);
        ${(props) => props.$additionaSlyle}
        :hover {
            border-color: var(--primary, #5ec3ca);
            border-radius: 3px;
        }
    }
    .ant-picker-focused {
        border-color: var(--primary, #5ec3ca);
        box-shadow: 0 0 0 2px rgb(0 169 175 / 20%);
    }
`

type Props = {
    onChange: (dateString: any) => any
    value?: any
    disabled?: boolean
    readOnlyMode?: boolean
    additionalStyle?: any
    suffixIcon?: React.ReactNode
    allowClear?: boolean
    clearIcon?: React.ReactNode
    customFormat?: string[]
    allowOnlyPast?: boolean
}

const DatePickerAntd: React.FC<Props> = ({
    onChange,
    value,
    disabled,
    readOnlyMode,
    additionalStyle,
    suffixIcon,
    allowClear,
    clearIcon,
    customFormat,
    allowOnlyPast,
}) => {
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current > dayjs().endOf('day')
    }

    return (
        <Wrapper $additionaSlyle={additionalStyle}>
            <DatePicker
                popupClassName="ant_date_dropdown_custom_today_text"
                format={
                    customFormat ?? [
                        'DD/MM/YYYY',
                        'DD/MM/YY',
                        'DD-MM-YYYY',
                        'DD-MM-YY',
                    ]
                }
                dateRender={(currentDate, today) => {
                    const style: any = {}

                    if (currentDate.isSame(today, 'day')) {
                        style.border = '1px solid var(--primary, #5ec3ca)'
                        style.borderRadius = '2px'
                    }
                    return (
                        <div className=".ant-picker-cell-inner" style={style}>
                            {currentDate.date()}
                        </div>
                    )
                }}
                disabledDate={allowOnlyPast ? disabledDate : undefined}
                onChange={(date: any, dateStr: any) => {
                    onChange(date.toISOString())
                }}
                value={value && dayjs(value)}
                disabled={disabled}
                inputReadOnly={readOnlyMode}
                popupStyle={{
                    display: readOnlyMode ? 'none' : 'auto',
                }}
                suffixIcon={suffixIcon}
                allowClear={allowClear}
                clearIcon={clearIcon}
            />
        </Wrapper>
    )
}

export default DatePickerAntd
