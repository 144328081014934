import { IApexCarOfTheWeekState } from 'apexModels'
import React, { useState } from 'react'
import styled from 'styled-components'
import ShowMoreExpandToggleMobile from '../../../../atoms/Button/showMoreExpandToggleMobile'
import Expander from '../../../../atoms/expander/expander'
import LoaderBackground from '../../../../atoms/loader/loaderBackground'
import { device } from '../../../../templates/displays/devices'
import external_link_green from '../../../../../public/assets/icons/external_link_green.svg'

const WrapperofWrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: flex-start;
    justify-content: flex-start;
`

const ImgStyle = styled.div`
    height: 224px;

    @media (max-width: 330px) {
        height: 175px;
    }

    width: 100%;
    box-sizing: border-box;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex-direction: column;
    border-radius: 10px;

    @media (min-width: 500px) {
        height: 300px;
    }
`

const Name = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 2px;

    @media (min-width: 500px) {
        font-size: 17px;
    }
`
const Title = styled.div`
    font-family: 'Lato-Light';
    color: var(--text-strong, #1a1a1a);
    font-size: 16px;
    padding-bottom: 20px;

    @media (min-width: 500px) {
        font-size: 17px;
    }
`

const Text = styled.div`
    line-height: 23px;
    font-family: Lato-Light;
    font-size: 14px;
    color: var(--text-strong, #1a1a1a);

    @media (min-width: 500px) {
        font-size: 15px;
        line-height: 26px;
    }
`

const TakeCloserLookText = styled.a`
    color: var(--primary, #5ec3ca) !important;
    font-family: 'Lato';
    text-transform: uppercase;
    font-size: 12px;
`

const ExternalLinkIcon = styled.img`
    width: 11px;
    height: 11px;
    margin-left: 8px;
    transform: translateY(-3px);
    @media ${device.ipad} {
        transform: translateY(-2px);
    }
`

type Props = {
    car: IApexCarOfTheWeekState | undefined
}

const ApexHomepageCarOfTheWeekMobile: React.FC<Props> = ({ car }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <WrapperofWrapper>
            {!car && <LoaderBackground height={'400px'} width={'100%'} />}
            {car && (
                <Wrapper>
                    <ImgStyle
                        role="img"
                        aria-label="fullwidth-picture"
                        style={{
                            backgroundImage: `url(${
                                car.img ? car.img.url : ''
                            })`,
                        }}
                    />

                    <Name>{car.name}</Name>

                    <Title>{car.title}</Title>

                    <Expander height={isExpanded ? 'auto' : 50}>
                        <Text>{car.description}</Text>

                        <div style={{ paddingTop: '14px' }} />
                        <TakeCloserLookText href={car.link} target="_blank">
                            Take a closer look
                            <ExternalLinkIcon src={external_link_green} />
                        </TakeCloserLookText>
                        <div style={{ paddingTop: '20px' }} />
                    </Expander>
                    <div style={{ paddingTop: '4px' }} />
                    <ShowMoreExpandToggleMobile
                        onClick={toggleIsExpanded}
                        isExpanded={isExpanded}
                    />
                </Wrapper>
            )}
        </WrapperofWrapper>
    )
}

export default ApexHomepageCarOfTheWeekMobile
