import styled from 'styled-components'
import { IInsuranceAddress_API } from '../../../../redux/insuranceQuoteApplication/types'
import { device } from '../../../templates/displays/devices'

type IStyledProps = {
    $isSelectable?: boolean
}
const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 165px;
    width: 50%;

    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--border-muted, #e5e5e5);
    box-shadow: 10px 6px 24px var(--box-shadow, rgba(0, 0, 0, 0.08));

    min-width: 443px;
    max-width: 443px;

    @media (min-width: 910px) and (max-width: 1200px) {
        min-width: 400px;
        max-width: 400px;
    }

    @media (min-width: 800px) and (max-width: 910px) {
        min-width: 350px;
        max-width: 350px;
    }

    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
        min-width: unset;
        max-width: unset;
    }
`

const CardBody = styled.div<IStyledProps>`
    all: unset;
    height: 100%;
    cursor: ${(props) => (props.$isSelectable ? `pointer` : 'auto')};
`
const CardBodyText = styled.p`
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--text-strong, #1a1a1a);
`

const CardFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`

const Btn = styled.button`
    all: unset;
    font-family: Lato-semibold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: max-content;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;
`

type Props = {
    address: IInsuranceAddress_API
    onEdit: () => void
    onRemove: (uid: string) => void
    onSelect?: (address: IInsuranceAddress_API) => any
}

const InsuranceAddressCard = (props: Props) => {
    let { address, onEdit, onRemove } = props

    let flattenedAddress_part_3 = `
         ${address && address.locality ? `${address.locality}, ` : ''} ${
        address && address.postcode ? `${address.postcode} ` : ''
    }`

    return (
        <Card>
            <CardBody
                onClick={() => {
                    if (props.onSelect) {
                        props.onSelect(address)
                    }
                }}
                $isSelectable={props.onSelect ? true : false}
            >
                <CardBodyText>
                    {address && address.street_1 ? `${address.street_1}` : ''}
                </CardBodyText>
                <CardBodyText>
                    {address && address.street_2 ? `${address.street_2}` : ''}
                </CardBodyText>
                <CardBodyText>{flattenedAddress_part_3}</CardBodyText>
            </CardBody>
            <CardFooter>
                <Btn
                    style={{ color: 'var(--primary, #5ec3ca)' }}
                    onClick={onEdit}
                >
                    Edit address
                </Btn>
                <Btn
                    style={{ color: ' var(--text-strong' }}
                    onClick={() => onRemove(`${address?.uid}`)}
                >
                    Remove
                </Btn>
            </CardFooter>
        </Card>
    )
}

export default InsuranceAddressCard
