import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import {
    cubicCentimetersToLiters,
    cubicInchesToLiters,
    generateBackEndValueDistanceUnit,
    generateBackEndValueFuelUnit,
    generateFrontEndValueDistanceUnit,
    generateFrontEndValueFuelUnit,
    kmToMiles,
} from '../../../../helpers/units/unitConversion'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import Expander from '../../../atoms/expander/expander'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { Select } from '../../../atoms/select'
import { getStandaloneSearchUrl } from '../../../organisms/editForms/technicalInformationEditForm/searchselect/mobile/standaloneSearchSelectMobile'
import { device } from '../../../templates/displays/devices'
import { IShowroomForm_TechInfo } from './showroomTechInfoFormDesktop'
import { ErrorText } from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerDesktop'
import { CarYearValidation } from './validationSchema'
import { getRightSchemaAccountData } from '../../../organisms/editForms/account/validationSchema'
import PickerBtn from '../../../atoms/Button/pickerBtn'
import LoaderElasticThreeDots from '../../../atoms/loader/loaderElasticThreeDots'
import SwitchButton from '../../../atoms/Button/switchButton'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { ICar } from '../../../../redux/entities/cars/types'
import { IUser } from '../../../../redux/user/types'
import {
    IDropdownItem,
    IList,
    ITechInfoData,
    IwriteMultipleTechnicalInformationPayloadReq,
} from 'entityModels'
import { technicalInformationActions } from '../../../../redux/entities/technical_information/reducer'
const EditFormContainerMobile = styled.section`
    height: 100%;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 4px;
    padding: 24px;
    padding-top: 20px;
    transition: all 100ms ease-in-out;
    z-index: 6;
    position: relative;

    overflow-y: scroll;

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }
    @media ${device.mobile} {
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const RowForm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const AdjustPosition = styled.div`
    margin-left: 24px;
    width: 100%;
    box-sizing: border-box;
`

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    height: 100px;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    padding-right: 30px;
    background-color: var(--bg-color, #fff);
    z-index: 6;
`

const EquivalentConverted = styled.div`
    font-size: 12px;
    color: var(--text-muted);
    font-family: Lato;
    padding-left: 8px;
`

type Props = {
    close: () => any
    car: ICar
    entryid?: string
    showroom_entry_undisclosed_mileage?: boolean
}

const FieldTitle = styled.div`
    font-family: Lato-Semibold;
    /* font-weight: 600; */
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.005em;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
    align-self: flex-start;
    text-align: left;
`

const Line = styled.div`
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
`

const ShowroomTechInfoFormMobile = (props: Props) => {
    const navigate = useNavigate()
    let { car, showroom_entry_undisclosed_mileage } = props
    let userData: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let appDispatch = useAppDispatch()

    const [hasChange, setHasChange] = useState<boolean>(false)

    let makesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let transmissionsList = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    let techInfoData = useAppSelector((state) => {
        return state.entities.technicalInformationData.technical_information
    })

    let tempMakeID = useAppSelector((state) => {
        return state.localdata.dropdownData.tempMakeID
    })

    let tempMakeDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempMakeDisplay
    })

    let tempModelID = useAppSelector((state) => {
        return state.localdata.dropdownData.tempModelID
    })

    let tempModelDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempModelDisplay
    })

    let carModel: string | null = car.overview.data['model'].answer
    let carMake: string | null = car.overview.data['make'].answer
    let carYear: string | number | null = car.overview.data['year'].answer
    let carDriveSide: string | null = car.overview.data['drive_side'].answer
    let carEngineSize: string | number | null =
        car.overview.data['engine_capacity'].answer

    useEffect(() => {
        if (!transmissionsList) {
            appDispatch(
                dropDownActions.setTransmissionTypesDropdownDataRequest()
            )
        }

        if (!makesDropdowndata) {
            appDispatch(dropDownActions.setMakeDropdownDataRequest())
        }

        if (!modelsDropdowndata && carMake && carMake.length > 1) {
            appDispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: carMake,
                    make_uid: car.overview.data['make'].current_uid,
                })
            )
        }
    }, [])

    let tech_info_id = car.technical_information

    let tech_car_info: ITechInfoData | undefined = techInfoData[tech_info_id]

    let carMileage: string | number | null | undefined =
        tech_car_info && tech_car_info.mileage.answer

    let transmission: string | number | null | undefined =
        tech_car_info && tech_car_info.transmission.answer

    let user_distance_unit_data =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['distance_unit'].data

    let user_distance_unit_ids_list =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['distance_unit'].ids_list

    let distance_units: IList[] | null =
        user_distance_unit_ids_list &&
        user_distance_unit_ids_list.map((id: string, index: number) => {
            return user_distance_unit_data![id]
        })

    let user_distance_unit_chosen_id =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['distance_unit'].user_choice_id

    let user_default_distance_unit: IList | null =
        user_distance_unit_data && user_distance_unit_chosen_id
            ? user_distance_unit_data[user_distance_unit_chosen_id]
            : null

    let user_fuel_unit_ids_list =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].ids_list

    let user_fuel_unit_chosen_id =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].user_choice_id

    let user_fuel_unit_data =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].data

    let user_default_fuel_unit: IList | null =
        user_fuel_unit_data &&
        user_fuel_unit_chosen_id &&
        // @ts-ignore
        user_fuel_unit_data[user_fuel_unit_chosen_id]
            ? // @ts-ignore
              user_fuel_unit_data[user_fuel_unit_chosen_id]
            : null

    let fuel_units: IList[] | null =
        user_fuel_unit_data &&
        user_fuel_unit_ids_list &&
        user_fuel_unit_ids_list.map((id: string, index: number) => {
            // @ts-ignore
            return user_fuel_unit_data[id]
        })

    let init_data = {
        make: carMake && carMake !== '-' ? carMake : undefined,
        model: carModel && carModel !== '-' ? carModel : undefined,
        year: carYear && carYear !== '-' ? carYear : undefined,
        drive_side:
            carDriveSide && carDriveSide !== '-' ? carDriveSide : undefined,
        mileage: carMileage && carMileage !== '-' ? carMileage : undefined,
        engine_size_unit: user_default_fuel_unit?.id,
        engine_size:
            carEngineSize && carEngineSize !== '-' ? carEngineSize : undefined,
        transmission:
            transmission && transmission !== '-'
                ? `${transmission}`
                : undefined,
        mileage_unit: user_default_distance_unit?.id,
    }

    const [formData, setFormData] = useState<IShowroomForm_TechInfo>({
        make: tempMakeDisplay ? tempMakeDisplay : init_data.make,
        model: tempModelDisplay ? tempModelDisplay : init_data.model,
        year: init_data.year,
        drive_side: init_data.drive_side,
        mileage:
            carMileage !== undefined &&
            carMileage !== null &&
            carMileage !== '-' &&
            user_default_distance_unit
                ? generateFrontEndValueDistanceUnit(
                      user_default_distance_unit.id,
                      +carMileage
                  )
                : undefined,
        engine_size_unit: user_default_fuel_unit?.id,
        engine_size:
            carEngineSize && carEngineSize !== '-' && user_default_fuel_unit
                ? generateFrontEndValueFuelUnit(
                      user_default_fuel_unit.id,
                      +carEngineSize
                  )
                : undefined,
        transmission: init_data.transmission,
        mileage_unit: user_default_distance_unit?.id,
    })

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    const validateForm = async (id: string, value?: number) => {
        if (id === 'year') {
            try {
                await CarYearValidation(car_years_validation.car_year).validate(
                    {
                        year: value,
                    }
                )
                setError((prevState) => {
                    return { ...prevState, [id]: undefined }
                })
            } catch (err: any) {
                setError((prevState) => {
                    return { ...prevState, year: err.errors[0] }
                })
            }
        } else {
            try {
                await getRightSchemaAccountData(id).validate({
                    [id]: value,
                })
                setError((prevState) => {
                    return { ...prevState, [id]: undefined }
                })
            } catch (err: any) {
                setError((prevState) => {
                    return { ...prevState, [id]: err.errors[0] }
                })
            }
        }
    }

    const handleChange = (id: string, value: string | undefined) => {
        if (!hasChange) {
            setHasChange(true)
        }

        let newData = {
            ...formData,
            [id]: value,
        }

        if (id === 'make') {
            newData.model = undefined

            if (tempModelID) {
                appDispatch(dropDownActions.reset_make_model_temps())
            }
        }

        setFormData({
            ...newData,
        })

        setError((prevState) => {
            return { ...prevState, [id]: undefined }
        })
        setIsSaveDisabled(false)
    }

    useEffect(() => {
        if (
            tempMakeID &&
            tempMakeID.length > 2 &&
            `${tempMakeDisplay}` !== init_data.make
        ) {
            handleChange('make', tempMakeDisplay)
        }
    }, [tempMakeID])

    useEffect(() => {
        if (
            tempModelID &&
            tempModelID.length > 2 &&
            `${tempModelDisplay}` !== init_data.model
        ) {
            handleChange('model', tempModelDisplay)
        }
    }, [tempModelID])

    const drive_side_options = [
        {
            name: 'LHD',
        },
        {
            name: 'RHD',
        },
        {
            name: 'Center',
        },
    ]

    let arr_form_values = [
        'drive_side',
        'mileage',
        'make',
        'model',
        'transmission',
        'engine_size',
        'engine_size_unit',
        'year',
    ]

    const [undisclosedMileage, setUndisclosedMileage] = useState<
        boolean | undefined
    >(showroom_entry_undisclosed_mileage)

    const car_years_validation = useAppSelector(
        (state) => state.localdata.form_validation_copy.years
    )

    const validateOnClick = (): boolean => {
        let res: boolean = true

        arr_form_values.forEach((id: string) => {
            if (props.entryid) {
                if (
                    // @ts-ignore
                    id === 'mileage' &&
                    (formData[id] === undefined || formData[id] === null) &&
                    !undisclosedMileage
                ) {
                    setError((prevState) => {
                        return {
                            ...prevState,
                            [id]: 'Please enter mileage or hide it',
                        }
                    })
                    res = false // @ts-ignore
                } else if (id !== 'mileage' && !formData[id]) {
                    setError((prevState) => {
                        return { ...prevState, [id]: 'Required' }
                    })
                    res = false
                }
            } else {
                if (
                    id !== 'year' &&
                    id !== 'engine_capacity' &&
                    id !== 'transmission' &&
                    id !== 'drive_side' &&
                    id !== 'mileage' &&
                    // @ts-ignore
                    !formData[id]
                ) {
                    setError((prevState) => {
                        return { ...prevState, [id]: 'Required' }
                    })

                    res = false
                }
            }
        })

        return res
    }

    const onSubmit = () => {
        let p: IwriteMultipleTechnicalInformationPayloadReq = {
            tech_info_id: tech_info_id,
            car_id: car.id,
            data: [],
            getShowroomEntryOnSuccess: props.entryid
                ? {
                      entry_id: props.entryid,
                  }
                : undefined,
        }

        let generateAnswer = (fieldID: string) => {
            if (formData[fieldID as keyof IShowroomForm_TechInfo]) {
                if (fieldID === 'mileage' && formData.mileage_unit) {
                    return generateBackEndValueDistanceUnit(
                        formData.mileage_unit,
                        // @ts-ignore
                        +formData[fieldID as keyof IShowroomForm_TechInfo]
                    )
                } else if (
                    fieldID === 'engine_size' &&
                    formData.engine_size_unit
                ) {
                    return generateBackEndValueFuelUnit(
                        formData.engine_size_unit,
                        // @ts-ignore
                        +formData[fieldID as keyof IShowroomForm_TechInfo]
                    )
                } else if (fieldID === 'year') {
                    // @ts-ignore
                    return +formData[fieldID as keyof IShowroomForm_TechInfo]
                } else {
                    // @ts-ignore
                    return formData[fieldID]
                }
            }
        }

        arr_form_values.forEach((val: string) => {
            // @ts-ignore
            if (formData[val]) {
                // @ts-ignore
                if (!init_data[val]) {
                    let newItem: IList = {
                        id: val,
                        text: val,
                        // @ts-ignore
                        answer: generateAnswer(val),
                    }
                    p.data = [...p.data, newItem]
                }
                // @ts-ignore
                else if (init_data[val] && formData[val] !== init_data[val]) {
                    // specific case where only unit is changed for engine size
                    if (
                        val === 'engine_size_unit' &&
                        formData.engine_size_unit &&
                        formData.engine_size &&
                        formData.engine_size === init_data.engine_size
                    ) {
                        const new_engine_size_item = {
                            id: 'engine_size',
                            text: 'engine_size',
                            answer: generateBackEndValueFuelUnit(
                                formData.engine_size_unit,
                                +formData.engine_size
                            ),
                        }

                        let newItem: IList = {
                            id: val,
                            text: val,
                            // @ts-ignore
                            answer: generateAnswer(val),
                        }
                        p.data = [...p.data, newItem, new_engine_size_item]
                    } else {
                        let newItem: IList = {
                            id: val,
                            text: val,
                            // @ts-ignore
                            answer: generateAnswer(val),
                        }
                        p.data = [...p.data, newItem]
                    }
                }
            }
        })

        if (p.data.length > 0) {
            appDispatch(
                technicalInformationActions.writeMultipleTechnicalInformationRequest(
                    p
                )
            )
        }

        if (undisclosedMileage !== undefined && props.entryid) {
            appDispatch(
                showroomActions.updateEntryShowroomRequest({
                    uid: props.entryid,
                    car_id: props.car.id,
                    data: {
                        undisclosed_mileage: undisclosedMileage,
                    },
                })
            )
        }

        setHasChange(false)

        props.entryid &&
            setTimeout(() => {
                props.entryid &&
                    appDispatch(
                        showroomActions.validateEntryForPublicationRequest(
                            props.entryid
                        )
                    )
            }, 150)
        props.close()
    }

    const location = useLocation()

    const makePickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}`
            : `${location.pathname}`,
        path: `/car/${props.car.id}/search`,
        formId: 'makeModelYear',
        fieldId: 'make',
    })

    const modelPickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}`
            : `${location.pathname}`,
        path: `/car/${props.car.id}/search`,
        formId: 'makeModelYear',
        fieldId: 'model',
    })

    let isLoadingNewMakeOrModel = useAppSelector((state) => {
        return state.localdata.dropdownData.isLoadingNewMakeOrModel
    })

    const toggleUndisclosedMileage = (v: boolean) => {
        setUndisclosedMileage(v)
        if (!hasChange) {
            setHasChange(true)
        }
        setIsSaveDisabled(false)
        setError((prevState) => {
            return { ...prevState, mileage: undefined }
        })
    }

    const [isSaveDisabled, setIsSaveDisabled] = useState(false)

    const { theme } = useThemes()

    return (
        <EditFormContainerMobile>
            <FieldTitle>Car Specification</FieldTitle>

            <Line />

            <div style={{ paddingTop: '30px' }} />

            <PickerBtn
                label="Make"
                text={formData.make ?? 'Select make'}
                // customTextTransform={'lowercase'}
                fwArrow
                onClick={() => {
                    !!makePickerUrl && navigate(makePickerUrl)
                }}
                height="48px"
            />
            <div style={{ paddingTop: '24px' }} />
            <PickerBtn
                // customTextTransform={'lowercase'}
                label="Model"
                text={formData.model ?? 'Select model'}
                onClick={() => {
                    !!modelPickerUrl && navigate(modelPickerUrl)
                }}
                fwArrow
                height="48px"
            />
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                value={formData.year}
                name={'Year *'}
                placeholder={'Year'}
                type={'number'}
                onChange={(e: any) => {
                    handleChange('year', e.target.value)
                    validateForm('year', +e.target.value)
                }}
            />
            <Expander height={error?.year ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.year}</ErrorText>
            </Expander>

            <div style={{ paddingTop: '24px' }} />
            <RowForm>
                <InputFieldNoFormikAnimated
                    name="Engine size *"
                    placeholder={'Engine size'}
                    tabindex={1}
                    aria-live="polite"
                    value={
                        formData.engine_size !== undefined &&
                        typeof formData.engine_size === 'number' &&
                        formData.engine_size_unit !== 'cubic_centimeters'
                            ? formData.engine_size.toFixed(1)
                            : formData.engine_size
                    }
                    font_family="Lato"
                    type="number"
                    isnarrow={true}
                    onChange={(e: any) => {
                        handleChange('engine_size', e.target.value)

                        e.target.value === ''
                            ? validateForm('engine_size', undefined)
                            : validateForm('engine_size', +e.target.value)
                    }}
                />

                <AdjustPosition>
                    <Select
                        defaultValue={formData.engine_size_unit}
                        onChange={(val: string | undefined) => {
                            handleChange('engine_size_unit', val)
                        }}
                        placeholder={'Unit'}
                        options={
                            fuel_units
                                ? fuel_units.map((unit: IList) => {
                                      return {
                                          value: unit?.id,
                                          label: `${unit?.short}`,
                                      }
                                  })
                                : []
                        }
                        disabled={props.entryid ? true : false}
                        hasInfoWithTooltip
                        tooltipColor={colours[theme].text_strongest}
                        tooltipText={
                            props.entryid
                                ? 'You can change the engine size unit in your account settings.'
                                : 'The value displayed on your car page will be converted into your preferred engine size unit. You can change the display unit in your account settings.'
                        }
                        tooltipPlacement="left"
                    />
                </AdjustPosition>
            </RowForm>
            <Expander height={error?.engine_size ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.engine_size}</ErrorText>
            </Expander>

            {!error?.engine_size && (
                <Expander
                    height={
                        formData.engine_size_unit !== 'liters' &&
                        formData.engine_size
                            ? 'auto'
                            : 0
                    }
                >
                    <EquivalentConverted>
                        Equal to{' '}
                        {formData.engine_size &&
                            (formData.engine_size_unit === 'cc' ||
                            formData.engine_size_unit === 'cubic_centimeters'
                                ? cubicCentimetersToLiters(
                                      +formData.engine_size
                                  ).toFixed(1)
                                : formData.engine_size_unit === 'ci' ||
                                  formData.engine_size_unit === 'cubic_inches'
                                ? cubicInchesToLiters(+formData.engine_size)
                                : null)}{' '}
                        litres
                    </EquivalentConverted>
                </Expander>
            )}

            <div style={{ paddingTop: '26px' }} />

            <Select
                defaultValue={formData.transmission}
                placeholder={
                    formData.transmission ? 'Transmission *' : 'Transmission'
                }
                onChange={(val: string) => {
                    handleChange('transmission', val)
                }}
                options={
                    transmissionsList
                        ? transmissionsList.map((unit: IDropdownItem) => ({
                              value: unit?.uid,
                              label: `${unit?.name}`,
                          }))
                        : []
                }
            />
            <Expander height={error?.transmission ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.transmission}</ErrorText>
            </Expander>

            <div style={{ paddingTop: '28px' }} />

            <Select
                options={drive_side_options.map((option: { name: string }) => ({
                    value: option.name,
                    label: option.name,
                }))}
                placeholder={
                    formData.drive_side ? 'Drive Side *' : 'Drive Side'
                }
                defaultValue={formData.drive_side}
                onChange={(name) => {
                    handleChange('drive_side', name)
                }}
            />
            <Expander height={error?.drive_side ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.drive_side}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '28px' }} />
            {props.entryid && (
                <>
                    <RowForm>
                        <InputFieldNoFormikAnimated
                            name="Mileage *"
                            placeholder={'Mileage'}
                            tabindex={1}
                            aria-live="polite"
                            value={formData.mileage}
                            font_family="Lato"
                            type="number"
                            isnarrow={true}
                            onChange={(e: any) => {
                                handleChange('mileage', e.target.value)

                                e.target.value === ''
                                    ? validateForm('mileage', undefined)
                                    : validateForm('mileage', +e.target.value)
                            }}
                            disabled={undisclosedMileage}
                        />

                        <AdjustPosition>
                            <Select
                                defaultValue={formData.mileage_unit}
                                onChange={(val: string | undefined) => {
                                    if (!hasChange) {
                                        setHasChange(true)
                                    }
                                    handleChange('mileage_unit', val)
                                }}
                                placeholder={'Unit'}
                                options={
                                    distance_units
                                        ? distance_units.map((unit: IList) => ({
                                              value: unit.id,
                                              label: `${unit.name}`,
                                          }))
                                        : []
                                }
                                disabled={undisclosedMileage}
                            />
                        </AdjustPosition>
                    </RowForm>
                    <Expander
                        height={error?.mileage ? 'auto' : 0}
                        width={'100%'}
                    >
                        <ErrorText>{error?.mileage}</ErrorText>
                    </Expander>
                </>
            )}
            {!error?.mileage && (
                <Expander
                    height={
                        formData.mileage_unit !== 'miles' && formData.mileage
                            ? 'auto'
                            : 0
                    }
                >
                    <EquivalentConverted>
                        Equal to{' '}
                        {formData.mileage && kmToMiles(+formData.mileage)} miles
                    </EquivalentConverted>
                </Expander>
            )}
            {props.entryid && (
                <div
                    style={{
                        paddingTop: '16px',
                        width: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <SwitchButton
                        checked={undisclosedMileage ?? false}
                        toggle={toggleUndisclosedMileage}
                        width={34}
                        circleSize={12}
                        height={18}
                        dataCyId={'makeAddressSameAsDealership'}
                        variant="outline"
                    />
                    <p
                        style={{
                            paddingLeft: 8,
                            fontFamily: 'Lato',
                            fontSize: 14,
                        }}
                    >
                        Hide mileage
                    </p>
                </div>
            )}

            <div style={{ paddingTop: '150px' }} />

            <Actions>
                <ButtonAtom
                    theme="capitalize-white-background"
                    width="74px"
                    height="48px"
                    dataCyId={'showroom-car-share'}
                    icon={''}
                    fontSize={16}
                    onClick={() => {
                        props.close()
                        setFormData({
                            make: init_data.make,
                            model: init_data.model,
                            year: init_data.year,
                            drive_side: init_data.drive_side,
                            mileage: init_data.mileage,
                            engine_size_unit: user_default_fuel_unit?.id,
                            engine_size: init_data.engine_size,
                            transmission: init_data.transmission,
                            mileage_unit: user_default_distance_unit?.id,
                        })
                        setError(undefined)

                        // appDispatch(
                        //     dropDownActions.setTempMakeIDSuccess(undefined)
                        // )
                        // appDispatch(
                        //     dropDownActions.setTempModelIDSuccess(undefined)
                        // )
                        // appDispatch(
                        //     dropDownActions.setTempMakeDisplay(undefined)
                        // )
                        // appDispatch(
                        //     dropDownActions.setTempModelDisplay(undefined)
                        // )

                        // appDispatch(dropDownActions.setSelectedModel(null))
                        // appDispatch(dropDownActions.setSelectedMake(null))
                    }}
                >
                    <div style={{}}>Close</div>
                </ButtonAtom>

                <ButtonAtom
                    theme="secondary"
                    width="80px"
                    height="48px"
                    textTransform="capitalize"
                    dataCyId={'showroom-car-enquire'}
                    onClick={() => {
                        if (
                            !validateOnClick() ||
                            error?.year ||
                            error?.mileage ||
                            error?.engine_size
                        ) {
                            setIsSaveDisabled(true)
                        } else if (!isLoadingNewMakeOrModel) {
                            setError({})
                            hasChange && onSubmit()
                        }
                    }}
                    disabled={
                        isLoadingNewMakeOrModel ||
                        !hasChange ||
                        isSaveDisabled ||
                        error?.year ||
                        error?.mileage ||
                        error?.engine_size
                            ? true
                            : false
                    }
                    fontSize={16}
                >
                    {isLoadingNewMakeOrModel ? (
                        <div style={{ width: 40 }}>
                            <LoaderElasticThreeDots />
                        </div>
                    ) : (
                        'Save'
                    )}
                </ButtonAtom>
            </Actions>
        </EditFormContainerMobile>
    )
}

export default ShowroomTechInfoFormMobile
