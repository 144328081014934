import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import * as unitGenerator from '../../../helpers/units/unitConversion'
import * as SvgIcon from '../../atoms/icons/components'
import { device } from '../../templates/displays/devices'
import React from 'react'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyledProps = {
    isShortDescriptionLong?: boolean
    customWidth?: string
    $theme: ITheme
}

const Container = styled.div<IStyledProps>`
    display: grid;
    grid-template-columns: ${(props: IStyledProps) =>
        !props?.isShortDescriptionLong ? '33% 33% 33%' : '50% 50%'};
    grid-gap: 8px;
    width: ${(props) => props.customWidth ?? '35vw'};
    max-width: 700px;
    min-width: 450px;
    grid-auto-rows: 1fr;

    @media (max-width: 1300px) {
        min-width: ${(props: IStyledProps) =>
            !props?.isShortDescriptionLong ? '470px' : '390px'};
    }

    @media ${device.mobile_and_ipad} {
        min-width: auto;
    }
`

const ListItem = styled.div<{ notClickable?: boolean; $theme: ITheme }>`
    all: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
    height: 88px;
    border: 1px solid ${(props) => colours[props.$theme].border_muted};
    border-radius: 2px;

    transition: all 200ms;

    cursor: ${(props) => (props.notClickable ? 'default' : 'pointer')};

    :hover {
        transition: all 200ms;
        background-color: ${(props) =>
            props.notClickable ? 'auto' : colours[props.$theme].primary_20};
    }
`

const ListItemContentTitle = styled.div<IStyledProps>`
    font-family: Lato-bold;
    font-size: 12px;

    @media (max-width: 1200px) {
        font-size: ${(props: IStyledProps) =>
            !props?.isShortDescriptionLong ? '10px' : '11px'};
    }

    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    color: ${(props) => colours[props.$theme].text_muted};
`

const ListItemContentSpec = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    @media (max-width: 1600px) {
        font-size: 15px;
    }

    @media (max-width: 1200px) {
        font-size: ${(props: IStyledProps) =>
            !props?.isShortDescriptionLong ? '14px' : '16px'};
    }

    @media (max-width: 375px) {
        font-size: 14px;
    }

    letter-spacing: 5e-5em;
    color: ${(props) => colours[props.$theme].text_default};
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ListItemAction = styled.button<IStyledProps>`
    all: unset;
    font-size: 14px;
    color: ${(props) => colours[props.$theme].primary};
    cursor: pointer;
    text-transform: capitalize;
`

interface Props {
    ids: string[]
    data: any
    carid: string
    fuel_unit_text?: string
    fuel_unit?: string
    readOnlyMode?: boolean
    isShortDescriptionLong?: boolean
    addKeyTechInfo?: () => void
    customWidth?: string
    notClickable?: boolean
    isInsurance?: boolean
}

export const carOverviewGridlistItems: {
    [key: string]: {
        id: string
        icon: any
        action_text: string
    }
} = {
    make: {
        id: 'make',
        icon: <SvgIcon.MakeIcon size={'30px'} />,
        action_text: 'Add make',
    },
    model: {
        id: 'model',
        icon: <SvgIcon.ModelIcon size={'30px'} />,
        action_text: 'Add model',
    },
    year: {
        id: 'year',
        icon: <SvgIcon.YearIcon size={'30px'} />,
        action_text: 'Add year',
    },
    manufacture_year: {
        id: 'manufacture_year',
        icon: <SvgIcon.YearIcon size={'30px'} />,
        action_text: 'Add year',
    },
    engine_capacity: {
        id: 'engine_capacity',
        icon: <SvgIcon.EngineSizeIcon size={'30px'} />,
        action_text: 'Add engine size',
    },
    transmission: {
        id: 'transmission',
        icon: <SvgIcon.TransmissionIcon size={'30px'} />,
        action_text: 'Add transmission',
    },
    drive_side: {
        id: 'drive_side',
        icon: <SvgIcon.DriveSideIcon size={'30px'} />,
        action_text: 'Add drive side',
    },
    drive_train: {
        id: 'drive_train',
        icon: <SvgIcon.DriveSideIcon size={'30px'} />,
        action_text: 'Add drive_train',
    },
}

const OverviewGridManagerDesktop = (props: Props) => {
    const {
        ids,
        isShortDescriptionLong,
        addKeyTechInfo,
        readOnlyMode,
        data,
        fuel_unit,
        fuel_unit_text,
        customWidth,
        notClickable,
        isInsurance,
    } = props

    const navigate = useNavigate()
    const location = useLocation()

    let linkPrefix =
        location.pathname && location.pathname.includes('overview')
            ? location.pathname.replace('/overview', '')
            : location.pathname

    const { theme } = useThemes()

    return (
        <Container
            $theme={theme}
            isShortDescriptionLong={isShortDescriptionLong}
            customWidth={customWidth}
        >
            {ids.map((id: string, index: number) => {
                let obj =
                    id === 'engine_capacity' &&
                    fuel_unit &&
                    data &&
                    data[id] &&
                    data[id].answer !== '-' &&
                    fuel_unit !== 'cubic_centimeters'
                        ? fuel_unit === 'liters'
                            ? {
                                  ...data[id],
                                  answer: unitGenerator
                                      .generateFrontEndValueFuelUnit(
                                          fuel_unit,
                                          data[id].answer
                                      )
                                      .toFixed(1),
                              }
                            : {
                                  ...data[id],
                                  answer: Math.round(
                                      unitGenerator.generateFrontEndValueFuelUnit(
                                          fuel_unit,
                                          data[id].answer
                                      )
                                  ),
                              }
                        : data[id]

                let unitText =
                    id === 'engine_capacity' &&
                    fuel_unit_text &&
                    data &&
                    data[id] &&
                    data[id].answer !== undefined &&
                    data[id].answer !== '-'
                        ? fuel_unit_text
                        : ''

                return (
                    <React.Fragment key={`${id}_${index}_desktop`}>
                        <ListItem
                            $theme={theme}
                            notClickable={notClickable}
                            onClick={
                                notClickable
                                    ? () => {}
                                    : readOnlyMode
                                    ? () =>
                                          navigate(
                                              `${linkPrefix}/technical-information`
                                          )
                                    : addKeyTechInfo && addKeyTechInfo
                            }
                        >
                            <ListItemContentTitle
                                $theme={theme}
                                isShortDescriptionLong={
                                    props?.isShortDescriptionLong
                                }
                            >
                                {/* {obj.text} */}
                                {carOverviewGridlistItems[id].icon}
                            </ListItemContentTitle>

                            <ListItemContentSpec
                                $theme={theme}
                                isShortDescriptionLong={
                                    props?.isShortDescriptionLong
                                }
                                style={
                                    isInsurance && index < 2
                                        ? {
                                              textTransform: 'uppercase',
                                              fontSize: 14,
                                          }
                                        : {}
                                }
                            >
                                {((obj?.id === 'year' ||
                                    obj?.id === 'manufacture_year') &&
                                    !obj.answer) ||
                                obj?.answer === undefined ||
                                obj?.answer === null ||
                                obj?.answer === '-' ||
                                obj?.answer === '' ||
                                obj?.answer === 'Unknown' ? (
                                    readOnlyMode ? (
                                        '-'
                                    ) : (
                                        <ListItemAction $theme={theme}>
                                            {
                                                carOverviewGridlistItems[id]
                                                    .action_text
                                            }
                                        </ListItemAction>
                                    )
                                ) : typeof obj?.answer !== 'string' &&
                                  typeof obj?.answer !== 'number' ? (
                                    obj?.answer?.name
                                ) : (
                                    `${obj.answer}
                            ${unitText && unitText}`
                                )}
                            </ListItemContentSpec>
                        </ListItem>
                    </React.Fragment>
                )
            })}
        </Container>
    )
}

export default OverviewGridManagerDesktop
