import styled from 'styled-components'
import AddBoxWithPlaceholderAndPlusMobile from '../../../atoms/placeholders/addBoxWithPlaceholderAndPlusMobile'
import ShowroomH2 from '../../../atoms/typography/showroom/ShowroomH2'
import placeholder from '../../../atoms/skeleton/keyMomentsFacts/skeleton.jpg'
import placeholder_dark from '../../../atoms/skeleton/keyMomentsFacts/skeleton_dark.png'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    keyMomentsList: string[]
    setActiveForm?: any
    isAdmin?: boolean
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    order: 1;
    align-self: stretch;
`

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    width: 100%;
    box-sizing: border-box;

    order: 1;
    align-self: stretch;
`
const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    padding: 32px;
    flex: 1;

    width: 100%;
    box-sizing: border-box;

    background: var(--off-bg-color, #fafafa);
`

const Number = styled.div`
    color: var(--text-muted, #b3b3b3);
    letter-spacing: 5e-5em;
    font-family: Lato-bold;
    font-size: 18px;
    line-height: 24px;
    padding-right: 18px;
`

const ListContent = styled.span`
    width: 100%;
    box-sizing: border-box;

    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 5e-5em;

    color: var(--text-default, #666666);
`

const KeyMomentsSectionMobile = (props: Props) => {
    const { keyMomentsList, isAdmin } = props
    const { theme } = useThemes()

    let checkIfAllEmpty =
        !keyMomentsList || keyMomentsList.every((item) => item === '')

    return (
        <Container>
            <ShowroomH2
                leftAlign
                textTransform="capitalize"
                hasEdit={isAdmin && props.setActiveForm ? true : false}
                onEdit={() => {
                    props.setActiveForm && props.setActiveForm()
                }}
            >
                key moments & facts
            </ShowroomH2>

            <div style={{ paddingTop: '20px' }} />
            <ListContainer>
                {checkIfAllEmpty ? (
                    isAdmin ? (
                        <AddBoxWithPlaceholderAndPlusMobile
                            onClickAdd={() => {
                                props.setActiveForm && props.setActiveForm()
                            }}
                            height="200px"
                            btnText="Write up to 5 key facts about your car"
                            customPlaceholderImg={
                                theme === 'dark'
                                    ? placeholder_dark
                                    : placeholder
                            }
                        />
                    ) : null
                ) : (
                    keyMomentsList?.map((keyMoment: string, index: number) =>
                        keyMoment ? (
                            <ListItem key={`${index}-mobile-kmf`}>
                                <Number>0{index + 1}</Number>
                                <ListContent>{keyMoment}</ListContent>
                            </ListItem>
                        ) : null
                    )
                )}
            </ListContainer>
        </Container>
    )
}

export default KeyMomentsSectionMobile
