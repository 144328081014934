import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import Register from './components/pages/register'
import Signin from './components/pages/signin'
import ErrorPage from './components/pages/error'
import EnterAddress from './components/pages/enter-address'
import MobileTopHeaderNav from './components/atoms/menu/topnavmobile/mobileTopHeaderNav'
import ScrollToTop from './components/templates/displays/scrollToTop'
import AccountPage from './components/pages/account/account'
import UserPreferencesPage from './components/pages/account/userPreferences'
import NotFoundPage from './components/pages/notFound'
import ResetPasswordForm from './components/pages/resetPasswordForm'
import ResetPasswordRequest from './components/pages/resetPasswordRequest'
import MainMenu from './components/templates/menus/mainMenu'
import GarageProjects from './components/pages/garage/garageProjects'
import CarOverview from './components/pages/car/carOverview'
import IpadAndMobileDisplay from './components/templates/displays/ipadAndMobileDisplay'
import TechnicalInformation from './components/pages/car/technicalInformation'
import Gallery from './components/pages/car/gallery'
import MyGarage from './components/pages/garage/myGarage'
import ConfirmCarData from './components/pages/car/confirmCarData'
import Tasks from './components/pages/tasks/tasks'
import SingleTask from './components/pages/tasks/singleTask'
import CarTasks from './components/pages/car/carTasks'
import CarSingleTask from './components/pages/car/carSingleTask'
import CarDeletionConfirmation from './components/pages/car/carDeletionConfirmation'
import ApexOverview from './components/pages/apex/overview'
import ApexCarsOfTheWeek from './components/pages/apex/carsOfTheWeek'
import ApexInterviewsOverview from './components/pages/apex/interviewsOverview'
import ApexSingleInterview from './components/pages/apex/apexSingleInterview'
import ApexSingleArticle from './components/pages/apex/apexSingleArticle'
import ApexArticleOverviews from './components/pages/apex/apexArticleOverviews'
import ApexPodcasts from './components/pages/apex/apexPodcasts'
import Help from './components/pages/account/help'
import Faq from './components/pages/account/faq'
import ContactHelp from './components/pages/account/contactHelp'
import TutorialsHelp from './components/pages/account/tutorials'
import InstallApp from './components/pages/account/installApp'
import InstallAndroidPropmt from './components/templates/pwaCustom/installAndroidPrompt'
import * as Sentry from '@sentry/react'
import Upcoming from './components/pages/upcoming/upcoming'
import AccountMenuMobile from './components/atoms/menu/bottomSheetMenu/accountMenuMobile'
import CarTimeline from './components/pages/entries/carTimeline'
import CarTimelineSingleEntry from './components/pages/entries/carTimelineSingleEntry'
import CreateTimelineEntry from './components/pages/entries/createTimelineEntry'
import EditTimelineEntry from './components/pages/entries/editTimelineEntry'
import CreateCost from './components/pages/entries/createCost'
import EditCost from './components/pages/entries/editCost'
import GeneralTimeline from './components/pages/entries/generalTimeline'
import TaskReminders from './components/pages/tasks/taskReminders'
import SharedWithOthers from './components/pages/sharing/sharedWithOthers'
import CarShares from './components/pages/sharing/carShares'
import CarShareCreate from './components/pages/sharing/carShareCreate'
import CarShareEdit from './components/pages/sharing/carShareEdit'
import CarShareEditRecipients from './components/pages/sharing/carShareEditRecipients'
import GarageShareCreate from './components/pages/sharing/garageShareCreate'
import GarageShareEdit from './components/pages/sharing/garageShareEdit'
import GarageShareEditRecipients from './components/pages/sharing/garageShareEditRecipients'
import SharedWithYou from './components/pages/sharing/sharedWithYou'
import ExternalCarOverview from './components/pages/car/externalCar/externalCarOverview'
import ExternalCarTechnicalInformation from './components/pages/car/externalCar/externalCarTechnicalInformation'
import ExternalCarGallery from './components/pages/car/externalCar/externalCarGallery'
import ExternalCarHistoryFile from './components/pages/car/externalCar/externalCarHistoryFile'
import ExternalCarHistoryFileEntry from './components/pages/car/externalCar/externalCarHistoryFileEntry'
import ExternalCarSingleEntryGallery from './components/pages/car/externalCar/externalCarSingleEntryGallery'
import CarSharing from './components/pages/car/carSharing'
import ExternalGarage from './components/pages/garage/externalGarage/externalGarage'
import SingleEntryGallery from './components/pages/entries/singleEntryGallery'
import { checkIfPwaStandalone } from './components/templates/pwaCustom/checkIpadPwa'
import DirectShareDeletionConfirmation from './components/organisms/directshareDeletionConfirmation/directshareDeletionConfirmationMobile'
import CarSearch from './components/pages/car/search'
import Showroom from './components/pages/showroom/showroom'
import ShowroomCarGallery from './components/pages/showroom/car/showroomCarGallery'
import ShowroomCarDescription from './components/pages/showroom/car/showroomCarDescription'
import TimezonePicker from './components/pages/account/userPreferences/timezonePicker'
import CountryPicker from './components/pages/garage/countryPicker'
import InviteShareError from './components/pages/emailInvites/inviteShareError'
import CarEnquiry from './components/pages/showroom/car/carEnquiry'
import ShowroomFiltersMobile from './components/pages/showroom/search/showroomFiltersMobile'
import ShowroomSearchFiltersMobile from './components/pages/showroom/search/showroomSearchFiltersMobile'
import CarGalleryShowroomEdit from './components/pages/showroom/car/carGalleryShowroomEdit'
import PublishToShowroomMobileStep from './components/pages/showroom/car/publishToShowroomMobileStep'
import CarHandover from './components/pages/car/handover'
import AccountDeletionConfirmed from './components/pages/accountDeletionConfirmed'
import EditShowroomEntryBasicInfoMobile from './components/pages/showroom/car/editShowroomEntryBasicInfoMobile'
import DescriptionImageShowroomEdit from './components/pages/showroom/car/descriptionImageEdit'
import WatchlistPage from './components/pages/showroom/watchlist'
import ShowroomSoldListPage from './components/pages/showroom/sold'
import ArchiveCarFlowMobile from './components/pages/car/ArchiveCarFlowMobile'
import GarageArchive from './components/pages/archive/garageArchive'
import CarOverviewArchive from './components/pages/archive/car/overview'
import CarGalleryArchive from './components/pages/archive/car/gallery'
import CarTechnicalInformationArchive from './components/pages/archive/car/technicalInformation'
import CarHistoryFileArchive from './components/pages/archive/car/historyFile'
import CarHistoryFileEntryArchive from './components/pages/archive/car/historyFileEntry'
import CarHistoryFileEntryGalleryArchive from './components/pages/archive/car/historyFileEntryGallery'
import InsuranceApplicationDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import InsuranceDetailsApplicationFlow from './components/pages/insurance_quote/flows/application/insuranceDetailsApplicationFlow'
import InsuranceApplicationCarDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationCarDashboard'
import InsuranceApplicationMainDriverDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationMainDriverDashboard'
import InsuranceApplicationOtherDriverDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationOtherDriverDashboard'
import InsuranceCarTechInfoApplicationFlow from './components/pages/insurance_quote/flows/main_car/insuranceCarTechInfoApplicationFlow'
import InsuranceCarOwnershipApplicationFlow from './components/pages/insurance_quote/flows/main_car/insuranceCarOwnershipApplicationFlow'
import InsuranceOtherDriverBasicDetailsApplicationFlow from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverBasicDetailsApplicationFlow'
import InsuranceOtherDriverLicenceAddressApplicationFlow from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverLicenceAddressApplicationFlow'
import InsuranceOtherDriverAdditionalDetailsApplicationFlow from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverAdditionalDetailsApplicationFlow'
import InsuranceOtherDriverTrackRecordApplicationFlow from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverTrackRecordApplicationFlow'
import InsuranceApplicationAddressesDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationAddressesDashboard'
import InsuranceMainDriverBasicDetailsApplicationFlow from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverBasicDetailsApplicationFlow'
import InsuranceMainDriverLicenceAddressApplicationFlow from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverLicenceAddressApplicationFlow'
import InsuranceMainDriverAdditionalDetailsApplicationFlow from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverAdditionalDetailsApplicationFlow'
import InsuranceMainDriverTrackRecordApplicationFlow from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverTrackRecordApplicationFlow'
import InsuranceEverydayCarOwnershipApplicationFlow from './components/pages/insurance_quote/flows/everyday_car/insuranceEverydayCarOwnershipApplicationFlow'
import InsuranceEverydayCarTechInfoApplicationFlow from './components/pages/insurance_quote/flows/everyday_car/insuranceEverydayCarTechInfoApplicationFlow'
import InsuranceOtherDriverAddressManager from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverAddressManager'
import InsuranceMainDriverAddressManager from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverAddressManager'
import InsuranceApplicationDriversDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationDriversDashboard'
import InsuranceCarMileageParkingApplicationFlow from './components/pages/insurance_quote/flows/main_car/insuranceCarMileageParkingApplicationFlow'
import InsuranceEverydayCarMileageParkingApplicationFlow from './components/pages/insurance_quote/flows/everyday_car/insuranceEverydayCarMileageParkingApplicationFlow'
import InsuranceApplicationVehiclesToInsure from './components/pages/insurance_quote/flows/application/insuranceApplicationVehiclesToInsure.tsx'
import InsuranceApplicationSubmissionAgreement from './components/pages/insurance_quote/insuranceApplicationSubmissionAgreement'
import InstallIosNativeAppBanner from './components/templates/topBanners/installIosNativeAppBanner'
import OnboardingStep_1 from './components/pages/onboarding/new/onboarding_step_1'
import OnboardingStep_2_1 from './components/pages/onboarding/new/onboarding_step_2_1'
import OnboardingStep_2_2 from './components/pages/onboarding/new/onboarding_step_2_2'
import OnboardingStep_3 from './components/pages/onboarding/new/onboarding_step_3'
import OnboardingStep_4 from './components/pages/onboarding/new/onboarding_step_4'
import InsuranceApplicationNotInsuredEverydayCarDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationNonInsuredEverydayCarDashboard'
import InsuranceWelcome from './components/pages/insurance_quote/insuranceWelcomeWithCars'
import { WelcomeApplicationTest } from './components/pages/insurance_quote/test'
import InsuranceOverview from './components/pages/insurance_quote/overview'
import LoaderWithPercentage from './components/atoms/loader/loaderWithPercentage'
import InsuranceEligibilityConfirmation from './components/pages/insurance_quote/insuranceEligibilityConfirmation'
import { customNavDataActions } from './redux/localdata/customNav/reducer'
import CreateTimelineEntryByCategory from './components/pages/entries/createTimelineEntryByCategory'
import PlatformErrorManager from './components/templates/backEndError'
import WizardPage from './components/pages/insurance_quote/wizard'
import RegisterNewLandingPage from './components/pages/register-new-landing-page'
import LoaderCarOutline from './components/atoms/loader/loaderCarOutline'
import CarJourney from './components/pages/journey/carProfile'
import SellYourCarJourney from './components/pages/journey/sellYourCar'
import AppleRedirectHandler from './components/pages/social-login/apple'
import CarInsights from './components/pages/car/insights'
import HistoryFileJourney from './components/pages/journey/historyFile'
import ShareYourCarJourney from './components/pages/journey/shareYourCar'
import UserProfileJourney from './components/pages/journey/userProfile'
import InsuranceJourney from './components/pages/journey/insurance'
import PastCarsJourney from './components/pages/journey/pastCars'
import HandoverJourney from './components/pages/journey/handover'
import JourneyTopBar from './components/templates/bars/journey/topBar'
import colours from './providers/theme/colours'
import ShowroomCarProfileEdit from './components/pages/showroom/car/showroomCarProfileEdit'
import ShowroomCarProfile from './components/pages/showroom/car/showroomCarProfile'
import { usersActions } from './redux/user/reducer'
import { useAppDispatch, useAppSelector } from './redux/store/hooks'
import useThemes from './providers/theme/hooks'
import OnboardingStep_0 from './components/pages/onboarding/new/onboarding_step_0'
import NotificationsPage from './components/pages/account/nofitications'

const App = () => {
    const dispatch = useAppDispatch()

    const user = useAppSelector((state) => state.user.userLoggedIn)
    // const userLoading = useAppSelector((state) => state.user.loading)
    const isAuthLoading = useAppSelector((state) => state.user.isAuthLoading)
    const featureFlags = useAppSelector((state) => state.featureFlags)
    const insuranceDataLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication?.isLoading
    )
    const getInsuranceDataLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isGetLoading
    )
    const allInsuranceDataLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isAllApplicationsInfoLoading
    )
    const userError = useAppSelector((state) => state.user.error)
    const location = useLocation()
    let pathname = checkIfPwaStandalone()
        ? window.location.pathname
        : location.pathname

    useEffect(() => {
        document.getElementById('loading-init')!.style.display = 'none'

        let q_params = location.search

        let is_insurance_related =
            pathname.match(/insurance/g) !== null ? true : false

        if (is_insurance_related) {
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `${pathname}${q_params}`
                )
            )

            if (user === null && userError?.status_code !== 503) {
                dispatch(usersActions.getCurrentUserDataRequest())
            }
        }

        let refresh_token: string | null =
            typeof localStorage !== 'undefined' && localStorage !== null
                ? localStorage.getItem('refresh_token')
                : null

        if (
            !is_insurance_related &&
            user === null &&
            refresh_token !== null &&
            userError?.status_code !== 503
        ) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [user, userError])

    const is_showroom_visible = featureFlags.enable_showroom
    const enable_insurance = featureFlags['enable_insurance']

    const is_dealer: boolean =
        user?.role === 'Admin' || user?.role === 'Dealer' ? true : false

    const params = new URLSearchParams(location.search)
    const carid: string | null = params.get('carid')

    let { theme } = useThemes()

    return (
        <Sentry.ErrorBoundary fallback={undefined}>
            <div
                className="App"
                style={{
                    backgroundColor: colours[theme].background_default,
                }}
            >
                {/* <ConnectedRouter history={history}> */}
                <InstallAndroidPropmt />
                <PlatformErrorManager />

                {/* MAIN PAGES NAV HEADER MOBILE */}

                <IpadAndMobileDisplay>
                    <MobileTopHeaderNav />
                    <DirectShareDeletionConfirmation />

                    <InstallIosNativeAppBanner />
                </IpadAndMobileDisplay>

                <React.Fragment>
                    <MainMenu user={user ? user : null} />
                </React.Fragment>

                {pathname.match(/insurance/g) !== null &&
                    pathname.match(/overview/g) === null &&
                    pathname.match(/register/g) === null && (
                        <LoaderWithPercentage
                            isLoading={insuranceDataLoading ? true : false}
                        />
                    )}

                {pathname.match(/insurance/g) !== null &&
                    pathname.match(/register/g) === null && (
                        <LoaderCarOutline
                            isOpen={
                                getInsuranceDataLoading ||
                                allInsuranceDataLoading
                                    ? true
                                    : false
                            }
                        />
                    )}

                <JourneyTopBar closeUrl={carid ? `/car/${carid}` : `/garage`} />

                {isAuthLoading && (
                    <LoaderCarOutline isOpen={isAuthLoading ? true : false} />
                )}

                <AccountMenuMobile activepage={pathname} />
                <ScrollToTop>
                    <Routes>
                        <Route
                            path="/register/landing/1"
                            element={<RegisterNewLandingPage />}
                        />
                        <Route path="/register" element={<Register />} />
                        <Route
                            path="/social-login/apple"
                            element={<AppleRedirectHandler />}
                        />

                        <Route path="/signin" element={<Signin />} />
                        <Route path="/login" element={<Signin />} />
                        <Route path="/" element={<Signin />} />
                        <Route
                            path="/history-files"
                            element={<GeneralTimeline />}
                        />
                        <Route
                            path="/reset-password-request"
                            element={<ResetPasswordRequest />}
                        />
                        <Route
                            path="/reset-password/:activationcode"
                            element={<ResetPasswordForm />}
                        />

                        <Route path="/error" element={<ErrorPage />} />
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/insurance_details"
                                element={<InsuranceDetailsApplicationFlow />}
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/wizard"
                                element={<WizardPage />}
                            />
                        )}
                        {/* {enable_insurance === true && ( */}
                        <Route
                            path="/insurance"
                            element={<InsuranceWelcome />}
                        />
                        {/* )} */}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/confirm-eligibility"
                                element={<InsuranceEligibilityConfirmation />}
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/new"
                                element={<InsuranceWelcome />}
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/test"
                                element={<WelcomeApplicationTest />}
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/overview"
                                element={<InsuranceOverview />}
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id"
                                element={<InsuranceApplicationDashboard />}
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/car/:carid"
                                element={<InsuranceApplicationCarDashboard />}
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/main_driver/:userid"
                                element={
                                    <InsuranceApplicationMainDriverDashboard />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/other_driver/:userid"
                                element={
                                    <InsuranceApplicationOtherDriverDashboard />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/drivers"
                                element={
                                    <InsuranceApplicationDriversDashboard />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/vehicles"
                                element={
                                    <InsuranceApplicationVehiclesToInsure />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/other_driver/:userid/licence_address"
                                element={
                                    <InsuranceOtherDriverLicenceAddressApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/other_driver/:userid/licence_address/address"
                                element={<InsuranceOtherDriverAddressManager />}
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/other_driver/:userid/additional_details"
                                element={
                                    <InsuranceOtherDriverAdditionalDetailsApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/other_driver/:userid/basic_details"
                                element={
                                    <InsuranceOtherDriverBasicDetailsApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/other_driver/:userid/track_record"
                                element={
                                    <InsuranceOtherDriverTrackRecordApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/main_driver/:userid/licence_address"
                                element={
                                    <InsuranceMainDriverLicenceAddressApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/main_driver/:userid/licence_address/address"
                                element={<InsuranceMainDriverAddressManager />}
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/main_driver/:userid/additional_details"
                                element={
                                    <InsuranceMainDriverAdditionalDetailsApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/main_driver/:userid/basic_details"
                                element={
                                    <InsuranceMainDriverBasicDetailsApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/main_driver/:userid/track_record"
                                element={
                                    <InsuranceMainDriverTrackRecordApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/car/:carid/tech_info"
                                element={
                                    <InsuranceCarTechInfoApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/addresses"
                                element={
                                    <InsuranceApplicationAddressesDashboard />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/car/:carid/ownership"
                                element={
                                    <InsuranceCarOwnershipApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/everyday_car/:carid"
                                element={
                                    <InsuranceApplicationNotInsuredEverydayCarDashboard />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/everyday_car/:carid/tech_info"
                                element={
                                    <InsuranceEverydayCarTechInfoApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/everyday_car/:carid/ownership"
                                element={
                                    <InsuranceEverydayCarOwnershipApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/everyday_car/:carid/mileage_parking"
                                element={
                                    <InsuranceEverydayCarMileageParkingApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/car/:carid/mileage_parking"
                                element={
                                    <InsuranceCarMileageParkingApplicationFlow />
                                }
                            />
                        )}
                        {enable_insurance === true && (
                            <Route
                                path="/insurance/application/:id/submission-agreement"
                                element={
                                    <InsuranceApplicationSubmissionAgreement />
                                }
                            />
                        )}
                        <Route
                            path="/onboarding-step-1"
                            element={<OnboardingStep_1 />}
                        />

                        <Route
                            path="/onboarding-step-0"
                            element={<OnboardingStep_0 />}
                        />

                        <Route
                            path="/onboarding-step-2-1"
                            element={<OnboardingStep_2_1 />}
                        />
                        <Route
                            path="/onboarding-step-2-2"
                            element={<OnboardingStep_2_2 />}
                        />
                        <Route
                            path="/onboarding-step-3"
                            element={<OnboardingStep_3 />}
                        />
                        <Route
                            path="/onboarding-step-4"
                            element={<OnboardingStep_4 />}
                        />
                        <Route
                            path="/journey/car-profile"
                            element={<CarJourney />}
                        />
                        <Route
                            path="/journey/history-file"
                            element={<HistoryFileJourney />}
                        />
                        <Route
                            path="/journey/your-profile"
                            element={<UserProfileJourney />}
                        />

                        <Route
                            path="/journey/sell-your-car"
                            element={<SellYourCarJourney />}
                        />

                        <Route
                            path="/journey/handover"
                            element={<HandoverJourney />}
                        />
                        <Route
                            path="/journey/share-your-car"
                            element={<ShareYourCarJourney />}
                        />

                        <Route
                            path="/journey/previously-owned"
                            element={<PastCarsJourney />}
                        />

                        <Route
                            path="/journey/insurance"
                            element={<InsuranceJourney />}
                        />

                        <Route
                            path="/handover/:carid"
                            element={<CarHandover />}
                        />
                        <Route
                            path="/account-deletion-confirmed"
                            element={<AccountDeletionConfirmed />}
                        />
                        <Route
                            path="/invite-user-error"
                            element={<InviteShareError />}
                        />
                        <Route
                            path="/car/:carid/search"
                            element={<CarSearch />}
                        />
                        <Route
                            path="/car/:carid/confirm"
                            element={<ConfirmCarData />}
                        />
                        <Route
                            path="/car/:carid/archive"
                            element={<ArchiveCarFlowMobile />}
                        />
                        <Route
                            path="/car/:carid/gallery"
                            element={<Gallery />}
                        />
                        <Route
                            path="/car/:carid/deletion"
                            element={<CarDeletionConfirmation />}
                        />
                        <Route
                            path="/car/:carid/technical-information"
                            element={<TechnicalInformation />}
                        />
                        <Route
                            path="/car/:carid/tasks/task"
                            element={<CarSingleTask />}
                        />
                        <Route
                            path="/car/:carid/insights"
                            element={<CarInsights />}
                        />
                        <Route
                            path="/car/:carid/tasks/:taskid/reminders"
                            element={<TaskReminders />}
                        />
                        <Route
                            path="/car/:carid/sharing"
                            element={<CarSharing />}
                        />
                        <Route
                            path="/sharing/with-others/car"
                            element={<CarShares />}
                        />
                        <Route
                            path="/sharing/with-others/car/create-share"
                            element={<CarShareCreate />}
                        />
                        <Route
                            path="/sharing/with-others/car/:carid/edit-share"
                            element={<CarShareEdit />}
                        />
                        <Route
                            path="/sharing/with-others/car/:carid/edit-share/recipients"
                            element={<CarShareEditRecipients />}
                        />
                        <Route
                            path="/sharing/with-others"
                            element={<SharedWithOthers />}
                        />
                        <Route
                            path="/sharing/with-others/garage/create-share"
                            element={<GarageShareCreate />}
                        />
                        <Route
                            path="/sharing/with-others/garage/edit-share"
                            element={<GarageShareEdit />}
                        />
                        <Route
                            path="/sharing/with-others/garage/edit-share/recipients"
                            element={<GarageShareEditRecipients />}
                        />
                        <Route
                            path="/sharing/with-you"
                            element={<SharedWithYou />}
                        />
                        {/*
                                    showroom route use the same component(smart), which will display the proper data regarding the different sub routes, from the child(dumb) components
                                */}
                        {(is_dealer === true ||
                            is_showroom_visible === true) && (
                            <Route path="/showroom" element={<Showroom />} />
                        )}
                        {(is_dealer === true ||
                            is_showroom_visible === true) && (
                            <Route
                                path="/showroom/for-sale"
                                element={<Showroom />}
                            />
                        )}
                        {(is_dealer === true ||
                            is_showroom_visible === true) && (
                            <Route
                                path="/showroom/sold"
                                element={<ShowroomSoldListPage />}
                            />
                        )}
                        {(is_dealer === true ||
                            is_showroom_visible === true) && (
                            <Route
                                path="/showroom/watch-list"
                                element={<WatchlistPage />}
                            />
                        )}
                        {(is_dealer === true ||
                            is_showroom_visible === true) && (
                            <Route
                                path="/showroom/filters"
                                element={<ShowroomFiltersMobile />}
                            />
                        )}
                        {(is_dealer === true ||
                            is_showroom_visible === true) && (
                            <Route
                                path="/showroom/search"
                                element={<ShowroomSearchFiltersMobile />}
                            />
                        )}
                        <Route
                            path="/shared/garage"
                            element={<ExternalGarage />}
                        />
                        <Route
                            path="/shared/car/:carid"
                            element={<ExternalCarOverview />}
                        />
                        <Route
                            path="/shared/car/:carid/gallery"
                            element={<ExternalCarGallery />}
                        />
                        <Route
                            path="/shared/car/:carid/history-file"
                            element={<ExternalCarHistoryFile />}
                        />
                        <Route
                            path="/shared/car/:carid/history-file/entry"
                            element={<ExternalCarHistoryFileEntry />}
                        />
                        <Route
                            path="/shared/car/:carid/history-file/:entryid/gallery"
                            element={<ExternalCarSingleEntryGallery />}
                        />
                        <Route
                            path="/shared/car/:carid/technical-information"
                            element={<ExternalCarTechnicalInformation />}
                        />
                        <Route
                            path="/car/:carid/history-file"
                            element={<CarTimeline />}
                        />
                        <Route
                            path="/car/:carid/history-file/create"
                            element={<CreateTimelineEntry />}
                        />
                        <Route
                            path="/car/:carid/history-file/create/:categoryid"
                            element={<CreateTimelineEntryByCategory />}
                        />
                        <Route
                            path="/car/:carid/history-file/entry"
                            element={<CarTimelineSingleEntry />}
                        />
                        <Route
                            path="/car/:carid/history-file/:entryid/gallery"
                            element={<SingleEntryGallery />}
                        />
                        <Route
                            path="/car/:carid/history-file/entry/edit"
                            element={<EditTimelineEntry />}
                        />
                        <Route
                            path="/car/:carid/history-file/:entryid/cost/create"
                            element={<CreateCost />}
                        />
                        <Route
                            path="/car/:carid/history-file/:entryid/cost/edit"
                            element={<EditCost />}
                        />
                        <Route
                            path="/car/:carid/tasks"
                            element={<CarTasks />}
                        />
                        <Route path="/car/:carid" element={<CarOverview />} />
                        {is_dealer === true && (
                            <Route
                                path="/car/:carid/showroom/create"
                                element={<PublishToShowroomMobileStep />}
                            />
                        )}
                        <Route
                            path="/garage/projects"
                            element={<GarageProjects />}
                        />
                        <Route path="/tasks" element={<Tasks />} />
                        <Route path="/tasks/task" element={<SingleTask />} />
                        <Route
                            path="/tasks/:taskid/reminders"
                            element={<TaskReminders />}
                        />
                        <Route
                            path="/garage/followers"
                            element={<GarageProjects />}
                        />
                        <Route
                            path="/garage/curated-cars"
                            element={<GarageProjects />}
                        />
                        <Route
                            path="/garage/country"
                            element={<CountryPicker />}
                        />
                        <Route path="/garage" element={<MyGarage />} />
                        <Route
                            path="/garage/previously-owned"
                            element={<GarageArchive />}
                        />
                        <Route
                            path="/archives/car/:carid"
                            element={<CarOverviewArchive />}
                        />
                        <Route
                            path="/archives/car/:carid/overview"
                            element={<CarOverviewArchive />}
                        />
                        <Route
                            path="/archives/car/:carid/gallery"
                            element={<CarGalleryArchive />}
                        />
                        <Route
                            path="/archives/car/:carid/technical-information"
                            element={<CarTechnicalInformationArchive />}
                        />
                        <Route
                            path="/archives/car/:carid/history-file"
                            element={<CarHistoryFileArchive />}
                        />
                        <Route
                            path="/archives/car/:carid/history-file/entry"
                            element={<CarHistoryFileEntryArchive />}
                        />
                        <Route
                            path="/archives/car/:carid/history-file/:entryid/gallery"
                            element={<CarHistoryFileEntryGalleryArchive />}
                        />

                        <Route
                            path="/enter-address"
                            element={<EnterAddress />}
                        />
                        <Route path="/account" element={<AccountPage />} />
                        <Route
                            path="/account/preferences"
                            element={<UserPreferencesPage />}
                        />
                        <Route
                            path="/account/preferences/timezone"
                            element={<TimezonePicker />}
                        />
                        <Route path="/account/help" element={<Help />} />
                        <Route path="/account/faq" element={<Faq />} />
                        <Route
                            path="/account/tutorials"
                            element={<TutorialsHelp />}
                        />
                        <Route
                            path="/account/contact"
                            element={<ContactHelp />}
                        />
                        <Route
                            path="/account/notifications"
                            element={<NotificationsPage />}
                        />
                        <Route
                            path="/account/install-app"
                            element={<InstallApp />}
                        />
                        <Route path="/upcoming" element={<Upcoming />} />
                        <Route path="/apex" element={<ApexOverview />} />
                        <Route
                            path="/apex/cars-of-the-week"
                            element={<ApexCarsOfTheWeek />}
                        />
                        {/* <Route
                                    
                                   
                                    path="/apex/news-highlights"
                                    element={ApexNewsHighlights}
                                /> */}
                        <Route
                            path="/apex/interviews"
                            element={<ApexInterviewsOverview />}
                        />
                        <Route
                            path="/apex/interviews/:uid"
                            element={<ApexSingleInterview />}
                        />
                        <Route
                            path="/apex/articles/:uid"
                            element={<ApexSingleArticle />}
                        />
                        <Route
                            path="/apex/articles"
                            element={<ApexArticleOverviews />}
                        />
                        <Route
                            path="/apex/podcasts"
                            element={<ApexPodcasts />}
                        />
                        <Route
                            path="/showroom/:entryid/enquiry"
                            element={<CarEnquiry />}
                        />
                        {is_dealer === true && (
                            <Route
                                path="/showroom/:entryid/edit"
                                element={<ShowroomCarProfileEdit />}
                            />
                        )}
                        {is_dealer === true && (
                            <Route
                                path="/showroom/:entryid/edit/general"
                                element={<EditShowroomEntryBasicInfoMobile />}
                            />
                        )}
                        {is_dealer === true && (
                            <Route
                                path="/showroom/:entryid/edit/gallery"
                                element={<CarGalleryShowroomEdit />}
                            />
                        )}
                        {is_dealer === true && (
                            <Route
                                path="/showroom/:entryid/edit/description-image"
                                element={<DescriptionImageShowroomEdit />}
                            />
                        )}
                        {is_showroom_visible === true || is_dealer === true ? (
                            <Route
                                path="/showroom/:entryid"
                                element={<ShowroomCarProfile />}
                            />
                        ) : null}
                        {is_showroom_visible === true || is_dealer === true ? (
                            <Route
                                path="/showroom/:entryid/gallery"
                                element={<ShowroomCarGallery />}
                            />
                        ) : null}
                        {is_showroom_visible === true || is_dealer === true ? (
                            <Route
                                path="/showroom/:entryid/description"
                                element={<ShowroomCarDescription />}
                            />
                        ) : null}
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </ScrollToTop>
                {/* </ConnectedRouter> */}
            </div>
        </Sentry.ErrorBoundary>
    )
}

export default App
