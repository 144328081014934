import * as React from 'react'
import './styles.css'
import styled from 'styled-components'
import { device } from './devices'

const OnlyMobileIpad = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

export interface GlobalLayoutProps {
    children?: React.ReactNode
    style?: React.CSSProperties | undefined
}

const IpadAndMobileDisplay = (props: GlobalLayoutProps) => (
    <OnlyMobileIpad style={props.style}>{props.children}</OnlyMobileIpad>
)

export default IpadAndMobileDisplay
