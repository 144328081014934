import { Slider } from 'antd'
import './rangeAntd.css'

type Props = {
    valueFrom?: number | null
    valueTo?: number | null
    defaultValueFrom: number
    defaultValueTo: number
    onChange: (
        valueFrom: number | null | undefined,
        valueTo: number | null | undefined
    ) => any
    customWidth?: string
    min?: number
    max?: number
}

const RangeSlider = (props: Props) => {
    return (
        <Slider
            id="showroom-range"
            range
            min={props.min}
            max={props.max}
            step={1}
            value={[
                props.valueFrom ?? props.defaultValueFrom,
                props.valueTo ?? props.defaultValueTo,
            ]}
            defaultValue={[props.defaultValueFrom, props.defaultValueTo]}
            onChange={(val: number[]) => {
                props.onChange(val[0], val[1])
            }}
            style={{ width: '100%' }}
        />
    )
}

export default RangeSlider
