import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import BorderAndIconButton from '../../../../atoms/Button/borderAndIconButton'
import LoadingAttachmentDesktop from './loadingAttachmentDesktop'
import AttachmentInItemSinglePageDesktop from '../../../../atoms/timeline/attachmentInItemSinglePage/attachmentInItemSinglePageDesktop'
import { message, Upload } from 'antd'
import { ConverFileTypeToFileSuffixEnum } from '../../../../../redux/conversions/attachments'
import { AttachmentTypesEnum } from '../../../../../redux/attachments/enums'
import { convertToShorterString } from '../../../../../helpers/longStrings/convertToShorterString'

const WrapperDesktop = styled.section`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 20px;
    padding-top: 30px;
`

const FieldTitle = styled.div`
    width: 30%;
    max-width: 180px;
    font-family: Lato-light;
    align-self: start;
    font-size: 16px;
    text-transform: capitalize;
    color: var(--text-strong, #1a1a1a);
`

const InputFieldWrapper = styled.div`
    width: 70%;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

type ProgressProps = {
    progressPercent: number
}

const AnimatedLoadingWrapper = styled.div<ProgressProps>`
    ${(props) =>
        props.progressPercent === 100 && 'animation: fadeOut 300ms both;'}
    width: 400px;
    @keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            display: none;
        }
    }
`
const AnimatedUploadCompleteWrapper = styled.div<ProgressProps>`
    ${(props) =>
        props.progressPercent === 100 && 'animation: fadeIn 300ms both;'}
    width: 100%;
    box-sizing: border-box;
    max-width: 460px;
    animation-delay: 300ms;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`

interface Values {
    file: File | null
}

interface OtherProps {
    close: any
    data: any
    deleteFileUploaded?: any
    setFileUploaded?: any
    currentFileUploaded?: File
    user_id?: string
    progressPercent?: any
    isSomethingBeingUploaded?: boolean
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    initialValue: any
}

export const errorFileTypeWarning = () => {
    message.error({
        content:
            "This file type is not supported. Please try:'.pdf', '.jpg','.xlsm', '.png','.docx','.xlsx', '.xls",
        duration: 2,
    })
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        setFileUploaded,
        currentFileUploaded,
        deleteFileUploaded,
        setFieldValue,
        progressPercent,
        data,
        initialValue,
    } = props

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const errorFileSizeWarning = () => {
        message.error({
            content: 'The maximum file size allowed per upload is 20MB.',
            duration: 2,
        })
    }

    function fileToArrayBuffer(file: any) {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader()
            const readFile = function (event: any) {
                const buffer = reader.result
                resolve(buffer)
            }
            reader.addEventListener('load', readFile)
            reader.readAsArrayBuffer(file)
        })
    }

    let handleChange = async (file: any) => {
        try {
            const fileContents: any = await fileToArrayBuffer(file)

            file.data = new Blob([fileContents])

            setFileUploaded(file)
        } catch (e) {}
    }

    return (
        <WrapperDesktop>
            <FieldTitle>{data && data.title}</FieldTitle>
            <InputFieldWrapper>
                {initialValue && !currentFileUploaded ? (
                    <AttachmentInItemSinglePageDesktop
                        attachment={initialValue}
                        // file={attachmentsObj[item]}
                        onClickDelete={deleteFileUploaded}
                    />
                ) : (
                    !currentFileUploaded && (
                        <Upload
                            multiple={false}
                            showUploadList={false}
                            beforeUpload={() => false}
                            onChange={(info: any) => {
                                if (info.file.size <= 20000000) {
                                    let fs: AttachmentTypesEnum | undefined =
                                        ConverFileTypeToFileSuffixEnum(
                                            info.file.name
                                        )

                                    if (fs) {
                                        setFieldValue('file', info.file)

                                        setFieldValue(
                                            'file_url',
                                            info.file.name
                                        )

                                        handleChange(info.file)
                                    } else errorFileTypeWarning()
                                } else errorFileSizeWarning()
                            }}
                            customRequest={dummyRequest}
                            accept=".pdf, .jpg, .jpeg, .png, .xlsm, .xlsx, .xls, .doc, .docx, .heif, .heic"
                        >
                            <BorderAndIconButton
                                variant="blue"
                                icon="download_blue"
                                text="upload file"
                                onClick={() => {}}
                                buttonwidth="122px"
                                iconwidth="12px"
                                fontsize="12px"
                            />
                        </Upload>
                    )
                )}
                {currentFileUploaded && progressPercent <= 100 && (
                    <AnimatedLoadingWrapper progressPercent={progressPercent}>
                        <LoadingAttachmentDesktop
                            progressPercent={progressPercent}
                        />
                    </AnimatedLoadingWrapper>
                )}
                {currentFileUploaded && progressPercent === 100 && (
                    <AnimatedUploadCompleteWrapper
                        progressPercent={progressPercent}
                    >
                        <AttachmentInItemSinglePageDesktop
                            attachment={{
                                id: '',
                                url: '',
                                filename:
                                    currentFileUploaded &&
                                    currentFileUploaded.name &&
                                    convertToShorterString({
                                        string: currentFileUploaded.name,
                                        maxChars: 20,
                                        isFileName: true,
                                    }),
                                fileSuffix: currentFileUploaded.name
                                    .split('.')
                                    .pop() as AttachmentTypesEnum,
                            }}
                            // downloadAttachment={() => {}}
                            onClickDelete={deleteFileUploaded}
                            noDownloadIcon
                        />
                    </AnimatedUploadCompleteWrapper>
                )}
            </InputFieldWrapper>
        </WrapperDesktop>
    )
}

const AddAttachmentDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        file: null,
    }),
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default AddAttachmentDesktop
