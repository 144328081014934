import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import { useState } from 'react'
import GooglePlacesLocationsDropdown from '../../../molecules/googlePlacesLocationsDropdown/googlePlacesLocationsDropdownDesktop'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import { ModalTopBar } from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import {
    IGalleryImage,
    IUpdateCarGalleryImagePayload,
    IUpdateEntryGalleryImagePayload,
} from '../../../../redux/entities/galleries/types'

interface Values {
    caption: string
    location: string
    credits: string
}

interface OtherProps {
    close: () => void
    updateImage: (data: any) => any
    carid: string
    entryid?: string
    imagedata: IGalleryImage
}
const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 90vw;
    margin-top: 30px;
    margin-bottom: 30px;
    height: auto;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);
    border-radius: 5px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 5px;
    max-height: 90vh;

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-top: 32px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 32px;
    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 350px) {
        padding: 20px;
    }
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 15px;
`

const SmallPadding = styled.div`
    padding-top: 16px;
`

const BiggerPadding = styled.div`
    padding-top: 32px;
    @media (max-width: 350px) {
        padding-top: 24px;
    }
`
const LargePadding = styled.div`
    padding-top: 42px;

    @media screen and (max-width: 325px) {
        padding-top: 10px;
    }
`

export const Error = styled.div`
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 11px;
`

const TextAreaWidth = styled.div`
    width: 100%;
    box-sizing: border-box;
    min-height: 70px;
    overflow: scroll;
`

const CaptionText = styled.textarea`
    font-family: Lato;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    color: var(--text-strong, #1a1a1a);
    min-height: 70px;
    font-size: 14px;
    border: none;
    padding-top: 20px;
    border-radius: 5px;
    border: 1px solid var(--primary_30);
    font-size: 0.9em;
    background-color: var(--bg-color, #fff);
    :focus {
        outline: 0;
    }
`

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { values, close, handleChange, setFieldValue, errors } = props

    const [showLocationSuggestions, toggleShowLocationSuggestion] =
        useState(false)

    const handleSuggestionSelection = (suggestion: any) => {
        setFieldValue(
            'location',
            suggestion.structured_formatting
                ? suggestion.structured_formatting.main_text
                : suggestion.description
        )
    }

    const { theme } = useThemes()

    return (
        <EditFormContainerMobile>
            <ModalTopBar $theme={theme}>Update image details</ModalTopBar>
            <InnerFormPadding>
                <FieldTitle>Caption</FieldTitle>
                <Expander
                    height={
                        errors.caption && values.caption.length > 1 ? 'auto' : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.caption}
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <TextAreaWidth id="scrollable-content">
                    <CaptionText
                        id="caption"
                        name="caption"
                        placeholder="ie. Doing donuts in my Fiat Panda 4x4 in the snow"
                        aria-live="polite"
                        onChange={handleChange}
                        value={values.caption}
                    />
                </TextAreaWidth>
                <BiggerPadding />
                <FieldTitle>Location</FieldTitle>
                <Expander
                    height={
                        errors.location && values.location.length > 1
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.location}
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id="location"
                    name="location"
                    placeholder="Where was this taken?"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.location}
                    onFocus={() => {
                        toggleShowLocationSuggestion(true)
                    }}
                />
                <GooglePlacesLocationsDropdown
                    valueSearched={values.location}
                    showDropdown={showLocationSuggestions}
                    toggleShowDropDown={(how: boolean) =>
                        toggleShowLocationSuggestion(how)
                    }
                    onSuggestionClick={handleSuggestionSelection}
                />
                <BiggerPadding />
                <FieldTitle>Credits</FieldTitle>
                <Expander
                    height={
                        errors.credits && values.credits.length > 1 ? 'auto' : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.credits}
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id="credits"
                    name="credits"
                    placeholder="Photographer (if applicable)"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.credits}
                />
                <BiggerPadding />
                <LargePadding />
                <ButtonsStandardBottomRowMobile
                    cancel={close}
                    submit={props.handleSubmit}
                    confirmationtext={'save'}
                    dataCyIdUpdate="edit_car_image_details_mobile"
                    dataCyIdCancel="cancel_edit_car_image_details_mobile"
                    disabled={
                        (values.caption && errors.caption) ||
                        (values.location && errors.caption) ||
                        (values.credits && errors.credits) ||
                        (values.caption === '' &&
                            values.location === '' &&
                            values.credits === '')
                            ? true
                            : false
                    }
                />
            </InnerFormPadding>
        </EditFormContainerMobile>
    )
}

const CarGalleryEditExistingImageFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        caption: props.imagedata.caption ? props.imagedata.caption : '',
        location: props.imagedata.location ? props.imagedata.location : '',
        credits: props.imagedata.credits ? props.imagedata.credits : '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                caption: Yup.string().max(
                    266,
                    'Caption must be be less than 265 characters.'
                ),
                location: Yup.string().max(
                    36,
                    'Location must be be less than 35 characters.'
                ),

                credits: Yup.string().max(
                    36,
                    'Credits must be be less than 35 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        if (props.entryid) {
            let payload: IUpdateEntryGalleryImagePayload = {
                carid: props.carid,
                entryid: props.entryid,
                imageid: props.imagedata.id,
                body: {
                    caption: values.caption,
                    credit_data: {
                        name: values.credits,
                    },
                    location_data: {
                        name: values.location,
                    },
                },
            }
            props.updateImage(payload)
            props.close()
        } else {
            let payload: IUpdateCarGalleryImagePayload = {
                carid: props.carid,
                imageid: props.imagedata.id,
                body: {
                    caption: values.caption,
                    credit_data: {
                        name: values.credits,
                    },
                    location_data: {
                        name: values.location,
                    },
                },
            }
            props.updateImage(payload)
            props.close()
        }
        setSubmitting(false)

        return
    },
})(InnerForm)

export default CarGalleryEditExistingImageFormMobile
