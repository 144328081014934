import styled from 'styled-components'
import { motion } from 'framer-motion'
import { message } from 'antd'
import './antd.css'
import { Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import placeholder from '../../../atoms/skeleton/gallery/2.jpg'
import placeholder_dark from '../../../atoms/skeleton/gallery/2_dark.png'
import { useAppDispatch } from '../../../../redux/store/hooks'
import BinIcon from '../../../atoms/icons/components/binIcon'
import OnbnoardingAccept from '../../dropzone/onboardingSingleImageDropZone'
import { OnboardingText } from '../../../templates/onboarding/onboardingFormLayoutMobile'
import useThemes from '../../../../providers/theme/hooks'
import { ITheme } from '../../../../providers/theme/colours'
import { IUploadImagePreSignedUrlPayloadSingle } from '../../../../redux/filestorage/types'
import { fileStorageActions } from '../../../../redux/filestorage/reducer'

interface Props {
    carid: string
    presignedurls?: IUploadImagePreSignedUrlPayloadSingle[]
    imageToUpload?: File | undefined
    setImageToUpload: (file: File | undefined) => void
    isDesktop?: boolean
}

const FormWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`

const ImagePickerStyled = styled(Upload)`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    position: absolute;
    z-index: 2;
`

const ImagePickerElement = styled(motion.div)`
    height: 56px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--background_primary_soft);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

const ShowcaseImgWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 25vh;
`

const ShowcaseImg = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    object-fit: cover;
`

const ImageDetailsBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background-color: var(--off-bg-color, #fafafa);
`

const ImageNameBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 16px 24px;
    align-items: center;
    justify-content: center;
    position: absolute;
`

const ImageName = styled.p`
    margin: 0;
    padding: 0;
    color: var(--text-default, #666);

    font-family: Lato-bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80%;
`

const UploadImageBtnText = styled.p`
    width: 100%;
    box-sizing: border-box;
    color: var(--primary, #5ec3ca);
    text-align: center;
    font-family: Lato-bold;
    font-size: 16px;
    line-height: 24px;
`

const DeleteBtn = styled.button`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
`

const ImageTitle = styled.h3`
    margin: 0;
    padding: 0;
    color: var(--text-darker, #616161);
    font-family: Lato-bold;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.001px;
`

const showImage = (file: File | undefined, theme: ITheme) => {
    let fake_url = file
        ? URL.createObjectURL(file)
        : theme === 'dark'
        ? placeholder_dark
        : placeholder
    return (
        <ShowcaseImgWrapper>
            <ShowcaseImg src={fake_url} alt="car cover img" />
        </ShowcaseImgWrapper>
    )
}

const OnboardingImageUploadSubForm = (props: Props) => {
    const { setImageToUpload, imageToUpload, isDesktop } = props

    const dispatch = useAppDispatch()

    const errorFileSizeWarning = () => {
        message.error({
            content: 'The maximum file size allowed per upload is 20MB.',
            duration: 2,
        })
    }

    const errorFileTypeWarning = () => {
        message.error({
            content: 'This type on file is not supported.',
            duration: 2,
        })
    }

    const handleFileGiven = (file: File) => {
        if (
            file &&
            (file.type === 'image/png' ||
                file.type === 'image/jpeg' ||
                file.type === 'image/heic' ||
                file.type === 'image/heif')
        ) {
            if (file.size <= 20000000 && file.size > 1) {
                dispatch(
                    fileStorageActions.getPresignedUrlsCarGalleryImagesRequest({
                        carid: props.carid,
                        count: 1,
                        file_names: [file.name],
                    })
                )
                return setImageToUpload(file)
            } else return errorFileSizeWarning()
        } else return errorFileTypeWarning()
    }

    const handleAntFileGiven = (files: UploadFile<any>[]) => {
        let file = files[0]
        if (file && file.originFileObj) {
            const actualFile = file.originFileObj
            if (
                actualFile.type === 'image/png' ||
                actualFile.type === 'image/jpeg' ||
                actualFile.type === 'image/heic' ||
                actualFile.type === 'image/heif'
            ) {
                if (actualFile.size <= 20000000 && actualFile.size > 1) {
                    dispatch(
                        fileStorageActions.getPresignedUrlsCarGalleryImagesRequest(
                            {
                                carid: props.carid,
                                count: 1,
                                file_names: [actualFile.name],
                            }
                        )
                    )
                    return setImageToUpload(actualFile)
                } else return errorFileSizeWarning()
            } else return errorFileTypeWarning()
        }
    }

    const { theme } = useThemes()

    return (
        <FormWrapper>
            {isDesktop ? (
                <ImageTitle>Car profile image</ImageTitle>
            ) : (
                showImage(imageToUpload, theme)
            )}

            {isDesktop && (
                <>
                    <div style={{ paddingTop: '4px' }} />

                    <OnboardingText style={{ opacity: 0.6, fontSize: '14px' }}>
                        png | jpg | jpeg | HEIC | HEIF | Max file size:
                        iOS(5MB), Desktop (20MB)
                    </OnboardingText>
                </>
            )}

            <div style={{ paddingTop: '16px' }} />

            {imageToUpload ? (
                <ImageDetailsBox style={isDesktop ? { height: 80 } : {}}>
                    <ImageName>{imageToUpload.name}</ImageName>
                    <DeleteBtn onClick={() => setImageToUpload(undefined)}>
                        <BinIcon />
                    </DeleteBtn>
                </ImageDetailsBox>
            ) : isDesktop ? (
                <OnbnoardingAccept
                    handleFilesGiven={(files: File[]) => {
                        handleFileGiven(files[0])
                    }}
                />
            ) : (
                <ImagePickerElement
                    id="onboarding_image_picker_mobile_new"
                    whileTap={{ scale: 1.02 }}
                    style={isDesktop ? { height: 80 } : {}}
                >
                    <ImagePickerStyled
                        multiple={false}
                        onChange={(
                            info: UploadChangeParam<UploadFile<any>>
                        ) => {
                            handleAntFileGiven(info.fileList)
                        }}
                        maxCount={1}
                        accept="image/*"
                        // selectable
                    >
                        <ImageNameBox>
                            <UploadImageBtnText>
                                {'Upload image'}
                            </UploadImageBtnText>
                        </ImageNameBox>
                    </ImagePickerStyled>
                </ImagePickerElement>
            )}
        </FormWrapper>
    )
}

export default OnboardingImageUploadSubForm
