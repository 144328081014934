import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { IApiAddCar_args, ICreateCarVersion2_api_req } from '../types'

export const addACar = (p: IApiAddCar_args): Promise<any> => {
    let carData = {}
    if (p.registration_number) {
        carData = {
            garage_id: p.garage_id,
            registration_number: p.registration_number,
        }
    } else {
        carData = {
            garage_id: p.garage_id,
            registration_number: '',
        }
    }
    let reqp: string = JSON.stringify(carData)
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: reqp,
        credentials: 'include',
    }

    return fetch(urls.cars_endpoint, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(data, 'Add car error', reqp)
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const addACarVersion2 = (
    p: ICreateCarVersion2_api_req
): Promise<any> => {
    let reqp: string = JSON.stringify(p)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: reqp,
        credentials: 'include',
    }

    return fetch(urls.cars_endpoint, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(data, 'Add car error', reqp)
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const deleteACar = (carid: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.delete_car_endpoint(carid), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(response, 'Delete car error')
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}

export const createGaragePublicShare = (garage_id: string): Promise<any> => {
    let jsonBody: any = {
        resource_id: garage_id,
        resource_type: 'garage',
        share_type: 'link_share',
    }

    let jsonBodyConv: string = JSON.stringify(jsonBody)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
        body: jsonBodyConv,
    }

    return fetch(urls.create_share_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            response,
                            'Create garage public share error',
                            jsonBodyConv
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
