import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { Sheet } from 'react-modal-sheet'
import { device } from '../../templates/displays/devices'
import { IThreeDotsActionsItems } from '../menu/actionsMenu/actionsMenuDots'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'

const CustomSheet = styled(Sheet)<{ $customHeight?: string }>`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '230px !important'};
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
    }
    .react-modal-sheet-header {
        height: 10px !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 0px !important;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`
const MenuItem = styled.div`
    height: 56px;
    width: 100vw;
    opacity: 1 !important;
    font-family: 'Lato';
    color: var(--text-default, #666666);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    flex-direction: row;
`

const MenuItemBox = styled(motion.div)<IStyle>`
    flex-direction: row;
    :focus {
        outline: 0;
    }
`

const BottomSheetHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 15px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
`

const BottomSheetHeaderText = styled.div`
    font-size: 16px;
    font-family: 'Lato-bold';
    color: var(--text-strong, #121212);
    padding-top: 10px;
`

interface IStyle {
    is_last: boolean
}

interface IProps {
    isOpen: boolean
    menu_items: IThreeDotsActionsItems[]
    toggle: any
}

const ShowroomOptionsBottomSheetActionsMobile: React.FunctionComponent<
    IProps
> = (props: IProps) => {
    let { menu_items, toggle, isOpen } = props

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    return (
        <React.Fragment>
            {isMobile && (
                <CustomSheet
                    isOpen={isOpen}
                    onClose={toggle}
                    $customHeight={'max-content'}
                >
                    <Sheet.Container>
                        <Sheet.Header />

                        <BottomSheetHeader>
                            <BottomSheetHeaderText>
                                Options
                            </BottomSheetHeaderText>
                        </BottomSheetHeader>

                        <div
                            style={{
                                paddingTop: '6px',
                            }}
                            data-attr="showroom-options-menu"
                        >
                            {menu_items !== undefined &&
                                menu_items.map(
                                    (
                                        item: IThreeDotsActionsItems,
                                        index: any
                                    ) => (
                                        <MenuItemBox
                                            is_last={
                                                index === menu_items.length - 1
                                            }
                                            onClick={(e: any) => {
                                                item.actionOnClick()
                                                toggle()
                                            }}
                                            key={`showroom_bottomsheet_${index}_mobile`}
                                            whileTap={{
                                                opacity: 1,
                                                backgroundColor:
                                                    'var(--base-overlays-neutral-alpha-50, rgba(26, 26, 26, 0.02))',
                                            }}
                                        >
                                            <MenuItem>{item.text}</MenuItem>
                                        </MenuItemBox>
                                    )
                                )}
                        </div>

                        <div style={{ paddingTop: '10px' }} />
                    </Sheet.Container>
                    <Sheet.Backdrop
                        data-attr="showroom-actions-backdrop-overlay"
                        onTap={toggle}
                    />
                </CustomSheet>
            )}
        </React.Fragment>
    )
}

export default ShowroomOptionsBottomSheetActionsMobile
