import { useLocation } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../../../helpers/strings/capitalizeFirstLetter'
import useThemes from '../../../../providers/theme/hooks'
import { IAnyObject } from '../../../../redux/insuranceQuoteApplication/reducer'
import {
    has_modification_adjusted_bhp_positive_answer_subform,
    IInsuranceFieldType,
    insurance_fields_main_driver_track_record_special_terms_imposed_subform,
    insurance_fields_track_record_previous_motor_insurance_voided_subform,
    insurance_fields_track_record_previous_motor_terms_cancelled_subform,
    insurance_fields_track_record_previous_motor_terms_refused_subform,
    insurance_sub_fields_other_driver_track_record_motorconviction_date,
    insurance_sub_fields_other_driver_track_record_motorconviction_fine_amount,
    is_uk_resident_since_birth_negative_answer_subform,
    other_driver_is_uk_resident_since_birth_negative_answer_subform,
    residential_visit_overnight_yes_subfields,
} from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import Expander from '../../../atoms/expander/expander'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import { Error } from '../../editForms/carDescriptionEditForm/carDescriptionEditFormDesktop'
import { RenderInsuranceQuoteFormFieldDesktop } from './renderElementDesktop'
import { RenderInsuranceQuoteFormFieldMobile } from './renderElementMobile'
import { InsuranceQuoteSubFormGeneratorLayer3 } from './subGeneratorLayer3'
import colours from '../../../../providers/theme/colours'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import { useEffect } from 'react'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { EditIcon } from '../../../atoms/statefulicons'

type Props = {
    field_id: string
    onAnswerChange: (id: string, answer: any) => any
    data: IAnyObject
    errors?: IAnyObject
    setError: (id: string, error: any) => any
    mobileSearchPickerSectionURL?: string
    sectionID?: IInsuranceApplicationSectionId
    updateListOfFields?: (list: IInsuranceFieldType[]) => void
}

export const InsuranceQuoteSubFormGeneratorLayer2 = (props: Props) => {
    let {
        field_id,
        onAnswerChange,
        data,
        errors,
        setError,
        mobileSearchPickerSectionURL,
        sectionID,
        updateListOfFields,
    } = props

    let currURL = new URL(window.location.href)
    let params2 = new URLSearchParams(currURL.search)

    const location = useLocation()
    let section_id = params2.get('section_id')
    let isNamedDriver =
        location.pathname.match(/other_driver/g) !== null ||
        window.location.pathname.match(/other_driver/g) !== null ||
        section_id === 'drivers'
            ? true
            : false

    let list: IInsuranceFieldType[] | undefined =
        field_id === 'has_previous_special_terms_imposed'
            ? insurance_fields_main_driver_track_record_special_terms_imposed_subform
            : field_id === 'has_previous_motor_insurance_refused'
            ? insurance_fields_track_record_previous_motor_terms_refused_subform
            : field_id === 'has_previous_motor_insurance_cancelled'
            ? insurance_fields_track_record_previous_motor_terms_cancelled_subform
            : field_id === 'has_previous_motor_insurance_voided'
            ? insurance_fields_track_record_previous_motor_insurance_voided_subform
            : field_id.match('has_resulted_in_driving_ban')
            ? insurance_sub_fields_other_driver_track_record_motorconviction_date
            : field_id.match('has_resulted_in_fine')
            ? insurance_sub_fields_other_driver_track_record_motorconviction_fine_amount
            : field_id === 'residential_visit_overnight'
            ? residential_visit_overnight_yes_subfields
            : field_id === 'has_modification_adjusted_bhp'
            ? has_modification_adjusted_bhp_positive_answer_subform
            : field_id === 'is_uk_resident_since_birth'
            ? isNamedDriver
                ? other_driver_is_uk_resident_since_birth_negative_answer_subform
                : is_uk_resident_since_birth_negative_answer_subform
            : undefined

    useEffect(() => {
        if (list && updateListOfFields) {
            updateListOfFields(list)
        }
    }, [])

    let { width } = useWindowSize()

    let isMobile = width && width < 800 ? true : false

    const { theme } = useThemes()

    let { crossFormData, setIsCrossFormOpen } = useSetInitialInsurance()

    return data ? (
        <>
            {list ? (
                <div style={{ width: '100%' }}>
                    {list?.map((item: IInsuranceFieldType, index: number) => {
                        let prevItemHadDivider: boolean | undefined =
                            index !== 0 &&
                            list![index - 1] &&
                            list![index - 1].ends_section_with_divider

                        let item_err =
                            errors && item.id
                                ? item.type === 'dates_range'
                                    ? errors[`${item.id}_start_date`] ||
                                      errors[`${item.id}_end_date`]
                                    : errors[item.id]
                                    ? errors[item.id]
                                    : undefined
                                : undefined

                        return (
                            <div
                                style={{ width: '100%' }}
                                key={`sub-gen-layer2-${item.id}-${index}-${
                                    isMobile ? 'mobile' : 'desktop'
                                }`}
                            >
                                <Expander
                                    height={
                                        item.type === 'date'
                                            ? 'auto'
                                            : data &&
                                              data[item.id] !== undefined &&
                                              data[item.id] !== null &&
                                              data[item.id] !== '' &&
                                              item.type !== 'yes_no' &&
                                              item.type !==
                                                  'contact_medium_choice' &&
                                              prevItemHadDivider !== true
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    <div
                                        style={{
                                            paddingTop: '10px',
                                        }}
                                    />
                                </Expander>
                                {item.type === 'yes_no' &&
                                    !prevItemHadDivider && (
                                        <div style={{ paddingTop: '10px' }} />
                                    )}
                                {isMobile ? (
                                    <RenderInsuranceQuoteFormFieldMobile
                                        data={data}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        item={item}
                                        mobileSearchPickerSectionURL={
                                            mobileSearchPickerSectionURL
                                        }
                                        sectionID={sectionID}
                                    />
                                ) : (
                                    <RenderInsuranceQuoteFormFieldDesktop
                                        data={data}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        item={item}
                                        sectionID={sectionID}
                                    />
                                )}
                                <Expander
                                    height={errors && item_err ? 'auto' : 0}
                                >
                                    <Error
                                        $theme={theme}
                                        style={{ paddingTop: '8px' }}
                                    >
                                        {errors &&
                                        item_err &&
                                        typeof item_err !== 'string' &&
                                        item_err.status
                                            ? `${
                                                  item_err.status?.toLowerCase() !==
                                                  'invalid'
                                                      ? `${capitalizeFirstLetter(
                                                            item_err.status
                                                        )} - `
                                                      : ''
                                              } ${
                                                  item_err?.message
                                                      ? `${item_err?.message}`
                                                      : ''
                                              }`
                                            : errors &&
                                              item_err &&
                                              typeof item_err === 'string'
                                            ? `${item_err}`
                                            : ''}
                                    </Error>
                                    {crossFormData && crossFormData[item.id] ? (
                                        <StandardCtaBtn
                                            bg="transparent"
                                            boder="none"
                                            removeShadowOnHover
                                            removePaddings
                                            customWidth="auto"
                                            customHeight="auto"
                                            onClick={() =>
                                                setIsCrossFormOpen({
                                                    id: item.id,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    marginTop: 12,
                                                    marginBottom: 24,
                                                }}
                                            >
                                                <EditIcon
                                                    color={
                                                        colours[theme].primary
                                                    }
                                                    onClick={() => {}}
                                                />
                                                <span
                                                    style={{
                                                        color: colours[theme]
                                                            .primary,
                                                    }}
                                                >
                                                    {
                                                        crossFormData[item.id]
                                                            ?.affected_field_error_cta
                                                    }
                                                </span>
                                            </div>
                                        </StandardCtaBtn>
                                    ) : null}
                                </Expander>
                                <div
                                    style={{
                                        paddingBottom: '16px',
                                    }}
                                />
                                <Expander
                                    height={
                                        (item.has_subform_if_true &&
                                            data &&
                                            (data[item.id] === true ||
                                                data[item.id]?.name ===
                                                    'Yes')) ||
                                        (item.has_subform_if_false &&
                                            data &&
                                            (data[item.id] === false ||
                                                data[item.id]?.name ===
                                                    'False'))
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    <InsuranceQuoteSubFormGeneratorLayer3
                                        data={data}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        field_id={item.id}
                                        isMobile={isMobile}
                                        sectionID={sectionID}
                                        updateListOfFields={updateListOfFields}
                                    />
                                </Expander>
                                {item.ends_section_with_divider === true && (
                                    <div
                                        style={{
                                            marginTop: '24px',
                                            marginBottom: '40px',
                                            background:
                                                'var(--border-muted, #e5e5e5)',
                                            height: '1px',
                                        }}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            ) : null}
        </>
    ) : (
        <div />
    )
}
