import React, { useState, useEffect } from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import FullPageWrapper from '../../../templates/displays/pageWrappers/FullPagePageWrapper'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import GallerySlide from '../../../molecules/slideshow/gallerySlide'
import ReturnNav from '../../../atoms/header/returnNav'
import ModalDisplayGalleryImageDesktop from '../../../templates/displays/pageWrappers/modalDisplayGalleryImageDesktop'
import GalleryImageCarousel from '../../../organisms/galleryCarousel'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import SlidingUpOnScrollBanner from '../../../atoms/header/slidingUpOnScrollBanner'
import { useAppSelector } from '../../../../redux/store/hooks'
import { ICar, IExternalCar } from '../../../../redux/entities/cars/types'

interface Props {
    sharedCar?: IExternalCar
    prevOwned?: ICar
}

const ReadOnlyCarGalleryImage: React.FC<Props> = ({ sharedCar, prevOwned }) => {
    const [imageIndex, setImageIndex] = useState<number | undefined>(undefined)
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const images_data = useAppSelector(
        (state) => state.entities.galleriesData.images
    )
    const isNecessaryGalleryDataLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )

    const setUpInitialIndex = () => {
        const params = new URLSearchParams(location.search)
        const image_id_param = params.get('id')

        const current = sharedCar ?? prevOwned

        if (current && image_id_param) {
            setImageIndex(current?.gallery?.images.indexOf(image_id_param))
        }
    }

    useEffect(() => {
        setUpInitialIndex()
        disableBodyScroll(document.body)
        return () => {
            enableBodyScroll(document.body)
        }
    }, [sharedCar, prevOwned, location.search])

    const generateImageNavigationUrl = (next: string) => {
        const params = new URLSearchParams(location.search)
        const image_id_param = params.get('id')
        const linkPrefix = sharedCar ? 'shared' : 'archives'
        const current = sharedCar ?? prevOwned

        if (current && image_id_param) {
            const ids_list = current.gallery?.images ?? []

            const index_of_id = ids_list.indexOf(image_id_param)
            if (index_of_id !== -1) {
                if (next === 'next') {
                    const index_of_next_image = index_of_id + 1

                    if (index_of_next_image === ids_list.length) {
                        return '/none'
                    }

                    const id_of_next_image = ids_list[index_of_next_image]

                    return `/${linkPrefix}/car/${carid}/gallery?id=${id_of_next_image}`
                } else {
                    const index_of_previous_image = index_of_id - 1

                    if (index_of_previous_image === -1) {
                        return '/none'
                    }

                    const id_of_previous_image =
                        ids_list[index_of_previous_image]

                    return `/${linkPrefix}/car/${carid}/gallery?id=${id_of_previous_image}`
                }
            }
        }
        return '/none'
    }

    const countFeaturedImages = (car: IExternalCar | ICar): number => {
        const carImagesIds = car.gallery.images

        let featuredImagesCount = 0
        if (carImagesIds && images_data) {
            for (let imageId of carImagesIds) {
                if (images_data[imageId]?.featured === true) {
                    featuredImagesCount++
                }
            }
        }
        return featuredImagesCount
    }

    const params = new URLSearchParams(location.search)
    const image_id_param = params.get('id')

    const currentcar = sharedCar ?? prevOwned

    const prev_url = generateImageNavigationUrl('previous')
    const next_url = generateImageNavigationUrl('next')

    const windowSize = window.innerWidth

    const current_image_id =
        windowSize < 800 &&
        imageIndex !== undefined &&
        currentcar &&
        currentcar?.gallery.images
            ? currentcar.gallery.images[imageIndex]
            : image_id_param

    return (
        <React.Fragment>
            {images_data && currentcar && (
                <>
                    <DesktopDisplayOnly>
                        <DesktopDisplayOnly>
                            <FullPageWrapper>
                                <ModalDisplayGalleryImageDesktop
                                    toggle={() => {
                                        navigate(location.pathname)
                                    }}
                                    isOpen={true}
                                >
                                    {currentcar !== undefined &&
                                        current_image_id &&
                                        images_data &&
                                        images_data[current_image_id] && (
                                            <GallerySlide
                                                closeGallery={() => {
                                                    navigate(location.pathname)
                                                }}
                                                currentImageData={
                                                    images_data[
                                                        current_image_id
                                                    ]
                                                }
                                                carid={currentcar.id}
                                                imagesData={images_data}
                                                currentCar={currentcar}
                                                isSomethingLoading={
                                                    isNecessaryGalleryDataLoading
                                                }
                                                prevUrl={prev_url}
                                                nextUrl={next_url}
                                                featuredImagesCount={
                                                    currentcar && images_data
                                                        ? countFeaturedImages(
                                                              currentcar
                                                          )
                                                        : 0
                                                }
                                                readOnlyMode={true}
                                                updateImage={() => {}}
                                                onClickEdit={() => {}}
                                            />
                                        )}
                                </ModalDisplayGalleryImageDesktop>
                            </FullPageWrapper>
                        </DesktopDisplayOnly>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <div
                            style={{
                                display: 'flex',
                                position: 'relative',
                                background: 'var(--bg-color, #fafafa)',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                height: '100vh',
                            }}
                        >
                            <Link
                                to={{
                                    pathname: `${location.pathname}`,
                                }}
                            >
                                <ReturnNav />
                            </Link>
                            {currentcar && (
                                <SlidingUpOnScrollBanner
                                    text={
                                        currentcar.title.length > 32
                                            ? `${currentcar.title.slice(
                                                  0,
                                                  34
                                              )}...`
                                            : currentcar.title
                                    }
                                    customStyle={{
                                        transform: 'translateY(50px)',
                                    }}
                                />
                            )}
                            {currentcar !== undefined &&
                            current_image_id &&
                            images_data &&
                            images_data[current_image_id] &&
                            imageIndex !== undefined ? (
                                <GalleryImageCarousel
                                    indexOfInitialImage={imageIndex}
                                    image_data={images_data}
                                    images_ids={currentcar.gallery.images}
                                    synchroniseActiveIndex={(n: number) => {
                                        setImageIndex(n)
                                    }}
                                    openActionsSheet={() => {}}
                                    cover_id={currentcar.gallery.cover}
                                    readOnlyMode={true}
                                />
                            ) : (
                                <SkeletonAbsolute isthingloading={true} />
                            )}
                        </div>
                    </IpadAndMobileDisplay>
                </>
            )}
        </React.Fragment>
    )
}

export default ReadOnlyCarGalleryImage
