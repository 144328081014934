import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image_desktop from '../../../../public/assets/images/journey/shareYourCar/private_share_settings_desktop.png'
import image_mobile from '../../../../public/assets/images/journey/shareYourCar/private_share_settings_mobile.png'

import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
    JourneyWelcomeNewLineOnMobile,
} from '../../../templates/styledcomponents/journeyStyles'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function PrivateDirectSettingsShareYourCarJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section[
                                        'share_your_car'
                                    ].primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[
                                        `share_your_car`
                                    ].primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        Choose exactly what you share!
                                    </JourneyStepTitle>
                                    <JourneyText $theme={theme}>
                                        When you choose to share your car or
                                        garage via Direct Share, you're in
                                        control every step of the way.
                                    </JourneyText>
                                    <JourneyText $theme={theme}>
                                        Select exactly what information is
                                        shared and with whom. Plus, rest assured
                                        that you can modify or remove shares at
                                        any time, ensuring that your data
                                        remains safe and secure with us.
                                    </JourneyText>
                                    <JourneyText $theme={theme}>
                                        With Custodian, your peace of mind is
                                        our priority.
                                    </JourneyText>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'share_your_car'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'share_your_car'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <FadedSlower>
                                <img
                                    // placeholder={grey_placeholder}
                                    src={image_desktop}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '45vw',
                                        maxHeight: '80vh',
                                    }}
                                />
                            </FadedSlower>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingBottom: 200,
                            paddingTop: 48,
                            overflow: 'hidden',
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <JourneyStepTitle $theme={theme}>
                                        Choose exactly what
                                        <JourneyWelcomeNewLineOnMobile />
                                        <span
                                            style={{
                                                color: journey_colours[theme]
                                                    .section.share_your_car
                                                    .primary_500,
                                                fontSize: '28px',
                                            }}
                                        >
                                            you share
                                        </span>
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        Car ownership is best enjoyed together
                                    </JourneyText>
                                </JourneyColumnContent>
                                <JourneyText $theme={theme}>
                                    When you choose to share your car or garage
                                    via Direct Share, you're in control every
                                    step of the way.
                                </JourneyText>
                                <FadedSlower>
                                    <img
                                        // placeholder={grey_placeholder}
                                        src={image_mobile}
                                        style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            height: 'auto',
                                        }}
                                    />
                                </FadedSlower>
                                <JourneyText $theme={theme}>
                                    Select exactly what information is shared
                                    and with whom. Plus, rest assured that you
                                    can modify or remove shares at any time,
                                    ensuring that your data remains safe and
                                    secure with us.
                                </JourneyText>
                                <JourneyText $theme={theme}>
                                    With Custodian, your peace of mind is our
                                    priority.
                                </JourneyText>
                            </JourneyColumnContent>
                            {/* add generated qr code with downloading btns */}
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="share_your_car"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
