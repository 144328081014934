import React, { useEffect } from 'react'
import Faded from '../../templates/animated/faded'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import HelpTutorialSectionDesktop from '../../molecules/helpTutorialsSection/helpTutorialSectionDesktop'
import HelpTutorialSectionMobile from '../../molecules/helpTutorialsSection/helpTutorialSectionMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'

const TutorialsHelp: React.FC = () => {
    const dispatch = useAppDispatch()

    const { userLoggedIn, garage_id, garage_data } = useAppSelector(
        (state) => ({
            userLoggedIn: state.user.userLoggedIn,
            garage_id: state.user.userLoggedIn?.owns_garage?.uid,
            garage_data: state.entities.garagesData.garages,
        })
    )

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl('/account/tutorials')
        )
    }, [])

    React.useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else if (userLoggedIn) {
            if (
                garage_id &&
                (!garage_data[garage_id] ||
                    !garage_data[garage_id].cars ||
                    garage_data[garage_id].cars.length === 0)
            ) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }
        }
    }, [userLoggedIn])

    let carId: string | null =
        garage_id && garage_data[garage_id] && garage_data[garage_id].cars
            ? garage_data[garage_id].cars[
                  garage_data[garage_id].cars.length - 1
              ]
            : null

    return (
        <CenteredPageWrapper hasMobileSubNav>
            {userLoggedIn && (
                <Faded>
                    <Loader />
                    <DesktopDisplayOnly>
                        <div
                            style={{
                                paddingLeft: '50px',
                                paddingRight: '50px',
                            }}
                        >
                            <SectionHeader
                                title={'Tutorials'}
                                removeEdit={true}
                            />

                            <div style={{ marginTop: '20px' }} />

                            <HelpTutorialSectionDesktop carId={carId} />
                        </div>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <div style={{ height: '40px' }} />
                        <HelpTutorialSectionMobile carId={carId} />
                        <div style={{ height: '100px' }} />
                    </IpadAndMobileDisplay>
                </Faded>
            )}
        </CenteredPageWrapper>
    )
}

export default TutorialsHelp
