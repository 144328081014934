import { PrismicLink } from 'apollo-link-prismic'
import { ApolloClient } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { apiEndpoint } from '../../prismic-configuration'

// Client method to query documents from the Prismic repo
export const client = new ApolloClient({
    link: PrismicLink({ uri: apiEndpoint }),
    cache: new InMemoryCache(),
    // cache: new InMemoryCache({ fragmentMatcher }),
})
