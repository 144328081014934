import {
    costNumShorterFormatter,
    numberWithCommas,
} from '../../../helpers/currencyConverter'
import { ITimelineItem, ICostItem } from '../../timeline/types'

const calculateTotalCostOnCreate = (
    entry: ITimelineItem,
    cost: ICostItem
): number => {
    let am = cost.user_amount ? cost.user_amount : cost.amount

    if (entry.total_cost_amount_raw && cost.amount && am) {
        return entry.total_cost_amount_raw + am
    } else if (!entry.total_cost_amount_raw && am) {
        return am
    } else if (entry.total_cost_amount_raw && !am) {
        return entry.total_cost_amount_raw
    } else return 0
}

const calculateTotalCostOnUpdate = (
    entry: ITimelineItem,
    cost: ICostItem,
    oldCostAmount: number | undefined
): number => {
    let am = cost.user_amount ? cost.user_amount : cost.amount

    if (
        entry.total_cost_amount_raw &&
        (cost.amount || cost.amount === 0) &&
        oldCostAmount &&
        am
    ) {
        return entry.total_cost_amount_raw + am - oldCostAmount
    } else if (entry.total_cost_amount_raw && am && !oldCostAmount) {
        return entry.total_cost_amount_raw + am
    } else if (entry.total_cost_amount_raw && !am) {
        return entry.total_cost_amount_raw
    } else return 0
}

const calculateTotalCostOnDelete = (
    entry: ITimelineItem,
    cost: ICostItem
): number => {
    let am = cost.user_amount ? cost.user_amount : cost.amount

    if (entry.total_cost_amount_raw && am) {
        return entry.total_cost_amount_raw - am
    } else if (entry.total_cost_amount_raw && !am) {
        return entry.total_cost_amount_raw
    } else return 0
}

export const UpdateActiveEntryWithCost = (
    action: 'update' | 'create' | 'delete',
    active_entry: ITimelineItem | undefined,
    cost_item: ICostItem,
    oldCostAmount?: number | undefined
): ITimelineItem | undefined => {
    switch (action) {
        case 'update': {
            if (
                active_entry &&
                active_entry.costItemsObj &&
                active_entry.costItemsObj[cost_item.id]
            ) {
                // Create a deep copy of the active entry
                const updatedEntry = {
                    ...active_entry,
                    costItemsObj: { ...active_entry.costItemsObj },
                }

                // Update the cost item immutably
                updatedEntry.costItemsObj[cost_item.id] = {
                    ...cost_item,
                }

                // Calculate new total cost
                const rawTotalCost = calculateTotalCostOnUpdate(
                    updatedEntry,
                    cost_item,
                    oldCostAmount
                )

                // Update cost-related fields
                updatedEntry.total_cost_amount_raw = rawTotalCost
                updatedEntry.total_cost_amount_display =
                    numberWithCommas(rawTotalCost)
                updatedEntry.total_cost_amount_display_short =
                    costNumShorterFormatter(rawTotalCost)

                return updatedEntry
            }
            return active_entry
        }

        case 'create': {
            if (active_entry) {
                // Create a deep copy of the active entry
                const updatedEntry = {
                    ...active_entry,
                    costItemsObj: active_entry.costItemsObj
                        ? { ...active_entry.costItemsObj }
                        : {},
                    costUIDS: active_entry.costUIDS
                        ? [...active_entry.costUIDS]
                        : [],
                }

                // Create the new cost object
                const new_cost_obj = {
                    [cost_item.id]: cost_item,
                }

                // Update the costItemsObj immutably
                updatedEntry.costItemsObj = {
                    ...updatedEntry.costItemsObj,
                    ...new_cost_obj,
                }

                // Calculate total cost
                const rawTotalCost: number = calculateTotalCostOnCreate(
                    updatedEntry,
                    cost_item
                )

                // Update cost-related fields
                updatedEntry.total_cost_amount_raw = rawTotalCost
                updatedEntry.total_cost_amount_display =
                    numberWithCommas(rawTotalCost)
                updatedEntry.total_cost_amount_display_short =
                    costNumShorterFormatter(rawTotalCost)

                // Update costUIDS array immutably
                if (!updatedEntry.costUIDS.includes(cost_item.id)) {
                    updatedEntry.costUIDS = [
                        ...updatedEntry.costUIDS,
                        cost_item.id,
                    ]
                }

                return updatedEntry
            }
            return active_entry
        }

        case 'delete': {
            if (active_entry) {
                // Create a deep copy of the active entry
                const updatedEntry = {
                    ...active_entry,
                    costItemsObj: active_entry.costItemsObj
                        ? { ...active_entry.costItemsObj }
                        : {},
                    costUIDS: active_entry.costUIDS
                        ? [...active_entry.costUIDS]
                        : [],
                }

                // Remove cost ID from costUIDS array immutably
                if (updatedEntry.costUIDS) {
                    updatedEntry.costUIDS = updatedEntry.costUIDS.filter(
                        (id) => id !== cost_item.id
                    )
                }

                // Calculate new total cost
                const rawTotalCost = calculateTotalCostOnDelete(
                    updatedEntry,
                    cost_item
                )

                // Update cost-related fields
                updatedEntry.total_cost_amount_raw = rawTotalCost
                updatedEntry.total_cost_amount_display =
                    numberWithCommas(rawTotalCost)
                updatedEntry.total_cost_amount_display_short =
                    costNumShorterFormatter(rawTotalCost)

                // Remove cost from costItemsObj immutably
                if (updatedEntry.costItemsObj) {
                    const { [cost_item.id]: removed, ...remainingCosts } =
                        updatedEntry.costItemsObj
                    updatedEntry.costItemsObj = remainingCosts
                }

                return updatedEntry
            }
            return active_entry
        }

        default:
            return active_entry
    }
}
