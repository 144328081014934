import { TimelineCreateFormStepTypesIDsEnum } from './timelineEnum'
import { ITimelineCreationFormFieldsData } from './types'

// for entry editing

export const timeline_general_fields_list: string[] = [
    'date',
    'title',
    'description',
]

export const timeline_cost_fields_list: string[] = [
    'name',
    'amount',
    'company',
    'attachment',
    // 'attachment_with_preview',
]

export const timeline_insurance_general_fields_list: string[] = [
    'insurance_entry_policy_start_date',
    'insurance_entry_policy_end_date',
    'insurance_entry_underwriter',
    'insurance_entry_broker',
    'title',
    'description',
]

export const timeline_insurance_fields_list: string[] = [
    'insurance_entry_underwriter',
    'insurance_entry_broker',
]

export const timeline_notes_fields_list: string[] = ['notes']

export const timeline_labels_fields_list: string[] = ['labels']

export const timeline_attachment_fields_list: string[] = ['attachment']

export const timeline_mileage_fields_list: string[] = ['mileage']

// for entry creation

export const create_timeline_entry_desktop_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.TITLE_AND_ENTRY_DATE_WITH_OPTIONS,
]

export const create_timeline_service_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_DATE_WITH_OPTIONS,
    TimelineCreateFormStepTypesIDsEnum.GENERAL_DETAIL_1,
    // TimelineCreateFormStepTypesIDsEnum.ATTACHMENT,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_LABELS,
]

export const create_timeline_maintenance_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_DATE_WITH_OPTIONS,
    TimelineCreateFormStepTypesIDsEnum.GENERAL_DETAIL_1,
    // TimelineCreateFormStepTypesIDsEnum.ATTACHMENT,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_LABELS,
]

export const create_timeline_restoration_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_DATE_WITH_OPTIONS,
    TimelineCreateFormStepTypesIDsEnum.GENERAL_DETAIL_1,
    // TimelineCreateFormStepTypesIDsEnum.ATTACHMENT,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_LABELS,
]

export const create_timeline_admin_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_DATE_WITH_OPTIONS,
    TimelineCreateFormStepTypesIDsEnum.GENERAL_DETAIL_1,
    // TimelineCreateFormStepTypesIDsEnum.ATTACHMENT,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_LABELS,
]

export const create_timeline_insurance_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.INSURANCE_FIELDS,
]

export const create_timeline_history_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_DATE_WITH_OPTIONS,
    TimelineCreateFormStepTypesIDsEnum.GENERAL_DETAIL_1,
    // TimelineCreateFormStepTypesIDsEnum.ATTACHMENT,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_LABELS,
]

export const create_timeline_events_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_DATE_WITH_OPTIONS,
    TimelineCreateFormStepTypesIDsEnum.GENERAL_DETAIL_1,
    // TimelineCreateFormStepTypesIDsEnum.ATTACHMENT,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_LABELS,
]

export const create_timeline_others_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_DATE_WITH_OPTIONS,
    TimelineCreateFormStepTypesIDsEnum.GENERAL_DETAIL_1,
    // TimelineCreateFormStepTypesIDsEnum.ATTACHMENT,
    TimelineCreateFormStepTypesIDsEnum.ENTRY_LABELS,
]

export const create_timeline_cost_item_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.COST_STEP_1,
    TimelineCreateFormStepTypesIDsEnum.COST_STEP_2,
    // TimelineCreateFormStepTypesIDsEnum.COST_SUMMARY,
]

export const create_timeline_cost_item_desktop_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.COST_DESKTOP,
]

export const create_timeline_add_new_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
]

export const create_timeline_add_new_desktop_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY,
    TimelineCreateFormStepTypesIDsEnum.TITLE_AND_ENTRY_DATE_WITH_OPTIONS,
]

export const create_timeline_add_new_steps_list_insurance: string[] = [
    TimelineCreateFormStepTypesIDsEnum.TITLE_AND_ENTRY_DATE_WITH_OPTIONS,
]

export const create_timeline_attachment_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.ATTACHMENT,
]

export const create_entry_label_steps_list: string[] = [
    TimelineCreateFormStepTypesIDsEnum.ENTRY_LABELS,
]

export const timeline_fields_data_per_step: ITimelineCreationFormFieldsData = {
    [TimelineCreateFormStepTypesIDsEnum.COST_STEP_1]: {
        step_field_ids: ['name', 'amount', 'company'],
        step_fields_data: {
            name: {
                id: 'name',
                type: 'basic',
                optional: false,
                value: null,
                text: 'Cost name',
            },
            amount: {
                id: 'amount',
                type: 'number',
                optional: false,
                value: null,
                text: 'price',
            },
            company: {
                id: 'company',
                type: 'basic',
                optional: true,
                value: null,
                text: 'company',
            },
        },
        step_title: 'How much did it cost?',
        step_subtitle:
            'If you add cost details you can look them up quickly and we can also give you estimated costs of ownership',
        submit: {
            text: 'Confirm',
            actionId: 'TBD',
            entity: 'cost',
            action: 'CREATE',
        },
    },

    [TimelineCreateFormStepTypesIDsEnum.COST_DESKTOP]: {
        // step_field_ids: ['name', 'amount', 'company', 'attachment'],
        step_field_ids: ['name', 'amount', 'company', 'attachment'],
        step_fields_data: {
            name: {
                id: 'name',
                type: 'basic',
                optional: false,
                value: null,
                text: 'Cost name',
            },
            amount: {
                id: 'amount',
                type: 'number',
                optional: false,
                value: null,
                text: 'price',
            },
            company: {
                id: 'company',
                type: 'basic',
                optional: true,
                value: null,
                text: 'company',
            },
            attachment: {
                id: 'attachment',
                type: 'attachment',
                optional: true,
                value: null,
                text: 'attachment',
            },
        },
        step_title: 'How much did it cost?',
        step_subtitle:
            'If you add cost details you can look them up quickly and we can also give you estimated costs of ownership',
        submit: {
            text: 'add cost',
            actionId: 'TBD',
            entity: 'cost',
            action: 'CREATE',
        },
    },

    [TimelineCreateFormStepTypesIDsEnum.COST_STEP_2]: {
        step_field_ids: ['attachment'],
        step_fields_data: {
            attachment: {
                id: 'attachment',
                type: 'attachment',
                optional: true,
                value: null,
                text: 'attachment',
            },
        },
        step_title: 'Anything physical',
        step_subtitle:
            'You can take a photo of your invoice or upload a copy for safe keeping and easy access',
        submit: {
            text: 'Confirm',
            actionId: 'add_attachment_to_cost',
            entity: 'cost',
            action: 'UPDATE',
        },
    },

    [TimelineCreateFormStepTypesIDsEnum.ATTACHMENT]: {
        step_field_ids: ['attachment'],
        step_fields_data: {
            attachment: {
                id: 'attachment',
                type: 'attachment',
                optional: true,
                value: null,
                text: 'attachment',
            },
        },
        step_title: 'Attach an image or document.',
        step_subtitle: 'You can upload an existing file or take a photo.',
        step_subtitle_detail:
            'Hang on to your invoices—you can add them in the costs section later.',
        submit: {
            text: 'Confirm',
            actionId: 'add_attachment_to_entry',
            entity: 'entry',
            action: 'UPDATE',
        },
    },

    [TimelineCreateFormStepTypesIDsEnum.ENTRY_DATE_WITH_OPTIONS]: {
        step_field_ids: ['date_with_options'],
        step_fields_data: {
            date_with_options: {
                id: 'date_with_options',
                type: 'date',
                optional: false,
                value: null,
                text: 'Date',
            },
        },
        step_title: 'What’s the date for this entry?',
        step_subtitle:
            ' Tip: Don’t worry about exact dates or if the entry took place over a period of time — an approximate date still helps build the timeline.',
        submit: {
            text: 'Confirm',
            actionId: 'add_date_to_entry',
            entity: 'entry',
            action: 'UPDATE',
        },
    },

    [TimelineCreateFormStepTypesIDsEnum.GENERAL_DETAIL_1]: {
        step_field_ids: ['title', 'short_description'],
        step_fields_data: {
            title: {
                id: 'title',
                type: 'basic',
                optional: false,
                value: null,
                text: 'title',
            },
            short_description: {
                id: 'short_description',
                type: 'text_area',
                optional: true,
                value: null,
                text: 'Short Description (optional)',
            },
        },
        step_title: 'Let’s add some details',
        step_subtitle:
            'Please add a title and brief description to make your car’s history file easily searchable.',
        submit: {
            text: 'Confirm',
            actionId: 'add_basic_info_to_entry',
            entity: 'entry',
            action: 'UPDATE',
        },
    },

    [TimelineCreateFormStepTypesIDsEnum.SELECT_CATEGORY]: {
        step_field_ids: ['categoryID'],
        step_fields_data: {
            categoryID: {
                id: 'categoryID',
                type: 'options',
                optional: false,
                value: null,
                text: 'Entry type',
            },
        },
        step_title: 'Add to your car’s digital history file',
        step_subtitle:
            'Please select an entry type to log to your car’s history file.',
        submit: {
            text: 'Confirm',
            actionId: 'create_entry_w_category',
            entity: 'entry',
            action: 'CREATE',
        },
    },

    [TimelineCreateFormStepTypesIDsEnum.TITLE_AND_ENTRY_DATE_WITH_OPTIONS]: {
        step_field_ids: ['title', 'date_with_options'],
        step_fields_data: {
            title: {
                id: 'title',
                type: 'basic',
                optional: false,
                value: null,
                text: 'title',
            },

            date_with_options: {
                id: 'date_with_options',
                type: 'date_with_options',
                optional: false,
                value: null,
                text: 'Date',
            },
        },
        step_title: 'Please add just a couple of details',
        step_subtitle:
            'Please add a title and the date when this happened. If you cant remember the exact date, please pick something approximate.',
        submit: {
            text: 'create entry',
            actionId: 'add_basic_info_to_entry',
            entity: 'entry',
            action: 'UPDATE',
        },
    },
    [TimelineCreateFormStepTypesIDsEnum.ENTRY_LABELS]: {
        step_field_ids: ['labels'],
        step_fields_data: {
            labels: {
                id: 'labels',
                type: 'labels',
                optional: true,
                value: null,
                text: 'Labels',
            },
        },
        step_title: 'Add labels',
        step_subtitle:
            'Select an existing label or create a custom one to make searching your history file easier.',
        submit: {
            text: 'confirm',
            actionId: 'confirm_data_to_be_submitted',
            entity: 'entry',
            action: 'CREATE',
        },
    },
    [TimelineCreateFormStepTypesIDsEnum.INSURANCE_FIELDS]: {
        step_field_ids: [
            'title',
            'insurance_entry_policy_start_date',
            'insurance_entry_policy_end_date',
            'insurance_entry_underwriter',
            'insurance_entry_broker',
        ],
        step_fields_data: {
            title: {
                id: 'title',
                type: 'basic',
                optional: false,
                value: null,
                text: 'title',
            },
            insurance_entry_policy_start_date: {
                id: 'insurance_entry_policy_start_date',
                type: 'date',
                optional: false,
                value: null,
                text: 'Policy Start Date',
            },
            insurance_entry_policy_end_date: {
                id: 'insurance_entry_policy_end_date',
                type: 'date',
                optional: false,
                value: null,
                text: 'Policy End Date',
            },
            insurance_entry_broker: {
                id: 'insurance_entry_broker',
                type: 'basic',
                optional: true,
                value: null,
                text: 'Broker (if applicable)',
            },
            insurance_entry_underwriter: {
                id: 'insurance_entry_underwriter',
                type: 'basic',
                optional: false,
                value: null,
                text: 'Insurer',
            },
        },
        step_title: 'New Insurance Entry',
        step_subtitle: '',
        // submit: {
        //     text: 'create entry',
        //     actionId: 'add_basic_info_to_entry',
        //     entity: 'entry',
        //     action: 'UPDATE',
        // },
        // will be:
        submit: {
            text: 'Create Entry',
            actionId: 'confirm_data_to_be_submitted',
            entity: 'entry',
            action: 'CREATE',
        },
    },
    [TimelineCreateFormStepTypesIDsEnum.ENTRY_SUMMARY]: {
        step_field_ids: [],
        step_fields_data: {},
        step_title: '',
        step_subtitle: '',
        step_subtitle_detail: undefined,
        submit: {
            text: '',
            actionId: undefined,
            action: 'CREATE',
            entity: 'cost',
        },
    },
    [TimelineCreateFormStepTypesIDsEnum.COST_SUMMARY]: {
        step_field_ids: [],
        step_fields_data: {},
        step_title: '',
        step_subtitle: '',
        step_subtitle_detail: undefined,
        submit: {
            text: '',
            actionId: undefined,
            action: 'CREATE',
            entity: 'cost',
        },
    },
}

// [TimelineCreateFormStepTypesIDsEnum.ENTRY_SUMMARY]: {
//     step_field_ids: ['summary'],
//     step_fields_data: {
//         summary: {
//             id: 'summary',
//             type: 'read-only',
//             optional: true,
//             value: null,
//             text: '',
//         },
//     },

//     step_title: 'Please check your entry',
//     step_subtitle:
//         'If something is not correct, please press ‘back’ to make changes.',
//     submit: {
//         text: 'create entry',
//         actionId: 'confirm_data_to_be_submitted',
//         entity: 'entry',
//         action: 'CONFIRM_TO_API',
//     },
// },

// [TimelineCreateFormStepTypesIDsEnum.COST_SUMMARY]: {
//     step_field_ids: ['summary'],
//     step_fields_data: {
//         summary: {
//             id: 'summary',
//             type: 'read-only',
//             optional: true,
//             value: null,
//             text: '',
//         },
//     },

//     step_title: 'Please check your cost',
//     step_subtitle:
//         'If something is not correct, please press ‘back’ to make changes.',
//     submit: {
//         text: 'create cost',
//         actionId: 'confirm_data_to_be_submitted',
//         entity: 'cost',
//         action: 'CONFIRM_TO_API',
//     },
// },
