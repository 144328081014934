import { call, fork, put, take, select } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertAPIEntryCostToEntryCostSingle } from '../../conversions/timeline/entryCostToCostItem'
import { UpdateActiveEntryWithCost } from '../../conversions/timeline/updateActiveEntryWithCost'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { IAddAttachmentToCostSaga } from './addAttachmentToCost'
import { ConvertAttachmentAPIToAttachmentState } from '../../conversions/attachments'
import { DeleteCostAttachmentSaga } from './deleteCostAttachment'
import Decimal from 'decimal.js'
import { timelineActions } from '../reducer'
import { attachmentActions } from '../../attachments/reducer'
import { carActions } from '../../entities/cars/reducer'
import { IRootState } from '../../store'
import {
    ICarsObject,
    ICustomErrorData,
    IReduxError,
} from '../../entities/cars/types'
import {
    IUpdateEntryCostPayloadAPI,
    IEntryCostPayloadAPI,
} from '../../services/typedefinitions/apiPayloads'
import {
    IDeleteCostAttachmentPayloadReq,
    IApiEntryCostUPDATE_args,
} from '../../services/types'
import {
    IUpdateCostItemPayloadRequest,
    ITimelineItem,
    ICostItem,
    IUpdateCostItemPayloadSuccess,
} from '../types'

const get_active_entry_in_state = (state: IRootState) =>
    state.timeline.activeEntry

const get_active_cost_in_state = (state: IRootState) =>
    state.timeline.activeCost

const get_cars_in_state = (state: IRootState) => state.entities.carsData.cars

export function* UpdateCarTimelineEntryCostSaga(
    payload: IUpdateCostItemPayloadRequest
): any {
    try {
        let active_entry_in_state: ITimelineItem = yield select(
            get_active_entry_in_state
        )

        let active_cost_in_state: ICostItem = yield select(
            get_active_cost_in_state
        )

        let keys: string[] = ['name', 'amount', 'company']

        if (
            active_cost_in_state.attachmentID &&
            !payload.cost.attachmentID &&
            active_entry_in_state &&
            active_entry_in_state.id
        ) {
            let fp: IDeleteCostAttachmentPayloadReq = {
                costID: payload.costid,
                entryID: payload.itemid,
                attachmentID: active_cost_in_state.attachmentID,
                carID: payload.carid,
            }

            yield call(DeleteCostAttachmentSaga, fp)
            // here  adjust cost w/ ats ID
        }

        if (
            payload.cost.attachment &&
            active_entry_in_state &&
            active_entry_in_state.id
        ) {
            let fp: IAddAttachmentToCostSaga = {
                fileData: payload.cost.attachment,
                carID: payload.carid,
                entryID: active_entry_in_state.id,
                costID: payload.costid,
            }
            // yield call(AddAttachmentToCostSaga, fp)
            yield put(timelineActions.addAttachmentToCostRequest(fp))
            // here  adjust cost w/ ats ID
        }

        if (
            active_entry_in_state &&
            active_entry_in_state.costItemsObj &&
            active_entry_in_state.costUIDS &&
            payload.cost &&
            active_entry_in_state.costItemsObj[payload.costid]
        ) {
            for (let i = 0; i < keys.length; i++) {
                if (
                    payload.cost[keys[i]] ===
                    active_entry_in_state.costItemsObj[payload.costid][keys[i]]
                ) {
                    payload.cost[keys[i]] = undefined
                }
            }
        }

        if (payload.cost.amount && isNaN(payload.cost.amount) !== true) {
            payload.cost.amount = Number(
                new Decimal(payload.cost.amount).times(100)
            )
        }

        // STEP 1 . build up correct payload to send to api => this is the JSON body
        let api_payload_req_entry_cost: IUpdateEntryCostPayloadAPI = {
            name: payload.cost.name ? payload.cost.name : undefined,
            amount: payload.cost.amount
                ? payload.cost.amount.toString()
                : undefined,
            currency: payload.cost.currency ? payload.cost.currency : 'GBP',
            company_name: payload.cost.company
                ? payload.cost.company
                : undefined,
            occurred_at: payload.cost.date ? payload.cost.date : undefined,
        }

        // STEP 2 . call the api func and keep data returned in a const
        let api_payload: IApiEntryCostUPDATE_args = {
            entry_id: payload.itemid,
            car_id: payload.carid,
            cost_id: payload.costid,
            dataToUpdate: api_payload_req_entry_cost,
        }

        const api_res = yield call(
            api.timeline.costs.updateEntryCost.updateEntryCost,
            api_payload
        )

        const updatedCostfromAPI: IEntryCostPayloadAPI = api_res

        // STEP 3 . convert API data to reducer data type
        let updated_cost: ICostItem =
            ConvertAPIEntryCostToEntryCostSingle(updatedCostfromAPI)

        let oldCostAmount: number | undefined =
            active_entry_in_state &&
            active_entry_in_state.costItemsObj &&
            active_entry_in_state.costItemsObj[payload.costid].amount &&
            active_entry_in_state.costItemsObj[payload.costid].amount

        let active_entry_updated_with_updated_cost: ITimelineItem | undefined =
            UpdateActiveEntryWithCost(
                'update',
                active_entry_in_state,
                updated_cost,
                oldCostAmount
            )

        // STEP 4 . build up payload to return to reducer
        let res: IUpdateCostItemPayloadSuccess = {
            // carid: payload.carid,
            entry: active_entry_updated_with_updated_cost,
            cost: updated_cost,
        }

        if (
            updatedCostfromAPI.attachments &&
            updatedCostfromAPI.attachments.length > 0
        ) {
            let length: number = updatedCostfromAPI.attachments.length - 1

            if (updatedCostfromAPI.attachments[length].uid) {
                yield put(
                    attachmentActions.addAttachmentsSuccess({
                        [updatedCostfromAPI.attachments[length].uid]:
                            ConvertAttachmentAPIToAttachmentState(
                                updatedCostfromAPI.attachments[length]
                            ),
                    })
                )
            }
        }

        // STEP 5 . send success action with payload above to redux
        yield put(timelineActions.updateTimelineItemCostSuccess(res))
        yield put(carActions.resetCarInsights(payload.carid))
        // yield put(push(`/car/${payload.carid}/history-file/entry?entryid=${payload.itemid}`))

        let cars_in_state: ICarsObject = yield select(get_cars_in_state)

        let carAffected = cars_in_state[payload.carid]

        if (carAffected?.last_insurance_entry?.id === payload.itemid) {
            yield put(
                carActions.getLastCarInsuranceEntrySuccess({
                    carid: payload.carid,
                    timelineItem: undefined,
                })
            )
        }

        // Capture event
        posthog.capture('UPDATE CAR ENTRY COST')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: UpdateCarTimelineEntryCostSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/car/${payload.carid}/history-file/entry?entryid=${payload.itemid}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(timelineActions.updateTimelineItemCostError(customError))
        }
    }
}

function* watcherUpdateCarTimelineCost() {
    while (true) {
        const { payload } = yield take(
            timelineActions.updateTimelineItemCostRequest
        )

        yield call(UpdateCarTimelineEntryCostSaga, payload)
    }
}

const update_car_timeline_cost: any[] = [fork(watcherUpdateCarTimelineCost)]

export default update_car_timeline_cost
