import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { menuActions } from '../../../redux/menus/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { insuranceActions } from '../../../redux/insuranceQuoteApplication/reducer'
import { IGetAllUserInsuranceApplicationsProps } from '../../../redux/insuranceQuoteApplication/sagas/application/getAll'
// import InsuranceOnboardingGetStarted from '../../organisms/insuranceQuote/onboarding/steps/getStarted'
import { InsuranceOnboardingAnimatedWrapper } from '../../organisms/insuranceQuote/onboarding/animatedWraper'
import { InsuranceOnboardingWindowTemplate } from '../../organisms/insuranceQuote/onboarding/windowTemplate'
import BottomBarBtnsStep from '../../atoms/Button/bottomBarBtnsStep'
import { InsuranceOnboardingMenu } from '../../organisms/insuranceQuote/onboarding/menu'
import { InsuranceOnboardingEligibility } from '../../organisms/insuranceQuote/onboarding/steps/eligibility'
import FadedSlower from '../../templates/animated/FadedSlower'
import { InsuranceOnboardingEExplanations } from '../../organisms/insuranceQuote/onboarding/steps/explanations'
import posthog from 'posthog-js'
import { useSetInitialInsurance } from '../../../providers/insurance/insuranceInitialProvider'
import { usersActions } from '../../../redux/user/reducer'
import InsuranceAppliCreationOnHold from '../../organisms/insuranceQuote/onboarding/steps/creationOnHold'

// needed in other files
export const AdjustedPadding = styled.div`
    padding-top: 24px;
    @media ${device.smallest_laptops} {
        padding-top: 0px;
    }
    @media ${device.mobile} {
        padding-top: 0px;
    }
`
// needed in other files
export const Title = styled.h1`
    margin: 0;
    padding: 0;
    color: var(--text-strong, #1a1a1a);
    text-align: center;
    font-family: Lato-bold;
    font-size: 42px;
    @media ${device.smallest_laptops} {
        font-size: 32px;
    }
    @media ${device.mobile_and_ipad} {
        font-size: 22px;
        line-height: 32px;
    }
    @media (max-width: 320px) {
        font-size: 18px;
        line-height: 26px;
    }
`

// needed in other files
export const Text = styled.p`
    margin: 0;
    padding: 0;
    color: var(--text-default, #666);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media ${device.mobile} {
        font-size: 14px;
        line-height: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const Title1 = styled.h1`
    color: var(--text-strong, #1a1a1a);
    text-align: flex-start;
    font-family: Lato-semibold;
    font-size: 32px;
    font-style: normal;
    line-height: 48px; /* 150% */
    width: 100%;
    box-sizing: border-box;

    margin: 0;

    @media (max-width: 1200px) {
        font-size: 27px;
    }

    @media (max-width: 744px) {
        all: unset;
        color: var(--text-strong, #1a1a1a);
        width: 58vw;
        max-width: 300px;

        font-family: Lato-semibold;
        font-size: 22px;
    }
`

type IStyled = {
    onboarding_id?: string
}

const WrapperOverflow = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    @media (min-width: 744px) {
        overflow-y: scroll;
        max-height: 376px;
        padding-bottom: 50px;

        /* width */
        ::-webkit-scrollbar {
            width: ${(props) =>
                `${props.onboarding_id}` === '2' ? '2px' : '0px'};
            border-radius: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: var(--scroll-bar-track-color);
            border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--scroll-bar-handle-color);
            border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: var(--scroll-bar-handle-color);
        }
    }

    @media (min-width: 1200px) {
        max-height: 413px;
    }
`

const Row = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 744px) {
        align-items: flex-start;
        padding-right: 24px;
        padding-left: 24px;
        padding-top: 24px;
    }
`

const Col = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`

const MobileCol = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    min-height: 100lvh;
    position: relative;
`

const Text2 = styled.p`
    color: var(--text-default, #616161);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Mobile/Text/T300 Regular */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    /* width: 590px; */
    width: 100%;
    @media (max-width: 744px) {
        width: 100%;
        box-sizing: border-box;
        max-width: 560px;
        padding-right: 24px;
        padding-left: 24px;
    }
`

type IOnboardingDataItem = {
    id: string
    onNext: any
    onPrevious: any
    title: string
    firstText: string
    goNextTxt: string
    learnMoreLink?: string
    secondCTAText?: string
    secondCTAIcon?: string
    onSecondCTAClick?: any
    submitIcon?: string
    hrefText?: string
    hasHrefLink?: string
    hrefPosthogMessage?: string
    description: string
    description_2?: string
    removeCancel?: boolean
    goBackIcon?: string
    cancel?: any
}

type IOnboardingData = {
    [key: string]: IOnboardingDataItem
}

export type IInsuranceOnboardingProps = {
    data: IOnboardingDataItem
    is_mobile?: boolean
    removeInsuranceOnboardingMenu?: boolean
}

let description_3 =
    'The more information we know about you and your car ownership, the better chance we have of finding you the perfect cover. Please read the information below to learn more about the process and what these icons mean.'
let description_2_1 =
    'Please check the following requirements for getting insurance with Custodian.'
let description_2_2 =
    'We can only currently arrange insurance for people who meet the following criteria:'
// let description_1 = `We're here to simplify insurance and help find you the best cover and terms. Your details are securely saved for future quotes. Pause and resume at your convenience. Stress-free and flexible.`
let description_1 = `Due to extremely high volumes and our ongoing platform migration, we have temporarily paused the creation of new insurance applications to maintain our service quality.`

const CommonBody = (props: IInsuranceOnboardingProps) => {
    return (
        <Col>
            <Row>
                <Title1>{props.data?.title}</Title1>
                {props.removeInsuranceOnboardingMenu ? null : (
                    <InsuranceOnboardingMenu data={props.data} />
                )}
            </Row>
            <div style={{ paddingTop: '24px' }} />
            <Text2>
                {props.data?.description}
                {props.data.description_2 ? (
                    <>
                        {props.is_mobile ? ' ' : <br />}
                        {props.data.description_2}
                    </>
                ) : null}
            </Text2>
            <div style={{ paddingTop: '24px' }} />
            <WrapperOverflow onboarding_id={props.data.id}>
                {props.data.id === '1' && (
                    <FadedSlower>
                        {/* <InsuranceOnboardingGetStarted /> */}
                        <InsuranceAppliCreationOnHold />
                    </FadedSlower>
                )}

                {props.data.id === '2' && (
                    <FadedSlower>
                        <InsuranceOnboardingEligibility
                            is_mobile={props.is_mobile}
                        />
                    </FadedSlower>
                )}

                {props.data.id === '3' && (
                    <FadedSlower>
                        <InsuranceOnboardingEExplanations />
                    </FadedSlower>
                )}
            </WrapperOverflow>
        </Col>
    )
}

const DesktopIpad = styled.div`
    @media (max-width: 743px) {
        display: none;
    }
`

const OnlyMobile = styled.div`
    @media (min-width: 744px) {
        display: none;
    }
`

type IpropsBar = {
    removeFixed: boolean
    is_mobile: boolean
}

const InsuranceWelcome = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()

    const { search } = useLocation()

    let queryParams = new URLSearchParams(search)
    let onboarding_id_params = queryParams.get('id')

    let onboarding_id = onboarding_id_params ? `${onboarding_id_params}` : '1'

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else if (userLoggedIn) {
            if (location.pathname !== '/insurance/new') {
                let payload: IGetAllUserInsuranceApplicationsProps = {
                    withAllInfo: true,
                    withNavigation: true,
                }
                dispatch(
                    insuranceActions.get_user_insurance_applications_request(
                        payload
                    )
                )
            }
        }

        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [userLoggedIn])

    const goToStep = (st: string) => {
        queryParams.delete('id')
        queryParams.append('id', st)
        navigate(
            {
                search: queryParams.toString(),
            },
            { replace: true }
        )
    }

    let { reset_set_wizard_entity_id_to_skip } = useSetInitialInsurance()

    const onboarding_obj: IOnboardingData = {
        // to comment out
        '1': {
            id: '1',
            onNext: () => {
                window.location.href =
                    'https://www.custodian.club/features/insurance'
            },
            onPrevious: () => {
                posthog.capture('Exit insurance creation on hold')
                navigate('/garage')
            },
            title: 'Driven by Enthusiasm, Powered by Trust.',
            firstText: '',
            goNextTxt: 'Learn More',
            removeCancel: true,
            description: description_1,
            goBackIcon: 'grey_cross_v2',
        },
        // '1': {
        //     id: '1',
        //     onNext: () => {
        //         goToStep('2')
        //         posthog.capture('Click on insurance onboarding step 2')
        //     },
        //     onPrevious: () => {
        //         posthog.capture('Exit insurance onboarding from step 1')
        //         navigate('/garage')
        //     },
        //     title: 'Driven by Enthusiasm, Powered by Trust.',
        //     firstText: '',
        //     goNextTxt: 'Get Started',
        //     learnMoreLink: '',
        //     hrefText: 'Learn More',
        //     hasHrefLink: 'https://www.custodian.club/features/insurance',
        //     hrefPosthogMessage: 'learn_more insurance clicked',
        //     description: description_1,
        //     goBackIcon: 'grey_cross_v2',
        // },
        '2': {
            id: '2',
            onNext: () => {
                goToStep('3')
                posthog.capture('Click on insurance onboarding step 3')
            },
            onPrevious: () => {
                goToStep('1')
                posthog.capture(
                    'Click on insurance onboarding previous step #1 which contains welcome'
                )
            },
            title: `We don't want to waste your time!`,
            firstText: `I'm not eligible`,
            // mobile = cancel
            goNextTxt: 'Good to go!',
            description: description_2_1,
            description_2: description_2_2,
            goBackIcon: 'chevron_left',
            removeCancel: false,
            cancel: () => {
                posthog.capture('Click on insurance non-eligibility')
                navigate('/garage')
            },
        },
        '3': {
            id: '3',
            onNext: () => {
                posthog.capture(
                    'Click on insurance application creation with guided tour'
                )
                dispatch(
                    insuranceActions.reset_insurance_application_to_none_active()
                )
                reset_set_wizard_entity_id_to_skip()
                dispatch(
                    insuranceActions.create_insurance_application_request({
                        mode: 'wizard',
                    })
                )
            },
            onPrevious: () => {
                goToStep('2')
                posthog.capture(
                    'Click on insurance onboarding previous step #2 which contains pre eligibility'
                )
            },
            title: 'Getting you the best insurance',
            firstText: '',
            secondCTAText: 'Start Manual',
            // secondCTAIcon: 'play_white',
            // submitIcon: 'free_flow_white',
            onSecondCTAClick: () => {
                posthog.capture(
                    'Click on insurance application creation with free flow'
                )
                dispatch(
                    insuranceActions.create_insurance_application_request({
                        mode: 'free_flow',
                    })
                )
            },
            description: description_3,
            goNextTxt: 'Start Guided',
            submitIcon: 'play_white',
            removeCancel: true,
            goBackIcon: 'chevron_left',
        },
    }

    let BottomBar = (props2: IpropsBar) => {
        return (
            <BottomBarBtnsStep
                cancel={() => {
                    onboarding_obj[`${onboarding_id}`].cancel()
                }}
                submit={() => {
                    onboarding_obj[`${onboarding_id}`].onNext()
                }}
                removeFixed={props2.removeFixed}
                height={'48px'}
                back_btn_width="48px"
                goBackIcon={onboarding_obj[`${onboarding_id}`].goBackIcon}
                onGoback={() => onboarding_obj[`${onboarding_id}`].onPrevious()}
                removeCancel={
                    onboarding_obj[`${onboarding_id}`].removeCancel ?? false
                }
                confirmationtext={onboarding_obj[`${onboarding_id}`].goNextTxt}
                disabled={false}
                hasGoBack={true}
                firstText={onboarding_obj[`${onboarding_id}`].firstText}
                hrefPosthogMessage={
                    onboarding_obj[`${onboarding_id}`].hrefPosthogMessage
                }
                hasHrefLink={onboarding_obj[`${onboarding_id}`].hasHrefLink}
                hrefText={onboarding_obj[`${onboarding_id}`].hrefText}
                submitIcon={onboarding_obj[`${onboarding_id}`].submitIcon}
                secondCTAIcon={onboarding_obj[`${onboarding_id}`].secondCTAIcon}
                secondCTAText={onboarding_obj[`${onboarding_id}`].secondCTAText}
                onSecondCTAClick={
                    onboarding_obj[`${onboarding_id}`].onSecondCTAClick
                }
                customBg={
                    props2.is_mobile ? undefined : 'var(--bg-color, #fff)'
                }
            />
        )
    }

    return (
        <>
            <DesktopIpad>
                <InsuranceOnboardingAnimatedWrapper>
                    <InsuranceOnboardingWindowTemplate
                        id={`${onboarding_id}`}
                        is_creation_on_hold={true}
                    >
                        <CommonBody
                            is_mobile={false}
                            data={onboarding_obj[`${onboarding_id}`]}
                            removeInsuranceOnboardingMenu={true}
                        />
                        <BottomBar is_mobile={false} removeFixed={true} />
                    </InsuranceOnboardingWindowTemplate>
                </InsuranceOnboardingAnimatedWrapper>
            </DesktopIpad>

            <OnlyMobile>
                <MobileCol>
                    <CommonBody
                        is_mobile={true}
                        data={onboarding_obj[`${onboarding_id}`]}
                        removeInsuranceOnboardingMenu={true}
                    />
                    <BottomBar is_mobile={true} removeFixed={false} />
                    <div style={{ paddingTop: '200px' }} />
                </MobileCol>
            </OnlyMobile>
        </>
    )
}

export default InsuranceWelcome
