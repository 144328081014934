import styled from 'styled-components'
import close_cross_grey from '../../../../../public/assets/icons/close_cross_grey.svg'
import { motion } from 'framer-motion'
import {
    IPublishValidationObject,
    IValidation_subObject,
} from '../../showroomBars/bottomBars/carShowroomEditBottomBar'
import Icon from '../../../../atoms/icons'
import { Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import Expander from '../../../../atoms/expander/expander'
import { device } from '../../../displays/devices'
import ChevronDown from '../../../../atoms/icons/components/chevronDown'
import React from 'react'

const Container = styled.div`
    @media ${device.beyond_ipad_mobile} {
        background-color: var(--shadow-cards-bg);
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        padding: 40px;
        width: 442px;
        transition: all 100ms ease-in-out;
        box-shadow: 0px 4px 24px var(--box-shadow, rgba(0, 0, 0, 0.08));
        @media (max-height: 950px) {
            padding-top: 28px;
            padding-bottom: 16px;
        }

        height: 100%;

        overflow-y: scroll !important;

        max-height: 80vh;
    }

    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: column;
        padding: 24px;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--scroll-bar-track-color);
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--scroll-bar-handle-color);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--scroll-bar-handle-color);
    }
`

const Title = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    font-size: 24px;
    padding-bottom: 8px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const SubText = styled.div`
    font-family: Lato;
    color: var(--text-default, #666666);
    font-size: 16px;
`

const CheckContainer = styled(motion.div)<{ $isSubItem?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${(props) => (props.$isSubItem ? '52px' : '72px')} !important;
    min-height: ${(props) => (props.$isSubItem ? '52px' : '72px')} !important;

    cursor: pointer;

    @media (max-height: 950px) {
        height: ${(props) => (props.$isSubItem ? '52px' : '64px')} !important;
        min-height: ${(props) =>
            props.$isSubItem ? '52px' : '64px'} !important;
    }
`

const CheckText = styled.div<{ $isDisabled?: boolean }>`
    font-family: Lato;
    color: ${(props) =>
        props.$isDisabled === true
            ? 'var(--text-muted)'
            : 'var(--text-default, #666666)'};
    font-size: 16px;
    padding-left: 16px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const CheckSquare = styled.div<{ isIncomplete?: boolean }>`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${(props) =>
        props.isIncomplete
            ? 'var(--error, #df6f6f)'
            : 'var(--primary, #5ec3ca)'};
    @media (max-height: 950px) {
        width: 24px;
        height: 24px;
    }
`

const NavSquare = styled.div<{ isIncomplete?: boolean }>`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${(props) =>
        props.isIncomplete ? 'var(--error_08)' : 'var(--primary_08)'};
    border-radius: 4px;
    transform: rotate(270deg);
    @media (max-height: 950px) {
        width: 24px;
        height: 24px;
    }
`

const Chevron = styled.img`
    cursor: pointer;
    height: 14px;
    justify-self: end;
    transition: all 200ms;
    transform: rotate(180deg);
`

const MinSquare = styled.div`
    width: 42px;
    height: 32px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    color: #b3b3b3;
    border-radius: 4px;
    :hover {
        background-color: #e5e5e5;
    }
`

interface Props {
    publishChecksObject: IPublishValidationObject
    onClose: any
    isMobile?: boolean
}

type PropsValidationCheck = {
    isIncomplete?: boolean
    text: string
    isLast?: boolean
    onClick: any
    isSubItem?: boolean
    hasSubItems?: boolean
    isOpen?: boolean
    hasNitems?: number
    minN?: number
    tooltipText?: string
    isTooltipRemoved?: boolean
    isDisabled?: boolean
    isCaps?: boolean
}

const PublishCheckItem = (props: PropsValidationCheck) => {
    return (
        <CheckContainer
            style={{
                borderBottom:
                    !props.isLast && !props.isSubItem
                        ? '1px solid var(--border-muted, #e5e5e5)'
                        : 'none',
                cursor: props.isDisabled ? 'not-allowed' : 'pointer',
            }}
            $isSubItem={props.isSubItem}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 1.02 }}
            onClick={() => {
                if (!props.isDisabled) {
                    props.onClick()
                }
            }}
            role="button"
            aria-disabled={props.isDisabled}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '80%',
                }}
            >
                {props.isSubItem ? (
                    <NavSquare isIncomplete={props.isIncomplete}>
                        <ChevronDown
                            color={props.isIncomplete ? '#DF6F6F' : '#5ec3ca'}
                            size="11"
                        />
                    </NavSquare>
                ) : (
                    <CheckSquare isIncomplete={props.isIncomplete}>
                        <Icon
                            icon={
                                props.isIncomplete
                                    ? 'add_plus_white'
                                    : 'white_check'
                            }
                            width={props.isIncomplete ? 'auto' : '15px'}
                        />
                    </CheckSquare>
                )}
                <CheckText
                    $isDisabled={props.isDisabled}
                    style={
                        props.isCaps
                            ? { textTransform: 'uppercase', fontSize: 14 }
                            : {}
                    }
                >
                    {props.text}
                </CheckText>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.minN && props.isTooltipRemoved !== true && (
                    <Tooltip
                        title={props.tooltipText}
                        placement="bottom"
                        color={'#fafafa'}
                        overlayInnerStyle={{
                            fontSize: 12,
                            color: '#1a1a1a',
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 14,
                        }}
                        overlayStyle={{
                            boxShadow: '2px 4px 7px rgba(0, 0, 0, 0.02)',
                            maxWidth: '200px',
                            borderRadius: 4,
                        }}
                    >
                        <MinSquare>
                            {`${props.hasNitems}/${props.minN}`}
                        </MinSquare>
                    </Tooltip>
                )}
                <div
                    style={{
                        transform: props.hasSubItems
                            ? props.isOpen
                                ? 'rotate(180deg)'
                                : 'none'
                            : 'rotate(-90deg)',
                    }}
                >
                    <ChevronDown
                        color={props.hasSubItems ? '#5ec3ca' : '#b3b3b3'}
                    />
                </div>
            </div>
        </CheckContainer>
    )
}

type InsuranceValidationEntries<IPublishValidationObject> = {
    [K in keyof IPublishValidationObject]: [K, IPublishValidationObject[K]]
}[keyof IPublishValidationObject][]

const InsuranceValidationSheet = (props: Props) => {
    const { publishChecksObject, onClose } = props

    const [subItemsOpenState, setSubItemsOpenState] = useState<{
        [key: string]: boolean
    }>({})

    useEffect(() => {
        Object.entries(publishChecksObject).forEach((check) => {
            let checkName = check[0]
            let checkValues = check[1]
            if (checkValues.subItems) {
                setSubItemsOpenState({
                    ...subItemsOpenState,
                    [checkName]: false,
                })
            }
        })
    }, [publishChecksObject])

    let checksArray: InsuranceValidationEntries<IPublishValidationObject> =
        Object.entries(publishChecksObject)

    return (
        <Container>
            <Title>
                Almost there!{' '}
                <Chevron
                    alt={'dismiss'}
                    src={close_cross_grey}
                    style={{ width: '12px', height: '12px' }}
                    onClick={onClose}
                />
            </Title>
            <SubText>
                Please fill in all required fields before submitting. Use the
                links below to finish any missing details.
            </SubText>

            <div style={{ paddingTop: '20px' }} />

            {checksArray.map(
                (check: [string, IPublishValidationObject[string]], i) => {
                    let checkName = check[0]
                    let checkValues = check[1]
                    if (checkValues.subItems) {
                        let subChecksArray: IValidation_subObject[] =
                            Object.values(checkValues.subItems)

                        return (
                            <React.Fragment
                                key={`insurance_valid_sheet_item${i}_${
                                    props.isMobile ? 'mobile' : 'desktop'
                                }`}
                            >
                                <PublishCheckItem
                                    isTooltipRemoved={
                                        checkValues.isTooltipRemoved
                                    }
                                    isIncomplete={checkValues.check === false}
                                    hasSubItems
                                    isLast={
                                        subItemsOpenState[checkName]
                                            ? true
                                            : i === checksArray.length - 1
                                            ? true
                                            : false
                                    }
                                    hasNitems={checkValues.hasNitems}
                                    minN={checkValues.minN}
                                    tooltipText={checkValues.tooltipText}
                                    isOpen={subItemsOpenState[checkName]}
                                    text={checkValues.text}
                                    onClick={() => {
                                        setSubItemsOpenState((prevState) => {
                                            return {
                                                // closing all others and
                                                ...subItemsOpenState,
                                                // keeping only one open
                                                [checkName]:
                                                    !prevState[checkName],
                                            }
                                        })
                                    }}
                                    isCaps={checkValues.isCaps}
                                />

                                <Expander
                                    height={
                                        subItemsOpenState[checkName]
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    {subChecksArray.map(
                                        (
                                            subItem: IValidation_subObject,
                                            index: number
                                        ) => (
                                            <React.Fragment
                                                key={`insurance_valid_sheet_sub_item${index}_${
                                                    props.isMobile
                                                        ? 'mobile'
                                                        : 'desktop'
                                                }`}
                                            >
                                                <PublishCheckItem
                                                    isIncomplete={
                                                        subItem.check === false
                                                    }
                                                    text={subItem.text}
                                                    isSubItem
                                                    onClick={() => {
                                                        subItem.action()
                                                    }}
                                                    isLast={
                                                        index ===
                                                        subChecksArray.length -
                                                            1
                                                            ? true
                                                            : false
                                                    }
                                                    isDisabled={
                                                        subItem.isDisabled
                                                    }
                                                    hasNitems={
                                                        subItem.hasNitems
                                                    }
                                                    minN={subItem.minN}
                                                    tooltipText={
                                                        subItem.tooltipText
                                                    }
                                                    isTooltipRemoved={
                                                        subItem.isTooltipRemoved
                                                    }
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                    <div
                                        style={{
                                            paddingTop: '16px',
                                        }}
                                    />
                                </Expander>
                            </React.Fragment>
                        )
                    } else
                        return (
                            <React.Fragment
                                key={`insurance_valid_sheet_item${i}_${
                                    props.isMobile ? 'mobile' : 'desktop'
                                }`}
                            >
                                <PublishCheckItem
                                    isTooltipRemoved={
                                        checkValues.isTooltipRemoved
                                    }
                                    hasNitems={checkValues.hasNitems}
                                    minN={checkValues.minN}
                                    tooltipText={checkValues.tooltipText}
                                    isIncomplete={checkValues.check === false}
                                    text={checkValues.text}
                                    isLast={
                                        i === checksArray.length - 1
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        checkValues.action()
                                    }}
                                />
                            </React.Fragment>
                        )
                }
            )}
        </Container>
    )
}

export default InsuranceValidationSheet
