import VehicleItemDesktop from '../../../atoms/list/vehicleItemDesktop'
import EmptyVehicleItem from '../../../atoms/placeholders/insuranceEmptyVehicleItem'
import VehicleItemMobile from '../../../atoms/list/vehicleItemMobile'
import { IInsuranceQuoteApplication_VehiclesObj } from '../../../../redux/insuranceQuoteApplication/reducer'
import { useNavigate } from 'react-router-dom'
import { ICarsObject } from '../../../../redux/entities/cars/types'

type Props = {
    carsData: ICarsObject
    insuranceVehiclesData?: IInsuranceQuoteApplication_VehiclesObj
    vehicles_to_insure_list?: string[]
    everyday_car_ids?: string[]
    onCardClick: (id: string) => void
    onRemove?: (id: string) => void
    isMobile?: boolean
    applicationID?: string | undefined
}
const InsuranceVehiclesToInsureCardContent = (props: Props) => {
    let {
        carsData,
        vehicles_to_insure_list,
        everyday_car_ids,
        onCardClick,
        onRemove,
        isMobile,
        insuranceVehiclesData,
    } = props

    const navigate = useNavigate()

    return vehicles_to_insure_list && vehicles_to_insure_list.length > 0 ? (
        <div style={{ width: '100%', boxSizing: 'border-box', paddingTop: 16 }}>
            {vehicles_to_insure_list.map((vehicle_id, i) => (
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        paddingBottom: 8,
                        cursor: 'pointer',
                    }}
                    onClick={() => onCardClick(vehicle_id)}
                    key={`${vehicle_id}_${i}_${
                        isMobile ? 'mobile' : 'desktop'
                    }`}
                >
                    {isMobile ? (
                        <VehicleItemMobile
                            garageItem={carsData[vehicle_id]}
                            isEverydayCar={
                                everyday_car_ids?.includes(vehicle_id)
                                    ? true
                                    : false
                            }
                            onDefaultRemove={() => {
                                onRemove && onRemove(vehicle_id)
                            }}
                            insuranceVehicleItem={
                                insuranceVehiclesData &&
                                insuranceVehiclesData[vehicle_id]
                            }
                            completion={
                                (insuranceVehiclesData &&
                                    insuranceVehiclesData[vehicle_id]
                                        ?.completion) ??
                                0
                            }
                            sectionId="vehicles_to_insure"
                        />
                    ) : (
                        <VehicleItemDesktop
                            insuranceVehicleItem={
                                insuranceVehiclesData &&
                                insuranceVehiclesData[vehicle_id]
                            }
                            garageItem={carsData[vehicle_id]}
                            isEverydayCar={
                                everyday_car_ids?.includes(vehicle_id)
                                    ? true
                                    : false
                            }
                            onDefaultRemove={() => {
                                onRemove && onRemove(vehicle_id)
                            }}
                            completion={
                                (insuranceVehiclesData &&
                                    insuranceVehiclesData[vehicle_id]
                                        ?.completion) ??
                                0
                            }
                            sectionId="vehicles_to_insure"
                        />
                    )}
                </div>
            ))}
        </div>
    ) : (
        <div style={{ width: '100%', boxSizing: 'border-box', paddingTop: 16 }}>
            {[1, 2, 3].map((i) => (
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        paddingBottom: 8,
                        opacity: 1 / i,
                        cursor: 'pointer',
                    }}
                    key={`empty_vehicle_card_${i}_${
                        isMobile ? 'mobile' : 'desktop'
                    }`}
                    onClick={() => {
                        if (props.applicationID) {
                            navigate(
                                `/insurance/application/${props.applicationID}/vehicles`
                            )
                        }
                    }}
                >
                    <EmptyVehicleItem />
                </div>
            ))}
        </div>
    )
}

export default InsuranceVehiclesToInsureCardContent
