import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { editFormsActions } from '../../../../redux/editForms/reducer'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import UserCircleProfilePage from '../../../atoms/user/userCircleProfilePage'
import {
    flatOwnerAddress_Showroom,
    UserSocialMediaRow,
} from './ownerSectionMobileWithCarPic'
import user_instagram from '../../../../public/assets/icons/socialMedia/user_instagram.svg'
import user_facebook from '../../../../public/assets/icons/socialMedia/user_facebook.svg'
import user_linkedin from '../../../../public/assets/icons/socialMedia/user_linkedin.svg'
import user_twitter from '../../../../public/assets/icons/socialMedia/user_twitter.svg'
import user_website from '../../../../public/assets/icons/socialMedia/user_website.svg'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { IUser } from '../../../../redux/user/types'

type Props = {
    carOwner: IUser | undefined
    entryid: string
    isSold?: boolean
    isAdmin?: boolean
    setActiveForm?: () => void
    isPOA?: boolean | undefined
}

type StyleProps = {
    $isTitle?: boolean
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 3.5rem;

    width: 100%;
    box-sizing: border-box;

    border: 1px solid var(--border-muted, #e5e5e5);
`

const UserInfoContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    box-sizing: border-box;
    height: inherit;
`

const UserInfoContent = styled.span<StyleProps>`
    width: 80%;
    height: 100%;

    font-family: 'Lato';
    font-style: normal;
    font-weight: ${(props) => (props.$isTitle ? 500 : 400)};
    font-size: ${(props) => (props.$isTitle ? '1.125rem' : '1rem')};
    line-height: ${(props) => (props.$isTitle ? '2rem' : '1.5rem')};
    letter-spacing: ${(props) => (props.$isTitle ? 'normal' : 'normal')};
    text-align: center;
    text-transform: capitalize;

    color: ${(props) =>
        props.$isTitle
            ? 'var(--text-strong, #1a1a1a)'
            : 'var(--text-default, #666666)'};
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;

    width: 100%;
    box-sizing: border-box;
    height: inherit;

    align-self: stretch;
`

const ImgContainer = styled.div`
    height: 45px;
    margin-bottom: 4rem;
`

const IconImg = styled.img`
    width: 24px;
`

const OwnerSectionMobile = (props: Props) => {
    const { carOwner, entryid } = props
    const navigate = useNavigate()
    let dispatch = useAppDispatch()

    let hasAnySocialMedia: boolean = carOwner?.external_urls?.WEBSITE?.url
        ? true
        : carOwner?.external_urls?.FACEBOOK?.url
        ? true
        : carOwner?.external_urls?.INSTAGRAM?.url
        ? true
        : carOwner?.external_urls?.TWITTER?.url
        ? true
        : carOwner?.external_urls?.LINKEDIN?.url
        ? true
        : false

    const clickOnOwner = () => {
        if (carOwner && carOwner.id && carOwner.display_name) {
            dispatch(showroomActions.setCurrentPageNumber_sold(0))
            dispatch(showroomActions.setCurrentPageNumber_sale(0))

            dispatch(
                showroomActions.searchShowroom_setCars({
                    cars: undefined,
                    resultsFoundNumber: undefined,
                    similarSoldCarsNumber: undefined,
                    type: props.isSold ? 'sold' : 'sale',
                })
            )
            dispatch(
                showroomActions.setOwner_filter({
                    id: carOwner?.id,
                    display_name: carOwner?.display_name,
                })
            )
            let url = `/showroom`

            if (props.isSold) {
                url = url + '/sold'
            } else {
                url = url + '/for-sale'
            }

            url =
                url +
                `?owner_id=${carOwner?.id}&owner_display_name=${carOwner?.display_name}`
            url = encodeURI(url)
            navigate(url)
        }
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const isOwner =
        carOwner?.id && userLoggedIn && carOwner.id === userLoggedIn.id
            ? true
            : false

    return (
        <Container>
            <ImgContainer>
                <UserCircleProfilePage
                    url={
                        carOwner?.profile_image_url?.length
                            ? carOwner?.profile_image_url
                            : null
                    }
                    onClick={() => {
                        if (props.isAdmin) {
                            dispatch(
                                editFormsActions.toggleUserProfileImageForm
                            )
                        } else {
                            clickOnOwner()
                        }
                    }}
                />
            </ImgContainer>
            <UserInfoContentContainer>
                <UserInfoContent $isTitle>
                    {/* put placeholder in case of undefined value */}
                    {carOwner?.display_name?.trim()?.length
                        ? carOwner?.display_name
                        : 'No name'}
                </UserInfoContent>
                <UserInfoContent
                    style={{
                        textTransform:
                            carOwner?.address?.postcode === 'SW1A 1AA'
                                ? 'none'
                                : 'capitalize',
                    }}
                >
                    {flatOwnerAddress_Showroom(carOwner?.address!)}
                </UserInfoContent>
            </UserInfoContentContainer>

            <div style={{ paddingTop: '16px' }} />
            {hasAnySocialMedia ? (
                <UserSocialMediaRow>
                    {carOwner?.external_urls?.WEBSITE?.url &&
                    carOwner?.external_urls?.WEBSITE?.url !== '' ? (
                        <a
                            href={`${carOwner.external_urls.WEBSITE.url}`}
                            target="_blank"
                        >
                            <IconImg src={user_website} alt="website" />
                        </a>
                    ) : null}

                    {carOwner?.external_urls?.FACEBOOK?.url &&
                    carOwner?.external_urls?.FACEBOOK?.url !== '' ? (
                        <a
                            href={`${carOwner.external_urls.FACEBOOK.url}`}
                            target="_blank"
                        >
                            <IconImg src={user_facebook} alt="facebook" />
                        </a>
                    ) : null}

                    {carOwner?.external_urls?.LINKEDIN?.url &&
                    carOwner?.external_urls?.LINKEDIN?.url !== '' ? (
                        <a
                            href={`${carOwner.external_urls.LINKEDIN.url}`}
                            target="_blank"
                        >
                            <IconImg src={user_linkedin} alt="linkedin" />
                        </a>
                    ) : null}

                    {carOwner?.external_urls?.INSTAGRAM?.url &&
                    carOwner?.external_urls?.INSTAGRAM?.url !== '' ? (
                        <a
                            href={`${carOwner.external_urls.INSTAGRAM.url}`}
                            target="_blank"
                        >
                            <IconImg src={user_instagram} alt="instagram" />
                        </a>
                    ) : null}

                    {carOwner?.external_urls?.TWITTER?.url &&
                    carOwner?.external_urls?.TWITTER?.url !== '' ? (
                        <a
                            href={`${carOwner.external_urls.TWITTER.url}`}
                            target="_blank"
                        >
                            <IconImg src={user_twitter} alt="twitter" />
                        </a>
                    ) : null}
                </UserSocialMediaRow>
            ) : (
                <div style={{ paddingTop: '8px' }} />
            )}

            <ActionsContainer>
                {props.isSold ? null : !props.isAdmin ? (
                    <>
                        {carOwner?.phone && (
                            <a
                                href={`tel:${carOwner.phone}`}
                                style={{ width: '100%' }}
                            >
                                <ButtonAtom
                                    theme="flexible-transparent-blue-border"
                                    fontSize={14}
                                    width="100%"
                                    height="48px"
                                    dataCyId={'showroom-car-share'}
                                    icon={''}
                                >
                                    Call now
                                </ButtonAtom>
                            </a>
                        )}
                        <ButtonAtom
                            theme="flexible-transparent-blue-border"
                            fontSize={14}
                            width="100%"
                            height="48px"
                            dataCyId={'showroom-car-share'}
                            icon={''}
                            onClick={() => {
                                if (!userLoggedIn) {
                                    navigate('/login')
                                    dispatch(
                                        customNavDataActions.setInitialLandingUrl(
                                            `/showroom/${entryid}/enquiry?tab=info`
                                        )
                                    )
                                } else {
                                    navigate(
                                        `/showroom/${entryid}/enquiry?tab=info`
                                    )
                                }
                            }}
                            disabled={isOwner ? true : false}
                        >
                            Request info
                        </ButtonAtom>
                        <ButtonAtom
                            theme="secondary"
                            width="100%"
                            height="48px"
                            textTransform="none"
                            dataCyId={'showroom-car-enquire'}
                            disabled={isOwner || props.isPOA ? true : false}
                            onClick={() => {
                                if (!userLoggedIn) {
                                    navigate('/login')
                                    dispatch(
                                        customNavDataActions.setInitialLandingUrl(
                                            `/showroom/${entryid}/enquiry?tab=offer`
                                        )
                                    )
                                } else {
                                    navigate(
                                        `/showroom/${entryid}/enquiry?tab=offer`
                                    )
                                }
                            }}
                        >
                            Make an offer
                        </ButtonAtom>
                    </>
                ) : (
                    <ButtonAtom
                        theme="flexible-transparent-blue-border"
                        width="100%"
                        height="48px"
                        dataCyId={'showroom-car-share'}
                        icon={''}
                        fontSize={16}
                        onClick={() => {
                            props.setActiveForm && props.setActiveForm()
                        }}
                    >
                        Edit contact details
                    </ButtonAtom>
                )}
            </ActionsContainer>
        </Container>
    )
}

export default OwnerSectionMobile
