import {
    IGalleryImagesObject,
    IGalleryImage,
} from '../../entities/galleries/types'
import {
    IEntryPayloadAPI,
    IPayloadCarGalleryImageFromApi,
} from '../../services/typedefinitions/apiPayloads'
import { convertToGalleryImage } from '../entities/conversionFromAPI'

export const convertEntryImagesAPIToGalleryImagesState = (
    entryPayload: IEntryPayloadAPI
): IGalleryImagesObject => {
    let gallery_images_normalised: IGalleryImagesObject = {}

    if (entryPayload.images) {
        entryPayload.images.length > 0 &&
            entryPayload.images.forEach(
                (image: IPayloadCarGalleryImageFromApi, index: number) => {
                    if (image !== undefined) {
                        let image_obj: IGalleryImage =
                            convertToGalleryImage(image)
                        gallery_images_normalised[image_obj.id] = image_obj
                    }
                }
            )
        return gallery_images_normalised
    } else return gallery_images_normalised
}
