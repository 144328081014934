import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRegisterPayloadReq } from './sagas/authSagas'
import { IAuthSocialRequestPayload } from './sagas/socialAuth'
import {
    I_getUserDirectSharesReceivedRequest_req_payload,
    IaddAddressRequest,
} from './actions/loadingActions'
import { I_getUserDirectSharesReceivedSuccess_res_payload } from './actions/actions'
import {
    ILabel,
    IUser,
    IUser_sharesReceived,
    IUser_SharesReceived_byEntity,
    IUSerGeneralInfoUpdatePayload,
    IUserHandoversObjByState,
    IUsersState,
} from './types'
import {
    ISignInPayloadReq,
    IUpdateShareReveivedStatusApi_arg,
} from '../services/types'
import { IUserAddressApi } from '../services/typedefinitions/apiPayloads'
import { IList } from 'entityModels'
import { IReduxError } from '../entities/cars/types'

const initialState: IUsersState = {
    error: null,
    loading: false,
    userLoggedIn: null,
    hasLoggedOut: false,
    isAuthLoading: false,
    tokenCheck: {
        isTokenReqInProcess: false,
        hasSessionRefreshedTokenOnce: false,
        retriesCount: 0,
    },
    sharesOwnedLoading: false,
    receivedSharesLoading: false,
    secondaryLoading: false,
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        registerRequest: (
            state,
            action: PayloadAction<IRegisterPayloadReq>
        ) => {
            state.isAuthLoading = true
            state.error = null
        },
        socialAuthRequest: (
            state,
            action: PayloadAction<IAuthSocialRequestPayload>
        ) => {
            state.isAuthLoading = true
            state.error = null
        },
        registerSuccess: (state) => {
            state.loading = false
            state.isAuthLoading = false
            state.error = null
        },
        registerError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.isAuthLoading = false
            state.error = action.payload
        },
        signInUserRequest: (
            state,
            action: PayloadAction<ISignInPayloadReq>
        ) => {
            state.isAuthLoading = true
            state.error = null
        },
        signInUserSuccess: (state) => {
            state.loading = false
            state.isAuthLoading = false
            state.error = null
        },
        signInUserError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.isAuthLoading = false
            state.error = action.payload
        },
        logoutRequest: (state) => {
            state.loading = true
            state.error = null
        },
        logoutUserSuccess: (state) => {
            state.userLoggedIn = null
            state.hasLoggedOut = true
            state.loading = false
            state.error = null
        },
        logoutUserError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        addPhoneSuccess: (state, action: PayloadAction<string>) => {
            if (state.userLoggedIn) {
                state.userLoggedIn.phone = action.payload
            }
            state.loading = false
            state.error = null
        },
        addPhoneRequest: (state) => {
            state.loading = true
            state.error = null
        },
        addPhoneError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        addCategorySuccess: (state) => {
            state.loading = false
            state.error = null
        },
        addCategoriesRequest: (state) => {
            state.loading = true
            state.error = null
        },
        addCategoriesError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        addAddressSuccess: (state, action: PayloadAction<IUserAddressApi>) => {
            if (state.userLoggedIn) {
                state.userLoggedIn.address = action.payload
            }
            state.loading = false
            state.error = null
        },
        addAddressRequest: (
            state,
            action: PayloadAction<IaddAddressRequest>
        ) => {
            state.loading = true
            state.error = null
        },
        addAddressError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        addAddressErrorNoModal: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = null
        },
        verifySuccess: (state) => {
            if (state.userLoggedIn) {
                state.userLoggedIn.verified = true
            }
            state.loading = false
            state.error = null
        },
        verifyRequest: (state) => {
            state.loading = true
            state.error = null
        },
        verifyError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        sendInvitationRequest: (state) => {
            state.loading = true
            state.error = null
        },

        resetPasswordRequest: (state, action: PayloadAction<string>) => {
            state.loading = true
            state.error = null
        },
        sendInvitationSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        sendInvitationError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        resetPasswordError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },
        resetPasswordSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        verifiedResetPasswordRequest: (
            state,
            action: PayloadAction<{ passwordGiven: string; uid: string }>
        ) => {
            state.loading = true
            state.error = null
        },
        verifiedResetPasswordError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        verifiedResetPasswordSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        getCurrentUserDataRequest: (
            state,
            action: PayloadAction<undefined | 'LOGIN' | 'REGISTER'>
        ) => {
            state.loading = true
            state.error = null
        },
        getCurrentUserDataSuccess: (state, action: PayloadAction<IUser>) => {
            const usr = state.userLoggedIn ? state.userLoggedIn : {}
            state.loading = false
            state.error = null
            state.userLoggedIn = {
                ...usr,
                ...action.payload,
            }
        },
        getCurrentUserDataError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.userLoggedIn = null
            state.loading = false
            state.error = action.payload
        },
        updateUserGeneralInfoRequest: (
            state,
            action: PayloadAction<IUSerGeneralInfoUpdatePayload>
        ) => {
            state.loading = true
            state.error = null
        },
        updateUserGeneralInfoSuccess: (state, action: PayloadAction<IUser>) => {
            state.loading = false
            state.error = null
            state.userLoggedIn = action.payload
        },
        updateUserGeneralInfoError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        resetErrorStateSuccess: (state) => {
            state.loading = false
            state.error = null
        },

        verifyResetPasswordCodeSuccess: (
            state,
            action: PayloadAction<string>
        ) => {
            if (state.userLoggedIn) {
                state.userLoggedIn = {
                    ...state.userLoggedIn,
                    id: action.payload,
                }
            } else {
                alert('No user successfully logged in')
                // state.userLoggedIn = { id: '', id: action.payload }
            }
            state.loading = false
            state.error = null
        },
        verifyResetPasswordCodeError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        verifyResetPasswordCodeRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.loading = true
            state.error = null
        },

        refreshTokenRequest: (state, action: PayloadAction<boolean>) => {
            state.tokenCheck = {
                ...state.tokenCheck,
                isTokenReqInProcess: action.payload,
            }
            state.loading = true
            state.error = null
        },

        refreshTokenSuccess: (state, action: PayloadAction<boolean>) => {
            state.tokenCheck = {
                ...state.tokenCheck,
                isTokenReqInProcess: action.payload,
            }
            state.loading = false
            state.error = null
        },
        refreshTokenError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        refreshTokenOnceRequest: (state, action: PayloadAction<boolean>) => {
            state.tokenCheck = {
                ...state.tokenCheck,
                hasSessionRefreshedTokenOnce: action.payload,
            }
            state.loading = true
            state.error = null
        },

        refreshTokenOnceSuccess: (state, action: PayloadAction<boolean>) => {
            state.tokenCheck = {
                ...state.tokenCheck,
                hasSessionRefreshedTokenOnce: action.payload,
            }
            state.loading = false
            state.error = null
        },

        refreshTokenOnceError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        addToRefreshTokenRetriesCount: (state) => {
            state.tokenCheck = {
                ...state.tokenCheck,
                retriesCount: state.tokenCheck.retriesCount + 1,
            }
            state.loading = false
            state.error = null
        },

        resetRefreshTokenRetriesCount: (state) => {
            state.tokenCheck = {
                ...state.tokenCheck,
                retriesCount: 0,
            }
            state.loading = false
            state.error = null
        },

        addLabelsToUserSuccess: (state, action: PayloadAction<ILabel[]>) => {
            state.loading = false
            state.error = null
            if (state.userLoggedIn) {
                state.userLoggedIn.labels = state.userLoggedIn.labels
                    ? [...state.userLoggedIn.labels, ...action.payload]
                    : [...action.payload]
            }
        },

        // SHARING
        getUserDirectSharesReceivedSuccess: (
            state,
            action: PayloadAction<I_getUserDirectSharesReceivedSuccess_res_payload>
        ) => {
            let data: IUser_sharesReceived =
                state.userLoggedIn?.received_direct_shares || {}

            if (
                action.payload.resource_type === 'car_and_garage' &&
                action.payload.cars &&
                action.payload.garages
            ) {
                data = {
                    ...data,
                    cars: {
                        shares_received_ids: [
                            ...action.payload.cars.shares_received_ids,
                        ],
                        accepted_shares_received_ids: [
                            ...action.payload.cars.accepted_shares_received_ids,
                        ],
                        pending_shares_received_ids: [
                            ...action.payload.cars.pending_shares_received_ids,
                        ],
                        ignored_shares_received_ids: [
                            ...action.payload.cars.ignored_shares_received_ids,
                        ],
                        shares_received_data: {
                            ...action.payload.cars.shares_received_data,
                        },
                    },
                    garages: {
                        shares_received_ids: [
                            ...action.payload.garages.shares_received_ids,
                        ],
                        accepted_shares_received_ids: [
                            ...action.payload.garages
                                .accepted_shares_received_ids,
                        ],
                        pending_shares_received_ids: [
                            ...action.payload.garages
                                .pending_shares_received_ids,
                        ],
                        ignored_shares_received_ids: [
                            ...action.payload.garages
                                .ignored_shares_received_ids,
                        ],
                        shares_received_data: {
                            ...action.payload.garages.shares_received_data,
                        },
                    },
                }
            } else if (
                action.payload.resource_type === 'car' &&
                action.payload.cars
            ) {
                data = {
                    ...data,
                    cars: {
                        ...data.cars,
                        shares_received_ids: [
                            ...action.payload.cars.shares_received_ids,
                        ],
                        accepted_shares_received_ids: [
                            ...action.payload.cars.accepted_shares_received_ids,
                        ],
                        pending_shares_received_ids: [
                            ...action.payload.cars.pending_shares_received_ids,
                        ],
                        ignored_shares_received_ids: [
                            ...action.payload.cars.ignored_shares_received_ids,
                        ],
                        shares_received_data: {
                            ...action.payload.cars.shares_received_data,
                        },
                    },
                }
            } else if (
                action.payload.resource_type === 'garage' &&
                action.payload.garages
            ) {
                data = {
                    ...data,
                    garages: {
                        shares_received_ids: [
                            ...action.payload.garages.shares_received_ids,
                        ],
                        accepted_shares_received_ids: [
                            ...action.payload.garages
                                .accepted_shares_received_ids,
                        ],
                        pending_shares_received_ids: [
                            ...action.payload.garages
                                .pending_shares_received_ids,
                        ],
                        ignored_shares_received_ids: [
                            ...action.payload.garages
                                .ignored_shares_received_ids,
                        ],
                        shares_received_data: {
                            ...action.payload.garages.shares_received_data,
                        },
                    },
                }
            }

            state.receivedSharesLoading = false
            state.error = null
            if (state.userLoggedIn) {
                state.userLoggedIn.received_direct_shares = data
            }
        },

        getUserDirectSharesReceivedRequest: (
            state,
            action: PayloadAction<I_getUserDirectSharesReceivedRequest_req_payload>
        ) => {
            state.receivedSharesLoading = true
            state.error = null
        },

        getUserDirectSharesReceivedError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.receivedSharesLoading = false
            state.error = action.payload
        },

        populateOwnedSharesDataRequest: (state) => {
            state.sharesOwnedLoading = true
        },

        populateOwnedSharesDataError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.sharesOwnedLoading = false
            state.error = action.payload
        },

        populateOwnedSharesDataPopulationSuccess: (state) => {
            state.sharesOwnedLoading = false
        },

        updateShareReceivedStatusSuccess: (
            state,
            action: PayloadAction<{
                entity_type: string
                shares_received: IUser_SharesReceived_byEntity
            }>
        ) => {
            state.loading = false
            state.error = null
            if (state.userLoggedIn?.received_direct_shares) {
                if (action.payload.entity_type === 'garage') {
                    state.userLoggedIn.received_direct_shares.garages =
                        action.payload.shares_received
                } else {
                    state.userLoggedIn.received_direct_shares.cars =
                        action.payload.shares_received
                }
            }
        },

        updateShareReceivedStatusRequest: (
            state,
            action: PayloadAction<IUpdateShareReveivedStatusApi_arg>
        ) => {
            state.loading = true
            state.error = null
        },

        updateShareReceivedStatusError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        updateUserTimeZone: (
            state,
            action: PayloadAction<{
                idsList: string[]
                data: { [key: string]: IList }
            }>
        ) => {
            state.loading = false
            state.error = null
            if (state.userLoggedIn?.preferences?.data?.notifications?.data) {
                state.userLoggedIn.preferences.data.notifications.data.time_zone =
                    {
                        ...state.userLoggedIn.preferences.data.notifications
                            .data.time_zone,
                        ids_list: action.payload.idsList,
                        data: action.payload.data,
                    }
            }
        },

        setUserArchivedCarsSuccess: (
            state,
            action: PayloadAction<{
                car_ids: string[]
                activeSort: string
            }>
        ) => {
            state.loading = false
            if (state.userLoggedIn) {
                state.userLoggedIn.archived_cars = {
                    car_ids: [...action.payload.car_ids],
                    active_sort: action.payload.activeSort,
                }
            }
        },

        getUserArchivedCarsRequest: (
            state,
            action: PayloadAction<
                | 'ARCHIVED_ON_OLDEST'
                | 'ARCHIVED_ON_MOST_RECENT'
                | 'MANUFACTURE_DATE_ASCENDING'
                | 'MANUFACTURE_DATE_DESCENDING'
                | undefined
            >
        ) => {
            state.loading = true
            state.error = null
        },

        getUserArchivedCarsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        updateArchivedCars: (
            state,
            action: PayloadAction<string[] | undefined>
        ) => {
            state.loading = false
            if (state.userLoggedIn?.archived_cars) {
                state.userLoggedIn.archived_cars.car_ids = action.payload ?? []
            }
        },

        updateUserTasksCountOnTasksCreation: (state) => {
            if (state.userLoggedIn) {
                state.userLoggedIn.total_tasks_count =
                    (state.userLoggedIn.total_tasks_count || 0) + 1
            }
        },

        updateUserTasksCountOnTasksDeletion: (state) => {
            if (state.userLoggedIn?.total_tasks_count) {
                state.userLoggedIn.total_tasks_count = Math.max(
                    0,
                    state.userLoggedIn.total_tasks_count - 1
                )
            }
        },

        listUserHandoversRequest: (
            state,
            action: PayloadAction<
                | ('INITIATED' | 'PROCESSING' | 'COMPLETED' | 'FAILED')[]
                | undefined
            >
        ) => {
            // we don't want to bloat the page with loading = true, when it's already there
        },

        listUserHandoversError: (state, action: PayloadAction<IReduxError>) => {
            state.error = action.payload
            state.loading = false
        },

        deleteAccountSuccess: (state) => {
            state.error = null
            state.loading = false
        },

        deleteAccountRequest: (state, action: PayloadAction<any>) => {
            state.loading = true
        },
        deleteAccountError: (state, action: PayloadAction<IReduxError>) => {
            state.error = action.payload
            state.loading = false
        },
        listUserHandoversSuccess: (
            state,
            action: PayloadAction<IUserHandoversObjByState>
        ) => {
            if (state.userLoggedIn) {
                state.userLoggedIn.handovers = {
                    ...action.payload,
                }
            }
        },
        getUserMailingGroupsSuccess: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.secondaryLoading = false
            state.error = null
            if (state.userLoggedIn) {
                state.userLoggedIn.mailingGroups = [...action.payload]
            }
        },
        getUserMailingGroupsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.secondaryLoading = false
            state.error = action.payload
        },
        getUserMailingGroupsRequest: (state) => {
            state.secondaryLoading = true
            state.error = null
        },
        updateUserMailingGroupsSuccess: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.secondaryLoading = false
            state.error = null
            if (state.userLoggedIn) {
                state.userLoggedIn.mailingGroups = [...action.payload]
            }
        },
        updateUserMailingGroupsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.secondaryLoading = false
            state.error = action.payload
        },
        updateUserMailingGroupsRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.secondaryLoading = true
            state.error = null
        },
    },
})

export const usersActions = usersSlice.actions

export default usersSlice.reducer

export type UsersState = ReturnType<typeof usersSlice.reducer>
