import { useState, useEffect } from 'react'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import ReturnNav from '../../atoms/header/returnNav'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import ModalDisplayGalleryImageDesktop from '../../templates/displays/pageWrappers/modalDisplayGalleryImageDesktop'
import CarGalleryEditExistingImageFormMobile from '../../organisms/editForms/carGalleryEditForm/carGalleryEditExistingImageFormMobile'
import styled from 'styled-components'
import GallerySlide from '../../molecules/slideshow/gallerySlide'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import TutorialConfigsCarGalleryImageDesktop from '../../molecules/tutorialConfigs/car/tutorialConfigsCarGalleryImageDesktop'
import TutorialConfigsCarGalleryImageMobile from '../../molecules/tutorialConfigs/car/tutorialConfigsCarGalleryImageMobile'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import ImageSubMenuActions from '../../atoms/menu/pageSubMenuActions/imageSubMenuActions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { countFeaturedImages } from '../../../helpers/numbers/countFeaturedImages'
import GalleryImageCarousel from '../../organisms/galleryCarousel'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import { IShowroomEntry } from '../../../redux/showroom/types'
import { showroomActions } from '../../../redux/showroom/reducer'
import colours from '../../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { ICar } from '../../../redux/entities/cars/types'
import { IUpdateCarGalleryImagePayload } from '../../../redux/entities/galleries/types'
import { carActions } from '../../../redux/entities/cars/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { galleriesActions } from '../../../redux/entities/galleries/reducer'
import useThemes from '../../../providers/theme/hooks'

type Iids = {
    carid: string
    imageid: string
}

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    padding-bottom: 5px;
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
    font-size: 14px;
    color: var(--text-muted);
    padding-bottom: 0px;
`

export const PaddingSpan = styled.span`
    padding-left: 8px;
`

export const RowWrapper = styled.div`
    display: flex;
    margin-top: 15px;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    position: relative;
    width: 100vw;
    padding-left: 15px;
    padding-right: 15px;
`

export const IconAdjust = styled.span`
    margin-top: -1px;
`

export const Container = styled.div`
    height: 70px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--bg-color, #fff);
    border-top: 1px solid var(--border-muted, #e5e5e5);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
`

export const LinkStyled = styled(Link)`
    flex: 0.5;
`
// NotLinkStyled
export const NotLinkStyled = styled.div`
    flex: 0.5;
`
export const StyledIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const MiddleLine = styled.div`
    height: 100%;
    width: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

const DeleteImageConfirmationWrapper = styled.div`
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 88vw;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: georgia;
    z-index: 25;
`

const SuccessDeletionMessage = styled.div`
    font-family: Lato;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
`

export const ReturnToGalleryLink = styled(Link)`
    color: var(--primary, #5ec3ca) !important;
    font-size: 14px;
    padding: 10px;
    width: 60vw;
    border: 1px solid var(--primary, #5ec3ca);
    border-radius: 5px;
    margin-top: 30px;
    text-align: center;
    z-index: 15;
    font-family: Lato;
`

const SubWrapper = styled.div`
    background-color: var(--off-bg-color, #fafafa);
`

const GalleryImageV2 = () => {
    let { theme } = useThemes()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()
    const [showEditImageForm, setShowEditImageForm] = useState(false)
    const [showDeleteConfirmationMessage, setShowDeleteConfirmationMessage] =
        useState(false)
    const [image_index, setImage_index] = useState<number | undefined>(
        undefined
    )

    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const imagesData = useAppSelector(
        (state) => state.entities.galleriesData.images
    )
    const isExistingcarGalleryImageFormOpened = useAppSelector(
        (state) =>
            state.editForms.formsData.carExistingGalleryImageEditForm.isOpen
    )
    const isNecessaryGalleryDataLoading = useAppSelector(
        (state) => state.entities.galleriesData.loading
    )
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const userShowroomEntriesData = useAppSelector(
        (state) => state.showroom.userShowroomEntries
    )

    const setUpInitialIndex = () => {
        let params = new URLSearchParams(location.search)
        let image_id_param = params.get('id')
        if (carsData && carsData[`${carid}`] && image_id_param) {
            setImage_index(
                carsData[`${carid}`]?.gallery.images.indexOf(image_id_param)
            )
        }
    }

    const manageShowroomEntryData = () => {
        let currentCar: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined

        let isCarOwner =
            currentCar &&
            userLoggedIn &&
            currentCar.owner_id === userLoggedIn?.id

        if (isCarOwner === true) {
            let hasEntryID: string | undefined =
                (currentCar &&
                    currentCar.showroom_entry &&
                    currentCar.showroom_entry.state === 'PUBLISHED') ||
                (currentCar &&
                    currentCar.showroom_entry &&
                    currentCar.showroom_entry.state === 'SOLD')
                    ? currentCar.showroom_entry.uid
                    : undefined

            if (hasEntryID) {
                let currentEntry: IShowroomEntry | undefined =
                    hasEntryID &&
                    userShowroomEntriesData &&
                    userShowroomEntriesData[hasEntryID]
                        ? userShowroomEntriesData[hasEntryID]
                        : undefined

                if (!currentEntry) {
                    dispatch(
                        showroomActions.getShowroomEntryByIDRequest({
                            is_owner: true,
                            entryID: hasEntryID,
                        })
                    )
                }
            }
        }
    }

    useEffect(() => {
        dispatch(carActions.setCurrentCar(`${carid}`))

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            let params = new URLSearchParams(location.search)
            let image_id_param = params.get('id')

            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/car/${carid}/gallery?id=${image_id_param}`
                )
            )

            dispatch(carActions.getCarDataByIdRequest(`${carid}`))

            manageShowroomEntryData()

            setUpInitialIndex()
        }
    }, [
        carid,
        userLoggedIn,
        location.search,
        isExistingcarGalleryImageFormOpened,
    ])

    useEffect(() => {
        setUpInitialIndex()
        manageShowroomEntryData()
    }, [carsData])

    const selectMenuGalleryImageState = (id: string) => {
        let params = new URLSearchParams(location.search)
        let image_id_param = params.get('id')

        const currentcar: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined

        let windowSize = window.innerWidth

        let current_image_id =
            windowSize < 800 &&
            image_index !== undefined &&
            currentcar &&
            currentcar?.gallery.images
                ? currentcar.gallery.images[image_index]
                : image_id_param

        if (id === 'cover' && current_image_id) {
            let payload: IUpdateCarGalleryImagePayload = {
                carid: `${carid}`,
                imageid: current_image_id,
                body: {
                    cover: true,
                    featured: false,
                },
            }
            dispatch(galleriesActions.updateCarGalleryImageRequest(payload))

            setTimeout(() => {
                navigate(`/car/${carid}`)
            }, 200)
        } else if (id === 'featured' && current_image_id && imagesData) {
            let payload: IUpdateCarGalleryImagePayload = {
                carid: `${carid}`,
                imageid: current_image_id,
                body: {
                    featured: !imagesData[current_image_id].featured,
                    cover: false,
                },
            }
            dispatch(galleriesActions.updateCarGalleryImageRequest(payload))
        } else if (id === 'edit' && current_image_id) {
            setShowEditImageForm(!showEditImageForm)
            setShowDeleteConfirmationMessage(false)
        } else if (id === 'delete' && current_image_id) {
            setShowEditImageForm(false)
            setShowDeleteConfirmationMessage(true)

            let ids: Iids = {
                carid: `${carid}`,
                imageid: current_image_id,
            }
            dispatch(galleriesActions.deleteCarGalleryImageRequest(ids))
        }
        dispatch(editFormsActions.toggleExistingCarGalleryImageEditForm())
    }

    const generateImageNavigationUrl = (next: string) => {
        let params = new URLSearchParams(location.search)
        let image_id_param = params.get('id')

        if (
            carsData &&
            carid &&
            carsData[carid] !== undefined &&
            image_id_param
        ) {
            const ids_list: string[] =
                carsData[carid].gallery && carsData[carid].gallery.images
                    ? carsData[carid].gallery.images
                    : []

            const index_of_id = ids_list.indexOf(image_id_param)

            if (next === 'next') {
                const index_of_next_image = index_of_id + 1

                if (index_of_next_image === -1) {
                    return '/none'
                }

                const id_of_next_image = ids_list[index_of_next_image]

                if (id_of_next_image !== undefined) {
                    return `/car/${carid}/gallery?id=${id_of_next_image}`
                } else return '/none'
            } else {
                const index_of_previous_image = index_of_id - 1

                if (index_of_previous_image === -1) {
                    return '/none'
                }

                const id_of_previous_image = ids_list[index_of_previous_image]

                return `/car/${carid}/gallery?id=${id_of_previous_image}`
            }
        } else {
            return '/none'
        }
    }

    let params = new URLSearchParams(location.search)
    let image_id_param = params.get('id')

    const currentcar: ICar | undefined =
        carsData && carid && carsData[carid] ? carsData[carid] : undefined

    let prev_url = generateImageNavigationUrl('previous')
    let next_url = generateImageNavigationUrl('next')

    let windowSize = window.innerWidth

    let current_image_id =
        windowSize < 800 &&
        image_index !== undefined &&
        currentcar &&
        currentcar?.gallery.images
            ? currentcar.gallery.images[image_index]
            : image_id_param

    const featuredImagesCount =
        currentcar && imagesData
            ? countFeaturedImages(currentcar, imagesData)
            : 0

    let hasEntryID: string | undefined =
        (currentcar &&
            currentcar.showroom_entry &&
            currentcar.showroom_entry.state === 'PUBLISHED') ||
        (currentcar &&
            currentcar.showroom_entry &&
            currentcar.showroom_entry.state === 'SOLD')
            ? currentcar.showroom_entry.uid
            : undefined

    let carEntry: IShowroomEntry | undefined =
        userShowroomEntriesData &&
        hasEntryID &&
        userShowroomEntriesData[hasEntryID]
            ? userShowroomEntriesData[hasEntryID]
            : undefined

    let entryImagesArray =
        carEntry && carEntry.gallery_images
            ? carEntry.gallery_images.map((item) => item.id)
            : undefined

    let hasShowroomImg: boolean =
        current_image_id &&
        entryImagesArray &&
        entryImagesArray.includes(current_image_id)
            ? true
            : false

    useEffect(() => {
        if (currentcar?.archived_on) {
            const searchParams = new URLSearchParams(location.search)
            const image_id_param = searchParams.get('id')
            navigate(
                `/archives/car/${currentcar.id}/gallery?id=${image_id_param}`,
                { replace: true }
            )
        }
    }, [currentcar, navigate, location.search])

    useEffect(() => {
        disableBodyScroll(document.body)
        return () => {
            enableBodyScroll(document.body)
        }
    }, [])

    const [isActionSheetOpen, setIsActionSheetOpen] = useState(false)

    const openActionSheet = () => setIsActionSheetOpen(true)
    const closeActionSheet = () => setIsActionSheetOpen(false)

    return imagesData ? (
        <>
            {params.get('tutorial') === 'true' && (
                <TutorialConfigsCarGalleryImageDesktop />
            )}

            {params.get('tutorial') === 'true' && (
                <TutorialConfigsCarGalleryImageMobile />
            )}

            <DesktopDisplayOnly>
                <FullPageWrapper>
                    <ModalDisplayGalleryImageDesktop
                        toggle={() => {
                            navigate(location.pathname)
                        }}
                        isOpen={true}
                    >
                        {carsData !== undefined &&
                            currentcar !== undefined &&
                            current_image_id &&
                            imagesData &&
                            imagesData[current_image_id] && (
                                <GallerySlide
                                    isImgInShowroom={hasShowroomImg}
                                    closeGallery={() => {
                                        navigate(location.pathname)
                                    }}
                                    currentImageData={
                                        imagesData[current_image_id]
                                    }
                                    carid={`${carid}`}
                                    imagesData={imagesData}
                                    currentCar={currentcar}
                                    onClickEdit={() =>
                                        dispatch(
                                            editFormsActions.toggleExistingCarGalleryImageEditForm()
                                        )
                                    }
                                    updateImage={(
                                        data: IUpdateCarGalleryImagePayload
                                    ) =>
                                        dispatch(
                                            galleriesActions.updateCarGalleryImageRequest(
                                                data
                                            )
                                        )
                                    }
                                    isSomethingLoading={
                                        isNecessaryGalleryDataLoading
                                    }
                                    prevUrl={prev_url}
                                    nextUrl={next_url}
                                    featuredImagesCount={
                                        currentcar && imagesData
                                            ? countFeaturedImages(
                                                  currentcar,
                                                  imagesData
                                              )
                                            : 0
                                    }
                                />
                            )}
                    </ModalDisplayGalleryImageDesktop>
                </FullPageWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <SubWrapper
                    style={{
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        height: '100vh',
                        backgroundColor: colours[theme].background_default,
                        overflowX: 'hidden',
                    }}
                >
                    <Link
                        to={{
                            pathname: `/car/${carid}/gallery`,
                        }}
                        state={{ prevPath: location.pathname }}
                    >
                        <ReturnNav />
                    </Link>
                    {current_image_id &&
                    imagesData &&
                    imagesData[current_image_id] &&
                    image_index !== undefined ? (
                        carsData !== undefined &&
                        currentcar !== undefined &&
                        userLoggedIn &&
                        userLoggedIn.display_name ? (
                            <GalleryImageCarousel
                                indexOfInitialImage={image_index}
                                image_data={imagesData}
                                images_ids={currentcar.gallery.images}
                                synchroniseActiveIndex={(n: number) => {
                                    setImage_index(n)
                                }}
                                openActionsSheet={openActionSheet}
                                cover_id={currentcar.gallery.cover}
                            />
                        ) : (
                            <SkeletonAbsolute isthingloading={true} />
                        )
                    ) : null}
                    {current_image_id &&
                    imagesData &&
                    imagesData[current_image_id] &&
                    image_index !== undefined &&
                    currentcar ? (
                        <ImageSubMenuActions
                            isOpen={
                                isActionSheetOpen &&
                                !showDeleteConfirmationMessage &&
                                !showEditImageForm
                            }
                            toggle={closeActionSheet}
                            items={[
                                {
                                    id: 'edit',
                                    text: 'Update image details',
                                    action: selectMenuGalleryImageState,
                                },
                                {
                                    id: 'cover',
                                    text: 'Set as car profile image',
                                    action: selectMenuGalleryImageState,
                                },
                                {
                                    id: 'featured',
                                    text:
                                        imagesData[current_image_id]
                                            .featured === true
                                            ? 'Remove feature'
                                            : 'Set as gallery feature',
                                    action: selectMenuGalleryImageState,
                                    isDisabled:
                                        imagesData[current_image_id]
                                            .featured !== true &&
                                        featuredImagesCount >= 5
                                            ? true
                                            : false,
                                    tooltipText:
                                        'Five images already set as gallery features. Please remove at least one before setting new ones.',
                                },

                                {
                                    id: 'delete',
                                    text: 'Delete image',
                                    isDisabled: hasShowroomImg,
                                    tooltipText:
                                        'Image is part of a published showroom entry',
                                    action: selectMenuGalleryImageState,
                                },
                            ]}
                        />
                    ) : null}
                    {isExistingcarGalleryImageFormOpened &&
                        showDeleteConfirmationMessage === false &&
                        showEditImageForm === true &&
                        current_image_id && (
                            <ModalDisplay
                                toggle={() => {
                                    // dispatch(
                                    //     editFormsActions.toggleExistingCarGalleryImageEditForm()
                                    // )
                                }}
                                isOpen={true}
                                customZindex={11}
                            >
                                <CarGalleryEditExistingImageFormMobile
                                    carid={`${carid}`}
                                    updateImage={(
                                        data: IUpdateCarGalleryImagePayload
                                    ) =>
                                        dispatch(
                                            galleriesActions.updateCarGalleryImageRequest(
                                                data
                                            )
                                        )
                                    }
                                    imagedata={
                                        imagesData &&
                                        imagesData[current_image_id]
                                    }
                                    close={() => {
                                        dispatch(
                                            editFormsActions.toggleExistingCarGalleryImageEditForm()
                                        )
                                        setShowDeleteConfirmationMessage(false)
                                        setShowEditImageForm(false)
                                    }}
                                />
                            </ModalDisplay>
                        )}
                    {showDeleteConfirmationMessage && !showEditImageForm && (
                        <ModalDisplay
                            toggle={() => {
                                dispatch(
                                    editFormsActions.toggleExistingCarGalleryImageEditForm()
                                )
                                navigate(`/car/${carid}/gallery`)
                            }}
                            customZindex={11}
                            isOpen={true}
                        >
                            <DeleteImageConfirmationWrapper>
                                <SuccessDeletionMessage>
                                    Image successfully deleted.
                                </SuccessDeletionMessage>

                                <ReturnToGalleryLink
                                    to={{
                                        pathname: `/car/${carid}/gallery`,
                                    }}
                                    replace
                                    onClick={() => {
                                        dispatch(
                                            editFormsActions.toggleExistingCarGalleryImageEditForm()
                                        )
                                    }}
                                >
                                    Return to gallery
                                </ReturnToGalleryLink>
                            </DeleteImageConfirmationWrapper>
                        </ModalDisplay>
                    )}
                </SubWrapper>
            </IpadAndMobileDisplay>
        </>
    ) : (
        <div style={{ display: 'none' }} />
    )
}

export default GalleryImageV2
