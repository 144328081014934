import { Option } from '../../atoms/select/types'
import { ITabProps } from 'myInterfaces'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { currency_data, currency_IDS } from '../../../redux/timeline/data'
import BottomBarBtnsStep from '../../atoms/Button/bottomBarBtnsStep'
import CustomAnimatedCheckboxField from '../../atoms/formField/customAnimatedCheckboxField'
import InfoIcon from '../../atoms/icons/infoIcon'
import Combobox from '../../atoms/select/Combobox'
import { NumberEditOrCreateModeSingleFieldDesktop } from '../../molecules/editOrCreateModeSingleFields'
import BtnTabRow from '../../molecules/tab/btnTabRow'
import RangeRowFieldsDesktop from '../../molecules/editOrCreateModeSingleFields/rangeRow/rangeRowDesktop'
import { IShowroomEntry } from '../../../redux/showroom/types'
import { StandardInputField } from '../../atoms/Inputfield/inputField'
import AnimatedTextarea from '../../atoms/textarea/animatedTextarea'
import { yupVar_CarTitle } from '../../molecules/editOrCreateModeSingleFields/yupVars'
import Expander from '../../atoms/expander/expander'
import { useAppSelector } from '../../../redux/store/hooks'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import { IEditOrCreateModeSingleFieldsProps } from '../../molecules/editOrCreateModeSingleFields/types'

type Props = {
    onSubmit: (p: IShowroomEntryBasicDataForm) => any
    onCancel: any
    isEditEntry?: boolean
    showroomEntry?: IShowroomEntry
}

const Wrapper = styled.div`
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    /* min-height: 340px; */
    width: 480px;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    position: relative;
    transition: all 200ms ease-in;
`

const Title = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: var(--text-strong, #1a1a1a);
`

const InfoRec = styled.div`
    background-color: var(
        --base-overlays-neutral-alpha-100,
        rgba(26, 26, 26, 0.04)
    );
    border-radius: 8px;
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 5e-5em;
    color: var(--text-default, #666);
`

const CheckboxRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;
`

export const ErrorText = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 4px;
    font-size: 11px;
    color: var(--error, #df6f6f);
    font-style: italic;
`

const CheckboxText = styled.div`
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
    color: var(--text-darker, #616161);
    padding-left: 5px;
    font-size: 14px;
`

export type IShowroomEntryBasicDataForm = {
    car_title?: string
    set_price?: {
        value: number
        currency: string
        open_to_offer?: boolean
    }
    price_range?: {
        valueFrom: number | undefined
        valueTo: number | undefined
        currency: string
    }
    poa?: boolean
    vehicle_bio?: string
}

const ShowroomEntryBasicInfoManagerDesktop = (props: Props) => {
    let { onSubmit, onCancel, isEditEntry, showroomEntry } = props

    const [activeTab, setActiveTab] = useState<
        'set_price' | 'PRICE_ON_ASKING' | 'price_range'
    >('set_price')

    const [formData, setFormData] = useState<IShowroomEntryBasicDataForm>(
        isEditEntry && showroomEntry
            ? {
                  car_title: showroomEntry.car?.title,
                  set_price: {
                      value: showroomEntry.listing_price_min
                          ? showroomEntry.listing_price_min
                          : 0,
                      currency: showroomEntry.listing_currency
                          ? showroomEntry.listing_currency
                          : 'GBP',
                      open_to_offer: showroomEntry.open_to_offers,
                  },
                  price_range: {
                      valueFrom: showroomEntry.listing_price_min
                          ? showroomEntry.listing_price_min
                          : 0,
                      valueTo: showroomEntry.listing_price_max
                          ? showroomEntry.listing_price_max
                          : 0,
                      currency: showroomEntry.listing_currency
                          ? showroomEntry.listing_currency
                          : 'GBP',
                  },
                  poa:
                      showroomEntry.type_of_sale === 'PRICE_ON_ASKING'
                          ? true
                          : false,
                  vehicle_bio: showroomEntry.car?.short_description,
              }
            : {}
    )

    const priceRangeValidation = useAppSelector(
        (state) => state.showroom.filterValidationError
    )

    const [carTitleError, setCarTitleError] = useState<string | undefined>(
        undefined
    )

    const [priceError, setPriceError] = useState<boolean>(false)

    const validateCarTitle = async (value: string) => {
        try {
            await yupVar_CarTitle().validate(value)
            setCarTitleError(undefined)
        } catch (err: any) {
            setCarTitleError(err.errors[0])
        }
    }

    let tabs: ITabProps[] = [
        {
            id: 0,
            title: 'Set price',
            onClick: (arg: number) => {
                setActiveTab('set_price')
            },
            isActive: activeTab === 'set_price' ? true : false,
        },
        {
            id: 1,
            title: 'Price range',
            onClick: (arg: number) => {
                setActiveTab('price_range')
            },
            isActive: activeTab === 'price_range' ? true : false,
        },
        {
            id: 3,
            title: 'POA',
            onClick: (arg: number) => {
                setActiveTab('PRICE_ON_ASKING')
            },
            isActive: activeTab === 'PRICE_ON_ASKING' ? true : false,
        },
    ]

    const checkIfDisabled = () => {
        if (carTitleError) return true
        if (priceRangeValidation?.price) return true
        if (
            activeTab === 'set_price' &&
            (!formData.set_price || !formData.set_price?.value || priceError)
        ) {
            return true
        }

        if (
            activeTab === 'price_range' &&
            (!formData.price_range ||
                !formData.price_range?.valueFrom ||
                !formData.price_range?.valueTo)
        ) {
            return true
        }
    }

    let renderBody = () => {
        switch (activeTab) {
            case 'set_price':
                let data: IEditOrCreateModeSingleFieldsProps = {
                    id: 'price',
                    value_start: formData.set_price?.value,
                    title: 'price',

                    onChangeFunc: (id: string, val: number | string) => {
                        if (id === 'price') {
                            setFormData(
                                (prevState: IShowroomEntryBasicDataForm) => {
                                    return {
                                        ...prevState,
                                        set_price: {
                                            ...prevState.set_price,
                                            value: +val,
                                            currency:
                                                formData &&
                                                formData.set_price &&
                                                formData.set_price.currency
                                                    ? formData.set_price
                                                          .currency
                                                    : 'GBP',
                                        },
                                    }
                                }
                            )
                        }
                        if (id === 'currency') {
                            setFormData(
                                (prevState: IShowroomEntryBasicDataForm) => {
                                    return {
                                        ...prevState,

                                        set_price: {
                                            ...prevState.set_price,
                                            value:
                                                formData &&
                                                formData.set_price &&
                                                formData.set_price.value
                                                    ? formData.set_price.value
                                                    : 0,
                                            currency: `${val}`,
                                        },
                                    }
                                }
                            )
                        }
                    },

                    optionName:
                        formData &&
                        formData.set_price &&
                        formData.set_price.currency
                            ? formData.set_price.currency
                            : 'GBP',
                }

                return (
                    <>
                        <NumberEditOrCreateModeSingleFieldDesktop
                            data={data}
                            key={'key'}
                            dataCyId={'set_price_desktp'}
                            optionsIDArr={currency_IDS}
                            optionsData={currency_data}
                            optionsFieldID="currency"
                            hasError={(v: boolean) => setPriceError(v)}
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        />

                        <CheckboxRow>
                            <CustomAnimatedCheckboxField
                                name={'open_to_offers'}
                                checked={
                                    formData.set_price &&
                                    formData.set_price.open_to_offer === true
                                        ? true
                                        : false
                                }
                                onChange={() => {
                                    setFormData((prevState) => {
                                        return {
                                            ...prevState,

                                            set_price: {
                                                currency:
                                                    formData &&
                                                    formData.set_price &&
                                                    formData.set_price.currency
                                                        ? formData.set_price
                                                              .currency
                                                        : 'GBP',

                                                value:
                                                    formData &&
                                                    formData.set_price &&
                                                    formData.set_price.value
                                                        ? formData.set_price
                                                              .value
                                                        : 0,
                                                open_to_offer:
                                                    formData.set_price &&
                                                    formData.set_price
                                                        .open_to_offer === true
                                                        ? false
                                                        : true,
                                            },
                                        }
                                    })
                                }}
                                small={true}
                            />
                            <CheckboxText>Open to offers</CheckboxText>
                        </CheckboxRow>
                    </>
                )

            case 'price_range':
                return (
                    <div style={{ width: '100%' }}>
                        <div style={{ paddingTop: '20px' }} />

                        <Combobox
                            defaultValue={
                                formData &&
                                formData.price_range &&
                                formData.price_range.currency
                                    ? formData.price_range.currency
                                    : 'GBP'
                            }
                            value={formData?.price_range?.currency}
                            onChange={(val: string) =>
                                setFormData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        price_range: {
                                            ...prevState.price_range,
                                            currency: `${val}`,
                                        },
                                    }
                                })
                            }
                            placeholder="currency"
                            options={
                                currency_IDS.map(
                                    (optionID: string, index: number) => {
                                        let option: Option | undefined = {
                                            label: currency_data[
                                                optionID as keyof typeof currency_data
                                            ].name,
                                            value: currency_data[
                                                optionID as keyof typeof currency_data
                                            ].uid,
                                        }

                                        return {
                                            value: option?.label.toUpperCase(),
                                            label: option?.label.toUpperCase(),
                                        }
                                    }
                                ) ?? []
                            }
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        />

                        <div style={{ paddingTop: '20px' }} />

                        <RangeRowFieldsDesktop
                            filterid="price"
                            valueFrom={formData.price_range?.valueFrom}
                            valueTo={formData.price_range?.valueTo}
                            customWidth={'100%'}
                            removeHelperText={true}
                            onChange={(
                                valueFrom: number | null | undefined,
                                valueTo: number | null | undefined
                            ) => {
                                setFormData(
                                    (
                                        prevState: IShowroomEntryBasicDataForm
                                    ) => {
                                        return {
                                            ...prevState,
                                            price_range: {
                                                currency: prevState.price_range
                                                    ?.currency
                                                    ? prevState.price_range
                                                          ?.currency
                                                    : 'GBP',
                                                valueFrom:
                                                    valueFrom ?? undefined,
                                                valueTo: valueTo ?? undefined,
                                            },
                                        }
                                    }
                                )
                            }}
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </div>
                )

            case 'PRICE_ON_ASKING':
                return (
                    <InfoRec>
                        <InfoIcon size="30" />
                        <div>
                            Your car will not appear in price range search
                            results and may not properly sort by price.
                        </div>
                    </InfoRec>
                )

            default:
                return <div />
        }
    }

    useEffect(() => {
        if (formData.poa) {
            setActiveTab('PRICE_ON_ASKING')
        } else if (
            formData.set_price &&
            formData.price_range?.valueFrom !== formData.price_range?.valueTo
        ) {
            setActiveTab('price_range')
        } else if (
            formData.set_price &&
            formData.price_range?.valueFrom === formData.price_range?.valueTo
        ) {
            setActiveTab('set_price')
        }

        return () => setActiveTab('set_price')
    }, [])

    const { theme } = useThemes()

    return (
        <Wrapper
            style={{
                height: isEditEntry
                    ? 'auto'
                    : activeTab === 'set_price'
                    ? '380px'
                    : activeTab === 'price_range'
                    ? '400px'
                    : '350px',
            }}
        >
            <Title>
                {isEditEntry ? 'Car details' : 'Set your car’s price'}
            </Title>

            <div style={{ paddingTop: '24px' }} />
            {isEditEntry && (
                <>
                    <StandardInputField
                        theme={theme}
                        id={'car_title'}
                        name={'car_title'}
                        placeholder={'car title *'}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={formData.car_title}
                        font_family="Lato"
                        paddingLeft="0px"
                        width="100%"
                        fontSize={'16px'}
                        onChange={(e: any) => {
                            validateCarTitle(e.target.value)
                            setFormData((prevState) => {
                                return {
                                    ...prevState,
                                    car_title: e.target.value,
                                }
                            })
                        }}
                        dataCyId={'car_title'}
                        linecolor={colours[theme].text_muted}
                        type={'text'}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <Expander
                        height={carTitleError ? 'auto' : 0}
                        width={'100%'}
                    >
                        <ErrorText>{carTitleError}</ErrorText>
                    </Expander>
                    <div style={{ paddingTop: '40px' }} />
                </>
            )}

            <BtnTabRow
                tabs={tabs}
                customPassiveColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />

            <div style={{ paddingTop: '20px' }} />

            {renderBody()}
            {isEditEntry && (
                <>
                    <div style={{ paddingTop: '40px' }} />
                    <div
                        style={{
                            height: '1px',
                            width: '100%',
                            boxSizing: 'border-box',
                            backgroundColor: colours[theme].border_muted,
                        }}
                    />
                    <div style={{ paddingTop: '40px' }} />
                    <AnimatedTextarea
                        id="car_short_description"
                        name="car_short_description"
                        value={formData.vehicle_bio ? formData.vehicle_bio : ''}
                        handleChange={(e: any) => {
                            setFormData((prevState) => {
                                return {
                                    ...prevState,
                                    vehicle_bio: e.target.value,
                                }
                            })
                        }}
                        fieldTitle={'Vehicle bio *'}
                        placeholder={'Type here... '}
                        keepLabelRaised
                        maxLength={265}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <div style={{ paddingTop: '40px' }} />
                </>
            )}

            <div style={{ paddingTop: '60px' }} />
            <BottomBarBtnsStep
                cancel={onCancel}
                submit={() => {
                    let payload: IShowroomEntryBasicDataForm = {}
                    if (activeTab === 'PRICE_ON_ASKING') {
                        payload = {
                            car_title: formData.car_title,
                            vehicle_bio: formData.vehicle_bio,
                            poa: true,
                        }
                    } else if (activeTab === 'set_price') {
                        payload = {
                            car_title: formData.car_title,
                            vehicle_bio: formData.vehicle_bio,
                            set_price: formData.set_price,
                        }
                    } else if (activeTab === 'price_range') {
                        payload = {
                            car_title: formData.car_title,
                            vehicle_bio: formData.vehicle_bio,
                            price_range: formData.price_range,
                        }
                    }
                    onSubmit(payload)
                }}
                confirmationtext="continue"
                disabled={checkIfDisabled()}
                hasGoBack={false}
                removeLine={true}
                customPadding={'24px 0px'}
            />
        </Wrapper>
    )
}

export default ShowroomEntryBasicInfoManagerDesktop
