import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import CustomIconTagQuickFilterItem from '../../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import FadedSlower from '../../../templates/animated/FadedSlower'
import FilterButton from '../../../atoms/Button/filterButton'
import Expander from '../../../atoms/expander/expander'
import FullWidthLightGreyBgButton from '../../../atoms/Button/fullWidthLightGreyBg'
import AddLabelButton from '../../../atoms/Button/addLabelButton'
import { IDropdownItem } from 'entityModels'
import { ILabel } from '../../../../redux/timeline/types'

export type Props = {
    items?: any
    searchFor?: any
    addTagFilter?: any
    addCategoryFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    user_searchable_labels_list?: IDropdownItem[] | null | undefined
    value?: any
    handleChange?: any
    categoryData?: any
    fetchItem?: any
    labelsDataRes?: any
    addLabelButton?: React.ReactNode
    onSave?: any
    hasCreateNewLabel?: boolean
    clearField?: any
    entry_labels_list: ILabel[]
    activeValues?: any
}

type IStyle = {
    selected?: boolean
    issearchactive?: boolean
    top?: number
    fixedwidth?: boolean
}

const SearchFieldWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 10px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
`

const SearchField = styled.input<IStyle>`
    border: ${(props) =>
        props.issearchactive
            ? '1px solid var(--primary, #5ec3ca)'
            : '1px solid var(--text-muted)'};
    border-radius: 5px;
    font-family: Lato;
    font-size: 16px;
    color: var(--text-darker, #616161);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    height: 48px;
    background-color: var(--bg-color, #fff);
    padding: 12px 16px;

    ::placeholder {
        color: var(--text-muted);
    }
    :active {
        border: 1px solid var(--primary, #5ec3ca);
    }
`

const DropdownOptions = styled.ul`
    position: absolute;
    z-index: 2;
    margin-top: 10px;
    width: 280px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--bg-color, #fff);
    border-radius: 10px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 15px;
    box-shadow: 0px 4px 24px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
    padding-bottom: 40px;
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: transparent;
    padding-left: 20px;
    cursor: pointer;
    :hover {
        background-color: var(--off-bg-color, #fafafa);
    }
`

const Wrapper = styled.div<IStyle>`
    width: ${(props) => (props.fixedwidth ? 'auto' : '280px')};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
`

const Count = styled.div`
    padding-left: 20px;
    padding-top: 12px;
    font-style: italic;
    color: var(--text-muted);
    font-family: 'Lato-Light';
    font-size: 12px;
`

const SelectedCriteriaWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 0px;
`

const LabelsFilterByMultipleWSearchUIDesktop: React.FC<Props> = ({
    dataCyId,
    categoryData,
    labelsDataRes,
    handleChange,
    fetchItem,
    value,
    addLabelButton,
    user_searchable_labels_list,
    onSave,
    hasCreateNewLabel,
    clearField,
    entry_labels_list,
}) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [addedCriteria, setAddedCriteria] = useState(
        entry_labels_list
            ? entry_labels_list.map((label: ILabel) => label.name)
            : []
    )
    const [issearchactive, setIssearchactive] = useState(false)
    const [showAllItems, setShowAllItems] = useState(false)

    const dropdownRef = useRef<HTMLDivElement>(null)
    const searchFieldRef = useRef<HTMLInputElement>(null)

    const handleClickOutsideDropdown = (e: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(e.target as Node)
        ) {
            setShowDropdown(false)
        }
    }

    const handleClickOutsideSearch = (e: MouseEvent) => {
        if (
            searchFieldRef.current &&
            !searchFieldRef.current.contains(e.target as Node)
        ) {
            setIssearchactive(false)
        }
    }

    useEffect(() => {
        dropdownRef.current?.focus()
        document.addEventListener('mousedown', handleClickOutsideDropdown)
        document.addEventListener('mousedown', handleClickOutsideSearch)

        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideDropdown
            )
            document.removeEventListener('mousedown', handleClickOutsideSearch)
        }
    }, [])

    useEffect(() => {
        if (entry_labels_list) {
            setAddedCriteria(
                entry_labels_list.map((label: ILabel) => label.name)
            )
        }
    }, [entry_labels_list])

    const handleFilterButtonClick = () => {
        setShowDropdown(!showDropdown)
    }

    const addOrRemoveCriteria = (criteria: string) => {
        setAddedCriteria((prev) => {
            const index = prev.indexOf(criteria)
            if (index < 0) {
                return [...prev, criteria]
            }
            return prev.filter((_, i) => i !== index)
        })
    }

    let allDropdownLabelNames = user_searchable_labels_list?.map(
        (label: IDropdownItem) => label.name
    )
    let dropdownLabelNamesResults = labelsDataRes?.map(
        (label: any) => label.item.name
    )

    const addedCriteriaElements = addedCriteria.map(
        (criteria: string, i: number) => (
            <div style={{ padding: '3px' }} key={`${criteria} - ${i}`}>
                <FadedSlower duration={0.2}>
                    <CustomIconTagQuickFilterItem
                        isActive={true}
                        onCrossClick={() => addOrRemoveCriteria(criteria)}
                        border="1px solid var(--border-muted, #e5e5e5)"
                        height="25px"
                        bgcolor="var(--bg-color, #fff)"
                        isLabel
                    >
                        {criteria}
                    </CustomIconTagQuickFilterItem>
                </FadedSlower>
            </div>
        )
    )

    const mappedCategoryData = categoryData?.map((item: any, index: number) => {
        let category = item.item
        if (category) {
            return (
                <StyledOption
                    selected={addedCriteria.includes(category.name)}
                    value={category.name}
                    key={`${category.name}-category-${index}`}
                    aria-label={category.name}
                >
                    <ContentRow>
                        <CustomAnimatedCheckboxField
                            width="100%"
                            onClick={() => addOrRemoveCriteria(category.name)}
                            checked={addedCriteria.includes(category.name)}
                        >
                            {category.name}
                        </CustomAnimatedCheckboxField>
                    </ContentRow>
                </StyledOption>
            )
        }
        return <StyledOption key={index} style={{ display: 'none' }} />
    })

    const mappedTagsData = labelsDataRes?.map((item: any, index: number) => {
        let label = item.item
        if (label && index < 3) {
            return (
                <StyledOption
                    selected={addedCriteria.includes(label.name)}
                    value={label.name}
                    key={`${label.name}-tag-${index}`}
                >
                    <ContentRow>
                        <CustomAnimatedCheckboxField
                            width="100%"
                            onClick={() => addOrRemoveCriteria(label.name)}
                            checked={addedCriteria.includes(label.name)}
                        >
                            {label.name}
                        </CustomAnimatedCheckboxField>
                    </ContentRow>
                </StyledOption>
            )
        }
        return <StyledOption key={index} style={{ display: 'none' }} />
    })

    const checkIfShowAddLabel = () => {
        if (
            !allDropdownLabelNames ||
            (!allDropdownLabelNames.includes(value[0]) &&
                !dropdownLabelNamesResults.includes(value[0]))
        ) {
            return (
                <FadedSlower>
                    <FullWidthLightGreyBgButton
                        text={`+ Create new label: ${value[0]}`}
                        onClick={() => {
                            addOrRemoveCriteria(value[0])
                            clearField && clearField()
                        }}
                        nobg
                    />
                </FadedSlower>
            )
        }
    }

    const show_all_options = () => {
        return (
            <>
                {user_searchable_labels_list?.map(
                    (label: any, index: number) => {
                        if (!showAllItems && index >= 6) {
                            return (
                                <div style={{ display: 'none' }} key={index} />
                            )
                        }
                        return (
                            <StyledOption
                                selected={addedCriteria.includes(label.name)}
                                value={label.name}
                                key={`${label.name}-label-${index}`}
                            >
                                <ContentRow>
                                    <CustomAnimatedCheckboxField
                                        width="100%"
                                        onClick={() =>
                                            addOrRemoveCriteria(label.name)
                                        }
                                        checked={addedCriteria.includes(
                                            label.name
                                        )}
                                    >
                                        {label.name}
                                    </CustomAnimatedCheckboxField>
                                </ContentRow>
                            </StyledOption>
                        )
                    }
                )}

                {user_searchable_labels_list &&
                    user_searchable_labels_list.length > 6 &&
                    !showAllItems && (
                        <Count
                            onClick={() => setShowAllItems(true)}
                            style={{ cursor: 'pointer' }}
                        >
                            +{user_searchable_labels_list.length - 6} more
                        </Count>
                    )}
            </>
        )
    }

    const are2arraysEqualv2 = (array1: any[], array2: any[]) => {
        if (array1.length === array2.length) {
            return array1.every((element, index) => {
                if (element === array2[index].name) {
                    return true
                }
                return false
            })
        }
        return false
    }

    return (
        <Wrapper
            data-attr={dataCyId}
            ref={dropdownRef}
            fixedwidth={addLabelButton ? true : false}
        >
            {addLabelButton ? (
                <AddLabelButton
                    onClick={handleFilterButtonClick}
                    isactive={showDropdown}
                />
            ) : (
                <FilterButton
                    text={
                        addedCriteria.length > 0
                            ? `Labels (${addedCriteria.length})`
                            : 'Labels (0)'
                    }
                    onClick={handleFilterButtonClick}
                    isactive={showDropdown}
                />
            )}

            <SlideDownWrapper
                isOpen={showDropdown}
                initialX={-280}
                originX={'280px'}
            >
                <DropdownOptions>
                    <Expander height={addedCriteria.length > 0 ? 'auto' : 0}>
                        <SelectedCriteriaWrapper>
                            {addedCriteriaElements}
                        </SelectedCriteriaWrapper>
                    </Expander>

                    <SearchFieldWrapper onClick={() => setIssearchactive(true)}>
                        <SearchField
                            placeholder={'Search labels'}
                            type="search"
                            onChange={(e: any) => {
                                handleChange(e.target.value)
                                fetchItem(e.target.value)
                            }}
                            issearchactive={issearchactive}
                            value={value[0]}
                            ref={searchFieldRef}
                        />
                    </SearchFieldWrapper>

                    <FadedSlower>
                        {labelsDataRes.length === 0 && show_all_options()}
                    </FadedSlower>

                    {categoryData &&
                        categoryData.length > 0 &&
                        mappedCategoryData}
                    {labelsDataRes &&
                        labelsDataRes.length > 0 &&
                        mappedTagsData}
                    <div
                        style={{
                            padding: '20px',
                            paddingTop: '10px',
                            paddingBottom: '0px',
                        }}
                    >
                        {hasCreateNewLabel &&
                            value &&
                            value[0] !== '' &&
                            value[0] !== undefined &&
                            value[0].trim().length > 1 &&
                            checkIfShowAddLabel()}

                        {are2arraysEqualv2(addedCriteria, entry_labels_list) !==
                            true && (
                            <FadedSlower>
                                <FullWidthLightGreyBgButton
                                    text="Save"
                                    onClick={() => {
                                        let submittedLabels: ILabel[] =
                                            addedCriteria.map((criteria) => ({
                                                uid: criteria,
                                                name: criteria,
                                            }))
                                        onSave(submittedLabels)
                                        setShowDropdown(false)
                                    }}
                                />
                            </FadedSlower>
                        )}
                    </div>
                </DropdownOptions>
            </SlideDownWrapper>
        </Wrapper>
    )
}

export default LabelsFilterByMultipleWSearchUIDesktop
