import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { Link, useLocation } from 'react-router-dom'
import * as NavigationIcons from '../../icons/components'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import { isAnyFormOpen } from '../../../../helpers/booleanChecks/isAnyFormOpen'
import DrawerMenuMobile from './drawerMenuMobile'
import { checkIfPwaStandalone } from '../../../templates/pwaCustom/checkIpadPwa'
import { HamburgerIcon } from '../../statefulicons'
import InsuranceIcon from '../../icons/components/insuranceIcon'
import { IGetAllUserInsuranceApplicationsProps } from '../../../../redux/insuranceQuoteApplication/sagas/application/getAll'
import { Spin } from 'antd'
import Faded from '../../../templates/animated/faded'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { IUser } from '../../../../redux/user/types'
import useThemes from '../../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
    $isActive?: boolean
}

const MenuWrapper = styled.div`
    @media ${device.desktop} {
        display: none;
    }
`

const MenuContainer = styled.section<IStyle>`
    position: fixed;
    display: grid;
    grid-auto-flow: column;
    width: 100vw;
    height: 74px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: ${(props) => colours[props.$theme].background_default};
    border-top: ${(props) =>
        props.$theme === 'light'
            ? 'none'
            : `1px solid ${colours[props.$theme].border_muted}`};
    -webkit-box-shadow: 0px -2px 9px -2px
        ${(props) => colours[props.$theme].shadow_strongest_09};
    -moz-box-shadow: 0px -2px 9px -2px
        ${(props) => colours[props.$theme].shadow_strongest_09};
    box-shadow: 0px -2px 9px -2px
        ${(props) => colours[props.$theme].shadow_strongest_09};
    z-index: 5;
`

const MenuItem = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    padding-top: 6px;
    font-family: Lato;
    font-size: 0.6rem;
    color: ${(props) => colours[props.$theme].text_darker};
`

const NavContainerMobile = styled.button<IStyle>`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: ${(props) =>
        props.$isActive
            ? colours[props.$theme].base_overlays_neutral_alpha_100
            : 'transparent'};

    :focus {
        outline: 0;
    }
`

const NewsFlag = styled.div<IStyle>`
    position: absolute;
    top: -5px;
    right: -30px;
    box-shadow: 2px 4px 7px
        ${(props) => colours[props.$theme].shadow_strongest_03};
    text-transform: uppercase;
    background-color: ${(props) => colours[props.$theme].error};
    height: 11px;
    width: 20px;
    border-radius: 2px;
    color: ${(props) => colours[props.$theme].background_default};
    font-family: 'Lato-bold';
    font-size: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    line-height: 11px;
`

const InsuranceItemWrapper = styled.div<IStyle>`
    .ant-spin-dot-item {
        background-color: ${(props) => colours[props.$theme].text_default};
    }
`

interface IProps {
    activepage: string
    user: IUser | null
    get_user_insurance_applications_request: (
        p: IGetAllUserInsuranceApplicationsProps
    ) => any
}

const regexCarGalleryImage = /^(?=.*\bcar\b)(?=.*\bgallery\b)(?=.*\bimg\b).*$/

const MainMenuMobile: React.FC<IProps> = ({
    activepage,
    user,
    get_user_insurance_applications_request,
}) => {
    const [isLeftSideMenuOpen, setIsLeftSideMenuOpen] = useState(false)
    const dispatch = useAppDispatch()

    let { theme } = useThemes()

    const {
        formsData,
        formIDs,

        isDefaultNavigationRemoved,
        featureFlags,
        isInsuranceLoading,
    } = useAppSelector((state) => ({
        formsData: state.editForms.formsData,
        formIDs: state.editForms.formIDs,
        isMobileAppSubMenuOpen: state.menus.mobileAppSubMenu.isOpen,
        isActionsMenuOpen: state.menus.isAddActionsOpen.isOpen,
        user_garage_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.owns_garage &&
            state.user.userLoggedIn.owns_garage?.uid,
        currentCarID: state.entities.carsData.currentCarID,
        isAccountPrivateAddressInfoOpened:
            state.editForms.formsData.userAccountAddressInformationForm.isOpen,
        isDefaultNavigationRemoved: state.menus.isDefaultNavigationRemoved,
        featureFlags: state.featureFlags,
        isInsuranceLoading: state.insuranceQuoteApplication.isLoading,
    }))

    useEffect(() => {
        dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
    }, [])

    const location = useLocation()

    const checkIfShowingBottomNav = (pathname: string): boolean => {
        let userid: string | null | undefined = user && user.id ? user.id : null

        if (userid === null || userid === undefined) {
            return false
        }

        if (
            pathname.match(/overview/g) !== null &&
            pathname.match(/car/g) !== null &&
            pathname.match(/archives/g) !== null
        ) {
            return true
        }

        if (location.search.match(/tutorial/g) !== null) {
            return false
        }

        if (
            pathname.match(/history-file/g) !== null &&
            pathname.match(/car/g) !== null
        ) {
            return false
        }

        if (pathname.match(/archive/g) !== null) {
            return false
        }

        if (
            pathname.match(/search/g) !== null &&
            pathname.match(/car/g) !== null
        ) {
            return false
        }

        if (
            pathname.match(/timezone/g) !== null &&
            pathname.match(/preferences/g) !== null
        ) {
            return false
        }

        if (
            pathname.match(/garage/g) !== null &&
            pathname.match(/country/g) !== null
        ) {
            return false
        }

        if (pathname === '/history-files') {
            return true
        }

        if (
            pathname.match(/technical-information/g) !== null &&
            pathname.match(/car/g) !== null
        ) {
            return false
        }

        if (
            pathname.match(/gallery/g) !== null &&
            pathname.match(/car/g) !== null
        ) {
            return false
        }

        if (
            pathname.match(/tasks/g) !== null &&
            pathname.match(/car/g) !== null
        ) {
            return false
        }

        if (
            pathname.match(/sharing/g) !== null &&
            pathname.match(/car/g) !== null
        ) {
            return false
        }

        if (pathname.match(/shared/g) !== null) {
            return false
        }

        if (pathname.match(/confirm/g) !== null) {
            return false
        }
        if (pathname.match(regexCarGalleryImage) !== null) {
            return false
        }

        if (pathname.match(/edit-share/g) !== null) {
            return false
        }

        if (pathname.match(/journey/g) !== null) {
            return false
        }

        if (
            pathname.match(/garage/g) !== null ||
            pathname === '/apex' ||
            (pathname === '/' && userid !== undefined && userid !== null) ||
            pathname.match(/upcoming/g) !== null ||
            pathname === '/apex/articles' ||
            pathname === '/apex/cars-of-the-week' ||
            pathname === '/apex/interviews' ||
            pathname === '/apex/podcasts' ||
            (pathname.match(/car/g) !== null &&
                pathname.match(/deletion/g) === null) ||
            pathname === '/tasks' ||
            pathname.match(/account/g) !== null ||
            pathname === '/apply' ||
            pathname === '/showroom' ||
            pathname === '/showroom/for-sale' ||
            pathname === '/showroom/sold' ||
            pathname === '/showroom/watch-list' ||
            pathname === '/sharing/with-others' ||
            pathname === '/sharing/with-you' ||
            pathname === '/insurance/overview'
        ) {
            return true
        } else return false
    }

    let isAnyFormOpened = isAnyFormOpen(formIDs, formsData)

    const params = new URLSearchParams(window.location.search)

    let image_id_param = params.get('id')

    let current_pathname = location.pathname

    // checkIfPwaStandalone()
    //     ? window.location.pathname
    //     : window.location.pathname

    let enable_showroom =
        user?.role === 'Admin' || user?.role === 'Dealer'
            ? true
            : featureFlags.enable_showroom

    let isPwa = checkIfPwaStandalone()

    let enable_insurance = featureFlags['enable_insurance']

    let showInsuranceLoading =
        isInsuranceLoading && current_pathname.match(/insurance/g) === null
            ? true
            : false

    return (
        <>
            <MenuWrapper>
                {image_id_param === null &&
                location.search.match(/onboarding/g) === null &&
                isAnyFormOpened !== true &&
                !isDefaultNavigationRemoved ? (
                    <React.Fragment>
                        {checkIfShowingBottomNav(current_pathname) === true && (
                            <MenuContainer
                                $theme={theme}
                                style={{
                                    height: isPwa ? '84px' : '80px',
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `/garage`,
                                        // state: {
                                        //     prevPath:
                                        //         location.pathname,
                                        // },
                                    }}
                                >
                                    <NavContainerMobile
                                        $theme={theme}
                                        $isActive={
                                            (location.pathname.match(
                                                /garage/g
                                            ) !== null ||
                                                location.pathname === '/') &&
                                            !isLeftSideMenuOpen
                                                ? true
                                                : false
                                        }
                                        style={{
                                            paddingBottom: 8,
                                        }}
                                    >
                                        <NavigationIcons.GarageIcon
                                            color={colours[theme].text_darker}
                                        />

                                        <MenuItem $theme={theme}>
                                            Garage
                                        </MenuItem>
                                    </NavContainerMobile>
                                </Link>

                                {enable_showroom === true ? (
                                    <a href="https://app.custodian.club/showroom/for-sale">
                                        <NavContainerMobile
                                            $theme={theme}
                                            style={{
                                                paddingBottom: 8,
                                            }}
                                            $isActive={
                                                location.pathname
                                                    .split('/')
                                                    .includes('showroom') ===
                                                true
                                                    ? true
                                                    : false
                                            }
                                            onClick={() =>
                                                setIsLeftSideMenuOpen(false)
                                            }
                                        >
                                            <NavigationIcons.ShowroomIcon
                                                color={
                                                    colours[theme].text_darker
                                                }
                                            />

                                            <MenuItem $theme={theme}>
                                                Showroom
                                            </MenuItem>
                                        </NavContainerMobile>
                                    </a>
                                ) : null}

                                <Link
                                    to={{
                                        pathname: `/history-files`,
                                        // state: {
                                        //     prevPath:
                                        //         location.pathname,
                                        // },
                                    }}
                                >
                                    <NavContainerMobile
                                        $theme={theme}
                                        style={{
                                            paddingBottom: 8,
                                        }}
                                        $isActive={
                                            location.pathname ===
                                                '/history-files' &&
                                            !isLeftSideMenuOpen
                                                ? true
                                                : false
                                        }
                                        onClick={() =>
                                            setIsLeftSideMenuOpen(false)
                                        }
                                    >
                                        <NavigationIcons.HistoryFilesIcon
                                            color={colours[theme].text_darker}
                                        />

                                        <MenuItem $theme={theme}>
                                            History Files
                                        </MenuItem>
                                    </NavContainerMobile>
                                </Link>

                                <Link to="/tasks">
                                    <NavContainerMobile
                                        $theme={theme}
                                        style={{
                                            paddingBottom: 8,
                                        }}
                                        $isActive={
                                            location.pathname === '/tasks'
                                                ? true
                                                : false
                                        }
                                        onClick={() =>
                                            setIsLeftSideMenuOpen(false)
                                        }
                                    >
                                        <NavigationIcons.TasksIcon
                                            color={colours[theme].text_darker}
                                        />

                                        <MenuItem $theme={theme}>
                                            Tasks
                                        </MenuItem>
                                    </NavContainerMobile>
                                </Link>

                                {enable_insurance === true ? (
                                    <InsuranceItemWrapper
                                        $theme={theme}
                                        onClick={() => {
                                            get_user_insurance_applications_request(
                                                {
                                                    withNavigation: true,
                                                    withAllInfo: true,
                                                }
                                            )
                                        }}
                                    >
                                        <NavContainerMobile
                                            $theme={theme}
                                            style={{
                                                paddingBottom: 8,
                                            }}
                                            $isActive={
                                                location.pathname ===
                                                '/insurance/application'
                                                    ? true
                                                    : false
                                            }
                                            onClick={() =>
                                                setIsLeftSideMenuOpen(false)
                                            }
                                        >
                                            <div
                                                style={{
                                                    position: 'relative',
                                                }}
                                            >
                                                <NewsFlag $theme={theme}>
                                                    New
                                                </NewsFlag>
                                            </div>

                                            {showInsuranceLoading ? (
                                                <Faded>
                                                    <div
                                                        style={{
                                                            paddingBottom: 4,
                                                        }}
                                                    >
                                                        <Spin
                                                            style={{
                                                                height: '20px',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </div>
                                                </Faded>
                                            ) : (
                                                <InsuranceIcon
                                                    color={
                                                        colours[theme]
                                                            .text_darker
                                                    }
                                                />
                                            )}

                                            <MenuItem $theme={theme}>
                                                Insurance
                                            </MenuItem>
                                        </NavContainerMobile>
                                    </InsuranceItemWrapper>
                                ) : null}

                                <NavContainerMobile
                                    $theme={theme}
                                    style={{ paddingBottom: 8 }}
                                    $isActive={false}
                                    onClick={() =>
                                        setIsLeftSideMenuOpen(
                                            !isLeftSideMenuOpen
                                        )
                                    }
                                >
                                    <HamburgerIcon
                                        isOpen={isLeftSideMenuOpen}
                                        toggle={() => {}}
                                        color={colours[theme].text_darker}
                                    />

                                    <MenuItem $theme={theme}>Menu</MenuItem>
                                </NavContainerMobile>
                            </MenuContainer>
                        )}
                    </React.Fragment>
                ) : null}
            </MenuWrapper>
            <DrawerMenuMobile
                userLoggedIn={user}
                isOpen={isLeftSideMenuOpen}
                toggle={() => setIsLeftSideMenuOpen(false)}
                currentPath={current_pathname}
                featureFlags={featureFlags}
            />
        </>
    )
}

export default MainMenuMobile
