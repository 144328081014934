import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import { motion } from 'framer-motion'
import { message } from 'antd'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import './antd.css'
import { useEffect, useState } from 'react'
import GooglePlacesLocationsDropdown from '../../../molecules/googlePlacesLocationsDropdown/googlePlacesLocationsDropdownDesktop'
import { ModalTopBar } from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { Upload } from 'antd'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import AddIcon from '../../../atoms/icons/components/addIcon'
import axios from 'axios'
import { customHeader } from '../../../../redux/services/headers'
import {
    apiSentryErrorHandler,
    apiSentryS3ErrorHandler,
} from '../../../../redux/services/errorHandler'
import { IUploadImagePreSignedUrlPayloadSingle } from '../../../../redux/filestorage/types'

type IStyledWarning = {
    hasError?: boolean
}

type IStyle = {
    $theme: ITheme
}

const EditFormContainerMobile = styled.section<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 90vw;
    margin-top: 30px;
    margin-bottom: 30px;
    /* padding-top: 20px; */
    height: auto;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};
    color: ${(props) => colours[props.$theme].text_strong};
    border-radius: 5px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 5px;

    @media screen and (max-width: 325px) {
        max-height: 90vh;
    }

    max-height: 95vh;

    @media screen and (min-width: 330px) {
        min-height: 358px;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 15px;
    padding-bottom: 10px;
`

const SmallPadding = styled.div`
    padding-top: 10px;

    @media screen and (max-width: 330px) {
        padding-top: 5px;
    }
`

const SmallPaddingBetweenFields = styled.div`
    padding-top: 5px;
`

const BiggerPadding = styled.div`
    padding-top: 32px;
    @media screen and (max-width: 330px) {
        padding-top: 15px;
    }
`
const LargePadding = styled.div`
    padding-top: 40px;

    @media screen and (max-width: 330px) {
        padding-top: 30px;
    }
`

const TextAreaWidth = styled.div`
    width: 80vw;
    min-height: 70px;
    margin-top: 10px;
`

const ProgressBarImagesMobileChild = styled.div<ProgressProps>`
    display: flex;
    position: relative;
    height: 100%;
    width: ${(props) => `${props.progressPercent}%`};
    background-color: ${(props) => colours[props.$theme].primary};
    border-radius: 5px;
`

const CaptionText = styled.textarea<IStyle>`
    font-family: Lato;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    color: ${(props) => colours[props.$theme].text_strong};
    min-height: 70px;
    font-size: 14px;
    border: none;
    padding-top: 20px;
    border-radius: 5px;
    border: ${(props) => colours[props.$theme].text_muted} 1px solid;
    font-size: 0.9em;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};
    :focus {
        outline: 0;
    }
`
export const PhotoPercentWrapper = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-bottom: 20px;
    padding-bottom: 20px;
    font-size: 15px;

    font-family: Lato-light;
    padding-top: 5px;
    padding-bottom: 10px;
    color: ${(props) => colours[props.$theme].text_default};
`

const ProgressBarImagesMobileParentContainer = styled.div<IStyle>`
    border: 1px ${(props) => colours[props.$theme].primary} solid;
    display: flex;
    position: relative;
    height: 6px;
    width: 78vw;
    border-radius: 5px;
`

export const Error = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].error};
    font-style: italic;
    font-size: 11px;
`

export const CharacterCountSpan = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: lato-light;
    font-size: 11px;
    padding-left: 10px;
`

const ImageInstructions = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 13px;
    padding-top: 10px;
    font-family: Lato-Light;
    @media screen and (min-width: 330px) {
        font-size: 14px;
    }
`

const BtnWrap = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding-top: 16px;
    padding-bottom: 32px;
    padding-right: 20px;
`

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: 'row';
    margin-top: 10px;
`

const Line = styled.div<IStyle>`
    background-color: ${(props) => colours[props.$theme].text_default};
    width: 1px;
    height: 40px;
    margin-right: 10px;

    @media screen and (max-width: 330px) {
        margin-right: 15px;
        height: 40px;
    }
`

const TipText = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 14px;
    font-family: 'Lato';
    max-width: 70vw;

    @media screen and (max-width: 330px) {
        max-width: '100%';
        font-size: 12px;
    }

    @media screen and (min-width: 400px) {
        max-width: 300px;
    }
`

const ImageInstructionsWarning = styled.div<IStyledWarning>`
    color: ${(props) =>
        props.hasError
            ? 'var(--error, #df6f6f)'
            : 'var(--text-strong, #1a1a1a)'};
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 5px;
    font-family: Lato-Light;

    @media screen and (min-width: 330px) {
        font-size: 14px;
        padding-bottom: 24px;
    }
`

interface Values {
    caption: string
    location: string
    credits: string
    morethanmax?: boolean
    uploadedFiles: File[] | undefined
}

interface OtherProps {
    close: any
    getPresignedUrlApi: (fileName: string) => string
    storePresignedUrl: (p: IUploadImagePreSignedUrlPayloadSingle) => void
    submitFiles: (values: Values) => void
}

type ProgressProps = {
    progressPercent: number
    $theme: ITheme
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        getPresignedUrlApi,
        storePresignedUrl,
        values,
        close,
        setFieldValue,
        handleChange,
        errors,
    } = props

    const [showLocationSuggestions, toggleShowLocationSuggestion] =
        useState(false)

    const handleSuggestionSelection = (suggestion: any) => {
        setFieldValue(
            'location',
            suggestion.structured_formatting
                ? suggestion.structured_formatting.main_text
                : suggestion.description
        )
    }

    const errorFileSizeWarning = () => {
        message.error({
            content: 'The maximum file size allowed per upload is 20MB.',
            duration: 3,
        })
    }

    const { theme } = useThemes()

    const [uploadedFilesCount, setUploadedFilesCount] = useState(0)
    const [fileProgressState, setFileProgressState] = useState<null | number>(
        null
    )
    const [filesToBeUploaded, setFilesToBeUploaded] = useState<File[]>([])
    const [filesCurrentlyUploaded, setFilesCurrentlyUploaded] = useState<
        any[] | undefined
    >(undefined)
    const [isLastFileUploaded, setIsLastFileUploaded] = useState(false)

    // console.log('filesCount', filesCount)
    // console.log('filesToBeUploaded', filesToBeUploaded)
    // console.log('filesCurrentlyUploaded', filesCurrentlyUploaded)
    // console.log('isLastFileUploaded ======= ???????', isLastFileUploaded)

    useEffect(() => {
        if (isLastFileUploaded) {
            if (filesCurrentlyUploaded && filesCurrentlyUploaded?.length > 20) {
                setFieldValue('morethanmax', true)
            } else {
                setFieldValue('uploadedFiles', filesCurrentlyUploaded)
            }
        }
    }, [isLastFileUploaded])

    const getPresignedUrlsAndUploadTos3 = ({
        file,
        onSuccess,
        onProgress,
    }: any) => {
        //@ts-ignore
        const fileType = file.type
        //@ts-ignore
        const fileName = file.name
        // console.log('Preparing the upload')
        // console.log('fileType', fileType)

        // get presigned url
        axios
            .get(`${getPresignedUrlApi(fileName)}`, {
                headers: { ...customHeader },
                withCredentials: true,
            })
            .then()
            .then((response) => {
                var returnData = response?.data?.data

                let presignedUrl = returnData ? returnData[0] : undefined

                storePresignedUrl(presignedUrl)

                // after presigned url is there upload to S3

                axios
                    .request({
                        method: 'put',
                        url: presignedUrl.url,
                        data: file,
                        onUploadProgress: (progressEvent: any) => {
                            let percentCompleted = Math.round(
                                progressEvent.progress * 100
                            )

                            setFileProgressState(percentCompleted)

                            onProgress &&
                                onProgress(
                                    {
                                        percent: percentCompleted,
                                    },
                                    file
                                )

                            // console.log(
                            //     'current file propgress',
                            //     percentCompleted
                            // )

                            if (percentCompleted === 100) {
                                setUploadedFilesCount((prevCount) => {
                                    if (
                                        prevCount + 1 ===
                                        filesToBeUploaded.length
                                    ) {
                                        setIsLastFileUploaded(true)
                                    }
                                    return prevCount + 1
                                })

                                // console.log(
                                //     'current file upload is complete'
                                // )

                                setFilesCurrentlyUploaded((prevValues) => {
                                    return prevValues
                                        ? [...prevValues, file]
                                        : [file]
                                })
                            }
                        },
                    })
                    .catch((e) => {
                        apiSentryS3ErrorHandler(
                            JSON.stringify(e),
                            'S3 file error'
                        )
                    })
            })
            .then((result) => {
                // console.log('Response from s3 - starting upload')
                onSuccess && onSuccess(result, file)
            })
            .catch((error) => {
                apiSentryErrorHandler(
                    JSON.stringify(error),
                    'presigned url error'
                )
            })
    }

    return (
        <EditFormContainerMobile id="scrollable-content" $theme={theme}>
            <ModalTopBar $theme={theme}>Add images to your gallery</ModalTopBar>
            <InnerFormPadding>
                {!fileProgressState && (
                    <>
                        <div style={{ paddingTop: '24px' }} />

                        <TipText $theme={theme}>
                            Once uploaded, you can edit images and also select
                            your{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                car profile image.
                            </span>
                        </TipText>

                        <ImageInstructions $theme={theme}>
                            Image formats accepted: png, jpg, jpeg, HEIC and
                            HEIF.
                            <br />
                            Maximum file size: 20MB.
                        </ImageInstructions>
                        <ImageInstructionsWarning
                            hasError={values.morethanmax ? true : false}
                        >
                            Please upload up to 20 files.
                        </ImageInstructionsWarning>

                        <div style={{ paddingTop: '30px' }} />

                        <motion.div whileTap={{ scale: 1.05 }}>
                            <Upload
                                multiple={true}
                                accept=".jpg, .jpeg, .png, .heif, .heic"
                                beforeUpload={(file, fileList) => {
                                    // console.log('file in before upload', file)
                                    setFilesToBeUploaded(fileList)
                                    if (file.size > 20000000) {
                                        errorFileSizeWarning()
                                    }
                                }}
                                showUploadList={false}
                                customRequest={getPresignedUrlsAndUploadTos3}
                                maxCount={10}
                            >
                                <StandardCtaBtn
                                    bg="transparent"
                                    boder="1px solid var(--primary)"
                                    customHeight="80px"
                                    customWidth="80px"
                                >
                                    <AddIcon
                                        colour="var(--primary)"
                                        size="28"
                                    />
                                </StandardCtaBtn>
                            </Upload>
                        </motion.div>
                    </>
                )}

                {fileProgressState && (
                    <PhotoPercentWrapper $theme={theme}>
                        <SmallPadding />
                        <SmallPadding />
                        {isLastFileUploaded && filesCurrentlyUploaded ? (
                            <div
                                style={{
                                    paddingBottom: '5px',
                                }}
                            >
                                <span
                                    style={{
                                        paddingRight: '3px',
                                    }}
                                >
                                    {filesCurrentlyUploaded.length}
                                </span>
                                file
                                {filesCurrentlyUploaded.length > 1
                                    ? `s`
                                    : ''}{' '}
                                successfully uploaded (
                                {Math.floor(fileProgressState)}% )
                            </div>
                        ) : (
                            <div
                                style={{
                                    paddingBottom: '5px',
                                }}
                            >
                                uploading {uploadedFilesCount + 1} /{' '}
                                {filesToBeUploaded.length} file
                                {filesToBeUploaded.length > 1 ? `s` : ''}{' '}
                                {Math.floor(fileProgressState)}%...
                            </div>
                        )}

                        <ProgressBarImagesMobileParentContainer $theme={theme}>
                            <ProgressBarImagesMobileChild
                                $theme={theme}
                                progressPercent={fileProgressState}
                            />
                        </ProgressBarImagesMobileParentContainer>
                    </PhotoPercentWrapper>
                )}

                <Expander height={fileProgressState ? 'auto' : 0}>
                    <SmallPadding />
                    <FieldTitle style={{ paddingBottom: 0 }}>
                        Caption
                        <CharacterCountSpan $theme={theme}>
                            [up to 265 characters]
                        </CharacterCountSpan>
                    </FieldTitle>
                    <Expander
                        height={
                            values.caption.length > 1 && errors.caption
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                $theme={theme}
                                className="animated fadeIn slower"
                            >
                                {errors.caption}
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPadding />
                    <TextAreaWidth id="scrollable-content">
                        <CaptionText
                            $theme={theme}
                            id="caption"
                            name="caption"
                            placeholder="ie. Doing donuts in my Fiat Panda 4x4 in the snow"
                            aria-live="polite"
                            onChange={handleChange}
                            value={values.caption}
                        />
                    </TextAreaWidth>
                    <SmallPadding />
                    <SmallPadding />
                    <FieldTitle>
                        Location
                        <CharacterCountSpan $theme={theme}>
                            [up to 35 characters]
                        </CharacterCountSpan>
                    </FieldTitle>
                    <Expander
                        height={
                            values.location.length > 1 && errors.location
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                $theme={theme}
                                className="animated fadeIn slower"
                            >
                                {errors.location}
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPaddingBetweenFields />
                    <InputField
                        theme={theme}
                        id="location"
                        name="location"
                        placeholder="Where was this taken?"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.location}
                        onFocus={() => {
                            toggleShowLocationSuggestion(true)
                        }}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <GooglePlacesLocationsDropdown
                        valueSearched={values.location}
                        showDropdown={showLocationSuggestions}
                        toggleShowDropDown={(how: boolean) =>
                            toggleShowLocationSuggestion(how)
                        }
                        onSuggestionClick={handleSuggestionSelection}
                    />
                    <BiggerPadding />
                    <FieldTitle>
                        Credits
                        <CharacterCountSpan $theme={theme}>
                            [up to 35 characters]
                        </CharacterCountSpan>
                    </FieldTitle>
                    <Expander
                        height={
                            values.credits.length > 1 && errors.credits
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                $theme={theme}
                                className="animated fadeIn slower"
                            >
                                {errors.credits}
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPaddingBetweenFields />
                    <InputField
                        theme={theme}
                        id="credits"
                        name="credits"
                        placeholder="Name of photographer (if applicable)"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.credits}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <SmallPadding />
                    <SmallPadding />
                    <Row>
                        <Line $theme={theme} />
                        <TipText $theme={theme}>
                            Once uploaded, you can edit images and also select
                            your{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                car profile image.
                            </span>
                        </TipText>
                    </Row>
                </Expander>
            </InnerFormPadding>

            {isLastFileUploaded ? (
                <LargePadding />
            ) : (
                <div style={{ paddingTop: '56px' }} />
            )}
            <BtnWrap>
                <ButtonsStandardBottomRowMobile
                    cancel={close}
                    submit={props.handleSubmit}
                    confirmationtext={'upload'}
                    disabled={
                        !filesCurrentlyUploaded ||
                        filesCurrentlyUploaded.length < 1 ||
                        filesCurrentlyUploaded[0] === undefined
                            ? true
                            : !isLastFileUploaded
                            ? true
                            : (values.caption && errors.caption) ||
                              (values.location && errors.caption) ||
                              (values.credits && errors.credits)
                            ? true
                            : false
                    }
                    dataCyIdUpdate="upload_car_images_mobile"
                    dataCyIdCancel="cancel_upload_car_images_mobile"
                />
            </BtnWrap>
        </EditFormContainerMobile>
    )
}

const ReusableGalleryImageEditFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        uploadedFiles: undefined,
        caption: '',
        location: '',
        credits: '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                caption: Yup.string().max(
                    266,
                    'Caption must be be less than 265 characters.'
                ),
                location: Yup.string().max(
                    36,
                    'Location must be be less than 35 characters.'
                ),

                credits: Yup.string().max(
                    36,
                    'Credits must be be less than 35 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        props.submitFiles(values)
        setSubmitting(false)
    },
})(InnerForm)

export default ReusableGalleryImageEditFormMobile
