import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
    IOtherDriver_DriveList,
} from '../../reducer'

import { gen_draft_differing_values_to_patch } from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { api } from '../../../services'

import {
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import { ICarID } from './carMileageParking_submit'
import { ValidateAndAddStatsToApplication } from '../application/get'
import posthog from 'posthog-js'
import { IInsuranceEverydayVehicleID } from '../../types'
import { message } from 'antd'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'

export function* Submit_car_ownership(p: ICarID): any {
    // console.log('payload', p)
    posthog.capture('Update insurance application car ownership')

    let all_existing: IInsuranceQuoteApplication = yield select(
        insurance_application_existing_state_select
    )

    let external_car_ids = all_existing.external_car_ids
    let car_is_external: boolean =
        external_car_ids && external_car_ids.indexOf(p.carid) !== -1
            ? true
            : false

    let everyday_car_ids = all_existing.everyday_car_ids

    let car_is_everyday: boolean =
        everyday_car_ids && everyday_car_ids.indexOf(p.carid) !== -1
            ? true
            : false

    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let draft = {
            ...all_draft.vehicles[p.carid],
        }
        let existing = {
            ...all_existing.vehicles[p.carid],
        }

        let dataToSendToPatch: IAnyObject | undefined = undefined

        if (draft.ownership) {
            dataToSendToPatch = gen_draft_differing_values_to_patch({
                existing: existing.ownership ?? {},
                draft: draft.ownership ?? {},
            })
        }

        // frequency changes
        let driverIDS: string[] = Object.keys(all_draft.other_drivers)

        let applicationNamedDriversChanges: any[] = []

        let uidd = all_existing.vehicles[p.carid]?.api_uid

        for (let i = 0; i < driverIDS.length; i++) {
            let draft_driveList: IOtherDriver_DriveList[] | undefined =
                all_draft.other_drivers[driverIDS[i]].drive_list

            let existing_driveList: IOtherDriver_DriveList[] | undefined =
                all_existing.other_drivers[driverIDS[i]].drive_list

            if (draft_driveList && existing_driveList) {
                let carWithExternalID: IOtherDriver_DriveList | undefined =
                    draft_driveList.find((car) => {
                        return car.carid === p.carid
                    })

                let existingCarWithExternalID:
                    | IOtherDriver_DriveList
                    | undefined = existing_driveList.find((car) => {
                    return car.carid === p.carid
                })

                if (carWithExternalID && existingCarWithExternalID) {
                    if (
                        carWithExternalID.use_frequency !==
                            existingCarWithExternalID.use_frequency &&
                        carWithExternalID.use_frequency?.uid
                    ) {
                        applicationNamedDriversChanges = [
                            ...applicationNamedDriversChanges,
                            {
                                uid: driverIDS[i],
                                custodian_cars: [
                                    {
                                        uid: uidd,
                                        use_frequency:
                                            carWithExternalID.use_frequency
                                                ?.uid,
                                    },
                                ],
                            },
                        ]
                    }
                }
            }
        }

        if (applicationNamedDriversChanges.length > 0) {
            // just to say yes has change, will be removed below
            dataToSendToPatch = dataToSendToPatch
                ? {
                      ...dataToSendToPatch,
                      named_drivers: true,
                  }
                : { named_drivers: true }
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            // console.log('data to patch', dataToSendToPatch)
            // call api to patch as thats all the data merged we want to patch

            if (dataToSendToPatch.named_drivers) {
                delete dataToSendToPatch['named_drivers']
            }

            let ncdYearsData: IInsuranceEverydayVehicleID[] = []

            let additional_ncd_ids = [
                'was_ncd_uk_earned_on_private_policy',
                'is_ncd_in_use',
                'has_requested_ncd_protection',
            ]

            if (
                dataToSendToPatch.ncd_years !== undefined ||
                (draft?.ownership?.ncd_years !== undefined &&
                    draft?.ownership?.ncd_years > 0)
            ) {
                let no_of_ncd_years = dataToSendToPatch.ncd_years
                if (car_is_external) {
                    if (no_of_ncd_years === 0) {
                        ncdYearsData = [
                            {
                                external_vehicle_uid: p.carid,
                                ncd_years: no_of_ncd_years,
                            },
                        ]
                    } else {
                        let ncd_obj_to_update: IAnyObject = {
                            external_vehicle_uid: p.carid,
                            ncd_years: no_of_ncd_years,
                        }

                        // additional_ncd_fields removed for not insured everyday => hence external cars
                        // but leaving logic here in case we need to add others eventually

                        // for (let id of additional_ncd_ids) {
                        //     if (dataToSendToPatch[id] !== undefined) {
                        //         let copy2 = dataToSendToPatch[id]
                        //         ncd_obj_to_update[id] = copy2
                        //     }
                        //     delete dataToSendToPatch[id]
                        // }
                        ncdYearsData = [ncd_obj_to_update]
                    }
                } else if (car_is_everyday) {
                    if (no_of_ncd_years === 0) {
                        ncdYearsData = [
                            {
                                custodian_car_uid: uidd,
                                ncd_years: no_of_ncd_years,
                            },
                        ]
                    } else {
                        let ncd_obj_to_update: IAnyObject = {
                            custodian_car_uid: uidd,
                            ncd_years: no_of_ncd_years,
                        }
                        for (let id of additional_ncd_ids) {
                            if (dataToSendToPatch[id] !== undefined) {
                                let copy2 = dataToSendToPatch[id]
                                ncd_obj_to_update[id] = copy2
                            }
                        }
                        ncdYearsData = [ncd_obj_to_update]
                    }
                } else {
                    if (no_of_ncd_years === 0) {
                        ncdYearsData = [
                            {
                                external_id: p.carid,
                                ncd_years: no_of_ncd_years,
                            },
                        ]
                    } else {
                        let ncd_obj_to_update: IAnyObject = {
                            external_id: p.carid,
                            ncd_years: no_of_ncd_years,
                        }
                        for (let id of additional_ncd_ids) {
                            if (dataToSendToPatch[id] !== undefined) {
                                let copy2 = dataToSendToPatch[id]
                                ncd_obj_to_update[id] = copy2
                            }
                        }
                        ncdYearsData = [ncd_obj_to_update]
                    }
                }

                delete dataToSendToPatch['ncd_years']

                // cleanup additional fields from main payload no matter value of ncd years
                // since they are assigned anyway to the right payload above if the case (everyday / external car)
                for (let id of additional_ncd_ids) {
                    if (dataToSendToPatch[id] !== undefined) {
                        delete dataToSendToPatch[id]
                    }
                }
            }

            let carData: IAnyObject = {}

            if (
                dataToSendToPatch &&
                Object.keys(dataToSendToPatch).length !== 0
            ) {
                carData = {
                    ...carData,
                    ...dataToSendToPatch,
                }
            }

            if (uidd) {
                carData = {
                    ...carData,
                    uid: uidd,
                }
            }

            if (!car_is_external) {
                carData = {
                    ...carData,
                    external_id: p.carid,
                }
            }

            let data: IAnyObject = {}

            if (!car_is_external) {
                data = {
                    customer: {
                        // check if external car or custodian car
                        custodian_cars: [
                            {
                                ...carData,
                            },
                        ],
                    },
                }
            }

            if (car_is_external) {
                data = {
                    customer: {
                        // check if external car or custodian car
                        external_vehicles: [
                            {
                                ...carData,
                            },
                        ],
                    },
                }
            }

            if (ncdYearsData.length > 0) {
                if (car_is_everyday || car_is_external) {
                    data = {
                        ...data,
                        application_everyday_vehicles: ncdYearsData,
                    }
                } else {
                    data = {
                        ...data,
                        application_custodian_cars: ncdYearsData,
                    }
                }
            }

            if (applicationNamedDriversChanges.length > 0) {
                data = {
                    ...data,
                    customer: {
                        ...data.customer,
                        named_drivers: [...applicationNamedDriversChanges],
                    },
                }
            }
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...data },
                }
            )

            if (p?.isCrossForm) {
                p.message && message.success(p.message)
            }

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )

            yield put(
                insuranceActions.submit_carOwnership_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(insuranceActions.submit_carOwnership_success())
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_car_ownership,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${
                    all_existing.id
                }/${car_is_external ? 'everyday_car' : 'car'}/${
                    p.carid
                }/ownership`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(insuranceActions.submit_carOwnership_error(customError))
        }
    }
}

function* Watcher_Submit_car_ownership() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.submit_carOwnership_request
        )
        yield call(Submit_car_ownership, payload)
    }
}

const car_ownership_submit: any[] = [fork(Watcher_Submit_car_ownership)]

export default car_ownership_submit
