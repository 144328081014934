import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import Accept from '../../dropzone/imagesDropzoneDesktop'
import useThemes from '../../../../providers/theme/hooks'
import { useAppDispatch } from '../../../../redux/store/hooks'
import { fileStorageActions } from '../../../../redux/filestorage/reducer'
import { IUploadGalleryImagesPreSignedUrlsPayload } from '../../../../redux/filestorage/types'

const EditFormContainerDesktop = styled.section`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        min-width: 670px;
        margin-top: 6vh;
        padding-bottom: 30px;
        padding-top: 30px;
        max-height: 80vh;
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
        color: var(--text-strong, #1a1a1a);
        border-radius: 5px;
        position: relative;
        overflow-y: auto;
        margin-bottom: 50px;
        /* width */
        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #999;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #888;
        }
    }

    @media ${device.desktop} {
        margin-top: 6vh !important;
        max-height: 88vh;
    }

    @media ${device.smallest_laptops} {
        margin-top: 5vh !important;
        max-height: 80vh;
    }

    @media ${device.ipad} {
        margin-top: 5vh !important;
    }

    @media ${device.large_desktop} {
        margin-top: 4vh !important;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    box-sizing: border-box;
`

const FormTitle = styled.div`
    font-family: Lato-light;
    align-self: start;
    font-size: 21px;

    @media ${device.large_desktop} {
        font-size: 21px;
    }
`

const SmallPadding = styled.div`
    padding-top: 5px;
`

const FieldPadding = styled.div`
    padding-top: 24px;
`

const LargePadding = styled.div`
    padding-top: 24px;

    @media ${device.ipad} {
        padding-top: 32px;
    }

    @media ${device.large_desktop} {
        padding-top: 24px;
    }
`

const ImgBtnRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`

export const Error = styled.div`
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 11px;
`

const Text = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
    font-family: 'Lato-Light';
    padding-top: 12px;
`

// const dispatchProps = {
//     getPresignedUrlEntryGalleryImages: (p: IPresignedUrlEntryImagesArgs) =>
//         getPresignedUrlEntryGalleryImagesRequest(p),
//     submitEntryGalleryImagesFilestorage: (
//         p: IUploadAndCreateEntryGalleryImageArgs
//     ) => submitEntryGalleryImagesFilestorageRequest(p),
// }

// function mapStateToProps(state: RootState) {
//     return {
//         gallerypresignedurls: state.fileStorage.gallerypresignedurls,
//     }
// }

type Cover = string | null

type Feature = string[] | null

interface Values {
    caption: string
    location: string
    credits: string
    file_url?: string
    files?: File[]
    cover?: Cover
    featured?: Feature
}

interface OtherProps {
    close: any
    deleteImageUploaded?: any
    setImageUploaded?: any
    currentImagesUploaded?: any
    carid: string
    entryid: string
    filesProgressObj?: any
    isSomethingBeingUploaded?: boolean
    gallerypresignedurls: IUploadGalleryImagesPreSignedUrlsPayload | undefined
    dispatch: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        close,
        setImageUploaded,
        deleteImageUploaded,
        setFieldValue,
        currentImagesUploaded,
        filesProgressObj,
        isSomethingBeingUploaded,
        errors,
    } = props

    const dispatch = useAppDispatch()

    const handleFilesGiven = (files: File[]) => {
        let count = files.length
        let file_names = files.map((file) => file.name)

        count > 0 &&
            dispatch(
                fileStorageActions.getPresignedUrlEntryGalleryImagesRequest({
                    entryid: props.entryid,
                    carid: props.carid,
                    count: count,
                    file_names: file_names,
                })
            )

        setFieldValue('files', files)

        setImageUploaded(files)
    }

    const checkIfDisabled = (): boolean => {
        let allFilesUndefined: boolean = true

        for (let i = 0; i < currentImagesUploaded.length; i++) {
            if (currentImagesUploaded[i] !== undefined) {
                allFilesUndefined = false
            }
        }

        if (isSomethingBeingUploaded) {
            return true
        } else if (
            (values.caption && errors.caption) ||
            (values.location && errors.caption) ||
            (values.credits && errors.credits)
        ) {
            return true
        } else if (allFilesUndefined) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <EditFormContainerDesktop id="scrollable-content">
            <InnerFormPadding>
                <FormTitle>Add images to your history file entry</FormTitle>
                <Text>
                    Upload up to 20 images at a time.
                    <br />
                    Image formats accepted : png, jpg, jpeg, HEIC and HEIF.
                    <br />
                    Maximum file size : 20MB
                </Text>
                <div style={{ paddingTop: '30px' }} />
                <ImgBtnRowWrapper>
                    <Accept
                        handleFilesGiven={handleFilesGiven}
                        removeFile={deleteImageUploaded}
                        imageUploadedData={currentImagesUploaded}
                        filesProgressObj={filesProgressObj}
                    />
                </ImgBtnRowWrapper>
                <SmallPadding />
                <Expander
                    height={
                        values.caption.length > 1 && errors.caption ? 'auto' : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.caption}
                        </Error>
                    </Faded>
                </Expander>
                <InputField
                    theme={theme}
                    id="caption"
                    name="caption"
                    placeholder="Caption"
                    helperText="ie. Doing donuts in my Fiat Panda 4x4 in the snow"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.caption}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <FieldPadding />
                <Expander
                    height={
                        values.location.length > 1 && errors.location
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.location}
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id="location"
                    name="location"
                    placeholder="Location"
                    helperText="Where was this taken?"
                    tabindex={2}
                    aria-live="polite"
                    formikprops={props}
                    value={values.location}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <FieldPadding />
                <Expander
                    height={
                        values.credits.length > 1 && errors.credits ? 'auto' : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.credits}
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id="credits"
                    name="credits"
                    placeholder="Credits"
                    helperText="Name of photographer (if applicable)"
                    tabindex={3}
                    aria-live="polite"
                    formikprops={props}
                    value={values.credits}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <LargePadding />
                <LargePadding />
                <ButtonsStandardBottomRowDesktop
                    cancel={close}
                    submit={props.handleSubmit}
                    secondText={'upload'}
                    isDisabled={checkIfDisabled()}
                />
                <LargePadding />
            </InnerFormPadding>
        </EditFormContainerDesktop>
    )
}

const HistoryFileGalleryEditFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        caption: '',
        location: '',
        credits: '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                caption: Yup.string().max(
                    266,
                    'Caption must be be less than 265 characters.'
                ),
                location: Yup.string().max(
                    36,
                    'Location must be be less than 35 characters.'
                ),

                credits: Yup.string().max(
                    36,
                    'Credits must be be less than 35 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        let files = props.currentImagesUploaded

        if (files !== undefined && props.gallerypresignedurls) {
            props.dispatch(
                fileStorageActions.submitEntryGalleryImagesFilestorageRequest({
                    carid: props.carid,
                    entryid: props.entryid,
                    gallerypresignedurls: props.gallerypresignedurls,
                    files: files,
                    fields: {
                        caption: values.caption,
                        credit: values.credits,
                        location: values.location,
                        cover: values.cover ? values.cover : null,
                        featured: values.featured ? values.featured : null,
                    },
                    isFromQuickActions: false,
                })
            )

            props.close()
        }

        setSubmitting(false)
    },
})(InnerForm)

export default HistoryFileGalleryEditFormDesktop
