import React, { useState, useEffect } from 'react'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import PrivateShareSearchFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareSearchFormMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import ReturnNav from '../../atoms/header/returnNav'
import SideSlider from '../../templates/animated/sideSlider'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IShareRecipientRequest_redux } from '../../../redux/entities/cars/actions'
import {
    IDirectShareOwnedItem,
    IEntityPrivateOwnedSharesState,
} from '../../../redux/entities/cars/types'
import { usersActions } from '../../../redux/user/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { searchUsersActions } from '../../../redux/searchUsers/reducer'

const GarageShareEdit: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const garage_id = useAppSelector(
        (state) => state.user.userLoggedIn?.owns_garage?.uid
    )

    const garageSharesLoading = useAppSelector(
        (state) => state.entities.garagesData.sharesLoading
    )
    const garageData = useAppSelector(
        (state) => state.entities.garagesData.garages
    )

    const searchResults = useAppSelector((state) => state.searchUsers.list)
    const searchResultsLoading = useAppSelector(
        (state) => state.searchUsers.loading
    )

    const [share_being_edited, setShare_being_edited] =
        useState<IDirectShareOwnedItem>({
            title: '',
            entity_id: '',
            active_permission_ids: [] as any,
            id: 'share_being_edited',
            type: 'garage',
            created_at: '',
            updated_at: '',
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            owner: {
                uid: 'useruid',
                display_name: 'noname',
                location: 'none',
            },
            total_recipients: 0,
        })
    const [isDesktop, setIsDesktop] = useState(false)

    const handleResize = () => {
        setIsDesktop(window.innerWidth > 881)
    }

    const setupLocalStateData = () => {
        const q_params = new URLSearchParams(location.search)
        let shareid = q_params.get('shareid')

        const garage_shares: IEntityPrivateOwnedSharesState | undefined =
            garage_id &&
            garageData &&
            garageData[garage_id] &&
            garageData[garage_id].private_shares_owned
                ? garageData[garage_id].private_shares_owned
                : undefined

        if (
            garage_shares &&
            garage_shares.entity_owned_direct_shares_data &&
            shareid &&
            garage_shares.entity_owned_direct_shares_data[shareid]
        ) {
            const share: IDirectShareOwnedItem =
                garage_shares.entity_owned_direct_shares_data[shareid]

            setShare_being_edited({
                ...share,
                active_display: {
                    item_ids_display: share.active_display?.item_ids_display
                        ? share.active_display?.item_ids_display
                        : share.all_share_recipients_ids_list,
                    activeSort: share.active_display?.activeSort
                        ? share.active_display?.activeSort
                        : 'added_at',
                },
            })
        }
    }

    useEffect(() => {
        const q_params = new URLSearchParams(location.search)
        let shareid = q_params.get('shareid')

        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/sharing/with-others/garage/edit-share/recipients?shareid=${shareid}`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else {
            if (!garageData[garage_id || '']) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }

            shareid &&
                dispatch(
                    garagesActions.getSingleGarageDirectShareRequest({
                        entity_id: garage_id || '',
                        share_id: shareid,
                    })
                )
            setupLocalStateData()
        }

        window.addEventListener('resize', handleResize, { passive: true })
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [userLoggedIn, garage_id])

    useEffect(() => {
        setupLocalStateData()
    }, [garageData, garage_id])

    const q_params = new URLSearchParams(location.search)
    let shareid = q_params.get('shareid')

    useEffect(() => {
        if (isDesktop) {
            navigate(
                `/sharing/with-others/garage/edit-share?shareid=${shareid}`,
                { replace: true }
            )
        }
    }, [isDesktop, navigate, shareid])

    return (
        <React.Fragment>
            <DesktopDisplayOnly></DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <SideSlider direction="right" initial="enter">
                    <Link
                        to={{
                            pathname: `/sharing/with-others/garage/edit-share`,
                            search: `?shareid=${shareid}`,
                        }}
                    >
                        <ReturnNav />
                    </Link>
                    <PrivateShareSearchFormManagerMobile
                        share={share_being_edited}
                        searchUsers={(value: string | undefined) =>
                            dispatch(
                                searchUsersActions.searchPlatformUsersRequest(
                                    value
                                )
                            )
                        }
                        searchResults={searchResults}
                        resultsLoading={searchResultsLoading}
                        addShareRecipient={(p: IShareRecipientRequest_redux) =>
                            dispatch(
                                garagesActions.addGarageShareRecipientRequest(p)
                            )
                        }
                        selectedUsers={
                            share_being_edited.all_share_recipients_ids_list
                        }
                        sharesLoading={garageSharesLoading}
                        goBack={() =>
                            setTimeout(
                                () =>
                                    navigate(
                                        `/sharing/with-others/garage/edit-share?shareid=${shareid}`
                                    ),
                                500
                            )
                        }
                    />
                </SideSlider>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default GarageShareEdit
