import * as React from 'react'
import { generateOverviewImages } from '../generateOverviewImages'
import GalleryOverviewManagerDesktop from './galleryOverviewManagerDesktop'
import {
    IGalleryImage,
    IGalleryImagesObject,
} from '../../../../redux/entities/galleries/types'

export interface IImageProps {
    url: string
}

interface Props {
    isNecessaryGalleryDataLoading: boolean
    overview_images_ids: string[]
    cover_id: string
    images_data: IGalleryImagesObject | null
    onClick?: any
    images_ids: string[]
    carid: string
    readOnlyMode?: boolean
    openImgForm?: any
}

const GalleryOverviewDataManagerDesktop: React.FC<Props> = ({
    images_data,
    overview_images_ids,
    cover_id,
    isNecessaryGalleryDataLoading,
    onClick,
    openImgForm,
    images_ids,
    carid,
    readOnlyMode,
}) => {
    const cover_img: IGalleryImage | undefined =
        images_data && images_data[cover_id] ? images_data[cover_id] : undefined

    const img_array: IGalleryImage[] | null =
        images_data &&
        generateOverviewImages(
            images_data,
            images_ids,
            overview_images_ids,
            cover_img
        )

    return (
        <React.Fragment>
            {isNecessaryGalleryDataLoading !== true && (
                <GalleryOverviewManagerDesktop
                    images_array={img_array}
                    coverimg={img_array && img_array[0]}
                    onClick={onClick && onClick}
                    onAddClick={openImgForm && openImgForm}
                    carid={carid}
                    count={images_ids ? images_ids.length : 0}
                    readOnlyMode={readOnlyMode}
                />
            )}
        </React.Fragment>
    )
}

export default GalleryOverviewDataManagerDesktop
