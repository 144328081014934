import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import posthog from 'posthog-js'
import { usersActions } from '../reducer'
import { IReduxError } from '../../entities/cars/types'

export function* verifiedResetPasswordSaga(p: {
    passwordGiven: string
    uid: string
}): any {
    try {
        yield call(api.authentication.verifiedResetPasswordApi, p)

        // Capture event
        posthog.capture('RESET PASSWORD VERIFICATION')

        yield put(usersActions.verifiedResetPasswordSuccess())
        localStorage?.removeItem('refresh_token')
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)
        yield put(usersActions.verifiedResetPasswordError(typedError))
    }
}

function* watcherVerifiedResetPassword() {
    while (true) {
        const { payload } = yield take(
            usersActions.verifiedResetPasswordRequest
        )
        yield call(verifiedResetPasswordSaga, payload)
    }
}

const verifiedResetPassword: any[] = [fork(watcherVerifiedResetPassword)]

export default verifiedResetPassword
