import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAppSelector } from '../../../redux/store/hooks'
import { device } from '../../templates/displays/devices'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import ButtonAtom from '../Button/ButtonAtom'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    @media ${device.mobile} {
        gap: 32px;
    }
`

const NoBgBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
`

const GreyBgBox = styled.div`
    background-color: rgba(26, 26, 26, 0.02);
    width: 510px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    @media ${device.mobile} {
        width: 100%;
        box-sizing: border-box;
        padding: 32px;
    }
`

const Title = styled.div`
    font-family: Lato-semibold;
    font-size: 24px;
    color: #666666;
    text-align: center;
    @media ${device.mobile} {
        font-size: 22px;
    }
`

const NoBgBoxText = styled.div`
    font-family: Lato;
    font-size: 16px;
    color: #666666;
    text-align: center;
    @media ${device.mobile} {
        font-size: 14px;
    }
`

const GreyBgBoxText = styled.div`
    font-family: Lato;
    font-size: 16px;
    color: #1a1a1a;
    text-align: center;
    @media ${device.mobile} {
        font-size: 14px;
    }
`

const LinkBtn = styled(Link)`
    all: unset;
    color: #5ec3ca !important;
    border: 1px solid #5ec3ca;
    padding: 10px 16px;
    font-family: Lato;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    :hover {
        border-width: 2px;
        color: #5ec3ca !important;
    }
`
// const StyledLink = styled(Link)`
//     all: unset;
//     color: #5ec3ca !important;
//     font-family: Lato;
//     font-size: 14px;
//     cursor: pointer;
// `

interface IProps {
    page: 'for sale' | 'sold' | 'watchlist'
    matchingSearchResults?: number
}

const ShowroomNoResultsBox = (props: IProps) => {
    let { matchingSearchResults, page } = props
    const size: WindowSize = useWindowSize()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let watchListCopy =
        userLoggedIn !== null
            ? `Your watchlist is empty.`
            : 'You need to login to your account or register to see your watchlist.'

    let navigate = useNavigate()

    return (
        <Wrapper>
            <NoBgBox>
                <Title>
                    {page === 'watchlist' ? watchListCopy : 'No cars found'}
                </Title>
                <NoBgBoxText
                    style={
                        page === 'watchlist' &&
                        size &&
                        size.width &&
                        size.width < 500
                            ? { maxWidth: '300px' }
                            : {}
                    }
                >
                    {page !== 'watchlist' ? (
                        <>
                            <div>Please, try using different search terms.</div>
                            <div>
                                You can search by Year, Make, Model or Keyword
                            </div>
                        </>
                    ) : page === 'watchlist' ? (
                        <>
                            <div
                                style={
                                    size && size.width && size.width < 500
                                        ? { paddingBottom: 8 }
                                        : {}
                                }
                            >
                                You are not watching any cars which are
                                currently in the Showroom.
                            </div>
                            {/* <div>
                                You can learn more about how watching works by
                                clicking <StyledLink to={'#'}>here</StyledLink>.
                            </div> */}

                            {!userLoggedIn && (
                                <div
                                    style={{
                                        justifySelf: 'center',
                                        alignSelf: 'center',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingTop: '50px',
                                    }}
                                >
                                    <ButtonAtom
                                        theme="secondary"
                                        onClick={() => {
                                            navigate('/login')
                                        }}
                                    >
                                        Login
                                    </ButtonAtom>
                                </div>
                            )}
                        </>
                    ) : null}
                </NoBgBoxText>
            </NoBgBox>
            {page !== 'watchlist' &&
            page !== 'sold' &&
            matchingSearchResults &&
            matchingSearchResults > 0 ? (
                <GreyBgBox>
                    <GreyBgBoxText>
                        {`Or check out matching search results in sold cars.`}
                    </GreyBgBoxText>
                    <LinkBtn to={`/showroom/sold`}>
                        {`${matchingSearchResults} sold cars`}
                    </LinkBtn>
                </GreyBgBox>
            ) : null}
        </Wrapper>
    )
}

export default ShowroomNoResultsBox
