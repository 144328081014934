import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/shareYourCar/public_share.png'
import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
    JourneyWelcomeNewLineOnMobile,
} from '../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import FadedSlower from '../../../templates/animated/FadedSlower'

const LinkBox = styled.a`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
`

const LinkText = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
`

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function PublicLinkShareYourCarJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section[
                                        'share_your_car'
                                    ].primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[
                                        `share_your_car`
                                    ].primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        You can also just share a link
                                    </JourneyStepTitle>
                                    <JourneyText $theme={theme}>
                                        QR codes aren’t for everyone so you can
                                        also just share the link via whatever
                                        method you like such as Email, WhatsApp,
                                        Messenger etc.
                                    </JourneyText>
                                    <JourneyText $theme={theme}>
                                        Preview how a car shared page looks
                                        below.
                                    </JourneyText>
                                    <LinkBox
                                        style={{
                                            backgroundColor:
                                                journey_colours[theme].section
                                                    .share_your_car
                                                    .primary_500_04,
                                        }}
                                        href="https://share.custodian.club/car/e477ee6b2a038a6eab6727d879bb386e/overview"
                                        target="_blank"
                                    >
                                        <LinkText
                                            style={{
                                                color: journey_colours[theme]
                                                    .section.share_your_car
                                                    .primary_500,
                                            }}
                                        >
                                            https://share.custodian.club/car/e477ee6b2a038a6eab6727d879bb386e/overview
                                        </LinkText>
                                    </LinkBox>
                                </JourneyColumnContent>
                            </JourneyColumnContent>
                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'share_your_car'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'share_your_car'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <FadedSlower>
                                <img
                                    src={image}
                                    // placeholder={grey_placeholder}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '45vw',
                                        maxHeight: '80vh',
                                    }}
                                />
                            </FadedSlower>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingBottom: 200,
                            paddingTop: 48,
                            overflow: 'hidden',
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <JourneyStepTitle
                                        $theme={theme}
                                        style={{ paddingRight: '40px' }}
                                    >
                                        You can also just
                                        <JourneyWelcomeNewLineOnMobile />
                                        <span
                                            style={{
                                                color: journey_colours[theme]
                                                    .section.share_your_car
                                                    .primary_500,
                                                fontSize: '28px',
                                            }}
                                        >
                                            share a link
                                        </span>
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        Car ownership is best enjoyed together
                                    </JourneyText>
                                </JourneyColumnContent>
                                <JourneyText $theme={theme}>
                                    QR codes aren’t for everyone so you can also
                                    just share the link via whatever method you
                                    like such as Email, WhatsApp, Messenger etc.
                                    Preview how a car shared page looks below.
                                </JourneyText>
                                <JourneyColumnContent style={{ gap: 12 }}>
                                    <JourneyStepTitle
                                        $theme={theme}
                                        style={{
                                            fontSize: 14,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Try it out!
                                    </JourneyStepTitle>
                                    <LinkBox
                                        style={{
                                            backgroundColor:
                                                journey_colours[theme].section
                                                    .share_your_car
                                                    .primary_500_04,
                                        }}
                                        href="https://share.custodian.club/car/e477ee6b2a038a6eab6727d879bb386e/overview"
                                        target="_blank"
                                    >
                                        <LinkText
                                            style={{
                                                color: journey_colours[theme]
                                                    .section.share_your_car
                                                    .primary_500,
                                            }}
                                        >
                                            https://share.custodian.club/car/e477ee6b2a038a6eab6727d879bb386e/overview
                                        </LinkText>
                                    </LinkBox>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <FadedSlower>
                                <img
                                    // placeholder={grey_placeholder}
                                    src={image}
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        height: 'auto',
                                        objectFit: 'contain',
                                    }}
                                />
                            </FadedSlower>
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="share_your_car"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
