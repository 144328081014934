import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import colours, {
    insurance_colours,
} from '../../../../../../providers/theme/colours'
import useThemes from '../../../../../../providers/theme/hooks'
import {
    IAnyObject,
    insuranceActions,
    IInsuranceQuoteApplication,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
// import {
//     IsInsuranceApplicationDateValid_result,
//     isInsuranceApplicationDateValid,
// } from '../../../../../../redux/localdata/insuranceLocalData/datesValidation'
import { insurance_fields_main_driver_licence_address } from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { menuActions } from '../../../../../../redux/menus/reducer'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import Expander from '../../../../../atoms/expander/expander'
import InsuranceAddressCardV2 from '../../../../../molecules/cards/insurance/addressCardDesktopV2'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import { IAddressActiveModal } from '../../../addressesManager'
import { AddressWindow } from '../../../addressesManager/addressWindow'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import { InsuranceFormQuestion } from '../../../formGenerators/renderElementMobile'
import CircleLoader from '../../../../../atoms/loader/circleLoader'
import FadedSlower from '../../../../../templates/animated/FadedSlower'
import { IDropdownItem } from 'entityModels'
import InsuranceApplicationMobileSearch from '../../../../../pages/insurance_quote/flows/search/mobileSearch'
import PortalInvisibleWrapper from '../../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import RemoveMobileSearchAnchor from '../../../../../templates/insurance/removeMobileSearchAnchor'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'

export const Error = styled.div`
    color: red;
    font-style: italic;
    font-size: 11px;
`

export type UserParams = {
    userid: string
}
type Props = {
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const InsuranceMainDriverLicenceAddressWizardForm = (props: Props) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    let userid = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.main_driver?.id
    })

    const data: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.main_driver
                ?.licence_address ?? {}
        )
    })

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.main_driver
                ?.licence_address ?? {}
        )
    })

    const errors: IAnyObject | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.main_driver
            ?.licence_address_errors
    })

    const addresses = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.addresses
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_main_driver_licenseAddress({
                id: id,
                error: error,
                userid: `${userid}`,
            })
        )
    }

    // const hasErrorFunc = () => {
    //     let hasErr: boolean = false

    //     if (errors) {
    //         for (const [_, value] of Object.entries(errors)) {
    //             if (value !== undefined) {
    //                 hasErr = true
    //             }
    //         }
    //     }

    //     return hasErr
    // }

    // let hasError = hasErrorFunc()

    let {
        setInitialInsurance_Drivers_License_dropdowns,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    const validateOnClick = (): boolean => {
        let hasErr2 = false

        for (
            let i = 0;
            i < insurance_fields_main_driver_licence_address.length;
            i++
        ) {
            let current_field_type =
                insurance_fields_main_driver_licence_address[i]

            if (current_field_type.is_required === true) {
                if (data[current_field_type.id] === undefined) {
                    setError(current_field_type.id, 'Required field.')

                    hasErr2 = true
                }
            }

            if (
                current_field_type.validation &&
                data[current_field_type.id] !== undefined
            ) {
                let value = data[current_field_type.id]

                if (
                    current_field_type.id === 'license_number' &&
                    data?.license_type?.uid &&
                    data?.license_type?.uid !== 'NON_UK'
                ) {
                    if (errors && errors['license_number']) {
                        hasErr2 = true
                    } else if (
                        data?.license_issue_location &&
                        data.license_issue_location?.name ===
                            'Northern Ireland' &&
                        data.license_number &&
                        data.license_number.length !== 8
                    ) {
                        setError(
                            'license_number',
                            'Invalid. Northern Ireland license number must have 8 characters.'
                        )
                        hasErr2 = true
                    } else if (
                        data?.license_issue_location &&
                        data.license_issue_location?.name === 'Great Britain' &&
                        data.license_number &&
                        data.license_number.length !== 16
                    ) {
                        setError(
                            'license_number',
                            'Invalid. Great Britain license number must have 16 characters.'
                        )
                        hasErr2 = true
                    }
                } else {
                    let is_valid_func = current_field_type.validation

                    let error_txt = current_field_type.error_txt

                    if (is_valid_func && is_valid_func(value) !== true) {
                        setError(current_field_type.id, error_txt ?? 'Invalid')
                        hasErr2 = true
                    }
                }
            }
        }

        // if (data && data['residency_date']) {
        //     let res: IsInsuranceApplicationDateValid_result =
        //         isInsuranceApplicationDateValid({
        //             fieldID: 'residency_date',
        //             application_edited: applicationData,
        //             section: 'license',
        //             valueToCheck: data['residency_date'],
        //             value_of_dependable_field:
        //                 data['is_uk_resident_since_birth'],
        //             expected_value_of_dependable_field_for_validation: false,
        //         })
        //     if (res.isValid === false) {
        //         hasErr2 = true
        //         setError('residency_date', res.error_txt ?? 'Invalid')
        //     }
        // }

        if (data && data['license_date'] && errors && errors[`license_date`]) {
            hasErr2 = true
        }

        if (
            data &&
            data['residency_date'] &&
            errors &&
            errors[`residency_date`]
        ) {
            hasErr2 = true
        }

        return hasErr2
    }

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
    }, [])

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        setInitialInsurance_Drivers_License_dropdowns()
    }, [userLoggedIn])

    let countriesList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    useEffect(() => {
        if (userLoggedIn && !countriesList) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [userLoggedIn])

    const [activeModal, toggleActiveModal] =
        useState<IAddressActiveModal>(undefined)

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const goPrevious = () => {
        navigate(
            `/insurance/application/${applicationData?.id}/wizard?section_id=main_policy_holder&subsection_id=basic_details`
        )
    }

    const goNext = () => {
        let ids2 = getInitialSectionWizardIds(
            'main_policy_holder',
            'licence_address'
        )

        if (ids2) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids2.sectionID}`)
            params2.append('subsection_id', `${ids2.sub_sectionID}`)
            if (ids2.car_id) {
                params2.append('car_id', `${ids2.car_id}`)
            }
            if (ids2.driver_id) {
                params2.append('driver_id', `${ids2.driver_id}`)
            }
            navigate(
                {
                    search: params2.toString(),
                },
                { replace: true }
            )
        } else navigate(`/insurance/application/${applicationData?.id}`)
    }
    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_mainDriver_licenceAddresses_request()
            )
            setTimeout(() => {
                goNext()
            }, 200)
        } else {
            setIsSavedDisabled(true)
        }
    }

    const saveFormAddress = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_mainDriver_licenceAddresses_request()
            )
        } else {
            setIsSavedDisabled(true)
        }
    }

    const { theme } = useThemes()

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'main_policy_holder',
            onSave: () => saveForm(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_main_driver_licenseAddress({
                id: id,
                answer: answer,
                userid: `${userid}`,
            })
        )
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'main_policy_holder',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }
    }

    let dataLoading = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.isLoading
    )

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')

    return dataLoading ? (
        <FadedSlower>
            <div
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    height: 240,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircleLoader
                    size={24}
                    color={
                        insurance_colours[theme].sections['main_policy_holder']
                            .primary_100
                    }
                />
            </div>
        </FadedSlower>
    ) : userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        list={insurance_fields_main_driver_licence_address}
                        onAnswerChange={onAnswerChange}
                        data={data}
                        errors={errors}
                        setError={setError}
                        sectionID="main_policy_holder"
                        isWizard={true}
                        mobileSearchPickerSectionURL={`/insurance/application/${applicationData?.id}/wizard?section_id=main_policy_holder&subsection_id=licence_address`}
                    />

                    <div style={{ paddingTop: '30px' }} />

                    <InsuranceFormQuestion $theme={theme}>
                        Residential address
                    </InsuranceFormQuestion>
                    <div style={{ paddingTop: '16px' }} />
                    {data['residential_address'] ? (
                        <InsuranceAddressCardV2
                            address={addresses[data['residential_address']]}
                            onChange={() => {
                                toggleActiveModal({
                                    active_modal: 'select',
                                    field_id: 'residential_address',
                                    title: 'Select residential address',
                                })
                            }}
                            sectionID="main_policy_holder"
                        />
                    ) : (
                        <ButtonAtom
                            theme="tertiary"
                            textTransform="none"
                            width={'100%'}
                            customBgColor="transparent"
                            onClick={() => {
                                toggleActiveModal({
                                    active_modal: 'select',
                                    field_id: 'residential_address',
                                    title: 'Select residential address',
                                })
                            }}
                            color={
                                insurance_colours[theme].sections[
                                    'main_policy_holder'
                                ].primary_100
                            }
                        >
                            Add address
                        </ButtonAtom>
                    )}
                    <Expander
                        height={
                            errors && errors[`residential_address`] ? 'auto' : 0
                        }
                    >
                        <Error style={{ paddingTop: '8px' }}>
                            {errors && errors[`residential_address`]
                                ? errors[`residential_address`]?.status
                                : ''}
                        </Error>
                    </Expander>

                    <div style={{ paddingTop: '40px' }} />

                    <InsuranceFormQuestion $theme={theme}>
                        Correspondence address
                    </InsuranceFormQuestion>
                    <div style={{ paddingTop: '16px' }} />
                    {data['correspondence_address'] ? (
                        <InsuranceAddressCardV2
                            address={addresses[data['correspondence_address']]}
                            onChange={() => {
                                toggleActiveModal({
                                    active_modal: 'select',
                                    field_id: 'correspondence_address',
                                    title: 'Select correspondence address',
                                })
                            }}
                            sectionID="main_policy_holder"
                        />
                    ) : (
                        <ButtonAtom
                            theme="tertiary"
                            textTransform="none"
                            width={'100%'}
                            customBgColor="transparent"
                            onClick={() => {
                                toggleActiveModal({
                                    active_modal: 'select',
                                    field_id: 'correspondence_address',
                                    title: 'Select correspondence address',
                                })
                            }}
                            color={
                                insurance_colours[theme].sections[
                                    'main_policy_holder'
                                ].primary_100
                            }
                        >
                            Add address
                        </ButtonAtom>
                    )}
                    <Expander
                        height={
                            errors && errors[`correspondence_address`]
                                ? 'auto'
                                : 0
                        }
                    >
                        <Error style={{ paddingTop: '8px' }}>
                            {errors && errors[`correspondence_address`]
                                ? errors[`correspondence_address`]?.status
                                : ''}
                        </Error>
                    </Expander>

                    <div style={{ paddingTop: '200px' }} />
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() => goPrevious()}
                    sectionId="main_policy_holder"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="main_policy_holder"
                            urlBack={`/insurance/application/${applicationData?.id}/wizard?section_id=main_policy_holder&subsection_id=licence_address`}
                            draft={data}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                if (field_id && errors && errors[field_id]) {
                                    setError(field_id, undefined)
                                }

                                userid &&
                                    dispatch(
                                        insuranceActions.set_answer_main_driver_licenseAddress(
                                            {
                                                id: `${field_id}`,
                                                answer: value,
                                                userid: userid,
                                            }
                                        )
                                    )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '88vw',
                                paddingBottom: 120,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                list={
                                    insurance_fields_main_driver_licence_address
                                }
                                onAnswerChange={onAnswerChange}
                                data={data}
                                errors={errors}
                                setError={setError}
                                mobileSearchPickerSectionURL={`/insurance/application/${applicationData?.id}/wizard?section_id=main_policy_holder&subsection_id=licence_address`}
                                sectionID="main_policy_holder"
                            />

                            <div style={{ paddingTop: '30px' }} />

                            <InsuranceFormQuestion $theme={theme}>
                                <div>Residential address</div>
                            </InsuranceFormQuestion>
                            <div style={{ paddingTop: '16px' }} />
                            {data['residential_address'] ? (
                                <InsuranceAddressCardV2
                                    address={
                                        addresses[data['residential_address']]
                                    }
                                    onChange={() => {
                                        toggleActiveModal({
                                            active_modal: 'select',
                                            field_id: 'residential_address',
                                            title: 'Select residential address',
                                        })
                                    }}
                                    sectionID="main_policy_holder"
                                />
                            ) : (
                                <ButtonAtom
                                    theme="tertiary"
                                    textTransform="none"
                                    width={'100%'}
                                    customBgColor="transparent"
                                    onClick={() => {
                                        toggleActiveModal({
                                            active_modal: 'select',
                                            field_id: 'residential_address',
                                            title: 'Select residential address',
                                        })
                                    }}
                                    color={
                                        insurance_colours[theme].sections[
                                            'main_policy_holder'
                                        ].primary_100
                                    }
                                >
                                    Add address
                                </ButtonAtom>
                            )}
                            <Expander
                                height={
                                    errors && errors[`residential_address`]
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <Error style={{ paddingTop: '8px' }}>
                                    {errors && errors[`residential_address`]
                                        ? errors[`residential_address`]?.status
                                        : ''}
                                </Error>
                            </Expander>

                            <div style={{ paddingTop: '40px' }} />

                            <InsuranceFormQuestion $theme={theme}>
                                Correspondence address
                            </InsuranceFormQuestion>
                            <div style={{ paddingTop: '16px' }} />
                            {data['correspondence_address'] ? (
                                <InsuranceAddressCardV2
                                    address={
                                        addresses[
                                            data['correspondence_address']
                                        ]
                                    }
                                    onChange={() => {
                                        toggleActiveModal({
                                            active_modal: 'select',
                                            field_id: 'correspondence_address',
                                            title: 'Select correspondence address',
                                        })
                                    }}
                                    sectionID="main_policy_holder"
                                />
                            ) : (
                                <ButtonAtom
                                    theme="tertiary"
                                    textTransform="none"
                                    width={'100%'}
                                    customBgColor="transparent"
                                    onClick={() => {
                                        toggleActiveModal({
                                            active_modal: 'select',
                                            field_id: 'correspondence_address',
                                            title: 'Select correspondence address',
                                        })
                                    }}
                                    color={
                                        insurance_colours[theme].sections[
                                            'main_policy_holder'
                                        ].primary_100
                                    }
                                >
                                    Add address
                                </ButtonAtom>
                            )}
                            <Expander
                                height={
                                    errors && errors[`correspondence_address`]
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <Error style={{ paddingTop: '8px' }}>
                                    {errors && errors[`correspondence_address`]
                                        ? errors[`correspondence_address`]
                                              ?.status
                                        : ''}
                                </Error>
                            </Expander>

                            <div style={{ paddingTop: '200px' }} />
                        </div>

                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() => goPrevious()}
                            sectionId="main_policy_holder"
                        />
                    </>
                )}
            </IpadAndMobileDisplay>

            <AddressWindow
                selectedAddress={
                    activeModal?.field_id === 'residential_address'
                        ? data['residential_address']
                        : activeModal?.field_id === 'correspondence_address'
                        ? data['correspondence_address']
                        : undefined
                }
                selectAddress={(addressid: string) => {
                    activeModal?.field_id &&
                        onAnswerChange(activeModal?.field_id, addressid)
                }}
                activeModal={activeModal}
                toggleActiveModal={toggleActiveModal}
                onSaveForm={saveFormAddress}
                sectionID="main_policy_holder"
            />
        </div>
    ) : (
        <div />
    )
}

export default InsuranceMainDriverLicenceAddressWizardForm
