import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'
import { IRemoveLabelToEntryAPIPayload } from '../../typedefinitions/apiPayloads'

export const unlinkLabelFromEntry = (
    payload: IRemoveLabelToEntryAPIPayload
): Promise<any> => {
    let req_body: string = JSON.stringify(
        (({ label_uids }) => ({ label_uids }))(payload)
    )

    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        body: req_body,
        credentials: 'include',
    }

    return fetch(
        urls.unlink_label_from_entry_endpoint(payload.entry_id, payload.car_id),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Unlink lebvel from entry error',
                        req_body
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
