import React, { useState } from 'react'
import { message } from 'antd'
import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'
import '../../antd.css'
import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import { Combobox } from '../../../../../atoms/select'
import Icon from '../../../../../atoms/icons'

const SelectBarContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    max-width: 600px;
    @media ${device.beyond_ipad_mobile} {
        display: flex;
    }

    display: none;
`

export type Props = {
    fetching: any
    value: any
    data: any
    fetchItem: any
    handleChange: any
    item?: any
    width?: string | undefined
    addAnItemFunc?: any
    dropdownTargetId?: string | undefined
    formCurrentValue?: string | undefined
    allowAdd?: boolean | undefined
    isDisabled?: boolean | undefined
    height?: string | undefined
    placeholder?: string | undefined
    helperText?: string
    dataCyId?: string | undefined
    customPlaceholder?: string
    sendId?: boolean
    sendDropdownItem?: boolean
    resetSearch?: any
    backgroundColor?: string
    customMaxWidth?: string
    borderColor?: string
    field_id?: string
    textTransform?: string
    customActiveColour?: string
    customHighlightColour?: string
}

const errorWarning = (errorText: string) => {
    message.error(errorText, 2)
}

const SingleSelectOnlyMatchedDesktop: React.FC<Props> = (props) => {
    const [valueTyped, setValueTyped] = useState<string>('')

    const handleSearch = (valueTyped: string) => {
        setValueTyped(valueTyped)
    }

    const {
        value,
        data,
        handleChange,
        fetchItem,
        item,
        addAnItemFunc,
        dropdownTargetId,
        formCurrentValue,
        allowAdd,
        isDisabled,
        placeholder,
        helperText,
        sendId,
        sendDropdownItem,
        resetSearch,
        customMaxWidth,
        borderColor,
        field_id,
        textTransform,
        customActiveColour,
        customHighlightColour,
    } = props

    const namesInResultsArray: string[] = []

    const options = data.map((di: any) => {
        let item = di.item

        if (item) {
            item.name &&
                namesInResultsArray.push(item.name.trim().toLowerCase())

            return {
                value: sendDropdownItem
                    ? item.uid
                    : sendId
                    ? item.uid
                    : item.uid &&
                      (field_id === 'make' ||
                          field_id === 'model' ||
                          field_id === 'car_model' ||
                          field_id === 'car_make')
                    ? item.uid
                    : item.name,
                label: item.name,
            }
        } else {
            di.name && namesInResultsArray.push(di.name.trim().toLowerCase())

            return {
                value: sendDropdownItem
                    ? di.uid
                    : sendId
                    ? di.uid
                    : di.uid &&
                      (field_id === 'make' ||
                          field_id === 'model' ||
                          field_id === 'car_model' ||
                          field_id === 'car_make')
                    ? di.uid
                    : di.name,
                label: di.name,
            }
        }
    })

    const cleanUpName = (name: string) => {
        if (name.includes('_')) {
            return name.replace(/_/g, ' ')
        } else return name
    }

    let valuee = sendDropdownItem
        ? value[value.length - 1]?.name
        : sendId && value[value.length - 1]
        ? cleanUpName(value[value.length - 1])
        : value[value.length - 1]

    return (
        <SelectBarContainer
            key={value}
            style={{ maxWidth: customMaxWidth ?? 600 }}
        >
            <Combobox
                sendDropdownItem={sendDropdownItem}
                backgroundColor={props.backgroundColor}
                customActiveColour={customActiveColour}
                customHighlightColour={customHighlightColour}
                borderColor={borderColor}
                defaultValue={item.answer ? item.answer : ''}
                disabled={isDisabled ? isDisabled : false}
                onChange={handleChange}
                options={data.length > 0 ? options : []}
                helperText={helperText}
                clearValueTyped={() => {
                    setValueTyped('')
                    resetSearch && resetSearch()
                }}
                field_id={field_id}
                onInputKeyDown={(e: any) => {
                    if (data.length === 0) {
                        if (
                            addAnItemFunc !== undefined &&
                            dropdownTargetId !== undefined &&
                            allowAdd
                        ) {
                            e = e || window.event
                            if (e.keyCode === 13) {
                                if (formCurrentValue) {
                                    if (formCurrentValue !== valueTyped) {
                                        if (valueTyped) {
                                            let trimmed_val =
                                                valueTyped.replace(/\s/g, '')

                                            if (
                                                trimmed_val.length >= 1 &&
                                                trimmed_val.length < 70
                                            ) {
                                                handleChange(valueTyped)
                                                addAnItemFunc(
                                                    dropdownTargetId,
                                                    valueTyped
                                                )
                                            } else {
                                                if (trimmed_val.length >= 70) {
                                                    errorWarning(
                                                        'Name must be less than 70 characters'
                                                    )
                                                } else {
                                                    errorWarning(
                                                        'Please enter a valid name.'
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }}
                fallbackContent={
                    allowAdd &&
                    valueTyped &&
                    valueTyped.length !== 0 &&
                    !value.includes(valueTyped) &&
                    !namesInResultsArray.includes(
                        valueTyped.trim().toLowerCase()
                    ) && (
                        <div
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                padding: 12,
                            }}
                        >
                            <ButtonAtom
                                onClick={(e: any) => {
                                    if (valueTyped) {
                                        let trimmed_val = valueTyped.replace(
                                            /\s/g,
                                            ''
                                        )
                                        if (
                                            trimmed_val.length >= 1 &&
                                            trimmed_val.length < 70
                                        ) {
                                            e.preventDefault()
                                            addAnItemFunc(
                                                dropdownTargetId,
                                                valueTyped
                                            )
                                            handleChange(valueTyped)
                                        } else {
                                            if (trimmed_val.length >= 70) {
                                                errorWarning(
                                                    'Name must be less than 70 characters'
                                                )
                                            } else {
                                                errorWarning(
                                                    'Please enter a valid name.'
                                                )
                                            }
                                        }
                                    }
                                }}
                                theme="modal-action-button-primary"
                                width="100%"
                            >
                                <div
                                    style={{
                                        textTransform: 'none',
                                        fontSize: 14,
                                        fontFamily: 'Lato-Semibold',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Icon icon="add_plus_white" />
                                    <span
                                        style={{
                                            textTransform: 'capitalize',
                                            paddingLeft: 8,
                                            paddingRight: 3,
                                        }}
                                    >
                                        {`Add ${valueTyped} `}
                                    </span>
                                    to our database
                                </div>
                            </ButtonAtom>
                        </div>
                    )
                }
                placeholder={placeholder}
                value={valuee}
                onSearch={(value) => {
                    fetchItem(value)
                    handleSearch(value)
                }}
                textTransform={textTransform}
            />
        </SelectBarContainer>
    )
}

export default SingleSelectOnlyMatchedDesktop
