import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import Faded from '../../../../templates/animated/faded'
import { motion } from 'framer-motion'
import { message, Upload } from 'antd'
import upload_grey from '../../../../../public/assets/icons/upload_grey.svg'
import upload_complete_green from '../../../../../public/assets/icons/upload_complete_green.svg'
import grey_bin from '../../../../../public/assets/icons/grey_bin.svg'
import { AttachmentTypesEnum } from '../../../../../redux/attachments/enums'
import { ConverFileTypeToFileSuffixEnum } from '../../../../../redux/conversions/attachments'
import { errorFileTypeWarning } from '../desktop/addAttachmentDesktop'
import AttachmentInItemSinglePageMobile from '../../../../atoms/timeline/attachmentInItemSinglePage/attachmentInItemSinglePageMobile'
import { convertToShorterString } from '../../../../../helpers/longStrings/convertToShorterString'
import { device } from '../../../../templates/displays/devices'
import './style.css'

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 280px;
    width: 100%;
    box-sizing: border-box;
    @media ${device.ipad} {
        padding-left: 25px;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    @keyframes scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
`

const NameOfFileUploaded = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: georgia;
    font-size: 14px;
    @media ${device.ipad} {
        justify-content: center;
    }
    color: var(--text-muted, #b3b3b3) !important;
`

const GreyBin = styled.img`
    width: 24px;
    @media ${device.ipad} {
        margin-left: 30px;
    }
`

interface Values {
    file: File | null
}

interface OtherProps {
    close: any
    deleteFileUploaded: any
    setFileUploaded: any
    currentFileUploaded: any
    user_id?: string
    progressPercent?: any
    isSomethingBeingUploaded?: boolean
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    initialValue: any
}

const CustomButton = styled.button<ProgressProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border: none;
    outline: 0;
    background-color: transparent;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const UploadIcon = styled.img<ProgressProps>`
    width: 120px !important;
    animation: fadeIn 300ms ease-in;
    ${(props) =>
        props.progressPercent === 100 &&
        'animation: fadeIn 300ms ease-in, scale 300ms ease-in-out; animation-delay: 0ms, 300ms;'}
`

const UploadIconContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const UploadingContainer = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 5px;
    background-color: var(--off-bg-color, #fafafa);
    @media ${device.ipad} {
        width: 100%;
        box-sizing: border-box;
    }
`

const WrapperContainer = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 5px;
    @media ${device.ipad} {
        width: 100%;
        box-sizing: border-box;
    }
`

type ProgressProps = {
    progressPercent: number
}

const ProgressBarImagesMobileParent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    animation: fadeIn 300ms ease-in;
    border-radius: 5px;
`

const ProgressBarImagesMobileChild = styled.div<ProgressProps>`
    border-radius: 50%;
    opacity: ${(props) => (props.progressPercent !== 100 ? 1 : 0)};
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    :before,
    :after {
        content: '';
        border: 2px rgba(0, 169, 175, 0.5) solid;
        border-radius: 50%;
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        position: absolute;
        left: 0px;
    }
    :before {
        transform: scale(1, 1);
        opacity: 1;
        -webkit-animation: spWaveBe 0.6s infinite linear;
        animation: spWaveBe 0.6s infinite linear;
    }
    :after {
        transform: scale(0, 0);
        opacity: 0;
        -webkit-animation: spWaveAf 0.6s infinite linear;
        animation: spWaveAf 0.6s infinite linear;
    }

    @keyframes spWaveAf {
        from {
            transform: scale(0.5, 0.5);
            opacity: 0;
        }
        to {
            transform: scale(1, 1);
            opacity: 1;
        }
    }

    @keyframes spWaveBe {
        from {
            -webkit-transform: scale(1, 1);
            opacity: 1;
        }
        to {
            -webkit-transform: scale(1.5, 1.5);
            opacity: 0;
        }
    }
`

const PercentageUploadTerxt = styled.span`
    font-family: Lato-bold;
    font-size: 16px;
    color: rgba(0, 169, 175, 0.5);
`

const CompletionDiv = styled.div`
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        setFileUploaded,
        currentFileUploaded,
        deleteFileUploaded,
        setFieldValue,
        progressPercent,
        initialValue,
    } = props

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const errorFileSizeWarning = () => {
        message.error({
            content: 'The maximum file size allowed per upload is 20MB.',
            duration: 2,
        })
    }

    function fileToArrayBuffer(file: any) {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader()
            const readFile = function (event: any) {
                const buffer = reader.result
                resolve(buffer)
            }
            reader.addEventListener('load', readFile)
            reader.readAsArrayBuffer(file)
        })
    }

    let handleChange = async (file: any) => {
        try {
            const fileContents: any = await fileToArrayBuffer(file)

            file.data = new Blob([fileContents])

            setFileUploaded(file)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <EditFormContainerMobile>
            {initialValue && !currentFileUploaded ? (
                <AttachmentInItemSinglePageMobile
                    attachment={initialValue}
                    onClickDelete={deleteFileUploaded}
                />
            ) : (
                !currentFileUploaded && (
                    <WrapperContainer>
                        <Container>
                            <motion.div
                                whileTap={{ scale: 1.05 }}
                                style={{ width: '100%' }}
                            >
                                <Upload
                                    className="upload_attachment_mobile"
                                    style={{ width: '100%' }}
                                    multiple={false}
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={(info: any) => {
                                        if (info.file.size <= 20000000) {
                                            let fs:
                                                | AttachmentTypesEnum
                                                | undefined =
                                                ConverFileTypeToFileSuffixEnum(
                                                    info.file.name
                                                )
                                            if (fs) {
                                                setFieldValue('file', info.file)

                                                setFieldValue(
                                                    'file_url',
                                                    info.file.name
                                                )

                                                handleChange(info.file)
                                            } else errorFileTypeWarning()
                                        } else errorFileSizeWarning()
                                    }}
                                    customRequest={dummyRequest}
                                    accept=".pdf, .jpg, .jpeg, .png, .xlsm, .xlsx, .xls, .doc, .docx, .heif, .heic"
                                >
                                    <UploadingContainer>
                                        <UploadIconContainer>
                                            <UploadIcon
                                                progressPercent={
                                                    progressPercent
                                                }
                                                src={upload_grey}
                                            />
                                        </UploadIconContainer>
                                    </UploadingContainer>
                                </Upload>
                            </motion.div>
                        </Container>
                    </WrapperContainer>
                )
            )}
            {currentFileUploaded && progressPercent > 0 && (
                <WrapperContainer>
                    <Container>
                        {progressPercent !== 100 && (
                            <CompletionDiv>
                                <ProgressBarImagesMobileParent>
                                    <ProgressBarImagesMobileChild
                                        progressPercent={
                                            progressPercent
                                                ? progressPercent
                                                : 0
                                        }
                                    >
                                        <PercentageUploadTerxt>
                                            {progressPercent}%
                                        </PercentageUploadTerxt>
                                    </ProgressBarImagesMobileChild>
                                </ProgressBarImagesMobileParent>
                            </CompletionDiv>
                        )}
                    </Container>

                    {currentFileUploaded && progressPercent === 100 && (
                        <Faded>
                            <Container>
                                <CustomButton progressPercent={progressPercent}>
                                    <UploadIcon
                                        progressPercent={progressPercent}
                                        src={upload_complete_green}
                                    />
                                </CustomButton>

                                <NameOfFileUploaded>
                                    <div
                                        style={{
                                            maxWidth: '70%',
                                            wordBreak: 'break-all',
                                            color: 'var(--text-muted, #B3B3B3)',
                                        }}
                                    >
                                        {currentFileUploaded &&
                                            currentFileUploaded.name &&
                                            convertToShorterString({
                                                string: currentFileUploaded.name,
                                                maxChars: 20,
                                                isFileName: true,
                                            })}
                                    </div>
                                    <GreyBin
                                        src={grey_bin}
                                        onClick={(e: any) => {
                                            e.preventDefault()
                                            // fake function

                                            deleteFileUploaded()
                                        }}
                                    />
                                </NameOfFileUploaded>
                            </Container>
                        </Faded>
                    )}
                </WrapperContainer>
            )}
        </EditFormContainerMobile>
    )
}

const AddAttachmentEditOrCreateForm = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        file: null,
    }),
    enableReinitialize: true,

    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        // let file = values.file
        // let presignedurl = props.presignedurls[0]
        // if (file !== undefined && presignedurl) {
        //
        //     let dataToSend: IUSerGeneralInfoUpdatePayload = {
        //         id: props.user_id,
        //         data: {
        //             profile_name: presignedurl.name,
        //         },
        //     }
        //     props.updateUserGeneralInfoRequest(dataToSend)
        //     // here req thunks to upload to S3
        //     props.close()
        // }
        // setSubmitting(false)
    },
})(InnerForm)

export default AddAttachmentEditOrCreateForm
