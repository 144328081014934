import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IHighlightedFact,
    IHighlightedFactsObject,
    IHighlightedFactsState,
    IUpdateHF,
} from 'entityModels'
import { IReduxError } from '../cars/types'

const initialState: IHighlightedFactsState = {
    highlighted_facts: {},
    error: null,
    loading: false,
}

const highlightedFactsSlice = createSlice({
    name: 'highlightedFacts',
    initialState,
    reducers: {
        getHighlightedFactsRequest: (state) => {
            state.loading = true
            state.error = null
        },

        getHighlightedFactsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        getHighlightedFactsSuccess: (
            state,
            action: PayloadAction<IHighlightedFactsObject>
        ) => {
            state.highlighted_facts = {
                ...state.highlighted_facts,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        updatetHighlightedFactsRequest: (
            state,
            action: PayloadAction<IUpdateHF>
        ) => {
            state.loading = true
            state.error = null
        },

        updatetHighlightedFactsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        updateHighlightedFactsSuccess: (
            state,
            action: PayloadAction<IHighlightedFact>
        ) => {
            state.highlighted_facts = {
                ...state.highlighted_facts,
                [action.payload.id]: action.payload,
            }
            state.loading = false
            state.error = null
        },

        setHighlightedFactsRequest: (state) => {
            state.loading = true
            state.error = null
        },

        setHighlightedFactsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        setHighlightedFactsSuccess: (
            state,
            action: PayloadAction<IHighlightedFactsObject>
        ) => {
            state.highlighted_facts = {
                ...state.highlighted_facts,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        cleanUpHighlightedFactsSuccess: (state) => {
            state.highlighted_facts = {}
            state.error = null
            state.loading = false
        },
    },
})

export const highlightedFactsActions = highlightedFactsSlice.actions

export default highlightedFactsSlice.reducer

export type HighlightedFactsState = ReturnType<
    typeof highlightedFactsSlice.reducer
>
