import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'
import {
    IApiGetAllEntriesByCarId_args,
    IFilterEntriesPayloadAPIReq,
    IApiGetEntryById_args,
} from '../../types'

export const getAllEntriesByCarId = (
    p: IApiGetAllEntriesByCarId_args
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.get_all_entries_by_carid_endpoint(p.car_id, p.user_currency),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get car timeline entries error'
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const getAllEntriesByCarIdAndCategoriesNLabelsNTitleQAPI = (
    p: IFilterEntriesPayloadAPIReq
) => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.get_all_entries_by_carid_and_category_and_labelsIds_and_TitleQ_endpoint(
            p
        ),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get filtered car timeline entries error'
                    )
                    return Promise.reject(response)
                }
                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const getEntryById = (p: IApiGetEntryById_args): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.get_entry_by_uid_endpoint(p.entry_id, p.car_id, p.user_currency),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(data, 'Get car timeline entry error')
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
