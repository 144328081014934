import { message } from 'antd'
import posthog from 'posthog-js'
import { call, fork, put, take, select } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { ConvertSharePermissionIDsToAPI } from '../../../conversions/shares/ConvertSharePermissionIds'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IRootState } from '../../../store'
import { IApi_UpdateDirectShare_args } from '../../../services/types'
import {
    IUpdateCarDirectShareRequest,
    ICarsObject,
    IUpdateCarDirectShareSuccess,
    IReduxError,
} from '../types'
import { carActions } from '../reducer'

const getCars = (state: IRootState) => state.entities.carsData.cars

export function* UpdateCarDirectShareSaga(p: IUpdateCarDirectShareRequest) {
    try {
        let api_payload: IApi_UpdateDirectShare_args = {
            share_id: p.share_id,
            title: p.title,
            policy_statements: p.policy_statements
                ? ConvertSharePermissionIDsToAPI(p.policy_statements)
                : undefined,
        }
        yield call(api.sharing.updateDirectShareAPI, api_payload)

        let cars_in_state: ICarsObject = yield select(getCars)

        let current_share_in_state =
            cars_in_state &&
            cars_in_state[p.car_id] &&
            cars_in_state[p.car_id].private_shares_owned &&
            cars_in_state[p.car_id].private_shares_owned
                ?.entity_owned_direct_shares_data &&
            cars_in_state[p.car_id].private_shares_owned
                ?.entity_owned_direct_shares_data[p.share_id]

        let stored_current_share_in_state = { ...current_share_in_state }

        if (current_share_in_state) {
            let successPayload: IUpdateCarDirectShareSuccess = {
                car_id: p.car_id,
                updated_share: {
                    ...current_share_in_state,
                    title: p.title,
                    active_permission_ids: p.policy_statements
                        ? p.policy_statements
                        : [],
                },
            }

            yield put(carActions.updateCarDirectShareSuccess(successPayload))

            // Capture event
            posthog.capture('UPDATE CAR DIRECT SHARE')

            const info = (messageText: string) => {
                message.info(messageText)
            }

            if (p.title && p.title !== stored_current_share_in_state.title) {
                if (
                    p.policy_statements &&
                    JSON.stringify(p.policy_statements) !==
                        JSON.stringify(
                            stored_current_share_in_state.active_permission_ids
                        )
                ) {
                    info(`Share title and permissions have been updated.`)
                } else {
                    info(`Share title has been updated.`)
                }
            } else if (
                p.policy_statements &&
                JSON.stringify(p.policy_statements) !==
                    JSON.stringify(
                        stored_current_share_in_state.active_permission_ids
                    )
            ) {
                info(`Share permissions have been updated.`)
            }
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: UpdateCarDirectShareSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(carActions.updateCarDirectShareError(typedError))
            return
        }
    }
}

function* watcherUpdateCarDirectShare() {
    while (true) {
        let { payload } = yield take(carActions.updateCarDirectShareRequest)
        yield call(UpdateCarDirectShareSaga, payload)
    }
}

const update_car_direct_share_sagas: any[] = [fork(watcherUpdateCarDirectShare)]

export default update_car_direct_share_sagas
