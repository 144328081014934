import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'
import { IAddLabelToEntryAPIPayload } from '../../typedefinitions/apiPayloads'

export const addLabelToEntry = (
    payload: IAddLabelToEntryAPIPayload
): Promise<any> => {
    let req_body: string = JSON.stringify(
        (({ label_names }) => ({ label_names }))(payload)
    )

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: req_body,
        credentials: 'include',
    }

    return fetch(
        urls.add_label_to_entry_endpoint(payload.entry_id, payload.car_id),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Add entry label error',
                        req_body
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
