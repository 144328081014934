import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { IHighlightedFact, IUpdateHF } from 'entityModels'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import KeyMomentCardDesktop from '../../../molecules/cards/keyFacts/keyMomentCardDesktop'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import CarHighlightsEditFormJourney from '../../editForms/carHighlightsEditForm/carHighlightsEditFormJourney'
import JourneyWrapperLeftSideDesktop from '../../../templates/wrappers/journeyPageWrapperLeftDesktop'
import JourneyPageWrapperMobile from '../../../templates/wrappers/journeyPageWrapperMobile'
import { ICarsObject } from '../../../../redux/entities/cars/types'
import { IGalleryImagesObject } from '../../../../redux/entities/galleries/types'
import { IUser } from '../../../../redux/user/types'
import { highlightedFactsActions } from '../../../../redux/entities/highlighted_facts/reducer'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: (carid: string) => void
    goToNextStep: (carid: string) => void
    carsData?: ICarsObject
    garageCars?: string[]
    galleryImages?: IGalleryImagesObject | null
    user: IUser | null
}

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`

const RightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow-y: hidden;
`

const ColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`

const EnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

const RowContent = styled.div`
    display: flex;
    align-items: center;
`

const HorizontalScroll = styled.div`
    display: flex;
    align-items: center;
    padding-left: 120px;
    padding-right: 120px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow-x: auto;

    :hover {
        /* Track */
        ::-webkit-scrollbar-track {
            background: #fff;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #dddddd;
        }
    }

    /* width */
    ::-webkit-scrollbar {
        height: 8px;
        border-radius: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 20px;
    }
`

const IconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

export default function JourneyCarProfileKMF({
    carsData,
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    let params = new URLSearchParams(location.search)
    let carid: string | null = params.get('carid')

    const highlighted_facts_data = useAppSelector(
        (state) => state.entities.highlightedFactsData.highlighted_facts
    )
    const highlighted_facts_ids =
        carsData && carid && carsData[carid]?.highlighted_facts
            ? carsData[carid].highlighted_facts
            : []

    let initial_kmf_order: { id: string; text: string; order: number }[] =
        highlighted_facts_ids.map((id, i) => {
            return {
                id: id,
                text: highlighted_facts_data[id].text,
                order: i + 1,
            }
        })

    const [kmfState, setKmfState] = useState<{ [key: string]: any }>({
        new_ordered_array: [...initial_kmf_order],
    })

    const submitAndGoToNextStep = () => {
        if (carid) {
            if (kmfState) {
                onSubmit(kmfState, carid)
            }
            goToNextStep(carid)
        }
    }

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            submitAndGoToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)
        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [kmfState, step, carid])

    const dispatch = useAppDispatch()
    const updatetHighlightedFacts = (data: IUpdateHF) =>
        dispatch(highlightedFactsActions.updatetHighlightedFactsRequest(data))

    useEffect(() => {
        highlighted_facts_ids.forEach((id) => {
            setFieldValue(id, highlighted_facts_data[id].text)
        })
    }, [highlighted_facts_ids, highlighted_facts_data])

    const setFieldValue = (id: string, value: any) =>
        setKmfState((prevstate) => {
            return {
                ...prevstate,
                [id]: value,
            }
        })

    const onSubmit = (payload: { [key: string]: any }, car_id: string) => {
        if (payload.new_ordered_array !== undefined) {
            let new_array_with_order_change: IHighlightedFact[] = []
            payload.new_ordered_array.forEach(
                (item: IHighlightedFact, index: any) => {
                    let newItem: IHighlightedFact = {
                        id: item.id,
                        text: payload[item.id]
                            ? payload[item.id].length < 200
                                ? payload[item.id]
                                : ''
                            : '',
                        order: index + 1,
                    }
                    new_array_with_order_change.push(newItem)
                }
            )

            let new_array_with_order_change_complete = [
                ...new_array_with_order_change,
            ]

            initial_kmf_order.forEach((item, i) => {
                if (
                    new_array_with_order_change.findIndex(
                        (el) => el.id === item.id
                    ) === -1
                ) {
                    let newItem: IHighlightedFact = {
                        id: item.id,
                        text: payload[item.id]
                            ? payload[item.id].length < 200
                                ? payload[item.id]
                                : ''
                            : '',
                        order: i + 1,
                    }
                    new_array_with_order_change_complete.push(newItem)
                }
            })

            if (new_array_with_order_change.length > 0 && car_id) {
                updatetHighlightedFacts({
                    carid: car_id,
                    highlightedFacts: new_array_with_order_change_complete,
                })
            }
        }
    }

    let idsToDisplay: { id: string; text: string; order: number }[] =
        kmfState[`new_ordered_array`]

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <Wrapper>
                        <JourneyWrapperLeftSideDesktop
                            title={'Add 3 Key Moments & Facts'}
                            sectionID="car_profile"
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={() => {
                                carid && goToPrevStep(carid)
                            }}
                        >
                            {carid ? (
                                <CarHighlightsEditFormJourney
                                    data_object={highlighted_facts_data}
                                    ids_list={
                                        idsToDisplay?.length > 0
                                            ? idsToDisplay
                                                  .map((item) => item.id)
                                                  .slice(0, 3)
                                            : highlighted_facts_ids.slice(0, 3)
                                    }
                                    carid={carid}
                                    values={kmfState}
                                    setFieldValue={(
                                        field: string,
                                        value: any
                                    ) => {
                                        setFieldValue(field, value)
                                    }}
                                    handleChange={(e: any) => {
                                        setFieldValue(
                                            e.target.id,
                                            e.target.value
                                        )
                                    }}
                                />
                            ) : null}

                            <RowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={submitAndGoToNextStep}
                                    isDisabled={!carid}
                                    bg={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>
                                {carid ? (
                                    <RowContent style={{ gap: 8 }}>
                                        <IconWrapper $theme={theme}>
                                            <EnterIcon />
                                        </IconWrapper>{' '}
                                        <EnterTxt $theme={theme}>
                                            Or press Enter
                                        </EnterTxt>
                                    </RowContent>
                                ) : null}
                            </RowContent>
                        </JourneyWrapperLeftSideDesktop>
                        <RightSide>
                            <HorizontalScroll>
                                <RowContent style={{ gap: 24 }}>
                                    {idsToDisplay.map(
                                        (
                                            item: {
                                                id: string
                                                text: string
                                                order: number
                                            },
                                            i: number
                                        ) =>
                                            i < 3 ? (
                                                <KeyMomentCardDesktop
                                                    obj={{
                                                        id: item.id,
                                                        text: kmfState[item.id],
                                                        order: i + 1,
                                                    }}
                                                    isLast={false}
                                                    isJourney
                                                />
                                            ) : null
                                    )}
                                </RowContent>
                            </HorizontalScroll>
                        </RightSide>
                    </Wrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyPageWrapperMobile
                    title={'Add 3 Key Moments & Facts'}
                    sectionID="car_profile"
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        carid && goToPrevStep(carid)
                    }}
                    onNext={submitAndGoToNextStep}
                >
                    <ColumnContent
                        style={{ paddingLeft: 24, paddingRight: 24 }}
                    >
                        {carid ? (
                            <CarHighlightsEditFormJourney
                                data_object={highlighted_facts_data}
                                ids_list={
                                    idsToDisplay?.length > 0
                                        ? idsToDisplay
                                              .map((item) => item.id)
                                              .slice(0, 3)
                                        : highlighted_facts_ids.slice(0, 3)
                                }
                                carid={carid}
                                values={kmfState}
                                setFieldValue={(field: string, value: any) => {
                                    setFieldValue(field, value)
                                }}
                                handleChange={(e: any) => {
                                    setFieldValue(e.target.id, e.target.value)
                                }}
                                isMobile={true}
                            />
                        ) : null}
                    </ColumnContent>
                </JourneyPageWrapperMobile>
            </IpadAndMobileDisplay>
        </div>
    )
}
