import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { fileStorageActions } from '../../../../redux/filestorage/reducer'
import * as urls from '../../../../redux/services/urls'
import ReusableGalleryImageEditFormMobile from '../carGalleryEditForm/reusableGalleryImageEditFormMobile'
import { IUploadImagePreSignedUrlPayloadSingle } from '../../../../redux/filestorage/types'

interface Props {
    close: any
    carid: string
    entryid?: string
    isOnboarding?: boolean
    step?: number
}

const WrapperImageFormsMobile: React.FC<Props> = ({
    close,
    carid,
    entryid,
}) => {
    let gallerypresignedurls = useAppSelector(
        (state) => state.fileStorage.gallerypresignedurls
    )

    const dispatch = useAppDispatch()

    // car images

    let carPresignedUrlApi = (fileName: string) =>
        urls.upload_image_car_gallery_type_endpoint(carid, 1, [fileName])

    const storeCarPresignedUrlsInRedux = (
        presignedUrl: IUploadImagePreSignedUrlPayloadSingle
    ) => {
        if (presignedUrl.original_filename) {
            dispatch(
                fileStorageActions.getPresignedUrlsCarGalleryImagesSuccess({
                    [presignedUrl.original_filename]: presignedUrl,
                })
            )
        }
    }

    const submitCarImages = (values: {
        caption: string
        location: string
        credits: string
        uploadedFiles: File[] | undefined
    }) => {
        let files = values.uploadedFiles
        if (files !== undefined && gallerypresignedurls && carid) {
            dispatch(
                fileStorageActions.submitCarGalleryImagesFilestorageRequest({
                    carid: carid,
                    gallerypresignedurls: gallerypresignedurls,
                    files: files,
                    fields: {
                        caption: values.caption,
                        credit: values.credits,
                        location: values.location,
                    },
                    isFromQuickActions: false,
                })
            )
        }
    }

    // history file entry images

    const storeEntryPresignedUrlsInRedux = (
        presignedUrl: IUploadImagePreSignedUrlPayloadSingle
    ) => {
        if (presignedUrl.original_filename) {
            dispatch(
                fileStorageActions.getPresignedUrlsEntryGalleryImagesSuccess({
                    [presignedUrl.original_filename]: presignedUrl,
                })
            )
        }
    }

    const submitEntryImages = (values: {
        caption: string
        location: string
        credits: string
        uploadedFiles: File[] | undefined
    }) => {
        let files = values.uploadedFiles
        if (files !== undefined && gallerypresignedurls && carid && entryid) {
            dispatch(
                fileStorageActions.submitEntryGalleryImagesFilestorageRequest({
                    carid: carid,
                    entryid: entryid,
                    gallerypresignedurls: gallerypresignedurls,
                    files: files,
                    fields: {
                        caption: values.caption,
                        credit: values.credits,
                        location: values.location,
                    },
                    isFromQuickActions: false,
                })
            )
            close()
        }
    }

    let entryPresignedUrlApi = (fileName: string) =>
        urls.upload_image_entry_gallery_type_endpoint(entryid ?? '', carid, 1, [
            fileName,
        ])

    return (
        <div>
            {!entryid && (
                <ReusableGalleryImageEditFormMobile
                    close={close}
                    submitFiles={submitCarImages}
                    getPresignedUrlApi={carPresignedUrlApi}
                    storePresignedUrl={storeCarPresignedUrlsInRedux}
                />
            )}

            {entryid && (
                <ReusableGalleryImageEditFormMobile
                    close={close}
                    submitFiles={submitEntryImages}
                    getPresignedUrlApi={entryPresignedUrlApi}
                    storePresignedUrl={storeEntryPresignedUrlsInRedux}
                />
            )}
        </div>
    )
}

export default WrapperImageFormsMobile
