import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_OtherDriver,
    insuranceActions,
} from './../../reducer'

import { gen_draft_differing_values_to_patch } from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { api } from '../../../services'
import {
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_licenceAddress_submit'
import { gen_address_insurance_entity_patch } from '../../../conversions/insuranceApplication/addressObj'
import { IOtherDriverID } from './otherdriver_trackrecord_submit'
import { ValidateAndAddStatsToApplication } from '../application/get'
import posthog from 'posthog-js'
import { message } from 'antd'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'

export function* Submit_otherdriver_licenceAddress_(p: IOtherDriverID): any {
    const { driver_id } = p

    posthog.capture('Update insurance application named driver licence address')

    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft: IInsuranceQuoteApplication_OtherDriver = {
            ...all_draft.other_drivers[driver_id],
        }
        let existing: IInsuranceQuoteApplication_OtherDriver = {
            ...all_existing.other_drivers[driver_id],
        }

        let dataToSendToPatch: IAnyObject | undefined = undefined

        if (draft.additional_details) {
            dataToSendToPatch = gen_draft_differing_values_to_patch({
                existing: existing.licence_address ?? {},
                draft: draft.licence_address ?? {},
            })
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            // console.log('data to patch', dataToSendToPatch)
            // call api to patch as thats all the data merged we want to patch

            let addresses_fields = [
                'residential_address',
                'correspondence_address',
            ]
            // check if NEW address inside of draft
            // in which case we do not include UID,
            // and we include the full address object:

            // isolate the address fields.
            dataToSendToPatch = {
                ...gen_address_insurance_entity_patch({
                    data: dataToSendToPatch,
                    addresses_fields: addresses_fields,
                    draft_addresses_normalised: all_draft.addresses,
                }),
            }

            dataToSendToPatch = {
                customer: {
                    named_drivers: [
                        {
                            uid: driver_id,
                            ...dataToSendToPatch,
                        },
                    ],
                },
            }

            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...dataToSendToPatch },
                }
            )

            if (p.isCrossForm) {
                p.message && message.success(p.message)
            }

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )

            yield put(
                insuranceActions.submit_otherDriver_licenceAddress_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(
                insuranceActions.submit_otherDriver_licenceAddress_success()
            )
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_otherdriver_licenceAddress_,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/other_driver/${p.driver_id}/licence_address`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_otherDriver_licenceAddress_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Submit_otherdriver_licenceAddress_() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.submit_otherDriver_licenceAddress_request
        )
        yield call(Submit_otherdriver_licenceAddress_, payload)
    }
}

const otherdriver_licenceAddress__submit: any[] = [
    fork(Watcher_Submit_otherdriver_licenceAddress_),
]

export default otherdriver_licenceAddress__submit
