import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import './styles.css'
import 'react-medium-image-zoom/dist/styles.css'
import { Link, useNavigate } from 'react-router-dom'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import GalleryImChevron from '../../atoms/icons/GalleryImgChevron'
import { LocationIcon } from '../../atoms/icons/components'
import {
    IGalleryImage,
    IGalleryImagesObject,
} from '../../../redux/entities/galleries/types'
import ZoomWrapper from './zoomWrapper'

const SlideWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 2px;
    height: 100%;
    max-height: 80vh;

    @media (max-width: 1180px) {
        margin-top: 160px;
        width: 68vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1180px) and (max-width: 1295px) {
        width: 64vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1295px) and (max-width: 1400px) {
        width: 55vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1400px) and (max-width: 1550px) {
        width: 54vw;
        max-width: 1100px;
        height: 75vh;
        max-height: 550px;
    }

    @media (min-width: 1550px) and (max-width: 1600px) {
        width: 1200px;
        height: 905px;
    }

    @media (min-width: 1600px) and (max-width: 1810px) {
        width: 1300px;
        height: 1000px;
    }

    @media (min-width: 1810px) {
        width: 1492px;
        height: 1100px;
        max-height: 70vh;
    }
`

const SlideImageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    background: var(--off-bg-color, #fafafa);

    @media (max-width: 1180px) {
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1180px) and (max-width: 1295px) {
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1295px) and (max-width: 1400px) {
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1400px) and (max-width: 1550px) {
        height: 75vh;
        max-height: 550px;
    }

    @media (min-width: 1550px) and (max-width: 1600px) {
        height: 905px;
    }

    @media (min-width: 1600px) and (max-width: 1810px) {
        height: 1000px;
    }

    @media (min-width: 1810px) {
        height: 1100px;
        max-height: 70vh;
    }
`

const SlideImage = styled.img`
    display: block;
    max-width: 100%;
    box-sizing: border-box;
    max-height: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    object-fit: contain;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
`

const TextBox = styled.div`
    z-index: 6;
    width: 100%;
    box-sizing: border-box;
    height: max-content;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    font-family: Lato;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: max-content;
    padding-right: 30px;
    text-align: left !important;
    color: black;
    font-size: 13px;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }
`

const CaptionCol = styled.div`
    font-family: Lato;
    padding-right: 10px;
    min-width: 230px;
    padding-left: 20px;

    font-size: 12px;

    @media (max-width: 1450px) {
        font-size: 11px;
    }

    @media ${device.large_desktop} {
        font-size: 14px;
    }
`

const Line = styled.div`
    height: 50px;
    justify-self: center !important;
    width: 1px;
    background-color: #b3b3b3;
`

const NextBlock = styled(Link)`
    position: absolute;
    right: -130px;

    @media (max-width: 1450px) {
        right: -100px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        right: -100px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
    z-index: 1;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`
const NextBlockNotLink = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 10vw;
    cursor: alias;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 50px;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
    opacity: 0.5;
`

const PrevBlock = styled(Link)`
    position: absolute;
    left: -120px;

    @media (max-width: 1450px) {
        left: -85px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        left: -94px;
    }
    height: 50px;
    width: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`
const PrevBlockNotLink = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 10vw;
    cursor: alias;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 50px;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;
    opacity: 0.5;
`
const PaddingSpan = styled.span`
    padding-left: 4px;
    color: #1a1a1a;
    font-size: 12px;
`

const SpanNoPadding = styled.span`
    color: #1a1a1a;
    font-size: 12px;
`

interface IProps {
    currentImageData: IGalleryImage
    closeGallery: () => void
    generateRequestedSlide?: () => void
    imagesData: IGalleryImagesObject
    isSomethingLoading: boolean
    prevUrl: string
    nextUrl: string
}

const ShowroomGallerySlide: React.FC<IProps> = ({
    currentImageData,
    closeGallery,
    generateRequestedSlide,
    imagesData,
    isSomethingLoading,
    prevUrl,
    nextUrl,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const genUrl = (e?: KeyboardEvent) => {
        if (e) {
            e = e || window.event

            if (e.keyCode === 37 && prevUrl !== '/none') {
                navigate(prevUrl)
            } else if (e.keyCode === 39 && nextUrl !== '/none') {
                navigate(nextUrl)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', genUrl)

        return () => {
            document.removeEventListener('keydown', genUrl)
        }
    }, [prevUrl, nextUrl])

    return (
        <div style={{ position: 'fixed' }}>
            <SlideWrapper>
                {nextUrl !== '/none' ? (
                    <NextBlock to={nextUrl}>
                        <GalleryImChevron />
                    </NextBlock>
                ) : (
                    <NextBlockNotLink>
                        <GalleryImChevron />
                    </NextBlockNotLink>
                )}

                {prevUrl !== '/none' ? (
                    <PrevBlock to={prevUrl}>
                        <GalleryImChevron direction="left" />
                    </PrevBlock>
                ) : (
                    <PrevBlockNotLink>
                        <GalleryImChevron direction="left" />
                    </PrevBlockNotLink>
                )}

                <SlideImageBox>
                    <ZoomWrapper
                        percentage={90}
                        backgroundColor="var(--bg-color, #fff)"
                    >
                        <div
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                height: ' 100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {isLoading && (
                                <SkeletonAbsolute
                                    isthingloading={isLoading}
                                    darkTheme={false}
                                />
                            )}
                            <SlideImage
                                src={grey_placeholder}
                                srcSet={currentImageData?.large_srcset}
                                onLoad={() => setIsLoading(false)}
                                alt="car"
                            />
                        </div>
                    </ZoomWrapper>
                </SlideImageBox>
                <TextBox>
                    <Row>
                        <Row>
                            <Col>
                                <div
                                    style={{
                                        paddingBottom: '3px',
                                        color: '#1A1A1A',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {currentImageData?.uploader || ''}
                                </div>

                                <Row>
                                    ©
                                    <PaddingSpan>
                                        {currentImageData?.credits || 'Unknown'}
                                    </PaddingSpan>
                                </Row>

                                <Row>
                                    <div
                                        style={{
                                            transform: 'translate(-1px, 1px)',
                                        }}
                                    >
                                        <LocationIcon size="14" />
                                    </div>
                                    <SpanNoPadding>
                                        {currentImageData?.location ||
                                            'Unknown'}
                                    </SpanNoPadding>
                                </Row>
                            </Col>
                        </Row>
                        <Line />
                        <CaptionCol style={{ width: '78%' }}>
                            {currentImageData?.caption || ''}
                        </CaptionCol>
                    </Row>
                </TextBox>
            </SlideWrapper>
        </div>
    )
}

export default ShowroomGallerySlide
