import * as React from 'react'
import CarBannerImageEditFormDesktop from '../carGalleryEditForm/carBannerImageEditFormDesktop'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import axios from 'axios'

interface Props {
    close: any
    carid: string
    isOnboarding?: boolean
    display_img_list?: string[]
    step?: number
}

const WrapperCarBannerImageFormDesktop: React.FC<Props> = ({
    close,
    carid,
}) => {
    let presignedurls = useAppSelector(
        (state) => state.fileStorage.presignedurls
    )
    // const [imageUploadedData, setImageUploadedData] = useState<any | null>(null)
    const [isImageCropperShown, setIsImageCropperShown] = useState(false)
    const [progressPercent, setProgressPercent] = useState<number | null>(null)
    const [changeInPresignedURL, setChangeInPresignedURL] = useState(false)
    // const [presignedUrlsState, setPresignedUrlsState] = useState<any>(undefined)
    const [file, setFile] = useState<File | undefined>(undefined)
    const [isSomethingBeingUploaded, setIsSomethingBeingUploaded] =
        useState(true)

    useEffect(() => {
        if (presignedurls !== undefined && presignedurls !== null) {
            if (presignedurls[0]) {
                if (file !== undefined) {
                    uploadPresignedUrlImageToS3(file)
                }
                // setPresignedUrlsState(presignedurls)
                setChangeInPresignedURL(!changeInPresignedURL)
            }
        }
    }, [presignedurls])

    const deleteImageUploaded = () => {
        setFile(undefined)
        setProgressPercent(null)
    }

    const toggleShowImageCropper = (res?: boolean) => {
        setIsImageCropperShown(res !== undefined ? res : !isImageCropperShown)
    }

    const uploadPresignedUrlImageToS3 = async (stateFile: File) => {
        if (
            stateFile !== undefined &&
            presignedurls !== undefined &&
            presignedurls !== null &&
            presignedurls[0]
        ) {
            try {
                await axios.request({
                    method: 'put',
                    url: presignedurls[0].url,
                    data: stateFile,
                    onUploadProgress: (p: any) => {
                        setProgressPercent((p.loaded / p.total) * 100)
                    },
                })

                setIsSomethingBeingUploaded(false)
            } catch (e: any) {
                apiSentryErrorHandler(
                    e,
                    'Upload desktop presigned url car banner image to s3 error'
                )
                return Promise.reject(e)
            }
        }
    }

    const handleFilesGivenInWrapperComponent = (file: File | undefined) => {
        setFile(file)
    }

    const dispatch = useAppDispatch()

    return (
        <div>
            <CarBannerImageEditFormDesktop
                close={close}
                deleteImageUploaded={deleteImageUploaded}
                setImageUploaded={handleFilesGivenInWrapperComponent}
                currentImagesUploaded={file}
                car_id={carid}
                progressPercent={progressPercent}
                isSomethingBeingUploaded={isSomethingBeingUploaded}
                toggleShowImageCropper={toggleShowImageCropper}
                isImageCropperShown={isImageCropperShown}
                dispatch={dispatch}
                presignedurls={presignedurls}
            />
        </div>
    )
}

export default WrapperCarBannerImageFormDesktop
