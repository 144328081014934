import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import bg from '../../../../public/assets/placeholder/carInsights/mobile_bg.jpg'
import entries_mobile from '../../../../public/assets/placeholder/carInsights/entries_mobile.png'
import entries_mobile_dark from '../../../../public/assets/placeholder/carInsights/entries_mobile_dark.png'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/store/hooks'
import useThemes from '../../../../providers/theme/hooks'

const Wrapper = styled.div`
    display: none;

    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        min-height: 120vh;
    }
`

const BgImg = styled.img`
    width: 100%;
    box-sizing: border-box;
`

const Images2 = styled.img`
    width: 150vw;
`

const WrapperImagesAbsolute = styled.div`
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    top: 28vh;

    @media (max-width: 400px) {
        top: 36vh;
    }

    @media (min-width: 500px) {
        top: 10vh;
    }

    @media (min-width: 680px) {
        top: -50px;
    }
`

const WrapBtn = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    z-index: 1;
    max-width: 450px;

    @media (max-width: 500px) {
        margin-top: 24px;
    }
`

const Title = styled.div`
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 24px;
`

const Paragraph = styled.div`
    color: var(--text-darker, #616161);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const CarInsightsMobilePlaceholder = () => {
    const navigate = useNavigate()
    const carID = useAppSelector((state) => {
        return state.entities.carsData.currentCarID
    })

    const { theme } = useThemes()

    return (
        <Wrapper>
            <WrapBtn>
                <Title>No Expenses to Show Yet</Title>
                <Paragraph>
                    Looks like we don't have any expense data for this car yet.
                    Once you've added some detailed history file entries with
                    costs included, you'll see a comprehensive breakdown of your
                    expenses here.
                </Paragraph>

                <div style={{ paddingTop: '12px' }} />
                <ButtonAtom
                    theme="lowercase-blue-background"
                    textTransform="none"
                    height="48px"
                    onClick={() => {
                        navigate(`/car/${carID}/history-file/create`)
                    }}
                >
                    Create History File Now
                </ButtonAtom>
            </WrapBtn>

            <WrapperImagesAbsolute>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignSelf: 'stretch',
                    }}
                >
                    <div
                        style={
                            theme === 'dark'
                                ? {
                                      position: 'absolute',
                                      top: 0,
                                      height: '100%',
                                      width: '100%',
                                      boxSizing: 'border-box',
                                      background:
                                          'linear-gradient(rgb(18, 18, 18), rgba(18, 18, 18, 0.5))',
                                  }
                                : {}
                        }
                    />
                    <BgImg src={bg} />
                </div>
                <div style={{ transform: 'translateY(-90px)' }}>
                    <Images2
                        src={
                            theme === 'dark'
                                ? entries_mobile_dark
                                : entries_mobile
                        }
                    />
                </div>
            </WrapperImagesAbsolute>
        </Wrapper>
    )
}

export default CarInsightsMobilePlaceholder
