import React, { useState, useCallback } from 'react'
import Fuse, { FuseResult } from 'fuse.js'
import { debounce } from 'lodash'
import FilterByMultipleWithSearchUIDesktop from './filterTimelineByMultipleWSearchUIDesktop'

export type Props = {
    user_searchable_labels_list: any
    searchFor?: any
    addTagFilter?: any
    addCategoryFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    issearchactive?: boolean | undefined
    addLabelButton?: boolean
    onSave?: (labels: string[]) => any
    hasCreateNewLabel?: boolean
    selectedFilteringLabels: string[]
    dataCyIdSave?: string
}

const FilterTimelineByMultipleWSearchWFuse: React.FC<Props> = ({
    dataCyId,
    addLabelButton,
    user_searchable_labels_list,
    onSave,
    hasCreateNewLabel,
    selectedFilteringLabels,
    dataCyIdSave,
}) => {
    const [state, setState] = useState<{
        data: any[]
        categoryData: any[]
        labelsDataRes: FuseResult<any>[]
        name: any[]
        fetching: boolean
        lastFetchId: number
    }>({
        data: [],
        categoryData: [],
        labelsDataRes: [],
        name: [],
        fetching: false,
        lastFetchId: 0,
    })

    const fetchItem = useCallback(
        debounce((name: any) => {
            const nextFetchId = state.lastFetchId + 1

            setState((prev) => ({
                ...prev,
                data: [],
                fetching: true,
                lastFetchId: nextFetchId,
            }))

            if (nextFetchId !== state.lastFetchId + 1) {
                return
            }

            const fuseLabels = new Fuse(user_searchable_labels_list, {
                keys: ['name'],
            })

            const resultsLabel = fuseLabels.search(name, { limit: 2 })

            setState((prev) => ({
                ...prev,
                labelsDataRes: resultsLabel,
                fetching: false,
            }))
        }, 180),
        [user_searchable_labels_list]
    )

    const handleChange = (name: any) => {
        setState((prev) => ({
            ...prev,
            name: [name],
            data: [],
            categoryData: [],
            labelsDataRes: [],
            fetching: false,
        }))
    }

    const clearField = () => {
        setState((prev) => ({
            ...prev,
            name: [],
            data: [],
            categoryData: [],
            labelsDataRes: [],
            fetching: false,
        }))
    }

    return (
        <FilterByMultipleWithSearchUIDesktop
            dataCyId={dataCyId}
            user_searchable_labels_list={user_searchable_labels_list}
            value={state.name}
            handleChange={handleChange}
            fetchItem={fetchItem}
            labelsDataRes={state.labelsDataRes}
            addLabelButton={addLabelButton}
            onSave={onSave}
            hasCreateNewLabel={hasCreateNewLabel}
            clearField={clearField}
            selectedFilteringLabels={selectedFilteringLabels}
            dataCyIdSave={dataCyIdSave}
        />
    )
}

export default FilterTimelineByMultipleWSearchWFuse
