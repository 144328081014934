import styled from 'styled-components'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { RangeFieldsValidationSchema } from './validationSchema'
import Expander from '../../../atoms/expander/expander'
import { ErrorText } from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerDesktop'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { IFilterID } from '../../../../redux/showroom/types'
import { useEffect, useState } from 'react'

type Props = {
    filterid: IFilterID
    valueFrom?: number | null
    valueTo?: number | null
    onChange: (
        valueFrom: number | null | undefined,
        valueTo: number | null | undefined
    ) => any
    customWidth?: string
    labelFrom?: string
    labelTo?: string
    rangeMin?: number
    rangeMax?: number
}
const Row = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
`

export const WarningTxt = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 4px;
    font-size: 11px;
    color: #5c5b5b;
    font-style: italic;
`

const RangeRowFieldsMobile = (props: Props) => {
    let { filterid, onChange, valueFrom, valueTo, rangeMin, rangeMax } = props

    const dispatch = useAppDispatch()
    const filterValidationErrorObject = useAppSelector(
        (state) => state.showroom.filterValidationError
    )

    let error =
        filterValidationErrorObject &&
        filterid &&
        filterValidationErrorObject[filterid] &&
        filterValidationErrorObject[filterid]?.error

    const [warning, setWarning] = useState<undefined | string>(undefined)

    const validateRangeFields = async (
        min: number | null | undefined,
        max: number | null | undefined
    ) => {
        if (warning) {
            setWarning(undefined)
        }
        if (min && rangeMin && min < Math.floor(rangeMin / 1000) * 1000) {
            setWarning(
                `Warning: the minimum value currently found on the platform is ${
                    filterid === 'year'
                        ? rangeMin
                        : new Intl.NumberFormat('en-GB', {
                              style:
                                  filterid === 'price' ? 'currency' : undefined,
                              currency:
                                  filterid === 'price' ? 'GBP' : undefined,
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                          }).format(Math.floor(rangeMin / 1000) * 1000)
                }`
            )
        }

        if (max && rangeMax && max > Math.ceil(rangeMax / 1000) * 1000) {
            setWarning(
                `Warning: the maximum value currently found on the platform is ${
                    filterid === 'year'
                        ? rangeMax
                        : new Intl.NumberFormat('en-GB', {
                              style:
                                  filterid === 'price' ? 'currency' : undefined,
                              currency:
                                  filterid === 'price' ? 'GBP' : undefined,
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                          }).format(Math.ceil(rangeMax / 1000) * 1000)
                }`
            )
        }

        try {
            await RangeFieldsValidationSchema.validate({
                min: min,
                max: max,
            })
            dispatch(
                showroomActions.setFilterValidationError({
                    id: filterid,
                    error: undefined,
                })
            )
        } catch (err: any) {
            dispatch(
                showroomActions.setFilterValidationError({
                    id: filterid,
                    error: err.errors[0],
                })
            )
        }
    }

    useEffect(() => {
        validateRangeFields(valueFrom, valueTo)
    }, [valueFrom, valueTo, rangeMax, rangeMin])

    // we don't need to cleanup errors on onmount
    // because on mobile multiple filters are displayed
    // on same page so we need to identify
    // which filters have validation problems and not send them
    // in the search request to BE in case a different page is mounted
    // example: while being on the filters page, user wants to select years so it navigates to search page
    // and after selecting years, he clicks 'Apply filters' so all filters defined in edit filters
    // will be bundeled in the request
    // unless we cleanup filters that have errors

    return (
        <div>
            <Row>
                <InputFieldNoFormikAnimated
                    name={props.labelFrom ?? 'From'}
                    placeholder={
                        filterid === 'year' && rangeMin
                            ? `${rangeMin}`
                            : filterid !== 'price' &&
                              filterid !== 'year' &&
                              rangeMin
                            ? `${new Intl.NumberFormat('en-GB', {
                                  // maximumFractionDigits: 2,
                              }).format(Math.ceil(rangeMin))}`
                            : rangeMin && filterid === 'price'
                            ? `${new Intl.NumberFormat('en-GB', {
                                  style: 'currency',
                                  currency: 'GBP',
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                              }).format(Math.ceil(rangeMin / 1000) * 1000)}`
                            : 'From'
                    }
                    value={
                        valueFrom ? `${valueFrom}` : ''
                        // !valueFrom && rangeMin
                        //     ? rangeMin
                        //     : valueFrom
                        //     ? valueFrom
                        //     : undefined
                    }
                    id={'valueFrom'}
                    onChange={(e: any) => {
                        onChange(
                            e.target.value !== '' ? +e.target.value : 0,
                            valueTo !== undefined
                                ? valueTo
                                : rangeMax
                                ? Math.ceil(rangeMax / 1000) * 1000
                                : undefined
                        )
                    }}
                    type="number"
                    width={props.customWidth ?? '120px'}
                    min={
                        rangeMin
                            ? `${Math.floor(rangeMin / 1000) * 1000}`
                            : undefined
                    }
                    placeholderStyle={`font-size: 14px;`}
                />

                <div style={{ width: '10px' }} />
                <InputFieldNoFormikAnimated
                    name={props.labelTo ?? 'To'}
                    placeholder={
                        filterid === 'year' && rangeMax
                            ? `${rangeMax}`
                            : filterid !== 'price' &&
                              filterid !== 'year' &&
                              rangeMax
                            ? `${new Intl.NumberFormat('en-GB', {
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                              }).format(Math.ceil(rangeMax))}`
                            : rangeMax && filterid === 'price'
                            ? `${new Intl.NumberFormat('en-GB', {
                                  style: 'currency',
                                  currency: 'GBP',
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                              }).format(Math.ceil(rangeMax / 1000) * 1000)}`
                            : 'To'
                    }
                    // value={valueTo ?? undefined}
                    value={
                        valueTo ? `${valueTo}` : ''
                        // !valueTo && rangeMax
                        //     ? rangeMax
                        //     : valueTo
                        //     ? valueTo
                        //     : undefined
                    }
                    id={'valueTo'}
                    onChange={(e: any) => {
                        onChange(
                            valueFrom !== undefined
                                ? valueFrom
                                : rangeMin
                                ? Math.floor(rangeMin / 1000) * 1000
                                : undefined,
                            e.target.value !== '' ? +e.target.value : undefined
                        )
                    }}
                    type="number"
                    width={props.customWidth ?? '120px'}
                    max={
                        rangeMax
                            ? `${Math.ceil(rangeMax / 1000) * 1000}`
                            : undefined
                    }
                    placeholderStyle={`font-size: 14px;`}
                />
            </Row>
            <Expander height={error ? 'auto' : 0} width={'100%'}>
                <ErrorText
                    style={{
                        paddingTop: 10,
                    }}
                >
                    {error}
                </ErrorText>
            </Expander>

            <Expander height={warning ? 'auto' : 0} width={'100%'}>
                <WarningTxt
                    style={{
                        paddingTop: error ? 0 : 10,
                    }}
                >
                    {warning}
                </WarningTxt>
            </Expander>
        </div>
    )
}

export default RangeRowFieldsMobile
