import * as urls from '../../urls'
import { customHeader } from '../../headers'
import { apiSentryErrorHandler } from '../../errorHandler'
import {
    ICreateCarGalleryImageFields,
    IUploadGalleryImagesPreSignedUrlsPayload,
} from '../../../filestorage/types'
import {
    IApiCreateCarCoverImage_args,
    IApiCreateCarGalleryImage_args,
    IApiDeleteCarGalleryImage_args,
} from '../../types'
import {
    IUpdateCarGalleryImagePayload,
    IUpdateEntryGalleryImagePayload,
    IDeleteEntryGalleryImagePayload,
} from '../../../entities/galleries/types'

export const createCarGalleryImage = (
    p: IApiCreateCarGalleryImage_args
): Promise<any> => {
    let bodyArray: any[] = []
    p.filesSubmitted.forEach((file: any, index: number) => {
        if (file !== undefined && p.gallerypresignedurls) {
            let arrelem = {
                type: 'Gallery',
                filename: p.gallerypresignedurls[file.name].filename,
                caption: p.fields.caption,
                credit_data: {
                    name: p.fields.credit,
                },
                location_data: {
                    name: p.fields.location,
                },
                cover:
                    p.fields.cover && p.fields.cover === file.path
                        ? true
                        : false,
                featured:
                    p.fields.featured && p.fields.featured.includes(file.path)
                        ? true
                        : false,
            }

            return bodyArray.push(arrelem)
        }
    })

    let reqp = JSON.stringify(bodyArray)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: reqp,
        credentials: 'include',
    }

    return fetch(urls.create_car_image_endpoint(p.car_id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            response,
                            'Create car gallery image error',
                            reqp
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const createCarCoverImage = (
    p: IApiCreateCarCoverImage_args
): Promise<any> => {
    let bodyContent = [
        {
            type: 'Cover',
            filename: p.presignedurl.filename,
        },
    ]

    let reqp: string = JSON.stringify(bodyContent)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: reqp,
        credentials: 'include',
    }

    return fetch(urls.create_car_image_endpoint(p.car_id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create car cover image error',
                            reqp
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const updateCarGalleryImage = (
    data: IUpdateCarGalleryImagePayload
): Promise<any> => {
    let reqp: string = JSON.stringify(data.body)
    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: reqp,
        credentials: 'include',
    }

    return fetch(
        urls.update_car_image_endpoint(data.carid, data.imageid),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Update car gallery image error',
                        reqp
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const deleteCarGalleryImage = (
    p: IApiDeleteCarGalleryImage_args
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.delete_car_image_endpoint(p.carid, p.imageid),
        requestOptions
    )
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(
                    response,
                    'Delete car gallery image error'
                )
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}

// entries gallery

export const createEntryGalleryImage = (
    entryid: string,
    car_id: string,
    gallerypresignedurls: IUploadGalleryImagesPreSignedUrlsPayload,
    filesSubmitted: any[],
    fields: ICreateCarGalleryImageFields
) => {
    let bodyArray: any[] = []
    filesSubmitted.forEach((file: any, index: number) => {
        if (file !== undefined && gallerypresignedurls) {
            let arrelem = {
                type: 'Gallery',
                filename: gallerypresignedurls[file.name].filename,
                caption: fields.caption,
                credit_data: {
                    name: fields.credit,
                },
                location_data: {
                    name: fields.location,
                },
            }

            return bodyArray.push(arrelem)
        }
    })

    let reqp: string = JSON.stringify(bodyArray)
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: reqp,
        credentials: 'include',
    }

    return fetch(
        urls.create_entry_image_endpoint(entryid, car_id),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Create entry gallery image error',
                        reqp
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const updateEntryGalleryImage = (
    data: IUpdateEntryGalleryImagePayload
) => {
    let reqp: string = JSON.stringify(data.body)
    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: reqp,
        credentials: 'include',
    }

    return fetch(
        urls.update_entry_image_endpoint(
            data.entryid,
            data.carid,
            data.imageid
        ),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Create entry gallery image error',
                        reqp
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const deleteEntryGalleryImage = (p: IDeleteEntryGalleryImagePayload) => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.delete_entry_image_endpoint(p.entryid, p.carid, p.imageid),
        requestOptions
    )
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(
                    response,
                    'Delete entry gallery image error'
                )
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
