import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
// import unchecked_checkbox_green from '../../../public/assets/icons/unchecked_checkbox_green.svg'
// import checked_checkbox_green from '../../../public/assets/icons/checked_checkbox_green.svg'

interface IListItemProps {
    onChange?: any
    name?: any
    checked?: boolean
    onClick?: any
    isDisabled?: boolean
    textchangedisabled?: boolean
    width?: string
    small?: boolean
    extra_small?: boolean
    labelcolor?: string
    labelfontsize?: string
    labelfontstyle?: string
    hasWhiteBg?: boolean
    id?: string
    rowHeight?: string
    borderColor?: string
    customActiveColour?: string
    fullColorOnTrue?: boolean
    mobileAlignSelf?: string
    children?: React.ReactNode
}

interface IStyledProps {
    checked?: boolean
    isDisabled?: boolean
    textchangedisabled?: boolean
    width?: string
    extra_small?: boolean
    small?: boolean
    labelcolor?: string
    labelfontsize?: string
    labelfontstyle?: string
    hasWhiteBg?: boolean
    borderColor?: string
    customActiveColour?: string
    mobileAlignSelf?: string
    $theme: ITheme
}

const CheckboxInput = styled.label<IStyledProps>`
    display: flex;
    align-items: ${(props) => (props.extra_small ? 'start' : 'center')};
    /* height: 30px; */
    transform: translateY(3px);
    ${(props) => props.width && `width: ${props.width};`}
    transform: ${(props) =>
        props.extra_small
            ? 'translateY(10px)'
            : props.small
            ? 'translateY(1px)'
            : 'translateY(3px)'};

    cursor: ${(props: IStyledProps) =>
        props.isDisabled ? 'not-allowed' : 'pointer'};
    line-height: 21px;

    @media ${device.ipad} {
        transform: ${(props) =>
            props.small || props.extra_small
                ? 'translateY(1px)'
                : 'translateY(3px)'};
    }

    @media ${device.mobile} {
        font-family: Lato;
        font-size: 16px;
        line-height: 17px;
        display: flex;
        align-self: ${(props) => props.mobileAlignSelf ?? ' flex-start'};

        // careful - would fuck up register form. removed it but
        // maybe was there for a reason:
        // padding-left: 35px;
    }

    input {
        cursor: ${(props: IStyledProps) =>
            props.isDisabled ? 'not-allowed' : 'pointer'};
    }

    input[type='checkbox'] {
        display: none;
    }

    .custom-checkbox-square {
        cursor: ${(props: IStyledProps) =>
            props.isDisabled ? 'not-allowed' : 'pointer'};
        display: inline-block;
        ${(props) =>
            props.isDisabled &&
            `stroke: ${colours[props.$theme].border_muted};`}
    }

    /* input:checked + */
    .ripple-square {
        transform-origin: center;

        ${(props) =>
            props.checked === true && `animation: ripple 300ms ease-in-out;`}
    }

    .custom-checkbox-checked {
        transform-origin: center;
        transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
        ${(props) =>
            (props.checked === false || props.isDisabled) &&
            `transform: scale(0);`}
    }

    /* input:checked + */
    .custom-checkbox-checked {
        transform-origin: center;
        transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);

        ${(props) => props.checked && `transform: scale(1);`}
    }

    /*input:checked ~ */
    .checkbox-svg {
        ${(props) =>
            props.hasWhiteBg === true &&
            `background-color: ${colours[props.$theme].background_default};`}
        ${(props) =>
            props.checked === true &&
            `animation: tick 200ms ease-in-out;`}/* transform: scale(1.2);
        transition: ease-in-out 200ms; */
    }

    .label-text {
        margin-left: ${(props) => (props.extra_small ? '0px' : '10px')};

        transition: all 200ms;

        color: ${(props) =>
            props.labelcolor
                ? props.labelcolor
                : colours[props.$theme].text_strong};
        font-size: ${(props) =>
            props.labelfontsize ? props.labelfontsize : 'inherit'};
        font-style: ${(props) =>
            props.labelfontstyle ? props.labelfontstyle : 'inherit'};

        user-select: none; /* supported by Chrome and Opera */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
    }

    @media ${device.mobile} {
        transform: translateY(1px);
    }

    /* input:checked ~ */
    .label-text {
        ${(props) =>
            props.checked === true &&
            !props.textchangedisabled &&
            `color:${
                props.customActiveColour ?? colours[props.$theme].primary
            }; 
            transition: all 200ms;`}

        ${(props) =>
            props.isDisabled === true &&
            `color: ${colours[props.$theme].text_muted};`}
    }

    @keyframes tick {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes ripple {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(2);
            fill: rgba(0, 169, 175, 0.1);
            rx: 15;
        }

        /* 100% {
            transform: scale(1);
        } */
    }

    .checkbox-svg {
        cursor: ${(props: IStyledProps) =>
            props.isDisabled ? 'not-allowed' : 'pointer'};
    }
`

const SpanStyled = styled.span<IStyledProps>`
    :hover {
        color: ${(props) =>
            props.customActiveColour ?? colours[props.$theme].primary};
    }
`

export default function CustomAnimatedCheckboxField(props: IListItemProps) {
    const {
        children,
        small,
        labelcolor,
        labelfontsize,
        labelfontstyle,
        extra_small,
        hasWhiteBg,
        borderColor,
        checked,
        customActiveColour,
        fullColorOnTrue,
        mobileAlignSelf,
    } = props

    const { theme } = useThemes()

    return (
        <React.Fragment>
            <CheckboxInput
                $theme={theme}
                checked={props.checked}
                isDisabled={props.isDisabled}
                textchangedisabled={props.textchangedisabled}
                width={props.width}
                labelcolor={labelcolor}
                labelfontsize={labelfontsize}
                labelfontstyle={labelfontstyle}
                hasWhiteBg={hasWhiteBg}
                extra_small={extra_small}
                style={{ height: props.rowHeight ?? '100%' }}
                customActiveColour={customActiveColour}
                mobileAlignSelf={mobileAlignSelf}
            >
                <input
                    type="checkbox"
                    name="name"
                    onChange={props.onChange}
                    onClick={props.onClick}
                />
                {/* <img className="custom-checkbox-square" src={unchecked_checkbox_green} /> */}
                <div>
                    <svg
                        className="checkbox-svg"
                        width={extra_small ? '18' : '25'}
                        height={extra_small ? '18' : '25'}
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            className="custom-checkbox-square"
                            x={small ? '1.5' : '0.5'}
                            y={small ? '1.5' : '0.5'}
                            width={small ? '22' : '24'}
                            height={small ? '22' : '24'}
                            rx="4.5"
                            stroke={
                                !checked && borderColor
                                    ? borderColor
                                    : customActiveColour ??
                                      colours[theme].primary
                            }
                        />
                        <rect
                            className="ripple-square"
                            x={small ? '1' : '0.5'}
                            y={small ? '1' : '0.5'}
                            width={small ? '22' : '24'}
                            height={small ? '22' : '24'}
                            rx="4.5"
                            stroke="none"
                        />
                        <rect
                            className="custom-checkbox-checked"
                            x={fullColorOnTrue ? 1.5 : small ? '5' : '4'}
                            y={fullColorOnTrue ? 1.5 : small ? '5' : '4'}
                            width={
                                small
                                    ? fullColorOnTrue
                                        ? '22'
                                        : '15'
                                    : fullColorOnTrue
                                    ? '24'
                                    : '17'
                            }
                            height={
                                small
                                    ? fullColorOnTrue
                                        ? '22'
                                        : '15'
                                    : fullColorOnTrue
                                    ? '24'
                                    : '17'
                            }
                            rx="3"
                            fill={
                                !checked && borderColor
                                    ? borderColor
                                    : customActiveColour ??
                                      colours[theme].primary
                            }
                            style={{
                                opacity: checked ? '1' : '0',
                            }}
                        />
                        <path
                            className="custom-checkbox-checked"
                            d="M10.6667 16.625L7 12.875L7.74776 12.1103L10.6667 14.375L17.2667 8.375L18 9.125"
                            fill={colours[theme].background_default}
                            style={{
                                opacity: checked ? '1' : '0',
                            }}
                        />
                    </svg>
                </div>
                <SpanStyled $theme={theme} className="label-text">
                    {children}
                </SpanStyled>
                {/* <img className="custom-checkbox-checked" src={checked_checkbox_green} /> */}
            </CheckboxInput>
        </React.Fragment>
    )
}
