import {
    IGalleryImagesObject,
    IGalleryImage,
} from '../../../redux/entities/galleries/types'

export const generateOverviewImages = (
    images_data: IGalleryImagesObject,
    images_ids: string[],
    featured_images_ids: string[],
    coverimg?: IGalleryImage
): IGalleryImage[] => {
    let resultArray: IGalleryImage[] = []

    // 0
    if (coverimg) {
        resultArray[0] = coverimg
    }

    // 1
    if (
        featured_images_ids[0] !== undefined &&
        images_data[featured_images_ids[0]]
    ) {
        resultArray.push(images_data[featured_images_ids[0]])
    } else if (images_ids[0] !== undefined && images_data[images_ids[0]]) {
        if (
            coverimg &&
            coverimg.id !== images_ids[0] &&
            featured_images_ids.indexOf(images_ids[0]) === -1
        ) {
            resultArray.push(images_data[images_ids[0]])
        }
    }
    // 2
    if (
        featured_images_ids[1] !== undefined &&
        images_data[featured_images_ids[1]]
    ) {
        resultArray.push(images_data[featured_images_ids[1]])
    } else if (images_ids[1] !== undefined && images_data[images_ids[1]]) {
        if (
            coverimg &&
            coverimg.id !== images_ids[1] &&
            featured_images_ids.indexOf(images_ids[1]) === -1
        ) {
            resultArray.push(images_data[images_ids[1]])
        }
    }

    // 3
    if (
        featured_images_ids[2] !== undefined &&
        images_data[featured_images_ids[2]]
    ) {
        resultArray.push(images_data[featured_images_ids[2]])
    } else if (images_ids[2] !== undefined && images_data[images_ids[2]]) {
        if (
            coverimg &&
            coverimg.id !== images_ids[2] &&
            featured_images_ids.indexOf(images_ids[2]) === -1
        ) {
            resultArray.push(images_data[images_ids[2]])
        }
    }

    // 4
    if (
        featured_images_ids[3] !== undefined &&
        images_data[featured_images_ids[3]]
    ) {
        resultArray.push(images_data[featured_images_ids[3]])
    } else if (images_ids[3] !== undefined && images_data[images_ids[3]]) {
        if (
            coverimg &&
            coverimg.id !== images_ids[3] &&
            featured_images_ids.indexOf(images_ids[3]) === -1
        ) {
            resultArray.push(images_data[images_ids[3]])
        }
    }

    // 5
    if (
        featured_images_ids[4] !== undefined &&
        images_data[featured_images_ids[4]]
    ) {
        resultArray.push(images_data[featured_images_ids[4]])
    } else if (images_ids[4] !== undefined && images_data[images_ids[4]]) {
        if (
            coverimg &&
            coverimg.id !== images_ids[4] &&
            featured_images_ids.indexOf(images_ids[4]) === -1
        ) {
            resultArray.push(images_data[images_ids[4]])
        }
    }

    // 5
    if (
        featured_images_ids[5] !== undefined &&
        images_data[featured_images_ids[5]]
    ) {
        resultArray.push(images_data[featured_images_ids[5]])
    } else if (images_ids[5] !== undefined && images_data[images_ids[5]]) {
        if (
            coverimg &&
            coverimg.id !== images_ids[5] &&
            featured_images_ids.indexOf(images_ids[5]) === -1
        ) {
            resultArray.push(images_data[images_ids[5]])
        }
    }

    // 6
    if (
        featured_images_ids[6] !== undefined &&
        images_data[featured_images_ids[6]]
    ) {
        resultArray.push(images_data[featured_images_ids[6]])
    } else if (images_ids[5] !== undefined && images_data[images_ids[6]]) {
        if (
            coverimg &&
            coverimg.id !== images_ids[6] &&
            featured_images_ids.indexOf(images_ids[6]) === -1
        ) {
            resultArray.push(images_data[images_ids[6]])
        }
    }

    if (resultArray.length < 7 && images_ids.length > 1) {
        images_ids.forEach((id: string, index: number) => {
            if (
                resultArray.length < 7 &&
                resultArray.length < images_ids.length
            ) {
                if (featured_images_ids.indexOf(id) === -1 && images_data[id]) {
                    if (coverimg && coverimg.id !== id) {
                        return resultArray.push(images_data[id])
                    } else if (!coverimg) {
                        return resultArray.push(images_data[id])
                    }
                }
            }
        })
    }

    return resultArray
}
