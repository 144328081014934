import * as React from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import SkeletonAbsolute from '../../skeleton/skeletonAbsolute'
import { IGalleryImage } from '../../../../redux/entities/galleries/types'

const ImageSizeWrapper = styled.div<StyleProps>`
    position: relative;
    aspect-ratio: 1.33;
    width: 100%;
    box-sizing: border-box;
    height: auto;

    &:hover .overlay {
        opacity: 1;
    }
`
const ImageOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.3s;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const Txt = styled.div`
    font-family: Lato;
    font-size: 15px;
    color: white;
`

const GalleryImage = styled.img<StyleProps>`
    object-fit: cover !important;
    aspect-ratio: 1.33;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${(props) => (props.isthingloading === true ? '0' : '1')};
    transition: opacity 400ms;
`

interface Props {
    img?: IGalleryImage
    placeholder?: string
    isBestQuality?: boolean
}

interface StyleProps {
    isthingloading?: boolean
}

export default function EntryGalleryItemPreview({
    img,
    placeholder,
    isBestQuality,
}: Props) {
    const [src, setSrc] = React.useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        const initialSrc = img?.url ?? placeholder
        if (initialSrc) {
            setSrc(initialSrc)
        }
    }, [img])

    return (
        <ImageSizeWrapper>
            <ImageOverlay className="overlay">
                {img?.caption && <Txt>{img?.caption}</Txt>}
                {img?.location && <Txt>{img?.location}</Txt>}
            </ImageOverlay>

            <SkeletonAbsolute isthingloading={isLoading} />
            <GalleryImage
                aria-label={img?.caption ?? 'picture'}
                src={src || grey_placeholder}
                srcSet={isBestQuality ? img?.large_src : img?.medium_srcset}
                isthingloading={isLoading}
                onLoad={() => {
                    setIsLoading(false)
                }}
            />
        </ImageSizeWrapper>
    )
}
