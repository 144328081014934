import { Input, Space } from 'antd'
import { useEffect, useState } from 'react'
import {
    deconstructLicenseNumber,
    prefillLicenceNumber,
} from '../../../../helpers/validations/licenseNumber'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import colours, {
    insurance_colours,
    ITheme,
} from '../../../../providers/theme/colours'
import { insuranceActions } from '../../../../redux/insuranceQuoteApplication/reducer'
import styled from 'styled-components'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import useThemes from '../../../../providers/theme/hooks'
import InputFieldNoFormikAnimated from '../inputFieldNoFormikAnimated'

const Wrapper = styled.div<{ $theme: ITheme }>`
    .ant-input {
        background-color: transparent !important;
    }

    .ant-input:hover {
        border-color: ${(props) => colours[props.$theme].primary} !important;
        border-right-width: 1px !important;
    }
`

const VerticalLine = styled.div`
    height: 24px;
    width: 1px;
    background-color: #b3b3b3;
    transform: translateY(14px);
`

type ILicenseNumberArg = {
    target?: 'INSURANCE_MAIN_DRIVER'
    licenseNumberType?: 'great_britain' | 'northern_ireland'
    isMobile?: boolean
    onAnswerChange: (id: string, answer: any) => any
    isWizard?: boolean
    isDisabled?: boolean
    sectionID: IInsuranceApplicationSectionId | undefined
}

const LicenseNumber = (props: ILicenseNumberArg) => {
    const [err1, setErr1] = useState<string | undefined>(undefined)
    const [err2, setErr2] = useState<string | undefined>(undefined)
    const [err3, setErr3] = useState<string | undefined>(undefined)
    const [err4, setErr4] = useState<string | undefined>(undefined)

    const [val1, setVal1] = useState<string>('')
    const [val2, setVal2] = useState<string>('')
    const [val3, setVal3] = useState<string>('')
    const [val4, setVal4] = useState<string>('')

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })
    let application_draft = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft
    })

    let errors = application_draft.main_driver?.licence_address_errors

    let currDraftValue =
        application_draft?.main_driver?.licence_address?.license_number

    let dispatch = useAppDispatch()

    const [hasChange, setHasChange] = useState<boolean>(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_main_driver_licenseAddress({
                id: id,
                error: error,
                userid: `${application?.main_driver?.id}`,
            })
        )
    }
    useEffect(() => {
        if (props.licenseNumberType === 'great_britain') {
            if (err1) {
                setError('license_number', err1)
            } else if (err2) {
                return
            }

            if (err2) {
                setError('license_number', err2)
            } else if (err3) {
                return
            }

            if (err3) {
                setError('license_number', err3)
            } else if (err4) {
                return
            }

            if (err4) {
                setError('license_number', err4)
            } else if (
                `${val1 + val2 + val3 + val4}`.length !== 16 &&
                hasChange
            ) {
                return
            }

            if (`${val1 + val2 + val3 + val4}`.length !== 16 && hasChange) {
                setError(
                    'license_number',
                    'Invalid. Great Britain license number must have 16 characters.'
                )
            } else if (`${val1 + val2 + val3 + val4}`.length === 16) {
                setError('license_number', undefined)
                return
            }
        }
    }, [err1, err2, err3, err4])

    useEffect(() => {
        if (props.licenseNumberType === 'great_britain') {
            if (err1) {
                setError('license_number', err1)
            } else if (err2) {
                setError('license_number', err2)
            } else if (err3) {
                setError('license_number', err3)
            } else if (err4) {
                setError('license_number', err4)
            } else if (
                `${val1 + val2 + val3 + val4}`.length !== 16 &&
                hasChange
            ) {
                setError(
                    'license_number',
                    'Invalid. Great Britain license number must have 16 characters.'
                )
            }
        }
    }, [err1, err2, err3, err4])

    useEffect(() => {
        if (props.licenseNumberType === 'great_britain') {
            let values: string[]

            if (currDraftValue) {
                values = deconstructLicenseNumber(currDraftValue)
            } else {
                values = prefillLicenceNumber({
                    dob: application?.main_driver?.basic_details?.dob,
                    family_name:
                        application?.main_driver?.basic_details?.family_name,
                    given_name:
                        application?.main_driver?.basic_details?.given_name,
                    middle_name:
                        application?.main_driver?.basic_details?.middle_name,
                    gender: application?.main_driver?.basic_details?.gender,
                })
            }

            if (values[0] !== undefined) {
                setVal1(values[0])
            }
            if (values[1] !== undefined) {
                setVal2(values[1])
            }
            if (values[2] !== undefined) {
                setVal3(values[2])
            }
            if (values[3] !== undefined) {
                setVal4(values[3])
            }
        }
    }, [application, props.licenseNumberType])

    useEffect(() => {
        if (
            errors &&
            errors['license_number'] &&
            !props.licenseNumberType &&
            currDraftValue
        ) {
            setError('license_number', undefined)
            props.onAnswerChange('license_issue_location', '')
        } else if (
            props.licenseNumberType === 'northern_ireland' &&
            currDraftValue
        ) {
            if (currDraftValue.length !== 8) {
                setError(
                    'license_number',
                    'Invalid. Northern Ireland license number must have 8 characters.'
                )
            } else {
                setError('license_number', undefined)
            }
        } else if (
            props.licenseNumberType === 'great_britain' &&
            currDraftValue
        ) {
            if (currDraftValue.length !== 16) {
                setError(
                    'license_number',
                    'Invalid. Great Britain license number must have 16 characters.'
                )
            } else {
                setError('license_number', undefined)
            }
        }
    }, [props.licenseNumberType])

    const { theme } = useThemes()

    switch (props.licenseNumberType) {
        case 'great_britain':
            return (
                <Wrapper
                    $theme={theme}
                    style={{
                        maxWidth: props.isWizard
                            ? '100%'
                            : props.isMobile
                            ? '100%'
                            : '448px',
                        width: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0,
                        margin: 0,
                        border: `1px solid ${colours[theme].text_muted}`,
                        borderRadius: 4,
                    }}
                >
                    <Space direction="vertical" size="middle">
                        <Space.Compact
                            style={{
                                height: '50px',
                                background: 'transparent',
                            }}
                        >
                            <Input
                                style={{
                                    width: '31%',
                                    borderWidth: 0,
                                    color: colours[theme].text_strong,
                                }}
                                value={val1}
                                maxLength={5}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setHasChange(true)
                                    const val11 =
                                        e.target.value?.toLocaleUpperCase()

                                    setVal1(val11)
                                    props.onAnswerChange(
                                        'license_number',
                                        val11 + val2 + val3 + val4
                                    )

                                    if (e.target.value?.length < 5) {
                                        setErr1(
                                            `Enter the first 5 letters of the driver's last name. If the last name is fewer than 5 letters add 9 to the end`
                                        )
                                    }

                                    if (e.target.value?.length === 5 && err1) {
                                        setErr1(undefined)
                                        setError('license_number', undefined)
                                    }
                                }}
                                disabled={props.isDisabled}
                            />
                            <VerticalLine />
                            <Input
                                // type="number"
                                style={{
                                    width: '38%',
                                    borderWidth: 0,
                                    color: colours[theme].text_strong,
                                }}
                                value={val2}
                                maxLength={6}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    const val22 =
                                        e.target.value?.toLocaleUpperCase()

                                    // if (!isNaN(+val22)) {
                                    setVal2(val22)
                                    props.onAnswerChange(
                                        'license_number',
                                        val1 + val22 + val3 + val4
                                    )
                                    setHasChange(true)
                                    if (e.target.value?.length < 6) {
                                        setErr2(
                                            `Enter the 6 numbers displayed on the licence. Start from the 6th character of the full licence number`
                                        )
                                    }

                                    if (e.target.value?.length === 6 && err2) {
                                        setErr2(undefined)
                                        setError('license_number', undefined)
                                    }
                                    // }
                                }}
                                disabled={props.isDisabled}
                            />
                            <VerticalLine />
                            <Input
                                value={val3}
                                style={{
                                    width: '12%',
                                    borderWidth: 0,
                                    color: colours[theme].text_strong,
                                }}
                                maxLength={2}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    const val33 =
                                        e.target.value?.toLocaleUpperCase()
                                    setVal3(val33)
                                    props.onAnswerChange(
                                        'license_number',
                                        val1 + val2 + val33 + val4
                                    )
                                    setHasChange(true)
                                    if (e.target.value?.length < 2) {
                                        setErr3(
                                            `Enter the initials of the driver's first and middle names. If the driver only has one name, add 9 to the end`
                                        )
                                    }

                                    if (e.target.value?.length === 2 && err3) {
                                        setErr3(undefined)
                                        setError('license_number', undefined)
                                    }
                                }}
                                disabled={props.isDisabled}
                            />
                            <VerticalLine />
                            <Input
                                value={val4}
                                style={{
                                    width: '19%',
                                    borderWidth: 0,
                                    color: colours[theme].text_strong,
                                }}
                                maxLength={3}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    const val44 =
                                        e.target.value?.toLocaleUpperCase()

                                    setVal4(val44)
                                    props.onAnswerChange(
                                        'license_number',
                                        val1 + val2 + val3 + val44
                                    )
                                    setHasChange(true)
                                    if (e.target.value?.length < 3) {
                                        setErr4(
                                            `Enter the 3 characters at the end of the licence number`
                                        )
                                    }
                                    if (e.target.value?.length === 3) {
                                        setErr4(undefined)
                                        setError('license_number', undefined)
                                    }
                                }}
                                disabled={props.isDisabled}
                            />
                        </Space.Compact>
                    </Space>

                    {/* {(err1 || err2 || err3 || err4) && (
                <Expander height={err1 || err2 || err3 || err4 ? 'auto' : 0}>
                    <div style={{ paddingTop: '10px' }} />
                </Expander>
            )}

            {err1 && (
                <Expander height={err1 ? 'auto' : 0}>
                    <Error>{err1}</Error>
                </Expander>
            )}

            {err2 && (
                <Expander height={err2 ? 'auto' : 0}>
                    <Error>{err2}</Error>
                </Expander>
            )}

            {err3 && (
                <Expander height={err3 ? 'auto' : 0}>
                    <Error>{err3}</Error>
                </Expander>
            )}

            {err4 && (
                <Expander height={err4 ? 'auto' : 0}>
                    <Error>{err4}</Error>
                </Expander>
            )} */}
                </Wrapper>
            )
        case 'northern_ireland':
            return (
                <InputFieldNoFormikAnimated
                    disabled={props.isDisabled}
                    name={''}
                    value={currDraftValue ? `${currDraftValue}` : undefined}
                    onChange={(e: any) => {
                        props.onAnswerChange('license_number', e.target.value)
                        if (e.target.value && e.target.value.length > 8) {
                            setError(
                                'license_number',
                                'Invalid. Northern Ireland license number must have 8 characters.'
                            )
                        } else {
                            setError('license_number', undefined)
                        }
                    }}
                    placeholder={'Type here your licence number '}
                    width={'100%'}
                    backgroundColor={colours[theme].background_neutral_subtle}
                    customActiveColour={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_100
                            : undefined
                    }
                />
            )
        default:
            return (
                <InputFieldNoFormikAnimated
                    disabled={props.isDisabled}
                    name={''}
                    value={currDraftValue ? `${currDraftValue}` : undefined}
                    onChange={(e: any) => {
                        props.onAnswerChange('license_number', e.target.value)
                    }}
                    placeholder={'Type here ...'}
                    width={'100%'}
                    backgroundColor={colours[theme].background_neutral_subtle}
                    customActiveColour={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_100
                            : undefined
                    }
                />
            )
    }
}

export default LicenseNumber
