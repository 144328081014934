import AddCarCoverImageMobile from '../carGalleryEditForm/addCarCoverImageMobileForm'
import { useEffect, useState } from 'react'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'

import axios from 'axios'

interface Props {
    close: any
    carid: string
    // gallerypresignedurls?: IUploadGalleryImagesPreSignedUrlsPayload
}

const WrapperCarCoverImageMobile = ({ close, carid }: Props) => {
    let gallerypresignedurls = useAppSelector(
        (state) => state.fileStorage.gallerypresignedurls
    )

    const [progressPercent, setProgressPercent] = useState<number | null>(null)
    const [changeInPresignedURL, setChangeInPresignedURL] = useState(false)
    const [file, setFile] = useState<File | undefined>(undefined)
    const [isSomethingBeingUploaded, setIsSomethingBeingUploaded] =
        useState(true)

    useEffect(() => {
        if (
            gallerypresignedurls !== undefined &&
            gallerypresignedurls !== null
        ) {
            if (gallerypresignedurls[0]) {
                if (file !== undefined) {
                    uploadPresignedUrlImageToS3(file)
                }
                // setPresignedUrlsState(gallerypresignedurls)
                setChangeInPresignedURL(!changeInPresignedURL)
            }
        }
    }, [gallerypresignedurls])

    // const deleteImageUploaded = () => {
    //     setFile(undefined)
    //     setProgressPercent(null)
    // }

    const uploadPresignedUrlImageToS3 = async (stateFile: File) => {
        if (stateFile !== undefined && gallerypresignedurls) {
            try {
                await axios.request({
                    method: 'put',
                    url: gallerypresignedurls[stateFile.name].url,
                    data: stateFile,
                    onUploadProgress: (p: any) => {
                        setProgressPercent((p.loaded / p.total) * 100)
                    },
                })
                setIsSomethingBeingUploaded(false)
            } catch (e: any) {
                apiSentryErrorHandler(
                    e,
                    'Upload presigned car cover url image to s3 error'
                )
                return Promise.reject(e)
            }
        }
    }

    const handleFilesGivenInWrapperComponent = (file: File) => {
        setFile(file)
    }

    const dispatch = useAppDispatch()

    return (
        <AddCarCoverImageMobile
            close={close}
            setImageUploaded={handleFilesGivenInWrapperComponent}
            currentImagesUploaded={file}
            car_id={carid}
            progressPercent={progressPercent}
            isSomethingBeingUploaded={isSomethingBeingUploaded}
            dispatch={dispatch}
            gallerypresignedurls={gallerypresignedurls}
        />
    )
}

export default WrapperCarCoverImageMobile
