// ApexTeamCreditsDesktop

import React from 'react'

import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'

const Wrapper = styled.div`
    font-size: 13px;
    font-family: 'Lato-Light';
    padding-top: 10px;
    padding-bottom: 18px;
    color: var(--text-default, #666);
    @media ${device.beyond_ipad_mobile} {
    }
`

type Props = {
    text: string
}

const ApexImageCreditsMobile: React.FC<Props> = ({ text }) => {
    return <Wrapper>{text}</Wrapper>
}

export default ApexImageCreditsMobile
