import JourneyBottomActionBarMobile from '../../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, {
    history_file_colours,
    journey_colours,
} from '../../../../../providers/theme/colours'
import FormStepProgress from '../../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../../atoms/icons/components/chevronDown'
import Faded from '../../../../templates/animated/faded'
import EnterIcon from '../../../../atoms/icons/components/enterIcon'

import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../../templates/styledcomponents/journeyStyles'
import TimelineItemBodyDesktop from '../../../../molecules/timelineItemDesktop/timelineItemBodyDesktop'
import {
    sampleDataJourney,
    timeline_category_data,
    timeline_category_ids,
} from '../../../../../redux/timeline/data'
import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import { renderCategoryIcon } from '../../../../atoms/icons/components/historyFile/categories'
import {
    ITimelineCategoryDataItem,
    ITimelineEntryTypes,
} from '../../../../../redux/timeline/types'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

const CardImageWrapper = styled.div`
    border-radius: 16px;

    background: var(--bg-color, #fff);
    box-shadow: 64px 64px 128px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
    width: 550px;

    transform: scale(0.9) translateY(-10px);

    @media ${device.mobile} {
    }
`

const CatWrapper = styled.div`
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
`

const RenderCategories = () => {
    return timeline_category_ids.map((optionID: string, i: number) => {
        if (timeline_category_data[optionID]) {
            return (
                <CategoriesUI option={timeline_category_data[optionID]} i={i} />
            )
        } else
            return (
                <div
                    key={`option_none_${i}_mobile`}
                    style={{ display: 'none' }}
                />
            )
    })
}

type Props2 = {
    option: ITimelineCategoryDataItem
    i: number
}

const RoundCat = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px !important;
    min-width: 30px;
    border-radius: 30px;
`

const CatTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Bold;
    font-size: 14px;
    letter-spacing: 0.001px;
    text-transform: capitalize;
`

const CatTxt = styled.div`
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`
const CategoriesUI = (props: Props2) => {
    const { theme } = useThemes()
    return (
        <CatWrapper
            key={`option_${props.option.name}_option_${props.i}`}
            style={{
                border: `1px solid ${
                    history_file_colours[theme].categories[
                        props.option.id as ITimelineEntryTypes
                    ].primary_30
                }`,
            }}
        >
            <RoundCat
                style={{
                    backgroundColor:
                        history_file_colours[theme].categories[
                            props.option.id as ITimelineEntryTypes
                        ].primary_100,
                }}
            >
                {renderCategoryIcon(
                    props.option.id as ITimelineEntryTypes,
                    '12'
                )}
            </RoundCat>

            <JourneyColumnContent style={{ gap: '4px' }}>
                <CatTitle>{props.option.name}</CatTitle>
                <CatTxt>{props.option.description}</CatTxt>
            </JourneyColumnContent>
        </CatWrapper>
    )
}

const SectionLeft = () => {
    const { theme } = useThemes()
    return (
        <JourneyColumnContent>
            <JourneyText $theme={theme}>
                Custodian lets you categorise your history file so that it is
                quick and easy to browse after doing so. Read through the
                categories below to familiarise yourself with the options. A
                massive advantage of a digital history file is how you can
                attach different media types to moments in time without printing
                them all out, ie. PDFs, pictures, videos etc!
            </JourneyText>
        </JourneyColumnContent>
    )
}
export default function HistoryFileLearnJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section[
                                        'history_file'
                                    ].primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[
                                        `history_file`
                                    ].primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        What kinds of things can I store?
                                    </JourneyStepTitle>
                                </JourneyColumnContent>
                                <SectionLeft />
                            </JourneyColumnContent>

                            <JourneyColumnContent style={{ gap: 16 }}>
                                {RenderCategories()}
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'history_file'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'history_file'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <JourneyColumnContent
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    height: '100%',
                                    paddingTop: '3vh',
                                    overflowY: 'scroll',
                                    gap: '0px',
                                }}
                            >
                                <CardImageWrapper
                                    style={{
                                        border: `1px solid ${
                                            history_file_colours.light
                                                .categories[
                                                sampleDataJourney[0]!
                                                    .categoryID!
                                            ].primary_16
                                        }`,
                                    }}
                                >
                                    <TimelineItemBodyDesktop
                                        userCurrency="£"
                                        item={sampleDataJourney[0]}
                                        key="0"
                                    />
                                </CardImageWrapper>
                                <CardImageWrapper
                                    style={{
                                        border: `1px solid ${
                                            history_file_colours.light
                                                .categories[
                                                sampleDataJourney[1]!
                                                    .categoryID!
                                            ].primary_16
                                        }`,
                                    }}
                                >
                                    <TimelineItemBodyDesktop
                                        userCurrency="£"
                                        item={sampleDataJourney[1]}
                                        key="1"
                                    />
                                </CardImageWrapper>
                                <CardImageWrapper
                                    style={{
                                        border: `1px solid ${
                                            history_file_colours.light
                                                .categories[
                                                sampleDataJourney[2]!
                                                    .categoryID!
                                            ].primary_16
                                        }`,
                                    }}
                                >
                                    <TimelineItemBodyDesktop
                                        userCurrency="£"
                                        item={sampleDataJourney[2]}
                                        key="2"
                                    />
                                </CardImageWrapper>
                            </JourneyColumnContent>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingBottom: 200,
                            paddingTop: 48,
                            overflow: 'hidden',
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <JourneyStepTitle
                                        $theme={theme}
                                        style={{ paddingRight: '40px' }}
                                    >
                                        What kinds of things can I store?
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        Bring your car's history online
                                    </JourneyText>
                                </JourneyColumnContent>

                                <SectionLeft />
                                <div />
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    {RenderCategories()}
                                </JourneyColumnContent>
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="history_file"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
