import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import grey_placeholder_dark from '../../../public/assets/placeholder/grey_placeholder_dark.jpg'
import { device } from '../../templates/displays/devices'
import { Link, useLocation } from 'react-router-dom'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import ImageSignMobile from '../../atoms/signs/imageSignMobile'
import { IGalleryImage } from '../../../redux/entities/galleries/types'
import useThemes from '../../../providers/theme/hooks'

const LinkWrap = styled(Link)<IImgProps>`
    width: ${(props) => (props.$isunique === true ? '50vw' : '100%')};
    height: 126px;
    position: relative;
    z-index: 1;

    @media ${device.ipad} {
        height: 22vh;
    }
`
const GalleryImage = styled.img<IImgProps>`
    object-fit: cover !important;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    opacity: ${(props) => (props.$isthingloading === true ? 0 : 1)};
    transition: opacity 400ms;
`

const GalleryCount = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 141.9%;
    display: flex;
    align-items: center;
    color: white;
`
const GalleryImageBackground = styled.div<IImgProps>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: ${(props) => (props.$isunique === true ? '50vw' : '100%')};
    height: 126px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: ${(props) => (props.$isthingloading === true ? '1' : '1')};
    transition: opacity 400ms;
    @media ${device.ipad} {
        height: 22vh;
    }
`

interface Props {
    isLast: boolean
    isFirst: boolean
    item: IGalleryImage
    count: number | null
    cover?: IGalleryImage
    carid: string
    isUnique?: boolean
    isPlaceholder?: boolean
}

type IImgProps = {
    $isthingloading: boolean
    $isunique?: boolean
}

const GalleryOverviewItemMobile: React.FC<Props> = ({
    isLast,
    isFirst,
    item,
    count,
    cover,
    carid,
    isUnique,
    isPlaceholder,
}) => {
    const [isLoading, setIsLoading] = useState(true)

    const location = useLocation()
    const { theme } = useThemes()

    const defaultPlaceholder =
        theme === 'dark' ? grey_placeholder_dark : grey_placeholder

    const imageSrc =
        cover?.large_src ||
        cover?.url ||
        item.large_src ||
        item.url ||
        defaultPlaceholder

    useEffect(() => {
        const image = new Image()
        image.src = imageSrc
        image.onload = () => setIsLoading(false)
        image.onerror = () => setIsLoading(false) // Ensure state updates on error.
    }, [cover])

    const generateSrcset = (): string | undefined => {
        if (cover?.small_srcset) {
            return cover.small_srcset
        } else if (item?.small_srcset) {
            return item.small_srcset
        }
        return undefined
    }

    const urlLocation = location.pathname

    const imgLinkPrefix = location.pathname.includes('overview')
        ? location.pathname.replace('/overview', '')
        : location.pathname

    return (
        <React.Fragment>
            {!isLast ? (
                <LinkWrap
                    $isunique={isUnique}
                    $isthingloading={isLoading}
                    to={{
                        pathname: `${imgLinkPrefix}/gallery`,
                        search: `?id=${item.id}`,
                    }}
                    state={{ prevPath: urlLocation }}
                    style={isPlaceholder ? { pointerEvents: 'none' } : {}}
                >
                    {isFirst && <ImageSignMobile />}

                    <SkeletonAbsolute isthingloading={isLoading} />
                    <GalleryImage
                        $isunique={isUnique}
                        aria-label="car overview banner"
                        src={imageSrc}
                        $isthingloading={isLoading}
                        srcSet={generateSrcset()}
                        onLoad={() => setIsLoading(false)}
                    />
                </LinkWrap>
            ) : (
                <Link
                    to={{
                        pathname: `${imgLinkPrefix}/gallery`,
                    }}
                    state={{ prevPath: urlLocation }}
                >
                    <GalleryImageBackground
                        role="img"
                        aria-label="car overview banner"
                        $isthingloading={true}
                        style={{
                            backgroundImage:
                                cover?.large_src || cover?.url
                                    ? `linear-gradient(to bottom,  rgba(00, 00, 00, 0.73), rgba(00, 00, 00, 0.73)), url(${imageSrc})`
                                    : `url(${imageSrc})`,
                        }}
                    >
                        <GalleryCount>
                            {count ? (
                                `+ ${count}`
                            ) : (
                                <span
                                    style={{
                                        fontFamily: 'Lato-Light',
                                        fontSize: '18px',
                                    }}
                                >
                                    see all
                                </span>
                            )}
                        </GalleryCount>
                    </GalleryImageBackground>
                </Link>
            )}
        </React.Fragment>
    )
}

export default GalleryOverviewItemMobile
