import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import GallerySlideZoomIcon from '../../atoms/icons/GallerySlideZoomIcon'
import styled from 'styled-components'

const AbsoluteIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`

interface Props {
    percentage: number
    backgroundColor?: string
    backgroundOpacity?: number
    animationDuration?: number
    onZoom?: (isZoomed: boolean) => void
    children: React.ReactNode
}

export const ZoomWrapper = (props: Props) => {
    const {
        percentage = 90,
        backgroundOpacity = 0.9,
        backgroundColor = 'white',
        animationDuration = 200,
        onZoom,
        children,
    } = props

    if (percentage < 1 || percentage > 100) {
        throw 'Zoom percentage must be between 1 and 100'
    }

    if (backgroundOpacity < 0 || backgroundOpacity > 1) {
        throw 'Background opacity must be between 0 and 1'
    }

    const containerRef = useRef<HTMLDivElement>(null)

    const [isZoomed, setIsZoomed] = useState(false)

    const closeWrapper = () => {
        if (!containerRef.current) return

        containerRef.current.style.transform = `scale(1)`
        setIsZoomed(false)
    }

    const handleImageZoom = () => {
        if (!containerRef.current) return
        if (isZoomed) {
            closeWrapper()
        } else {
            const containerRect = containerRef.current.getBoundingClientRect()
            let clientHeight = containerRect.height
            let clientWidth = containerRect.width

            const wPrim = (window.innerWidth - containerRect.width) / 2
            const hPrim = (window.innerHeight - containerRect.height) / 2
            const cL = containerRect.left
            const cT = containerRect.top

            const zoomPerc = percentage / 100
            if (
                ((window.innerHeight * zoomPerc) / clientHeight) *
                    clientWidth >=
                window.innerWidth
            ) {
                containerRef.current.style.transform = `translate(${
                    wPrim - cL
                }px,${hPrim - cT}px) scale(${
                    (window.innerWidth * zoomPerc) / clientWidth
                })`
            } else {
                containerRef.current.style.transform = `translate(${
                    wPrim - cL
                }px,${hPrim - cT}px) scale(${
                    (window.innerHeight * zoomPerc) / clientHeight
                })`
            }

            if (clientWidth <= clientHeight) {
            } else {
            }

            window.document.addEventListener('scroll', closeWrapper, {
                once: true,
            })

            setIsZoomed(true)
            return
        }
    }

    const styles: CSSProperties = {
        position: 'relative',
        transition: `transform ${animationDuration}ms`,
        // display: props.layout === "fixed" ? "inline-block" : "block",
        // width: props.layout === "fixed" ? "max-content" : "100%",
        // height: props.layout === "fixed" ? "max-content" : "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        zIndex: isZoomed ? 50 : 0,
        overflow: 'hidden',
        backgroundColor: isZoomed ? '#fff' : backgroundColor ?? 'transparent',
        cursor: isZoomed ? 'zoom-out' : 'zoom-in',
    }

    useEffect(() => {
        if (onZoom) {
            onZoom(isZoomed)
        }
    }, [isZoomed])

    return (
        <>
            {isZoomed ? (
                <div
                    style={{
                        backgroundColor: backgroundColor,
                        opacity: backgroundOpacity,
                        position: 'fixed',
                        zIndex: 40,
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }}
                    onClick={closeWrapper}
                />
            ) : null}
            <div style={styles} ref={containerRef} onClick={handleImageZoom}>
                {isZoomed ? null : (
                    <AbsoluteIcon>
                        <GallerySlideZoomIcon />
                    </AbsoluteIcon>
                )}
                {children}
            </div>
        </>
    )
}

export default ZoomWrapper
