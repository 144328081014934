import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'
import qr_code_icon from '../../../public/assets/icons/qr_code_icon.svg'
import Expander from '../expander/expander'
import { useEffect, useState, useRef } from 'react'

type StyledProps = {
    disabled?: boolean
    $height?: string
    $width?: string
    $iconSize?: string
    $alwaysHasText?: boolean
    $neverHasText?: boolean
}

const MainButton = styled.button<StyledProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 5px;
    height: ${(props) => (props.$height ? props.$height : '30px')};
    width: ${(props) => (props.$width ? props.$width : 'fit-content')};
    padding: 8px 8px;
    background-color: var(--off-bg-color, #fafafa);
    cursor: pointer;
    border: ${(props) =>
        props.disabled === true
            ? 'solid 1px var(--border_muted_neutral) !important'
            : 'solid 1px var(--primary_80)'};

    transition: all 200ms;

    :hover {
        transition: all 200ms;
        box-shadow: 2px 4px 7px var(--border-box);
        background-color: var(--bg-color, #fff);
        color: black;
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: 6px 6px;
    }

    @media ${device.mobile} {
        padding: 6px 6px;
    }
`

const BtnWrapper = styled.div`
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
`
const Text = styled.div<StyledProps>`
    color: var(--primary, #5ec3ca);
    text-transform: uppercase;
    font-size: 12px;
    font-family: Lato;
    padding-left: 8px;
    line-height: 16px;
    ${(props) => props.$neverHasText && 'display: none;'};
    @media (min-width: 1200px) and (max-width: 1600px) {
        ${(props) =>
            (!props.$alwaysHasText || props.$neverHasText) && 'display: none;'}
    }
    @media ${device.mobile} {
        ${(props) =>
            (!props.$alwaysHasText || props.$neverHasText) && 'display: none;'}
    }
`
const IconWrapper = styled.div<StyledProps>`
    width: ${(props) => (props.$iconSize ? props.$iconSize : '18px')};
    height: ${(props) => (props.$iconSize ? props.$iconSize : '18px')};
    display: flex;
    justify-content: center;
    align-items: center;
`
const Icon = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const DropdownOptionsWrapper = styled.div<{ $isOpen: boolean }>`
    height: 65px;
    width: 100%;
    box-sizing: border-box;
    min-width: 100px;
    position: absolute;
    bottom: -65px;
    right: 0;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    box-shadow: 4px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08));
    background-color: transparent;
    transform: ${(props) => (props.$isOpen ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: top;
    transition: all 200ms;
`

const DropdownOptionsItem = styled(motion.div)`
    padding: 7px 10px;
    color: var(--primary, #5ec3ca);
    text-transform: uppercase;
    font-size: 12px;
    font-family: Lato;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color, #fff);
`

type IDropDowmnOptions = {
    id: string
    text: string
    action: any
}

type Props = {
    disabled?: boolean
    onClick?: any
    dataCyId?: string | undefined
    txt: string
    dropDownOptions?: IDropDowmnOptions[]
    height?: string
    width?: string
    iconSize?: string
    alwaysHasText?: boolean
    neverHasText?: boolean
    bgColor?: string
    borderColor?: string
}

const QrCodeBtn: React.FunctionComponent<Props> = (props) => {
    let {
        onClick,
        dropDownOptions,
        disabled,
        txt,
        dataCyId,
        height,
        width,
        iconSize,
        alwaysHasText,
        neverHasText,
        bgColor,
        borderColor,
    } = props

    const [showDropDown, setShowDropDown] = useState(false)
    const dropDownRef = useRef(null)

    const handleClickOutsideDropdown = (e: any, ref?: any) => {
        if (ref && ref.contains(e.target) === false) {
            setShowDropDown(false)
        }
    }

    useEffect(() => {
        let node = dropDownRef.current
        document.addEventListener('mousedown', (e: any) =>
            handleClickOutsideDropdown(e, node)
        )

        return () => {
            document.removeEventListener('mousedown', (e: any) =>
                handleClickOutsideDropdown(e, node)
            )
        }
    }, [])

    return (
        <BtnWrapper>
            <motion.div whileTap={{ scale: 1.05 }}>
                <MainButton
                    style={
                        bgColor && borderColor
                            ? {
                                  backgroundColor: bgColor,
                                  borderColor: borderColor,
                              }
                            : bgColor
                            ? { backgroundColor: bgColor }
                            : borderColor
                            ? { borderColor: borderColor }
                            : {}
                    }
                    $height={height}
                    $width={width}
                    onClick={
                        dropDownOptions
                            ? () => setShowDropDown(!showDropDown)
                            : onClick
                    }
                    disabled={disabled}
                    data-attr={dataCyId ? dataCyId : undefined}
                >
                    <IconWrapper $iconSize={iconSize}>
                        <Icon src={qr_code_icon} />
                    </IconWrapper>
                    <Text
                        $alwaysHasText={alwaysHasText}
                        $neverHasText={neverHasText}
                    >
                        {txt}
                    </Text>
                </MainButton>
            </motion.div>

            <DropdownOptionsWrapper $isOpen={showDropDown} ref={dropDownRef}>
                <Expander height={showDropDown ? 'auto' : 0}>
                    {dropDownOptions?.map((item, index) => (
                        <DropdownOptionsItem
                            whileTap={{ scale: 1.1 }}
                            key={index}
                            style={{
                                borderBottom:
                                    dropDownOptions &&
                                    index === dropDownOptions?.length - 1
                                        ? 'none'
                                        : '1px solid var(--border-muted, #e5e5e5)',
                            }}
                            onClick={() => {
                                item.action()
                                setShowDropDown(false)
                            }}
                        >
                            {item.text}
                        </DropdownOptionsItem>
                    ))}
                </Expander>
            </DropdownOptionsWrapper>
        </BtnWrapper>
    )
}

export default QrCodeBtn
