import styled from 'styled-components'

const WrapperDesktop = styled.section`
    width: 100%;
    box-sizing: border-box;
    display: flex;
`

const CompletionRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const ProgressBarContainer = styled.div`
    border: 1px #5ec3ca solid;
    display: flex;
    position: relative;
    height: 4px;
    width: 230px;
    border-radius: 5px;
`

type ProgressProps = {
    progressPercent: number
}

const ProgressBar = styled.div<ProgressProps>`
    display: flex;
    position: relative;
    height: 100%;
    width: ${(props) => `${props.progressPercent}%`};
    background-color: #5ec3ca;
    border-radius: 5px;
`

const Completed = styled.div`
    color: #676767;
    font-size: 14px;
    font-family: Lato;
    padding-left: 20px;
`

interface Props {
    currentFileUploaded?: any
    progressPercent?: any
}

const LoadingAttachmentDesktop = (props: Props) => {
    const { progressPercent } = props

    return (
        <WrapperDesktop>
            <CompletionRow>
                <ProgressBarContainer>
                    <ProgressBar
                        progressPercent={progressPercent ? progressPercent : 0}
                    />
                </ProgressBarContainer>

                {progressPercent === 100 && <Completed>100%</Completed>}
            </CompletionRow>
        </WrapperDesktop>
    )
}

export default LoadingAttachmentDesktop
