import { call, fork, select, take, put } from 'redux-saga/effects'
import { api } from '../../../services'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    convertToExternalCarState,
    convertToGalleryImagesState,
} from '../../../conversions/entities/conversionFromAPI'
import { IRootState } from '../../../store'
import { IExternalGarageAPIresponse } from '../../../services/typedefinitions/apiPayloads'
import { carActions } from '../../cars/reducer'
import {
    IExternalCarsObject,
    ICustomErrorData,
    IReduxError,
} from '../../cars/types'
import { IGalleryImagesObject } from '../../galleries/types'
import { IExternalGarageObject } from '../types'
import { galleriesActions } from '../../galleries/reducer'
import { garagesActions } from '../reducer'

const getExternalCarsDataState = (state: IRootState) =>
    state.entities.carsData.directly_shared_ext_cars

const getExternalGarageState = (state: IRootState) =>
    state.entities.garagesData.directly_shared_external_garages
const getGalleryState = (state: IRootState) =>
    state.entities.galleriesData.images

export function* getExternalGarageByIdSaga(garageid: string) {
    try {
        const res: IExternalGarageAPIresponse = yield call(
            api.entities.getData.getGarageData_api,
            garageid
        )
        let external_car_ids: string[] = []
        if (res.cars && res.cars.length > 0) {
            const externalCarsDataState: IExternalCarsObject = yield select(
                getExternalCarsDataState
            )

            const galleryDataState: IGalleryImagesObject | null = yield select(
                getGalleryState
            )

            //get car ids

            let external_car_objects: IExternalCarsObject =
                externalCarsDataState ? { ...externalCarsDataState } : {}

            let galleryImagesObject: IGalleryImagesObject = galleryDataState
                ? {
                      ...galleryDataState,
                  }
                : {}

            for (const item of res.cars) {
                // add id to car ids list
                external_car_ids.push(item.uid)

                if (!externalCarsDataState[item.uid]) {
                    // convert to car state payload

                    let external_car_to_add: IExternalCarsObject =
                        convertToExternalCarState(item)
                    external_car_to_add[item.uid].has_limited_garage_info = true

                    let gallery_images_object_to_add: IGalleryImagesObject =
                        convertToGalleryImagesState(item)

                    Object.assign(
                        galleryImagesObject,
                        gallery_images_object_to_add
                    )
                    Object.assign(external_car_objects, external_car_to_add)
                }
            }

            yield put(
                galleriesActions.setGalleryImagesSuccess(galleryImagesObject)
            )

            yield put(
                carActions.getExternalCarsSharedWithYouSuccess(
                    external_car_objects
                )
            )
        }

        const external_garages_in_state: IExternalGarageObject = yield select(
            getExternalGarageState
        )

        const externalGaragesObject = external_garages_in_state
            ? { ...external_garages_in_state }
            : {}

        let external_garage_to_add: IExternalGarageObject = {
            [res.uid]: {
                uid: res.uid,
                owner: {
                    uid: res.owner.uid,
                    display_name: res.owner.display_name
                        ? res.owner.display_name
                        : 'Under cover',

                    location:
                        res.owner.address && res.owner.address[0]
                            ? `${res.owner.address[0].locality}, ${res.owner.address[0].country}`
                            : 'unknown',
                    profile_picture: res.owner.profile_image_url
                        ? {
                              id: `profile_pic_of${res.owner.uid}`,
                              url: res.owner.profile_image_url,
                          }
                        : undefined,
                    bio: res.owner.bio ? res.owner.bio : 'no bio',
                },
                cars_list: external_car_ids,
            },
        }

        Object.assign(externalGaragesObject, external_garage_to_add)

        yield put(
            garagesActions.getExternalGaragesSharedWithYouSuccess(
                externalGaragesObject
            )
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getExternalGarageByIdSaga,
                payload: garageid,
            }
            yield call(unauthHandlerSaga, payload)
        } else if (error.status === 403) {
            let customErrorData: ICustomErrorData = {
                custom_message: `You don't have permissions to access details of this page.`,
                custom_user_action_text: 'Go back',
                custom_redirect_path: '/sharing/with-you?entity=garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'shared_with_you_garage',
                false
            )
            yield put(
                garagesActions.getExternalGaragesSharedWithYouError(customError)
            )
            return
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `This garage doesn not exist or you don't have permissions to access details of this page.`,
                custom_user_action_text: 'Go back',
                custom_redirect_path: '/sharing/with-you?entity=garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'shared_with_you_garage',
                false
            )
            yield put(
                garagesActions.getExternalGaragesSharedWithYouError(customError)
            )
            return
        }
    }
}

// WATCHER FUNCTION : watcherGetExternalGarageByUserId*

function* watcherGetExternalGarageByUserId() {
    while (true) {
        let { payload } = yield take(
            garagesActions.getExternalGaragesSharedWithYouRequest
        )
        yield call(getExternalGarageByIdSaga, payload)
    }
}

const directly_shared_with_you_external_garage_sagas: any[] = [
    fork(watcherGetExternalGarageByUserId),
]

export default directly_shared_with_you_external_garage_sagas
