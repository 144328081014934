import React, { useState } from 'react'
import styled from 'styled-components'
import InfoIcon from '../icons/infoIcon'

const Tooltip = styled.div`
    position: relative;
`

const TooltipContent = styled.div<{
    $width?: number
    $tooltipPlacement?: 'left' | 'right'
    $isOpen: boolean
}>`
    position: absolute;
    top: 0;
    right: 0;
    width: ${(props) => (props.$width ? `${props.$width}px` : '248px')};
    transform: ${(props) =>
        props.$width
            ? props.$tooltipPlacement === 'left'
                ? `translate(-24px, 0px)`
                : `translate(${props.$width + 24}px, -14px)`
            : props.$tooltipPlacement === 'left'
            ? 'translate(-24px, 0px)'
            : 'translate(272px, -14px)'};
    display: flex;
    gap: 8px;
    padding: 12px;
    padding-bottom: 10px;
    border-radius: 4px;
    transition: opacity 200ms, translateY 0ms;
    z-index: ${(props) => (props.$isOpen ? 102 : -1)};

    // for small desktops and ipad pros
    @media (min-width: 880px) and (max-width: 1100px) {
        width: ${(props) =>
            props.$width
                ? `${props.$width}px`
                : props.$tooltipPlacement !== 'left'
                ? '150px'
                : '248px'};
        transform: ${(props) =>
            props.$width
                ? props.$tooltipPlacement === 'left'
                    ? `translate(-24px, 0px)`
                    : `translate(${props.$width}px, -14px)`
                : props.$tooltipPlacement === 'left'
                ? 'translate(-24px, 0px)'
                : 'translate(168px, -14px)'};
    }
`

const Text = styled.div`
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    transform: translateY(-4px);
`

interface Props {
    children: React.ReactNode
    tooltipText: string
    color?: string
    width?: number
    tooltipPlacement?: 'left' | 'right'
}

const InputFieldTooltip: React.FunctionComponent<Props> = (props) => {
    let { children, tooltipText, color, width, tooltipPlacement } = props
    const [isOpen, toggle] = useState(false)

    return (
        <Tooltip
            onMouseEnter={() => toggle(true)}
            onMouseLeave={() => toggle(false)}
            onClick={() => toggle(!isOpen)}
        >
            <TooltipContent
                $isOpen={isOpen}
                style={{
                    backgroundColor: color ?? '#b3b3b3',
                    opacity: isOpen ? 1 : 0,
                    zIndex: isOpen ? undefined : -1,
                    display: isOpen ? 'flex' : 'none',
                }}
                $width={width}
                $tooltipPlacement={tooltipPlacement}
            >
                <InfoIcon size="14" color={'#fff'} />
                <Text>{tooltipText}</Text>
            </TooltipContent>
            <div>{children}</div>
        </Tooltip>
    )
}

export default InputFieldTooltip
