import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as ReactScroll from 'react-scroll'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CarShareTopHeaderDesktop from '../../molecules/carShareTopHeader/carShareTopHeaderDesktop'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import SingleCarShareBodyDesktop from '../../molecules/singleCarShareBody/singleCarShareBodyDesktop'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import SingleCarShareBodyMobile from '../../molecules/singleCarShareBody/singleCarShareBodyMobile'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import Faded from '../../templates/animated/faded'
import QrCodeModalDesktop from '../../molecules/qrCodeModal/qrCodeModalDesktop'
import QrCodeModalMobile from '../../molecules/qrCodeModal/qrCodeModalMobile'
import QrCodeCustomiseStickerModalDesktop from '../../molecules/qrCodeCustomiseStickerModal/qrCodeCustomiseStickerModalDesktop'
import QrCodeStickerBottomSheetMobile from '../../molecules/qrCodeCustomiseStickerModal/qrCodeStickerBottomSheetMobile'
import AdjustableLoader from '../../atoms/loader/adjustableLoader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import {
    useAppDispatch,
    useAppSelector,
    useThunkAppDispatch,
} from '../../../redux/store/hooks'
import { carActions } from '../../../redux/entities/cars/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { ICar } from '../../../redux/entities/cars/types'
import {
    IDeleteShareReq,
    IFilterCarSharesDisplay_change_request,
    ISearchQueryCarSharesDisplay_change_request,
    ISortCarSharesDisplay_change_request,
} from '../../../redux/entities/cars/actions'

const CarShares: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const isCarLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const carsData = useAppSelector((state) => state.entities.carsData.cars)

    const garagesData = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    const qrCodeModal = useAppSelector(
        (state) => state.editForms && state.editForms.formsData.qrCodeModal
    )
    const qrCodeStickerModal = useAppSelector(
        (state) =>
            state.editForms && state.editForms.formsData.qrCodeStickerModal
    )
    const predefinedQrCodeStickerThemes = useAppSelector(
        (state) => state.localdata.stickerPredefinedThemes
    )
    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )

    const getNecessaryData = () => {
        let userData = userLoggedIn
        let garageid =
            userData && userData.owns_garage && userData.owns_garage.uid

        const q_params = new URLSearchParams(location.search)
        let carid = q_params.get('carid')

        if (carid && !carsData[carid]) {
            dispatch(carActions.getCarDataByIdRequest(`${carid}`))
        }

        if (userData && carid && garageid) {
            dispatch(carActions.getPublicSharesCarRequest(carid))
            dispatch(carActions.getOwnedDirectSharesCarRequest(carid))
            garageid &&
                dispatch(
                    garagesActions.getOwnedDirectSharesGarageRequest(garageid)
                )
        }
    }

    const areCarSharesLoading: boolean = useAppSelector(
        (state) => state.entities.carsData.carSharesLoading
    )

    const dispatchThunks = useThunkAppDispatch()

    useEffect(() => {
        const q_params = new URLSearchParams(location.search)
        let carid = q_params.get('carid')
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/sharing/with-others/car?carid=${carid}`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else {
            if (carid) {
                dispatchThunks(carActions.setCurrentCar(carid))
            }
            getNecessaryData()
        }

        let id = q_params.get('id')

        if (id === 'car_private_m') {
            scrollToElement('car_privately_shared_mobile')
        }

        if (id === 'car_private_d') {
            scrollToElement('car_privately_shared_desktop')
        }

        let qr_id = q_params.get('qr_id')
        let openCarQrCodeModal = () =>
            dispatch(
                editFormsActions.toggleQrCodeModal({
                    isOpen: true,
                    id: 'car_only',
                })
            )

        let openGarageQrCodeModal = () =>
            dispatch(
                editFormsActions.toggleQrCodeModal({
                    isOpen: true,
                    id: 'car_via_garage',
                })
            )
        if (qr_id === 'car_only') {
            setTimeout(openCarQrCodeModal, 200)
        } else if (qr_id === 'car_via_garage') {
            setTimeout(openGarageQrCodeModal, 200)
        }
    }, [location.search, userLoggedIn])

    useEffect(() => {
        if (userLoggedIn) {
            const q_params = new URLSearchParams(location.search)
            let carid = q_params.get('carid')
            carid && dispatchThunks(carActions.setCurrentCar(carid))
            getNecessaryData()
        }
    }, [userLoggedIn, location.search])

    const scrollToElement = (elementName: string) => {
        ReactScroll.scroller.scrollTo(elementName, {
            duration: 500,
            delay: 300,
            smooth: true,
            offset: -70,
        })
    }

    const q_params = new URLSearchParams(location.search)
    let carid = q_params.get('carid')

    let current: ICar | undefined =
        carsData && carid && carsData[carid] ? carsData[carid] : undefined

    let car_only_qr_code: string | undefined =
        current &&
        current.public_share_owned &&
        current.public_share_owned.qr_code_url
            ? current.public_share_owned.qr_code_url
            : undefined

    let car_via_garage_qr_code: string | undefined =
        current &&
        current.public_share_owned &&
        current.public_share_owned.qr_code_url_via_garage
            ? current.public_share_owned.qr_code_url_via_garage
            : undefined

    return (
        <React.Fragment>
            <DesktopDisplayOnly>
                <CenteredPageWrapper fullwidth>
                    {isCarLoading ? (
                        <div
                            style={{
                                position: 'relative',
                                height: '30vh',
                            }}
                        >
                            <AdjustableLoader isLoading={isCarLoading} />
                        </div>
                    ) : (
                        current &&
                        userLoggedIn &&
                        garagesData && (
                            <WindowPageTemplate
                                hasPositionRelative
                                isCollapsed={isCollapsed}
                                stickyHeaderContent={
                                    <CarShareTopHeaderDesktop
                                        goBackUrl="/sharing/with-others"
                                        onDeleteClick={() => {}}
                                        goToNext={() => {}}
                                        goToPrevious={() => {
                                            navigate('/sharing/with-others')
                                        }}
                                        carData={current}
                                        elementToScrollTo={null}
                                        removeUpDownNav
                                        customTitle={current.title}
                                    />
                                }
                            >
                                <SingleCarShareBodyDesktop
                                    handleChange_shares_search={(
                                        p: ISearchQueryCarSharesDisplay_change_request
                                    ) =>
                                        dispatch(
                                            carActions.searchQueryCarSharesDisplay_change_request(
                                                p
                                            )
                                        )
                                    }
                                    handleChange_shares_sort={(
                                        p: ISortCarSharesDisplay_change_request
                                    ) =>
                                        dispatch(
                                            carActions.sortCarSharesDisplay_change_request(
                                                p
                                            )
                                        )
                                    }
                                    handleChange_shares_filter={(
                                        p: IFilterCarSharesDisplay_change_request
                                    ) =>
                                        dispatch(
                                            carActions.filterCarSharesDisplay_change_request(
                                                p
                                            )
                                        )
                                    }
                                    car={current}
                                    resetSearch={(p: string) =>
                                        dispatch(
                                            carActions.resetSearchQueryCarShareDisplay_request(
                                                p
                                            )
                                        )
                                    }
                                    garage={
                                        garagesData[
                                            userLoggedIn.owns_garage.uid
                                        ]
                                    }
                                    createCarPublicShare={(p: string) =>
                                        dispatch(
                                            carActions.createCarPublicShareRequest(
                                                p
                                            )
                                        )
                                    }
                                    deletePublicLink={(p: IDeleteShareReq) =>
                                        dispatch(
                                            carActions.deleteCarPublicShareRequest(
                                                p
                                            )
                                        )
                                    }
                                    openQrCodeModal={(qr_id: string) =>
                                        dispatch(
                                            editFormsActions.toggleQrCodeModal({
                                                isOpen: true,
                                                id: qr_id,
                                            })
                                        )
                                    }
                                />
                                {qrCodeModal.isOpen &&
                                    (car_via_garage_qr_code ||
                                        car_only_qr_code) && (
                                        <Faded>
                                            <ModalDisplayAbsolute
                                                isOpen={qrCodeModal.isOpen}
                                                toggle={() => {}}
                                                top="10px"
                                                left="0px"
                                                right="20px"
                                                bottom="10px"
                                            >
                                                <QrCodeModalDesktop
                                                    qr_code_link={
                                                        qrCodeModal.id ===
                                                        'car_via_garage'
                                                            ? car_via_garage_qr_code
                                                                ? car_via_garage_qr_code
                                                                : ''
                                                            : car_only_qr_code
                                                            ? car_only_qr_code
                                                            : ''
                                                    }
                                                    qr_code_id={
                                                        'car_qr_code_to_export_desktop'
                                                    }
                                                    car_title={
                                                        current && current.title
                                                            ? current.title
                                                            : 'Untitled'
                                                    }
                                                    closeModal={() =>
                                                        dispatch(
                                                            editFormsActions.toggleQrCodeModal(
                                                                {
                                                                    isOpen: false,
                                                                    id: undefined,
                                                                }
                                                            )
                                                        )
                                                    }
                                                    isGarage={
                                                        qrCodeModal.id ===
                                                        'car_via_garage'
                                                    }
                                                    onStickerBtnClick={() =>
                                                        dispatch(
                                                            editFormsActions.toggleQrCodeStickerModal(
                                                                {
                                                                    isOpen: true,
                                                                    id: qrCodeModal.id,
                                                                }
                                                            )
                                                        )
                                                    }
                                                />
                                            </ModalDisplayAbsolute>
                                        </Faded>
                                    )}

                                {qrCodeStickerModal.isOpen &&
                                    (car_via_garage_qr_code !== undefined ||
                                        car_only_qr_code !== undefined) && (
                                        <Faded>
                                            <ModalDisplayAbsolute
                                                isOpen={
                                                    qrCodeStickerModal.isOpen
                                                }
                                                modalBackgroundColor="rgba(0, 0, 0, 0)"
                                                toggle={() => {}}
                                                top="10px"
                                                left="0px"
                                                right="20px"
                                                bottom="10px"
                                            >
                                                <QrCodeCustomiseStickerModalDesktop
                                                    qr_code_link={
                                                        qrCodeStickerModal.id ===
                                                        'car_via_garage'
                                                            ? car_via_garage_qr_code
                                                                ? car_via_garage_qr_code
                                                                : ''
                                                            : car_only_qr_code
                                                            ? car_only_qr_code
                                                            : ''
                                                    }
                                                    qr_code_id={
                                                        'car_qr_code_to_export_desktop'
                                                    }
                                                    car_title={
                                                        current && current.title
                                                            ? current.title
                                                            : 'Untitled'
                                                    }
                                                    closeModal={() =>
                                                        dispatch(
                                                            editFormsActions.toggleQrCodeStickerModal(
                                                                {
                                                                    isOpen: false,
                                                                    id: undefined,
                                                                }
                                                            )
                                                        )
                                                    }
                                                    isGarage={
                                                        qrCodeStickerModal.id ===
                                                        'car_via_garage'
                                                    }
                                                    predefinedStickerThemes={
                                                        predefinedQrCodeStickerThemes
                                                    }
                                                />
                                            </ModalDisplayAbsolute>
                                        </Faded>
                                    )}
                            </WindowPageTemplate>
                        )
                    )}
                </CenteredPageWrapper>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <PrivateShareReturnNav
                    customText="Shared with Others"
                    chevron
                    linkTo={'/sharing/with-others'}
                />
                <div style={{ paddingTop: '80px' }} />
                {current && userLoggedIn && garagesData && (
                    <SingleCarShareBodyMobile
                        handleChange_shares_search={(
                            p: ISearchQueryCarSharesDisplay_change_request
                        ) =>
                            dispatch(
                                carActions.searchQueryCarSharesDisplay_change_request(
                                    p
                                )
                            )
                        }
                        areCarSharesLoading={areCarSharesLoading}
                        handleChange_shares_sort={(
                            p: ISortCarSharesDisplay_change_request
                        ) =>
                            dispatch(
                                carActions.sortCarSharesDisplay_change_request(
                                    p
                                )
                            )
                        }
                        handleChange_shares_filter={(
                            p: IFilterCarSharesDisplay_change_request
                        ) =>
                            dispatch(
                                carActions.filterCarSharesDisplay_change_request(
                                    p
                                )
                            )
                        }
                        car={current}
                        resetSearch={(p: string) =>
                            dispatch(
                                carActions.resetSearchQueryCarShareDisplay_request(
                                    p
                                )
                            )
                        }
                        createCarPublicShare={(p: string) =>
                            dispatch(carActions.createCarPublicShareRequest(p))
                        }
                        garage={garagesData[userLoggedIn.owns_garage.uid]}
                        deletePublicLink={(p: IDeleteShareReq) =>
                            dispatch(carActions.deleteCarPublicShareRequest(p))
                        }
                        openQrCodeModal={(qr_id: string) =>
                            dispatch(
                                editFormsActions.toggleQrCodeModal({
                                    isOpen: true,
                                    id: qr_id,
                                })
                            )
                        }
                    />
                )}
                {qrCodeModal.isOpen &&
                    (car_via_garage_qr_code !== undefined ||
                        car_only_qr_code !== undefined) && (
                        <Faded>
                            <ModalDisplay
                                isOpen={qrCodeModal.isOpen}
                                toggle={() =>
                                    dispatch(
                                        editFormsActions.toggleQrCodeModal({
                                            isOpen: false,
                                            id: undefined,
                                        })
                                    )
                                }
                            >
                                <QrCodeModalMobile
                                    qr_code_link={
                                        qrCodeModal.id === 'car_via_garage'
                                            ? car_via_garage_qr_code
                                                ? car_via_garage_qr_code
                                                : ''
                                            : car_only_qr_code
                                            ? car_only_qr_code
                                            : ''
                                    }
                                    qr_code_id={'car_qr_code_to_export_desktop'}
                                    car_title={
                                        current && current.title
                                            ? current.title
                                            : 'Untitled'
                                    }
                                    closeModal={() =>
                                        dispatch(
                                            editFormsActions.toggleQrCodeModal({
                                                isOpen: false,
                                                id: undefined,
                                            })
                                        )
                                    }
                                    isGarage={
                                        qrCodeModal.id === 'car_via_garage'
                                    }
                                    onStickerBtnClick={() => {
                                        dispatch(
                                            editFormsActions.toggleQrCodeStickerModal(
                                                {
                                                    isOpen: true,
                                                    id: qrCodeModal.id,
                                                }
                                            )
                                        )
                                        dispatch(
                                            editFormsActions.toggleQrCodeModal({
                                                isOpen: false,
                                                id: undefined,
                                            })
                                        )
                                    }}
                                />
                            </ModalDisplay>
                        </Faded>
                    )}

                <QrCodeStickerBottomSheetMobile
                    qr_code_link={
                        qrCodeStickerModal.id === 'car_via_garage'
                            ? car_via_garage_qr_code
                                ? car_via_garage_qr_code
                                : ''
                            : car_only_qr_code
                            ? car_only_qr_code
                            : ''
                    }
                    title={
                        current && current.title ? current.title : 'Untitled'
                    }
                    qr_code_id={'car_qr_code_to_export_mobile'}
                    closeBottomSheet={() =>
                        dispatch(
                            editFormsActions.toggleQrCodeStickerModal({
                                isOpen: false,
                                id: undefined,
                            })
                        )
                    }
                    isBottomSheetOpen={qrCodeStickerModal.isOpen}
                    predefinedStickerThemes={predefinedQrCodeStickerThemes}
                />
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default CarShares
