import * as urls from '../urls'
import { removeUndefinedfromObject } from '../../helpers/objects'
import { customHeader } from '../headers'
import { apiSentryErrorHandler } from '../errorHandler'
import { IUSerGeneralInfoUpdatePayload } from '../../user/types'
import { addCategoryUserApi_args } from '../types'
import { IUserPayload } from './types'

export const getCurrentUser = (): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.current_user_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: IUserPayload) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get current user error')
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                        // let cloned: any = data
                        // cloned.status = response.status
                        // return Promise.reject(cloned)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const getUserDataApi = (idReceived: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.user_endpoint(idReceived), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: IUserPayload) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get user data error')
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                        // let cloned: any = data
                        // cloned.status = response.status
                        // return Promise.reject(cloned)
                    }
                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
export const addCategoryUserApi = (
    p: addCategoryUserApi_args
): Promise<any> => {
    let categoryData = removeUndefinedfromObject(p.categories)
    let rpq: string = JSON.stringify(categoryData)

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: rpq,
        credentials: 'include',
    }

    return fetch(urls.user_endpoint(p.id), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(response, 'Add category user error', rpq)
                return Promise.reject(response)
            } else return
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}

export const updateUserProfileInformationApi = (
    data: IUSerGeneralInfoUpdatePayload
): Promise<any> => {
    let rqp: string = JSON.stringify(data.data)
    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: rqp,
        credentials: 'include',
    }

    return fetch(urls.user_endpoint(data.id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: IUserPayload) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Update user profile info error',
                            rqp
                        )
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
