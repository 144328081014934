import { ITask, ITaskNormalisedTaskInfoList } from 'entityModels'
import React from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { OOtherTaskOptions, OTaskFilterNSort } from '../../../../ol/ol'
import { convertToDisplayedTaskObj } from '../../../../redux/conversions/tasks/displayTaskConversions'
import TaskInfoListElementMobile from '../../../atoms/list/taskInfoListElementMobile'
import { device } from '../../../templates/displays/devices'
import dayjs from 'dayjs'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
    }

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        height: auto;
    }

    @media ${device.ipad} {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        height: auto;
    }
`

interface Props {
    task: ITask
    carsData: any
    garageId: string
    openUpdateTaskForm: any
    updateTask: any
    isEveryCar?: boolean
}

const TaskInfoTableMobile: React.FC<Props> = ({
    task,
    carsData,
    openUpdateTaskForm,
    updateTask,
    isEveryCar,
}) => {
    const navigate = useNavigate()
    const { carid } = useParams<{ carid: string }>()
    const location = useLocation()

    const infoObj: ITaskNormalisedTaskInfoList = convertToDisplayedTaskObj(task)

    const today = new Date()

    const isDueDateInThePast =
        task && task.due && dayjs(task.due).isValid()
            ? dayjs(task.due).isBefore(today)
            : undefined

    const all_info_ids_list: string[] = [
        'linkedToCar',
        'due',
        'reminders',
        'status',
    ]

    const generateLinkTitle = () => {
        if (task.linkedToCar) {
            if (isEveryCar) {
                return 'Every Car'
            } else if (
                carsData[task.linkedToCar[0]] &&
                task.linkedToCar.length === 1
            ) {
                if (
                    carsData[task.linkedToCar[0]] &&
                    carsData[task.linkedToCar[0]].title
                ) {
                    return `${
                        carsData[task.linkedToCar[0]].title.slice(0, 20) + '...'
                    }`
                } else return 'untitled car'
            } else if (task.linkedToCar.length > 1) {
                if (
                    carsData[task.linkedToCar[0]] &&
                    carsData[task.linkedToCar[0]].title
                ) {
                    return `${
                        carsData[task.linkedToCar[0]].title.slice(0, 17) + '...'
                    } + ${task.linkedToCar.length - 1}`
                } else return 'untitled car'
            }
        } else return OOtherTaskOptions.general_task.name
    }

    const renderTableList = () =>
        all_info_ids_list.map((taskElem: string, index: number) => {
            if (infoObj[taskElem]) {
                if (taskElem === 'linkedToCar') {
                    infoObj[taskElem] = {
                        ...infoObj[taskElem],
                        answer: generateLinkTitle(),
                        answers: task.linkedToCar,
                    }
                } else if (taskElem === 'reminder') {
                    if (task.reminders) {
                        infoObj[taskElem] = {
                            ...infoObj[taskElem],
                            answer: task.reminders[0],
                        }
                    } else {
                        infoObj[taskElem] = {
                            ...infoObj[taskElem],
                            answer: task.reminders,
                        }
                    }
                }

                const currentPath = location.pathname
                const isCarPage = currentPath.match(/car/g) !== null && carid

                return (
                    <React.Fragment key={`${taskElem}_${index}_mobile`}>
                        <TaskInfoListElementMobile
                            openUpdateTaskForm={openUpdateTaskForm}
                            key={index}
                            obj={infoObj[taskElem]}
                            noDueDateSet={
                                !infoObj['due'].answer ||
                                infoObj['due'].answer === '-'
                            }
                            isDueDateInThePast={isDueDateInThePast}
                            createReminders={() =>
                                isCarPage
                                    ? navigate(
                                          `/car/${carid}/tasks/task?taskid=${task.id}&reminders=true&viewid=create`
                                      )
                                    : navigate(
                                          `/tasks/task?taskid=${task.id}&reminders=true&viewid=create`
                                      )
                            }
                            viewReminders={() =>
                                isCarPage
                                    ? navigate(
                                          `/car/${carid}/tasks/task?taskid=${task.id}&reminders=true&viewid=view`
                                      )
                                    : navigate(
                                          `/tasks/task?taskid=${task.id}&reminders=true&viewid=view`
                                      )
                            }
                            updateTask={updateTask}
                            taskid={task.id}
                            color={
                                infoObj[taskElem].id === 'status' &&
                                infoObj[taskElem].answer ===
                                    OTaskFilterNSort.filter_by.completed.name
                                    ? 'green'
                                    : infoObj[taskElem].id === 'status' &&
                                      infoObj[taskElem].answer ===
                                          OTaskFilterNSort.filter_by.outstanding
                                              .name
                                    ? '#df6f6f'
                                    : undefined
                            }
                        />
                    </React.Fragment>
                )
            } else return <div key={index} />
        })

    return <Wrapper>{task ? renderTableList() : null}</Wrapper>
}

export default TaskInfoTableMobile
