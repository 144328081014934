import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import VehicleItemDesktop from '../../../atoms/list/vehicleItemDesktop'
import VehicleItemMobile from '../../../atoms/list/vehicleItemMobile'
import { device } from '../../../templates/displays/devices'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import Expander from '../../../atoms/expander/expander'
import {
    IAnyObject,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { insurance_colours } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import FadedSlower from '../../../templates/animated/FadedSlower'
import CircleLoader from '../../../atoms/loader/circleLoader'
import { IRootState } from '../../../../redux/store'

const Text = styled.div`
    color: var(--text-default, #666);
    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
    width: 100%;
    box-sizing: border-box;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const Line = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    margin-top: 40px;
    margin-bottom: 40px;
    @media ${device.mobile_and_ipad} {
        margin-top: 32px;
        margin-bottom: 32px;
    }
`

const Column = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`

const RowSpaceBetween = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const TxtBtn = styled.button`
    all: unset;
    text-align: center;
    font-size: 16px;
    font-family: Lato-bold;
    line-height: 24px;
    letter-spacing: 0.001px;
    cursor: pointer;
`

type Props = {
    selected: string[]
    updateSelected: (ids: string[]) => void
    isMobile?: boolean
    fromWizard?: boolean
}

const EverydayCarSelection = (props: Props) => {
    let carsData = useAppSelector((state) => state.entities.carsData.cars)

    let insuranceQuoteApplication = useAppSelector(
        (state) => state.insuranceQuoteApplication
    )

    let insurance_vehicles_data = insuranceQuoteApplication?.draft.vehicles

    let external_vehicles_list = useAppSelector(
        (state) => state.insuranceQuoteApplication.draft.external_car_ids
    )

    let { selected, updateSelected, isMobile } = props

    let noEverydayCar = !selected || selected.length === 0 ? true : false
    let noExternalCar =
        !external_vehicles_list || external_vehicles_list.length === 0
            ? true
            : false

    const onSelect = (clickedCarId: string) => {
        // console.log('clickedCarId', clickedCarId)
        if (!selected || (selected !== undefined && selected.length === 0)) {
            return updateSelected([clickedCarId])
        }

        if (selected) {
            if (selected.includes(clickedCarId)) {
                let updated = selected.filter((id) => id !== clickedCarId)
                updateSelected(updated)
            } else {
                updateSelected([...selected, clickedCarId])
            }
        }
    }

    const dispatch = useAppDispatch()

    let isDraft = insuranceQuoteApplication.submitted_data?.status === 'DRAFT'

    let cleanedUpCarIds: string[] | undefined =
        carsData &&
        Object.keys(carsData).filter((carid) => {
            if (
                carsData[carid].archived_on === undefined &&
                carid !== 'new_car'
            )
                return carid
        })

    const { theme } = useThemes()

    const size: WindowSize = useWindowSize()

    let isMobilee = isMobile
        ? true
        : size && size.width && size.width < 880
        ? true
        : false

    let dataLoading = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.isLoading
    )

    return (
        <Column
            style={{
                padding: props.fromWizard ? 0 : 32,
            }}
        >
            <Text>
                Please select one or more cars from your garage which you use on
                daily basis. You can edit or fill in the details once the
                vehicle(s) is selected.
            </Text>

            <div style={{ paddingTop: 24 }} />
            <RowSpaceBetween>
                <Text style={{ fontFamily: 'Lato-bold', fontSize: 16 }}>
                    Cars in your garage
                </Text>
                {isDraft && cleanedUpCarIds && cleanedUpCarIds.length > 0 && (
                    <TxtBtn
                        style={{
                            color: insurance_colours[theme].sections[
                                'everyday_vehicles'
                            ].primary_100,
                        }}
                        onClick={() => updateSelected([])}
                    >
                        Clear
                    </TxtBtn>
                )}
            </RowSpaceBetween>
            <div style={{ paddingTop: 24 }} />
            {cleanedUpCarIds &&
                cleanedUpCarIds.map((car_id, i) => (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            paddingBottom: 16,
                        }}
                        key={`everydey_car_${car_id}_${i}_${
                            isMobilee ? 'mobile' : 'desktop'
                        }`}
                    >
                        {isMobilee ? (
                            <VehicleItemMobile
                                garageItem={carsData[car_id]}
                                showGarageCarTitle
                                variant="selectable"
                                isSelected={
                                    selected.includes(car_id) ? true : false
                                }
                                onSelect={(id: string) => {
                                    if (isDraft) {
                                        onSelect(id)
                                    }
                                }}
                                car_id={car_id}
                                sectionId={'everyday_vehicles'}
                                bg="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                        ) : (
                            <VehicleItemDesktop
                                car_id={car_id}
                                garageItem={carsData[car_id]}
                                showGarageCarTitle
                                variant="selectable"
                                isSelected={
                                    selected.includes(car_id) ? true : false
                                }
                                onSelect={(id: string) => {
                                    if (isDraft) {
                                        onSelect(id)
                                    }
                                }}
                                sectionId={'everyday_vehicles'}
                                bg="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                        )}
                    </div>
                ))}
            <Expander height={noEverydayCar || !noExternalCar ? 'auto' : 0}>
                <Column>
                    <Line />

                    {dataLoading ? (
                        <FadedSlower>
                            <div
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    height: 56,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CircleLoader
                                    size={24}
                                    color={
                                        insurance_colours[theme].sections[
                                            'everyday_vehicles'
                                        ].primary_100
                                    }
                                />
                            </div>
                        </FadedSlower>
                    ) : noExternalCar ? (
                        <Column>
                            <Text>
                                If your everyday car is not in your Custodian
                                garage, you can either add it to the Custodian
                                platform and check the box above or directly add
                                it below as an external car.
                            </Text>
                            <div style={{ paddingTop: 24 }} />
                            <ButtonAtom
                                theme="tertiary"
                                textTransform="none"
                                width={'100%'}
                                customBgColor="transparent"
                                color={
                                    insurance_colours[theme].sections[
                                        'everyday_vehicles'
                                    ].primary_100
                                }
                                fontSize={16}
                                onClick={() => {
                                    let payload: IAnyObject = {
                                        autoSelect: true,
                                    }

                                    if (props.fromWizard === true) {
                                        payload['fromWizard'] = true
                                    }
                                    dispatch(
                                        insuranceActions.create_external_vehicle_request(
                                            payload
                                        )
                                    )
                                }}
                            >
                                Add external car
                            </ButtonAtom>
                        </Column>
                    ) : (
                        <Column>
                            <RowSpaceBetween>
                                <Text
                                    style={{
                                        fontFamily: 'Lato-bold',
                                        fontSize: 16,
                                    }}
                                >
                                    External cars
                                </Text>
                                {isDraft && (
                                    <TxtBtn
                                        onClick={() => updateSelected([])}
                                        style={{
                                            color: insurance_colours[theme]
                                                .sections['everyday_vehicles']
                                                .primary_100,
                                        }}
                                    >
                                        Clear
                                    </TxtBtn>
                                )}
                            </RowSpaceBetween>
                            <div style={{ paddingTop: 24 }} />
                            {external_vehicles_list?.map((car_id, i) => (
                                <div
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        paddingBottom: 16,
                                    }}
                                    key={`everydey_car_external_${car_id}_${i}_${
                                        isMobilee ? 'mobile' : 'desktop'
                                    }`}
                                >
                                    {isMobilee ? (
                                        <VehicleItemMobile
                                            insuranceVehicleItem={
                                                insurance_vehicles_data[car_id]
                                            }
                                            variant="selectable"
                                            isSelected={
                                                selected.includes(car_id)
                                                    ? true
                                                    : false
                                            }
                                            onSelect={() => {
                                                onSelect(car_id)
                                            }}
                                            sectionId="everyday_vehicles"
                                        />
                                    ) : (
                                        <VehicleItemDesktop
                                            insuranceVehicleItem={
                                                insurance_vehicles_data[car_id]
                                            }
                                            variant="selectable"
                                            isSelected={
                                                selected.includes(car_id)
                                                    ? true
                                                    : false
                                            }
                                            onSelect={() => {
                                                onSelect(car_id)
                                            }}
                                            sectionId="everyday_vehicles"
                                        />
                                    )}
                                </div>
                            ))}
                        </Column>
                    )}
                </Column>
            </Expander>
        </Column>
    )
}

export default EverydayCarSelection
