import { IApexWeeklyEventState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import apex_calendar from '../../../../../public/assets/icons/apex_calendar.svg'
import location from '../../../../../public/assets/icons/location.svg'
import LoaderBackground from '../../../../atoms/loader/loaderBackground'
import external_link_green from '../../../../../public/assets/icons/external_link_green.svg'

const SubColumnRight1Desktop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    justify-self: flex-start;
    font-family: Lato;
    flex: 3;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    min-height: 90vh;
    padding-bottom: 90px;
    min-height: 730px;

    @media ${device.smallest_laptops} {
        min-height: 710px;
    }

    @media ${device.large_desktop} {
        min-height: 56vh;
    }

    background: var(--modal-side-sheet-pop-over-bg, #fff);
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media ${device.ipad} {
        align-self: flex-end;
    }
`

// SectionTitle
const SectionTitle = styled.div`
    text-align: left;
    align-self: flex-start;
    font-family: Lato-Light;
    color: var(--primary, #5ec3ca);
    text-transform: uppercase;

    font-size: 20px;
    padding-top: 60px;
    padding-bottom: 50px;
    padding-left: 60px;

    @media ${device.smallest_laptops} {
        font-size: 18px;
    }

    @media ${device.large_desktop} {
        padding-bottom: 60px;
        font-size: 21px;
        padding-left: 60px;
    }

    @media ${device.ipad} {
        padding-left: 30px;
    }
`

const Title = styled.a`
    font-family: Lato;
    color: var(--primary, #5ec3ca) !important;

    padding-bottom: 10px;

    font-size: 16px;

    @media ${device.large_desktop} {
        font-size: 17px;
    }
`

const ExternalLinkIcon = styled.img`
    width: 11px;
    height: 11px;
    transform: translateY(-1px);
`

//

const EventWrapper = styled.div`
    padding-bottom: 30px;

    padding-left: 50px;
    padding-right: 40px;

    color: var(--text-darker, #616161);

    @media ${device.large_desktop} {
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 40px;
    }

    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }
`

const InfoText = styled.div`
    font-size: 14px;
    color: var(--text-darker, #616161);

    @media ${device.large_desktop} {
        font-size: 15px;
    }

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
    justify-self: flex-start;
    padding-top: 4px;

    @media ${device.ipad} {
        font-size: 12px;
    }
`

const CalendarIcon = styled.img`
    height: 16px;
    width: 13px;
    transform: translateY(2px);
    margin-right: 10px;
    margin-left: 1px;
`

const LocationIcon = styled.img`
    height: 16px;
    transform: translateY(2px);
    margin-right: 10px;
`

const Line = styled.div`
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
    width: 35px;
    margin-top: 20px;

    margin-left: 50px;
    margin-right: 40px;

    @media ${device.large_desktop} {
        margin-left: 60px;
        margin-right: 60px;
    }
`

const SmallDash = styled.div`
    background-color: var(--primary, #5ec3ca);
    height: 1px;
    width: 50px;
    margin-top: 15px;
`

type Props = {
    events: IApexWeeklyEventState[] | undefined
}

const WeeklyEventsBoxDesktop: React.FC<Props> = ({ events }) => {
    const renderEventInfo = (
        elocation: string,
        start?: string,
        end?: string
    ) => {
        if (start !== undefined && end === undefined) {
            return (
                <div>
                    <InfoText>
                        <CalendarIcon />
                        {start}
                    </InfoText>

                    <InfoText>
                        <LocationIcon />

                        {elocation}
                    </InfoText>
                </div>
            )
        } else if (start && end && start === end) {
            return (
                <div>
                    <InfoText>
                        <CalendarIcon src={apex_calendar} alt="icon" />

                        {start}
                    </InfoText>
                    <InfoText>
                        <LocationIcon src={location} alt="icon" />

                        {elocation}
                    </InfoText>
                </div>
            )
        } else if (start && end && start !== end) {
            return (
                <div>
                    <InfoText>
                        <CalendarIcon src={apex_calendar} alt="icon" />
                        {start} - {end}
                    </InfoText>
                    <InfoText>
                        <LocationIcon src={location} alt="icon" />

                        {elocation}
                    </InfoText>
                </div>
            )
        }
    }

    return (
        <SubColumnRight1Desktop>
            <SectionTitle>
                {' '}
                Events Ahead
                <SmallDash />
            </SectionTitle>

            {!events && <LoaderBackground height={'400px'} width={'100%'} />}

            {events &&
                events.map((event: IApexWeeklyEventState, index: number) => {
                    return (
                        <EventWrapper key={index}>
                            <Title href={event.link} target="_blank">
                                {event.title.split(' ').map((word, i) => {
                                    if (i < event.title.split(' ').length - 1) {
                                        return `${word} `
                                    } else {
                                        return (
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                }}
                                                key={`word_${i}`}
                                            >
                                                {word}
                                                <span
                                                    style={{
                                                        paddingLeft: '8px',
                                                    }}
                                                />
                                                <ExternalLinkIcon
                                                    src={external_link_green}
                                                />
                                            </span>
                                        )
                                    }
                                })}
                            </Title>

                            {renderEventInfo(
                                event.location,
                                event.event_date_start,
                                event.event_date_end
                            )}
                        </EventWrapper>
                    )
                })}

            <Line />
        </SubColumnRight1Desktop>
    )
}

export default WeeklyEventsBoxDesktop
