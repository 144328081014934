import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import './styles.css'
import ActionsMenuDots from '../../atoms/menu/actionsMenu/actionsMenuDots'
import 'react-medium-image-zoom/dist/styles.css'
import { Link, useNavigate } from 'react-router-dom'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import GalleryImChevron from '../../atoms/icons/GalleryImgChevron'
import { LocationIcon } from '../../atoms/icons/components'
import colours from '../../../providers/theme/colours'
import {
    IDeleteEntryGalleryImagePayload,
    IGalleryImage,
    IUpdateEntryGalleryImagePayload,
} from '../../../redux/entities/galleries/types'
import useThemes from '../../../providers/theme/hooks'
import ZoomWrapper from './zoomWrapper'

interface IProps {
    currentImageData: IGalleryImage
    closeGallery: () => any
    generateRequestedSlide?: () => void
    carid: string
    entryid: string
    imagesData: any
    onClickEdit: () => void
    isSomethingLoading: boolean
    prevUrl: string
    nextUrl: string
    deleteImage: (ids: IDeleteEntryGalleryImagePayload) => void
    updateEntryGalleryImage: (payload: IUpdateEntryGalleryImagePayload) => void
    featuredImagesCount: number
    isExternalCar?: boolean
}

const EntryGallerySlide: React.FC<IProps> = ({
    currentImageData,
    closeGallery,
    carid,
    entryid,
    onClickEdit,
    prevUrl,
    nextUrl,
    deleteImage,
    updateEntryGalleryImage,
    isExternalCar,
}) => {
    const [overallMenuOpenId, setOverallMenuOpenId] = useState<string | null>(
        null
    )
    const [isLoading, setIsLoading] = useState(true)
    const [imgLoaded, _] = useState<string | undefined>(undefined)

    const navigate = useNavigate()

    let { theme } = useThemes()

    const genUrl = (e?: React.KeyboardEvent) => {
        if (e) {
            e = e || window.event
            if (e.keyCode === 37 && prevUrl !== '/none') {
                navigate(prevUrl)
            } else if (e.keyCode === 39 && nextUrl !== '/none') {
                navigate(nextUrl)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', genUrl as any, { passive: true })
        return () => {
            document.removeEventListener('keydown', genUrl as any)
        }
    }, [])

    const manageOverallMenus = (id?: string) => {
        if (id === overallMenuOpenId) {
            setOverallMenuOpenId(null)
        } else {
            setOverallMenuOpenId(id || null)
        }
    }

    const handleDeletion = () => {
        let ids = {
            carid,
            entryid,
            imageid: currentImageData.id,
        }

        currentImageData && deleteImage(ids)
        closeGallery()
    }

    const handleOptionClickedOn = (optionClicked: string) => {
        if (
            optionClicked === 'set as gallery feature' &&
            currentImageData &&
            currentImageData.featured !== true
        ) {
            let payload: IUpdateEntryGalleryImagePayload = {
                carid,
                entryid,
                imageid: currentImageData.id,
                body: {
                    featured: true,
                },
            }
            updateEntryGalleryImage(payload)
        } else if (
            optionClicked === 'remove feature' &&
            currentImageData &&
            currentImageData.featured === true
        ) {
            let payload: IUpdateEntryGalleryImagePayload = {
                carid,
                entryid,
                imageid: currentImageData.id,
                body: {
                    featured: false,
                },
            }
            updateEntryGalleryImage(payload)
        }
    }

    const actions_items = () => {
        if (currentImageData) {
            if (currentImageData.featured === true) {
                return [
                    {
                        text: 'Update image details',
                        actionOnClick: onClickEdit,
                        idRequired: false,
                    },
                    {
                        text: 'Delete',
                        actionOnClick: handleDeletion,
                        color: 'red',
                        idRequired: true,
                    },
                    {
                        text: 'Remove feature',
                        actionOnClick: handleOptionClickedOn,
                        idRequired: true,
                        color: 'red',
                    },
                ]
            } else {
                return [
                    {
                        text: 'Update image details',
                        actionOnClick: onClickEdit,
                        idRequired: false,
                    },
                    {
                        text: 'Delete',
                        actionOnClick: handleDeletion,
                        color: 'red',
                        idRequired: true,
                    },
                ]
            }
        } else return []
    }

    return (
        <div style={{ position: 'fixed' }}>
            <SlideWrapper>
                <SlideImageBox>
                    <ZoomWrapper
                        percentage={90}
                        backgroundColor="var(--bg-color, #fff)"
                    >
                        {isLoading === true && (
                            <SkeletonAbsolute
                                isthingloading={isLoading}
                                darkTheme={false}
                            />
                        )}

                        <div
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                height: ' 100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            <SlideImage
                                src={imgLoaded ? imgLoaded : grey_placeholder}
                                srcSet={
                                    currentImageData &&
                                    currentImageData.large_srcset
                                        ? currentImageData.large_srcset
                                        : undefined
                                }
                                onLoad={() => setIsLoading(false)}
                                alt="car"
                            />
                        </div>
                    </ZoomWrapper>

                    {nextUrl !== '/none' ? (
                        <NextBlock to={nextUrl}>
                            <GalleryImChevron />
                        </NextBlock>
                    ) : null}

                    {prevUrl !== '/none' ? (
                        <PrevBlock to={prevUrl}>
                            <GalleryImChevron direction="left" />
                        </PrevBlock>
                    ) : null}
                </SlideImageBox>
                <TextBox>
                    <Row>
                        <Row>
                            <Col>
                                <div
                                    style={{
                                        paddingBottom: '3px',
                                        color: colours[theme].text_strong,
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {currentImageData &&
                                    currentImageData.uploader
                                        ? currentImageData.uploader
                                        : ''}
                                </div>

                                <Row>
                                    ©
                                    <PaddingSpan>
                                        {currentImageData &&
                                        currentImageData.credits
                                            ? currentImageData.credits
                                            : 'Unknown'}
                                    </PaddingSpan>
                                </Row>

                                <Row>
                                    <div
                                        style={{
                                            transform: 'translate(-1px, 1px)',
                                        }}
                                    >
                                        <LocationIcon size="14" />
                                    </div>
                                    <SpanNoPadding>
                                        {currentImageData &&
                                        currentImageData.location
                                            ? currentImageData.location
                                            : 'Unknown'}
                                    </SpanNoPadding>
                                </Row>
                            </Col>
                        </Row>
                        <Line />
                        <CaptionCol style={{ width: '78%' }}>
                            {currentImageData && currentImageData.caption
                                ? currentImageData.caption
                                : ''}
                        </CaptionCol>
                    </Row>

                    {!isExternalCar && (
                        <div
                            data-tut="car-gallery-image-tuto-desktop-1"
                            style={{ paddingLeft: '20px' }}
                        >
                            <ActionsMenuDots
                                menuOpened={overallMenuOpenId}
                                manageMenus={manageOverallMenus}
                                menuId={
                                    currentImageData && currentImageData.id
                                        ? currentImageData.id
                                        : ''
                                }
                                actions_items={actions_items()}
                            />
                        </div>
                    )}
                </TextBox>
            </SlideWrapper>
        </div>
    )
}

export default EntryGallerySlide

const SlideWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-strong, #1a1a1a);
    border-radius: 2px;
    height: 100%;
    max-height: 80vh;

    @media (max-width: 1180px) {
        margin-top: 160px;
        width: 68vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1180px) and (max-width: 1295px) {
        width: 64vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1295px) and (max-width: 1400px) {
        width: 55vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1400px) and (max-width: 1550px) {
        width: 54vw;
        max-width: 1100px;
        height: 75vh;
        max-height: 550px;
    }

    @media (min-width: 1550px) and (max-width: 1600px) {
        width: 1200px;
        height: 905px;
    }

    @media (min-width: 1600px) and (max-width: 1810px) {
        width: 1300px;
        height: 1000px;
    }

    @media (min-width: 1810px) {
        width: 1492px;
        height: 1100px;
        max-height: 70vh;
    }
`

const SlideImageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    background: var(--off-bg-color, #fafafa);
`

const SlideImage = styled.img`
    display: block;
    max-width: 100%;
    box-sizing: border-box;
    max-height: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    object-fit: contain;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
`

const TextBox = styled.div`
    z-index: 6;
    width: 100%;
    box-sizing: border-box;
    height: max-content;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
`

const Row = styled.div`
    color: var(--text-strong, #1a1a1a);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    font-family: Lato;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    min-width: 230px;
    text-align: left !important;
    color: black;
    font-size: 13px;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }
`

const CaptionCol = styled.div`
    width: 70%;
    font-family: Lato;
    padding-right: 10px;
    min-width: 230px;
    padding-left: 20px;

    font-size: 12px;

    @media ${device.large_desktop} {
        font-size: 14px;
    }
`

const Line = styled.div`
    height: 68%;
    justify-self: center !important;
    width: 1px;
    background-color: var(--text-muted);
`

const NextBlock = styled(Link)`
    position: absolute;
    right: -130px;

    @media (max-width: 1450px) {
        right: -100px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        right: -100px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
    z-index: 1;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`

const PrevBlock = styled(Link)`
    position: absolute;
    left: -120px;

    @media (max-width: 1450px) {
        left: -85px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        left: -94px;
    }
    height: 50px;
    width: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`

const PaddingSpan = styled.span`
    padding-left: 4px;
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
    @media ${device.smallest_laptops} {
        font-size: 10px;
    }

    @media ${device.large_desktop} {
        font-size: 11px;
    }
`

const SpanNoPadding = styled.span`
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
`
