import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import CustomAnimatedCheckboxField from './customAnimatedCheckboxField'

type IStyled = {
    $isDisabled?: boolean
}

const Row = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
`
const InfoTxt = styled(motion.div)<IStyled>`
    font-family: Lato;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    padding-right: 10px;
    color: ${(props: IStyled) => (props.$isDisabled ? '#bdbdbd' : '#666666')};

    cursor: ${(props: IStyled) =>
        props.$isDisabled ? 'not-allowed' : 'pointer'};
`

interface IFilterBottomSheetItemMobileProps {
    onChange?: (p: string) => void
    name?: string
    checked?: boolean
    onClick?: (p: string) => void
    isDisabled?: boolean
    text?: string
    notCapitalised?: boolean
    iconID?: 'email' | 'phone' | 'message' | string // more over time here
    borderColor?: string
    customActiveColour?: string
    customHighlightColour?: string
}

const CheckboxRowItem: React.FC<IFilterBottomSheetItemMobileProps> = ({
    checked,
    name,
    isDisabled,
    onChange,
    text,
}) => {
    const handleChange = () => {
        if (onChange && name) {
            onChange(name)
        }
    }

    return (
        <Row>
            <InfoTxt
                style={{ transform: 'translateY(-2px)' }}
                $isDisabled={isDisabled}
                onClick={handleChange}
            >
                {text}
            </InfoTxt>

            <CustomAnimatedCheckboxField
                small
                width="auto"
                hasWhiteBg
                onChange={() => {}}
                name={name}
                checked={checked}
                onClick={handleChange}
                isDisabled={isDisabled}
            />
        </Row>
    )
}

export default CheckboxRowItem
