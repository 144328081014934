import React from 'react'
import MobileLogoHeader from '../templates/logoheader/mobilelogoheader'
import DesktopDisplay from '../templates/displays/desktopDisplay'
import MobileDisplay from '../templates/displays/mobileDisplay'
import DesktopLogoHeader from '../templates/logoheader/desktoplogoheader'
import LogoBg from '../templates/logobg/logobg'

const ErrorPage: React.FC = () => {
    // const errorUser = useSelector((state: IRootState) => state.user.error)
    // const userLoggedIn = useSelector((state: IRootState) => state.user.userLoggedIn)

    return (
        <React.Fragment>
            <DesktopDisplay>
                <React.Fragment>
                    <LogoBg />

                    <DesktopLogoHeader />
                    <div
                        style={{
                            fontSize: '288px',
                            color: '#E2E2E2',
                            fontWeight: 'bold',
                            marginLeft: '8vw',
                            marginTop: '8vw',
                            fontFamily: 'Arial',
                        }}
                    >
                        404
                        <span
                            style={{
                                marginLeft: '20px',
                                fontSize: '64px',
                                color: '#E2E2E2',
                                fontWeight: 'bold',
                            }}
                        >
                            error
                        </span>
                    </div>
                </React.Fragment>
            </DesktopDisplay>
            <MobileDisplay>
                <div>
                    <MobileLogoHeader />
                    <div
                        style={{
                            fontSize: '96px',
                            color: '#8F8F8F',
                            fontWeight: 'bold',
                            marginLeft: '40px',
                            marginTop: '8vw',
                            fontFamily: 'Arial',
                        }}
                    >
                        404
                    </div>
                    <div
                        style={{
                            marginLeft: '140px',
                            fontSize: '48px',
                            color: '#8F8F8F',
                            fontWeight: 'bold',
                            transform: 'translateY(-20px)',
                        }}
                    >
                        error
                    </div>
                </div>
            </MobileDisplay>
        </React.Fragment>
    )
}

export default ErrorPage
