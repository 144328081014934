import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { menuActions } from '../../../redux/menus/reducer'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import TopHeaderFilterNavMobile from '../../organisms/smartHeadres/topHeaderFilterNav/topHeaderFilterNavMobile'
import FilterBottomSheetMenuMobile from '../../atoms/menu/bottomSheetMenu/filterMenuTimelineMobile'
import TimelineViewManagerMobile from '../../organisms/timelineViewManager/timelineViewManagerMobile'
import styled from 'styled-components'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { TimelineCreationTypeIdsEnum } from '../../../redux/timeline/timelineEnum'
import TimelineViewManagerDesktop from '../../organisms/timelineViewManager/timelineViewManagerDesktop'
import { sampleData } from '../../../redux/timeline/data'
import HistoryFilePageManagerHeaderDesktop from '../../molecules/carTimelineHeader/carTimelineHeaderDesktop'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import WrapperCarBannerImageFormDesktop from '../../organisms/editForms/imageFormWrapper/wrapperCarBannerImageFormDesktop'
import Loader from '../../atoms/loader/loader'
import { device } from '../../templates/displays/devices'
import NoFilterResults from '../../atoms/noFilterResults/noFilterResults'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { ITimelineCreateForms } from '../../../redux/editForms/types'
import {
    ICar,
    IExternalCar,
    ICarsObject,
    IExternalCarsObject,
} from '../../../redux/entities/cars/types'
import {
    ITimelineDataByCarNormalised,
    ISetFilterTimelineByCategoryNLabelNTitleQCarID,
    ITimelineEntryTypes,
} from '../../../redux/timeline/types'
import useThemes from '../../../providers/theme/hooks'

export enum TimelineViewEnum {
    small = 'small',
    fat = 'fat',
}

const PageColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

type IStyle = {
    show: boolean
}

const SmallPadding = styled.div<IStyle>`
    padding-top: ${(props) => (props.show ? '45px' : '0px')};
`
const WrapperHeaderStyle = styled.div`
    width: 80vw;
    max-width: 1500px;
    padding-bottom: 20px;

    @media ${device.beyond_ipad_mobile} {
        width: 78vw;
    }
    @media ${device.smallest_laptops} {
        width: 81vw;
    }
`

export const redirectToFilteredPath = (
    currentPath: string | undefined,
    categories: string[] | undefined,
    labels: string[] | undefined,
    scrolltoentryid?: string
): string | undefined => {
    let newPath = currentPath

    if (!categories || (categories && categories.length === 0)) {
        labels &&
            labels.forEach((label: string, i: number) => {
                if (i === 0) {
                    newPath = newPath + `?label=${label}`
                } else {
                    newPath = newPath + `&label=${label}`
                }
            })
    } else {
        categories.forEach((category: string, i: number) => {
            if (i === 0) {
                newPath = newPath + `?category=${category}`
            } else {
                newPath = newPath + `&category=${category}`
            }
        })

        labels &&
            labels.length > 0 &&
            labels.forEach((label: string, i: number) => {
                newPath = newPath + `&label=${label}`
            })
    }

    if (scrolltoentryid) {
        newPath = newPath?.includes('?')
            ? newPath + `&scrolltoid=${scrolltoentryid}`
            : newPath + `?scrolltoid=${scrolltoentryid}`
    }

    return newPath
}

interface Props {
    carid: string
    currentOwnedCar?: ICar
    prevOwned?: ICar
    sharedCar?: IExternalCar | undefined
    carsData: ICarsObject | IExternalCarsObject | undefined
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    setFilterTimelineItemsByCarIdCategoryIdsNLabelIds?: (
        payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
    ) => {}
    readOnlyMode?: boolean
}

const HistoryFilePageManager: React.FC<Props> = ({
    carid,
    currentOwnedCar,
    prevOwned,
    sharedCar,
    carsTimelineData,
    setFilterTimelineItemsByCarIdCategoryIdsNLabelIds,
    readOnlyMode,
}) => {
    const [state, setState] = useState({
        activeView: TimelineViewEnum.small,
        placeholderUrl: '',
        isSearching: false,
    })

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    let { theme } = useThemes()

    const isCarBannerImageFormOpen = useAppSelector(
        (state) => state.editForms.formsData.carBannerImageForm.isOpen
    )
    const attachmentsObj = useAppSelector(
        (state) => state.attachments.attachmentsById
    )

    const userTimelineCustomTags = useAppSelector(
        (state) => state.user.userLoggedIn?.labels
    )

    const category_searchable_items_list = useAppSelector(
        (state) => state.timeline.categories.category_searchable_items_list
    )
    const isTimelineDataLoading = useAppSelector(
        (state) => state.timeline.loading
    )
    const isUserLoading = useAppSelector((state) => state.user.loading)
    const userCurrency = useAppSelector(
        (state) =>
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id
    )
    const isFilterBottomSheetMenuOpen = useAppSelector(
        (state) => state.menus.mobileTimelineFilterBottomSheetMenu.isOpen
    )

    useEffect(() => {
        let placeholderUrl = randomCarSvgPlaceholderFunc(undefined, theme)
        setState((prevState) => ({ ...prevState, placeholderUrl }))
    }, [theme])

    useEffect(() => {
        return () => {
            dispatch(
                editFormsActions.manageTimelineCreationFormState({
                    isOpen: false,
                    current_step_index: 0,
                    active_creation: null,
                })
            )
        }
    }, [])

    const changeView = () => {
        setState((prevState) => ({
            ...prevState,
            activeView:
                prevState.activeView === TimelineViewEnum.small
                    ? TimelineViewEnum.fat
                    : TimelineViewEnum.small,
        }))
    }

    const updateIsSearching = (isSearching: boolean) => {
        setState((prevState) => ({ ...prevState, isSearching }))
    }

    const toggleFilterTimeline = (isOpen: boolean) =>
        dispatch(menuActions.toggleFilterBottomSheetMenu(isOpen))
    const toggleCarBannerImageForm = () =>
        dispatch(editFormsActions.toggleCarBannerImageForm())

    const manageTimelineCreationFormState = (obj: ITimelineCreateForms) =>
        dispatch(editFormsActions.manageTimelineCreationFormState(obj))

    let carsDataSearch =
        carsTimelineData &&
        carsTimelineData[carid] &&
        carsTimelineData[carid].search

    let activeCategories: ITimelineEntryTypes[] =
        carsDataSearch && carsDataSearch.activeCategoryIds
            ? carsDataSearch.activeCategoryIds
            : []
    let activeLabels: string[] =
        carsDataSearch && carsDataSearch.activeCustomLabelsIds
            ? carsDataSearch.activeCustomLabelsIds
            : []
    let activeEntryTitleQuery: string =
        carsDataSearch && carsDataSearch.activeEntryTitleQuery
            ? carsDataSearch.activeEntryTitleQuery
            : ''

    let currentPath = location.pathname

    let currentCar = sharedCar
        ? sharedCar
        : prevOwned
        ? prevOwned
        : currentOwnedCar ?? undefined

    const filterTimelineAndAddQueryParams = (
        payload: {
            categories: ITimelineEntryTypes[]
            labels: string[]
            entry_title?: string
        },
        resetResults?: boolean
    ) => {
        if (setFilterTimelineItemsByCarIdCategoryIdsNLabelIds) {
            if (payload.entry_title && payload.entry_title !== '') {
                setFilterTimelineItemsByCarIdCategoryIdsNLabelIds({
                    car_id: carid,
                    category_ids: payload.categories,
                    label_ids: payload.labels,
                    entry_title: payload.entry_title,
                    sendToAPI: true,
                    resetResults: resetResults,
                })
            } else {
                setFilterTimelineItemsByCarIdCategoryIdsNLabelIds({
                    car_id: carid,
                    category_ids: payload.categories,
                    label_ids: payload.labels,
                    entry_title: undefined,
                    sendToAPI: true,
                    resetResults: resetResults,
                })
            }
        }

        let newPath = redirectToFilteredPath(
            currentPath,
            payload.categories,
            payload.labels
        )
        newPath && navigate(newPath)
    }

    const displayedEntries = () => {
        if (carsDataSearch && carsDataSearch.results) {
            return carsDataSearch.results
        } else {
            if (
                currentCar &&
                carsTimelineData &&
                currentCar.id &&
                carsTimelineData[currentCar.id] &&
                carsTimelineData[currentCar.id].entries &&
                carsTimelineData[currentCar.id].entries.length > 0
            ) {
                return carsTimelineData[currentCar.id].entries
            } else return undefined
        }
    }

    const returnUrlForMobileTopNav = () => {
        if (sharedCar) {
            return `/shared/car/${carid}`
        } else if (prevOwned) {
            return `/archives/car/${carid}`
        } else {
            return location.state &&
                location.state.prevPath === '/history-files'
                ? '/history-files'
                : `/car/${carid}`
        }
    }

    return (
        <React.Fragment>
            <Loader isLoading={isTimelineDataLoading || isUserLoading} />
            <Faded>
                <DesktopDisplayOnly>
                    <CenteredPageWrapper fullwidth={true} removeHorizPaddings>
                        <PageColumnWrapper>
                            <HistoryFilePageManagerHeaderDesktop
                                categories={category_searchable_items_list}
                                tags={userTimelineCustomTags}
                                onAddNEwEntry={() => {
                                    navigate(
                                        `/car/${carid}/history-file/create`
                                    )
                                    manageTimelineCreationFormState({
                                        isOpen: true,
                                        current_step_index: 0,
                                        active_creation:
                                            TimelineCreationTypeIdsEnum.add_new_desktop,
                                    })
                                }}
                                selectedFilteringCategories={activeCategories}
                                selectedFilteringLabels={activeLabels}
                                activeEntryTitleQuery={
                                    carsDataSearch &&
                                    carsDataSearch.activeEntryTitleQuery
                                        ? carsDataSearch.activeEntryTitleQuery
                                        : ''
                                }
                                filterByCategories={(
                                    selectedCategories: ITimelineEntryTypes[]
                                ) =>
                                    filterTimelineAndAddQueryParams({
                                        categories: selectedCategories,
                                        labels: activeLabels,
                                        entry_title: activeEntryTitleQuery,
                                    })
                                }
                                filterByLabels={(selectedLabels: string[]) =>
                                    filterTimelineAndAddQueryParams({
                                        categories: activeCategories,
                                        labels: selectedLabels,
                                        entry_title: activeEntryTitleQuery,
                                    })
                                }
                                sendFiltersToApi={(payload: {
                                    categories: ITimelineEntryTypes[]
                                    labels: string[]
                                    entry_title?: string
                                }) =>
                                    filterTimelineAndAddQueryParams({
                                        categories: payload.categories,
                                        labels: payload.labels,
                                        entry_title: payload.entry_title,
                                    })
                                }
                                readOnlyMode={
                                    readOnlyMode === true ? true : false
                                }
                            />

                            {displayedEntries() !== undefined &&
                                !isTimelineDataLoading && (
                                    <TimelineViewManagerDesktop
                                        carid={carid}
                                        timelineItems={displayedEntries()}
                                        attachmentsObj={attachmentsObj}
                                        categories={
                                            category_searchable_items_list
                                        }
                                        tags={userTimelineCustomTags}
                                        selectedCategories={activeCategories}
                                        selectedLabels={activeLabels}
                                        activeEntryTitleQuery={
                                            activeEntryTitleQuery
                                        }
                                        withPlaceholder={
                                            currentCar &&
                                            carsTimelineData &&
                                            currentCar.id &&
                                            carsTimelineData[currentCar.id] &&
                                            carsTimelineData[currentCar.id]
                                                .entries &&
                                            carsTimelineData[currentCar.id]
                                                .entries.length > 0
                                                ? false
                                                : true
                                        }
                                        onPlaceholderClick={() => {
                                            navigate(
                                                `/car/${carid}/history-file/create`
                                            )
                                            manageTimelineCreationFormState({
                                                isOpen: true,
                                                current_step_index: 0,
                                                active_creation:
                                                    TimelineCreationTypeIdsEnum.add_new_desktop,
                                            })
                                        }}
                                        removeCriteria={(
                                            categories: ITimelineEntryTypes[],
                                            labels: string[],
                                            queryString?: string
                                        ) =>
                                            filterTimelineAndAddQueryParams({
                                                categories: categories,
                                                labels: labels,
                                                entry_title: queryString,
                                            })
                                        }
                                        clearAll={() =>
                                            filterTimelineAndAddQueryParams(
                                                {
                                                    categories: [],
                                                    labels: [],
                                                    entry_title: undefined,
                                                },
                                                true
                                            )
                                        }
                                        userCurrency={userCurrency}
                                        readOnlyMode={readOnlyMode}
                                    />
                                )}
                            {displayedEntries() === undefined &&
                                !isTimelineDataLoading &&
                                (!readOnlyMode ? (
                                    <TimelineViewManagerDesktop
                                        carid={carid}
                                        timelineItems={sampleData && sampleData}
                                        attachmentsObj={attachmentsObj}
                                        categories={
                                            category_searchable_items_list
                                        }
                                        tags={userTimelineCustomTags}
                                        selectedCategories={activeCategories}
                                        selectedLabels={activeLabels}
                                        activeEntryTitleQuery={
                                            activeEntryTitleQuery
                                        }
                                        withPlaceholder={
                                            currentCar &&
                                            carsTimelineData &&
                                            currentCar.id &&
                                            carsTimelineData[currentCar.id] &&
                                            carsTimelineData[currentCar.id]
                                                .entries &&
                                            carsTimelineData[currentCar.id]
                                                .entries.length > 0
                                                ? false
                                                : true
                                        }
                                        onPlaceholderClick={() => {
                                            navigate(
                                                `/car/${carid}/history-file/create`
                                            )
                                            manageTimelineCreationFormState({
                                                isOpen: true,
                                                current_step_index: 0,
                                                active_creation:
                                                    TimelineCreationTypeIdsEnum.add_new_desktop,
                                            })
                                        }}
                                        removeCriteria={(
                                            categories: ITimelineEntryTypes[],
                                            labels: string[],
                                            queryString?: string
                                        ) =>
                                            filterTimelineAndAddQueryParams({
                                                categories: categories,
                                                labels: labels,
                                                entry_title: queryString,
                                            })
                                        }
                                        hasSampleData
                                        userCurrency={userCurrency}
                                    />
                                ) : (
                                    <WrapperHeaderStyle>
                                        <NoDataRectangle text="no entries in history file" />
                                    </WrapperHeaderStyle>
                                ))}

                            {isCarBannerImageFormOpen && (
                                <ModalDisplay
                                    toggle={toggleCarBannerImageForm}
                                    isOpen={true}
                                    customZindex={20}
                                >
                                    <WrapperCarBannerImageFormDesktop
                                        close={toggleCarBannerImageForm}
                                        carid={carid}
                                    />
                                </ModalDisplay>
                            )}
                        </PageColumnWrapper>
                    </CenteredPageWrapper>
                </DesktopDisplayOnly>

                <CenteredPageWrapper>
                    <IpadAndMobileDisplay>
                        {!readOnlyMode && (
                            <TopHeaderFilterNavMobile
                                carid={carid}
                                returnUrl={returnUrlForMobileTopNav()}
                                onFilterIconClick={toggleFilterTimeline}
                                changeView={changeView}
                                currentSearch={activeEntryTitleQuery}
                                activeCategoryIds={
                                    carsDataSearch &&
                                    carsDataSearch.activeCategoryIds
                                        ? carsDataSearch.activeCategoryIds
                                        : []
                                }
                                activeCustomLabelsIds={
                                    carsDataSearch &&
                                    carsDataSearch.activeCustomLabelsIds
                                        ? carsDataSearch.activeCustomLabelsIds
                                        : []
                                }
                                activeEntryTitleQuery={
                                    carsDataSearch &&
                                    carsDataSearch.activeEntryTitleQuery
                                        ? carsDataSearch.activeEntryTitleQuery
                                        : ''
                                }
                                sendFiltersToApi={(payload: {
                                    categories: ITimelineEntryTypes[]
                                    labels: string[]
                                    entry_title?: string
                                }) =>
                                    filterTimelineAndAddQueryParams({
                                        categories: payload.categories,
                                        labels: payload.labels,
                                        entry_title: payload.entry_title,
                                    })
                                }
                                updateIsSearching={updateIsSearching}
                            />
                        )}
                        <SmallPadding
                            show={
                                carsDataSearch &&
                                ((carsDataSearch.activeCustomLabelsIds &&
                                    carsDataSearch.activeCustomLabelsIds
                                        .length > 0) ||
                                    (carsDataSearch.activeCategoryIds &&
                                        carsDataSearch.activeCategoryIds
                                            .length > 0))
                                    ? true
                                    : false
                            }
                        />

                        {!readOnlyMode && (
                            <FilterBottomSheetMenuMobile
                                toggle={(isOpen: boolean) =>
                                    toggleFilterTimeline(isOpen)
                                }
                                isFilterBottomSheetMenuOpen={
                                    isFilterBottomSheetMenuOpen
                                }
                                activeCriteria={
                                    carsDataSearch &&
                                    carsDataSearch.activeCategoryIds
                                }
                                criteriaData={
                                    category_searchable_items_list &&
                                    category_searchable_items_list
                                }
                                sendFiltersToApi={(
                                    categories: ITimelineEntryTypes[]
                                ) =>
                                    filterTimelineAndAddQueryParams({
                                        categories: categories,
                                        labels: activeLabels,
                                        entry_title: activeEntryTitleQuery,
                                    })
                                }
                            />
                        )}

                        {!readOnlyMode && (
                            <div style={{ paddingBottom: '93px' }} />
                        )}

                        {!state.isSearching &&
                        displayedEntries() !== undefined &&
                        !isTimelineDataLoading &&
                        displayedEntries()?.length === 0 &&
                        (activeCategories.length > 0 ||
                            activeLabels.length > 0 ||
                            activeEntryTitleQuery !== '') ? (
                            <NoFilterResults variant="mobile" />
                        ) : (
                            displayedEntries() !== undefined &&
                            !isTimelineDataLoading && (
                                <>
                                    <TimelineViewManagerMobile
                                        activeView={state.activeView}
                                        timelineItems={displayedEntries()}
                                        attachmentsObj={attachmentsObj}
                                        dataCyId="timeline-view-manager"
                                        withPlaceholder={
                                            currentCar &&
                                            carsTimelineData &&
                                            currentCar.id &&
                                            carsTimelineData[currentCar.id] &&
                                            carsTimelineData[currentCar.id]
                                                .entries &&
                                            carsTimelineData[currentCar.id]
                                                .entries.length > 0
                                                ? false
                                                : true
                                        }
                                        onPlaceholderClick={() => {
                                            navigate(
                                                `/car/${carid}/history-file/create`
                                            )
                                            manageTimelineCreationFormState({
                                                isOpen: true,
                                                current_step_index: 0,
                                                active_creation:
                                                    TimelineCreationTypeIdsEnum.add_new,
                                            })
                                        }}
                                        userCurrency={userCurrency}
                                        readOnlyMode={
                                            readOnlyMode === true ? true : false
                                        }
                                    />
                                </>
                            )
                        )}

                        {displayedEntries() === undefined &&
                            !isTimelineDataLoading &&
                            (!readOnlyMode ? (
                                <TimelineViewManagerMobile
                                    activeView={state.activeView}
                                    timelineItems={sampleData && sampleData}
                                    attachmentsObj={attachmentsObj}
                                    dataCyId="timeline-view-manager"
                                    withPlaceholder
                                    onPlaceholderClick={() => {
                                        navigate(
                                            `/car/${carid}/history-file/create`
                                        )
                                        manageTimelineCreationFormState({
                                            isOpen: true,
                                            current_step_index: 0,
                                            active_creation:
                                                TimelineCreationTypeIdsEnum.add_new,
                                        })
                                    }}
                                    hasSampleData
                                    userCurrency={userCurrency}
                                />
                            ) : (
                                <NoDataRectangle text="no entries in history file" />
                            ))}
                    </IpadAndMobileDisplay>
                </CenteredPageWrapper>
            </Faded>
        </React.Fragment>
    )
}

export default HistoryFilePageManager
