import { Option } from '../../atoms/select/types'
import { ITabProps } from 'myInterfaces'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { currency_data, currency_IDS } from '../../../redux/timeline/data'
import BottomBarBtnsStep from '../../atoms/Button/bottomBarBtnsStep'
import CustomAnimatedCheckboxField from '../../atoms/formField/customAnimatedCheckboxField'
import InfoIcon from '../../atoms/icons/infoIcon'
import Combobox from '../../atoms/select/Combobox'
import { NumberEditOrCreateModeSingleFieldMobile } from '../../molecules/editOrCreateModeSingleFields'
import BtnTabRow from '../../molecules/tab/btnTabRow'
import RangeRowFieldsMobile from '../../molecules/editOrCreateModeSingleFields/rangeRow/rangeFieldMobile'
import { IShowroomEntry } from '../../../redux/showroom/types'
import {
    ErrorText,
    IShowroomEntryBasicDataForm,
} from './showroomEntryBasicInfoManagerDesktop'
import { StandardInputField } from '../../atoms/Inputfield/inputField'
import AnimatedTextarea from '../../atoms/textarea/animatedTextarea'
import { yupVar_CarTitle } from '../../molecules/editOrCreateModeSingleFields/yupVars'
import Expander from '../../atoms/expander/expander'
import { useAppSelector } from '../../../redux/store/hooks'
import useThemes from '../../../providers/theme/hooks'
import { IEditOrCreateModeSingleFieldsProps } from '../../molecules/editOrCreateModeSingleFields/types'

const InfoRec = styled.div`
    background-color: var(--base-overlays-neutral-alpha-100);
    border-radius: 8px;
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
`

const CheckboxRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;
`

const CheckboxText = styled.div`
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
    color: var(--text-darker, #616161);
    padding-left: 5px;
    font-size: 14px;
`

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-height: 639px) {
        height: 80vh;
        overflow-y: auto;
        padding-top: 8px;
    }
`

type OtherProps = {
    onSubmit: (p: IShowroomEntryBasicDataForm) => any
    onCancel: any
    isEditEntry?: boolean
    showroomEntry?: IShowroomEntry
}
const ShowroomEntryBasicInfoManagerMobile = (props: OtherProps) => {
    let { onSubmit, onCancel, isEditEntry, showroomEntry } = props

    const [activeTab, setActiveTab] = useState<
        'set_price' | 'PRICE_ON_ASKING' | 'price_range'
    >('set_price')

    const [formData, setFormData] = useState<IShowroomEntryBasicDataForm>(
        isEditEntry && showroomEntry
            ? {
                  car_title: showroomEntry.car?.title,
                  set_price: {
                      value: showroomEntry.listing_price_min
                          ? showroomEntry.listing_price_min
                          : 0,
                      currency: showroomEntry.listing_currency
                          ? showroomEntry.listing_currency
                          : 'GBP',
                      open_to_offer: showroomEntry.open_to_offers,
                  },
                  price_range: {
                      valueFrom: showroomEntry.listing_price_min
                          ? showroomEntry.listing_price_min
                          : 0,
                      valueTo: showroomEntry.listing_price_max
                          ? showroomEntry.listing_price_max
                          : 0,
                      currency: showroomEntry.listing_currency
                          ? showroomEntry.listing_currency
                          : 'GBP',
                  },
                  poa:
                      showroomEntry.type_of_sale === 'PRICE_ON_ASKING'
                          ? true
                          : false,
                  vehicle_bio: showroomEntry.car?.short_description,
              }
            : {}
    )

    let tabs: ITabProps[] = [
        {
            id: 0,
            title: 'Set price',
            onClick: (arg: number) => {
                setActiveTab('set_price')
            },
            isActive: activeTab === 'set_price' ? true : false,
        },
        {
            id: 1,
            title: 'Price range',
            onClick: (arg: number) => {
                setActiveTab('price_range')
            },
            isActive: activeTab === 'price_range' ? true : false,
        },
        {
            id: 3,
            title: 'POA',
            onClick: (arg: number) => {
                setActiveTab('PRICE_ON_ASKING')
            },
            isActive: activeTab === 'PRICE_ON_ASKING' ? true : false,
        },
    ]

    const priceRangeValidation = useAppSelector(
        (state) => state.showroom.filterValidationError
    )

    const [carTitleError, setCarTitleError] = useState<string | undefined>(
        undefined
    )

    const [priceError, setPriceError] = useState<boolean>(false)

    const validateCarTitle = async (value: string) => {
        try {
            await yupVar_CarTitle().validate(value)
            setCarTitleError(undefined)
        } catch (err: any) {
            setCarTitleError(err.errors[0])
        }
    }

    const checkIfDisabled = () => {
        if (carTitleError) return true
        if (priceRangeValidation?.price) return true
        if (
            activeTab === 'set_price' &&
            (!formData.set_price || !formData.set_price?.value || priceError)
        ) {
            return true
        }

        if (
            activeTab === 'price_range' &&
            (!formData.price_range ||
                !formData.price_range?.valueFrom ||
                !formData.price_range?.valueTo)
        ) {
            return true
        }
    }

    let renderBody = () => {
        switch (activeTab) {
            case 'set_price':
                let data: IEditOrCreateModeSingleFieldsProps = {
                    id: 'price',
                    value_start: formData.set_price?.value,
                    title: 'price',

                    onChangeFunc: (id: string, val: number | string) => {
                        if (id === 'price') {
                            setFormData((prevState) => {
                                return {
                                    ...prevState,
                                    set_price: {
                                        ...prevState.set_price,
                                        value: +val,
                                        currency:
                                            formData &&
                                            formData.set_price &&
                                            formData.set_price.currency
                                                ? formData.set_price.currency
                                                : 'GBP',
                                    },
                                }
                            })
                        }
                        if (id === 'currency') {
                            setFormData((prevState) => {
                                return {
                                    ...prevState,
                                    set_price: {
                                        ...prevState.set_price,
                                        value:
                                            formData &&
                                            formData.set_price &&
                                            formData.set_price.value
                                                ? formData.set_price.value
                                                : 0,
                                        currency: `${val}`,
                                    },
                                }
                            })
                        }
                    },
                    optionName:
                        formData &&
                        formData.set_price &&
                        formData.set_price.currency
                            ? formData.set_price.currency
                            : 'GBP',
                }

                return (
                    <>
                        <div style={{ paddingTop: '10px' }} />
                        <NumberEditOrCreateModeSingleFieldMobile
                            data={data}
                            key={'key'}
                            dataCyId={'set_price_desktp['}
                            optionsIDArr={currency_IDS}
                            optionsData={currency_data}
                            optionsFieldID="currency"
                            hasError={(v: boolean) => setPriceError(v)}
                        />

                        <CheckboxRow>
                            <CustomAnimatedCheckboxField
                                name={'open_to_offers'}
                                checked={
                                    formData.set_price &&
                                    formData.set_price.open_to_offer === true
                                        ? true
                                        : false
                                }
                                onChange={() => {
                                    setFormData((prevState) => {
                                        return {
                                            ...prevState,

                                            set_price: {
                                                currency:
                                                    formData &&
                                                    formData.set_price &&
                                                    formData.set_price.currency
                                                        ? formData.set_price
                                                              .currency
                                                        : 'GBP',

                                                value:
                                                    formData &&
                                                    formData.set_price &&
                                                    formData.set_price.value
                                                        ? formData.set_price
                                                              .value
                                                        : 0,
                                                open_to_offer:
                                                    formData.set_price &&
                                                    formData.set_price
                                                        .open_to_offer === true
                                                        ? false
                                                        : true,
                                            },
                                        }
                                    })
                                }}
                                small={true}
                            />
                            <CheckboxText>Open to offers</CheckboxText>
                        </CheckboxRow>
                    </>
                )

            case 'price_range':
                return (
                    <div style={{ width: '100%' }}>
                        <div style={{ paddingTop: '20px' }} />

                        <Combobox
                            defaultValue={
                                formData &&
                                formData.price_range &&
                                formData.price_range.currency
                                    ? formData.price_range.currency
                                    : 'GBP'
                            }
                            value={formData?.price_range?.currency}
                            onChange={(val: string) =>
                                setFormData((prevState: any) => {
                                    return {
                                        ...prevState,

                                        price_range: {
                                            ...prevState.price_range,
                                            currency: `${val}`,
                                        },
                                    }
                                })
                            }
                            placeholder="currency"
                            options={
                                currency_IDS.map(
                                    (optionID: string, index: number) => {
                                        let option: Option | undefined = {
                                            label: currency_data[
                                                optionID as keyof typeof currency_data
                                            ].name,
                                            value: currency_data[
                                                optionID as keyof typeof currency_data
                                            ].uid,
                                        }

                                        return {
                                            value: option?.label.toUpperCase(),
                                            label: option?.label.toUpperCase(),
                                        }
                                    }
                                ) ?? []
                            }
                        />

                        <div style={{ paddingTop: '20px' }} />

                        <RangeRowFieldsMobile
                            filterid="price"
                            valueFrom={formData.price_range?.valueFrom}
                            valueTo={formData.price_range?.valueTo}
                            customWidth={'100%'}
                            onChange={(
                                valueFrom: number | null | undefined,
                                valueTo: number | null | undefined
                            ) => {
                                setFormData((prevState) => {
                                    return {
                                        ...prevState,

                                        price_range: {
                                            currency:
                                                formData &&
                                                formData.set_price &&
                                                formData.set_price.currency
                                                    ? formData.set_price
                                                          .currency
                                                    : 'GBP',
                                            valueFrom: valueFrom ?? undefined,
                                            valueTo: valueTo ?? undefined,
                                        },
                                    }
                                })
                            }}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </div>
                )

            case 'PRICE_ON_ASKING':
                return (
                    <InfoRec>
                        <InfoIcon size="30" />
                        <div>
                            Your car will not appear in price range search
                            results and may not properly sort by price.
                        </div>
                    </InfoRec>
                )

            default:
                return <div />
        }
    }

    useEffect(() => {
        if (formData.poa) {
            setActiveTab('PRICE_ON_ASKING')
        } else if (
            formData.set_price &&
            formData.price_range?.valueFrom !== formData.price_range?.valueTo
        ) {
            setActiveTab('price_range')
        } else if (
            formData.set_price &&
            formData.price_range?.valueFrom === formData.price_range?.valueTo
        ) {
            setActiveTab('set_price')
        }

        return () => setActiveTab('set_price')
    }, [])

    const { theme } = useThemes()

    return (
        <>
            <Wrapper>
                {isEditEntry && (
                    <>
                        <StandardInputField
                            theme={theme}
                            id={'car_title'}
                            name={'car_title'}
                            placeholder={'car title'}
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            value={formData.car_title}
                            font_family="Lato"
                            paddingLeft="0px"
                            width="100%"
                            fontSize={'16px'}
                            onChange={(e: any) => {
                                validateCarTitle(e.target.value)
                                setFormData((prevState) => {
                                    return {
                                        ...prevState,
                                        car_title: e.target.value,
                                    }
                                })
                            }}
                            dataCyId={'car_title'}
                            linecolor="#C7C7C7"
                            type={'text'}
                        />
                        <Expander
                            height={carTitleError ? 'auto' : 0}
                            width={'100%'}
                        >
                            <ErrorText>{carTitleError}</ErrorText>
                        </Expander>
                        <div style={{ paddingTop: '32px' }} />
                    </>
                )}
                <BtnTabRow tabs={tabs} />

                <div style={{ paddingTop: '20px' }} />

                {renderBody()}
                {isEditEntry && (
                    <>
                        <div
                            style={{
                                paddingTop: '24px',
                                paddingBottom: '32px',
                            }}
                        >
                            <div
                                style={{
                                    height: '1px',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    backgroundColor:
                                        'var(--border-muted, #E5E5E5)',
                                }}
                            />
                        </div>
                        <AnimatedTextarea
                            id="car_short_description"
                            name="car_short_description"
                            value={
                                formData.vehicle_bio ? formData.vehicle_bio : ''
                            }
                            handleChange={(e: any) => {
                                setFormData((prevState) => {
                                    return {
                                        ...prevState,
                                        vehicle_bio: e.target.value,
                                    }
                                })
                            }}
                            fieldTitle={'Vehicle bio'}
                            placeholder={'Type here... '}
                            keepLabelRaised
                        />
                        <div style={{ paddingTop: '40px' }} />
                    </>
                )}
            </Wrapper>
            <div
                style={{
                    alignSelf: 'flex-end',
                    justifySelf: 'flex-end',
                    backgroundColor: 'red',
                    height: 60,
                }}
            >
                <BottomBarBtnsStep
                    cancel={onCancel}
                    submit={() => {
                        let payload: IShowroomEntryBasicDataForm = {}
                        if (activeTab === 'PRICE_ON_ASKING') {
                            payload = {
                                car_title: formData.car_title,
                                vehicle_bio: formData.vehicle_bio,
                                poa: true,
                            }
                        } else if (activeTab === 'set_price') {
                            payload = {
                                car_title: formData.car_title,
                                vehicle_bio: formData.vehicle_bio,
                                set_price: formData.set_price,
                            }
                        } else if (activeTab === 'price_range') {
                            payload = {
                                car_title: formData.car_title,
                                vehicle_bio: formData.vehicle_bio,
                                price_range: formData.price_range,
                            }
                        }

                        onSubmit(payload)
                    }}
                    confirmationtext="continue"
                    disabled={checkIfDisabled()}
                    hasGoBack={false}
                    removeLine={true}
                />
            </div>
        </>
    )
}

export default ShowroomEntryBasicInfoManagerMobile
