import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import blank_user_dark from '../../../public/assets/placeholders_svgs/avatar_dark.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import useThemes from '../../../providers/theme/hooks'

type IStyleProps = {
    hasImage?: boolean
    $isShowroom?: boolean
}

const UserImgWrapper = styled.div<IStyleProps>`
    position: absolute;
    border-radius: 16px;
    height: 116px;
    width: 116px;
    border: ${(props) => (props.hasImage ? 'none' : 'solid 1px #ececec')};

    @media ${device.desktop} {
        left: 0px;
        top: ${(props) => (props.$isShowroom ? 0 : '-40px')};
    }

    @media ${device.smallest_laptops} {
        left: 0px;
        top: ${(props) => (props.$isShowroom ? 0 : '-50px')};
    }

    @media ${device.large_desktop} {
        left: 0px;
        top: ${(props) => (props.$isShowroom ? 0 : '-50px')};
    }
`
const UserImg = styled.img<IStyleProps>`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 16px;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    width: 116px;

    @media ${device.desktop} {
        margin-right: 100px;
    }
    @media ${device.large_desktop} {
        margin-right: 100px;
    }

    @media ${device.smallest_laptops} {
        margin-right: 70px;
    }
`

type Props = {
    size?: string
    url?: any
    onClick?: any
    isShowroom?: boolean
}

const UserSquareProfileImage: React.FC<Props> = ({
    url,
    onClick,
    isShowroom,
}) => {
    const [src, setSrc] = React.useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    let { theme } = useThemes()

    React.useEffect(() => {
        const initialSrc =
            url !== undefined && url !== false && url !== null && url.length > 1
                ? url
                : theme === 'dark'
                ? blank_user_dark
                : blank_user
        if (initialSrc) {
            setSrc(initialSrc)
        }
    }, [url])

    return (
        <Wrapper style={{ cursor: onClick ? 'pointer' : 'auto' }}>
            <UserImgWrapper
                hasImage={src !== undefined}
                $isShowroom={isShowroom}
                onClick={() => onClick && onClick()}
            >
                <UserImg
                    src={src}
                    onLoad={() => {
                        setIsLoading(false)
                    }}
                />
                <SkeletonAbsolute
                    customBorderRadius={'16px'}
                    isthingloading={isLoading}
                />
            </UserImgWrapper>
        </Wrapper>
    )
}

export default UserSquareProfileImage
