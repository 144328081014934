import * as React from 'react'
import AcceptAttachments from '../../dropzone/attachmentsDropzoneDesktop'
import { useAppDispatch } from '../../../../redux/store/hooks'
import { timelineActions } from '../../../../redux/timeline/reducer'

interface Props {
    carid: string
    uploadChildren?: React.ReactNode
    noActionIcon?: boolean
    icon?: string
    onClickOpen?: any
    entityID: string
    isTopPage?: boolean
    scrollToElement?: any
}

interface Props {
    carid: string
    uploadChildren?: React.ReactNode
    noActionIcon?: boolean
    icon?: string
    onClickOpen?: any
    entityID: string
    isTopPage?: boolean
    scrollToElement?: any
}

const EntryAttachmentsFormWrapperDesktop: React.FC<Props> = (props) => {
    const [filesArray, setFilesArray] = React.useState<File[] | undefined[]>([
        undefined,
    ])
    const dispatch = useAppDispatch()

    const handleFilesGiven = (files: File[]) => {
        setFilesArray(files)

        dispatch(
            timelineActions.manageAddAttachmentsToEntryRequest({
                files: files,
                entryID: props.entityID,
                carID: props.carid,
            })
        )
    }

    return (
        <AcceptAttachments
            handleFilesGiven={handleFilesGiven}
            filesUploaded={filesArray}
            progressArray={{ filesArray }}
            isTopPage={props.isTopPage}
            scrollToElement={props.scrollToElement}
        />
    )
}

export default EntryAttachmentsFormWrapperDesktop
