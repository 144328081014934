import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplicationValidation,
    insuranceActions,
} from '../../reducer'
import {
    ConvertInsuranceApplicationErrorsToReducer,
    ConvertInsuranceApplicationToReducer,
    ValidateReducerData,
    addStatsToReducer,
} from '../../../conversions/insuranceApplication/api/applicationToReducer'
import {
    IInsuranceApplication_API,
    IInsuranceApplication_APIErrors,
} from '../../types'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'
import { push } from 'redux-first-history'

export function* ValidateInsuranceApplication(payload: {
    application: IInsuranceQuoteApplication
    activateValidationSheet?: boolean
    isWizard?: boolean
}): any {
    try {
        if (payload.application.id) {
            let res: IInsuranceApplication_APIErrors = yield call(
                api.insuranceApplication.validateInsuranceApplication,
                payload.application.id
            )

            if (payload.activateValidationSheet) {
                let validationReducerData: IInsuranceQuoteApplicationValidation =
                    {
                        ...ConvertInsuranceApplicationErrorsToReducer(res),
                    }
                if (
                    (res && Object.keys(res).length > 0) ||
                    !payload.application?.everyday_car_ids ||
                    payload.application?.everyday_car_ids?.length === 0
                ) {
                    if (res && Object.keys(res).length > 0) {
                        let getUpdatedData = yield call(
                            GetInsuranceApplicationOnly,
                            payload.application.id
                        )
                        let applicationReducerDataWithStats = addStatsToReducer(
                            getUpdatedData,
                            validationReducerData
                        )

                        let validatedApplication = ValidateReducerData(
                            applicationReducerDataWithStats,
                            validationReducerData
                        )

                        yield put(
                            insuranceActions.validate_insurance_application_success(
                                validatedApplication
                            )
                        )
                    } else {
                        yield put(
                            insuranceActions.validate_insurance_application_success()
                        )
                    }

                    yield put(insuranceActions.show_Validation_sheet(true))
                } else {
                    yield put(insuranceActions.show_Validation_sheet(false))
                    yield put(
                        insuranceActions.validate_insurance_application_success()
                    )
                    if (payload.isWizard) {
                        yield put(
                            push(
                                `/insurance/application/${payload.application.id}`
                            )
                        )
                    } else {
                        yield put(
                            push(
                                `/insurance/application/${payload.application.id}/submission-agreement`
                            )
                        )
                    }
                }
            }

            if (
                res &&
                Object.keys(res).length === 0 &&
                payload.application?.everyday_car_ids &&
                payload.application?.everyday_car_ids?.length > 0
            ) {
                yield put(insuranceActions.show_Validation_sheet(false))
            }
            return res
        }
    } catch (error: any) {
        // console.log('error:', error)
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: ValidateInsuranceApplication,
            }
            yield call(unauthHandlerSaga, p)
        }
    }
}

export function* GetInsuranceApplicationOnly(application_id: string): any {
    try {
        let res: IInsuranceApplication_API = yield call(
            api.insuranceApplication.getInsuranceApplication,
            application_id
        )

        let applicationConvertedToReducerFormat: IInsuranceQuoteApplication = {
            ...ConvertInsuranceApplicationToReducer(res),
        }

        applicationConvertedToReducerFormat['id'] = application_id

        return applicationConvertedToReducerFormat
    } catch (error: any) {
        // console.log('error:', error)
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetInsuranceApplicationOnly,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.validate_insurance_application_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Validate_Application() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.validate_insurance_application_request
        )

        yield call(ValidateInsuranceApplication, payload)
    }
}

const insurance_validate_application: any[] = [
    fork(Watcher_Validate_Application),
]

export default insurance_validate_application
