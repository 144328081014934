import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import NoDataRectangle from '../../../atoms/noData/noDataRectangle'
import HistoryFilePageManager from '../../../organisms/HistoryFile/historyFilePageManager'
import { device } from '../../displays/devices'
import CenteredPageWrapper from '../../displays/pageWrappers/CenteredPageWrapper'
import FabsMenusMobile from '../../menus/fabsMenuMobile'
import { useAppSelector } from '../../../../redux/store/hooks'
import { ICar, IExternalCar } from '../../../../redux/entities/cars/types'
import { IUser } from '../../../../redux/user/types'

const AdjustedPaddingTop = styled.div`
    padding-top: 50px;
    @media ${device.ipad} {
        padding-top: 80px;
    }
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

interface Props {
    userLoggedIn: IUser | null
    sharedCar?: IExternalCar
    prevOwned?: ICar
}

const ReadOnlyCarHistoryFile: React.FC<Props> = ({
    userLoggedIn,
    sharedCar,
    prevOwned,
}) => {
    const { carid } = useParams<{ carid: string }>()

    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )
    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const timelineData = useAppSelector(
        (state) => state.timeline.timeline_data_normalised_by_car_id
    )

    return (
        <React.Fragment>
            <FabsMenusMobile hasAdd={false} hasSubNav={true} />
            <AdjustedPaddingTop />

            {sharedCar ? (
                sharedCar.policy_statements &&
                sharedCar.policy_statements?.includes('history file') ? (
                    <HistoryFilePageManager
                        carid={`${carid}`}
                        sharedCar={sharedCar}
                        carsData={externalCarsData}
                        carsTimelineData={timelineData}
                        readOnlyMode
                    />
                ) : (
                    <CenteredPageWrapper>
                        <NoDataRectangle isInfoRestricted />
                    </CenteredPageWrapper>
                )
            ) : (
                prevOwned && (
                    <HistoryFilePageManager
                        carid={`${carid}`}
                        prevOwned={prevOwned}
                        carsData={carsData}
                        carsTimelineData={timelineData}
                        readOnlyMode
                    />
                )
            )}
        </React.Fragment>
    )
}

export default ReadOnlyCarHistoryFile
