import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { faqPageCopyData, helpContactReasonsData } from './data'
import { IFAQPageCopy, IHelpPageContact } from 'helpPageCopyModels'

export type IHelpPageState = {
    faqPageCopy: IFAQPageCopy
    selectedContactReason: IHelpPageContact
}

const initialState: IHelpPageState = {
    faqPageCopy: faqPageCopyData,
    selectedContactReason: helpContactReasonsData,
}

const helpSlice = createSlice({
    name: 'help',
    initialState,
    reducers: {
        selectContactReason: (state, action: PayloadAction<string>) => {
            state.selectedContactReason.selected = action.payload
        },
    },
})

export const helpActions = helpSlice.actions
export default helpSlice.reducer
