import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { IApi_UpdateDirectShare_args } from '../types'

export const updateDirectShareAPI = (
    p: IApi_UpdateDirectShare_args
): Promise<any> => {
    let api_payload = {
        title: p.title,
        policy_statements: p.policy_statements,
    }
    let jsonBodyConv: string = JSON.stringify(api_payload)

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        credentials: 'include',
        body: jsonBodyConv,
    }

    return fetch(urls.update_received_share_status(p.share_id), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(
                    response,
                    'Update direct share error',
                    jsonBodyConv
                )
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
