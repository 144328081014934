import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGetTaskRemindersReq } from './sagas/getTaskReminders'
import {
    IDeleteTaskReminderReqSaga,
    IDeleteTaskReminderSuccess,
} from './sagas/deleteTaskReminder'
import {
    ICreateTaskPayload,
    IDeleteTaskPayload,
    IPayloadTaskRemindersState,
    ISetAPITaskRemindersInStatePayload,
    ISetTaskRemindersEditedPayload,
    ITask,
    ITaskNormalisedObject,
    ITaskRelationsPayload,
    ITasksDisplayCriteria,
    ITasksState,
    IUpdateTaskReq,
} from 'entityModels'
import { IReduxError } from '../entities/cars/types'

const initialState: ITasksState = {
    tasks: {},
    tasksDisplayCriteria: {
        selectedCarID: null,
        sortBy: 'Date Added',
        filteredBy: 'Outstanding',
    },
    error: null,
    loading: false,
    isRemindersLoading: false,
}

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        // GET TASKS BY GARAGE ID
        getTasksByGarageIdRequest: (state, action: PayloadAction<string>) => {
            state.loading = true
            state.error = null
        },

        getTasksByGarageIdSuccess: (state, action: PayloadAction<any>) => {
            state.tasks = { ...state.tasks, ...action.payload }
            state.loading = false
            state.error = null
        },

        getTasksByGarageIdError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        // GET TASKS BY CAR ID
        getTasksByCarIdRequest: (state, action: PayloadAction<string>) => {
            state.loading = true
            state.error = null
        },

        getTasksByCarIdSuccess: (
            state,
            action: PayloadAction<ITaskNormalisedObject>
        ) => {
            state.tasks = { ...state.tasks, ...action.payload }
            state.loading = false
            state.error = null
        },

        getTasksByCarIdError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        // GET TASKS BY TASK ID
        getTaskByIdRequest: (state, action: PayloadAction<string>) => {
            state.loading = true
            state.error = null
        },

        setTaskRemindersEditedRequest: (
            state,
            action: PayloadAction<ISetTaskRemindersEditedPayload>
        ) => {},

        getTaskByIdSuccess: (
            state,
            action: PayloadAction<ITaskNormalisedObject>
        ) => {
            state.tasks = { ...state.tasks, ...action.payload }
            state.loading = false
            state.error = null
        },

        getTaskByIdError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        // UPDATE TASK
        updateTaskRequest: (state, action: PayloadAction<IUpdateTaskReq>) => {
            state.loading = true
            state.error = null
        },

        updateTaskSuccess: (state, action: PayloadAction<ITask>) => {
            state.tasks = {
                ...state.tasks,
                [action.payload.id]: {
                    ...state.tasks[action.payload.id],
                    ...action.payload,
                },
            }
            state.loading = false
            state.error = null
        },

        setTaskRemindersEditedSuccess: (
            state,
            action: PayloadAction<ISetTaskRemindersEditedPayload>
        ) => {
            state.tasks = {
                ...state.tasks,
                [action.payload.taskID]: {
                    ...state.tasks[action.payload.taskID],
                    reminders_being_added: action.payload.reminders,
                },
            }
            state.isRemindersLoading = false
            state.loading = false
            state.error = null
        },

        updateTaskError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        // CREATE TASK
        createTaskRequest: (
            state,
            action: PayloadAction<ICreateTaskPayload>
        ) => {
            state.loading = true
            state.error = null
        },

        createTaskSuccess: (
            state,
            action: PayloadAction<ITaskNormalisedObject>
        ) => {
            state.tasks = { ...state.tasks, ...action.payload }
            state.loading = false
            state.error = null
        },

        createTaskError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        // LINK TASK
        linkTaskRequest: (
            state,
            action: PayloadAction<ITaskRelationsPayload>
        ) => {
            state.loading = true
            state.error = null
        },

        linkTaskSuccess: (state, action: PayloadAction<ITask>) => {
            state.tasks = {
                ...state.tasks,
                [action.payload.id]: action.payload,
            }
            state.loading = false
            state.error = null
        },

        linkTaskError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        // UNLINK TASK
        unlinkTaskRequest: (
            state,
            action: PayloadAction<ITaskRelationsPayload>
        ) => {
            state.loading = true
            state.error = null
        },

        unlinkTaskSuccess: (state, action: PayloadAction<ITask>) => {
            state.tasks = {
                ...state.tasks,
                [action.payload.id]: action.payload,
            }
            state.loading = false
            state.error = null
        },

        unlinkTaskError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        // DELETE TASK
        deleteTaskRequest: (
            state,
            action: PayloadAction<IDeleteTaskPayload>
        ) => {
            state.loading = true
            state.error = null
        },
        deleteTaskSuccess: (state, action: PayloadAction<string>) => {
            delete state.tasks[action.payload]
            state.loading = false
            state.error = null
        },

        deleteTaskError: (state, action: PayloadAction<IReduxError>) => {
            state.loading = false
            state.error = action.payload
        },

        // REMOVE TASKS
        removeStoreTasksSuccess: (state) => {
            state.tasks = {}
            state.loading = false
            state.error = null
        },

        registerTasksDisplayCriteriaSuccess: (
            state,
            action: PayloadAction<ITasksDisplayCriteria>
        ) => {
            state.tasksDisplayCriteria = action.payload
        },

        // GET TASK REMINDERS
        getTaskRemindersRequest: (
            state,
            action: PayloadAction<IGetTaskRemindersReq>
        ) => {
            state.isRemindersLoading = true
            state.error = null
        },

        getTaskRemindersError: (state, action: PayloadAction<IReduxError>) => {
            state.error = action.payload
            state.isRemindersLoading = false
        },

        getTaskRemindersSuccess: (
            state,
            action: PayloadAction<IPayloadTaskRemindersState>
        ) => {
            state.tasks = {
                ...state.tasks,
                [action.payload.taskUID]: {
                    ...state.tasks[action.payload.taskUID],
                    reminders:
                        action.payload.reminders?.length > 0
                            ? action.payload.reminders
                            : [],
                },
            }
            state.error = null
            state.isRemindersLoading = false
        },

        // ADD REMINDER
        addRemindersToTaskRequest: (
            state,
            action: PayloadAction<ISetTaskRemindersEditedPayload>
        ) => {
            state.error = null
            state.isRemindersLoading = true
        },

        addRemindersToTaskSuccess: (
            state,
            action: PayloadAction<ISetAPITaskRemindersInStatePayload>
        ) => {
            const arr = state.tasks?.[action.payload.taskID]?.reminders ?? []
            state.tasks = {
                ...state.tasks,
                [action.payload.taskID]: {
                    ...state.tasks[action.payload.taskID],
                    reminders: [...arr, ...action.payload.reminders],
                },
            }
            state.loading = false
            state.error = null
            state.isRemindersLoading = false
        },

        addRemindersToTaskError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
            state.isRemindersLoading = false
        },

        // DELETE REMINDER
        deleteReminderTaskRequest: (
            state,
            action: PayloadAction<IDeleteTaskReminderReqSaga>
        ) => {
            state.loading = false
            state.error = null
            state.isRemindersLoading = true
        },

        deleteReminderTaskSuccess: (
            state,
            action: PayloadAction<IDeleteTaskReminderSuccess>
        ) => {
            state.tasks = {
                ...state.tasks,
                [action.payload.taskUID]: {
                    ...state.tasks[action.payload.taskUID],
                    reminders: [...action.payload.reminders],
                },
            }
            state.loading = false
            state.error = null
            state.isRemindersLoading = false
        },

        deleteReminderTaskError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
            state.isRemindersLoading = false
        },

        cleanUpTasksSuccess: (state) => {
            state.tasks = {}
            state.tasksDisplayCriteria = {
                selectedCarID: null,
                sortBy: 'Date Added',
                filteredBy: 'Outstanding',
            }
            state.error = null
            state.loading = false
            state.isRemindersLoading = false
        },
    },
})

export const tasksActions = tasksSlice.actions
export default tasksSlice.reducer

export type TasksState = ReturnType<typeof tasksSlice.reducer>
