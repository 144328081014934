import React from 'react'
import styled from 'styled-components'
import useThemes from '../../../providers/theme/hooks'

const Wrap = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-start;
    gap: 16px;
    flex-direction: row;
`

const MilestoneIcon = styled.img`
    width: 64px;
    height: 64px;
    flex-shrink: 0;
`

const Step = styled.div`
    display: flex;
    padding: 32px 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: var(--off-bg-color, #fafafa);
`

const Text = styled.div`
    color: var(--text-darker, #616161);
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

interface IMilestoneProps {
    id: string
    text: string
    icon: string
    icon_dark: string
}

export interface IRoadmapProps {
    milestoneList: IMilestoneProps[]
}

const Roadmap: React.FunctionComponent<IRoadmapProps> = (props) => {
    const { theme } = useThemes()

    return (
        <Wrap>
            {props.milestoneList.map((item, i) => (
                <Step key={`${item.id}-${i}-desktop`} id={item.id}>
                    <MilestoneIcon
                        src={theme === 'dark' ? item.icon_dark : item.icon}
                    />
                    <Text>{item.text}</Text>
                </Step>
            ))}
        </Wrap>
    )
}

export default Roadmap
