import { withFormik, FormikProps } from 'formik'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import { IDropdownItem, IList } from 'entityModels'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../molecules/technicalInformation/modal'
import { Select } from '../../../atoms/select'
import styled from 'styled-components'
import useThemes from '../../../../providers/theme/hooks'
import { IUserPreferencesDataLevel4Obj } from '../../../../redux/user/preferences'
import { IUSerGeneralInfoUpdatePayload } from '../../../../redux/user/types'

const Padding24 = styled.div`
    padding-top: 24px;
`

interface Values {
    unique_value_mobile: string
}

interface OtherProps {
    title: any
    item: IList | null
    data?: IUserPreferencesDataLevel4Obj | null
    ids_list?: string[] | null
    toggle: any
    handleSubmit: any
    field_id: string | null
    userid: string
    onlyShowMatch?: boolean
    optionsList?: IDropdownItem[] | string[]
    isKeyValue?: boolean
    placeholder?: string
    helperText?: string
    standalonePicker?: boolean
    pickerPath?: string
    // clearSelectedTimezone: () => void
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        title,
        ids_list,
        data,
        optionsList,
        setFieldValue,
        values,
        toggle,
        handleSubmit: onSubmit,
        item,
        // clearSelectedTimezone,
    } = props

    let convertedOptionsList: { value: string; label: string }[] = []

    if (data && ids_list) {
        convertedOptionsList = ids_list.map((id: string, index: any) => {
            return { value: data[id].id, label: data[id].name ?? data[id].id }
        })
    } else {
        convertedOptionsList = (optionsList as IDropdownItem[]).map(
            (option) => ({
                value: option.uid,
                label: option.name ?? option.uid,
            })
        )
    }

    const handleCancel = () => {
        toggle()
        // dispatch(dropDownActions.clearSelectedTimezone())
    }

    const handleSubmit = (...args: any[]) => {
        onSubmit(...args)
        // clearSelectedTimezone()
    }

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>{title}</ModalTopBar>
            <ModalInnerContentWrapperMobile>
                <Select
                    onChange={(name: string) => {
                        setFieldValue('unique_value_mobile', name)
                    }}
                    value={item?.id ?? ''}
                    placeholder={title}
                    options={convertedOptionsList}
                    disabled={
                        !values.unique_value_mobile &&
                        (!item || item.answer === null)
                            ? true
                            : false
                    }
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <Padding24 />
                <ButtonsStandardBottomRowMobile
                    cancel={handleCancel}
                    submit={handleSubmit}
                    confirmationtext="save"
                />
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const PreferencesSelectFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        unique_value_mobile: props.item ? props.item.id : '',
    }),
    // validationSchema: {},

    enableReinitialize: true,

    handleSubmit: (values, { props, setSubmitting }) => {
        if (props.field_id) {
            let dataToSend: IUSerGeneralInfoUpdatePayload = {
                id: props.userid,
                data: {
                    user_preferences: {
                        [props.field_id]: values.unique_value_mobile,
                    },
                },
            }
            if (values.unique_value_mobile !== props.item?.answer) {
                props.handleSubmit(dataToSend)
            }
        }
        props.toggle()

        setSubmitting(false)
    },
})(InnerForm)

export default PreferencesSelectFormMobile
