import * as React from 'react'
import styled from 'styled-components'
import TimelineCostItem from '../../atoms/timeline/costItem/timelineCostItemMobile'
import AttachmentInItemSinglePageMobile from '../../atoms/timeline/attachmentInItemSinglePage/attachmentInItemSinglePageMobile'
import CustomTagQuickFilterItemMobile from '../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemMobile'
import CustomIconTagQuickFilterItem from '../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import TotalCost from '../../atoms/timeline/totalCost/totalCost'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import TimelineNotesMobile from '../../atoms/typography/timelineNotesMobile'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import { useNavigate, useParams } from 'react-router'
import * as ReactScroll from 'react-scroll'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import * as unitGenerator from '../../../helpers/units/unitConversion'
import TimelineEntryGalleryGrid from '../imageGrid/timelineEntryGalleryGrid'
import Faded from '../../templates/animated/faded'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import WrapperImageFormsMobile from '../../organisms/editForms/imageFormWrapper/wrapperImageFormMobile'
import { numberWithCommas } from '../../../helpers/numbers'
import TimeAgoEntryTag from '../../atoms/tags/timeAgoEntryTag/timeAgoEntryTag'
import { editFormsActions } from '../../../redux/editForms/reducer'
import EntryAttachmentsFormWrapperMobile from '../../organisms/editForms/attachmentsFormWrapper/entryAttachmentsFormWrapperMobile'
import Icon from '../../atoms/icons'
import StandardCtaBtn from '../../atoms/Button/standardCtaBtn'
import PenIcon from '../../atoms/icons/components/penIcon'
import AddIcon from '../../atoms/icons/components/addIcon'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import {
    ICostItem,
    ILabel,
    INormalisedCostItems,
    ITimelineItem,
} from '../../../redux/timeline/types'
import { INormalisedAttachmentsByID } from '../../../redux/attachments/types'
import { IExternalCar } from '../../../redux/entities/cars/types'
import { IDeleteEntryAttachmentPayloadReq } from '../../../redux/services/types'
import { timelineActions } from '../../../redux/timeline/reducer'

interface IStyled {
    isAnimated?: any
}

const Container = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    gap: 24px;
`

const Row = styled.div<IStyled>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`
const RowStart = styled.div<IStyled>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`

const MileageWrapper = styled.div<IStyled>`
    display: flex;
    padding: 16px;
    align-items: center;
    border-radius: 8px;
    background-color: var(--off-bg-color, #fafafa);
    gap: 16px;
`

const CostItemsSection = styled.div<IStyled>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-top: 10px;
`

const Title = styled.div<IStyled>`
    color: var(--text-strong, #1a1a1a);
    text-transform: capitalize;
    font-family: 'Lato-semibold';
    font-size: 22px;
    line-height: 32px;
`

const SectionTitle = styled(ReactScroll.Element)<IStyled>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 16px;
    line-height: 24px;
`

const DateText = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    font-family: 'Lato-semibold';
    font-size: 16px;
    line-height: 24px;
    color: var(--text-strong, #1a1a1a);
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
`

const Description = styled.div<IStyled>`
    font-family: 'Lato';
    white-space: pre-line;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-default, #666666);
`

const NotesText = styled.div<IStyled>`
    font-family: 'Lato';
    white-space: pre-line;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-default, #666666);
`

const TotalCostWrapper = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
`

const AttachmentLoadingWrapper = styled.div`
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
`

const Col = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`

const AnimatedWrapper = styled.div<IStyled>`
    @keyframes scale {
        0% {
            transform: scale(1);
        }

        25% {
            transform: scale(1.1);
        }

        50% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.1);
        }
    }

    ${(props) => props.isAnimated && 'animation: 0.4s scale linear'};
    animation-delay: 0.5s;
`

const CurrentMileage = styled.span`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-semibold';
    font-size: 16px;
    line-height: 24px;
`

const MileageSince = styled.span`
    color: var(--insurance_details_green, #85c98b);
    font-family: 'Lato-semibold';
    font-size: 16px;
    line-height: 24px;
`

export const InsurerBox = styled.div`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid var(--border-muted, #e5e5e5);
    width: 100%;
    box-sizing: border-box;
    position: relative;
    min-height: 88px;
`

export const InsurerIconWrapper = styled.div`
    display: flex;
    width: 56px;
    height: 56px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--bg-color, #fff);
    box-shadow: 0px 4px 8px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
`

const NameTxt = styled.div`
    color: var(--text-muted);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

const AnswerTxt = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

interface Props {
    item: ITimelineItem
    dataCyId?: string
    attachmentsObj: INormalisedAttachmentsByID
    costsObj: INormalisedCostItems | undefined
    onCreateCostClick?: any
    onAddNewAttachmentClick?: any
    onAddNewLabelClick?: any
    onEditNotes?: any
    onCostClick?: any
    onRemoveLabel?: any
    setFileUploaded?: any
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    isEntryAttachmentBeingUploaded?: boolean
    isCostAttachmentBeingUploaded: boolean
    userCurrency: string | undefined
    readOnlyMode?: boolean
    carMileage?: number | undefined | null
    userDistanceUnit?: string | null
    onMileageEdit?: any
    sharedWithYouCar?: IExternalCar
    onEditInsurer?: () => any
}

type CarParams = {
    carid: string
}

export default function SingleTimelineItemBodyMobile(props: Props) {
    const scrollToElement = (elementName: string) => {
        ReactScroll.scroller.scrollTo(elementName, {
            duration: 500,
            delay: 300,
            smooth: true,
            offset: 50,
        })
    }

    const dispatch = useAppDispatch()

    React.useEffect(() => {
        let params = new URLSearchParams(location.search)
        let section_id: string | null = params.get('section_id')

        if (section_id && section_id === 'labels') {
            scrollToElement('labels-section-mobile')
        }
    }, [])

    const {
        item,
        dataCyId,
        attachmentsObj,
        costsObj,
        onCreateCostClick,
        onAddNewLabelClick,
        onEditNotes,
        onCostClick,
        onRemoveLabel,
        carID,
        entityID,
        isEntryAttachmentBeingUploaded,
        isCostAttachmentBeingUploaded,
        userCurrency,
        readOnlyMode,
        carMileage,
        userDistanceUnit,
        onMileageEdit,
        sharedWithYouCar,
        onEditInsurer,
    } = props

    let params = new URLSearchParams(location.search)
    let section_id: string | null = params.get('section_id')

    let convertedCarMileage =
        carMileage && userDistanceUnit
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnit,
                  carMileage
              )
            : undefined

    let convertedMileageRecorded: number | undefined =
        userDistanceUnit && item.mileage
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnit,
                  item.mileage
              )
            : undefined

    const { carid } = useParams<CarParams>()
    const navigate = useNavigate()

    const imagesData = useAppSelector(
        (state) => state.entities.galleriesData.images
    )
    const isAddGalleryImageToEntryFormOpen = useAppSelector(
        (state) =>
            state.editForms.formsData.isAddGalleryImageToEntryFormOpen.isOpen
    )

    let shared_with_you_car_policies =
        props.readOnlyMode &&
        sharedWithYouCar &&
        sharedWithYouCar.policy_statements
            ? sharedWithYouCar.policy_statements
            : undefined

    let is_cost_info_restricted =
        shared_with_you_car_policies &&
        !shared_with_you_car_policies.includes('entry costs')

    let readOnlylinkPrefix = sharedWithYouCar ? '/shared' : '/archives'

    const { theme } = useThemes()

    return (
        <Container data-attr={dataCyId && dataCyId}>
            <Col>
                <Row>
                    <DateText>
                        {item.date && fromIsoStringToDDMMYYY(item.date)}{' '}
                        {item.categoryID === 'insurance' &&
                            item.insurance_entry_policy_end_date &&
                            ` - ${fromIsoStringToDDMMYYY(
                                item.insurance_entry_policy_end_date
                            )}`}
                        {!item.date && 'no date'}
                        <TimeAgoEntryTag
                            device="mobile"
                            height="24px"
                            entry_date={item.date}
                        />
                    </DateText>
                </Row>
                <Row>
                    <Title>{item.title}</Title>
                </Row>
            </Col>

            {item.description ? (
                <Row>
                    <Description>{item.description}</Description>
                </Row>
            ) : readOnlyMode ? (
                <Row style={{ width: '100%' }}>
                    <NoDataRectangle
                        height="70px"
                        text="No description"
                        // isInfoRestricted={is_cost_info_restricted}
                    />
                </Row>
            ) : (
                <Row style={{ paddingTop: 4 }}>
                    <StandardCtaBtn
                        bg="transparent"
                        boder="none"
                        removeShadowOnHover
                        customWidth="auto"
                        customHeight="auto"
                        removePaddings
                        onClick={() =>
                            navigate(
                                `/car/${carid}/history-file/entry/edit?entry_id=${item.id}&section_id=general`
                            )
                        }
                    >
                        <Row style={{ gap: 8 }}>
                            <span style={{ color: colours[theme].primary }}>
                                Add a short description
                            </span>
                            <AddIcon colour={colours[theme].primary} />
                        </Row>
                    </StandardCtaBtn>
                </Row>
            )}

            {item.categoryID === 'insurance' && (
                <Col>
                    <SectionTitle name="insurer-section-mobile">
                        <span>Insurer</span>
                        {readOnlyMode ||
                        (!item.insurance_entry_broker &&
                            !item.insurance_entry_underwriter) ? null : (
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="32px"
                                customHeight="auto"
                                removePaddings
                                onClick={onEditInsurer}
                            >
                                <PenIcon color={colours[theme].primary} />
                            </StandardCtaBtn>
                        )}
                    </SectionTitle>

                    {item.insurance_entry_broker ||
                    item.insurance_entry_underwriter ? (
                        <CostItemsSection>
                            {item.insurance_entry_broker && (
                                <InsurerBox>
                                    <InsurerIconWrapper>
                                        <Icon icon="broker" />
                                    </InsurerIconWrapper>

                                    <Col style={{ gap: '6px' }}>
                                        <NameTxt>Broker</NameTxt>
                                        <AnswerTxt>
                                            {item.insurance_entry_broker}
                                        </AnswerTxt>
                                    </Col>
                                </InsurerBox>
                            )}

                            {item.insurance_entry_underwriter && (
                                <InsurerBox>
                                    <InsurerIconWrapper>
                                        <Icon icon="underwriter" />
                                    </InsurerIconWrapper>

                                    <Col style={{ gap: '6px' }}>
                                        <NameTxt>Insurer</NameTxt>
                                        <AnswerTxt>
                                            {item.insurance_entry_underwriter}
                                        </AnswerTxt>
                                    </Col>
                                </InsurerBox>
                            )}
                        </CostItemsSection>
                    ) : readOnlyMode ? (
                        <Row style={{ width: '100%' }}>
                            <NoDataRectangle
                                height="70px"
                                text="No insurer details"
                                // isInfoRestricted={is_cost_info_restricted}
                            />
                        </Row>
                    ) : (
                        <Row style={{ paddingTop: 4 }}>
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="auto"
                                customHeight="auto"
                                removePaddings
                                onClick={onEditInsurer}
                            >
                                <Row style={{ gap: 8 }}>
                                    <span
                                        style={{
                                            color: colours[theme].primary,
                                        }}
                                    >
                                        Add insurer details
                                    </span>
                                    <AddIcon colour={colours[theme].primary} />
                                </Row>
                            </StandardCtaBtn>
                        </Row>
                    )}
                </Col>
            )}

            <Col>
                <Row>
                    <SectionTitle name="mileage-section-mobile">
                        Mileage
                        {readOnlyMode || !convertedMileageRecorded ? null : (
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="32px"
                                customHeight="auto"
                                removePaddings
                                onClick={onMileageEdit}
                            >
                                <PenIcon color={colours[theme].primary} />
                            </StandardCtaBtn>
                        )}
                    </SectionTitle>
                </Row>
                {convertedMileageRecorded && (
                    <MileageWrapper>
                        <CurrentMileage>
                            {`${numberWithCommas({
                                numberToConvert: convertedMileageRecorded,
                                decimals: 0,
                            })} ${userDistanceUnit}`}
                        </CurrentMileage>
                        {convertedCarMileage &&
                            convertedCarMileage > convertedMileageRecorded && (
                                <MileageSince>
                                    {`+ ${numberWithCommas({
                                        numberToConvert:
                                            convertedCarMileage -
                                            convertedMileageRecorded,
                                        decimals: 0,
                                        round: 'ceil',
                                    })} `}
                                </MileageSince>
                            )}
                    </MileageWrapper>
                )}

                {!convertedMileageRecorded ? (
                    readOnlyMode ? (
                        <Row style={{ paddingTop: '10px' }}>
                            <NoDataRectangle
                                height="70px"
                                text="mileage not recorded"
                            />
                        </Row>
                    ) : (
                        <Row style={{ paddingTop: 4 }}>
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="auto"
                                customHeight="auto"
                                removePaddings
                                onClick={onMileageEdit}
                            >
                                <Row style={{ gap: 8 }}>
                                    <span
                                        style={{
                                            color: colours[theme].primary,
                                        }}
                                    >
                                        Add mileage to this entry
                                    </span>
                                    <AddIcon colour={colours[theme].primary} />
                                </Row>
                            </StandardCtaBtn>
                        </Row>
                    )
                ) : null}
            </Col>

            <Col>
                <Row>
                    <SectionTitle name="costs-section-mobile">
                        <span>Costs</span>
                        {readOnlyMode ||
                        !item.costUIDS ||
                        item.costUIDS.length === 0 ? null : (
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="32px"
                                customHeight="auto"
                                removePaddings
                                onClick={onCreateCostClick}
                            >
                                <AddIcon colour={colours[theme].primary} />
                            </StandardCtaBtn>
                        )}
                    </SectionTitle>
                </Row>

                <CostItemsSection>
                    {item.costUIDS && item.costUIDS.length > 0 && costsObj ? (
                        item.costUIDS.map((costID: string, index: number) => {
                            if (costsObj[costID] !== undefined) {
                                let cost: ICostItem = costsObj[costID]
                                return (
                                    <TimelineCostItem
                                        costItem={cost}
                                        unit={cost.currency}
                                        key={index}
                                        attachment={
                                            cost.attachmentID
                                                ? attachmentsObj[
                                                      cost.attachmentID
                                                  ]
                                                : undefined
                                        }
                                        // attachmentsObj={attachmentsObj}
                                        // file={attachmentsObj[item]}
                                        dataCyId={`edit-${cost.id}-mobile`}
                                        onClick={() =>
                                            !readOnlyMode && onCostClick
                                                ? onCostClick(cost.id)
                                                : {}
                                        }
                                        isCostAttachmentBeingUploaded={
                                            isCostAttachmentBeingUploaded
                                        }
                                        readOnlyMode={readOnlyMode}
                                        isCostAttachmentRestricted={
                                            readOnlyMode &&
                                            shared_with_you_car_policies &&
                                            !shared_with_you_car_policies.includes(
                                                'entry cost attachments'
                                            )
                                        }
                                    />
                                )
                            } else return null
                        })
                    ) : readOnlyMode ? (
                        <Row style={{ paddingTop: '10px', width: '100%' }}>
                            <NoDataRectangle
                                height="70px"
                                text="No costs"
                                isInfoRestricted={is_cost_info_restricted}
                            />
                        </Row>
                    ) : (
                        <Row style={{ paddingTop: 4, width: '100%' }}>
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="auto"
                                customHeight="auto"
                                removePaddings
                                onClick={onCreateCostClick}
                            >
                                <Row style={{ gap: 8 }}>
                                    <span
                                        style={{
                                            color: colours[theme].primary,
                                        }}
                                    >
                                        Add any invoices or costs
                                    </span>
                                    <AddIcon colour={colours[theme].primary} />
                                </Row>
                            </StandardCtaBtn>
                        </Row>
                    )}
                </CostItemsSection>
            </Col>

            {is_cost_info_restricted ? null : (
                <TotalCostWrapper>
                    <TotalCost
                        mobile
                        amount={
                            item.total_cost_amount_display
                                ? item.total_cost_amount_display
                                : '0.00'
                        }
                        unit={userCurrency}
                    />
                </TotalCostWrapper>
            )}

            <Col>
                <Row>
                    <SectionTitle name="notes-section-mobile">
                        <span>Notepad</span>
                        {readOnlyMode ||
                        !item.notes ||
                        item.notes === '<p><br></p>' ? null : (
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="32px"
                                customHeight="auto"
                                removePaddings
                                onClick={onEditNotes}
                            >
                                {item.notes && item.notes !== '<p><br></p>' ? (
                                    <PenIcon color={colours[theme].primary} />
                                ) : (
                                    <AddIcon colour={colours[theme].primary} />
                                )}
                            </StandardCtaBtn>
                        )}
                    </SectionTitle>
                </Row>
                <Row style={{ width: '100%' }}>
                    <NotesText style={{ width: '100%' }}>
                        {item.notes && item.notes !== '<p><br></p>' ? (
                            <TimelineNotesMobile
                                notes={item.notes}
                                isExternalCar
                            />
                        ) : readOnlyMode ? (
                            <Row style={{ width: '100%' }}>
                                <NoDataRectangle
                                    height="70px"
                                    text="No notes"
                                />
                            </Row>
                        ) : (
                            <Row style={{ paddingTop: 4, width: '100%' }}>
                                <StandardCtaBtn
                                    bg="transparent"
                                    boder="none"
                                    removeShadowOnHover
                                    customWidth="auto"
                                    customHeight="auto"
                                    removePaddings
                                    onClick={onEditNotes}
                                >
                                    <Row style={{ gap: 8 }}>
                                        <span
                                            style={{
                                                color: colours[theme].primary,
                                            }}
                                        >
                                            Add any notes or comments
                                        </span>
                                        <AddIcon
                                            colour={colours[theme].primary}
                                        />
                                    </Row>
                                </StandardCtaBtn>
                            </Row>
                        )}
                    </NotesText>
                </Row>
            </Col>
            <Col>
                <Row>
                    <SectionTitle name="notes-section-mobile">
                        <span>Gallery</span>
                        {readOnlyMode ||
                        !item.gallery_image_ids ||
                        item.gallery_image_ids.length === 0 ? null : (
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="32px"
                                customHeight="auto"
                                removePaddings
                                onClick={() =>
                                    dispatch(
                                        editFormsActions.toggleAddGalleryImageToEntryForm(
                                            {
                                                isOpen: true,
                                            }
                                        )
                                    )
                                }
                            >
                                <AddIcon colour={colours[theme].primary} />
                            </StandardCtaBtn>
                        )}
                    </SectionTitle>
                </Row>

                <Row>
                    {imagesData &&
                        item &&
                        item.gallery_image_ids &&
                        item.gallery_image_ids.length > 0 && (
                            <TimelineEntryGalleryGrid
                                images_data_object={imagesData}
                                ids_list={item.gallery_image_ids}
                                onImageClick={(imageid: string) =>
                                    readOnlyMode
                                        ? navigate(
                                              `${readOnlylinkPrefix}/car/${carID}/history-file/${entityID}/gallery?id=${imageid}`
                                          )
                                        : navigate(
                                              `/car/${carID}/history-file/${entityID}/gallery?id=${imageid}`
                                          )
                                }
                                onPlaceholderClick={() =>
                                    readOnlyMode
                                        ? navigate(
                                              `${readOnlylinkPrefix}/car/${carID}/history-file/${entityID}/gallery`
                                          )
                                        : navigate(
                                              `/car/${carID}/history-file/${entityID}/gallery`
                                          )
                                }
                            />
                        )}
                </Row>
                <div
                    style={{
                        paddingTop:
                            imagesData && item.gallery_image_ids
                                ? '15px'
                                : '0px',
                    }}
                />

                {!item.gallery_image_ids ||
                item.gallery_image_ids.length === 0 ? (
                    readOnlyMode ? (
                        <Row style={{ paddingTop: '10px' }}>
                            <NoDataRectangle height="70px" text="no images" />
                        </Row>
                    ) : (
                        <Row style={{ paddingTop: 4, width: '100%' }}>
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="auto"
                                customHeight="auto"
                                removePaddings
                                onClick={() =>
                                    dispatch(
                                        editFormsActions.toggleAddGalleryImageToEntryForm(
                                            {
                                                isOpen: true,
                                            }
                                        )
                                    )
                                }
                            >
                                <Row style={{ gap: 8 }}>
                                    <span
                                        style={{
                                            color: colours[theme].primary,
                                        }}
                                    >
                                        Add images to this entry
                                    </span>
                                    <AddIcon colour={colours[theme].primary} />
                                </Row>
                            </StandardCtaBtn>
                        </Row>
                    )
                ) : null}

                {isAddGalleryImageToEntryFormOpen && (
                    <Faded>
                        <ModalDisplay
                            isOpen={isAddGalleryImageToEntryFormOpen}
                            toggle={() =>
                                dispatch(
                                    editFormsActions.toggleAddGalleryImageToEntryForm(
                                        {
                                            isOpen: false,
                                        }
                                    )
                                )
                            }
                        >
                            <WrapperImageFormsMobile
                                close={() =>
                                    dispatch(
                                        editFormsActions.toggleAddGalleryImageToEntryForm(
                                            {
                                                isOpen: false,
                                            }
                                        )
                                    )
                                }
                                carid={`${carid}`}
                                entryid={entityID}
                            />
                        </ModalDisplay>
                    </Faded>
                )}
            </Col>
            <Col>
                <Row>
                    <SectionTitle name="attachments-section-mobile">
                        Attachments
                    </SectionTitle>
                </Row>
                <div style={{ paddingTop: '5px' }} />

                {isEntryAttachmentBeingUploaded && (
                    <AttachmentLoadingWrapper>
                        <LoaderElasticThreeDots />
                    </AttachmentLoadingWrapper>
                )}
                {item.attachmentIDs && item.attachmentIDs.length > 0 ? (
                    item.attachmentIDs.map((item: string, index: number) => {
                        if (attachmentsObj[item]) {
                            return (
                                <AttachmentInItemSinglePageMobile
                                    key={index}
                                    attachment={attachmentsObj[item]}
                                    // file={attachmentsObj[item]}
                                    onClickDelete={(id: string) => {
                                        let p: IDeleteEntryAttachmentPayloadReq =
                                            {
                                                attachmentID: id,
                                                carID: carID,
                                                entryID: entityID,
                                            }
                                        dispatch(
                                            timelineActions.deleteEntryAttachmentRequest(
                                                p
                                            )
                                        )
                                    }}
                                    readOnlyMode={readOnlyMode}
                                />
                            )
                        } else return <div key={index} />
                    })
                ) : !readOnlyMode ? null : (
                    <Row>
                        <div style={{ paddingTop: '10px' }} />
                        <NoDataRectangle
                            height="70px"
                            text="no attachments"
                            isInfoRestricted={
                                shared_with_you_car_policies &&
                                !shared_with_you_car_policies.includes(
                                    'entry attachments'
                                )
                            }
                        />
                    </Row>
                )}

                {item.attachmentIDs && item.attachmentIDs.length > 0 && (
                    <div style={{ paddingTop: '12px' }} />
                )}

                {!readOnlyMode && (
                    <Row>
                        <EntryAttachmentsFormWrapperMobile
                            entityID={props.entityID}
                            carid={props.carID}
                        />
                    </Row>
                )}
            </Col>
            <Col>
                <Row>
                    <SectionTitle name="labels-section-mobile">
                        Labels
                    </SectionTitle>
                </Row>
                <div style={{ paddingTop: item.labels ? '15px' : '0px' }} />
                <RowStart>
                    {item.labels && item.labels.length > 0 ? (
                        item.labels.map((label: ILabel, i: number) => (
                            <div
                                key={label.uid}
                                style={{
                                    display: 'flex',
                                    paddingBottom: '20px',
                                }}
                            >
                                {readOnlyMode ? (
                                    <CustomTagQuickFilterItemMobile>
                                        {label.name}
                                    </CustomTagQuickFilterItemMobile>
                                ) : (
                                    <CustomIconTagQuickFilterItem
                                        isActive={true}
                                        onCrossClick={() =>
                                            onRemoveLabel(label.name)
                                        }
                                        border={`1px solid ${colours[theme].border_muted}`}
                                        height="25px"
                                        bgcolor={
                                            colours[theme].background_default
                                        }
                                        isLabel
                                    >
                                        {label.name}
                                    </CustomIconTagQuickFilterItem>
                                )}
                                <div style={{ paddingRight: '10px' }} />
                            </div>
                        ))
                    ) : !readOnlyMode ? null : (
                        <NoDataRectangle height="70px" text="no labels" />
                    )}
                </RowStart>

                {!readOnlyMode && (
                    <Row>
                        <AnimatedWrapper isAnimated={section_id ? true : false}>
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="auto"
                                customHeight="auto"
                                removePaddings
                                onClick={onAddNewLabelClick}
                            >
                                <Row style={{ gap: 8 }}>
                                    <span
                                        style={{
                                            color: colours[theme].primary,
                                        }}
                                    >
                                        Add labels
                                    </span>
                                    <AddIcon colour={colours[theme].primary} />
                                </Row>
                            </StandardCtaBtn>
                        </AnimatedWrapper>
                    </Row>
                )}
            </Col>
            <div style={{ paddingBottom: '150px' }} />
        </Container>
    )
}
