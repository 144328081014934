import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import styled from 'styled-components'
import file_upload from '../../../public/assets/icons/file_upload.svg'
import Expander from '../../atoms/expander/expander'
import { Spin, message } from 'antd'
// import DropdownExpanderHover from '../../atoms/menu/dropdownExpander/dropdownExpanderHover'
import './antd.css'
import ImgPreview from './imgPreview'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
}

const NameOfFileUploaded = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: Lato;
    font-size: 12px;
    margin-right: 30px;
    max-width: 390px;
    padding-bottom: 7px;
    overflow: hidden;
`

const FileRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`

const FileCol = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
    padding-left: 35px;
    padding-right: 30px;
`

const ColumnFileList = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`

const StyledDropzone = styled.div<IStyle>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${(props) => colours[props.$theme].primary_50};
    border-radius: 5px;
    height: 70px;
    font-family: Lato;
    color: ${(props) => colours[props.$theme].primary};
    font-size: 14px;
    cursor: pointer;
    transition: all 500ms;

    :hover {
        background-color: ${(props) => colours[props.$theme].primary_20};
    }
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: inherit;
`

const ProgressBarImagesDesktopParentContainer = styled.div<IStyle>`
    border: 1px ${(props) => colours[props.$theme].primary} solid;
    display: flex;
    position: relative;
    height: 3px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
`

type ProgressProps = {
    progressPercent: number
    $theme: ITheme
}

const ProgressBarImagesDesktopChild = styled.div<ProgressProps>`
    display: flex;
    position: relative;
    height: 100%;
    width: ${(props) => `${props.progressPercent}%`};
    background-color: ${(props) => colours[props.$theme].primary};
    border-radius: 5px;
`

const PhotoAndDataWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: flex-start;
`

const CompletionRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Completed = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_darker};
    font-size: 14px;
    font-family: Lato;
    padding-left: 20px;
    width: 65px;
`

const Imcompleted = styled.div`
    width: 65px;
    height: 100%;
`

// const errorWarning = () => {
//   message.error("Please upload a maximum of 10 files.", 2);
// };

const errorWarning = () => {
    message.error({
        content: 'Please upload a maximum of 10 files.',
        duration: 2,
    })
}

const errorFileSizeWarning = () => {
    message.error({
        content: 'The maximum file size allowed per upload is 20MB.',
        duration: 2,
    })
}

const errorFileTypeWarning = (filename: string, message_text: string) => {
    message.error({
        content: `${filename} is not supported, ${message_text}`,
        duration: 2,
    })
}

type Props = {
    handleFilesGiven: any
    imageUploadedData: any
    removeFile: any
    handleSelectAs?: any
    removeSetAs?: any
    filesProgressObj?: any
}

function Accept(props: Props) {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 20) {
                errorWarning()
            } else if (acceptedFiles.length > 0 && acceptedFiles.length <= 20) {
                props.handleFilesGiven && props.handleFilesGiven(acceptedFiles)
            }
        },
        [props]
    )

    const onDropRejected = useCallback((fileRejections: any[]) => {
        let showTooManyFilesWarning = false
        for (let i = 0; i < fileRejections.length; i++) {
            if (fileRejections[i].errors) {
                let file = fileRejections[i].file
                let errors: any[] = fileRejections[i].errors
                for (let i = 0; i < errors.length; i++) {
                    if (errors[i].code === 'too-many-files') {
                        showTooManyFilesWarning = true
                    } else {
                        if (errors[i].code === 'file-invalid-type') {
                            errorFileTypeWarning(file.name, errors[i].message)
                        }
                        if (
                            errors[i].code === 'file-too-large' ||
                            errors[i].code === 'file-too-small'
                        ) {
                            errorFileSizeWarning()
                        }
                    }
                }
            }
        }
        if (showTooManyFilesWarning) {
            errorWarning()
        }
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        onDrop,
        onDropRejected,
        // accept: 'image/jpeg, image/png, image/heic, image/heif',
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/heic': ['.heic'],
            'image/heif': ['.heif'],
        },
        maxFiles: 20,
        minSize: 0,
        maxSize: 20000000,
    })

    const { filesProgressObj, imageUploadedData } = props

    const { theme } = useThemes()

    const filesArrayUndefinedTrimmed = imageUploadedData.filter(
        (img: any) => img && img !== undefined
    )

    const lengthOfFilesArray = filesArrayUndefinedTrimmed.length

    const files = acceptedFiles.map((file: any, index: number) => {
        if (file !== undefined && filesProgressObj) {
            let new_key = `filesProgressObj-${index}`

            return (
                <Expander
                    width="100%"
                    height={
                        filesProgressObj && filesProgressObj[new_key]
                            ? 'auto'
                            : 0
                    }
                    key={file.path ? file.path : `file-${index}`}
                >
                    {filesProgressObj && (
                        <FileRow
                            key={file.path ? file.path : `file-${index}`}
                            style={{
                                paddingTop: index !== 0 ? '30px' : '0px',
                            }}
                        >
                            <PhotoAndDataWrapper>
                                {(filesProgressObj &&
                                    filesProgressObj[new_key]) === 100 ? (
                                    file.type === 'image/jpeg' ||
                                    file.type === 'image/png' ? (
                                        <img
                                            src={URL.createObjectURL(file)}
                                            alt="car gallery"
                                            style={{
                                                height: '30px',
                                                width: '30px',
                                                borderRadius: '5px',
                                            }}
                                        />
                                    ) : (
                                        <ImgPreview
                                            currentImagesUploaded={file}
                                        />
                                    )
                                ) : (
                                    <Spin />
                                )}
                                <FileCol>
                                    <NameOfFileUploaded $theme={theme}>
                                        {file.path}
                                    </NameOfFileUploaded>
                                    <CompletionRow>
                                        <ProgressBarImagesDesktopParentContainer
                                            $theme={theme}
                                        >
                                            <ProgressBarImagesDesktopChild
                                                $theme={theme}
                                                progressPercent={
                                                    filesProgressObj &&
                                                    filesProgressObj[new_key]
                                                        ? filesProgressObj[
                                                              new_key
                                                          ]
                                                        : 0
                                                }
                                            />
                                        </ProgressBarImagesDesktopParentContainer>

                                        {filesProgressObj &&
                                            filesProgressObj[new_key] ===
                                                100 && (
                                                <Completed $theme={theme}>
                                                    100%
                                                </Completed>
                                            )}

                                        {!filesProgressObj ||
                                            (filesProgressObj[new_key] !==
                                                100 && (
                                                <Imcompleted></Imcompleted>
                                            ))}
                                    </CompletionRow>
                                </FileCol>
                            </PhotoAndDataWrapper>

                            <ButtonRow>
                                <div style={{ transform: 'translateY(0px)' }}>
                                    <ButtonAtom
                                        onClick={(e: any) => {
                                            e.preventDefault()
                                            // fake function
                                            props.removeSetAs &&
                                                props.removeSetAs(file.path)
                                            props.removeFile &&
                                                props.removeFile(index)
                                        }}
                                        theme="bin_img_circle"
                                        width="21px"
                                    />
                                </div>
                            </ButtonRow>
                        </FileRow>
                    )}
                </Expander>
            )
        } else return <div key={index} />
    })

    // let fake_progress: number = 30;

    return (
        <div className="container" style={{ width: '100%' }}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {isDragAccept && (
                    <StyledDropzone $theme={theme}>... </StyledDropzone>
                )}

                {isDragReject && (
                    <StyledDropzone $theme={theme}>... </StyledDropzone>
                )}

                <Expander
                    height={
                        !isDragActive && lengthOfFilesArray < 1 ? 'auto' : 0
                    }
                >
                    <StyledDropzone $theme={theme}>
                        <img src={file_upload} alt="upload file" />
                        <span style={{ width: '10px' }} />
                        <div style={{ color: colours[theme].primary }}>
                            Drag image(s) or click to browse
                        </div>
                    </StyledDropzone>
                </Expander>
            </div>
            <ColumnFileList>
                <div>{files} </div>
                {/* loading case */}
            </ColumnFileList>

            <div style={{ paddingTop: '20px' }} />
        </div>
    )
}

export default Accept
