import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import blank_user_dark from '../../../public/assets/placeholders_svgs/avatar_dark.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import useThemes from '../../../providers/theme/hooks'

type IStyleProps = {
    hasImage?: boolean
}

const UserImgWrapper = styled.div<IStyleProps>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--base-overlays-neutral-alpha-50);
    border-radius: 16px;
    padding: 8px;
    border: 1px solid var(--base-overlays-neutral-alpha-100);
    @media ${device.desktop} {
        height: 110px;
        width: 110px;
    }
    @media ${device.smallest_laptops} {
        height: 100px;
        width: 100px;
    }
    @media ${device.ipad} {
        height: 100px;
        width: 100px;
    }
    @media ${device.large_desktop} {
        height: 110px;
        width: 110px;
    }
`

const UserImg = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 16px;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    min-width: 108px;
    margin-right: 40px;
    margin-left: 20px;

    @media ${device.smallest_laptops} {
        min-width: 50px;
        margin-right: 0px;
        margin-left: 0px;
    }
    @media ${device.ipad} {
        min-width: 50px;
        margin-right: 0px;
        margin-left: 0px;
    }
`

type Props = {
    size?: string
    url?: any
}

const UserAccountCircleLarge: React.FC<Props> = ({ size, url }) => {
    const [src, setSrc] = React.useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    let { theme } = useThemes()

    React.useEffect(() => {
        const initialSrc =
            url !== undefined && url !== false && url !== null && url.length > 1
                ? url
                : theme === 'dark'
                ? blank_user_dark
                : blank_user
        if (initialSrc) {
            setSrc(initialSrc)
        }
    }, [url])

    return (
        <Wrapper>
            <UserImgWrapper hasImage={src !== undefined}>
                <UserImg src={src} onLoad={() => setIsLoading(false)} />
                <SkeletonAbsolute
                    isthingloading={isLoading}
                    customBorderRadius={'16px'}
                />
            </UserImgWrapper>
        </Wrapper>
    )
}

export default UserAccountCircleLarge
