import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { menuActions } from '../../../../redux/menus/reducer'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import ShowroomEntryBasicInfoManagerMobile from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerMobile'
import Faded from '../../../templates/animated/faded'
import styled from 'styled-components'
import { IShowroomEntryBasicDataForm } from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerDesktop'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { IUser } from '../../../../redux/user/types'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'

const PageContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
const Line = styled.div`
    width: 90%;
    height: 4px;
    background: var(--primary, #5ec3ca);
    border-radius: 100px;
    align-self: center;
`

const Wrapper = styled.div`
    background-color: var(--bg-color, #fff);
    height: 100%;
    min-height: 85vh;
    width: 100%;
    box-sizing: border-box;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    position: relative;
    transition: all 200ms ease-in;
`

const Title = styled.div`
    font-family: Lato-bold;
    font-size: 24px;
    line-height: 40px;
    color: var(--text-strong, #1a1a1a);
`

const PublishToShowroomMobileStep: React.FunctionComponent = () => {
    let userLoggedIn: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const dispatch = useAppDispatch()
    const { carid } = useParams<{ carid: string }>()
    const navigate = useNavigate()

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [])

    useEffect(() => {
        if (userLoggedIn && carid) {
            if (!carsData || (carsData && !carsData[carid])) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            }
        }
    }, [userLoggedIn, carid])

    useEffect(() => {
        // trigger the function to dispatch the top sub menu remove action
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        // set up nav back as before on page unmount
        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    const submit = (formData: IShowroomEntryBasicDataForm) => {
        if (carid) {
            dispatch(
                showroomActions.createEntryShowroomRequest({
                    resource_id: carid,
                    type_of_sale: formData.poa
                        ? 'PRICE_ON_ASKING'
                        : formData.set_price
                        ? 'SET_PRICE'
                        : 'PRICE_RANGE',
                    price_min:
                        formData.set_price && formData.set_price.value
                            ? formData.set_price.value
                            : formData.price_range?.valueFrom,
                    price_max:
                        formData.set_price && formData.set_price.value
                            ? formData.set_price.value
                            : formData.price_range?.valueTo,
                    currency:
                        formData.price_range && formData.price_range.currency
                            ? formData.price_range.currency
                            : formData.set_price && formData.set_price.currency
                            ? formData.set_price.currency
                            : 'GBP',
                    open_to_offers: formData.set_price
                        ? formData.set_price.open_to_offer
                        : formData.set_price
                        ? false
                        : undefined,
                })
            )
        }
    }

    return (
        <IpadAndMobileDisplay>
            <Faded>
                <PageContainer>
                    <div style={{ paddingTop: '40px' }} />

                    <Line />
                    <div style={{ paddingTop: '30px' }} />
                    <Wrapper>
                        <Title>Set your car's price</Title>

                        <div style={{ paddingTop: '39px' }} />
                        <ShowroomEntryBasicInfoManagerMobile
                            onSubmit={submit}
                            onCancel={() => {
                                if (carid) {
                                    navigate(`/car/${carid}`)
                                }
                            }}
                        />
                    </Wrapper>
                </PageContainer>
            </Faded>
        </IpadAndMobileDisplay>
    )
}

export default PublishToShowroomMobileStep
