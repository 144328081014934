import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { convertDateToBeFormat } from '../../conversions/time/dates'
import { getUserCurrencyPreference } from './addCarTimelineEntryCost'
import { message } from 'antd'
import { carActions } from '../../entities/cars/reducer'
import { timelineActions } from '../reducer'
import {
    ICreateEntryPayloadAPI,
    IEntryPayloadAPI,
    ICreateEntryCostPayloadAPI,
} from '../../services/typedefinitions/apiPayloads'
import {
    IApiEntryCREATE_args,
    IApiEntryCostCREATE_args,
} from '../../services/types'
import {
    IAddTimelineItemsWithCostPayloadRequest,
    ITimelineItem,
} from '../types'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'
export const insurance_history_fiel_specific_fields = [
    'insurance_entry_policy_start_date',
    'insurance_entry_policy_end_date',
    'insurance_entry_underwriter',
    'insurance_entry_broker',
]

const successInfo = (messageText: string) => {
    message.success(messageText, 1)
}

export function* AddCarTimelinesWithCostSaga(
    payload: IAddTimelineItemsWithCostPayloadRequest
): any {
    let userCurrencyPreference = yield select(getUserCurrencyPreference)

    try {
        for (let i = 0; i < payload?.items.length; i++) {
            let item: ITimelineItem = payload?.items[i]

            if (item.insurance_entry_policy_start_date) {
                item.insurance_entry_policy_start_date = convertDateToBeFormat(
                    item.insurance_entry_policy_start_date
                )
            }

            if (item.insurance_entry_policy_end_date) {
                item.insurance_entry_policy_end_date = convertDateToBeFormat(
                    item.insurance_entry_policy_end_date
                )
            }
            // STEP 1 . build up correct payload to send to api => this is the JSON body
            let convertedDate =
                item.occurred_at && convertDateToBeFormat(item.occurred_at)

            let api_payload_req_entry: ICreateEntryPayloadAPI = {
                title: item.title
                    ? item.title
                    : `${item.categoryID ? item.categoryID : 'Untitled'} entry`,
                entry_type: item.categoryID
                    ? item.categoryID === 'other'
                        ? 'others'
                        : item.categoryID
                    : 'others',
                description: item.short_description
                    ? item.short_description
                    : undefined,
                notes: item.notes ? item.notes : undefined,
                // entry_labels: item.labels ? item.labels : undefined,
                occurred_at: convertedDate ? convertedDate : undefined,
                // etc
            }

            let fields: string[] | undefined = undefined

            if (
                item.categoryID === 'insurance' &&
                item?.insurance_entry_policy_start_date
            ) {
                api_payload_req_entry.occurred_at = convertDateToBeFormat(
                    item.insurance_entry_policy_start_date
                )
                fields = [...insurance_history_fiel_specific_fields]
            }

            if (fields) {
                for (let i = 0; i < fields.length; i++) {
                    if (item[fields[i]]) {
                        api_payload_req_entry = {
                            ...api_payload_req_entry,
                            [fields[i]]: fields[i].includes('date')
                                ? convertDateToBeFormat(item[fields[i]])
                                : item[fields[i]],
                        }
                    }
                }
            }

            // STEP 2 . call the api func and keep data returned in a const
            let api_payload: IApiEntryCREATE_args = {
                newEntryData: api_payload_req_entry,
                car_id: payload.carid,
            }
            const newEntry: IEntryPayloadAPI = yield call(
                api.timeline.entries.createEntry.createEntry,
                api_payload
            )

            // STEP 3 . convert API data to reducer data type
            // check what back end returns on console log or network window inspector:
            // sometimes theres a nested object where you get the item after .data,
            // but sometimes it returns straight on the item returned, depends on the function

            //update car entries count
            yield put(
                carActions.updatCarEntriesCount({
                    carid: payload.carid,
                    update: 'add',
                })
            )

            // make it so that it gets fetched again
            if (item?.categoryID === 'insurance') {
                yield put(
                    carActions.getLastCarInsuranceEntrySuccess({
                        carid: payload.carid,
                        timelineItem: undefined,
                    })
                )

                posthog.capture('insurance entry created')
            }

            // Capture event
            posthog.capture('add_car_entry from journey')

            if (
                newEntry?.uid &&
                item.costItemsObj &&
                item.costItemsObj['id'] &&
                item.costItemsObj['id'].amount
            ) {
                let cost = item.costItemsObj['id']
                // Capture event
                posthog.capture('add_car_cost from journey')

                let api_payload_req_entry_cost: ICreateEntryCostPayloadAPI = {
                    name: cost.name ? cost.name : `${item.categoryID} cost`,
                    amount:
                        cost.amount && isNaN(cost.amount) !== true
                            ? (cost.amount * 100).toString()
                            : undefined,
                    currency: cost.currency
                        ? cost.currency
                        : userCurrencyPreference
                        ? userCurrencyPreference
                        : 'GBP',
                    company_name: cost.company ? cost.company : undefined,
                    occurred_at: cost.date ? cost.date : undefined,
                }

                // STEP 2 . call the api func and keep data returned in a const
                const p2: IApiEntryCostCREATE_args = {
                    itemid: newEntry?.uid,
                    carid: payload.carid,
                    cost: api_payload_req_entry_cost,
                }
                yield call(
                    api.timeline.costs.createEntryCost.createEntryCost,
                    p2
                )

                // STEP 3 . convert API data to reducer data type
                // check what back end returns on console log or network window inspector:
                // sometimes theres a nested object where you get the cost after .data,
                // but sometimes it returns straight on the cost returned, depends on the function
            }
        }

        if (payload.fetch_last_insurance) {
            yield put(carActions.getLastCarInsuranceEntryRequest(payload.carid))
        }

        yield put(carActions.resetCarInsights(payload.carid))
        yield put(timelineActions.resetTimelineItems(payload.carid))
        successInfo(`Information successfully added to car's timeline`)
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: AddCarTimelinesWithCostSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try adding a timeline item again.`,
                custom_user_action_text: 'Return to timeline',
                custom_redirect_path: `/car/${payload.carid}/history-file`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(timelineActions.addTimelineItemToCarError(customError))
        }
    }
}

function* watcherAddCarTimelineWithCost() {
    while (true) {
        const { payload } = yield take(
            timelineActions.addTimelineItemWithCostsToCarRequest
        )

        yield call(AddCarTimelinesWithCostSaga, payload)
    }
}

const add_car_timeline_with_costs: any[] = [fork(watcherAddCarTimelineWithCost)]

export default add_car_timeline_with_costs
