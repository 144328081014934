import React, { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../../redux/store/hooks'
import Faded from '../../templates/animated/faded'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import ios_share from '../../../public/assets/icons/ios_share.svg'
import browser_menu from '../../../public/assets/icons/browser_menu.svg'
import pwa_help_step1_ios from '../../../public/assets/images/pwa_help/PWA_help_step1_ios.png'
import pwa_help_step2_ios from '../../../public/assets/images/pwa_help/PWA_help_step2_ios.png'
import pwa_help_step3_ios from '../../../public/assets/images/pwa_help/PWA_help_step3_ios.png'
import pwa_help_step4_ios from '../../../public/assets/images/pwa_help/PWA_help_step4_ios.png'
import pwa_help_step1_android from '../../../public/assets/images/pwa_help/PWA_help_step1_android.png'
import pwa_help_step2_android from '../../../public/assets/images/pwa_help/PWA_help_step2_android.png'
import pwa_help_step3_android from '../../../public/assets/images/pwa_help/PWA_help_step3_android.png'
import pwa_help_step4_android from '../../../public/assets/images/pwa_help/PWA_help_step4_android.png'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { checkIfIpad } from '../../templates/pwaCustom/checkIpadPwa'

const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

const Image = styled.img`
    width: 40%;
    box-sizing: border-box;
    height: 100%;
    margin-bottom: 20px;
    @media ${device.desktop} {
        display: none;
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
`

const Text = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-Light';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
`

const ShareIcon = styled.img`
    height: 25px;
    transform: translateY(-3px);
`

const InstallApp: React.FC = () => {
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl('/account/install-app')
        )
    }, [])

    const ua = window.navigator.userAgent
    const isIPad = checkIfIpad()
    const isIPhone = !!ua.match(/iPhone/i)
    const isIOS = isIPad || isIPhone

    return (
        <CenteredPageWrapper hasMobileSubNav>
            {userLoggedIn &&
                (isIOS ? (
                    <Faded>
                        <Loader />
                        <DesktopDisplayOnly>
                            <div
                                style={{
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                }}
                            >
                                <SectionHeader
                                    title={'Install app'}
                                    removeEdit={true}
                                />
                                {/* install app component for desktop here */}
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <div style={{ height: '30px' }} />
                            <TextContainer>
                                <Text>
                                    Download this app on your iPhone or iPad:{' '}
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        tap
                                        <span style={{ paddingLeft: '5px' }}>
                                            <ShareIcon
                                                src={ios_share}
                                                alt="share"
                                            />
                                        </span>
                                    </span>
                                    <span style={{ paddingLeft: '5px' }}></span>
                                    and then select{' '}
                                    <span
                                        style={{
                                            fontFamily: 'Lato-bold',
                                        }}
                                    >
                                        Add to Home Screen
                                    </span>
                                </Text>
                            </TextContainer>
                            <ImagesContainer>
                                <Image
                                    src={pwa_help_step1_ios}
                                    alt="pwa_help_step1"
                                />
                                <Image
                                    src={pwa_help_step2_ios}
                                    alt="pwa_help_step2"
                                />
                                <Image
                                    src={pwa_help_step3_ios}
                                    alt="pwa_help_step3"
                                />
                                <Image
                                    src={pwa_help_step4_ios}
                                    alt="pwa_help_step4"
                                />
                            </ImagesContainer>
                            <div style={{ height: '100px' }} />
                        </IpadAndMobileDisplay>
                    </Faded>
                ) : (
                    <Faded>
                        <Loader />
                        <DesktopDisplayOnly>
                            <div
                                style={{
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                }}
                            >
                                <SectionHeader
                                    title={'Install app'}
                                    removeEdit={true}
                                />
                                {/* install app component for desktop here */}
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <div style={{ height: '30px' }} />
                            <TextContainer>
                                <Text>
                                    To install this web-app to your homescreen
                                    open the browser option{' '}
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        menu:
                                        <span
                                            style={{
                                                paddingLeft: '5px',
                                                paddingRight: '5px',
                                            }}
                                        >
                                            <img
                                                style={{
                                                    height: '25px',
                                                }}
                                                src={browser_menu}
                                                alt="browser menu"
                                            />
                                        </span>
                                        and{' '}
                                    </span>
                                    tap on{`  `}
                                    <span style={{ fontWeight: 'bold' }}>
                                        Add to home screen
                                    </span>{' '}
                                    or{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        Install app
                                    </span>
                                    .
                                </Text>
                            </TextContainer>
                            <ImagesContainer>
                                <Image
                                    src={pwa_help_step1_android}
                                    alt="pwa_help_step1"
                                />
                                <Image
                                    src={pwa_help_step2_android}
                                    alt="pwa_help_step2"
                                />
                                <Image
                                    src={pwa_help_step3_android}
                                    alt="pwa_help_step3"
                                />
                                <Image
                                    src={pwa_help_step4_android}
                                    alt="pwa_help_step4"
                                />
                            </ImagesContainer>
                            <div style={{ height: '100px' }} />
                        </IpadAndMobileDisplay>
                    </Faded>
                ))}
        </CenteredPageWrapper>
    )
}

export default InstallApp
