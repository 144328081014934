import React from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import ReturnNav from '../../../atoms/header/returnNav'
import Loader from '../../../atoms/loader/loader'
import EntryOptionsMenuBar from '../../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import EntryGalleryPageTopHeaderDesktop from '../../../molecules/entryPageTopHeader/entryGalleryPageTopHeaderDesktop'
import EntryGalleryManagerDesktop from '../../../organisms/entryGalleryManager/entryGalleryManagerDesktop'
import EntryGalleryManagerMobile from '../../../organisms/entryGalleryManager/entryGalleryManagerMobile'
import Faded from '../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../../templates/displays/pageWrappers/CenteredPageWrapper'
import PortalInvisibleWrapper from '../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import WindowPageTemplate from '../../../templates/windowPageTemplate/windowPageTemplateDesktop'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import SlidingUpOnScrollBanner from '../../../atoms/header/slidingUpOnScrollBanner'
import ExternalCarSingleEntryGalleryImage from '../../../pages/car/externalCar/externalCarSingleEntryGalleryImage'
import CarHistoryFileEntryGalleryImageArchive from '../../../pages/archive/car/historyFileEntryGalleryImage'
import { useAppSelector } from '../../../../redux/store/hooks'
import { IUser } from '../../../../redux/user/types'
import { ICar, IExternalCar } from '../../../../redux/entities/cars/types'
import { ITimelineItem } from '../../../../redux/timeline/types'

const IpadAndMobilePadding = styled.div`
    padding-top: 0px;
    @media ${device.ipad} {
        padding-top: 50px;
    }
`

interface Props {
    userLoggedIn: IUser | null
    sharedCar?: IExternalCar
    prevOwned?: ICar
}

const ReadOnlyCarHistoryFileEntryGallery: React.FC<Props> = ({
    userLoggedIn,
    sharedCar,
    prevOwned,
}) => {
    const { carid, entryid } = useParams<{ carid: string; entryid: string }>()
    const location = useLocation()
    const navigate = useNavigate()

    const timelineData = useAppSelector(
        (state) => state.timeline.timeline_data_normalised_by_car_id
    )
    const isActiveEntryLoading = useAppSelector(
        (state) => state.timeline.loading
    )
    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )

    const params = new URLSearchParams(location.search)
    const image_id = params.get('id')

    const all_entries =
        timelineData &&
        timelineData[`${carid}`] &&
        timelineData[`${carid}`].entries

    const indexOFActiveEntry = all_entries?.findIndex(
        (entry: ITimelineItem) => entry.id === entryid
    )

    const activeEntry =
        all_entries &&
        indexOFActiveEntry !== undefined &&
        all_entries[indexOFActiveEntry]

    const currentExternalCar = sharedCar ?? prevOwned
    const linkPrefix = sharedCar ? '/shared' : '/archives'

    return (
        <>
            <CenteredPageWrapper fullwidth>
                <Loader isLoading={isActiveEntryLoading} />
                <Faded>
                    {activeEntry && (
                        <>
                            <DesktopDisplayOnly>
                                <Faded>
                                    <div
                                        style={{
                                            position: 'relative',
                                        }}
                                    >
                                        <WindowPageTemplate
                                            isCollapsed={isCollapsed}
                                            hasPositionRelative
                                            rightSideContent={
                                                <EntryOptionsMenuBar
                                                    carid={`${carid}`}
                                                    activeEntry={activeEntry}
                                                    noAddLabelButton
                                                    readOnlyMode
                                                />
                                            }
                                            isExternalCar
                                            hideOnIpad
                                            stickyHeaderContent={
                                                <EntryGalleryPageTopHeaderDesktop
                                                    item={activeEntry}
                                                    carID={`${carid}`}
                                                    uploadImage={() => {}}
                                                    readOnlyMode
                                                    sharedCar={sharedCar}
                                                />
                                            }
                                        >
                                            <IpadAndMobilePadding />
                                            <EntryGalleryManagerDesktop
                                                ids_list={
                                                    activeEntry.gallery_image_ids
                                                }
                                                openImgForm={() => {}}
                                                readOnlyMode
                                            />
                                        </WindowPageTemplate>
                                    </div>
                                </Faded>
                            </DesktopDisplayOnly>

                            {!image_id && (
                                <IpadAndMobileDisplay>
                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <Link
                                            to={{
                                                pathname: image_id
                                                    ? `${linkPrefix}/car/${carid}/history-file/${activeEntry.id}/gallery`
                                                    : `${linkPrefix}/car/${carid}/history-file/entry`,
                                                search: `?entryid=${activeEntry.id}`,
                                            }}
                                        >
                                            <ReturnNav />
                                        </Link>
                                        {currentExternalCar && (
                                            <SlidingUpOnScrollBanner
                                                text={
                                                    currentExternalCar.title
                                                        .length > 32
                                                        ? `${currentExternalCar.title.slice(
                                                              0,
                                                              34
                                                          )}...`
                                                        : currentExternalCar.title
                                                }
                                                customStyle={{
                                                    transform:
                                                        'translateY(50px)',
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div style={{ paddingTop: '50px' }} />
                                    <EntryGalleryManagerMobile
                                        ids_list={activeEntry.gallery_image_ids}
                                        openImgForm={() => {}}
                                        isExternalCar
                                    />
                                </IpadAndMobileDisplay>
                            )}
                        </>
                    )}
                </Faded>
            </CenteredPageWrapper>
            {image_id && (
                <PortalInvisibleWrapper
                    onClick={() => {
                        navigate(location.pathname)
                    }}
                >
                    {sharedCar ? (
                        <ExternalCarSingleEntryGalleryImage />
                    ) : (
                        <CarHistoryFileEntryGalleryImageArchive />
                    )}
                </PortalInvisibleWrapper>
            )}
        </>
    )
}

export default ReadOnlyCarHistoryFileEntryGallery
