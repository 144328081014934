import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../../services'
import { HFstoAPIconversion } from '../../../conversions/entities/highlightedFactsConversions'
import posthog from 'posthog-js'

import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IHighlightedFact } from 'entityModels'
import { IHighlightedFactPayload } from '../../../services/typedefinitions/apiPayloads'
import { IApiUpdateCarHighFact_args } from '../../../services/types'
import { IReduxError } from '../../cars/types'
import { highlightedFactsActions } from '../reducer'

type IupdateCarHighlightedFactsApiSagaPayloadReq = {
    carid: string
    highlightedFacts: IHighlightedFact[]
}

function* updateCarHighlightedFactsApi(
    payload: IupdateCarHighlightedFactsApiSagaPayloadReq
) {
    try {
        let api_data: IHighlightedFactPayload[] = HFstoAPIconversion(
            payload.highlightedFacts
        )

        let api_payload: IApiUpdateCarHighFact_args = {
            car_id: payload.carid,
            facts: api_data,
        }

        yield api.entities.updateCar.updateCarHighlightedFactsAPI(api_payload)

        for (let i = 0; i < payload.highlightedFacts.length; i++) {
            yield put(
                highlightedFactsActions.updateHighlightedFactsSuccess(
                    payload.highlightedFacts[i]
                )
            )
        }

        // Capture event
        posthog.capture('UPDATE CAR HIGHLIGHTED FACTS')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: updateCarHighlightedFactsApi,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                highlightedFactsActions.updatetHighlightedFactsError(typedError)
            )
            return
        }
    }
}

function* watcherUpdateCarHighlightedFacts() {
    while (true) {
        const { payload } = yield take(
            highlightedFactsActions.updatetHighlightedFactsRequest
        )

        let p: IupdateCarHighlightedFactsApiSagaPayloadReq = {
            carid: payload.carid,
            highlightedFacts: payload.highlightedFacts,
        }

        yield call(updateCarHighlightedFactsApi, p)
    }
}

const hf_mutate_sagas: any[] = [fork(watcherUpdateCarHighlightedFacts)]

export default hf_mutate_sagas
