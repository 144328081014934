import React, { useState, useEffect } from 'react'
import Faded from '../animated/faded'
import DesktopDisplayOnly from '../displays/desktopDisplayOnly'
import * as SvgIcons from '../../atoms/icons/components'
import styled from 'styled-components'
import NoltWidget from '../widgets/noltWidget'
import posthog from 'posthog-js'
import { device } from '../displays/devices'
import { IUser } from '../../../redux/user/types'

const jose = require('jose')

const GreenBox = styled.button`
    all: unset;
    position: relative;
    cursor: pointer;
    border: 1px solid #5ec3ca;
    border-radius: 8px;
    padding: 16px 24px;
    margin: 24px;
    color: #5ec3ca;
    text-align: left;

    @media (min-width: 881px) and (max-width: 1600px) {
        padding: 12px 16px;
        margin: 16px;
    }
`
const GreenBoxTitle = styled.div`
    font-family: Lato-semibold;
    font-size: 16px;
    margin-bottom: 8px;
`
const GreenBoxContent = styled.div`
    font-family: Lato;
    font-size: 14px;

    @media (min-width: 881px) and (max-width: 1600px) {
        font-size: 13px;
    }
`

const IconWrapper = styled.button<{ $isCollapsed: boolean }>`
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    height: 64px;
    width: 72px;
    cursor: pointer;
    border: transparent !important;
    user-select: none;
    background-color: transparent;
    @media ${device.desktop} {
        transform: ${(props) =>
            props.$isCollapsed ? 'translate(8px, 0px)' : 'translate(0px)'};
    }
    @media ${device.large_desktop} {
        transform: translate(0px);
    }
    @media ${device.smallest_laptops} {
        transform: ${(props) =>
            props.$isCollapsed ? 'translate(8px, 0px)' : 'translate(0px)'};
    }
`

export const generateNoltToken = async (user: IUser) => {
    const payload = {
        // The ID that you use in your app for this user
        id: user.id,
        // The user's email address that
        // Nolt should use for notifications
        email: user.email ? user.email : '',
        // The display name for this user
        name: user.given_name,
    }

    const sec = 'heywearecustodiananditscoolgoplanet!!'

    const jwtt = await new jose.SignJWT({ ...payload })
        .setProtectedHeader({ alg: 'HS256' })
        .sign(new TextEncoder().encode(sec))

    return jwtt
}

interface Props {
    userLoggedIn: null | IUser
    isCollapsed: boolean
}

const IdeasButtonOnNav: React.FC<Props> = ({ userLoggedIn, isCollapsed }) => {
    const [jwt, setJwt] = useState<any>(null)
    // const [visibility, setVisibility] = useState<boolean>(true)

    useEffect(() => {
        if (userLoggedIn) {
            generateNoltToken(userLoggedIn).then((jwt) => {
                setJwt(jwt)
            })
        }
    }, [userLoggedIn])

    return (
        <DesktopDisplayOnly>
            {userLoggedIn !== null && (
                <Faded>
                    {isCollapsed ? (
                        <IconWrapper
                            $isCollapsed={isCollapsed}
                            onClick={(e: any) => e.stopPropagation()}
                        >
                            <SvgIcons.LightbulbIcon />
                            <div
                                id="nolt-click-surface"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    height: '100%',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    zIndex: 1,
                                }}
                            >
                                <NoltWidget jwt={jwt}>
                                    <div
                                        onClick={() => {
                                            posthog.capture(
                                                'ideas_button clicked'
                                            )
                                        }}
                                        id="nolt-click-surface"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                        }}
                                        data-attr="ideas_btn_desktop"
                                    ></div>
                                </NoltWidget>
                            </div>
                        </IconWrapper>
                    ) : (
                        <GreenBox onClick={(e: any) => e.stopPropagation()}>
                            <GreenBoxTitle>Got an idea?</GreenBoxTitle>
                            <GreenBoxContent>
                                Let us know what would make our platform even
                                better!
                            </GreenBoxContent>
                            <div
                                id="nolt-click-surface"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    height: '100%',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    zIndex: 1,
                                }}
                            >
                                <NoltWidget jwt={jwt}>
                                    <div
                                        onClick={() => {
                                            posthog.capture(
                                                'ideas_button clicked'
                                            )
                                        }}
                                        id="nolt-click-surface"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                        }}
                                        data-attr="ideas_btn_desktop"
                                    ></div>
                                </NoltWidget>
                            </div>
                        </GreenBox>
                    )}
                </Faded>
            )}
        </DesktopDisplayOnly>
    )
}

export default IdeasButtonOnNav
