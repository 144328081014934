import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import InputField from '../../../../atoms/Inputfield/inputField'
import * as Yup from 'yup'
import ButtonsStandardBottomRowDesktop from '../../buttons/buttonsStandardBottomRowDesktop'
import MultipleSelectSearchBarCarInfoDesktop from '../../technicalInformationEditForm/multipleselect/desktop/multipleSelectSearchBarCarInfoDesktop'

import SwitchButton from '../../../../atoms/Button/switchButton'
import { OOtherTaskOptions } from '../../../../../ol/ol'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
} from '../../../../molecules/technicalInformation/modal'
import Expander from '../../../../atoms/expander/expander'
import Faded from '../../../../templates/animated/faded'
import dayjs from 'dayjs'
import useThemes from '../../../../../providers/theme/hooks'
import colours from '../../../../../providers/theme/colours'

const FieldTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 17px;
    line-height: 160%;
`

const FormTitle = styled.div`
    font-family: Lato;
    font-size: 18px;
    color: var(--text-strong, #1a1a1a);
    width: 100%;
    box-sizing: border-box;
    text-align: start;
    font-size: 22px;
    padding-bottom: 30px;

    @media ${device.large_desktop} {
        font-size: 22px;
    }
`

const LargePadding = styled.div`
    padding-top: 70px;
`

export const DescriptionHeader = styled.div`
    padding-top: 7px;
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    text-align: left;
    padding-right: 5vw;
    font-family: Lato-Light;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
`

type IstyledToggleItem = {
    isActive?: boolean
}
const ToggleText = styled.div<IstyledToggleItem>`
    padding-left: 16px;
    text-transform: capitalize;
    font-family: lato;
    font-size: 14px;
    letter-spacing: 0px;
    transform: translateY(-3px);
    color: ${(props) =>
        props.isActive
            ? 'var(--primary, #5ec3ca)'
            : 'var(--text-default, #666666)'};
`

const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const Error = styled.div`
    color: red;
    font-style: italic;
    font-size: 11px;
`

interface Values {
    title: string
    linkedToCar: string[]
    multiple_car_names_values_array?: string[] | undefined
    linkedToAll?: string
    due_date: string | undefined
}

interface OtherProps {
    toggle: any
    submitFunc: any
    dropdownData: any
    carsData: any
    garageCarsIds: string[]
    garage_id: string
    currentCarID?: string | null
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        setFieldValue,
        dropdownData,
        currentCarID,
        carsData,
        errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    const handleDueDateAnswerChange = (date: string) => {
        setFieldValue('due_date', date)
    }

    let checkIfDisabled = () => {
        if (!values.title) {
            return true
        } else if (errors.title !== undefined) {
            return true
        } else if (
            !values.multiple_car_names_values_array &&
            !values.linkedToAll
        ) {
            return true
        } else if (
            values.multiple_car_names_values_array &&
            values.multiple_car_names_values_array[0] === undefined &&
            !values.linkedToAll
        ) {
            return true
        } else return false

        //  !values.title ||
        //  errors.title !== undefined ||
        //  (!values.multiple_car_names_values_array &&
        //      !values.linkedToAll &&
        //      values.multiple_car_names_values_array &&
        //      values.multiple_car_names_values_array[0] ===
        //          undefined)
        //      ? true
        //      : false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop
            $theme={theme}
            style={{ overflowY: 'auto', marginTop: '15vh', maxHeight: '85vh' }}
        >
            <ModalInnerContentWrapperDesktop>
                <>
                    <FormTitle>Create a Task</FormTitle>
                    <FieldTitle>
                        What needs to get done?
                        <span style={{ color: '#9f9f9f' }}>*</span>
                    </FieldTitle>
                    <Expander
                        height={
                            values.title.length > 1 && errors.title ? 'auto' : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {errors.title}
                            </Error>
                        </Faded>
                    </Expander>
                    <div style={{ paddingBottom: '20px' }} />
                    <InputField
                        theme={theme}
                        id="title"
                        name="title"
                        placeholder={'Task Title'}
                        helperText="[up to 70 characters]"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.title}
                        font_family="Lato"
                        type="text"
                        onEnterKeyPress={props.handleSubmit}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <div style={{ paddingTop: '40px' }} />
                    <FieldTitle>
                        What does it apply to?
                        <span style={{ color: colours[theme].text_default }}>
                            *
                        </span>
                    </FieldTitle>

                    <DescriptionHeader>
                        Search specific car(s) or select what applies:
                    </DescriptionHeader>
                    <div style={{ paddingTop: '16px' }} />
                    <MultipleSelectSearchBarCarInfoDesktop
                        item={{
                            id: 'linkedToCar',
                            text: 'your cars',
                            answer: undefined,

                            answers:
                                currentCarID && carsData[currentCarID]
                                    ? carsData[currentCarID].title
                                    : undefined,
                        }}
                        items={dropdownData}
                        handleSelectChange={(name: string) =>
                            setFieldValue(
                                'multiple_car_names_values_array',
                                name
                            )
                        }
                        isDisabled={values.linkedToAll ? true : false}
                        width="100%"
                    />
                    <div style={{ paddingTop: '30px' }} />
                    <ToggleWrapper>
                        <Row>
                            <SwitchButton
                                checked={
                                    values.linkedToAll ===
                                    OOtherTaskOptions.general_task.uid
                                        ? true
                                        : false
                                }
                                toggle={() => {
                                    if (
                                        values.linkedToAll !==
                                        OOtherTaskOptions.general_task.uid
                                    ) {
                                        setFieldValue(
                                            'linkedToAll',
                                            OOtherTaskOptions.general_task.uid
                                        )
                                    } else {
                                        setFieldValue('linkedToAll', undefined)
                                    }
                                }}
                                width={34}
                                circleSize={12}
                                height={18}
                            />

                            <ToggleText
                                isActive={
                                    values.linkedToAll ===
                                    OOtherTaskOptions.general_task.uid
                                        ? true
                                        : false
                                }
                            >
                                {OOtherTaskOptions.general_task.single}
                            </ToggleText>
                        </Row>
                        <div style={{ paddingRight: '40px' }} />
                        <Row>
                            <SwitchButton
                                checked={
                                    values.linkedToAll === 'all_cars'
                                        ? true
                                        : false
                                }
                                toggle={() => {
                                    if (values.linkedToAll !== 'all_cars') {
                                        setFieldValue('linkedToAll', 'all_cars')
                                    } else {
                                        setFieldValue('linkedToAll', undefined)
                                    }
                                }}
                                circleSize={12}
                                height={18}
                                width={34}
                            />

                            <ToggleText
                                isActive={
                                    values.linkedToAll === 'all_cars'
                                        ? true
                                        : false
                                }
                            >
                                Every car
                            </ToggleText>
                        </Row>
                    </ToggleWrapper>
                    <div style={{ paddingTop: '40px' }} />
                    <FieldTitle>
                        What is the due date?
                        <span style={{ color: colours[theme].text_default }}>
                            *
                        </span>
                    </FieldTitle>
                    <DescriptionHeader
                        style={
                            errors.title === 'over70' ? { color: 'red' } : {}
                        }
                    >
                        You will be notified via email ahead of the task's due
                        date.
                    </DescriptionHeader>
                    <div style={{ paddingBottom: '20px' }} />

                    <InputField
                        theme={theme}
                        id="selected_date"
                        name="selected_date"
                        placeholder={'Due date'}
                        customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                        tabindex={1}
                        aria-live="polite"
                        onChange={handleDueDateAnswerChange}
                        value={values.due_date}
                        font_family="Lato"
                        type="date"
                        onEnterKeyPress={props.handleSubmit}
                        min={dayjs().add(1, 'day').format('YYYY-MM-DD')}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                </>
                <LargePadding />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={props.handleSubmit}
                    secondText="create"
                    isDisabled={checkIfDisabled()}
                    dataCyIdUpdate="create_task_desktop"
                    dataCyIdCancel="cancel_task_creation_desktop"
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const CreateTaskFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        title: '',
        linkedToCar: [
            props.currentCarID && props.carsData[props.currentCarID]
                ? props.carsData[props.currentCarID].title
                : '',
        ],

        multiple_car_names_values_array:
            props.currentCarID && props.carsData[props.currentCarID]
                ? [props.carsData[props.currentCarID].title]
                : undefined,
        due_date: undefined,
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                // title: Yup.string().test(
                //     'len',
                //     'Display name must be of at least 2 characters and of a maximum of 70 characters.',
                //     (val: any) =>
                //         val &&
                //         val.toString().length > 1 &&
                //         val.toString().length < 71
                // ),
                title: Yup.string().max(70, 'over70').min(2, 'under2'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        let generateCarIds = (names: string[]) => {
            let car_ids: string[] = []

            let { carsData } = props

            let all_car_ids_list: string[] = Object.keys(carsData).filter(
                (id) => id !== 'new_car'
            )

            for (const carid of all_car_ids_list) {
                if (names.includes(carsData[carid].title)) {
                    car_ids.push(carid)
                }
            }
            return car_ids
        }

        setSubmitting(false)

        if (
            props.submitFunc &&
            (values.multiple_car_names_values_array || values.linkedToAll)
        ) {
            let obj = {
                title: values.title,
                car_id:
                    values.linkedToAll === 'all_cars'
                        ? props.garageCarsIds
                        : values.linkedToAll ===
                          OOtherTaskOptions.general_task.uid
                        ? undefined
                        : values.multiple_car_names_values_array
                        ? generateCarIds(values.multiple_car_names_values_array)
                        : undefined,
                garage_id: [props.garage_id],
                currentCarID: props.currentCarID
                    ? props.currentCarID
                    : undefined,
                due: values.due_date ? values.due_date : undefined,
            }

            props.submitFunc(obj)

            props.toggle()
        }
    },
})(InnerForm)

export default CreateTaskFormDesktop
