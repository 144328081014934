import { Fragment } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Faded from '../../templates/animated/faded'
import RiveAnimation from './riveWrapper'
import { useAppSelector } from '../../../redux/store/hooks'

const LoaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const LoaderStyled = styled.div`
    width: 200px;
    height: 200px;

    @media ${device.mobile} {
        width: 100px;
        height: 100px;
    }
`

interface Props {
    isLoadingUser?: boolean
    isLoading?: boolean
}

const AdjustableLoader = (props: Props) => {
    let isLoadingUser = useAppSelector((state) => state.user.loading)
    return (
        <Fragment>
            {(isLoadingUser === true || props.isLoading === true) && (
                <Faded>
                    <LoaderWrapper>
                        <LoaderStyled>
                            <RiveAnimation />
                        </LoaderStyled>
                    </LoaderWrapper>
                </Faded>
            )}
        </Fragment>
    )
}

export default AdjustableLoader
