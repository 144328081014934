import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    convertToCarState,
    convertToGalleryImagesState,
    convertToHighlightedFactsState,
    convertToTechnicalInformationState,
} from '../../../conversions/entities/conversionFromAPI'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { state_select_garages, state_select_user } from './getCarPublicShares'
import { getGarageByGarageId } from '../../garages/sagas/garage'
import { dropDownActions } from '../../../localdata/dropdownData/reducer'
import {
    IHighlightedFactsObject,
    ITechnicalInformationObject,
} from 'entityModels'
import { ICarPayload } from '../../../services/typedefinitions/apiPayloads'
import { IUser } from '../../../user/types'
import { IGalleryImagesObject } from '../../galleries/types'
import { IGarageObject } from '../../garages/types'
import { ICarsObject, ICustomErrorData, IReduxError } from '../types'
import { galleriesActions } from '../../galleries/reducer'
import { highlightedFactsActions } from '../../highlighted_facts/reducer'
import { technicalInformationActions } from '../../technical_information/reducer'
import { carActions } from '../reducer'

export function* setEmptyCarSaga(carid: string) {
    try {
        // resetting
        // yield put(dropDownActions.reset_make_model_temps())

        let caritem: ICarPayload = {
            uid: 'new_car',
        }

        let carObjects: ICarsObject = {}

        let galleryImagesObject: IGalleryImagesObject = {}

        let highlightedFactsObject: IHighlightedFactsObject = {}

        let technicalInformationObject: ITechnicalInformationObject = {}

        // convert to car state payload

        let car: ICarsObject = convertToCarState(caritem)

        car[carid].has_limited_garage_info = false

        let gallery_images_object: IGalleryImagesObject =
            convertToGalleryImagesState(caritem)

        let highlighted_facts_object: IHighlightedFactsObject =
            convertToHighlightedFactsState(caritem)

        let technical_information_object: ITechnicalInformationObject =
            convertToTechnicalInformationState(caritem)

        if (caritem.make_id) {
            yield put(dropDownActions.setTempMakeDisplay(caritem.make))
            yield put(dropDownActions.setTempMakeIDSuccess(caritem.make_id))
        }

        if (caritem.model_id) {
            yield put(dropDownActions.setTempModelDisplay(caritem.model))
            yield put(dropDownActions.setTempModelIDSuccess(caritem.model_id))
        }

        Object.assign(galleryImagesObject, gallery_images_object)

        Object.assign(carObjects, car)

        Object.assign(highlightedFactsObject, highlighted_facts_object)

        Object.assign(technicalInformationObject, technical_information_object)

        const results: ICarsObject = carObjects

        yield put(galleriesActions.setGalleryImagesSuccess(galleryImagesObject))
        yield put(
            highlightedFactsActions.setHighlightedFactsSuccess(
                highlightedFactsObject
            )
        )
        yield put(
            technicalInformationActions.setTechnicalInformationSuccess(
                technicalInformationObject
            )
        )

        yield put(carActions.getCarDataByIdSuccess(results))

        yield put(carActions.setCurrentCar(carid))

        let userLoggedIn: IUser = yield select(state_select_user)
        let garage_id =
            userLoggedIn &&
            userLoggedIn.owns_garage &&
            userLoggedIn.owns_garage.uid

        if (garage_id) {
            let garages: IGarageObject = yield select(state_select_garages)
            if (
                (garage_id && !garages[garage_id]) ||
                !garages[garage_id].public_share_owned
            ) {
                yield call(getGarageByGarageId, garage_id)
            }
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: setEmptyCarSaga,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else if (error.status === 403) {
            let customErrorData: ICustomErrorData = {
                custom_message: `You don't have permission to view this car`,
                custom_user_action_text: 'Return to garage',
                custom_redirect_path: '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'car',
                true
            )
            yield put(carActions.getCarDataByIdError(customError))
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't get your car`,
                custom_user_action_text: 'Return to garage',
                custom_redirect_path: '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'car',
                false
            )
            yield put(carActions.getCarDataByIdError(customError))
        }
    }
}

function* watcherSetEmptyCar() {
    while (true) {
        const { payload } = yield take(carActions.setEmptyCarRequest)

        yield call(setEmptyCarSaga, payload)
    }
}

const set_empty_car_sagas: any[] = [fork(watcherSetEmptyCar)]

export default set_empty_car_sagas
