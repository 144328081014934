import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import { IwriteMultipleTechnicalInformationPayloadReq } from 'entityModels'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import { Select } from '../../../../../atoms/select'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding40 = styled.div`
    padding-top: 40px;
`
const Padding5 = styled.div`
    padding-top: 5px;
`

interface Values {
    body_type: string
    // custom_body_type_desktop?: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: (p: IwriteMultipleTechnicalInformationPayloadReq) => any
    tech_info_id?: any
    optionsList: any
    carid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        item,
        optionsList,
        setFieldValue,
        handleSubmit,
        values,
        // errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let checkIfDisabled = () => {
        if (!values.body_type) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop style={{ marginTop: '20vh' }} $theme={theme}>
            <ModalTopBar $theme={theme}>Body type</ModalTopBar>
            <ModalInnerContentWrapperDesktop>
                <Padding5 />
                {optionsList && (
                    <Select
                        options={optionsList.map(
                            (option: { name: string }) => ({
                                value: option.name,
                                label: option.name,
                            })
                        )}
                        placeholder="Body Type"
                        defaultValue={item.name}
                        onChange={(name: string) =>
                            setFieldValue('body_type', name)
                        }
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                )}

                <Padding40 />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={handleSubmit}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const CarBodyTypeCustomDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        body_type: props.item.answer ? props.item.answer : undefined,
        // custom_body_type_desktop: props.item.custom ? props.item.custom : '',
    }),
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let p: IwriteMultipleTechnicalInformationPayloadReq = {
            car_id: props.carid,
            tech_info_id: props.tech_info_id,
            data: [
                {
                    id: 'body_type',
                    text: props.item.text,
                    answer: values.body_type,
                },
            ],
        }

        props.submitFunc(p)

        props.toggle()
    },
})(InnerForm)

export default CarBodyTypeCustomDesktop
