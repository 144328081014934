import React from 'react'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import Loader from '../../atoms/loader/loader'
import styled from 'styled-components'
import left_align_logo from '../../../public/assets/landingPage/left_align_logo.svg'
import ErrorCardDesktop from '../../molecules/errorCards/errorCardDesktop'
import { device } from '../../templates/displays/devices'
import ErrorCardMobile from '../../molecules/errorCards/errorCardMobile'
import { useAppSelector } from '../../../redux/store/hooks'

const LeftAlignLogoWrapper = styled.div`
    height: 50px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;

    @media ${device.smallest_laptops} {
        height: 48px;
    }

    @media ${device.mobile} {
        height: 40px;
    }
`

const Logo = styled.img`
    width: auto;
    height: 100%;
`

const SubWrapper = styled.div`
    padding: 80px;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${device.mobile_and_ipad} {
        padding: 80px 0px;
    }
    @media ${device.mobile} {
        padding: 50px 0px;
    }
`

const FlexOneSubWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
`

interface Props {
    error_is?: string | undefined
    onClick: any
}

const ShowroomErrorManager: React.FC<Props> = (props: Props) => {
    const { error_is, onClick } = props

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    let isUserLoading = useAppSelector((state) => state.user.loading)

    return (
        <React.Fragment>
            {<Loader isLoading={isUserLoading} />}

            <React.Fragment>
                <DesktopDisplayOnly>
                    <SubWrapper>
                        <LeftAlignLogoWrapper>
                            <Logo src={left_align_logo} />
                        </LeftAlignLogoWrapper>
                        <FlexOneSubWrapper>
                            {error_is === 'invalid_access' && (
                                <ErrorCardDesktop
                                    title="Invalid access"
                                    text={`You do not have permissions to access details of this page.`}
                                    main_btn_text={
                                        userLoggedIn && userLoggedIn.id
                                            ? 'Return to your garage'
                                            : 'Return to showroom'
                                    }
                                    onClick={onClick}
                                />
                            )}
                            {error_is === 'entry_does_not_exist' && (
                                <ErrorCardDesktop
                                    title="Invalid access"
                                    text={`Looks like the showroom entry was removed by its owner.`}
                                    main_btn_text={
                                        userLoggedIn && userLoggedIn.id
                                            ? 'Return to your garage'
                                            : 'Return to showroom'
                                    }
                                    onClick={onClick}
                                />
                            )}
                            {!error_is && (
                                <ErrorCardDesktop
                                    title="Something went wrong"
                                    text="Please try again later."
                                    main_btn_text={
                                        userLoggedIn && userLoggedIn.id
                                            ? 'Return to your garage'
                                            : 'Return to showroom'
                                    }
                                    onClick={onClick}
                                />
                            )}
                        </FlexOneSubWrapper>
                    </SubWrapper>
                </DesktopDisplayOnly>

                <IpadAndMobileDisplay>
                    <SubWrapper>
                        <LeftAlignLogoWrapper>
                            <Logo src={left_align_logo} />
                        </LeftAlignLogoWrapper>
                        <FlexOneSubWrapper>
                            {error_is === 'invalid_access' && (
                                <ErrorCardMobile
                                    title="Invalid access"
                                    text={`You do not have permissions to access details of this page.`}
                                    main_btn_text={
                                        userLoggedIn && userLoggedIn.id
                                            ? 'Return to your garage'
                                            : 'Return to showroom'
                                    }
                                    onClick={onClick}
                                />
                            )}

                            {error_is === 'entry_does_not_exist' && (
                                <ErrorCardMobile
                                    title="Invalid access"
                                    text={`Looks like the showroom entry was removed by its owner.`}
                                    main_btn_text={
                                        userLoggedIn && userLoggedIn.id
                                            ? 'Return to your garage'
                                            : 'Return to showroom'
                                    }
                                    onClick={onClick}
                                />
                            )}

                            {!error_is && (
                                <ErrorCardMobile
                                    title="Something went wrong"
                                    text="Please try again later."
                                    main_btn_text={
                                        userLoggedIn && userLoggedIn.id
                                            ? 'Return to your garage'
                                            : 'Return to showroom'
                                    }
                                    onClick={onClick}
                                />
                            )}
                        </FlexOneSubWrapper>
                    </SubWrapper>
                </IpadAndMobileDisplay>
            </React.Fragment>
        </React.Fragment>
    )
}

export default ShowroomErrorManager
