import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'

import ButtonsStandardBottomRowMobile from '../../../buttons/buttonsStandardBottomRowMobile'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import { Select } from '../../../../../atoms/select'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding32 = styled.div`
    padding-top: 32px;
`
const Padding11 = styled.div`
    padding-top: 11px;
`

interface Values {
    single_item_edited_value_selectSearchMobile: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc?: any
    tech_info_id?: any
    optionsList: any
    carid: string
    placeholder?: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        item,
        optionsList,
        setFieldValue,
        handleSubmit,
        placeholder,
    } = props

    let checkIfDisabled = () => {
        if (!props.values.single_item_edited_value_selectSearchMobile) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>
                <span>{item.text}</span>
            </ModalTopBar>
            <ModalInnerContentWrapperMobile>
                <Padding11 />
                <Select
                    options={optionsList.map((option: { name: string }) => ({
                        value: option.name,
                        label: option.name,
                    }))}
                    defaultValue={item?.answer}
                    placeholder={item.text}
                    helperText={placeholder}
                    onChange={(name) =>
                        setFieldValue(
                            'single_item_edited_value_selectSearchMobile',
                            name
                        )
                    }
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <Padding32 />
                <ButtonsStandardBottomRowMobile
                    cancel={toggle}
                    submit={handleSubmit}
                    confirmationtext="save"
                    disabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const CarTechInfoEditFormSelectSearchMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_selectSearchMobile: props.item.answer
            ? props.item.answer
            : undefined,
    }),
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
        if (props.submitFunc) {
            let p: IwriteTechnicalInformationPayloadReq = {
                car_id: props.carid,
                tech_info_id: props.tech_info_id,
                data: {
                    id: props.item.id,
                    text: props.item.text,
                    answer: values.single_item_edited_value_selectSearchMobile,
                },
            }

            props.submitFunc(p)
        }
        props.toggle()
    },
})(InnerForm)

export default CarTechInfoEditFormSelectSearchMobile
