import { Formik, FormikHelpers, FormikProps } from 'formik'
import InputField from '../../atoms/Inputfield/inputField'
import Expander from '../../atoms/expander/expander'
import Faded from '../../templates/animated/faded'
import StyledFormError from '../../templates/styledcomponents/styledformerrormessage'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import {
    FormWrapperVerified,
    SubWrapperVerified,
    DividerVerified,
    PaddingVerified,
} from './style'
import { FormikVerifiedResetPasswordSchema } from './validationSchema'
import useThemes from '../../../providers/theme/hooks'
import { useAppDispatch } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'

type OtherProps = {
    setSubmit: () => void
    uid: string
}

interface Values {
    password: string
    passwordconfirm: string
}

const VerifiedFormikResetPassword = (props: OtherProps) => {
    const { theme } = useThemes()

    let dispatch = useAppDispatch()
    return (
        <Formik
            initialValues={{ password: '', passwordconfirm: '' }}
            onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
            ) => {
                let p = {
                    passwordGiven: values.password,
                    uid: props.uid,
                }
                dispatch(usersActions.verifiedResetPasswordRequest(p))
                props.setSubmit()
                setSubmitting(false)
            }}
            validationSchema={FormikVerifiedResetPasswordSchema}
        >
            {(formikprops: FormikProps<Values>) => (
                <FormWrapperVerified translate="yes">
                    <SubWrapperVerified>
                        <InputField
                            theme={theme}
                            type="password"
                            name="password"
                            placeholder="password"
                            formikprops={formikprops}
                            value={formikprops.values.password}
                            tabindex={1}
                            width={'100%'}
                        />
                        <Expander
                            height={formikprops.errors.password ? 'auto' : 0}
                        >
                            <Faded>
                                <StyledFormError>
                                    {formikprops.errors.password}
                                </StyledFormError>
                            </Faded>
                        </Expander>

                        <DividerVerified />

                        <InputField
                            theme={theme}
                            type="password"
                            name="passwordconfirm"
                            placeholder="confirm password"
                            formikprops={formikprops}
                            value={formikprops.values.passwordconfirm}
                            tabindex={2}
                            width={'100%'}
                        />
                        <Expander
                            height={
                                formikprops.errors.passwordconfirm ? 'auto' : 0
                            }
                        >
                            <Faded>
                                <StyledFormError>
                                    Passwords don't match.
                                </StyledFormError>
                            </Faded>
                        </Expander>
                        <PaddingVerified />
                        <ButtonAtom
                            theme="primary"
                            disabled={
                                formikprops.isSubmitting ||
                                !!(
                                    formikprops.errors.password &&
                                    formikprops.touched.password
                                )
                            }
                            type="submit"
                        >
                            Submit
                        </ButtonAtom>
                    </SubWrapperVerified>
                </FormWrapperVerified>
            )}
        </Formik>
    )
}

export default VerifiedFormikResetPassword
