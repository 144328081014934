import styled from 'styled-components'
import InfoIcon from '../icons/infoIcon'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyleProps = {
    $variant?: 'default' | 'info' | 'error'
    $theme: ITheme
}

const Wrap = styled.div<IStyleProps>`
    background-color: ${(props) =>
        props.$variant === 'error'
            ? colours[props.$theme].error_underlay
            : props.$variant === 'info'
            ? colours[props.$theme].primary_underlay
            : colours[props.$theme].background_neutral_subtle};
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 32px;
    border-bottom: 1px solid ${(props) => colours[props.$theme].border_muted};
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
`

const Row = styled.div<IStyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
`

const IconWrapper = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Title = styled.div<IStyleProps>`
    color: ${(props) => colours[props.$theme].text_strong};
    /* Desktop/Heading/H300 */
    font-size: 16px;
    font-family: Lato-bold;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const Text = styled.div<IStyleProps>`
    color: ${(props) =>
        props.$variant === 'error'
            ? colours[props.$theme].error
            : colours[props.$theme].text_default};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato';
    @media ${device.mobile_and_ipad} {
        color: ${(props) => colours[props.$theme].text_default};
        /* Desktop/Text/T200 Regular */
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.001px;
    }
`
type Props = {
    title: string
    text?: string
    variant?: 'default' | 'info' | 'error'
    isMobile?: boolean
    noInfoIcon?: boolean
}

const FullWidthInfoBoxWithTitle = (props: Props) => {
    const { theme } = useThemes()
    return (
        <Wrap $variant={props.variant} $theme={theme}>
            <Row $theme={theme}>
                {props.noInfoIcon ? null : (
                    <IconWrapper>
                        <InfoIcon
                            size="20"
                            color={
                                props.variant === 'error'
                                    ? colours[theme].error
                                    : props.variant === 'info'
                                    ? colours[theme].primary
                                    : undefined
                            }
                        />
                    </IconWrapper>
                )}

                {props.noInfoIcon ? null : <div style={{ paddingLeft: 8 }} />}
                <Title $theme={theme}>{props.title}</Title>
            </Row>
            <Text $theme={theme}>{props.text}</Text>
        </Wrap>
    )
}

export default FullWidthInfoBoxWithTitle
