import React, { useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import Loader from '../../atoms/loader/loader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import EntryGalleryPageTopHeaderDesktop from '../../molecules/entryPageTopHeader/entryGalleryPageTopHeaderDesktop'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import WrapperImageFormsMobile from '../../organisms/editForms/imageFormWrapper/wrapperImageFormMobile'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import EntryGalleryManagerDesktop from '../../organisms/entryGalleryManager/entryGalleryManagerDesktop'
import EntryGalleryManagerMobile from '../../organisms/entryGalleryManager/entryGalleryManagerMobile'
import PortalInvisibleWrapper from '../../templates/displays/pageWrappers/portalInvisibleWrapper'
import SingleEntryGalleryImage from './singleEntryGalleryImage'
import WrapperImageFormsDesktop from '../../organisms/editForms/imageFormWrapper/wrapperImageFormsDesktop'
import ReturnNav from '../../atoms/header/returnNav'
import EntryOptionsMenuBar from '../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import {
    EntryOptionsMenuWrapper,
    EntryTopSectionWrapper,
} from './carTimelineSingleEntry'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { timelineActions } from '../../../redux/timeline/reducer'
import { IisOpen } from '../../../redux/menus/types'

const GalleryDesktopContainer = styled.div`
    width: calc(100% - 296px);
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding-bottom: 48px;
    min-height: 90dvh;
`

const SingleEntryGallery: React.FC = () => {
    const dispatch = useAppDispatch()
    const { entryid, carid } = useParams<{ entryid: string; carid: string }>()
    const location = useLocation()

    const activeEntry = useAppSelector((state) => state.timeline.activeEntry)
    const isActiveEntryLoading = useAppSelector(
        (state) => state.timeline.loading
    )
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const isUserLoading = useAppSelector((state) => state.user.loading)
    const isAddGalleryImageToEntryFormOpen = useAppSelector(
        (state) =>
            state.editForms.formsData.isAddGalleryImageToEntryFormOpen.isOpen
    )

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/car/${carid}/history-file/entry?entryid=${entryid}`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            dispatch(carActions.setCurrentCar(`${carid}`))
            if (entryid) {
                dispatch(
                    timelineActions.getTimelineItemByIDRequest({
                        entryUID: entryid,
                        car_id: `${carid}`,
                    })
                )
            }
        }
    }, [userLoggedIn, carid, entryid])

    const toggleAddGalleryImageToEntryForm = (p: IisOpen) => {
        dispatch(editFormsActions.toggleAddGalleryImageToEntryForm(p))
    }

    const params = new URLSearchParams(location.search)
    const image_id = params.get('id')

    return (
        <>
            <Loader isLoading={isActiveEntryLoading || isUserLoading} />
            <Faded>
                {activeEntry && (
                    <>
                        <DesktopDisplayOnly>
                            <FullPagePageWrapper>
                                <Faded>
                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <EntryTopSectionWrapper>
                                            <div
                                                style={{
                                                    maxWidth: 1200,
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                }}
                                            >
                                                <EntryGalleryPageTopHeaderDesktop
                                                    item={activeEntry}
                                                    carID={`${carid}`}
                                                    uploadImage={() =>
                                                        toggleAddGalleryImageToEntryForm(
                                                            { isOpen: true }
                                                        )
                                                    }
                                                />
                                            </div>
                                        </EntryTopSectionWrapper>
                                    </div>
                                    <div style={{ paddingTop: 32 }} />

                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '0px 40px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: 1200,
                                                position: 'relative',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <EntryOptionsMenuWrapper>
                                                <EntryOptionsMenuBar
                                                    carid={`${carid}`}
                                                    activeEntry={activeEntry}
                                                />
                                            </EntryOptionsMenuWrapper>
                                            <GalleryDesktopContainer>
                                                <EntryGalleryManagerDesktop
                                                    ids_list={
                                                        activeEntry.gallery_image_ids
                                                    }
                                                    openImgForm={() =>
                                                        toggleAddGalleryImageToEntryForm(
                                                            { isOpen: true }
                                                        )
                                                    }
                                                />
                                            </GalleryDesktopContainer>
                                        </div>
                                    </div>
                                    {isAddGalleryImageToEntryFormOpen && (
                                        <Faded>
                                            <ModalDisplayAbsolute
                                                isOpen={
                                                    isAddGalleryImageToEntryFormOpen
                                                }
                                                toggle={() => {}}
                                            >
                                                <WrapperImageFormsDesktop
                                                    close={() =>
                                                        toggleAddGalleryImageToEntryForm(
                                                            { isOpen: false }
                                                        )
                                                    }
                                                    carid={`${carid}`}
                                                    entryid={activeEntry.id}
                                                />
                                            </ModalDisplayAbsolute>
                                        </Faded>
                                    )}
                                </Faded>
                            </FullPagePageWrapper>
                        </DesktopDisplayOnly>

                        {!image_id && (
                            <IpadAndMobileDisplay>
                                <CenteredPageWrapper
                                    fullwidth
                                    removePaddingsOnAllDevices
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            paddingTop: 50,
                                        }}
                                    >
                                        <Link
                                            to={{
                                                pathname: image_id
                                                    ? `/car/${carid}/history-file/${entryid}/gallery`
                                                    : `/car/${carid}/history-file/entry`,
                                                search: image_id
                                                    ? ''
                                                    : `?entryid=${entryid}`,
                                            }}
                                            state={{
                                                prevPath: location.pathname,
                                            }}
                                        >
                                            <ReturnNav />
                                        </Link>
                                    </div>
                                    <EntryGalleryManagerMobile
                                        ids_list={activeEntry.gallery_image_ids}
                                        openImgForm={() =>
                                            toggleAddGalleryImageToEntryForm({
                                                isOpen: true,
                                            })
                                        }
                                    />
                                    {isAddGalleryImageToEntryFormOpen && (
                                        <Faded>
                                            <ModalDisplay
                                                isOpen={
                                                    isAddGalleryImageToEntryFormOpen
                                                }
                                                toggle={() =>
                                                    toggleAddGalleryImageToEntryForm(
                                                        { isOpen: false }
                                                    )
                                                }
                                            >
                                                <WrapperImageFormsMobile
                                                    close={() =>
                                                        toggleAddGalleryImageToEntryForm(
                                                            { isOpen: false }
                                                        )
                                                    }
                                                    carid={`${carid}`}
                                                    entryid={activeEntry.id}
                                                />
                                            </ModalDisplay>
                                        </Faded>
                                    )}
                                </CenteredPageWrapper>
                            </IpadAndMobileDisplay>
                        )}
                    </>
                )}
            </Faded>

            {image_id && (
                <PortalInvisibleWrapper
                // onClick={() => navigate(location.pathname)}
                >
                    <SingleEntryGalleryImage />
                </PortalInvisibleWrapper>
            )}
        </>
    )
}

export default SingleEntryGallery
