import { Fragment } from 'react'
import styled from 'styled-components'
import Faded from '../../templates/animated/faded'
import { device } from '../../templates/displays/devices'
import RiveAnimation from './riveWrapper'
import { useAppSelector } from '../../../redux/store/hooks'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0px;
    left: 0px;
    right: 0;
    background-color: var(--off-bg-color, #fafafa);
    z-index: 10;
`
const LoaderWrapper = styled.div`
    width: 300px;
    height: 300px;

    @media ${device.mobile} {
        width: 200px;
        height: 200px;
    }
`

interface Props {
    isLoading?: boolean
}

const Loader = (props: Props) => {
    let isLoadingUser = useAppSelector((state) => state.user.loading)
    return (
        <Fragment>
            {isLoadingUser === true || props.isLoading === true ? (
                <Faded>
                    <Wrapper>
                        <LoaderWrapper>
                            <RiveAnimation />
                        </LoaderWrapper>
                    </Wrapper>
                </Faded>
            ) : null}
        </Fragment>
    )
}

export default Loader
