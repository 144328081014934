import * as React from 'react'
import styled from 'styled-components'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Icon from '../icons'
import { motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = { $theme: ITheme }

const ListItem = styled.li<IStyle>`
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 24px 16px;
    list-style-type: none;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const ListContentRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
`

const Handler = styled.div`
    width: 26px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
`

const TextWrapper = styled.div`
    position: relative;
    color: transparent;
    padding-top: 4px;
    padding-left: 16px;
`

const Text = styled.textarea<IStyle>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-family: Lato;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    resize: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 5e-5em;
    color: ${(props) => colours[props.$theme].text_default};
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    vertical-align: center;

    @media ${device.ipad} {
        font-size: 14px;
        line-height: 20px;
    }

    :focus {
        outline: 0;
    }
    ::placeholder {
        color: ${(props) => colours[props.$theme].text_muted};
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${(props) => colours[props.$theme].background_default};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${(props) => colours[props.$theme].border_muted};
    }
    /* width */
    ::-webkit-scrollbar {
        width: 4px;
        border-radius: 20px;
    }
`

const DragHandle = () => (
    <motion.div whileTap={{ scale: 1.1 }}>
        <Handler>
            <Icon icon="handler" />
        </Handler>
    </motion.div>
)

const SortableItem = ({
    item,
    formikprops,
}: {
    item: EnumerableObject
    formikprops: {
        handleChange: (e: React.ChangeEvent<any>) => void
        values: {
            [key: string]: string
        }
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined
        ) => void
    }
}) => {
    const { theme } = useThemes()
    const {
        attributes,
        listeners,
        setDroppableNodeRef,
        setDraggableNodeRef,
        transform,
        transition,
    } = useSortable({ id: item.id })

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    }

    return (
        <ListItem ref={setDroppableNodeRef} style={style} $theme={theme}>
            <ListContentRow>
                <div {...attributes} {...listeners}>
                    <motion.div
                        whileTap={{ scale: 1.2 }}
                        ref={setDraggableNodeRef}
                    >
                        <DragHandle />
                    </motion.div>
                </div>
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <TextWrapper>
                        {formikprops.values[item.id]
                            ? formikprops.values[item.id]
                            : item.order === 1
                            ? 'i.e. This is one of only 400 Heritage Editions produced in both long and short wheelbase form to mark the ending of original Defender production.'
                            : item.order === 2
                            ? 'i.e. Finished in the beautifull N2 Orange colour'
                            : item.order === 3
                            ? 'i.e. Features many nods to models past including the Almond cloth upholstery, painted steel wheels and HUE 166 badging, this being the registration number of the first Land Rover prototype.'
                            : ''}

                        <Text
                            $theme={theme}
                            id={item.id}
                            name={item.id}
                            placeholder={
                                item.order === 1
                                    ? 'i.e. This is one of only 400 Heritage Editions produced in both long and short wheelbase form to mark the ending of original Defender production.'
                                    : item.order === 2
                                    ? 'i.e. Finished in the beautifull N2 Orange colour'
                                    : item.order === 3
                                    ? 'i.e. Features many nods to models past including the Almond cloth upholstery, painted steel wheels and HUE 166 badging, this being the registration number of the first Land Rover prototype.'
                                    : ''
                            }
                            onChange={formikprops.handleChange}
                            value={
                                formikprops.values[item.id] !== undefined
                                    ? formikprops.values[item.id]
                                    : ''
                            }
                            maxLength={200}
                        />
                    </TextWrapper>
                </div>
            </ListContentRow>
        </ListItem>
    )
}

type EnumerableObject = {
    id: string
    text: string
    order: number
    // formikprops: any
}

type Props = {
    item: EnumerableObject
    index: number
    formikprops: any
    isJourney?: boolean
}

const DraggableItemEditableJourneyDesktop: React.FC<Props> = ({
    item,
    formikprops,
}) => {
    return <SortableItem item={item} formikprops={formikprops} />
}

export default DraggableItemEditableJourneyDesktop
