import * as React from 'react'
import styled from 'styled-components'
import { device } from '../displays/devices'

type IStyle = {
    $isExternalCar?: boolean
}

interface Props {
    children?: React.ReactNode
    isExternalCar?: boolean
}

const FixedBottomContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const HorizontalLineV2 = styled.div`
    width: 95%;
    margin: 0 auto;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    @media ${device.ipad} {
        margin-bottom: 10px;
    }
`

const BottomContainerWrapper = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    height: 100px;
    max-height: 130px;
    @media screen and (min-width: 1100px) and (max-width: 1200px) {
        padding-left: 60px;
        padding-right: 70px;
    }
    @media ${device.ipad} {
        bottom: ${(props) => (props.$isExternalCar ? '50px' : '0px')};
        height: 150px;
        padding-bottom: 70px;
        width: 87%;
        align-items: flex-start;
        position: fixed;
        border-bottom: 1px solid var(--border-muted, #e5e5e5);
        background-color: var(--bg-color, #fff);
        z-index: 1;
    }
`

const BottomFixedContainer: React.FunctionComponent<Props> = (props) => (
    <BottomContainerWrapper $isExternalCar={props.isExternalCar}>
        <HorizontalLineV2 />
        <FixedBottomContainer>{props.children}</FixedBottomContainer>
    </BottomContainerWrapper>
)

export default BottomFixedContainer
