import { useEffect, useState } from 'react'
import styled from 'styled-components'
import BottomBarBtnsStep from '../../atoms/Button/bottomBarBtnsStep'
import TextArea from '../../atoms/textarea/textarea'
import { device } from '../../templates/displays/devices'

export type ICarEnquiry_CustomMessage_FormManager_props = {
    handleCustomMessageChange: (value: string | undefined) => void
    setIsEditorOpen: (p: boolean) => void
    initialMessage?: string
}

const TextAreaWidthOneLiner = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    font-size: 12px !important;
`

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-top: 100px;
    padding-bottom: 110px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fafafa;
    @media ${device.ipad} {
        padding-bottom: 40px;
    }
`

const TopBanner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 72px;
    background-color: #ffffff;
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
`

const TxtTopBanner = styled.div`
    font-size: 18px;
    color: #1a1a1a;
    font-family: Lato;
`

const CarEnquiryCustomMessageFormMobile = (
    props: ICarEnquiry_CustomMessage_FormManager_props
) => {
    let {
        handleCustomMessageChange,

        initialMessage,
        setIsEditorOpen,
    } = props

    const [messageDraft, setMessageDraft] = useState<string>('')

    useEffect(() => {
        setMessageDraft(initialMessage ? initialMessage : '')
    }, [initialMessage])

    return (
        <div>
            <TopBanner>
                <TxtTopBanner>Write custom message</TxtTopBanner>
            </TopBanner>
            <Wrapper>
                <TextAreaWidthOneLiner>
                    <TextArea
                        value={messageDraft}
                        handleChange={(e: any) => {
                            setMessageDraft(e.target.value)
                        }}
                        name=""
                        placeholder="Write here..."
                    />
                </TextAreaWidthOneLiner>

                <BottomBarBtnsStep
                    cancel={() => {
                        setMessageDraft('')
                        setIsEditorOpen(false)
                    }}
                    hasClear={true}
                    onClear={() => {
                        setMessageDraft('')
                    }}
                    submit={() => {
                        handleCustomMessageChange(messageDraft)
                        setIsEditorOpen(false)
                    }}
                    confirmationtext="save"
                    disabled={false}
                />
            </Wrapper>
        </div>
    )
}

export default CarEnquiryCustomMessageFormMobile
