import * as React from 'react'
import styled from 'styled-components'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyleProps = {
    $theme: ITheme
    hasImage?: boolean
}

const UserImgWrapper = styled.div<IStyleProps>`
    border-radius: 50%;
    border: ${(props) =>
        props.hasImage
            ? `solid 0px ${colours[props.$theme].primary}`
            : `1px solid ${colours[props.$theme].border_muted}`};
`

const UserImg = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
`

type Props = {
    size?: string
    url?: any
}

const CarUserCircle: React.FC<Props> = ({ size, url }) => {
    const [src, setSrc] = React.useState<any>(undefined)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        const initialSrc =
            url !== undefined && url !== false && url !== null && url.length > 1
                ? url
                : blank_user

        if (initialSrc) {
            setSrc(initialSrc)
        }
    }, [url])

    let { theme } = useThemes()

    return (
        <Wrapper>
            <UserImgWrapper
                $theme={theme}
                hasImage={src !== undefined}
                style={{
                    width: size ?? 64,
                    height: size ?? 64,
                }}
            >
                <UserImg
                    src={src}
                    onLoad={() => {
                        setIsLoading(false)
                    }}
                />
                <SkeletonAbsolute isthingloading={isLoading} isRound={true} />
            </UserImgWrapper>
        </Wrapper>
    )
}

export default CarUserCircle
