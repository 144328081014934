import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { ReminderAllSelectTypes } from '../../localdata/remindersLocalData'

dayjs.extend(utc)
dayjs.extend(timezone)

export const fromIsoStringToDDMMYYY = (isostring: string): string => {
    let conv: string = dayjs(isostring).format('DD MMM YYYY')

    if (conv) {
        return conv
    } else return ''
}

export const fromIsoStringToDDMMYYY_time = (isostring: string): string => {
    let conv: string = dayjs(isostring).format('DD MMM YYYY HH:mm')

    if (conv) {
        return conv
    } else return ''
}

export const fromIsoStringToDDMMYYYInTimezone = (
    isostring: string,
    timezone: string
): string => {
    let conv: string = dayjs(isostring)
        .tz(timezone.trim().split(' ').join('_'))
        .format('DD MMM YYYY')
    if (conv) {
        return conv
    } else return ''
}

export const DatePickerConverterFormat = (date: string): string => {
    let md = dayjs(date)
    let da = md && md.format('YYYY-MM-DD')

    if (md && da) {
        return da
    } else return ''
}

export const shareCardConverterFormat = (date: string): string => {
    let md = dayjs(date)
    let da = md && md.format('DD/MM/YYYY')

    if (md && da) {
        return da
    } else return ''
}

export const fromISOStringToReminderDate = (isostring: string): string => {
    let conv: string = dayjs(isostring).format('DD MMM YYYY')

    if (conv) {
        return conv
    } else return ''
}

export type IConvertReminderDateToBEPayload = {
    type: ReminderAllSelectTypes | string
    value: string
    nvalue?: number | undefined
    dueDate: string
}

export const fromReminderDateToBackEndFormat = (
    p: IConvertReminderDateToBEPayload
): string => {
    if (!p.dueDate || !dayjs(p.dueDate).isValid()) {
        console.error('Invalid or missing due date:', p.dueDate)
        return dayjs().toISOString()
    }

    const ofset = dayjs().utcOffset() * 60000

    let dayjs_dueDate = dayjs(p.dueDate).startOf('day')

    const nValueP = p.nvalue !== undefined ? p.nvalue : 0

    try {
        switch (p.type) {
            case 'custom_date_copy':
                return p.value
                    ? dayjs(p.value).toISOString()
                    : dayjs_dueDate.toISOString()

            case 'tomorrow': {
                return dayjs()
                    .add(1, 'day')
                    .startOf('day')
                    .add(ofset)
                    .toISOString()
            }

            case 'on_due_day':
                return dayjs_dueDate.toISOString()

            case 'days_before':
                return dayjs_dueDate
                    .subtract(nValueP, 'day')
                    .add(ofset)
                    .toISOString()

            case 'weeks_before':
                return dayjs_dueDate
                    .subtract(nValueP, 'week')
                    .add(ofset)
                    .toISOString()

            case 'months_before':
                return dayjs_dueDate
                    .subtract(nValueP, 'month')
                    .add(ofset)
                    .toISOString()

            default:
                console.error('Unknown reminder type:', p.type)
                return dayjs_dueDate.add(ofset).toISOString()
        }
    } catch (error) {
        console.error('Error in date conversion:', error)
        return dayjs().toISOString()
    }
}
