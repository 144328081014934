import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import { AbsoluteGradient } from '../../../../templates/styledcomponents/journeyStyles'
import useThemes from '../../../../../providers/theme/hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../../providers/theme/colours'
import StandardCtaBtn from '../../../../atoms/Button/standardCtaBtn'
import Faded from '../../../../templates/animated/faded'
import EnterIcon from '../../../../atoms/icons/components/enterIcon'
import { device } from '../../../../templates/displays/devices'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import JourneyPageWrapperMobile from '../../../../templates/wrappers/journeyPageWrapperMobile'
import JourneyWrapperLeftSideDesktop from '../../../../templates/wrappers/journeyPageWrapperLeftDesktop'
import { UserProfileJourneyUserCardMobile } from '../card/cardMobile'
import { UserProfileJourneyUserCardDesktop } from '../card/cardDesktop'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { InfoBoxAtom } from '../../../../atoms/box/infoBox'
import { message } from 'antd'
import { IUSerGeneralInfoUpdatePayload } from '../../../../../redux/user/types'
import { usersActions } from '../../../../../redux/user/reducer'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`

const RightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    @media ${device.smallest_laptops} {
        padding: 24px;
    }
`

const ColCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const EnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

const RowContent = styled.div`
    display: flex;
    align-items: center;
`

const IconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

type Props2 = {
    displayName: string | undefined
    givenName: string | undefined
    familyName: string | undefined
    setDisplayName: any
    setGivenName: any
    setFamilyName: any
    isMobile?: boolean
    setHasChange: (p: boolean) => any
    hasChange?: boolean
}

const RenderForms = (props2: Props2) => {
    return (
        <>
            <InputFieldNoFormikAnimated
                name={'Display name'}
                value={props2?.displayName}
                placeholder={''}
                onChange={(e: any) => {
                    if (!props2.hasChange) {
                        props2.setHasChange(true)
                    }
                    props2.setDisplayName(e.target.value ?? undefined)
                }}
            />

            <div style={{ paddingTop: '24px' }} />

            <InfoBoxAtom>
                Whilst Custodian is 100% private by default, your Display Name
                is used when interacting with other users on the Custodian
                platform. Use a pseudonym or your real name, think of it like
                your Instagram or X handle.
            </InfoBoxAtom>

            <div style={{ paddingTop: '40px' }} />

            <InputFieldNoFormikAnimated
                name={'First name'}
                value={props2?.givenName}
                placeholder={''}
                onChange={(e: any) => {
                    if (!props2.hasChange) {
                        props2.setHasChange(true)
                    }
                    props2.setGivenName(e.target.value ?? undefined)
                }}
            />
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name={'Last name'}
                value={props2?.familyName}
                placeholder={''}
                onChange={(e: any) => {
                    if (!props2.hasChange) {
                        props2.setHasChange(true)
                    }
                    props2.setFamilyName(e.target.value ?? undefined)
                }}
            />
        </>
    )
}

export default function UserProfileJourneyNames({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const [hasChange, setHasChange] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const [displayName, setDisplayName] = useState<string | undefined>(
        undefined
    )
    const [givenName, setGivenName] = useState<string | undefined>(undefined)
    const [familyName, setFamilyName] = useState<string | undefined>(undefined)

    let userError = useAppSelector((state) => state.user.error)

    const onSubmit = () => {
        if (userLoggedIn?.id) {
            if (
                hasChange &&
                displayName &&
                displayName?.trim().length > 0 &&
                familyName &&
                familyName?.trim().length > 0 &&
                givenName &&
                givenName?.trim().length > 0
            ) {
                let data: IUSerGeneralInfoUpdatePayload = {
                    id: userLoggedIn?.id,
                    data: {
                        given_name: givenName,
                        family_name: familyName,
                        display_name: displayName,
                    },
                }
                dispatch(usersActions.updateUserGeneralInfoRequest(data))

                setTimeout(() => {
                    if (!userError) {
                        goToNextStep()
                    }
                }, 150)
            } else if (
                (hasChange &&
                    displayName &&
                    displayName?.trim().length === 0) ||
                (familyName && familyName?.trim().length === 0) ||
                (givenName && givenName?.trim().length === 0)
            ) {
                message.error({
                    content: 'Please fill in all the fields',
                })
            } else {
                goToNextStep()
            }
        }
    }

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [step, displayName, familyName, givenName, userError])

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    useEffect(() => {
        if (userLoggedIn) {
            setDisplayName(userLoggedIn?.display_name)
            setGivenName(userLoggedIn?.given_name)
            setFamilyName(userLoggedIn?.family_name)
        }
    }, [userLoggedIn])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <Wrapper>
                        <JourneyWrapperLeftSideDesktop
                            title={'Set a Display Name'}
                            sectionID="your_profile"
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={() => {
                                goToPrevStep()
                            }}
                            maxTitleWith={'286px'}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <RenderForms
                                    displayName={displayName}
                                    setDisplayName={setDisplayName}
                                    givenName={givenName}
                                    setGivenName={setGivenName}
                                    familyName={familyName}
                                    setFamilyName={setFamilyName}
                                    setHasChange={setHasChange}
                                    hasChange={hasChange}
                                />
                            </div>

                            <RowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={onSubmit}
                                    bg={
                                        journey_colours[theme].section[
                                            'your_profile'
                                        ].primary_500
                                    }
                                    isDisabled={!userLoggedIn}
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'your_profile'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <RowContent style={{ gap: 8 }}>
                                    <IconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </IconWrapper>{' '}
                                    <EnterTxt $theme={theme}>
                                        Or press Enter
                                    </EnterTxt>
                                </RowContent>
                            </RowContent>
                        </JourneyWrapperLeftSideDesktop>
                        <RightSide
                            style={{
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <UserProfileJourneyUserCardDesktop
                                userWithFormInfo={
                                    userLoggedIn
                                        ? {
                                              ...userLoggedIn,
                                              display_name: displayName,
                                              family_name: familyName,
                                              given_name: givenName,
                                          }
                                        : undefined
                                }
                            />
                        </RightSide>
                    </Wrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyPageWrapperMobile
                    allowScroll={true}
                    title={'Set a Display Name'}
                    sectionID="your_profile"
                    maxTitleWidth={'250px'}
                    subtitle={'Make sure you look your best'}
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        goToPrevStep()
                    }}
                    onNext={onSubmit}
                >
                    <ColCenter>
                        <div style={{ paddingTop: '24px' }} />
                        <UserProfileJourneyUserCardMobile
                            withPhoto={true}
                            userWithFormInfo={
                                userLoggedIn
                                    ? {
                                          ...userLoggedIn,
                                          display_name: displayName,
                                          family_name: familyName,
                                          given_name: givenName,
                                      }
                                    : undefined
                            }
                        />

                        <div
                            style={{
                                paddingTop: '40px',
                                width: '90%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <RenderForms
                                displayName={displayName}
                                setDisplayName={setDisplayName}
                                givenName={givenName}
                                setGivenName={setGivenName}
                                familyName={familyName}
                                setFamilyName={setFamilyName}
                                isMobile={true}
                                setHasChange={setHasChange}
                                hasChange={hasChange}
                            />
                        </div>
                        <AbsoluteGradient />
                    </ColCenter>
                </JourneyPageWrapperMobile>

                <div style={{ paddingTop: '60px' }} />
            </IpadAndMobileDisplay>
        </div>
    )
}
