import { call, fork, put, select, take } from 'redux-saga/effects'
import { I_archiveCar_api_req_args } from '../../../services/archive/archiveCar'
import { api } from '../../../services'
import posthog from 'posthog-js'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { getUserArchivedCarsSaga } from '../../../user/sagas/getUserArchives'
import { editFormsActions } from '../../../editForms/reducer'
import { IRootState } from '../../../store'
import { IUser } from '../../../user/types'
import { ICustomErrorData, IReduxError } from '../types'
import { carActions } from '../reducer'
import { garagesActions } from '../../garages/reducer'

let state_select_user = (state: IRootState) => state.user.userLoggedIn

export function* ArchiveCarSaga(p: I_archiveCar_api_req_args) {
    try {
        yield call(api.archive.archiveCar, p)

        // Capture event
        posthog.capture('submitted car archive')

        yield put(carActions.archiveCarSuccess())
        let udata: IUser | null = yield select(state_select_user)

        if (udata) {
            yield put(
                garagesActions.removeCarFromUserGarage({
                    carID: p.carID,
                    garageID: udata.owns_garage.uid,
                })
            )

            yield call(getUserArchivedCarsSaga)

            // if archiving is successful, set archive step to success, redux will be cleaned there
            yield put(editFormsActions.setArchivingStep('success'))
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: ArchiveCarSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            if (error.status === 403 && error.text) {
                let customErrorData: ICustomErrorData = {
                    custom_message: 'Whoops!',
                    custom_message_detail: error.text,
                    custom_user_action_text: 'OK',
                    custom_redirect_path: `/car/${p.carID}`,
                }
                let customError = ConvertToReduxError(error, customErrorData)
                yield put(carActions.archiveCarError(customError))
            } else {
                let typedError: IReduxError = ConvertToReduxError(error)
                yield put(carActions.archiveCarError(typedError))
            }
            // if other any error than 401 where it retries - cleanup redux
            yield put(editFormsActions.setArchivingStep(undefined))
            return
        }
    }
}

function* WatcherArchiveCar() {
    while (true) {
        let { payload } = yield take(carActions.archiveCarRequest)
        yield call(ArchiveCarSaga, payload)
    }
}

const car_archive_saga: any[] = [fork(WatcherArchiveCar)]

export default car_archive_saga
