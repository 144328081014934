import { ITask, ITaskRelationsPayload } from 'entityModels'
import DatePickerTaskMobile from './../datePicker/datePickerTaskMobile'
import TaskNotesEditorWrapperMobile from './../notes/taskNotesEditorWrapperMobile'
import BasicTaskFieldFormMobile from './../basic/basicTaskFieldFormMobile'
import { generate_task_cars_dropdown_list } from '../../../../../redux/conversions/dropdowns/taskCarsDropdownGenerator'
import SearchNselectCarsTaskMobile from '../taskCars/searchNselectCarsTaskMobile'
import { OOtherTaskOptions } from '../../../../../ol/ol'
import dayjs from 'dayjs'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { carActions } from '../../../../../redux/entities/cars/reducer'
import { tasksActions } from '../../../../../redux/tasks/reducer'
import { useEffect, useState } from 'react'
import { IUser } from '../../../../../redux/user/types'
import { IRootState } from '../../../../../redux/store'

export interface Props {
    item: any
    task: ITask
    toggle: any
    updateTask: any
    carsData: any
    isEveryCar?: boolean
    timezone?: string
}

const TaskFormManagerMobile: React.FC<Props> = (props) => {
    const { item, task, updateTask, toggle, carsData, isEveryCar, timezone } =
        props
    const [dropdownCars, setDropdownCars] = useState<any>(null)
    const [selectedCarNames, setSelectedCarNames] = useState<string[]>([])
    // const [allCarsLinkedTo, setAllCarsLinkedTo] = useState<string[]>([])

    const userLoggedIn: IUser | null = useAppSelector(
        (state: IRootState) => state.user.userLoggedIn
    )

    const garagesData = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    const dispatch = useAppDispatch()

    const generateLinkTitle = (): string[] => {
        let res: string[] = []
        if (item.id === 'linkedToCar' && item.answer) {
            item.answer.forEach((carid: string) => {
                if (carsData && carsData[carid] && carsData[carid].title) {
                    res.push(carsData[carid].title + ' - ')
                }
            })
        } else {
            res = [OOtherTaskOptions.general_task.name]
        }
        return res
    }

    useEffect(() => {
        if (item.id === 'linkedToCar') {
            if (item.answer !== undefined) {
                if (carsData[item.answer[0]]) {
                    carsData[item.answer[0]].title &&
                        setSelectedCarNames([carsData[item.answer[0]].title])
                }
            }

            if (!item.answer) {
                setSelectedCarNames([OOtherTaskOptions.general_task.name])
            }

            if (userLoggedIn && !garagesData) {
                if (userLoggedIn.owns_garage && userLoggedIn.owns_garage.uid) {
                    dispatch(
                        carActions.getGarageCarsDataRequest(
                            userLoggedIn.owns_garage.uid
                        )
                    )
                }
            } else if (
                garagesData &&
                userLoggedIn &&
                userLoggedIn.owns_garage &&
                !dropdownCars
            ) {
                if (
                    garagesData[userLoggedIn.owns_garage.uid] &&
                    garagesData[userLoggedIn.owns_garage.uid].cars
                ) {
                    let dropdown_cars_dropdown =
                        generate_task_cars_dropdown_list(
                            garagesData[userLoggedIn.owns_garage.uid].cars,
                            carsData,
                            userLoggedIn.owns_garage.uid
                        )
                    setDropdownCars(dropdown_cars_dropdown)
                }
            }
        }
    }, [item, carsData, userLoggedIn, garagesData])

    const renderForm = (
        item: any,
        task: ITask,
        updateTask: any,
        toggle: any
    ) => {
        switch (item.id) {
            case 'due':
                return (
                    <DatePickerTaskMobile
                        submitFunc={updateTask}
                        taskid={task.id}
                        item={item}
                        toggle={toggle}
                        showReminderResetWarning={
                            task.reminders && task.reminders?.length > 0
                        }
                        minDate={dayjs().format('YYYY-MM-DD')}
                        timezone={timezone}
                    />
                )
            case 'body':
                return (
                    <TaskNotesEditorWrapperMobile
                        submitFunc={updateTask}
                        taskid={task.id}
                        item={item}
                        toggle={toggle}
                    />
                )
            case 'linkedToCar':
                return (
                    <div style={{ width: '100%' }}>
                        {userLoggedIn &&
                        garagesData[userLoggedIn.owns_garage.uid] &&
                        garagesData[userLoggedIn.owns_garage.uid].cars ? (
                            <SearchNselectCarsTaskMobile
                                item={{
                                    ...item,
                                    text: 'Update what this Task is applied to:',
                                    answer: selectedCarNames,
                                    answers: generateLinkTitle(),
                                }}
                                task={task}
                                toggle={toggle}
                                dropdownData={dropdownCars ? dropdownCars : []}
                                carsData={carsData}
                                garage_id={userLoggedIn.owns_garage.uid}
                                unlinkTaskRequest={(
                                    p: ITaskRelationsPayload
                                ) => {
                                    dispatch(tasksActions.unlinkTaskRequest(p))
                                }}
                                linkTaskRequest={(p: ITaskRelationsPayload) => {
                                    dispatch(tasksActions.linkTaskRequest(p))
                                }}
                                garageCarsIds={
                                    garagesData[userLoggedIn.owns_garage.uid]
                                        .cars
                                }
                                isEveryCar={isEveryCar}
                            />
                        ) : (
                            <div />
                        )}
                    </div>
                )
            default:
                return (
                    <BasicTaskFieldFormMobile
                        submitFunc={updateTask}
                        taskid={task.id}
                        item={item}
                        toggle={toggle}
                    />
                )
        }
    }

    return (
        <div
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {renderForm(item, task, updateTask, toggle)}
        </div>
    )
}

export default TaskFormManagerMobile
