import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

type StyledProps = {
    disabled?: boolean
    $isActive: boolean
}

const MainButton = styled.button<StyledProps>`
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: Lato;
    height: 45px;
    width: fit-content;
    min-width: 130px;
    padding: 10px 10px;
    text-transform: capitalize;
    border: none;
    color: ${(props) =>
        props.$isActive ? 'var(--text-strong, #1a1a1a)' : 'var(--text-muted)'};
    font-size: 17px;
    transition: all 200ms;
    background-color: var(--bg-color, #fff);
    :hover {
        transition: all 200ms;
        background-color: var(--off-bg-color, #fafafa);
        color: var(--primary_strong);
    }
    cursor: ${(props) => (props.disabled === true ? 'not-allowed' : 'pointer')};
    transition: all 500ms;
`

const BtnWrapper = styled(motion.div)<{ isActive?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const BottomLine = styled.div`
    position: relative;
    height: 3px;
    width: 100%;
    box-sizing: border-box;
`

const BottomLineGreen = styled.div<StyledProps>`
    position: absolute;
    left: 0;
    top: 0;
    height: 3px;
    width: 100%;
    box-sizing: border-box;
    transform: ${(props) =>
        props.$isActive === true ? 'scale(1)' : 'scale(0)'};
    transform-origin: left;
    background-color: ${(props) =>
        props.$isActive === true ? 'var(--primary,#5EC3CA)' : 'none'};
    transition: all 500ms;
`
const BottomLineGrey = styled.div<StyledProps>`
    left: 0;
    top: 0;
    height: 3px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--border_muted_subtle);
`

const Row = styled.div`
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
`
const LongLine = styled.div`
    height: 3px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--border_muted_subtle);
`

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--bg-color, #fff);
`

type Props = {
    dataCyId?: string | undefined
    activeID: string
    btnIdsList: string[]
    btnsData: any
}

const SwitchTabMenuDesktop: React.FC<Props> = ({
    dataCyId,
    activeID,
    btnIdsList,
    btnsData,
}) => {
    const [currentActiveID, setCurrentActiveID] = React.useState(activeID)

    return (
        <Wrapper>
            <Row>
                {btnIdsList.map((id: string, index: number) => {
                    return (
                        <React.Fragment key={`${id}_${index}_desktop`}>
                            <BtnWrapper
                                whileTap={{
                                    scale:
                                        btnsData[id].isDisabled === true
                                            ? 1
                                            : 1.05,
                                }}
                                isActive={currentActiveID === id}
                            >
                                <BottomLine>
                                    <BottomLineGrey
                                        $isActive={currentActiveID === id}
                                    />
                                    <BottomLineGreen
                                        $isActive={currentActiveID === id}
                                    />
                                </BottomLine>
                                <MainButton
                                    onClick={() => {
                                        btnsData[id].action(id)
                                        setCurrentActiveID(id)
                                    }}
                                    disabled={
                                        btnsData[id].isDisabled === true
                                            ? true
                                            : false
                                    }
                                    data-cy={dataCyId ? dataCyId : undefined}
                                    $isActive={currentActiveID === id}
                                >
                                    <div>{btnsData[id].txt}</div>
                                </MainButton>
                            </BtnWrapper>
                        </React.Fragment>
                    )
                })}
            </Row>
            <LongLine />
        </Wrapper>
    )
}

export default SwitchTabMenuDesktop
