import { message } from 'antd'
import posthog from 'posthog-js'
import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { createUserAddressApi } from '../../services/user_data/userAddress'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
import { IaddAddressRequest } from '../actions/loadingActions'
import { usersActions } from '../reducer'
import { IUserAddressApi } from '../../services/typedefinitions/apiPayloads'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'

function* addUserAddress(dataToSend: IaddAddressRequest): any {
    try {
        const address: IUserAddressApi = yield call(
            createUserAddressApi,
            dataToSend.data
        )

        yield put(usersActions.addAddressSuccess(address))

        if (
            window.location.pathname &&
            window.location.pathname.includes('showroom')
        ) {
            message.info({
                content: 'Contact details saved!',
            })
        } else {
            message.info({
                content: 'Address saved',
                duration: 10,
            })
        }

        // Capture event
        posthog.capture('ADD USER ADDRESS')

        return true
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: addUserAddress,
                payload: dataToSend,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            if (error?.text?.includes('invalid post code')) {
                let customErrorData: ICustomErrorData = {
                    custom_message: `Invalid Post Code`,
                    custom_redirect_path: '/account',
                }
                let customError: IReduxError = ConvertToReduxError(
                    error,
                    customErrorData
                )
                message.error({ content: error?.text })
                yield put(usersActions.addAddressErrorNoModal(customError))
            } else {
                message.error(error?.text ?? 'Error')
                let typedError: IReduxError = ConvertToReduxError(error)
                yield put(usersActions.addAddressErrorNoModal(typedError))
            }
        }
    }
    return false
}

function* watcherAddUserAddress() {
    while (true) {
        const { payload } = yield take(usersActions.addAddressRequest.type)

        yield call(addUserAddress, payload)
    }
}

const add_user_address: any[] = [fork(watcherAddUserAddress)]

export default add_user_address
