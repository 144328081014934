import { motion, AnimatePresence } from 'framer-motion'

type Props = {
    duration?: number
    children?: React.ReactNode
    isFullWidth?: boolean
}

const FadedSlower = (props: Props) => {
    const duration = props.duration ? props.duration : 0.5
    const variants = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: {
                duration: duration,
                when: 'beforeChildren',
            },
        },
        exit: {
            opacity: 0,
            transition: { duration: duration },
        },
    }

    return (
        <AnimatePresence>
            <motion.div
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
                style={{
                    width: props.isFullWidth ? '100%' : 'auto',
                    height: props.isFullWidth ? '100%' : 'auto',
                    boxSizing: 'border-box',
                }}
            >
                {props.children}
            </motion.div>
        </AnimatePresence>
    )
}

export default FadedSlower
