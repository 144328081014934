import styled from 'styled-components'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import InfoIcon from '../../../atoms/icons/infoIcon'
import useThemes from '../../../../providers/theme/hooks'
import colours, { insurance_colours } from '../../../../providers/theme/colours'
import { device } from '../../../templates/displays/devices'
const OverWrap = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.mobile_and_ipad} {
        padding-left: 16px;
        padding-right: 16px;
    }
`

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    transition: all 200ms;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    box-shadow: 0px 24px 32px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));

    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
    }
`
const Title = styled.div`
    font-size: 24px;
    line-height: 34px;
    font-family: 'Lato-semibold';
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile_and_ipad} {
        font-size: 22px;
        line-height: 28px;
    }
`

const ModalHeader = styled.div`
    padding: 24px;
    padding-bottom: 8px;
`
const ModalBody = styled.div`
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
`

const ModalCtas = styled.div`
    display: flex;
    padding: 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    align-self: stretch;
`

const BtnText = styled.p`
    padding: 10px 16px;
    margin: 0;
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 14px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
    color: var(--primary, #5ec3ca);
`

const Text = styled.div`
    color: var(--text-darker, #616161);
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    width: 100%;
    box-sizing: border-box;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const InfoBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 4px;
`

type Props = {
    title: string
    infoBoxText?: string
    onSave: () => void
    isSaveDisabled?: boolean
    onClose: () => void
    children: React.ReactNode
}

const SingleFieldModalForm = (props: Props) => {
    let { title, infoBoxText, onSave, isSaveDisabled, onClose, children } =
        props
    const { theme } = useThemes()
    return (
        <OverWrap>
            <Wrap>
                <ModalHeader>
                    <Title>{title}</Title>
                </ModalHeader>
                <ModalBody>
                    {infoBoxText ? (
                        <InfoBox
                            style={{
                                backgroundColor:
                                    colours[theme].background_default,
                            }}
                        >
                            <InfoIcon
                                color={
                                    insurance_colours[theme].sections[
                                        'vehicles_to_insure'
                                    ].primary_100
                                }
                            />
                            <Text
                                style={{
                                    color: insurance_colours[theme].sections[
                                        'vehicles_to_insure'
                                    ].primary_text,
                                    lineHeight: '20px',
                                }}
                            >
                                {infoBoxText}
                            </Text>
                        </InfoBox>
                    ) : null}
                    {children}
                </ModalBody>
                <ModalCtas>
                    <StandardCtaBtn
                        onClick={onClose}
                        boder="1px solid var(--primary, #5ec3ca)"
                        bg={'transparent'}
                        customHeight="auto"
                        removePaddings
                        removeShadowOnHover
                    >
                        <BtnText>Cancel</BtnText>
                    </StandardCtaBtn>
                    <StandardCtaBtn
                        onClick={onSave}
                        removePaddings
                        customHeight="auto"
                        boder={
                            isSaveDisabled
                                ? '1px solid var(--border-muted, #e5e5e5)'
                                : '1px solid var(--primary, #5ec3ca)'
                        }
                        isDisabled={isSaveDisabled}
                    >
                        <BtnText style={{ color: 'var(--bg-color, #fff)' }}>
                            Save
                        </BtnText>
                    </StandardCtaBtn>
                </ModalCtas>
            </Wrap>
        </OverWrap>
    )
}

export default SingleFieldModalForm
