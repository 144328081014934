import { IConfirmationFunctionality, IIconData } from 'expandingListPropsTypes'
import React, { useState } from 'react'
import styled from 'styled-components'
// import chevron from '../../../public/assets/icons/chevron_apex_mobile.svg'
// import { Link } from 'react-router-dom'
import Expander from '../expander/expander'
// import ExpanderWCheckbox from '../expanderWCheckbox/expanderWCheckbox'
import reactStringReplace from 'react-string-replace'
import { CheckIconListItem } from './expandingListItemDesktop'

const ItemStyleWrapper = styled.div<IItemStyle>`
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    background: var(--bg-color, #fff);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 90vw;
    box-shadow: 0px 5px 15px var(--box-shadow, rgba(0, 0, 0, 0.08));
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    z-index: 2;
`

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const WrapContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const TextSection = styled.div`
    width: 100%;
    box-sizing: border-box;
    font-family: 'Lato-light';
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    cursor: pointer;
`

// const FunctionalIcon = styled.img`
//     width: 20px;
//     margin-right: 30px;
//     filter: drop-shadow(2px 4px 7px rgba(0, 0, 0, 0.09));
// `

// const CheckIconCircle = styled.img`
//     width: 40px;
//     margin-left: 20px;
//     filter: drop-shadow(2px 4px 7px rgba(0, 0, 0, 0.09));

//     @media (max-width: 325px) {
//         width: 30px;
//         margin-left: 16px;
//     }
// `

const ReadText = styled.div`
    color: var(--primary, #5ec3ca);
    font-family: 'Lato-light';
    font-size: 13px;
    padding-top: 6px;
    padding-left: 20px;
    padding-right: 20px;
`
const BodyText = styled.div`
    font-size: 18px;
    font-weight: 300;
    color: var(--text-strong, #1a1a1a);
    padding-left: 20px;
    padding-right: 20px;

    @media (max-width: 325px) {
        font-size: 15px;
    }
`

const SmallDash = styled.div`
    width: 20px;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    margin-left: 20px;
    margin-top: 20px;
`

const AnswerSection = styled.div`
    width: 100%;
    box-sizing: border-box;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--text-strong, #1a1a1a);
`

const LinksSection = styled.div`
    padding: 0 30px 30px 30px;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .linksIndent {
        padding-left: 30px;
    }
`

const MoreText = styled.span`
    font-family: Lato;
    font-style: italic;
    font-weight: 200;
    font-size: 12px;
    color: var(--text-strong, #1a1a1a);
`

const LinkText = styled.a`
    font-family: Lato;
    font-style: italic;
    font-weight: 200;
    font-size: 12px;
    color: var(--primary, #5ec3ca) !important;
`

const LinksList = styled.div`
    display: flex;
    flex-direction: column;

    .linksInList {
        margin-top: -15px;
    }
`

// const ConfirmationContainer = styled.div`
//     transform: translateY(-20px);
//     z-index: 1;
//     width: 100%;
//     width: 85vw;
// `

// const Voted = styled.span`
//     font-size: 13px;
//     color: #009ea4;
//     text-transform: capitalize;
//     padding-right: 10px;
//     font-family: Lato;
//     width: 50px;
//     transform: translateX(-14px);
// `

interface IItemStyle {
    isDark: boolean
}

interface IItemProps {
    id: string
    text: string
    dateFinished?: string
    answer: string
    links?: string[]
    iconData?: IIconData
    confirmationFunctionality?: IConfirmationFunctionality
    theme?: 'upvote' | 'check'
}

// interface IUpvoteThemeProps {
//     iconData?: IIconData
//     confirmationFunctionality?: IConfirmationFunctionality
//     isConfirmationOpen: boolean
//     setIsConfirmationOpen?: any
// }

interface ICheckThemeProps {
    iconData?: IIconData
}

// const UpvoteThemePart1: React.SFC<IUpvoteThemeProps> = (props) => {
//     return (
//         <>
//             {props.iconData &&
//                 (props.confirmationFunctionality && props.isConfirmationOpen ? (
//                     <Voted>Voted!</Voted>
//                 ) : (
//                     <FunctionalIcon
//                         src={props.iconData.icon}
//                         onClick={
//                             /*props.iconData.onClick*/ () =>
//                                 props.setIsConfirmationOpen(
//                                     !props.isConfirmationOpen
//                                 )
//                         }
//                     />
//                 ))}

//             <div />
//         </>
//     )
// }

// const UpvoteThemePart2: React.SFC<IUpvoteThemeProps> = (props) => {
//     return (
//         <>
//             {props.confirmationFunctionality && (
//                 <ConfirmationContainer>
//                     <ExpanderWCheckbox
//                         text={props.confirmationFunctionality.text}
//                         onChange={props.confirmationFunctionality.onClick}
//                         isOpen={props.isConfirmationOpen}
//                         bgColor="#00adb3"
//                     />
//                 </ConfirmationContainer>
//             )}
//         </>
//     )
// }

const FunctionalIcon = styled.div`
    padding-left: 24px;
    padding-right: 4px;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    height: 100%;
    display: flex;
`

const CheckTheme: React.FunctionComponent<ICheckThemeProps> = (props) => {
    return (
        <FunctionalIcon>
            <CheckIconListItem />
        </FunctionalIcon>
    )
}

const ExpandingListItemMobile = (props: IItemProps) => {
    const [isOpen, setIsOpen] = useState(false)
    // const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

    let replaceAnswer: string | React.ReactNode[] = props.answer

    replaceAnswer = reactStringReplace(
        replaceAnswer,
        /(https?:\/\/[^\s]+)/g,
        (match, i) => (
            <a href={match} key={i} target="_blank" rel="noreferrer">
                {match}
            </a>
        )
    )
    replaceAnswer = reactStringReplace(
        replaceAnswer,
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
        (match, i) => (
            <a
                href={`mailto:${match}?subject=Help`}
                key={i}
                target="_blank"
                rel="noreferrer"
            >
                {match}
            </a>
        )
    )

    return (
        <React.Fragment>
            <ItemStyleWrapper isDark={!isOpen} key={props.id}>
                <WrapContainer>
                    {props.theme === 'check' && (
                        <CheckTheme iconData={props.iconData} />
                    )}
                    <Container>
                        <TextSection onClick={() => setIsOpen(!isOpen)}>
                            <BodyText>{props.text}</BodyText>
                            <ReadText>{!isOpen ? 'Read More' : ''}</ReadText>
                        </TextSection>

                        {/* {props.theme === 'upvote' && (
                            <UpvoteThemePart1
                                iconData={props.iconData}
                                setIsConfirmationOpen={setIsConfirmationOpen}
                                isConfirmationOpen={isConfirmationOpen}
                                confirmationFunctionality={
                                    props.confirmationFunctionality
                                }
                            />
                        )} */}
                    </Container>
                </WrapContainer>

                <Expander height={isOpen ? 'auto' : 0}>
                    {props.theme !== 'check' && <SmallDash />}
                    <AnswerSection>{replaceAnswer}</AnswerSection>
                    {/* <LinksSection> */}
                    {props.links ? (
                        props.links.length > 1 ? (
                            <LinksSection>
                                <LinksList>
                                    <MoreText> More on: </MoreText>
                                    <ul className="linksIndent">
                                        {props.links.map((link, i) => (
                                            <li
                                                className="linksInList"
                                                key={`links_${link}_${i}_mobile`}
                                            >
                                                <LinkText href={link}>
                                                    {link}
                                                </LinkText>
                                            </li>
                                        ))}
                                    </ul>
                                </LinksList>
                            </LinksSection>
                        ) : (
                            <LinksSection>
                                <MoreText>More on &nbsp;</MoreText>
                                <LinkText href={props.links[0]}>
                                    {props.links[0]}
                                </LinkText>
                            </LinksSection>
                        )
                    ) : null}
                    {/* </LinksSection> */}

                    <ReadText onClick={() => setIsOpen(!isOpen)}>
                        Read Less
                    </ReadText>
                </Expander>
            </ItemStyleWrapper>
            {/* {props.theme === 'upvote' && (
                <UpvoteThemePart2
                    iconData={props.iconData}
                    setIsConfirmationOpen={setIsConfirmationOpen}
                    isConfirmationOpen={isConfirmationOpen}
                    confirmationFunctionality={props.confirmationFunctionality}
                />
            )} */}
        </React.Fragment>
    )
}

export default ExpandingListItemMobile
