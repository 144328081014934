import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import {
    IManageTopSubmenuSuccessArg,
    menuActions,
} from '../../../../redux/menus/reducer'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import styled from 'styled-components'
import CarShowroomTopBar from '../../../templates/bars/showroomBars/topBars/carShowroomTopBar'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import {
    INormalisedShowroomEntries,
    IShowroomEntry,
} from '../../../../redux/showroom/types'
import { showroomActions } from '../../../../redux/showroom/reducer'
import CarShowroomEntryShareModal from '../../../molecules/showroom/editModals/desktop/carShowroomEntryShareModal'
import ModalDisplayFullPageDesktopExclusive from '../../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import ModalDisplayMobileIpad from '../../../templates/displays/pageWrappers/modalDisplayMobileExclusive'
import { device } from '../../../templates/displays/devices'
import PortalInvisibleWrapper from '../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import ShowroomCarGalleryImage from './showroomCarGalleryImage'
import DescriptionSectionExpanded from '../../../molecules/showroom/section/descriptionSectionExpanded'
import { usersActions } from '../../../../redux/user/reducer'

const MobileSectionWrapper = styled.div`
    padding-right: 1.5rem;
    padding-left: 1.5rem;
`

const ShowroomModalInnerContainer = styled.div`
    height: max-content;
    width: max-content;
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 2px;

    @media ${device.mobile_and_ipad} {
        width: 95vw;
    }

    @media ${device.beyond_ipad_mobile} {
        min-width: 500px;
    }

    z-index: 20;
    transition: all 100ms ease-in-out;
    padding: 24px;
`

type EntryParams = {
    entryid: string
}

const ShowroomCarDescription: React.FC = () => {
    const { entryid } = useParams<EntryParams>()
    const navigate = useNavigate()
    const location = useLocation()

    const dispatch = useAppDispatch()

    const removeTopSubMenu = (status: IManageTopSubmenuSuccessArg) =>
        dispatch(menuActions.manageTopSubmenu(status))

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const normalisedPublicEntries: INormalisedShowroomEntries | undefined =
        useAppSelector((state) => state.showroom.normalisedPublicEntries)

    const currentEntry: IShowroomEntry | undefined =
        entryid && normalisedPublicEntries && normalisedPublicEntries[entryid]
            ? normalisedPublicEntries[entryid]
            : undefined

    const [isShareOpen, setIsShareOpen] = useState(false)

    useEffect(() => {
        let refresh_token: string | null =
            typeof localStorage !== 'undefined' && localStorage !== null
                ? localStorage.getItem('refresh_token')
                : null

        if (userLoggedIn) {
            dispatch(
                showroomActions.getShowroomEntryByIDRequest({
                    is_owner: false,
                    entryID: entryid!,
                })
            )
        } else if (!userLoggedIn && refresh_token !== null) {
            dispatch(usersActions.getCurrentUserDataRequest())
            dispatch(
                showroomActions.getShowroomEntryByIDRequest({
                    is_owner: false,
                    entryID: entryid!,
                })
            )
        } else {
            dispatch(
                showroomActions.getPublicShowroomEntryByIDRequest(entryid!)
            )
        }

        removeTopSubMenu({ isRemoved: true })

        return () => {
            removeTopSubMenu({ isRemoved: false })
        }
    }, [entryid, userLoggedIn])

    const params = new URLSearchParams(location.search)

    return (
        <>
            <DesktopDisplayOnly>
                <ModalDisplayFullPageDesktopExclusive
                    isOpen={isShareOpen}
                    toggle={() => {
                        setIsShareOpen(false)
                    }}
                >
                    <ShowroomModalInnerContainer>
                        <CarShowroomEntryShareModal
                            entryid={entryid!}
                            close={() => {
                                setIsShareOpen(false)
                            }}
                        />
                    </ShowroomModalInnerContainer>
                </ModalDisplayFullPageDesktopExclusive>
                <div style={{ paddingTop: '5em' }} />
                <CarShowroomTopBar
                    onClickEnquire={() => {
                        navigate(`/showroom/${entryid}/enquiry`)
                    }}
                    isMobile={false}
                    entryID={entryid!}
                    isReadOnly={true}
                    is_watched={currentEntry?.is_watched ? true : false}
                    onClickShare={() => {
                        setIsShareOpen(true)
                    }}
                />
                <div style={{ paddingTop: '80px' }} />
                <DescriptionSectionExpanded
                    isLoading={false}
                    currentEntry={currentEntry}
                    isAdmin={false}
                />
                <div style={{ paddingTop: '300px' }} />
            </DesktopDisplayOnly>

            {!params.get('id') && (
                <IpadAndMobileDisplay>
                    <ModalDisplayMobileIpad
                        isOpen={isShareOpen}
                        toggle={() => {
                            setIsShareOpen(false)
                        }}
                    >
                        <ShowroomModalInnerContainer>
                            <CarShowroomEntryShareModal
                                entryid={entryid!}
                                close={() => {
                                    setIsShareOpen(false)
                                }}
                            />
                        </ShowroomModalInnerContainer>
                    </ModalDisplayMobileIpad>
                    <div style={{ paddingTop: 63 }} />
                    <CarShowroomTopBar
                        isMobile
                        onClickEnquire={() => {
                            navigate(`/showroom/${entryid}/enquiry`)
                        }}
                        entryID={entryid!}
                        isReadOnly={true}
                        is_watched={currentEntry?.is_watched ? true : false}
                        onClickShare={() => {
                            setIsShareOpen(true)
                        }}
                    />
                    <div style={{ paddingTop: '24px' }} />
                    <DescriptionSectionExpanded
                        isLoading={false}
                        currentEntry={currentEntry}
                        isAdmin={false}
                    />
                    <MobileSectionWrapper>
                        <div style={{ paddingTop: '48px' }} />
                    </MobileSectionWrapper>
                </IpadAndMobileDisplay>
            )}
            {params.get('id') && (
                <PortalInvisibleWrapper
                    onClick={() => {
                        navigate(location.pathname)
                    }}
                >
                    <ShowroomCarGalleryImage />
                </PortalInvisibleWrapper>
            )}
        </>
    )
}

export default ShowroomCarDescription
