import { call, fork, put, take } from 'redux-saga/effects'
import { ResponseGenerator } from 'sagaInterfaces'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { fileStorageActions } from '../../reducer'
import { IReduxError } from '../../../entities/cars/types'
import { IApiGetUploadPresignedUrlCarGalleryImg_args } from '../../../services/types'
import {
    IUploadGalleryImagesPreSignedUrlsPayload,
    IUploadImagePreSignedUrlPayloadSingle,
    IUploadImagesPreSignedUrlsPayload,
} from '../../types'

type IPayloadPresignedUrlsSagaReq = {
    car_id: string
    count: number
    file_names: string[]
}

function* getPreSignedUrlImagesCarGallery(
    payload: IPayloadPresignedUrlsSagaReq
) {
    try {
        let api_payload: IApiGetUploadPresignedUrlCarGalleryImg_args = {
            carID: payload.car_id,
            count: payload.count,
            file_names: payload.file_names,
        }
        const result: ResponseGenerator =
            yield api.fileStorage.get_upload_presigned_url_cargalleryimage_API(
                api_payload
            )

        const data: IUploadGalleryImagesPreSignedUrlsPayload = {}

        result.data?.forEach(
            (element: IUploadImagePreSignedUrlPayloadSingle) => {
                if (element.original_filename) {
                    data[element.original_filename] = element
                }
            }
        )

        yield put(
            fileStorageActions.getPresignedUrlsCarGalleryImagesSuccess(data)
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: getPreSignedUrlImagesCarCoverImage,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                fileStorageActions.getPresignedUrlsCarGalleryImagesFileStorageError(
                    typedError
                )
            )
        }
    }
}

// LIST

// WATCHER FUNCTION : getPreSignedUrlImagesCarGalleryWatcher*

export function* watchGetPreSignedUrlImagesCarGallery() {
    while (true) {
        const { payload } = yield take(
            fileStorageActions.getPresignedUrlsCarGalleryImagesRequest
        )

        let p: IPayloadPresignedUrlsSagaReq = {
            car_id: payload.carid,
            count: payload.count,
            file_names: payload.file_names,
        }
        yield fork(getPreSignedUrlImagesCarGallery, p)
    }
}

// 1. take every GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_REQUEST

// 2. call DOER FUNCTION : getPreSignedUrlImagesCarGalleryApi

function* getPreSignedUrlImagesCarCoverImage(p: {
    carid: string
    file_name: string
}) {
    try {
        const result: ResponseGenerator =
            yield api.fileStorage.get_upload_presigned_url_carcoverimage_API(p)

        const data: IUploadImagesPreSignedUrlsPayload = result.data

        yield put(fileStorageActions.getPresignedUrlCarCoverImageSuccess(data))
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getPreSignedUrlImagesCarCoverImage,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                fileStorageActions.getPresignedUrlCarCoverImageFileStorageError(
                    typedError
                )
            )
            return
        }
    }
}

// LIST

// WATCHER FUNCTION : getPreSignedUrlImagesCarGalleryWatcher*

function* watchGetPreSignedUrlCarCoverImage() {
    while (true) {
        const { payload } = yield take(
            fileStorageActions.getPresignedUrlCarCoverImageRequest
        )
        yield fork(getPreSignedUrlImagesCarCoverImage, payload)
    }
}

function* getPreSignedUrlUserProfileImage(fileName: string) {
    try {
        const result: ResponseGenerator =
            yield api.fileStorage.get_upload_presigned_url_userprofileimage_API(
                fileName
            )

        const data: IUploadImagePreSignedUrlPayloadSingle = result.data

        yield put(
            fileStorageActions.getPresignedUrlUserProfileImageSuccess([data])
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getPreSignedUrlUserProfileImage,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                fileStorageActions.getPresignedUrlUserProfileImageError(
                    typedError
                )
            )
        }
    }
}

function* watchGetPreSignedUrlUserProfileImage() {
    while (true) {
        const { payload } = yield take(
            fileStorageActions.getPresignedUrlUserProfileImageRequest
        )
        yield fork(getPreSignedUrlUserProfileImage, payload)
    }
}

const filestorage_sagas_presigned_urls: any[] = [
    fork(watchGetPreSignedUrlImagesCarGallery),
    fork(watchGetPreSignedUrlCarCoverImage),
    fork(watchGetPreSignedUrlUserProfileImage),
]

export default filestorage_sagas_presigned_urls

// 1. take every GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_REQUEST

// 2. call DOER FUNCTION : getPreSignedUrlImagesCarGalleryApi
