import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import AddLabelButton from '../../atoms/Button/addLabelButton'
import PiledUpLabelsMobile from '../../atoms/PledUpLabels/piledUPLabelsMobile'
import CustomIconTagQuickFilterItem from '../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import CustomTagQuickFilterItemMobile from '../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemMobile'
import Faded from '../../templates/animated/faded'
import { device } from '../../templates/displays/devices'
import * as unitGenerator from '../../../helpers/units/unitConversion'
import MileageNakedTagMobile from '../../atoms/tags/mileageTags/mileageNakedTagMobile'
import TimeAgoEntryTag from '../../atoms/tags/timeAgoEntryTag/timeAgoEntryTag'
import colours, {
    history_file_colours,
    ITheme,
} from '../../../providers/theme/colours'
import { useAppSelector } from '../../../redux/store/hooks'
import {
    ILabel,
    ITimelineEntryTypes,
    ITimelineItem,
} from '../../../redux/timeline/types'
import useThemes from '../../../providers/theme/hooks'

interface IStyled {
    $theme: ITheme
    someProp?: any
    iscaroverview?: boolean
    $hasDescription?: boolean | undefined
    $hasSampleData?: boolean
    $height?: number
    $category?: ITimelineEntryTypes
}

const Container = styled.div<IStyled>`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border: ${(props) =>
        props.iscaroverview
            ? `1px solid ${
                  props.$category
                      ? history_file_colours[props.$theme]?.categories[
                            props.$category as keyof (typeof history_file_colours)[typeof props.$theme]['categories']
                        ]?.primary_100
                      : colours[props.$theme]?.border_muted
              }`
            : 'none'};
    ${(props) => props.iscaroverview && 'border-radius: 5px;'};
    display: flex;
    flex-direction: column;

    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }

    height: ${(props) => (props.$height ? `${props.$height}px` : '100%')};
`

const Row = styled.div<IStyled>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
`

const TagsRow = styled.div<IStyled>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
`

const Title = styled.div<IStyled>`
    color: ${(props) => colours[props.$theme].text_strong};
    text-transform: capitalize;
    font-family: 'Lato';
    font-size: 18px;
`

const ValueText = styled.div<IStyled>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: 'Lato';
    font-size: 16px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
`

const DateText = styled.div<IStyled>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: 'Lato-light';
    font-size: 14px;
    letter-spacing: 0.7px;
    display: flex;
    @media (max-width: 320px) {
        font-size: 12px;
    }
`

const Description = styled.div<IStyled>`
    font-family: 'Lato-light';
    font-size: 14px;
    height: ${(props) => (props.$hasSampleData ? '45px' : '45px')};
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    @media (max-device-width: 321px) {
        height: 70px;
    }

    @media (min-device-width: 600px) {
        height: 45px;
    }

    color: ${(props) =>
        props.$hasDescription
            ? colours[props.$theme].text_strong
            : colours[props.$theme].text_muted};
    font-style: ${(props) => (props.$hasDescription ? 'inherit' : 'italic')};
`

const SmallPadding = styled.div<IStyled>`
    padding-top: 20px;
`

const Separator = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: ${(props) => colours[props.$theme].border_muted};
`

const SmallPaddingRight = styled.div<IStyled>`
    padding-right: 10px;
`

const MileageTagWrapper = styled.div<IStyled>`
    position: absolute;
    bottom: 20px;
    right: 20px;
    @media ${device.ipad} {
        right: 50px;
    }
`

interface Props {
    item: ITimelineItem
    onClick?: any
    iscaroverview?: boolean
    dataCyId?: string
    onAddNewLabelClick?: any
    userCurrency: string | undefined
    hasSampleData?: boolean
    readOnlyMode?: boolean
    height?: number
}

const SmallTimelineItemMobile: React.FC<Props> = ({
    item,
    dataCyId,
    iscaroverview,
    onClick,
    onAddNewLabelClick,
    userCurrency,
    hasSampleData,
    readOnlyMode,
    height,
}) => {
    let { theme } = useThemes()

    const [descriptionPreviewLength, setDescriptionPreviewLength] = useState(95)

    const { carid } = useParams<{ carid: string }>()

    const userDistanceUnit = useAppSelector((state) =>
        state.user.userLoggedIn
            ? state.user.userLoggedIn.preferences.data.units.data.distance_unit
                  .user_choice_id
            : null
    )

    const technicalInformationData = useAppSelector(
        (state) => state.entities.technicalInformationData.technical_information
    )

    const handleResize = () => {
        if (hasSampleData) {
            setDescriptionPreviewLength(170)
        } else if (window.innerWidth < 376) {
            setDescriptionPreviewLength(90)
        } else {
            setDescriptionPreviewLength(105)
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [hasSampleData])

    const carMileageFromState: number | string | undefined | null =
        carid &&
        technicalInformationData &&
        technicalInformationData[`hf-${carid}`] &&
        technicalInformationData[`hf-${carid}`].mileage &&
        technicalInformationData[`hf-${carid}`].mileage.answer

    const carMileage: number | undefined | null =
        typeof carMileageFromState === 'string'
            ? parseInt(carMileageFromState)
            : carMileageFromState

    const convertedCarMileage =
        carMileage && userDistanceUnit
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnit,
                  carMileage
              )
            : undefined

    const convertedMileageRecorded: number | undefined =
        userDistanceUnit && item.mileage
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnit,
                  item.mileage
              )
            : undefined

    const mileageSince =
        convertedCarMileage !== undefined &&
        convertedMileageRecorded !== undefined &&
        convertedCarMileage >= convertedMileageRecorded
            ? convertedCarMileage - convertedMileageRecorded
            : undefined

    return (
        <Faded>
            <Container
                $category={item.categoryID}
                $theme={theme}
                key={`${item.id}-small`}
                data-attr={dataCyId && dataCyId}
                iscaroverview={iscaroverview}
                onClick={onClick}
                $hasSampleData={hasSampleData}
                $height={height}
            >
                <SmallPadding $theme={theme} />
                <Row $theme={theme}>
                    <DateText $theme={theme}>
                        {item.date ? fromIsoStringToDDMMYYY(item.date) : ''}
                        <TimeAgoEntryTag
                            device="mobile"
                            height="24px"
                            entry_date={item.date}
                        />
                    </DateText>
                    <CustomIconTagQuickFilterItem
                        height="24px"
                        id={item.categoryID}
                    >
                        {item.categoryID}
                    </CustomIconTagQuickFilterItem>
                </Row>
                <Row $theme={theme}>
                    <Title $theme={theme}>{item.title}</Title>
                    <ValueText $theme={theme}>
                        {userCurrency
                            ? userCurrency === 'GBP'
                                ? '£'
                                : userCurrency === 'USD'
                                ? '$'
                                : userCurrency === 'EUR'
                                ? '€'
                                : '£'
                            : '£'}
                        {item.total_cost_amount_display_short
                            ? item.total_cost_amount_display_short
                            : '0'}
                    </ValueText>
                </Row>
                <Row $theme={theme}>
                    <Description
                        $theme={theme}
                        $hasDescription={item.description ? true : false}
                        $hasSampleData={hasSampleData}
                    >
                        {item.description ? (
                            item.description.length <
                            descriptionPreviewLength ? (
                                item.description
                            ) : (
                                `${item.description.slice(
                                    0,
                                    descriptionPreviewLength
                                )}...`
                            )
                        ) : (
                            <div
                                style={{
                                    color: colours[theme].text_muted,
                                }}
                            >
                                No description
                            </div>
                        )}
                    </Description>
                </Row>

                <TagsRow $theme={theme}>
                    {item.labels?.length === 0 && !readOnlyMode && (
                        <div>
                            <AddLabelButton
                                onClick={(e: any) => {
                                    onAddNewLabelClick()
                                    e.stopPropagation()
                                }}
                                text={'Add label'}
                                backgroundColor={
                                    colours[theme].border_muted_lighter
                                }
                                height="22px"
                            />
                            <div style={{ paddingBottom: '10px' }} />
                        </div>
                    )}
                    {item.labels?.length === 0 && readOnlyMode === true && (
                        <div
                            style={{
                                color: colours[theme].text_muted_light,
                                fontStyle: 'italic',
                                fontFamily: 'Lato-light',
                            }}
                        >
                            no labels
                        </div>
                    )}

                    {item.labels &&
                        item.labels.map(
                            (label: ILabel, i: number) =>
                                i < 1 && (
                                    <div
                                        key={label.uid}
                                        style={{
                                            display: 'flex',
                                            paddingBottom: '15px',
                                        }}
                                    >
                                        <CustomTagQuickFilterItemMobile>
                                            {label.name}
                                        </CustomTagQuickFilterItemMobile>
                                        <div
                                            style={{
                                                paddingRight: '10px',
                                            }}
                                        />
                                    </div>
                                )
                        )}
                    {item.labels && item.labels.length > 1 && (
                        <>
                            <SmallPaddingRight $theme={theme} />
                            <PiledUpLabelsMobile>{`+ ${
                                item.labels.length - 1
                            }`}</PiledUpLabelsMobile>
                        </>
                    )}
                </TagsRow>

                <MileageTagWrapper $theme={theme}>
                    <MileageNakedTagMobile
                        mileageRecorded={convertedMileageRecorded}
                        mileageSince={mileageSince}
                        unit={userDistanceUnit}
                        hasSampleData={hasSampleData}
                    />
                </MileageTagWrapper>
            </Container>
            {!iscaroverview && <Separator $theme={theme} />}
        </Faded>
    )
}

export default SmallTimelineItemMobile
