import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import InputField from '../../../../../atoms/Inputfield/inputField'
import { IYearsValidationCopy } from 'formValidationCopyModels'
import Expander from '../../../../../atoms/expander/expander'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding40 = styled.div`
    padding-top: 40px;
`
const Padding5 = styled.div`
    padding-top: 5px;
`

const WarningText = styled.div`
    color: #f08080;
    font-size: 12px;
    width: 100%;
    box-sizing: border-box;
    align-items: start;
    justify-content: flex-start;
    font-style: italic;
    font-family: Lato;
`

interface Values {
    car_year_custom_desktop: any
}

interface OtherProps {
    toggle: any
    year_item: any
    submitFunc?: any
    tech_info_id?: any
    setCustomItemInSpecifiedTargettedDropdown: any
    model_item: any
    carid: string
    car_years_validation?: IYearsValidationCopy
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { toggle, year_item, handleSubmit, values, errors, model_item } =
        props

    let checkIfDisabled = () => {
        if (!values.car_year_custom_desktop) {
            return true
        } else if (errors.car_year_custom_desktop) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop style={{ marginTop: '20vh' }} $theme={theme}>
            <ModalTopBar $theme={theme}>Year</ModalTopBar>

            {year_item !== undefined && model_item !== undefined && (
                <ModalInnerContentWrapperDesktop>
                    <Expander height={model_item.answer === null ? 'auto' : 0}>
                        <WarningText style={{ paddingBottom: 16 }}>
                            Please enter the car's model.
                        </WarningText>
                    </Expander>
                    <Padding5 />
                    <InputField
                        theme={theme}
                        id="car_year_custom_desktop"
                        name="car_year_custom_desktop"
                        placeholder={'year'}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.car_year_custom_desktop}
                        font_family="Lato"
                        width="150px"
                        type="number"
                        disabled={
                            values['car_year_custom_desktop'] === null &&
                            model_item.answer === null
                                ? true
                                : false
                        }
                        onEnterKeyPress={props.handleSubmit}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <Expander
                        height={errors.car_year_custom_desktop ? 'auto' : 0}
                    >
                        <WarningText style={{ paddingTop: 8 }}>
                            {errors.car_year_custom_desktop
                                ? `${errors.car_year_custom_desktop}`
                                : 'Error'}
                        </WarningText>
                    </Expander>

                    <Padding40 />
                    <ButtonsStandardBottomRowDesktop
                        isDisabled={checkIfDisabled()}
                        cancel={toggle}
                        submit={handleSubmit}
                    />
                </ModalInnerContentWrapperDesktop>
            )}
        </ModalContentDesktop>
    )
}

const CarYearCustomFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        car_year_custom_desktop: props.year_item ? props.year_item.answer : '-',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                car_year_custom_desktop:
                    values.car_year_custom_desktop &&
                    values.car_year_custom_desktop !== '-' &&
                    props.car_years_validation &&
                    Yup.number()
                        .min(
                            props.car_years_validation.min,
                            props.car_years_validation.warning_min
                        )
                        .max(
                            props.car_years_validation.max,
                            props.car_years_validation.warning_max
                        ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let p: IwriteTechnicalInformationPayloadReq = {
            car_id: props.carid,
            tech_info_id: props.tech_info_id,
            data: {
                id: 'year',
                text: 'year',
                answer: values.car_year_custom_desktop,
            },
        }

        props.submitFunc(p)
        props.toggle()
    },
})(InnerForm)

export default CarYearCustomFormDesktop
