import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertAPIEntryCostToEntryCostSingle } from '../../conversions/timeline/entryCostToCostItem'
import { UpdateActiveEntryWithCost } from '../../conversions/timeline/updateActiveEntryWithCost'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'

import Decimal from 'decimal.js'
import { IRootState } from '../../store'
import { timelineActions } from '../reducer'
import { carActions } from '../../entities/cars/reducer'
import {
    ICreateEntryCostPayloadAPI,
    IEntryCostPayloadAPI,
} from '../../services/typedefinitions/apiPayloads'
import { IApiEntryCostCREATE_args } from '../../services/types'
import {
    IAddCostItemPayloadRequest,
    ICostItem,
    ITimelineItem,
    IAddCostItemPayloadSuccess,
} from '../types'
import {
    ICarsObject,
    ICustomErrorData,
    IReduxError,
} from '../../entities/cars/types'
import { push } from 'redux-first-history'

const get_active_entry_in_state = (state: IRootState) =>
    state.timeline.activeEntry

const getActiveEntryCategory = (state: IRootState) =>
    state.timeline.activeEntry && state.timeline.activeEntry.categoryID

export const getUserCurrencyPreference = (state: IRootState) =>
    state.user.userLoggedIn?.preferences.data.units.data.default_currency
        .user_choice_id

const get_cars_in_state = (state: IRootState) => state.entities.carsData.cars

export function* AddCarTimelineEntryCostSaga(
    payload: IAddCostItemPayloadRequest
): any {
    try {
        // STEP 1 . build up correct payload to send to api => this is the JSON body

        let costCategory = yield select(getActiveEntryCategory)
        let userCurrencyPreference = yield select(getUserCurrencyPreference)
        let costToSend =
            payload.cost.amount && isNaN(payload.cost.amount) !== true
                ? new Decimal(payload.cost.amount).times(100)
                : undefined

        let api_payload_req_entry_cost: ICreateEntryCostPayloadAPI = {
            name: payload.cost.name
                ? payload.cost.name
                : `${costCategory} cost`,
            amount: costToSend ? costToSend.toString() : undefined,
            currency: payload.cost.currency
                ? payload.cost.currency
                : userCurrencyPreference
                ? userCurrencyPreference
                : 'GBP',
            company_name: payload.cost.company
                ? payload.cost.company
                : undefined,
            occurred_at: payload.cost.date ? payload.cost.date : undefined,
        }
        // STEP 2 . call the api func and keep data returned in a const
        const p2: IApiEntryCostCREATE_args = {
            itemid: payload.itemid,
            carid: payload.carid,
            cost: api_payload_req_entry_cost,
        }
        const newCost: IEntryCostPayloadAPI = yield call(
            api.timeline.costs.createEntryCost.createEntryCost,
            p2
        )

        // STEP 3 . convert API data to reducer data type
        // check what back end returns on console log or network window inspector:
        // sometimes theres a nested object where you get the cost after .data,
        // but sometimes it returns straight on the cost returned, depends on the function

        let newCostItem: ICostItem =
            ConvertAPIEntryCostToEntryCostSingle(newCost)

        let active_entry_in_state = yield select(get_active_entry_in_state)

        let active_entry_updated_with_updated_cost: ITimelineItem | undefined =
            UpdateActiveEntryWithCost(
                'create',
                active_entry_in_state,
                newCostItem
            )
        // STEP 4 . build up payload to return to reducer
        let res: IAddCostItemPayloadSuccess = {
            // carid: payload.carid,
            entry: active_entry_updated_with_updated_cost,
            cost: newCostItem,
        }
        // STEP 5 . send success action with payload above to redux
        yield put(timelineActions.addCostToTimelineItemSuccess(res))
        yield put(timelineActions.setActiveCostSuccess(res.cost))
        yield put(carActions.resetCarInsights(payload.carid))
        if (payload.goToEntryPage === true) {
            yield put(
                push(
                    `/car/${payload.carid}/history-file/entry?entryid=${payload.itemid}`
                )
            )
        }

        let cars_in_state: ICarsObject = yield select(get_cars_in_state)

        let carAffected = cars_in_state[payload.carid]

        if (carAffected?.last_insurance_entry?.id === payload.itemid) {
            yield put(
                carActions.getLastCarInsuranceEntrySuccess({
                    carid: payload.carid,
                    timelineItem: undefined,
                })
            )
        }

        // Capture event
        posthog.capture('ADD COST TO ENTRY')

        return newCostItem.id
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: AddCarTimelineEntryCostSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try adding a cost item again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/car/${payload.carid}/history-file/entry?entryid=${payload.itemid}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(timelineActions.addCostToTimelineItemError(customError))
        }
    }
}

function* watcherAddCarTimelineCost() {
    while (true) {
        const { payload } = yield take(
            timelineActions.addCostToTimelineItemRequest
        )

        yield call(AddCarTimelineEntryCostSaga, payload)
    }
}

const add_car_timeline_entry_cost: any[] = [fork(watcherAddCarTimelineCost)]

export default add_car_timeline_entry_cost
