import * as React from 'react'
import './styles.css'
import styled from 'styled-components'

import { device } from './devices'

const OnlyDesktopWrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        display: none;
    }
`

export interface GlobalLayoutProps {
    children?: React.ReactNode
}

const DesktopDisplayOnly = (props: GlobalLayoutProps) => (
    <OnlyDesktopWrapper>{props.children}</OnlyDesktopWrapper>
)

export default DesktopDisplayOnly
