import { IDirectShareOwnedItem } from '../../../../redux/entities/cars/types'

export let isHistoryFileChecked = (
    share_being_edited: IDirectShareOwnedItem,
    readOnlyMode?: boolean
): boolean =>
    share_being_edited.active_permission_ids.includes('history file') ||
    share_being_edited.active_permission_ids.includes('entry attachments') ||
    share_being_edited.active_permission_ids.includes('entry costs') ||
    share_being_edited.active_permission_ids.includes('entry cost attachments')
        ? true
        : false

export let isHistoryFileDisabled = (
    share_being_edited: IDirectShareOwnedItem,
    readOnlyMode?: boolean
): boolean =>
    readOnlyMode ||
    share_being_edited.active_permission_ids.includes('entry attachments') ||
    share_being_edited.active_permission_ids.includes('entry costs') ||
    share_being_edited.active_permission_ids.includes('entry cost attachments')
        ? true
        : false

export let isEntryAttachmentsChecked = (
    share_being_edited: IDirectShareOwnedItem,
    readOnlyMode?: boolean
): boolean =>
    share_being_edited.active_permission_ids.includes('entry attachments')
        ? true
        : false

export let isEntryCostsChecked = (
    share_being_edited: IDirectShareOwnedItem,
    readOnlyMode?: boolean
): boolean =>
    share_being_edited.active_permission_ids.includes(
        'entry cost attachments'
    ) || share_being_edited.active_permission_ids.includes('entry costs')
        ? true
        : false

export let isEntryCostDisabled = (
    share_being_edited: IDirectShareOwnedItem,
    readOnlyMode?: boolean
): boolean =>
    readOnlyMode ||
    share_being_edited.active_permission_ids.includes('entry cost attachments')
        ? true
        : false

export let isCostAttachmentChecked = (
    share_being_edited: IDirectShareOwnedItem,
    readOnlyMode?: boolean
): boolean =>
    share_being_edited.active_permission_ids.includes('entry cost attachments')
        ? true
        : false
