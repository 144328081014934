import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import SelectSearchBarCarInfoMobile from '../../../organisms/editForms/technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'

const TimezonePicker: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const timeZoneDropdownList = useAppSelector(
        (state) => state.localdata.dropdownData.timeZonesList
    )

    useEffect(() => {
        dispatch(dropDownActions.setTimeZoneDropdownDataRequest())
    }, [])

    const handleChange = (name: string) => {
        dispatch(dropDownActions.setSelectedTimezone(name))

        const params = new URLSearchParams(location.search)
        let returnTo = params.get('return_to')

        navigate(returnTo ?? '/account/preferences')
    }

    const searchOptionsData = {
        text: 'timezone',
        id: 'timezone',
        answer: '',
        unique_answer_id: undefined,
    }

    return (
        <>
            <SelectSearchBarCarInfoMobile
                handleSelectChange={(
                    name: string,
                    other?: string | undefined
                ) => {
                    handleChange(name)
                }}
                items={timeZoneDropdownList ?? []}
                item={searchOptionsData}
                isStandalonePage={true}
                isKeyValue={true}
            />
        </>
    )
}

export default TimezonePicker
