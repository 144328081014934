import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import CreationTopHeaderDesktop from '../../molecules/entryPageTopHeader/creationTopHeaderDesktop'
import PrivateShareFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareFormManagerDesktop'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import PrivateShareFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareFormManagerMobile'
import BottomSheetPage from '../../templates/bottomSheetPages.tsx/bottomSheetPage'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import {
    ICreateDirectShareRequest,
    IDirectShareOwnedItem,
    ISharingPermissionIDS,
} from '../../../redux/entities/cars/types'
import { usersActions } from '../../../redux/user/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'

const GarageShareCreate: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const garage_id = useAppSelector(
        (state) => state.user.userLoggedIn?.owns_garage?.uid
    )
    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )

    const [share_being_edited, setShare_being_edited] =
        useState<IDirectShareOwnedItem>({
            entity_id: '',
            title: '',
            active_permission_ids: ['car gallery'] as any,
            id: 'share_being_edited',
            type: 'garage',
            created_at: '',
            updated_at: '',
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            total_recipients: 0,
        })
    const [isMobileCreationOpen, setIsMobileCreationOpen] = useState(false)

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                '/sharing/with-others/garage/create-share'
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else {
            dispatch(garagesActions.getGarageByUserIdRequest())
        }

        const handleResize = () => {
            setIsMobileCreationOpen(window.innerWidth < 881)
        }

        window.addEventListener('resize', handleResize, { passive: true })
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [userLoggedIn])

    const handleChangePermissions = (permissionID: ISharingPermissionIDS) => {
        setShare_being_edited((prevState) => {
            const permissions_arr = prevState.active_permission_ids

            if (permissions_arr.includes(permissionID)) {
                return {
                    ...prevState,
                    active_permission_ids: permissions_arr.filter(
                        (id) => permissionID !== id
                    ),
                }
            } else {
                return {
                    ...prevState,
                    active_permission_ids: [...permissions_arr, permissionID],
                }
            }
        })
    }

    const handleSubmitToBE = () => {
        if (garage_id) {
            const payload: ICreateDirectShareRequest = {
                resource_id: garage_id,
                permissionIDS: share_being_edited.active_permission_ids,
                title:
                    userLoggedIn && userLoggedIn.display_name
                        ? `${userLoggedIn.display_name}'s garage direct share`
                        : `Untitled garage direct share`,
                resource_type: 'garage',
                share_type: 'direct_share',
            }

            dispatch(garagesActions.createGarageDirectShareRequest(payload))
        }
    }

    return (
        <React.Fragment>
            <DesktopDisplayOnly>
                <CenteredPageWrapper fullwidth>
                    {share_being_edited && (
                        <WindowPageTemplate
                            isCollapsed={isCollapsed}
                            stickyHeaderContent={
                                <CreationTopHeaderDesktop
                                    onClose={() =>
                                        navigate(
                                            '/sharing/with-others?entity_id=garage'
                                        )
                                    }
                                    text={'Add Garage Direct Share'}
                                />
                            }
                        >
                            <PrivateShareFormManagerDesktop
                                share_being_edited={share_being_edited}
                                handleSubmit={handleSubmitToBE}
                                handlePermissions={handleChangePermissions}
                                formType="create"
                                isGarageShare
                            />
                        </WindowPageTemplate>
                    )}
                </CenteredPageWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <BottomSheetPage
                    isOpen={isMobileCreationOpen}
                    toggle={() => setIsMobileCreationOpen(false)}
                >
                    <PrivateShareReturnNav
                        customText="Add garage direct share"
                        chevron
                        linkTo={`/sharing/with-others`}
                        params="entity_id=garage"
                    />
                    <div style={{ paddingTop: '60px' }} />
                    <PrivateShareFormManagerMobile
                        share_being_edited={share_being_edited}
                        handleSubmit={() => {
                            handleSubmitToBE()
                            setIsMobileCreationOpen(false)
                            navigate('/sharing/with-others?entity_id=garage')
                        }}
                        handlePermissions={handleChangePermissions}
                        formType="create"
                        isGarageShare
                    />
                    <div style={{ paddingTop: '150px' }} />
                </BottomSheetPage>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default GarageShareCreate
