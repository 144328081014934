import React from 'react'
import { Sheet } from 'react-modal-sheet'
import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'

type Props = {
    children: React.ReactNode
    onConfirm: () => any
    title: string
    isOpen: boolean
    close: () => any
    customHeight?: string
}

type IStyledProps = {
    color?: string
    $customHeight?: string
    $ipadCustomHeight?: string
}

const CustomSheet = styled(Sheet)<IStyledProps>`
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '50vh !important'};
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;

        @media ${device.ipad} {
            height: ${(props) =>
                props.$ipadCustomHeight
                    ? `${props.$ipadCustomHeight} !important`
                    : props.$customHeight
                    ? `${props.$customHeight} !important`
                    : '70vh !important'};
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .react-modal-sheet-header {
        height: 30px !important;
        align-items: flex-start !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 30px !important;
        margin-top: 10px;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`

const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.001px;
`

const BottomSheetCustomHeader = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 20px;
    /* padding-top: 10px; */
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    margin-bottom: 30px;
`

export const BottomSheetMobileHistoryFileJourney = (props: Props) => {
    return (
        <React.Fragment>
            <CustomSheet
                isOpen={props.isOpen}
                onClose={() => {}}
                $customHeight={props.customHeight}
                $ipadCustomHeight={undefined}
                disableDrag
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <BottomSheetCustomHeader>
                            <Title>{props.title}</Title>
                        </BottomSheetCustomHeader>
                        <div
                            style={{
                                overflowY: 'scroll',
                                overflowX: 'hidden',
                                paddingBottom: 500,
                                paddingTop: 8,
                            }}
                        >
                            {props.children}
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop
                    onTap={(e: any) => {
                        e.preventDefault()
                        props.close()
                    }}
                />
            </CustomSheet>
        </React.Fragment>
    )
}
