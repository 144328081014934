import styled from 'styled-components'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { device } from '../../../templates/displays/devices'
import dayjs from 'dayjs'
import { IHandover } from '../../../../redux/entities/cars/types'

const Title = styled.div`
    font-family: Lato-bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: var(--text-strong, #1a1a1a);

    max-width: 400px;

    @media ${device.mobile_and_ipad} {
        font-size: 24px;
        line-height: 24px;
    }
`

const Subtitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 5e-5em;
    color: #666666;
    max-width: 400px;
`

type Props = {
    cancel: () => any
    onConfirm: () => any
    handover: IHandover
}

const HandoverCancellationModal = (props: Props) => {
    let { handover } = props
    let getName = () => {
        if (handover.new_owner_invite) {
            if (
                handover.new_owner_invite.recipient_given_name ||
                handover.new_owner_invite.recipient_family_name
            ) {
                return `${handover.new_owner_invite.recipient_given_name} ${handover.new_owner_invite.recipient_family_name}`
            } else return handover.new_owner_invite.recipient_email
        } else return handover.new_owner?.display_name
    }
    return (
        <>
            <Title>Are you sure you want to cancel Handover?</Title>
            <div style={{ paddingTop: '20px' }} />
            <Subtitle>
                The Handover request you’ve sent to{' '}
                <span style={{ fontWeight: 600 }}>{getName()}</span> on the{' '}
                {dayjs(handover.created_at).format('DD of MMMM, YYYY')} won’t be
                active anymore if you confirm this action. You will be able to
                handover this car again to the same person or someone else.
            </Subtitle>

            <div style={{ paddingTop: '50px' }} />

            <ButtonAtom
                theme="lowercase-red-background"
                width="100%"
                height="48px"
                textTransform="capitalize"
                dataCyId={'archive-cancel'}
                onClick={() => {
                    props.onConfirm()
                    props.cancel()
                }}
                fontSize={16}
            >
                <div style={{ textTransform: 'capitalize', cursor: 'pointer' }}>
                    Confirm and Cancel Handover
                </div>
            </ButtonAtom>
            <div style={{ paddingTop: '20px' }} />

            <ButtonAtom
                theme="naked-text"
                height="48px"
                dataCyId={'archive-cancel-cancel'}
                icon={''}
                fontSize={16}
                onClick={() => {
                    props.cancel()
                }}
            >
                Cancel
            </ButtonAtom>
        </>
    )
}

export default HandoverCancellationModal
