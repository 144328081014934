import { ThunkAction } from 'redux-thunk'
import {
    IIAllApex_carOfTheWeeks_api,
    IApexCarOfTheWeekState,
    IApexHomepageContentQueryAPI,
    IApexHomepageDataState,
    IAllApex_podcastss_api,
    IApexPodscastState,
    IApexWeeklyNewsHighlightState,
    ITaskNormalisedSingleInterviewItemState,
    IApexSingleInterviewPayloadFromQueryAPI,
    IApexSingleInterviewItemState,
    IApexSingleArticlePayloadFromQueryAPI,
    IApexSingleArticleItemState,
    ITaskNormalisedSingleArticleItemState,
    IApexArticleOverviewItemState,
    IAllApex_articless_overviews_api,
} from 'apexModels'

import { api } from '../../services'
import { ConvertPrismicApexHomepageDataFromAPItoLocalState } from '../../conversions/apex/homepage'
import { ConvertPrismicApexCarOfTheWeekFromAPIToArray } from '../../conversions/apex/carsOfTheWeek'
import { convertPrismicApexPodcastFromAPIToArray } from '../../conversions/apex/apexPodcast'
import { IAllApexWeekly_highlightss_api } from 'apexModels'
import { convertPrismicWeeklyHighlightsFromAPIToArray } from '../../conversions/apex/weeklyHighlights'
import { convertPrismicApexSingleInterviewFromAPIToState } from '../../conversions/apex/apexSingleInterview'
import { convertPrismicApexSingleArticleFromAPIToState } from '../../conversions/apex/apexSingleArticle'
import { convertPrismicArticleOverviewsFromAPI } from '../../conversions/apex/apexArticleOverviews'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { apexActions } from '../reducer'
import { RootAction } from '../../store/types'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'

export const getApexHomepageDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(apexActions.getApexHomepageContentRequest())

            try {
                api.apexQuery.apexHomepageQuery
                    .queryApexHomepageContent()
                    .then((apexData: any) => {
                        if (apexData.data) {
                            let apiRes: IApexHomepageContentQueryAPI =
                                apexData.data

                            if (apiRes) {
                                let res: IApexHomepageDataState =
                                    ConvertPrismicApexHomepageDataFromAPItoLocalState(
                                        apiRes
                                    )

                                dispatch(
                                    apexActions.getApexHomepageContentSuccess(
                                        res
                                    )
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                apexActions.getApexHomepageContentError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)

                            dispatch(
                                apexActions.getApexHomepageContentError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(apexActions.getApexHomepageContentError(typedError))
            }
        })
    }
}

export const getApexCarsOfTheWeekDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(apexActions.getApexCarsOfTheWeekRequest())

            try {
                api.apexQuery.apexCarsoftheWeekQuery
                    .apexCarsoftheWeekQuery()
                    .then((apexData: any) => {
                        // apexData.data is of type IApexCarsOfTheWeekQueryAPI

                        if (apexData.data !== undefined) {
                            let apiRes: IIAllApex_carOfTheWeeks_api =
                                apexData.data.allApex_car_of_the_weeks

                            if (apiRes) {
                                let res: IApexCarOfTheWeekState[] =
                                    ConvertPrismicApexCarOfTheWeekFromAPIToArray(
                                        apiRes
                                    )

                                dispatch(
                                    apexActions.getApexCarsOfTheWeekSuccess(res)
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                apexActions.getApexCarsOfTheWeekError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                apexActions.getApexCarsOfTheWeekError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error: any) {
                console.error(error)
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(apexActions.getApexCarsOfTheWeekError(typedError))
            }
        })
    }
}

export const getApexPodcastsDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(apexActions.getApexPodcastsRequest())

            try {
                api.apexQuery.apexPodcastQuery
                    .apexAllpodcastsQuery()
                    .then((apexData: any) => {
                        // apexData.data is of type IApexPodcastsQueryAPI

                        if (apexData.data !== undefined) {
                            let apiRes: IAllApex_podcastss_api =
                                apexData.data.allApex_podcastss

                            if (apiRes) {
                                let res: IApexPodscastState[] =
                                    convertPrismicApexPodcastFromAPIToArray(
                                        apiRes
                                    )

                                dispatch(
                                    apexActions.getApexPodcastsSuccess(res)
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                apexActions.getApexPodcastsError(customError)
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                apexActions.getApexPodcastsError(typedError)
                            )
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(apexActions.getApexPodcastsError(typedError))
            }
        })
    }
}

export const getWeeklyHighlightsDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(apexActions.getWeeklyHighlightsRequest())

            try {
                api.apexQuery.weeklyHighlightsQuery
                    .allApexWeeklyhighlightsQuery()
                    .then((apexData: any) => {
                        if (apexData.data !== undefined) {
                            let apiRes: IAllApexWeekly_highlightss_api =
                                apexData.data.allApex_weekly_highlightss

                            if (apiRes) {
                                let res: IApexWeeklyNewsHighlightState[] =
                                    convertPrismicWeeklyHighlightsFromAPIToArray(
                                        apiRes
                                    )

                                dispatch(
                                    apexActions.getWeeklyHighlightsSuccess(res)
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                apexActions.getWeeklyHighlightsError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                apexActions.getWeeklyHighlightsError(typedError)
                            )
                        }
                    })
            } catch (error) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(apexActions.getWeeklyHighlightsError(typedError))
            }
        })
    }
}

export const getSingleApexInterviewByUIDDataThunks = (
    uid: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(apexActions.getApexSingleInterviewByUIDRequest())

            try {
                api.apexQuery.apexSingleInterviewQuery
                    .apexSingleInterviewQuery(uid)
                    .then((apexData: any) => {
                        if (apexData.data) {
                            let apiRes: IApexSingleInterviewPayloadFromQueryAPI =
                                apexData.data

                            if (apiRes.allApex_interviewss) {
                                let converted_interview:
                                    | IApexSingleInterviewItemState
                                    | undefined =
                                    convertPrismicApexSingleInterviewFromAPIToState(
                                        apiRes
                                    )

                                if (converted_interview !== undefined) {
                                    let normalised_interview: ITaskNormalisedSingleInterviewItemState =
                                        {}

                                    normalised_interview[uid] =
                                        converted_interview

                                    dispatch(
                                        apexActions.getApexSingleInterviewByUIDSuccess(
                                            normalised_interview
                                        )
                                    )
                                } else
                                    dispatch(
                                        apexActions.getApexSingleInterviewByUIDError(
                                            {
                                                default:
                                                    'error, no interview match this UID',
                                            }
                                        )
                                    )
                            } else
                                dispatch(
                                    apexActions.getApexSingleInterviewByUIDError(
                                        {
                                            default:
                                                'error, no interview match this UID',
                                        }
                                    )
                                )
                        } else
                            dispatch(
                                apexActions.getApexSingleInterviewByUIDError({
                                    default:
                                        'error, no interview match this UID',
                                })
                            )
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                apexActions.getApexSingleInterviewByUIDError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                apexActions.getApexSingleInterviewByUIDError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(
                    apexActions.getApexSingleInterviewByUIDError(typedError)
                )
            }
        })
    }
}

export const getSingleApexArticleByUIDDataThunks = (
    uid: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(apexActions.getApexSingleArticleByUIDRequest())

            try {
                api.apexQuery.apexSingleArticleQuery
                    .apexSingleArticleQuery(uid)
                    .then((apexData: any) => {
                        if (apexData.data) {
                            let apiRes: IApexSingleArticlePayloadFromQueryAPI =
                                apexData.data

                            if (apiRes.allApex_articless) {
                                let converted_article:
                                    | IApexSingleArticleItemState
                                    | undefined =
                                    convertPrismicApexSingleArticleFromAPIToState(
                                        apiRes
                                    )

                                if (converted_article !== undefined) {
                                    let normalised_article: ITaskNormalisedSingleArticleItemState =
                                        {}

                                    normalised_article[uid] = converted_article

                                    dispatch(
                                        apexActions.getApexSingleArticleyUIDSuccess(
                                            normalised_article
                                        )
                                    )
                                } else
                                    dispatch(
                                        apexActions.getApexSingleArticleByUIDError(
                                            {
                                                default:
                                                    'error, no article match this UID',
                                            }
                                        )
                                    )
                            } else
                                dispatch(
                                    apexActions.getApexSingleArticleByUIDError({
                                        default:
                                            'error, no article match this UID',
                                    })
                                )
                        } else
                            dispatch(
                                apexActions.getApexSingleArticleByUIDError({
                                    default: 'error, no article match this UID',
                                })
                            )
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                apexActions.getApexSingleArticleByUIDError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                apexActions.getApexSingleArticleByUIDError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(apexActions.getApexSingleArticleByUIDError(typedError))
            }
        })
    }
}

export const getApexArticleOverviewsDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(apexActions.getApexArticleOverviewsRequest())

            try {
                api.apexQuery.apexArticleOverviewsQuery
                    .apexArticleOverviewsQuery()
                    .then((apexData: any) => {
                        if (apexData.data !== undefined) {
                            let apiRes: IAllApex_articless_overviews_api =
                                apexData.data.allApex_articless

                            if (apiRes) {
                                let res: IApexArticleOverviewItemState[] =
                                    convertPrismicArticleOverviewsFromAPI(
                                        apiRes
                                    )

                                dispatch(
                                    apexActions.getApexArticleOverviewsSuccess(
                                        res
                                    )
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                apexActions.getWeeklyHighlightsError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                apexActions.getWeeklyHighlightsError(typedError)
                            )
                        }
                    })
            } catch (error) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(apexActions.getWeeklyHighlightsError(typedError))
            }
        })
    }
}
