import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../atoms/Inputfield/inputField'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import Expander from '../../../atoms/expander/expander'
import { yupVar_EmailSingleField } from '../yupVars'
import useThemes from '../../../../providers/theme/hooks'

type IStyle = {
    isentry?: boolean
}

const WrapperDesktop = styled.section<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: ${(props) => (props.isentry ? 'block' : 'flex')};
    padding-bottom: 20px;
    padding-top: 20px;
`

const FieldTitle = styled.div`
    width: 30%;
    max-width: 180px;
    font-family: Lato-light;
    align-self: start;
    font-size: 16px;
    text-transform: capitalize;
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: red;
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const InputFieldWrapper = styled.div<IStyle>`
    width: 70%;
    max-width: ${(props) => (props.isentry ? '400px' : '460px')};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

interface Values {
    single_item_edited_value_email_field_desktop: string
}

interface OtherProps {
    data: any
    isentry?: boolean
    dataCyId?: string
    placeholder?: string
    hasError?: (payload: boolean) => void
    isNaked?: boolean
    fontSize?: string
    isDisabled?: boolean
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        data,
        errors,
        setFieldValue,
        isentry,
        hasError,
        isNaked,
        fontSize,
        isDisabled,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let handleAnswerChange = (e: any) => {
        setFieldValue(
            'single_item_edited_value_email_field_desktop',
            e.target.value
        )

        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                e.target.value,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, e.target.value)
        }

        if (errors.single_item_edited_value_email_field_desktop !== undefined) {
            hasError && hasError(true)
        } else {
            hasError && hasError(false)
        }
    }

    const { theme } = useThemes()

    return (
        <WrapperDesktop isentry={isentry}>
            {!isNaked && <FieldTitle>{data.title}</FieldTitle>}

            <InputFieldWrapper isentry={isentry}>
                <InputField
                    theme={theme}
                    id={'single_item_edited_value_email_field_desktop'}
                    name={'single_item_edited_value_email_field_desktop'}
                    placeholder={props.placeholder ? props.placeholder : ''}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.single_item_edited_value_email_field_desktop}
                    font_family="Lato"
                    paddingLeft="0px"
                    width="100%"
                    fontSize={fontSize ? fontSize : '14px'}
                    onChange={handleAnswerChange}
                    textTransform={'lowercase'}
                    dataCyId={props.dataCyId}
                    linecolor="#C7C7C7"
                    type={'email'}
                    disabled={isDisabled ? isDisabled : false}
                />

                <Expander
                    height={
                        errors.single_item_edited_value_email_field_desktop !==
                        undefined
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {
                                errors.single_item_edited_value_email_field_desktop
                            }
                        </Error>
                    </Faded>
                </Expander>
            </InputFieldWrapper>
        </WrapperDesktop>
    )
}

const EmailEditOrCreateModeSingleFieldDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_email_field_desktop: props.data.value_start
            ? props.data.value_start
            : '',
    }),
    enableReinitialize: true,
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_edited_value_email_field_desktop:
                    yupVar_EmailSingleField(props.data),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default EmailEditOrCreateModeSingleFieldDesktop
