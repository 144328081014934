import styled from 'styled-components'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'

const FormBtnsBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 30px;
`

const SideDivider = styled.div`
    padding-left: 26px;
`

type Props = {
    cancel: any
    submit: any
    secondText?: string
    firstText?: string
    isDisabled?: boolean
    delete?: boolean
}

const ButtonsRowDeletionDesktop = (props: Props) => {
    return (
        <FormBtnsBottomRow>
            <ButtonAtom
                onClick={(e: any) => {
                    e.preventDefault()
                    setTimeout(function () {
                        props.cancel()
                    }, 50)
                }}
                theme="lowercase-white-background"
                width="154px"
                height="45px"
            >
                {props.firstText ? props.firstText : 'cancel'}
            </ButtonAtom>
            <SideDivider />
            <ButtonAtom
                width="154px"
                height="45px"
                onClick={props.submit}
                theme={'lowercase-red-background'}
                disabled={props.isDisabled}
            >
                {props.secondText ? props.secondText : 'save'}
            </ButtonAtom>
        </FormBtnsBottomRow>
    )
}

export default ButtonsRowDeletionDesktop
