import React from 'react'
import styled from 'styled-components'
import ListManagerDesktop from '../../../molecules/list/ListManagerDesktop'
import ListManagerMobile from '../../../molecules/list/listManagerMobile'
import SideSlider from '../../animated/sideSlider'
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'
import { device } from '../../displays/devices'
import IpadAndMobileDisplay from '../../displays/ipadAndMobileDisplay'
import FullPageWrapper from '../../displays/pageWrappers/FullPagePageWrapper'
import FabsMenusMobile from '../../menus/fabsMenuMobile'
import { useAppSelector } from '../../../../redux/store/hooks'
import { ICar, IExternalCar } from '../../../../redux/entities/cars/types'
import { IUser } from '../../../../redux/user/types'
import { ITechInfoData } from 'entityModels'

const Padding = styled.div`
    padding-right: 10px;
    padding-left: 10px;
`

const AdjustedPaddingTop = styled.div`
    padding-top: 45px;
    @media ${device.ipad} {
        padding-top: 73px;
    }
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

interface Props {
    sharedCar?: IExternalCar
    prevOwned?: ICar
    userLoggedIn: IUser
}

const ReadOnlyCarTechnicalInformation: React.FC<Props> = ({
    sharedCar,
    prevOwned,
    userLoggedIn,
}) => {
    const { technical_information, list } = useAppSelector((state) => ({
        technical_information:
            state.entities.technicalInformationData.technical_information,
        list: state.entities.technicalInformationData.list,
    }))

    const current: IExternalCar | ICar | undefined = sharedCar
        ? sharedCar
        : prevOwned

    const default_fuel_unit_user: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'].data['fuel_unit'].user_choice_id

    const default_fuel_unit_user_text: string | null =
        default_fuel_unit_user &&
        userLoggedIn &&
        userLoggedIn.preferences.data['units'].data['fuel_unit'].data[
            default_fuel_unit_user
        ].text

    const default_fuel_unit_user_text_short: string | null | undefined =
        default_fuel_unit_user &&
        userLoggedIn &&
        userLoggedIn.preferences.data['units'].data['fuel_unit'].data[
            default_fuel_unit_user
        ].short

    const default_distance_unit_user: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'].data['distance_unit']
            .user_choice_id

    const default_distance_unit_user_text: string | null =
        default_distance_unit_user &&
        userLoggedIn &&
        userLoggedIn.preferences.data['units'].data['distance_unit'].data[
            default_distance_unit_user
        ].text

    const default_distance_unit_user_text_short: string | null | undefined =
        default_distance_unit_user &&
        userLoggedIn &&
        userLoggedIn.preferences.data['units'].data['distance_unit'].data[
            default_distance_unit_user
        ].short

    const currentCarTechInfoId: string | undefined =
        current && current.id && `hf-${current.id}`

    const currentCarTechInfo: ITechInfoData | undefined =
        technical_information &&
        currentCarTechInfoId &&
        technical_information[currentCarTechInfoId]
            ? technical_information[currentCarTechInfoId]
            : undefined

    return (
        <React.Fragment>
            <FabsMenusMobile hasAdd={false} hasSubNav={true} />
            {current && technical_information && currentCarTechInfo && (
                <FullPageWrapper>
                    <DesktopDisplayOnly>
                        {sharedCar && <div style={{ paddingTop: '50px' }} />}
                        <Padding>
                            {currentCarTechInfo !== undefined && (
                                <>
                                    <ListManagerDesktop
                                        readOnlyMode
                                        data={currentCarTechInfo}
                                        list={list}
                                        default_fuel_unit_user_text={
                                            default_fuel_unit_user_text_short
                                                ? default_fuel_unit_user_text_short
                                                : default_fuel_unit_user_text
                                                ? default_fuel_unit_user_text
                                                : undefined
                                        }
                                        default_fuel_unit_user={
                                            default_fuel_unit_user
                                                ? default_fuel_unit_user
                                                : undefined
                                        }
                                        default_distance_unit_user_text={
                                            default_distance_unit_user_text_short
                                                ? default_distance_unit_user_text_short
                                                : default_distance_unit_user_text
                                                ? default_distance_unit_user_text
                                                : undefined
                                        }
                                        default_distance_unit_user={
                                            default_distance_unit_user
                                                ? default_distance_unit_user
                                                : undefined
                                        }
                                    />
                                </>
                            )}
                        </Padding>
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <div
                            style={{
                                zIndex: 3,
                                backgroundColor: 'var(--bg-color, #fff)',
                            }}
                        >
                            <AdjustedPaddingTop />

                            <SideSlider direction="right" initial="enter">
                                {currentCarTechInfo !== undefined && (
                                    <ListManagerMobile
                                        readOnlyMode
                                        data={currentCarTechInfo}
                                        list={list}
                                        default_fuel_unit_user_text={
                                            default_fuel_unit_user_text_short
                                                ? default_fuel_unit_user_text_short
                                                : default_fuel_unit_user_text
                                                ? default_fuel_unit_user_text
                                                : undefined
                                        }
                                        default_fuel_unit_user={
                                            default_fuel_unit_user
                                                ? default_fuel_unit_user
                                                : undefined
                                        }
                                        default_distance_unit_user_text={
                                            default_distance_unit_user_text_short
                                                ? default_distance_unit_user_text_short
                                                : default_distance_unit_user_text
                                                ? default_distance_unit_user_text
                                                : undefined
                                        }
                                        default_distance_unit_user={
                                            default_distance_unit_user
                                                ? default_distance_unit_user
                                                : undefined
                                        }
                                    />
                                )}
                            </SideSlider>
                        </div>
                    </IpadAndMobileDisplay>
                </FullPageWrapper>
            )}
        </React.Fragment>
    )
}

export default ReadOnlyCarTechnicalInformation
