import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { IDropdownItem } from 'entityModels'
import CustomAnimatedCheckboxField from '../../formField/customAnimatedCheckboxField'
// import CustomIconTagQuickFilterItemMobile from '../../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import FilterButton from '../../Button/filterButton'

export type Props = {
    dropdownData: IDropdownItem[]
    dataCyId?: string
    isDisabled?: boolean
    buttonMinWidth?: string
    onSelected?: (value: string) => void
    currentOption?: string | null
    isSort?: boolean
}

type IStyle = {
    selected?: boolean
}

const DropdownOptions = styled.ul`
    position: absolute;
    z-index: 1000;
    margin-top: 10px;
    width: 200px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--off-bg-color, #fafafa);
    border-radius: 10px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 15px;
    box-shadow: 4px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08));
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: transparent;
    padding-left: 20px;
    cursor: pointer;

    :hover {
        background-color: var(--background_neutral_soft);
    }
`

const Wrapper = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    text-transform: capitalize;
`

const getOptionFronmParam = (value: string) =>
    (value.charAt(0).toUpperCase() + value.slice(1)).replace(/_/g, ' ')

const SharesViewMenuDesktop: React.FC<Props> = ({
    dropdownData,
    dataCyId,
    isDisabled,
    buttonMinWidth,
    onSelected,
    currentOption,
    isSort,
}) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [currentSelected, setCurrentSelected] = useState<
        string | undefined | null
    >(
        currentOption
            ? getOptionFronmParam(currentOption)
            : dropdownData
            ? dropdownData[0].name
            : undefined
    )
    const dropdowRef = useRef<HTMLDivElement>(null)
    const _isMounted = useRef(false)

    const handleClickOutsideDropdown = (e: any, ref?: any) => {
        if (ref && ref.contains(e.target) === false && _isMounted.current) {
            setShowDropdown(false)
        }
    }

    useEffect(() => {
        _isMounted.current = true
        dropdowRef.current!.focus()
        let node = dropdowRef.current
        document.addEventListener('mousedown', (e: any) =>
            handleClickOutsideDropdown(e, node)
        )

        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideDropdown
            )
            _isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (currentOption !== currentSelected) {
            setCurrentSelected(currentOption)
        }
    }, [currentOption])

    const handleFilterButtonClick = () => {
        setShowDropdown(!showDropdown)
    }

    const mappedDropdownData =
        dropdownData &&
        dropdownData.map((item: IDropdownItem, index: number) => {
            if (item) {
                return (
                    <StyledOption
                        selected={currentSelected === item.name}
                        value={item.name}
                        key={`${item.name}-${index}-desktop`}
                        aria-label={item.name}
                    >
                        <ContentRow>
                            <CustomAnimatedCheckboxField
                                // textchangedisabled
                                width="100%"
                                onClick={() => {
                                    onSelected && onSelected(item.uid)
                                    setShowDropdown(false)
                                    setCurrentSelected(item.uid)
                                }}
                                checked={
                                    currentSelected?.toLowerCase() ===
                                    item.name.toLowerCase()
                                }
                            >
                                {item.name}
                            </CustomAnimatedCheckboxField>
                        </ContentRow>
                    </StyledOption>
                )
            } else {
                return null
            }
        })

    return (
        <Wrapper data-attr={dataCyId} ref={dropdowRef}>
            <FilterButton
                text={currentSelected ? currentSelected : dropdownData[0].name}
                onClick={isDisabled ? () => {} : handleFilterButtonClick}
                isactive={showDropdown}
                minWidth={buttonMinWidth}
                isDisabled={isDisabled}
                hasChevron={!isSort}
                isSort={isSort}
                capitalize
            />

            <SlideDownWrapper
                isOpen={showDropdown}
                initialX={-200}
                originX={'200px'}
            >
                <DropdownOptions>
                    {dropdownData &&
                        dropdownData.length > 0 &&
                        mappedDropdownData}
                </DropdownOptions>
            </SlideDownWrapper>
        </Wrapper>
    )
}

export default SharesViewMenuDesktop
