import * as React from 'react'
import TrioGridFullpage from '../../molecules/imageGrid/trioGridFullpage'
import Loader from '../../atoms/loader/loader'
import { IRootState } from '../../../redux/store'
import { useAppSelector } from '../../../redux/store/hooks'

export interface IImageProps {
    url: string
}

interface Props {
    ids_list: any
    openImgForm: any
    readOnlyMode?: boolean
}

const EntryGalleryManagerDesktop: React.FC<Props> = ({
    ids_list,
    openImgForm,
    readOnlyMode,
}) => {
    const imagesData = useAppSelector(
        (state: IRootState) => state.entities.galleriesData.images
    )
    const isNecessaryGalleryDataLoading = useAppSelector(
        (state: IRootState) => state.entities.galleriesData.loading
    )

    return (
        <React.Fragment>
            {isNecessaryGalleryDataLoading !== true && imagesData !== null && (
                <TrioGridFullpage
                    images_data_object={imagesData}
                    ids_list={ids_list}
                    openImgForm={openImgForm}
                    readOnlyMode={readOnlyMode ? true : false}
                />
            )}

            {isNecessaryGalleryDataLoading === true && (
                <Loader isLoading={isNecessaryGalleryDataLoading} />
            )}
        </React.Fragment>
    )
}

export default EntryGalleryManagerDesktop
