import { message } from 'antd'
import posthog from 'posthog-js'
import { call, fork, put, take, select } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { ConvertSharePermissionIDsToAPI } from '../../../conversions/shares/ConvertSharePermissionIds'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IRootState } from '../../../store'
import { IApi_UpdateDirectShare_args } from '../../../services/types'
import { IReduxError } from '../../cars/types'
import {
    IUpdateGarageDirectShareRequest,
    IGarageObject,
    IUpdateGarageDirectShareSuccess,
} from '../types'
import { garagesActions } from '../reducer'

const getGarages = (state: IRootState) => state.entities.garagesData.garages

export function* UpdateGarageDirectShareSaga(
    p: IUpdateGarageDirectShareRequest
) {
    try {
        let api_payload: IApi_UpdateDirectShare_args = {
            share_id: p.share_id,
            title: p.title,
            policy_statements: p.policy_statements
                ? ConvertSharePermissionIDsToAPI(p.policy_statements)
                : undefined,
        }
        yield call(api.sharing.updateDirectShareAPI, api_payload)

        let garages_in_state: IGarageObject = yield select(getGarages)

        let current_share_in_state =
            garages_in_state &&
            garages_in_state[p.garage_id] &&
            garages_in_state[p.garage_id].private_shares_owned &&
            garages_in_state[p.garage_id].private_shares_owned
                ?.entity_owned_direct_shares_data &&
            garages_in_state[p.garage_id].private_shares_owned
                ?.entity_owned_direct_shares_data[p.share_id]

        let stored_current_share_in_state = { ...current_share_in_state }

        if (current_share_in_state) {
            let successPayload: IUpdateGarageDirectShareSuccess = {
                garage_id: p.garage_id,
                updated_share: {
                    ...current_share_in_state,
                    title: p.title,
                    active_permission_ids: p.policy_statements
                        ? p.policy_statements
                        : [],
                },
            }

            const info = (messageText: string) => {
                message.info(messageText)
            }

            if (p.title && p.title !== stored_current_share_in_state.title) {
                if (
                    p.policy_statements &&
                    JSON.stringify(p.policy_statements) !==
                        JSON.stringify(
                            stored_current_share_in_state.active_permission_ids
                        )
                ) {
                    info(`Share title and permissions have been updated.`)
                } else {
                    info(`Share title has been updated.`)
                }
            } else if (
                p.policy_statements &&
                JSON.stringify(p.policy_statements) !==
                    JSON.stringify(
                        stored_current_share_in_state.active_permission_ids
                    )
            ) {
                info(`Share permissions have been updated.`)
            }

            yield put(
                garagesActions.updateGarageDirectShareSuccess(successPayload)
            )

            posthog.capture('UPDATE GARAGE DIRECT SHARE')
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: UpdateGarageDirectShareSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(garagesActions.updateGarageDirectShareError(typedError))
            return
        }
    }
}

function* watcherUpdateGarageDirectShare() {
    while (true) {
        let { payload } = yield take(
            garagesActions.updateGarageDirectShareRequest
        )
        yield call(UpdateGarageDirectShareSaga, payload)
    }
}

const update_garage_direct_share_sagas: any[] = [
    fork(watcherUpdateGarageDirectShare),
]

export default update_garage_direct_share_sagas
