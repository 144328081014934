import * as React from 'react'
import styled from 'styled-components'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'

interface IStyle {
    $theme: ITheme
    $iconOnLeft?: boolean | undefined
    $isUppercase?: boolean | undefined
    $width?: string | undefined
    $nobg?: boolean
    disabled?: boolean
}

const Button = styled.button<IStyle>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;
    border: transparent;
    outline: none;
    background-color: ${(props) =>
        props.disabled
            ? colours[props.$theme].background_neutral_soft
            : props.$nobg
            ? 'transparent'
            : colours[props.$theme].background_primary_soft};
    ${(props) => props.$nobg && 'font-style: italic;'}
    border-radius: 5px;
    box-shadow: none;
    transition: all 200ms;
    cursor: pointer;

    :hover {
        box-shadow: ${(props) =>
            `2px 2px 4px ${colours[props.$theme].shadow_strongest_03}`};
        transition: all 200ms;
    }
`

const Text = styled.div<IStyle>`
    color: ${(props) =>
        props.disabled
            ? colours[props.$theme].text_default
            : colours[props.$theme].primary};
    font-size: 12px;
    font-family: Lato;
    letter-spacing: 0.7px;
    text-transform: ${(props) =>
        props.$isUppercase ? 'uppercase' : 'capitalize'};
`

type Props = {
    onClick: any
    text: string | undefined
    dataCyId?: string | undefined
    isUppercase?: boolean
    width?: string | undefined
    nobg?: boolean
    disabled?: boolean
    type?: 'submit' | 'reset' | 'button' | undefined
}

const FullWidthLightGreyBgButton: React.FunctionComponent<Props> = (
    props: Props
) => {
    let { onClick, text, isUppercase, width, dataCyId, nobg, disabled, type } =
        props

    const { theme } = useThemes()
    return (
        <Button
            $theme={theme}
            $width={width}
            onClick={disabled ? () => {} : onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            $nobg={nobg}
            disabled={disabled}
            type={type ? type : 'button'}
        >
            <Text $theme={theme} $isUppercase={isUppercase} disabled={disabled}>
                {text}
            </Text>
        </Button>
    )
}

export default FullWidthLightGreyBgButton
