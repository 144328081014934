import * as React from 'react'
import { useState, useEffect } from 'react'
import TimelineGenerateEditFormModeDesktop from '../timelineFormGenerators/timelineGenerateEditFormModeDesktop'
import { ITimelineEditForms } from '../../../../redux/editForms/types'
import { ITimelineItem, ICostItem } from '../../../../redux/timeline/types'

interface Props {
    timeline_item?: ITimelineItem | undefined | null
    item_to_edit: ITimelineItem | ICostItem | null
    timelineItemEditForm: ITimelineEditForms
    savingEdit: any
    onDeleteClick?: () => void
    closeForm: () => void
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    readOnlyMode?: boolean
}

const TimelineEditManagerDesktop = (props: Props) => {
    let {
        timeline_item,
        item_to_edit,
        timelineItemEditForm,
        savingEdit,
        onDeleteClick,
        closeForm,
        dataCyId,
        entityID,
        carID,
        entityType,
        readOnlyMode,
    } = props

    const [edited_item, setEditedItem] = useState<
        ITimelineItem | ICostItem | null
    >(null)

    const editNewItem = (
        fieldID: keyof ITimelineItem | keyof ICostItem,
        newValue: any
    ) => {
        let updated_item: ITimelineItem | ICostItem | null =
            edited_item !== null
                ? Object.assign({}, edited_item)
                : Object.assign({}, timeline_item)

        if (updated_item !== null) {
            if (entityType === 'cost' && fieldID === 'attachment') {
                if (newValue === null) {
                    updated_item.attachmentID = undefined
                } else {
                    updated_item.attachmentID = newValue.id
                    updated_item.attachment = newValue
                }
            } else {
                updated_item = { ...updated_item, [fieldID]: newValue }
            }

            setEditedItem(updated_item)
        }
    }

    useEffect(() => {
        if (timeline_item) {
            setEditedItem(item_to_edit)
        }

        return () => {
            setEditedItem(null)
        }
    }, [timeline_item])

    return (
        <React.Fragment>
            {item_to_edit && timelineItemEditForm.id && (
                <TimelineGenerateEditFormModeDesktop
                    item_to_edit={item_to_edit}
                    timelineItemEditForm={timelineItemEditForm}
                    edited_item={edited_item}
                    onChange={editNewItem}
                    onClickSave={() => savingEdit(edited_item)}
                    onDeleteClick={onDeleteClick}
                    closeForm={closeForm}
                    dataCyId={dataCyId}
                    entityID={entityID}
                    carID={carID}
                    entityType={entityType}
                    readOnlyMode={readOnlyMode}
                />
            )}
        </React.Fragment>
    )
}

export default TimelineEditManagerDesktop
