import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowDesktop from '../../buttons/buttonsStandardBottomRowDesktop'
import { DatePickerConverterFormat } from '../../../../../redux/conversions/time'
import warning from '../../../../../public/assets/icons/round_warning_thick.svg'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../providers/theme/hooks'

const SmallPadding = styled.div`
    padding-top: 40px;
`

const Warning = styled.div`
    font-family: Lato;
    font-size: 14px;
    color: var(--error, #df6f6f);
    font-style: italic;
    max-width: 440px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 16px;
`

interface Values {
    single_item_edited_value_task_datePickerDesktop: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: any
    taskid: string
    showReminderResetWarning?: boolean
    minDate?: string
    timezone?: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        errors,
        setFieldValue,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        minDate,
        // timezone,
    } = props

    let checkIfDisabled = () => {
        if (!values.single_item_edited_value_task_datePickerDesktop) {
            return true
        } else if (
            errors.single_item_edited_value_task_datePickerDesktop !== undefined
        ) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop style={{ maxWidth: 550 }} $theme={theme}>
            <ModalInnerContentWrapperDesktop>
                <>
                    <ModalTitle $theme={theme} style={{ marginBottom: 0 }}>
                        {item.text}
                    </ModalTitle>

                    {/* {timezone && item.id === 'due' && (
                    <CurrentTimezone>
                        Your current timezone is set for{' '}
                        <strong>{timezone}</strong>. You can change it{' '}
                        <Link
                            to={'/account/preferences'}
                            style={{ color: '#5EC3CA' }}
                        >
                            in your preferences page.
                        </Link>
                    </CurrentTimezone>
                )} */}
                    {props.showReminderResetWarning === true && (
                        <>
                            <Row>
                                <img
                                    src={warning}
                                    alt="warning"
                                    style={{
                                        marginRight: '7px',
                                        width: '22px',
                                    }}
                                />
                                <Warning>
                                    Changing the due date will cancel all
                                    existing reminders for your Task.
                                </Warning>
                            </Row>
                        </>
                    )}

                    <div style={{ paddingTop: '16px' }} />

                    <InputField
                        theme={theme}
                        id="single_item_edited_value_task_datePickerDesktop"
                        name="single_item_edited_value_task_datePickerDesktop"
                        placeholder={item.text}
                        customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={
                            values.single_item_edited_value_task_datePickerDesktop
                        }
                        onChange={(date: string) => {
                            setFieldValue(
                                'single_item_edited_value_task_datePickerDesktop',
                                date
                            )
                        }}
                        font_family="Lato"
                        type="date"
                        onEnterKeyPress={props.handleSubmit}
                        min={minDate}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                </>
                <SmallPadding />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={props.handleSubmit}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const DatePickerTaskDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_task_datePickerDesktop:
            props.item.answer && props.item.answer !== '-'
                ? DatePickerConverterFormat(props.item.answer)
                : '',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_edited_value_task_datePickerDesktop: Yup.date(),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        if (values.single_item_edited_value_task_datePickerDesktop) {
            // let res = dayjs(
            //     values.single_item_edited_value_task_datePickerDesktop
            // ).toISOString()

            let res = values.single_item_edited_value_task_datePickerDesktop

            setSubmitting(false)
            props.submitFunc &&
                props.submitFunc({
                    taskid: props.taskid,
                    dataList: [
                        {
                            id: props.item.id,
                            text: props.item.text,
                            answer: res,
                        },
                    ],
                })
            props.toggle()
        }
    },
})(InnerForm)

export default DatePickerTaskDesktop
