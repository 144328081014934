import { removeUndefinedfromObject } from '../../../helpers/objects'
import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'
import { IApiEntryCostCREATE_args } from '../../types'

export const createEntryCost = (p: IApiEntryCostCREATE_args): Promise<any> => {
    let newCostDataNoUndefined = removeUndefinedfromObject(p.cost)

    if (newCostDataNoUndefined && newCostDataNoUndefined.amount) {
        newCostDataNoUndefined.amount = Math.floor(
            newCostDataNoUndefined.amount
        ).toString()
    }

    let newCostDataFinal: string = JSON.stringify(newCostDataNoUndefined)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: newCostDataFinal,
        credentials: 'include',
    }

    return fetch(
        urls.add_cost_to_entry_endpoint(p.itemid, p.carid),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Create cost entry error',
                        newCostDataFinal
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
