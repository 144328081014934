import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (min-width: 500px) {
        justify-content: center;
        align-items: center;
    }
`

const Title = styled.div`
    font-family: Lato-Light;
    font-size: 20px;
    color: var(--primary, #5ec3ca);
    text-transform: uppercase;
    padding-bottom: 10px;
    text-align: left;
    @media (min-width: 500px) {
        text-align: center;
    }
`

const Text = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Light;
    font-size: 16px;
    padding-bottom: 24px;
    text-align: left;
    max-width: 400px;

    @media (min-width: 500px) {
        text-align: center;
        padding-bottom: 30px;
    }
`

const Line = styled.div`
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
    width: 40px;
`

type Props = {
    title: string
    text: string
}

const ApexPageHeaderMobile: React.FC<Props> = ({ title, text }) => {
    return (
        <Container>
            <Wrapper>
                <Title>{title}</Title>
                <Text>{text}</Text>
                <Line />
            </Wrapper>
        </Container>
    )
}

export default ApexPageHeaderMobile
