import React, { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../../redux/store/hooks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import ReadOnlyCarHistoryFileEntryGalleryImage from '../../../templates/readOnly/car/readOnlyHistoryFileEntryGalleryImage'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { usersActions } from '../../../../redux/user/reducer'

const ExternalCarSingleEntryGalleryImage: React.FC = () => {
    const dispatch = useAppDispatch()
    const { carid, entryid } = useParams<{ carid: string; entryid: string }>()
    const [searchParams] = useSearchParams()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const externalCarData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )

    useEffect(() => {
        if (carid && entryid) {
            dispatch(carActions.setCurrentExternalCarSuccess(carid))

            if (!userLoggedIn) {
                dispatch(usersActions.getCurrentUserDataRequest())
            }

            const image_id_param = searchParams.get('id')

            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/shared/car/${carid}/history-file/${entryid}/gallery?id=${image_id_param}`
                )
            )

            dispatch(carActions.getExternalCarsSharedWithYouRequest(carid))
        }
    }, [carid, entryid, userLoggedIn, searchParams])

    const currentExternalCar =
        externalCarData && carid ? externalCarData[carid] : undefined

    return (
        <ReadOnlyCarHistoryFileEntryGalleryImage
            sharedCar={currentExternalCar}
            userLoggedIn={userLoggedIn}
        />
    )
}

export default ExternalCarSingleEntryGalleryImage
