import { call, fork, take, put } from 'redux-saga/effects'
import {
    ConverFileTypeToFileSuffixEnum,
    ConvertAttachmentAPIToAttachmentState,
} from '../../conversions/attachments'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { putToS3 } from './addAttachmentToEntry'
import { getPreSignedUrlCostAttachment } from '../../filestorage/actions/sagas/presignedUrlCostAttachments'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { timelineActions } from '../reducer'
import { attachmentActions } from '../../attachments/reducer'

import { addCostAttachmentApi } from '../../services/timeline/costs/addCostAttachment'
import {
    IAttachmentAPI,
    IAttachmentRequestPayload,
} from '../../attachments/types'
import {
    IUploadAttachmentPreSignedUrlPayloadSingle,
    IUploadImagePreSignedUrlPayloadSingle,
} from '../../filestorage/types'
import { IApiAddCostAttachmentPayload } from '../../services/types'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'

export function* UploadCostAttachmentToAwsSaga(
    payload: IAddAttachmentToCostSubSaga
): any {
    try {
        yield call(putToS3, payload.fileData.file, payload.presigned_url)

        yield call(AddAttachmentToCostAPISaga, payload)
    } catch (e: any) {
        let customErrorData: ICustomErrorData = {
            custom_message: `Something went wrong while uploading the attachment, please try adding it again.`,
            custom_user_action_text: 'Return',
            custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`, //pathname to entry page possibly
        }
        let customError: IReduxError = ConvertToReduxError(e, customErrorData)
        yield put(timelineActions.addAttachmentToCostError(customError))
    }
}

export function* AddAttachmentToCostAPISaga(
    payload: IAddAttachmentToCostSubSaga
): any {
    try {
        let p: IApiAddCostAttachmentPayload = {
            attachment: payload.attachmentApi,
            carID: payload.carID,
            entryUID: payload.entryID,
            costID: payload.costID,
        }
        let at: IAttachmentAPI = yield call(addCostAttachmentApi, p)
        let converted_at = ConvertAttachmentAPIToAttachmentState(at)

        yield put(
            timelineActions.addAttachmentToCostSuccess({
                cost_id: payload.costID,
                att_id: converted_at.id,
            })
        )

        yield put(
            attachmentActions.addAttachmentsSuccess({
                [converted_at.id]: converted_at,
            })
        )
    } catch (e: any) {
        if (e.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: AddAttachmentToCostAPISaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong while uploading the attachment, please try adding it again.`,
                custom_user_action_text: 'Return',
                custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`, //pathname to entry page possibly
            }
            let customError: IReduxError = ConvertToReduxError(
                e,
                customErrorData
            )
            yield put(timelineActions.addAttachmentToCostError(customError))
        }
    }
}

export type IAddAttachmentToCostSaga = {
    fileData: IAttachmentRequestPayload
    entryID: string
    costID: string
    carID: string
}

export type IAddAttachmentToCostSubSaga = {
    fileData: IAttachmentRequestPayload
    presigned_url: string
    attachmentApi: IAttachmentAPI
    entryID: string
    carID: string
    costID: string
}

export function* AddAttachmentToCostSaga(
    payload: IAddAttachmentToCostSaga
): any {
    let ats: IAttachmentAPI = {
        uid: '',
        filename: undefined,
        title: payload.fileData.filename,
        presigned_download_url: undefined,
    }

    let suff = ConverFileTypeToFileSuffixEnum(payload.fileData.filename)

    if (suff) {
        let p2: IUploadAttachmentPreSignedUrlPayloadSingle = {
            fileSuffix: suff,
            entityID: payload.costID,
            carID: payload.carID,
        }

        let data: IUploadImagePreSignedUrlPayloadSingle = yield call(
            getPreSignedUrlCostAttachment,
            p2
        )

        if (data) {
            ats.filename = data.filename
            ats.presigned_download_url = data.url

            let p: IAddAttachmentToCostSubSaga = {
                fileData: payload.fileData,
                presigned_url: data.url,
                attachmentApi: ats,
                carID: payload.carID,
                entryID: payload.entryID,
                costID: payload.costID,
            }

            yield call(UploadCostAttachmentToAwsSaga, p)
        }
    } else {
        let customErrorData: ICustomErrorData = {
            custom_message: `Something went wrong with the file you tried to upload: the file extension seems wrong.`,
            custom_user_action_text: 'Return',
            custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`, //pathname to entry page possibly
        }

        let erro: IReduxError = {
            default: undefined,
            custom: customErrorData,
        }

        yield put(timelineActions.addAttachmentToCostError(erro))
    }
}

function* watcherAddAttachmentToCost() {
    while (true) {
        const { payload } = yield take(
            timelineActions.addAttachmentToCostRequest
        )

        yield call(AddAttachmentToCostSaga, payload)
    }
}

const add_attachment_to_cost: any[] = [fork(watcherAddAttachmentToCost)]

export default add_attachment_to_cost
