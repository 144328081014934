import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { MenuDots } from '../../statefulicons'
import Faded from '../../../templates/animated/faded'
import { Tooltip } from 'antd'
import { useState, useRef, useEffect } from 'react'

const Wrapper = styled.div`
    position: relative;
    z-index: 10;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
`

type IStyle = {
    $isDisabled: boolean | undefined
    $isActive?: boolean
    $customColour?: string
}

export const OptionsMenuItem = styled.div<IStyle>`
    position: relative;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 4px;
    cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
    width: 100%;
    box-sizing: border-box;

    color: ${(props) =>
        props.$isDisabled
            ? 'var(--text-disabled) !important'
            : props.$customColour ?? 'var(--text-default, #666666)'};

    background-color: ${(props) =>
        props.$isActive
            ? 'var(--base-overlays-neutral-alpha-50, rgba(26, 26, 26, 0.02))'
            : 'transparent'};

    :hover {
        transition: all 100ms;
        background-color: ${(props) =>
            props.$isDisabled
                ? 'transparent'
                : 'var(--base-overlays-neutral-alpha-100, rgba(26, 26, 26, 0.04)) !important'};

        color: ${(props) =>
            props.$isDisabled
                ? 'var(--text-disabled) !important'
                : 'inherited'};
    }
`

type IstyledProps = {
    direction?: 'top' | 'bottom'
}

const MenuBox = styled.div<IstyledProps>`
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: ${(props: IstyledProps) =>
        props.direction === 'bottom' ? 'auto' : '50px'};

    top: ${(props: IstyledProps) =>
        props.direction === 'bottom' ? '60px' : 'auto'};
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    font-family: Lato;
    font-size: 16px;
    /* Base/White */

    background: var(--modal-side-sheet-pop-over-bg, #fff);
    /* 24 */

    box-shadow: 0px 4px 24px var(--box-shadow, rgba(0, 0, 0, 0.08));
    border-radius: 4px;

    height: auto;
    width: max-content;

    @media ${device.smallest_laptops} {
    }
    @media ${device.ipad} {
    }

    :hover {
        color: var(--primary, #5ec3ca);
        transition: all 100ms;
    }
`

export type IThreeDotsActionsItems = {
    id?: string
    text: string
    url?: string
    actionOnClick?: any
    color?: string
    idRequired?: boolean
    isDisabled?: boolean
    disabledTxt?: string
    hide_item?: boolean
    isLoading?: boolean
    icon?: React.ReactNode
}

interface Props {
    actions_items: IThreeDotsActionsItems[]
    menuId: string
    manageMenus?: any
    menuOpened: string | null
    customWidth?: string
    customHeight?: string
    direction?: 'top' | 'bottom'
    active_item?: IThreeDotsActionsItems
    customButton?: React.ReactNode
}

const ActionsMenuDots: React.FC<Props> = ({
    actions_items,
    menuId,
    manageMenus,
    menuOpened,
    customWidth,
    customHeight,
    direction,
    active_item,
    customButton,
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)
    const dropdowRef = useRef<HTMLDivElement>(null)
    const _isMounted = useRef(false)

    useEffect(() => {
        _isMounted.current = true
        dropdowRef.current!.focus()
        const handleClickOutsideDropdown = (e: any) => {
            if (
                dropdowRef.current &&
                !dropdowRef.current.contains(e.target) &&
                _isMounted.current
            ) {
                manageMenus && manageMenus(undefined)
            }
        }
        document.addEventListener('mousedown', handleClickOutsideDropdown)
        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideDropdown
            )
            _isMounted.current = false
        }
    }, [manageMenus])

    useEffect(() => {
        if (isTooltipVisible) {
            const timer = setTimeout(() => setIsTooltipVisible(false), 2000)
            return () => clearTimeout(timer)
        }
    }, [isTooltipVisible])

    const status = menuOpened === menuId

    const renderItem = (item: IThreeDotsActionsItems, index: number) => (
        <OptionsMenuItem
            key={index}
            $isDisabled={item.isDisabled}
            onClick={(e: any) => {
                e.preventDefault()
                if (!item.isDisabled) {
                    if (!item.idRequired) {
                        item.actionOnClick
                            ? item.actionOnClick(item.text)
                            : setIsTooltipVisible(true)
                    } else if (item.idRequired) {
                        item.actionOnClick
                            ? item.actionOnClick(item.text)
                            : setIsTooltipVisible(true)
                    } else {
                        setIsTooltipVisible(true)
                    }
                }
            }}
            $isActive={item.text === active_item?.text}
            $customColour={item.color}
        >
            {item.icon ?? null}
            {item.text}
        </OptionsMenuItem>
    )

    return (
        <React.Fragment>
            <Wrapper ref={dropdowRef} id={'id_of_wrapper'}>
                {customButton ?? (
                    <MenuDots
                        onClick={(e: any) => {
                            e.preventDefault()
                            manageMenus && manageMenus(menuId)
                        }}
                        isOpen={status}
                        width={customWidth ?? '30px'}
                        customHeight={customHeight ?? undefined}
                    />
                )}
                {status && (
                    <Faded>
                        <MenuBox direction={direction}>
                            {actions_items.map((item, index) => {
                                if (item.isDisabled && item.disabledTxt) {
                                    return (
                                        <React.Fragment
                                            key={`actions_menu_${index}_with_tooltip`}
                                        >
                                            <Tooltip
                                                title={item.disabledTxt}
                                                placement="left"
                                                color={'var(--error, #df6f6f)'}
                                            >
                                                {renderItem(item, index)}
                                            </Tooltip>
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <React.Fragment
                                            key={`${index}_img_menu_item`}
                                        >
                                            {renderItem(item, index)}
                                        </React.Fragment>
                                    )
                                }
                            })}
                        </MenuBox>
                    </Faded>
                )}
            </Wrapper>
        </React.Fragment>
    )
}

export default ActionsMenuDots
