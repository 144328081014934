import * as React from 'react'
import styled from 'styled-components'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { device } from '../../templates/displays/devices'

import DraggableItemEditable from '../../atoms/draggable/draggableItemEditable'
import { IHighlightedFact } from 'entityModels'

const ListContainer = styled.ul`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: disc;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    overflow-y: auto;
    @media ${device.mobile} {
        overflow-y: scroll;
    }

    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style-type: disc;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
    }
`

type Props = {
    data_object: IObject
    ids_list: string[]
    formikprops: any
    giveNewArrayOrder?: (items: IHighlightedFact[]) => void
}

interface IObject {
    [key: string]: IHighlightedFact
}

const sortList = (list: IHighlightedFact[]) => {
    return list.sort(
        (a: IHighlightedFact, b: IHighlightedFact) => a.order - b.order
    )
}

const DragNSortEditable: React.FC<Props> = ({
    data_object,
    ids_list,
    formikprops,
    giveNewArrayOrder,
}) => {
    const [items, setItems] = React.useState<IHighlightedFact[]>([])

    React.useEffect(() => {
        let list = ids_list.map((id: string) => data_object[id])
        let ordered_list = sortList(list)
        setItems(ordered_list)
    }, [data_object, ids_list])

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (event: any) => {
        const { active, over } = event

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex(
                    (item) => item.id === active.id
                )
                const newIndex = items.findIndex((item) => item.id === over.id)

                const newItems = arrayMove(items, oldIndex, newIndex)
                giveNewArrayOrder && giveNewArrayOrder(newItems)
                return newItems
            })
        }
    }

    return (
        <React.Fragment>
            {items.length > 1 && (
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={items.map((item) => item.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        <ListContainer>
                            {items.map(
                                (item: IHighlightedFact, index: number) => (
                                    <React.Fragment
                                        key={`${item?.id}_${index}_drag_n_sort_desktop`}
                                    >
                                        <DraggableItemEditable
                                            formikprops={formikprops}
                                            index={index}
                                            item={item}
                                        />
                                    </React.Fragment>
                                )
                            )}
                        </ListContainer>
                    </SortableContext>
                </DndContext>
            )}
        </React.Fragment>
    )
}

export default DragNSortEditable
