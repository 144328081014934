import styled from 'styled-components'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import { device } from '../../templates/displays/devices'

const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 100%;
    box-sizing: border-box;

    @media ${device.ipad} {
        max-width: 420px;
    }
`

const Title = styled.div`
    font-size: 24px;
    font-family: Lato-Semibold;
    color: var(--text-strong, #1a1a1a);
`

const SmallPadding = styled.div`
    padding-top: 16px;
`

const LargePadding = styled.div`
    padding-top: 48px;
`

export const Error = styled.div`
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 11px;
`

const Content = styled.div`
    text-align: center;
    font-size: 16px;
    font-family: Lato;
    color: var(--text-default, #666);
`

const SecondaryBtn = styled.button`
    width: 100%;
    box-sizing: border-box;
    all: unset;
    cursor: pointer;
    font-size: 16px;
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
`

interface Props {
    title: string
    text: string
    main_btn_text: string
    secondary_btn_text?: string
    secondary_btn_color?: string
    onClick: () => void
    onSecondaryBtnClick?: () => void
}
const ErrorCardMobile: React.FunctionComponent<Props> = (props) => {
    const {
        title,
        text,
        main_btn_text,
        secondary_btn_text,
        secondary_btn_color,
        onSecondaryBtnClick,
        onClick,
    } = props

    return (
        <ModalWrapper>
            <Title>{title}</Title>
            <SmallPadding />
            <Content>{text}</Content>
            <LargePadding />
            <ButtonAtom
                theme="modal-action-button-primary"
                width="100%"
                height="48px"
                textTransform="none"
                onClick={onClick}
                fontSize={16}
            >
                {main_btn_text}
            </ButtonAtom>
            {secondary_btn_text && onSecondaryBtnClick && (
                <>
                    <SmallPadding />
                    <SecondaryBtn
                        style={{
                            color: secondary_btn_color
                                ? secondary_btn_color
                                : '#5ec3ca',
                        }}
                        onClick={onSecondaryBtnClick}
                    >
                        {secondary_btn_text}
                    </SecondaryBtn>
                </>
            )}
        </ModalWrapper>
    )
}
export default ErrorCardMobile
