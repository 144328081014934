import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import SingleCarShareBodyDesktop from '../../molecules/singleCarShareBody/singleCarShareBodyDesktop'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import SingleCarShareBodyMobile from '../../molecules/singleCarShareBody/singleCarShareBodyMobile'
import * as ReactScroll from 'react-scroll'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import { editFormsActions } from '../../../redux/editForms/reducer'
import QrCodeModalDesktop from '../../molecules/qrCodeModal/qrCodeModalDesktop'
import ModalDisplayDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayDesktopExclusive'
import Faded from '../../templates/animated/faded'
import QrCodeCustomiseStickerModalDesktop from '../../molecules/qrCodeCustomiseStickerModal/qrCodeCustomiseStickerModalDesktop'
import QrCodeStickerBottomSheetMobile from '../../molecules/qrCodeCustomiseStickerModal/qrCodeStickerBottomSheetMobile'
import QrCodeModalMobile from '../../molecules/qrCodeModal/qrCodeModalMobile'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import {
    useAppDispatch,
    useAppSelector,
    useThunkAppDispatch,
} from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { ICar } from '../../../redux/entities/cars/types'
import {
    IDeleteShareReq,
    IFilterCarSharesDisplay_change_request,
    ISearchQueryCarSharesDisplay_change_request,
    ISortCarSharesDisplay_change_request,
} from '../../../redux/entities/cars/actions'

const CarSharing: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const carsData = useAppSelector((state) => state.entities.carsData.cars)

    const qrCodeModal = useAppSelector(
        (state) => state.editForms?.formsData.qrCodeModal
    )
    const garagesData = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    const qrCodeStickerModal = useAppSelector(
        (state) => state.editForms?.formsData.qrCodeStickerModal
    )
    const predefinedQrCodeStickerThemes = useAppSelector(
        (state) => state.localdata.stickerPredefinedThemes
    )

    const areCarSharesLoading: boolean = useAppSelector(
        (state) => state.entities.carsData.carSharesLoading
    )

    const thunksDispatch = useThunkAppDispatch()

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(`/car/${carid}/sharing`)
        )

        const userData = userLoggedIn
        const garageid = userData?.owns_garage?.uid

        if (!userData) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else {
            thunksDispatch(carActions.setCurrentCar(`${carid}`))
            dispatch(carActions.getOwnedDirectSharesCarRequest(`${carid}`))

            if (!carsData[`${carid}`]) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            }

            if (
                userData &&
                garageid &&
                (!garagesData[garageid] ||
                    (!garagesData[garageid].public_share_owned &&
                        garagesData[garageid].public_share_owned !== null))
            ) {
                dispatch(garagesActions.getPublicSharesGarageRequest(garageid))
            }
        }

        const params = new URLSearchParams(location.search)
        const id = params.get('id')

        if (id === 'car_private_m') {
            scrollToElement('car_privately_shared_mobile')
        }

        if (id === 'car_private_d') {
            scrollToElement('car_privately_shared_desktop')
        }

        const qr_id = params.get('qr_id')
        const openCarQrCodeModal = () =>
            dispatch(
                editFormsActions.toggleQrCodeModal({
                    isOpen: true,
                    id: 'car_only',
                })
            )
        const openGarageQrCodeModal = () =>
            dispatch(
                editFormsActions.toggleQrCodeModal({
                    isOpen: true,
                    id: 'car_via_garage',
                })
            )
        if (qr_id === 'car_only') {
            setTimeout(openCarQrCodeModal, 200)
        } else if (qr_id === 'car_via_garage') {
            setTimeout(openGarageQrCodeModal, 200)
        }
    }, [carid, userLoggedIn, location.search])

    const scrollToElement = (elementName: string) => {
        ReactScroll.scroller.scrollTo(elementName, {
            duration: 500,
            delay: 300,
            smooth: true,
            offset: -70,
        })
    }

    const current: ICar | undefined =
        carsData && carid && carsData[carid] ? carsData[carid] : undefined

    const car_only_qr_code: string | undefined =
        current?.public_share_owned?.qr_code_url
    const car_via_garage_qr_code: string | undefined =
        current?.public_share_owned?.qr_code_url_via_garage

    if (current?.archived_on) {
        navigate(`/archives/car/${current.id}/overview`)
        return null
    }

    return (
        <React.Fragment>
            <FabsMenusMobile hasAdd={false} hasSubNav={true} />
            <DesktopDisplayOnly>
                <FullPagePageWrapper>
                    <div style={{ paddingLeft: '50px', paddingTop: '20px' }}>
                        {current && userLoggedIn && garagesData && (
                            <SingleCarShareBodyDesktop
                                handleChange_shares_search={(
                                    p: ISearchQueryCarSharesDisplay_change_request
                                ) =>
                                    dispatch(
                                        carActions.searchQueryCarSharesDisplay_change_request(
                                            p
                                        )
                                    )
                                }
                                handleChange_shares_sort={(
                                    p: ISortCarSharesDisplay_change_request
                                ) =>
                                    dispatch(
                                        carActions.sortCarSharesDisplay_change_request(
                                            p
                                        )
                                    )
                                }
                                handleChange_shares_filter={(
                                    p: IFilterCarSharesDisplay_change_request
                                ) =>
                                    dispatch(
                                        carActions.filterCarSharesDisplay_change_request(
                                            p
                                        )
                                    )
                                }
                                car={current}
                                resetSearch={(p: string) =>
                                    dispatch(
                                        carActions.resetSearchQueryCarShareDisplay_request(
                                            p
                                        )
                                    )
                                }
                                garage={
                                    garagesData[userLoggedIn.owns_garage.uid]
                                }
                                createCarPublicShare={(p: string) =>
                                    dispatch(
                                        carActions.createCarPublicShareRequest(
                                            p
                                        )
                                    )
                                }
                                deletePublicLink={(p: IDeleteShareReq) =>
                                    dispatch(
                                        carActions.deleteCarPublicShareRequest(
                                            p
                                        )
                                    )
                                }
                                openQrCodeModal={(qr_id: string) =>
                                    dispatch(
                                        editFormsActions.toggleQrCodeModal({
                                            isOpen: true,
                                            id: qr_id,
                                        })
                                    )
                                }
                            />
                        )}
                    </div>
                    {qrCodeModal.isOpen &&
                        (car_via_garage_qr_code || car_only_qr_code) && (
                            <Faded>
                                <ModalDisplayDesktopExclusive
                                    isOpen={qrCodeModal.isOpen}
                                    toggle={() => {}}
                                    modalBackgroundColor="rgba(0, 0, 0, 0.65)"
                                >
                                    <div style={{ marginTop: '20vh' }} />
                                    <QrCodeModalDesktop
                                        qr_code_link={
                                            qrCodeModal.id === 'car_via_garage'
                                                ? car_via_garage_qr_code || ''
                                                : car_only_qr_code || ''
                                        }
                                        qr_code_id={
                                            'car_qr_code_to_export_desktop'
                                        }
                                        car_title={current?.title || 'Untitled'}
                                        closeModal={() =>
                                            dispatch(
                                                editFormsActions.toggleQrCodeModal(
                                                    {
                                                        isOpen: false,
                                                        id: undefined,
                                                    }
                                                )
                                            )
                                        }
                                        isGarage={
                                            qrCodeModal.id === 'car_via_garage'
                                        }
                                        onStickerBtnClick={() =>
                                            dispatch(
                                                editFormsActions.toggleQrCodeStickerModal(
                                                    {
                                                        isOpen: true,
                                                        id: qrCodeModal.id,
                                                    }
                                                )
                                            )
                                        }
                                    />
                                </ModalDisplayDesktopExclusive>
                                {qrCodeStickerModal.isOpen &&
                                    (car_via_garage_qr_code !== undefined ||
                                        car_only_qr_code !== undefined) && (
                                        <Faded>
                                            <ModalDisplayDesktopExclusive
                                                isOpen={qrCodeModal.isOpen}
                                                toggle={() => {}}
                                                modalBackgroundColor="rgba(0, 0, 0, 0)"
                                            >
                                                <div
                                                    style={{
                                                        marginTop: '20vh',
                                                    }}
                                                />
                                                <QrCodeCustomiseStickerModalDesktop
                                                    qr_code_link={
                                                        qrCodeStickerModal.id ===
                                                        'car_via_garage'
                                                            ? car_via_garage_qr_code ||
                                                              ''
                                                            : car_only_qr_code ||
                                                              ''
                                                    }
                                                    qr_code_id={
                                                        'car_qr_code_to_export_desktop'
                                                    }
                                                    car_title={
                                                        current?.title ||
                                                        'Untitled'
                                                    }
                                                    closeModal={() =>
                                                        dispatch(
                                                            editFormsActions.toggleQrCodeStickerModal(
                                                                {
                                                                    isOpen: false,
                                                                    id: undefined,
                                                                }
                                                            )
                                                        )
                                                    }
                                                    isGarage={
                                                        qrCodeStickerModal.id ===
                                                        'car_via_garage'
                                                    }
                                                    predefinedStickerThemes={
                                                        predefinedQrCodeStickerThemes
                                                    }
                                                />
                                            </ModalDisplayDesktopExclusive>
                                        </Faded>
                                    )}
                            </Faded>
                        )}
                </FullPagePageWrapper>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <FullPagePageWrapper hasMobileSubNav>
                    {current && userLoggedIn && garagesData && (
                        <SingleCarShareBodyMobile
                            noCarTitle
                            handleChange_shares_search={(
                                p: ISearchQueryCarSharesDisplay_change_request
                            ) =>
                                dispatch(
                                    carActions.searchQueryCarSharesDisplay_change_request(
                                        p
                                    )
                                )
                            }
                            areCarSharesLoading={areCarSharesLoading}
                            handleChange_shares_sort={(
                                p: ISortCarSharesDisplay_change_request
                            ) =>
                                dispatch(
                                    carActions.sortCarSharesDisplay_change_request(
                                        p
                                    )
                                )
                            }
                            handleChange_shares_filter={(
                                p: IFilterCarSharesDisplay_change_request
                            ) =>
                                dispatch(
                                    carActions.filterCarSharesDisplay_change_request(
                                        p
                                    )
                                )
                            }
                            car={current}
                            resetSearch={(p: string) =>
                                dispatch(
                                    carActions.resetSearchQueryCarShareDisplay_request(
                                        p
                                    )
                                )
                            }
                            createCarPublicShare={(p: string) =>
                                dispatch(
                                    carActions.createCarPublicShareRequest(p)
                                )
                            }
                            garage={garagesData[userLoggedIn.owns_garage.uid]}
                            deletePublicLink={(p: IDeleteShareReq) =>
                                dispatch(
                                    carActions.deleteCarPublicShareRequest(p)
                                )
                            }
                            openQrCodeModal={(qr_id: string) =>
                                dispatch(
                                    editFormsActions.toggleQrCodeModal({
                                        isOpen: true,
                                        id: qr_id,
                                    })
                                )
                            }
                        />
                    )}
                    {qrCodeModal.isOpen &&
                        (car_via_garage_qr_code !== undefined ||
                            car_only_qr_code !== undefined) && (
                            <Faded>
                                <ModalDisplay
                                    isOpen={qrCodeModal.isOpen}
                                    toggle={() =>
                                        dispatch(
                                            editFormsActions.toggleQrCodeModal({
                                                isOpen: false,
                                                id: undefined,
                                            })
                                        )
                                    }
                                >
                                    <QrCodeModalMobile
                                        qr_code_link={
                                            qrCodeModal.id === 'car_via_garage'
                                                ? car_via_garage_qr_code || ''
                                                : car_only_qr_code || ''
                                        }
                                        qr_code_id={
                                            'car_qr_code_to_export_desktop'
                                        }
                                        car_title={current?.title || 'Untitled'}
                                        closeModal={() =>
                                            dispatch(
                                                editFormsActions.toggleQrCodeModal(
                                                    {
                                                        isOpen: false,
                                                        id: undefined,
                                                    }
                                                )
                                            )
                                        }
                                        isGarage={
                                            qrCodeModal.id === 'car_via_garage'
                                        }
                                        onStickerBtnClick={() => {
                                            dispatch(
                                                editFormsActions.toggleQrCodeStickerModal(
                                                    {
                                                        isOpen: true,
                                                        id: qrCodeModal.id,
                                                    }
                                                )
                                            )
                                            dispatch(
                                                editFormsActions.toggleQrCodeModal(
                                                    {
                                                        isOpen: false,
                                                        id: undefined,
                                                    }
                                                )
                                            )
                                        }}
                                    />
                                </ModalDisplay>
                            </Faded>
                        )}

                    <QrCodeStickerBottomSheetMobile
                        qr_code_link={
                            qrCodeStickerModal.id === 'car_via_garage'
                                ? car_via_garage_qr_code || ''
                                : car_only_qr_code || ''
                        }
                        title={current?.title || 'Untitled'}
                        qr_code_id={'car_qr_code_to_export_mobile'}
                        closeBottomSheet={() =>
                            dispatch(
                                editFormsActions.toggleQrCodeStickerModal({
                                    isOpen: false,
                                    id: undefined,
                                })
                            )
                        }
                        isBottomSheetOpen={qrCodeStickerModal.isOpen}
                        predefinedStickerThemes={predefinedQrCodeStickerThemes}
                    />
                </FullPagePageWrapper>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default CarSharing
