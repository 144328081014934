import { useState, useEffect } from 'react'
import Loader from '../atoms/loader/loader'
import TriangleHeader from '../atoms/header/triangleheader'
import PublicPageWrapper from '../templates/displays/pageWrappers/PublicPageWrapper'
import VerifiedFormikResetPassword from '../organisms/resetpasswordform/verifiedFormikResetPassword'
import styled from 'styled-components'
import { device } from '../templates/displays/devices'
import Faded from '../templates/animated/faded'
import { Paragraph } from '../atoms/typography'
import ButtonAtom from '../atoms/Button/ButtonAtom'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'
import { usersActions } from '../../redux/user/reducer'

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    @media ${device.desktop} {
        padding-top: 80px;
    }

    @media ${device.ipad} {
        padding-top: 100px;
    }

    @media ${device.mobile} {
        padding-top: 20px;
    }
`

const Padding = styled.div`
    @media ${device.ipad} {
        padding-top: 1vh;
    }
    @media ${device.desktop} {
        padding-top: 2vh;
    }
    @media ${device.mobile} {
        margin-top: 0px;
    }
`
const LineHeight = styled.div`
    @media ${device.mobile} {
        line-height: 2 !important;
    }
`

const PaddingSuccess = styled.div`
    @media ${device.ipad} {
        padding-top: 50px;
    }
    @media ${device.desktop} {
        padding-top: 4vh;
    }
    @media ${device.mobile} {
        padding-top: 30px;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.ipad} {
        margin-top: 60px;
    }
    @media ${device.desktop} {
        margin-top: 40px;
    }
    @media ${device.mobile} {
        margin-top: 40px;
    }
`

const ResetPasswordForm = () => {
    const [sent, setSent] = useState(false)

    const { userLoggedIn, error } = useAppSelector((state) => ({
        userLoggedIn: state.user.userLoggedIn,
        error: state.user.error,
    }))
    const dispatch = useAppDispatch()

    useEffect(() => {
        // prev this.props.match.params.activationcode equivalent
        dispatch(
            usersActions.verifyResetPasswordCodeRequest(
                window.location.pathname.split('/').pop() || ''
            )
        )
    }, [userLoggedIn])

    const setSubmit = () => setSent(true)
    const toggleSent = () => setSent(false)

    return (
        <PublicPageWrapper>
            <Loader />

            <Wrapper>
                <TriangleHeader title="Reset Password" />
                <Padding />
                {sent && error === null && (
                    <Faded>
                        <div className="animated fadeIn faster">
                            <PaddingSuccess />
                            <LineHeight>
                                <Paragraph align="center">
                                    Thank you, your password has successfully
                                    been reset.
                                    <br />
                                    Click the button below to sign in with your
                                    new password.
                                </Paragraph>
                            </LineHeight>

                            <ButtonContainer>
                                <ButtonAtom theme="primary">
                                    <Link to="/signin">sign in</Link>
                                </ButtonAtom>
                            </ButtonContainer>
                        </div>
                    </Faded>
                )}

                {error !== null && (
                    <Faded>
                        <div className="animated fadeIn faster">
                            <PaddingSuccess />
                            <LineHeight>
                                <Paragraph align="center">
                                    The verification code is either expired,
                                    redeemed or invalid.
                                    <br /> <br />
                                    The password reset link is only valid for 15
                                    minutes and is single-use.
                                    <br /> <br />
                                    Please try again, we apologise for the
                                    inconvenience caused.
                                </Paragraph>
                            </LineHeight>

                            <Link to="/reset-password-request">
                                <ButtonContainer>
                                    <ButtonAtom
                                        theme="primary"
                                        onClick={toggleSent}
                                    >
                                        try again
                                    </ButtonAtom>
                                </ButtonContainer>
                            </Link>
                        </div>
                    </Faded>
                )}

                {!sent && error === null && userLoggedIn && (
                    <VerifiedFormikResetPassword
                        uid={userLoggedIn.id}
                        setSubmit={setSubmit}
                    />
                )}
            </Wrapper>
        </PublicPageWrapper>
    )
}

export default ResetPasswordForm
