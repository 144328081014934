/// <reference types="googlemaps" />
import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import InputFieldNoFormik from '../../../../atoms/Inputfield/inputFieldNoFormik'
import ButtonsStandardBottomRowDesktop from '../../buttons/buttonsStandardBottomRowDesktop'
import SelectSearchBarCarInfoDesktop from '../../technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import Expander from '../../../../atoms/expander/expander'
import { Select } from '../../../../atoms/select'
import { IInsuranceDropdownItem } from '../../../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'

const EditFormContainerDesktop = styled.section`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        padding-bottom: 30px;
        padding-top: 20px;
        height: auto;
        background-color: none;
        color: var(--text-strong, #1a1a1a);
        border-radius: 5px;
        position: relative;
        margin-bottom: 50px;
    }
`

const DesktopAddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
`
const DesktopAddressSubContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
`

const SearchField = styled.input`
    background-color: var(
        --base-overlays-neutral-alpha-50,
        rgba(26, 26, 26, 0.02)
    );
    height: 45px;
    border-radius: 5px;
    border: solid 1px var(--text-muted, #b3b3b3);
    color: var(--text-strong, #1a1a1a);
    width: 400px;
    margin-left: 10px;
    padding-left: 15px;
    /* z-index: 1000; */
`
export const ErrorText = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 4px;
    font-size: 12px;
    color: var(--error, #df6f6f);
    font-style: italic;
`

type Props = {
    street_number: any
    postal_town: any
    route: any
    postal_code: any
    country: any
    onSubmit: any
    handleChange: any
    isFormValid: any
    toggle: any
    countriesdropdownlist: any
    handleSelectCountry: any
    administrative_area_level_1?: string
    countryUpdatedFromSearchedAddress?: string | null
    error: { [key: string]: string | undefined } | undefined
    usaAdminCodes: IInsuranceDropdownItem[] | null
    handleSelectAdmin1Code: (code: string) => any
}
const AccountAddressFormDesktop: React.FC<Props> = ({
    street_number,
    route,
    postal_town,
    postal_code,
    handleSelectCountry,
    country,
    countriesdropdownlist,
    onSubmit,
    handleChange,
    isFormValid,
    toggle,
    administrative_area_level_1,
    countryUpdatedFromSearchedAddress,
    error,
    handleSelectAdmin1Code,
    usaAdminCodes,
}) => {
    const [isAdminLevel1Opened, setIsAdminLevel1Opened] = React.useState(false)

    return (
        <EditFormContainerDesktop>
            <InnerFormPadding>
                <SearchField
                    id="autocompletedesktopaccountaddress"
                    placeholder="Search your address"
                    type="text"
                />

                <DesktopAddressContainer id="address-search-desktop">
                    <DesktopAddressSubContainer>
                        <InputFieldNoFormik
                            name="street_number"
                            type="text"
                            placeholder="Street number  /  House name"
                            value={street_number}
                            onChange={handleChange}
                            tabindex={1}
                            isNarrow={true}
                            onBlur={(e: any) => e.preventDefault()}
                        />
                        <Expander
                            height={
                                error && error['street_number'] ? 'auto' : 0
                            }
                            width={'100%'}
                        >
                            <ErrorText>
                                {error && error['street_number']}
                            </ErrorText>
                        </Expander>
                    </DesktopAddressSubContainer>

                    <DesktopAddressSubContainer>
                        <InputFieldNoFormik
                            type="text"
                            name="route"
                            placeholder="Street address"
                            value={route}
                            onChange={handleChange}
                            tabindex={2}
                        />
                        <Expander
                            height={error && error['route'] ? 'auto' : 0}
                            width={'100%'}
                        >
                            <ErrorText>{error && error['route']}</ErrorText>
                        </Expander>
                    </DesktopAddressSubContainer>
                    <DesktopAddressSubContainer>
                        <InputFieldNoFormik
                            type="text"
                            name="postal_town"
                            placeholder="city"
                            value={postal_town}
                            onChange={handleChange}
                            isNarrow={true}
                            tabindex={3}
                        />
                        <Expander
                            height={error && error['postal_town'] ? 'auto' : 0}
                            width={'100%'}
                        >
                            <ErrorText>
                                {error && error['postal_town']}
                            </ErrorText>
                        </Expander>
                    </DesktopAddressSubContainer>

                    <DesktopAddressSubContainer>
                        <InputFieldNoFormik
                            type="text"
                            placeholder="zip code"
                            name="postal_code"
                            value={postal_code}
                            onChange={handleChange}
                            isNarrow={true}
                            tabindex={4}
                        />
                        <Expander
                            height={error && error['postal_code'] ? 'auto' : 0}
                            width={'100%'}
                        >
                            <ErrorText>
                                {error && error['postal_code']}
                            </ErrorText>
                        </Expander>
                    </DesktopAddressSubContainer>

                    <Expander height={country === 'United States' ? 'auto' : 0}>
                        <DesktopAddressSubContainer
                            style={{ zIndex: 16, marginTop: '40px' }}
                        >
                            <Select
                                value={administrative_area_level_1}
                                options={usaAdminCodes ?? []}
                                defaultValue={administrative_area_level_1}
                                placeholder={'Administrative Area Code'}
                                disabled={false}
                                onChange={(val: string) => {
                                    handleSelectAdmin1Code(val)
                                }}
                                onFocusToggle={(p: boolean) => {
                                    setIsAdminLevel1Opened(p)
                                }}
                            />
                        </DesktopAddressSubContainer>
                    </Expander>
                    <div style={{ paddingTop: '10px' }} />
                    <DesktopAddressSubContainer
                        style={{
                            zIndex: isAdminLevel1Opened ? -1 : 'auto',
                        }}
                    >
                        <SelectSearchBarCarInfoDesktop
                            items={countriesdropdownlist}
                            item={{
                                text: 'countries',
                                answer: country ? country : null,
                            }}
                            handleSelectChange={handleSelectCountry}
                            valueUpdatedFromExternalSource={
                                countryUpdatedFromSearchedAddress
                                    ? countryUpdatedFromSearchedAddress
                                    : null
                            }
                        />
                        <Expander
                            height={error && error['country'] ? 'auto' : 0}
                            width={'100%'}
                        >
                            <ErrorText>{error && error['country']}</ErrorText>
                        </Expander>
                    </DesktopAddressSubContainer>
                </DesktopAddressContainer>

                <div
                    style={{
                        marginTop: '60px',
                        opacity: isFormValid() === false ? 0.5 : 1,
                    }}
                >
                    <ButtonsStandardBottomRowDesktop
                        cancel={toggle}
                        submit={onSubmit}
                        isDisabled={isFormValid() === false ? true : false}
                        secondText="update"
                    />
                </div>
            </InnerFormPadding>
        </EditFormContainerDesktop>
    )
}

export default AccountAddressFormDesktop
