import { apex_homepage } from './data'
import { ISubNavigationState } from 'TopSubnavigationData'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: ISubNavigationState = {
    apex_homepage: apex_homepage,
    currentID: '',
}

const subNavigationSlice = createSlice({
    name: 'subNavigation',
    initialState,
    reducers: {
        setCurrentVisitedSectionID: (state, action: PayloadAction<string>) => {
            state.currentID = action.payload
        },
        resetNavigation: (state, action: PayloadAction<any>) => {
            state.currentID = ''
        },
        updateHomepage: (state, action: PayloadAction<any>) => {
            state.apex_homepage = action.payload
        },
    },
})

export const subNavigationActions = subNavigationSlice.actions

export default subNavigationSlice.reducer
