import * as React from 'react'
import styled from 'styled-components'
import { renderCategoryIcon } from '../../icons/components/historyFile/categories'
import useThemes from '../../../../providers/theme/hooks'
import colours, {
    history_file_colours,
} from '../../../../providers/theme/colours'
import CrossIcon from '../../icons/crossIcon'
import {
    ITimelineCategoriesObj,
    ITimelineEntryTypes,
} from '../../../../redux/timeline/types'

interface IStyled {
    height?: string
    bgcolor?: string
    border?: string
}

const Container = styled.div<IStyled>`
    background-color: ${(props) =>
        props.bgcolor ? props.bgcolor : 'var(--primary, #5ec3ca)'};
    color: var(--bg-color, #fff);
    height: ${(props) => (props.height ? props.height : 'auto')};
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    white-space: nowrap;
    cursor: pointer;
    border: ${(props) => props.border ?? 'none'};
`

const Text = styled.div`
    color: var(--bg-color, #fff);
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.001px;
    text-transform: lowercase;
`

// const Icon = styled.img`
//     width: 14px;
//     height: 14px;
//     margin-right: 5px;
// `

type Props = {
    children?: React.ReactNode
    height?: string
    dataCyId?: string
    id?: string | undefined
    isActive?: boolean
    bgcolor?: string
    onCrossClick?: any
    entryTypes_data?: ITimelineCategoriesObj
    entityID?: 'timeline' | 'sharing'
    border?: string
    isLabel?: boolean
}

const CustomIconTagQuickFilterItem: React.FC<Props> = (props) => {
    const {
        children,
        dataCyId,
        id,
        height,
        bgcolor,
        onCrossClick,
        entityID,
        border,
        isLabel,
    } = props

    const { theme } = useThemes()

    return (
        <Container
            height={height}
            data-attr={dataCyId && dataCyId}
            bgcolor={
                entityID === 'sharing'
                    ? bgcolor
                    : id
                    ? history_file_colours[theme].categories[
                          id as ITimelineEntryTypes
                      ].primary_100
                    : bgcolor
            }
            border={border}
            onClick={onCrossClick ? onCrossClick : () => {}}
        >
            <>
                {/* <Icon src={checkIcon(id)} /> */}
                {id ? renderCategoryIcon(id as ITimelineEntryTypes) : null}
                <Text
                    style={
                        id
                            ? { textTransform: 'capitalize' }
                            : isLabel
                            ? {
                                  textTransform: 'uppercase',
                                  color: colours[theme].text_darker,
                                  lineHeight: undefined,
                                  paddingTop: 1,
                              }
                            : {}
                    }
                >
                    {children}
                </Text>
            </>
            {onCrossClick && (
                <CrossIcon
                    size="14"
                    color={
                        isLabel
                            ? colours[theme].text_darker
                            : colours[theme].background_default
                    }
                />
            )}
        </Container>
    )
}

export default CustomIconTagQuickFilterItem
