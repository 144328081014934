import React from 'react'
import styled from 'styled-components'

const HeaderLineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: self-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    @media (min-width: 500px) {
        align-self: flex-start;
        justify-self: flex-start;
        padding-bottom: 30px;
        padding-top: 30px;
    }
`

const Header = styled.div`
    font-family: Lato;
    font-size: 18px;
    padding-right: 20px;
    white-space: nowrap;
    color: var(--text-strong, #1a1a1a);
`

const HeaderLine = styled.div`
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid var(--text-strong, #1a1a1a);
`

type Props = {
    title: string
}

const ApexHeaderLineSectionMobile: React.FC<Props> = ({ title }) => {
    return (
        <HeaderLineWrapper>
            <Header>{title}</Header>
            <HeaderLine />
        </HeaderLineWrapper>
    )
}

export default ApexHeaderLineSectionMobile
