import { IDropdownItem } from 'entityModels'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import {
    IAnyObject,
    IAnyErrorString,
    insuranceActions,
    IInsuranceQuoteApplication,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import { insurance_fields_other_driver_basic_details } from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import InsuranceApplicationMobileSearch from '../../../../../pages/insurance_quote/flows/search/mobileSearch'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import PortalInvisibleWrapper from '../../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import RemoveMobileSearchAnchor from '../../../../../templates/insurance/removeMobileSearchAnchor'
import colours from '../../../../../../providers/theme/colours'
import useThemes from '../../../../../../providers/theme/hooks'

type Props = {
    driver_id: string
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const InsuranceOtherDriverBasicDetailsApplicationWizardForm = (
    props: Props
) => {
    let userid = props.driver_id

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const data: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.other_drivers[userid]
                ?.basic_details ?? {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.other_drivers[userid]
            ?.basic_details_errors
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_other_driver_basic_details({
                id: id,
                error: error,
                userid: userid,
            })
        )
    }

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (
            let i = 0;
            i < insurance_fields_other_driver_basic_details.length;
            i++
        ) {
            if (
                insurance_fields_other_driver_basic_details[i].is_required ===
                true
            ) {
                if (
                    data[insurance_fields_other_driver_basic_details[i].id] ===
                    undefined
                ) {
                    setError(
                        insurance_fields_other_driver_basic_details[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
                if (
                    insurance_fields_other_driver_basic_details[i].validation &&
                    data[insurance_fields_other_driver_basic_details[i].id]
                ) {
                    let validation_func =
                        insurance_fields_other_driver_basic_details[i]
                            .validation
                    let value =
                        data[insurance_fields_other_driver_basic_details[i].id]
                    let error_txt =
                        insurance_fields_other_driver_basic_details[i].error_txt

                    if (
                        validation_func &&
                        value &&
                        validation_func(value) !== true
                    ) {
                        setError(
                            insurance_fields_other_driver_basic_details[i].id,
                            error_txt ?? 'Invalid'
                        )
                        hasErr2 = true
                    }
                }
            }

            if (
                insurance_fields_other_driver_basic_details[i].validation &&
                data[insurance_fields_other_driver_basic_details[i].id] !==
                    undefined
            ) {
                let is_valid_func =
                    insurance_fields_other_driver_basic_details[i].validation
                let value =
                    data[insurance_fields_other_driver_basic_details[i].id]
                let error_txt =
                    insurance_fields_other_driver_basic_details[i].error_txt

                if (is_valid_func && is_valid_func(value) !== true) {
                    setError(
                        insurance_fields_other_driver_basic_details[i].id,
                        error_txt ?? 'Invalid'
                    )
                    hasErr2 = true
                }
            }
        }

        return hasErr2
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let {
        setInitialInsurance_Drivers_BasicDetails_dropdowns,
        getFirstIncompleteSubsection_OtherDriver,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    useEffect(() => {
        setInitialInsurance_Drivers_BasicDetails_dropdowns()
    }, [userLoggedIn])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const goPrevious = () => {
        navigate(
            `/insurance/application/${applicationData?.id}/wizard?section_id=drivers`
        )
    }

    const goNext = () => {
        let ids = getFirstIncompleteSubsection_OtherDriver(
            userid,
            'basic_details'
        )

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return navigate(
                {
                    search: params2.toString(),
                },
                { replace: true }
            )
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('drivers')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                navigate(
                    {
                        search: params2.toString(),
                    },
                    { replace: true }
                )
            } else navigate(`/insurance/application/${applicationData?.id}`)
        }

        return getToAny()
        // next of the driver that is 100%, I should do the same for cars
    }

    let currURL = `/insurance/application/${applicationData?.id}/wizard?section_id=drivers&subsection_id=basic_details&driver_id=${userid}`

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_otherDriver_basicDetails_request({
                    driver_id: userid,
                })
            )
            setTimeout(() => {
                goNext()
            }, 200)
        } else {
            setIsSavedDisabled(true)
        }
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'drivers',
            onSave: () => saveForm(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_other_driver_basicDetails({
                id: id,
                answer: answer,
                userid: userid,
            })
        )
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'drivers',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }
    }

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        driverid={userid}
                        sectionID="drivers"
                        list={insurance_fields_other_driver_basic_details}
                        onAnswerChange={onAnswerChange}
                        data={data}
                        errors={errors}
                        setError={setError}
                    />
                </div>
                <div style={{ paddingTop: '200px' }} />

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() => goPrevious()}
                    sectionId="drivers"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                <div style={{ width: '100vw' }}>
                    {field_id ? (
                        <PortalInvisibleWrapper>
                            <InsuranceApplicationMobileSearch
                                sectionId="drivers"
                                urlBack={currURL}
                                draft={data}
                                submitted={applicationData}
                                onChange={(value: IDropdownItem) => {
                                    if (
                                        field_id &&
                                        errors &&
                                        errors[field_id]
                                    ) {
                                        setError(field_id, undefined)
                                    }

                                    dispatch(
                                        insuranceActions.set_answer_other_driver_basicDetails(
                                            {
                                                id:
                                                    field_id === 'titles'
                                                        ? `given_name-title`
                                                        : `${field_id}`,
                                                answer: value,
                                                userid: userid,
                                            }
                                        )
                                    )
                                }}
                            />
                        </PortalInvisibleWrapper>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%',
                                boxSizing: 'border-box',
                                paddingBottom: 120,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            <div
                                style={{
                                    width: '88vw',
                                }}
                            >
                                <InsuranceQuoteFormGenerator
                                    driverid={userid}
                                    sectionID="drivers"
                                    list={
                                        insurance_fields_other_driver_basic_details
                                    }
                                    onAnswerChange={onAnswerChange}
                                    data={data}
                                    errors={errors}
                                    setError={setError}
                                    mobileSearchPickerSectionURL={currURL}
                                />

                                <div style={{ paddingTop: '200px' }} />
                            </div>
                            <InsuranceGoBackReusableBottomBar
                                isMobile
                                saveBtnTxt="Save & Continue"
                                isSaveDisabled={isSavedDisabled ? true : false}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                goBack={() => goPrevious()}
                                sectionId="drivers"
                            />
                        </div>
                    )}
                </div>
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceOtherDriverBasicDetailsApplicationWizardForm
