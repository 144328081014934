import { IApexCarOfTheWeekState } from 'apexModels'
import { useEffect } from 'react'
import styled from 'styled-components'
import { getApexCarsOfTheWeekDataThunks } from '../../../redux/theapex/thunks'
import ApexPageHeaderDesktop from '../../atoms/header/apex/apexPageHeaderDesktop'
import ApexPageHeaderMobile from '../../atoms/header/apex/apexPageHeaderMobile'
import LoaderBackground from '../../atoms/loader/loaderBackground'
import ApexCarOfTheWeekItemDesktop from '../../molecules/apex/carsoftheweek/desktop/apexCarOfTheWeekItemDesktop'
import ApexMainCarOfTheWeekDesktop from '../../molecules/apex/carsoftheweek/desktop/apexMainCarOfTheWeekDesktop'
import ApexCarOfTheWeekItemMobile from '../../molecules/apex/carsoftheweek/mobile/apexCarOfTheWeekItemMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { device } from '../../templates/displays/devices'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import { checkForIpad } from './overview'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { useAppSelector, useThunkAppDispatch } from '../../../redux/store/hooks'
import { IRootState } from '../../../redux/store'

const BackgroundColorWrapper = styled.div`
    background-color: var(--bg-color, #fff);
`

const DesktopSubWrapper = styled.div`
    display: flex;
    align-self: flex-start;
    justify-self: flex-start;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`
const MobileWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-10px);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: ${checkForIpad() ? '80px' : '45px'};
`

const MainCarDesktopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const CarItemsDesktopWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
`
const DesktopPadding1 = styled.div`
    padding-top: 50px;

    @media ${device.large_desktop} {
        padding-top: 100px;
    }
`

const ApexCarsOfTheWeek = () => {
    const carsOfTheWeek = useAppSelector(
        (state: IRootState) => state.apex.carsOfTheWeekData
    )
    const carOfTheWeekPageLocalCopy = useAppSelector(
        (state: IRootState) =>
            state.localdata.apex_local_copy.separatePage.carOfTheWeek
    )
    const dispatchThunks = useThunkAppDispatch()

    useEffect(() => {
        dispatchThunks(getApexCarsOfTheWeekDataThunks())
    }, [])

    const mainCar = carsOfTheWeek && carsOfTheWeek[0]

    return (
        <>
            <FabsMenusMobile hasAdd={false} hasSubNav={true} />

            <BackgroundColorWrapper>
                <CenteredPageWrapper hasMobileSubNav>
                    <DesktopSubWrapper>
                        <DesktopDisplayOnly>
                            <div style={{ paddingTop: '40px' }} />

                            <ApexPageHeaderDesktop
                                title={carOfTheWeekPageLocalCopy.title}
                                text={carOfTheWeekPageLocalCopy.subTitle}
                            />

                            <DesktopPadding1 />

                            <MainCarDesktopWrapper>
                                {!mainCar && (
                                    <LoaderBackground
                                        height={'700px'}
                                        width={'100%'}
                                    />
                                )}
                                {mainCar && (
                                    <ApexMainCarOfTheWeekDesktop
                                        car={mainCar}
                                    />
                                )}
                            </MainCarDesktopWrapper>

                            <div style={{ paddingTop: '100px' }} />

                            {!carsOfTheWeek && (
                                <>
                                    <LoaderBackground
                                        height={'600px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'600px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'600px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'600px'}
                                        width={'100%'}
                                    />
                                </>
                            )}
                            <CarItemsDesktopWrapper>
                                {carsOfTheWeek &&
                                    carsOfTheWeek.map(
                                        (
                                            car: IApexCarOfTheWeekState,
                                            index: number
                                        ) => {
                                            if (index !== 0) {
                                                return (
                                                    <ApexCarOfTheWeekItemDesktop
                                                        car={car}
                                                        index={index}
                                                        key={`car_${car.date}_desktop`}
                                                    />
                                                )
                                            } else return undefined
                                        }
                                    )}
                            </CarItemsDesktopWrapper>

                            <div style={{ paddingBottom: '150px' }} />
                        </DesktopDisplayOnly>
                    </DesktopSubWrapper>

                    <IpadAndMobileDisplay>
                        <MobileWrapper>
                            <ApexPageHeaderMobile
                                title={carOfTheWeekPageLocalCopy.title}
                                text={carOfTheWeekPageLocalCopy.subTitle}
                            />

                            <div style={{ paddingTop: '20px' }} />

                            {!carsOfTheWeek && (
                                <>
                                    <LoaderBackground
                                        height={'250px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'250px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'250px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'250px'}
                                        width={'100%'}
                                    />
                                </>
                            )}

                            {carsOfTheWeek &&
                                carsOfTheWeek.map(
                                    (
                                        car: IApexCarOfTheWeekState,
                                        index: number
                                    ) => {
                                        return (
                                            <ApexCarOfTheWeekItemMobile
                                                car={car}
                                                key={`car_${car.date}_mobile`}
                                            />
                                        )
                                    }
                                )}

                            <div style={{ paddingBottom: '150px' }} />
                        </MobileWrapper>
                    </IpadAndMobileDisplay>
                </CenteredPageWrapper>
            </BackgroundColorWrapper>
        </>
    )
}

export default ApexCarsOfTheWeek
