import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { api } from '../../../services'
import { IaddShareRecipientApi_arg } from '../../../services/sharing/addShareRecipient'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { GetSingleGarageShare } from './getGarageDirectShares'
import { IShareRecipientRequest_redux } from '../../cars/actions'
import { IReduxError } from '../../cars/types'
import { garagesActions } from '../reducer'

export function* AddGarageShareRecipient(p: IShareRecipientRequest_redux) {
    try {
        let api_arg: IaddShareRecipientApi_arg = {
            share_id: p.share_id,
            recipient_userid: p.recipient_userid,
        }

        yield call(api.sharing.addShareRecipientApi, api_arg)

        yield call(GetSingleGarageShare, {
            entity_id: p.entity_id,
            share_id: p.share_id,
        })

        yield put(garagesActions.addGarageShareRecipientSuccess())
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: AddGarageShareRecipient,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(garagesActions.addGarageShareRecipientError(typedError))
            return
        }
    }
}

function* watcherAddGarageShareRecipient() {
    while (true) {
        let { payload } = yield take(
            garagesActions.addGarageShareRecipientRequest
        )
        yield call(AddGarageShareRecipient, payload)
    }
}

export function* DeleteGarageShareRecipient(p: IShareRecipientRequest_redux) {
    try {
        let api_arg: IaddShareRecipientApi_arg = {
            share_id: p.share_id,
            recipient_userid: p.recipient_userid,
        }

        yield call(api.sharing.deleteShareRecipientApi, api_arg)

        yield put(
            garagesActions.deleteGarageShareRecipientSuccess({
                share_id: p.share_id,
                entity_id: p.entity_id,
                recipient_id: p.recipient_userid,
            })
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: DeleteGarageShareRecipient,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                garagesActions.deleteGarageShareRecipientError(typedError)
            )
            return
        }
    }
}

function* watcherDeleteGarageShareRecipient() {
    while (true) {
        let { payload } = yield take(
            garagesActions.deleteGarageShareRecipientRequest
        )
        yield call(DeleteGarageShareRecipient, payload)
    }
}

const garage_share_recipient_sagas: any[] = [
    fork(watcherAddGarageShareRecipient),
    fork(watcherDeleteGarageShareRecipient),
]

export default garage_share_recipient_sagas
