import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Icon from '../icons'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
`

const Text = styled.h1<IStyle>`
    font-family: ${(props) => (props.isbold ? 'Lato-bold' : 'Lato')};
    font-size: ${(props) => (props.fontsize ? props.fontsize : '18px')};
    text-transform: capitalize;
    padding-right: 10px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    @media ${device.smallest_laptops} {
        font-size: 18px;
    }

    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 200;

    @media ${device.mobile_and_ipad} {
        font-size: 15px;
    }
`

const IconWrapper = styled.div`
    cursor: pointer;
`

type IStyle = {
    fontsize?: string
    isbold?: boolean
}

export interface Props {
    children?: React.ReactNode
    icon?: string
    fontsize?: string
    isbold?: boolean
    onClick?: any
    id?: string
    hasActionIcon: boolean
    width?: string
}

const SectionHeaderV2: React.FunctionComponent<Props> = (props) => (
    <Wrapper
        id={props.id}
        style={{ width: props.width ? props.width : 'auto' }}
    >
        <Text fontsize={props.fontsize} isbold={props.isbold}>
            {props.children}
        </Text>
        {props.hasActionIcon && (
            <IconWrapper onClick={props.onClick}>
                {props.icon && <Icon icon={props.icon}></Icon>}
            </IconWrapper>
        )}
    </Wrapper>
)

export default SectionHeaderV2
