import React from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import { Select } from '../../../../../atoms/select'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding40 = styled.div`
    padding-top: 40px;
`
const Padding5 = styled.div`
    padding-top: 5px;
`

interface Values {
    single_item_edited_value_selectSearchDesktop: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc?: (p: IwriteTechnicalInformationPayloadReq) => any
    tech_info_id?: any
    optionsList: any
    carid: string
    placeholder?: string
}

const CarTechInfoEditFormSelectSearchDesktop: React.FC<OtherProps> = ({
    toggle,
    item,
    optionsList,
    submitFunc,
    tech_info_id,
    carid,
    placeholder,
}) => {
    const { theme } = useThemes()

    const formik = useFormik<Values>({
        initialValues: {
            single_item_edited_value_selectSearchDesktop: item.answer || '',
        },
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false)
            if (submitFunc) {
                const payload: IwriteTechnicalInformationPayloadReq = {
                    tech_info_id,
                    car_id: carid,
                    data: {
                        id: item.id,
                        text: item.text,
                        answer: values.single_item_edited_value_selectSearchDesktop,
                    },
                }
                submitFunc(payload)
            }
            toggle()
        },
    })

    const checkIfDisabled = () => {
        return !formik.values.single_item_edited_value_selectSearchDesktop
    }

    return (
        <ModalContentDesktop $theme={theme} style={{ marginTop: '20vh' }}>
            <ModalTopBar $theme={theme}>
                <span>{item.text}</span>
            </ModalTopBar>
            <ModalInnerContentWrapperDesktop>
                <Padding5 />
                {optionsList && (
                    <Select
                        options={optionsList.map(
                            (option: { name: string }) => ({
                                value: option.name,
                                label: option.name,
                            })
                        )}
                        defaultValue={item?.answer}
                        placeholder={item.text}
                        helperText={placeholder}
                        onChange={(name) =>
                            formik.setFieldValue(
                                'single_item_edited_value_selectSearchDesktop',
                                name
                            )
                        }
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                )}
                <Padding40 />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={formik.handleSubmit}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

export default CarTechInfoEditFormSelectSearchDesktop
