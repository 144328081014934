import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import FullPageWrapperShowroom from '../../templates/displays/pageWrappers/fullPageWrapperShowroom'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { device } from '../../templates/displays/devices'
import ShowroomH1 from '../../atoms/typography/showroom/ShowroomH1'
import ShowroomFiltersBarDesktop from '../../organisms/searchbar/showroom/filterContent/showroomFiltersBarDesktop'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import ShowroomCarSliderDesktop from '../../molecules/swipeNslide/showroom/showroomCarSliderDesktop'
import { IFilterID, IShowroomFiltersState } from '../../../redux/showroom/types'
import { AnimatePresence, motion } from 'framer-motion'
import { ISearchShowroom_API_car_item } from '../../../redux/services/showroom/types'
import { useEffect, useRef, useState } from 'react'
import { showroomActions } from '../../../redux/showroom/reducer'
import IconButton from '../../atoms/Button/iconButton'
import SortMenuDesktop from '../../atoms/menu/sortMenu/sortMenuDesktop'
import SortMenuMobile from '../../atoms/menu/sortMenu/sortMenuMobile'
import { CardSkeletonItems, showroomSortDataOptions } from './showroom'
import ShowroomNoResultsBox from '../../atoms/showroom/showroomNoResultsBox'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { ICarouselShowroomItem } from '../../../redux/services/showroom/getCarousel'
import { showroomLocalDataActions } from '../../../redux/localdata/showroomLocalData'
import ScrollToTopBtn from '../../atoms/Button/scrollToTopBtn'
import { useScrollDirectionInsideADiv } from '../../../helpers/hooks/checkScrollDirection'
import BottomPaginationNavigation from '../../templates/pagination/bottomPaginationNavigation'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import ShowroomCardsContainerDesktop from '../../organisms/showroom/cardsContainer/cardsContainerDesktop'
import ShowroomCardsContainerMobile from '../../organisms/showroom/cardsContainer/cardsContainermobile'
import ShowroomPublicFooterDesktop from '../../templates/footer/showroomPublicFooter/showroomPublicFooterDesktop'
import ShowroomPublicFooterMobile from '../../templates/footer/showroomPublicFooter/showroomPublicFooterMobile'
import { useShowroom } from '../../../providers/showroom'
import { usersActions } from '../../../redux/user/reducer'

export interface ICarShowroomSpecs {
    make: string
    model: string
    year: string
    engine_size: string
    transmission: string
    drive_side: string
    mileage: string
    location: string
}

const ShowroomCarContainer = styled.div`
    z-index: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
    row-gap: 3rem;
    transition: all 100ms;
    padding-bottom: 2rem;
    width: 100%;
    box-sizing: border-box;

    height: 100%;

    overflow-y: none;

    @media ${device.ipad} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.smallest_laptops} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 880px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2rem;
    }

    @media ${device.ipad} {
        row-gap: 4rem;
    }

    @media (min-width: 2300px) {
        grid-template-columns: repeat(4, 1fr);
    }
`

const SectionWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;

    @media ${device.desktop} {
        max-width: 83vw;
        padding-left: 10px;
    }

    @media ${device.large_desktop} {
        max-width: 80vw;
        padding-left: 30px;
    }

    @media screen and (min-width: 1900px) {
        max-width: 1600px;
    }
    @media screen and (min-width: 2300px) {
        max-width: 1800px;
    }

    @media ${device.smallest_laptops} {
        max-width: 80vw;
        /* padding-left: 20px; */
        padding-right: 20px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: center;

    @media ${device.mobile_and_ipad} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const PageWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 2200px) {
        padding-top: 20px;
    }

    @media ${device.beyond_ipad_mobile} {
        height: 100%;
    }

    @media ${device.mobile_and_ipad} {
        padding-top: 112px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`

const SubSectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    z-index: -1;
    @media ${device.ipad} {
        padding-top: 20px;
    }
`

const FiltersWrapper = styled.div`
    display: flex;
`

const SelectedFilters = styled.div`
    position: absolute;
    right: -5px;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #df6f6f;
    border-radius: 50%;
    color: #fff;
    height: 16px;
    width: 16px;
    font-size: 12px;
`

const ShowroomSoldListPage = () => {
    let {
        putInitialParamsInReduxSearch,
        removeQueryParamSearchID,
        synchReduxSearchToQueryParam,
    } = useShowroom()

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        let paramsObj_copy: any = Array.from(params.keys()).reduce(
            (acc, val) => ({ ...acc, [val]: params.getAll(val) }),
            {}
        )

        delete paramsObj_copy['page']
        delete paramsObj_copy['anchor_entry']
        delete paramsObj_copy['owner_id']
        delete paramsObj_copy['owner_display_name']

        delete paramsObj_copy['utm_source']
        delete paramsObj_copy['utm_medium']
        delete paramsObj_copy['utm_term']
        delete paramsObj_copy['utm_campaign']
        // page + anchor
        if (Object.keys(paramsObj_copy).length > 0) {
            putInitialParamsInReduxSearch(window.location)
        } else {
            synchReduxSearchToQueryParam()
        }
    }, [])

    let entriesList: ISearchShowroom_API_car_item[] | undefined =
        useAppSelector((state) => {
            return state.showroom.sold_results
        })

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const soldEntriesListInnerRefDesktop: any = useRef()
    const soldEntriesListInnerRefMobile: any = useRef()

    let isSearchLoading: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isSearchLoading
    })

    let currentPageNumberSold: number = useAppSelector((state) => {
        return state.showroom.currentPageNumber_sold
    })

    let sold_results_total_count: number | undefined = useAppSelector(
        (state) => {
            return state.showroom.sold_results_total_count
        }
    )

    const location = useLocation()
    let params = new URLSearchParams(location.search)
    let ownerIDParams = params.get('owner_id')
    let ownerNameParams = params.get('owner_display_name')
    let anchor_entry_params = params.get('anchor_entry')

    const [prevPage, setPrevPage] = useState(currentPageNumberSold - 1)

    const navigate = useNavigate()

    const checkIfIsLast = () => {
        if (
            sold_results_total_count !== undefined &&
            entriesList &&
            (entriesList.length === sold_results_total_count ||
                entriesList.length > sold_results_total_count)
        ) {
            return true
        }

        if (!sold_results_total_count) {
            return false
        }

        if (!entriesList) {
            return false
        } else if (
            sold_results_total_count !== undefined &&
            entriesList &&
            entriesList.length < sold_results_total_count
        )
            return false
    }

    useEffect(() => {
        let refresh_token: string | null =
            typeof localStorage !== 'undefined' && localStorage !== null
                ? localStorage.getItem('refresh_token')
                : null

        if (!userLoggedIn && refresh_token !== null) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [])

    let handleChangeSearchEffectsReset = () => {
        dispatch(showroomActions.setCurrentPageNumber_sold(0))
        dispatch(
            showroomActions.searchShowroom_setCars({
                cars: undefined,
                resultsFoundNumber: undefined,
                similarSoldCarsNumber: undefined,
                type: 'sold',
            })
        )

        setPrevPage(-1)
        // window.scrollTo({ top: 0, behavior: 'smooth' })
        let currURL = new URL(window.location.href)
        let params2 = new URLSearchParams(currURL.search)
        params2.delete('page')
        params2.append('page', `${1}`)

        if (anchor_entry_params) {
            params2.delete('anchor_entry')
        }

        navigate({
            search: params2.toString(),
        })

        scrollSmoothTop()
    }

    let slides: ICarouselShowroomItem[] | null = useAppSelector((state) => {
        return state.localdata.showroomLocalData.slides
    })

    useEffect(() => {
        if (!slides) {
            dispatch(showroomLocalDataActions.getShowroomSlides_request())
        }

        // if (currentPageNumberSold !== 0) {
        //     handleChangeSearchEffectsReset()
        // }

        // return () => {
        //     // reset
        //     handleChangeSearchEffectsReset()
        // }
    }, [])

    function isPageValid(
        totalCount: number,
        itemsPerPage: number,
        page: number
    ): boolean {
        const totalPages = Math.ceil(totalCount / itemsPerPage)
        return page <= totalPages
    }

    const manageEntryAnchorRemoval = () => {
        // in future, can take any FilterID

        if (anchor_entry_params) {
            let currURL = new URL(window.location.href)
            const params2 = new URLSearchParams(currURL.search)
            params2.delete('anchor_entry')

            navigate({ search: params2.toString() })
        }
    }

    // to avoid any looping edge cases
    const [hasResetN, setHasResetN] = useState<number>(0)
    let pageN = params.get('page')

    useEffect(() => {
        setTimeout(() => {
            if (
                !ownerIDParams &&
                hasResetN < 3 &&
                !isSearchLoading &&
                !isThereActiveFilter &&
                sold_results_total_count !== undefined &&
                (!entriesList || entriesList?.length === 0) &&
                pageN &&
                typeof +pageN === 'number' &&
                isPageValid(sold_results_total_count, itemsPerPage, +pageN) ===
                    false
            ) {
                handleChangeSearchEffectsReset()

                setHasResetN(hasResetN + 1)
            }
        }, 100)
    }, [sold_results_total_count, isSearchLoading])

    useEffect(() => {
        if (!ownerIDParams) {
            if (pageN && typeof +pageN === 'number') {
                dispatch(showroomActions.setCurrentPageNumber_sold(+pageN - 1))
                setPrevPage(+pageN)

                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('page')
                params2.append('page', `${pageN}`)

                navigate({
                    search: params2.toString(),
                })
            } else {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('page')
                params2.append('page', `${1}`)

                navigate({
                    search: params2.toString(),
                })
            }
        }
    }, [])

    let filtersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.filters
    })

    useEffect(() => {
        // this is the saga
        const fetchData = () => {
            if (
                ownerIDParams !== undefined &&
                ownerIDParams !== null &&
                ownerNameParams !== undefined &&
                ownerNameParams !== null
            ) {
                dispatch(
                    showroomActions.setOwner_filter({
                        id: ownerIDParams,
                        display_name: ownerNameParams,
                    })
                )
            }
            if (!checkIfIsLast() && !isSearchLoading) {
                dispatch(
                    showroomActions.searchShowroomRequest({
                        states: 'SOLD',
                        freeze_total_count: true,
                    })
                )

                setPrevPage(currentPageNumberSold)
            }
        }

        if (prevPage !== currentPageNumberSold) {
            // call search
            fetchData()
        }
    }, [currentPageNumberSold, prevPage])
    // }, [currentPageNumberSold, prevPage, ownerIDParams])

    let itemsPerPage: number = useAppSelector((state) => {
        return state.showroom.itemsNumberPerPage
    })

    let isThereActiveFilter = Object.values(filtersState).some(
        (val) => val.isActive === true
    )

    let openedFilterID: IFilterID | undefined = useAppSelector((state) => {
        return state.showroom.openedFilterID
    })

    let nbOfSelectedfilters = Object.values(filtersState).filter(
        (val) => val.isActive === true
    ).length

    const [showSortActionBottomSheet, toggleSortActionBottomSheet] =
        useState(false)

    let isMoreFiltersOpen: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isMoreFiltersOpened
    })
    let activeSorting:
        | 'OLDEST'
        | 'MOST_RECENT'
        | 'PRICE_ASCENDING'
        | 'PRICE_DESCENDING'
        | 'MANUFACTURE_DATE_ASCENDING'
        | 'MANUFACTURE_DATE_DESCENDING'
        | undefined = useAppSelector((state) => {
        return state.showroom.activeSort
    })

    const onHandleSortingSelect = (v: string) => {
        dispatch(
            showroomActions.setActiveSorting(
                // @ts-ignore
                v
            )
        )
        setTimeout(() => {
            // reset
            handleChangeSearchEffectsReset()

            if (!isSearchLoading) {
                manageEntryAnchorRemoval()
                dispatch(
                    showroomActions.searchShowroomRequest({
                        states: 'SOLD',
                    })
                )
            }
        }, 100)
    }

    // const manageOwnerQueryParamRemoval = (id: string) => {
    //     // in future, can take any FilterID
    //     if (id === 'owner') {
    //         if (ownerIDParams) {
    //             let currURL = new URL(window.location.href)
    //             const params2 = new URLSearchParams(currURL.search)
    //             params2.delete('owner_id')
    //             params2.delete('owner_display_name')
    //             history.replace({ search: params2.toString() })
    //         }
    //     }
    // }

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    let cardAnchoredRefDesktop: any = useRef()
    let cardAnchoredRefMobile: any = useRef()

    let anchor_id_redux: string | undefined = useAppSelector((state) => {
        return state.showroom.entry_anchor
    })

    let entryIDtoScrollTo = !anchor_entry_params
        ? undefined
        : isMobile
        ? `mobile-${anchor_entry_params}`
        : `desktop-${anchor_entry_params}`

    let entryDiv =
        entryIDtoScrollTo && document.getElementById(entryIDtoScrollTo)

    let scrollToElement = () => {
        if (entryDiv) {
            scrollIntoView(entryDiv, {
                behavior: 'smooth',
            })
        }
    }

    useEffect(() => {
        if (anchor_id_redux) {
            let currURL = new URL(window.location.href)
            const params2 = new URLSearchParams(currURL.search)

            params2.delete('anchor_entry')
            params2.append('anchor_entry', anchor_id_redux)
            navigate({ search: params2.toString() })
        }

        scrollToElement()
    }, [entryDiv, anchor_id_redux])

    const [showScrollToTopBtn, setShowScrollToTopBtn] = useState(false)

    const checkIfScrollToTopBtnIsVisible = (
        ref: any,
        scrollDirection: string | null
    ) => {
        if (ref.current) {
            const { scrollTop, clientHeight } = ref.current
            if (scrollTop > clientHeight / 2 && scrollDirection === 'down') {
                setShowScrollToTopBtn(true)
            } else if (scrollTop === 0) {
                setShowScrollToTopBtn(false)
            }
        }
    }

    const scrollDirection = useScrollDirectionInsideADiv(
        isMobile
            ? soldEntriesListInnerRefMobile
            : soldEntriesListInnerRefDesktop
    )

    let isSliderEmpty = slides && slides.length === 0 ? true : false

    let showSlider = isSliderEmpty === false && isThereActiveFilter === false

    let scrollSmoothTop = () => {
        if (isMobile) {
            soldEntriesListInnerRefMobile?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        } else {
            soldEntriesListInnerRefDesktop?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        }
    }

    useEffect(() => {
        if (ownerIDParams) {
            scrollSmoothTop()
        }
        if (filtersState.owner?.isActive && !ownerIDParams) {
            dispatch(
                showroomActions.removeFilterRequest({
                    id: 'owner',
                    direct_search: true,
                })
            )

            dispatch(showroomActions.setCurrentPageNumber_sale(0))

            dispatch(
                showroomActions.searchShowroom_setCars({
                    cars: undefined,
                    resultsFoundNumber: undefined,
                    similarSoldCarsNumber: undefined,
                    type: 'sale',
                })
            )
        }
    }, [ownerIDParams])

    let totalPages = Math.ceil(
        (sold_results_total_count ? sold_results_total_count : 0) / itemsPerPage
    )

    let isLastPage = currentPageNumberSold + 1 === totalPages

    const onClickNext = () => {
        scrollSmoothTop()
        const currOage = currentPageNumberSold

        dispatch(showroomActions.setCurrentPageNumber_sold(currOage + 1))
        setPrevPage(currOage)

        let currURL = new URL(window.location.href)
        let params2 = new URLSearchParams(currURL.search)

        params2.delete('page')
        params2.append('page', `${currOage + 2}`)

        navigate({
            search: params2.toString(),
        })
    }

    const onClickPrev = () => {
        scrollSmoothTop()
        let currURL = new URL(window.location.href)
        let params2 = new URLSearchParams(currURL.search)

        params2.delete('page')
        params2.append('page', `${currentPageNumberSold}`)

        navigate({
            search: params2.toString(),
        })

        dispatch(
            showroomActions.setCurrentPageNumber_sold(currentPageNumberSold - 1)
        )
        setPrevPage(currentPageNumberSold - 2)
    }

    let onClickPageNumber = (n: number) => {
        scrollSmoothTop()
        let currURL = new URL(window.location.href)
        const params2 = new URLSearchParams(currURL.search)

        if (anchor_entry_params) {
            params2.delete('anchor_entry')
        }

        params2.delete('page')
        params2.append('page', `${n}`)
        navigate({ search: params2.toString() })
        dispatch(showroomActions.setCurrentPageNumber_sold(n - 1))
        setPrevPage(n - 2)
    }

    const [topPadding, setTopPadding] = useState(0)

    return (
        <>
            <DesktopDisplayOnly>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 100,
                        right: 80,
                        zIndex: showScrollToTopBtn ? 5 : -1,
                        opacity: showScrollToTopBtn ? 1 : 0,
                        transition: 'all 200ms',
                    }}
                >
                    <ScrollToTopBtn
                        onClick={() => {
                            soldEntriesListInnerRefDesktop?.current?.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            })
                        }}
                    />
                </div>
                <PageWrapper>
                    <div
                        style={{
                            height: '100vh',
                            overflowY: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'flex-start',
                        }}
                        key={'desktop-sold-scroll'}
                        onScroll={() => {
                            if (!isMobile) {
                                // check if we show scroll to top btn
                                checkIfScrollToTopBtnIsVisible(
                                    soldEntriesListInnerRefDesktop,
                                    scrollDirection
                                )
                            }
                        }}
                        ref={soldEntriesListInnerRefDesktop}
                    >
                        <FullPageWrapperShowroom>
                            <div
                                style={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    //width: '100%',
                                    boxSizing: 'border-box',
                                    flexDirection: 'column',
                                    position: 'relative',
                                    overflowX: 'hidden',
                                }}
                            >
                                {!showSlider ? (
                                    <div
                                        style={{
                                            paddingTop:
                                                isMoreFiltersOpen &&
                                                size &&
                                                size.width &&
                                                size.width < 1707
                                                    ? '172px'
                                                    : '100px',
                                        }}
                                    />
                                ) : null}

                                {!showSlider ? (
                                    <AnimatePresence>
                                        <motion.div
                                            style={{
                                                position: 'fixed',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                backgroundColor: 'white',
                                                zIndex: 10,
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                left: 0,
                                            }}
                                            initial={{
                                                opacity: 0,
                                                // transform: 'translateY(100px)',
                                                top: 100,
                                            }}
                                            animate={{
                                                opacity: 1,
                                                // transform: 'translateY(0px)',
                                                top: 64,
                                            }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                opacity: { duration: 0.2 },
                                                duration: 0.15,
                                            }}
                                        >
                                            <ShowroomFiltersBarDesktop
                                                isWithinPageLimits={false}
                                                manageQueryParamRemoval={(
                                                    id: string,
                                                    val?: any
                                                ) => {
                                                    removeQueryParamSearchID(
                                                        id,
                                                        val
                                                    )
                                                }}
                                                handleChangeSearchEffects={
                                                    handleChangeSearchEffectsReset
                                                }
                                                page="sold"
                                                currentTopPadding={topPadding}
                                                updateTopPadding={(
                                                    height: number
                                                ) => setTopPadding(height)}
                                            />
                                        </motion.div>
                                    </AnimatePresence>
                                ) : null}
                                <SectionWrapper>
                                    {showSlider ? (
                                        <div
                                            style={{
                                                paddingTop: '30px',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                height: '100%',
                                            }}
                                        >
                                            <ShowroomCarSliderDesktop
                                                slides={slides}
                                            />

                                            <div
                                                style={{
                                                    paddingTop: '30px',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    height: '100%',
                                                    zIndex:
                                                        openedFilterID !==
                                                            undefined ||
                                                        isMoreFiltersOpen ===
                                                            true
                                                            ? 10
                                                            : 0,
                                                }}
                                            />
                                            <ShowroomFiltersBarDesktop
                                                isWithinPageLimits={true}
                                                manageQueryParamRemoval={(
                                                    id: string,
                                                    val?: any
                                                ) => {
                                                    removeQueryParamSearchID(
                                                        id,
                                                        val
                                                    )
                                                }}
                                                handleChangeSearchEffects={
                                                    handleChangeSearchEffectsReset
                                                }
                                                page="sold"
                                                currentTopPadding={topPadding}
                                                updateTopPadding={(
                                                    height: number
                                                ) => setTopPadding(height)}
                                            />
                                        </div>
                                    ) : null}

                                    <SubSectionWrapper
                                        style={{
                                            zIndex:
                                                openedFilterID !== undefined
                                                    ? -1
                                                    : 8,
                                            paddingTop: isThereActiveFilter
                                                ? topPadding
                                                : '20px',
                                        }}
                                    >
                                        <ShowroomH1 leftAlign>
                                            Sold cars
                                        </ShowroomH1>
                                        <SortMenuDesktop
                                            dropdownData={
                                                showroomSortDataOptions
                                            }
                                            currentOption={
                                                activeSorting ?? undefined
                                            }
                                            onSelected={(v: string) => {
                                                onHandleSortingSelect(v)
                                            }}
                                        />
                                    </SubSectionWrapper>
                                    {!isMobile &&
                                    entriesList &&
                                    entriesList.length > 0 ? (
                                        <ShowroomCarContainer
                                            style={{
                                                zIndex: 1,
                                                // openedFilterID !==
                                                //     undefined ||
                                                // isMoreFiltersOpen === true
                                                //     ? -1
                                                //     : 0,
                                            }}
                                        >
                                            <ShowroomCardsContainerDesktop
                                                userLoggedIn={userLoggedIn}
                                                entriesList={entriesList}
                                                cardAnchoredRefDesktop={
                                                    cardAnchoredRefDesktop
                                                }
                                                anchor_entry_params={
                                                    anchor_entry_params
                                                }
                                                anchor_id_redux={
                                                    anchor_id_redux
                                                }
                                                handleChangeSearchEffectsReset={
                                                    handleChangeSearchEffectsReset
                                                }
                                            />

                                            {isSearchLoading &&
                                                !checkIfIsLast() && (
                                                    <CardSkeletonItems
                                                        height="25vh"
                                                        width="95%"
                                                        paddingBottom="0px"
                                                        narr={[
                                                            1, 2, 3, 4, 5, 6,
                                                        ]}
                                                    />
                                                )}
                                        </ShowroomCarContainer>
                                    ) : !isSearchLoading ? (
                                        <ShowroomNoResultsBox page="sold" />
                                    ) : (
                                        <CardSkeletonItems
                                            height="25vh"
                                            width="95%"
                                            paddingBottom="0px"
                                            narr={[1, 2, 3, 4, 5, 6]}
                                        />
                                    )}

                                    <BottomPaginationNavigation
                                        results={entriesList}
                                        currentPage={currentPageNumberSold}
                                        isLastPage={isLastPage}
                                        onClickPrev={onClickPrev}
                                        onClickNext={onClickNext}
                                        onClickPageNumber={onClickPageNumber}
                                        totalCount={
                                            sold_results_total_count ?? 0
                                        }
                                        itemsPerPage={itemsPerPage}
                                    />
                                    {!userLoggedIn ? (
                                        <ShowroomPublicFooterDesktop />
                                    ) : (
                                        <div style={{ paddingBottom: 300 }} />
                                    )}
                                </SectionWrapper>
                            </div>
                        </FullPageWrapperShowroom>
                    </div>
                </PageWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 100,
                        right: 16,
                        zIndex: showScrollToTopBtn ? 1 : -1,
                        opacity: showScrollToTopBtn ? 1 : 0,
                        transition: 'all 200ms',
                    }}
                >
                    <ScrollToTopBtn
                        onClick={() => {
                            soldEntriesListInnerRefMobile?.current?.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            })
                        }}
                    />
                </div>
                <SortMenuMobile
                    isVisible={showSortActionBottomSheet}
                    close={() => toggleSortActionBottomSheet(false)}
                    dropdownData={showroomSortDataOptions}
                    currentOption={activeSorting ?? 'MOST_RECENT'}
                    onSelected={(v: string) => {
                        onHandleSortingSelect(v)
                        toggleSortActionBottomSheet(false)
                    }}
                />
                <div style={{ position: 'fixed', width: '100%' }}>
                    <PageWrapper
                        style={{
                            height: '100vh',
                            overflowY: 'auto',
                        }}
                        key={'mobile-sold-scroll'}
                        onScroll={() => {
                            if (isMobile) {
                                // check if we show scroll to top btn
                                checkIfScrollToTopBtnIsVisible(
                                    soldEntriesListInnerRefMobile,
                                    scrollDirection
                                )
                            }
                        }}
                        ref={soldEntriesListInnerRefMobile}
                    >
                        <SectionWrapper>
                            <SubSectionWrapper style={{ zIndex: 2 }}>
                                <ShowroomH1 leftAlign>Sold cars</ShowroomH1>
                                <FiltersWrapper>
                                    <IconButton
                                        buttonheight="40px"
                                        buttonwidth="40px"
                                        bgcolor="transparent"
                                        borderColor="#e5e5e5"
                                        icon="sort-icon-grey"
                                        onClick={() =>
                                            toggleSortActionBottomSheet(true)
                                        }
                                    />

                                    <div
                                        style={{
                                            paddingLeft: 16,
                                            position: 'relative',
                                        }}
                                    >
                                        <IconButton
                                            buttonheight="40px"
                                            buttonwidth="40px"
                                            bgcolor="transparent"
                                            borderColor="#5EC3CA"
                                            icon="filters_icon_green"
                                            onClick={() =>
                                                navigate(
                                                    `/showroom/filters?sold=true`
                                                )
                                            }
                                        />
                                        {nbOfSelectedfilters > 0 ? (
                                            <SelectedFilters>
                                                {nbOfSelectedfilters}
                                            </SelectedFilters>
                                        ) : null}
                                    </div>
                                </FiltersWrapper>
                            </SubSectionWrapper>
                            {isMobile &&
                            entriesList &&
                            entriesList.length > 0 ? (
                                <ShowroomCarContainer>
                                    <ShowroomCardsContainerMobile
                                        userLoggedIn={userLoggedIn}
                                        entriesList={entriesList}
                                        cardAnchoredRefMobile={
                                            cardAnchoredRefMobile
                                        }
                                        anchor_entry_params={
                                            anchor_entry_params
                                        }
                                        anchor_id_redux={anchor_id_redux}
                                        handleChangeSearchEffectsReset={
                                            handleChangeSearchEffectsReset
                                        }
                                    />
                                    {isSearchLoading && !checkIfIsLast() && (
                                        <CardSkeletonItems
                                            height="25vh"
                                            width="100%"
                                            paddingBottom="0px"
                                            narr={[1, 2, 3, 4, 5, 6]}
                                        />
                                    )}
                                </ShowroomCarContainer>
                            ) : !isSearchLoading ? (
                                <ShowroomNoResultsBox page="sold" />
                            ) : (
                                <CardSkeletonItems
                                    height="25vh"
                                    width="100%"
                                    paddingBottom="0px"
                                    narr={[1, 2, 3, 4, 5, 6]}
                                />
                            )}

                            <BottomPaginationNavigation
                                results={entriesList}
                                currentPage={currentPageNumberSold}
                                isLastPage={isLastPage}
                                onClickPrev={onClickPrev}
                                onClickNext={onClickNext}
                                onClickPageNumber={onClickPageNumber}
                                totalCount={sold_results_total_count ?? 0}
                                itemsPerPage={itemsPerPage}
                            />
                            {!userLoggedIn ? (
                                <ShowroomPublicFooterMobile />
                            ) : (
                                <div style={{ paddingBottom: 300 }} />
                            )}
                        </SectionWrapper>
                    </PageWrapper>
                </div>
            </IpadAndMobileDisplay>
        </>
    )
}

export default ShowroomSoldListPage
