import * as React from 'react'
import styled from 'styled-components'
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'

interface Props {
    children: React.ReactNode
    isOpen: boolean
    toggle: any
    modalBackgroundColor?: string
    top?: string
    bottom?: string
    left?: string
    right?: string
}

interface Istyle {
    isOpen?: boolean
    backgroundColor?: string
    $top?: string
    $bottom?: string
    $left?: string
    $right?: string
}

const DesktopPageWrapperStyle = styled.div<Istyle>`
    z-index: 12;
    position: absolute;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : 'rgba(0, 0, 0, 0.65)'};
    top: ${(props) => (props.$top ? props.$top : '0px')};
    bottom: ${(props) => (props.$bottom ? props.$bottom : '0px')};
    left: ${(props) => (props.$left ? props.$left : '0px')};
    right: ${(props) => (props.$right ? props.$right : '0px')};
    transition: all 100ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 100vh;
`

const ModalDisplayAbsolute: React.FC<Props> = ({
    isOpen,
    toggle,
    children,
    modalBackgroundColor,
    top,
    bottom,
    left,
    right,
}) => {
    return (
        <DesktopDisplayOnly>
            <DesktopPageWrapperStyle
                isOpen={isOpen}
                backgroundColor={modalBackgroundColor}
                onClick={(e: any) => {
                    e.preventDefault()
                    setTimeout(() => {
                        toggle()
                    }, 50)
                }}
                $top={top}
                $left={left}
                $right={right}
                $bottom={bottom}
            >
                {children}
            </DesktopPageWrapperStyle>
        </DesktopDisplayOnly>
    )
}

export default ModalDisplayAbsolute
