import * as React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import grey_placeholder_dark from '../../../public/assets/placeholder/grey_placeholder_dark.jpg'
import upload_white from '../../../public/assets/icons/upload_icon.svg'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import AddBoxWithSvgPlaceholderDesktop from '../placeholders/addBoxWithSvgPlaceholderDesktop'
import banner_placeholder_desktop from '../../../public/assets/placeholder_images/banner_placeholder_desktop.jpg'
import banner_placeholder_desktop_dark from '../../../public/assets/placeholder_images/banner_placeholder_desktop_dark.png'
import { IGalleryImage } from '../../../redux/entities/galleries/types'
import useThemes from '../../../providers/theme/hooks'

type IImgProps = {
    isthingloading: boolean
    showCoverPhotoOptionsMode?: boolean
}

const ImageStyle = styled.div<IImgProps>`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: ${(props) => (props.isthingloading === true ? 0 : 1)};
    transition: opacity 400ms;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
`
const ImageStyleWrapper = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 400px;
    cursor: pointer;
    z-index: 1;

    @media ${device.ipad} {
        height: 250px;
    }

    @media ${device.smallest_laptops} {
        height: 280px;
    }

    @media ${device.large_desktop} {
        height: 400px;
    }

    @media ${device.wide_desktop} {
        height: 450px;
    }
`

type IProps2 = {
    showCoverPhotoOptionsMode: boolean
}

const ImageOverlayStyle = styled.div<IProps2>`
    position: absolute;
    bottom: 0px;
    width: 100%;
    box-sizing: border-box;
    height: 40%;
    background: linear-gradient(rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.4) 70%);
    opacity: ${(props) => (props.showCoverPhotoOptionsMode ? 1 : 0)};
`

const UploadLogoStyle = styled.img`
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 18px;
    left: 18px;
`

export interface IImageProps {
    img?: IGalleryImage | undefined | null
    placeholder?: string
    toggleCarBannerImageForm?: any
    hasSvgBackgroundImage?: boolean
    readOnlyMode?: boolean
}

const CarOverviewBanner: React.FC<IImageProps> = ({
    img,
    placeholder,
    toggleCarBannerImageForm,
    hasSvgBackgroundImage,
    readOnlyMode,
}) => {
    const [showCoverPhotoOptions, setShowCoverPhotoOptions] =
        useState<boolean>(false)

    const [isLoading, setIsLoading] = useState(true)
    const { theme } = useThemes()

    const defaultPlaceholder =
        theme === 'dark' ? grey_placeholder_dark : grey_placeholder

    const imageSrc = img?.url || placeholder || defaultPlaceholder

    useEffect(() => {
        const image = new Image()
        image.src = imageSrc
        image.onload = () => setIsLoading(false)
        image.onerror = () => setIsLoading(false) // Ensure state updates on error.
    }, [img])

    const setShowCoverPhotoOptionsModeTrue = () =>
        setShowCoverPhotoOptions(true)
    const setShowCoverPhotoOptionsModeFalse = () =>
        setShowCoverPhotoOptions(false)

    return (
        <React.Fragment>
            {img !== undefined ? (
                <ImageStyleWrapper
                    style={readOnlyMode ? { cursor: 'default' } : {}}
                >
                    <SkeletonAbsolute isthingloading={isLoading} />
                    <ImageStyle
                        role="img"
                        aria-label="car overview banner"
                        style={{
                            backgroundImage: `url(${imageSrc})`,
                        }}
                        onMouseEnter={setShowCoverPhotoOptionsModeTrue}
                        onMouseLeave={setShowCoverPhotoOptionsModeFalse}
                        isthingloading={isLoading}
                    >
                        {!readOnlyMode && (
                            <ImageOverlayStyle
                                role="img"
                                aria-label="car overview banner"
                                showCoverPhotoOptionsMode={
                                    showCoverPhotoOptions
                                }
                                onClick={
                                    !readOnlyMode
                                        ? toggleCarBannerImageForm
                                        : () => {}
                                }
                            >
                                <UploadLogoStyle
                                    src={upload_white}
                                    alt="upload"
                                />
                            </ImageOverlayStyle>
                        )}
                    </ImageStyle>
                </ImageStyleWrapper>
            ) : (
                <AddBoxWithSvgPlaceholderDesktop
                    onClickAdd={toggleCarBannerImageForm}
                    hooverText="Add your car's banner image"
                    readOnlyMode={readOnlyMode}
                    hasSvgBackgroundImage={hasSvgBackgroundImage}
                    customPlaceholderImg={
                        theme === 'dark'
                            ? banner_placeholder_desktop_dark
                            : banner_placeholder_desktop
                    }
                    height={'100px'}
                    hasAddTxtIcon={true}
                />
            )}
        </React.Fragment>
    )
}

export default CarOverviewBanner
