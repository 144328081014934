import { call, fork, put, take, select } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { api } from '../../../services'
import posthog from 'posthog-js'
import {
    ConvertSingleDirectShare_owned,
    IConvertSingleDirectShareOwnedArg,
} from '../../../conversions/shares/convertDirectShares'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { ConvertSharePermissionIDsToAPI } from '../../../conversions/shares/ConvertSharePermissionIds'
import { IRootState } from '../../../store'
import {
    IApi_CreateDirectShare_args,
    IDirectShareOwnedItem_Api_payload,
} from '../../../services/types'
import {
    ICreateDirectShareRequest,
    IDirectShareOwnedItemsData,
    IDirectSharesSuccessPayload_redux_arg,
    IReduxError,
} from '../../cars/types'
import { IGarageObject } from '../types'
import { garagesActions } from '../reducer'
import { push } from 'redux-first-history'

const getGarageState = (state: IRootState) => state.entities.garagesData.garages

export function* CreateGarageDirectShareSaga(p: ICreateDirectShareRequest) {
    try {
        let api_payload: IApi_CreateDirectShare_args = {
            resource_id: p.resource_id,
            resource_type: p.resource_type,
            share_type: p.share_type,
            title: p.title,
            recipients: p.recipients,
            policy_statements: p.permissionIDS
                ? ConvertSharePermissionIDsToAPI(p.permissionIDS)
                : undefined,
        }
        const res: IDirectShareOwnedItem_Api_payload = yield call(
            api.sharing.createDirectShareAPI,
            api_payload
        )

        let p_conv: IConvertSingleDirectShareOwnedArg = {
            api_payload: res,
        }

        const direct_share_owned = ConvertSingleDirectShare_owned(p_conv)

        const garageDataState: IGarageObject = yield select(getGarageState)

        let private_shares_in_state =
            garageDataState[p.resource_id] &&
            garageDataState[p.resource_id].private_shares_owned

        if (private_shares_in_state) {
            let private_shares_ids_in_state: string[] =
                private_shares_in_state.entity_owned_direct_shares_ids
                    ? private_shares_in_state.entity_owned_direct_shares_ids
                    : []
            let private_shares_data_in_state: IDirectShareOwnedItemsData =
                private_shares_in_state.entity_owned_direct_shares_data
                    ? private_shares_in_state.entity_owned_direct_shares_data
                    : {}
            let reduxPayload: IDirectSharesSuccessPayload_redux_arg = {
                id: p.resource_id,
                private_shares_owned: {
                    entity_owned_direct_shares_ids: [
                        ...private_shares_ids_in_state,
                        direct_share_owned.id,
                    ],
                    entity_owned_direct_shares_data: {
                        ...private_shares_data_in_state,
                        [direct_share_owned.id]: direct_share_owned,
                    },
                },
            }
            yield put(
                garagesActions.createGarageDirectShareSuccess(reduxPayload)
            )
        } else {
            let reduxPayload: IDirectSharesSuccessPayload_redux_arg = {
                id: p.resource_id,
                private_shares_owned: {
                    entity_owned_direct_shares_ids: [direct_share_owned.id],
                    entity_owned_direct_shares_data: {
                        [direct_share_owned.id]: direct_share_owned,
                    },
                },
            }

            yield put(
                garagesActions.createGarageDirectShareSuccess(reduxPayload)
            )
        }

        yield put(
            push(
                `/sharing/with-others/garage/edit-share?shareid=${direct_share_owned.id}`
            )
        )

        // Capture event
        posthog.capture('CREATE GARAGE DIRECT SHARE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateGarageDirectShareSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(garagesActions.createGarageDirectShareError(typedError))
            return
        }
    }
}

function* watcherCreateCarDirectShare() {
    while (true) {
        const { payload } = yield take(
            garagesActions.createGarageDirectShareRequest
        )
        yield call(CreateGarageDirectShareSaga, payload)
    }
}
const create_garage_direct_share_sagas: any[] = [
    fork(watcherCreateCarDirectShare),
]

export default create_garage_direct_share_sagas
