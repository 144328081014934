import * as React from 'react'
import styled from 'styled-components'
import SectionHeaderV2 from '../../atoms/typography/sectionHeaderV2'
import { device } from '../../templates/displays/devices'
import copy_icon from '../../../public/assets/icons/copy_icon.svg'
import GreyIconButton from '../../atoms/Button/GreyIconButton'
import FilterBottomSheetMenuMobile from '../../atoms/menu/bottomSheetMenu/filterMenuTimelineMobile'
import { sharing_permissions_ids_selectable_list_mock } from '../../../redux/entities/cars/mockSharedItems'
import { motion } from 'framer-motion'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import { Tooltip } from 'antd'
import NoFilterResults from '../../atoms/noFilterResults/noFilterResults'
import DirectShareRowMobile from '../../atoms/shareRow/directShareRowMobile'
import PrivateSharesSortingMobile from '../../atoms/menu/bottomSheetMenu/privateSharesSortingMobile'
import TopHeaderFilterNavMobileCarShare from '../../organisms/smartHeadres/topHeaderFilterNav/topHeaderFilterNavMobileCarShare'
import BottomSheetPage from '../../templates/bottomSheetPages.tsx/bottomSheetPage'
import {
    IFilterGarageSharesDisplay_change_request,
    ISortGarageSharesDisplay_change_request,
} from '../../../redux/entities/garages/actions/loadingActions'
import QrCodeBtn from '../../atoms/Button/qrCodeBtn'
import { editFormsActions } from '../../../redux/editForms/reducer'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import AddInfoBannerMobile from '../../atoms/Button/addInfoBannerMobile'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IGarage } from '../../../redux/entities/garages/types'
import { IDeleteShareReq } from '../../../redux/entities/cars/actions'
import { IisOpen } from '../../../redux/menus/types'
import { IDirectShareDeletionConfirmation } from '../../../redux/editForms/types'
import {
    IDirectShareOwnedItem,
    IPrivateSharesActiveDisplayArgs,
    ISharingPermissionIDS,
} from '../../../redux/entities/cars/types'
import { IDropdownItem } from 'entityModels'

const FiltersContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

interface Props {
    garage: IGarage | undefined
    createGaragePublicShare: (p: string) => void
    dataCyId?: string
    handleChange_shares_filter: (
        p: IFilterGarageSharesDisplay_change_request
    ) => void
    handleChange_shares_sort: (
        p: ISortGarageSharesDisplay_change_request
    ) => void
    deleteGaragePublicShare: (p: IDeleteShareReq) => void
    openGarageQrCodeModal: (payload: IisOpen) => void
    areGarageSharesLoading: boolean
}

interface IStyled {
    isActive?: boolean
}

const Container = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Lato';
    padding: 20px;
    @media ${device.ipad} {
        padding: 40px;
    }
    @media ${device.mobile} {
        width: 100vw;
    }
    padding-top: 42px;
`

const TitleWrapper = styled.div<IStyled>`
    max-width: 450px;
`

const Row = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const Description = styled.div<IStyled>`
    font-family: 'Lato';
    font-size: 12px;
    overflow-wrap: break-word;
    color: var(--text-darker, #616161);
    line-height: 22px;

    @media ${device.ipad} {
        font-size: 14px;
        width: 70%;
        max-width: 450px;
    }
`

const Tag = styled.div<IStyled>`
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${(props) =>
        props.isActive
            ? 'var(--insurance_details_green)'
            : 'var(--border_muted_neutral)'};
    color: var(--bg-color, #fff);
    font-family: 'Lato';
    text-align: center;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 15px;
    font-size: 9px;
`

const BtnBlue = styled.a`
    color: var(--primary, #5ec3ca) !important;
    font-size: 12px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: auto;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    :hover {
        opacity: 0.7;
    }

    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const BtnRed = styled.button`
    color: var(--error, #df6f6f);
    font-size: 12px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: auto;
    font-family: 'Lato';
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    :hover {
        opacity: 0.7;
    }
    padding: 0px;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const LinkWrapper = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.1s;
    cursor: pointer;
    background-color: transparent;
    :focus {
        outline: 0;
    }
    :hover {
        opacity: 0.7;
    }
`

const LinkTxt = styled.div`
    color: var(--text-darker, #616161);
    font-size: 12px;
    line-height: 20px;
    padding-right: 5px;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`
const CopyIcon = styled.img`
    height: 20px;
`

type IFilterButtonProp = {
    $content?: number | undefined
}

const FilterButtonWrapper = styled.div<IFilterButtonProp>`
    padding-left: 15px;
    position: relative;
    ::after {
        display: ${(props) => (props.$content ? 'block' : 'none')};
        position: absolute;
        right: 0;
        top: 0;
        margin-right: -5px;
        margin-top: -7px;
        height: 15px;
        width: 15px;
        background-color: var(--primary, #5ec3ca);
        font-size: 10px;
        color: var(--bg-color, #ffffff);
        border: transparent;
        border-radius: 4px;
        text-align: center;
        content: ${(props) => (props.$content ? `'${props.$content}'` : '')};
    }
`

const SingleGarageShareBodyMobile: React.FC<Props> = ({
    dataCyId,
    garage,
    createGaragePublicShare,
    handleChange_shares_filter,
    handleChange_shares_sort,
    openGarageQrCodeModal,
    deleteGaragePublicShare,
    areGarageSharesLoading,
}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const publicLinkDeactivationConfirmationModal = useAppSelector(
        (state) =>
            state.editForms.formsData.publicLinkDeactivationConfirmationModal
                .isOpen
    )

    const [state, setState] = React.useState({
        isSearchPageOpen: false,
        isFilterBottomSheetOpen: false,
        isSortBottomSheetOpen: false,
        showTooltip: false,
    })

    const onAddShareClick = () => {
        garage &&
            garage.uid &&
            navigate(`/sharing/with-others/garage/create-share`)
    }

    const toggleTooltip = () => {
        setState((prevState) => ({ ...prevState, showTooltip: true }))
        setTimeout(() => {
            setState((prevState) => ({ ...prevState, showTooltip: false }))
        }, 500)
    }

    const togglePublicLinkDeactivConfModal = (p: IisOpen) => {
        dispatch(editFormsActions.togglePublicLinkDeactivConfModal(p))
    }

    const toggleShareDeletionConfirmation = (
        p: IDirectShareDeletionConfirmation
    ) => {
        dispatch(editFormsActions.toggleShareDeletionConfirmation(p))
    }

    let has_garage_public_share: boolean =
        garage &&
        garage.public_share_owned &&
        garage.public_share_owned.isActive
            ? garage.public_share_owned.isActive
            : false
    let categoryData: IDropdownItem[] =
        sharing_permissions_ids_selectable_list_mock

    let sharesDisplayState: IPrivateSharesActiveDisplayArgs | undefined =
        garage && garage.private_shares_owned?.active_display

    let garage_link = () => {
        let link =
            garage && garage.public_share_owned && garage.public_share_owned.url
                ? garage.public_share_owned.url
                : ''

        if (window.innerWidth > 700) {
            return link
        } else if (window.innerWidth <= 700 && window.innerWidth > 600) {
            if (link.length > 63) {
                return `${link.slice(0, 63)}...`
            } else return link
        } else if (window.innerWidth <= 600 && window.innerWidth > 320) {
            if (link.length > 40) {
                return `${link.slice(0, 40)}...`
            } else return link
        } else if (window.innerWidth <= 320 && window.innerWidth > 300) {
            if (link.length > 30) {
                return `${link.slice(0, 30)}...`
            } else return link
        } else if (window.innerWidth <= 300) {
            if (link.length > 20) {
                return `${link.slice(0, 20)}...`
            } else return link
        } else return link
    }

    let garagePrivateSharesOwned = garage && garage.private_shares_owned
    let garageHasDirectShares: boolean =
        garagePrivateSharesOwned &&
        garagePrivateSharesOwned.entity_owned_direct_shares_ids &&
        garagePrivateSharesOwned.entity_owned_direct_shares_ids.length > 0
            ? true
            : false

    let directSharesToDisplayExtractor = (): {
        shares: IDirectShareOwnedItem[] | undefined
        hasActiveSearchCriteria: boolean
    } => {
        let sharesToDisplayArray: IDirectShareOwnedItem[] = []
        if (garagePrivateSharesOwned) {
            if (
                garagePrivateSharesOwned.active_display &&
                garagePrivateSharesOwned.active_display.item_ids_display &&
                garagePrivateSharesOwned.active_display.item_ids_display
                    .length > 0
            ) {
                garagePrivateSharesOwned.active_display.item_ids_display.map(
                    (shareID: string, index: number) =>
                        sharesToDisplayArray.push(
                            garagePrivateSharesOwned!
                                .entity_owned_direct_shares_data[shareID]
                        )
                )
                return {
                    shares:
                        sharesToDisplayArray && sharesToDisplayArray.length > 0
                            ? sharesToDisplayArray
                            : undefined,
                    hasActiveSearchCriteria: true,
                }
            } else if (
                garagePrivateSharesOwned.active_display &&
                garagePrivateSharesOwned.active_display?.activeFilter
            ) {
                return {
                    shares: sharesToDisplayArray
                        ? sharesToDisplayArray
                        : undefined,
                    hasActiveSearchCriteria: true,
                }
            } else {
                garagePrivateSharesOwned &&
                    garagePrivateSharesOwned.entity_owned_direct_shares_ids &&
                    garagePrivateSharesOwned.entity_owned_direct_shares_ids.map(
                        (shareID: string, index: number) =>
                            sharesToDisplayArray.push(
                                garagePrivateSharesOwned!
                                    .entity_owned_direct_shares_data[shareID]
                            )
                    )
                return {
                    shares:
                        sharesToDisplayArray && sharesToDisplayArray.length > 0
                            ? sharesToDisplayArray
                            : undefined,
                    hasActiveSearchCriteria: false,
                }
            }
        } else return { shares: undefined, hasActiveSearchCriteria: false }
    }

    let directSharesToDisplay: IDirectShareOwnedItem[] | undefined =
        directSharesToDisplayExtractor().shares

    let hasActiveSearchCriteria: boolean =
        directSharesToDisplayExtractor().hasActiveSearchCriteria

    return (
        <>
            <Container data-attr={dataCyId && dataCyId}>
                <TitleWrapper>
                    <Row>
                        <SectionHeaderV2
                            isbold
                            icon="v2_add"
                            hasActionIcon={false}
                        >
                            Shareable Link
                        </SectionHeaderV2>
                        <Tag isActive={has_garage_public_share}>
                            {has_garage_public_share === true
                                ? 'active'
                                : 'inactive'}
                        </Tag>
                    </Row>
                </TitleWrapper>
                <Description>
                    A public link allows you to share your garage - and its
                    respective cars - with anyone you'd like.
                    <br />
                    You can remove access to this link at any time.
                </Description>

                <div
                    style={{
                        paddingTop: '20px',
                    }}
                />

                {areGarageSharesLoading ? (
                    <LoaderElasticThreeDots />
                ) : has_garage_public_share ? (
                    <>
                        <LinkWrapper
                            onClick={() => {
                                toggleTooltip()
                                navigator.clipboard.writeText(
                                    garage &&
                                        garage.public_share_owned &&
                                        garage.public_share_owned.url
                                        ? garage.public_share_owned.url
                                        : ''
                                )
                            }}
                        >
                            <LinkTxt>
                                {garage &&
                                    garage.public_share_owned &&
                                    garage_link()}
                            </LinkTxt>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CopyIcon src={copy_icon} />

                                <Tooltip
                                    title="Copied!"
                                    open={state.showTooltip}
                                    placement="bottom"
                                    color={'#2a969ad3'}
                                />
                            </div>
                        </LinkWrapper>
                        <div style={{ paddingTop: '20px' }} />
                        <QrCodeBtn
                            txt="qr code"
                            height="42px"
                            iconSize="22px"
                            alwaysHasText
                            width="150px"
                            onClick={openGarageQrCodeModal}
                        />
                        <div
                            style={{
                                paddingTop: has_garage_public_share
                                    ? '20px'
                                    : '30px',
                            }}
                        />
                        <BtnBlue
                            href={
                                garage &&
                                garage.public_share_owned &&
                                garage.public_share_owned.url
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            Preview Digital Garage Profile
                        </BtnBlue>
                        <div style={{ paddingTop: '15px' }} />
                        <BtnRed
                            onClick={() =>
                                togglePublicLinkDeactivConfModal({
                                    isOpen: true,
                                })
                            }
                        >
                            Deactivate Shareable Link
                        </BtnRed>
                        <div
                            style={{
                                paddingTop: !has_garage_public_share
                                    ? '30px'
                                    : '40px',
                            }}
                        />
                    </>
                ) : (
                    <motion.div whileTap={{ scale: 1.05 }}>
                        {!has_garage_public_share ? (
                            <div style={{ paddingTop: '0px' }} />
                        ) : null}
                        <ButtonAtom
                            onClick={() => {
                                garage &&
                                    garage.uid &&
                                    createGaragePublicShare(garage.uid)
                            }}
                            theme="lowercase-white-background"
                            height="45px"
                            width="80vw"
                            fontSize={13}
                        >
                            <div style={{ textTransform: 'capitalize' }}>
                                Generate Garage Shareable Link
                            </div>
                        </ButtonAtom>

                        {!has_garage_public_share ? (
                            <div style={{ paddingTop: '50px' }} />
                        ) : null}
                    </motion.div>
                )}

                <Row>
                    <SectionHeaderV2 isbold hasActionIcon={false} width="100%">
                        Direct Shares
                    </SectionHeaderV2>
                    <FiltersContainer>
                        <GreyIconButton
                            onClick={() =>
                                setState((prevState) => ({
                                    ...prevState,
                                    isSortBottomSheetOpen: true,
                                }))
                            }
                            variant="sort"
                            isActive={
                                sharesDisplayState &&
                                sharesDisplayState.activeSort &&
                                sharesDisplayState.activeSort.length > 0
                                    ? true
                                    : false
                            }
                            sortingCriteria={
                                sharesDisplayState &&
                                sharesDisplayState.activeSort
                                    ? sharesDisplayState.activeSort
                                    : 'created_at'
                            }
                            isDisabled={garageHasDirectShares === false}
                        />
                        <FilterButtonWrapper
                            $content={
                                sharesDisplayState &&
                                sharesDisplayState.activeFilter &&
                                sharesDisplayState.activeFilter.length
                            }
                        >
                            <GreyIconButton
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        isFilterBottomSheetOpen: true,
                                    }))
                                }
                                variant="filter"
                                isActive={
                                    sharesDisplayState &&
                                    sharesDisplayState.activeFilter &&
                                    sharesDisplayState.activeFilter.length > 0
                                        ? true
                                        : false
                                }
                                isDisabled={garageHasDirectShares === false}
                            />
                        </FilterButtonWrapper>
                    </FiltersContainer>
                </Row>

                <div style={{ paddingTop: '10px' }} />

                <Row>
                    <Description>
                        A direct share allows you to privately share your garage
                        - and its respective cars - only with the people you
                        choose. You can delete a direct share at any time.
                    </Description>
                </Row>

                <div style={{ paddingTop: '30px' }} />
                {directSharesToDisplay !== undefined &&
                directSharesToDisplay.length > 0 ? (
                    directSharesToDisplay.map(
                        (share: IDirectShareOwnedItem, index: number) => (
                            <DirectShareRowMobile
                                key={`direct_share_desktop_${index}`}
                                isGarage
                                share={share}
                                index={index}
                                isLast={
                                    garage &&
                                    garage.private_shares_owned
                                        ?.entity_owned_direct_shares_ids &&
                                    garage.private_shares_owned
                                        ?.entity_owned_direct_shares_ids
                                        .length - 1
                                        ? true
                                        : false
                                }
                                actionsData={{
                                    addUserToShare: () =>
                                        navigate(
                                            `/sharing/with-others/garage/edit-share?shareid=${share.id}&active_tab_id=recipients`
                                        ),

                                    settings: () =>
                                        navigate(
                                            `/sharing/with-others/garage/edit-share?shareid=${share.id}&active_tab_id=settings`
                                        ),
                                    delete: () =>
                                        garage &&
                                        toggleShareDeletionConfirmation({
                                            entity_type: 'garage',
                                            entity_id: garage.uid,
                                            share_id: share.id,
                                            isOpen: true,
                                        }),
                                }}
                            />
                        )
                    )
                ) : garageHasDirectShares && hasActiveSearchCriteria ? (
                    <NoFilterResults
                        text="No results containing all your search terms were found."
                        variant={'desktop'}
                    />
                ) : (
                    <>
                        <div style={{ paddingTop: '20px' }} />

                        <AddInfoBannerMobile
                            onClick={() => onAddShareClick()}
                            action="add"
                            width="100%"
                            height="70px"
                            placeholder="add a private share for your garage"
                        />
                    </>
                )}

                {directSharesToDisplay !== undefined &&
                directSharesToDisplay.length > 0 ? (
                    <>
                        <div style={{ paddingTop: '20px' }} />

                        <AddInfoBannerMobile
                            onClick={() => onAddShareClick()}
                            action="add"
                            width="100%"
                            height="70px"
                            placeholder="add a private share for your garage"
                        />
                    </>
                ) : null}

                <DeletionConfirmationBottomSheet
                    customHeight="340px"
                    isOpen={publicLinkDeactivationConfirmationModal}
                    toggle={() =>
                        togglePublicLinkDeactivConfModal({
                            isOpen: false,
                        })
                    }
                    action={() => {
                        deleteGaragePublicShare({
                            entity_id: garage && garage.uid ? garage.uid : '',
                            share_id:
                                garage &&
                                garage.public_share_owned &&
                                garage.public_share_owned.id
                                    ? garage.public_share_owned.id
                                    : '',
                        })
                    }}
                    actionCopy="Confirm"
                    titleCopy="Are you sure?"
                    detailsCopy="Once deactivated, the link and associated QR code will no longer give access to your car. If you decide to share your car in the future, this will generate a new shareable link and a different QR code, with your previous QR code no longer working."
                />
            </Container>
            {garage && (
                <FilterBottomSheetMenuMobile
                    activeCriteria={sharesDisplayState?.activeFilter}
                    criteriaData={categoryData}
                    sendFiltersToApi={(selected: ISharingPermissionIDS[]) => {
                        handleChange_shares_filter({
                            garageid: garage.uid,
                            filterIDs: selected,
                        })
                    }}
                    isFilterBottomSheetMenuOpen={state.isFilterBottomSheetOpen}
                    toggle={() =>
                        setState((prevState) => ({
                            ...prevState,
                            isFilterBottomSheetOpen: false,
                        }))
                    }
                    customHeight="60vh"
                    ipadCustomHeight="40vh"
                    notCapitalised
                />
            )}
            {garage && (
                <PrivateSharesSortingMobile
                    actions_items={[
                        {
                            id: 'created_at',
                            text: 'Created at',
                            action: () => {
                                handleChange_shares_sort({
                                    garageid: garage.uid,
                                    sortID: 'created_at',
                                })
                            },
                        },
                        {
                            id: 'updated_at',
                            text: 'Updated at',
                            action: () =>
                                handleChange_shares_sort({
                                    garageid: garage.uid,
                                    sortID: 'updated_at',
                                }),
                        },
                        {
                            id: 'alphabetical',
                            text: 'Alphabetical',
                            action: () =>
                                handleChange_shares_sort({
                                    garageid: garage.uid,
                                    sortID: 'alphabetical',
                                }),
                        },
                    ]}
                    toggle={() =>
                        setState((prevState) => ({
                            ...prevState,
                            isSortBottomSheetOpen: false,
                        }))
                    }
                    isPrivateSharesSortingOpen={state.isSortBottomSheetOpen}
                    activeSort={
                        sharesDisplayState && sharesDisplayState?.activeSort
                            ? sharesDisplayState?.activeSort
                            : ''
                    }
                />
            )}
            <BottomSheetPage
                isOpen={state.isSearchPageOpen}
                toggle={() =>
                    setState((prevState) => ({
                        ...prevState,
                        isSearchPageOpen: !prevState.isSearchPageOpen,
                    }))
                }
            >
                <TopHeaderFilterNavMobileCarShare
                    onFilterIconClick={() =>
                        setState((prevState) => ({
                            ...prevState,
                            isFilterBottomSheetOpen: true,
                        }))
                    }
                    onSortIconClick={() =>
                        setState((prevState) => ({
                            ...prevState,
                            isSortBottomSheetOpen: true,
                        }))
                    }
                    onReturn={() => {
                        setState((prevState) => ({
                            ...prevState,
                            isSearchPageOpen: false,
                        }))
                    }}
                    activeFilters={0}
                    sortingCriteria={'created_at'}
                    searchFor={''}
                    onSearch={(query: string) => {}}
                    clearSearch={() => {}}
                />
                <FilterBottomSheetMenuMobile
                    activeCriteria={[]}
                    criteriaData={categoryData}
                    sendFiltersToApi={(selected: ISharingPermissionIDS[]) => {}}
                    isFilterBottomSheetMenuOpen={state.isFilterBottomSheetOpen}
                    toggle={() =>
                        setState((prevState) => ({
                            ...prevState,
                            isFilterBottomSheetOpen: false,
                        }))
                    }
                    notCapitalised
                />
                {garage && (
                    <PrivateSharesSortingMobile
                        actions_items={[
                            {
                                id: 'created_at',
                                text: 'Created At',
                                action: () =>
                                    handleChange_shares_sort({
                                        garageid: garage.uid,
                                        sortID: 'created_at',
                                    }),
                            },
                            {
                                id: 'updated_at',
                                text: 'Updated At',
                                action: () =>
                                    handleChange_shares_sort({
                                        garageid: garage.uid,
                                        sortID: 'updated_at',
                                    }),
                            },
                            {
                                id: 'alphabetical',
                                text: 'Alphabetical',
                                action: () =>
                                    handleChange_shares_sort({
                                        garageid: garage.uid,
                                        sortID: 'alphabetical',
                                    }),
                            },
                        ]}
                        toggle={() =>
                            setState((prevState) => ({
                                ...prevState,
                                isSortBottomSheetOpen: false,
                            }))
                        }
                        isPrivateSharesSortingOpen={state.isSortBottomSheetOpen}
                        activeSort={
                            sharesDisplayState && sharesDisplayState?.activeSort
                                ? sharesDisplayState?.activeSort
                                : ''
                        }
                    />
                )}
            </BottomSheetPage>
        </>
    )
}

export default SingleGarageShareBodyMobile
