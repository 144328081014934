import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../../../redux/store/hooks'
import Loader from '../../../atoms/loader/loader'
import { usersActions } from '../../../../redux/user/reducer'

const AppleRedirectHandler = () => {
    const location = useLocation()

    const dispatch = useAppDispatch()

    useEffect(() => {
        // Extract the authorization code from URL parameters
        const params = new URLSearchParams(location.search)
        const authorizationCode = params.get('code')
        const platform_consent = params.get('platform_consent')
        const apex_consent = params.get('apex_consent')
        const fbclid = params.get('fbclid')
        const next_path = params.get('next_path')
        const is_registration = params.get('is_registration')
        const from_landing = params.get('from_landing')

        if (is_registration === 'true') {
            dispatch(
                usersActions.socialAuthRequest({
                    platformConsent: platform_consent === 'true' ? true : false,
                    apexConsent: apex_consent === 'true' ? true : false,
                    fbclid: fbclid ? `${fbclid}` : null,
                    authorization_code: authorizationCode ?? undefined,
                    next_path: next_path,
                    provider: 'apple',
                    is_registration: true,
                    from_landing: from_landing === 'true' ? true : false,
                })
            )
        } else {
            dispatch(
                usersActions.socialAuthRequest({
                    platformConsent: platform_consent === 'true' ? true : false,
                    apexConsent: apex_consent === 'true' ? true : false,
                    fbclid: fbclid ? `${fbclid}` : null,
                    authorization_code: authorizationCode ?? undefined,
                    next_path: next_path,
                    provider: 'apple',
                    is_registration: false,
                    from_landing: false,
                })
            )
        }
    }, [location, history])

    return (
        <div>
            <Loader />
        </div>
    )
}

export default AppleRedirectHandler
