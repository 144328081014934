import * as React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import { Link, useLocation } from 'react-router-dom'
import { topMenuList, currentPageGroup } from './mainMenuData'
import * as NavigationIcons from '../../statefulicons'
import TopSubMenuDesktop from './topSubMenuDesktop'
import { menuActions } from '../../../../redux/menus/reducer'
import { Spin, Tooltip } from 'antd'
import { device } from '../../../templates/displays/devices'
import chevron_to_left from '../../../../public/assets/navigation/chevron_to_left.svg'
import * as SvgIcons from '../../icons/components'
import IdeasButtonOnNav from '../../../templates/ideasButton/ideasButtonOnNav'
import InsuranceIcon from '../../icons/components/insuranceIcon'
import Faded from '../../../templates/animated/faded'
import colours, { ITheme } from '../../../../providers/theme/colours'
import LeftAlignLogo from '../../icons/components/leftAlignLogo'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import useThemes from '../../../../providers/theme/hooks'
import { insuranceActions } from '../../../../redux/insuranceQuoteApplication/reducer'

interface IProps {
    activepage: string
}

type IStyle = {
    $theme: ITheme
    $isCollapsed?: boolean
    $ispageactive?: boolean
    $isIdeas?: boolean
}
const MenuWrapper = styled.div<IStyle>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    display: flex;
    display: -webkit-flex;
    flex-basis: 100% !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-right: 0.5px solid ${(props) => colours[props.$theme].border_muted};
    padding-bottom: 16px;
    transition-duration: 100ms;
    z-index: 11;

    @media ${device.desktop} {
        width: 200px;
        transform: ${(props) =>
            props.$isCollapsed ? 'translateX(-140px)' : 'translateX(0px)'};
    }
    @media ${device.large_desktop} {
        width: 280px;
        transform: ${(props) =>
            props.$isCollapsed ? 'translateX(-200px)' : 'translateX(0px)'};
    }
    @media ${device.smallest_laptops} {
        width: 200px;
        transform: ${(props) =>
            props.$isCollapsed ? 'translateX(-140px)' : 'translateX(0px)'};
    }
`

const ItemText = styled.div<IStyle>`
    text-align: left;
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-Light;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    display: ${(props) => (props.$isCollapsed ? 'none' : 'auto')};
    @media ${device.desktop} {
        font-size: 14px;
    }
    @media ${device.large_desktop} {
        font-size: 16px;
    }
    @media ${device.smallest_laptops} {
        font-size: 14px;
    }
`

const BottomSection = styled.div`
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: flex-end;
    background-color: none;
    flex-shrink: 0 !important;
`

const ToggleCollapseIcon = styled.img<IStyle>`
    width: 8px;
    margin-bottom: ${(props) => (props.$isCollapsed ? '16px' : '8px')};
    margin-right: 24px;
    animation: all 3s;
    transform: ${(props) =>
        props.$isCollapsed
            ? 'rotate(180deg) translateX(-1px)'
            : 'rotate(0deg)'};
    user-select: none;
`

const TopListWrap = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-shrink: 0 !important;
`

const LinkStyle = styled(Link)<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 64px;
    transition: all 200ms;
    background-color: ${(props) =>
        props.$ispageactive
            ? colours[props.$theme].background_muted
            : 'transparent'};
    :hover {
        background-color: ${(props) =>
            props.$isIdeas
                ? 'transparent'
                : colours[props.$theme].background_muted};
    }
    :focus {
        outline: 0;
    }
    @media ${device.smallest_laptops} {
        height: 56px;
    }
`

const NoLinkStyle = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 64px;
    transition: all 200ms;
    background-color: ${(props) =>
        props.$ispageactive
            ? colours[props.$theme].background_muted
            : 'transparent'};
    :hover {
        background-color: ${(props) =>
            props.$isIdeas
                ? 'transparent'
                : colours[props.$theme].background_muted};
    }
    :focus {
        outline: 0;
    }
    @media ${device.smallest_laptops} {
        height: 56px;
    }
`

const AnchorLinkStyle = styled.a<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 64px;
    transition: all 200ms;
    background-color: ${(props) =>
        props.$ispageactive
            ? colours[props.$theme].background_muted
            : 'transparent'};
    :hover {
        background-color: ${(props) =>
            props.$isIdeas
                ? 'transparent'
                : colours[props.$theme].background_muted};
    }
    :focus {
        outline: 0;
    }
    @media ${device.smallest_laptops} {
        height: 56px;
    }
`

const MenuItemWrap = styled.button`
    padding: 0;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-shrink: 0 !important;
    flex-basis: 100% !important;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.2s;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border: transparent !important;
    user-select: none;
    background-color: transparent;
`

const TopLogoAndMenu = styled.button`
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.2s;
    height: 64px;
    width: 100%;
    box-sizing: border-box;
    @media (min-width: 881px) and (max-width: 1600px) {
        margin-bottom: 0;
    }
`

const IconAnimationStyle = styled.div<IStyle>`
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.desktop} {
        transform: ${(props) =>
            props.$isCollapsed ? 'translate(135px, 0px)' : 'translate(0px)'};
    }
    @media ${device.large_desktop} {
        transform: ${(props) =>
            props.$isCollapsed ? 'translate(205px, 0px)' : 'translate(0px)'};
    }
    @media ${device.smallest_laptops} {
        transform: ${(props) =>
            props.$isCollapsed ? 'translate(135px, 0px)' : 'translate(0px)'};
    }
`

const NewsFlag = styled.div<IStyle>`
    position: absolute;
    top: -10px;
    left: 135px;
    box-shadow: 2px 4px 7px
        ${(props) => colours[props.$theme].shadow_strongest_03};
    text-transform: uppercase;
    background-color: ${(props) => colours[props.$theme].error};
    height: 19px;
    width: 38px;
    border-radius: 5px;
    color: ${(props) => colours[props.$theme].background_default};
    font-family: 'Lato-bold';
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    @media ${device.desktop} {
        transform: ${(props) =>
            props.$isCollapsed
                ? 'translate(-110px, -17px)'
                : 'translate(10px, 1px)'};
    }
    @media ${device.large_desktop} {
        transform: ${(props) =>
            props.$isCollapsed
                ? 'translate(-105px, -17px)'
                : 'translate(20px, 1px)'};
    }
    @media ${device.smallest_laptops} {
        transform: ${(props) =>
            props.$isCollapsed
                ? 'translate(-110px, -17px)'
                : 'translate(10px, 1px)'};
    }
`

const AccountIconAnimationStyle = styled.div<IStyle>`
    @media ${device.desktop} {
        transform: ${(props) =>
            props.$isCollapsed
                ? 'translate(146px, 0px)'
                : 'translate(3px, 2px)'};
    }
    @media ${device.large_desktop} {
        transform: ${(props) =>
            props.$isCollapsed
                ? 'translate(212px, 0px)'
                : 'translate(3px, 2px)'};
    }
    @media ${device.smallest_laptops} {
        transform: ${(props) =>
            props.$isCollapsed
                ? 'translate(146px, 0px)'
                : 'translate(3px, 2px)'};
    }
`

const SpinnerWrapper = styled.div<IStyle>`
    .ant-spin-dot-item {
        background-color: ${(props) => colours[props.$theme].text_default};
    }
`

const MainMenuDesktop: React.FC<IProps> = ({ activepage }) => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const dispatch = useAppDispatch()

    // Add this ref to track if we've already handled the initial state
    const initialStateHandled = React.useRef(false)

    let { pathname } = useLocation()

    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleResize, { passive: true })
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Separate resize listener effect

    useEffect(() => {
        if (windowSize < 1100 && !isCollapsed) {
            dispatch(menuActions.toggleDesktopMainAppMenu())
        } else if (windowSize >= 1100 && isCollapsed) {
            dispatch(menuActions.toggleDesktopMainAppMenu())
        }
    }, [windowSize])

    useEffect(() => {
        if (initialStateHandled.current) return
        initialStateHandled.current = true

        // Handle initial menu state
        if (window.location.search.match(/onboarding/g) !== null) {
            dispatch(menuActions.toggleDesktopMainAppMenu())
        } else if (
            windowSize < 1100 &&
            !isCollapsed &&
            pathname.match(/apex/g) === null
        ) {
            dispatch(menuActions.toggleDesktopMainAppMenu())
        } else if (
            windowSize > 1100 &&
            isCollapsed &&
            pathname.match(/apex/g) === null &&
            window.location.search.match(/onboarding/g) === null
        ) {
            dispatch(menuActions.toggleDesktopMainAppMenu())
        }
    }, [isCollapsed, pathname])

    // Add separate effect for window size changes
    useEffect(() => {
        if (
            windowSize < 1100 &&
            !isCollapsed &&
            window.location.search.match(/onboarding/g) === null &&
            pathname.match(/apex/g) === null
        ) {
            dispatch(menuActions.toggleDesktopMainAppMenu())
        }

        if (
            windowSize > 1100 &&
            isCollapsed &&
            window.location.search.match(/onboarding/g) === null &&
            pathname.match(/apex/g) === null
        ) {
            dispatch(menuActions.toggleDesktopMainAppMenu())
        }
    }, [])

    // Add separate effect for tutorial and apex path handling
    useEffect(() => {
        if (
            isCollapsed === true &&
            window.location.search.match(/tutorial/g) !== null
        ) {
            dispatch(menuActions.toggleDesktopMainAppMenu())
        }

        if (
            isCollapsed !== true &&
            windowSize < 1599 &&
            pathname.match(/apex/g) !== null
        ) {
            dispatch(menuActions.toggleDesktopMainAppMenu())
        }
    }, [pathname, isCollapsed])

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const isDefaultNavigationRemoved = useAppSelector(
        (state) => state.menus.isDefaultNavigationRemoved
    )
    const featureFlags = useAppSelector((state) => state.featureFlags)

    const isInsuranceLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isLoading
    )

    const checkIfMainPage = () => {
        let userid: string | undefined | null = userLoggedIn && userLoggedIn.id

        if (
            pathname.match(/garage/g) !== null ||
            (pathname === '/' && userid !== undefined && userid !== null) ||
            (pathname.match(/car/g) !== null &&
                pathname.match(/journey/g) === null) ||
            pathname.match(/apex/g) !== null ||
            pathname.match(/account/g) !== null ||
            pathname.match(/sharing/g) !== null ||
            pathname.match(/showroom/g) !== null ||
            pathname === '/upcoming' ||
            pathname === '/history-files' ||
            pathname.match(/confirm/g) !== null ||
            pathname.match(/tasks/g) !== null ||
            pathname === '/apply' ||
            pathname === '/insurance/overview'
        ) {
            return true
        } else return false
    }

    const params = new URLSearchParams(window.location.search)

    let { theme } = useThemes()

    const checkIfSubMenu = () => {
        let userid: string | undefined | null = userLoggedIn && userLoggedIn.id

        let user = userLoggedIn

        let onboardingData = params.get('onboarding')

        let onboardingDataParsed = qs.parse(onboardingData || '')

        let onboardingParam = params.get('onboarding')

        let isOnboardingOn: boolean =
            onboardingDataParsed.onboarding === 'true' ||
            onboardingParam === 'true'
                ? true
                : false

        let final = pathname.substring(pathname.lastIndexOf('/') + 1)

        // confo
        if (pathname.match(/confirm/g) !== null) {
            return false
        }
        if (pathname.match(/previously-owned/g) !== null) {
            return true
        }
        if (pathname.match(/journey/g) !== null) {
            return false
        }
        // onboarding
        else if (isOnboardingOn === true) {
            return false
        } else if (final === 'tasks') {
            return true
        } else if (pathname === '/garage') {
            return true
        } else if (
            (pathname === '/' &&
                userid !== undefined &&
                userid !== null &&
                user !== null) ||
            pathname.match(/sharing/g) !== null ||
            pathname.match(/showroom/g) !== null ||
            pathname.match(/shared/g) !== null ||
            pathname.match(/archives/g) !== null ||
            pathname === '/upcoming' ||
            (pathname.match(/car/g) !== null &&
                pathname.match(/deletion/g) === null &&
                pathname.match(/tasks/g) === null) ||
            pathname.match(/account/g) !== null ||
            pathname === '/apply' ||
            pathname === '/tasks' ||
            pathname === '/history-files' ||
            pathname.match(/apex/g) !== null ||
            pathname === '/insurance/overview'
        ) {
            return true
        } else return false
    }

    const renderIcon = (url: string, theme: ITheme, isLoading?: boolean) => {
        switch (url) {
            // ApexIcon

            case '/apex':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.ApexIcon color={colours[theme].text_darker} />
                    </IconAnimationStyle>
                )

            case '/garage':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.GarageIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )

            case '/':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.GarageIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )

            case '/history-files':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.HistoryFilesIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )

            case '/sharing/with-you':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.SharingNavIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )

            case '/upcoming':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.UpcomingIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )

            case '/tasks':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.TasksIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )

            case '/account':
                return (
                    <AccountIconAnimationStyle
                        $theme={theme}
                        $isCollapsed={isCollapsed}
                    >
                        <NavigationIcons.AccountIcon
                            active={
                                pathname === '/account' || isCollapsed === true
                                    ? true
                                    : false
                            }
                            height="14"
                            width="14"
                            color={colours[theme].text_darker}
                        />
                    </AccountIconAnimationStyle>
                )

            case '/account/preferences':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.SettingsIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )

            case 'https://app.custodian.club/showroom/for-sale':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.ShowroomIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )

            case 'hamburger':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.NavHamburgerIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )
            case 'settings':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.SettingsIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )
            case '/insurance/application':
                let enable_insurance = featureFlags['enable_insurance']

                if (enable_insurance == true) {
                    return (
                        <IconAnimationStyle
                            $theme={theme}
                            $isCollapsed={isCollapsed}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    transform:
                                        isCollapsed !== true
                                            ? 'translateX(-20px)'
                                            : 'translateX(0px)',
                                }}
                            >
                                <NewsFlag
                                    $theme={theme}
                                    $isCollapsed={isCollapsed}
                                >
                                    NEW
                                </NewsFlag>
                            </div>
                            {isLoading ? (
                                <Faded width={'auto'}>
                                    <SpinnerWrapper
                                        $theme={theme}
                                        $isCollapsed={isCollapsed}
                                    >
                                        <Spin
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                            }}
                                        />
                                    </SpinnerWrapper>
                                </Faded>
                            ) : (
                                <InsuranceIcon
                                    color={colours[theme].text_darker}
                                />
                            )}
                        </IconAnimationStyle>
                    )
                } else return null

            case 'ideas':
                return (
                    <IconAnimationStyle
                        $isCollapsed={isCollapsed}
                        $theme={theme}
                    >
                        <SvgIcons.LightbulbIcon
                            color={colours[theme].text_darker}
                        />
                    </IconAnimationStyle>
                )

            default:
                alert(`please chose an icon for ${url}`)
        }
    }

    const groupToHighlight = currentPageGroup(pathname)
    // let pathname = window.location.pathname

    let enable_showroom =
        userLoggedIn?.role === 'Admin' || userLoggedIn?.role === 'Dealer'
            ? true
            : featureFlags.enable_showroom

    let enable_insurance = featureFlags['enable_insurance']

    let showInsuranceLoading =
        isInsuranceLoading && pathname.match(/insurance/g) === null
            ? true
            : false

    const editFormsData = useAppSelector((state) => state.editForms.formsData)

    const isEditFormsDataModalOpen = () => {
        let isEditFormOn = false
        const excluded = [
            'sortBy',
            'filterBy',
            'searchMode',
            'userAccountAddressInformationForm',
        ]
        Object.keys(editFormsData).forEach((key) => {
            if (
                excluded.indexOf(key) === -1 &&
                editFormsData[key]?.isOpen === true
            ) {
                isEditFormOn = true
            }
        })
        return isEditFormOn
    }

    return (
        <>
            {checkIfSubMenu() === true &&
            isDefaultNavigationRemoved !== true &&
            isEditFormsDataModalOpen() === false ? (
                <TopSubMenuDesktop activepage={pathname} />
            ) : null}
            {checkIfMainPage() === true && (
                <MenuWrapper
                    $theme={theme}
                    $isCollapsed={isCollapsed}
                    data-tut="nav-step-car-desktop"
                >
                    <TopListWrap>
                        <TopLogoAndMenu>
                            <div
                                onClick={(e: any) => {
                                    e.preventDefault()

                                    window.location.search.match(
                                        /onboarding/g
                                    ) === null &&
                                        dispatch(
                                            menuActions.toggleDesktopMainAppMenu()
                                        )
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {renderIcon('hamburger', theme)}
                            </div>
                            {isCollapsed ? null : (
                                <Link
                                    to={{
                                        pathname: `/garage`,
                                        // state: {
                                        //     prevPath: location.pathname,
                                        // },
                                    }}
                                    style={{
                                        transform: 'translate(-8px, 3px)',
                                    }}
                                >
                                    <LeftAlignLogo
                                        colour={
                                            theme === 'light'
                                                ? 'black'
                                                : 'white'
                                        }
                                        size={24}
                                    />
                                </Link>
                            )}
                        </TopLogoAndMenu>

                        {topMenuList.map((item, index) => {
                            if (
                                item.pageName === 'showroom' &&
                                enable_showroom !== true
                            ) {
                                return null
                            }
                            if (
                                item.pageName === 'insurance' &&
                                enable_insurance !== true
                            ) {
                                return null
                            } else if (
                                item.pageName === 'insurance' &&
                                enable_insurance === true
                            ) {
                                return (
                                    <NoLinkStyle
                                        $theme={theme}
                                        onClick={() => {
                                            dispatch(
                                                insuranceActions.get_user_insurance_applications_request(
                                                    {
                                                        withNavigation: true,
                                                        withAllInfo: true,
                                                    }
                                                )
                                            )
                                        }}
                                        key={`menu_item_no_link_${index}_desktop`}
                                        $isCollapsed={isCollapsed}
                                        $ispageactive={
                                            groupToHighlight === item.url
                                                ? true
                                                : false
                                        }
                                    >
                                        <MenuItemWrap>
                                            {renderIcon(
                                                item.url,
                                                theme,
                                                showInsuranceLoading
                                            )}
                                            <ItemText
                                                $theme={theme}
                                                $isCollapsed={isCollapsed}
                                            >
                                                {item.pageName}
                                            </ItemText>
                                        </MenuItemWrap>
                                    </NoLinkStyle>
                                )
                            } else if (item.pageName === 'showroom') {
                                return (
                                    <AnchorLinkStyle
                                        $theme={theme}
                                        href={item.url}
                                        key={`menu_item_no_link_${index}_desktop`}
                                        $isCollapsed={isCollapsed}
                                        $ispageactive={
                                            groupToHighlight === item.url
                                                ? true
                                                : false
                                        }
                                    >
                                        <MenuItemWrap>
                                            {renderIcon(
                                                item.url,
                                                theme,
                                                showInsuranceLoading
                                            )}
                                            <ItemText
                                                $theme={theme}
                                                $isCollapsed={isCollapsed}
                                            >
                                                {item.pageName}
                                            </ItemText>
                                        </MenuItemWrap>
                                    </AnchorLinkStyle>
                                )
                            } else
                                return (
                                    <Tooltip
                                        overlayStyle={{
                                            visibility: isCollapsed
                                                ? 'visible'
                                                : item.pageName === 'reminders'
                                                ? 'visible'
                                                : 'hidden',
                                            fontFamily: 'Lato-Light',
                                            textTransform: 'capitalize',
                                        }}
                                        placement="right"
                                        title={
                                            item.pageName === 'reminders'
                                                ? 'In Beta Testing'
                                                : item.pageName
                                        }
                                        key={index}
                                    >
                                        {item.pageName === 'the apex' ? (
                                            <LinkStyle
                                                to={'/apex'}
                                                key={index}
                                                $theme={theme}
                                                $isCollapsed={isCollapsed}
                                                $ispageactive={
                                                    groupToHighlight ===
                                                    item.url
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <MenuItemWrap>
                                                    {renderIcon(
                                                        item.url,
                                                        theme
                                                    )}
                                                    <ItemText
                                                        $theme={theme}
                                                        $isCollapsed={
                                                            isCollapsed
                                                        }
                                                    >
                                                        {item.pageName}
                                                    </ItemText>
                                                </MenuItemWrap>
                                            </LinkStyle>
                                        ) : (
                                            <LinkStyle
                                                to={{
                                                    pathname:
                                                        item.url ===
                                                        '/reminders'
                                                            ? '/'
                                                            : item.url,
                                                    // state: {
                                                    //     prevPath:
                                                    //         location.pathname,
                                                    // },
                                                }}
                                                key={index}
                                                $theme={theme}
                                                $isCollapsed={isCollapsed}
                                                $ispageactive={
                                                    groupToHighlight ===
                                                    item.url
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <MenuItemWrap>
                                                    {renderIcon(
                                                        item.url,
                                                        theme
                                                    )}
                                                    <ItemText
                                                        $theme={theme}
                                                        $isCollapsed={
                                                            isCollapsed
                                                        }
                                                    >
                                                        {item.pageName}
                                                    </ItemText>
                                                </MenuItemWrap>
                                            </LinkStyle>
                                        )}
                                    </Tooltip>
                                )
                        })}
                    </TopListWrap>
                    <BottomSection
                        onClick={(e: any) => {
                            e.preventDefault()
                            window.location.search.match(/onboarding/g) ===
                                null &&
                                dispatch(menuActions.toggleDesktopMainAppMenu())
                        }}
                    >
                        {pathname !== '/apex' && (
                            <ToggleCollapseIcon
                                $theme={theme}
                                $isCollapsed={isCollapsed}
                                src={chevron_to_left}
                                alt="toggle collapse"
                            />
                        )}
                        <IdeasButtonOnNav
                            isCollapsed={isCollapsed}
                            userLoggedIn={userLoggedIn}
                        />
                        <LinkStyle
                            to={'/account/preferences'}
                            $theme={theme}
                            $isCollapsed={isCollapsed}
                            $ispageactive={pathname === '/account/preferences'}
                            onClick={(e: any) => e.stopPropagation()}
                        >
                            <MenuItemWrap>
                                {renderIcon('settings', theme)}
                                <ItemText
                                    $theme={theme}
                                    $isCollapsed={isCollapsed}
                                >
                                    Settings
                                </ItemText>
                            </MenuItemWrap>
                        </LinkStyle>
                    </BottomSection>
                </MenuWrapper>
            )}
        </>
    )
}

export default MainMenuDesktop
