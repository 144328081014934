import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { IGetSingleShareAPI_Arg } from '../types'

export const getSingleShareAPI = (p: IGetSingleShareAPI_Arg): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.get_share_endpoint(p), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get single share error')
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
