import { IJourneyAchievedStepData, IJourneySectionID } from 'journeyInterfaces'
import { JourneyAchievedStepDesktop } from './desktop'
import { JourneyAchievedStepMobile } from './mobile'
import past_cars_desktop from '../../../../public/assets/images/journey/pastCars/achieved_d.png'
import past_cars_mobile from '../../../../public/assets/images/journey/pastCars/achieved_m.png'
import history_file_image_desktop from '../../../../public/assets/images/journey/historyFile/achieved_d.png'
import history_file_image_mobile from '../../../../public/assets/images/journey/historyFile/achieved_m.png'
import profile_desktop from '../../../../public/assets/images/journey/profile/achieved_d.png'
import profile_mobile from '../../../../public/assets/images/journey/profile/achieved_m.png'
import car_profile_desktop from '../../../../public/assets/images/journey/carProfile/achieved_d.png'
import car_profile_mobile from '../../../../public/assets/images/journey/carProfile/achieved_m.png'
import insurance_desktop from '../../../../public/assets/images/journey/insurance/achieved_d.png'
import insurance_mobile from '../../../../public/assets/images/journey/insurance/achieved_m.png'
import share_car_desktop from '../../../../public/assets/images/journey/shareYourCar/achieved_d.png'
import share_car_mobile from '../../../../public/assets/images/journey/shareYourCar/achieved_m.png'
import sell_car_desktop from '../../../../public/assets/images/journey/sellYourCar/achieved_d.png'
import sell_car_mobile from '../../../../public/assets/images/journey/sellYourCar/achieved_m.png'
import handover_desktop from '../../../../public/assets/images/journey/sellYourCar/achieved_d.png'
import handover_mobile from '../../../../public/assets/images/journey/sellYourCar/achieved_m.png'
import { JourneyPageBackground } from '../../../templates/windowPageTemplate/journey/pageBackground'

const data: IJourneyAchievedStepData = {
    car_profile: {
        lightTextCopy:
            "Discover more by tapping the 'Menu' button on your car page. It holds a treasure trove of additional actions and features awaiting your exploration.",
        darkTextCopy:
            "Don't stop now—there's still so much more to explore and accomplish on Custodian. You're just getting started on your journey to automotive excellence.",
        image_src_desktop: car_profile_desktop,
        image_src_mobile: car_profile_mobile,
        nextSectionID: 'history_file',
        nextSectionPageName: 'history-file',
    },
    history_file: {
        lightTextCopy:
            "Craft a detailed timeline of your car's life with our History Files feature! Easily navigate through its journey using our intuitive filters.",
        darkTextCopy:
            "Don't stop now—there's still so much more to explore and accomplish on Custodian. You're just getting started on your journey to automotive excellence.",
        image_src_desktop: history_file_image_desktop,
        image_src_mobile: history_file_image_mobile,
        nextSectionID: 'insurance',
        nextSectionPageName: 'insurance',
    },
    insurance: {
        lightTextCopy:
            'Complete your insurance application at your own pace, whether you prefer to follow our step-by-step guide or fill it out in any order you like. The choice is yours!',
        darkTextCopy:
            "Don't stop now—there's still so much more to explore and accomplish on Custodian. You're just getting started on your journey to automotive excellence.",
        image_src_desktop: insurance_desktop,
        image_src_mobile: insurance_mobile,
        nextSectionID: 'share_your_car',
        nextSectionPageName: 'share-your-car',
    },
    share_your_car: {
        lightTextCopy:
            "Share your car's details easily, whether you prefer a simple or more detailed approach. Rest assured, you can revoke access at any time.",
        darkTextCopy:
            "Don't stop now—there's still so much more to explore and accomplish on Custodian. You're just getting started on your journey to automotive excellence.",
        image_src_desktop: share_car_desktop,
        image_src_mobile: share_car_mobile,
        nextSectionID: 'past_cars',
        nextSectionPageName: 'previously-owned',
    },
    past_cars: {
        lightTextCopy:
            "Even if your beloved car is no longer with you, add it to your garage and either archive or hand it over to its current owner. That way, you'll always have easy access to stroll down memory lane and reminisce about your automotive journey.",
        darkTextCopy:
            "Don't stop now—there's still so much more to explore and accomplish on Custodian. You're just getting started on your journey to automotive excellence.",
        image_src_desktop: past_cars_desktop,
        image_src_mobile: past_cars_mobile,
        nextSectionID: 'handover',
        nextSectionPageName: 'handover',
    },
    handover: {
        lightTextCopy:
            "Rest assured, even if the new owner of your car isn't a Custodian user yet, you can invite them to join the platform seamlessly during the Handover process.",
        darkTextCopy:
            "Don't stop now—there's still so much more to explore and accomplish on Custodian. You're just getting started on your journey to automotive excellence.",
        image_src_desktop: handover_desktop,
        image_src_mobile: handover_mobile,
        nextSectionID: 'sell_your_car',
        nextSectionPageName: 'sell-your-car',
    },
    sell_your_car: {
        lightTextCopy:
            "When it's time to part ways with your car, it might be the perfect moment to explore what's waiting for its next owner. Take a look at our Showroom to see what's available!",
        darkTextCopy:
            "Don't stop now—there's still so much more to explore and accomplish on Custodian. You're just getting started on your journey to automotive excellence.",
        image_src_desktop: sell_car_desktop,
        image_src_mobile: sell_car_mobile,
        nextSectionID: 'your_profile',
        nextSectionPageName: 'your-profile',
    },
    your_profile: {
        lightTextCopy:
            'Unlock a world of possibilities right from your profile. Dive into detailed preferences, explore platform tutorials, and more—all at your fingertips.',
        darkTextCopy:
            "Don't stop now—there's still so much more to explore and accomplish on Custodian. You're just getting started on your journey to automotive excellence.",
        image_src_desktop: profile_desktop,
        image_src_mobile: profile_mobile,
        nextSectionID: 'car_profile',
        nextSectionPageName: 'car-profile',
    },
}

export const JourneyAchievedStep = ({
    sectionID,
    carid,
}: {
    sectionID: IJourneySectionID
    carid?: string | null | undefined
}) => {
    return (
        <JourneyPageBackground
            sectionID={sectionID}
            hasBlurredBg={true}
            hasLinearBgColour={true}
            hasPattern={true}
        >
            <JourneyAchievedStepDesktop
                sectionID={sectionID}
                carid={carid}
                {...data[sectionID]}
            />
            <JourneyAchievedStepMobile
                sectionID={sectionID}
                carid={carid}
                {...data[sectionID]}
            />
        </JourneyPageBackground>
    )
}
