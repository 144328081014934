import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../../services'
import {
    convertToCarState,
    convertToGalleryImagesState,
    convertToHighlightedFactsState,
    convertToTechnicalInformationState,
} from '../../../conversions/entities/conversionFromAPI'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IRootState } from '../../../store'
import { garagesActions } from '../../garages/reducer'
import {
    ITechnicalInformationObject,
    IHighlightedFactsObject,
} from 'entityModels'
import { IGaragePayload } from '../../../services/typedefinitions/apiPayloads'
import { IGalleryImagesObject } from '../../galleries/types'
import { IGarageObject, IGarage } from '../../garages/types'
import { ICarsObject, ICustomErrorData, IReduxError } from '../types'
import { galleriesActions } from '../../galleries/reducer'
import { highlightedFactsActions } from '../../highlighted_facts/reducer'
import { technicalInformationActions } from '../../technical_information/reducer'
import { carActions } from '../reducer'

const getGaragesState = (state: IRootState) =>
    state.entities.garagesData.garages

const getCarsDataState = (state: IRootState) => state.entities.carsData.cars

const getTechInfoState = (state: IRootState) =>
    state.entities.technicalInformationData.technical_information

const getGalleryState = (state: IRootState) =>
    state.entities.galleriesData.images

const getHighFactsState = (state: IRootState) =>
    state.entities.highlightedFactsData

export function* getGarageCarsDataSaga(uid: string) {
    if (uid !== undefined) {
        yield put(garagesActions.setGarageDataRequest())
        try {
            let data: IGaragePayload = yield call(
                api.entities.getData.getGarageData_api,
                uid
            )

            if (data.cars && data.cars.length > 0) {
                const carsDataState: ICarsObject = yield select(
                    getCarsDataState
                )

                const galleryDataState: IGalleryImagesObject | null =
                    yield select(getGalleryState)

                const techInfoState: ITechnicalInformationObject | null =
                    yield select(getTechInfoState)

                const highFactsState: IHighlightedFactsObject | null =
                    yield select(getHighFactsState)

                let carObjects: ICarsObject = carsDataState
                    ? { ...carsDataState }
                    : {}

                let galleryImagesObject: IGalleryImagesObject = galleryDataState
                    ? {
                          ...galleryDataState,
                      }
                    : {}

                let highlightedFactsObject: IHighlightedFactsObject =
                    highFactsState ? { ...highFactsState } : {}

                let technicalInformationObject: ITechnicalInformationObject =
                    techInfoState ? { ...techInfoState } : {}

                yield put(galleriesActions.setGalleryImagesRequest())

                yield put(highlightedFactsActions.setHighlightedFactsRequest())

                yield put(
                    technicalInformationActions.setTechnicalInformationRequest()
                )

                let car_ids: string[] = []
                for (const item of data.cars) {
                    // add id to car ids list
                    car_ids.push(item.uid)

                    if (!carsDataState[item.uid]) {
                        // convert to car state payload
                        let car: ICarsObject = convertToCarState(item)

                        car[item.uid].has_limited_garage_info = true

                        let gallery_images_object: IGalleryImagesObject =
                            convertToGalleryImagesState(item)

                        let highlighted_facts_object: IHighlightedFactsObject =
                            convertToHighlightedFactsState(item)

                        let technical_information_object: ITechnicalInformationObject =
                            convertToTechnicalInformationState(item)

                        Object.assign(
                            galleryImagesObject,
                            gallery_images_object
                        )

                        Object.assign(carObjects, car)

                        Object.assign(
                            highlightedFactsObject,
                            highlighted_facts_object
                        )

                        Object.assign(
                            technicalInformationObject,
                            technical_information_object
                        )
                    }
                }

                const cars_results: ICarsObject = { ...carObjects }

                yield put(carActions.getCarDataSuccess(cars_results))

                const garages_data_in_state: IGarageObject = yield select(
                    getGaragesState
                )
                const garage_in_state: IGarage = garages_data_in_state[uid]

                const garageToAdd: IGarage = garage_in_state
                    ? { ...garage_in_state, cars: car_ids }
                    : { uid: uid, cars: car_ids }

                yield put(garagesActions.getPublicSharesGarageRequest(uid))

                yield put(garagesActions.setGarageDataSuccess(garageToAdd))

                yield put(
                    galleriesActions.setGalleryImagesSuccess(
                        galleryImagesObject
                    )
                )

                yield put(
                    highlightedFactsActions.setHighlightedFactsSuccess(
                        highlightedFactsObject
                    )
                )

                yield put(
                    technicalInformationActions.setTechnicalInformationSuccess(
                        technicalInformationObject
                    )
                )

                yield put(carActions.getGarageCarsDataSuccess(cars_results))
            } else {
                const garages_data_in_state: IGarageObject = yield select(
                    getGaragesState
                )
                const garage_in_state: IGarage = garages_data_in_state[uid]

                const garageToAdd: IGarage = garage_in_state
                    ? { ...garage_in_state, cars: [] }
                    : { uid: uid, cars: [] }

                yield put(garagesActions.getPublicSharesGarageRequest(uid))
                yield put(carActions.getCarDataSuccess({}))

                yield put(garagesActions.setGarageDataSuccess(garageToAdd))
            }
        } catch (error: any) {
            if (error.status === 401) {
                let payload: IUnauthHandlerPayload = {
                    functionToRepeat: getGarageCarsDataSaga,
                    payload: uid,
                }
                yield call(unauthHandlerSaga, payload)
            } else {
                let customErrorData: ICustomErrorData = {
                    custom_message: `Something went wrong, we couldn't get your garage`,
                    custom_user_action_text: 'Return to garage',
                    custom_redirect_path: '/garage',
                }
                let customError: IReduxError = ConvertToReduxError(
                    error,
                    customErrorData
                )
                yield put(carActions.getGarageCarsDataError(customError))
                return
                // window.location.reload()
            }
        }
    } else {
        let customErrorData: ICustomErrorData = {
            custom_message: `Something went wrong, we couldn't get your garage`,
            custom_user_action_text: 'Return to garage',
            custom_redirect_path: '/garage',
        }
        let customError: IReduxError = ConvertToReduxError(
            { status: '500' },
            customErrorData
        )
        yield put(carActions.getGarageCarsDataError(customError))
    }
}

function* watcherGetGarageCarsData() {
    while (true) {
        const { payload } = yield take(carActions.getGarageCarsDataRequest)

        yield call(getGarageCarsDataSaga, payload)
    }
}

const get_garage_cars_data: any[] = [fork(watcherGetGarageCarsData)]

export default get_garage_cars_data
