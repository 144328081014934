import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import GalleryOverviewItemDesktop from '../../../molecules/imageGrid/galleryOverviewItemDesktop'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import ImageTagDesktop from '../../../atoms/signs/imageSignDesktop'
import NoDataRectangle from '../../../atoms/noData/noDataRectangle'
import cover_image from '../../../atoms/skeleton/gallery/cover_image.jpg'
import cover_image_dark from '../../../atoms/skeleton/gallery/cover_image_dark.png'
import AddBox from '../../../atoms/Button/addBox'
import { useLocation, useNavigate } from 'react-router-dom'
import { IGalleryImage } from '../../../../redux/entities/galleries/types'
import useThemes from '../../../../providers/theme/hooks'

interface StyleProps {
    isImgLoaded: boolean
    hasOpacity?: boolean
}
const WrapperSizeHighlightImg = styled.div`
    cursor: pointer;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    z-index: 1;
`

const HighlightImage = styled.div<StyleProps>`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    z-index: 1;
    opacity: ${(props) => (props.isImgLoaded === false ? 1 : 0)};
    transition: all 400ms;

    @media ${device.beyond_ipad_mobile} {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    transition: opacity 400ms;
`

const HideThis = styled.div`
    display: none;
`

const Wrapper = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    flex: 1;

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
        flex: 1;
    }
`

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    height: 100%;
    grid-gap: 20px;
    justify-items: baseline;
    height: 30vh;
    min-height: 450px;
    max-height: 500px;

    @media ${device.beyond_ipad_mobile} {
        display: grid;
        grid-template-columns: 0.65fr auto;
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        grid-gap: 20px;
        justify-items: baseline;
        height: 30vh;
        min-height: 400px;
        max-height: 500px;
    }

    @media ${device.large_desktop} {
        grid-template-columns: 0.65fr auto;
        min-height: 500px;
        max-height: 580px;
    }
`

const GridContainerCells = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        width: 100%;
        box-sizing: border-box;
        height: 100%;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const ImgOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    min-height: 100px;
    transition: 200ms all;
    background: linear-gradient(#000 50%, #000 100%);
    z-index: 7;
`

export interface IImageProps {
    url: string
}

interface Props {
    images_array: IGalleryImage[] | null
    coverimg: IGalleryImage | null
    onClick?: any
    carid: string
    count: number
    readOnlyMode?: boolean
    onAddClick?: any
}

const GalleryOverviewManagerDesktop: React.FC<Props> = ({
    images_array,
    coverimg,
    onAddClick,
    carid,
    count,
    readOnlyMode,
}) => {
    let { theme } = useThemes()
    const [addBox, setAddBox] = useState(false)
    const [isImgLoading, setIsImgLoading] = useState(true)
    const [imgOverlay, setImgOverlay] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    const showAddBox = () => setAddBox(true)
    const removeAddBox = () => setAddBox(false)
    const showImageOverlay = () => setImgOverlay(true)
    const removeImageOverlay = () => setImgOverlay(false)

    const isEmpty = images_array && images_array.length > 0 ? false : true
    const cover_url =
        coverimg && coverimg.url
            ? coverimg.url
            : theme === 'dark'
            ? cover_image_dark
            : cover_image

    useEffect(() => {
        const image = new Image()
        image.src = cover_url
        image.onload = () => setIsImgLoading(false)
        image.onerror = () => setIsImgLoading(false) // Ensure state updates on error.
    }, [coverimg])

    const n_array = ['0', ' 1', '2', '3', '4', '5', '6']

    const imgLinkPrefix = location.pathname.includes('overview')
        ? location.pathname.replace('/overview', '')
        : location.pathname

    return (
        <Wrapper>
            {((readOnlyMode && !isEmpty) || !readOnlyMode) && (
                <GridContainer>
                    <WrapperSizeHighlightImg
                        onMouseEnter={() => {
                            !cover_url ? showAddBox() : showImageOverlay()
                        }}
                        onMouseLeave={() => {
                            !cover_url ? removeAddBox() : removeImageOverlay()
                        }}
                        onClick={() =>
                            !cover_url && onAddClick
                                ? onAddClick()
                                : navigate(
                                      `${imgLinkPrefix}/gallery?id=${
                                          coverimg && coverimg.id
                                              ? coverimg.id
                                              : ''
                                      }`
                                  )
                        }
                    >
                        <SkeletonAbsolute isthingloading={isImgLoading} />

                        {addBox && !readOnlyMode ? (
                            <AddBox />
                        ) : (
                            <>
                                <HighlightImage
                                    role="img"
                                    aria-label="car overview banner"
                                    style={{
                                        backgroundImage: `url(${cover_url}`,
                                    }}
                                    hasOpacity={false}
                                    isImgLoaded={isImgLoading}
                                />
                                <ImageTagDesktop copy="Car Profile Image" />
                                <ImgOverlay
                                    style={{
                                        opacity: imgOverlay ? 0.5 : 0,
                                    }}
                                />
                            </>
                        )}
                    </WrapperSizeHighlightImg>

                    <GridContainerCells>
                        {n_array.map((id: string, index: number) => {
                            if (index !== 0) {
                                return (
                                    <GalleryOverviewItemDesktop
                                        key={index}
                                        img={
                                            images_array && images_array[index]
                                                ? images_array[index]
                                                : undefined
                                        }
                                        carid={carid}
                                        readonlyMode={readOnlyMode}
                                        image_number={index}
                                        lastItemTxt={
                                            images_array &&
                                            images_array.length &&
                                            count > 6
                                                ? `+ ${count - 6}`
                                                : 'Gallery'
                                        }
                                        openImgForm={() =>
                                            onAddClick && onAddClick()
                                        }
                                    />
                                )
                            } else return <HideThis key={index} />
                        })}
                    </GridContainerCells>
                </GridContainer>
            )}

            {/* IF NO IMAGES: */}
            {isEmpty === true && readOnlyMode ? (
                <NoDataRectangle text="no images" height="300px" />
            ) : null}
        </Wrapper>
    )
}

export default GalleryOverviewManagerDesktop
