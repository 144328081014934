import JourneyBottomActionBarMobile from '../../../../../templates/bars/journey/bottomActionBarMobile'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../../../providers/theme/hooks'
import { useState } from 'react'
import colours from '../../../../../../providers/theme/colours'
import Faded from '../../../../../templates/animated/faded'
import {
    JourneyColumnContent,
    JourneyStepTitle,
    JourneyText,
} from '../../../../../templates/styledcomponents/journeyStyles'
import { BottomSheetMobileHistoryFileJourney } from '../add/bottomSheetMobile'
import TimelineInsuranceItemMobileJourneyCard from '../../../../../molecules/timelineItemDesktop/insuranceTimelineCard'
import { JourneyHFInsuranceFormBody } from './insuranceFormBody'
import { ITimelineItem } from '../../../../../../redux/timeline/types'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
    entry1: ITimelineItem | undefined
    setEntry1: (p: ITimelineItem | undefined) => any
    hasInsuranceEntryWithMandatoryFields?: boolean
}

const SectionLeft = ({ hasEntry }: { hasEntry?: boolean }) => {
    const { theme } = useThemes()
    return (
        <JourneyColumnContent>
            {hasEntry ? (
                <JourneyText $theme={theme}>
                    Great job getting started with your car’s digital history
                    file!
                    <br />
                    <br />
                    Having all your documents easily searchable in one place is
                    extremely helpful both during the ownership of your vehicles
                    and especially helpful should you decide to sell or handover
                    your vehicle to a friend or loved one.
                    <br />
                    <br />
                    As you might already know, Custodian is working hard to
                    create fairer and better insurance for automotive
                    enthusiasts. Our goal is to become an extremely reliable
                    source of risk for underwriters allowing better pricing for
                    a new category of car owner and pricing more on how a person
                    looks after their car rather than what gender, age etc they
                    are.
                    <br />
                    <br />
                    It'll be a long journey, but its the best way to make
                    insurance better for those who look after their cars,
                    themselves, and others well so they do not have to foot the
                    bill for those who do not.
                    <br />
                    <br />
                    By using Custodian, you’re helping us make specialist car
                    insurance better for all enthusiasts.
                </JourneyText>
            ) : (
                <JourneyText $theme={theme}>
                    Add an Insurance history file entry to record your policy
                    details along with its renewal dates. Having this
                    information easily accessible at the most unfortunate
                    moments can bring great peace of mind.
                    <br />
                    <br />
                    It also means we will be prepared to broker you the best
                    cover should you decide to apply for Custodian Insurance.
                </JourneyText>
            )}
        </JourneyColumnContent>
    )
}
export default function InsuranceHistoryFileJourneyStepMobile({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    entry1,
    setEntry1,
    hasInsuranceEntryWithMandatoryFields,
}: Props) {
    const { theme } = useThemes()

    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <IpadAndMobileDisplay>
            {hasInsuranceEntryWithMandatoryFields ? null : (
                <BottomSheetMobileHistoryFileJourney
                    isOpen={isOpen}
                    close={() => {
                        setIsOpen(false)
                    }}
                    onConfirm={() => {}}
                    title={`Insurance entry details`}
                    customHeight={'80dvh'}
                >
                    <JourneyColumnContent
                        style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                        }}
                    >
                        <JourneyHFInsuranceFormBody
                            entry={entry1}
                            setEntry={setEntry1}
                            isMobile
                        />
                    </JourneyColumnContent>
                </BottomSheetMobileHistoryFileJourney>
            )}
            <Faded>
                <div
                    style={{
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingBottom: 200,
                        paddingTop: 48,
                        overflowX: 'hidden',
                    }}
                >
                    <JourneyColumnContent style={{ gap: 40 }}>
                        <JourneyColumnContent style={{ gap: 24 }}>
                            <JourneyColumnContent style={{ gap: 4 }}>
                                <JourneyStepTitle
                                    $theme={theme}
                                    style={{ paddingRight: '40px' }}
                                >
                                    {hasInsuranceEntryWithMandatoryFields
                                        ? 'Nice one!'
                                        : 'Don’t forget your insurance!'}
                                </JourneyStepTitle>
                                <JourneyText
                                    style={{
                                        fontSize: 14,
                                        color: colours[theme].text_muted,
                                    }}
                                    $theme={theme}
                                >
                                    Bring your car's history online
                                </JourneyText>
                            </JourneyColumnContent>

                            <SectionLeft
                                hasEntry={hasInsuranceEntryWithMandatoryFields}
                            />
                            <div />
                            <JourneyColumnContent
                                style={{
                                    gap: 16,
                                    alignSelf: 'center',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <TimelineInsuranceItemMobileJourneyCard
                                    userCurrency="£"
                                    entry={entry1}
                                    onClick={() => {
                                        setIsOpen(true)
                                    }}
                                    isReadonly={
                                        hasInsuranceEntryWithMandatoryFields
                                    }
                                />
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                    </JourneyColumnContent>
                </div>
                <JourneyBottomActionBarMobile
                    sectionID="history_file"
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={goToPrevStep}
                    onNext={() => {
                        goToNextStep()
                    }}
                />
            </Faded>
        </IpadAndMobileDisplay>
    )
}
