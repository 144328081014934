import { useState, useEffect } from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import grey_placeholder_dark from '../../../public/assets/placeholder/grey_placeholder_dark.jpg'
import { Link, useLocation } from 'react-router-dom'
import { device } from '../../templates/displays/devices'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import { IGalleryImage } from '../../../redux/entities/galleries/types'
import useThemes from '../../../providers/theme/hooks'

type IImgProps = {
    isthingloading: boolean
}

const Wrap = styled.div`
    height: 160px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    @media ${device.ipad} {
        height: 22vh;
    }
`

const GalleryImage = styled.img<IImgProps>`
    object-fit: cover !important;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    opacity: ${(props) => (props.isthingloading === true ? 0 : 1)};
    transition: opacity 400ms;
`

export interface IImageProps {
    url: string
}

interface Props {
    image_obj: IGalleryImage
}

export default function SquareDuoItemMobile(props: Props) {
    const [src, setSrc] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { theme } = useThemes()
    const { image_obj } = props
    useEffect(() => {
        const imageSrc =
            image_obj && image_obj.url !== undefined
                ? image_obj.url
                : theme === 'dark'
                ? grey_placeholder_dark
                : grey_placeholder

        if (imageSrc) {
            setSrc(imageSrc)
        }
    }, [image_obj])

    let location = useLocation()

    return (
        <Link
            replace={true}
            to={{
                pathname: location.pathname,
                search: `?id=${image_obj.id}`,
                // state: { prevPath: props.location.pathname },
            }}
        >
            <Wrap>
                <SkeletonAbsolute isthingloading={isLoading} />
                <GalleryImage
                    aria-label="car overview banner"
                    src={src}
                    isthingloading={isLoading}
                    srcSet={
                        image_obj.small_srcset
                            ? image_obj.small_srcset
                            : undefined
                    }
                    onLoad={() => setIsLoading(false)}
                />
            </Wrap>
        </Link>
    )
}
