import * as urls from '../../urls'
import { removeUndefinedfromObject } from '../../../helpers/objects'
import { customHeader } from '../../headers'
import { apiSentryErrorHandler } from '../../errorHandler'
import { IApiEntryUPDATE_args } from '../../types'

export const updateEntry = (p: IApiEntryUPDATE_args): Promise<any> => {
    let dataToUpdateNoUndefined = removeUndefinedfromObject(p.dataToUpdate)

    let dataToUpdateFinal = JSON.stringify(dataToUpdateNoUndefined)

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: dataToUpdateFinal,
        credentials: 'include',
    }

    return fetch(
        urls.update_entry_endpoint(p.entry_id, p.car_id),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Update car timeline entry error',
                        dataToUpdateFinal
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
