import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import Loader from '../../atoms/loader/loader'
import styled from 'styled-components'
import left_align_logo from '../../../public/assets/landingPage/left_align_logo.svg'
import ErrorCardDesktop from '../../molecules/errorCards/errorCardDesktop'
import { device } from '../../templates/displays/devices'
import ErrorCardMobile from '../../molecules/errorCards/errorCardMobile'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'

const LeftAlignLogoWrapper = styled.div`
    height: 50px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;

    @media ${device.smallest_laptops} {
        height: 48px;
    }

    @media ${device.mobile} {
        height: 40px;
    }
`

const Logo = styled.img`
    width: auto;
    height: 100%;
`

const SubWrapper = styled.div`
    padding: 80px;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${device.mobile_and_ipad} {
        padding: 80px 0px;
    }
    @media ${device.mobile} {
        padding: 50px 0px;
    }
`

const FlexOneSubWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
`

const InviteShareError: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const isUserLoading = useAppSelector((state) => state.user.loading)

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const handleLogout = () => {
        dispatch(usersActions.logoutRequest())
    }

    const handleGoToGarage = () => {
        navigate('/garage')
    }

    return (
        <React.Fragment>
            {<Loader isLoading={isUserLoading} />}

            {userLoggedIn && (
                <React.Fragment>
                    <DesktopDisplayOnly>
                        <SubWrapper>
                            <LeftAlignLogoWrapper>
                                <Logo src={left_align_logo} />
                            </LeftAlignLogoWrapper>
                            <FlexOneSubWrapper>
                                <ErrorCardDesktop
                                    title="Invalid access"
                                    text="You do not have permissions to access details of this page. Log in or register with the email that has been granted access."
                                    main_btn_text="Go to your garage"
                                    onClick={handleGoToGarage}
                                    secondary_btn_text="Logout"
                                    secondary_btn_color="#df6f6f"
                                    onSecondaryBtnClick={handleLogout}
                                />
                            </FlexOneSubWrapper>
                        </SubWrapper>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <SubWrapper>
                            <LeftAlignLogoWrapper>
                                <Logo src={left_align_logo} />
                            </LeftAlignLogoWrapper>
                            <FlexOneSubWrapper>
                                <ErrorCardMobile
                                    title="Invalid access"
                                    text="You do not have permissions to access details of this page. Log in or register with the email that has been granted access."
                                    main_btn_text="Go to your garage"
                                    onClick={handleGoToGarage}
                                    secondary_btn_text="Logout"
                                    secondary_btn_color="#df6f6f"
                                    onSecondaryBtnClick={handleLogout}
                                />
                            </FlexOneSubWrapper>
                        </SubWrapper>
                    </IpadAndMobileDisplay>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default InviteShareError
