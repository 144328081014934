import * as React from 'react'
import { generateOverviewImages } from '../generateOverviewImages'
import GalleryOverviewManagerMobile from './galleryOverviewManagerMobile'
import {
    IGalleryImage,
    IGalleryImagesObject,
} from '../../../../redux/entities/galleries/types'

export interface IImageProps {
    url: string
}

interface Props {
    isNecessaryGalleryDataLoading: boolean
    overview_images_ids: string[]
    images_ids: string[]
    images_data: IGalleryImagesObject | null
    cover_id: string
    onClick?: any
    carid: string
    readOnlyMode?: boolean
}

const GalleryOverviewDataManagerMobile: React.FC<Props> = ({
    images_data,
    images_ids,
    isNecessaryGalleryDataLoading,
    onClick,
    cover_id,
    overview_images_ids,
    carid,
    readOnlyMode,
}) => {
    const cover_img: IGalleryImage | undefined =
        images_data && images_data[cover_id] ? images_data[cover_id] : undefined

    const img_array: IGalleryImage[] | null =
        images_data &&
        generateOverviewImages(
            images_data,
            images_ids,
            overview_images_ids,
            cover_img
        )

    return (
        <React.Fragment>
            {isNecessaryGalleryDataLoading !== true && (
                <GalleryOverviewManagerMobile
                    images_array={
                        img_array && img_array.length > 0 ? img_array : null
                    }
                    onClick={onClick && onClick}
                    carid={carid}
                    count={images_ids.length}
                    readOnlyMode={readOnlyMode}
                />
            )}
        </React.Fragment>
    )
}

export default GalleryOverviewDataManagerMobile
