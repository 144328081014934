import {
    IDirectShareOwnedItemsData,
    IPrivateShareReceivedItem,
    IShareRecipient,
} from '../../redux/entities/cars/types'

export const sortObjectByCreatedAt = (obj: any, ids: string[]): string[] => {
    let array = ids.map((id: string, index: any) => {
        return {
            ...obj[id],
        }
    })

    let sorted: any = array.sort((obj1, obj2) => {
        let dateA: any = new Date(obj1.created_at),
            dateB: any = new Date(obj2.created_at)
        return dateB - dateA
    })

    let arraySorted: string[] = Object.keys(sorted).map(
        (id: string, index: any) => {
            return sorted[index].id
        }
    )

    return arraySorted
}

export const sortObjectByDueDate = (obj: any, ids: string[]) => {
    // array of ids in the sorted order
    //  let sortedList: string[] = [];

    let sortableArray = ids
        .map((id: string, index: any) => {
            if (obj[id] && obj[id].due !== null) {
                return {
                    ...obj[id],
                }
            } else return undefined
        })
        .filter((item) => item !== undefined)

    let nonSortableArray = ids
        .map((id: string, index: any) => {
            if ((obj[id] && obj[id].due) === null) {
                return {
                    ...obj[id],
                }
            } else return undefined
        })
        .filter((item) => item !== undefined)

    let sorted: any = sortableArray.sort((obj1, obj2) => {
        let dateA: any = new Date(obj1.due),
            dateB: any = new Date(obj2.due)
        return dateA - dateB
    })

    let arrayOfIDsSorted = Object.keys(sorted).map((id: string, index: any) => {
        return sorted[index].id
    })

    let arrayOfIDsNonSortable = Object.keys(nonSortableArray).map(
        (id: string, index: any) => {
            return nonSortableArray[index].id
        }
    )

    return [...arrayOfIDsSorted, ...arrayOfIDsNonSortable]
}

export const sortObjectByUpdatedAt = (obj: any, ids: string[]) => {
    // array of ids in the sorted order
    //  let sortedList: string[] = [];

    let array = ids.map((id: string, index: any) => {
        return {
            ...obj[id],
        }
    })

    let sorted: any = array.sort((obj1, obj2) => {
        let dateA: any = new Date(obj1.updated_at),
            dateB: any = new Date(obj2.updated_at)
        return dateB - dateA
    })

    let arraySorted = Object.keys(sorted).map((id: string, index: any) => {
        return sorted[index].id
    })

    return arraySorted
}

export const getCreatedAtTimestamp = (x: any): any => {
    return new Date(x.created_at)
}

export const getRecipientsCreatedAtTimestamp = (x: any): any => {
    return new Date(x.date_added_to_share)
}

export const getUpdatedAtTimestamp = (x: any): any => {
    return new Date(x.updated_at)
}

export const sortSharesByCreatedAt = (
    ids: string[],
    data: IDirectShareOwnedItemsData
): string[] => {
    let sortedDates = ids
        .sort(
            (a: string, b: string): any =>
                getCreatedAtTimestamp(data[a]) - getCreatedAtTimestamp(data[b])
        )
        .reverse()

    return sortedDates
}

export const sortSharesByUpdatedAt = (
    ids: string[],
    data: IDirectShareOwnedItemsData
): string[] => {
    let sortedDates = ids
        .sort(
            (a: string, b: string): any =>
                getUpdatedAtTimestamp(data[a]) - getUpdatedAtTimestamp(data[b])
        )
        .reverse()

    return sortedDates
}

export const sortShareRecipientsByAddedAt = (
    ids: string[],
    data: {
        [key: string]: IShareRecipient
    }
): string[] => {
    let sortedDates = ids
        .sort(
            (a: string, b: string): any =>
                getRecipientsCreatedAtTimestamp(data[a]) -
                getRecipientsCreatedAtTimestamp(data[b])
        )
        .reverse()

    return sortedDates
}

export const sortDirectReceivedSharesByDate = (
    ids: string[],
    data: {
        [key: string]: IPrivateShareReceivedItem
    },
    order: 'most_recent' | 'oldest'
): string[] => {
    let sortedDates = ids.sort(
        (a: string, b: string): any =>
            getCreatedAtTimestamp(data[a]) - getCreatedAtTimestamp(data[b])
    )
    if (order === 'most_recent') {
        return sortedDates.reverse()
    } else {
        return sortedDates
    }
}
