import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const TaskHeaderStyle = styled.h1`
    font-family: Lato;
    text-align: start;
    color: var(--text-strong, #1a1a1a);
    font-size: 22px;
    padding-right: 5px;
    letter-spacing: 0.03em;

    @media ${device.mobile} {
        font-size: 20px;
        padding-right: 5px;
        color: var(--primary, #5ec3ca);
    }

    @media ${device.ipad} {
        font-size: 26px;
        padding-right: 5px;
    }
`

interface ITextProps {
    onClick?: any
    children?: React.ReactNode
}

const TaskHeader: React.FunctionComponent<ITextProps> = (props: any) => (
    <TaskHeaderStyle onClick={props.onClick && props.onClick}>
        {props.children}
    </TaskHeaderStyle>
)

export default TaskHeader
