import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import SwitchButton from '../../atoms/Button/switchButton'
import { useEffect, useState } from 'react'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IRootState } from '../../../redux/store'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import { usersActions } from '../../../redux/user/reducer'

const Wrapper = styled.div`
    max-width: 550px;

    @media ${device.mobile_and_ipad} {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 10px;
    }
`

const Subheader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
    }
`
const SectionSubheader = styled.div`
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    color: var(--text-strong, #1a1a1a);
`

const ItemTxtWrap = styled.div`
    display: flex;
    flex-direction: column;
`

const ItemContentWrap = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.mobile_and_ipad} {
        flex-direction: row;
        align-items: flex-start;
    }
`

type Props2 = {
    items: INotifItem[]
    onChange: (id: string) => any
    isLoading: boolean
}

const Item = styled.div`
    @media ${device.mobile_and_ipad} {
        border-radius: 8px;
        background: var(--off-bg-color, #fafafa);
        padding: 16px;
    }

    @media ${device.beyond_ipad_mobile} {
        width: 450px;
    }
`

const Container = styled.div`
    gap: 12px;

    @media ${device.mobile_and_ipad} {
        gap: 16px;
    }

    display: flex;
    flex-direction: column;

    @media ${device.beyond_ipad_mobile} {
        width: 550px;
    }
`

const NotificationItem = (props2: Props2) => {
    return (
        <Container>
            {props2.items.map((item: INotifItem, key: number) => {
                return (
                    <Item
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                        key={`notification_${key}`}
                    >
                        <ItemContentWrap>
                            <ItemTxtWrap>
                                <h3
                                    style={{
                                        color: 'var(--text-strong, #1A1A1A)',
                                        fontFamily: 'Lato-Bold',
                                        fontSize: '15px',
                                        lineHeight: '20px',
                                        margin: '0 0 8px 0',
                                    }}
                                >
                                    {item.title}
                                </h3>

                                <p
                                    style={{
                                        color: 'var(--text-darker, #616161)',
                                        fontFamily: 'Lato',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        margin: 0,
                                    }}
                                >
                                    {item.text}
                                </p>
                            </ItemTxtWrap>

                            <IpadAndMobileDisplay>
                                {props2.isLoading ? (
                                    <LoaderElasticThreeDots />
                                ) : (
                                    <SwitchButton
                                        checked={item.optIn ?? false}
                                        toggle={() => {
                                            props2.onChange(item.id)
                                        }}
                                        width={34}
                                        circleSize={12}
                                        height={18}
                                    />
                                )}
                            </IpadAndMobileDisplay>
                        </ItemContentWrap>

                        <DesktopDisplayOnly>
                            {props2.isLoading ? (
                                <LoaderElasticThreeDots />
                            ) : (
                                <SwitchButton
                                    checked={item.optIn ?? false}
                                    toggle={() => {
                                        props2.onChange(item.id)
                                    }}
                                    width={34}
                                    circleSize={12}
                                    height={18}
                                />
                            )}
                        </DesktopDisplayOnly>
                    </Item>
                )
            })}
        </Container>
    )
}

type INotifItem = {
    id: string
    title: string
    text: string
    optIn?: boolean
}

const NotificationsBody = () => {
    const userLoggedIn = useAppSelector(
        (state: IRootState) => state.user.userLoggedIn
    )
    const secondaryLoading = useAppSelector(
        (state: IRootState) => state.user.secondaryLoading
    )
    const [notificationItemsGeneral, setNotificationItemsGeneral] = useState<
        INotifItem[]
    >([
        {
            id: 'General Communications',
            title: 'General Communications',
            text: 'General communications and important information about your account or the platform.',
            optIn: true,
        },
        {
            title: 'Showroom Updates',
            text: 'Showroom notifications, new cars added to the platform, high profile sales, new dealerships joining the platform, market trends and purchasing information.',
            optIn: true,
            id: 'Showroom Updates',
        },
        {
            title: 'The Apex',
            text: "Custodian's automotive publications featuring one weekly article/interview with a titan of the industry, upcoming auction dates, and a summary of everything automotive.",
            optIn: true,
            id: 'The Apex',
        },
        {
            title: 'Product Updates',
            text: 'Updates around new platform features, product development and community feedback and requests.',
            optIn: true,
            id: 'Product Updates',
        },
    ])

    useEffect(() => {
        if (userLoggedIn) {
            let currentUsersMailingGroups = userLoggedIn?.mailingGroups || []

            setNotificationItemsGeneral((prevItems) =>
                prevItems.map((item) => ({
                    ...item,
                    optIn: currentUsersMailingGroups.includes(item.id),
                }))
            )
        }
    }, [userLoggedIn])

    const dispatch = useAppDispatch()

    const onChange = (id: string) => {
        dispatch(usersActions.updateUserMailingGroupsRequest(id))
    }

    return (
        <Wrapper>
            <div style={{ marginTop: '10px' }} />
            <Subheader>
                Select email notifications for each type of update and never
                miss an important message again.
            </Subheader>
            <div style={{ marginTop: '20px' }} />
            <SectionSubheader>General</SectionSubheader>
            <div style={{ marginTop: '20px' }} />
            <NotificationItem
                isLoading={secondaryLoading}
                items={notificationItemsGeneral}
                onChange={onChange}
            />
        </Wrapper>
    )
}

export default NotificationsBody
