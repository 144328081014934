import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import chevronLeft from '../../../public/assets/icons/chevronLeft.svg'
import chevronupblue from '../../../public/assets/icons/chevronupblue.svg'
import chevrondownblue from '../../../public/assets/icons/chevrondownblue.svg'
import sendIcon from '../../../public/assets/icons/send.svg'
import copycon from '../../../public/assets/icons/copy.svg'
import info from '../../../public/assets/icons/info.svg'
import email from '../../../public/assets/icons/email.svg'
import back_circle_navigation from '../../../public/assets/navigation/back_circle_navigation.svg'
import preview from '../../../public/assets/icons/preview.svg'
import DesktopLinkNavArrow from './desktop_link_navigation_arrow'
import vote_up from '../../../public/assets/icons/vote_up.svg'
// import handler_blue from '../../../public/assets/icons/handler_blue.svg'
import edit_pen from '../../../public/assets/icons/edit_pen.svg'
import edit_pen_naked from '../../../public/assets/icons/edit_icon_naked.svg'
// import edit_pen_v2 from '../../../public/assets/icons/edit_pen_v2_gray.svg'
import add_plus from '../../../public/assets/icons/add_plus.svg'
import add_strong from '../../../public/assets/icons/add_strong.svg'
import { motion } from 'framer-motion'
import location from '../../../public/assets/icons/location.svg'
import location_white from '../../../public/assets/icons/location_white.svg'
import credits_circle from '../../../public/assets/icons/credits_circle.svg'
import email_at from '../../../public/assets/icons/email_at.svg'
import car_listed from '../../../public/assets/icons/car_listed.svg'
import share_link from '../../../public/assets/icons/share_link.svg'
import arrow_white from '../../../public/assets/icons/arrow_white.svg'
import chevron_down_light from '../../../public/assets/icons/chevron_down_light.svg'
import information_italic from '../../../public/assets/icons/information_italic.svg'
import padlock from '../../../public/assets/icons/padlock.svg'
import globe from '../../../public/assets/icons/globe.svg'
import three_dots from '../../../public/assets/icons/three_dots.svg'
import red_cross from '../../../public/assets/icons/red_cross.svg'
import information_italic_white from '../../../public/assets/icons/information_italic_white.svg'
import close_cross_white from '../../../public/assets/icons/close_cross_white.svg'
import arrow_blue_previous from '../../../public/assets/navigation/arrow_blue_previous.svg'
import blue_arrow_gallery_slide_mobile from '../../../public/assets/navigation/arrow_blue_previous.svg'
import three_dots_vertical from '../../../public/assets/icons/three_dots_vertical.svg'
import exit_actions_menu_mobile from '../../../public/assets/navigation/exit_actions_menu_mobile.svg'
import arrow_next_gallery_slide_desktop from '../../../public/assets/navigation/arrow_next_gallery_slide_desktop.svg'
import arrow_prev_gallery_slide_desktop from '../../../public/assets/navigation/arrow_prev_gallery_slide_desktop.svg'
import upload_tuto_mobile from '../../../public/assets/icons/upload_tuto_mobile.svg'
import add_tuto_mobile from '../../../public/assets/icons/add_tuto_mobile.svg'
// import crop_img from "../../../public/assets/icons/crop_img.svg";
import delete_img from '../../../public/assets/icons/delete_img.svg'
import reposition_button_circle_blue from '../../../public/assets/icons/reposition_button_circle_blue.svg'
import bin_button_circle_blue from '../../../public/assets/icons/bin_button_circle_blue.svg'
import calendar_btn_use from '../../../public/assets/icons/calendar_btn_use.svg'
import filter_mobile from '../../../public/assets/icons/filter_mobile.svg'
import task_status_mobile from '../../../public/assets/icons/task_status_mobile.svg'
import tasks_user_banner from '../../../public/assets/icons/tasks_user_banner.svg'
import reminder_icon from '../../../public/assets/icons/reminder_icon.svg'
import attachment_black from '../../../public/assets/icons/attachment_black.svg'
import gbp_black from '../../../public/assets/icons/gbp_black.svg'
import dollar_black from '../../../public/assets/icons/dollar_black.svg'
import euro_black from '../../../public/assets/icons/euro_black.svg'
import company_house_black from '../../../public/assets/icons/company_house_black.svg'
import navigate_square from '../../../public/assets/icons/navigate_square.svg'
import v2_edit from '../../../public/assets/icons/v2_edit.svg'
import v2_add from '../../../public/assets/icons/v2_add.svg'
import v2_add_white from '../../../public/assets/icons/v2_add_white.svg'
import download_icon_grey from '../../../public/assets/icons/download_icon_grey.svg'
import download_blue from '../../../public/assets/icons/download_blue.svg'
import grey_cross from '../../../public/assets/icons/grey_cross.svg'
import grey_cross_v2 from '../../../public/assets/icons/grey_cross_v2.svg'
import grey_cross_w_circle from '../../../public/assets/icons/grey_cross_w_circle.svg'
import light_red_cross from '../../../public/assets/icons/light_red_cross.svg'
import blue_check from '../../../public/assets/icons/blue_check.svg'
import light_grey_check from '../../../public/assets/icons/light_grey_check.svg'
import grey_check from '../../../public/assets/icons/grey_check.svg'
import white_check from '../../../public/assets/icons/white_check.svg'
import sort_icon from '../../../public/assets/icons/sort_icon.svg'
import filter_icon from '../../../public/assets/icons/filter_icon.svg'
import long_green_arrow from '../../../public/assets/icons/long_green_arrow.svg'
import thicker_red_bin from '../../../public/assets/icons/thicker_red_bin.svg'
// import grey_bin from '../../../public/assets/icons/grey_bin_updated.svg'
import carProfileImageSign from '../../../public/assets/icons/carProfileImageSign.svg'
import arrow_to_right from '../../../public/assets/icons/arrow_to_right.svg'
import reload_blue from '../../../public/assets/icons/reload_blue.svg'
import copy_icon from '../../../public/assets/icons/copy_icon.svg'
import copy_icon_grey from '../../../public/assets/icons/copy_icon_grey.svg'
import email_icon from '../../../public/assets/icons/email_icon.svg'
import cog from '../../../public/assets/navigation/cog.svg'
import sort_icon_black from '../../../public/assets/icons/sort_icon_black.svg'
import sort_icon_grey from '../../../public/assets/icons/sort_icon_grey.svg'
import sort_icon_blue from '../../../public/assets/icons/sort_icon_blue.svg'
import tasks from '../../../public/assets/navigation/tasks.svg'
import home from '../../../public/assets/navigation/home.svg'
import history_file from '../../../public/assets/navigation/history_file.svg'
import upcoming from '../../../public/assets/navigation/upcoming_white.svg'
import apex from '../../../public/assets/navigation/apex.svg'
import sharing from '../../../public/assets/navigation/sharing.svg'
import instagram from '../../../public/assets/social/instagram.jpg'
import twitter from '../../../public/assets/social/twitter.svg'
import facebook from '../../../public/assets/social/facebook.svg'
import add_user_icon from '../../../public/assets/icons/add_user_icon.svg'
import add_user_icon_blue from '../../../public/assets/icons/add_user_icon_blue.svg'
import get_link_icon from '../../../public/assets/icons/get_link_icon.svg'
import blue_thin_search from '../../../public/assets/icons/blue_thin_search.svg'
import blue_cross_w_circle from '../../../public/assets/icons/blue_cross_w_circle.svg'
import light_bulb from '../../../public/assets/icons/light_bulb.svg'
import blue_cross_v2 from '../../../public/assets/icons/blue_cross_v2.svg'
import dragger from '../../../public/assets/icons/dragger.svg'
import filters_icon_green from '../../../public/assets/icons/filters_icon_green.svg'
import description from '../../../public/assets/actionMenu/description.svg'
import key_moments_and_facts from '../../../public/assets/actionMenu/key_moments_and_facts.svg'
import vehicle_bio from '../../../public/assets/actionMenu/vehicle_bio.svg'
import image from '../../../public/assets/actionMenu/image.svg'

import archive from '../../../public/assets/actionMenu/archive.svg'

import task from '../../../public/assets/actionMenu/task.svg'
import history_file_menu from '../../../public/assets/actionMenu/history_file_menu.svg'
import new_share_link_icon from '../../../public/assets/icons/new_share_link_icon.svg'
// import new_share_link_icon_black from '../../../public/assets/icons/new_share_link_icon_black.svg'

import info_blue from '../../../public/assets/icons/info_blue.svg'
import info_red from '../../../public/assets/icons/info_red.svg'
import info_gray from '../../../public/assets/icons/info_gray.svg'

import gallery_light from '../../../public/assets/icons/gallery_light.svg'
import kmf_light from '../../../public/assets/icons/kmf_light.svg'
import description_light from '../../../public/assets/icons/description_light.svg'
import hf_light from '../../../public/assets/icons/hf_light.svg'
import insurance_light from '../../../public/assets/icons/insurance_light.svg'
import gallery_dark from '../../../public/assets/icons/gallery_dark.svg'
import kmf_dark from '../../../public/assets/icons/kmf_dark.svg'
import description_dark from '../../../public/assets/icons/description_dark.svg'
import hf_dark from '../../../public/assets/icons/hf_dark.svg'
import insurance_dark from '../../../public/assets/icons/insurance_dark.svg'

// import cost_icon_v2 from '../../../public/assets/icons/cost_icon_v2.svg'
import play from '../../../public/assets/icons/play.svg'
import play_white from '../../../public/assets/icons/play_white.svg'
import play_black from '../../../public/assets/icons/play_black.svg'
import free_flow_white from '../../../public/assets/icons/free_flow_white.svg'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import PenIcon from './components/penIcon'
import BinIcon from './components/binIcon'
import { SettingsIcon, ShowroomIcon } from './components'
import AddIcon from './components/addIcon'
import CostIcon from './components/historyFile/costIcon'
import ShareLinkIcon from './components/historyFile/shareLinkIcon'
import KeyIcon from './components/keyIcon'
import JourneySharingIconV2 from './components/journey/sharingV2'
import ChevronDown from './components/chevronDown'

const Arrow = styled.img`
    background-color: transparent;
    @media ${device.desktop} {
        width: 16px;
        cursor: pointer;
    }

    @media ${device.mobile} {
        width: 10px;
    }
`

const NavigateBackMobile = styled.img`
    @media ${device.desktop} {
        width: 17px;
        max-height: 13px;
    }

    @media ${device.mobile} {
        width: 50px;
        max-height: 50px;
    }
`

const Send = styled.img`
    max-width: 20px;
    height: 20px;
`

const Copy = styled.img`
    @media ${device.desktop} {
        max-width: 22px;
    }

    @media ${device.mobile} {
        width: 17px;
        max-height: 17px;
    }
`

const Info = styled.img`
    max-width: 20px;
    height: 20px;
`

const Preview = styled.img`
    @media ${device.desktop} {
        max-width: 22px;
    }

    @media ${device.mobile} {
        width: 17px;
        max-height: 13px;
    }
`

const Email = styled.img`
    @media ${device.desktop} {
        max-width: 22px;
    }

    @media ${device.mobile} {
        width: 17px;
        height: 14px;
    }
`

const Edit = styled.img`
    cursor: pointer;
    transition: all 200ms;
    :hover {
        transform: scale(1.2);
    }
`

const VoteUp = styled.img`
    width: 15px;
    @media ${device.beyond_ipad_mobile} {
        width: 20px;
    }
`

type StylePropsSize = {
    height?: string
    width?: string
}

const AdjustedIcon = styled.img<StylePropsSize>`
    height: ${(props) => (props.height ? props.height : '12px')};
    width: ${(props) => (props.width ? props.width : 'auto')};

    @media ${device.smallest_laptops} {
        height: '12px';
    }
`

export interface IconProps {
    icon: string
    orientation?: 'up' | 'down'
    active?: boolean
    color?: string
    height?: string
    width?: string
    isDisabled?: boolean
}

export default function Icon(props: IconProps) {
    const { theme } = useThemes()
    const renderIcon = (
        icon: string,
        orientation?: string,
        active?: boolean
    ) => {
        switch (icon) {
            case 'free_flow_white':
                return (
                    <img
                        src={free_flow_white}
                        alt="free flow"
                        style={{ width: props.height ?? '16px' }}
                    />
                )
            case 'play':
                return (
                    <img
                        src={play}
                        alt="play"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'play_white':
                return (
                    <img
                        src={theme === 'dark' ? play_black : play_white}
                        alt="play"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'info-blue':
                return (
                    <img
                        src={info_blue}
                        alt="info-blue"
                        style={{ height: props.height ?? '20px' }}
                    />
                )

            case 'gallery_section':
                return (
                    <img
                        src={theme === 'light' ? gallery_light : gallery_dark}
                        alt="gallery"
                        style={{ height: props.height ?? '20px' }}
                    />
                )

            case 'kmf_section':
                return (
                    <img
                        src={theme === 'light' ? kmf_light : kmf_dark}
                        alt="key moments and facts"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'description_section':
                return (
                    <img
                        src={
                            theme === 'light'
                                ? description_light
                                : description_dark
                        }
                        alt="description"
                        style={{ height: props.height ?? '20px' }}
                    />
                )

            case 'insurance_section':
                return (
                    <img
                        src={
                            theme === 'light' ? insurance_light : insurance_dark
                        }
                        alt="insurance"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'history_file_section':
                return (
                    <img
                        src={theme === 'light' ? hf_light : hf_dark}
                        alt="history file"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'broker':
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                    >
                        <g clip-path="url(#clip0_1514_11432)">
                            <path
                                d="M27 7.1997V14.4261C27 17.3478 22.625 20.1476 20 21C17.375 20.1476 13 17.3478 13 14.4261V7.1997C14.4 6.72288 17.76 5.21539 20 3C22.24 5.21539 25.6 6.72288 27 7.1997Z"
                                stroke={colours[theme].text_neutral_default_500}
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M20 4.05884V21"
                                stroke={colours[theme].text_neutral_default_500}
                                stroke-width="1.5"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M29.352 35.6409L29.352 33.5257L24.1141 29.235C23.2058 29.4897 22.0692 35.6407 22.0692 35.6407L29.352 35.6409Z"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M33.4142 22.9468L34.4142 19.5207C34.7448 18.3875 35.8052 17.6224 36.9646 17.6815L36.3902 23.0975C35.8125 27.1186 35.6868 27.4107 34.2147 28.6341L29.6827 33.1027"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M24.9334 29.7075V29.7075C25.8245 27.5064 27.3339 25.6105 29.2792 24.2487L30.5284 23.3743C31.3743 22.9514 31.7974 22.5493 32.8279 23.1442L33.1718 23.2209L32.8277 24.398C32.7179 24.7751 32.5274 25.124 32.2697 25.4199L29.6993 28.3707"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10.8433 35.6409L10.8433 33.5257L16.0812 29.235C16.9896 29.4897 18.1261 35.6407 18.1261 35.6407L10.8433 35.6409Z"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M6.78111 22.9468L5.78114 19.5207C5.45056 18.3875 4.39011 17.6224 3.23067 17.6815L3.80508 23.0975C4.38277 27.1186 4.50854 27.4107 5.98063 28.6341L10.5126 33.1027"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M15.2619 29.7075V29.7075C14.3708 27.5064 12.8614 25.6105 10.9161 24.2487L9.66694 23.3743C8.821 22.9514 8.3979 22.5493 7.36741 23.1442L7.0235 23.2209L7.36758 24.398C7.47745 24.7751 7.66793 25.124 7.92561 25.4199L10.496 28.3707"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1514_11432">
                                <rect
                                    width="40"
                                    height="40"
                                    fill={colours[theme].background_default}
                                />
                            </clipPath>
                        </defs>
                    </svg>
                )

            case 'info_gray':
                return (
                    <img
                        src={info_gray}
                        alt="info-grey"
                        style={{
                            height: props.height ?? '20px',
                        }}
                    />
                )
            case 'info-red':
                return (
                    <img
                        src={info_red}
                        alt="info-red"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'filter-mobile':
                return (
                    <img
                        src={filter_mobile}
                        alt="filter"
                        style={{ height: '20px' }}
                    />
                )
            case 'archive':
                return (
                    <img
                        src={archive}
                        alt="archive"
                        style={{
                            height: props.height ?? '20px',
                            filter: props.isDisabled ? 'saturate(0)' : 'none',
                            opacity: props.isDisabled ? 0.6 : 1,
                        }}
                    />
                )

            case 'car_handover':
                return (
                    <KeyIcon color={props.color} size={props.width ?? '16'} />
                )

            case 'showroom':
                return <ShowroomIcon size={props.height ?? '16'} />
            case 'menu_share':
                return (
                    <JourneySharingIconV2
                        color={props.color ?? colours[theme].text_darker}
                        size={props.height ? props.height : '16'}
                    />
                )

            case 'image':
                return (
                    <img
                        src={image}
                        alt="image"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'history_file_menu':
                return (
                    <img
                        src={history_file_menu}
                        alt="history_file_menu"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'vehicle_bio':
                return (
                    <img
                        src={vehicle_bio}
                        alt="vehicle_bio"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'key_moments_and_facts':
                return (
                    <img
                        src={key_moments_and_facts}
                        alt="key_moments_and_facts"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'description':
                return (
                    <img
                        src={description}
                        alt="description"
                        style={{ height: props.height ?? '20px' }}
                    />
                )
            case 'status-task-mobile':
                return (
                    <img
                        src={task_status_mobile}
                        alt="task_status_mobile"
                        style={{ height: '20px' }}
                    />
                )

            case 'arrow':
                return (
                    // <Arrow
                    //     src={orientation === 'down' ? chevrondown : chevronup}
                    //     alt="navigation-arrow"
                    // />
                    orientation === 'down' ? (
                        <ChevronDown size="16" />
                    ) : (
                        <div style={{ transform: 'rotate(180deg)' }}>
                            <ChevronDown size="16" />
                        </div>
                    )
                )
            case 'blue-arrow':
                return (
                    <Arrow
                        src={
                            orientation === 'down'
                                ? chevrondownblue
                                : chevronupblue
                        }
                        alt="navigation-arrow"
                    />
                )
            case 'blue_arrow_previous':
                return (
                    <img
                        src={arrow_blue_previous}
                        alt="navigation-arrow-previous"
                    />
                )
            case 'blue_arrow_next':
                return (
                    <img
                        style={{
                            transform: 'rotate(180deg)',
                            height: props.height ?? 'auto',
                        }}
                        src={arrow_blue_previous}
                        alt="navigation-arrow-previous"
                    />
                )
            case 'three_dots':
                return <img src={three_dots} alt="three-dots" />

            case 'red_cross':
                return <img src={red_cross} alt="red-cross" />

            case 'desktopLinkNavigationArrow':
                return (
                    <DesktopLinkNavArrow
                        color={props.color ? props.color : 'black'}
                    />
                )
            case 'handler':
                return <img src={dragger} alt="drag-handler" />
            case 'three_dots_vertical':
                return (
                    <motion.div whileTap={{ scale: 1.3 }}>
                        <img
                            className="car-gallery-image-tuto-mobile-1"
                            style={{ height: props.height ?? '20px' }}
                            src={three_dots_vertical}
                            alt="three-dots-modal"
                        />
                    </motion.div>
                )

            case 'exit_actions_menu_mobile':
                return (
                    <motion.div whileTap={{ scale: 1.3 }}>
                        <img
                            style={{
                                height: props.height ? props.height : '42px',
                            }}
                            src={exit_actions_menu_mobile}
                            alt="exit-actions-menu"
                        />
                    </motion.div>
                )
            case 'edit_pen':
                return (
                    <Edit
                        style={{
                            height: props.height ? props.height : '20px',
                        }}
                        src={edit_pen}
                        alt="edit-pen"
                    />
                )
            case 'edit_pen_naked':
                return (
                    <Edit
                        style={{
                            height: props.height ? props.height : '16px',
                            width: props.width ? props.width : '16px',
                        }}
                        src={edit_pen_naked}
                        alt="edit-pen"
                    />
                )
            case 'edit_pen_v2':
                return (
                    // <Edit
                    //     style={{
                    //         height: props.height ? props.height : '20px',
                    //         width: props.width ? props.width : '20px',
                    //     }}
                    //     src={edit_pen_v2}
                    //     alt="edit-pen"
                    // />
                    <PenIcon color={colours[theme].text_darker} />
                )
            case 'add_plus':
                return (
                    <motion.div whileTap={{ scale: 1.3 }}>
                        <img
                            style={{
                                height: props.height ? props.height : '20px',
                            }}
                            src={add_plus}
                            alt="add-btn"
                        />
                    </motion.div>
                )

            case 'add_strong':
                return (
                    <motion.div whileTap={{ scale: 1.3 }}>
                        <img
                            style={{
                                height: props.height ? props.height : '16px',
                                width: props.width ? props.width : '16px',
                            }}
                            src={add_strong}
                            alt="add-btn"
                        />
                    </motion.div>
                )

            case 'tasks_user_banner':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                        }}
                        src={tasks_user_banner}
                        alt="tasks-pen"
                    />
                )

            case 'calendar':
                return (
                    <motion.div whileTap={{ scale: 1.3 }}>
                        <img
                            style={{
                                height: props.height ? props.height : '20px',
                            }}
                            src={calendar_btn_use}
                            alt="calendar_btn_use"
                        />
                    </motion.div>
                )

            case 'add_plus_white':
                return <AddIcon />
            case 'arrow-next-gallery-desktop':
                return (
                    <img
                        style={{
                            height: props.height ? props.height : '14px',
                            outline: 'none',
                        }}
                        src={arrow_next_gallery_slide_desktop}
                        alt="arrow-gallery-desktop"
                    />
                )
            case 'arrow-prev-gallery-desktop':
                return (
                    <img
                        style={{
                            height: props.height ? props.height : '14px',
                        }}
                        src={arrow_prev_gallery_slide_desktop}
                        alt="arrow-gallery-desktop"
                    />
                )

            case 'location':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                            height: props.width ? props.width : '20px',
                        }}
                        src={location}
                        alt="location-icon"
                    />
                )

            case 'location_white':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                        }}
                        src={location_white}
                        alt="location-icon"
                    />
                )

            case 'arrow_white':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                        }}
                        src={arrow_white}
                        alt="arrow-white"
                    />
                )

            case 'close_cross_white':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                        src={close_cross_white}
                        alt="close-white"
                    />
                )
            case 'share_link':
                return (
                    <img
                        style={{
                            height: props.height ? props.height : '20px',
                            width: props.height ? props.height : '20px',
                        }}
                        src={share_link}
                        alt="arrow-white"
                    />
                )
            case 'new_share_link':
                return (
                    <img
                        style={{
                            height: props.height ? props.height : '18px',
                            width: props.height ? props.height : '18px',
                        }}
                        src={new_share_link_icon}
                        alt="arrow-white"
                    />
                )

            case 'new_share_link_black':
                return <ShareLinkIcon />

            case 'credits':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                            height: props.width ? props.width : '20px',
                        }}
                        src={credits_circle}
                        alt="credits-icon"
                    />
                )
            case 'email_at':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                            height: props.width ? props.width : '20px',
                        }}
                        src={email_at}
                        alt="email-at-icon"
                    />
                )
            case 'car_listed':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                            height: props.width ? props.width : '20px',
                        }}
                        src={car_listed}
                        alt="car-listed-icon"
                    />
                )

            case 'chevron_down_light':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                            height: props.width ? props.width : '20px',
                        }}
                        src={chevron_down_light}
                        alt="chevron down light"
                    />
                )

            case 'chevron_left':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '16px',
                            height: props.width ? props.width : '16px',
                        }}
                        src={chevronLeft}
                        alt="chevron left"
                    />
                )

            case 'blue-arrow-gallery-slide-mobile':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : 'auto',
                            height: props.width ? props.width : '20px',
                        }}
                        src={blue_arrow_gallery_slide_mobile}
                        alt="blue arrow gallery slide mobile"
                    />
                )

            case 'information_italic':
                return (
                    <img
                        style={{
                            height: props.height ? props.height : '12px',
                        }}
                        src={information_italic}
                        alt="information italic"
                    />
                )

            case 'information_italic_white':
                return (
                    <img
                        style={{
                            height: props.height ? props.height : '12px',
                        }}
                        src={information_italic_white}
                        alt="information italic"
                    />
                )

            case 'padlock':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                            height: props.width ? props.width : '20px',
                        }}
                        src={padlock}
                        alt="padlock"
                    />
                )

            case 'globe':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '20px',
                            height: props.width ? props.width : '20px',
                        }}
                        src={globe}
                        alt="globe"
                    />
                )

            case 'upload_tuto_mobile':
                return (
                    <motion.div whileTap={{ scale: 1.3 }}>
                        <img
                            style={{
                                height: props.height ? props.height : '13px',
                            }}
                            src={upload_tuto_mobile}
                            alt="upload-actions"
                        />
                    </motion.div>
                )

            case 'add':
                return (
                    <motion.div whileTap={{ scale: 1.3 }}>
                        <img
                            style={{
                                height: props.height ? props.height : '12px',
                            }}
                            src={add_tuto_mobile}
                            alt="add-actions"
                        />
                    </motion.div>
                )

            case 'crop_img_circle':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '22px',
                            cursor: 'pointer',
                        }}
                        src={reposition_button_circle_blue}
                        alt="crop_img_circle"
                    />
                )

            case 'bin_img_circle':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '22px',
                            cursor: 'pointer',
                        }}
                        src={bin_button_circle_blue}
                        alt="bin_img_circle"
                    />
                )

            case 'delete_img':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '22px',
                            cursor: 'pointer',
                        }}
                        src={delete_img}
                        alt="delete_img"
                    />
                )
            case 'reminder':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '19px',
                            // cursor: 'pointer',
                        }}
                        src={reminder_icon}
                        alt="reminder_img"
                    />
                )
            case 'filter_icon':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '25px',
                            cursor: 'pointer',
                        }}
                        src={filter_icon}
                        alt="filter_img"
                    />
                )
            case 'sort_icon':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '25px',
                            cursor: 'pointer',
                        }}
                        src={sort_icon}
                        alt="sort_img"
                    />
                )

            case 'copy':
                return <Copy src={copycon} alt="copy-icon" />
            case 'copy_clipboard':
                return (
                    <Copy
                        style={{
                            width: props.height ? props.height : '25px',
                            height: props.height ? props.height : '17px',
                        }}
                        src={copy_icon}
                        alt="copy-icon"
                    />
                )
            case 'copy_clipboard_grey':
                return (
                    <Copy
                        style={{
                            width: props.height ? props.height : '25px',
                            height: props.height ? props.height : '17px',
                        }}
                        src={copy_icon_grey}
                        alt="copy-icon"
                    />
                )
            case 'email_icon':
                return (
                    <Copy
                        style={{
                            width: props.height ? props.height : '25px',
                            height: props.height ? props.height : '17px',
                        }}
                        src={email_icon}
                        alt="email-btn"
                    />
                )

            case 'cog':
                return (
                    <Copy
                        style={{
                            width: props.height ? props.height : '25px',
                            height: props.height ? props.height : '17px',
                        }}
                        src={cog}
                        alt="settings-btn"
                    />
                )

            case 'blue_cog':
                return (
                    <SettingsIcon
                        color={colours[theme].primary_strong}
                        width={props.height ? props.height : '16'}
                    />
                )

            // case 'red_cross':
            //     return (
            //         <Copy
            //             style={{
            //                 width: props.height
            //                     ? props.height
            //                     : '25px',
            //                 height: props.height
            //                     ? props.height
            //                     : '17px',
            //             }}
            //             src={cog}
            //             alt="settings-btn"
            //         />
            //     )

            case 'voteUp':
                return <VoteUp src={vote_up} alt="vote-up-icon" />
            case 'email':
                return <Email src={email} alt="email-icon" />
            case 'send':
                return <Send src={sendIcon} alt="send-icon" />
            case 'info':
                return <Info src={info} alt="info-icon" />
            case 'preview':
                return <Preview src={preview} alt="preview-icon" />
            case 'go_back':
                return (
                    <NavigateBackMobile
                        src={back_circle_navigation}
                        alt="go-back-icon"
                    />
                )

            case 'gbp_black':
                return (
                    <AdjustedIcon
                        style={{
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '15px',
                        }}
                        src={gbp_black}
                        alt="british_pound_icon"
                    />
                )

            case 'euro_black':
                return (
                    <AdjustedIcon
                        style={{
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '15px',
                        }}
                        src={euro_black}
                        alt="euro_icon"
                    />
                )

            case 'dollar_black':
                return (
                    <AdjustedIcon
                        style={{
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '13px',
                        }}
                        src={dollar_black}
                        alt="dollar_icon"
                    />
                )

            case 'company_house_black':
                return (
                    <AdjustedIcon
                        height="16px"
                        src={company_house_black}
                        alt="company_house_icon"
                        style={{
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '13px',
                        }}
                    />
                )

            case 'attachment_black':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '15px',
                        }}
                        src={attachment_black}
                        alt="attachment_icon"
                    />
                )

            case 'navigate_square':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '36px',
                        }}
                        src={navigate_square}
                        alt="navigate_square"
                    />
                )

            case 'v2_add_white':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '20px',
                        }}
                        src={v2_add_white}
                        alt="v2_add_circle"
                    />
                )
            case `v2_add`:
                return (
                    <img
                        style={{
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '20px',
                        }}
                        src={v2_add}
                        alt="v2_add_circle"
                    />
                )

            case 'v2_edit':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '20px',
                        }}
                        src={v2_edit}
                        alt="v2_edit"
                    />
                )

            case 'download':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '18px',
                        }}
                        src={download_icon_grey}
                        alt="download_icon"
                    />
                )
            case 'download_blue':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : 'auto',
                        }}
                        src={download_blue}
                        alt="download_blue"
                    />
                )
            case 'grey_cross':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : 'auto',
                        }}
                        src={grey_cross}
                        alt="close_grey_cross_icon"
                    />
                )
            case 'grey_cross_v2':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : 'auto',
                        }}
                        src={grey_cross_v2}
                        alt="close_grey_cross_icon"
                    />
                )

            case 'blue_cross':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : 'auto',
                        }}
                        src={blue_cross_v2}
                        alt="blue_cross"
                    />
                )

            case 'blue_cross_w_circle':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : 'auto',
                        }}
                        src={blue_cross_w_circle}
                        alt="blue_cross_w_circle"
                    />
                )

            case 'grey_cross_w_circle':
                return (
                    <img
                        style={{
                            transform: 'translateY(2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '20px',
                        }}
                        src={grey_cross_w_circle}
                        alt="close_grey_cross_w_circle"
                    />
                )

            case 'light_red_cross':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '8px',
                        }}
                        src={light_red_cross}
                        alt="light_red_cross"
                    />
                )

            case 'blue_check':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '8px',
                        }}
                        src={blue_check}
                        alt="blue_check"
                    />
                )

            case 'white_check':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '10px',
                        }}
                        src={white_check}
                        alt="blue_check"
                    />
                )

            case 'light_grey_check':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '8px',
                        }}
                        src={light_grey_check}
                        alt="light_grey__check"
                    />
                )

            case 'grey_check':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '8px',
                        }}
                        src={grey_check}
                        alt="light_grey__check"
                    />
                )

            case 'long_green_arrow':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '5px',
                        }}
                        src={long_green_arrow}
                        alt="blue_check"
                    />
                )

            case 'long_green_arrow_to_right':
                return (
                    <img
                        style={{
                            transform: 'rotate(180deg)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '5px',
                        }}
                        src={long_green_arrow}
                        alt="blue_check"
                    />
                )

            case 'thick_red_bin':
                return (
                    <img
                        style={{
                            // transform: 'translateY(-2px)',
                            width: props.width ? props.width : 'auto',
                            height: props.height ? props.height : '11px',
                            transform: 'translateY(1px)',
                        }}
                        src={thicker_red_bin}
                        alt="thick_red_bin"
                    />
                )

            case 'grey_bin':
                return <BinIcon />

            case 'cost_icon_v2':
                return (
                    // <img
                    //     style={{
                    //         // transform: 'translateY(-2px)',
                    //         width: props.width ? props.width : 'auto',
                    //         height: props.height ? props.height : '16px',
                    //     }}
                    //     src={cost_icon_v2}
                    //     alt="cost_icon_v2"
                    // />
                    <CostIcon />
                )

            case 'car_profile_image':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '14px',
                        }}
                        src={carProfileImageSign}
                        alt="car_profile_image"
                    />
                )

            case 'arrow_to_right':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '18px',
                        }}
                        src={arrow_to_right}
                        alt="arrow_to_right"
                    />
                )

            case 'arrow_to_left':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '18px',
                            transform: 'rotate(180deg)',
                        }}
                        src={arrow_to_right}
                        alt="arrow_to_left"
                    />
                )

            case 'reload':
                return (
                    <img
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                        src={reload_blue}
                        alt="reload_blue"
                    />
                )

            case 'sort-icon':
                return (
                    <img
                        src={sort_icon_black}
                        alt="sort"
                        style={{
                            height: props.height ? props.height : '12px',
                        }}
                    />
                )
            case 'sort-icon-grey':
                return (
                    <img
                        src={sort_icon_grey}
                        alt="sort"
                        style={{
                            height: props.height ? props.height : '16px',
                        }}
                    />
                )

            case 'sort-icon-blue':
                return (
                    <img
                        src={sort_icon_blue}
                        alt="sort"
                        style={{
                            height: props.height ? props.height : '16px',
                        }}
                    />
                )
            case 'tasks':
                return (
                    <img
                        src={tasks}
                        alt="tasks"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )

            case 'task':
                return (
                    <img
                        src={task}
                        alt="task"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )
            case 'home':
                return (
                    <img
                        src={home}
                        alt="home"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )
            case 'history_file':
                return (
                    <img
                        src={history_file}
                        alt="history_file"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )
            case 'sharing':
                return (
                    <img
                        src={sharing}
                        alt="sharing"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )
            case 'upcoming':
                return (
                    <img
                        src={upcoming}
                        alt="upcoming"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )
            case 'apex':
                return (
                    <img
                        src={apex}
                        alt="apex"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )
            case 'instagram':
                return (
                    <img
                        src={instagram}
                        alt="instagram"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )
            case 'twitter':
                return (
                    <img
                        src={twitter}
                        alt="twitter"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )
            case 'facebook':
                return (
                    <img
                        src={facebook}
                        alt="facebook"
                        style={{
                            width: props.width ? props.width : '12px',
                        }}
                    />
                )

            case 'add_user':
                return (
                    <img
                        src={add_user_icon}
                        alt="add_user"
                        style={{
                            width: props.width ? props.width : '20px',
                            height: props.height ? props.height : 'auto',
                        }}
                    />
                )
            case 'add_user_blue':
                return (
                    <img
                        src={add_user_icon_blue}
                        alt="add_user"
                        style={{
                            width: props.width ? props.width : '20px',
                        }}
                    />
                )
            case 'get_link':
                return (
                    <img
                        src={get_link_icon}
                        alt="get_link"
                        style={{
                            width: props.width ? props.width : '20px',
                        }}
                    />
                )

            case 'blue_search':
                return (
                    <img
                        src={blue_thin_search}
                        alt="blue_thin_search"
                        style={{
                            width: props.width ? props.width : '20px',
                        }}
                    />
                )

            case 'light_bulb':
                return (
                    <img
                        src={light_bulb}
                        alt="light_bulb"
                        style={{
                            width: props.width ? props.width : '20px',
                        }}
                    />
                )
            case 'filters_icon_green':
                return (
                    <img
                        src={filters_icon_green}
                        alt="filters_icon_green"
                        style={{
                            width: props.width ? props.width : '16px',
                        }}
                    />
                )

            case 'underwriter':
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                    >
                        <g clip-path="url(#clip0_1514_11456)">
                            <path
                                d="M30.9239 18.75V5H8.75V35H15.462"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M35 26.4997V32.5217C35 34.9565 31.25 37.2897 29 38C26.75 37.2897 23 34.9565 23 32.5217V26.4997C24.2 26.1024 27.08 24.8462 29 23C30.92 24.8462 33.8 26.1024 35 26.4997Z"
                                stroke={colours[theme].text_neutral_default_500}
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M29 24V38"
                                stroke={colours[theme].text_neutral_default_500}
                                stroke-width="1.5"
                            />
                            <path
                                d="M27.1875 10H18.125"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <circle
                                cx="13.75"
                                cy="10"
                                r="1.25"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                            />
                            <path
                                d="M27.1875 16.25H18.125"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <circle
                                cx="13.75"
                                cy="16.25"
                                r="1.25"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                            />
                            <path
                                d="M20.625 22.5H18.125"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <circle
                                cx="13.75"
                                cy="22.5"
                                r="1.25"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                            />
                            <path
                                d="M19.375 28.75H18.125"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <circle
                                cx="13.75"
                                cy="28.75"
                                r="1.25"
                                stroke={colours[theme].text_strong}
                                stroke-width="1.5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1514_11456">
                                <rect
                                    width="40"
                                    height="40"
                                    fill={colours[theme].background_default}
                                />
                            </clipPath>
                        </defs>
                    </svg>
                )

            default:
                return <div>Icon?</div>
        }
    }

    return renderIcon(props.icon, props.orientation && props.orientation)
}
