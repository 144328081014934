import styled from 'styled-components'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import FadedSlower from '../../templates/animated/FadedSlower'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { device } from '../../templates/displays/devices'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

export const EnquiryBtnsOnlyMobile = styled.div`
    width: 100%;
    box-sizing: border-box;
    @media screen and (min-width: 880px) {
        display: none;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
`

const Title = styled.div`
    text-align: center;
    color: var(--text-strong, #1a1a1a);
    font-size: 24px;
    font-family: Lato-Bold;

    @media screen and (min-width: 880px) {
        font-size: 32px;
    }
`

const SubTitle = styled.div`
    text-align: center;
    color: var(--text-default, #666666);
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    max-width: 420px;
`

const IpadPadingTop = styled.div<{ needsPaddingTop: boolean }>`
    @media ${device.ipad} {
        padding-top: 16vh;
    }
    @media ${device.mobile} {
        padding-top: ${(props) => (props.needsPaddingTop ? '8vh' : 0)};
    }
`

type Props = {
    onClickContinute: () => any
    new_owner_name: string
    hasInfoBox?: boolean
}
const CarHandoverSuccess = (props: Props) => {
    const { theme } = useThemes()
    return (
        <div style={{ width: '100%' }}>
            <FadedSlower>
                <Container>
                    <IpadPadingTop
                        needsPaddingTop={props.hasInfoBox ? false : true}
                    />

                    <div style={{ paddingTop: '24px' }} />

                    <Title>Handover request sent</Title>
                    <div style={{ paddingTop: '16px' }} />
                    <SubTitle>
                        You’ve sent a handover request to
                        <p
                            style={{
                                fontWeight: 600,
                                color: colours[theme].text_strong,
                            }}
                        >
                            {' '}
                            {props.new_owner_name}
                        </p>
                        <br />
                        The car will be moved to “Previously owned” tab in your
                        garage and you will be notified via email when the new
                        owner accepts the handover request.
                        <br />
                        If you change your mind, you may cancel the handover
                        process at any time (before the new owner accepts it) in
                        your Car page.
                    </SubTitle>

                    <div style={{ paddingTop: '50px' }} />

                    <DesktopDisplayOnly>
                        <ButtonAtom
                            theme="main-cta-onboarding"
                            onClick={() => {
                                props.onClickContinute()
                            }}
                            disabled={false}
                            dataCyId={`handoversuccess`}
                            height={'48px'}
                            width={'400px'}
                            fontSize={16}
                        >
                            Continue
                        </ButtonAtom>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <ButtonAtom
                            theme="main-cta-onboarding"
                            onClick={() => {
                                props.onClickContinute()
                            }}
                            disabled={false}
                            dataCyId={`handoversuccess`}
                            height={'48px'}
                            width={'86vw'}
                            fontSize={16}
                        >
                            Continue
                        </ButtonAtom>
                    </IpadAndMobileDisplay>
                </Container>
            </FadedSlower>
        </div>
    )
}

export default CarHandoverSuccess
