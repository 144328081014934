import { useNavigate, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import colours, {
    history_file_colours,
} from '../../../../providers/theme/colours'
import { CategoryLabel } from '../../../molecules/timelineItemDesktop/timelineItemBodyDesktop'
import { ICarInsightsResAPI_entry } from '../../../organisms/carInsights/body'
import { device } from '../../../templates/displays/devices'
import { renderCategoryIcon } from '../../icons/components/historyFile/categories'
import { AddIcon } from '../../statefulicons'
import { useAppSelector } from '../../../../redux/store/hooks'
import { ITimeRangeEnum } from '../../menu/carInsights/timeMenu'
import useThemes from '../../../../providers/theme/hooks'
import {
    ICarsObject,
    ICar,
    ICarInsights,
} from '../../../../redux/entities/cars/types'
import { ITimelineEntryTypes } from '../../../../redux/timeline/types'

type Props = {
    index: number
    keyID: string
}

const Tile = styled(motion.div)`
    /* all: unset; */
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 136px;
    width: 160px;

    @media ${device.large_desktop} {
        padding: 24px;
    }

    @media (min-width: 1400px) {
        width: 20%;
        max-width: 198px;
    }

    @media (min-width: 1000px) and (max-width: 1400px) {
        width: 186px;
    }

    @media ${device.smallest_laptops} {
        width: 156px;
        padding: 12px;
    }

    @media ${device.ipad} {
        width: 156px;
    }

    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
    }
    // 180px

    @media (min-width: 984px) and (max-width: 1200px) {
        width: 156px;
        padding: 12px;
    }
    @media (min-width: 881px) and (max-width: 900px) {
        width: 155px;
        padding: 12px;
    }

    :hover {
        cursor: pointer;
        opacity: 0.9;
    }
`

const Row1 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: row;
`

const Row2 = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: fit-content;
    gap: 4px;
`

const Cost = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;

    @media ${device.ipad} {
        font-size: 14px;
    }
`

const InvoiceNumber = styled.div`
    color: var(--text-muted);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

type Props2 = {
    categoryID: ITimelineEntryTypes | string
}

export const IconSquareInsights = (props: Props2) => {
    let { categoryID } = props
    const { theme } = useThemes()

    let primary_16 =
        categoryID === 'all'
            ? colours[theme].primary_09
            : // @ts-ignore
              history_file_colours[theme].categories[categoryID].primary_16

    let primary_100 =
        categoryID === 'all'
            ? colours[theme].primary
            : // @ts-ignore
              history_file_colours[theme].categories[categoryID].primary_100

    let size = categoryID === 'all' ? 34 : 24

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={size} height={size} rx="4" fill={primary_16} />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5 16.5833L11.5 9L13 9L13 16.5833L11.5 16.5833Z"
                fill={primary_100}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 16.5833L8 12L9.5 12L9.5 16.5833L8 16.5833Z"
                fill={primary_100}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 16.5833L15 7L16.5 7L16.5 16.5833L15 16.5833Z"
                fill={primary_100}
            />
        </svg>
    )
}

const CostSVG = () => {
    const { theme } = useThemes()
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.25 0.25H14.75V16.4014L12 14.5681L10 15.9014L8 14.5681L6 15.9014L4 14.5681L1.25 16.4014V0.25ZM2.75 1.75V13.5986L4 12.7653L6 14.0986L8 12.7653L10 14.0986L12 12.7653L13.25 13.5986V1.75H2.75Z"
                fill={colours[theme].text_muted}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.25 9.58325H8.75V11.0833H4.25V9.58325Z"
                fill={colours[theme].text_muted}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0508 9.58325H11.7508V11.0833H10.0508V9.58325Z"
                fill={colours[theme].text_muted}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 4.41663C7.30964 4.41663 6.75 4.97627 6.75 5.66663C6.75 6.35698 7.30964 6.91663 8 6.91663C8.69036 6.91663 9.25 6.35698 9.25 5.66663C9.25 4.97627 8.69036 4.41663 8 4.41663ZM5.25 5.66663C5.25 4.14784 6.48122 2.91663 8 2.91663C9.51878 2.91663 10.75 4.14784 10.75 5.66663C10.75 7.18541 9.51878 8.41663 8 8.41663C6.48122 8.41663 5.25 7.18541 5.25 5.66663Z"
                fill={colours[theme].text_muted}
            />
        </svg>
    )
}

const CarInsightsTile = (props: Props) => {
    const { theme } = useThemes()
    const navigate = useNavigate()
    const location = useLocation()

    let { index, keyID } = props

    let keyIDAdjusted = keyID === 'other' ? 'others' : keyID

    const carsData: ICarsObject = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    const carID = useAppSelector((state) => {
        return state.entities.carsData.currentCarID
    })
    let current: ICar | undefined = carID ? carsData[carID] : undefined
    let carInsights: ICarInsights | undefined = current?.insights
    let params2 = new URLSearchParams(location.search)

    let selectedID: ITimeRangeEnum =
        params2.get('time_range') === 'year'
            ? 'year'
            : params2.get('time_range') === 'last_year'
            ? 'last_year'
            : params2.get('time_range') === 'all_time'
            ? 'all_time'
            : params2.get('time_range') === 'custom'
            ? 'custom'
            : 'year'

    let tileData: ICarInsightsResAPI_entry | undefined =
        selectedID &&
        carInsights &&
        carInsights[selectedID] &&
        carInsights![selectedID]!.stats &&
        carInsights![selectedID]!.stats.entries &&
        carInsights![selectedID]!.stats.entries.filter(
            (item: ICarInsightsResAPI_entry) => {
                return item.car_entry_type === keyIDAdjusted
            }
        )[0]

    let currency =
        carInsights?.[selectedID]?.stats?.currency === 'GBP'
            ? '£'
            : carInsights?.[selectedID]?.stats?.currency === 'USD'
            ? '$'
            : carInsights?.[selectedID]?.stats?.currency === 'EUR'
            ? '€'
            : '£'

    let showAdd: boolean = tileData && tileData.cost_sum > 0 ? false : true

    return (
        <Tile
            whileTap={{ scale: 1.05 }}
            role={'button'}
            key={`${index}_${keyIDAdjusted}`}
            data-ph={!showAdd ? `car-insights-tile` : `add-history-file-btn`}
            style={{
                background:
                    // @ts-ignore
                    history_file_colours[theme].categories[keyIDAdjusted]
                        .bg_gradient,
            }}
            onClick={() => {
                if (!showAdd) {
                    let params = new URLSearchParams(location.search)

                    params.delete('categoryID')

                    if (keyIDAdjusted === 'other') {
                        keyIDAdjusted = 'others'
                    }
                    params.append('categoryID', `${keyIDAdjusted}`)

                    navigate({
                        search: params.toString(),
                    })
                    // not needed anymore given Use Effet
                    // setTimeout(() => {
                    //     categorySearch()
                    // }, 100)
                    // search
                } else {
                    navigate(`/car/${carID}/history-file/create`)
                }
            }}
        >
            <Row1>
                <CategoryLabel
                    $theme={theme}
                    // @ts-ignore
                    $category={keyIDAdjusted ?? 'maintenance'}
                >
                    {
                        // @ts-ignore
                        renderCategoryIcon(keyIDAdjusted)
                    }{' '}
                    {keyIDAdjusted}
                </CategoryLabel>
                <div style={{ width: '24px', height: '24px' }}>
                    <IconSquareInsights categoryID={keyIDAdjusted} />
                </div>
            </Row1>

            <Row1>
                {/* to do : currency */}
                {showAdd ? (
                    <InvoiceNumber>{`${currency}`}0.00</InvoiceNumber>
                ) : (
                    <Cost>
                        {tileData && `${currency}${tileData.cost_sum / 100}`}
                    </Cost>
                )}

                {showAdd ? (
                    <AddIcon
                        colour={
                            // @ts-ignore
                            history_file_colours[theme].categories[
                                keyIDAdjusted
                            ].primary_100
                        }
                        onClick={() => {}}
                        height={'16'}
                    />
                ) : (
                    <Row2>
                        <InvoiceNumber>
                            {tileData && tileData.count}
                        </InvoiceNumber>
                        <div style={{ width: '16px', height: '16px' }}>
                            <CostSVG />
                        </div>
                    </Row2>
                )}
            </Row1>
        </Tile>
    )
}

export default CarInsightsTile
