import { Tooltip } from 'antd'
import { useRef } from 'react'
import styled from 'styled-components'
import { showroomActions } from '../../../../../redux/showroom/reducer'
import {
    IFilterID,
    IShowroomFiltersState,
} from '../../../../../redux/showroom/types'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import Expander from '../../../../atoms/expander/expander'
import { SearchIcon } from '../../../../atoms/statefulicons'
import { device } from '../../../../templates/displays/devices'

import useWindowSize, {
    WindowSize,
} from '../../../../templates/displays/windowSizeHook'
import ShowroomDismissableFiltersBarDesktop from '../../../smartHeadres/showroom/showroomDismissableFiltersBarDesktop'
import ShowroomFilterFormManagerDesktop from '../filterManager/showroomFilterFormManagerDesktop'
import { MenuAdjust } from '../../../../templates/displays/pageWrappers/CenteredPageWrapper'

type IStyleProps = {
    $isMenuCollapsed?: boolean
    $IsWithinPageLimits?: boolean
}

const Wrap = styled.div<IStyleProps>`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    z-index: ${(props) =>
        props.$IsWithinPageLimits ? undefined : '500 !important'};

    /* @media (min-width: 2200px) {
        transform: translateY(-18px);
    } */
`

const Container = styled.div<IStyleProps>`
    background: #ffffff;
    border: ${(props) =>
        props.$IsWithinPageLimits
            ? '1px solid var(--border-muted, #e5e5e5)'
            : 'none'};
    border-radius: ${(props) => (props.$IsWithinPageLimits ? '4px' : 'none')};
    min-height: 96px;
    padding: 24px;
    padding-left: ${(props) => (props.$IsWithinPageLimits ? '24px' : '0px')};

    @media (max-width: 1050px) {
        padding-left: ${(props) =>
            props.$IsWithinPageLimits ? '20px' : '0px'};
        min-height: 80px;
    }
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */

    justify-content: space-between;

    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    align-self: center;
    transition: all 100ms;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    @media ${device.desktop} {
        max-width: 83vw;
        padding-left: ${(props) =>
            props.$IsWithinPageLimits ? '24px' : '0px'};
    }

    @media ${device.large_desktop} {
        max-width: 80vw;
        padding-left: ${(props) =>
            props.$IsWithinPageLimits ? '30px' : '16px'};
        padding-right: ${(props) =>
            props.$IsWithinPageLimits ? '24px' : '8px'};
    }

    @media screen and (min-width: 1900px) {
        max-width: 1600px;
    }
    @media screen and (min-width: 2300px) {
        max-width: 1800px;
    }

    @media ${device.smallest_laptops} {
        max-width: 80vw;

        padding-left: ${(props) =>
            props.$IsWithinPageLimits ? '20px' : '0px'};
    }
`
const FiltersRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const FilterFormWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--bg-color, #fff);
    width: 280px;
    z-index: 2;
    left: 0;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    padding-top: 10px;
    height: auto;
    border-radius: 4px;
`

const ActiveCircle = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4dc9c5;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    z-index: 1;
    top: -5px;
    right: 6px;
    color: white;
    font-family: Lato-Bold;
    font-size: 12px !important;
`
const ActiveCircleSimple = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 5px solid #4dc9c5;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    z-index: 1;
    top: -5px;
    right: 8px;
    background-color: var(--bg-color, #fff);
    font-family: Lato-Bold;
    font-size: 12px !important;
`
type IStyledProps = {
    isActive?: boolean
}
const FilterItemBtn = styled.div<IStyledProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 4px;
    font-size: 16px;
    font-family: Lato;
    color: var(--text-default, #666666);
    cursor: pointer;
    transition: all 150ms;
    user-select: none;
    background: ${(props: IStyledProps) =>
        props.isActive ? 'rgba(26, 26, 26, 0.04)' : 'white'};

    :hover {
        background: rgba(26, 26, 26, 0.04);
    }

    @media (max-width: 1400px) {
        font-size: 14px;
        padding-left: 16px;
        padding-right: 16px;
    }

    @media (max-width: 1050px) {
        font-size: 13px;
        padding-left: 14px;
        padding-right: 14px;
    }

    @media (max-width: 1000px) {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
    }
    z-index: 0;
`
const SearchBtn = styled.div<IStyleProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: #5ec3ca;
    transition: all 150ms;
    border-radius: 4px;
    cursor: pointer;

    /* margin-left: ${(props) => (props.$isMenuCollapsed ? '0px' : '50px')};
    margin-right: ${(props) => (props.$isMenuCollapsed ? '100px' : '0px')}; */

    transition: all 100ms;
    :hover {
        background: #41b5bd;
    }
`

const Separator = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #e5e5e5;
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    align-self: center;
`

const filters_id_array_all: IFilterID[] = [
    'make',
    'model',
    'year',
    'price',
    'location',
    'type_of_sale',
    'keyword',
    'mileage',
    'colour',
    'body_type',
    'drive_side',
]

const filters_id_array_small: IFilterID[] = [
    'make',
    'model',
    'year',
    'price',
    'location',
    'type_of_sale',
    'keyword',
    'mileage',
    'more_filters',
]

const filters_id_array_xs: IFilterID[] = [
    'make',
    'model',
    'year',
    'price',
    'location',
    'keyword',
    'more_filters',
]

const xs_rest: IFilterID[] = [
    'colour',
    'body_type',
    'drive_side',
    'type_of_sale',
    'mileage',
]

const small_rest: IFilterID[] = ['colour', 'body_type', 'drive_side']

type Props = {
    isWithinPageLimits?: boolean
    manageQueryParamRemoval: (p: string, val?: any) => any
    handleChangeSearchEffects: () => any
    page: 'sale' | 'sold'
    currentTopPadding: number
    updateTopPadding: (height: number) => void
}
const ShowroomFiltersBarDesktop = (props: Props) => {
    let filtersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.filters
    })

    let isMenuCollapsed: boolean = useAppSelector((state) => {
        return state.menus.desktopMainAppMenu.isCollapsed
    })

    const size: WindowSize = useWindowSize()

    let openedFilterID: IFilterID | undefined = useAppSelector((state) => {
        return state.showroom.openedFilterID
    })

    let isMoreFiltersOpen: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isMoreFiltersOpened
    })

    let screenSize: 'default' | 'large' | 'small' | 'xs' = !size
        ? 'small'
        : size && size.width && size.width < 1710 && size.width > 1270
        ? 'small'
        : size && size.width && size.width < 1270
        ? 'xs'
        : 'default'

    let filters_id_array: IFilterID[] =
        screenSize === 'default'
            ? filters_id_array_all
            : screenSize === 'small'
            ? filters_id_array_small
            : screenSize === 'xs'
            ? filters_id_array_xs
            : filters_id_array_all

    let rest_arr: IFilterID[] =
        screenSize === 'small' ? small_rest : screenSize === 'xs' ? xs_rest : []

    const dispatch = useAppDispatch()

    const ref = useRef<HTMLDivElement>(null)

    let {
        handleChangeSearchEffects,
        page,
        updateTopPadding,
        currentTopPadding,
    } = props

    let isFilterActive =
        openedFilterID &&
        Object.values(filtersState).some(
            (val) => (val.isActive && val.id === openedFilterID) === true
        )

    const clearFunc = () => {
        if (openedFilterID) {
            dispatch(
                showroomActions.removeFilterRequest({
                    id: openedFilterID,
                })
            )
            dispatch(showroomActions.setFilterOpen(undefined))

            if (isFilterActive) {
                handleChangeSearchEffects()
            }
        }
    }

    const activeCircle = (id: IFilterID) => {
        if (
            filtersState[id].isActive &&
            id !== 'more_filters' &&
            // @ts-ignore
            filtersState[id].values
        ) {
            return (
                <ActiveCircle>
                    {
                        // @ts-ignore
                        filtersState[id].values.length
                    }
                </ActiveCircle>
            )
        } else if (filtersState[id].isActive && id !== 'more_filters') {
            return <ActiveCircleSimple />
        }
    }

    const onClickItem = (id: IFilterID) => {
        if (id === 'more_filters') {
            dispatch(showroomActions.setIsMoreFiltersOpened(!isMoreFiltersOpen))
            dispatch(showroomActions.setFilterOpen(undefined))
        } else if (openedFilterID === id) {
            dispatch(showroomActions.setFilterOpen(undefined))
        } else {
            dispatch(showroomActions.setFilterOpen(id))
        }
    }

    return (
        <MenuAdjust
            isCollapsed={isMenuCollapsed}
            style={props.isWithinPageLimits ? { paddingLeft: 0 } : {}}
        >
            <Wrap
                $IsWithinPageLimits={props.isWithinPageLimits}
                $isMenuCollapsed={isMenuCollapsed}
            >
                <Container
                    $IsWithinPageLimits={props.isWithinPageLimits}
                    ref={ref}
                    $isMenuCollapsed={isMenuCollapsed}
                >
                    <Col>
                        <FiltersRow>
                            {filters_id_array.map(
                                (id: IFilterID, index: number) => {
                                    return (
                                        <div
                                            style={{
                                                paddingRight:
                                                    index !==
                                                    filters_id_array.length - 1
                                                        ? '16px'
                                                        : '0xp',
                                                position: 'relative',
                                            }}
                                            key={`filter_bar_desktop_${id}_${index}`}
                                        >
                                            {activeCircle(id)}
                                            <FilterItemBtn
                                                role="button"
                                                onClick={() => {
                                                    onClickItem(id)
                                                }}
                                                isActive={
                                                    isMoreFiltersOpen ===
                                                        true &&
                                                    id === 'more_filters'
                                                        ? true
                                                        : openedFilterID === id
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {isMoreFiltersOpen === true &&
                                                id === 'more_filters'
                                                    ? 'Less Filters'
                                                    : filtersState[id].name}
                                            </FilterItemBtn>

                                            {openedFilterID === id ? (
                                                <FilterFormWrapper
                                                    style={{ top: '60px' }}
                                                >
                                                    <ShowroomFilterFormManagerDesktop
                                                        clearFunc={clearFunc}
                                                        handleChangeSearchEffects={
                                                            handleChangeSearchEffects
                                                        }
                                                    />
                                                </FilterFormWrapper>
                                            ) : null}
                                        </div>
                                    )
                                }
                            )}
                        </FiltersRow>

                        <Expander height={isMoreFiltersOpen ? 'auto' : 0}>
                            <Col style={{ width: '100%' }}>
                                {screenSize !== 'default' ? (
                                    <Separator />
                                ) : null}
                                {screenSize !== 'default' ? (
                                    <FiltersRow>
                                        {rest_arr.map(
                                            (id: IFilterID, index: number) => {
                                                return (
                                                    <div
                                                        style={{
                                                            paddingRight:
                                                                index !==
                                                                filters_id_array.length -
                                                                    1
                                                                    ? '10px'
                                                                    : '0xp',
                                                            position:
                                                                'relative',
                                                        }}
                                                    >
                                                        {activeCircle(id)}

                                                        <FilterItemBtn
                                                            onClick={() => {
                                                                onClickItem(id)
                                                            }}
                                                            isActive={
                                                                isMoreFiltersOpen ===
                                                                    true &&
                                                                id ===
                                                                    'more_filters'
                                                                    ? true
                                                                    : openedFilterID ===
                                                                      id
                                                                    ? true
                                                                    : false
                                                            }
                                                            role="button"
                                                        >
                                                            {
                                                                filtersState[id]
                                                                    .name
                                                            }
                                                        </FilterItemBtn>

                                                        {openedFilterID ===
                                                        id ? (
                                                            <FilterFormWrapper
                                                                style={{
                                                                    top: '60px',
                                                                }}
                                                            >
                                                                <ShowroomFilterFormManagerDesktop
                                                                    clearFunc={
                                                                        clearFunc
                                                                    }
                                                                    handleChangeSearchEffects={
                                                                        handleChangeSearchEffects
                                                                    }
                                                                />
                                                            </FilterFormWrapper>
                                                        ) : null}
                                                    </div>
                                                )
                                            }
                                        )}
                                    </FiltersRow>
                                ) : null}
                            </Col>
                        </Expander>
                    </Col>
                    <Tooltip title="apply filter(s)">
                        <SearchBtn
                            $isMenuCollapsed={isMenuCollapsed}
                            role="button"
                            onClick={() => {}}
                            $IsWithinPageLimits={props.isWithinPageLimits}
                        >
                            <SearchIcon
                                color="white"
                                isActive={false}
                                height={15}
                            />
                        </SearchBtn>
                    </Tooltip>
                </Container>
                <ShowroomDismissableFiltersBarDesktop
                    manageQueryParamRemoval={props.manageQueryParamRemoval}
                    handleChangeSearchEffects={handleChangeSearchEffects}
                    page={page}
                    updateTopPadding={updateTopPadding}
                    currentTopPadding={currentTopPadding}
                />
            </Wrap>
        </MenuAdjust>
    )
}

export default ShowroomFiltersBarDesktop
