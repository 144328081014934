import { call, fork, put, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import * as actions from '../actions'
import { api } from '../../../services'
import posthog from 'posthog-js'
import {
    ConvertSingleDirectShare_owned,
    IConvertSingleDirectShareOwnedArg,
} from '../../../conversions/shares/convertDirectShares'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { ConvertSharePermissionIDsToAPI } from '../../../conversions/shares/ConvertSharePermissionIds'
import {
    IApi_CreateDirectShare_args,
    IDirectShareOwnedItem_Api_payload,
} from '../../../services/types'
import { ICreateDirectShareRequest, IReduxError } from '../types'
import { carActions } from '../reducer'
import { push } from 'redux-first-history'

export function* CreateCarDirectShareSaga(p: ICreateDirectShareRequest) {
    try {
        let api_payload: IApi_CreateDirectShare_args = {
            resource_id: p.resource_id,
            resource_type: p.resource_type,
            share_type: p.share_type,
            title: p.title,
            recipients: p.recipients,
            policy_statements: p.permissionIDS
                ? ConvertSharePermissionIDsToAPI(p.permissionIDS)
                : undefined,
        }

        const res: IDirectShareOwnedItem_Api_payload = yield call(
            api.sharing.createDirectShareAPI,
            api_payload
        )

        let p_conv: IConvertSingleDirectShareOwnedArg = {
            api_payload: res,
        }

        const direct_share_state = ConvertSingleDirectShare_owned(p_conv)

        let final: actions.IDirectShareSuccessPayloadRedux = {
            id: p.resource_id,
            direct_share: direct_share_state,
        }

        yield put(carActions.createCarDirectShareSuccess(final))
        yield put(
            push(
                `/sharing/with-others/car/${p.resource_id}/edit-share?shareid=${direct_share_state.id}`
            )
        )

        // Capture event
        posthog.capture('CREATE CAR DIRECT SHARE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateCarDirectShareSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(carActions.createCarDirectShareError(typedError))
            return
        }
    }
}

function* watcherCreateCarDirectShare() {
    while (true) {
        const { payload } = yield take(carActions.createCarDirectShareRequest)
        yield call(CreateCarDirectShareSaga, payload)
    }
}
const create_car_direct_share_sagas: any[] = [fork(watcherCreateCarDirectShare)]

export default create_car_direct_share_sagas
