import { call, fork, put, take, select } from 'redux-saga/effects'
import { api } from '../../../services'
import { ConvertFromVesApiToStateFormat } from '../../../conversions/entities/externalCarDataConversions'
import posthog from 'posthog-js'

import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../../../localdata/dropdownData/reducer'
import { IRootState } from '../../../store'
import { IDropdownItem, IListFromAPiType } from 'entityModels'
import {
    IExternalCarDataRequestPayload,
    IExternalDataByRegistrationNumberSingle,
    IExternalDataByRegistrationNumberObject,
} from '../types'
import { carActions } from '../reducer'
import { push } from 'redux-first-history'

let getDropdownMakesDataState = (state: IRootState): IDropdownItem[] | null =>
    state.localdata.dropdownData.carMakes

export function* getExternalCarDataByRegistrationNumber(
    payload: IExternalCarDataRequestPayload
) {
    try {
        const apidata: IExternalDataByRegistrationNumberSingle = yield call(
            api.externalCarDataByRegistrationNumber
                .get_external_car_data_by_registration_number,
            payload.registration_number
        )

        let converted_object: IListFromAPiType =
            ConvertFromVesApiToStateFormat(apidata)

        let resObject: IExternalDataByRegistrationNumberObject = {}

        resObject[payload.car_id] = converted_object

        yield put(
            carActions.getExternalCarDataByRegistrationNumberSuccess(resObject)
        )

        if (payload.hydrateOnly) {
            // don't navigate
        } else if (payload.isOnboarding === true) {
            yield put(
                push(
                    `/onboarding-step-2-2?carid=${payload.car_id}&from_ves_api=true&reg_no=${payload.registration_number}`
                )
            )

            let apiMakeName = converted_object['make'].answer ?? null

            let makeName = null
            let makeId = null

            let makesList: IDropdownItem[] = yield select(
                getDropdownMakesDataState
            )

            let item: IDropdownItem

            for (const itemIndex in makesList) {
                item = makesList[itemIndex]

                if (
                    item?.name?.toLowerCase() === `${apiMakeName}`.toLowerCase()
                ) {
                    makeName = item.name
                    makeId = item.uid
                    break
                }
            }

            if (makeName && makeId) {
                yield put(dropDownActions.setTempMakeDisplay(`${makeName}`))
                yield put(dropDownActions.setTempMakeIDSuccess(makeId))
                yield put(
                    dropDownActions.setModelDropdownDataRequest({
                        makeName: `${makeName}`,
                    })
                )
            }
        } else {
            yield put(push(`/car/${payload.car_id}/confirm?from_ves_api=true`))
        }

        // Capture event
        posthog.capture('DVLA API LOOKUP BY CAR REGISTRATION')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: getExternalCarDataByRegistrationNumber,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            yield put(
                carActions.getExternalCarDataByRegistrationNumberSuccess({})
            )

            if (payload.hydrateOnly) {
                // don't navigate
            } else if (payload.isOnboarding === true) {
                yield put(
                    push(
                        `/onboarding-step-2-2?carid=${payload.car_id}&from_ves_api=false&reg_no=${payload.registration_number}`
                    )
                )
            } else {
                yield put(
                    push(`/car/${payload.car_id}/confirm?from_ves_api=false`)
                )
            }
        }
    }
}

function* watcherGetExternalCarDataByRegistrationNumber() {
    while (true) {
        const { payload } = yield take(
            carActions.getExternalCarDataByRegistrationNumberRequest
        )

        yield call(getExternalCarDataByRegistrationNumber, payload)
    }
}

const get_external_car_data_by_registration_number: any[] = [
    fork(watcherGetExternalCarDataByRegistrationNumber),
]

export default get_external_car_data_by_registration_number
