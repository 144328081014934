import React from 'react'
import CarHighlightsEditFormMobile from '../editForms/carHighlightsEditForm/carHighlightsEditFormMobile'
import { IUpdateHF } from 'entityModels'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { highlightedFactsActions } from '../../../redux/entities/highlighted_facts/reducer'

interface Props {
    highlighted_facts_ids: string[]
    close: any
    car_id: string
}

const DragNSortEditableManagerMobile: React.FC<Props> = ({
    highlighted_facts_ids,
    close,
    car_id,
}) => {
    const dispatch = useAppDispatch()
    const highlighted_facts_data = useAppSelector(
        (state) => state.entities.highlightedFactsData.highlighted_facts
    )
    const isLoading = useAppSelector(
        (state) => state.entities.highlightedFactsData.loading
    )

    const handleUpdateHighlightedFactsRequest = (data: IUpdateHF) => {
        dispatch(highlightedFactsActions.updatetHighlightedFactsRequest(data))
    }

    return (
        <React.Fragment>
            {isLoading !== true &&
                highlighted_facts_ids.length > 0 &&
                highlighted_facts_data !== null && (
                    <CarHighlightsEditFormMobile
                        close={close}
                        data_object={highlighted_facts_data}
                        ids_list={highlighted_facts_ids}
                        submitFunc={handleUpdateHighlightedFactsRequest}
                        carid={car_id}
                    />
                )}
        </React.Fragment>
    )
}

export default DragNSortEditableManagerMobile
