import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GalleryTabNameEnum } from '../../../organisms/galleryCarousel'
import { device } from '../../../templates/displays/devices'
import SkeletonAbsolute from '../../skeleton/skeletonAbsolute'
import zoom from './zoom.svg'
import { IGalleryImage } from '../../../../redux/entities/galleries/types'

type Props = {
    image?: IGalleryImage
    tabName: GalleryTabNameEnum
    onClickZoom: () => any
}

const ImageContainerWrapper = styled.div`
    height: 80vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    position: relative;
`

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100%;
`

const ImageSubContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    max-height: 80vh;
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: contain;
    z-index: 6;
    height: 100%;
    max-height: 80vh;
`

const ZoomInIcon = styled.img`
    position: absolute;
    height: 30px;
    top: 10px;
    right: 10px;
    z-index: 6;

    @media ${device.ipad} {
        height: 20px;
        top: 10px;
        right: 10px;
    }
`

const GalleryImageMobile = (props: Props) => {
    const { image, tabName, onClickZoom } = props

    const [isImageLoading, setIsImageLoading] = useState<boolean>(true)

    const [img_src, setImag_src] = useState<string | null>(null)

    useEffect(() => {
        const initialSrc = image?.url ? image?.url : undefined

        if (initialSrc) {
            setImag_src(initialSrc)
        }
    }, [image])

    return (
        <ImageContainerWrapper>
            <ImageContainer key={image?.id}>
                <ImageSubContainer>
                    <SkeletonAbsolute
                        isthingloading={!img_src || isImageLoading}
                    />

                    {image ? (
                        <Image
                            style={{
                                opacity: img_src ? 1 : 0,
                            }}
                            src={img_src ?? ''}
                            id={tabName}
                            onClick={() => {
                                onClickZoom()
                            }}
                            onLoad={() => setIsImageLoading(false)}
                        />
                    ) : null}

                    <ZoomInIcon src={zoom} />
                </ImageSubContainer>
            </ImageContainer>
        </ImageContainerWrapper>
    )
}

export default GalleryImageMobile
