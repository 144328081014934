import { useLocation, useNavigate } from 'react-router-dom'
// import JourneyTopBar from '../../templates/bars/journey/topBar'
import { JourneyPageBackground } from '../../templates/windowPageTemplate/journey/pageBackground'
import { IJourneyHistoryFileStepID } from 'journeyInterfaces'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { useEffect, useState } from 'react'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import HistoryFileJourneyStepsManager from '../../organisms/journey/historyFile/stepManager'

import { message } from 'antd'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { ITimelineItem } from '../../../redux/timeline/types'
import { timelineActions } from '../../../redux/timeline/reducer'

const errorInfo = (messageText: string) => {
    message.error(messageText, 5)
}

export default function HistoryFileJourney() {
    const navigate = useNavigate()
    const location = useLocation()
    let params = new URLSearchParams(location.search)
    let step: string | null = params.get('step') ?? 'welcome'
    let carid: string | null = params.get('carid')

    useEffect(() => {
        window?.scrollTo(0, 0)
    }, [step])

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const garage_data = useAppSelector((state) => {
        return state.entities.garagesData.garages
    })
    const cars_data = useAppSelector((state) => {
        return state.entities.carsData.cars
    })
    let garage_cars =
        userLoggedIn &&
        userLoggedIn.owns_garage &&
        userLoggedIn.owns_garage.uid &&
        garage_data &&
        garage_data[userLoggedIn.owns_garage.uid] &&
        garage_data[userLoggedIn.owns_garage.uid].cars
            ? garage_data[userLoggedIn.owns_garage.uid].cars
            : []

    const [originPath, setOriginPath] = useState<string>('')

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl('/journey/history-file')
        )
        let locationState: any = location.state

        if (locationState?.prevPath) {
            if (locationState?.prevPath.includes(`step=achieved`)) {
                carid
                    ? setOriginPath(`/car/${carid}/history-file`)
                    : setOriginPath(`/garage`)
            } else {
                setOriginPath(locationState?.prevPath)
            }
        } else {
            setOriginPath(`/garage`)
        }
    }, [])
    useEffect(() => {
        if (userLoggedIn) {
            if (!garage_cars || garage_cars.length === 0) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }
        } else {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    const onAdd = (
        entry1: ITimelineItem | undefined,
        entry2: ITimelineItem | undefined,
        entry3: ITimelineItem | undefined
    ) => {
        if (carid) {
            let itemss: ITimelineItem[] = []

            if (entry1) {
                if (entry1.categoryID === 'insurance') {
                    if (
                        (entry1 &&
                            !entry1?.insurance_entry_policy_start_date) ||
                        (entry1 && !entry1?.insurance_entry_policy_end_date)
                    ) {
                        errorInfo(
                            `Please enter the insurance entry's dates. If not sure, you can pick some approximative ones.`
                        )
                        return
                    }
                } else {
                    if (!entry1?.occurred_at) {
                        errorInfo(
                            `Please enter the first entry's date. If not sure, you can pick an approximative one.`
                        )
                        return
                    }
                }

                itemss.push(entry1)
            }
            if (entry2) {
                if (entry2.categoryID === 'insurance') {
                    if (
                        (entry2 &&
                            !entry2?.insurance_entry_policy_start_date) ||
                        (entry2 && !entry2?.insurance_entry_policy_end_date)
                    ) {
                        errorInfo(
                            `Please enter the insurance entry's dates. If not sure, you can pick some approximative ones.`
                        )
                        return
                    }
                } else {
                    if (!entry2?.occurred_at) {
                        errorInfo(
                            `Please enter the first entry's date. If not sure, you can pick an approximative one.`
                        )
                        return
                    }
                }

                itemss.push(entry2)
            }

            if (entry3) {
                if (entry3.categoryID === 'insurance') {
                    if (
                        (entry3 &&
                            !entry3?.insurance_entry_policy_start_date) ||
                        (entry3 && !entry3?.insurance_entry_policy_end_date)
                    ) {
                        errorInfo(
                            `Please enter the insurance entry's dates. If not sure, you can pick some approximative ones.`
                        )
                        return
                    }
                } else {
                    if (!entry3?.occurred_at) {
                        errorInfo(
                            `Please enter the first entry's date. If not sure, you can pick an approximative one.`
                        )
                        return
                    }
                }

                itemss.push(entry3)
            }

            if (itemss?.length !== 0) {
                dispatch(
                    timelineActions.addTimelineItemWithCostsToCarRequest({
                        items: itemss,
                        carid: carid,
                        fetch_last_insurance:
                            entry1?.categoryID === 'insurance' ||
                            entry2?.categoryID === 'insurance' ||
                            entry3?.categoryID === 'insurance'
                                ? true
                                : false,
                    })
                )
            }
        }

        setTimeout(() => {
            navigate(`/journey/history-file?step=insurance&carid=${carid}`, {
                state: {
                    prevPath: `${location.pathname}${location.search ?? ''}`,
                },
            })
        }, 100)
    }

    const onAddInsurance = (entry1?: ITimelineItem | undefined) => {
        if (carid) {
            if (entry1) {
                if (!entry1?.insurance_entry_policy_start_date) {
                    errorInfo(
                        `Please enter the entry's start date. If not sure, you can pick an approximative one.`
                    )
                    return
                }
                if (entry1.id === 'id' || entry1.id === '') {
                    dispatch(
                        timelineActions.addTimelineItemWithCostsToCarRequest({
                            items: [entry1],
                            carid: carid,
                        })
                    )
                } else {
                    dispatch(
                        timelineActions.updateTimelineItemWithCostRequest({
                            item: entry1,
                            carid: carid,
                        })
                    )
                }
            }

            setTimeout(() => {
                navigate(`/journey/history-file?step=achieved&carid=${carid}`, {
                    state: {
                        prevPath: `${location.pathname}${
                            location.search ?? ''
                        }`,
                    },
                })
            }, 100)
        }
    }

    return (
        <JourneyPageBackground
            sectionID="history_file"
            hasBlurredBg={step === 'welcome' || isMobile ? true : false}
            hasLinearBgColour={
                true
                // isMobile ? true : step && step === 'welcome' ? false : true
            }
            hasPattern={isMobile ? (step === 'welcome' ? true : false) : true}
        >
            <HistoryFileJourneyStepsManager
                onAdd={onAdd}
                onAddInsurance={(entry: ITimelineItem | undefined) => {
                    onAddInsurance(entry)
                }}
                stepID={step as IJourneyHistoryFileStepID}
                startJourney={() => {
                    if (carid) {
                        navigate(
                            {
                                pathname: `/journey/history-file`,
                                search: `?step=learn&carid=${carid}`,
                            },
                            {
                                state: {
                                    prevPath: `${location.pathname}${
                                        location.search ?? ''
                                    }`,
                                },
                            }
                        )
                    } else if (garage_cars && garage_cars?.length > 1) {
                        navigate(
                            {
                                pathname: `/journey/history-file`,
                                search: '?step=select_car',
                            },
                            {
                                state: {
                                    prevPath: `${location.pathname}${
                                        location.search ?? ''
                                    }`,
                                },
                            }
                        )
                    } else if (garage_cars && garage_cars?.length === 1) {
                        navigate(
                            {
                                pathname: `/journey/history-file`,
                                search: `?step=learn&carid=${garage_cars[0]}`,
                            },
                            {
                                state: {
                                    prevPath: `${location.pathname}${
                                        location.search ?? ''
                                    }`,
                                },
                            }
                        )
                    } else {
                        navigate(
                            {
                                pathname: `/journey/history-file`,
                                search: '?step=select_car',
                            },
                            {
                                state: {
                                    prevPath: `${location.pathname}${
                                        location.search ?? ''
                                    }`,
                                },
                            }
                        )
                    }
                }}
                exitJourney={() => {
                    return navigate(originPath)
                }}
                carid={carid}
                carsData={cars_data}
                garageCars={garage_cars}
                user={userLoggedIn}
            />
        </JourneyPageBackground>
    )
}
