import { call, fork, put, take } from 'redux-saga/effects'
import { sortShareRecipientsByAlphabetical } from '../../../../helpers/sort/sortByAlphabetical'
import { sortShareRecipientsByAddedAt } from '../../../../helpers/sort/sortByDate'
import {
    ISortShareRecipientsDisplay_change_request,
    ISortShareRecipientsDisplay_change_success,
} from '../../cars/types'
import { garagesActions } from '../reducer'

function* changeSortGarageShareRecipientssDisplay(
    payload: ISortShareRecipientsDisplay_change_request
): any {
    let currentShareRecipientsItemsDisplay: string[] | undefined =
        payload.share &&
        payload.share.active_display &&
        payload.share.active_display.item_ids_display
            ? [...payload.share.active_display.item_ids_display]
            : [...payload.share.all_share_recipients_ids_list]

    let items_new: string[] = currentShareRecipientsItemsDisplay
        ? [...currentShareRecipientsItemsDisplay]
        : []

    if (
        payload.sortID === 'alphabetical' &&
        currentShareRecipientsItemsDisplay &&
        payload.share &&
        payload.share.share_recipients_data
    ) {
        items_new = sortShareRecipientsByAlphabetical(
            currentShareRecipientsItemsDisplay,
            payload.share?.share_recipients_data
        )
    }

    if (
        payload.sortID === 'added_at' &&
        currentShareRecipientsItemsDisplay &&
        payload.share &&
        payload.share.share_recipients_data
    ) {
        items_new = sortShareRecipientsByAddedAt(
            currentShareRecipientsItemsDisplay,
            payload.share.share_recipients_data
        )
    }

    let p: ISortShareRecipientsDisplay_change_success = {
        entityId: payload.entityid,
        shareid: payload.share.id,
        sortID: payload.sortID,
        item_ids_display: items_new,
    }
    yield put(garagesActions.sortGarageShareRecipientsDisplay_change_success(p))
}

function* watcherChangeSortGarageShareRecipientsDisplay() {
    while (true) {
        const { payload } = yield take(
            garagesActions.sortGarageShareRecipientsDisplay_change_request
        )

        yield call(changeSortGarageShareRecipientssDisplay, payload)
    }
}

const handle_garage_shares_recipients_display_change: any[] = [
    fork(watcherChangeSortGarageShareRecipientsDisplay),
]

export default handle_garage_shares_recipients_display_change
