// ApexTeamCreditsDesktop

import React from 'react'

import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'

const Wrapper = styled.div`
    font-family: Lato-light;
    color: var(--text-strong, #1a1a1a);
    font-size: 15px;
    line-height: 32px;
    font-style: italic;

    @media ${device.mobile} {
    }
`

type Props = {
    text: string
}

const ApexTeamCreditsMobile: React.FC<Props> = ({ text }) => {
    return <Wrapper>{text}</Wrapper>
}

export default ApexTeamCreditsMobile
