import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import * as FieldChoices from '../../../molecules/editOrCreateModeSingleFields'
import CreateFormTitle from '../../../atoms/typography/createFormTitle'
import CreateFormSubtitle from '../../../atoms/typography/createFormSubtitle'
import AddNewNaked from '../../../atoms/Button/addNewNaked'
import {
    timeline_category_data,
    currency_data,
    currency_IDS,
} from '../../../../redux/timeline/data'

import LabelsFilterByMultipleWSearchWFuseMobile from '../../filterByMultiple/labels/labelsFilterByMultipleWSearchWFuseMobile'
import { IDropdownItem } from 'entityModels'

import { device } from '../../../templates/displays/devices'
import CreateFormSubSubtitleDetail from '../../../atoms/typography/createFormSubtitleDetail'
import styled from 'styled-components'
import { renderCategoryIcon } from '../../../atoms/icons/components/historyFile/categories'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { useAppSelector } from '../../../../redux/store/hooks'
import {
    ITimelineDataToBeSubmittedObj,
    ITimelineCreationOrEditFormFieldItem,
    ICreationTimelineSubmitConfigs,
    ITimelineEntityType,
    ITimelineActionType,
    ITimelineItem,
    ICostItem,
    ILabel,
    ITimelineStepsIndexedListByCreationType,
} from '../../../../redux/timeline/types'
import { IEditOrCreateModeSingleFieldsProps } from '../../../molecules/editOrCreateModeSingleFields/types'

const { v4: uuidv4 } = require('uuid')

type IStyled = {
    isHeaderContentRemoved?: boolean
}

const Wrapper = styled.section<IStyled>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);
    padding-top: ${(props) => (props.isHeaderContentRemoved ? '0px' : '20px')};
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    /* height: 100vh; */
    min-height: -webkit-fill-available;
    overflow-x: hidden;
`
const InnerFormPadding = styled.div`
    width: 100%;
    box-sizing: border-box;
    /* padding-left: 20px;
    padding-right: 20px; */
    padding-bottom: 50px;
    padding-top: 10px;
`

const LargePadding = styled.div`
    padding-top: 40px;
`

const TitlesContainer = styled.div`
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ButtonsSection = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 30px;
    @media ${device.ipad} {
        justify-content: flex-end;
        padding-right: 30px;
    }
`
type IStyle = {
    isactive?: boolean
    $bgColor?: string
}

const IconContainer = styled.div<IStyle>`
    width: 42px;
    height: 42px;
    background-color: ${(props) =>
        props.$bgColor ? props.$bgColor : 'var(--primary, #5ec3ca)'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;
`

interface Props {
    data_to_be_submitted: ITimelineDataToBeSubmittedObj | undefined
    current_step_index: number
    step_list: string[]
    stepFieldsData: { [key: string]: ITimelineCreationOrEditFormFieldItem }
    stepFieldIdsList: string[]
    stepTitle: string
    stepSubtitle: string
    stepSubtitleDetail?: string
    submit: ICreationTimelineSubmitConfigs
    onChange: (
        fieldID: string,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => void

    onCategorySelect: (
        fieldID: string,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => void
    closeForm: () => void
    manageSteps: () => void
    showSkipButton: boolean
    labels_searchable_list?: IDropdownItem[] | null | undefined
    submitDataRequest?: () => void
    isHeaderContentRemoved?: boolean
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    activeEntry?: ITimelineItem | null | undefined
}

const TimelineEditGenerateCreateFormMobile: React.FC<Props> = ({
    data_to_be_submitted,
    current_step_index,
    step_list,
    stepFieldsData,
    stepFieldIdsList,
    stepTitle,
    stepSubtitle,
    stepSubtitleDetail,
    submit,
    onChange,
    manageSteps,
    showSkipButton,
    labels_searchable_list,
    submitDataRequest,
    isHeaderContentRemoved,
    entityID,
    carID,
    entityType,
    onCategorySelect,
}) => {
    const navigate = useNavigate()

    const timeline_creation_forms_current_state = useAppSelector(
        (state) => state.editForms.timelineCreationForm
    )
    const steps = useAppSelector(
        (state) =>
            state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list
    )

    const entryTypes_data = useAppSelector((state) => state.timeline.categories)
    const userCurrency = useAppSelector(
        (state) =>
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id
    )

    const [errorObject, setErrorObject] = React.useState<{
        [key: string]: boolean
    }>({})

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const determineErrors = (fieldId: string, error: boolean) => {
        setErrorObject((prevState) => ({
            ...prevState,
            [fieldId]: error,
        }))
    }

    const active_creation =
        timeline_creation_forms_current_state.active_creation as keyof ITimelineStepsIndexedListByCreationType
    const active_creation_steps =
        active_creation !== null ? steps[active_creation] : null

    const generateFields = (fieldId: string, key: any) => {
        let data: IEditOrCreateModeSingleFieldsProps = {
            value_start: determineValueStart(fieldId),
            title: stepFieldsData[fieldId] && stepFieldsData[fieldId].text,
            onChangeFunc:
                fieldId === 'categoryID' ? onCategorySelect : onChange,
            id: fieldId,
            unique_identifier: 'default',
            isCreation: true,
            submitCreationConfigs: {
                entityType: submit.entity,
                action: submit.action,
            },
            placeholder:
                active_creation === 'insurance'
                    ? undefined
                    : generatePlaceholder(fieldId),
            optionTypeID:
                fieldId === 'categoryID'
                    ? 'entryType'
                    : fieldId === 'amount'
                    ? 'currency'
                    : undefined,
        }

        let hasError = (error: boolean) => {
            determineErrors(fieldId, error)
        }

        switch (fieldId) {
            case 'categoryID': {
                data.isMandatory = true
                data.charLimit = [2, 70]

                data.onChangeFunc = (
                    fieldID: any,
                    value: any,
                    entityType: ITimelineEntityType,
                    action: ITimelineActionType
                ) => {
                    if (value === 'insurance') {
                        navigate(`/car/${carID}/history-file/create/insurance`)
                    } else {
                        // onChange(fieldID, value, entityType, action)
                        onCategorySelect(fieldID, value, entityType, action)
                    }
                }

                if (data.optionTypeID) {
                    switch (data.optionTypeID) {
                        case 'entryType':
                            return (
                                <FieldChoices.OptionsWithIconAndDescription
                                    data={data}
                                    key={key}
                                    onOptionClick={() => {}}
                                    dataCyId={`${
                                        active_creation_steps &&
                                        active_creation_steps[
                                            current_step_index
                                        ]
                                    }`}
                                    optionsData={entryTypes_data.data}
                                    optionsIDArr={entryTypes_data.ids}
                                    selectedOption={
                                        data_to_be_submitted &&
                                        data_to_be_submitted.entry &&
                                        data_to_be_submitted.entry.categoryID
                                    }
                                />
                            )

                        default:
                            return <div style={{ display: 'none' }} key={key} />
                    }
                } else return <div style={{ display: 'none' }} key={key} />
            }

            case 'title': {
                data.isMandatory = true
                data.charLimit = [2, 30]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        placeholder={data.placeholder ? data.placeholder : ''}
                        hasError={hasError}
                    />
                )
            }

            case 'name': {
                data.isMandatory = true
                data.charLimit = [2, 30]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        placeholder={''}
                        hasError={hasError}
                    />
                )
            }

            case 'company': {
                data.charLimit = [0, 70]

                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'amount': {
                data.isMandatory = true
                data.optionName = userCurrency ? userCurrency : 'GBP'

                return (
                    <FieldChoices.NumberEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        optionsIDArr={currency_IDS}
                        optionsData={currency_data}
                        optionsFieldID="currency"
                        hasError={hasError}
                    />
                )
            }

            case 'date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                    />
                )

            case 'insurance_entry_policy_start_date':
                data.isMandatory = true

                data.onChangeFunc = (
                    fieldID: any,
                    value: any,
                    entityType: ITimelineEntityType,
                    action: ITimelineActionType
                ) => {
                    onChange(fieldID, value, entityType, action)

                    const aYearFromNow = new Date(value)
                    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)

                    onChange(
                        'insurance_entry_policy_end_date',
                        aYearFromNow,
                        entityType,
                        action
                    )
                }
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        removePadding={true}
                    />
                )

            case 'insurance_entry_policy_end_date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldMobile
                        data={{ ...data }}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        removePadding={true}
                    />
                )

            case 'date_with_options':
                return (
                    <FieldChoices.DatePickerWithButtons
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                    />
                )

            case 'insurance_entry_broker': {
                data.isMandatory = false
                data.charLimit = [2, 100]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        placeholder={''}
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_underwriter': {
                data.isMandatory = true
                data.charLimit = [2, 100]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        placeholder={''}
                        hasError={hasError}
                    />
                )
            }

            case 'short_description':
                data.charLimit = [0, 70]
                return (
                    <FieldChoices.TextareaEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        placeholder={data.placeholder ? data.placeholder : ''}
                        hasError={hasError}
                    />
                )

            case 'attachments': {
                return (
                    <FieldChoices.WrapperAttachmentFormMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        entityID={entityID}
                        carID={carID}
                        entityType={entityType}
                    />
                )
            }

            case 'attachment': {
                return (
                    <FieldChoices.WrapperAttachmentFormMobile
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps
                                ? active_creation_steps[current_step_index]
                                : ''
                        }
                        entityID={entityID}
                        carID={carID}
                        entityType={entityType}
                    />
                )
            }

            case 'image':
                return <div />

            case 'labels':
                return (
                    <LabelsFilterByMultipleWSearchWFuseMobile
                        placeholder="Search for title or tag"
                        labels_searchable_list={
                            labels_searchable_list &&
                            labels_searchable_list.length > 0
                                ? labels_searchable_list.concat(
                                      generatePrePopulatedLabels()
                                  )
                                : generatePrePopulatedLabels()
                        }
                        key={key}
                        editData={data}
                        addToEntry={onChange}
                    />
                )

            default:
                return <div />
        }
    }

    const determineValueStart = (fieldId: string) => {
        if (data_to_be_submitted && submit.entity) {
            if (submit.entity && data_to_be_submitted[submit.entity]) {
                let obj: ITimelineItem | ICostItem = {
                    id: '',
                    ...data_to_be_submitted[submit.entity],
                }
                if (obj[fieldId]) {
                    if (fieldId === 'labels')
                        return obj[fieldId].map((label: ILabel) => label.name)
                    return obj[fieldId]
                }
            }

            return stepFieldsData[fieldId].value
        }
        return stepFieldsData[fieldId].value
    }

    const generatePlaceholder = (fieldId: string): string => {
        if (data_to_be_submitted && submit.entity) {
            if (data_to_be_submitted[submit.entity]) {
                if (
                    data_to_be_submitted.entry &&
                    data_to_be_submitted.entry.categoryID &&
                    timeline_category_data[
                        data_to_be_submitted.entry.categoryID
                    ] &&
                    timeline_category_data[
                        data_to_be_submitted.entry.categoryID
                    ].placeholders
                ) {
                    let placeholders =
                        timeline_category_data[
                            data_to_be_submitted.entry.categoryID
                        ].placeholders

                    return placeholders ? placeholders[fieldId] : ''
                }
            }
            return ''
        }
        return ''
    }

    const generatePrePopulatedLabels = (): IDropdownItem[] => {
        let labels_searchable_list_names = labels_searchable_list?.map(
            (label) => label.name
        )

        if (
            data_to_be_submitted &&
            data_to_be_submitted.entry &&
            data_to_be_submitted.entry.categoryID
        ) {
            let pre_populated_labels: IDropdownItem[] = timeline_category_data[
                data_to_be_submitted.entry.categoryID
            ].pre_populated_labels
                .filter(
                    (label) => !labels_searchable_list_names?.includes(label)
                )
                .map((label: string) => ({
                    uid: label,
                    name: label,
                }))
            return pre_populated_labels
        }
        return []
    }

    const checkIfSavedIsDisabled = () => {
        for (const id of stepFieldIdsList) {
            let str = id as keyof typeof errorObject

            let form_data =
                data_to_be_submitted &&
                data_to_be_submitted[entityType] &&
                data_to_be_submitted[entityType]![id]

            if (errorObject[str] === true) {
                return true
            }

            if (
                form_data &&
                form_data.length === 0 &&
                errorObject[str] === undefined &&
                stepFieldsData[id].optional === false
            ) {
                return true
            }

            if (!form_data && stepFieldsData[id].optional === false) {
                return true
            }
        }

        return false
    }

    const checkIfNoValueEntered = () => {
        let noValueEntered: boolean = true
        stepFieldIdsList.forEach((id: string) => {
            if (determineValueStart(id)) {
                noValueEntered = false
            }
        })

        return noValueEntered
    }

    const stepsWithSkipButton = () => {
        let current_creation_step_id = step_list[current_step_index]
        if (
            (current_creation_step_id === 'attachment' ||
                (current_creation_step_id === 'entry_labels' &&
                    checkIfNoValueEntered()) ||
                current_creation_step_id === 'cost_step_2' ||
                (submit.entity === 'cost' &&
                    current_creation_step_id === 'cost_step_1' &&
                    checkIfNoValueEntered())) &&
            current_step_index < step_list.length - 1
        )
            return true
    }

    let is_insurance_specific = active_creation === 'insurance' ? true : false

    return (
        <Wrapper
            data-attr={`wizard_step_${current_step_index}`}
            isHeaderContentRemoved={isHeaderContentRemoved}
        >
            <InnerFormPadding>
                {isHeaderContentRemoved !== true && (
                    <>
                        <TitlesContainer>
                            {is_insurance_specific && (
                                <IconContainer
                                    $bgColor={
                                        entryTypes_data.data.insurance.color
                                    }
                                >
                                    {renderCategoryIcon('insurance')}
                                </IconContainer>
                            )}

                            <CreateFormTitle>{stepTitle}</CreateFormTitle>

                            <CreateFormSubtitle>
                                {stepSubtitle}
                            </CreateFormSubtitle>
                            {stepSubtitleDetail && (
                                <CreateFormSubSubtitleDetail>
                                    {stepSubtitleDetail}
                                </CreateFormSubSubtitleDetail>
                            )}
                        </TitlesContainer>
                        <LargePadding />
                    </>
                )}

                <div style={{ width: '100%' }}>
                    {stepFieldIdsList &&
                        stepFieldIdsList.map(
                            (fieldID: string, index: number) => {
                                return generateFields(fieldID, index)
                            }
                        )}
                </div>
                <LargePadding />

                {stepsWithSkipButton() ? (
                    <ButtonsSection>
                        {showSkipButton ? (
                            <AddNewNaked
                                text={'skip'}
                                isUppercase={true}
                                onClick={manageSteps}
                                width={'100px'}
                                dataCyId={'skip_step_mobile'}
                            />
                        ) : (
                            <StandardCtaBtn
                                onClick={manageSteps}
                                dataCyId={'confirm_step_mobile'}
                                isDisabled={checkIfSavedIsDisabled()}
                                customWidth="100%"
                            >
                                {submit.text}
                            </StandardCtaBtn>
                        )}
                    </ButtonsSection>
                ) : step_list[current_step_index] ===
                  'select_category' ? null : (
                    <ButtonsSection>
                        <StandardCtaBtn
                            onClick={
                                current_step_index < step_list.length - 1
                                    ? manageSteps
                                    : () => {
                                          submitDataRequest &&
                                              submitDataRequest()
                                          manageSteps()
                                      }
                            }
                            isDisabled={checkIfSavedIsDisabled()}
                            dataCyId={
                                current_step_index < step_list.length - 1
                                    ? 'confirm_step_mobile'
                                    : submit.entity === 'cost'
                                    ? 'create_history_file_cost_mobile'
                                    : 'create_history_file_entry_mobile'
                            }
                            customWidth="100%"
                        >
                            {current_step_index < step_list.length - 1
                                ? submit.text
                                : submit.entity === 'cost'
                                ? 'Create cost'
                                : 'Create entry'}
                        </StandardCtaBtn>
                    </ButtonsSection>
                )}
            </InnerFormPadding>
        </Wrapper>
    )
}

export default TimelineEditGenerateCreateFormMobile
