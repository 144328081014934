import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import ButtonsStandardBottomRowMobile from '../../buttons/buttonsStandardBottomRowMobile'
import TaskNotesEditorMobile from '../../../editorSections/taskNotes/taskNotesEditorMobile'

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    padding-top: 8vh;
    overflow-y: scroll;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);
    position: relative;
    animation: fadeIn 0.2s both;
    animation-delay: 0.15s;
    opacity: 1;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media ${device.ipad} {
        max-width: 600px;
    }
`

const FieldTitle = styled.div`
    font-family: Lato;
    font-size: 18px;
    width: 80vw;
`

const SmallPadding = styled.div`
    padding-top: 20px;
`

const TextAreaWidth = styled.div`
    width: 100%;
    box-sizing: border-box;
    min-height: 50vh;
    overflow-y: scroll;
`

const TextareaWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 24px 30px;
    background-color: var(--off-bg-color, #fafafa);

    @media ${device.ipad} {
        max-width: 600px;
    }
`

const DescriptionHeader = styled.div`
    padding-top: 7px;
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
    text-align: left;
    font-family: Lato-Light;
    width: 80vw;
`

const ButtonsWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding-bottom: 64px;
    @media ${device.ipad} {
        max-width: 600px;
    }
`

const HorizLine = styled.div`
    padding-top: 32px;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid var(--border-muted, #e5e5e5);
`

interface Values {
    notes: string
}

interface OtherProps {
    toggle: any
    submitFunc: any
    taskid: string
    item: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        setFieldValue,
        errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let checkIfDisabled = () => {
        if (!values.notes) {
            return true
        } else if (errors.notes !== undefined) {
            return true
        } else return false
    }

    return (
        <EditFormContainerMobile>
            <InnerFormPadding>
                <FieldTitle>Task Notes</FieldTitle>
                <DescriptionHeader
                    style={errors.notes === 'over20k' ? { color: 'red' } : {}}
                >
                    [Up to 20,000 characters]
                </DescriptionHeader>
                <SmallPadding />
            </InnerFormPadding>
            <TextareaWrapper>
                <TextAreaWidth id="scrollable-content">
                    {/* <TouchScrollable> */}
                    <TaskNotesEditorMobile
                        placeholder={'+ add any notes relating to the task'}
                        valuePassed={values.notes}
                        handleChange={(text: any) =>
                            setFieldValue('notes', text)
                        }
                        idPassed="notes"
                        readOnly={false}
                        height="100%"
                    />
                    {/* </TouchScrollable> */}
                </TextAreaWidth>
            </TextareaWrapper>

            <ButtonsWrapper>
                <HorizLine />
                <InnerFormPadding>
                    <ButtonsStandardBottomRowMobile
                        cancel={toggle}
                        submit={props.handleSubmit}
                        confirmationtext={'save'}
                        disabled={checkIfDisabled()}
                    />
                </InnerFormPadding>
            </ButtonsWrapper>
        </EditFormContainerMobile>
    )
}

const TaskNotesEditorWrapperMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        notes: props.item.answer ? props.item.answer : '',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                notes: Yup.string().max(20000, 'over20k'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (props.item.answer !== values.notes) {
            props.submitFunc({
                taskid: props.taskid,
                dataList: [
                    {
                        id: 'body',
                        answer: values.notes,
                    },
                ],
            })
        }

        props.toggle()
    },
})(InnerForm)

export default TaskNotesEditorWrapperMobile
