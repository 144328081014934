import styled from 'styled-components'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import blank_user from '../../../../public/assets/placeholders_svgs/avatar.svg'
import AddBoxWithSvgPlaceholderMobile from '../../../atoms/placeholders/addBoxWithSvgPlaceholderMobile'
import { useNavigate } from 'react-router-dom'
import user_instagram from '../../../../public/assets/icons/socialMedia/user_instagram.svg'
import user_facebook from '../../../../public/assets/icons/socialMedia/user_facebook.svg'
import user_linkedin from '../../../../public/assets/icons/socialMedia/user_linkedin.svg'
import user_twitter from '../../../../public/assets/icons/socialMedia/user_twitter.svg'
import user_website from '../../../../public/assets/icons/socialMedia/user_website.svg'
import { device } from '../../../templates/displays/devices'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { useEffect, useState } from 'react'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { generateImageSources } from '../../../../helpers/images'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { IGalleryImage } from '../../../../redux/entities/galleries/types'
import { ICar } from '../../../../redux/entities/cars/types'
import { IUser } from '../../../../redux/user/types'
import { IUserAddressApi } from '../../../../redux/services/typedefinitions/apiPayloads'

type Props = {
    carOwner: IUser | undefined
    car?: ICar | undefined
    isAdmin?: boolean
    setActiveForm?: () => any
    onBannerAddClick?: () => any
    bannerImage?: IGalleryImage
    entryid: string
    isSold?: boolean
    isPOA?: boolean | undefined
}

const Container = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;

    width: 100%;
    box-sizing: border-box;

    height: 100%;

    padding-right: 1.5rem;
    padding-left: 1.5rem;

    background: var(--off-bg-color, #fafafa);
`

const UserInfoContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 80%;
    height: inherit;
`

const Address = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    /* letter-spacing: 150%; */
    text-align: center;
    text-transform: capitalize;
    color: var(--text-default, #666666);
`
const Title = styled.span`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    font-family: Lato-bold;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-align: center;
    text-transform: capitalize;
    color: var(--text-strong, #1a1a1a);
`
const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;

    width: 100%;
    box-sizing: border-box;
    height: inherit;

    align-self: stretch;
`

const UserImage = styled.img`
    height: 64px;
    width: 64px;
    border-radius: 50px;
`

const BannerWrapper = styled.div`
    height: 140px;
    width: 100%;
    box-sizing: border-box;

    @media ${device.ipad} {
        height: 250px;
    }

    position: relative;
`

const CarBannerImage = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
`

export const UserSocialMediaRow = styled.div`
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    @media ${device.mobile_and_ipad} {
        padding-bottom: 24px;
    }
`

const IconImg = styled.img`
    width: 24px;
`
export const flatOwnerAddress_Showroom = (address: IUserAddressApi) => {
    // return placeholder in case of undefined value

    if (address?.postcode?.toString() === 'SW1A 1AA') {
        return 'Address not publicly disclosed, please contact the dealership'
    }

    if (
        !address?.street_1?.trim().length &&
        !address?.locality?.trim().length &&
        !address?.postcode?.trim().length &&
        !address?.country?.trim().length
    )
        return 'No address'

    return `${address && address.street_1 ? `${address.street_1}, ` : ''} ${
        address && address.street_2 ? `${address.street_2}, ` : ''
    } ${address && address.locality ? `${address.locality}, ` : ''} ${
        address && address.postcode ? `${address.postcode}, ` : ''
    } ${
        address && address.country === 'United Kingdom'
            ? 'UK'
            : address.country
            ? address.country
            : ''
    }`
}

const OwnerSectionMobileWithCarPic = (props: Props) => {
    const { carOwner, entryid } = props
    const navigate = useNavigate()

    let hasAnySocialMedia: boolean = carOwner?.external_urls?.WEBSITE?.url
        ? true
        : carOwner?.external_urls?.FACEBOOK?.url
        ? true
        : carOwner?.external_urls?.INSTAGRAM?.url
        ? true
        : carOwner?.external_urls?.TWITTER?.url
        ? true
        : carOwner?.external_urls?.LINKEDIN?.url
        ? true
        : false

    let dispatch = useAppDispatch()

    const clickOnOwner = () => {
        if (carOwner && carOwner.id && carOwner.display_name) {
            dispatch(showroomActions.setCurrentPageNumber_sold(0))
            dispatch(showroomActions.setCurrentPageNumber_sale(0))

            dispatch(
                showroomActions.searchShowroom_setCars({
                    cars: undefined,
                    resultsFoundNumber: undefined,
                    similarSoldCarsNumber: undefined,
                    type: props.isSold ? 'sold' : 'sale',
                })
            )
            dispatch(
                showroomActions.setOwner_filter({
                    id: carOwner?.id,
                    display_name: carOwner?.display_name,
                })
            )

            let url = `/showroom`

            if (props.isSold) {
                url = url + '/sold'
            } else {
                url = url + '/for-sale'
            }

            url =
                url +
                `?owner_id=${carOwner?.id}&owner_display_name=${carOwner?.display_name}`
            url = encodeURI(url)
            navigate(url)
        }
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const isOwner =
        carOwner?.id && userLoggedIn && carOwner.id === userLoggedIn.id
            ? true
            : false

    const [isImgLoading, setIsImgLoading] = useState<boolean>(true)
    const [src, setSrc] = useState<string | undefined>(undefined)

    useEffect(() => {
        let url = props.bannerImage?.url

        if (url) {
            setSrc(url)
        }
    }, [props.bannerImage])

    let { width } = useWindowSize()
    return (
        <>
            {props.bannerImage ? (
                <BannerWrapper>
                    <SkeletonAbsolute isthingloading={isImgLoading} />
                    <CarBannerImage
                        src={src}
                        onLoad={() => {
                            setIsImgLoading(false)
                        }}
                        srcSet={
                            props.bannerImage
                                ? generateImageSources(props.bannerImage, width)
                                : undefined
                        }
                    />
                </BannerWrapper>
            ) : (
                <AddBoxWithSvgPlaceholderMobile
                    height="140px"
                    hasSvgBackgroundImage
                    onClickAdd={() => {
                        props.onBannerAddClick && props.onBannerAddClick()
                    }}
                    btnText="Add your car's banner image"
                />
            )}
            <Container>
                <div style={{ paddingTop: '20px' }} />

                <UserImage
                    src={
                        carOwner?.profile_image_url?.length
                            ? carOwner?.profile_image_url
                            : blank_user
                    }
                    onClick={() => {
                        clickOnOwner && clickOnOwner()
                    }}
                    // onClick={toggleUserProfileImageForm}
                />

                <div style={{ paddingTop: '20px' }} />

                <UserInfoContentContainer>
                    <Title
                        onClick={() => {
                            clickOnOwner && clickOnOwner()
                        }}
                    >
                        {carOwner?.display_name
                            ? carOwner?.display_name
                            : 'No name'}
                    </Title>
                    <div style={{ paddingTop: '8px' }} />
                    <Address
                        style={{
                            textTransform:
                                carOwner?.address?.postcode === 'SW1A 1AA'
                                    ? 'none'
                                    : 'capitalize',
                        }}
                    >
                        {flatOwnerAddress_Showroom(carOwner?.address!)}
                    </Address>
                </UserInfoContentContainer>
                <div style={{ paddingTop: '16px' }} />
                {hasAnySocialMedia ? (
                    <UserSocialMediaRow>
                        {carOwner?.external_urls?.WEBSITE?.url &&
                        carOwner?.external_urls?.WEBSITE?.url !== '' ? (
                            <a
                                href={`${carOwner.external_urls.WEBSITE.url}`}
                                target="_blank"
                            >
                                <IconImg src={user_website} alt="website" />
                            </a>
                        ) : null}

                        {carOwner?.external_urls?.FACEBOOK?.url &&
                        carOwner?.external_urls?.FACEBOOK?.url !== '' ? (
                            <a
                                href={`${carOwner.external_urls.FACEBOOK.url}`}
                                target="_blank"
                            >
                                <IconImg src={user_facebook} alt="facebook" />
                            </a>
                        ) : null}

                        {carOwner?.external_urls?.LINKEDIN?.url &&
                        carOwner?.external_urls?.LINKEDIN?.url !== '' ? (
                            <a
                                href={`${carOwner.external_urls.LINKEDIN.url}`}
                                target="_blank"
                            >
                                <IconImg src={user_linkedin} alt="linkedin" />
                            </a>
                        ) : null}

                        {carOwner?.external_urls?.INSTAGRAM?.url &&
                        carOwner?.external_urls?.INSTAGRAM?.url !== '' ? (
                            <a
                                href={`${carOwner.external_urls.INSTAGRAM.url}`}
                                target="_blank"
                            >
                                <IconImg src={user_instagram} alt="instagram" />
                            </a>
                        ) : null}

                        {carOwner?.external_urls?.TWITTER?.url &&
                        carOwner?.external_urls?.TWITTER?.url !== '' ? (
                            <a
                                href={`${carOwner.external_urls.TWITTER.url}`}
                                target="_blank"
                            >
                                <IconImg src={user_twitter} alt="twitter" />
                            </a>
                        ) : null}
                    </UserSocialMediaRow>
                ) : (
                    <div style={{ paddingTop: '8px' }} />
                )}

                <ActionsContainer>
                    {!props.isAdmin ? (
                        <>
                            {carOwner?.phone && (
                                <a
                                    href={`tel:${carOwner.phone}`}
                                    style={{ width: '100%' }}
                                >
                                    <ButtonAtom
                                        theme="flexible-transparent-blue-border"
                                        fontSize={14}
                                        width="100%"
                                        height="48px"
                                        dataCyId={'showroom-car-share'}
                                        icon={''}
                                    >
                                        Call now
                                    </ButtonAtom>
                                </a>
                            )}

                            {!props.isSold && (
                                <ButtonAtom
                                    theme="secondary"
                                    width="100%"
                                    height="48px"
                                    textTransform="capitalize"
                                    dataCyId={'showroom-car-share'}
                                    icon={''}
                                    onClick={() => {
                                        if (!userLoggedIn) {
                                            navigate('/login')
                                            dispatch(
                                                customNavDataActions.setInitialLandingUrl(
                                                    `/showroom/${entryid}/enquiry?tab=info`
                                                )
                                            )
                                        } else {
                                            navigate(
                                                `/showroom/${entryid}/enquiry?tab=info`
                                            )
                                        }
                                    }}
                                    disabled={isOwner ? true : false}
                                >
                                    enquire
                                </ButtonAtom>
                            )}
                        </>
                    ) : (
                        <ButtonAtom
                            theme="flexible-transparent-blue-border"
                            width="100%"
                            height="48px"
                            dataCyId={'showroom-car-share'}
                            icon={''}
                            fontSize={16}
                            onClick={() => {
                                props.setActiveForm && props.setActiveForm()
                            }}
                        >
                            Edit contact details
                        </ButtonAtom>
                    )}
                </ActionsContainer>

                <div style={{ paddingTop: '30px' }} />
            </Container>
        </>
    )
}

export default OwnerSectionMobileWithCarPic
