import * as React from 'react'
import styled from 'styled-components'
import { device } from '../devices'

interface Props {
    children?: React.ReactNode
}

const Wrap = styled.div`
    @media ${device.mobile_and_ipad} {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        padding-left: 18px;
        padding-right: 18px;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
    }

    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }
`

const MobileContentBody = (props: Props) => <Wrap>{props.children}</Wrap>

export default MobileContentBody
