import styled from 'styled-components'
import useWindowSize, {
    WindowSize,
} from '../../../../templates/displays/windowSizeHook'
import useThemes from '../../../../../providers/theme/hooks'

const Text = styled.div`
    margin: 0;
    padding: 0;
    color: var(--text-default, #666);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;

    @media (max-width: 744px) {
        padding-left: 24px;
        padding-right: 24px;
    }
`

const Imagee = styled.div`
    height: 255px;
    width: 100%;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;

    @media (max-width: 1200px) {
        height: 230px;
        object-fit: unset;
    }

    @media (max-width: 845px) {
        object-fit: contain;
    }

    @media (max-width: 744px) {
        width: 720px;
        margin-left: 24px;
    }

    @media (max-width: 445px) {
        object-fit: cover;
        width: 100%;
        height: 250px;
        box-sizing: border-box;
        margin-left: 0px;
    }
`
const Parent = styled.div`
    width: 800px;
    overflow-x: hidded;

    @media (max-width: 1200px) {
        width: 715px;
    }

    @media (max-width: 845px) {
        width: 620px;
        overflow-x: hidden;
    }

    @media (max-width: 744px) {
        width: 100%;
        box-sizing: border-box;
    }
`

const MailAddress = styled.a`
    display: inline;
    color: var(--primary, #5ec3ca) !important;
    font-family: 'Lato';
`

const InsuranceAppliCreationOnHold = () => {
    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 445 ? true : false

    const { theme } = useThemes()

    return (
        <Parent>
            <Imagee
                style={{
                    backgroundImage:
                        theme === 'dark'
                            ? isMobile
                                ? `url(${
                                      process.env.PUBLIC_URL +
                                      'insurance_appli_creation_on_hold_mobile_dark.png'
                                  })`
                                : `url(${
                                      process.env.PUBLIC_URL +
                                      'insurance_appli_creation_on_hold_desktop_dark.png'
                                  })`
                            : isMobile
                            ? `url(${
                                  process.env.PUBLIC_URL +
                                  'insurance_appli_creation_on_hold_mobile.png'
                              })`
                            : `url(${
                                  process.env.PUBLIC_URL +
                                  'insurance_appli_creation_on_hold_desktop.png'
                              })`,
                }}
            />
            <div style={{ paddingTop: '20px' }} />

            <Text>
                We sincerely apologise for any inconvenience this may cause. If
                you have any questions or would like assistance, please email us
                at{' '}
                <MailAddress
                    href={'mailto:hello@custodian.club'}
                    target="_blank"
                    rel="noreferrer"
                >
                    hello@custodian.club
                </MailAddress>
                , and we will respond as soon as possible.
                <p
                    style={{
                        color: 'inherit',
                        fontSize: 'inherit',
                        fontFamily: 'inherit',
                        paddingTop: '16px',
                    }}
                >
                    Thank you for your understanding.
                </p>
            </Text>
        </Parent>
    )
}

export default InsuranceAppliCreationOnHold
