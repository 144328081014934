import { IApexSingleInterviewItemState } from 'apexModels'
import React, { useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useAppSelector, useThunkAppDispatch } from '../../../redux/store/hooks'
import { getSingleApexInterviewByUIDDataThunks } from '../../../redux/theapex/thunks'
import ApexTeamCreditsDesktop from '../../molecules/apex/reusable/credits/apexTeamCreditsDesktop'
import ApexTeamCreditsMobile from '../../molecules/apex/reusable/credits/apexTeamCreditsMobile'
import ApexSingleInterviewHeaderDesktop from '../../molecules/apex/singleInterview/apexSingleInterviewHeaderDesktop'
import ApexSingleInterviewHeaderMobile from '../../molecules/apex/singleInterview/apexSingleInterviewHeaderMobile'
import ApexBodyRichContentManagerDesktop from '../../organisms/richContentManager/apex/apexBodyRichContentManagerDesktop'
import ApexBodyRichContentManagerMobile from '../../organisms/richContentManager/apex/apexBodyRichContentManagerMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { device } from '../../templates/displays/devices'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import ReturnNav from '../../atoms/header/returnNav'
import LoaderBackground from '../../atoms/loader/loaderBackground'
import SideSlider from '../../templates/animated/sideSlider'

const Wrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        padding-top: 28px;
        padding-right: 30px;
        padding-left: 30px;
    }

    @media ${device.beyond_ipad_mobile} {
        padding-right: 20vw;
        padding-left: 20vw;
        padding-top: 26px;
        width: 100%;
        box-sizing: border-box;
    }

    @media ${device.smallest_laptops} {
        padding-right: 16vw;
        padding-left: 16vw;
    }

    @media ${device.ipad} {
        padding-right: 10vw;
        padding-left: 10vw;
    }

    @media ${device.large_desktop} {
        padding-top: 26px;
        padding-right: 8vw;
        padding-left: 8vw;
    }
`

const Date = styled.div`
    @media ${device.mobile_and_ipad} {
        font-size: 15px;
    }

    color: var(--text-muted, #b3b3b3);
    font-size: 16px;
    font-family: 'Lato-Light';
`

const ApexSingleInterview: React.FC = () => {
    const { uid } = useParams<{ uid: string }>()
    const location = useLocation()

    const singleInterviews = useAppSelector(
        (state) => state.apex.singleInterviews
    )

    const dispatchThunks = useThunkAppDispatch()

    useEffect(() => {
        if (uid) {
            dispatchThunks(getSingleApexInterviewByUIDDataThunks(uid))
        }
    }, [uid])

    const interview: IApexSingleInterviewItemState | undefined =
        singleInterviews && uid && singleInterviews[uid]
            ? singleInterviews[uid]
            : undefined

    return (
        <React.Fragment>
            <CenteredPageWrapper>
                <DesktopDisplayOnly>
                    {!interview && (
                        <LoaderBackground height={'60vh'} width={'100%'} />
                    )}

                    {interview && (
                        <Wrapper>
                            <ApexSingleInterviewHeaderDesktop
                                title={interview.title}
                                name={interview.name}
                                uid={`${uid}`}
                            />
                            <div style={{ paddingTop: '50px' }} />
                            <ApexBodyRichContentManagerDesktop
                                rich_objects_array_without_slice={
                                    interview.introduction
                                }
                            />
                            <ApexTeamCreditsDesktop
                                text={interview.apex_team_credits}
                            />
                            <ApexBodyRichContentManagerDesktop
                                rich_slice_objects_array={interview.body}
                            />

                            <div style={{ paddingTop: '50px' }} />

                            <Date>
                                The Apex Interviews
                                <br />
                                {interview.date}
                            </Date>
                        </Wrapper>
                    )}
                </DesktopDisplayOnly>

                <IpadAndMobileDisplay>
                    <Link
                        to={{
                            pathname: `/apex/interviews`,
                        }}
                        state={{ prevPath: location.pathname }}
                    >
                        <ReturnNav customText="all interviews" />
                    </Link>

                    {!interview && (
                        <LoaderBackground height={'60vh'} width={'100%'} />
                    )}
                    <SideSlider direction="right" initial="enter">
                        {interview && (
                            <Wrapper>
                                <ApexSingleInterviewHeaderMobile
                                    title={interview.title}
                                    name={interview.name}
                                    uid={`${uid}`}
                                />
                                <div style={{ paddingTop: '33px' }} />
                                <ApexBodyRichContentManagerMobile
                                    rich_objects_array_without_slice={
                                        interview.introduction
                                    }
                                />

                                <ApexBodyRichContentManagerMobile
                                    rich_slice_objects_array={interview.body}
                                />

                                <div style={{ paddingTop: '40px' }} />

                                <ApexTeamCreditsMobile
                                    text={interview.apex_team_credits}
                                />
                                <div style={{ paddingTop: '25px' }} />

                                <Date>
                                    The Apex Interviews
                                    <br />
                                    {interview.date}
                                </Date>

                                <div style={{ paddingTop: '190px' }} />
                            </Wrapper>
                        )}
                    </SideSlider>
                </IpadAndMobileDisplay>
            </CenteredPageWrapper>
        </React.Fragment>
    )
}

export default ApexSingleInterview
