import { ITabProps } from 'myInterfaces'
import { useState } from 'react'
import styled from 'styled-components'
import OwnerBasicDetailsManagerMobile from '../../../molecules/showroom/ownerForm/basicDetails/ownerBasicDetailsManagerMobile'
import ShowroomOwnerSocialMediaFormMobile from '../../../molecules/showroom/ownerForm/socialMedia/socialMediaFormMobile'
import BtnTabRow from '../../../molecules/tab/btnTabRow'
import { device } from '../../../templates/displays/devices'
import { IUserAddressApi } from '../../../../redux/services/typedefinitions/apiPayloads'

const Wrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        min-height: 100vh;
        width: 100vw;
        background-color: var(--bg-color, #fff);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 24px;
        z-index: 20;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-bold;
    font-size: 24px;
    align-self: flex-start;
    padding-bottom: 10px;
`

// container for action button
export const OwnerShowroomFormActions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-end;
    height: 50px;
`
type Props = {
    close: () => any
}

export interface IOwnerBasicDetails extends IUserAddressApi {
    name: string
    phone: string
}
const OwnerContactFormMobie = (props: Props) => {
    const [activeTab, setActiveTab] = useState<'info' | 'address'>('info')

    const [hasInfoChange, setHasInfoChange] = useState<boolean>(false)
    const [hasSMChange, setHasSMChange] = useState<boolean>(false)

    let tabs: ITabProps[] = [
        {
            id: 0,
            title: 'Basic Details',
            onClick: (arg: number) => {
                setActiveTab('info')
            },
            isActive: activeTab === 'info' ? true : false,
            isDisabled: hasSMChange,
            disabledInfo: 'Please save your Social Media changes',
        },
        {
            id: 1,
            title: 'Social Media',
            onClick: (arg: number) => {
                setActiveTab('address')
            },
            isActive: activeTab === 'address' ? true : false,
            isDisabled: hasInfoChange,
            disabledInfo: 'Please save your Basic Details changes',
        },
    ]

    return (
        <Wrapper>
            <div style={{ paddingTop: '20px' }} />
            <Title>Contact details</Title>
            <div style={{ paddingTop: '20px' }} />
            <BtnTabRow tabs={tabs} />

            <div style={{ paddingTop: '20px' }} />

            <div
                style={{
                    display: activeTab === 'info' ? 'flex' : 'none',
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <OwnerBasicDetailsManagerMobile
                    close={() => {
                        props.close()
                    }}
                    hasInfoChange={hasInfoChange}
                    setHasInfoChange={setHasInfoChange}
                />
            </div>

            <div
                style={{
                    display: activeTab === 'address' ? 'flex' : 'none',
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <ShowroomOwnerSocialMediaFormMobile
                    close={() => {
                        props.close()
                    }}
                    hasSMChange={hasSMChange}
                    setHasSMChange={setHasSMChange}
                />
            </div>
        </Wrapper>
    )
}

export default OwnerContactFormMobie
