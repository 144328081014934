import styled from 'styled-components'
import chevron_grey_to_right from '../../../../public/assets/icons/chevron_grey_to_right.svg'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import ShowroomH2 from '../../../atoms/typography/showroom/ShowroomH2'
import { device } from '../../../templates/displays/devices'
import placeholderImg from '../../../atoms/skeleton/gallery/1.jpg'
import placeholderImg_dark from '../../../atoms/skeleton/gallery/1_dark.png'
import AddBoxWithSvgPlaceholderMobile from '../../../atoms/placeholders/addBoxWithSvgPlaceholderMobile'
import AddBoxWithPlaceholderAndPlusMobile from '../../../atoms/placeholders/addBoxWithPlaceholderAndPlusMobile'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import parse from 'html-react-parser'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    isLoading: boolean
    currentEntry: IShowroomEntry | undefined
    setActiveForm?: any
    editDescriptionImage?: any
    onShowMoreClick?: () => void
    carHasNoImages?: boolean
}
type StyleProps = {
    $isTitle?: boolean
    $isShowMore?: boolean
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    box-sizing: border-box;
    height: 100%;
    margin-bottom: 10px;

    flex: 1;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
`

const CarContent = styled.span<StyleProps>`
    font-family: Lato;
    font-style: normal;
    text-transform: ${(props) =>
        props.$isTitle || props.$isShowMore ? 'capitalize' : 'none'};
    font-weight: ${(props) =>
        props.$isTitle || props.$isShowMore ? 500 : 400};
    font-size: ${(props) => (props.$isTitle ? '1.5rem' : '1rem')};
    line-height: ${(props) => (props.$isTitle ? '2.5rem' : '1.5rem')};
    letter-spacing: ${(props) => (props.$isTitle ? 'normal' : 'normal')};
    color: ${(props) =>
        props.$isTitle
            ? 'var(--text-strong, #1a1a1a)'
            : props.$isShowMore
            ? 'var(--primary, #5EC3CA)'
            : 'var(--text-default, #666666)'};
    width: 100%;
    box-sizing: border-box;

    transition: all 100ms;

    @media ${device.smallest_laptops} {
        font-size: ${(props) => (props.$isTitle ? '1.2rem' : '0.9rem')};
    }

    p,
    li,
    h1,
    h2,
    h3 {
        color: var(--text-default, #666666);
    }

    h2 {
        font-size: 18px !important;
        padding-bottom: 10px;
    }

    h1 {
        font-size: 19px !important;
        padding-bottom: 10px;
    }
`

const SectionImage = styled.img`
    object-fit: cover;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 500px) {
        height: 200px;
    }

    @media (min-width: 501px) and (max-width: 690px) {
        height: 350px;
    }

    @media (min-width: 690px) {
        height: 500px;
    }
`

const ShowMoreContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    cursor: pointer;
    max-width: 6.5rem;
    height: 1.5rem;
`

const Chevron = styled.img`
    filter: brightness(0) saturate(100%) invert(72%) sepia(37%) saturate(496%)
        hue-rotate(135deg) brightness(91%) contrast(91%);
`

// container for loader skeleton
const LoaderImageContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 20rem;
`

const DescriptionSectionMobile = (props: Props) => {
    const {
        currentEntry,
        isLoading,
        editDescriptionImage,
        onShowMoreClick,
        carHasNoImages,
    } = props

    let image_1 =
        currentEntry && currentEntry.featured_image
            ? currentEntry.featured_image
            : undefined

    let car = currentEntry && currentEntry.car ? currentEntry.car : undefined

    let textWithoutMainTags =
        car &&
        car.description &&
        car.description
            .replace(/<\/h1>/g, '\n')
            .replace(/<\/h2>/g, '\n')
            .replace(/<\/h3>/g, '\n')
            .replace(/<\/li>/g, '\n')
            .replace(/<\/ul>/g, '\n')
            .replace(/<\/p>/g, '\n')
            // .replace(/<[^>]+>/g, '')
            .replace(/\n/g, '<br />')

    let str = textWithoutMainTags
        ? textWithoutMainTags.length > 600
            ? `${textWithoutMainTags.substring(0, 600)}...`
            : car?.description
        : undefined

    const { theme } = useThemes()

    return (
        <Container>
            {!image_1 ? (
                <AddBoxWithSvgPlaceholderMobile
                    height="200px"
                    onClickAdd={editDescriptionImage}
                    hasSvgBackgroundImage
                    customPlaceholderImg={
                        theme === 'dark' ? placeholderImg_dark : placeholderImg
                    }
                    btnText={
                        carHasNoImages
                            ? `Let's add some images to your car`
                            : 'Choose an image from your gallery'
                    }
                />
            ) : isLoading ? (
                <LoaderImageContainer>
                    <SkeletonAbsolute isthingloading={isLoading} />
                </LoaderImageContainer>
            ) : image_1 ? (
                <SectionImage
                    src={image_1.url}
                    srcSet={
                        image_1.small_srcset
                            ? image_1.small_srcset
                            : image_1.url
                    }
                    onClick={editDescriptionImage}
                />
            ) : null}

            <div style={{ paddingTop: '52px' }} />

            <ContentContainer>
                <ShowroomH2
                    leftAlign
                    textTransform="capitalize"
                    hasEdit={props.setActiveForm ? true : false}
                    onEdit={() => {
                        props.setActiveForm && props.setActiveForm()
                    }}
                >
                    Description
                </ShowroomH2>
                <div style={{ paddingTop: '16px' }} />

                {car && car.description && car.description.length > 1 ? (
                    <CarContent>{str ? parse(str) : '-'}</CarContent>
                ) : (
                    <AddBoxWithPlaceholderAndPlusMobile
                        onClickAdd={() => {
                            props.setActiveForm && props.setActiveForm()
                        }}
                        height="200px"
                        btnText="Add car's description"
                    />
                )}
                <div style={{ paddingTop: '16px' }} />
                {textWithoutMainTags && textWithoutMainTags.length > 600 && (
                    <ShowMoreContainer
                        onClick={onShowMoreClick && onShowMoreClick}
                    >
                        <CarContent $isShowMore>show more</CarContent>
                        <Chevron src={chevron_grey_to_right} />
                    </ShowMoreContainer>
                )}
            </ContentContainer>
        </Container>
    )
}

export default DescriptionSectionMobile
