import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    ModalArchiveReasons,
    ModalArchiveStart,
    ModalArchiveSuccess,
} from '../../organisms/archiveForms/archiveCarDesktopManager'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'

const MobilePageWrap = styled.div`
    height: 100vh;
    background-color: 'white';
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
`

type CarParams = {
    carid: string
}

const ArchiveCarFlowMobile: React.FC = () => {
    const activeArchivingStep = useAppSelector(
        (state) => state.editForms.activeArchivingStep
    )
    const [reason, setReason] = useState<string | undefined>(undefined)
    const [message, setMessage] = useState<string | undefined>(undefined)

    const dispatch = useAppDispatch()
    const { carid } = useParams<CarParams>()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(editFormsActions.setArchivingStep('start'))
    }, [])

    const handleHandoverClick = () => {
        navigate(`/handover/${carid}`)
    }

    const handleArchiveClick = () => {
        dispatch(editFormsActions.setArchivingStep('reasons'))
    }

    const handleClose = () => {
        navigate(-1)
    }

    const handleSelect = (id: string) => {
        reason === id ? setReason(undefined) : setReason(id)
    }

    const handleSubmitArchive = () => {
        dispatch(
            carActions.archiveCarRequest({
                carID: carid!,
                data: {
                    archived_reason: 'custom',
                    custom_reason: reason,
                    custom_note: message,
                },
            })
        )
        // success step will be set inside archiving saga if no error
    }

    const handleCancel = () => {
        navigate(`/car/${carid}`)
    }

    const handleSuccessClose = () => {
        navigate('/garage/previously-owned')
        dispatch(editFormsActions.setArchivingStep(undefined))
    }

    return (
        <IpadAndMobileDisplay>
            <MobilePageWrap
                style={{
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingBottom: '100px',
                }}
            >
                {activeArchivingStep === 'start' && (
                    <ModalArchiveStart
                        onHandoverClick={handleHandoverClick}
                        onArchiveClick={handleArchiveClick}
                        close={handleClose}
                    />
                )}
                {activeArchivingStep === 'reasons' && (
                    <ModalArchiveReasons
                        message={message}
                        setMessage={setMessage}
                        onSelect={handleSelect}
                        activeID={reason}
                        submitArchive={handleSubmitArchive}
                        onCancel={handleCancel}
                        isDiabled={reason === undefined}
                    />
                )}
                {activeArchivingStep === 'success' && (
                    <ModalArchiveSuccess onClose={handleSuccessClose} />
                )}
            </MobilePageWrap>
        </IpadAndMobileDisplay>
    )
}

export default ArchiveCarFlowMobile
