import { all, call, fork, put, select, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { api } from '../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ConvertDateToStateReminders } from '../../conversions/reminders/convertToStateReminders'
import {
    fromReminderDateToBackEndFormat,
    IConvertReminderDateToBEPayload,
} from '../../conversions/time'
import { getTaskRemindersSaga, IGetTaskRemindersReq } from './getTaskReminders'
import { tasksActions } from '../reducer'
import {
    IReminder,
    IReminderEdited,
    ISetAPITaskRemindersInStatePayload,
    ISetTaskRemindersEditedPayload,
    ITask,
    ITaskNormalisedObject,
} from 'entityModels'
import { IRootState } from '../../store'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'
import { IReminderReqPayloadAPI, IReminderAPIRes } from '../../services/types'

export type I_addSingleReminderToTaskToAPISaga_Payload = {
    reminder?: IReminderEdited
    taskID: string
}

// get tasks in the state
export const getTasksInState = (state: IRootState): ITaskNormalisedObject =>
    state.tasks.tasks

export function* addSingleReminderToTaskToAPISaga(
    p: I_addSingleReminderToTaskToAPISaga_Payload
): any {
    if (p.reminder) {
        try {
            let tasksInState = yield select(getTasksInState)

            let taskData: ITask | undefined = tasksInState[p.taskID]

            let convData: IConvertReminderDateToBEPayload = {
                type: p.reminder?.type ? p.reminder?.type : 'custom_date_copy',
                value: p.reminder?.datePicked ? p.reminder?.datePicked : '',
                nvalue: p.reminder.nvalue,
                dueDate: taskData && taskData.due ? taskData.due : '',
            }

            let dateConv: string = fromReminderDateToBackEndFormat(convData)

            let payloadAPI: IReminderReqPayloadAPI = {
                scheduled_at: dateConv,
                taskUID: p.taskID,
            }

            const task_reminder: IReminderAPIRes = yield call(
                api.tasks.reminders.createReminderAPI,
                payloadAPI
            )

            let converted_reminders: IReminder[] = ConvertDateToStateReminders(
                [task_reminder],
                taskData ? taskData?.due : undefined
            )

            let action_payload: ISetAPITaskRemindersInStatePayload = {
                taskID: p.taskID,
                reminders: [...converted_reminders],
            }

            yield put(tasksActions.addRemindersToTaskSuccess(action_payload))
        } catch (error: any) {
            if (error.status === 401) {
                let payload: IUnauthHandlerPayload = {
                    functionToRepeat: addSingleReminderToTaskToAPISaga,
                    payload: p,
                }
                yield call(unauthHandlerSaga, payload)
            } else {
                let customErrorData: ICustomErrorData = {
                    custom_message: `Something went wrong, we couldn't get the task's reminders`,
                    custom_user_action_text: 'Return to see all tasks',
                    custom_redirect_path: `/tasks`,
                }
                let customError: IReduxError = ConvertToReduxError(
                    error,
                    customErrorData
                )
                yield put(tasksActions.getTaskRemindersError(customError))
            }
        }
    }
}

export function* addRemindersToTaskSaga(
    p: ISetTaskRemindersEditedPayload
): any {
    let reminders_keys: string[] =
        p.reminders !== undefined ? Object.keys(p.reminders) : []

    if (reminders_keys.length > 0 && p.reminders !== undefined) {
        yield all(
            reminders_keys.map((reminderID: string) => {
                return call(addSingleReminderToTaskToAPISaga, {
                    taskID: p.taskID,
                    reminder:
                        p.reminders && p.reminders[reminderID]
                            ? p.reminders[reminderID]
                            : undefined,
                })
            })
        )
    }

    if (reminders_keys.length > 1) {
        let tasksInState = yield select(getTasksInState)

        let taskData: ITask | undefined = tasksInState[p.taskID]

        let due: string = taskData && taskData.due ? taskData.due : ''

        let p2: IGetTaskRemindersReq = {
            taskUID: p.taskID,
            dueDate: due,
        }

        yield call(getTaskRemindersSaga, p2)
    }
}

function* watcherAddRemindersToTaskSaga() {
    while (true) {
        const { payload } = yield take(tasksActions.addRemindersToTaskRequest)
        yield call(addRemindersToTaskSaga, payload)
    }
}

const add_task_reminders: any[] = [fork(watcherAddRemindersToTaskSaga)]

export default add_task_reminders
