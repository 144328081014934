import { IAnyObject } from '../../redux/insuranceQuoteApplication/reducer'

export function normalizeArray(arr: any[]) {
    const normalizedObj: IAnyObject = {}

    arr.forEach((item) => {
        const key = item.id
        normalizedObj[key] = { ...item }
    })

    return normalizedObj
}
