import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { useAppSelector } from '../../../../redux/store/hooks'
import { randomCarSvgPlaceholderFunc } from '../../../../helpers/random/randomPlaceholder'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { ArchivedMenuItem, ArchiveThreeDotMenuWrapper } from './itemGridView'
import { IThreeDotsActionsItems } from '../../../atoms/menu/actionsMenu/actionsMenuDots'
import { useOnClickOutside } from '../../../templates/clickOutside'
import { determineCarArchiveCopy } from '../../../pages/archive/garageArchive'
import React from 'react'
import GalleryIcon from '../../../atoms/icons/components/galleryIcon'
import ThreeDotsIcon from '../../../atoms/icons/components/threeDots'
import colours from '../../../../providers/theme/colours'
import { HistoryFilesIcon } from '../../../atoms/icons/components'
import useThemes from '../../../../providers/theme/hooks'
import { IGalleryImage } from '../../../../redux/entities/galleries/types'
import { ICar } from '../../../../redux/entities/cars/types'

type Props = {
    car: ICar
    menu_items: (car: ICar) => IThreeDotsActionsItems[]
    onThreeDotsClick?: (carid: string) => void
}

const MenuBox = styled.div`
    z-index: 10;
    position: absolute;
    right: 30px;
    top: -20px;

    top: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    font-family: Lato;
    font-size: 16px;
    /* Base/White */

    background: var(--bg-color, #fff);
    /* 24 */

    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    height: auto;
    width: max-content;

    :hover {
        color: var(--primary, #5ec3ca);
        transition: all 100ms;
    }
`
const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    height: 364px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 1px;
`

const ProfileImage = styled.img`
    object-fit: cover;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px 32px 32px;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    border-right: 1px solid var(--border-muted, #e5e5e5);
    height: 100%;
    width: 50%;
    border-radius: 1px;
`

const Row1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`

const Title = styled.div`
    font-family: Lato-Semibold;
    /* font-weight: 600; */
    font-size: 24px;
    color: var(--text-strong, #1a1a1a);

    cursor: pointer;

    :hover {
        color: var(--primary, #5ec3ca);
    }
`

type IArchivedAtStyledProps = {
    $isFailed?: boolean
}

const ArchivedAt = styled.div<IArchivedAtStyledProps>`
    font-family: Lato-Semibold;
    /* font-weight: 600; */
    font-size: 12px;
    letter-spacing: 0.07em;

    color: ${(props) =>
        props.$isFailed === true
            ? `var(--error, #df6f6f)`
            : 'var(--text-darker, #616161)'};

    text-transform: uppercase;

    @media ${device.mobile_and_ipad} {
        /* font-weight: 400; */
        font-size: 11px;
        color: ${(props) =>
            props.$isFailed === true
                ? `var(--error, #df6f6f)`
                : 'var(--text-darker, #616161)'};
    }

    @media (min-width: 880px) and (max-width: 1200px) {
        font-size: 13px;
    }
`

const Desc = styled.div`
    font-family: 'Lato';
    /* font-weight: 400; */
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
`

const Column1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
`

const CountsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
`

const ShortRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0px;
    gap: 8px;
`
const ThreeDots = styled.button`
    all: unset;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    cursor: pointer;
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 4px;

    @media ${device.mobile_and_ipad} {
        width: 30px;
        height: 30px;
    }
`

const CountTxt = styled.div`
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);

    @media ${device.smallest_laptops} {
        font-size: 12px;
    }
`

const ImageWrapper = styled.div`
    height: 100%;
    width: 50%;
    position: relative;
    cursor: pointer;
`

const ArchivedGarageItemListView = (props: Props) => {
    let { car, menu_items, onThreeDotsClick } = props

    const images_data = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let cover_id = car?.gallery?.cover
    let cover_img: IGalleryImage | undefined =
        images_data && images_data[cover_id] ? images_data[cover_id] : undefined

    let navigate = useNavigate()

    const [isImgLoading, setIsImgLoading] = useState<boolean>(true)
    const [src, setSrc] = useState<string>('')
    const { theme } = useThemes()

    const randomPlaceholder = randomCarSvgPlaceholderFunc(undefined, theme)

    useEffect(() => {
        let url =
            cover_img && cover_img.url ? cover_img.url : `${randomPlaceholder}`

        setSrc(url)
    }, [cover_img])

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const menuRef = useRef<HTMLDivElement>(null)

    useOnClickOutside(menuRef, () => {
        if (isMenuOpen === true) {
            setIsMenuOpen(false)
        }
    })

    return (
        <Container key={`${car.id}-desk-list`}>
            <ImageWrapper
                onClick={() => {
                    navigate(`/archives/car/${car.id}/overview`)
                }}
            >
                <SkeletonAbsolute isthingloading={isImgLoading} />
                <ProfileImage
                    role="button"
                    alt="car profile image"
                    src={src}
                    onLoad={() => {
                        setIsImgLoading(false)
                    }}
                />
            </ImageWrapper>

            <Column>
                <Column1>
                    <Row1>
                        <Title
                            role="button"
                            onClick={() => {
                                navigate(`/archives/car/${car.id}/overview`)
                            }}
                        >
                            {car.title}
                        </Title>
                        <ArchiveThreeDotMenuWrapper
                            whileTap={{ scale: 1.02 }}
                            onClick={() => {
                                setIsMenuOpen(true)
                                onThreeDotsClick && onThreeDotsClick(car.id)
                            }}
                            ref={menuRef}
                        >
                            <ThreeDots>
                                <ThreeDotsIcon />
                            </ThreeDots>
                            {isMenuOpen === true && (
                                <MenuBox>
                                    {menu_items(car).map((item, index) => {
                                        return item.hide_item ? null : (
                                            <React.Fragment
                                                key={`list_view_item_${index}_desktop`}
                                            >
                                                <ArchivedMenuItem
                                                    item={item}
                                                    index={index}
                                                    toggle={(p: boolean) => {
                                                        setIsMenuOpen(p)
                                                    }}
                                                    carID={car.id}
                                                    carHandoverData={
                                                        car.handover
                                                    }
                                                />
                                            </React.Fragment>
                                        )
                                    })}
                                </MenuBox>
                            )}
                        </ArchiveThreeDotMenuWrapper>
                    </Row1>

                    <div style={{ paddingTop: '8px' }} />

                    <ArchivedAt
                        $isFailed={car.handover?.handover_state === 'FAILED'}
                    >
                        {determineCarArchiveCopy(car)}
                    </ArchivedAt>

                    {/* <ArchivedAt>
                        This car was in your Custodianshop for 8 years
                    </ArchivedAt> */}

                    <div style={{ paddingTop: '10px' }} />

                    <Desc>{car.short_description}</Desc>
                </Column1>

                <CountsRow>
                    <ShortRow>
                        <GalleryIcon
                            size="20"
                            color={colours[theme].text_muted}
                        />
                        <CountTxt>
                            {car.gallery_images_count ?? 0} Photos
                        </CountTxt>
                    </ShortRow>
                    <ShortRow>
                        <HistoryFilesIcon
                            size="20"
                            color={colours[theme].text_muted}
                        />
                        <CountTxt>
                            {car.car_entries_count} History Files
                        </CountTxt>
                    </ShortRow>
                </CountsRow>
            </Column>
        </Container>
    )
}

export default ArchivedGarageItemListView
