import useThemes from '../../../../../providers/theme/hooks'
import Faded from '../../../../templates/animated/faded'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import {
    JourneyColumnContent,
    JourneyText,
} from '../../../../templates/styledcomponents/journeyStyles'
import JourneyPageWrapperMobile from '../../../../templates/wrappers/journeyPageWrapperMobile'
import { CenterImg } from '../memories'
import image_mobile from '../../../../../public/assets/images/journey/pastCars/mobile_legacy.png'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function LegacyJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    return (
        <IpadAndMobileDisplay>
            <Faded>
                <JourneyPageWrapperMobile
                    allowScroll={true}
                    title={'Start Building Your Automotive Legacy'}
                    sectionID="past_cars"
                    subtitle={`Don't forget your past cars`}
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        goToPrevStep()
                    }}
                    onNext={() => {
                        goToNextStep()
                    }}
                >
                    <JourneyColumnContent style={{ gap: 0 }}>
                        <JourneyText
                            $theme={theme}
                            style={{
                                paddingLeft: '24px',
                                paddingRight: '24px',
                            }}
                        >
                            Don't let your past cars fade into oblivion. Add
                            them to your Custodian garage and create a legacy of
                            automotive memories that will last a lifetime.
                        </JourneyText>

                        <div style={{ paddingTop: '36px' }} />

                        <CenterImg
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Faded width={'auto'}>
                                <img
                                    // placeholder={grey_placeholder}
                                    src={image_mobile}
                                    style={{
                                        objectFit: 'contain',
                                        width: '90vw',
                                        transform: 'translateX(-10px)',
                                        maxWidth: '550px',
                                    }}
                                />
                            </Faded>
                        </CenterImg>

                        <div style={{ paddingTop: '42px' }} />

                        <JourneyText
                            $theme={theme}
                            style={{
                                fontSize: '16px',
                                paddingLeft: '24px',
                                paddingRight: '24px',
                            }}
                        >
                            Your automotive journey is waiting to be
                            immortalized—start preserving your past cars today.
                        </JourneyText>

                        <div style={{ paddingTop: '80px' }} />
                    </JourneyColumnContent>
                </JourneyPageWrapperMobile>
            </Faded>
        </IpadAndMobileDisplay>
    )
}
