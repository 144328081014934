import Faded from '../../templates/animated/faded'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { editFormsActions } from '../../../redux/editForms/reducer'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'
import ListItemMobile from '../../atoms/list/listItemMobile'
import styled from 'styled-components'
import PreferencesInlineFormDesktop from '../../organisms/editForms/preferences/preferencesInlineFormDesktop'
import PreferencesRadioFormMobile from '../../organisms/editForms/preferences/preferencesRadioFormMobile'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import InfoBarMobile from '../../atoms/info/infoBarMobile'
import PreferencesInlineSearchSelectDesktop from '../../organisms/editForms/preferences/preferencesInlineSearchSelectDesktop'
import PreferencesSearchSelectFormMobile from '../../organisms/editForms/preferences/preferencesSearchSelectMobile'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import PreferencesSelectMobile from '../../organisms/editForms/preferences/preferencesSelectMobile'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IRootState } from '../../../redux/store'
import { useEffect } from 'react'
import { carActions } from '../../../redux/entities/cars/reducer'
import { IUSerGeneralInfoUpdatePayload } from '../../../redux/user/types'
import { usersActions } from '../../../redux/user/reducer'
import { IUserPreferencesDataLevel4Obj } from '../../../redux/user/preferences'

const ColWrapperUnit = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`

const UserPreferencesPage = () => {
    const dispatch = useAppDispatch()
    const userLoggedIn = useAppSelector(
        (state: IRootState) => state.user.userLoggedIn
    )

    const isAccountExternalInfoFormOpened = useAppSelector(
        (state: IRootState) =>
            state.editForms.formsData.accountExternalInfoForm.isOpen
    )
    const accountExternalInfoFormID = useAppSelector(
        (state: IRootState) =>
            state.editForms.formsData.accountExternalInfoForm.item?.id
    )
    const garage_data = useAppSelector(
        (state: IRootState) => state.entities.garagesData.garages
    )

    const time_zone_dropdown_data = useAppSelector(
        (state: IRootState) => state.localdata.dropdownData.timeZonesData
    )
    const time_zone_dropdown_list = useAppSelector(
        (state: IRootState) => state.localdata.dropdownData.timeZonesList
    )
    const selectedTimezone = useAppSelector(
        (state: IRootState) => state.localdata.dropdownData.selectedTimezone
    )

    const toggleAccountExternalInfoEditForm = (item?: any) =>
        dispatch(editFormsActions.toggleAccountExternalInfoEditForm(item))
    const setTimeZoneDropdownData = () =>
        dispatch(dropDownActions.setTimeZoneDropdownDataRequest())
    const setInitialLandingUrl = (str: string) =>
        dispatch(customNavDataActions.setInitialLandingUrl(str))
    const clearSelectedTimezone = () =>
        dispatch(dropDownActions.setSelectedTimezone(null))

    useEffect(() => {
        setInitialLandingUrl('/account/preferences')

        if (!userLoggedIn) {
            usersActions.getCurrentUserDataRequest()
        }

        if (
            userLoggedIn &&
            userLoggedIn.owns_garage &&
            !garage_data[userLoggedIn.owns_garage.uid]
        ) {
            dispatch(
                carActions.getGarageCarsDataRequest(
                    userLoggedIn.owns_garage.uid
                )
            )
        }

        if (!time_zone_dropdown_data) {
            setTimeZoneDropdownData()
        }
    }, [userLoggedIn])

    let nofCars =
        userLoggedIn &&
        userLoggedIn.owns_garage &&
        userLoggedIn.owns_garage.uid &&
        garage_data[userLoggedIn.owns_garage.uid] &&
        garage_data[userLoggedIn.owns_garage.uid].cars
            ? garage_data[userLoggedIn.owns_garage.uid].cars.length
            : null

    let def_garage_pref: string | null = nofCars === null ? null : 'grid'

    // ids

    let fuel_unit_field_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['fuel_unit'].field_id

    let distance_unit_field_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['distance_unit'].field_id

    let currency_field_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['default_currency'].field_id

    let garage_user_preferences_field_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['garage'] &&
        userLoggedIn.preferences.data['garage'].data['default_garage_view']
            .field_id

    let time_zone_user_preferences_field_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['notifications'] &&
        userLoggedIn.preferences.data['notifications'].data['time_zone']
            .field_id

    let notification_hour_user_preferences_field_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['notifications'] &&
        userLoggedIn.preferences.data['notifications'].data[
            'preferred_notification_time'
        ] &&
        userLoggedIn.preferences.data['notifications'].data[
            'preferred_notification_time'
        ].field_id

    // lists

    let fuel_unit_user_preferences_list_desktop: string[] | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['fuel_unit'].ids_list

    let distance_unit_user_preferences_list_desktop: string[] | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['distance_unit'].ids_list

    let currency_user_preferences_list_desktop: string[] | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['default_currency'].ids_list

    let garage_user_preferences_list_desktop: string[] | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['garage'] &&
        userLoggedIn.preferences.data['garage'].data['default_garage_view']
            .ids_list

    let notification_hour_user_preference_list_desktop: string[] | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['notifications'] &&
        userLoggedIn.preferences.data['notifications'].data[
            'preferred_notification_time'
        ] &&
        userLoggedIn.preferences.data['notifications'].data[
            'preferred_notification_time'
        ].ids_list

    // let time_zone_user_preference_list_desktop: string[] | null =
    //     userLoggedIn &&
    //     userLoggedIn.preferences.data['notifications'] &&
    //     userLoggedIn.preferences.data['notifications'].data['time_zone'] &&
    //     userLoggedIn.preferences.data['notifications'].data['time_zone']
    //         .ids_list

    // current choices

    let current_fuel_unit_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['fuel_unit'].user_choice_id

    let current_distance_unit_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['distance_unit']
            .user_choice_id

    let current_user_currency_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['default_currency']
            .user_choice_id

    let current_garage_preferences_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['garage'] &&
        (userLoggedIn.preferences.data['garage'].data['default_garage_view']
            .user_choice_id === 'grid' ||
            userLoggedIn.preferences.data['garage'].data['default_garage_view']
                .user_choice_id === 'list')
            ? userLoggedIn.preferences.data['garage'].data[
                  'default_garage_view'
              ].user_choice_id
            : def_garage_pref !== null
            ? def_garage_pref
            : 'grid'

    let current_user_time_zone_id: string | null =
        userLoggedIn?.preferences?.data?.notifications?.data?.time_zone
            ?.user_choice_id ?? ''

    let current_user_notification_hour_id: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['notifications'] &&
        userLoggedIn.preferences.data['notifications'].data[
            'preferred_notification_time'
        ] &&
        userLoggedIn.preferences.data['notifications'].data[
            'preferred_notification_time'
        ].user_choice_id

    let fuel_unit_user_preferences_data_desktop: IUserPreferencesDataLevel4Obj | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['fuel_unit'].data

    let distance_unit_user_preferences_data_desktop: IUserPreferencesDataLevel4Obj | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['distance_unit'].data

    let garage_user_preferences_data_desktop: IUserPreferencesDataLevel4Obj | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['garage'] &&
        userLoggedIn.preferences.data['garage'].data['default_garage_view'].data

    let currency_user_preferences_data_desktop: IUserPreferencesDataLevel4Obj | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'] &&
        userLoggedIn.preferences.data['units'].data['default_currency'].data

    let notification_hour_data_desktop: IUserPreferencesDataLevel4Obj | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['notifications'] &&
        userLoggedIn.preferences.data['notifications'].data[
            'preferred_notification_time'
        ] &&
        userLoggedIn.preferences.data['notifications'].data[
            'preferred_notification_time'
        ].data

    let handleChangeTimeZone = (dataToSend: IUSerGeneralInfoUpdatePayload) => {
        if (dataToSend.data?.user_preferences && time_zone_dropdown_data) {
            let match: string =
                time_zone_dropdown_data[
                    dataToSend.data.user_preferences.time_zone
                        ? dataToSend.data.user_preferences.time_zone
                        : ''
                ]
            dataToSend.data.user_preferences.time_zone = match

            dispatch(usersActions.updateUserGeneralInfoRequest(dataToSend))
        }
        clearSelectedTimezone()
    }

    return (
        <CenteredPageWrapper hasMobileSubNav>
            {userLoggedIn &&
                currency_user_preferences_list_desktop &&
                current_fuel_unit_id &&
                distance_unit_user_preferences_data_desktop &&
                distance_unit_user_preferences_list_desktop &&
                currency_user_preferences_data_desktop &&
                current_distance_unit_id &&
                current_user_currency_id &&
                fuel_unit_user_preferences_list_desktop &&
                garage_user_preferences_data_desktop &&
                current_garage_preferences_id &&
                garage_user_preferences_field_id &&
                garage_user_preferences_list_desktop &&
                fuel_unit_user_preferences_data_desktop &&
                fuel_unit_field_id &&
                currency_field_id &&
                distance_unit_field_id &&
                time_zone_dropdown_data &&
                time_zone_dropdown_list && (
                    <Faded>
                        <Loader />
                        <DesktopDisplayOnly>
                            <div
                                style={{
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                }}
                            >
                                <SectionHeader
                                    title={'Reminders Notification preferences'}
                                    removeEdit={true}
                                />

                                <PreferencesInlineSearchSelectDesktop
                                    userid={userLoggedIn.id}
                                    title="Time-zone"
                                    field_id={
                                        time_zone_user_preferences_field_id
                                    }
                                    item={{
                                        id: current_user_time_zone_id
                                            ? current_user_time_zone_id
                                            : '',
                                        text: current_user_time_zone_id
                                            ? current_user_time_zone_id
                                            : '',
                                        answer: current_user_time_zone_id
                                            ? current_user_time_zone_id
                                            : undefined,
                                    }}
                                    helperText={
                                        'Please type your region (ex: Europe) to search'
                                    }
                                    submitFunc={handleChangeTimeZone}
                                    isKeyValue={true}
                                    optionList={time_zone_dropdown_list}
                                />
                                <div style={{ marginTop: '20px' }} />

                                <PreferencesInlineSearchSelectDesktop
                                    userid={userLoggedIn.id}
                                    title="Preferred hour"
                                    field_id={
                                        notification_hour_user_preferences_field_id
                                    }
                                    item={
                                        notification_hour_data_desktop &&
                                        current_user_notification_hour_id
                                            ? notification_hour_data_desktop[
                                                  current_user_notification_hour_id
                                              ]
                                            : null
                                    }
                                    ids_list={
                                        notification_hour_user_preference_list_desktop
                                    }
                                    data={notification_hour_data_desktop}
                                    submitFunc={(
                                        p: IUSerGeneralInfoUpdatePayload
                                    ) => {
                                        dispatch(
                                            usersActions.updateUserGeneralInfoRequest(
                                                p
                                            )
                                        )
                                    }}
                                />
                                <SectionHeader
                                    title={'Units'}
                                    removeEdit={true}
                                />

                                <ColWrapperUnit>
                                    <PreferencesInlineFormDesktop
                                        title="Distance units"
                                        item={
                                            distance_unit_user_preferences_data_desktop[
                                                current_distance_unit_id
                                            ]
                                        }
                                        ids_list={
                                            distance_unit_user_preferences_list_desktop
                                        }
                                        data={
                                            distance_unit_user_preferences_data_desktop
                                        }
                                        userid={userLoggedIn.id}
                                        field_id={distance_unit_field_id}
                                        submitFunc={(
                                            p: IUSerGeneralInfoUpdatePayload
                                        ) => {
                                            dispatch(
                                                usersActions.updateUserGeneralInfoRequest(
                                                    p
                                                )
                                            )
                                        }}
                                    />
                                    <div style={{ marginTop: '20px' }} />

                                    <PreferencesInlineFormDesktop
                                        userid={userLoggedIn.id}
                                        title="Capacity units"
                                        field_id={fuel_unit_field_id}
                                        item={
                                            fuel_unit_user_preferences_data_desktop[
                                                current_fuel_unit_id
                                            ]
                                        }
                                        ids_list={
                                            fuel_unit_user_preferences_list_desktop
                                        }
                                        data={
                                            fuel_unit_user_preferences_data_desktop
                                        }
                                        submitFunc={(
                                            p: IUSerGeneralInfoUpdatePayload
                                        ) => {
                                            dispatch(
                                                usersActions.updateUserGeneralInfoRequest(
                                                    p
                                                )
                                            )
                                        }}
                                    />
                                    <div style={{ marginTop: '20px' }} />

                                    <PreferencesInlineFormDesktop
                                        userid={userLoggedIn.id}
                                        title="Currency"
                                        field_id={currency_field_id}
                                        item={
                                            currency_user_preferences_data_desktop[
                                                current_user_currency_id
                                            ]
                                        }
                                        ids_list={
                                            currency_user_preferences_list_desktop
                                        }
                                        data={
                                            currency_user_preferences_data_desktop
                                        }
                                        submitFunc={(
                                            p: IUSerGeneralInfoUpdatePayload
                                        ) => {
                                            dispatch(
                                                usersActions.updateUserGeneralInfoRequest(
                                                    p
                                                )
                                            )
                                        }}
                                    />
                                </ColWrapperUnit>
                                <div style={{ marginTop: '10px' }} />
                                <SectionHeader
                                    title={'Garage'}
                                    removeEdit={true}
                                />

                                <PreferencesInlineFormDesktop
                                    userid={userLoggedIn.id}
                                    title="Default view"
                                    field_id={garage_user_preferences_field_id}
                                    item={
                                        garage_user_preferences_data_desktop[
                                            current_garage_preferences_id
                                        ]
                                    }
                                    ids_list={
                                        garage_user_preferences_list_desktop
                                    }
                                    data={garage_user_preferences_data_desktop}
                                    submitFunc={(
                                        p: IUSerGeneralInfoUpdatePayload
                                    ) => {
                                        dispatch(
                                            usersActions.updateUserGeneralInfoRequest(
                                                p
                                            )
                                        )
                                    }}
                                />

                                <div style={{ marginTop: '10px' }} />
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <InfoBarMobile>
                                Please tap the field you wish to edit
                            </InfoBarMobile>
                            <MobileSettingsSectionHeader title="Notifications" />

                            <div style={{ marginTop: '20px' }}>
                                <ListItemMobile
                                    item={{
                                        id: time_zone_user_preferences_field_id,
                                        text: 'Time-zone*',
                                        answer: current_user_time_zone_id
                                            ? current_user_time_zone_id
                                            : undefined,
                                    }}
                                    toggle={() =>
                                        toggleAccountExternalInfoEditForm({
                                            id: time_zone_user_preferences_field_id,
                                            text: '',
                                            answer: '',
                                        })
                                    }
                                    height="80px"
                                    fontStyle="Lato"
                                />
                                <div style={{ height: '10px' }} />
                                <ListItemMobile
                                    item={{
                                        id: notification_hour_user_preferences_field_id,
                                        text: 'Notification hour*',
                                        answer:
                                            notification_hour_data_desktop &&
                                            current_user_notification_hour_id &&
                                            notification_hour_data_desktop[
                                                current_user_notification_hour_id
                                            ].text,
                                    }}
                                    toggle={() =>
                                        toggleAccountExternalInfoEditForm({
                                            id: notification_hour_user_preferences_field_id,
                                            text: '',
                                            answer: '',
                                        })
                                    }
                                    height="80px"
                                    fontStyle="Lato"
                                />
                            </div>
                            <MobileSettingsSectionHeader title="units" />

                            <div style={{ marginTop: '20px' }}>
                                <ListItemMobile
                                    item={{
                                        id: fuel_unit_field_id,
                                        text: 'Capacity units*',
                                        answer: fuel_unit_user_preferences_data_desktop[
                                            current_fuel_unit_id
                                        ].text,
                                    }}
                                    toggle={() =>
                                        toggleAccountExternalInfoEditForm({
                                            id: fuel_unit_field_id,
                                            text: '',
                                            answer: '',
                                        })
                                    }
                                    height="80px"
                                    fontStyle="Lato"
                                />
                            </div>
                            <div style={{ height: '10px' }} />
                            <ListItemMobile
                                item={{
                                    id: distance_unit_field_id,
                                    text: 'Distance units*',
                                    answer: distance_unit_user_preferences_data_desktop[
                                        current_distance_unit_id
                                    ].text,
                                }}
                                minHeight="80px"
                                toggle={() =>
                                    toggleAccountExternalInfoEditForm({
                                        id: distance_unit_field_id,
                                        text: '',
                                        answer: '',
                                    })
                                }
                                fontStyle="Lato"
                            />

                            <div style={{ height: '10px' }} />
                            <ListItemMobile
                                item={{
                                    id: currency_field_id,
                                    text: 'Currency*',
                                    answer: currency_user_preferences_data_desktop[
                                        current_user_currency_id
                                    ].text,
                                }}
                                minHeight="80px"
                                toggle={() =>
                                    toggleAccountExternalInfoEditForm({
                                        id: currency_field_id,
                                        text: '',
                                        answer: '',
                                    })
                                }
                                fontStyle="Lato"
                            />

                            <MobileSettingsSectionHeader title="garage" />

                            <div style={{ marginTop: '20px' }}>
                                <ListItemMobile
                                    item={{
                                        id: garage_user_preferences_field_id,
                                        text: 'Default view*',
                                        answer: garage_user_preferences_data_desktop[
                                            current_garage_preferences_id
                                        ].text,
                                    }}
                                    toggle={() =>
                                        toggleAccountExternalInfoEditForm({
                                            id: 'garage_default_view',
                                            text: 'Grid',
                                            answer: 'grid',
                                        })
                                    }
                                    height="80px"
                                    fontStyle="Lato"
                                />
                            </div>

                            {/* Notifications data to be adapted*/}

                            <div style={{ height: '80px' }} />

                            {isAccountExternalInfoFormOpened &&
                                accountExternalInfoFormID &&
                                accountExternalInfoFormID === 'fuel_unit' && (
                                    <ModalDisplay
                                        toggle={
                                            toggleAccountExternalInfoEditForm
                                        }
                                        isOpen={true}
                                    >
                                        <PreferencesRadioFormMobile
                                            title="Capacity Units"
                                            item={
                                                fuel_unit_user_preferences_data_desktop[
                                                    current_fuel_unit_id
                                                ]
                                            }
                                            ids_list={
                                                fuel_unit_user_preferences_list_desktop
                                            }
                                            data={
                                                fuel_unit_user_preferences_data_desktop
                                            }
                                            toggle={() => {
                                                toggleAccountExternalInfoEditForm()
                                            }}
                                            handleSubmit={(
                                                p: IUSerGeneralInfoUpdatePayload
                                            ) => {
                                                dispatch(
                                                    usersActions.updateUserGeneralInfoRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            userid={userLoggedIn.id}
                                            field_id={fuel_unit_field_id}
                                        />
                                    </ModalDisplay>
                                )}

                            {isAccountExternalInfoFormOpened &&
                                accountExternalInfoFormID &&
                                accountExternalInfoFormID ===
                                    'garage_default_view' && (
                                    <ModalDisplay
                                        toggle={
                                            toggleAccountExternalInfoEditForm
                                        }
                                        isOpen={true}
                                    >
                                        <PreferencesRadioFormMobile
                                            toggle={() => {
                                                toggleAccountExternalInfoEditForm()
                                            }}
                                            title="Garage view"
                                            item={
                                                garage_user_preferences_data_desktop[
                                                    current_garage_preferences_id
                                                ]
                                            }
                                            ids_list={
                                                garage_user_preferences_list_desktop
                                            }
                                            data={
                                                garage_user_preferences_data_desktop
                                            }
                                            userid={userLoggedIn.id}
                                            field_id={
                                                garage_user_preferences_field_id
                                            }
                                            handleSubmit={(
                                                p: IUSerGeneralInfoUpdatePayload
                                            ) => {
                                                dispatch(
                                                    usersActions.updateUserGeneralInfoRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                        />
                                    </ModalDisplay>
                                )}

                            {isAccountExternalInfoFormOpened &&
                                accountExternalInfoFormID &&
                                accountExternalInfoFormID ===
                                    distance_unit_field_id && (
                                    <ModalDisplay
                                        toggle={
                                            toggleAccountExternalInfoEditForm
                                        }
                                        isOpen={true}
                                    >
                                        <PreferencesRadioFormMobile
                                            title="Distance units"
                                            item={
                                                distance_unit_user_preferences_data_desktop[
                                                    current_distance_unit_id
                                                ]
                                            }
                                            ids_list={
                                                distance_unit_user_preferences_list_desktop
                                            }
                                            data={
                                                distance_unit_user_preferences_data_desktop
                                            }
                                            toggle={() => {
                                                toggleAccountExternalInfoEditForm()
                                            }}
                                            handleSubmit={(
                                                p: IUSerGeneralInfoUpdatePayload
                                            ) => {
                                                dispatch(
                                                    usersActions.updateUserGeneralInfoRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            userid={userLoggedIn.id}
                                            field_id={distance_unit_field_id}
                                        />
                                    </ModalDisplay>
                                )}

                            {isAccountExternalInfoFormOpened &&
                                accountExternalInfoFormID &&
                                accountExternalInfoFormID ===
                                    currency_field_id && (
                                    <ModalDisplay
                                        toggle={
                                            toggleAccountExternalInfoEditForm
                                        }
                                        isOpen={true}
                                    >
                                        <PreferencesRadioFormMobile
                                            title="Currency"
                                            item={
                                                currency_user_preferences_data_desktop[
                                                    current_user_currency_id
                                                ]
                                            }
                                            ids_list={
                                                currency_user_preferences_list_desktop
                                            }
                                            data={
                                                currency_user_preferences_data_desktop
                                            }
                                            toggle={() => {
                                                toggleAccountExternalInfoEditForm()
                                            }}
                                            handleSubmit={(
                                                p: IUSerGeneralInfoUpdatePayload
                                            ) => {
                                                dispatch(
                                                    usersActions.updateUserGeneralInfoRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            userid={userLoggedIn.id}
                                            field_id={currency_field_id}
                                        />
                                    </ModalDisplay>
                                )}

                            {/* time-zone form data to be adapted bellow */}

                            {isAccountExternalInfoFormOpened &&
                                accountExternalInfoFormID &&
                                accountExternalInfoFormID ===
                                    time_zone_user_preferences_field_id && (
                                    <ModalDisplay
                                        toggle={
                                            toggleAccountExternalInfoEditForm
                                        }
                                        isOpen={true}
                                    >
                                        <PreferencesSearchSelectFormMobile
                                            userid={userLoggedIn.id}
                                            title="Timezone"
                                            field_id={
                                                time_zone_user_preferences_field_id
                                            }
                                            item={{
                                                id: current_user_time_zone_id
                                                    ? current_user_time_zone_id
                                                    : '',
                                                text: current_user_time_zone_id
                                                    ? current_user_time_zone_id
                                                    : '',
                                                answer: current_user_time_zone_id
                                                    ? current_user_time_zone_id
                                                    : undefined,
                                            }}
                                            selectedValue={selectedTimezone}
                                            handleSubmit={handleChangeTimeZone}
                                            onCancel={() =>
                                                clearSelectedTimezone()
                                            }
                                            isKeyValue={true}
                                            optionsList={
                                                time_zone_dropdown_list
                                            }
                                            toggle={() => {
                                                toggleAccountExternalInfoEditForm()
                                            }}
                                            onlyShowMatch={false}
                                            standalonePicker={true}
                                            pickerPath={
                                                '/account/preferences/timezone'
                                            }

                                            // onlyShowMatch
                                        />
                                    </ModalDisplay>
                                )}
                            {isAccountExternalInfoFormOpened &&
                                accountExternalInfoFormID &&
                                accountExternalInfoFormID ===
                                    notification_hour_user_preferences_field_id && (
                                    <ModalDisplay
                                        toggle={
                                            toggleAccountExternalInfoEditForm
                                        }
                                        isOpen={true}
                                    >
                                        <PreferencesSelectMobile
                                            title="Notification Hour"
                                            item={
                                                notification_hour_data_desktop &&
                                                current_user_notification_hour_id
                                                    ? notification_hour_data_desktop[
                                                          current_user_notification_hour_id
                                                      ]
                                                    : null
                                            }
                                            ids_list={
                                                notification_hour_user_preference_list_desktop
                                            }
                                            data={
                                                notification_hour_data_desktop
                                            }
                                            toggle={() => {
                                                toggleAccountExternalInfoEditForm()
                                            }}
                                            handleSubmit={(
                                                p: IUSerGeneralInfoUpdatePayload
                                            ) => {
                                                dispatch(
                                                    usersActions.updateUserGeneralInfoRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            userid={userLoggedIn.id}
                                            field_id={
                                                notification_hour_user_preferences_field_id
                                            }
                                        />
                                    </ModalDisplay>
                                )}

                            <div style={{ paddingBottom: '100px' }} />
                        </IpadAndMobileDisplay>
                    </Faded>
                )}
        </CenteredPageWrapper>
    )
}

export default UserPreferencesPage
