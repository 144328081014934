import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../templates/animated/faded'
import TaskInfoTableMobile from '../../molecules/tasks/taskInfoTable/taskInfoTableMobile'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { IList, ITask, IUpdateTaskReq } from 'entityModels'
import TaskHeader from '../../atoms/typography/taskHeader'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import SideSlider from '../../templates/animated/sideSlider'
import TaskCarHeaderDesktop from '../../atoms/typography/taskCarHeaderDesktop'
import TaskInfoTableDesktop from '../../molecules/tasks/taskInfoTable/taskInfoTableDesktop'
import { editFormsActions } from '../../../redux/editForms/reducer'
import Icon from '../../atoms/icons'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import TaskFormManagerDesktop from '../../organisms/editForms/taskFormManager/manager/taskFormManagerDesktop'
import TaskFormManagerMobile from '../../organisms/editForms/taskFormManager/manager/taskFormManagerMobile'
import red_btn_bin from '../../../public/assets/icons/red_btn_bin.svg'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import AddInfoBannerMobile from '../../atoms/Button/addInfoBannerMobile'
import { OOtherTaskOptions } from '../../../ol/ol'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import AdjustableLoader from '../../atoms/loader/adjustableLoader'
import PortalInvisibleWrapper from '../../templates/displays/pageWrappers/portalInvisibleWrapper'
import TaskReminders from '../tasks/taskReminders'
import parse from 'html-react-parser'
import ReturnNavV2 from '../../atoms/header/returnNavV2'
import ThreeDotsIcon from '../../atoms/icons/components/threeDots'
import TasksSubMenuActions from '../../atoms/menu/pageSubMenuActions/tasksSubMenuActions'
import ChevronLeft from '../../atoms/icons/components/chevronLeft'
import colours from '../../../providers/theme/colours'
import AddIcon from '../../atoms/icons/components/addIcon'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { carActions } from '../../../redux/entities/cars/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { tasksActions } from '../../../redux/tasks/reducer'
import { ICar } from '../../../redux/entities/cars/types'
import useThemes from '../../../providers/theme/hooks'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        min-height: 100vh;
        background-color: var(--off-bg-color, #fafafa);
        transform: translateY(-20px);
    }

    @media ${device.mobile} {
    }
`

const PaddingSideMobile = styled.section`
    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }

    @media ${device.ipad} {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }
`

const TaskDateMobile = styled.section`
    color: var(--text-strong, #1a1a1a);
    @media ${device.mobile} {
        width: 100%;
        box-sizing: border-box;
        text-align: right;
        font-size: 12px;
        font-family: Lato-Italic;
    }
`

const WrapperNotesDesktop = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--bg-color, #fff);
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding-right: 100px;
    padding-left: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    p,
    h1,
    h2,
    li {
        color: var(--text-darker, #616161);
    }
`

const WrapperNotesMobile = styled.section`
    width: 100%;
    box-sizing: border-box;
    p,
    h1,
    h2,
    li {
        color: var(--text-darker, #616161);
    }
`

const EditRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
`

const RowTopDesktopBtns = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const CustomReturnDesktop = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding-left: 32px;
    width: 180px;
    height: 52px;

    background-color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 18px;
    border-radius: 10px;

    transition: all 500ms;

    :hover {
        background-color: var(--primary, #5ec3ca);
        transition: all 500ms;
    }
`
const TxtReturn = styled.div`
    color: var(--bg-color, #fff);
`
const CustomDeleteBtnDesktop = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 180px;
    height: 52px;
    background-color: var(--off-bg-color, #fafafa);
    border: 1px solid var(--error, #df6f6f);
    font-family: Lato-Light;
    font-size: 18px;
    border-radius: 10px;
    color: var(--error, #df6f6f) !important;

    transition: all 500ms;
    cursor: pointer;

    :hover {
        background-color: var(--error_08);
        transition: all 500ms;
    }
`

const ThreeDotsContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 18px;
    height: 64px;
    z-index: 6;
`

const GoToBox = styled.div`
    :hover {
        border: 1px solid var(--primary, #5ec3ca);
        transform: scale(1.015);
    }

    width: 100%;
    box-sizing: border-box;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
    color: var(--primary, #5ec3ca);
    background-color: var(--primary_16);
`

const CarSingleTask: React.FC = () => {
    let { theme } = useThemes()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const [isMobileActionsSubMenuOpen, setIsMobileActionsSubMenuOpen] =
        useState(false)
    const [showConfirmDeleteTaskSheet, setShowConfirmDeleteTaskSheet] =
        useState(false)

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const garage_id = useAppSelector(
        (state) => state.user.userLoggedIn?.owns_garage?.uid || null
    )
    const all_tasks = useAppSelector((state) => state.tasks)
    const carsData = useAppSelector((state) => state.entities.carsData)
    const garageData = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    const updateTaskForm = useAppSelector(
        (state) => state.editForms.formsData.updateTaskForm
    )
    const isUpdateTaskFormOpened = updateTaskForm.isOpen

    const item_to_edit = useAppSelector(
        (state) => state.editForms.formsData.updateTaskForm.item
    )
    const tasksLoading = useAppSelector((state) => state.tasks.loading)
    const carLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const garageLoading = useAppSelector(
        (state) => state.entities.garagesData.loading
    )
    const remindersLoading = useAppSelector(
        (state) => state.tasks.isRemindersLoading
    )

    const query_params = new URLSearchParams(location.search)
    const taskid = query_params.get('taskid')

    const task: ITask | undefined =
        taskid && all_tasks.tasks[taskid] ? all_tasks.tasks[taskid] : undefined

    useEffect(() => {
        dispatch(carActions.setCurrentCar(`${carid}`))
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/car/${carid}/tasks/task?taskid=${taskid}`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else if (garage_id) {
            if (
                !garageData ||
                !garageData[garage_id] ||
                !garageData[garage_id].cars
            ) {
                dispatch(carActions.getGarageCarsDataRequest(garage_id))
            }
        }
    }, [userLoggedIn])

    useEffect(() => {
        if (userLoggedIn && taskid) {
            if (!task) {
                dispatch(tasksActions.getTaskByIdRequest(`${taskid}`))
            } else {
                if (taskid && task && task.due && !task.reminders) {
                    dispatch(
                        tasksActions.getTaskRemindersRequest({
                            taskUID: taskid,
                            dueDate: all_tasks.tasks[taskid].due,
                        })
                    )
                }
            }
        }
    }, [userLoggedIn, task, taskid])

    const handleActionsSubMenu = () => {
        setIsMobileActionsSubMenuOpen(!isMobileActionsSubMenuOpen)
    }

    const numberOfCars: number =
        carsData && carsData.cars ? Object.entries(carsData.cars).length : 0

    const generateLinkTitle = (): string[] => {
        let res: string[] = []
        let resFinal: string[] = []

        if (task && task.linkedToCar) {
            task.linkedToCar.forEach((carid: string) => {
                if (carsData && carsData.cars) {
                    carsData.cars[carid] &&
                        carsData.cars[carid].title &&
                        res.push(carsData.cars[carid].title)
                }
            })
            if (res.length > 1 && res.length === numberOfCars) {
                resFinal = ['Every Car']
            } else resFinal = [...res]
        } else {
            resFinal = [OOtherTaskOptions.general_task.name]
        }
        return resFinal
    }

    const user_prefered_timezone: string | null =
        userLoggedIn &&
        userLoggedIn.preferences &&
        userLoggedIn.preferences.data['notifications'] &&
        userLoggedIn.preferences.data['notifications'].data['time_zone'] &&
        userLoggedIn.preferences.data['notifications'].data['time_zone']
            .user_choice_id

    const isDataLoading =
        tasksLoading || carLoading || garageLoading || remindersLoading

    const currentCar: ICar | undefined =
        carsData && carsData.cars && carid && carsData.cars[carid]
            ? carsData.cars[carid]
            : undefined

    useEffect(() => {
        if (currentCar?.archived_on) {
            navigate(`/archives/car/${currentCar.id}/overview`, {
                replace: true,
            })
        }
    }, [currentCar])

    const closeUpdateTaskForm = () => {
        dispatch(editFormsActions.toggleUpdateTaskForm())
        if (
            query_params.get('setduedate') &&
            updateTaskForm?.item?.id === 'due'
        ) {
            query_params.delete('setduedate')
            navigate({
                search: query_params.toString(),
            })
        }
    }

    return (
        <Wrapper>
            <CenteredPageWrapper>
                {userLoggedIn && garage_id && (
                    <Faded>
                        {isDataLoading && (
                            <div
                                style={{
                                    position: 'relative',
                                    height: '50vh',
                                }}
                            >
                                <AdjustableLoader isLoading={isDataLoading} />
                            </div>
                        )}
                        {task && !isDataLoading && (
                            <DesktopDisplayOnly>
                                <RowTopDesktopBtns>
                                    <CustomReturnDesktop
                                        to={`/car/${carid}/tasks`}
                                        state={{
                                            prevPath: location.pathname,
                                        }}
                                    >
                                        <ChevronLeft
                                            color={
                                                colours[theme]
                                                    .background_default
                                            }
                                        />
                                        <div
                                            style={{
                                                paddingLeft: '12px',
                                            }}
                                        />
                                        <TxtReturn
                                            style={{
                                                transform: 'translateY(-1px)',
                                            }}
                                        >
                                            All tasks
                                        </TxtReturn>
                                    </CustomReturnDesktop>
                                    <CustomDeleteBtnDesktop
                                        onClick={() => {
                                            taskid &&
                                                carid &&
                                                dispatch(
                                                    tasksActions.deleteTaskRequest(
                                                        {
                                                            task: task,
                                                            car_id: carid,
                                                        }
                                                    )
                                                )
                                            navigate(`/car/${carid}/tasks`)
                                        }}
                                    >
                                        <img
                                            src={red_btn_bin}
                                            alt="delete"
                                            style={{ height: '16px' }}
                                        />
                                        <div
                                            style={{
                                                paddingLeft: '12px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                transform: 'translateY(-2px)',
                                            }}
                                        >
                                            remove
                                        </div>
                                    </CustomDeleteBtnDesktop>
                                </RowTopDesktopBtns>
                                <div style={{ paddingTop: '80px' }} />
                                <EditRow>
                                    <TaskHeader>{task.title}</TaskHeader>
                                    <div
                                        style={{
                                            paddingLeft: '20px',
                                            transform: 'translateY(-5px)',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                editFormsActions.toggleUpdateTaskForm(
                                                    {
                                                        id: 'title',
                                                        text: 'Edit task title',
                                                        answer: task.title,
                                                    }
                                                )
                                            )
                                        }}
                                    >
                                        <Icon height="20px" icon="edit_pen" />
                                    </div>
                                </EditRow>
                                <div style={{ paddingTop: '10px' }} />
                                <EditRow>
                                    <TaskCarHeaderDesktop
                                        answers={
                                            task.linkedToCar
                                                ? generateLinkTitle()
                                                : [
                                                      OOtherTaskOptions
                                                          .general_task.name,
                                                  ]
                                        }
                                        carids={task.linkedToCar}
                                        garageid={garage_id}
                                    />
                                    <div
                                        style={{
                                            paddingLeft: '10px',
                                            transform: 'translateY(-5px)',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                editFormsActions.toggleUpdateTaskForm(
                                                    {
                                                        id: 'linkedToCar',
                                                        text: 'Update what this Task is applied to:',
                                                        // @ts-ignore
                                                        answer: task.linkedToCar,
                                                    }
                                                )
                                            )
                                        }}
                                    >
                                        <Icon height="20px" icon="edit_pen" />
                                    </div>
                                </EditRow>
                                <div style={{ paddingTop: '60px' }} />
                                <TaskInfoTableDesktop
                                    task={task}
                                    carsData={carsData.cars}
                                    garageId={garage_id}
                                    updateTask={(payload: IUpdateTaskReq) =>
                                        dispatch(
                                            tasksActions.updateTaskRequest(
                                                payload
                                            )
                                        )
                                    }
                                    toggleUpdateTaskForm={(item?: IList) =>
                                        dispatch(
                                            editFormsActions.toggleUpdateTaskForm(
                                                item
                                            )
                                        )
                                    }
                                />
                                <div style={{ paddingTop: '20px' }} />
                                <SectionHeader
                                    title="Notepad"
                                    removeEdit={task.body ? false : true}
                                    openForm={() => {
                                        window.scrollTo(0, 0)
                                        dispatch(
                                            editFormsActions.toggleUpdateTaskForm(
                                                {
                                                    id: 'body',
                                                    text: 'notes',
                                                    answer: task.body,
                                                }
                                            )
                                        )
                                    }}
                                />
                                {task.body ? (
                                    <WrapperNotesDesktop>
                                        <span
                                            style={{
                                                maxWidth: '950px',
                                            }}
                                        >
                                            {task.body ? parse(task.body) : ''}
                                        </span>
                                    </WrapperNotesDesktop>
                                ) : (
                                    <GoToBox
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            dispatch(
                                                editFormsActions.toggleUpdateTaskForm(
                                                    {
                                                        id: 'body',
                                                        text: 'notes',
                                                        answer: task.body,
                                                    }
                                                )
                                            )
                                        }}
                                    >
                                        <AddIcon
                                            colour={colours[theme].primary}
                                        />
                                    </GoToBox>
                                )}
                                {isUpdateTaskFormOpened && task && (
                                    <Faded>
                                        <ModalDisplay
                                            toggle={closeUpdateTaskForm}
                                            isOpen={true}
                                        >
                                            <TaskFormManagerDesktop
                                                item={item_to_edit}
                                                toggle={closeUpdateTaskForm}
                                                task={task}
                                                updateTask={(
                                                    payload: IUpdateTaskReq
                                                ) =>
                                                    dispatch(
                                                        tasksActions.updateTaskRequest(
                                                            payload
                                                        )
                                                    )
                                                }
                                                carsData={carsData.cars}
                                                isEveryCar={
                                                    generateLinkTitle()[0] ===
                                                    'Every Car'
                                                }
                                                timezone={
                                                    user_prefered_timezone
                                                        ? user_prefered_timezone
                                                        : undefined
                                                }
                                            />
                                        </ModalDisplay>
                                    </Faded>
                                )}
                                {query_params.get('reminders') && (
                                    <PortalInvisibleWrapper
                                        onClick={() => {
                                            navigate(location.pathname)
                                        }}
                                        customZindex={5}
                                    >
                                        <TaskReminders />
                                    </PortalInvisibleWrapper>
                                )}
                            </DesktopDisplayOnly>
                        )}
                        <IpadAndMobileDisplay>
                            <Link
                                to={{
                                    pathname: `/car/${carid}/tasks`,
                                }}
                                state={{ prevPath: location.pathname }}
                            >
                                <ReturnNavV2 customText="Go back" />
                            </Link>
                            <ThreeDotsContainer onClick={handleActionsSubMenu}>
                                <ThreeDotsIcon />
                            </ThreeDotsContainer>
                            {task && !isDataLoading && (
                                <SideSlider direction="right" initial="enter">
                                    <PaddingSideMobile>
                                        <TaskHeader
                                            onClick={() => {
                                                dispatch(
                                                    editFormsActions.toggleUpdateTaskForm(
                                                        {
                                                            id: 'title',
                                                            text: 'Edit task title',
                                                            answer: task.title,
                                                        }
                                                    )
                                                )
                                            }}
                                        >
                                            {task.title}
                                        </TaskHeader>
                                        <div
                                            style={{
                                                paddingTop: '38px',
                                            }}
                                        />
                                        <TaskInfoTableMobile
                                            task={task}
                                            carsData={carsData.cars}
                                            garageId={garage_id}
                                            openUpdateTaskForm={(
                                                item?: IList
                                            ) =>
                                                dispatch(
                                                    editFormsActions.toggleUpdateTaskForm(
                                                        item
                                                    )
                                                )
                                            }
                                            updateTask={(
                                                payload: IUpdateTaskReq
                                            ) =>
                                                dispatch(
                                                    tasksActions.updateTaskRequest(
                                                        payload
                                                    )
                                                )
                                            }
                                            isEveryCar={
                                                generateLinkTitle()[0] ===
                                                'Every Car'
                                            }
                                        />
                                        <SectionHeader
                                            title="Notepad"
                                            openForm={() => {
                                                dispatch(
                                                    editFormsActions.toggleUpdateTaskForm(
                                                        {
                                                            id: 'body',
                                                            text: 'notes',
                                                            answer: task.body,
                                                        }
                                                    )
                                                )
                                            }}
                                        />
                                        {!task.body && (
                                            <div
                                                style={{
                                                    transform:
                                                        'translateY(-20px)',
                                                    paddingBottom: '10px',
                                                }}
                                            >
                                                <AddInfoBannerMobile
                                                    onClick={() => {
                                                        dispatch(
                                                            editFormsActions.toggleUpdateTaskForm(
                                                                {
                                                                    id: 'body',
                                                                    text: 'notes',
                                                                    answer: task.body,
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    height="90px"
                                                    action="add"
                                                />
                                            </div>
                                        )}
                                        <WrapperNotesMobile>
                                            {task.body && parse(task.body)}
                                            {task.body && (
                                                <div
                                                    style={{
                                                        paddingTop: '20px',
                                                    }}
                                                />
                                            )}
                                        </WrapperNotesMobile>
                                        <TaskDateMobile>
                                            created on{' '}
                                            {fromIsoStringToDDMMYYY(
                                                task.created_at
                                            )}
                                        </TaskDateMobile>
                                        <div
                                            style={{
                                                paddingTop: '50px',
                                            }}
                                        />
                                        <TasksSubMenuActions
                                            active_item=""
                                            items={[
                                                {
                                                    id: 'delete',
                                                    text: 'Delete task',
                                                    action: () =>
                                                        setShowConfirmDeleteTaskSheet(
                                                            true
                                                        ),
                                                },
                                            ]}
                                            toggle={handleActionsSubMenu}
                                            isOpen={isMobileActionsSubMenuOpen}
                                        />
                                    </PaddingSideMobile>
                                    <DeletionConfirmationBottomSheet
                                        isOpen={showConfirmDeleteTaskSheet}
                                        toggle={() =>
                                            setShowConfirmDeleteTaskSheet(false)
                                        }
                                        action={() => {
                                            dispatch(
                                                tasksActions.deleteTaskRequest({
                                                    task: task,
                                                    car_id: carid,
                                                })
                                            )
                                            navigate(`/car/${carid}/tasks`)
                                        }}
                                        actionCopy="Confirm deletion"
                                        titleCopy="Are you sure?"
                                        detailsCopy="On deletion, the data will be lost."
                                    />
                                </SideSlider>
                            )}
                            {isUpdateTaskFormOpened && task && (
                                <Faded>
                                    <ModalDisplay
                                        toggle={closeUpdateTaskForm}
                                        isOpen={true}
                                        customZindex={10}
                                    >
                                        <TaskFormManagerMobile
                                            item={item_to_edit}
                                            toggle={closeUpdateTaskForm}
                                            task={task}
                                            updateTask={(
                                                payload: IUpdateTaskReq
                                            ) =>
                                                dispatch(
                                                    tasksActions.updateTaskRequest(
                                                        payload
                                                    )
                                                )
                                            }
                                            carsData={carsData.cars}
                                            isEveryCar={
                                                generateLinkTitle()[0] ===
                                                'Every Car'
                                            }
                                            timezone={
                                                user_prefered_timezone
                                                    ? user_prefered_timezone
                                                    : undefined
                                            }
                                        />
                                    </ModalDisplay>
                                </Faded>
                            )}
                        </IpadAndMobileDisplay>
                    </Faded>
                )}
            </CenteredPageWrapper>
        </Wrapper>
    )
}

export default CarSingleTask
