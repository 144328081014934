import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BubbleCardWithHorizontalLine from '../../atoms/bubbleCards/bubbleCardWithHorizontalLine'
import TimelineItemBodyDesktop from './timelineItemBodyDesktop'
import BubbleCard from '../../atoms/bubbleCards/bubbleCard'
import { useAppSelector } from '../../../redux/store/hooks'
import { ITimelineItem } from '../../../redux/timeline/types'
import { INormalisedAttachmentsByID } from '../../../redux/attachments/types'

interface Props {
    item: ITimelineItem
    onClick?: () => void
    dataCyId?: string
    attachmentsObj?: INormalisedAttachmentsByID
    lineOnRight?: boolean
    lineOnLeft?: boolean
    isCarOverview?: boolean
    hasTootip?: boolean
    userCurrency: string | undefined
    hasSampleData?: boolean
    isFirst?: boolean
    isLast?: boolean
}

const TimelineItemDesktop: React.FC<Props> = ({
    item,
    dataCyId,
    onClick,
    lineOnRight,
    lineOnLeft,
    userCurrency,
    hasSampleData,
    isCarOverview,
    isFirst,
    isLast,
}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const currentCarID = useAppSelector(
        (state) => state.entities.carsData.currentCarID
    )

    return (
        <div
            style={{
                display: 'flex',
                minWidth: 0,
                flex: isCarOverview ? 1 : undefined,
            }}
        >
            {lineOnRight ? (
                <BubbleCard
                    bubleSide="left"
                    category={item.categoryID}
                    hasSampleData={hasSampleData}
                    key={`${item.id}-entry`}
                    dataCyId={dataCyId}
                    onClick={() => {
                        if (isFirst && hasSampleData) {
                            navigate(
                                {
                                    pathname: `/journey/history-file`,
                                    search: `?step=welcome&carid=${currentCarID}`,
                                },
                                {
                                    state: {
                                        prevPath: `${location.pathname}${
                                            location.search ?? ''
                                        }`,
                                    },
                                }
                            )
                        } else {
                            onClick && onClick()
                        }
                    }}
                >
                    <TimelineItemBodyDesktop
                        item={item}
                        key={item?.id}
                        onLeft
                        isFirst={isFirst}
                        userCurrency={userCurrency}
                        hasSampleData={hasSampleData}
                    />
                </BubbleCard>
            ) : lineOnLeft ? (
                <BubbleCard
                    bubleSide="right"
                    category={item.categoryID}
                    hasSampleData={hasSampleData}
                    key={`${item.id}-entry`}
                    dataCyId={dataCyId}
                    onClick={() => {
                        if (hasSampleData) {
                            navigate(`/car/${currentCarID}/history-file/create`)
                        } else if (onClick) {
                            onClick()
                        }
                    }}
                >
                    <TimelineItemBodyDesktop
                        item={item}
                        key={item?.id}
                        onRight
                        userCurrency={userCurrency}
                        hasSampleData={hasSampleData}
                    />
                </BubbleCard>
            ) : (
                <BubbleCardWithHorizontalLine
                    key={`${item.id}-entry`}
                    dataCyId={dataCyId}
                    category={item.categoryID}
                    onClick={() => {
                        if (hasSampleData) {
                            navigate(`/car/${currentCarID}/history-file/create`)
                        } else if (onClick) {
                            onClick()
                        }
                    }}
                    isFirst={isFirst}
                    isLast={isLast}
                >
                    <TimelineItemBodyDesktop
                        item={item}
                        key={item?.id}
                        onRight
                        isFirst={isFirst}
                        isCarOverview
                        userCurrency={userCurrency}
                        hasSampleData={hasSampleData}
                    />
                </BubbleCardWithHorizontalLine>
            )}
        </div>
    )
}

export default TimelineItemDesktop
