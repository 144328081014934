import * as Yup from 'yup'
export const RangeFieldsValidationSchema = Yup.object().shape({
    min: Yup.number()
        .positive()
        // .required('Both fields are required')
        .min(0, 'Please add a positive number'),
    max: Yup.number()
        .positive()
        // .required('Both fields are required')
        .when('min', ([min], schema) =>
            schema.min(min, 'Min value cannot be greater than max value.')
        ),
})
