import styled from 'styled-components'
import SwitchButton from '../../atoms/Button/switchButton'
import CheckboxRowItem from '../../atoms/formField/checkboxRowItem'
import CustomAnimatedCheckboxField from '../../atoms/formField/customAnimatedCheckboxField'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import React from 'react'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border: 1px solid var(--border-muted, #e5e5e5);
    padding: 16px;
    border-radius: 4px;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-bold;
    font-size: 16px;
`

const Line = styled.div`
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 16px;
    margin-bottom: 16px;
`

// const Switch = styled.div``

const CheckboxesWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    grid-auto-rows: auto;
    grid-row-gap: 10px;
`

const TwoCheckboxesCol = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    grid-auto-rows: auto;
    grid-row-gap: 10px;
`

type IOptionData = {
    id: string | number
    name: string
}

type Props = {
    title: string
    isOn: boolean
    toggleSwitch: () => any
    selectedIDs: (string | number)[]
    option_data: IOptionData[]
    onOptionClick: (s: string) => any
    isAllSelected?: boolean
}

const SelectionSectionWithToggle = (props: Props) => {
    const { theme } = useThemes()
    const renderCheckBoxes = () => {
        return props.option_data.map((item: IOptionData, i) => {
            return (
                <React.Fragment
                    key={`${item.id}_${i}_select_with_toggle_desktop`}
                >
                    <CustomAnimatedCheckboxField
                        width={'fit-content'}
                        onChange={() => {
                            props.onOptionClick(`${item.id}`)
                        }}
                        checked={
                            props.isAllSelected === true
                                ? true
                                : props.selectedIDs.includes(item.id)
                        }
                        key={`desktop-${item.id}`}
                        isDisabled={false}
                    >
                        <span
                            style={{
                                color: colours[theme].text_default,
                                fontSize: '14px',
                                letterSpacing: '5e-05em',
                                fontFamily: 'Lato',
                                paddingLeft: '5px',
                            }}
                        >
                            {item.name}
                        </span>
                    </CustomAnimatedCheckboxField>
                </React.Fragment>
            )
        })
    }
    return (
        <Container>
            <Row>
                <Title>{props.title}</Title>
                <SwitchButton
                    toggle={() => {
                        props.toggleSwitch()
                    }}
                    checked={props.isOn}
                />
            </Row>

            <Line />

            <DesktopDisplayOnly>
                {props.option_data.length > 2 ? (
                    <CheckboxesWrapper>{renderCheckBoxes()}</CheckboxesWrapper>
                ) : (
                    <TwoCheckboxesCol>{renderCheckBoxes()}</TwoCheckboxesCol>
                )}
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                {props.option_data.map((item: IOptionData, i) => {
                    return (
                        <React.Fragment
                            key={`${item.id}_${i}_select_with_toggle_mobile`}
                        >
                            <CheckboxRowItem
                                name={
                                    item.id !== undefined
                                        ? `${item.id}`
                                        : undefined
                                }
                                text={item.name}
                                onChange={() => {
                                    props.onOptionClick(`${item.id}`)
                                }}
                                key={`mobile-${item.id}`}
                                checked={
                                    props.isAllSelected === true
                                        ? true
                                        : props.selectedIDs.includes(item.id)
                                }
                            />
                        </React.Fragment>
                    )
                })}
            </IpadAndMobileDisplay>
        </Container>
    )
}

export default SelectionSectionWithToggle
