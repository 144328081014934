import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import InputField from '../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import { IDropdownItem } from 'entityModels'
import SwitchButton from '../../../atoms/Button/switchButton'
import ForwardArrowIcon from '../../../../public/assets/icons/chevron_black_to_right.svg'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import Expander from '../../../atoms/expander/expander'
import { ErrorText } from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { IExternalCarDataRequestPayloadVersion2 } from '../../../../redux/entities/cars/types'

type IStyle = {
    $theme: ITheme
    $isActive?: boolean | undefined
    $noValue?: boolean
    disabled?: boolean
}

const EditFormContainerMobile = styled.section<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-top: 7vh;
    height: 100vh;
    overflow-y: auto;
    background-color: ${(props) => colours[props.$theme].background_default};
    color: black;
    position: relative;
    animation: fadeIn 0.2s both;
    animation-delay: 0.15s;
    opacity: 1;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @media ${device.mobile} {
        padding-top: 5vh;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    box-sizing: border-box;

    @media ${device.ipad} {
        max-width: 600px;
    }
`

const FormTitle = styled.div<IStyle>`
    font-family: Lato;
    align-self: start;
    font-size: 18px;
    letter-spacing: -1px;
    color: ${(props) => colours[props.$theme].text_strong};
    padding-bottom: 20px;

    @media screen and (max-width: 325px) {
        padding-bottom: 15px;
        font-size: 18px;
    }
`

const FieldDescription = styled.div<IStyle>`
    padding-top: 0px;
    color: ${(props) => colours[props.$theme].text_darker};
    font-size: 14px;
    font-family: 'Lato-Light';
    padding-right: 20px;
    line-height: 22px;
`

const SmallPadding = styled.div`
    padding-top: 10px;
`

const LargePadding = styled.div`
    padding-top: 50px;
`

const ButtonsWrapper = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    padding-top: 32px;
    bottom: env(safe-area-inset-bottom);
    border-top: 1px solid ${(props) => colours[props.$theme].border_muted};
    padding: 24px;

    @media ${device.ipad} {
        max-width: 600px;
        bottom: 96px;
    }

    @media (max-height: 568px) {
        position: relative;
    }
`

const ToggleText = styled.div<IStyle>`
    padding-left: 12px;
    text-transform: capitalize;
    font-family: lato;
    font-size: 14px;
    letter-spacing: 0px;
    transform: translateY(-4px);
    color: ${(props) =>
        props.$isActive
            ? colours[props.$theme].primary
            : colours[props.$theme].text_muted};
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
`

const PickerButton = styled.button<IStyle>`
    width: 100%;
    box-sizing: border-box;
    background-color: ${(props) => colours[props.$theme].background_default};
    border-radius: 4px;
    position: relative;

    ${(props) => `
        border: ${
            props.disabled
                ? 'none'
                : props.$noValue
                ? `1px solid ${colours[props.$theme].primary}`
                : `1px solid ${colours[props.$theme].text_muted}`
        }
    `}
`

const PickerButtonContents = styled.div<IStyle>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 12px;
    color: ${(props) => colours[props.$theme].text_strong};

    ${(props) => `
        opacity: ${props.disabled ? 0.5 : 1}
    `}
`

const PickerButtonAdornmentIcon = styled.img`
    margin-left: 4px;
`

const PickerLabel = styled.label<IStyle>`
    position: absolute;
    top: -16px;
    left: 8px;
    padding: 8px;
    background-color: ${(props) => colours[props.$theme].background_default};
    color: ${(props) => colours[props.$theme].text_darker};
`

interface Values {
    registration_number?: string
    country?: string
    hasNoRegistrationNumber?: boolean
}

interface OtherProps {
    close: () => void
    garage_id: string
    countriesdropdownlist: IDropdownItem[]
    userCountry: string | undefined
    numberOfCarsInGarage: number
    dataCyId: string
    dispatch: any
    navigate: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        close,
        setFieldValue,
        userCountry,
        dataCyId,
        errors,
        handleSubmit,
    } = props

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const selectedCountry = useAppSelector(
        (state) => state.localdata.dropdownData.selectedCountry
    )

    const clearSelectedCountry = () =>
        dispatch(dropDownActions.setSelectedCountry(null))

    let checkIsDisabled = (): boolean => {
        if (values.country === 'United Kingdom') {
            if (
                !values.registration_number &&
                values.hasNoRegistrationNumber !== true
            ) {
                return true
            } else if (
                values.registration_number &&
                values.registration_number.trim().length < 1
            ) {
                return true
            } else return false
        } else if (!values.country) {
            return true
        } else return false
    }

    const handleCancel = () => {
        close()
        clearSelectedCountry()
    }

    const { theme } = useThemes()

    return (
        <EditFormContainerMobile data-attr={dataCyId} $theme={theme}>
            <InnerFormPadding>
                <FormTitle $theme={theme}>Add your car</FormTitle>
                <FieldDescription $theme={theme}>
                    Select the country where the car is registered and enter its
                    registration number.
                    <div style={{ paddingTop: '5px' }} />
                    {values.country === 'United Kingdom' && (
                        <div>
                            If your car is registered in the UK, our DVLA lookup
                            will pull your info automatically.
                        </div>
                    )}
                </FieldDescription>
                <SmallPadding />
                <SmallPadding />
                <SmallPadding />
                <div style={{ paddingTop: '16px' }} />
                <PickerLink
                    $theme={theme}
                    label="Country"
                    onClick={() => {
                        navigate('/garage/country')
                    }}
                >
                    {selectedCountry ?? userCountry ?? 'United Kingdom'}
                </PickerLink>

                <div style={{ paddingTop: '20px' }} />
                <InputField
                    theme={theme}
                    id="registration_number"
                    name="registration_number"
                    placeholder="registration number"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.registration_number}
                    dataCyId={'mainaddacar-form-regnumber-input-mobile'}
                    disabled={values.hasNoRegistrationNumber ? true : false}
                    onEnterKeyPress={handleSubmit}
                />
                <Expander height={errors.registration_number ? 'auto' : 0}>
                    <ErrorText $theme={theme}>
                        {errors.registration_number}
                    </ErrorText>
                </Expander>

                <div style={{ paddingTop: '30px' }} />

                <Row>
                    <SwitchButton
                        checked={
                            values.hasNoRegistrationNumber === true
                                ? true
                                : false
                        }
                        toggle={() => {
                            setFieldValue(
                                'hasNoRegistrationNumber',
                                !values.hasNoRegistrationNumber
                            )
                        }}
                        width={34}
                        circleSize={12}
                        height={18}
                        dataCyId={'mainaddacar-regnumber-toggle-mobile'}
                    />

                    <ToggleText
                        $theme={theme}
                        $isActive={
                            values.hasNoRegistrationNumber === true
                                ? true
                                : false
                        }
                    >
                        No Registration number
                    </ToggleText>
                </Row>
            </InnerFormPadding>
            <LargePadding />

            <ButtonsWrapper $theme={theme}>
                <ButtonsStandardBottomRowMobile
                    cancel={handleCancel}
                    submit={handleSubmit}
                    firstText="cancel"
                    confirmationtext="next"
                    disabled={
                        checkIsDisabled() || errors.registration_number
                            ? true
                            : false
                    }
                    dataCyIdUpdate={'mainaddacar-form-submit-mobile'}
                    height="40px"
                />
            </ButtonsWrapper>
        </EditFormContainerMobile>
    )
}

const AddACarFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        country: props.userCountry ? props.userCountry : 'United Kingdom',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            const regexCheck = /^[a-zA-Z0-9_ ]*$/
            return Yup.object().shape({
                registration_number: Yup.string()
                    .max(20, 'Your answer must be under 20 characters')
                    .min(2)
                    .matches(regexCheck, 'Special characters not allowed'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        let countryy = values.country ? values.country : 'United Kingdom'
        let egistration_number =
            values.registration_number &&
            values.registration_number.trim().length > 1 &&
            values.registration_number.length > 1
                ? values.registration_number.trim()
                : undefined
        if (egistration_number && countryy === 'United Kingdom') {
            let payload: IExternalCarDataRequestPayloadVersion2 = {
                country: values.country ? values.country : 'United Kingdom',
                registration_number: egistration_number,
                isOnboarding: false,
            }

            props.dispatch(
                carActions.getExternalCarDataByRegistrationNumberRequestVersion2(
                    payload
                )
            )
        } else {
            props.navigate(
                `/car/new_car/confirm?from_ves_api=false&country=${countryy}`
            )
        }

        setSubmitting(false)

        let { close } = props

        setTimeout(function () {
            close()
        }, 500)
    },
})(InnerForm)

export default AddACarFormMobile

const PickerLink = ({ children, ...props }: any) => {
    return (
        <PickerButton {...props}>
            <PickerButtonContents
                $theme={props.$theme}
                disabled={props.disabled}
            >
                {children}
                <PickerButtonAdornmentIcon src={ForwardArrowIcon} />
            </PickerButtonContents>
            <PickerLabel $theme={props.$theme}>{props.label}</PickerLabel>
        </PickerButton>
    )
}
