import React from 'react'
import { Select, Spin } from 'antd'
import styled from 'styled-components'
import './antd.css'
import { device } from '../../../../../templates/displays/devices'

const { Option } = Select

const SelectBarContainer = styled.div`
    display: flex;
    position: relative;
    border: 1px solid var(--primary, #5ec3ca);
    border-radius: 2px;
    background-color: transparent;

    .ant-select-selection-item-remove {
        display: none;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

export type Props = {
    fetching: any
    value: any
    data: any
    fetchItem: any
    handleChange: any
    optionsList: any
    item?: any
    width?: string
    isDisabled?: boolean
}

const MultipleSelectMobile: React.FC<Props> = ({
    fetching,
    value,
    data,
    handleChange,
    fetchItem,
    optionsList,
    item,
    isDisabled,
    width,
}) => {
    const mappedData = data
        ? data.map((d: any) => (
              <Option value={d.name} key={d.name} aria-label="UK">
                  <span style={{ marginLeft: '10px' }}>{d.name} </span>
              </Option>
          ))
        : null

    const mappedList = optionsList
        ? optionsList.map((d: any) => (
              <Option value={d.name} key={d.name} aria-label="UK">
                  <span style={{ marginLeft: '10px' }}>{d.name} </span>
              </Option>
          ))
        : null

    return (
        <SelectBarContainer id="formmobileditscrolltechinfomultiple">
            <Select
                mode="multiple"
                showSearch
                value={
                    isDisabled === true ? undefined : value[value.length - 1]
                }
                placeholder={
                    item.answers && !isDisabled
                        ? item.answers
                        : isDisabled
                        ? ''
                        : `Search through ${item && item.text}`
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={fetchItem}
                onChange={handleChange}
                autoClearSearchValue={true}
                getPopupContainer={() =>
                    document.getElementById(
                        'formmobileditscrolltechinfomultiple'
                    ) as HTMLElement
                }
                style={{
                    minWidth: width ? width : '80vw',
                    fontFamily: 'Lato',
                    height: '100%',
                    maxWidth: '80vw',
                }}
                size="large"
                disabled={isDisabled ? isDisabled : false}
            >
                {data.length > 0 ? mappedData : mappedList}
            </Select>
        </SelectBarContainer>
    )
}

export default MultipleSelectMobile
