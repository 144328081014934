import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

import Ellipsis from '../loader/ellipsis'

interface IStyle {
    $theme: ITheme
    width?: string | undefined
    height?: string | undefined
    variant?: 'blue' | 'red' | 'darkGrey'
    fontsize?: string
    disabled?: boolean
    centeredText?: boolean
}

const Button = styled.button<IStyle>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.width ? props.width : '90px')};
    height: ${(props) => (props.height ? props.height : '26px')};
    /* padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 11px;
    padding-right: 11px; */
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.$theme].text_muted}`
            : props.variant === 'red'
            ? `1px solid ${colours[props.$theme].error_50}`
            : props.variant === 'blue'
            ? `1px solid ${colours[props.$theme].primary}`
            : props.variant === 'darkGrey'
            ? `1px solid ${colours[props.$theme].border_neutral_strong}`
            : ''};
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    background-color: ${(props) => colours[props.$theme].background_default};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 2px 4px ${colours[props.$theme].shadow_strongest_09}`};
        transition: all 200ms;
    }
`

const Text = styled.div<IStyle>`
    color: ${(props) =>
        props.disabled
            ? colours[props.$theme].text_muted
            : props.variant === 'red'
            ? colours[props.$theme].error
            : props.variant === 'blue'
            ? colours[props.$theme].primary
            : props.variant === 'darkGrey'
            ? colours[props.$theme].border_neutral_strong
            : ''};
    font-family: 'Lato';
    font-size: ${(props) => (props.fontsize ? props.fontsize : '14px')};
    padding-left: ${(props) => (props.centeredText ? '0px' : '10px')};
    transform: translateY(1px);
`

type Props = {
    onClick: any
    icon?: string
    text: string | undefined
    dataCyId?: string | undefined
    iconwidth?: string | undefined
    buttonwidth?: string | undefined
    buttonheight?: string | undefined
    variant: 'red' | 'blue' | 'darkGrey'
    fontsize?: string
    isDisabled?: boolean
    isDisabledIcon?: string
    iconHeight?: string | undefined
    isBtnLoading?: boolean
    loadingColour?: string
}

const BorderAndIconButton: React.FunctionComponent<Props> = (props: Props) => {
    let {
        onClick,
        icon,
        text,
        iconwidth,
        buttonwidth,
        buttonheight,
        dataCyId,
        variant,
        fontsize,
        isDisabled,
        isDisabledIcon,
        iconHeight,
        isBtnLoading,
        loadingColour,
    } = props

    const { theme } = useThemes()

    return (
        <Button
            width={buttonwidth}
            height={buttonheight}
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            variant={variant}
            disabled={isDisabled}
            $theme={theme}
        >
            {isBtnLoading ? (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 8,
                        paddingRight: 8,
                    }}
                >
                    <Ellipsis size={10} colour={loadingColour} />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            transform:
                                icon === 'thick_red_bin'
                                    ? 'translateY(1px)'
                                    : variant !== 'blue'
                                    ? 'translateY(0.5px)'
                                    : 'translateY(-1.5px)',
                        }}
                    >
                        {isDisabled
                            ? icon && (
                                  <Icon
                                      icon={
                                          isDisabledIcon ? isDisabledIcon : icon
                                      }
                                      width={iconwidth}
                                  />
                              )
                            : icon && (
                                  <Icon
                                      icon={icon}
                                      width={iconwidth}
                                      height={iconHeight}
                                  />
                              )}
                    </div>

                    <Text
                        variant={variant}
                        fontsize={fontsize}
                        disabled={isDisabled}
                        centeredText={!icon}
                        $theme={theme}
                    >
                        {text}
                    </Text>
                </>
            )}
        </Button>
    )
}

export default BorderAndIconButton
