import { IDropdownItem } from 'entityModels'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import {
    IAnyErrorString,
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Car,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { fields_array_insurance_application_car_techinfo } from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import InsuranceQuoteFormGenerator from '../../../../organisms/insuranceQuote/formGenerators'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import { CarParams } from '../../dashboards/insuranceApplicationCarDashboard'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import PortalInvisibleWrapper from '../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceApplicationMobileSearch from '../search/mobileSearch'
import { IRootState } from '../../../../../redux/store'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import RemoveMobileSearchAnchor from '../../../../templates/insurance/removeMobileSearchAnchor'
import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { ICar } from '../../../../../redux/entities/cars/types'
import { carActions } from '../../../../../redux/entities/cars/reducer'
const InsuranceCarTechInfoApplicationFlow = () => {
    const dispatch = useAppDispatch()

    const { carid } = useParams<CarParams>()

    let {
        getUserAndApplicationData,
        setInitialCarTechnicalDropdowns,
        setInitialInsurance_CarTechInfo,
    } = useSetInitialInsurance()

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            (state.insuranceQuoteApplication.submitted_data.vehicles[
                `${carid}`
            ] &&
                state.insuranceQuoteApplication.submitted_data.vehicles[
                    `${carid}`
                ].tech_info) ??
            {}
        )
    })

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            (state.insuranceQuoteApplication.draft.vehicles[`${carid}`] &&
                state.insuranceQuoteApplication.draft.vehicles[`${carid}`]
                    .tech_info) ??
            {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[`${carid}`]
            ?.tech_info_errors
    })

    const entitiesObjArr = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[`${carid}`] &&
            state.insuranceQuoteApplication.draft.vehicles[`${carid}`].entities
            ? state.insuranceQuoteApplication.draft.vehicles[`${carid}`]
                  .entities
            : undefined
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_insurance_car_techInfo({
                carid: `${carid}`,
                id: id,
                answer: answer,
            })
        )

        if (id === 'make') {
            dispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: answer?.name,
                    make_uid: answer?.uid,
                })
            )
            dispatch(dropDownActions.setTempMakeDisplay(answer?.name))
            dispatch(dropDownActions.setTempMakeIDSuccess(answer?.uid))
        }

        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }
    }

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_car_techInfo({
                carid: `${carid}`,
                id: id,
                error: error,
            })
        )
    }

    const validateOnClick = (): boolean => {
        let hasErr2 = false

        if (!data_draft) {
            hasErr2 = true
        }

        if (data_draft) {
            if (
                data_draft['has_vehicle_modifications'] === true ||
                data_draft['has_vehicle_modifications']?.name === 'Yes'
            ) {
                if (
                    !entitiesObjArr?.modifications ||
                    (entitiesObjArr?.modifications &&
                        entitiesObjArr?.modifications.length === 0)
                ) {
                    hasErr2 = true
                    setError(
                        'has_vehicle_modifications',
                        `Please fill in the modification(s) below.`
                    )
                }

                if (
                    entitiesObjArr?.modifications &&
                    entitiesObjArr?.modifications[0]
                ) {
                    const obj = entitiesObjArr?.modifications[0]
                    let entityKeys = Object.keys(obj)
                    if (obj[entityKeys[0]] === undefined) {
                        hasErr2 = true
                        setError(
                            'has_vehicle_modifications',
                            `Please fill in all the modification's fields.`
                        )
                    }
                }

                if (
                    // @ts-ignore
                    entitiesObjArr?.modifications
                ) {
                    // @ts-ignore
                    let arrayOfObjToCheck = [...entitiesObjArr?.modifications]

                    arrayOfObjToCheck.map((obj, i) => {
                        let objTocheck = { ...obj }
                        if (objTocheck[`sentence`] === undefined) {
                            delete objTocheck[`sentence`]
                        }
                        if (objTocheck[`driving_ban`] === undefined) {
                            delete objTocheck[`driving_ban`]
                        }

                        if (
                            Object.values(objTocheck).some(
                                (v) => v === undefined
                            )
                        ) {
                            hasErr2 = true
                            setError(
                                'has_vehicle_modifications',
                                `Please fill in all the modification's fields.`
                            )
                        }
                    })
                }
                if (data_draft['has_modification_adjusted_bhp'] === undefined) {
                    hasErr2 = true
                    setError('has_modification_adjusted_bhp', `Required.`)
                }

                if (
                    data_draft['has_modification_adjusted_bhp'] === true ||
                    data_draft['has_modification_adjusted_bhp']?.name === 'Yes'
                ) {
                    if (data_draft['modified_bhp'] === undefined) {
                        hasErr2 = true
                        setError('modified_bhp', `Required.`)
                    }
                }
            } else if (
                data_draft['has_vehicle_modifications'] === false ||
                data_draft['has_vehicle_modifications']?.name === 'No'
            ) {
                if (
                    data_draft['is_planned_on_getting_modified'] === undefined
                ) {
                    hasErr2 = true
                    setError('is_planned_on_getting_modified', `Required.`)
                }
            }

            for (
                let i = 0;
                i < fields_array_insurance_application_car_techinfo.length;
                i++
            ) {
                if (
                    fields_array_insurance_application_car_techinfo[i]
                        .is_required === true
                ) {
                    if (
                        data_draft[
                            fields_array_insurance_application_car_techinfo[i]
                                .id
                        ] === undefined ||
                        data_draft[
                            fields_array_insurance_application_car_techinfo[i]
                                .id
                        ] === '' ||
                        data_draft[
                            fields_array_insurance_application_car_techinfo[i]
                                .id
                        ] === null
                    ) {
                        setError(
                            fields_array_insurance_application_car_techinfo[i]
                                .id,
                            'Required field.'
                        )

                        hasErr2 = true
                    }
                }

                if (
                    fields_array_insurance_application_car_techinfo[i]
                        .validation &&
                    data_draft[
                        fields_array_insurance_application_car_techinfo[i].id
                    ] !== undefined
                ) {
                    let is_valid_func =
                        fields_array_insurance_application_car_techinfo[i]
                            .validation
                    let value =
                        data_draft[
                            fields_array_insurance_application_car_techinfo[i]
                                .id
                        ]
                    let error_txt =
                        fields_array_insurance_application_car_techinfo[i]
                            .error_txt

                    if (is_valid_func && is_valid_func(value) !== true) {
                        setError(
                            fields_array_insurance_application_car_techinfo[i]
                                .id,
                            error_txt ?? 'Invalid'
                        )
                        hasErr2 = true
                    }
                }
            }
        }

        return hasErr2
    }

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let car: ICar | undefined = carsData[`${carid}`]

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const { id } = useParams<{ id?: string }>()

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    useEffect(() => {
        id && getUserAndApplicationData(id)
        setInitialInsurance_CarTechInfo(`${carid}`)
        setInitialCarTechnicalDropdowns(`${carid}`)
    }, [userLoggedIn])

    const tempMakeID = useAppSelector((state) => {
        return state.localdata.dropdownData.tempMakeID
    })

    const tempModelID = useAppSelector((state) => {
        return state.localdata.dropdownData.tempModelID
    })

    useEffect(() => {
        if (
            (userLoggedIn && !car) ||
            (car && car.has_limited_garage_info === true)
        ) {
            dispatch(carActions.getCarDataByIdRequest(`${carid}`))
        }

        if (car?.overview?.data?.make?.current_uid && !tempMakeID) {
            dispatch(
                dropDownActions.setTempMakeIDSuccess(
                    car?.overview?.data?.make?.current_uid
                )
            )
        }

        if (car?.overview?.data?.model?.current_uid && !tempModelID) {
            dispatch(
                dropDownActions.setTempModelIDSuccess(
                    car?.overview?.data?.model?.current_uid
                )
            )
        }
    }, [car, userLoggedIn])

    const navigate = useNavigate()

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')
    let entity = queryParams.get('entity')
    let entity_index = queryParams.get('entity_index')

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_carTechInfo_request({
                    carid: `${carid}`,
                })
            )
            navigate(`/insurance/application/${id}/car/${carid}`)
        } else {
            setIsSavedDisabled(true)
        }
    }

    let vehicle: IInsuranceQuoteApplication_Car | undefined =
        applicationData.vehicles[`${carid}`]

    let generateTitle = (): string => {
        if (vehicle && vehicle.tech_info) {
            if (
                vehicle.tech_info?.manufacture_year ||
                vehicle.tech_info?.make?.name ||
                vehicle.tech_info?.model?.name
            ) {
                return `${
                    vehicle.tech_info?.manufacture_year
                        ? `${vehicle.tech_info.manufacture_year.toString()} `
                        : ''
                }${
                    vehicle.tech_info?.make?.name
                        ? `${vehicle.tech_info.make.name} `
                        : ''
                }${
                    vehicle.tech_info?.model?.name
                        ? vehicle.tech_info.model.name
                        : ''
                }`
            } else {
                return 'Untitled car'
            }
        } else return ''
    }

    let isLoadingNewMakeOrModel = useAppSelector((state) => {
        return state.localdata.dropdownData.isLoadingNewMakeOrModel
    })

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: generateTitle(),
                            isCaps: true,
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/car/${carid}`
                                ),
                        },
                        { pageName: 'Technical specifications' },
                    ]}
                    hasFormChanged={hasFormChanged}
                    onSave={saveForm}
                    sectionId="vehicles_to_insure"
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        boxSizing: 'border-box',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    <div
                        style={{
                            minWidth: '448px',
                        }}
                    >
                        <InsuranceQuoteFormGenerator
                            carid={carid}
                            sectionID="vehicles_to_insure"
                            list={
                                fields_array_insurance_application_car_techinfo
                            }
                            onAnswerChange={onAnswerChange}
                            data={data_draft}
                            errors={errors}
                            setError={setError}
                            entitiesObjArr={entitiesObjArr}
                            setEntityArr={(
                                arr: IAnyObject[],
                                fieldID: string,
                                has_error?: {
                                    entityTypeID: string
                                    entityUID: string
                                }
                            ) => {
                                setHasFormChanged(true)

                                if (isSavedDisabled) {
                                    setIsSavedDisabled(false)
                                }
                                setError('has_vehicle_modifications', undefined)

                                dispatch(
                                    insuranceActions.set_entity_arr_car({
                                        carid: `${carid}`,
                                        fieldID: fieldID,
                                        arr: arr,
                                        has_error,
                                    })
                                )
                            }}
                        />
                        <div style={{ paddingTop: '200px' }} />
                    </div>
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() =>
                        navigate(`/insurance/application/${id}/car/${carid}`)
                    }
                    isSaveLoading={isLoadingNewMakeOrModel}
                    sectionId="vehicles_to_insure"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="vehicles_to_insure"
                            urlBack={`/insurance/application/${id}/car/${carid}/tech_info`}
                            carid={carid}
                            draft={data_draft}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                if (field_id && errors && errors[field_id]) {
                                    setError(field_id, undefined)
                                }

                                if (entity !== null && entity_index !== null) {
                                    let roota: IAnyObject[] =
                                        entitiesObjArr &&
                                        // @ts-ignore
                                        entitiesObjArr[`${entity}`]
                                            ? [
                                                  // @ts-ignore
                                                  ...entitiesObjArr[
                                                      `${entity}`
                                                  ],
                                              ]
                                            : []

                                    roota[+entity_index] = {
                                        ...roota[+entity_index],
                                        [`${field_id}`]: value,
                                    }

                                    dispatch(
                                        insuranceActions.set_entity_arr_car({
                                            arr: [...roota],
                                            fieldID: entity,
                                            carid: `${carid}`,
                                        })
                                    )
                                } else
                                    dispatch(
                                        insuranceActions.set_answer_insurance_car_techInfo(
                                            {
                                                id: `${field_id}`,
                                                answer: value,
                                                carid: `${carid}`,
                                            }
                                        )
                                    )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <InsuranceTopBarWithProgressionRateMobile
                            breadCrumbs={[
                                {
                                    pageName: generateTitle(),
                                    isCaps: true,
                                    pageUrl: () =>
                                        navigate(
                                            `/insurance/application/${id}/car/${carid}`
                                        ),
                                },
                                { pageName: 'Technical specifications' },
                            ]}
                            hasFormChanged={hasFormChanged}
                            onSave={saveForm}
                            sectionId="vehicles_to_insure"
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                boxSizing: 'border-box',
                                paddingTop: 120,
                                paddingBottom: 120,
                                paddingLeft: 16,
                                paddingRight: 16,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                carid={carid}
                                sectionID="vehicles_to_insure"
                                list={
                                    fields_array_insurance_application_car_techinfo
                                }
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                                entitiesObjArr={entitiesObjArr}
                                setEntityArr={(
                                    arr: IAnyObject[],
                                    fieldID: string,
                                    has_error?: {
                                        entityTypeID: string
                                        entityUID: string
                                    }
                                ) => {
                                    setHasFormChanged(true)
                                    setError(
                                        'has_vehicle_modifications',
                                        undefined
                                    )
                                    if (isSavedDisabled) {
                                        setIsSavedDisabled(false)
                                    }

                                    dispatch(
                                        insuranceActions.set_entity_arr_car({
                                            carid: `${carid}`,
                                            fieldID: fieldID,
                                            arr: arr,
                                            has_error,
                                        })
                                    )
                                }}
                                mobileSearchPickerSectionURL={`/insurance/application/${id}/car/${carid}/tech_info`}
                            />
                            <div style={{ paddingTop: '200px' }} />
                        </div>
                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() =>
                                navigate(
                                    `/insurance/application/${id}/car/${carid}`
                                )
                            }
                            isSaveLoading={isLoadingNewMakeOrModel}
                            sectionId="vehicles_to_insure"
                        />
                    </>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceCarTechInfoApplicationFlow
