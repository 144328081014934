import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { showroomActions } from '../reducer'
import { IShowroomEntry } from '../types'
import { state_select_normalised_entries } from './removeEntryFromWatchlist'
import { GetShowroomEntryByID } from './getShowroomEntry'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'

export function* AddEntryToWatchlist(entryUID: string): any {
    try {
        yield call(api.showroom.addToWatchlist, entryUID)
        yield put(showroomActions.addEntryToWatchlistSuccess(entryUID))

        let normalised_entries = yield select(state_select_normalised_entries)

        let entry_normalised: IShowroomEntry | undefined =
            normalised_entries && normalised_entries[entryUID]
                ? normalised_entries[entryUID]
                : undefined

        if (!entry_normalised) {
            yield call(GetShowroomEntryByID, {
                is_owner: false,
                entryID: entryUID,
            })
        }

        // here, pass the results and thats it
        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: AddEntryToWatchlist,
                payload: entryUID,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(showroomActions.addEntryToWatchlistError(customError))
        }
    }
}

function* watcherAddEntryToWatchlist() {
    while (true) {
        const { payload } = yield take(
            showroomActions.addEntryToWatchlistRequest
        )
        yield call(AddEntryToWatchlist, payload)
    }
}

const add_watchlist_entry: any[] = [fork(watcherAddEntryToWatchlist)]

export default add_watchlist_entry
