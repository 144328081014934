import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { api } from '../../../services'
import { ICarouselShowroomItem } from '../../../services/showroom/getCarousel'

import { call, fork, put, take } from 'redux-saga/effects'
import { showroomLocalDataActions } from '../index'
import { IReduxError } from '../../../entities/cars/types'

export type IgetShowroomSlides_action_payload = {
    slides?: ICarouselShowroomItem[]
    error?: IReduxError
}

export function* GetShowroomSlides_action_saga(): any {
    try {
        let slides = yield call(api.showroom.getShowroomCarouselEntries)
        yield put(showroomLocalDataActions.getShowroomSlides_success(slides))
    } catch (error: any) {
        let customError: IReduxError = ConvertToReduxError(error)
        yield put(showroomLocalDataActions.getShowroomSlides_error(customError))
    }
}

function* watcherGetShowroomSlides() {
    while (true) {
        yield take(showroomLocalDataActions.getShowroomSlides_request)
        yield call(GetShowroomSlides_action_saga)
    }
}

const getShowroomSlides_action: any[] = [fork(watcherGetShowroomSlides)]

export default getShowroomSlides_action
