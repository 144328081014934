import { call, fork, put, select, take } from 'redux-saga/effects'
import { AddCarTimelineSaga } from './addCarTimeline'
import { AddCarTimelineEntryCostSaga } from './addCarTimelineEntryCost'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'

import {
    // AddAttachmentToCostSaga,
    IAddAttachmentToCostSaga,
} from './addAttachmentToCost'

import { ManageAddAttachmentsToEntrySaga } from './manageAddAttachmentsToEntry'
import { IManageAddAttachmentsToEntryRequest } from '../actions/requestActions'
import { timelineActions } from '../reducer'
import { IRootState } from '../../store'
import {
    IActionPayloadTimelineCreationRequest,
    IActionPayloadTimelineCreationSuccess,
    IAddCostItemPayloadRequest,
    IAddTimelineItemPayloadRequest,
    ICostItem,
    ITimelineItem,
} from '../types'
import { IReduxError } from '../../entities/cars/types'

const getTimelineCreationStore = (state: IRootState) =>
    state.timeline.formsData.creation

const getActiveEntryId = (state: IRootState) =>
    state.timeline.activeEntry && state.timeline.activeEntry.id

function* ManageDataToBeSubmittedTimelineCreationSaga(
    requestPayload: IActionPayloadTimelineCreationRequest
): any {
    try {
        let timelineCreationState = yield select(getTimelineCreationStore)

        let obj: any = {}

        if (
            requestPayload.fieldID !== null &&
            requestPayload.fieldID !== undefined &&
            requestPayload.entityType !== null &&
            requestPayload.entityType !== undefined
        ) {
            if (requestPayload.entityType === 'entry') {
                if (requestPayload.action === 'CREATE') {
                    let new_entry: ITimelineItem = {
                        id: 'NEW_entryUID',
                    }

                    new_entry[requestPayload.fieldID] =
                        requestPayload.fieldValue
                    obj = { ...new_entry }
                }

                if (requestPayload.action === 'UPDATE') {
                    let entry_data: ITimelineItem | undefined =
                        timelineCreationState.data_to_be_submitted['entry']

                    if (entry_data) {
                        let mutableEntry = { ...entry_data }
                        mutableEntry[requestPayload.fieldID] =
                            requestPayload.fieldValue
                        obj = { ...mutableEntry }
                    }
                }
            }

            if (requestPayload.entityType === 'cost') {
                if (requestPayload.action === 'CREATE') {
                    let cost_data: ITimelineItem | undefined =
                        timelineCreationState.data_to_be_submitted['cost']

                    let mutableCost = cost_data
                        ? { ...cost_data }
                        : {
                              id: 'NEW_costUID',
                              name: '',
                              amount: 0,
                          }
                    // @ts-ignore
                    mutableCost[requestPayload.fieldID] =
                        requestPayload.fieldValue
                    obj = { ...mutableCost }
                }

                if (requestPayload.action === 'UPDATE') {
                    let cost_data: ITimelineItem | undefined =
                        timelineCreationState.data_to_be_submitted['cost']

                    if (cost_data) {
                        let mutableCost = { ...cost_data }
                        mutableCost[requestPayload.fieldID] =
                            requestPayload.fieldValue
                        obj = { ...mutableCost }
                    }
                }
            }

            let typedObj: ITimelineItem | ICostItem = { ...obj }

            let succesPayload: IActionPayloadTimelineCreationSuccess = {
                entityType: requestPayload.entityType,
                entityObj: typedObj,
            }

            yield put(
                timelineActions.manageDataToBeSubmittedTimelineCreationSuccess(
                    succesPayload
                )
            )
        }

        if (requestPayload.isReadyToSendToApi && requestPayload.carid) {
            let carid: string = requestPayload.carid

            if (timelineCreationState.data_to_be_submitted.entry) {
                let entryItem: ITimelineItem =
                    timelineCreationState.data_to_be_submitted.entry

                let payloadForApiEntry: IAddTimelineItemPayloadRequest = {
                    carid: carid,
                    item: entryItem,
                    goToEntryPage:
                        timelineCreationState.data_to_be_submitted.cost &&
                        timelineCreationState.data_to_be_submitted.cost.name !==
                            '' &&
                        timelineCreationState.data_to_be_submitted.entry
                            .attachment
                            ? false
                            : true,
                }

                let newEntryId = yield call(
                    AddCarTimelineSaga,
                    payloadForApiEntry
                )

                if (entryItem.attachments && entryItem.attachments[0]) {
                    let fp: IManageAddAttachmentsToEntryRequest = {
                        files: entryItem.attachments,
                        carID: payloadForApiEntry.carid,
                        entryID: newEntryId,
                    }
                    yield call(ManageAddAttachmentsToEntrySaga, fp)
                    // yield put(requestActions.addAttachmentsToEntryRequest(fp))
                }

                if (
                    timelineCreationState.data_to_be_submitted.cost &&
                    timelineCreationState.data_to_be_submitted.cost.name !== ''
                ) {
                    let costItem: ICostItem =
                        timelineCreationState.data_to_be_submitted.cost

                    let payloadForApiCost: IAddCostItemPayloadRequest = {
                        carid: carid,
                        itemid: newEntryId,
                        cost: costItem,
                        goToEntryPage: true,
                    }
                    // later : need to add a try catch here

                    yield call(AddCarTimelineEntryCostSaga, payloadForApiCost)

                    yield put(
                        timelineActions.clearDataToBeSubmittedSuccess(true)
                    )
                } else {
                    yield put(
                        timelineActions.clearDataToBeSubmittedSuccess(true)
                    )
                }
            } else if (
                !timelineCreationState.data_to_be_submitted.entry &&
                timelineCreationState.data_to_be_submitted.cost
            ) {
                let costItem: ICostItem =
                    timelineCreationState.data_to_be_submitted.cost

                let newEntryId = yield select(getActiveEntryId)

                let payloadForApiCost = {
                    carid: carid,
                    itemid: newEntryId,
                    cost: costItem,
                    goToEntryPage: true,
                }

                let newCostId = yield call(
                    AddCarTimelineEntryCostSaga,
                    payloadForApiCost
                )

                if (
                    costItem.attachment &&
                    costItem.attachment.filename &&
                    costItem.attachment.type
                ) {
                    let fp: IAddAttachmentToCostSaga = {
                        fileData: costItem.attachment,
                        carID: carid,
                        entryID: newEntryId,
                        costID: newCostId,
                    }
                    // yield call(AddAttachmentToCostSaga, fp)
                    yield put(timelineActions.addAttachmentToCostRequest(fp))

                    // yield put(successActions.setActiveCostSuccess(costItem))
                }

                yield put(timelineActions.clearDataToBeSubmittedSuccess(true))
            }
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: ManageDataToBeSubmittedTimelineCreationSaga,
                payload: requestPayload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let err: IReduxError = ConvertToReduxError(error)
            yield put(timelineActions.addTimelineItemToCarError(err))
        }
    }
}

function* watcherManageDataToBeSubmittedTimelineCreation() {
    while (true) {
        const { payload } = yield take(
            timelineActions.manageDataToBeSubmittedTimelineCreationRequest
        )

        yield call(ManageDataToBeSubmittedTimelineCreationSaga, payload)
    }
}

const manage_data_to_be_submitted_timeline_creation: any[] = [
    fork(watcherManageDataToBeSubmittedTimelineCreation),
]

export default manage_data_to_be_submitted_timeline_creation
