import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { insurance_fields_main_driver_licence_address } from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import InsuranceQuoteFormGenerator from '../../../../organisms/insuranceQuote/formGenerators'
import { InsuranceFormQuestion } from '../../../../organisms/insuranceQuote/formGenerators/renderElementDesktop'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import InsuranceAddressCardV2 from '../../../../molecules/cards/insurance/addressCardDesktopV2'
import { IAddressActiveModal } from '../../../../organisms/insuranceQuote/addressesManager'
import { AddressWindow } from '../../../../organisms/insuranceQuote/addressesManager/addressWindow'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../../redux/store'
import Expander from '../../../../atoms/expander/expander'
import styled from 'styled-components'
// import {
//     isInsuranceApplicationDateValid,
//     IsInsuranceApplicationDateValid_result,
// } from '../../../../../redux/localdata/insuranceLocalData/datesValidation'
import colours, {
    insurance_colours,
} from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import PortalInvisibleWrapper from '../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import RemoveMobileSearchAnchor from '../../../../templates/insurance/removeMobileSearchAnchor'
import InsuranceApplicationMobileSearch from '../search/mobileSearch'
import { IDropdownItem } from 'entityModels'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'

export const Error = styled.div`
    color: red;
    font-style: italic;
    font-size: 11px;
`

export type UserParams = {
    userid: string
}

const InsuranceMainDriverLicenceAddressApplicationFlow = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { userid } = useParams<UserParams>()

    const data: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.main_driver
                ?.licence_address ?? {}
        )
    })

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.main_driver
                ?.licence_address ?? {}
        )
    })

    const errors: IAnyObject | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.main_driver
            ?.licence_address_errors
    })

    const addresses = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.addresses
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_main_driver_licenseAddress({
                id: id,
                answer: answer,
                userid: `${userid}`,
            })
        )
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }
    }

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_main_driver_licenseAddress({
                id: id,
                error: error,
                userid: `${userid}`,
            })
        )
    }

    // const hasErrorFunc = () => {
    //     let hasErr: boolean = false

    //     if (errors) {
    //         for (const [_, value] of Object.entries(errors)) {
    //             if (value !== undefined) {
    //                 hasErr = true
    //             }
    //         }
    //     }

    //     return hasErr
    // }

    // let hasError = hasErrorFunc()

    let {
        getUserAndApplicationData,
        setInitialInsurance_Drivers_License_dropdowns,
    } = useSetInitialInsurance()

    const validateOnClick = (): boolean => {
        let hasErr2 = false

        for (
            let i = 0;
            i < insurance_fields_main_driver_licence_address.length;
            i++
        ) {
            let current_field_type =
                insurance_fields_main_driver_licence_address[i]

            if (current_field_type.is_required === true) {
                if (data[current_field_type.id] === undefined) {
                    setError(current_field_type.id, 'Required field.')

                    hasErr2 = true
                }
            }

            if (
                current_field_type.validation &&
                data[current_field_type.id] !== undefined
            ) {
                let value = data[current_field_type.id]

                if (
                    current_field_type.id === 'license_number' &&
                    data?.license_type?.uid &&
                    data?.license_type?.uid !== 'NON_UK'
                ) {
                    if (errors && errors['license_number']) {
                        hasErr2 = true
                    } else if (
                        data?.license_issue_location &&
                        data.license_issue_location?.name ===
                            'Northern Ireland' &&
                        data.license_number &&
                        data.license_number.length !== 8
                    ) {
                        setError(
                            'license_number',
                            'Invalid. Northern Ireland license number must have 8 characters.'
                        )
                        hasErr2 = true
                    } else if (
                        data?.license_issue_location &&
                        data.license_issue_location?.name === 'Great Britain' &&
                        data.license_number &&
                        data.license_number.length !== 16
                    ) {
                        setError(
                            'license_number',
                            'Invalid. Great Britain license number must have 16 characters.'
                        )
                        hasErr2 = true
                    }
                } else {
                    let is_valid_func = current_field_type.validation

                    let error_txt = current_field_type.error_txt

                    if (is_valid_func && is_valid_func(value) !== true) {
                        setError(current_field_type.id, error_txt ?? 'Invalid')
                        hasErr2 = true
                    }
                }
            }
        }

        // if (data && data['residency_date']) {
        //     let res: IsInsuranceApplicationDateValid_result =
        //         isInsuranceApplicationDateValid({
        //             fieldID: 'residency_date',
        //             application_edited: applicationData,
        //             section: 'license',
        //             valueToCheck: data['residency_date'],
        //             value_of_dependable_field:
        //                 data['is_uk_resident_since_birth'],
        //             expected_value_of_dependable_field_for_validation: false,
        //         })
        //     if (res.isValid === false) {
        //         hasErr2 = true
        //         setError('residency_date', res.error_txt ?? 'Invalid')
        //     }
        // }

        if (data && data['license_date'] && errors && errors[`license_date`]) {
            hasErr2 = true
        }

        if (
            data &&
            data['residency_date'] &&
            errors &&
            errors[`residency_date`]
        ) {
            hasErr2 = true
        }

        return hasErr2
    }

    const { id } = useParams<{ id?: string }>()

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        // below only needed after application process?
        // return () => {
        //     dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        // }
    }, [])

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        id && getUserAndApplicationData(id)
        setInitialInsurance_Drivers_License_dropdowns()
    }, [userLoggedIn])

    let countriesList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    useEffect(() => {
        if (userLoggedIn && !countriesList) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [userLoggedIn])

    const [activeModal, toggleActiveModal] =
        useState<IAddressActiveModal>(undefined)

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_mainDriver_licenceAddresses_request()
            )
            navigate(`/insurance/application/${id}/main_driver/${userid}`)
        } else {
            setIsSavedDisabled(true)
        }
    }

    const saveFormAddress = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_mainDriver_licenceAddresses_request()
            )
        } else {
            setIsSavedDisabled(true)
        }
    }

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: 'Driver',
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/main_driver/${userid}`
                                ),
                        },
                        { pageName: 'Drivers license & address' },
                    ]}
                    hasFormChanged={hasFormChanged}
                    onSave={saveForm}
                    sectionId="main_policy_holder"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        boxSizing: 'border-box',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    <div
                        style={{
                            minWidth: '448px',
                        }}
                    >
                        <InsuranceQuoteFormGenerator
                            list={insurance_fields_main_driver_licence_address}
                            onAnswerChange={onAnswerChange}
                            data={data}
                            errors={errors}
                            setError={setError}
                            sectionID="main_policy_holder"
                            mobileSearchPickerSectionURL={`/insurance/application/${id}/main_driver/${userid}/licence_address`}
                        />

                        <div style={{ paddingTop: '30px' }} />

                        <InsuranceFormQuestion $theme={theme}>
                            Residential address
                        </InsuranceFormQuestion>
                        <div style={{ paddingTop: '16px' }} />
                        {data['residential_address'] ? (
                            <InsuranceAddressCardV2
                                address={addresses[data['residential_address']]}
                                onChange={() => {
                                    toggleActiveModal({
                                        active_modal: 'select',
                                        field_id: 'residential_address',
                                        title: 'Select residential address',
                                    })
                                }}
                                sectionID="main_policy_holder"
                            />
                        ) : (
                            <ButtonAtom
                                theme="tertiary"
                                textTransform="none"
                                width={'100%'}
                                customBgColor="transparent"
                                onClick={() => {
                                    toggleActiveModal({
                                        active_modal: 'select',
                                        field_id: 'residential_address',
                                        title: 'Select residential address',
                                    })
                                }}
                                color={
                                    insurance_colours[theme].sections[
                                        'main_policy_holder'
                                    ].primary_100
                                }
                            >
                                Add address
                            </ButtonAtom>
                        )}
                        <Expander
                            height={
                                errors && errors[`residential_address`]
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <Error style={{ paddingTop: '8px' }}>
                                {errors && errors[`residential_address`]
                                    ? errors[`residential_address`]?.status
                                    : ''}
                            </Error>
                        </Expander>

                        <div style={{ paddingTop: '40px' }} />

                        <InsuranceFormQuestion $theme={theme}>
                            Correspondence address
                        </InsuranceFormQuestion>
                        <div style={{ paddingTop: '16px' }} />
                        {data['correspondence_address'] ? (
                            <InsuranceAddressCardV2
                                address={
                                    addresses[data['correspondence_address']]
                                }
                                onChange={() => {
                                    toggleActiveModal({
                                        active_modal: 'select',
                                        field_id: 'correspondence_address',
                                        title: 'Select correspondence address',
                                    })
                                }}
                                sectionID="main_policy_holder"
                            />
                        ) : (
                            <ButtonAtom
                                theme="tertiary"
                                textTransform="none"
                                width={'100%'}
                                customBgColor="transparent"
                                onClick={() => {
                                    toggleActiveModal({
                                        active_modal: 'select',
                                        field_id: 'correspondence_address',
                                        title: 'Select correspondence address',
                                    })
                                }}
                                color={
                                    insurance_colours[theme].sections[
                                        'main_policy_holder'
                                    ].primary_100
                                }
                            >
                                Add address
                            </ButtonAtom>
                        )}
                        <Expander
                            height={
                                errors && errors[`correspondence_address`]
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <Error style={{ paddingTop: '8px' }}>
                                {errors && errors[`correspondence_address`]
                                    ? errors[`correspondence_address`]?.status
                                    : ''}
                            </Error>
                        </Expander>
                    </div>
                    <div style={{ paddingTop: '200px' }} />
                </div>

                <AddressWindow
                    selectedAddress={
                        activeModal?.field_id === 'residential_address'
                            ? data['residential_address']
                            : activeModal?.field_id === 'correspondence_address'
                            ? data['correspondence_address']
                            : undefined
                    }
                    selectAddress={(addressid: string) => {
                        activeModal?.field_id &&
                            onAnswerChange(activeModal?.field_id, addressid)
                    }}
                    activeModal={activeModal}
                    toggleActiveModal={toggleActiveModal}
                    onSaveForm={saveFormAddress}
                    sectionID="main_policy_holder"
                />

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() =>
                        navigate(
                            `/insurance/application/${id}/main_driver/${userid}`
                        )
                    }
                    sectionId="main_policy_holder"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="main_policy_holder"
                            urlBack={`/insurance/application/${id}/main_driver/${userid}/licence_address`}
                            draft={data}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                if (field_id && errors && errors[field_id]) {
                                    setError(field_id, undefined)
                                }

                                userid &&
                                    dispatch(
                                        insuranceActions.set_answer_main_driver_licenseAddress(
                                            {
                                                id: `${field_id}`,
                                                answer: value,
                                                userid: userid,
                                            }
                                        )
                                    )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <InsuranceTopBarWithProgressionRateMobile
                            breadCrumbs={[
                                {
                                    pageName: 'Driver',
                                    pageUrl: () =>
                                        navigate(
                                            `/insurance/application/${id}/main_driver/${userid}`
                                        ),
                                },
                                { pageName: 'Drivers license & address' },
                            ]}
                            hasFormChanged={hasFormChanged}
                            onSave={saveForm}
                            sectionId="main_policy_holder"
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100vw',
                                paddingTop: 120,
                                paddingBottom: 120,
                                paddingLeft: 16,
                                paddingRight: 16,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                list={
                                    insurance_fields_main_driver_licence_address
                                }
                                onAnswerChange={onAnswerChange}
                                data={data}
                                errors={errors}
                                setError={setError}
                                mobileSearchPickerSectionURL={`/insurance/application/${id}/main_driver/${userid}/licence_address`}
                                sectionID="main_policy_holder"
                            />

                            <div style={{ paddingTop: '30px' }} />

                            <InsuranceFormQuestion $theme={theme}>
                                <div>Residential address</div>
                            </InsuranceFormQuestion>
                            <div style={{ paddingTop: '16px' }} />
                            {data['residential_address'] ? (
                                <InsuranceAddressCardV2
                                    address={
                                        addresses[data['residential_address']]
                                    }
                                    onChange={() => {
                                        toggleActiveModal({
                                            active_modal: 'select',
                                            field_id: 'residential_address',
                                            title: 'Select residential address',
                                        })
                                    }}
                                    sectionID="main_policy_holder"
                                />
                            ) : (
                                <ButtonAtom
                                    theme="tertiary"
                                    textTransform="none"
                                    width={'100%'}
                                    customBgColor="transparent"
                                    onClick={() => {
                                        toggleActiveModal({
                                            active_modal: 'select',
                                            field_id: 'residential_address',
                                            title: 'Select residential address',
                                        })
                                    }}
                                    color={
                                        insurance_colours[theme].sections[
                                            'main_policy_holder'
                                        ].primary_100
                                    }
                                >
                                    Add address
                                </ButtonAtom>
                            )}
                            <Expander
                                height={
                                    errors && errors[`residential_address`]
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <Error style={{ paddingTop: '8px' }}>
                                    {errors && errors[`residential_address`]
                                        ? errors[`residential_address`]?.status
                                        : ''}
                                </Error>
                            </Expander>

                            <div style={{ paddingTop: '40px' }} />

                            <InsuranceFormQuestion $theme={theme}>
                                Correspondence address
                            </InsuranceFormQuestion>
                            <div style={{ paddingTop: '16px' }} />
                            {data['correspondence_address'] ? (
                                <InsuranceAddressCardV2
                                    address={
                                        addresses[
                                            data['correspondence_address']
                                        ]
                                    }
                                    onChange={() => {
                                        toggleActiveModal({
                                            active_modal: 'select',
                                            field_id: 'correspondence_address',
                                            title: 'Select correspondence address',
                                        })
                                    }}
                                    sectionID="main_policy_holder"
                                />
                            ) : (
                                <ButtonAtom
                                    theme="tertiary"
                                    textTransform="none"
                                    width={'100%'}
                                    customBgColor="transparent"
                                    onClick={() => {
                                        toggleActiveModal({
                                            active_modal: 'select',
                                            field_id: 'correspondence_address',
                                            title: 'Select correspondence address',
                                        })
                                    }}
                                    color={
                                        insurance_colours[theme].sections[
                                            'main_policy_holder'
                                        ].primary_100
                                    }
                                >
                                    Add address
                                </ButtonAtom>
                            )}
                            <Expander
                                height={
                                    errors && errors[`correspondence_address`]
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <Error style={{ paddingTop: '8px' }}>
                                    {errors && errors[`correspondence_address`]
                                        ? errors[`correspondence_address`]
                                              ?.status
                                        : ''}
                                </Error>
                            </Expander>

                            <div style={{ paddingTop: '200px' }} />
                        </div>

                        <AddressWindow
                            selectedAddress={
                                activeModal?.field_id === 'residential_address'
                                    ? data['residential_address']
                                    : activeModal?.field_id ===
                                      'correspondence_address'
                                    ? data['correspondence_address']
                                    : undefined
                            }
                            selectAddress={(addressid: string) => {
                                activeModal?.field_id &&
                                    onAnswerChange(
                                        activeModal?.field_id,
                                        addressid
                                    )
                            }}
                            activeModal={activeModal}
                            toggleActiveModal={toggleActiveModal}
                            onSaveForm={saveFormAddress}
                            sectionID="main_policy_holder"
                        />
                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() =>
                                navigate(
                                    `/insurance/application/${id}/main_driver/${userid}`
                                )
                            }
                            sectionId="main_policy_holder"
                        />
                    </>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceMainDriverLicenceAddressApplicationFlow
