import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import DocumentIcon from '../../../atoms/icons/components/document'
import EyeIcon from '../../../atoms/icons/components/eyeIcon'
import { useEffect, useMemo, useRef, useState } from 'react'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import {
    IApplicationInfo,
    IInsuranceStatusEnum,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { useNavigate } from 'react-router-dom'
import CustomTooltip from '../../../atoms/info/CustomTooltip'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import BottomSheetWarning from '../../../atoms/info/bottomSheetWarning'
import { AtdConfWarningBox } from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import { Popconfirm } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import DeletionConfirmationBottomSheet from '../../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import posthog from 'posthog-js'
import useThemes from '../../../../providers/theme/hooks'
import RightSideModalDisplay from '../../../templates/displays/pageWrappers/rightSideModalDisplay'
import PolicyOfferModal from '../../../organisms/insuranceQuote/modalManagers/policyOfferModal'
import { ICarsObject } from '../../../../redux/entities/cars/types'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-radius: 8px;
    background: var(--off-bg-color, #fafafa);
    margin-bottom: 16px;

    @media ${device.mobile_and_ipad} {
        height: fit-content;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
        margin-bottom: 8px;
    }
`

const FlexRowGap16 = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 1200px) {
        gap: 8px;
    }
`

const FlexColumnGap16 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 1200px) {
        width: 96%;
    }
    @media ${device.mobile_and_ipad} {
        gap: 16px;
    }
`

const Title = styled.h3`
    padding: 0;
    margin: 0;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    @media (min-width: 881px) and (max-width: 960px) {
        font-size: 15px;
        line-height: 22x;
    }
`

const Text = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

const TextSemibold = styled.p`
    padding: 0;
    margin: 0;
    font-family: Lato-semibold;
    font-size: 14px;
`

const ImgWrap = styled.div`
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    background-color: var(
        --base-overlays-neutral-alpha-100,
        rgba(26, 26, 26, 0.04)
    );
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.mobile_and_ipad} {
        display: none;
    }
`

const Dot = styled.div`
    width: 4px;
    height: 4px;
    background-color: var(--text-default, #666666);
    border-radius: 4px;
    margin-left: 16px;
    @media (max-width: 1024px) {
        margin-left: 8px;
    }
`

const BtnText = styled.p`
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 14px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

const AnimatedSvg = styled.svg<{ $draw: number }>`
    transform: rotate(-90deg);
    transition: all 200ms ease-in-out;
    fill: none;
    stroke: var(--primary, #5ec3ca);
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-dasharray: ${(props) =>
        props.$draw ? `${props.$draw} 999` : '0 999'};
`

type Props = {
    carsData: ICarsObject
    isMobile?: boolean
    variant: IInsuranceStatusEnum
    application_info: IApplicationInfo
}

const InsuranceOverviewCard = (props: Props) => {
    let { application_info, variant } = props

    const svgRef: React.RefObject<SVGSVGElement> | null | undefined =
        useRef(null)

    const [circleDraw, setCircleDraw] = useState(0)
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const svgEl = svgRef.current
        let radius = 10
        if (svgEl && application_info.stat) {
            setTimeout(
                () =>
                    application_info.stat &&
                    setCircleDraw(
                        (application_info.stat * 2 * radius * Math.PI) / 100
                    ),
                200
            )
        }
        return () => {
            setCircleDraw(0)
        }
    }, [application_info])

    const navigate = useNavigate()

    const [showHelpTooltip, toggleShowHelpTooltip] = useState(false)
    const [showConfirmation, toggleConfirmation] = useState(false)
    const [showOfferModal, toggleShowOfferModal] = useState(false)

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    const { theme } = useThemes()

    const cardsData: {
        [key in IInsuranceStatusEnum]: {
            cardIcon: React.ReactNode
            title: string
            description_start: string
            doubleBtn?: {
                leftSideBtn: {
                    copy: string
                    onClick: () => void
                    withConfirmation?: {
                        copy: string
                        onConfirmation: () => void
                        onCancel: () => void
                    }

                    icon?: React.ReactNode
                }
                rightSideBtn: {
                    copy: string
                    onClick: () => void
                    icon?: React.ReactNode
                    customBgColor?: string
                    customTextColour?: string
                }
            }
            singleBtn?: {
                copy: string
                onClick: () => void
                icon?: React.ReactNode
            }
        }
    } = useMemo(() => {
        return {
            DRAFT: {
                cardIcon: (
                    <AnimatedSvg
                        ref={svgRef}
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        data-percent="89"
                        $draw={circleDraw}
                    >
                        <circle cx="12" cy="12" r="10" />
                    </AnimatedSvg>
                ),
                title: `Finish up your Insurance Application, ${application_info.stat}% completed`,
                description_start: `${
                    application_info.carsInfoArr.length > 0
                        ? `Application for `
                        : `No vehicles added yet`
                }`,
                doubleBtn: {
                    leftSideBtn: {
                        copy: 'Remove',
                        onClick: () => {
                            toggleConfirmation(true)
                        },
                        withConfirmation: {
                            copy: 'Are you sure?',
                            onConfirmation: () => {
                                dispatch(
                                    insuranceActions.delete_insurance_application_request(
                                        {
                                            uid: application_info.uid,
                                        }
                                    )
                                )
                            },
                            onCancel: () => {
                                toggleConfirmation(false)
                            },
                        },
                    },
                    rightSideBtn: {
                        copy: 'Finish now',
                        onClick: () => {
                            posthog.capture(
                                'Go to draft insurance application from dashboard'
                            )
                            navigate(
                                `/insurance/application/${application_info.uid}`
                            )
                        },
                    },
                },
            },
            REQUESTED: {
                cardIcon: <DocumentIcon />,
                title: `Your Application is being processed`,
                description_start: `${
                    application_info.carsInfoArr.length > 0
                        ? `Application for `
                        : `No vehicles added yet`
                }`,
                singleBtn: {
                    copy: 'View',
                    onClick: () => {
                        posthog.capture(
                            'View requested insurance application from dashboard'
                        )
                        navigate(
                            `/insurance/application/${application_info.uid}`
                        )
                    },
                    icon: <EyeIcon />,
                },
            },
            ENRICHMENT: {
                cardIcon: <DocumentIcon />,
                title: `Finish up your Insurance Application, ${application_info.stat}% completed`,
                description_start: `${
                    application_info.carsInfoArr.length > 0
                        ? `Application for `
                        : `No vehicles added yet`
                }`,
            },
            QUOTING: {
                cardIcon: <DocumentIcon />,
                title: `Only £${
                    application_info.offer
                        ? application_info.offer.toLocaleString()
                        : (1250).toLocaleString()
                } to protect your ${
                    application_info.carsInfoArr.length > 1
                        ? 'vehicles'
                        : 'vehicle'
                }`,
                description_start: 'View the offer for',
                doubleBtn: {
                    leftSideBtn: { copy: 'Ignore', onClick: () => {} },
                    rightSideBtn: {
                        copy: 'View offer',
                        onClick: () => {
                            toggleShowOfferModal(true)
                        },
                        customBgColor: 'var(--primary, #5ec3ca)',
                        customTextColour: 'var(--bg-color, #fff)',
                    },
                },
            },
            BROKING: {
                cardIcon: <DocumentIcon />,
                title: `Finish up your Insurance Application, ${application_info.stat}% completed`,
                description_start: '',
                doubleBtn: {
                    leftSideBtn: {
                        copy: 'Need help',
                        onClick: () => {
                            toggleShowHelpTooltip(true)
                        },
                    },
                    rightSideBtn: { copy: 'View', onClick: () => {} },
                },
            },
            COMPLETE: {
                cardIcon: <DocumentIcon />,
                title: `${userLoggedIn?.given_name}'s  ${
                    application_info.carsInfoArr.length > 1 ? 'multi-car' : ''
                } policy`,
                description_start: '',
                singleBtn: {
                    copy: 'View',
                    onClick: () => {
                        posthog.capture(
                            'View complete insurance application from dashboard'
                        )
                        navigate(
                            `/insurance/application/${application_info.uid}`
                        )
                    },
                    icon: <EyeIcon />,
                },
            },
            ARCHIVED: {
                cardIcon: <DocumentIcon />,
                title: `Finish up your Insurance Application, ${application_info.stat}% completed`,
                description_start: '',
                singleBtn: { copy: 'View', onClick: () => {} },
            },
        }
    }, [application_info, circleDraw, navigate])

    const currentCard = cardsData[variant]

    return (
        <Wrapper>
            <FlexRowGap16
                style={{
                    maxWidth: props.isMobile ? '100%' : '60%',
                }}
            >
                <FlexRowGap16>
                    {/* card icon */}
                    <ImgWrap>{currentCard.cardIcon}</ImgWrap>

                    <FlexColumnGap16>
                        {/* card title */}
                        <Title>{currentCard?.title ?? ''}</Title>

                        {/* card cars */}
                        <FlexRowGap16
                            style={{ width: 'auto', maxWidth: '100%' }}
                        >
                            {currentCard &&
                            currentCard?.description_start.length > 0 ? (
                                <Text
                                    style={{
                                        whiteSpace: 'nowrap',

                                        width: 'fit-content',
                                    }}
                                >
                                    {currentCard?.description_start ?? ''}
                                </Text>
                            ) : null}
                            {application_info.carsInfoArr?.map((car, i) =>
                                (props.isMobile && i < 1) ||
                                (!props.isMobile && i < 2) ? (
                                    <div
                                        key={`carinsured_${i}_${
                                            props.isMobile
                                                ? 'mobile'
                                                : 'desktop'
                                        }`}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: 'fit-content',
                                            maxWidth: '100%',
                                            minWidth:
                                                size?.width && size.width < 1400
                                                    ? '20%'
                                                    : undefined,
                                            marginLeft:
                                                currentCard &&
                                                currentCard?.description_start
                                                    .length > 0
                                                    ? -4
                                                    : 0,
                                        }}
                                    >
                                        <TextSemibold
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflowX: 'hidden',
                                                textOverflow: 'ellipsis',
                                                textTransform: 'uppercase',
                                                fontSize: '12px',
                                                marginTop: 2,
                                            }}
                                        >
                                            {car?.title ?? `Untitled #${i}`}
                                        </TextSemibold>
                                        {application_info.carsInfoArr?.length >
                                            0 &&
                                            !props.isMobile &&
                                            i <
                                                application_info.carsInfoArr
                                                    ?.length -
                                                    1 && (
                                                <div
                                                    style={{
                                                        marginLeft:
                                                            size?.width &&
                                                            size.width < 1024
                                                                ? 4
                                                                : -4,
                                                        paddingRight: 8,
                                                    }}
                                                >
                                                    <Dot />
                                                </div>
                                            )}
                                    </div>
                                ) : null
                            )}{' '}
                            <TextSemibold
                                style={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {' '}
                                {props.isMobile &&
                                application_info.carsInfoArr &&
                                application_info.carsInfoArr?.length > 1
                                    ? `+${
                                          application_info.carsInfoArr.length -
                                          1
                                      } more`
                                    : application_info.carsInfoArr.length > 2
                                    ? `+${
                                          application_info.carsInfoArr.length -
                                          2
                                      } ${
                                          size.width && size.width > 1024
                                              ? 'more'
                                              : ''
                                      }`
                                    : null}
                            </TextSemibold>
                        </FlexRowGap16>
                    </FlexColumnGap16>
                </FlexRowGap16>
            </FlexRowGap16>

            {/* card buttons */}
            <FlexRowGap16 style={!props.isMobile ? { width: 'auto' } : {}}>
                {/* card single btn */}
                {currentCard?.singleBtn ? (
                    <StandardCtaBtn
                        onClick={() => {
                            currentCard.singleBtn?.onClick()
                        }}
                        customHeight="auto"
                        customWidth={props.isMobile ? '100%' : '112px'}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                            }}
                        >
                            {currentCard.singleBtn.icon ?? null}
                            <BtnText style={{ color: `var(--bg-color, #fff)` }}>
                                {currentCard.singleBtn.copy}
                            </BtnText>
                        </div>
                    </StandardCtaBtn>
                ) : null}

                {/* card double btns with confirmation or help */}
                {currentCard?.doubleBtn ? (
                    <>
                        {/* if btn has confirmation pop-up on desktop */}
                        <Popconfirm
                            title={
                                currentCard.doubleBtn.leftSideBtn
                                    .withConfirmation?.copy ? (
                                    <AtdConfWarningBox
                                        title={
                                            currentCard.doubleBtn.leftSideBtn
                                                .withConfirmation?.copy
                                        }
                                        theme={theme}
                                    />
                                ) : (
                                    ''
                                )
                            }
                            open={!isMobile && showConfirmation ? true : false}
                            onOpenChange={() => {
                                toggleConfirmation(!showConfirmation)
                            }}
                            onConfirm={() => {
                                currentCard.doubleBtn?.leftSideBtn
                                    .withConfirmation?.onConfirmation &&
                                    currentCard.doubleBtn?.leftSideBtn.withConfirmation?.onConfirmation()
                            }}
                            onCancel={() => {
                                toggleConfirmation(false)
                            }}
                            okText="Remove"
                            cancelText="Cancel"
                            okButtonProps={{
                                style: {
                                    backgroundColor: `var(--error, #df6f6f)`,
                                    border: 'none',
                                    borderRadius: 4,
                                    height: 32,
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                },
                            }}
                            cancelButtonProps={{
                                style: {
                                    borderRadius: 4,
                                    height: 32,
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    backgroundColor: `var(--modal-side-sheet-pop-over-bg, #fff)`,
                                    color: 'var(--text-strong, #1a1a1a)',
                                    borderColor: 'var(--border-muted, #e5e5e5)',
                                },
                            }}
                            placement="bottomRight"
                            style={{
                                borderRadius: 4,
                            }}
                            icon={
                                <ExclamationCircleFilled
                                    style={{ color: 'var(--error, #df6f6f)' }}
                                />
                            }
                        >
                            {/* left side btn */}
                            <StandardCtaBtn
                                onClick={() => {
                                    currentCard.doubleBtn?.leftSideBtn.onClick()
                                }}
                                boder="1px solid var(--border-muted, #e5e5e5)"
                                bg="transparent"
                                customWidth={
                                    props.isMobile ? '100%' : undefined
                                }
                                customHeight="auto"
                            >
                                <BtnText
                                    style={{
                                        color: `var(--text-default, #666666)`,
                                    }}
                                >
                                    {currentCard.doubleBtn?.leftSideBtn.copy}
                                </BtnText>
                            </StandardCtaBtn>
                        </Popconfirm>

                        {/* right side btn */}
                        <StandardCtaBtn
                            onClick={() => {
                                currentCard.doubleBtn?.rightSideBtn.onClick()
                            }}
                            boder="1px solid var(--primary, #5ec3ca)"
                            bg={
                                currentCard.doubleBtn.rightSideBtn
                                    .customBgColor ?? 'transparent'
                            }
                            customWidth={props.isMobile ? '100%' : undefined}
                            customHeight="auto"
                        >
                            <BtnText
                                style={{
                                    color:
                                        currentCard.doubleBtn.rightSideBtn
                                            .customTextColour ??
                                        `var(--primary, #5ec3ca)`,
                                }}
                            >
                                {currentCard.doubleBtn?.rightSideBtn.copy}
                            </BtnText>
                        </StandardCtaBtn>

                        {/* if btn has confirmation */}
                        <DeletionConfirmationBottomSheet
                            customHeight="260px"
                            isOpen={showConfirmation && isMobile ? true : false}
                            toggle={() => toggleConfirmation(false)}
                            action={() => {
                                currentCard.doubleBtn?.leftSideBtn.withConfirmation?.onConfirmation()
                            }}
                            actionCopy="Remove"
                            titleCopy="Are you sure?"
                            detailsCopy="Please confirm that you want to remove this insurance application"
                        />

                        {/* if btn triggers help tooltip on desktop */}
                        <CustomTooltip
                            isOpen={!isMobile && showHelpTooltip ? true : false}
                            customBg="#333"
                            customPadding={8}
                            customWidth={300}
                            text={
                                <span
                                    style={{ color: 'var(--bg-color, #fff)' }}
                                >
                                    Have questions about your policy or would
                                    you like to make some changes?
                                    <br />
                                    Contact us at{' '}
                                    <a
                                        href={
                                            'mailto:insurance@custodian.club?subject=Help'
                                        }
                                        style={{
                                            color: 'var(--primary, #5ec3ca)',
                                        }}
                                    >
                                        {' '}
                                        insurance@custodian.club
                                    </a>
                                    .
                                </span>
                            }
                        />

                        {/* if btn triggers help bottom sheet on mobile */}
                        <BottomSheetWarning
                            isOpen={isMobile && showHelpTooltip ? true : false}
                            toggle={() => toggleShowHelpTooltip(false)}
                            title="Need help?"
                            text={
                                <span>
                                    Have questions about your policy or would
                                    you like to make some changes?
                                    <br />
                                    Contact us at{' '}
                                    <a
                                        href={
                                            'mailto:insurance@custodian.club?subject=Help'
                                        }
                                        style={{
                                            color: 'var(--primary, #5ec3ca)',
                                        }}
                                    >
                                        {' '}
                                        insurance@custodian.club
                                    </a>
                                    .
                                </span>
                            }
                        />
                        {showOfferModal ? (
                            <RightSideModalDisplay
                                isOpen={showOfferModal ? true : false}
                                cancel={() => {
                                    toggleShowOfferModal(false)
                                }}
                                title={'Policy offer'}
                                removeBottomPadding
                                customFooterBtns={
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            alignItems: 'center',
                                            gap: 16,
                                        }}
                                    >
                                        <StandardCtaBtn
                                            onClick={() => {
                                                toggleShowOfferModal(false)
                                            }}
                                            boder="1px solid var(--primary, #5ec3ca)"
                                            bg={'transparent'}
                                            customWidth={'auto'}
                                            customHeight="48px"
                                        >
                                            <BtnText
                                                style={{
                                                    color: `var(--primary, #5ec3ca)`,
                                                    fontSize: 16,
                                                }}
                                            >
                                                Ignore
                                            </BtnText>
                                        </StandardCtaBtn>
                                        <StandardCtaBtn
                                            onClick={() => {
                                                toggleShowOfferModal(false)
                                            }}
                                            boder="1px solid #5EC3CA"
                                            customHeight="48px"
                                            customWidth={'100%'}
                                        >
                                            <BtnText
                                                style={{
                                                    color: `var(--bg-color, #fff)`,
                                                    fontSize: 16,
                                                }}
                                            >
                                                Accept & Pay
                                            </BtnText>
                                        </StandardCtaBtn>
                                    </div>
                                }
                            >
                                <PolicyOfferModal
                                    application_info={application_info}
                                />
                            </RightSideModalDisplay>
                        ) : null}
                    </>
                ) : null}
            </FlexRowGap16>
        </Wrapper>
    )
}

export default InsuranceOverviewCard
