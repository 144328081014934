import * as React from 'react'
import BasicAccountItemInfoFormDesktop from '../basic/basicAccountItemInfoFormDesktop'
import ChangePasswordFormDesktop from '../changePasswordForm/changePasswordFormDesktop'
import { IList } from 'entityModels'
import { IUser } from '../../../../../../redux/user/types'
import { useAppSelector } from '../../../../../../redux/store/hooks'

export interface Props {
    item: IList | undefined
    toggle: any
    submit_func: any
    setToResetPasswordClicked: any
    hasResetPasswordBeenClicked: boolean
    userLoggedIn: IUser
    request_reset_password_func: any
}

const AccountPrivateInfoManagerFormDesktop: React.FC<Props> = ({
    item,
    toggle,
    submit_func,
    setToResetPasswordClicked,
    hasResetPasswordBeenClicked,
    userLoggedIn,
    request_reset_password_func,
}) => {
    const hasOnlyUsername =
        userLoggedIn?.family_name === userLoggedIn?.given_name ? true : false

    const emptyAnswerItem: IList | undefined = item
        ? {
              ...item,
              answer: '',
          }
        : undefined

    const error = useAppSelector((state) => state.user.error)
    const renderForm = (
        item: any,
        toggle: any,
        submit_func: any,
        setToResetPasswordClicked: any,
        hasResetPasswordBeenClicked: boolean,
        userLoggedIn: any,
        request_reset_password_func: any
    ) => {
        switch (item.id) {
            case 'password':
                return (
                    <ChangePasswordFormDesktop
                        toggle={toggle}
                        submitFunc={request_reset_password_func}
                        setToResetPasswordClicked={setToResetPasswordClicked}
                        hasResetPasswordBeenClicked={
                            hasResetPasswordBeenClicked
                        }
                        error={error}
                        userLoggedIn={userLoggedIn}
                    />
                )

            default:
                return (
                    <BasicAccountItemInfoFormDesktop
                        toggle={toggle}
                        item={
                            (item?.id === 'given_name' ||
                                item?.id === 'family_name') &&
                            hasOnlyUsername &&
                            emptyAnswerItem
                                ? { ...emptyAnswerItem }
                                : { ...item }
                        }
                        submitFunc={submit_func}
                        userLoggedIn={userLoggedIn}
                    />
                )
        }
    }

    return renderForm(
        item,
        toggle,
        submit_func,
        setToResetPasswordClicked,
        hasResetPasswordBeenClicked,
        userLoggedIn,
        request_reset_password_func
    )
}

export default AccountPrivateInfoManagerFormDesktop
