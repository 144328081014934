import * as React from 'react'
import BasicAccountPrivateInfoFormMobile from '../basic/basicAccountPrivateInfoFormMobile'
import ChangePasswordAccountFormMobile from '../changePasswordForm/changePasswordFormMobile'
import { useAppSelector } from '../../../../../../redux/store/hooks'

export interface Props {
    item: any
    toggle: any
    submit_func: any
    setToResetPasswordClicked: any
    hasResetPasswordBeenClicked: boolean
    userLoggedIn: any
    request_reset_password_func: any
}

const AccountPrivateInfoManagerMobileForm: React.FC<Props> = ({
    item,
    toggle,
    submit_func,
    setToResetPasswordClicked,
    hasResetPasswordBeenClicked,
    userLoggedIn,
    request_reset_password_func,
}) => {
    const error = useAppSelector((state) => state.user.error)

    const renderForm = () => {
        switch (item.id) {
            case 'password':
                return (
                    <ChangePasswordAccountFormMobile
                        error={error}
                        toggle={toggle}
                        submitFunc={request_reset_password_func}
                        setToResetPasswordClicked={setToResetPasswordClicked}
                        hasResetPasswordBeenClicked={
                            hasResetPasswordBeenClicked
                        }
                        userLoggedIn={userLoggedIn}
                    />
                )

            default:
                return (
                    <BasicAccountPrivateInfoFormMobile
                        toggle={toggle}
                        item={item}
                        submitFunc={submit_func}
                        userLoggedIn={userLoggedIn}
                    />
                )
        }
    }

    return renderForm()
}

export default AccountPrivateInfoManagerMobileForm
