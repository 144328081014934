import { ConvertAPIEntryCostToEntryCostSingle } from './entryCostToCostItem'
import {
    costNumShorterFormatter,
    doSumRawTotalCosts,
    numberWithCommas,
} from '../../../helpers/currencyConverter'
import { convertDateToFeFormat } from '../time/dates'
import { IAttachmentAPI } from '../../attachments/types'
import {
    IEntryPayloadAPI,
    ILabelAPI,
    IPayloadCarGalleryImageFromApi,
} from '../../services/typedefinitions/apiPayloads'
import { ITimelineItem, ICostItem } from '../../timeline/types'
// import * as unitGenerator from '../../../helpers/units/unitConversion'

export const ConvertAPIEntryToTimelineItemSingle = (
    entryAPI: IEntryPayloadAPI
): ITimelineItem => {
    let costUIDsFromEntryAPI: string[] = []
    let labelsUIDsFromEntryAPI: ILabelAPI[] = []

    let attachmentsIDs: string[] = []
    let imageIDS: string[] | undefined = undefined

    if (entryAPI.attachments && entryAPI.attachments.length > 0) {
        attachmentsIDs = entryAPI.attachments.map((i: IAttachmentAPI) => i.uid)
    }

    if (entryAPI.images && entryAPI.images.length > 0) {
        imageIDS = entryAPI.images.map(
            (i: IPayloadCarGalleryImageFromApi) => i.uid
        )
    }

    let timeline_item: ITimelineItem = {
        id: entryAPI.uid,
        categoryID:
            entryAPI.entry_type === 'others' ? 'other' : entryAPI.entry_type,
        date:
            entryAPI.occurred_at && convertDateToFeFormat(entryAPI.occurred_at),
        title: entryAPI.title,
        notes: entryAPI.notes,
        labels: labelsUIDsFromEntryAPI,
        description: entryAPI.description,
        costUIDS: costUIDsFromEntryAPI,
        attachmentIDs: attachmentsIDs,
        costItemsObj: {},
        total_cost_amount_display: '0',
        total_cost_amount_display_short: '0',
        total_cost_amount_raw: 0,
        //mocking mileage for building UI
        gallery_image_ids: imageIDS, // add car images ids here to see components
        mileage: entryAPI.entry_mileage,
        insurance_entry_broker: entryAPI.insurance_entry_broker,
        insurance_entry_policy_end_date:
            entryAPI.insurance_entry_policy_end_date,
        insurance_entry_policy_start_date:
            entryAPI.insurance_entry_policy_start_date,
        insurance_entry_underwriter: entryAPI.insurance_entry_underwriter,
    }

    if (entryAPI.costs) {
        for (const cost of entryAPI.costs) {
            costUIDsFromEntryAPI.push(cost.uid)
            let converted_cost: ICostItem =
                ConvertAPIEntryCostToEntryCostSingle(cost)
            timeline_item.costItemsObj = {
                ...timeline_item.costItemsObj,
                [converted_cost.id]: converted_cost,
            }
        }

        // call the reduce function and put res in totalAmount,
        let amountsArray = (): (number | undefined)[] => {
            if (timeline_item.costUIDS) {
                if (timeline_item.costItemsObj) {
                    return timeline_item.costUIDS.map((uid: string) => {
                        if (
                            timeline_item.costItemsObj &&
                            timeline_item.costItemsObj[uid] &&
                            timeline_item.costItemsObj[uid].amount
                        ) {
                            if (timeline_item.costItemsObj[uid].user_amount) {
                                return timeline_item.costItemsObj[uid]
                                    .user_amount !== undefined
                                    ? timeline_item.costItemsObj[uid]
                                          .user_amount
                                    : 0
                            } else {
                                return timeline_item.costItemsObj[uid]
                                    .amount !== undefined
                                    ? timeline_item.costItemsObj[uid].amount
                                    : 0
                            }
                        } else return 0
                    })
                } else return []
            } else return []
        }

        // get array of costs amounts
        let amount_arr = amountsArray().map((item) => (item ? item : 0))

        if (amount_arr) {
            // get total sum of these amounts
            let costsSum = doSumRawTotalCosts(amount_arr)
            // assign raw result to entry total_cost_amount_raw
            timeline_item.total_cost_amount_raw = costsSum ? costsSum : 0
            // assign formatted result to entry total_cost_amount_display
            timeline_item.total_cost_amount_display = costsSum
                ? numberWithCommas(costsSum)
                : '0.00'
            // assign a shorter formatted result to entry total_cost_amount_display
            timeline_item.total_cost_amount_display_short = costsSum
                ? costNumShorterFormatter(costsSum)
                : '0.00'
        }
    }
    if (entryAPI.entry_labels) {
        for (const label of entryAPI.entry_labels) {
            labelsUIDsFromEntryAPI.push(label)
        }
    }

    return timeline_item
}
