import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BottomUpSliderWithExit from '../../../templates/animated/bottomUpSliderWithExit'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { IThreeDotsActionsItems } from '../actionsMenu/actionsMenuDots'
import { IHandoverMenuItemsArgProps } from '../../../pages/archive/garageArchive'
import { Spin, Tooltip } from 'antd'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { IHandover } from '../../../../redux/entities/cars/types'

interface HandoverMenuItemPropsMobile {
    isVisible: boolean
    close: () => void
    menu_items: IThreeDotsActionsItems[]
    carID: string
    carHandoverData?: IHandover
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const StyledOption = styled.li`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    height: 56px;
    background-color: transparent;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
    font-family: Lato;
    color: var(--text-default, #666666);
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    text-transform: capitalize;
`

const Handle = styled.div`
    height: 4px;
    width: 60px;
    background-color: var(--text-darker, #616161);
    border-radius: 10px;
`

const StyledSpin = styled(Spin)`
    .ant-spin-dot-item {
        background-color: var(--primary, #5ec3ca);
    }
`

const CarHandoverMenuMobile: React.FunctionComponent<
    HandoverMenuItemPropsMobile
> = (props: HandoverMenuItemPropsMobile) => {
    const { isVisible, close, menu_items } = props
    const [showTooltip, toggleTooltip] = useState(false)
    const [clickedIndex, setClikedIndex] = useState<number | undefined>(
        undefined
    )

    const { theme } = useThemes()

    useEffect(() => {
        if (isVisible) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [isVisible])

    let optionsDataList = menu_items.map(
        (item: IThreeDotsActionsItems, index: number) => {
            if (item && !item.hide_item) {
                return (
                    <StyledOption
                        value={item.text}
                        key={`mobilemenu-${index}`}
                        aria-label={item.text}
                        onClick={(e: any) => {
                            if (!item.isDisabled && !item.isLoading) {
                                let arg: IHandoverMenuItemsArgProps = {
                                    carID: props.carID,
                                    handoverData: props.carHandoverData,
                                }
                                item.actionOnClick && item.actionOnClick(arg)

                                close()
                            } else {
                                e.stopPropagation()
                                if (!item.isLoading) {
                                    setClikedIndex(index)
                                    toggleTooltip(true)
                                    setTimeout(() => toggleTooltip(false), 2000)
                                }
                            }
                        }}
                    >
                        <Tooltip
                            title={item.disabledTxt}
                            open={showTooltip && clickedIndex === index}
                            placement="bottom"
                            color={colours[theme].error}
                        >
                            <ContentRow
                                style={{
                                    color: item.isLoading
                                        ? colours[theme].border_muted
                                        : item.color
                                        ? item.color
                                        : colours[theme].text_default,
                                    display: 'flex',
                                    alignItems: 'center',
                                    transition: 'color 200ms',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        opacity: item.isLoading ? 1 : 0,
                                        width: item.isLoading ? 24 : 0,
                                        transition: 'all 200ms',
                                    }}
                                >
                                    <StyledSpin size="small" />
                                </div>

                                {item.text}
                            </ContentRow>{' '}
                        </Tooltip>
                    </StyledOption>
                )
            } else {
                return null
            }
        }
    )

    return (
        <BottomUpSliderWithExit
            isVisible={isVisible}
            onBackdropClick={close}
            customHeight={menu_items.length === 1 ? 'auto' : '384px'}
        >
            <Handle />

            <div style={{ paddingTop: '10px' }} />
            {/* <Header onClick={(e: any) => e.stopPropagation()}>Sort by</Header> */}
            <Wrapper>{optionsDataList}</Wrapper>

            <div style={{ paddingTop: '40px' }} />
        </BottomUpSliderWithExit>
    )
}

export default CarHandoverMenuMobile
