import styled from 'styled-components'
import { useAppSelector } from '../../../../redux/store/hooks'
import FullWidthBtn from '../../../atoms/Button/fullWidthBtn'
import { IInsuranceAddress_API } from '../../../../redux/insuranceQuoteApplication/types'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import useThemes from '../../../../providers/theme/hooks'
import { insurance_colours } from '../../../../providers/theme/colours'
import OutlinedRadioInput from '../../editOrCreateModeSingleFields/outlinedRadioInputs'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    overflow-y: auto;
    padding-bottom: 200px;
`

type Props = {
    selectedAddressId: string | undefined
    selectAddressId?: (addressid: string | undefined) => void
    onAddNew: () => void
    sectionID?: IInsuranceApplicationSectionId
}

const GetQuoteSearchSelectAddressForm = (props: Props) => {
    let addresses = useAppSelector(
        (state) => state.insuranceQuoteApplication.draft.addresses
    )

    let addresses_array = Object.values(addresses).map(
        (address: IInsuranceAddress_API) => {
            return {
                uid: address.uid ?? '',
                postcode: address.postcode,
                name: address
                    ? `${address.street_1 ?? ''} ${address.street_2 ?? ''}${
                          address.postcode ? `, ${address.postcode} ` : ''
                      }`
                    : '',
            }
        }
    )

    const { theme } = useThemes()

    return (
        <Wrapper>
            <div
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <OutlinedRadioInput
                    options={addresses_array}
                    onChange={(uid: string) =>
                        props.selectAddressId && props.selectAddressId(uid)
                    }
                    customPrimaryColour={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_100
                            : undefined
                    }
                    customPrimaryColour_04={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_04
                            : undefined
                    }
                    value={props.selectedAddressId}
                />

                {addresses_array?.length > 0 && (
                    <div style={{ paddingTop: '32px' }} />
                )}
                <FullWidthBtn
                    onClick={props.onAddNew}
                    text="Add new address"
                    bg={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_08
                            : undefined
                    }
                    txt_color={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_100
                            : undefined
                    }
                />
            </div>
        </Wrapper>
    )
}

export default GetQuoteSearchSelectAddressForm
