import { message } from 'antd'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import CancelIcon from '../../../../../../public/assets/icons/close_cross_grey.svg'
import Expander from '../../../../../atoms/expander/expander'
import SearchIcon from '../../../../../atoms/statefulicons/SearchIcon'
import SideSlider from '../../../../../templates/animated/sideSlider'
import queryString from 'query-string'
import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import AddIcon from '../../../../../atoms/icons/components/addIcon'
import ChevronLeft from '../../../../../atoms/icons/components/chevronLeft'
import useThemes from '../../../../../../providers/theme/hooks'
import colours from '../../../../../../providers/theme/colours'
import { useNavigate } from 'react-router-dom'

const StickyHeader = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    padding: 16px;
    padding-top: 8px;
    background-color: var(--bg-color, #fff);
`

const SearchBarWrapper = styled.div`
    padding: 8px 16px;
    background-color: var(--off-bg-color, #fafafa);
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    height: 48px;
`

const SearchInput = styled.input`
    flex: 1;
    background-color: transparent;
    border: none;
    margin: 0 16px;
    color: var(--text-strong, #1a1a1a);
`

const ClearInputButton = styled.button`
    background: transparent;
    border: none;
    background-image: url(${CancelIcon});
    background-position: center;
    background-repeat: no-repeat;
    padding: 10px;
`

const SearchPromptText = styled.span`
    display: inline-block;
    padding-top: 16px;
    padding-left: 16px;
    color: var(--text-muted);
`

const BackButtonLink = styled.button`
    color: var(--primary, #5ec3ca);
    font-size: 16px;
    padding: 8px 4px;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    margin-bottom: 8px;
`

const SearchResultsWrapper = styled.div`
    padding: 16px;
    padding-top: 0;
`

const SearchResult = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    height: 56px;
    border: none;
    background-color: var(--off-bg-color, #fafafa);
    color: var(--text-strong, #1a1a1a);
    border-radius: 4px;
    margin: 8px 0;
    font-family: Lato;
    font-size: 14px;
`

interface SearchBarProps {
    value?: string
    defaultValue?: string
    placeholder: string
    onChange: (searchText: string) => void
}

const SearchBar = (props: SearchBarProps) => {
    const [isFocussed, setIsFocussed] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const clearInput = () => {
        props.onChange('')
        inputRef.current?.focus()
    }

    return (
        <SearchBarWrapper>
            <SearchIcon isActive={isFocussed} height={16} />
            <SearchInput
                ref={inputRef}
                autoFocus
                autoCapitalize="words"
                placeholder={props.placeholder}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={(e) => props.onChange(e.target.value)}
                onFocus={() => setIsFocussed(true)}
                onBlur={() => setIsFocussed(false)}
            />
            {props.value && <ClearInputButton onClick={clearInput} />}
        </SearchBarWrapper>
    )
}

const BackButton = () => {
    const navigate = useNavigate()
    const { theme } = useThemes()

    return (
        <BackButtonLink onClick={() => navigate(-1)}>
            <div style={{ marginRight: 8 }}>
                <ChevronLeft color={colours[theme].primary} />
            </div>
            <div style={{ height: 22 }}>return</div>
        </BackButtonLink>
    )
}

export type Props = {
    fetching: any
    value: any
    data: any
    fetchItem: any
    handleChange: any
    optionsList: any
    item?: any | undefined
    width?: string | undefined
    addAnItemFunc?: any | undefined
    dropdownTargetId?: string | undefined
    formCurrentValue?: string | undefined
    allowAdd?: boolean | undefined
    isDisabled?: boolean | undefined
    fontSize?: string | undefined
    placeholder?: string | undefined
    dataCyId?: string | undefined
    theme?: 'timeline' | undefined
    sendId?: boolean
    isKeyValue?: boolean
    backgroundColor?: string
    field_id?: string
}

const StandaloneSearchSelectMobile: React.FC<Props> = (props) => {
    const [valueTyped, setValueTyped] = useState('')

    const errorWarning = (errorText: string) => {
        message.error(errorText, 2)
    }

    const {
        fetchItem,
        data,
        optionsList,
        isKeyValue,
        handleChange,
        item,
        placeholder,
        dropdownTargetId,
        allowAdd,
        addAnItemFunc,
        field_id,
    } = props

    const handleSearch = (value: any) => {
        fetchItem(value)
        setValueTyped(value)
    }

    const handleAddClick = () => {
        if (valueTyped) {
            let trimmed_val = valueTyped.replace(/\s/g, '')
            if (trimmed_val.length >= 1 && trimmed_val.length < 70) {
                let trimmed_val_final = valueTyped.trim()

                if (
                    (field_id === 'car_make' ||
                        field_id === 'make' ||
                        field_id === 'car_model' ||
                        field_id === 'model') &&
                    !isKeyValue
                ) {
                    handleChange(trimmed_val_final, 'new')
                    addAnItemFunc(dropdownTargetId, trimmed_val_final)
                } else {
                    handleChange(trimmed_val_final)
                }
            } else {
                if (trimmed_val.length >= 70) {
                    errorWarning('Name must be less than 70 characters')
                } else {
                    errorWarning('Please enter a valid name.')
                }
            }
        }
    }

    const namesInResultsArray: string[] = []

    const mappedData = data
        ? data.map((di: any) => {
              let d = di && di.item

              if (d) {
                  isKeyValue
                      ? d && namesInResultsArray.push(d.trim().toLowerCase())
                      : d.name &&
                        namesInResultsArray.push(d.name.trim().toLowerCase())
                  return (
                      <SearchResult
                          key={d.name ?? d}
                          aria-label="UK"
                          onClick={() => {
                              if (
                                  (field_id === 'car_make' ||
                                      field_id === 'make' ||
                                      field_id === 'car_model' ||
                                      field_id === 'model') &&
                                  !isKeyValue
                              ) {
                                  handleChange(d.name, d.uid)
                              } else handleChange(d.name ?? d)
                          }}
                      >
                          <span style={{ marginLeft: '10px' }}>
                              {isKeyValue ? d : d.name}
                          </span>
                      </SearchResult>
                  )
              } else {
                  isKeyValue
                      ? di && namesInResultsArray.push(di.trim().toLowerCase())
                      : di.name &&
                        namesInResultsArray.push(di.name.trim().toLowerCase())

                  return (
                      <SearchResult
                          key={isKeyValue ? di : di.name}
                          aria-label="UK"
                          onClick={() => {
                              if (
                                  (field_id === 'car_make' ||
                                      field_id === 'make' ||
                                      field_id === 'car_model' ||
                                      field_id === 'model') &&
                                  !isKeyValue
                              ) {
                                  handleChange(di.name, di.uid)
                              } else handleChange(isKeyValue ? di : di.name)
                          }}
                      >
                          <span style={{ marginLeft: '10px' }}>
                              {isKeyValue ? di : di.name}
                          </span>
                      </SearchResult>
                  )
              }
          })
        : null

    const mappedList = optionsList
        ? optionsList.map((d: any) => {
              const value = isKeyValue ? d : d.name

              if (!value || value === '-') return null

              value && namesInResultsArray.push(value.trim().toLowerCase())

              return (
                  <SearchResult
                      onClick={() => {
                          if (
                              (field_id === 'car_make' ||
                                  field_id === 'make' ||
                                  field_id === 'car_model' ||
                                  field_id === 'model') &&
                              !isKeyValue
                          ) {
                              handleChange(d.name, d.uid)
                          } else handleChange(value)
                      }}
                      key={value}
                      aria-label="UK"
                  >
                      <span style={{ marginLeft: '10px' }}>{value}</span>
                  </SearchResult>
              )
          })
        : null

    return (
        <div>
            <StickyHeader>
                <SideSlider direction="right" initial="enter">
                    <BackButton />
                    <SearchBar
                        placeholder={
                            !item.answer && item.text === 'countries'
                                ? `Search through countries`
                                : !item.answer && placeholder
                                ? placeholder
                                : !item.answer && !placeholder
                                ? `Search through ${item.text && item.text}s`
                                : item.answer.length > 1
                                ? item.answer
                                : placeholder
                                ? placeholder
                                : `Search through ${item.text && item.text}s`
                        }
                        defaultValue={item.answer ? item.answer : '-'}
                        value={valueTyped}
                        onChange={handleSearch}
                    />
                    <SearchPromptText>
                        {`Please search your car's ${
                            item.text ?? item.answer
                        }.`}
                    </SearchPromptText>
                    {!namesInResultsArray.includes(
                        valueTyped.trim().toLowerCase()
                    ) &&
                        (allowAdd ? (
                            <Expander
                                height={
                                    valueTyped.trim().length > 1 ? 'auto' : 0
                                }
                            >
                                <div style={{ paddingTop: 16 }} />
                                <ButtonAtom
                                    onClick={handleAddClick}
                                    theme="modal-action-button-primary"
                                    width="100%"
                                >
                                    <div
                                        style={{
                                            textTransform: 'none',
                                            fontSize: 14,
                                            fontFamily: 'Lato-Semibold',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <AddIcon />
                                        <span
                                            style={{
                                                textTransform: 'capitalize',
                                                paddingLeft: 8,
                                                paddingRight: 3,
                                            }}
                                        >
                                            {`Add ${valueTyped.trim()} `}
                                        </span>
                                        to our database
                                    </div>
                                </ButtonAtom>
                            </Expander>
                        ) : (
                            valueTyped &&
                            !data &&
                            data.length === 0 && <div> no data </div>
                        ))}
                </SideSlider>
            </StickyHeader>
            <div
                style={{
                    height:
                        valueTyped.trim().length < 2 ||
                        namesInResultsArray.includes(
                            valueTyped.trim().toLowerCase()
                        )
                            ? 150
                            : (valueTyped && !data && data.length === 0) ||
                              (valueTyped &&
                                  allowAdd &&
                                  !namesInResultsArray.includes(
                                      valueTyped.trim().toLowerCase()
                                  ))
                            ? 200
                            : 150,
                }}
            />
            <SideSlider direction="right" initial="enter">
                <SearchResultsWrapper>
                    {data && data.length > 0 ? mappedData : mappedList}
                </SearchResultsWrapper>
            </SideSlider>
        </div>
    )
}

interface StandaloneSearchUrlOptions {
    returnUrl: string
    path: string
    formId: string
    fieldId: string
}

export const getStandaloneSearchUrl = ({
    returnUrl,
    path,
    formId,
    fieldId,
}: StandaloneSearchUrlOptions) => {
    const queryParams = queryString.stringify({
        form_id: formId,
        field_id: fieldId,
        return_to: returnUrl,
    })

    return `${path}?${queryParams}`
}

export default StandaloneSearchSelectMobile
