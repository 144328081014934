import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../../redux/store/hooks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import ReadOnlyCarHistoryFileEntryGallery from '../../../templates/readOnly/car/readOnlyHistoryFileEntryGallery'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'

const ExternalCarSingleEntryGallery: React.FC = () => {
    const dispatch = useAppDispatch()
    const { carid, entryid } = useParams<{ carid: string; entryid: string }>()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )

    useEffect(() => {
        if (carid && entryid) {
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `shared/car/${carid}/history-file/${entryid}/gallery`
                )
            )

            if (!userLoggedIn) {
                dispatch(usersActions.getCurrentUserDataRequest())
            }

            dispatch(carActions.setCurrentExternalCarSuccess(carid))
            dispatch(carActions.getExternalCarsSharedWithYouRequest(carid))
        }
    }, [carid, entryid, userLoggedIn])

    const currentExternalCar =
        externalCarsData && carid ? externalCarsData[carid] : undefined

    return (
        <ReadOnlyCarHistoryFileEntryGallery
            sharedCar={currentExternalCar}
            userLoggedIn={userLoggedIn}
        />
    )
}

export default ExternalCarSingleEntryGallery
