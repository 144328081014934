import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertAPIEntryToTimelineItemSingle } from '../../conversions/timeline/entryToTimelineItem'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'

import { ICurencyUIDsEnum } from '../timelineEnum'
import {
    getCurrentUserDataSaga,
    selectStateUserCurrency,
} from '../../user/sagas/getUserData'
import { categoryNameToEntryTypeArr } from '../../conversions/timeline/categoriesToEntryTypes'
import { timelineActions } from '../reducer'
import { IRootState } from '../../store'
import { IEntryPayloadAPI } from '../../services/typedefinitions/apiPayloads'
import { IFilterEntriesPayloadAPIReq } from '../../services/types'
import { IUser } from '../../user/types'
import {
    IFilterTimelineByCategoryNLabelNTitleQCarIDRequest,
    ILabel,
    IFilterTimelineByCategoryNLabelCarIDSuccess,
    ITimelineItem,
} from '../types'
import { IReduxError } from '../../entities/cars/types'

const getUser = (state: IRootState) => state.user.userLoggedIn

export function* FilterCarTimelineByCategoryNLabelIdsNTitleQSaga(
    payload: IFilterTimelineByCategoryNLabelNTitleQCarIDRequest
): any {
    try {
        //Step 1 - call the api func and keep data returned in a const

        let user: IUser | null = yield select(getUser)

        if (!user) {
            yield call(getCurrentUserDataSaga)
            user = yield select(getUser)
        }

        let userLabelsInState: ILabel[] | undefined | null = user && user.labels

        let labelsIdsPayload: any =
            userLabelsInState &&
            userLabelsInState
                .filter((labelInState) =>
                    payload.label_ids?.includes(labelInState.name)
                )
                .map((label) => label.uid)
                .filter((label_uid) => label_uid)

        let user_current_curr: ICurencyUIDsEnum = yield call(
            selectStateUserCurrency
        )
        if (payload.category_ids) {
            let p: IFilterEntriesPayloadAPIReq = {
                car_id: payload.car_id,
                entry_type_ids: categoryNameToEntryTypeArr(
                    payload.category_ids
                ),
                labels_ids: labelsIdsPayload,
                entry_title: payload.entry_title,
                user_currency: user_current_curr,
            }

            const api_res: any = yield call(
                api.timeline.entries.getEntriesData
                    .getAllEntriesByCarIdAndCategoriesNLabelsNTitleQAPI,
                p
            )

            if (
                api_res && // 👈 null and undefined check
                Object.keys(api_res).length === 0 &&
                Object.getPrototypeOf(api_res) === Object.prototype
            ) {
                let res: IFilterTimelineByCategoryNLabelCarIDSuccess = {
                    car_id: payload.car_id,
                    results: [],
                }
                yield put(
                    timelineActions.filterTimelineItemsByCarCategoryIdsNLabelIdsSuccess(
                        res
                    )
                )
                posthog.capture('GET TIMELINE BY CAR ID WITH FILTERS')
            } else {
                let filtered_entries_from_api: IEntryPayloadAPI[] = api_res.data

                //Step 2 - filtering array from api according to set categories
                let entries_by_car_id_filtered_by_category_n_label_ids: ITimelineItem[] =
                    []
                for (const entry of filtered_entries_from_api) {
                    let converted_entry =
                        ConvertAPIEntryToTimelineItemSingle(entry)
                    entries_by_car_id_filtered_by_category_n_label_ids.push(
                        converted_entry
                    )
                }

                // STEP 3 . build up payload to return to reducer
                let res: IFilterTimelineByCategoryNLabelCarIDSuccess = {
                    car_id: payload.car_id,
                    results: entries_by_car_id_filtered_by_category_n_label_ids,
                }

                yield put(
                    timelineActions.filterTimelineItemsByCarCategoryIdsNLabelIdsSuccess(
                        res
                    )
                )

                // Capture event
                posthog.capture('GET TIMELINE BY CAR ID WITH FILTERS')
            }
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat:
                    FilterCarTimelineByCategoryNLabelIdsNTitleQSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let err: IReduxError = ConvertToReduxError(error)
            yield put(
                timelineActions.filterTimelineItemsByCarCategoryIdsNLabelIdsError(
                    err
                )
            )
        }
    }
}

function* watcherFilterCarTimelineByCategoryNLabelIds() {
    while (true) {
        const { payload } = yield take(
            timelineActions.filterTimelineItemsByCarCategoryIdsNLabelIdsRequest
        )

        yield call(FilterCarTimelineByCategoryNLabelIdsNTitleQSaga, payload)
    }
}

const get_filtered_car_timeline_by_category_ids: any[] = [
    fork(watcherFilterCarTimelineByCategoryNLabelIds),
]

export default get_filtered_car_timeline_by_category_ids
