import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { IRootState } from '../../store'
import { tasksActions } from '../reducer'
import { usersActions } from '../../user/reducer'
import { carActions } from '../../entities/cars/reducer'
import { IDeleteTaskPayload } from 'entityModels'
import {
    ITasksNormalisedByCar,
    IReduxError,
    ICustomErrorData,
} from '../../entities/cars/types'

// GET TASK BY TASK ID

const getCarError = (state: IRootState) => state.entities.carsData.error
const getCarsTasksDataInState = (state: IRootState): ITasksNormalisedByCar =>
    state.entities.carsData.tasks

function* deleteTaskSaga(payload: IDeleteTaskPayload): any {
    // later on : put API TRY CATH

    try {
        yield call(api.tasks.deletion.deleteTask, payload.task.id)

        yield put(tasksActions.deleteTaskSuccess(payload.task.id))

        yield put(usersActions.updateUserTasksCountOnTasksDeletion())

        let carsTasksDataInState = yield select(getCarsTasksDataInState)

        if (payload.task.linkedToCar) {
            for (const id of payload.task.linkedToCar) {
                if (carsTasksDataInState[id]) {
                    if (carsTasksDataInState[id].includes(payload.task.id)) {
                        let new_arr = carsTasksDataInState[id].filter(
                            (taskid: string) => taskid !== payload.task.id
                        )
                        yield put(
                            carActions.updateCarTasksArrayOnTasksCreationOrDeletion(
                                { car_id: id, tasks_ids: new_arr }
                            )
                        )
                    }
                }
            }
        }

        // Capture event
        posthog.capture('DELETE TASK')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: deleteTaskSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let carError: IReduxError = yield select(getCarError)
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't delete the task`,
                custom_user_action_text: 'Return to tasks',
                custom_redirect_path:
                    payload.car_id && !carError
                        ? `/car/${payload.car_id}/tasks`
                        : '/tasks',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(tasksActions.deleteTaskError(customError))
        }
    }
}

function* watcherDeleteTask() {
    while (true) {
        const { payload } = yield take(tasksActions.deleteTaskRequest)

        yield call(deleteTaskSaga, payload)
    }
}

const delete_task: any[] = [fork(watcherDeleteTask)]

export default delete_task
