import styled from 'styled-components'
import { useAppSelector } from '../../../../redux/store/hooks'
import { device } from '../../../templates/displays/devices'
import { IApplicationInfo } from '../../../../redux/insuranceQuoteApplication/reducer'
import useThemes from '../../../../providers/theme/hooks'
import { IRootState } from '../../../../redux/store'
import { ITheme } from '../../../../providers/theme/colours'
import { fromIsoStringToDDMMYYY } from '../../../../redux/conversions/time'
import { useEffect } from 'react'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    padding-bottom: 120px;
    @media ${device.beyond_mobile_ipad} {
        padding: 32px;
        height: 100%;
        justify-content: space-between;
    }
`
const SubWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media ${device.mobile} {
        gap: 24px;
    }
`

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`

const TextSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media ${device.mobile} {
        gap: 8px;
    }
`

const Title = styled.h2`
    margin: 0px;
    padding: 0px;
    color: #1a1a1a;
    font-family: Lato-semibold;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.001px;
    @media ${device.mobile} {
        font-size: 16px;
    }
`

const GreyTitle = styled.p`
    margin: 0px;
    padding: 0px;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`
const DriverName = styled.p`
    margin: 0px;
    padding: 0px;
    color: var(--text-darker, #616161);
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

const BlackText = styled.p`
    margin: 0px;
    padding: 0px;
    color: #1a1a1a;
    font-family: Lato;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.001px;
    @media ${device.mobile} {
        font-size: 16px;
    }
`

const Line = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    margin-top: 32px;
    margin-bottom: 32px;
    @media ${device.mobile_and_ipad} {
        margin-top: 24px;
        margin-bottom: 24px;
    }
`

const TxtBtn = styled.button<{ $theme: ITheme }>`
    all: unset;
    color: var(--primary, #5ec3ca);
    text-align: center;
    font-family: Lato-semibold;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
`

const Dot = styled.div`
    width: 4px;
    height: 4px;
    background-color: var(--text-default, #666);
    border-radius: 4px;
`

const handleTouchMove = (e: any) => {
    let targetElement: HTMLElement | null =
        document.querySelector('#scrollable-content') &&
        document.querySelector('#scrollable-content')

    let scrollableContent: HTMLElement[] = []

    targetElement &&
        document
            .querySelectorAll('#scrollable-content *')
            .forEach((node: any) => scrollableContent.push(node))

    const targetIsElement =
        targetElement !== null && e.target.id === targetElement.id
    const modalContainsTarget = scrollableContent.includes(e.target)

    const shouldPreventDefault = !(targetIsElement || modalContainsTarget)

    if (shouldPreventDefault) e.preventDefault()
}

type Props = {
    application_info: IApplicationInfo
}

const PolicyOfferModal = (props: Props) => {
    const { application_info } = props
    const { theme } = useThemes()

    let userLoggedIn = useAppSelector(
        (state: IRootState) => state.user.userLoggedIn
    )

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        if (window.matchMedia('(display-mode: standalone)').matches) {
            // document.body.ontouchmove = (e) => e.preventDefault()
            window.addEventListener('touchmove', handleTouchMove, {
                passive: false,
            })
        }

        return () => {
            document.body.style.overflow = 'auto'
            if (window.matchMedia('(display-mode: standalone)').matches) {
                // document.body.ontouchmove = (e) => true
                window.removeEventListener('touchmove', handleTouchMove)
            }
        }
    }, [])

    return (
        <Wrapper id="scrollable-content">
            <SubWrapper>
                <Title>
                    {`${userLoggedIn?.given_name}'s ${
                        application_info.carsInfoArr.length > 1
                            ? 'multi-car'
                            : ''
                    } policy`}
                </Title>
                <Line />
                <Column>
                    {application_info.carsInfoArr.map((car, i) => {
                        return (
                            <TextSection>
                                <BlackText>
                                    {car?.title ?? `Untitled #${i}`}
                                </BlackText>
                                <Row>
                                    <DriverName>
                                        {`${userLoggedIn?.given_name} ${userLoggedIn?.family_name}`}
                                    </DriverName>
                                    {car.drivers?.map((driver) => (
                                        <Row>
                                            <Dot />
                                            <DriverName>{driver}</DriverName>
                                        </Row>
                                    ))}
                                </Row>
                            </TextSection>
                        )
                    })}
                </Column>
                <Line />
                <Column style={{ gap: 16 }}>
                    <TextSection>
                        <GreyTitle>Quote/Policy number</GreyTitle>
                        <BlackText>#{application_info.uid}</BlackText>
                    </TextSection>
                    <TextSection>
                        <GreyTitle>Start date</GreyTitle>
                        <BlackText>
                            {application_info.start_date
                                ? fromIsoStringToDDMMYYY(
                                      application_info.start_date
                                  )
                                : '23 Sep 2022'}
                        </BlackText>
                    </TextSection>
                    <TextSection>
                        <GreyTitle>End date</GreyTitle>
                        <BlackText>
                            {application_info.end_date
                                ? fromIsoStringToDDMMYYY(
                                      application_info.end_date
                                  )
                                : '23 Sep 2023'}
                        </BlackText>
                    </TextSection>
                </Column>
                <Line />
                <TxtBtn $theme={theme} onClick={() => {}}>
                    See full offer
                </TxtBtn>
            </SubWrapper>
            <div>
                <Line />
                <Row
                    style={{
                        justifyContent: 'space-between',
                    }}
                >
                    <BlackText style={{ color: '#616161' }}>
                        Policy price
                    </BlackText>
                    <BlackText style={{ fontSize: 32, color: '#1a1a1a' }}>
                        {`£${
                            application_info.offer
                                ? application_info.offer.toLocaleString()
                                : (1250).toLocaleString()
                        }`}
                    </BlackText>
                </Row>
            </div>
        </Wrapper>
    )
}

export default PolicyOfferModal
