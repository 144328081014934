import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { Sheet } from 'react-modal-sheet'
import { device } from '../../../templates/displays/devices'
import {
    CarActionMenuItemsID,
    CarOptionMenuItem,
    ICarActionMenuItemsData,
} from '../../../pages/car/carOverview'
import Icon from '../../icons'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import { Spin, message } from 'antd'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

const CustomSheet = styled(Sheet)<{ $customHeight?: string; $theme: ITheme }>`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '230px !important'};
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
    }
    .react-modal-sheet-header {
        height: 10px !important;
        background-color: ${(props) =>
            colours[props.$theme].modal_side_sheet_pop_over} !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 0px !important;
    }
    .react-modal-sheet-content {
        background-color: ${(props) =>
            colours[props.$theme].modal_side_sheet_pop_over} !important;
        /* custom styles */
    }
`
const MenuItem = styled.div<IStyle>`
    height: 56px;
    width: 100vw;
    opacity: 1 !important;
    font-family: 'Lato';
    color: ${(props) =>
        props.$isDisabled
            ? `${colours[props.$theme].text_disabled} !important;`
            : props.$customColor
            ? props.$customColor
            : colours[props.$theme].text_default};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
`

const MenuItemBox = styled(motion.div)<IStyle>`
    position: relative;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};

    padding-left: 30px;
    padding-right: 40px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    :focus {
        outline: 0;
    }
`

const BottomSheetHeader = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 40px;
    border-bottom: 1px solid ${(props) => colours[props.$theme].border_muted};
`

const BottomSheetHeaderText = styled.div<IStyle>`
    font-size: 16px;
    font-family: 'Lato-bold';
    color: ${(props) => colours[props.$theme].text_strong};
    padding-top: 10px;
`

const StyledSpin = styled(Spin)<IStyle>`
    transform: scale(0.7);
    i {
        background-color: ${(props) => colours[props.$theme].primary};
    }
`

interface IStyle {
    $theme: ITheme
    is_last?: boolean
    $isDisabled?: boolean
    $customColor?: string
}

interface IProps {
    isOpen: boolean
    data: (theme: ITheme) => ICarActionMenuItemsData
    setActiveIDToUndefined: any
    toggle: any
    activeID: CarActionMenuItemsID | undefined
}

const CarOverviewActionsSheetMobile: React.FunctionComponent<IProps> = (
    props: IProps
) => {
    let { data, activeID, toggle, isOpen, setActiveIDToUndefined } = props
    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false
    const { theme } = useThemes()

    let dataa = data(theme)
    return (
        <React.Fragment>
            {isMobile && (
                <CustomSheet
                    $theme={theme}
                    isOpen={isMobile && isOpen && activeID ? true : false}
                    onClose={() => {
                        if (activeID) {
                            setActiveIDToUndefined()
                        } else {
                            toggle()
                        }
                    }}
                    $customHeight={'max-content'}
                >
                    <Sheet.Container>
                        <Sheet.Header />

                        <BottomSheetHeader $theme={theme}>
                            <BottomSheetHeaderText $theme={theme}>
                                {activeID && dataa[activeID]
                                    ? dataa[activeID].name
                                    : ''}
                            </BottomSheetHeaderText>
                        </BottomSheetHeader>

                        <div
                            style={{
                                backgroundColor:
                                    colours[theme].modal_side_sheet_pop_over,
                                paddingTop: '6px',
                            }}
                            data-attr="showroom-options-menu"
                        >
                            {activeID !== undefined &&
                                dataa[activeID].options.map(
                                    (item: CarOptionMenuItem, index: any) => (
                                        <MenuItemBox
                                            $theme={theme}
                                            style={{
                                                color:
                                                    item.customColor ??
                                                    '#333333',
                                            }}
                                            is_last={
                                                index ===
                                                dataa[activeID!].options
                                                    .length -
                                                    1
                                            }
                                            onClick={(e: any) => {
                                                if (!item.isDisabled) {
                                                    item?.action()
                                                    setActiveIDToUndefined()
                                                    toggle()
                                                } else {
                                                    message.error(item?.tooltip)
                                                }
                                            }}
                                            key={`dropsheet_${item.id}_${index}`}
                                            whileTap={{
                                                opacity: 1,
                                                backgroundColor:
                                                    colours[theme].border_muted,
                                            }}
                                        >
                                            {item.iconLoading ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        backgroundColor:
                                                            colours[theme]
                                                                .background_neutral_subtle,
                                                        top: 0,
                                                        bottom: 0,
                                                        left: 24,
                                                        width: 24,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        zIndex: 4,
                                                    }}
                                                >
                                                    <StyledSpin
                                                        $theme={theme}
                                                    />
                                                </div>
                                            ) : null}
                                            <div
                                                style={{
                                                    width: 24,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Icon
                                                    height={'16px'}
                                                    isDisabled={item.isDisabled}
                                                    width={'16px'}
                                                    icon={item.icon}
                                                    color={
                                                        item.customColor ??
                                                        undefined
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{ paddingLeft: '12px' }}
                                            />
                                            <MenuItem
                                                $theme={theme}
                                                $isDisabled={item.isDisabled}
                                                $customColor={item.customColor}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        </MenuItemBox>
                                    )
                                )}
                        </div>

                        <div style={{ paddingTop: '10px' }} />
                    </Sheet.Container>
                    <Sheet.Backdrop
                        data-attr="showroom-actions-backdrop-overlay"
                        onTap={() => {
                            if (activeID) {
                                setActiveIDToUndefined()
                            } else {
                                toggle()
                            }
                        }}
                    />
                </CustomSheet>
            )}
        </React.Fragment>
    )
}

export default CarOverviewActionsSheetMobile
