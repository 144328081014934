import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import InputField from '../../../atoms/Inputfield/inputField'
import ButtonsRowDeletionDesktop from '../buttons/buttonsRowDeletionDesktop'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

const Wrapper = styled.div`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        color: var(--text-strong, #1a1a1a);
    }
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 20px;
    color: var(--text-strong, #1a1a1a);
`

const SmallPadding = styled.div`
    padding-top: 24px;
`

const LargePadding = styled.div`
    padding-top: 120px;
`

const StatementText = styled.div`
    max-width: 900px;
    font-family: Lato-Light;
    font-size: 20px;
`

interface Values {
    car_title_deletion_desktop: string
    isAgreementChecked: boolean
}

interface OtherProps {
    cancel: () => void
    submitFunc: (carid: string) => void
    carid: string
    car_title: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { values, cancel, car_title, setFieldValue } = props

    const { theme } = useThemes()

    return (
        <Wrapper>
            <FieldTitle>
                To confirm the car deletion, please enter the title of the car :
                <span style={{ paddingLeft: '5px' }} />`{car_title}`
            </FieldTitle>

            <SmallPadding />
            <div style={{ maxWidth: 500 }}>
                <InputField
                    theme={theme}
                    id="car_title_deletion_desktop"
                    name="car_title_deletion_desktop"
                    placeholder="Car title"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.car_title_deletion_desktop}
                    font_family="Lato"
                    fontSize="19px"
                    width="500px"
                />
            </div>

            <SmallPadding />
            <SmallPadding />

            <FieldTitle>And agree to the following statement :</FieldTitle>

            <SmallPadding />

            <StatementText>
                I understand that deleting my car will delete all the
                aforementioned images, assets, and information. This car will no
                longer appear in my garage and unable to be recovered.
            </StatementText>

            <SmallPadding />
            <CustomAnimatedCheckboxField
                onChange={(e: any) => {
                    setFieldValue(
                        'isAgreementChecked',
                        !values.isAgreementChecked
                    )
                }}
                name={'isAgreementChecked'}
                checked={values.isAgreementChecked}
                labelfontstyle={'italic'}
                labelfontsize={'19px'}
                labelcolor={colours[theme].text_muted}
            >
                I have read the statement above and agree.
            </CustomAnimatedCheckboxField>

            <LargePadding />
            <ButtonsRowDeletionDesktop
                // push nav on cancel click
                cancel={cancel}
                submit={props.handleSubmit}
                secondText="delete"
                isDisabled={
                    !values.isAgreementChecked ||
                    values.car_title_deletion_desktop !== car_title
                }
            />
        </Wrapper>
    )
}

const CarDeletionFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        car_title_deletion_desktop: '',
        isAgreementChecked: false,
    }),
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
        props.submitFunc && props.submitFunc(props.carid)
    },
})(InnerForm)

export default CarDeletionFormDesktop
