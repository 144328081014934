import { ISetTaskRemindersEditedPayload } from 'entityModels'
import { call, fork, put, take } from 'redux-saga/effects'
import { tasksActions } from '../reducer'

function* setTaskEditedRemindersSaga(
    payload: ISetTaskRemindersEditedPayload
): any {
    yield put(tasksActions.setTaskRemindersEditedSuccess(payload))
}

function* watcherSetTaskRemindersEdited() {
    while (true) {
        const { payload } = yield take(
            tasksActions.setTaskRemindersEditedRequest
        )
        yield call(setTaskEditedRemindersSaga, payload)
    }
}

const set_task_reminders_edited: any[] = [fork(watcherSetTaskRemindersEdited)]

export default set_task_reminders_edited
