import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import AddHistoryFileJourneyStepDesktop from './addDesktop'
import AddHistoryFileJourneyStepMobile from './addMobile'
import { ITimelineItem } from '../../../../../../redux/timeline/types'

type Props = {
    onAdd: (
        entry1: ITimelineItem | undefined,
        entry2: ITimelineItem | undefined,
        entry3: ITimelineItem | undefined
    ) => any
}
export const JourneyHistoryFileAddStep = (props: Props) => {
    const location = useLocation()
    let params = new URLSearchParams(location.search)
    let carid: string | null = params.get('carid')

    const [entry1, setEntry1] = useState<undefined | ITimelineItem>()
    const [entry2, setEntry2] = useState<undefined | ITimelineItem>()
    const [entry3, setEntry3] = useState<undefined | ITimelineItem>()

    const navigate = useNavigate()

    let locationState: any = location.state

    return (
        <div style={{ width: '100%' }}>
            <AddHistoryFileJourneyStepDesktop
                step={2}
                entry1={entry1}
                entry2={entry2}
                entry3={entry3}
                setEntry1={setEntry1}
                setEntry2={setEntry2}
                setEntry3={setEntry3}
                totalSteps={3}
                goToPrevStep={() => {
                    locationState?.prevPath
                        ? navigate(locationState?.prevPath)
                        : navigate(
                              `/journey/history-file?step=learn&carid=${carid}`
                          )
                }}
                goToNextStep={() => {
                    props.onAdd(entry1, entry2, entry3)
                }}
            />
            <AddHistoryFileJourneyStepMobile
                entry1={entry1}
                entry2={entry2}
                entry3={entry3}
                setEntry1={setEntry1}
                setEntry2={setEntry2}
                setEntry3={setEntry3}
                step={2}
                totalSteps={3}
                goToPrevStep={() => {
                    locationState?.prevPath
                        ? navigate(locationState?.prevPath)
                        : navigate(
                              `/journey/history-file?step=learn&carid=${carid}`
                          )
                }}
                goToNextStep={() => {
                    props.onAdd(entry1, entry2, entry3)
                }}
            />
        </div>
    )
}
