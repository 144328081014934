import * as React from 'react'
import styled from 'styled-components'
import SectionHeaderV2 from '../../atoms/typography/sectionHeaderV2'
import { device } from '../../templates/displays/devices'
import copy_icon from '../../../public/assets/icons/copy_icon.svg'
import FilterByMultipleDesktop from '../../organisms/filterByMultiple/timeline/filterByMultipleDesktop'
import DropdownExpanderHover from '../../atoms/menu/dropdownExpander/dropdownExpanderHover'
import { sharing_permissions_ids_selectable_list_mock } from '../../../redux/entities/cars/mockSharedItems'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import { motion } from 'framer-motion'
import { Tooltip } from 'antd'
import NoFilterResults from '../../atoms/noFilterResults/noFilterResults'
import DirectShareRowDesktop from '../../atoms/shareRow/directShareRowDesktop'
import AddBoxTextInfo from '../../atoms/typography/addBoxTextInfo'
import Icon from '../../atoms/icons'
import {
    IFilterGarageSharesDisplay_change_request,
    ISortGarageSharesDisplay_change_request,
} from '../../../redux/entities/garages/actions/loadingActions'
import QrCodeBtn from '../../atoms/Button/qrCodeBtn'
import { editFormsActions } from '../../../redux/editForms/reducer'
import PublicLinkDeactivConfDesktop from '../deletionConfirmationDesktop/publicLinkDeactivModal'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IGarage } from '../../../redux/entities/garages/types'
import { IDeleteShareReq } from '../../../redux/entities/cars/actions'
import { IisOpen } from '../../../redux/menus/types'
import {
    IDirectShareOwnedItem,
    ISharingPermissionIDS,
} from '../../../redux/entities/cars/types'
import { IDropdownItem } from 'entityModels'

interface Props {
    garage: IGarage | undefined
    createGaragePublicShare: (p: string) => void
    dataCyId?: string
    handleChange_shares_filter: (
        p: IFilterGarageSharesDisplay_change_request
    ) => void
    handleChange_shares_sort: (
        p: ISortGarageSharesDisplay_change_request
    ) => any
    deleteGaragePublicShare: (p: IDeleteShareReq) => void
    openGarageQrCodeModal: (payload: { isOpen: boolean }) => void
    areGarageSharesLoading: boolean
}

interface IStyled {
    isActive?: boolean
}

const Container = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Lato';
    background-color: var(--bg-color, #fff);
    padding-bottom: 50px;
`

const TitleWrapper = styled.div<IStyled>`
    max-width: 450px;
`

const Row = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    min-width: 730px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const Description = styled.div<IStyled>`
    font-family: 'Lato';
    font-size: 18px;
    overflow-wrap: break-word;
    color: var(--text-darker, #616161);
    line-height: 22px;

    @media ${device.smallest_laptops} {
        font-size: 17px;
    }

    @media ${device.ipad} {
        width: 70%;
        max-width: 450px;
    }
`

const Tag = styled.div<IStyled>`
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${(props) =>
        props.isActive
            ? 'var(--insurance_details_green)'
            : 'var(--border_muted_neutral)'};
    color: var(--bg-color, #fff);
    font-family: 'Lato-Bold';
    text-align: center;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 20px;
    font-size: 14px;
`

const BtnBlue = styled.a`
    color: var(--primary, #5ec3ca) !important;
    font-size: 15px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: auto;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    :hover {
        opacity: 0.7;
    }
`

const BtnRed = styled.button`
    color: var(--error, #df6f6f);
    font-size: 15px;
    font-family: 'Lato';
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: auto;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    :hover {
        opacity: 0.7;
    }
    padding: 0px;
`

const LinkWrapper = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 10px;
    min-width: 550px;
    transition: all 0.1s;
    cursor: pointer;
    background-color: transparent;
    :focus {
        outline: 0;
    }
    :hover {
        opacity: 0.7;
    }
`

const LinkTxt = styled.div`
    color: var(--text-darker, #616161);
    font-size: 14px;
    padding-right: 25px;
    font-family: 'Lato';
`
const CopyIcon = styled.img`
    height: 20px;
`

const GoToBoxDesktop = styled.div`
    @media ${device.mobile} {
        display: none;
    }

    background: var(--primary_16, rgba(94, 195, 202, 0.16));
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;
    height: 70px;
    width: 40vw;
    box-sizing: border-box;
    min-width: 730px;

    @media ${device.ipad} {
        min-width: 710px;
        padding-right: 0px;
    }
    margin-bottom: 10px;

    :hover {
        background-color: var(--bg-color, #fff);
        color: var(--bg-color, #fff);
        border: 1px solid var(--primary_80);
        transform: scale(1.015);
    }
`
const FiltersContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

const DropdownHoverWrapper = styled.div`
    border-radius: 5px;
    height: 40px;
`

const SingleGarageShareBodyDesktop: React.FC<Props> = ({
    dataCyId,
    garage,
    createGaragePublicShare,
    handleChange_shares_filter,
    handleChange_shares_sort,
    openGarageQrCodeModal,
    deleteGaragePublicShare,
    areGarageSharesLoading,
}) => {
    const [showBoxInfo, setShowBoxInfo] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const publicLinkDeactivationConfirmationModal = useAppSelector(
        (state) =>
            state.editForms.formsData.publicLinkDeactivationConfirmationModal
                .isOpen
    )

    const togglePublicLinkDeactivConfModal = (p: IisOpen) => {
        dispatch(editFormsActions.togglePublicLinkDeactivConfModal(p))
    }

    const setTrueBoxInfoToggle = () => setShowBoxInfo(true)
    const setFalseBoxInfoToggle = () => setShowBoxInfo(false)

    const onAddShareClick = () => {
        garage && navigate(`/sharing/with-others/garage/create-share`)
    }

    const toggleTooltip = () => {
        setShowTooltip(true)
        setTimeout(() => {
            setShowTooltip(false)
        }, 500)
    }

    let has_garage_public_share: boolean =
        garage &&
        garage.public_share_owned &&
        garage.public_share_owned.isActive
            ? garage.public_share_owned.isActive
            : false

    let categoryData: IDropdownItem[] =
        sharing_permissions_ids_selectable_list_mock

    let garagePrivateSharesOwned = garage && garage.private_shares_owned
    let garageHasDirectShares: boolean =
        garagePrivateSharesOwned &&
        garagePrivateSharesOwned.entity_owned_direct_shares_ids &&
        garagePrivateSharesOwned.entity_owned_direct_shares_ids.length > 0
            ? true
            : false

    let directSharesToDisplayExtractor = (): {
        shares: IDirectShareOwnedItem[] | undefined
        hasActiveSearchCriteria: boolean
    } => {
        let sharesToDisplayArray: IDirectShareOwnedItem[] = []
        if (garagePrivateSharesOwned) {
            if (
                garagePrivateSharesOwned.active_display &&
                garagePrivateSharesOwned.active_display.item_ids_display &&
                garagePrivateSharesOwned.active_display.item_ids_display
                    .length > 0
            ) {
                garagePrivateSharesOwned.active_display.item_ids_display.map(
                    (shareID: string, index: number) =>
                        sharesToDisplayArray.push(
                            garagePrivateSharesOwned!
                                .entity_owned_direct_shares_data[shareID]
                        )
                )
                return {
                    shares:
                        sharesToDisplayArray && sharesToDisplayArray.length > 0
                            ? sharesToDisplayArray
                            : undefined,
                    hasActiveSearchCriteria: true,
                }
            } else if (
                garagePrivateSharesOwned.active_display &&
                garagePrivateSharesOwned.active_display?.activeFilter
            ) {
                return {
                    shares: sharesToDisplayArray
                        ? sharesToDisplayArray
                        : undefined,
                    hasActiveSearchCriteria: true,
                }
            } else {
                garagePrivateSharesOwned &&
                    garagePrivateSharesOwned.entity_owned_direct_shares_ids &&
                    garagePrivateSharesOwned.entity_owned_direct_shares_ids.map(
                        (shareID: string, index: number) =>
                            sharesToDisplayArray.push(
                                garagePrivateSharesOwned!
                                    .entity_owned_direct_shares_data[shareID]
                            )
                    )
                return {
                    shares:
                        sharesToDisplayArray && sharesToDisplayArray.length > 0
                            ? sharesToDisplayArray
                            : undefined,
                    hasActiveSearchCriteria: false,
                }
            }
        } else return { shares: undefined, hasActiveSearchCriteria: false }
    }

    let directSharesToDisplay: IDirectShareOwnedItem[] | undefined =
        directSharesToDisplayExtractor().shares

    let hasActiveSearchCriteria: boolean =
        directSharesToDisplayExtractor().hasActiveSearchCriteria

    return (
        <Container data-attr={dataCyId && dataCyId}>
            <TitleWrapper>
                <Row>
                    <SectionHeaderV2 isbold icon="v2_add" hasActionIcon={false}>
                        Shareable Link
                    </SectionHeaderV2>
                    <Tag isActive={has_garage_public_share}>
                        {has_garage_public_share === true
                            ? 'active'
                            : 'inactive'}
                    </Tag>
                </Row>
            </TitleWrapper>
            <Description>
                A link share allows you to publicly share your garage - and its
                respective cars - with anyone you'd like. <br />
                You can remove access to this link at any time.
            </Description>

            <div
                style={{
                    paddingTop: has_garage_public_share ? '30px' : '20px',
                }}
            />

            {areGarageSharesLoading ? (
                <LoaderElasticThreeDots isLoading={areGarageSharesLoading} />
            ) : has_garage_public_share ? (
                <>
                    <div style={{ display: 'flex' }}>
                        <LinkWrapper
                            onClick={() => {
                                toggleTooltip()
                                navigator.clipboard.writeText(
                                    garage &&
                                        garage.public_share_owned &&
                                        garage.public_share_owned.url
                                        ? garage.public_share_owned.url
                                        : ''
                                )
                            }}
                        >
                            <LinkTxt>
                                {garage &&
                                    garage.public_share_owned &&
                                    garage.public_share_owned.url}
                            </LinkTxt>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CopyIcon src={copy_icon} />

                                <Tooltip
                                    title="Copied!"
                                    open={showTooltip}
                                    placement="bottom"
                                    color={'#2a969ad3'}
                                />
                            </div>
                        </LinkWrapper>
                        <div style={{ paddingRight: '20px' }} />
                        <QrCodeBtn
                            txt="qr code"
                            height="42px"
                            iconSize="25px"
                            onClick={openGarageQrCodeModal}
                            alwaysHasText
                        />
                    </div>
                    <div
                        style={{
                            paddingTop: '30px',
                        }}
                    />
                    <BtnBlue
                        href={
                            garage &&
                            garage.public_share_owned &&
                            garage.public_share_owned.url
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Preview Digital Garage
                    </BtnBlue>
                    <div style={{ paddingTop: '15px' }} />
                    <BtnRed
                        onClick={() =>
                            togglePublicLinkDeactivConfModal({
                                isOpen: true,
                            })
                        }
                    >
                        Deactivate Shareable Link
                    </BtnRed>
                    <div style={{ paddingTop: '15px' }} />
                </>
            ) : (
                <motion.div whileTap={{ scale: 1.05 }}>
                    {!has_garage_public_share ? (
                        <div style={{ paddingTop: '30px' }} />
                    ) : null}
                    <ButtonAtom
                        onClick={() => {
                            garage &&
                                garage.uid &&
                                createGaragePublicShare(garage.uid)
                        }}
                        theme="lowercase-white-background"
                        height="50px"
                        width="550px"
                    >
                        <div style={{ textTransform: 'capitalize' }}>
                            Generate Garage Shareable Link
                        </div>
                    </ButtonAtom>
                </motion.div>
            )}

            <div style={{ paddingTop: '50px' }} />

            <Row>
                <SectionHeaderV2
                    isbold
                    hasActionIcon={false}
                    width="100%"
                    id="garage_privately_shared"
                >
                    Garage Direct Shares
                </SectionHeaderV2>
            </Row>
            <Row>
                <Description>
                    A direct share allows you to privately share your garage -
                    and its respective cars - only with the people you choose.{' '}
                    <br />
                    You can delete a direct share at any time.
                </Description>
            </Row>
            <div style={{ paddingTop: '30px' }} />
            {garage && (
                <div style={{ transform: 'translateY(-5px)' }}>
                    <Row>
                        <FiltersContainer>
                            <DropdownHoverWrapper>
                                <DropdownExpanderHover
                                    width={'210px'}
                                    fontSize="15px"
                                    height="40px"
                                    placeholder={`Sorted by created at`}
                                    placeholderWhenSelected="Sorted by"
                                    items={[
                                        {
                                            text: 'Created at',
                                            action: () => {
                                                handleChange_shares_sort({
                                                    garageid: garage.uid,
                                                    sortID: 'created_at',
                                                })
                                            },
                                        },
                                        {
                                            text: 'Updated at',
                                            action: () =>
                                                handleChange_shares_sort({
                                                    garageid: garage.uid,
                                                    sortID: 'updated_at',
                                                }),
                                        },
                                        {
                                            text: 'Alphabetical',
                                            action: () =>
                                                handleChange_shares_sort({
                                                    garageid: garage.uid,
                                                    sortID: 'alphabetical',
                                                }),
                                        },
                                    ]}
                                    isDisabled={garageHasDirectShares === false}
                                />
                            </DropdownHoverWrapper>

                            <FilterByMultipleDesktop
                                categoryData={categoryData}
                                selectedFilteringLabels={[]}
                                selectedFilteringCategories={
                                    garage.private_shares_owned &&
                                    garage.private_shares_owned
                                        .active_display &&
                                    garage.private_shares_owned.active_display
                                        .activeFilter
                                }
                                filterBySelected={(
                                    selected: ISharingPermissionIDS[]
                                ) => {
                                    handleChange_shares_filter({
                                        garageid: garage.uid,
                                        filterIDs: selected,
                                    })
                                }}
                                customSideCopy={'Filter By'}
                                buttonMinWidth={'170px'}
                                entityID="sharing"
                                isDisabled={garageHasDirectShares === false}
                                notCapitalised
                            />
                        </FiltersContainer>
                    </Row>

                    <div style={{ paddingTop: '30px' }} />

                    {directSharesToDisplay &&
                    directSharesToDisplay.length > 0 ? (
                        directSharesToDisplay.map(
                            (share: IDirectShareOwnedItem, index: number) => (
                                <DirectShareRowDesktop
                                    key={`direct_share_desktop_${index}`}
                                    share={share}
                                    isGarage
                                    index={index}
                                    isLast={
                                        garage &&
                                        garage.private_shares_owned
                                            ?.entity_owned_direct_shares_ids &&
                                        garage.private_shares_owned
                                            ?.entity_owned_direct_shares_ids
                                            .length - 1
                                            ? true
                                            : false
                                    }
                                    actionsData={{
                                        addUserToShare: () =>
                                            navigate(
                                                `/sharing/with-others/garage/edit-share?shareid=${share.id}&active_tab_id=recipients`
                                            ),

                                        settings: () =>
                                            navigate(
                                                `/sharing/with-others/garage/edit-share?shareid=${share.id}&active_tab_id=settings`
                                            ),
                                    }}
                                />
                            )
                        )
                    ) : garageHasDirectShares && hasActiveSearchCriteria ? (
                        <NoFilterResults
                            text="No results containing all your search terms were found."
                            variant={'desktop'}
                        />
                    ) : (
                        <>
                            <GoToBoxDesktop
                                onClick={() => onAddShareClick()}
                                onMouseEnter={setTrueBoxInfoToggle}
                                onMouseLeave={setFalseBoxInfoToggle}
                            >
                                {showBoxInfo === false && (
                                    <Icon icon="add_plus" />
                                )}
                                {showBoxInfo === true && (
                                    <AddBoxTextInfo>
                                        Add a direct share to your garage
                                    </AddBoxTextInfo>
                                )}
                            </GoToBoxDesktop>
                        </>
                    )}

                    {directSharesToDisplay !== undefined && (
                        <>
                            <GoToBoxDesktop
                                onClick={() => onAddShareClick()}
                                onMouseEnter={setTrueBoxInfoToggle}
                                onMouseLeave={setFalseBoxInfoToggle}
                            >
                                {showBoxInfo === false && (
                                    <Icon icon="add_plus" />
                                )}
                                {showBoxInfo === true && (
                                    <AddBoxTextInfo>
                                        Add a direct share to your garage
                                    </AddBoxTextInfo>
                                )}
                            </GoToBoxDesktop>
                        </>
                    )}
                </div>
            )}
            <PublicLinkDeactivConfDesktop
                isOpen={publicLinkDeactivationConfirmationModal}
                toggle={() =>
                    togglePublicLinkDeactivConfModal({ isOpen: false })
                }
                action={() => {
                    deleteGaragePublicShare({
                        entity_id: garage && garage.uid ? garage.uid : '',
                        share_id:
                            garage &&
                            garage.public_share_owned &&
                            garage.public_share_owned.id
                                ? garage.public_share_owned.id
                                : '',
                    })
                }}
                actionCopy="Confirm"
                titleCopy="Are you sure?"
                detailsCopy="Once deactivated, the link and associated QR code will no longer give access to your car. If you decide to share your car in the future, this will generate a new shareable link and a different QR code, with your previous QR code no longer working."
            />
        </Container>
    )
}

export default SingleGarageShareBodyDesktop
