import React, { useEffect, useState } from 'react'
import qs from 'qs'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import styled from 'styled-components'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import CarOverviewBanner from '../../atoms/image/carOverviewBanner'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import OneLiner from '../../atoms/typography/oneLiner'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { DescriptionMobile, Paragraph } from '../../atoms/typography'
import FullWidthMobile from '../../atoms/image/fullWidthMobile'
import MobileContentBody from '../../templates/displays/pageWrappers/MobileContentBody'
import GalleryOverviewDataManagerDesktop from '../../organisms/galleryOverviewManager/desktop/galleryOverviewDataManagerDesktop'
import GalleryOverviewDataManagerMobile from '../../organisms/galleryOverviewManager/mobile/galleryOverviewDataManagerMobile'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import Loader from '../../atoms/loader/loader'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import CarDescriptionEditFormDesktop from '../../organisms/editForms/carDescriptionEditForm/carDescriptionEditFormDesktop'
import CarDescriptionEditFormMobile from '../../organisms/editForms/carDescriptionEditForm/carDescriptionEditFormMobile'
import { IHighlightedFact } from 'entityModels'
import { device } from '../../templates/displays/devices'
import TutorialConfigsCarMobile from '../../molecules/tutorialConfigs/car/tutorialConfigsCarMobile'
import TutorialConfigsCarDesktop from '../../molecules/tutorialConfigs/car/tutorialConfigsCarDesktop'
import Faded from '../../templates/animated/faded'
import AndroidInstallPwaInstructionsPropmt from '../../templates/pwaCustom/androidInstallPwaInstructionsPropmt'
import TimelineOverviewManagerDesktop from '../../organisms/timelineOverviewManager.tsx/timelineOverviewManagerDesktop'
import TimelineOverviewManagerMobile from '../../organisms/timelineOverviewManager.tsx/timelineOverviewManagerMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { getCookie } from '../../../redux/services/authcheck'
import OverviewGridManagerDesktop from '../../molecules/car/OverviewGridManagerDesktop'
import CarOverviewInfoRow from '../../atoms/car/profile/carOverviewInfoRow'
import CarKeyMomentsfactsDesktopV2 from '../../molecules/car/carKeyMomentsFactsDesktopV2'
import SimpleDescriptionParagraph from '../../atoms/typography/simpleDescriptionParagraph'
import short_description_skeleton from '../../atoms/skeleton/svg/short_description_skeleton.svg'
import CarKeyMomentsFactsMobile from '../../molecules/car/CarKeyMomentsFactsMobile'
import CarOverviewGridManagerMobile from '../../molecules/car/carOverviewGridManagerMobile'
import descSkeleton from '../../atoms/skeleton/description/skeleton.jpg'
import CreateShowroomEntryFormDesktop from '../../organisms/showroomForms/createShowroomEntryDesktop'
import CarOverviewFormsBodyDesktop from '../../organisms/carOverviewForms/carOverviewFormsBodyDesktop'
import CarOverviewFormsBodyMobile from '../../organisms/carOverviewForms/carOverviewFormsBodyMobile'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import NativeAppPromptDesktop from '../../molecules/nativeAppPrompt/nativeAppPromptDesktop'
import NativeAppPromptMobile from '../../molecules/nativeAppPrompt/nativeAppPromptMobile'
import ArchiveCarDesktopFormManager from '../../organisms/archiveForms/archiveCarDesktopManager'
import CarActionMenu from '../../atoms/menu/carActionMenu/carActionMenu'
import CarOverviewActionsSheetMobile from '../../atoms/menu/carActionMenu/carActionsMenuDropsheet'
import { TimelineCreationTypeIdsEnum } from '../../../redux/timeline/timelineEnum'
import CarActionMenuMobile from '../../atoms/menu/carActionMenu/carActionMobile'
import ShowroomTechInfoFormMobile from '../../molecules/showroom/techInfoForm/showroomTechInfoFormMobile'
import CarUserAndTitleBox from '../../atoms/user/carUserAndTitleBox'
import CarRemovedConfirmationModalDesktop from '../../molecules/showroom/editModals/desktop/carRemovedConfirmationModalDesktop'
import { ShowroomModalInnerContainer } from '../../organisms/showroom/showroomModalEditManager/showroomModalEditManagerDesktop'
import { ShowroomModalContainerMobile } from '../../organisms/showroom/showroomModalEditManager/showroomModalEditManagerMobile'
import banner_placeholder_mobile from '../../../public/assets/placeholder_images/banner_placeholder_mobile.jpg'
import banner_placeholder_mobile_dark from '../../../public/assets/placeholder_images/banner_placeholder_desktop_dark.png'
import description_section_icon_desktop from '../../../public/assets/icons/description_section_icon_desktop.svg'
import insurance_section_icon_desktop_dark from '../../../public/assets/icons/insurance_section_icon_desktop_dark.png'
import Icon from '../../atoms/icons'
import insurance_section_icon_desktop from '../../../public/assets/icons/insurance_section_icon_desktop.svg'
import description_section_icon_desktop_dark from '../../../public/assets/icons/description_section_icon_desktop_dark.png'
import { CarInsuranceSectionBodyDesktop } from '../../molecules/car/carInsuranceSectionBodyDesktop'
import { CarInsuranceSectionBodyMobile } from '../../molecules/car/carInsuranceSectionMobile'
import {
    hasDateExpired,
    isDateWithinAMonth,
} from '../../../helpers/time/withinXdays'
import posthog from 'posthog-js'
import ChevronDown from '../../atoms/icons/components/chevronDown'
import JourneyRedirectCardDesktop from '../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import JourneyRedirectCardMobile from '../../molecules/cards/journey/redirectCards/redirectCardMobile'
import FadedSlower from '../../templates/animated/FadedSlower'
import Expander from '../../atoms/expander/expander'
import { checkIfCarHasMissingJourneyInfo } from '../../../helpers/objChecks'
import CarInsightsBtn from '../../atoms/carInsights/btn'
import {
    useAppDispatch,
    useAppSelector,
    useThunkAppDispatch,
} from '../../../redux/store/hooks'
import { useLocation, useNavigate, useParams } from 'react-router'
import { editFormsActions } from '../../../redux/editForms/reducer'
import colours, { ITheme } from '../../../providers/theme/colours'
import { showroomActions } from '../../../redux/showroom/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { tutorialsActions } from '../../../redux/localdata/tutorialsCheck/reducer'
import { ITimelineItem } from '../../../redux/timeline/types'
import { IUpdateCarGeneralPayloadReq } from '../../../redux/entities/cars/types'
import useThemes from '../../../providers/theme/hooks'

export const SectionsWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const FirstSectionWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 48px;
    width: 100%;
    box-sizing: border-box;
    @media ${device.large_desktop} {
        padding-top: 64px;
        padding-bottom: 66px;
    }
`

export const GridSeparation = styled.div`
    margin-left: 2vw;
`

export const FirstSectionCol1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`

export const StyledNavBtn = styled.div`
    align-self: end;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: flex-end;
    justify-self: end;
    flex-direction: column;
    display: flex;
    padding-top: 16px;
    padding-right: 10px;
`

export const IpadWrapPadding = styled.div`
    @media ${device.ipad} {
        padding-left: 2vw;
    }
`

export const IpadWrapUserPadding = styled.div`
    @media ${device.ipad} {
        padding-left: 2vw;
        padding-top: 10px;
    }
`

const Row = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
`

const EmptyBtn = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

const MobilePadding = styled.div`
    @media (max-width: 500px) {
        padding-top: 10px;
    }
`

export const KeyMomNFactsHeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding-left: 50px;
    padding-right: 50px;
    @media (min-width: 2000px) {
        padding-left: 0px;
        padding-right: 0px;
    }
`

const SectionIconRowInsurance = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
    flex-direction: row;
`

const SectionIconRowDescription = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
    flex-direction: row;
`
const SectionRowPadding = styled.div`
    padding-top: 16px;

    @media ${device.smallest_laptops} {
        padding-top: 16px;
    }
`

const ChevronWrapper = styled.a<{ $theme: ITheme }>`
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transform: rotate(-90deg);

    @media ${device.mobile_and_ipad} {
        height: 32px;
        width: 32px;
    }

    :hover {
        background-color: ${(props) =>
            colours[props.$theme].primary_underlay} !important;
        transition: background-color 200ms;
    }
`

export type CarOptionMenuItem = {
    id: string
    name: string
    icon: string
    iconLoading?: boolean
    action: any
    customColor?: string
    isDisabled?: boolean
    tooltip?: string
}

export type ICarActionMenuItemsData = {
    [key: string]: {
        id: string
        name: string
        onClick: any
        options: CarOptionMenuItem[]
    }
}

export type CarActionMenuItemsID = 'add' | 'edit' | 'more'

const CarOverview: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const [isCarMenuOpened, setIsCarMenuOpened] = useState(false)
    const [activeCarActionMenuItemID, setActiveCarActionMenuItemID] = useState<
        CarActionMenuItemsID | undefined
    >(undefined)

    const [isShowroomPublishOpen, setIsShowroomPublishOpen] = useState(false)
    const [isCarArchiveOpen, setIsCarArchiveOpen] = useState(false)
    const [activeLocalForm, setActiveLocalForm] = useState<'tech_info' | null>(
        null
    )

    const [showJourneyCard, setShowJourneyCard] = useState(false)
    const [carActionsChecked, setCarActionsChecked] = useState(false)
    const currentCarId = useAppSelector(
        (state) => state.entities.carsData.currentCarID
    )
    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const images_data = useAppSelector(
        (state) => state.entities.galleriesData.images
    )
    const isCarLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )

    const carError = useAppSelector((state) => state.entities.carsData.error)

    const isUserLoading = useAppSelector((state) => state.user.loading)
    const highlightedFactsData = useAppSelector(
        (state) => state.entities.highlightedFactsData.highlighted_facts
    )
    const isNecessaryGalleryDataLoading = useAppSelector(
        (state) => state.entities.galleriesData.loading
    )
    const isCarDescriptionFormEditOpened = useAppSelector(
        (state) => state.editForms.formsData.carDescriptionEditForm.isOpen
    )

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const tutorialsCheck = useAppSelector(
        (state) => state.localdata.tutorials_check_state
    )
    const carsTimelineData = useAppSelector(
        (state) => state.timeline.timeline_data_normalised_by_car_id
    )
    const attachmentsObj = useAppSelector(
        (state) => state.attachments.attachmentsById
    )

    const userCurrency =
        userLoggedIn?.preferences.data.units.data.default_currency
            .user_choice_id

    const carShowroomEntryWasDeleted = useAppSelector(
        (state) => state.showroom.deletedShowroomEntryId
    )

    const openCarArchived = () => {
        setIsCarArchiveOpen(true)
        setIsCarMenuOpened(false)
    }

    const displayJourneyCard = () => {
        setShowJourneyCard(true)
    }

    const dispatchThunk = useThunkAppDispatch()

    useEffect(() => {
        dispatch(customNavDataActions.setInitialLandingUrl(`/car/${carid}`))

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else {
            if (!currentCarId || currentCarId !== carid) {
                dispatchThunk(carActions.setCurrentCar(`${carid}`))
            }

            window.scrollTo(0, 0)

            if (!carsData[`${carid}`]) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            } else if (
                carsData[`${carid}`] &&
                carsData[`${carid}`].has_limited_garage_info === true
            ) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            }

            const params = new URLSearchParams(location.search)
            if (params.get('onboarding') === 'true') {
                dispatch(tutorialsActions.setIsFirstSessionSuccess())
            }

            if (
                params.get('onboarding') !== 'true' &&
                params.get('native_app_prompt') !== 'true' &&
                tutorialsCheck.isFirstSession === true &&
                tutorialsCheck.tutorialsCheckByPageId.car === false
            ) {
                navigate(`/car/${carid}?tutorial=true`)
            }
        }

        const params = new URLSearchParams(location.search)
        let local_form_id = params.get('form_id')
        if (local_form_id === 'makeModelYear') {
            setActiveLocalForm('tech_info')
        }

        if (local_form_id === 'initiate_archive') {
            openCarArchived()
        }

        if (
            carsData[`${carid}`] &&
            carsData[`${carid}`]?.last_insurance_entry === undefined
        ) {
            dispatch(carActions.getLastCarInsuranceEntryRequest(`${carid}`))
        }

        if (
            carsData[`${carid}`] &&
            carsData[`${carid}`].has_limited_garage_info === false
        ) {
            if (
                checkIfCarHasMissingJourneyInfo(
                    carsData[`${carid}`],
                    highlightedFactsData
                )
            ) {
                displayJourneyCard()
            }
        }
    }, [userLoggedIn, carid, carsData, location.search])

    useEffect(() => {
        if (carid && carsData && carsData[`${carid}`]) {
            if (
                carsData[carid]?.last_insurance_entry === undefined &&
                carsData[carid]?.archived_on === undefined
            ) {
                dispatch(carActions.getLastCarInsuranceEntryRequest(`${carid}`))
            }
        }

        if (isCarArchiveOpen === true && carError) {
            setIsCarArchiveOpen(false)
        }

        if (
            carid &&
            carsData &&
            carsData[carid] &&
            carsData[carid].has_limited_garage_info
        ) {
            if (
                carsData[carid] &&
                carsData[carid].has_limited_garage_info !== true
            ) {
                if (
                    checkIfCarHasMissingJourneyInfo(
                        carsData[carid],
                        highlightedFactsData
                    )
                ) {
                    displayJourneyCard()
                }
            }
        }
    }, [carsData[`${carid}`], carError, isCarArchiveOpen])

    const current = carsData && carid ? carsData[carid] : null

    const params = new URLSearchParams(location.search)
    const onboardingDataParsed = qs.parse(params.get('onboarding') || '')

    const default_fuel_unit_user =
        userLoggedIn?.preferences?.data?.units?.data?.fuel_unit
            ?.user_choice_id || null

    const default_fuel_unit_user_text = default_fuel_unit_user
        ? userLoggedIn?.preferences?.data?.units?.data?.fuel_unit?.data?.[
              default_fuel_unit_user
          ]?.short
        : undefined

    const all_entries =
        current && carsTimelineData && current.id
            ? carsTimelineData[current.id]?.entries
            : []

    const isShortDescriptionLong = current
        ? !current.short_description ||
          current.short_description === '' ||
          (current.short_description && current.short_description.length > 180)
        : false

    const hasGalleryContent =
        current?.gallery?.images && current.gallery.images.length > 0

    const hasKMContentCheck = (obj: IHighlightedFact) =>
        obj.text && obj.text.length > 0

    const hasKMContent = current?.highlighted_facts?.filter(
        (kmid: string) =>
            highlightedFactsData[kmid] &&
            hasKMContentCheck(highlightedFactsData[kmid])
    )

    const hasNoDescription =
        !current ||
        (current.description && current.description.length < 1) ||
        current.description === '<p><br></p>'

    const hasShowroomDraft = current?.showroom_entry?.state === 'DRAFT'
    const hasShowroomPublished = current?.showroom_entry?.state === 'PUBLISHED'
    const hasSoldShowroomEntry = current?.showroom_entry?.state === 'SOLD'

    const openShowroomflow = () => {
        if (
            (hasSoldShowroomEntry ||
                hasShowroomDraft ||
                hasShowroomPublished) &&
            current?.showroom_entry
        ) {
            navigate(`/showroom/${current.showroom_entry.uid}/edit`)
        } else {
            setIsCarMenuOpened(false)
            setIsShowroomPublishOpen(true)
            if (window.innerWidth < 881) {
                navigate(`/car/${carid}/showroom/create`)
            }
        }
    }

    const currentCarHighlightedFactsIds = current?.highlighted_facts
    const currentCarHighlightedFacts = currentCarHighlightedFactsIds?.filter(
        (id: string) => highlightedFactsData[id]?.text !== ''
    )

    const numberOfKeyMomAndFacts = currentCarHighlightedFacts?.length || 0

    const generateAddOptions = (): CarOptionMenuItem[] => {
        let options: CarOptionMenuItem[] = []

        if (
            (current && !current.short_description) ||
            (current &&
                current.short_description &&
                current.short_description.length < 3)
        ) {
            options = [
                ...options,
                {
                    id: 'vehicle_bio',
                    name: 'Car Title & Bio',
                    icon: 'vehicle_bio',
                    action: (id: string) => {
                        dispatch(editFormsActions.toggleCarOverviewEditForm())
                    },
                },
            ]
        }

        if (current && !current.banner_url) {
            options = [
                ...options,
                {
                    id: 'banner_image',
                    name: 'Banner Image',
                    icon: 'image',
                    action: (id: string) => {
                        dispatch(editFormsActions.toggleCarBannerImageForm())
                    },
                },
            ]
        }

        options = [
            ...options,
            {
                id: 'photos',
                name: 'Photos',
                icon: 'image',
                action: (id: string) => {
                    dispatch(editFormsActions.toggleCarGalleryEditForm())
                },
            },
            {
                id: 'history_files',
                name: 'History Files',
                icon: 'history_file_menu',
                action: (id: string) => {
                    navigate(`/car/${carid}/history-file/create`)
                    dispatch(
                        editFormsActions.manageTimelineCreationFormState({
                            isOpen: true,
                            current_step_index: 0,
                            active_creation:
                                TimelineCreationTypeIdsEnum.add_new,
                        })
                    )
                },
            },
            {
                id: 'task',
                name: 'Task',
                icon: 'task',
                action: (id: string) => {
                    navigate(`/car/${carid}/tasks`)
                },
            },
        ]

        if (numberOfKeyMomAndFacts < 6) {
            options = [
                ...options,
                {
                    id: 'key_moments_and_facts',
                    name: 'Key Moments and Facts',
                    icon: 'key_moments_and_facts',
                    action: (id: string) => {
                        dispatch(editFormsActions.toggleCarHighlightsEditForm())
                    },
                },
            ]
        }

        if (hasNoDescription) {
            options = [
                ...options,
                {
                    id: 'description',
                    name: 'Description',
                    icon: 'description',
                    action: (id: string) => {
                        dispatch(
                            editFormsActions.toggleCarDescriptionEditForm()
                        )
                    },
                },
            ]
        }

        return options
    }

    let { theme } = useThemes()

    const generateEditOptions = (): CarOptionMenuItem[] => {
        let options: CarOptionMenuItem[] = []

        if (
            current &&
            current.short_description &&
            current.short_description.length > 3
        ) {
            options = [
                ...options,
                {
                    id: 'vehicle_bio',
                    name: 'Car Title & Bio',
                    icon: 'vehicle_bio',
                    action: (id: string) => {
                        dispatch(editFormsActions.toggleCarOverviewEditForm())
                    },
                },
            ]
        }

        if (current && current.banner_url) {
            options = [
                ...options,
                {
                    id: 'banner_image',
                    name: 'Banner Image',
                    icon: 'image',
                    action: (id: string) => {
                        dispatch(editFormsActions.toggleCarBannerImageForm())
                    },
                },
            ]
        }

        if (numberOfKeyMomAndFacts > 0) {
            options = [
                ...options,
                {
                    id: 'key_moments_and_facts',
                    name: 'Key Moments and Facts',
                    icon: 'key_moments_and_facts',
                    action: (id: string) => {
                        dispatch(editFormsActions.toggleCarHighlightsEditForm())
                    },
                },
            ]
        }

        if (!hasNoDescription) {
            options = [
                ...options,
                {
                    id: 'description',
                    name: 'Description',
                    icon: 'description',
                    action: (id: string) => {
                        dispatch(
                            editFormsActions.toggleCarDescriptionEditForm()
                        )
                    },
                },
            ]
        }

        return options
    }

    const car_checks_loading = useAppSelector((state) => {
        return state.entities.carsData.car_checks_loading
    })

    const generateMoreOptions = (theme: ITheme): CarOptionMenuItem[] => {
        let options: CarOptionMenuItem[] = []
        options = [
            {
                id: 'share_link',
                name:
                    current?.public_share_owned &&
                    Object.keys(current?.public_share_owned).length > 0
                        ? 'Manage Share Link'
                        : 'Create Share Link',
                icon: 'menu_share',
                action: (id: string) => {
                    navigate(`/car/${carid}/sharing`)
                },
            },
            {
                id: 'car_handover',
                name: 'Car Handover',
                icon: 'car_handover',
                iconLoading: car_checks_loading,
                action: (id: string) => {
                    if (
                        current &&
                        current.id &&
                        current?.car_actions_checks?.handover?.allowed
                    ) {
                        navigate(`/handover/${current.id}`)
                    }
                },
                customColor: current?.car_actions_checks?.handover?.allowed
                    ? undefined
                    : colours[theme].text_muted,
                isDisabled: current?.car_actions_checks?.handover?.allowed
                    ? false
                    : true,
                tooltip:
                    current?.car_actions_checks?.handover?.allowed === false
                        ? current?.car_actions_checks?.handover?.message ??
                          'Handover is not available.'
                        : undefined,
            },
        ]

        if (userLoggedIn?.role === 'Admin' || userLoggedIn?.role === 'Dealer') {
            options = [
                ...options,
                {
                    id: 'showroom',
                    name: hasSoldShowroomEntry
                        ? 'Edit Showroom Entry'
                        : hasShowroomDraft
                        ? 'Edit Showroom Draft'
                        : hasShowroomPublished
                        ? 'Edit Showroom Entry'
                        : 'Publish to Showroom',
                    icon: 'showroom',
                    action: (id: string) => {
                        openShowroomflow()
                    },
                },
            ]
        }

        options = [
            ...options,
            {
                id: 'archive',
                name: 'Archive',
                icon: 'archive',
                iconLoading: car_checks_loading,
                action: (id: string) => {
                    if (current?.car_actions_checks?.archive?.allowed) {
                        if (window.innerWidth < 881) {
                            navigate(`/car/${carid}/archive`)
                        } else openCarArchived()
                    }
                },
                customColor: current?.car_actions_checks?.archive?.allowed
                    ? colours[theme].error
                    : colours[theme].text_muted,

                isDisabled: current?.car_actions_checks?.archive?.allowed
                    ? false
                    : true,
                tooltip:
                    current?.car_actions_checks?.archive?.allowed === false
                        ? current?.car_actions_checks?.archive?.message ??
                          'Car archiving is not available.'
                        : undefined,
            },
        ]
        return options
    }

    const carAtionMenuItemsData = (theme: ITheme): ICarActionMenuItemsData => {
        return {
            add: {
                id: 'add',
                name: 'Add New',
                onClick: () => {
                    setActiveCarActionMenuItemID(
                        activeCarActionMenuItemID === 'add' ? undefined : 'add'
                    )
                },
                options: generateAddOptions(),
            },
            edit: {
                id: 'edit',
                name: 'Edit',
                onClick: () => {
                    setActiveCarActionMenuItemID(
                        activeCarActionMenuItemID === 'edit'
                            ? undefined
                            : 'edit'
                    )
                },
                options: generateEditOptions(),
            },
            more: {
                id: 'more',
                name: 'More Options',
                onClick: () => {
                    setActiveCarActionMenuItemID(
                        activeCarActionMenuItemID === 'more'
                            ? undefined
                            : 'more'
                    )
                },
                options: generateMoreOptions(theme),
            },
        }
    }

    const insurance_timeline_item: ITimelineItem | undefined | null =
        carsData[`${carid}`]?.last_insurance_entry

    const isInsuranceWithin30Days: boolean =
        insurance_timeline_item &&
        insurance_timeline_item.insurance_entry_policy_end_date
            ? isDateWithinAMonth(
                  insurance_timeline_item.insurance_entry_policy_end_date
              )
            : false

    const hasInsuranceExpired: boolean =
        insurance_timeline_item &&
        insurance_timeline_item.insurance_entry_policy_end_date
            ? hasDateExpired(
                  insurance_timeline_item.insurance_entry_policy_end_date
              )
            : false

    useEffect(() => {
        if (current?.archived_on && !isCarArchiveOpen) {
            navigate(`/archives/car/${current.id}/overview`, { replace: true })
        }
    }, [current, isCarArchiveOpen, navigate])

    const openCarActionsMenu = (v: boolean) => {
        if (
            v === true &&
            carActionsChecked === false &&
            carsData &&
            carid &&
            carsData[carid]
        ) {
            dispatch(
                carActions.getCarActionCheckRequest({
                    carid: carid,
                })
            )
            setCarActionsChecked(true)
        }

        if (v === false) {
            setActiveCarActionMenuItemID(undefined)
        }

        setIsCarMenuOpened(v)
    }

    return (
        <React.Fragment>
            <FabsMenusMobile hasAdd={true} hasSubNav={true} />
            <Loader
                isLoading={
                    isCarLoading ||
                    isUserLoading ||
                    isNecessaryGalleryDataLoading
                }
            />

            {params.get('tutorial') !== 'true' &&
                !params.get('id') &&
                getCookie('android_install_prompt') !== 'seen' && (
                    <AndroidInstallPwaInstructionsPropmt />
                )}
            {params.get('tutorial') === 'true' && (
                <TutorialConfigsCarMobile
                    registerCarTutorial={() =>
                        tutorialsActions.manageUserTutorialByIdCheck('car')
                    }
                />
            )}
            {params.get('tutorial') === 'true' && (
                <TutorialConfigsCarDesktop
                    registerCarTutorial={() =>
                        tutorialsActions.manageUserTutorialByIdCheck('car')
                    }
                />
            )}

            {isCarArchiveOpen ? (
                <ArchiveCarDesktopFormManager
                    carid={`${carid}`}
                    isOpen={isCarArchiveOpen}
                    toggle={(p?: boolean) => {
                        setIsCarMenuOpened(false)
                        setIsCarArchiveOpen(!isCarArchiveOpen)
                    }}
                />
            ) : null}

            {carShowroomEntryWasDeleted && (
                <ModalDisplayAbsolute isOpen toggle={() => {}}>
                    <ShowroomModalInnerContainer
                        style={{ height: '386px', padding: 50 }}
                    >
                        <CarRemovedConfirmationModalDesktop
                            continue={() => {
                                dispatch(
                                    showroomActions.showroomEntryDeletionConfirmed(
                                        undefined
                                    )
                                )
                            }}
                        />
                    </ShowroomModalInnerContainer>
                </ModalDisplayAbsolute>
            )}

            {params.get('native_app_prompt') === 'true' && (
                <>
                    <NativeAppPromptDesktop
                        onClose={() => navigate(`/car/${carid}?tutorial=true`)}
                    />

                    <NativeAppPromptMobile
                        onClose={() => navigate(`/car/${carid}?tutorial=true`)}
                        isOpen={params.get('native_app_prompt') === 'true'}
                    />
                </>
            )}

            {isShowroomPublishOpen ? (
                <>
                    <CreateShowroomEntryFormDesktop
                        carid={`${carid}`}
                        isOpen={isShowroomPublishOpen}
                        toggle={(p: boolean) => {
                            setIsCarMenuOpened(false)
                            setIsShowroomPublishOpen(p)
                        }}
                    />
                </>
            ) : null}

            {current !== null &&
                current !== undefined &&
                current &&
                userLoggedIn &&
                default_fuel_unit_user_text && (
                    <>
                        <DesktopDisplayOnly>
                            {/* DESKTOP BANNER SECTION */}
                            <CenteredPageWrapper removePaddings={true}>
                                <SectionsWrap>
                                    <div
                                        style={{
                                            paddingTop: '90px',
                                        }}
                                    />
                                    {showJourneyCard ? (
                                        <div
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                paddingBottom: 32,
                                            }}
                                        >
                                            <FadedSlower>
                                                <JourneyRedirectCardDesktop
                                                    sectionID="car_profile"
                                                    dataPH={
                                                        'start_car_profile_journey'
                                                    }
                                                    copyData={
                                                        journeyRedirectCardsCopy
                                                    }
                                                    onStartNow={() => {
                                                        navigate(
                                                            `/journey/car-profile?step=welcome&carid=${carid}`,
                                                            {
                                                                state: {
                                                                    prevPath: `${
                                                                        location.pathname
                                                                    }${
                                                                        location.search ??
                                                                        ''
                                                                    }`,
                                                                },
                                                            }
                                                        )
                                                    }}
                                                />
                                            </FadedSlower>
                                        </div>
                                    ) : null}
                                    <div
                                        data-tut="second-step-car-desktop"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            position: 'relative',
                                        }}
                                    >
                                        <CarOverviewBanner
                                            toggleCarBannerImageForm={() => {
                                                dispatch(
                                                    editFormsActions.toggleCarBannerImageForm()
                                                )
                                            }}
                                            img={current.banner_url}
                                            hasSvgBackgroundImage
                                        />

                                        <div
                                            data-tut="car-actions-tuto-desktop"
                                            style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                right: '16px',
                                                zIndex: 2,
                                            }}
                                        >
                                            <CarActionMenu
                                                isCarMenuOpened={
                                                    isCarMenuOpened
                                                }
                                                activeID={
                                                    activeCarActionMenuItemID
                                                }
                                                carAtionMenuItemsData={
                                                    carAtionMenuItemsData
                                                }
                                                setIsCarMenuOpened={
                                                    openCarActionsMenu
                                                }
                                            />
                                        </div>
                                    </div>

                                    {/* DESKTOP INTRO SECTION */}
                                    <FirstSectionWrap>
                                        <FirstSectionCol1>
                                            <CarUserAndTitleBox
                                                url={
                                                    userLoggedIn.profile_image_url
                                                }
                                                name={userLoggedIn.display_name}
                                                car_title={current.title}
                                                openForm={() => {
                                                    dispatch(
                                                        editFormsActions.toggleCarOverviewEditForm()
                                                    )
                                                }}
                                            />
                                            <OneLiner italic={false}>
                                                {current.short_description &&
                                                current.short_description
                                                    .length > 1 ? (
                                                    current.short_description
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            boxSizing:
                                                                'border-box',
                                                            height:
                                                                theme ===
                                                                'light'
                                                                    ? '100%'
                                                                    : 152,
                                                            position:
                                                                'relative',
                                                            backgroundColor:
                                                                theme === 'dark'
                                                                    ? colours[
                                                                          theme
                                                                      ]
                                                                          .background_muted
                                                                    : '',
                                                        }}
                                                        onClick={(e: any) => {
                                                            e.preventDefault()
                                                            dispatch(
                                                                editFormsActions.toggleCarOverviewEditForm()
                                                            )
                                                        }}
                                                        role="button"
                                                    >
                                                        {theme === 'light' ? (
                                                            <img
                                                                src={
                                                                    short_description_skeleton
                                                                }
                                                                alt="car short description placeholder"
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing:
                                                                        'border-box',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        ) : null}
                                                        <div
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                left: '50%',
                                                                top: '50%',
                                                                transform:
                                                                    'translate(-50%, -50%)',
                                                            }}
                                                        >
                                                            <ButtonAtom
                                                                theme="lowercase-blue-background"
                                                                fontSize={14}
                                                            >
                                                                <div
                                                                    style={{
                                                                        textTransform:
                                                                            'none',
                                                                    }}
                                                                >
                                                                    Add short
                                                                    description
                                                                </div>
                                                            </ButtonAtom>
                                                        </div>
                                                    </div>
                                                )}
                                            </OneLiner>

                                            <CarOverviewInfoRow
                                                outstanding_tasks_number={
                                                    current.tasks_count
                                                }
                                                car_entries_count={
                                                    current.car_entries_count ??
                                                    0
                                                }
                                            />
                                        </FirstSectionCol1>
                                        <GridSeparation>
                                            <OverviewGridManagerDesktop
                                                ids={
                                                    current.overview &&
                                                    current.overview.list
                                                        ? current.overview.list
                                                        : [
                                                              'make',
                                                              'model',
                                                              'year',
                                                              'engine_capacity',
                                                              'transmission',
                                                              'drive_side',
                                                          ]
                                                }
                                                data={
                                                    current &&
                                                    current.overview &&
                                                    current.overview.data
                                                }
                                                carid={`${carid}`}
                                                fuel_unit_text={
                                                    default_fuel_unit_user_text
                                                        ? default_fuel_unit_user_text
                                                        : '-'
                                                }
                                                fuel_unit={
                                                    default_fuel_unit_user
                                                        ? default_fuel_unit_user
                                                        : '-'
                                                }
                                                isShortDescriptionLong={
                                                    isShortDescriptionLong ===
                                                    true
                                                        ? true
                                                        : false
                                                }
                                                addKeyTechInfo={() =>
                                                    setActiveLocalForm(
                                                        'tech_info'
                                                    )
                                                }
                                            />
                                        </GridSeparation>
                                    </FirstSectionWrap>
                                    {/* DESKTOP GALLERY SECTION */}
                                </SectionsWrap>
                            </CenteredPageWrapper>

                            <div
                                style={{
                                    backgroundColor:
                                        colours[theme]
                                            .background_neutral_subtle,
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    height: '100%',
                                }}
                            >
                                <CenteredPageWrapper removePaddings={true}>
                                    <div
                                        data-tut="gallery-overview-car-tuto-desktop"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <SectionsWrap>
                                            <div
                                                style={{
                                                    paddingTop: '22px',
                                                }}
                                            />
                                            <SectionHeader
                                                openForm={() => {
                                                    dispatch(
                                                        editFormsActions.toggleCarGalleryEditForm()
                                                    )
                                                }}
                                                title=""
                                                removeEdit
                                                customPadding="0px"
                                                rowChildren={() => (
                                                    <Row>
                                                        <ButtonAtom
                                                            theme={
                                                                !hasGalleryContent
                                                                    ? 'lowercase-blue-background'
                                                                    : 'naked-add-text'
                                                            }
                                                            onClick={() => {
                                                                dispatch(
                                                                    editFormsActions.toggleCarGalleryEditForm()
                                                                )
                                                            }}
                                                            fontSize={16}
                                                        >
                                                            <div
                                                                style={{
                                                                    textTransform:
                                                                        'none',
                                                                    cursor: 'pointer',
                                                                    minWidth: 110,
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                {!hasGalleryContent
                                                                    ? 'Start adding photos of your car'
                                                                    : 'Add photos'}
                                                                <span
                                                                    style={{
                                                                        paddingLeft: 8,
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    +
                                                                </span>
                                                            </div>
                                                        </ButtonAtom>
                                                    </Row>
                                                )}
                                            />

                                            <div
                                                style={{
                                                    paddingTop: '22px',
                                                }}
                                            />

                                            <GalleryOverviewDataManagerDesktop
                                                overview_images_ids={
                                                    current?.gallery?.overview
                                                }
                                                images_data={images_data}
                                                isNecessaryGalleryDataLoading={
                                                    isNecessaryGalleryDataLoading
                                                }
                                                cover_id={
                                                    current?.gallery?.cover
                                                }
                                                onClick={() => {
                                                    dispatch(
                                                        editFormsActions.toggleCarGalleryEditForm()
                                                    )
                                                }}
                                                images_ids={
                                                    current?.gallery?.images
                                                }
                                                carid={`${carid}`}
                                                openImgForm={() => {
                                                    dispatch(
                                                        editFormsActions.toggleCarGalleryEditForm()
                                                    )
                                                }}
                                            />

                                            <div
                                                style={{
                                                    paddingTop: '90px',
                                                }}
                                            />
                                        </SectionsWrap>
                                    </div>
                                </CenteredPageWrapper>
                            </div>

                            {/* DESKTOP HIGHLIGHTS SECTION */}
                            <CenteredPageWrapper
                                removePaddings={true}
                                removeHorizPaddings
                            >
                                <SectionsWrap>
                                    <div
                                        data-tut="key-moments-car-tuto-desktop"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <KeyMomNFactsHeaderWrapper>
                                            <SectionHeader
                                                customPadding={'48px'}
                                                openForm={() => {
                                                    dispatch(
                                                        editFormsActions.toggleCarHighlightsEditForm()
                                                    )
                                                }}
                                                sectionPadding={false}
                                                title="Key Moments & Facts"
                                                removeEdit={
                                                    hasKMContent &&
                                                    hasKMContent.length === 0
                                                        ? true
                                                        : false
                                                }
                                                rowChildren={() => {
                                                    if (
                                                        hasKMContent &&
                                                        hasKMContent.length ===
                                                            0
                                                    ) {
                                                        return (
                                                            <Row>
                                                                <ButtonAtom
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    theme="lowercase-blue-background"
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            editFormsActions.toggleCarHighlightsEditForm()
                                                                        )
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            textTransform:
                                                                                'none',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        Write up
                                                                        to 5 key
                                                                        facts
                                                                        about
                                                                        your car
                                                                    </div>
                                                                </ButtonAtom>
                                                            </Row>
                                                        )
                                                    } else return null
                                                }}
                                            />
                                        </KeyMomNFactsHeaderWrapper>
                                        <CarKeyMomentsfactsDesktopV2
                                            highlighted_facts_ids={
                                                current.highlighted_facts
                                            }
                                            onClick={() =>
                                                dispatch(
                                                    editFormsActions.toggleCarHighlightsEditForm()
                                                )
                                            }
                                        />
                                    </div>
                                </SectionsWrap>
                            </CenteredPageWrapper>

                            <div style={{ paddingTop: '20px' }} />

                            <div
                                style={{
                                    backgroundColor:
                                        colours[theme]
                                            .background_neutral_subtle,
                                    width: '100%',
                                }}
                            >
                                <CenteredPageWrapper removePaddings={true}>
                                    <SectionsWrap>
                                        <div
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                height: '100%',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    paddingTop: '48px',
                                                }}
                                            />

                                            <SectionIconRowDescription>
                                                <div>
                                                    <img
                                                        style={{
                                                            width: '128px',
                                                        }}
                                                        src={
                                                            theme === 'light'
                                                                ? description_section_icon_desktop
                                                                : description_section_icon_desktop_dark
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <SectionHeader
                                                        openForm={() => {
                                                            dispatch(
                                                                editFormsActions.toggleCarDescriptionEditForm()
                                                            )
                                                        }}
                                                        rowChildren={() => {
                                                            if (
                                                                hasNoDescription
                                                            ) {
                                                                return (
                                                                    <Row>
                                                                        {
                                                                            <ButtonAtom
                                                                                fontSize={
                                                                                    14
                                                                                }
                                                                                theme="lowercase-blue-background"
                                                                                onClick={() => {
                                                                                    dispatch(
                                                                                        editFormsActions.toggleCarDescriptionEditForm()
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        textTransform:
                                                                                            'none',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    Add
                                                                                    car's
                                                                                    description
                                                                                </div>
                                                                            </ButtonAtom>
                                                                        }
                                                                    </Row>
                                                                )
                                                            } else
                                                                return (
                                                                    <ButtonAtom
                                                                        theme="naked"
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                editFormsActions.toggleCarDescriptionEditForm()
                                                                            )
                                                                        }}
                                                                    >
                                                                        <Icon icon="edit_pen" />
                                                                    </ButtonAtom>
                                                                )
                                                        }}
                                                        customPadding={'0px'}
                                                        title="description"
                                                        removeEdit
                                                    />

                                                    <SimpleDescriptionParagraph
                                                        description={
                                                            current.description
                                                        }
                                                        onClick={() => {
                                                            dispatch(
                                                                editFormsActions.toggleCarDescriptionEditForm()
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </SectionIconRowDescription>

                                            <div
                                                style={{
                                                    paddingTop: '48px',
                                                }}
                                            />
                                        </div>
                                    </SectionsWrap>
                                </CenteredPageWrapper>
                            </div>

                            <CenteredPageWrapper removePaddings={true}>
                                <SectionsWrap>
                                    <div
                                        style={{
                                            // maxWidth: !hasNoDescription
                                            //     ? '900px'
                                            //     : '100%',
                                            // paddingRight: '100px',
                                            // paddingLeft: '100px',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            height: '100%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingTop: '48px',
                                            }}
                                        />

                                        <SectionIconRowInsurance>
                                            <div>
                                                <img
                                                    style={{
                                                        width: '128px',
                                                    }}
                                                    src={
                                                        theme === 'light'
                                                            ? insurance_section_icon_desktop
                                                            : insurance_section_icon_desktop_dark
                                                    }
                                                />
                                            </div>

                                            <SectionHeader
                                                openForm={() => {
                                                    dispatch(
                                                        editFormsActions.toggleCarDescriptionEditForm()
                                                    )
                                                }}
                                                is_warning={
                                                    hasInsuranceExpired ||
                                                    isInsuranceWithin30Days
                                                        ? true
                                                        : false
                                                }
                                                ChildText={() => {
                                                    if (hasInsuranceExpired) {
                                                        return (
                                                            <>
                                                                Your insurance
                                                                has expired.{' '}
                                                                <br />
                                                                Don't leave your
                                                                vehicle
                                                                unprotected. Get
                                                                a personalized
                                                                quote tailored
                                                                to your needs
                                                                and reinstate
                                                                coverage today.
                                                            </>
                                                        )
                                                    } else if (
                                                        isInsuranceWithin30Days
                                                    ) {
                                                        return (
                                                            <>
                                                                Your insurance
                                                                is due for
                                                                renewal soon.
                                                                <br />
                                                                To ensure you
                                                                continue
                                                                enjoying
                                                                comprehensive
                                                                coverage, get a
                                                                personalized
                                                                quote tailored
                                                                to your needs.
                                                            </>
                                                        )
                                                    } else
                                                        return (
                                                            <>
                                                                Insurance plays
                                                                a critical role
                                                                in safeguarding
                                                                your vehicle. If
                                                                you haven't
                                                                added your
                                                                current
                                                                insurance to
                                                                your History
                                                                Files or
                                                                initiated a
                                                                policy with
                                                                Custodian yet,
                                                                it's time to
                                                                act!
                                                            </>
                                                        )
                                                }}
                                                rowChildren={() => {
                                                    return (
                                                        <ChevronWrapper
                                                            $theme={theme}
                                                            href={
                                                                'https://www.custodian.club/features/insurance'
                                                            }
                                                            onClick={() => {
                                                                posthog.capture(
                                                                    'learn_more insurance clicked'
                                                                )
                                                            }}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <ChevronDown
                                                                color={
                                                                    colours[
                                                                        theme
                                                                    ].primary
                                                                }
                                                            />
                                                        </ChevronWrapper>
                                                    )
                                                }}
                                                customPadding={'0px'}
                                                title="insurance"
                                                removeEdit
                                            />
                                        </SectionIconRowInsurance>
                                    </div>

                                    <SectionRowPadding
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            paddingTop: '32px',
                                        }}
                                    >
                                        <CarInsuranceSectionBodyDesktop
                                            carid={`${carid}`}
                                            insurance_timeline_item={
                                                insurance_timeline_item
                                            }
                                            hasExpired={hasInsuranceExpired}
                                            isWithin30Days={
                                                isInsuranceWithin30Days
                                            }
                                        />
                                    </SectionRowPadding>
                                </SectionsWrap>

                                <div style={{ paddingTop: '16px' }} />
                            </CenteredPageWrapper>

                            <CenteredPageWrapper removePaddings={true}>
                                <SectionsWrap>
                                    <div
                                        data-tut="timeline-overview-car-tuto-desktop"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <SectionHeader
                                            openForm={() => {
                                                current &&
                                                current.car_entries_count
                                                    ? navigate(
                                                          `/car/${carid}/history-file/create`
                                                      )
                                                    : navigate(
                                                          `/car/${carid}/history-file?section_id=create`
                                                      )
                                            }}
                                            title="History File"
                                            removeEdit
                                            addButton={true}
                                            rowChildren={() => {
                                                return <CarInsightsBtn />
                                            }}
                                        />

                                        <TimelineOverviewManagerDesktop
                                            entries_array={all_entries}
                                            car_entries_count={
                                                current.car_entries_count
                                                    ? current.car_entries_count
                                                    : 0
                                            }
                                            attachmentsObj={attachmentsObj}
                                            // costsObj={costsObj}
                                            carid={`${carid}`}
                                            onPlaceholderClick={() => {
                                                current &&
                                                current.car_entries_count
                                                    ? navigate(
                                                          `/car/${carid}/history-file/create`
                                                      )
                                                    : navigate(
                                                          `/car/${carid}/history-file?section_id=create`
                                                      )
                                            }}
                                            userCurrency={userCurrency}
                                        />
                                    </div>
                                </SectionsWrap>
                            </CenteredPageWrapper>

                            <div style={{ paddingTop: '300px' }} />
                        </DesktopDisplayOnly>

                        {onboardingDataParsed.id !== '5' &&
                            params.get('id') !== '5' &&
                            onboardingDataParsed.id !== '4' &&
                            params.get('id') !== '4' &&
                            onboardingDataParsed.id !== '6' &&
                            params.get('id') !== '6' &&
                            onboardingDataParsed.id !== '7' &&
                            params.get('id') !== '7' && (
                                <IpadAndMobileDisplay>
                                    <CenteredPageWrapper hasMobileSubNav>
                                        <Faded>
                                            {current.banner_url && (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        boxSizing: 'border-box',
                                                        height: '100%',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <FullWidthMobile
                                                        img={current.banner_url}
                                                        threeDotsMenu={false}
                                                        height="160px"
                                                        onClick={() => {
                                                            dispatch(
                                                                editFormsActions.toggleCarBannerImageForm()
                                                            )

                                                            if (
                                                                isCarMenuOpened
                                                            ) {
                                                                setIsCarMenuOpened(
                                                                    false
                                                                )
                                                            }
                                                        }}
                                                    />

                                                    <div
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            bottom: '-22px',
                                                            right: '16px',
                                                            zIndex: 2,
                                                        }}
                                                    >
                                                        <CarActionMenuMobile
                                                            isCarMenuOpened={
                                                                isCarMenuOpened
                                                            }
                                                            activeID={
                                                                activeCarActionMenuItemID
                                                            }
                                                            carAtionMenuItemsData={
                                                                carAtionMenuItemsData
                                                            }
                                                            setIsCarMenuOpened={
                                                                openCarActionsMenu
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {!current.banner_url && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        width: '100%',
                                                        boxSizing: 'border-box',
                                                        position: 'relative',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <FullWidthMobile
                                                        img={{
                                                            id: 'banner_svg_img',
                                                            url:
                                                                theme ===
                                                                'light'
                                                                    ? banner_placeholder_mobile
                                                                    : banner_placeholder_mobile_dark,
                                                        }}
                                                        threeDotsMenu={false}
                                                        height="80px"
                                                        onClick={() => {
                                                            dispatch(
                                                                editFormsActions.toggleCarBannerImageForm()
                                                            )
                                                            if (
                                                                isCarMenuOpened
                                                            ) {
                                                                setIsCarMenuOpened(
                                                                    false
                                                                )
                                                            }
                                                        }}
                                                    />

                                                    <div
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            bottom: '-20px',
                                                            right: '16px',
                                                            zIndex: 2,
                                                        }}
                                                        data-tut="car-actions-tuto-mobile"
                                                    >
                                                        <CarActionMenuMobile
                                                            isCarMenuOpened={
                                                                isCarMenuOpened
                                                            }
                                                            activeID={
                                                                activeCarActionMenuItemID
                                                            }
                                                            carAtionMenuItemsData={
                                                                carAtionMenuItemsData
                                                            }
                                                            setIsCarMenuOpened={
                                                                openCarActionsMenu
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {current?.title?.length < 1 &&
                                                current?.short_description
                                                    ?.length < 2 && (
                                                    <MobileContentBody>
                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '20px',
                                                                marginBottom:
                                                                    '30px',
                                                                width: '100%',
                                                                boxSizing:
                                                                    'border-box',
                                                                height:
                                                                    theme ===
                                                                    'light'
                                                                        ? 'auto'
                                                                        : 152,
                                                                backgroundColor:
                                                                    theme ===
                                                                    'dark'
                                                                        ? colours[
                                                                              theme
                                                                          ]
                                                                              .background_muted
                                                                        : '',
                                                            }}
                                                        >
                                                            {theme ===
                                                            'light' ? (
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            descSkeleton
                                                                        }
                                                                        alt="description skeleton"
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </MobileContentBody>
                                                )}

                                            {/* // IF TITLE AND NO DESC */}
                                            {current?.short_description
                                                ?.length < 2 &&
                                                current?.title?.length > 1 && (
                                                    <MobileContentBody>
                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '24px',
                                                            }}
                                                        />
                                                        <SectionHeader
                                                            customFontSize={
                                                                '22px'
                                                            }
                                                            customPadding="0px"
                                                            customFontWeight={
                                                                500
                                                            }
                                                            // openForm={() => {
                                                            //     toggleCarOverviewEditForm()
                                                            // }}
                                                            removeEdit
                                                            title={
                                                                current.title
                                                            }
                                                        />

                                                        <CarUserAndTitleBox
                                                            url={
                                                                userLoggedIn.profile_image_url
                                                            }
                                                            name={
                                                                userLoggedIn.display_name
                                                            }
                                                            car_title={
                                                                current.title
                                                            }
                                                            isMobile={true}
                                                        />

                                                        <Expander
                                                            height={
                                                                showJourneyCard
                                                                    ? 'auto'
                                                                    : 0
                                                            }
                                                            width="100%"
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing:
                                                                        'border-box',
                                                                    paddingTop: 24,
                                                                }}
                                                            >
                                                                <FadedSlower>
                                                                    <JourneyRedirectCardMobile
                                                                        dataPH="start_car_profile_journey"
                                                                        sectionID="car_profile"
                                                                        copyData={
                                                                            journeyRedirectCardsCopy
                                                                        }
                                                                        onStartNow={() => {
                                                                            navigate(
                                                                                `/journey/car-profile?step=welcome&carid=${carid}`
                                                                            )
                                                                        }}
                                                                        onDismiss={() => {
                                                                            setShowJourneyCard(
                                                                                false
                                                                            )
                                                                        }}
                                                                    />
                                                                </FadedSlower>
                                                            </div>
                                                        </Expander>

                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '24px',
                                                                marginBottom:
                                                                    '10px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    boxSizing:
                                                                        'border-box',
                                                                    position:
                                                                        'relative',
                                                                    height:
                                                                        theme ===
                                                                        'light'
                                                                            ? '100%'
                                                                            : 150,
                                                                    backgroundColor:
                                                                        theme ===
                                                                        'dark'
                                                                            ? colours[
                                                                                  theme
                                                                              ]
                                                                                  .background_neutral_subtle
                                                                            : '',
                                                                }}
                                                            >
                                                                <EmptyBtn>
                                                                    <ButtonAtom
                                                                        theme={
                                                                            'lowercase-blue-background'
                                                                        }
                                                                        fontSize={
                                                                            14
                                                                        }
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                editFormsActions.toggleCarOverviewEditForm()
                                                                            )
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                textTransform:
                                                                                    'none',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        >
                                                                            Add
                                                                            car’s
                                                                            bio
                                                                        </div>
                                                                    </ButtonAtom>
                                                                </EmptyBtn>

                                                                {theme ===
                                                                'light' ? (
                                                                    <img
                                                                        src={
                                                                            descSkeleton
                                                                        }
                                                                        alt="description skeleton"
                                                                        style={{
                                                                            height: '150px',
                                                                            width: '100%',
                                                                            boxSizing:
                                                                                'border-box',
                                                                        }}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </MobileContentBody>
                                                )}
                                            {/* // IF TITLE AND DESC */}
                                            {current?.short_description
                                                ?.length > 2 &&
                                                current?.title?.length > 1 && (
                                                    <MobileContentBody>
                                                        <IpadWrapPadding
                                                            style={{
                                                                width: '100%',
                                                                boxSizing:
                                                                    'border-box',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '24px',
                                                                }}
                                                            />
                                                            <SectionHeader
                                                                customFontSize={
                                                                    '19px'
                                                                }
                                                                customPadding="0px"
                                                                customFontWeight={
                                                                    500
                                                                }
                                                                // openForm={() => {
                                                                //     toggleCarOverviewEditForm()
                                                                // }}
                                                                removeEdit
                                                                title={
                                                                    current.title
                                                                }
                                                            />
                                                            <MobilePadding />
                                                            <CarUserAndTitleBox
                                                                url={
                                                                    userLoggedIn.profile_image_url
                                                                }
                                                                name={
                                                                    userLoggedIn.display_name
                                                                }
                                                                car_title={
                                                                    current.title
                                                                }
                                                                isMobile
                                                            />
                                                            <Expander
                                                                height={
                                                                    showJourneyCard
                                                                        ? 'auto'
                                                                        : 0
                                                                }
                                                                width="100%"
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                        boxSizing:
                                                                            'border-box',
                                                                        paddingTop: 24,
                                                                    }}
                                                                >
                                                                    <FadedSlower>
                                                                        <JourneyRedirectCardMobile
                                                                            dataPH="start_car_profile_journey"
                                                                            sectionID="car_profile"
                                                                            copyData={
                                                                                journeyRedirectCardsCopy
                                                                            }
                                                                            onStartNow={() => {
                                                                                navigate(
                                                                                    `/journey/car-profile?step=welcome&carid=${carid}`
                                                                                )
                                                                            }}
                                                                            onDismiss={() => {
                                                                                setShowJourneyCard(
                                                                                    false
                                                                                )
                                                                            }}
                                                                        />
                                                                    </FadedSlower>
                                                                </div>
                                                            </Expander>

                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '24px',
                                                                }}
                                                                onClick={() => {
                                                                    if (
                                                                        isCarMenuOpened
                                                                    ) {
                                                                        setIsCarMenuOpened(
                                                                            false
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                <Paragraph
                                                                    ipadAlign="left"
                                                                    align="left"
                                                                    removeIpadPadding={
                                                                        true
                                                                    }
                                                                >
                                                                    {
                                                                        current.short_description
                                                                    }
                                                                </Paragraph>
                                                            </div>
                                                        </IpadWrapPadding>
                                                    </MobileContentBody>
                                                )}

                                            <div
                                                style={{
                                                    paddingTop: '16px',
                                                }}
                                            />

                                            <MobileContentBody>
                                                <IpadWrapUserPadding
                                                    onClick={() => {
                                                        if (isCarMenuOpened) {
                                                            setIsCarMenuOpened(
                                                                false
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <CarOverviewInfoRow
                                                        outstanding_tasks_number={
                                                            current.tasks_count
                                                        }
                                                        car_entries_count={
                                                            current.car_entries_count ??
                                                            0
                                                        }
                                                    />
                                                </IpadWrapUserPadding>
                                            </MobileContentBody>

                                            <div
                                                style={{
                                                    paddingTop: '40px',
                                                }}
                                            />

                                            <div
                                                data-tut="gallery-overview-car-tuto-mobile"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            colours[theme]
                                                                .background_default,
                                                        display: 'flex',
                                                        paddingTop: '16px',
                                                        paddingBottom: '50px',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <MobileContentBody>
                                                        <SectionHeader
                                                            titleIcon="gallery_section"
                                                            openForm={() => {
                                                                dispatch(
                                                                    editFormsActions.toggleCarGalleryEditForm()
                                                                )
                                                            }}
                                                            title="Gallery"
                                                            removeEdit={true}
                                                            addButton
                                                            customPadding={
                                                                '0px'
                                                            }
                                                        />
                                                        <div
                                                            style={{
                                                                paddingTop: 16,
                                                            }}
                                                        />
                                                    </MobileContentBody>
                                                    <GalleryOverviewDataManagerMobile
                                                        overview_images_ids={
                                                            current?.gallery
                                                                ?.overview
                                                        }
                                                        images_data={
                                                            images_data
                                                        }
                                                        isNecessaryGalleryDataLoading={
                                                            isNecessaryGalleryDataLoading
                                                        }
                                                        cover_id={
                                                            current?.gallery
                                                                ?.cover
                                                        }
                                                        onClick={() => {
                                                            dispatch(
                                                                editFormsActions.toggleCarGalleryEditForm()
                                                            )
                                                        }}
                                                        images_ids={
                                                            current?.gallery
                                                                ?.images
                                                        }
                                                        carid={`${carid}`}
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    paddingTop: '40px',
                                                }}
                                            />

                                            <MobileContentBody>
                                                <CarOverviewGridManagerMobile
                                                    carid={`${carid}`}
                                                    ids={
                                                        current.overview &&
                                                        current.overview.list
                                                            ? current.overview
                                                                  .list
                                                            : [
                                                                  'year',
                                                                  'make',
                                                                  'model',
                                                                  'engine_capacity',
                                                                  'transmission',
                                                                  'drive_side',
                                                              ]
                                                    }
                                                    data={
                                                        current &&
                                                        current.overview &&
                                                        current.overview.data
                                                    }
                                                    fuel_unit_text={
                                                        default_fuel_unit_user_text
                                                            ? default_fuel_unit_user_text
                                                            : ''
                                                    }
                                                    fuel_unit={
                                                        default_fuel_unit_user
                                                            ? default_fuel_unit_user
                                                            : ''
                                                    }
                                                    addKeyTechInfo={() => {
                                                        setActiveLocalForm(
                                                            'tech_info'
                                                        )
                                                    }}
                                                />
                                            </MobileContentBody>
                                            {activeLocalForm ===
                                                'tech_info' && (
                                                <ModalDisplay
                                                    isOpen
                                                    toggle={() => {}}
                                                >
                                                    <ShowroomTechInfoFormMobile
                                                        close={() => {
                                                            setActiveLocalForm(
                                                                null
                                                            )
                                                        }}
                                                        car={current}
                                                    />
                                                </ModalDisplay>
                                            )}

                                            <div
                                                style={{
                                                    paddingTop: '30px',
                                                }}
                                            />
                                            <MobileContentBody>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        boxSizing: 'border-box',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <SectionHeader
                                                        openForm={() => {
                                                            dispatch(
                                                                editFormsActions.toggleCarHighlightsEditForm()
                                                            )
                                                        }}
                                                        titleIcon="kmf_section"
                                                        title="Key Moments & Facts"
                                                        customPadding="20px"
                                                        removeEdit={
                                                            hasKMContent &&
                                                            hasKMContent.length ===
                                                                0
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        paddingTop: '7px',
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        boxSizing: 'border-box',
                                                        height: '100%',
                                                    }}
                                                    data-tut="key-moments-car-tuto-mobile"
                                                >
                                                    <CarKeyMomentsFactsMobile
                                                        highlighted_facts_ids={
                                                            current.highlighted_facts
                                                        }
                                                        onClick={() => {
                                                            dispatch(
                                                                editFormsActions.toggleCarHighlightsEditForm()
                                                            )
                                                        }}
                                                        isEmpty={
                                                            hasKMContent &&
                                                            hasKMContent.length ===
                                                                0
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        paddingTop: '32px',
                                                    }}
                                                />
                                                <SectionHeader
                                                    title="description"
                                                    titleIcon="description_section"
                                                    customPadding="0px"
                                                    removeEdit={
                                                        hasNoDescription
                                                            ? true
                                                            : false
                                                    }
                                                    openForm={() => {
                                                        dispatch(
                                                            editFormsActions.toggleCarDescriptionEditForm()
                                                        )
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        paddingTop: '20px',
                                                    }}
                                                />
                                                <DescriptionMobile
                                                    description={
                                                        current.description
                                                    }
                                                    onClick={() => {
                                                        dispatch(
                                                            editFormsActions.toggleCarDescriptionEditForm()
                                                        )
                                                    }}
                                                />
                                            </MobileContentBody>

                                            {!hasNoDescription && (
                                                <div
                                                    style={{
                                                        paddingTop: '48px',
                                                    }}
                                                />
                                            )}
                                            <MobileContentBody>
                                                <SectionHeader
                                                    title="insurance"
                                                    titleIcon="insurance_section"
                                                    customPadding="0px"
                                                    removeEdit={true}
                                                    rowChildren={() => {
                                                        return (
                                                            <ChevronWrapper
                                                                $theme={theme}
                                                                href={
                                                                    'https://www.custodian.club/features/insurance'
                                                                }
                                                                onClick={() => {
                                                                    posthog.capture(
                                                                        'learn_more insurance clicked'
                                                                    )
                                                                }}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <ChevronDown
                                                                    color={
                                                                        colours[
                                                                            theme
                                                                        ]
                                                                            .primary
                                                                    }
                                                                />
                                                            </ChevronWrapper>
                                                        )
                                                    }}
                                                />

                                                <div
                                                    style={{
                                                        paddingTop: '12px',
                                                    }}
                                                />

                                                <CarInsuranceSectionBodyMobile
                                                    carid={`${carid}`}
                                                    insurance_timeline_item={
                                                        insurance_timeline_item
                                                    }
                                                    hasExpired={
                                                        hasInsuranceExpired
                                                    }
                                                    isWithin30Days={
                                                        isInsuranceWithin30Days
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        paddingTop: '20px',
                                                    }}
                                                />
                                            </MobileContentBody>

                                            <div
                                                style={{
                                                    paddingTop: '32px',
                                                }}
                                            />

                                            <div
                                                data-tut="timeline-overview-car-tuto-mobile"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            >
                                                <MobileContentBody>
                                                    <SectionHeader
                                                        titleIcon="history_file_section"
                                                        openForm={() => {
                                                            current &&
                                                            current.car_entries_count
                                                                ? navigate(
                                                                      `/car/${carid}/history-file/create`
                                                                  )
                                                                : navigate(
                                                                      `/car/${carid}/history-file?section_id=create`
                                                                  )
                                                        }}
                                                        title="History File"
                                                        removeEdit={true}
                                                        addButton
                                                        customPadding={'0px'}
                                                    />

                                                    <div
                                                        style={{
                                                            paddingTop: '30px',
                                                        }}
                                                    />

                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            boxSizing:
                                                                'border-box',
                                                        }}
                                                    >
                                                        <TimelineOverviewManagerMobile
                                                            entries_array={
                                                                all_entries
                                                            }
                                                            car_entries_count={
                                                                current.car_entries_count
                                                                    ? current.car_entries_count
                                                                    : 0
                                                            }
                                                            carid={`${carid}`}
                                                            onPlaceholderClick={() => {
                                                                current &&
                                                                current.car_entries_count
                                                                    ? navigate(
                                                                          `/car/${carid}/history-file/create`
                                                                      )
                                                                    : navigate(
                                                                          `/car/${carid}/history-file?section_id=create`
                                                                      )
                                                            }}
                                                            userCurrency={
                                                                userCurrency
                                                            }
                                                        />
                                                    </div>

                                                    <div
                                                        style={{
                                                            paddingBottom:
                                                                '250px',
                                                        }}
                                                    />
                                                </MobileContentBody>
                                            </div>
                                        </Faded>
                                    </CenteredPageWrapper>
                                </IpadAndMobileDisplay>
                            )}
                    </>
                )}

            <DesktopDisplayOnly>
                {current && (
                    <CarOverviewFormsBodyDesktop
                        current={current}
                        localFormOpened={activeLocalForm}
                        setActiveLocalForm={() => setActiveLocalForm(null)}
                    />
                )}

                {isCarDescriptionFormEditOpened && (
                    <ModalDisplay
                        toggle={() => {
                            dispatch(
                                editFormsActions.toggleCarDescriptionEditForm()
                            )
                        }}
                        isOpen={true}
                    >
                        <CarDescriptionEditFormDesktop
                            carId={current?.id || ''}
                            writeCarDataGeneralRequest={(
                                p: IUpdateCarGeneralPayloadReq
                            ) => {
                                dispatch(
                                    carActions.writeCarDataGeneralRequest(p)
                                )
                            }}
                            carDescription={current?.description || ''}
                            close={() => {
                                dispatch(
                                    editFormsActions.toggleCarDescriptionEditForm()
                                )
                            }}
                        />
                    </ModalDisplay>
                )}
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                {isCarDescriptionFormEditOpened && (
                    <ModalDisplay
                        toggle={() => {
                            dispatch(
                                editFormsActions.toggleCarDescriptionEditForm()
                            )
                        }}
                        isOpen={true}
                    >
                        <CarDescriptionEditFormMobile
                            carId={current?.id || ''}
                            writeCarDataGeneralRequest={(
                                p: IUpdateCarGeneralPayloadReq
                            ) => {
                                dispatch(
                                    carActions.writeCarDataGeneralRequest(p)
                                )
                            }}
                            carDescription={current?.description || ''}
                            close={() =>
                                dispatch(
                                    editFormsActions.toggleCarDescriptionEditForm()
                                )
                            }
                        />
                    </ModalDisplay>
                )}

                {current && <CarOverviewFormsBodyMobile current={current} />}

                {carShowroomEntryWasDeleted && (
                    <ModalDisplay isOpen toggle={() => {}}>
                        <ShowroomModalContainerMobile>
                            <CarRemovedConfirmationModalDesktop
                                continue={() => {
                                    dispatch(
                                        showroomActions.showroomEntryDeletionConfirmed(
                                            undefined
                                        )
                                    )
                                }}
                            />
                        </ShowroomModalContainerMobile>
                    </ModalDisplay>
                )}
            </IpadAndMobileDisplay>

            <IpadAndMobileDisplay>
                <CarOverviewActionsSheetMobile
                    isOpen={isCarMenuOpened}
                    data={carAtionMenuItemsData}
                    toggle={() => {
                        setIsCarMenuOpened(false)
                    }}
                    setActiveIDToUndefined={() => {
                        setActiveCarActionMenuItemID(undefined)
                    }}
                    activeID={activeCarActionMenuItemID}
                />
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default CarOverview
