import React, { useEffect } from 'react'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import ShareErrorManager from '../../organisms/shareErrorManager/shareErrorManager'

const Wrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    z-index: 10;
    background-color: var(--bg-color, #fff);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
`

interface Props {
    error_is: string | undefined
    entity: 'car' | 'garage'
    onClick: any
}

const SharedWithYouEntityError: React.FC<Props> = ({
    error_is,
    entity,
    onClick,
}) => {
    useEffect(() => {
        disableBodyScroll(document.body)
        return () => {
            enableBodyScroll(document.body)
        }
    }, [])

    return (
        <Wrapper>
            <ShareErrorManager
                error_is={error_is}
                entity={entity}
                onClick={onClick}
            />
        </Wrapper>
    )
}

export default SharedWithYouEntityError
