import { Upload, message } from 'antd'
import styled from 'styled-components'
import { AttachmentTypesEnum } from '../../../../redux/attachments/enums'
import {
    ConverFileTypeToFileSuffixEnum,
    GetFileType,
} from '../../../../redux/conversions/attachments'
import AddIcon from '../../../atoms/icons/components/addIcon'
import AttachmentIcon from '../../../atoms/icons/components/historyFile/attachment'
import { useCallback, useState } from 'react'
import { useAppDispatch } from '../../../../redux/store/hooks'
import { timelineActions } from '../../../../redux/timeline/reducer'

interface Props {
    carid: string
    uploadChildren?: React.ReactNode
    noActionIcon?: boolean
    icon?: string
    onClickOpen?: any
    entityID: string
    isBottomsheet?: boolean
    closeBottomSheet?: () => void
}

const errorFileSizeWarning = (filename: string) => {
    message.error({
        content: `${filename} not supported. The maximum file size allowed per upload is 20MB.`,
        duration: 3,
    })
}

const errorFileTypeWarning = (filename: string) => {
    message.error({
        content: `*.${GetFileType(filename)} files are not supported`,
        duration: 2,
    })
}

const errorWarning = () => {
    message.error({
        content: 'Please upload a maximum of 20 files.',
        duration: 2,
    })
}

const StyledUpload = styled(Upload)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    height: 48px;
    border: none;
    border-radius: 4px;
    transition: all 500ms;
    background-color: var(--primary_08);
`
const StyleMenuItemdUpload = styled(Upload)`
    width: 100%;
    box-sizing: border-box;
    height: auto;
`
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border: none;
    border-radius: 4px;
    gap: 8px;
`

const MenuItemWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px 40px;
    color: var(--text-default, #666666);
    font-family: Lato;
    font-size: 16px;
    text-transform: capitalize;
`
const UploadText = styled.div`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    font-size: 16px;
    text-align: center;
`

const EntryAttachmentsFormWrapperMobile: React.FC<Props> = (props) => {
    // const [filesArray, setFilesArray] = useState<File[] | undefined[]>([
    //     undefined,
    // ])
    const [rejectdedFiles, setRejectedFiles] = useState<any[]>([undefined])
    const [renderNumber, setRenderNumber] = useState(0)
    const [filesnb, setFilesnb] = useState(0)

    const dispatch = useAppDispatch()

    const handleFiles = (info: any) => {
        let newFilesList: File[] = []
        let rejectdedFiles: any[] = []

        if (info && info.fileList) {
            setFilesnb(info.fileList.length - 1)
            info.fileList.forEach((f: any) => {
                let file: File = f.originFileObj
                let fs: AttachmentTypesEnum | undefined =
                    ConverFileTypeToFileSuffixEnum(f.name)

                if (file) {
                    if (file.size > 20000000 || file.size < 1) {
                        rejectdedFiles.push({
                            filename: file.name,
                            code: 'file-size-not-supported',
                        })
                        return
                    } else if (!fs) {
                        rejectdedFiles.push({
                            filename: file.name,
                            code: 'file-type-not-supported',
                        })
                        return
                    } else {
                        newFilesList.push(file)
                    }
                }
            })

            setRejectedFiles(rejectdedFiles)

            if (
                newFilesList.length > 0 &&
                newFilesList.length <= 20 &&
                (!rejectdedFiles || rejectdedFiles.length === 0)
            ) {
                dispatch(
                    timelineActions.manageAddAttachmentsToEntryRequest({
                        files: newFilesList,
                        entryID: props.entityID,
                        carID: props.carid,
                    })
                )
            }
        }
    }

    const dummyRequest = useCallback(({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }, [])

    const checkIfWarnings = useCallback(() => {
        if (rejectdedFiles && renderNumber === filesnb) {
            if (filesnb > 20) {
                errorWarning()
            }

            rejectdedFiles.forEach((item: any) => {
                if (item && item.code === 'file-type-not-supported') {
                    errorFileTypeWarning(item.filename)
                } else if (item && item.code === 'file-size-not-supported') {
                    errorFileSizeWarning(item.filename)
                }
            })

            setRenderNumber(-1)
            setFilesnb(0)
        }
    }, [rejectdedFiles, renderNumber, filesnb])

    return props.isBottomsheet ? (
        <StyleMenuItemdUpload
            onChange={(info: any) => {
                if (info) {
                    handleFiles(info)
                    checkIfWarnings()
                    setRenderNumber(renderNumber + 1)
                    props.closeBottomSheet && props.closeBottomSheet()
                }
            }}
            customRequest={dummyRequest}
            multiple
            fileList={[]}
            accept=".pdf, .jpg, .jpeg, .png, .xlsm, .xlsx, .xls, .doc, .docx, .heif, .heic"
        >
            <MenuItemWrapper>
                <AttachmentIcon />
                <span>Add attachments</span>
            </MenuItemWrapper>
        </StyleMenuItemdUpload>
    ) : (
        <StyledUpload
            onChange={(info: any) => {
                if (info) {
                    handleFiles(info)
                    checkIfWarnings()
                    setRenderNumber(renderNumber + 1)
                }
            }}
            customRequest={dummyRequest}
            multiple
            fileList={[]}
            accept=".pdf, .jpg, .jpeg, .png, .xlsm, .xlsx, .xls, .doc, .docx, .heif, .heic"
        >
            <Wrapper>
                <AddIcon size="16" colour="#5ec3ca" />
                <UploadText>Add Files(s)</UploadText>
            </Wrapper>
        </StyledUpload>
    )
}

export default EntryAttachmentsFormWrapperMobile
