import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { IAPIAccDelItems } from './types'

// call custodian API to delete account

export const deleteAccount = async (): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.current_user_endpoint, requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(response, 'Delete user account error')
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}

export default deleteAccount

// record data in Xata db

export const recordAccountDeletionFeedback = (
    payload: IAPIAccDelItems
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer xau_I0eQy6O3EysffbxiSK6pTKLkiSxv95YO1',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user_email: payload.userEmail,
            form: 'rec_cf2k51gjfcu5aunigs40',
            reason: payload.reason,
            custom_reason_detail:
                payload.custom_reason_detail !== ''
                    ? payload.custom_reason_detail
                    : 'No custom reason',
            rating: payload.rating,
            feedback: payload.feedback ? payload.feedback : 'No feedback',
            further_feedback: payload.further_feedback,
            created_at: payload.created_at,
        }),
    }

    return fetch(
        'https://custodian-700jdq.eu-west-1.xata.sh/db/custodian-forms:main/tables/responses/data?columns=id',
        requestOptions
    )
        .then((response: any) => {
            if (response.ok !== true) {
                return Promise.reject(response)
            }
        })
        .catch((error: any) => {
            return Promise.reject(error)
        })
}
