import React from 'react'
import AddImageGrid from '../../atoms/image/addImageGrid'
import SquareDuoItemMobile from '../../atoms/image/squareDuoItemMobile'
import styled from 'styled-components'
import { IGalleryImagesObject } from '../../../redux/entities/galleries/types'

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

export interface Props {
    images_data_object: IGalleryImagesObject
    ids_list: string[]
    onAddClick?: any
    readOnlyMode?: boolean
}

const SquareDuoMobile: React.FC<Props> = ({
    images_data_object,
    ids_list,
    onAddClick,
    readOnlyMode,
}) => {
    const ids_list_displayed: string[] =
        ids_list && ids_list.length > 0 ? [...ids_list] : []

    return (
        <Wrapper>
            {ids_list_displayed.map((id: string, index: number) => {
                if (images_data_object[id]) {
                    return (
                        <div key={index}>
                            <SquareDuoItemMobile
                                image_obj={images_data_object[id]}
                            />
                        </div>
                    )
                } else {
                    return <div key={index} style={{ display: 'none' }} />
                }
            })}

            {!readOnlyMode && (
                <AddImageGrid
                    onClick={onAddClick && onAddClick}
                    ipadHeight="22vh"
                />
            )}
        </Wrapper>
    )
}

export default SquareDuoMobile
