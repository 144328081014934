import React from 'react'
import { Select, Spin } from 'antd'
import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'
import './antd.css'

const { Option } = Select

const SelectBarContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        border: 1px solid var(--primary, #5ec3ca);
        border-radius: 2px;
        background-color: transparent;
    }

    display: none;
`

export type Props = {
    fetching: any
    value: any
    data: any
    fetchItem: any
    handleChange: any
    optionsList: any
    item?: any
    width?: string
    isDisabled?: boolean
}

const MultipleSelectDesktop: React.FC<Props> = ({
    fetching,
    value,
    data,
    handleChange,
    fetchItem,
    optionsList,
    item,
    width,
    isDisabled,
}) => {
    const mappedData = data
        ? data.map((d: any) => (
              <Option value={d.name} key={d.name} aria-label="UK">
                  <span style={{ marginLeft: '10px' }}>{d.name} </span>
              </Option>
          ))
        : null

    const mappedList = optionsList
        ? optionsList.map((d: any) => (
              <Option value={d.name} key={d.name} aria-label="UK">
                  <span style={{ marginLeft: '10px' }}>{d.name} </span>
              </Option>
          ))
        : null

    return (
        <SelectBarContainer>
            <Select
                mode="multiple"
                showSearch
                value={
                    isDisabled === true ? undefined : value[value.length - 1]
                }
                placeholder={
                    item.answers && !isDisabled
                        ? item.answers
                        : isDisabled
                        ? ''
                        : `Search through ${item && item.text}`
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={fetchItem}
                onChange={handleChange}
                autoClearSearchValue={true}
                style={{
                    minWidth: width ? width : '500px',
                    maxWidth: width ? width : '500px',
                    fontFamily: 'Lato',
                    height: '100% !important',
                }}
                size="large"
                disabled={isDisabled ? isDisabled : false}
            >
                {data.length > 0 ? mappedData : mappedList}
            </Select>
        </SelectBarContainer>
    )
}

export default MultipleSelectDesktop
