import './styles.css'

import * as React from 'react'
export interface GlobalLayoutProps {
    children?: React.ReactNode
}

const MobileDisplay = (props: GlobalLayoutProps) => (
    <div className="mobile-displayed">{props.children}</div>
)

export default MobileDisplay
