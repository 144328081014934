import { ConvertGetUserArchivesAPIReqToURL } from '../../conversions/archive/getArchiveUrl'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { ICarPayload } from '../typedefinitions/apiPayloads'

export type I_getUserArchives_api_req = {
    limit: string
    offset: string
    sorting:
        | 'ARCHIVED_ON_OLDEST'
        | 'ARCHIVED_ON_MOST_RECENT'
        | 'MANUFACTURE_DATE_ASCENDING'
        | 'MANUFACTURE_DATE_DESCENDING'
}

export type I_getUserArchives_api_res = {
    total_count: number
    cars: ICarPayload[]
}

export const getUserArchives = (
    p: I_getUserArchives_api_req
): Promise<I_getUserArchives_api_res> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    let urlBuilt = ConvertGetUserArchivesAPIReqToURL(p)

    return fetch(urls.user_archives_endpoint(urlBuilt), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get user archives error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
