import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ICarouselShowroomItem } from '../../../../redux/services/showroom/getCarousel'
import { device } from '../../../templates/displays/devices'
import './styles.css'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { numberWithCommas } from '../../../../helpers/numbers'
import posthog from 'posthog-js'

type Props = {
    slide: ICarouselShowroomItem
}

const SlideStyle = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        text-align: left;
        position: relative;
    }
`

const AbsoluteTxt = styled.div`
    position: absolute;
    left: 40px;
    bottom: 27px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
const CarTitle = styled.div`
    color: #ffffff;
    font-family: Lato-bold;
    font-size: 32px;
    line-height: 48px;
    max-width: 450px;

    @media ${device.smallest_laptops} {
        font-size: 21px;
        line-height: 33px;
        max-width: 320px;
    }
`
const CarPrice = styled.div`
    color: #e5e5e5;
    font-family: Lato-bold;
    font-size: 24px;
    line-height: 40px;

    @media ${device.smallest_laptops} {
        line-height: 30px;
        font-size: 19px;
    }
`

const ImageStyledBg = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    @media screen and (min-width: 881px) and (max-width: 1399px) {
        height: 350px;
    }
    @media screen and (min-width: 1400px) and (max-width: 1600px) {
        height: 450px;
    }

    @media screen and (min-width: 1601px) {
        height: 500px;
    }

    @media screen and (min-width: 2000px) {
        height: 600px;
    }
`

const ShowroomCarSlide: React.FC<Props> = ({ slide }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        const imageSrc = slide?.image_url
        setIsLoading(true)
        const image = new Image()
        image.src = imageSrc || ''
        image.onload = () => setIsLoading(false)
    }, [slide])

    return (
        <a
            href={`/showroom/${slide.entry_uid}`}
            target="_blank"
            onClick={() => {
                posthog.capture('showroom slide click')
            }}
        >
            <SlideStyle>
                <ImageStyledBg
                    style={{
                        backgroundImage: `url(${
                            slide?.image_url || grey_placeholder
                        })`,
                    }}
                >
                    <AbsoluteTxt>
                        <CarTitle>{slide?.entry_title}</CarTitle>
                        <CarPrice>
                            {!slide.price
                                ? 'POA'
                                : slide?.currency === 'EUR'
                                ? '€'
                                : slide?.currency === 'USD'
                                ? '$'
                                : '£'}{' '}
                            {slide?.price
                                ? numberWithCommas({
                                      numberToConvert: slide.price / 100,
                                  })
                                : ''}
                        </CarPrice>
                    </AbsoluteTxt>

                    <SkeletonAbsolute isthingloading={isLoading} />
                </ImageStyledBg>
            </SlideStyle>
        </a>
    )
}

export default ShowroomCarSlide
