import * as React from 'react'
import styled from 'styled-components'
import Icon from '../../atoms/icons/index'
import ShowroomGalleryOverviewGridItem from '../../atoms/image/showroom/showroomOverviewGalleryGridItem'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import AddBoxTextInfo from '../../atoms/typography/addBoxTextInfo'
import img_1 from '../../atoms/skeleton/gallery/1.jpg'
import img_2 from '../../atoms/skeleton/gallery/2.jpg'
import img_3 from '../../atoms/skeleton/gallery/3.jpg'
import img_4 from '../../atoms/skeleton/gallery/4.jpg'
import img_5 from '../../atoms/skeleton/gallery/5.jpg'
import img_6 from '../../atoms/skeleton/gallery/6.jpg'

import img_1_dark from '../../atoms/skeleton/gallery/1_dark.png'
import img_2_dark from '../../atoms/skeleton/gallery/2_dark.png'
import img_3_dark from '../../atoms/skeleton/gallery/3_dark.png'
import img_4_dark from '../../atoms/skeleton/gallery/4_dark.png'
import img_5_dark from '../../atoms/skeleton/gallery/5_dark.png'
import img_6_dark from '../../atoms/skeleton/gallery/6_dark.png'
import { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import { IGalleryImage } from '../../../redux/entities/galleries/types'

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    width: 100%;
    box-sizing: border-box;
    grid-auto-rows: 1fr;
    /* height: 100%; */
`

const GoToBox = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--primary_16);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 0%;
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;
    :hover {
        transition: all 200ms;
        background-color: var(--bg-color, #fff);
        cursor: pointer;
    }
`

interface Props {
    overview_images: IGalleryImage[] | undefined
    openImgForm?: any
    readOnlyMode?: boolean
    hasAddBox?: boolean
}

const ImageCellWrap = styled.div`
    cursor: pointer;
    height: 250px;

    @media (max-width: 1200px) {
        height: 200px;
    }

    @media (max-width: 1000px) {
        height: 180px;
    }

    @media (min-width: 2000px) {
        height: 370px;
    }
`

const placeholderImages = (theme: ITheme) =>
    theme === 'dark'
        ? [
              img_1_dark,
              img_2_dark,
              img_3_dark,
              img_4_dark,
              img_5_dark,
              img_6_dark,
          ]
        : [img_1, img_2, img_3, img_4, img_5, img_6]

export default function ShowroomOverviewGalleryDesktop(props: Props) {
    const [showBoxInfo, setShowBoxInfo] = React.useState(false)
    const { theme } = useThemes()

    const setTrueBoxInfoToggle = () => setShowBoxInfo(true)

    const setFalseBoxInfoToggle = () => setShowBoxInfo(false)

    const { overview_images, openImgForm, readOnlyMode, hasAddBox } = props

    return (
        <>
            <GridContainer>
                {overview_images &&
                    overview_images.map(
                        (image: IGalleryImage, index: number) => {
                            if (image) {
                                return (
                                    <div key={index}>
                                        <ShowroomGalleryOverviewGridItem
                                            image_obj={image}
                                        />
                                    </div>
                                )
                            } else
                                return (
                                    <div
                                        key={index}
                                        style={{ display: 'none' }}
                                    />
                                )
                        }
                    )}
                {!readOnlyMode && hasAddBox && (
                    // IF USER OWN CAR DISPLAY ADD BTN
                    <ImageCellWrap>
                        <GoToBox
                            onClick={openImgForm && openImgForm}
                            onMouseEnter={setTrueBoxInfoToggle}
                            onMouseLeave={setFalseBoxInfoToggle}
                        >
                            {showBoxInfo === false && (
                                <Icon icon="add_plus" height={'20px'} />
                            )}
                            {showBoxInfo === true && (
                                <AddBoxTextInfo>
                                    Add at least 6 images to your car
                                </AddBoxTextInfo>
                            )}
                        </GoToBox>
                    </ImageCellWrap>
                )}

                {!readOnlyMode &&
                    (!overview_images || overview_images.length === 0) &&
                    !hasAddBox &&
                    placeholderImages(theme).map((img, index: number) => (
                        <div key={index}>
                            <ShowroomGalleryOverviewGridItem
                                image_obj={{
                                    id: `placeholder_${index}`,
                                    url: img,
                                }}
                                isPlaceholder
                            />
                        </div>
                    ))}
            </GridContainer>

            {/* IF NO IMAGES IDS */}
            {hasAddBox && readOnlyMode && (
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '50px',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            maxWidth: '1400px',
                        }}
                    >
                        <NoDataRectangle text="no images" />
                    </div>
                </div>
            )}
        </>
    )
}
