import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import InputField from '../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import { Notice } from '../../../molecules/technicalInformation/modal'
import { message } from 'antd'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'
import { IUser } from '../../../../redux/user/types'
import { ICreateShareInviteRequestPayload } from '../../../../redux/entities/cars/actions'
import { garagesActions } from '../../../../redux/entities/garages/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-top: 7vh;
    height: 100vh;
    overflow-y: auto;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);
    position: relative;
    animation: fadeIn 0.2s both;
    animation-delay: 0.15s;
    opacity: 1;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @media ${device.mobile} {
        padding-top: 5vh;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 7vw;
    padding-right: 7vw;
    width: 100%;
    box-sizing: border-box;

    @media ${device.ipad} {
        max-width: 600px;
        padding-left: 0px;
        padding-right: 0px;
    }
`

const FormTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 17px;
    text-transform: capitalize;
`

export const FormHeader = styled.div`
    padding-top: 0px;
    color: #000000;
    font-size: 14px;
    font-family: 'Lato-Light';
    padding-right: 20px;
    line-height: 22px;
`

const SmallPadding = styled.div`
    padding-top: 24px;
`

const LargePadding = styled.div`
    padding-top: 60px;
`

export const Error = styled.div`
    color: red;
    font-style: italic;
    font-size: 11px;
`

const ButtonsWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    padding-top: 32px;
    bottom: env(safe-area-inset-bottom);
    border-top: 1px solid var(--border-muted, #e5e5e5);
    padding: 30px;

    @media ${device.ipad} {
        max-width: 600px;
        bottom: 96px;
        padding-left: 0px;
        padding-right: 0px;
    }

    @media (max-height: 568px) {
        position: relative;
    }
`

interface Values {
    single_item_edited_value_email_field_mobile: string
    single_item_edited_value_first_name_field_mobile: string
    single_item_edited_value_last_name_field_mobile: string
}

interface OtherProps {
    user: IUser | null
    close: any
    share_id: string
    entity_id: string
    isDisabled?: boolean
    dataCyId?: string
    isGarage?: boolean
    dispatch: any
}

const errorBanner = (messageText: string) => {
    message.error(messageText)
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        close,
        // setFieldValue,
        values,
        errors,
        isDisabled,
        dataCyId,
    } = props

    const { theme } = useThemes()

    return (
        <EditFormContainerMobile>
            <InnerFormPadding>
                <FormTitle>Invite to Custodian via Email & Share</FormTitle>
                <SmallPadding />
                <Notice>
                    Fill up some basic informations about the person you want to
                    invite
                </Notice>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id={'single_item_edited_value_email_field_mobile'}
                    name={'single_item_edited_value_email_field_mobile'}
                    placeholder={'Email'}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.single_item_edited_value_email_field_mobile}
                    font_family="Lato"
                    paddingLeft="0px"
                    width="100%"
                    fontSize={'14px'}
                    dataCyId={dataCyId}
                    linecolor={colours[theme].text_muted}
                    type={'email'}
                    disabled={isDisabled ? isDisabled : false}
                />
                <Expander
                    height={
                        errors.single_item_edited_value_email_field_mobile
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.single_item_edited_value_email_field_mobile}
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id={'single_item_edited_value_first_name_field_mobile'}
                    name={'single_item_edited_value_first_name_field_mobile'}
                    placeholder={'Given Name'}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={
                        values.single_item_edited_value_first_name_field_mobile
                    }
                    font_family="Lato"
                    paddingLeft="0px"
                    width="100%"
                    fontSize={'14px'}
                    textTransform={'capitalize'}
                    dataCyId={dataCyId}
                    linecolor={colours[theme].text_muted}
                    type={'text'}
                    disabled={isDisabled ? isDisabled : false}
                />
                <Expander
                    height={
                        errors.single_item_edited_value_first_name_field_mobile
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {
                                errors.single_item_edited_value_first_name_field_mobile
                            }
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id={'single_item_edited_value_last_name_field_mobile'}
                    name={'single_item_edited_value_last_name_field_mobile'}
                    placeholder={'Family Name'}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={
                        values.single_item_edited_value_last_name_field_mobile
                    }
                    font_family="Lato"
                    paddingLeft="0px"
                    width="100%"
                    fontSize={'14px'}
                    textTransform={'capitalize'}
                    dataCyId={dataCyId}
                    linecolor={colours[theme].text_muted}
                    type={'text'}
                    disabled={isDisabled ? isDisabled : false}
                />
                <Expander
                    height={
                        errors.single_item_edited_value_last_name_field_mobile
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {
                                errors.single_item_edited_value_last_name_field_mobile
                            }
                        </Error>
                    </Faded>
                </Expander>
            </InnerFormPadding>
            <LargePadding />

            <LargePadding />

            <ButtonsWrapper>
                <ButtonsStandardBottomRowMobile
                    cancel={close}
                    submit={props.handleSubmit}
                    disabled={
                        errors.single_item_edited_value_email_field_mobile ||
                        errors.single_item_edited_value_first_name_field_mobile ||
                        errors.single_item_edited_value_last_name_field_mobile
                            ? true
                            : false
                    }
                    confirmationtext={'Send invite'}
                />
            </ButtonsWrapper>
        </EditFormContainerMobile>
    )
}

const AddShareByEmailFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_email_field_mobile: '',
        single_item_edited_value_first_name_field_mobile: '',
        single_item_edited_value_last_name_field_mobile: '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_edited_value_email_field_mobile: Yup.string()
                    .email('Email not valid')
                    .required('Email is required'),
                single_item_edited_value_first_name_field_mobile: Yup.string()
                    .min(2, 'First name should have at least 2 characters.')
                    .max(30, 'First name should have max 30 characters.')
                    .required('First name is required'),
                single_item_edited_value_last_name_field_mobile: Yup.string()
                    .min(2, 'Last name should have at least 2 characters.')
                    .max(30, 'Last name should have max 30 characters.')
                    .required('Last name is required'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        // const dispatchProps = {
        //     createCarShareInviteRequest: (p: ICreateShareInviteRequestPayload) =>
        //         createCarShareInviteRequest(p),
        //     createGarageShareInviteRequest: (p: ICreateShareInviteRequestPayload) =>
        //         createGarageShareInviteRequest(p),
        // }

        // function mapStateToProps(state: RootState) {
        //     return {
        //         user: state.user.userLoggedIn,
        //     }
        // }

        let payload: ICreateShareInviteRequestPayload = {
            entity_id: props.entity_id,
            api_payload: {
                share_id: props.share_id,
                recipient_email:
                    values.single_item_edited_value_email_field_mobile &&
                    values.single_item_edited_value_email_field_mobile.trim(),
                recipient_given_name:
                    values.single_item_edited_value_first_name_field_mobile
                        ? values.single_item_edited_value_first_name_field_mobile.trim()
                        : '',
                recipient_family_name:
                    values.single_item_edited_value_last_name_field_mobile
                        ? values.single_item_edited_value_last_name_field_mobile.trim()
                        : '',
            },
        }

        if (
            props.user &&
            props.user.email &&
            values.single_item_edited_value_email_field_mobile &&
            props.user.email.toLowerCase() !==
                values.single_item_edited_value_email_field_mobile
                    .trim()
                    .toLowerCase()
        ) {
            props.isGarage
                ? props.dispatch(
                      garagesActions.createGarageShareInviteRequest(payload)
                  )
                : props.dispatch(
                      carActions.createCarShareInviteRequest(payload)
                  )
            setSubmitting(false)
            props.close()
        } else {
            errorBanner('You cannot add an invite for yourself.')
        }
    },
})(InnerForm)

export default AddShareByEmailFormMobile
