import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode
    show?: boolean
}

type IStyle = {
    hideComponent?: boolean
    triggerAnimationHide?: boolean
}

const Backdrop = styled.div<IStyle>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.55);
    opacity: 1;
    z-index: 7;

    //enter animation
    animation: fadeIn 200ms ease-in both;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    //exit animation
    ${(props) =>
        props.triggerAnimationHide &&
        'animation: fadeOut 200ms ease-in-out 200ms both;'}

    @keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
`

const Container = styled.div<IStyle>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    overflow-y: auto;
    background-color: var(--bg-color, #fff);
    opacity: 1;
    z-index: 7;

    //enter animation
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1) both;

    @keyframes slideUp {
        0% {
            transform: translate3d(0, 100%, 0);
        }

        100% {
            transform: translateY(0, 0%, 0);
        }
    }

    //exit animation
    ${(props) =>
        props.triggerAnimationHide &&
        'animation: slideDown 200ms ease-out both;'}

    @keyframes slideDown {
        from {
            transform: translate3d(0, 0, 0);
        }

        to {
            transform: translate3d(0, 100%, 0);
        }
    }
`

const BottomUpSlider: React.FC<Props> = ({ children, show = true }) => {
    const [shouldHide, setShouldHide] = useState(false)

    useEffect(() => {
        if (show === false) {
            const timer = setTimeout(() => {
                setShouldHide(true)
            }, 500)
            return () => clearTimeout(timer)
        } else {
            setShouldHide(false)
        }
    }, [show])

    return (
        !shouldHide && (
            <Backdrop triggerAnimationHide={!show}>
                <Container
                    triggerAnimationHide={!show}
                    data-attr="bottom-up-slider"
                >
                    {children}
                </Container>
            </Backdrop>
        )
    )
}

export default BottomUpSlider
