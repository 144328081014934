import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import SelectSearchBarCarInfoMobile from '../../organisms/editForms/technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'
import queryString from 'query-string'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'

const CarSearch: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const makeDropDownList = useAppSelector(
        (state) => state.localdata.dropdownData.carMakes
    )
    const modelDropDownList = useAppSelector(
        (state) => state.localdata.dropdownData.carModels
    )

    const params = new URLSearchParams(location.search)
    const field_id = params.get('field_id') || ''

    const getNecessaryData = () => {
        if (field_id === 'make' && !makeDropDownList) {
            dispatch(dropDownActions.setMakeDropdownDataRequest())
        }
    }

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else {
            getNecessaryData()
        }
    }, [userLoggedIn])

    const handleChange = (name: string, uid?: string) => {
        const getReturnUrl = () => {
            let returnTo = params.get('return_to') as string
            const formId = params.get('form_id')

            const returnUrl = queryString.stringifyUrl({
                url: returnTo,
                query: { form_id: formId },
            })

            return returnUrl
        }

        if (field_id === 'make') {
            dispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: name,
                    make_uid: uid,
                })
            )
            dispatch(dropDownActions.setTempMakeDisplay(name))
            dispatch(dropDownActions.setTempMakeIDSuccess(uid))
            dispatch(dropDownActions.setTempModelDisplay('-'))
            navigate(getReturnUrl())
        }

        if (field_id === 'model') {
            dispatch(dropDownActions.setTempModelDisplay(name))
            dispatch(dropDownActions.setTempModelIDSuccess(uid))
            navigate(getReturnUrl())
        }
    }

    const getItems = () => {
        if (field_id === 'make') {
            return makeDropDownList ?? []
        }

        if (field_id === 'model') {
            return modelDropDownList ?? []
        }

        return []
    }

    const searchOptionsData: any = {
        make: {
            text: 'make',
            id: 'make',
            answer: '',
            unique_answer_id: undefined,
        },
        model: {
            text: 'model',
            id: 'model',
            answer: '',
            unique_answer_id: undefined,
        },
    }

    return (
        <>
            {userLoggedIn && (
                <SelectSearchBarCarInfoMobile
                    handleSelectChange={(name: string, other?: string) => {
                        handleChange(name, other)
                    }}
                    field_id={field_id}
                    items={getItems()}
                    item={searchOptionsData[field_id]}
                    isStandalonePage={true}
                    dropdownTargetId={
                        { make: 'carMakes', model: 'carModels' }[field_id]
                    }
                    allowAdd={true}
                    addAnItemFunc={(id: string, name: string) => {
                        dispatch(
                            dropDownActions.setIsLoadingNewMakeOrModel(true)
                        )
                        dispatch(
                            dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                {
                                    dropdownTargetId: id,
                                    name: name,
                                }
                            )
                        )
                    }}
                />
            )}
        </>
    )
}

export default CarSearch
