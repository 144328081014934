import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    IPublishValidationObject,
    IValidation_subObject_normalised,
} from '../../showroomBars/bottomBars/carShowroomEditBottomBar'
import { Sheet } from 'react-modal-sheet'
import { device } from '../../../displays/devices'
import useWindowSize, { WindowSize } from '../../../displays/windowSizeHook'
import InsuranceValidationSheet from './insuranceValidationSheet'
import { useAppSelector } from '../../../../../redux/store/hooks'
import {
    IAnyObject,
    IInsuranceQuoteApplication_Car,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { useNavigate } from 'react-router-dom'

export type ISubmitInsuranceApplicationValidationObject = {
    [key: string]: {
        check: boolean
        text: string
        action: any
        hasNitems?: number
        minN?: number
        subItems?: IValidation_subObject_normalised
        tooltipText?: string
        isTooltipRemoved?: boolean
        isDisabled?: boolean
    }
}

const WrapperDesktop = styled.div`
    display: none;
    @media (min-width: 880px) {
        display: block;
        position: fixed;
        right: 30px;
        bottom: 112px;
        z-index: 102;
    }

    @media (min-width: 880px) and (max-height: 950px) {
        bottom: 90px;
    }
`

const WrapperMobile = styled.div`
    @media (min-width: 880px) {
        display: none !important;
    }
`

const CustomSheet = styled(Sheet)<{ $customHeight?: string }>`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;

        @media (min-width: 880px) {
            display: none !important;
        }
    }
    .react-modal-sheet-container {
        height: 80vh !important;

        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
        overflow-y: scroll !important;

        @media (min-width: 880px) {
            display: none !important;
        }
    }
    .react-modal-sheet-header {
        height: 10px !important;

        @media (min-width: 880px) {
            display: none !important;
        }
    }
    .react-modal-sheet-drag-indicator {
        width: 0px !important;

        @media (min-width: 880px) {
            display: none !important;
        }
    }
    .react-modal-sheet-content {
        /* custom styles */

        @media (min-width: 880px) {
            display: none !important;
        }
    }
`

let generateTitle = (vehicle: IInsuranceQuoteApplication_Car | undefined) => {
    if (vehicle && vehicle.tech_info) {
        if (
            vehicle.tech_info?.manufacture_year ||
            vehicle.tech_info?.make?.name ||
            vehicle.tech_info?.model?.name
        ) {
            return `${
                vehicle.tech_info?.manufacture_year
                    ? `${vehicle.tech_info.manufacture_year} `
                    : ''
            }${
                vehicle.tech_info?.make?.name
                    ? `${vehicle.tech_info.make.name} `
                    : ''
            }${
                vehicle.tech_info?.model?.name
                    ? vehicle.tech_info.model.name
                    : ''
            }`
        } else {
            return 'Untitled car'
        }
    }
}

const checkIfValid = (
    obj:
        | IAnyObject
        | {
              disabilities?: IAnyObject | undefined
              claims?: IAnyObject | undefined
              memberships?: IAnyObject | undefined
              convictions?: IAnyObject | undefined
              motoring_convictions?: IAnyObject | undefined
          }
        | undefined
) => {
    if (
        obj &&
        Object.keys(obj).length > 0 &&
        Object.values(obj).some((item) => item !== undefined)
    ) {
        return false
    } else {
        return true
    }
}

const InsuranceValidationSheetManager = ({
    triggerShowAddEverydayCar,
    showValidationFromBtnPress,
    closeValidationFromBtnPress,
}: {
    triggerShowAddEverydayCar: () => void
    showValidationFromBtnPress: boolean
    closeValidationFromBtnPress: () => void
}) => {
    // const dispatch = useAppDispatch()

    // const { id } = useParams<{id?: string}>()

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft
    })

    let user_id = useAppSelector((state) => {
        return state.user.userLoggedIn?.id
    })

    const navigate = useNavigate()

    let validationObjects = (): IPublishValidationObject => {
        let initial: IPublishValidationObject = {
            insurance_details: {
                check: checkIfValid(application?.insurance_details_errors),
                text: 'Insurance details',
                action: () => {
                    return navigate(
                        `/insurance/application/${application?.id}/insurance_details`
                    )
                },
            },

            insured_cars: {
                check:
                    application?.vehicles_to_insure_ids_list?.length > 0
                        ? true
                        : false,
                text: 'Vehicles to insure',
                tooltipText:
                    'You need to select at least on car to be insured.',
                action: () => {
                    navigate(
                        `/insurance/application/${application?.id}/vehicles`
                    )
                },
            },
        }

        if (
            application?.vehicles_to_insure_ids_list &&
            application?.vehicles_to_insure_ids_list?.length > 0 &&
            initial['insured_cars']
        ) {
            delete initial['insured_cars']

            let cars: any = {}

            application.vehicles_to_insure_ids_list.forEach((id) => {
                let vehicle = application?.vehicles[id]

                cars[id] = {
                    check:
                        checkIfValid(vehicle?.tech_info_errors) &&
                        checkIfValid(vehicle?.mileage_parking_errors) &&
                        checkIfValid(vehicle?.ownership_errors) &&
                        checkIfValid(vehicle?.entities_error?.modifications),
                    isTooltipRemoved: false,
                    text: generateTitle(vehicle),
                    isCaps: true,
                    action: () => {},
                    subItems: {
                        tech_info: {
                            check:
                                checkIfValid(vehicle?.tech_info_errors) &&
                                checkIfValid(vehicle?.entities_error),
                            text: 'Technical specification',
                            action: () => {
                                navigate(
                                    `/insurance/application/${application?.id}/car/${id}/tech_info`
                                )
                            },
                        },
                        mileage_and_parking: {
                            check: checkIfValid(
                                vehicle?.mileage_parking_errors
                            ),
                            text: 'Mileage and parking',
                            action: () => {
                                navigate(
                                    `/insurance/application/${application?.id}/car/${id}/mileage_parking`
                                )
                            },
                        },
                        ownership: {
                            check: checkIfValid(vehicle?.ownership_errors),
                            text: 'Ownership',
                            action: () => {
                                navigate(
                                    `/insurance/application/${application?.id}/car/${id}/ownership`
                                )
                            },
                        },
                    },
                }
            })

            initial = {
                ...initial,
                ...cars,
            }
        }

        initial = {
            ...initial,
            main_driver: {
                check:
                    checkIfValid(
                        application?.main_driver?.basic_details_errors
                    ) &&
                    checkIfValid(
                        application?.main_driver?.additional_details_errors
                    ) &&
                    checkIfValid(
                        application?.main_driver?.licence_address_errors
                    ) &&
                    checkIfValid(
                        application?.main_driver?.track_record_errors
                    ) &&
                    checkIfValid(
                        application?.main_driver?.entities_error?.disabilities
                    ) &&
                    checkIfValid(
                        application?.main_driver?.entities_error?.memberships
                    ) &&
                    checkIfValid(
                        application?.main_driver?.entities_error?.claims
                    ) &&
                    checkIfValid(
                        application?.main_driver?.entities_error?.convictions
                    ) &&
                    checkIfValid(
                        application.main_driver?.entities_error
                            ?.motoring_convictions
                    ),
                isTooltipRemoved: false,
                text: application?.main_driver?.basic_details
                    ? `${application?.main_driver?.basic_details?.given_name} ${application?.main_driver?.basic_details?.family_name}`
                    : 'Policy holder',
                action: () => {},
                subItems: {
                    basic_details: {
                        check: checkIfValid(
                            application?.main_driver?.basic_details_errors
                        ),
                        text: 'Basic details',
                        action: () => {
                            return navigate(
                                `/insurance/application/${application?.id}/main_driver/${user_id}/basic_details`
                            )
                        },
                    },
                    licence_address: {
                        check: checkIfValid(
                            application?.main_driver?.licence_address_errors
                        ),
                        text: 'Driver licence',
                        action: () => {
                            return navigate(
                                `/insurance/application/${application?.id}/main_driver/${user_id}/licence_address`
                            )
                        },
                    },
                    additional_details: {
                        check:
                            checkIfValid(
                                application.main_driver
                                    .additional_details_errors
                            ) &&
                            checkIfValid(
                                application.main_driver.entities_error
                                    ?.disabilities
                            ) &&
                            checkIfValid(
                                application.main_driver.entities_error
                                    ?.memberships
                            ),
                        text: 'Additional details',
                        action: () => {
                            navigate(
                                `/insurance/application/${application?.id}/main_driver/${user_id}/additional_details`
                            )
                        },
                    },
                    track_record: {
                        check:
                            checkIfValid(
                                application?.main_driver?.track_record_errors
                            ) &&
                            checkIfValid(
                                application?.main_driver?.entities_error?.claims
                            ) &&
                            checkIfValid(
                                application?.main_driver?.entities_error
                                    ?.convictions
                            ) &&
                            checkIfValid(
                                application?.main_driver?.entities_error
                                    ?.motoring_convictions
                            ),
                        text: 'Track record',
                        action: () => {
                            navigate(
                                `/insurance/application/${application?.id}/main_driver/${user_id}/track_record`
                            )
                        },
                    },
                },
            },
        }

        let everyday_car: IPublishValidationObject = {
            everyday_car: {
                check:
                    application?.everyday_car_ids &&
                    application?.everyday_car_ids?.length > 0
                        ? true
                        : false,
                text: 'Has everyday vehicle',
                action: () => {
                    triggerShowAddEverydayCar()
                    setIsValidationSheetOpened(false)
                    closeValidationFromBtnPress()
                },
            },
        }

        if (
            !application?.everyday_car_ids ||
            application?.everyday_car_ids?.length === 0
        ) {
            initial = { ...initial, ...everyday_car }
        } else {
            let everyday_not_insured_cars: any = {}

            application?.everyday_car_ids?.forEach((id) => {
                if (
                    application?.vehicles_to_insure_ids_list?.includes(id) ===
                    false
                ) {
                    let vehicle = application?.vehicles[id]

                    everyday_not_insured_cars[id] = {
                        check:
                            checkIfValid(vehicle?.tech_info_errors) &&
                            checkIfValid(vehicle?.mileage_parking_errors) &&
                            checkIfValid(vehicle?.ownership_errors) &&
                            checkIfValid(
                                vehicle?.entities_error?.modifications
                            ),
                        isTooltipRemoved: false,
                        text: generateTitle(vehicle),
                        isCaps: true,
                        action: () => {},
                        subItems: {
                            tech_info: {
                                check:
                                    checkIfValid(vehicle?.tech_info_errors) &&
                                    checkIfValid(vehicle?.entities_error),
                                text: 'Technical specification',
                                action: () => {
                                    navigate(
                                        `/insurance/application/${application.id}/everyday_car/${id}/tech_info`
                                    )
                                },
                            },
                            mileage_and_parking: {
                                check: checkIfValid(
                                    vehicle?.mileage_parking_errors
                                ),
                                text: 'Mileage and parking',
                                action: () => {
                                    navigate(
                                        `/insurance/application/${application.id}/everyday_car/${id}/mileage_parking`
                                    )
                                },
                            },
                            ownership: {
                                check: checkIfValid(vehicle?.ownership_errors),
                                text: 'Ownership',
                                action: () => {
                                    navigate(
                                        `/insurance/application/${application.id}/everyday_car/${id}/ownership`
                                    )
                                },
                            },
                        },
                    }
                }
            })

            initial = {
                ...initial,

                ...everyday_not_insured_cars,
            }
        }

        let drivers: any = {}

        application.selected_named_drivers?.forEach((key, index) => {
            let other_driver = application.other_drivers[key]
            drivers[key] = {
                check:
                    checkIfValid(other_driver?.basic_details_errors) &&
                    checkIfValid(other_driver?.additional_details_errors) &&
                    checkIfValid(other_driver?.licence_address_errors) &&
                    checkIfValid(other_driver.track_record_errors) &&
                    checkIfValid(other_driver?.entities_error?.disabilities) &&
                    checkIfValid(other_driver?.entities_error?.memberships) &&
                    checkIfValid(other_driver?.entities_error?.claims) &&
                    checkIfValid(other_driver?.entities_error?.convictions) &&
                    checkIfValid(
                        other_driver?.entities_error?.motoring_convictions
                    ),
                isTooltipRemoved: false,
                text: application?.other_drivers[key]?.basic_details
                    ? `${application.other_drivers[key].basic_details?.given_name} ${application.other_drivers[key].basic_details?.family_name}`
                    : `Driver ${index}`,
                action: () => {},
                subItems: {
                    basic_details: {
                        check: checkIfValid(other_driver?.basic_details_errors),
                        text: 'Basic details',
                        action: () => {
                            navigate(
                                `/insurance/application/${application?.id}/other_driver/${application?.other_drivers[key]?.id}/basic_details`
                            )
                        },
                    },
                    licence_address: {
                        check: checkIfValid(
                            other_driver?.licence_address_errors
                        ),
                        text: 'Driver licence',
                        action: () => {
                            navigate(
                                `/insurance/application/${application?.id}/other_driver/${application?.other_drivers[key]?.id}/licence_address`
                            )
                        },
                    },
                    additional_details: {
                        check:
                            checkIfValid(
                                other_driver?.additional_details_errors
                            ) &&
                            checkIfValid(
                                other_driver?.entities_error?.disabilities
                            ) &&
                            checkIfValid(
                                other_driver?.entities_error?.memberships
                            ),
                        text: 'Additional details',
                        action: () => {
                            navigate(
                                `/insurance/application/${application?.id}/other_driver/${application?.other_drivers[key]?.id}/additional_details`
                            )
                        },
                    },
                    track_record: {
                        check:
                            checkIfValid(other_driver?.track_record_errors) &&
                            checkIfValid(
                                other_driver?.entities_error?.claims
                            ) &&
                            checkIfValid(
                                other_driver?.entities_error?.convictions
                            ) &&
                            checkIfValid(
                                other_driver?.entities_error
                                    ?.motoring_convictions
                            ),
                        text: 'Track record',
                        action: () => {
                            navigate(
                                `/insurance/application/${application?.id}/other_driver/${application?.other_drivers[key]?.id}/track_record`
                            )
                        },
                    },
                },
            }

            if (
                application.other_drivers[key]?.drive_list_errors &&
                application.other_drivers[key].drive_list_errors?.message
            ) {
                drivers[key] = {
                    ...drivers[key],
                    check: false,
                    subItems: {
                        ...drivers[key].subItems,
                        drive_list: {
                            check: false,
                            text: 'Drive List',
                            action: () => {
                                navigate(
                                    `/insurance/application/${application.id}/other_driver/${application.other_drivers[key].id}?anchor=other_driver_drive_list`
                                )
                            },
                        },
                    },
                }
            }
        })

        initial = {
            ...initial,
            ...drivers,
        }

        return initial
    }

    let hasValidationError = useAppSelector((state) => {
        return state.insuranceQuoteApplication.showValidationSheet
    })

    const [isValidationSheetOpened, setIsValidationSheetOpened] =
        useState<boolean>(false)

    useEffect(() => {
        if (hasValidationError) {
            setIsValidationSheetOpened(true)
        }

        if (hasValidationError === false) {
            setIsValidationSheetOpened(false)
        }
        return () => {
            setIsValidationSheetOpened(false)
        }
    }, [hasValidationError])

    useEffect(() => {
        if (showValidationFromBtnPress) {
            setIsValidationSheetOpened(true)
        }

        return () => {
            setIsValidationSheetOpened(false)
        }
    }, [showValidationFromBtnPress])

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    return (
        <>
            {!isMobile && isValidationSheetOpened && (
                <WrapperDesktop>
                    <InsuranceValidationSheet
                        publishChecksObject={validationObjects()}
                        onClose={() => {
                            setIsValidationSheetOpened(false)
                            closeValidationFromBtnPress()
                        }}
                    />
                </WrapperDesktop>
            )}

            {isMobile && (
                <WrapperMobile>
                    <CustomSheet
                        isOpen={isValidationSheetOpened}
                        onClose={() => {
                            setIsValidationSheetOpened(false)
                            closeValidationFromBtnPress()
                        }}
                        disableDrag={true}
                    >
                        <Sheet.Container>
                            <InsuranceValidationSheet
                                publishChecksObject={validationObjects()}
                                onClose={() => {
                                    setIsValidationSheetOpened(false)
                                    closeValidationFromBtnPress()
                                }}
                                isMobile={isMobile}
                            />
                        </Sheet.Container>
                        <Sheet.Backdrop
                            data-attr="showroom-validation-backdrop-overlay"
                            onTap={() => {
                                setIsValidationSheetOpened(
                                    !isValidationSheetOpened
                                )
                            }}
                        />
                    </CustomSheet>
                </WrapperMobile>
            )}
        </>
    )
}

export default InsuranceValidationSheetManager
