import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../../services'
import posthog from 'posthog-js'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { GetCarActionCheck } from './getCarActionCheck'
import { carActions } from '../reducer'
import { IReduxError } from '../types'

export function* HandoverDeletion(carID: string) {
    try {
        yield call(api.handover.deleteHandover, carID)
        yield call(GetCarActionCheck, { carid: carID })

        // Capture event
        posthog.capture('car_handover delete')

        yield put(carActions.deleteHandoverSuccess(carID))
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: HandoverDeletion,
                payload: carID,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(carActions.deleteHandoverError(typedError))
            return
        }
    }
}

function* WatcherDeleteHandover() {
    while (true) {
        let { payload } = yield take(carActions.deleteHandoverRequest)
        yield call(HandoverDeletion, payload)
    }
}

const handover_deletion_saga: any[] = [fork(WatcherDeleteHandover)]

export default handover_deletion_saga
