import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../../services'
import posthog from 'posthog-js'
import { retrieveUserGarageId } from '../../garages/sagas/garage'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IRootState } from '../../../store'
import { carActions } from '../reducer'
import { tasksActions } from '../../../tasks/reducer'
import { IUser } from '../../../user/types'
import { IReduxError } from '../types'
import { garagesActions } from '../../garages/reducer'
import { usersActions } from '../../../user/reducer'
import { push } from 'redux-first-history'

let getUserData = (state: IRootState) => state.user.userLoggedIn

function* deleteCarApi(carid: string) {
    try {
        yield api.entities.mutateGarage.deleteACar(carid)
        yield put(carActions.deleteCarSuccess(carid))
        yield put(tasksActions.removeStoreTasksSuccess())

        // resetting sorted garage ids
        let userDataInState: IUser = yield select(getUserData)
        let garage_id = userDataInState.owns_garage.uid
        if (garage_id) {
            yield put(garagesActions.resetSortedCarsInGarage(garage_id))
        }

        // if car was archived clean up archived cars array
        const archivedCars: string[] | undefined =
            userDataInState.archived_cars?.car_ids
        if (archivedCars) {
            const cleanedUpArchivedCars = archivedCars.filter(
                (id) => id !== carid
            )
            yield put(usersActions.updateArchivedCars(cleanedUpArchivedCars))
        }

        // we need to keep these calls as they also update all other entities data (cars, tech_info, gallery etc)
        // yield put(getGarageByUserIdRequest())
        yield call(retrieveUserGarageId)
        yield put(push('/garage'))
        // Capture event
        posthog.capture('DELETE CAR')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: deleteCarApi,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(carActions.deleteCarError(typedError))
            return
            // window.location.reload()
        }
    }
    yield put(push('/garage'))
}

function* watcherDeleteCar() {
    while (true) {
        const { payload } = yield take(carActions.deleteCarRequest)
        yield call(deleteCarApi, payload)
    }
}

const car_deletion_sagas: any[] = [fork(watcherDeleteCar)]

export default car_deletion_sagas
