import styled from 'styled-components'

const EnquiryCustomMessageTextStyling = styled.div`
    font-family: Lato;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    letter-spacing: 5e-5em;
    color: #666666;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
`

const Wrap = styled.div`
    margin-top: 16px;
    height: 100px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
`
const Gradient = styled.div`
    top: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 22.3%,
        #ffffff 95%
    );
`

type Props = {
    text: string
    onClick?: () => any
}

const EnquiryCustomMessageText = (props: Props) => {
    return (
        <Wrap onClick={() => props.onClick && props.onClick()}>
            <EnquiryCustomMessageTextStyling>
                {props.text}
            </EnquiryCustomMessageTextStyling>
            <Gradient />
        </Wrap>
    )
}

export default EnquiryCustomMessageText
