import { useParams, useNavigate } from 'react-router-dom'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import ShowroomEntryBasicInfoManagerMobile from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerMobile'
import Faded from '../../../templates/animated/faded'
import styled from 'styled-components'
import { IShowroomEntryBasicDataForm } from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerDesktop'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { showroomActions } from '../../../../redux/showroom/reducer'
import {
    IManageTopSubmenuSuccessArg,
    menuActions,
} from '../../../../redux/menus/reducer'
import { useEffect } from 'react'
import { IRootState } from '../../../../redux/store'
import { usersActions } from '../../../../redux/user/reducer'
import { IUpdateCarGeneralPayloadReq } from '../../../../redux/entities/cars/types'
import { carActions } from '../../../../redux/entities/cars/reducer'

const PageContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Wrapper = styled.div`
    background-color: var(--bg-color, #fff);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    padding-top: 0px;
    position: relative;
    transition: all 200ms ease-in;
`

const Title = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    color: var(--text-strong, #1a1a1a);
    padding-top: 16px;
    padding-bottom: 16px;
`

type EntryParams = {
    entryid: string
}

const EditShowroomEntryBasicInfoMobile = () => {
    const { entryid } = useParams<EntryParams>()
    const navigate = useNavigate()

    let userShowroomEntriesData = useAppSelector((state) => {
        return state.showroom.userShowroomEntries
    })

    let currentEntry: IShowroomEntry | undefined =
        entryid && userShowroomEntriesData && userShowroomEntriesData[entryid]
            ? userShowroomEntriesData[entryid]
            : undefined

    let carid = currentEntry?.car?.id

    const userLoggedIn = useAppSelector((state: IRootState) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [])

    const removeTopSubMenu = (status: IManageTopSubmenuSuccessArg) =>
        dispatch(menuActions.manageTopSubmenu(status))

    useEffect(() => {
        // trigger the function to dispatch the top sub menu remove action
        removeTopSubMenu({ isRemoved: true })
        if (userLoggedIn) {
            if (
                (entryid && !userShowroomEntriesData) ||
                (userShowroomEntriesData &&
                    !userShowroomEntriesData[`${entryid}`])
            ) {
                dispatch(
                    showroomActions.getShowroomEntryByIDRequest({
                        is_owner: true,
                        entryID: `${entryid}`,
                    })
                )
            }
        }
        // set up nav back as before on page unmount
        return () => {
            removeTopSubMenu({ isRemoved: false })
        }
    }, [userLoggedIn])

    const dispatch = useAppDispatch()

    const submit = (formData: IShowroomEntryBasicDataForm) => {
        let carPayload: IUpdateCarGeneralPayloadReq | undefined = carid
            ? {
                  car_id: carid,
                  dataListForm: [
                      {
                          id: 'title',
                          text: 'title',
                          answer: formData.car_title,
                      },
                      {
                          id: 'short_description',
                          text: 'title',
                          answer: formData.vehicle_bio,
                      },
                  ],
              }
            : undefined
        carPayload &&
            dispatch(carActions.writeCarDataGeneralRequest(carPayload))
        carid &&
            dispatch(
                showroomActions.updateEntryShowroomRequest({
                    uid: `${entryid}`,
                    car_id: carid,
                    data: {
                        type_of_sale: formData.poa
                            ? 'PRICE_ON_ASKING'
                            : formData.set_price
                            ? 'SET_PRICE'
                            : 'PRICE_RANGE',
                        price_min:
                            formData.set_price && formData.set_price.value
                                ? formData.set_price.value
                                : formData.price_range?.valueFrom,
                        price_max:
                            formData.set_price && formData.set_price.value
                                ? formData.set_price.value
                                : formData.price_range?.valueTo,
                        currency:
                            formData.price_range &&
                            formData.price_range.currency &&
                            (formData.price_range.currency === 'USD' ||
                                formData.price_range.currency === 'EUR' ||
                                formData.price_range.currency === 'GBP')
                                ? formData.price_range.currency
                                : formData.set_price &&
                                  formData.set_price.currency &&
                                  (formData.set_price.currency === 'USD' ||
                                      formData.set_price.currency === 'EUR' ||
                                      formData.set_price.currency === 'GBP')
                                ? formData.set_price.currency
                                : 'GBP',
                        open_to_offers: formData.set_price
                            ? formData.set_price.open_to_offer
                            : undefined,
                    },
                })
            )

        setTimeout(() => {
            dispatch(
                showroomActions.validateEntryForPublicationRequest(`${entryid}`)
            )
        }, 150)
        navigate(-1)
    }

    return (
        <IpadAndMobileDisplay>
            <Faded>
                <PageContainer>
                    <Wrapper>
                        <Title>Car details</Title>
                        <div
                            style={{
                                height: '1px',
                                width: '100vw',
                                transform: 'translateX(-24px)',
                                backgroundColor: 'var(--border-muted, #E5E5E5)',
                            }}
                        />

                        <div style={{ paddingTop: '24px' }} />
                        <ShowroomEntryBasicInfoManagerMobile
                            onSubmit={submit}
                            onCancel={() => {
                                navigate(-1)
                            }}
                            isEditEntry
                            showroomEntry={currentEntry}
                        />
                    </Wrapper>
                </PageContainer>
            </Faded>
        </IpadAndMobileDisplay>
    )
}

export default EditShowroomEntryBasicInfoMobile
