import './styles.css'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { Field } from 'formik'
import React from 'react'
import { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface IInputFieldProps {
    name: string
    value?: string | number | undefined
    placeholder?: string
    fieldLabel: string
    handleChange?: (e: any) => any
    type?: any
    onBlur?: any
    onFocus?: any
    onChange?: any
    disabled?: boolean
    desktop?: boolean | string
    id?: string
    tabindex?: number | string
    formikprops?: any
    font_family?: string
    width?: string
    fieldId?: string
    fontSize?: string
    text_transform?: string
    dataCyId?: string
}

interface StandardInputProps {
    disabled?: boolean
    desktop?: boolean | string
    tabindex?: number | string
    font_family?: string
    width?: string
    fontSize?: string
    text_transform?: string
    dataCyId?: string
    $theme: ITheme
}

const StandardInput = styled(Field)<StandardInputProps>`
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    height: 48px;
    font-family: ${(props) => (props.font_family ? props.font_family : 'Lato')};
    border: none !important;
    border-radius: 5px;
    background-color: var(
        --base-overlays-neutral-alpha-100,
        rgba(26, 26, 26, 0.04)
    ) !important;
    color: var(--text-strong, #1a1a1a);
    cursor: ${(props) => props.disabled === true && 'not-allowed !important '};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: ${(props) => (props.is_narrow ? '8px' : '12px')};
    text-transform: ${(props) =>
        props.text_transform ? props.text_transform : 'none'};

    ::placeholder {
        font-family: Lato-Light;
        color: var(--text-default, #666666);
        text-transform: capitalize;
        font-size: 14px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px
            var(--base-overlays-neutral-alpha-100-full-color, #f6f6f6) inset !important;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: var(--text-strong, #1a1a1a) !important;
    }

    @media ${device.mobile} {
        font-size: 14px;
        padding-bottom: 10px;
        padding-top: 11px;
    }

    @media ${device.ipad} {
        font-size: 16px;
        padding-bottom: 10px;
    }

    @media ${device.desktop} {
        height: 35px;
        font-size: 14px;
        padding-bottom: 10px;
    }

    @media ${device.large_desktop} {
        height: 56px;
        font-size: 16px;
    }
`

const FieldLabel = styled.div<StandardInputProps>`
    z-index: 2;
    font-family: Lato;
    color: var(--text-darker, #616161);
    border: none;
    background-color: transparent !important;
    padding-bottom: 3px;
    text-transform: capitalize;

    @media ${device.desktop} {
        font-size: 12px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }

    @media ${device.ipad} {
        font-size: 16px;
        padding-top: 10px;
    }

    @media ${device.mobile} {
        font-size: 15px;
        padding-bottom: 5px;
    }
`

function InputFieldLandingPage({
    name,
    value,
    placeholder,
    fieldLabel,
    type,
    onBlur,
    onChange,
    id,
    disabled,
    tabindex,
    desktop,
    onFocus,
    formikprops,
    font_family,
    width,
    fieldId,
    fontSize,
    text_transform,
    dataCyId,
}: IInputFieldProps) {
    const { theme } = useThemes()
    return (
        <React.Fragment>
            <FieldLabel $theme={theme}>{fieldLabel}</FieldLabel>
            <StandardInput
                $theme={theme}
                id={fieldId ? fieldId : id}
                name={name}
                type={type ? type : 'text'}
                value={value && value}
                placeholder={placeholder}
                onChange={
                    onChange
                        ? onChange
                        : formikprops
                        ? formikprops.handleChange
                        : onChange
                }
                onBlur={
                    onBlur
                        ? onBlur
                        : formikprops
                        ? formikprops.handleBlur
                        : onBlur
                }
                desktop={desktop}
                disabled={disabled ? 'disabled' : ''}
                tabIndex={tabindex}
                font_family={font_family && font_family}
                width={width && width}
                fontSize={fontSize}
                text_transform={text_transform}
                data-attr={dataCyId ? dataCyId : ''}
            />
        </React.Fragment>
    )
}

export default InputFieldLandingPage
