import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import filterÍcon from '../../../public/assets/icons/icon-filter.svg'
import greySearch from '../../../public/assets/icons/grey_thin_search.svg'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import SortIcon from '../icons/components/sortIcon'
import ChevronDown from '../icons/components/chevronDown'

type IStyled = {
    $theme: ITheme
    $isactive?: boolean
    $isMobile?: boolean
    $minWidth?: string
    $hasTextLeftAligned?: boolean
    $fontSize?: string
    $isDisabled?: boolean
    $capitalize?: boolean
}

const Text = styled.div<IStyled>`
    padding-left: 5px;
    padding-right: 5px;
    text-align: ${(props) => (props.$hasTextLeftAligned ? 'left' : 'center')};
    width: 100%;
    box-sizing: border-box;
    border-right: ${(props) =>
        `1px solid ${colours[props.$theme].border_muted}`};
    ${(props) => props.$isMobile && 'font-size: 10px; font-family: Lato;'};
    ${(props) => props.$fontSize && `font-size: ${props.$fontSize};`};
    ${(props) => props.$hasTextLeftAligned && `padding-left: 10px;`};
    color: ${(props) =>
        props.$isDisabled
            ? colours[props.$theme].text_disabled
            : colours[props.$theme].text_strong};
    ${(props) => props.$capitalize && 'text-transform: capitalize;'};
`

const FilterButtonWrapper = styled.div<IStyled>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    padding-left: 5px;
    background-color: ${(props) =>
        props.$isactive
            ? colours[props.$theme].background_primary_soft
            : colours[props.$theme].background_neutral_soft};
    border: ${(props) => `1px solid ${colours[props.$theme].border_muted}`};
    border-radius: 6px;
    height: ${(props) => (props.$isMobile ? '27px' : '34px')};
    min-width: ${(props) =>
        props.$isMobile ? '90px' : props.$minWidth ? props.$minWidth : '100px'};
    cursor: pointer;
`

const FilterIconWrapper = styled(motion.div)`
    padding-left: 2px;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
`

type Props = {
    onClick: any
    text?: string
    dataCyId?: string | undefined
    isactive?: boolean
    isMobile?: boolean
    minWidth?: string
    hasSearchIcon?: boolean
    hasTextLeftAligned?: boolean
    fontSize?: string
    isDisabled?: boolean
    hasChevron?: boolean
    isSort?: boolean
    capitalize?: boolean
}

const FilterButton: React.FunctionComponent<Props> = (props: Props) => {
    let {
        onClick,
        text,
        dataCyId,
        isMobile,
        minWidth,
        hasSearchIcon,
        hasChevron,
        hasTextLeftAligned,
        fontSize,
        isDisabled,
        isSort,
        capitalize,
    } = props

    const { theme } = useThemes()

    return (
        <FilterButtonWrapper
            $theme={theme}
            data-attr={dataCyId}
            onClick={!isDisabled ? onClick : (e) => e.preventDefault()}
            $isactive={props.isactive}
            $isMobile={isMobile}
            $minWidth={minWidth}
        >
            <Text
                $theme={theme}
                $isMobile={isMobile}
                $hasTextLeftAligned={hasTextLeftAligned}
                $fontSize={fontSize}
                $isDisabled={isDisabled}
                $capitalize={capitalize}
            >
                {text}
            </Text>
            <FilterIconWrapper whileTap={{ scale: 1.5 }}>
                {isSort ? (
                    <div
                        style={{
                            paddingTop: 4,
                            paddingLeft: 6,
                            paddingRight: 4,
                        }}
                    >
                        <SortIcon color={colours[theme].text_muted} />
                    </div>
                ) : hasChevron ? (
                    <div
                        style={{
                            paddingLeft: 6,
                            paddingRight: 4,
                        }}
                    >
                        <ChevronDown color={colours[theme].text_muted} />
                    </div>
                ) : (
                    <img
                        alt="filter icon"
                        src={hasSearchIcon ? greySearch : filterÍcon}
                        style={{
                            marginRight: hasChevron ? '7px' : '5px',
                            marginLeft: hasChevron ? '9px' : '6px',
                            transform: hasChevron
                                ? 'rotate(90deg)'
                                : 'rotate(0deg)',
                        }}
                    />
                )}
            </FilterIconWrapper>
        </FilterButtonWrapper>
    )
}

export default FilterButton
