import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/insurance/1.png'
import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

const CustomMobileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 40px;
    transform: translateY(-64px);

    @media (min-width: 560px) {
        transform: translateY(-120px);
    }
`

export default function JourneyInsuranceJoinStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section['insurance']
                                        .primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section['insurance']
                                        .primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        Who are Custodian?
                                    </JourneyStepTitle>
                                </JourneyColumnContent>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <JourneyText $theme={theme}>
                                        Custodian is where everything ownership
                                        is our passion! We're enthusiasts just
                                        like you, dedicated to making car
                                        ownership easy and rewarding.
                                    </JourneyText>
                                    <JourneyText $theme={theme}>
                                        Alongside all our free tooling our first
                                        major goal is to provide the best
                                        specialist insurance possible. We
                                        believe that people who look after their
                                        cars, themselves, and others deserve
                                        better cover for less. People who are
                                        true custodians of their belongings
                                        shouldn’t have to foot the bill for
                                        those who are not.
                                    </JourneyText>
                                    <JourneyText $theme={theme}>
                                        Join us on our journey and together we
                                        can protect enthusiast ownership for
                                        generations to come!
                                    </JourneyText>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section.insurance
                                            .primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section.insurance
                                            .primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <FadedSlower>
                                <img
                                    src={image}
                                    // placeholder={grey_placeholder}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '56vw',
                                        maxHeight: '90vh',
                                    }}
                                />
                            </FadedSlower>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingBottom: 200,
                            paddingTop: 48,
                            overflow: 'hidden',
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent
                                    style={{
                                        gap: 4,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                    }}
                                >
                                    <JourneyStepTitle
                                        $theme={theme}
                                        style={{ paddingRight: '40px' }}
                                    >
                                        Who are Custodian?
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        Learn about Custodian Insurance
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                    }}
                                >
                                    Custodian is where everything ownership is
                                    our passion! We're enthusiasts just like
                                    you, dedicated to making car ownership easy
                                    and rewarding.
                                </JourneyText>

                                <Faded width={'100%'}>
                                    <img
                                        src={image}
                                        // placeholder={grey_placeholder}
                                        style={{
                                            objectFit: 'contain',
                                            maxWidth: '100vw',
                                            maxHeight: '80vh',
                                            objectPosition: 'center',
                                        }}
                                    />
                                </Faded>
                            </JourneyColumnContent>
                            <CustomMobileWrapper
                                style={{
                                    gap: 40,
                                }}
                            >
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                    }}
                                >
                                    Alongside all our free tooling our first
                                    major goal is to provide the best specialist
                                    insurance possible. We believe that people
                                    who look after their cars, themselves, and
                                    others deserve better cover for less. People
                                    who are true custodians of their belongings
                                    shouldn’t have to foot the bill for those
                                    who are not.
                                </JourneyText>
                                <JourneyColumnContent
                                    style={{
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                    }}
                                >
                                    <JourneyColumnContent
                                        style={{
                                            padding: 16,
                                            borderRadius: 8,
                                            backgroundColor:
                                                journey_colours[theme].section
                                                    .insurance.primary_100,
                                        }}
                                    >
                                        <JourneyText
                                            $theme={theme}
                                            style={{
                                                color: journey_colours[theme]
                                                    .section.insurance
                                                    .primary_700,
                                            }}
                                        >
                                            Join us on our journey and together
                                            we can protect enthusiast ownership
                                            for generations to come!
                                        </JourneyText>
                                    </JourneyColumnContent>
                                </JourneyColumnContent>
                            </CustomMobileWrapper>
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="insurance"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
