import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'

type StyledProps = {
    disabled?: boolean
    isActive: boolean
    fontSize?: string
}

const MainButton = styled.button<StyledProps>`
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: ${(props) => (props.isActive ? 'Lato-Bold' : 'Lato')};
    height: 45px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 15px;
    text-transform: capitalize;
    border: none;
    color: ${(props) =>
        props.isActive
            ? 'var(--primary, #5ec3ca)'
            : 'var(--text-darker, #616161)'};
    font-size: ${(props) => props.fontSize ?? '12px'};
    text-transform: uppercase;
    transition: all 200ms;
    background-color: var(--bg-color, #fff);
    :hover {
        transition: all 200ms;
        background-color: var(--off-bg-color, #fafafa);
    }
    cursor: ${(props) => (props.disabled === true ? 'not-allowed' : 'pointer')};

    /* @media ${device.mobile_and_ipad} {
        font-size: 12px;
        height: 43px;
        width: 125px;
        border-radius: 16px;
    } */
    transition: all 500ms;
`

const BtnWrapper = styled(motion.div)<{ isActive?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
`

const BottomLine = styled.div`
    position: relative;
    height: 3px;
    width: 100%;
    box-sizing: border-box;
`

const BottomLineGreen = styled.div<StyledProps>`
    position: absolute;
    left: 0;
    top: 0;
    height: 3px;
    width: 100%;
    box-sizing: border-box;
    transform: ${(props) =>
        props.isActive === true ? 'scale(1)' : 'scale(0)'};
    transform-origin: center;
    background-color: ${(props) =>
        props.isActive === true ? 'var(--primary, #5ec3ca)' : 'none'};
    transition: all 500ms;
`
const BottomLineGrey = styled.div<StyledProps>`
    left: 0;
    top: 0;
    height: 3px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--border_muted_subtle);
`

const Row = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
`

type Props = {
    dataCyId?: string | undefined
    activeID: string
    btnIdsList: string[]
    btnsData: any
    fontSize?: string
}

const SwitchTabMenuMobile: React.FC<Props> = ({
    dataCyId,
    activeID,
    btnIdsList,
    btnsData,
    fontSize,
}) => {
    const [currentActiveID, setCurrentActiveID] = React.useState(activeID)

    return (
        <Row>
            {btnIdsList.map((id: string, index: number) => {
                return (
                    <React.Fragment key={`${id}_${index}_mobile`}>
                        <BtnWrapper
                            whileTap={{
                                scale:
                                    btnsData[id].isDisabled === true ? 1 : 1.05,
                            }}
                            isActive={currentActiveID === id}
                        >
                            <MainButton
                                onClick={() => {
                                    btnsData[id].action(id)
                                    setCurrentActiveID(id)
                                }}
                                disabled={
                                    btnsData[id].isDisabled === true
                                        ? true
                                        : false
                                }
                                data-cy={dataCyId ? dataCyId : undefined}
                                isActive={currentActiveID === id}
                                fontSize={fontSize}
                            >
                                <div>{btnsData[id].txt}</div>
                            </MainButton>
                            <BottomLine>
                                <BottomLineGrey
                                    isActive={currentActiveID === id}
                                />
                                <BottomLineGreen
                                    isActive={currentActiveID === id}
                                />
                            </BottomLine>
                        </BtnWrapper>
                    </React.Fragment>
                )
            })}
        </Row>
    )
}

export default SwitchTabMenuMobile
