import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { showroomActions } from '../reducer'
import { IFilterValidationErrorObj, IShowroomFiltersState } from '../types'
import {
    ISearchShowroom_API_car_item,
    ISearchShowroom_API_req_args,
    ISearchShowroom_API_res,
    ISearchShowroom_API_res_filter,
} from '../../services/showroom/types'
import {
    convertShowRoomSearchAPIOptionsToState,
    I_convertShowRoomSearchAPIOptionsToState_res,
} from '../../conversions/showroom/searchFilters'
import { ConvertSearchFiltersToAPIRes } from '../../conversions/showroom/searchFiltersToAPIRes'
import { generateShowroomYearsDropdown } from '../../conversions/dropdowns/showroomYears'
import { default_showroomFilters } from '../data'
import { IRootState } from '../../store'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'

let filters_select = (state: IRootState): IShowroomFiltersState =>
    state.showroom.filters

let filters_edit_select = (state: IRootState): IShowroomFiltersState =>
    state.showroom.editingFilters

let resultsFoundNumber_select = (state: IRootState): number | undefined =>
    state.showroom.sale_results_total_count

let pagination_select_sold = (state: IRootState): number =>
    state.showroom.currentPageNumber_sold

let pagination_select_sale = (state: IRootState): number =>
    state.showroom.currentPageNumber_sale

let limit_select = (state: IRootState): number =>
    state.showroom.itemsNumberPerPage

let filters_validation_error = (
    state: IRootState
): IFilterValidationErrorObj | undefined => state.showroom.filterValidationError

let active_sorting = (
    state: IRootState
):
    | 'OLDEST'
    | 'MOST_RECENT'
    | 'PRICE_ASCENDING'
    | 'PRICE_DESCENDING'
    | 'MANUFACTURE_DATE_ASCENDING'
    | 'MANUFACTURE_DATE_DESCENDING'
    | undefined => state.showroom.activeSort

export type ISearchSagaProps = {
    states?: 'PUBLISHED' | 'SOLD'
    isSimilarSearch?: boolean
    only_for_filter_results?: boolean
    only_for_filter_makes_results?: boolean
    freeze_total_count?: boolean
}

let total_sold_count = (state: IRootState): number | undefined =>
    state.showroom.sold_results_total_count

let total_sale_count = (state: IRootState): number | undefined =>
    state.showroom.sale_results_total_count

export function* SearchShowroomSubSaga(props?: ISearchSagaProps) {
    let only_for_filters =
        props?.only_for_filter_results === true ? true : false
    let currentFilters: IShowroomFiltersState = yield select(filters_select)
    //filters_edit_select

    let editingFilters: IShowroomFiltersState = yield select(
        filters_edit_select
    )
    let page_n: number =
        props?.states === 'SOLD'
            ? yield select(pagination_select_sold)
            : yield select(pagination_select_sale)

    let offset_limit: number = yield select(limit_select)
    let sort:
        | 'OLDEST'
        | 'MOST_RECENT'
        | 'PRICE_ASCENDING'
        | 'PRICE_DESCENDING'
        | 'MANUFACTURE_DATE_ASCENDING'
        | 'MANUFACTURE_DATE_DESCENDING'
        | undefined = yield select(active_sorting)

    let current_total_sale_count: number | undefined = yield select(
        total_sale_count
    )
    let current_total_sold_count: number | undefined = yield select(
        total_sold_count
    )

    let only_for_filter_makes_results =
        props?.only_for_filter_makes_results === true

    try {
        let filtersToSearchFor = only_for_filter_makes_results
            ? default_showroomFilters
            : only_for_filters === true
            ? editingFilters
            : currentFilters

        let p: ISearchShowroom_API_req_args =
            ConvertSearchFiltersToAPIRes(filtersToSearchFor)

        if (sort) {
            p.sorting = sort
        }

        if (props?.states) {
            p.states = props.states
        }

        let res: ISearchShowroom_API_res = yield call(
            api.showroom.searchShowroom,
            {
                data: p,
                offset: only_for_filters ? 0 : page_n,
                limit: only_for_filters ? 0 : offset_limit,
            }
        )

        let filtersArr: ISearchShowroom_API_res_filter[] = res.filters
            ? res.filters
            : []

        let conv: I_convertShowRoomSearchAPIOptionsToState_res =
            convertShowRoomSearchAPIOptionsToState(filtersArr)

        yield put(
            showroomActions.setShowroomMakesList({
                options: conv.make,
                editingOnly: only_for_filters || only_for_filter_makes_results,
            })
        )

        yield put(
            showroomActions.setShowroomModelsList({
                options: conv.model,
                editingOnly: only_for_filters || only_for_filter_makes_results,
            })
        )

        if (!only_for_filter_makes_results) {
            yield put(
                showroomActions.setShowroomBodyTypesList({
                    options: conv.body_type,
                    editingOnly: only_for_filters,
                })
            )
            yield put(
                showroomActions.setShowroomColoursList({
                    options: conv.exterior_color,
                    editingOnly: only_for_filters,
                })
            )

            let convYearsDropdown = generateShowroomYearsDropdown(
                conv.year?.min_backend,
                conv.year?.max_backend
            )
            yield put(
                showroomActions.setShowroomYearsRadioOptions({
                    options: convYearsDropdown,
                    editingOnly: only_for_filters,
                })
            )
            yield put(
                showroomActions.setShowroomMinMaxThresholds({
                    year: conv.year,
                    mileage: conv.mileage,
                    price: conv.price,
                })
            )

            if (!only_for_filters) {
                let cars: ISearchShowroom_API_car_item[] = res.cars
                    ? res.cars
                    : []

                if (props?.isSimilarSearch) {
                    yield put(
                        showroomActions.searchShowroom_setCars({
                            cars: cars,
                            resultsFoundNumber: 0,
                            similarSoldCarsNumber: res.count,
                            type: props.states === 'SOLD' ? 'sold' : 'sale',
                        })
                    )
                } else {
                    let total_count = (): number => {
                        if (
                            props &&
                            props.freeze_total_count === true &&
                            props.states === 'SOLD'
                        ) {
                            return current_total_sold_count !== undefined &&
                                current_total_sold_count !== 0
                                ? current_total_sold_count
                                : res.count
                        } else if (props && props.freeze_total_count === true) {
                            return current_total_sale_count !== undefined &&
                                current_total_sale_count !== 0
                                ? current_total_sale_count
                                : res.count
                        } else return res.count
                    }

                    yield put(
                        showroomActions.searchShowroom_setCars({
                            cars: cars,
                            resultsFoundNumber: total_count(),
                            similarSoldCarsNumber: undefined,
                            type:
                                props && props.states === 'SOLD'
                                    ? 'sold'
                                    : 'sale',
                        })
                    )
                }
            }
        }

        yield put(showroomActions.searchShowroomSuccess())
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: SearchShowroomSaga,
                payload: props,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(showroomActions.searchShowroomError(customError))
        }
    }
}

export function* SearchShowroomSaga(props?: ISearchSagaProps): any {
    // cleanup filters that have errors

    let filterValidationError: IFilterValidationErrorObj = yield select(
        filters_validation_error
    )

    if (filterValidationError) {
        for (const id in filterValidationError) {
            yield put(
                showroomActions.removeFilterRequest({
                    id: id as keyof IFilterValidationErrorObj,
                })
            )
        }
    }
    yield call(SearchShowroomSubSaga, props)
    let resultsFoundNumber = yield select(resultsFoundNumber_select)

    if (
        props?.states !== 'SOLD' &&
        resultsFoundNumber === 0 &&
        props?.only_for_filter_results !== true &&
        props?.only_for_filter_makes_results !== true
    ) {
        yield call(SearchShowroomSubSaga, {
            ...props,
            states: 'SOLD',
            isSimilarSearch: true,
            only_for_filter_results: false,
        })
    }
}

function* watcherSearchShowroom() {
    while (true) {
        let { payload } = yield take(showroomActions.searchShowroomRequest)

        yield call(SearchShowroomSaga, payload)
    }
}

const search_showroom: any[] = [fork(watcherSearchShowroom)]

export default search_showroom
