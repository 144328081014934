import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Button from '../Button/ButtonAtom'
import Expander from '../expander/expander'
import NoDataRectangle from '../noData/noDataRectangle'
import './styles.css'
import parse from 'html-react-parser'

const Reveal = styled.div<IStyle>`
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    height: 280px;
    top: 1;
    z-index: 1;

    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 22.3%,
        #ffffff 55.53%
    );
    opacity: ${(props) => (props.isOpen ? '0' : '1')};

    transition: all 300ms;
`
const Wrapper = styled.div<IStyle>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-self: start;
    justify-self: start;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-self: start;
    justify-self: start;
    background-color: red;
`

const ReadMore = styled.div<IStyle>`
    z-index: 2;
    padding-top: ${(props) => (props.isOpen ? '20px' : '0px')};

    @media ${device.beyond_ipad_mobile} {
        padding-left: 5px;
    }
`

interface Props {
    description: string
    onClick?: any
    readOnlyMode?: boolean
}

interface IStyle {
    isOpen: boolean
}

const DescriptionDesktop: React.FC<Props> = ({ description, readOnlyMode }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false)
    // const [showBoxInfo, setShowBoxInfo] = React.useState<boolean>(false)

    const toggleReveal = () => {
        setIsOpen(!isOpen)
    }

    // const setTrueBoxInfoToggle = () => setShowBoxInfo(true)

    // const setFalseBoxInfoToggle = () => setShowBoxInfo(false)

    const isDescriptionShort: boolean = description.length < 1822

    return (
        <Container>
            {/* IF NO DESCRIPTION */}
            {(description.length < 1 || description === '<p><br></p>') &&
                // FOR USER'S OWN CAR (NO READ ONLY) WE DISPLAY ADD BTN
                (!readOnlyMode ? null : (
                    // <GoToBoxDesktop
                    //     onClick={onClick && onClick}
                    //     onMouseEnter={setTrueBoxInfoToggle}
                    //     onMouseLeave={setFalseBoxInfoToggle}
                    // >
                    //     {showBoxInfo === false && <Icon icon="add_plus" />}
                    //     {showBoxInfo === true && (
                    //         <AddBoxTextInfo>
                    //             Add car's description
                    //         </AddBoxTextInfo>
                    //     )}
                    // </GoToBoxDesktop>
                    // FOR EXTERNAL CAR (READ ONLY MODE) WE DISPLAY NO DATA SQUARE
                    <NoDataRectangle text="no description" height="300px" />
                ))}

            {description.length > 1 && (
                <Wrapper isOpen={isDescriptionShort ? true : isOpen}>
                    <Reveal isOpen={isDescriptionShort ? true : isOpen} />
                    <Expander
                        height={
                            isDescriptionShort ? 'auto' : isOpen ? 'auto' : 200
                        }
                    >
                        {parse(description)}
                    </Expander>

                    {!isDescriptionShort && (
                        <ReadMore isOpen={isOpen}>
                            <Button
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    toggleReveal()
                                }}
                                theme="naked-text"
                            >
                                {isOpen ? 'read less' : 'read more'}
                            </Button>
                        </ReadMore>
                    )}
                </Wrapper>
            )}
        </Container>
    )
}

export default DescriptionDesktop
