import { call, fork, put, take } from 'redux-saga/effects'

import { api } from '../../../services'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../reducer'
import { IDropdownItem } from 'entityModels'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'

function* setBodyTypeDropdownDataApi() {
    try {
        const dataReceived: string[] =
            yield api.dropdownsData.get_car_body_types_dropdown_api()

        let convertedData: IDropdownItem[] = dataReceived.map(
            (item: string) => {
                return {
                    uid: `${item}`,
                    name: item !== 'SUV' ? item : 'SUV / 4x4',
                }
            }
        )
        yield put(dropDownActions.setBodyTypeDropdownDataSuccess(convertedData))
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: setBodyTypeDropdownDataApi,
            }
            yield call(unauthHandlerSaga, p)
        } else if (error === Error) {
            let customErrorData: ICustomErrorData = {
                custom_message: `Looks like your internet has stoped working`,
                custom_message_detail: 'Check your connection and refresh',
                custom_user_action_text: 'Refresh page',
            }

            let customError: IReduxError = ConvertToReduxError(
                { status: '500' },
                customErrorData
            )
            yield put(dropDownActions.setBodyTypeDropdownDataError(customError))
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)

            yield put(dropDownActions.setBodyTypeDropdownDataError(typedError))
        }
    }
}

function* watchersetBodyTypeDropdownData() {
    while (true) {
        yield take(dropDownActions.setBodyTypeDropdownDataRequest)
        yield call(setBodyTypeDropdownDataApi)
    }
}

const set_body_type_dropdownData: any[] = [fork(watchersetBodyTypeDropdownData)]
export default set_body_type_dropdownData
