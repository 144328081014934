import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import { IGalleryImage } from '../../../redux/entities/galleries/types'
import useThemes from '../../../providers/theme/hooks'

type IImgProps = {
    isthingloading: boolean
}

const ImageStyle = styled.div<IImgProps>`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    opacity: ${(props) => (props.isthingloading === false ? '1' : '0')};
    transition: opacity 400ms;
`

const ImageStyleWrapper = styled.div<IImgProps>`
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 3px;

    @media ${device.large_desktop} {
        height: 700px;
    }

    @media ${device.desktop} {
        height: 600px;
    }

    @media ${device.smallest_laptops} {
        height: 530px;
    }
`

export interface IImageProps {
    img?: IGalleryImage | null | undefined
    placeholder?: string
    children: React.ReactNode
}

const GarageCarBanner: React.FC<IImageProps> = ({
    img,
    placeholder,
    children,
}) => {
    const [isLoading, setIsLoading] = useState(true)
    const { theme } = useThemes()

    const defaultPlaceholder = randomCarSvgPlaceholderFunc(undefined, theme)

    const imageSrc = img?.url || placeholder || defaultPlaceholder

    useEffect(() => {
        const image = new Image()
        image.src = imageSrc
        image.onload = () => setIsLoading(false)
        image.onerror = () => setIsLoading(false) // Ensure state updates on error.
    }, [img])

    return (
        <ImageStyleWrapper isthingloading={isLoading}>
            <SkeletonAbsolute isthingloading={isLoading} />

            <ImageStyle
                role="img"
                bg-srcset={img?.medium_srcset}
                aria-label="car overview banner"
                style={{
                    backgroundImage: `url(${imageSrc})`,
                }}
                isthingloading={isLoading}
            >
                {children}
            </ImageStyle>
        </ImageStyleWrapper>
    )
}

export default GarageCarBanner
