import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import styled from 'styled-components'
import { countEntryFeaturedImages } from '../../../../helpers/numbers/countFeaturedImages'
import ReturnNav from '../../../atoms/header/returnNav'
import EntryGallerySlide from '../../../molecules/slideshow/entryGallerySlide'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import FullPageWrapper from '../../../templates/displays/pageWrappers/FullPagePageWrapper'
import SlidingUpOnScrollBanner from '../../../atoms/header/slidingUpOnScrollBanner'
import { device } from '../../../templates/displays/devices'
import ModalDisplayGalleryImageDesktop from '../../displays/pageWrappers/modalDisplayGalleryImageDesktop'
import GalleryImageCarousel from '../../../organisms/galleryCarousel'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { useAppSelector } from '../../../../redux/store/hooks'
import { ICar, IExternalCar } from '../../../../redux/entities/cars/types'
import { ITimelineItem } from '../../../../redux/timeline/types'
import { IUser } from '../../../../redux/user/types'

interface Props {
    sharedCar?: IExternalCar
    prevOwned?: ICar
    userLoggedIn: IUser | null
}

const AdjustedPaddingTop = styled.div`
    @media ${device.ipad} {
        padding-top: 60px;
    }
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

const ReadOnlyCarHistoryFileEntryGalleryImage: React.FC<Props> = ({
    sharedCar,
    prevOwned,
    userLoggedIn,
}) => {
    const [image_index, setImage_index] = useState<number | undefined>(
        undefined
    )

    const { carid, entryid } = useParams<{ carid: string; entryid: string }>()
    const location = useLocation()
    const navigate = useNavigate()

    const timelineData = useAppSelector(
        (state) => state.timeline.timeline_data_normalised_by_car_id
    )
    const imagesData = useAppSelector(
        (state) => state.entities.galleriesData.images
    )

    const isGalleryLoading = useAppSelector(
        (state) => state.entities.galleriesData.loading
    )

    const setUpInitialIndex = () => {
        const params = new URLSearchParams(location.search)
        const image_id_param = params.get('id')

        const all_entries =
            timelineData &&
            carid &&
            timelineData[carid] &&
            timelineData[carid].entries

        const indexOFActiveEntry =
            all_entries &&
            all_entries.findIndex(
                (entry: ITimelineItem) => entry.id === entryid
            )

        const activeEntry =
            all_entries &&
            indexOFActiveEntry !== undefined &&
            indexOFActiveEntry !== null &&
            all_entries[+indexOFActiveEntry]
        if (activeEntry && activeEntry.gallery_image_ids && image_id_param) {
            const numberr =
                activeEntry.gallery_image_ids.indexOf(image_id_param)
            setImage_index(numberr)
        }
    }

    useEffect(() => {
        setUpInitialIndex()
        disableBodyScroll(document.body)
        return () => {
            enableBodyScroll(document.body)
        }
    }, [userLoggedIn, timelineData])

    const generateImageNavigationUrl = (next: string) => {
        const params = new URLSearchParams(location.search)
        const image_id_param = params.get('id')

        if (image_id_param) {
            const all_entries =
                timelineData &&
                carid &&
                timelineData[carid] &&
                timelineData[carid].entries
            const indexOFActiveEntry =
                all_entries &&
                all_entries.findIndex(
                    (entry: ITimelineItem) => entry.id === entryid
                )
            const activeEntry =
                all_entries &&
                indexOFActiveEntry !== undefined &&
                indexOFActiveEntry !== null &&
                all_entries[+indexOFActiveEntry]
            const ids_list = activeEntry && activeEntry.gallery_image_ids

            if (ids_list) {
                const index_of_id = ids_list.indexOf(image_id_param)

                if (next === 'next') {
                    const index_of_next_image = index_of_id + 1
                    if (index_of_next_image === -1) return '/none'
                    const id_of_next_image = ids_list[index_of_next_image]
                    return id_of_next_image !== undefined
                        ? `${location.pathname}?id=${id_of_next_image}`
                        : '/none'
                } else {
                    const index_of_previous_image = index_of_id - 1
                    if (index_of_previous_image === -1) return '/none'
                    const id_of_previous_image =
                        ids_list[index_of_previous_image]
                    return `${location.pathname}?id=${id_of_previous_image}`
                }
            }
        }
        return '/none'
    }

    const params = new URLSearchParams(location.search)
    const all_entries =
        timelineData &&
        carid &&
        timelineData[carid] &&
        timelineData[carid].entries

    const indexOFActiveEntry =
        all_entries &&
        all_entries?.findIndex((entry: ITimelineItem) => entry.id === entryid)
    const activeEntry =
        all_entries &&
        indexOFActiveEntry !== undefined &&
        indexOFActiveEntry !== null &&
        all_entries[+indexOFActiveEntry]

    const image_id_p = params.get('id')
    const prev_url = generateImageNavigationUrl('previous')
    const next_url = generateImageNavigationUrl('next')

    const current = sharedCar ?? prevOwned
    const windowSize = window.innerWidth

    const current_image_id =
        windowSize < 800 &&
        image_index !== undefined &&
        activeEntry &&
        activeEntry.gallery_image_ids
            ? activeEntry.gallery_image_ids[image_index]
            : image_id_p

    return (
        <>
            <DesktopDisplayOnly>
                <FullPageWrapper>
                    <ModalDisplayGalleryImageDesktop
                        toggle={() => {
                            navigate(location.pathname)
                        }}
                        isOpen={true}
                    >
                        {imagesData && (
                            <EntryGallerySlide
                                closeGallery={() => {
                                    navigate(location.pathname)
                                }}
                                currentImageData={
                                    imagesData && imagesData[params.get('id')!]
                                }
                                carid={`${carid}`}
                                entryid={`${entryid}`}
                                imagesData={imagesData}
                                deleteImage={() => {}}
                                isSomethingLoading={isGalleryLoading}
                                prevUrl={prev_url}
                                nextUrl={next_url}
                                onClickEdit={() => {}}
                                updateEntryGalleryImage={() => {}}
                                featuredImagesCount={
                                    activeEntry && imagesData
                                        ? countEntryFeaturedImages(
                                              activeEntry,
                                              imagesData
                                          )
                                        : 0
                                }
                                isExternalCar
                            />
                        )}
                    </ModalDisplayGalleryImageDesktop>
                </FullPageWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <div
                    style={{
                        display: 'flex',
                        position: 'relative',
                        background: 'var(--bg-color, #fafafa)',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        height: '100vh',
                    }}
                >
                    <Link
                        to={{
                            pathname: location.pathname,
                        }}
                    >
                        <ReturnNav />
                    </Link>
                    {current && (
                        <SlidingUpOnScrollBanner
                            text={
                                current.title.length > 32
                                    ? `${current.title.slice(0, 34)}...`
                                    : current.title
                            }
                            customStyle={{
                                transform: 'translateY(50px)',
                            }}
                        />
                    )}
                    <AdjustedPaddingTop />

                    {activeEntry &&
                    current_image_id &&
                    activeEntry.gallery_image_ids &&
                    imagesData &&
                    imagesData[current_image_id] &&
                    image_index !== undefined ? (
                        <GalleryImageCarousel
                            indexOfInitialImage={image_index}
                            image_data={imagesData}
                            images_ids={activeEntry.gallery_image_ids}
                            synchroniseActiveIndex={(n: number) => {
                                setImage_index(n)
                            }}
                            openActionsSheet={() => {}}
                            cover_id={''}
                            readOnlyMode={true}
                        />
                    ) : (
                        <SkeletonAbsolute isthingloading={true} />
                    )}
                </div>
            </IpadAndMobileDisplay>
        </>
    )
}

export default ReadOnlyCarHistoryFileEntryGalleryImage
