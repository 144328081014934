import { ExclamationCircleFilled } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import colours, {
    insurance_colours,
    ITheme,
} from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { insuranceActions } from '../../../../../redux/insuranceQuoteApplication/reducer'
import { IRootState } from '../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { IInsuranceApplicationSectionId } from '../../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { ITopWizardCheckActions } from '../../../../pages/insurance_quote/wizard'
import Faded from '../../../animated/faded'
import { device } from '../../../displays/devices'
import { AtdConfWarningBox } from '../topBars/insuranceTopBarWithProgressionRate'
import InsuranceTopBarIcon from './icons'
import { sections_insurance_wizard } from './topBarDesktop'

type Props = {
    sectionID: IInsuranceApplicationSectionId | 'submit' | string
    topWizardCheckActions: ITopWizardCheckActions | null
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--bg-color, #fff);
    height: 72px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 101;

    display: flex;

    border-bottom: 1px solid var(--border-muted, #e5e5e5);

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }

    @media (max-width: 400px) {
        height: 62px;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 48px;
    align-items: center;
    justify-content: center;
    position: absolute;

    @media (max-width: 400px) {
        height: 40px;
    }
`

type IStyle = {
    $theme: ITheme
    $isActive: boolean
    $isPassed?: boolean
    $sectionId: IInsuranceApplicationSectionId
}

const Circle = styled.button<IStyle>`
    position: relative;
    cursor: pointer;
    transition: all 200ms;
    position: relative;
    display: flex;
    height: 40px;
    border-radius: 100px;

    background: ${(props) =>
        props.$isActive
            ? insurance_colours[props.$theme].sections[props.$sectionId]
                  .primary_08
            : props.$isPassed
            ? colours[props.$theme].background_neutral_subtle
            : colours[props.$theme].background_default};

    :hover {
        background: ${(props) =>
            insurance_colours[props.$theme].sections[props.$sectionId]
                .primary_04};

        border: ${(props) =>
            `1px solid ${
                insurance_colours[props.$theme].sections[props.$sectionId]
                    .primary_30
            }`};
    }

    border: ${(props) =>
        props.$isActive === true
            ? `1px solid ${
                  insurance_colours[props.$theme].sections[props.$sectionId]
                      .primary_60
              }`
            : `1px solid ${colours[props.$theme].border_muted}`};

    height: 48px;
    padding: ${(props) => (props.$isActive === true ? `0px` : `4px 16px`)};
    width: ${(props) => (props.$isActive === true ? `101px` : `100px`)};
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: ${(props) => (props.$isActive === true ? `2` : `1`)};

    @media (max-width: 400px) {
        width: ${(props) => (props.$isActive === true ? `91px` : `90px`)};
        height: 38px;
    }

    @media (min-width: 600px) {
        width: ${(props) => (props.$isActive === true ? `141px` : `140px`)};
    }
`

const IconWrapper = styled.div<IStyle>`
    padding: ${(props) =>
        props.$isActive === true
            ? '10px 16px'
            : props.$isPassed === true
            ? `8px 28px 8px 12px`
            : `8px 25px 8px 48px`};
    /* position: absolute;
    left: ${(props) => (props.$isPassed === true ? '18px' : 'auto')};
    right: ${(props) => (props.$isPassed !== true ? '18px' : 'auto')}; */
`

const InsuranceApplicationWizardTopBarMobile = (props: Props) => {
    let { sectionID } = props

    const application = useAppSelector((state: IRootState) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    const { theme } = useThemes()

    let vehicles_to_insure_has_passed =
        sectionID === 'insurance_details' ? false : true

    let everyday_vehicles_has_passed =
        sectionID === 'main_policy_holder' || sectionID === 'drivers'
            ? true
            : false

    let main_policy_holder_has_passed = sectionID === 'drivers' ? true : false

    const navigate = useNavigate()

    const [showGoBackConfURL, toggleGoBackConfURL] = useState<string | null>(
        null
    )

    let checkClick = (url: string) => {
        let hasChange: boolean = false

        for (let i = 0; i < sections_insurance_wizard.length; i++) {
            if (
                props.topWizardCheckActions &&
                sectionID === sections_insurance_wizard[i] &&
                props.topWizardCheckActions.has_changes === true
            ) {
                hasChange = true
                return toggleGoBackConfURL(url)
            }
        }
        if (!hasChange) {
            toggleGoBackConfURL(null)
            return navigate(url, { replace: true })
        }
    }
    const dispatch = useAppDispatch()

    const stayOnPage = () => {
        // if (showGoBackConfURL) {
        //     props.topWizardCheckActions && props.topWizardCheckActions.onSave()
        //     if (props.topWizardCheckActions) {
        //         props.setTopWizardCheckActions({
        //             ...props.topWizardCheckActions,
        //             has_changes: false,
        //         })
        //     }
        //     toggleGoBackConfURL(null)
        //     history.replace(showGoBackConfURL)
        //     return
        // }
        toggleGoBackConfURL(null)
    }

    const destroyChanges = () => {
        if (showGoBackConfURL) {
            dispatch(insuranceActions.reset_draft_insurance_application())

            toggleGoBackConfURL(null)
            if (props.topWizardCheckActions) {
                props.setTopWizardCheckActions({
                    ...props.topWizardCheckActions,
                    has_changes: false,
                })
            }
            navigate(showGoBackConfURL, { replace: true })
            return
        }
    }

    let PopUp = () => {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 50,
                }}
            >
                <Popconfirm
                    title={
                        <AtdConfWarningBox
                            title="You have unsaved changes."
                            theme={theme}
                        />
                    }
                    open={true}
                    onConfirm={stayOnPage}
                    onCancel={destroyChanges}
                    okText="Stay on this pgae"
                    cancelText="Discard changes"
                    okButtonProps={{
                        style: {
                            backgroundColor: sectionID
                                ? // @ts-ignore
                                  insurance_colours[theme].sections[sectionID]
                                      .primary_100
                                : colours[theme].primary,
                            border: 'none',
                            borderRadius: 4,
                            height: 32,
                            paddingLeft: 12,
                            paddingRight: 12,
                        },
                    }}
                    cancelButtonProps={{
                        danger: true,
                        style: {
                            borderRadius: 4,
                            height: 32,
                            paddingLeft: 12,
                            paddingRight: 12,
                        },
                    }}
                    placement="bottomRight"
                    style={{
                        borderRadius: 4,
                    }}
                    icon={
                        <ExclamationCircleFilled
                            style={{ color: colours[theme].error }}
                        />
                    }
                ></Popconfirm>
            </div>
        )
    }

    return (
        <Container>
            <Wrapper>
                {/* insurance_details */}
                <>
                    <Circle
                        onClick={() => {
                            checkClick(
                                `/insurance/application/${application?.id}/wizard?section_id=insurance_details`
                            )
                        }}
                        $theme={theme}
                        $isPassed={
                            sectionID === 'insurance_details' ? false : true
                        }
                        $sectionId={'insurance_details'}
                        $isActive={
                            sectionID === 'insurance_details' ? true : false
                        }
                        style={{
                            zIndex: sectionID === 'insurance_details' ? 5 : 0,
                            transform:
                                sectionID === 'insurance_details'
                                    ? 'translateX(66px)'
                                    : 'translateX(65px)',
                        }}
                    >
                        <Faded>
                            <IconWrapper
                                $theme={theme}
                                $isPassed={
                                    sectionID === 'insurance_details'
                                        ? false
                                        : true
                                }
                                $sectionId={'insurance_details'}
                                $isActive={
                                    sectionID === 'insurance_details'
                                        ? true
                                        : false
                                }
                            >
                                <InsuranceTopBarIcon
                                    sectionID="insurance_details"
                                    isMuted={
                                        sectionID === 'insurance_details'
                                            ? false
                                            : true
                                    }
                                    size={24}
                                />
                            </IconWrapper>
                        </Faded>
                    </Circle>
                    {showGoBackConfURL !== null &&
                        sectionID === 'insurance_details' &&
                        props.topWizardCheckActions?.sectionID === sectionID &&
                        props.topWizardCheckActions.has_changes === true && (
                            <PopUp />
                        )}
                </>
                {/* vehicles_to_insure */}
                {/* // 24* x */}
                <>
                    <Circle
                        onClick={() => {
                            checkClick(
                                `/insurance/application/${application?.id}/wizard?section_id=vehicles_to_insure`
                            )
                        }}
                        $theme={theme}
                        $isPassed={vehicles_to_insure_has_passed}
                        $sectionId={'vehicles_to_insure'}
                        $isActive={
                            sectionID === 'vehicles_to_insure' ? true : false
                        }
                        style={{
                            transform:
                                sectionID === 'vehicles_to_insure'
                                    ? 'translateX(32px)'
                                    : 'translateX(38px)',
                            zIndex:
                                sectionID === 'vehicles_to_insure'
                                    ? 5
                                    : vehicles_to_insure_has_passed
                                    ? 0
                                    : 4,
                        }}
                    >
                        <Faded width={'auto'}>
                            <IconWrapper
                                $theme={theme}
                                $isPassed={vehicles_to_insure_has_passed}
                                $sectionId={'vehicles_to_insure'}
                                $isActive={
                                    sectionID === 'vehicles_to_insure'
                                        ? true
                                        : false
                                }
                            >
                                <InsuranceTopBarIcon
                                    sectionID="vehicles_to_insure"
                                    isMuted={
                                        sectionID === 'vehicles_to_insure'
                                            ? false
                                            : true
                                    }
                                    size={24}
                                />
                            </IconWrapper>
                        </Faded>
                    </Circle>
                    {showGoBackConfURL !== null &&
                        sectionID === 'vehicles_to_insure' &&
                        props.topWizardCheckActions?.sectionID === sectionID &&
                        props.topWizardCheckActions.has_changes === true && (
                            <PopUp />
                        )}
                </>
                {/* everyday_vehicles */}
                <>
                    <Circle
                        onClick={() => {
                            checkClick(
                                `/insurance/application/${application?.id}/wizard?section_id=everyday_vehicles`
                            )
                        }}
                        $theme={theme}
                        $isPassed={everyday_vehicles_has_passed}
                        $sectionId={'everyday_vehicles'}
                        $isActive={
                            sectionID === 'everyday_vehicles' ? true : false
                        }
                        style={{
                            transform:
                                sectionID === 'everyday_vehicles'
                                    ? 'translateX(0px)'
                                    : 'translateX(0px)',

                            zIndex:
                                sectionID === 'everyday_vehicles'
                                    ? 5
                                    : everyday_vehicles_has_passed
                                    ? 0
                                    : 3,
                        }}
                    >
                        <Faded width={'auto'}>
                            <IconWrapper
                                $theme={theme}
                                $isPassed={everyday_vehicles_has_passed}
                                $sectionId={'everyday_vehicles'}
                                $isActive={
                                    sectionID === 'everyday_vehicles'
                                        ? true
                                        : false
                                }
                            >
                                <InsuranceTopBarIcon
                                    sectionID="everyday_vehicles"
                                    isMuted={
                                        sectionID === 'everyday_vehicles'
                                            ? false
                                            : true
                                    }
                                    size={24}
                                />
                            </IconWrapper>
                        </Faded>
                    </Circle>
                    {showGoBackConfURL !== null &&
                        sectionID === 'everyday_vehicles' &&
                        props.topWizardCheckActions?.sectionID === sectionID &&
                        props.topWizardCheckActions.has_changes === true && (
                            <PopUp />
                        )}
                </>
                {/* main_policy_holding */}
                <>
                    <Circle
                        onClick={() => {
                            checkClick(
                                `/insurance/application/${application?.id}/wizard?section_id=main_policy_holder&subsection_id=basic_details`
                            )
                        }}
                        $theme={theme}
                        $isPassed={main_policy_holder_has_passed}
                        $sectionId={'main_policy_holder'}
                        $isActive={
                            sectionID === 'main_policy_holder' ? true : false
                        }
                        style={{
                            transform:
                                sectionID === 'main_policy_holder'
                                    ? 'translateX(-32px)'
                                    : 'translateX(-32px)',
                            zIndex:
                                sectionID === 'main_policy_holder'
                                    ? 5
                                    : main_policy_holder_has_passed
                                    ? 0
                                    : 2,
                        }}
                    >
                        <Faded width={'auto'}>
                            <IconWrapper
                                $theme={theme}
                                $sectionId={'main_policy_holder'}
                                $isActive={
                                    sectionID === 'main_policy_holder'
                                        ? true
                                        : false
                                }
                                $isPassed={main_policy_holder_has_passed}
                            >
                                <InsuranceTopBarIcon
                                    sectionID="main_policy_holder"
                                    isMuted={
                                        sectionID === 'main_policy_holder'
                                            ? false
                                            : true
                                    }
                                    size={24}
                                />
                            </IconWrapper>
                        </Faded>
                    </Circle>
                    {showGoBackConfURL !== null &&
                        sectionID === 'main_policy_holder' &&
                        props.topWizardCheckActions?.sectionID === sectionID &&
                        props.topWizardCheckActions.has_changes === true && (
                            <PopUp />
                        )}
                </>
                {/* drivers */}
                <>
                    <Circle
                        onClick={() => {
                            checkClick(
                                `/insurance/application/${application?.id}/wizard?section_id=drivers`
                            )
                        }}
                        $theme={theme}
                        $isPassed={false}
                        $sectionId={'drivers'}
                        $isActive={sectionID === 'drivers' ? true : false}
                        style={{
                            transform:
                                sectionID === 'drivers'
                                    ? 'translateX(-64px)'
                                    : 'translateX(-66px)',
                            zIndex: sectionID === 'drivers' ? 5 : 1,
                        }}
                    >
                        <Faded width={'auto'}>
                            <IconWrapper
                                $theme={theme}
                                $isPassed={false}
                                $sectionId={'drivers'}
                                $isActive={
                                    sectionID === 'drivers' ? true : false
                                }
                            >
                                <InsuranceTopBarIcon
                                    sectionID="drivers"
                                    isMuted={
                                        sectionID === 'drivers' ? false : true
                                    }
                                    size={24}
                                />
                            </IconWrapper>
                        </Faded>
                    </Circle>
                    {showGoBackConfURL !== null &&
                        sectionID === 'drivers' &&
                        props.topWizardCheckActions?.sectionID === sectionID &&
                        props.topWizardCheckActions.has_changes === true && (
                            <PopUp />
                        )}
                </>
            </Wrapper>
        </Container>
    )
}

export default InsuranceApplicationWizardTopBarMobile
