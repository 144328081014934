import styled from 'styled-components'
import chevron_to_left from '../../../../../public/assets/navigation/chevron_to_left.svg'
import { device } from '../../../displays/devices'
import { motion } from 'framer-motion'
import { AtdConfWarningBox } from '../topBars/insuranceTopBarWithProgressionRate'
import { Popconfirm, Tooltip } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { insuranceActions } from '../../../../../redux/insuranceQuoteApplication/reducer'
import CustomTooltip from '../../../../atoms/info/CustomTooltip'
import BottomSheetWarning from '../../../../atoms/info/bottomSheetWarning'
import posthog from 'posthog-js'
import SquareIconBtn from '../../../../atoms/Button/squareIconBtn'
import {
    FloatingBtnWrapper,
    FloatingBtnSubWrapper,
    IInsuranceApplicationSectionId,
} from '../../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import LoaderElasticThreeDots from '../../../../atoms/loader/loaderElasticThreeDots'
import useThemes from '../../../../../providers/theme/hooks'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../../../providers/theme/colours'
import useWindowSize, { WindowSize } from '../../../displays/windowSizeHook'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import CrossIcon from '../../../../atoms/icons/crossIcon'
import './style.css'
import ModalDisplayFullPageDesktopExclusive from '../../../displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import { RenderInsuranceQuoteFormFieldMobile } from '../../../../organisms/insuranceQuote/formGenerators/renderElementMobile'
import { RenderInsuranceQuoteFormFieldDesktop } from '../../../../organisms/insuranceQuote/formGenerators/renderElementDesktop'
import SingleFieldModalForm from '../../../../organisms/insuranceQuote/modalManagers/singleFieldModalForm'
import ModalDisplay from '../../../displays/pageWrappers/modalDisplay'
import { ModalContentDesktop } from '../../../../molecules/technicalInformation/modal'

const Container = styled.div<StyleProps>`
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${(props) => (props.$isMobile ? '72px' : '80px')};
    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
    z-index: 100;
    background-color: ${(props) => colours[props.$theme].background_default};
    border-top: ${(props) => `1px solid ${colours[props.$theme].border_muted}`};
    transition: all 100ms;
`

const RightSide = styled.div<StyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 16px;
    position: relative;
`
const LeftSide = styled.div<StyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: 100%;
`

const CloseBtn = styled(motion.button)<StyleProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    height: 48px;
    color: ${(props) => colours[props.$theme].text_default};
    background-color: ${(props) => colours[props.$theme].background_default};
    border: ${(props) => `1px solid ${colours[props.$theme].border_muted}`};
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;
    white-space: nowrap;
    :hover {
        background-color: ${(props) => colours[props.$theme].border_muted};
    }
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
        height: 40px;
    }
`

const SaveBtn = styled(motion.button)<StyleProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    color: ${(props) => colours[props.$theme].background_default};
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;
    :hover {
        opacity: 0.8;
    }
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
        height: 40px;
    }
`

// const Text = styled.p`
//     all: unset;
//     font-family: Lato;
//     font-size: 16px;
//     font-weight: 400;
//     padding-left: 8px;
//     color: #666666;
// `

const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(83%) contrast(84%);
    height: 14px;
    margin-right: 6px;
`

type StyleProps = {
    $theme: ITheme
    $isMobile?: boolean
}

type Props = {
    isMobile?: boolean
    goBack: () => any
    onSave?: () => any
    saveBtnTxt?: string
    autoFormSaveCopy?: boolean
    isSaveDisabled?: boolean
    hasFormChanged?: boolean
    isSaveLoading?: boolean
    sectionId?: IInsuranceApplicationSectionId
}
const InsuranceGoBackReusableBottomBar = (props: Props) => {
    let {
        isMobile,
        saveBtnTxt,
        goBack,
        isSaveDisabled,
        // autoFormSaveCopy,
        onSave,
        hasFormChanged,
        isSaveLoading,
        sectionId,
    } = props

    let navigate = useNavigate()
    let location = useLocation()

    const dispatch = useAppDispatch()

    const [showGoBackConf, toggleGoBackConf] = useState(false)

    const handleOpenChangeConf = (open: boolean) => {
        if (!open) {
            toggleGoBackConf(open)
            return
        }

        if (hasFormChanged) {
            toggleGoBackConf(open)
        } else {
            goBack && goBack()
        }
    }

    const [isWizardExit, setIsWizardExit] = useState(false)

    let wizardExitAction = () => {
        reset_set_wizard_entity_id_to_skip()
        dispatch(
            insuranceActions.validate_insurance_application_request({
                application: {
                    ...application,
                },
                activateValidationSheet: true,
                isWizard: true,
            })
        )

        // activate missing info sheet and redirect
        navigate(`/insurance/application/${application?.id}`)
    }

    const saveChanges = () => {
        toggleGoBackConf(false)

        if (isWizardExit) {
            wizardExitAction()
        } else onSave && onSave()
    }

    const destroyChanges = () => {
        toggleGoBackConf(false)
        dispatch(insuranceActions.reset_draft_insurance_application())

        if (isWizardExit) {
            wizardExitAction()
        } else goBack && goBack()
    }

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    let application_draft = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft
    })

    let isInsuranceLoading = useAppSelector((state) => {
        return state.insuranceQuoteApplication.isLoading
    })

    const [showWarning, toggleWarning] = useState(false)
    const [showHelpTooltip, toggleShowHelpTooltip] = useState(false)

    useEffect(() => {
        if (isSaveDisabled === true) {
            toggleWarning(true)
        } else if (isSaveDisabled === false) {
            toggleWarning(false)
        }
    }, [isSaveDisabled])

    const { theme } = useThemes()

    let isSomethingLoading = isSaveLoading || isInsuranceLoading

    const size: WindowSize = useWindowSize()

    let isMobilee = isMobile
        ? true
        : size && size.width && size.width < 880
        ? true
        : false

    let is_wizard = location.pathname.match(/wizard/g)

    let {
        reset_set_wizard_entity_id_to_skip,
        crossFormData,
        isCrossFormOpen,
        setIsCrossFormOpen,
        trackCrossFormSubmission,
        setTrackCrossFormSubmission,
    } = useSetInitialInsurance()

    let openCrossFormData =
        isCrossFormOpen && crossFormData && crossFormData[isCrossFormOpen.id]
            ? crossFormData[isCrossFormOpen.id]
            : undefined

    const determineCrossFormData = () => {
        if (openCrossFormData?.field_to_update_section_id) {
            let section_data =
                application_draft[openCrossFormData.field_to_update_section_id]

            if (openCrossFormData.field_to_update_vehicle_id) {
                if (
                    section_data &&
                    openCrossFormData.field_to_update_sub_section_id
                ) {
                    let vehicle_section_data = //@ts-ignore
                        section_data[
                            openCrossFormData.field_to_update_vehicle_id
                        ]

                    return vehicle_section_data[
                        openCrossFormData.field_to_update_sub_section_id
                    ]
                }
            } else if (openCrossFormData.field_to_update_other_driver_id) {
                if (
                    section_data &&
                    openCrossFormData.field_to_update_sub_section_id
                ) {
                    let other_driver_section_data = //@ts-ignore
                        section_data[
                            openCrossFormData.field_to_update_other_driver_id
                        ]

                    return other_driver_section_data[
                        openCrossFormData.field_to_update_sub_section_id
                    ]
                }
            } else {
                if (
                    section_data &&
                    openCrossFormData.field_to_update_sub_section_id
                ) {
                    //@ts-ignore
                    return section_data[
                        openCrossFormData.field_to_update_sub_section_id
                    ]
                }
            }

            if (section_data) {
                return section_data
            }

            return application_draft
        }
        return application_draft
    }

    const keepCurrentFormUnsavedData = (fieldid: string) => {
        if (crossFormData && crossFormData[fieldid]) {
            let currentFormSnapshop = crossFormData[fieldid].currentFormSnapshop

            currentFormSnapshop.unsavedDataListOfFields.forEach((field) => {
                currentFormSnapshop.keepUnsavedDataFunction(
                    field.id,
                    currentFormSnapshop.unsavedData[field.id]
                )

                if (currentFormSnapshop.unsavedData[field.id] !== undefined) {
                    currentFormSnapshop.resetUnsavedDataErrorsFunction(
                        field.id,
                        undefined
                    )
                }
            })
        }
    }

    useEffect(() => {
        if (!isInsuranceLoading && trackCrossFormSubmission?.id) {
            keepCurrentFormUnsavedData(trackCrossFormSubmission.id)

            setTrackCrossFormSubmission(undefined)
        }
    }, [isInsuranceLoading, trackCrossFormSubmission])

    return (
        <Container $isMobile={isMobilee} $theme={theme}>
            {isCrossFormOpen &&
            openCrossFormData?.field_to_update_section_id &&
            openCrossFormData?.field_to_update ? (
                isMobile ? (
                    <ModalDisplay isOpen toggle={() => {}}>
                        <SingleFieldModalForm
                            title={openCrossFormData.affected_field_error_cta}
                            infoBoxText={openCrossFormData.field_to_update_info}
                            onSave={() => {
                                openCrossFormData?.onSubmit &&
                                    openCrossFormData.onSubmit()

                                if (openCrossFormData?.affected_field_id) {
                                    setTrackCrossFormSubmission({
                                        id: openCrossFormData?.affected_field_id,
                                    })
                                }
                                setIsCrossFormOpen(undefined)
                            }}
                            onClose={() => {
                                setIsCrossFormOpen(undefined)
                            }}
                        >
                            <RenderInsuranceQuoteFormFieldMobile
                                data={determineCrossFormData()}
                                onAnswerChange={(id: string, answer: any) => {
                                    openCrossFormData?.onAnswerChange
                                        ? openCrossFormData.onAnswerChange(
                                              id,
                                              answer
                                          )
                                        : {}
                                }}
                                item={openCrossFormData?.field_to_update}
                                sectionID={
                                    openCrossFormData?.field_to_update_section_id ===
                                    'main_driver'
                                        ? 'main_policy_holder'
                                        : openCrossFormData?.field_to_update_section_id ===
                                          'vehicles'
                                        ? 'vehicles_to_insure'
                                        : openCrossFormData?.field_to_update_section_id ===
                                          'other_drivers'
                                        ? 'drivers'
                                        : 'insurance_details'
                                }
                                bg={colours[theme].modal_side_sheet_pop_over}
                                setError={(id: string, error: any) => {}}
                            />
                        </SingleFieldModalForm>
                    </ModalDisplay>
                ) : (
                    <ModalDisplayFullPageDesktopExclusive
                        isOpen
                        toggle={() => {}}
                    >
                        <ModalContentDesktop
                            $theme={theme}
                            style={{
                                paddingInline: 0,
                                width: 480,
                                marginTop: 0,
                                marginBottom: 0,
                            }}
                        >
                            <SingleFieldModalForm
                                title={
                                    openCrossFormData?.affected_field_error_cta
                                }
                                infoBoxText={
                                    openCrossFormData?.field_to_update_info
                                }
                                onSave={() => {
                                    openCrossFormData?.onSubmit &&
                                        openCrossFormData.onSubmit()
                                    if (openCrossFormData?.affected_field_id) {
                                        setTrackCrossFormSubmission({
                                            id: openCrossFormData?.affected_field_id,
                                        })
                                    }
                                    setIsCrossFormOpen(undefined)
                                }}
                                onClose={() => {
                                    setIsCrossFormOpen(undefined)
                                }}
                            >
                                <RenderInsuranceQuoteFormFieldDesktop
                                    data={determineCrossFormData()}
                                    onAnswerChange={(
                                        id: string,
                                        answer: any
                                    ) => {
                                        openCrossFormData?.onAnswerChange
                                            ? openCrossFormData.onAnswerChange(
                                                  id,
                                                  answer
                                              )
                                            : {}
                                    }}
                                    item={openCrossFormData?.field_to_update}
                                    sectionID={
                                        openCrossFormData?.field_to_update_section_id ===
                                        'main_driver'
                                            ? 'main_policy_holder'
                                            : openCrossFormData?.field_to_update_section_id ===
                                              'vehicles'
                                            ? 'vehicles_to_insure'
                                            : openCrossFormData?.field_to_update_section_id ===
                                              'other_drivers'
                                            ? 'drivers'
                                            : 'insurance_details'
                                    }
                                    bg={
                                        colours[theme].modal_side_sheet_pop_over
                                    }
                                    setError={(id: string, error: any) => {}}
                                />
                            </SingleFieldModalForm>
                        </ModalContentDesktop>
                    </ModalDisplayFullPageDesktopExclusive>
                )
            ) : null}
            <LeftSide $theme={theme}>
                <Popconfirm
                    title={
                        <AtdConfWarningBox
                            title="You have unsaved changes."
                            theme={theme}
                        />
                    }
                    open={showGoBackConf}
                    onOpenChange={handleOpenChangeConf}
                    onConfirm={saveChanges}
                    onCancel={destroyChanges}
                    okText="Save"
                    cancelText="Discard changes"
                    okButtonProps={{
                        style: {
                            backgroundColor: sectionId
                                ? insurance_colours[theme].sections[sectionId]
                                      .primary_100
                                : colours[theme].primary,
                            border: 'none',
                            borderRadius: 4,
                            height: 32,
                            paddingLeft: 12,
                            paddingRight: 12,
                        },
                    }}
                    cancelButtonProps={{
                        danger: true,
                        style: {
                            borderRadius: 4,
                            height: 32,
                            paddingLeft: 12,
                            paddingRight: 12,
                        },
                    }}
                    placement="topLeft"
                    style={{
                        borderRadius: 4,
                    }}
                    icon={
                        <ExclamationCircleFilled
                            style={{ color: colours[theme].error }}
                        />
                    }
                >
                    <CloseBtn
                        $theme={theme}
                        onClick={() => {
                            if (hasFormChanged) {
                                toggleGoBackConf(true)
                            } else {
                                goBack()
                            }
                        }}
                        whileTap={{ scale: 1.1 }}
                    >
                        <Chevron src={chevron_to_left} />
                        {is_wizard && isMobilee ? null : ' Go back'}
                    </CloseBtn>
                </Popconfirm>
                {/* {autoFormSaveCopy && (
                    <div style={{ display: 'flex' }}>
                        <div style={{ transform: 'translateY(2px)' }}>
                            <InfoIcon size="20" />
                        </div>
                        <Text>Form saves automatically</Text>
                    </div>
                )} */}
            </LeftSide>
            {onSave && application?.status === 'DRAFT' && (
                <RightSide $theme={theme}>
                    <BottomSheetWarning
                        isOpen={showWarning && isMobilee ? true : false}
                        toggle={() => toggleWarning(false)}
                        title="Warning!"
                        text="Some required information for this stage is missing or is invalid, please have a look at the highlighted fields. "
                    />
                    <CustomTooltip
                        isOpen={showWarning && !isMobilee ? true : false}
                        toggle={() => toggleWarning(false)}
                        title="Warning!"
                        text="Some required information for this stage is missing or is invalid, please have a look at the highlighted fields."
                    />

                    {is_wizard && (
                        <Tooltip
                            title={'Switch to free-flow'}
                            color={colours[theme].strong_tooltip}
                        >
                            <div
                                style={{
                                    border: sectionId
                                        ? `1px solid ${insurance_colours[theme].sections[sectionId].primary_100}`
                                        : `1px solid ${colours[theme].primary}`,
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}
                                role={'button'}
                                onClick={() => {
                                    if (hasFormChanged) {
                                        setIsWizardExit(true)
                                        toggleGoBackConf(true)
                                    } else {
                                        wizardExitAction()
                                    }
                                }}
                            >
                                <CrossIcon
                                    size="16"
                                    color={
                                        sectionId
                                            ? insurance_colours[theme].sections[
                                                  sectionId
                                              ].primary_100
                                            : colours[theme].primary
                                    }
                                />
                            </div>
                        </Tooltip>
                    )}

                    <SaveBtn
                        $theme={theme}
                        style={{
                            backgroundColor: isSaveDisabled
                                ? colours[theme].error
                                : sectionId
                                ? insurance_colours[theme].sections[sectionId]
                                      .primary_100
                                : colours[theme].primary,
                        }}
                        onClick={
                            isSomethingLoading
                                ? () => {}
                                : isSaveDisabled
                                ? () => toggleWarning(true)
                                : onSave
                        }
                        whileTap={{ scale: 1.1 }}
                    >
                        {isSomethingLoading ? (
                            <div
                                style={{
                                    width: 112,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <LoaderElasticThreeDots />
                            </div>
                        ) : (
                            saveBtnTxt ?? 'Save'
                        )}
                    </SaveBtn>
                </RightSide>
            )}
            {application?.status !== 'DRAFT' && application.id && (
                <FloatingBtnWrapper>
                    <FloatingBtnSubWrapper>
                        <CustomTooltip
                            isOpen={
                                !isMobilee && showHelpTooltip ? true : false
                            }
                            toggle={() => toggleShowHelpTooltip(false)}
                            title="Need to make a change to your application?"
                            text={
                                <span>
                                    Please email us at{' '}
                                    <a
                                        href={
                                            'mailto:insurance@custodian.club?subject=Help'
                                        }
                                        style={{
                                            color: colours[theme].primary,
                                        }}
                                    >
                                        insurance@custodian.club
                                    </a>{' '}
                                    and let us know what change you’d like to
                                    make
                                </span>
                            }
                        />
                        <BottomSheetWarning
                            isOpen={isMobilee && showHelpTooltip ? true : false}
                            toggle={() => toggleShowHelpTooltip(false)}
                            customHeight="250px"
                            title="Need to make a change to your application?"
                            text={
                                <span>
                                    Please email us at{' '}
                                    <a
                                        href={
                                            'mailto:insurance@custodian.club?subject=Help'
                                        }
                                        style={{
                                            color: colours[theme].primary,
                                        }}
                                    >
                                        insurance@custodian.club
                                    </a>{' '}
                                    and let us know what change you’d like to
                                    make.
                                </span>
                            }
                        />

                        <SquareIconBtn
                            icon="pen"
                            padding="12px"
                            size="48px"
                            isActive={showHelpTooltip ? true : false}
                            onClick={() => {
                                posthog.capture(
                                    'Click on insurance application help from readonly view'
                                )
                                toggleShowHelpTooltip(true)
                            }}
                        />
                    </FloatingBtnSubWrapper>
                </FloatingBtnWrapper>
            )}
        </Container>
    )
}

export default InsuranceGoBackReusableBottomBar
