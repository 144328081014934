import { ITabProps } from 'myInterfaces'
import { useState } from 'react'
import styled from 'styled-components'
import OwnerBasicDetailsManagerDesktop from '../../../molecules/showroom/ownerForm/basicDetails/ownerBasicDetailsManagerDesktop'
import ShowroomOwnerSocialMediaFormDesktop from '../../../molecules/showroom/ownerForm/socialMedia/socialMediaFormDesktop'
import BtnTabRow from '../../../molecules/tab/btnTabRow'
import { device } from '../../../templates/displays/devices'
import { IUserAddressApi } from '../../../../redux/services/typedefinitions/apiPayloads'

const Wrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        display: none;
    }

    @media ${device.beyond_ipad_mobile} {
        display: block;
        width: 100%;
        box-sizing: border-box;
        max-width: 500px;
        min-width: 500px;
        height: 100%;
        max-height: 90vh;
        overflow-y: auto;
        z-index: 50;
        position: relative;
        border-radius: 3px;
    }
`

const Container = styled.div`
    @media ${device.beyond_ipad_mobile} {
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 24px;
        padding-right: 24px;
        transition: all 100ms ease-in-out;
        overflow-y: auto;
    }
`

const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-bold;
    font-size: 24px;
    align-self: flex-start;
    padding-bottom: 10px;
`

// container for action button
export const OwnerShowroomFormActions = styled.div`
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        bottom: 0;
        height: 100px;
        border-top: 1px solid var(--border-muted, #e5e5e5);
        left: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        padding-right: 30px;
        background-color: var(--bg-color, #fff);
        z-index: 6;
    }

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: 100%;
        box-sizing: border-box;
        justify-content: flex-end;
        height: 50px;
    }
`
type Props = {
    close: () => any
}

export interface IOwnerBasicDetails extends IUserAddressApi {
    name: string
    phone: string
}
const OwnerContactFormDesktop = (props: Props) => {
    const [activeTab, setActiveTab] = useState<'info' | 'address'>('info')

    const [hasInfoChange, setHasInfoChange] = useState<boolean>(false)
    const [hasSMChange, setHasSMChange] = useState<boolean>(false)

    let tabs: ITabProps[] = [
        {
            id: 0,
            title: 'Basic Details',
            onClick: (arg: number) => {
                setActiveTab('info')
            },
            isActive: activeTab === 'info' ? true : false,
            isDisabled: hasSMChange,
            disabledInfo: 'Please save your Social Media changes',
        },
        {
            id: 1,
            title: 'Social Media',
            onClick: (arg: number) => {
                setActiveTab('address')
            },
            isActive: activeTab === 'address' ? true : false,
            isDisabled: hasInfoChange,
            disabledInfo: 'Please save your Basic Details changes',
        },
    ]

    return (
        <Wrapper>
            <Container>
                <div style={{ paddingTop: '20px' }} />
                <Title>Contact details</Title>
                <div style={{ paddingTop: '20px' }} />

                <BtnTabRow
                    tabs={tabs}
                    customPassiveColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />

                <div style={{ paddingTop: '20px' }} />

                <div
                    style={{
                        display: activeTab === 'info' ? 'flex' : 'none',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <OwnerBasicDetailsManagerDesktop
                        close={() => {
                            props.close()
                        }}
                        hasInfoChange={hasInfoChange}
                        setHasInfoChange={setHasInfoChange}
                    />
                </div>

                <div
                    style={{
                        display: activeTab === 'address' ? 'flex' : 'none',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <ShowroomOwnerSocialMediaFormDesktop
                        close={() => {
                            props.close()
                        }}
                        hasSMChange={hasSMChange}
                        setHasSMChange={setHasSMChange}
                    />
                </div>
            </Container>
        </Wrapper>
    )
}

export default OwnerContactFormDesktop
