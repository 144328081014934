import { useState } from 'react'
import styled from 'styled-components'
import AddBox from '../../Button/addBox'
import placeholder from '../../skeleton/keyMomentsFacts/skeleton.jpg'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type EnumerableObject = {
    id: string
    text: string
    order: number
}

type IStyle = { $theme: ITheme }

type Props = {
    obj: EnumerableObject
    index: number
    theme: '1' | '2'
    onClick?: any
    isReadOnly?: boolean
}

const ListItem1 = styled.div<IStyle>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;

    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    padding-top: 30px;
`

const ListItem2 = styled.div<IStyle>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;

    flex: 1;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    height: 100%;

    min-height: 170px;

    padding: 20px;
    padding-top: 30px;

    width: inherit;
`

const ListContent = styled.div<IStyle>`
    width: inherit;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 5e-5em;
    color: ${(props) => colours[props.$theme].text_default};
    padding-bottom: 20px;
`
const NumberKey = styled.div<IStyle>`
    font-family: Lato;
    font-style: normal;
    font-size: 18px;
    color: ${(props) => colours[props.$theme].text_muted};
    padding-right: 32px;
`

const KeyMomentItem = (props: Props) => {
    let { index, obj, theme } = props

    const { theme: uiTheme } = useThemes()

    let hasContent = () => {
        if (!obj.text || (obj.text && obj.text.length < 1)) {
            return false
        } else return true
    }

    const [isAddShow, setIsAddShow] = useState<boolean>(false)

    let nothing = <div style={{ display: 'none' }} key={index} />

    let render = (): any => {
        if (theme === '1') {
            if (index <= 2) {
                if (hasContent()) {
                    return (
                        <ListItem1 $theme={uiTheme}>
                            <NumberKey $theme={uiTheme}>{obj.order}</NumberKey>
                            <ListContent $theme={uiTheme}>
                                {obj.text}
                            </ListContent>
                        </ListItem1>
                    )
                } else if (!props.isReadOnly) {
                    return (
                        <div
                            onMouseEnter={() => {
                                setIsAddShow(true)
                            }}
                            onMouseLeave={() => {
                                setIsAddShow(false)
                            }}
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                height: '152px',
                            }}
                            role="button"
                            onClick={() => {
                                if (!props.isReadOnly && props.onClick) {
                                    props.onClick()
                                }
                            }}
                        >
                            {!isAddShow ? (
                                <img
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        height: '152px',
                                    }}
                                    src={placeholder}
                                    alt="placeholder"
                                />
                            ) : (
                                <AddBox />
                            )}
                        </div>
                    )
                }
            } else return nothing
        } else if (index > 2) {
            if (hasContent()) {
                return (
                    <ListItem2
                        $theme={uiTheme}
                        style={{
                            minHeight: '150px',
                        }}
                    >
                        <NumberKey $theme={uiTheme}>{obj.order}</NumberKey>
                        <ListContent $theme={uiTheme}>{obj.text}</ListContent>
                    </ListItem2>
                )
            } else if (!props.isReadOnly) {
                return (
                    <div
                        onMouseEnter={() => setIsAddShow(true)}
                        onMouseLeave={() => setIsAddShow(false)}
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '152px',
                        }}
                        role="button"
                        onClick={() => {
                            if (!props.isReadOnly && props.onClick) {
                                props.onClick()
                            }
                        }}
                    >
                        {!isAddShow ? (
                            <img
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    height: '100%',
                                }}
                                src={placeholder}
                                alt="placeholder"
                            />
                        ) : (
                            <AddBox />
                        )}
                    </div>
                )
            } else return <div style={{ display: 'none' }} key={index} />
        } else return nothing
    }

    return render()
}

export default KeyMomentItem
