import {
    IApexInterviewCategory,
    ITaskNormalisedICategoryInformationObject_State,
} from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { device } from '../../../../templates/displays/devices'

type Props = {
    categories_information_list: ITaskNormalisedICategoryInformationObject_State
    category_ids_list: IApexInterviewCategory[]
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const RowWPadding = styled(Row)`
    padding-top: 16px;
    padding-bottom: 16px;
`

const Anchor = styled(AnchorLink)``

const CategoryText = styled.div`
    font-family: Lato;
    font-size: 17px;
    text-transform: uppercase;
    color: var(--primary, #5ec3ca) !important;
    :focus {
        color: var(--primary, #5ec3ca) !important;
    }
    letter-spacing: 1.25px;
    transition: all 400ms;

    :hover {
        color: rgb(208 208 208);
        transition: all 400ms;
    }

    @media ${device.ipad} {
        font-size: 14px;
    }
`

type IStyledProps = {
    index: number
}
const Dot = styled.div<IStyledProps>`
    height: 4px;
    width: 4px;
    background-color: var(--primary, #5ec3ca);
    border-radius: 50%;
    display: ${(props) => (props.index === 0 ? 'none' : 'flex')};
    margin-left: 25px;
    margin-right: 25px;

    @media ${device.ipad} {
        margin-left: 18px;
        margin-right: 18px;
    }
`

const ApexCategoryAnchorsDesktop: React.FC<Props> = ({
    categories_information_list,
    category_ids_list,
}) => {
    return (
        <RowWPadding>
            {category_ids_list !== undefined && (
                <>
                    {category_ids_list.map(
                        (
                            category_id: IApexInterviewCategory,
                            index: number
                        ) => {
                            if (
                                categories_information_list &&
                                categories_information_list[category_id] !==
                                    undefined
                            ) {
                                return (
                                    <Row key={`category_${index}-desktop`}>
                                        <Dot index={index} />

                                        <Anchor
                                            href={`#${category_id}-desktop`}
                                        >
                                            {categories_information_list[
                                                category_id
                                            ].category_name && (
                                                <CategoryText>
                                                    {
                                                        categories_information_list[
                                                            category_id
                                                        ].category_name
                                                    }
                                                </CategoryText>
                                            )}
                                        </Anchor>
                                    </Row>
                                )
                            } else return undefined
                        }
                    )}
                </>
            )}
        </RowWPadding>
    )
}

export default ApexCategoryAnchorsDesktop
