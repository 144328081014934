import styled from 'styled-components'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import ScrollToTop from '../../../templates/displays/scrollToTop'
import {
    IAnyObject,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { IInsuranceFieldType } from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import MobileInsuranceOptionsSearchChild from './mobileInsuranceOptionsSearchChild'
import { IDropdownItem, IDropdownListState } from 'entityModels'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import ChevronLeft from '../../../atoms/icons/components/chevronLeft'

const PageWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`

const TopBar = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 64px;
    position: fixed;
    background-color: var(--bg-color, #fff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
    padding-left: 24px;
    padding-right: 24px;
`

const EndBtn = styled.div`
    letter-spacing: 5e-5em;
    font-family: Lato;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
`
const ReturnBtn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Lato;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
`

type Props = {
    draft_data?: IAnyObject
    edited_entity?: IAnyObject[] | undefined
    entity_index?: number
    submitted_data?: IAnyObject
    UIcopy?: IInsuranceFieldType
    field_id?: string
    onChange: (value: IDropdownItem) => void
    carid?: string
    sectionId?: IInsuranceApplicationSectionId
}

export function containsNumbers(str: string) {
    return /\d/.test(str)
}

const InsuranceApplicationQuoteFieldsSearchManager = (props: Props) => {
    // const dispatch = useAppDispatch()

    let {
        field_id,
        UIcopy,
        draft_data,
        carid,
        sectionId,
        edited_entity,
        entity_index,
    } = props

    let dispatch = useAppDispatch()

    let cancel = () => {
        dispatch(insuranceActions.set_temp_field_search_copy(undefined))
    }

    let makesDropdowndata: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let transmissionsList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    let bodyTypeList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carBodyTypes
    })

    let countriesList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    let dropdownsState: IDropdownListState = useAppSelector((state) => {
        return state.localdata.dropdownData
    })

    let adjustedCopyID = !field_id
        ? ''
        : !containsNumbers(field_id)
        ? field_id
        : field_id.replace(/-\d+$/, '')

    let motoring_convictions_type =
        edited_entity &&
        entity_index !== undefined &&
        edited_entity[entity_index] &&
        edited_entity[entity_index].type
            ? edited_entity[entity_index].type
            : undefined

    let motoring_convictions_type_code =
        motoring_convictions_type?.code ?? undefined
    let motoring_convictions_codes_grouped_dropdown_data =
        motoring_convictions_type_code &&
        dropdownsState?.motoring_conviction_codes_grouped &&
        dropdownsState.motoring_conviction_codes_grouped[
            motoring_convictions_type_code
        ]
            ? dropdownsState.motoring_conviction_codes_grouped[
                  motoring_convictions_type_code
              ]
            : undefined
    let show_motoring_convictions_codes_grouped =
        field_id === 'code' &&
        UIcopy?.parent_entity_id === 'motoring_convictions' &&
        motoring_convictions_type_code &&
        motoring_convictions_codes_grouped_dropdown_data
            ? true
            : false

    let hasCountriesOptions =
        field_id === 'registration_country' ||
        field_id === 'import_origin_country_code' ||
        field_id === 'current_country_of_residence_country_code' ||
        field_id === 'country'
            ? true
            : false

    let all_options: string | boolean | IDropdownItem[] | null | undefined =
        !UIcopy
            ? []
            : hasCountriesOptions
            ? countriesList
            : show_motoring_convictions_codes_grouped
            ? motoring_convictions_codes_grouped_dropdown_data
            : UIcopy.dropdown_id
            ? dropdownsState[UIcopy.dropdown_id]
                ? dropdownsState[UIcopy.dropdown_id]
                : []
            : field_id === 'make'
            ? makesDropdowndata
            : field_id === 'model'
            ? modelsDropdowndata
            : field_id === 'transmission'
            ? transmissionsList
            : field_id === 'body_type'
            ? bodyTypeList
            : dropdownsState[`${field_id}`]
            ? dropdownsState[`${field_id}`]
            : UIcopy.options
            ? UIcopy.options
            : []

    return (
        <>
            <ScrollToTop>
                <IpadAndMobileDisplay>
                    <PageWrapper>
                        <TopBar>
                            <ReturnBtn
                                role="button"
                                onClick={() => {
                                    // need to reset JUST the field not the whole sub form.
                                    cancel()
                                }}
                            >
                                <ChevronLeft />
                                <div style={{ paddingLeft: '8px' }} />
                                {UIcopy && <div>{UIcopy.text}</div>}
                            </ReturnBtn>
                            <EndBtn role="button" onClick={() => {}}>
                                Clear
                            </EndBtn>
                        </TopBar>
                        <div style={{ paddingTop: '80px' }} />

                        {adjustedCopyID && UIcopy && (
                            <MobileInsuranceOptionsSearchChild
                                sectionId={sectionId}
                                key={`${props.UIcopy?.id}`}
                                UIcopy={{
                                    ...props.UIcopy,
                                    // @ts-ignore
                                    options: all_options,
                                }}
                                value={
                                    draft_data &&
                                    draft_data[`${UIcopy?.id}`]?.uid
                                }
                                allowAdd={
                                    field_id === 'make' || field_id === 'model'
                                        ? true
                                        : false
                                }
                                addAnItemFunc={(id: string, name: string) => {
                                    if (
                                        field_id === 'make' ||
                                        field_id === 'model'
                                    ) {
                                        dispatch(
                                            dropDownActions.setIsLoadingNewMakeOrModel(
                                                true
                                            )
                                        )
                                        dispatch(
                                            dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                                {
                                                    dropdownTargetId:
                                                        field_id === 'make'
                                                            ? 'carMakes'
                                                            : 'carModels',
                                                    name: name,
                                                    relevantOtherLinkedDataMakeMake:
                                                        draft_data &&
                                                        draft_data['make']?.name
                                                            ? draft_data['make']
                                                                  ?.name
                                                            : draft_data &&
                                                              draft_data['make']
                                                            ? draft_data['make']
                                                            : undefined,
                                                }
                                            )
                                        )
                                    }
                                    props.onChange({
                                        uid: id,
                                        name: name,
                                    })
                                    if (field_id === 'make' && carid) {
                                        dispatch(
                                            insuranceActions.set_answer_insurance_car_techInfo(
                                                {
                                                    carid: carid,
                                                    id: 'model',
                                                    answer: undefined,
                                                }
                                            )
                                        )
                                    }
                                }}
                                isValueLabel={UIcopy.dropdown_id ? true : false}
                                // customIsCheckedFunction={(
                                //     item: IDropdownItem
                                // ) => {
                                //     return draft_data &&
                                //         draft_data[`${UIcopy?.id}`]?.uid ===
                                //             item.uid
                                //         ? true
                                //         : false
                                // }}
                                placeholder={'Search'}
                                onChange={(value: IDropdownItem) => {
                                    if (UIcopy?.dropdown_id) {
                                        props.onChange({
                                            uid: `${value.value}`,
                                            name: value.label,
                                            code: value?.code,
                                        })
                                    } else {
                                        props.onChange(value)
                                    }

                                    if (field_id === 'model') {
                                        if (value?.uid !== 'new_entry') {
                                            dispatch(
                                                dropDownActions.setTempModelIDSuccess(
                                                    value?.uid
                                                )
                                            )
                                        }
                                    }

                                    if (field_id === 'make' && carid) {
                                        if (value?.uid !== 'new_entry') {
                                            dispatch(
                                                dropDownActions.setTempMakeIDSuccess(
                                                    value?.uid
                                                )
                                            )
                                        }
                                        dispatch(
                                            insuranceActions.set_answer_insurance_car_techInfo(
                                                {
                                                    carid: carid,
                                                    id: 'model',
                                                    answer: undefined,
                                                }
                                            )
                                        )
                                        dispatch(
                                            dropDownActions.setModelDropdownDataRequest(
                                                {
                                                    makeName: value.name,
                                                    make_uid: value.uid,
                                                }
                                            )
                                        )
                                    }
                                }}
                            />
                        )}
                    </PageWrapper>
                    <div style={{ paddingBottom: '20px' }} />
                </IpadAndMobileDisplay>
            </ScrollToTop>
        </>
    )
}

export default InsuranceApplicationQuoteFieldsSearchManager
