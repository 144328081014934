import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../../services'
import posthog from 'posthog-js'
import { ResponseGenerator } from 'sagaInterfaces'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { convertToGalleryImage } from '../../../conversions/entities/conversionFromAPI'
import { message } from 'antd'
import { editFormsActions } from '../../../editForms/reducer'
import { submitToS3Saga } from './submitImagesToS3'
import { updateCarGalleryImageApi } from '../../../entities/galleries/sagas/updateCarGalleryImage'
import { fileStorageActions } from '../../reducer'
import { carActions } from '../../../entities/cars/reducer'
import { galleriesActions } from '../../../entities/galleries/reducer'
import { ICarsObject, ICar, IReduxError } from '../../../entities/cars/types'
import { IGalleryImage } from '../../../entities/galleries/types'
import {
    IApiCreateCarGalleryImage_args,
    IApiUploadPresignedUrlGalleryImgsToS3_args,
    IApiCreateCarCoverImage_args,
} from '../../../services/types'
import { RootState } from '../../../store/types'
import {
    IUploadGalleryImagesPreSignedUrlsPayload,
    ICreateCarGalleryImageFields,
    IUploadImagePreSignedUrlPayloadSingle,
} from '../../types'

type IPayloadCreateCarGalleryImageSagaReq = {
    car_id: string
    gallerypresignedurls: IUploadGalleryImagesPreSignedUrlsPayload
    files: File[]
    fields: ICreateCarGalleryImageFields
    isFromQuickActions: boolean
    showNoProgress?: boolean
    makeCover?: boolean
}

const get_cars_from_state = (state: RootState) => state.entities.carsData.cars

function* createCarGalleryImage(p: IPayloadCreateCarGalleryImageSagaReq) {
    let api_payload: IApiCreateCarGalleryImage_args = {
        car_id: p.car_id,
        gallerypresignedurls: p.gallerypresignedurls,
        filesSubmitted: p.files,
        fields: p.fields,
    }

    try {
        if (p.showNoProgress === true) {
            let s3Payload: IApiUploadPresignedUrlGalleryImgsToS3_args = {
                gallerypresignedurls: p.gallerypresignedurls,
                files: p.files,
            }

            yield call(submitToS3Saga, s3Payload)
        }

        const res: ResponseGenerator = yield call(
            api.entities.mutateGallery.createCarGalleryImage,
            api_payload
        )

        if (!p.isFromQuickActions) {
            yield put(editFormsActions.toggleCarGalleryEditForm())
        }

        yield put(fileStorageActions.submitCarGalleryImagesFilestorageSuccess())

        let array_of_img_ids_in_response: string[] = []

        let converted_images_array: IGalleryImage[] = []

        let cover_img_id: string | undefined

        if (res && res.data) {
            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].cover) {
                    cover_img_id = res.data[i].uid
                }
                array_of_img_ids_in_response.push(res.data[i].uid)
                converted_images_array.push(convertToGalleryImage(res.data[i]))
            }

            yield put(
                carActions.addToCarImagesIdsArray({
                    carid: p.car_id,
                    image_ids: array_of_img_ids_in_response,
                })
            )

            let carsInState: ICarsObject = yield select(get_cars_from_state)
            let currentCar: ICar = carsInState && carsInState[p.car_id]

            if (currentCar && currentCar.gallery && !currentCar.gallery.cover) {
                if (!cover_img_id) {
                    let index_of_cover_img =
                        array_of_img_ids_in_response.length - 1
                    yield put(
                        carActions.updateCoverImgId({
                            carid: p.car_id,
                            new_cover_id:
                                array_of_img_ids_in_response[
                                    index_of_cover_img
                                ],
                        })
                    )
                } else {
                    yield put(
                        carActions.updateCoverImgId({
                            carid: p.car_id,
                            new_cover_id: cover_img_id,
                        })
                    )
                }
            }
            yield put(
                galleriesActions.addToGalleryImageSuccess(
                    converted_images_array
                )
            )
        }

        // Capture event
        if (p.files.length > 0) {
            posthog.capture('ADD CAR IMAGE', {
                imagesUploadCount: 1,
                isFromQuickActions: p.isFromQuickActions,
            })
        } else {
            posthog.capture('ADD CAR MULTIPLE IMAGES', {
                imagesUploadCount: p.files.length,
                isFromQuickActions: p.isFromQuickActions,
            })
        }

        const info = (messageText: string) => {
            message.info(messageText)
        }

        info(
            converted_images_array.length === 1
                ? '1 image added to gallery'
                : `${converted_images_array.length} 
                        images added to gallery`
        )
        yield put(
            fileStorageActions.resetPresignedUrlsCarGalleryImagesSuccess()
        )

        if (
            p.files.length === 1 &&
            res.data.length === 1 &&
            p.makeCover &&
            res.data[0].uid
        ) {
            yield call(updateCarGalleryImageApi, {
                carid: p.car_id,
                imageid: res.data[0].uid,
                body: { cover: true },
            })
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: createCarGalleryImage,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                fileStorageActions.submitCarGalleryImagesFilestorageError(
                    typedError
                )
            )
            yield put(
                fileStorageActions.resetPresignedUrlsCarGalleryImagesSuccess()
            )
            return
        }
    }
}

// LIST

function* watcherUploadAndSubmitCarGalleryImages() {
    while (true) {
        const { payload } = yield take(
            fileStorageActions.submitCarGalleryImagesFilestorageRequest
        )

        let p: IPayloadCreateCarGalleryImageSagaReq = {
            car_id: payload.carid,
            gallerypresignedurls: payload.gallerypresignedurls,
            files: payload.files,
            fields: payload.fields,
            isFromQuickActions: payload.isFromQuickActions,
            showNoProgress: payload.showNoProgress,
            makeCover: payload.makeCover,
        }

        yield call(createCarGalleryImage, p)

        // yield call(getCarDataApiPayloadById, payload.carid)
    }
}

type ICreateCarCoverImageSaga = {
    car_id: string
    presignedurl: IUploadImagePreSignedUrlPayloadSingle
    noToggle?: boolean
}

function* createCarCoverImage(p: ICreateCarCoverImageSaga) {
    let api_payload: IApiCreateCarCoverImage_args = {
        car_id: p.car_id,
        presignedurl: p.presignedurl,
    }
    try {
        const res: ResponseGenerator =
            yield api.entities.mutateGallery.createCarCoverImage(api_payload)

        let banner_img: IGalleryImage = convertToGalleryImage(res.data[0])

        yield put(
            carActions.updateBannerImage({
                carid: p.car_id,
                banner_img: banner_img,
            })
        )

        // yield call(getCarDataApiPayloadById, p.car_id)

        if (p.noToggle === true) {
            return
        } else if (!p.noToggle) {
            yield put(editFormsActions.toggleCarBannerImageForm())
        }

        // Capture event
        posthog.capture('ADD CAR COVER IMAGE')

        return res
    } catch (error: any) {
        if (error.status === 401) {
            let pyload: IUnauthHandlerPayload = {
                functionToRepeat: createCarCoverImage,
                payload: p,
            }
            yield call(unauthHandlerSaga, pyload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                fileStorageActions.submitCarCoverImageFilestorageError(
                    typedError
                )
            )
            return
        }
    }
}

// LIST

// WATCHER FUNCTION : getPreSignedUrlImagesCarGalleryWatcher*

function* watcherUploadAndSubmitCarCoverImage() {
    while (true) {
        const { payload } = yield take(
            fileStorageActions.submitCarCoverImageFilestorageRequest
        )

        // payload type : IUploadAndCreateCarCoverImageArgs

        let fileArray = []
        fileArray[0] = payload.file

        let presignedUrlArray = []
        presignedUrlArray[0] = payload.presignedurl

        // yield call(uploadCarCoverImageTos3, presignedUrlArray, fileArray);

        let p: ICreateCarCoverImageSaga = {
            car_id: payload.carid,
            presignedurl: payload.presignedurl,
            noToggle: payload.noToggle,
        }
        yield call(createCarCoverImage, p)
    }
}

const filestorage_sagas_submission_car_gallery_images: any[] = [
    fork(watcherUploadAndSubmitCarGalleryImages),
    fork(watcherUploadAndSubmitCarCoverImage),
]

export default filestorage_sagas_submission_car_gallery_images
