import styled from 'styled-components'
import ListItemDesktop from '../../atoms/list/listItemDesktop'
import * as unitGenerator from '../../../helpers/units/unitConversion'

const Wrap = styled.section<IStyle>`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

interface Props {
    list: string[]
    data: any
    toggle?: any
    removeBottomBorderCondition?: boolean | undefined
    default_fuel_unit_user_text?: string | undefined | null
    default_fuel_unit_user?: string | undefined | null
    default_distance_unit_user_text?: string | undefined | null
    default_distance_unit_user?: string | undefined | null
    requiredLabel?: boolean
    readOnlyMode?: boolean
}

interface IStyle {
    something?: boolean
}

const ListManagerDesktop = (props: Props) => {
    const shapeItemAccordingToUnitPreferences = (id: any) => {
        if (id === 'mileage') {
            if (
                props.default_distance_unit_user &&
                props.default_distance_unit_user !== 'kilometers' &&
                props.data[id].answer !== null
            ) {
                return {
                    ...props.data[id],
                    answer: unitGenerator.generateFrontEndValueDistanceUnit(
                        props.default_distance_unit_user,
                        props.data[id].answer
                    ),
                }
            } else {
                return props.data[id]
            }
        }

        if (id === 'engine_capacity') {
            if (
                props.data[id].answer !== null &&
                props.default_fuel_unit_user &&
                props.default_fuel_unit_user !== 'cubic_centimeters'
            ) {
                if (props.default_fuel_unit_user === 'liters') {
                    return {
                        ...props.data[id],
                        answer: unitGenerator
                            .generateFrontEndValueFuelUnit(
                                props.default_fuel_unit_user,
                                props.data[id].answer
                            )
                            .toFixed(1),
                    }
                } else {
                    return {
                        ...props.data[id],
                        answer: unitGenerator
                            .generateFrontEndValueFuelUnit(
                                props.default_fuel_unit_user,
                                props.data[id].answer
                            )
                            .toFixed(1),
                    }
                }
            } else {
                return props.data[id]
            }
        }
        return props.data[id]
    }

    return (
        <Wrap>
            {props.list.map((id: any, index) => {
                return (
                    <ListItemDesktop
                        key={id}
                        toggle={props.toggle}
                        item={shapeItemAccordingToUnitPreferences(id)}
                        removeBottomBorder={
                            props.removeBottomBorderCondition ? true : false
                        }
                        removeEdit={
                            props.readOnlyMode ||
                            (props.data[id] &&
                                props.data[id].id &&
                                props.data[id].id === 'email')
                                ? true
                                : false
                        }
                        unit={
                            id === 'engine_capacity'
                                ? props.default_fuel_unit_user_text
                                : id === 'mileage'
                                ? props.default_distance_unit_user_text
                                : undefined
                        }
                        dataCyId={`${id}-field-desktop`}
                        requiredLabel={props.requiredLabel}
                        readOnlyMode={props.readOnlyMode}
                    />
                )
            })}
        </Wrap>
    )
}

export default ListManagerDesktop
