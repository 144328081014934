import { IApexInterviewOverviewItemState } from 'apexModels'
import React from 'react'
import ApexInterviewOverviewItemMobile from './apexInterviewOverviewItemMobile'

type Props = {
    interview_overviews: IApexInterviewOverviewItemState[]
}

const ApexInterviewsOverviewManagerMobile: React.FC<Props> = ({
    interview_overviews,
}) => {
    return (
        <>
            {interview_overviews.map(
                (item: IApexInterviewOverviewItemState, index: number) => {
                    return (
                        <div key={`interview_${index}-mobile`}>
                            <ApexInterviewOverviewItemMobile
                                interview_overview={item}
                            />
                        </div>
                    )
                }
            )}
        </>
    )
}

export default ApexInterviewsOverviewManagerMobile
