import styled from 'styled-components'
import {
    car_enquiry_info_copy,
    IEnquiryOptionCopyItem,
} from '../../../redux/localdata/carEnquiryCopy'
import { IEnquiryStructureRequest } from '../../../redux/showroom/types'
import BottomBarBtnsStep from '../../atoms/Button/bottomBarBtnsStep'
import EditAddLineBtn from '../../atoms/Button/EditAddLineBtn'
import CheckboxRowItem from '../../atoms/formField/checkboxRowItem'
import EnquiryStepTitle from '../../atoms/typography/showroom/enquiryStepTitle'
import EnquiryCustomMessageText from './enquiryCustomMessageText'
import { useNavigate } from 'react-router-dom'

export const EnquityFormPaddingBottomDesktop = styled.div`
    @media screen and (min-width: 880px) {
        padding-bottom: 150px;
        background-color: var(--bg-color, #fff);
    }
`
export const EnquiryBtnsOnlyMobile = styled.div`
    width: 100%;
    box-sizing: border-box;
    @media screen and (min-width: 880px) {
        display: none;
    }
`
export type ICarEnquiry_Info_FormManager_props = {
    handleSelectedIDChange: (selectedID: string) => void
    setIsEditorOpen: (p: boolean) => void
    manageStep: (p: 'contact' | 'enquiry') => void
    enquiryFormData: IEnquiryStructureRequest
}
const CarEnquiryInfoForm = (props: ICarEnquiry_Info_FormManager_props) => {
    const {
        handleSelectedIDChange,
        setIsEditorOpen,
        manageStep,
        enquiryFormData,
    } = props

    const checkIfBtnISDisabled = () => {
        if (
            enquiryFormData.enquiry.type === 'info' &&
            enquiryFormData.enquiry.info &&
            enquiryFormData.enquiry.info.selected_enquiry &&
            enquiryFormData.enquiry.info.selected_enquiry.length === 0
        ) {
            return true
        }
        return false
    }

    const navigate = useNavigate()

    return (
        <div style={{ width: '100%' }}>
            <EnquiryStepTitle>{car_enquiry_info_copy.text}</EnquiryStepTitle>
            <div style={{ paddingTop: '20px' }} />
            <div style={{ width: '100%' }}>
                <div style={{ paddingTop: '10px' }} />

                {car_enquiry_info_copy.options.map(
                    (item: IEnquiryOptionCopyItem) => {
                        return (
                            <CheckboxRowItem
                                name={item.id}
                                text={item.name}
                                onChange={() => {
                                    handleSelectedIDChange(item.id)
                                }}
                                checked={
                                    enquiryFormData.enquiry.info
                                        ?.selected_enquiry &&
                                    enquiryFormData.enquiry.info.selected_enquiry.indexOf(
                                        item.id
                                    ) === -1
                                        ? false
                                        : true
                                }
                            />
                        )
                    }
                )}
            </div>

            <div style={{ paddingTop: '10px' }} />
            {enquiryFormData.enquiry.info &&
                enquiryFormData.enquiry.info.selected_enquiry &&
                enquiryFormData.enquiry.info.selected_enquiry.length > 0 && (
                    <EditAddLineBtn
                        theme={
                            enquiryFormData.enquiry.info?.custom_message
                                ? 'edit-icon-primary-text'
                                : 'add-icon-primary-text'
                        }
                        onClick={() => {
                            setIsEditorOpen(true)
                        }}
                        width="100%"
                        fontSize={16}
                    >
                        {enquiryFormData.enquiry.info?.custom_message
                            ? 'Edit custom message'
                            : 'Add custom message'}
                    </EditAddLineBtn>
                )}
            <div style={{ paddingTop: '10px' }} />

            {enquiryFormData.enquiry.info?.custom_message &&
            enquiryFormData.enquiry.info &&
            enquiryFormData.enquiry.info.selected_enquiry &&
            enquiryFormData.enquiry.info.selected_enquiry.length > 0 ? (
                <EnquiryCustomMessageText
                    onClick={() => {
                        setIsEditorOpen(true)
                    }}
                    text={enquiryFormData.enquiry.info.custom_message}
                />
            ) : null}

            <EnquityFormPaddingBottomDesktop />

            <EnquiryBtnsOnlyMobile>
                <BottomBarBtnsStep
                    cancel={() => {
                        navigate(`/showroom/${enquiryFormData.entry_id}`)
                    }}
                    submit={() => {
                        manageStep('contact')
                    }}
                    confirmationtext="continue"
                    disabled={checkIfBtnISDisabled()}
                />
            </EnquiryBtnsOnlyMobile>
        </div>
    )
}

export default CarEnquiryInfoForm
