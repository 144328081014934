import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import CustomIconTagQuickFilterItem from '../../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import FadedSlower from '../../../templates/animated/FadedSlower'
import Expander from '../../../atoms/expander/expander'
import SearchIcon from '../../../atoms/statefulicons/SearchIcon'
import FullWidthLightGreyBgButton from '../../../atoms/Button/fullWidthLightGreyBg'
import { motion } from 'framer-motion'
import { device } from '../../../templates/displays/devices'
import { IEditOrCreateModeSingleFieldsProps } from '../../../molecules/editOrCreateModeSingleFields/types'
import { ILabel, ITimelineItem } from '../../../../redux/timeline/types'
import { useAppSelector } from '../../../../redux/store/hooks'

type IStyle = {
    selected?: boolean
    issearchactive?: boolean
    top?: number
}

const SearchFieldWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 10px;
    padding-top: 20px;
`

const SearchField = styled.input<IStyle>`
    border: ${(props) =>
        props.issearchactive
            ? '1px solid var(--primary, #5ec3ca)'
            : '1px solid var(--border-muted, #e5e5e5)'};
    border-radius: 5px;
    font-family: Lato;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);

    padding-left: 40px;
    ::placeholder {
        color: var(--text-muted);
    }
    :active {
        border: 1px solid var(--primary, #5ec3ca);
    }
`

const SearchIconWrapper = styled.div<IStyle>`
    position: absolute;
    z-index: 2;
    margin-top: 18px;
    margin-left: 15px;
`

const DropdownOptions = styled.ul`
    position: relative;
    z-index: 2;
    margin: 0px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
    max-height: 96vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    text-transform: capitalize;
    padding-bottom: 100px;
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: transparent;
    padding-left: 20px;
`

const StyledOptionBtn = styled.li<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: transparent;
    padding-left: 20px;
    color: var(--primary, #5ec3ca);
    font-size: 16px;
    background-color: var(--background_primary_soft);
    border-radius: 5px;
`

const Count = styled.div`
    padding-left: 20px;
    padding-top: 12px;
    font-style: italic;
    color: var(--text-muted);
    font-family: 'Lato-Light';
    font-size: 12px;
    text-transform: lowercase;
`

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    min-height: 40vh;
    max-height: 80vh;
    overflow: hidden;
    z-index: 2;
    @media ${device.ipad} {
        padding-left: 25px;
    }
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
`

const SelectedCriteriaWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 0px;
`

export type Props = {
    items?: any
    searchFor?: any
    addTagFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    labels_searchable_list?: any
    value?: any
    handleChange?: any
    fetchItem?: any
    labelsDataRes?: any
    hasInnerSaveBtn?: boolean
    addToEntry?: any
    editData?: IEditOrCreateModeSingleFieldsProps
    clearField?: any
}

const LabelsFilterByMultipleWithSearchUIMobile: React.FC<Props> = ({
    dataCyId,
    labelsDataRes,
    handleChange,
    fetchItem,
    value,
    hasInnerSaveBtn,
    labels_searchable_list,
    addToEntry,
    editData,
    clearField,
}) => {
    const [addedCriteria, setAddedCriteria] = useState<string[]>([])
    const [issearchactive, setIssearchactive] = useState(false)
    const [showAllOptions, setShowAllOptions] = useState(false)

    const activeEntry: ITimelineItem | undefined = useAppSelector(
        (state) => state.timeline.activeEntry
    )

    const dropdowRef = useRef<HTMLDivElement>(null)
    const searchFieldRef = useRef<HTMLInputElement>(null)

    const handleClickOutsideSearch = useCallback((e: MouseEvent) => {
        if (
            searchFieldRef.current &&
            !searchFieldRef.current.contains(e.target as Node)
        ) {
            setIssearchactive(false)
        }
    }, [])

    useEffect(() => {
        dropdowRef.current?.focus()

        document.addEventListener('mousedown', handleClickOutsideSearch)

        const valueStart = activeEntry?.labels?.map(
            (label: ILabel) => label.name
        )
        if (valueStart) {
            setAddedCriteria(valueStart)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSearch)
        }
    }, [])

    useEffect(() => {
        const valueStart = activeEntry?.labels?.map(
            (label: ILabel) => label.name
        )
        if (valueStart) {
            setAddedCriteria(valueStart)
        }
    }, [activeEntry])

    const addOrRemoveCriteria = useCallback(
        (criteria: string) => {
            setAddedCriteria((prev) => {
                const indexOfClickedCriteria = prev.indexOf(criteria)
                let newCriteria: string[]

                if (indexOfClickedCriteria < 0) {
                    newCriteria = [...prev, criteria]
                } else {
                    newCriteria = [...prev]
                    newCriteria.splice(indexOfClickedCriteria, 1)
                }

                const submittedLabels = newCriteria.map((criteria) => ({
                    name: criteria,
                }))

                if (editData) {
                    addToEntry(
                        editData.id,
                        submittedLabels,
                        editData.submitCreationConfigs?.entityType,
                        editData.submitCreationConfigs?.action
                    )
                }

                return newCriteria
            })
        },
        [editData, addToEntry]
    )

    const addedCriteriaElements = addedCriteria.map(
        (criteria: string, i: number) => (
            <div style={{ padding: '3px' }} key={`${criteria} - ${i}`}>
                <FadedSlower duration={0.2}>
                    <CustomIconTagQuickFilterItem
                        isActive={true}
                        onCrossClick={() => addOrRemoveCriteria(criteria)}
                        border="1px solid var(--border-muted, #e5e5e5)"
                        height="25px"
                        bgcolor="var(--bg-color, #fff)"
                        isLabel
                    >
                        {criteria}
                    </CustomIconTagQuickFilterItem>
                </FadedSlower>
            </div>
        )
    )

    const mappedTagsData = labelsDataRes?.map((item: any, index: number) => {
        const label = item.item
        if (label && index < 3) {
            return (
                <StyledOption
                    selected={addedCriteria.includes(label.name)}
                    value={label.name}
                    key={`${label.name}-label-${index}`}
                >
                    <ContentRow
                        style={{
                            justifyContent: 'space-between',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        {label.name}
                        <CustomAnimatedCheckboxField
                            onClick={() => addOrRemoveCriteria(label.name)}
                            checked={addedCriteria.includes(label.name)}
                        />
                    </ContentRow>
                </StyledOption>
            )
        }
        return <StyledOption key={index} style={{ display: 'none' }} />
    })

    const show_all_options = () => {
        return (
            <>
                {labels_searchable_list.map((label: any, index: number) => {
                    if (!showAllOptions && index >= 10) {
                        return (
                            <div
                                key={`no-label-${index}`}
                                style={{ display: 'none' }}
                            />
                        )
                    }
                    return (
                        <React.Fragment key={`searchable-label-no-${index}`}>
                            <StyledOption
                                selected={addedCriteria.includes(label.name)}
                                value={label.name}
                            >
                                <ContentRow
                                    style={{
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    {label.name}
                                    <CustomAnimatedCheckboxField
                                        onClick={() =>
                                            addOrRemoveCriteria(label.name)
                                        }
                                        checked={addedCriteria.includes(
                                            label.name
                                        )}
                                    />
                                </ContentRow>
                            </StyledOption>
                        </React.Fragment>
                    )
                })}
                {!showAllOptions && labels_searchable_list.length > 10 && (
                    <Count
                        onClick={() => setShowAllOptions(true)}
                        style={{ cursor: 'pointer' }}
                    >
                        +{labels_searchable_list.length - 10} more
                    </Count>
                )}
            </>
        )
    }

    const labNamesSearchableList = labels_searchable_list.map(
        (item: any) => item.name
    )
    const labNamesReturnedResults = labelsDataRes.map(
        (item: any) => item.item.name
    )

    const checkIfShowAddLabel = () => {
        if (
            !labNamesSearchableList.includes(value[0]) &&
            !labNamesReturnedResults.includes(value[0])
        ) {
            return (
                <FadedSlower>
                    <motion.div
                        style={{ paddingTop: '20px' }}
                        whileTap={{ scale: 1.1 }}
                    >
                        <StyledOptionBtn
                            selected={false}
                            value={value[0]}
                            key={`${value[0]}-label-${0}`}
                            onClick={() => {
                                addOrRemoveCriteria(value[0])
                                clearField?.()
                            }}
                        >
                            <ContentRow>
                                + Create new label: "{value[0]}"
                            </ContentRow>
                        </StyledOptionBtn>
                    </motion.div>
                </FadedSlower>
            )
        }
    }

    return (
        <Wrapper data-attr={dataCyId} ref={dropdowRef}>
            <DropdownOptions>
                <Expander height={addedCriteria.length > 0 ? 'auto' : 0}>
                    <SelectedCriteriaWrapper>
                        {addedCriteriaElements}
                    </SelectedCriteriaWrapper>
                </Expander>

                <SearchFieldWrapper onClick={() => setIssearchactive(true)}>
                    <SearchIconWrapper>
                        <SearchIcon height={16} isActive={issearchactive} />
                    </SearchIconWrapper>
                    <SearchField
                        placeholder={'Search through your labels or create new'}
                        type="search"
                        onChange={(e: any) => {
                            handleChange(e.target.value)
                            fetchItem(e.target.value)
                        }}
                        issearchactive={issearchactive}
                        value={value[0]}
                        ref={searchFieldRef}
                    />
                </SearchFieldWrapper>

                <div style={{ paddingTop: '10px' }} />

                {labelsDataRes && labelsDataRes.length > 0 && mappedTagsData}

                <FadedSlower>
                    {labelsDataRes.length === 0 && show_all_options()}
                </FadedSlower>

                {value &&
                    value[0] !== '' &&
                    value[0] !== undefined &&
                    value[0].trim().length > 1 &&
                    checkIfShowAddLabel()}
                {hasInnerSaveBtn && (
                    <div
                        style={{
                            padding: '20px',
                            paddingTop: '10px',
                            paddingBottom: '0px',
                        }}
                    >
                        <FullWidthLightGreyBgButton
                            text="Save"
                            onClick={() => {}}
                        />
                    </div>
                )}
            </DropdownOptions>
        </Wrapper>
    )
}

export default LabelsFilterByMultipleWithSearchUIMobile
