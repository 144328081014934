import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import {
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'

import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import LandingPageMainButton from '../../../../../atoms/Button/landingPageMainButton'
import VehicleItemDesktop from '../../../../../atoms/list/vehicleItemDesktop'
import VehicleItemMobile from '../../../../../atoms/list/vehicleItemMobile'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import { device } from '../../../../../templates/displays/devices'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import InfoIcon from '../../../../../atoms/icons/infoIcon'
import useThemes from '../../../../../../providers/theme/hooks'
import colours, {
    insurance_colours,
} from '../../../../../../providers/theme/colours'

const Text = styled.div`
    color: #666;
    /* Mobile/Text/T300 Regular */
    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
    width: 100%;
    box-sizing: border-box;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const InfoBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 16px;
    padding: 16px;
    margin-top: 24px;
    border-radius: 4px;
`

type Props = {
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const VehiclesToInsureListWizard = (props: Props) => {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()

    let carsData = useAppSelector((state) => state.entities.carsData.cars)

    let selectedVehiclesToInsure = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.draft.vehicles_to_insure_ids_list
    )

    let {
        getFirstIncompleteSubsection_InsuredVehicle,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    //draft as faster

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.draft
    )

    let cleanedUpCarIds: string[] | undefined =
        carsData &&
        Object.keys(carsData).filter((carid) => {
            if (
                carsData[carid].archived_on === undefined &&
                carid !== 'new_car'
            )
                return carid
        })

    let goNext = () => {
        let ids = getFirstIncompleteSubsection_InsuredVehicle()

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return navigate(
                {
                    search: params2.toString(),
                },
                { replace: true }
            )
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('vehicles_to_insure')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                navigate(
                    {
                        search: params2.toString(),
                    },
                    { replace: true }
                )
            } else {
                return navigate(`/insurance/application/${applicationData?.id}`)
            }
        }

        return getToAny()
    }

    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onSave = () => {
        if (
            !applicationData?.vehicles_to_insure_ids_list ||
            (applicationData?.vehicles_to_insure_ids_list &&
                applicationData?.vehicles_to_insure_ids_list.length === 0)
        ) {
            setIsDisabled(true)
        } else {
            dispatch(insuranceActions.submit_vehiclesToInsure_request())

            setTimeout(() => {
                goNext()
            }, 500)
        }
    }

    let goPrevious = () => {
        navigate(
            `/insurance/application/${applicationData?.id}/wizard?section_id=insurance_details`
        )
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'vehicles_to_insure',
            onSave: () => onSave(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    {cleanedUpCarIds && cleanedUpCarIds.length > 0 ? (
                        <>
                            <Text>
                                Choose the cars from your garage that you would
                                like to insure. Make your selections below and
                                let's get started!
                            </Text>
                            <InfoBox
                                style={{
                                    backgroundColor:
                                        colours[theme].background_default,
                                }}
                            >
                                <InfoIcon
                                    color={
                                        insurance_colours[theme].sections[
                                            'vehicles_to_insure'
                                        ].primary_100
                                    }
                                />
                                <Text
                                    style={{
                                        color: insurance_colours[theme]
                                            .sections['vehicles_to_insure']
                                            .primary_text,
                                        lineHeight: '20px',
                                    }}
                                >
                                    You’ll be able to assign drivers to selected
                                    cars in later steps.
                                </Text>
                            </InfoBox>
                        </>
                    ) : (
                        <Text>
                            <Text>
                                Before insuring a vehicle, please make sure it's
                                added to your garage. Your quote progress will
                                be saved, and you can easily continue the quote
                                flow afterward.
                            </Text>
                        </Text>
                    )}
                    <div style={{ paddingTop: 40 }} />
                    {cleanedUpCarIds && cleanedUpCarIds.length > 0 ? (
                        cleanedUpCarIds.map((car_id, i) => {
                            if (carsData[car_id].archived_on === undefined) {
                                return (
                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            paddingBottom: 16,
                                        }}
                                        key={`${car_id}_${i}_desktop`}
                                    >
                                        <VehicleItemDesktop
                                            garageItem={carsData[car_id]}
                                            showGarageCarTitle
                                            car_id={car_id}
                                            variant="selectable"
                                            isSelected={
                                                selectedVehiclesToInsure.includes(
                                                    car_id
                                                )
                                                    ? true
                                                    : false
                                            }
                                            onSelect={(id: string) => {
                                                if (isDisabled) {
                                                    setIsDisabled(false)
                                                }

                                                if (hasFormChanged === false) {
                                                    setHasFormChanged(true)
                                                    props.setTopWizardCheckActions(
                                                        {
                                                            sectionID:
                                                                'vehicles_to_insure',
                                                            onSave: () =>
                                                                onSave(),
                                                            has_changes: true,
                                                        }
                                                    )
                                                }

                                                let selected_ids =
                                                    selectedVehiclesToInsure
                                                        ? selectedVehiclesToInsure.includes(
                                                              id
                                                          )
                                                            ? selectedVehiclesToInsure.filter(
                                                                  (id: any) =>
                                                                      car_id !==
                                                                      id
                                                              )
                                                            : [
                                                                  ...selectedVehiclesToInsure,
                                                                  id,
                                                              ]
                                                        : [id]
                                                dispatch(
                                                    insuranceActions.select_vehicles_to_insure(
                                                        selected_ids
                                                    )
                                                )
                                            }}
                                        />
                                    </div>
                                )
                            } else return null
                        })
                    ) : (
                        <LandingPageMainButton
                            onClick={() => {
                                navigate('/garage')
                            }}
                            normal_height="48px"
                            desktop_height="48px"
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textTransform: 'none',
                                    fontFamily: 'Lato',
                                    letterSpacing: 'normal',
                                }}
                            >
                                Add car to your garage
                            </div>
                        </LandingPageMainButton>
                    )}
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    onSave={() => {
                        onSave()
                    }}
                    hasFormChanged={hasFormChanged}
                    goBack={() => goPrevious()}
                    isSaveDisabled={isDisabled}
                    sectionId="vehicles_to_insure"
                />
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '88vw',
                        paddingBottom: 120,
                        backgroundColor:
                            colours[theme].background_neutral_subtle,
                    }}
                >
                    {cleanedUpCarIds && cleanedUpCarIds.length > 0 ? (
                        <>
                            <Text>
                                Choose the cars from your garage that you would
                                like to insure. Make your selections below and
                                let's get started!
                            </Text>
                            <InfoBox
                                style={{
                                    backgroundColor:
                                        colours[theme].background_default,
                                }}
                            >
                                <InfoIcon
                                    color={
                                        insurance_colours[theme].sections[
                                            'vehicles_to_insure'
                                        ].primary_100
                                    }
                                />
                                <Text
                                    style={{
                                        color: insurance_colours[theme]
                                            .sections['vehicles_to_insure']
                                            .primary_text,
                                        lineHeight: '20px',
                                    }}
                                >
                                    You’ll be able to assign drivers to selected
                                    cars in later steps.
                                </Text>
                            </InfoBox>
                        </>
                    ) : (
                        <Text>
                            Before insuring a vehicle, please make sure it's
                            added to your garage. Your quote progress will be
                            saved, and you can easily continue the quote flow
                            afterward.
                        </Text>
                    )}
                    <div style={{ paddingTop: 40 }} />
                    {cleanedUpCarIds && cleanedUpCarIds.length > 0 ? (
                        cleanedUpCarIds.map((car_id, i) => (
                            <div
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    paddingBottom: 16,
                                }}
                                key={`${car_id}_${i}_mobile`}
                            >
                                <VehicleItemMobile
                                    garageItem={carsData[car_id]}
                                    variant="selectable"
                                    showGarageCarTitle
                                    isSelected={
                                        selectedVehiclesToInsure.includes(
                                            car_id
                                        )
                                            ? true
                                            : false
                                    }
                                    car_id={car_id}
                                    onSelect={(id: string) => {
                                        if (isDisabled) {
                                            setIsDisabled(false)
                                        }
                                        let selected_ids =
                                            selectedVehiclesToInsure
                                                ? selectedVehiclesToInsure.includes(
                                                      id
                                                  )
                                                    ? selectedVehiclesToInsure.filter(
                                                          (id) => car_id !== id
                                                      )
                                                    : [
                                                          ...selectedVehiclesToInsure,
                                                          id,
                                                      ]
                                                : [id]
                                        dispatch(
                                            insuranceActions.select_vehicles_to_insure(
                                                selected_ids
                                            )
                                        )
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <LandingPageMainButton
                            onClick={() => {
                                navigate('/garage')
                            }}
                            normal_height="48px"
                            desktop_height="48px"
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textTransform: 'none',
                                    fontFamily: 'Lato',
                                    letterSpacing: 'normal',
                                }}
                            >
                                Add car to your garage
                            </div>
                        </LandingPageMainButton>
                    )}
                </div>
                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    onSave={() => {
                        onSave()
                    }}
                    isSaveDisabled={isDisabled}
                    isMobile
                    goBack={() => goPrevious()}
                    sectionId="vehicles_to_insure"
                    hasFormChanged={hasFormChanged}
                />
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default VehiclesToInsureListWizard
