import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    ICheckCarActionsResponse,
    ICheckCarActions,
} from '../../../services/typedefinitions/apiPayloads'
import {
    IGetCarActionCheckRequest,
    IGetCarActionCheckSuccess,
    IReduxError,
} from '../types'
import { carActions } from '../reducer'

export function* GetCarActionCheck(p: IGetCarActionCheckRequest) {
    try {
        let res: ICheckCarActionsResponse = yield call(
            api.entities.getData.get_car_action_check_api,
            p
        )

        if (p.action && res) {
            let action_id: ICheckCarActions =
                p.action === 'DELETE'
                    ? 'delete'
                    : p.action === 'HANDOVER'
                    ? 'handover'
                    : 'archive'

            let success_payload: IGetCarActionCheckSuccess = {
                carid: p.carid,
                action_check: {
                    [action_id]: {
                        allowed: res[action_id].allowed,
                        message: res[action_id].message,
                    },
                },
            }
            yield put(carActions.getCarActionCheckSuccess(success_payload))
        } else {
            if (res) {
                // @ts-ignore
                let actions_checks_obj: ICheckCarActionsResponse = {}
                Object.keys(res).forEach((key) => {
                    actions_checks_obj[key as ICheckCarActions] =
                        res[key as ICheckCarActions]
                })

                let success_payload: IGetCarActionCheckSuccess = {
                    carid: p.carid,
                    action_check: {
                        ...actions_checks_obj,
                    },
                }
                yield put(carActions.getCarActionCheckSuccess(success_payload))
            }
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: GetCarActionCheck,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(carActions.getCarActionCheckError(typedError))
            return
        }
    }
}

function* watcherGetCarActionCheck() {
    while (true) {
        let { payload } = yield take(carActions.getCarActionCheckRequest)
        yield call(GetCarActionCheck, payload)
    }
}

const get_car_action_check: any[] = [fork(watcherGetCarActionCheck)]

export default get_car_action_check
