import React from 'react'
import { editFormsActions } from '../../../redux/editForms/reducer'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IDirectShareDeletionConfirmation } from '../../../redux/editForms/types'
import { carActions } from '../../../redux/entities/cars/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { useNavigate } from 'react-router-dom'

const DirectShareDeletionConfirmation: React.FC = () => {
    const dispatch = useAppDispatch()

    const directShareDeletionConfirmation = useAppSelector(
        (state) => state.editForms.directShareDeletionConfirmation
    )

    const toggleShareDeletionConfirmation = (
        p: IDirectShareDeletionConfirmation
    ) => {
        dispatch(editFormsActions.toggleShareDeletionConfirmation(p))
    }

    let navigate = useNavigate()

    const handleDelete = (p: IDirectShareDeletionConfirmation) => {
        if (p.entity_type === 'car') {
            if (p.entity_id && p.share_id) {
                dispatch(
                    carActions.deleteCarDirectShareRequest({
                        entity_id: p.entity_id,
                        share_id: p.share_id,
                    })
                )

                navigate(`/sharing/with-others/car?carid=${p.entity_id}`)
            }
        } else {
            if (p.entity_id && p.share_id) {
                dispatch(
                    garagesActions.deleteGarageDirectShareRequest({
                        entity_id: p.entity_id,
                        share_id: p.share_id,
                    })
                )
                navigate(`/sharing/with-others?entity_id=garage`)
            }
        }
    }

    return (
        <React.Fragment>
            <DeletionConfirmationBottomSheet
                customHeight="240px"
                isOpen={directShareDeletionConfirmation.isOpen}
                toggle={() =>
                    setTimeout(
                        () =>
                            toggleShareDeletionConfirmation({
                                isOpen: false,
                                entity_id: undefined,
                                entity_type: undefined,
                                share_id: undefined,
                            }),
                        50
                    )
                }
                action={() => handleDelete(directShareDeletionConfirmation)}
                actionCopy="Confirm"
                titleCopy="Are you sure?"
                detailsCopy={
                    directShareDeletionConfirmation.entity_type === 'car'
                        ? 'All recipients will lose access to your car.'
                        : 'All recipients will lose access to your garage.'
                }
            />
        </React.Fragment>
    )
}

export default DirectShareDeletionConfirmation
