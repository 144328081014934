import { IJourneyInsuranceStepID } from 'journeyInterfaces'
import { useNavigate } from 'react-router-dom'
import JourneyInsuranceWelcome from '../welcome'
import JourneyInsuranceJoinStep from '../join'
import JourneyInsuranceBenefitsStep from '../benefits'
import useWindowSize, {
    WindowSize,
} from '../../../../templates/displays/windowSizeHook'
import JourneyInsuranceHowItWorksStep from '../howItWorks'
import JourneyInsuranceHowItWorksExtraStepMobile from '../howItWorksExtraStepMobile'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'
import { IUser } from '../../../../../redux/user/types'

type Props = {
    stepID: IJourneyInsuranceStepID
    startJourney: () => void
    exitJourney: () => void
    user: IUser | null
}
export default function InsuranceJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
}: // user,
Props) {
    const navigate = useNavigate()
    let locationState: any = (navigate as any).location?.state

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    switch (stepID) {
        case 'welcome':
            return (
                <JourneyInsuranceWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
        case 'join':
            return (
                <JourneyInsuranceJoinStep
                    step={1}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(`/journey/insurance?step=welcome`)
                    }}
                    goToNextStep={() => {
                        navigate(`/journey/insurance?step=benefits`, {
                            state: {
                                prevPath: `${window.location.pathname}${
                                    window.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )
        case 'benefits':
            return (
                <JourneyInsuranceBenefitsStep
                    step={2}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(`/journey/insurance?step=join`)
                    }}
                    goToNextStep={() => {
                        navigate(`/journey/insurance?step=how_it_works`, {
                            state: {
                                prevPath: `${window.location.pathname}${
                                    window.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'how_it_works':
            return (
                <JourneyInsuranceHowItWorksStep
                    step={3}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(`/journey/insurance?step=benefits`)
                    }}
                    goToNextStep={() => {
                        isMobile
                            ? navigate(
                                  `/journey/insurance?step=how_it_works_mobile_extra`,
                                  {
                                      state: {
                                          prevPath: `${
                                              window.location.pathname
                                          }${window.location.search ?? ''}`,
                                      },
                                  }
                              )
                            : navigate(`/journey/insurance?step=achieved`, {
                                  state: {
                                      prevPath: `${window.location.pathname}${
                                          window.location.search ?? ''
                                      }`,
                                  },
                              })
                    }}
                />
            )

        case 'how_it_works_mobile_extra':
            return (
                <JourneyInsuranceHowItWorksExtraStepMobile
                    step={3}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(`/journey/insurance?step=how_it_works`)
                    }}
                    goToNextStep={() => {
                        navigate(`/journey/insurance?step=achieved`, {
                            state: {
                                prevPath: `${window.location.pathname}${
                                    window.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'achieved':
            return <JourneyAchievedStep sectionID="insurance" />

        default:
            return (
                <JourneyInsuranceWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
