import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { message } from 'antd'
import './antd.css'
import { device } from '../../templates/displays/devices'

const StyledDropzone = styled.div`
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--background_primary_soft);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    :hover {
        background-color: var(--primary_30);
    }
`

const UploadImageBtnText = styled.p`
    width: 100%;
    box-sizing: border-box;
    color: var(--primary, #5ec3ca);
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;

    @media ${device.ipad} {
        font-size: 15px;
    }
`

const errorWarning = () => {
    message.error({
        content: 'Please upload one image.',
        duration: 2,
    })
}

const errorFileSizeWarning = () => {
    message.error({
        content: 'The maximum image size allowed is 20MB.',
        duration: 2,
    })
}

const errorFileTypeWarning = (filename: string, message_text: string) => {
    message.error({
        content: `${filename} is not supported, ${message_text}`,
        duration: 2,
    })
}

type Props = {
    handleFilesGiven: (files: File[]) => void
}

function OnbnoardingAccept(props: Props) {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length === 1) {
                props.handleFilesGiven && props.handleFilesGiven(acceptedFiles)
            }
        },
        [props]
    )

    const onDropRejected = useCallback((fileRejections: any[]) => {
        let showTooManyFilesWarning = false
        for (let i = 0; i < fileRejections.length; i++) {
            if (fileRejections[i].errors) {
                let file = fileRejections[i].file
                let errors: any[] = fileRejections[i].errors
                for (let i = 0; i < errors.length; i++) {
                    if (errors[i].code === 'too-many-files') {
                        showTooManyFilesWarning = true
                    } else {
                        if (errors[i].code === 'file-invalid-type') {
                            errorFileTypeWarning(file.name, errors[i].message)
                        }
                        if (
                            errors[i].code === 'file-too-large' ||
                            errors[i].code === 'file-too-small'
                        ) {
                            errorFileSizeWarning()
                        }
                    }
                }
            }
        }
        if (showTooManyFilesWarning) {
            errorWarning()
        }
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
        isDragActive,
    } = useDropzone({
        onDrop,
        onDropRejected,
        // accept: 'image/jpeg, image/png, image/heic, image/heif',
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/heic': ['.heic'],
            'image/heif': ['.heif'],
        },
        maxFiles: 1,
        minSize: 0,
        maxSize: 20000000,
    })

    return (
        <div className="container" style={{ width: '100%' }}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {isDragAccept && <StyledDropzone>... </StyledDropzone>}

                {isDragReject && <StyledDropzone>... </StyledDropzone>}
                {!isDragActive && (
                    <StyledDropzone>
                        <UploadImageBtnText>
                            Drag & Drop image or press to browse
                        </UploadImageBtnText>
                    </StyledDropzone>
                )}
            </div>
        </div>
    )
}

export default OnbnoardingAccept
