import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { subMenuList, matchPageSubGroup } from '../mainmenu/mainMenuData'
import { motion } from 'framer-motion'
import { checkIfPwaStandalone } from '../../../templates/pwaCustom/checkIpadPwa'
import { checkIfActivePage, genPathname } from '../mainmenu/helpers'
import { device } from '../../../templates/displays/devices'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { useAppSelector } from '../../../../redux/store/hooks'

interface IProps {
    isMainTopHeaderVisible?: boolean
    isExternalCar?: boolean
}

type IStyle = {
    $theme: ITheme
    $islast?: boolean
    $isactive?: boolean
    $isCarPage?: boolean
    $isDisabled?: boolean
    $width?: number
    $isMainTopHeaderVisible?: boolean
}

const Wrapper = styled.div<IStyle>`
    background-color: ${(props) => colours[props.$theme].background_default};
    width: 100vw;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none;
    }
    transition: all 200ms;
    scroll-behavior: smooth;
    height: 70px;

    ${(props) =>
        !props.$isMainTopHeaderVisible &&
        `border-bottom: 1px solid ${colours[props.$theme].border_muted};`};

    @media ${device.mobile} {
        height: 60px;
        ${(props) => props.$isMainTopHeaderVisible && 'height: 50px'};
    }
`

const RowContainer = styled.div<IStyle>`
    background-color: ${(props) => colours[props.$theme].background_default};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: all 200ms;
    height: 100%;
    ${(props) => props.$width && `width: ${props.$width}px;`};
`

const MenuItem = styled(Link)<IStyle>`
    color: ${(props) =>
        props.$isDisabled
            ? `${colours[props.$theme].text_disabled} !important`
            : props.$isactive
            ? `${colours[props.$theme].primary} !important`
            : `${colours[props.$theme].text_darker} !important`};
    width: 100%;
    box-sizing: border-box;
    opacity: 1 !important;
    font-family: 'Lato';
    font-size: 14px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    height: 36px;
    border-radius: 100px;
    background-color: ${(props) =>
        props.$isactive ? colours[props.$theme].primary_20 : 'transparent'};
    ${(props) => props.$isDisabled && 'pointer-events: none;'}
`

const MenuItemAnchor = styled.a<IStyle>`
    color: ${(props) =>
        props.$isDisabled
            ? `${colours[props.$theme].text_disabled} !important`
            : props.$isactive
            ? `${colours[props.$theme].primary} !important`
            : `${colours[props.$theme].text_darker} !important`};
    width: 100%;
    box-sizing: border-box;
    opacity: 1 !important;
    font-family: 'Lato';
    font-size: 14px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    height: 36px;
    border-radius: 100px;
    background-color: ${(props) =>
        props.$isactive ? colours[props.$theme].primary_20 : 'transparent'};
    ${(props) => props.$isDisabled && 'pointer-events: none;'}
`

const MenuItemBody = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
`

const MenuItemBox = styled(motion.div)<IStyle>`
    margin-right: 5px;

    :focus {
        outline: 0;
    }

    transform: translateY(-8px);
`

const TopSubnavigationBarMobile: React.FC<IProps> = ({
    isMainTopHeaderVisible,
    isExternalCar,
}) => {
    const menuRef = useRef<HTMLDivElement>(null)
    const [scrollableDivWidth, setScrollableDivWidth] = useState(0)

    const location = useLocation()

    const currentCarID = useAppSelector(
        (state) => state.entities.carsData.currentCarID
    )
    const currentExternalCarID = useAppSelector(
        (state) => state.entities.carsData.currentCarID_ext_directShare
    )

    const scrollToElement = (e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLElement
        let scroll_left_distance =
            target.offsetLeft + target.offsetWidth / 2 - window.innerWidth / 2

        let containerWidth = target.offsetLeft + target.offsetWidth + 20

        if (menuRef.current) {
            if (scrollableDivWidth < containerWidth) {
                setScrollableDivWidth(containerWidth)
            }
            menuRef.current.scrollLeft = scroll_left_distance
        }
    }

    useEffect(() => {
        const last_menu_element: HTMLElement | null =
            document.getElementById('last_menu_element')

        if (last_menu_element) {
            setScrollableDivWidth(
                last_menu_element.offsetLeft +
                    last_menu_element.offsetWidth +
                    20
            )
        }

        const item: HTMLElement | null =
            document.getElementById('active_menu_item')
        if (item) {
            let distance =
                item.offsetLeft + item.offsetWidth / 2 - window.innerWidth / 2
            if (menuRef.current) {
                menuRef.current.scrollLeft = distance
            }
        }
    }, [scrollableDivWidth, location.pathname])

    const effectiveCarID = isExternalCar ? currentExternalCarID : currentCarID

    let activepage = checkIfPwaStandalone()
        ? location.pathname
        : location.pathname

    let subPageGroup = matchPageSubGroup(activepage)

    const subListItems =
        subPageGroup !== '/tasks' &&
        subPageGroup !== '/history-files' &&
        subPageGroup !== '/insurance/overview'
            ? subMenuList[`${subPageGroup}`]
            : undefined

    const { theme } = useThemes()

    return subListItems !== undefined ? (
        <Wrapper
            $theme={theme}
            ref={menuRef}
            $isMainTopHeaderVisible={isMainTopHeaderVisible}
        >
            <RowContainer
                $theme={theme}
                data-attr="car-sub-menu"
                $width={scrollableDivWidth}
            >
                <div style={{ paddingRight: '20px' }} />
                {subListItems.map((item: any, index: number) => {
                    return subPageGroup === '/showroom' ? (
                        <MenuItemBox
                            $theme={theme}
                            id={
                                checkIfActivePage(
                                    activepage,
                                    item.url,
                                    effectiveCarID
                                )
                                    ? 'active_menu_item'
                                    : index === subListItems.length - 1
                                    ? 'last_menu_element'
                                    : `menu_item_${index}`
                            }
                            onClick={scrollToElement}
                            $isactive={checkIfActivePage(
                                activepage,
                                item.url,
                                effectiveCarID
                            )}
                            key={`top_subnav_mobile_${index}`}
                            whileTap={{
                                opacity: 1,
                            }}
                            $islast={index === subListItems.length - 1}
                        >
                            <MenuItemAnchor
                                $theme={theme}
                                id={
                                    index === subListItems.length - 1
                                        ? 'last_menu_element'
                                        : ``
                                }
                                href={item.url}
                                $isDisabled={false}
                                $isactive={checkIfActivePage(
                                    activepage,
                                    item.url,
                                    effectiveCarID
                                )}
                            >
                                <MenuItemBody>
                                    <span>{item.pageName}</span>
                                </MenuItemBody>
                            </MenuItemAnchor>
                        </MenuItemBox>
                    ) : (
                        <MenuItemBox
                            $theme={theme}
                            id={
                                checkIfActivePage(
                                    activepage,
                                    item.url,
                                    effectiveCarID
                                )
                                    ? 'active_menu_item'
                                    : index === subListItems.length - 1
                                    ? 'last_menu_element'
                                    : `menu_item_${index}`
                            }
                            onClick={scrollToElement}
                            $isactive={checkIfActivePage(
                                activepage,
                                item.url,
                                effectiveCarID
                            )}
                            key={`top_subnav_mobile_${index}`}
                            whileTap={{
                                opacity: 1,
                            }}
                            $islast={index === subListItems.length - 1}
                        >
                            <MenuItem
                                $theme={theme}
                                id={
                                    index === subListItems.length - 1
                                        ? 'last_menu_element'
                                        : ``
                                }
                                to={{
                                    pathname: genPathname(item, effectiveCarID),
                                }}
                                $isDisabled={false}
                                $isactive={checkIfActivePage(
                                    activepage,
                                    item.url,
                                    effectiveCarID
                                )}
                            >
                                <MenuItemBody>
                                    <span>{item.pageName}</span>
                                </MenuItemBody>
                            </MenuItem>
                        </MenuItemBox>
                    )
                })}
            </RowContainer>
        </Wrapper>
    ) : null
}

export default TopSubnavigationBarMobile
