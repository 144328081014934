import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Sheet } from 'react-modal-sheet'
import { menuActions } from '../../../../redux/menus/reducer'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { checkIfPwaStandalone } from '../../../templates/pwaCustom/checkIpadPwa'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import {
    IAccountDropdownOption,
    accountSubPages,
} from '../mainmenu/topSubMenuDesktop'
import { renderAccountIcons } from '../../icons/components/account'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { usersActions } from '../../../../redux/user/reducer'
import { useLocation, useNavigate } from 'react-router-dom'

type IStyle = { $theme: ITheme }

const CustomSheet = styled(Sheet)<IStyle>`
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        /* height: 70vh !important; */
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
        height: fit-content !important;

        /* @media (min-device-height: 600px) {
            height: 70vh !important;
        }

        @media (min-device-height: 700px) {
            height: 64vh !important;
        }

        @media (min-device-height: 800px) {
            height: 58vh !important;
        }

        @media (min-device-height: 1000px) {
            height: 45vh !important;
        }

        @media (max-device-height: 600px) {
            height: 80vh !important;
        } */
    }
    .react-modal-sheet-header {
        height: 20px !important;
        background: ${(props) =>
            colours[props.$theme].modal_side_sheet_pop_over};
    }
    .react-modal-sheet-drag-indicator {
        width: 30px !important;
        background-color: ${(props) =>
            colours[props.$theme].text_darker} !important;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`
const Dropdown = styled.div<IStyle>`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    background: ${(props) => colours[props.$theme].modal_side_sheet_pop_over};
    padding-bottom: 24px;
`

const DropdownOption = styled.div<IStyle>`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 40px;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    :hover {
        background-color: ${(props) =>
            colours[props.$theme].modal_side_sheet_pop_over};
        transition: all 200ms;
    }
`

const DropdownText = styled.p<IStyle>`
    margin: 0px;
    padding: 0px;
    color: ${(props) => colours[props.$theme].text_darker};
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const AccountIconWrapper = styled.div<IStyle>`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
`

const BottomGreyLine = styled.div<IStyle>`
    width: 240px;
    height: 4px;
    border-radius: 5px;
    background: ${(props) => colours[props.$theme].text_darker};
    margin: 0 auto;
    margin-bottom: 8px;
`

interface IProps {
    // togglePublicMobileMenu: any;
    activepage: string
}

const renderOptions = (p: {
    options: IAccountDropdownOption[]
    onOptionClick: (option: IAccountDropdownOption) => void
    currentPath: string
    theme: ITheme
}) => {
    return p.options && p.options.length > 0 ? (
        <Dropdown $theme={p.theme}>
            {p.options.map((option, i) => {
                const isActive = p.currentPath === option.url ? true : false
                return (
                    <DropdownOption
                        $theme={p.theme}
                        key={`mobile_${option.id}`}
                        role="button"
                        onClick={() => p.onOptionClick(option)}
                    >
                        <AccountIconWrapper
                            $theme={p.theme}
                            style={
                                isActive
                                    ? {
                                          backgroundColor:
                                              colours[p.theme].primary_underlay,
                                      }
                                    : {}
                            }
                        >
                            {renderAccountIcons(
                                option.id,
                                isActive ? colours[p.theme].primary : undefined
                            )}
                        </AccountIconWrapper>
                        <DropdownText
                            $theme={p.theme}
                            style={
                                isActive
                                    ? { color: colours[p.theme].primary }
                                    : {}
                            }
                        >
                            {option.pageName}
                        </DropdownText>
                    </DropdownOption>
                )
            })}
        </Dropdown>
    ) : null
}

const AccountMenuMobile: React.FC<IProps> = (props) => {
    const location = useLocation()

    let currentPath = checkIfPwaStandalone()
        ? window.location.pathname
        : location.pathname

    const isMobileAccountMenuOpen = useAppSelector(
        (state) => state.menus.mobileAccountMenu.isOpen
    )
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const toggle = (p: boolean) =>
        dispatch(menuActions.toggleMobileAccountMenu(p))

    const onAccountOptionClick = (option: IAccountDropdownOption) => {
        if (option.id === 'logout') {
            dispatch(usersActions.logoutRequest())
        } else {
            navigate(option.url)
        }
        toggle(false)
    }

    const { theme } = useThemes()

    useEffect(() => {
        if (isMobileAccountMenuOpen) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [isMobileAccountMenuOpen])

    return (
        <IpadAndMobileDisplay>
            <CustomSheet
                $theme={theme}
                isOpen={isMobileAccountMenuOpen}
                onClose={() => toggle(false)}
            >
                <Sheet.Container>
                    <Sheet.Header />

                    <div
                        style={{
                            backgroundColor:
                                colours[theme].modal_side_sheet_pop_over,
                            paddingTop: '0px',
                        }}
                        data-attr="account-menu"
                    >
                        {renderOptions({
                            options: accountSubPages,
                            onOptionClick: onAccountOptionClick,
                            currentPath: currentPath,
                            theme: theme,
                        })}
                        <BottomGreyLine $theme={theme} />
                    </div>
                </Sheet.Container>
                <Sheet.Backdrop
                    data-attr="account-menu-backdrop-overlay"
                    onTap={(e: any) => {
                        e.preventDefault()
                        setTimeout(function () {
                            toggle(false)
                        }, 50)
                    }}
                />
            </CustomSheet>
        </IpadAndMobileDisplay>
    )
}

export default AccountMenuMobile
