import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import {
    JourneyBtnContent,
    JourneyCardDesktop,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../templates/styledcomponents/journeyStyles'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import BgRadioInput from '../../../molecules/editOrCreateModeSingleFields/bgRadioInputs'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import UserPlainIcon from '../../../atoms/icons/components/userPlainIcon'
import placeholder from '../../../atoms/skeleton/gallery/cover_image.jpg'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import InfoBox from '../../../atoms/info/infoBox'
import { IJourneySectionID } from 'journeyInterfaces'
import { editFormsActions } from '../../../../redux/editForms/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import AddACarFormDesktop from '../../../organisms/editForms/addACarForm/addACarFormDesktop'
import AddACarFormMobile from '../../../organisms/editForms/addACarForm/addACarFormMobile'
import ModalDisplay from '../../../templates/displays/pageWrappers/modalDisplay'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import ModalDisplayAbsolute from '../../../templates/displays/pageWrappers/modalDisplayAbsolute'
import { ICarsObject } from '../../../../redux/entities/cars/types'
import { IGalleryImagesObject } from '../../../../redux/entities/galleries/types'
import { IUser } from '../../../../redux/user/types'
import { usersActions } from '../../../../redux/user/reducer'
import { useNavigate } from 'react-router-dom'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: (carid: string) => void
    carsData?: ICarsObject
    garageCars?: string[]
    galleryImages?: IGalleryImagesObject | null
    user: IUser | null
    sectionID: IJourneySectionID
}

type IStyle = {
    $theme: ITheme
}

const CardContent = styled.section`
    display: flex;
    width: 592px;
    box-sizing: border-box;
    padding: 56px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
`

const CardTitle = styled.h3<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 22px;
    font-family: Lato-semibold;
    line-height: 32px;
`

const CardSubtitle = styled.p<IStyle>`
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
`

const CardImageWrapper = styled.div`
    height: 360px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
`

export default function JourneySelectCar({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    carsData,
    garageCars,
    galleryImages,
    user,
    sectionID,
}: Props) {
    const { theme } = useThemes()
    const [selectedCarId, setSelectedCarId] = useState<string | undefined>(
        undefined
    )
    let cars_array = garageCars
        ? garageCars.map((carid) => {
              let car = carsData && carsData[carid]
              return {
                  uid: car?.id ?? '',
                  name: car?.title ?? '',
              }
          })
        : []

    const continueOnEnter = (e: KeyboardEvent) => {
        if (selectedCarId && e.key === 'Enter') {
            goToNextStep(selectedCarId)
        }
    }

    const dispatch = useAppDispatch()

    const garage_id = useAppSelector((state) => {
        return state.user.userLoggedIn?.owns_garage?.uid
    })
    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })
    const countriesdropdownlist = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    const isAddACarFormOpened = useAppSelector((state) => {
        return state.editForms.formsData.addCarForm.isOpen
    })

    useEffect(() => {
        if (selectedCarId) {
            document.addEventListener('keypress', continueOnEnter)
        }
        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [selectedCarId])

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    let selectedCarCoverImgId =
        carsData && selectedCarId && carsData[selectedCarId].gallery.cover
    let selectedCarCoverImg =
        galleryImages &&
        selectedCarCoverImgId &&
        galleryImages[selectedCarCoverImgId]

    const [isImgLoading, setIsImgLoading] = useState(false)

    const addCar = () => {
        if (!countriesdropdownlist) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
        dispatch(editFormsActions.toggleAddACarForm(true))
    }

    const navigate = useNavigate()

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section[sectionID]
                                        .primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[sectionID]
                                        .primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        Select the car from your garage
                                    </JourneyStepTitle>
                                </JourneyColumnContent>
                                {cars_array.length > 0 ? (
                                    <JourneyText $theme={theme}>
                                        You have multiple cars in your garage
                                        with missing details. Let's take the
                                        first step by selecting the car you'd
                                        like to focus on.
                                        {sectionID === 'history_file' ? (
                                            <p style={{ paddingTop: 16 }}>
                                                There are many advantages to
                                                digitising your cars history
                                                file. From searching through all
                                                your cars documents wherever you
                                                are, to protecting important
                                                service information for future
                                                generations.
                                            </p>
                                        ) : (
                                            <p style={{ paddingTop: 16 }}>
                                                Remember, you can apply the same
                                                enhancements to your other cars
                                                later on.
                                            </p>
                                        )}
                                    </JourneyText>
                                ) : (
                                    <JourneyText $theme={theme}>
                                        To get started with this journey, you’ll
                                        need to add a car to your garage first.
                                        Having a car in your garage is necessary
                                        to complete this flow. Add your car now
                                        to continue!
                                    </JourneyText>
                                )}
                                {cars_array.length > 0 ? (
                                    <BgRadioInput
                                        options={cars_array}
                                        onChange={(uid: string) => {
                                            setSelectedCarId(uid)
                                            selectedCarCoverImgId &&
                                                setIsImgLoading(true)
                                        }}
                                        customActiveRadioColour={
                                            journey_colours[theme].section[
                                                sectionID
                                            ].primary_500
                                        }
                                        customActiveBgColour={
                                            journey_colours[theme].section[
                                                sectionID
                                            ].primary_500_08
                                        }
                                        value={selectedCarId}
                                    />
                                ) : (
                                    <StandardCtaBtn
                                        onClick={addCar}
                                        bg={
                                            journey_colours[theme].section[
                                                sectionID
                                            ].primary_500
                                        }
                                        customHoverColor={
                                            journey_colours[theme].section[
                                                sectionID
                                            ].primary_600
                                        }
                                    >
                                        Add car to your garage now
                                    </StandardCtaBtn>
                                )}
                            </JourneyColumnContent>
                            {cars_array.length > 0 ? (
                                <JourneyRowContent style={{ gap: 32 }}>
                                    <StandardCtaBtn
                                        onClick={() => {
                                            selectedCarId &&
                                                goToNextStep(selectedCarId)
                                        }}
                                        isDisabled={!selectedCarId}
                                        bg={
                                            journey_colours[theme].section[
                                                sectionID
                                            ].primary_500
                                        }
                                        customHoverColor={
                                            journey_colours[theme].section[
                                                sectionID
                                            ].primary_600
                                        }
                                    >
                                        Continue
                                    </StandardCtaBtn>
                                    {selectedCarId ? (
                                        <JourneyRowContent style={{ gap: 8 }}>
                                            <JourneyIconWrapper $theme={theme}>
                                                <EnterIcon />
                                            </JourneyIconWrapper>{' '}
                                            <JourneyEnterTxt $theme={theme}>
                                                Or press Enter
                                            </JourneyEnterTxt>
                                        </JourneyRowContent>
                                    ) : null}
                                </JourneyRowContent>
                            ) : null}
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <JourneyCardDesktop
                                $theme={theme}
                                $customBorderRadius="24px"
                                $customWidth="600px"
                            >
                                <CardContent>
                                    <JourneyColumnContent style={{ gap: 24 }}>
                                        <CardTitle
                                            $theme={theme}
                                            style={
                                                selectedCarId
                                                    ? {
                                                          color: colours[theme]
                                                              .text_darker,
                                                      }
                                                    : {}
                                            }
                                        >
                                            {selectedCarId && carsData
                                                ? carsData[selectedCarId].title
                                                : 'Select car'}
                                        </CardTitle>
                                        <CardSubtitle $theme={theme}>
                                            <JourneyIconWrapper
                                                $theme={theme}
                                                style={{
                                                    backgroundColor:
                                                        colours[theme]
                                                            .background_neutral_overlay_02,
                                                    border: `1px solid ${colours[theme].background_neutral_overlay_40}`,
                                                    borderRadius: 6,
                                                }}
                                            >
                                                <UserPlainIcon
                                                    size="16"
                                                    color={
                                                        colours[theme]
                                                            .text_neutral_default
                                                    }
                                                />
                                            </JourneyIconWrapper>
                                            {`Owned by ${user?.display_name}`}
                                        </CardSubtitle>
                                    </JourneyColumnContent>
                                    <CardImageWrapper>
                                        {isImgLoading ? (
                                            <SkeletonAbsolute isthingloading />
                                        ) : null}
                                        <Image
                                            src={
                                                selectedCarCoverImg
                                                    ? selectedCarCoverImg.url
                                                    : placeholder
                                            }
                                            srcSet={
                                                selectedCarCoverImg
                                                    ? selectedCarCoverImg.medium_srcset
                                                    : undefined
                                            }
                                            onLoad={() => {
                                                setIsImgLoading(false)
                                            }}
                                        />
                                    </CardImageWrapper>
                                </CardContent>
                            </JourneyCardDesktop>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
                {isAddACarFormOpened &&
                countriesdropdownlist &&
                userLoggedIn &&
                garage_id ? (
                    <ModalDisplayAbsolute
                        isOpen={isAddACarFormOpened}
                        toggle={() => {}}
                    >
                        <AddACarFormDesktop
                            countriesdropdownlist={countriesdropdownlist}
                            userCountry={
                                userLoggedIn.address &&
                                userLoggedIn.address.country
                            }
                            close={() =>
                                dispatch(
                                    editFormsActions.toggleAddACarForm(false)
                                )
                            }
                            garage_id={garage_id}
                            numberOfCarsInGarage={0}
                            dataCyId={'addCar-form-desktop'}
                            dispatch={dispatch}
                            navigate={navigate}
                        />
                    </ModalDisplayAbsolute>
                ) : null}
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingBottom: 200,
                            paddingTop: 48,
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <JourneyStepTitle $theme={theme}>
                                        Select the car from your garage
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        {sectionID === 'history_file'
                                            ? "Bring your car's history online"
                                            : 'Create a digital profile for your car'}
                                    </JourneyText>
                                </JourneyColumnContent>
                                {cars_array.length > 0 ? (
                                    <JourneyText $theme={theme}>
                                        {sectionID === 'history_file'
                                            ? "Let's take the first step by selecting the car you'd like to focus on."
                                            : "You have multiple cars in your garage with missing details. Let's take the first step by selecting the car you'd like to focus on."}
                                    </JourneyText>
                                ) : (
                                    <JourneyText $theme={theme}>
                                        To get started with this journey, you’ll
                                        need to add a car to your garage first.
                                        Having a car in your garage is necessary
                                        to complete this flow. Add your car now
                                        to continue!
                                    </JourneyText>
                                )}
                            </JourneyColumnContent>
                            {cars_array.length > 0 ? (
                                <BgRadioInput
                                    options={cars_array}
                                    onChange={(uid: string) => {
                                        setSelectedCarId(uid)
                                        selectedCarCoverImgId &&
                                            setIsImgLoading(true)
                                    }}
                                    customPassiveBgColour={
                                        journey_colours[theme].section[
                                            sectionID
                                        ].primary_500_16
                                    }
                                    customActiveBgColour={
                                        journey_colours[theme].section[
                                            sectionID
                                        ].primary_500_16
                                    }
                                    customActiveRadioColour={
                                        journey_colours[theme].section[
                                            sectionID
                                        ].primary_500
                                    }
                                    value={selectedCarId}
                                />
                            ) : (
                                <StandardCtaBtn
                                    onClick={addCar}
                                    bg={
                                        journey_colours[theme].section[
                                            sectionID
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            sectionID
                                        ].primary_600
                                    }
                                >
                                    Add car to your garage now
                                </StandardCtaBtn>
                            )}
                            {cars_array.length > 0 ? (
                                <InfoBox
                                    variant="info"
                                    removeBg
                                    border={`1px solid ${journey_colours[theme].section[sectionID].primary_400}`}
                                    text="Placeholder text is a temporary stand-in for actual content. You can replace it with your own text or information when you're ready."
                                />
                            ) : null}
                        </JourneyColumnContent>
                    </div>
                    {!isAddACarFormOpened && (
                        <JourneyBottomActionBarMobile
                            sectionID={sectionID}
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={goToPrevStep}
                            onNext={() => {
                                selectedCarId && goToNextStep(selectedCarId)
                            }}
                            isNextDisabled={!selectedCarId}
                        />
                    )}
                </Faded>
                {isAddACarFormOpened &&
                countriesdropdownlist &&
                userLoggedIn &&
                garage_id ? (
                    <ModalDisplay
                        isOpen={isAddACarFormOpened}
                        toggle={() =>
                            dispatch(editFormsActions.toggleAddACarForm(false))
                        }
                    >
                        <AddACarFormMobile
                            countriesdropdownlist={countriesdropdownlist}
                            userCountry={
                                userLoggedIn.address &&
                                userLoggedIn.address.country
                            }
                            close={() => {
                                dispatch(
                                    editFormsActions.toggleAddACarForm(false)
                                )
                            }}
                            garage_id={garage_id}
                            numberOfCarsInGarage={0}
                            dataCyId={'addCar-form-mobile'}
                            dispatch={dispatch}
                            navigate={navigate}
                        />
                    </ModalDisplay>
                ) : null}
            </IpadAndMobileDisplay>
        </div>
    )
}
