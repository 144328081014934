import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import CenteredPageWrapper from '../../../templates/displays/pageWrappers/CenteredPageWrapper'
import UserBanner from '../../../atoms/user/userBanner'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import ViewsMenu from '../../../atoms/menu/viewsMenu/viewsMenu'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import UserTagBox from '../../../atoms/user/userTagBox'
import MobileContentBody from '../../../templates/displays/pageWrappers/MobileContentBody'
import { Paragraph } from '../../../atoms/typography'
import ViewsMenuMobile from '../../../atoms/menu/viewsMenu/viewsMenuMobile'
import Loader from '../../../atoms/loader/loader'
import DesktopGarageViewManager from '../../../organisms/garageViewManager/desktopGarageViewManager'
import MobileGarageViewManager from '../../../organisms/garageViewManager/mobileGarageViewManager'
import { useAppSelector, useAppDispatch } from '../../../../redux/store/hooks'
import { usersActions } from '../../../../redux/user/reducer'
import { garagesActions } from '../../../../redux/entities/garages/reducer'
import { IExternalGarage } from '../../../../redux/entities/garages/types'

const GarageMenuContainer = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 42px;
        padding-bottom: 42px;
        width: 100%;
        box-sizing: border-box;
    }
`

const ExternalGarage: React.FC = () => {
    const [activeView, setActiveView] = useState('grid_view')

    const [searchParams] = useSearchParams()

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const external_garage_data = useAppSelector(
        (state) => state.entities.garagesData.directly_shared_external_garages
    )
    const isExternalGarageLoading = useAppSelector(
        (state) => state.entities.garagesData.loading
    )
    const isExternalCarLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const isUserLoading = useAppSelector((state) => state.user.loading)
    const images_data = useAppSelector(
        (state) => state.entities.galleriesData.images
    )

    const toggleMenu = (id: string) => {
        setActiveView(id)
    }

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        const garageid = searchParams.get('garageid')

        if (garageid) {
            dispatch(
                garagesActions.getExternalGaragesSharedWithYouRequest(garageid)
            )
            dispatch(garagesActions.setCurrentExternalGarageSuccess(garageid))
        }
    }, [userLoggedIn, searchParams])

    const garageid = searchParams.get('garageid')

    const currentGarage: IExternalGarage | undefined =
        garageid && external_garage_data && external_garage_data[garageid]
            ? external_garage_data[garageid]
            : undefined

    const numberOfCarsInGarage: number =
        currentGarage && currentGarage.cars_list
            ? currentGarage.cars_list.length
            : 0

    const garageOwner = currentGarage && currentGarage.owner

    const accountInternalInfoList = garageOwner && {
        profile_image_url: garageOwner.profile_picture
            ? garageOwner.profile_picture.url
            : null,
        display_name:
            garageOwner && garageOwner.display_name
                ? `${garageOwner.display_name}`
                : 'Unknown',
        one_liner: garageOwner.bio ? garageOwner.bio : '',
        location: garageOwner.location ? garageOwner.location : 'Unknown',
    }

    return (
        <React.Fragment>
            <CenteredPageWrapper>
                {
                    <Loader
                        isLoading={
                            isExternalGarageLoading ||
                            isUserLoading ||
                            isExternalCarLoading
                        }
                    />
                }

                {currentGarage !== null &&
                    currentGarage !== undefined &&
                    garageid &&
                    userLoggedIn &&
                    accountInternalInfoList && (
                        <React.Fragment>
                            <DesktopDisplayOnly>
                                <UserBanner
                                    garage_id={garageid}
                                    car_number={
                                        numberOfCarsInGarage
                                            ? numberOfCarsInGarage
                                            : 0
                                    }
                                    user={accountInternalInfoList}
                                    isExternalGarage
                                />

                                <GarageMenuContainer>
                                    <div data-tut="second-step-garage-desktop">
                                        <ViewsMenu
                                            toggleMenu={toggleMenu}
                                            activeView={activeView}
                                        />
                                    </div>
                                </GarageMenuContainer>

                                <DesktopGarageViewManager
                                    isExternalGarage
                                    activeView={activeView}
                                    carsIdList={
                                        currentGarage && currentGarage.cars_list
                                            ? currentGarage.cars_list
                                            : []
                                    }
                                    addCarForm={() => {}}
                                    imagesData={images_data}
                                    garageid={garageid}
                                />
                            </DesktopDisplayOnly>

                            <IpadAndMobileDisplay>
                                <UserTagBox
                                    fullWidth={true}
                                    user={accountInternalInfoList}
                                    car_number={numberOfCarsInGarage}
                                    hideTasksCount={true}
                                    isExternalGarage
                                    readonlyMode
                                />

                                <MobileContentBody>
                                    <Paragraph
                                        isSmaller={true}
                                        align="left"
                                        ipadAlign="left"
                                        removeIpadPadding={true}
                                    >
                                        {garageOwner && garageOwner.bio && (
                                            <span
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {garageOwner.bio}
                                            </span>
                                        )}
                                    </Paragraph>
                                </MobileContentBody>
                                <div style={{ paddingTop: '24px' }} />
                                <div data-tut="second-step-garage-mobile">
                                    <ViewsMenuMobile
                                        toggleMenu={toggleMenu}
                                        activeView={activeView}
                                        isExternalGarage
                                    />
                                </div>

                                <div style={{ paddingTop: '32px' }} />
                                <MobileGarageViewManager
                                    isExternalGarage
                                    garageid={garageid}
                                    activeView={activeView}
                                    addCarForm={() => {}}
                                    carsIdList={
                                        currentGarage && currentGarage.cars_list
                                            ? currentGarage.cars_list
                                            : []
                                    }
                                    getDropdownList={() => {}}
                                    imagesData={images_data}
                                />

                                <div style={{ paddingBottom: '200px' }} />
                            </IpadAndMobileDisplay>
                        </React.Fragment>
                    )}
            </CenteredPageWrapper>
        </React.Fragment>
    )
}

export default ExternalGarage
