import './styles.css'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { isSafari } from 'react-device-detect'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface IInputFieldProps {
    name: string
    fieldLabel?: string
    value?: string | number | undefined
    placeholder?: string
    handleChange?: (e: any) => any
    type?: any
    onBlur?: any
    onFocus?: any
    onChange?: any
    onEnterKeyPress?: any
    disabled?: boolean
    id?: string
    tabindex?: number | string
    width?: string
    dataCyId?: string
}

interface StandardInputProps {
    disabled?: boolean
    tabindex?: number | string
    font_family?: string
    width?: string
    linecolor?: string
    $theme: ITheme
}

const FieldLabel = styled.label`
    font-family: Lato-semibold;
    color: var(--text-strong, #1a1a1a);
    border: none;
    background-color: transparent !important;
    text-transform: uppercase;
    font-size: 11px;

    @media ${device.mobile} {
    }
`

const StandardInput = styled.input<StandardInputProps>`
    width: ${(props) => props.width ?? '100%'};
    box-sizing: border-box;
    font-family: Lato;
    font-size: 16px;
    border: ${(props) =>
        `${
            props.linecolor
                ? `1px solid ${props.linecolor}`
                : '1px solid #B9B9B9'
        }`};
    border-radius: 4px;
    background-color: transparent !important;
    pointer-events: ${(props) => props.disabled === true && 'none !important '};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    padding: 12px;
    color: var(--text-strong, #1a1a1a);

    ::placeholder {
        font-family: Lato-Light;
        color: var(--text-default, #666666);
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
    }

    @media ${device.desktop} {
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px
            ${(props) => colours[props.$theme].background_default} inset !important;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: ${(props) =>
            props.color
                ? props.color
                : colours[props.$theme].text_strong} !important;
    }
`

function InputFieldNoFormikV2({
    name,
    fieldLabel,
    value,
    placeholder,
    type,
    onBlur,
    onChange,
    onEnterKeyPress,
    id,
    disabled,
    tabindex,
    width,
    dataCyId,
}: IInputFieldProps) {
    const { theme } = useThemes()
    return (
        <div style={{ width: '100%' }}>
            {fieldLabel ? (
                <div style={{ paddingBottom: 4, width: '100%' }}>
                    <FieldLabel>{fieldLabel}</FieldLabel>
                </div>
            ) : null}
            <StandardInput
                $theme={theme}
                id={id}
                name={name}
                type={type ? type : 'text'}
                value={value && value}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                tabindex={tabindex}
                width={width && width}
                data-attr={dataCyId ? dataCyId : ''}
                onKeyDown={(e: any) => {
                    if (e?.which === 13) {
                        if (onEnterKeyPress) {
                            onEnterKeyPress()
                        } else {
                            if (isSafari) {
                                e.preventDefault()
                            }
                        }
                    } else if (e?.key === 'Enter') {
                        if (onEnterKeyPress) {
                            onEnterKeyPress()
                        } else {
                            if (isSafari) {
                                e.preventDefault()
                            }
                        }
                    }
                }}
            />
        </div>
    )
}

export default InputFieldNoFormikV2
