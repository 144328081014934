import { call, fork, put, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { api } from '../../services'
import { timelineActions } from '../reducer'
import { IDeleteCostAttachmentPayloadReq } from '../../services/types'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'

export function* DeleteCostAttachmentSaga(
    payload: IDeleteCostAttachmentPayloadReq
): any {
    try {
        yield call(
            api.timeline.costs.deleteCostAttachment.deleteCostAttachment,
            payload
        )

        yield put(timelineActions.deleteCostAttachmentSuccess)
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: DeleteCostAttachmentSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(timelineActions.deleteCostAttachmentError(customError))
        }
    }
}

function* watcherDeleteCostAttachment() {
    while (true) {
        const { payload } = yield take(
            timelineActions.deleteCostAttachmentRequest
        )

        yield call(DeleteCostAttachmentSaga, payload)
    }
}

const delete_cost_attachment: any[] = [fork(watcherDeleteCostAttachment)]

export default delete_cost_attachment
