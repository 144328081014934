import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import ReturnNav from '../../atoms/header/returnNav'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import CarGalleryEditExistingImageFormMobile from '../../organisms/editForms/carGalleryEditForm/carGalleryEditExistingImageFormMobile'
import styled from 'styled-components'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import EntryGallerySlide from '../../molecules/slideshow/entryGallerySlide'
import CarGalleryEditExistingImageFormDesktop from '../../organisms/editForms/carGalleryEditForm/carGalleryEditExistingImageFormDesktop'
import ImageSubMenuActions from '../../atoms/menu/pageSubMenuActions/imageSubMenuActions'
import { countEntryFeaturedImages } from '../../../helpers/numbers/countFeaturedImages'
import ModalDisplayGalleryImageDesktop from '../../templates/displays/pageWrappers/modalDisplayGalleryImageDesktop'
import GalleryImageCarousel from '../../organisms/galleryCarousel'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import colours from '../../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import {
    IDeleteEntryGalleryImagePayload,
    IUpdateEntryGalleryImagePayload,
} from '../../../redux/entities/galleries/types'
import { carActions } from '../../../redux/entities/cars/reducer'
import { galleriesActions } from '../../../redux/entities/galleries/reducer'
import { usersActions } from '../../../redux/user/reducer'
import useThemes from '../../../providers/theme/hooks'

const SuccessDeletionMessage = styled.div`
    font-size: 17px;
`

const ReturnToGalleryLink = styled(Link)`
    color: var(--primary, #5ec3ca) !important;
    font-size: 14px;
    padding: 10px;
    width: 60vw;
    border: 1px solid var(--primary, #5ec3ca);
    border-radius: 5px;
    margin-top: 30px;
    text-align: center;
    z-index: 15;
`

const DeleteImageConfirmationWrapper = styled.div`
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 88vw;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: georgia;
    z-index: 25;
`

const SingleEntryGalleryImage: React.FC = () => {
    let { theme } = useThemes()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid, entryid } = useParams<{ carid: string; entryid: string }>()
    const [showEditImageForm, setShowEditImageForm] = useState(false)
    const [showDeleteConfirmationMessage, setShowDeleteConfirmationMessage] =
        useState(false)

    const [image_index, setImage_index] = useState<number | undefined>(0)

    const activeEntry = useAppSelector((state) => state.timeline.activeEntry)
    const carsTimelineData = useAppSelector(
        (state) => state.timeline.timeline_data_normalised_by_car_id
    )
    const isExistingcarGalleryImageFormOpened = useAppSelector(
        (state) =>
            state.editForms.formsData.carExistingGalleryImageEditForm.isOpen
    )
    const imagesData = useAppSelector(
        (state) => state.entities.galleriesData.images
    )
    const isGalleryLoading = useAppSelector(
        (state) => state.entities.galleriesData.loading
    )

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const toggleExistingCarGalleryImageEditForm = () =>
        dispatch(editFormsActions.toggleExistingCarGalleryImageEditForm())
    const deleteEntryGalleryImage = (ids: IDeleteEntryGalleryImagePayload) =>
        dispatch(galleriesActions.deleteEntryGalleryImageRequest(ids))
    const updateEntryGalleryImage = (
        payload: IUpdateEntryGalleryImagePayload
    ) => dispatch(galleriesActions.updateEntryGalleryImageRequest(payload))
    const setInitialLandingUrl = (str: string) =>
        dispatch(customNavDataActions.setInitialLandingUrl(str))
    const setCurrentCarAction = (id: string) =>
        dispatch(carActions.setCurrentCar(id))
    const getCurrentUserDataRequest = () =>
        dispatch(usersActions.getCurrentUserDataRequest())

    useEffect(() => {
        setCurrentCarAction(`${carid}`)

        if (!userLoggedIn) {
            getCurrentUserDataRequest()
        }

        if (userLoggedIn) {
            const params = new URLSearchParams(location.search)
            const image_id_param = params.get('id')

            setInitialLandingUrl(
                `/car/${carid}/history-file/${entryid}/gallery?id=${image_id_param}`
            )

            if (isExistingcarGalleryImageFormOpened === true) {
                toggleExistingCarGalleryImageEditForm()
            }

            setUpInitialIndex()
        }
    }, [userLoggedIn])

    const setUpInitialIndex = () => {
        const params = new URLSearchParams(location.search)
        const image_id_param = params.get('id')
        if (activeEntry && activeEntry.gallery_image_ids && image_id_param) {
            const numberr =
                activeEntry.gallery_image_ids.indexOf(image_id_param)
            setImage_index(numberr)
        }
    }

    const selectMenuGalleryImageState = (id: string) => {
        const params = new URLSearchParams(location.search)
        const image_id_param = params.get('id')

        const windowSize = window.innerWidth

        const current_image_id =
            windowSize < 800 &&
            image_index !== undefined &&
            activeEntry &&
            activeEntry.gallery_image_ids
                ? activeEntry.gallery_image_ids[image_index]
                : image_id_param

        if (id === 'edit' && current_image_id) {
            setShowEditImageForm(!showEditImageForm)
            setShowDeleteConfirmationMessage(false)
        } else if (id === 'delete' && current_image_id) {
            setShowEditImageForm(false)
            setShowDeleteConfirmationMessage(true)

            deleteEntryGalleryImage({
                carid: `${carid}`,
                entryid: `${entryid}`,
                imageid: current_image_id,
            })
        } else if (id === 'featured' && current_image_id && imagesData) {
            const payload: IUpdateEntryGalleryImagePayload = {
                carid: `${carid}`,
                entryid: `${entryid}`,
                imageid: current_image_id,
                body: {
                    featured: !imagesData[current_image_id].featured,
                },
            }
            updateEntryGalleryImage(payload)
        }
        toggleExistingCarGalleryImageEditForm()
    }

    const generateImageNavigationUrl = (next: string) => {
        const params = new URLSearchParams(location.search)
        const image_id_param = params.get('id')

        const windowSize = window.innerWidth

        const current_image_id =
            windowSize < 800 &&
            image_index !== undefined &&
            activeEntry &&
            activeEntry.gallery_image_ids
                ? activeEntry.gallery_image_ids[image_index]
                : image_id_param

        if (
            carsTimelineData &&
            carsTimelineData[`${carid}`] !== undefined &&
            current_image_id
        ) {
            const ids_list = activeEntry && activeEntry.gallery_image_ids

            if (ids_list) {
                const index_of_id = ids_list.indexOf(current_image_id)

                if (next === 'next') {
                    const index_of_next_image = index_of_id + 1

                    if (index_of_next_image === -1) {
                        return '/none'
                    }

                    const id_of_next_image = ids_list[index_of_next_image]

                    if (id_of_next_image !== undefined) {
                        return `/car/${carid}/history-file/${entryid}/gallery?id=${id_of_next_image}`
                    } else return '/none'
                } else {
                    const index_of_previous_image = index_of_id - 1

                    if (index_of_previous_image === -1) {
                        return '/none'
                    }

                    const id_of_previous_image =
                        ids_list[index_of_previous_image]

                    return `/car/${carid}/history-file/${entryid}/gallery?id=${id_of_previous_image}`
                }
            } else {
                return '/none'
            }
        } else {
            return '/none'
        }
    }

    const prev_url = generateImageNavigationUrl('previous')
    const next_url = generateImageNavigationUrl('next')

    const windowSize = window.innerWidth

    const params = new URLSearchParams(location.search)
    const image_id_param = params.get('id')

    const current_image_id =
        windowSize < 800 &&
        image_index !== undefined &&
        activeEntry &&
        activeEntry.gallery_image_ids
            ? activeEntry.gallery_image_ids[image_index]
            : image_id_param

    const currentImageData =
        current_image_id && imagesData ? imagesData[current_image_id] : null

    const featuredImagesCount =
        activeEntry && imagesData
            ? countEntryFeaturedImages(activeEntry, imagesData)
            : 0

    useEffect(() => {
        disableBodyScroll(document.body)
        return () => {
            enableBodyScroll(document.body)
        }
    }, [])

    const [isActionSheetOpen, setIsActionSheetOpen] = useState(false)

    const openActionSheet = () => setIsActionSheetOpen(true)
    const closeActionSheet = () => setIsActionSheetOpen(false)

    return (
        <>
            <DesktopDisplayOnly>
                <FullPageWrapper>
                    <ModalDisplayGalleryImageDesktop
                        toggle={() => {
                            navigate(location.pathname)
                        }}
                        isOpen={true}
                        modalBackgroundColor={colours[theme].gallery_image_bg}
                    >
                        {imagesData && imagesData[current_image_id!] && (
                            <EntryGallerySlide
                                closeGallery={() => {
                                    navigate(location.pathname)
                                }}
                                currentImageData={
                                    imagesData && imagesData[current_image_id!]
                                }
                                carid={`${carid}`}
                                entryid={`${entryid}`}
                                imagesData={imagesData}
                                deleteImage={(
                                    ids: IDeleteEntryGalleryImagePayload
                                ) => deleteEntryGalleryImage(ids)}
                                isSomethingLoading={isGalleryLoading}
                                prevUrl={prev_url}
                                nextUrl={next_url}
                                onClickEdit={() =>
                                    toggleExistingCarGalleryImageEditForm()
                                }
                                updateEntryGalleryImage={
                                    updateEntryGalleryImage
                                }
                                featuredImagesCount={
                                    activeEntry && imagesData
                                        ? countEntryFeaturedImages(
                                              activeEntry,
                                              imagesData
                                          )
                                        : 0
                                }
                            />
                        )}

                        {isExistingcarGalleryImageFormOpened &&
                            currentImageData && (
                                <ModalDisplayAbsolute
                                    toggle={() => {}}
                                    isOpen={true}
                                >
                                    <CarGalleryEditExistingImageFormDesktop
                                        close={
                                            toggleExistingCarGalleryImageEditForm
                                        }
                                        updateImage={updateEntryGalleryImage}
                                        carid={`${carid}`}
                                        imagedata={currentImageData}
                                        entryid={entryid}
                                    />
                                </ModalDisplayAbsolute>
                            )}
                    </ModalDisplayGalleryImageDesktop>
                </FullPageWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <div
                    style={{
                        display: 'flex',
                        position: 'relative',
                        background: colours[theme].background_default,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        height: '100vh',
                    }}
                >
                    <Link
                        to={{
                            pathname: `/car/${carid}/history-file/${entryid}/gallery`,
                        }}
                        state={{ prevPath: location.pathname }}
                    >
                        <ReturnNav />
                    </Link>

                    {activeEntry &&
                    activeEntry.gallery_image_ids &&
                    current_image_id &&
                    imagesData &&
                    imagesData[current_image_id] &&
                    image_index !== undefined ? (
                        <GalleryImageCarousel
                            indexOfInitialImage={image_index}
                            image_data={imagesData}
                            images_ids={activeEntry.gallery_image_ids}
                            synchroniseActiveIndex={(n: number) => {
                                setImage_index(n)
                            }}
                            openActionsSheet={openActionSheet}
                            cover_id={''}
                        />
                    ) : (
                        <SkeletonAbsolute isthingloading={true} />
                    )}

                    {current_image_id &&
                        imagesData &&
                        imagesData[current_image_id] && (
                            <ImageSubMenuActions
                                isOpen={
                                    isActionSheetOpen &&
                                    !showDeleteConfirmationMessage &&
                                    !showEditImageForm
                                }
                                toggle={closeActionSheet}
                                items={[
                                    {
                                        id: 'edit',
                                        text: 'Update image details',
                                        action: selectMenuGalleryImageState,
                                    },
                                    {
                                        id: 'delete',
                                        text: 'Delete image',
                                        action: selectMenuGalleryImageState,
                                        isDisabled:
                                            imagesData[current_image_id]
                                                .featured !== true &&
                                            featuredImagesCount >= 5,
                                        tooltipText:
                                            'Five images already set as gallery features. Please remove at least one before setting new ones.',
                                    },
                                ]}
                            />
                        )}

                    {isExistingcarGalleryImageFormOpened &&
                        showDeleteConfirmationMessage === false &&
                        showEditImageForm === true &&
                        current_image_id && (
                            <ModalDisplay
                                toggle={toggleExistingCarGalleryImageEditForm}
                                isOpen={true}
                                customZindex={8}
                            >
                                <div style={{ paddingTop: '20px' }} />
                                {imagesData && current_image_id && (
                                    <CarGalleryEditExistingImageFormMobile
                                        carid={`${carid}`}
                                        entryid={entryid}
                                        updateImage={updateEntryGalleryImage}
                                        imagedata={imagesData[current_image_id]}
                                        close={() => {
                                            toggleExistingCarGalleryImageEditForm()
                                            setShowDeleteConfirmationMessage(
                                                false
                                            )
                                            setShowEditImageForm(false)
                                        }}
                                    />
                                )}
                            </ModalDisplay>
                        )}

                    {showDeleteConfirmationMessage && !showEditImageForm && (
                        <ModalDisplay
                            toggle={() => {
                                toggleExistingCarGalleryImageEditForm()
                                navigate(
                                    `/car/${carid}/history-file/${entryid}/gallery`
                                )
                            }}
                            isOpen={true}
                        >
                            <DeleteImageConfirmationWrapper>
                                <SuccessDeletionMessage>
                                    Image successfully deleted.
                                </SuccessDeletionMessage>

                                <ReturnToGalleryLink
                                    to={{
                                        pathname: `/car/${carid}/history-file/${entryid}/gallery`,
                                    }}
                                    replace
                                >
                                    Return to gallery
                                </ReturnToGalleryLink>
                            </DeleteImageConfirmationWrapper>
                        </ModalDisplay>
                    )}
                </div>
            </IpadAndMobileDisplay>
        </>
    )
}

export default SingleEntryGalleryImage
