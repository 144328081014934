import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import { insuranceActions } from '../../../../../../redux/insuranceQuoteApplication/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import { device } from '../../../../../templates/displays/devices'
import EverydayCarSelection from '../../../modalManagers/everydayCarSelection'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;

    padding-bottom: 120px;

    width: 88vw;

    @media ${device.beyond_ipad_mobile} {
        width: 566px;
        padding-bottom: 150px;
    }
`

type Props = {
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const EverydayCarSelectorWizard = (props: Props) => {
    let darftApplicationData = useAppSelector(
        (state) => state.insuranceQuoteApplication.draft
    )

    let everyday_car_ids = darftApplicationData?.everyday_car_ids

    let {
        getFirstIncompleteSubsection_EverydayVehicle,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    let goNext = () => {
        let ids = getFirstIncompleteSubsection_EverydayVehicle()

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('everyday_vehicles')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                navigate(
                    {
                        search: params2.toString(),
                    },
                    { replace: true }
                )
            } else
                navigate(`/insurance/application/${darftApplicationData?.id}`)
        }

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return navigate(
                {
                    search: params2.toString(),
                },
                { replace: true }
            )
        }

        return getToAny()
    }
    let onSave = () => {
        if (!everyday_car_ids || everyday_car_ids.length === 0) {
            setIsDisabled(true)
        } else {
            if (isDisabled) {
                setIsDisabled(false)
            }
            dispatch(
                insuranceActions.submit_everyday_car_ids_request(
                    everyday_car_ids
                )
            )
            setTimeout(() => {
                goNext()
            }, 200)
        }
    }

    let goPrevious = () => {
        return navigate(
            `/insurance/application/${darftApplicationData?.id}/wizard?section_id=vehicles_to_insure`
        )
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'everyday_vehicles',
            onSave: () => onSave(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    return (
        <Wrapper>
            <EverydayCarSelection
                selected={everyday_car_ids ?? []}
                updateSelected={(ids: string[]) => {
                    if (ids.length > 0 && isDisabled) {
                        setIsDisabled(false)
                    }

                    if (hasFormChanged === false) {
                        setHasFormChanged(true)
                        props.setTopWizardCheckActions({
                            sectionID: 'everyday_vehicles',
                            onSave: () => onSave(),
                            has_changes: true,
                        })
                    }

                    dispatch(insuranceActions.select_everyday_cars(ids))
                }}
                fromWizard={true}
            />

            <InsuranceGoBackReusableBottomBar
                saveBtnTxt="Save & Continue"
                isSaveDisabled={isDisabled ? true : false}
                onSave={onSave}
                goBack={() => goPrevious()}
                sectionId="everyday_vehicles"
                hasFormChanged={hasFormChanged}
            />
        </Wrapper>
    )
}

export default EverydayCarSelectorWizard
