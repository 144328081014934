import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { IApiTasksUpdate_args } from '../types'

export const updateTask = (p: IApiTasksUpdate_args): Promise<any> => {
    let dataToUpdateFinal: string = JSON.stringify(p.dataToUpdate)

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: dataToUpdateFinal,
        credentials: 'include',
    }

    return fetch(
        urls.update_task_by_task_id_endpoint(p.taskid),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        response,
                        'Update task error',
                        dataToUpdateFinal
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
