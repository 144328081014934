import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import { Select } from '../../../../../atoms/select'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding40 = styled.div`
    padding-top: 40px;
`
const Padding5 = styled.div`
    padding-top: 5px;
`

interface Values {
    transmission: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: (p: IwriteTechnicalInformationPayloadReq) => any
    tech_info_id?: any
    optionsList: any
    carid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        optionsList,
        setFieldValue,
        handleSubmit,
        values,
        errors,
        // touched,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    function handleChangeField(value: any) {
        setFieldValue('transmission', value)
    }

    let checkIfDisabled = () => {
        if (!values.transmission) {
            return true
        } else if (errors.transmission !== undefined) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop style={{ marginTop: '20vh' }} $theme={theme}>
            <ModalTopBar $theme={theme}>Transmission</ModalTopBar>
            <ModalInnerContentWrapperDesktop>
                <Padding5 />
                {optionsList !== null && (
                    <Select
                        defaultValue={values.transmission}
                        placeholder="transmission"
                        onChange={handleChangeField}
                        options={optionsList.map((item: any) => ({
                            value: item.name,
                            label: item.name === 'Unknown' ? '-' : item.name,
                        }))}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                )}
                <Padding40 />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={handleSubmit}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const CarTransmissionTypeCustomDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        transmission: props.item.answer ? props.item.answer : undefined,
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                transmission: Yup.string().oneOf(['Manual', 'Automatic']),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let payload: IwriteTechnicalInformationPayloadReq = {
            car_id: props.carid,
            tech_info_id: props.tech_info_id,
            data: {
                id: 'transmission',
                text: props.item.text,
                answer: values.transmission,
            },
        }

        props.submitFunc(payload)

        props.toggle()
    },
})(InnerForm)

export default CarTransmissionTypeCustomDesktop
