import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import DatepickerButton from '../../../atoms/Button/datepickerButton'
import dayjs from 'dayjs'
import { useState } from 'react'
import { device } from '../../../templates/displays/devices'
import InputField from '../../../atoms/Inputfield/inputField'
import useThemes from '../../../../providers/theme/hooks'
import { IEditOrCreateModeSingleFieldsProps } from '../types'

type IStyle = {
    isDesktop?: boolean
}

const WrapperMobile = styled.section<IStyle>`
    width: 100%;
    @media ${device.ipad} {
        ${(props) => !props.isDesktop && 'padding-left: 25px;'}
    }
    ${(props) => props.isDesktop && 'max-width: 400px; min-width: 300px;'}

    .ant-picker-focused, .ant-picker:hover {
        border-color: var(--primary, #5ec3ca) !important;
        border-right-width: 1px;
    }
`

const FieldTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-light;
    align-self: start;
    font-size: 14px;
    text-transform: capitalize;
`

const Row = styled.div<IStyle>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
        props.isDesktop ? 'space-between' : 'space-around'};
    @media ${device.ipad} {
        ${(props) => props.isDesktop && 'max-width: 100%;'}
    }
`

const Or = styled.span`
    font-family: Lato-bold;
    color: var(--text-strong, #1a1a1a);
    @media ${device.ipad} {
        padding-bottom: 20px;
    }
`

const InputRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media ${device.ipad} {
        align-items: flex-start;
    }

    @media ${device.desktop} {
        margin-top: 16px;
    }
`

// const Error = styled.div`
//     font-family: 'Lato-light';
//     color: red;
//     font-style: italic;
//     font-size: 12px;
//     @media ${device.ipad} {
//         font-size: 10px;
//     }
// `
// const RegistrationNumberReminder = styled.div`
//     color: grey;
//     font-family: Lato;
//     font-size: 13px;
//     padding-top: 10px;
// `
const SmallPadding = styled.div`
    padding-top: 30px;
`

interface Values {
    date_picked_btns_form: string
}

interface OtherProps {
    data: IEditOrCreateModeSingleFieldsProps
    dataCyId?: string
    isDesktop?: boolean
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        isDesktop,
        data,
        // errors,
        setFieldValue,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    const handleAnswerChange = (date: string) => {
        setFieldValue('date_picked_btns_form', date)
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                date,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, date)
        }
        // if (!e.target.value || e.target.value.length > 20) {
        //     setIsDisabled(true)
        // } else {
        //     setIsDisabled(false)
        // }
        selectToday(false)
        selectYesterday(false)
    }

    const today: Date = new Date()

    const yesterday = new Date(today)

    yesterday.setDate(yesterday.getDate() - 1)

    const [todayIsSelected, selectToday] = useState(
        data.value_start &&
            data.value_start === dayjs(today).format('YYYY-MM-DD')
            ? true
            : false
    )
    const [YesterdayIsSelected, selectYesterday] = useState(
        data.value_start &&
            data.value_start === dayjs(yesterday).format('YYYY-MM-DD')
            ? true
            : false
    )

    const handleAnswerChangeOnButtonPress = (date: string) => {
        let value: string

        if (date === 'today') {
            value = dayjs(today).format('YYYY-MM-DD')
            setFieldValue(
                'date_picked_btns_form',

                values.date_picked_btns_form
            )
            if (data.isCreation) {
                data.onChangeFunc(
                    data.id,
                    value,
                    data.submitCreationConfigs?.entityType,
                    data.submitCreationConfigs?.action
                )
            } else {
                data.onChangeFunc(data.id, value)
            }
            selectYesterday(false)
            return selectToday(true)
        } else if (date === 'yesterday') {
            value = dayjs(yesterday).format('YYYY-MM-DD')
            setFieldValue('date_picked_btns_form', values.date_picked_btns_form)
            if (data.isCreation) {
                data.onChangeFunc(
                    data.id,
                    value,
                    data.submitCreationConfigs?.entityType,
                    data.submitCreationConfigs?.action
                )
            } else {
                data.onChangeFunc(data.id, value)
            }
            selectToday(false)
            return selectYesterday(true)
        }
    }

    const { theme } = useThemes()

    return (
        <WrapperMobile isDesktop={isDesktop}>
            {isDesktop && (
                <>
                    <FieldTitle>{data.title}</FieldTitle>
                    <div style={{ paddingTop: '20px' }} />
                </>
            )}
            <Row isDesktop={isDesktop}>
                <DatepickerButton
                    text={'Today'}
                    onClick={() => handleAnswerChangeOnButtonPress('today')}
                    selected={todayIsSelected}
                    width={isDesktop ? '170px' : '100%'}
                />
                <div style={{ paddingRight: 24 }} />
                <DatepickerButton
                    text={'Yesterday'}
                    onClick={() => handleAnswerChangeOnButtonPress('yesterday')}
                    selected={YesterdayIsSelected}
                    width={isDesktop ? '170px' : '100%'}
                />
            </Row>
            <SmallPadding />
            <Row>
                <Or>OR</Or>
            </Row>
            {!isDesktop && <SmallPadding />}
            <InputRow>
                <InputField
                    theme={theme}
                    id="date_picked_btns_form"
                    name="date_picked_btns_form"
                    placeholder="Date"
                    customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                    helperText="Type or select a date (dd/mm/yyyy)"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={
                        values.date_picked_btns_form &&
                        values.date_picked_btns_form !== '-'
                            ? values.date_picked_btns_form
                            : undefined
                    }
                    font_family="Lato"
                    onChange={handleAnswerChange}
                    type="date"
                    width="100%"
                    fontSize="16px"
                    paddingLeft="0px"
                    dataCyId={props.dataCyId}
                />
            </InputRow>
            <SmallPadding />
        </WrapperMobile>
    )
}

const DatePickerWithButtons = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        date_picked_btns_form: props.data.value_start
            ? props.data.value_start
            : '-',
    }),
    enableReinitialize: true,
    // validationSchema: {},
    // validationSchema: (props: OtherProps) => {
    //     return Yup.lazy(() => {
    //         return Yup.object().shape({
    //             date_picked_btns_form: Yup.string()
    //                 .max(20, 'Your answer must be under 20 characters')
    //                 .min(2),
    //         })
    //     })
    // },

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default DatePickerWithButtons
