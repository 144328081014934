import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    @media ${device.mobile} {
        gap: 32px;
    }
`

const NoBgBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    max-width: 550px;
`

const Title = styled.div`
    font-family: Lato-semibold;
    font-size: 24px;
    color: #666666;
    text-align: center;
    @media ${device.mobile} {
        font-size: 22px;
    }
`

const NoBgBoxText = styled.div`
    font-family: Lato;
    font-size: 16px;
    color: #666666;
    text-align: center;
    @media ${device.mobile} {
        font-size: 15px;
    }
`

interface IProps {}

const NoPrevOwned = (props: IProps) => {
    const size: WindowSize = useWindowSize()
    return (
        <Wrapper>
            <NoBgBox>
                <Title>No previously owned cars</Title>
                <NoBgBoxText
                    style={
                        size && size.width && size.width < 500
                            ? { maxWidth: '300px' }
                            : {}
                    }
                >
                    After you archive or handover a car from your garage, it
                    will appear here. Over time, you can build an ownership
                    timeline of all the cars that were in your custodianship.
                </NoBgBoxText>
            </NoBgBox>
        </Wrapper>
    )
}

export default NoPrevOwned
