import { IPrismicImg } from 'apexModels'
import React from 'react'

import styled from 'styled-components'
import { device } from '../../../../../../templates/displays/devices'
import grey_placeholder from '../../../../../../../public/assets/placeholder/grey_placeholder.svg'
import ApexImageCreditsMobile from '../../../credits/apexImageCreditsMobile'

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 50px;
    @media ${device.beyond_ipad_mobile} {
    }
`

const Img = styled.img`
    object-fit: cover;
    height: auto;
    @media ${device.ipad} {
        height: auto;
    }
    @media ${device.mobile} {
        height: auto;
        max-height: 500px;
    }
    @media ${device.large_desktop} {
    }
`

type Props = {
    img_obj: IPrismicImg
}

const ApexRichTextImageInsertedMobile: React.FC<Props> = ({ img_obj }) => {
    return (
        <ImageWrapper>
            <Img src={img_obj.url ? img_obj.url : grey_placeholder} />
            {img_obj.alt && <ApexImageCreditsMobile text={img_obj.alt} />}
        </ImageWrapper>
    )
}

export default ApexRichTextImageInsertedMobile
