import * as React from 'react'
import styled from 'styled-components'
import SelectWithRemovalIconMobile from '../../molecules/blueBorderedEditOrCreateFields/selectWithRemovalIcon'
import {
    remindersData,
    reminder_optionsData,
} from '../../../redux/localdata/remindersLocalData'
import { ISetEditedReminderChangeInnerPayload } from '../../pages/tasks/taskReminders'
import { IReminderEdited } from 'entityModels'
import NumberAndSelectWithRemovalIcon, {
    NumberAndSelectValuesPayload,
} from '../../molecules/blueBorderedEditOrCreateFields/numberAndSelectWithRemovalIcon'
import DateWithRemovalIcon from '../../molecules/blueBorderedEditOrCreateFields/dateWithRemovalIcon'
import Expander from '../../atoms/expander/expander'
import dayjs from 'dayjs'

const Wrapper = styled.div`
    margin: 8px 0;
`

type Props = {
    handleChange: (p: ISetEditedReminderChangeInnerPayload) => any
    item: IReminderEdited
    isInvalid?: boolean
    dueDate: string
    minDate: string
    maxDate?: string
    variant: 'desktop' | 'mobile'
}

const RemindersFieldsManager: React.FunctionComponent<Props> = (props) => {
    const { handleChange, item, minDate, maxDate, variant } = props

    const full_list_ids = React.useMemo(
        () => [
            'on_due_day',
            'tomorrow',
            'days_before',
            'weeks_before',
            'months_before',
            'custom_date_copy',
        ],
        []
    )
    const remindersOptionsDataListCleaned = React.useCallback(
        (argArray: string[], dueDate: string) => {
            let arr = argArray.map((item: string) => {
                return item
            })

            let dueDateD = dayjs(dueDate)
            let today = dayjs()

            let monthsDiff = dueDateD.diff(today, 'month', true)

            if (monthsDiff < 1) {
                var monthsIndex = arr.indexOf('months_before')

                if (arr[monthsIndex] === 'months_before') {
                    arr.splice(monthsIndex, 1)
                }
            }

            let weeksDiff = dueDateD.diff(today, 'week', true)

            if (weeksDiff < 1) {
                var weeksIndex = arr.indexOf('weeks_before')

                if (arr[weeksIndex] === 'weeks_before') {
                    arr.splice(weeksIndex, 1)
                }
            }

            let daysDiff = dueDateD.diff(today, 'day', true)

            if (daysDiff < 1) {
                var daysIndex = arr.indexOf('days_before')

                if (arr[daysIndex] === 'days_before') {
                    arr.splice(daysIndex, 1)
                }
            }

            return arr
        },
        []
    )
    const [dueDateLocal, setDueDateLocal] = React.useState(props.dueDate)
    const [optionListv1, setOptionListv1] = React.useState(
        remindersOptionsDataListCleaned(full_list_ids, props.dueDate)
    )
    const [optionListv2, setOptionListv2] = React.useState(
        remindersOptionsDataListCleaned(
            remindersData.date_number_and_select.optionsIDsList,
            props.dueDate
        )
    )

    React.useEffect(() => {
        if (props.dueDate) {
            setDueDateLocal(props.dueDate)
        }

        if (dueDateLocal !== props.dueDate) {
            let arr = remindersOptionsDataListCleaned(
                full_list_ids,
                props.dueDate
            )
            setOptionListv1(arr)

            let arr2 = remindersOptionsDataListCleaned(
                remindersData.date_number_and_select.optionsIDsList,
                props.dueDate
            )
            setOptionListv2(arr2)
        }
    }, [
        props.dueDate,
        dueDateLocal,
        remindersOptionsDataListCleaned,
        full_list_ids,
    ])

    let custom_id = () => {
        let id_custom = `blue-border-number-input-${item.id}`
        if (window.innerWidth < 500) {
            id_custom = `blue-border-number-input-${item.id}-mobile`
        }

        return id_custom
    }

    let focusNValueField = () => {
        let id = custom_id()
        return (
            document.getElementById(id) && document.getElementById(id)!.focus()
        )
    }

    return (
        <Wrapper>
            <div
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                {(item.type === undefined ||
                    remindersData.date_option_select.optionsIDsList.includes(
                        item.type ?? ''
                    )) && (
                    <SelectWithRemovalIconMobile
                        variant={variant}
                        optionsData={reminder_optionsData}
                        optionsIDsList={optionListv1}
                        customHandleChange={(name: string) => {
                            handleChange({
                                reminder_id: item.id,
                                changeDataType: 'type',
                                val: name,
                            })

                            setTimeout(() => {
                                focusNValueField()
                            }, 300)
                        }}
                        item={item}
                        onCrossClick={() =>
                            handleChange({
                                reminder_id: item.id,
                                isDeletion: true,
                            })
                        }
                    />
                )}
                <Expander
                    height={
                        item.type &&
                        remindersData.date_number_and_select.optionsIDsList.includes(
                            item.type
                        )
                            ? 'auto'
                            : 0
                    }
                >
                    <NumberAndSelectWithRemovalIcon
                        variant={variant}
                        optionsData={reminder_optionsData}
                        optionsIDsList={optionListv2}
                        item={item}
                        customHandleChange={(
                            p: NumberAndSelectValuesPayload,
                            hasError?: boolean
                        ) => {
                            focusNValueField()
                            if (p.selected) {
                                handleChange({
                                    reminder_id: item.id,
                                    changeDataType: 'type',
                                    val: p.selected,
                                    hasError: hasError === true ? true : false,
                                })
                            }
                            if (p.nvalue) {
                                handleChange({
                                    reminder_id: item.id,
                                    changeDataType: 'nvalue',
                                    val: p.nvalue,
                                    hasError: hasError === true ? true : false,
                                })
                            }
                            return
                        }}
                        onCrossClick={() =>
                            handleChange({
                                reminder_id: item.id,
                                isDeletion: true,
                            })
                        }
                        maxDate={props.dueDate}
                        minDate={minDate}
                        dataCyId={custom_id()}
                    />
                </Expander>

                <Expander
                    height={
                        item.type &&
                        remindersData.date_picker.optionsIDsList.includes(
                            item.type
                        )
                            ? 'auto'
                            : 0
                    }
                >
                    <DateWithRemovalIcon
                        variant={variant}
                        customHandleChange={(date: string) => {
                            handleChange({
                                reminder_id: item.id,
                                changeDataType: 'datePicked',
                                val: date,
                            })
                        }}
                        onCrossClick={() =>
                            handleChange({
                                reminder_id: item.id,
                                isDeletion: true,
                            })
                        }
                        minDate={minDate}
                        maxDate={maxDate}
                        value_start={item.datePicked}
                    />
                </Expander>
            </div>
        </Wrapper>
    )
}

export default RemindersFieldsManager
