import { useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import {
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import InsuranceQuoteAddressesManager from '../../../../organisms/insuranceQuote/addressesManager'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import { UserParams } from './insuranceMainDriverBasicDetailsApplicationFlow'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import { IInsuranceAddress_API } from '../../../../../redux/insuranceQuoteApplication/types'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../../redux/store'

const InsuranceMainDriverAddressManager = () => {
    const navigate = useNavigate()

    const { userid } = useParams<UserParams>()

    const search = useLocation().search

    const field_id = new URLSearchParams(search).get('field_id')

    const dispatch = useAppDispatch()

    const { id } = useParams<{ id?: string }>()

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let { getUserAndApplicationData } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    let onAnswerChange = (answer: any) => {
        let field_idd =
            field_id && field_id === 'residential'
                ? `policy_holder_residential_address`
                : `policy_holder_correspondence_address`
        dispatch(
            insuranceActions.set_answer_main_driver_licenseAddress({
                id: field_idd,
                answer: answer,
                userid: `${userid}`,
            })
        )
    }

    let goBack = () => {
        navigate(
            `/insurance/application/${id}/main_driver/${userid}/licence_address`
        )
    }

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: 'Main Driver Name',
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/main_driver/${userid}`
                                ),
                        },
                        {
                            pageName: 'Driver licence & Address',
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/main_driver/${userid}/licence_address`
                                ),
                        },
                        {
                            pageName:
                                id && id === 'residential'
                                    ? 'Select residential address'
                                    : id && id === 'correspondence'
                                    ? 'Select correspondence address'
                                    : 'Select Address',
                        },
                    ]}
                    sectionId="main_policy_holder"
                />
            </DesktopDisplayOnly>
            <InsuranceQuoteAddressesManager
                goBack={() => goBack()}
                onSelect={(address: IInsuranceAddress_API) => {
                    onAnswerChange(address)
                    goBack()
                }}
            />

            <IpadAndMobileDisplay>
                <InsuranceTopBarWithProgressionRateMobile
                    breadCrumbs={[
                        {
                            pageName: 'Main Driver Name',
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/main_driver/${userid}`
                                ),
                        },
                        {
                            pageName: 'Driver licence & Address',
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/main_driver/${userid}/licence_address`
                                ),
                        },
                        {
                            pageName:
                                id && id === 'residential'
                                    ? 'Select residential address'
                                    : id && id === 'correspondence'
                                    ? 'Select correspondence address'
                                    : 'Select Address',
                        },
                    ]}
                    sectionId="main_policy_holder"
                />
                <InsuranceQuoteAddressesManager
                    userid={userid}
                    goBack={() => goBack()}
                    onSelect={(address: IInsuranceAddress_API) => {
                        onAnswerChange(address)
                        goBack()
                    }}
                    isMobile
                />
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceMainDriverAddressManager
