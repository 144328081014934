import { call, fork, put, take } from 'redux-saga/effects'

import { dropDownActions } from '../reducer'
import { get_dropdowns_by_names_insurance_api } from '../../../services/dropdowns/insuranceDropdowns'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { IAnyObject } from '../../../insuranceQuoteApplication/reducer'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'

export type IInsuranceDropdownItem = {
    value: string
    label: string
    code?: string
}

export type IInsuranceDropdowns = {
    [key: string]: IInsuranceDropdownItem[]
}

function* setInsuranceDropdownByFieldIDApi(fieldIDs: string[]) {
    try {
        let dataReceived: IInsuranceDropdowns =
            yield get_dropdowns_by_names_insurance_api(fieldIDs)

        if (dataReceived && dataReceived.motoring_clubs) {
            // other option is the last one back end returns.
            let last = dataReceived.motoring_clubs.pop()

            if (last) {
                dataReceived = {
                    ...dataReceived,
                    motoring_clubs: [last, ...dataReceived.motoring_clubs],
                }
            }
        }

        if (
            dataReceived?.motoring_conviction_types &&
            dataReceived?.motoring_conviction_codes
        ) {
            let obj: IAnyObject = {}
            for (let item of dataReceived.motoring_conviction_types) {
                if (item.code) {
                    obj[`${item.code}`] = []
                }
            }
            for (let item2 of dataReceived.motoring_conviction_codes) {
                let item_code = item2.code ? item2.code?.slice(0, 2) : undefined
                if (item_code && obj[`${item_code}`]) {
                    obj[`${item_code}`].push(item2)
                }
            }

            yield put(
                dropDownActions.setInsuranceMotorConvCodesGroupedDropdownData({
                    motoring_conviction_codes_grouped: { ...obj },
                })
            )
        }

        yield put(
            dropDownActions.setInsuranceFieldByIDsDropdownDataSuccess({
                ...dataReceived,
            })
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: setInsuranceDropdownByFieldIDApi,
            }
            yield call(unauthHandlerSaga, p)
        } else if (error === Error) {
            let customErrorData: ICustomErrorData = {
                custom_message: `Looks like your internet has stoped working`,
                custom_message_detail: 'Check your connection and refresh',
                custom_user_action_text: 'Refresh page',
            }

            let customError: IReduxError = ConvertToReduxError(
                { status: '500' },
                customErrorData
            )
            yield put(
                dropDownActions.setInsuranceFieldByIDsDropdownDataError(
                    customError
                )
            )
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)

            yield put(
                dropDownActions.setInsuranceFieldByIDsDropdownDataError(
                    typedError
                )
            )
        }
    }
}

function* watcherSetInsuranceDropdownData() {
    while (true) {
        const { payload } = yield take(
            dropDownActions.setInsuranceFieldByIDsDropdownDataRequest
        )

        yield call(setInsuranceDropdownByFieldIDApi, payload)
    }
}

const set_insurance_dropdownData: any[] = [
    fork(watcherSetInsuranceDropdownData),
]
export default set_insurance_dropdownData
