import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import {
    AbsoluteGradient,
    JourneyCardDesktop,
    JourneyCardMobileWithScroll,
} from '../../../templates/styledcomponents/journeyStyles'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import UserPlainIcon from '../../../atoms/icons/components/userPlainIcon'
import placeholder1 from '../../../atoms/skeleton/gallery/cover_image.jpg'
import placeholder2 from '../../../atoms/skeleton/gallery/cover_image2.jpg'
import placeholder1_dark from '../../../atoms/skeleton/gallery/cover_image_dark.png'
import placeholder2_dark from '../../../atoms/skeleton/gallery/2_dark.png'
import { device } from '../../../templates/displays/devices'
import UploadedImagePreview from '../../../atoms/image/uploadedImagePreview'
import JourneyImagesDropzone from '../../dropzone/journeyImagesDropZone'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import axios from 'axios'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import SwitchSVG from '../../../atoms/icons/switch'
import SortIcon from '../../../atoms/icons/components/sortIcon'
import AddIcon from '../../../atoms/icons/components/addIcon'
import ImageUploadBtn from '../../../atoms/Button/imageUploadBtn'
import JourneyPageWrapperMobile from '../../../templates/wrappers/journeyPageWrapperMobile'
import JourneyWrapperLeftSideDesktop from '../../../templates/wrappers/journeyPageWrapperLeftDesktop'
import { ICarsObject } from '../../../../redux/entities/cars/types'
import { IGalleryImagesObject } from '../../../../redux/entities/galleries/types'
import { IUploadGalleryImagesPreSignedUrlsPayload } from '../../../../redux/filestorage/types'
import { IUser } from '../../../../redux/user/types'
import { fileStorageActions } from '../../../../redux/filestorage/reducer'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: (carid?: string) => void
    goToNextStep: (carid: string) => void
    carsData?: ICarsObject
    garageCars?: string[]
    galleryImages?: IGalleryImagesObject | null
    user: IUser | null
}

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`

const RightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    @media ${device.smallest_laptops} {
        padding: 24px;
    }
`

const ColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`

const Text = styled.span<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    @media ${device.mobile_and_ipad} {
        font-size: 16px;
        line-height: 24px;
    }
`

const EnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

const RowContent = styled.div`
    display: flex;
    align-items: center;
`

const IconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

const CardContent = styled.section`
    display: flex;
    width: 592px;
    box-sizing: border-box;
    padding: 56px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    @media (max-width: 1200px) {
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
    }
    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
    }
`
const CardTitle = styled.h3<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 22px;
    font-family: Lato-semibold;
    line-height: 32px;
`

const CardSubtitle = styled.p<IStyle>`
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
`

const CardImageWrapper = styled.div`
    height: 360px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    @media ${device.mobile} {
        height: 220px;
    }
`

const ImageWrapper = styled.div`
    height: 176px;
    width: 176px;
    position: relative;

    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
        height: 104px;
    }
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
`

const MobileCardHeader = styled.div<IStyle>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 7px 23px;
    align-items: flex-start;
    gap: 4.622px;
    align-self: stretch;
    border-bottom: ${(props) =>
        `0.5px solid ${colours[props.$theme].border_muted}`};
`

const MobileCardHeaderElement = styled.div<IStyle>`
    display: flex;
    padding: 6px 10px;
    align-items: flex-start;
    gap: 6px;
    border-radius: 57.77px;
    background-color: ${(props) => `${colours[props.$theme].primary_08}`};
    color: ${(props) => `${colours[props.$theme].primary}`};
    font-family: Lato-semibold;
    font-size: 8px;
    line-height: 11.554px;
`

const SortSquareContainer = styled.div<IStyle>`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 2.311px;
    border: ${(props) => `0.578px solid ${colours[props.$theme].primary}`};
`

const AddSquareContainer = styled.div<IStyle>`
    display: flex;
    padding: 9px;
    justify-content: center;
    align-items: center;
    border-radius: 2.311px;
    background-color: ${(props) => `${colours[props.$theme].primary}`};
`

export default function AddProfilePhotoStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    carsData,
    galleryImages,
    user,
}: Props) {
    const { theme } = useThemes()
    const [selectedImage, setSelectedImage] = useState<File | undefined>(
        undefined
    )

    const [imageUploadProgress, setUploadImageProgress] = useState<
        number | undefined
    >(undefined)

    let params = new URLSearchParams(location.search)
    let carid: string | null = params.get('carid')

    const dispatch = useAppDispatch()

    const gallerypresignedurls = useAppSelector(
        (state) => state.fileStorage.gallerypresignedurls
    )

    const upload_presignedurl_image_to_s3 = (filesToSend: File[]) => {
        filesToSend.length > 0 &&
            filesToSend.forEach(async (file: File, index: number) => {
                if (
                    file !== undefined &&
                    gallerypresignedurls &&
                    gallerypresignedurls[file.name]
                ) {
                    setUploadImageProgress(0)

                    return await axios
                        .request({
                            method: 'put',
                            url: gallerypresignedurls[file.name].url,
                            data: file,
                            onUploadProgress: (p: any) => {
                                setUploadImageProgress(
                                    Math.ceil((p.loaded / p.total) * 100)
                                )
                            },
                        })
                        .then((data: any) => {
                            setUploadImageProgress(100)
                            return
                        })
                        .catch((e: any) => {
                            apiSentryErrorHandler(
                                e,
                                'Upload presigned url car image to s3 error'
                            )
                            return Promise.reject(e)
                        })
                }
                // };
            })
    }

    useEffect(() => {
        if (
            gallerypresignedurls &&
            selectedImage &&
            gallerypresignedurls[selectedImage.name]
        ) {
            upload_presignedurl_image_to_s3([selectedImage])
        }
    }, [gallerypresignedurls])

    const onSubmit = (
        car_id: string,
        img: File,
        presignedurls: IUploadGalleryImagesPreSignedUrlsPayload
    ) => {
        if (car_id && presignedurls && presignedurls[img.name]) {
            let imgPayload = {
                carid: car_id,
                gallerypresignedurls: presignedurls,
                files: [img],
                fields: {
                    caption: undefined,
                    credit: undefined,
                    location: undefined,
                },
                isFromQuickActions: true,
                makeCover: true,
            }

            dispatch(
                fileStorageActions.submitCarGalleryImagesFilestorageRequest(
                    imgPayload
                )
            )
        }
    }

    const submitAndGoToNextStep = () => {
        if (carid) {
            if (selectedImage && gallerypresignedurls) {
                onSubmit(carid, selectedImage, gallerypresignedurls)
            }
            goToNextStep(carid)
        }
    }

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            submitAndGoToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [selectedImage, gallerypresignedurls, step, carid])

    const removeImage = () => {
        setSelectedImage(undefined)
        setUploadImageProgress(undefined)
    }

    let carCoverImgId =
        carsData && carid && carsData[carid] && carsData[carid].gallery.cover
    let carCoverImg =
        galleryImages && carCoverImgId && galleryImages[carCoverImgId]

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <Wrapper>
                        <JourneyWrapperLeftSideDesktop
                            title={
                                carCoverImg
                                    ? 'Change profile photo of your car'
                                    : 'Add profile photo of your car'
                            }
                            sectionID="car_profile"
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={() => {
                                carid && goToPrevStep(carid)
                            }}
                        >
                            <ImageWrapper>
                                {selectedImage ? (
                                    <UploadedImagePreview
                                        currentImagesUploaded={selectedImage}
                                        borderRadius="24px"
                                        onDelete={
                                            selectedImage &&
                                            imageUploadProgress === 100
                                                ? removeImage
                                                : undefined
                                        }
                                    />
                                ) : carid ? (
                                    <JourneyImagesDropzone
                                        carid={carid}
                                        handleFilesGiven={(files) => {
                                            files && setSelectedImage(files[0])
                                        }}
                                        isSingleImg
                                        hasCropper
                                        coppedImgAspectRatio={1.3}
                                    />
                                ) : null}
                            </ImageWrapper>
                            <RowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={submitAndGoToNextStep}
                                    bg={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_500
                                    }
                                    isDisabled={!carid}
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>
                                {carid ? (
                                    <RowContent style={{ gap: 8 }}>
                                        <IconWrapper $theme={theme}>
                                            <EnterIcon />
                                        </IconWrapper>{' '}
                                        <EnterTxt $theme={theme}>
                                            Or press Enter
                                        </EnterTxt>
                                    </RowContent>
                                ) : null}
                            </RowContent>
                        </JourneyWrapperLeftSideDesktop>
                        <RightSide>
                            <JourneyCardDesktop
                                $theme={theme}
                                $customBorderRadius="24px"
                                $customWidth="600px"
                            >
                                <CardContent>
                                    <ColumnContent style={{ gap: 24 }}>
                                        <Image
                                            src={
                                                theme === 'dark'
                                                    ? placeholder2_dark
                                                    : placeholder2
                                            }
                                            style={{ height: 80 }}
                                        />
                                        <CardTitle $theme={theme}>
                                            {carid &&
                                            carsData &&
                                            carsData[carid]
                                                ? carsData[carid].title
                                                : 'Untitled car'}
                                        </CardTitle>
                                        <CardSubtitle $theme={theme}>
                                            <IconWrapper
                                                $theme={theme}
                                                style={{
                                                    backgroundColor:
                                                        colours[theme]
                                                            .background_neutral_overlay_02,
                                                    border: `1px solid ${colours[theme].background_neutral_overlay_40}`,
                                                    borderRadius: 6,
                                                }}
                                            >
                                                <UserPlainIcon
                                                    size="16"
                                                    color={
                                                        colours[theme]
                                                            .text_neutral_default
                                                    }
                                                />
                                            </IconWrapper>
                                            {`Owned by ${user?.display_name}`}
                                        </CardSubtitle>
                                        <Text $theme={theme}>
                                            My trusty companion, meticulously
                                            crafted to elevate driving
                                            experience. From its sleek exterior
                                            to its powerful performance under
                                            the hood, this car embodies both
                                            style and substance.
                                        </Text>
                                    </ColumnContent>
                                    <CardImageWrapper>
                                        {selectedImage ? (
                                            <UploadedImagePreview
                                                currentImagesUploaded={
                                                    selectedImage
                                                }
                                                uploadProgress={
                                                    imageUploadProgress ?? 0
                                                }
                                            />
                                        ) : carCoverImg ? (
                                            <Image
                                                src={carCoverImg.large_src}
                                                srcSet={
                                                    carCoverImg.large_srcset
                                                }
                                            />
                                        ) : (
                                            <Image
                                                src={
                                                    theme === 'dark'
                                                        ? placeholder1_dark
                                                        : placeholder1
                                                }
                                            />
                                        )}
                                    </CardImageWrapper>
                                </CardContent>
                            </JourneyCardDesktop>
                        </RightSide>
                    </Wrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyPageWrapperMobile
                    title={
                        carCoverImg
                            ? 'Change profile photo of your car'
                            : 'Add profile photo of your car'
                    }
                    sectionID="car_profile"
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        carid && goToPrevStep(carid)
                    }}
                    onNext={submitAndGoToNextStep}
                >
                    <ImageWrapper
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        {selectedImage ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: 0,
                                }}
                            >
                                <ImageUploadBtn
                                    variant="image_preview"
                                    image={selectedImage}
                                    sectionID="car_profile"
                                    width="100%"
                                    height="104px"
                                    removeImage={
                                        selectedImage &&
                                        imageUploadProgress === 100
                                            ? removeImage
                                            : undefined
                                    }
                                />
                            </div>
                        ) : carid ? (
                            <JourneyImagesDropzone
                                carid={carid}
                                handleFilesGiven={(files) => {
                                    files && setSelectedImage(files[0])
                                }}
                                isMobile
                                isSingleImg
                                hasCropper
                                coppedImgAspectRatio={1.3}
                            />
                        ) : null}
                    </ImageWrapper>
                    <JourneyCardMobileWithScroll
                        $theme={theme}
                        $customBorderRadius="24px"
                    >
                        <CardContent
                            style={{
                                gap: 14,
                                height: '100%',
                                width: '100%',
                                boxSizing: 'border-box',
                                overflowY: 'auto',
                                position: 'relative',
                                paddingTop: 92,
                            }}
                        >
                            <MobileCardHeader $theme={theme}>
                                <MobileCardHeaderElement $theme={theme}>
                                    Currently Owned
                                </MobileCardHeaderElement>
                                <MobileCardHeaderElement
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_darker,
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    Previously Owned
                                </MobileCardHeaderElement>
                            </MobileCardHeader>
                            <MobileCardHeader
                                $theme={theme}
                                style={{
                                    top: 44,
                                    height: 40,
                                    border: 'none',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <SwitchSVG />
                                <RowContent style={{ gap: 8 }}>
                                    <SortSquareContainer $theme={theme}>
                                        <SortIcon
                                            size="12"
                                            color={colours[theme].primary}
                                        />
                                    </SortSquareContainer>
                                    <AddSquareContainer $theme={theme}>
                                        <AddIcon
                                            size="12"
                                            colour={
                                                colours[theme]
                                                    .background_default
                                            }
                                        />
                                    </AddSquareContainer>
                                </RowContent>
                            </MobileCardHeader>
                            <CardImageWrapper>
                                {selectedImage ? (
                                    <UploadedImagePreview
                                        currentImagesUploaded={selectedImage}
                                        uploadProgress={
                                            imageUploadProgress ?? 0
                                        }
                                    />
                                ) : carCoverImg ? (
                                    <Image
                                        src={carCoverImg.large_src}
                                        srcSet={carCoverImg.large_srcset}
                                    />
                                ) : (
                                    <Image
                                        src={
                                            theme === 'dark'
                                                ? placeholder1_dark
                                                : placeholder1
                                        }
                                    />
                                )}
                            </CardImageWrapper>
                            <Image
                                src={
                                    theme === 'dark'
                                        ? placeholder2_dark
                                        : placeholder2
                                }
                                style={{ height: 220 }}
                            />
                        </CardContent>
                        <AbsoluteGradient />
                    </JourneyCardMobileWithScroll>
                </JourneyPageWrapperMobile>
            </IpadAndMobileDisplay>
        </div>
    )
}
