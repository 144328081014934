import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IList } from 'entityModels'

import { editFormIDs } from './data'
import {
    IDirectShareDeletionConfirmation,
    IEditFormsState,
    ITimelineCreateForms,
    ITimelineEditForms,
} from './types'

const initialState: IEditFormsState = {
    formIDs: editFormIDs,
    formsData: {
        searchMode: {
            isOpen: false,
        },
        overviewCarEditForm: {
            isOpen: false,
        },
        carGalleryEditForm: {
            isOpen: false,
        },
        carExistingGalleryImageEditForm: {
            isOpen: false,
        },
        carHighlightsEditForm: {
            isOpen: false,
        },
        carDescriptionEditForm: {
            isOpen: false,
        },
        carKeyFactsMobileForm: {
            isOpen: false,
        },
        accountExternalInfoForm: {
            isOpen: false,
        },
        accountPrivateInfoForm: {
            isOpen: false,
            item: undefined,
        },
        userAccountAddressInformationForm: {
            isOpen: false,
        },
        carTechInfoEditForm: {
            isOpen: false,
            item: undefined,
        },
        addCarForm: {
            isOpen: false,
        },
        carBannerImageForm: {
            isOpen: false,
        },
        userProfileImageForm: {
            isOpen: false,
        },
        updateTaskForm: {
            isOpen: false,
        },
        createTaskForm: {
            isOpen: false,
        },
        sortBy: {
            isOpen: false,
        },
        filterBy: {
            isOpen: false,
        },

        qrCodeModal: {
            isOpen: false,
            id: '',
        },
        qrCodeStickerModal: {
            isOpen: false,
            id: '',
        },
        publicLinkDeactivationConfirmationModal: {
            isOpen: false,
        },

        addShareRecipientByEmailForm: {
            isOpen: false,
        },
        isAddGalleryImageToEntryFormOpen: { isOpen: false },
    },
    timelineItemEditForm: {
        isOpen: false,
        category: undefined,
        id: undefined,
        removeHeaderContent: false,
    },
    timelineCreationForm: {
        isOpen: false,
        active_creation: null,
        current_step_index: 0,
    },
    directShareDeletionConfirmation: {
        entity_type: undefined,
        entity_id: undefined,
        share_id: undefined,
        isOpen: false,
    },
    user_dial_code: '+44',
    user_dial_country: 'GB',
    activeArchivingStep: undefined,
}

export const editFormsSlice = createSlice({
    name: 'editForms',
    initialState: initialState,
    reducers: {
        toggleCarOverviewEditForm: (state) => {
            state.formsData.overviewCarEditForm.isOpen =
                !state.formsData.overviewCarEditForm.isOpen
        },

        toggleCarGalleryEditForm: (state) => {
            state.formsData.carGalleryEditForm.isOpen =
                !state.formsData.carGalleryEditForm.isOpen
        },

        toggleExistingCarGalleryImageEditForm: (state) => {
            state.formsData.carExistingGalleryImageEditForm.isOpen =
                !state.formsData.carExistingGalleryImageEditForm.isOpen
        },

        toggleCarHighlightsEditForm: (state) => {
            state.formsData.carHighlightsEditForm.isOpen =
                !state.formsData.carHighlightsEditForm.isOpen
        },
        toggleCarDescriptionEditForm: (state) => {
            state.formsData.carGalleryEditForm.isOpen = false
            state.formsData.overviewCarEditForm.isOpen = false
            state.formsData.carHighlightsEditForm.isOpen = false
            state.formsData.carDescriptionEditForm.isOpen =
                !state.formsData.carDescriptionEditForm.isOpen
        },
        toggleCarKeyFactsEditForm: (state) => {
            state.formsData.carKeyFactsMobileForm.isOpen =
                !state.formsData.carKeyFactsMobileForm.isOpen
        },

        toggleCarTechInfoEditForm: (
            state,
            action: PayloadAction<{
                isOpen: boolean
                item?: IList | undefined
            }>
        ) => {
            state.formsData.carTechInfoEditForm.isOpen = action.payload.isOpen
            state.formsData.carTechInfoEditForm.item = action.payload.item
        },

        toggleAccountExternalInfoEditForm: (
            state,
            action: PayloadAction<IList | undefined>
        ) => {
            state.formsData.carGalleryEditForm.isOpen = false
            state.formsData.overviewCarEditForm.isOpen = false
            state.formsData.carHighlightsEditForm.isOpen = false
            state.formsData.carDescriptionEditForm.isOpen = false
            state.formsData.accountExternalInfoForm.isOpen =
                !state.formsData.accountExternalInfoForm.isOpen
            state.formsData.accountExternalInfoForm.item = action.payload
        },
        toggleAccountPrivateInfoEditForm: (
            state,
            action: PayloadAction<IList | undefined>
        ) => {
            state.formsData.accountPrivateInfoForm.isOpen =
                !state.formsData.accountPrivateInfoForm.isOpen
            state.formsData.accountPrivateInfoForm.item = action.payload
        },
        toggleAccounPrivateAddressEditForm: (state) => {
            state.formsData.userAccountAddressInformationForm.isOpen =
                !state.formsData.userAccountAddressInformationForm.isOpen
        },

        toggleAddACarForm: (state, action: PayloadAction<boolean>) => {
            state.formsData.addCarForm.isOpen = action.payload
        },

        toggleCarBannerImageForm: (state) => {
            state.formsData.carBannerImageForm.isOpen =
                !state.formsData.carBannerImageForm.isOpen
        },

        toggleUserProfileImageForm: (state) => {
            state.formsData.userProfileImageForm.isOpen =
                !state.formsData.userProfileImageForm.isOpen
        },

        toggleUpdateTaskForm: (
            state,
            action: PayloadAction<IList | undefined>
        ) => {
            state.formsData.updateTaskForm = {
                isOpen: !state.formsData.updateTaskForm.isOpen,
                item: action.payload,
            }
        },

        toggleCreateTaskForm: (state) => {
            state.formsData.createTaskForm.isOpen =
                !state.formsData.createTaskForm.isOpen
        },

        toggleFilterByChoice: (state) => {
            state.formsData.filterBy.isOpen = !state.formsData.filterBy.isOpen
        },

        toggleSortByChoice: (state) => {
            state.formsData.sortBy.isOpen = !state.formsData.sortBy.isOpen
        },
        manageTimelineItemEditForm: (
            state,
            action: PayloadAction<ITimelineEditForms>
        ) => {
            state.timelineItemEditForm = {
                isOpen: action.payload.isOpen,
                category: action.payload.category,
                id: action.payload.id,
                removeHeaderContent:
                    action.payload.removeHeaderContent === true ? true : false,
            }
        },

        manageTimelineCreationFormState: (
            state,
            action: PayloadAction<ITimelineCreateForms>
        ) => {
            state.timelineCreationForm = {
                isOpen: action.payload.isOpen,
                current_step_index: action.payload.current_step_index,
                active_creation: action.payload.active_creation,
                removeHeaderContent:
                    action.payload.removeHeaderContent === true ? true : false,
            }
        },
        setSearchModeOff: (state) => {
            state.formsData.searchMode.isOpen = false
        },
        setSearchModeOn: (state) => {
            state.formsData.searchMode.isOpen = true
        },
        toggleAddShareRecipientByEmail: (
            state,
            action: PayloadAction<{ isOpen: boolean }>
        ) => {
            state.formsData.addShareRecipientByEmailForm.isOpen =
                action.payload.isOpen
        },

        toggleQrCodeModal: (
            state,
            action: PayloadAction<{
                isOpen: boolean
                id: string | undefined
            }>
        ) => {
            state.formsData.qrCodeModal = {
                isOpen: action.payload.isOpen,
                id: action.payload.id,
            }
        },

        toggleQrCodeStickerModal: (
            state,
            action: PayloadAction<{
                isOpen: boolean
                id: string | undefined
            }>
        ) => {
            state.formsData.qrCodeStickerModal = {
                isOpen: action.payload.isOpen,
                id: action.payload.id,
            }
        },

        togglePublicLinkDeactivConfModal: (
            state,
            action: PayloadAction<{
                isOpen: boolean
            }>
        ) => {
            state.formsData.publicLinkDeactivationConfirmationModal.isOpen =
                action.payload.isOpen
        },

        toggleShareDeletionConfirmation: (
            state,
            action: PayloadAction<IDirectShareDeletionConfirmation>
        ) => {
            state.directShareDeletionConfirmation = {
                entity_type: action.payload.entity_type,
                entity_id: action.payload.entity_id,
                share_id: action.payload.share_id,
                isOpen: action.payload.isOpen,
            }
        },

        toggleAddGalleryImageToEntryForm: (
            state,
            action: PayloadAction<{
                isOpen: boolean
            }>
        ) => {
            state.formsData.isAddGalleryImageToEntryFormOpen.isOpen =
                action.payload.isOpen
        },

        setUserDialCode: (state, action: PayloadAction<string>) => {
            state.user_dial_code = action.payload
        },

        setUserDialCountry: (state, action: PayloadAction<string>) => {
            state.user_dial_country = action.payload
        },

        setArchivingStep: (
            state,
            action: PayloadAction<'start' | 'reasons' | 'success' | undefined>
        ) => {
            state.activeArchivingStep = action.payload
        },

        resetAllForms: (state) => {
            state.formsData = {
                ...state.formsData,
                searchMode: {
                    isOpen: false,
                },
                overviewCarEditForm: {
                    isOpen: false,
                },
                carGalleryEditForm: {
                    isOpen: false,
                },
                carExistingGalleryImageEditForm: {
                    isOpen: false,
                },
                carHighlightsEditForm: {
                    isOpen: false,
                },
                carDescriptionEditForm: {
                    isOpen: false,
                },
                carKeyFactsMobileForm: {
                    isOpen: false,
                },
                accountExternalInfoForm: {
                    isOpen: false,
                },
                accountPrivateInfoForm: {
                    isOpen: false,
                    item: undefined,
                },
                userAccountAddressInformationForm: {
                    isOpen: false,
                },
                carTechInfoEditForm: {
                    isOpen: false,
                    item: undefined,
                },
                addCarForm: {
                    isOpen: false,
                },
                carBannerImageForm: {
                    isOpen: false,
                },
                userProfileImageForm: {
                    isOpen: false,
                },
                updateTaskForm: {
                    isOpen: false,
                },
                createTaskForm: {
                    isOpen: false,
                },
                sortBy: {
                    isOpen: false,
                },
                filterBy: {
                    isOpen: false,
                },

                qrCodeModal: {
                    isOpen: false,
                    id: '',
                },
                qrCodeStickerModal: {
                    isOpen: false,
                    id: '',
                },
                publicLinkDeactivationConfirmationModal: {
                    isOpen: false,
                },

                addShareRecipientByEmailForm: {
                    isOpen: false,
                },
                isAddGalleryImageToEntryFormOpen: { isOpen: false },
            }
        },
    },
})

export const editFormsActions = editFormsSlice.actions

export default editFormsSlice.reducer
