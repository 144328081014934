import { IDropdownItem, IList } from 'entityModels'
import { useEffect } from 'react'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import {
    OnboardingText,
    PaddingUnit,
} from '../../../../templates/onboarding/onboardingFormLayoutMobile'
import {
    cubicCentimetersToLiters,
    cubicInchesToLiters,
} from '../../../../../helpers/units/unitConversion'
import {
    useAppSelector,
    useAppDispatch,
} from '../../../../../redux/store/hooks'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import Expander from '../../../../atoms/expander/expander'
import { ErrorText } from '../../account/accountAddressForm/accountAddressformDesktop'
import uk_flag from '../../../../../public/assets/flags/gb.png'
import us_flag from '../../../../../public/assets/flags/us.png'
import default_flag from '../../../../../public/assets/flags/default.png'
import CrossIcon from '../../../../atoms/icons/crossIcon'
import styled from 'styled-components'
import SelectSearchBarCarInfoDesktop from '../../technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import { IOnboarding_Car_Data_DVLA } from './confirmCarDataFormOnboardingMobile'
import Select from '../../../../atoms/select/Select'
import { useLocation } from 'react-router'
import { IUser } from '../../../../../redux/user/types'

const RowForm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const AdjustPosition = styled.div`
    margin-left: 24px;
    width: 100%;
    box-sizing: border-box;
`

const EquivalentConverted = styled.div`
    font-size: 12px;
    color: #b3b3b3;
    font-family: Lato;
    padding-left: 8px;
`

const CountryIndicatorWrapper = styled.div`
    width: 24px;
    height: 46px;
    transform: translateX(-15px);
    border-radius: 4px 0px 0px 4px;
    background-color: #012169;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const generateCountryIndicator = (
    selected: string | null,
    countries: IDropdownItem[] | null
) => {
    let countryCode = () =>
        countries
            ? countries.find((contry) => contry.name === selected)
            : undefined

    return (
        <CountryIndicatorWrapper>
            <img
                src={
                    !selected || selected === 'United Kingdom'
                        ? uk_flag
                        : selected === 'United States'
                        ? us_flag
                        : default_flag
                }
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    height: 'auto',
                }}
                alt="country-reg-no"
            />
            <div
                style={{
                    fontFamily: 'UK-Number-Plate',
                    color: '#fff',
                    fontSize: 16,
                }}
            >
                {!selected || selected === 'United Kingdom' || !countryCode
                    ? 'GB'
                    : countryCode()?.uid}
            </div>
        </CountryIndicatorWrapper>
    )
}

type Props = {
    formData: IOnboarding_Car_Data_DVLA
    handleChange: (id: string, value: any) => void
    error: { [key: string]: string | undefined } | undefined
    resetAllFormDataAndReturnToPrevStep: () => void
    isFormVesApi?: boolean
    formText: string
    dvlaText: string
    nonDvlaText: string
}

const ConfirmCarDataFormLeftOnboardingDesktop = (props: Props) => {
    let {
        formData,
        handleChange,
        error,
        resetAllFormDataAndReturnToPrevStep,
        isFormVesApi,
        formText,
        dvlaText,
        nonDvlaText,
    } = props
    let userData: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let appDispatch = useAppDispatch()

    let makesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let transmissionsList = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    let countriesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    let selectedCountry = useAppSelector((state) => {
        return state.localdata.dropdownData.selectedCountry
    })

    let dropdownDataLoading = useAppSelector(
        (state) => state.localdata.dropdownData.loading
    )

    const drive_side_options = [
        {
            name: 'LHD',
        },
        {
            name: 'RHD',
        },
        {
            name: 'Center',
        },
    ]

    useEffect(() => {
        if (!transmissionsList) {
            appDispatch(
                dropDownActions.setTransmissionTypesDropdownDataRequest()
            )
        }

        if (!makesDropdowndata) {
            appDispatch(dropDownActions.setMakeDropdownDataRequest())
        }
    }, [])

    let user_fuel_unit_ids_list =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].ids_list

    let user_fuel_unit_data =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].data

    let fuel_units: IList[] | null =
        user_fuel_unit_data &&
        user_fuel_unit_ids_list &&
        user_fuel_unit_ids_list.map((id: string, index: number) => {
            // @ts-ignore
            return user_fuel_unit_data[id]
        })

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let countryParam = queryParams.get('country')
    let country = countryParam
        ? decodeURIComponent(countryParam)
        : 'United Kingdom'

    return (
        <>
            <PaddingUnit $times={2} />
            <OnboardingText>{formText}</OnboardingText>
            <PaddingUnit $times={2} />
            {formData.registration_number &&
                `${formData.registration_number}`?.length > 0 && (
                    <>
                        <PaddingUnit $times={3} />
                        <InputFieldNoFormikAnimated
                            value={formData.registration_number}
                            name={''}
                            placeholder=""
                            readonly={true}
                            valueStart={generateCountryIndicator(
                                selectedCountry,
                                countriesDropdowndata
                            )}
                            paddingLeft="0px"
                            font_family="UK-Number-Plate"
                            fontSize="32px"
                            rightAdornment={
                                <button
                                    style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        width: 40,
                                        height: 40,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        cursor: 'pointer',
                                    }}
                                    onClick={
                                        resetAllFormDataAndReturnToPrevStep
                                    }
                                >
                                    <CrossIcon />
                                </button>
                            }
                        />
                        <PaddingUnit $times={5} />
                    </>
                )}

            {isFormVesApi ? (
                <>
                    <OnboardingText>{dvlaText}</OnboardingText>
                    <PaddingUnit $times={5} />
                </>
            ) : (
                <>
                    {country !== 'United Kingdom' && (
                        <>
                            <OnboardingText>{nonDvlaText}</OnboardingText>
                            <PaddingUnit $times={5} />
                        </>
                    )}

                    <SelectSearchBarCarInfoDesktop
                        placeholder={formData.make ? 'Make *' : 'Make *'}
                        handleSelectChange={(name: string, other?: string) => {
                            handleChange('make', name)
                            appDispatch(
                                dropDownActions.setModelDropdownDataRequest({
                                    makeName: name,
                                    make_uid: other,
                                })
                            )
                        }}
                        addAnItemFunc={(id: string, name: string) => {
                            appDispatch(
                                dropDownActions.setIsLoadingNewMakeOrModel(true)
                            )
                            appDispatch(
                                dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                    {
                                        dropdownTargetId: id,
                                        name: name,
                                    }
                                )
                            )
                        }}
                        item={{
                            answer: formData.make,
                            id: 'make',
                            text: 'make *',
                        }}
                        items={makesDropdowndata ? makesDropdowndata : []}
                        formCurrentValue={formData.make}
                        dropdownTargetId="carMakes"
                        allowAdd={true}
                        onlyShowMatch={true}
                        customMaxWidth="100%"
                        borderColor={
                            error?.make ? 'rgb(223, 111, 111)' : undefined
                        }
                        field_id={'make'}
                        sendId={true}
                    />
                    <Expander height={error?.make ? 'auto' : 0} width={'100%'}>
                        <ErrorText>{error?.make}</ErrorText>
                    </Expander>
                    <PaddingUnit $times={3} />
                    <SelectSearchBarCarInfoDesktop
                        handleSelectChange={(name: string) => {
                            handleChange('model', name)
                        }}
                        sendId={true}
                        addAnItemFunc={(id: string, name: string) => {
                            appDispatch(
                                dropDownActions.setIsLoadingNewMakeOrModel(true)
                            )
                            appDispatch(
                                dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                    {
                                        dropdownTargetId: id,
                                        name: name,
                                        relevantOtherLinkedDataMakeMake:
                                            formData.make,
                                    }
                                )
                            )
                        }}
                        field_id={'model'}
                        item={{
                            answer: formData.model,
                            id: 'model',
                            text: 'model *',
                        }}
                        placeholder={
                            dropdownDataLoading
                                ? 'Models loading ... '
                                : formData.model
                                ? 'Model *'
                                : 'Model *'
                        }
                        dropdownTargetId="carModels"
                        items={modelsDropdowndata ? modelsDropdowndata : []}
                        formCurrentValue={formData.model}
                        allowAdd={true}
                        isDisabled={
                            dropdownDataLoading || !formData.make ? true : false
                        }
                        customMaxWidth="100%"
                        borderColor={
                            error?.model ? 'rgb(223, 111, 111)' : undefined
                        }
                    />
                    <Expander height={error?.model ? 'auto' : 0} width={'100%'}>
                        <ErrorText>{error?.model}</ErrorText>
                    </Expander>
                    <PaddingUnit $times={3} />
                    <InputFieldNoFormikAnimated
                        value={formData.year}
                        name={'Year *'}
                        placeholder=""
                        type={'number'}
                        onChange={(e: any) => {
                            handleChange('year', e.target.value)
                        }}
                        linecolor={
                            error?.year ? 'rgb(223, 111, 111)' : undefined
                        }
                    />
                    <Expander height={error?.year ? 'auto' : 0} width={'100%'}>
                        <ErrorText>{error?.year}</ErrorText>
                    </Expander>
                    <div style={{ paddingTop: 32 }} />
                    <RowForm>
                        <InputFieldNoFormikAnimated
                            name="Engine size *"
                            placeholder={'Engine size'}
                            tabindex={1}
                            aria-live="polite"
                            value={
                                formData.engine_capacity !== undefined &&
                                typeof formData.engine_capacity === 'number' &&
                                formData.engine_capacity_unit !==
                                    'cubic_centimeters'
                                    ? formData.engine_capacity.toFixed(1)
                                    : formData.engine_capacity
                            }
                            font_family="Lato"
                            type="number"
                            isnarrow={true}
                            onChange={(e: any) => {
                                handleChange('engine_capacity', e.target.value)
                            }}
                        />

                        <AdjustPosition>
                            <Select
                                defaultValue={formData.engine_capacity_unit}
                                onChange={(val: string | undefined) => {
                                    handleChange('engine_capacity_unit', val)
                                }}
                                placeholder={'Unit'}
                                options={
                                    fuel_units
                                        ? fuel_units.map((unit: IList) => {
                                              return {
                                                  value: unit?.id,
                                                  label: `${unit?.short}`,
                                              }
                                          })
                                        : []
                                }
                            />
                        </AdjustPosition>
                    </RowForm>
                    <Expander
                        height={error?.engine_capacity ? 'auto' : 0}
                        width={'100%'}
                    >
                        <ErrorText>{error?.engine_capacity}</ErrorText>
                    </Expander>
                    {!error?.engine_capacity && (
                        <Expander
                            height={
                                formData.engine_capacity_unit !== 'liters' &&
                                formData.engine_capacity
                                    ? 'auto'
                                    : 0
                            }
                            width="100%"
                        >
                            <EquivalentConverted>
                                Equal to{' '}
                                {formData.engine_capacity &&
                                    (formData.engine_capacity_unit === 'cc' ||
                                    formData.engine_capacity_unit ===
                                        'cubic_centimeters'
                                        ? cubicCentimetersToLiters(
                                              +formData.engine_capacity
                                          ).toFixed(1)
                                        : formData.engine_capacity_unit ===
                                              'ci' ||
                                          formData.engine_capacity_unit ===
                                              'cubic_inches'
                                        ? cubicInchesToLiters(
                                              +formData.engine_capacity
                                          )
                                        : null)}{' '}
                                litres
                            </EquivalentConverted>
                        </Expander>
                    )}
                    <div style={{ paddingTop: '16px' }} />
                    <Select
                        defaultValue={formData.transmission}
                        placeholder={
                            formData.transmission
                                ? 'Transmission *'
                                : 'Transmission'
                        }
                        onChange={(val: string) => {
                            handleChange('transmission', val)
                        }}
                        options={
                            transmissionsList
                                ? transmissionsList.map(
                                      (unit: IDropdownItem) => ({
                                          value: unit?.uid,
                                          label: `${unit?.name}`,
                                      })
                                  )
                                : []
                        }
                    />
                    <PaddingUnit $times={3} />
                    <Select
                        options={drive_side_options.map(
                            (option: { name: string }) => ({
                                value: option.name,
                                label: option.name,
                            })
                        )}
                        placeholder={
                            formData.drive_side ? 'Drive Side *' : 'Drive Side'
                        }
                        defaultValue={formData.drive_side}
                        onChange={(name) => {
                            handleChange('drive_side', name)
                        }}
                    />
                </>
            )}
        </>
    )
}

export default ConfirmCarDataFormLeftOnboardingDesktop
