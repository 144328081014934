import styled from 'styled-components'

import Ellipsis from './ellipsis'

type IStyledProps = {
    height: string
    width: string
}

const BackgroundContainer = styled.div<IStyledProps>`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--off-bg-color, #fafafa);
    width: ${(props) => `${props.width}`};
    height: ${(props) => `${props.height}`};
    border-radius: 10px;
`

const GradientBg = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    background: linear-gradient(
        89.96deg,
        var(--bg-color, #fff) 1.26%,
        rgba(255, 255, 255, 0) 50.16%,
        var(--bg-color, #fff) 99.06%
    );
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border-radius: 10px;
`

const GradientBg2 = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(
        180deg,
        var(--off-bg-color, #fafafa) 0%,
        rgba(255, 255, 255, 0) 50%,
        var(--off-bg-color, #fafafa) 100%
    );
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border-radius: 10px;
`

type Props = {
    height: string
    width: string
}

const LoaderBackground = (props: Props) => (
    <BackgroundContainer height={props.height} width={props.width}>
        <GradientBg />
        <GradientBg2 />
        <Ellipsis size={10} />
    </BackgroundContainer>
)

export default LoaderBackground
