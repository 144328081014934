import styled from 'styled-components'
import { convertShowroomEntryPriceInfoToUIString } from '../../../../helpers/typeConv/showroom'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import EditIcon from '../../../atoms/statefulicons/editIcon'

type Props = {
    isLoading: boolean
    entry: IShowroomEntry | undefined
    isAdmin?: boolean
    setActiveForm?: () => any
    customOpenGeneralInfoFunc?: () => any
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    width: 100%;
    box-sizing: border-box;
    height: auto;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-right: 50px;
    padding-left: 1.5rem;
`

const Title = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: var(--text-strong, #1a1a1a);
    width: 100%;
    box-sizing: border-box;
`

const Description = styled.div`
    font-family: Lato-semibold;
    font-style: normal;
    color: var(--text-default, #666666);
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 32px;
    padding-right: 50px;
    padding-left: 1.5rem;
`

const Price = styled.div`
    font-family: Lato-semibold;
    font-size: 22px;
    line-height: 32px;
    color: var(--text-default, #666666);
    padding-right: 15px;
`

const Rectangle = styled.div`
    width: 80px;
    height: 2px;
    background: var(--primary, #5ec3ca);
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 6px;
    padding: 0;
`

const Row2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
`

const LocalPrice = styled.div`
    font-family: Lato;
    color: var(--text-muted, #b3b3b3);
    line-height: 32px;
    font-weight: 400;

    font-size: 16px;
`

const OverviewSectionMobile = (props: Props) => {
    const { entry, customOpenGeneralInfoFunc, isAdmin } = props

    let car = entry?.car

    let price: string =
        entry?.state === 'SOLD'
            ? 'SOLD'
            : entry
            ? convertShowroomEntryPriceInfoToUIString(entry).listing_price
            : '£'

    let price_local: string =
        entry?.state === 'SOLD'
            ? 'SOLD'
            : entry
            ? convertShowroomEntryPriceInfoToUIString(entry).local_price
            : '£'

    return (
        <Container>
            {car ? (
                <ContentContainer>
                    <TitleContainer>
                        <Row>
                            <Title>{car.title ? car.title : 'Untitled'} </Title>

                            {isAdmin && (
                                <div
                                    style={{
                                        transform: 'translateY(10px)',
                                        paddingLeft: '10px',
                                    }}
                                    role="button"
                                >
                                    <EditIcon
                                        onClick={() => {
                                            if (
                                                customOpenGeneralInfoFunc !==
                                                undefined
                                            ) {
                                                customOpenGeneralInfoFunc()
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </Row>

                        <div style={{ paddingTop: '8px' }} />

                        <Row2>
                            <Price>{price}</Price>

                            {entry &&
                                entry?.state !== 'SOLD' &&
                                entry?.listing_currency !==
                                    entry?.local_currency && (
                                    <LocalPrice>{price_local}</LocalPrice>
                                )}
                        </Row2>
                    </TitleContainer>

                    <div style={{ paddingTop: '23px' }} />
                    <Rectangle />
                    <div style={{ paddingTop: '23px' }} />
                    <Description>{car.short_description}</Description>
                </ContentContainer>
            ) : null}
        </Container>
    )
}

export default OverviewSectionMobile
