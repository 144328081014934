import {
    IAllApex_interviewss_overviews_list_api,
    IApexInterviewCategory,
    IApexInterviewOverviewItemState,
    IApexInterviewOverviewsPageData,
} from 'apexModels'
import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'
import {
    convertPrismicInterviewsOverviewFromAPI_array,
    convertPrismicInterviewsOverviewFromAPI_single,
} from '../../conversions/apex/apexInterviewOverviews'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { apexActions } from '../reducer'
import { IReduxError } from '../../entities/cars/types'

// GET APEX INTERVIEW OVERVIEWS DATA

// GET APEX LATEST APEX INTERVIEW OVERVIEW

function* getApexInterviewOverviewByCategorySubSaga(
    categoryname: IApexInterviewCategory
): any {
    try {
        const category_1_interview_overviews: IAllApex_interviewss_overviews_list_api =
            yield call(
                api.apexQuery.apexInterviewQueries
                    .apexCategoryInterviewOverviewsQuery,
                categoryname
            )

        // do conversion

        if (category_1_interview_overviews) {
            let converted_array: IApexInterviewOverviewItemState[] =
                convertPrismicInterviewsOverviewFromAPI_array(
                    category_1_interview_overviews
                )

            return converted_array
        } else
            yield put(
                apexActions.getApexInterviewOverviewsError({
                    default: 'no data found',
                })
            )
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)
        yield put(apexActions.getApexInterviewOverviewsError(typedError))
    }
}

// GET APEX APEX INTERVIEW OVERVIEWS BY CATEGORY

function* getApexLatestInterviewOverviewSubSaga(): any {
    try {
        const latest_interview_overview: IAllApex_interviewss_overviews_list_api =
            yield call(
                api.apexQuery.apexInterviewQueries
                    .apexlatestInterviewOverviewQuery
            )

        // do conversion

        if (latest_interview_overview) {
            let converted_item: IApexInterviewOverviewItemState =
                convertPrismicInterviewsOverviewFromAPI_single(
                    latest_interview_overview
                )

            return converted_item
        } else
            yield put(
                apexActions.getApexInterviewOverviewsError({
                    default: 'no data found',
                })
            )
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)
        yield put(apexActions.getApexInterviewOverviewsError(typedError))
    }
}

function* getApexInterviewOverviewDataSubSaga(): any {
    let latest_interview: IApexInterviewOverviewItemState | undefined =
        yield call(getApexLatestInterviewOverviewSubSaga)

    let category_1: IApexInterviewOverviewItemState[] = yield call(
        getApexInterviewOverviewByCategorySubSaga,
        'category_1'
    )

    let category_2: IApexInterviewOverviewItemState[] = yield call(
        getApexInterviewOverviewByCategorySubSaga,
        'category_2'
    )

    let category_3: IApexInterviewOverviewItemState[] = yield call(
        getApexInterviewOverviewByCategorySubSaga,
        'category_3'
    )

    let category_4: IApexInterviewOverviewItemState[] = yield call(
        getApexInterviewOverviewByCategorySubSaga,
        'category_4'
    )

    let apexInterviewsOverviewState: IApexInterviewOverviewsPageData = {
        latest_interview: latest_interview,
        category_ids_list: [
            'category_1',
            'category_2',
            'category_3',
            'category_4',
        ],
        categories_information_list: {
            category_1: {
                id: 'category_1',
                category_name: 'Motorsport',
            },
            category_2: {
                id: 'category_2',

                category_name: 'Industry Insights',
            },
            category_3: {
                id: 'category_3',

                category_name: 'Designers & Creators',
            },
            category_4: {
                id: 'category_4',

                category_name: 'Drivers & Collectors',
            },
        },

        interview_overview_objects_by_category: {
            category_1,
            category_2,
            category_3,
            category_4,
        },
    }
    yield put(
        apexActions.getApexInterviewOverviewsSuccess(
            apexInterviewsOverviewState
        )
    )
    return
}

function* watcherGetApexInterviewOverviews() {
    while (true) {
        yield take(apexActions.getApexInterviewOverviewsRequest)

        yield call(getApexInterviewOverviewDataSubSaga)
    }
}

const get_apex_interview_overviews: any[] = [
    fork(watcherGetApexInterviewOverviews),
]

export default get_apex_interview_overviews
