import React from 'react'
import styled from 'styled-components'
import { device } from '../displays/devices'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { menuActions } from '../../../redux/menus/reducer'
import { isAnyFormOpen } from '../../../helpers/booleanChecks/isAnyFormOpen'
import * as NavigationIcons from '../../atoms/statefulicons'
import ActionsMenuModalMobile from '../../atoms/menu/mainmenu/actionsMenuModalMobile'
import MainSubmenuBottomSheetMobile from '../../atoms/menu/bottomSheetMenu/mainSubmenuBottomSheetMobile'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { motion } from 'framer-motion'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { TimelineCreationTypeIdsEnum } from '../../../redux/timeline/timelineEnum'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { ITimelineCreateForms } from '../../../redux/editForms/types'

type FabProps = {
    isOpen?: boolean
}

const Fabs = styled(motion.div)<FabProps>`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }

    position: fixed;
    z-index: 5;

    right: 20px;

    opacity: ${(props) => (props.isOpen ? '0' : '1')};
    transition: all 500ms;
`

interface IProps {
    hasAdd?: boolean
    hasSubNav?: boolean
}

const FabsMenusMobile: React.FC<IProps> = ({ hasAdd, hasSubNav }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    const isMobileAppSubMenuOpen = useAppSelector(
        (state) => state.menus.mobileAppSubMenu.isOpen
    )
    const formsData = useAppSelector((state) => state.editForms.formsData)
    const formIDs = useAppSelector((state) => state.editForms.formIDs)
    const isActionsMenuOpen = useAppSelector(
        (state) => state.menus.isAddActionsOpen.isOpen
    )

    const carsData = useAppSelector((state) => state.entities.carsData)
    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )

    const currentExternalCarId = useAppSelector(
        (state) => state.entities.carsData.currentCarID_ext_directShare
    )
    const toggleMobileAppSubMenu = (isOpen: boolean) =>
        dispatch(menuActions.toggleMobileAppSubMenu(isOpen))
    const toggleAddActions = (id: string) =>
        dispatch(menuActions.toggleAddActions(id))
    const setCountriesCodeDropdownData = () =>
        dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
    const toggleAddACarForm = (isOpen: boolean) =>
        dispatch(editFormsActions.toggleAddACarForm(isOpen))
    const toggleCreateTaskForm = () =>
        dispatch(editFormsActions.toggleCreateTaskForm())
    const manageTimelineCreationFormState = (obj: ITimelineCreateForms) =>
        dispatch(editFormsActions.manageTimelineCreationFormState(obj))

    const isAnyFormOpened = isAnyFormOpen(formIDs, formsData)

    const currentPath = location.pathname
    const searchParams = new URLSearchParams(location.search)

    const carid = params.carid

    const addACar = () => {
        setCountriesCodeDropdownData()
        toggleAddACarForm(true)
    }

    const addTimelineEntry = () => {
        navigate(`/car/${carid}/history-file/create`)
        manageTimelineCreationFormState({
            isOpen: true,
            current_step_index: 0,
            active_creation: TimelineCreationTypeIdsEnum.add_new,
        })
    }

    const activePage = location.pathname
    const arr = location.pathname.split('/')
    const overviewCarPage = (): boolean => {
        if (arr.includes('history-file')) return false
        if (arr.includes('tasks')) return false
        if (arr.includes('technical-information')) return false
        if (arr.includes('gallery')) return false
        if (arr.includes('sharing')) return false
        if (arr.includes('archive')) return false
        return true
    }

    const isCarOverview = overviewCarPage()

    React.useEffect(() => {
        if (isActionsMenuOpen || isMobileAppSubMenuOpen) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [isActionsMenuOpen, isMobileAppSubMenuOpen])

    return (
        <>
            {currentPath !== '/garage' &&
                !currentPath.match(/tasks/g) &&
                !currentPath.match(/shared/g) && (
                    <ActionsMenuModalMobile
                        activepage={activePage}
                        isOpen={isActionsMenuOpen}
                        toggle={() => toggleAddActions(activePage)}
                    />
                )}
            {!isActionsMenuOpen && (
                <MainSubmenuBottomSheetMobile
                    activepage={activePage}
                    isOpen={isMobileAppSubMenuOpen}
                    toggle={toggleMobileAppSubMenu}
                    currentCarID={carid}
                    carsData={carsData}
                    externalCarsData={externalCarsData}
                    currentExternalCarId={currentExternalCarId}
                />
            )}

            {!isAnyFormOpened && !isActionsMenuOpen && !isCarOverview && (
                <Fabs
                    style={{
                        bottom:
                            arr.includes('history-file') || arr.includes('car')
                                ? '50px'
                                : '110px',
                    }}
                >
                    {hasAdd && !searchParams.get('onboarding') && (
                        <NavigationIcons.FabIconAdd
                            dataCyId={undefined}
                            isOpen={isMobileAppSubMenuOpen}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault()
                                if (currentPath === '/garage') {
                                    addACar()
                                } else if (currentPath.match(/tasks/g)) {
                                    toggleCreateTaskForm()
                                } else if (currentPath.match(/history-file/g)) {
                                    addTimelineEntry()
                                } else {
                                    toggleAddActions(activePage)
                                }
                            }}
                        />
                    )}
                </Fabs>
            )}
        </>
    )
}

export default FabsMenusMobile
