import styled from 'styled-components'
import FullPageWrapperShowroom from '../../templates/displays/pageWrappers/fullPageWrapperShowroom'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { device } from '../../templates/displays/devices'
import ShowroomH1 from '../../atoms/typography/showroom/ShowroomH1'
import ShowroomCardDesktop from '../../molecules/showroom/card/showroomCardDesktop'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IShowroomEntryAPI } from '../../../redux/showroom/types'
import { useEffect, useRef, useState } from 'react'
import { showroomActions } from '../../../redux/showroom/reducer'
import ShowroomNoResultsBox from '../../atoms/showroom/showroomNoResultsBox'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { CardSkeletonItems } from './showroom'
import Faded from '../../templates/animated/faded'
import { useScrollDirectionInsideADiv } from '../../../helpers/hooks/checkScrollDirection'
import ScrollToTopBtn from '../../atoms/Button/scrollToTopBtn'
import ShowroomPublicFooterMobile from '../../templates/footer/showroomPublicFooter/showroomPublicFooterMobile'
import ShowroomPublicFooterDesktop from '../../templates/footer/showroomPublicFooter/showroomPublicFooterDesktop'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import BottomPaginationNavigation from '../../templates/pagination/bottomPaginationNavigation'
import { useNavigate } from 'react-router-dom'
import { usersActions } from '../../../redux/user/reducer'

export interface ICarShowroomSpecs {
    make: string
    model: string
    year: string
    engine_size: string
    transmission: string
    drive_side: string
    mileage: string
    location: string
}

const ShowroomCarContainer = styled.div`
    z-index: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
    row-gap: 3rem;
    transition: all 100ms;
    padding-bottom: 2rem;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow-y: none;

    @media ${device.ipad} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.smallest_laptops} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 880px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2rem;
    }

    @media ${device.ipad} {
        row-gap: 4rem;
    }

    @media (min-width: 2300px) {
        grid-template-columns: repeat(4, 1fr);
    }
`

const SectionWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;

    /* padding-left: 30px; */
    /* padding-right: 30px; */

    @media ${device.desktop} {
        max-width: 83vw;
        padding-left: 10px;
    }

    @media ${device.large_desktop} {
        max-width: 80vw;
        padding-left: 30px;
    }

    @media screen and (min-width: 1900px) {
        max-width: 1600px;
    }
    @media screen and (min-width: 2300px) {
        max-width: 1800px;
    }

    @media ${device.smallest_laptops} {
        max-width: 80vw;
        /* padding-left: 20px; */
        padding-right: 20px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: center;

    @media ${device.mobile_and_ipad} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const PageWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    @media (min-width: 2200px) {
        padding-top: 20px;
        /* padding-left: 50px; */
        /* padding-right: 50px; */
    }

    @media ${device.mobile_and_ipad} {
        padding-top: 122px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    @media (min-width: 500px) and (max-width: 880px) {
        padding-top: 132px;
    }
`

const SubSectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const WatchlistPage = () => {
    let entriesList: IShowroomEntryAPI[] | undefined = useAppSelector(
        (state) => {
            return state.showroom.watchlist_results
        }
    )

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const watchlistInnerRefDesktop: any = useRef()
    const watchlistInnerRefMobile: any = useRef()

    let currentPageNumberWatchlist: number = useAppSelector((state) => {
        return state.showroom.currentPageNumber_watchlist
    })

    let watchlist_count: number | undefined = useAppSelector((state) => {
        return state.showroom.watchlist_results_total_count
    })

    const [prevPage, setPrevPage] = useState(currentPageNumberWatchlist - 1)

    // let refresh_token: string | null =
    //     typeof localStorage !== 'undefined'
    //         ? localStorage.getItem('refresh_token')
    //         : null

    let isSearchLoading: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isSearchLoading
    })

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
        if (userLoggedIn) {
            if (currentPageNumberWatchlist !== 0) {
                dispatch(showroomActions.resetWatchlistEntries())
                setPrevPage(-1)
            }

            if (!isSearchLoading) {
                dispatch(showroomActions.getWatchlistEntriesRequest())
            }
        }
    }, [userLoggedIn])

    const checkIfIsLast = () => {
        if (
            watchlist_count !== undefined &&
            entriesList &&
            (entriesList.length === watchlist_count ||
                entriesList.length > watchlist_count)
        ) {
            return true
        }

        if (!watchlist_count) {
            return false
        }

        if (!entriesList) {
            return false
        }

        return false
    }

    useEffect(() => {
        // this is the saga
        const fetchData = () => {
            if (!checkIfIsLast() && !isSearchLoading) {
                if (userLoggedIn) {
                    dispatch(showroomActions.getWatchlistEntriesRequest())
                }
                setPrevPage(currentPageNumberWatchlist)
            }
        }
        if (prevPage !== currentPageNumberWatchlist) {
            // call search
            if (!checkIfIsLast()) {
                fetchData()
            }
        }
    }, [currentPageNumberWatchlist, prevPage])

    let itemsPerPage: number = useAppSelector((state) => {
        return state.showroom.itemsNumberPerPage
    })

    const navigate = useNavigate()
    let handleChangeSearchEffectsReset = () => {
        // reset

        dispatch(showroomActions.setCurrentPageNumber_watchlist(0))

        dispatch(showroomActions.resetWatchlistEntries())

        setPrevPage(-1)
        // window.scrollTo({ top: 0, behavior: 'smooth' })
        let currURL = new URL(window.location.href)
        let params2 = new URLSearchParams(currURL.search)
        params2.delete('page')
        params2.append('page', `${1}`)

        if (anchor_entry_params) {
            params2.delete('anchor_entry')
        }

        navigate({
            search: params2.toString(),
        })

        scrollSmoothTop()
    }

    let scrollSmoothTop = () => {
        if (isMobile) {
            watchlistInnerRefMobile?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        } else {
            watchlistInnerRefDesktop?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        }
    }

    const [showScrollToTopBtn, setShowScrollToTopBtn] = useState(false)

    const checkIfScrollToTopBtnIsVisible = (
        ref: any,
        scrollDirection: string | null
    ) => {
        if (ref.current) {
            const { scrollTop, clientHeight } = ref.current
            if (scrollTop > clientHeight / 2 && scrollDirection === 'down') {
                setShowScrollToTopBtn(true)
            } else if (scrollTop === 0) {
                setShowScrollToTopBtn(false)
            }
        }
    }

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    const scrollDirection = useScrollDirectionInsideADiv(
        isMobile ? watchlistInnerRefMobile : watchlistInnerRefDesktop
    )

    let scrollToElement = (div: HTMLElement | undefined) => {
        if (div) {
            scrollIntoView(div, {
                behavior: 'smooth',
                block: 'nearest',
            })
        }
    }

    let params = new URLSearchParams(location.search)
    let anchor_entry_params = params.get('anchor_entry')

    let entryIDtoScrollTo = !anchor_entry_params
        ? undefined
        : isMobile
        ? `mobile-${anchor_entry_params}`
        : `desktop-${anchor_entry_params}`

    let entryDiv = entryIDtoScrollTo
        ? document.getElementById(entryIDtoScrollTo)
        : undefined

    useEffect(() => {
        if (entryDiv) {
            scrollToElement(entryDiv)
        }
    }, [entryDiv])

    // to avoid any looping edge cases
    const [hasResetN, setHasResetN] = useState<number>(0)
    let pageN = params.get('page')

    function isPageValid(
        totalCount: number,
        itemsPerPage: number,
        page: number
    ): boolean {
        const totalPages = Math.ceil(totalCount / itemsPerPage)
        return page <= totalPages
    }

    useEffect(() => {
        setTimeout(() => {
            if (
                hasResetN < 3 &&
                !isSearchLoading &&
                watchlist_count !== undefined &&
                (!entriesList || entriesList?.length === 0) &&
                pageN &&
                typeof +pageN === 'number' &&
                isPageValid(watchlist_count, itemsPerPage, +pageN) === false
            ) {
                handleChangeSearchEffectsReset()

                setHasResetN(hasResetN + 1)
            }
        }, 100)
    }, [watchlist_count, isSearchLoading])

    let onClickPageNumber = (n: number) => {
        scrollSmoothTop()
        let currURL = new URL(window.location.href)
        const params2 = new URLSearchParams(currURL.search)

        if (anchor_entry_params) {
            params2.delete('anchor_entry')
        }

        params2.delete('page')
        params2.append('page', `${n}`)
        navigate({ search: params2.toString() })
        dispatch(showroomActions.setCurrentPageNumber_watchlist(n - 1))
        setPrevPage(n - 2)
    }

    let totalPages = Math.ceil(
        (watchlist_count ? watchlist_count : 0) / itemsPerPage
    )

    let isLastPage = currentPageNumberWatchlist + 1 === totalPages

    const onClickNext = () => {
        scrollSmoothTop()
        const currOage = currentPageNumberWatchlist

        dispatch(showroomActions.setCurrentPageNumber_watchlist(currOage + 1))
        setPrevPage(currOage)

        let currURL = new URL(window.location.href)
        let params2 = new URLSearchParams(currURL.search)

        params2.delete('page')
        params2.append('page', `${currOage + 2}`)

        navigate({
            search: params2.toString(),
        })
    }

    const onClickPrev = () => {
        scrollSmoothTop()
        let currURL = new URL(window.location.href)
        let params2 = new URLSearchParams(currURL.search)

        params2.delete('page')
        params2.append('page', `${currentPageNumberWatchlist}`)

        navigate({
            search: params2.toString(),
        })

        dispatch(
            showroomActions.setCurrentPageNumber_watchlist(
                currentPageNumberWatchlist - 1
            )
        )
        setPrevPage(currentPageNumberWatchlist - 2)
    }

    let cardAnchoredRefDesktop: any = useRef()
    let cardAnchoredRefMobile: any = useRef()

    return (
        <>
            <DesktopDisplayOnly>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 100,
                        right: 80,
                        zIndex: showScrollToTopBtn ? 5 : -1,
                        opacity: showScrollToTopBtn ? 1 : 0,
                        transition: 'all 200ms',
                    }}
                >
                    <ScrollToTopBtn
                        onClick={() => {
                            watchlistInnerRefDesktop?.current?.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            })
                        }}
                    />
                </div>
                <PageWrapper>
                    <div
                        style={{
                            height: '100vh',
                            overflowY: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'flex-start',
                        }}
                        key={'desktop-scroll-watchlist'}
                        onScroll={() => {
                            // check if we show scroll to top btn
                            checkIfScrollToTopBtnIsVisible(
                                watchlistInnerRefDesktop,
                                scrollDirection
                            )

                            // fetch data if necessary
                            // if (!isMobile) {
                            //     onScroll(watchlistInnerRefDesktop)
                            // }
                        }}
                        ref={watchlistInnerRefDesktop}
                    >
                        <FullPageWrapperShowroom>
                            <div
                                style={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    //width: '100%',
                                    boxSizing: 'border-box',
                                    flexDirection: 'column',
                                    overflowX: 'hidden',
                                }}
                            >
                                <SectionWrapper>
                                    <SubSectionWrapper>
                                        <ShowroomH1 leftAlign>
                                            Watch list
                                        </ShowroomH1>
                                    </SubSectionWrapper>

                                    {!isSearchLoading &&
                                        (watchlist_count === 0 ||
                                            !userLoggedIn) && (
                                            <ShowroomNoResultsBox page="watchlist" />
                                        )}

                                    {!isMobile &&
                                        watchlist_count !== 0 &&
                                        entriesList &&
                                        entriesList.length > 0 && (
                                            <ShowroomCarContainer>
                                                {entriesList.map(
                                                    (
                                                        entry: IShowroomEntryAPI
                                                    ) => {
                                                        return (
                                                            <Faded
                                                                key={`desktop-${entry.uid}`}
                                                            >
                                                                <ShowroomCardDesktop
                                                                    watchlist_entry={
                                                                        entry
                                                                    }
                                                                    user={
                                                                        userLoggedIn ??
                                                                        undefined
                                                                    }
                                                                    reset={
                                                                        handleChangeSearchEffectsReset
                                                                    }
                                                                    refCreated={
                                                                        anchor_entry_params ===
                                                                        entry.uid
                                                                            ? cardAnchoredRefDesktop
                                                                            : undefined
                                                                    }
                                                                />
                                                            </Faded>
                                                        )
                                                    }
                                                )}

                                                {isSearchLoading &&
                                                    !checkIfIsLast() && (
                                                        <CardSkeletonItems
                                                            height="25vh"
                                                            width="95%"
                                                            paddingBottom="0px"
                                                            narr={[
                                                                1, 2, 3, 4, 5,
                                                                6,
                                                            ]}
                                                        />
                                                    )}
                                            </ShowroomCarContainer>
                                        )}

                                    {isSearchLoading &&
                                        (!entriesList ||
                                            (entriesList &&
                                                entriesList.length < 0 && (
                                                    <CardSkeletonItems
                                                        height="25vh"
                                                        width="95%"
                                                        paddingBottom="0px"
                                                        narr={[
                                                            1, 2, 3, 4, 5, 6,
                                                        ]}
                                                    />
                                                )))}
                                    {!entriesList ||
                                    checkIfIsLast() ||
                                    (entriesList &&
                                        entriesList.length === 0) ? (
                                        <div
                                            style={{
                                                paddingBottom: '24px',
                                            }}
                                        />
                                    ) : null}

                                    <BottomPaginationNavigation
                                        results={entriesList}
                                        currentPage={currentPageNumberWatchlist}
                                        isLastPage={isLastPage}
                                        onClickPrev={onClickPrev}
                                        onClickNext={onClickNext}
                                        onClickPageNumber={onClickPageNumber}
                                        totalCount={watchlist_count ?? 0}
                                        itemsPerPage={itemsPerPage}
                                    />

                                    {!userLoggedIn ? (
                                        <ShowroomPublicFooterDesktop />
                                    ) : (
                                        <div style={{ paddingBottom: 300 }} />
                                    )}
                                </SectionWrapper>
                            </div>
                        </FullPageWrapperShowroom>
                    </div>
                </PageWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 100,
                        right: 16,
                        zIndex: showScrollToTopBtn ? 1 : -1,
                        opacity: showScrollToTopBtn ? 1 : 0,
                        transition: 'all 200ms',
                    }}
                >
                    <ScrollToTopBtn
                        onClick={() => {
                            watchlistInnerRefMobile?.current?.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            })
                        }}
                    />
                </div>
                <PageWrapper
                    style={{
                        height: '100vh',
                        overflowY: 'auto',
                    }}
                    key={'mobile-scroll-watchlist'}
                    onScroll={() => {
                        if (isMobile) {
                            // check if we show scroll to top btn
                            checkIfScrollToTopBtnIsVisible(
                                watchlistInnerRefMobile,
                                scrollDirection
                            )

                            // fetch data if necessary
                            // onScroll(watchlistInnerRefMobile)
                        }
                    }}
                    ref={watchlistInnerRefMobile}
                >
                    <SectionWrapper>
                        <SubSectionWrapper>
                            <ShowroomH1 leftAlign>Watch list</ShowroomH1>
                        </SubSectionWrapper>

                        {!isSearchLoading &&
                            (watchlist_count === 0 || !userLoggedIn) && (
                                <ShowroomNoResultsBox page="watchlist" />
                            )}

                        {isMobile && entriesList && entriesList.length > 0 && (
                            <ShowroomCarContainer>
                                {entriesList.map((entry: IShowroomEntryAPI) => {
                                    return (
                                        <Faded key={`mobile-${entry.uid}`}>
                                            <ShowroomCardDesktop
                                                watchlist_entry={entry}
                                                user={userLoggedIn ?? undefined}
                                                reset={
                                                    handleChangeSearchEffectsReset
                                                }
                                                refCreated={
                                                    anchor_entry_params ===
                                                    entry.uid
                                                        ? cardAnchoredRefMobile
                                                        : undefined
                                                }
                                            />
                                        </Faded>
                                    )
                                })}

                                {isSearchLoading && !checkIfIsLast() && (
                                    <CardSkeletonItems
                                        height="25vh"
                                        width="95%"
                                        paddingBottom="0px"
                                        narr={[1, 2, 3, 4, 5, 6]}
                                    />
                                )}
                            </ShowroomCarContainer>
                        )}

                        {isSearchLoading &&
                            (!entriesList ||
                                (entriesList && entriesList.length < 0 && (
                                    <CardSkeletonItems
                                        height="25vh"
                                        width="95%"
                                        paddingBottom="0px"
                                        narr={[1, 2, 3, 4, 5, 6]}
                                    />
                                )))}
                        {(!entriesList ||
                            checkIfIsLast() ||
                            (entriesList && entriesList.length === 0)) && (
                            <div style={{ paddingBottom: '24px' }} />
                        )}

                        <BottomPaginationNavigation
                            results={entriesList}
                            currentPage={currentPageNumberWatchlist}
                            isLastPage={isLastPage}
                            onClickPrev={onClickPrev}
                            onClickNext={onClickNext}
                            onClickPageNumber={onClickPageNumber}
                            totalCount={watchlist_count ?? 0}
                            itemsPerPage={itemsPerPage}
                        />
                        {!userLoggedIn ? (
                            <ShowroomPublicFooterMobile />
                        ) : (
                            <div style={{ paddingBottom: 300 }} />
                        )}
                    </SectionWrapper>
                </PageWrapper>
            </IpadAndMobileDisplay>
        </>
    )
}

export default WatchlistPage
