import styled from 'styled-components'
import CustomAnimatedRadioField from '../../atoms/formField/customAnimatedRadioField'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import React from 'react'

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border: 1px solid var(--border-muted, #e5e5e5);
    padding: 16px;
    border-radius: 4px;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-bold;
    font-size: 16px;
`

const Line = styled.div`
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 16px;
    margin-bottom: 16px;
`

const CheckboxesWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    grid-auto-rows: auto;
    grid-row-gap: 10px;
`

const TwoCheckboxesCol = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    grid-auto-rows: auto;
    grid-row-gap: 10px;
`

type IOptionData = {
    id: string | number
    name: string
}

type Props = {
    title: string
    isOn: boolean
    selectedID: number | string
    option_data: IOptionData[]
    onOptionClick: (s: string) => any
}

const SelectionRadioSectionWithoutToggle = (props: Props) => {
    const renderCheckBoxes = () => {
        return props.option_data.map((item, i) => {
            return (
                <React.Fragment
                    key={`${item.id}_${i}_select_no_toggle_desktop`}
                >
                    <CustomAnimatedRadioField
                        onChange={() => {
                            props.onOptionClick(`${item.id}`)
                        }}
                        id={item.id}
                        checked={props.selectedID === item.id}
                        name={item.name}
                        rowHeight={'36px'}
                        hasWhiteBg
                        key={`desktop-${item.id}`}
                    />
                </React.Fragment>
            )
        })
    }
    return (
        <Container>
            <Row>
                <Title>{props.title}</Title>
            </Row>

            <Line />

            <DesktopDisplayOnly>
                {props.option_data.length > 2 ? (
                    <CheckboxesWrapper>{renderCheckBoxes()}</CheckboxesWrapper>
                ) : (
                    <TwoCheckboxesCol>{renderCheckBoxes()}</TwoCheckboxesCol>
                )}
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                {props.option_data.map((item: IOptionData, i) => {
                    return (
                        <React.Fragment
                            key={`${item.id}_${i}_select_no_toggle_mobile`}
                        >
                            <CustomAnimatedRadioField
                                onChange={() => {
                                    props.onOptionClick(`${item.id}`)
                                }}
                                id={item.id}
                                checked={props.selectedID === item.id}
                                name={item.name}
                                rowHeight={'36px'}
                                hasWhiteBg
                                key={`mobile-${item.id}`}
                            />
                        </React.Fragment>
                    )
                })}
            </IpadAndMobileDisplay>
        </Container>
    )
}

export default SelectionRadioSectionWithoutToggle
