import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../reducer'

import { IRootState } from '../../../store'
import { api } from '../../../services'
import { compareTwoAddresses_insurance } from '../../../conversions/insuranceApplication/addressObj'
import { IInsuranceApplication_API } from '../../types'
import posthog from 'posthog-js'
import { convertApplicationToAddressesNormalised } from '../../../conversions/insuranceApplication/api/addressesNormalised'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'

export let insurance_application_draft_state_select = (state: IRootState) =>
    state.insuranceQuoteApplication.draft

export let insurance_application_existing_state_select = (
    state: IRootState
): IInsuranceQuoteApplication => state.insuranceQuoteApplication.submitted_data

export function* Submit_user_addresses(): any {
    posthog.capture('Save insurance application customer address')
    try {
        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft = { ...all_draft.addresses }
        let existing = { ...all_existing.addresses }

        let dataToSendToPatch: IAnyObject = {}

        let addressesToAdd: IAnyObject[] = []

        const pattern = /^new_address/

        if (Object.keys(draft).length > 0) {
            Object.keys(draft).forEach((addressUID: string) => {
                if (Object.keys(existing).indexOf(addressUID) === -1) {
                    // doesnt exist, needs to be created. So UID gen by FE should not be included in the PATCH.
                    // here can convert FE address to IAddressAPI type if need be

                    let addresss = { ...draft[addressUID] }
                    if (pattern.test(`${addressUID}`)) {
                        delete addresss['uid']
                    }

                    addressesToAdd = [...addressesToAdd, { ...addresss }]
                } else {
                    // existing address, but diff fields
                    let addresss = compareTwoAddresses_insurance({
                        draft: draft[addressUID],
                        existing: existing[addressUID],
                    })

                    if (addresss) {
                        addresss = {
                            ...addresss,
                            uid: addressUID,
                        }
                        addressesToAdd = [...addressesToAdd, { ...addresss }]
                    }
                }
            })
        }

        if (addressesToAdd.length > 0) {
            dataToSendToPatch = {
                customer: {
                    addressbook: [...addressesToAdd],
                },
            }
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            // call api to patch as thats all the data merged we want to patch

            let insuranceData: IInsuranceApplication_API = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...dataToSendToPatch },
                }
            )

            let addresses =
                convertApplicationToAddressesNormalised(insuranceData)

            yield put(insuranceActions.submit_user_address_success(addresses))
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_user_addresses,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/main_driver/${all_existing.main_driver.id}/licence_address`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_user_residential_address_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Submit_user_addresses() {
    while (true) {
        yield take(insuranceActions.submit_user_address_request)
        yield call(Submit_user_addresses)
    }
}

const user_address_submit: any[] = [fork(Watcher_Submit_user_addresses)]

export default user_address_submit
