import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'
import { IDeleteCostAttachmentPayloadReq } from '../../types'

export const deleteCostAttachment = (
    p: IDeleteCostAttachmentPayloadReq
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.delete_cost_attachment_endpoint(p), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            apiSentryErrorHandler(e, 'Delete cost attachment error')
            return Promise.reject(e)
        })
}
