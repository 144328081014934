import { IisOpen } from '../../redux/menus/types'

type FormObj = {
    [key: string]: IisOpen
}
export const isAnyFormOpen = (ids: string[], obj: FormObj): boolean => {
    // The some() method tests whether at least one element in the array passes the test implemented
    const isAnyOpen: boolean = ids.some((id: string) => obj[id].isOpen === true)
    // if true, then true, if false then false
    return isAnyOpen
}
