import DatePickerTaskDesktop from './../datePicker/datePickerTaskDesktop'
import NotesEditorFormWrapperDesktop from './../notes/notesEditorFormWrapperDesktop'
import BasicTaskFieldFormDesktop from './../basic/basicTaskFieldFormDesktop'
import { generate_task_cars_dropdown_list } from '../../../../../redux/conversions/dropdowns/taskCarsDropdownGenerator'
import SearchNselectCarsTaskDesktop from './../taskCars/searchNselectCarsTaskDesktop'
import { OOtherTaskOptions } from '../../../../../ol/ol'
import dayjs from 'dayjs'
import { ITask, ITaskRelationsPayload } from 'entityModels'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { useState, useEffect } from 'react'
import { carActions } from '../../../../../redux/entities/cars/reducer'
import { tasksActions } from '../../../../../redux/tasks/reducer'

export interface Props {
    item: any
    task: ITask
    toggle: any
    updateTask: any
    carsData: any
    isEveryCar?: boolean
    timezone?: string
}

const TaskFormManagerDesktop: React.FC<Props> = (props) => {
    const { item, task, updateTask, toggle, carsData, isEveryCar, timezone } =
        props
    const [dropdownCars, setDropdownCars] = useState<any>(null)
    const [selectedCarNames, setSelectedCarNames] = useState<string[]>([])

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })
    const garagesData = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (item.id === 'linkedToCar') {
            if (item.answer !== undefined) {
                if (carsData[item.answer[0]]) {
                    carsData[item.answer[0]].title &&
                        setSelectedCarNames([carsData[item.answer[0]].title])
                }
            }

            if (!item.answer) {
                setSelectedCarNames([OOtherTaskOptions.general_task.name])
            }

            if (userLoggedIn && !garagesData) {
                if (userLoggedIn.owns_garage && userLoggedIn.owns_garage.uid) {
                    dispatch(
                        carActions.getGarageCarsDataRequest(
                            userLoggedIn.owns_garage.uid
                        )
                    )
                }
            } else if (
                garagesData &&
                userLoggedIn &&
                userLoggedIn.owns_garage &&
                !dropdownCars
            ) {
                if (
                    garagesData[userLoggedIn.owns_garage.uid] &&
                    garagesData[userLoggedIn.owns_garage.uid].cars
                ) {
                    let dropdown_cars_dropdown =
                        generate_task_cars_dropdown_list(
                            garagesData[userLoggedIn.owns_garage.uid].cars,
                            carsData,
                            userLoggedIn.owns_garage.uid
                        )
                    setDropdownCars(dropdown_cars_dropdown)
                }
            }
        }
    }, [item, carsData, userLoggedIn, garagesData])

    const renderForm = (
        item: any,
        task: ITask,
        updateTask: any,
        toggle: any
    ) => {
        switch (item.id) {
            case 'due':
                return (
                    <DatePickerTaskDesktop
                        submitFunc={updateTask}
                        taskid={task.id}
                        item={item}
                        toggle={toggle}
                        showReminderResetWarning={
                            task.reminders && task.reminders?.length > 0
                                ? true
                                : false
                        }
                        minDate={dayjs().format('YYYY-MM-DD')}
                        timezone={timezone}
                    />
                )

            case 'body':
                return (
                    <NotesEditorFormWrapperDesktop
                        submitFunc={updateTask}
                        taskid={task.id}
                        item={item}
                        toggle={toggle}
                    />
                )

            case 'linkedToCar':
                return (
                    <>
                        {userLoggedIn &&
                        garagesData[userLoggedIn.owns_garage.uid] &&
                        garagesData[userLoggedIn.owns_garage.uid].cars ? (
                            <SearchNselectCarsTaskDesktop
                                item={{
                                    ...item,
                                    answer: selectedCarNames,
                                }}
                                task={task}
                                toggle={toggle}
                                dropdownData={dropdownCars ? dropdownCars : []}
                                carsData={carsData}
                                garage_id={userLoggedIn.owns_garage.uid}
                                unlinkTaskRequest={(
                                    p: ITaskRelationsPayload
                                ) => {
                                    dispatch(tasksActions.unlinkTaskRequest(p))
                                }}
                                linkTaskRequest={(p: ITaskRelationsPayload) => {
                                    dispatch(tasksActions.linkTaskRequest(p))
                                }}
                                garageCarsIds={
                                    garagesData[userLoggedIn.owns_garage.uid]
                                        .cars
                                }
                                isEveryCar={isEveryCar}
                            />
                        ) : (
                            <div />
                        )}
                    </>
                )

            default:
                return (
                    <BasicTaskFieldFormDesktop
                        submitFunc={updateTask}
                        taskid={task.id}
                        item={item}
                        toggle={toggle}
                    />
                )
        }
    }

    return renderForm(item, task, updateTask, toggle)
}

export default TaskFormManagerDesktop
