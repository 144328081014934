import React, { useState, useEffect, useCallback } from 'react'
import Fuse from 'fuse.js'
import SelectSingleDesktop from './selectSingleDesktop'
import { debounce } from 'lodash'
import SingleSelectOnlyMatchedDesktop from './singleSelectOnlyMatchedDesktop'

export type Props = {
    items: any // IDropdownItems[] ||  IInsuranceDropdownItem[]
    item: any
    height?: string
    width?: string
    handleSelectChange?: any
    addAnItemFunc?: any
    dropdownTargetId?: string
    formCurrentValue?: string
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    helperText?: string
    onlyShowMatch?: boolean
    dataCyId?: string | undefined
    customPlaceholder?: string
    sendId?: boolean
    isKeyValue?: boolean
    valueUpdatedFromExternalSource?: string | null
    clearFieldIfValueUpdatedFromExternalSource?: boolean
    customMaxWidth?: string
    showBothDevices?: boolean
    iconsSize?: string
    backgroundColor?: string
    borderColor?: string
    sendDropdownItem?: boolean
    isValueLabel?: boolean
    showEmptyBox?: boolean
    field_id?: string
    textTransform?: string
    customActiveColour?: string
    customHighlightColour?: string
}

const SelectSearchBarCarInfoDesktop: React.FC<Props> = (props) => {
    const [data, setData] = useState<any[]>([])
    const [name, setName] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(false)
    const [lastFetchId, setLastFetchId] = useState<number>(0)

    const fetchItem = useCallback(
        debounce((name: any) => {
            setLastFetchId((prevFetchId) => prevFetchId + 1)
            const fetchId = lastFetchId + 1
            setData([])
            setFetching(true)
            if (fetchId !== lastFetchId + 1) {
                return
            }

            let fuse: any = {}

            if (props.isKeyValue === true) {
                fuse = new Fuse(props.items, { threshold: 0.2 })
            } else {
                if (props.isValueLabel === true) {
                    fuse = new Fuse(props.items, {
                        keys: ['label', 'value'],
                        threshold: 0.2,
                    })
                } else {
                    fuse = new Fuse(props.items, {
                        keys: ['name', 'uid'],
                        threshold: 0.2,
                    })
                }
            }

            let results: any = fuse.search(name)
            setData(results ? results : [])
            setFetching(false)
        }, 300),
        [lastFetchId, props.items, props.isKeyValue, props.isValueLabel]
    )

    const handleChange = (name: any) => {
        setName((prevName) => [...prevName, name])
        setData([])
        setFetching(false)
        props.handleSelectChange && props.handleSelectChange(name)
    }

    useEffect(() => {
        if (props.valueUpdatedFromExternalSource) {
            setName((prevName) =>
                props.clearFieldIfValueUpdatedFromExternalSource
                    ? [...prevName, undefined]
                    : [...prevName, props.valueUpdatedFromExternalSource]
            )
            setData([])
            setFetching(false)
        }
    }, [
        props.valueUpdatedFromExternalSource,
        props.clearFieldIfValueUpdatedFromExternalSource,
    ])

    const {
        item,
        width,
        height,
        addAnItemFunc,
        dropdownTargetId,
        formCurrentValue,
        allowAdd,
        isDisabled,
        placeholder,
        helperText,
        onlyShowMatch,
        dataCyId,
        customPlaceholder,
        sendId,
        isKeyValue,
        showBothDevices,
        iconsSize,
        backgroundColor,
        customMaxWidth,
        sendDropdownItem,
        borderColor,
        showEmptyBox,
        field_id,
        textTransform,
        customActiveColour,
        customHighlightColour,
    } = props

    return (
        <React.Fragment>
            {onlyShowMatch === true ? (
                <SingleSelectOnlyMatchedDesktop
                    field_id={field_id}
                    sendDropdownItem={sendDropdownItem}
                    backgroundColor={backgroundColor}
                    borderColor={borderColor}
                    fetching={fetching}
                    value={name}
                    data={data}
                    fetchItem={fetchItem}
                    handleChange={handleChange}
                    item={item}
                    width={width && width}
                    height={height && height}
                    addAnItemFunc={addAnItemFunc && addAnItemFunc}
                    dropdownTargetId={dropdownTargetId && dropdownTargetId}
                    formCurrentValue={formCurrentValue && formCurrentValue}
                    allowAdd={allowAdd && allowAdd}
                    isDisabled={isDisabled && isDisabled}
                    placeholder={placeholder && placeholder}
                    helperText={helperText}
                    dataCyId={dataCyId}
                    customPlaceholder={customPlaceholder}
                    customMaxWidth={customMaxWidth}
                    sendId={sendId}
                    resetSearch={() => setData([])}
                    customActiveColour={customActiveColour}
                    customHighlightColour={customHighlightColour}
                />
            ) : (
                <SelectSingleDesktop
                    field_id={field_id}
                    showEmptyBox={showEmptyBox}
                    sendDropdownItem={sendDropdownItem}
                    backgroundColor={backgroundColor}
                    borderColor={borderColor}
                    fetching={fetching}
                    value={name}
                    data={data}
                    isValueLabel={props.isValueLabel}
                    fetchItem={fetchItem}
                    handleChange={handleChange}
                    optionsList={props.items}
                    item={item}
                    width={width && width}
                    height={height && height}
                    addAnItemFunc={addAnItemFunc && addAnItemFunc}
                    dropdownTargetId={dropdownTargetId && dropdownTargetId}
                    formCurrentValue={formCurrentValue && formCurrentValue}
                    allowAdd={allowAdd && allowAdd}
                    isDisabled={isDisabled && isDisabled}
                    placeholder={placeholder && placeholder}
                    helperText={helperText}
                    dataCyId={dataCyId}
                    sendId={sendId}
                    isKeyValue={isKeyValue}
                    showBothDevices={showBothDevices}
                    iconsSize={iconsSize}
                    customMaxWidth={customMaxWidth}
                    resetSearch={() => setData([])}
                    textTransform={textTransform}
                    customActiveColour={customActiveColour}
                    customHighlightColour={customHighlightColour}
                />
            )}
        </React.Fragment>
    )
}

export default SelectSearchBarCarInfoDesktop
