import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { device } from '../../../templates/displays/devices'
import Tour from 'reactour'
import './styles.css'
import grey_tip from '../../../../public/assets/icons/grey_tip.svg'
import { useParams, useNavigate } from 'react-router-dom'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import TutorialWrapperDesktop from '../tutorialWrappers/TutorialWrapperDesktop'
import TutorialWrapperMobile from '../tutorialWrappers/TutorialWrapperMobile'

interface Props {
    registerCarTutorial: () => void
}

const TutorialConfigsCarDesktop: React.FC<Props> = ({
    registerCarTutorial,
}) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [isTourOpen, setIsTourOpen] = useState(true)
    const { carid } = useParams<{ carid: string }>()
    const navigate = useNavigate()

    const setCurrentStepCustom = (step: number) => {
        setCurrentStep(step)
    }

    const setIsTourOpenCustom = () => {
        setIsTourOpen(false)
        navigate(`/car/${carid}`)
    }

    useEffect(() => {
        registerCarTutorial()
    }, [registerCarTutorial])

    useEffect(() => {
        if (isTourOpen) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [isTourOpen])

    return (
        <>
            {window.innerWidth > 880 && (
                <>
                    <TourStyled
                        steps={tutorialConfigs_Garage(setIsTourOpenCustom)}
                        isOpen={isTourOpen}
                        onRequestClose={() => undefined}
                        rounded={0}
                        accentColor="#5ec3ca"
                        getCurrentStep={(curr: number) => {
                            window.scrollTo(0, 0)
                            setCurrentStepCustom(curr + 1)
                        }}
                        disableInteraction={true}
                        showCloseButton={false}
                        currentStep={currentStep}
                    />
                </>
            )}
        </>
    )
}

export default TutorialConfigsCarDesktop

const Title = styled.h1`
    color: var(--primary, #5ec3ca);
    font-family: Lato-Light;
    font-size: 24px;
    text-align: center;

    @media ${device.mobile} {
    }
`

const Text = styled.p`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 17px;
    line-height: 1.9;

    @media ${device.mobile} {
    }
`

const BulletList = styled.ul`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 17px;
    line-height: 1.9;
`

const Padding = styled.div`
    padding-top: 25px;

    @media ${device.mobile} {
    }
`
const TextPadding = styled.div`
    padding-top: 8px;

    @media ${device.mobile} {
    }
`

const Tip = styled.img`
    width: 20px;
    @media ${device.mobile} {
    }
`

const TipText = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Light;
    padding-left: 20px;
    font-size: 16px;

    @media ${device.mobile} {
    }
`

const TipRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
`

const TourStyled = styled((props: any) => <Tour {...props} />)`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: ${(props) =>
            props.currentStep && props.currentStep !== 1 ? '550px' : '400px'};
    }

    .reactour__badge {
        padding: 0 !important;
        width: 32px !important;
        height: 32px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 0 !important;
    }

    /* Override the generated class styles */
    [data-tour-elem='badge'] {
        line-height: 2 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
`

export type ReactourStepPosition =
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | [number, number]

let bottomPositionStrongType: ReactourStepPosition = 'bottom'

const tutorialConfigs_Garage = (close: any) => [
    {
        selector: '[data-tut="car-actions-tuto-desktop"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperMobile
                skip={close}
                next={() => {
                    goTo(1)
                }}
            >
                <Title>Get Started</Title>
                <Padding />

                <Text>
                    The Car Menu button allows you to quickly fill in your car
                    profile, or perform actions such as a handover.
                </Text>
            </TutorialWrapperMobile>
        ),
    },
    {
        selector: '[data-tut="nav-step-car-desktop"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperDesktop
                skip={close}
                prev={() => {
                    goTo(0)
                }}
                next={() => {
                    goTo(2)
                }}
            >
                <Title>Navigation</Title>
                <Padding />

                <Text>There are two navigation systems:</Text>

                <TextPadding />
                <BulletList>
                    <li>
                        The left menu bar which contains access to the main
                        platform features.
                    </li>
                    <TextPadding />

                    <li>
                        The top menu bar which contains additional navigation
                        for the specific page you are on.
                    </li>
                </BulletList>
                <TipRow>
                    <Tip src={grey_tip} alt="tip" />
                    <TipText>
                        Click on the menu bar to expand or collapse it.
                    </TipText>
                </TipRow>
            </TutorialWrapperDesktop>
        ),
        position: [200, 60],
    },

    {
        selector: '[data-tut="second-step-car-desktop"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperDesktop
                skip={close}
                prev={() => {
                    goTo(1)
                }}
                next={() => {
                    goTo(3)
                }}
            >
                <Title>Car Banner Image</Title>
                <Padding />

                <Text>
                    The car banner image re-appears at the top section of other
                    general management pages, such as your car's Tasks.
                </Text>

                <TextPadding />

                <Text>
                    We help you get any image in the right format by showing a
                    cropper with the right ratios as soon as you upload an
                    image.
                </Text>

                <TipRow>
                    <Tip src={grey_tip} alt="tip" />
                    <TipText>
                        Hover on the image to see the (re)upload button.
                    </TipText>
                </TipRow>
            </TutorialWrapperDesktop>
        ),
        position: bottomPositionStrongType,
    },
    {
        selector: '[data-tut="gallery-overview-car-tuto-desktop"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperDesktop
                skip={close}
                prev={() => {
                    goTo(2)
                }}
                next={() => {
                    goTo(4)
                }}
            >
                <Title>
                    Image Gallery | Car Profile Image | Gallery Highlights
                </Title>

                <Padding />

                <Text>
                    You can add images of your car to your gallery from here.
                    Once uploaded, each individual image is editable. You could,
                    for example:
                </Text>

                <TextPadding />

                <BulletList>
                    <li>
                        Set it as the car's Profile Image (visible from your
                        garage).
                    </li>
                    <TextPadding />
                    <li>
                        Set it as one of the six Gallery Highlights (visible
                        from this gallery overview).
                    </li>
                </BulletList>
            </TutorialWrapperDesktop>
        ),
        position: bottomPositionStrongType,
    },
    {
        selector: '[data-tut="key-moments-car-tuto-desktop"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperDesktop
                skip={close}
                prev={() => {
                    goTo(3)
                }}
                next={close}
                nextBtnText="close"
            >
                <Title>Key Moments & Facts</Title>

                <Padding />

                <Text>
                    Share and rank a few interesting facts about your car's
                    history, specification or condition, and add any highlights
                    or special milestones during your ownership.
                </Text>

                <TextPadding />

                <Text onClick={() => close()}>
                    <div
                        style={{
                            color: 'var(--primary, #5ec3ca)',
                            cursor: 'pointer',
                        }}
                    >
                        Your turn to explore the rest!
                    </div>
                </Text>
            </TutorialWrapperDesktop>
        ),
        position: bottomPositionStrongType,
    },
]
