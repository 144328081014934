import styled from 'styled-components'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import success_img from '../../../public/assets/icons/success_img.png'
import { useNavigate } from 'react-router-dom'

export const EnquiryBtnsOnlyMobile = styled.div`
    width: 100%;
    box-sizing: border-box;
    @media screen and (min-width: 880px) {
        display: none;
    }
`
const BtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
`
const SmallPadding = styled.div`
    padding-top: 20px;

    @media screen and (max-width: 880px) {
        padding-top: 30px;
    }
`

const Skip = styled.button`
    font-family: Lato;
    color: #5ec3ca;
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    padding: 0px;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }
    @media screen and (min-width: 880px) {
        font-size: 16px;
    }
    cursor: pointer;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
`

const SuccessImg = styled.img`
    height: 140px;
`

const Title = styled.div`
    text-align: center;
    color: #1a1a1a;
    font-size: 24px;
    font-family: Lato-Bold;

    @media screen and (min-width: 880px) {
        font-size: 32px;
    }
`

const SubTitle = styled.div`
    text-align: center;
    color: #666666;
    font-size: 16px;
    font-family: Lato;

    @media screen and (min-width: 880px) {
    }
`

const SeparationPadding = styled.div`
    padding-top: 40px;

    @media screen and (min-width: 880px) {
        padding-top: 40px;
    }
`

type Props = {
    entryid: string
}
const EnquirySuccess = (props: Props) => {
    const navigate = useNavigate()

    return (
        <Container>
            <div style={{ paddingTop: '50px' }} />

            <SuccessImg src={success_img} alt="success-logo" />

            <SeparationPadding />

            <Title>Enquiry sent!</Title>
            <div style={{ paddingTop: '10px' }} />
            <SubTitle>
                Your message to the seller was successfully sent.
                <br />
                They will contact you directly.
            </SubTitle>

            <div style={{ paddingTop: '50px' }} />

            <BtnWrapper>
                <ButtonAtom
                    theme="main-cta-onboarding"
                    onClick={() => {
                        navigate(`/showroom/${props.entryid}`, {
                            replace: true,
                        })
                    }}
                    disabled={false}
                    dataCyId={`enquirysuccess`}
                    height={'48px'}
                    fontSize={16}
                >
                    Back to the Car Page
                </ButtonAtom>
                <SmallPadding />

                <Skip
                    onClick={() => {
                        setTimeout(function () {
                            navigate(`/showroom`, { replace: true })
                        }, 200)
                    }}
                    data-attr="enquiry-success-back"
                >
                    Back to Showroom
                </Skip>
            </BtnWrapper>

            <div style={{ paddingTop: '50px' }} />
        </Container>
    )
}

export default EnquirySuccess
