import * as urls from '../../urls'
import { removeUndefinedfromObject } from '../../../helpers/objects'
import { customHeader } from '../../headers'
import { apiSentryErrorHandler } from '../../errorHandler'
import { IApiEntryCostUPDATE_args } from '../../types'

export const updateEntryCost = (p: IApiEntryCostUPDATE_args): Promise<any> => {
    let dataToUpdateNoUndefined = removeUndefinedfromObject(p.dataToUpdate)

    if (dataToUpdateNoUndefined && dataToUpdateNoUndefined.amount) {
        dataToUpdateNoUndefined.amount = Math.floor(
            dataToUpdateNoUndefined.amount
        ).toString()
    }

    let dataToUpdateFinal: string = JSON.stringify(dataToUpdateNoUndefined)

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: dataToUpdateFinal,
        credentials: 'include',
    }

    return fetch(
        urls.update_entry_cost_endpoint(p.entry_id, p.cost_id, p.car_id),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Update cost error',
                        dataToUpdateFinal
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
