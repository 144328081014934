import { put, fork, take, call } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { api } from '../../../services'
import { message } from 'antd'
import { fileStorageActions } from '../../reducer'
import { IApiUploadPresignedUrlGalleryImgsToS3_args } from '../../../services/types'
import { IReduxError } from '../../../entities/cars/types'

export function* submitToS3Saga(
    payload: IApiUploadPresignedUrlGalleryImgsToS3_args
) {
    try {
        message.loading(
            `Your ${
                payload.files.length > 1 ? 'images are' : 'image is'
            } uploading`,
            0
        )
        let isUploaded: boolean = yield call(
            api.fileStorage.upload_presignedurl_gallery_images_to_s3,
            payload
        )

        if (isUploaded) {
            message.destroy()
            yield put(fileStorageActions.submitFilesToS3Success())
        } else {
            let isOnboarding =
                window.location.search.match(/onboarding/g) === null
            message.destroy()
            let typedError: IReduxError = ConvertToReduxError({
                status: 500,
                message: 'S3 Image upload failed. Please try again',
                custom: {
                    custom_message: 'S3 Image upload failed. Please try again.',
                    custom_user_action_text: `OK`,
                    custom_redirect_path: isOnboarding
                        ? `/onboarding-step-3${location.search}`
                        : window.location.pathname
                        ? `${window.location.pathname}${location.search}`
                        : window.location.pathname,
                },
            })
            yield put(fileStorageActions.submitFilesToS3Error(typedError))
        }
    } catch (error: any) {
        message.destroy()
        let typedError: IReduxError = ConvertToReduxError(error)
        yield put(fileStorageActions.submitFilesToS3Error(typedError))
    }
}

export function* watcherSubmitToS3() {
    while (true) {
        const { payload } = yield take(
            fileStorageActions.submitFilesToS3Request
        )
        yield fork(submitToS3Saga, payload)
    }
}

const filestorage_sagas_submission_to_s3: any[] = [fork(watcherSubmitToS3)]

export default filestorage_sagas_submission_to_s3
