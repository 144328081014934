import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/insurance/3.png'
// import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import {
    JourneyBtnContent,
    JourneyCardMobile,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyListItemText,
    JourneyListItemTitle,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../templates/styledcomponents/journeyStyles'
import InsuranceHowItWorksCardIcon from '../../../atoms/icons/components/journey/howItWorks'
import { journeyInsuranceHowItWorksCardsStepCopy } from '../../../../redux/localdata/journey/copyData'
import React from 'react'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

const ListItem = ({
    item,
    index,
    theme,
}: {
    item: { title: string; description: string }
    index: number
    theme: ITheme
}) => {
    return (
        <JourneyCardMobile $theme={theme} $customBorderRadius="8px">
            <JourneyRowContent
                style={{ gap: 24, padding: 24, alignItems: 'flex-start' }}
            >
                <div style={{ maxWidth: 32, minWidth: 32 }}>
                    <InsuranceHowItWorksCardIcon item={index + 1} />
                </div>
                <JourneyColumnContent style={{ gap: 4 }}>
                    <JourneyListItemTitle $theme={theme}>
                        {item.title}
                    </JourneyListItemTitle>
                    <JourneyListItemText $theme={theme}>
                        {item.description}
                    </JourneyListItemText>
                </JourneyColumnContent>
            </JourneyRowContent>
        </JourneyCardMobile>
    )
}

export default function JourneyInsuranceHowItWorksStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section['insurance']
                                        .primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section['insurance']
                                        .primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        How does it work
                                    </JourneyStepTitle>
                                </JourneyColumnContent>
                                <JourneyText $theme={theme}>
                                    With Custodian Insurance, applying for
                                    coverage is flexible, convenient, and
                                    tailored to your preferences, providing
                                    peace of mind for your car ownership
                                    journey.
                                </JourneyText>
                            </JourneyColumnContent>

                            <JourneyColumnContent style={{ gap: 16 }}>
                                {journeyInsuranceHowItWorksCardsStepCopy.map(
                                    (item, index) => {
                                        return (
                                            <React.Fragment
                                                key={`item_mobile_${index}`}
                                            >
                                                <ListItem
                                                    item={item}
                                                    index={index}
                                                    theme={theme}
                                                />
                                            </React.Fragment>
                                        )
                                    }
                                )}
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section.insurance
                                            .primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section.insurance
                                            .primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <FadedSlower>
                                <img
                                    src={image}
                                    // placeholder={grey_placeholder}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '56vw',
                                        maxHeight: '90vh',
                                    }}
                                />
                            </FadedSlower>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingBottom: 200,
                            paddingTop: 48,
                            overflow: 'hidden',
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent
                                    style={{
                                        gap: 4,
                                    }}
                                >
                                    <JourneyStepTitle
                                        $theme={theme}
                                        style={{ paddingRight: '40px' }}
                                    >
                                        How does it work
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        Learn about Custodian Insurance
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyText $theme={theme}>
                                    With Custodian Insurance, applying for
                                    coverage is flexible, convenient, and
                                    tailored to your preferences, providing
                                    peace of mind for your car ownership
                                    journey.
                                </JourneyText>
                            </JourneyColumnContent>
                            <JourneyColumnContent style={{ gap: 8 }}>
                                {journeyInsuranceHowItWorksCardsStepCopy.map(
                                    (item, index) => {
                                        return (
                                            <React.Fragment
                                                key={`item_mobile_${index}`}
                                            >
                                                <ListItem
                                                    item={item}
                                                    index={index}
                                                    theme={theme}
                                                />
                                            </React.Fragment>
                                        )
                                    }
                                )}
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="insurance"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
