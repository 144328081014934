import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { journey_colours } from '../../../../providers/theme/colours'
import image from '../../../../public/assets/images/journey/handover/welcome.png'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import {
    JourneyWelcomeBtnText,
    JourneyWelcomeButtonsWrapper,
    JourneyWelcomeImage,
    JourneyWelcomeNewLineOnMobile,
    JourneyWelcomeSubWrapperV2,
    JourneyWelcomeText,
    JourneyWelcomeTextWrapper,
    JourneyWelcomeTitle,
    JourneyWelcomeWrapper,
} from '../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import FadedSlower from '../../../templates/animated/FadedSlower'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    start: () => void
    cancel: (carid: string) => void
}

const CustomImageMobile = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;
    height: auto;
    width: 1000px;
    max-height: 35dvh;
    transform: translateX(-10px);

    @media (min-width: 500px) {
        max-height: 50dvh;
    }
`

const JourneyWelcomeImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    /* flex: 1; */

    @media ${device.beyond_ipad_mobile} {
        margin-top: 16px;
        height: 50dvh;
        transform: translateX(-10px);
    }
    @media ${device.mobile} {
        width: 100dvw;
        transform: translateX(10px);
    }
`

export default function JourneyHandoverWelcome({ start, cancel }: Props) {
    const { theme } = useThemes()
    return (
        <>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneyWelcomeWrapper>
                        <JourneyWelcomeSubWrapperV2>
                            <JourneyWelcomeTextWrapper style={{ gap: '12px' }}>
                                <div
                                    style={{
                                        // not adding padding everywhere
                                        // as pattern should stay above
                                        paddingTop: '4px',
                                    }}
                                />

                                <JourneyWelcomeTitle>
                                    Protect the future of automotive enthusiasm
                                </JourneyWelcomeTitle>
                                <JourneyWelcomeText>
                                    We know a car is as much about the journey
                                    it's been on, as to how it first rolled out
                                    of the factory. Ensure future owners know
                                    your car's history, so that they can be as
                                    great of a custodian as you have been.
                                </JourneyWelcomeText>
                            </JourneyWelcomeTextWrapper>

                            <div
                                style={{
                                    paddingTop: '12px',
                                }}
                            />

                            <JourneyWelcomeImageWrapper>
                                <FadedSlower isFullWidth={true}>
                                    <JourneyWelcomeImage
                                        src={image}
                                        // placeholder={grey_placeholder}
                                    />
                                </FadedSlower>
                            </JourneyWelcomeImageWrapper>

                            <div
                                style={{
                                    paddingTop: '24px',
                                }}
                            />

                            <JourneyWelcomeButtonsWrapper>
                                <StandardCtaBtn
                                    onClick={start}
                                    bg={
                                        journey_colours[theme].section.handover
                                            .primary_600
                                    }
                                >
                                    Let's get started
                                </StandardCtaBtn>
                                <StandardCtaBtn
                                    onClick={cancel}
                                    boder="transparent"
                                    bg="transparent"
                                    removeShadowOnHover
                                    customWidth="100%"
                                >
                                    <JourneyWelcomeBtnText
                                        style={{
                                            color: journey_colours[theme]
                                                .section.handover.primary_600,
                                        }}
                                    >
                                        Cancel
                                    </JourneyWelcomeBtnText>
                                </StandardCtaBtn>
                            </JourneyWelcomeButtonsWrapper>
                        </JourneyWelcomeSubWrapperV2>
                    </JourneyWelcomeWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyWelcomeWrapper>
                    <JourneyWelcomeSubWrapperV2>
                        <div
                            style={{
                                paddingTop: '8px',
                            }}
                        />

                        <JourneyWelcomeTitle
                            style={{
                                fontSize: '26px',
                                maxWidth: '90vw',
                            }}
                        >
                            Protect the future of
                            <JourneyWelcomeNewLineOnMobile />
                            automotive
                            <span
                                style={{
                                    color: journey_colours[theme].section
                                        .handover.primary_500,
                                    fontSize: '28px',
                                }}
                            >
                                {' '}
                                enthusiasm
                            </span>
                        </JourneyWelcomeTitle>

                        <div
                            style={{
                                paddingTop: '24px',
                            }}
                        />

                        <JourneyWelcomeImageWrapper>
                            <Faded width={'auto'}>
                                <CustomImageMobile
                                    src={image}
                                    // placeholder={grey_placeholder}
                                />
                            </Faded>
                        </JourneyWelcomeImageWrapper>

                        <div
                            style={{
                                paddingTop: '16px',
                            }}
                        />

                        <JourneyWelcomeText>
                            We know a car is as much about the journey it's been
                            on, as to how it first rolled out of the factory.
                            Ensure future owners know your car's history, so
                            that they can be as great of a custodian as you have
                            been.
                        </JourneyWelcomeText>
                        <JourneyWelcomeButtonsWrapper>
                            <StandardCtaBtn
                                onClick={start}
                                customWidth="100%"
                                bg={
                                    journey_colours[theme].section.handover
                                        .primary_600
                                }
                            >
                                Let's get started
                            </StandardCtaBtn>
                        </JourneyWelcomeButtonsWrapper>
                    </JourneyWelcomeSubWrapperV2>
                </JourneyWelcomeWrapper>
            </IpadAndMobileDisplay>
        </>
    )
}
