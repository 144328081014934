import useThemes from '../../../../../providers/theme/hooks'
import StandardCtaBtn from '../../../../atoms/Button/standardCtaBtn'
import { journey_colours } from '../../../../../providers/theme/colours'
import image from '../../../../../public/assets/images/journey/historyFile/1.png'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import {
    JourneyWelcomeBtnText,
    JourneyWelcomeNewLineOnMobile,
    JourneyWelcomeSubWrapperV2,
    JourneyWelcomeText,
    JourneyWelcomeTextWrapper,
    JourneyWelcomeTitle,
    JourneyWelcomeWrapperV2,
    JourneyWelcomeButtonsWrapper,
} from '../../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import FadedSlower from '../../../../templates/animated/FadedSlower'

type Props = {
    start: () => void
    cancel: (carid: string) => void
}

const JourneyWelcomeImageCustom = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;
    height: 40dvh;

    @media ${device.mobile} {
        height: auto;
        width: 700px;
    }
    @media (max-height: 880px) {
        max-height: 50dvh;
    }

    @media ${device.ipad} {
        height: 44dvh;
    }
`

const CustomImageMobile = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;

    height: auto;
    width: 700px;

    @media (min-width: 500px) {
        max-height: 50dvh;
    }

    @media (max-width: 475px) {
        max-height: 32dvh;
    }

    @media (min-width: 475px) and (max-width: 500px) {
        max-height: 35dvh;
    }
`

const JourneyWelcomeImageWrapperCustom = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media ${device.mobile} {
        width: 100dvw;
        overflow: hidden;
    }
`

export default function JourneyHistoryFileWelcome({ start, cancel }: Props) {
    const { theme } = useThemes()

    return (
        <>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneyWelcomeWrapperV2>
                        <JourneyWelcomeSubWrapperV2>
                            <JourneyWelcomeTextWrapper>
                                <div />

                                <JourneyWelcomeTitle>
                                    Bring your car's history online
                                </JourneyWelcomeTitle>
                                <JourneyWelcomeText>
                                    There are many advantages to digitising your
                                    car's history file. From searching through
                                    all your car's documents wherever you are,
                                    to protecting important service information
                                    for future generations.
                                </JourneyWelcomeText>
                            </JourneyWelcomeTextWrapper>

                            <div
                                style={{
                                    paddingTop: '50px',
                                }}
                            />

                            <JourneyWelcomeImageWrapperCustom>
                                <FadedSlower isFullWidth={true}>
                                    <JourneyWelcomeImageCustom
                                        src={image}
                                        // placeholder={grey_placeholder}
                                    />
                                </FadedSlower>
                            </JourneyWelcomeImageWrapperCustom>

                            <div
                                style={{
                                    paddingTop: '40px',
                                }}
                            />

                            <JourneyWelcomeButtonsWrapper>
                                <StandardCtaBtn
                                    onClick={start}
                                    bg={
                                        journey_colours[theme].section
                                            .history_file.primary_600
                                    }
                                >
                                    Let's get started
                                </StandardCtaBtn>
                                <StandardCtaBtn
                                    onClick={cancel}
                                    boder="transparent"
                                    bg="transparent"
                                    removeShadowOnHover
                                    customWidth="100%"
                                >
                                    <JourneyWelcomeBtnText
                                        style={{
                                            color: journey_colours[theme]
                                                .section.history_file
                                                .primary_600,
                                        }}
                                    >
                                        Cancel
                                    </JourneyWelcomeBtnText>
                                </StandardCtaBtn>
                            </JourneyWelcomeButtonsWrapper>
                        </JourneyWelcomeSubWrapperV2>
                    </JourneyWelcomeWrapperV2>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyWelcomeWrapperV2>
                    <JourneyWelcomeSubWrapperV2 style={{ paddingTop: 20 }}>
                        <JourneyWelcomeTitle
                            style={{
                                fontSize: '26px',
                                maxWidth: '90vw',
                                lineHeight: '30px',
                                paddingTop: 0,
                            }}
                        >
                            Bring your car's <JourneyWelcomeNewLineOnMobile />
                            <span
                                style={{
                                    color: journey_colours[theme].section
                                        .history_file.primary_500,
                                    fontSize: '28px',
                                }}
                            >
                                history online
                            </span>{' '}
                        </JourneyWelcomeTitle>

                        <div
                            style={{
                                paddingTop: '48px',
                            }}
                        />

                        <JourneyWelcomeImageWrapperCustom>
                            <Faded width={'auto'}>
                                <CustomImageMobile
                                    src={image}
                                    // placeholder={grey_placeholder}
                                />
                            </Faded>
                        </JourneyWelcomeImageWrapperCustom>

                        <div
                            style={{
                                paddingTop: '12px',
                            }}
                        />

                        <JourneyWelcomeText>
                            There are many advantages to digitising your car's
                            history file. From searching through all your car's
                            documents wherever you are, to protecting important
                            service information for future generations.
                        </JourneyWelcomeText>

                        <div
                            style={{
                                paddingTop: '24px',
                            }}
                        />

                        <StandardCtaBtn
                            onClick={start}
                            customWidth="90vw"
                            bg={
                                journey_colours[theme].section.history_file
                                    .primary_600
                            }
                        >
                            Let's get started
                        </StandardCtaBtn>

                        <div
                            style={{
                                paddingTop: '36px',
                            }}
                        />
                    </JourneyWelcomeSubWrapperV2>
                </JourneyWelcomeWrapperV2>
            </IpadAndMobileDisplay>
        </>
    )
}
