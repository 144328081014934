import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import grey_placeholder_dark from '../../../public/assets/placeholder/grey_placeholder_dark.jpg'
import { device } from '../../templates/displays/devices'
import ButtonAtom from '../Button/ButtonAtom'
import Icon from '../icons'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import { IGalleryImage } from '../../../redux/entities/galleries/types'

type IImgProps = {
    isthingloading?: boolean
    height?: string
    ipadHeight?: string
}

const ImageStyleWrapper = styled.div<IImgProps>`
    width: 100%;
    box-sizing: border-box;
    position: relative;
    object-fit: cover !important;
    height: ${(props) => (props.height ? props.height : '230px')};

    @media ${device.ipad} {
        height: ${(props) =>
            props.ipadHeight
                ? props.ipadHeight
                : props.height === '160px'
                ? '230px'
                : '350px'};
        margin: 0 auto;
    }
    z-index: 1;
`

const ImageStyle = styled.img<IImgProps>`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    object-fit: cover !important;

    opacity: ${(props) => (props.isthingloading === false ? 1 : 0)};
    transition: opacity 400ms;

    z-index: 1;
`

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @media ${device.ipad} {
        margin: 0 auto;
    }
`

const ThreeDotsEdit = styled.div`
    align-self: flex-end;
    justify-self: flex-end;
    padding-right: 30px;
    padding-left: 30px;
    transform: translateY(-50px);
    z-index: 2;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0px;
`

export interface IImageProps {
    img?: IGalleryImage
    placeholder?: string
    height?: string
    ipadHeight?: string
    onClick?: any
    threeDotsMenu?: boolean
    isDarkTheme?: boolean
    aspectRatio?: number
}

const FullWidthMobile: React.FC<IImageProps> = ({
    img,
    placeholder,
    height,
    ipadHeight,
    onClick,
    threeDotsMenu,
    isDarkTheme,
    aspectRatio,
}) => {
    const [src, setSrc] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        const initialSrc =
            img && img.url !== undefined
                ? img.url
                : placeholder
                ? placeholder
                : isDarkTheme
                ? grey_placeholder_dark
                : grey_placeholder

        if (initialSrc) {
            setSrc(initialSrc)
        }

        return
    }, [img, placeholder])

    return (
        <Wrapper>
            <SkeletonAbsolute
                mobileHeight={height && height}
                isthingloading={isLoading}
                darkTheme={isDarkTheme}
            />

            <ImageStyleWrapper
                ipadHeight={ipadHeight && ipadHeight}
                height={height && height}
                style={
                    aspectRatio
                        ? { aspectRatio: aspectRatio, height: 'auto' }
                        : {}
                }
            >
                <ImageStyle
                    src={src || grey_placeholder}
                    onClick={(e: any) => {
                        onClick && !threeDotsMenu && onClick()
                    }}
                    isthingloading={isLoading}
                    srcSet={src}
                    onLoad={() => {
                        setIsLoading(false)
                    }}
                />

                {threeDotsMenu && !isLoading && (
                    <ThreeDotsEdit
                        onClick={(e: any) => {
                            e && e.preventDefault()
                            onClick && onClick()
                        }}
                    >
                        <div style={{ transform: 'translateY(10px)' }}>
                            <ButtonAtom theme="naked">
                                <Icon icon="three_dots_vertical" />
                            </ButtonAtom>
                        </div>
                    </ThreeDotsEdit>
                )}
            </ImageStyleWrapper>
        </Wrapper>
    )
}

export default FullWidthMobile
