import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { insuranceActions } from './../../reducer'
import posthog from 'posthog-js'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'
import { push } from 'redux-first-history'

export type DeleteUserInsuranceApplicationProps = {
    uid: string
    redirectURL?: string
}
export function* DeleteUserInsuranceApplication(
    p: DeleteUserInsuranceApplicationProps
): any {
    try {
        posthog.capture('Delete insurance application')

        yield call(
            api.insuranceApplication.deleteUserInsuranceApplication,
            p.uid
        )

        yield put(insuranceActions.delete_insurance_application_success(p.uid))

        if (p.redirectURL) {
            yield put(insuranceActions.setRecentDeletedAppliID(p.uid))
            yield put(push(p.redirectURL))
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: DeleteUserInsuranceApplication,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.delete_insurance_application_error(customError)
            )
        }
    }
}

function* Watcher_Delete_UserApplication() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.delete_insurance_application_request
        )
        yield call(DeleteUserInsuranceApplication, payload)
    }
}

const insurance_delete_user_application: any[] = [
    fork(Watcher_Delete_UserApplication),
]

export default insurance_delete_user_application
