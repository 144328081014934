import { call, fork, put, select, take } from 'redux-saga/effects'

import posthog from 'posthog-js'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { api } from '../../services'
import { searchUsersActions } from '../reducer'
import { IRootState } from '../../store'
import { ISearchUsersResult } from '../types'
import { IReduxError } from '../../entities/cars/types'

const get_current_user = (state: IRootState) =>
    state.user.userLoggedIn && state.user.userLoggedIn.id

export function* CheckUserExistsByEmailSaga(payload: string): any {
    try {
        if (payload && payload.trim().length > 5 && payload.trim() !== '') {
            let trimmedPayload = payload.trim()

            let response: ISearchUsersResult | undefined = yield call(
                api.userData.userExistsCheck.checkUserExistsByEmailAPI,
                trimmedPayload
            )

            let currentUserId: string | undefined = yield select(
                get_current_user
            )

            if (response !== undefined && response.uid !== currentUserId) {
                yield put(
                    searchUsersActions.checkUserEmailExistsSuccess([response])
                )
            } else {
                yield put(
                    searchUsersActions.checkUserEmailExistsSuccess(undefined)
                )
            }

            posthog.capture('SEARCH USER IN DIRECT SHARE OR HANDOVER')
        } else {
            yield put(searchUsersActions.checkUserEmailExistsSuccess(undefined))
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: CheckUserExistsByEmailSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customError: IReduxError = ConvertToReduxError(error)
            yield put(searchUsersActions.checkUserEmailExistsError(customError))
        }

        yield put(searchUsersActions.checkUserEmailExistsSuccess(undefined))
    }
}

function* watcherCheckUserExistsByEmail() {
    while (true) {
        const { payload } = yield take(
            searchUsersActions.checkUserEmailExistsRequest
        )

        yield call(CheckUserExistsByEmailSaga, payload)
    }
}

const check_user_exists: any[] = [fork(watcherCheckUserExistsByEmail)]

export default check_user_exists
