import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'

import { api } from '../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ConvertDateToStateReminders } from '../../conversions/reminders/convertToStateReminders'
import { tasksActions } from '../reducer'
import { IReminder, IPayloadTaskRemindersState } from 'entityModels'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'
import { IReminderAPIDataRes } from '../../services/types'

export type IGetTaskRemindersReq = {
    taskUID: string
    dueDate: string
}

export function* getTaskRemindersSaga(p: IGetTaskRemindersReq): any {
    try {
        const task_reminders_data: IReminderAPIDataRes = yield call(
            api.tasks.reminders.getTaskReminders,
            p.taskUID
        )

        let converted_reminders: IReminder[] = ConvertDateToStateReminders(
            task_reminders_data.data,
            p.dueDate
        )

        let pa: IPayloadTaskRemindersState = {
            taskUID: p.taskUID,
            reminders: converted_reminders,
        }
        yield put(tasksActions.getTaskRemindersSuccess(pa))
        return converted_reminders
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getTaskRemindersSaga,
                payload: p.taskUID,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't get the task's reminders`,
                custom_user_action_text: 'Return to see all tasks',
                custom_redirect_path: `/tasks`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(tasksActions.getTaskRemindersError(customError))
        }
    }
}

function* watcherGetTaskReminders() {
    while (true) {
        const { payload } = yield take(tasksActions.getTaskRemindersRequest)
        yield call(getTaskRemindersSaga, payload)
    }
}

const get_task_reminders: any[] = [fork(watcherGetTaskReminders)]

export default get_task_reminders
