import React from 'react'
import styled from 'styled-components'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'

const SearchResultsDropdownItem = styled.div`
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 40px;
    background-color: var(--off-bg-color, #fafafa);
`
const ProfilePicWrapper = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 20px;
`

const UserName = styled.div`
    position: relative;
    height: 14px;
    width: 200px;
    margin-left: 20px;
    padding-right: 20px;
`

interface IItemProps {}

const SearchLoadingPlaceholdersDesktop: React.FunctionComponent<IItemProps> = (
    props
) => (
    <SearchResultsDropdownItem>
        <ProfilePicWrapper>
            <SkeletonAbsolute isthingloading={true} isRound />
        </ProfilePicWrapper>
        <UserName>
            <SkeletonAbsolute isthingloading={true} />
        </UserName>
    </SearchResultsDropdownItem>
)

export default SearchLoadingPlaceholdersDesktop
