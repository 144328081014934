import * as React from 'react'
import styled from 'styled-components'
import devices_grid from '../../../public/assets/images/devices_grid.png'
import app_store_logo from '../../../public/assets/images/app_store_logo.png'
import native_app_prompt_arrow from '../../../public/assets/images/native_app_prompt_arrow.png'
import { AnimatePresence, motion } from 'framer-motion'
import qr_code_native_app from '../../../public/assets/images/qr_code_native_app.png'
import FadedSlower from '../../templates/animated/FadedSlower'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'

const EditFormContainerDesktop = styled.section`
    width: 90vw;
    height: 560px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: var(--text-strong, #1a1a1a);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1220px;
`

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 70px;
    width: 400px;
    position: relative;
`

const RightSide = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    border-radius: 10px;
    z-index: 2;
    max-width: 80%;
`
const RightSideImg = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
    object-position: left;
    border-radius: 10px;
`

const QrCodeForNativeAppImg = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
`
const QrCodeForNativeApp = styled.div`
    width: 78px;
    height: 78px;
    transform: translateX(-5px);
`

const AppStoreAppLogo = styled.img`
    height: 48px;
`

const Title = styled.div`
    font-size: 32px;
    font-family: Lato;
    color: var(--primary, #5ec3ca);
`

const Text = styled.div`
    font-size: 16px;
    font-family: Lato;
    color: var(--text-darker, #616161);
`

const Close = styled.div`
    font-size: 16px;
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    padding-left: 3px;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
    :hover {
        background-color: var(--primary_04, #5ec3ca18);
    }
`

const Arrow = styled(motion.img)`
    position: absolute;
    top: 150px;
    right: 60px;
    height: 90px;
`

type IProps = { onClose: any }

const NativeAppPromptDesktop: React.FunctionComponent<IProps> = (props) => {
    return (
        <DesktopDisplayOnly>
            <ModalDisplayAbsolute isOpen toggle={props.onClose}>
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <FadedSlower>
                        <EditFormContainerDesktop
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            <LeftSide>
                                <Title>iOS app available now</Title>
                                <div style={{ paddingTop: 24 }} />
                                <Text>
                                    You can now download our app from the App
                                    Store and enjoy managing your garage on your
                                    iPhone and iPad.
                                </Text>
                                <div style={{ paddingTop: 48 }} />
                                <a
                                    href="https://apps.apple.com/gb/app/custodian/id1665962547"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        display: 'block',
                                    }}
                                >
                                    <AppStoreAppLogo src={app_store_logo} />
                                </a>
                                <div style={{ paddingTop: 24 }} />
                                <QrCodeForNativeApp>
                                    <QrCodeForNativeAppImg
                                        src={qr_code_native_app}
                                        alt="native_app_qr_code"
                                    />
                                </QrCodeForNativeApp>
                                <div style={{ paddingTop: 32 }} />
                                <Close onClick={props.onClose}>Close</Close>
                                <Arrow
                                    initial={{ scale: 0, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    exit={{ scale: 0, opacity: 0 }}
                                    transition={{
                                        scale: {
                                            delay: 0.4,
                                            duration: 0.1,
                                        },
                                    }}
                                    src={native_app_prompt_arrow}
                                    alt="arrow"
                                />
                            </LeftSide>
                            <RightSide>
                                <AnimatePresence>
                                    <motion.div
                                        style={{ height: '100%' }}
                                        initial={{
                                            opacity: 0,
                                            x: 900,
                                        }}
                                        animate={{
                                            opacity: 1,
                                            x: 0,
                                        }}
                                        exit={{ opacity: 0, x: 900 }}
                                        transition={{
                                            duration: 0.9,
                                            opacity: {
                                                delay: 0.2,
                                                duration: 0.5,
                                                type: 'linear',
                                            },
                                            x: {
                                                duration: 0.9,
                                                type: 'spring',
                                                bounce: 0.25,
                                            },
                                        }}
                                    >
                                        <RightSideImg
                                            src={devices_grid}
                                            alt="devices"
                                        />
                                    </motion.div>
                                </AnimatePresence>
                            </RightSide>
                            <AnimatePresence></AnimatePresence>
                        </EditFormContainerDesktop>
                    </FadedSlower>
                </div>
            </ModalDisplayAbsolute>
        </DesktopDisplayOnly>
    )
}

export default NativeAppPromptDesktop
