import * as React from 'react'
import styled from 'styled-components'

interface IStyle {
    iconOnLeft?: boolean | undefined
    isUppercase?: boolean | undefined
    width?: string | undefined
    height?: string | undefined
    nobg?: boolean
    disabled?: boolean
}

const Button = styled.button<IStyle>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '56px')};
    border: transparent;
    outline: none;
    background-color: var(--bg-color, #ffffff);
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 5px 5px 20px 3px var(--box-shadow, rgba(0, 0, 0, 0.03));

    :hover {
        background-color: var(--off-bg-color, #fafafa);
        transition: all 200ms;
    }
`

const Text = styled.div<IStyle>`
    color: ${(props) =>
        props.disabled
            ? 'var(--text-default, #666666)'
            : 'var(--primary, #5EC3CA)'};
    font-size: 16px;
    font-family: Lato;
`

type Props = {
    onClick: any
    text: string | undefined
    dataCyId?: string | undefined
    width?: string | undefined
    height?: string | undefined
    disabled?: boolean
    type?: 'submit' | 'reset' | 'button' | undefined
}

const WhiteBgNoBorder: React.FC<Props> = ({
    onClick,
    text,
    width,
    height,
    dataCyId,
    disabled,
    type,
}) => {
    return (
        <Button
            width={width}
            height={height}
            onClick={disabled ? () => {} : onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            disabled={disabled}
            type={type ? type : 'button'}
        >
            <Text disabled={disabled}>{text}</Text>
        </Button>
    )
}

export default WhiteBgNoBorder
