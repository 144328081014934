import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAttachmentsReducer, INormalisedAttachmentsByID } from './types'

const initialState: IAttachmentsReducer = {
    attachmentsById: {},
    loading: false,
    error: null,
}

const attachmentsSlice = createSlice({
    name: 'attachments',
    initialState,
    reducers: {
        addAttachmentsRequest: (state) => {
            state.loading = true
            state.error = null
        },
        addAttachmentsError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },
        addAttachmentsSuccess: (
            state,
            action: PayloadAction<INormalisedAttachmentsByID>
        ) => {
            state.attachmentsById = {
                ...state.attachmentsById,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },
        cleanUpAttachmentsSuccess: (state) => {
            state.attachmentsById = {}
            state.loading = false
            state.error = null
        },
    },
})

export const attachmentActions = attachmentsSlice.actions

export default attachmentsSlice.reducer

export type IAttachmentsState = ReturnType<typeof attachmentsSlice.reducer>
