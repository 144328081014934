import * as React from 'react'
import styled from 'styled-components'
import { device } from '../devices'
import { checkIfPwaStandalone } from '../../pwaCustom/checkIpadPwa'
import { useAppSelector } from '../../../../redux/store/hooks'

interface Props {
    children?: React.ReactNode
    hasMobileSubNav?: boolean
    isOnboarding?: boolean
    bg?: string
}

interface Istyle {
    $isCollapsed: boolean
    $isExternalPage?: boolean
    $hasMobileSubNav?: boolean
    $isOnboarding?: boolean
    $isDefaultNavigationRemoved?: boolean
}

const PageWrapperStyle = styled.div<Istyle>`
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 881px) {
        position: relative;
        margin-top: ${(props) =>
            props.$isDefaultNavigationRemoved
                ? 0
                : props.$isExternalPage
                ? '75px'
                : '70px'};
        transition: all 100ms;

        padding-left: 0px;
        padding-right: 0px;
        transition: all 500ms;
    }

    @media (max-width: 880px) {
        padding-top: ${(props) =>
            props.$hasMobileSubNav
                ? '120px'
                : props.$isOnboarding
                ? '0px'
                : '50px'};

        height: 100%;
    }
    @media (max-width: 500px) {
        padding-top: ${(props) =>
            props.$hasMobileSubNav
                ? '100px'
                : props.$isOnboarding
                ? '0px'
                : '50px'};

        height: 100%;
    }
`

const MenuAdjust = styled.div<Istyle>`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    transition: all 100ms;

    @media (max-width: 799px) {
        display: flex;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding-bottom: 100px;
    }

    @media ${device.desktop} {
        padding-left: ${(props) => (props.$isCollapsed ? '50px' : '240px')};
    }
    @media ${device.large_desktop} {
        padding-left: ${(props) =>
            props.$isCollapsed ? '71px' : '311px'}; //. 230
    }

    @media ${device.smallest_laptops} {
        padding-left: ${(props) => (props.$isCollapsed ? '50px' : '240px')};
    }
`

const FullPageWrapperShowroom: React.FC<Props> = ({
    children,
    hasMobileSubNav,
    isOnboarding,
    bg,
}) => {
    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )
    const isDefaultNavigationRemoved = useAppSelector(
        (state) => state.menus.isDefaultNavigationRemoved
    )

    const currentPath = checkIfPwaStandalone()
        ? window.location.pathname
        : window.location.pathname

    const isExternalPage: boolean = currentPath.match(/shared/g) ? true : false

    return (
        <React.Fragment>
            <MenuAdjust
                style={{
                    background: bg ?? 'white',
                }}
                $isCollapsed={isCollapsed}
            >
                <PageWrapperStyle
                    $isExternalPage={isExternalPage}
                    $isCollapsed={isCollapsed}
                    $hasMobileSubNav={hasMobileSubNav}
                    $isOnboarding={isOnboarding}
                    $isDefaultNavigationRemoved={isDefaultNavigationRemoved}
                >
                    {children}
                </PageWrapperStyle>
            </MenuAdjust>
        </React.Fragment>
    )
}

export default FullPageWrapperShowroom
