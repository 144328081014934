import * as React from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import grey_placeholder_dark from '../../../public/assets/placeholder/grey_placeholder_dark.jpg'
import { device } from '../../templates/displays/devices'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import SimpleTag from '../tags/simpleTag'
import { useState, useEffect } from 'react'
import { IGalleryImage } from '../../../redux/entities/galleries/types'
import useThemes from '../../../providers/theme/hooks'

interface StyleProps {
    isthingloading: boolean
    $customIpadHeight?: string
    $customHeight?: string
}

const ImageSizeWrapper = styled.div<StyleProps>`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    @media ${device.ipad} {
        height: ${(props: StyleProps) => props.$customIpadHeight ?? '26vh'};
    }
`

const GalleryImage = styled.img<StyleProps>`
    object-fit: cover !important;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: ${(props) => (props.isthingloading === true ? '0' : '1')};
    transition: opacity 400ms;

    height: ${(props: StyleProps) => props.$customHeight ?? '126px'};

    @media ${device.ipad} {
        height: ${(props: StyleProps) => props.$customIpadHeight ?? '26vh'};
    }
`

interface Props {
    img?: IGalleryImage
    placeholder?: string
    customHeight?: string
    customIpadHeight?: string
    tag?: string
}

const DuoGridItemMobile: React.FC<Props> = ({
    img,
    placeholder,
    customHeight,
    customIpadHeight,
    tag,
}) => {
    const [src, setSrc] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { theme } = useThemes()

    useEffect(() => {
        const imageSrc =
            img && img.url !== undefined
                ? img.url
                : placeholder
                ? placeholder
                : theme === 'dark'
                ? grey_placeholder_dark
                : grey_placeholder

        if (imageSrc) {
            setSrc(imageSrc)
        }
    }, [img])

    return (
        <ImageSizeWrapper
            isthingloading={isLoading}
            $customIpadHeight={customIpadHeight}
        >
            <SkeletonAbsolute isthingloading={isLoading} />

            <GalleryImage
                aria-label={img && img.caption ? img.caption : 'picture'}
                src={src}
                srcSet={img && img.small_srcset ? img.small_srcset : undefined}
                isthingloading={isLoading}
                onLoad={() => setIsLoading(false)}
                $customHeight={customHeight}
                $customIpadHeight={customIpadHeight}
            />
            {tag && (
                <div
                    style={{
                        position: 'absolute',
                        right: 10,
                        bottom: 10,
                    }}
                >
                    <SimpleTag text={tag} />
                </div>
            )}
        </ImageSizeWrapper>
    )
}

export default DuoGridItemMobile
