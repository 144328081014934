import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import InputField from '../../../atoms/Inputfield/inputField'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
    Notice,
} from '../../../molecules/technicalInformation/modal'
import { message } from 'antd'
import useThemes from '../../../../providers/theme/hooks'
import { ICreateShareInviteRequestPayload } from '../../../../redux/entities/cars/actions'
import { IUser } from '../../../../redux/user/types'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { garagesActions } from '../../../../redux/entities/garages/reducer'

export const FormHeader = styled.div`
    padding-top: 3px;
    color: black;
    font-size: 16px;
    text-align: left;
    padding-right: 5vw;
    font-family: Lato-Light;

    @media ${device.large_desktop} {
        font-size: 17px;
    }
`

const SmallPadding = styled.div`
    padding-top: 24px;
`

const LargePadding = styled.div`
    padding-top: 60px;
`

export const Error = styled.div`
    color: red;
    font-style: italic;
    font-size: 11px;
`

interface Values {
    single_item_edited_value_email_field_desktop: string
    single_item_edited_value_first_name_field_desktop: string
    single_item_edited_value_last_name_field_desktop: string
}

const errorBanner = (messageText: string) => {
    message.error(messageText)
}

interface OtherProps {
    user: IUser | null
    close: any
    share_id: string
    entity_id: string
    isGarage?: boolean
    isDisabled?: boolean
    dataCyId?: string
    dispatch: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { close, values, errors, isDisabled, dataCyId } = props

    const { theme } = useThemes()

    return (
        <ModalContentDesktop
            $theme={theme}
            style={{ marginTop: 0, marginBottom: 0, maxWidth: '480px' }}
        >
            <ModalInnerContentWrapperDesktop>
                <ModalTitle $theme={theme}>
                    Invite to Custodian via Email & Share
                </ModalTitle>
                <SmallPadding style={{ paddingTop: 8 }} />
                <Notice>
                    Fill up some basic informations about the person you want to
                    invite
                </Notice>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id={'single_item_edited_value_email_field_desktop'}
                    name={'single_item_edited_value_email_field_desktop'}
                    placeholder={'Email'}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.single_item_edited_value_email_field_desktop}
                    font_family="Lato"
                    paddingLeft="0px"
                    width="100%"
                    fontSize={'14px'}
                    dataCyId={dataCyId}
                    linecolor="#C7C7C7"
                    type={'email'}
                    disabled={isDisabled ? isDisabled : false}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <Expander
                    height={
                        errors.single_item_edited_value_email_field_desktop
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {
                                errors.single_item_edited_value_email_field_desktop
                            }
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id={'single_item_edited_value_first_name_field_desktop'}
                    name={'single_item_edited_value_first_name_field_desktop'}
                    placeholder={'Given Name'}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={
                        values.single_item_edited_value_first_name_field_desktop
                    }
                    font_family="Lato"
                    paddingLeft="0px"
                    width="100%"
                    fontSize={'14px'}
                    textTransform={'capitalize'}
                    dataCyId={dataCyId}
                    linecolor="#C7C7C7"
                    type={'text'}
                    disabled={isDisabled ? isDisabled : false}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <Expander
                    height={
                        errors.single_item_edited_value_first_name_field_desktop
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {
                                errors.single_item_edited_value_first_name_field_desktop
                            }
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id={'single_item_edited_value_last_name_field_desktop'}
                    name={'single_item_edited_value_last_name_field_desktop'}
                    placeholder={'Family Name'}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={
                        values.single_item_edited_value_last_name_field_desktop
                    }
                    font_family="Lato"
                    paddingLeft="0px"
                    width="100%"
                    fontSize={'14px'}
                    textTransform={'capitalize'}
                    dataCyId={dataCyId}
                    linecolor="#C7C7C7"
                    type={'text'}
                    disabled={isDisabled ? isDisabled : false}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <Expander
                    height={
                        errors.single_item_edited_value_last_name_field_desktop
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {
                                errors.single_item_edited_value_last_name_field_desktop
                            }
                        </Error>
                    </Faded>
                </Expander>
                <LargePadding />

                <ButtonsStandardBottomRowDesktop
                    cancel={close}
                    submit={props.handleSubmit}
                    secondText={'Send Invite'}
                    isDisabled={
                        errors.single_item_edited_value_email_field_desktop ||
                        errors.single_item_edited_value_first_name_field_desktop ||
                        errors.single_item_edited_value_last_name_field_desktop
                            ? true
                            : false
                    }
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const AddShareByEmailFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_email_field_desktop: '',
        single_item_edited_value_first_name_field_desktop: '',
        single_item_edited_value_last_name_field_desktop: '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_edited_value_email_field_desktop: Yup.string()
                    .email('Email not valid')
                    .required('Email is required'),
                single_item_edited_value_first_name_field_desktop: Yup.string()
                    .min(2, 'First name should have at least 2 characters.')
                    .max(30, 'First name should have max 30 characters.')
                    .required('First name is required'),
                single_item_edited_value_last_name_field_desktop: Yup.string()
                    .min(2, 'Last name should have at least 2 characters.')
                    .max(30, 'Last name should have max 30 characters.')
                    .required('Last name is required'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        let payload: ICreateShareInviteRequestPayload = {
            entity_id: props.entity_id,
            api_payload: {
                share_id: props.share_id,
                recipient_email:
                    values.single_item_edited_value_email_field_desktop &&
                    values.single_item_edited_value_email_field_desktop.trim(),
                recipient_given_name:
                    values.single_item_edited_value_first_name_field_desktop
                        ? values.single_item_edited_value_first_name_field_desktop.trim()
                        : '',
                recipient_family_name:
                    values.single_item_edited_value_last_name_field_desktop
                        ? values.single_item_edited_value_last_name_field_desktop.trim()
                        : '',
            },
        }

        if (
            props.user &&
            props.user.email &&
            values.single_item_edited_value_email_field_desktop &&
            props.user.email.toLowerCase() !==
                values.single_item_edited_value_email_field_desktop
                    .trim()
                    .toLowerCase()
        ) {
            props.isGarage
                ? props.dispatch(
                      garagesActions.createGarageShareInviteRequest(payload)
                  )
                : props.dispatch(
                      carActions.createCarShareInviteRequest(payload)
                  )
            setSubmitting(false)
            props.close()
        } else {
            errorBanner('You cannot add an invite for yourself.')
        }
    },
})(InnerForm)

export default AddShareByEmailFormDesktop
