import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import posthog from 'posthog-js'
import { usersActions } from '../reducer'
import { IReduxError } from '../../entities/cars/types'

export function* resetPasswordSaga(email: string): any {
    try {
        yield call(
            api.authentication.resetPasswordRequestApi,
            email.toLowerCase()
        )

        yield put(usersActions.resetPasswordSuccess())
        // Capture event
        posthog.capture('RESET PASSWORD REQUEST')
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)
        yield put(usersActions.resetPasswordError(typedError))
    }
}

function* watcherResetPassword() {
    while (true) {
        const { payload } = yield take(usersActions.resetPasswordRequest)
        yield call(resetPasswordSaga, payload)
    }
}

const resetPassword: any[] = [fork(watcherResetPassword)]

export default resetPassword
