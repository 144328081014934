import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import InsuranceQuoteAddressesManager from '../../../../organisms/insuranceQuote/addressesManager'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import { UserParams } from './insuranceOtherDriverBasicDetailsApplicationFlow'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'

import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IInsuranceQuoteApplication } from '../../../../../redux/insuranceQuoteApplication/reducer'
import { IRootState } from '../../../../../redux/store'

const InsuranceOtherDriverAddressManager = () => {
    const navigate = useNavigate()

    const { userid } = useParams<UserParams>()

    const dispatch = useAppDispatch()

    const { id } = useParams<{ id?: string }>()

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let { getUserAndApplicationData } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: 'Named Driver Name',
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/other_driver/${userid}`
                                ),
                        },
                        {
                            pageName: 'Driver licence',
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/other_driver/${userid}/licence_address`
                                ),
                        },
                        { pageName: 'Select Address' },
                    ]}
                    progressionRate={15}
                    sectionId="drivers"
                />
            </DesktopDisplayOnly>
            <InsuranceQuoteAddressesManager
                goBack={() =>
                    navigate(
                        `/insurance/application/${id}/other_driver/${userid}/licence_address`
                    )
                }
            />

            <IpadAndMobileDisplay>
                <InsuranceTopBarWithProgressionRateMobile
                    breadCrumbs={[
                        {
                            pageName: 'Named Driver Name',
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/other_driver/${userid}`
                                ),
                        },
                        {
                            pageName: 'Driver licence',
                            pageUrl: () =>
                                navigate(
                                    `/insurance/application/${id}/other_driver/${userid}/licence_address`
                                ),
                        },
                        { pageName: 'Select Address' },
                    ]}
                    progressionRate={15}
                    sectionId="drivers"
                />
                <InsuranceQuoteAddressesManager
                    goBack={() =>
                        navigate(
                            `/insurance/application/${id}/other_driver/${userid}/licence_address`
                        )
                    }
                    isMobile
                />
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceOtherDriverAddressManager
