import React, { useState, useCallback } from 'react'
import Fuse, { FuseResult } from 'fuse.js'
import { debounce } from 'lodash'
import LabelsFilterByMultipleWithSearchUIMobile from './labelsFilterByMultipleWSearchUIMobile'
import { IDropdownItem } from 'entityModels'
import { IEditOrCreateModeSingleFieldsProps } from '../../../molecules/editOrCreateModeSingleFields/types'

export type Props = {
    labels_searchable_list: IDropdownItem[]
    searchFor?: any
    addTagFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    issearchactive?: boolean | undefined
    hasInnerSaveBtn?: boolean
    addToEntry?: any
    editData?: IEditOrCreateModeSingleFieldsProps
}

const LabelsFilterByMultipleWSearchWFuseMobile: React.FC<Props> = ({
    dataCyId,
    addToEntry,
    labels_searchable_list,
    editData,
}) => {
    const [state, setState] = useState<{
        data: any[]
        labelsDataRes: FuseResult<any>[]
        name: any[]
        fetching: boolean
        lastFetchId: number
    }>({
        data: [],
        labelsDataRes: [],
        name: [],
        fetching: false,
        lastFetchId: 0,
    })

    const fetchItem = useCallback(
        debounce((name: any) => {
            const newFetchId = state.lastFetchId + 1

            setState((prev) => ({
                ...prev,
                data: [],
                fetching: true,
                lastFetchId: newFetchId,
            }))

            if (newFetchId !== state.lastFetchId) {
                return
            }

            const fuseLabels = new Fuse(labels_searchable_list, {
                keys: ['name'],
            })

            const resultsLabel: FuseResult<any>[] = fuseLabels.search(name, {
                limit: 2,
            })

            setState((prev) => ({
                ...prev,
                labelsDataRes: resultsLabel,
                fetching: false,
            }))
        }, 180),
        [labels_searchable_list, state.lastFetchId]
    )

    const handleChange = useCallback((value: any) => {
        setState((prev) => ({
            ...prev,
            name: [value],
            data: [],
            labelsDataRes: [],
            fetching: false,
        }))
    }, [])

    const clearField = useCallback(() => {
        setState((prev) => ({
            ...prev,
            name: [],
            data: [],
            labelsDataRes: [],
            fetching: false,
        }))
    }, [])

    return (
        <LabelsFilterByMultipleWithSearchUIMobile
            dataCyId={dataCyId}
            labels_searchable_list={labels_searchable_list}
            value={state.name}
            handleChange={handleChange}
            fetchItem={fetchItem}
            labelsDataRes={state.labelsDataRes}
            addToEntry={addToEntry}
            editData={editData}
            clearField={clearField}
        />
    )
}

export default LabelsFilterByMultipleWSearchWFuseMobile
