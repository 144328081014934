import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Sheet } from 'react-modal-sheet'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import quickMenuAddImages from '../../../../public/assets/icons/quickMenuAddImages.svg'
import quickMenuAddTask from '../../../../public/assets/icons/quickMenuAddTask.svg'
import quickMenuUpdateMileage from '../../../../public/assets/icons/quickMenuUpdateMileage.svg'
import quickMenuUpdateCarProfileImg from '../../../../public/assets/icons/quickMenuUpdateCarProfileImg.svg'
import timeline_blue_icon from '../../../../public/assets/icons/timeline_blue_icon.svg'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React from 'react'

const CustomSheet = styled(Sheet)`
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: 280px !important;
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
    }
    .react-modal-sheet-header {
        height: 10px !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 0px !important;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`
const MenuItem = styled.div`
    color: #0e0e0e !important;
    height: 50px;
    width: 100vw;
    opacity: 1 !important;
    font-family: 'Lato';
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    letter-spacing: 0.09rem;
`

const MenuItemBox = styled(motion.div)<IStyle>`
    background-color: #f9f9f9;
    flex-direction: row;
    color: #5ec3ca !important;
    border-bottom: ${(props) => (props.is_last ? 'none' : '1px solid #f1f1f1')};
    :focus {
        outline: 0;
    }
`

interface IStyle {
    is_last: boolean
}

type IactionsItems = {
    id: string
    action?: any
}

interface IProps {
    actions_items: IactionsItems[]
    toggle: any
    isCarOverviewQuickMenuOpen: boolean
}

const CarOverviewActionsMobile = (props: IProps) => {
    const { actions_items, toggle, isCarOverviewQuickMenuOpen } = props

    React.useEffect(() => {
        if (isCarOverviewQuickMenuOpen) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [isCarOverviewQuickMenuOpen])

    return (
        <IpadAndMobileDisplay>
            <CustomSheet isOpen={isCarOverviewQuickMenuOpen} onClose={toggle}>
                <Sheet.Container>
                    <Sheet.Header />

                    <div
                        style={{
                            backgroundColor: '#f9f9f9',
                            paddingTop: '0px',
                        }}
                        data-attr="gallery-image-menu"
                    >
                        {actions_items !== undefined &&
                            actions_items.map((item: any, index: any) => (
                                <MenuItemBox
                                    is_last={
                                        item.id === 'Update Car Profile Image'
                                    }
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        item.action && item.action()
                                    }}
                                    key={`overview_actions_menu_${
                                        index + 10
                                    }_mobile`}
                                    whileTap={{
                                        opacity: 1,
                                        backgroundColor: '#e7e7e7',
                                    }}
                                >
                                    <MenuItem>
                                        <div
                                            style={{
                                                width: '30px',
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width:
                                                        item.id ===
                                                        'Update Mileage'
                                                            ? '25px'
                                                            : '20px',
                                                    transform:
                                                        item.id ===
                                                        'Update Car Profile Image'
                                                            ? 'translateX(2px)'
                                                            : 'none',
                                                }}
                                                src={
                                                    item.id === 'Add a Task'
                                                        ? quickMenuAddTask
                                                        : item.id ===
                                                          'Add Image(s)'
                                                        ? quickMenuAddImages
                                                        : item.id ===
                                                          'Update Mileage'
                                                        ? quickMenuUpdateMileage
                                                        : item.id ===
                                                          'Update Car Profile Image'
                                                        ? quickMenuUpdateCarProfileImg
                                                        : item.id ===
                                                          'Add to History File'
                                                        ? timeline_blue_icon
                                                        : ''
                                                }
                                                alt={`${item.id} icon`}
                                            />
                                        </div>
                                        <span
                                            style={{
                                                paddingRight: '15px',
                                            }}
                                        />

                                        <span
                                            style={{
                                                transform: 'translateY(1px)',
                                            }}
                                        >
                                            {item.id}
                                        </span>
                                    </MenuItem>
                                </MenuItemBox>
                            ))}
                    </div>
                </Sheet.Container>
                <Sheet.Backdrop
                    data-attr="car-overview-menu-backdrop-overlay"
                    onTap={toggle}
                />
            </CustomSheet>
        </IpadAndMobileDisplay>
    )
}

export default CarOverviewActionsMobile
