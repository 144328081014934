import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Faded from '../templates/animated/faded'
import styled from 'styled-components'
import Loader from '../atoms/loader/loader'
import backgroundImage from '../../public/assets/landingPage/zebra_resize.jpg'
import banner_text from '../../public/assets/landingPage/manage_your_car_banner_text.svg'
import banner_text_dark from '../../public/assets/landingPage/manage_your_car_banner_text_dark.svg'
import SignInFormCard from '../organisms/signinform/SignInFormCard'
import { customNavDataActions } from '../../redux/localdata/customNav/reducer'
import { ITheme } from '../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'
import { device } from '../templates/displays/devices'
import { usersActions } from '../../redux/user/reducer'
import useThemes from '../../providers/theme/hooks'

const Wrapper = styled.section<{ $theme: ITheme }>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100dvh;

    @media (max-width: 1600px) {
        justify-content: flex-end;
        padding-bottom: 30px;
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-start;
        /* height: 100vh; */
        max-height: none;
        padding-bottom: 0px;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100dvh;
        max-height: none;
    }

    @media ${device.large_desktop} {
        justify-content: flex-end;
        align-items: flex-start;
        background-image: ${(props) =>
            props.$theme === 'dark'
                ? `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`
                : `url(${backgroundImage})`};
        background-repeat: no-repeat;
        background-size: 120%;
        background-position: center;
        padding-right: 150px;
        padding-bottom: 150px;
        padding-top: 150px;
        animation: 0.3s fadeIn both;
        display: flex;
        align-items: center;
        overflow-y: hidden;
    }

    @media (min-width: 2200px) {
        padding-right: 300px;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`

const ImageDiv = styled.div<{ $theme: ITheme }>`
    background-image: ${(props) =>
        props.$theme === 'dark'
            ? `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`
            : `url(${backgroundImage})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: cover;

    @media ${device.mobile} {
        display: none;
    }

    @media ${device.ipad} {
        display: none;
    }
    @media ${device.desktop} {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 150%;
    }

    @media ${device.large_desktop} {
        display: none;
    }
`

const MobileAndIpadImage = styled.div<{ $theme: ITheme }>`
    background-image: ${(props) =>
        props.$theme === 'dark'
            ? `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`
            : `url(${backgroundImage})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    bottom: -50px;
    z-index: 1;

    @media ${device.mobile} {
    }

    @media ${device.ipad} {
        background-size: 150%;
        height: 70vh;
    }
    @media ${device.desktop} {
        display: none;
    }
`

const BannerTextSVG = styled.div`
    width: 80%;
    padding-bottom: 20px;
    padding-left: 20px;
    animation: 1s slideUp both;

    @media ${device.mobile} {
        display: none;
    }

    @media ${device.ipad} {
        display: none;
    }

    @media ${device.desktop} {
        position: relative;
        width: 70%;
        padding-top: 50px;
        padding-left: 60px;
    }

    @media ${device.large_desktop} {
        position: relative;
        width: 60%;
        padding-top: 100px;
        padding-left: 60px;
    }

    @media (min-width: 2200px) {
        padding-top: 50px;
        padding-left: 80px;
        width: 60%;
    }

    @keyframes slideUp {
        0% {
            opacity: 0;
            transform: translateY(50px);
        }

        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
`

const BannerTextWideScreenWrapper = styled.div`
    position: absolute;
    top: 50px;
    left: 50px;
    @media (max-width: 1599px) {
        display: none;
    }
`

const FormWrapper = styled.section`
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding-left: 30px;
    padding-right: 30px; */
    overflow-y: auto;

    @media (max-width: 1600px) {
        position: relative;
        justify-self: flex-end;
        border-radius: 0px;
        /* width: 30%; */
        width: 25%;
        padding-top: 0px;
    }

    @media ${device.mobile} {
        align-items: center;
        position: relative;
        z-index: 2;
        height: 100%;
        padding-top: calc(env(safe-area-inset-bottom) + 32px);
        width: 100%;
        box-sizing: border-box;
        box-shadow: none;
        border-radius: 0px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 50px;
        background: linear-gradient(
            var(--bg-color, #fff) 85%,
            rgba(255, 255, 255, 0)
        );
        overflow-x: hidden;
    }

    @media ${device.ipad} {
        align-items: center;
        position: relative;
        z-index: 2;
        /* height: auto; */
        padding-top: 50px;
        padding-bottom: 80px;
        width: 100%;
        box-sizing: border-box;
        box-shadow: none;
        border-radius: 0px;
        background: linear-gradient(
            var(--bg-color, #fff) 85%,
            rgba(255, 255, 255, 0)
        );
    }
    @media (min-width: 1201px) and (max-width: 1599px) {
        position: absolute;
        right: 35px;
    }

    @media ${device.large_desktop} {
        padding-top: 10px;
        padding-bottom: 50px;
        border-radius: 10px;
        box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.1);
        width: 400px;
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (min-width: 2150px) {
        padding-left: 40px;
        padding-right: 40px;
        transform: scale(1.2);
    }
`

const Signin: React.FC = () => {
    let { theme } = useThemes()

    const dispatch = useAppDispatch()
    const location = useLocation()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const hasLoggedOut = useAppSelector((state) => state.user.hasLoggedOut)
    const userError = useAppSelector((state) => state.user.error)

    const navigate = useNavigate()
    useEffect(() => {
        dispatch(usersActions.resetErrorStateSuccess())

        const q_params = new URLSearchParams(location.search)
        let external_redirect_to = q_params.get('external_redirect_to')

        if (external_redirect_to) {
            dispatch(
                customNavDataActions.setInitialExternalLandingUrl(
                    external_redirect_to
                )
            )
        }

        // Only try to get user data if there's a refresh token
        const refresh_token = localStorage.getItem('refresh_token')

        if (!userLoggedIn && refresh_token) {
            dispatch(usersActions.getCurrentUserDataRequest('LOGIN'))
        }

        if (userLoggedIn && userLoggedIn !== null && !hasLoggedOut) {
            if (external_redirect_to && userError?.status_code !== 503) {
                navigate(external_redirect_to)
            } else {
                if (userError?.status_code !== 503) {
                    navigate('/garage')
                }
            }
        }
    }, [userLoggedIn, userError])

    return (
        <>
            <Loader />
            <Faded>
                <Wrapper $theme={theme}>
                    <BannerTextWideScreenWrapper>
                        <BannerTextSVG>
                            <img
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                }}
                                src={
                                    theme === 'dark'
                                        ? banner_text_dark
                                        : banner_text
                                }
                            />
                        </BannerTextSVG>
                    </BannerTextWideScreenWrapper>
                    <ImageDiv $theme={theme}>
                        <BannerTextSVG>
                            <img
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    position: 'absolute',
                                    zIndex: 2,
                                }}
                                src={
                                    theme === 'dark'
                                        ? banner_text_dark
                                        : banner_text
                                }
                            />
                        </BannerTextSVG>
                    </ImageDiv>
                    <FormWrapper>
                        <SignInFormCard />
                    </FormWrapper>
                    <MobileAndIpadImage $theme={theme} />
                </Wrapper>
            </Faded>
        </>
    )
}

export default Signin
