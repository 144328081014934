import React, { useEffect } from 'react'
import Faded from '../../templates/animated/faded'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'
import ExpandingItemsListDesktop from '../../molecules/expandingItemsList/expandingItemsListDesktop'
import HelpTutorialSectionDesktop from '../../molecules/helpTutorialsSection/helpTutorialSectionDesktop'
import HelpTutorialSectionMobile from '../../molecules/helpTutorialsSection/helpTutorialSectionMobile'
import ExpandingItemsListMobile from '../../molecules/expandingItemsList/expandingItemsListMobile'
import HelpQuickAccessMobile from '../../molecules/helpQuickAccessMobile/HelpQuickAccessMobile'
import ContactFormMobile from '../../organisms/editForms/contactForm/contactFormMobile'
import ContactFormDesktop from '../../organisms/editForms/contactForm/contactFormDesktop'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IFAQquestionsData } from 'helpPageCopyModels'
import { usersActions } from '../../../redux/user/reducer'

interface IData {
    id: string
    text: string
    answer: string
    links?: string[]
}

const Help: React.FC = () => {
    const dispatch = useAppDispatch()

    const {
        userLoggedIn,
        garage_id,
        garage_data,
        faqData,
        contactReasonsData,
    } = useAppSelector((state) => ({
        userLoggedIn: state.user.userLoggedIn,
        garage_id:
            state.user.userLoggedIn && state.user.userLoggedIn.owns_garage?.uid,
        garage_data: state.entities.garagesData.garages,
        faqData: state.localdata.help_page_copy.faqPageCopy.faqQuestionsData,
        contactReasonsData:
            state.localdata.help_page_copy.selectedContactReason,
    }))

    useEffect(() => {
        dispatch(customNavDataActions.setInitialLandingUrl('/account/help'))

        let userData = userLoggedIn
        if (!userData) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    let carId: string | null =
        garage_id && garage_data[garage_id]
            ? garage_data[garage_id].cars[
                  garage_data[garage_id].cars.length - 1
              ]
            : null

    const faqDataArray: IData[] = Object.values(faqData as IFAQquestionsData)

    return (
        <CenteredPageWrapper>
            {userLoggedIn && (
                <Faded>
                    <Loader />
                    <DesktopDisplayOnly>
                        <div
                            style={{
                                paddingLeft: '50px',
                                paddingRight: '50px',
                            }}
                        >
                            <SectionHeader title={'FAQ'} removeEdit={true} />

                            {/* Add FAQ molecule here */}
                            <ExpandingItemsListDesktop list={faqDataArray} />

                            <div style={{ marginTop: '20px' }} />

                            <SectionHeader
                                title={'Tutorials'}
                                removeEdit={true}
                            />

                            <div style={{ marginTop: '20px' }} />

                            <HelpTutorialSectionDesktop carId={carId} />

                            <SectionHeader
                                title={'Contact'}
                                removeEdit={true}
                            />

                            <ContactFormDesktop
                                contactReasonsData={contactReasonsData}
                            />
                        </div>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <MobileSettingsSectionHeader title="QUICK ACCESS" />
                        <div style={{ height: '30px' }} />
                        <HelpQuickAccessMobile />
                        <div style={{ height: '20px' }} />
                        <MobileSettingsSectionHeader
                            id="faq-mobile"
                            title="FAQ"
                        />
                        <div style={{ height: '40px' }} />
                        <ExpandingItemsListMobile list={faqDataArray} />
                        <div style={{ height: '50px' }} />
                        <MobileSettingsSectionHeader
                            id="tutorials-mobile"
                            title="Tutorials"
                        />
                        <div style={{ height: '40px' }} />
                        <HelpTutorialSectionMobile carId={carId} />
                        <div style={{ height: '50px' }} />
                        <MobileSettingsSectionHeader
                            id="contact-mobile"
                            title="Contact"
                        />
                        <div style={{ height: '20px' }} />
                        <ContactFormMobile
                            contactReasonsData={contactReasonsData}
                        />
                    </IpadAndMobileDisplay>
                </Faded>
            )}
        </CenteredPageWrapper>
    )
}

export default Help
