import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import Expander from '../../../atoms/expander/expander'
import dayjs from 'dayjs'
import 'react-datepicker/dist/react-datepicker.css'
import InputField from '../../../atoms/Inputfield/inputField'
import useThemes from '../../../../providers/theme/hooks'
import { IEditOrCreateModeSingleFieldsProps } from '../types'

const WrapperMobile = styled.section`
    @media ${device.ipad} {
        padding-left: 25px;
    }
    cursor: pointer;
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: red;
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const SmallPadding = styled.div`
    padding-top: 30px;
`

interface Values {
    single_item_edited_value_basic_field_mobile: string
}

interface OtherProps {
    data: IEditOrCreateModeSingleFieldsProps
    dataCyId?: string
    removePadding?: boolean
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,

        data,
        errors,
        setFieldValue,
        removePadding,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    const handleAnswerChange = (date: string) => {
        setFieldValue('single_item_edited_value_basic_field_mobile', date)
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                date,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, date)
        }

        // if (!e.target.value || e.target.value.length > 20) {
        //     setIsDisabled(true)
        // } else {
        //     setIsDisabled(false)
        // }
    }

    const { theme } = useThemes()

    return (
        <WrapperMobile>
            {!removePadding && <SmallPadding />}

            <InputField
                theme={theme}
                id="single_item_edited_value_basic_field_mobile"
                name="single_item_edited_value_basic_field_mobile"
                placeholder={data.title}
                customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                tabindex={1}
                aria-live="polite"
                formikprops={props}
                value={
                    values.single_item_edited_value_basic_field_mobile ??
                    undefined
                }
                font_family="Lato"
                fontSize="16px"
                paddingLeft="0px"
                width="100%"
                onChange={handleAnswerChange}
                type="date"
                dataCyId={props.dataCyId}
            />
            {/* 
            <DatePickerAntd
                onChange={handleAnswerChange}
                value={values.single_item_edited_value_basic_field_mobile}
                // disabled={p}
            /> */}

            <Expander
                height={
                    values.single_item_edited_value_basic_field_mobile.length >
                        1 && errors.single_item_edited_value_basic_field_mobile
                        ? 'auto'
                        : 0
                }
            >
                <Faded>
                    <div style={{ paddingTop: '5px' }} />
                    <Error className="animated fadeIn slower">
                        {errors.single_item_edited_value_basic_field_mobile}
                    </Error>
                </Faded>
            </Expander>
            <SmallPadding />
        </WrapperMobile>
    )
}

const DatePickerEditOrCreateModeSingleFieldMobile = withFormik<
    OtherProps,
    Values
>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_basic_field_mobile: props.data.value_start
            ? dayjs(props.data.value_start).format('YYYY-MM-DD')
            : '',
    }),
    enableReinitialize: true,

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default DatePickerEditOrCreateModeSingleFieldMobile
