import React from 'react'
import styled from 'styled-components'
import { device } from '../templates/displays/devices'
import { Link } from 'react-router-dom'
import ButtonAtom from './../atoms/Button/ButtonAtom'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30vh;

    @media ${device.desktop} {
        font-size: 1.2rem;
    }

    @media ${device.ipad} {
        font-size: 1.2rem;
    }

    @media ${device.mobile} {
        font-size: 1rem;
    }
`

const SectionDivider = styled.div`
    padding-top: 12vh;
`

const Padding = styled.div`
    @media ${device.desktop} {
        padding-top: 30px;
    }

    @media ${device.ipad} {
        padding-top: 30px;
    }

    @media ${device.mobile} {
        padding-top: 20px;
    }
`

const Title = styled.div`
    font-family: Lato-light;
    color: var(--text-strong, #1a1a1a);
    text-transform: capitalize;

    @media ${device.desktop} {
        font-size: 1.8rem;
    }

    @media ${device.ipad} {
        font-size: 1.6rem;
    }

    @media ${device.mobile} {
        font-size: 1.2rem;
    }
`

const Line = styled.div`
    height: 1px;
    width: 300px;
    background-color: var(--border-muted, #e5e5e5);

    @media ${device.desktop} {
        width: 300px;
        margin-top: 10px;
    }

    @media ${device.mobile} {
        margin-top: 4px;
        width: 200px;
    }
    @media ${device.ipad} {
        margin-top: 4px;
        width: 280px;
    }
`

const NotFoundPage: React.FC = () => {
    // const errorUser = useSelector((state: IRootState) => state.user.error)

    return (
        <Container>
            <Title>404 page not found </Title>

            <Line />
            <SectionDivider />
            <Link to="/signin">
                <ButtonAtom theme="naked-text">Sign In</ButtonAtom>
            </Link>
            <Padding />
            <Link to="/">
                <ButtonAtom theme="naked-text">Home</ButtonAtom>
            </Link>
        </Container>
    )
}

export default NotFoundPage
