import React, { useState } from 'react'
import { message } from 'antd'
import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'
import '../../antd.css'
import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import { Combobox } from '../../../../../atoms/select'
import Icon from '../../../../../atoms/icons'

type IStyleMedia = {
    showBothDevices?: boolean
}

const SelectBarContainer = styled.div<IStyleMedia>`
    /* width: 100%;
    box-sizing: border-box; */
    max-width: 600px;
    @media ${device.beyond_ipad_mobile} {
        display: flex;
    }

    @media ${device.mobile_and_ipad} {
        display: ${(props) => (props.showBothDevices ? 'flex' : 'none')};
    }
`

const EmptyBoxText = styled.div`
    color: grey;
    font-style: italic;
`

export type Props = {
    fetching: any
    value: any
    data: any
    fetchItem: any
    handleChange: any
    optionsList: any
    item?: any | undefined
    width?: string | undefined
    addAnItemFunc?: any
    dropdownTargetId?: string | undefined
    formCurrentValue?: string | undefined
    allowAdd?: boolean | undefined
    isDisabled?: boolean | undefined
    height?: string | undefined
    dataCyId?: string | undefined
    placeholder?: string | undefined
    helperText?: string
    sendId?: boolean
    isKeyValue?: boolean
    resetSearch?: any
    showBothDevices?: boolean
    iconsSize?: string
    backgroundColor?: string
    borderColor?: string
    customMaxWidth?: string
    sendDropdownItem?: boolean
    isValueLabel?: boolean
    showEmptyBox?: boolean
    field_id?: string
    textTransform?: string
    customActiveColour?: string
    customHighlightColour?: string
}

const errorWarning = (errorText: string) => {
    message.error(errorText, 2)
}

const SelectSingleDesktop: React.FC<Props> = (props) => {
    const [valueTyped, setValueTyped] = useState<string>('')

    const handleSearch = (valueTyped: string) => {
        setValueTyped(valueTyped)
    }

    const {
        value,
        data,
        handleChange,
        fetchItem,
        optionsList,
        item,
        addAnItemFunc,
        dropdownTargetId,
        formCurrentValue,
        allowAdd,
        isDisabled,
        placeholder,
        helperText,
        sendId,
        isKeyValue,
        resetSearch,
        showBothDevices,
        iconsSize,
        backgroundColor,
        customMaxWidth,
        borderColor,
        sendDropdownItem,
        isValueLabel,
        field_id,
        textTransform,
        customActiveColour,
        customHighlightColour,
    } = props

    const namesInResultsArray: string[] = []

    const options =
        data && data.length > 0
            ? data.map((di: any) => {
                  let d = di.item

                  let nameD = d.name ? d.name : d.label ? d.label : undefined

                  if (d) {
                      nameD &&
                          namesInResultsArray.push(nameD.trim().toLowerCase())

                      return {
                          value: isValueLabel
                              ? d.value
                              : sendDropdownItem
                              ? d.uid
                              : isKeyValue
                              ? d
                              : sendId
                              ? d.uid
                              : d.uid &&
                                (field_id === 'make' ||
                                    field_id === 'model' ||
                                    field_id === 'car_model' ||
                                    field_id === 'car_make')
                              ? d.uid
                              : d.name,
                          label: isValueLabel
                              ? d.label
                              : isKeyValue
                              ? d
                              : d.name,
                      }
                  } else {
                      let nameDI = di.name
                          ? di.name
                          : di.label
                          ? di.label
                          : undefined
                      nameDI &&
                          namesInResultsArray.push(nameDI.trim().toLowerCase())

                      return {
                          value: sendDropdownItem
                              ? d.uid
                              : isValueLabel
                              ? di.value
                              : isKeyValue
                              ? di
                              : sendId
                              ? di.uid
                              : di.uid &&
                                (field_id === 'make' ||
                                    field_id === 'model' ||
                                    field_id === 'car_model' ||
                                    field_id === 'car_make')
                              ? di.uid
                              : di.name,
                          label: isKeyValue
                              ? di
                              : isValueLabel
                              ? di.label
                              : di.name,
                      }
                  }
              })
            : optionsList
            ? optionsList.map((d: any) => {
                  let dname = d.name ? d.name : d.label ? d.label : undefined
                  dname && namesInResultsArray.push(dname.trim().toLowerCase())
                  return {
                      value: isKeyValue
                          ? d
                          : sendId
                          ? d.uid
                          : isValueLabel
                          ? d.value
                          : d.uid &&
                            (field_id === 'make' ||
                                field_id === 'model' ||
                                field_id === 'car_model' ||
                                field_id === 'car_make')
                          ? d.uid
                          : d.name,
                      label: isKeyValue ? d : isValueLabel ? d.label : d.name,
                      code: isValueLabel ? d.code : undefined,
                  }
              })
            : null

    const cleanUpName = (name: string) => {
        if (name.includes('_')) {
            return name.replace(/_/g, ' ')
        } else return name
    }

    return (
        <SelectBarContainer
            style={{
                width: props.width ?? '100%',
                maxWidth: customMaxWidth ?? 600,
            }}
            key={value}
            showBothDevices={showBothDevices}
        >
            <Combobox
                customActiveColour={customActiveColour}
                customHighlightColour={customHighlightColour}
                field_id={field_id}
                sendDropdownItem={sendDropdownItem}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                options={options}
                value={
                    sendId && !isKeyValue && value[value.length - 1]
                        ? cleanUpName(value[value.length - 1])
                        : value[value.length - 1]
                }
                defaultValue={item.answer ? item.answer : ''}
                helperText={helperText}
                placeholder={placeholder}
                clearValueTyped={() => {
                    setValueTyped('')
                    resetSearch && resetSearch()
                }}
                onInputKeyDown={(e: any) => {
                    if (data.length === 0) {
                        if (
                            addAnItemFunc !== undefined &&
                            dropdownTargetId !== undefined &&
                            allowAdd
                        ) {
                            e = e || window.event
                            if (e.keyCode === 13) {
                                if (formCurrentValue) {
                                    if (formCurrentValue !== valueTyped) {
                                        if (valueTyped) {
                                            let trimmed_val =
                                                valueTyped.replace(/\s/g, '')

                                            if (
                                                trimmed_val.length >= 1 &&
                                                trimmed_val.length < 70
                                            ) {
                                                handleChange(valueTyped)
                                                addAnItemFunc(
                                                    dropdownTargetId,
                                                    valueTyped
                                                )
                                            } else {
                                                if (trimmed_val.length >= 70) {
                                                    errorWarning(
                                                        'Name must be less than 70 characters'
                                                    )
                                                } else {
                                                    errorWarning(
                                                        'Please enter a valid name.'
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }}
                disabled={isDisabled ? isDisabled : false}
                fallbackContent={
                    allowAdd &&
                    valueTyped &&
                    valueTyped.length !== 0 &&
                    !value.includes(valueTyped) &&
                    !namesInResultsArray.includes(
                        valueTyped.trim().toLowerCase()
                    ) ? (
                        <div
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                padding: 12,
                            }}
                        >
                            <ButtonAtom
                                onClick={(e: any) => {
                                    if (valueTyped) {
                                        let trimmed_val = valueTyped.replace(
                                            /\s/g,
                                            ''
                                        )
                                        if (
                                            trimmed_val.length >= 1 &&
                                            trimmed_val.length < 70
                                        ) {
                                            e.preventDefault()
                                            addAnItemFunc(
                                                dropdownTargetId,
                                                valueTyped
                                            )
                                            handleChange(valueTyped)
                                        } else {
                                            if (trimmed_val.length >= 70) {
                                                errorWarning(
                                                    'Name must be less than 70 characters'
                                                )
                                            } else {
                                                errorWarning(
                                                    'Please enter a valid name.'
                                                )
                                            }
                                        }
                                    }
                                }}
                                theme="modal-action-button-primary"
                                width="100%"
                            >
                                <div
                                    style={{
                                        textTransform: 'none',
                                        fontSize: 14,
                                        fontFamily: 'Lato-Semibold',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Icon icon="add_plus_white" />
                                    <span
                                        style={{
                                            textTransform: 'capitalize',
                                            paddingLeft: 8,
                                            paddingRight: 3,
                                        }}
                                    >
                                        {`Add ${valueTyped} `}
                                    </span>
                                    to our database
                                </div>
                            </ButtonAtom>
                        </div>
                    ) : props.showEmptyBox === true &&
                      data.length === 0 &&
                      valueTyped?.length > 2 ? (
                        <EmptyBoxText>No exact match found</EmptyBoxText>
                    ) : null
                }
                onSearch={(value: string) => {
                    fetchItem(value)
                    handleSearch(value)
                }}
                onChange={handleChange}
                iconsSize={iconsSize}
                textTransform={textTransform}
            />
        </SelectBarContainer>
    )
}

export default SelectSingleDesktop
