import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import ReadOnlyCarHistoryFile from '../../../templates/readOnly/car/readOnlyHistoryFile'
import { useAppSelector, useAppDispatch } from '../../../../redux/store/hooks'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { IExternalCar } from '../../../../redux/entities/cars/types'

const ExternalCarHistoryFile: React.FC = () => {
    const { carid } = useParams<{ carid: string }>()
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (carid) {
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/shared/car/${carid}/history-file`
                )
            )
            dispatch(carActions.setCurrentExternalCarSuccess(carid))
            dispatch(carActions.getExternalCarsSharedWithYouRequest(carid))
        }
    }, [userLoggedIn, carid])

    const currentCar: IExternalCar | undefined =
        externalCarsData && carid ? externalCarsData[carid] : undefined

    return (
        <React.Fragment>
            <ReadOnlyCarHistoryFile
                userLoggedIn={userLoggedIn}
                sharedCar={currentCar}
            />
        </React.Fragment>
    )
}

export default ExternalCarHistoryFile
