import { createSlice } from '@reduxjs/toolkit'
import {
    shortlisted_features_list,
    section_one_copy_list,
    what_we_built,
} from './data'
import { IUpcomingPageState } from 'upcomingPageModels'

const initialState: IUpcomingPageState = {
    section_one_list: section_one_copy_list,
    upcoming_shortlisted_features_list: shortlisted_features_list,
    upcoming_what_weBuilt: what_we_built,
}

const upcomingSlice = createSlice({
    name: 'upcoming',
    initialState,
    reducers: {},
})

export const upcomingActions = upcomingSlice.actions
export default upcomingSlice.reducer
