import { call, fork, join, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
    IOtherDriver_DriveList,
} from './../../reducer'

import { api } from '../../../services'
import {
    entityDeletionSaga,
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import { IApplicationNamedDriversIDs_API } from '../../types'
import {
    GetInsuranceApplication,
    ValidateAndAddStatsToApplication,
    insurance_application_state_select,
} from '../application/get'
import posthog from 'posthog-js'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'

export function* Submit_otherDrivers_dashboard(): any {
    posthog.capture('Update insurance application named drivers')

    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let application_has_deletions = false
        let application_has_patch = false

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        // driver ids loop to populate the carArrDataToPatch of custodian_cars

        let driverIDS: string[] = Object.keys(all_draft.other_drivers)

        let customerNamedDriversArr: any[] = []

        for (let i = 0; i < driverIDS.length; i++) {
            let driver_id = driverIDS[i]

            let draft_driveList: IOtherDriver_DriveList[] | undefined =
                all_draft.other_drivers[driver_id].drive_list

            let existing_driveList: IOtherDriver_DriveList[] | undefined =
                all_existing.other_drivers[driver_id].drive_list

            if (draft_driveList) {
                let existingDriverCarIDKeys = existing_driveList
                    ? existing_driveList.map((item) => item.carid)
                    : []

                let singleNamedDriverObj: IAnyObject = {}
                let singleDriverCarsFrequencies: any[] = []

                let named_driver_obj_draft = all_draft.other_drivers[driver_id]

                let named_driver_obj_existing =
                    all_existing.other_drivers[driver_id]

                if (!named_driver_obj_existing) {
                    // TBC excat BE field names
                    singleNamedDriverObj = {
                        ...singleNamedDriverObj,
                        given_name:
                            named_driver_obj_draft.basic_details?.given_name,
                        family_name:
                            named_driver_obj_draft.basic_details?.family_name,
                    }
                }

                if (named_driver_obj_existing) {
                    singleNamedDriverObj = { uid: driver_id }
                }

                draft_driveList.forEach((item: IOtherDriver_DriveList) => {
                    // case driver has a car associated with them

                    // need to have array of custodian_cars

                    if (item.carid) {
                        let indexx = existingDriverCarIDKeys.indexOf(item.carid)
                        // check if new car id
                        if (indexx === -1) {
                            if (item.use_frequency?.name) {
                                let obj: IAnyObject = {
                                    external_id: item.carid,
                                    use_frequency: item.use_frequency?.uid,
                                }

                                if (
                                    all_existing.vehicles[item.carid] &&
                                    all_existing.vehicles[item.carid]
                                        .api_uid !== undefined
                                ) {
                                    obj = {
                                        ...obj,
                                        uid: `${
                                            all_existing.vehicles[item.carid]
                                                .api_uid
                                        }`,
                                    }
                                }
                                singleDriverCarsFrequencies = [
                                    ...singleDriverCarsFrequencies,
                                    {
                                        ...obj,
                                    },
                                ]
                            } else {
                                let obj: IAnyObject = {
                                    external_id: item.carid,
                                }

                                if (
                                    all_existing.vehicles[item.carid] &&
                                    all_existing.vehicles[item.carid]
                                        .api_uid !== undefined
                                ) {
                                    obj = {
                                        ...obj,
                                        uid: `${
                                            all_existing.vehicles[item.carid]
                                                .api_uid
                                        }`,
                                    }
                                }

                                singleDriverCarsFrequencies = [
                                    ...singleDriverCarsFrequencies,
                                    {
                                        ...obj,
                                    },
                                ]
                            }

                            // at the end
                        } else {
                            // check if ecisting car but new frequency answer
                            let existingFrequency =
                                existing_driveList &&
                                existing_driveList[indexx].use_frequency
                            if (
                                existingFrequency?.name !==
                                item.use_frequency?.name
                            ) {
                                let obj: IAnyObject = {
                                    external_id: item.carid,
                                    use_frequency: item.use_frequency?.uid,
                                }

                                if (
                                    all_existing.vehicles[item.carid] &&
                                    all_existing.vehicles[item.carid]
                                        .api_uid !== undefined
                                ) {
                                    obj = {
                                        ...obj,
                                        uid: `${
                                            all_existing.vehicles[item.carid]
                                                .api_uid
                                        }`,
                                    }
                                }

                                singleDriverCarsFrequencies = [
                                    ...singleDriverCarsFrequencies,
                                    {
                                        ...obj,
                                    },
                                ]
                            }
                        }
                    }
                })

                if (singleDriverCarsFrequencies.length > 0) {
                    singleNamedDriverObj.custodian_cars = [
                        ...singleDriverCarsFrequencies,
                    ]
                }
                // maybe to put this out side
                customerNamedDriversArr = [
                    ...customerNamedDriversArr,
                    { ...singleNamedDriverObj },
                ]
            }

            let carIDsToUnlink: string[] = []

            if (draft_driveList) {
                let draftIDKeys = draft_driveList
                    ? draft_driveList.map((item) => item.carid)
                    : []

                existing_driveList &&
                    existing_driveList.forEach((item) => {
                        if (draftIDKeys.indexOf(item.carid) === -1) {
                            let carAPIUID =
                                all_existing.vehicles[item.carid]?.api_uid

                            if (carAPIUID) {
                                carIDsToUnlink = [...carIDsToUnlink, carAPIUID]
                            }
                        }
                    })
            }

            if (carIDsToUnlink.length > 0) {
                let arrayOfDeletionTasks = []
                for (let i = 0; i < carIDsToUnlink.length; i++) {
                    let req_del: IDeleteInsuranceApplication_req = {
                        appli_id: `${all_existing.id}`,
                        entity_id: driver_id,
                        entity_type: 'drivers',
                        parent_vehicle_id: carIDsToUnlink[i],
                    }
                    const deletionTask = yield fork(entityDeletionSaga, {
                        ...req_del,
                    })
                    arrayOfDeletionTasks.push(deletionTask)
                }

                if (arrayOfDeletionTasks.length > 0) {
                    yield join(arrayOfDeletionTasks)
                    application_has_deletions = true
                }
            }
        }

        let selectedDriverIDSExisting =
            all_existing.selected_named_drivers ?? []

        let selectedDriverIDSDraft = all_draft.selected_named_drivers ?? []

        let dataToSendToPatch: IAnyObject = {}

        let namedDriversToAdd: IApplicationNamedDriversIDs_API[] = []

        selectedDriverIDSDraft.forEach((driverid) => {
            if (selectedDriverIDSExisting.indexOf(driverid) === -1) {
                namedDriversToAdd.push({ uid: driverid })
            }
        })

        let namedDriversToUnlink: string[] = []

        selectedDriverIDSExisting.forEach((driverid) => {
            if (selectedDriverIDSDraft.indexOf(driverid) === -1) {
                namedDriversToUnlink.push(driverid)
            }
        })

        if (namedDriversToUnlink.length > 0) {
            let arrayOfDriversDeletionTasks = []
            for (let i = 0; i < namedDriversToUnlink.length; i++) {
                let req_del: IDeleteInsuranceApplication_req = {
                    appli_id: `${all_existing.id}`,
                    entity_id: namedDriversToUnlink[i],
                    entity_type: 'deassign_named_driver',
                }

                const driverDeletionTask = yield fork(entityDeletionSaga, {
                    ...req_del,
                })
                arrayOfDriversDeletionTasks.push(driverDeletionTask)
            }
            if (arrayOfDriversDeletionTasks.length > 0) {
                yield join(arrayOfDriversDeletionTasks)
                application_has_deletions = true
            }
        }

        if (customerNamedDriversArr && customerNamedDriversArr.length > 0) {
            dataToSendToPatch = {
                ...dataToSendToPatch,
                customer: {
                    named_drivers: [...customerNamedDriversArr],
                },
            }
            if (namedDriversToAdd.length > 0) {
                dataToSendToPatch = {
                    ...dataToSendToPatch,
                    application_named_drivers: [...namedDriversToAdd],
                }
            }
        }

        // console.log('data to patch', dataToSendToPatch)

        if (Object.keys(dataToSendToPatch).length > 0) {
            // call api to patch as thats all the data merged we want to patch
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...dataToSendToPatch },
                }
            )

            application_has_patch = true

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )
            yield put(
                insuranceActions.submit_driversDashboard_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(insuranceActions.submit_driversDashboard_success())
        }

        if (
            application_has_deletions === true &&
            application_has_patch === false
        ) {
            let application: IInsuranceQuoteApplication_Reducer = yield select(
                insurance_application_state_select
            )

            if (
                application.showValidationSheet &&
                application.submitted_data?.id
            ) {
                const isWizard =
                    window.location.pathname.match(/wizard/g) !== null
                        ? true
                        : false

                yield put(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...application.submitted_data },
                        activateValidationSheet: true,
                        isWizard: isWizard,
                    })
                )
            } else {
                yield put(
                    insuranceActions.getting_insurance_info_after_change()
                )
                yield call(GetInsuranceApplication, `${all_existing.id}`)
            }
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_otherDrivers_dashboard,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/drivers`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_driversDashboard_error(customError)
            )
        }
    }
}

function* Watcher_Submit_otherdriver_assignCars() {
    while (true) {
        yield take(insuranceActions.submit_driversDashboard_request)
        yield call(Submit_otherDrivers_dashboard)
    }
}

const manage_drivers_submit: any[] = [
    fork(Watcher_Submit_otherdriver_assignCars),
]

export default manage_drivers_submit
