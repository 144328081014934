import { IDropdownItem } from 'entityModels'

export const enumToNameFunc = (input: string): string => {
    const words = input.split('_')
    const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    const transformedString = capitalizedWords.join(' ')
    return transformedString
}

export const customEnumToNameFunc = (
    input: string,
    checkAgainstArray: IDropdownItem[]
): string => {
    for (let item of checkAgainstArray) {
        if (item.uid === input) {
            return item.name
        }
    }
    return ''
}
