import {
    ITechnicalInformationObject,
    ITechInfoData,
    IHighlightedFactsObject,
    IHighlightedFact,
} from 'entityModels'
import {
    ICarsObject,
    ICar,
    IExternalCarsObject,
    IExternalCar,
} from '../../entities/cars/types'
import {
    IGalleryImage,
    IGalleryImagesObject,
} from '../../entities/galleries/types'
import { IGarage, IGarageObject } from '../../entities/garages/types'
import {
    IGaragePayload,
    ICarPayload,
    IHighlightedFactPayload,
    IPayloadCarGalleryImageFromApi,
} from '../../services/typedefinitions/apiPayloads'
import { IShowroomEntryAPI } from '../../showroom/types'

import { fromIsoStringToDDMMYYY } from '../time'

// THIS IS ONLY GARAGE AND CAR

export const ConvertToGarageState = (
    garagePayload: IGaragePayload
): IGarageObject => {
    const garage_ids_list: string[] = garagePayload.cars.map(
        (car: ICarPayload, index: number) => {
            return car.uid
        }
    )

    const garage: IGarage = {
        uid: garagePayload.uid,
        cars: garage_ids_list,
    }

    const garage_normalised: IGarageObject = {}
    garage_normalised[garage.uid] = garage

    return garage_normalised
}

export const convertToTechnicalInformationState = (
    // CONVERT

    carPayload: ICarPayload
): ITechnicalInformationObject => {
    let tech_info_object: ITechInfoData = {
        id: `hf-${carPayload.uid}`,
        make: {
            id: 'make',
            text: 'make',
            answer: carPayload.make !== undefined ? carPayload.make : null,
            current_uid: carPayload.make_id,
        },

        model: {
            id: 'model',
            text: 'model',
            answer: carPayload.model !== undefined ? carPayload.model : null,
            current_uid: carPayload.model_id,
        },
        year: {
            id: 'year',
            text: 'year',
            answer: carPayload.year ? carPayload.year : null,
        },
        body_type: {
            id: 'body_type',
            text: 'body type',
            answer: carPayload.body_type ? carPayload.body_type : null,
            custom: carPayload.custom_body_type
                ? carPayload.custom_body_type
                : null,
        },
        drive_side: {
            id: 'drive_side',
            text: 'drive side',
            answer:
                carPayload.drive_side && carPayload.drive_side === 'Right'
                    ? 'RHD'
                    : carPayload.drive_side && carPayload.drive_side === 'Left'
                    ? 'LHD'
                    : carPayload.drive_side &&
                      carPayload.drive_side === 'Center'
                    ? 'Center'
                    : null,
        },
        engine_capacity: {
            id: 'engine_capacity',
            text: 'engine size',
            answer:
                carPayload && carPayload.engine_capacity
                    ? carPayload.engine_capacity
                    : null,
            unit: {
                id: 'Kilometres',
                name: 'Kilometres',
            },
        },

        transmission: {
            id: 'transmission',
            text: 'transmission',
            answer: carPayload.transmission ? carPayload.transmission : null,
        },

        mileage: {
            id: 'mileage',
            text: 'mileage',
            answer:
                carPayload.mileage !== null && carPayload.mileage !== undefined
                    ? carPayload.mileage
                    : null,
            unit: {
                id: 'Kilometres',
                name: 'Kilometres',
            },
        },

        registration_number: {
            id: 'registration_number',
            text: 'Registration number',
            answer:
                carPayload && carPayload.registration_number !== undefined
                    ? carPayload.registration_number
                    : null,
        },
        registration_date: {
            id: 'registration_date',
            text: 'date of registration',
            answer:
                carPayload && carPayload.registration_date !== undefined
                    ? carPayload.registration_date
                    : null,
        },

        last_owner_change: {
            id: 'last_owner_change',
            text: 'Last Ownership Change',
            answer:
                carPayload && carPayload.last_owner_change !== undefined
                    ? carPayload.last_owner_change
                    : null,
        },

        service_check_status: {
            id: 'service_check_status',
            text: 'MOT status',
            answer:
                carPayload && carPayload.service_check_status !== undefined
                    ? carPayload.service_check_status
                    : null,
        },
        service_check_expiry: {
            id: 'service_check_expiry',
            text: 'MOT expiry',
            answer:
                carPayload && carPayload.service_check_expiry !== undefined
                    ? carPayload.service_check_expiry
                    : null,
        },

        tax_due: {
            id: 'tax_due',
            text: 'Road Tax Due',
            answer:
                carPayload && carPayload.tax_due !== undefined
                    ? carPayload.tax_due
                    : null,
        },

        tax_status: {
            id: 'tax_status',
            text: 'Road Tax Status',
            answer:
                carPayload && carPayload.tax_status !== undefined
                    ? carPayload.tax_status
                    : null,
        },

        color_exterior: {
            id: 'color_exterior',
            text: 'exterior colour',
            answer:
                carPayload && carPayload.color_exterior !== undefined
                    ? carPayload.color_exterior.toLowerCase()
                    : null,
        },

        color_interior: {
            id: 'color_interior',
            text: 'interior colour',
            answer:
                carPayload && carPayload.color_interior !== undefined
                    ? carPayload.color_interior.toLowerCase()
                    : null,
        },

        fuel_type: {
            id: 'fuel_type',
            text: 'fuel / power type',
            answer: carPayload.fuel_type
                ? carPayload.fuel_type.toLowerCase()
                : null,
        },

        drive_train: {
            id: 'drive_train',
            text: 'Drivetrain',

            answer:
                carPayload && carPayload.drive_train
                    ? carPayload.drive_train
                    : null,

            // unit: {
            //     id: '2WD',
            //     name: '2WD',
            // },
        },
        chassis_number: {
            id: 'chassis_number',
            text: 'chassis number',
            answer: carPayload.vin ? carPayload.vin : null,
        },
    }

    // NORMALISE
    const tech_info_normalised: ITechnicalInformationObject = {}
    tech_info_normalised[tech_info_object.id] = tech_info_object
    return tech_info_normalised
}

export const convertToHighlightedFactsState = (
    carPayload: ICarPayload
): IHighlightedFactsObject => {
    const highlighted_facts_normalised: IHighlightedFactsObject = {}

    if (carPayload.facts) {
        carPayload.facts.forEach(
            (hf: IHighlightedFactPayload, index: number) => {
                let obj: IHighlightedFact = {
                    id: hf.uid,
                    text: hf.body ? hf.body : '',
                    order: hf.order,
                }

                highlighted_facts_normalised[obj.id] = obj
            }
        )

        return highlighted_facts_normalised
    } else {
        return highlighted_facts_normalised
    }
}

export const convertToGalleryImage = (
    image: IPayloadCarGalleryImageFromApi
): IGalleryImage => {
    let image_obj: IGalleryImage = {
        id: image.uid,
        cover: image.cover,
        featured: image.featured,
        caption: image.caption ? image.caption : '',
        credits: image.credit_data ? image.credit_data.name : '',
        uploader:
            image.uploader &&
            `${image.uploader.given_name ? image.uploader.given_name : ''} ${
                image.uploader.family_name ? image.uploader.family_name : ''
            }`,
        location: image.location_data ? image.location_data.name : '',
        url: image.url,
        small_srcset: image.small_srcset && image.small_srcset,
        medium_srcset: image.medium_srcset && image.medium_srcset,
        large_srcset: image.large_srcset && image.large_srcset,
        large_src: image.large_src && image.large_src,
    }
    return image_obj
}

export const convertToGalleryImagesState = (
    carPayload: ICarPayload
): IGalleryImagesObject => {
    let gallery_images_normalised: IGalleryImagesObject = {}

    if (carPayload.gallery) {
        carPayload.gallery.forEach(
            (image: IPayloadCarGalleryImageFromApi, index: number) => {
                if (image !== undefined) {
                    let image_obj: IGalleryImage = convertToGalleryImage(image)
                    gallery_images_normalised[image_obj.id] = image_obj
                }
            }
        )
        return gallery_images_normalised
    } else return gallery_images_normalised
}

export const convertEntryShowroomGalleryToGalleryImagesState = (
    entryPayload: IShowroomEntryAPI
): IGalleryImagesObject => {
    let gallery_images_normalised: IGalleryImagesObject = {}

    if (entryPayload.gallery_images) {
        entryPayload.gallery_images.forEach(
            (image: IPayloadCarGalleryImageFromApi, index: number) => {
                if (image !== undefined) {
                    let image_obj: IGalleryImage = convertToGalleryImage(image)
                    gallery_images_normalised[image_obj.id] = image_obj
                }
            }
        )
        return gallery_images_normalised
    } else return gallery_images_normalised
}

export const convertToCarState = (carPayload: ICarPayload): ICarsObject => {
    // this is the code which will be valid once jeremy upgrades his function

    // gather gallery images into a list of ids
    let gallery_all_images_ids_list: string[] = []

    // gather gallery overview images into a list of ids
    let gallery_overview_images_ids_list: string[] = []

    // cover id image
    let gallery_cover_id: string[] = []

    if (carPayload.gallery && carPayload.gallery.length > 0) {
        carPayload.gallery.forEach(
            (image: IPayloadCarGalleryImageFromApi, index: number) => {
                // populate overview id list
                if (
                    image.featured &&
                    image.featured === true &&
                    image.cover === false
                ) {
                    gallery_overview_images_ids_list.push(image.uid)
                }

                // populate the cover item

                if (image.cover && image.cover === true) {
                    if (image.uid) {
                        gallery_cover_id[0] = image.uid
                    }
                }

                gallery_all_images_ids_list.push(image.uid)
            }
        )
    }

    // gather highlighted facts into a list of ids
    let highlighted_facts_ids_list: string[]
    if (carPayload.facts) {
        highlighted_facts_ids_list = carPayload.facts.map(
            (hf: IHighlightedFactPayload, index: number) => {
                return hf.uid
            }
        )
    } else highlighted_facts_ids_list = []

    let car: ICar = {
        id: carPayload.uid,

        owner_id:
            carPayload.owner && carPayload.owner.uid
                ? carPayload.owner.uid
                : '',
        banner_url: carPayload.cover
            ? {
                  id: carPayload.cover.uid,
                  url: carPayload.cover.large_src
                      ? carPayload.cover.large_src
                      : carPayload.cover.url,
                  small_srcset:
                      carPayload.cover.small_srcset &&
                      carPayload.cover.small_srcset,
                  medium_srcset:
                      carPayload.cover.medium_srcset &&
                      carPayload.cover.medium_srcset,
                  large_srcset:
                      carPayload.cover.large_srcset &&
                      carPayload.cover.large_srcset,
                  large_src:
                      carPayload.cover.large_src && carPayload.cover.large_src,
              }
            : undefined,
        title:
            carPayload.title && carPayload.title !== ' '
                ? carPayload.title
                : carPayload.make && carPayload.model && carPayload.year
                ? `${carPayload.year} ${carPayload.make} ${carPayload.model}`
                : '',
        short_description: carPayload.short_description
            ? carPayload.short_description
            : '',
        status: carPayload.status ? carPayload.status : 'not for sale',
        car_registration_number: carPayload.registration_number
            ? carPayload.registration_number
            : '',

        overview: {
            list: [
                'make',
                'model',
                'year',
                'engine_capacity',
                'transmission',
                'drive_side',
            ],
            data: {
                make: {
                    id: 'make',
                    text: 'make',
                    answer: carPayload.make ? carPayload.make : '-',
                    current_uid: carPayload.make_id,
                },

                model: {
                    id: 'model',
                    text: 'model',
                    answer: carPayload.model ? carPayload.model : '-',
                    current_uid: carPayload.model_id,
                },
                year: {
                    id: 'year',
                    text: 'year',
                    answer: carPayload.year ? carPayload.year : 0,
                },

                drive_side: {
                    id: 'drive_side',
                    text: 'drive side',
                    answer:
                        carPayload.drive_side &&
                        carPayload.drive_side === 'Right'
                            ? 'RHD'
                            : carPayload.drive_side &&
                              carPayload.drive_side === 'Left'
                            ? 'LHD'
                            : carPayload.drive_side &&
                              carPayload.drive_side === 'Center'
                            ? 'Center'
                            : '-',
                },

                engine_capacity: {
                    id: 'engine_capacity',
                    text: 'engine size',
                    answer: carPayload.engine_capacity
                        ? carPayload.engine_capacity
                        : '-',
                },

                transmission: {
                    id: 'transmission',
                    text: 'transmission',
                    answer: carPayload.transmission
                        ? carPayload.transmission
                        : '-',
                },
            },
        },

        highlighted_facts: highlighted_facts_ids_list,
        gallery: {
            cover: gallery_cover_id[0],
            overview: gallery_overview_images_ids_list,
            images: gallery_all_images_ids_list,
        },

        technical_information: `hf-${carPayload.uid}`,
        description: carPayload.description ? carPayload.description : '',
        tasks_count: carPayload.tasks_count ? carPayload.tasks_count : 0,
        chassis_number: carPayload.vin ? carPayload.vin : '',
        car_entries_count: carPayload.car_entries_count
            ? carPayload.car_entries_count
            : 0,
        last_entry_updated_at_date:
            carPayload.car_entry &&
            carPayload.car_entry[0] &&
            carPayload.car_entry[0].updated_at
                ? fromIsoStringToDDMMYYY(carPayload.car_entry[0].updated_at)
                : undefined,
        showroom_entry: carPayload.showroom_entry
            ? { ...carPayload.showroom_entry }
            : undefined,

        archived_note: carPayload.archived_note,
        archived_on: carPayload.archived_on
            ? fromIsoStringToDDMMYYY(carPayload.archived_on)
            : undefined,
        archived_reason: carPayload.archived_reason,
        archived_reason_custom: carPayload.archived_reason_custom,
        gallery_images_count: carPayload.gallery_images_count ?? undefined,
        handover: carPayload.handover ? { ...carPayload.handover } : undefined,
        insights: undefined,
    }

    // NORMALISE

    const carNormalised: ICarsObject = {}

    carNormalised[car.id] = car

    return carNormalised
}

export const convertToExternalCarState = (
    carPayload: ICarPayload
): IExternalCarsObject => {
    // this is the code which will be valid once jeremy upgrades his function

    // gather gallery images into a list of ids
    let gallery_all_images_ids_list: string[] = []

    // gather gallery overview images into a list of ids
    let gallery_overview_images_ids_list: string[] = []

    // cover id image
    let gallery_cover_id: string[] = []

    if (carPayload.gallery && carPayload.gallery.length > 0) {
        carPayload.gallery.forEach(
            (image: IPayloadCarGalleryImageFromApi, index: number) => {
                // populate overview id list
                if (image.featured && image.featured === true) {
                    gallery_overview_images_ids_list.push(image.uid)
                }

                // populate the cover item

                if (image.cover && image.cover === true) {
                    if (image.uid) {
                        gallery_cover_id[0] = image.uid
                    }
                }

                gallery_all_images_ids_list.push(image.uid)
            }
        )
    }

    // gather highlighted facts into a list of ids
    let highlighted_facts_ids_list: string[]
    if (carPayload.facts) {
        highlighted_facts_ids_list = carPayload.facts.map(
            (hf: IHighlightedFactPayload, index: number) => {
                return hf.uid
            }
        )
    } else highlighted_facts_ids_list = []

    let car: IExternalCar = {
        id: carPayload.uid,

        owner: carPayload.owner
            ? {
                  uid: `${carPayload.owner.uid}`,
                  display_name: carPayload.owner.display_name
                      ? carPayload.owner.display_name
                      : 'Under cover',

                  location:
                      carPayload.owner.addresses &&
                      carPayload.owner.addresses[0]
                          ? `${carPayload.owner.addresses[0].locality}, ${carPayload.owner.addresses[0].country}`
                          : 'unknown',
                  profile_picture: carPayload.owner.profile_image_url
                      ? {
                            id: `profile_pic_of${carPayload.owner.uid}`,
                            url: carPayload.owner.profile_image_url,
                        }
                      : undefined,
                  bio: carPayload.owner.bio ? carPayload.owner.bio : 'no bio',
              }
            : undefined,
        banner_url: carPayload.cover
            ? {
                  id: carPayload.cover.uid,
                  url: carPayload.cover.large_src
                      ? carPayload.cover.large_src
                      : carPayload.cover.url,
                  small_srcset:
                      carPayload.cover.small_srcset &&
                      carPayload.cover.small_srcset,
                  medium_srcset:
                      carPayload.cover.medium_srcset &&
                      carPayload.cover.medium_srcset,
                  large_srcset:
                      carPayload.cover.large_srcset &&
                      carPayload.cover.large_srcset,
                  large_src:
                      carPayload.cover.large_src && carPayload.cover.large_src,
              }
            : undefined,
        title:
            carPayload.title && carPayload.title !== ' '
                ? carPayload.title
                : carPayload.make && carPayload.model && carPayload.year
                ? `${carPayload.year} ${carPayload.make} ${carPayload.model}`
                : '',
        short_description: carPayload.short_description
            ? carPayload.short_description
            : '',
        status: carPayload.status ? carPayload.status : 'not for sale',
        car_registration_number: carPayload.registration_number
            ? carPayload.registration_number
            : '',

        overview: {
            list: [
                'year',
                'make',
                'model',
                'engine_capacity',
                'transmission',
                'drive_side',
            ],
            data: {
                year: {
                    id: 'year',
                    text: 'year',
                    answer: carPayload.year ? carPayload.year : 0,
                },

                make: {
                    id: 'make',
                    text: 'make',
                    answer: carPayload.make ? carPayload.make : '-',
                },

                model: {
                    id: 'model',
                    text: 'model',
                    answer: carPayload.model ? carPayload.model : '-',
                },

                drive_side: {
                    id: 'drive_side',
                    text: 'drive side',
                    answer:
                        carPayload.drive_side &&
                        carPayload.drive_side === 'Right'
                            ? 'RHD'
                            : carPayload.drive_side &&
                              carPayload.drive_side === 'Left'
                            ? 'LHD'
                            : carPayload.drive_side &&
                              carPayload.drive_side === 'Center'
                            ? 'Center'
                            : '-',
                },

                engine_capacity: {
                    id: 'engine_capacity',
                    text: 'engine size',
                    answer: carPayload.engine_capacity
                        ? carPayload.engine_capacity
                        : '-',
                },

                transmission: {
                    id: 'transmission',
                    text: 'transmission',
                    answer: carPayload.transmission
                        ? carPayload.transmission
                        : '-',
                },
            },
        },

        highlighted_facts: highlighted_facts_ids_list,
        gallery: {
            cover: gallery_cover_id[0],
            overview: gallery_overview_images_ids_list,
            images: gallery_all_images_ids_list,
        },

        technical_information: `hf-${carPayload.uid}`,
        description: carPayload.description ? carPayload.description : '',
        car_entries_count: carPayload.car_entries_count
            ? carPayload.car_entries_count
            : 0,
    }

    // NORMALISE

    const carNormalised: IExternalCarsObject = {}

    carNormalised[car.id] = car

    return carNormalised
}

// private_shares_owned: {
//       entity_owned_direct_shares_ids: ['001', '002', '003', '004'],
//       entity_owned_direct_shares_data: {
//           '001': {
//               entity_id: 'carid',
//               id: '001',
//               type: 'car',
//               title: 'Direct share #1',
//               active_permission_ids: ['history file'],
//               updated_at: '10/02/2005',
//               created_at: '09/01/1999',
//               share_recipients_ids_list: [
//                   'mock_owner_id1',
//                   'mock_owner_id2',
//               ],
//               total_recipients: 0,
//               share_recipients_data: {
//                   mock_owner_id1: {
//                       id: 'mock_owner_id1',
//                       given_name: 'Natalia',
//                       family_name: 'Rus',
//                       email: 'nataliarus@gmail.com',
//                       location: 'London, UK',
//                       date_added_to_share: '09/02/2007',
//                   },
//                   mock_owner_id2: {
//                       id: 'mock_owner_id2',
//                       given_name: 'Jeremy',
//                       family_name: 'Hindle',
//                       email: 'jeremyoshindle@gmail.com',
//                       location: 'London, UK',
//                       date_added_to_share: '10/02/2005',
//                   },
//               },
//               active_display: {
//                   activeSort: 'added_at',
//                   item_ids_display: ['mock_owner_id1', 'mock_owner_id2'],
//               },
//           },
//           '002': {
//               id: '002',
//               entity_id: 'carid',
//               type: 'car',
//               title: 'Direct share #2',
//               active_permission_ids: [
//                   'history file',
//                   'entry attachments',
//               ],
//               total_recipients: 0,
//               updated_at: '10/02/2005',
//               created_at: '09/01/1999',
//               share_recipients_ids_list: ['mock_owner_id2'],
//               share_recipients_data: {
//                   mock_owner_id2: {
//                       id: 'mock_owner_id2',
//                       given_name: 'Natalia',
//                       family_name: 'Rus',
//                       email: 'nataliarus@gmail.com',
//                       location: 'London, UK',
//                       date_added_to_share: '10/02/2005',
//                   },
//               },
//               active_display: {
//                   activeSort: 'added_at',
//                   item_ids_display: ['mock_owner_id2'],
//               },
//           },
//           '003': {
//               id: '003',
//               type: 'car',
//               entity_id: 'carid',
//               title: 'Direct share with longer title hello',
//               total_recipients: 0,
//               active_permission_ids: [
//                   'history file',
//                   'entry attachments',
//                   'entry costs',
//                   'entry cost attachments',
//               ],
//               share_recipients_ids_list: [
//                   'mock_owner_id1',
//                   'mock_owner_id2',
//                   'mock_owner_id3',
//                   'mock_owner_id4',
//               ],
//               share_recipients_data: {
//                   mock_owner_id1: {
//                       id: 'mock_owner_id1',
//                       given_name: 'Jeremy',
//                       family_name: 'Hindle',
//                       email: 'jeremyoshindle@gmail.com',
//                       location: 'London, UK',
//                       date_added_to_share: '10/02/2005',
//                   },
//                   mock_owner_id2: {
//                       id: 'mock_owner_id2',
//                       given_name: 'Natalia',
//                       family_name: 'Rus',
//                       email: 'nataliarus@gmail.com',
//                       location: 'London, UK',
//                       date_added_to_share: '10/02/2005',
//                   },
//                   mock_owner_id3: {
//                       id: 'mock_owner_id3',
//                       given_name: 'Charles',
//                       family_name: 'Clegg',
//                       email: 'charlesclegg@gmail.com',
//                       location: 'London, UK',
//                       date_added_to_share: '10/02/2005',
//                   },
//                   mock_owner_id4: {
//                       id: 'mock_owner_id4',
//                       given_name: 'Jeremy',
//                       family_name: 'Hindle',
//                       email: 'jeremyoshindle@gmail.com',
//                       location: 'London, UK',
//                       date_added_to_share: '10/02/2005',
//                   },
//               },
//               active_display: {
//                   activeSort: 'added_at',
//                   item_ids_display: [
//                       'mock_owner_id1',
//                       'mock_owner_id2',
//                       'mock_owner_id3',
//                       'mock_owner_id4',
//                   ],
//               },
//               // email: 'andra.ghitulescu@custodian.club',
//               updated_at: '10/02/2005',
//               created_at: '09/01/1999',
//               // user_name: 'Andra Ghitulescu',
//           },
//           '004': {
//               id: '004',
//               entity_id: 'carid',
//               type: 'car',
//               total_recipients: 0,
//               title: 'Direct share with longer title hello',
//               active_permission_ids: [
//                   'history file',
//                   'entry attachments',
//                   'entry costs',
//                   'entry cost attachments',
//               ],
//               share_recipients_ids_list: [],
//               share_recipients_data: {},
//               // email: 'andra.ghitulescu@custodian.club',
//               updated_at: '10/02/2005',
//               created_at: '09/01/1999',
//               // user_name: 'Andra Ghitulescu',
//           },
//       },
//       active_display: {
//           ...defaultActiveCarSharedItemsDisplay,
//           item_ids_display: ['001', '002', '003', '004'],
//       },
//   },
