import styled from 'styled-components'

import FullWidthMobile from '../image/fullWidthMobile'
import { Paragraph } from '../typography'
import { device } from '../../templates/displays/devices'

const Wrap = styled.section<IStyle>`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const Title = styled.div`
    font-family: Lato;
    font-size: 0.92rem;
    padding-right: 20px;
    color: #5ec3ca;

    @media ${device.ipad} {
        font-size: 1.1rem;
    }
`

const WrapPadding = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    @media ${device.ipad} {
        padding-left: 20px;
    }
`

interface Props {
    item?: any
}

interface IStyle {
    something?: boolean
}

const ImageListItemMobile = (props: Props) => (
    <Wrap>
        <FullWidthMobile img={props.item} />
        <WrapPadding>
            <Title>{props.item.title}</Title>

            <div style={{ paddingBottom: '30px', paddingTop: '5px' }}>
                <Paragraph
                    isSmaller={true}
                    align="left"
                    ipadAlign="left"
                    fontSize="13px"
                    removeIpadPadding={true}
                >
                    {props.item.short_description}
                </Paragraph>
            </div>
        </WrapPadding>
    </Wrap>
)

export default ImageListItemMobile
