import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import TimelineEditGenerateCreateFormMobile from '../timelineFormGenerators/timelineEditGenerateCreateFormMobile'
import { TimelineCreationTypeIdsEnum } from '../../../../redux/timeline/timelineEnum'
import TimelineItemReturnNav from '../../../atoms/header/timelineItemReturnNav'
import { IDropdownItem } from 'entityModels'
import DeletionConfirmationBottomSheet from '../../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import { useAppDispatch } from '../../../../redux/store/hooks'
import { ITimelineCreateForms } from '../../../../redux/editForms/types'
import {
    ITimelineCreationFormFieldsData,
    ITimelineStepsIndexedListByCreationType,
    ITimelineDataToBeSubmittedObj,
    ITimelineItem,
    ITimelineEntityType,
    ITimelineActionType,
} from '../../../../redux/timeline/types'
import { timelineActions } from '../../../../redux/timeline/reducer'

interface Props {
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => any
    close_create_form: any
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted?: ITimelineDataToBeSubmittedObj
    submitDataRequest?: () => void
    labels_searchable_list?: IDropdownItem[] | null | undefined
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    activeEntry?: ITimelineItem | null | undefined
    is_category_specific_landing?: boolean
}

const TimelineCreateManagerMobile: React.FC<Props> = ({
    timeline_creation_steps_data,
    timeline_creation_forms_current_state,
    manageTimelineCreationFormState,
    close_create_form,
    timeline_creation_steps_object_of_indexed_list,
    data_to_be_submitted,
    submitDataRequest,
    labels_searchable_list,
    entityID,
    carID,
    entityType,
    activeEntry,
    is_category_specific_landing,
}) => {
    const [showConfirmQuitCreationSheet, setShowConfirmQuitCreationSheet] =
        useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const updateItemBeingCreated = (
        fieldID: any,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => {
        dispatch(
            timelineActions.manageDataToBeSubmittedTimelineCreationRequest({
                carid: carID,
                entityType: entityType,
                action: action,
                fieldID: fieldID,
                fieldValue: fieldID.includes('date') ? value.toString() : value,
            })
        )
    }

    const goToNextStep = () => {
        let steps_list: string[] | null =
            timeline_creation_forms_current_state.active_creation &&
            // @ts-ignore
            timeline_creation_steps_object_of_indexed_list[
                timeline_creation_forms_current_state.active_creation
            ]

        let next_index: number =
            timeline_creation_forms_current_state.current_step_index + 1

        // when creating a timeline item
        if (
            steps_list &&
            timeline_creation_forms_current_state.active_creation ===
                TimelineCreationTypeIdsEnum.add_new &&
            data_to_be_submitted &&
            data_to_be_submitted.entry &&
            data_to_be_submitted.entry.categoryID
        ) {
            if (
                next_index === 1 ||
                (next_index > 0 && next_index < steps_list.length)
            ) {
                return manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: next_index,
                    active_creation:
                        data_to_be_submitted &&
                        data_to_be_submitted.entry &&
                        data_to_be_submitted.entry.categoryID,
                })
            } else {
                return close_create_form()
            }
        }

        // when creating any other item

        if (steps_list) {
            if (
                next_index > 0 &&
                next_index < steps_list.length &&
                steps_list.length > 1
            ) {
                return manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: next_index,
                    active_creation:
                        timeline_creation_forms_current_state.active_creation,
                })
            } else {
                return close_create_form()
            }
        }
    }

    const goToPreviousStep = () => {
        let previous_index: number =
            timeline_creation_forms_current_state.current_step_index - 1

        if (previous_index >= 0) {
            if (
                timeline_creation_forms_current_state.active_creation ===
                    TimelineCreationTypeIdsEnum.add_new &&
                data_to_be_submitted &&
                data_to_be_submitted.entry &&
                data_to_be_submitted.entry.categoryID
            ) {
                if (previous_index > 0) {
                    return manageTimelineCreationFormState({
                        isOpen: true,
                        current_step_index: previous_index,
                        active_creation:
                            data_to_be_submitted &&
                            data_to_be_submitted.entry &&
                            data_to_be_submitted.entry.categoryID,
                    })
                } else {
                    return manageTimelineCreationFormState({
                        isOpen: true,
                        current_step_index: previous_index,
                        active_creation: TimelineCreationTypeIdsEnum.add_new,
                    })
                }
            }

            return manageTimelineCreationFormState({
                isOpen: true,
                current_step_index: previous_index,
                active_creation:
                    timeline_creation_forms_current_state.active_creation,
            })
        } else {
            if (entityType === 'cost' && activeEntry) {
                navigate(
                    `/car/${carID}/history-file/entry?entryid=${activeEntry.id}`
                )
            } else {
                navigate(`/car/${carID}/history-file`)
            }
            return close_create_form()
        }
    }

    const current_index: number =
        timeline_creation_forms_current_state.current_step_index

    const steps_list: string[] | null =
        timeline_creation_forms_current_state.active_creation
            ? timeline_creation_steps_object_of_indexed_list[
                  timeline_creation_forms_current_state.active_creation as keyof ITimelineStepsIndexedListByCreationType
              ]
            : null

    const current_step_data =
        steps_list &&
        timeline_creation_steps_data[
            steps_list[current_index] as keyof ITimelineCreationFormFieldsData
        ]

    const showSkipButton = () => {
        if (current_step_data) {
            if (
                current_step_data.submit.entity === 'entry' &&
                data_to_be_submitted &&
                data_to_be_submitted.entry &&
                !data_to_be_submitted.entry.attachments
            ) {
                return true
            }
        }

        return false
    }

    const isHeaderContentRemoved: boolean =
        timeline_creation_forms_current_state.removeHeaderContent === true
            ? true
            : false

    const handleCategorySelection = (
        fieldID: string,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => {
        dispatch(
            timelineActions.manageDataToBeSubmittedTimelineCreationRequest({
                carid: carID,
                entityType: entityType,
                action: 'CREATE',
                fieldID: 'categoryID',
                fieldValue: value,
            })
        )

        return manageTimelineCreationFormState({
            isOpen: true,
            current_step_index: 1,
            active_creation: value,
        })
    }

    return (
        <div
            data-attr={`${
                current_step_data && current_step_data.submit.entity
            }-creation-manager`}
            style={{
                position: 'absolute',
                width: '100%',
                boxSizing: 'border-box',
                minHeight: '-webkit-fill-available',
            }}
        >
            <TimelineItemReturnNav
                chevron
                onChevronClick={() => {
                    if (is_category_specific_landing) {
                        navigate(-1)
                    } else goToPreviousStep()
                }}
                hasQuit
                customText={is_category_specific_landing ? '' : undefined}
                onQuit={() => setShowConfirmQuitCreationSheet(true)}
                dataCyIdChevron={'go-back-one-step'}
            />

            {steps_list && current_step_data && (
                <TimelineEditGenerateCreateFormMobile
                    labels_searchable_list={labels_searchable_list}
                    closeForm={close_create_form}
                    step_list={steps_list}
                    current_step_index={
                        timeline_creation_forms_current_state.current_step_index
                    }
                    isHeaderContentRemoved={isHeaderContentRemoved}
                    stepFieldsData={current_step_data.step_fields_data}
                    stepFieldIdsList={current_step_data.step_field_ids}
                    stepTitle={current_step_data.step_title}
                    stepSubtitle={current_step_data.step_subtitle}
                    stepSubtitleDetail={current_step_data.step_subtitle_detail}
                    submit={current_step_data.submit}
                    data_to_be_submitted={data_to_be_submitted}
                    onChange={updateItemBeingCreated}
                    manageSteps={goToNextStep}
                    showSkipButton={showSkipButton()}
                    submitDataRequest={() => {
                        dispatch(
                            timelineActions.manageDataToBeSubmittedTimelineCreationRequest(
                                {
                                    carid: carID,
                                    isReadyToSendToApi: true,
                                }
                            )
                        )
                    }}
                    entityID={entityID}
                    carID={carID}
                    entityType={entityType}
                    activeEntry={activeEntry}
                    onCategorySelect={handleCategorySelection}
                />
            )}
            <DeletionConfirmationBottomSheet
                isOpen={showConfirmQuitCreationSheet}
                toggle={() => setShowConfirmQuitCreationSheet(false)}
                action={() => {
                    if (entityType === 'cost' && activeEntry) {
                        navigate(
                            `/car/${carID}/history-file/entry?entryid=${activeEntry.id}`
                        )
                    } else {
                        navigate(`/car/${carID}/history-file`)
                    }
                    return close_create_form()
                }}
                actionCopy="Confirm cancellation"
                titleCopy="Are you sure?"
                detailsCopy="Cancelling now means not creating this entry and losing the data you've entered."
                redBtnDataCyId="cancel_entry_creation_mobile"
            />
        </div>
    )
}

export default TimelineCreateManagerMobile
