import { call, delay, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertUserApitoUserState } from '../../conversions/user/userConversions'
import { IRootState } from '../../store'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { logoutSaga } from './logout'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
import { showroomActions } from '../../showroom/reducer'
import { featureFlagsActions } from '../../featureFlags/reducer'
import { customNavDataActions } from '../../localdata/customNav/reducer'
import { IUser } from '../types'
import { usersActions } from '../reducer'
import { garagesActions } from '../../entities/garages/reducer'
import { timelineActions } from '../../timeline/reducer'
import { tasksActions } from '../../tasks/reducer'
import { technicalInformationActions } from '../../entities/technical_information/reducer'
import { highlightedFactsActions } from '../../entities/highlighted_facts/reducer'
import { galleriesActions } from '../../entities/galleries/reducer'
import { searchUsersActions } from '../../searchUsers/reducer'
import { attachmentActions } from '../../attachments/reducer'
import { carActions } from '../../entities/cars/reducer'
import { IUserPayload } from '../../services/user_data/types'
import { IDropdownItem } from 'entityModels'
import { IReduxError } from '../../entities/cars/types'
import { push } from 'redux-first-history'
import {
    addToUserMailingGroupAPI,
    getUserMailingGroupsAPI,
    removeFromUserMailingGroupAPI,
} from '../../services/user_data/mailingGroup'

export const state_select_user = (state: IRootState) => state.user.userLoggedIn
export const time_zones_dropdowns_state = (state: IRootState) =>
    state.localdata.dropdownData.timeZones

export function* selectStateUserCurrency(): any {
    let state_select_user_currency: IUser = yield select(state_select_user)

    let curr =
        state_select_user_currency &&
        state_select_user_currency.preferences.data['units'].data[
            'default_currency'
        ].user_choice_id
            ? state_select_user_currency.preferences.data['units'].data[
                  'default_currency'
              ].user_choice_id
            : undefined

    if (curr === undefined) {
        yield call(getCurrentUserDataSaga)
        return curr
    } else return curr
}

export function* selectStateUserDistanceUnit(): any {
    let state_select_user_distance_unit: IUser = yield select(state_select_user)

    let user_distance_unit =
        state_select_user_distance_unit &&
        state_select_user_distance_unit.preferences.data['units'].data[
            'distance_unit'
        ].user_choice_id
            ? state_select_user_distance_unit.preferences.data['units'].data[
                  'distance_unit'
              ].user_choice_id
            : undefined

    if (user_distance_unit === undefined) {
        yield call(getCurrentUserDataSaga)
        return user_distance_unit
    } else return user_distance_unit
}

export function* getUserMailingGroups(): any {
    try {
        let user: IUser = yield select(state_select_user)
        if (user) {
            const mailingGroups: string[] = yield call(
                getUserMailingGroupsAPI,
                user.id
            )

            yield put(usersActions.getUserMailingGroupsSuccess(mailingGroups))
        } else {
            throw new Error(
                'User not found when trying to call the mailing groups API'
            )
        }
    } catch (error: any) {
        let err = ConvertToReduxError(error)
        yield put(usersActions.getUserMailingGroupsError(err))
    }
}

export function* updateUserMailingGroup(id: string): any {
    try {
        let user: IUser = yield select(state_select_user)

        if (user) {
            if (user.mailingGroups && user.mailingGroups.includes(id)) {
                yield call(removeFromUserMailingGroupAPI, user.id, id)
            } else {
                yield call(addToUserMailingGroupAPI, user.id, id)
            }

            yield call(delay, 150)
            const updatedMailingGroups: string[] = yield call(
                getUserMailingGroupsAPI,
                user.id
            )

            yield put(
                usersActions.updateUserMailingGroupsSuccess(
                    updatedMailingGroups
                )
            )
        } else {
            throw new Error(
                'User not found when trying to call the mailing groups update API'
            )
        }
    } catch (error: any) {
        let err = ConvertToReduxError(error)
        yield put(usersActions.updateUserMailingGroupsError(err))
    }
}

export function* getCurrentUserDataSaga(context?: 'LOGIN' | 'REGISTER'): any {
    try {
        const userData: IUserPayload = yield call(
            api.userData.user_data.getCurrentUser
        )

        let time_zone_dropdown: IDropdownItem[] | null = yield select(
            time_zones_dropdowns_state
        )
        let data: IUser = ConvertUserApitoUserState(
            userData,
            time_zone_dropdown
        )

        if (data.owns_garage?.uid) {
            window?.localStorage?.setItem('garage_id', data.owns_garage?.uid)
        }

        yield put(usersActions.getCurrentUserDataSuccess(data))

        // Set email or any other data
        if (posthog.people !== undefined && data) {
            posthog.people.set_once({
                email: data.email && data.email.toLowerCase(),
                given_name: data && data.given_name && data.given_name,
                family_name: data && data.family_name && data.family_name,
            })
        }
    } catch (error: any) {
        if (error?.status === 503 || !error?.status) {
            let typedError: IReduxError = !error?.status
                ? ConvertToReduxError({
                      status: 503,
                  })
                : ConvertToReduxError(error)
            yield put(usersActions.getCurrentUserDataError(typedError))
            return
        } else {
            let refresh_token: string | null =
                typeof localStorage !== 'undefined' && localStorage !== null
                    ? localStorage.getItem('refresh_token')
                    : null

            let isResetPassPage =
                window.location.pathname.match(/reset-password/g) !== null ||
                window.location.pathname.match(/reset-password/g) !== null
                    ? true
                    : false

            let hasRegisterRedirect =
                window.location.search.match(/register/g) !== null ||
                window.location.search.match(/register/g) !== null
                    ? true
                    : false

            if (!isResetPassPage) {
                if (!refresh_token && hasRegisterRedirect) {
                    // if a user without a refresh token lands on /insurance?register=true from ads or website
                    yield put(push('/register'))
                } else if (error.status === 401) {
                    const refresh_token = localStorage.getItem('refresh_token')
                    if (refresh_token) {
                        let payload: IUnauthHandlerPayload = {
                            functionToRepeat: getCurrentUserDataSaga,
                        }
                        yield call(unauthHandlerSaga, payload)
                    } else {
                        // No refresh token, just return the error
                        let typedError: IReduxError = ConvertToReduxError({
                            status: error.status,
                            statusText: error.statusText,
                            message: error.message || 'Authentication failed',
                        })
                        yield put(
                            usersActions.getCurrentUserDataError(typedError)
                        )
                    }
                } else if (error.status === 404 && refresh_token) {
                    // this is an edge case for when browser has in storage jwt and refresh token but they are not valid anymore because user doesn't exist

                    // examples:
                    // when we restart our dbs or
                    // when a user deletes his account using his phone but then tries to access the platform using a desktop where his browser storage is still in place

                    // in this case we can't call logout endpoint (so run the else case below)
                    // because it will error => we call logout with refresh token which is invalid
                    // so we cleanup all redux local state and local storage and redirect to sign in

                    // clean up Redux of all cars and garages data
                    yield put(carActions.cleanUpCarDataOnLogoutSuccess())
                    yield put(
                        garagesActions.cleanUpGaragesDataOnLogoutSuccess()
                    )
                    yield put(
                        timelineActions.cleanUpTimelineDataOnLogoutSuccess()
                    )
                    yield put(tasksActions.cleanUpTasksSuccess())
                    yield put(
                        technicalInformationActions.cleanUpTechnicalInformationSuccess()
                    )
                    yield put(
                        highlightedFactsActions.cleanUpHighlightedFactsSuccess()
                    )
                    yield put(galleriesActions.cleanUpGallerySuccess())
                    yield put(searchUsersActions.cleanUpSearchUsersSuccess())
                    yield put(attachmentActions.cleanUpAttachmentsSuccess())
                    yield put(showroomActions.cleanUpShowroom())
                    yield put(featureFlagsActions.cleanupFeatureFlags())
                    yield put(usersActions.resetRefreshTokenRetriesCount())
                    yield put(customNavDataActions.setInitialLandingUrl(null))

                    // clean up local storage
                    localStorage?.removeItem('refresh_token')

                    // redirect to signin

                    yield put(push('/signin'))
                } else {
                    let typedError: IReduxError | null =
                        context === 'LOGIN' || context === 'REGISTER'
                            ? null
                            : ConvertToReduxError(error)

                    yield put(usersActions.getCurrentUserDataError(typedError))

                    yield put(usersActions.resetErrorStateSuccess)

                    if (
                        window.location.pathname !== '/' &&
                        window.location.pathname !== '/register' &&
                        window.location.pathname !== '/login' &&
                        window.location.pathname !== '/signin' &&
                        window.location.pathname !==
                            '/share-invalid-access-public' &&
                        !isResetPassPage
                    ) {
                        yield call(logoutSaga)
                        yield put(push(`/signin`))
                    }
                }
            } else {
                yield put(usersActions.getCurrentUserDataError(null))
            }
        }
    }
}

function* watcherGetUserData() {
    while (true) {
        const { payload } = yield take(usersActions.getCurrentUserDataRequest)
        yield call(getCurrentUserDataSaga, payload)
    }
}

function* watcherGetUserMailingGroups() {
    while (true) {
        yield take(usersActions.getUserMailingGroupsRequest)
        yield call(getUserMailingGroups)
    }
}

function* watcherUpdateUserMailingGroups() {
    while (true) {
        const { payload } = yield take(
            usersActions.updateUserMailingGroupsRequest
        )
        yield call(updateUserMailingGroup, payload)
    }
}
const get_user_data: any[] = [
    fork(watcherGetUserData),
    fork(watcherGetUserMailingGroups),
    fork(watcherUpdateUserMailingGroups),
]

export default get_user_data
