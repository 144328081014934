import * as React from 'react'
import styled from 'styled-components'
import FormStepProgress from '../../atoms/FormStepProgress/FormStepProgress'
import { device } from '../displays/devices'
import useWindowSize from '../displays/windowSizeHook'
import LandingPageMainButton from '../../atoms/Button/landingPageMainButton'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import posthog from 'posthog-js'
import { useNavigate } from 'react-router-dom'
import { isIOS } from 'react-device-detect'
import step_0_img from '../../../public/assets/onboarding/step_0_mobile.png'
import step_0_img_dark from '../../../public/assets/onboarding/step_0_mobile_flipped.png'
import useThemes from '../../../providers/theme/hooks'

type Props = {
    step: number
    children?: React.ReactNode
    title?: string
    centeredTitle?: boolean
    submit: () => void
    submitDisabled?: boolean
    skip?: () => void
    skipCopy?: string
    goToWebsite?: boolean
    customSubmitText?: string
    isBtnLoading?: boolean
    isStepsBarRemoved?: boolean
    isLast?: boolean
}

export const OnboardingFormContainerMobile = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100dvh;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const OnboardingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-right: 24px;
    padding-left: 24px;
`

const FormTitle = styled.div`
    padding-top: 24px;
    align-self: start;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
`

const OnboardingTitle = styled.h1`
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--primary, #5ec3ca);
    font-weight: 400;
    font-family: Lato;
    font-size: 28px;
`

export const OnboardingText = styled.p`
    margin: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
`

export const PaddingUnit = styled.div<{ $times?: number }>`
    padding-top: ${(props) => (props.$times ? `${props.$times * 8}px` : '8px')};
`

const BottomBtns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 32px;
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;

    @media ${device.ipad} {
        padding-bottom: 40px;
    }
`

const Skip = styled.button`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    padding: 0px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.1s;
    :focus {
        outline: 0;
        background-color: rgba(94, 195, 202, 0.114);
    }
`

const OnboardingFormLayoutMobile: React.FunctionComponent<Props> = (
    props: Props
) => {
    let {
        step,
        children,
        title,
        centeredTitle,
        submit,
        skip,
        submitDisabled,
        goToWebsite,
        customSubmitText,
        isBtnLoading,
        isStepsBarRemoved,
        skipCopy,
        isLast,
    } = props
    let windowHeight = useWindowSize().height
    let windowWidth = useWindowSize().width

    let navigate = useNavigate()

    const { theme } = useThemes()

    return (
        <OnboardingFormContainerMobile
            style={{
                height: windowHeight,
            }}
        >
            {isStepsBarRemoved !== true && step < 4 && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        boxSizing: 'border-box',
                        paddingTop:
                            windowHeight && windowHeight > 700 ? 32 : 16,
                        paddingBottom:
                            windowHeight && windowHeight > 700 ? 32 : 24,
                        paddingLeft: 24,
                        paddingRight: 24,
                        zIndex: 1,
                    }}
                >
                    <FormStepProgress
                        totalSteps={3}
                        currentStep={step}
                        screen_name="mobile"
                        passiveColour={
                            step === 0
                                ? theme === 'dark'
                                    ? 'rgba(26, 26, 26, 0.16)'
                                    : 'rgba(255, 255, 255, 0.16)'
                                : undefined
                        }
                    />
                </div>
            )}

            <OnboardingWrapper
                style={{
                    background:
                        step === 0 && theme === 'dark'
                            ? 'linear-gradient(#0E0E0E, rgba(14, 14, 14, 0))'
                            : undefined,
                    backgroundSize: `cover`,
                }}
            >
                {step === 0 ? null : (
                    <PaddingUnit
                        $times={
                            isStepsBarRemoved
                                ? 0
                                : step === 4
                                ? windowHeight && windowHeight > 900
                                    ? 6
                                    : 3
                                : windowHeight && windowHeight > 700
                                ? 7
                                : 5
                        }
                    />
                )}

                {step === 0 ? (
                    <div
                        style={{
                            width: '100dvw',
                            transform: 'translateX(-24px)',
                            boxSizing: 'border-box',
                            background: `url(${
                                theme === 'dark' ? step_0_img_dark : step_0_img
                            }) center center / cover`,
                            height: windowWidth
                                ? windowWidth > 680
                                    ? 500
                                    : windowWidth > 550
                                    ? 380
                                    : 300
                                : 300,
                        }}
                    />
                ) : null}
                <FormTitle
                    style={
                        step === 0
                            ? {
                                  paddingTop: 0,
                                  marginTop:
                                      windowWidth && windowWidth > 680
                                          ? -48
                                          : -32,
                                  zIndex: 1,
                              }
                            : {}
                    }
                >
                    <OnboardingTitle
                        style={centeredTitle ? { textAlign: 'center' } : {}}
                    >
                        {title}
                    </OnboardingTitle>
                </FormTitle>
                {children}
            </OnboardingWrapper>

            <div style={{ display: 'flex', flexGrow: 1, minHeight: 8 }} />

            <BottomBtns>
                <LandingPageMainButton
                    type="submit"
                    disabled={submitDisabled ? true : false}
                    disableWhileLoading={isBtnLoading}
                    aria-live="polite"
                    dataCyId="onboarding-step-2-next-button-mobile"
                    onClick={submit}
                    normal_height="48px"
                >
                    <span
                        style={{
                            textTransform: 'none',
                            fontFamily: 'Lato',
                            fontSize: 16,
                            letterSpacing: 0,
                        }}
                    >
                        {isBtnLoading ? (
                            <LoaderElasticThreeDots />
                        ) : (
                            customSubmitText ?? 'Continue'
                        )}
                    </span>
                </LandingPageMainButton>

                <PaddingUnit $times={2} />
                {skip && (
                    <Skip
                        onClick={isBtnLoading ? () => {} : skip}
                        data-attr="skip-step-button-mobile"
                        style={isBtnLoading ? { color: '#b3b3b3' } : {}}
                        disabled={isBtnLoading ? true : false}
                    >
                        {skipCopy ?? 'Skip'}
                    </Skip>
                )}
                {goToWebsite && (
                    <ButtonAtom
                        fontSize={15}
                        height="48px"
                        width={'100%'}
                        theme="capitalize-white-background"
                        onClick={() => {
                            posthog.capture(
                                'garage clicked from onboarding CTA'
                            )
                            isIOS
                                ? navigate(`/garage?native_app_prompt=true`)
                                : navigate(`/garage?tutorial=true`)
                        }}
                    >
                        Go to your garage
                    </ButtonAtom>
                )}

                {isLast && (
                    <>
                        <PaddingUnit $times={2} />
                        <ButtonAtom
                            fontSize={15}
                            height="48px"
                            width={'100%'}
                            theme="capitalize-white-background"
                            onClick={() => {
                                posthog.capture(
                                    'insurance clicked from onboarding CTA'
                                )
                                navigate('/insurance')
                            }}
                        >
                            Go to insurance dashboard
                        </ButtonAtom>
                    </>
                )}
            </BottomBtns>
        </OnboardingFormContainerMobile>
    )
}

export default OnboardingFormLayoutMobile
