import * as React from 'react'
import styled from 'styled-components'
import { renderCategoryIcon } from '../../icons/components/historyFile/categories'
import { history_file_colours } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import {
    ITimelineCategoriesObj,
    ITimelineEntryTypes,
} from '../../../../redux/timeline/types'

interface IStyled {
    category?: string
    height: string
    bgcolor?: string
}

const Container = styled.div<IStyled>`
    background-color: ${(props) =>
        props.bgcolor ? props.bgcolor : 'var(--primary, #5ec3ca)'};
    color: var(--bg-color, #fff);
    height: ${(props) => (props.height ? props.height : '24px')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-radius: 100px;
`

const Text = styled.div`
    font-family: 'Lato-bold';
    font-size: 15px;
    letter-spacing: 0.7px;
    text-transform: lowercase;
    padding-left: 8px;
`

type Props = {
    children?: React.ReactNode
    height: string
    dataCyId?: string
    id?: ITimelineEntryTypes | undefined
    bgcolor?: string
    isActive?: boolean
    icon?: string
    entryTypes_data?: ITimelineCategoriesObj
}

const CustomIconTagQuickFilterItemDesktop: React.FC<Props> = (props) => {
    const { children, dataCyId, id, height, bgcolor } = props

    const { theme } = useThemes()

    return (
        <Container
            height={height}
            data-attr={dataCyId && dataCyId}
            category={id}
            bgcolor={
                id
                    ? history_file_colours[theme].categories[
                          id as ITimelineEntryTypes
                      ].primary_100
                    : bgcolor
            }
        >
            {renderCategoryIcon(id as ITimelineEntryTypes)}
            <Text>{children}</Text>
        </Container>
    )
}

export default CustomIconTagQuickFilterItemDesktop
