import * as React from 'react'
import styled from 'styled-components'
import QrCodeSVG from '../../atoms/qrCodeSvg/QrCodeSvg'
import grey_cross from '../../../public/assets/icons/close_cross_grey.svg'
import QrCodeStickerThemeBtn from '../../atoms/Button/qrCodeStickerThemeBtn'
import { useReducer, useRef, useState } from 'react'
import QrCodeActionBtnWrapper from '../../atoms/Button/qrCodeActionBtnWrapper'
import { ChromePicker } from 'react-color'
import {
    IStickerTheme,
    changeStickerThemeReducer,
    ChangeStickerTheme,
    generateCustomCodeFile,
} from './fns'
import { useOnClickOutside } from '../../templates/clickOutside'
import posthog from 'posthog-js'

type StyledProps = {
    $height?: string
    $width?: string
    $iconSize?: string
}

const OverWrapper = styled.div<StyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    border: none;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1000px;
    padding-bottom: 80px;
    height: auto;
    transition: all 200ms;
`

const Close = styled.div<StyledProps>`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`
const TopHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    height: 70px;
    padding-top: 10px;
`

const HeaderTitle = styled.div<StyledProps>`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 18px;
`
const HorizontalLine = styled.div<StyledProps>`
    width: 100%;
    box-sizing: border-box;
    background-color: var(--border_muted_subtle);
    height: 1px;
`

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-top: 25px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    @media (min-width: 1201px) and (max-width: 1300px) {
        padding-left: 0px;
    }
`

const SVGStickerWrapper = styled.div`
    width: 260px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.05);
    @media (min-width: 881px) and (max-width: 1024px) {
        width: 230px;
        height: 230px;
    }
`

type ISvgColours = {
    $colour: string
}
const SVGColouredCircle = styled.div<ISvgColours>`
    width: 240px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => (props.$colour ? props.$colour : '#ffe5fb')};
    @media (min-width: 881px) and (max-width: 1024px) {
        width: 210px;
        height: 210px;
    }
`
const SVGBackgroundWrapper = styled.div`
    width: 205px;
    height: 205px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    @media (min-width: 881px) and (max-width: 1024px) {
        width: 180px;
        height: 180px;
    }
`
const SVGBackground = styled.div<ISvgColours>`
    width: 190px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => (props.$colour ? props.$colour : '#fcf8ec')};
    @media (min-width: 881px) and (max-width: 1024px) {
        width: 165px;
        height: 165px;
    }
`

const LeftSide = styled.div<StyledProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
    @media (min-width: 881px) and (max-width: 1024px) {
        padding-right: 30px;
    }
`

const RightSide = styled.div<StyledProps>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const Title = styled.div<StyledProps>`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 18px;
`

const Details = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--off-bg-color, #fafafa);
    display: flex;
    padding: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
`
const VerticalLine = styled.div`
    width: 1px;
    background-color: var(--border-muted, #e5e5e5);
    height: 20px;
`

const CarTitle = styled.div<StyledProps>`
    padding-left: 10px;
    color: var(--text-darker, #616161);
    font-family: Lato-bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const ShareSource = styled.div<StyledProps>`
    color: var(--text-muted);
    text-transform: uppercase;
    padding-right: 10px;
`

const BtnWrapper = styled.div<StyledProps>`
    display: grid;
    grid-template-columns: 180px 180px 180px;
    grid-gap: 10px;
    @media (max-width: 1300px) {
        grid-template-columns: 180px 180px;
    }
`

const SwatchesBtnWrapper = styled.div<StyledProps>`
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 220px 230px;

    @media (min-width: 881px) and (max-width: 1200px) {
        grid-template-columns: 170px 200px;
        grid-gap: 10px;
    }
`
// PickerWrapper;

const PickerWrapper = styled.div`
    position: absolute;
    top: 70px;
    z-index: 1;

    @media (max-height: 800px) {
        top: -40px;
    }
`

const PaddingTop20 = styled.div<StyledProps>`
    padding-top: 20px;
`
const PaddingTop30 = styled.div<StyledProps>`
    padding-top: 30px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const Line = styled.div`
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--border-muted, #e5e5e5);
`

type Props = {
    qr_code_link: string
    qr_code_id: string
    car_title: string
    isGarage?: boolean
    closeModal: any
    predefinedStickerThemes: IStickerTheme[]
}

const QrCodeCustomiseStickerModalDesktop: React.FunctionComponent<Props> = (
    props
) => {
    let {
        qr_code_id,
        qr_code_link,
        car_title,
        isGarage,
        closeModal,
        predefinedStickerThemes,
    } = props

    const [state, dispatch] = useReducer(changeStickerThemeReducer, {
        colours: {
            colour_one: predefinedStickerThemes[0].colour_one,
            colour_two: predefinedStickerThemes[0].colour_two,
        },
        openColourPicker: undefined,
    })

    const [count, setCount] = useState<number>(0)

    const [activeThemeIndex, setActiveThemeIndex] = useState<number | null>(0)

    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => {
        dispatch({
            type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
            payload: {
                ...state,
                openColourPicker: undefined,
            },
        })
    })

    let svg_id: string = `preview_${qr_code_id}_custom_desktop_${count}`

    return (
        <OverWrapper>
            <TopHeader>
                <HeaderTitle>Custom QR Code Sticker</HeaderTitle>
                <Close onClick={closeModal}>
                    <img
                        src={grey_cross}
                        width="15px"
                        height="15px"
                        alt="close"
                    />
                </Close>
            </TopHeader>
            <HorizontalLine />
            <Wrapper>
                <LeftSide>
                    <SVGStickerWrapper>
                        <SVGColouredCircle $colour={state.colours.colour_one}>
                            <SVGBackgroundWrapper>
                                <SVGBackground
                                    $colour={state.colours.colour_two}
                                >
                                    <QrCodeSVG
                                        bgColor="transparent"
                                        link={qr_code_link}
                                        size={130}
                                        withLogo
                                        id={svg_id}
                                        logoSize={32}
                                    />
                                </SVGBackground>
                            </SVGBackgroundWrapper>
                        </SVGColouredCircle>
                    </SVGStickerWrapper>
                </LeftSide>
                <RightSide>
                    <Title>Themes</Title>
                    <PaddingTop20 />
                    <BtnWrapper>
                        {predefinedStickerThemes &&
                            predefinedStickerThemes.map((theme, index) => (
                                <React.Fragment
                                    key={`qr_${theme}_${index}_desktop`}
                                >
                                    <QrCodeStickerThemeBtn
                                        key={index}
                                        colourOne={theme.colour_one}
                                        colourTwo={theme.colour_two}
                                        txt={
                                            theme.name ? theme.name : 'Untitled'
                                        }
                                        height="60px"
                                        width="100%"
                                        index={index}
                                        isActiveBtn={
                                            activeThemeIndex === index
                                                ? true
                                                : false
                                        }
                                        onClick={() => {
                                            setActiveThemeIndex(index)

                                            dispatch({
                                                type: ChangeStickerTheme.CHANGE_BOTH_COLOURS,
                                                payload: {
                                                    colours: {
                                                        colour_one:
                                                            theme.colour_one,
                                                        colour_two:
                                                            theme.colour_two,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                </React.Fragment>
                            ))}
                    </BtnWrapper>
                    <PaddingTop30 />
                    <Title>Current Colours</Title>
                    <div style={{ paddingTop: 5 }} />
                    {activeThemeIndex === null && (
                        <div
                            style={{
                                fontSize: 13,
                                color: 'var(--text-default, #666666)',
                                fontFamily: 'Lato',
                                fontStyle: 'italic',
                            }}
                        >
                            Note: Please avoid dark colours for the background
                            as it will make the QR code unscannable.
                        </div>
                    )}
                    <PaddingTop20 />
                    <SwatchesBtnWrapper>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <QrCodeStickerThemeBtn
                                colourOne={state.colours.colour_one}
                                txt={'Outer Ring Colour'}
                                height="60px"
                                isNotTheme={true}
                                hasEditIcon
                                width="100%"
                                onClick={() =>
                                    dispatch({
                                        type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
                                        payload: {
                                            ...state,
                                            openColourPicker: 'one',
                                        },
                                    })
                                }
                            />
                            {state.openColourPicker === 'one' && (
                                <PickerWrapper ref={ref}>
                                    <ChromePicker
                                        disableAlpha
                                        color={
                                            state.openColourPicker === 'one'
                                                ? state.colours.colour_one
                                                : undefined
                                        }
                                        onChange={(colour: any) => {
                                            setActiveThemeIndex(null)
                                            dispatch({
                                                type: ChangeStickerTheme.CHANGE_COLOUR_ONE,
                                                payload: {
                                                    colours: {
                                                        ...state.colours,
                                                        colour_one: colour.hex,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                </PickerWrapper>
                            )}
                        </div>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <QrCodeStickerThemeBtn
                                colourOne={state.colours.colour_two}
                                txt={'background colour'}
                                height="60px"
                                width="100%"
                                isNotTheme={true}
                                hasEditIcon
                                onClick={() => {
                                    dispatch({
                                        type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
                                        payload: {
                                            ...state,
                                            openColourPicker: 'two',
                                        },
                                    })
                                }}
                            />
                            {state.openColourPicker === 'two' && (
                                <PickerWrapper ref={ref}>
                                    <ChromePicker
                                        disableAlpha
                                        color={
                                            state.openColourPicker === 'two'
                                                ? state.colours.colour_two
                                                : undefined
                                        }
                                        onChange={(colour: any) => {
                                            setActiveThemeIndex(null)
                                            dispatch({
                                                type: ChangeStickerTheme.CHANGE_COLOUR_TWO,
                                                payload: {
                                                    colours: {
                                                        ...state.colours,
                                                        colour_two: colour.hex,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                </PickerWrapper>
                            )}
                        </div>
                    </SwatchesBtnWrapper>
                    <PaddingTop20 />
                    <Line />

                    <PaddingTop30 />
                    <Row>
                        <QrCodeActionBtnWrapper
                            onClick={() => {
                                generateCustomCodeFile({
                                    colour_background: state.colours.colour_two,
                                    colour_circle: state.colours.colour_one,
                                    svg_qr_code_id: svg_id,
                                    title: car_title,
                                    format: 'PDF',
                                    device: 'desktop',
                                })
                                setCount(count + 1)

                                // capture custom qr_code PDF doc generation/download
                                posthog.capture(
                                    'custom_qr_code_pdf_doc_download_btn clicked'
                                )
                                return
                            }}
                            variant={'pdf'}
                            height="50px"
                            width="150px"
                            dataCyId="download_customised_qr_code_pdf_desktop"
                        >
                            A4 PDF
                        </QrCodeActionBtnWrapper>

                        <div style={{ paddingRight: '20px' }} />

                        <QrCodeActionBtnWrapper
                            onClick={() => {
                                generateCustomCodeFile({
                                    colour_background: state.colours.colour_two,
                                    colour_circle: state.colours.colour_one,
                                    svg_qr_code_id: svg_id,
                                    title: car_title,
                                    format: 'PNG',
                                    device: 'desktop',
                                })
                                setCount(count + 1)

                                // capture custom qr_code PNG image generation/download
                                posthog.capture(
                                    'custom_qr_code_png_img_download_btn clicked'
                                )
                                return
                            }}
                            variant={'image'}
                            height="50px"
                            width="150px"
                            dataCyId="download_customised_qr_code_png_desktop"
                        >
                            Image
                        </QrCodeActionBtnWrapper>
                    </Row>
                </RightSide>
            </Wrapper>
            <Details>
                {isGarage ? (
                    <>
                        <ShareSource>Garage</ShareSource>
                        <VerticalLine />
                    </>
                ) : (
                    <>
                        <ShareSource>Car</ShareSource>
                        <VerticalLine />
                    </>
                )}
                <CarTitle>{car_title}</CarTitle>
            </Details>
        </OverWrapper>
    )
}

export default QrCodeCustomiseStickerModalDesktop
