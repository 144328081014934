import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../../redux/store/hooks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import ReadOnlyCar from '../../../templates/readOnly/car/readOnlyCar'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { IExternalCar } from '../../../../redux/entities/cars/types'

const ExternalCarOverview: React.FC = () => {
    const dispatch = useAppDispatch()
    const { carid } = useParams<{ carid: string }>()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (carid) {
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/shared/car/${carid}`
                )
            )
            dispatch(carActions.setCurrentExternalCarSuccess(carid))
            dispatch(carActions.getExternalCarsSharedWithYouRequest(carid))
        }
    }, [userLoggedIn, carid])

    const current: IExternalCar | undefined =
        externalCarsData && carid ? externalCarsData[carid] : undefined

    return (
        <React.Fragment>
            {current && userLoggedIn && (
                <ReadOnlyCar sharedCar={current} userLoggedIn={userLoggedIn} />
            )}
        </React.Fragment>
    )
}

export default ExternalCarOverview
