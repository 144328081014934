import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`

const Text = styled.h1<IStyle>`
    font-family: ${(props) => (props.isbold ? 'Lato-bold' : 'Lato')};
    font-size: ${(props) => (props.fontsize ? props.fontsize : '18px')};
    text-transform: capitalize;
    padding-right: 10px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    @media ${device.smallest_laptops} {
        font-size: 22px;
    }

    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 200;
`

type IStyle = {
    fontsize?: string
    isbold?: boolean
}

interface Props {
    children?: React.ReactNode
    icon?: string
    fontsize?: string
    isbold?: boolean
    onClick?: any
    id?: string
    // setFileUploaded: any
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    noActionIcon?: boolean
    uploadChildren?: React.ReactNode
}

const SectionHeaderAttachment: React.FunctionComponent<Props> = (props) => {
    return (
        <Wrapper id={props.id}>
            <Text fontsize={props.fontsize} isbold={props.isbold}>
                {props.children}
            </Text>
        </Wrapper>
    )
}

export default SectionHeaderAttachment
