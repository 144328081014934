import { IApexInterviewOverviewItemState } from 'apexModels'
import React from 'react'

import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'
import read_apex_interview from '../../../../../../public/assets/icons/read_apex_interview.svg'
import listen_apex_interview from '../../../../../../public/assets/icons/listen_apex_interview.svg'
import { Link } from 'react-router-dom'

const WrapperRow = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        padding: 38px;
        transition: all 200ms;
    }
    cursor: pointer;
    @media ${device.ipad} {
        align-items: center;
        justify-content: center;
    }
`

const ImgStyle = styled.div`
    display: flex;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    height: 250px;
    width: 176px;

    @media ${device.smallest_laptops} {
        height: 232px;
        width: 142px;
    }

    @media ${device.large_desktop} {
        height: 280px;
        width: 198px;
    }
    @media ${device.ipad} {
        height: 272px;
        width: 170px;
    }
`
const ContentCol = styled.div`
    padding-left: 50px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 404px;
    @media ${device.smallest_laptops} {
        max-width: 365px;
        padding-left: 40px;
        font-size: 16px;
    }

    @media ${device.large_desktop} {
        max-width: 516px;
        padding-left: 56px;
    }
`

const Title = styled(Link)`
    font-family: Lato;

    align-self: flex-start !important;

    padding-bottom: 15px;

    font-size: 17px;

    @media ${device.smallest_laptops} {
        font-size: 16px;
    }

    @media ${device.ipad} {
        font-size: 18px;
    }
    color: var(--text-strong, #1a1a1a) !important;
`

const Text = styled.div`
    line-height: 23px;
    font-family: Lato;
    color: var(--text-darker, #616161);

    align-self: flex-start !important;

    font-size: 15px;

    @media ${device.smallest_laptops} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
        line-height: 25px;
    }

    @media ${device.ipad} {
        font-size: 15px;
        line-height: 23px;
    }
`
const Name = styled(Link)`
    font-family: Lato-Light;
    text-transform: uppercase;

    align-self: flex-start !important;

    padding-bottom: 5px;

    font-size: 17px;

    @media ${device.smallest_laptops} {
        font-size: 16px;
    }

    @media ${device.ipad} {
        font-size: 18px;
    }

    color: var(--text-strong, #1a1a1a) !important;
`

const BtnRow = styled.div`
    padding-top: 31px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    @media ${device.smallest_laptops} {
        padding-top: 25px;
    }

    @media ${device.large_desktop} {
        padding-top: 36px;
    }
`

const BtnRowBtn = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--primary, #5ec3ca);
    background-color: var(--bg-color, #fff);
    border-radius: 5px;

    :focus {
        color: var(--primary, #5ec3ca) !important;
    }
    font-family: lato-light;
    font-size: 16px;
    width: 140px;
    height: 41px;
    cursor: pointer;
    transition: all 200ms;

    @media ${device.smallest_laptops} {
        font-size: 15px;
        width: 130px;
        height: 40px;
    }

    @media ${device.large_desktop} {
        width: 150px;
        height: 44px;
    }

    :hover {
        transition: all 200ms;
        filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1));
        transform: scale(1.02);
    }

    color: var(--primary, #5ec3ca) !important;
`

type Props = {
    interview_overview: IApexInterviewOverviewItemState
}

const ApexInterviewOverviewItemDesktop: React.FC<Props> = ({
    interview_overview,
}) => {
    return (
        <WrapperRow>
            <Link to={`/apex/interviews/${interview_overview.uid}`}>
                <ImgStyle
                    role="img"
                    aria-label="fullwidth-picture-2"
                    style={{
                        backgroundImage: `url(${
                            interview_overview.headshot
                                ? interview_overview.headshot.url
                                : ''
                        })`,
                    }}
                />
            </Link>

            <ContentCol>
                <Name to={`/apex/interviews/${interview_overview.uid}`}>
                    {interview_overview.name}
                </Name>
                <Title to={`/apex/interviews/${interview_overview.uid}`}>
                    {interview_overview.title}
                </Title>
                <Text>{interview_overview.blurb}</Text>
                <BtnRow>
                    <BtnRowBtn
                        to={`/apex/interviews/${interview_overview.uid}`}
                    >
                        <img
                            style={{
                                height: '18px',
                                width: '20px',
                                marginRight: '12px',
                            }}
                            src={read_apex_interview}
                            alt="read"
                        />
                        <div>Read</div>
                    </BtnRowBtn>

                    <span style={{ paddingRight: '15px' }} />

                    <BtnRowBtn
                        to={`/apex/podcasts#${interview_overview.uid}_d`}
                    >
                        <img
                            style={{
                                height: '18px',
                                width: '18px',
                                marginRight: '12px',
                            }}
                            src={listen_apex_interview}
                            alt="listen"
                        />
                        <div>Listen</div>
                    </BtnRowBtn>
                </BtnRow>
            </ContentCol>
        </WrapperRow>
    )
}

export default ApexInterviewOverviewItemDesktop
