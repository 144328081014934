import * as React from 'react'
import { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import SectionHeaderV2 from '../../atoms/typography/sectionHeaderV2'
import { device } from '../../templates/displays/devices'
import copy_icon from '../../../public/assets/icons/copy_icon.svg'
import FilterByMultipleDesktop from '../../organisms/filterByMultiple/timeline/filterByMultipleDesktop'
import DropdownExpanderHover from '../../atoms/menu/dropdownExpander/dropdownExpanderHover'
import { sharing_permissions_ids_selectable_list_mock } from '../../../redux/entities/cars/mockSharedItems'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import { motion } from 'framer-motion'
import { Tooltip } from 'antd'
import NoFilterResults from '../../atoms/noFilterResults/noFilterResults'
import Icon from '../../atoms/icons'
import AddBoxTextInfo from '../../atoms/typography/addBoxTextInfo'
import DirectShareRowDesktop from '../../atoms/shareRow/directShareRowDesktop'
import QrCodeBtn from '../../atoms/Button/qrCodeBtn'
import { editFormsActions } from '../../../redux/editForms/reducer'
import PublicLinkDeactivConfDesktop from '../deletionConfirmationDesktop/publicLinkDeactivModal'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import {
    IDeleteShareReq,
    IFilterCarSharesDisplay_change_request,
    ISearchQueryCarSharesDisplay_change_request,
    ISortCarSharesDisplay_change_request,
} from '../../../redux/entities/cars/actions'
import {
    ICar,
    IDirectShareOwnedItem,
    ISharingPermissionIDS,
} from '../../../redux/entities/cars/types'
import { IGarage } from '../../../redux/entities/garages/types'
import { IisOpen } from '../../../redux/menus/types'
import { IDropdownItem } from 'entityModels'

interface Props {
    car: ICar
    dataCyId?: string
    handleChange_shares_search: (
        p: ISearchQueryCarSharesDisplay_change_request
    ) => void
    handleChange_shares_filter: (
        p: IFilterCarSharesDisplay_change_request
    ) => void
    handleChange_shares_sort: (p: ISortCarSharesDisplay_change_request) => void
    resetSearch: (carid: string) => void
    garage: IGarage
    createCarPublicShare: (p: string) => void
    deletePublicLink: (p: IDeleteShareReq) => void
    openQrCodeModal: (qr_code_id: string) => void
}

interface IStyled {
    isActive?: boolean
}

const Container = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Lato';
    padding-bottom: 100px;
`

const TitleWrapper = styled.div<IStyled>`
    max-width: 450px;
`

const Row = styled.div<IStyled>`
    /*width: 40vw;
        box-sizing: border-box; */
    min-width: 730px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    @media ${device.ipad} {
        max-width: 650px;
    }
`

const Description = styled.div<IStyled>`
    font-family: 'Lato';
    font-size: 16px;
    overflow-wrap: break-word;
    color: var(--text-darker, #616161);
    line-height: 22px;

    @media ${device.smallest_laptops} {
        font-size: 17px;
    }

    @media ${device.ipad} {
        width: 70%;
        max-width: 450px;
    }
`

const Tag = styled.div<IStyled>`
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${(props) =>
        props.isActive
            ? 'var(--insurance_details_green)'
            : 'var(--border_muted_neutral)'};
    color: var(--bg-color, #fff);
    font-family: 'Lato-Bold';
    text-align: center;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 20px;
    font-size: 14px;
`

const BtnBlue = styled.a`
    color: var(--primary, #5ec3ca) !important;
    font-size: 15px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: auto;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    :hover {
        opacity: 0.7;
    }
`

const BtnRed = styled.button`
    color: var(--error, #df6f6f);
    font-size: 15px;
    font-family: 'Lato';
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: auto;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    :hover {
        opacity: 0.7;
    }
    padding: 0px;
`

const LinkWrapper = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 10px;
    min-width: 550px;
    transition: all 0.1s;
    cursor: pointer;
    background-color: transparent;
    :focus {
        outline: 0;
    }
    :hover {
        opacity: 0.7;
    }
`

const LinkTxt = styled.div`
    color: var(--text-darker, #616161);
    font-size: 12px;
    padding-right: 25px;
    font-family: 'Lato';
`
const CopyIcon = styled.img`
    height: 20px;
`

const AddShareBtn = styled.button`
    display: none;
`
const CopyTxt = styled.div`
    color: var(--text-muted);
    font-size: 12px;
    padding-right: 20px;
    font-family: 'Lato';
    padding-top: 20px;
    padding-bottom: 14px;
    text-transform: uppercase;
`

const FiltersContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

const DropdownHoverWrapper = styled.div`
    border-radius: 5px;
    height: 40px;
`

const GoToBoxDesktop = styled.div`
    @media ${device.mobile} {
        display: none;
    }

    background: var(--primary_16);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;
    height: 70px;
    width: 40vw;
    box-sizing: border-box;
    min-width: 730px;

    @media ${device.ipad} {
        min-width: 710px;
        padding-right: 0px;
    }
    margin-bottom: 10px;

    :hover {
        background-color: var(--bg-color, #fff);
        color: var(--bg-color, #fff);
        border: 1px solid var(--primary_80);
        transform: scale(1.015);
    }
`

const SingleCarShareBodyDesktop: React.FC<Props> = ({
    car,
    dataCyId,
    handleChange_shares_search,
    handleChange_shares_filter,
    handleChange_shares_sort,
    resetSearch,
    garage,
    createCarPublicShare,
    deletePublicLink,
    openQrCodeModal,
}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [showBoxInfo, setShowBoxInfo] = useState(false)
    const [showCarTooltip, setShowCarTooltip] = useState(false)
    const [showGarageTooltip, setShowGarageTooltip] = useState(false)

    const publicLinkDeactivationConfirmationModal = useAppSelector(
        (state) =>
            state.editForms.formsData.publicLinkDeactivationConfirmationModal
                .isOpen
    )
    const areCarSharesLoading = useAppSelector(
        (state) => state.entities.carsData.carSharesLoading
    )

    const togglePublicLinkDeactivConfModal = useCallback(
        (p: IisOpen) =>
            dispatch(editFormsActions.togglePublicLinkDeactivConfModal(p)),
        []
    )

    const setTrueBoxInfoToggle = () => setShowBoxInfo(true)
    const setFalseBoxInfoToggle = () => setShowBoxInfo(false)

    const onAddShareClick = () => {
        navigate(`/sharing/with-others/car/create-share?carid=${car.id}`)
    }

    const toggleTooltip = (option: 'car' | 'garage') => {
        if (option === 'car') {
            setShowCarTooltip(true)
        } else {
            setShowGarageTooltip(true)
        }
        setTimeout(() => {
            setShowCarTooltip(false)
            setShowGarageTooltip(false)
        }, 500)
    }

    const has_car_public_share: boolean =
        car && car.public_share_owned && car.public_share_owned.isActive
            ? car.public_share_owned.isActive
            : false

    const has_garage_public_share: boolean =
        garage &&
        garage.public_share_owned &&
        garage.public_share_owned.isActive
            ? garage.public_share_owned.isActive
            : false

    const categoryData: IDropdownItem[] =
        sharing_permissions_ids_selectable_list_mock

    const carPrivateSharesOwned = car && car.private_shares_owned

    const carHasDirectShares: boolean =
        carPrivateSharesOwned &&
        carPrivateSharesOwned.entity_owned_direct_shares_ids &&
        carPrivateSharesOwned.entity_owned_direct_shares_ids.length > 0
            ? true
            : false

    const isCarSharedViaGarage =
        garage &&
        garage.private_shares_owned &&
        garage.private_shares_owned?.entity_owned_direct_shares_ids &&
        garage.private_shares_owned?.entity_owned_direct_shares_ids.length > 0
            ? true
            : false

    const directSharesToDisplayExtractor = (): {
        shares: IDirectShareOwnedItem[] | undefined
        hasActiveSearchCriteria: boolean
    } => {
        let sharesToDisplayArray: IDirectShareOwnedItem[] = []

        if (
            carPrivateSharesOwned &&
            carPrivateSharesOwned.entity_owned_direct_shares_ids
        ) {
            if (
                carPrivateSharesOwned.active_display &&
                carPrivateSharesOwned.active_display.item_ids_display
            ) {
                carPrivateSharesOwned.active_display.item_ids_display.map(
                    (shareID: string) =>
                        sharesToDisplayArray.push(
                            carPrivateSharesOwned!
                                .entity_owned_direct_shares_data[shareID]
                        )
                )
                return {
                    shares:
                        sharesToDisplayArray && sharesToDisplayArray.length > 0
                            ? sharesToDisplayArray
                            : undefined,
                    hasActiveSearchCriteria: true,
                }
            } else if (
                carPrivateSharesOwned.active_display &&
                carPrivateSharesOwned.active_display?.activeFilter
            ) {
                return {
                    shares: sharesToDisplayArray
                        ? sharesToDisplayArray
                        : undefined,
                    hasActiveSearchCriteria: true,
                }
            } else {
                carPrivateSharesOwned.entity_owned_direct_shares_ids.map(
                    (shareID: string) =>
                        sharesToDisplayArray.push(
                            carPrivateSharesOwned!
                                .entity_owned_direct_shares_data[shareID]
                        )
                )
                return {
                    shares:
                        sharesToDisplayArray && sharesToDisplayArray.length > 0
                            ? sharesToDisplayArray
                            : undefined,
                    hasActiveSearchCriteria: false,
                }
            }
        } else return { shares: undefined, hasActiveSearchCriteria: false }
    }

    const { shares: directSharesToDisplay, hasActiveSearchCriteria } =
        directSharesToDisplayExtractor()

    return (
        <Container data-attr={dataCyId && dataCyId}>
            <TitleWrapper>
                <Row>
                    <SectionHeaderV2 isbold icon="v2_add" hasActionIcon={false}>
                        {has_garage_public_share && has_car_public_share
                            ? 'Shareable links'
                            : 'Shareable Link'}
                    </SectionHeaderV2>
                    <Tag
                        isActive={
                            has_car_public_share || has_garage_public_share
                        }
                    >
                        {has_car_public_share || has_garage_public_share
                            ? 'active'
                            : 'inactive'}
                    </Tag>
                </Row>
            </TitleWrapper>
            <Description>
                A public link allows you to share your car with anyone you'd
                like. <br />
                You can remove access to this link at any time.
            </Description>

            <div
                style={{
                    paddingTop: has_garage_public_share ? '5px' : '30px',
                }}
            />

            {has_garage_public_share ? <CopyTxt>Car-Only</CopyTxt> : null}

            {areCarSharesLoading ? (
                <LoaderElasticThreeDots />
            ) : has_car_public_share ? (
                <>
                    <div style={{ display: 'flex' }}>
                        <LinkWrapper
                            onClick={() => {
                                toggleTooltip('car')
                                navigator.clipboard.writeText(
                                    car.public_share_owned &&
                                        car.public_share_owned.url
                                        ? car.public_share_owned.url
                                        : ''
                                )
                            }}
                        >
                            <LinkTxt>
                                {car &&
                                    car.public_share_owned &&
                                    car.public_share_owned.url}
                            </LinkTxt>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CopyIcon src={copy_icon} />

                                <Tooltip
                                    title="Copied!"
                                    open={showCarTooltip}
                                    placement="bottom"
                                    color={'var(--primary-80)'}
                                />
                            </div>
                        </LinkWrapper>
                        <div style={{ paddingRight: '20px' }} />
                        <QrCodeBtn
                            txt="qr code"
                            height="42px"
                            iconSize="25px"
                            alwaysHasText
                            onClick={() => openQrCodeModal('car_only')}
                        />
                    </div>
                    <div
                        style={{
                            paddingTop: has_garage_public_share
                                ? '20px'
                                : '30px',
                        }}
                    />
                    <BtnBlue
                        href={
                            car &&
                            car.public_share_owned &&
                            car.public_share_owned.url
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Preview Car-Only Profile
                    </BtnBlue>
                    <div style={{ paddingTop: '15px' }} />
                    <BtnRed
                        onClick={() =>
                            togglePublicLinkDeactivConfModal({
                                isOpen: true,
                            })
                        }
                    >
                        Deactivate Shareable Link
                    </BtnRed>
                    <div style={{ paddingTop: '15px' }} />
                </>
            ) : (
                <motion.div whileTap={{ scale: 1.05 }}>
                    {!has_car_public_share && !has_garage_public_share ? (
                        <div style={{ paddingTop: '30px' }} />
                    ) : null}
                    <ButtonAtom
                        onClick={() => {
                            createCarPublicShare(car.id)
                        }}
                        theme="lowercase-white-background"
                        height="50px"
                        width="550px"
                    >
                        <div style={{ textTransform: 'capitalize' }}>
                            Generate Car-Only Shareable Link
                        </div>
                    </ButtonAtom>
                </motion.div>
            )}

            {has_garage_public_share ? (
                <>
                    <div
                        style={{
                            paddingTop: !has_car_public_share ? '10px' : '0px',
                        }}
                    />
                    {garage &&
                    garage.public_share_owned &&
                    garage.public_share_owned.isActive ? (
                        <CopyTxt>Car via Garage</CopyTxt>
                    ) : null}
                    <div style={{ display: 'flex' }}>
                        <LinkWrapper
                            onClick={() => {
                                toggleTooltip('garage')
                                navigator.clipboard.writeText(
                                    garage.public_share_owned &&
                                        garage.public_share_owned.url
                                        ? `${garage.public_share_owned.url}/car/${car.id}`
                                        : ''
                                )
                            }}
                        >
                            <LinkTxt>
                                {garage &&
                                    garage.public_share_owned &&
                                    `${garage.public_share_owned.url}/car/${car.id}`}
                            </LinkTxt>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CopyIcon src={copy_icon} />

                                <Tooltip
                                    title="Copied!"
                                    open={showGarageTooltip}
                                    placement="bottom"
                                    color={'#2a969ad3'}
                                />
                            </div>
                        </LinkWrapper>
                        <div style={{ paddingRight: '20px' }} />
                        <QrCodeBtn
                            txt="qr code"
                            height="42px"
                            iconSize="25px"
                            alwaysHasText
                            onClick={() => openQrCodeModal('car_via_garage')}
                        />
                    </div>
                </>
            ) : (
                <div />
            )}

            <div style={{ paddingTop: '50px' }} />
            <PublicLinkDeactivConfDesktop
                isOpen={publicLinkDeactivationConfirmationModal}
                toggle={() =>
                    togglePublicLinkDeactivConfModal({ isOpen: false })
                }
                action={() => {
                    deletePublicLink({
                        entity_id: car && car.id ? car.id : '',
                        share_id:
                            car &&
                            car.public_share_owned &&
                            car.public_share_owned.id
                                ? car.public_share_owned.id
                                : '',
                    })
                }}
                actionCopy="Confirm"
                titleCopy="Are you sure?"
                detailsCopy="Once deactivated, the link and associated QR code will no longer give access to your car. If you decide to share your car in the future, this will generate a new shareable link and a different QR code, with your previous QR code no longer working."
            />

            <Row>
                <SectionHeaderV2
                    isbold
                    hasActionIcon={false}
                    width="100%"
                    id="car_privately_shared_desktop"
                >
                    Car Direct Shares
                </SectionHeaderV2>
            </Row>
            <Row>
                <Description>
                    A direct share allows you to privately share your car only
                    with the people you choose. <br />
                    You can delete a direct share at any time.
                </Description>
            </Row>

            {isCarSharedViaGarage && (
                <>
                    <div style={{ paddingTop: '30px' }} />
                    <Row>
                        <Description>
                            This car is directly shared via a garage share.
                        </Description>
                    </Row>
                    <div style={{ paddingTop: '10px' }} />
                    <ButtonAtom
                        theme="naked-text-arrow"
                        color="#5EC3CA"
                        fontSize={16}
                        onClick={() =>
                            navigate(`/sharing/with-others?entity_id=garage`)
                        }
                    >
                        View Garage Direct Shares
                    </ButtonAtom>
                </>
            )}
            <div style={{ paddingTop: '30px' }} />
            <div style={{ transform: 'translateY(-5px)' }}>
                <Row>
                    <FiltersContainer>
                        <DropdownHoverWrapper>
                            <DropdownExpanderHover
                                width={'210px'}
                                fontSize="15px"
                                height="40px"
                                placeholder={`Sorted by created at`}
                                placeholderWhenSelected="Sorted by"
                                items={[
                                    {
                                        text: 'Created at',
                                        action: () => {
                                            handleChange_shares_sort({
                                                carid: car.id,
                                                sortID: 'created_at',
                                            })
                                        },
                                    },
                                    {
                                        text: 'Updated at',
                                        action: () =>
                                            handleChange_shares_sort({
                                                carid: car.id,
                                                sortID: 'updated_at',
                                            }),
                                    },
                                    {
                                        text: 'Alphabetical',
                                        action: () =>
                                            handleChange_shares_sort({
                                                carid: car.id,
                                                sortID: 'alphabetical',
                                            }),
                                    },
                                ]}
                                isDisabled={carHasDirectShares === false}
                            />
                        </DropdownHoverWrapper>

                        <FilterByMultipleDesktop
                            categoryData={categoryData}
                            selectedFilteringLabels={[]}
                            selectedFilteringCategories={
                                car.private_shares_owned &&
                                car.private_shares_owned.active_display
                                    ? car.private_shares_owned.active_display
                                          .activeFilter
                                    : []
                            }
                            filterBySelected={(
                                selected: ISharingPermissionIDS[]
                            ) => {
                                handleChange_shares_filter({
                                    carid: car.id,
                                    filterIDs: selected,
                                })
                            }}
                            customSideCopy={'Filter By'}
                            buttonMinWidth={'170px'}
                            entityID="sharing"
                            isDisabled={carHasDirectShares === false}
                        />
                    </FiltersContainer>
                </Row>
                <div style={{ paddingTop: '30px' }} />
                {directSharesToDisplay ? (
                    directSharesToDisplay.map(
                        (share: IDirectShareOwnedItem, index: number) => (
                            <DirectShareRowDesktop
                                key={`direct_share_desktop_${index}`}
                                share={share}
                                carid={car.id}
                                index={index}
                                isLast={
                                    car &&
                                    car.private_shares_owned
                                        ?.entity_owned_direct_shares_ids &&
                                    car.private_shares_owned
                                        ?.entity_owned_direct_shares_ids
                                        .length - 1
                                        ? true
                                        : false
                                }
                                actionsData={{
                                    addUserToShare: () =>
                                        navigate(
                                            `/sharing/with-others/car/${car.id}/edit-share?shareid=${share.id}&active_tab_id=recipients`
                                        ),

                                    settings: () =>
                                        navigate(
                                            `/sharing/with-others/car/${car.id}/edit-share?shareid=${share.id}&active_tab_id=settings`
                                        ),
                                }}
                            />
                        )
                    )
                ) : carHasDirectShares && hasActiveSearchCriteria ? (
                    <NoFilterResults
                        text="No results containing all your search terms were found."
                        variant={'desktop'}
                    />
                ) : (
                    <>
                        <AddShareBtn>+</AddShareBtn>

                        <GoToBoxDesktop
                            onClick={() => onAddShareClick()}
                            onMouseEnter={setTrueBoxInfoToggle}
                            onMouseLeave={setFalseBoxInfoToggle}
                        >
                            {showBoxInfo === false && <Icon icon="add_plus" />}
                            {showBoxInfo === true && (
                                <AddBoxTextInfo>
                                    Create a car-only direct share.
                                </AddBoxTextInfo>
                            )}
                        </GoToBoxDesktop>
                    </>
                )}

                {directSharesToDisplay && (
                    <>
                        <AddShareBtn>+</AddShareBtn>

                        <GoToBoxDesktop
                            onClick={() => onAddShareClick()}
                            onMouseEnter={setTrueBoxInfoToggle}
                            onMouseLeave={setFalseBoxInfoToggle}
                        >
                            {showBoxInfo === false && <Icon icon="add_plus" />}
                            {showBoxInfo === true && (
                                <AddBoxTextInfo>
                                    Create a car-only direct share.
                                </AddBoxTextInfo>
                            )}
                        </GoToBoxDesktop>
                    </>
                )}
            </div>
        </Container>
    )
}

export default SingleCarShareBodyDesktop
