import styled, { keyframes } from 'styled-components'
import { device } from '../../templates/displays/devices'
import './style.css'
import useThemes from '../../../providers/theme/hooks'

export const placeholderShimmer = keyframes`
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
`

export const whiteThemeSkeletonGradient = `linear-gradient(
        120deg,
        #f1f1f1 30%,
        rgba(255, 255, 255, 0.89) 50%,
        #f1f1f1 65%
    );`

export const darkThemeSkeletonGradient = `linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 20%,
                rgba(26, 26, 26, 0.3) 30%,
        rgba(26, 26, 26, 1) 50%,
                rgba(26, 26, 26, 0.3) 60%,
        rgba(255, 255, 255, 0) 10%
    );`

export const SkeletonStyledComp = styled.div<ISkeletonProps>`
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeholderShimmer};
    animation-timing-function: linear;
    background: ${(props) => (props.darkTheme ? '#2B2B2B' : '#f1f1f1')};

    background-image: ${(props) =>
        props.darkTheme
            ? darkThemeSkeletonGradient
            : whiteThemeSkeletonGradient};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;

    border-radius: ${(props) =>
        props.customBorderRadius
            ? props.customBorderRadius
            : props.isRound
            ? '50%'
            : ''};

    opacity: ${(props) => (props.isthingloading ? 1 : 0)};
    transition: all 500ms;

    @media ${device.mobile} {
        height: ${(props) =>
            props.mobileHeight ? props.mobileHeight : '100%'};
    }
`

export const whiteThemeSkeletonGradient2 = `linear-gradient(
        120deg,
        #fafafa 30%,
        #fff 55%,
        #fafafa 70%
    );`

export const SkeletonStyledCompNonAbsolute = styled.div<ISkeletonProps>`
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeholderShimmer};
    animation-timing-function: linear;
    background: ${(props) => (props.darkTheme ? '#2B2B2B' : '#fafafa')};

    background-image: ${(props) =>
        props.darkTheme
            ? darkThemeSkeletonGradient
            : whiteThemeSkeletonGradient2};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100% !important;
    width: 100% !important;
    border-radius: ${(props) =>
        props.customBorderRadius
            ? props.customBorderRadius
            : props.isRound
            ? '50%'
            : ''};

    opacity: ${(props) => (props.isthingloading ? 1 : 0)};
    transition: all 500ms;

    @media ${device.mobile} {
        height: ${(props) =>
            props.mobileHeight ? props.mobileHeight : '100%'};
    }
`

interface ISkeletonProps {
    isthingloading: boolean
    mobileHeight?: string
    darkTheme?: boolean
    isRound?: boolean
    customBorderRadius?: string
}

const SkeletonAbsolute = (props: ISkeletonProps) => {
    const { theme } = useThemes()
    return (
        <SkeletonStyledComp
            mobileHeight={props.mobileHeight && props.mobileHeight}
            isthingloading={props.isthingloading}
            darkTheme={theme === 'dark' || props.darkTheme}
            isRound={props.isRound}
            customBorderRadius={props.customBorderRadius}
        />
    )
}

export default SkeletonAbsolute
