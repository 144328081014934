import ModalDisplayFullPageDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import { useAppDispatch } from '../../../redux/store/hooks'
import { showroomActions } from '../../../redux/showroom/reducer'
import ShowroomEntryBasicInfoManagerDesktop, {
    IShowroomEntryBasicDataForm,
} from '../showroomEntryManager/showroomEntryBasicInfoManagerDesktop'

type Props = {
    isOpen: boolean
    toggle: (p: boolean) => any
    carid: string
}

const CreateShowroomEntryFormDesktop = (props: Props) => {
    let { isOpen, toggle, carid } = props

    const dispatch = useAppDispatch()

    const submit = (formData: IShowroomEntryBasicDataForm) => {
        dispatch(
            showroomActions.createEntryShowroomRequest({
                resource_id: carid,
                type_of_sale: formData.poa
                    ? 'PRICE_ON_ASKING'
                    : formData.set_price
                    ? 'SET_PRICE'
                    : 'PRICE_RANGE',
                price_min:
                    formData.set_price && formData.set_price.value
                        ? formData.set_price.value
                        : formData.price_range?.valueFrom,
                price_max:
                    formData.set_price && formData.set_price.value
                        ? formData.set_price.value
                        : formData.price_range?.valueTo,
                currency:
                    formData.price_range && formData.price_range.currency
                        ? formData.price_range.currency
                        : formData.set_price && formData.set_price.currency
                        ? formData.set_price.currency
                        : 'GBP',
                open_to_offers: formData.set_price
                    ? formData.set_price.open_to_offer
                    : formData.set_price
                    ? false
                    : undefined,
            })
        )
    }

    return (
        <ModalDisplayFullPageDesktopExclusive
            toggle={toggle}
            isLightMode={false}
            isOpen={isOpen}
        >
            <ShowroomEntryBasicInfoManagerDesktop
                onSubmit={submit}
                onCancel={toggle}
            />
        </ModalDisplayFullPageDesktopExclusive>
    )
}

export default CreateShowroomEntryFormDesktop
