import * as React from 'react'
import styled from 'styled-components'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { device } from '../../templates/displays/devices'

import DraggableItemEditableMobile from '../../atoms/draggable/draggableEditableItemMobile'
import DraggableItemEditableJourneyDesktop from '../../atoms/draggable/draggableEditableItemJourneyDesktop'
import DraggableItemEditableJourneyMobile from '../../atoms/draggable/draggableEditableItemJourneyMobile'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

const ListContainer = styled.ul<{ $isJourney?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    list-style-type: disc;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    overflow-y: ${(props) => (props.$isJourney ? 'visible' : 'scroll')};

    @media ${device.beyond_ipad_mobile} {
        ${(props) => (!props.$isJourney ? 'display: none' : undefined)};
    }
`

type Props = {
    data_object: IObject
    ids_list: string[]
    formikprops: {
        handleChange: (e: React.ChangeEvent<any>) => void
        values: {
            [key: string]: string
        }
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined
        ) => void
    }
    giveNewArrayOrder?: (items: EnumerableObject[]) => void
    isJourney?: boolean
    isJourneyMobile?: boolean
}

interface IObject {
    [key: string]: EnumerableObject
}

type EnumerableObject = {
    id: string
    text: string
    order: number
}

const sortList = (list: EnumerableObject[]) => {
    return list.sort(
        (a: EnumerableObject, b: EnumerableObject) => a.order - b.order
    )
}

const DragNSortEditableMobile: React.FC<Props> = ({
    data_object,
    ids_list,
    formikprops,
    giveNewArrayOrder,
    isJourney,
    isJourneyMobile,
}) => {
    const [items, setItems] = React.useState<EnumerableObject[]>([])

    React.useEffect(() => {
        let list = ids_list.map((id: string) => data_object[id])
        let ordered_list = sortList(list)
        setItems(ordered_list)
    }, [data_object])

    const sensors = isJourneyMobile
        ? useSensors(
              useSensor(TouchSensor, {
                  // Press delay of 250ms, with tolerance of 5px of movement
                  activationConstraint: {
                      delay: 250,
                      tolerance: 5,
                  },
              })
          )
        : useSensors(
              useSensor(PointerSensor),
              useSensor(KeyboardSensor, {
                  coordinateGetter: sortableKeyboardCoordinates,
              }),
              useSensor(MouseSensor, {
                  // Require the mouse to move by 10 pixels before activating
                  activationConstraint: {
                      distance: 10,
                  },
              })
          )

    const handleDragEnd = (event: any) => {
        const { active, over } = event

        if (active?.id !== over?.id) {
            setItems((items) => {
                const oldIndex = items.findIndex(
                    (item) => item?.id === active?.id
                )
                const newIndex = items.findIndex(
                    (item) => item?.id === over?.id
                )

                const newItems = arrayMove(items, oldIndex, newIndex)
                giveNewArrayOrder && giveNewArrayOrder(newItems)
                return newItems
            })
        }
        enableBodyScroll(document.body)
    }

    const handleDragStart = () => {
        disableBodyScroll(document.body)
    }

    return (
        <React.Fragment>
            {items.length > 1 && (
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                >
                    <SortableContext
                        items={items.map((item) => item.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        <ListContainer $isJourney={isJourney}>
                            {items.map(
                                (item: EnumerableObject, index: number) =>
                                    isJourney ? (
                                        <React.Fragment
                                            key={`${item?.id}_${index}_drag_n_sort_mobile`}
                                        >
                                            {isJourneyMobile ? (
                                                <DraggableItemEditableJourneyMobile
                                                    formikprops={formikprops}
                                                    index={index}
                                                    item={item}
                                                />
                                            ) : (
                                                <DraggableItemEditableJourneyDesktop
                                                    formikprops={formikprops}
                                                    index={index}
                                                    item={item}
                                                />
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment
                                            key={`${item?.id}_${index}_drag_n_sort_mobile`}
                                        >
                                            <DraggableItemEditableMobile
                                                formikprops={formikprops}
                                                index={index}
                                                item={item}
                                            />
                                        </React.Fragment>
                                    )
                            )}
                        </ListContainer>
                    </SortableContext>
                </DndContext>
            )}
        </React.Fragment>
    )
}

export default DragNSortEditableMobile
