import { useEffect } from 'react'
import { capitalizeFirstLetter } from '../../../../helpers/strings/capitalizeFirstLetter'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { IAnyObject } from '../../../../redux/insuranceQuoteApplication/reducer'
import {
    IInsuranceFieldType,
    insurance_sub_fields_other_driver_track_record_motorconviction_date,
    insurance_sub_fields_other_driver_track_record_motorconviction_fine_amount,
} from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import Expander from '../../../atoms/expander/expander'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { Error } from '../../editForms/carDescriptionEditForm/carDescriptionEditFormDesktop'
import { RenderInsuranceQuoteFormFieldDesktop } from './renderElementDesktop'
import { RenderInsuranceQuoteFormFieldMobile } from './renderElementMobile'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { EditIcon } from '../../../atoms/statefulicons'

type Props = {
    field_id: string
    onAnswerChange: (id: string, answer: any) => any
    data: IAnyObject
    errors?: IAnyObject
    setError: (id: string, error: any) => any
    isMobile?: boolean
    sectionID?: IInsuranceApplicationSectionId
    updateListOfFields?: (list: IInsuranceFieldType[]) => void
}

export const InsuranceQuoteSubFormGeneratorLayer3 = (props: Props) => {
    let {
        field_id,
        onAnswerChange,
        data,
        errors,
        setError,
        isMobile,
        sectionID,
        updateListOfFields,
    } = props

    let list: IInsuranceFieldType[] | undefined = field_id.match(
        'has_resulted_in_driving_ban'
    )
        ? insurance_sub_fields_other_driver_track_record_motorconviction_date
        : field_id.match('has_resulted_in_fine')
        ? insurance_sub_fields_other_driver_track_record_motorconviction_fine_amount
        : undefined

    useEffect(() => {
        if (list && updateListOfFields) {
            updateListOfFields(list)
        }
    }, [])

    const { theme } = useThemes()

    let { crossFormData, setIsCrossFormOpen } = useSetInitialInsurance()

    return data ? (
        <>
            {list ? (
                <div style={{ width: '100%' }}>
                    {list?.map((item: IInsuranceFieldType, index: number) => {
                        let prevItemHadDivider: boolean | undefined =
                            index !== 0 &&
                            list![index - 1] &&
                            list![index - 1].ends_section_with_divider

                        let item_err =
                            errors && item.id
                                ? item.type === 'dates_range'
                                    ? errors[`${item.id}_start_date`] ||
                                      errors[`${item.id}_end_date`]
                                    : errors[item.id]
                                    ? errors[item.id]
                                    : undefined
                                : undefined

                        return (
                            <div
                                style={{ width: '100%' }}
                                key={`sub-gen-layer3-${item.id}-${index}-${
                                    isMobile ? 'mobile' : 'desktop'
                                }`}
                            >
                                <Expander
                                    height={
                                        item.type === 'date'
                                            ? 'auto'
                                            : data &&
                                              data[item.id] !== undefined &&
                                              data[item.id] !== null &&
                                              data[item.id] !== '' &&
                                              item.type !== 'yes_no' &&
                                              item.type !==
                                                  'contact_medium_choice' &&
                                              prevItemHadDivider !== true
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    <div
                                        style={{
                                            paddingTop: '10px',
                                        }}
                                    />
                                </Expander>

                                {item.type === 'yes_no' &&
                                    !prevItemHadDivider && (
                                        <div style={{ paddingTop: '10px' }} />
                                    )}

                                {isMobile ? (
                                    <RenderInsuranceQuoteFormFieldMobile
                                        data={data}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        item={item}
                                        sectionID={sectionID}
                                    />
                                ) : (
                                    <RenderInsuranceQuoteFormFieldDesktop
                                        data={data}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        item={item}
                                        sectionID={sectionID}
                                    />
                                )}

                                <Expander
                                    height={errors && item_err ? 'auto' : 0}
                                >
                                    <Error
                                        $theme={theme}
                                        style={{ paddingTop: '8px' }}
                                    >
                                        {errors &&
                                        item_err &&
                                        typeof item_err !== 'string' &&
                                        item_err.status
                                            ? `${
                                                  item_err.status?.toLowerCase() !==
                                                  'invalid'
                                                      ? `${capitalizeFirstLetter(
                                                            item_err.status
                                                        )} - `
                                                      : ''
                                              } ${
                                                  item_err?.message
                                                      ? `${item_err?.message}`
                                                      : ''
                                              }`
                                            : errors &&
                                              item_err &&
                                              typeof item_err === 'string'
                                            ? `${item_err}`
                                            : ''}
                                    </Error>
                                    {crossFormData && crossFormData[item.id] ? (
                                        <StandardCtaBtn
                                            bg="transparent"
                                            boder="none"
                                            removeShadowOnHover
                                            removePaddings
                                            customWidth="auto"
                                            customHeight="auto"
                                            onClick={() =>
                                                setIsCrossFormOpen({
                                                    id: item.id,
                                                })
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    marginTop: 12,
                                                    marginBottom: 24,
                                                }}
                                            >
                                                <EditIcon
                                                    color={
                                                        colours[theme].primary
                                                    }
                                                    onClick={() => {}}
                                                />
                                                <span
                                                    style={{
                                                        color: colours[theme]
                                                            .primary,
                                                    }}
                                                >
                                                    {
                                                        crossFormData[item.id]
                                                            ?.affected_field_error_cta
                                                    }
                                                </span>
                                            </div>
                                        </StandardCtaBtn>
                                    ) : null}
                                </Expander>

                                <div
                                    style={{
                                        paddingBottom: '16px',
                                    }}
                                />

                                {item.ends_section_with_divider === true && (
                                    <div
                                        style={{
                                            marginTop: '24px',
                                            marginBottom: '40px',
                                            background:
                                                'var(--border-muted, #e5e5e5)',
                                            height: '1px',
                                        }}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            ) : null}
        </>
    ) : (
        <div />
    )
}
