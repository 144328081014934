import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import {
    JourneyCardDesktop,
    JourneyCardMobile,
} from '../../../templates/styledcomponents/journeyStyles'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import UserPlainIcon from '../../../atoms/icons/components/userPlainIcon'
import placeholder1 from '../../../atoms/skeleton/gallery/cover_image.jpg'
import placeholder1_dark from '../../../atoms/skeleton/gallery/cover_image_dark.png'
import { device } from '../../../templates/displays/devices'
import { useAppDispatch } from '../../../../redux/store/hooks'
import AnimatedTextareaShort from '../../../atoms/textarea/animatedTextareaShort'
import JourneyPageWrapperMobile from '../../../templates/wrappers/journeyPageWrapperMobile'
import JourneyWrapperLeftSideDesktop from '../../../templates/wrappers/journeyPageWrapperLeftDesktop'
import {
    ICarsObject,
    IUpdateCarGeneralPayloadReq,
} from '../../../../redux/entities/cars/types'
import { IGalleryImagesObject } from '../../../../redux/entities/galleries/types'
import { IUser } from '../../../../redux/user/types'
import { carActions } from '../../../../redux/entities/cars/reducer'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: (carid?: string) => void
    goToNextStep: (carid: string) => void
    carsData?: ICarsObject
    garageCars?: string[]
    galleryImages?: IGalleryImagesObject | null
    user: IUser | null
}

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`
const RightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
    height: 100%;
    @media ${device.smallest_laptops} {
        padding: 24px;
    }
`

const ColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`

const Text = styled.span<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    @media ${device.mobile_and_ipad} {
        font-size: 16px;
        line-height: 24px;
    }
`

const EnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

const RowContent = styled.div`
    display: flex;
    align-items: center;
`

const IconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

const CardContent = styled.section`
    display: flex;
    width: 592px;
    box-sizing: border-box;
    padding: 56px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    @media (max-width: 1200px) {
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
    }
    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
    }
`
const CardTitle = styled.h3<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 22px;
    font-family: Lato-semibold;
    line-height: 32px;
    @media ${device.mobile_and_ipad} {
        color: ${(props) => colours[props.$theme].text_strong};
        font-size: 16px;
        line-height: 24px;
    }
`

const CardSubtitle = styled.p<IStyle>`
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
    @media ${device.mobile_and_ipad} {
        color: ${(props) => colours[props.$theme].text_default};
        font-size: 14px;
        line-height: 20px;
    }
`

const CardImageWrapper = styled.div`
    height: 140px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    @media ${device.ipad} {
        height: 160px;
    }
    @media ${device.mobile} {
        height: 120px;
    }
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
`

export default function AddPCarBioStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    carsData,
    user,
}: Props) {
    const { theme } = useThemes()
    let params = new URLSearchParams(location.search)
    let carid: string | null = params.get('carid')

    let currentBio =
        carsData &&
        carid &&
        carsData[carid] &&
        carsData[carid].short_description
            ? carsData[carid].short_description
            : undefined

    const [bioContent, setBioContent] = useState<string | undefined>(
        currentBio ?? undefined
    )

    const dispatch = useAppDispatch()

    const onSubmit = (car_id: string, bio: string) => {
        if (car_id && bio) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: car_id,
                isOnboarding: true,
                dataListForm: [
                    {
                        id: 'short_description',
                        text: 'Short description',
                        answer: bioContent,
                    },
                ],
            }
            dispatch(carActions.writeCarDataGeneralRequest(payload))
        }
    }

    const submitAndGoToNextStep = () => {
        if (carid) {
            if (bioContent) {
                onSubmit(carid, bioContent)
            }
            goToNextStep(carid)
        }
    }

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            submitAndGoToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [bioContent, step, carid])

    let bannerImg =
        carsData && carid && carsData[carid] && carsData[carid].banner_url
            ? carsData[carid].banner_url
            : undefined

    let carBioPlaceholder =
        'My trusty companion, meticulously crafted to elevate driving experience. From its sleek exterior to its powerful performance under the hood, this car embodies both style and substance.'

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <Wrapper>
                        <JourneyWrapperLeftSideDesktop
                            title={
                                currentBio ? 'Edit short bio' : 'Add short bio'
                            }
                            sectionID="car_profile"
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={() => {
                                carid && goToPrevStep(carid)
                            }}
                        >
                            <AnimatedTextareaShort
                                name={''}
                                value={bioContent ? `${bioContent}` : ''}
                                placeholder={carBioPlaceholder}
                                handleChange={(e: any) => {
                                    setBioContent(e.target.value)
                                }}
                                furtherStyle={{
                                    fontSize: 16,
                                    fontFamily: 'Lato',
                                    lineHeight: '24px',
                                    color: '#666',
                                }}
                                height={'200px'}
                                maxLength={256}
                            />

                            <RowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={submitAndGoToNextStep}
                                    bg={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_500
                                    }
                                    isDisabled={!carid}
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>
                                {carid ? (
                                    <RowContent style={{ gap: 8 }}>
                                        <IconWrapper $theme={theme}>
                                            <EnterIcon />
                                        </IconWrapper>{' '}
                                        <EnterTxt $theme={theme}>
                                            Or press Enter
                                        </EnterTxt>
                                    </RowContent>
                                ) : null}
                            </RowContent>
                        </JourneyWrapperLeftSideDesktop>
                        <RightSide>
                            <JourneyCardDesktop
                                $theme={theme}
                                $customBorderRadius="24px"
                                $customWidth="600px"
                            >
                                <CardContent>
                                    <ColumnContent style={{ gap: 24 }}>
                                        <CardImageWrapper>
                                            {bannerImg ? (
                                                <Image
                                                    src={bannerImg.large_src}
                                                    srcSet={
                                                        bannerImg.large_srcset
                                                    }
                                                />
                                            ) : (
                                                <Image
                                                    src={
                                                        theme === 'dark'
                                                            ? placeholder1_dark
                                                            : placeholder1
                                                    }
                                                />
                                            )}
                                        </CardImageWrapper>
                                        <ColumnContent style={{ gap: 8 }}>
                                            <ColumnContent style={{ gap: 6 }}>
                                                <CardTitle $theme={theme}>
                                                    {carid &&
                                                    carsData &&
                                                    carsData[carid]
                                                        ? carsData[carid].title
                                                        : 'Untitled car'}
                                                </CardTitle>
                                                <CardSubtitle $theme={theme}>
                                                    <IconWrapper
                                                        $theme={theme}
                                                        style={{
                                                            backgroundColor:
                                                                colours[theme]
                                                                    .background_neutral_overlay_02,
                                                            border: `1px solid ${colours[theme].background_neutral_overlay_40}`,
                                                            borderRadius: 6,
                                                        }}
                                                    >
                                                        <UserPlainIcon
                                                            size="16"
                                                            color={
                                                                colours[theme]
                                                                    .text_neutral_default
                                                            }
                                                        />
                                                    </IconWrapper>
                                                    {`Owned by ${user?.display_name}`}
                                                </CardSubtitle>
                                            </ColumnContent>
                                            <Text
                                                $theme={theme}
                                                style={
                                                    bioContent
                                                        ? {
                                                              color: colours
                                                                  .light
                                                                  .text_darker,
                                                          }
                                                        : {}
                                                }
                                            >
                                                {bioContent && bioContent !== ''
                                                    ? bioContent
                                                    : carBioPlaceholder}
                                            </Text>
                                        </ColumnContent>
                                    </ColumnContent>
                                </CardContent>
                            </JourneyCardDesktop>
                        </RightSide>
                    </Wrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyPageWrapperMobile
                    title={currentBio ? 'Edit short bio' : 'Add short bio'}
                    sectionID="car_profile"
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        carid && goToPrevStep(carid)
                    }}
                    onNext={submitAndGoToNextStep}
                >
                    <ColumnContent
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        <JourneyCardMobile
                            $theme={theme}
                            $customBorderRadius="24px"
                        >
                            <CardContent
                                style={{
                                    gap: 24,
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <CardImageWrapper>
                                    {bannerImg ? (
                                        <Image
                                            src={bannerImg.large_src}
                                            srcSet={bannerImg.large_srcset}
                                        />
                                    ) : (
                                        <Image
                                            src={
                                                theme === 'dark'
                                                    ? placeholder1_dark
                                                    : placeholder1
                                            }
                                        />
                                    )}
                                </CardImageWrapper>
                                <ColumnContent style={{ gap: 8 }}>
                                    <ColumnContent style={{ gap: 6 }}>
                                        <CardTitle $theme={theme}>
                                            {carid &&
                                            carsData &&
                                            carsData[carid]
                                                ? carsData[carid].title
                                                : 'Untitled car'}
                                        </CardTitle>
                                        <CardSubtitle $theme={theme}>
                                            <IconWrapper
                                                $theme={theme}
                                                style={{
                                                    backgroundColor:
                                                        colours[theme]
                                                            .background_neutral_overlay_02,
                                                    border: `1px solid ${colours[theme].background_neutral_overlay_40}`,
                                                    borderRadius: 6,
                                                }}
                                            >
                                                <UserPlainIcon
                                                    size="16"
                                                    color={
                                                        colours[theme]
                                                            .text_neutral_default
                                                    }
                                                />
                                            </IconWrapper>
                                            {`Owned by ${user?.display_name}`}
                                        </CardSubtitle>
                                    </ColumnContent>
                                </ColumnContent>
                                <AnimatedTextareaShort
                                    name={''}
                                    value={bioContent ? `${bioContent}` : ''}
                                    placeholder={carBioPlaceholder}
                                    handleChange={(e: any) => {
                                        setBioContent(e.target.value)
                                    }}
                                    furtherStyle={{
                                        fontSize: 16,
                                        fontFamily: 'Lato',
                                        lineHeight: '24px',
                                    }}
                                    height={'200px'}
                                    maxLength={256}
                                />
                            </CardContent>
                        </JourneyCardMobile>
                    </ColumnContent>
                </JourneyPageWrapperMobile>
            </IpadAndMobileDisplay>
        </div>
    )
}
