import * as React from 'react'
import styled from 'styled-components'
import profile_blank from '../../../public/assets/placeholders_svgs/avatar.svg'
import { device } from '../../templates/displays/devices'
import {
    IDirectShareOwner,
    IShareRecipient,
} from '../../../redux/entities/cars/types'

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const UserDetails = styled.div`
    padding-left: 10px;
`

const UserName = styled.div<IStyle>`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-light;
    font-size: ${(props) => (props.$isCarOverview ? '14px' : '12px')};
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;

    @media ${device.mobile} {
        font-size: ${(props) => (props.$isCarOverview ? '12px' : '11px')};
    }
    @media (max-width: 320px) {
        font-size: ${(props) => (props.$isCarOverview ? '12px' : '10px')};
        white-space: normal;
        overflow: auto;
    }
`

const UserLocation = styled.div<IStyle>`
    font-family: Lato-light;
    font-size: ${(props) => (props.$isCarOverview ? '14px' : '12px')};
    font-style: italic;
    color: var(--text-default, #666666);
    @media ${device.mobile} {
        font-size: ${(props) => (props.$isCarOverview ? '12px' : '11px')};
    }
`

type IStyle = {
    $isCarOverview?: boolean
}

type IPicProps = {
    $userPic?: string
}

const ProfilePic = styled.div<IPicProps>`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border-radius: 50%;
    background-image: ${(props) =>
        props.$userPic ? `url(${props.$userPic})` : `url(${profile_blank})`};
    background-position: center;
    background-size: cover;
`

const ProfilePicWrapper = styled.div<IStyle>`
    width: ${(props) => (props.$isCarOverview ? '65px' : '40px')};
    height: ${(props) => (props.$isCarOverview ? '65px' : '40px')};

    @media ${device.mobile} {
        width: ${(props) => (props.$isCarOverview ? '57px' : '35px')};
        height: ${(props) => (props.$isCarOverview ? '57px' : '35px')};
    }
`

type IProps = {
    owner: IShareRecipient | IDirectShareOwner | undefined
    sharedOn?: string
    isMobile?: boolean
    isCarOverview?: boolean
}

const CarOwnerBox: React.FunctionComponent<IProps> = (props) => {
    let { owner, isCarOverview } = props

    let fullname: string =
        owner && owner.display_name ? owner.display_name : 'Car Owner'

    return (
        <Wrapper>
            {owner && (
                <>
                    <ProfilePicWrapper $isCarOverview={isCarOverview}>
                        <ProfilePic
                            style={{
                                border: owner.profile_picture
                                    ? '1px solid var(--primary, #5ec3ca)'
                                    : '1px solid var(--border-muteda)',
                            }}
                            $userPic={
                                owner.profile_picture
                                    ? owner.profile_picture.url
                                    : profile_blank
                            }
                        />
                    </ProfilePicWrapper>
                    <UserDetails>
                        <UserName $isCarOverview={isCarOverview}>
                            {fullname.length > 20
                                ? `${fullname.slice(0, 20)}...`
                                : fullname.trim()}
                        </UserName>
                        <UserLocation $isCarOverview={isCarOverview}>
                            {props.isMobile
                                ? props.sharedOn
                                : props.owner && props.owner.location
                                ? props.owner.location.length > 30
                                    ? `${props.owner.location.slice(0, 30)}...`
                                    : props.owner.location.trim() !== ''
                                    ? props.owner.location
                                    : ''
                                : ''}
                        </UserLocation>
                    </UserDetails>
                </>
            )}
        </Wrapper>
    )
}

export default CarOwnerBox
