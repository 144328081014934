import React, { useState, useEffect, useCallback } from 'react'
import Fuse from 'fuse.js'
import SingleSelectMobile from './singleSelectMobile'
import { debounce } from 'lodash'
import SingleSelectOnlyMatchedMobile from './singleSelectOnlyMatchedMobile'
import StandaloneSearchSelectMobile from './standaloneSearchSelectMobile'

export type Props = {
    items: any[]
    item: any
    width?: string
    maxWidth?: string
    handleSelectChange?: any
    addAnItemFunc?: any
    dropdownTargetId?: string
    formCurrentValue?: string
    allowAdd?: boolean
    isDisabled?: boolean
    fontSize?: string
    placeholder?: string
    helperText?: string
    onlyShowMatch?: boolean
    dataCyId?: string | undefined
    theme?: 'timeline' | undefined
    customPlaceholder?: string
    sendId?: boolean
    isKeyValue?: boolean
    valueUpdatedFromExternalSource?: string | null
    isStandalonePage?: boolean
    backgroundColor?: string
    field_id?: string
    height?: string
    borderColor?: string
}

const SelectSearchBarCarInfoMobile: React.FC<Props> = (props) => {
    const [data, setData] = useState<any[]>([])
    const [name, setName] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(false)
    const [lastFetchId, setLastFetchId] = useState<number>(0)

    const fetchItem = useCallback(
        debounce((name: any) => {
            setLastFetchId((prevId) => prevId + 1)
            const fetchId = lastFetchId + 1
            setData([])
            setFetching(true)
            if (fetchId !== lastFetchId + 1) {
                return
            }

            let fuse: any = {}

            if (props.isKeyValue === true) {
                fuse = new Fuse(props.items, { threshold: 0.2 })
            } else {
                fuse = new Fuse(props.items, {
                    keys: ['name', 'uid'],
                    threshold: 0.2,
                })
            }

            let results: any = fuse.search(name)
            setData(results)
            setFetching(false)
        }, 300),
        [lastFetchId, props.items, props.isKeyValue]
    )

    const handleChange = (name: any, other?: string) => {
        setName((prevName) => [...prevName, name])
        setData([])
        setFetching(false)
        props.handleSelectChange && props.handleSelectChange(name, other)
    }

    useEffect(() => {
        if (props.valueUpdatedFromExternalSource) {
            setName((prevName) => [
                ...prevName,
                props.valueUpdatedFromExternalSource,
            ])
            setData([])
            setFetching(false)
        }
    }, [props.valueUpdatedFromExternalSource])

    const {
        item,
        width,
        addAnItemFunc,
        dropdownTargetId,
        formCurrentValue,
        allowAdd,
        isDisabled,
        fontSize,
        placeholder,
        helperText,
        onlyShowMatch,
        dataCyId,
        theme,
        maxWidth,
        customPlaceholder,
        sendId,
        isKeyValue,
        isStandalonePage,
        backgroundColor,
        field_id,
        height,
        borderColor,
    } = props

    return (
        <>
            {isStandalonePage && (
                <StandaloneSearchSelectMobile
                    field_id={field_id}
                    backgroundColor={backgroundColor}
                    fetching={fetching}
                    value={name}
                    data={data}
                    fetchItem={fetchItem}
                    handleChange={handleChange}
                    optionsList={props.items}
                    item={item}
                    width={width && width}
                    addAnItemFunc={addAnItemFunc && addAnItemFunc}
                    dropdownTargetId={dropdownTargetId && dropdownTargetId}
                    formCurrentValue={formCurrentValue && formCurrentValue}
                    allowAdd={allowAdd && allowAdd}
                    isDisabled={isDisabled && isDisabled}
                    fontSize={fontSize && fontSize}
                    placeholder={placeholder && placeholder}
                    dataCyId={dataCyId}
                    theme={theme && theme}
                    sendId={sendId}
                    isKeyValue={isKeyValue}
                />
            )}
            {!isStandalonePage && onlyShowMatch === true ? (
                <SingleSelectOnlyMatchedMobile
                    backgroundColor={backgroundColor}
                    field_id={field_id}
                    fetching={fetching}
                    value={name}
                    data={data}
                    fetchItem={fetchItem}
                    handleChange={handleChange}
                    item={item}
                    width={width && width}
                    addAnItemFunc={addAnItemFunc && addAnItemFunc}
                    dropdownTargetId={dropdownTargetId && dropdownTargetId}
                    formCurrentValue={formCurrentValue && formCurrentValue}
                    allowAdd={allowAdd && allowAdd}
                    isDisabled={isDisabled && isDisabled}
                    fontSize={fontSize && fontSize}
                    placeholder={placeholder && placeholder}
                    helperText={helperText}
                    dataCyId={dataCyId}
                    maxWidth={maxWidth}
                    customPlaceholder={customPlaceholder}
                    sendId={sendId}
                />
            ) : !isStandalonePage ? (
                <SingleSelectMobile
                    field_id={field_id}
                    backgroundColor={backgroundColor}
                    fetching={fetching}
                    value={name}
                    data={data}
                    fetchItem={fetchItem}
                    handleChange={handleChange}
                    optionsList={props.items}
                    item={item}
                    width={width && width}
                    addAnItemFunc={addAnItemFunc && addAnItemFunc}
                    dropdownTargetId={dropdownTargetId && dropdownTargetId}
                    formCurrentValue={formCurrentValue && formCurrentValue}
                    allowAdd={allowAdd && allowAdd}
                    isDisabled={isDisabled && isDisabled}
                    fontSize={fontSize && fontSize}
                    placeholder={placeholder && placeholder}
                    helperText={helperText}
                    dataCyId={dataCyId}
                    theme={theme && theme}
                    sendId={sendId}
                    isKeyValue={isKeyValue}
                    height={height}
                    borderColor={borderColor}
                />
            ) : null}
        </>
    )
}

export default SelectSearchBarCarInfoMobile
