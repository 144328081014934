import * as React from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../../../redux/store/hooks'
import { IRootState } from '../../../../redux/store'
import { motion } from 'framer-motion'
import { convertToShorterString } from '../../../../helpers/longStrings/convertToShorterString'
import { numberWithCommas } from '../../../../helpers/currencyConverter'
import LoaderElasticThreeDots from '../../loader/loaderElasticThreeDots'
import { IAttachmentItem } from '../../../../redux/attachments/types'
import { ICostItem } from '../../../../redux/timeline/types'

interface IStyled {
    someProp?: any
    $readOnlyMode?: any
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
`
const Container = styled(motion.div)<IStyled>`
    width: 100%;
    box-sizing: border-box;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    border: ${(props) =>
        props.$readOnlyMode
            ? '1px solid var(primary_20)'
            : '1px solid var(--border-muted, #e5e5e5)'};
    border-radius: 8px;
    color: var(--text-strong, #1a1a1a);
    cursor: ${(props) => (props.$readOnlyMode ? 'auto' : 'pointer')};
`

const Row = styled.div<IStyled>`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

const CompanyName = styled.div<IStyled>`
    font-family: 'Lato';
    color: var(--text-muted);
    font-size: 14px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 50%;
`

const FileName = styled.a<IStyled>`
    font-family: 'Lato';
    color: var(--text-muted) !important;
    font-size: 14px;
    text-transform: lowercase;
    max-width: 70%;
    word-break: break-all;
    text-align: right;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`

const CostName = styled.div<IStyled>`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    font-size: 16px;
    text-transform: capitalize;
`

const CostValue = styled.div<IStyled>`
    font-family: 'Lato';
    font-size: 16px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

const IconWrapper = styled.div`
    padding-right: 5px;
    color: var(--text-muted);
`

const HorizLine = styled.div`
    height: 1px;
    width: 50%;
    background-color: var(--border-muted, #e5e5e5);
    margin-top: 7px;
    margin-bottom: 15px;
`

interface Props {
    costItem: ICostItem
    dataCyId?: string
    unit?: string
    attachment?: IAttachmentItem | undefined
    onClick: any
    isCostAttachmentBeingUploaded: boolean
    readOnlyMode?: boolean
    isCostAttachmentRestricted?: boolean
}

const TimelineCostItemDesktop: React.FC<Props> = ({
    dataCyId,
    unit,
    costItem,
    attachment,
    onClick,
    isCostAttachmentBeingUploaded,
    readOnlyMode,
    isCostAttachmentRestricted,
}) => {
    const activeCost = useAppSelector(
        (state: IRootState) => state.timeline.activeCost
    )

    return (
        <Wrapper>
            <Container
                whileTap={
                    !readOnlyMode
                        ? {
                              backgroundColor: `#2a969a1e`,
                          }
                        : {}
                }
                onClick={!readOnlyMode ? onClick : () => {}}
                $readOnlyMode={readOnlyMode}
                data-attr={dataCyId && dataCyId}
            >
                <Row
                    style={{
                        gap: 8,
                        minWidth: 0,
                    }}
                >
                    <CostName>{costItem.name ? costItem.name : ''}</CostName>
                    <CostValue>
                        <IconWrapper>
                            {unit
                                ? unit === 'GBP'
                                    ? `£`
                                    : unit === 'USD'
                                    ? '$'
                                    : unit === 'EUR'
                                    ? '€'
                                    : ''
                                : 'gbp_black'}
                        </IconWrapper>
                        {costItem.amount
                            ? numberWithCommas(costItem.amount)
                            : 0}
                    </CostValue>
                </Row>

                <Row
                    style={{
                        display: readOnlyMode ? 'block' : 'flex',
                        alignItems: 'center',
                        gap: 8,
                        minWidth: 0,
                    }}
                >
                    {readOnlyMode && <HorizLine />}
                    <CompanyName>{costItem.company ?? ''}</CompanyName>
                    {readOnlyMode && <div style={{ paddingTop: '2px' }} />}
                    <FileName
                        href={
                            readOnlyMode && !isCostAttachmentRestricted
                                ? attachment?.url
                                : ``
                        }
                        onClick={(e: any) =>
                            !readOnlyMode ? e.preventDefault() : {}
                        }
                        target="_blank"
                    >
                        {isCostAttachmentRestricted ? (
                            'Attachment is private'
                        ) : isCostAttachmentBeingUploaded &&
                          //   !attachment &&
                          activeCost &&
                          costItem.id === activeCost.id ? (
                            <div style={{ paddingRight: '10px' }}>
                                <LoaderElasticThreeDots />
                            </div>
                        ) : attachment ? (
                            attachment.filename &&
                            convertToShorterString({
                                string: attachment.filename,
                                maxChars: 15,
                                isFileName: true,
                            })
                        ) : (
                            'No attachment'
                        )}
                    </FileName>
                </Row>

                {/* <Separator /> */}
            </Container>
        </Wrapper>
    )
}

export default TimelineCostItemDesktop
