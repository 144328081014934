import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import ButtonsStandardBottomRowDesktop from '../../buttons/buttonsStandardBottomRowDesktop'
import MultipleSelectSearchBarCarInfoDesktop from '../../technicalInformationEditForm/multipleselect/desktop/multipleSelectSearchBarCarInfoDesktop'
import { ITask, ITaskRelationsPayload } from 'entityModels'
import SwitchButton from '../../../../atoms/Button/switchButton'
import { OOtherTaskOptions } from '../../../../../ol/ol'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../providers/theme/hooks'

export const DescriptionHeader = styled.div`
    padding-top: 7px;
    color: var(--text-strong, #121212);
    font-size: 14px;
    text-align: left;
    padding-right: 5vw;
    font-family: Lato-Light;
`

const LargePadding = styled.div`
    padding-top: 50px;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
`

type IstyledToggleItem = {
    isActive?: boolean
}
const ToggleText = styled.div<IstyledToggleItem>`
    padding-left: 16px;
    text-transform: capitalize;
    font-family: lato;
    font-size: 14px;
    letter-spacing: 0px;
    transform: translateY(-4px);
    color: ${(props) => (props.isActive ? '#5EC3CA' : '#757575')};
`

interface Values {
    multiple_values_array: string[]
    linkedToAll?: string
}

interface OtherProps {
    toggle: any
    item: any
    task: ITask
    dropdownData: any
    carsData: any
    garage_id: string
    unlinkTaskRequest: any
    linkTaskRequest: any
    garageCarsIds: string[]
    isEveryCar?: boolean
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        item,
        dropdownData,
        setFieldValue,
        values,
        // errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let new_item = {
        ...item,
        text: 'your cars',
        answers: `${item.answer}    ${
            item.answer && item.answer.length > 1
                ? `+ ${item.answer.length + 1}`
                : ''
        }`,
    }

    let checkIfDisabled = () => {
        if (!values.multiple_values_array && !values.linkedToAll) {
            return true
        } else if (
            values.multiple_values_array &&
            values.multiple_values_array[0] === undefined &&
            !values.linkedToAll
        ) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop $theme={theme}>
            <ModalInnerContentWrapperDesktop>
                <>
                    <ModalTitle $theme={theme} style={{ marginBottom: 0 }}>
                        Update what this Task is applied to:
                    </ModalTitle>

                    <DescriptionHeader>
                        Search specific car(s) or select what applies
                    </DescriptionHeader>
                    <div style={{ paddingTop: '30px' }} />

                    <MultipleSelectSearchBarCarInfoDesktop
                        item={new_item}
                        items={dropdownData}
                        handleSelectChange={(name: string[]) =>
                            setFieldValue('multiple_values_array', name)
                        }
                        isDisabled={
                            values.linkedToAll &&
                            (values.linkedToAll === 'all_cars' ||
                                values.linkedToAll ===
                                    OOtherTaskOptions.general_task.uid)
                                ? true
                                : false
                        }
                        width="100%"
                    />

                    <div style={{ paddingTop: '30px' }} />
                    <Row>
                        <SwitchButton
                            checked={
                                values.linkedToAll ===
                                OOtherTaskOptions.general_task.uid
                                    ? true
                                    : false
                            }
                            toggle={() => {
                                if (
                                    values.linkedToAll !==
                                    OOtherTaskOptions.general_task.uid
                                ) {
                                    setFieldValue(
                                        'linkedToAll',
                                        OOtherTaskOptions.general_task.uid
                                    )
                                } else {
                                    setFieldValue('linkedToAll', undefined)
                                }
                            }}
                            width={34}
                            circleSize={12}
                            height={18}
                        />

                        <ToggleText
                            isActive={
                                values.linkedToAll ===
                                OOtherTaskOptions.general_task.uid
                                    ? true
                                    : false
                            }
                        >
                            {OOtherTaskOptions.general_task.single}
                        </ToggleText>
                    </Row>
                    <div style={{ paddingTop: '10px' }} />
                    <Row>
                        <SwitchButton
                            checked={
                                values.linkedToAll === 'all_cars' ? true : false
                            }
                            toggle={() => {
                                if (values.linkedToAll !== 'all_cars') {
                                    setFieldValue('linkedToAll', 'all_cars')
                                } else {
                                    setFieldValue('linkedToAll', undefined)
                                }
                            }}
                            circleSize={12}
                            height={18}
                            width={34}
                        />

                        <ToggleText
                            isActive={
                                values.linkedToAll === 'all_cars' ? true : false
                            }
                        >
                            Every car
                        </ToggleText>
                    </Row>
                </>
                <LargePadding />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={props.handleSubmit}
                    secondText={'save'}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const SearchNselectCarsTaskDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        multiple_values_array: props.item.answer ? props.item.answer : '-',
        linkedToAll: props.isEveryCar
            ? 'all_cars'
            : props.item.answer[0] === OOtherTaskOptions.general_task.name
            ? OOtherTaskOptions.general_task.uid
            : undefined,
    }),
    // validationSchema: {},
    enableReinitialize: true,

    handleSubmit: (values, { props, setSubmitting }) => {
        let carIdsSelected = (names: string[]) => {
            let car_ids: string[] = []

            let { carsData } = props

            let all_car_ids_list: string[] = Object.keys(carsData).filter(
                (id) => id !== 'new_car'
            )

            for (const carid of all_car_ids_list) {
                if (names.includes(carsData[carid].title)) {
                    car_ids.push(carid)
                }
            }
            return car_ids
        }

        setSubmitting(false)

        if (values.linkedToAll) {
            if (values.linkedToAll === OOtherTaskOptions.general_task.uid) {
                // determine payload
                let unlink_payload: ITaskRelationsPayload = {
                    taskid: props.task.id,
                    car_id: props.task.linkedToCar
                        ? props.task.linkedToCar
                        : [],
                }

                // unlink cars
                if (props.task.linkedToCar) {
                    props.task.linkedToCar[0] !== undefined &&
                        props.unlinkTaskRequest({
                            taskid: unlink_payload.taskid,
                            car_id: unlink_payload.car_id,
                        })
                }
            } else if (values.linkedToAll === 'all_cars') {
                // determine payload
                let link_payload: ITaskRelationsPayload = {
                    taskid: props.task.id,
                    car_id: props.garageCarsIds,
                }

                // link all garage cars
                props.linkTaskRequest({
                    taskid: link_payload.taskid,
                    car_id: link_payload.car_id,
                })
            }
        } else if (values.multiple_values_array) {
            let unlink_payload: ITaskRelationsPayload = {
                taskid: props.task.id,
                car_id: props.task.linkedToCar ? props.task.linkedToCar : [],
            }

            let link_payload: ITaskRelationsPayload = {
                taskid: props.task.id,
                car_id: carIdsSelected(values.multiple_values_array),
            }

            // unlink first
            if (props.task.linkedToCar) {
                props.task.linkedToCar[0] !== undefined &&
                    props.unlinkTaskRequest({
                        taskid: unlink_payload.taskid,
                        car_id: unlink_payload.car_id,
                    })
            }

            if (
                values.multiple_values_array[0] !==
                OOtherTaskOptions.general_task.name
            ) {
                // then link

                setTimeout(function () {
                    props.linkTaskRequest({
                        taskid: link_payload.taskid,
                        car_id: link_payload.car_id,
                    })
                }, 100)
            }
        }

        props.toggle()
    },
})(InnerForm)

export default SearchNselectCarsTaskDesktop
