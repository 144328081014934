import { ITaskNormalisedObject } from 'entityModels'
import styled from 'styled-components'
import task_skeleton_mobile from '../../../atoms/skeleton/tasks/task_skeleton_mobile.png'
import task_skeleton_mobile_dark from '../../../atoms/skeleton/tasks/task_skeleton_mobile_dark.png'
import TaskCardMobile from '../../../atoms/taskCard/taskCardMobile'

import { device } from '../../../templates/displays/devices'
import useThemes from '../../../../providers/theme/hooks'

const Wrapper = styled.section`
    @media ${device.ipad} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin: 0 auto;
        position: relative;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 25px;
    }

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        padding-left: 24px;
        padding-right: 24px;
    }
`

const Wrap = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
`

const SkeletonImg = styled.img`
    height: 252px;
    width: 100%;
    box-sizing: border-box;

    @media ${device.ipad} {
        aspect-ratio: 16/9;
    }
`

interface Props {
    all_tasks: ITaskNormalisedObject
    carsData: any
    updateTask: any
    tasks_ids_to_render: string[]
    toggleCreateTaskForm: any
    garageCars?: any
}

export default function TaskCardsListMobile(props: Props) {
    const checkCarExists = (linkedToCar?: string[], garageCars?: any) => {
        if (linkedToCar && garageCars) {
            if (linkedToCar.length === 1) {
                if (!garageCars.includes(linkedToCar[0])) {
                    return false
                } else return true
            }
        } else return true
    }

    let {
        all_tasks,
        carsData,
        updateTask,
        tasks_ids_to_render,
        // toggleCreateTaskForm,
        garageCars,
    } = props

    const { theme } = useThemes()

    let skeletons = () => (
        <Wrap>
            {[1, 2, 3].map((item) => (
                <SkeletonImg
                    src={
                        theme === 'light'
                            ? task_skeleton_mobile
                            : task_skeleton_mobile_dark
                    }
                />
            ))}
        </Wrap>
    )

    let clean_tasks_ids_to_render: string[] = tasks_ids_to_render?.filter(
        (id) => id !== undefined
    )

    return (
        <Wrapper>
            {clean_tasks_ids_to_render[0] &&
                clean_tasks_ids_to_render.map(
                    (taskID: string, index: number) => {
                        let task = all_tasks[taskID]
                        if (task !== undefined && garageCars !== undefined) {
                            let doesCarExist = checkCarExists(
                                task.linkedToCar,
                                garageCars
                            )

                            if (doesCarExist) {
                                return (
                                    <TaskCardMobile
                                        key={index}
                                        task={all_tasks[taskID]}
                                        carsData={carsData}
                                        updateTask={updateTask}
                                    />
                                )
                            } else return <div style={{ display: 'none' }} />
                        } else if (task !== undefined) {
                            return (
                                <TaskCardMobile
                                    key={index}
                                    task={all_tasks[taskID]}
                                    carsData={carsData}
                                    updateTask={updateTask}
                                />
                            )
                        } else return <div style={{ display: 'none' }} />
                    }
                )}

            {!clean_tasks_ids_to_render[0] && skeletons()}
        </Wrapper>
    )
}
