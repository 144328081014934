import styled from 'styled-components'
import {
    IInsuranceQuoteApplication_MainDriver,
    IInsuranceQuoteApplication_OtherDriver,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { Link } from 'react-router-dom'
import UserPlainIcon from '../../../atoms/icons/components/userPlainIcon'
import { useAppSelector } from '../../../../redux/store/hooks'

const Card = styled(Link)`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--off-bg-color, #fafafa);

    :hover {
        background: rgba(94, 195, 202, 0.04);
    }
`

const Wrapper = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    @media (max-width: 320px) {
        gap: 8px;
    }
`

const Name = styled.h3`
    all: unset;
    color: var(--text-strong, #1a1a1a);
    /* Desktop/Text/T300 Regular */
    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
    max-width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Text = styled.p`
    all: unset;
    color: var(--text-muted, #b3b3b3);
    /* Desktop/Text/T300 Regular */
    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
    width: 100%;
    box-sizing: border-box;
`

const ImageWrapper = styled.div`
    height: 48px;
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--off-bg-color, #fafafa);
`

const CompletionPercentage = styled.div`
    color: var(--text-muted, #b3b3b3);
    /* Desktop/Heading/H300 */
    font-size: 16px;
    font-family: Lato-bold;
    line-height: 24px;
    letter-spacing: 0.001px;
`
const GreyWrapper = styled.div`
    width: 80%;
    display: flex;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--base-overlays-neutral-alpha-100, rgba(26, 26, 26, 0.02));
    :hover {
        background: rgba(94, 195, 202, 0.04);
    }

    @media (max-width: 420px) {
        width: 70%;
    }
`

const EmptyBox = styled.div`
    position: relative;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--off-bg-color, #fafafa);
`

const EmptyLine = styled.div`
    height: 16px;
    background: var(--garage-placeholder-gradient-1, rgba(26, 26, 26, 0.04));
`

type Props = {
    mainDriver?: IInsuranceQuoteApplication_MainDriver
    otherDriver?: IInsuranceQuoteApplication_OtherDriver
    onlyPlaceholder?: boolean
    removeStat?: boolean
}

const InsuranceSingleDriverCardPlain = (props: Props) => {
    let { mainDriver, otherDriver, onlyPlaceholder } = props

    let hasFirstAndLastName =
        (mainDriver?.basic_details?.given_name &&
            mainDriver?.basic_details?.family_name) ||
        (otherDriver?.basic_details?.given_name &&
            otherDriver?.basic_details?.family_name)
            ? true
            : false

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    return !hasFirstAndLastName || onlyPlaceholder ? (
        <EmptyBox>
            <EmptyLine style={{ width: '60%' }} />
            <EmptyLine style={{ width: '30%' }} />
        </EmptyBox>
    ) : mainDriver ? (
        <Card
            to={`/insurance/application/${application?.id}/main_driver/${mainDriver.id}/basic_details`}
        >
            <Name>{`${mainDriver?.basic_details?.given_name ?? 'First_name'} ${
                mainDriver?.basic_details?.family_name ?? ''
            }`}</Name>

            <Text>Policy holder</Text>
        </Card>
    ) : otherDriver ? (
        <Wrapper
            to={`/insurance/application/${application?.id}/other_driver/${otherDriver.id}/basic_details`}
        >
            <ImageWrapper>
                <UserPlainIcon />
            </ImageWrapper>
            <GreyWrapper>
                <Name style={{ maxWidth: '70%' }}>{`${
                    otherDriver.basic_details?.given_name ?? ''
                } ${otherDriver.basic_details?.family_name ?? ''}`}</Name>{' '}
                {!props.removeStat && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                        }}
                    >
                        <CompletionPercentage>
                            {otherDriver.basic_details_stats
                                ? `${otherDriver.basic_details_stats}%`
                                : '0%'}
                        </CompletionPercentage>
                    </div>
                )}
            </GreyWrapper>
        </Wrapper>
    ) : (
        <div />
    )
}

export default InsuranceSingleDriverCardPlain
