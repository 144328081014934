import { useState, useCallback, HTMLAttributes, forwardRef } from 'react'
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
    DragStartEvent,
    DragEndEvent,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
} from '@dnd-kit/sortable'
import SortableImageItem from './sortableImageItem'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import DragIcon from './dragIcon'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import {
    IGalleryImage,
    IGalleryImagesObject,
} from '../../../../redux/entities/galleries/types'

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    background-color: var(--bg-color, #fff);
    width: 100%;
    box-sizing: border-box;
    grid-auto-rows: 1fr;

    @media ${device.mobile_and_ipad} {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2px;
    }
`

export type IGallerySortableItemProps = HTMLAttributes<HTMLDivElement> & {
    id: string
    withOpacity?: boolean
    isDragging?: boolean
    imgObj: IGalleryImage
}

type IStyledProps = {
    isDragging?: boolean
}
const GridItem = styled.div<IStyledProps>`
    position: relative;
    z-index: 1;

    cursor: pointer;
    @media ${device.desktop} {
        height: 26vh;
    }

    @media ${device.large_desktop} {
        height: 28vh;
    }
    @media ${device.smallest_laptops} {
        height: 22vh;
    }
    @media ${device.ipad} {
        height: 18vh;
    }

    @media ${device.beyond_ipad_mobile} {
        min-height: 325px;
    }
    @media ${device.mobile_and_ipad} {
        height: 18vh;
    }

    cursor: ${(props: IStyledProps) =>
        props.isDragging ? 'grabbing' : 'grab'};

    transform: ${(props: IStyledProps) =>
        props.isDragging ? 'scale(1.05)' : 'scale(1)'};
`
interface ImgStyleProps {
    url: string
    $isLoading: boolean
}

const ImageCell = styled.div<ImgStyleProps>`
    background-image: ${(props) => `url(${props.url})`};
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    height: 100%;

    text-align: center;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: ${(props) => (props.$isLoading === true ? 1 : 1)};
    transition: opacity 400ms;
`

const AbsoluteDragIcon = styled.div`
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
`

export const GallerySortableItem = forwardRef<
    HTMLDivElement,
    IGallerySortableItemProps
>(({ id, withOpacity, isDragging, style, ...props }, ref) => {
    return (
        <GridItem key={id} isDragging={isDragging} ref={ref} {...props}>
            <ImageCell
                role="img"
                aria-label="car overview image"
                url={props.imgObj.url}
                $isLoading={false}
            ></ImageCell>
            <AbsoluteDragIcon>
                <DragIcon />
            </AbsoluteDragIcon>
        </GridItem>
    )
})

type Props = {
    ids_list: string[]
    img_data: IGalleryImagesObject
    setSelectedImages: (p: string[]) => any
}

const GallerySortableManager = (props: Props) => {
    const [items, setItems] = useState(props.ids_list)

    const [activeId, setActiveId] = useState<string | null>(null)

    const size: WindowSize = useWindowSize()

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: size && size.width && size.width > 800 ? 250 : 10,
                tolerance: 5,
            },
        })
    )

    const handleDragStart = (event: DragStartEvent) => {
        //@ts-ignore
        setActiveId(event.active.id)
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event

        if (active.id !== over?.id) {
            let newItems = () => {
                //@ts-ignore
                const oldIndex = items.indexOf(active.id)
                //@ts-ignore
                const newIndex = items.indexOf(over!.id)

                return arrayMove(items, oldIndex, newIndex)
            }

            let newItemsVal = newItems()

            setItems(newItemsVal)
            // can also just be called on 'save changes'
            props.setSelectedImages(newItemsVal)
        }

        setActiveId(null)
    }

    const handleDragCancel = useCallback(() => {
        setActiveId(null)
    }, [])

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={items} strategy={rectSortingStrategy}>
                <GridContainer>
                    {items.map((id) => {
                        let img: IGalleryImage = props.img_data[id]

                        return (
                            <SortableImageItem imgObj={img} key={id} id={id} />
                        )
                    })}
                </GridContainer>
            </SortableContext>
            <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                {activeId ? (
                    <GallerySortableItem
                        id={activeId}
                        imgObj={props.img_data[activeId]}
                        isDragging
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    )
}

export default GallerySortableManager
