import React, { useEffect, useState } from 'react'
import { getApexHomepageDataThunks } from '../../../redux/theapex/thunks'

import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import SliderApexHomepageDesktop from '../../molecules/swipeNslide/theme2/sliderApexHomepageDesktop'
import SliderApexHomepageMobile from '../../molecules/swipeNslide/theme2/sliderApexHomepageMobile'

import styled from 'styled-components'
import ApexPageWrapper from '../../templates/displays/pageWrappers/apexPageWrapper'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { device } from '../../templates/displays/devices'
import ApexHomepageArticleDesktop from '../../molecules/apex/homepage/desktop/apexHomepageArticleDesktop'
import WeeklyEventsBoxDesktop from '../../molecules/apex/homepage/desktop/weeklyEventsBoxDesktop'
import WeeklyApexQuoteDesktop from '../../molecules/apex/homepage/desktop/weeklyApexQuoteDesktop'
import ApexCarOfTheWeekHomepage from '../../molecules/apex/homepage/desktop/apexCarOfTheWeekHomepage'
import ApexPodcastsHomeListMobile from '../../molecules/apex/homepage/mobile/apexPodcastsHomeListMobile'
import ApexHomepageArticleMobile from '../../molecules/apex/homepage/mobile/apexHomepageArticleMobile'
import ApexHomepageCarOfTheWeekMobile from '../../molecules/apex/homepage/mobile/apexHomepageCarOfTheWeekMobile'
import ApexInterviewCategoryHeaderAtom from '../../atoms/header/apex/apexInterviewCategoryHeader'
import ApexInterviewOverviewsManagerDesktop from '../../molecules/apex/reusable/interviewOverviews/desktop/apexInterviewsOverviewManagerDesktop'
import ApexInterviewsOverviewManagerMobile from '../../molecules/apex/reusable/interviewOverviews/mobile/apexInterviewsOverviewManagerMobile'
import { Link } from 'react-router-dom'
import LoaderBackground from '../../atoms/loader/loaderBackground'
import { Waypoint } from 'react-waypoint'
import { subNavigationActions } from '../../../redux/localdata/subPageNavigation/reducer'
import AndroidInstallPwaInstructionsPropmt from '../../templates/pwaCustom/androidInstallPwaInstructionsPropmt'
import './styles.css'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { getCookie } from '../../../redux/services/authcheck'
import {
    useAppDispatch,
    useAppSelector,
    useThunkAppDispatch,
} from '../../../redux/store/hooks'
import { IRootState } from '../../../redux/store'
import { IApexHomepageArticleState } from 'apexModels'

const SectionsWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const OneBigRowDesktop = styled.div`
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    @media ${device.large_desktop} {
        width: 72vw;
    }
`

const TopSectionRowsDesktop = styled.div`
    padding-top: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 95%;
    padding-right: 30px;
    height: 100%;
    width: 70vw;
    /* background-color: blue; */

    @media ${device.smallest_laptops} {
        padding-top: 58px;
        width: 60vw;
    }
    @media ${device.large_desktop} {
        padding-top: 60px;
        width: 50vw;
    }

    @media ${device.ipad} {
        justify-content: flex-start;
        padding-bottom: 50px;
    }
`

const TopSectionRowsDesktopArticles = styled.div`
    padding-top: 60px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    padding-right: 30px;
    height: 100%;
    width: 70vw;
    /* background-color: blue; */

    @media ${device.smallest_laptops} {
        padding-top: 58px;
        width: 63vw;
    }
    @media ${device.large_desktop} {
        padding-top: 60px;
        width: 50vw;
    }

    @media ${device.ipad} {
        justify-content: flex-start;
        padding-bottom: 50px;
        width: 100%;
        box-sizing: border-box;
    }
`

const SideApexCTAWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;

    @media ${device.large_desktop} {
        width: 98%;
    }
`

const Column1Desktop = styled.div`
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 3;

    @media ${device.ipad} {
        /* width: 300px; */
        width: 60%;
    }
`
const Column2Desktop = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    width: 300px;

    @media ${device.large_desktop} {
        width: 350px;
    }

    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
    justify-self: flex-start;

    @media ${device.ipad} {
        /* width: 252px; */
        width: 30%;
    }
`

const ApexSectionTitle = styled.div`
    color: var(--primary, #5ec3ca);
    font-family: Lato-Light;
    text-transform: uppercase;

    font-size: 32px;

    @media ${device.mobile_and_ipad} {
        font-size: 20px;
    }

    @media (min-width: 500px) {
        text-align: center;
    }

    @media ${device.beyond_ipad_mobile} {
        text-align: left;
    }

    @media ${device.large_desktop} {
        font-size: 36px;
    }
`

const ApexSectionSubtitle = styled.div`
    padding-top: 10px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Light;
    font-size: 18px;
    max-width: 500px;

    @media ${device.mobile_and_ipad} {
        font-size: 16px;
        max-width: auto;
    }

    @media (max-width: 330px) {
        font-size: 15px;
    }

    @media ${device.smallest_laptops} {
        font-size: 17px;
    }

    @media ${device.large_desktop} {
        font-size: 21px;
        max-width: 700px;
    }

    @media (min-width: 500px) {
        text-align: center;
        padding-bottom: 30px;
    }

    @media ${device.beyond_ipad_mobile} {
        text-align: left;
    }
`

const ApexSectionSubtitleCentered = styled.div`
    text-align: center;
    padding-top: 10px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Light;
    font-size: 18px;
    max-width: 500px;

    @media ${device.smallest_laptops} {
        font-size: 17px;
    }

    @media ${device.large_desktop} {
        font-size: 21px;
        max-width: 500px;
    }
`

const PodcastElemDesktop = styled.iframe`
    height: 232px;
    width: 96%;
    margin-right: 20px;

    @media ${device.smallest_laptops} {
        width: 125%;
    }

    @media ${device.ipad} {
        height: 200px;
    }

    @media screen and (max-width: 950px) {
    }

    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        height: 242px;
    }
`

const SideApexCTALine = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-color: #5ec3ca;
    height: 1px;
    margin-right: 30px;

    @media ${device.large_desktop} {
        margin-right: 50px;
    }
`

const RightApexCTALine = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--primary, #5ec3ca);
    height: 1px;
    margin-left: 30px;

    @media ${device.large_desktop} {
        margin-left: 50px;
    }
`
const ApexCTABtn = styled(Link)`
    :focus {
        outline: 0;
    }

    cursor: pointer;
    transition: all 200ms;

    :hover {
        box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.09);
        transition: all 200ms;

        background-color: var(--primary, #5ec3ca);

        color: var(--bg-color, #fff) !important;
    }

    background-color: var(--bg-color, #fff);
    border: 1px solid var(--primary, #5ec3ca);
    color: var(--primary, #5ec3ca) !important;
    font-family: Lato;
    text-transform: uppercase;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    min-width: 200px;

    font-size: 14px;
    height: 54px;

    @media ${device.large_desktop} {
        min-width: 300px;
        font-size: 15px;
        height: 60px;
    }
`

const SeparationDesktopTopSection = styled.div`
    padding-top: 44px;

    @media ${device.smallest_laptops} {
        padding-top: 40px;
    }

    @media ${device.large_desktop} {
        padding-top: 60px;
    }
`

const CustomPaddingDesktop1 = styled.div`
    padding-top: 60px;

    @media ${device.large_desktop} {
        padding-top: 100px;
    }
`

const BtnPaddingDesktop = styled.div`
    margin-right: 40px;
    @media ${device.large_desktop} {
        padding-right: 20px;
    }
`

const InterviewsRowDesktop = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

const ColumnInterviewsDesktop = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    @media ${device.ipad} {
        padding-bottom: 56px;
    }
`

const MobileContentPadding = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 26px;
    padding-right: 10vw;

    @media ${device.ipad} {
        padding-left: 10vw;
        padding-right: 10vw;
    }
    @media (min-width: 500px) {
        align-items: center;
        justify-content: center;
    }
`

const MobileCTAWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: 100vw; */
    width: 100%;
    box-sizing: border-box;
    padding-left: 22px;
    padding-right: 0px;
    text-align: center;
`

const MobileCTALine = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--border-muted, #ececec);
    height: 1px;
`

const TestLoader = styled.div`
    height: 100vh;
    width: 100%;
    box-sizing: border-box;

    @media ${device.mobile_and_ipad} {
        height: 100vh;
    }
`

const DesktopPadding3 = styled.div`
    padding-top: 80px;
    @media ${device.ipad} {
        padding-top: 0px;
    }
`
const DesktopPadding4 = styled.div`
    padding-top: 80px;
`
type IWidth = {
    width?: string
}
const ApexCTABtnMobile = styled(Link)<IWidth>`
    :focus {
        outline: 0;
    }

    padding-left: 10px;
    background: none;
    border: none;

    font-family: Lato;
    text-transform: uppercase;
    font-size: 14px;
    width: ${(props) => (props.width ? props.width : '195px')};

    @media (max-width: 330px) {
        font-size: 12px;
    }

    color: var(--primary, #5ec3ca) !important;
`

const PodcastRowDesktop = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 50px;

    @media ${device.smallest_laptops} {
        grid-column-gap: 108px;
        margin-bottom: 30px;
    }
`

export const checkForIpad = () => {
    const ua = window.navigator.userAgent
    const isIPad =
        !!ua.match(/Mac/i) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2
    const isIPhone = !!ua.match(/iPhone/i)
    const isIPadAndNotIphone = isIPad && !isIPhone
    return isIPadAndNotIphone
}

const PaddingIpad = styled.div`
    padding-top: ${checkForIpad() ? '55px' : '110px'};
`

const ApexOverview = () => {
    const dispatch = useAppDispatch()
    const dispatchThunks = useThunkAppDispatch()

    useEffect(() => {
        dispatchThunks(getApexHomepageDataThunks())
        dispatch(
            subNavigationActions.setCurrentVisitedSectionID(
                'mobile-apex-podcasts-hp'
            )
        )
    }, [])

    const {
        slideshow,
        podcasts,
        articles,
        events,
        weeklyQuote,
        carOfTheWeek,
        interviewCategoryHighlights,
        overviewPageLocalCopy,
    } = useAppSelector((state: IRootState) => ({
        slideshow: state.apex.homepageData && state.apex.homepageData.slideshow,
        podcasts: state.apex.homepageData && state.apex.homepageData.podcasts,
        articles: state.apex.homepageData && state.apex.homepageData.articles,
        events: state.apex.homepageData && state.apex.homepageData.weeklyEvents,
        weeklyQuote:
            state.apex.homepageData && state.apex.homepageData.weeklyQuote,
        carOfTheWeek:
            state.apex.homepageData && state.apex.homepageData.carOfTheWeek,
        interviewCategoryHighlights:
            state.apex.homepageData &&
            state.apex.homepageData.interviewCategoryHighlights,
        overviewPageLocalCopy: state.localdata.apex_local_copy.overviewPage,
    }))
    //create an array to store the embed urls in it

    // //  loop through the podcasts and get the spotify urls and create the correct spotify URL https://open.spotify.com/embed/episode/3AnL7BmcheJH1oGLlcmPGB
    // let podcastss = podcasts?.forEach((podcast) => {
    //     // This is the url structure for spotify embeds: https://open.spotify.com/embed/episode/3AnL7BmcheJH1oGLlcmPGB

    //     const spotifyId = podcast?.spotify_url?.split('/').pop()

    //     // create the spotify embed URL
    //     const spotifyEmbedUrl = `https://open.spotify.com/embed/episode/${spotifyId}`
    //     spotifyEmbedUrls.push(spotifyEmbedUrl)
    // })

    // Move this outside the render to avoid unnecessary recalculation

    const [spotifyEmbedUrls, setSpotifyEmbedUrls] = useState<string[]>([])

    useEffect(() => {
        if (podcasts) {
            let arr = podcasts.map((podcast) => {
                const spotifyId = podcast?.spotify_url?.split('/').pop()
                return `https://open.spotify.com/embed/episode/${spotifyId}`
            })
            setSpotifyEmbedUrls(arr)
        }
    }, [podcasts])

    return (
        <React.Fragment>
            <FabsMenusMobile hasAdd={false} hasSubNav={true} />

            {/* {getCookie('ios_install_prompt') !== 'seen' && (
                    <InstallIosPropmt />
                )} */}

            {getCookie('android_install_prompt') !== 'seen' && (
                <AndroidInstallPwaInstructionsPropmt />
            )}

            <ApexPageWrapper isLoading={slideshow ? false : true}>
                <DesktopDisplayOnly>
                    {/* {events !== undefined &&
                            weeklyQuote !== undefined &&
                            carOfTheWeek !== undefined &&
                            weeklyNewsHighlights !== undefined &&
                            interviewCategoryHighlights !== undefined && ( */}
                    {!slideshow && (
                        <TestLoader>
                            <div
                                id="loading-init-apex"
                                className="loader-wrapper-inpage "
                            >
                                <span className="loader">
                                    <span className="loader-inner"></span>
                                </span>

                                <div
                                    className="loading-text"
                                    style={{ fontSize: '14px' }}
                                >
                                    THE APEX BY CUSTODIAN
                                </div>

                                <div
                                    className="loading-text-lighter"
                                    style={{ fontSize: '14px' }}
                                >
                                    Loading...
                                </div>
                            </div>{' '}
                        </TestLoader>
                    )}
                    {slideshow && (
                        <SectionsWrap>
                            <SliderApexHomepageDesktop slides={slideshow} />

                            <OneBigRowDesktop>
                                <Column1Desktop>
                                    <ApexSectionTitle>
                                        {
                                            overviewPageLocalCopy.latestPodcasts
                                                .title
                                        }
                                    </ApexSectionTitle>
                                    <ApexSectionSubtitle>
                                        {
                                            overviewPageLocalCopy.latestPodcasts
                                                .subTitle
                                        }
                                    </ApexSectionSubtitle>

                                    {!podcasts && (
                                        <>
                                            <TopSectionRowsDesktop>
                                                <LoaderBackground
                                                    height={'230px'}
                                                    width={'30vw'}
                                                />

                                                <div
                                                    style={{
                                                        paddingLeft: '50px',
                                                    }}
                                                />

                                                <LoaderBackground
                                                    height={'230px'}
                                                    width={'30vw'}
                                                />
                                            </TopSectionRowsDesktop>

                                            <div
                                                style={{
                                                    marginTop: '60px',
                                                }}
                                            />
                                        </>
                                    )}

                                    {podcasts && (
                                        <TopSectionRowsDesktop>
                                            <PodcastRowDesktop>
                                                {podcasts &&
                                                    podcasts[0] &&
                                                    spotifyEmbedUrls[0] && (
                                                        <PodcastElemDesktop
                                                            title="podcast 1"
                                                            src={
                                                                spotifyEmbedUrls[0]
                                                                    ? spotifyEmbedUrls[0]
                                                                    : ''
                                                            }
                                                            frameBorder={0}
                                                            allowTransparency={
                                                                true
                                                            }
                                                            allow="encrypted-media"
                                                        ></PodcastElemDesktop>
                                                    )}
                                                {podcasts &&
                                                    podcasts[1] &&
                                                    spotifyEmbedUrls[1] && (
                                                        <PodcastElemDesktop
                                                            title="podcast 1"
                                                            src={
                                                                spotifyEmbedUrls[1]
                                                                    ? spotifyEmbedUrls[1]
                                                                    : ''
                                                            }
                                                            frameBorder={0}
                                                            allowTransparency={
                                                                true
                                                            }
                                                            allow="encrypted-media"
                                                        ></PodcastElemDesktop>
                                                    )}
                                                {podcasts &&
                                                    podcasts[2] &&
                                                    spotifyEmbedUrls[2] && (
                                                        <div
                                                            style={{
                                                                gridColumnStart: 1,
                                                                gridRowStart: 2,
                                                            }}
                                                        >
                                                            <PodcastElemDesktop
                                                                title="podcast 1"
                                                                src={
                                                                    spotifyEmbedUrls[2]
                                                                        ? spotifyEmbedUrls[2]
                                                                        : ''
                                                                }
                                                                frameBorder={0}
                                                                allowTransparency={
                                                                    true
                                                                }
                                                                allow="encrypted-media"
                                                            ></PodcastElemDesktop>
                                                        </div>
                                                    )}
                                                {podcasts &&
                                                    podcasts[3] &&
                                                    spotifyEmbedUrls[3] && (
                                                        <div
                                                            style={{
                                                                gridColumnStart: 2,
                                                                gridRowStart: 2,
                                                            }}
                                                        >
                                                            <PodcastElemDesktop
                                                                title="podcast 1"
                                                                src={
                                                                    spotifyEmbedUrls[3]
                                                                        ? spotifyEmbedUrls[3]
                                                                        : ''
                                                                }
                                                                frameBorder={0}
                                                                allowTransparency={
                                                                    true
                                                                }
                                                                allow="encrypted-media"
                                                            ></PodcastElemDesktop>
                                                        </div>
                                                    )}
                                            </PodcastRowDesktop>
                                        </TopSectionRowsDesktop>
                                    )}

                                    <SideApexCTAWrap>
                                        <SideApexCTALine />
                                        <ApexCTABtn to={`/apex/podcasts`}>
                                            browse all
                                        </ApexCTABtn>

                                        <BtnPaddingDesktop />
                                    </SideApexCTAWrap>
                                    <SeparationDesktopTopSection />
                                    <ApexSectionTitle>
                                        {
                                            overviewPageLocalCopy.latestArticles
                                                .title
                                        }
                                    </ApexSectionTitle>
                                    <ApexSectionSubtitle>
                                        {
                                            overviewPageLocalCopy.latestArticles
                                                .subTitle
                                        }
                                    </ApexSectionSubtitle>

                                    {!articles && (
                                        <TopSectionRowsDesktop>
                                            <LoaderBackground
                                                height={'500px'}
                                                width={'30vw'}
                                            />

                                            <LoaderBackground
                                                height={'500px'}
                                                width={'30vw'}
                                            />
                                        </TopSectionRowsDesktop>
                                    )}

                                    <TopSectionRowsDesktopArticles>
                                        {articles &&
                                            articles.map(
                                                (
                                                    article: IApexHomepageArticleState,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <>
                                                            <ApexHomepageArticleDesktop
                                                                article={
                                                                    article
                                                                }
                                                                key={index}
                                                            />

                                                            <div
                                                                style={{
                                                                    paddingRight:
                                                                        index ===
                                                                        0
                                                                            ? '30px'
                                                                            : 'auto',
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                            )}
                                    </TopSectionRowsDesktopArticles>

                                    <div style={{ paddingTop: '46px' }} />

                                    <SideApexCTAWrap>
                                        <SideApexCTALine />
                                        <ApexCTABtn to={`/apex/articles`}>
                                            discover more
                                        </ApexCTABtn>

                                        <BtnPaddingDesktop />
                                    </SideApexCTAWrap>
                                </Column1Desktop>
                                <Column2Desktop>
                                    <WeeklyEventsBoxDesktop events={events} />

                                    <div style={{ paddingTop: '32px' }} />

                                    <WeeklyApexQuoteDesktop
                                        quote={weeklyQuote}
                                    />
                                </Column2Desktop>
                            </OneBigRowDesktop>
                            <div style={{ paddingTop: '110px' }} />
                            <ApexSectionTitle>
                                {overviewPageLocalCopy.carOfTheWeek.title}
                            </ApexSectionTitle>
                            <ApexSectionSubtitleCentered>
                                {overviewPageLocalCopy.carOfTheWeek.subTitle}
                            </ApexSectionSubtitleCentered>

                            <ApexCarOfTheWeekHomepage car={carOfTheWeek} />

                            <CustomPaddingDesktop1 />
                            <SideApexCTAWrap>
                                <SideApexCTALine />
                                <ApexCTABtn to={`/apex/cars-of-the-week`}>
                                    see previous picks
                                </ApexCTABtn>
                                <RightApexCTALine />
                            </SideApexCTAWrap>
                            <div style={{ paddingTop: '110px' }} />

                            <div style={{ paddingTop: '110px' }} />
                            <ApexSectionTitle>
                                {overviewPageLocalCopy.timelessInterviews.title}
                            </ApexSectionTitle>
                            <ApexSectionSubtitleCentered>
                                {
                                    overviewPageLocalCopy.timelessInterviews
                                        .subTitle
                                }
                            </ApexSectionSubtitleCentered>
                            <DesktopPadding4 />
                            <InterviewsRowDesktop>
                                <ColumnInterviewsDesktop>
                                    {!interviewCategoryHighlights && (
                                        <>
                                            <LoaderBackground
                                                height={'500px'}
                                                width={'100%'}
                                            />
                                            <LoaderBackground
                                                height={'500px'}
                                                width={'100%'}
                                            />
                                        </>
                                    )}

                                    {interviewCategoryHighlights &&
                                        interviewCategoryHighlights[0] &&
                                        interviewCategoryHighlights[0]
                                            .category && (
                                            <ApexInterviewCategoryHeaderAtom
                                                name={
                                                    interviewCategoryHighlights[0]
                                                        .category
                                                }
                                            />
                                        )}
                                    <div style={{ paddingTop: '30px' }} />

                                    {interviewCategoryHighlights &&
                                        interviewCategoryHighlights[0] &&
                                        interviewCategoryHighlights[0]
                                            .items && (
                                            <ApexInterviewOverviewsManagerDesktop
                                                interview_overviews={
                                                    interviewCategoryHighlights[0] &&
                                                    interviewCategoryHighlights[0]
                                                        .items
                                                }
                                            />
                                        )}
                                </ColumnInterviewsDesktop>
                                <ColumnInterviewsDesktop>
                                    {!interviewCategoryHighlights && (
                                        <>
                                            <LoaderBackground
                                                height={'500px'}
                                                width={'100%'}
                                            />
                                            <LoaderBackground
                                                height={'500px'}
                                                width={'100%'}
                                            />
                                        </>
                                    )}
                                    {interviewCategoryHighlights &&
                                        interviewCategoryHighlights[1] &&
                                        interviewCategoryHighlights[1]
                                            .category && (
                                            <ApexInterviewCategoryHeaderAtom
                                                name={
                                                    interviewCategoryHighlights[1]
                                                        .category
                                                }
                                            />
                                        )}
                                    <div style={{ paddingTop: '30px' }} />

                                    {interviewCategoryHighlights &&
                                        interviewCategoryHighlights[1] &&
                                        interviewCategoryHighlights[1]
                                            .items && (
                                            <ApexInterviewOverviewsManagerDesktop
                                                interview_overviews={
                                                    interviewCategoryHighlights[1] &&
                                                    interviewCategoryHighlights[1]
                                                        .items
                                                }
                                            />
                                        )}
                                </ColumnInterviewsDesktop>
                            </InterviewsRowDesktop>
                            <DesktopPadding3 />
                            <InterviewsRowDesktop>
                                <ColumnInterviewsDesktop>
                                    {!interviewCategoryHighlights && (
                                        <>
                                            <LoaderBackground
                                                height={'500px'}
                                                width={'100%'}
                                            />
                                            <LoaderBackground
                                                height={'500px'}
                                                width={'100%'}
                                            />
                                        </>
                                    )}

                                    {interviewCategoryHighlights &&
                                        interviewCategoryHighlights[2] &&
                                        interviewCategoryHighlights[2]
                                            .category && (
                                            <ApexInterviewCategoryHeaderAtom
                                                name={
                                                    interviewCategoryHighlights[2]
                                                        .category
                                                }
                                            />
                                        )}

                                    <div style={{ paddingTop: '30px' }} />

                                    {interviewCategoryHighlights &&
                                        interviewCategoryHighlights[2] &&
                                        interviewCategoryHighlights[2]
                                            .items && (
                                            <ApexInterviewOverviewsManagerDesktop
                                                interview_overviews={
                                                    interviewCategoryHighlights[2] &&
                                                    interviewCategoryHighlights[2]
                                                        .items
                                                }
                                            />
                                        )}
                                </ColumnInterviewsDesktop>
                                <ColumnInterviewsDesktop>
                                    {!interviewCategoryHighlights && (
                                        <>
                                            <LoaderBackground
                                                height={'500px'}
                                                width={'100%'}
                                            />
                                            <LoaderBackground
                                                height={'500px'}
                                                width={'100%'}
                                            />
                                        </>
                                    )}
                                    {interviewCategoryHighlights &&
                                        interviewCategoryHighlights[3] &&
                                        interviewCategoryHighlights[3]
                                            .category && (
                                            <ApexInterviewCategoryHeaderAtom
                                                name={
                                                    interviewCategoryHighlights[3]
                                                        .category
                                                }
                                            />
                                        )}

                                    <div style={{ paddingTop: '30px' }} />
                                    {interviewCategoryHighlights &&
                                        interviewCategoryHighlights[3] &&
                                        interviewCategoryHighlights[3]
                                            .items && (
                                            <ApexInterviewOverviewsManagerDesktop
                                                interview_overviews={
                                                    interviewCategoryHighlights[3] &&
                                                    interviewCategoryHighlights[3]
                                                        .items
                                                }
                                            />
                                        )}
                                </ColumnInterviewsDesktop>
                            </InterviewsRowDesktop>

                            <div style={{ paddingTop: '90px' }} />

                            <SideApexCTAWrap>
                                <SideApexCTALine />
                                <ApexCTABtn to={`/apex/interviews`}>
                                    discover all
                                </ApexCTABtn>
                                <RightApexCTALine />
                            </SideApexCTAWrap>
                            <div style={{ paddingBottom: '90px' }} />
                            {/* )} */}
                        </SectionsWrap>
                    )}
                </DesktopDisplayOnly>

                <IpadAndMobileDisplay>
                    {!slideshow && (
                        <TestLoader>
                            <div
                                id="loading-init-apex"
                                className="loader-wrapper-inpage "
                            >
                                <span className="loader">
                                    <span className="loader-inner"></span>
                                </span>

                                <div
                                    className="loading-text"
                                    style={{ fontSize: '11px' }}
                                >
                                    THE APEX BY CUSTODIAN
                                </div>

                                <div
                                    className="loading-text-lighter"
                                    style={{ fontSize: '11px' }}
                                >
                                    Loading...
                                </div>
                            </div>{' '}
                        </TestLoader>
                    )}

                    {slideshow && (
                        <>
                            <PaddingIpad />

                            <SliderApexHomepageMobile slides={slideshow} />

                            <div
                                style={{ paddingTop: '40px' }}
                                id="mobile-apex-podcasts-hp"
                            >
                                <MobileContentPadding>
                                    <ApexSectionTitle>
                                        {
                                            overviewPageLocalCopy.latestPodcasts
                                                .title
                                        }
                                    </ApexSectionTitle>
                                    <ApexSectionSubtitle>
                                        {
                                            overviewPageLocalCopy.latestPodcasts
                                                .subTitle
                                        }
                                    </ApexSectionSubtitle>
                                </MobileContentPadding>

                                <div style={{ paddingTop: '35px' }} />
                                <ApexPodcastsHomeListMobile
                                    podcasts_list={podcasts}
                                />
                                <div style={{ paddingTop: '20px' }} />
                                <Waypoint
                                    onEnter={() => {
                                        dispatch(
                                            subNavigationActions.setCurrentVisitedSectionID(
                                                'mobile-apex-podcasts-hp'
                                            )
                                        )
                                    }}
                                >
                                    <MobileCTAWrap>
                                        <MobileCTALine />
                                        <ApexCTABtnMobile to={`/apex/podcasts`}>
                                            Browse all
                                        </ApexCTABtnMobile>
                                    </MobileCTAWrap>
                                </Waypoint>
                            </div>

                            <div
                                style={{ paddingTop: '50px' }}
                                id="mobile-apex-articles-hp"
                            />

                            <MobileContentPadding>
                                <ApexSectionTitle>
                                    {overviewPageLocalCopy.latestArticles.title}
                                </ApexSectionTitle>
                                <ApexSectionSubtitle>
                                    {
                                        overviewPageLocalCopy.latestArticles
                                            .subTitle
                                    }
                                </ApexSectionSubtitle>
                            </MobileContentPadding>
                            <div style={{ paddingTop: '35px' }} />
                            {articles && (
                                <ApexHomepageArticleMobile
                                    article={articles[0]}
                                />
                            )}
                            <div style={{ paddingTop: '22px' }} />
                            <Waypoint
                                onEnter={() => {
                                    dispatch(
                                        subNavigationActions.setCurrentVisitedSectionID(
                                            'mobile-apex-articles-hp'
                                        )
                                    )
                                }}
                            >
                                <MobileCTAWrap>
                                    <MobileCTALine />
                                    <ApexCTABtnMobile to={`apex/articles`}>
                                        Browse all
                                    </ApexCTABtnMobile>
                                </MobileCTAWrap>
                            </Waypoint>
                            <div
                                style={{ paddingTop: '50px' }}
                                id="mobile-apex-cotw-hp"
                            />
                            <MobileContentPadding>
                                <ApexSectionTitle>
                                    {overviewPageLocalCopy.carOfTheWeek.title}
                                </ApexSectionTitle>
                                <ApexSectionSubtitle>
                                    {
                                        overviewPageLocalCopy.carOfTheWeek
                                            .subTitle
                                    }
                                </ApexSectionSubtitle>
                            </MobileContentPadding>
                            <div style={{ paddingTop: '35px' }} />
                            <ApexHomepageCarOfTheWeekMobile
                                car={carOfTheWeek}
                            />
                            <div style={{ paddingTop: '22px' }} />
                            <Waypoint
                                onEnter={() => {
                                    dispatch(
                                        subNavigationActions.setCurrentVisitedSectionID(
                                            'mobile-apex-cotw-hp'
                                        )
                                    )
                                }}
                            >
                                <MobileCTAWrap>
                                    <MobileCTALine />
                                    <ApexCTABtnMobile
                                        to={`apex/cars-of-the-week`}
                                        width={'280px'}
                                    >
                                        See previous picks
                                    </ApexCTABtnMobile>
                                    <div style={{ paddingRight: '30px' }} />
                                </MobileCTAWrap>
                            </Waypoint>

                            <div
                                style={{ paddingTop: '48px' }}
                                id="mobile-apex-interviews-hp"
                            />
                            <MobileContentPadding>
                                <ApexSectionTitle>
                                    {
                                        overviewPageLocalCopy.timelessInterviews
                                            .title
                                    }
                                </ApexSectionTitle>
                                <ApexSectionSubtitle>
                                    {
                                        overviewPageLocalCopy.timelessInterviews
                                            .subTitle
                                    }
                                </ApexSectionSubtitle>
                                <div style={{ paddingTop: '66px' }} />

                                {!interviewCategoryHighlights && (
                                    <>
                                        <LoaderBackground
                                            height={'300px'}
                                            width={'100%'}
                                        />
                                        <LoaderBackground
                                            height={'300px'}
                                            width={'100%'}
                                        />
                                        <LoaderBackground
                                            height={'300px'}
                                            width={'100%'}
                                        />
                                        <LoaderBackground
                                            height={'300px'}
                                            width={'100%'}
                                        />
                                    </>
                                )}
                                <Waypoint
                                    onEnter={() => {
                                        dispatch(
                                            subNavigationActions.setCurrentVisitedSectionID(
                                                'mobile-apex-interviews-hp'
                                            )
                                        )
                                    }}
                                >
                                    <div>
                                        {interviewCategoryHighlights &&
                                            interviewCategoryHighlights[0] &&
                                            interviewCategoryHighlights[0]
                                                .category && (
                                                <ApexInterviewCategoryHeaderAtom
                                                    name={
                                                        interviewCategoryHighlights[0]
                                                            .category
                                                    }
                                                />
                                            )}
                                    </div>
                                </Waypoint>
                                {interviewCategoryHighlights &&
                                    interviewCategoryHighlights[0] &&
                                    interviewCategoryHighlights[0].items && (
                                        <ApexInterviewsOverviewManagerMobile
                                            interview_overviews={
                                                interviewCategoryHighlights[0]
                                                    .items
                                            }
                                        />
                                    )}

                                <div style={{ paddingTop: '66px' }} />
                                {interviewCategoryHighlights &&
                                    interviewCategoryHighlights[1] &&
                                    interviewCategoryHighlights[1].category && (
                                        <ApexInterviewCategoryHeaderAtom
                                            name={
                                                interviewCategoryHighlights[1]
                                                    .category
                                            }
                                        />
                                    )}

                                {interviewCategoryHighlights &&
                                    interviewCategoryHighlights[1] &&
                                    interviewCategoryHighlights[1].items && (
                                        <ApexInterviewsOverviewManagerMobile
                                            interview_overviews={
                                                interviewCategoryHighlights[1]
                                                    .items
                                            }
                                        />
                                    )}

                                <div style={{ paddingTop: '66px' }} />
                                {interviewCategoryHighlights &&
                                    interviewCategoryHighlights[2] &&
                                    interviewCategoryHighlights[2].category && (
                                        <ApexInterviewCategoryHeaderAtom
                                            name={
                                                interviewCategoryHighlights[2]
                                                    .category
                                            }
                                        />
                                    )}

                                {interviewCategoryHighlights &&
                                    interviewCategoryHighlights[2] &&
                                    interviewCategoryHighlights[2].items && (
                                        <ApexInterviewsOverviewManagerMobile
                                            interview_overviews={
                                                interviewCategoryHighlights[2]
                                                    .items
                                            }
                                        />
                                    )}

                                <div style={{ paddingTop: '66px' }} />
                                {interviewCategoryHighlights &&
                                    interviewCategoryHighlights[3] &&
                                    interviewCategoryHighlights[3].category && (
                                        <ApexInterviewCategoryHeaderAtom
                                            name={
                                                interviewCategoryHighlights[3]
                                                    .category
                                            }
                                        />
                                    )}

                                {interviewCategoryHighlights &&
                                    interviewCategoryHighlights[3] &&
                                    interviewCategoryHighlights[3].items && (
                                        <ApexInterviewsOverviewManagerMobile
                                            interview_overviews={
                                                interviewCategoryHighlights[3]
                                                    .items
                                            }
                                        />
                                    )}
                            </MobileContentPadding>
                            <div style={{ paddingTop: '40px' }} />
                            <MobileCTAWrap>
                                <MobileCTALine />
                                <ApexCTABtnMobile
                                    to={`apex/interviews`}
                                    width={'100%'}
                                >
                                    discover all
                                </ApexCTABtnMobile>
                            </MobileCTAWrap>
                            <div style={{ paddingTop: '200px' }} />
                        </>
                    )}
                </IpadAndMobileDisplay>
            </ApexPageWrapper>
        </React.Fragment>
    )
}

export default ApexOverview
