import { useState } from 'react'
import styled from 'styled-components'

import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import CrossIcon from '../../../../atoms/icons/crossIcon'
import InfoIcon from '../../../../atoms/icons/infoIcon'
import DesktopDisplayOnly from '../../../displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../displays/ipadAndMobileDisplay'

type Props = {
    role?: string
    isMobile?: boolean
    onClickPublish?: any
    txt?: string
    cancel?: () => any
    save: () => any
    isDisabled?: boolean
    activeStep?: 'select' | 'sort'
    onDeselectAll?: () => any
    onSelectAll?: () => any
    isAllSelected?: boolean
    noneIsSelected?: boolean
}

type StyleProps = {
    $role?: string
    $isMobile?: boolean
}

const Container = styled.div<StyleProps>`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;

    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
        props.$role === 'admin' ? 'center' : 'space-between'};
    align-items: center;
    height: 5rem;
    background-color: var(--bg-color, #ffffff);
    border-top: 1px solid var(--border-muted, #e5e5e5);
    transition: all 100ms;

    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
    z-index: 20;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const RowEnd = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`
const InfoBox = styled.div<StyleProps>`
    position: fixed;
    bottom: 5rem;
    left: 0px;
    right: 0px;

    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 5rem;
    background-color: var(--background_primary_soft, #f2fafb);
    transition: all 100ms;
    color: var(--text-default, #666666);
    font-size: 14px;
    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
    z-index: 5;
`

const CarGalleryShowroomEditBottomBar = (props: Props) => {
    const [dismissInfo, setDismissInfo] = useState<boolean>()

    let { isDisabled, isAllSelected } = props

    return (
        <>
            <DesktopDisplayOnly>
                <Container>
                    <Row>
                        <InfoIcon color="var(--primary, #5EC3CA)" />
                        <div style={{ paddingLeft: '20px' }} />
                        <div style={{ color: 'var(--text-strong, #1a1a1a)' }}>
                            {props.txt}
                        </div>
                    </Row>

                    <Row>
                        <ButtonAtom
                            theme="capitalize-white-background"
                            width="130px"
                            height="48px"
                            dataCyId={'showroom-car-share'}
                            icon={''}
                            fontSize={16}
                            onClick={() => {
                                props.cancel && props.cancel()
                            }}
                        >
                            Cancel
                        </ButtonAtom>

                        <div style={{ paddingLeft: '20px' }} />

                        <ButtonAtom
                            theme="secondary"
                            width="130px"
                            height="48px"
                            dataCyId={'showroom-car-share'}
                            icon={''}
                            textTransform="capitalize"
                            fontSize={16}
                            onClick={() => {
                                props.save()
                            }}
                            disabled={isDisabled}
                        >
                            Save changes
                        </ButtonAtom>
                    </Row>
                </Container>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                {props.txt && !dismissInfo && (
                    <InfoBox
                        onClick={() => {
                            setDismissInfo(true)
                        }}
                    >
                        {props.txt}

                        <div
                            style={{
                                position: 'absolute',
                                right: '16px',
                                top: '2.2rem',
                                cursor: 'pointer',
                            }}
                        >
                            <CrossIcon />
                        </div>
                    </InfoBox>
                )}

                <Container>
                    {props.activeStep &&
                        props.activeStep === 'select' &&
                        props.onSelectAll &&
                        props.onDeselectAll && (
                            <ButtonAtom
                                theme="capitalize-white-background"
                                width={'130px'}
                                height="45px"
                                dataCyId={'showroom-car-share'}
                                icon={''}
                                fontSize={14}
                                onClick={() => {
                                    if (isAllSelected && props.onDeselectAll) {
                                        props.onDeselectAll()
                                    }
                                    if (!isAllSelected && props.onSelectAll) {
                                        props.onSelectAll()
                                    }
                                }}
                            >
                                {!isAllSelected ? 'Select all' : 'Deselect all'}
                            </ButtonAtom>
                        )}

                    <RowEnd>
                        <ButtonAtom
                            theme="capitalize-white-background"
                            width={props.onSelectAll ? '80px' : '100px'}
                            height="48px"
                            dataCyId={'showroom-car-share'}
                            icon={''}
                            fontSize={props.onSelectAll ? 14 : 16}
                            onClick={() => {
                                props.cancel && props.cancel()
                            }}
                        >
                            Cancel
                        </ButtonAtom>

                        <div style={{ paddingLeft: '10px' }} />

                        <ButtonAtom
                            theme="secondary"
                            width={props.onSelectAll ? '80px' : '90px'}
                            height="48px"
                            dataCyId={'showroom-car-share'}
                            icon={''}
                            textTransform="capitalize"
                            fontSize={props.onSelectAll ? 14 : 16}
                            onClick={() => {
                                props.save()
                            }}
                            disabled={isDisabled}
                        >
                            Save
                        </ButtonAtom>
                    </RowEnd>
                </Container>
            </IpadAndMobileDisplay>
        </>
    )
}

export default CarGalleryShowroomEditBottomBar
