import * as React from 'react'
import styled from 'styled-components'
import UserAccountCircleLarge from './userAccountCircleLarge'
import { device } from '../../templates/displays/devices'
import * as SvgIcons from '../../atoms/icons/components'
import { Link, useNavigate } from 'react-router-dom'
import { UserProfileJourneyGarageBtnDesktop } from '../Button/journeyBtns/userProfileGarageBtnDestkop'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    /* min-height: 168px; */
    /* min-height: 158px; */
    background-color: var(--off-bg-color, #fafafa);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    border-radius: 12px;
    padding-bottom: 32px;
    padding-top: 32px;

    @media ${device.smallest_laptops} {
        padding-right: 24px;
        padding-left: 24px;
    }

    @media ${device.desktop} {
        padding-left: 2vw;
        padding-right: 2vw;
    }

    @media ${device.large_desktop} {
        padding-left: 50px;
        padding-right: 60px;
        border-radius: 12px;
    }
`

const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
`

// const Row = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     padding-top: 2px;
//     padding-bottom: 2px;
//     line-height: 1.7;
//     align-items: center;
// `

const ListRow = styled.div`
    display: flex;
    height: 32px;
    margin-bottom: 4px;
    align-items: center;
    border-radius: 10px;

    cursor: pointer;

    :hover {
        background: var(--primary_08);
        transition: 100ms;
    }
`

// const Col = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: start;
//     justify-content: center;
//     max-width: 680px;
//     padding-right: 20px;

//     @media ${device.ipad} {
//         padding-left: 20px;
//         padding-right: 10px;
//     }

//     text-align: left !important;
// `

const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 10px;
    padding-left: 24px;
    overflow-wrap: wrap;
    text-align: left !important;

    gap: 4px;

    @media ${device.smallest_laptops} {
        padding-right: 0px;
    }
`

const Name = styled.div`
    font-size: 24px;
    font-family: Lato-semibold;
    text-transform: capitalize;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 5px;

    @media ${device.smallest_laptops} {
        font-size: 22px;
    }

    @media ${device.ipad} {
        font-size: 18px;
    }
`

const Description = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    white-space: pre-line;

    @media ${device.smallest_laptops} {
        font-size: 1.02em;
    }
`

const RowText = styled.div`
    font-family: Lato;
    padding-left: 14px;
    font-size: 14px;
    color: var(--text-default, #666666);
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }
    width: 177px;
`

const RowTasks = styled.div`
    color: var(--text-default, #666666);
    font-family: Lato;
    padding-left: 14px;
    font-size: 14px;

    @media ${device.smallest_laptops} {
        font-size: 13px;
    }
    width: 177px;
`

const ListItemAction = styled.button`
    all: unset;
    font-size: 14px;
    color: var(--primary, #5ec3ca);
    cursor: pointer;
`

const DescriptionWidth = styled.div`
    width: 100%;
    box-sizing: border-box;
`

const DescriptionWidthPlaceholder = styled.div`
    width: 80%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    display: flex;
    flex-direction: column;

    cursor: pointer;

    @media ${device.large_desktop} {
        width: 60%;
    }

    @media ${device.ipad} {
        height: 50px;
    }
`

const IconWrapper = styled.div`
    display: inline-flex;
    width: 28px;
    height: 28px;

    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--garage_icons_bg, #fff);
    /* 8 */
    box-shadow: 0px 4px 8px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));

    align-items: center;
    justify-content: center;
`

const Skeleton1 = styled.div`
    width: 100%;
    box-sizing: border-box;

    @media ${device.smallest_laptops} {
        width: 60%;
    }

    @media (min-width: 881px) and (max-width: 1100px) {
        width: 100%;
        box-sizing: border-box;
    }
`

const Skeleton2 = styled.div`
    width: 64%;

    @media ${device.smallest_laptops} {
        width: 45%;
    }

    @media (min-width: 881px) and (max-width: 1100px) {
        width: 64%;
    }
`

const UserInfoSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
    min-width: 0;
`

const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    margin-left: 20px;
`

const ActionSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
`
interface Props {
    user: any
    car_number: number
    garage_id: string
    outstanding_tasks_number?: number
    isExternalGarage?: boolean
    linkToAddingLocation?: () => void
    linkToAddingCar?: () => void
    linkToAddingTasks?: () => void
}

export default function UserBanner(props: Props) {
    let {
        user,
        car_number,
        outstanding_tasks_number,
        isExternalGarage,
        linkToAddingCar,
        linkToAddingLocation,
        linkToAddingTasks,
    } = props

    const [bioSkeletonHover, setBioSkeletonHover] = React.useState(false)

    const { theme } = useThemes()

    const navigate = useNavigate()

    const listItems = {
        location: {
            action_text: 'Add location',
            action: linkToAddingLocation,
        },
        cars: {
            action_text: 'Add car',
            action: linkToAddingCar,
        },
        tasks: {
            action_text: 'Add task',
            action: linkToAddingTasks,
        },
    }

    return (
        <Wrapper style={{ width: '100%' }}>
            <ContentRow>
                <UserInfoSection>
                    {isExternalGarage ? (
                        <UserAccountCircleLarge
                            url={user.profile_image_url}
                            size="90px"
                        />
                    ) : (
                        <Link to={`/account?id=profile_picture`}>
                            <UserAccountCircleLarge
                                url={user.profile_image_url}
                                size="90px"
                            />
                        </Link>
                    )}
                    <UserDetails>
                        <Name>{user.display_name}</Name>
                        {user.one_liner && user.one_liner.length > 1 ? (
                            <DescriptionWidth>
                                <Description>{user.one_liner}</Description>
                            </DescriptionWidth>
                        ) : (
                            <>
                                <div style={{ paddingTop: '8px' }} />
                                <DescriptionWidthPlaceholder
                                    role={'button'}
                                    onMouseEnter={() => {
                                        setBioSkeletonHover(true)
                                    }}
                                    onMouseLeave={() => {
                                        setBioSkeletonHover(false)
                                    }}
                                    onClick={() => {
                                        navigate(
                                            '/journey/your-profile?step=bio'
                                        )
                                    }}
                                >
                                    <Skeleton1
                                        style={{
                                            background: bioSkeletonHover
                                                ? colours[theme].primary
                                                : 'var(--garage-placeholder-gradient-1)',
                                            height: '20px',
                                            marginBottom: '8px',
                                            opacity: bioSkeletonHover ? 0.5 : 1,
                                            transition: '1s',
                                        }}
                                    />
                                    <Skeleton2
                                        style={{
                                            background: bioSkeletonHover
                                                ? colours[theme].primary
                                                : 'var(--garage-placeholder-gradient-2)',
                                            height: '20px',
                                            opacity: bioSkeletonHover ? 0.3 : 1,
                                            transition: '1s',
                                        }}
                                    />
                                </DescriptionWidthPlaceholder>
                            </>
                        )}
                    </UserDetails>
                </UserInfoSection>
                <ActionSection>
                    {!isExternalGarage && !user?.one_liner && (
                        <div style={{ position: 'relative' }}>
                            <UserProfileJourneyGarageBtnDesktop
                                display_name={user.display_name}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            backgroundColor: colours[theme].border_muted,
                            height: '100px',
                            width: '1px',
                            margin: '0 20px',
                        }}
                    />
                    <List>
                        <ListRow
                            onClick={listItems[`location`].action}
                            role={'button'}
                        >
                            <IconWrapper>
                                <SvgIcons.LocationIcon
                                    color={
                                        user.location &&
                                        user.location !== 'Unknown ' &&
                                        user.location.length > 1
                                            ? undefined
                                            : colours.light.primary
                                    }
                                    size="20px"
                                />
                            </IconWrapper>
                            {isExternalGarage ? (
                                <RowText
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {user.location &&
                                    user.location !== 'Unknown '
                                        ? user.location.length > 1
                                            ? user.location
                                            : 'Unknown'
                                        : 'Unknown'}
                                </RowText>
                            ) : (
                                <RowText>
                                    {user.location &&
                                    user.location !== 'Unknown ' ? (
                                        user.location.length > 1 ? (
                                            user.location
                                                ?.replace(
                                                    'United Kingdom',
                                                    'UK'
                                                )
                                                ?.replace(
                                                    'United States',
                                                    'USA'
                                                )
                                        ) : (
                                            <ListItemAction>
                                                {
                                                    listItems[`location`]
                                                        .action_text
                                                }
                                            </ListItemAction>
                                        )
                                    ) : (
                                        <ListItemAction
                                            onClick={
                                                listItems[`location`].action
                                            }
                                        >
                                            {listItems[`location`].action_text}
                                        </ListItemAction>
                                    )}
                                </RowText>
                            )}
                        </ListRow>
                        <ListRow
                            onClick={listItems[`cars`].action}
                            role={'button'}
                        >
                            <IconWrapper>
                                <SvgIcons.CarIcon
                                    color={
                                        car_number
                                            ? undefined
                                            : colours.light.primary
                                    }
                                    size="21px"
                                />
                            </IconWrapper>
                            {isExternalGarage ? (
                                <RowText>
                                    {car_number ? car_number : `0`}
                                </RowText>
                            ) : (
                                <RowText>
                                    {car_number ? (
                                        car_number
                                    ) : (
                                        <ListItemAction>
                                            {listItems[`cars`].action_text}
                                        </ListItemAction>
                                    )}
                                </RowText>
                            )}
                        </ListRow>
                        {!isExternalGarage && (
                            <ListRow
                                style={{ marginTop: 4, marginBottom: 0 }}
                                onClick={listItems[`tasks`].action}
                                role={'button'}
                            >
                                <IconWrapper>
                                    <SvgIcons.TasksIcon
                                        color={
                                            outstanding_tasks_number
                                                ? undefined
                                                : colours.light.primary
                                        }
                                        size="20px"
                                    />
                                </IconWrapper>
                                <RowTasks>
                                    {outstanding_tasks_number ? (
                                        outstanding_tasks_number
                                    ) : (
                                        <ListItemAction>
                                            {listItems[`tasks`].action_text}
                                        </ListItemAction>
                                    )}
                                </RowTasks>
                            </ListRow>
                        )}
                    </List>
                </ActionSection>
            </ContentRow>
        </Wrapper>
    )
}
