import * as React from 'react'
import styled from 'styled-components'
import AddNewNaked from '../Button/addNewNaked'
import Expander from '../expander/expander'
import './styles.css'
import parse from 'html-react-parser'

const Reveal = styled.div<IStyle>`
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    bottom: 8px;
    opacity: ${(props) => (props.isOpen ? '0' : '1')};
    top: 1;
    z-index: 1;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0),
        var(--bg-color, #fff) 70%
    );
    height: ${(props) => (props.$isExternalCar ? '100px' : '200px')};
    transition: all 300ms;
`
const Wrapper = styled.div<IStyle>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-self: start;
    justify-self: start;
`

const Container = styled.div`
    padding-top: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-self: start;
    justify-self: start;
`

const ReadMore = styled.div<IStyle>`
    z-index: 1;
    text-decoration: underline;
    text-decoration-color: var(--primary_20);
`

interface Props {
    notes: string
    onClick?: any
    isExternalCar?: boolean
}

interface IStyle {
    isOpen: boolean
    $isExternalCar?: boolean
}

const TimelineNotesMobile: React.FC<Props> = ({ notes, isExternalCar }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    const toggleReveal = () => {
        setIsOpen(!isOpen)
    }

    const isTextShort: boolean = notes.length < 300

    return (
        <Container>
            {notes.length > 1 && (
                <Wrapper isOpen={isTextShort ? true : isOpen}>
                    {isTextShort ? null : (
                        <Reveal
                            isOpen={isOpen}
                            $isExternalCar={isExternalCar}
                        />
                    )}

                    <Expander
                        height={isTextShort ? 'auto' : isOpen ? 'auto' : 150}
                    >
                        {parse(notes)}
                    </Expander>

                    {!isTextShort && (
                        <>
                            <ReadMore isOpen={isOpen}>
                                <AddNewNaked
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        toggleReveal()
                                    }}
                                    text={isOpen ? 'read less' : 'read more'}
                                />
                            </ReadMore>
                        </>
                    )}
                </Wrapper>
            )}
        </Container>
    )
}

export default TimelineNotesMobile
