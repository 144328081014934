import React, { useEffect, useState } from 'react'
import { ITheme } from './colours'
import { useAppSelector } from '../../redux/store/hooks'

export type IThemesContext = {
    setTheme: (v: ITheme) => void
    theme: ITheme
}

export const ThemesContext = React.createContext<IThemesContext>({
    setTheme: (v: ITheme) => {},
    theme: 'light',
})

export const getPreferredTheme = (): 'dark' | 'light' => {
    if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
        return 'dark'
    }
    return 'light'
}

const ThemesProvider = ({ children }: { children: React.ReactNode }) => {
    const [activeTheme, setActiveTheme] = useState<ITheme>(getPreferredTheme)

    const userPreferredTheme = useAppSelector(
        (state) => state.user.userLoggedIn?.ui_appearance_mode
    )

    const setTheme = (v: ITheme) => {
        setActiveTheme(v)
        if (v === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    let savedThemeInLocalStorage: string | null =
        window?.localStorage?.getItem('ui_appearance_mode')

    const listenToColorSchemeChange = ({ matches }: any) => {
        if (matches) {
            setTheme('dark')
        } else {
            setTheme('light')
        }
    }

    useEffect(() => {
        let systemTheme = getPreferredTheme()

        const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')

        if (
            (userPreferredTheme && userPreferredTheme === 'SYSTEM') ||
            (savedThemeInLocalStorage &&
                savedThemeInLocalStorage === 'system') ||
            (userPreferredTheme === undefined &&
                savedThemeInLocalStorage === null)
        ) {
            mediaQueryList.addEventListener('change', listenToColorSchemeChange)
        } else {
            mediaQueryList.removeEventListener(
                'change',
                listenToColorSchemeChange
            )
        }

        if (userPreferredTheme) {
            if (userPreferredTheme === 'DARK') {
                setTheme('dark')
            } else if (userPreferredTheme === 'LIGHT') {
                setTheme('light')
            } else {
                setTheme(systemTheme)
            }
        } else {
            if (savedThemeInLocalStorage) {
                if (
                    savedThemeInLocalStorage === 'light' ||
                    savedThemeInLocalStorage === 'dark'
                ) {
                    setTheme(savedThemeInLocalStorage)
                } else {
                    setTheme(systemTheme)
                }
            } else {
                setTheme(systemTheme)
            }
        }

        return () => {
            mediaQueryList.removeEventListener(
                'change',
                listenToColorSchemeChange
            )
        }
    }, [userPreferredTheme])

    const theme: IThemesContext = {
        setTheme: setTheme,
        theme: activeTheme,
    }

    return (
        <ThemesContext.Provider value={theme}>
            {children}
        </ThemesContext.Provider>
    )
}

export default ThemesProvider
