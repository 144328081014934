import {
    IDirectShareOwnedItem,
    IShareRecipientsData,
    ISharingPermissionIDS,
    IPrivateShareReceivedItem,
    IDirectShareOwner,
} from '../../entities/cars/types'
import { IGalleryImage } from '../../entities/galleries/types'
import {
    IDirectShareOwnedItem_Api_payload,
    IDirectShareRecipient_item_API,
    IPrivateShareReceivedItem_Api_payload,
} from '../../services/types'
import { IDirectSharesReceivedObj } from '../../user/types'
import { ConvertRawToNewImageEntity } from '../entities/ImageTypeConversions'
import { shareCardConverterFormat } from '../time'
import { ConvertSharePermissionIDsToState } from './ConvertSharePermissionIds'

export type IConvertSingleDirectShareOwnedArg = {
    api_payload: IDirectShareOwnedItem_Api_payload
    recipients?: IDirectShareRecipient_item_API[]
}

export type IConvertSingleDirectShareReceivedArg =
    IPrivateShareReceivedItem_Api_payload

export type IConvertRecipientsConversionInDirectShare = {
    item: IDirectShareOwnedItem
    recipients?: IDirectShareRecipient_item_API[]
}
export const AddRecipientsConversionToDirectShare = (
    p: IConvertRecipientsConversionInDirectShare
): IDirectShareOwnedItem => {
    let external_share_recipients_ids_list: string[] = []
    let platform_share_recipients_ids_list: string[] = []
    let all_share_recipients_ids_list: string[] = []
    let share_recipients_data: IShareRecipientsData = {}
    if (p.recipients && p.recipients.length > 0) {
        for (let i = 0; i < p.recipients.length; i++) {
            let platform_item = p.recipients[i].platform_recipient
            if (platform_item) {
                share_recipients_data[platform_item.uid] = {
                    id: platform_item.uid,
                    recipient_type: 'platform',
                    given_name: platform_item.given_name,
                    family_name: platform_item.family_name,
                    display_name: platform_item.display_name,
                    email: undefined,
                    date_added_to_share: p.item.created_at,
                    profile_picture: platform_item.profile_image_url
                        ? {
                              id: `recipient_profile_picture-${i}`,
                              url: platform_item.profile_image_url,
                          }
                        : undefined,
                    location: '', // address
                    approvalStatus: p.recipients[i].status,
                }
                platform_share_recipients_ids_list.push(platform_item.uid)
                all_share_recipients_ids_list.push(platform_item.uid)
            }

            let external_item = p.recipients[i].external_recipient
            if (external_item) {
                share_recipients_data[external_item.uid] = {
                    id: external_item.uid,
                    recipient_type: 'external',
                    given_name: external_item.recipient_given_name,
                    family_name: external_item.recipient_family_name,
                    email: external_item.recipient_email,
                    date_added_to_share: p.item.created_at,
                    profile_picture: undefined,
                    location: '', // address
                    approvalStatus: p.recipients[i].status,
                }
                external_share_recipients_ids_list.push(external_item.uid)
                all_share_recipients_ids_list.push(external_item.uid)
            }
        }
    }

    return {
        ...p.item,
        share_recipients_data: share_recipients_data,
        all_share_recipients_ids_list: all_share_recipients_ids_list,
        external_share_recipients_ids_list: external_share_recipients_ids_list,
        platform_share_recipients_ids_list: platform_share_recipients_ids_list,
    }
}

export const ConvertSingleDirectShare_owned = (
    p: IConvertSingleDirectShareOwnedArg
): IDirectShareOwnedItem => {
    let external_share_recipients_ids_list: string[] = []
    let platform_share_recipients_ids_list: string[] = []
    let all_share_recipients_ids_list: string[] = []
    let share_recipients_data: IShareRecipientsData = {}
    if (p.recipients && p.recipients.length > 0) {
        for (let i = 0; i < p.recipients.length; i++) {
            let platform_item = p.recipients[i].platform_recipient
            if (platform_item) {
                share_recipients_data[platform_item.uid] = {
                    id: platform_item.uid,
                    recipient_type: 'platform',
                    given_name: platform_item.given_name,
                    family_name: platform_item.family_name,
                    display_name: platform_item.display_name,
                    email: undefined,
                    date_added_to_share: p.api_payload.created_at,
                    profile_picture: platform_item.profile_image_url
                        ? {
                              id: `recipient_profile_picture-${i}`,
                              url: platform_item.profile_image_url,
                          }
                        : undefined,
                    location: '', // address
                    approvalStatus: p.recipients[i].status,
                }
                platform_share_recipients_ids_list.push(platform_item.uid)
                all_share_recipients_ids_list.push(platform_item.uid)
            }

            let external_item = p.recipients[i].external_recipient
            if (external_item) {
                share_recipients_data[external_item.uid] = {
                    id: external_item.uid,
                    recipient_type: 'external',
                    given_name: external_item.recipient_given_name,
                    family_name: external_item.recipient_family_name,
                    email: external_item.recipient_email,
                    date_added_to_share: p.api_payload.created_at,
                    profile_picture: undefined,
                    location: '', // address
                    approvalStatus: p.recipients[i].status,
                }
                external_share_recipients_ids_list.push(external_item.uid)
                all_share_recipients_ids_list.push(external_item.uid)
            }
        }
    }

    let permissions: ISharingPermissionIDS[] = []

    if (p.api_payload.policy) {
        permissions = ConvertSharePermissionIDsToState(p.api_payload.policy)
    }

    let item: IDirectShareOwnedItem = {
        id: p.api_payload.uid,
        entity_id:
            p.api_payload.entity_type === 'car' &&
            p.api_payload.car &&
            p.api_payload.car.uid
                ? p.api_payload.car.uid
                : p.api_payload.garage && p.api_payload.garage.uid
                ? p.api_payload.garage.uid
                : 'noid',
        type: p.api_payload.entity_type,
        active_permission_ids: permissions,
        title: p.api_payload.title,
        created_at: p.api_payload.created_at,
        updated_at: p.api_payload.updated_at,
        all_share_recipients_ids_list: all_share_recipients_ids_list,
        external_share_recipients_ids_list: external_share_recipients_ids_list,
        platform_share_recipients_ids_list: platform_share_recipients_ids_list,
        share_recipients_data: share_recipients_data,
        total_recipients: p.api_payload.total_recipients,
    }

    return item
}

export const ConvertUserDirectSharesReceived_toObj = (
    p: IPrivateShareReceivedItem_Api_payload[]
): IDirectSharesReceivedObj => {
    let obj: IDirectSharesReceivedObj = {}

    for (let i = 0; i < p.length; i++) {
        obj[p[i].uid] = ConvertSingleDirectShare_received(p[i])
    }
    return obj
}

export const ConvertSingleDirectShare_received = (
    p: IConvertSingleDirectShareReceivedArg
): IPrivateShareReceivedItem => {
    let owner: IDirectShareOwner | undefined = p.owner
        ? {
              uid: p.owner.uid,
              display_name: p.owner.display_name
                  ? p.owner.display_name
                  : 'unknown',
              location:
                  p.owner.addresses && p.owner.addresses[0]
                      ? `${p.owner.addresses[0].locality}, ${p.owner.addresses[0].country}`
                      : 'unknown',
              profile_picture: p.owner.profile_image_url
                  ? {
                        id: p.owner.uid,
                        url: p.owner.profile_image_url,
                    }
                  : undefined,
          }
        : {
              uid: 'noid',
              display_name: 'unknown',
              location: 'unknown',
              profile_picture: undefined,
          }

    let car_profile_pic: IGalleryImage | undefined = undefined

    if (
        p.car &&
        p.car.gallery &&
        p.car.gallery.length > 0 &&
        p.car.gallery[0]
    ) {
        car_profile_pic = ConvertRawToNewImageEntity(p.car.gallery[0])
    }

    let permissions: ISharingPermissionIDS[] = []

    if (p.policy) {
        permissions = ConvertSharePermissionIDsToState(p.policy)
    }

    let item: IPrivateShareReceivedItem = {
        id: p.uid,
        entity_id:
            p.entity_type === 'car' && p.car && p.car.uid
                ? p.car.uid
                : p.garage && p.garage.uid
                ? p.garage.uid
                : 'noid',
        type: p.entity_type,
        active_permission_ids: permissions,
        car_title: p.car && p.car.title,
        car_profile_pic: car_profile_pic,
        created_at: shareCardConverterFormat(p.created_at),
        updated_at: shareCardConverterFormat(p.updated_at),
        owner,
        approvalStatus: p.status ? p.status : 'pending',
    }

    return item
}
