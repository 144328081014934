import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import Faded from '../../../templates/animated/faded'
import ImageCropperDesktop from '../../imageCropper/imageCropperDesktop'
import { Upload, Button, message } from 'antd'
import './antd.css'
import { motion } from 'framer-motion'
import Expander from '../../../atoms/expander/expander'
import { checkIfIpad } from '../../../templates/pwaCustom/checkIpadPwa'
import ImageCropperDesktop2 from '../../imageCropper/imageCropperDesktop2'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { useAppDispatch } from '../../../../redux/store/hooks'
import { fileStorageActions } from '../../../../redux/filestorage/reducer'
import { IUploadImagePreSignedUrlPayloadSingle } from '../../../../redux/filestorage/types'

type IStyle = { $theme: ITheme }

const DescriptionHeader = styled.div<IStyle>`
    padding-top: 3px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 14px;
    text-align: left;
    padding-right: 5vw;
    font-family: Lato-Light;

    @media ${device.large_desktop} {
        font-size: 15px;
    }
`

const LargePadding = styled.div`
    padding-top: 80px;
`

const ImgBtnRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

const ImgBtnSideRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`

const NameOfFileUploaded = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: Lato;
    font-size: 13px;
    margin-right: 30px;
    max-width: 390px;
    padding-bottom: 6px;
    overflow: hidden;
`

// const StyledInput = styled.input`
//   color: white !important;
//   border: none;

//   transition: all 0.1s;
//   :focus {
//     outline: 0;
//   }
//   width: 100px;
// `;

const CustomButton = styled(Button)<IStyle>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: 2px 4px 7px
        ${(props) => colours[props.$theme].shadow_strongest_03};
    transition: all 200ms;
    :hover {
        border-width: 2px;
        transition: all 200ms;
    }

    text-transform: lowercase;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over} !important;

    width: 160px !important;
    height: 48px !important;
    border-radius: 5px !important;
    color: ${(props) => colours[props.$theme].primary} !important;
    border: 1px solid ${(props) => colours[props.$theme].primary} !important;
    font-family: Lato !important;
    font-size: 15px !important;

    @media ${device.mobile} {
        font-size: 12px !important;
        height: 40px !important;
    }

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const ProgressBarImagesDesktopParentContainer = styled.div<IStyle>`
    border: 1px ${(props) => colours[props.$theme].primary} solid;
    display: flex;
    position: relative;
    height: 4px;
    width: 286px;
    border-radius: 5px;
`

const PhotoAndDataWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

const FileRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 550px;
    padding-top: 10px;
`

const FileCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
    padding-left: 35px;
    padding-right: 30px;
`

const CompletionRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Completed = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_darker};
    font-size: 14px;
    font-family: Lato;
    padding-left: 20px;
`

type ProgressProps = {
    progressPercent: number
    $theme: ITheme
}

const ProgressBarImagesDesktopChild = styled.div<ProgressProps>`
    display: flex;
    position: relative;
    height: 100%;
    width: ${(props) => `${props.progressPercent}%`};
    background-color: ${(props) => colours[props.$theme].primary};
    border-radius: 5px;
`

interface Values {
    file: File | null
}

interface OtherProps {
    close: () => void
    deleteImageUploaded: () => void
    setImageUploaded: (file: File | undefined) => void
    currentImagesUploaded: File | undefined
    toggleShowImageCropper: (res?: boolean | undefined) => void
    isImageCropperShown?: boolean
    car_id: string
    progressPercent?: number | null
    isSomethingBeingUploaded?: boolean
    presignedurls: IUploadImagePreSignedUrlPayloadSingle[] | null | undefined
    dispatch: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        close,
        setImageUploaded,
        currentImagesUploaded,
        deleteImageUploaded,
        toggleShowImageCropper,
        isImageCropperShown,
        setFieldValue,
        car_id,
        progressPercent,
    } = props

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const dispatch = useAppDispatch()

    const handleCroppedFile = (file: File | undefined) => {
        if (file) {
            dispatch(
                fileStorageActions.getPresignedUrlCarCoverImageRequest({
                    carid: car_id,
                    file_name: file.name,
                })
            )
            setFieldValue('file', file)
            let fake_url = URL.createObjectURL(file)
            setFieldValue('file_url', fake_url)
            setImageUploaded(file)
        } else {
            setImageUploaded(undefined)
        }

        return
    }

    let fake_url =
        currentImagesUploaded && URL.createObjectURL(currentImagesUploaded)

    const errorFileSizeWarning = () => {
        message.error({
            content: 'The maximum file size allowed per upload is 20MB.',
            duration: 2,
        })
    }

    const { theme } = useThemes()

    return (
        <React.Fragment>
            {isImageCropperShown ? (
                checkIfIpad() ? (
                    <ImageCropperDesktop2
                        currentImagesUploaded={currentImagesUploaded}
                        close={() => toggleShowImageCropper(false)}
                        handleFile={handleCroppedFile}
                    />
                ) : (
                    <ImageCropperDesktop
                        currentImagesUploaded={currentImagesUploaded}
                        close={() => toggleShowImageCropper(false)}
                        handleFile={handleCroppedFile}
                    />
                )
            ) : (
                <ModalContentDesktop
                    style={{ width: '85vw', maxWidth: 650 }}
                    $theme={theme}
                >
                    <ModalInnerContentWrapperDesktop>
                        <>
                            <ModalTitle $theme={theme}>
                                Update your car's banner image
                            </ModalTitle>

                            <DescriptionHeader $theme={theme}>
                                <strong
                                    style={{ color: colours[theme].text_muted }}
                                >
                                    Image formats accepted{' '}
                                </strong>
                                : png, jpg, jpeg, HEIC and HEIF.
                                <br />
                                <strong
                                    style={{ color: colours[theme].text_muted }}
                                >
                                    Maximum file size
                                </strong>
                                : 20MB
                            </DescriptionHeader>

                            <div style={{ paddingTop: '40px' }} />

                            <Expander
                                height={currentImagesUploaded ? 0 : 'auto'}
                            >
                                <ImgBtnRowWrapper>
                                    <motion.div whileTap={{ scale: 1.05 }}>
                                        <Upload
                                            multiple={false}
                                            showUploadList={false}
                                            onChange={(info: any) => {
                                                if (
                                                    info.file &&
                                                    (info.file.type ===
                                                        'image/jpeg' ||
                                                        info.file.type ===
                                                            'image/png' ||
                                                        info.file.type ===
                                                            'image/heic' ||
                                                        info.file.type ===
                                                            'image/heif')
                                                ) {
                                                    if (
                                                        info.file.size <=
                                                            20000000 &&
                                                        info.file.size > 1
                                                    ) {
                                                        dispatch(
                                                            fileStorageActions.getPresignedUrlCarCoverImageRequest(
                                                                {
                                                                    carid: car_id,
                                                                    file_name:
                                                                        info
                                                                            .file
                                                                            .name,
                                                                }
                                                            )
                                                        )

                                                        let fileimg =
                                                            info.file
                                                                .originFileObj

                                                        setFieldValue(
                                                            'file',
                                                            fileimg
                                                        )
                                                        let fake_url =
                                                            URL.createObjectURL(
                                                                fileimg
                                                            )
                                                        setFieldValue(
                                                            'file_url',
                                                            fake_url
                                                        )

                                                        setImageUploaded(
                                                            fileimg
                                                        )

                                                        toggleShowImageCropper(
                                                            true
                                                        )
                                                    } else
                                                        errorFileSizeWarning()
                                                }
                                            }}
                                            customRequest={dummyRequest}
                                        >
                                            <CustomButton $theme={theme}>
                                                upload image
                                            </CustomButton>
                                        </Upload>
                                    </motion.div>
                                </ImgBtnRowWrapper>
                            </Expander>
                            {/*     START OF THE  REST */}
                            <Expander
                                height={currentImagesUploaded ? 'auto' : 0}
                            >
                                <FileRow>
                                    <PhotoAndDataWrapper>
                                        {currentImagesUploaded && (
                                            <img
                                                src={fake_url}
                                                alt="car banner cover"
                                                style={{
                                                    height: '40px',
                                                    width: '40px',
                                                    borderRadius: '5px',
                                                }}
                                            />
                                        )}
                                        <FileCol>
                                            <NameOfFileUploaded $theme={theme}>
                                                {currentImagesUploaded &&
                                                    // currentImagesUploaded.new === true &&
                                                    currentImagesUploaded.name !==
                                                        undefined &&
                                                    currentImagesUploaded.name}
                                            </NameOfFileUploaded>

                                            {currentImagesUploaded &&
                                                progressPercent && (
                                                    <CompletionRow>
                                                        <ProgressBarImagesDesktopParentContainer
                                                            $theme={theme}
                                                        >
                                                            <ProgressBarImagesDesktopChild
                                                                $theme={theme}
                                                                progressPercent={
                                                                    progressPercent
                                                                        ? progressPercent
                                                                        : 0
                                                                }
                                                            />
                                                        </ProgressBarImagesDesktopParentContainer>

                                                        {progressPercent ===
                                                            100 && (
                                                            <Completed
                                                                $theme={theme}
                                                            >
                                                                100%
                                                            </Completed>
                                                        )}
                                                    </CompletionRow>
                                                )}
                                        </FileCol>
                                    </PhotoAndDataWrapper>

                                    {currentImagesUploaded && (
                                        <Faded>
                                            <ImgBtnSideRow>
                                                <ButtonAtom
                                                    onClick={(e: any) => {
                                                        e.preventDefault()
                                                        // fake function

                                                        deleteImageUploaded()
                                                    }}
                                                    theme="bin_img_circle"
                                                    height="30px"
                                                    width="30px"
                                                >
                                                    import image
                                                </ButtonAtom>
                                            </ImgBtnSideRow>
                                        </Faded>
                                    )}
                                </FileRow>
                            </Expander>
                        </>

                        <LargePadding />
                        <ButtonsStandardBottomRowDesktop
                            cancel={close}
                            submit={props.handleSubmit}
                            isDisabled={progressPercent === 100 ? false : true}
                            dataCyIdUpdate="add_or_edit_car_banner_desktop"
                            dataCyIdCancel="cancel_add_or_edit_car_banner_desktop"
                        />
                    </ModalInnerContentWrapperDesktop>
                </ModalContentDesktop>
            )}
        </React.Fragment>
    )
}

const CarBannerImageEditFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        file: null,
    }),

    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        let file = values.file

        let presignedurl = props.presignedurls && props.presignedurls[0]

        if (file !== undefined && file !== null && presignedurl) {
            props.dispatch(
                fileStorageActions.submitCarCoverImageFilestorageRequest({
                    carid: props.car_id,
                    presignedurl: presignedurl,
                    file: file,
                })
            )
        }

        setSubmitting(false)
    },
})(InnerForm)

export default CarBannerImageEditFormDesktop
