export enum TimelineCategoryUIDsEnum {
    service = 'service',
    maintenance = 'maintenance',
    restoration = 'restoration',
    admin = 'admin',
    insurance = 'insurance',
    history = 'history',
    events = 'events',
    other = 'other',
}

export enum TimelineFormTypeIDsEnum {
    general = 'general',
    cost = 'cost',
    attachment = 'attachment',
    notes = 'notes',
    labels = 'labels',
    mileage = 'mileage',
    insurance_fields = 'insurance_fields',
}

export enum TimelineCreationTypeIdsEnum {
    cost = 'cost',
    cost_desktop = 'cost_desktop',
    add_new = 'add_new_timeline_item',
    attachment = 'add_new_attachment',
    label = 'label',
    insurance = 'insurance',
    add_new_desktop = 'add_new_timeline_intem_desktop',
}

export enum TimelineCreateFormStepTypesIDsEnum {
    SELECT_CATEGORY = 'select_category',
    TITLE_AND_ENTRY_DATE_WITH_OPTIONS = 'title_and_entry_date_with_options',
    ENTRY_DATE_WITH_OPTIONS = 'entry_date_with_options',
    GENERAL_DETAIL_1 = 'GENERAL_DETAIL_1',
    ATTACHMENT = 'attachment',
    COST_STEP_1 = 'cost_step_1',
    COST_DESKTOP = 'cost_desktop',
    ENTRY_SUMMARY = 'entry_summary',
    COST_STEP_2 = 'cost_step_2',
    COST_SUMMARY = 'cost_summary',
    ENTRY_LABELS = 'entry_labels',
    INSURANCE_FIELDS = 'insurance_fields',
}

export const CurencyUIDsEnumObj = {
    gbp: 'GBP',
    usd: 'USD',
    eur: 'EUR',
} as const

export type ICurencyUIDsEnum =
    (typeof CurencyUIDsEnumObj)[keyof typeof CurencyUIDsEnumObj]
