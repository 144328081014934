import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import blank_user_dark from '../../../public/assets/placeholders_svgs/avatar_dark.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import useThemes from '../../../providers/theme/hooks'

type IStyleProps = {
    hasImage?: boolean
    $isShowroom?: boolean
}

const UserImgWrapper = styled.div<IStyleProps>`
    position: absolute;

    @media ${device.desktop} {
        border-radius: 50%;
        border: ${(props) =>
            props.hasImage ? 'solid 1px #5EC3CA' : 'solid 1px #ececec'};
        height: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        width: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        left: 0px;
        top: ${(props) => (props.$isShowroom ? 0 : '-40px')};
    }

    @media ${device.smallest_laptops} {
        border-radius: 50%;
        border: ${(props) =>
            props.hasImage ? 'solid 1px #5EC3CA' : 'solid 1px #ececec'};
        height: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        width: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        left: 0px;
        top: ${(props) => (props.$isShowroom ? 0 : '-50px')};
    }

    @media ${device.large_desktop} {
        height: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        width: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        left: 0px;
        top: ${(props) => (props.$isShowroom ? 0 : '-50px')};
    }
`
const UserImg = styled.img<IStyleProps>`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    width: 100px;

    @media ${device.desktop} {
        margin-right: 100px;
    }
    @media ${device.large_desktop} {
        margin-right: 100px;
    }

    @media ${device.smallest_laptops} {
        margin-right: 70px;
    }
`
type Props = {
    size?: string
    url?: any
    onClick?: any
    isShowroom?: boolean
}

const UserCircleProfilePage: React.FC<Props> = ({
    size,
    url,
    onClick,
    isShowroom,
}) => {
    const [src, setSrc] = React.useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    let { theme } = useThemes()

    React.useEffect(() => {
        const initialSrc =
            url !== undefined && url !== false && url !== null && url.length > 1
                ? url
                : theme === 'dark'
                ? blank_user_dark
                : blank_user
        if (initialSrc) {
            setSrc(initialSrc)
        }
    }, [url])

    return (
        <Wrapper style={{ cursor: onClick ? 'pointer' : 'auto' }}>
            <UserImgWrapper
                hasImage={src !== undefined}
                $isShowroom={isShowroom}
                onClick={() => onClick && onClick()}
            >
                <UserImg src={src} onLoad={() => setIsLoading(false)} />
                <SkeletonAbsolute isRound={true} isthingloading={isLoading} />
            </UserImgWrapper>
        </Wrapper>
    )
}

export default UserCircleProfilePage
