import { IJourneySellYourCarStepID } from 'journeyInterfaces'
import { useNavigate, useLocation } from 'react-router-dom'
import HandoversSellYourCarJourneyStep from '../handovers'
import SellYourCarIncreaseValueStep from '../increaseValueStep'
import SharingSellYourCarJourneyStep from '../sharing'
import JourneySellYourCarWelcome from '../welcome'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'
import { IUser } from '../../../../../redux/user/types'

type Props = {
    stepID: IJourneySellYourCarStepID
    startJourney: () => void
    exitJourney: () => void
    user: IUser | null
}
export default function SellYourCarJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
    user,
}: Props) {
    const navigate = useNavigate()
    const location = useLocation()
    let locationState: any = location.state

    switch (stepID) {
        case 'welcome':
            return (
                <JourneySellYourCarWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )

        case 'increase_value':
            return (
                <SellYourCarIncreaseValueStep
                    step={1}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(`/journey/sell-your-car?step=welcome`)
                    }}
                    goToNextStep={() => {
                        navigate(`/journey/sell-your-car?step=sharing`, {
                            state: {
                                prevPath: `${location.pathname}${
                                    location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'sharing':
            return (
                <SharingSellYourCarJourneyStep
                    step={2}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(
                                  `/journey/sell-your-car?step=increase_value`
                              )
                    }}
                    goToNextStep={() => {
                        navigate(`/journey/sell-your-car?step=handovers`, {
                            state: {
                                prevPath: `${location.pathname}${
                                    location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'handovers':
            return (
                <HandoversSellYourCarJourneyStep
                    step={3}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(`/journey/sell-your-car?step=handovers`)
                    }}
                    goToNextStep={() => {
                        navigate(`/journey/sell-your-car?step=achieved`, {
                            state: {
                                prevPath: `${location.pathname}${
                                    location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )
        case 'achieved':
            return <JourneyAchievedStep sectionID="sell_your_car" />

        default:
            return (
                <JourneySellYourCarWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
