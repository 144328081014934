import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertAPIEntryToTimelineItemSingle } from '../../conversions/timeline/entryToTimelineItem'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ConvertAttachmentAPIToAttachmentState } from '../../conversions/attachments'
import { selectStateUserCurrency } from '../../user/sagas/getUserData'
import { ICurencyUIDsEnum } from '../timelineEnum'
import { convertEntryImagesAPIToGalleryImagesState } from '../../conversions/timeline/entryImagesToStateImages'
import { attachmentActions } from '../../attachments/reducer'
import { galleriesActions } from '../../entities/galleries/reducer'
import { timelineActions } from '../reducer'
import { IRootState } from '../../store'
import { IGetTimelineItemByIdPayloadRequest, ITimelineItem } from '../types'
import {
    INormalisedAttachmentsByID,
    IAttachmentItem,
} from '../../attachments/types'
import { IReduxError, ICustomErrorData } from '../../entities/cars/types'
import { IEntryPayloadAPI } from '../../services/typedefinitions/apiPayloads'
import { IApiGetEntryById_args } from '../../services/types'

const getCarError = (state: IRootState) => state.entities.carsData.error
const getTimelineError = (state: IRootState) => state.timeline.error

export function* GetEntryByIdSaga(
    payload: IGetTimelineItemByIdPayloadRequest
): any {
    try {
        //Step 1 - call the api func and keep data returned in a const

        let user_current_curr: ICurencyUIDsEnum = yield call(
            selectStateUserCurrency
        )

        let api_payload: IApiGetEntryById_args = {
            entry_id: payload.entryUID,
            car_id: payload.car_id,
            user_currency: user_current_curr,
        }

        const entry_from_api: IEntryPayloadAPI = yield call(
            api.timeline.entries.getEntriesData.getEntryById,
            api_payload
        )
        // STEP 2 . build up payload to return to reducer
        let entry_converted: ITimelineItem =
            ConvertAPIEntryToTimelineItemSingle(entry_from_api)

        if (entry_from_api.attachments) {
            if (entry_from_api.attachments.length > 0) {
                let obj: INormalisedAttachmentsByID = {}

                for (const element of entry_from_api.attachments) {
                    let i: IAttachmentItem =
                        ConvertAttachmentAPIToAttachmentState(element)

                    obj = { ...obj, [i.id]: i }
                }

                yield put(attachmentActions.addAttachmentsSuccess(obj))
            }
        }

        if (entry_from_api.images) {
            if (entry_from_api.images.length > 0) {
                let galleryImagesObject =
                    convertEntryImagesAPIToGalleryImagesState(entry_from_api)

                yield put(
                    galleriesActions.setGalleryImagesSuccess(
                        galleryImagesObject
                    )
                )
            }
        }

        if (entry_from_api.costs) {
            if (entry_from_api.costs.length > 0) {
                for (const cost of entry_from_api.costs) {
                    if (cost.attachments && cost.attachments.length > 0) {
                        let length: number = cost.attachments.length - 1
                        if (cost.attachments[length].uid) {
                            yield put(
                                attachmentActions.addAttachmentsSuccess({
                                    [cost.attachments[length].uid]:
                                        ConvertAttachmentAPIToAttachmentState(
                                            cost.attachments[length]
                                        ),
                                })
                            )
                        }
                    }
                }
            }
        }
        // STEP 3. dispatch action to reducer
        yield put(timelineActions.getTimelineItemByIDSuccess(entry_converted))
        yield put(timelineActions.setActiveEntrySuccess(entry_converted))
        // yield put(push(`/car/${payload.car_id}/history-file/entry?entryid=${res.id}`))
        // Capture event
        posthog.capture('GET TIMELINE ITEM BY ID')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetEntryByIdSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let carError: IReduxError = yield select(getCarError)
            let timelineError: IReduxError = yield select(getTimelineError)
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'Return to timeline',
                custom_redirect_path:
                    !carError && !timelineError
                        ? `/car/${payload.car_id}/history-file`
                        : '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(timelineActions.getTimelineItemByIDError(customError))
        }
    }
}

function* watcherGetEntryById() {
    while (true) {
        const { payload } = yield take(
            timelineActions.getTimelineItemByIDRequest
        )

        yield call(GetEntryByIdSaga, payload)
    }
}

const get_timeline_item_by_id: any[] = [fork(watcherGetEntryById)]

export default get_timeline_item_by_id
