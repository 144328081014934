import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../../redux/store/hooks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import ReadOnlyCarGalleryImage from '../../../templates/readOnly/car/readOnlyGalleryImage'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'

const ExternalCarGalleryImage: React.FC = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        const params = new URLSearchParams(location.search)
        const image_id_param = params.get('id')

        if (carid) {
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/shared/car/${carid}/gallery?id=${image_id_param}`
                )
            )
            dispatch(carActions.setCurrentExternalCarSuccess(carid))
            dispatch(carActions.getExternalCarsSharedWithYouRequest(carid))
        }
    }, [userLoggedIn, carid, location.search])

    const current =
        externalCarsData && carid ? externalCarsData[carid] : undefined

    return <ReadOnlyCarGalleryImage sharedCar={current} />
}

export default ExternalCarGalleryImage
