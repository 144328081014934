import { IApexArticleOverviewItemState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import chevrondownblue from '../../../../public/assets/icons/chevrondownblue.svg'
import { Link } from 'react-router-dom'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 60px;

    @media ${device.large_desktop} {
        padding-bottom: 66px;
    }
`
const CoverImage = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;

    @media ${device.large_desktop} {
    }
`

const CustomLink = styled(Link)`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`
const TitleLink = styled(Link)`
    font-family: Lato;
    font-size: 22px;

    @media ${device.large_desktop} {
        font-size: 23px;
    }
    color: var(--text-strong, #1a1a1a) !important;
`

const Paragraph = styled.div`
    font-family: Lato-light;
    font-size: 17px;
    line-height: 32px;
    color: var(--text-strong, #1a1a1a);
    @media ${device.large_desktop} {
        font-size: 17px;
    }
`

const Read = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color: var(--primary, #5ec3ca) !important;
    :focus {
        color: var(--primary, #5ec3ca) !important;
    }
    @media ${device.large_desktop} {
        font-size: 17px;
    }
`

const Chevron = styled.img`
    width: 12px;
    transform: rotate(-90deg);
    margin-left: 10px;
`

type Props = {
    item: IApexArticleOverviewItemState
    index: number
}

const ArticleOverviewItemDesktop: React.FC<Props> = ({ item, index }) => {
    return (
        <Container key={index}>
            <CustomLink to={`/apex/articles/${item.uid}`}>
                <CoverImage src={item.img ? item.img.url : grey_placeholder} />
            </CustomLink>
            <div style={{ paddingTop: '30px' }} />
            <TitleLink to={`/apex/articles/${item.uid}`}>
                {item.title}
            </TitleLink>
            <div style={{ paddingTop: '20px' }} />
            <Paragraph>
                {item.introduction &&
                    item.introduction[0] &&
                    item.introduction[0]}
            </Paragraph>
            <div style={{ paddingTop: '16px' }} />
            <Paragraph>
                {item.introduction &&
                    item.introduction[1] &&
                    item.introduction[1]}
            </Paragraph>
            <div style={{ paddingTop: '26px' }} />

            <Read to={`/apex/articles/${item.uid}`}>
                <div>Read More</div>
                <Chevron src={chevrondownblue} />
            </Read>
        </Container>
    )
}

export default ArticleOverviewItemDesktop
