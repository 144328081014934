import styled from 'styled-components'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'

const FormBtns = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-end;
    justify-content: flex-end;
`

const SideDivider = styled.div`
    padding-top: 15px;
`

type Props = {
    cancel: any
    submit: any
    secondText?: string
    firstText?: string
    isDisabled?: boolean
    delete?: boolean
}

const ButtonsRowDeletionMobile = (props: Props) => {
    return (
        <FormBtns>
            <ButtonAtom
                width="100%"
                height="50px"
                onClick={props.submit}
                theme={'lowercase-red-background'}
                disabled={props.isDisabled}
            >
                {props.secondText ? props.secondText : 'save'}
            </ButtonAtom>

            <SideDivider />

            <ButtonAtom
                onClick={(e: any) => {
                    e.preventDefault()
                    setTimeout(function () {
                        props.cancel()
                    }, 50)
                }}
                theme="lowercase-white-background"
                width="100%"
                height="50px"
            >
                {props.firstText ? props.firstText : 'cancel'}
            </ButtonAtom>
        </FormBtns>
    )
}

export default ButtonsRowDeletionMobile
