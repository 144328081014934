import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import grey_placeholder_dark from '../../../public/assets/placeholder/grey_placeholder_dark.jpg'
import { Link, useLocation } from 'react-router-dom'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import img_1 from '../../atoms/skeleton/gallery/1.jpg'
import img_2 from '../../atoms/skeleton/gallery/2.jpg'
import img_3 from '../../atoms/skeleton/gallery/3.jpg'
import img_4 from '../../atoms/skeleton/gallery/4.jpg'
import img_5 from '../../atoms/skeleton/gallery/5.jpg'
import img_6 from '../../atoms/skeleton/gallery/6.jpg'
import img_1_dark from '../../atoms/skeleton/gallery/1_dark.png'
import img_2_dark from '../../atoms/skeleton/gallery/2_dark.png'
import img_3_dark from '../../atoms/skeleton/gallery/3_dark.png'
import img_4_dark from '../../atoms/skeleton/gallery/4_dark.png'
import img_5_dark from '../../atoms/skeleton/gallery/5_dark.png'
import img_6_dark from '../../atoms/skeleton/gallery/6_dark.png'
import AddBox from '../../atoms/Button/addBox'
import { ITheme } from '../../../providers/theme/colours'
import { IGalleryImage } from '../../../redux/entities/galleries/types'
import useThemes from '../../../providers/theme/hooks'

const CallWrapperSize = styled.div`
    position: relative;
    cursor: pointer;

    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        min-height: 100px;
    }

    width: 100%;
    box-sizing: border-box;
    height: 100%;
    min-height: 100px;
    z-index: 1;
`

const CellImage = styled.div<StyleProps>`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media ${device.beyond_ipad_mobile} {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    opacity: ${(props) => (props.isthingloading ? '0' : '1')};
    transition: opacity 400ms;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
`

const GalleryCount = styled.div`
    @media ${device.beyond_ipad_mobile} {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        display: flex;
        align-items: center;
    }

    font-size: 42px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
`

const ImgOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    min-height: 100px;
    transition: 200ms all;
    background: linear-gradient(#000 50%, #000 100%);
    z-index: 2;
`

interface StyleProps {
    isthingloading: boolean
}

interface IImageProps {
    img: IGalleryImage | undefined
    carid: string
    readonlyMode?: boolean
    image_number: number
    lastItemTxt: string
    openImgForm?: () => void
}

const GalleryOverviewItemDesktop: React.FC<IImageProps> = ({
    img,
    carid,
    readonlyMode,
    image_number,
    lastItemTxt,
    openImgForm,
}) => {
    let { theme } = useThemes()

    const [isLoading, setIsLoading] = useState(true)
    const [addBox, setAddBox] = useState(false)
    const [imgOverlay, setImgOverlay] = useState(false)

    const location = useLocation()

    const defaultPlaceholder =
        theme === 'dark' ? grey_placeholder_dark : grey_placeholder

    const imageSrc = img?.url || defaultPlaceholder

    useEffect(() => {
        const image = new Image()
        image.src = imageSrc
        image.onload = () => setIsLoading(false)
        image.onerror = () => setIsLoading(false) // Ensure state updates on error.
    }, [img])

    const showAddBox = () => setAddBox(true)
    const removeAddBox = () => setAddBox(false)
    const showImageOverlay = () => setImgOverlay(true)
    const removeImageOverlay = () => setImgOverlay(false)

    const getPlaceholderImg = (theme: ITheme): string => {
        switch (image_number) {
            case 1:
                return theme === 'light' ? img_1 : img_1_dark
            case 2:
                return theme === 'light' ? img_2 : img_2_dark
            case 3:
                return theme === 'light' ? img_3 : img_3_dark
            case 4:
                return theme === 'light' ? img_4 : img_4_dark
            case 5:
                return theme === 'light' ? img_5 : img_5_dark
            case 6:
                return theme === 'light' ? img_6 : img_6_dark
            default:
                return grey_placeholder
        }
    }

    const isLast = image_number === 6

    const determineImageLink = (img: IGalleryImage) => {
        const urlLocation = location.pathname

        let imgLinkPrefix = location.pathname.includes('overview')
            ? location.pathname.replace('/overview', '')
            : location.pathname

        if (isLast) {
            return {
                pathname: `${imgLinkPrefix}/gallery`,
                state: { prevPath: urlLocation },
            }
        } else {
            return {
                pathname: `${imgLinkPrefix}/gallery`,
                search: `?id=${img.id}`,
                state: { prevPath: urlLocation },
            }
        }
    }

    return (
        <React.Fragment>
            {img !== undefined && (
                <Link to={determineImageLink(img)}>
                    <CallWrapperSize
                        onMouseEnter={showImageOverlay}
                        onMouseLeave={removeImageOverlay}
                    >
                        <SkeletonAbsolute isthingloading={isLoading} />

                        {isLast ? (
                            <div
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                <CellImage
                                    role="img"
                                    aria-label="car overview banner"
                                    style={{
                                        backgroundImage: `url(${imageSrc})`,

                                        filter: 'brightness(30%)',
                                    }}
                                    isthingloading={isLoading}
                                />

                                <GalleryCount>{lastItemTxt}</GalleryCount>
                            </div>
                        ) : (
                            <CellImage
                                role="img"
                                aria-label="car overview banner"
                                style={{
                                    backgroundImage: `url(${imageSrc})`,
                                }}
                                isthingloading={isLoading}
                            />
                        )}
                        <ImgOverlay
                            style={{
                                opacity: imgOverlay ? 0.5 : 0,
                            }}
                        />
                    </CallWrapperSize>
                </Link>
            )}

            {!img && (
                <CallWrapperSize
                    onMouseEnter={showAddBox}
                    onMouseLeave={removeAddBox}
                    onClick={openImgForm}
                >
                    <SkeletonAbsolute isthingloading={isLoading} />
                    {!readonlyMode && addBox ? (
                        <AddBox />
                    ) : (
                        <CellImage
                            role="img"
                            aria-label="car overview banner"
                            style={{
                                backgroundImage: `url(${getPlaceholderImg(
                                    theme
                                )})`,
                                position: 'relative',
                            }}
                            isthingloading={isLoading}
                        >
                            {readonlyMode && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontFamily: 'Lato',
                                        fontSize: 16,
                                        color: '#a4a4a4',
                                    }}
                                >
                                    No Image
                                </div>
                            )}
                        </CellImage>
                    )}
                </CallWrapperSize>
            )}
        </React.Fragment>
    )
}

export default GalleryOverviewItemDesktop
