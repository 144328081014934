import * as React from 'react'
import styled from 'styled-components'

const List = styled.ul`
    all: unset;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
`
const Row = styled.li`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    gap: 8px;
`

const Title = styled.h3`
    padding: 0;
    margin: 0;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.98px;
    text-transform: uppercase;

    @media (max-width: 370px) {
        font-size: 12px;
    }
`

const Answer = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-default, #666);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.98px;
    text-transform: uppercase;
    @media (max-width: 370px) {
        font-size: 12px;
    }
`

interface Props {
    list: string[]
    data: any
    default_fuel_unit_user_text?: string | undefined
    default_fuel_unit_user?: string | undefined
    requiredLabel?: boolean
}

const ListItem = (p: { item: any; unit_txt?: string }) => {
    return (
        <Row>
            <Title>{p.item?.text}</Title>
            <Answer>
                {p.item?.answer ?? '-'}{' '}
                {p.item.id === 'engine_capacity' &&
                    p.item?.answer &&
                    (p.unit_txt ? ` ${p.unit_txt}` : ' CC')}
            </Answer>
        </Row>
    )
}

const ListOnboardingDVLAmobile: React.FunctionComponent<Props> = (
    props: Props
) => {
    let { list, data, default_fuel_unit_user_text } = props

    return (
        <List>
            {list.map((id: string, index) => {
                return (
                    <React.Fragment key={`onboarding_mobile_${index}`}>
                        <ListItem
                            item={data[id]}
                            unit_txt={default_fuel_unit_user_text}
                        />
                    </React.Fragment>
                )
            })}
        </List>
    )
}

export default ListOnboardingDVLAmobile
