import styled from 'styled-components'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import LoaderElasticThreeDots from '../../../atoms/loader/loaderElasticThreeDots'

const FormBtnsBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-end;
`

const SideDivider = styled.div`
    padding-left: 16px;
`

type Props = {
    cancel: any
    submit: any
    secondText?: string
    firstText?: string
    isDisabled?: boolean
    dataCyIdUpdate?: string
    dataCyIdCancel?: string
    isDistructive?: boolean
    isSecondTextLoading?: boolean
}

const ButtonsStandardBottomRowDesktop = (props: Props) => {
    return (
        <FormBtnsBottomRow>
            <ButtonAtom
                onClick={(e: any) => {
                    e.preventDefault()
                    setTimeout(function () {
                        props.cancel()
                    }, 50)
                }}
                theme="modal-action-button-secondary"
                dataCyId={props.dataCyIdCancel && props.dataCyIdCancel}
            >
                {props.firstText ?? 'Close'}
            </ButtonAtom>
            <SideDivider />
            {props.isDistructive ? (
                <ButtonAtom
                    theme="lowercase-red-background"
                    onClick={props.submit}
                    disabled={props.isDisabled ? props.isDisabled : false}
                    dataCyId={props.dataCyIdUpdate ? props.dataCyIdUpdate : ''}
                    height={'40px'}
                >
                    <span
                        style={{
                            textTransform: 'capitalize',
                        }}
                    >
                        {props.secondText ?? 'Delete'}
                    </span>
                </ButtonAtom>
            ) : (
                <ButtonAtom
                    onClick={props.submit}
                    theme="modal-action-button-primary"
                    disabled={props.isDisabled ? props.isDisabled : false}
                    dataCyId={props.dataCyIdUpdate ? props.dataCyIdUpdate : ''}
                >
                    {props.isSecondTextLoading ? (
                        <div style={{ width: 40 }}>
                            <LoaderElasticThreeDots />
                        </div>
                    ) : (
                        props.secondText ?? 'Save'
                    )}
                </ButtonAtom>
            )}
        </FormBtnsBottomRow>
    )
}

export default ButtonsStandardBottomRowDesktop
