import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISetCustomItemInSpecifiedTargettedDropdownReq } from './sagas/setCustomItemInSpecifiedTargettedDropdown'
import { ISetModelDropdownDataReq } from './sagas/setModelDropdownData'
import { ISetYearDropdownData } from './sagas/setYearDropdownData'
import { IAnyObject } from '../../insuranceQuoteApplication/reducer'
import { IInsuranceDropdownItem } from './sagas/setInsuranceFieldDataByIDs'
import {
    IDropdownListState,
    IDropdownItem,
    INewDropdownDataForCarMakesModelYears,
    IKeyValue,
} from 'entityModels'
import { IReduxError } from '../../entities/cars/types'

export type IDropdownTimezonePayload = {
    data: IKeyValue
    idsList: string[]
}

const initialState: IDropdownListState = {
    carMakes: null,
    carModels: null,
    carYears: null,
    carTransmissionTypes: null,
    carBodyTypes: null,
    countriesCode: null,
    tempMakeID: undefined,
    tempModelID: undefined,
    usaStatesCodes: null,

    tempMakeDisplay: undefined,
    tempModelDisplay: undefined,

    timeZonesData: null,
    timeZonesList: null,
    error: null,
    loading: false,
    isLoadingNewMakeOrModel: false,
    // selectedMake: null,
    // selectedModel: null,
    selectedTimezone: null,
    selectedCountry: null,
    registrationNumber: null,
}

export const dropdownData = createSlice({
    name: 'editForms',
    initialState: initialState,
    reducers: {
        setInsuranceFieldByIDsDropdownDataRequest: (
            state: IDropdownListState,
            action: PayloadAction<string[]>
        ) => {
            state.loading = true
        },

        setUSAStatesDropdownRequest: (state: IDropdownListState) => {
            state.loading = true
        },

        setUSAStatesDropdownSuccess: (
            state: IDropdownListState,
            action: PayloadAction<IInsuranceDropdownItem[]>
        ) => {
            state.usaStatesCodes = action.payload
            state.loading = false
            state.error = null
        },

        setUSAStatesDropdownError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        setInsuranceFieldByIDsDropdownDataSuccess: (
            state: IDropdownListState,
            action: PayloadAction<IAnyObject>
        ) => {
            //@ts-ignore
            let newState: IDropdownListState = {
                ...state,
                loading: false,
                ...action.payload,
            }

            return newState
        },

        setInsuranceMotorConvCodesGroupedDropdownData: (
            state: IDropdownListState,
            action: PayloadAction<IAnyObject>
        ) => {
            //@ts-ignore
            let newState: IDropdownListState = {
                ...state,
                loading: false,
                ...action.payload,
            }

            return newState
        },

        setInsuranceFieldByIDsDropdownDataError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        setMakeDropdownDataRequest: (state: IDropdownListState) => {
            state.loading = true
        },

        setMakeDropdownDataError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        setMakeDropdownDataSuccess: (
            state: IDropdownListState,
            action: PayloadAction<IDropdownItem[]>
        ) => {
            state.carMakes = action.payload
            state.loading = false
            state.error = null
        },

        // setSelectedMake: (
        //     state: IDropdownListState,
        //     action: PayloadAction<string | null>
        // ) => {
        //     state.selectedMake = action.payload
        //     state.loading = false
        //     state.error = null
        // },

        setTempMakeIDSuccess: (
            state: IDropdownListState,
            action: PayloadAction<string | undefined>
        ) => {
            state.tempMakeID = action.payload
            state.tempModelID = undefined
            state.tempModelDisplay = undefined
            state.loading = false
            state.error = null
        },

        setTempMakeDisplay: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.tempMakeDisplay = action.payload
        },

        reset_make_model_temps: (state) => {
            state.tempMakeDisplay = undefined
            state.tempMakeID = undefined
            state.tempModelDisplay = undefined
            state.tempModelID = undefined
        },

        setTempModelDisplay: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.tempModelDisplay = action.payload
        },

        setCountriesCodeDropdownDataRequest: (state: IDropdownListState) => {
            state.loading = true
            state.error = null
        },

        setCountriesCodeDropdownDataSuccess: (
            state: IDropdownListState,
            action: PayloadAction<IDropdownItem[]>
        ) => {
            state.countriesCode = [
                {
                    uid: 'GB',
                    name: 'United Kingdom',
                },
                ...action.payload,
            ]
            state.loading = false
            state.error = null
        },

        setCountriesCodeDropdownDataError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        setSelectedCountry: (
            state: IDropdownListState,
            action: PayloadAction<string | null>
        ) => {
            state.selectedCountry = action.payload
            state.loading = false
            state.error = null
        },

        setRegistrationNumber: (
            state: IDropdownListState,
            action: PayloadAction<string | null>
        ) => {
            state.registrationNumber = action.payload
            state.loading = false
            state.error = null
        },

        setTransmissionTypesDropdownDataRequest: (
            state: IDropdownListState
        ) => {
            state.loading = true
            state.error = null
        },

        setTransmissionTypesDropdownDataSuccess: (
            state: IDropdownListState,
            action: PayloadAction<IDropdownItem[]>
        ) => {
            state.carTransmissionTypes = action.payload
            state.loading = false
            state.error = null
        },

        setTransmissionTypesDropdownDataError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        setModelDropdownDataRequest: (
            state: IDropdownListState,
            action: PayloadAction<ISetModelDropdownDataReq>
        ) => {
            state.loading = true
            state.error = null
        },

        setModelDropdownDataSuccess: (
            state: IDropdownListState,
            action: PayloadAction<IDropdownItem[]>
        ) => {
            state.carModels = action.payload
            state.loading = false
            state.error = null
        },

        setModelDropdownDataError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        // setSelectedModel: (
        //     state: IDropdownListState,
        //     action: PayloadAction<string | null>
        // ) => {
        //     state.selectedModel = action.payload
        //     state.loading = false
        //     state.error = null
        // },

        setTempModelIDSuccess: (
            state: IDropdownListState,
            action: PayloadAction<string | undefined>
        ) => {
            state.tempModelID = action.payload
            state.loading = false
            state.error = null
        },

        setYearDropdownDataRequest: (
            state: IDropdownListState,
            action: PayloadAction<ISetYearDropdownData | undefined>
        ) => {
            state.loading = true
            state.error = null
        },

        setYearDropdownDataSuccess: (
            state: IDropdownListState,
            action: PayloadAction<IDropdownItem[]>
        ) => {
            state.carYears = action.payload
            state.loading = false
            state.error = null
        },

        setYearDropdownDataError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        setCustomItemInSpecifiedTargettedDropdownRequest: (
            state: IDropdownListState,
            action: PayloadAction<ISetCustomItemInSpecifiedTargettedDropdownReq>
        ) => {
            state.loading = true
            state.error = null
        },

        setCustomItemInSpecifiedTargettedDropdownSuccess: (
            state: IDropdownListState,
            action: PayloadAction<INewDropdownDataForCarMakesModelYears>
        ) => {
            state.carMakes = action.payload.carMakes
            state.carModels = action.payload.carModels
            state.carYears = action.payload.carYears
            state.loading = false
            state.error = null
        },

        setCustomItemInSpecifiedTargettedDropdownError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        addCustomFieldToApiRequest: (state: IDropdownListState) => {
            state.loading = true
            state.error = null
        },

        addCustomFieldToApiSuccess: (state: IDropdownListState) => {
            state.loading = false
            state.error = null
        },

        addCustomFieldToApiError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
        },

        setBodyTypeDropdownDataRequest: (state: IDropdownListState) => {
            state.loading = true
            state.error = null
        },

        setBodyTypeDropdownDataSuccess: (
            state: IDropdownListState,
            action: PayloadAction<IDropdownItem[]>
        ) => {
            state.carBodyTypes = action.payload
            state.loading = false
            state.error = null
        },

        setBodyTypeDropdownDataError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
        },

        setTimeZoneDropdownDataRequest: (state: IDropdownListState) => {
            state.loading = true
            state.error = null
        },

        setTimeZoneDropdownDataSuccess: (
            state: IDropdownListState,
            action: PayloadAction<IDropdownTimezonePayload>
        ) => {
            state.timeZonesData = action.payload.data
            state.timeZonesList = action.payload.idsList
            state.loading = false
            state.error = null
        },

        setTimeZoneDropdownDataError: (
            state: IDropdownListState,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        setSelectedTimezone: (
            state: IDropdownListState,
            action: PayloadAction<string | null>
        ) => {
            state.selectedTimezone = action.payload
            state.loading = false
            state.error = null
        },
        setIsLoadingNewMakeOrModel: (
            state: IDropdownListState,
            action: PayloadAction<boolean>
        ) => {
            state.isLoadingNewMakeOrModel = action.payload
        },
    },
})

export const dropDownActions = dropdownData.actions

export default dropdownData.reducer
