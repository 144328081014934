import styled from 'styled-components'
import CarAsSoldConfirmModalDesktop from '../../../molecules/showroom/editModals/desktop/carAsSoldConfirmModalDesktop'
import CarRemovalActionModalDesktop from '../../../molecules/showroom/editModals/desktop/carRemovalActionModal'
import CarUnpublishActionModalDesktop from '../../../molecules/showroom/editModals/desktop/carUnpublishActionModalDesktop'
import CarUnpublishConfirmationModalDesktop from '../../../molecules/showroom/editModals/desktop/carUnpublishConfirmationModalDesktop'
import PublicationConfirmedModalDesktop from '../../../molecules/showroom/editModals/desktop/publicationConfirmedModalDesktop'

import {
    IEditShowroomModalOptions,
    ShowroomEntryParams,
} from '../../../pages/showroom/car/showroomCarProfileEdit'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ICar } from '../../../../redux/entities/cars/types'

export const ShowroomModalContainerMobile = styled.div`
    min-height: 100vh;
    width: 100vw;
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`

type Props = {
    activeModal: IEditShowroomModalOptions
    handleSetModalOpened: (p: IEditShowroomModalOptions | null) => any
    car: ICar
    entryid: string
}

const ShowroomModalEditManagerMobile = (props: Props) => {
    const { entryid } = useParams<ShowroomEntryParams>()
    let { handleSetModalOpened, car } = props

    // const navigate = useNavigate()
    useEffect(() => {
        scrollTo(0, 0)
        disableBodyScroll(document.body)
        return () => {
            enableBodyScroll(document.body)
        }
    }, [])

    let renderModalBod = () => {
        switch (props.activeModal) {
            case 'publication_confirmed':
                return (
                    <PublicationConfirmedModalDesktop entryid={`${entryid}`} />
                )

            case 'unpublish_action':
                return (
                    <CarUnpublishActionModalDesktop
                        carTitle={car.title}
                        handleSetModalOpened={handleSetModalOpened}
                        cancel={() => {
                            handleSetModalOpened(null)
                        }}
                    />
                )

            case 'unpublished_confirmed':
                return (
                    <CarUnpublishConfirmationModalDesktop
                        cancel={() => {
                            handleSetModalOpened(null)
                            // handleSetModalOpened('publication_confirmed')
                        }}
                        continue={() => {
                            handleSetModalOpened(null)
                        }}
                    />
                )

            case 'removal_action':
                return (
                    <CarRemovalActionModalDesktop
                        handleSetModalOpened={() => {
                            handleSetModalOpened('removal_confirmed')
                        }}
                        cancel={() => {
                            handleSetModalOpened(null)
                        }}
                        carTitle={props.car.title}
                    />
                )

            case 'mark_as_sold_confirmed':
                return (
                    <CarAsSoldConfirmModalDesktop
                        cancel={() => handleSetModalOpened(null)}
                        carID={props.car.id}
                    />
                )
            default:
                return <div />
        }
    }

    return (
        <ShowroomModalContainerMobile>
            {renderModalBod()}
        </ShowroomModalContainerMobile>
    )
}

export default ShowroomModalEditManagerMobile
