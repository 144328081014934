import { IJourneySectionID } from 'journeyInterfaces'
import styled from 'styled-components'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import Faded from '../animated/faded'
import { device } from '../displays/devices'
import JourneyBottomActionBarMobile from '../bars/journey/bottomActionBarMobile'

type IStyle = {
    $theme: ITheme
}

const ColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`
const Title = styled.h1<IStyle>`
    padding: 0px;
    margin: 0px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-semibold;
    font-size: 24px;
    line-height: 40px;

    @media ${device.mobile_and_ipad} {
        font-size: 22px;
        line-height: 32px;
        width: 100%;
        box-sizing: border-box;
    }
`

const Text = styled.span<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    @media ${device.mobile_and_ipad} {
        font-size: 16px;
        line-height: 24px;
    }
`

type Props = {
    title: string
    subtitle?: string
    onBack: () => void
    onNext: () => void
    currentStep: number
    totalSteps: number
    sectionID: IJourneySectionID
    children: React.ReactNode
    allowScroll?: boolean
    maxTitleWidth?: string
    isDisabled?: boolean
}

export default function JourneyPageWrapperMobile({
    title,
    subtitle,
    onBack,
    onNext,
    currentStep,
    totalSteps,
    sectionID,
    children,
    allowScroll,
    maxTitleWidth,
    isDisabled,
}: Props) {
    let allowScrollBool = allowScroll
        ? true
        : currentStep === 2 || currentStep === 3 || currentStep === 5
        ? true
        : false

    const { theme } = useThemes()
    return (
        <>
            <Faded>
                <div
                    style={{
                        height: allowScrollBool ? 'auto' : '100dvh',
                        minHeight: '100dvh',
                        overflowY: allowScrollBool ? 'scroll' : 'hidden',
                        display: 'flex',
                    }}
                >
                    <ColumnContent
                        style={{
                            paddingBottom: 64,
                            paddingTop: 48,
                            gap: 24,
                            flex: 1,
                            maxWidth: '100%',
                        }}
                    >
                        <ColumnContent
                            style={{
                                gap: 4,
                                paddingLeft: 24,
                                paddingRight: 24,
                            }}
                        >
                            <Title
                                style={{ maxWidth: maxTitleWidth ?? 'auto' }}
                                $theme={theme}
                            >
                                {title}
                            </Title>
                            <Text
                                style={{
                                    fontSize: 14,
                                    color: colours[theme].text_muted,
                                }}
                                $theme={theme}
                            >
                                {subtitle ??
                                    `Create a digital profile for your car`}
                            </Text>
                        </ColumnContent>
                        {children}
                    </ColumnContent>
                </div>
                <JourneyBottomActionBarMobile
                    sectionID={sectionID}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    onBack={onBack}
                    onNext={onNext}
                    isNextDisabled={isDisabled}
                />
            </Faded>
        </>
    )
}
