import React from 'react'
import './style.css'

import { IdropdownObject } from '../../../redux/localdata/remindersLocalData'
import { Select } from '../select'

export type Props = {
    item?: any
    value?: any
    handleChange?: any
    optionsIDsList: string[]
    optionsData: IdropdownObject
    width?: string | undefined
    isDisabled?: boolean | undefined
    fontSize?: string | undefined
    placeholder?: string | undefined
    dataCyId?: string | undefined
    isLowerCase?: boolean
    noChangeAllowed?: boolean
    defaultValue?: string
    bg?: string
}

const BlueBorderSelectInput: React.FC<Props> = ({
    value,
    item,
    handleChange,
    optionsIDsList,
    isDisabled,
    placeholder,
    optionsData,
    defaultValue,
    bg,
}) => {
    const mappedList = optionsIDsList.map((id) => {
        let opt = optionsData[id]

        return {
            value: opt.uid,
            label: opt.name,
        }
    })

    return (
        <Select
            value={value}
            options={mappedList}
            defaultValue={
                defaultValue
                    ? defaultValue
                    : item && item.answer
                    ? item.answer
                    : undefined
            }
            placeholder={placeholder}
            disabled={isDisabled ? isDisabled : false}
            onChange={handleChange}
            backgroundColor={bg}
        />
    )
}

export default BlueBorderSelectInput
