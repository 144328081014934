import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../../redux/store/hooks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import FabsMenusMobile from '../../../templates/menus/fabsMenuMobile'
import ReadOnlyCarTechnicalInformation from '../../../templates/readOnly/car/readonlyTechnicalInformation'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { usersActions } from '../../../../redux/user/reducer'
import { IExternalCar } from '../../../../redux/entities/cars/types'

const ExternalCarTechnicalInformation: React.FC = () => {
    const dispatch = useAppDispatch()
    const { carid } = useParams<{ carid: string }>()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )

    useEffect(() => {
        if (carid) {
            dispatch(carActions.setCurrentExternalCarSuccess(carid))
            if (!userLoggedIn) {
                dispatch(usersActions.getCurrentUserDataRequest())
            }
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/shared/car/${carid}/technical-information`
                )
            )
            dispatch(carActions.getExternalCarsSharedWithYouRequest(carid))
        }
    }, [carid, userLoggedIn])

    const currentExternalCar: IExternalCar | undefined =
        externalCarsData && carid ? externalCarsData[carid] : undefined

    return (
        <React.Fragment>
            <FabsMenusMobile hasAdd={false} hasSubNav={true} />
            {userLoggedIn && (
                <ReadOnlyCarTechnicalInformation
                    sharedCar={currentExternalCar}
                    userLoggedIn={userLoggedIn}
                />
            )}
        </React.Fragment>
    )
}

export default ExternalCarTechnicalInformation
