import React from 'react'
import SideSlider from '../../templates/animated/sideSlider'
import ImageListItemMobile from '../../atoms/list/ImageListItemMobile'
import GarageCarsDuoGridMobile from '../../molecules/imageGrid/garageCarsDuoGridMobile'
import { Link } from 'react-router-dom'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import img_1 from '../../atoms/skeleton/garage/1.png'
import img_1_dark from '../../atoms/skeleton/garage/1_dark.png'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import AddImageBanner from '../../atoms/image/addImageBanner'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IGalleryImagesObject } from '../../../redux/entities/galleries/types'
import useThemes from '../../../providers/theme/hooks'

const PlaceholderWrapper = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 252px;
    @media ${device.ipad} {
        height: 350px;
    }
`

const Placeholder = styled.img`
    object-fit: cover !important;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

interface Props {
    activeView: string
    carsIdList: string[]
    addCarForm: () => void
    getDropdownList: any
    isExternalGarage?: boolean
    garageid?: string
    imagesData: IGalleryImagesObject | null
    dontShowAddBox?: boolean
    dataLoading?: boolean
}

const MobileGarageViewManager: React.FC<Props> = ({
    activeView,
    carsIdList,
    addCarForm,
    getDropdownList,
    isExternalGarage,
    garageid,
    imagesData,
    dontShowAddBox,
    dataLoading,
}) => {
    let { theme } = useThemes()

    const dispatch = useAppDispatch()

    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const areCarsLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )

    const reset_make_model_temps = () =>
        dispatch(dropDownActions.reset_make_model_temps())

    const car_cover_url = (carObj: any): string | undefined => {
        let imageid =
            carObj.gallery && carObj.gallery.cover && carObj.gallery.cover

        if (imageid && imagesData) {
            if (imagesData[imageid]) {
                return imagesData[imageid].url
            }
        } else return randomCarSvgPlaceholderFunc(undefined, theme)
    }

    const renderRightView = (activeView: string, addCarForm: any) => {
        let checkIfGarageIsEmpty = !carsIdList || carsIdList.length === 0

        if (activeView === 'banner_view') {
            return (
                <SideSlider direction={'left'} initial="enter">
                    {carsIdList.map((id: string, index: number) => {
                        return (
                            <Link
                                replace={isExternalGarage ? false : true}
                                to={{
                                    pathname: isExternalGarage
                                        ? `/shared/car/${id}`
                                        : `/car/${id}`,
                                }}
                                key={index}
                                onClick={() => {
                                    reset_make_model_temps()
                                }}
                            >
                                <ImageListItemMobile
                                    item={
                                        isExternalGarage && externalCarsData
                                            ? {
                                                  ...externalCarsData[id],
                                                  url: car_cover_url(
                                                      externalCarsData[id]
                                                  ),
                                              }
                                            : {
                                                  ...carsData[id],
                                                  url: car_cover_url(
                                                      carsData[id]
                                                  ),
                                              }
                                    }
                                />
                            </Link>
                        )
                    })}

                    {!isExternalGarage &&
                        !dataLoading &&
                        (checkIfGarageIsEmpty ? (
                            <PlaceholderWrapper>
                                <Placeholder
                                    src={theme === 'dark' ? img_1_dark : img_1}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        zIndex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ButtonAtom
                                        width={'230px'}
                                        theme={'lowercase-blue-background'}
                                        fontSize={14}
                                        onClick={() => {
                                            getDropdownList()
                                            addCarForm()
                                        }}
                                        dataPH={'add_car'}
                                    >
                                        <div
                                            style={{
                                                textTransform: 'none',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Start adding cars to your garage
                                        </div>
                                    </ButtonAtom>
                                </div>
                            </PlaceholderWrapper>
                        ) : (
                            !dontShowAddBox && (
                                <AddImageBanner
                                    ipadHeight="350px"
                                    onClick={() => {
                                        getDropdownList()
                                        addCarForm()
                                    }}
                                    dataPH="add_car"
                                />
                            )
                        ))}
                    {isExternalGarage && checkIfGarageIsEmpty && (
                        <NoDataRectangle
                            text="This garage is empty"
                            height="200px"
                        />
                    )}
                </SideSlider>
            )
        } else if (activeView === 'grid_view') {
            return (
                <SideSlider direction={'right'} initial="enter">
                    <GarageCarsDuoGridMobile
                        reset_make_model_temps={reset_make_model_temps}
                        dataToMap={carsIdList}
                        carsdata={
                            isExternalGarage && externalCarsData
                                ? externalCarsData
                                : carsData
                        }
                        onClick={() => {
                            getDropdownList()
                            addCarForm()
                        }}
                        imagesData={imagesData}
                        isExternalGarage={isExternalGarage}
                        dontShowAddBox={dontShowAddBox}
                        dataLoading={dataLoading}
                        dataPHAddBox="add_car"
                    />
                </SideSlider>
            )
        }
    }

    return (
        <React.Fragment>
            {!areCarsLoading && renderRightView(activeView, addCarForm)}
        </React.Fragment>
    )
}

export default MobileGarageViewManager
