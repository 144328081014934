import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import {
    useAppSelector,
    useAppDispatch,
    useThunkAppDispatch,
} from '../../../redux/store/hooks'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import HistoryFilePageManager, {
    redirectToFilteredPath,
} from '../../organisms/HistoryFile/historyFilePageManager'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { timelineActions } from '../../../redux/timeline/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import {
    ISetFilterTimelineByCategoryNLabelNTitleQCarID,
    ITimelineEntryTypes,
} from '../../../redux/timeline/types'

export enum TimelineViewEnum {
    small = 'small',
    fat = 'fat',
}

const CarTimeline: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const dispatchThunks = useThunkAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const carsData = useAppSelector((state) => state.entities.carsData.cars)

    const carsTimelineData = useAppSelector(
        (state) => state.timeline.timeline_data_normalised_by_car_id
    )

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/car/${carid}/history-file`
            )
        )
        dispatch(timelineActions.setActiveEntrySuccess(undefined))

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            dispatchThunks(carActions.setCurrentCar(`${carid}`))

            if (!carsData[`${carid}`]) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            }

            dispatch(
                timelineActions.getAllTimelineItemsByCarIDRequest({
                    car_id: `${carid}`,
                })
            )

            const carsDataSearch = carsTimelineData?.[`${carid}`]?.search
            const entry_title = carsDataSearch?.activeEntryTitleQuery

            const params = new URLSearchParams(location.search)
            const labelsParam = params.getAll('label')
            const categoryParam = params.getAll(
                'category'
            ) as ITimelineEntryTypes[]
            const scrolltoentryid = params.get('scrolltoid')

            if (
                (carsDataSearch?.activeCategoryIds &&
                    carsDataSearch.activeCategoryIds.length > 0) ||
                (carsDataSearch?.activeCustomLabelsIds &&
                    carsDataSearch.activeCustomLabelsIds.length > 0) ||
                carsDataSearch?.activeEntryTitleQuery
            ) {
                dispatch(
                    timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
                        {
                            car_id: `${carid}`,
                            category_ids: carsDataSearch.activeCategoryIds,
                            label_ids: carsDataSearch.activeCustomLabelsIds,
                            entry_title: entry_title,
                            sendToAPI: true,
                        }
                    )
                )
            } else if (labelsParam.length > 0 || categoryParam.length > 0) {
                dispatch(
                    timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
                        {
                            car_id: `${carid}`,
                            category_ids: categoryParam,
                            label_ids: labelsParam,
                            entry_title: entry_title,
                            sendToAPI: true,
                        }
                    )
                )
            }

            if (
                labelsParam.length === 0 &&
                categoryParam.length === 0 &&
                entry_title === undefined
            ) {
                dispatch(
                    timelineActions.getAllTimelineItemsByCarIDRequest({
                        car_id: `${carid}`,
                    })
                )
            }

            const currentPath = location.pathname
            const newPath = redirectToFilteredPath(
                currentPath,
                carsDataSearch?.activeCategoryIds,
                carsDataSearch?.activeCustomLabelsIds,
                scrolltoentryid ?? undefined
            )

            if (carsDataSearch && newPath) {
                navigate(newPath)
            }
        }
    }, [userLoggedIn, carid])

    const currentCar = carsData[`${carid}`]

    return (
        <React.Fragment>
            <FabsMenusMobile hasAdd={true} hasSubNav={true} />
            <HistoryFilePageManager
                carid={`${carid}`}
                currentOwnedCar={currentCar}
                carsData={carsData}
                carsTimelineData={carsTimelineData}
                setFilterTimelineItemsByCarIdCategoryIdsNLabelIds={(
                    payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
                ) =>
                    dispatch(
                        timelineActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
                            payload
                        )
                    )
                }
            />
        </React.Fragment>
    )
}

export default CarTimeline
