import React, { useEffect } from 'react'
import styled from 'styled-components'
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'
import Faded from '../../animated/faded'
import { device } from '../devices'
import { menuActions } from '../../../../redux/menus/reducer'
import { useAppDispatch } from '../../../../redux/store/hooks'

interface Props {
    children: React.ReactNode
    isOpen: boolean
    toggle: () => void
    modalBackgroundColor?: string
}

interface Istyle {
    isCollapsed?: boolean
    isOpen?: boolean
    backgroundColor?: string
}

const DesktopPageWrapperStyle = styled.div<Istyle>`
    z-index: 12;
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : 'var(--gallery-image-bg)'};
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    top: 0px;
    bottom: 0px;
    transition: all 100ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    left: 0px;
    right: 0px;
`

const LeftClickBlock = styled.div`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        display: block;
        position: absolute;
        top: 0;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 100vh;
        width: 100vw;
    }
`

const ModalDisplayGalleryImageDesktop: React.FC<Props> = ({
    children,
    isOpen,
    toggle,
    modalBackgroundColor,
}) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [dispatch])

    return (
        <DesktopDisplayOnly>
            <Faded>
                <DesktopPageWrapperStyle
                    isOpen={isOpen}
                    backgroundColor={modalBackgroundColor}
                >
                    <LeftClickBlock
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault()
                            setTimeout(() => {
                                toggle()
                            }, 50)
                        }}
                    />

                    {children}
                </DesktopPageWrapperStyle>
            </Faded>
        </DesktopDisplayOnly>
    )
}

export default ModalDisplayGalleryImageDesktop
