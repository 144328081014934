import React from 'react'
import styled from 'styled-components'
import ModalDisplay from '../displays/pageWrappers/modalDisplay'
import { useNavigate } from 'react-router-dom'
import AccessDenied from '../../molecules/errors/accessDenied'
import ErrorBody from '../../molecules/errors/errorBody'
import access_denied from '../../../public/assets/icons/access_denied.svg'
import warning from '../../../public/assets/icons/warning.svg'
import { device } from '../displays/devices'
import { checkIfPwaStandalone } from '../pwaCustom/checkIpadPwa'
import SharedWithYouEntityError from '../../molecules/errors/sharedWithYouEntityError'
import ShowroomError from '../../molecules/errors/showroomError'

import { PlatformErrorsState } from '.'
import { useAppSelector } from '../../../redux/store/hooks'
import { IReduxError } from '../../../redux/entities/cars/types'
import { CarDoesNotExist } from '../../molecules/errors/carDoesNotExist'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 1000;
    @media ${device.beyond_ipad_mobile} {
        padding-top: 20vh;
    }
`

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 260px;
    min-width: 230px;
    background-color: var(--off-bg-color, #fafafa);
    border-radius: 5px;
    @media ${device.beyond_ipad_mobile} {
        max-width: 350px;
    }
`

interface Props {
    platform_errors_state: PlatformErrorsState
    resetErrorState: () => any
    generateRedirect: (error: IReduxError) => string
    handleUserAction: () => any
    generateTextError: (error: IReduxError) => {
        text: string
        detail?: string | undefined
    }
    generateCustomUserActionText: (error: IReduxError) => string
}

export const BackEndError: React.FC<Props> = ({
    platform_errors_state,
    resetErrorState,
    generateRedirect,
    handleUserAction,
    generateTextError,
    generateCustomUserActionText,
}) => {
    const navigate = useNavigate()

    const user = useAppSelector((state) => state.user.userLoggedIn)

    const pathname = checkIfPwaStandalone()
        ? window.location.pathname
        : window?.location.pathname

    const error_code = platform_errors_state?.error?.status_code

    return (
        <>
            {error_code === 503 ? null : (
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        boxSizing: 'border-box',
                        zIndex: 1000,
                        top: 0,
                    }}
                >
                    {pathname.match(/timeline/g) === null &&
                        pathname !== '/login' &&
                        pathname !== '/signin' &&
                        pathname !== '/register' &&
                        pathname.match(/reset-password/g) === null && (
                            <div
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                {platform_errors_state?.showErrorMessage &&
                                    platform_errors_state?.error &&
                                    (platform_errors_state?.error
                                        .entity_error === 'car' ? (
                                        <ModalDisplay
                                            isOpen={
                                                platform_errors_state?.showErrorMessage
                                            }
                                            toggle={() => {}}
                                            customZindex={15}
                                        >
                                            {platform_errors_state?.error
                                                .is_permission_denied ? (
                                                <AccessDenied
                                                    onClick={() => {
                                                        resetErrorState()
                                                        navigate('/garage')
                                                    }}
                                                />
                                            ) : (
                                                <CarDoesNotExist
                                                    onClick={() => {
                                                        resetErrorState()
                                                        navigate('/garage')
                                                    }}
                                                />
                                            )}
                                        </ModalDisplay>
                                    ) : platform_errors_state?.error
                                          .entity_error ===
                                          'shared_with_you_car' ||
                                      platform_errors_state?.error
                                          .entity_error ===
                                          'shared_with_you_garage' ? (
                                        <ModalDisplay
                                            isOpen={
                                                platform_errors_state?.showErrorMessage
                                            }
                                            toggle={() => {}}
                                            customZindex={15}
                                        >
                                            <SharedWithYouEntityError
                                                error_is={
                                                    platform_errors_state?.error
                                                        .status_code === 403
                                                        ? 'invalid_entity_access'
                                                        : platform_errors_state
                                                              ?.error
                                                              .status_code ===
                                                          404
                                                        ? 'entity_does_not_exist'
                                                        : undefined
                                                }
                                                entity={
                                                    platform_errors_state?.error
                                                        .entity_error ===
                                                    'shared_with_you_car'
                                                        ? 'car'
                                                        : 'garage'
                                                }
                                                onClick={() => {
                                                    resetErrorState()
                                                    navigate('/garage')
                                                }}
                                            />
                                        </ModalDisplay>
                                    ) : platform_errors_state?.error
                                          .entity_error === 'showroom' &&
                                      (platform_errors_state?.error
                                          .status_code === 403 ||
                                          platform_errors_state?.error
                                              .status_code === 404) ? (
                                        <ModalDisplay
                                            isOpen={
                                                platform_errors_state?.showErrorMessage
                                            }
                                            toggle={() => {}}
                                            customZindex={15}
                                        >
                                            <ShowroomError
                                                error_is={
                                                    platform_errors_state?.error
                                                        .status_code === 403
                                                        ? 'invalid_access'
                                                        : platform_errors_state
                                                              ?.error
                                                              .status_code ===
                                                          404
                                                        ? 'entry_does_not_exist'
                                                        : undefined
                                                }
                                                onClick={() => {
                                                    resetErrorState()
                                                    user && user.id
                                                        ? navigate('/garage')
                                                        : navigate('/showroom')
                                                }}
                                            />
                                        </ModalDisplay>
                                    ) : (
                                        <ModalDisplay
                                            noMenuAdjust
                                            isOpen={
                                                platform_errors_state?.showErrorMessage
                                            }
                                            toggle={() => {}}
                                            customZindex={15}
                                        >
                                            <Wrapper>
                                                <Container>
                                                    {platform_errors_state
                                                        ?.error.status_code ===
                                                        500 &&
                                                    platform_errors_state?.error
                                                        .custom &&
                                                    platform_errors_state?.error
                                                        .custom
                                                        .custom_message &&
                                                    platform_errors_state?.error.custom.custom_message.includes(
                                                        'internet'
                                                    ) ? (
                                                        <ErrorBody
                                                            onClick={() => {
                                                                navigate(
                                                                    generateRedirect(
                                                                        platform_errors_state.error
                                                                    )
                                                                )
                                                                handleUserAction()
                                                            }}
                                                            title={
                                                                'Looks like your internet has stopped working.'
                                                            }
                                                            subTitle={
                                                                'Check your connection and refresh.'
                                                            }
                                                            buttonText={
                                                                'Refresh page'
                                                            }
                                                            headIcon={
                                                                access_denied
                                                            }
                                                            isRefresh
                                                            isModal
                                                        />
                                                    ) : (
                                                        <>
                                                            <ErrorBody
                                                                onClick={
                                                                    platform_errors_state
                                                                        .error
                                                                        .custom &&
                                                                    platform_errors_state
                                                                        .error
                                                                        .custom
                                                                        .custom_redirect_path ===
                                                                        'reload_page'
                                                                        ? () =>
                                                                              navigate(
                                                                                  0
                                                                              )
                                                                        : () => {
                                                                              navigate(
                                                                                  generateRedirect(
                                                                                      platform_errors_state.error
                                                                                  )
                                                                              )
                                                                              handleUserAction()
                                                                          }
                                                                }
                                                                title={
                                                                    generateTextError(
                                                                        platform_errors_state.error
                                                                    ).text
                                                                }
                                                                subTitle={
                                                                    generateTextError(
                                                                        platform_errors_state.error
                                                                    ).detail
                                                                }
                                                                buttonText={generateCustomUserActionText(
                                                                    platform_errors_state.error
                                                                )}
                                                                headIcon={
                                                                    warning
                                                                }
                                                                isRefresh
                                                                isModal
                                                            />
                                                        </>
                                                    )}
                                                </Container>
                                            </Wrapper>
                                        </ModalDisplay>
                                    ))}
                            </div>
                        )}
                </div>
            )}
        </>
    )
}

export default BackEndError
