import * as React from 'react'
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
    Dot,
} from 'pure-react-carousel'

import 'pure-react-carousel/dist/react-carousel.es.css'
import SlideTheme2Desktop from '../../../atoms/slider/theme2/slideTheme2Desktop'

import carousel_arrow_next from '../../../../public/assets/navigation/carousel_arrow_next.svg'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

import './styles.css'

const StyledImage = styled.img`
    width: 16px;
    transition: all 200ms;
    :hover {
        transform: scale(1.2);
        transition: all 200ms;
    }

    @media ${device.large_desktop} {
        width: 20px;
    }
`
const StyledImage2 = styled.img`
    transform: rotate(180deg);
    width: 16px;
    transition: all 200ms;
    :hover {
        transform: scale(1.2);
        transition: all 200ms;
    }

    @media ${device.large_desktop} {
        width: 20px;
    }
`

const CarouselBtnNextPosition = styled.div`
    position: absolute;
    right: 100px;
    top: 24vh;

    @media ${device.ipad} {
        display: none;
    }
`
const CarouselBtnBackPosition = styled.div`
    position: absolute;
    left: 100px;
    top: 24vh;

    @media ${device.ipad} {
        display: none;
    }
`

const CustomDotgroup = styled.div`
    position: absolute;
    z-index: 5 !important;
    width: 100%;
    box-sizing: border-box;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;

    @media screen and (max-width: 1400px) {
        margin-top: 360px;
    }

    @media screen and (min-width: 1400px) {
        margin-top: 460px;
    }

    @media screen and (min-width: 1600px) {
        margin-top: 555px;
    }
`

type IProps = {
    slides: any
}

const SliderApexHomepageDesktop: React.FC<IProps> = ({ slides }) => {
    return (
        <CarouselProvider
            naturalSlideWidth={2}
            naturalSlideHeight={1}
            totalSlides={slides.length}
            visibleSlides={1}
            className="slider-custom-2"
            infinite={true}
        >
            <Slider>
                {slides.map((item: number, index: any) => {
                    return (
                        <Slide index={0} key={index}>
                            <SlideTheme2Desktop slide={slides[index]} />
                        </Slide>
                    )
                })}
            </Slider>

            <CustomDotgroup>
                {slides.map((item: number, index: any) => {
                    return (
                        <Dot
                            className="custom-dot-carousel"
                            slide={index}
                            key={index}
                        />
                    )
                })}
            </CustomDotgroup>

            <CarouselBtnNextPosition>
                <ButtonNext>
                    <StyledImage src={carousel_arrow_next} alt="arrow" />
                </ButtonNext>
            </CarouselBtnNextPosition>

            <CarouselBtnBackPosition>
                <ButtonBack>
                    <StyledImage2 src={carousel_arrow_next} alt="arrow" />
                </ButtonBack>
            </CarouselBtnBackPosition>
        </CarouselProvider>
    )
}

export default SliderApexHomepageDesktop
