import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Title = styled.div`
    font-family: Lato-Light;
    font-size: 27px;
    text-transform: uppercase;
    padding-bottom: 12px;
    color: var(--primary, #5ec3ca) !important;
`

const Text = styled.div`
    display: flex;
    text-align: center;
    max-width: 408px;
    font-family: Lato-Light;
    font-size: 19px;
    padding-bottom: 24px;
    color: var(--text-strong, #1a1a1a);
`

type Props = {
    title: string
    text: string
}

const ApexPageHeaderDesktop: React.FC<Props> = ({ title, text }) => {
    return (
        <Container>
            <Wrapper>
                <Title>{title}</Title>
                <Text>{text}</Text>
            </Wrapper>
        </Container>
    )
}

export default ApexPageHeaderDesktop
