import { useState } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Expander from '../expander/expander'
import CrossIcon from '../icons/crossIcon'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

const InfoBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    background-color: var(--primary_08);
    transition: all 100ms;
    color: var(--text-default, #666666);
    font-size: 14px;
    z-index: 5;
    width: 100%;
    box-sizing: border-box;
    height: 80px;
    box-sizing: border-box;

    @media ${device.beyond_ipad_mobile} {
        padding: 1rem 2rem;
        margin-top: -5px;
    }
    @media ${device.mobile_and_ipad} {
        font-size: 13px;
        padding: 0.75rem 1.5rem;
    }
`

const GarageArchiveInfoBar = () => {
    const [isDismissed, setIsDismissed] = useState<boolean>(false)
    const { theme } = useThemes()
    return (
        <Expander height={isDismissed ? 0 : 'auto'}>
            <InfoBox>
                <div>
                    If you archived your car by mistake, contact us at{' '}
                    <a
                        style={{ color: colours[theme].primary }}
                        href="mailto:hello@custodia.club?subject=Help with an archived car."
                    >
                        hello@custodian.club
                    </a>{' '}
                    and we’ll help you out.
                </div>

                <div style={{ paddingLeft: '10px' }} />

                <div
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setIsDismissed(true)
                    }}
                >
                    <CrossIcon size="12px" />
                </div>
            </InfoBox>
        </Expander>
    )
}

export default GarageArchiveInfoBar
