import {
    IApexSingleRichBodyState,
    IHybridPrismicPropsToState,
    IPrismicFieldPropsToState,
} from 'apexModels'
import React from 'react'

import styled from 'styled-components'
import ApexDuoHybridImageLandscapePortraitSliceMobile from '../../../molecules/apex/reusable/richcontent/duoImg/hybridDuoImg/mobile/apexDuoHybridImageLandscapePortraitSliceMobile'
import ApexDuoHybridImagePortraitLandscapeSliceMobile from '../../../molecules/apex/reusable/richcontent/duoImg/hybridDuoImg/mobile/apexDuoHybridImagePortraitLandscapeSliceMobile'
import ApexDuoImageLandscapeSliceMobile from '../../../molecules/apex/reusable/richcontent/duoImg/simpleDuoImg/mobile/apexDuoImageLandscapeSliceMobile'
import ApexDuoImagePortraitSliceMobile from '../../../molecules/apex/reusable/richcontent/duoImg/simpleDuoImg/mobile/apexDuoImagePortraitSliceMobile'
import ApexRichTextImageInsertedMobile from '../../../molecules/apex/reusable/richcontent/singleImg/mobile/apexRichTextImageInsertedMobile'
import ApexSingleImageLandscapeSliceMobile from '../../../molecules/apex/reusable/richcontent/singleImg/mobile/apexSingleImageLandscapeSliceMobile'
import ApexSingleImagePortraitSliceMobile from '../../../molecules/apex/reusable/richcontent/singleImg/mobile/apexSingleImagePortraitSliceMobile'
import ApexBodyRichContentTextItemMobile from '../../../molecules/apex/reusable/richcontent/text/mobile/apexBodyRichContentTextItemMobile'

import { device } from '../../../templates/displays/devices'

const Wrapper = styled.div`
    @media ${device.beyond_ipad_mobile} {
    }
`

type Props = {
    rich_objects_array_without_slice?: IHybridPrismicPropsToState[]
    rich_slice_objects_array?: IApexSingleRichBodyState[]
}

type SwitchPropsNoSlice = {
    obj: IHybridPrismicPropsToState
}

type SwitchSliceProps = {
    obj: IApexSingleRichBodyState
}

const SwitchComponentNoSlice: React.FunctionComponent<SwitchPropsNoSlice> = (
    props
) => {
    let { obj } = props
    return (
        <>
            {(() => {
                switch (obj.type) {
                    case 'text':
                        return <ApexBodyRichContentTextItemMobile item={obj} />
                    case 'image':
                        return <ApexRichTextImageInsertedMobile img_obj={obj} />
                    default:
                        return <div />
                }
            })()}
        </>
    )
}

const SwitchSliceComponent: React.FunctionComponent<SwitchSliceProps> = (
    props
) => {
    let { obj } = props
    return (
        <>
            {(() => {
                switch (obj.slice_type) {
                    case 'body_rich_content':
                        return (
                            <>
                                {obj.text_array ? (
                                    obj.text_array.map(
                                        (obj: IPrismicFieldPropsToState) => {
                                            return (
                                                <ApexBodyRichContentTextItemMobile
                                                    item={obj}
                                                />
                                            )
                                        }
                                    )
                                ) : (
                                    <div />
                                )}
                            </>
                        )

                    case 'single_image_portrait':
                        return (
                            <>
                                {obj.img && (
                                    <ApexSingleImagePortraitSliceMobile
                                        img_obj={obj.img}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )

                    case 'single_image_landscape':
                        return (
                            <>
                                {obj.img && (
                                    <ApexSingleImageLandscapeSliceMobile
                                        img_obj={obj.img}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )
                    case 'duo_img_lansdcape_lansdcape':
                        return (
                            <>
                                {obj.img_1 && obj.img_2 && (
                                    <ApexDuoImageLandscapeSliceMobile
                                        img_1={obj.img_1}
                                        img_2={obj.img_2}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )
                    case 'duo_img_portrait_portrait':
                        return (
                            <>
                                {obj.img_1 && obj.img_2 && (
                                    <ApexDuoImagePortraitSliceMobile
                                        img_1={obj.img_1}
                                        img_2={obj.img_2}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )
                    case 'duo_img_hybrid_landscape_portrait':
                        return (
                            <>
                                {obj.img_1 && obj.img_2 && (
                                    <ApexDuoHybridImageLandscapePortraitSliceMobile
                                        img_1={obj.img_1}
                                        img_2={obj.img_2}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )
                    case 'duo_img_hybrid_portrait_landscape':
                        return (
                            <>
                                {obj.img_1 && obj.img_2 && (
                                    <ApexDuoHybridImagePortraitLandscapeSliceMobile
                                        img_1={obj.img_1}
                                        img_2={obj.img_2}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )

                    default:
                        return <div />
                }
            })()}
        </>
    )
}

const ApexBodyRichContentManagerMobile: React.FC<Props> = ({
    rich_objects_array_without_slice,
    rich_slice_objects_array,
}) => {
    const goThroughArrayWithoutSlice = (arr: IHybridPrismicPropsToState[]) => {
        return arr.map((obj: IHybridPrismicPropsToState, index: number) => {
            return <SwitchComponentNoSlice obj={obj} key={index} />
        })
    }

    const goThroughSlicedObjects = (arr: IApexSingleRichBodyState[]) => {
        return arr.map((obj: IApexSingleRichBodyState, index: number) => {
            return <SwitchSliceComponent obj={obj} key={index} />
        })
    }

    return (
        <Wrapper>
            {rich_objects_array_without_slice !== undefined &&
                goThroughArrayWithoutSlice(rich_objects_array_without_slice)}

            {rich_slice_objects_array !== undefined &&
                goThroughSlicedObjects(rich_slice_objects_array)}
        </Wrapper>
    )
}

export default ApexBodyRichContentManagerMobile
