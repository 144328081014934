import VehicleItemDesktop from '../../../atoms/list/vehicleItemDesktop'
import VehicleItemMobile from '../../../atoms/list/vehicleItemMobile'
import { IInsuranceQuoteApplication_VehiclesObj } from '../../../../redux/insuranceQuoteApplication/reducer'
import { useState } from 'react'
import { Tooltip } from 'antd'
import { ICarsObject } from '../../../../redux/entities/cars/types'

type Props = {
    garageVehicles: ICarsObject
    vehicles: IInsuranceQuoteApplication_VehiclesObj
    everyday_vehicles_list?: string[]
    external_vehicles_list?: string[]
    insured_vehicles_list?: string[]
    onCardClick: (id: string) => void
    onRemove: (id: string) => void
    isMobile?: boolean
}
const InsuranceEverydayVehiclesCardContent = (props: Props) => {
    let {
        vehicles,
        garageVehicles,
        everyday_vehicles_list,
        external_vehicles_list,
        insured_vehicles_list,
        onCardClick,
        onRemove,
        isMobile,
    } = props

    const [showTooltip, toggleTooltip] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(-1)

    const determineIfIsInsuredCar = (vehicle_id: string) =>
        insured_vehicles_list &&
        insured_vehicles_list.indexOf(vehicle_id) !== -1
            ? true
            : false

    let determineIfIsEverydayCar = (vehicle_id: string) =>
        everyday_vehicles_list?.includes(vehicle_id) ? true : false
    let determineIfIsExternalCar = (vehicle_id: string) =>
        external_vehicles_list?.includes(vehicle_id) ? true : false

    return (
        <div style={{ width: '100%', boxSizing: 'border-box', paddingTop: 16 }}>
            {everyday_vehicles_list?.map((vehicle_id, i) => {
                let isInsuredCar = determineIfIsInsuredCar(vehicle_id)
                let isExternalCar = determineIfIsExternalCar(vehicle_id)
                let isEverydayCar = determineIfIsEverydayCar(vehicle_id)
                let restrictClick = isInsuredCar && isEverydayCar ? true : false
                return (
                    <div
                        key={`${vehicle_id}_${i}_${
                            isMobile ? 'mobile' : 'desktop'
                        }`}
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            paddingBottom: 8,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (restrictClick && showTooltip === false) {
                                toggleTooltip(true)
                                setCurrentIndex(i)
                                setTimeout(() => {
                                    toggleTooltip(false)
                                    setCurrentIndex(-1)
                                }, 2000)
                            } else {
                                !restrictClick && onCardClick(vehicle_id)
                            }
                        }}
                    >
                        <Tooltip
                            title={
                                "You can edit this car from the 'Vehicles to insure' section above"
                            }
                            open={showTooltip && currentIndex === i}
                            placement={isMobile ? 'top' : 'right'}
                            color={'#333'}
                        >
                            {isMobile ? (
                                <VehicleItemMobile
                                    garageItem={garageVehicles[vehicle_id]}
                                    insuranceVehicleItem={vehicles[vehicle_id]}
                                    variant="everyday_car_card_content"
                                    onEverydayCarDelete={() => {
                                        onRemove(vehicle_id)
                                    }}
                                    isExternalCar={isExternalCar}
                                    isInsuredCar={isInsuredCar}
                                    completion={
                                        vehicles &&
                                        vehicles[vehicle_id]?.completion
                                    }
                                    sectionId="everyday_vehicles"
                                />
                            ) : (
                                <VehicleItemDesktop
                                    garageItem={garageVehicles[vehicle_id]}
                                    insuranceVehicleItem={vehicles[vehicle_id]}
                                    variant="everyday_car_card_content"
                                    onEverydayCarDelete={() => {
                                        onRemove(vehicle_id)
                                    }}
                                    isExternalCar={isExternalCar}
                                    isInsuredCar={isInsuredCar}
                                    completion={
                                        vehicles &&
                                        vehicles[vehicle_id]?.completion
                                    }
                                    sectionId="everyday_vehicles"
                                />
                            )}
                        </Tooltip>
                    </div>
                )
            })}
            {/* {!everyday_car_id && (
                <div style={{width: '100%',
      boxSizing:'border-box', paddingTop: 8 }}>
                    <InfoBox
                        text={
                            'One of the cars needs to be marked as an Everyday car, a car you drive the most. This information helps us understand your needs better and offer you the best insurance options.'
                        }
                        variant="error"
                    />
                </div>
            )} */}
        </div>
    )
}

export default InsuranceEverydayVehiclesCardContent
