import { AnimatePresence, motion, Variants } from 'framer-motion'
import { forwardRef } from 'react'
import styled from 'styled-components'

interface DropdownProps
    extends React.PropsWithChildren<{
        isOpen: boolean
        nudgeUp?: boolean
    }> {}

export default forwardRef<HTMLDivElement, DropdownProps>(function Dropdown(
    props,
    ref
) {
    return (
        <AnimatePresence>
            {props.isOpen && (
                <Listbox
                    id="scrollable-content"
                    ref={ref}
                    variants={listboxAnimationVariants}
                    custom={{ nudgeUp: props.nudgeUp }}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.15 }}
                >
                    {props.children}
                </Listbox>
            )}
        </AnimatePresence>
    )
})

const listboxAnimationVariants: Variants = {
    initial: ({ nudgeUp }: { nudgeUp?: boolean }) => ({
        opacity: 0,
        scaleY: 0.95,
        originY: 0,
        originX: 0,
        y: nudgeUp ? -20 : 0,
    }),
    animate: ({ nudgeUp }: { nudgeUp?: boolean }) => ({
        opacity: 1,
        scaleY: 1,
        originY: 0,
        originX: 0,
        y: nudgeUp ? -20 : 0,
    }),
    exit: ({ nudgeUp }: { nudgeUp?: boolean }) => ({
        opacity: 0,
        scaleY: 0.95,
        originY: 0,
        originX: 0,
        y: nudgeUp ? -20 : 0,
    }),
}

const Listbox = motion(styled.div`
    position: absolute;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    max-height: 210px;
    overflow-y: auto;
    box-shadow: 0px 0px 20px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
`)
