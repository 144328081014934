import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import { useState } from 'react'
import GooglePlacesLocationsDropdown from '../../../molecules/googlePlacesLocationsDropdown/googlePlacesLocationsDropdownDesktop'
import useThemes from '../../../../providers/theme/hooks'
import {
    IGalleryImage,
    IUpdateCarGalleryImagePayload,
    IUpdateEntryGalleryImagePayload,
} from '../../../../redux/entities/galleries/types'

const EditFormContainerDesktop = styled.section`
    display: none;

    @media ${device.large_desktop} {
        margin-top: 18vh !important;
    }

    @media ${device.desktop} {
        margin-top: 18vh !important;
    }

    @media ${device.smallest_laptops} {
        margin-top: 10vh !important;
    }

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        min-width: 670px;
        margin-top: 15vh;
        padding-bottom: 30px;
        padding-top: 30px;
        max-height: 70vhpx;
        background-color: var(--bg-color, #fff);
        color: var(--text-strong, #1a1a1a);
        border-radius: 5px;
        position: relative;
        overflow-y: auto;
        margin-bottom: 50px;
        /* width */
        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: var(--scroll-bar-track-color);
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--scroll-bar-handle-color);
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: var(--scroll-bar-handle-color);
        }
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    box-sizing: border-box;
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 15px;
    text-transform: capitalize;

    @media ${device.large_desktop} {
        font-size: 15px;
    }
`

const FormTitle = styled.div`
    font-family: Lato-light;
    align-self: start;
    font-size: 21px;
    text-transform: capitalize;
`
const SmallPadding = styled.div`
    padding-top: 20px;
`

const BiggerPadding = styled.div`
    padding-top: 25px;
`
const LargePadding = styled.div`
    padding-top: 60px;
`

export const Error = styled.div`
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 11px;
`

interface Values {
    caption: string
    location: string
    credits: string
}

interface OtherProps {
    close: () => void
    updateImage: (data: any) => any
    carid: string
    entryid?: string
    imagedata: IGalleryImage
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { values, close, errors, setFieldValue } = props

    const [showLocationSuggestions, toggleShowLocationSuggestion] =
        useState(false)

    const handleSuggestionSelection = (suggestion: any) => {
        setFieldValue(
            'location',
            suggestion.structured_formatting
                ? suggestion.structured_formatting.main_text
                : suggestion.description
        )
    }

    const { theme } = useThemes()

    return (
        <EditFormContainerDesktop>
            <InnerFormPadding>
                <FormTitle>Update image details</FormTitle>
                <BiggerPadding />
                <FieldTitle>Caption</FieldTitle>
                <Expander
                    height={
                        values.caption.length > 1 && errors.caption ? 'auto' : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.caption}
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id="caption"
                    name="caption"
                    placeholder="caption"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.caption}
                />
                <BiggerPadding />
                <FieldTitle>Location</FieldTitle>
                <Expander
                    height={
                        errors.location && values.location.length > 1
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.location}
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id="location"
                    name="location"
                    placeholder="location"
                    tabindex={2}
                    aria-live="polite"
                    formikprops={props}
                    value={values.location}
                    onFocus={() => {
                        toggleShowLocationSuggestion(true)
                    }}
                />
                <GooglePlacesLocationsDropdown
                    valueSearched={values.location}
                    showDropdown={showLocationSuggestions}
                    toggleShowDropDown={(how: boolean) =>
                        toggleShowLocationSuggestion(how)
                    }
                    onSuggestionClick={handleSuggestionSelection}
                />
                <BiggerPadding />
                <FieldTitle>Credits</FieldTitle>
                <Expander
                    height={
                        errors.credits && values.credits.length > 1 ? 'auto' : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.credits}
                        </Error>
                    </Faded>
                </Expander>
                <SmallPadding />
                <InputField
                    theme={theme}
                    id="credits"
                    name="credits"
                    placeholder="credits"
                    tabindex={3}
                    aria-live="polite"
                    formikprops={props}
                    value={values.credits}
                />
                <LargePadding />
                <ButtonsStandardBottomRowDesktop
                    cancel={close}
                    submit={props.handleSubmit}
                    isDisabled={
                        (values.caption && errors.caption) ||
                        (values.location && errors.caption) ||
                        (values.credits && errors.credits) ||
                        (values.caption === '' &&
                            values.location === '' &&
                            values.credits === '')
                            ? true
                            : false
                    }
                    dataCyIdUpdate="edit_car_image_details_desktop"
                    dataCyIdCancel="cancel_edit_car_image_details_desktop"
                />
            </InnerFormPadding>
        </EditFormContainerDesktop>
    )
}

const CarGalleryEditExistingImageFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        caption:
            props.imagedata && props.imagedata.caption
                ? props.imagedata.caption
                : '',
        location:
            props.imagedata && props.imagedata.location
                ? props.imagedata.location
                : '',
        credits:
            props.imagedata && props.imagedata.credits
                ? props.imagedata.credits
                : '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                caption: Yup.string().max(
                    266,
                    'Caption must be be less than 265 characters.'
                ),
                location: Yup.string().max(
                    36,
                    'Location must be be less than 35 characters.'
                ),

                credits: Yup.string().max(
                    36,
                    'Credits must be be less than 35 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        if (props.entryid) {
            let payload: IUpdateEntryGalleryImagePayload = {
                carid: props.carid,
                entryid: props.entryid,
                imageid: props.imagedata.id,
                body: {
                    caption: values.caption,
                    credit_data: {
                        name: values.credits,
                    },
                    location_data: {
                        name: values.location,
                    },
                },
            }
            props.updateImage(payload)
            props.close()
        } else {
            let payload: IUpdateCarGalleryImagePayload = {
                carid: props.carid,
                imageid: props.imagedata.id,
                body: {
                    caption: values.caption,
                    credit_data: {
                        name: values.credits,
                    },
                    location_data: {
                        name: values.location,
                    },
                },
            }
            props.updateImage(payload)
            props.close()
        }

        return
    },
})(InnerForm)

const CarGalleryEditExistingImageFormDesktopWithHooks = (props: OtherProps) => {
    return <CarGalleryEditExistingImageFormDesktop {...props} />
}

export default CarGalleryEditExistingImageFormDesktopWithHooks
