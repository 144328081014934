/* eslint-disable no-useless-escape */
import { withFormik, FormikProps } from 'formik'
import InputField from '../../../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowMobile from '../../../buttons/buttonsStandardBottomRowMobile'
import Expander from '../../../../../atoms/expander/expander'
import Faded from '../../../../../templates/animated/faded'
import StyledFormError from '../../../../../templates/styledcomponents/styledformerrormessage'
import { getRightSchemaAccountData } from '../../validationSchema'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import styled from 'styled-components'
import { validateEmail } from '../../../../../../helpers/email/validate'
import useThemes from '../../../../../../providers/theme/hooks'
import { IUSerGeneralInfoUpdatePayload } from '../../../../../../redux/user/types'

const SmallPadding = styled.div`
    padding-top: 24px;
`

interface Values {
    item_edited: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: any
    userLoggedIn: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        errors,
        // touched,
        // handleChange,
        isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props
    let isEmailValid = true

    if (item.id === 'email') {
        isEmailValid = validateEmail(values.item_edited)
    }

    const { theme } = useThemes()
    return (
        <ModalContentMobile
            $theme={theme}
            data-attr={`change-${
                item.id === 'given_name'
                    ? 'first_name'
                    : item.id === 'family_name'
                    ? 'last_name'
                    : item.id
            }-modal-mobile`}
        >
            <ModalTopBar $theme={theme}>
                <span>{item.text && item.text?.replace('*', '')}</span>
            </ModalTopBar>
            <ModalInnerContentWrapperMobile>
                <InputField
                    theme={theme}
                    id="item_edited"
                    name="item_edited"
                    placeholder={item.text}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.item_edited}
                    font_family="Lato"
                    dataCyId={`${
                        item.id === 'given_name'
                            ? 'first_name'
                            : item.id === 'family_name'
                            ? 'last_name'
                            : item.id
                    }-input-field-mobile`}
                    onEnterKeyPress={props.handleSubmit}
                />
                {item.id === 'email' && isEmailValid === false ? (
                    <Expander height={isEmailValid === false ? 'auto' : 0}>
                        <Faded>
                            <StyledFormError>Email not valid</StyledFormError>
                        </Faded>
                    </Expander>
                ) : (
                    <Expander
                        height={errors.item_edited ? 'auto' : 0}
                        dataCyId={`${
                            item.id === 'given_name'
                                ? 'first_name'
                                : item.id === 'family_name'
                                ? 'last_name'
                                : item.id
                        }-input-field-error-mobile`}
                    >
                        <Faded>
                            <StyledFormError>
                                {errors.item_edited}
                            </StyledFormError>
                        </Faded>
                    </Expander>
                )}
                <SmallPadding />
                <ButtonsStandardBottomRowMobile
                    cancel={toggle}
                    submit={props.handleSubmit}
                    confirmationtext="update"
                    disabled={
                        isSubmitting ||
                        errors.item_edited !== undefined ||
                        isEmailValid === false
                    }
                    dataCyIdUpdate={`submit-new-${
                        item.id === 'given_name'
                            ? 'first_name'
                            : item.id === 'family_name'
                            ? 'last_name'
                            : item.id
                    }-mobile`}
                    dataCyIdCancel={`cancel-new-${
                        item.id === 'given_name'
                            ? 'first_name'
                            : item.id === 'family_name'
                            ? 'last_name'
                            : item.id
                    }-mobile`}
                />{' '}
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const BasicAccountPrivateInfoFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        item_edited: props.item.answer,
    }),
    validationSchema: (props: any) => getRightSchemaAccountData(props.item.id),

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let answer: any
        if (props.item.answer !== values.item_edited) {
            if (props.item.id === 'email') {
                answer = values.item_edited.toLocaleLowerCase()
            } else {
                answer = values.item_edited
            }

            let final: IUSerGeneralInfoUpdatePayload = {
                id: props.userLoggedIn.id,
                data: {},
            }

            if (props.item.id === 'given_name' && final && final.data) {
                final.data.given_name = answer
            }

            if (props.item.id === 'family_name' && final && final.data) {
                final.data.family_name = answer
            }

            props.submitFunc && props.submitFunc(final)
        }
        props.toggle()
    },
})(InnerForm)

export default BasicAccountPrivateInfoFormMobile
