import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import ReadOnlyCarHistoryFile from '../../../templates/readOnly/car/readOnlyHistoryFile'
import { ICarsObject, ICar } from '../../../../redux/entities/cars/types'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'

type CarParams = {
    carid: string
}

const CarHistoryFileArchive = () => {
    const { carid } = useParams<CarParams>()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const carsData: ICarsObject = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let current: ICar | undefined = carsData[`${carid}`]

    const dispatch = useAppDispatch()

    useEffect(() => {
        // if (!current) {
        // for now always get on render ( we'll add the has_limited_garage_info check tho)
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/archives/car/${carid}/history-file`
            )
        )
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            dispatch(carActions.setCurrentCar(`${carid}`))
            dispatch(carActions.getCarDataByIdRequest(`${carid}`))
        }

        // }
    }, [userLoggedIn])

    return (
        <React.Fragment>
            {current && userLoggedIn && (
                <ReadOnlyCarHistoryFile
                    userLoggedIn={userLoggedIn}
                    prevOwned={current}
                />
            )}
        </React.Fragment>
    )
}

export default CarHistoryFileArchive
