import styled from 'styled-components'

import { Link, useLocation } from 'react-router-dom'

import technical_info_icon_green from '../../../../public/assets/icons/technical_info_icon_green.svg'
import tasks_menu_icon_green from '../../../../public/assets/icons/tasks_menu_icon_green.svg'
import car_overview_icon_green from '../../../../public/assets/icons/car_overview_icon_green.svg'
import timeline_blue_icon from '../../../../public/assets/icons/timeline_blue_icon.svg'
import gallery_icon_green from '../../../../public/assets/icons/gallery_icon_green.svg'
import chevron_black_to_right from '../../../../public/assets/icons/chevron_black_to_right.svg'
import book from '../../../../public/assets/icons/book_green.svg'
import article from '../../../../public/assets/icons/article_green.svg'
import headphones from '../../../../public/assets/icons/headphones_green.svg'
import apexLogo from '../../../../public/assets/icons/apex_logo_green.svg'
import flash from '../../../../public/assets/icons/flash_green.svg'
import smaller_car from '../../../../public/assets/icons/car_logo_smaller.svg'
import shared_w_others from '../../../../public/assets/icons/shared_w_others_green.svg'
import shared_w_you from '../../../../public/assets/icons/shared_w_you_green.svg'
import get_link_icon_green from '../../../../public/assets/icons/get_link_icon_green.svg'

import { subMenuList, matchPageSubGroup } from '../mainmenu/mainMenuData'
import { motion } from 'framer-motion'

import { Sheet } from 'react-modal-sheet'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'

import close_cross_grey from '../../../../public/assets/icons/close_cross_grey.svg'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useEffect } from 'react'
import { ICarsState, IExternalCar } from '../../../../redux/entities/cars/types'

type IStyle = {
    islast?: boolean
    isactive?: boolean
    $isCarPage?: boolean
    isDisabled?: boolean
}

const CustomSheet = styled(Sheet)<IStyle>`
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }

    .react-modal-sheet-container {
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;

        @media only screen and (min-device-height: 601px) and (max-device-height: 699px) {
            height: ${(props) =>
                props.$isCarPage ? '64vh !important' : '64vh !important'};
        }

        @media only screen and (min-device-height: 700px) and (max-device-height: 799px) {
            height: ${(props) =>
                props.$isCarPage ? '58vh !important' : '58vh !important'};
        }

        @media only screen and (min-device-height: 800px) and (max-device-height: 999px) {
            height: ${(props) =>
                props.$isCarPage ? '52vh !important' : '52vh !important'};
        }

        @media only screen and (min-device-height: 1000px) and (max-device-height: 1200px) {
            height: ${(props) =>
                props.$isCarPage ? '45vh !important' : '45vh !important'};
        }

        @media only screen and (max-device-height: 600px) {
            height: ${(props) =>
                props.$isCarPage ? '72vh !important' : '72vh !important'};
        }
    }

    .react-modal-sheet-header {
        height: 20px !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 30px !important;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`
const MenuItem = styled(Link)<IStyle>`
    color: ${(props) =>
        props.isDisabled ? '#aaaaaa !important' : '#0e0e0e !important'};
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    opacity: 1 !important;
    font-family: 'Lato';
    font-size: 14px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    letter-spacing: 0.09rem;

    ${(props) => props.isDisabled && 'pointer-events: none;'}
`

const MenuItemBody = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const MenuItemBox = styled(motion.div)<IStyle>`
    background-color: ${(props) =>
        props.isactive ? 'rgba(111, 237, 237, 0.07)' : '#f9f9f9'};
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: row;
    color: #5ec3ca !important;
    border-bottom: ${(props) => (props.islast ? 'none' : '1px solid #f1f1f1')};
    :focus {
        outline: 0;
    }
`

const BottomSheetHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 25px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
`

const FlexRow = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 10px;
`

const BottomSheetHeaderClose = styled.img`
    height: 14px;
    width: 14px;
    left: 28px;

    z-index: 3;
    position: absolute;
`

const BottomSheetHeaderCloseSmall = styled.img`
    height: 12px;
    width: 12px;
    margin-left: 28px;
    z-index: 3;
    margin-top: 10px;
`

const BottomSheetHeaderText = styled.div`
    font-size: 16px;
    font-family: 'Lato-bold';
    color: #0e0e0e;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 60%;
`

const BottomSheetHeaderTitle = styled.div`
    font-size: 12px;
    font-family: 'Lato';
    color: #959595;
    text-transform: uppercase;
    padding-top: 10px;
`

const BottomSheetHeaderSubText = styled.div`
    font-size: 15px;
    font-family: 'Lato';
    color: #393939;
    text-transform: capitalize;
    padding-left: 20px;
    padding-bottom: 25px;
`

const HorizontalLine = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: #e5e5e5;
`

interface IProps {
    toggle: any
    activepage: string
    isOpen: boolean
    currentCarID: string | undefined
    carsData: ICarsState
    externalCarsData: { [key: string]: IExternalCar } | undefined
    currentExternalCarId: string | undefined
}

const MainSubmenuBottomSheetMobile = (props: IProps) => {
    const {
        isOpen,
        toggle,
        activepage,
        currentCarID,
        carsData,
        externalCarsData,
        currentExternalCarId,
    } = props

    const location = useLocation()

    let subPageGroup = matchPageSubGroup(activepage)
    const subListItems = subMenuList[`${subPageGroup}`]
    // let currentPath = checkIfPwaStandalone()
    //     ? window.location.pathname
    //     : window.location.pathname

    const getHeaderText = () => {
        if (subPageGroup === '/car') {
            if (
                currentCarID &&
                carsData &&
                carsData.cars &&
                carsData.cars[currentCarID] &&
                carsData.cars[currentCarID].title
            ) {
                return carsData.cars[currentCarID].title
            } else return 'Your car'
        } else if (subPageGroup === '/sharing') {
            return 'Sharing'
        } else if (subPageGroup === '/apex') {
            return 'The Apex'
        } else return ''
    }

    let genPathname = (item: any) => {
        if (item.url === '/car/gallery') {
            return `/car/${currentCarID}/gallery`
        }

        if (item.url === '/car/technical-information') {
            return `/car/${currentCarID}/technical-information`
        }

        if (item.url === '/car/tasks') {
            return `/car/${currentCarID}/tasks`
        }

        if (item.url === '/car/history-file') {
            return `/car/${currentCarID}/history-file`
        }

        if (item.url === '/car/sharing') {
            return `/car/${currentCarID}/sharing`
        }

        if (item.url === '/car') {
            return `/car/${currentCarID}`
        }

        if (item.url === '/shared/car') {
            return `/shared/car/${currentExternalCarId}`
        }

        if (item.url === '/shared/car/gallery') {
            return `/shared/car/${currentExternalCarId}/gallery`
        }

        if (item.url === '/shared/car/technical-information') {
            return `/shared/car/${currentExternalCarId}/technical-information`
        }

        if (item.url === '/shared/car/tasks') {
            return `/shared/car/${currentExternalCarId}/tasks`
        }

        if (item.url === '/shared/car/history-file') {
            return `/shared/car/${currentExternalCarId}/history-file`
        }

        if (item.url === '/archives/car/overview') {
            return `/archives/car/${currentExternalCarId}/overview`
        }

        if (item.url === '/archives/car/gallery') {
            return `/archives/car/${currentExternalCarId}/gallery`
        }

        if (item.url === '/archives/car/technical-information') {
            return `/archives/car/${currentExternalCarId}/technical-information`
        }

        if (item.url === '/archives/car/history-file') {
            return `/archives/car/${currentExternalCarId}/history-file`
        }

        if (item.url === '/car/insights') {
            return `/car/${currentCarID}/insights`
        }

        return item.url
    }

    let checkIfActivePage = (activepage: string, item_url: string) => {
        if (
            item_url === '/shared/car/technical-information' &&
            activepage ===
                `/shared/car/${currentExternalCarId}/technical-information`
        ) {
            return true
        }

        if (
            item_url === '/shared/car/gallery' &&
            activepage === `/shared/car/${currentExternalCarId}/gallery`
        ) {
            return true
        }

        if (
            item_url === '/shared/car' &&
            activepage === `/shared/car/${currentExternalCarId}`
        ) {
            return true
        }

        if (
            item_url === '/shared/car/history-file' &&
            activepage === `/shared/car/${currentExternalCarId}/history-file`
        ) {
            return true
        }

        if (
            item_url === '/archives/car/technical-information' &&
            activepage ===
                `/archives/car/${currentExternalCarId}/technical-information`
        ) {
            return true
        }

        if (
            item_url === '/archives/car/gallery' &&
            activepage === `/archives/car/${currentExternalCarId}/gallery`
        ) {
            return true
        }

        if (
            item_url === '/archives/car/overview' &&
            activepage === `/archives/car/${currentExternalCarId}/overview`
        ) {
            return true
        }

        if (
            item_url === '/archives/car/history-file' &&
            activepage === `/archives/car/${currentExternalCarId}/history-file`
        ) {
            return true
        }

        if (
            item_url === '/car/gallery' &&
            activepage === `/car/${currentCarID}/gallery`
        ) {
            return true
        }

        if (
            item_url === '/car/technical-information' &&
            activepage === `/car/${currentCarID}/technical-information`
        ) {
            return true
        }

        if (
            item_url === '/car/tasks' &&
            activepage === `/car/${currentCarID}/tasks`
        ) {
            return true
        }

        if (
            item_url === '/car/history-file' &&
            activepage === `/car/${currentCarID}/history-file`
        ) {
            return true
        }

        if (
            item_url === '/car/sharing' &&
            activepage === `/car/${currentCarID}/sharing`
        ) {
            return true
        }
        if (item_url === '/car' && activepage === `/car/${currentCarID}`) {
            return true
        }

        if (item_url === '/garage' && activepage === `/garage`) {
            return true
        }

        if (item_url === '/garage' && activepage === `/`) {
            return true
        }
        if (item_url === '/history-files' && activepage === `/history-files`) {
            return true
        }

        if (item_url === activepage) {
            return true
        } else return false
    }

    const isExternalCar: boolean =
        location.pathname.match(/shared/g) !== null ? true : false

    useEffect(() => {
        if (isOpen) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [isOpen])

    return (
        <IpadAndMobileDisplay>
            <CustomSheet
                isOpen={isOpen}
                onClose={() => toggle(false)}
                $isCarPage={subPageGroup === '/car'}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    {isExternalCar ? (
                        <BottomSheetHeader
                            style={{
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <FlexRow
                                style={{
                                    paddingLeft: '20px',
                                    paddingRight: '25px',
                                }}
                            >
                                <BottomSheetHeaderTitle>
                                    Shared with you
                                </BottomSheetHeaderTitle>
                                <BottomSheetHeaderCloseSmall
                                    src={close_cross_grey}
                                    onClick={() => toggle(false)}
                                    style={{ transform: 'translateY(0px)' }}
                                />
                            </FlexRow>
                            <BottomSheetHeaderSubText>
                                {externalCarsData &&
                                    currentExternalCarId &&
                                    externalCarsData[currentExternalCarId] &&
                                    externalCarsData[currentExternalCarId]
                                        .title &&
                                    (externalCarsData[currentExternalCarId]
                                        .title.length > 34
                                        ? `${externalCarsData[
                                              currentExternalCarId
                                          ].title.slice(0, 34)}...`
                                        : externalCarsData[currentExternalCarId]
                                              .title)}
                            </BottomSheetHeaderSubText>
                            <HorizontalLine />
                        </BottomSheetHeader>
                    ) : (
                        <BottomSheetHeader>
                            <BottomSheetHeaderClose
                                src={close_cross_grey}
                                onClick={() => toggle(false)}
                            />
                            <BottomSheetHeaderText>
                                {getHeaderText()}
                            </BottomSheetHeaderText>
                        </BottomSheetHeader>
                    )}
                    <div
                        style={{
                            backgroundColor: '#f9f9f9',
                            paddingTop: '0px',
                        }}
                        data-attr="car-sub-menu"
                    >
                        {subListItems !== undefined &&
                            subListItems.map((item: any, index: any) => (
                                <MenuItemBox
                                    isactive={checkIfActivePage(
                                        activepage,
                                        item.url
                                    )}
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        setTimeout(function () {
                                            toggle()
                                        }, 50)
                                    }}
                                    key={`bottomsheet_main_menu_${item.id}_${index}_mobile`}
                                    whileTap={{
                                        opacity: 1,
                                        backgroundColor: '#e7e7e7',
                                    }}
                                    islast={index === subListItems.length - 1}
                                >
                                    <MenuItem
                                        to={{
                                            pathname: genPathname(item),
                                            // state: {
                                            //     prevPath: currentPath,
                                            // },
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '30px',
                                            }}
                                        >
                                            <img
                                                src={
                                                    item.pageName ===
                                                        'overview' &&
                                                    item.url === '/car'
                                                        ? car_overview_icon_green
                                                        : item.pageName ===
                                                          'gallery'
                                                        ? gallery_icon_green
                                                        : item.pageName ===
                                                          'technical information'
                                                        ? technical_info_icon_green
                                                        : item.pageName ===
                                                          'tasks'
                                                        ? tasks_menu_icon_green
                                                        : item.pageName ===
                                                              'overview' &&
                                                          item.url === '/apex'
                                                        ? apexLogo
                                                        : item.pageName ===
                                                          'podcasts'
                                                        ? headphones
                                                        : item.pageName ===
                                                          'articles'
                                                        ? article
                                                        : item.pageName ===
                                                          'car of the week'
                                                        ? smaller_car
                                                        : item.pageName ===
                                                          'news highlights'
                                                        ? flash
                                                        : item.pageName ===
                                                          'interviews'
                                                        ? book
                                                        : item.pageName ===
                                                          'history file'
                                                        ? timeline_blue_icon
                                                        : item.pageName ===
                                                          'shared with others'
                                                        ? shared_w_others
                                                        : item.pageName ===
                                                          'shared with you'
                                                        ? shared_w_you
                                                        : item.pageName ===
                                                          'sharing'
                                                        ? get_link_icon_green
                                                        : car_overview_icon_green
                                                }
                                                alt={`${item.pageName} icon`}
                                                style={
                                                    item.pageName === 'sharing'
                                                        ? {
                                                              width: '18px',
                                                              transform:
                                                                  'translateY(-2px) rotate(45deg)',
                                                          }
                                                        : {}
                                                }
                                            />
                                        </div>
                                        <MenuItemBody>
                                            <span
                                                style={{
                                                    transform:
                                                        'translateY(1px)',
                                                    paddingLeft: '15px',
                                                }}
                                            >
                                                {item.pageName}
                                            </span>

                                            <img
                                                src={chevron_black_to_right}
                                                alt="chevron mobile"
                                            />
                                        </MenuItemBody>
                                    </MenuItem>
                                </MenuItemBox>
                            ))}
                    </div>
                </Sheet.Container>
                <Sheet.Backdrop
                    data-attr="account-menu-backdrop-overlay"
                    onTap={(e: any) => {
                        e.preventDefault()
                        setTimeout(function () {
                            toggle(false)
                        }, 50)
                    }}
                />
            </CustomSheet>
        </IpadAndMobileDisplay>
    )
}

export default MainSubmenuBottomSheetMobile
