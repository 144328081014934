import React, { useState, useEffect } from 'react'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import PrivateShareSearchFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareSearchFormMobile'
import ReturnNav from '../../atoms/header/returnNav'
import SideSlider from '../../templates/animated/sideSlider'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import {
    IDirectShareOwnedItem,
    IEntityPrivateOwnedSharesState,
} from '../../../redux/entities/cars/types'
import { carActions } from '../../../redux/entities/cars/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { IShareRecipientRequest_redux } from '../../../redux/entities/cars/actions'
import { searchUsersActions } from '../../../redux/searchUsers/reducer'

const CarShareEdit: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { carid } = useParams<{ carid: string }>()
    const location = useLocation()
    const q_params = new URLSearchParams(location.search)
    const shareid = q_params.get('shareid')

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const sharesLoading = useAppSelector(
        (state) => state.entities.carsData.carSharesLoading
    )
    const carsData = useAppSelector((state) => state.entities.carsData.cars)

    const searchResults = useAppSelector((state) => state.searchUsers.list)
    const searchResultsLoading = useAppSelector(
        (state) => state.searchUsers.loading
    )

    const [shareBeignEdited, setShareBeingEdited] =
        useState<IDirectShareOwnedItem>({
            entity_id: '',
            title: '',
            active_permission_ids: [],
            id: 'share_being_edited',
            created_at: '',
            type: 'car',
            updated_at: '',
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            total_recipients: 0,
        })
    const [isDesktop, setIsDesktop] = useState(false)

    const handleResize = () => {
        setIsDesktop(window.innerWidth > 881)
    }

    const setupLocalStateData = () => {
        const car_shares: IEntityPrivateOwnedSharesState | undefined =
            carid &&
            carsData &&
            carsData[carid] &&
            carsData[carid].private_shares_owned
                ? carsData[carid].private_shares_owned
                : undefined

        if (
            car_shares &&
            car_shares.entity_owned_direct_shares_data &&
            shareid &&
            car_shares.entity_owned_direct_shares_data[shareid]
        ) {
            const share: IDirectShareOwnedItem =
                car_shares.entity_owned_direct_shares_data[shareid]

            setShareBeingEdited({
                ...share,
                active_display: {
                    item_ids_display: share.active_display?.item_ids_display
                        ? share.active_display?.item_ids_display
                        : share.all_share_recipients_ids_list,
                    activeSort: share.active_display?.activeSort
                        ? share.active_display?.activeSort
                        : 'added_at',
                },
            })
        }
    }

    useEffect(() => {
        dispatch(carActions.setCurrentCar(`${carid}`))
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/sharing/with-others/car/${carid}/edit-share/recipients?shareid=${shareid}`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else {
            if (!carsData[`${carid}`]) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            }

            shareid &&
                dispatch(
                    carActions.getSingleCarDirectShareRequest({
                        entity_id: `${carid}`,
                        share_id: shareid,
                    })
                )
            setupLocalStateData()
        }

        window.addEventListener('resize', handleResize, { passive: true })
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [userLoggedIn, carid, shareid, carsData])

    useEffect(() => {
        setupLocalStateData()
    }, [carsData[`${carid}`]])

    useEffect(() => {
        if (isDesktop) {
            navigate(
                `/sharing/with-others/car/${carid}/edit-share?shareid=${shareid}`,
                { replace: true }
            )
        }
    }, [isDesktop, navigate, carid, shareid])

    return (
        <React.Fragment>
            <DesktopDisplayOnly></DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <SideSlider direction="right" initial="enter">
                    <Link
                        to={{
                            pathname: `/sharing/with-others/car/${carid}/edit-share`,
                            search: `?shareid=${shareid}`,
                        }}
                    >
                        <ReturnNav />
                    </Link>
                    <PrivateShareSearchFormManagerMobile
                        share={shareBeignEdited}
                        addShareRecipient={(p: IShareRecipientRequest_redux) =>
                            dispatch(carActions.addCarShareRecipientRequest(p))
                        }
                        searchUsers={(value: string | undefined) =>
                            dispatch(
                                searchUsersActions.searchPlatformUsersRequest(
                                    value
                                )
                            )
                        }
                        searchResults={searchResults}
                        resultsLoading={searchResultsLoading}
                        selectedUsers={
                            shareBeignEdited.all_share_recipients_ids_list
                        }
                        sharesLoading={sharesLoading}
                        goBack={() =>
                            setTimeout(
                                () =>
                                    navigate(
                                        `/sharing/with-others/car/${carid}/edit-share?shareid=${shareid}`
                                    ),
                                500
                            )
                        }
                    />
                </SideSlider>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default CarShareEdit
