import * as React from 'react'
import styled from 'styled-components'
// import blank_user from '../../../public/assets/icons/profile_pic_placeholder.svg'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import * as StatefulIcons from '../statefulicons'
import crossed_circle_grey from '../../../public/assets/icons/crossed_circle_grey.svg'
import { device } from '../../templates/displays/devices'
import { HistoryFilesIcon } from '../icons/components'
import { IDirectShareOwnedItem } from '../../../redux/entities/cars/types'

const Container = styled.div`
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: space-between;
    background-color: var(--bg-color, #fff);
    border: 1px solid var(--border_muted_subtle);
    border-radius: 5px;
    @media ${device.ipad} {
        height: 34px;
    }
    @media ${device.mobile} {
        height: 30px;
    }
`

const VerticalLine = styled.div`
    width: 1px;
    height: 100%;
    background-color: var(--border_muted_subtle);
`

type IUserImgStyle = {
    $nbOfUsers: number
    $userPic1?: string
    $userPic2?: string
}

const UserImageCircleProfileWrapperAndPiledUpImages = styled.div<IUserImgStyle>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    ${(props) => props.$nbOfUsers < 3 && 'transform: translateX(5px)'};
    ${(props) => props.$nbOfUsers < 2 && 'transform: translateX(10px)'};

    ${(props) =>
        props.$nbOfUsers > 1 &&
        `:after {
        position: absolute;
        content: '';
        right: -6px;
        width: 20px;
        height: 20px;
        background-color:  var(--bg-color, #fff);
        background-image: ${
            props.$userPic1 ? `url(${props.$userPic1})` : `url(${blank_user})`
        };
        background-size: cover;
        background-position: center;
        border: 1px solid var(--primary_20);
        border-radius: 50%;
        z-index: -1;
    }`};

    ${(props) =>
        props.$nbOfUsers > 2 &&
        `:before {
        position: absolute;
        content: '';
        right: -12px;
        width: 20px;
        height: 20px;
        border: 1px solid var(--primary_20);
        background-color: var(--bg-color, #fff);
        background-image: ${
            props.$userPic2 ? `url(${props.$userPic2})` : `url(${blank_user})`
        };
         background-size: cover;
        background-position: center;
        border-radius: 50%;
        z-index: -1;
    }`};
`

const UserImageCircleProfile = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border-radius: 50%;
    border: 1px solid var(--primary_20);
    background-color: var(--bg-color, #fff);
    position: absolute;
    z-index: 2;
`

const LeftSide = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    @media ${device.beyond_ipad_mobile} {
        :hover {
            background-color: var(--primary_16);
        }
    }

    @media ${device.mobile} {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media (max-width: 350px) {
        padding-left: 5px;
        padding-right: 5px;
    }
`

const RightSide = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    @media ${device.beyond_ipad_mobile} {
        :hover {
            background-color: var(--primary_16);
        }
    }
    @media (max-width: 350px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`

const PaddingLeft = styled.div`
    padding-left: 10px;
`

const UsersNumber = styled.div`
    font-family: Lato;
    font-size: 18px;
    padding-left: 20px;
    color: var(--text-default, #666666);
    line-height: 19px;

    @media ${device.ipad} {
        font-size: 16px;
    }
    @media ${device.mobile} {
        font-size: 12px;
        line-height: 16px;
    }
    @media (max-width: 350px) {
        font-size: 10px;
    }
`

type Props = {
    share: IDirectShareOwnedItem
    leftSideClick?: any
    rightSideClick?: any
}

const DirectShareInfo: React.FunctionComponent<Props> = (props: Props) => {
    let { share, leftSideClick, rightSideClick } = props

    // commented out code is for when we'll have users profile pic in the response

    let shareReceiversIds = share.all_share_recipients_ids_list
    let shareReceiversData = share.share_recipients_data

    return (
        <Container>
            <LeftSide onClick={leftSideClick ? leftSideClick : () => {}}>
                {share &&
                share.total_recipients &&
                share.total_recipients > 0 ? (
                    // && shareReceiversData
                    <UserImageCircleProfileWrapperAndPiledUpImages
                        $userPic1={
                            shareReceiversData &&
                            shareReceiversData[shareReceiversIds[1]] &&
                            shareReceiversData[shareReceiversIds[1]]
                                .profile_picture &&
                            shareReceiversData[shareReceiversIds[1]]
                                .profile_picture?.url
                                ? shareReceiversData[shareReceiversIds[1]]
                                      .profile_picture?.url
                                : undefined
                        }
                        $userPic2={
                            shareReceiversData &&
                            shareReceiversData[shareReceiversIds[2]] &&
                            shareReceiversData[shareReceiversIds[2]]
                                .profile_picture &&
                            shareReceiversData[shareReceiversIds[2]] &&
                            shareReceiversData[shareReceiversIds[2]]
                                .profile_picture?.url
                                ? shareReceiversData[shareReceiversIds[2]]
                                      .profile_picture?.url
                                : undefined
                        }
                        $nbOfUsers={share.total_recipients}
                    >
                        <UserImageCircleProfile
                            src={
                                shareReceiversData &&
                                shareReceiversData[shareReceiversIds[0]] &&
                                shareReceiversData[shareReceiversIds[0]]
                                    .profile_picture
                                    ? shareReceiversData[shareReceiversIds[0]]!
                                          .profile_picture!.url
                                    : blank_user
                            }
                        />
                    </UserImageCircleProfileWrapperAndPiledUpImages>
                ) : (
                    <div
                        style={{
                            transform: 'translateX(7px) scale(0.9)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            alt="no user"
                            src={crossed_circle_grey}
                            style={{ opacity: 0.5 }}
                        />
                    </div>
                )}

                {share ? (
                    <UsersNumber>
                        {share.total_recipients
                            ? share.total_recipients < 4
                                ? share.total_recipients
                                : `+ ${share.total_recipients - 3}`
                            : 0}
                    </UsersNumber>
                ) : null}
            </LeftSide>
            <VerticalLine />
            <RightSide onClick={rightSideClick ? rightSideClick : () => {}}>
                <HistoryFilesIcon
                    color={
                        share.active_permission_ids.includes('history file')
                            ? 'var(--text-default, #666666)'
                            : 'var(--border-muted, #e5e5e5)'
                    }
                    size="20"
                />
                <PaddingLeft style={{ paddingLeft: '12px' }} />
                <StatefulIcons.SharingAttachmentIcon
                    active={
                        share.active_permission_ids.includes(
                            'entry attachments'
                        )
                            ? true
                            : false
                    }
                />
                <PaddingLeft style={{ paddingLeft: '12px' }} />
                <StatefulIcons.SharingEntryCostIcon
                    active={
                        share.active_permission_ids.includes('entry costs')
                            ? true
                            : false
                    }
                />
                <PaddingLeft />
                <StatefulIcons.SharingEntryCostAttachmentIcon
                    active={
                        share.active_permission_ids.includes(
                            'entry cost attachments'
                        )
                            ? true
                            : false
                    }
                />
            </RightSide>
        </Container>
    )
}

export default DirectShareInfo
