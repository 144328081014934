import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const BellIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.13333 12.3833C6.54754 12.3833 6.88333 12.7191 6.88333 13.1333C6.88333 13.7505 7.38288 14.25 8 14.25C8.61712 14.25 9.11666 13.7505 9.11666 13.1333C9.11666 12.7191 9.45245 12.3833 9.86666 12.3833C10.2809 12.3833 10.6167 12.7191 10.6167 13.1333C10.6167 14.5789 9.44555 15.75 8 15.75C6.55445 15.75 5.38333 14.5789 5.38333 13.1333C5.38333 12.7191 5.71912 12.3833 6.13333 12.3833Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.25 11.2667C0.25 10.8524 0.585786 10.5167 1 10.5167H15C15.4142 10.5167 15.75 10.8524 15.75 11.2667C15.75 11.6809 15.4142 12.0167 15 12.0167H1C0.585786 12.0167 0.25 11.6809 0.25 11.2667Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.05002 5.2C3.05002 2.46645 5.26647 0.25 8.00002 0.25C10.7336 0.25 12.95 2.46645 12.95 5.2V9.4C12.95 9.5885 13.0772 9.8579 13.4273 10.1181C13.77 10.3729 14.1995 10.5167 14.5333 10.5167C14.9476 10.5167 15.2833 10.8525 15.2833 11.2667C15.2833 11.6809 14.9476 12.0167 14.5333 12.0167H14.0667C13.9026 12.0167 13.7508 11.964 13.6273 11.8745C13.2328 11.7536 12.8549 11.5617 12.5325 11.322C11.9738 10.9068 11.45 10.2428 11.45 9.4V5.2C11.45 3.29488 9.90514 1.75 8.00002 1.75C6.0949 1.75 4.55002 3.29488 4.55002 5.2V9.4C4.55002 10.8455 3.3789 12.0167 1.93335 12.0167C1.51914 12.0167 1.18335 11.6809 1.18335 11.2667C1.18335 10.8525 1.51914 10.5167 1.93335 10.5167C2.55047 10.5167 3.05002 10.0171 3.05002 9.4V5.2Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default BellIcon
