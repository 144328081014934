import { useLocation, useNavigate } from 'react-router-dom'
import { IAnyObject } from '../../../../../redux/insuranceQuoteApplication/reducer'
import { useAppSelector } from '../../../../../redux/store/hooks'
import InsuranceApplicationQuoteFieldsSearchManager from '../../../../organisms/searchbar/insurance/mobileInsuranceOptionsSearchManager'
import useWindowSize, {
    WindowSize,
} from '../../../../templates/displays/windowSizeHook'
import { IDropdownItem } from 'entityModels'
import { IInsuranceApplicationSectionId } from '../../dashboards/insuranceApplicationDashboard'
import { useEffect } from 'react'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'

type Props = {
    draft?: IAnyObject | undefined
    edited_entity?: IAnyObject[]
    entity_index?: number
    submitted?: IAnyObject | undefined
    urlBack: string
    onChange: (item: IDropdownItem) => any
    carid?: string
    sectionId?: IInsuranceApplicationSectionId
}

const InsuranceApplicationMobileSearch = (props: Props) => {
    const temp_field_search_copy = useAppSelector(
        (state) => state.insuranceQuoteApplication.temp_field_search_copy
    )

    let navigate = useNavigate()

    let {
        draft,
        submitted,
        onChange,
        urlBack,
        carid,
        sectionId,
        edited_entity,
        entity_index,
    } = props

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    let { setIsInsuranceWindowOpened } = useSetInitialInsurance()
    useEffect(() => {
        setIsInsuranceWindowOpened(true)

        return () => {
            setIsInsuranceWindowOpened(false)
        }
    }, [])

    const location = useLocation()

    useEffect(() => {
        if (!temp_field_search_copy) {
            navigate(props.urlBack)
        }
    }, [temp_field_search_copy])

    return (
        <div>
            <InsuranceApplicationQuoteFieldsSearchManager
                sectionId={sectionId}
                carid={carid}
                field_id={temp_field_search_copy?.id}
                draft_data={draft}
                edited_entity={edited_entity}
                entity_index={entity_index}
                submitted_data={submitted}
                UIcopy={temp_field_search_copy}
                onChange={(value: IDropdownItem) => {
                    let copyval = { ...value }

                    if (temp_field_search_copy?.patch_field_id) {
                        copyval.patch_field_id =
                            temp_field_search_copy?.patch_field_id
                    }

                    if (temp_field_search_copy?.patch_as_int) {
                        copyval.patch_as_int =
                            temp_field_search_copy?.patch_as_int
                    }

                    if (temp_field_search_copy?.is_price !== undefined) {
                        copyval = {
                            ...copyval,
                            is_price: temp_field_search_copy?.is_price,
                        }
                    }

                    onChange({ ...copyval })

                    setTimeout(() => {
                        if (location.pathname.match(/wizard/g)) {
                            navigate(
                                `${urlBack}&anchor=${
                                    temp_field_search_copy?.id
                                }_${isMobile ? 'mobile' : 'desktop'}`,
                                { replace: true }
                            )
                        } else {
                            navigate(
                                `${urlBack}?anchor=${
                                    temp_field_search_copy?.id
                                }_${isMobile ? 'mobile' : 'desktop'}`,
                                { replace: true }
                            )
                        }
                    }, 150)
                }}
            />
        </div>
    )
}

export default InsuranceApplicationMobileSearch
