import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import { device } from '../../templates/displays/devices'

const PlaceHolderStyle = styled.div<Props>`
    width: 100%;
    box-sizing: border-box;

    height: ${(props) => (props.mobileHeight ? props.mobileHeight : '160px')};

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary_20);

    @media ${device.ipad} {
        height: ${(props) => (props.ipadHeight ? props.ipadHeight : '160px')};
    }
`

type Props = {
    ipadHeight?: string
    mobileHeight?: string
    onClick?: any
    dataPH?: string
}

const AddImageGrid: React.FC<Props> = ({
    ipadHeight,
    mobileHeight,
    onClick,
    dataPH,
}) => {
    return (
        <PlaceHolderStyle
            ipadHeight={ipadHeight}
            mobileHeight={mobileHeight}
            onClick={onClick}
            data-ph={dataPH}
        >
            <Icon icon="add_plus" />
        </PlaceHolderStyle>
    )
}

export default AddImageGrid
