import { IJourneySectionID } from 'journeyInterfaces'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { generatenJourneyBgPattern } from '../../../atoms/patterns/journey'
import BlurredBg from './blurredBg'
import { device } from '../../displays/devices'

type Props = {
    sectionID: IJourneySectionID
    hasLinearBgColour?: boolean
    hasBlurredBg?: boolean
    hasPattern?: boolean
    isMobile?: boolean
    children: React.ReactNode
}

type IStyle = {
    $theme: ITheme
    $sectionID: IJourneySectionID
    $hasLinearBgColour?: boolean
}

const MainWrapper = styled.main<IStyle>`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    background: ${(props) =>
        props.$hasLinearBgColour
            ? `linear-gradient(180deg, ${
                  colours[props.$theme].background_neutral_subtle
              } 0%, ${
                  journey_colours[props.$theme].section[props.$sectionID]
                      .primary_100
              } 100%)`
            : `${colours[props.$theme].background_default}`};

    @media ${device.mobile_and_ipad} {
        background: ${(props) =>
            props.$hasLinearBgColour
                ? `linear-gradient(180deg, ${
                      journey_colours[props.$theme].section[props.$sectionID]
                          .primary_100
                  } 7.82%, ${colours[props.$theme].background_default} 54.28%);`
                : `${colours[props.$theme].background_default}`};
    }
`

const Pattern = styled.div<IStyle>`
    position: absolute;
    right: 0;
    bottom: 0;
    flex-shrink: 0;
    z-index: 1;
`

export function JourneyPageBackground(props: Props) {
    let { theme } = useThemes()
    let {
        children,
        sectionID,
        hasLinearBgColour,
        hasBlurredBg,
        hasPattern,
        isMobile,
    } = props

    return (
        <MainWrapper
            $theme={theme}
            $sectionID={sectionID}
            $hasLinearBgColour={hasLinearBgColour}
        >
            {hasBlurredBg ? <BlurredBg sectionID={sectionID} /> : null}
            {hasPattern ? (
                <Pattern $theme={theme} $sectionID={sectionID}>
                    {generatenJourneyBgPattern(
                        sectionID,
                        isMobile ? '390' : undefined,
                        isMobile ? '400' : undefined,
                        isMobile
                    )}
                </Pattern>
            ) : null}
            <div style={{ width: '100%', boxSizing: 'border-box', zIndex: 2 }}>
                {children}
            </div>
        </MainWrapper>
    )
}
