import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import heroCar from '../../../../public/assets/images/hero_car.png'
import line1 from '../../../../public/assets/images/line1.png'
import line2 from '../../../../public/assets/images/line2.png'
import line3 from '../../../../public/assets/images/line3.png'
import line4 from '../../../../public/assets/images/line4.png'
import line5 from '../../../../public/assets/images/line5.png'
import line6 from '../../../../public/assets/images/line6.png'
import fasterCar from '../../../../public/assets/images/fasterCar.png'
import slowerCar from '../../../../public/assets/images/slowerCar.png'
import useWindowSize from '../../../templates/displays/windowSizeHook'

const PageWrapper = styled.div`
    position: relative;
`

const AnimationsWrapper = styled.div`
    padding-top: 156px;
    min-height: 100vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 0;
    /* max-width: 1400px; */
    margin: 0 auto;
    background-color: var(
        --base-overlays-neutral-alpha-50,
        rgba(26, 26, 26, 0.02)
    );

    @media (min-width: 743px) {
        height: 100dvh;
        padding-top: 144px;
    }

    @media (max-width: 743px) {
        height: 100dvh;
        padding-top: 100px;
    }
`

const ClickableElementsOverWrapper = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
`

const ClickableElementsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    gap: 24px;
    /* max-width: 960px; */
    /* padding-left: 80px;
    padding-right: 80px; */
    /* height: 50vh; */
    /* min-height: 520px;
    max-height: 600px; */
    z-index: 2;

    @media ${device.smallest_laptops} {
        /* min-height: 470px; */
    }

    @media ${device.mobile_and_ipad} {
        /* padding-top: 88px; */

        /* min-height: 480px; */
        /* padding-left: 24px;
        padding-right: 24px; */
    }

    /* @media ${device.ipad} {
        padding-top: 176px;
    }

    @media ${device.beyond_ipad_mobile} {
        padding-top: 176px;
    } */

    /* @media ${device.large_desktop} {
        padding-top: 240px;
    } */

    @media (max-height: 700px) {
        gap: 16px;
        /* min-height: 400px; */
    }
    @media (max-height: 600px) {
        gap: 16px;
        /* min-height: 380px; */
    }
`

export const AdjustedPadding = styled.div`
    padding-top: 24px;
    @media ${device.smallest_laptops} {
        padding-top: 0px;
    }
    @media (max-width: 743px) {
        padding-top: 0px;
    }
`

export const Title = styled.h1`
    margin: 0;
    padding: 0;
    color: var(--text-strong, #1a1a1a);
    text-align: center;
    font-family: Lato-bold;
    font-size: 42px;

    @media ${device.smallest_laptops} {
        font-size: 32px;
    }

    @media ${device.mobile_and_ipad} {
        font-size: 22px;
        line-height: 32px;
    }
    @media (max-width: 320px) {
        font-size: 18px;
        line-height: 26px;
    }
`

export const Text = styled.p`
    margin: 0;
    padding: 0;
    color: var(--text-default, #666);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.mobile} {
        font-size: 14px;
        line-height: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const CenteredCar = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 80vw;
    height: auto;
    max-width: 300px;

    @media ${device.mobile} {
        width: 53vw;
    }

    @media (max-height: 900px) {
        max-width: 250px;
    }
`
const AnimatedBackgroundOverWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    transform: translateX(-50%);
    height: 100%;
    width: 90vw;
    max-width: 500px;
    z-index: -1;
    display: flex;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;

    @media ${device.mobile} {
        width: 100%;
        box-sizing: border-box;
    }
`

const AnimatedBackgroundWrapper = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${device.mobile} {
        border-left: 10px solid var(--bg-color, #fff);
        border-right: 10px solid var(--bg-color, #fff);
    }
`

const AnimatedBackground1 = styled.div`
    position: absolute;
    overflow-y: hidded;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    animation: move 5s linear infinite;
    transform-origin: top;
    display: flex;
    justify-content: space-between;
    @media ${device.mobile} {
        justify-content: center;
    }

    @keyframes move {
        0% {
            transform: translateY(0%);
        }
        100% {
            transform: translateY(100%);
        }
    }
`

const AnimatedBackground2 = styled.div`
    position: absolute;
    overflow-y: hidded;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    animation: move2 5s linear infinite;
    transform-origin: top;
    display: flex;
    justify-content: space-between;
    @media ${device.mobile} {
        justify-content: center;
    }

    @keyframes move2 {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(0%);
        }
    }
`

const Road_line = styled.div`
    height: 80px;
    width: 20px;
    background-color: var(--base-overlays-white, #fff);
    margin-bottom: 80px;
    @media ${device.mobile} {
        width: 5px;
    }
`

const AnimatedLine = styled.img`
    top: 0;
    z-index: 0;
    transform-origin: bottom;
    max-height: 180px;
    max-width: 24px;
    height: auto;
    width: auto;
    opacity: 0;

    @media ${device.mobile_and_ipad} {
        max-height: 120px;
        max-width: 16px;
        filter: saturate(50%);
    }
`
const AnimatedLine1 = styled(AnimatedLine)`
    animation: moveLine1 7s linear infinite;
    @keyframes moveLine1 {
        0% {
            opacity: 0.7;
            transform: translateY(-120vh);
        }
        100% {
            opacity: 0;
            transform: translateY(120vh);
        }
    }
`

const AnimatedLine2 = styled(AnimatedLine)`
    animation: moveLine2 6s linear infinite;
    @keyframes moveLine2 {
        0% {
            opacity: 0.7;
            transform: translateY(-110vh);
        }
        100% {
            opacity: 0;
            transform: translateY(150vh);
        }
    }
`

const AnimatedLine3 = styled(AnimatedLine)`
    animation: moveLine3 5s linear infinite;
    animation-delay: 1s;
    @keyframes moveLine3 {
        0% {
            opacity: 0.7;
            transform: translateY(-120vh);
        }
        100% {
            opacity: 0;
            transform: translateY(105vh);
        }
    }
`

const AnimatedLine4 = styled(AnimatedLine)`
    animation: moveLine4 4s linear infinite;
    @keyframes moveLine4 {
        0% {
            opacity: 0.7;
            transform: translateY(-125vh);
        }
        100% {
            opacity: 0;
            transform: translateY(120vh);
        }
    }
`

const AnimatedLine5 = styled(AnimatedLine)`
    animation: moveLine5 5s linear infinite;
    animation-delay: 6s;
    @keyframes moveLine5 {
        0% {
            opacity: 0.7;
            transform: translateY(-135vh);
        }
        100% {
            opacity: 0;
            transform: translateY(130vh);
        }
    }
`

const AnimatedLine6 = styled(AnimatedLine)`
    animation: moveLine6 4s linear infinite;
    @keyframes moveLine6 {
        0% {
            opacity: 0.7;
            transform: translateY(-115vh);
        }
        100% {
            opacity: 0;
            transform: translateY(105vh);
        }
    }
`

const AnimatedLine7 = styled(AnimatedLine)`
    animation: moveLine7 5s linear infinite;
    animation-delay: 2s;
    @keyframes moveLine7 {
        0% {
            opacity: 0.7;
            transform: translateY(-125vh);
        }
        100% {
            opacity: 0;
            transform: translateY(150vh);
        }
    }
`

const AnimatedLine8 = styled(AnimatedLine)`
    animation: moveLine8 6s linear infinite;
    @keyframes moveLine8 {
        0% {
            opacity: 0.7;
            transform: translateY(-120vh);
        }
        100% {
            opacity: 0;
            transform: translateY(105vh);
        }
    }
`

const AnimatedLine9 = styled(AnimatedLine)`
    animation: moveLine9 6s linear infinite;
    animation-delay: 1s;
    @keyframes moveLine9 {
        0% {
            opacity: 0.7;
            transform: translateY(-130vh);
        }
        100% {
            opacity: 0;
            transform: translateY(105vh);
        }
    }
`

const AnimatedLine10 = styled(AnimatedLine)`
    animation: moveLine10 6s linear infinite;
    @keyframes moveLine10 {
        0% {
            opacity: 0.7;
            transform: translateY(-150vh);
        }
        100% {
            opacity: 0;
            transform: translateY(125vh);
        }
    }
`

const CarWrapperLeft1 = styled.div`
    @media ${device.mobile_and_ipad} {
        display: none;
    }
    position: absolute;
    top: 0;
    overflow: hidden;
    height: 100%;
    width: 100vw;
    z-index: -1;
    transform-origin: top;
    max-width: 1500px;

    animation-name: animateLeftCar1;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;

    @keyframes animateLeftCar1 {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(100%);
        }
    }
`

const CarWrapperRight1 = styled.div`
    @media ${device.mobile_and_ipad} {
        display: none;
    }
    position: absolute;
    top: 0;
    overflow: hidden;
    height: 100%;
    width: 100vw;
    z-index: -1;
    transform-origin: top;
    max-width: 1500px;

    animation-name: animateRightCar;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    @keyframes animateRightCar {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(-100%);
        }
    }
`

const AnimatedLeftCar = styled.img`
    position: absolute;
    left: 40px;
    width: 78vw;
    max-width: 280px;
    opacity: 50%;
    @media ${device.mobile} {
        display: none;
    }

    @media (max-height: 900px) {
        max-width: 250px;
    }
    @media ${device.ipad} {
        left: -80px;
    }
`

const AnimatedRightCar = styled.img`
    position: absolute;
    right: 40px;
    width: 78vw;
    max-width: 280px;
    opacity: 50%;
    @media (max-width: 743px) {
        display: none;
    }

    @media (max-height: 900px) {
        max-width: 250px;
    }

    @media ${device.ipad} {
        right: -80px;
    }
`

type Props = {
    children: React.ReactNode
}

export const InsuranceOnboardingAnimatedWrapper = (props: Props) => {
    let windowHeight = useWindowSize().height

    const generatedLines = (): number[] => {
        let numberOfLines = windowHeight ? windowHeight / 160 : 0
        return numberOfLines
            ? Array.from(Array(Math.round(numberOfLines)).keys())
            : []
    }

    return (
        <PageWrapper>
            <ClickableElementsOverWrapper>
                <ClickableElementsWrapper>
                    {props.children}
                </ClickableElementsWrapper>
            </ClickableElementsOverWrapper>

            <AnimationsWrapper>
                <CenteredCar>
                    <img
                        src={heroCar}
                        alt="hero-car"
                        style={{
                            objectFit: 'cover',
                            width: '100%',
                            boxSizing: 'border-box',
                            opacity: 0.1,
                            filter: 'saturate(0)',
                        }}
                    />
                </CenteredCar>

                {/* left side lines */}
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        maxWidth: 1400,
                        position: 'absolute',
                        top: 80,
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
                        <AnimatedLine1 src={line2} />
                        <AnimatedLine2 src={line1} />
                        <AnimatedLine3 src={line3} />
                        <AnimatedLine4 src={line2} />
                        <AnimatedLine5 src={line5} />
                        <div style={{ padding: 100 }} />
                        <AnimatedLine6 src={line6} />
                        <AnimatedLine7 src={line4} />
                        <AnimatedLine8 src={line3} />
                        <AnimatedLine9 src={line2} />
                        <AnimatedLine10 src={line4} />
                    </div>
                </div>

                <CarWrapperRight1>
                    <AnimatedRightCar src={fasterCar} />
                </CarWrapperRight1>
                <CarWrapperLeft1>
                    <AnimatedLeftCar src={slowerCar} />
                </CarWrapperLeft1>

                <AnimatedBackgroundOverWrapper>
                    <AnimatedBackgroundWrapper>
                        <AnimatedBackground2>
                            <div>
                                {generatedLines().map((line) => (
                                    <Road_line />
                                ))}
                            </div>
                            <div>
                                {generatedLines().map((line) => (
                                    <Road_line />
                                ))}
                            </div>
                        </AnimatedBackground2>
                        <AnimatedBackground1>
                            <div>
                                {generatedLines().map((line) => (
                                    <Road_line />
                                ))}
                            </div>
                            <div>
                                {generatedLines().map((line) => (
                                    <Road_line />
                                ))}
                            </div>
                        </AnimatedBackground1>
                    </AnimatedBackgroundWrapper>
                </AnimatedBackgroundOverWrapper>
            </AnimationsWrapper>
        </PageWrapper>
    )
}
