import styled from 'styled-components'
import React, { useEffect, useRef, useState } from 'react'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'

type IStyle = {
    $theme: ITheme
    $progress_base_colour?: string
    $progress_filled_colour?: string
}

const ProgressBarsWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 24px;
`
const ProgressBar = styled.div<IStyle>`
    all: unset;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    height: 4px;
    background-color: ${(props) =>
        props.$progress_base_colour ?? colours[props.$theme].border_muted};
    border-radius: 4px;
`

const ProgressBarCompletionRate = styled.div<IStyle>`
    all: unset;
    position: absolute;
    left: 0;
    height: 4px;
    background-color: ${(props) =>
        props.$progress_filled_colour ?? colours[props.$theme].primary};
    border-radius: 4px;
    transition: all 500ms;
`

interface FormStepProgressProps {
    arrayOfProgressBarsValues: number[]
    progress_base_colour?: string
    progress_filled_colour?: string
}

export default function FormMultipleStepsProgress(
    props: FormStepProgressProps
) {
    let barRef = useRef<HTMLDivElement>(null)
    const [refWidth, setRefWidth] = useState<number | undefined>(undefined)

    const size: WindowSize = useWindowSize()
    const windowWidth = size.width

    useEffect(() => {
        if (barRef.current) {
            setRefWidth(barRef.current?.offsetWidth)
        }
        return () => {}
    }, [barRef, windowWidth, props.arrayOfProgressBarsValues])

    const { theme } = useThemes()

    return (
        <ProgressBarsWrapper>
            {props.arrayOfProgressBarsValues.map((progress, i) => (
                <React.Fragment key={`progress_bar_${i}_desktop`}>
                    <ProgressBar
                        ref={barRef}
                        $theme={theme}
                        $progress_base_colour={props.progress_base_colour}
                    >
                        <ProgressBarCompletionRate
                            $theme={theme}
                            $progress_filled_colour={
                                props.progress_filled_colour
                            }
                            style={{
                                width: refWidth
                                    ? refWidth * (progress / 100)
                                    : '100%',
                                maxWidth: refWidth,
                            }}
                        />
                    </ProgressBar>
                </React.Fragment>
            ))}
        </ProgressBarsWrapper>
    )
}
