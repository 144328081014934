import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { IDeleteShareAPi_Arg } from '../types'

export const deleteShareAPI = (p: IDeleteShareAPi_Arg): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.delete_share_endpoint(p), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(response, 'Delete share error')
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
