import { call, fork, take, put } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertAttachmentAPIToAttachmentResponseState } from '../../conversions/attachments'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { timelineActions } from '../reducer'
import { attachmentActions } from '../../attachments/reducer'
import {
    IAttachmentRequestPayload,
    IAttachmentAPI,
    IAttachmentAPIResponse,
} from '../../attachments/types'
import { IAddEntryAttachmenstApiRequest } from '../../services/types'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'

export async function putToS3(fileObject: any, presignedUrl: string) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': fileObject.type,
            origin: 'custodian.club',
        },
        ContentEncoding: 'base64',
        body: fileObject.data,
    }
    const response = await fetch(presignedUrl, requestOptions)
    return await response
}

export async function putToS3Tracked(fileObject: File, presignedUrl: string) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': fileObject.type,
            origin: 'custodian.club',
        },
        ContentEncoding: 'base64',
        body: fileObject,
    }
    const response = await fetch(presignedUrl, requestOptions)
    return await response
}

// export function* UploadEntryAttachmentToAwsSaga(
//     payload: IAddAttachmentToEntrySubSaga
// ): any {
//     try {
//         yield call(putToS3, payload.fileData.file, payload.presigned_url)

//         yield call(AddAttachmentsToEntryAPISaga, payload)
//     } catch (e: any) {
//         let customErrorData: ICustomErrorData = {
//             custom_message: `Something went wrong while uploading the attachment, please try adding it again.`,
//             custom_user_action_text: 'Return',
//             custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`, //pathname to entry page possibly
//         }
//         let customError: IReduxError = ConvertToReduxError(e, customErrorData)
//         yield put(errorActions.addAttachmentToEntryError(customError))
//     }
// }

export function* UploadEntryAttachmentToAwsWithTrackingSaga(
    payload: IAddAttachmentToEntryAwsTrackingSaga
): any {
    try {
        yield call(putToS3Tracked, payload.fileData.file, payload.presigned_url)
        return 'ok'
        // next to do: update on progress ( redux centralisation)
    } catch (e: any) {
        let item_with_error_tracked: IAttachmentRequestPayload = {
            ...payload.fileData,
            hasErrorAWS: e,
        }
        return item_with_error_tracked
    }
}

export function* AddAttachmentsToEntryAPISaga(
    payload: IAddEntryAttachmenstApiRequest
): any {
    try {
        let p: IAddEntryAttachmenstApiRequest = {
            attachments: payload.attachments,
            carID: payload.carID,
            entryID: payload.entryID,
        }
        let at: IAttachmentAPIResponse[] = yield call(
            api.timeline.entries.addEntryAttachments.addEntryAttachmentsApi,
            p
        )

        if (at && at.length > 0) {
            for (let i = 0; i < at.length; i++) {
                let converted_attac =
                    ConvertAttachmentAPIToAttachmentResponseState(at[i])

                yield put(
                    timelineActions.addAttachmentToEntrySuccess([
                        converted_attac.id,
                    ])
                )

                yield put(
                    attachmentActions.addAttachmentsSuccess({
                        [converted_attac.id]: converted_attac,
                    })
                )
            }
        }
    } catch (e: any) {
        if (e.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: AddAttachmentsToEntryAPISaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong while uploading the attachment, please try adding it again.`,
                custom_user_action_text: 'Return',
                custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`, //pathname to entry page possibly
            }
            let customError: IReduxError = ConvertToReduxError(
                e,
                customErrorData
            )
            yield put(timelineActions.addAttachmentToEntryError(customError))
        }
    }
}

export type IAddAttachmentToEntrySubSaga = {
    fileData: IAttachmentRequestPayload
    presigned_url: string
    attachmentApi: IAttachmentAPI
    entryID: string
    carID: string
}

export type IAddAttachmentToEntryAwsTrackingSaga = {
    fileData: IAttachmentRequestPayload
    presigned_url: string
}

function* watcherAddAttachmentToEntry() {
    while (true) {
        const { payload } = yield take(
            timelineActions.addAttachmentsToEntryRequest
        )

        yield call(AddAttachmentsToEntryAPISaga, payload)
    }
}

const add_attachment_to_entry: any[] = [fork(watcherAddAttachmentToEntry)]

export default add_attachment_to_entry
