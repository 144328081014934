import * as React from 'react'
import styled from 'styled-components'
import { OOtherTaskOptions } from '../../../ol/ol'
import { device } from '../../templates/displays/devices'

const TaskCarHeaderDesktopStyle = styled.h1`
    font-family: Lato;
    text-align: start;
    color: #5ec3ca;
    font-size: 18px;
    padding-right: 5px;
    cursor: pointer;

    @media ${device.mobile} {
    }
`

interface ITextProps {
    answers: string[]
    carids?: any
    garageid: any
}

const TaskCarHeaderDesktop: React.FunctionComponent<ITextProps> = (props) => {
    const [showAll, setShowAll] = React.useState(
        props.answers.length > 5 ? false : true
    )

    React.useEffect(() => {
        if (props.answers.length > 5) {
            setShowAll(false)
        } else {
            setShowAll(true)
        }
    }, [props.answers.length])

    return (
        <TaskCarHeaderDesktopStyle>
            {!props.carids ? (
                <div>{OOtherTaskOptions.general_task.single}</div>
            ) : props.answers.length === 1 ? (
                <div>{props.answers[0]}</div>
            ) : (
                props.answers.map((answer: string, index: any) => {
                    if (showAll) {
                        return (
                            <div key={`${index}_tasks_car_showall`}>
                                {answer}
                            </div>
                        )
                    } else if (index < 5) {
                        return (
                            <div key={`${index}_tasks_less_than+5`}>
                                {answer}
                            </div>
                        )
                    } else {
                        return (
                            <div
                                key={`${index}_tasks_car`}
                                style={{ display: 'none' }}
                            />
                        )
                    }
                })
            )}
            {!showAll && (
                <div onClick={() => setShowAll(true)}>
                    +{props.answers.length - 5}
                </div>
            )}
        </TaskCarHeaderDesktopStyle>
    )
}

export default TaskCarHeaderDesktop
