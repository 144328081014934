import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IFileStorageState,
    IPresignedUrlEntryImagesArgs,
    IUploadAndCreateCarCoverImageArgs,
    IUploadAndCreateCarGalleryImageArgs,
    IUploadAndCreateEntryGalleryImageArgs,
    IUploadAttachmentPreSignedUrlPayloadSaga,
    IUploadAttachmentPreSignedUrlPayloadSingle,
    IUploadGalleryImagesPreSignedUrlsPayload,
    IUploadImagePreSignedUrlPayloadSingle,
    IUploadImagesPreSignedUrlsPayload,
    reqPresignedUrImageArgs,
} from './types'
import { IReduxError } from '../entities/cars/types'
import { IUploadAttachmentsPreSignedUrlsPayloadAPI } from '../services/typedefinitions/apiPayloads'
import { IApiUploadPresignedUrlImgsToS3_args } from '../services/types'

const initialState: IFileStorageState = {
    error: null,
    loading: false,
    loadingTos3: false,
}

const fileStorageSlice = createSlice({
    name: 'fileStorage',
    initialState,
    reducers: {
        getPresignedUrlsCarGalleryImagesRequest: (
            state,
            action: PayloadAction<reqPresignedUrImageArgs>
        ) => {
            state.presignedurls = null
            state.loading = true
            state.error = null
        },
        getPresignedUrlsCarGalleryImagesSuccess: (
            state,
            action: PayloadAction<IUploadGalleryImagesPreSignedUrlsPayload>
        ) => {
            state.gallerypresignedurls = state.gallerypresignedurls
                ? { ...state.gallerypresignedurls, ...action.payload }
                : action.payload
            state.loading = false
            state.error = null
        },
        getPresignedUrlsEntryAttachmentsRequest: (
            state,
            action: PayloadAction<IUploadAttachmentPreSignedUrlPayloadSaga>
        ) => {
            state.presignedurls = null
            state.loading = true
            state.error = null
        },
        resetPresignedUrlsCarGalleryImagesSuccess: (state) => {
            state.gallerypresignedurls = undefined
            state.loading = false
            state.error = null
        },
        getPresignedUrlsEntryAttachmentsSuccess: (
            state,
            action: PayloadAction<IUploadAttachmentsPreSignedUrlsPayloadAPI>
        ) => {
            state.presignedurls = action.payload
            state.loading = false
            state.error = null
        },
        getPresignedUrlEntryAttachmentFileStorageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        getPresignedUrlCostAttachmentRequest: (
            state,
            action: PayloadAction<IUploadAttachmentPreSignedUrlPayloadSingle>
        ) => {
            state.presignedurls = null
            state.loading = true
            state.error = null
        },
        getPresignedUrlCostAttachmentSuccess: (
            state,
            action: PayloadAction<IUploadImagePreSignedUrlPayloadSingle>
        ) => {
            state.presignedurls = [action.payload]
            state.loading = false
            state.error = null
        },
        getPresignedUrlCostAttachmentFileStorageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        getPresignedUrlsCarGalleryImagesFileStorageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        getPresignedUrlCarCoverImageRequest: (
            state,
            action: PayloadAction<{ carid: string; file_name: string }>
        ) => {
            state.presignedurls = null
            state.loading = true
            state.error = null
        },
        getPresignedUrlCarCoverImageSuccess: (
            state,
            action: PayloadAction<IUploadImagesPreSignedUrlsPayload>
        ) => {
            state.presignedurls = action.payload
            state.loading = false
            state.error = null
        },
        getPresignedUrlCarCoverImageFileStorageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        submitCarGalleryImagesFilestorageRequest: (
            state,
            action: PayloadAction<IUploadAndCreateCarGalleryImageArgs>
        ) => {
            state.loading = true
            state.error = null
        },
        submitCarGalleryImagesFilestorageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        submitCarGalleryImagesFilestorageSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        submitCarCoverImageFilestorageRequest: (
            state,
            action: PayloadAction<IUploadAndCreateCarCoverImageArgs>
        ) => {
            state.loading = true
            state.error = null
        },
        submitCarCoverImageFilestorageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        submitCarCoverImageFilestorageSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        getPresignedUrlUserProfileImageRequest: (
            state,
            action: PayloadAction<string>
        ) => {
            state.presignedurls = null
            state.loading = true
            state.error = null
        },
        getPresignedUrlUserProfileImageSuccess: (
            state,
            action: PayloadAction<IUploadImagesPreSignedUrlsPayload>
        ) => {
            state.presignedurls = action.payload
            state.loading = false
            state.error = null
        },
        getPresignedUrlUserProfileImageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        getPresignedUrlEntryGalleryImagesRequest: (
            state,
            action: PayloadAction<IPresignedUrlEntryImagesArgs>
        ) => {
            state.presignedurls = null
            state.loading = true
            state.error = null
        },
        getPresignedUrlsEntryGalleryImagesSuccess: (
            state,
            action: PayloadAction<IUploadGalleryImagesPreSignedUrlsPayload>
        ) => {
            state.gallerypresignedurls = state.gallerypresignedurls
                ? { ...state.gallerypresignedurls, ...action.payload }
                : { ...action.payload }
            state.loading = false
            state.error = null
        },
        getPresignedUrlsEntryGalleryImagesFileStorageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        submitEntryGalleryImagesFilestorageRequest: (
            state,
            action: PayloadAction<IUploadAndCreateEntryGalleryImageArgs>
        ) => {
            state.loading = true
            state.error = null
        },
        submitEntryGalleryImagesFilestorageError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
        submitEntryGalleryImagesFilestorageSuccess: (state) => {
            state.loading = false
            state.error = null
        },
        submitFilesToS3Request: (
            state,
            action: PayloadAction<IApiUploadPresignedUrlImgsToS3_args>
        ) => {
            state.loadingTos3 = true
            state.error = null
        },
        submitFilesToS3Error: (state, action: PayloadAction<IReduxError>) => {
            state.loadingTos3 = false
            state.error = action.payload
        },
        submitFilesToS3Success: (state) => {
            state.loadingTos3 = false
            state.error = null
        },
    },
})

export const fileStorageActions = fileStorageSlice.actions

export default fileStorageSlice.reducer

export type FileStorageState = ReturnType<typeof fileStorageSlice.reducer>
