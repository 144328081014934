import { ICreateCarVersion2, IDropdownItem, IList } from 'entityModels'
import { useEffect, useState } from 'react'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import {
    OnboardingText,
    PaddingUnit,
} from '../../../../templates/onboarding/onboardingFormLayoutMobile'
import {
    generateBackEndValueFuelUnit,
    cubicCentimetersToLiters,
    cubicInchesToLiters,
} from '../../../../../helpers/units/unitConversion'
import {
    useAppSelector,
    useAppDispatch,
} from '../../../../../redux/store/hooks'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import Expander from '../../../../atoms/expander/expander'
import { ErrorText } from '../../account/accountAddressForm/accountAddressformDesktop'
import styled from 'styled-components'
import { Select } from '../../../../atoms/select'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import OnboardingFormTemplateDesktop from '../../../../templates/onboarding/desktop/onboardingFormTemplateDesktop'
import SelectSearchBarCarInfoDesktop from '../../technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import { useLocation } from 'react-router'
import { ICar } from '../../../../../redux/entities/cars/types'
import { IUser } from '../../../../../redux/user/types'
import { carActions } from '../../../../../redux/entities/cars/reducer'

const RowForm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const AdjustPosition = styled.div`
    margin-left: 24px;
    width: 100%;
    box-sizing: border-box;
`

const EquivalentConverted = styled.div`
    font-size: 12px;
    color: #b3b3b3;
    font-family: Lato;
    padding-left: 8px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
`

type IOnboarding_Car_Data = {
    make: string | undefined
    model: string | undefined
    year: number | string | undefined
    engine_size: number | string | undefined
    engine_size_unit: string | undefined
    transmission: string | undefined
    drive_side: string | undefined
}

type Props = {
    car: ICar
    onRetrieveByRegNoClick: () => void
    formText: string
}

const AddACarDataFormOnboardingDesktop = (props: Props) => {
    let { car, onRetrieveByRegNoClick, formText } = props
    let userData: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let appDispatch = useAppDispatch()

    let makesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let tempMakeDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempMakeDisplay
    })

    let tempModelDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempModelDisplay
    })

    let transmissionsList = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    useEffect(() => {
        if (!transmissionsList) {
            appDispatch(
                dropDownActions.setTransmissionTypesDropdownDataRequest()
            )
        }

        if (!makesDropdowndata) {
            appDispatch(dropDownActions.setMakeDropdownDataRequest())
        }
    }, [])

    let user_fuel_unit_ids_list =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].ids_list

    let user_fuel_unit_data =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].data

    let fuel_units: IList[] | null =
        user_fuel_unit_data &&
        user_fuel_unit_ids_list &&
        user_fuel_unit_ids_list.map((id: string, index: number) => {
            // @ts-ignore
            return user_fuel_unit_data[id]
        })

    let init_data: IOnboarding_Car_Data = {
        make: tempMakeDisplay,
        model: tempModelDisplay,
        year: undefined,
        drive_side: undefined,
        engine_size_unit: 'cubic_centimeters',
        engine_size: undefined,
        transmission: undefined,
    }

    const [formData, setFormData] = useState<IOnboarding_Car_Data>({
        ...init_data,
    })

    const handleChange = (id: string, value: string | undefined) => {
        if (id === 'make') {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    make: value,
                    model: undefined,
                }
            })
        } else {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    [id]: value,
                }
            })
        }
    }

    const drive_side_options = [
        {
            name: 'LHD',
        },
        {
            name: 'RHD',
        },
        {
            name: 'Center',
        },
    ]

    let arr_form_values = [
        'drive_side',
        'make',
        'model',
        'transmission',
        'engine_size',
        'year',
    ]

    let arr_validating_form_values = ['make', 'model', 'year']

    const car_years_validation = useAppSelector(
        (state) => state.localdata.form_validation_copy.years
    )

    const isFieldValid = (id: string, value?: string | number): boolean => {
        if (id === 'year' && value !== undefined && car_years_validation) {
            if (+value < 1850) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        year: car_years_validation.car_year.warning_min,
                    }
                })
                return false
            } else if (+value > car_years_validation.car_year.max) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        year: car_years_validation.car_year.warning_max,
                    }
                })

                return false
            }
        } else if (id === 'year' && !value) {
            setError((prevState) => {
                return { ...prevState, [id]: 'Required' }
            })
            return false
        } else if (
            ((id === 'make' || id === 'model') &&
                !formData[id as keyof IOnboarding_Car_Data]) ||
            formData[id as keyof IOnboarding_Car_Data] === '-' ||
            formData[id as keyof IOnboarding_Car_Data] === ''
        ) {
            setError((prevState) => {
                return { ...prevState, [id]: 'Required' }
            })
            return false
        }

        return true
    }

    const validateOnSubmit = () => {
        let res = true
        arr_validating_form_values.forEach((id: string) => {
            if (!isFieldValid(id, formData[id as keyof IOnboarding_Car_Data])) {
                res = false
            }
        })

        return res
    }
    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let country = queryParams.get('country')

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    let reg_no = queryParams.get('reg_no')

    const submitData: () => void = () => {
        let p: ICreateCarVersion2 = {
            car_id: car.id,
            data: [],
            isOnboarding: true,
            country: `${country}`,
            garage_id: `${userLoggedIn?.owns_garage?.uid}`,
            direct_url: `/onboarding-step-3`,
        }

        if (reg_no) {
            p['registration_number'] = `${reg_no}`
        }

        let generateAnswer = (fieldID: string) => {
            if (formData[fieldID as keyof IOnboarding_Car_Data]) {
                if (
                    fieldID === 'engine_size' &&
                    formData.engine_size_unit &&
                    formData.engine_size
                ) {
                    return generateBackEndValueFuelUnit(
                        formData.engine_size_unit,
                        parseFloat(`${formData.engine_size}`)
                    )
                } else if (fieldID === 'year' && formData.year) {
                    return +formData.year
                } else {
                    return formData[fieldID as keyof IOnboarding_Car_Data]
                }
            }
        }

        arr_form_values.forEach((id: string) => {
            if (formData[id as keyof IOnboarding_Car_Data]) {
                let newItem: IList = {
                    id: id,
                    text: id,
                    answer: generateAnswer(id),
                }
                p.data = [...p.data, newItem]
            }
        })

        let car_title = generate_car_title()

        let carTitleItem: IList = {
            id: 'title',
            text: 'Title',
            answer: car_title,
        }

        p.data = [...p.data, carTitleItem]

        if (p.data.length > 0) {
            appDispatch(carActions.createCarRequestVersion2(p))
        }
    }

    let generate_car_title = () => {
        if (formData.year || formData.make || formData.model) {
            return `${formData.year ? `${formData.year.toString()} ` : ''}${
                formData.make ? `${formData.make} ` : ''
            }${formData.model ? formData.model : ''}`
        }
    }

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    let isLoadingNewMakeOrModel = useAppSelector((state) => {
        return state.localdata.dropdownData.isLoadingNewMakeOrModel
    })

    return (
        <OnboardingFormTemplateDesktop
            submit={() => {
                let isFormValid = validateOnSubmit()

                if (isFormValid) {
                    submitData()
                }
            }}
            isBtnLoading={isLoadingNewMakeOrModel}
        >
            <PaddingUnit $times={4} />
            <OnboardingText>{formText}</OnboardingText>
            <PaddingUnit $times={5} />

            <>
                <ButtonAtom
                    theme="flexible-transparent-blue-border"
                    height="48px"
                    width="100%"
                    fontSize={16}
                    onClick={onRetrieveByRegNoClick}
                >
                    Find my car by registration number
                </ButtonAtom>
                <PaddingUnit $times={5} />
            </>

            <SelectSearchBarCarInfoDesktop
                placeholder={formData.make ? 'Make *' : 'Make'}
                handleSelectChange={(name: string, uid?: string) => {
                    handleChange('make', name)
                    appDispatch(
                        dropDownActions.setModelDropdownDataRequest({
                            makeName: name,
                            make_uid: uid,
                        })
                    )
                }}
                addAnItemFunc={(id: string, name: string) => {
                    appDispatch(
                        dropDownActions.setIsLoadingNewMakeOrModel(true)
                    )
                    appDispatch(
                        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                            {
                                dropdownTargetId: id,
                                name: name,
                            }
                        )
                    )
                }}
                item={{
                    answer: formData.make,
                    id: 'make',
                    text: 'make *',
                }}
                items={makesDropdowndata ? makesDropdowndata : []}
                formCurrentValue={formData.make}
                dropdownTargetId="carMakes"
                allowAdd={true}
                onlyShowMatch={true}
                customMaxWidth="100%"
                borderColor={error?.make ? 'rgb(223, 111, 111)' : undefined}
            />
            <Expander height={error?.make ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.make}</ErrorText>
            </Expander>
            <PaddingUnit $times={3} />
            <SelectSearchBarCarInfoDesktop
                handleSelectChange={(name: string) => {
                    handleChange('model', name)
                }}
                addAnItemFunc={(id: string, name: string) => {
                    appDispatch(
                        dropDownActions.setIsLoadingNewMakeOrModel(true)
                    )
                    appDispatch(
                        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                            {
                                dropdownTargetId: id,
                                name: name,
                                relevantOtherLinkedDataMakeMake: formData.make,
                            }
                        )
                    )
                }}
                item={{
                    answer: formData.model,
                    id: 'model',
                    text: 'model *',
                }}
                placeholder={formData.model ? 'Model *' : 'Model'}
                dropdownTargetId="carModels"
                items={modelsDropdowndata ? modelsDropdowndata : []}
                formCurrentValue={formData.model}
                allowAdd={true}
                isDisabled={!formData.make ? true : false}
                customMaxWidth="100%"
                borderColor={error?.model ? 'rgb(223, 111, 111)' : undefined}
            />
            <Expander height={error?.model ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.model}</ErrorText>
            </Expander>
            <PaddingUnit $times={3} />
            <InputFieldNoFormikAnimated
                value={formData.year}
                name={'Year *'}
                placeholder=""
                type={'number'}
                onChange={(e: any) => {
                    handleChange('year', e.target.value)
                    setError((prevState) => {
                        return { ...prevState, year: undefined }
                    })
                }}
                linecolor={error?.year ? 'rgb(223, 111, 111)' : undefined}
            />
            <Expander height={error?.year ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.year}</ErrorText>
            </Expander>
            <div style={{ paddingTop: 32 }} />
            <RowForm>
                <InputFieldNoFormikAnimated
                    name="Engine size"
                    placeholder={'Engine size'}
                    tabindex={1}
                    aria-live="polite"
                    value={
                        formData.engine_size !== undefined &&
                        typeof formData.engine_size === 'number' &&
                        formData.engine_size_unit !== 'cubic_centimeters'
                            ? formData.engine_size.toFixed(1)
                            : formData.engine_size
                    }
                    font_family="Lato"
                    type="number"
                    isnarrow={true}
                    onChange={(e: any) => {
                        handleChange('engine_size', e.target.value)
                    }}
                />

                <AdjustPosition>
                    <Select
                        defaultValue={formData.engine_size_unit}
                        onChange={(val: string | undefined) => {
                            handleChange('engine_size_unit', val)
                        }}
                        placeholder={'Unit'}
                        options={
                            fuel_units
                                ? fuel_units.map((unit: IList) => {
                                      return {
                                          value: unit?.id,
                                          label: `${unit?.short}`,
                                      }
                                  })
                                : []
                        }
                    />
                </AdjustPosition>
            </RowForm>
            <Expander height={error?.engine_size ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.engine_size}</ErrorText>
            </Expander>
            {!error?.engine_size && (
                <Expander
                    height={
                        formData.engine_size_unit !== 'liters' &&
                        formData.engine_size
                            ? 'auto'
                            : 0
                    }
                    width="100%"
                >
                    <EquivalentConverted>
                        Equal to{' '}
                        {formData.engine_size &&
                            (formData.engine_size_unit === 'cc' ||
                            formData.engine_size_unit === 'cubic_centimeters'
                                ? cubicCentimetersToLiters(
                                      +formData.engine_size
                                  ).toFixed(1)
                                : formData.engine_size_unit === 'ci' ||
                                  formData.engine_size_unit === 'cubic_inches'
                                ? cubicInchesToLiters(+formData.engine_size)
                                : null)}{' '}
                        litres
                    </EquivalentConverted>
                </Expander>
            )}
            <div style={{ paddingTop: '26px' }} />
            <Select
                defaultValue={formData.transmission}
                placeholder={
                    formData.transmission ? 'Transmission *' : 'Transmission'
                }
                onChange={(val: string) => {
                    handleChange('transmission', val)
                }}
                options={
                    transmissionsList
                        ? transmissionsList.map((unit: IDropdownItem) => ({
                              value: unit?.uid,
                              label: `${unit?.name}`,
                          }))
                        : []
                }
            />
            <PaddingUnit $times={3} />
            <Select
                options={drive_side_options.map((option: { name: string }) => ({
                    value: option.name,
                    label: option.name,
                }))}
                placeholder={
                    formData.drive_side ? 'Drive Side *' : 'Drive Side'
                }
                defaultValue={formData.drive_side}
                onChange={(name) => {
                    handleChange('drive_side', name)
                }}
            />
        </OnboardingFormTemplateDesktop>
    )
}

export default AddACarDataFormOnboardingDesktop
