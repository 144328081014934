import styled from 'styled-components'
import {
    IInsuranceApplicationSectionCard,
    IInsuranceApplicationSectionId,
} from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import React, { useState } from 'react'
import InfoIcon from '../../../atoms/icons/infoIcon'
import { Tooltip } from 'antd'
import SwitchButton from '../../../atoms/Button/switchButton'
import FormMultipleStepsProgress from '../../../atoms/FormStepProgress/FormMultipleStepsProgress'
import { motion } from 'framer-motion'
import { useAppSelector } from '../../../../redux/store/hooks'
import ShimmerOverlay from '../../../atoms/skeleton/shimmerOveraly'
import CircleLoader from '../../../atoms/loader/circleLoader'
import EyeIcon from '../../../atoms/icons/components/eyeIcon'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { generatensuranceSectionIcon } from './insuranceApplicationCardDesktop'
import { device } from '../../../templates/displays/devices'
import { AddIcon, EditIcon } from '../../../atoms/statefulicons'
import { SettingsIcon } from '../../../atoms/icons/components'

type IStyle = {
    $theme: ITheme
    $sectionID?: IInsuranceApplicationSectionId
    $outlinedBtns?: boolean
    $isDisabled?: boolean
    $isApplicationSubmitted?: boolean
}

const Card = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    border-radius: 8px;
    border: ${(props) =>
        `1px solid ${
            props.$sectionID
                ? insurance_colours[props.$theme].sections[props.$sectionID]
                      .primary_30
                : colours[props.$theme].border_muted
        }`};
    background-color: ${(props) => colours[props.$theme].background_default};
`

const CardTopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 24px;
`

const Title = styled.p`
    all: unset;
    line-height: normal;
    font-family: Lato-bold;
    color: var(--text-strong, #1a1a1a);
    font-size: 16px;
    @media ${device.small_mobile} {
        font-size: 14px;
    }
`

const IconBtn = styled(motion.button)`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 4px;
    transition: all 150ms;
    :hover {
        opacity: 0.8;
    }
`

const Text = styled.p<IStyle>`
    color: ${(props) => colours[props.$theme].text_default};
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    letter-spacing: 0.001px;
    width: 100%;
    box-sizing: border-box;
`

type Props = {
    cardProps: IInsuranceApplicationSectionCard
    children: React.ReactNode
    hasIcon?: boolean
    outlinedBtns?: boolean
}

const InsuranceApplicationCardMobile = (props: Props) => {
    let {
        id,
        title,
        text,
        btnIcon,
        onClick,
        isDisabled,
        numberOfProgressBars,
        arrayOfProgressBarsValues,
        hasTooltip,
        tooltipTxt,
        hasToggle,
        onToggle,
        removeBtn,
        sectionID,
    } = props.cardProps

    const [toggle, setToggle] = useState(false)

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )

    let applicationLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isLoading
    )

    let isDraft = application?.status === 'DRAFT' ? true : false

    let showLoadingUI = !application.id || applicationLoading ? true : false

    const loadingArray = arrayOfProgressBarsValues
        ? arrayOfProgressBarsValues.map(() => 0)
        : [0]
    const { theme } = useThemes()

    return (
        <Card
            style={{ marginBottom: 24 }}
            $theme={theme}
            $sectionID={sectionID}
        >
            {title || text ? (
                <CardTopSection style={btnIcon ? {} : { paddingBottom: 12 }}>
                    {title ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {props.hasIcon && sectionID ? (
                                <div
                                    style={{
                                        paddingRight: 12,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {generatensuranceSectionIcon(sectionID)}
                                </div>
                            ) : null}
                            <Title>{title}</Title>
                            {hasTooltip && (
                                <div
                                    style={{
                                        transform: 'translateY(5px)',
                                        paddingLeft: 8,
                                    }}
                                >
                                    <Tooltip
                                        title={tooltipTxt}
                                        placement="right"
                                        color={colours[theme].strong_tooltip}
                                        overlayInnerStyle={{
                                            boxShadow: 'none',
                                        }}
                                    >
                                        <InfoIcon size="16" />{' '}
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    ) : null}
                    {id === 'addresses' && !isDraft ? null : hasToggle ? (
                        <SwitchButton
                            checked={toggle}
                            toggle={(v: boolean) => {
                                if (isDraft) {
                                    onToggle && onToggle(v)
                                    setToggle(!toggle)
                                }
                            }}
                            dataCyId={'makeAddressSameAsDealership'}
                            variant="outline"
                        />
                    ) : removeBtn || showLoadingUI ? null : (
                        <IconBtn
                            whileTap={{ scale: 1.1 }}
                            style={{
                                backgroundColor:
                                    !isDraft || props.outlinedBtns
                                        ? colours[theme].background_default
                                        : isDisabled
                                        ? colours[theme]
                                              .background_disabled_neutral
                                        : sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_100
                                        : colours[theme].primary,
                                border:
                                    !isDraft || props.outlinedBtns
                                        ? `1px solid ${
                                              sectionID
                                                  ? insurance_colours[theme]
                                                        .sections[sectionID]
                                                        .primary_100
                                                  : colours[theme].primary
                                          }`
                                        : 'none',
                                padding: 0,
                            }}
                            onClick={onClick}
                        >
                            {!isDraft ? (
                                <EyeIcon
                                    color={
                                        sectionID
                                            ? insurance_colours[theme].sections[
                                                  sectionID
                                              ].primary_100
                                            : colours[theme].primary
                                    }
                                />
                            ) : btnIcon === 'add' ? (
                                <AddIcon
                                    onClick={() => {}}
                                    colour={
                                        props.outlinedBtns
                                            ? isDisabled
                                                ? colours[theme]
                                                      .background_disabled_neutral
                                                : sectionID
                                                ? insurance_colours[theme]
                                                      .sections[sectionID]
                                                      .primary_100
                                                : colours[theme].primary
                                            : colours[theme].background_default
                                    }
                                />
                            ) : btnIcon === 'edit' ? (
                                <EditIcon
                                    onClick={() => {}}
                                    color={
                                        props.outlinedBtns
                                            ? isDisabled
                                                ? colours[theme]
                                                      .background_disabled_neutral
                                                : sectionID
                                                ? insurance_colours[theme]
                                                      .sections[sectionID]
                                                      .primary_100
                                                : colours[theme].primary
                                            : colours[theme].background_default
                                    }
                                />
                            ) : btnIcon === 'cog' ? (
                                <SettingsIcon
                                    width="16"
                                    height="16"
                                    color={
                                        props.outlinedBtns
                                            ? isDisabled
                                                ? colours[theme]
                                                      .background_disabled_neutral
                                                : sectionID
                                                ? insurance_colours[theme]
                                                      .sections[sectionID]
                                                      .primary_100
                                                : colours[theme].primary
                                            : colours[theme].background_default
                                    }
                                />
                            ) : (
                                <AddIcon
                                    onClick={() => {}}
                                    colour={
                                        props.outlinedBtns
                                            ? isDisabled
                                                ? colours[theme]
                                                      .background_disabled_neutral
                                                : sectionID
                                                ? insurance_colours[theme]
                                                      .sections[sectionID]
                                                      .primary_100
                                                : colours[theme].primary
                                            : colours[theme].background_default
                                    }
                                />
                            )}
                        </IconBtn>
                    )}
                    {showLoadingUI && (
                        <div
                            style={{
                                width: 48,
                                height: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircleLoader
                                size={24}
                                color={
                                    sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_100
                                        : colours[theme].primary
                                }
                            />
                        </div>
                    )}
                </CardTopSection>
            ) : null}
            {arrayOfProgressBarsValues &&
            numberOfProgressBars &&
            numberOfProgressBars > 0 ? (
                <FormMultipleStepsProgress
                    arrayOfProgressBarsValues={
                        showLoadingUI ? loadingArray : arrayOfProgressBarsValues
                    }
                    progress_base_colour={
                        sectionID
                            ? insurance_colours[theme].sections[sectionID]
                                  .primary_16
                            : undefined
                    }
                    progress_filled_colour={
                        sectionID
                            ? insurance_colours[theme].sections[sectionID]
                                  .primary_100
                            : undefined
                    }
                />
            ) : null}
            {text ? (
                <Text $theme={theme} $sectionID={sectionID}>
                    {text}
                </Text>
            ) : null}
            <div
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    height: '100%',
                    position: 'relative',
                }}
            >
                {showLoadingUI && (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '100%',
                        }}
                    >
                        <ShimmerOverlay isthingloading />
                    </div>
                )}
                {props.children}
            </div>
        </Card>
    )
}

export default InsuranceApplicationCardMobile
