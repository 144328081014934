import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import EditIcon from '../../statefulicons/editIcon'

type IStyle = {
    $isLeftAligned?: boolean
    $textTransform?: TextTransform
}

type TextTransform = 'capitalize' | 'lowercase' | 'uppercase' | 'none'

export interface Props {
    children?: React.ReactNode
    leftAlign?: boolean
    textTransform?: TextTransform
    hasEdit?: boolean
    onEdit?: (e?: any) => void
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`
const EditWrapper = styled.div`
    transform: translateY(-2px);
    padding-left: 20px;
    cursor: pointer;
`

const H2Styled = styled.h1<IStyle>`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    text-transform: ${(props) =>
        props.$textTransform ? props.$textTransform : 'none'};
    font-size: 1.5rem;
    font-weight: 500;
    text-align: ${(props) => (props.$isLeftAligned ? 'left' : 'center')};

    margin-top: 0px;
    margin-bottom: 0px;

    @media ${device.mobile} {
        font-family: Lato-semibold;
        font-size: 18px;
        line-height: 32px;
        margin: 0;
    }
`

const ShowroomH2: React.FC<Props> = (props) => {
    return (
        <Wrapper>
            <H2Styled
                $isLeftAligned={props.leftAlign}
                $textTransform={props.textTransform}
            >
                {props.children}
            </H2Styled>
            {props.hasEdit && props.onEdit && (
                <EditWrapper>
                    <EditIcon onClick={props.onEdit} />
                </EditWrapper>
            )}
        </Wrapper>
    )
}

export default ShowroomH2
