import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { actionsObject } from './mainMenuData'

import WrapperCarBannerImageFormMobile from '../../../organisms/editForms/imageFormWrapper/wrapperCarBannerImageFormMobile'
import ModalDisplay from '../../../templates/displays/pageWrappers/modalDisplay'
import { editFormsActions } from '../../../../redux/editForms/reducer'

import { ICreateTaskPayload, IList } from 'entityModels'
import NumberTechInfoEditFormMobile from '../../../organisms/editForms/technicalInformationEditForm/number/mobile/numberTechInfoEditFormMobile'

import { generate_task_cars_dropdown_list } from '../../../../redux/conversions/dropdowns/taskCarsDropdownGenerator'
import CreateTaskFormMobile from '../../../organisms/editForms/taskFormManager/custom/createTaskFormMobile'
import WrapperCarCoverImageMobile from '../../../organisms/editForms/imageFormWrapper/wrapperCarCoverImageMobile'
import * as unitGenerator from '../../../../helpers/units/unitConversion'
import CarOverviewActionsMobile from '../bottomSheetMenu/carOverviewActionsMobile'
import { TimelineCreationTypeIdsEnum } from '../../../../redux/timeline/timelineEnum'
import { checkIfPwaStandalone } from '../../../templates/pwaCustom/checkIpadPwa'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { technicalInformationActions } from '../../../../redux/entities/technical_information/reducer'
import { tasksActions } from '../../../../redux/tasks/reducer'

type IUnitInfo = {
    list: IList[] | null
    id?: string
    user_default_unit?: string
}

const ActionsMenuModalMobile: React.FC<{
    isOpen: boolean
    toggle: () => void
    activepage: string
}> = ({ isOpen, toggle, activepage }) => {
    const [action, setAction] = useState<string | null>(null)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const {
        currentCarID,
        technical_information,
        garageid,
        carsData,
        garagesData,
        user_distance_unit_ids_list,
        user_distance_unit_chosen_id,
        user_distance_unit_data,
    } = useAppSelector((state) => ({
        userid: state.user.userLoggedIn?.id,
        currentCarID: state.entities.carsData.currentCarID,
        technical_information:
            state.entities.technicalInformationData.technical_information,
        garageid: state.user.userLoggedIn?.owns_garage?.uid,
        carsData: state.entities.carsData.cars,
        garagesData: state.entities.garagesData.garages,
        user_distance_unit_ids_list:
            state.user.userLoggedIn?.preferences?.data?.units?.data
                ?.distance_unit?.ids_list,
        user_distance_unit_chosen_id:
            state.user.userLoggedIn?.preferences?.data?.units?.data
                ?.distance_unit?.user_choice_id,
        user_distance_unit_data:
            state.user.userLoggedIn?.preferences?.data?.units?.data
                ?.distance_unit?.data,
    }))

    useEffect(() => {
        if (garageid && !garagesData[garageid]) {
            dispatch(carActions.getGarageCarsDataRequest(garageid))
        }
    }, [garagesData, garageid])

    const listOfActions = (): string[] => {
        let currentPath = checkIfPwaStandalone()
            ? window.location.pathname
            : location.pathname

        if (currentPath === '/garage') {
            return actionsObject.garage
        } else if (currentPath === `/car/${currentCarID}`) {
            return actionsObject.car_overview
        } else return []
    }

    const generate_cars_dropdown_list = (arr: string[]) => {
        if (garageid) {
            return generate_task_cars_dropdown_list(arr, carsData, garageid)
        } else return []
    }

    const distance_units = (): IList[] | null => {
        if (user_distance_unit_ids_list) {
            return user_distance_unit_ids_list.map(
                (id: string) => user_distance_unit_data![id]
            )
        } else return null
    }

    const user_default_distance_unit: IList | null =
        user_distance_unit_data && user_distance_unit_chosen_id
            ? user_distance_unit_data[user_distance_unit_chosen_id]
            : null

    const distanceUnitInfo = (): IUnitInfo => ({
        list: distance_units(),
        id: 'distance_units',
        user_default_unit: user_default_distance_unit?.id,
    })

    const user_default_unit: string | undefined =
        distanceUnitInfo().user_default_unit

    const quickMenuActionItems = listOfActions().map((item) => ({
        id: item,
        action: () => {
            switch (item) {
                case "Update your car's banner image":
                    setAction('editBannerImage')
                    break
                case 'Add Image(s)':
                    dispatch(editFormsActions.toggleCarGalleryEditForm())
                    toggle()
                    break
                case 'add car highlight':
                    dispatch(editFormsActions.toggleCarHighlightsEditForm())
                    toggle()
                    break
                case 'Update Mileage':
                    setAction('mileage')
                    break
                case 'Add a Task':
                    setAction('task')
                    break
                case 'Update Car Profile Image':
                    setAction('cover')
                    break
                case 'Add to History File':
                    navigate(`/car/${carid}/history-file/create`)
                    dispatch(
                        editFormsActions.manageTimelineCreationFormState({
                            isOpen: true,
                            current_step_index: 0,
                            active_creation:
                                TimelineCreationTypeIdsEnum.add_new,
                        })
                    )
                    toggle()
                    break
            }
        },
    }))

    let milage_answer =
        user_default_unit &&
        distanceUnitInfo().user_default_unit &&
        technical_information[`hf-${currentCarID}`] &&
        technical_information[`hf-${currentCarID}`].mileage &&
        technical_information[`hf-${currentCarID}`].mileage.answer &&
        unitGenerator.generateFrontEndValueDistanceUnit(
            user_default_unit,
            technical_information[`hf-${currentCarID}`].mileage.answer as number
        )

    return (
        <div style={{ zIndex: 10 }}>
            {action === 'editBannerImage' && (
                <ModalDisplay
                    toggle={() => {
                        setAction(null)
                        toggle()
                    }}
                    isOpen={true}
                >
                    <WrapperCarBannerImageFormMobile
                        close={() => {
                            setAction(null)
                            toggle()
                        }}
                        carid={`${currentCarID}`}
                    />
                </ModalDisplay>
            )}

            {action === 'mileage' &&
                technical_information[`hf-${currentCarID}`] && (
                    <ModalDisplay
                        toggle={() => {
                            setAction(null)
                            toggle()
                        }}
                        isOpen={true}
                    >
                        <NumberTechInfoEditFormMobile
                            tech_info_id={`hf-${currentCarID}`}
                            submitFunc={(p) => {
                                dispatch(
                                    technicalInformationActions.writeTechnicalInformationRequest(
                                        p
                                    )
                                )
                            }}
                            isUnits={true}
                            unitInfo={distanceUnitInfo()}
                            unitList={distance_units() ?? undefined}
                            item={
                                user_default_unit
                                    ? user_default_unit === 'kilometers'
                                        ? technical_information[
                                              `hf-${currentCarID}`
                                          ].mileage
                                        : {
                                              ...technical_information[
                                                  `hf-${currentCarID}`
                                              ].mileage,
                                              answer: milage_answer,
                                          }
                                    : technical_information[
                                          `hf-${currentCarID}`
                                      ].mileage
                            }
                            toggle={() => {
                                setAction(null)
                                toggle()
                            }}
                            carid={`${currentCarID}`}
                        />
                    </ModalDisplay>
                )}

            {action === 'task' && garagesData[`${garageid}`]?.cars && (
                <ModalDisplay
                    toggle={() => {
                        setAction(null)
                        toggle()
                    }}
                    isOpen={true}
                >
                    <CreateTaskFormMobile
                        toggle={() => {
                            setAction(null)
                            toggle()
                        }}
                        submitFunc={(p: ICreateTaskPayload) => {
                            dispatch(tasksActions.createTaskRequest(p))
                        }}
                        dropdownData={generate_cars_dropdown_list(
                            garagesData[`${garageid}`]?.cars
                        )}
                        carsData={carsData}
                        garage_id={`${garageid}`}
                        currentCarID={currentCarID}
                        garageCarsIds={garagesData[`${garageid}`].cars}
                    />
                </ModalDisplay>
            )}

            {action === 'cover' && (
                <ModalDisplay
                    toggle={() => {
                        setAction(null)
                        toggle()
                    }}
                    isOpen={true}
                >
                    <WrapperCarCoverImageMobile
                        close={() => {
                            setAction(null)
                            toggle()
                        }}
                        carid={`${currentCarID}`}
                    />
                </ModalDisplay>
            )}

            <CarOverviewActionsMobile
                actions_items={quickMenuActionItems}
                toggle={() => {
                    setTimeout(toggle, 50)
                }}
                isCarOverviewQuickMenuOpen={action === null ? isOpen : false}
            />
        </div>
    )
}

export default ActionsMenuModalMobile
