import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import DescriptionEditorMobile from '../../editorSections/description/editorDescriptionMobile'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { IUpdateCarGeneralPayloadReq } from '../../../../redux/entities/cars/types'

type IStyle = {
    $theme: ITheme
}

const EditFormContainerMobile = styled.section<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-top: 7vh;
    height: 100vh;
    overflow-y: scroll;
    background-color: ${(props) => colours[props.$theme].background_default};
    color: ${(props) => colours[props.$theme].text_strong};
    position: relative;
    animation: fadeIn 0.2s both;
    animation-delay: 0.15s;
    opacity: 1;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @media ${device.mobile} {
        padding-top: 5vh;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    @media ${device.ipad} {
        max-width: 600px;
    }
    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const TextareaWrapper = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    padding: 24px 30px;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};

    @media ${device.ipad} {
        max-width: 600px;
    }
`

const FieldTitle = styled.div<IStyle>`
    font-family: Lato;
    align-self: start;
    font-size: 18px;
    letter-spacing: -1px;
    color: ${(props) => colours[props.$theme].text_strong};
    padding-bottom: 10px;

    @media screen and (max-width: 325px) {
        padding-bottom: 15px;
        font-size: 18px;
    }
`

const SmallPadding = styled.div`
    padding-top: 20px;
`

const TextAreaWidth = styled.div`
    width: 100%;
    box-sizing: border-box;
    min-height: 50vh;
    overflow-y: auto;
`

const ButtonsWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding-bottom: 64px;
    @media ${device.ipad} {
        max-width: 600px;
    }
`

export const Error = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].error};
    font-style: italic;
    font-size: 11px;
`

export const Text = styled.div<IStyle>`
    padding-top: 3px;
    color: ${(props) => colours[props.$theme].text_strong};
    text-align: left;

    font-family: Lato-Light;

    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 80vw;

    @media screen and (min-width: 420px) {
        padding-right: 30px;
    }

    @media screen and (max-width: 325px) {
        display: none;
        padding-right: 5px;
    }
`

const HorizLine = styled.div<IStyle>`
    padding-top: 32px;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid ${(props) => colours[props.$theme].border_muted};
`

interface Values {
    description: string
}

interface OtherProps {
    close: () => void
    carDescription: string

    writeCarDataGeneralRequest: (payload: IUpdateCarGeneralPayloadReq) => any
    carId: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { values, close, setFieldValue, errors } = props

    const { theme } = useThemes()

    return (
        <EditFormContainerMobile $theme={theme}>
            <InnerFormPadding>
                <FieldTitle $theme={theme}>Description</FieldTitle>

                <Text $theme={theme}>
                    This is your space to document your car's history, expand on
                    your custodianship, or jot down ideas for future plans.
                </Text>

                <Expander
                    height={
                        values.description.length > 1 && errors.description
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error
                            $theme={theme}
                            className="animated fadeIn slower"
                        >
                            {errors.description}
                        </Error>
                    </Faded>
                </Expander>

                <SmallPadding />
                {/* <TouchScrollable> */}
            </InnerFormPadding>
            <TextareaWrapper $theme={theme}>
                <TextAreaWidth id="scrollable-content">
                    <DescriptionEditorMobile
                        placeholder={`Let's get started...`}
                        valuePassed={values.description}
                        handleChange={(text: any) =>
                            setFieldValue('description', text)
                        }
                        idPassed="description"
                        readOnly={false}
                        height="100%"
                    />
                </TextAreaWidth>
            </TextareaWrapper>
            {/* </TouchScrollable> */}

            {/* <LargePadding /> */}

            <ButtonsWrapper>
                <HorizLine $theme={theme} />
                <InnerFormPadding>
                    <ButtonsStandardBottomRowMobile
                        cancel={close}
                        submit={props.handleSubmit}
                        confirmationtext={'save'}
                        disabled={
                            values.description && errors.description
                                ? true
                                : false
                        }
                    />
                </InnerFormPadding>
            </ButtonsWrapper>
        </EditFormContainerMobile>
    )
}

const CarDescriptionEditFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        description: props.carDescription,
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                description: Yup.string().max(
                    30000,
                    'Description must be be less than 30000 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (props.carDescription !== values.description) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: props.carId,
                dataListForm: [
                    {
                        id: 'description',
                        text: 'description',
                        answer: values.description,
                    },
                ],
            }
            props.writeCarDataGeneralRequest(payload)
        }

        props.close()
    },
})(InnerForm)

export default CarDescriptionEditFormMobile
