import * as React from 'react'
import styled from 'styled-components'
import { calculate_entry_n_attachments } from '../../../redux/conversions/timeline/n_entry_attachments'
import colours, {
    ITheme,
    history_file_colours,
    journey_colours,
} from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import dayjs from 'dayjs'
import { renderCategoryIcon } from '../../atoms/icons/components/historyFile/categories'
import HistoryFileIcons from '../../atoms/icons/components/historyFile'
import { useAppSelector } from '../../../redux/store/hooks'
import * as unitGenerator from '../../../helpers/units/unitConversion'
import { numberWithCommas } from '../../../helpers/numbers'
import OverviewHistoryFileSkeleton from '../../atoms/car/profile/overviewHistoryFileSkeleton'
import JourneyStartIcon from '../../atoms/icons/components/journey/playIcon'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import {
    ICostItem,
    ITimelineEntryTypes,
    ITimelineItem,
} from '../../../redux/timeline/types'

interface IStyled {
    $theme: ITheme
    $category: ITimelineEntryTypes
    $isEmpty?: boolean
}

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    min-width: 0px;
`

const FlexRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    min-width: 0px;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    min-width: 0px;
`

const TopRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    min-width: 0px;
`

export const CategoryLabel = styled.div<IStyled>`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 99px;
    background-color: ${(props) =>
        history_file_colours[props.$theme].categories[props.$category]
            .primary_100};
    color: ${(props) => colours[props.$theme].background_default};
    text-transform: capitalize;
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px;
`

const InfoLabel = styled.div<IStyled>`
    display: flex;
    padding-right: 12px;
    align-items: center;
    border-radius: 8px;
    background-color: ${(props) =>
        props.$isEmpty
            ? history_file_colours[props.$theme].categories[props.$category]
                  .primary_04
            : history_file_colours[props.$theme].categories[props.$category]
                  .primary_16};
    overflow: hidden;
    color: ${(props) =>
        props.$isEmpty
            ? colours[props.$theme].text_muted
            : colours[props.$theme].text_darker};
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.001px;
`

const ItemLabel = styled.div<IStyled>`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid ${(props) => colours[props.$theme].border_muted};
    color: ${(props) => colours[props.$theme].text_darker};
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Title = styled.h3<IStyled>`
    margin: 0px;
    padding: 0px;
    color: ${(props) => colours[props.$theme].text_strong};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const Date = styled.p<IStyled>`
    margin: 0px;
    padding: 0px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

const Description = styled.p<IStyled>`
    margin: 0px;
    padding: 0px;
    color: ${(props) => colours[props.$theme].text_darker};
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const LabelPlaceholder = styled.div<IStyled>`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    color: ${(props) => colours[props.$theme].text_muted};
    min-width: 87px;
    height: 26px;

    font-family: Lato-Semibold;
    font-size: 12px;

    line-height: 16px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
`

const IconWrapper = styled.div`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
`

const JourneyWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid var(--journey-history_file-primary_300);
    background: linear-gradient(
        89deg,
        var(--journey-history_file-primary_500_08) 0%,
        var(--bg-color, #fff) 100%
    );
    display: flex;
    height: 100%;
    align-items: center;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;
    height: 244px;

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
`

const JournetTxt2 = styled.div`
    color: var(--journey-history_file-primary_900);
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.001px;
    max-width: 450px;
`

const JournetTxt1 = styled.div`
    color: var(--journey-history_file-primary_900);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0.001px;
`

type Props = {
    item: ITimelineItem
    onLeft?: boolean
    onRight?: boolean
    isCarOverview?: boolean
    userCurrency: string | undefined
    hasSampleData?: boolean
    onPlaceholderClick?: () => void
    readOnlyMode?: boolean
    isFirst?: boolean
}

const TimelineItemBodyDesktop: React.FC<Props> = (props) => {
    const { item, isCarOverview, hasSampleData, onPlaceholderClick } = props

    let costt: ICostItem | undefined =
        props.item?.costItemsObj && props.item?.costItemsObj['id']

    let companies: string[] | undefined = item.costUIDS
        ?.filter(
            (costUID: string) =>
                item.costItemsObj &&
                item.costItemsObj[costUID] &&
                item.costItemsObj[costUID].company !== undefined
        )
        .map((costUID: string) => {
            return item.costItemsObj![costUID].company!
        })

    const { theme } = useThemes()

    let carid = useAppSelector((state) => state.entities.carsData.currentCarID)
    let technicalInformationData = useAppSelector(
        (state) => state.entities.technicalInformationData.technical_information
    )
    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    let userDistanceUnitId =
        userLoggedIn &&
        userLoggedIn.preferences.data.units.data.distance_unit.user_choice_id
    let userDistanceUnit =
        userLoggedIn &&
        userDistanceUnitId &&
        userLoggedIn.preferences.data.units.data.distance_unit.data[
            userDistanceUnitId
        ].short

    let carMileageFromState: number | string | undefined | null =
        carid &&
        technicalInformationData &&
        technicalInformationData[`hf-${carid}`] &&
        technicalInformationData[`hf-${carid}`].mileage &&
        technicalInformationData[`hf-${carid}`].mileage.answer

    let carMileage: number | undefined | null =
        typeof carMileageFromState === 'string'
            ? parseInt(carMileageFromState)
            : carMileageFromState

    let convertedCarMileage =
        carMileage && userDistanceUnitId
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnitId,
                  carMileage
              )
            : undefined

    let convertedMileageRecorded: number | undefined =
        userDistanceUnitId && item.mileage
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnitId,
                  item.mileage
              )
            : undefined

    let mileageSince =
        convertedCarMileage !== undefined &&
        convertedMileageRecorded !== undefined &&
        convertedCarMileage >= convertedMileageRecorded
            ? convertedCarMileage - convertedMileageRecorded
            : undefined

    let attach_no = calculate_entry_n_attachments(item)

    let userCurrencyPreferences =
        userLoggedIn?.preferences.data.units.data.default_currency
            .user_choice_id

    let userCurrency = props.userCurrency ?? userCurrencyPreferences

    let labels_no = isCarOverview ? 2 : 3

    return !props.readOnlyMode && hasSampleData && props.isFirst ? (
        <JourneyWrapper role="button">
            <JourneyStartIcon
                color={journey_colours[theme].section.history_file.primary_500}
                size={'40px'}
            />
            <div style={{ paddingTop: '12px' }} />
            <JournetTxt1>
                {journeyRedirectCardsCopy.history_file.title}
            </JournetTxt1>
            <div style={{ paddingTop: '8px' }} />
            <JournetTxt2>
                {journeyRedirectCardsCopy.history_file.description}
            </JournetTxt2>
        </JourneyWrapper>
    ) : hasSampleData ? (
        <OverviewHistoryFileSkeleton
            onClick={onPlaceholderClick && onPlaceholderClick}
            isWhite
            reaonlyMode={props.readOnlyMode}
        />
    ) : (
        <Wrapper>
            <Column>
                <TopRow>
                    <CategoryLabel
                        $theme={theme}
                        $category={item.categoryID ?? 'maintenance'}
                    >
                        {renderCategoryIcon(item.categoryID)} {item.categoryID}
                    </CategoryLabel>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Date
                            key="start-date"
                            $theme={theme}
                            $category={item.categoryID ?? 'maintenance'}
                        >
                            {item.date
                                ? dayjs(item.date).format('DD MMM YYYY')
                                : item.insurance_entry_policy_start_date
                                ? dayjs(
                                      item.insurance_entry_policy_start_date
                                  ).format('DD MMM YYYY')
                                : dayjs(item.date).format('DD MMM YYYY')}
                        </Date>
                        {item.insurance_entry_policy_end_date ? (
                            <Date
                                key="end-date"
                                $theme={theme}
                                $category={item.categoryID ?? 'maintenance'}
                            >
                                {` - `}
                                {dayjs(
                                    item.insurance_entry_policy_end_date
                                ).format('DD MMM YYYY')}
                            </Date>
                        ) : null}
                    </div>
                </TopRow>
                <FlexRow>
                    {item.labels && item.labels.length > 0
                        ? item.labels.map((label, i) =>
                              i < labels_no ? (
                                  <ItemLabel
                                      key={`${i}_${label.uid}`}
                                      $theme={theme}
                                      $category={
                                          item.categoryID ?? 'maintenance'
                                      }
                                  >
                                      {label.name}
                                  </ItemLabel>
                              ) : item.labels?.length &&
                                item.labels?.length > 3 &&
                                i === labels_no ? (
                                  <ItemLabel
                                      key={`${i}_${label.uid}`}
                                      $theme={theme}
                                      $category={
                                          item.categoryID ?? 'maintenance'
                                      }
                                  >
                                      +{item.labels?.length - labels_no}
                                  </ItemLabel>
                              ) : null
                          )
                        : [1, 2, 3].map((p) =>
                              p === 1 ? (
                                  <LabelPlaceholder
                                      key={`${p}`}
                                      $theme={theme}
                                      $category={
                                          item.categoryID ?? 'maintenance'
                                      }
                                  >
                                      NO LABELS
                                  </LabelPlaceholder>
                              ) : (
                                  <LabelPlaceholder
                                      style={{ opacity: p === 2 ? 0.6 : 0.3 }}
                                      $theme={theme}
                                      $category={
                                          item.categoryID ?? 'maintenance'
                                      }
                                  />
                              )
                          )}
                </FlexRow>
            </Column>
            <Title $theme={theme} $category={item.categoryID ?? 'maintenance'}>
                {item.title}
            </Title>
            {item.description ? (
                <Description
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    style={{ transform: 'translateY(-10px)' }}
                >
                    {item.description}
                </Description>
            ) : (
                <Description
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    style={{ color: colours[theme].text_muted }}
                >
                    No description added
                </Description>
            )}
            <FlexRow>
                <InfoLabel
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    $isEmpty={companies && companies?.length > 0 ? false : true}
                >
                    <IconWrapper>
                        <HistoryFileIcons.CompaniesIcon
                            color={
                                companies && companies?.length > 0
                                    ? colours[theme].text_darker
                                    : colours[theme].text_muted
                            }
                        />
                    </IconWrapper>
                    {companies?.length ?? 0}
                </InfoLabel>
                <InfoLabel
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    $isEmpty={attach_no ? false : true}
                >
                    <IconWrapper>
                        <HistoryFileIcons.AttachmentsIcon
                            color={
                                attach_no
                                    ? colours[theme].text_darker
                                    : colours[theme].text_muted
                            }
                        />
                    </IconWrapper>
                    {attach_no ?? 0}
                </InfoLabel>
                <InfoLabel
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    $isEmpty={
                        item.total_cost_amount_display !== '0' &&
                        item.total_cost_amount_display !== '0.00'
                            ? false
                            : true
                    }
                >
                    <IconWrapper>
                        {userCurrency ? (
                            userCurrency === 'GBP' ? (
                                <HistoryFileIcons.CurrencyIcons.GbpIcon
                                    color={
                                        item.total_cost_amount_display !==
                                            '0' &&
                                        item.total_cost_amount_display !==
                                            '0.00'
                                            ? colours[theme].text_darker
                                            : colours[theme].text_muted
                                    }
                                />
                            ) : userCurrency === 'USD' ? (
                                <HistoryFileIcons.CurrencyIcons.UsdIcon
                                    color={
                                        item.total_cost_amount_display !==
                                            '0' &&
                                        item.total_cost_amount_display !==
                                            '0.00'
                                            ? colours[theme].text_darker
                                            : colours[theme].text_muted
                                    }
                                />
                            ) : userCurrency === 'EUR' ? (
                                <HistoryFileIcons.CurrencyIcons.EurIcon
                                    color={
                                        item.total_cost_amount_display !==
                                            '0' &&
                                        item.total_cost_amount_display !==
                                            '0.00'
                                            ? colours[theme].text_darker
                                            : colours[theme].text_muted
                                    }
                                />
                            ) : (
                                <HistoryFileIcons.CurrencyIcons.GbpIcon
                                    color={
                                        item.total_cost_amount_display !==
                                            '0' &&
                                        item.total_cost_amount_display !==
                                            '0.00'
                                            ? colours[theme].text_darker
                                            : colours[theme].text_muted
                                    }
                                />
                            )
                        ) : (
                            <HistoryFileIcons.CurrencyIcons.GbpIcon
                                color={
                                    item.total_cost_amount_display
                                        ? colours[theme].text_darker
                                        : colours[theme].text_muted
                                }
                            />
                        )}
                    </IconWrapper>
                    {!costt?.amount
                        ? item.total_cost_amount_display_short ?? '0.00'
                        : numberWithCommas({
                              numberToConvert: +costt?.amount,
                              decimals: 2,
                          })}
                </InfoLabel>
                <InfoLabel
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    $isEmpty={item.mileage ? false : true}
                >
                    <IconWrapper>
                        <HistoryFileIcons.MileageIcon
                            color={
                                item.mileage
                                    ? colours[theme].text_darker
                                    : colours[theme].text_muted
                            }
                        />
                    </IconWrapper>
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {convertedMileageRecorded
                            ? `${numberWithCommas({
                                  numberToConvert: convertedMileageRecorded,
                                  decimals: 0,
                              })}  ${userDistanceUnit} `
                            : 'unknown'}
                    </span>
                    <span
                        style={{
                            color: colours[theme].primary,
                            paddingLeft: 2,
                            fontFamily: 'Lato-Semibold',
                        }}
                    >
                        {mileageSince !== undefined
                            ? ` +${numberWithCommas({
                                  numberToConvert: mileageSince,
                                  decimals: 0,
                                  round: 'ceil',
                              })}`
                            : ''}
                    </span>
                    {mileageSince ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M7.19998 5.56667C7.59998 5.03333 8.39998 5.03333 8.79998 5.56667L11.3 8.9C11.7944 9.55924 11.324 10.5 10.5 10.5L5.49998 10.5C4.67594 10.5 4.20556 9.55924 4.69998 8.9L7.19998 5.56667Z"
                                fill={colours[theme].primary}
                            />
                        </svg>
                    ) : null}
                </InfoLabel>
            </FlexRow>
        </Wrapper>
    )
}

export default TimelineItemBodyDesktop
