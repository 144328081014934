import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Button from '../Button/ButtonAtom'
import Expander from '../expander/expander'
import './styles.css'
import parse from 'html-react-parser'

const Reveal = styled.div<IStyle>`
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    top: 1;
    z-index: 1;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0), #ffffff 70%);
    opacity: ${(props) => (props.isOpen ? '0' : '1')};

    transition: all 300ms;
`
const Wrapper = styled.div<IStyle>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-self: start;
    justify-self: start;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-self: start;
    justify-self: start;
`

const ReadMore = styled.div<IStyle>`
    z-index: 2;
    padding-top: 30px;

    @media ${device.beyond_ipad_mobile} {
        padding-left: 5px;
    }
`

interface Props {
    notes: string
    onClick?: any
}

interface IStyle {
    isOpen: boolean
}

const TimelineNotesDesktop: React.FC<Props> = ({ notes }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    const toggleReveal = () => {
        setIsOpen(!isOpen)
    }

    const isTextShort: boolean = notes.length < 1822

    return (
        <Container>
            {notes.length > 1 && (
                <Wrapper isOpen={isTextShort ? true : isOpen}>
                    <Reveal isOpen={isTextShort ? true : isOpen} />
                    <Expander
                        height={isTextShort ? 'auto' : isOpen ? 'auto' : 200}
                    >
                        {parse(notes)}
                    </Expander>

                    {!isTextShort && (
                        <ReadMore isOpen={isOpen}>
                            <Button
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    toggleReveal()
                                }}
                                theme="naked-text"
                            >
                                {isOpen ? 'read less' : 'read more'}
                            </Button>
                        </ReadMore>
                    )}
                </Wrapper>
            )}
        </Container>
    )
}

export default TimelineNotesDesktop
