import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { IConvertSearchAPIReqToURL_arg } from '../../conversions/showroom/searchURLBuilder'
import { api } from '../../services'
import {
    ISearchShowroom_API_req_args,
    ISearchShowroom_API_res,
} from '../../services/showroom/types'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { showroomActions } from '../reducer'
import {
    IShowroomEntriesSimilarRecommendationsObj,
    IShowroomEntry,
} from '../types'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'

export type IShowroomEntrySimilarRecommendationsRequest = {
    showroomEntry: IShowroomEntry
}

export type IShowroomEntrySimilarRecommendationssuccess = {
    dealer: IShowroomEntriesSimilarRecommendationsObj
    make?: IShowroomEntriesSimilarRecommendationsObj
}

export function* SearchShowroomEntryRecommendations(
    props?: IShowroomEntrySimilarRecommendationsRequest
) {
    let offset: number = 0
    let limit: number = 4
    // in case theres car id in them

    try {
        // 1. dealer

        if (
            props?.showroomEntry.owner?.id &&
            props?.showroomEntry.car?.overview?.data.make.current_uid
        ) {
            let dealer_filter_data: ISearchShowroom_API_req_args = {
                states: 'PUBLISHED',
                owner: {
                    ids: [props?.showroomEntry.owner?.id],
                },
            }
            let dealer_search: IConvertSearchAPIReqToURL_arg = {
                data: dealer_filter_data,
                offset: offset,
                limit: limit,
            }
            const res_dealer: ISearchShowroom_API_res = yield call(
                api.showroom.searchShowroom,
                dealer_search
            )

            // 2. make
            // let make_filter_data: ISearchShowroom_API_req_args = {
            //     states: 'PUBLISHED',
            //     makes: [props?.showroomEntry.car?.overview?.data.make.id],
            // }
            // let make_search: IConvertSearchAPIReqToURL_arg = {
            //     data: make_filter_data,
            //     offset: offset,
            //     limit: limit,
            // }
            // const res_make: ISearchShowroom_API_res = yield call(
            //     api.showroom.searchShowroom,
            //     make_search
            // )

            yield put(
                showroomActions.getShowroomEntryRecommendationsSuccess({
                    dealer: {
                        latest: res_dealer.cars,
                        total_count: res_dealer.count,
                        id: props?.showroomEntry.owner?.id,
                        name:
                            props?.showroomEntry.owner?.display_name ??
                            'Unknown',
                    },
                    // make: {
                    //     latest: res_make.cars,
                    //     total_count: res_make.count,
                    //     id: props?.showroomEntry.car?.overview?.data.make.current_uid,
                    //     name: props?.showroomEntry.car?.overview?.data.make
                    //         .answer,
                    // },
                })
            )
        } else {
            yield put(
                showroomActions.getShowroomEntryRecommendationsSuccess(
                    undefined
                )
            )
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: SearchShowroomEntryRecommendations,
                payload: props,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(
                showroomActions.getShowroomEntryRecommendationsError(
                    customError
                )
            )
        }
    }
}

function* watcherGetShowroomEntryRecommendations() {
    while (true) {
        const { payload } = yield take(
            showroomActions.getShowroomEntryRecommendationsRequest
        )

        yield call(SearchShowroomEntryRecommendations, payload)
    }
}

const get_showroom_entry_recommendations: any[] = [
    fork(watcherGetShowroomEntryRecommendations),
]

export default get_showroom_entry_recommendations
