import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Sheet } from 'react-modal-sheet'
import { IDropdownItem } from 'entityModels'
import { device } from '../../../templates/displays/devices'

type IStyledProps = {
    color?: string
    $customHeight?: string
    $ipadCustomHeight?: string
    $isSelected?: boolean
    $customActiveColour?: string
}

const CustomSheet = styled(Sheet)<IStyledProps>`
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '70vh !important'};
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;

        @media ${device.ipad} {
            height: ${(props) =>
                props.$ipadCustomHeight
                    ? `${props.$ipadCustomHeight} !important`
                    : props.$customHeight
                    ? `${props.$customHeight} !important`
                    : '70vh !important'};
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .react-modal-sheet-header {
        height: 30px !important;
        align-items: flex-start !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 30px !important;
        margin-top: 10px;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`

const Option = styled.div<IStyledProps>`
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    font-family: 'Lato';
    font-size: 16px;
    color: ${(props) =>
        props.$isSelected
            ? props.$customActiveColour ?? '#5EC3CA'
            : 'var(--text-strong, #1a1a1a)'};
`

interface IProps {
    toggle: any
    isOpen: boolean
    dropdownData?: IDropdownItem[] | null | undefined
    currentOption?: string | null
    currentDropdownItem?: IDropdownItem
    onSelected?: (value: any) => void
    sendDropdownItem?: boolean
    customHeight?: string
    ipadCustomHeight?: string
    isValueLabel?: boolean
    disabled?: boolean
    allowContentScroll?: boolean
    customActiveColour?: string
}

const MobileSelectBottomSheet: React.FunctionComponent<IProps> = (props) => {
    const {
        isOpen,
        toggle,
        dropdownData,
        currentOption,
        customHeight,
        ipadCustomHeight,
        onSelected,
        currentDropdownItem,
        sendDropdownItem,
        isValueLabel,
        disabled,
        allowContentScroll,
        customActiveColour,
    } = props

    const [currentSelected, setCurrentSelected] = useState<any>(
        currentOption
            ? currentOption
            : currentDropdownItem
            ? currentDropdownItem
            : undefined
    )

    useEffect(() => {
        if (currentOption) {
            setCurrentSelected(currentOption)
        }

        if (currentDropdownItem) {
            setCurrentSelected(currentDropdownItem)
        }

        return
    }, [currentOption, currentDropdownItem])

    let mappedDropdownData =
        dropdownData &&
        dropdownData.map((item: IDropdownItem, index: number) => {
            if (item) {
                let namee = isValueLabel ? item.label : item.name
                return (
                    <Option
                        $customActiveColour={customActiveColour}
                        $isSelected={
                            currentDropdownItem && namee == currentSelected.name
                                ? true
                                : item.name === currentSelected
                                ? true
                                : false
                        }
                        key={`select_bottomsheet_menu_${item.uid}_${index}_mobile`}
                        onClick={() => {
                            if (!disabled) {
                                if (onSelected) {
                                    if (sendDropdownItem) {
                                        onSelected(
                                            isValueLabel
                                                ? {
                                                      uid: item.value,
                                                      name: item.label,
                                                  }
                                                : item
                                        )
                                    } else
                                        onSelected(
                                            isValueLabel
                                                ? item.label
                                                : item.name
                                        )
                                }
                                toggle(false)
                            }
                        }}
                    >
                        {isValueLabel ? item.label : item.name}
                    </Option>
                )
            } else {
                return null
            }
        })

    return (
        <React.Fragment>
            <CustomSheet
                isOpen={isOpen}
                onClose={() => {
                    toggle(false)
                }}
                $customHeight={customHeight}
                $ipadCustomHeight={ipadCustomHeight}
                disableDrag={allowContentScroll ? true : false}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content
                        style={{
                            overflowY: allowContentScroll ? 'auto' : undefined,
                        }}
                    >
                        {mappedDropdownData}
                        <div style={{ paddingTop: 40 }} />
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop
                    onTap={(e: any) => {
                        e.preventDefault()
                        setTimeout(function () {
                            toggle(false)
                        }, 50)
                    }}
                />
            </CustomSheet>
        </React.Fragment>
    )
}
export default MobileSelectBottomSheet
