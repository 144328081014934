import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { IJourneySectionID, IRedirectCardsCopy } from 'journeyInterfaces'
import JourneyStartIcon from '../../../../atoms/icons/components/journey/playIcon'
import { generatenJourneyCardPattern } from '../../../../atoms/patterns/journey'

const Wrapper = styled.div<IStyle>`
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background-color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID]
                .primary_500_70
        }`};
`

const StartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;
`
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
`

const Title = styled.h3<IStyle>`
    padding: 0;
    margin: 0;
    align-self: stretch;
    color: ${(props) => `${colours[props.$theme].background_default}`};
    font-family: Lato;
    font-weight: 600;
    text-shadow: none;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 2px;
    text-align: center;
`

const Description = styled.p<IStyle>`
    padding: 0;
    margin: 0;
    align-self: stretch;
    color: ${(props) => `${colours[props.$theme].background_default}`};
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    text-align: center;
`

const DismissButton = styled.button<IStyle>`
    all: unset;
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-items: center;
    border-radius: 8px;
    background: ${(props) => `${colours[props.$theme].background_default_20}`};
`
const DismissButtonText = styled.p<IStyle>`
    margin: 0;
    padding: 0;
    color: ${(props) => `${colours[props.$theme].background_default}`};
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 12px;
`

const AbsolutePositionedPattern = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
`

type IStyle = {
    $theme: ITheme
    $sectionID: IJourneySectionID
}

export default function JourneyPatternCardMobile({
    sectionID,
    copyData,
    onDismiss,
    onStartNow,
    dataPH,
}: {
    sectionID: IJourneySectionID
    copyData: IRedirectCardsCopy
    dataPH: string
    onStartNow: () => void
    onDismiss: () => void
}) {
    const { theme } = useThemes()
    return (
        <Wrapper $theme={theme} $sectionID={sectionID} data-ph={dataPH}>
            <StartWrapper onClick={onStartNow} style={{ zIndex: 2 }}>
                <JourneyStartIcon
                    size="40"
                    color={colours[theme].background_default}
                />
                <TextWrapper>
                    <Title $theme={theme} $sectionID={sectionID}>
                        {copyData[sectionID].title}
                    </Title>
                    <Description $theme={theme} $sectionID={sectionID}>
                        {copyData[sectionID].description}
                    </Description>
                </TextWrapper>
            </StartWrapper>

            <DismissButton
                $theme={theme}
                $sectionID={sectionID}
                onClick={onDismiss}
                style={{ zIndex: 2 }}
            >
                <DismissButtonText $theme={theme} $sectionID={sectionID}>
                    Dismiss
                </DismissButtonText>
            </DismissButton>
            <AbsolutePositionedPattern style={{ zIndex: 0 }}>
                {generatenJourneyCardPattern(sectionID, 'auto', '100%')}
            </AbsolutePositionedPattern>
        </Wrapper>
    )
}
