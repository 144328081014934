import { useLocation, useNavigate } from 'react-router-dom'
import { JourneyPageBackground } from '../../templates/windowPageTemplate/journey/pageBackground'
import { IJourneyCarProfileStepID } from 'journeyInterfaces'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { useEffect, useState } from 'react'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import CarProfileJourneyStepsManager from '../../organisms/journey/carProfile/stepManager'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { usersActions } from '../../../redux/user/reducer'

export default function CarJourney() {
    const navigate = useNavigate()
    const location = useLocation()

    let params = new URLSearchParams(location.search)
    let step: string | null = params.get('step')
    let carid: string | null = params.get('carid')

    useEffect(() => {
        window?.scrollTo(0, 0)
    }, [step])

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const garage_data = useAppSelector((state) => {
        return state.entities.garagesData.garages
    })
    const cars_data = useAppSelector((state) => {
        return state.entities.carsData.cars
    })
    let garage_cars =
        userLoggedIn &&
        userLoggedIn.owns_garage &&
        userLoggedIn.owns_garage.uid &&
        garage_data &&
        garage_data[userLoggedIn.owns_garage.uid] &&
        garage_data[userLoggedIn.owns_garage.uid].cars
            ? garage_data[userLoggedIn.owns_garage.uid].cars
            : []

    const [originPath, setOriginPath] = useState<string>('')

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl('/journey/car-profile')
        )
        let locationState: any = location.state

        if (locationState?.prevPath) {
            if (locationState?.prevPath.includes(`step=achieved`)) {
                carid
                    ? setOriginPath(`/car/${carid}`)
                    : setOriginPath(`/garage`)
            } else {
                setOriginPath(locationState?.prevPath)
            }
        } else {
            setOriginPath(`/garage`)
        }
    }, [])

    useEffect(() => {
        if (userLoggedIn) {
            if (!garage_cars || garage_cars.length === 0) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }

            if (
                carid &&
                (!cars_data[carid] || cars_data[carid].has_limited_garage_info)
            ) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            }
        } else {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn, carid])

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    return (
        <JourneyPageBackground
            sectionID="car_profile"
            hasBlurredBg={true}
            hasLinearBgColour={true}
            hasPattern={isMobile ? (step === 'welcome' ? true : false) : true}
        >
            <CarProfileJourneyStepsManager
                stepID={step as IJourneyCarProfileStepID}
                startJourney={() => {
                    const navigateOptions = {
                        pathname: '/journey/car-profile',
                        search: '',
                        state: {
                            prevPath: `${location.pathname}${
                                location.search ?? ''
                            }`,
                        },
                    }

                    if (carid) {
                        navigateOptions.search = `?step=profile_photo&carid=${carid}`
                    } else if (garage_cars && garage_cars.length === 1) {
                        navigateOptions.search = `?step=profile_photo&carid=${garage_cars[0]}`
                    } else {
                        navigateOptions.search = '?step=select_car'
                    }

                    navigate(navigateOptions)
                }}
                exitJourney={() => {
                    navigate(originPath)
                }}
                carid={carid}
                carsData={cars_data}
                garageCars={garage_cars}
                user={userLoggedIn}
            />
        </JourneyPageBackground>
    )
}
