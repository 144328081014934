import React from 'react'
import styled from 'styled-components'
import IconButton from '../../atoms/Button/iconButton'
import SectionHeaderV2 from '../../atoms/typography/sectionHeaderV2'
import { device } from '../../templates/displays/devices'
import { Link } from 'react-router-dom'
import NavigateSquareIcon from '../../atoms/icons/components/navigateSquareIcon'
import { ICar } from '../../../redux/entities/cars/types'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.ipad} {
        padding-left: 0px;
        padding-right: 0px;
    }
    @media (min-width: 1000px) and (max-width: 1100px) {
        padding-right: 70px;
    }
`

const TitleWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    @media ${device.ipad} {
        display: none;
    }
`

const ArrowButtonsWrapper = styled.div`
    display: none;
    @media ${device.ipad} {
        display: flex;
        align-items: center;
    }
`

const LeftDiv = styled.div`
    display: flex;
    align-items: center;
`

const SmallPadding = styled.div`
    padding-left: 10px;
`

const IPadCarTitle = styled.h1`
    font-family: Lato-bold;
    font-size: 16px;
    text-transform: capitalize;
    padding-right: 10px;
    padding-left: 20px;
`

interface Props {
    elementToScrollTo: HTMLElement | null
    goBackUrl: string
    onDeleteClick: any
    goToNext?: any
    goToPrevious?: any
    carData: ICar
    customTitle?: string
    removeUpDownNav?: boolean
}

const CarShareTopHeaderDesktop: React.FC<Props> = ({
    elementToScrollTo,
    goBackUrl,
    onDeleteClick,
    goToNext,
    goToPrevious,
    carData,
    customTitle,
    removeUpDownNav,
}) => {
    return (
        <Wrapper>
            <LeftDiv>
                <ArrowButtonsWrapper>
                    <Link to={`${goBackUrl}`}>
                        <IconButton
                            icon="grey_cross"
                            iconwidth="14px"
                            noborder
                            bgcolor="transparent"
                        />
                    </Link>
                    {!removeUpDownNav && (
                        <>
                            <SmallPadding />
                            <IconButton
                                icon="arrow"
                                onClick={() => goToPrevious && goToPrevious()}
                                // icon="grey_cross"
                                iconwidth="14px"
                                noborder
                                bgcolor="var(--bg-color, #fff)"
                            />
                            <SmallPadding />
                            <IconButton
                                icon="arrow"
                                orientation="down"
                                onClick={() => goToNext && goToNext()}
                            />
                        </>
                    )}
                    {removeUpDownNav && customTitle && (
                        <IPadCarTitle>
                            {customTitle ? customTitle : carData.title}
                        </IPadCarTitle>
                    )}
                </ArrowButtonsWrapper>
                <TitleWrapper>
                    <Link
                        to={`${goBackUrl}`}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <NavigateSquareIcon />
                    </Link>
                    <SmallPadding />
                    <SmallPadding />
                    <SectionHeaderV2
                        fontsize="24px"
                        icon="v2_edit"
                        hasActionIcon={false}
                    >
                        {customTitle ? customTitle : carData.title}
                    </SectionHeaderV2>
                </TitleWrapper>
            </LeftDiv>
        </Wrapper>
    )
}

export default CarShareTopHeaderDesktop
