import { IApexCarOfTheWeekState } from 'apexModels'
import React, { useState } from 'react'
import styled from 'styled-components'
import ShowMoreExpandToggleMobile from '../../../../atoms/Button/showMoreExpandToggleMobile'
import external_link_green from '../../../../../public/assets/icons/external_link_green.svg'
import Expander from '../../../../atoms/expander/expander'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 22px;
    padding-bottom: 22px;
`

const Image = styled.img`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    height: 24vh;
    object-fit: cover;
    border-radius: 5px;

    @media screen and (min-width: 500px) {
        height: 300px;
    }
`

const Date = styled.div`
    font-family: Lato-Light;
    font-size: 11px;
    padding-top: 20px;
`

const Name = styled.div`
    font-family: Lato;
    font-size: 16px;
    padding-top: 8px;
    color: var(--text-strong, #1a1a1a);
`

const Title = styled.div`
    font-family: Lato-Light;
    font-size: 16px;
    padding-top: 2px;
`

const Text = styled.div`
    line-height: 23px;
    font-family: Lato-Light;
    font-size: 14px;
    color: var(--text-strong, #1a1a1a);
    padding-top: 10px;
    padding-right: 10px;
`
const TakeCloserLookText = styled.a`
    color: var(--primary, #5ec3ca) !important;
    :focus {
        color: var(--primary, #5ec3ca) !important;
    }
    font-family: 'Lato';
    text-transform: uppercase;
    font-size: 12px;
`

const ExternalLinkIcon = styled.img`
    width: 11px;
    height: 11px;
    margin-left: 8px;
    transform: translateY(-2px);
`

type Props = {
    car: IApexCarOfTheWeekState
}

const ApexCarOfTheWeekItemMobile: React.FC<Props> = ({ car }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <Container>
            <Wrapper>
                <Image
                    src={car.img && car.img.url ? car.img.url : ''}
                    alt={car.name}
                />
                <Date>{car.date}</Date>
                <Name>{car.name}</Name>
                <Title>{car.title}</Title>

                <Expander height={isExpanded ? 'auto' : 60}>
                    <Text>{car.description}</Text>

                    <div style={{ paddingTop: '14px' }} />
                    <TakeCloserLookText href={car.link} target="_blank">
                        Take a closer look
                        <ExternalLinkIcon src={external_link_green} />
                    </TakeCloserLookText>
                    <div style={{ paddingTop: '20px' }} />
                </Expander>

                <div style={{ paddingTop: '3px' }} />

                <ShowMoreExpandToggleMobile
                    onClick={toggleIsExpanded}
                    isExpanded={isExpanded}
                />
            </Wrapper>
        </Container>
    )
}

export default ApexCarOfTheWeekItemMobile
