import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import SelectGroup from '../../../molecules/formFieldsGroup/selectGroup'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { IHelpPageContact } from 'helpPageCopyModels'
import { useAppDispatch } from '../../../../redux/store/hooks'
import { helpActions } from '../../../../redux/localdata/help/reducer'

const Wrap = styled.section`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 30px;
    margin-bottom: 150px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media ${device.ipad} {
        width: 70vw;
    }
`

const SmallPadding = styled.div`
    padding-top: 20px;
`

const Text = styled.div`
    font-family: 'Lato';
    font-size: 16px;
    justify-self: flex-start;
    color: var(--text-strong, #1a1a1a);
`

const ContactUsSection = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin: 0px;
    font-family: 'Lato-bold';
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .contact {
        white-space: nowrap;
    }
`

const HorizontalLine = styled.div`
    background: var(--text-strong, #1a1a1a);

    height: 1px;
    width: 100%;
    box-sizing: border-box;
    margin-left: 20px;
`

const EmailsSection = styled.div`
    width: 100%;
    box-sizing: border-box;
`
const EmailText = styled.div`
    font-family: 'Lato-light';
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    padding-bottom: 6px;
`

const MailAddress = styled.a`
    color: var(--primary, #5ec3ca) !important;
    font-family: 'Lato';
`

interface Props {
    contactReasonsData: IHelpPageContact
}

const ContactFormMobile = (props: Props) => {
    const dispatch = useAppDispatch()

    const { contactReasonsData } = props

    return (
        <Wrap>
            <Container>
                <Text>
                    Please select the main reason why you would like to get in
                    touch:
                </Text>
                <SmallPadding />
                <SelectGroup
                    items={contactReasonsData.list}
                    initialValue={contactReasonsData.list[0]}
                    customHandleChange={(value: string) => {
                        dispatch(helpActions.selectContactReason(value))
                    }}
                />
                <SmallPadding />
                <ButtonAtom theme="wide-mobile-blue-bg">
                    <a
                        style={{ color: 'var(--bg-color, #fff)' }}
                        href={
                            'mailto:hello@custodian.club?subject=' +
                            contactReasonsData.list[
                                parseInt(contactReasonsData.selected)
                            ].name
                        }
                        rel="noreferrer"
                        target="_blank"
                    >
                        Send an Email
                    </a>
                </ButtonAtom>
                <SmallPadding />
                <SmallPadding />

                <ContactUsSection>
                    <span className="contact">Contact us</span>
                    <HorizontalLine />
                </ContactUsSection>
                <SmallPadding />
                <EmailsSection>
                    <EmailText>
                        General equiries -{' '}
                        <MailAddress
                            href={'mailto:hello@custodian.club'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            hello@custodian.club
                        </MailAddress>
                    </EmailText>
                    <EmailText>
                        Careers -{' '}
                        <MailAddress
                            href={'mailto:careers@custodian.club?'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            careers@custodian.club
                        </MailAddress>
                    </EmailText>
                </EmailsSection>
            </Container>
        </Wrap>
    )
}

export default ContactFormMobile
