import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import ButtonAtom from '../Button/ButtonAtom'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import colours from '../../../providers/theme/colours'
import { WarningOutlined } from '@ant-design/icons'
import useThemes from '../../../providers/theme/hooks'

export interface StyledProps {
    removeBottomBorder?: boolean
    removeEdit?: boolean
}

const ListItem = styled.div<StyledProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-bottom: ${(props) =>
        props.removeBottomBorder
            ? 'none'
            : 'solid 0.05rem var(--border-muted, #e5e5e5)'};

    height: 50px;
    padding-right: 30px;
    padding-left: 30px;
    position: relative;
    transition: all 200ms;

    cursor: ${(props) => (!props.removeEdit ? 'pointer' : 'auto')};

    :hover {
        transition: all 200ms;

        background-color: ${(props) =>
            !props.removeEdit ? 'var(--off-bg-color, #fafafa)' : 'auto'};
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const Title = styled.div`
    font-family: Lato-Light;
    font-size: 15px;
    padding-right: 20px;
    text-transform: capitalize;
    color: var(--text-strong, #1a1a1a);
`

type IAnswerStyle = {
    removeEdit?: boolean | undefined
    multipleLineAnswer?: boolean | undefined
    readOnlyMode?: boolean
}

const Answer = styled.div<IAnswerStyle>`
    position: absolute;
    left: 300px;
    font-family: Lato;
    font-size: 1.04em;
    ${(props) => props.multipleLineAnswer && 'white-space: pre-line;'};
    color: ${(props) =>
        props.removeEdit && !props.readOnlyMode
            ? 'var(--text-muted)'
            : 'var(--text-strong, #1a1a1a)'};
`

const WrapAnwersMultiple = styled.div`
    display: flex;
    position: absolute;
    left: 300px;
    flex-direction: row;
    margin-right: 30px;
`
const TextSimple = styled.div`
    font-family: Cinzel;
    font-size: 15px;
    color: #1c1c1c;
    text-transform: lowercase;
    margin-right: 30px;
`

interface Props {
    item: any
    toggle: any
    removeBottomBorder?: boolean | undefined
    removeEdit?: boolean | undefined
    unit?: string | undefined | null
    multipleLineAnswer?: boolean | undefined
    dataCyId?: string
    requiredLabel?: boolean
    readOnlyMode?: boolean
}

export const determineAnswerType = (answer: any, custom: any, id: string) => {
    if (typeof answer === 'number') {
        if (id === 'mileage' && answer !== undefined && answer !== null) {
            return answer
        } else if (answer) {
            return answer
        } else return '-'
    }
    if (!answer) {
        return '-'
    }
    if (id === 'body_type') {
        return !answer ? '-' : answer === 'SUV' ? 'SUV / 4x4' : answer
    }

    if (
        (id === 'registration_date' ||
            id === 'last_owner_change' ||
            id === 'service_check_expiry' ||
            id === 'tax_due') &&
        answer !== null &&
        answer !== undefined
    ) {
        return fromIsoStringToDDMMYYY(answer)
    }

    if (custom && custom !== undefined && custom !== null) {
        return custom.length > 0 ? custom : '-'
    } else if (answer) {
        return answer.length > 0 ? answer : '-'
    } else return '-'
}

const ListItemDesktop: React.FC<Props> = (props) => {
    const { theme } = useThemes()
    return (
        <ListItem
            removeEdit={props.removeEdit ? props.removeEdit : false}
            removeBottomBorder={
                props.removeBottomBorder ? props.removeBottomBorder : false
            }
            onClick={() => {
                !props.removeEdit && props.toggle(props.item)
            }}
            data-attr={props.dataCyId && props.dataCyId}
        >
            {props.item !== undefined && (
                <Content>
                    <Title>
                        {props.requiredLabel &&
                        (props.item.id === 'make' ||
                            props.item.id === 'model') ? (
                            <span>
                                {props.item.text}
                                {` `}
                                <div
                                    style={{
                                        display: 'inline-block',
                                        fontSize: '10px',
                                        transform: 'translateY(-7px)',
                                        textTransform: 'lowercase',
                                        fontStyle: 'italic',
                                        color: '#ff5a60',
                                    }}
                                >
                                    {`required `}
                                </div>
                                {` `}
                                <div
                                    style={{
                                        display: 'inline-block',
                                        transform: 'translateY(-3px)',
                                        color: '#ff5a60',
                                    }}
                                >
                                    {` *`}
                                </div>
                            </span>
                        ) : (
                            props.item.text
                        )}
                    </Title>
                    {props.item.answers ? (
                        <WrapAnwersMultiple>
                            {props.item.answers.length < 0
                                ? '-'
                                : props.item.answers.map(
                                      (item: any, index: number) => {
                                          return (
                                              <TextSimple
                                                  key={`list_item_${item.id}_${index}_desktop`}
                                              >
                                                  {item}{' '}
                                                  {props.unit && props.unit}
                                              </TextSimple>
                                          )
                                      }
                                  )}
                        </WrapAnwersMultiple>
                    ) : (
                        <Answer
                            style={{
                                textTransform:
                                    props.item.id && props.item.id === 'email'
                                        ? 'lowercase'
                                        : props.item.id &&
                                          props.item.id ===
                                              'registration_number'
                                        ? 'uppercase'
                                        : 'capitalize',
                            }}
                            removeEdit={props.removeEdit}
                            readOnlyMode={props.readOnlyMode}
                            multipleLineAnswer={props.multipleLineAnswer}
                        >
                            {!props.item.answer &&
                            (props.item.id === 'family_name' ||
                                props.item.id === 'given_name') ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        color: colours[theme].primary,
                                        width: '100%',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <WarningOutlined
                                        size={10}
                                        color={colours[theme].primary}
                                    />{' '}
                                    <div style={{ marginLeft: '30px' }}>
                                        Add your {props.item.text}
                                    </div>
                                </div>
                            ) : (
                                determineAnswerType(
                                    props.item.answer,
                                    props.item.custom
                                        ? props.item.custom
                                        : null,
                                    props.item.id
                                )
                            )}
                            {props.item.id === 'engine_capacity' &&
                                props.item.answer && (
                                    <span
                                        style={{
                                            paddingLeft: '6px',
                                            textTransform: 'initial',
                                        }}
                                    >
                                        {props.unit && props.unit}
                                    </span>
                                )}
                            {props.item.id === 'mileage' &&
                                props.item.answer !== null &&
                                props.item.answer !== undefined && (
                                    <span
                                        style={{
                                            paddingLeft: '6px',
                                            textTransform: 'initial',
                                        }}
                                    >
                                        {props.unit && props.unit}
                                    </span>
                                )}
                        </Answer>
                    )}
                </Content>
            )}

            {!props.removeEdit && (
                <ButtonAtom
                    theme="naked"
                    // onClick={() => {
                    //     props.toggle(props.item)
                    // }}
                >
                    <Icon height="20px" icon="edit_pen" />
                </ButtonAtom>
            )}
        </ListItem>
    )
}

export default ListItemDesktop
