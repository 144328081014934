import { useEffect, useState } from 'react'
import { IAddressActiveModal } from '.'
import GetQuoteAddressesForm from '../../../molecules/insurance/addressesForms/getQuoteAddressessForm'
import RightSideModalDisplay from '../../../templates/displays/pageWrappers/rightSideModalDisplay'
import { getRightSchemaAccountData } from '../../editForms/account/validationSchema'
import GetQuoteSearchSelectAddressForm from '../../../molecules/insurance/addressesForms/getQuoteSearchSelectAddressForm'
import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { insuranceActions } from '../../../../redux/insuranceQuoteApplication/reducer'
import { IInsuranceAddress_API } from '../../../../redux/insuranceQuoteApplication/types'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'

type Props = {
    updateInitialAddressOnCreation?: (
        p: IInsuranceAddress_API | undefined
    ) => any
    selectAddress?: (addressid: string) => void
    selectedAddress?: string
    initialAddressOnCreation?: IInsuranceAddress_API | undefined
    activeModal: IAddressActiveModal
    toggleActiveModal: (p: IAddressActiveModal | undefined) => any
    onSaveForm: () => any
    removeSideEffectSubmit?: boolean
    sectionID?: IInsuranceApplicationSectionId
}

export const AddressWindow = (props: Props) => {
    let {
        updateInitialAddressOnCreation,
        initialAddressOnCreation,
        activeModal,
        toggleActiveModal,
        selectAddress,
        onSaveForm,
        removeSideEffectSubmit,
        sectionID,
        selectedAddress,
    } = props

    const dispatch = useAppDispatch()

    const [updatedAddress, setUpdatedAddress] = useState<
        IInsuranceAddress_API | undefined
    >(undefined)

    const [selectedAddressId, setSelectedAddressId] = useState<
        string | undefined
    >(undefined)

    let emptyAddress: IInsuranceAddress_API = {
        country: 'United Kingdom',
        street_1: undefined,
        street_2: undefined,
        locality: undefined,
        postcode: undefined,
    }

    let { setIsInsuranceWindowOpened } = useSetInitialInsurance()

    useEffect(() => {
        if (activeModal && activeModal.active_modal !== 'select') {
            if (!initialAddressOnCreation) {
                setUpdatedAddress(emptyAddress)
            } else {
                setUpdatedAddress(initialAddressOnCreation)
            }
        }

        setIsInsuranceWindowOpened(activeModal ? true : false)
    }, [initialAddressOnCreation, activeModal])

    useEffect(() => {
        if (selectedAddress) {
            setSelectedAddressId(selectedAddress)
        }
    }, [selectedAddress, activeModal])

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    const validateForm = async (id: string, value: string) => {
        try {
            await getRightSchemaAccountData(id).validate({ [id]: value })
            setError((prevState) => {
                return { ...prevState, [id]: undefined }
            })
        } catch (err: any) {
            setError((prevState) => {
                return { ...prevState, [id]: err.errors[0] }
            })
        }
    }

    const isFormValid = (): boolean => {
        if (activeModal?.active_modal === 'select') {
            if (!selectedAddressId) {
                return false
            } else {
                return true
            }
        } else {
            if (!updatedAddress) {
                return false
            }
            if (
                !updatedAddress.street_1 ||
                updatedAddress.street_1?.length === 0 ||
                !updatedAddress.street_2 ||
                updatedAddress.street_2?.length === 0 ||
                !updatedAddress.locality ||
                updatedAddress.locality?.length === 0 ||
                !updatedAddress.postcode ||
                updatedAddress.postcode?.length === 0 ||
                !updatedAddress.country ||
                updatedAddress.country?.length === 0
            ) {
                return false
            }
            //  else if (
            //     error &&
            //     Object.values(error).some((item) => item !== undefined)
            // ) {
            //     return false
            // }
            else return true
        }
    }

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    return (
        <div
            style={{
                width: '100%',
                boxSizing: 'border-box',
                position: 'fixed',
                zIndex: 101,
            }}
        >
            {activeModal && (
                <RightSideModalDisplay
                    sectionId={sectionID}
                    isOpen={activeModal.active_modal ? true : false}
                    cancel={() => {
                        toggleActiveModal(undefined)
                        updateInitialAddressOnCreation &&
                            updateInitialAddressOnCreation(undefined)
                        setUpdatedAddress(undefined)
                        setSelectedAddressId(undefined)
                        setError && setError(undefined)
                        if (activeModal?.prevOpenedModal === 'select') {
                            toggleActiveModal({
                                active_modal: 'select',
                                title: 'Select address',
                                field_id: activeModal?.field_id,
                            })
                            setSelectedAddressId(undefined)
                        }
                    }}
                    title={activeModal.title}
                    footerBtn={{
                        action: () => {
                            updatedAddress &&
                                dispatch(
                                    insuranceActions.set_answer_addresses(
                                        updatedAddress
                                    )
                                )

                            toggleActiveModal(undefined)
                            if (
                                updatedAddress &&
                                updateInitialAddressOnCreation
                            ) {
                                updateInitialAddressOnCreation(updatedAddress)
                            }

                            setError && setError(undefined)

                            setUpdatedAddress(undefined)
                            setSelectedAddressId(undefined)

                            if (activeModal?.prevOpenedModal === 'select') {
                                toggleActiveModal({
                                    active_modal: 'select',
                                    title: 'Select address',
                                    field_id: activeModal?.field_id,
                                })
                            }

                            setTimeout(() => {
                                if (activeModal?.active_modal !== 'select') {
                                    if (removeSideEffectSubmit !== true) {
                                        dispatch(
                                            insuranceActions.submit_insuranceAddresses_request()
                                        )
                                    }
                                }

                                if (onSaveForm) {
                                    onSaveForm()
                                }
                            }, 100)
                        },
                        isDisabled:
                            application?.status !== 'DRAFT'
                                ? true
                                : isFormValid() !== true
                                ? true
                                : false,
                        text: 'Save',
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            paddingTop: 80,
                            position: 'relative',
                        }}
                    >
                        <SideSliderWithExit
                            isVisible={activeModal.active_modal === 'select'}
                            direction={'right'}
                            initial="enter"
                            customDuration={0.5}
                            isAbsolute
                        >
                            <GetQuoteSearchSelectAddressForm
                                sectionID={sectionID}
                                selectedAddressId={selectedAddressId}
                                selectAddressId={(
                                    addressid: string | undefined
                                ) => {
                                    addressid &&
                                        selectAddress &&
                                        selectAddress(addressid)

                                    setSelectedAddressId(addressid)
                                }}
                                onAddNew={() => {
                                    if (application?.status === 'DRAFT') {
                                        setSelectedAddressId(undefined)
                                        toggleActiveModal({
                                            active_modal: 'create',
                                            title: 'Add new address',
                                            prevOpenedModal: 'select',
                                            field_id: activeModal?.field_id,
                                        })
                                    }
                                }}
                            />
                        </SideSliderWithExit>

                        <SideSliderWithExit
                            isVisible={activeModal.active_modal !== 'select'}
                            direction={'right'}
                            initial="enter"
                            customDuration={0.5}
                            isAbsolute
                        >
                            <GetQuoteAddressesForm
                                formData={updatedAddress ?? emptyAddress}
                                handleChange={(
                                    id: keyof IInsuranceAddress_API,
                                    value: string
                                ) => {
                                    if (application?.status === 'DRAFT') {
                                        validateForm(id, value)

                                        setUpdatedAddress((prevState) => {
                                            return prevState
                                                ? {
                                                      ...prevState,
                                                      [id]: value,
                                                  }
                                                : undefined
                                        })
                                    }
                                }}
                                isFormValid={isFormValid}
                                error={error}
                            />
                        </SideSliderWithExit>
                    </div>
                </RightSideModalDisplay>
            )}
        </div>
    )
}
