import React from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { device } from '../../templates/displays/devices'
import CarsSharedWithYouCardDesktop from '../../molecules/cards/sharing/carsSharedWithYouCardDesktop'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import WhiteBgNoBorder from '../../atoms/Button/whiteBgNoBorder'
import { sortReceivedSharesByAlphabetical } from '../../../helpers/sort/sortByAlphabetical'
import { sortDirectReceivedSharesByDate } from '../../../helpers/sort/sortByDate'
import { AnimatePresence } from 'framer-motion'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IRootState } from '../../../redux/store'
import { IDirectSharesReceivedObj } from '../../../redux/user/types'
import { IPrivateShareReceivedItemApprovalPayload } from '../../../redux/entities/cars/types'
import { usersActions } from '../../../redux/user/reducer'

interface Props {
    sort_id: string
}

const WrapperDesktopAdjusted = styled.div`
    transition: all 200ms;
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px;
        padding-top: 20px;
        background-color: var(--primary_04);
    }

    @media ${device.ipad} {
        justify-content: center;
    }
`
const SubWrapperDesktopAdjusted = styled.div`
    transition: all 200ms;
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    @media ${device.ipad} {
        justify-content: center;
    }
`

const SubWrapperDesktopAdjustedViewAll = styled.div<{ $noMaxWidth?: boolean }>`
    transition: all 200ms;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    max-width: ${(props) => (props.$noMaxWidth ? 'none' : '1100px;')};
    @media screen and (max-width: 1360px) {
        grid-template-columns: 1fr;
        max-width: 500px;
    }
`

const GridBtnWrapper = styled.div`
    @media screen and (min-width: 1360px) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
        max-width: 1070px;
    }
    @media screen and (min-width: 1600px) {
        max-width: 1132px;
    }
    max-width: 500px;
`

const SectionLine = styled.div`
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    width: 100%;
    box-sizing: border-box;
`

const SmallPadding = styled.div`
    padding-top: 30px;
`

function SharedWithYouCarsManagerDesktop(props: Props) {
    let { sort_id } = props
    const location = useLocation()
    const navigate = useNavigate()

    const { userLoggedIn } = useAppSelector((state: IRootState) => ({
        userLoggedIn: state.user.userLoggedIn,
    }))
    const dispatch = useAppDispatch()

    const { theme } = useThemes()

    const generateSharesDisplayIds = (
        share_ids: string[],
        shares_data: IDirectSharesReceivedObj,
        sort_id: string
    ) => {
        if (sort_id === 'alphabetical') {
            return sortReceivedSharesByAlphabetical(
                share_ids,
                shares_data,
                'cars'
            )
        } else if (sort_id === 'by_oldest') {
            return sortDirectReceivedSharesByDate(
                share_ids,
                shares_data,
                'oldest'
            )
        }
        return sortDirectReceivedSharesByDate(
            share_ids,
            shares_data,
            'most_recent'
        )
    }

    let cars_shared_received_obj =
        userLoggedIn &&
        userLoggedIn.received_direct_shares &&
        userLoggedIn.received_direct_shares.cars &&
        userLoggedIn.received_direct_shares.cars

    let carsReceivedData: IDirectSharesReceivedObj | undefined =
        cars_shared_received_obj &&
        cars_shared_received_obj.shares_received_ids.length > 0
            ? cars_shared_received_obj.shares_received_data
            : undefined

    let carsReceivedIds: string[] | undefined =
        cars_shared_received_obj &&
        cars_shared_received_obj.shares_received_ids.length > 0
            ? cars_shared_received_obj.shares_received_ids
            : undefined

    let pendingCarsReceivedIds: string[] | undefined =
        cars_shared_received_obj &&
        carsReceivedData &&
        cars_shared_received_obj.pending_shares_received_ids.length > 0
            ? sort_id !== ''
                ? generateSharesDisplayIds(
                      [...cars_shared_received_obj.pending_shares_received_ids],
                      carsReceivedData,
                      sort_id
                  )
                : cars_shared_received_obj.pending_shares_received_ids
            : undefined

    let acceptedCarsReceivedIds: string[] | undefined =
        cars_shared_received_obj &&
        carsReceivedData &&
        cars_shared_received_obj.accepted_shares_received_ids.length > 0
            ? sort_id !== ''
                ? generateSharesDisplayIds(
                      [
                          ...cars_shared_received_obj.accepted_shares_received_ids,
                      ],
                      carsReceivedData,
                      sort_id
                  )
                : cars_shared_received_obj.accepted_shares_received_ids
            : undefined

    let ignoredCarsReceivedIds: string[] | undefined =
        cars_shared_received_obj &&
        carsReceivedData &&
        cars_shared_received_obj.ignored_shares_received_ids.length > 0
            ? sort_id !== ''
                ? generateSharesDisplayIds(
                      [...cars_shared_received_obj.ignored_shares_received_ids],
                      carsReceivedData,
                      sort_id
                  )
                : cars_shared_received_obj.ignored_shares_received_ids
            : undefined

    let params = new URLSearchParams(location.search)
    let view_id = params.get('view_id')

    return (
        <React.Fragment>
            <WrapperDesktopAdjusted>
                {(view_id === 'view_all' || !view_id) && (
                    <>
                        {carsReceivedData &&
                            (pendingCarsReceivedIds ||
                                acceptedCarsReceivedIds) && (
                                <SubWrapperDesktopAdjustedViewAll
                                    $noMaxWidth={
                                        (pendingCarsReceivedIds &&
                                            pendingCarsReceivedIds.length ===
                                                1 &&
                                            (!acceptedCarsReceivedIds ||
                                                (acceptedCarsReceivedIds &&
                                                    acceptedCarsReceivedIds.length <
                                                        2))) ||
                                        (acceptedCarsReceivedIds &&
                                            acceptedCarsReceivedIds.length ===
                                                1 &&
                                            (!pendingCarsReceivedIds ||
                                                (pendingCarsReceivedIds &&
                                                    pendingCarsReceivedIds.length <
                                                        2)))
                                            ? true
                                            : false
                                    }
                                >
                                    <AnimatePresence>
                                        {pendingCarsReceivedIds &&
                                            pendingCarsReceivedIds.map(
                                                (
                                                    shareid: string,
                                                    index: number
                                                ) => {
                                                    if (
                                                        carsReceivedData![
                                                            shareid
                                                        ] &&
                                                        index < 2
                                                    ) {
                                                        return (
                                                            <CarsSharedWithYouCardDesktop
                                                                key={`pending_view_all_${shareid}`}
                                                                share_received={
                                                                    carsReceivedData![
                                                                        shareid
                                                                    ]
                                                                }
                                                                hasMarginRight
                                                                shareApprovalAction={(
                                                                    p: IPrivateShareReceivedItemApprovalPayload
                                                                ) =>
                                                                    dispatch(
                                                                        usersActions.updateShareReceivedStatusRequest(
                                                                            {
                                                                                share_id:
                                                                                    p.shareId,
                                                                                status: p.approvalId,
                                                                                entity_type:
                                                                                    'car',
                                                                            }
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                }
                                            )}
                                    </AnimatePresence>

                                    {pendingCarsReceivedIds &&
                                        pendingCarsReceivedIds.length > 2 && (
                                            <GridBtnWrapper>
                                                <WhiteBgNoBorder
                                                    text={`See ${
                                                        pendingCarsReceivedIds.length -
                                                        2
                                                    } more shares pending`}
                                                    onClick={() =>
                                                        navigate(
                                                            `/sharing/with-you?view_id=pending`
                                                        )
                                                    }
                                                />
                                            </GridBtnWrapper>
                                        )}
                                </SubWrapperDesktopAdjustedViewAll>
                            )}
                        <SmallPadding />

                        {acceptedCarsReceivedIds &&
                            acceptedCarsReceivedIds.length > 0 &&
                            pendingCarsReceivedIds &&
                            pendingCarsReceivedIds.length > 0 && (
                                <>
                                    <SmallPadding
                                        style={{
                                            paddingTop: '20px',
                                        }}
                                    />
                                    <SectionLine /> <SmallPadding />
                                </>
                            )}

                        {carsReceivedData && acceptedCarsReceivedIds && (
                            <SubWrapperDesktopAdjustedViewAll
                                $noMaxWidth={
                                    acceptedCarsReceivedIds.length === 1 &&
                                    pendingCarsReceivedIds &&
                                    pendingCarsReceivedIds.length < 2
                                        ? true
                                        : false
                                }
                            >
                                <AnimatePresence>
                                    {acceptedCarsReceivedIds &&
                                        acceptedCarsReceivedIds.map(
                                            (
                                                shareid: string,
                                                index: number
                                            ) => {
                                                if (
                                                    carsReceivedData![shareid]
                                                ) {
                                                    return (
                                                        <CarsSharedWithYouCardDesktop
                                                            key={`accepted_view_all_${shareid}`}
                                                            share_received={
                                                                carsReceivedData![
                                                                    shareid
                                                                ]
                                                            }
                                                            hasMarginRight
                                                            shareApprovalAction={(
                                                                p: IPrivateShareReceivedItemApprovalPayload
                                                            ) =>
                                                                dispatch(
                                                                    usersActions.updateShareReceivedStatusRequest(
                                                                        {
                                                                            share_id:
                                                                                p.shareId,
                                                                            status: p.approvalId,
                                                                            entity_type:
                                                                                'car',
                                                                        }
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    return null
                                                }
                                            }
                                        )}
                                </AnimatePresence>
                            </SubWrapperDesktopAdjustedViewAll>
                        )}

                        {(!carsReceivedIds || carsReceivedIds.length === 0) && (
                            <NoDataRectangle
                                text="Nothing shared with you"
                                bgColor={colours[theme].background_muted}
                            />
                        )}
                    </>
                )}
                {view_id === 'pending' &&
                    (carsReceivedData &&
                    pendingCarsReceivedIds &&
                    pendingCarsReceivedIds.length > 0 ? (
                        <SubWrapperDesktopAdjusted>
                            <AnimatePresence>
                                {pendingCarsReceivedIds.map(
                                    (shareid: string, index: number) => {
                                        if (carsReceivedData![shareid]) {
                                            return (
                                                <CarsSharedWithYouCardDesktop
                                                    key={`pending_${shareid}`}
                                                    share_received={
                                                        carsReceivedData![
                                                            shareid
                                                        ]
                                                    }
                                                    hasMarginRight
                                                    shareApprovalAction={(
                                                        p: IPrivateShareReceivedItemApprovalPayload
                                                    ) =>
                                                        dispatch(
                                                            usersActions.updateShareReceivedStatusRequest(
                                                                {
                                                                    share_id:
                                                                        p.shareId,
                                                                    status: p.approvalId,
                                                                    entity_type:
                                                                        'car',
                                                                }
                                                            )
                                                        )
                                                    }
                                                />
                                            )
                                        } else {
                                            return null
                                        }
                                    }
                                )}
                            </AnimatePresence>
                        </SubWrapperDesktopAdjusted>
                    ) : (
                        <NoDataRectangle
                            text="You don't have pending garage shares"
                            bgColor={colours[theme].background_muted}
                        />
                    ))}
                {view_id === 'accepted' &&
                    (carsReceivedData &&
                    acceptedCarsReceivedIds &&
                    acceptedCarsReceivedIds.length > 0 ? (
                        <SubWrapperDesktopAdjusted>
                            <AnimatePresence>
                                {acceptedCarsReceivedIds.map(
                                    (shareid: string, index: number) => {
                                        if (carsReceivedData![shareid]) {
                                            return (
                                                <CarsSharedWithYouCardDesktop
                                                    key={`accepted_${shareid}`}
                                                    share_received={
                                                        carsReceivedData![
                                                            shareid
                                                        ]
                                                    }
                                                    hasMarginRight
                                                    shareApprovalAction={(
                                                        p: IPrivateShareReceivedItemApprovalPayload
                                                    ) =>
                                                        dispatch(
                                                            usersActions.updateShareReceivedStatusRequest(
                                                                {
                                                                    share_id:
                                                                        p.shareId,
                                                                    status: p.approvalId,
                                                                    entity_type:
                                                                        'car',
                                                                }
                                                            )
                                                        )
                                                    }
                                                />
                                            )
                                        } else {
                                            return null
                                        }
                                    }
                                )}
                            </AnimatePresence>
                        </SubWrapperDesktopAdjusted>
                    ) : (
                        <NoDataRectangle
                            text="You don't have accepted garage shares"
                            bgColor={colours[theme].background_muted}
                        />
                    ))}
                {view_id === 'ignored' &&
                    (carsReceivedData &&
                    ignoredCarsReceivedIds &&
                    ignoredCarsReceivedIds.length > 0 ? (
                        <SubWrapperDesktopAdjusted>
                            <AnimatePresence>
                                {ignoredCarsReceivedIds.map(
                                    (shareid: string, index: number) => {
                                        if (carsReceivedData![shareid]) {
                                            return (
                                                <CarsSharedWithYouCardDesktop
                                                    key={`ignored_${shareid}`}
                                                    share_received={
                                                        carsReceivedData![
                                                            shareid
                                                        ]
                                                    }
                                                    hasMarginRight
                                                    shareApprovalAction={(
                                                        p: IPrivateShareReceivedItemApprovalPayload
                                                    ) =>
                                                        dispatch(
                                                            usersActions.updateShareReceivedStatusRequest(
                                                                {
                                                                    share_id:
                                                                        p.shareId,
                                                                    status: p.approvalId,
                                                                    entity_type:
                                                                        'car',
                                                                }
                                                            )
                                                        )
                                                    }
                                                />
                                            )
                                        } else {
                                            return null
                                        }
                                    }
                                )}
                            </AnimatePresence>
                        </SubWrapperDesktopAdjusted>
                    ) : (
                        <NoDataRectangle
                            text="You don't have ignored garage shares"
                            bgColor={colours[theme].background_muted}
                        />
                    ))}
            </WrapperDesktopAdjusted>
        </React.Fragment>
    )
}

export default SharedWithYouCarsManagerDesktop
