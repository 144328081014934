import { call, fork, put, select, take } from 'redux-saga/effects'
import * as requestActions from '../actions/loadingActions'
import * as successActions from '../actions/actions'
import { sortSharesByAlphabetical } from '../../../../helpers/sort/sortByAlphabetical'
import {
    sortSharesByCreatedAt,
    sortSharesByUpdatedAt,
} from '../../../../helpers/sort/sortByDate'
import { IRootState } from '../../../store'
import {
    IDirectShareOwnedItemsData,
    ISharingPermissionIDS,
} from '../../cars/types'
import { IGarage } from '../types'
import { garagesActions } from '../reducer'

let getGarageData = (state: IRootState) => state.entities.garagesData.garages

function* changeSortGarageSharesDisplay(
    payload: requestActions.ISortGarageSharesDisplay_change_request
): any {
    let garageData: { [key: string]: IGarage } = yield select(getGarageData)

    let garage = garageData && garageData[payload.garageid]
    let currentGarageShareItemsDisplay: string[] | undefined =
        garage &&
        garage.private_shares_owned &&
        garage.private_shares_owned.active_display &&
        garage.private_shares_owned.active_display.item_ids_display
            ? [...garage.private_shares_owned.active_display.item_ids_display]
            : garage.private_shares_owned?.entity_owned_direct_shares_ids
            ? [...garage.private_shares_owned?.entity_owned_direct_shares_ids]
            : undefined

    let items_new: string[] = currentGarageShareItemsDisplay
        ? currentGarageShareItemsDisplay
        : []

    if (
        payload.sortID === 'alphabetical' &&
        currentGarageShareItemsDisplay &&
        garage.private_shares_owned
    ) {
        items_new = sortSharesByAlphabetical(
            currentGarageShareItemsDisplay,
            garage.private_shares_owned.entity_owned_direct_shares_data
        )
    }

    if (
        payload.sortID === 'created_at' &&
        currentGarageShareItemsDisplay &&
        garage.private_shares_owned
    ) {
        items_new = sortSharesByCreatedAt(
            currentGarageShareItemsDisplay,
            garage.private_shares_owned.entity_owned_direct_shares_data
        )
    }

    if (
        payload.sortID === 'updated_at' &&
        currentGarageShareItemsDisplay &&
        garage.private_shares_owned
    ) {
        items_new = sortSharesByUpdatedAt(
            currentGarageShareItemsDisplay,
            garage.private_shares_owned.entity_owned_direct_shares_data
        )
    }

    let p: successActions.ISortGarageSharesDisplay_change_success = {
        garageid: payload.garageid,
        sortID: payload.sortID,
        item_ids_display: items_new,
    }
    yield put(garagesActions.sortGarageSharesDisplay_change_success(p))
}

function* watcherChangeSortGarageShareDisplay() {
    while (true) {
        const { payload } = yield take(
            garagesActions.sortGarageSharesDisplay_change_request
        )

        yield call(changeSortGarageSharesDisplay, payload)
    }
}

function* changeFilterGarageSharesDisplay(
    payload: requestActions.IFilterGarageSharesDisplay_change_request
): any {
    let garagesData: { [key: string]: IGarage } = yield select(getGarageData)

    let all_ids: string[] | undefined =
        garagesData[payload.garageid].private_shares_owned &&
        garagesData[payload.garageid].private_shares_owned
            ?.entity_owned_direct_shares_ids
            ? garagesData[payload.garageid].private_shares_owned
                  ?.entity_owned_direct_shares_ids
            : []

    // TODO do thing to sort items with currentGarageShareItemsDisplay. For now:
    const current_items_ids: string[] = all_ids ? all_ids : []

    let new_items_ids: string[] = []

    const data: IDirectShareOwnedItemsData | undefined =
        garagesData[payload.garageid].private_shares_owned
            ?.entity_owned_direct_shares_data

    if (data && payload.filterIDs.length > 0) {
        let boolArr: boolean[] = current_items_ids.map((id: string) =>
            payload.filterIDs.some((pid: ISharingPermissionIDS) => {
                return data[id].active_permission_ids.includes(pid)
            })
        )

        if (boolArr.includes(true)) {
            boolArr.forEach((boo: boolean, index: number) => {
                if (boo === true) {
                    new_items_ids = [...new_items_ids, current_items_ids[index]]
                }
            })
        }
    } else {
        new_items_ids = all_ids ? all_ids : []
    }

    let p: successActions.IFilterGarageSharesDisplay_change_success = {
        garageid: payload.garageid,
        filterIDs: payload.filterIDs,
        item_ids_display: new_items_ids,
    }
    yield put(garagesActions.filterGarageSharesDisplay_change_success(p))
}

function* watcherChangeFilterGarageShareDisplay() {
    while (true) {
        const { payload } = yield take(
            garagesActions.filterGarageSharesDisplay_change_request
        )

        yield call(changeFilterGarageSharesDisplay, payload)
    }
}

const handle_Garage_shares_display_change: any[] = [
    fork(watcherChangeSortGarageShareDisplay),
    fork(watcherChangeFilterGarageShareDisplay),
]

export default handle_Garage_shares_display_change
