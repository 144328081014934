import * as React from 'react'
import styled from 'styled-components'
import NoDataRectangle from '../noData/noDataRectangle'
import { useState } from 'react'
import AddBox from '../Button/addBox'
import placeholderImg from '../../atoms/skeleton/description/skeleton.jpg'
import placeholderImgDark from '../../atoms/skeleton/description/skeleton_dark.png'
import useThemes from '../../../providers/theme/hooks'

type IProps = {
    onClickAdd: any
    height?: string
    readOnlyMode?: boolean
    noDataText?: string
    bgColor?: string
    customPlaceholderImg?: string
}

const GoToBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
    color: var(--primary, #5ec3ca);
`

const AddBoxWithPlaceholderAndPlusDesktop: React.FunctionComponent<IProps> = (
    props: IProps
) => {
    const [showBoxInfo, setShowBoxInfo] = useState(false)

    const { theme } = useThemes()

    return !props.readOnlyMode ? (
        <GoToBox
            style={{
                backgroundColor:
                    props.bgColor ?? 'var(--off-bg-color, #fafafa)',
                height: props.height ?? '100%',
                width: '100%',
                boxSizing: 'border-box',
                minHeight: '120px',
                padding:
                    theme === 'dark' && !props.customPlaceholderImg ? 24 : 0,
            }}
            onMouseEnter={() => setShowBoxInfo(true)}
            onMouseLeave={() => setShowBoxInfo(false)}
            onClick={props.onClickAdd}
        >
            {showBoxInfo === false ? (
                <img
                    src={
                        props.customPlaceholderImg
                            ? props.customPlaceholderImg
                            : theme === 'dark'
                            ? placeholderImgDark
                            : placeholderImg
                    }
                    alt="placeholder"
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        height: '100%',
                    }}
                />
            ) : (
                <AddBox />
            )}
        </GoToBox>
    ) : (
        <NoDataRectangle
            text={props.noDataText ?? 'No data'}
            height={props.height ?? '400px'}
        />
    )
}

export default AddBoxWithPlaceholderAndPlusDesktop
