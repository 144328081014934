import * as React from 'react'
import posthog from 'posthog-js'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { insuranceActions } from '../../../redux/insuranceQuoteApplication/reducer'
import { IGetAllUserInsuranceApplicationsProps } from '../../../redux/insuranceQuoteApplication/sagas/application/getAll'
import DesktopDisplayOnly from '../displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../displays/ipadAndMobileDisplay'
import MainMenuDesktop from '../../atoms/menu/mainmenu/mainMenuDesktop'
import MainMenuMobile from '../../atoms/menu/mainmenu/mainMenuMobile'
import { IUser } from '../../../redux/user/types'

interface IMainMenuProps {
    user: IUser | null
}

const MainMenu: React.FC<IMainMenuProps> = ({ user }) => {
    const dispatch = useAppDispatch()

    const isDefaultNavigationRemoved = useAppSelector(
        (state): boolean => state.menus.isDefaultNavigationRemoved
    )

    const insuranceQuoteApplicationState = useAppSelector(
        (state) => state.insuranceQuoteApplication
    )

    React.useEffect(() => {
        posthog.capture('$pageview')
    }, [window.location.pathname])

    React.useEffect(() => {
        if (insuranceQuoteApplicationState.submitted_data?.id !== undefined) {
            if (window.location.pathname.match(/insurance/g) === null) {
                dispatch(
                    insuranceActions.reset_insurance_application_to_none_active()
                )
            }
        }
    }, [
        window.location.pathname,
        insuranceQuoteApplicationState.submitted_data?.id,
    ])

    const determineIfMainMenu = (): boolean => {
        if (isDefaultNavigationRemoved === true) {
            return false
        }

        const isGaragePage: boolean =
            window.location.pathname.match(/garage/g) !== null
        const isGaragePageV2 =
            window.location.pathname === '/' && user && user.id ? true : false
        const isTheRightcarPage: boolean =
            window.location.pathname.match(/car/g) !== null ||
            window.location.pathname.match(/confirm/g) !== null
        const isAccountDeletionConfirmed: boolean =
            window.location.pathname === `/account-deletion-confirmed`
        const isOtherMainPage: boolean =
            window.location.pathname.match(/upcoming/g) !== null ||
            window.location.pathname.match(/sharing/g) !== null ||
            window.location.pathname.match(/showroom/g) !== null ||
            window.location.pathname === '/apply' ||
            window.location.pathname.match(/apex/g) !== null ||
            window.location.pathname === '/history-files' ||
            window.location.pathname.match(/tasks/g) !== null ||
            window.location.pathname.match(/account/g) !== null
        const isTheRightCarArchivedPage: boolean =
            window.location.pathname.match(/car/g) !== null &&
            window.location.pathname.match(/archives/g) !== null
        const isInsuranceOverview: boolean =
            window.location.pathname === '/insurance/overview'

        return (
            !isAccountDeletionConfirmed &&
            (isGaragePage ||
                isGaragePageV2 ||
                isTheRightcarPage ||
                isTheRightCarArchivedPage ||
                isOtherMainPage ||
                isInsuranceOverview)
        )
    }

    const handleGetUserInsuranceApplications = (
        p: IGetAllUserInsuranceApplicationsProps
    ) => {
        dispatch(insuranceActions.get_user_insurance_applications_request(p))
    }

    return (
        <React.Fragment>
            {determineIfMainMenu() && (
                <>
                    <DesktopDisplayOnly>
                        <MainMenuDesktop
                            activepage={window.location.pathname}
                            // get_user_insurance_applications_request={
                            //     handleGetUserInsuranceApplications
                            // }
                        />
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <MainMenuMobile
                            activepage={window.location.pathname}
                            user={user}
                            get_user_insurance_applications_request={
                                handleGetUserInsuranceApplications
                            }
                        />
                    </IpadAndMobileDisplay>
                </>
            )}
        </React.Fragment>
    )
}

export default MainMenu
