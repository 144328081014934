import AdminIcon from './admin'
import EventsIcon from './events'
import HistoryIcon from './history'
import MaintenanceIcon from './maintenance'
import OtherIcon from './other'
import RestorationIcon from './restoration'
import ServiceIcon from './service'
import TripIcon from './trip'
import InsuranceIcon from './insurance'
import { ITimelineEntryTypes } from '../../../../../redux/timeline/types'

const CategoryIcons = {
    ServiceIcon,
    MaintenanceIcon,
    RestorationIcon,
    TripIcon,
    AdminIcon,
    EventsIcon,
    OtherIcon,
    HistoryIcon,
    InsuranceIcon,
}

export const renderCategoryIcon = (
    categoryID: ITimelineEntryTypes | undefined,
    size?: string
) => {
    switch (categoryID) {
        case 'service':
            return <ServiceIcon size={size} />
        case 'maintenance':
            return <MaintenanceIcon size={size} />
        case 'admin':
            return <AdminIcon size={size} />
        case 'insurance':
            return <InsuranceIcon size={size} />
        case 'history':
            return <HistoryIcon size={size} />
        case 'restoration':
            return <RestorationIcon size={size} />
        case 'events':
            return <EventsIcon size={size} />
        case 'other':
            return <OtherIcon size={size} />
        default:
            return <OtherIcon size={size} />
    }
}

export default CategoryIcons
