import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import { IDropdownItem, IList } from 'entityModels'
import '../../../atoms/formField/antd.css'
import SelectSearchBarCarInfoDesktop from '../technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import { IUserPreferencesDataLevel4Obj } from '../../../../redux/user/preferences'
import { IUSerGeneralInfoUpdatePayload } from '../../../../redux/user/types'

const RowWrapperUnit = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: Lato;
`
const UnitTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 17px;
    width: 160px;
`

const UnitLine = styled.div`
    height: 30px;
    width: 1px;
    background-color: var(--border-muted, #e5e5e5);
    margin-right: 50px;
`

interface Values {
    form_unique_value: string
}

interface OtherProps {
    title: any
    item: IList | null
    data?: IUserPreferencesDataLevel4Obj | null
    ids_list?: string[] | null
    optionList?: IDropdownItem[] | string[]
    field_id: string | null
    submitFunc: any
    userid: string
    onlyShowMatch?: boolean
    isKeyValue?: boolean
    placeholder?: string
    helperText?: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        title,
        setFieldValue,
        values,
        handleSubmit,
        item,
        ids_list,
        onlyShowMatch,
        data,
        optionList,
        isKeyValue,
        placeholder,
        helperText,
    } = props

    let convertedOptionsList: IDropdownItem[] = []

    if (data && ids_list) {
        convertedOptionsList = ids_list.map((id: string, index: any) => {
            return { uid: data[id].id, name: data[id].name }
        })
    }

    return (
        <RowWrapperUnit>
            <UnitTitle>{title}</UnitTitle>

            <UnitLine />

            {item && (
                <SelectSearchBarCarInfoDesktop
                    handleSelectChange={(name: string) => {
                        setFieldValue('form_unique_value', name)
                        handleSubmit()
                    }}
                    item={item}
                    dropdownTargetId="form_unique_value"
                    items={optionList ? optionList : convertedOptionsList} //IDropdownItem[]
                    formCurrentValue={values.form_unique_value}
                    isDisabled={
                        !values.form_unique_value &&
                        (!item || item.answer) === null
                            ? true
                            : false
                    }
                    onlyShowMatch={onlyShowMatch}
                    height="24px"
                    width="300px"
                    placeholder={placeholder}
                    helperText={helperText}
                    sendId
                    isKeyValue={isKeyValue}
                />
            )}
        </RowWrapperUnit>
    )
}

const PreferencesInlineSearchSelectDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        form_unique_value: props.item ? props.item.id : '',
    }),
    // validationSchema: {},
    enableReinitialize: true,

    handleSubmit: (values, { props, setSubmitting }) => {
        if (props.field_id) {
            let dataToSend: IUSerGeneralInfoUpdatePayload = {
                id: props.userid,
                data: {
                    user_preferences: {
                        [props.field_id]: values.form_unique_value,
                    },
                },
            }

            if (values.form_unique_value !== props.item?.answer) {
                props.submitFunc(dataToSend)
            }
        }
        setSubmitting(false)
    },
})(InnerForm)

export default PreferencesInlineSearchSelectDesktop
