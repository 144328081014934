import * as React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type StyledProps = {
    $theme: ITheme
    disabled?: boolean
    normal_height?: string
    desktop_height?: string
    wide_screen_height?: string
    $custom_bg?: string
}

const MainButton = styled(Button)<StyledProps>`
    font-size: 15px;
    font-family: Lato !important;
    border: solid 1px black;
    border-radius: 4px;
    height: ${(props) => (props.normal_height ? props.normal_height : '56px')};
    width: 100% !important;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.disabled
            ? colours[props.$theme].background_disabled_neutral
            : props.$custom_bg ?? colours[props.$theme].primary} !important;
    color: ${(props) =>
        `${
            props.disabled
                ? colours[props.$theme].text_muted
                : colours[props.$theme].background_default
        } !important`};

    border: ${(props) =>
        props.disabled === true
            ? `solid 1px ${colours[props.$theme].border_muted} !important`
            : `solid 1px ${
                  props.$custom_bg ?? colours[props.$theme].primary
              } !important`};

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.$theme].shadow_strongest_03}`};
    transition: all 200ms;

    :focus {
        color: ${(props) =>
            `${colours[props.$theme].border_muted_neutral}  !important`};
        border: ${(props) =>
            `solid 1px ${
                colours[props.$theme].border_muted_neutral
            } !important`};
    }
    :hover {
        transition: all 200ms;
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.$theme].shadow_strongest_09}`};
        background-color: ${(props) =>
            props.$custom_bg ?? colours[props.$theme].primary_strong};
        color: ${(props) =>
            `${colours[props.$theme].background_default} !important`};
        border: none !important;
    }

    @media ${device.desktop} {
        height: ${(props) =>
            props.desktop_height ? props.desktop_height : '45px'};
    }

    @media ${device.large_desktop} {
        height: ${(props) =>
            props.wide_screen_height ? props.wide_screen_height : '48px'};
    }
`

type Props = {
    disabled?: boolean
    disableWhileLoading?: boolean
    normal_height?: string
    wide_screen_height?: string
    desktop_height?: string
    type?: any
    onClick?: any
    dataCyId?: string | undefined
    custom_bg?: string
    children: React.ReactNode
}

const LandingPageMainButton: React.FunctionComponent<Props> = (props) => {
    let {
        onClick,
        type,
        disabled,
        disableWhileLoading,
        normal_height,
        desktop_height,
        wide_screen_height,
        dataCyId,
        custom_bg,
        children,
    } = props
    const { theme } = useThemes()
    return (
        <motion.div
            whileTap={{ scale: 1.05 }}
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'flex-end',
                scale: 1,
            }}
        >
            <MainButton
                $theme={theme}
                htmlType={type ? type : 'button'}
                onClick={disabled || disableWhileLoading ? () => {} : onClick}
                disabled={disabled}
                data-attr={dataCyId ? dataCyId : undefined}
                type="default"
                normal_height={normal_height}
                desktop_height={desktop_height}
                wide_screen_height={wide_screen_height}
                $custom_bg={custom_bg}
            >
                {children}
            </MainButton>
        </motion.div>
    )
}

export default LandingPageMainButton
