import styled from 'styled-components'
import React, { useState } from 'react'
import { device } from '../../../templates/displays/devices'
import Tour from 'reactour'
import './styles.css'
import { useParams, useNavigate } from 'react-router-dom'
import TutorialWrapperMobile from '../tutorialWrappers/TutorialWrapperMobile'

const TourStyled = styled((props: any) => <Tour {...props} />)`
    display: none;
    @media ${device.mobile} {
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: ${(props) => (props.currentStep === 1 ? '70vw' : 'auto')};
    }

    .reactour__badge {
        padding: 0 !important;
        width: 32px !important;
        height: 32px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 0 !important;
    }

    /* Override the generated class styles */
    [data-tour-elem='badge'] {
        line-height: 2 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
`

const Title = styled.h1`
    color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 16px;
    justify-content: center;
    text-align: center;

    @media ${device.mobile} {
    }
`

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 14px;
    line-height: 1.9;

    @media ${device.mobile} {
    }
`

const Padding = styled.div`
    padding-top: 20px;
`
const TextPadding = styled.div`
    padding-top: 8px;
`

export type ReactourStepPosition =
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | [number, number]

let bottomPositionStrongType: ReactourStepPosition = 'bottom'

const tutorialConfigs_Car = (close: any) => [
    {
        selector: '[data-tut="tech-info-tuto-mobile"]',
        content: () => (
            <TutorialWrapperMobile
                skip={close}
                next={close}
                nextBtnText="close"
                translateNextBtn={true}
            >
                <Title>Technical specs</Title>

                <Padding />

                <Text>
                    Your car's technical details are stored on this page. It's
                    important we know you latest registration and MOT dates so
                    we can ping you for upcoming appointments.
                </Text>

                <TextPadding />

                <Text>
                    You can update each indvidiual item at any time, just tap
                    the field.
                </Text>
                <TextPadding />

                <Text onClick={() => close()}>
                    <div style={{ color: '#5EC3CA' }}>Got it!</div>
                </Text>
            </TutorialWrapperMobile>
        ),
        position: bottomPositionStrongType,
    },
]

const TutorialConfigsTechInfoMobile: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(0)
    const [isTourOpen, setIsTourOpen] = useState(true)
    const { carid } = useParams<{ carid: string }>()
    const navigate = useNavigate()

    const setCurrentStepCustom = (step: number) => {
        setCurrentStep(step)
    }

    const setIsTourOpenCustom = () => {
        setIsTourOpen(false)
        navigate(`/car/${carid}/technical-information`)
    }

    return (
        <>
            {window.innerWidth < 480 && (
                <>
                    <TourStyled
                        steps={tutorialConfigs_Car(setIsTourOpenCustom)}
                        isOpen={isTourOpen}
                        onRequestClose={() => undefined}
                        rounded={5}
                        accentColor="#5ec3ca"
                        getCurrentStep={(curr: number) => {
                            window.scrollTo(0, 0)
                            return setCurrentStepCustom(curr + 1)
                        }}
                        currentStep={currentStep}
                        disableInteraction={true}
                        showCloseButton={false}
                        showNavigation={false}
                    />
                </>
            )}
        </>
    )
}

export default TutorialConfigsTechInfoMobile
