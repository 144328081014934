import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { api } from '../../../services'
import {
    convertToCarState,
    convertToGalleryImagesState,
    convertToHighlightedFactsState,
} from '../../../conversions/entities/conversionFromAPI'
import { IRootState } from '../../../store'
import { IHighlightedFactsObject } from 'entityModels'
import { IGaragePayload } from '../../../services/typedefinitions/apiPayloads'
import { carActions } from '../../cars/reducer'
import { ICarsObject, ICustomErrorData, IReduxError } from '../../cars/types'
import { IGalleryImagesObject } from '../../galleries/types'
import {
    IGetSortedGarageApiPayload,
    IGarageObject,
    IGarage,
    IGarageState,
    ISetGarageActiveSort,
} from '../types'
import { garagesActions } from '../reducer'
import { galleriesActions } from '../../galleries/reducer'
import { highlightedFactsActions } from '../../highlighted_facts/reducer'

export const getUserLoggedInFromState = (state: IRootState) =>
    state.user.userLoggedIn

const getCarsDataState = (state: IRootState) => state.entities.carsData.cars

const getGalleryState = (state: IRootState) =>
    state.entities.galleriesData.images

const getHighFactsState = (state: IRootState) =>
    state.entities.highlightedFactsData

const getGaragesState = (state: IRootState) =>
    state.entities.garagesData.garages

const getGarageReducerData = (state: IRootState) => state.entities.garagesData

export function* getSortedGarageByGarageId(
    api_payload: IGetSortedGarageApiPayload
) {
    try {
        const garagePayload: IGaragePayload = yield call(
            api.entities.getData.getSortedGarageData_api,
            {
                garageid: api_payload.garageid,
                q_params: api_payload.q_params
                    ? [...api_payload.q_params]
                    : undefined,
            }
        )

        // this will always make sure that after at least one fetch
        // total_cars_number_found is populated and not undefined anymore
        // currently if BE doesn't send count it means garage doesn't have any cars
        // so we log 0

        let count = garagePayload.count ?? 0
        yield put(garagesActions.setTotalCarsNumberFound(count))

        let garageid = api_payload.garageid

        const garages_data_in_state: IGarageObject = yield select(
            getGaragesState
        )
        const garage_in_state: IGarage = garages_data_in_state[garageid]

        if (garagePayload.cars && garagePayload.cars.length > 0) {
            // cdeclare wveythiong to convert

            const carsDataState: ICarsObject = yield select(getCarsDataState)

            const galleryDataState: IGalleryImagesObject | null = yield select(
                getGalleryState
            )

            const highFactsState: IHighlightedFactsObject | null = yield select(
                getHighFactsState
            )

            let carObjects: ICarsObject = carsDataState
                ? { ...carsDataState }
                : {}

            let galleryImagesObject: IGalleryImagesObject = galleryDataState
                ? {
                      ...galleryDataState,
                  }
                : {}

            let highlightedFactsObject: IHighlightedFactsObject = highFactsState
                ? { ...highFactsState }
                : {}

            let car_ids: string[] =
                garage_in_state && garage_in_state.sorted_car_ids
                    ? [...garage_in_state.sorted_car_ids]
                    : []

            for (const item of garagePayload.cars) {
                // add id to car ids list

                if (!car_ids.includes(item.uid)) {
                    car_ids.push(item.uid)
                }

                if (!carsDataState[item.uid]) {
                    // convert to car state payload
                    let car: ICarsObject = convertToCarState(item)

                    car[item.uid].has_limited_garage_info = true

                    let gallery_images_object: IGalleryImagesObject =
                        convertToGalleryImagesState(item)

                    let highlighted_facts_object: IHighlightedFactsObject =
                        convertToHighlightedFactsState(item)

                    Object.assign(galleryImagesObject, gallery_images_object)

                    Object.assign(carObjects, car)

                    Object.assign(
                        highlightedFactsObject,
                        highlighted_facts_object
                    )
                }
            }

            const cars_results: ICarsObject = carObjects

            yield put(carActions.getCarDataSuccess(cars_results))

            yield put(
                galleriesActions.setGalleryImagesSuccess(galleryImagesObject)
            )
            yield put(
                highlightedFactsActions.setHighlightedFactsSuccess(
                    highlightedFactsObject
                )
            )

            const garageToAdd: IGarage = {
                ...garage_in_state,
                uid: garageid,
                sorted_car_ids: car_ids,
            }

            yield put(garagesActions.setGarageDataSuccess(garageToAdd))
            yield put(garagesActions.getGarageByUserIdSuccess(garageToAdd))
        } else {
            // this will always make sure that after at least one fetch
            // sorted_car_ids is populated and not undefined anymore
            let car_ids: string[] =
                garage_in_state && garage_in_state.sorted_car_ids
                    ? garage_in_state.sorted_car_ids
                    : []

            const garageToAdd: IGarage = {
                ...garage_in_state,
                uid: garageid,
                sorted_car_ids: [...car_ids],
            }
            yield put(garagesActions.setGarageDataSuccess(garageToAdd))
            yield put(garagesActions.getGarageByUserIdSuccess(garageToAdd))
        }

        // moved page incrementation for next api call after current fetch is done
        const garageReducer: IGarageState = yield select(getGarageReducerData)
        let current_garage_page_number = garageReducer.current_page_number
        yield put(
            garagesActions.setCurrentGaragePageNumber(
                current_garage_page_number + 1
            )
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getSortedGarageByGarageId,
                payload: api_payload,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Please refresh the page and try again, or check your internet connection.`,
                custom_user_action_text: 'Refresh',
                custom_redirect_path: '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(garagesActions.getGarageByUserIdError(customError))
            return
        }
    }
}

export function* SortGarageCarsSaga(payload: ISetGarageActiveSort): any {
    try {
        const garages_reducer: IGarageState = yield select(getGarageReducerData)
        yield call(getSortedGarageByGarageId, {
            garageid: payload.garageid,
            q_params: [
                { sorting: payload.activeSort },
                { limit: garages_reducer.cars_per_page },
                {
                    offset:
                        garages_reducer.current_page_number *
                        garages_reducer.cars_per_page,
                },
            ],
        })

        yield put(
            garagesActions.setGarageActiveSortingSuccess(payload.activeSort)
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: SortGarageCarsSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `We couldn't sort your cars.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(garagesActions.setGarageActiveSortingError(customError))
        }
    }
}

function* watcherSetGarageActiveSort() {
    while (true) {
        const { payload } = yield take(
            garagesActions.setGarageActiveSortingRequest
        )

        yield call(SortGarageCarsSaga, payload)
    }
}

const set_garage_active_sort: any[] = [fork(watcherSetGarageActiveSort)]

export default set_garage_active_sort
