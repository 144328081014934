import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import PrivateShareSearchFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareSearchFormDesktop'
import PrivateShareFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareFormManagerDesktop'
import EditModeTopHeaderDesktop from '../../atoms/header/editModeTopHeader/editModeTopHeaderDesktop'
import PrivateShareFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareFormManagerMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import SwitchTabMenuDesktop from '../../atoms/Button/switchTabMenuDesktop'
import ShareRowDesktop from '../../atoms/shareRow/shareRowDesktop'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import AddShareByEmailFormDesktop from '../../organisms/editForms/addShareByEmailForm/addShareByEmailFormDesktop'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import SwitchTabMenuMobile from '../../atoms/Button/switchTabMenuMobile'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import AddShareByEmailFormMobile from '../../organisms/editForms/addShareByEmailForm/addShareByEmailFormMobile'
import DropdownExpanderHover from '../../atoms/menu/dropdownExpander/dropdownExpanderHover'
import PrivateSharesSortingMobile from '../../atoms/menu/bottomSheetMenu/privateSharesSortingMobile'
import GreyIconButton from '../../atoms/Button/GreyIconButton'
import ShareRowMobile from '../../atoms/shareRow/shareRowMobile'
import Faded from '../../templates/animated/faded'
import EditModeFooterDesktop from '../../atoms/footer/editModeFooterDesktop'
import DeletionConfirmationDesktop from '../../molecules/deletionConfirmationDesktop/deletionConfirmationDesktop'
import NoIconDataRectangle from '../../atoms/noData/noIconDataRectangle'
import SideSlider from '../../templates/animated/sideSlider'
import PrivateShareSearchManagerMobileV2 from '../../organisms/shareForms/privateShare/privateShareSearchManagerMobileV2'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { device } from '../../templates/displays/devices'
import { IDirectShareDeletionConfirmation } from '../../../redux/editForms/types'
import {
    IDirectShareOwnedItem,
    IEntityPrivateOwnedSharesState,
    ISharingPermissionIDS,
} from '../../../redux/entities/cars/types'
import { usersActions } from '../../../redux/user/reducer'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { searchUsersActions } from '../../../redux/searchUsers/reducer'

const GarageShareEdit: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const params = new URLSearchParams(location.search)
    const shareid = params.get('shareid')

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const garage_id = useAppSelector(
        (state) => state.user.userLoggedIn?.owns_garage?.uid
    )
    // const isGarageLoading = useAppSelector(
    //     (state) => state.entities.garagesData.loading
    // )
    const garageSharesLoading = useAppSelector(
        (state) => state.entities.garagesData.sharesLoading
    )
    const garageData = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    // const isUserLoading = useAppSelector((state) => state.user.loading)
    const addShareRecipientByEmailForm = useAppSelector(
        (state) =>
            state.editForms.formsData?.addShareRecipientByEmailForm?.isOpen
    )
    const searchResults = useAppSelector((state) => state.searchUsers.list)
    const searchResultsLoading = useAppSelector(
        (state) => state.searchUsers.loading
    )
    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )
    const directShareDeletionConfirmation = useAppSelector(
        (state) => state.editForms.directShareDeletionConfirmation
    )

    const [share_being_edited, setShareBeingEdited] =
        useState<IDirectShareOwnedItem>({
            title: '',
            entity_id: '',
            active_permission_ids: [],
            id: 'share_being_edited',
            type: 'garage',
            created_at: '',
            updated_at: '',
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            owner: {
                uid: 'useruid',
                display_name: 'noname',
                location: 'none',
            },
            total_recipients: 0,
        })
    // const [isMobileEditOpen, setIsMobileEditOpen] = useState(false)
    const [activeTabId, setActiveTabId] = useState('recipients')
    const [isSortBottomSheetOpen, setIsSortBottomSheetOpen] = useState(false)

    useEffect(() => {
        // const handleResize = () => {
        //     setIsMobileEditOpen(window.innerWidth < 881)
        // }

        // window.addEventListener('resize', handleResize, { passive: true })
        // handleResize()
        if (shareid) {
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/sharing/with-others/garage/edit-share?shareid=${shareid}`
                )
            )
        }

        const activeTabId_id = params.get('activeTabId_id')
        if (activeTabId_id === 'settings') {
            setActiveTabId('settings')
        }

        return () => {
            // window.removeEventListener('resize', handleResize)
            dispatch(
                editFormsActions.toggleAddShareRecipientByEmail({
                    isOpen: false,
                })
            )
        }
    }, [])

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else if (garage_id) {
            if (!garageData[garage_id]) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }
            if (shareid && garage_id) {
                dispatch(
                    garagesActions.getSingleGarageDirectShareRequest({
                        entity_id: garage_id,
                        share_id: shareid,
                    })
                )
            }
        }
    }, [userLoggedIn, garage_id, shareid])

    const garage_shares: IEntityPrivateOwnedSharesState | undefined =
        garage_id &&
        garageData &&
        garageData[garage_id] &&
        garageData[garage_id].private_shares_owned
            ? garageData[garage_id].private_shares_owned
            : undefined

    const current_share: IDirectShareOwnedItem | undefined =
        garage_shares &&
        garage_shares.entity_owned_direct_shares_data &&
        shareid &&
        garage_shares.entity_owned_direct_shares_data[shareid]
            ? garage_shares.entity_owned_direct_shares_data[shareid]
            : undefined

    const setupLocalStateData = () => {
        if (current_share) {
            setShareBeingEdited({
                ...current_share,
                active_display: {
                    item_ids_display: current_share.active_display
                        ?.item_ids_display
                        ? current_share.active_display?.item_ids_display
                        : current_share.all_share_recipients_ids_list,
                    activeSort: current_share.active_display?.activeSort
                        ? current_share.active_display?.activeSort
                        : 'added_at',
                },
            })
        }
    }

    useEffect(() => {
        setupLocalStateData()
    }, [current_share])

    const handleChangePermissions = (permissionID: ISharingPermissionIDS) => {
        setShareBeingEdited((prevState) => {
            const permissions_arr = prevState.active_permission_ids

            if (permissions_arr.includes(permissionID)) {
                return {
                    ...prevState,
                    active_permission_ids: permissions_arr.filter(
                        (id) => permissionID !== id
                    ),
                }
            } else {
                return {
                    ...prevState,
                    active_permission_ids: [...permissions_arr, permissionID],
                }
            }
        })
    }

    const handleTitleChange = (id: string, title: string) => {
        setShareBeingEdited((prevState) => ({
            ...prevState,
            title: title,
        }))
    }

    const handleSubmitToBE = () => {
        const payload = {
            garage_id: garage_id || '',
            share_id: shareid || '',
            policy_statements: share_being_edited.active_permission_ids,
            title: share_being_edited.title,
        }

        if (garage_id) {
            dispatch(garagesActions.updateGarageDirectShareRequest(payload))
        }

        return payload
    }

    const handleDelete = (p: IDirectShareDeletionConfirmation) => {
        if (garage_id) {
            dispatch(
                garagesActions.deleteGarageDirectShareRequest({
                    entity_id: garage_id,
                    share_id: share_being_edited.id,
                })
            )

            navigate(`/sharing/with-others?entity_id=garage`)
        }
    }

    const changeTab = (tabID: string) => {
        setActiveTabId(tabID)
    }

    const btnIdsList: string[] = ['recipients', 'settings']
    const btnsData: any = {
        recipients: {
            id: 'recipients',
            txt: 'recipients',
            action: () => changeTab('recipients'),
            isDisabled: false,
        },
        settings: {
            id: 'settings',
            txt: 'settings',
            action: () => changeTab('settings'),
            isDisabled: false,
        },
    }

    const hasShareChanged = (
        a: IDirectShareOwnedItem,
        b: IDirectShareOwnedItem
    ): boolean => {
        if (a.title !== b.title) {
            return true
        }
        if (a.active_permission_ids && !b.active_permission_ids) {
            return true
        }
        if (!a.active_permission_ids && b.active_permission_ids) {
            return true
        }
        if (
            a.active_permission_ids &&
            b.active_permission_ids &&
            a.active_permission_ids.length !== b.active_permission_ids.length
        ) {
            return true
        }

        if (a.active_permission_ids && b.active_permission_ids) {
            const uniqueValues = new Set([
                ...a.active_permission_ids,
                ...b.active_permission_ids,
            ])
            for (const v of uniqueValues) {
                const aCount = a.active_permission_ids.filter(
                    (e) => e === v
                ).length
                const bCount = b.active_permission_ids.filter(
                    (e) => e === v
                ).length
                if (aCount !== bCount) return true
            }
        }

        return false
    }

    const checkWhatIsBeingUpdated = (): string | undefined => {
        if (
            share_being_edited.title &&
            current_share &&
            share_being_edited.title !== current_share.title
        ) {
            if (
                share_being_edited.active_permission_ids &&
                JSON.stringify(share_being_edited.active_permission_ids) !==
                    JSON.stringify(current_share.active_permission_ids)
            ) {
                return 'title and permisions'
            } else {
                return 'title'
            }
        } else if (
            current_share &&
            share_being_edited.active_permission_ids &&
            JSON.stringify(share_being_edited.active_permission_ids) !==
                JSON.stringify(current_share.active_permission_ids)
        ) {
            return 'permissions'
        }
        return undefined
    }

    const toggleShareDeletionConfirmation = (
        p: IDirectShareDeletionConfirmation
    ) => {
        dispatch(editFormsActions.toggleShareDeletionConfirmation(p))
    }

    let share_idsTo_be_displayed =
        share_being_edited &&
        share_being_edited.active_display &&
        share_being_edited.active_display.item_ids_display
            ? share_being_edited.active_display.item_ids_display
            : share_being_edited &&
              share_being_edited.all_share_recipients_ids_list

    return (
        <React.Fragment>
            <DesktopDisplayOnly>
                <CenteredPageWrapper fullwidth>
                    {share_being_edited && (
                        <WindowPageTemplate
                            hasPositionRelative
                            isCollapsed={isCollapsed}
                            stickyHeaderContent={
                                <EditModeTopHeaderDesktop
                                    headerText={
                                        current_share && current_share.title
                                            ? current_share.title
                                            : 'Untitled'
                                    }
                                    noBtns
                                    isInsideWindowTemplate
                                    goBack={() => {
                                        navigate(
                                            `/sharing/with-others?entity_id=garage`
                                        )
                                    }}
                                />
                            }
                            stickyBottomContent={
                                <EditModeFooterDesktop
                                    onCancel={
                                        activeTabId === 'settings'
                                            ? () => {
                                                  navigate(
                                                      `/sharing/with-others?entity_id=garage`
                                                  )
                                              }
                                            : undefined
                                    }
                                    onDelete={() =>
                                        setTimeout(
                                            () =>
                                                garage_id &&
                                                shareid &&
                                                toggleShareDeletionConfirmation(
                                                    {
                                                        isOpen: true,
                                                        entity_id: garage_id,
                                                        entity_type: 'garage',
                                                        share_id: shareid,
                                                    }
                                                )
                                        )
                                    }
                                    // isActive={this.checkIfChange()}
                                    isInsideWindowTemplate
                                    isActive={
                                        current_share &&
                                        share_being_edited &&
                                        hasShareChanged(
                                            current_share,
                                            share_being_edited
                                        )
                                    }
                                    onSave={
                                        activeTabId === 'settings'
                                            ? handleSubmitToBE
                                            : undefined
                                    }
                                    isCollapsed={isCollapsed}
                                />
                            }
                        >
                            <DesktopAdjustedWrapper>
                                {addShareRecipientByEmailForm && garage_id && (
                                    <ModalDisplayAbsolute
                                        isOpen={
                                            addShareRecipientByEmailForm &&
                                            addShareRecipientByEmailForm
                                                ? true
                                                : false
                                        }
                                        toggle={() => {}}
                                        top="20px"
                                        left="0px"
                                        right="20px"
                                        bottom="20px"
                                    >
                                        <AddShareByEmailFormDesktop
                                            user={userLoggedIn}
                                            close={() => {
                                                dispatch(
                                                    editFormsActions.toggleAddShareRecipientByEmail(
                                                        {
                                                            isOpen: false,
                                                        }
                                                    )
                                                )
                                            }}
                                            share_id={share_being_edited.id}
                                            entity_id={garage_id}
                                            isGarage
                                            dispatch={dispatch}
                                        />
                                    </ModalDisplayAbsolute>
                                )}
                                <StickyWrapper>
                                    <SwitchTabMenuDesktop
                                        btnIdsList={btnIdsList}
                                        btnsData={btnsData}
                                        activeID={activeTabId}
                                    />
                                </StickyWrapper>
                                <Padding40 />
                                {activeTabId === 'recipients' && (
                                    <Faded>
                                        <PrivateShareSearchFormManagerDesktop
                                            share={share_being_edited}
                                            searchUsers={(p) => {
                                                dispatch(
                                                    searchUsersActions.checkUserEmailExistsRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            searchResults={searchResults}
                                            resultsLoading={
                                                searchResultsLoading
                                            }
                                            addShareRecipient={(p) => {
                                                dispatch(
                                                    garagesActions.addGarageShareRecipientRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            isGarage
                                            selectedUsers={
                                                share_being_edited.all_share_recipients_ids_list
                                            }
                                            onClickInvite={() =>
                                                dispatch(
                                                    editFormsActions.toggleAddShareRecipientByEmail(
                                                        {
                                                            isOpen: true,
                                                        }
                                                    )
                                                )
                                            }
                                            inviteTxt={`Invite to Custodian & share`}
                                        />

                                        <Padding30 />
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                maxWidth: '730px',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <SharedWith>
                                                Direct share recipients
                                            </SharedWith>
                                            <DropdownHoverWrapper>
                                                <DropdownExpanderHover
                                                    width={'210px'}
                                                    fontSize="15px"
                                                    height="40px"
                                                    placeholder={`Sorted by Date added`}
                                                    placeholderWhenSelected="Sorted by"
                                                    rightAligned
                                                    items={[
                                                        {
                                                            text: 'by Date added',
                                                            action: () => {
                                                                garage_id &&
                                                                    dispatch(
                                                                        garagesActions.sortGarageShareRecipientsDisplay_change_request(
                                                                            {
                                                                                sortID: 'added_at',
                                                                                entityid:
                                                                                    garage_id,
                                                                                share: share_being_edited,
                                                                            }
                                                                        )
                                                                    )
                                                            },
                                                        },
                                                        {
                                                            text: 'Alphabetical',
                                                            action: () => {
                                                                garage_id &&
                                                                    garagesActions.sortGarageShareRecipientsDisplay_change_request(
                                                                        {
                                                                            sortID: 'alphabetical',
                                                                            entityid:
                                                                                garage_id,
                                                                            share: share_being_edited,
                                                                        }
                                                                    )
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </DropdownHoverWrapper>
                                        </div>

                                        <Padding20 />
                                        {share_idsTo_be_displayed &&
                                        share_idsTo_be_displayed.length > 0 ? (
                                            share_being_edited.share_recipients_data &&
                                            share_idsTo_be_displayed.map(
                                                (userid, index) => (
                                                    <ShareRowDesktop
                                                        recipient={
                                                            share_being_edited!
                                                                .share_recipients_data![
                                                                userid
                                                            ]
                                                        }
                                                        index={index}
                                                        isLast={
                                                            share_idsTo_be_displayed &&
                                                            share_idsTo_be_displayed.length -
                                                                1 ===
                                                                index
                                                                ? true
                                                                : false
                                                        }
                                                        actionsData={{
                                                            email: (payload: {
                                                                invite_id: string
                                                                recipient_email: string
                                                            }) =>
                                                                dispatch(
                                                                    garagesActions.resendGarageShareInviteRequest(
                                                                        {
                                                                            invite_id:
                                                                                payload.invite_id,
                                                                            recipient_email:
                                                                                payload.recipient_email,
                                                                        }
                                                                    )
                                                                ),

                                                            delete: (payload: {
                                                                recipient_id: string
                                                                recipient_type:
                                                                    | 'platform'
                                                                    | 'external'
                                                            }) => {
                                                                payload.recipient_type ===
                                                                'external'
                                                                    ? dispatch(
                                                                          garagesActions.deleteGarageShareInviteRequest(
                                                                              {
                                                                                  share_id:
                                                                                      share_being_edited.id,
                                                                                  entity_id:
                                                                                      share_being_edited.entity_id,
                                                                                  invite_id:
                                                                                      payload.recipient_id,
                                                                              }
                                                                          )
                                                                      )
                                                                    : dispatch(
                                                                          garagesActions.deleteGarageShareRecipientRequest(
                                                                              {
                                                                                  share_id:
                                                                                      share_being_edited.id,
                                                                                  entity_id:
                                                                                      share_being_edited.entity_id,
                                                                                  recipient_userid:
                                                                                      payload.recipient_id,
                                                                              }
                                                                          )
                                                                      )
                                                            },
                                                        }}
                                                        sharedOn={
                                                            share_being_edited.created_at
                                                        }
                                                    />
                                                )
                                            )
                                        ) : (
                                            <Faded>
                                                <NoIconDataRectangle
                                                    text={`You haven’t shared this with anyone yet`}
                                                />
                                            </Faded>
                                        )}
                                    </Faded>
                                )}
                                {activeTabId === 'settings' && (
                                    <Faded>
                                        <PrivateShareFormManagerDesktop
                                            share_being_edited={
                                                share_being_edited
                                            }
                                            handleSubmit={handleSubmitToBE}
                                            handlePrivateShareTitleChange={
                                                handleTitleChange
                                            }
                                            handlePermissions={
                                                handleChangePermissions
                                            }
                                            formType="edit"
                                            isGarageShare
                                        />
                                    </Faded>
                                )}
                            </DesktopAdjustedWrapper>
                        </WindowPageTemplate>
                    )}
                </CenteredPageWrapper>
                <DeletionConfirmationDesktop
                    isShare
                    isEditForm
                    isOpen={directShareDeletionConfirmation.isOpen}
                    toggle={() =>
                        setTimeout(
                            () =>
                                toggleShareDeletionConfirmation({
                                    isOpen: false,
                                    entity_id: undefined,
                                    entity_type: undefined,
                                    share_id: undefined,
                                }),
                            50
                        )
                    }
                    action={() => handleDelete(directShareDeletionConfirmation)}
                    actionCopy="Confirm deletion"
                    titleCopy="Are you sure?"
                    detailsCopy="All recipients will lose access to your garage."
                />
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <SideSlider
                    direction={
                        location.state &&
                        location.state.prevPath &&
                        location.state.prevPath.includes(
                            `/edit-share/recipients`
                        )
                            ? 'left'
                            : 'right'
                    }
                    initial="enter"
                >
                    <PrivateShareReturnNav
                        customText={
                            current_share && current_share.title
                                ? current_share.title
                                : 'Untitled'
                        }
                        chevron
                        linkTo={`/sharing/with-others`}
                        params="?entity_id=garage"
                        noBoxShadow
                    />
                    <div style={{ paddingTop: '60px' }} />
                    <StickyWrapper>
                        <SwitchTabMenuMobile
                            btnIdsList={btnIdsList}
                            btnsData={btnsData}
                            activeID={activeTabId}
                        />
                    </StickyWrapper>
                    {activeTabId === 'settings' && (
                        <PrivateShareFormManagerMobile
                            share_being_edited={share_being_edited}
                            handleSubmit={handleSubmitToBE}
                            handlePrivateShareTitleChange={handleTitleChange}
                            handlePermissions={handleChangePermissions}
                            formType="edit"
                            isSaveActive={
                                current_share &&
                                share_being_edited &&
                                hasShareChanged(
                                    current_share,
                                    share_being_edited
                                )
                            }
                            isGarageShare
                            onDeleteShare={() =>
                                setTimeout(
                                    () =>
                                        garage_id &&
                                        shareid &&
                                        toggleShareDeletionConfirmation({
                                            isOpen: true,
                                            entity_id: garage_id,
                                            entity_type: 'garage',
                                            share_id: shareid,
                                        })
                                )
                            }
                            shareChanges={checkWhatIsBeingUpdated()}
                            sharesLoading={garageSharesLoading}
                        />
                    )}
                    {activeTabId === 'recipients' && (
                        <MobileWrapper>
                            {addShareRecipientByEmailForm && garage_id && (
                                <ModalDisplay
                                    isOpen={
                                        addShareRecipientByEmailForm &&
                                        addShareRecipientByEmailForm
                                            ? true
                                            : false
                                    }
                                    toggle={() => {}}
                                >
                                    <AddShareByEmailFormMobile
                                        user={userLoggedIn}
                                        close={() => {
                                            dispatch(
                                                editFormsActions.toggleAddShareRecipientByEmail(
                                                    {
                                                        isOpen: false,
                                                    }
                                                )
                                            )
                                        }}
                                        share_id={share_being_edited.id}
                                        entity_id={garage_id}
                                        isGarage
                                        dispatch={dispatch}
                                    />
                                </ModalDisplay>
                            )}
                            <Container>
                                <Title>Choose your Share Recipient(s)</Title>
                                <SubTitle>
                                    {`Please search and select the recipient(s) you would like to share your garage with by entering their email.`}
                                </SubTitle>
                                <Padding24 />

                                <PrivateShareSearchManagerMobileV2
                                    share={share_being_edited}
                                    searchUsers={(p) => {
                                        dispatch(
                                            searchUsersActions.checkUserEmailExistsRequest(
                                                p
                                            )
                                        )
                                    }}
                                    searchResults={searchResults}
                                    resultsLoading={searchResultsLoading}
                                    addShareRecipient={(p) => {
                                        dispatch(
                                            garagesActions.addGarageShareRecipientRequest(
                                                p
                                            )
                                        )
                                    }}
                                    isGarage
                                    selectedUsers={
                                        share_being_edited.all_share_recipients_ids_list
                                    }
                                    onClickInvite={() =>
                                        dispatch(
                                            editFormsActions.toggleAddShareRecipientByEmail(
                                                {
                                                    isOpen: true,
                                                }
                                            )
                                        )
                                    }
                                    inviteTxt={`Invite to Custodian & Share`}
                                />

                                <Padding24 />
                            </Container>

                            <Padding20 />

                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <SharedWith>Direct share recipients</SharedWith>
                                <GreyIconButton
                                    onClick={() =>
                                        setIsSortBottomSheetOpen(true)
                                    }
                                    variant="sort"
                                    isActive={false}
                                    sortingCriteria={'by_date_added'}
                                />
                            </div>

                            <Padding10 />
                            {share_idsTo_be_displayed &&
                            share_idsTo_be_displayed.length > 0 ? (
                                share_being_edited.share_recipients_data &&
                                share_idsTo_be_displayed.map(
                                    (userid, index) => (
                                        <ShareRowMobile
                                            recipient={
                                                share_being_edited!
                                                    .share_recipients_data![
                                                    userid
                                                ]
                                            }
                                            index={index}
                                            isLast={
                                                share_idsTo_be_displayed &&
                                                share_idsTo_be_displayed.length -
                                                    1 ===
                                                    index
                                                    ? true
                                                    : false
                                            }
                                            actionsData={{
                                                email: (payload: {
                                                    recipient_id: string
                                                    recipient_email: string
                                                }) =>
                                                    dispatch(
                                                        garagesActions.resendGarageShareInviteRequest(
                                                            {
                                                                invite_id:
                                                                    payload.recipient_id,
                                                                recipient_email:
                                                                    payload.recipient_email,
                                                            }
                                                        )
                                                    ),
                                                delete: (payload: {
                                                    recipient_id: string
                                                    recipient_type:
                                                        | 'platform'
                                                        | 'external'
                                                }) => {
                                                    payload.recipient_type ===
                                                    'external'
                                                        ? dispatch(
                                                              garagesActions.deleteGarageShareInviteRequest(
                                                                  {
                                                                      share_id:
                                                                          share_being_edited.id,
                                                                      entity_id:
                                                                          share_being_edited.entity_id,
                                                                      invite_id:
                                                                          payload.recipient_id,
                                                                  }
                                                              )
                                                          )
                                                        : dispatch(
                                                              garagesActions.deleteGarageShareRecipientRequest(
                                                                  {
                                                                      share_id:
                                                                          share_being_edited.id,
                                                                      entity_id:
                                                                          share_being_edited.entity_id,
                                                                      recipient_userid:
                                                                          payload.recipient_id,
                                                                  }
                                                              )
                                                          )
                                                },
                                            }}
                                            sharedOn={
                                                share_being_edited.created_at
                                            }
                                        />
                                    )
                                )
                            ) : (
                                <Faded>
                                    <NoIconDataRectangle
                                        text={`You haven’t shared this with anyone yet`}
                                    />
                                </Faded>
                            )}
                            <PrivateSharesSortingMobile
                                actions_items={[
                                    {
                                        id: 'added_at',
                                        text: 'By date added',
                                        action: () => {
                                            garage_id &&
                                                garagesActions.sortGarageShareRecipientsDisplay_change_request(
                                                    {
                                                        sortID: 'added_at',
                                                        entityid: garage_id,
                                                        share: share_being_edited,
                                                    }
                                                )
                                        },
                                    },
                                    {
                                        id: 'alphabetical',
                                        text: 'Alphabetical',
                                        action: () => {
                                            garage_id &&
                                                garagesActions.sortGarageShareRecipientsDisplay_change_request(
                                                    {
                                                        sortID: 'alphabetical',
                                                        entityid: garage_id,
                                                        share: share_being_edited,
                                                    }
                                                )
                                        },
                                    },
                                ]}
                                toggle={() => setIsSortBottomSheetOpen(false)}
                                isPrivateSharesSortingOpen={
                                    isSortBottomSheetOpen
                                }
                                activeSort={
                                    share_being_edited.active_display &&
                                    share_being_edited.active_display.activeSort
                                        ? share_being_edited.active_display
                                              .activeSort
                                        : 'added_at'
                                }
                            />
                        </MobileWrapper>
                    )}
                    <div style={{ paddingTop: '150px' }} />
                </SideSlider>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default GarageShareEdit

const DesktopAdjustedWrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
`

const MobileWrapper = styled.div`
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    @media ${device.ipad} {
        max-width: 600px;
        padding-left: 30px;
        padding-right: 30px;
    }
`
const SharedWith = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-Bold';
    font-size: 17px;
    padding-bottom: 20px;
`

const Padding10 = styled.div`
    padding-top: 10px;
`
const Padding20 = styled.div`
    padding-top: 20px;
`

const Padding30 = styled.div`
    padding-top: 30px;
`

const Padding40 = styled.div`
    padding-top: 40px;
`

const StickyWrapper = styled.div`
    position: sticky;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    z-index: 4;
    display: flex;
    align-items: flex-end;
    background-color: var(--bg-color, #fff);
    height: 50px;
    transform: translateY(-20px);
    margin-top: 30px;
    @media ${device.mobile_and_ipad} {
        top: 75px;
        margin-top: 15px;
    }
`

const DropdownHoverWrapper = styled.div`
    border-radius: 5px;
    height: 40px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 200ms;
`

const Title = styled.div`
    font-family: Lato;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
`

const SubTitle = styled.div`
    font-family: Lato;
    font-size: 15px;
    color: var(--text-default, #666666);
`

const Padding24 = styled.div`
    padding-top: 24px;
`
