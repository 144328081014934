import { all, call, fork, put, select, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { api } from '../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { getTasksInState } from './addReminderToTask'
import { tasksActions } from '../reducer'
import { IReminder, ITask } from 'entityModels'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'
import { IDeleteTaskReminderAPIReq } from '../../services/types'

export type IDeleteTaskReminderReqSaga = {
    taskUID: string
    reminderIDs: string[]
}

export type IDeleteTaskReminderSuccess = {
    taskUID: string
    reminders: IReminder[]
}

export function* deleteTaskRemindersSagaAPI(p: IDeleteTaskReminderAPIReq): any {
    try {
        yield call(api.tasks.reminders.deleteTaskReminder, p)

        let tasksInState = yield select(getTasksInState)

        let taskData: ITask | undefined = tasksInState[p.taskUID]

        let payl: IDeleteTaskReminderSuccess = {
            taskUID: p.taskUID,
            reminders: [],
        }

        if (taskData && taskData.reminders) {
            for (const reminder of taskData.reminders) {
                if (reminder.id !== p.reminderID) {
                    payl.reminders = [...payl.reminders, reminder]
                }
            }
        }

        yield put(tasksActions.deleteReminderTaskSuccess(payl))
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: deleteTaskRemindersSagaAPI,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't get the task's reminders`,
                custom_user_action_text: 'Return to see all tasks',
                custom_redirect_path: `/tasks`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(tasksActions.deleteReminderTaskError(customError))
        }
    }
}

export function* deleteTaskRemindersSaga(p: IDeleteTaskReminderReqSaga): any {
    if (p.reminderIDs && p.reminderIDs.length > 0) {
        yield all(
            p.reminderIDs.map((reminderID: string) => {
                let payload: IDeleteTaskReminderAPIReq = {
                    taskUID: p.taskUID,
                    reminderID: reminderID,
                }
                return fork(deleteTaskRemindersSagaAPI, payload)
            })
        )
    }
}

function* watcherDeleteTaskReminder() {
    while (true) {
        const { payload } = yield take(tasksActions.deleteReminderTaskRequest)
        yield call(deleteTaskRemindersSaga, payload)
    }
}

const delete_task_reminder: any[] = [fork(watcherDeleteTaskReminder)]

export default delete_task_reminder
