import * as React from 'react'
import { useEffect } from 'react'
import BasicTechInfoEditFormMobile from '../basic/basicTechInfoEditFormMobile'
import CarTechInfoEditFormSelectSearchMobile from '../searchselect/mobile/carTechInfoEditFormSelectSearchMobile'
import colors from '../../../../../redux/localdata/formslist/car/colors.json'
import vehicle_condition from '../../../../../redux/localdata/formslist/car/vehicle_condition.json'
import powertype from '../../../../../redux/localdata/formslist/car/powertype.json'
import DatePickerTechInfoEditFormMobile from '../../technicalInformationEditForm/datepicker/mobile/datePickerTechInfoEditFormMobile'
import NumberTechInfoEditFormMobile from '../../technicalInformationEditForm/number/mobile/numberTechInfoEditFormMobile'
import DuoRadioTechInfoEditFormMobile from '../../technicalInformationEditForm/duoradio/mobile/duoRadioTechInfoEditFormMobile'
import drive_train_options from '../../../../../redux/localdata/formslist/car/drive_train_options.json'
import features from '../../../../../redux/localdata/formslist/car/features.json'
import CarTechInfoEditFormMultipleSelectSearchMobile from '../multipleselect/mobile/carTechInfoEditFormMultipleSelectSearchMobile'
import {
    IList,
    IwriteMultipleTechnicalInformationPayloadReq,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import CarMakeCustomFormMobile from '../custom/carMakeCustomForm/carMakeCustomFormMobile'
import CarModelCustomFormMobile from '../custom/carModelCustomForm/carModelMobileFormCustom'
import CarTransmissionTypeCustomMobile from '../custom/carTransmissionTypeCustom/carTransmissionTypeCustomMobile'
import CarYearCustomFormMobile from '../custom/carYearCustomForm/carYearMobileFormCustom'
import CarBodyTypeCustomMobile from '../custom/carBodyTypeCustom/carBodyTypeCustomMobile'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import { IRootState } from '../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { technicalInformationActions } from '../../../../../redux/entities/technical_information/reducer'

export interface Props {
    item: IList | undefined
    toggle: () => void
    id: string
    allTechInfodata: any
    carid: string
}

const drive_side_options = [
    {
        name: 'LHD',
    },
    {
        name: 'RHD',
    },
    {
        name: 'Center',
    },
]

const road_tax_status_options = [
    {
        name: 'Taxed',
    },
    {
        name: 'Untaxed',
    },
    {
        name: 'SORN',
    },
    {
        name: 'NA',
    },
    {
        name: '-',
    },
]

const mot_status_options = [
    {
        name: 'Valid',
    },
    {
        name: 'Not Valid',
    },
    {
        name: 'NA',
    },
    {
        name: '-',
    },
]

const TechInfoFormManagerMobile: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch()

    const {
        tempMakeDisplay,
        tempModelDisplay,
        makesdropdowndata,
        modelsdropdowndata,
        yearsdropdowndata,
        body_types_list,
        transmission_types_list,
        user_fuel_unit_ids_list,
        user_fuel_unit_chosen_id,
        user_fuel_unit_data,
        user_distance_unit_ids_list,
        user_distance_unit_chosen_id,
        user_distance_unit_data,
    } = useAppSelector((state: IRootState) => ({
        tempMakeDisplay: state.localdata.dropdownData.tempMakeDisplay,
        tempModelDisplay: state.localdata.dropdownData.tempModelDisplay,
        makesdropdowndata: state.localdata.dropdownData.carMakes,
        yearsdropdowndata: state.localdata.dropdownData.carYears,
        modelsdropdowndata: state.localdata.dropdownData.carModels,
        body_types_list: state.localdata.dropdownData.carBodyTypes,
        transmission_types_list:
            state.localdata.dropdownData.carTransmissionTypes,
        user_fuel_unit_ids_list:
            state.user.userLoggedIn?.preferences?.data?.units?.data?.fuel_unit
                .ids_list,
        user_fuel_unit_chosen_id:
            state.user.userLoggedIn?.preferences?.data?.units?.data?.fuel_unit
                .user_choice_id,
        user_fuel_unit_data:
            state.user.userLoggedIn?.preferences?.data?.units?.data?.fuel_unit
                .data,
        user_distance_unit_ids_list:
            state.user.userLoggedIn?.preferences?.data?.units?.data
                ?.distance_unit.ids_list,
        user_distance_unit_chosen_id:
            state.user.userLoggedIn?.preferences?.data?.units?.data
                ?.distance_unit.user_choice_id,
        user_distance_unit_data:
            state.user.userLoggedIn?.preferences?.data?.units?.data
                ?.distance_unit.data,
    }))

    useEffect(() => {
        const { item, allTechInfodata } = props

        if (item) {
            if (item.id === 'make') {
                if (!makesdropdowndata) {
                    dispatch(dropDownActions.setMakeDropdownDataRequest())
                }

                if (!yearsdropdowndata) {
                    dispatch(
                        dropDownActions.setYearDropdownDataRequest(undefined)
                    )
                }
                if (item.answer && typeof item.answer === 'string') {
                    dispatch(
                        dropDownActions.setModelDropdownDataRequest({
                            makeName: item.answer,
                            make_uid: item.uid_current,
                        })
                    )
                }
            }

            if (item.id === 'model') {
                if (item.answer) {
                    if (allTechInfodata.make.answer) {
                        dispatch(dropDownActions.setMakeDropdownDataRequest())
                        dispatch(
                            dropDownActions.setModelDropdownDataRequest({
                                makeName: allTechInfodata.make.answer,
                                make_uid: item.uid_current,
                            })
                        )
                    }
                }
            }

            if (item.id === 'body_type') {
                if (!body_types_list) {
                    dispatch(dropDownActions.setBodyTypeDropdownDataRequest())
                }
            }
            if (item.id === 'transmission') {
                dispatch(
                    dropDownActions.setTransmissionTypesDropdownDataRequest()
                )
            }
        }
    }, [makesdropdowndata, yearsdropdowndata, body_types_list])

    const distance_units: IList[] | null | undefined = React.useMemo(
        () =>
            user_distance_unit_ids_list &&
            user_distance_unit_ids_list.map((id: string) => {
                return user_distance_unit_data![id]
            }),
        [user_distance_unit_ids_list, user_distance_unit_data]
    )

    const user_default_distance_unit: IList | null = React.useMemo(
        () =>
            user_distance_unit_data && user_distance_unit_chosen_id
                ? user_distance_unit_data[user_distance_unit_chosen_id]
                : null,
        [user_distance_unit_data, user_distance_unit_chosen_id]
    )

    const fuel_units: IList[] | null | undefined = React.useMemo(
        () =>
            user_fuel_unit_ids_list &&
            user_fuel_unit_ids_list.map((id: string) => {
                return user_fuel_unit_data![id]
            }),
        [user_fuel_unit_ids_list, user_fuel_unit_data]
    )

    const user_default_fuel_unit: IList | null =
        user_fuel_unit_data && user_fuel_unit_chosen_id
            ? user_fuel_unit_data[user_fuel_unit_chosen_id]
            : null

    const loading = useAppSelector(
        (state: IRootState) => state.localdata.dropdownData.loading
    )

    const renderForm = (
        item: any,
        toggle: any,
        id: string,
        allTechInfodata: any,
        carid: string,
        distance_units: IList[],
        fuel_units: IList[]
    ) => {
        let distanceUnitInfo = {
            list: distance_units,
            id: 'distance_units',
            user_default_unit: user_default_distance_unit?.id,
        }

        let fuelUnitInfo = {
            list: fuel_units,
            id: 'fuel_units',
            user_default_unit: user_default_fuel_unit?.id,
        }

        switch (item.id) {
            case 'make':
                return (
                    <CarMakeCustomFormMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        make_item={{
                            id: 'make',
                            answer: tempMakeDisplay,
                        }}
                        model_item={{
                            id: 'model',
                            answer: tempModelDisplay,
                        }}
                        year_item={allTechInfodata.year}
                        toggle={toggle}
                        carid={carid}
                        writeMultipleTechnicalInformationRequest={(
                            p: IwriteMultipleTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeMultipleTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        setCustomItemInSpecifiedTargettedDropdown={undefined}
                        isLoading={loading}
                        isLoadingNewMakeOrModel={false}
                    />
                )

            case 'model':
                return (
                    <CarModelCustomFormMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        toggle={toggle}
                        model_item={{
                            id: 'model',
                            answer: tempModelDisplay,
                        }}
                        year_item={allTechInfodata.year}
                        make_item={{
                            id: 'make',
                            answer: tempMakeDisplay,
                        }}
                        carid={carid}
                        tempModelDisplay={tempModelDisplay}
                        modelDropdownList={modelsdropdowndata}
                        setCustomItemInSpecifiedTargettedDropdown={undefined}
                        isLoading={loading}
                        dispatch={dispatch}
                    />
                )

            case 'year':
                return (
                    <CarYearCustomFormMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        toggle={toggle}
                        model_item={{
                            id: 'model',
                            answer: tempModelDisplay,
                        }}
                        year_item={allTechInfodata.year}
                        carid={carid}
                        setCustomItemInSpecifiedTargettedDropdown={undefined}
                        isLoading={loading}
                    />
                )

            case 'body_type':
                return (
                    <CarBodyTypeCustomMobile
                        submitFunc={(
                            p: IwriteMultipleTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeMultipleTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={body_types_list}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'transmission':
                return (
                    <CarTransmissionTypeCustomMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={transmission_types_list}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'drive_train':
                return (
                    <DuoRadioTechInfoEditFormMobile
                        optionsList={drive_train_options}
                        item={item}
                        toggle={toggle}
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        carid={carid}
                    />
                )

            case 'features':
                return (
                    <CarTechInfoEditFormMultipleSelectSearchMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={features}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'previous_owners_number':
                return (
                    <NumberTechInfoEditFormMobile
                        unitInfo={{ list: [] }}
                        tech_info_id={id}
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'engine_capacity':
                return (
                    <NumberTechInfoEditFormMobile
                        isUnits={true}
                        unitInfo={fuelUnitInfo}
                        unitList={fuel_units}
                        item={item}
                        toggle={toggle}
                        tech_info_id={id}
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        carid={carid}
                    />
                )

            case 'mileage':
                return (
                    <NumberTechInfoEditFormMobile
                        tech_info_id={id}
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        isUnits={true}
                        unitInfo={distanceUnitInfo}
                        unitList={distance_units}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'fuel_type':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={powertype}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
            case 'color_interior':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={colors}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'color_exterior':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={colors}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'drive_side':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={drive_side_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'condition':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={vehicle_condition}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'tax_status':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={road_tax_status_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'service_check_status':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        optionsList={mot_status_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'last_owner_change':
                return (
                    <DatePickerTechInfoEditFormMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'tax_due':
                return (
                    <DatePickerTechInfoEditFormMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'service_check_expiry':
                return (
                    <DatePickerTechInfoEditFormMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'registration_date':
                return (
                    <DatePickerTechInfoEditFormMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            default:
                return (
                    <BasicTechInfoEditFormMobile
                        submitFunc={(
                            p: IwriteTechnicalInformationPayloadReq
                        ) => {
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    p
                                )
                            )
                        }}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
        }
    }

    return (
        <>
            {distance_units &&
                fuel_units &&
                renderForm(
                    props.item,
                    props.toggle,
                    props.id,
                    props.allTechInfodata,
                    props.carid,
                    distance_units,
                    fuel_units
                )}
            <div />
        </>
    )
}

export default TechInfoFormManagerMobile
