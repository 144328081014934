import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { IInsuranceQuoteApplication, insuranceActions } from './../../reducer'

import { api } from '../../../services'
import { insurance_application_existing_state_select } from '../maindriver/maindriver_additionaldetails_submit'
import posthog from 'posthog-js'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'
import { push } from 'redux-first-history'

export function* Submit_application_details(): any {
    try {
        posthog.capture('Submit insurance application')

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        yield call(
            api.insuranceApplication.publishInsuranceApplication,
            `${all_existing.id}`
        )

        // call validation endpoint there

        yield put(insuranceActions.submit_application_success())

        yield put(push(`/insurance/overview`))

        // here, pass the results and thats it

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_application_details,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )

            if (error.status === 403 && error.text) {
                let customErrorData: ICustomErrorData = {
                    custom_message: 'Whoops!',
                    custom_message_detail: error.text,
                    custom_user_action_text: 'OK',
                    custom_redirect_path: `/insurance/application/${`${all_existing.id}`}`,
                }
                let customError = ConvertToReduxError(error, customErrorData)
                yield put(
                    insuranceActions.submit_application_error(customError)
                )
            } else if (
                error.status === 400 &&
                error.text &&
                error.text === 'application is not valid'
            ) {
                let customErrorData: ICustomErrorData = {
                    custom_message: 'Whoops!',
                    custom_message_detail:
                        'Your application is missing mandatory data. Please fill it in and submit your application again.',
                    custom_user_action_text: 'OK',
                    custom_redirect_path: `/insurance/application/${`${all_existing.id}`}`,
                }
                let customError = ConvertToReduxError(error, customErrorData)
                yield put(insuranceActions.show_Validation_sheet(true))
                yield put(
                    insuranceActions.submit_application_error(customError)
                )
            } else {
                let customErrorData: ICustomErrorData = {
                    custom_message: `Something went wrong`,
                    custom_message_detail:
                        'Please try submitting your application again.',
                    custom_user_action_text: 'OK',
                    custom_redirect_path: `/insurance/application/${`${all_existing.id}`}`,
                }
                let customError: IReduxError = ConvertToReduxError(
                    error,
                    customErrorData,
                    'insurance'
                )
                yield put(
                    insuranceActions.submit_application_error(customError)
                )
            }
        }
    }
}

function* Watcher_Submit_application() {
    while (true) {
        yield take(insuranceActions.submit_application_request)
        yield call(Submit_application_details)
    }
}

const application_submit: any[] = [fork(Watcher_Submit_application)]

export default application_submit
