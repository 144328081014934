import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'

import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'
import OnboardingFormLayoutMobile, {
    OnboardingText,
} from '../../../templates/onboarding/onboardingFormLayoutMobile'
import OnboardingLayoutDesktop from '../../../templates/onboarding/desktop/onboardingLayoutDesktop'
import OnboardingFormTemplateDesktop from '../../../templates/onboarding/desktop/onboardingFormTemplateDesktop'
import ImageRadioInput from '../../../molecules/editOrCreateModeSingleFields/imageRadioInputs'
import system from '../../../../public/assets/onboarding/step_0_system_mode.png'
import light from '../../../../public/assets/onboarding/step_0_light_mode.png'
import dark from '../../../../public/assets/onboarding/step_0_dark_mode.png'
import { getPreferredTheme } from '../../../../providers/theme/customThemeProvider'
import useThemes from '../../../../providers/theme/hooks'
import { useNavigate } from 'react-router'
import { IUSerGeneralInfoUpdatePayload } from '../../../../redux/user/types'
import { usersActions } from '../../../../redux/user/reducer'

const OnboardingStep_0 = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    let userLoading = useAppSelector((state) => state.user.loading)
    let onboardingStep1Data = useAppSelector(
        (state) => state.localdata.onboardingContent.content[0]
    )

    const [mode, setMode] = useState<{
        uid: string
        name: string
        img?: string
    }>({
        uid: 'SYSTEM',
        name: 'Synch with system',
        img: system,
    })

    const options: {
        uid: string
        name: string
        img?: string
    }[] = [
        {
            uid: 'SYSTEM',
            name: 'Synch with system',
            img: system,
        },
        {
            uid: 'LIGHT',
            name: 'Always daytime',
            img: light,
        },

        {
            uid: 'DARK',
            name: 'Always nighttime',
            img: dark,
        },
    ]

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl('/onboarding-step-0')
        )

        let themeSwitcherStored = localStorage?.getItem('ui_appearance_mode')

        if (themeSwitcherStored) {
            if (themeSwitcherStored === 'dark') {
                setMode({
                    uid: 'DARK',
                    name: 'Always nighttime',
                    img: dark,
                })
            } else if (themeSwitcherStored === 'light') {
                setMode({
                    uid: 'LIGHT',
                    name: 'Always daytime',
                    img: light,
                })
            } else {
                setMode({
                    uid: 'SYSTEM',
                    name: 'Synch with system',
                    img: system,
                })
            }
        } else if (userLoggedIn && userLoggedIn.ui_appearance_mode) {
            if (userLoggedIn.ui_appearance_mode === 'DARK') {
                setMode({
                    uid: 'DARK',
                    name: 'Always nighttime',
                    img: dark,
                })
            } else if (userLoggedIn.ui_appearance_mode === 'LIGHT') {
                setMode({
                    uid: 'LIGHT',
                    name: 'Always daytime',
                    img: light,
                })
            } else
                setMode({
                    uid: 'SYSTEM',
                    name: 'Synch with system',
                    img: system,
                })
        }
    }, [])

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const { setTheme } = useThemes()

    const updateTheme = (p: 'SYSTEM' | 'DARK' | 'LIGHT') => {
        if (userLoggedIn?.id) {
            let dataToSend: IUSerGeneralInfoUpdatePayload = {
                id: userLoggedIn?.id,
                data: {
                    user_preferences: { ui_appearance_mode: p },
                },
            }

            dispatch(usersActions.updateUserGeneralInfoRequest(dataToSend))
        }

        localStorage.setItem(
            'ui_appearance_mode',
            p === 'SYSTEM' ? 'system' : p === 'DARK' ? 'dark' : 'light'
        )
    }

    return (
        <React.Fragment>
            <DesktopDisplayOnly>
                <OnboardingLayoutDesktop
                    step={0}
                    title={onboardingStep1Data.title}
                    whiteTextTitle={onboardingStep1Data.desktopRightSideTitle}
                    whiteTextSubTitle={
                        onboardingStep1Data.desktopRightSideSubtitle
                    }
                >
                    <OnboardingFormTemplateDesktop
                        isBtnLoading={userLoading ? true : false}
                        submit={() => {
                            updateTheme(mode.uid as any)
                            setTimeout(
                                () => navigate(`/onboarding-step-1`),
                                100
                            )
                        }}
                    >
                        <OnboardingText style={{ paddingTop: 32 }}>
                            {onboardingStep1Data.text}
                        </OnboardingText>
                        <ImageRadioInput
                            options={options}
                            onChange={(option: {
                                uid: string
                                name: string
                                img?: string
                            }) => {
                                let systemTheme = getPreferredTheme()
                                setMode(option)
                                setTheme(
                                    option.uid === 'SYSTEM'
                                        ? systemTheme
                                        : option.uid === 'DARK'
                                        ? 'dark'
                                        : 'light'
                                )
                            }}
                            value={mode?.uid}
                        />
                    </OnboardingFormTemplateDesktop>
                </OnboardingLayoutDesktop>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <SideSliderWithExit
                    isVisible={true}
                    direction={'right'}
                    initial="enter"
                    customDuration={0.5}
                    noOverflow
                >
                    <OnboardingFormLayoutMobile
                        step={0}
                        title={onboardingStep1Data.title}
                        isBtnLoading={userLoading ? true : false}
                        submit={() => {
                            updateTheme(mode.uid as any)
                            setTimeout(
                                () => navigate(`/onboarding-step-1`),
                                100
                            )
                        }}
                    >
                        <OnboardingText style={{ paddingTop: 32 }}>
                            {onboardingStep1Data.text}
                        </OnboardingText>
                        <ImageRadioInput
                            options={options}
                            onChange={(option: {
                                uid: string
                                name: string
                                img?: string
                            }) => {
                                let systemTheme = getPreferredTheme()
                                setMode(option)
                                setTheme(
                                    option.uid === 'SYSTEM'
                                        ? systemTheme
                                        : option.uid === 'DARK'
                                        ? 'dark'
                                        : 'light'
                                )
                            }}
                            value={mode?.uid}
                        />
                        <div style={{ paddingTop: 40 }} />
                    </OnboardingFormLayoutMobile>
                </SideSliderWithExit>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default OnboardingStep_0
