import styled from 'styled-components'
import colours, { ITheme } from '../../../providers/theme/colours'
import { device } from '../displays/devices'

type IStyleProps = {
    $theme: ITheme
    $customBorderRadius?: string
    $customWidth?: string
    $hasBorder?: boolean
}

type IStyle = {
    $theme: ITheme
}

export const JourneyCardDesktop = styled.div<IStyleProps>`
    width: ${(props) => props.$customWidth ?? '100%'};
    background: ${(props) => colours[props.$theme].background_default};
    border: ${(props) =>
        props.$hasBorder
            ? `1px solid ${colours[props.$theme].shavow_08}`
            : 'none'};
    border-radius: ${(props) => props.$customBorderRadius ?? '16px'};
    box-shadow: ${(props) =>
        ` 64px 64px 128px 0px ${colours[props.$theme].shavow_08}`};

    @media (max-width: 1200px) {
        width: 100%;
        box-sizing: border-box;
    }
`

export const JourneyCardMobile = styled.div<IStyleProps>`
    width: ${(props) => props.$customWidth ?? '100%'};
    background: ${(props) => colours[props.$theme].background_default};
    border-radius: ${(props) => props.$customBorderRadius ?? '24px'};
    box-shadow: ${(props) =>
        ` 0px 24px 32px 0px ${colours[props.$theme].shadow_04}`};
`

export const JourneyCardMobileWithScroll = styled.div<IStyleProps>`
    position: relative;
    width: ${(props) => props.$customWidth ?? 'calc(100% - 48px)'};
    height: 100%;
    background: ${(props) => colours[props.$theme].background_default};
    border-radius: ${(props) => props.$customBorderRadius ?? '24px'};
    box-shadow: ${(props) =>
        ` 0px 24px 32px 0px ${colours[props.$theme].shadow_04}`};
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
`

export const JourneyCardDesktopWithScroll = styled.div<IStyleProps>`
    position: relative;
    width: ${(props) => props.$customWidth ?? 'calc(100% - 48px)'};
    max-height: 100%;
    background: ${(props) => colours[props.$theme].background_default};
    border-radius: ${(props) => props.$customBorderRadius ?? '24px'};
    box-shadow: ${(props) =>
        ` 0px 24px 32px 0px ${colours[props.$theme].shadow_04}`};
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
`

export const AbsoluteGradient = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        var(--bg-color, #fff) 100%
    );
`

export const JourneyWelcomeWrapper = styled.div`
    min-height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

export const JourneyWelcomeWrapperV2 = styled.div`
    min-height: 100dvh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;

    padding-top: 60px;

    @media ${device.mobile} {
        height: 100%;
        padding-top: 40px;
        padding-bottom: 0px;
    }

    @media (min-width: 400px) and (max-width: 881px) {
        padding-top: 60px;
    }

    @media ${device.wide_desktop} {
        padding-top: 6vh;
    }
`

export const JourneyWelcomeSubWrapper = styled.div`
    display: flex;
    /* padding: 88px 0px; */
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: 1024px;
    min-height: 80dvh;
    transition: all 200ms;
    @media ${device.wide_desktop} {
        max-height: 1200px;
    }
    @media ${device.mobile} {
        height: 100%;
        padding-top: 40px;
        padding-bottom: 0px;
    }
`

export const JourneyWelcomeSubWrapperV2 = styled.div`
    display: flex;
    /* padding: 88px 0px; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 1024px;
    min-height: 80dvh;
    transition: all 200ms;
    @media ${device.wide_desktop} {
        max-height: 1200px;
    }
    @media ${device.mobile} {
        height: 100%;
        padding-bottom: 0px;
    }

    @media (max-width: 400px) {
        padding-top: 24px;
    }
`
export const JourneyWelcomeTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`
export const JourneyWelcomeTitle = styled.h1`
    padding: 0px;
    padding-top: 24px;
    margin: 0px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 32px;
    line-height: 48px;

    @media ${device.mobile} {
        padding: 0px 24px;
        width: 100%;
        box-sizing: border-box;
        line-height: 44px;
    }

    @media (max-width: 350px) {
        font-size: 26px;
        line-height: 28px;
        padding-top: 24px;
    }
`
export const JourneyWelcomeText = styled.div`
    padding: 0px;
    margin: 0px;
    color: var(--text-darker, #616161);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    width: 599px;
    max-width: 90vw;

    @media ${device.mobile_and_ipad} {
        max-width: 550px !important;
    }

    @media ${device.ipad} {
        padding-left: 24px;
        padding-right: 24px;
    }
    @media ${device.mobile} {
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        padding: 16px 24px;
    }

    @media (max-width: 350px) {
        font-size: 14px;
    }
`

export const JourneyWelcomeImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    /* flex: 1; */
    @media ${device.mobile} {
        /* flex: 0; */
        width: 100dvw;
        overflow: hidden;
    }
`
export const JourneyWelcomeImage = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;

    @media ${device.mobile} {
        height: auto;
        width: 700px;
    }
    @media (max-height: 880px) {
        max-height: 50dvh;
    }
`
export const JourneyWelcomeButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    @media ${device.mobile_and_ipad} {
        padding: 24px;
        padding-bottom: 36px;
    }
    @media ${device.mobile} {
        width: 100%;
        box-sizing: border-box;
    }
`

export const JourneyWelcomeBtnText = styled.span`
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
    font-family: Lato-Semibold;
    line-height: 20px;

    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

export const JourneyWelcomeNewLineOnMobile = styled.br`
    display: none;
    @media ${device.mobile} {
        display: block;
    }
`

export const JourneySectionWrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`

export const JourneySectionWrapperCustom = styled.section`
    width: 100%;
    box-sizing: border-box;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
`

export const JourneyLeftSide = styled.section<IStyle>`
    width: 40%;
    box-sizing: border-box;
    max-width: 600px;
    height: 100%;
    background-color: ${(props) => colours[props.$theme].background_default};
    padding: 48px 80px 80px 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex-shrink: 0;
    overflow-y: auto;

    @media ${device.smallest_laptops} {
        padding: 48px;
    }
`

export const JourneyRightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow-y: hidden;
`

export const JourneyColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`

export const JourneyBtnContent = styled.div<IStyle>`
    display: flex;
    gap: 8px;
    height: 16px;
    justify-content: center;
    align-items: center;
    color: ${(props) => colours[props.$theme].text_darker};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 20px;
`

export const JourneyStepTitle = styled.h1<IStyle>`
    padding: 0px;
    margin: 0px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-semibold;
    font-size: 24px;
    line-height: 40px;

    @media ${device.mobile_and_ipad} {
        font-size: 22px;
        line-height: 32px;
    }
`

export const JourneyText = styled.span<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_darker};
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;

    max-width: 600px;
    @media ${device.mobile_and_ipad} {
        font-size: 16px;
        line-height: 24px;
    }
`

export const JourneyListItemTitle = styled.span<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
`

export const JourneyListItemText = styled.span<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_neutral_default_500};
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
`

export const JourneyEnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

export const JourneyRowContent = styled.div`
    display: flex;
    align-items: center;
`

export const JourneyIconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

export const JourneyRightSideSubWrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.smallest_laptops} {
        padding: 24px;
    }
`
