import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import SelectSearchBarCarInfoDesktop from '../../searchselect/desktop/selectSearchBarCarInfoDesktop'
import { IwriteMultipleTechnicalInformationPayloadReq } from 'entityModels'
import InputField from '../../../../../atoms/Inputfield/inputField'
import Expander from '../../../../../atoms/expander/expander'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
    Notice,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'
import { IRootState } from '../../../../../../redux/store'
import { technicalInformationActions } from '../../../../../../redux/entities/technical_information/reducer'

const SmallPadding = styled.div`
    padding-top: 22px;
`

const Padding16 = styled.div`
    padding-top: 16px;
`

const WarningText = styled.div`
    color: #f08080;
    font-size: 14px;
    padding-top: 5px;
    width: 100%;
    box-sizing: border-box;
    align-items: start;
    justify-content: flex-start;
    font-style: italic;
    font-family: Lato;
`

const defaultOption = [
    {
        uid: 'nodata',
        name: '-',
    },
]

interface Values {
    car_model_custom_model_desktop: string
    car_year_custom_model_desktop: any
}

interface OtherProps {
    toggle: any
    model_item: any
    year_item: any
    submitFunc?: any
    tech_info_id?: any
    make_item: any
    carid: string
}

const InnerForm = (props: OtherProps) => {
    const dispatch = useDispatch()
    const modelDropdownList = useSelector(
        (state: IRootState) => state.localdata.dropdownData.carModels
    )
    // const isLoading = useSelector(
    //     (state: IRootState) => state.localdata.dropdownData.loading
    // )
    const isLoadingNewMakeOrModel = useSelector(
        (state: IRootState) =>
            state.localdata.dropdownData.isLoadingNewMakeOrModel
    )
    const car_years_validation = useSelector(
        (state: IRootState) =>
            state.localdata.form_validation_copy.years.car_year
    )

    const { theme } = useThemes()

    const formik = useFormik<Values>({
        initialValues: {
            car_model_custom_model_desktop: props.model_item
                ? props.model_item.answer
                : '-',
            car_year_custom_model_desktop: props.year_item
                ? props.year_item.answer
                : '-',
        },
        validationSchema: Yup.lazy((values: Values) => {
            return Yup.object().shape({
                car_year_custom_model_desktop:
                    values.car_year_custom_model_desktop &&
                    values.car_year_custom_model_desktop !== '-' &&
                    car_years_validation &&
                    Yup.number()
                        .min(
                            car_years_validation.min,
                            car_years_validation.warning_min
                        )
                        .max(
                            car_years_validation.max,
                            car_years_validation.warning_max
                        ),
            })
        }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false)

            let p: IwriteMultipleTechnicalInformationPayloadReq = {
                car_id: props.carid,
                tech_info_id: props.tech_info_id,
                data: [
                    {
                        id: 'model',
                        text: 'model',
                        answer: values.car_model_custom_model_desktop,
                    },
                    {
                        id: 'year',
                        text: 'year',
                        answer: values.car_year_custom_model_desktop,
                    },
                ],
            }

            dispatch(
                technicalInformationActions.writeMultipleTechnicalInformationRequest(
                    p
                )
            )
            props.toggle()
        },
    })

    let checkIfDisabled = () => {
        if (
            !formik.values.car_model_custom_model_desktop ||
            formik.values.car_model_custom_model_desktop === '-'
        ) {
            return true
        } else if (
            formik.errors.car_model_custom_model_desktop ||
            formik.errors.car_year_custom_model_desktop
        ) {
            return true
        } else if (isLoadingNewMakeOrModel) {
            return true
        } else return false
    }

    return (
        <ModalContentDesktop
            $theme={theme}
            id="scrollable-content"
            style={{ marginTop: '15vh' }}
        >
            <ModalInnerContentWrapperDesktop>
                {props.model_item !== undefined &&
                    props.year_item !== undefined &&
                    props.make_item !== undefined && (
                        <>
                            <ModalTitle $theme={theme}>Model | Year</ModalTitle>
                            <Padding16 />
                            <Notice>
                                If you can't find your car's model, simply type
                                the model name fully, then select "
                                <span style={{ fontWeight: 'bold' }}>add</span>
                                ".
                            </Notice>
                            <Expander
                                height={
                                    props.make_item.answer === null ? 'auto' : 0
                                }
                            >
                                <WarningText>
                                    Please enter the car's make.
                                </WarningText>
                            </Expander>
                            <SmallPadding />

                            <SelectSearchBarCarInfoDesktop
                                handleSelectChange={(name: string) => {
                                    formik.setFieldValue(
                                        'car_model_custom_model_desktop',
                                        name
                                    )
                                }}
                                addAnItemFunc={(id: string, name: string) => {
                                    dispatch(
                                        dropDownActions.setIsLoadingNewMakeOrModel(
                                            true
                                        )
                                    )

                                    dispatch(
                                        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                            {
                                                dropdownTargetId: id,
                                                name: name,
                                                relevantOtherLinkedDataMakeMake:
                                                    props.make_item.answer,
                                            }
                                        )
                                    )
                                }}
                                item={props.model_item}
                                placeholder="Car Model"
                                dropdownTargetId="carModels"
                                items={
                                    modelDropdownList
                                        ? modelDropdownList
                                        : defaultOption
                                }
                                formCurrentValue={
                                    formik.values[
                                        'car_model_custom_model_desktop'
                                    ]
                                }
                                allowAdd={true}
                                isDisabled={
                                    props.make_item.answer === null
                                        ? true
                                        : false
                                }
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                            <Expander
                                height={
                                    props.make_item.answer !== null &&
                                    formik.values[
                                        'car_model_custom_model_desktop'
                                    ] === null
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <WarningText>
                                    Please enter the car's model.
                                </WarningText>
                            </Expander>
                            <SmallPadding />

                            <InputField
                                theme={theme}
                                id="car_year_custom_model_desktop"
                                name="car_year_custom_model_desktop"
                                placeholder={'year'}
                                tabindex={1}
                                aria-live="polite"
                                formikprops={formik}
                                value={
                                    formik.values.car_year_custom_model_desktop
                                }
                                font_family="Lato"
                                width="150px"
                                type="number"
                                disabled={
                                    formik.values[
                                        'car_model_custom_model_desktop'
                                    ] === null &&
                                    props.model_item.answer === null
                                        ? true
                                        : false
                                }
                                onEnterKeyPress={formik.handleSubmit}
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />

                            <Expander
                                height={
                                    formik.errors.car_year_custom_model_desktop
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <WarningText>
                                    {formik.errors.car_year_custom_model_desktop
                                        ? `${formik.errors.car_year_custom_model_desktop}`
                                        : 'error'}
                                </WarningText>
                            </Expander>
                        </>
                    )}
                <SmallPadding />
                <SmallPadding />
                <ButtonsStandardBottomRowDesktop
                    isDisabled={checkIfDisabled()}
                    cancel={props.toggle}
                    submit={formik.handleSubmit}
                    isSecondTextLoading={isLoadingNewMakeOrModel}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

export default InnerForm
