import TimelineSearchBarWDropdownDesktop from './timelineSearchBarWDropdownDesktop'
import { IDropdownItem } from 'entityModels'
import { useState } from 'react'
import Fuse from 'fuse.js'
import type { FuseResult } from 'fuse.js'
import { ITimelineEntryTypes } from '../../../../redux/timeline/types'

type Props = {
    categoriesToSearch: IDropdownItem[]
    labelsToSearch: IDropdownItem[]
    activeFilters: {
        categories: ITimelineEntryTypes[]
        labels: string[]
        titleQuery?: string
    }
    apiSearchBySelected: (payload: {
        categories: ITimelineEntryTypes[]
        labels: string[]
        entry_title?: string
    }) => void
}
const TimelineSearchBarFuseWrapperDesktop: React.FC<Props> = (props) => {
    const {
        categoriesToSearch,
        labelsToSearch,
        apiSearchBySelected,
        activeFilters,
    } = props

    const [categoriesDisplayedOptions, setCategoriesDisplayedOptions] =
        useState<IDropdownItem[]>([])
    const [labelsDisplayedOptions, setLabelsDisplayedOptions] = useState<
        IDropdownItem[]
    >([])

    const FuseOptions = {
        includeScore: true,
        keys: ['name'],
    }

    const categories_fuse_data =
        categoriesToSearch && new Fuse(categoriesToSearch, FuseOptions)

    const labels_fuse_data =
        labelsToSearch && new Fuse(labelsToSearch, FuseOptions)

    const setCategoriesAndLabelsDropdownOptions = (val: string) => {
        // categories search

        const categories_fuse_results: FuseResult<IDropdownItem>[] =
            categories_fuse_data.search(val, {
                limit: 2,
            })

        let categories_res: IDropdownItem[] = []

        categories_fuse_results.forEach((elem) => {
            if (elem.item) {
                categories_res = [...categories_res, elem.item]
            }
        })

        val
            ? setCategoriesDisplayedOptions(categories_res)
            : setCategoriesDisplayedOptions([])

        // labels search

        const labels_fuse_results: FuseResult<IDropdownItem>[] =
            labels_fuse_data.search(val, {
                limit: 5,
            })

        let labels_res: IDropdownItem[] = []

        labels_fuse_results.forEach((elem) => {
            if (elem.item) {
                labels_res = [...labels_res, elem.item]
            }
        })

        val
            ? setLabelsDisplayedOptions(labels_res)
            : setLabelsDisplayedOptions([])

        return
    }

    const onOptionClick = (
        target: 'string' | 'label' | 'category',
        value: string
    ) => {
        if (target === 'category') {
            if (
                activeFilters.categories.includes(
                    value as ITimelineEntryTypes
                ) === false
            ) {
                apiSearchBySelected({
                    categories: [
                        ...activeFilters.categories,
                        value as ITimelineEntryTypes,
                    ],
                    labels: activeFilters.labels,
                    entry_title: activeFilters.titleQuery,
                })
            }
        }
        if (target === 'label') {
            if (activeFilters.labels.includes(value) === false) {
                apiSearchBySelected({
                    categories: activeFilters.categories,
                    labels: [...activeFilters.labels, value],
                    entry_title: activeFilters.titleQuery,
                })
            }
        }
        if (target === 'string') {
            apiSearchBySelected({
                categories: activeFilters.categories,
                labels: activeFilters.labels,
                entry_title: value,
            })
        }
    }

    return (
        <TimelineSearchBarWDropdownDesktop
            categoriesInDropdown={categoriesDisplayedOptions}
            labelsInDropdown={labelsDisplayedOptions}
            fuseSearchToSetCategoriesAndLabelsDropdownOptions={
                setCategoriesAndLabelsDropdownOptions
            }
            onOptionClick={onOptionClick}
        />
    )
}

export default TimelineSearchBarFuseWrapperDesktop
