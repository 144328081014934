import styled from 'styled-components'
import {
    car_enquiry_offer_copy,
    IEnquiryOptionCopyItem,
} from '../../../redux/localdata/carEnquiryCopy'
import { IEnquiryStructureRequest } from '../../../redux/showroom/types'
import BottomBarBtnsStep from '../../atoms/Button/bottomBarBtnsStep'
import EditAddLineBtn from '../../atoms/Button/EditAddLineBtn'
import Expander from '../../atoms/expander/expander'
import RadioRowItem from '../../atoms/formField/radioRowItem'

import InputFieldNoFormikAnimated from '../../atoms/Inputfield/inputFieldNoFormikAnimated'
import EnquiryStepTitle from '../../atoms/typography/showroom/enquiryStepTitle'
import {
    EnquiryBtnsOnlyMobile,
    EnquityFormPaddingBottomDesktop,
} from './carEnquiryInfoForm'
import EnquiryCustomMessageText from './enquiryCustomMessageText'
import { device } from '../../templates/displays/devices'
import { useNavigate } from 'react-router-dom'

const CurrencySign = styled.p`
    all: unset;
    font-size: 'Lato-light';
    font-size: 16px;
    position: absolute;
    left: 14px;
    top: 14px;
    color: #616161;
    @media ${device.mobile} {
        top: 12px;
    }
`

export type ICarEnquiry_Offer_FormManager_props = {
    handleOfferChange: (id: string, nvalue?: number) => void
    setIsEditorOpen: (p: boolean) => void
    enquiryFormData: IEnquiryStructureRequest
    manageStep: (p: 'contact' | 'enquiry') => void
    set_price?: string
    listing_currency: string
}
const CarEnquiryOfferFormMobile = (
    props: ICarEnquiry_Offer_FormManager_props
) => {
    const {
        handleOfferChange,
        setIsEditorOpen,
        enquiryFormData,
        manageStep,
        listing_currency,
    } = props

    let activeItem = enquiryFormData.enquiry.offer?.selected_offer

    const checkIfBtnISDisabled = () => {
        if (
            enquiryFormData.enquiry.type === 'offer' &&
            enquiryFormData.enquiry.offer &&
            !enquiryFormData.enquiry.offer.selected_offer &&
            !enquiryFormData.enquiry.offer.personal_offer_amount
        ) {
            return true
        }
        return false
    }

    const navigate = useNavigate()

    return (
        <div style={{ width: '100%' }}>
            <EnquiryStepTitle>{car_enquiry_offer_copy.text}</EnquiryStepTitle>

            <div style={{ paddingTop: '20px' }} />
            <div style={{ width: '100%' }}>
                {car_enquiry_offer_copy.options.map(
                    (item: IEnquiryOptionCopyItem) => {
                        return (
                            <RadioRowItem
                                name={item.id}
                                text={
                                    item.id === `asking_price_offer`
                                        ? `${item.name} ${props.set_price}`
                                        : `${item.name}`
                                }
                                onChange={() => {
                                    handleOfferChange(item.id)
                                }}
                                checked={
                                    enquiryFormData.enquiry.offer
                                        ?.selected_offer === item.id
                                        ? true
                                        : false
                                }
                            />
                        )
                    }
                )}
            </div>

            <Expander
                height={activeItem === 'personal_offer_amount' ? 'auto' : 0}
            >
                <div style={{ paddingBottom: '30px', position: 'relative' }}>
                    <InputFieldNoFormikAnimated
                        placeholder="Your Offer"
                        name="Your Offer"
                        value={
                            enquiryFormData.enquiry.offer?.personal_offer_amount
                        }
                        id="personal_offer_amount"
                        onChange={(e: any) => {
                            if (e.target.value && +e.target.value) {
                                handleOfferChange(
                                    'personal_offer_amount',
                                    +e.target.value
                                )
                            } else {
                                handleOfferChange('personal_offer_amount', 0)
                            }
                        }}
                        paddingLeft="32px"
                    />
                    <CurrencySign>
                        {listing_currency === 'GBP'
                            ? '£'
                            : listing_currency === 'USD'
                            ? '$'
                            : listing_currency === 'EUR'
                            ? '€'
                            : '£'}
                    </CurrencySign>
                </div>
            </Expander>

            <div style={{ paddingTop: '10px' }} />
            <EditAddLineBtn
                theme={
                    enquiryFormData.enquiry.offer?.custom_message
                        ? 'edit-icon-primary-text'
                        : 'add-icon-primary-text'
                }
                onClick={() => {
                    setIsEditorOpen(true)
                }}
                width="100%"
                fontSize={16}
            >
                {enquiryFormData.enquiry.offer?.custom_message
                    ? 'Edit custom message'
                    : 'Add custom message'}
            </EditAddLineBtn>

            {enquiryFormData.enquiry.offer?.custom_message ? (
                <EnquiryCustomMessageText
                    onClick={() => {
                        setIsEditorOpen(true)
                    }}
                    text={enquiryFormData.enquiry.offer?.custom_message}
                />
            ) : null}

            <EnquityFormPaddingBottomDesktop />
            <EnquiryBtnsOnlyMobile>
                <BottomBarBtnsStep
                    cancel={() => {
                        navigate(`/showroom/${enquiryFormData.entry_id}`)
                    }}
                    submit={() => {
                        manageStep('contact')
                    }}
                    confirmationtext="Continue"
                    disabled={checkIfBtnISDisabled()}
                />
            </EnquiryBtnsOnlyMobile>
        </div>
    )
}

export default CarEnquiryOfferFormMobile
