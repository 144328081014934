import { removeUndefinedfromObject } from '../../../helpers/objects'
import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'
import { IApiEntryCREATE_args } from '../../types'

export const createEntry = (p: IApiEntryCREATE_args): Promise<any> => {
    // run removeUndefined func here (move from down)
    let newEntryDataNoUndefined = removeUndefinedfromObject(p.newEntryData)

    let newEntryDataFinal: string = JSON.stringify(newEntryDataNoUndefined)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: newEntryDataFinal,
        credentials: 'include',
    }

    return fetch(urls.create_entry_endpoint(p.car_id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create entry error',
                            newEntryDataFinal
                        )
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
