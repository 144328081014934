import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'

const ModalWrapper = styled.div<IStyle>`
    @media ${device.desktop} {
        display: none;
    }

    background-color: var(
        --background_neutral_backdrop_70,
        rgba(26, 26, 26, 0.7)
    );
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    top: 0;
    bottom: 0;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    animation: 0.1s fadeIn both;
    animation-delay: 0s;
    z-index: ${(props) => (props.isOpen ? '6' : '-2')};
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    justify-content: flex-end;
    flex-direction: column;
    padding: 0px;
`

const MenuItemWrap = styled.div<IStyle>`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    padding-bottom: calc(env(safe-area-inset-bottom) + 80px);
    /* transform: translateY(100%); */

    @keyframes slideUp {
        from {
            transform: translateY(100%);
            opacity: 0;
        }

        to {
            transform: translateY(0%);
            opacity: 1;
        }
    }

    animation: slideUp 0.3s cubic-bezier(0.87, 0, 0.13, 1) both;
    animation-delay: 0.05s;

    /* opacity: ${(props) => (props.isOpen ? '1' : '0')};
    transform: ${(props) =>
        props.isOpen ? 'translateY(0px)' : 'translateY(470px)'};
    transition: opacity 100ms, z-index 0ms, transform 100ms;*/
    z-index: ${(props) => (props.isOpen ? '6' : '-2')};
    border: none;

    @media ${device.ipad} {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`

const MenuItem = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px 40px;

    color: var(--text-default, #666666);
    font-family: Lato;
    font-size: 16px;
    text-transform: capitalize;
`

const Handle = styled.div`
    width: 60px;
    height: 4px;
    border-radius: 5px;
    background: var(--text-muted, #b3b3b3);
`
const HandleWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
`

const Header = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 40px 16px 40px;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
`
const HeaderText = styled.span`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 16px;
    line-height: 24px;
    word-break: break-all;
`

type IStyle = {
    isOpen?: boolean
    isDelete?: boolean
}

type IItem = {
    text: string
    id: string
    action: any
    icon?: React.ReactNode
    color?: string
}

interface IProps {
    toggle: any
    isOpen: boolean
    items: IItem[]
    dataCyPrefix?: string
    header?: string
    customMenuItem?: React.ReactNode
    customMenuItemIndex?: number
}

const PageSubMenuActions: React.FunctionComponent<IProps> = (props) => {
    const {
        toggle,
        isOpen,
        items,
        dataCyPrefix,
        header,
        customMenuItem,
        customMenuItemIndex,
    } = props

    return (
        <Faded>
            <ModalWrapper
                isOpen={isOpen}
                onClick={(e: any) => {
                    e.preventDefault()
                    setTimeout(function () {
                        toggle()
                    }, 50)
                }}
            />

            <MenuItemWrap isOpen={isOpen}>
                <HandleWrapper>
                    <Handle />
                </HandleWrapper>
                {header ? (
                    <Header>
                        <HeaderText>{header}</HeaderText>
                    </Header>
                ) : null}
                {items.map((item: any, index: any) => {
                    if (
                        customMenuItem &&
                        customMenuItemIndex &&
                        index === customMenuItemIndex
                    ) {
                        return (
                            <>
                                <div
                                    key={items.length + 1}
                                    style={{ width: '100%' }}
                                >
                                    {customMenuItem}
                                </div>
                                <MenuItem
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        setTimeout(function () {
                                            item.action()
                                            toggle()
                                        }, 50)
                                    }}
                                    key={index}
                                    data-attr={`${dataCyPrefix}-${item.id}-action`}
                                    style={
                                        item.color ? { color: item.color } : {}
                                    }
                                >
                                    {item.icon ?? null}
                                    {item.text}
                                </MenuItem>
                            </>
                        )
                    } else {
                        return (
                            <MenuItem
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    setTimeout(function () {
                                        item.action()
                                        toggle()
                                    }, 50)
                                }}
                                key={index}
                                data-attr={`${dataCyPrefix}-${item.id}-action`}
                                style={item.color ? { color: item.color } : {}}
                            >
                                {item.icon ?? null}
                                {item.text}
                            </MenuItem>
                        )
                    }
                })}
                {customMenuItem && !customMenuItemIndex ? (
                    <div key={items.length + 1} style={{ width: '100%' }}>
                        {customMenuItem}
                    </div>
                ) : null}
            </MenuItemWrap>
        </Faded>
    )
}

export default PageSubMenuActions
