import Fuse from 'fuse.js'

export const createSearchableIndex = (list: any[], keys: string[]) => {
    const options = {
        includeScore: true,
        keys: keys,
    }
    const fuse = new Fuse(list, options)
    return fuse
}

export const searchResults = (
    fuse: any,
    termToSearch: string,
    onlyFirstResult?: boolean
) => {
    const result = fuse.search(termToSearch)

    if (onlyFirstResult) {
        return result[0]
    } else return result
}
