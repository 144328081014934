import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import ReadOnlyCarGallery from '../../../templates/readOnly/car/readOnlyGallery'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { IExternalCar } from '../../../../redux/entities/cars/types'

const ExternalCarGallery: React.FC = () => {
    const dispatch = useAppDispatch()

    const { carid } = useParams<{ carid: string }>()

    const { userLoggedIn, externalCarsData } = useAppSelector((state) => ({
        userLoggedIn: state.user.userLoggedIn,
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
        images_data: state.entities.galleriesData.images,
        isNecesarryDataLoading: state.entities.carsData.loading,
    }))

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (carid) {
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/shared/car/${carid}/gallery`
                )
            )
            dispatch(carActions.setCurrentExternalCarSuccess(carid))
            dispatch(carActions.getExternalCarsSharedWithYouRequest(carid))
        }
    }, [userLoggedIn, carid])

    const current: IExternalCar | undefined =
        externalCarsData && carid ? externalCarsData[carid] : undefined

    return (
        <React.Fragment>
            {current && userLoggedIn && (
                <ReadOnlyCarGallery
                    sharedCar={current}
                    userLoggedIn={userLoggedIn}
                    hasPaddingTop
                />
            )}
        </React.Fragment>
    )
}

export default ExternalCarGallery
