import { GoogleLogin, CredentialResponse } from '@react-oauth/google'
import { useAppDispatch } from '../../../../../redux/store/hooks'
import { SocialSignContainer } from '../apple/appleSigninButton'
import '../styles.css'
import * as Sentry from '@sentry/browser'
import { ConvertToReduxError } from '../../../../../redux/conversions/errors/convertToReduxError'
import useThemes from '../../../../../providers/theme/hooks'
import { usersActions } from '../../../../../redux/user/reducer'
import { IReduxError } from '../../../../../redux/entities/cars/types'

type Props = {
    isValidToken?: boolean
    platform_consent?: boolean
    apex_consent?: boolean
    fbclid?: string
    next_path?: string
    is_registration: boolean
    normal_height?: string
    from_landing?: boolean
}

export const GoogleAuthButton = (props: Props) => {
    const dispatch = useAppDispatch()
    let {
        is_registration,
        platform_consent,
        apex_consent,
        fbclid,
        next_path,
        from_landing,
    } = props

    const { theme } = useThemes()

    return (
        <SocialSignContainer
            normal_height={props.normal_height ?? undefined}
            id="google_btn"
        >
            <GoogleLogin
                // className="apple-auth-btn"
                theme={theme === 'dark' ? 'filled_black' : undefined}
                // containerProps={{
                //     style: {
                //         backgroundColor: `${colours[theme].background_default}`,
                //         border: `1px solid ${colours[theme].border_muted}`,
                //         color: colours[theme].text_strong,
                //     },
                // }}
                width={'100%'}
                data-ph={'continue_with_google'}
                size="large"
                text="continue_with"
                shape="rectangular"
                logo_alignment="center"
                onSuccess={(response: CredentialResponse) => {
                    dispatch(usersActions.resetErrorStateSuccess())

                    if (is_registration) {
                        dispatch(
                            usersActions.socialAuthRequest({
                                platformConsent: platform_consent
                                    ? true
                                    : false,
                                apexConsent: apex_consent ? true : false,
                                fbclid: fbclid ? `${fbclid}` : null,
                                authorization_code: undefined,
                                id_token: response.credential,
                                next_path: next_path,
                                provider: 'google',
                                is_registration: true,
                                from_landing: from_landing ? true : false,
                            })
                        )
                    } else {
                        dispatch(
                            usersActions.socialAuthRequest({
                                platformConsent: platform_consent
                                    ? true
                                    : false,
                                apexConsent: apex_consent ? true : false,
                                fbclid: fbclid ? `${fbclid}` : null,
                                authorization_code: undefined,
                                next_path: next_path,
                                provider: 'google',
                                is_registration: false,
                                from_landing: false,
                                id_token: response.credential,
                            })
                        )
                    }
                    // No need to handle redirection here because i have redirectURI above
                }}
                onError={() => {
                    Sentry.captureMessage(`Google auth failed`)

                    let typedError: IReduxError = ConvertToReduxError(
                        { status: 400 },
                        {
                            custom_message: 'Google signin error',
                        }
                    )
                    dispatch(usersActions.signInUserError(typedError))
                }}
            />
        </SocialSignContainer>
    )
}
