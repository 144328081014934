import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IDeleteEntryGalleryImagePayload,
    IGalleriesState,
    IGalleryImage,
    IGalleryImagesObject,
    IUpdateCarGalleryImagePayload,
    IUpdateEntryGalleryImagePayload,
} from './types'

const initialState: IGalleriesState = {
    images: {},
    presignedurls: null,
    error: null,
    loading: false,
}

type Iids = {
    carid: string
    imageid: string
}

const galleriesSlice = createSlice({
    name: 'galleries',
    initialState,
    reducers: {
        addToGalleryImageSuccess: (
            state,
            action: PayloadAction<IGalleryImage[]>
        ) => {
            let new_images_obj = { ...state.images }
            for (let i = 0; i < action.payload.length; i++) {
                if (!new_images_obj[action.payload[i].id]) {
                    new_images_obj[action.payload[i].id] = action.payload[i]
                }
            }
            state.images = new_images_obj
            state.loading = false
            state.error = null
        },

        deleteCarGalleryImageRequest: (state, action: PayloadAction<Iids>) => {
            state.loading = true
            state.error = null
        },

        deleteCarGalleryImageError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },

        deleteCarGalleryImageSuccess: (
            state,
            action: PayloadAction<string>
        ) => {
            state.error = null
            state.loading = false
            if (state.images !== null) {
                delete state.images[action.payload]
            }
        },

        updateCarGalleryImageRequest: (
            state,
            action: PayloadAction<IUpdateCarGalleryImagePayload>
        ) => {
            state.loading = true
            state.error = null
        },

        updateCarGalleryImageError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },

        updateCarGalleryImageSuccess: (
            state,
            action: PayloadAction<IGalleryImage>
        ) => {
            state.loading = false
            state.error = null
            if (state.images !== null) {
                state.images[action.payload.id] = action.payload
            }
        },

        getGalleryImagesRequest: (state) => {
            state.loading = true
            state.error = null
        },

        getGalleryImagesError: (state, action: PayloadAction<any>) => {
            state.loading = true
            state.error = action.payload
        },

        getGalleryImagesSuccess: (
            state,
            action: PayloadAction<IGalleryImagesObject>
        ) => {
            state.images = {
                ...state.images,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        setGalleryImagesRequest: (state) => {
            state.loading = true
            state.error = null
        },

        setGalleryImagesError: (state, action: PayloadAction<any>) => {
            state.loading = true
            state.error = action.payload
        },

        setGalleryImagesSuccess: (
            state,
            action: PayloadAction<IGalleryImagesObject>
        ) => {
            state.images = {
                ...state.images,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        deleteEntryGalleryImageRequest: (
            state,
            action: PayloadAction<IDeleteEntryGalleryImagePayload>
        ) => {
            state.loading = true
            state.error = null
        },

        deleteEntryGalleryImageError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },

        deleteEntryGalleryImageSuccess: (
            state,
            action: PayloadAction<string>
        ) => {
            state.error = null
            state.loading = false
            if (state.images !== null) {
                delete state.images[action.payload]
            }
        },

        updateEntryGalleryImageRequest: (
            state,
            action: PayloadAction<IUpdateEntryGalleryImagePayload>
        ) => {
            state.loading = true
            state.error = null
        },

        updateEntryGalleryImageError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },

        updateEntryGalleryImageSuccess: (
            state,
            action: PayloadAction<IGalleryImage>
        ) => {
            state.error = null
            state.loading = false
            if (state.images !== null) {
                state.images[action.payload.id] = action.payload
            }
        },

        cleanUpGallerySuccess: (state) => {
            state.images = {}
            state.presignedurls = null
            state.error = null
            state.loading = false
        },
    },
})

export const galleriesActions = galleriesSlice.actions

export default galleriesSlice.reducer

export type GalleriesState = ReturnType<typeof galleriesSlice.reducer>
