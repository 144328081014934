import { IApexInterviewOverviewItemState } from 'apexModels'
import React from 'react'
import ApexInterviewOverviewItemDesktop from './apexInterviewOverviewItemDesktop'

type Props = {
    interview_overviews: IApexInterviewOverviewItemState[]
}

const ApexInterviewOverviewsManagerDesktop: React.FC<Props> = ({
    interview_overviews,
}) => {
    return (
        <>
            {interview_overviews.map(
                (item: IApexInterviewOverviewItemState, index: number) => {
                    return (
                        <div key={`interview_${index}-desktop`}>
                            <ApexInterviewOverviewItemDesktop
                                interview_overview={item}
                            />
                        </div>
                    )
                }
            )}
        </>
    )
}

export default ApexInterviewOverviewsManagerDesktop
