import { IDropdownItem } from 'entityModels'
import styled from 'styled-components'
import Expander from '../../../../atoms/expander/expander'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import SelectSearchBarCarInfoDesktop from '../../../../organisms/editForms/technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import { device } from '../../../../templates/displays/devices'

import {
    IShowroomOwnerBasicDetails,
    IShowroomOwnerBasicDetailsString,
} from './ownerBasicDetailsManagerDesktop'
import { ErrorText } from '../../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerDesktop'
import PhoneInputNew from '../../../../organisms/phonefield/phoneInputNew'
import { Select } from '../../../../atoms/select'
import { useState } from 'react'
import { IInsuranceDropdownItem } from '../../../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'
import { useAppSelector } from '../../../../../redux/store/hooks'

const Wrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        display: none;
    }

    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
    }
`

type Props = {
    close: () => any
    formData: IShowroomOwnerBasicDetails
    isCountryChangedFromSearch: boolean
    handleChange: (value: string, id: IShowroomOwnerBasicDetailsString) => any
    handleSelectCountry: (answer: string) => any
    isFormValid: () => boolean
    countriesOptions: IDropdownItem[] | null
    error?: { [key: string]: string | undefined }
}

const SearchField = styled.input`
    background-color: var(
        --base-overlays-neutral-alpha-50,
        rgba(26, 26, 26, 0.02)
    );
    height: 50px;
    min-height: 50px;
    border-radius: 5px;
    border: solid 1px var(--text-muted, #b3b3b3);
    padding-left: 15px;
    width: 100%;
    box-sizing: border-box;
`

const OwnerBasicDetailsFormDesktop = (props: Props) => {
    let {
        formData,
        handleChange,
        handleSelectCountry,
        isCountryChangedFromSearch,
        countriesOptions,
        error,
    } = props

    let usaAdminCodes: IInsuranceDropdownItem[] | null = useAppSelector(
        (state) => {
            return state.localdata.dropdownData.usaStatesCodes
        }
    )

    const [isAdmin1Open, setIsAdmin1Open] = useState<boolean>(false)

    return (
        <Wrapper>
            <div style={{ paddingTop: '20px' }} />

            <InputFieldNoFormikAnimated
                name="Name"
                type="text"
                id="name"
                placeholder="Name"
                value={formData.name}
                onChange={(e: any) => {
                    props.handleChange(e.target.value, 'name')
                }}
                onEnterKeyPress={handleChange}
                tabindex={1}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
            <Expander
                height={error && error['name'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['name']}</ErrorText>
            </Expander>

            <div style={{ paddingTop: '20px' }} />

            <PhoneInputNew
                value={formData.phone ? formData.phone : ''}
                handleChangeNumber={(phone: string) => {
                    props.handleChange(`${phone}`, 'phone')
                }}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />

            <Expander
                height={error && error['phone'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['phone']}</ErrorText>
            </Expander>

            <div style={{ paddingTop: '20px' }} />
            <SearchField
                id="autocomplete_showroom_owner_address_desktop"
                placeholder="Search your address"
                type="text"
                autoFocus
            />

            <div style={{ paddingTop: '30px' }} />

            <InputFieldNoFormikAnimated
                name="Street number  /  House name"
                type="text"
                id="street_number"
                placeholder=""
                value={formData.street_number}
                onChange={(e: any) => {
                    props.handleChange(e.target.value, 'street_number')
                }}
                onEnterKeyPress={handleChange}
                tabindex={1}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
            <Expander
                height={error && error['street_number'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['street_number']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name="Street address"
                type="text"
                id="route"
                placeholder=""
                value={formData.route}
                onChange={(e: any) => {
                    props.handleChange(e.target.value, 'route')
                }}
                onEnterKeyPress={handleChange}
                tabindex={2}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
            <Expander
                height={error && error['route'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['route']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name="City"
                type="text"
                id="postal_town"
                placeholder=""
                value={formData.postal_town}
                onChange={(e: any) => {
                    props.handleChange(e.target.value, 'postal_town')
                }}
                onEnterKeyPress={handleChange}
                tabindex={3}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
            <Expander
                height={error && error['postal_town'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['postal_town']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name="Postcode"
                type="text"
                id="postal_code"
                placeholder=""
                value={formData.postal_code}
                onChange={(e: any) => {
                    if (e.target.value) {
                        e.target.value = `${e.target.value}`.toUpperCase()
                    }

                    props.handleChange(e.target.value, 'postal_code')
                }}
                onEnterKeyPress={handleChange}
                tabindex={4}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
            <Expander
                height={error && error['postal_code'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['postal_code']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <Expander
                height={formData.country === 'United States' ? 'auto' : 0}
                width={'100%'}
            >
                <div style={{ width: '100%' }}>
                    <Select
                        value={formData.administrative_area_level_1}
                        options={usaAdminCodes ?? []}
                        defaultValue={formData.administrative_area_level_1}
                        placeholder={'Administrative Area Code'}
                        disabled={false}
                        onChange={(val: string) => {
                            props.handleChange(
                                val,
                                'administrative_area_level_1'
                            )
                        }}
                        onFocusToggle={(p: boolean) => {
                            setIsAdmin1Open(p)
                        }}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    {/* <InputFieldNoFormikAnimated
                        type="text"
                        placeholder=""
                        name="admninistrative area"
                        value={formData.administrative_area_level_1}
                        onChange={(e: any) => {
                            props.handleChange(
                                e.target.value,
                                'administrative_area_level_1'
                            )
                        }}
                        onEnterKeyPress={handleChange}
                        isNarrow={true}
                        tabindex={5}
                        width={'100%'}
                    /> */}

                    <div style={{ paddingTop: '24px' }} />
                </div>
            </Expander>

            <div
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    zIndex: isAdmin1Open ? -1 : 'auto',
                }}
            >
                <SelectSearchBarCarInfoDesktop
                    items={countriesOptions ?? []}
                    item={{
                        text: 'countries',
                        answer: formData.country ? formData.country : null,
                    }}
                    handleSelectChange={handleSelectCountry}
                    valueUpdatedFromExternalSource={
                        isCountryChangedFromSearch ? formData.country : null
                    }
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
            </div>
        </Wrapper>
    )
}

export default OwnerBasicDetailsFormDesktop
