import { useNavigate, useLocation } from 'react-router-dom'
// import JourneyTopBar from '../../templates/bars/journey/topBar'
import { JourneyPageBackground } from '../../templates/windowPageTemplate/journey/pageBackground'

import { IJourneyInsuranceStepID } from 'journeyInterfaces'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { useEffect, useState } from 'react'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import InsuranceJourneyStepsManager from '../../organisms/journey/insurance/stepManager'
import { usersActions } from '../../../redux/user/reducer'

export default function InsuranceJourney() {
    const navigate = useNavigate()
    const location = useLocation()
    let params = new URLSearchParams(location.search)
    let step: string | null = params.get('step')

    useEffect(() => {
        window?.scrollTo(0, 0)
    }, [step])

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const [originPath, setOriginPath] = useState<string>('')

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl('/journey/insurance')
        )

        let locationState: any = location.state

        if (locationState?.prevPath) {
            if (locationState?.prevPath.includes(`step=achieved`)) {
                setOriginPath(`/garage`)
            } else {
                setOriginPath(locationState?.prevPath)
            }
        } else {
            setOriginPath(`/garage`)
        }
    }, [])

    useEffect(() => {
        if (userLoggedIn) {
        } else {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    return (
        <JourneyPageBackground
            sectionID="insurance"
            hasBlurredBg={step === 'welcome' || isMobile ? true : false}
            hasLinearBgColour={
                isMobile ? true : step && step === 'welcome' ? false : true
            }
            hasPattern={isMobile ? (step === 'welcome' ? true : false) : true}
        >
            {/* <JourneyTopBar
                sectionID="insurance"
                onClose={() => {
                    return navigate(`/garage`)
                }}
            /> */}
            <InsuranceJourneyStepsManager
                stepID={step as IJourneyInsuranceStepID}
                startJourney={() => {
                    return navigate(
                        {
                            pathname: `/journey/insurance`,
                            search: `?step=join`,
                        },
                        {
                            state: {
                                prevPath: `${location.pathname}${
                                    location.search ?? ''
                                }`,
                            },
                        }
                    )
                }}
                exitJourney={() => {
                    return navigate(originPath)
                }}
                user={userLoggedIn}
            />
        </JourneyPageBackground>
    )
}
