import { IGalleryImage } from '../../entities/galleries/types'
import { IPayloadCarGalleryImageFromApi } from '../../services/typedefinitions/apiPayloads'

export const ConvertRawToNewImageEntity = (
    img: IPayloadCarGalleryImageFromApi
): IGalleryImage => {
    let image_res: IGalleryImage = {
        id: img.uid,
        url: img.large_src ? img.large_src : img.url,
        small_srcset: img.small_srcset && img.small_srcset,
        medium_srcset: img.medium_srcset && img.medium_srcset,
        large_srcset: img.large_srcset && img.large_srcset,
        large_src: img.large_src && img.large_src,
        uploader:
            img.uploader &&
            `${img.uploader.given_name ? img.uploader.given_name : ''} ${
                img.uploader.family_name ? img.uploader.family_name : ''
            }`,
        caption: img.caption,
        location: img.location_data ? img.location_data.name : '',
        credits: img.credit_data ? img.credit_data.name : '',
        fileName: img.filename && img.filename,
        cover: img.cover && img.cover,
        featured: img.featured && img.featured,
    }

    return image_res
}
