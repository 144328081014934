import { IDropdownItem } from 'entityModels'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import {
    IAnyObject,
    IAnyErrorString,
    insuranceActions,
    IInsuranceQuoteApplication,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import {
    fields_with_date_validations_ids,
    fields_with_date_validations_data,
    IsInsuranceApplicationDateValid_result,
    isInsuranceApplicationDateValid,
} from '../../../../../../redux/localdata/insuranceLocalData/datesValidation'
import { insurance_fields_main_driver_track_record } from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import InsuranceApplicationMobileSearch from '../../../../../pages/insurance_quote/flows/search/mobileSearch'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import PortalInvisibleWrapper from '../../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import { ISubfieldsValidationDraftCheck } from './additionalDetails'
import RemoveMobileSearchAnchor from '../../../../../templates/insurance/removeMobileSearchAnchor'
import colours from '../../../../../../providers/theme/colours'
import useThemes from '../../../../../../providers/theme/hooks'

type Props = {
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const InsuranceMainDriverTrackRecordApplicationFlow = (props: Props) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    let userid = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.main_driver?.id
    })

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.main_driver
                ?.track_record ?? {}
        )
    })

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.main_driver?.track_record ??
            {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.main_driver
            ?.track_record_errors
    })

    const entitiesErrNormalised = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.main_driver &&
            state.insuranceQuoteApplication.draft.main_driver.entities_error
            ? state.insuranceQuoteApplication.draft.main_driver.entities_error
            : undefined
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_main_driver_trackRecord({
                id: id,
                error: error,
                userid: `${userid}`,
            })
        )
    }

    const subfield_to_check_bool_and_data_obj_check: ISubfieldsValidationDraftCheck =
        {
            has_any_previous_insurance_refusals: {
                required_fields_if_true: [
                    'has_previous_special_terms_imposed',
                    'has_previous_motor_insurance_refused',
                    'has_previous_motor_insurance_cancelled',
                    'has_previous_motor_insurance_voided',
                ],
            },
            has_previous_special_terms_imposed: {
                required_fields_if_true: [
                    'previous_special_terms_imposed_reason',
                ],
            },
            has_previous_motor_insurance_refused: {
                required_fields_if_true: [
                    'previous_motor_terms_refused_reason',
                ],
            },
            has_previous_motor_insurance_cancelled: {
                required_fields_if_true: [
                    'previous_motor_insurance_cancelled_reason',
                ],
            },
            has_previous_motor_insurance_voided: {
                required_fields_if_true: [
                    'previous_motor_insurance_voided_reason',
                ],
            },

            has_motoring_convictions: {
                required_entity_if_true: {
                    name: 'motoring conviction',
                    uid: 'motoring_convictions',
                },
            },

            has_convictions: {
                required_entity_if_true: {
                    name: 'conviction',
                    uid: 'convictions',
                },
            },

            has_claims: {
                required_entity_if_true: {
                    name: 'claim',
                    uid: 'claims',
                },
            },
        }

    const validateEntities = (): boolean => {
        let hasErr = false
        let idss = Object.keys(subfield_to_check_bool_and_data_obj_check)

        for (let i = 0; i < idss.length; i++) {
            let key = idss[i]
            if (data_draft[key] === true || data_draft[key]?.name === 'Yes') {
                if (
                    subfield_to_check_bool_and_data_obj_check[key] &&
                    subfield_to_check_bool_and_data_obj_check[key]
                        .required_fields_if_true
                ) {
                    for (
                        let a = 0;
                        a <
                        subfield_to_check_bool_and_data_obj_check[key]!
                            .required_fields_if_true!.length;
                        a++
                    ) {
                        let required_field_id =
                            subfield_to_check_bool_and_data_obj_check[key]!
                                .required_fields_if_true![a]

                        if (data_draft[required_field_id] === undefined) {
                            hasErr = true
                            setError(required_field_id, 'Please specify.')
                        }
                    }
                }

                if (
                    subfield_to_check_bool_and_data_obj_check[key] &&
                    subfield_to_check_bool_and_data_obj_check[key]
                        .required_entity_if_true
                ) {
                    let entityUID =
                        subfield_to_check_bool_and_data_obj_check[key]!
                            .required_entity_if_true!.uid

                    let entityName =
                        subfield_to_check_bool_and_data_obj_check[key]!
                            .required_entity_if_true!.name

                    // @ts-ignore
                    if (entitiesObjArr[entityUID]?.length === 0) {
                        hasErr = true
                        setError(key, `Please add the ${entityName} below.`)
                    }

                    if (
                        // @ts-ignore
                        entitiesObjArr[entityUID] &&
                        // @ts-ignore
                        entitiesObjArr[entityUID][0]
                    ) {
                        // @ts-ignore
                        const obj = entitiesObjArr[entityUID][0]
                        let entityKeys = Object.keys(obj)

                        if (entityKeys.length === 0) {
                            hasErr = true
                            setError(key, `Please add the ${entityName} below.`)
                        }

                        if (obj[entityKeys[0]] === undefined) {
                            hasErr = true
                            setError(
                                key,
                                `Please fill in all the ${entityName} fields.`
                            )
                        }
                    }

                    if (
                        // @ts-ignore
                        entitiesObjArr[entityUID]
                    ) {
                        // @ts-ignore
                        let arrayOfObjToCheck = [...entitiesObjArr[entityUID]]

                        arrayOfObjToCheck.map((obj, i) => {
                            let objTocheck = { ...obj }

                            // date fielf check here

                            fields_with_date_validations_ids.forEach(
                                (fieldDateID) => {
                                    if (objTocheck[fieldDateID]) {
                                        let validation_depends_on_field_id =
                                            fields_with_date_validations_data[
                                                fieldDateID
                                            ]?.validation_depends_on_field_id

                                        let expected_value_of_dependable_field_for_validation =
                                            fields_with_date_validations_data[
                                                fieldDateID
                                            ]
                                                ?.expected_value_of_dependable_field_for_validation

                                        let value_of_dependable_field =
                                            validation_depends_on_field_id
                                                ? objTocheck[
                                                      validation_depends_on_field_id
                                                  ] !== undefined
                                                    ? objTocheck[
                                                          validation_depends_on_field_id
                                                      ]?.uid
                                                        ? objTocheck[
                                                              validation_depends_on_field_id
                                                          ].uid
                                                        : objTocheck[
                                                              validation_depends_on_field_id
                                                          ]
                                                    : undefined
                                                : undefined

                                        let res: IsInsuranceApplicationDateValid_result =
                                            isInsuranceApplicationDateValid({
                                                fieldID: fieldDateID,
                                                entity: objTocheck,
                                                valueToCheck:
                                                    objTocheck[fieldDateID],
                                                value_of_dependable_field:
                                                    value_of_dependable_field,
                                                expected_value_of_dependable_field_for_validation:
                                                    expected_value_of_dependable_field_for_validation !==
                                                    undefined
                                                        ? //@ts-ignore
                                                          expected_value_of_dependable_field_for_validation.uid
                                                            ? //@ts-ignore
                                                              expected_value_of_dependable_field_for_validation.uid
                                                            : expected_value_of_dependable_field_for_validation
                                                        : undefined,
                                            })
                                        if (res.isValid === false) {
                                            hasErr = true
                                            setError(
                                                key,
                                                res.error_txt ?? 'Invalid'
                                            )
                                        }
                                    }
                                }
                            )

                            if (objTocheck[`sentence`] === undefined) {
                                delete objTocheck[`sentence`]
                            }
                            if (objTocheck[`driving_ban`] === undefined) {
                                delete objTocheck[`driving_ban`]
                            }

                            if (
                                objTocheck[`status`] &&
                                objTocheck[`status`].uid !== 'SETTLED' &&
                                objTocheck[`settled_date`] === undefined
                            ) {
                                delete objTocheck[`settled_date`]
                            }

                            if (
                                Object.values(objTocheck).some(
                                    (v) => v === undefined
                                )
                            ) {
                                hasErr = true
                                setError(
                                    key,
                                    `Please fill in all the ${entityName} fields.`
                                )
                            }
                        })
                    }
                }
            }
        }

        return hasErr
    }

    const entitiesObjArr = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.main_driver &&
            state.insuranceQuoteApplication.draft.main_driver.entities
            ? state.insuranceQuoteApplication.draft.main_driver.entities
            : undefined
    })

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (
            let i = 0;
            i < insurance_fields_main_driver_track_record.length;
            i++
        ) {
            if (
                insurance_fields_main_driver_track_record[i].is_required ===
                true
            ) {
                if (
                    data_draft[
                        insurance_fields_main_driver_track_record[i].id
                    ] === undefined
                ) {
                    setError(
                        insurance_fields_main_driver_track_record[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
            }
        }

        let hasEntityErr = validateEntities()

        if (hasEntityErr === true) {
            hasErr2 = true
        }

        return hasErr2
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let {
        setInitialInsurance_Drivers_TrackRecord_dropdowns,
        set_wizard_entity_id_to_skip,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    useEffect(() => {
        // to remove from entity id to skip if user click on it again though
        set_wizard_entity_id_to_skip(`${applicationData?.main_driver?.id}`)
        setInitialInsurance_Drivers_TrackRecord_dropdowns()
    }, [userLoggedIn])

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')
    let entity = queryParams.get('entity')
    let entity_index = queryParams.get('entity_index')

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const goPrevious = () => {
        navigate(
            `/insurance/application/${applicationData?.id}/wizard?section_id=main_policy_holder&subsection_id=additional_details`
        )
    }

    const goNext = () => {
        let ids2 = getInitialSectionWizardIds(
            'main_policy_holder',
            'track_record'
        )

        if (ids2) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids2.sectionID}`)
            params2.append('subsection_id', `${ids2.sub_sectionID}`)
            if (ids2.car_id) {
                params2.append('car_id', `${ids2.car_id}`)
            }
            if (ids2.driver_id) {
                params2.append('driver_id', `${ids2.driver_id}`)
            }
            navigate(
                {
                    search: params2.toString(),
                },
                { replace: true }
            )
        } else navigate(`/insurance/application/${applicationData?.id}`)

        // next of the driver that is 100%, I should do the same for cars
    }

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(insuranceActions.submit_mainDriver_trackRecord_request())

            setTimeout(() => {
                goNext()
            }, 200)
        } else {
            setIsSavedDisabled(true)
        }
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'main_policy_holder',
            onSave: () => saveForm(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    let onAnswerChange = (id: string, answer: any) => {
        if (errors && errors[id]) {
            setError(id, undefined)
        }
        if (id === 'has_any_previous_insurance_refusals' && answer === true) {
            dispatch(
                insuranceActions.set_answer_main_driver_trackRecord({
                    id: 'has_previous_special_terms_imposed',
                    answer: undefined,
                    userid: `${userid}`,
                })
            )

            dispatch(
                insuranceActions.set_answer_main_driver_trackRecord({
                    id: 'previous_special_terms_imposed_reason',
                    answer: undefined,
                    userid: `${userid}`,
                })
            )

            dispatch(
                insuranceActions.set_answer_main_driver_trackRecord({
                    id: 'has_previous_motor_insurance_refused',
                    answer: undefined,
                    userid: `${userid}`,
                })
            )

            dispatch(
                insuranceActions.set_answer_main_driver_trackRecord({
                    id: 'previous_motor_terms_refused_reason',
                    answer: undefined,
                    userid: `${userid}`,
                })
            )

            dispatch(
                insuranceActions.set_answer_main_driver_trackRecord({
                    id: 'has_previous_motor_insurance_cancelled',
                    answer: undefined,
                    userid: `${userid}`,
                })
            )

            dispatch(
                insuranceActions.set_answer_main_driver_trackRecord({
                    id: 'previous_motor_insurance_cancelled_reason',
                    answer: undefined,
                    userid: `${userid}`,
                })
            )

            dispatch(
                insuranceActions.set_answer_main_driver_trackRecord({
                    id: 'has_previous_motor_insurance_voided',
                    answer: undefined,
                    userid: `${userid}`,
                })
            )

            dispatch(
                insuranceActions.set_answer_main_driver_trackRecord({
                    id: 'previous_motor_insurance_voided_reason',
                    answer: undefined,
                    userid: `${userid}`,
                })
            )
        }
        dispatch(
            insuranceActions.set_answer_main_driver_trackRecord({
                id: id,
                answer: answer,
                userid: `${userid}`,
            })
        )

        if (isSavedDisabled === true) {
            setIsSavedDisabled(false)
        }
        if (!hasFormChanged) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'main_policy_holder',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }
    }

    let currURL = `/insurance/application/${applicationData?.id}/wizard?section_id=main_policy_holder&subsection_id=track_record`

    const setEntityArrFunc = (
        arr: IAnyObject[],
        entity: string,
        has_error?: {
            entityTypeID: string
            entityUID: string
        }
    ) => {
        if (!hasFormChanged) {
            setHasFormChanged(true)
        }

        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }

        if (entity === 'motoring_convictions') {
            setError('has_motoring_convictions', undefined)
        }

        if (entity === 'convictions') {
            setError('has_convictions', undefined)
        }

        if (entity === 'claims') {
            setError('has_claims', undefined)
        }

        dispatch(
            insuranceActions.set_entity_arr_main_driver({
                arr: arr,
                fieldID: entity,
                has_error,
            })
        )
    }

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        sectionID="main_policy_holder"
                        list={insurance_fields_main_driver_track_record}
                        onAnswerChange={onAnswerChange}
                        data={data_draft}
                        errors={errors}
                        setError={setError}
                        entitiesObjArr={entitiesObjArr}
                        entitiesErrNormalised={entitiesErrNormalised}
                        setEntityArr={(
                            arr: IAnyObject[],
                            entity: string,
                            has_error?: {
                                entityTypeID: string
                                entityUID: string
                            }
                        ) => {
                            setEntityArrFunc(arr, entity, has_error)
                            if (hasFormChanged === false) {
                                setHasFormChanged(true)
                            }
                            if (isSavedDisabled) {
                                setIsSavedDisabled(false)
                            }
                        }}
                    />

                    <div style={{ paddingTop: '200px' }} />
                </div>
                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() => goPrevious()}
                    sectionId="main_policy_holder"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="main_policy_holder"
                            urlBack={currURL}
                            draft={data_draft}
                            submitted={data_submitted}
                            edited_entity={
                                entitiesObjArr &&
                                // @ts-ignore
                                entitiesObjArr[`${entity}`]
                                    ? // @ts-ignore
                                      [...entitiesObjArr[`${entity}`]]
                                    : []
                            }
                            entity_index={
                                entity_index !== undefined &&
                                entity_index !== null &&
                                typeof +entity_index === 'number'
                                    ? +entity_index
                                    : undefined
                            }
                            onChange={(value: IDropdownItem) => {
                                if (field_id && errors && errors[field_id]) {
                                    setError(field_id, undefined)
                                }

                                if (entity !== null && entity_index !== null) {
                                    let roota: IAnyObject[] =
                                        entitiesObjArr &&
                                        // @ts-ignore
                                        entitiesObjArr[`${entity}`]
                                            ? // @ts-ignore
                                              [...entitiesObjArr[`${entity}`]]
                                            : []

                                    roota[+entity_index] = {
                                        ...roota[+entity_index],
                                        [`${field_id}`]: value,
                                    }

                                    dispatch(
                                        insuranceActions.set_entity_arr_main_driver(
                                            {
                                                arr: [...roota],
                                                fieldID: entity,
                                            }
                                        )
                                    )
                                } else
                                    dispatch(
                                        insuranceActions.set_answer_main_driver_trackRecord(
                                            {
                                                id: `${field_id}`,
                                                answer: value,
                                                userid: `${userid}`,
                                            }
                                        )
                                    )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '88vw',
                                paddingBottom: 120,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                sectionID="main_policy_holder"
                                list={insurance_fields_main_driver_track_record}
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                entitiesErrNormalised={entitiesErrNormalised}
                                setError={setError}
                                mobileSearchPickerSectionURL={currURL}
                                entitiesObjArr={entitiesObjArr}
                                setEntityArr={(
                                    arr: IAnyObject[],
                                    entity: string,
                                    has_error?: {
                                        entityTypeID: string
                                        entityUID: string
                                    }
                                ) => {
                                    setEntityArrFunc(arr, entity, has_error)
                                    if (hasFormChanged === false) {
                                        setHasFormChanged(true)
                                    }
                                    if (isSavedDisabled) {
                                        setIsSavedDisabled(false)
                                    }
                                }}
                            />

                            <div style={{ paddingTop: '200px' }} />
                        </div>
                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() => goPrevious()}
                            sectionId="main_policy_holder"
                        />
                    </div>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceMainDriverTrackRecordApplicationFlow
