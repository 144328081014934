import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CarGalleryManagerDesktop from '../../organisms/galleryManager/carGalleryManagerDesktop'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import SideSlider from '../../templates/animated/sideSlider'
import CarGalleryManagerMobile from '../../organisms/galleryManager/carGalleryManagerMobile'
import { editFormsActions } from '../../../redux/editForms/reducer'
import WrapperImageFormsDesktop from '../../organisms/editForms/imageFormWrapper/wrapperImageFormsDesktop'
import WrapperImageFormsMobile from '../../organisms/editForms/imageFormWrapper/wrapperImageFormMobile'
import CarGalleryEditExistingImageFormDesktop from '../../organisms/editForms/carGalleryEditForm/carGalleryEditExistingImageFormDesktop'
import PortalInvisibleWrapper from '../../templates/displays/pageWrappers/portalInvisibleWrapper'
import AndroidInstallPwaInstructionsPropmt from '../../templates/pwaCustom/androidInstallPwaInstructionsPropmt'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { getCookie } from '../../../redux/services/authcheck'
import Loader from '../../atoms/loader/loader'
import GalleryImageV2 from './galleryImagev2'
import { IShowroomEntry } from '../../../redux/showroom/types'
import { showroomActions } from '../../../redux/showroom/reducer'
import {
    useAppDispatch,
    useAppSelector,
    useThunkAppDispatch,
} from '../../../redux/store/hooks'
import { ICar } from '../../../redux/entities/cars/types'
import { carActions } from '../../../redux/entities/cars/reducer'
import { usersActions } from '../../../redux/user/reducer'
import { IUpdateCarGalleryImagePayload } from '../../../redux/entities/galleries/types'
import { galleriesActions } from '../../../redux/entities/galleries/reducer'

const Gallery: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const currentCarId = useAppSelector(
        (state) => state.entities.carsData.currentCarID
    )
    const isCarLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const isGalleryLoading = useAppSelector(
        (state) => state.entities.galleriesData.loading
    )
    const isCargalleryFormEditOpened = useAppSelector(
        (state) => state.editForms.formsData.carGalleryEditForm.isOpen
    )
    const imagesData = useAppSelector(
        (state) => state.entities.galleriesData.images
    )

    const isExistingcarGalleryImageFormOpened = useAppSelector(
        (state) =>
            state.editForms.formsData.carExistingGalleryImageEditForm.isOpen
    )
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const isNecessaryGalleryDataLoading = useAppSelector(
        (state) => state.entities.galleriesData.loading
    )
    const isGettingCarDataLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const userShowroomEntriesData = useAppSelector(
        (state) => state.showroom.userShowroomEntries
    )

    const dispatchThunks = useThunkAppDispatch()

    const checkDirection = () => {
        if (location.state) {
            if ((location.state as any).prevPath === '/car/gallery/image-id') {
                return 'left'
            }
            if ((location.state as any).prevPath === '/car') {
                return 'right'
            } else return ''
        } else return ''
    }

    const manageShowroomEntryData = () => {
        let currentCar: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined

        let isCarOwner =
            currentCar &&
            userLoggedIn &&
            currentCar.owner_id === userLoggedIn?.id

        if (isCarOwner === true) {
            let hasEntryID: string | undefined =
                (currentCar &&
                    currentCar.showroom_entry &&
                    currentCar.showroom_entry.state === 'PUBLISHED') ||
                (currentCar &&
                    currentCar.showroom_entry &&
                    currentCar.showroom_entry.state === 'SOLD')
                    ? currentCar.showroom_entry.uid
                    : undefined

            if (hasEntryID) {
                let currentEntry: IShowroomEntry | undefined =
                    hasEntryID &&
                    userShowroomEntriesData &&
                    userShowroomEntriesData[hasEntryID]
                        ? userShowroomEntriesData[hasEntryID]
                        : undefined

                if (!currentEntry) {
                    dispatch(
                        showroomActions.getShowroomEntryByIDRequest({
                            is_owner: true,
                            entryID: hasEntryID,
                        })
                    )
                }
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(
            customNavDataActions.setInitialLandingUrl(`/car/${carid}/gallery`)
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            manageShowroomEntryData()

            if (!currentCarId || currentCarId !== carid) {
                dispatchThunks(carActions.setCurrentCar(`${carid}`))
            }

            if (!(carsData && carid && carsData[carid])) {
                dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            }
        }
        return () => {
            if (isCargalleryFormEditOpened === true) {
                dispatch(editFormsActions.toggleCarGalleryEditForm())
            }
        }
    }, [carid, userLoggedIn])

    useEffect(() => {
        manageShowroomEntryData()
    }, [carsData])

    const params = new URLSearchParams(location.search)

    const currentImageData =
        imagesData && params.get('id') ? imagesData[params.get('id')!] : null

    const current: ICar | undefined =
        carsData && carid && carsData[carid] ? carsData[carid] : undefined

    if (current?.archived_on) {
        navigate(`/archives/car/${current.id}/gallery`)
        return null
    }

    return (
        <React.Fragment>
            <Loader isLoading={isCarLoading || isGalleryLoading} />
            {!currentImageData && (
                <FabsMenusMobile hasAdd={false} hasSubNav={true} />
            )}

            {!params.get('id') &&
                getCookie('android_install_prompt') !== 'seen' && (
                    <AndroidInstallPwaInstructionsPropmt />
                )}
            {current !== null &&
                current !== undefined &&
                isGalleryLoading !== true &&
                isCarLoading !== true && (
                    <React.Fragment>
                        <FullPageWrapper hasMobileSubNav>
                            <DesktopDisplayOnly>
                                <CarGalleryManagerDesktop
                                    ids_list={current.gallery.images}
                                    openImgForm={() => {
                                        dispatch(
                                            editFormsActions.toggleCarGalleryEditForm()
                                        )
                                        setTimeout(() => {
                                            window.scrollTo(0, 0)
                                        }, 150)
                                    }}
                                    imagesData={imagesData}
                                    isNecessaryGalleryDataLoading={
                                        isNecessaryGalleryDataLoading
                                    }
                                />
                            </DesktopDisplayOnly>
                            {!currentImageData && (
                                <IpadAndMobileDisplay>
                                    <SideSlider
                                        direction={checkDirection()}
                                        initial="enter"
                                    >
                                        <CarGalleryManagerMobile
                                            ids_list={current.gallery.images}
                                            openImgForm={() => {
                                                dispatch(
                                                    editFormsActions.toggleCarGalleryEditForm()
                                                )
                                                setTimeout(() => {
                                                    window.scrollTo(0, 0)
                                                }, 150)
                                            }}
                                            imagesData={imagesData}
                                            isNecessaryGalleryDataLoading={
                                                isNecessaryGalleryDataLoading
                                            }
                                            isGettingCarDataLoading={
                                                isGettingCarDataLoading
                                            }
                                        />
                                    </SideSlider>
                                </IpadAndMobileDisplay>
                            )}
                        </FullPageWrapper>
                    </React.Fragment>
                )}
            <DesktopDisplayOnly>
                {isCargalleryFormEditOpened && (
                    <ModalDisplay
                        toggle={() =>
                            dispatch(
                                editFormsActions.toggleCarGalleryEditForm()
                            )
                        }
                        isOpen={true}
                    >
                        <WrapperImageFormsDesktop
                            close={() =>
                                dispatch(
                                    editFormsActions.toggleCarGalleryEditForm()
                                )
                            }
                            carid={`${carid}`}
                        />
                    </ModalDisplay>
                )}

                {isExistingcarGalleryImageFormOpened && currentImageData && (
                    <ModalDisplay
                        toggle={() =>
                            dispatch(
                                editFormsActions.toggleExistingCarGalleryImageEditForm()
                            )
                        }
                        isOpen={true}
                    >
                        <CarGalleryEditExistingImageFormDesktop
                            close={() =>
                                dispatch(
                                    editFormsActions.toggleExistingCarGalleryImageEditForm()
                                )
                            }
                            updateImage={(
                                data: IUpdateCarGalleryImagePayload
                            ) =>
                                dispatch(
                                    galleriesActions.updateCarGalleryImageRequest(
                                        data
                                    )
                                )
                            }
                            carid={`${carid}`}
                            imagedata={currentImageData}
                        />
                    </ModalDisplay>
                )}
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                {isCargalleryFormEditOpened && (
                    <ModalDisplay
                        toggle={() =>
                            dispatch(
                                editFormsActions.toggleCarGalleryEditForm()
                            )
                        }
                        isOpen={true}
                    >
                        <WrapperImageFormsMobile
                            close={() =>
                                dispatch(
                                    editFormsActions.toggleCarGalleryEditForm()
                                )
                            }
                            carid={`${carid}`}
                        />
                    </ModalDisplay>
                )}
            </IpadAndMobileDisplay>
            {params.get('id') && (
                <PortalInvisibleWrapper
                    onClick={() => {
                        // navigate(location.pathname)
                    }}
                >
                    <GalleryImageV2 />
                </PortalInvisibleWrapper>
            )}
        </React.Fragment>
    )
}

export default Gallery
