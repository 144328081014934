import { IJourneySectionID } from 'journeyInterfaces'
import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'
import styled from 'styled-components'
import useThemes from '../../../../providers/theme/hooks'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import { device } from '../../../templates/displays/devices'
import FormMultipleStepsProgress from '../../FormStepProgress/FormMultipleStepsProgress'
import { motion } from 'framer-motion'
import CrossIcon from '../../icons/crossIcon'
import { CarIcon, HistoryFilesIcon } from '../../icons/components'
import InsuranceIcon from '../../icons/components/insuranceIcon'
import KeyIcon from '../../icons/components/keyIcon'
import PoundIcon from '../../icons/components/poundIcon'
import UserPlainIcon from '../../icons/components/userPlainIcon'
import { JourneyColumnContent } from '../../../templates/styledcomponents/journeyStyles'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import CarIconV2 from '../../icons/components/carIconV2'
import JourneySharingIconV2 from '../../icons/components/journey/sharingV2'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'

type IStyle = {
    $theme: ITheme
    $sectionID: IJourneySectionID
    $isActive?: boolean
}

interface IListItemProps extends LinkProps {
    item: {
        sectionID: string
        title: string
        icon: JSX.Element
    }
    theme: ITheme
    sectionID: IJourneySectionID
    isActive: boolean
}

const JourneyMenuBackground = styled.div`
    width: calc(100dvw - 40px);
    min-height: 100dvh;
    background: linear-gradient(
        270deg,
        rgba(26, 26, 26, 0) 0%,
        rgba(26, 26, 26, 0.15) 98.2%,
        rgba(26, 26, 26, 0.02) 98.21%
    );
    @media ${device.mobile_and_ipad} {
        width: 100dvw;
    }
`
const JourneyMenuWrapper = styled.menu`
    margin: 0;
    padding: 0;
    width: 400px;
    min-height: 100dvh;
    padding-bottom: 100px;
    flex-direction: column;
    align-items: flex-end;
    box-shadow: 0px 0px 128px 0px rgba(0, 0, 0, 0.04);
    padding-top: 42px;

    @media ${device.mobile_and_ipad} {
        padding-top: 48px;
        width: 100dvw;
    }
`

const TopBarContainer = styled.div<IStyle>`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: 0px;
    width: 400px;
    height: 42px;
    z-index: 100;
    background-color: ${(props) =>
        `${colours[props.$theme].background_neutral_subtle}`};
    transition: all 100ms;

    @media ${device.mobile_and_ipad} {
        height: 48px;
        background-color: transparent;
        border: none;
        backdrop-filter: blur(2px);
        width: 100dvw;
    }
`

const TopBarSubContainer = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
`

const BtnWrapper = styled(motion.button)`
    outline: none;
    border: none;
    display: flex;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all 150ms;
    background-color: transparent;

    @media ${device.mobile_and_ipad} {
    }
`

const TopSection = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    @media ${device.mobile} {
        padding-top: 0px;
        padding-bottom: 8px;
    }
`

const SmallTxt = styled.span`
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 20px;
`

const Title = styled.h1`
    font-family: Lato-semibold;
    font-size: 24px;
    line-height: 40px;
`

const ListItemstyle = styled(Link)<IStyle>`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    gap: 16px;
    border-radius: 4px;
    background-color: ${(props) =>
        props.$isActive
            ? props.$sectionID === 'your_profile'
                ? `${
                      journey_colours[props.$theme].section[props.$sectionID]
                          .primary_500_24
                  }`
                : `${
                      journey_colours[props.$theme].section[props.$sectionID]
                          .primary_500_16
                  }`
            : undefined};
    :hover {
        background-color: ${(props) =>
            props.$sectionID === 'your_profile'
                ? `${
                      journey_colours[props.$theme].section[props.$sectionID]
                          .primary_500_24
                  }`
                : `${
                      journey_colours[props.$theme].section[props.$sectionID]
                          .primary_500_16
                  }`};
    }
`

const menuData = (theme: ITheme, sectionID: IJourneySectionID) => [
    {
        sectionID: 'car_profile',
        title: 'Create a digital profile for your car',
        icon: (
            <CarIcon
                size="24"
                color={journey_colours[theme].section[sectionID].primary_900}
                strokeColour={
                    journey_colours[theme].section[sectionID].primary_900
                }
            />
        ),
        pathname: '/journey/car-profile',
    },
    {
        sectionID: 'history_file',
        title: "Bring your car's history online",
        icon: (
            <HistoryFilesIcon
                size="24"
                color={journey_colours[theme].section[sectionID].primary_900}
            />
        ),
        pathname: '/journey/history-file',
    },
    {
        sectionID: 'insurance',
        title: 'Learn about Custodian Insurance',
        icon: (
            <InsuranceIcon
                height="24"
                width="24"
                color={journey_colours[theme].section[sectionID].primary_900}
            />
        ),
        pathname: '/journey/insurance',
    },
    {
        sectionID: 'share_your_car',
        title: 'Car ownership is best enjoyed together ',
        icon: (
            <JourneySharingIconV2
                size="24"
                color={journey_colours[theme].section[sectionID].primary_900}
            />
        ),
        pathname: '/journey/share-your-car',
    },
    {
        sectionID: 'past_cars',
        title: "Don't forget your past cars",
        icon: (
            <CarIconV2
                size="24"
                color={journey_colours[theme].section[sectionID].primary_900}
            />
        ),
        pathname: '/journey/previously-owned',
    },
    {
        sectionID: 'handover',
        title: "Preserve and pass on your car's history and provenance",
        icon: (
            <KeyIcon
                color={journey_colours[theme].section[sectionID].primary_900}
            />
        ),
        pathname: '/journey/handover',
    },
    {
        sectionID: 'sell_your_car',
        title: 'Sell your car in the best possible way',
        icon: (
            <PoundIcon
                size="24"
                color={journey_colours[theme].section[sectionID].primary_900}
            />
        ),
        pathname: '/journey/sell-your-car',
    },
    {
        sectionID: 'your_profile',
        title: 'Make sure you look your best',
        icon: (
            <UserPlainIcon
                size="24"
                color={journey_colours[theme].section[sectionID].primary_900}
            />
        ),
        pathname: '/journey/your-profile',
    },
]

const ListItem = (props: IListItemProps) => {
    let { item, theme, sectionID, isActive } = props
    return (
        <ListItemstyle
            $isActive={isActive}
            $theme={theme}
            $sectionID={sectionID}
            {...props}
        >
            {item.icon}{' '}
            <SmallTxt
                style={{
                    fontSize: 16,
                    color: journey_colours[theme].section[sectionID]
                        .primary_900,
                }}
            >
                {item.title}
            </SmallTxt>
        </ListItemstyle>
    )
}

export default function JourneySideMenu({
    sectionID,
    isOpen,
    onClose,
}: {
    sectionID: IJourneySectionID
    isOpen: boolean
    onClose: () => void
}) {
    const { theme } = useThemes()
    let params = new URLSearchParams(location.search)
    let carid: string | null = params.get('carid')

    const menuDataArray = menuData(theme, sectionID)

    const activeIndex = menuDataArray.findIndex(
        (item) => item.sectionID === sectionID
    )
    const barCompletion = Math.round(
        ((activeIndex + 1) / menuDataArray.length) * 100
    )

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    return (
        <SideSliderWithExit
            direction={'left'}
            isVisible={isOpen}
            customZIndex={101}
            initial="enter"
            customDuration={0.5}
            removeRightValue
        >
            <JourneyMenuBackground onClick={onClose}>
                <JourneyMenuWrapper
                    style={{
                        background: `linear-gradient(180deg, ${colours[theme].background_neutral_subtle} 0%, ${journey_colours[theme].section[sectionID].primary_100} 100%)`,
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <TopBarContainer $theme={theme} $sectionID={sectionID}>
                        <TopBarSubContainer>
                            <div />

                            <BtnWrapper
                                onClick={onClose}
                                whileTap={{ scale: 1.5 }}
                            >
                                <CrossIcon
                                    size="16"
                                    color={colours[theme].text_darker}
                                />
                            </BtnWrapper>
                        </TopBarSubContainer>
                    </TopBarContainer>
                    <TopSection>
                        <div>
                            <SmallTxt
                                style={{
                                    color: journey_colours[theme].section[
                                        sectionID
                                    ].primary_900,
                                }}
                            >
                                Main objective
                            </SmallTxt>
                            <Title
                                style={{
                                    color: journey_colours[theme].section[
                                        sectionID
                                    ].primary_900,
                                }}
                            >
                                Become a True Custodian
                            </Title>
                        </div>
                        <FormMultipleStepsProgress
                            arrayOfProgressBarsValues={[barCompletion]}
                            progress_base_colour={
                                journey_colours[theme].section[sectionID]
                                    .primary_500_24
                            }
                            progress_filled_colour={
                                journey_colours[theme].section[sectionID]
                                    .primary_900
                            }
                        />
                    </TopSection>
                    <JourneyColumnContent style={{ padding: '0px 16px' }}>
                        {menuData(theme, sectionID).map((item) => (
                            <React.Fragment>
                                <ListItem
                                    item={item}
                                    theme={theme}
                                    sectionID={sectionID}
                                    isActive={
                                        item.sectionID === sectionID
                                            ? true
                                            : false
                                    }
                                    to={{
                                        pathname: item.pathname,
                                        search:
                                            carid &&
                                            (item.sectionID === 'car_profile' ||
                                                item.sectionID ===
                                                    'history_file')
                                                ? `?step=welcome&carid=${carid}`
                                                : `?step=welcome`,
                                    }}
                                    onClick={() => {
                                        if (isMobile) {
                                            onClose()
                                        }
                                    }}
                                />
                            </React.Fragment>
                        ))}
                    </JourneyColumnContent>
                </JourneyMenuWrapper>
            </JourneyMenuBackground>
        </SideSliderWithExit>
    )
}
