import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import posthog from 'posthog-js'
import {
    ConvertLabelsStateToLabelsAddAPI,
    ConvertLabelsStateToLabelsUnlinkAPI,
} from '../../conversions/labels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { timelineActions } from '../reducer'
import { usersActions } from '../../user/reducer'
import { IRootState } from '../../store'
import {
    IAddLabelToEntryAPIPayload,
    IEntryPayloadAPI,
    ILabelAPI,
    IRemoveLabelToEntryAPIPayload,
} from '../../services/typedefinitions/apiPayloads'
import { IReduxError } from '../../entities/cars/types'
import { ILabel, IUpdateEntryLabelPayload } from '../types'

const getActiveEntryLabelsStore = (state: IRootState) =>
    state.timeline.activeEntry && state.timeline.activeEntry.labels

const getUserLabelsStore = (state: IRootState): ILabel[] | undefined =>
    state.user.userLoggedIn?.labels

export function* AddLabelToEntrySaga(payload: IUpdateEntryLabelPayload): any {
    try {
        let labels_converted: string[] = ConvertLabelsStateToLabelsAddAPI(
            payload.labels
        )

        let payload_converted: IAddLabelToEntryAPIPayload = {
            ...payload,
            label_names: labels_converted,
        }
        // STEP 1 . call the api func
        let res: IEntryPayloadAPI = yield call(
            api.timeline.labels.addLabelToEntry.addLabelToEntry,
            payload_converted
        )

        // STEP 2. build up payload to return to reducer

        // STEP 3. dispatch delete action
        yield put(timelineActions.addLabelToCarEntrySuccess(payload))

        let entry_ls = res.entry_labels.map((a: ILabelAPI) => a.name)

        let userLabels = yield select(getUserLabelsStore)
        let user_ls =
            userLabels !== undefined
                ? userLabels.map((a: ILabelAPI) => a.name)
                : []

        let newLabels: ILabel[] = []

        for (let i = 0; i < entry_ls.length; i++) {
            if (!user_ls.includes(entry_ls[i])) {
                newLabels.push({
                    uid: res.entry_labels[i].uid,
                    name: res.entry_labels[i].name,
                })
            }
        }

        if (newLabels.length > 0) {
            yield put(usersActions.addLabelsToUserSuccess([...newLabels]))
        }

        // Capture event
        posthog.capture('ADD LABEL TO ENTRY')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: AddLabelToEntrySaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let err: IReduxError = ConvertToReduxError(error)
            yield put(timelineActions.addLabelToCarEntryError(err))
        }
    }
}

export function* RemoveLabelFromEntrySaga(
    payload: IUpdateEntryLabelPayload
): any {
    try {
        let labels_converted: string[] = ConvertLabelsStateToLabelsUnlinkAPI(
            payload.labels
        )

        let payload_converted: IRemoveLabelToEntryAPIPayload = {
            ...payload,
            label_uids: labels_converted,
        }
        // STEP 1 . call the api func
        yield call(
            api.timeline.labels.unlinkLabelFromEntry.unlinkLabelFromEntry,
            payload_converted
        )

        // STEP 2. build up payload to return to reducer
        let currentLabels: ILabel[] = yield select(getActiveEntryLabelsStore)
            ? yield select(getActiveEntryLabelsStore)
            : []

        let uids_removed_arr: string[] = payload.labels.map(
            (label: ILabel, index: number) => {
                return label.uid
            }
        )

        let currentLabelsCleaned: ILabel[] = currentLabels.filter(
            (label: ILabel, index: number) => {
                return !uids_removed_arr.includes(label.uid)
            }
        )

        payload.labels = currentLabelsCleaned

        // STEP 3. dispatch delete action
        yield put(timelineActions.removeLabelFromCarEntrySuccess(payload))

        // Capture event
        posthog.capture('ADD LABEL TO ENTRY')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: RemoveLabelFromEntrySaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let err: IReduxError = ConvertToReduxError(error)
            yield put(timelineActions.removeLabelToCarEntryError(err))
        }
    }
}

function* watcherAddLabelToEntry() {
    while (true) {
        const { payload } = yield take(
            timelineActions.addLabelToCarEntryRequest
        )

        yield call(AddLabelToEntrySaga, payload)
    }
}

function* watcherRemoveLabelFromEntry() {
    while (true) {
        const { payload } = yield take(
            timelineActions.removeLabelFromCarEntryRequest
        )

        yield call(RemoveLabelFromEntrySaga, payload)
    }
}

const entry_labels_updates: any[] = [
    fork(watcherAddLabelToEntry),
    fork(watcherRemoveLabelFromEntry),
]

export default entry_labels_updates
