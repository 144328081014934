import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import ReadOnlyCarHistoryFileEntryGallery from '../../../templates/readOnly/car/readOnlyHistoryFileEntryGallery'
import { ICar, ICarsObject } from '../../../../redux/entities/cars/types'
import { usersActions } from '../../../../redux/user/reducer'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { timelineActions } from '../../../../redux/timeline/reducer'

type PageParams = {
    carid: string
    entryid: string
}

const CarHistoryFileEntryGalleryArchive = () => {
    const { carid, entryid } = useParams<PageParams>()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const carsData: ICarsObject = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let current: ICar | undefined =
        carid && carsData[carid] ? carsData[carid] : undefined

    const dispatch = useAppDispatch()

    useEffect(() => {
        // if (!current) {
        // for now always get on render ( we'll add the has_limited_garage_info check tho)
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/archives/car/${carid}/history-file/${entryid}/gallery`
            )
        )
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            dispatch(carActions.setCurrentCar(`${carid}`))
            dispatch(carActions.getCarDataByIdRequest(`${carid}`))
            if (entryid) {
                dispatch(
                    timelineActions.getTimelineItemByIDRequest({
                        entryUID: entryid,
                        car_id: `${carid}`,
                    })
                )
            }
        }

        // }
    }, [userLoggedIn])

    return (
        <React.Fragment>
            {current && userLoggedIn && (
                <ReadOnlyCarHistoryFileEntryGallery
                    userLoggedIn={userLoggedIn}
                    prevOwned={current}
                />
            )}
        </React.Fragment>
    )
}

export default CarHistoryFileEntryGalleryArchive
