import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'

type StyledProps = {
    disabled?: boolean
    isActive: boolean
    xAxis: number
}

const MainButton = styled.button<StyledProps>`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    font-family: ${(props) => (props.isActive ? 'Lato-Bold' : 'Lato')};
    border-radius: 20px;
    height: 50px;
    width: 170px;
    padding: 10px 15px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.isActive === true
            ? 'var(--primary, #5ec3ca)'
            : 'var(--off-bg-color, #fafafa)'};

    color: ${(props) =>
        props.isActive
            ? 'var(--bg-color, #fff)'
            : 'var(--text-default, #666666)'};
    font-size: 14px;
    box-shadow: 7.3px 7.3px 29px 4.5px var(--box-shadow, rgba(0, 0, 0, 0.08));

    border: ${(props) =>
        props.disabled === true
            ? 'solid 1px var(--border-muted, #e5e5e5) !important'
            : 'solid 1px var(--primary_20) !important'};

    transition: all 200ms;
    transform: ${(props) => `translateX(${props.xAxis}px)`};

    :hover {
        transition: all 200ms;
        box-shadow: 2px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08));
        color: ${(props) =>
            props.isActive
                ? 'var(--bg-color, #fff)'
                : 'var(--text-default, #666666)'};
        background-color: ${(props) =>
            props.isActive === true
                ? 'var(--primary, #5ec3ca)'
                : 'var(--primary_08)'};
    }
    cursor: ${(props) => (props.disabled === true ? 'not-allowed' : 'pointer')};

    @media ${device.mobile_and_ipad} {
        font-size: 12px;
        height: 43px;
        width: 125px;
        border-radius: 16px;
    }
    transition: all 500ms;
`

const BtnWrapper = styled(motion.div)<{ isActive?: boolean }>`
    z-index: ${(props) => (props.isActive === true ? 2 : 0)};
    transition: all 200ms;
`

const Row = styled.div`
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
`

type Props = {
    dataCyId?: string | undefined
    activeID: string
    btnIdsList: string[]
    btnsData: any
}

const SwitchLikeBtnDesktop: React.FC<Props> = ({
    dataCyId,
    activeID,
    btnIdsList,
    btnsData,
}) => {
    const [currentActiveID, setCurrentActiveID] = React.useState(activeID)

    React.useEffect(() => {
        setCurrentActiveID(activeID)
    }, [activeID])

    return (
        <Row>
            {btnIdsList.map((id: string, index: number) => {
                return (
                    <React.Fragment key={`${id}_${index}_desktop`}>
                        <BtnWrapper
                            whileTap={{
                                scale:
                                    btnsData[id].isDisabled === true ? 1 : 1.05,
                            }}
                            isActive={currentActiveID === id}
                        >
                            <MainButton
                                onClick={() => {
                                    btnsData[id].action(id)
                                    setCurrentActiveID(id)
                                }}
                                disabled={btnsData[id].isDisabled === true}
                                data-attr={dataCyId ? dataCyId : undefined}
                                isActive={currentActiveID === id}
                                xAxis={index === 0 ? 0 : index - 30}
                            >
                                <div>{btnsData[id].txt}</div>
                            </MainButton>
                        </BtnWrapper>
                    </React.Fragment>
                )
            })}
        </Row>
    )
}

export default SwitchLikeBtnDesktop
