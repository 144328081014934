import React, { useState } from 'react'
import styled from 'styled-components'
import thicker_red_bin from '../../../../public/assets/icons/thicker_red_bin.svg'
import edit_icon_naked from '../../../../public/assets/icons/edit_icon_naked.svg'
import grid_icon_blue from '../../../../public/assets/icons/grid_icon_blue.svg'
import gallery_icon_blue from '../../../../public/assets/icons/gallery_icon_blue.svg'
import gallery_icon_red from '../../../../public/assets/icons/gallery_icon_red.svg'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import { motion } from 'framer-motion'
import { Tooltip } from 'antd'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

const ModalWrapper = styled.div<IStyle>`
    @media ${device.desktop} {
        display: none;
    }

    /* position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    transition: all 100ms;
    transition-timing-function: ease-in-out; */
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    top: 0;
    bottom: 0;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    animation: 0.1s fadeIn both;
    animation-delay: 0s;

    z-index: ${(props) => (props.isOpen ? '10' : '-2')};
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    justify-content: flex-end;
    flex-direction: column;
    padding: 0px;
`

const MenuItemWrap = styled.div<IStyle>`
    background-color: var(--off-bg-color, #fafafa);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-bottom: 20px;
    padding-top: 15px;
    /* transform: translateY(100%); */

    @keyframes slideUp {
        from {
            transform: translateY(100%);
            opacity: 0;
        }

        to {
            transform: translateY(0%);
            opacity: 1;
        }
    }

    animation: slideUp 0.3s cubic-bezier(0.87, 0, 0.13, 1) both;
    animation-delay: 0.05s;

    /* opacity: ${(props) => (props.isOpen ? '1' : '0')};
    transform: ${(props) =>
        props.isOpen ? 'translateY(0px)' : 'translateY(470px)'};
    transition: opacity 100ms, z-index 0ms, transform 100ms;*/
    z-index: ${(props) => (props.isOpen ? '10' : '-2')};
    border: none;

    @media ${device.ipad} {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`

const MenuItemBox = styled(motion.div)<IStyle>`
    background-color: var(--off-bg-color, #fafafa);
    color: var(--primary, #5ec3ca);

    :focus {
        outline: 0;
    }
`

const MenuItem = styled.div<IStyle>`
    position: relative;
    color: ${(props) =>
        props.$isDisabled
            ? 'var(--text-disabled) !important;'
            : props.is_last
            ? 'var(--error, #df6f6f) !important'
            : 'var(--text-darker, #616161) !important'};
    height: 50px;
    width: 100vw;
    opacity: 1 !important;
    font-family: 'Lato';
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    letter-spacing: 0.09rem;
`

type IStyle = {
    isOpen?: boolean
    is_last?: boolean
    $isDisabled?: boolean
}

type IItem = {
    text: string
    id: string
    action: any
    icon?: string
    isDisabled?: boolean
    tooltipText?: string
}

interface IProps {
    toggle: any
    isOpen: boolean
    items: IItem[]
}

const ImageSubMenuActions: React.FunctionComponent<IProps> = (props) => {
    const { toggle, isOpen, items } = props

    const [isTooltipVisible, toggleTooltip] = useState(false)

    const [activeIndex, setActiveIndex] = useState<number | undefined>(
        undefined
    )

    const showTooltip = (p: boolean) => {
        toggleTooltip(p)
        if (isTooltipVisible) {
            setTimeout(() => toggleTooltip(false), 2000)
        }
    }

    const { theme } = useThemes()

    return (
        <Faded>
            <ModalWrapper
                isOpen={isOpen}
                onClick={(e: any) => {
                    e.preventDefault()
                    setTimeout(function () {
                        toggle()
                        toggleTooltip(false)
                    }, 50)
                }}
            />

            <MenuItemWrap isOpen={isOpen}>
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                    }}
                >
                    <Tooltip
                        title={
                            activeIndex ? items[activeIndex].tooltipText : ''
                        }
                        open={isTooltipVisible && activeIndex ? true : false}
                        placement="top"
                        color={colours[theme].error}
                    />
                </div>
                {items.map((item: IItem, index: any) => (
                    <MenuItemBox
                        is_last={item.id === 'delete'}
                        onClick={(e: any) => {
                            e.preventDefault()

                            if (item.isDisabled) {
                                showTooltip(true)
                                setActiveIndex(index)
                            } else {
                                item.action && item.action(item.id)
                                showTooltip(false)
                                toggle()
                            }
                        }}
                        key={`${item.id}_${index}_img_menu_mobile`}
                        whileTap={{
                            backgroundColor: colours[theme].primary_04,
                        }}
                    >
                        <MenuItem $isDisabled={item.isDisabled}>
                            <div
                                style={{
                                    width: '30px',
                                }}
                            >
                                <img
                                    style={{
                                        width:
                                            item.id === 'edit'
                                                ? '16px'
                                                : '16px',
                                        transform:
                                            item.id === 'edit'
                                                ? 'translateY(1px)'
                                                : item.id === 'featured'
                                                ? 'translateY(2px)'
                                                : 'none',
                                        filter: item.isDisabled
                                            ? 'invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg) brightness(0%) contrast(24%)'
                                            : '',
                                    }}
                                    src={
                                        item.id === 'edit'
                                            ? edit_icon_naked
                                            : item.id === 'cover'
                                            ? grid_icon_blue
                                            : item.id === 'featured' &&
                                              item.text !== 'Remove feature'
                                            ? gallery_icon_blue
                                            : item.text === 'Remove feature'
                                            ? gallery_icon_red
                                            : item.id === 'delete'
                                            ? thicker_red_bin
                                            : ''
                                    }
                                    alt={`${item.id} icon`}
                                />
                            </div>
                            <span
                                style={{
                                    paddingRight: '15px',
                                }}
                            />

                            <span
                                style={{
                                    transform: 'translateY(1px)',
                                    color:
                                        (item.id === 'delete' ||
                                            item.text === 'Remove feature') &&
                                        !item.isDisabled
                                            ? colours[theme].error
                                            : 'inherit',
                                }}
                            >
                                {item.text}
                            </span>
                        </MenuItem>
                    </MenuItemBox>
                ))}
            </MenuItemWrap>
        </Faded>
    )
}

export default ImageSubMenuActions
