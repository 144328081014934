import * as React from 'react'
import styled from 'styled-components'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import useWindowSize from '../../displays/windowSizeHook'
import image_step_0 from '../../../../public/assets/onboarding/step_0.jpg'
import image_step_1 from '../../../../public/assets/onboarding/step_1.jpg'
import image_step_2 from '../../../../public/assets/onboarding/step_2.jpg'
import image_step_3 from '../../../../public/assets/onboarding/step_3.jpg'
import LeftAlignLogo from '../../../atoms/icons/components/leftAlignLogo'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    step: number
    children?: React.ReactNode
    title?: string
    whiteTextTitle?: string
    whiteTextSubTitle?: string
    rightSideContent?: React.ReactChild
    isStepsBarRemoved?: boolean
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
`

const OnboardingFormContainerLeft = styled.section`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 0.7;
    align-items: center;
    padding-left: 80px;
    padding-right: 80px;

    overflow-y: auto;
    overflow-x: hidden;
`
const LogoWrapper = styled.div`
    width: 160px;
    height: 32px;
`

export const OnboardingFormContainerRight = styled.section`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1.3;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`

const OnboardingTopLeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
`

const FormTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
`

const WhiteTitle = styled.h1`
    padding: 0px;
    margin: 0px;
    padding-bottom: 16px;
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Desktop/Heading/H600 */
    font-family: Lato-Semibold;
    font-size: 32px;

    line-height: 48px; /* 150% */
`

const WhiteSubTitle = styled.h2`
    padding: 0px;
    margin: 0px;
    color: white;
    /* Desktop/Heading/H500 */
    font-family: Lato-Semibold;
    font-size: 24px;
    /* line-height: 40px; 166.667% */
    max-width: 500px;
`

const OnboardingTitle = styled.h1`
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--primary, #5ec3ca);
    /* Mobile/Heading/H800 */
    font-family: Lato;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 40px; 142.857% */
`

export const OnboardingText = styled.p`
    margin: 0;
    padding: 0;
    color: var(--text-darker, #616161);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Mobile/Text/T300 Regular */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

// const PaddingUnit = styled.div<{ padding: number }>`
//     padding-top: ${(props) => (props.$times ? `${props.$times * 8}px` : '8px')};
// `

const OnboardingLayoutDesktop: React.FunctionComponent<Props> = (
    props: Props
) => {
    let {
        step,
        children,
        title,
        whiteTextTitle,
        whiteTextSubTitle,
        rightSideContent,
        isStepsBarRemoved,
    } = props
    let windowHeight = useWindowSize().height

    let windowWidth = useWindowSize().width

    const [isRightSideVisible, toggleRightSide] = React.useState(false)
    React.useEffect(() => {
        setTimeout(() => toggleRightSide(true), 20)
    }, [])

    const { theme } = useThemes()
    return (
        <Wrapper>
            <OnboardingFormContainerLeft
                style={
                    windowHeight && windowHeight < 800
                        ? {
                              maxHeight: windowHeight,
                              boxSizing: 'border-box',
                              paddingLeft: 56,
                              paddingRight: 56,
                              paddingTop: isStepsBarRemoved ? 32 : 50,
                          }
                        : {
                              maxHeight: windowHeight,
                              boxSizing: 'border-box',
                              paddingTop: isStepsBarRemoved ? 32 : 50,
                          }
                }
            >
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        maxWidth: 700,
                    }}
                >
                    <LogoWrapper>
                        <LeftAlignLogo
                            size={28}
                            colour={colours[theme].text_strong}
                        />
                    </LogoWrapper>
                    <div
                        style={{
                            paddingTop:
                                isStepsBarRemoved === true
                                    ? 30
                                    : windowHeight && windowHeight < 800
                                    ? 30
                                    : 30,
                        }}
                    />
                    {isStepsBarRemoved !== true && step < 4 && (
                        <FormStepProgress
                            totalSteps={3}
                            currentStep={step}
                            screen_name="desktop"
                        />
                    )}

                    <OnboardingTopLeftWrapper>
                        <div
                            style={{
                                paddingTop:
                                    isStepsBarRemoved === true
                                        ? 0
                                        : windowHeight && windowHeight < 800
                                        ? 30
                                        : 30,
                            }}
                        />
                        <FormTitle
                            style={
                                step === 4
                                    ? { paddingTop: 30 }
                                    : {
                                          paddingTop: isStepsBarRemoved
                                              ? 0
                                              : 10,
                                      }
                            }
                        >
                            <OnboardingTitle
                                style={
                                    step === 4
                                        ? {
                                              color: 'var(--text-strong, #1a1a1a)',
                                          }
                                        : {}
                                }
                            >
                                {title}
                            </OnboardingTitle>
                        </FormTitle>
                    </OnboardingTopLeftWrapper>

                    {children}
                </div>
            </OnboardingFormContainerLeft>
            <OnboardingFormContainerRight
                style={{
                    height: windowHeight,
                    boxSizing: 'border-box',
                    backgroundImage:
                        step === 4
                            ? 'none'
                            : `url(${
                                  step === 0
                                      ? image_step_0
                                      : step === 1
                                      ? image_step_1
                                      : step === 2
                                      ? image_step_2
                                      : step === 3
                                      ? image_step_3
                                      : image_step_1
                              })`,
                }}
            >
                <div
                    style={{
                        opacity: isRightSideVisible ? 1 : 0,
                        transition: 'all 500ms',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                        paddingLeft: step === 4 ? 40 : 0,
                        background: rightSideContent
                            ? step === 2
                                ? `var(--background-overlay-light)`
                                : step === 4
                                ? 'var(--off-bg-color, #fafafa)'
                                : `var(--bg-color-85)`
                            : `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)`,
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '100%',
                            display: step !== 4 ? 'flex' : undefined,
                            overflowX: 'hidden',
                            alignItems: step === 2 ? 'flex-start' : 'center',
                            justifyContent:
                                step === 3 ? 'center' : 'flex-start',
                            paddingTop:
                                step === 2 && windowWidth && windowWidth > 1100
                                    ? '88px'
                                    : step === 2
                                    ? '50px'
                                    : '0px',
                            paddingBottom:
                                step === 4
                                    ? 0
                                    : windowHeight && windowHeight < 1000
                                    ? 16
                                    : 80,
                        }}
                    >
                        {rightSideContent ?? null}
                    </div>
                </div>
                {(whiteTextTitle || whiteTextSubTitle) && (
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            boxSizing: 'border-box',
                            paddingTop: 40,
                            paddingBottom: 64,
                            paddingLeft: 80,
                            paddingRight: 24,
                            bottom: 0,
                            left: 0,
                            zIndex: 2,
                            opacity: isRightSideVisible ? 1 : 0,
                        }}
                    >
                        <WhiteTitle>{whiteTextTitle}</WhiteTitle>
                        <div style={{ paddingTop: 10 }} />
                        <WhiteSubTitle>{whiteTextSubTitle}</WhiteSubTitle>
                    </div>
                )}
            </OnboardingFormContainerRight>
        </Wrapper>
    )
}

export default OnboardingLayoutDesktop
