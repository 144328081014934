import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
// import * as Sentry from "@sentry/react";

import createSagaMiddleware from 'redux-saga'
// import from module created in types.d.ts gets imported
// utils
// material for the store:
import rootReducer from './root-reducer'
import { thunk } from 'redux-thunk'
import rootSaga from './root-saga'

const sagaMiddleware = createSagaMiddleware()

// const store = createStore(rootReducer(history), initialState, enhancer)

export const history = createBrowserHistory()

const { routerMiddleware } = createReduxHistoryContext({
    history,
    reduxTravelling: process.env.NODE_ENV === 'development',
    savePreviousLocations: 1,
})

const store = configureStore({
    reducer: rootReducer(history),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActionPaths: ['payload.files'],
            },
        }).concat([thunk, routerMiddleware, sagaMiddleware]),
})

sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type IRootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
