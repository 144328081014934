import { IRelativeTimeData, IReminder } from 'entityModels'
import { getRelativeTimeData } from '../../../helpers/time/relativeTime'
import { fromIsoStringToDDMMYYY } from '../time'
import { IReminderAPIRes } from '../../services/types'

export const ConvertDateToStateReminders = (
    remindersFromApi: IReminderAPIRes[],
    dueDate?: string
): IReminder[] => {
    let rem: IReminder[] = []

    for (const element of remindersFromApi) {
        if (element.scheduled_at) {
            let convertedDate: any = fromIsoStringToDDMMYYY(
                element.scheduled_at
            )

            let relTime: IRelativeTimeData | undefined = undefined

            if (dueDate && dueDate !== '' && dueDate !== undefined) {
                if (dueDate !== element.scheduled_at) {
                    relTime = getRelativeTimeData(
                        new Date(dueDate),
                        new Date(element.scheduled_at)
                    )
                }
            }

            rem.push({
                id: element.id,
                date: convertedDate,
                relativeTime: relTime ? relTime : undefined,
                displayDateText: relTime
                    ? `${relTime.n} ${relTime.timeType} ${
                          dueDate
                              ? new Date(dueDate) >
                                new Date(element.scheduled_at)
                                  ? 'before'
                                  : 'after'
                              : 'before'
                      }`
                    : convertedDate,
            })
        }
    }

    return rem
}
