import styled from 'styled-components'
import useThemes from '../../../../providers/theme/hooks'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import { motion } from 'framer-motion'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import { IJourneySectionID } from 'journeyInterfaces'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import { device } from '../../displays/devices'

type IStyle = {
    $theme: ITheme
    $sectionID: IJourneySectionID
}

const Container = styled.div<IStyle>`
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 72px;
    z-index: 100;
    background-color: ${(props) =>
        `${colours[props.$theme].background_default}`};
    border-top: ${(props) => `1px solid ${colours[props.$theme].border_muted}`};
    transition: all 100ms;
`

const SubContainer = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding-left: 24px;
    padding-right: 24px;

    @media ${device.ipad} {
        gap: 40px;
    }
`

const BtnWrapperLeft = styled(motion.button)<IStyle>`
    outline: none;
    border: none;
    display: flex;
    padding: 0px;
    height: 40px;
    width: 40px;
    min-width: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 150ms;
    background-color: transparent;
    border-radius: 4px;
    border: ${(props) => `1px solid ${colours[props.$theme].border_muted}`};
`

const BtnWrapperRight = styled(motion.button)<IStyle>`
    outline: none;
    border: none;
    display: flex;
    padding: 0px;
    height: 40px;
    width: 40px;
    min-width: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 150ms;
    border-radius: 4px;
    background-color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_500
        }`};
`

type Props = {
    sectionID: IJourneySectionID
    onBack: () => void
    onNext: () => any
    currentStep: number
    totalSteps: number
    isNextDisabled?: boolean
}
const JourneyBottomActionBarMobile = (props: Props) => {
    let { currentStep, totalSteps, sectionID, onBack, onNext, isNextDisabled } =
        props
    let { theme } = useThemes()
    return (
        <Container $theme={theme} $sectionID={sectionID}>
            <SubContainer>
                <BtnWrapperLeft
                    $theme={theme}
                    $sectionID={sectionID}
                    onClick={onBack}
                    whileTap={{ scale: 1.2 }}
                >
                    <div style={{ transform: 'rotate(90deg)' }}>
                        <ChevronDown
                            size="16"
                            color={colours[theme].text_darker}
                        />
                    </div>
                </BtnWrapperLeft>
                <FormStepProgress
                    screen_name="mobile"
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    activeColour={
                        journey_colours[theme].section[sectionID].primary_500
                    }
                    passiveColour={
                        journey_colours[theme].section[sectionID].primary_100
                    }
                />

                <BtnWrapperRight
                    onClick={onNext}
                    whileTap={isNextDisabled ? undefined : { scale: 1.2 }}
                    $theme={theme}
                    $sectionID={sectionID}
                    disabled={isNextDisabled}
                    style={
                        isNextDisabled
                            ? {
                                  backgroundColor:
                                      colours[theme]
                                          .background_disabled_neutral,
                              }
                            : {}
                    }
                >
                    <div style={{ transform: 'rotate(-90deg)' }}>
                        <ChevronDown
                            size="16"
                            color={colours[theme].background_default}
                        />
                    </div>
                </BtnWrapperRight>
            </SubContainer>
        </Container>
    )
}

export default JourneyBottomActionBarMobile
