import styled from 'styled-components'
import InsuranceTechInfoCardContent from '../../molecules/cards/insurance/insuranceTechInfoCardContent'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import {
    IInsuranceQuoteApplication_Car,
    IInsuranceQuoteApplication_MainDriver,
    IInsuranceQuoteApplication_OtherDriver,
    insuranceActions,
} from '../../../redux/insuranceQuoteApplication/reducer'
import InsuranceVehiclesToInsureCardContent from '../../molecules/cards/insurance/insuranceVehiclesToInsureCardContent'
import PolicyHolderItem from '../../atoms/list/policy_holder_item'
import { IInsuranceApplicationSectionCard } from '../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import InsurancePolicyDriversCardContent from '../../molecules/cards/insurance/insurancePolicyDriversCardContent'
import InsurancePolicyAddressesCardContent from '../../molecules/cards/insurance/insurancePolicyAddressesCardContent'
import YesNoRadioInput from '../../molecules/editOrCreateModeSingleFields/yesOrNoRadioInput/yesOrNoRadioInput'
import InfoBox from '../../atoms/info/infoBox'
import InsuranceSingleDriverCardPlain from '../../molecules/cards/insurance/singleDriverCardPlain'
import InsuranceOtherDriverDriveListCardContent from '../../molecules/cards/insurance/insuranceOtherDriverDriveListCardContent'
import InsuranceEverydayVehiclesCardContent from '../../molecules/cards/insurance/insuranceEverydayVehiclesCardContent'
import { IInsuranceAddress_API } from '../../../redux/insuranceQuoteApplication/types'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { useEffect, useRef } from 'react'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import { useNavigate } from 'react-router-dom'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
`

type Props = {
    card: IInsuranceApplicationSectionCard
    addressesList?: IInsuranceAddress_API[]
    isMobile?: boolean
    carDashboardID?: string
    mainDriver?: IInsuranceQuoteApplication_MainDriver
    otherDriver?: IInsuranceQuoteApplication_OtherDriver
    applicationID?: string | undefined
}

const InsuranceApplicationCardContentManager = (props: Props) => {
    const navigate = useNavigate()

    let { card, addressesList, mainDriver, otherDriver, isMobile } = props

    let card_id = card.id

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    let carsData = useAppSelector((state) => state.entities.carsData.cars)
    let vehicles_to_insure_list = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.submitted_data
                .vehicles_to_insure_ids_list
    )

    let all_vehicles = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data.vehicles
    )

    let everyday_car_ids = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.submitted_data.everyday_car_ids
    )

    let external_car_ids = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.submitted_data.external_car_ids
    )

    let main_policy_holder = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data.main_driver
    )

    let other_drivers = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data.other_drivers
    )

    let selected_named_drivers = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.submitted_data
                .selected_named_drivers
    )

    let appli_id = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data?.id
    )

    const dispatch = useAppDispatch()

    let car_dashboard_id: string | undefined = props.carDashboardID

    let curentVehicle =
        all_vehicles && car_dashboard_id
            ? all_vehicles[car_dashboard_id]
            : undefined

    let emptyVehicle: IInsuranceQuoteApplication_Car = {
        tech_info: {},
        entities: { modifications: [] },
        daytime_parking_info: {},
        eveningtime_parking_info: {},
    }

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )

    let renderBody = () => {
        switch (card_id) {
            case 'tech_info':
                return curentVehicle && car_dashboard_id && userLoggedIn ? (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            paddingTop: 16,
                        }}
                    >
                        <InsuranceTechInfoCardContent
                            vehicleid={car_dashboard_id}
                            vehicle={curentVehicle}
                            userLoggedIn={userLoggedIn}
                            isMobile={isMobile}
                        />
                    </div>
                ) : car_dashboard_id && userLoggedIn ? (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            paddingTop: 16,
                        }}
                    >
                        <InsuranceTechInfoCardContent
                            vehicleid={car_dashboard_id}
                            vehicle={emptyVehicle}
                            userLoggedIn={userLoggedIn}
                            isMobile={isMobile}
                        />
                    </div>
                ) : null
            case 'vehicles_to_insure':
                return (
                    <InsuranceVehiclesToInsureCardContent
                        applicationID={props.applicationID}
                        isMobile={isMobile}
                        carsData={carsData}
                        insuranceVehiclesData={all_vehicles}
                        vehicles_to_insure_list={vehicles_to_insure_list}
                        everyday_car_ids={everyday_car_ids}
                        onCardClick={(vehicle_id) => {
                            dispatch(dropDownActions.reset_make_model_temps())
                            navigate(
                                `/insurance/application/${appli_id}/car/${vehicle_id}`
                            )
                        }}
                        // onRemove={(vehicle_id: string) => {
                        //     if (application?.status === 'DRAFT') {
                        //         let selected_ids =
                        //             vehicles_to_insure_list &&
                        //             vehicles_to_insure_list.indexOf(
                        //                 vehicle_id
                        //             ) !== -1
                        //                 ? vehicles_to_insure_list.filter(
                        //                       (id) => vehicle_id !== id
                        //                   )
                        //                 : undefined

                        //         if (selected_ids) {
                        //             dispatch(
                        //                 insuranceActions.select_vehicles_to_insure(
                        //                     selected_ids
                        //                 )
                        //             )
                        //             setTimeout(() => {
                        //                 dispatch(
                        //                     insuranceActions.submit_vehiclesToInsure_request()
                        //                 )
                        //             }, 100)
                        //         }
                        //     }
                        // }}
                    />
                )
            case 'everyday_vehicles':
                return (
                    <InsuranceEverydayVehiclesCardContent
                        isMobile={isMobile}
                        garageVehicles={carsData}
                        vehicles={all_vehicles}
                        everyday_vehicles_list={everyday_car_ids}
                        external_vehicles_list={external_car_ids}
                        insured_vehicles_list={vehicles_to_insure_list}
                        onCardClick={(vehicle_id) => {
                            dispatch(dropDownActions.reset_make_model_temps())
                            navigate(
                                `/insurance/application/${appli_id}/everyday_car/${vehicle_id}`
                            )
                        }}
                        onRemove={(vehicle_id: string) => {
                            if (application?.status === 'DRAFT') {
                                let selected_ids =
                                    everyday_car_ids &&
                                    everyday_car_ids.indexOf(vehicle_id) !== -1
                                        ? everyday_car_ids.filter(
                                              (id: string) => vehicle_id !== id
                                          )
                                        : undefined

                                if (selected_ids) {
                                    dispatch(
                                        insuranceActions.submit_everyday_car_ids_request(
                                            selected_ids
                                        )
                                    )
                                }
                            }
                        }}
                    />
                )
            case 'other_driver_drive_list':
                return (
                    <InsuranceOtherDriverDriveListCardContent
                        isMobile={isMobile}
                        carsData={carsData}
                        vehicles={all_vehicles}
                        named_driver={otherDriver}
                        drive_list={otherDriver?.drive_list}
                        vehicles_to_insure_list={vehicles_to_insure_list}
                        onCardClick={(vehicle_id) => {
                            dispatch(dropDownActions.reset_make_model_temps())
                            navigate(
                                `/insurance/application/${appli_id}/car/${vehicle_id}`
                            )
                        }}
                        onSkeletonClick={() => {
                            if (card && card.onClick) {
                                card?.onClick()
                            }
                        }}
                    />
                )

            case 'main_policy_holder':
                return main_policy_holder && card?.arrayOfProgressBarsValues ? (
                    <PolicyHolderItem
                        policy_holder={main_policy_holder}
                        completion={card.arrayOfProgressBarsValues[0]}
                        onClick={card.onClick}
                        isMobile={isMobile}
                        sectionID="main_policy_holder"
                    />
                ) : null

            case 'is_everyday_car':
                if (car_dashboard_id) {
                    let isEveryday: boolean =
                        everyday_car_ids &&
                        everyday_car_ids.indexOf(car_dashboard_id) === -1
                            ? false
                            : true

                    return (
                        <div
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                paddingTop: isMobile ? 8 : 0,
                            }}
                        >
                            <YesNoRadioInput
                                value={isEveryday}
                                isYesActive={isEveryday}
                                isNoActive={!isEveryday}
                                isDisabled={
                                    application?.status === 'DRAFT'
                                        ? false
                                        : true
                                }
                                onChange={(v: boolean) => {
                                    if (application?.status === 'DRAFT') {
                                        card.onToggle && card.onToggle(v)
                                    }
                                }}
                            />
                            {isMobile && card.tooltipTxt ? (
                                <div
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        paddingTop: 24,
                                    }}
                                >
                                    <InfoBox text={card.tooltipTxt} />
                                </div>
                            ) : null}
                        </div>
                    )
                } else {
                    return null
                }

            case 'drivers':
                return userLoggedIn ? (
                    <InsurancePolicyDriversCardContent
                        card={card}
                        other_drivers={other_drivers}
                        selected_named_drivers={selected_named_drivers}
                        main_policy_holder={main_policy_holder}
                        isMobile={isMobile}
                    />
                ) : null

            case 'basic_details_main_policy_driver':
                return mainDriver ? (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            paddingTop: 24,
                        }}
                    >
                        <InsuranceSingleDriverCardPlain
                            mainDriver={mainDriver}
                            removeStat={true}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            paddingTop: 24,
                        }}
                    >
                        <InsuranceSingleDriverCardPlain onlyPlaceholder />
                    </div>
                )
            case 'basic_details_other_driver':
                return otherDriver ? (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            paddingTop: 24,
                        }}
                    >
                        <InsuranceSingleDriverCardPlain
                            removeStat={true}
                            otherDriver={otherDriver}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            paddingTop: 24,
                        }}
                    >
                        <InsuranceSingleDriverCardPlain onlyPlaceholder />
                    </div>
                )
            case 'addresses':
                return userLoggedIn && addressesList ? (
                    <InsurancePolicyAddressesCardContent
                        addressesList={addressesList}
                        isMobile={isMobile}
                        applicationID={props.applicationID}
                    />
                ) : null

            default:
                return <div />
        }
    }

    let params = new URLSearchParams(location.search)
    let anchor_params = params.get('anchor')

    let anchoredRefMobile: any = useRef()
    let anchoredRefDesktop: any = useRef()

    const removeAnchor = () => {
        // in future, can take any FilterID

        if (anchor_params) {
            let currURL = new URL(window.location.href)
            const params2 = new URLSearchParams(currURL.search)
            params2.delete('anchor')
            navigate({ search: params2.toString() }, { replace: true })
        }
    }

    useEffect(() => {
        if (anchor_params) {
            if (isMobile) {
                scrollIntoView(anchoredRefMobile?.current, {
                    behavior: 'smooth',
                    block: 'center',
                })
            } else {
                scrollIntoView(anchoredRefDesktop?.current, {
                    behavior: 'smooth',
                    block: 'center',
                })
            }

            setTimeout(() => {
                removeAnchor()
            }, 300)
        }
    }, [anchor_params])

    return (
        <Wrapper
            ref={isMobile ? anchoredRefMobile : anchoredRefDesktop}
            id={`${props.card.id}_${isMobile ? 'mobile' : 'desktop'}`}
        >
            {renderBody()}
        </Wrapper>
    )
}

export default InsuranceApplicationCardContentManager
