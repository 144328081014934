import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import { Radio } from 'antd'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import { IList } from 'entityModels'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import { IUSerGeneralInfoUpdatePayload } from '../../../../redux/user/types'

const ColumnAlignStart = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
`

const RadioValue = styled.span`
    padding-left: 5px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 14px;
`

const StyleRadio = styled(Radio)`
    .ant-radio-inner {
        background-color: var(--bg-color, #fff) !important;
    }
`

const Padding24 = styled.div`
    padding-top: 24px;
`

interface Values {
    unique_value_mobile: string
}

type Iobj = {
    [key: string]: IList
}

interface OtherProps {
    title: any
    item: IList
    data: Iobj
    ids_list: string[]
    toggle: any
    handleSubmit: any
    field_id: string
    userid: string
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        title,
        ids_list,
        data,
        setFieldValue,
        values,
        toggle,
        handleSubmit,
    } = props

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>{title}</ModalTopBar>
            <ModalInnerContentWrapperMobile>
                <Radio.Group defaultValue={values.unique_value_mobile}>
                    <ColumnAlignStart>
                        {ids_list.map((id: string, index: any) => {
                            if (data[id] !== undefined) {
                                return (
                                    <StyleRadio
                                        key={`preference_${index}`}
                                        style={{ marginBottom: '12px' }}
                                        type="radio"
                                        value={data[id].id}
                                        onChange={() =>
                                            setFieldValue(
                                                'unique_value_mobile',
                                                data[id].id
                                            )
                                        }
                                        name={data[id].id}
                                    >
                                        <RadioValue>{data[id].text}</RadioValue>
                                    </StyleRadio>
                                )
                            } else return <div style={{ display: 'none' }} />
                        })}
                    </ColumnAlignStart>
                </Radio.Group>
                <Padding24 />
                <ButtonsStandardBottomRowMobile
                    cancel={toggle}
                    submit={handleSubmit}
                    confirmationtext="save"
                />
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const PreferencesRadioFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        unique_value_mobile: props.item.id,
    }),
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        let dataToSend: IUSerGeneralInfoUpdatePayload = {
            id: props.userid,
            data: {
                user_preferences: {
                    [props.field_id]: values.unique_value_mobile,
                },
            },
        }
        props.handleSubmit(dataToSend)
        props.toggle()

        setSubmitting(false)
    },
})(InnerForm)

export default PreferencesRadioFormMobile
