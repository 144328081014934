import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../../services'
import { ConvertRawToNewImageEntity } from '../../../conversions/entities/ImageTypeConversions'
import posthog from 'posthog-js'
import { ResponseGenerator } from 'sagaInterfaces'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IPayloadCarGalleryImageFromApi, IReduxError } from '../../cars/types'
import { IGalleryImage, IUpdateCarGalleryImagePayload } from '../types'
import { carActions } from '../../cars/reducer'
import { galleriesActions } from '../reducer'

export function* updateCarGalleryImageApi(
    payload: IUpdateCarGalleryImagePayload
) {
    try {
        const result: ResponseGenerator =
            yield api.entities.mutateGallery.updateCarGalleryImage(payload)
        const rawData: IPayloadCarGalleryImageFromApi = result.data[0]

        const convertedImage: IGalleryImage = yield call(
            ConvertRawToNewImageEntity,
            rawData
        )

        yield put(galleriesActions.updateCarGalleryImageSuccess(convertedImage))

        if (payload.body.cover) {
            yield put(
                carActions.updateCoverImgId({
                    carid: payload.carid,
                    new_cover_id: rawData.uid,
                })
            )
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: updateCarGalleryImageApi,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(galleriesActions.updateCarGalleryImageError(typedError))
            return
        }
    }
}

function* watcherUpdateCarGalleryImage() {
    while (true) {
        const { payload } = yield take(
            galleriesActions.updateCarGalleryImageRequest
        )

        yield call(updateCarGalleryImageApi, payload)

        // Capture event
        posthog.capture('UPDATE CAR IMAGE')
    }
}

const gallery_sagas: any[] = [fork(watcherUpdateCarGalleryImage)]

export default gallery_sagas
