import React from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { device } from '../../templates/displays/devices'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import GaragesSharedWithYouCardDesktop from '../../molecules/cards/sharing/garagesSharedWithYouCardDesktop'
import WhiteBgNoBorder from '../../atoms/Button/whiteBgNoBorder'
import { sortReceivedSharesByAlphabetical } from '../../../helpers/sort/sortByAlphabetical'
import { sortDirectReceivedSharesByDate } from '../../../helpers/sort/sortByDate'
import { AnimatePresence } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IUpdateShareReveivedStatusApi_arg } from '../../../redux/services/types'
import { IDirectSharesReceivedObj } from '../../../redux/user/types'
import { IPrivateShareReceivedItemApprovalPayload } from '../../../redux/entities/cars/types'
import { usersActions } from '../../../redux/user/reducer'

interface Props {
    sort_id: string
}

const WrapperDesktopAdjusted = styled.div`
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px;
        padding-top: 20px;
        background-color: var(--primary_04);
    }

    @media ${device.ipad} {
        justify-content: center;
    }
`
const SubWrapperDesktopAdjusted = styled.div`
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    @media ${device.ipad} {
        justify-content: center;
    }
`

const SubWrapperDesktopAdjustedViewAll = styled.div<{ $noMaxWidth?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    max-width: ${(props) => (props.$noMaxWidth ? 'none' : '1100px;')};
    @media screen and (max-width: 1360px) {
        grid-template-columns: repeat(1, 1fr);
        max-width: 500px;
    }
`

const GridCardWrapper = styled.div``

const GridBtnWrapper = styled.div`
    @media screen and (min-width: 1360px) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
        max-width: 1070px;
    }
    @media screen and (min-width: 1600px) {
        max-width: 1132px;
    }
    max-width: 500px;
`

const SectionLine = styled.div`
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    width: 100%;
    box-sizing: border-box;
`

const SmallPadding = styled.div`
    padding-top: 30px;
`

const SharedWithYouGaragesManagerDesktop: React.FC<Props> = ({ sort_id }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const updateShareReceivedStatus: any = (
        p: IUpdateShareReveivedStatusApi_arg
    ) => dispatch(usersActions.updateShareReceivedStatusRequest(p))

    const generateSharesDisplayIds = (
        share_ids: string[],
        shares_data: IDirectSharesReceivedObj,
        sort_id: string
    ) => {
        if (sort_id === 'alphabetical') {
            return sortReceivedSharesByAlphabetical(
                share_ids,
                shares_data,
                'garage'
            )
        } else if (sort_id === 'by_oldest') {
            return sortDirectReceivedSharesByDate(
                share_ids,
                shares_data,
                'oldest'
            )
        }
        return sortDirectReceivedSharesByDate(
            share_ids,
            shares_data,
            'most_recent'
        )
    }

    const garages_shared_received_obj =
        userLoggedIn &&
        userLoggedIn.received_direct_shares &&
        userLoggedIn.received_direct_shares.garages &&
        userLoggedIn.received_direct_shares.garages

    const garagesReceivedData: IDirectSharesReceivedObj | undefined =
        garages_shared_received_obj &&
        garages_shared_received_obj.shares_received_ids.length > 0
            ? garages_shared_received_obj.shares_received_data
            : undefined

    const garagesReceivedIds: string[] | undefined =
        garagesReceivedData &&
        garages_shared_received_obj &&
        garages_shared_received_obj.shares_received_ids.length > 0
            ? garages_shared_received_obj.shares_received_ids
            : undefined

    const pendingGaragesReceivedIds: string[] | undefined =
        garagesReceivedData &&
        garages_shared_received_obj &&
        garages_shared_received_obj.pending_shares_received_ids.length > 0
            ? sort_id !== ''
                ? generateSharesDisplayIds(
                      [
                          ...garages_shared_received_obj.pending_shares_received_ids,
                      ],
                      garagesReceivedData,
                      sort_id
                  )
                : garages_shared_received_obj.pending_shares_received_ids
            : undefined

    const acceptedGaragesReceivedIds: string[] | undefined =
        garagesReceivedData &&
        garages_shared_received_obj &&
        garages_shared_received_obj.accepted_shares_received_ids.length > 0
            ? sort_id !== ''
                ? generateSharesDisplayIds(
                      [
                          ...garages_shared_received_obj.accepted_shares_received_ids,
                      ],
                      garagesReceivedData,
                      sort_id
                  )
                : garages_shared_received_obj.accepted_shares_received_ids
            : undefined

    const ignoredGaragesReceivedIds: string[] | undefined =
        garagesReceivedData &&
        garages_shared_received_obj &&
        garages_shared_received_obj.ignored_shares_received_ids.length > 0
            ? sort_id !== ''
                ? generateSharesDisplayIds(
                      [
                          ...garages_shared_received_obj.ignored_shares_received_ids,
                      ],
                      garagesReceivedData,
                      sort_id
                  )
                : garages_shared_received_obj.ignored_shares_received_ids
            : undefined

    const params = new URLSearchParams(location.search)
    const view_id = params.get('view_id')

    return (
        <WrapperDesktopAdjusted>
            {(view_id === 'view_all' || !view_id) && (
                <>
                    {garagesReceivedData && pendingGaragesReceivedIds && (
                        <SubWrapperDesktopAdjustedViewAll
                            $noMaxWidth={
                                pendingGaragesReceivedIds.length === 1 &&
                                acceptedGaragesReceivedIds &&
                                acceptedGaragesReceivedIds.length < 2
                                    ? true
                                    : false
                            }
                        >
                            <AnimatePresence>
                                {pendingGaragesReceivedIds.map(
                                    (shareid: string, index: number) => {
                                        if (
                                            garagesReceivedData![shareid] &&
                                            index < 2
                                        ) {
                                            return (
                                                <GridCardWrapper
                                                    key={`pending_view_all_garage_${shareid}_desktop`}
                                                >
                                                    <GaragesSharedWithYouCardDesktop
                                                        share_received={
                                                            garagesReceivedData![
                                                                shareid
                                                            ]
                                                        }
                                                        hasMarginRight
                                                        shareApprovalAction={(
                                                            p: IPrivateShareReceivedItemApprovalPayload
                                                        ) =>
                                                            updateShareReceivedStatus(
                                                                {
                                                                    share_id:
                                                                        p.shareId,
                                                                    status: p.approvalId,
                                                                    entity_type:
                                                                        'garage',
                                                                }
                                                            )
                                                        }
                                                    />
                                                </GridCardWrapper>
                                            )
                                        } else {
                                            return null
                                        }
                                    }
                                )}
                            </AnimatePresence>

                            {pendingGaragesReceivedIds &&
                                pendingGaragesReceivedIds.length > 2 && (
                                    <GridBtnWrapper>
                                        <WhiteBgNoBorder
                                            text={`See ${
                                                pendingGaragesReceivedIds.length -
                                                2
                                            } more shares pending`}
                                            onClick={() =>
                                                navigate(
                                                    `/sharing/with-you?view_id=pending`
                                                )
                                            }
                                        />
                                    </GridBtnWrapper>
                                )}
                        </SubWrapperDesktopAdjustedViewAll>
                    )}

                    <SmallPadding />

                    {acceptedGaragesReceivedIds &&
                        acceptedGaragesReceivedIds.length > 0 &&
                        pendingGaragesReceivedIds &&
                        pendingGaragesReceivedIds.length > 0 && (
                            <>
                                <SmallPadding
                                    style={{
                                        paddingTop: '20px',
                                    }}
                                />
                                <SectionLine /> <SmallPadding />
                            </>
                        )}

                    {garagesReceivedData && acceptedGaragesReceivedIds && (
                        <SubWrapperDesktopAdjustedViewAll
                            $noMaxWidth={
                                acceptedGaragesReceivedIds.length === 1 &&
                                pendingGaragesReceivedIds &&
                                pendingGaragesReceivedIds.length < 2
                                    ? true
                                    : false
                            }
                        >
                            <AnimatePresence>
                                {acceptedGaragesReceivedIds.map(
                                    (shareid: string, index: number) => {
                                        if (garagesReceivedData![shareid]) {
                                            return (
                                                <GaragesSharedWithYouCardDesktop
                                                    key={`accepted_view_all_garage_${shareid}_desktop`}
                                                    share_received={
                                                        garagesReceivedData![
                                                            shareid
                                                        ]
                                                    }
                                                    hasMarginRight
                                                    shareApprovalAction={(
                                                        p: IPrivateShareReceivedItemApprovalPayload
                                                    ) =>
                                                        updateShareReceivedStatus(
                                                            {
                                                                share_id:
                                                                    p.shareId,
                                                                status: p.approvalId,
                                                                entity_type:
                                                                    'garage',
                                                            }
                                                        )
                                                    }
                                                />
                                            )
                                        } else {
                                            return null
                                        }
                                    }
                                )}
                            </AnimatePresence>
                        </SubWrapperDesktopAdjustedViewAll>
                    )}

                    {(!garagesReceivedIds ||
                        garagesReceivedIds.length === 0) && (
                        <NoDataRectangle text="Nothing shared with you" />
                    )}
                </>
            )}
            {view_id === 'pending' &&
                (garagesReceivedData &&
                pendingGaragesReceivedIds &&
                pendingGaragesReceivedIds.length > 0 ? (
                    <SubWrapperDesktopAdjusted>
                        <AnimatePresence>
                            {pendingGaragesReceivedIds.map(
                                (shareid: string, index: number) => {
                                    if (garagesReceivedData![shareid]) {
                                        return (
                                            <GaragesSharedWithYouCardDesktop
                                                key={`pending_garage_${shareid}_desktop`}
                                                share_received={
                                                    garagesReceivedData![
                                                        shareid
                                                    ]
                                                }
                                                hasMarginRight
                                                shareApprovalAction={(
                                                    p: IPrivateShareReceivedItemApprovalPayload
                                                ) =>
                                                    updateShareReceivedStatus({
                                                        share_id: p.shareId,
                                                        status: p.approvalId,
                                                        entity_type: 'garage',
                                                    })
                                                }
                                            />
                                        )
                                    } else {
                                        return null
                                    }
                                }
                            )}
                        </AnimatePresence>
                    </SubWrapperDesktopAdjusted>
                ) : (
                    <NoDataRectangle text="You don't have pending garage shares" />
                ))}
            {view_id === 'accepted' &&
                (garagesReceivedData &&
                acceptedGaragesReceivedIds &&
                acceptedGaragesReceivedIds.length > 0 ? (
                    <SubWrapperDesktopAdjusted>
                        <AnimatePresence>
                            {acceptedGaragesReceivedIds.map(
                                (shareid: string, index: number) => {
                                    if (garagesReceivedData![shareid]) {
                                        return (
                                            <GaragesSharedWithYouCardDesktop
                                                key={`accepted_garage_${shareid}_desktop`}
                                                share_received={
                                                    garagesReceivedData![
                                                        shareid
                                                    ]
                                                }
                                                hasMarginRight
                                                shareApprovalAction={(
                                                    p: IPrivateShareReceivedItemApprovalPayload
                                                ) =>
                                                    updateShareReceivedStatus({
                                                        share_id: p.shareId,
                                                        status: p.approvalId,
                                                        entity_type: 'garage',
                                                    })
                                                }
                                            />
                                        )
                                    } else {
                                        return null
                                    }
                                }
                            )}
                        </AnimatePresence>
                    </SubWrapperDesktopAdjusted>
                ) : (
                    <NoDataRectangle text="You don't have accepted garage shares" />
                ))}
            {view_id === 'ignored' &&
                (garagesReceivedData &&
                ignoredGaragesReceivedIds &&
                ignoredGaragesReceivedIds.length > 0 ? (
                    <SubWrapperDesktopAdjusted>
                        <AnimatePresence>
                            {ignoredGaragesReceivedIds.map(
                                (shareid: string, index: number) => {
                                    if (garagesReceivedData![shareid]) {
                                        return (
                                            <GaragesSharedWithYouCardDesktop
                                                key={`ignored_garage_${shareid}_desktop`}
                                                share_received={
                                                    garagesReceivedData![
                                                        shareid
                                                    ]
                                                }
                                                hasMarginRight
                                                shareApprovalAction={(
                                                    p: IPrivateShareReceivedItemApprovalPayload
                                                ) =>
                                                    updateShareReceivedStatus({
                                                        share_id: p.shareId,
                                                        status: p.approvalId,
                                                        entity_type: 'garage',
                                                    })
                                                }
                                            />
                                        )
                                    } else {
                                        return null
                                    }
                                }
                            )}
                        </AnimatePresence>
                    </SubWrapperDesktopAdjusted>
                ) : (
                    <NoDataRectangle text="You don't have ignored garage shares" />
                ))}
        </WrapperDesktopAdjusted>
    )
}

export default SharedWithYouGaragesManagerDesktop
