import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const HistoryFilesIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 8.35046H22.75V15.8505H1.25V8.35046ZM2.75 9.85046V14.3505H21.25V9.85046H2.75Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 4.35022L20 4.35022L20 5.85022L4 5.85022L4 4.35022Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 18.3527L20 18.3527L20 19.8527L4 19.8527L4 18.3527Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default HistoryFilesIcon
