import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import {
    isHistoryFileChecked,
    isHistoryFileDisabled,
    isEntryAttachmentsChecked,
    isEntryCostsChecked,
    isEntryCostDisabled,
    isCostAttachmentChecked,
} from './permissionChecksLogic'
import {
    ISharingPermissionIDS,
    IDirectShareOwnedItem,
} from '../../../../redux/entities/cars/types'

export interface Props {
    onChange: (val: ISharingPermissionIDS) => void
    share_being_edited: IDirectShareOwnedItem
    readOnlyMode?: boolean
    noPermissionDetails?: boolean
    isCreation?: boolean
}

type IStyled = {
    $isDisabled?: boolean
}

const Row = styled.div`
    width: auto;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
`

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
`

const InfoTxt = styled(motion.div)<IStyled>`
    font-family: 'Lato';
    font-size: 14px;
    padding-left: 10px;
    color: ${(props: IStyled) =>
        props.$isDisabled
            ? 'var(--text-muted)'
            : 'var(--text-darker, #616161)'};

    cursor: ${(props: IStyled) =>
        props.$isDisabled ? 'not-allowed' : 'pointer'};
`

const ItalicInfoTxt = styled.div<IStyled>`
    font-size: 12px;
    font-style: italic;
    color: ${(props: IStyled) =>
        props.$isDisabled ? 'var(--text-muted)' : 'var(--text-muted)'};
`

const VerticalLine = styled.section<{ $noPermissionDetails?: boolean }>`
    width: 1px;
    position: absolute;
    top: ${(props) => (props.$noPermissionDetails ? '25px' : '35px')};
    left: 12px;
    bottom: 20px;
    background-color: var(--border-muted, #e5e5e5);
`

const HorizontalLine = styled.section<{ $noPermissionDetails?: boolean }>`
    height: 1px;
    width: 100vw;
    background-color: var(--border-muted, #e5e5e5);
    margin-bottom: 15px;
    margin-top: -10px;
    overflow-x: hidden;
`
const SubWrapper1 = styled.section`
    padding-left: 0px;
    position: relative;
`
const SubWrapper2 = styled.section`
    padding-left: 40px;
    position: relative;
`
const SubWrapper3 = styled.section`
    padding-left: 40px;
    position: relative;
`

const CarSharePermissionTableCheckboxesMobile: React.FC<Props> = ({
    onChange,
    share_being_edited,
    readOnlyMode,
    noPermissionDetails,
    isCreation,
}) => {
    return (
        <Wrapper>
            <SubWrapper1>
                {isCreation ? (
                    <HorizontalLine style={{ marginLeft: '-20px' }} />
                ) : (
                    <VerticalLine $noPermissionDetails={noPermissionDetails} />
                )}
                <Row>
                    <CustomAnimatedCheckboxField
                        small
                        width="100%"
                        hasWhiteBg
                        onChange={() => {
                            // onChange('history file')
                        }}
                        name={'history_file'}
                        checked={isHistoryFileChecked(
                            share_being_edited,
                            readOnlyMode
                        )}
                        onClick={() => {
                            onChange('history file')
                        }}
                        isDisabled={isHistoryFileDisabled(
                            share_being_edited,
                            readOnlyMode
                        )}
                    >
                        <InfoTxt
                            style={{ transform: 'translateY(-2px)' }}
                            $isDisabled={isHistoryFileDisabled(
                                share_being_edited,
                                readOnlyMode
                            )}
                        >
                            Include Digital History File
                            {!noPermissionDetails && (
                                <ItalicInfoTxt>
                                    Include basic history information
                                </ItalicInfoTxt>
                            )}
                        </InfoTxt>
                    </CustomAnimatedCheckboxField>
                </Row>

                <SubWrapper2>
                    {isCreation ? (
                        <HorizontalLine style={{ marginLeft: '-60px' }} />
                    ) : (
                        <VerticalLine
                            $noPermissionDetails={noPermissionDetails}
                            style={{ left: '52px' }}
                        />
                    )}
                    <Row>
                        <CustomAnimatedCheckboxField
                            small
                            width="100%"
                            hasWhiteBg
                            onChange={() => {
                                // onChange('entry attachments')
                            }}
                            name={'entry_attachments'}
                            checked={isEntryAttachmentsChecked(
                                share_being_edited,
                                readOnlyMode
                            )}
                            onClick={() => {
                                onChange('entry attachments')
                            }}
                            isDisabled={readOnlyMode}
                        >
                            <InfoTxt
                                style={{ transform: 'translateY(-2px)' }}
                                $isDisabled={readOnlyMode}
                            >
                                Include Entry Attachments
                                {!noPermissionDetails && (
                                    <ItalicInfoTxt>
                                        Include the attachments on Entries in
                                        your history file
                                    </ItalicInfoTxt>
                                )}
                            </InfoTxt>
                        </CustomAnimatedCheckboxField>
                    </Row>
                    {isCreation && (
                        <HorizontalLine style={{ marginLeft: '-60px' }} />
                    )}
                    <Row>
                        <CustomAnimatedCheckboxField
                            small
                            hasWhiteBg
                            onChange={() => {
                                // onChange('entry costs')
                            }}
                            name={'entry_costs'}
                            checked={isEntryCostsChecked(
                                share_being_edited,
                                readOnlyMode
                            )}
                            onClick={() => {
                                onChange('entry costs')
                            }}
                            isDisabled={isEntryCostDisabled(
                                share_being_edited,
                                readOnlyMode
                            )}
                        >
                            <InfoTxt
                                style={{ transform: 'translateY(-2px)' }}
                                $isDisabled={isEntryCostDisabled(
                                    share_being_edited,
                                    readOnlyMode
                                )}
                            >
                                Include Costs
                                {!noPermissionDetails && (
                                    <ItalicInfoTxt>
                                        Include the costs associated with
                                        Entries in your history file
                                    </ItalicInfoTxt>
                                )}
                            </InfoTxt>
                        </CustomAnimatedCheckboxField>
                    </Row>
                    <SubWrapper3>
                        {isCreation ? (
                            <HorizontalLine style={{ marginLeft: '-100px' }} />
                        ) : (
                            <VerticalLine
                                $noPermissionDetails={noPermissionDetails}
                                style={{
                                    left: '52px',
                                    top: '0px',
                                    bottom: '20px',
                                }}
                            />
                        )}

                        <Row>
                            <CustomAnimatedCheckboxField
                                small
                                width="100%"
                                hasWhiteBg
                                onChange={() => {
                                    // onChange('entry cost attachments')
                                }}
                                name={'entry_cost_attachments'}
                                checked={isCostAttachmentChecked(
                                    share_being_edited,
                                    readOnlyMode
                                )}
                                onClick={() => {
                                    onChange('entry cost attachments')
                                }}
                                isDisabled={readOnlyMode}
                            >
                                <InfoTxt
                                    style={{
                                        transform: 'translateY(-2px)',
                                    }}
                                >
                                    Include Cost Attachments
                                    {!noPermissionDetails && (
                                        <ItalicInfoTxt>
                                            Include files attached to Costs
                                            (invoices)
                                        </ItalicInfoTxt>
                                    )}
                                </InfoTxt>
                            </CustomAnimatedCheckboxField>
                        </Row>
                        {isCreation && (
                            <HorizontalLine style={{ marginLeft: '-100px' }} />
                        )}
                    </SubWrapper3>
                </SubWrapper2>
            </SubWrapper1>
        </Wrapper>
    )
}

export default CarSharePermissionTableCheckboxesMobile
