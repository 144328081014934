import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions'
import { api } from '../../../services'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import posthog from 'posthog-js'
import { IDeleteShareAPi_Arg } from '../../../services/types'
import { IReduxError } from '../types'
import { carActions } from '../reducer'

export function* DeleteCarPublicShareSaga(p: actions.IDeleteShareReq) {
    try {
        let papi: IDeleteShareAPi_Arg = {
            share_id: p.share_id,
            share_type: 'link_share',
        }
        yield call(api.sharing.deleteShareAPI, papi)
        yield put(carActions.deleteCarPublicShareSuccess(p))
        // Capture event
        posthog.capture('delete car link-share')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: DeleteCarPublicShareSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(carActions.deleteCarPublicShareError(typedError))
            return
        }
    }
}

function* watcherDeletePublicCarShare() {
    while (true) {
        const { payload } = yield take(carActions.deleteCarPublicShareRequest)
        yield call(DeleteCarPublicShareSaga, payload)
    }
}

export function* DeleteCarDirectShareSaga(p: actions.IDeleteShareReq) {
    try {
        let papi: IDeleteShareAPi_Arg = {
            share_id: p.share_id,
            share_type: 'direct_share',
        }
        yield call(api.sharing.deleteShareAPI, papi)
        yield put(carActions.deleteDirectCarShareSuccess(p))
        // Capture event
        posthog.capture('DELETE CAR DIRECT SHARE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: DeleteCarDirectShareSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(carActions.deleteDirectCarShareError(typedError))
            return
        }
    }
}

function* watcherDeleteCarDirectShare() {
    while (true) {
        const { payload } = yield take(carActions.deleteCarDirectShareRequest)
        yield call(DeleteCarDirectShareSaga, payload)
    }
}

const delete_car_shares_sagas: any[] = [
    fork(watcherDeletePublicCarShare),
    fork(watcherDeleteCarDirectShare),
]

export default delete_car_shares_sagas
