import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useAppSelector } from '../../../../../../redux/store/hooks'
import {
    currency_data,
    currency_IDS,
} from '../../../../../../redux/timeline/data'
import CalendarIcon from '../../../../../atoms/icons/components/calendar'
import InputField from '../../../../../atoms/Inputfield/inputField'
import InputFieldNoFormikAnimated from '../../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { Select } from '../../../../../atoms/select'
import { FieldRow } from '../../../../insuranceQuote/formGenerators/renderElementMobile'
import useThemes from '../../../../../../providers/theme/hooks'
import {
    ITimelineItem,
    ICostItem,
} from '../../../../../../redux/timeline/types'

type Props = {
    entry: undefined | ITimelineItem
    setEntry: (p: ITimelineItem | undefined) => any
    isMobile?: boolean
}

export const historyFileBaseEntry: ITimelineItem = {
    costUIDS: undefined,
    id: '',
    occurred_at: undefined,
    title: undefined,
    categoryID: 'other',
    description: undefined,
    total_cost_amount_display_short: '0.00',
    total_cost_amount_display: '0.00',
    labels: [],
    notes: undefined,
    attachmentIDs: undefined,
    costItemsObj: undefined,
}

export const historyFileBaseEntryInsurance: ITimelineItem = {
    costUIDS: undefined,
    id: '',
    occurred_at: undefined,
    title: undefined,
    categoryID: 'insurance',
    description: undefined,
    total_cost_amount_display_short: '0.00',
    total_cost_amount_display: '0.00',
    labels: [],
    notes: undefined,
    attachmentIDs: undefined,
    costItemsObj: undefined,
    insurance_entry_policy_end_date: undefined,
    insurance_entry_policy_start_date: undefined,
    insurance_entry_broker: undefined,
    insurance_entry_underwriter: undefined,
}
export const JourneyHFInsuranceFormBody = (props: Props) => {
    let costt: ICostItem | undefined =
        props.entry?.costItemsObj &&
        props.entry?.costUIDS &&
        props.entry.costUIDS[0]
            ? props.entry?.costItemsObj[props.entry.costUIDS[0]]
            : props.entry?.costItemsObj && props.entry?.costItemsObj['id']

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    let placeholdertitle = `${
        userLoggedIn?.given_name && `${userLoggedIn?.given_name}'s`
    } Insurance policy`

    useEffect(() => {
        if (
            userLoggedIn &&
            userLoggedIn?.given_name &&
            (!props.entry?.title || props.entry?.title?.length === 0)
        ) {
            if (props.entry && props.entry.categoryID === 'insurance') {
                props.setEntry({
                    ...props.entry,
                    title: placeholdertitle,
                })
            } else {
                props.setEntry({
                    ...historyFileBaseEntryInsurance,
                    title: placeholdertitle,
                })
            }
        }
    }, [userLoggedIn])

    // console.log('entry', props.entry)

    const costid = costt ? costt.id : 'id'

    const { theme } = useThemes()

    return (
        <>
            <InputFieldNoFormikAnimated
                name={'title'}
                value={
                    props.entry?.title ? props.entry?.title : placeholdertitle
                }
                placeholder={''}
                onChange={(e: any) => {
                    if (props.entry) {
                        props.setEntry({
                            ...props.entry,
                            title: e.target.value ?? undefined,
                        })
                    } else {
                        props.setEntry({
                            ...historyFileBaseEntryInsurance,
                            title: e.target.value ?? undefined,
                        })
                    }
                }}
                backgroundColor={
                    props.isMobile
                        ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        : 'var(--bg-color, #ffffff)'
                }
            />

            <div style={{ paddingTop: '24px' }} />
            <FieldRow style={{ width: '100%' }}>
                <InputField
                    theme={theme}
                    disabled={false}
                    id="insurance_entry_policy_start_date"
                    name="Policy start date"
                    placeholder={'Policy start date'}
                    customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                    // helperText="Type or select a date (yyyy/mm/dd)"
                    tabindex={1}
                    aria-live="polite"
                    defaultValue={
                        props.entry?.insurance_entry_policy_start_date
                    }
                    value={props.entry?.insurance_entry_policy_start_date}
                    font_family="Lato"
                    restrictFlipOfDatePopUp
                    onChange={(dateString: any) => {
                        let startDate = dateString
                            ? `${dateString.toString()}`
                            : undefined
                        let endDate = dayjs(new Date(dateString)).isValid()
                            ? `${dayjs(new Date(dateString))
                                  .add(1, 'year')
                                  .toString()}`
                            : undefined

                        if (props.entry) {
                            props.setEntry({
                                ...props.entry,
                                insurance_entry_policy_start_date: startDate,
                                insurance_entry_policy_end_date: endDate,
                            })
                        } else {
                            props.setEntry({
                                ...historyFileBaseEntryInsurance,
                                insurance_entry_policy_start_date: startDate,
                                insurance_entry_policy_end_date: endDate,
                            })
                        }
                    }}
                    type="date"
                    // width="100%"
                    fontSize="16px"
                    paddingLeft="0px"
                    backgroundColor={
                        props.isMobile
                            ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                            : 'var(--bg-color, #ffffff)'
                    }
                    // dataCyId={props.dataCyId}
                    rightAdornment={<CalendarIcon />}
                />
                <InputField
                    theme={theme}
                    disabled={false}
                    id="insurance_entry_policy_end_date"
                    name="'Policy end date'"
                    placeholder={'Policy end date'}
                    customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                    // helperText="Type or select a date (yyyy/mm/dd)"
                    tabindex={1}
                    aria-live="polite"
                    defaultValue={props.entry?.insurance_entry_policy_end_date}
                    value={props.entry?.insurance_entry_policy_end_date}
                    font_family="Lato"
                    restrictFlipOfDatePopUp
                    onChange={(dateString: any) => {
                        if (props.entry) {
                            props.setEntry({
                                ...props.entry,
                                insurance_entry_policy_end_date:
                                    dateString?.toString(),
                            })
                        } else {
                            props.setEntry({
                                ...historyFileBaseEntryInsurance,
                                insurance_entry_policy_end_date:
                                    dateString?.toString(),
                            })
                        }
                    }}
                    type="date"
                    // width="100%"
                    fontSize="16px"
                    paddingLeft="0px"
                    backgroundColor={
                        props.isMobile
                            ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                            : 'var(--bg-color, #ffffff)'
                    }
                    // dataCyId={props.dataCyId}
                    rightAdornment={<CalendarIcon />}
                />
            </FieldRow>

            <div style={{ paddingTop: '24px' }} />

            <InputFieldNoFormikAnimated
                name={'Insurer'}
                value={props.entry?.insurance_entry_underwriter ?? undefined}
                placeholder={''}
                onChange={(e: any) => {
                    if (props.entry) {
                        props.setEntry({
                            ...props.entry,
                            insurance_entry_underwriter:
                                e.target.value ?? undefined,
                        })
                    } else {
                        props.setEntry({
                            ...historyFileBaseEntryInsurance,
                            insurance_entry_underwriter:
                                e.target.value ?? undefined,
                        })
                    }
                }}
                backgroundColor={
                    props.isMobile
                        ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        : 'var(--bg-color, #ffffff)'
                }
            />
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name={'Broker'}
                value={props.entry?.insurance_entry_broker ?? undefined}
                placeholder={''}
                onChange={(e: any) => {
                    if (props.entry) {
                        props.setEntry({
                            ...props.entry,
                            insurance_entry_broker: e.target.value ?? undefined,
                        })
                    } else {
                        props.setEntry({
                            ...historyFileBaseEntryInsurance,
                            insurance_entry_broker: e.target.value ?? undefined,
                        })
                    }
                }}
                backgroundColor={
                    props.isMobile
                        ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        : 'var(--bg-color, #ffffff)'
                }
            />
            <div style={{ paddingTop: '24px' }} />

            <FieldRow style={{ width: '100%' }}>
                <InputFieldNoFormikAnimated
                    width="100%"
                    type={'number'}
                    name={'insurance cost amount'}
                    value={costt?.amount ?? undefined}
                    placeholder={''}
                    onChange={(e: any) => {
                        let replaceEntry =
                            props.entry ?? historyFileBaseEntryInsurance

                        let newCost =
                            props.entry?.costItemsObj &&
                            props.entry?.costItemsObj[costid]
                                ? {
                                      ...props.entry?.costItemsObj[costid],
                                      id: costid,
                                  }
                                : {
                                      id: costid,
                                      amount: undefined,
                                      currency: 'GBP',
                                  }
                        props.setEntry({
                            ...replaceEntry,
                            costItemsObj: {
                                [costid]: {
                                    ...newCost,
                                    amount:
                                        e.target.value &&
                                        typeof +e.target.value === 'number'
                                            ? +e.target.value
                                            : undefined,
                                },
                            },
                        })
                    }}
                    backgroundColor={
                        props.isMobile
                            ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                            : 'var(--bg-color, #ffffff)'
                    }
                />

                <Select
                    maxWidth="130px"
                    value={costt?.currency ?? 'GBP'}
                    defaultValue={costt?.currency ?? 'GBP'}
                    onChange={(val: string) => {
                        let replaceEntry = props.entry
                            ? { ...props.entry }
                            : historyFileBaseEntryInsurance

                        let newCost =
                            props.entry?.costItemsObj &&
                            props.entry?.costItemsObj[costid]
                                ? {
                                      ...props.entry?.costItemsObj[costid],
                                      id: costid,
                                      currency: val,
                                  }
                                : {
                                      id: costid,
                                      amount: undefined,
                                      currency: val,
                                  }

                        props.setEntry({
                            ...replaceEntry,
                            costItemsObj: {
                                [costid]: { ...newCost },
                            },
                        })
                    }}
                    options={
                        currency_IDS?.map((optionID: string, index: number) => {
                            const option =
                                currency_data[
                                    optionID as keyof typeof currency_data
                                ]
                            return {
                                value: option.name.toUpperCase(),
                                label: option.name.toUpperCase(),
                            }
                        }) ?? []
                    }
                    backgroundColor={
                        props.isMobile
                            ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                            : 'var(--bg-color, #ffffff)'
                    }
                />
            </FieldRow>
        </>
    )
}
