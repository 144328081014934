import * as React from 'react'
import styled from 'styled-components'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import ChevronLeft from '../icons/components/chevronLeft'

type IStyle = {
    $disabled?: boolean
    $noValue?: boolean
    $height?: string
    $borderColour?: string
}

const PickerButton = styled.button<IStyle>`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: ${(props) => (props.$height ? props.$height : 'auto')};
    background-color: var(--off-bg-color, #fafafa);
    border-radius: 4px;
    padding: 0px 16px;
    ${(props) => `
        border: ${
            props.$borderColour
                ? `1px solid ${props.$borderColour}`
                : props.$disabled
                ? 'none'
                : props.$noValue
                ? '1px solid var(--primary, #5ec3ca)'
                : 'none'
        }
    `}
`

const PickerButtonContents = styled.div<IStyle>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    height: ${(props) => (props.$height ? props.$height : 'auto')};
    color: var(--text-strong, #1a1a1a);
    font-size: 15px;
    font-family: Lato;

    ${(props) => `
        opacity: ${props.$disabled ? 0.5 : 1}
    `}
`

const PickerLabel = styled.label`
    position: absolute;
    top: -16px;
    left: 8px;
    padding: 8px;
    background-color: var(--bg-color, #fff);
    color: var(--text-darker, #616161);
    font-size: 12px;
    font-family: Lato;
`

type Props = {
    disabled?: boolean
    onClick?: any
    dataCyId?: string | undefined
    noValue?: boolean
    text: string | undefined
    fwArrow?: boolean
    label?: string
    height?: string
    bg?: string
    borderColour?: string
    leftLabelPadding?: string
}

const PickerBtn: React.FunctionComponent<Props> = (props: Props) => {
    const { theme } = useThemes()
    return (
        <PickerButton
            data-attr={props.dataCyId}
            onClick={props.onClick}
            $noValue={props.noValue}
            style={
                props.fwArrow
                    ? {
                          border: props.borderColour
                              ? `${props.borderColour} 1px solid`
                              : `${colours[theme].text_muted} 1px solid`,
                          backgroundColor:
                              props.bg ?? colours[theme].background_default,
                      }
                    : {}
            }
            $borderColour={props.borderColour}
            $height={props.height}
        >
            <PickerButtonContents
                $disabled={props.disabled}
                $height={props.height}
            >
                {props.text ?? (
                    <span style={{ color: colours[theme].text_darker }}>
                        {props.label}
                    </span>
                )}
                <div style={{ transform: 'rotate(180deg) translateY(2px)' }}>
                    <ChevronLeft size="20" />
                </div>
            </PickerButtonContents>
            {props.label && props.text && (
                <PickerLabel
                    style={{
                        backgroundColor:
                            props.bg ?? colours[theme].background_default,
                    }}
                >
                    {props.label}
                </PickerLabel>
            )}
        </PickerButton>
    )
}
export default PickerBtn
