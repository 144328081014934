import { call, fork, join, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from './../../reducer'

import {
    gen_draft_differing_self_replicating_entities_multiple,
    gen_draft_differing_values_to_patch,
} from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { api } from '../../../services'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import {
    entityDeletionSaga,
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import {
    GetInsuranceApplication,
    ValidateAndAddStatsToApplication,
    insurance_application_state_select,
} from '../application/get'
import posthog from 'posthog-js'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'

export type IOtherDriverID = {
    driver_id: string
    isCrossForm?: boolean
    message?: string
}
export function* Submit_otherDriver_trackRecord(p: IOtherDriverID): any {
    posthog.capture('Update insurance application named driver track record')

    const { driver_id } = p
    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let application_has_deletions = false
        let application_has_patch = false

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft = { ...all_draft.other_drivers[driver_id]?.track_record }
        let existing = {
            ...all_existing.other_drivers[driver_id]?.track_record,
        }

        let dataToSendToPatch: IAnyObject | undefined =
            gen_draft_differing_values_to_patch({
                existing: existing,
                draft: draft,
            })

        if (all_draft.other_drivers[p.driver_id]?.entities) {
            let entitiies_draft = {
                motoring_convictions:
                    all_draft.other_drivers[p.driver_id]?.entities
                        .motoring_convictions,
                convictions:
                    all_draft.other_drivers[p.driver_id]?.entities.convictions,
                claims: all_draft.other_drivers[p.driver_id]?.entities.claims,
            }

            let entitiies_existing = {
                motoring_convictions:
                    all_existing.other_drivers[p.driver_id]?.entities
                        .motoring_convictions,
                convictions:
                    all_existing.other_drivers[p.driver_id]?.entities
                        .convictions,
                claims: all_existing.other_drivers[p.driver_id]?.entities
                    .claims,
            }
            let entities_info =
                gen_draft_differing_self_replicating_entities_multiple({
                    draft: entitiies_draft,
                    existing: entitiies_existing,
                })

            // console.log('entities_info', entities_info)

            if (entities_info.data_to_patch_by_type) {
                dataToSendToPatch = dataToSendToPatch
                    ? {
                          ...dataToSendToPatch,
                          ...entities_info.data_to_patch_by_type,
                      }
                    : { ...entities_info.data_to_patch_by_type }
            }

            if (entities_info.ids_to_delete_by_type) {
                let arrayOfDeletionTasks: any = []
                let entity_ids = Object.keys(
                    entities_info.ids_to_delete_by_type
                )
                for (let i = 0; i < entity_ids.length; i++) {
                    let entity_type = entity_ids[i]

                    for (
                        let i = 0;
                        i <
                        entities_info.ids_to_delete_by_type[entity_type].length;
                        i++
                    ) {
                        let id_to_delete: string =
                            entities_info.ids_to_delete_by_type[entity_type][i]

                        // console.log(
                        //     'deleting entity type:',
                        //     entity_type,
                        //     'of ID:',
                        //     id_to_delete
                        // )

                        let req_del: IDeleteInsuranceApplication_req = {
                            appli_id: `${all_existing.id}`,
                            entity_id: id_to_delete,
                            // @ts-ignore
                            entity_type: entity_type,
                            parent_named_driver_id: driver_id,
                        }

                        const deletionTask = yield fork(entityDeletionSaga, {
                            ...req_del,
                        })
                        arrayOfDeletionTasks.push(deletionTask)

                        // call BE with /DELETE/entity_type id =entity_id
                    }
                }

                if (arrayOfDeletionTasks && arrayOfDeletionTasks.length > 0) {
                    yield join(arrayOfDeletionTasks)
                    application_has_deletions = true
                }
            }
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            // console.log('data to patch', dataToSendToPatch)
            // call api to patch as thats all the data merged we want to patch
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: {
                        customer: {
                            named_drivers: [
                                { uid: driver_id, ...dataToSendToPatch },
                            ],
                        },
                    },
                }
            )

            application_has_patch = true

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )

            yield put(
                insuranceActions.submit_otherDriver_trackRecord_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(insuranceActions.submit_otherDriver_trackRecord_success())
        }

        if (
            application_has_deletions === true &&
            application_has_patch === false
        ) {
            let application: IInsuranceQuoteApplication_Reducer = yield select(
                insurance_application_state_select
            )

            if (
                application.showValidationSheet &&
                application.submitted_data?.id
            ) {
                const isWizard =
                    window.location.pathname.match(/wizard/g) !== null
                        ? true
                        : false

                yield put(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...application.submitted_data },
                        activateValidationSheet: true,
                        isWizard: isWizard,
                    })
                )
            } else {
                yield put(
                    insuranceActions.getting_insurance_info_after_change()
                )
                yield call(GetInsuranceApplication, `${all_existing.id}`)
            }
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_otherDriver_trackRecord,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/other_driver/${p.driver_id}/track_record`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_otherDriver_trackRecord_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Submit_otherDriver_trackRecord() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.submit_otherDriver_trackRecord_request
        )
        yield call(Submit_otherDriver_trackRecord, payload)
    }
}

const otherDriver_trackRecord_submit: any[] = [
    fork(Watcher_Submit_otherDriver_trackRecord),
]

export default otherDriver_trackRecord_submit
