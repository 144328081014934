import styled from 'styled-components'
import { withFormik, FormikProps, Field } from 'formik'
import * as Yup from 'yup'
import InputField from '../../../atoms/Inputfield/inputField'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import Expander from '../../../atoms/expander/expander'
import './style.css'
import { yupVar_NumberBasicSingleField } from '../yupVars'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import { numberWithCommas } from '../../../../helpers/numbers'
import { useState } from 'react'
import * as unitGenerator from '../../../../helpers/units/unitConversion'
import circled_chevron_green from '../../../../public/assets/icons/circled_chevron_green.svg'
import grey_cross from '../../../../public/assets/icons/grey_cross.svg'
import useThemes from '../../../../providers/theme/hooks'
import { IEditOrCreateModeSingleFieldsProps } from '../types'

const WrapperMobile = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    @media ${device.ipad} {
        padding-left: 25px;
    }
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const SmallPadding = styled.div`
    padding-top: 20px;
`

const Row = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
`

const CustomCarMileageWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    background-color: var(--primary_08);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
`

const Label = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    transform: translateY(3px);
`

const CarMileage = styled.span`
    font-family: Lato-bold;
    color: var(--text-strong, #1a1a1a);
`

const UpdateCarMileage = styled.span`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
`

const CustomCarMilageInput = styled(Field)`
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 12px;
    border-radius: 5px;
    padding-left: 10px;
    height: 32px;
`

const IconWrapper = styled.div`
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
`

interface Values {
    single_item_edited_value_number_field_mobile: number | undefined
    update_car_mileage: boolean
    custom_item_edited_value_number_field_mobile: number | undefined
}

interface OtherProps {
    data: IEditOrCreateModeSingleFieldsProps
    dataCyId?: string
    customMileageDataCyId?: string
    optionsFieldID?: string
    hasError?: (payload: boolean) => void
    updateCarMileage?: any
    carMileage?: number | null | undefined
    userDistanceUnits?: string | undefined | null
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,

        data,
        errors,
        setFieldValue,
        // updateCarMileage,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        hasError,
        carMileage,
        userDistanceUnits,
        updateCarMileage,
    } = props

    const [customCarMileageInput, toggleCustomCarMileageInput] = useState(false)
    const [showBtnForCustomInput, toggleBtnForCustomInput] = useState(true)

    const handleAnswerChange = (e: any) => {
        setFieldValue(
            'single_item_edited_value_number_field_mobile',
            parseInt(e.target.value)
        )

        setFieldValue(
            'custom_item_edited_value_number_field_mobile',
            parseInt(e.target.value)
        )

        let backEndValue: number | undefined = userDistanceUnits
            ? unitGenerator.generateBackEndValueDistanceUnit(
                  userDistanceUnits,
                  parseInt(e.target.value)
              )
            : undefined

        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                backEndValue,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, backEndValue)

            if (userDistanceUnits) {
                updateCarMileage({
                    updateCarMileage: values.update_car_mileage,
                    value: backEndValue,
                    user_distance_unit: userDistanceUnits,
                })
            }
        }
        if (
            errors.single_item_edited_value_number_field_mobile !== undefined ||
            !values.single_item_edited_value_number_field_mobile
        ) {
            hasError && hasError(true)
        } else {
            hasError && hasError(false)
        }
    }

    const handleCustomAnswerChange = (e: any) => {
        setFieldValue(
            'custom_item_edited_value_number_field_mobile',
            parseInt(e.target.value)
        )
        setFieldValue('update_car_mileage', true)

        if (userDistanceUnits) {
            updateCarMileage({
                updateCarMileage: true,
                value: unitGenerator.generateBackEndValueDistanceUnit(
                    userDistanceUnits,
                    parseInt(e.target.value)
                ),
                user_distance_unit: userDistanceUnits,
            })
        }
        if (errors.custom_item_edited_value_number_field_mobile !== undefined) {
            hasError && hasError(true)
        } else {
            hasError && hasError(false)
        }
    }

    const handleUpdatingCarMileage = (payload?: boolean) => {
        if (payload) {
            setFieldValue('update_car_mileage', payload)
        } else {
            setFieldValue('update_car_mileage', !values.update_car_mileage)
            if (
                values.custom_item_edited_value_number_field_mobile ===
                    values.single_item_edited_value_number_field_mobile &&
                values.update_car_mileage === false
            ) {
                data.onChangeFunc('allow_form_submission', true)
            }
        }

        if (!showBtnForCustomInput && values.update_car_mileage) {
            toggleCustomCarMileageInput(false)
            toggleBtnForCustomInput(true)
        }

        if (userDistanceUnits) {
            let backEndValue: number | undefined =
                values.single_item_edited_value_number_field_mobile &&
                unitGenerator.generateBackEndValueDistanceUnit(
                    userDistanceUnits,
                    values.single_item_edited_value_number_field_mobile
                )

            let customBackEndValue: number | undefined =
                values.custom_item_edited_value_number_field_mobile &&
                unitGenerator.generateBackEndValueDistanceUnit(
                    userDistanceUnits,
                    values.custom_item_edited_value_number_field_mobile
                )

            updateCarMileage({
                updateCarMileage: !values.update_car_mileage,
                value: customBackEndValue ? customBackEndValue : backEndValue,
                user_distance_unit: userDistanceUnits,
            })
        }
    }

    const { theme } = useThemes()

    return (
        <WrapperMobile>
            <div style={{ paddingTop: '16px' }} />
            <InputField
                theme={theme}
                id="single_item_edited_value_number_field_mobile"
                name="single_item_edited_value_number_field_mobile"
                helperText={data.title}
                placeholder={'Mileage'}
                tabindex={1}
                aria-live="polite"
                formikprops={props}
                value={values.single_item_edited_value_number_field_mobile}
                type="number"
                font_family="Lato"
                paddingLeft="0px"
                width="100%"
                fontSize="16px"
                onChange={handleAnswerChange}
                dataCyId={props.dataCyId}
            />
            <Expander
                height={
                    values.single_item_edited_value_number_field_mobile &&
                    errors.single_item_edited_value_number_field_mobile
                        ? 'auto'
                        : 0
                }
            >
                <Faded>
                    <div style={{ paddingTop: '5px' }} />
                    <Error className="animated fadeIn slower">
                        {errors.single_item_edited_value_number_field_mobile}
                    </Error>
                </Faded>
            </Expander>
            <SmallPadding />
            <Label>
                Current car mileage:{' '}
                {carMileage !== undefined &&
                carMileage !== null &&
                userDistanceUnits ? (
                    <span
                        style={{ fontFamily: 'Lato-bold' }}
                    >{` ${numberWithCommas({
                        numberToConvert: carMileage,
                    })} ${userDistanceUnits}`}</span>
                ) : carMileage === 0 ? (
                    0
                ) : (
                    'unknown'
                )}
            </Label>

            <SmallPadding />
            <Expander
                height={
                    values.single_item_edited_value_number_field_mobile &&
                    (carMileage === null ||
                        carMileage === undefined ||
                        carMileage <
                            values.single_item_edited_value_number_field_mobile)
                        ? 'auto'
                        : 0
                }
            >
                <CustomCarMileageWrapper>
                    <Row>
                        <CustomAnimatedCheckboxField
                            checked={values.update_car_mileage}
                            onChange={() => handleUpdatingCarMileage()}
                            hasWhiteBg
                        />
                        <Label>
                            Update my car mileage{' '}
                            {carMileage !== null &&
                                carMileage !== undefined && (
                                    <>
                                        from{' '}
                                        <CarMileage>{`${numberWithCommas({
                                            numberToConvert: carMileage,
                                            decimals: 0,
                                        })} ${userDistanceUnits}`}</CarMileage>{' '}
                                    </>
                                )}
                            to
                            {showBtnForCustomInput &&
                                !customCarMileageInput && (
                                    <Row style={{ alignItems: 'center' }}>
                                        <CarMileage>
                                            {` ${values.single_item_edited_value_number_field_mobile} ${userDistanceUnits} `}
                                        </CarMileage>
                                        <span
                                            style={{
                                                paddingLeft: '3px',
                                                paddingRight: '3px',
                                            }}
                                        >
                                            or
                                        </span>
                                        <UpdateCarMileage
                                            onClick={() => {
                                                toggleCustomCarMileageInput(
                                                    true
                                                )
                                                toggleBtnForCustomInput(false)
                                                handleUpdatingCarMileage(true)
                                            }}
                                        >
                                            set a custom value
                                        </UpdateCarMileage>{' '}
                                        <IconWrapper>
                                            <img
                                                src={circled_chevron_green}
                                                style={{
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    height: '100%',
                                                    marginLeft: '8px',
                                                }}
                                                alt="chev"
                                            />
                                        </IconWrapper>
                                    </Row>
                                )}
                            <div style={{ paddingTop: '10px' }} />
                            <Expander
                                height={
                                    customCarMileageInput &&
                                    !showBtnForCustomInput &&
                                    values.update_car_mileage
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <Row style={{ alignItems: 'center' }}>
                                    <CustomCarMilageInput
                                        id="custom_item_edited_value_number_field_mobile"
                                        name="custom_item_edited_value_number_field_mobile"
                                        placeholder={
                                            values.single_item_edited_value_number_field_mobile
                                        }
                                        tabindex={1}
                                        aria-live="polite"
                                        formikprops={props}
                                        value={
                                            values.custom_item_edited_value_number_field_mobile
                                        }
                                        type="number"
                                        onChange={handleCustomAnswerChange}
                                        data-ttr={props.customMileageDataCyId}
                                        step="0.01"
                                    />
                                    <IconWrapper
                                        onClick={() => {
                                            toggleCustomCarMileageInput(false)
                                            setFieldValue(
                                                'custom_item_edited_value_number_field_mobile',
                                                undefined
                                            )
                                            toggleBtnForCustomInput(true)
                                            updateCarMileage({
                                                updateCarMileage:
                                                    values.update_car_mileage,
                                                value: values.single_item_edited_value_number_field_mobile,
                                                user_distance_unit:
                                                    userDistanceUnits,
                                            })
                                        }}
                                    >
                                        <img
                                            src={grey_cross}
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                height: '100%',
                                                marginLeft: '25px',
                                            }}
                                            alt="close"
                                        />
                                    </IconWrapper>
                                </Row>
                                <div style={{ paddingBottom: '10px' }} />
                            </Expander>
                        </Label>
                    </Row>
                </CustomCarMileageWrapper>
            </Expander>
        </WrapperMobile>
    )
}

const MileageEditOrCreateModeMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_number_field_mobile:
            props.data.value_start && props.userDistanceUnits
                ? unitGenerator.generateFrontEndValueDistanceUnit(
                      props.userDistanceUnits,
                      props.data.value_start
                  )
                : undefined,
        update_car_mileage: false,
        custom_item_edited_value_number_field_mobile:
            props.data.value_start && props.userDistanceUnits
                ? unitGenerator.generateFrontEndValueDistanceUnit(
                      props.userDistanceUnits,
                      props.data.value_start
                  )
                : undefined,
    }),
    enableReinitialize: true,
    // validationSchema: {},
    validationSchema: () => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                single_item_edited_value_number_field_mobile:
                    yupVar_NumberBasicSingleField(
                        values.single_item_edited_value_number_field_mobile
                    ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default MileageEditOrCreateModeMobile
