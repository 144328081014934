import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import Loader from '../../../atoms/loader/loader'
import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'
import { useLocation, useNavigate } from 'react-router-dom'
import ConfirmCarDataFormOnboardingMobile, {
    IOnboarding_Car_Data_DVLA,
} from '../../../organisms/editForms/addACarForm/onboarding/confirmCarDataFormOnboardingMobile'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import { generateUserChoiceUnit } from '../../../../helpers/units/generateUserChoiceUnit'
import {
    generateFrontEndValueFuelUnit,
    generateBackEndValueFuelUnit,
} from '../../../../helpers/units/unitConversion'
import OnboardingLayoutDesktop from '../../../templates/onboarding/desktop/onboardingLayoutDesktop'
import ConfirmCarDataFormLeftOnboardingDesktop from '../../../organisms/editForms/addACarForm/onboarding/confirmCarDataFormOnboardingLeftDesktop'
import OnboardingFormTemplateDesktop from '../../../templates/onboarding/desktop/onboardingFormTemplateDesktop'
import ConfirmCarDataFormOnboardingRightDesktop from '../../../organisms/editForms/addACarForm/onboarding/confirmCarDataFormOnboardingRightDesktop'
import OnboardingFormLayoutMobile, {
    OnboardingText,
    PaddingUnit,
} from '../../../templates/onboarding/onboardingFormLayoutMobile'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { IExternalCarDataRequestPayloadVersion2 } from '../../../../redux/entities/cars/types'
import { usersActions } from '../../../../redux/user/reducer'
import { ICreateCarVersion2, IList, ITechInfoData } from 'entityModels'

const OnboardingStep_2_2 = () => {
    const dispatch = useAppDispatch()
    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let countryParam = queryParams.get('country')
    let country = countryParam
        ? decodeURIComponent(countryParam)
        : 'United Kingdom'
    let carid = queryParams.get('carid')
    let isFromVesApi = queryParams.get('from_ves_api')
    let reg_no = queryParams.get('reg_no')
    let onboardingStep2Data = useAppSelector(
        (state) => state.localdata.onboardingContent.content[2]
    )

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    let userLoading = useAppSelector((state) => state.user.loading)

    let carsData = useAppSelector((state) => state.entities.carsData.cars)
    let carsDataLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    let garageDataLoading = useAppSelector(
        (state) => state.entities.garagesData.loading
    )

    let currentCar =
        carid && carsData && carsData[carid] ? carsData[carid] : undefined

    let apiData = useAppSelector(
        (state) => state.entities.carsData.externalDataByRegistrationNumber
    )

    let apiCarData =
        apiData && carid && apiData[carid] ? apiData[carid] : undefined

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/onboarding-step-2-2?carid=${carid}`
            )
        )
    }, [])

    useEffect(() => {
        if (userLoggedIn) {
            if (carid && !carsData[carid]) {
                if (carid === 'new_car') {
                    dispatch(carActions.setEmptyCarRequest(carid))
                } else {
                    dispatch(carActions.getCarDataByIdRequest(`${carid}`))
                }
            }
            if (
                carid &&
                isFromVesApi === 'true' &&
                reg_no &&
                (!apiData || !apiData[carid])
                // carsDataLoading === false &&
                // (!apiData || !apiData[carid])
            ) {
                let p: IExternalCarDataRequestPayloadVersion2 = {
                    // car_id: carid,
                    registration_number: reg_no,
                    isOnboarding: true,
                    country: country ? decodeURIComponent(country) : undefined,
                }
                dispatch(
                    carActions.getExternalCarDataByRegistrationNumberRequestVersion2(
                        p
                    )
                )
            }
            if (reg_no) {
                dispatch(dropDownActions.setRegistrationNumber(reg_no))
            }
        } else {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    let navigate = useNavigate()

    let makesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })
    let modelsDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let techInfoData = useAppSelector((state) => {
        return state.entities.technicalInformationData.technical_information
    })

    let tempMakeID = useAppSelector((state) => {
        return state.localdata.dropdownData.tempMakeID
    })

    let tempMakeDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempMakeDisplay
    })

    let tempModelDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempModelDisplay
    })

    let carRegNb = useAppSelector((state) => {
        return state.localdata.dropdownData.registrationNumber
    })

    let carModel: string | null = null

    useEffect(() => {
        if (!makesDropdowndata) {
            dispatch(dropDownActions.setMakeDropdownDataRequest())
        }

        if (
            tempMakeDisplay &&
            tempMakeDisplay.length > 1 &&
            !modelsDropdowndata
        ) {
            dispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: tempMakeDisplay,
                    make_uid: tempMakeID,
                })
            )
        }
    }, [])

    let tech_info_id = currentCar?.technical_information

    let user_fuel_unit_chosen_id = userLoggedIn
        ? generateUserChoiceUnit(userLoggedIn, 'fuel_unit').unit_id
        : ''

    let all_car_technical_information: ITechInfoData | undefined =
        techInfoData && tech_info_id ? techInfoData[tech_info_id] : undefined

    let initial_technical_information = () => {
        if (all_car_technical_information) {
            let res: any = {}
            Object.values(all_car_technical_information).forEach(
                (item: any) => {
                    if (item.id) {
                        res[item.id] = {
                            id: item.id,
                            text: item.text,
                            answer:
                                item.id === 'registration_number' && carRegNb
                                    ? carRegNb
                                    : item.id === 'make' && tempMakeDisplay
                                    ? tempMakeDisplay
                                    : item.id === 'model' && tempModelDisplay
                                    ? tempModelDisplay
                                    : isFromVesApi === 'true' &&
                                      apiCarData &&
                                      apiCarData[item.id]
                                    ? apiCarData[item.id].answer
                                    : null,
                        }
                    }
                }
            )

            return res
        } else return undefined
    }

    let initial_info = initial_technical_information()

    let init_data: IOnboarding_Car_Data_DVLA = {
        make:
            tempMakeDisplay && tempMakeDisplay !== '-'
                ? tempMakeDisplay
                : undefined,
        model: carModel && carModel !== '-' ? carModel : undefined,
        year: initial_info?.year?.answer ?? undefined,
        engine_capacity:
            initial_info?.engine_capacity?.answer && user_fuel_unit_chosen_id
                ? generateFrontEndValueFuelUnit(
                      user_fuel_unit_chosen_id,
                      +initial_info?.engine_capacity?.answer
                  )
                : undefined,
        tax_status: initial_info?.tax_status?.answer,
        tax_due: initial_info?.tax_due?.answer,
        service_check_status: initial_info?.service_check_status?.answer,
        service_check_expiry: initial_info?.service_check_expiry?.answer,
        registration_date: initial_info?.registration_date?.answer ?? undefined,
        fuel_type: initial_info?.fuel_type?.answer ?? undefined,
        body_type: initial_info?.body_type?.answer ?? undefined,
        color_exterior: initial_info?.color_exterior?.answer
            ? `${initial_info?.color_exterior.answer}`
            : undefined,
        last_owner_change: initial_info?.last_owner_change?.answer,
        drive_side: initial_info?.drive_side?.ansewr,
        registration_number:
            initial_info?.registration_number?.answer ?? undefined,
    }

    const [formData, setFormData] = useState<IOnboarding_Car_Data_DVLA>({
        make: undefined,
        model: undefined,
        year: undefined,
        engine_capacity: undefined,
        tax_status: undefined,
        tax_due: undefined,
        service_check_status: undefined,
        service_check_expiry: undefined,
        registration_date: undefined,
        fuel_type: undefined,
        body_type: undefined,
        color_exterior: undefined,
        drive_side: undefined,
        last_owner_change: undefined,
        registration_number: undefined,
    })

    useEffect(() => {
        setFormData({ ...init_data })
    }, [apiCarData, all_car_technical_information])

    const handleChange = (id: string, value: string | undefined) => {
        // if (id === 'make') {
        //     // if (tempModelDisplay) {
        //     //     appDispatch(dropDownActions.setTempModelIDSuccess(undefined))
        //     //     appDispatch(dropDownActions.setTempModelDisplay(undefined))
        //     // }
        //     setFormData((prevState) => {
        //         return {
        //             ...prevState,
        //             make: value,
        //             model: undefined,
        //         }
        //     })
        // } else
        //  {
        setFormData((prevState) => {
            return {
                ...prevState,
                [id]: value,
            }
        })
        if (id === 'year') {
            setError((prevState) => {
                return { ...prevState, year: undefined }
            })
        }
        // }
    }

    // useEffect(() => {
    //     if (
    //         tempMakeID &&
    //         tempMakeID.length > 0 &&
    //         `${tempMakeDisplay}` !== formData.make
    //     ) {
    //         handleChange('make', tempMakeDisplay)
    //         setError((prevState) => {
    //             return { ...prevState, make: undefined }
    //         })
    //     }
    // }, [tempMakeID])

    // useEffect(() => {
    //     if (
    //         tempModelID &&
    //         tempModelID.length > 0 &&
    //         `${tempModelDisplay}` !== formData.model
    //     ) {
    //         handleChange('model', tempModelID)
    //         setError((prevState) => {
    //             return { ...prevState, model: undefined }
    //         })
    //     }
    // }, [tempModelID])

    useEffect(() => {
        if (carRegNb) {
            handleChange('registration_number', carRegNb)
        }
    }, [carRegNb])

    let arr_mandatory_form_values = ['make', 'model', 'year']

    const car_years_validation = useAppSelector(
        (state) => state.localdata.form_validation_copy.years
    )

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    const isFieldValid = (id: string, value?: string | number): boolean => {
        if (id === 'year' && value !== undefined && car_years_validation) {
            if (+value < 1850) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        year: car_years_validation.car_year.warning_min,
                    }
                })
                return false
            } else if (+value > car_years_validation.car_year.max) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        year: car_years_validation.car_year.warning_max,
                    }
                })
                return false
            }
        } else if (id === 'year' && !value) {
            setError((prevState) => {
                return { ...prevState, [id]: 'Required' }
            })
            return false
        } else if (
            id === 'make' &&
            (!tempMakeDisplay ||
                tempMakeDisplay === '-' ||
                tempMakeDisplay === '')
        ) {
            setError((prevState) => {
                return { ...prevState, [id]: 'Required' }
            })
            return false
        } else if (
            id === 'model' &&
            (!tempModelDisplay ||
                tempModelDisplay === '-' ||
                tempModelDisplay === '')
        ) {
            setError((prevState) => {
                return { ...prevState, [id]: 'Required' }
            })
            return false
        }
        return true
    }

    const validateOnSubmit = () => {
        let res = true
        arr_mandatory_form_values.forEach((id: string) => {
            if (
                !isFieldValid(
                    id,
                    formData[id as keyof IOnboarding_Car_Data_DVLA]
                )
            ) {
                res = false
            }
        })

        return res
    }

    let generate_car_title = () => {
        if (formData.year || formData.make || formData.model) {
            return `${formData.year ? `${formData.year.toString()} ` : ''}${
                formData.make ? `${formData.make} ` : ''
            }${formData.model ? formData.model : ''}`
        }
    }

    const submitData = () => {
        if (currentCar) {
            let p: ICreateCarVersion2 = {
                car_id: currentCar.id,
                data: [],
                isOnboarding: true,
                country: `${country}`,
                garage_id: `${userLoggedIn?.owns_garage?.uid}`,
                registration_number: `${reg_no}`,
                direct_url: `/onboarding-step-3`,
            }

            let generateAnswer = (fieldID: string) => {
                if (formData[fieldID as keyof IOnboarding_Car_Data_DVLA]) {
                    if (
                        fieldID === 'engine_capacity' &&
                        formData.engine_capacity
                    ) {
                        return generateBackEndValueFuelUnit(
                            'cubic_centimeters',
                            parseFloat(`${formData.engine_capacity}`)
                        )
                    } else if (fieldID === 'year' && formData.year) {
                        return +formData.year
                    } else {
                        return formData[
                            fieldID as keyof IOnboarding_Car_Data_DVLA
                        ]
                    }
                }
            }

            Object.keys(formData).forEach((key: string) => {
                if (formData[key as keyof IOnboarding_Car_Data_DVLA]) {
                    let newItem: IList = {
                        id: key,
                        text:
                            initial_info &&
                            initial_info[key] &&
                            initial_info[key].text
                                ? initial_info[key].text
                                : key,
                        answer: generateAnswer(key),
                    }

                    p.data = [...p.data, newItem]
                }
            })

            if (tempMakeDisplay) {
                p.data = [
                    ...p.data,
                    {
                        id: 'make',
                        text: 'make',
                        answer: tempMakeDisplay,
                    },
                ]
            }

            if (tempModelDisplay) {
                p.data = [
                    ...p.data,
                    {
                        id: 'model',
                        text: 'model',
                        answer: tempModelDisplay,
                    },
                ]
            }

            let car_title = generate_car_title()

            let carTitleItem: IList = {
                id: 'title',
                text: 'Title',
                answer: car_title,
            }

            p.data = [...p.data, carTitleItem]

            if (p.data.length > 0) {
                dispatch(carActions.createCarRequestVersion2(p))
            }
        }
    }

    const resetFormData = () => {
        Object.keys(formData).forEach((key) => {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    [key]: undefined,
                }
            })
        })
    }

    const resetAllFormDataAndReturnToPrevStep = () => {
        resetFormData()
        dispatch(dropDownActions.reset_make_model_temps())
        dispatch(dropDownActions.setRegistrationNumber(null))

        setTimeout(
            () =>
                navigate(`/onboarding-step-2-1?carid=${currentCar?.id}`, {
                    replace: true,
                }),
            20
        )
    }

    const [isVesApiFormOpen, toggleVesApiForm] = useState(false)
    let windowHeight = useWindowSize().height

    let isLoadingNewMakeOrModel = useAppSelector((state) => {
        return state.localdata.dropdownData.isLoadingNewMakeOrModel
    })

    return userLoading || carsDataLoading || garageDataLoading ? (
        <Loader isLoading />
    ) : (
        <React.Fragment>
            <DesktopDisplayOnly>
                <OnboardingLayoutDesktop
                    step={2}
                    title={onboardingStep2Data.title}
                    whiteTextTitle={
                        isFromVesApi === 'true'
                            ? undefined
                            : "Don't forget the details!"
                    }
                    whiteTextSubTitle={
                        isFromVesApi === 'true'
                            ? undefined
                            : 'They make all the difference in your car management experience.'
                    }
                    rightSideContent={
                        isFromVesApi === 'true' ? (
                            <ConfirmCarDataFormOnboardingRightDesktop
                                formData={formData}
                                handleChange={(
                                    id: string,
                                    value: string | undefined
                                ) => handleChange(id, value)}
                                initial_info={initial_info}
                                isVesApiFormOpen={isVesApiFormOpen}
                                toggleVesApiForm={() => toggleVesApiForm(false)}
                            />
                        ) : undefined
                    }
                >
                    <OnboardingFormTemplateDesktop
                        submit={() => {
                            let isFormValid = validateOnSubmit()
                            if (isFormValid) {
                                submitData()
                            } else {
                                if (isFromVesApi === 'true') {
                                    toggleVesApiForm(true)
                                }
                            }
                        }}
                        isBtnLoading={isLoadingNewMakeOrModel}
                    >
                        <ConfirmCarDataFormLeftOnboardingDesktop
                            formData={formData}
                            error={error}
                            handleChange={handleChange}
                            resetAllFormDataAndReturnToPrevStep={
                                resetAllFormDataAndReturnToPrevStep
                            }
                            isFormVesApi={
                                isFromVesApi === 'true' ? true : false
                            }
                            formText={onboardingStep2Data.text}
                            dvlaText={onboardingStep2Data.dvlaText}
                            nonDvlaText={onboardingStep2Data.nonDvlaText}
                        />
                    </OnboardingFormTemplateDesktop>
                </OnboardingLayoutDesktop>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                {carid ? (
                    <SideSliderWithExit
                        isVisible={
                            carid && !userLoading && !carsDataLoading
                                ? true
                                : false
                        }
                        direction={'right'}
                        initial="enter"
                        customDuration={0.5}
                        noOverflow
                    >
                        <OnboardingFormLayoutMobile
                            step={2}
                            title={onboardingStep2Data.title}
                            submit={() => {
                                let isFormValid = validateOnSubmit()
                                if (isFormValid) {
                                    submitData()
                                } else {
                                    if (isFromVesApi === 'true') {
                                        toggleVesApiForm(true)
                                    }
                                }
                            }}
                            isBtnLoading={isLoadingNewMakeOrModel}
                        >
                            <PaddingUnit
                                $times={
                                    windowHeight && windowHeight > 700 ? 4 : 2
                                }
                            />
                            <OnboardingText>
                                {onboardingStep2Data.text}
                            </OnboardingText>
                            <PaddingUnit $times={5} />
                            {currentCar && (
                                <ConfirmCarDataFormOnboardingMobile
                                    carid={carid}
                                    formData={formData}
                                    handleChange={(
                                        id: string,
                                        value: string | undefined
                                    ) => handleChange(id, value)}
                                    initial_info={initial_info}
                                    isFromVesApi={
                                        isFromVesApi === 'true' ? true : false
                                    }
                                    resetAllFormDataAndReturnToPrevStep={
                                        resetAllFormDataAndReturnToPrevStep
                                    }
                                    error={error}
                                    dvlaText={onboardingStep2Data.dvlaText}
                                    nonDvlaText={
                                        onboardingStep2Data.nonDvlaText
                                    }
                                />
                            )}
                        </OnboardingFormLayoutMobile>
                    </SideSliderWithExit>
                ) : null}
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default OnboardingStep_2_2
