import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const Text = styled.section<IStyle>`
    width: 100%;
    box-sizing: border-box;
    font-style: ${(props) => (props.$italic ? 'italic' : 'normal')};
    font-family: 'Lato';
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 30px;
    max-width: 650px;
    color: ${(props) => colours[props.$theme].text_default};
    letter-spacing: 5e-5em;
    padding-right: 30px;

    @media (max-width: 1200px) {
        font-size: 18px;
        line-height: 30px;
        padding-right: 20px;
    }

    @media ${device.mobile} {
        font-size: 0.9rem;
    }
`

interface Props {
    children: React.ReactNode
    italic?: boolean
}

interface IStyle {
    $theme: ITheme
    $italic?: boolean
}

const OneLiner: React.FunctionComponent<Props> = (props) => {
    const { theme } = useThemes()
    return (
        <React.Fragment>
            <Text $theme={theme} $italic={props.italic && props.italic}>
                {props.children}
            </Text>
        </React.Fragment>
    )
}

export default OneLiner
