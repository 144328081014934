import * as React from 'react'

import TrioGridFullpage from '../../molecules/imageGrid/trioGridFullpage'
import Loader from '../../atoms/loader/loader'
import { IGalleryImagesObject } from '../../../redux/entities/galleries/types'

export interface IImageProps {
    url: string
}

interface Props {
    imagesData: IGalleryImagesObject | null
    isNecessaryGalleryDataLoading: boolean
    ids_list: string[] | null | undefined
    openImgForm: () => void
    readOnlyMode?: boolean
}

const CarGalleryManagerDesktop: React.FC<Props> = ({
    imagesData,
    isNecessaryGalleryDataLoading,
    ids_list,
    openImgForm,
    readOnlyMode,
}) => {
    return (
        <React.Fragment>
            {isNecessaryGalleryDataLoading !== true && imagesData && (
                <TrioGridFullpage
                    images_data_object={imagesData}
                    ids_list={ids_list}
                    openImgForm={openImgForm}
                    readOnlyMode={readOnlyMode}
                />
            )}

            {isNecessaryGalleryDataLoading === true && (
                <Loader isLoading={isNecessaryGalleryDataLoading} />
            )}
        </React.Fragment>
    )
}

export default CarGalleryManagerDesktop
