import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions'
import { selectStateUserCurrency } from '../../../user/sagas/getUserData'
import { categoryNameToEntryTypeArr } from '../../../conversions/timeline/categoriesToEntryTypes'
import { api } from '../../../services'
import { ConvertAPIEntryToTimelineItemSingle } from '../../../conversions/timeline/entryToTimelineItem'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { IEntryPayloadAPI } from '../../../services/typedefinitions/apiPayloads'
import { IFilterEntriesPayloadAPIReq } from '../../../services/types'
import { ICurencyUIDsEnum } from '../../../timeline/timelineEnum'
import { IReduxError } from '../types'
import { carActions } from '../reducer'

function* GetLastInsuranceTimelineEntrySaga(carid: string): any {
    try {
        let user_current_curr: ICurencyUIDsEnum = yield call(
            selectStateUserCurrency
        )

        let p: IFilterEntriesPayloadAPIReq = {
            car_id: carid,
            entry_type_ids: categoryNameToEntryTypeArr(['insurance']),
            labels_ids: [],
            entry_title: undefined,
            user_currency: user_current_curr,
        }

        const api_res: any = yield call(
            api.timeline.entries.getEntriesData
                .getAllEntriesByCarIdAndCategoriesNLabelsNTitleQAPI,
            p
        )

        if (
            api_res && // 👈 null and undefined check
            Object.keys(api_res).length === 0 &&
            Object.getPrototypeOf(api_res) === Object.prototype
        ) {
            let res: actions.IGetLastCarInsuranceEntrySuccess = {
                carid: carid,
                timelineItem: null,
            }
            yield put(carActions.getLastCarInsuranceEntrySuccess(res))
        } else {
            let filtered_entries_from_api: IEntryPayloadAPI[] = api_res.data

            // order by start date:

            let sorted = filtered_entries_from_api.sort(
                (a, b) =>
                    new Date(`${b.occurred_at}`).getTime() -
                    new Date(`${a.occurred_at}`).getTime()
            )

            let converted_entry = ConvertAPIEntryToTimelineItemSingle(sorted[0])
            let res: actions.IGetLastCarInsuranceEntrySuccess = {
                carid: carid,
                timelineItem: converted_entry,
            }
            yield put(carActions.getLastCarInsuranceEntrySuccess(res))
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetLastInsuranceTimelineEntrySaga,
                payload: carid,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let err: IReduxError = ConvertToReduxError(error)
            yield put(carActions.getLastCarInsuranceEntryError(err))
        }
    }
}

function* getLastInsuranceTimelineEntry() {
    while (true) {
        let { payload } = yield take(carActions.getLastCarInsuranceEntryRequest)

        yield call(GetLastInsuranceTimelineEntrySaga, payload)
    }
}

const get_last_timeline_item_insurance: any[] = [
    fork(getLastInsuranceTimelineEntry),
]

export default get_last_timeline_item_insurance
