import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import left_1 from '../../../../public/assets/onboarding/left_1.jpg'
import left_2 from '../../../../public/assets/onboarding/left_2.jpg'
import left_3 from '../../../../public/assets/onboarding/left_3.jpg'
import right_1 from '../../../../public/assets/onboarding/right_1.jpg'
import right_2 from '../../../../public/assets/onboarding/right_2.jpg'
import right_3 from '../../../../public/assets/onboarding/right_3.jpg'
import showroom from '../../../../public/assets/onboarding/showroom.jpg'
import garage from '../../../../public/assets/onboarding/garage.jpg'
import history_file from '../../../../public/assets/onboarding/history_file.jpg'
import insurance from '../../../../public/assets/onboarding/insurance.jpg'
import apex from '../../../../public/assets/onboarding/apex.jpg'

import useWindowSize from '../../../templates/displays/windowSizeHook'

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
`
const SubWrapperDefault = styled.div`
    height: 100%;
    width: fit-content;
    display: flex;
    gap: 40px;
    flex-wrap: nowrap;
    overflow: hidden;
    z-index: 1;
`
const SubWrapperActiveIndex = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
`

const Column = styled(motion.div)`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
`

const ImgWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: auto;
    border-radius: 40px;
`
const ImgWrapperSingle = styled.div`
    border-radius: 16px;
    background: var(--base-white, #fff);
    box-shadow: 64px 64px 128px 0px rgba(0, 0, 0, 0.08);
    height: auto;
`

const Img = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    object-fit: cover;
    border-radius: 40px;
`

interface IProps {
    activeIndex: number
}

const OnboardingLastStepRightSideDesktop: React.FunctionComponent<IProps> = (
    props
) => {
    let { activeIndex } = props
    let windowWidth = useWindowSize().width
    let windowHeight = useWindowSize().height

    return (
        <Wrapper>
            {windowHeight && windowWidth && windowWidth < windowHeight ? (
                <SubWrapperDefault
                    style={{
                        opacity: activeIndex > 0 ? 0.05 : 1,
                        transition: 'all 200ms',
                    }}
                >
                    <Column
                        style={{
                            translateY: -100,
                            width: windowWidth && windowWidth / 1.5,
                            maxWidth: 800,
                        }}
                        animate={activeIndex > 0 ? {} : { translateY: -400 }}
                        transition={{
                            repeat: activeIndex > 0 ? 0 : Infinity,
                            repeatType: 'reverse',
                            duration: 20,
                        }}
                    >
                        <ImgWrapper>
                            <Img src={left_1} />
                        </ImgWrapper>
                        <ImgWrapper>
                            <Img src={left_2} />
                        </ImgWrapper>

                        <ImgWrapper>
                            <Img src={left_3} />
                        </ImgWrapper>
                        <ImgWrapper>
                            <Img src={right_1} />
                        </ImgWrapper>
                        <ImgWrapper>
                            <Img src={right_2} />
                        </ImgWrapper>

                        <ImgWrapper>
                            <Img src={right_3} />
                        </ImgWrapper>
                    </Column>
                </SubWrapperDefault>
            ) : (
                <SubWrapperDefault
                    style={{
                        opacity: activeIndex > 0 ? 0.05 : 1,
                        transition: 'all 200ms',
                    }}
                >
                    <Column
                        style={{
                            translateY: -100,
                            width: windowWidth && windowWidth / 2.2,
                            maxWidth: 800,
                        }}
                        animate={activeIndex > 0 ? {} : { translateY: -400 }}
                        transition={{
                            repeat: activeIndex > 0 ? 0 : Infinity,
                            repeatType: 'reverse',
                            duration: 20,
                        }}
                    >
                        <ImgWrapper>
                            <Img src={left_1} />
                        </ImgWrapper>
                        <ImgWrapper>
                            <Img src={left_2} />
                        </ImgWrapper>

                        <ImgWrapper>
                            <Img src={left_3} />
                        </ImgWrapper>
                    </Column>
                    <Column
                        style={{
                            translateY: -400,
                            width: windowWidth && windowWidth / 2.2,
                            maxWidth: 800,
                        }}
                        animate={activeIndex > 0 ? {} : { translateY: 0 }}
                        transition={{
                            repeat: activeIndex > 0 ? 0 : Infinity,
                            repeatType: 'reverse',
                            duration: 20,
                        }}
                    >
                        <ImgWrapper>
                            <Img src={right_1} />
                        </ImgWrapper>
                        <ImgWrapper>
                            <Img src={right_2} />
                        </ImgWrapper>

                        <ImgWrapper>
                            <Img src={right_3} />
                        </ImgWrapper>
                    </Column>
                </SubWrapperDefault>
            )}

            {[1, 2, 3, 4, 5].map((i) => (
                <SubWrapperActiveIndex
                    style={{
                        opacity: activeIndex > 0 ? 1 : 0,
                    }}
                >
                    <ImgWrapperSingle
                        style={{
                            width: windowWidth && windowWidth / 3,
                            maxWidth: 600,
                            minWidth: 450,
                            height: 'auto',
                            transition: 'all 200ms',
                            backgroundColor: '#fff',
                            padding: 16,
                            paddingBottom: 0,
                            transform:
                                activeIndex === i ? 'scale(1)' : 'scale(0.75)',
                            transitionDelay:
                                activeIndex === i ? '110ms' : '0ms',
                            opacity: activeIndex === i ? 1 : 0,
                        }}
                    >
                        <Img
                            style={{ borderRadius: 16, objectFit: 'cover' }}
                            src={
                                activeIndex === 1
                                    ? garage
                                    : activeIndex === 2
                                    ? history_file
                                    : activeIndex === 3
                                    ? showroom
                                    : activeIndex === 4
                                    ? insurance
                                    : apex
                            }
                        />
                    </ImgWrapperSingle>
                </SubWrapperActiveIndex>
            ))}
        </Wrapper>
    )
}

export default OnboardingLastStepRightSideDesktop
