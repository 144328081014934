import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import crossed_car_white_bg from '../../../public/assets/icons/crossed_car_white_bg.svg'
import crossed_car_dark_bg from '../../../public/assets/icons/crossed_car_dark_bg.svg'
import ErrorBody from './errorBody'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import useThemes from '../../../providers/theme/hooks'
import { useEffect } from 'react'

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: var(--bg-color, #fff);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    padding-top: 20vh;
    @media ${device.beyond_ipad_mobile} {
        padding-left: 20vw;
        justify-content: flex-start;
    }
`

const Container = styled.div`
    background-color: var(--off-bg-color, #fafafa);
    @media ${device.beyond_ipad_mobile} {
        width: 50%;
        max-width: 800px;
        min-width: 600px;
    }
`

interface Props {
    onClick?: any
}

export const CarDoesNotExist: React.FC<Props> = ({ onClick }) => {
    const { theme } = useThemes()

    useEffect(() => {
        disableBodyScroll(document.body)
        return () => {
            enableBodyScroll(document.body)
        }
    }, [])

    return (
        <Wrapper>
            <Container>
                <ErrorBody
                    onClick={onClick}
                    title={'Oops, this car does not exist.'}
                    subTitle={
                        'You might have deleted it or typed a wrong link.'
                    }
                    buttonText={'Go to your garage'}
                    headIcon={
                        theme === 'dark'
                            ? crossed_car_dark_bg
                            : crossed_car_white_bg
                    }
                />
            </Container>
        </Wrapper>
    )
}
