import React, { useEffect } from 'react'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import ShowroomErrorManager from '../../organisms/showroomErrorManager/showroomErrorManager'

const Wrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    left: 0;
    background-color: var(--bg-color, #fff);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
`

interface Props {
    error_is: string | undefined
    onClick: any
}

const ShowroomError: React.FC<Props> = ({ error_is, onClick }) => {
    useEffect(() => {
        disableBodyScroll(document.body)
        return () => {
            enableBodyScroll(document.body)
        }
    }, [])

    return (
        <Wrapper>
            <ShowroomErrorManager error_is={error_is} onClick={onClick} />
        </Wrapper>
    )
}

export default ShowroomError
