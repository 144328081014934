import styled from 'styled-components'
import colours, { ITheme } from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { useAppSelector } from '../../../../../redux/store/hooks'
import { CarIcon, LocationIcon } from '../../../../atoms/icons/components'
import { device } from '../../../../templates/displays/devices'
import blank_user from '../../../../../public/assets/placeholders_svgs/avatar.svg'
import blank_user_dark from '../../../../../public/assets/placeholders_svgs/avatar_dark.svg'
import { IUser } from '../../../../../redux/user/types'

type IStyle = {
    $theme: ITheme
}

const ListRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`

const RowText = styled.div`
    font-family: Lato;
    padding-left: 14px;
    font-size: 15px;
`

const TextEllipsis = styled.div<IStyle>`
    padding: 0;
    margin: 0;

    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media ${device.mobile_and_ipad} {
        font-size: 16px;
        line-height: 24px;
    }

    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;

    max-width: 550px;
`

const CardWrapperMobile = styled.div`
    display: flex;
    width: 90%;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--bg-color, #fff);
    box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04);
    position: relative;
`
const MobileName = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Bold;
    font-size: 18px;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.09px;
`

const ProfileImg = styled.div`
    position: absolute;
    top: -30px;
    right: 18px;
    border-radius: 16px;
`

type Props = {
    withPhoto?: boolean
    withBio?: boolean
    userWithFormInfo?: IUser
}
export const UserProfileJourneyUserCardMobile = (props: Props) => {
    const { withPhoto, userWithFormInfo, withBio } = props
    const { theme } = useThemes()

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const garage_data = useAppSelector((state) => {
        return state.entities.garagesData.garages
    })

    let garage =
        userLoggedIn &&
        userLoggedIn.owns_garage &&
        userLoggedIn.owns_garage.uid &&
        garage_data &&
        garage_data[userLoggedIn.owns_garage.uid]

    let carsN =
        garage && garage.cars && garage.cars.length > 0
            ? garage.cars.length - 1
            : 0
    let user = userWithFormInfo ?? userLoggedIn

    return (
        <CardWrapperMobile>
            {withPhoto && (
                <ProfileImg
                    style={{
                        backgroundColor:
                            colours[theme].modal_side_sheet_pop_over,
                    }}
                >
                    <img
                        src={
                            user && user.profile_image_url
                                ? user.profile_image_url
                                : theme === 'dark'
                                ? blank_user_dark
                                : blank_user
                        }
                        style={{
                            height: '90px',
                            width: '90px',
                            objectFit: 'cover',
                            borderRadius: 12,
                        }}
                    />
                </ProfileImg>
            )}
            <MobileName>{user?.display_name}</MobileName>

            <div style={{ paddingTop: '8px' }} />
            <ListRow>
                <LocationIcon
                    size="21px"
                    color={
                        user?.location
                            ? colours[theme].text_default
                            : colours[theme].text_muted
                    }
                />
                <RowText
                    style={{
                        color: user?.location
                            ? colours[theme].text_default
                            : colours[theme].text_muted,
                    }}
                >
                    {user?.location ?? 'London, UK'}
                </RowText>
            </ListRow>
            <div style={{ paddingTop: '4px' }} />
            <ListRow>
                <CarIcon
                    size="21px"
                    color={
                        carsN
                            ? colours[theme].text_default
                            : colours[theme].text_muted
                    }
                />
                <RowText
                    style={{
                        color: carsN
                            ? colours[theme].text_default
                            : colours[theme].text_muted,
                    }}
                >
                    {carsN} car{carsN > 1 ? 's' : ''} in garage
                </RowText>
            </ListRow>
            {withBio && (
                <>
                    <div style={{ paddingTop: '24px' }} />
                    <TextEllipsis
                        $theme={theme}
                        style={{
                            color:
                                user?.one_liner && user?.one_liner?.length > 0
                                    ? colours[theme].text_default
                                    : colours[theme].text_muted,
                        }}
                    >
                        {user?.one_liner && user?.one_liner?.length > 0
                            ? user?.one_liner
                            : 'Passionate car enthusiast with a deep love for performance vehicles, classic cars, and motorsports. Enjoys attending car shows, restoring classics, and sharing driving tips.'}
                    </TextEllipsis>
                </>
            )}
        </CardWrapperMobile>
    )
}
