import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../../services'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IReduxError } from '../../cars/types'
import { IDeleteEntryGalleryImagePayload } from '../types'
import { galleriesActions } from '../reducer'
import { timelineActions } from '../../../timeline/reducer'

function* deleteEntryGalleryImageApi(payload: IDeleteEntryGalleryImagePayload) {
    try {
        yield call(api.entities.mutateGallery.deleteEntryGalleryImage, payload)

        yield put(
            galleriesActions.deleteEntryGalleryImageSuccess(payload.imageid)
        )

        yield put(
            timelineActions.deleteGalleryImagesIdFromEntrySuccess(payload)
        )

        // Capture event
        posthog.capture('DELETE ENTRY IMAGE')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: deleteEntryGalleryImageApi,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(galleriesActions.deleteEntryGalleryImageError(typedError))
            return
        }
    }
}

function* watcherDeleteEntryGalleryImage() {
    while (true) {
        const { payload } = yield take(
            galleriesActions.deleteEntryGalleryImageRequest
        )

        yield call(deleteEntryGalleryImageApi, payload)
    }
}

const delete_entry_gallery_image_sagas: any[] = [
    fork(watcherDeleteEntryGalleryImage),
]

export default delete_entry_gallery_image_sagas
