import { fork, take, call, put } from 'redux-saga/effects'
import { AttachmentTypesEnum } from '../../attachments/enums'
import { ConverFileTypeToFileSuffixEnum } from '../../conversions/attachments'
import { getPreSignedUrlsEntryAttachments } from '../../filestorage/actions/sagas/presignedurlsEntryAttachments'
import {
    AddAttachmentsToEntryAPISaga,
    IAddAttachmentToEntryAwsTrackingSaga,
    UploadEntryAttachmentToAwsWithTrackingSaga,
} from './addAttachmentToEntry'
import { timelineActions } from '../reducer'
import { IUploadImagePreSignedUrlPayloadSingle } from '../../services/typedefinitions/apiPayloads'
import {
    IAttachmentRequestPayload,
    IAttachmentAPI,
} from '../../attachments/types'
import { IUploadAttachmentPreSignedUrlPayloadSaga } from '../../filestorage/types'
import { IAddEntryAttachmenstApiRequest } from '../../services/types'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'

export type IobjBySuffixDataItem = {
    files: File[]
    filesConvertedForAWS: IAttachmentRequestPayload[]
    filesConvertedForApi: IAttachmentAPI[]
    count: number
    presigned_urls?: IUploadImagePreSignedUrlPayloadSingle[]
}

type IobjBySuffixData = {
    [key in AttachmentTypesEnum | string]: IobjBySuffixDataItem
}

export interface IManageAddAttachmentsToEntryRequest {
    files: File[]
    carID: string
    entryID: string
}

export function* ManageAddAttachmentsToEntrySaga(
    payload: IManageAddAttachmentsToEntryRequest
): any {
    try {
        let obj1: IobjBySuffixData = {}

        for (let i = 0; i < payload.files.length; i++) {
            const fileData = payload.files[i]
            const file = new File([fileData], fileData.name, {
                type: fileData.type,
                lastModified: fileData.lastModified,
            })

            if (file && file.name) {
                let file_extension: AttachmentTypesEnum | undefined =
                    ConverFileTypeToFileSuffixEnum(file.name)

                if (file_extension) {
                    if (!obj1[file_extension]) {
                        obj1 = {
                            ...obj1,
                            [file_extension]: {
                                files: [],
                                count: 0,
                                filesConvertedForApi: [],
                                filesConvertedForAWS: [],
                            },
                        }
                    }
                    obj1[file_extension].files = [
                        ...obj1[file_extension].files,
                        file,
                    ]

                    obj1[file_extension].count =
                        obj1[file_extension] &&
                        obj1[file_extension].count &&
                        obj1[file_extension].count
                            ? (obj1[file_extension].count += 1)
                            : 1
                }
            }
        }

        let keysArr = Object.keys(obj1)

        let all_entry_attachments_to_send_api: IAttachmentAPI[] = []

        for (let i = 0; i < keysArr.length; i++) {
            let p: IUploadAttachmentPreSignedUrlPayloadSaga = {
                entityID: payload.entryID,
                carID: payload.carID,
                fileSuffix: keysArr[i],
                count: obj1[keysArr[i]].count,
                objBySuffixToPopulate: obj1[keysArr[i]],
            }

            let suffixItemObjPopulated: IobjBySuffixDataItem = yield call(
                getPreSignedUrlsEntryAttachments,
                p
            )

            obj1[keysArr[i]] = {
                ...suffixItemObjPopulated,
            }

            let suffixObjItem = obj1[keysArr[i]]

            if (suffixObjItem && suffixObjItem.files) {
                for (let i = 0; i < suffixObjItem.files.length; i++) {
                    if (
                        suffixObjItem.presigned_urls &&
                        suffixObjItem.presigned_urls[i] &&
                        suffixObjItem.files
                    ) {
                        let presigned = suffixObjItem.presigned_urls[i]

                        let fileItem = suffixObjItem.files[i]

                        suffixObjItem.filesConvertedForApi[i] = {
                            uid: '',
                            filename: presigned.filename,
                            title: fileItem.name,
                        }

                        suffixObjItem.filesConvertedForAWS[i] = {
                            filename: presigned.filename,
                            type: fileItem.type,
                            file: fileItem,
                            progressRateAWS: 0,
                        }

                        let aws_p: IAddAttachmentToEntryAwsTrackingSaga = {
                            fileData: suffixObjItem.filesConvertedForAWS[i],
                            presigned_url: presigned.url,
                        }

                        let res = yield call(
                            UploadEntryAttachmentToAwsWithTrackingSaga,
                            aws_p
                        )

                        if (res !== 'ok') {
                            let itemAWS: IAttachmentRequestPayload = {
                                ...suffixObjItem.filesConvertedForAWS[i],
                                hasErrorAWS: res,
                            }

                            suffixObjItem.filesConvertedForAWS[i] = itemAWS
                        } else {
                            all_entry_attachments_to_send_api = [
                                ...all_entry_attachments_to_send_api,
                                suffixObjItem.filesConvertedForApi[i],
                            ]
                        }
                    }
                }
            }
        }

        let payload_api: IAddEntryAttachmenstApiRequest = {
            attachments: all_entry_attachments_to_send_api,
            carID: payload.carID,
            entryID: payload.entryID,
        }

        yield call(AddAttachmentsToEntryAPISaga, payload_api)

        yield put(timelineActions.manageAddAttachmentsToEntrySuccess())
    } catch (error) {
        let err = ConvertToReduxError(error)
        yield put(timelineActions.manageAddAttachmentsToEntryError(err))
    }
}

function* watcherManageAddAttachmentsToEntry() {
    while (true) {
        const { payload } = yield take(
            timelineActions.manageAddAttachmentsToEntryRequest
        )
        yield call(ManageAddAttachmentsToEntrySaga, payload)
    }
}

const manage_add_attachments_to_entry: any[] = [
    fork(watcherManageAddAttachmentsToEntry),
]

export default manage_add_attachments_to_entry
