import * as React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import './antd.css'
import { ITask } from 'entityModels'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import { OOtherTaskOptions } from '../../../ol/ol'
import CustomAnimatedCheckboxField from '../formField/customAnimatedCheckboxField'
import parse from 'html-react-parser'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'
import { useState } from 'react'
import { useAppSelector } from '../../../redux/store/hooks'
import StandardCtaBtn from '../Button/standardCtaBtn'
import ClockIcon from '../icons/components/clockIcon'
import CalendarIcon from '../icons/components/calendar'
import dayjs from 'dayjs'

const ShadowBox = styled.section<IBoxProps>`
    max-width: 100%;
    box-sizing: border-box;
    min-width: 100%;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background: var(--bg-color, #fff);
    box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04),
        0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    transition: all 100ms;
    border: ${(props) =>
        props.$status
            ? `1px solid ${colours[props.$theme].primary_20}`
            : `1px solid ${colours[props.$theme].supp_lavender_30}`};
    :hover {
        box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.12),
            0px 2px 8px 0px rgba(0, 0, 0, 0.04);
        border: ${(props) =>
            props.$status
                ? `1px solid ${colours[props.$theme].primary}`
                : `1px solid ${colours[props.$theme].supp_Lavender_100}`};
    }
`

const Label = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

const Line = styled.div<{ $theme: ITheme }>`
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${(props) => colours[props.$theme].border_muted};
    margin-top: 24px;
    margin-bottom: 24px;
`

const Row = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const TaskTitle = styled.div<{ $theme: ITheme }>`
    color: ${(props) => colours[props.$theme].text_strong};
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-family: Lato-Semibold;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.001px;
`

const TaskBody = styled.div<ITaskBody>`
    color: ${(props) => colours[props.$theme].text_darker};
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    height: 72px;
    overflow-y: hidden;
`

const BottomSectionText = styled.div`
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

const GreyPlaceholderLine = styled.div`
    height: 20px;
    align-self: stretch;
    background-color: var(--off-bg-color, #fafafa);
`

type IBoxProps = {
    isBodyEmpty?: boolean
    $status?: boolean
    $theme: ITheme
}

type ITaskBody = {
    $theme: ITheme
    isTaskBodyEmpty?: boolean
}

interface Props {
    task: ITask
    carsData: any
    updateTask: any
    carid?: string
}

const TaskCardDesktop: React.FC<Props> = (props) => {
    let { task, carsData, updateTask } = props

    const carid = useAppSelector(
        (state) => state.entities.carsData.currentCarID
    )

    let numberOfCars: number = carsData ? Object.entries(carsData).length : 0

    let generateLinkTitle = () => {
        if (task.linkedToCar) {
            if (
                task.linkedToCar.length > 1 &&
                task.linkedToCar.length === numberOfCars
            ) {
                return 'Every Car'
            } else if (carsData[task.linkedToCar[0]]) {
                if (
                    carsData[task.linkedToCar[0]] &&
                    task.linkedToCar.length === 1
                ) {
                    if (
                        carsData[task.linkedToCar[0]] &&
                        carsData[task.linkedToCar[0]].title
                    ) {
                        return carsData[task.linkedToCar[0]].title.length > 25
                            ? `${carsData[task.linkedToCar[0]].title.slice(
                                  0,
                                  25
                              )}...`
                            : carsData[task.linkedToCar[0]].title
                    } else return 'untitled car'
                } else if (task.linkedToCar.length > 1) {
                    if (
                        carsData[task.linkedToCar[0]] &&
                        carsData[task.linkedToCar[0]].title
                    ) {
                        return carsData[task.linkedToCar[0]].title.length > 22
                            ? `${carsData[task.linkedToCar[0]].title.slice(
                                  0,
                                  22
                              )}... 
                                            +${task.linkedToCar.length - 1}`
                            : `${carsData[task.linkedToCar[0]].title} +${
                                  task.linkedToCar.length - 1
                              }`
                    } else return 'untitled car'
                }
            } else return 'Car To delete'
        } else return OOtherTaskOptions.general_task.single
    }

    const [showTooltip, setShowTooltip] = useState(false)
    const [status, setStatus] = useState(false)

    React.useEffect(() => {
        setStatus(task.status)
    }, [task])

    const toggleStatus = () => setStatus(!status)

    let stripped_task_body = task.body
        ?.replace(/<p>/g, '<div>')
        .replace(/<\/p>/g, '</div>')
        .replace(/<h1>/g, '<div>')
        .replace(/<\/h1>/g, '</div>')
        .replace(/<h2>/g, '<div>')
        .replace(/<\/h2>/g, '</div>')
        .replace(/<ul>/g, '')
        .replace(/<\/ul>/g, '')
        .replace(/<li>/g, '<div>')
        .replace(/<\/li>/g, '</div>')
        .replace(/<ol>/g, '<div>')
        .replace(/<\/ol>/g, '</div>')
        .replace(/<blockquote>/g, '<div>')
        .replace(/<\/blockquote>/g, '</div>')
        .replace(/<em>/g, '<span>')
        .replace(/<\/em>/g, '</span>')
        .replace(/<s>/g, '<span>')
        .replace(/<\/s>/g, '</span>')
        .replace(/<strong>/g, '<span>')
        .replace(/<\/strong>/g, '</span>')

    const { theme } = useThemes()

    const navigate = useNavigate()
    const location = useLocation()

    let today = new Date()

    const isDueDateInThePast = task?.due
        ? dayjs(task.due).isBefore(today)
            ? true
            : false
        : undefined

    return task && task.id ? (
        <ShadowBox
            $status={status}
            $theme={theme}
            isBodyEmpty={task.body && task.body.length > 1 ? false : true}
            key={task.id}
            onMouseLeave={(e: any) => {
                e.preventDefault()
                setShowTooltip(false)
            }}
        >
            <Column style={{ gap: 16 }}>
                <Row>
                    <Link
                        to={
                            location.pathname.match(/car/g) !== null && carid
                                ? `/car/${carid}/tasks/task?taskid=${task.id}`
                                : `/tasks/task?taskid=${task.id}`
                        }
                        onClick={(e: any) => e.stopPropagation()}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                            }}
                        >
                            <Label
                                style={{
                                    backgroundColor:
                                        colours[theme]
                                            .background_neutral_subtle,
                                    color: colours[theme].text_darker,
                                }}
                            >
                                {generateLinkTitle()}
                            </Label>
                            <Label
                                style={{
                                    textTransform: 'capitalize',
                                    backgroundColor: status
                                        ? colours[theme].background_primary_soft
                                        : colours[theme].supp_lavender_8,
                                    color: status
                                        ? colours[theme].primary
                                        : colours[theme].supp_Lavender_100,
                                }}
                            >
                                {status ? 'completed' : 'outstanding'}
                            </Label>
                        </div>
                    </Link>

                    <CustomAnimatedCheckboxField
                        small
                        fullColorOnTrue
                        borderColor={colours[theme].text_muted}
                        checked={status}
                        onChange={() => {
                            updateTask({
                                taskid: task.id,
                                dataList: [
                                    {
                                        id: 'status',
                                        answer: !status,
                                    },
                                ],
                            })

                            toggleStatus()
                        }}
                    />
                </Row>
                <Link
                    to={
                        location.pathname.match(/car/g) !== null && carid
                            ? `/car/${carid}/tasks/task?taskid=${task.id}`
                            : `/tasks/task?taskid=${task.id}`
                    }
                    onClick={(e: any) => e.stopPropagation()}
                >
                    <TaskTitle
                        $theme={theme}
                        style={{
                            textDecoration: status ? 'line-through' : undefined,
                        }}
                    >
                        {task.title}
                    </TaskTitle>
                </Link>
            </Column>
            <Link
                to={
                    location.pathname.match(/car/g) !== null && carid
                        ? `/car/${carid}/tasks/task?taskid=${task.id}`
                        : `/tasks/task?taskid=${task.id}`
                }
                onClick={(e: any) => e.stopPropagation()}
            >
                <Column>
                    {stripped_task_body ? (
                        <TaskBody
                            isTaskBodyEmpty={task.body ? false : true}
                            $theme={theme}
                        >
                            {parse(stripped_task_body)}
                        </TaskBody>
                    ) : (
                        <Column style={{ gap: 6 }}>
                            <GreyPlaceholderLine style={{ width: '100%' }} />
                            <GreyPlaceholderLine style={{ width: '75%' }} />
                            <GreyPlaceholderLine style={{ width: '50%' }} />
                        </Column>
                    )}
                    <Line $theme={theme} />

                    <Row>
                        <Column
                            style={{
                                gap: 8,
                            }}
                        >
                            <Row
                                style={{ justifyContent: 'flex-start', gap: 8 }}
                            >
                                <ClockIcon
                                    color={
                                        status
                                            ? colours[theme].text_muted
                                            : colours[theme].text_default
                                    }
                                />
                                <BottomSectionText
                                    style={{
                                        color: status
                                            ? colours[theme].text_muted
                                            : colours[theme].text_default,
                                    }}
                                >
                                    {task.due && 'Due on '}
                                    {task.due
                                        ? fromIsoStringToDDMMYYY(task.due)
                                        : 'No due date set'}
                                </BottomSectionText>
                            </Row>
                            <Row
                                style={{ justifyContent: 'flex-start', gap: 8 }}
                            >
                                <CalendarIcon
                                    size="16"
                                    color={colours[theme].text_muted}
                                />
                                <BottomSectionText
                                    style={{
                                        color: colours[theme].text_muted,
                                    }}
                                >
                                    Created on{' '}
                                    {fromIsoStringToDDMMYYY(task.created_at)}
                                </BottomSectionText>
                            </Row>
                        </Column>

                        <div
                            onMouseEnter={() =>
                                !task.status && setShowTooltip(true)
                            }
                            onClick={(e: any) => e.preventDefault()}
                        >
                            {(!task.due || task.due === '-') && (
                                <Tooltip
                                    overlayStyle={{
                                        fontFamily: 'Lato-Light',
                                        textTransform: 'capitalize',
                                    }}
                                    open={showTooltip}
                                    placement="topLeft"
                                    title={'Please set a due date first'}
                                    color={colours[theme].text_darker}
                                />
                            )}
                            {isDueDateInThePast ? null : (
                                <StandardCtaBtn
                                    isSecondary
                                    bg={'transparent'}
                                    customHoverColor={
                                        colours[theme].supp_lavender_8
                                    }
                                    borderOnHover={`2px solid ${colours[theme].supp_Lavender_100}`}
                                    boder={
                                        status
                                            ? `1px solid ${colours[theme].border_muted}`
                                            : `1px solid ${colours[theme].supp_Lavender_100}`
                                    }
                                    isDisabled={task.status ? true : false}
                                    onClick={() =>
                                        task.status
                                            ? {}
                                            : task.due && task.due !== '-'
                                            ? task.reminders &&
                                              task.reminders.length > 0
                                                ? navigate(
                                                      `/tasks/task?taskid=${task.id}&reminders=true&viewid=view`
                                                  )
                                                : navigate(
                                                      `/tasks/task?taskid=${task.id}&reminders=true&viewid=create`
                                                  )
                                            : navigate(
                                                  `/tasks/task?taskid=${task.id}&setduedate=true`
                                              )
                                    }
                                >
                                    <div
                                        style={{
                                            height: 16,
                                            display: 'flex',
                                            gap: 8,
                                            color: status
                                                ? colours[theme].text_muted
                                                : colours[theme]
                                                      .supp_Lavender_100,
                                        }}
                                    >
                                        {status
                                            ? 'Reminders'
                                            : task.has_reminders ||
                                              (task.reminders &&
                                                  task.reminders.length > 0)
                                            ? 'View Reminders'
                                            : 'Set Reminders'}
                                    </div>
                                </StandardCtaBtn>
                            )}
                        </div>
                    </Row>
                </Column>
            </Link>
        </ShadowBox>
    ) : (
        <div />
    )
}

export default TaskCardDesktop
