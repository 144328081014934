import React from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import TimelineItemReturnNav from '../../../atoms/header/timelineItemReturnNav'
import EntryOptionsMenuBar from '../../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'

import EntryPageTopHeaderDesktop from '../../../molecules/entryPageTopHeader/entryPageTopHeaderDesktop'
import SingleTimelineItemBodyDesktop from '../../../molecules/singleTimelineItemBody/singleTimelineItemBodyDesktop'
import SingleTimelineItemBodyMobile from '../../../molecules/singleTimelineItemBody/singleTimelineItemBodyMobile'
import Faded from '../../../templates/animated/faded'
import SideSlider from '../../../templates/animated/sideSlider'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../../templates/displays/pageWrappers/CenteredPageWrapper'
import WindowPageTemplate from '../../../templates/windowPageTemplate/windowPageTemplateDesktop'
import FullPagePageWrapper from '../../displays/pageWrappers/FullPagePageWrapper'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { IExternalCar, ICar } from '../../../../redux/entities/cars/types'
import { ITimelineItem } from '../../../../redux/timeline/types'
import { IUser } from '../../../../redux/user/types'
import { timelineActions } from '../../../../redux/timeline/reducer'

const SmallPadding = styled.div`
    padding-top: 20px;
`

interface Props {
    sharedCar?: IExternalCar
    prevOwned?: ICar
    userLoggedIn: IUser | null
}

const ReadOnlyCarHistoryFileEntry: React.FC<Props> = ({
    sharedCar,
    prevOwned,
    userLoggedIn,
}) => {
    const { carid } = useParams<{ carid: string }>()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const timelineData = useAppSelector(
        (state) => state.timeline.timeline_data_normalised_by_car_id
    )
    const attachmentsObj = useAppSelector(
        (state) => state.attachments.attachmentsById
    )

    const userCurrency = useAppSelector(
        (state) =>
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id
    )
    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )

    const setActiveEntry = (payload: ITimelineItem) =>
        dispatch(timelineActions.setActiveEntrySuccess(payload))

    const navigateEntries = (direction: 'next' | 'previous') => {
        const all_entries =
            timelineData &&
            carid &&
            timelineData[carid] &&
            timelineData[carid].entries

        const q_params = new URLSearchParams(location.search)
        const entryid = q_params.get('entryid')

        const indexOFActiveEntry =
            all_entries &&
            all_entries?.findIndex(
                (entry: ITimelineItem) => entry && entry.id === entryid
            )

        const nextEntry =
            all_entries && (indexOFActiveEntry || indexOFActiveEntry === 0)
                ? indexOFActiveEntry < all_entries.length - 1
                    ? all_entries[indexOFActiveEntry + 1]
                        ? all_entries[indexOFActiveEntry + 1]
                        : undefined
                    : all_entries[all_entries.length - 1]
                    ? all_entries[all_entries.length - 1]
                    : undefined
                : undefined

        const previousEntry =
            all_entries && (indexOFActiveEntry || indexOFActiveEntry === 0)
                ? indexOFActiveEntry > 1
                    ? all_entries[indexOFActiveEntry - 1]
                        ? all_entries[indexOFActiveEntry - 1]
                        : undefined
                    : all_entries[0]
                    ? all_entries[0]
                    : undefined
                : undefined

        const linkPrefix = sharedCar ? '/shared' : prevOwned ? '/archives' : ''

        if (direction === 'next' && nextEntry !== undefined) {
            setActiveEntry(nextEntry)
            navigate(
                `${linkPrefix}/car/${carid}/history-file/entry?entryid=${nextEntry.id}`
            )
        } else if (previousEntry !== undefined) {
            setActiveEntry(previousEntry)
            navigate(
                `${linkPrefix}/car/${carid}/history-file/entry?entryid=${previousEntry.id}`
            )
        }
    }

    const q_params = new URLSearchParams(location.search)
    const entryid = q_params.get('entryid')

    const all_entries =
        timelineData &&
        carid &&
        timelineData[carid] &&
        timelineData[carid].entries

    const indexOFActiveEntry =
        all_entries &&
        all_entries?.findIndex((entry: ITimelineItem) => entry.id === entryid)

    const activeEntry =
        all_entries &&
        indexOFActiveEntry !== undefined &&
        indexOFActiveEntry !== null &&
        all_entries[+indexOFActiveEntry]

    const attachmentSectionDiv = document.getElementById('attachments-section')

    const userDistanceUnit =
        userLoggedIn?.preferences?.data?.units?.data?.distance_unit
            ?.user_choice_id

    const currentCar = sharedCar ?? prevOwned

    const linkPrefix = sharedCar ? '/shared' : prevOwned ? '/archives' : ''

    return (
        <React.Fragment>
            <Faded>
                {activeEntry && currentCar && (
                    <>
                        <DesktopDisplayOnly>
                            <FullPagePageWrapper paddingTop="24px">
                                <Faded>
                                    <div
                                        style={{
                                            position: 'relative',
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingTop: '20px',
                                            }}
                                        />
                                        <WindowPageTemplate
                                            isCollapsed={isCollapsed}
                                            isExternalCar
                                            rightSideContent={
                                                <EntryOptionsMenuBar
                                                    activeEntry={activeEntry}
                                                    carid={carid}
                                                    noAddLabelButton
                                                    readOnlyMode
                                                />
                                            }
                                            stickyHeaderContent={
                                                <EntryPageTopHeaderDesktop
                                                    item={activeEntry}
                                                    elementToScrollTo={
                                                        attachmentSectionDiv
                                                    }
                                                    entityID={activeEntry.id}
                                                    carID={carid}
                                                    entityType="entry"
                                                    goBackToTimeline={`${linkPrefix}/car/${carid}/history-file`}
                                                    goToNextEntry={() =>
                                                        navigateEntries('next')
                                                    }
                                                    goToPreviousEntry={() =>
                                                        navigateEntries(
                                                            'previous'
                                                        )
                                                    }
                                                    readOnlyMode
                                                />
                                            }
                                        >
                                            <SingleTimelineItemBodyDesktop
                                                item={activeEntry}
                                                entityType="entry"
                                                carID={carid}
                                                entityID={activeEntry.id}
                                                attachmentsObj={attachmentsObj}
                                                costsObj={
                                                    activeEntry.costItemsObj
                                                }
                                                userCurrency={userCurrency}
                                                isCostAttachmentBeingUploaded={
                                                    false
                                                }
                                                readOnlyMode
                                                sharedWithYouCar={sharedCar}
                                                toggleAddGalleryImageToEntryForm={() => {}}
                                            />
                                        </WindowPageTemplate>
                                    </div>
                                </Faded>
                            </FullPagePageWrapper>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <CenteredPageWrapper fullwidth>
                                <div
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        marginTop: '-50px',
                                    }}
                                >
                                    <TimelineItemReturnNav
                                        chevron={true}
                                        customText={`Entries`}
                                        linkTo={`${linkPrefix}/car/${carid}/history-file`}
                                        dataCyIdThreeDots={
                                            'open-entry-slide-up-menu'
                                        }
                                        dataCyIdChevron={'go-back-to-timeline'}
                                        category={activeEntry.categoryID}
                                        currentExternalCarTitle={
                                            currentCar && currentCar.title
                                        }
                                        isReadonly
                                    />

                                    <SideSlider
                                        direction="right"
                                        initial="enter"
                                    >
                                        <SmallPadding />

                                        <SingleTimelineItemBodyMobile
                                            item={activeEntry}
                                            userDistanceUnit={userDistanceUnit}
                                            attachmentsObj={attachmentsObj}
                                            entityType="entry"
                                            carID={carid}
                                            entityID={activeEntry.id}
                                            costsObj={activeEntry.costItemsObj}
                                            userCurrency={userCurrency}
                                            isCostAttachmentBeingUploaded={
                                                false
                                            }
                                            readOnlyMode
                                            sharedWithYouCar={sharedCar}
                                        />
                                    </SideSlider>
                                </div>
                            </CenteredPageWrapper>
                        </IpadAndMobileDisplay>
                    </>
                )}
            </Faded>
        </React.Fragment>
    )
}

export default ReadOnlyCarHistoryFileEntry
