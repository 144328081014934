import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISearchUsersResult, ISearchUsersState } from './types'
import { IReduxError } from '../entities/cars/types'

const initialState: ISearchUsersState = {
    list: undefined,
    loading: false,
    error: null,
}

const searchUsersSlice = createSlice({
    name: 'searchUsers',
    initialState,
    reducers: {
        searchPlatformUsersRequest: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.loading = true
            state.error = null
        },

        searchPlatformUsersError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        searchPlatformUsersSuccess: (
            state,
            action: PayloadAction<ISearchUsersResult[] | undefined>
        ) => {
            state.list = action.payload
            state.loading = false
            state.error = null
        },

        checkUserEmailExistsRequest: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.loading = true
            state.error = null
        },

        checkUserEmailExistsError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },

        checkUserEmailExistsSuccess: (
            state,
            action: PayloadAction<ISearchUsersResult[] | undefined>
        ) => {
            state.list = action.payload
            state.loading = false
            state.error = null
        },

        cleanUpSearchUsersSuccess: (state) => {
            state.list = undefined
            state.loading = false
            state.error = null
        },
    },
})

export const searchUsersActions = searchUsersSlice.actions
export default searchUsersSlice.reducer

export type SearchUsersState = ReturnType<typeof searchUsersSlice.reducer>
