import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ITechnicalInformationState,
    ITechnicalInformationPayload,
    ITechnicalInformationFormPayload,
    ITechnicalInformationObject,
    IwriteTechnicalInformationPayloadReq,
    IwriteMultipleTechnicalInformationPayloadReq,
} from 'entityModels'
import { IReduxError } from '../cars/types'

const initialState: ITechnicalInformationState = {
    technical_information: {},
    list: [
        'make',
        'model',
        'year',
        'body_type',
        'drive_side',
        'transmission',
        'mileage',
        'engine_capacity',
        'registration_number',
        'registration_date',
        'last_owner_change',
        'service_check_status',
        'service_check_expiry',
        'tax_status',
        'tax_due',
        'color_exterior',
        'color_interior',
        'fuel_type',
        'drive_train',
        'chassis_number',
        // 'condition',
        // 'features',
    ],
    error: null,
    loading: false,
}

const technicalInformationSlice = createSlice({
    name: 'technicalInformation',
    initialState,
    reducers: {
        getTechnicalInformationRequest: (state) => {
            state.loading = true
            state.error = null
        },

        getTechnicalInformationError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = true
            state.error = action.payload
        },

        getTechnicalInformationSuccess: (
            state,
            action: PayloadAction<ITechnicalInformationPayload>
        ) => {
            state.technical_information = {
                ...state.technical_information,
                [action.payload.id]: { ...action.payload.data },
            }
            state.loading = false
            state.error = null
        },

        writeTechnicalInformationRequest: (
            state,
            action: PayloadAction<IwriteTechnicalInformationPayloadReq>
        ) => {
            state.loading = true
            state.error = null
        },

        writeMultipleTechnicalInformationRequest: (
            state,
            action: PayloadAction<IwriteMultipleTechnicalInformationPayloadReq>
        ) => {
            state.loading = true
            state.error = null
        },

        writeTechnicalInformationError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = true
            state.error = action.payload
        },

        writeTechnicalInformationSuccess: (
            state,
            action: PayloadAction<ITechnicalInformationFormPayload>
        ) => {
            state.loading = false
            state.error = null

            if (state.technical_information[action.payload.tech_info_id]) {
                if (
                    // @ts-ignore
                    state.technical_information[action.payload.tech_info_id][
                        action.payload.id
                    ]
                ) {
                    state.technical_information[action.payload.tech_info_id] = {
                        ...state.technical_information[
                            action.payload.tech_info_id
                        ],
                        [action.payload.id]: {
                            // @ts-ignore
                            ...state.technical_information[
                                action.payload.tech_info_id
                            ][action.payload.id],
                            ...action.payload.data,
                        },
                    }
                } else {
                    state.technical_information[action.payload.tech_info_id] = {
                        ...state.technical_information[
                            action.payload.tech_info_id
                        ],
                        [action.payload.id]: {
                            ...action.payload.data,
                        },
                    }
                }
                state.technical_information[action.payload.tech_info_id] = {
                    ...state.technical_information[action.payload.tech_info_id],
                    [action.payload.id]: {
                        // @ts-ignore
                        ...state.technical_information[
                            action.payload.tech_info_id
                        ][action.payload.id],
                        ...action.payload.data,
                    },
                }
            } else {
                // @ts-ignore
                state.technical_information[action.payload.tech_info_id] = {
                    [action.payload.id]: {
                        ...action.payload.data,
                    },
                }
            }
        },

        setTechnicalInformationRequest: (state) => {
            state.loading = true
            state.error = null
        },

        setTechnicalInformationError: (
            state,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = true
            state.error = action.payload
        },

        setTechnicalInformationSuccess: (
            state,
            action: PayloadAction<ITechnicalInformationObject>
        ) => {
            state.technical_information = {
                ...state.technical_information,
                ...action.payload,
            }
            state.loading = false
            state.error = null
        },

        cleanUpTechnicalInformationSuccess: (state) => {
            state.technical_information = {}
            state.list = [
                'make',
                'model',
                'year',
                'body_type',
                'drive_side',
                'transmission',
                'mileage',
                'engine_capacity',
                'registration_number',
                'registration_date',
                'last_owner_change',
                'service_check_status',
                'service_check_expiry',
                'tax_status',
                'tax_due',
                'color_exterior',
                'color_interior',
                'fuel_type',
                'drive_train',
                'chassis_number',
                // 'condition',
                // 'features',
            ]
            state.error = null
            state.loading = false
        },
    },
})

export const technicalInformationActions = technicalInformationSlice.actions

export default technicalInformationSlice.reducer

export type TechnicalInformationState = ReturnType<
    typeof technicalInformationSlice.reducer
>
