import { call, fork, put, take } from 'redux-saga/effects'
import { showroomActions } from '../../showroom/reducer'
import posthog from 'posthog-js'
import { customNavDataActions } from '../../localdata/customNav/reducer'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { api } from '../../services'
import { featureFlagsActions } from '../../featureFlags/reducer'
import { usersActions } from '../reducer'
import { carActions } from '../../entities/cars/reducer'
import { garagesActions } from '../../entities/garages/reducer'
import { timelineActions } from '../../timeline/reducer'
import { tasksActions } from '../../tasks/reducer'
import { technicalInformationActions } from '../../entities/technical_information/reducer'
import { highlightedFactsActions } from '../../entities/highlighted_facts/reducer'
import { galleriesActions } from '../../entities/galleries/reducer'
import { searchUsersActions } from '../../searchUsers/reducer'
import { attachmentActions } from '../../attachments/reducer'
import { IReduxError } from '../../entities/cars/types'
import { push } from 'redux-first-history'

export function* logoutSaga(): any {
    try {
        yield call(api.authentication.logout)

        yield put(usersActions.logoutUserSuccess())

        posthog.reset()

        // clean up Redux of all cars and garages data
        yield put(carActions.cleanUpCarDataOnLogoutSuccess())
        yield put(garagesActions.cleanUpGaragesDataOnLogoutSuccess())
        yield put(timelineActions.cleanUpTimelineDataOnLogoutSuccess())
        yield put(tasksActions.cleanUpTasksSuccess())
        yield put(
            technicalInformationActions.cleanUpTechnicalInformationSuccess()
        )
        yield put(highlightedFactsActions.cleanUpHighlightedFactsSuccess())
        yield put(galleriesActions.cleanUpGallerySuccess())
        yield put(searchUsersActions.cleanUpSearchUsersSuccess())
        yield put(attachmentActions.cleanUpAttachmentsSuccess())
        yield put(showroomActions.cleanUpShowroom())
        yield put(featureFlagsActions.cleanupFeatureFlags())

        yield put(usersActions.resetRefreshTokenRetriesCount())

        posthog.capture('USER LOGOUT')
        yield put(customNavDataActions.setInitialLandingUrl(null))

        yield put(push('/signin'))
        localStorage?.removeItem('refresh_token')
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)
        yield put(usersActions.logoutUserError(typedError))
        window.location.reload()
    }
}

function* watcherLogout() {
    while (true) {
        yield take(usersActions.logoutRequest)
        yield call(logoutSaga)
    }
}

const logout: any[] = [fork(watcherLogout)]

export default logout
