import styled from 'styled-components'
import {
    car_enquiry_contact_copy,
    car_enquiry_contact_copy_options,
    car_enquiry_contact_details_copy,
    car_enquiry_contact_details_copy_options,
    IEnquiryOptionCopyItem,
} from '../../../redux/localdata/carEnquiryCopy'
import { IEnquiryStructureRequest } from '../../../redux/showroom/types'
import BottomBarBtnsStep from '../../atoms/Button/bottomBarBtnsStep'
import CheckboxWithIconRowItem from '../../atoms/formField/checkboxWithIconRowItem'
import InputFieldNoFormikAnimated from '../../atoms/Inputfield/inputFieldNoFormikAnimated'
import EnquiryStepTitle from '../../atoms/typography/showroom/enquiryStepTitle'
import { EnquiryBtnsOnlyMobile } from './carEnquiryInfoForm'
import { validateEmail } from '../editForms/account/accountPrivateInfoForm/basic/basicAccountItemInfoFormDesktop'
import PhoneInputNew from '../phonefield/phoneInputNew'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useAppSelector } from '../../../redux/store/hooks'
import { useState } from 'react'
import React from 'react'

export type ICarEnquiry_Contact_FormManager_props = {
    handleContactDetailsChange: (
        id: string,
        value: string | number | undefined
    ) => void
    handleSelectedIDChange: (selectedID: string) => void
    manageStep: (p: 'contact' | 'enquiry') => void
    enquiryFormData: IEnquiryStructureRequest
    onSubmit: () => void
}
export const EnquityFormPaddingBottomDesktop = styled.div`
    @media screen and (min-width: 880px) {
        padding-bottom: 100px;
        background-color: var(--bg-color, #fff);
    }
`

const CarEnquiryContactForm = (
    props: ICarEnquiry_Contact_FormManager_props
) => {
    const {
        handleSelectedIDChange,
        enquiryFormData,
        handleContactDetailsChange,
        manageStep,
        onSubmit,
    } = props

    const [isPhoneInvalid, setIsPhoneInvalid] = useState<boolean>(false)

    const validatePhoneNumber = (
        value: string | undefined | number,
        country: string | undefined
    ) => {
        if (value && country) {
            let isPhoneNbValid = isValidPhoneNumber(
                `${value}`,
                // @ts-ignore
                country ? country : 'GB'
            )

            setIsPhoneInvalid(isPhoneNbValid ? false : true)
        } else setIsPhoneInvalid(true)
    }

    let countryPhone: string | undefined = useAppSelector((state) => {
        return state.editForms.user_dial_country
    })

    const isContactSubmitDisabled = () => {
        let isEmailPassingChecks =
            (enquiryFormData.contact.medium.includes('email') &&
                enquiryFormData.contact.details.email === '') ||
            !enquiryFormData.contact.details.email
                ? false
                : true

        if (
            isEmailPassingChecks === true &&
            !validateEmail(enquiryFormData.contact.details.email)
        ) {
            isEmailPassingChecks = false
        }

        let isPhonePassingChecks =
            (enquiryFormData.contact.medium.includes('phone') ||
                enquiryFormData.contact.medium.includes('message')) &&
            (enquiryFormData.contact.details.phone === undefined || // @ts-ignore
                enquiryFormData.contact.details.phone?.length === 0)
                ? false
                : true

        let isDetailPassingChecks =
            !enquiryFormData.contact.details.name ||
            (enquiryFormData.contact.details.name &&
                // @ts-ignore
                enquiryFormData.contact.details.name.length < 1)
                ? false
                : true

        let isMediumPassingChecks =
            enquiryFormData.contact.medium.length === 0 ? false : true

        if (
            isDetailPassingChecks == true &&
            isEmailPassingChecks === true &&
            isPhonePassingChecks === true &&
            isMediumPassingChecks === true
        ) {
            return false
        }
        return true
    }

    return (
        <div style={{ width: '100%' }}>
            <EnquiryStepTitle>
                {car_enquiry_contact_details_copy.text}
            </EnquiryStepTitle>
            <div style={{ paddingTop: '20px' }} />
            <div style={{ width: '100%' }}>
                {car_enquiry_contact_details_copy_options.map(
                    (item: IEnquiryOptionCopyItem) => {
                        let id: 'email' | 'phone' | 'name' =
                            item.id === 'email'
                                ? 'email'
                                : item.id === 'phone'
                                ? 'phone'
                                : 'name'

                        if (id === 'phone') {
                            return (
                                <div
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        paddingBottom: '20px',
                                    }}
                                    key={item.id}
                                >
                                    <PhoneInputNew
                                        value={
                                            enquiryFormData.contact.details[
                                                'phone'
                                            ]
                                        }
                                        handleChangeNumber={(phone: string) => {
                                            handleContactDetailsChange(
                                                'phone',
                                                phone
                                            )
                                        }}
                                        isPhoneInvalid_parent_check={
                                            isPhoneInvalid
                                        }
                                    />
                                </div>
                            )
                        } else
                            return (
                                <div style={{ width: '100%' }} key={item.id}>
                                    <InputFieldNoFormikAnimated
                                        name={item.name}
                                        placeholder={item.name}
                                        value={
                                            enquiryFormData.contact.details[id]
                                        }
                                        id={id}
                                        onChange={(e: any) => {
                                            handleContactDetailsChange(
                                                item.id,
                                                e.target.value
                                            )
                                        }}
                                        type={'text'}
                                        isPhone={false}
                                    />
                                    <div style={{ paddingTop: '20px' }} />
                                </div>
                            )
                    }
                )}
            </div>

            <EnquiryStepTitle>{car_enquiry_contact_copy.text}</EnquiryStepTitle>
            <div style={{ paddingTop: '20px' }} />
            <div>
                {car_enquiry_contact_copy_options.map(
                    (item: IEnquiryOptionCopyItem, i) => {
                        return (
                            <React.Fragment key={`enquiry_${item.id}_${i}`}>
                                <CheckboxWithIconRowItem
                                    name={item.id}
                                    text={item.name}
                                    onChange={() => {
                                        handleSelectedIDChange(item.id)

                                        if (
                                            item.id === 'phone' ||
                                            item.id === 'message'
                                        ) {
                                            validatePhoneNumber(
                                                // @ts-ignore
                                                enquiryFormData.contact.details
                                                    .phone,
                                                countryPhone
                                                    ? countryPhone
                                                    : 'GB'
                                            )
                                        }
                                    }}
                                    iconID={item.iconID}
                                    checked={
                                        enquiryFormData.contact.medium.indexOf(
                                            item.id
                                        ) === -1
                                            ? false
                                            : true
                                    }
                                />
                            </React.Fragment>
                        )
                    }
                )}
            </div>

            <EnquityFormPaddingBottomDesktop />

            <EnquiryBtnsOnlyMobile>
                <BottomBarBtnsStep
                    cancel={() => {
                        manageStep('enquiry')
                    }}
                    submit={() => {
                        onSubmit()
                    }}
                    confirmationtext="continue"
                    disabled={isContactSubmitDisabled()}
                    hasGoBack={true}
                    onGoback={() => {
                        manageStep('enquiry')
                    }}
                />
            </EnquiryBtnsOnlyMobile>
        </div>
    )
}

export default CarEnquiryContactForm
