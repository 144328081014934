import React from 'react'
import MobileDisplay from '../templates/displays/mobileDisplay'
import Loader from '../atoms/loader/loader'
import DesktopDisplay from '../templates/displays/desktopDisplay'
import Faded from '../templates/animated/faded'
import AddressForm from '../organisms/addressForm/addressForm'
import TriangleHeader from '../atoms/header/triangleheader'
import Question from '../atoms/typography/question'
import PublicPageWrapper from '../templates/displays/pageWrappers/PublicPageWrapper'
import { IRootState } from '../../redux/store'
import { IUserAddressApi } from '../../redux/services/typedefinitions/apiPayloads'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'
import { usersActions } from '../../redux/user/reducer'

const EnterAddress: React.FC = () => {
    const dispatch = useAppDispatch()
    const user = useAppSelector((state: IRootState) => state.user.userLoggedIn)
    const isLoading = useAppSelector((state: IRootState) => state.user.loading)

    const handleAddAddress = (address: IUserAddressApi) => {
        dispatch(usersActions.addAddressRequest({ data: address }))
    }

    return (
        <PublicPageWrapper>
            <Faded>
                {isLoading === true && <Loader />}
                {user === null && <Loader />}

                <TriangleHeader title="contact" />
                <Question>Please enter your address:</Question>
                <DesktopDisplay>
                    <AddressForm
                        addAddress={handleAddAddress}
                        isDesktop={true}
                    />
                </DesktopDisplay>
            </Faded>

            <MobileDisplay>
                <AddressForm addAddress={handleAddAddress} isDesktop={false} />
            </MobileDisplay>
        </PublicPageWrapper>
    )
}

export default EnterAddress
