import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import no_cars_placeholder from '../../../public/assets/placeholder/no_cars_placeholder.svg'
import { device } from '../../templates/displays/devices'

const Wrapper = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    @media ${device.ipad} {
        ${(props) => props.$variant === 'mobile' && 'padding-top: 30px;'}
    }
`

const SVGWrapper = styled.div`
    min-width: 40px;
    padding: 10px;
    padding-right: 15px;
    display: flex;
    align-items: center;
`

const Text = styled.div<IStyle>`
    font-family: 'Lato';
    font-size: ${(props) => (props.$variant === 'mobile' ? '14px' : '16px')};
    align-self: ${(props) =>
        props.$alignTextLeft && props.$variant !== 'mobile'
            ? 'flex-start'
            : 'center'};
    padding-top: 10px;
    padding-bottom: 50px;
    color: #878787;
    text-align: center;
`

const AddCar = styled(Link)`
    color: #359ea4 !important;
    @media ${device.mobile} {
        line-height: 30px;
    }
`

const SvgImage = styled.img`
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    height: auto;
`

interface IStyle {
    $variant: 'desktop' | 'mobile'
    $alignTextLeft?: boolean
}

interface IProps {
    text?: string
    alignTextLeft?: boolean
    variant: 'desktop' | 'mobile'
}

const NoCarsInGarage: React.FunctionComponent<IProps> = (props) => {
    return (
        <Wrapper $variant={props.variant}>
            <Text $variant={props.variant} $alignTextLeft={props.alignTextLeft}>
                Oops... you have no cars in your Garage.
                <span style={{ paddingRight: '3px' }} />
                {props.variant === 'mobile' && <br />}
                <AddCar to="/garage?action=add_car">Add a Car</AddCar>
            </Text>
            <SVGWrapper>
                <SvgImage src={no_cars_placeholder} />
            </SVGWrapper>
        </Wrapper>
    )
}

export default NoCarsInGarage
