import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import { editFormsActions } from '../../../redux/editForms/reducer'
import TimelineEditManagerDesktop from '../../organisms/timelineForms/timelineEditManager/timelineEditManagerDesktop'
import TimelineEditManagerMobile from '../../organisms/timelineForms/timelineEditManager/timelineEditManagerMobile'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import BottomUpSlider from '../../templates/animated/bottomUpSlider'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import EntryOptionsMenuBar from '../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { TimelineFormTypeIDsEnum } from '../../../redux/timeline/timelineEnum'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { timelineActions } from '../../../redux/timeline/reducer'
import { ITimelineItem } from '../../../redux/timeline/types'
import { technicalInformationActions } from '../../../redux/entities/technical_information/reducer'

const EditTimelineEntry: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid } = useParams<{ carid: string }>()

    const [state, setState] = useState({
        isMobileActionsSubMenuOpen: false,
        bottomsheetTimer: 500,
        showConfirmDeleteEntrySheet: false,
        showRightSide: true,
    })

    const {
        userLoggedIn,
        carsTimelineData,
        activeEntry,
        timelineItemEditForm,
        user_searchable_labels_list,
        isCollapsed,
    } = useAppSelector((state) => ({
        userLoggedIn: state.user.userLoggedIn,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
        data_to_be_submitted:
            state.timeline.formsData.creation.data_to_be_submitted,
        activeEntry: state.timeline.activeEntry,
        activeCost: state.timeline.activeCost,
        timelineItemEditForm: state.editForms.timelineItemEditForm,
        attachmentsObj: state.attachments.attachmentsById,
        timeline_creation_steps_data:
            state.timeline.formsData.creation.timeline_creation_steps_data,
        timeline_creation_forms_current_state:
            state.editForms.timelineCreationForm,
        timeline_creation_steps_object_of_indexed_list:
            state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list,
        user_searchable_labels_list: state.user.userLoggedIn?.labels,
        entry_labels_list: state.timeline.activeEntry?.labels,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }))

    const handleResize = useCallback(() => {
        if (window.innerWidth < 1201) {
            setState((prevState) => ({ ...prevState, showRightSide: false }))
        } else {
            setState((prevState) => ({ ...prevState, showRightSide: true }))
        }
    }, [])

    useEffect(() => {
        const q_params = new URLSearchParams(location.search)
        const entryid_from_q_params = q_params.get('entry_id')
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/car/${carid}/history-file/${entryid_from_q_params}/edit`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        dispatch(carActions.setCurrentCar(`${carid}`))

        if (!carsTimelineData?.[`${carid}`]?.entries) {
            dispatch(
                timelineActions.getAllTimelineItemsByCarIDRequest({
                    car_id: `${carid}`,
                })
            )
        }

        const all_entries = carsTimelineData?.[`${carid}`]?.entries
        const activeEntryFromParams = all_entries?.find(
            (entry: ITimelineItem) => entry.id === entryid_from_q_params
        )
        if (activeEntryFromParams) {
            dispatch(
                timelineActions.setActiveEntrySuccess(activeEntryFromParams)
            )
        }

        if (!activeEntry && entryid_from_q_params) {
            dispatch(
                timelineActions.getTimelineItemByIDRequest({
                    entryUID: entryid_from_q_params,
                    car_id: `${carid}`,
                })
            )
        }

        const editFormId = q_params.get('section_id')
        if (activeEntry && editFormId) {
            dispatch(
                editFormsActions.manageTimelineItemEditForm({
                    id: editFormId as TimelineFormTypeIDsEnum,
                    category: activeEntry.categoryID,
                    isOpen: true,
                })
            )
        }

        window.scrollTo(0, 0)
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            dispatch(
                editFormsActions.manageTimelineCreationFormState({
                    isOpen: false,
                    current_step_index: 0,
                    active_creation: null,
                })
            )
            dispatch(timelineActions.clearDataToBeSubmittedSuccess(true))
            dispatch(
                editFormsActions.manageTimelineItemEditForm({ isOpen: false })
            )
            window.removeEventListener('resize', handleResize)
        }
    }, [carid, userLoggedIn, location.search, handleResize])

    useEffect(() => {
        // if (activeEntry) {
        //     dispatch(
        //         timelineActions.getTimelineItemByIDRequest({
        //             entryUID: activeEntry.id,
        //             car_id: `${carid}`,
        //         })
        //     )
        // }

        const q_params = new URLSearchParams(location.search)
        const editFormId = q_params.get('section_id')
        if (activeEntry && editFormId) {
            dispatch(
                editFormsActions.manageTimelineItemEditForm({
                    id: editFormId as TimelineFormTypeIDsEnum,
                    category: activeEntry.categoryID,
                    isOpen: true,
                })
            )
        }
    }, [carid, location.search])

    const close_edit_form = () => {
        navigate(`/car/${carid}/history-file/entry?entryid=${activeEntry?.id}`)
        dispatch(editFormsActions.manageTimelineItemEditForm({ isOpen: false }))
    }

    return (
        <CenteredPageWrapper fullwidth>
            <Faded>
                {activeEntry && (
                    <>
                        {timelineItemEditForm.isOpen && (
                            <DesktopDisplayOnly>
                                <WindowPageTemplate
                                    isCollapsed={isCollapsed}
                                    rightSideContent={
                                        activeEntry &&
                                        state.showRightSide && (
                                            <EntryOptionsMenuBar
                                                carid={`${carid}`}
                                                activeEntry={activeEntry}
                                            />
                                        )
                                    }
                                >
                                    <TimelineEditManagerDesktop
                                        item_to_edit={activeEntry}
                                        timelineItemEditForm={
                                            timelineItemEditForm
                                        }
                                        savingEdit={(
                                            edited_item: ITimelineItem
                                        ) => {
                                            dispatch(
                                                timelineActions.updateTimelineEntryRequest(
                                                    {
                                                        carid: `${carid}`,
                                                        item: {
                                                            ...edited_item,
                                                            id: activeEntry?.id,
                                                        },
                                                    }
                                                )
                                            )
                                        }}
                                        closeForm={close_edit_form}
                                        dataCyId={'edit-timeline-entry'}
                                        entityID={activeEntry.id}
                                        entityType="entry"
                                        carID={`${carid}`}
                                    />
                                </WindowPageTemplate>
                            </DesktopDisplayOnly>
                        )}

                        <IpadAndMobileDisplay>
                            {timelineItemEditForm.isOpen && (
                                <BottomUpSlider
                                    show={timelineItemEditForm.isOpen}
                                >
                                    <TimelineEditManagerMobile
                                        item={activeEntry}
                                        timelineItemEditForm={
                                            timelineItemEditForm
                                        }
                                        savingEdit={(
                                            editedItem: ITimelineItem,
                                            carMileage: {
                                                sendToBe: boolean
                                                payload: IwriteTechnicalInformationPayloadReq
                                            }
                                        ) => {
                                            dispatch(
                                                timelineActions.updateTimelineEntryRequest(
                                                    {
                                                        carid: `${carid}`,
                                                        item: {
                                                            ...editedItem,
                                                            id: activeEntry?.id,
                                                        },
                                                    }
                                                )
                                            )
                                            if (carMileage.sendToBe) {
                                                dispatch(
                                                    technicalInformationActions.writeTechnicalInformationRequest(
                                                        carMileage.payload
                                                    )
                                                )
                                            }
                                        }}
                                        closeForm={close_edit_form}
                                        dataCyId={'edit-timeline-entry'}
                                        labels_searchable_list={
                                            user_searchable_labels_list
                                        }
                                        entityID={activeEntry.id}
                                        entityType="entry"
                                        carID={`${carid}`}
                                    />
                                </BottomUpSlider>
                            )}
                        </IpadAndMobileDisplay>
                    </>
                )}
            </Faded>
        </CenteredPageWrapper>
    )
}

export default EditTimelineEntry
