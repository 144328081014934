import styled from 'styled-components'
import ShowMoreBtn from '../../../atoms/Button/showMoreBtn'
import AddBoxWithPlaceholderAndPlusDesktop from '../../../atoms/placeholders/addBoxWithPlaceholderAndPlusDesktop'
import AddBoxWithSvgPlaceholderDesktop from '../../../atoms/placeholders/addBoxWithSvgPlaceholderDesktop'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { device } from '../../../templates/displays/devices'
import placeholderImg from '../../../atoms/skeleton/gallery/1.jpg'
import placeholderImgDark from '../../../atoms/skeleton/gallery/1_dark.png'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { useState } from 'react'
import parse from 'html-react-parser'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import ShowroomH2 from '../../../atoms/typography/showroom/ShowroomH2'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    isLoading: boolean
    currentEntry: IShowroomEntry | undefined
    onShowMoreClick?: () => void
    isAdmin?: boolean
    setActiveForm?: () => any
    editDescriptionImage?: () => any
    carHasNoImages?: boolean
}

type StyleProps = {
    $isTitle?: boolean
    $isShowMore?: boolean
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;

    flex: 1;

    @media ${device.smallest_laptops} {
        align-items: flex-start;
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    gap: 2rem;
    width: 80%;
    min-width: 500px;

    max-width: 700px;
    padding-right: 100px;
    height: inherit;

    @media (max-width: 1300px) {
        gap: 1rem;
        padding-right: 100px;
    }
    @media (max-width: 1030px) {
        min-width: 450px;
        gap: 1rem;
        padding-right: 60px;
    }

    @media (max-width: 980px) {
        min-width: 400px;
    }
`

const Description = styled.div<StyleProps>`
    font-family: Lato;
    font-style: normal;
    text-transform: none;
    font-size: 1rem !important;
    line-height: 1.5rem;
    width: 100%;
    box-sizing: border-box;
    transition: all 100ms;

    p,
    li,
    h1,
    h2,
    h3 {
        color: var(--text-default, #666666);
    }

    @media ${device.smallest_laptops} {
        font-size: 0.9rem;
    }

    @media ${device.large_desktop} {
        font-size: 18px;
        line-height: 28px;
    }

    h2 {
        font-size: 19px !important;
        padding-bottom: 10px;
    }

    h1 {
        font-size: 20px !important;
        padding-bottom: 10px;
    }
`

const SectionImage = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    object-fit: cover;
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
`

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    height: 400px;

    @media ${device.smallest_laptops} {
        width: 100%;
        box-sizing: border-box;
        height: 350px;
    }

    /* @media (max-width: 1220px) {
        width: 400px;
        height: 250px;
    } */

    @media (max-width: 1220px) {
        width: 550px;
        height: 250px;
    }

    @media (min-width: 2000px) {
        height: 500px;
    }

    border-radius: 2px;
`

// container for loader skeleton
const LoaderImageContainer = styled.div`
    width: 55%;
    height: 100%;
`

const DescriptionSectionDesktop = (props: Props) => {
    const {
        currentEntry,
        isLoading,
        editDescriptionImage,
        onShowMoreClick,
        carHasNoImages,
    } = props

    let car = currentEntry && currentEntry.car ? currentEntry.car : undefined

    let image_1 =
        currentEntry && currentEntry.featured_image
            ? currentEntry.featured_image
            : undefined

    const [showEditBtn, setShowEditBtn] = useState(false)

    let textWithoutMainTags =
        car &&
        car.description &&
        car.description
            .replace(/<\/h1>/g, '\n')
            .replace(/<\/h2>/g, '\n')
            .replace(/<\/h3>/g, '\n')
            .replace(/<\/li>/g, '\n')
            .replace(/<\/ul>/g, '\n')
            .replace(/<\/p>/g, '\n')
            // .replace(/<[^>]+>/g, '')
            .replace(/\n/g, '<br />')

    const windowWidth = useWindowSize().width

    let txtLimitN: number =
        windowWidth && windowWidth < 1220
            ? 250
            : windowWidth && windowWidth < 1700
            ? 450
            : windowWidth && windowWidth > 1700
            ? 500
            : 400

    let str = textWithoutMainTags
        ? textWithoutMainTags.length > txtLimitN
            ? `${textWithoutMainTags.substring(0, txtLimitN)}...`
            : car?.description
        : undefined

    const { theme } = useThemes()

    return (
        <Container>
            <ContentContainer>
                <ShowroomH2
                    leftAlign
                    textTransform="capitalize"
                    hasEdit={
                        props.isAdmin && props.setActiveForm ? true : false
                    }
                    onEdit={(e: any) => {
                        e.preventDefault()
                        props.setActiveForm && props.setActiveForm()
                    }}
                >
                    description
                </ShowroomH2>

                {car && car.description && car.description.length > 1 ? (
                    <Description>{str ? parse(str) : '-'}</Description>
                ) : (
                    <AddBoxWithPlaceholderAndPlusDesktop
                        height="150px"
                        onClickAdd={() => {
                            props.setActiveForm && props.setActiveForm()
                        }}
                        bgColor={'var(--off-bg-color, #F9F9F9)'}
                    />
                )}
                {textWithoutMainTags &&
                    textWithoutMainTags.length > txtLimitN && (
                        <ShowMoreBtn
                            onClick={onShowMoreClick && onShowMoreClick}
                        />
                    )}
            </ContentContainer>

            <ImageWrapper>
                {!image_1 && props.isAdmin ? (
                    <AddBoxWithSvgPlaceholderDesktop
                        height="100%"
                        onClickAdd={editDescriptionImage}
                        hasSvgBackgroundImage
                        customPlaceholderImg={
                            theme === 'dark'
                                ? placeholderImgDark
                                : placeholderImg
                        }
                        hooverText={
                            carHasNoImages
                                ? `Let's add some images to your car`
                                : 'Choose an image from your gallery'
                        }
                        isDisabled={carHasNoImages}
                    />
                ) : isLoading ? (
                    <LoaderImageContainer>
                        <SkeletonAbsolute isthingloading={isLoading} />
                    </LoaderImageContainer>
                ) : image_1 && props.isAdmin ? (
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        <Overlay
                            onMouseEnter={() => setShowEditBtn(true)}
                            onMouseLeave={() => setShowEditBtn(false)}
                        >
                            {showEditBtn && (
                                <ButtonAtom
                                    theme="lowercase-blue-background"
                                    fontSize={16}
                                    onClick={editDescriptionImage}
                                >
                                    <span style={{ textTransform: 'none' }}>
                                        Edit photo
                                    </span>
                                </ButtonAtom>
                            )}
                        </Overlay>
                        <SectionImage
                            src={image_1.url}
                            srcSet={
                                image_1.medium_srcset
                                    ? image_1.medium_srcset
                                    : undefined
                            }
                        />
                    </div>
                ) : image_1 && !props.isAdmin ? (
                    <SectionImage
                        src={image_1.url}
                        srcSet={
                            image_1.medium_srcset
                                ? image_1.medium_srcset
                                : undefined
                        }
                    />
                ) : null}
            </ImageWrapper>
        </Container>
    )
}

export default DescriptionSectionDesktop
