import { IExternalDataByRegistrationNumberSingle } from '../../entities/cars/types'
import { apiSentryErrorHandler } from '../errorHandler'
import { customHeader } from '../headers'
import * as urls from '../urls'

export const get_external_car_data_by_registration_number = (
    registration_number: string
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    let rn = registration_number.trim()

    return fetch(
        urls.get_car_data_by_registration_number_endpoint(rn),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get external car data via VES API'
                    )
                    return Promise.reject(response)
                }
                if (data) {
                    let dataToSend: IExternalDataByRegistrationNumberSingle =
                        data

                    return dataToSend
                }
            })
            .catch((error: any) => {
                return Promise.reject(error)
            })
    })
}
