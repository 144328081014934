import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type Props = {
    children?: React.ReactNode
    customZIndex?: number
    customHeight?: string
    isVisible: boolean
    customDuration?: number
    onBackdropClick?: () => void
}

const Wrapper = styled(motion.div)<{ $theme: ITheme }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 8px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};
    position: absolute;
    bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

const BottomUpSliderWithExit = (props: Props) => {
    const variants = {
        enter: () => ({
            zIndex: 0,
            y: 900,
            opacity: 0,
        }),
        center: {
            zIndex: 1,
            y: 0,
            opacity: 1,
        },
        exit: (direction: number) => ({
            zIndex: 0,
            y: 900,

            opacity: 0,
        }),
    }

    const { theme } = useThemes()

    return (
        <AnimatePresence>
            {props.isVisible && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: props.customZIndex ? props.customZIndex : 6,
                        overflowY: 'hidden',
                        backgroundColor: colours[theme].shadow_strongest_16,
                    }}
                    onClick={props.onBackdropClick}
                >
                    <Wrapper
                        $theme={theme}
                        variants={variants}
                        initial={'enter'}
                        animate="center"
                        exit="exit"
                        transition={{
                            opacity: { duration: 0.3 },
                            duration: 0.3,
                        }}
                        style={{ height: props.customHeight ?? '500px' }}
                    >
                        {props.children}
                    </Wrapper>
                </div>
            )}
        </AnimatePresence>
    )
}

export default BottomUpSliderWithExit
