/// <reference types="googlemaps" />
import * as React from 'react'
import AccountAddressFormMobile from './accontAddressFormMobile'
import { getRightSchemaAccountData } from '../validationSchema'
import { IInsuranceDropdownItem } from '../../../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'
import { IUserAddressApi } from '../../../../../redux/services/typedefinitions/apiPayloads'
import { useAppDispatch } from '../../../../../redux/store/hooks'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'

type Props = {
    toggleAddresForm: any
    addAddress: any
    currentAddress: IUserAddressApi | null
    countriesdropdownlist: any
    usaAdminCodes: IInsuranceDropdownItem[] | null
}

type LocalState = {
    street_number: string
    route: string
    postal_town: string
    postal_code: string
    country: string
    administrative_area_level_1?: string
    administrative_area_level_2?: string
    countryChangedFromSearch: boolean
    error: { [key: string]: string | undefined } | undefined
}

const AccountAddressFormManagerMobile: React.FC<Props> = ({
    toggleAddresForm,
    addAddress,
    currentAddress,
    countriesdropdownlist,
    usaAdminCodes,
}) => {
    const dispatch = useAppDispatch()

    const [state, setState] = React.useState<LocalState>({
        street_number: currentAddress?.street_1 || '',
        route: currentAddress?.street_2 || '',
        postal_town: currentAddress?.locality || '',
        postal_code: currentAddress?.postcode || '',
        country: currentAddress?.country || '',
        administrative_area_level_1: currentAddress?.administrative_area || '',
        administrative_area_level_2: currentAddress?.administrative_area || '',
        countryChangedFromSearch: false,
        error: undefined,
    })

    React.useEffect(() => {
        const googleMaps = async () => {
            let input: any = document.getElementById(
                'autocompletemobileaccountaddress'
            )

            try {
                let autocomplete = new google.maps.places.Autocomplete(input, {
                    types: ['geocode'],
                })

                autocomplete.setFields(['address_component'])

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (
                        position
                    ) {
                        let geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }

                        let circle = new google.maps.Circle({
                            center: geolocation,
                            radius: position.coords.accuracy,
                        })
                        autocomplete.setBounds(circle.getBounds())
                    })
                }

                autocomplete.addListener('place_changed', () => {
                    let place: any = autocomplete.getPlace()
                    let addressComponents: string[] = []

                    place.address_components !== undefined &&
                        place.address_components.map((chunk: any) => {
                            addressComponents.push(chunk.types[0])
                            if (chunk.types[0] === 'street_number') {
                                validateForm('street_number', chunk.long_name)
                                setState((prev) => ({
                                    ...prev,
                                    street_number: chunk.long_name,
                                }))
                            } else if (chunk.types[0] === 'route') {
                                validateForm('route', chunk.long_name)
                                setState((prev) => ({
                                    ...prev,
                                    route: chunk.long_name,
                                }))
                            } else if (chunk.types[0] === 'postal_code') {
                                validateForm(
                                    'postal_code',
                                    chunk.long_name
                                        ? `${chunk.long_name}`.toUpperCase()
                                        : ''
                                )
                                setState((prev) => ({
                                    ...prev,
                                    postal_code: chunk.long_name
                                        ? `${chunk.long_name}`.toUpperCase()
                                        : '',
                                }))
                            } else if (chunk.types[0] === 'locality') {
                                setState((prev) => ({
                                    ...prev,
                                    postal_town: chunk.long_name,
                                }))
                            } else if (chunk.types[0] === 'postal_town') {
                                validateForm('postal_town', chunk.long_name)
                                setState((prev) => ({
                                    ...prev,
                                    postal_town: chunk.long_name,
                                }))
                            } else if (
                                chunk.types[0] === 'administrative_area_level_1'
                            ) {
                                setState((prev) => ({
                                    ...prev,
                                    administrative_area_level_1:
                                        chunk.short_name,
                                }))
                            } else if (
                                chunk.types[0] === 'administrative_area_level_2'
                            ) {
                                setState((prev) => ({
                                    ...prev,
                                    administrative_area_level_2:
                                        chunk.short_name,
                                }))
                            } else if (chunk.types[0] === 'country') {
                                setState((prev) => ({
                                    ...prev,
                                    country: chunk.long_name,
                                    countryChangedFromSearch: true,
                                }))
                            }
                            return false
                        })

                    if (!addressComponents.includes('street_number')) {
                        validateForm('street_number', '')
                        setState((prev) => ({
                            ...prev,
                            street_number: '',
                        }))
                    }
                    if (!addressComponents.includes('route')) {
                        validateForm('route', '')
                        setState((prev) => ({
                            ...prev,
                            route: '',
                        }))
                    }
                    if (!addressComponents.includes('postal_code')) {
                        validateForm('postal_code', '')
                        setState((prev) => ({
                            ...prev,
                            postal_code: '',
                        }))
                    }
                    if (!addressComponents.includes('postal_town')) {
                        validateForm('postal_town', '')
                        setState((prev) => ({
                            ...prev,
                            postal_town: '',
                        }))
                    }
                })
            } catch (e) {
                console.error(e)
            }
        }

        googleMaps()

        if (state.country === 'United States' && !usaAdminCodes) {
            dispatch(dropDownActions.setUSAStatesDropdownRequest)
        }
    }, [])

    const handleSelectCountry = (answer: string) => {
        setState((prev) => ({
            ...prev,
            administrative_area_level_1:
                answer !== 'United States' && prev.administrative_area_level_1
                    ? ''
                    : prev.administrative_area_level_1,
            administrative_area_level_2:
                answer !== 'Spain' && prev.administrative_area_level_2
                    ? ''
                    : prev.administrative_area_level_2,
            country: answer,
            countryChangedFromSearch: false,
            error: prev.error
                ? { ...prev.error, country: undefined }
                : { country: undefined },
        }))

        if (answer === 'United States' && !usaAdminCodes) {
            dispatch(dropDownActions.setUSAStatesDropdownRequest)
        }
    }

    const validateForm = async (id: string, value: string) => {
        try {
            await getRightSchemaAccountData(id).validate({ [id]: value })
            setState((prev) => ({
                ...prev,
                error: prev.error
                    ? { ...prev.error, [id]: undefined }
                    : { [id]: undefined },
            }))
        } catch (err: any) {
            setState((prev) => ({
                ...prev,
                error: prev.error
                    ? { ...prev.error, [id]: err.errors[0] }
                    : { [id]: err.errors[0] },
            }))
        }
    }

    const handleChange: React.ReactEventHandler<HTMLInputElement> = (ev) => {
        let value = ev.currentTarget.value
        let name = ev.currentTarget.name

        if (name === 'postal_code') {
            value = value?.toUpperCase()
        }
        if (
            [
                'street_number',
                'route',
                'name',
                'phone',
                'postal_town',
                'postal_code',
                'country',
            ].includes(name)
        ) {
            validateForm(name, value)
        }

        setState((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleSelectAdmin1Code = (answer: string) => {
        setState((prev) => ({
            ...prev,
            administrative_area_level_1: answer,
        }))
    }

    const isFormValid = () => {
        let check = state.error
            ? Object.values(state.error).some((val) => val !== undefined)
            : undefined
        if (check) {
            return false
        } else return true
    }

    const onSubmit = () => {
        let addressObj: IUserAddressApi = {
            type: 'User Address',
            street_1: state.street_number,
            street_2: state.route,
            locality: state.postal_town,
            postcode: state.postal_code,
            administrative_area:
                state.country === 'United States'
                    ? state.administrative_area_level_1
                    : state.country === 'Spain'
                    ? state.administrative_area_level_2
                    : undefined,
            country: state.country,
        }

        let check = true

        if (
            state.street_number.length === 0 ||
            state.route.length === 0 ||
            state.country.length === 0 ||
            state.postal_code.length === 0 ||
            state.postal_town.length === 0
        ) {
            check = false
        }

        if (check === false) {
            let arr = [
                'street_number',
                'route',
                'postal_town',
                'postal_code',
                'country',
            ]
            arr.forEach((fieldName: string) =>
                validateForm(
                    fieldName,
                    state[fieldName as keyof typeof state] as string
                )
            )
        }

        if (check) {
            addAddress(addressObj)
            return toggleAddresForm()
        }
    }

    return (
        <React.Fragment>
            <AccountAddressFormMobile
                street_number={state.street_number}
                postal_town={state.postal_town}
                route={state.route}
                postal_code={state.postal_code}
                country={state.country}
                onSubmit={onSubmit}
                handleChange={handleChange}
                isFormValid={isFormValid}
                toggle={toggleAddresForm}
                countriesdropdownlist={countriesdropdownlist}
                handleSelectCountry={handleSelectCountry}
                administrative_area_level_1={state.administrative_area_level_1}
                countryUpdatedFromSearchedAddress={
                    state.countryChangedFromSearch ? state.country : null
                }
                handleSelectAdmin1Code={handleSelectAdmin1Code}
                usaAdminCodes={usaAdminCodes}
                error={state.error}
            />
        </React.Fragment>
    )
}

export default AccountAddressFormManagerMobile
