import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'

import {
    I_getUserArchives_api_req,
    I_getUserArchives_api_res,
} from '../../services/archive/getUserArchives'
import {
    convertToCarState,
    convertToGalleryImagesState,
    convertToHighlightedFactsState,
    convertToTechnicalInformationState,
} from '../../conversions/entities/conversionFromAPI'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
import { galleriesActions } from '../../entities/galleries/reducer'
import { highlightedFactsActions } from '../../entities/highlighted_facts/reducer'
import { technicalInformationActions } from '../../entities/technical_information/reducer'
import { carActions } from '../../entities/cars/reducer'
import { usersActions } from '../reducer'
import { ICarPayload } from '../../services/typedefinitions/apiPayloads'
import {
    IHighlightedFactsObject,
    ITechnicalInformationObject,
} from 'entityModels'
import { ICarsObject, IReduxError } from '../../entities/cars/types'
import { IGalleryImagesObject } from '../../entities/galleries/types'

function* SetCarExtraneousReduxData(p: ICarPayload) {
    let carObject: ICarsObject = {}

    let galleryImagesObject: IGalleryImagesObject = {}

    let highlightedFactsObject: IHighlightedFactsObject = {}

    let technicalInformationObject: ITechnicalInformationObject = {}

    // convert to car state payload

    let car: ICarsObject = convertToCarState(p)

    car[p.uid].has_limited_garage_info = false

    if (p.gallery) {
        let gallery_images_object: IGalleryImagesObject =
            convertToGalleryImagesState(p)
        Object.assign(galleryImagesObject, gallery_images_object)
    }

    let highlighted_facts_object: IHighlightedFactsObject =
        convertToHighlightedFactsState(p)

    let technical_information_object: ITechnicalInformationObject =
        convertToTechnicalInformationState(p)

    Object.assign(carObject, car)

    Object.assign(highlightedFactsObject, highlighted_facts_object)

    Object.assign(technicalInformationObject, technical_information_object)

    return {
        car: carObject,
        gallery: galleryImagesObject,
        keyFacts: highlighted_facts_object,
        techInfo: technical_information_object,
    }
}

export function* getUserArchivedCarsSaga(
    p?:
        | 'ARCHIVED_ON_OLDEST'
        | 'ARCHIVED_ON_MOST_RECENT'
        | 'MANUFACTURE_DATE_ASCENDING'
        | 'MANUFACTURE_DATE_DESCENDING'
): any {
    try {
        let payload: I_getUserArchives_api_req = {
            offset: '0',
            limit: '100',
            sorting: p ? p : 'ARCHIVED_ON_MOST_RECENT',
        }

        let res: I_getUserArchives_api_res = yield call(
            api.archive.getUserArchives,
            payload
        )

        let car_ids: string[] = []
        if (res.cars) {
            let carObjects: ICarsObject = {}
            let galleryObjects: IGalleryImagesObject = {}
            let keyFactsObjects: IHighlightedFactsObject = {}
            let techInfoObjects: ITechnicalInformationObject = {}

            for (let i = 0; i < res.cars.length; i++) {
                car_ids = [...car_ids, res.cars[i].uid]

                let results = yield call(SetCarExtraneousReduxData, res.cars[i])

                carObjects = { ...carObjects, ...results.car }
                galleryObjects = { ...galleryObjects, ...results.gallery }
                keyFactsObjects = { ...keyFactsObjects, ...results.keyFacts }
                techInfoObjects = { ...techInfoObjects, ...results.techInfo }
            }
            yield put(galleriesActions.setGalleryImagesSuccess(galleryObjects))
            yield put(
                highlightedFactsActions.setHighlightedFactsSuccess(
                    keyFactsObjects
                )
            )
            yield put(
                technicalInformationActions.setTechnicalInformationSuccess(
                    techInfoObjects
                )
            )
            yield put(carActions.getGarageCarsDataSuccess(carObjects))
        }

        yield put(
            usersActions.setUserArchivedCarsSuccess({
                car_ids: car_ids,
                activeSort: p ?? 'ARCHIVED_ON_MOST_RECENT',
            })
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getUserArchivedCarsSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(usersActions.getUserArchivedCarsError(typedError))
        }
    }
}

function* watcherGetUserArchives() {
    while (true) {
        const { payload } = yield take(usersActions.getUserArchivedCarsRequest)
        yield call(getUserArchivedCarsSaga, payload)
    }
}

const get_user_archives: any[] = [fork(watcherGetUserArchives)]

export default get_user_archives
