import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from './../../reducer'

import { gen_draft_differing_values_to_patch } from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { api } from '../../../services'
import {
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import posthog from 'posthog-js'
import { ValidateAndAddStatsToApplication } from './get'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'

export function* Submit_application_details(): any {
    const pathname = window.location.pathname
    const search = window.location.search
    let referral_code_error_redirect = `${pathname}${search}`
    try {
        posthog.capture('Update insurance application general details')
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft = { ...all_draft.insurance_details }
        let existing = { ...all_existing.insurance_details }

        let dataToSendToPatch: IAnyObject | undefined =
            gen_draft_differing_values_to_patch({
                existing: existing,
                draft: draft,
            })

        let referral_code: string | undefined = undefined

        // keep referral code separate
        if (
            dataToSendToPatch &&
            dataToSendToPatch[`application_referral_code`]
        ) {
            referral_code = dataToSendToPatch[`application_referral_code`]
            delete dataToSendToPatch[`application_referral_code`]
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            // call api to patch as thats all the data merged we want to patch
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...dataToSendToPatch },
                }
            )

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )

            yield put(
                insuranceActions.submit_insuranceDetailsApplication_success(
                    applicationReducerDataWithStats
                )
            )

            if (referral_code !== undefined && all_existing.id) {
                let referral_p = {
                    code: referral_code,
                    appli_id: all_existing.id,
                    errorRedirect: referral_code_error_redirect,
                }
                yield put(
                    insuranceActions.submit_insuranceApplicationReferralCode_request(
                        referral_p
                    )
                )
            }
        } else {
            yield put(
                insuranceActions.submit_insuranceDetailsApplication_success(
                    undefined
                )
            )
            if (referral_code !== undefined && all_existing.id) {
                let referral_p = {
                    code: referral_code,
                    appli_id: all_existing.id,
                    errorRedirect: referral_code_error_redirect,
                }
                yield put(
                    insuranceActions.submit_insuranceApplicationReferralCode_request(
                        referral_p
                    )
                )
            }
        }
        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_application_details,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )

            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_insuranceDetailsApplication_error(
                    customError
                )
            )
        }
    }
}

export function* Submit_application_referral_code({
    code,
    appli_id,
    errorRedirect,
}: {
    code: string
    appli_id: string
    errorRedirect: string
}): any {
    try {
        posthog.capture('Add insurance application referral')

        yield call(api.insuranceApplication.patchInsuranceApplication, {
            id: appli_id,
            data: { application_referral_code: code },
        })
        yield put(
            insuranceActions.submit_insuranceApplicationReferralCode_success(
                code
            )
        )
        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_application_referral_code,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${appli_id}`}`,
            }

            if (
                error.status === 400 &&
                error.text &&
                typeof error.text === 'string' &&
                error.text.includes('referral code')
            ) {
                customErrorData.custom_message = `Whoops, referral code not found or invalid`
                customErrorData.custom_message_detail =
                    'Please add the correct referral code or leave the field blank.'
                customErrorData.custom_redirect_path = errorRedirect
            }

            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(insuranceActions.reset_draft_insurance_application())
            yield put(
                insuranceActions.submit_insuranceApplicationReferralCode_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Submit_application_details() {
    while (true) {
        yield take(insuranceActions.submit_insuranceDetailsApplication_request)
        yield call(Submit_application_details)
    }
}

function* Watcher_Submit_application_referral_code() {
    while (true) {
        const { payload } = yield take(
            insuranceActions.submit_insuranceApplicationReferralCode_request
        )
        yield call(Submit_application_referral_code, payload)
    }
}

const application_details_submit: any[] = [
    fork(Watcher_Submit_application_details),
    fork(Watcher_Submit_application_referral_code),
]

export default application_details_submit
