import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import BarChart, { IChartDoughnut } from './barChart'
import colours, {
    history_file_colours,
    ITheme,
} from '../../../../providers/theme/colours'
import { ITimeRangeEnum } from '../../menu/carInsights/timeMenu'
import {
    getMonthsBetweenDates,
    getMonthsUntilToday,
    getYearsBetweenDates,
    monthNames,
} from '../../../../helpers/time/getcarInsightsBarLabels'
import dayjs from 'dayjs'
import { useAppSelector } from '../../../../redux/store/hooks'
import { useEffect, useState } from 'react'
import Faded from '../../../templates/animated/faded'
import useThemes from '../../../../providers/theme/hooks'
import {
    ICarsObject,
    ICar,
    ICarInsights,
    ICarInsightsResAPI_category_item,
} from '../../../../redux/entities/cars/types'

const Wrapper = styled.div<{ $theme: ITheme }>`
    position: relative;
    width: 90vw;
    max-width: 900px;
    overflow-x: scroll;

    ::-webkit-scrollbar {
        background: ${(props) =>
            colours[props.$theme].background_neutral_subtle};
    }

    ::-webkit-scrollbar-track {
        background: ${(props) =>
            colours[props.$theme].background_neutral_subtle};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${(props) => colours[props.$theme].border_muted};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => colours[props.$theme].border_muted};
    }
`

const WrapperSub = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    @media ${device.mobile_and_ipad} {
        padding: 20px;
        width: 500px;
        padding-right: 50px;
        overflow-x: scroll;
    }
`

Chart.register(CategoryScale)

type Props = {
    categoryID?: string
}

const CarInsightsBarChart = (props: Props) => {
    const { theme } = useThemes()
    let { categoryID } = props
    let currURL = new URL(window.location.href)
    let params2 = new URLSearchParams(currURL.search)

    const carsData: ICarsObject = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    const carID = useAppSelector((state) => {
        return state.entities.carsData.currentCarID
    })

    let current: ICar | undefined = carID ? carsData[carID] : undefined
    let carInsights: ICarInsights | undefined = current?.insights

    let timeUnit: 'monthly' | 'yearly' =
        params2.get('representation') === 'yearly' ? 'yearly' : 'monthly'

    let selectedID: ITimeRangeEnum =
        params2.get('time_range') === 'year'
            ? 'year'
            : params2.get('time_range') === 'last_year'
            ? 'last_year'
            : params2.get('time_range') === 'all_time'
            ? 'all_time'
            : params2.get('time_range') === 'custom'
            ? 'custom'
            : 'year'

    let categoryData: ICarInsightsResAPI_category_item[] | undefined =
        categoryID &&
        carInsights &&
        timeUnit &&
        carInsights.categories &&
        carInsights.categories[
            categoryID as keyof typeof carInsights.categories
        ] &&
        carInsights.categories[
            categoryID as keyof typeof carInsights.categories
        ][selectedID] &&
        carInsights.categories[
            categoryID as keyof typeof carInsights.categories
        ][selectedID]![timeUnit]
            ? carInsights.categories[
                  categoryID as keyof typeof carInsights.categories
              ][selectedID]![timeUnit]
            : undefined

    let custom_start_date: string | undefined | null = params2.get('start_date')
        ? dayjs(decodeURIComponent(params2.get('start_date')!)).format(
              'DD-MM-YYYY'
          )
        : undefined

    let custom_end_date: string | undefined | null = params2.get('end_date')
        ? dayjs(decodeURIComponent(params2.get('end_date')!)).format(
              'DD-MM-YYYY'
          )
        : undefined

    const [data, setData] = useState<IChartDoughnut | undefined>(undefined)

    const getData = (): IChartDoughnut => {
        let getAllTimeLabels = (): string[] => {
            let newest = dayjs().format('DD-MM-YYYY')

            let oldestMonthName =
                categoryData && categoryData[0] && categoryData[0].month

            const monthIndex = !oldestMonthName
                ? -1
                : monthNames.indexOf(oldestMonthName)

            let oldestYear =
                categoryData && categoryData[0] && categoryData[0]?.year

            if (timeUnit === 'yearly') {
                if (oldestYear) {
                    let yearsArr = getYearsBetweenDates(
                        dayjs(new Date(oldestYear, 1, 1)).format('DD-MM-YYYY'),
                        newest
                    )

                    return yearsArr
                }
            } else {
                if (monthIndex !== -1 && oldestYear) {
                    let monthsArr = getMonthsBetweenDates(
                        dayjs(new Date(oldestYear, monthIndex, 1)).format(
                            'DD-MM-YYYY'
                        ),
                        newest,
                        true
                    )

                    return monthsArr
                }
            }

            return []
        }

        let timeLabels: string[] =
            selectedID === 'custom' && (!custom_start_date || !custom_end_date)
                ? []
                : // timeunit = monthly
                timeUnit === 'monthly' && selectedID === 'year'
                ? getMonthsUntilToday()
                : timeUnit === 'monthly' && selectedID === 'last_year'
                ? monthNames
                : timeUnit === 'monthly' &&
                  selectedID === 'custom' &&
                  (!custom_start_date || !custom_end_date)
                ? []
                : timeUnit === 'monthly' &&
                  selectedID === 'custom' &&
                  custom_start_date &&
                  custom_end_date
                ? getMonthsBetweenDates(
                      custom_start_date,
                      custom_end_date,
                      selectedID === 'custom'
                  )
                : // timeunit = monthly
                timeUnit === 'yearly' && selectedID === 'year'
                ? ['2024']
                : timeUnit === 'yearly' && selectedID === 'last_year'
                ? ['2023']
                : timeUnit === 'yearly' &&
                  selectedID === 'custom' &&
                  custom_start_date &&
                  custom_end_date
                ? getYearsBetweenDates(custom_start_date, custom_end_date)
                : selectedID === 'all_time'
                ? getAllTimeLabels()
                : []

        let getCostsArr = (): number[] => {
            let objjj: { [key: string]: ICarInsightsResAPI_category_item } = {}

            if (categoryData) {
                for (let i = 0; i < categoryData.length; i++) {
                    if (
                        timeUnit === 'yearly' &&
                        categoryData[i] &&
                        categoryData[i]?.year
                    ) {
                        objjj = {
                            ...objjj,
                            [`${categoryData[i].year}`]: {
                                ...categoryData[i],
                            },
                        }
                    } else if (categoryData[i].month) {
                        objjj = {
                            ...objjj,
                            [`${categoryData[i].month}`]: {
                                ...categoryData[i],
                            },
                        }
                    }
                }

                if (timeLabels) {
                    return timeLabels.map((timeID, index) => {
                        if (timeUnit === 'yearly') {
                            return `${objjj[timeID]?.year}` === timeID &&
                                objjj[timeID]?.amount
                                ? +objjj![timeID].amount! / 100
                                : 0
                        } else {
                            if (
                                selectedID === 'custom' ||
                                selectedID === 'all_time'
                            ) {
                                const words: string[] = timeID.split(' ') // split on single space characters

                                let propertyName: string = `${
                                    objjj[words[0]]?.month
                                } ${objjj[words[0]]?.year}`

                                return propertyName === timeID &&
                                    objjj[words[0]]?.amount
                                    ? +objjj![words[0]]!.amount! / 100
                                    : 0
                            } else {
                                return objjj[timeID]?.month === timeID &&
                                    objjj[timeID]?.amount
                                    ? +objjj![timeID]!.amount! / 100
                                    : 0
                            }
                        }
                    })
                }
            }
            return []
        }

        let costs = getCostsArr()

        return {
            // labels are time related
            // if yearly: months labels
            labels: timeLabels.map((item) => `${item}`),
            datasets: [
                {
                    label: '£',
                    data: costs,
                    fonts: ['Lato'],
                    backgroundColor: [
                        props.categoryID === 'all'
                            ? colours[theme].primary
                            : props.categoryID &&
                              // @ts-ignore
                              history_file_colours[theme].categories[
                                  props.categoryID
                              ]
                            ? // @ts-ignore
                              history_file_colours[theme].categories[
                                  props.categoryID
                              ].primary_100
                            : colours[theme].border_muted,
                    ],
                    borderWidth: 0,
                    borderRadius: 4,
                    hoverBackgroundColor: [
                        props.categoryID === 'all'
                            ? colours[theme].primary_strong
                            : props.categoryID &&
                              // @ts-ignore
                              history_file_colours[theme].categories[
                                  props.categoryID
                              ]
                            ? // @ts-ignore
                              history_file_colours[theme].categories[
                                  props.categoryID
                              ].strong
                            : colours[theme].border_muted,
                    ],
                },
            ],
        }
    }

    useEffect(() => {
        if (categoryData) {
            setData(getData())
        }
    }, [categoryData, timeUnit])

    return (
        <Wrapper $theme={theme}>
            <WrapperSub
                style={{
                    background:
                        props.categoryID === 'all'
                            ? colours[theme].primary_08
                            : props.categoryID &&
                              // @ts-ignore
                              history_file_colours[theme].categories[
                                  props.categoryID
                              ]
                            ? // @ts-ignore
                              history_file_colours[theme].categories[
                                  props.categoryID
                              ].bg_gradient
                            : colours[theme].primary_08,

                    borderRadius: '5px',
                }}
            >
                <Faded>
                    {data && (
                        <BarChart
                            currency={
                                carInsights?.[selectedID]?.stats?.currency ===
                                'GBP'
                                    ? '£'
                                    : carInsights?.[selectedID]?.stats
                                          ?.currency === 'USD'
                                    ? '$'
                                    : carInsights?.[selectedID]?.stats
                                          ?.currency === 'EUR'
                                    ? '€'
                                    : '£'
                            }
                            chartData={data}
                        />
                    )}
                </Faded>
            </WrapperSub>
        </Wrapper>
    )
}

export default CarInsightsBarChart
