import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import checked_green from '../../../../public/assets/icons/checked_green.svg'
import checked_light_grey from '../../../../public/assets/icons/checked_light_grey.svg'
import Ellipsis from '../../loader/ellipsis'
import colours from '../../../../providers/theme/colours'
import ChevronLeft from '../../icons/components/chevronLeft'
import BinIcon from '../../icons/components/binIcon'
import useThemes from '../../../../providers/theme/hooks'

type IStyledProps = {
    isActive?: boolean
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 9px -2px var(--box-shadow, rgba(0, 0, 0, 0.08));
    top: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    z-index: 10;
    background-color: var(--bg-color, #fff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    position: fixed;

    @media ${device.desktop} {
        display: none;
    }
`

const NavCaption = styled.div<IStyledProps>`
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
`

const HeaderText = styled.div<IStyledProps>`
    color: ${(props) =>
        props.isActive
            ? 'var(--error, #df6f6f)'
            : 'var(--text-strong, #1a1a1a)'};
    font-family: Lato-semibold;
    justify-content: center;
    color: var(--text-strong, #1a1a1a);
    font-size: 18px;
    padding-left: 12px;

    @media ${device.desktop} {
        display: none;
    }
`

const CheckedContainer = styled.button`
    margin-right: 16px;
    border: none;
    background-color: transparent;
    width: 32px;
    height: 32px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Checked = styled.img`
    width: 22px;
    /* height: 26px; */
    margin-right: 10px;

    @media ${device.desktop} {
        display: none;
    }
`

interface Props {
    url?: string
    onChevronClick?: any
    isActive: boolean
    onCheckedClick?: any
    editedItemId?: string
    onDeleteClick?: any
    isSaveDisabled?: boolean
    isDataLoading?: boolean
    deletionInProgress?: boolean
}

const EditModeTopHeaderMobile = (props: Props) => {
    const { theme } = useThemes()
    return (
        <Wrapper>
            {/* left side of nav */}
            <NavCaption onClick={props.onChevronClick}>
                <ChevronLeft />
                {props.isActive ? (
                    <HeaderText
                        isActive={props.isActive}
                        data-attr="cancel-button"
                    >
                        {props.isActive ? 'Cancel' : 'Return'}
                    </HeaderText>
                ) : (
                    <HeaderText>
                        {/* {props.editedItemId === 'cost'
                            ? 'Edit Cost'
                            : props.editedItemId === 'notes'
                            ? 'Edit Notes'
                            : 'Edit Entry'} */}
                        {props.editedItemId === 'general'
                            ? 'Edit entry'
                            : props.editedItemId === 'insurance_fields'
                            ? 'Edit Insurer'
                            : `Edit ${props.editedItemId}`}
                    </HeaderText>
                )}
            </NavCaption>
            {/* right side of nav */}
            {props.isDataLoading || props.deletionInProgress ? (
                <CheckedContainer
                    onClick={() => {}}
                    style={{ paddingRight: 32 }}
                >
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            boxSizing: 'border-box',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingBottom: 8,
                            paddingRight: 24,
                        }}
                    >
                        <Ellipsis
                            size={10}
                            colour={
                                props.deletionInProgress
                                    ? colours[theme].error_50
                                    : undefined
                            }
                        />
                    </div>
                </CheckedContainer>
            ) : props.isActive ? (
                <CheckedContainer
                    onClick={props.onCheckedClick}
                    data-attr="save-button-icon"
                    disabled={props.isSaveDisabled}
                >
                    <Checked
                        src={
                            props.isSaveDisabled
                                ? checked_light_grey
                                : checked_green
                        }
                    />
                </CheckedContainer>
            ) : props.editedItemId === 'cost' ? (
                <CheckedContainer onClick={props.onDeleteClick}>
                    <BinIcon color="#DF6F6F" />
                </CheckedContainer>
            ) : null}
        </Wrapper>
    )
}

export default EditModeTopHeaderMobile
