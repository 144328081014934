import React, { useState, useCallback } from 'react'
import Fuse from 'fuse.js'
import MultipleSelectMobile from './multipleSelectMobile'

export type Props = {
    items: any
    item: any
    width?: string
    handleSelectChange?: any
    isDisabled?: boolean
}

const SelectMultipleSearchBarCarInfoMobile: React.FC<Props> = ({
    items,
    item,
    width,
    handleSelectChange,
    isDisabled,
}) => {
    const [data, setData] = useState<any[]>([])
    const [name, setName] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(false)
    const [lastFetchId, setLastFetchId] = useState<number>(0)

    const fuse = new Fuse(items, {
        keys: ['name'],
    })

    const fetchItem = useCallback(
        (name: any) => {
            const fetchId = lastFetchId + 1
            setLastFetchId(fetchId)
            setData([])
            setFetching(true)

            if (fetchId !== lastFetchId + 1) {
                return
            }

            const results = fuse.search(name)
            setData(results)
            setFetching(false)
        },
        [fuse, lastFetchId]
    )

    const handleChange = (name: any) => {
        setName((prevName) => [...prevName, name])
        setData([])
        setFetching(false)
        handleSelectChange && handleSelectChange(name)
    }

    return (
        <MultipleSelectMobile
            fetching={fetching}
            value={name}
            data={data}
            fetchItem={fetchItem}
            handleChange={handleChange}
            optionsList={items}
            item={item}
            width={width}
            isDisabled={isDisabled}
        />
    )
}

export default SelectMultipleSearchBarCarInfoMobile
