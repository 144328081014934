import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IInsuranceFieldType } from '../localdata/insuranceLocalData/fieldTypes'
import { ICreateInsuranceApplicationSagaArg } from './sagas/application/create'
import { DeleteUserInsuranceApplicationProps } from './sagas/application/delete'
import {
    IGetAllApplicationsSuccess,
    IGetAllUserInsuranceApplicationsProps,
} from './sagas/application/getAll'
import { IIsCarEverydayArg } from './sagas/car/everyday_bool_submit'
import { IInsuranceGetExternalCarDataByRegNumber } from './sagas/car/registration_number_external_vehicle'
import { IDriverBasicDetailsSumitPayload } from './sagas/otherdriver/otherdriver_basicdetails_submit'
import { IOtherDriverID } from './sagas/otherdriver/otherdriver_trackrecord_submit'
import { IInsuranceAddress_API } from './types'
import { IDropdownItem } from 'entityModels'
import { IReduxError } from '../entities/cars/types'
import { IUser } from '../user/types'

export type IAnyObject = {
    [key: string]: any
}

export type IAnyErrorString = {
    [key: string]: string
}

export type IInsuranceQuoteApplication_Car = {
    id?: string
    api_uid?: string
    tech_info?: IAnyObject
    tech_info_errors?: IAnyObject
    tech_info_stats?: number
    ownership?: IAnyObject
    ownership_errors?: IAnyObject
    ownership_stats?: number
    mileage_parking?: IAnyObject
    mileage_parking_errors?: IAnyObject
    mileage_parking_stats?: number
    completion?: number

    daytime_parking_info: IAnyObject // `IParking`Info_API
    daytime_parking_info_errors?: IAnyObject
    eveningtime_parking_info: IAnyObject // IParkingInfo_API
    eveningtime_parking_info_errors?: IAnyObject

    is_everyday?: boolean

    is_external?: boolean

    entities: {
        modifications: IAnyObject[]
    }
    entities_error?: {
        modifications?: IAnyObject
    }
}

export type IInsuranceQuoteApplication_VehiclesObj = {
    [key: string]: IInsuranceQuoteApplication_Car
}

export type IOtherDriver_DriveList = {
    carid: string
    use_frequency?: IDropdownItem
    use_frequency_error?: IAnyObject
}

export type IInsuranceQuoteApplication_OtherDriver = {
    id?: string
    basic_details?: IAnyObject
    basic_details_errors?: IAnyObject
    basic_details_stats?: number
    licence_address?: IAnyObject
    licence_address_errors?: IAnyObject
    licence_address_stats?: number
    additional_details?: IAnyObject
    additional_details_errors?: IAnyObject
    additional_details_stats?: number
    track_record?: IAnyObject
    track_record_errors?: IAnyObject
    track_record_stats?: number
    drive_list?: IOtherDriver_DriveList[]
    completion?: number

    drive_list_errors?: IAnyObject | null

    // self replica
    entities: {
        memberships: IAnyObject[]
        claims: IAnyObject[]
        disabilities: IAnyObject[]
        convictions: IAnyObject[]
        motoring_convictions: IAnyObject[]
    }
    entities_error?: {
        disabilities?: IAnyObject
        claims?: IAnyObject
        memberships?: IAnyObject
        convictions?: IAnyObject
        motoring_convictions?: IAnyObject
    }
}

export type IAddressInsuranceNormalised = {
    [key: string]: IInsuranceAddress_API
}

export type IEntitiesObjArr = {
    [key: string]: IAnyObject[]
}

export type IInsuranceQuoteApplication_MainDriver = {
    id?: string
    completion?: number
    basic_details?: IAnyObject
    basic_details_errors?: IAnyObject
    basic_details_stats?: number
    licence_address?: IAnyObject
    licence_address_errors?: IAnyObject
    licence_address_stats?: number
    additional_details?: IAnyObject
    additional_details_errors?: IAnyObject
    additional_details_stats?: number
    track_record?: IAnyObject
    track_record_errors?: IAnyObject
    track_record_stats?: number

    // self replica
    entities: {
        memberships: IAnyObject[]
        claims: IAnyObject[]
        disabilities: IAnyObject[]
        convictions: IAnyObject[]
        motoring_convictions: IAnyObject[]
    }
    entities_error?: {
        disabilities?: IAnyObject
        claims?: IAnyObject
        memberships?: IAnyObject
        convictions?: IAnyObject
        motoring_convictions?: IAnyObject
    }
}

export type IInsuranceQuoteApplication_OtherDriverObj = {
    [key: string]: IInsuranceQuoteApplication_OtherDriver
}

export type IInsuranceQuoteApplication_MainDriverObj = {
    [key: string]: IInsuranceQuoteApplication_MainDriver
}

export type InsuranceSectionsWithDropdownsList_IDS =
    | 'drivers_trackRecord'
    | 'drivers_basicDetails'
    | 'technical_information'
    | 'drivers_additionalDetails'
    | 'titles'

export type IInsuranceQuoteApplication = {
    id?: string
    overall_completion?: number
    // INSURANCE DETAILS
    addresses: IAddressInsuranceNormalised
    insurance_details: IAnyObject
    insurance_details_errors: IAnyObject
    insurance_details_stats: number
    vehicles_to_insure_ids_list: string[]
    everyday_car_ids?: string[] | undefined
    everyday_car_stats?: number
    external_car_ids?: string[] | undefined
    selected_named_drivers?: string[] | undefined
    vehicles: IInsuranceQuoteApplication_VehiclesObj
    vehicles_overall_stats?: number
    other_drivers: IInsuranceQuoteApplication_OtherDriverObj
    other_drivers_overall_stats?: number
    main_driver: IInsuranceQuoteApplication_MainDriver
    main_driver_overall_stats?: number
    stats?: number
    status?: IInsuranceStatusEnum
}
export type IInsuranceStatusEnum =
    | 'DRAFT'
    | 'REQUESTED'
    | 'ENRICHMENT'
    | 'QUOTING'
    | 'BROKING'
    | 'COMPLETE'
    | 'ARCHIVED'

export type IInsuranceQuoteApplicationValidation = {
    id?: string
    insurance_details_errors?: IAnyObject
    application_custodian_cars?: IAnyObject | IAnyObject[] | null
    application_everyday_vehicles?: IAnyObject | null
    vehicles?: IInsuranceQuoteApplication_VehiclesObj
    other_drivers?: IInsuranceQuoteApplication_OtherDriverObj
    main_driver?: IInsuranceQuoteApplication_MainDriver
}

export type IInsuranceHighLevelCarInfo = {
    id: string
    title: string
    drivers?: string[]
}

export type IApplicationInfo = {
    uid: string
    stat: number
    carsInfoArr: IInsuranceHighLevelCarInfo[]
    status?: IInsuranceStatusEnum
    offer?: number
    start_date?: string
    end_date?: string
    agreed_value?: number
    car_breakdown?: boolean
}

export type IApplicationInfoObj = {
    [key: string]: IApplicationInfo
}

export type IInsuranceQuoteApplication_Reducer = {
    applications_draft_ids: string[] | undefined
    applications_opened_ids: string[] | undefined
    applications_complete_ids: string[] | undefined
    applications_info: undefined | IApplicationInfoObj
    draft: IInsuranceQuoteApplication
    submitted_data: IInsuranceQuoteApplication
    isGetLoading: boolean
    isLoading: boolean
    isAllApplicationsInfoLoading?: boolean
    has_sections_dropdowns: InsuranceSectionsWithDropdownsList_IDS[]
    error: IReduxError | null
    showValidationSheet: boolean
    temp_field_search_copy?: IInsuranceFieldType
    recentDeletedAppliID: string | undefined
}

const initialApplication: IInsuranceQuoteApplication = {
    addresses: {},
    insurance_details: {},
    insurance_details_errors: {},
    insurance_details_stats: 0,
    other_drivers: {},
    main_driver: {
        entities: {
            memberships: [],
            claims: [],
            disabilities: [],
            convictions: [],
            motoring_convictions: [],
        },
    },
    vehicles_to_insure_ids_list: [],
    vehicles: {},
}

// on submit, submitted_data = draft
// on cancel, draft = submitted_data

const initialState: IInsuranceQuoteApplication_Reducer = {
    applications_draft_ids: undefined,
    applications_opened_ids: undefined,
    applications_complete_ids: undefined,
    applications_info: undefined,
    draft: initialApplication,
    submitted_data: initialApplication,
    isLoading: false,
    isGetLoading: false,
    has_sections_dropdowns: [],
    showValidationSheet: false,
    error: null,
    recentDeletedAppliID: undefined,
}

type IIQA_answer_payload = {
    id: string
    answer: any
}

type IIQA_Car_answer_payload = {
    carid: string
    id: string
    answer: any
}
type IIQA_Car_answer_parking_info_payload = {
    carid: string
    id: string
    answer: any
    type: 'evening' | 'day'
}

type IIQA_Car_reset_parking_info_payload = {
    carid: string
    type: 'evening' | 'day'
}

type IIQA_Driver_answer_payload = {
    userid: string
    id: string
    answer: any
}

type IIQA_Driver_drivelist_answer_payload = {
    userid: string
    id: string
    answer: IOtherDriver_DriveList[] | undefined
}

type IIQA_Car_multiple_answers_payload = {
    carid: string
    data: IAnyObject
}

type IIQA_error_payload = {
    id: string
    error?: undefined | string
}

type IIQA_Car_error_payload = {
    carid: string
    id: string
    error: any
}

type IIQA_Driver_error_payload = {
    userid: string
    id: string
    error: any
}

export const insuranceQuoteApplicationSlice = createSlice({
    name: 'insurance',
    initialState: initialState,
    reducers: {
        set_temp_field_search_copy: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceFieldType | undefined>
        ) => {
            state.temp_field_search_copy = action.payload
        },

        get_user_insurance_applications_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IGetAllUserInsuranceApplicationsProps>
        ) => {
            state.isAllApplicationsInfoLoading = true
        },

        getting_insurance_info_after_change: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = true
        },

        get_user_insurance_applications_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IGetAllApplicationsSuccess>
        ) => {
            state.applications_draft_ids = action.payload.applications_draft_ids

            state.applications_opened_ids =
                action.payload.applications_opened_ids

            state.applications_complete_ids =
                action.payload.applications_complete_ids

            state.isAllApplicationsInfoLoading = false
        },

        get_insurance_applications_info_request: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isAllApplicationsInfoLoading = true
        },

        get_insurance_applications_info_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IApplicationInfoObj>
        ) => {
            state.isAllApplicationsInfoLoading = false
            state.applications_info = action.payload
        },

        get_user_insurance_applications_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isAllApplicationsInfoLoading = false
            state.error = action.payload
        },

        delete_insurance_application_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<DeleteUserInsuranceApplicationProps>
        ) => {
            state.isLoading = true
        },

        delete_insurance_application_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string>
        ) => {
            state.isLoading = false
            state.draft = initialApplication
            state.submitted_data = initialApplication

            state.applications_draft_ids = state.applications_draft_ids
                ? state.applications_draft_ids.filter(
                      (id) => id !== action.payload
                  )
                : []

            if (state.applications_info) {
                delete state.applications_info[action.payload]
            }
        },

        delete_insurance_application_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        create_external_vehicle_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IAnyObject>
        ) => {
            state.isLoading = true
        },

        create_external_vehicle_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            }
        },

        create_external_vehicle_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        get_external_vehicle_data_by_reg_number_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceGetExternalCarDataByRegNumber>
        ) => {
            state.isLoading = true
        },

        get_external_vehicle_data_by_reg_number_success: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            // assuming its GETTING insurance application on success
            state.isLoading = false
        },

        get_external_vehicle_data_by_reg_number_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        create_insurance_application_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<ICreateInsuranceApplicationSagaArg>
        ) => {
            state.isLoading = true
        },

        create_insurance_application_success: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = false
        },

        create_insurance_application_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        get_insurance_application_request: (
            state: IInsuranceQuoteApplication_Reducer,
            // application_id
            action: PayloadAction<string>
        ) => {
            state.isGetLoading = true
        },

        get_insurance_application_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication>
        ) => {
            state.isGetLoading = false
            state.isLoading = false
            state.draft = {
                ...action.payload,
            }
            state.submitted_data = {
                ...action.payload,
            }
        },

        get_insurance_application_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isGetLoading = false
            state.error = action.payload
        },

        ///

        validate_insurance_application_request: (
            state: IInsuranceQuoteApplication_Reducer,
            // application_id
            action: PayloadAction<{
                application: IInsuranceQuoteApplication
                activateValidationSheet?: boolean
                isWizard?: boolean
            }>
        ) => {
            state.isLoading = true
        },

        validate_insurance_application_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            }
        },

        validate_insurance_application_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        show_Validation_sheet: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<boolean>
        ) => {
            state.showValidationSheet = action.payload
        },

        // letting provider know which section has all their necessary dropdowns fetched.
        // if the id of section is included in, we'll never need to fetch again.
        // if its not, provider knows we need to fetch dropdowns.
        // we could set the ids in the state of provider but as we discussed
        // this would cause rerenders on top of redux. we use redux for all "state" stuff.
        set_has_sections_dropdowns_ids: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<InsuranceSectionsWithDropdownsList_IDS[]>
        ) => {
            state.has_sections_dropdowns = [
                ...state.has_sections_dropdowns,
                ...action.payload,
            ]
        },

        // ==================> submitting drafts to api

        // insuranceDetailsApplication flow
        submit_insuranceDetailsApplication_request: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = true
            state.error = null
        },

        submit_insuranceDetailsApplication_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },

        submit_insuranceDetailsApplication_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // referral code
        submit_insuranceApplicationReferralCode_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<
                | {
                      code: string
                      appli_id: string
                      errorRedirect: string
                  }
                | undefined
            >
        ) => {
            state.isLoading = true
            state.error = null
        },

        submit_insuranceApplicationReferralCode_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...state.draft,
                    insurance_details: {
                        ...state.draft.insurance_details,
                        application_referral_code: action.payload,
                    },
                }
                state.submitted_data = {
                    ...state.submitted_data,
                    insurance_details: {
                        ...state.submitted_data.insurance_details,
                        application_referral_code: action.payload,
                    },
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },

        submit_insuranceApplicationReferralCode_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // insuranceDetailsApplication flow
        submit_application_request: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = true
            state.error = null
        },

        submit_application_success: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = false
            state.error = null
            state.submitted_data.status = 'REQUESTED'
            state.draft.status = 'REQUESTED'
        },

        submit_application_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // insudrance application drivers dashboard
        submit_driversDashboard_request: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = true
        },

        submit_driversDashboard_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            }
        },

        submit_driversDashboard_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // insudrance application drivers drive list
        submit_otherDriver_driveList_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IOtherDriverID>
        ) => {
            state.isLoading = true
        },

        submit_otherDriver_driveList_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            }
        },

        submit_otherDriver_driveList_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        delete_otherDriver_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IOtherDriverID>
        ) => {
            state.isLoading = true
        },

        delete_otherDriver_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IOtherDriverID>
        ) => {
            state.isLoading = false
            delete state.submitted_data.other_drivers[action.payload.driver_id]
        },

        delete_otherDriver_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // vehiclesToInsure flow

        submit_vehiclesToInsure_request: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = true
        },
        submit_vehiclesToInsure_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                    vehicles_to_insure_ids_list: action.payload
                        .vehicles_to_insure_ids_list
                        ? [...action.payload.vehicles_to_insure_ids_list]
                        : [],
                }
                state.submitted_data = {
                    ...action.payload,
                    vehicles_to_insure_ids_list: action.payload
                        .vehicles_to_insure_ids_list
                        ? [...action.payload.vehicles_to_insure_ids_list]
                        : [],
                }
            }
        },
        submit_vehiclesToInsure_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // addresses add

        submit_insuranceAddresses_request: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = true
        },

        submit_user_residential_address_request: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = true
        },

        submit_user_residential_address_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceAddress_API | undefined>
        ) => {
            state.isLoading = false

            if (action.payload) {
                state.submitted_data.main_driver.licence_address = {
                    ...state.submitted_data.main_driver.licence_address,
                    ['residential_address']: action.payload.uid,
                }
                state.draft.main_driver.licence_address = {
                    ...state.draft.main_driver.licence_address,
                    ['residential_address']: action.payload.uid,
                }
                state.submitted_data.addresses = {
                    ...state.submitted_data.addresses,
                    [`${action.payload.uid}`]: {
                        ...action.payload,
                    },
                }

                state.draft.addresses = {
                    ...state.draft.addresses,
                    [`${action.payload.uid}`]: {
                        ...action.payload,
                    },
                }

                const pattern = /^new_address/

                Object.keys(state.draft.addresses).forEach((key) => {
                    if (pattern.test(key)) {
                        delete state.draft.addresses[key]
                    }
                })
            }
        },
        submit_user_residential_address_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        submit_user_address_request: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.isLoading = true
        },

        submit_user_address_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IAddressInsuranceNormalised>
        ) => {
            state.isLoading = false

            if (action.payload) {
                state.submitted_data.addresses = action.payload

                state.draft.addresses = action.payload
            }
        },
        submit_user_address_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        submit_insuranceAddresses_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            }
        },

        submit_insuranceAddresses_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // cars flows

        // carMileageParking flow

        submit_carMileageParking_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{
                carid: string
                isCrossForm?: boolean
                message?: string
            }>
        ) => {
            state.isLoading = true
        },
        submit_carMileageParking_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_carMileageParking_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // carOwnership flow

        submit_carOwnership_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{
                carid: string
                isCrossForm?: boolean
                message?: string
            }>
        ) => {
            state.isLoading = true
        },
        submit_carOwnership_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_carOwnership_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // carTechInfo flow

        submit_carTechInfo_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{
                carid: string
                isCrossForm?: boolean
                message?: string
            }>
        ) => {
            state.isLoading = true
        },
        submit_carTechInfo_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_carTechInfo_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // main driver flows

        // main driver additional details flow

        submit_mainDriver_additionalDetails_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<
                | {
                      isCrossForm?: boolean
                      message?: string
                  }
                | undefined
            >
        ) => {
            state.isLoading = true
        },
        submit_mainDriver_additionalDetails_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_mainDriver_additionalDetails_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // main driver basic details flow

        submit_mainDriver_basicDetails_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<
                | {
                      isCrossForm?: boolean
                      message?: string
                  }
                | undefined
            >
        ) => {
            state.isLoading = true
        },
        submit_mainDriver_basicDetails_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_mainDriver_basicDetails_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // main driver licence addresses flow

        submit_mainDriver_licenceAddresses_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<
                { isCrossForm?: boolean; message?: string } | undefined
            >
        ) => {
            state.isLoading = true
        },
        submit_mainDriver_licenceAddresses_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_mainDriver_licenceAddresses_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // main driver track record flow

        submit_mainDriver_trackRecord_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<
                { isCrossForm?: boolean; message?: string } | undefined
            >
        ) => {
            state.isLoading = true
        },
        submit_mainDriver_trackRecord_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_mainDriver_trackRecord_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
            // for error, TBC is error at reducer level
            // or within that obj within reducer
        },

        // other driver flows

        // other driver additional details flow

        submit_otherDriver_additionalDetails_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{
                driver_id: string
                isCrossForm?: boolean
                message?: string
            }>
        ) => {
            state.isLoading = true
        },
        submit_otherDriver_additionalDetails_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_otherDriver_additionalDetails_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // other driver basic details flow

        submit_otherDriver_basicDetails_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IDriverBasicDetailsSumitPayload>
        ) => {
            state.isLoading = true
        },
        submit_otherDriver_basicDetails_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_otherDriver_basicDetails_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // other driver licence addresses flow

        submit_otherDriver_licenceAddress_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{
                driver_id: string
                isCrossForm?: boolean
                message?: string
            }>
        ) => {
            state.isLoading = true
        },
        submit_otherDriver_licenceAddress_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_otherDriver_licenceAddress_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // other driver track record flow

        submit_otherDriver_trackRecord_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{
                driver_id: string
                isCrossForm?: boolean
                message?: string
            }>
        ) => {
            state.isLoading = true
        },
        submit_otherDriver_trackRecord_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            } else {
                state.draft = {
                    ...state.submitted_data,
                }
            }
        },
        submit_otherDriver_trackRecord_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        // ==================> setting quote granular answers

        set_answer_insuranceDetails: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_answer_payload>
        ) => {
            let isUndefinedAnswer =
                action.payload.answer === '' ||
                action.payload.answer === undefined
                    ? true
                    : false

            state.draft.insurance_details[action.payload.id] = isUndefinedAnswer
                ? undefined
                : action.payload.answer
        },
        set_error_insuranceDetails: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_error_payload>
        ) => {
            state.draft.insurance_details_errors[action.payload.id] =
                action.payload.error
        },
        remove_addresses: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string>
        ) => {
            let newDraftStateAdd = { ...state.draft.addresses }
            delete newDraftStateAdd[action.payload]
            state.draft.addresses = {
                ...newDraftStateAdd,
            }
        },
        set_answer_addresses: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceAddress_API>
        ) => {
            let addressUID = action.payload.uid
                ? action.payload.uid
                : `new_address_${Object.keys(state.draft.addresses).length + 1}`
            state.draft.addresses = {
                ...state.draft.addresses,
                [addressUID]: { uid: addressUID, ...action.payload },
            }
        },
        set_error_addresses: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<any>
        ) => {
            state.draft.addresses = action.payload.error
        },

        set_answer_insurance_car_techInfo: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_answer_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid].tech_info
            ) {
                // @ts-ignore
                state.draft.vehicles[action.payload.carid].tech_info[
                    action.payload.id
                ] = action.payload.answer
            } else if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[action.payload.carid].tech_info = {
                    [action.payload.id]: action.payload.answer,
                }
            } else {
                state.draft.vehicles[action.payload.carid] = {
                    id: action.payload.carid,
                    entities: { modifications: [] },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                    tech_info: {
                        [action.payload.id]: action.payload.answer,
                    },
                }
            }
        },
        set_multiple_answers_insurance_car_techInfo: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_multiple_answers_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid].tech_info
            ) {
                // @ts-ignore
                state.draft.vehicles[action.payload.carid].tech_info = {
                    ...state.draft.vehicles[action.payload.carid].tech_info,
                    ...action.payload.data,
                }
            } else if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[action.payload.carid].tech_info = {
                    ...action.payload.data,
                }
            } else {
                state.draft.vehicles[action.payload.carid] = {
                    id: action.payload.carid,
                    entities: { modifications: [] },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                    tech_info: { ...action.payload.data },
                }
            }
        },

        reset_multiple_errors_insurance_car_techInfo: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_multiple_answers_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid].tech_info_errors
            ) {
                Object.keys(action.payload.data).forEach((key) => {
                    if (
                        state.draft.vehicles[action.payload.carid]
                            .tech_info_errors &&
                        state.draft.vehicles[action.payload.carid]
                            .tech_info_errors![key]
                    ) {
                        state.draft.vehicles[
                            action.payload.carid
                        ].tech_info_errors![key] = undefined
                    }
                })
            }
        },

        set_error_insurance_car_techInfo: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_error_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid].tech_info_errors
            ) {
                // @ts-ignore
                state.draft.vehicles[action.payload.carid].tech_info_errors[
                    action.payload.id
                ] = action.payload.error
            } else if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[action.payload.carid].tech_info_errors = {
                    [action.payload.id]: action.payload.error,
                }
            } else {
                state.draft.vehicles[action.payload.carid] = {
                    id: action.payload.id,
                    entities: { modifications: [] },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                    tech_info_errors: {
                        [action.payload.id]: action.payload.error,
                    },
                }
            }
        },

        set_answer_insurance_car_ownership: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_answer_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid].ownership
            ) {
                // @ts-ignore
                state.draft.vehicles[action.payload.carid].ownership[
                    action.payload.id
                ] = action.payload.answer
            } else if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[action.payload.carid].ownership = {
                    [action.payload.id]: action.payload.answer,
                }
            } else {
                state.draft.vehicles[action.payload.carid] = {
                    id: action.payload.id,
                    entities: { modifications: [] },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                    ownership: {
                        [action.payload.id]: action.payload.answer,
                    },
                }
            }
        },

        set_error_insurance_car_ownership: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_error_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid].ownership_errors
            ) {
                // @ts-ignore
                state.draft.vehicles[action.payload.carid].ownership_errors[
                    action.payload.id
                ] = action.payload.error
            } else if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[action.payload.carid].ownership_errors = {
                    [action.payload.id]: action.payload.error,
                }
            } else {
                state.draft.vehicles[action.payload.carid] = {
                    id: action.payload.id,
                    entities: { modifications: [] },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                    ownership_errors: {
                        [action.payload.id]: action.payload.error,
                    },
                }
            }
        },

        set_answer_insurance_car_mileage_parking: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_answer_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid].mileage_parking
            ) {
                // @ts-ignore
                state.draft.vehicles[action.payload.carid].mileage_parking[
                    action.payload.id
                ] = action.payload.answer
            } else if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[action.payload.carid].mileage_parking = {
                    [action.payload.id]: action.payload.answer,
                }
            } else {
                state.draft.vehicles[action.payload.carid] = {
                    id: action.payload.id,
                    entities: { modifications: [] },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                    mileage_parking: {
                        [action.payload.id]: action.payload.answer,
                    },
                }
            }
        },

        set_answer_insurance_car_mileage_parking_info: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_answer_parking_info_payload>
        ) => {
            if (action.payload.type === 'day') {
                state.draft.vehicles[action.payload.carid] = {
                    ...state.draft.vehicles[action.payload.carid],
                    daytime_parking_info: {
                        ...state.draft.vehicles[action.payload.carid]
                            .daytime_parking_info,
                        [action.payload.id]: action.payload.answer,
                    },
                }
            } else if (action.payload.type === 'evening') {
                state.draft.vehicles[action.payload.carid] = {
                    ...state.draft.vehicles[action.payload.carid],
                    eveningtime_parking_info: {
                        ...state.draft.vehicles[action.payload.carid]
                            .eveningtime_parking_info,
                        [action.payload.id]: action.payload.answer,
                    },
                }
            }
        },

        reset_insurance_car_mileage_parking_info: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_reset_parking_info_payload>
        ) => {
            if (action.payload.type === 'day') {
                state.draft.vehicles[action.payload.carid] = {
                    ...state.draft.vehicles[action.payload.carid],
                    daytime_parking_info: {
                        uid: state.draft.vehicles[action.payload.carid]
                            .daytime_parking_info?.uid,
                    },
                }
            } else if (action.payload.type === 'evening') {
                state.draft.vehicles[action.payload.carid] = {
                    ...state.draft.vehicles[action.payload.carid],
                    eveningtime_parking_info: {
                        uid: state.draft.vehicles[action.payload.carid]
                            .eveningtime_parking_info?.uid,
                    },
                }
            }
        },

        reset_insurance_car_tech_info_engine_size: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{ carid: string }>
        ) => {
            if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[action.payload.carid] = {
                    ...state.draft.vehicles[action.payload.carid],
                    tech_info: {
                        ...state.draft.vehicles[action.payload.carid].tech_info,
                        engine_capacity:
                            state.submitted_data.vehicles[action.payload.carid]
                                .tech_info?.engine_capacity,
                    },
                }
            }
        },

        set_error_insurance_car_mileage_parking: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_error_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid]
                    .mileage_parking_errors
            ) {
                // @ts-ignore
                state.draft.vehicles[
                    action.payload.carid
                ].mileage_parking_errors[action.payload.id] =
                    action.payload.error
            } else if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[
                    action.payload.carid
                ].mileage_parking_errors = {
                    [action.payload.id]: action.payload.error,
                }
            } else {
                state.draft.vehicles[action.payload.carid] = {
                    id: action.payload.id,
                    entities: { modifications: [] },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                    mileage_parking_errors: {
                        [action.payload.id]: action.payload.error,
                    },
                }
            }
        },

        set_error_insurance_car_mileage_parking_day: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_error_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid]
                    .daytime_parking_info_errors
            ) {
                // @ts-ignore
                state.draft.vehicles[
                    action.payload.carid
                ].daytime_parking_info_errors[action.payload.id] =
                    action.payload.error
            } else if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[
                    action.payload.carid
                ].daytime_parking_info_errors = {
                    [action.payload.id]: action.payload.error,
                }
            } else {
                state.draft.vehicles[action.payload.carid] = {
                    id: action.payload.id,
                    entities: { modifications: [] },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                    daytime_parking_info_errors: {
                        [action.payload.id]: action.payload.error,
                    },
                }
            }
        },

        set_error_insurance_car_mileage_parking_evening: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Car_error_payload>
        ) => {
            if (
                state.draft.vehicles[action.payload.carid] &&
                state.draft.vehicles[action.payload.carid]
                    .eveningtime_parking_info_errors
            ) {
                // @ts-ignore
                state.draft.vehicles[
                    action.payload.carid
                ].eveningtime_parking_info_errors[action.payload.id] =
                    action.payload.error
            } else if (state.draft.vehicles[action.payload.carid]) {
                state.draft.vehicles[
                    action.payload.carid
                ].eveningtime_parking_info_errors = {
                    [action.payload.id]: action.payload.error,
                }
            } else {
                state.draft.vehicles[action.payload.carid] = {
                    id: action.payload.id,
                    entities: { modifications: [] },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                    eveningtime_parking_info_errors: {
                        [action.payload.id]: action.payload.error,
                    },
                }
            }
        },
        initialize_main_policy_holder_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IUser>
        ) => {
            // will be from BE data
            state.draft.main_driver = {
                id: `${action.payload.id}`,
                basic_details: {
                    given_name: action.payload.given_name,
                    family_name: action.payload.family_name,
                    email: action.payload.email,
                },
                completion: 0,

                entities: {
                    memberships: [],
                    claims: [],
                    disabilities: [],
                    convictions: [],
                    motoring_convictions: [],
                },
            }
        },

        initialize_new_other_driver: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.draft.other_drivers['new_driver_uid'] = {
                basic_details: {},
                completion: 0,
                drive_list: [],

                entities: {
                    memberships: [],
                    claims: [],
                    disabilities: [],
                    convictions: [],
                    motoring_convictions: [],
                },
            }
        },

        remove_other_driver_edits: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string>
        ) => {
            if (state.submitted_data.other_drivers[action.payload]) {
                state.draft.other_drivers[action.payload] =
                    state.submitted_data.other_drivers[action.payload]
            } else {
                delete state.draft.other_drivers[action.payload]
            }
        },

        set_answer_other_driver_drive_list: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_drivelist_answer_payload>
        ) => {
            state.draft.other_drivers[action.payload.userid] = {
                ...state.draft.other_drivers[action.payload.userid],
                drive_list: action.payload.answer,
            }
        },

        reset_other_driver_drive_list: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{ userid: string }>
        ) => {
            if (
                state.draft.other_drivers[action.payload.userid] &&
                state.submitted_data.other_drivers[action.payload.userid]
            ) {
                state.draft.other_drivers[action.payload.userid].drive_list =
                    state.submitted_data.other_drivers[
                        action.payload.userid
                    ].drive_list
            }
        },
        reset_other_driver_drive_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string>
        ) => {
            state.draft.other_drivers[action.payload] = {
                ...state.draft.other_drivers[action.payload],
                drive_list_errors: null,
            }
        },

        set_answer_other_driver_basicDetails: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_answer_payload>
        ) => {
            if (
                state.draft.other_drivers[action.payload.userid]?.basic_details
            ) {
                // @ts-ignore
                state.draft.other_drivers[action.payload.userid].basic_details[
                    action.payload.id
                ] = action.payload.answer
            } else {
                state.draft.other_drivers[action.payload.userid].basic_details =
                    {
                        [action.payload.id]: action.payload.answer,
                    }
            }
        },

        set_error_insurance_other_driver_basic_details: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_error_payload>
        ) => {
            if (
                state.draft.other_drivers[action.payload.userid] &&
                state.draft.other_drivers[action.payload.userid]
                    .basic_details_errors
            ) {
                // @ts-ignore
                state.draft.other_drivers[
                    action.payload.userid
                ].basic_details_errors[action.payload.id] = action.payload.error
            } else if (state.draft.other_drivers[action.payload.userid]) {
                state.draft.other_drivers[
                    action.payload.userid
                ].basic_details_errors = {
                    [action.payload.id]: action.payload.error,
                }
            }
        },

        set_answer_other_driver_licenseAddress: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_answer_payload>
        ) => {
            if (
                state.draft.other_drivers[action.payload.userid] &&
                state.draft.other_drivers[action.payload.userid].licence_address
            ) {
                // @ts-ignore
                state.draft.other_drivers[
                    action.payload.userid
                ].licence_address[action.payload.id] = action.payload.answer
            } else if (state.draft.other_drivers[action.payload.userid]) {
                state.draft.other_drivers[
                    action.payload.userid
                ].licence_address = {
                    [action.payload.id]: action.payload.answer,
                }
            }
        },

        set_error_insurance_other_driver_licenseAddress: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_error_payload>
        ) => {
            if (
                state.draft.other_drivers[action.payload.userid] &&
                state.draft.other_drivers[action.payload.userid]
                    .licence_address_errors
            ) {
                // @ts-ignore
                state.draft.other_drivers[
                    action.payload.userid
                ].licence_address_errors[action.payload.id] =
                    action.payload.error
            } else if (state.draft.other_drivers[action.payload.userid]) {
                state.draft.other_drivers[
                    action.payload.userid
                ].licence_address_errors = {
                    [action.payload.id]: action.payload.error,
                }
            }
        },

        set_answer_other_driver_additionalDetails: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_answer_payload>
        ) => {
            if (
                state.draft.other_drivers[action.payload.userid] &&
                state.draft.other_drivers[action.payload.userid]
                    .additional_details
            ) {
                // @ts-ignore
                state.draft.other_drivers[
                    action.payload.userid
                ].additional_details[action.payload.id] = action.payload.answer
            } else if (state.draft.other_drivers[action.payload.userid]) {
                state.draft.other_drivers[
                    action.payload.userid
                ].additional_details = {
                    [action.payload.id]: action.payload.answer,
                }
            }
        },

        set_error_insurance_other_driver_additionalDetails: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_error_payload>
        ) => {
            if (
                state.draft.other_drivers[action.payload.userid] &&
                state.draft.other_drivers[action.payload.userid]
                    .additional_details_errors
            ) {
                // @ts-ignore
                state.draft.other_drivers[
                    action.payload.userid
                ].additional_details_errors[action.payload.id] =
                    action.payload.error
            } else if (state.draft.other_drivers[action.payload.userid]) {
                state.draft.other_drivers[
                    action.payload.userid
                ].additional_details_errors = {
                    [action.payload.id]: action.payload.error,
                }
            }
        },
        set_answer_other_driver_trackRecord: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_answer_payload>
        ) => {
            if (
                state.draft.other_drivers[action.payload.userid] &&
                state.draft.other_drivers[action.payload.userid].track_record
            ) {
                // @ts-ignore
                state.draft.other_drivers[action.payload.userid].track_record[
                    action.payload.id
                ] = action.payload.answer
            } else if (state.draft.other_drivers[action.payload.userid]) {
                state.draft.other_drivers[action.payload.userid].track_record =
                    {
                        [action.payload.id]: action.payload.answer,
                    }
            }
        },

        set_error_insurance_other_driver_trackRecord: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_error_payload>
        ) => {
            if (
                state.draft.other_drivers[action.payload.userid] &&
                state.draft.other_drivers[action.payload.userid]
                    .track_record_errors
            ) {
                // @ts-ignore
                state.draft.other_drivers[
                    action.payload.userid
                ].track_record_errors[action.payload.id] = action.payload.error
            } else if (state.draft.other_drivers[action.payload.userid]) {
                state.draft.other_drivers[
                    action.payload.userid
                ].track_record_errors = {
                    [action.payload.id]: action.payload.error,
                }
            }
        },

        set_answer_other_driver_assigned: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_answer_payload>
        ) => {
            if (
                state.draft.selected_named_drivers &&
                state.draft.selected_named_drivers.indexOf(
                    action.payload.userid
                ) !== -1
            ) {
                state.draft.selected_named_drivers =
                    state.draft.selected_named_drivers.filter(
                        (driverid) => driverid !== action.payload.userid
                    )
            } else {
                let currentlySelected = state.draft.selected_named_drivers
                    ? [...state.draft.selected_named_drivers]
                    : []
                state.draft.selected_named_drivers = [
                    ...currentlySelected,
                    action.payload.userid,
                ]
            }
        },

        set_error_insurance_main_driver_basic_details: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_error_payload>
        ) => {
            if (
                state.draft.main_driver &&
                state.draft.main_driver.basic_details_errors
            ) {
                // @ts-ignore
                state.draft.main_driver.basic_details_errors[
                    action.payload.id
                ] = action.payload.error
            } else if (state.draft.main_driver) {
                state.draft.main_driver.basic_details_errors = {
                    [action.payload.id]: action.payload.error,
                }
            }
        },

        set_answer_main_driver_licenseAddress: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_answer_payload>
        ) => {
            if (
                state.draft.main_driver &&
                state.draft.main_driver.licence_address
            ) {
                state.draft.main_driver.licence_address = {
                    ...state.draft.main_driver.licence_address,
                    [action.payload.id]: action.payload.answer,
                }
            } else if (state.draft.main_driver) {
                state.draft.main_driver.licence_address = {
                    [action.payload.id]: action.payload.answer,
                }
            }
        },

        set_error_insurance_main_driver_licenseAddress: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_error_payload>
        ) => {
            if (
                state.draft.main_driver &&
                state.draft.main_driver.licence_address_errors
            ) {
                state.draft.main_driver.licence_address_errors[
                    action.payload.id
                ] = action.payload.error
            } else if (state.draft.main_driver) {
                state.draft.main_driver.licence_address_errors = {
                    [action.payload.id]: action.payload.error,
                }
            }
        },

        set_answer_main_driver_additionalDetails: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_answer_payload>
        ) => {
            if (
                state.draft.main_driver &&
                state.draft.main_driver.additional_details
            ) {
                state.draft.main_driver.additional_details[action.payload.id] =
                    action.payload.answer
            } else if (state.draft.main_driver) {
                state.draft.main_driver.additional_details = {
                    [action.payload.id]: action.payload.answer,
                }
            } else {
                state.draft.main_driver = {
                    additional_details: {
                        [action.payload.id]: action.payload.answer,
                    },
                    entities: {
                        memberships: [],
                        claims: [],
                        disabilities: [],
                        convictions: [],
                        motoring_convictions: [],
                    },
                }
            }
        },

        set_entity_arr_car: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{
                carid: string
                fieldID: string
                arr: IAnyObject[]
                has_error?: {
                    entityTypeID: string
                    entityUID: string
                }
            }>
        ) => {
            state.draft.vehicles[action.payload.carid] = {
                ...state.draft.vehicles[action.payload.carid],
                entities: {
                    ...state.draft.vehicles[action.payload.carid].entities,
                    [action.payload.fieldID]: action.payload.arr,
                },
            }

            if (action.payload.has_error) {
                let existing_entity_error: IAnyObject = state.draft.main_driver
                    .entities_error
                    ? state.draft.vehicles.entities_error
                    : {}

                let existing_entity_error_entity_type = existing_entity_error[
                    `${action.payload.has_error.entityTypeID}`
                ]
                    ? existing_entity_error[
                          `${action.payload.has_error.entityTypeID}`
                      ]
                    : {}

                let existing_entity_error_entity_object =
                    existing_entity_error[
                        `${action.payload.has_error.entityTypeID}`
                    ] &&
                    existing_entity_error[
                        `${action.payload.has_error.entityTypeID}`
                    ][`"${action.payload.has_error.entityUID}"`]
                        ? existing_entity_error[
                              `${action.payload.has_error.entityTypeID}`
                          ][`"${action.payload.has_error.entityUID}"`]
                        : {}

                delete existing_entity_error_entity_object[
                    action.payload.fieldID
                ]

                state.draft.vehicles = {
                    ...state.draft.vehicles,
                    entities_error: {
                        ...existing_entity_error_entity_type,
                        [`"${action.payload.has_error.entityUID}"`]: {
                            ...existing_entity_error_entity_object,
                        },
                    },
                }
            }
        },

        set_entity_arr_main_driver: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{
                fieldID: string
                arr: IAnyObject[]
                has_error?: {
                    entityTypeID: string
                    entityUID: string
                }
            }>
        ) => {
            let entityID =
                action.payload.fieldID === 'disabilities'
                    ? 'disabilities'
                    : action.payload.fieldID

            state.draft.main_driver = {
                ...state.draft.main_driver,
                entities: {
                    ...state.draft.main_driver.entities,
                    [entityID]: action.payload.arr,
                },
            }

            if (action.payload.has_error) {
                let existing_entity_error: IAnyObject = state.draft.main_driver
                    .entities_error
                    ? state.draft.main_driver.entities_error
                    : {}

                let existing_entity_error_entity_type = existing_entity_error[
                    `${action.payload.has_error.entityTypeID}`
                ]
                    ? existing_entity_error[
                          `${action.payload.has_error.entityTypeID}`
                      ]
                    : {}

                let existing_entity_error_entity_object =
                    existing_entity_error[
                        `${action.payload.has_error.entityTypeID}`
                    ] &&
                    existing_entity_error[
                        `${action.payload.has_error.entityTypeID}`
                    ][`"${action.payload.has_error.entityUID}"`]
                        ? existing_entity_error[
                              `${action.payload.has_error.entityTypeID}`
                          ][`"${action.payload.has_error.entityUID}"`]
                        : {}

                delete existing_entity_error_entity_object[
                    action.payload.fieldID
                ]

                state.draft.main_driver = {
                    ...state.draft.main_driver,
                    entities_error: {
                        ...existing_entity_error_entity_type,
                        [`"${action.payload.has_error.entityUID}"`]: {
                            ...existing_entity_error_entity_object,
                        },
                    },
                }
            }
        },

        set_entity_arr_other_driver: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<{
                userid: string
                fieldID: string
                arr: IAnyObject[]
                has_error?: {
                    entityTypeID: string
                    entityUID: string
                }
            }>
        ) => {
            state.draft.other_drivers[action.payload.userid] = {
                ...state.draft.other_drivers[action.payload.userid],
                entities: {
                    ...state.draft.other_drivers[action.payload.userid]
                        .entities,
                    [action.payload.fieldID]: action.payload.arr,
                },
            }

            if (action.payload.has_error) {
                let existing_entity_error: IAnyObject = state.draft.main_driver
                    .entities_error
                    ? state.draft.other_drivers.entities_error
                    : {}

                let existing_entity_error_entity_type = existing_entity_error[
                    `${action.payload.has_error.entityTypeID}`
                ]
                    ? existing_entity_error[
                          `${action.payload.has_error.entityTypeID}`
                      ]
                    : {}

                let existing_entity_error_entity_object =
                    existing_entity_error[
                        `${action.payload.has_error.entityTypeID}`
                    ] &&
                    existing_entity_error[
                        `${action.payload.has_error.entityTypeID}`
                    ][`"${action.payload.has_error.entityUID}"`]
                        ? existing_entity_error[
                              `${action.payload.has_error.entityTypeID}`
                          ][`"${action.payload.has_error.entityUID}"`]
                        : {}

                delete existing_entity_error_entity_object[
                    action.payload.fieldID
                ]

                state.draft.other_drivers = {
                    ...state.draft.other_drivers,
                    entities_error: {
                        ...existing_entity_error_entity_type,
                        [`"${action.payload.has_error.entityUID}"`]: {
                            ...existing_entity_error_entity_object,
                        },
                    },
                }
            }
        },

        set_error_insurance_main_driver_additionalDetails: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_error_payload>
        ) => {
            if (
                state.draft.main_driver &&
                state.draft.main_driver.additional_details_errors
            ) {
                state.draft.main_driver.additional_details_errors[
                    action.payload.id
                ] = action.payload.error
            } else if (state.draft.main_driver) {
                state.draft.main_driver.additional_details_errors = {
                    [action.payload.id]: action.payload.error,
                }
            }
        },

        set_answer_main_driver_trackRecord: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_answer_payload>
        ) => {
            if (
                state.draft.main_driver &&
                state.draft.main_driver.track_record
            ) {
                state.draft.main_driver.track_record[action.payload.id] =
                    action.payload.answer
            } else if (state.draft.main_driver) {
                state.draft.main_driver.track_record = {
                    [action.payload.id]: action.payload.answer,
                }
            } else {
                state.draft.main_driver = {
                    track_record: {
                        [action.payload.id]: action.payload.answer,
                    },
                    entities: {
                        memberships: [],
                        claims: [],
                        disabilities: [],
                        convictions: [],
                        motoring_convictions: [],
                    },
                }
            }
        },

        set_error_insurance_main_driver_trackRecord: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_error_payload>
        ) => {
            if (
                state.draft.main_driver &&
                state.draft.main_driver.track_record_errors
            ) {
                state.draft.main_driver.track_record_errors[action.payload.id] =
                    action.payload.error
            } else if (state.draft.main_driver) {
                state.draft.main_driver.track_record_errors = {
                    [action.payload.id]: action.payload.error,
                }
            }
        },

        set_answer_main_driver_basicDetails: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_answer_payload>
        ) => {
            if (
                state.draft.main_driver &&
                state.draft.main_driver.basic_details
            ) {
                state.draft.main_driver.basic_details[action.payload.id] =
                    action.payload.answer
            } else if (state.draft.main_driver) {
                state.draft.main_driver.basic_details = {
                    [action.payload.id]: action.payload.answer,
                }
            } else {
                state.draft.main_driver = {
                    basic_details: {
                        [action.payload.id]: action.payload.answer,
                    },
                    entities: {
                        memberships: [],
                        claims: [],
                        disabilities: [],
                        convictions: [],
                        motoring_convictions: [],
                    },
                }
            }
        },

        set_error_insurance_main_driver_basicDetails: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIQA_Driver_error_payload>
        ) => {
            if (
                state.draft.main_driver &&
                state.draft.main_driver.basic_details_errors
            ) {
                state.draft.main_driver.basic_details_errors[
                    action.payload.id
                ] = action.payload.error
            } else if (state.draft.main_driver) {
                state.draft.main_driver.basic_details_errors = {
                    [action.payload.id]: action.payload.error,
                }
            }
        },

        select_vehicles_to_insure: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string[]>
        ) => {
            state.draft.vehicles_to_insure_ids_list = action.payload
            state.draft.vehicles = {}
            action.payload.forEach((id) => {
                state.draft.vehicles = {
                    ...state.draft.vehicles,
                    [id]: {
                        id: id,
                        entities: { modifications: [] },
                        daytime_parking_info: {},
                        eveningtime_parking_info: {},
                    },
                }
            })
        },

        select_everyday_cars: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string[]>
        ) => {
            state.draft.everyday_car_ids = action.payload

            // check if vehicle was added to the appli
            action.payload.forEach((id) => {
                // if no vehicles at all in the application
                if (!state.draft.vehicles) {
                    state.draft.vehicles = {}
                    action.payload.forEach((id) => {
                        state.draft.vehicles = {
                            ...state.draft.vehicles,
                            [id]: {
                                id: id,
                                entities: { modifications: [] },
                                daytime_parking_info: {},
                                eveningtime_parking_info: {},
                            },
                        }
                    })
                } else {
                    // if vehicles in the appli but not one of the ones added
                    if (!state.draft.vehicles[id]) {
                        state.draft.vehicles = {
                            ...state.draft.vehicles,
                            [id]: {
                                id: id,
                                entities: { modifications: [] },
                                daytime_parking_info: {},
                                eveningtime_parking_info: {},
                            },
                        }
                    }
                }
            })
        },

        manage_external_car_id: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string>
        ) => {
            let current = state.draft.external_car_ids
            if (current) {
                if (current.includes(action.payload)) {
                    let updated = current.filter((id) => id != action.payload)
                    state.draft.external_car_ids = [...updated]
                } else {
                    state.draft.external_car_ids = [...current, action.payload]
                }
            } else {
                state.draft.external_car_ids = [action.payload]
            }
        },

        submit_set_everyday_car_id_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IIsCarEverydayArg>
        ) => {
            state.isLoading = true
        },

        submit_set_everyday_car_id_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            }
        },

        submit_set_everyday_car_id_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },

        submit_everyday_car_ids_request: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string[]>
        ) => {
            state.isLoading = true
            state.draft.everyday_car_ids = [...action.payload]
        },

        submit_everyday_car_ids_success: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IInsuranceQuoteApplication | undefined>
        ) => {
            state.isLoading = false
            if (action.payload) {
                state.draft = {
                    ...action.payload,
                }
                state.submitted_data = {
                    ...action.payload,
                }
            }
        },

        submit_everyday_car_ids_error: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<IReduxError>
        ) => {
            state.isLoading = false
            state.error = action.payload
        },
        reset_draft_insurance_application: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.draft = state.submitted_data
        },

        reset_insurance_application_to_none_active: (
            state: IInsuranceQuoteApplication_Reducer
        ) => {
            state.draft = initialApplication
            state.submitted_data = initialApplication
        },
        setRecentDeletedAppliID: (
            state: IInsuranceQuoteApplication_Reducer,
            action: PayloadAction<string | undefined>
        ) => {
            state.recentDeletedAppliID = action.payload
        },
    },
})

export const insuranceActions = insuranceQuoteApplicationSlice.actions

export default insuranceQuoteApplicationSlice.reducer
