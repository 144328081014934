import React, { useEffect, Fragment } from 'react'

interface Props {
    children?: React.ReactNode
}

function ScrollToTop({ children }: Props) {
    useEffect(() => {
        const handleRouteChange = () => {
            if (!window.location.pathname.match(/gallery|car|for-sale|sold/g)) {
                window.scrollTo(0, 0)
            }
        }

        window.addEventListener('popstate', handleRouteChange)
        handleRouteChange() // Call on initial load

        return () => {
            window.removeEventListener('popstate', handleRouteChange)
        }
    }, [])

    return <Fragment>{children}</Fragment>
}

export default ScrollToTop
