import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import './styles.css'
import { IApexPodscastState } from 'apexModels'

const PodcastElemMobile = styled.iframe`
    height: 180px;
    width: 90vw;
    display: flex !important;
    flex-direction: row !important;
    align-self: center !important;
    justify-self: center !important;
    @media ${device.large_desktop} {
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    box-sizing: border-box;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
`

type IProps = {
    podcasts_list: IApexPodscastState[] | undefined
}

const ApexPodcastsHomeListMobile = (props: IProps) => {
    const podcasts_list = props.podcasts_list

    const spotifyEmbedUrls: string[] = []

    podcasts_list?.forEach((podcast) => {
        const spotifyId = podcast?.spotify_url?.split('/').pop()
        const spotifyEmbedUrl = `https://open.spotify.com/embed/episode/${spotifyId}`
        spotifyEmbedUrls.push(spotifyEmbedUrl)
    })

    return (
        <Wrapper>
            {spotifyEmbedUrls &&
                spotifyEmbedUrls.map((podcast: string, index: number) => {
                    if (podcast && index < 4) {
                        return (
                            <Col key={`podcast_${index}_mobile`}>
                                <PodcastElemMobile
                                    title="podcast 1"
                                    src={podcast}
                                    frameBorder={0}
                                    allowTransparency={true}
                                    allow="encrypted-media"
                                ></PodcastElemMobile>
                                <div style={{ paddingTop: '15px' }} />
                            </Col>
                        )
                    } else return <div key={index} />
                })}
        </Wrapper>
    )
}

export default ApexPodcastsHomeListMobile
