import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SwitchLikeBtnDesktop from '../../atoms/Button/switchLikeBtnDesktop'
import H1V3 from '../../atoms/typography/H1V3'
import H2V3 from '../../atoms/typography/H2V3'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import { useNavigate, useLocation } from 'react-router-dom'
import Faded from '../../templates/animated/faded'
import { device } from '../../templates/displays/devices'
import CarSharedWithOthersCardMobile from '../../molecules/cards/sharing/CarSharedWithOthersCardMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import CarSharedWithOthersCardDesktop from '../../molecules/cards/sharing/carSharedWithOthersCardDesktop'
import SingleGarageShareBodyDesktop from '../../molecules/singleGarageShareBody/singleGarageShareBodyDesktop'
import SingleGarageShareBodyMobile from '../../molecules/singleGarageShareBody/singleGarageShareBodyMobile'
import * as ReactScroll from 'react-scroll'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import NoCarsInGarage from '../../atoms/noCarsInGarage/noCarsInGarage'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import ModalDisplayDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayDesktopExclusive'
import QrCodeModalMobile from '../../molecules/qrCodeModal/qrCodeModalMobile'
import QrCodeModalDesktop from '../../molecules/qrCodeModal/qrCodeModalDesktop'
import QrCodeCustomiseStickerModalDesktop from '../../molecules/qrCodeCustomiseStickerModal/qrCodeCustomiseStickerModalDesktop'
import QrCodeStickerBottomSheetMobile from '../../molecules/qrCodeCustomiseStickerModal/qrCodeStickerBottomSheetMobile'
import AdjustableLoader from '../../atoms/loader/adjustableLoader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { garagesActions } from '../../../redux/entities/garages/reducer'
import { usersActions } from '../../../redux/user/reducer'
import {
    IFilterGarageSharesDisplay_change_request,
    ISortGarageSharesDisplay_change_request,
} from '../../../redux/entities/garages/actions/loadingActions'

const SharedWithOthers: React.FC = () => {
    const [activeTabID, setActiveTabID] = useState('cars')

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()

    const garage_id = useAppSelector((state) =>
        state.user.userLoggedIn && state.user.userLoggedIn.owns_garage
            ? state.user.userLoggedIn.owns_garage.uid
            : null
    )
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const allSharesOwnedLoading = useAppSelector(
        (state) => state.user.sharesOwnedLoading
    )
    const garages_data = useAppSelector(
        (state) => state.entities.garagesData.garages
    )
    const isGarageLoading = useAppSelector(
        (state) => state.entities.garagesData.loading
    )

    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const isUserLoading = useAppSelector((state) => state.user.loading)
    const imagesData = useAppSelector(
        (state) => state.entities.galleriesData.images
    )
    const qrCodeModal = useAppSelector(
        (state) => state.editForms && state.editForms.formsData.qrCodeModal
    )
    const qrCodeStickerModal = useAppSelector(
        (state) =>
            state.editForms && state.editForms.formsData.qrCodeStickerModal
    )
    const predefinedQrCodeStickerThemes = useAppSelector(
        (state) => state.localdata.stickerPredefinedThemes
    )
    const isCarDataLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    const areAllCarSharesLoading = useAppSelector(
        (state) => state.entities.carsData.carSharesLoading
    )

    const getNecessaryData = () => {
        if (userLoggedIn && userLoggedIn.id) {
            if (
                garage_id &&
                (!garages_data ||
                    !garages_data[garage_id] ||
                    !garages_data[garage_id].cars ||
                    garages_data[garage_id].cars.length === 0)
            ) {
                dispatch(garagesActions.getGarageByUserIdRequest())
            }

            dispatch(usersActions.populateOwnedSharesDataRequest())
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const entity_id = params.get('entity_id')

        if (entity_id && entity_id === 'garage') {
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/sharing/with-others?entity_id=garage`
                )
            )
        } else {
            dispatch(
                customNavDataActions.setInitialLandingUrl(
                    `/sharing/with-others`
                )
            )
        }

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        } else if (garage_id) {
            if (
                !garages_data[garage_id] ||
                (garages_data[garage_id] &&
                    !garages_data[garage_id].public_share_owned) ||
                !garages_data[garage_id].cars ||
                garages_data[garage_id].cars.length === 0
            ) {
                getNecessaryData()
                dispatch(
                    garagesActions.getOwnedDirectSharesGarageRequest(garage_id)
                )
            } else {
                dispatch(
                    garagesActions.getOwnedDirectSharesGarageRequest(garage_id)
                )
            }
        }

        const action = params.get('action')

        if (entity_id === 'garage_private' || entity_id === 'garage') {
            setActiveTabID('garage')
        }

        const openQrCodeGarage = () =>
            dispatch(
                editFormsActions.toggleQrCodeModal({
                    isOpen: true,
                    id: 'garage',
                })
            )

        if (entity_id === 'garage' && action === 'show_qr_code') {
            setTimeout(openQrCodeGarage, 200)
        }
    }, [userLoggedIn])

    const changeTab = (tabID: string) => {
        setActiveTabID(tabID)
        navigate(`/sharing/with-others?entity_id=${tabID}`)
    }

    const scrollToElement = (elementName: string) => {
        ReactScroll.scroller.scrollTo(elementName, {
            duration: 500,
            delay: 300,
            smooth: true,
            offset: 50,
        })
    }

    const current = garage_id && garages_data && garages_data[garage_id]

    const garage_qr_code_link =
        current &&
        current.public_share_owned &&
        current.public_share_owned.qr_code_url

    const btnIdsList: string[] = ['cars', 'garage']
    const btnsData: any = {
        cars: {
            id: 'cars',
            txt: 'cars',
            action: () => changeTab('cars'),
            isDisabled: false,
        },
        garage: {
            id: 'garage',
            txt: 'garage',
            action: () => changeTab('garage'),
            isDisabled: false,
        },
    }

    const params = new URLSearchParams(location.search)
    const id = params.get('id')

    useEffect(() => {
        if (id === 'garage_private') {
            scrollToElement('garage_privately_shared')
        }
    }, [id])

    const isDataLoading: boolean =
        isCarDataLoading || isUserLoading || isGarageLoading

    return (
        <React.Fragment>
            <FabsMenusMobile hasSubNav={true} />
            <DesktopDisplayOnly>
                <FullPageWrapper>
                    <PageWrapper>
                        <H1V3>Shared with Others</H1V3>

                        <H2V3>
                            Allow your car(s) or garage to be visible to others,
                            either as a publicly shareable link or a direct
                            share.
                        </H2V3>

                        <div style={{ paddingBottom: '60px' }} />

                        <SwitchLikeBtnDesktop
                            btnIdsList={btnIdsList}
                            btnsData={btnsData}
                            activeID={activeTabID}
                        />

                        {activeTabID === 'cars' &&
                            (isDataLoading ? (
                                <div
                                    style={{
                                        position: 'relative',
                                        height: '30vh',
                                    }}
                                >
                                    <AdjustableLoader
                                        isLoading={isDataLoading}
                                    />
                                </div>
                            ) : (
                                userLoggedIn &&
                                current !== null &&
                                current !== undefined &&
                                garage_id &&
                                carsData &&
                                garages_data &&
                                garages_data[garage_id] &&
                                garages_data[garage_id].cars &&
                                (garages_data[garage_id].cars.length > 0 ? (
                                    <Faded>
                                        <DesktopCenteredWrap>
                                            <OverWrapperDesktop>
                                                <WrapperDesktopAdjusted>
                                                    {garages_data[
                                                        garage_id
                                                    ].cars.map(
                                                        (
                                                            id: string,
                                                            index: number
                                                        ) => {
                                                            if (
                                                                carsData[id] &&
                                                                garage_id
                                                            ) {
                                                                return (
                                                                    userLoggedIn && (
                                                                        <CarSharedWithOthersCardDesktop
                                                                            key={`${id}-carshared-desktop`}
                                                                            car={
                                                                                carsData[
                                                                                    id
                                                                                ]
                                                                            }
                                                                            carIndex={Object.keys(
                                                                                carsData
                                                                            ).indexOf(
                                                                                id
                                                                            )}
                                                                            imagesData={
                                                                                imagesData
                                                                            }
                                                                            hasMarginRight={
                                                                                garages_data[
                                                                                    garage_id
                                                                                ]
                                                                                    .cars
                                                                                    .length >
                                                                                    0 &&
                                                                                index %
                                                                                    2 ===
                                                                                    0
                                                                            }
                                                                            garage={
                                                                                garages_data[
                                                                                    garage_id
                                                                                ]
                                                                            }
                                                                            qrCodeRequested={(
                                                                                qr_code_id: string
                                                                            ) =>
                                                                                navigate(
                                                                                    `/sharing/with-others/car?carid=${id}&qr_id=${qr_code_id}`
                                                                                )
                                                                            }
                                                                            areSharesLoading={
                                                                                areAllCarSharesLoading ||
                                                                                allSharesOwnedLoading
                                                                            }
                                                                        />
                                                                    )
                                                                )
                                                            } else
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    />
                                                                )
                                                        }
                                                    )}
                                                </WrapperDesktopAdjusted>
                                            </OverWrapperDesktop>
                                        </DesktopCenteredWrap>
                                    </Faded>
                                ) : (
                                    <div style={{ paddingTop: '50px' }}>
                                        <NoCarsInGarage variant="desktop" />
                                    </div>
                                ))
                            ))}

                        {activeTabID === 'garage' &&
                            (isDataLoading || areAllCarSharesLoading ? (
                                <div
                                    style={{
                                        position: 'relative',
                                        height: '30vh',
                                    }}
                                >
                                    <AdjustableLoader
                                        isLoading={
                                            isDataLoading ||
                                            areAllCarSharesLoading
                                        }
                                    />
                                </div>
                            ) : userLoggedIn &&
                              current !== null &&
                              current !== undefined &&
                              garage_id &&
                              garages_data &&
                              garages_data[garage_id] &&
                              garages_data[garage_id].cars ? (
                                <Faded>
                                    <WhiteBgGarageShareWrapper>
                                        <WhiteBgGarageShareDesktop>
                                            <SingleGarageShareBodyDesktop
                                                garage={garages_data[garage_id]}
                                                createGaragePublicShare={(
                                                    p
                                                ) => {
                                                    dispatch(
                                                        garagesActions.createGaragePublicShareRequest(
                                                            p
                                                        )
                                                    )
                                                }}
                                                deleteGaragePublicShare={(
                                                    p
                                                ) => {
                                                    dispatch(
                                                        garagesActions.deleteGaragePublicShareRequest(
                                                            p
                                                        )
                                                    )
                                                }}
                                                handleChange_shares_sort={(
                                                    p: ISortGarageSharesDisplay_change_request
                                                ) => {
                                                    dispatch(
                                                        garagesActions.sortGarageSharesDisplay_change_request(
                                                            p
                                                        )
                                                    )
                                                }}
                                                handleChange_shares_filter={(
                                                    p: IFilterGarageSharesDisplay_change_request
                                                ) => {
                                                    dispatch(
                                                        garagesActions.filterGarageSharesDisplay_change_request(
                                                            p
                                                        )
                                                    )
                                                }}
                                                openGarageQrCodeModal={() => {
                                                    dispatch(
                                                        editFormsActions.toggleQrCodeModal(
                                                            {
                                                                isOpen: true,
                                                                id: 'garage',
                                                            }
                                                        )
                                                    )
                                                }}
                                                areGarageSharesLoading={false}
                                            />
                                        </WhiteBgGarageShareDesktop>
                                    </WhiteBgGarageShareWrapper>
                                    <div style={{ paddingTop: '200px' }} />
                                </Faded>
                            ) : null)}

                        {qrCodeModal.isOpen && qrCodeModal.id === 'garage' && (
                            <ModalDisplayDesktopExclusive
                                isOpen={
                                    qrCodeModal.isOpen &&
                                    qrCodeModal.id === 'garage'
                                }
                                toggle={() => {}}
                                modalBackgroundColor="rgba(0, 0, 0, 0.65)"
                            >
                                <div style={{ marginTop: '20vh' }} />
                                <QrCodeModalDesktop
                                    qr_code_link={garage_qr_code_link || ''}
                                    qr_code_id={
                                        'garage_qr_code_to_export_desktop'
                                    }
                                    car_title={
                                        userLoggedIn &&
                                        userLoggedIn.display_name
                                            ? `${userLoggedIn.display_name}'s Garage`
                                            : ''
                                    }
                                    closeModal={() =>
                                        dispatch(
                                            editFormsActions.toggleQrCodeModal({
                                                isOpen: false,
                                                id: undefined,
                                            })
                                        )
                                    }
                                    onStickerBtnClick={() => {
                                        dispatch(
                                            editFormsActions.toggleQrCodeStickerModal(
                                                {
                                                    isOpen: true,
                                                    id: qrCodeModal.id,
                                                }
                                            )
                                        )
                                    }}
                                    isGarage
                                />
                            </ModalDisplayDesktopExclusive>
                        )}
                        {qrCodeStickerModal.isOpen &&
                            qrCodeModal.id === 'garage' && (
                                <Faded>
                                    <ModalDisplayDesktopExclusive
                                        isOpen={qrCodeModal.isOpen}
                                        toggle={() => {}}
                                        modalBackgroundColor="rgba(0, 0, 0, 0)"
                                    >
                                        <div
                                            style={{
                                                marginTop: '20vh',
                                            }}
                                        />
                                        <QrCodeCustomiseStickerModalDesktop
                                            qr_code_link={
                                                garage_qr_code_link || ''
                                            }
                                            qr_code_id={
                                                'garage_qr_code_to_export_desktop'
                                            }
                                            car_title={
                                                userLoggedIn &&
                                                userLoggedIn.display_name
                                                    ? `${userLoggedIn.display_name}'s Garage`
                                                    : ''
                                            }
                                            closeModal={() => {
                                                dispatch(
                                                    editFormsActions.toggleQrCodeModal(
                                                        {
                                                            isOpen: false,
                                                            id: undefined,
                                                        }
                                                    )
                                                )
                                                dispatch(
                                                    editFormsActions.toggleQrCodeStickerModal(
                                                        {
                                                            isOpen: false,
                                                            id: undefined,
                                                        }
                                                    )
                                                )
                                            }}
                                            predefinedStickerThemes={
                                                predefinedQrCodeStickerThemes
                                            }
                                            isGarage
                                        />
                                    </ModalDisplayDesktopExclusive>
                                </Faded>
                            )}
                    </PageWrapper>{' '}
                </FullPageWrapper>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <IpadAndMobileWrapper>
                        <H1V3>Shared with Others</H1V3>

                        <H2V3>
                            Allow your car(s) or garage to be visible to others,
                            either as a publicly shareable link or a direct
                            private share.
                        </H2V3>

                        <div style={{ paddingBottom: '30px' }} />

                        <SwitchLikeBtnDesktop
                            btnIdsList={btnIdsList}
                            btnsData={btnsData}
                            activeID={activeTabID}
                        />
                        {activeTabID === 'cars' &&
                            (isDataLoading ? (
                                <div
                                    style={{
                                        position: 'relative',
                                        height: '30vh',
                                    }}
                                >
                                    <AdjustableLoader
                                        isLoading={isDataLoading}
                                    />
                                </div>
                            ) : (
                                userLoggedIn &&
                                userLoggedIn !== null &&
                                current !== null &&
                                current !== undefined &&
                                garage_id &&
                                carsData &&
                                garages_data &&
                                garages_data[garage_id] &&
                                garages_data[garage_id].cars &&
                                (garages_data[garage_id].cars.length > 0 ? (
                                    <Faded>
                                        <WrapperMobileAdjusted>
                                            {garages_data[garage_id].cars.map(
                                                (id: string, index: number) => {
                                                    if (carsData[id]) {
                                                        return (
                                                            <CarSharedWithOthersCardMobile
                                                                key={`${id}-carshared-mobile`}
                                                                car={
                                                                    carsData[id]
                                                                }
                                                                carIndex={Object.keys(
                                                                    carsData
                                                                ).indexOf(id)}
                                                                imagesData={
                                                                    imagesData
                                                                }
                                                                index={index}
                                                                garage={
                                                                    garages_data[
                                                                        garage_id
                                                                            ? garage_id
                                                                            : ''
                                                                    ]
                                                                }
                                                                qrCodeRequested={(
                                                                    qr_code_id: string
                                                                ) =>
                                                                    navigate(
                                                                        `/sharing/with-others/car?carid=${id}&qr_id=${qr_code_id}`
                                                                    )
                                                                }
                                                                isCarDataLoading={
                                                                    areAllCarSharesLoading ||
                                                                    allSharesOwnedLoading
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )
                                                    } else
                                                        return (
                                                            <div key={index} />
                                                        )
                                                }
                                            )}
                                        </WrapperMobileAdjusted>
                                    </Faded>
                                ) : (
                                    <div style={{ paddingTop: '50px' }}>
                                        <NoCarsInGarage variant="mobile" />
                                    </div>
                                ))
                            ))}

                        {activeTabID === 'garage' &&
                            (isDataLoading || areAllCarSharesLoading ? (
                                <div
                                    style={{
                                        position: 'relative',
                                        height: '30vh',
                                    }}
                                >
                                    <AdjustableLoader
                                        isLoading={
                                            isDataLoading ||
                                            areAllCarSharesLoading
                                        }
                                    />
                                </div>
                            ) : userLoggedIn &&
                              current !== null &&
                              current !== undefined &&
                              garage_id &&
                              garages_data &&
                              garages_data[garage_id] &&
                              garages_data[garage_id].cars ? (
                                <Faded>
                                    <WhiteBgGarageShareWrapper>
                                        <SingleGarageShareBodyMobile
                                            garage={garages_data[garage_id]}
                                            createGaragePublicShare={(p) => {
                                                dispatch(
                                                    garagesActions.createGaragePublicShareRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            deleteGaragePublicShare={(p) => {
                                                dispatch(
                                                    garagesActions.deleteGaragePublicShareRequest(
                                                        p
                                                    )
                                                )
                                            }}
                                            handleChange_shares_sort={(
                                                p: ISortGarageSharesDisplay_change_request
                                            ) => {
                                                dispatch(
                                                    garagesActions.sortGarageSharesDisplay_change_request(
                                                        p
                                                    )
                                                )
                                            }}
                                            handleChange_shares_filter={(
                                                p: IFilterGarageSharesDisplay_change_request
                                            ) => {
                                                dispatch(
                                                    garagesActions.filterGarageSharesDisplay_change_request(
                                                        p
                                                    )
                                                )
                                            }}
                                            openGarageQrCodeModal={() => {
                                                dispatch(
                                                    editFormsActions.toggleQrCodeModal(
                                                        {
                                                            isOpen: true,
                                                            id: 'garage',
                                                        }
                                                    )
                                                )
                                            }}
                                            areGarageSharesLoading={false}
                                        />
                                    </WhiteBgGarageShareWrapper>
                                    <div style={{ paddingTop: '50px' }} />
                                </Faded>
                            ) : null)}
                    </IpadAndMobileWrapper>
                </Faded>
                <div style={{ paddingTop: '120px' }} />
                {qrCodeModal.isOpen && qrCodeModal.id === 'garage' && (
                    <ModalDisplay
                        isOpen={
                            qrCodeModal.isOpen && qrCodeModal.id === 'garage'
                        }
                        toggle={() => {}}
                    >
                        <QrCodeModalMobile
                            qr_code_link={garage_qr_code_link || ''}
                            qr_code_id={'garage_qr_code_to_export_desktop'}
                            closeModal={() =>
                                dispatch(
                                    editFormsActions.toggleQrCodeModal({
                                        isOpen: false,
                                        id: undefined,
                                    })
                                )
                            }
                            onStickerBtnClick={() => {
                                dispatch(
                                    editFormsActions.toggleQrCodeStickerModal({
                                        isOpen: true,
                                        id: qrCodeModal.id,
                                    })
                                )

                                dispatch(
                                    editFormsActions.toggleQrCodeModal({
                                        isOpen: false,
                                        id: undefined,
                                    })
                                )
                            }}
                            car_title={
                                userLoggedIn && userLoggedIn.display_name
                                    ? `${userLoggedIn.display_name}'s Garage`
                                    : ''
                            }
                            isGarage={true}
                        />
                    </ModalDisplay>
                )}
                <QrCodeStickerBottomSheetMobile
                    qr_code_link={garage_qr_code_link || ''}
                    qr_code_id={'garage_qr_code_to_export_desktop'}
                    closeBottomSheet={() => {
                        dispatch(
                            editFormsActions.toggleQrCodeStickerModal({
                                isOpen: false,
                                id: undefined,
                            })
                        )
                    }}
                    title={
                        userLoggedIn && userLoggedIn.display_name
                            ? `${userLoggedIn.display_name}'s Garage`
                            : ''
                    }
                    isBottomSheetOpen={qrCodeStickerModal.isOpen}
                    predefinedStickerThemes={predefinedQrCodeStickerThemes}
                />
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default SharedWithOthers

const PageWrapper = styled.div`
    background-color: var(--off-bg-color, #fafafa);
    width: 100%;
    box-sizing: border-box;
    padding-top: 20px;
    min-height: 100vh;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 200px;
`
const DesktopCenteredWrap = styled.div`
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        justify-self: center;
    }
`
const OverWrapperDesktop = styled.div`
    @media ${device.beyond_ipad_mobile} {
        max-width: 1400px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        align-self: center;
        justify-self: center;
    }
`

const WhiteBgGarageShareDesktop = styled.div`
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 30px;
        align-self: center;
        justify-self: center;
        background-color: var(--bg-color, #fff);
        padding-left: 100px;
        padding-right: 50px;
        max-width: 1400px;
    }
`

const WhiteBgGarageShareWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    @media ${device.beyond_ipad_mobile} {
        padding-top: 70px;
    }
`

const WrapperDesktopAdjusted = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
    }

    @media (max-width: 1240px) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
`

const WrapperMobileAdjusted = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    flex-wrap: wrap;
    padding-top: 52px;
    padding-bottom: 100px;

    @media ${device.ipad} {
        padding-top: 60px;
    }
`

const IpadAndMobileWrapper = styled.div`
    background-color: var(--bg-color, #fff);
    padding: 15px;
    padding-top: 110px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;

    @media ${device.ipad} {
        padding-top: 150px;
    }
    @media (max-width: 400px) {
        padding: 5px;
        padding-top: 110px;
    }
`
