import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import {
    IAnyObject,
    IAnyErrorString,
    insuranceActions,
    IInsuranceQuoteApplication,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
// import {
//     IsInsuranceApplicationDateValid_result,
//     isInsuranceApplicationDateValid,
// } from '../../../../../../redux/localdata/insuranceLocalData/datesValidation'
import { insurance_fields_other_driver_licence_address } from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import colours from '../../../../../../providers/theme/colours'
import useThemes from '../../../../../../providers/theme/hooks'
import { IDropdownItem } from 'entityModels'
import InsuranceApplicationMobileSearch from '../../../../../pages/insurance_quote/flows/search/mobileSearch'
import PortalInvisibleWrapper from '../../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import RemoveMobileSearchAnchor from '../../../../../templates/insurance/removeMobileSearchAnchor'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'

type Props = {
    driver_id: string
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}
const InsuranceOtherDriverLicenceAddressApplicationWizardForm = (
    props: Props
) => {
    let userid = props.driver_id

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const data: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.other_drivers[userid]
                ?.licence_address ?? {}
        )
    })

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.other_drivers[userid]
                ?.licence_address ?? {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.other_drivers[userid]
            ?.licence_address_errors
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_other_driver_licenseAddress({
                id: id,
                error: error,
                userid: userid,
            })
        )
    }

    // const hasErrorFunc = () => {
    //     let hasErr: boolean = false

    //     if (errors) {
    //         for (const [_, value] of Object.entries(errors)) {
    //             if (value !== undefined) {
    //                 hasErr = true
    //             }
    //         }
    //     }

    //     return hasErr
    // }

    // let hasError = hasErrorFunc()

    let {
        setInitialInsurance_Drivers_License_dropdowns,
        getFirstIncompleteSubsection_OtherDriver,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    const validateOnClick = (): boolean => {
        let hasErr2 = false

        for (
            let i = 0;
            i < insurance_fields_other_driver_licence_address.length;
            i++
        ) {
            let current_field_type =
                insurance_fields_other_driver_licence_address[i]
            if (current_field_type.is_required === true) {
                if (data[current_field_type.id] === undefined) {
                    setError(current_field_type.id, 'Required field.')

                    hasErr2 = true
                }
            }

            if (
                current_field_type.validation &&
                data[current_field_type.id] !== undefined
            ) {
                let is_valid_func = current_field_type.validation
                let value = data[current_field_type.id]
                let error_txt = current_field_type.error_txt

                if (is_valid_func && is_valid_func(value) !== true) {
                    setError(current_field_type.id, error_txt ?? 'Invalid')
                    hasErr2 = true
                }
            }
        }

        // if (data && data['residency_date']) {
        //     let res: IsInsuranceApplicationDateValid_result =
        //         isInsuranceApplicationDateValid({
        //             fieldID: 'residency_date',
        //             application_edited: applicationData,
        //             section: 'license',
        //             other_driver_id: userid,
        //             valueToCheck: data['residency_date'],
        //             value_of_dependable_field:
        //                 data['is_uk_resident_since_birth'],
        //             expected_value_of_dependable_field_for_validation: false,
        //         })
        //     if (res.isValid === false) {
        //         hasErr2 = true
        //         setError('residency_date', res.error_txt ?? 'Invalid')
        //     }
        // }

        if (data && data['license_date'] && errors && errors[`license_date`]) {
            hasErr2 = true
        }

        if (
            data &&
            data['residency_date'] &&
            errors &&
            errors[`residency_date`]
        ) {
            hasErr2 = true
        }

        return hasErr2
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        setInitialInsurance_Drivers_License_dropdowns()
    }, [userLoggedIn])

    let countriesList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    useEffect(() => {
        if (userLoggedIn && !countriesList) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [userLoggedIn])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_otherDriver_licenceAddress_request({
                    driver_id: userid,
                })
            )
            // next up

            setTimeout(() => {
                goNext()
            }, 200)
        } else {
            setIsSavedDisabled(true)
        }
    }

    const goPrevious = () => {
        navigate(
            `/insurance/application/${applicationData?.id}/wizard?section_id=drivers&subsection_id=basic_details&driver_id=${userid}`
        )
    }

    const goNext = () => {
        let ids = getFirstIncompleteSubsection_OtherDriver(
            userid,
            'licence_address'
        )

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            navigate(
                {
                    search: params2.toString(),
                },
                { replace: true }
            )
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('drivers')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                navigate(
                    {
                        search: params2.toString(),
                    },
                    { replace: true }
                )
            } else navigate(`/insurance/application/${applicationData?.id}`)
        }

        return getToAny()
        // next of the driver that is 100%, I should do the same for cars
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'drivers',
            onSave: () => saveForm(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_other_driver_licenseAddress({
                id: id,
                answer: answer,
                userid: userid,
            })
        )
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'drivers',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }
    }

    const { theme } = useThemes()
    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        driverid={userid}
                        sectionID="drivers"
                        list={insurance_fields_other_driver_licence_address}
                        onAnswerChange={onAnswerChange}
                        data={data}
                        errors={errors}
                        setError={setError}
                        mobileSearchPickerSectionURL={`/insurance/application/${applicationData?.id}/wizard?section_id=drivers&subsection_id=licence_address&driver_id=${userid}`}
                    />

                    <div style={{ paddingTop: '200px' }} />
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() => goPrevious()}
                    sectionId="drivers"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="drivers"
                            urlBack={`/insurance/application/${applicationData?.id}/wizard?section_id=drivers&subsection_id=licence_address&driver_id=${userid}`}
                            draft={data}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                if (field_id && errors && errors[field_id]) {
                                    setError(field_id, undefined)
                                }

                                userid &&
                                    dispatch(
                                        insuranceActions.set_answer_other_driver_licenseAddress(
                                            {
                                                id: `${field_id}`,
                                                answer: value,
                                                userid: userid,
                                            }
                                        )
                                    )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <div style={{ width: '100vw' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100vw',
                                    paddingBottom: 120,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}
                            >
                                <InsuranceQuoteFormGenerator
                                    driverid={userid}
                                    sectionID="drivers"
                                    list={
                                        insurance_fields_other_driver_licence_address
                                    }
                                    onAnswerChange={onAnswerChange}
                                    data={data}
                                    errors={errors}
                                    setError={setError}
                                    mobileSearchPickerSectionURL={`/insurance/application/${applicationData?.id}/wizard?section_id=drivers&subsection_id=licence_address&driver_id=${userid}`}
                                />

                                <div style={{ paddingTop: '200px' }} />
                            </div>
                            <InsuranceGoBackReusableBottomBar
                                isMobile
                                saveBtnTxt="Save & Continue"
                                isSaveDisabled={isSavedDisabled ? true : false}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                goBack={() => goPrevious()}
                                sectionId="drivers"
                            />
                        </div>
                    </>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceOtherDriverLicenceAddressApplicationWizardForm
