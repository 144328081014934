import { IApexArticleOverviewItemState } from 'apexModels'
import React, { useState } from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import { Link } from 'react-router-dom'
import ShowMoreExpandToggleMobile from '../../../atoms/Button/showMoreExpandToggleMobile'
import Expander from '../../../atoms/expander/expander'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 45px;
`
const CoverImage = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 112px;
    object-fit: cover;
    border-radius: 10px;

    @media (min-width: 500px) {
        height: 184px;
    }
`

const CustomLink = styled(Link)`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`
const TitleLink = styled(Link)`
    font-family: Lato;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a) !important;
    padding-right: 2vw;

    @media ${device.large_desktop} {
    }
`

const Paragraph = styled.div`
    font-family: Lato-light;
    font-size: 15px;
    line-height: 25px;
    color: var(--text-strong, #1a1a1a);
    padding-right: 2vw;
`

type Props = {
    item: IApexArticleOverviewItemState
    index: number
}

const ArticleOverviewItemMobile: React.FC<Props> = ({ item, index }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <Container key={index}>
            <CustomLink to={`/apex/articles/${item.uid}`}>
                <CoverImage src={item.img ? item.img.url : grey_placeholder} />
            </CustomLink>
            <div style={{ paddingTop: '16px' }} />
            <TitleLink to={`/apex/articles/${item.uid}`}>
                {item.title}
            </TitleLink>
            <div style={{ paddingTop: '10px' }} />
            <Expander height={isExpanded ? 'auto' : 55}>
                <Paragraph>
                    {item.introduction &&
                        item.introduction[0] &&
                        item.introduction[0]}
                </Paragraph>
                <div style={{ paddingTop: '16px' }} />
                <Paragraph>
                    {item.introduction &&
                        item.introduction[1] &&
                        item.introduction[1]}
                </Paragraph>
                <div style={{ paddingTop: '13px' }} />
            </Expander>

            <ShowMoreExpandToggleMobile
                onClick={toggleIsExpanded}
                isExpanded={isExpanded}
            />
        </Container>
    )
}

export default ArticleOverviewItemMobile
