import { useNavigate, useLocation } from 'react-router-dom'
import { JourneyPageBackground } from '../../templates/windowPageTemplate/journey/pageBackground'
import { IJourneyPastCarsStepID } from 'journeyInterfaces'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { useEffect, useState } from 'react'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import PastCarsJourneyStepsManager from '../../organisms/journey/pastCars/stepManager'
import { usersActions } from '../../../redux/user/reducer'

export default function PastCarsJourney() {
    const navigate = useNavigate()
    const location = useLocation()
    let params = new URLSearchParams(location.search)
    let step: string | null = params.get('step')

    useEffect(() => {
        window?.scrollTo(0, 0)
    }, [step])

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const [originPath, setOriginPath] = useState<string>('')

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl('/journey/past-cars')
        )

        let locationState: any = location.state

        if (locationState?.prevPath) {
            if (locationState?.prevPath.includes(`step=achieved`)) {
                setOriginPath(`/garage`)
            } else {
                setOriginPath(locationState?.prevPath)
            }
        } else {
            setOriginPath(`/garage`)
        }
    }, [])

    useEffect(() => {
        if (userLoggedIn) {
        } else {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    return (
        <JourneyPageBackground
            sectionID="past_cars"
            hasBlurredBg={true}
            hasLinearBgColour={true}
            isMobile={isMobile}
            hasPattern={isMobile ? (step === 'welcome' ? true : false) : true}
        >
            {/* <JourneyTopBar
                sectionID="past_cars"
                onClose={() => {
                    return navigate(`/garage`)
                }}
            /> */}
            <PastCarsJourneyStepsManager
                isMobile={isMobile}
                stepID={step as IJourneyPastCarsStepID}
                startJourney={() => {
                    return navigate(`/journey/previously-owned?step=memories`, {
                        state: {
                            prevPath: `${location.pathname}${
                                location.search ?? ''
                            }`,
                        },
                    })
                }}
                exitJourney={() => {
                    return navigate(originPath)
                }}
                user={userLoggedIn}
            />
        </JourneyPageBackground>
    )
}
