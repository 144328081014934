import React from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import TimelineItemDesktop from '../../molecules/timelineItemDesktop/timelineItemDesktop'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import OverviewHistoryFileSkeleton from '../../atoms/car/profile/overviewHistoryFileSkeleton'
import BubbleCardWithHorizontalLine from '../../atoms/bubbleCards/bubbleCardWithHorizontalLine'
import ChevronDown from '../../atoms/icons/components/chevronDown'
import colours, { ITheme } from '../../../providers/theme/colours'
import { INormalisedAttachmentsByID } from '../../../redux/attachments/types'
import { IExternalCar, ICar } from '../../../redux/entities/cars/types'
import { ITimelineItem } from '../../../redux/timeline/types'
import useThemes from '../../../providers/theme/hooks'

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 24px;
`

const TimelineItemsSpacing = styled.div<IStyle>`
    min-width: 24px;
    max-width: 24px;
    position: relative;
    border: none;
    background-color: none;
    margin-top: 0px;

    :before {
        content: '';
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        height: 4px;
        top: 0px;
        left: 0;
        background-color: ${(props) => colours[props.$theme].border_muted};
    }
`

const GridContainer = styled.div`
    display: grid;
    width: 100%;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
    }
`

const ViewAll = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].primary};
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.001px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding-top: 24px;
    justify-content: flex-end;
`

const ItemWrapper = styled.div<IStyle>`
    display: flex;
    flex: 1;
    min-width: 0px;
`

export interface IStyle {
    $theme: ITheme
    numberOfEntriesPerPage?: number
    width?: string
    height?: string
}

interface Props {
    entries_array: ITimelineItem[] | undefined | null
    car_entries_count: number
    attachmentsObj?: INormalisedAttachmentsByID
    onClick?: any
    carid: string
    onPlaceholderClick: any
    userCurrency: string | undefined
    readOnlyMode?: boolean
    sharedWithYouCar?: IExternalCar
    isOwnedCar?: ICar
}

const TimelineOverviewManagerDesktop: React.FC<Props> = ({
    entries_array,
    car_entries_count,
    attachmentsObj,
    carid,
    onPlaceholderClick,
    userCurrency,
    readOnlyMode,
    sharedWithYouCar,
}) => {
    let { theme } = useThemes()

    const navigate = useNavigate()
    const location = useLocation()

    const checkIfInfoIsRestricted = () => {
        if (
            readOnlyMode &&
            sharedWithYouCar &&
            sharedWithYouCar.policy_statements
        ) {
            if (!sharedWithYouCar.policy_statements.includes('history file')) {
                return true
            }
        }
        return false
    }

    const isEmpty = !entries_array || entries_array.length === 0

    const entriesN = window.innerWidth < 1400 ? [1, 2] : [1, 2, 3]
    const emptyN = window.innerWidth < 1400 ? [1, 2] : [1, 2, 3]

    const linkPrefix = location.pathname.includes('overview')
        ? location.pathname.replace('/overview', '')
        : location.pathname

    return (
        <Wrapper>
            {!isEmpty && (
                <GridContainer>
                    {entriesN.map((indexd: number, index: number) => {
                        const entry = entries_array && entries_array[index]
                        if (entry) {
                            return (
                                <div
                                    style={{
                                        maxWidth: '100%',
                                        minWidth: '100%',
                                        display: 'flex',
                                    }}
                                    key={`timeline_entry_${index}_desktop`}
                                >
                                    <ItemWrapper $theme={theme}>
                                        <TimelineItemDesktop
                                            isFirst={index === 0}
                                            isLast={
                                                index === entriesN.length - 1
                                            }
                                            item={entry}
                                            attachmentsObj={attachmentsObj}
                                            onClick={() =>
                                                navigate(
                                                    `${linkPrefix}/history-file/entry?entryid=${entry?.id}`
                                                )
                                            }
                                            isCarOverview
                                            userCurrency={userCurrency}
                                        />
                                        {index < entriesN.length - 1 && (
                                            <TimelineItemsSpacing
                                                $theme={theme}
                                            />
                                        )}
                                    </ItemWrapper>
                                </div>
                            )
                        } else {
                            return (
                                <div
                                    style={{
                                        maxWidth: '100%',
                                        minWidth: '100%',
                                        display: 'flex',
                                    }}
                                    key={`timeline_skeleton_${index}_desktop`}
                                >
                                    <BubbleCardWithHorizontalLine
                                        isFirst={index === 0}
                                        isLast={index === entriesN.length - 1}
                                        onClick={
                                            readOnlyMode ? undefined : () => {}
                                        }
                                    >
                                        <OverviewHistoryFileSkeleton
                                            index={index}
                                            onClick={onPlaceholderClick}
                                            reaonlyMode={readOnlyMode}
                                        />
                                    </BubbleCardWithHorizontalLine>
                                    {index < entriesN.length - 1 && (
                                        <TimelineItemsSpacing $theme={theme} />
                                    )}
                                </div>
                            )
                        }
                    })}
                </GridContainer>
            )}

            {isEmpty && readOnlyMode && (
                <NoDataRectangle
                    text="no history file entries"
                    isInfoRestricted={checkIfInfoIsRestricted()}
                />
            )}

            <GridContainer>
                {isEmpty
                    ? readOnlyMode
                        ? null
                        : emptyN.map((indexd: number, index: number) => (
                              <div
                                  style={{
                                      maxWidth: '100%',
                                      minWidth: '100%',
                                      display: 'flex',
                                  }}
                                  key={`timeline_skeleton_${index}_desktop`}
                              >
                                  <BubbleCardWithHorizontalLine
                                      isFirst={index === 0}
                                      isLast={index === emptyN.length - 1}
                                      onClick={
                                          readOnlyMode ? undefined : () => {}
                                      }
                                  >
                                      <OverviewHistoryFileSkeleton
                                          index={index}
                                          onClick={onPlaceholderClick}
                                          reaonlyMode={readOnlyMode}
                                      />
                                  </BubbleCardWithHorizontalLine>
                                  {index < emptyN.length - 1 && (
                                      <TimelineItemsSpacing $theme={theme} />
                                  )}
                              </div>
                          ))
                    : null}
            </GridContainer>

            {car_entries_count && car_entries_count > entriesN.length ? (
                <ViewAll
                    $theme={theme}
                    role="button"
                    onClick={
                        readOnlyMode
                            ? onPlaceholderClick
                            : () => navigate(`/car/${carid}/history-file`)
                    }
                >
                    {`${car_entries_count - entriesN.length} more`}{' '}
                    <div style={{ transform: 'rotate(-90deg)' }}>
                        <ChevronDown color={colours[theme].primary} />
                    </div>
                </ViewAll>
            ) : null}
        </Wrapper>
    )
}

export default TimelineOverviewManagerDesktop
