import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import JourneyStartIcon from '../../icons/components/journey/playIcon'
import useThemes from '../../../../providers/theme/hooks'
import { ITheme, journey_colours } from '../../../../providers/theme/colours'

type IStyle = {
    $theme: ITheme
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 163px;
    right: 20px;
    top: 0px;

    @media (min-width: 881px) and (max-width: 1100px) {
        display: none;
    }
`

const AbsoluteBackground = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const LinearBackground = styled.div<IStyle>`
    height: 64px;
    width: 200px;
    border-radius: 10px;
    background: ${(props) =>
        `linear-gradient(270deg, ${
            journey_colours[props.$theme].section[`your_profile`].primary_300
        } 0%, rgba(26, 26, 26, 0.00) 100%)`};
`

const TextRow = styled.div`
    padding-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.001px;
    gap: 6px;
`

type Props = {
    display_name?: string
}

export const UserProfileJourneyGarageBtnDesktop = (props: Props) => {
    let navigate = useNavigate()
    let location = useLocation()

    let { width } = useWindowSize()

    let isShown =
        width &&
        width < 1300 &&
        props.display_name &&
        props.display_name?.length > 24
            ? false
            : true

    const { theme } = useThemes()

    return (
        <>
            {isShown === false ? null : (
                <Row>
                    <AbsoluteBackground
                        onClick={() => {
                            navigate(
                                {
                                    pathname: `/journey/your-profile`,
                                    search: `?step=welcome`,
                                },
                                {
                                    state: {
                                        prevPath: `${location.pathname}${
                                            location.search ?? ''
                                        }`,
                                    },
                                }
                            )
                        }}
                    >
                        {theme === 'dark' ? (
                            <LinearBackground $theme={theme} />
                        ) : (
                            <svg
                                width="169"
                                height="76"
                                viewBox="0 0 169 76"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="169"
                                    height="76"
                                    rx="14"
                                    fill="url(#paint0_linear_4317_57519)"
                                />
                                <path
                                    d="M0 4H153C159.627 4 165 9.37258 165 16V60C165 66.6274 159.627 72 153 72H0V4Z"
                                    fill="url(#paint1_linear_4317_57519)"
                                />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_4317_57519"
                                        x1="169"
                                        y1="39.9388"
                                        x2="-0.00406083"
                                        y2="38.4656"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stop-color="white" />
                                        <stop offset="1" stop-color="#FAFAFA" />
                                    </linearGradient>
                                    <linearGradient
                                        id="paint1_linear_4317_57519"
                                        x1="113.88"
                                        y1="38"
                                        x2="0"
                                        y2="38"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            stop-color="#DEE7E5"
                                            stop-opacity="0.82"
                                        />
                                        <stop
                                            offset="1"
                                            stop-color="white"
                                            stop-opacity="0"
                                        />
                                    </linearGradient>
                                </defs>
                            </svg>
                        )}
                    </AbsoluteBackground>

                    <AbsoluteBackground
                        onClick={() => {
                            navigate(
                                {
                                    pathname: `/journey/your-profile`,
                                    search: `?step=welcome`,
                                },
                                {
                                    state: {
                                        prevPath: `${location.pathname}${
                                            location.search ?? ''
                                        }`,
                                    },
                                }
                            )
                        }}
                    >
                        <TextRow
                            style={{
                                color: journey_colours[theme].section
                                    .your_profile.primary_500,
                            }}
                        >
                            <div style={{ transform: 'translateY(0px)' }}>
                                Fill in your profile
                            </div>
                            <JourneyStartIcon
                                size="16px"
                                color={
                                    journey_colours[theme].section.your_profile
                                        .primary_500
                                }
                            />
                        </TextRow>
                    </AbsoluteBackground>
                </Row>
            )}
        </>
    )
}
