import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import chevron_grey_to_right from '../../../public/assets/icons/chevron_grey_to_right.svg'

const ShowMoreContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;
    width: fit-content;
`

const Chevron = styled.img`
    filter: brightness(0) saturate(100%) invert(72%) sepia(37%) saturate(496%)
        hue-rotate(135deg) brightness(91%) contrast(91%);
`

const ShowMore = styled.div`
    font-family: Lato;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    /* letter-spacing: 150%; */
    color: #5ec3ca;
    width: 100%;
    box-sizing: border-box;

    transition: all 100ms;

    @media ${device.smallest_laptops} {
        font-size: 0.9rem;
    }
`

type Props = {
    onClick?: any
    showLess?: boolean
    customTxt?: string
}

const ShowMoreBtn = (props: Props) => {
    return (
        <ShowMoreContainer
            role="button"
            onClick={() => {
                props.onClick && props.onClick()
            }}
        >
            <ShowMore>
                {props.customTxt
                    ? props.customTxt
                    : props.showLess
                    ? 'show less'
                    : 'show more'}
            </ShowMore>
            <Chevron height={'12px'} src={chevron_grey_to_right} />
        </ShowMoreContainer>
    )
}

export default ShowMoreBtn
