import * as React from 'react'
import { useState, useEffect } from 'react'

import SquareDuoMobile from '../../molecules/imageGrid/squareDuoMobile'
import AddImageBanner from '../../atoms/image/addImageBanner'
import Loader from '../../atoms/loader/loader'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import { IGalleryImagesObject } from '../../../redux/entities/galleries/types'

export interface IImageProps {
    url: string
}

interface Props {
    imagesData: IGalleryImagesObject | null
    isNecessaryGalleryDataLoading: boolean
    ids_list: string[]
    openImgForm?: () => void
    isGettingCarDataLoading: boolean
    readOnlyMode?: boolean
}

const CarGalleryManagerMobile: React.FC<Props> = ({
    imagesData,
    isNecessaryGalleryDataLoading,
    ids_list,
    openImgForm,
    isGettingCarDataLoading,
    readOnlyMode,
}) => {
    const [isEmpty, setIsEmpty] = useState<boolean | null>(true)

    useEffect(() => {
        if (!ids_list || ids_list.length < 1) {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }
    }, [ids_list])

    return (
        <React.Fragment>
            {isEmpty !== true &&
                !isNecessaryGalleryDataLoading &&
                !isGettingCarDataLoading &&
                imagesData && (
                    <SquareDuoMobile
                        images_data_object={imagesData}
                        ids_list={ids_list}
                        onAddClick={openImgForm}
                        readOnlyMode={readOnlyMode}
                    />
                )}

            <Loader
                isLoading={
                    isGettingCarDataLoading || isNecessaryGalleryDataLoading
                }
            />

            {isEmpty === true &&
                (readOnlyMode ? (
                    <>
                        <div style={{ paddingTop: '20px' }} />
                        <NoDataRectangle text="no images" />
                    </>
                ) : (
                    <AddImageBanner ipadHeight="350px" onClick={openImgForm} />
                ))}
        </React.Fragment>
    )
}

export default CarGalleryManagerMobile
