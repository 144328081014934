import { useState } from 'react'
import styled from 'styled-components'
import AddBox from '../../../atoms/Button/addBox'
import KMFplaceholder from './kmfPlaceholder'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type EnumerableObject = {
    id: string
    text: string
    order: number
}

type Props = {
    obj: EnumerableObject
    onClick?: any
    isReadOnly?: boolean
    isJourney?: boolean
    isLast: boolean
}

const Card = styled.div<{
    $isLast: boolean
    $isJourney?: boolean
    $theme: ITheme
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    min-width: 260px;
    max-width: 260px;
    height: 400px;
    padding: 24px;
    padding-top: 19px;
    border-radius: 8px;
    border: 1px solid ${(props) => colours[props.$theme].border_muted};
    box-shadow: ${(props) =>
        props.$isJourney
            ? '64px 64px 128px 0px rgba(26, 26, 26, 0.08)'
            : '10px 6px 24px rgba(0, 0, 0, 0.04)'};
    margin-right: ${(props) =>
        props.$isJourney ? '0px' : props.$isLast ? `20%` : '20px'};
    background-color: ${(props) =>
        props.$isJourney
            ? colours[props.$theme].background_default
            : 'transparent'};

    @media (min-width: 1800px) {
        min-width: 254px;
        max-width: 254px;
        margin-right: ${(props) =>
            props.$isJourney ? '0px' : props.$isLast ? `0px` : '20px'};
    }

    @media (min-width: 2000px) {
        min-width: 260px;
        max-width: 260px;
        margin-right: ${(props) =>
            props.$isJourney ? '0px' : props.$isLast ? `0px` : '24px'};
    }
`

const CardTopSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
`

const CardContent = styled.div<{ $theme: ITheme }>`
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: ${(props) => colours[props.$theme].text_default};
    padding-top: 8px;
    padding-bottom: 20px;
    text-align: center;
`
const NumberKeyWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
`
const NumberKey = styled.div<{ $theme: ITheme }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    font-family: Lato;
    font-style: normal;
    font-size: 24px;
    color: ${(props) => colours[props.$theme].primary};
`
const HorizLine = styled.div<{ $theme: ITheme }>`
    height: 4px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${(props) => colours[props.$theme].primary_20};
`

const KeyMomentCardDesktop = (props: Props) => {
    let { obj } = props

    let hasContent = () => {
        if (!obj.text || (obj.text && obj.text.length < 1)) {
            return false
        } else return true
    }

    const [isAddShow, setIsAddShow] = useState<boolean>(false)

    const { theme } = useThemes()

    let render = (): any => {
        if (hasContent()) {
            return (
                <Card
                    $isLast={props.isLast}
                    $isJourney={props.isJourney}
                    $theme={theme}
                >
                    <CardTopSection>
                        <NumberKeyWrapper>
                            <HorizLine $theme={theme} />
                            <NumberKey $theme={theme}>0{obj.order}</NumberKey>
                            <HorizLine $theme={theme} />
                        </NumberKeyWrapper>
                        <CardContent $theme={theme}>{obj.text}</CardContent>
                    </CardTopSection>
                    <HorizLine $theme={theme} />
                </Card>
            )
        } else if (!props.isReadOnly) {
            return (
                <Card
                    $theme={theme}
                    style={{
                        padding: 0,
                    }}
                    $isLast={props.isLast}
                    $isJourney={props.isJourney}
                    className={props.isLast ? 'last-item' : ''}
                >
                    <div
                        onMouseEnter={() => {
                            setIsAddShow(true)
                        }}
                        onMouseLeave={() => {
                            setIsAddShow(false)
                        }}
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        role="button"
                        onClick={() => {
                            if (!props.isReadOnly && props.onClick) {
                                props.onClick()
                            }
                        }}
                    >
                        {!isAddShow || props.isJourney ? (
                            <KMFplaceholder />
                        ) : (
                            <AddBox borderRadius="8px" />
                        )}
                    </div>
                </Card>
            )
        } else {
            return (
                <Card
                    $theme={theme}
                    $isLast={props.isLast}
                    $isJourney={props.isJourney}
                    className={props.isLast ? 'last-item' : ''}
                >
                    <KMFplaceholder />
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                    >
                        <CardContent
                            style={{ color: colours[theme].icons_neutral_250 }}
                            $theme={theme}
                        >
                            No data
                        </CardContent>
                    </div>
                </Card>
            )
        }
    }

    return render()
}

export default KeyMomentCardDesktop
