import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import RegisterForm from './registerForm'
import LeftAlignLogo from '../../atoms/icons/components/leftAlignLogo'

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    /* height: 100%; */

    @media (min-width: 700px) and (max-width: 1200px) {
        width: 100%;
        box-sizing: border-box;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
    }

    @media ${device.desktop} {
        padding-top: 10px;
    }

    @media (min-width: 1190px) and (max-width: 1600px) {
        padding-top: 30px;
    }

    @media (max-width: 1600px) {
        padding-bottom: 40px;
    }
`

const TopSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 700px) and (max-width: 1200px) {
        width: 46%;
        padding-left: 1vh;
        padding-right: 5vh;
        padding-top: 30px;
    }
`

const VerticalLine = styled.div`
    display: none;

    @media (min-width: 700px) and (max-width: 1200px) {
        display: block;
        background-color: var(--border-muted, #e5e5e5);
        width: 1px;
        height: 780px;
    }
`

const HorizontalLine = styled.div`
    display: none;
    @media (min-width: 700px) and (max-width: 1200px) {
        display: block;
        width: 16px;
        height: 3px;
        background-color: var(--text-strong, #1a1a1a);
    }
`

const FieldsSection = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 700px) and (max-width: 1200px) {
        width: 53%;
        padding-left: 5vh;
        padding-right: 1vh;
    }
`

const Instructions = styled.div`
    color: var(--text-strong, #1a1a1a);
    width: 100%;
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: left;
    font-size: 16px;

    @media ${device.mobile} {
        padding-top: 18px;
        padding-bottom: 30px;
    }

    @media ${device.desktop} {
        font-size: 13px;
        padding-top: 25px;
        padding-bottom: 15px;
    }

    @media ${device.large_desktop} {
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 16px;
        max-width: 310px;
    }
`

const LearnMore = styled.a`
    color: var(--primary, #5ec3ca) !important;
    /* font-style: italic; */
    font-family: 'Lato-Light';
    cursor: pointer;
    font-size: 14px;
    padding-left: 3px;
    transform: translateY(3px);
    padding-left: 8px;

    @media ${device.desktop} {
        font-size: 12px;
    }

    @media ${device.large_desktop} {
        font-size: 15px;
    }
`

type Props = {
    invite_code: any
}

const RegistrationFormCard = (props: Props) => {
    return (
        <FormContainer>
            {/* <TriangleHeaderLanding title="CUSTODIAN" /> */}
            <TopSection>
                <LeftAlignLogo size={32} />

                <Instructions>
                    Sign up for free and kick-start your digital garage. No
                    hidden costs, just pure car enthusiasm.
                    {/* <MobileDisplay>
                            <div style={{ paddingTop: '10px' }} />
                        </MobileDisplay> */}
                    <LearnMore
                        href="https://www.custodian.club"
                        target="_blank"
                        rel="noreferrer"
                        data-attr={'learn_more_registration'}
                    >
                        Learn more
                    </LearnMore>
                </Instructions>

                <HorizontalLine />
            </TopSection>
            <VerticalLine />
            <FieldsSection>
                <RegisterForm />
            </FieldsSection>
        </FormContainer>
    )
}

export default RegistrationFormCard
