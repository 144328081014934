import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import InsuranceHistoryFileJourneyStepDesktop from './insuranceDesktop'
import InsuranceHistoryFileJourneyStepMobile from './insuranceMobile'
import { useAppSelector } from '../../../../../../redux/store/hooks'
import { ITimelineItem } from '../../../../../../redux/timeline/types'

type Props = {
    onAddInsurance: (entry1?: ITimelineItem | undefined) => any
}

export const JourneyHistoryFileInsuranceStep = (props: Props) => {
    const [entry1, setEntry1] = useState<undefined | ITimelineItem>(undefined)

    const navigate = useNavigate()
    const location = useLocation()
    let locationState: any = location.state

    let params = new URLSearchParams(location.search)
    let carid: string | null = params.get('carid')

    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const currentCar =
        carsData && carid && carsData[carid] ? carsData[carid] : undefined
    const insurance_timeline_item =
        currentCar && currentCar.last_insurance_entry
            ? currentCar.last_insurance_entry
            : undefined

    useEffect(() => {
        setEntry1(insurance_timeline_item)
    }, [insurance_timeline_item])

    const hasInsuranceEntryWithMandatoryFields =
        insurance_timeline_item &&
        insurance_timeline_item.insurance_entry_policy_start_date &&
        insurance_timeline_item.insurance_entry_policy_end_date &&
        insurance_timeline_item.costUIDS &&
        insurance_timeline_item.costUIDS.length &&
        insurance_timeline_item.insurance_entry_underwriter
            ? true
            : false

    return (
        <div style={{ width: '100%' }}>
            <InsuranceHistoryFileJourneyStepDesktop
                entry1={entry1}
                setEntry1={setEntry1}
                step={3}
                totalSteps={3}
                goToPrevStep={() => {
                    locationState?.prevPath
                        ? navigate(locationState?.prevPath)
                        : navigate(
                              `/journey/history-file?step=add&carid=${carid}`
                          )
                }}
                goToNextStep={() => {
                    if (hasInsuranceEntryWithMandatoryFields) {
                        props.onAddInsurance()
                    } else {
                        props.onAddInsurance(entry1)
                    }
                }}
                hasInsuranceEntryWithMandatoryFields={
                    hasInsuranceEntryWithMandatoryFields
                }
            />
            <InsuranceHistoryFileJourneyStepMobile
                entry1={entry1}
                setEntry1={setEntry1}
                step={3}
                totalSteps={3}
                goToPrevStep={() => {
                    locationState?.prevPath
                        ? navigate(locationState?.prevPath)
                        : navigate(
                              `/journey/history-file?step=add&carid=${carid}`
                          )
                }}
                goToNextStep={() => {
                    if (hasInsuranceEntryWithMandatoryFields) {
                        props.onAddInsurance()
                    } else {
                        props.onAddInsurance(entry1)
                    }
                }}
                hasInsuranceEntryWithMandatoryFields={
                    hasInsuranceEntryWithMandatoryFields
                }
            />
        </div>
    )
}
