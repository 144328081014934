import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import {
    JourneyCardDesktop,
    JourneyCardMobile,
} from '../../../templates/styledcomponents/journeyStyles'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import UserPlainIcon from '../../../atoms/icons/components/userPlainIcon'
import placeholder1 from '../../../atoms/skeleton/gallery/cover_image.jpg'
import placeholder1_dark from '../../../atoms/skeleton/gallery/cover_image_dark.png'
import { device } from '../../../templates/displays/devices'
import UploadedImagePreview from '../../../atoms/image/uploadedImagePreview'
import JourneyImagesDropzone from '../../dropzone/journeyImagesDropZone'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import axios from 'axios'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import ImageUploadBtn from '../../../atoms/Button/imageUploadBtn'
import JourneyWrapperLeftSideDesktop from '../../../templates/wrappers/journeyPageWrapperLeftDesktop'
import JourneyPageWrapperMobile from '../../../templates/wrappers/journeyPageWrapperMobile'
import { ICarsObject } from '../../../../redux/entities/cars/types'
import { IGalleryImagesObject } from '../../../../redux/entities/galleries/types'
import { IUploadGalleryImagesPreSignedUrlsPayload } from '../../../../redux/filestorage/types'
import { IUser } from '../../../../redux/user/types'
import { fileStorageActions } from '../../../../redux/filestorage/reducer'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: (carid?: string) => void
    goToNextStep: (carid: string) => void
    carsData?: ICarsObject
    garageCars?: string[]
    galleryImages?: IGalleryImagesObject | null
    user: IUser | null
}

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`

const RightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
    height: 100%;
    @media ${device.smallest_laptops} {
        padding: 24px;
    }
`

const ColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`

const Text = styled.span<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    @media ${device.mobile_and_ipad} {
        font-size: 16px;
        line-height: 24px;
    }
`

const EnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

const RowContent = styled.div`
    display: flex;
    align-items: center;
`

const IconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

const CardContent = styled.section`
    display: flex;
    width: 592px;
    box-sizing: border-box;
    padding: 56px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    @media (max-width: 1200px) {
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
    }
    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
    }
`
const CardTitle = styled.h3<IStyle>`
    padding: 0;
    margin: 0;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 22px;
    font-family: Lato-semibold;
    line-height: 32px;
    @media ${device.mobile_and_ipad} {
        color: ${(props) => colours[props.$theme].text_strong};
        font-size: 16px;
        line-height: 24px;
    }
`

const CardSubtitle = styled.p<IStyle>`
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
    @media ${device.mobile_and_ipad} {
        color: ${(props) => colours[props.$theme].text_default};
        font-size: 14px;
        line-height: 20px;
    }
`

const CardImageWrapper = styled.div<{ $hasSelectedImage: boolean }>`
    height: ${(props) => (props.$hasSelectedImage ? '140px' : '80px')};
    width: 100%;
    box-sizing: border-box;
    position: relative;
    @media ${device.ipad} {
        height: ${(props) => (props.$hasSelectedImage ? '160px' : 'auto')};
    }
    @media ${device.mobile} {
        height: ${(props) => (props.$hasSelectedImage ? '120px' : '100px')};
    }
`

const ImageWrapper = styled.div`
    height: 140px;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    @media ${device.mobile_and_ipad} {
        width: 100%;
        box-sizing: border-box;
        height: 104px;
    }
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
`

const GreyWrapper = styled.div<IStyle>`
    display: flex;
    padding: 12px 0px;
    flex-direction: column;
    min-width: 0;
    border-radius: 24px;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
`

export default function AddPCarBannerStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    carsData,
    user,
}: Props) {
    const { theme } = useThemes()
    const [selectedImage, setSelectedImage] = useState<File | undefined>(
        undefined
    )

    const [imageUploadProgress, setUploadImageProgress] = useState<
        number | undefined
    >(undefined)

    let params = new URLSearchParams(location.search)
    let carid: string | null = params.get('carid')

    const dispatch = useAppDispatch()

    const gallerypresignedurls = useAppSelector(
        (state) => state.fileStorage.gallerypresignedurls
    )

    const upload_presignedurl_image_to_s3 = (filesToSend: File[]) => {
        filesToSend.length > 0 &&
            filesToSend.forEach(async (file: File, index: number) => {
                if (
                    file !== undefined &&
                    gallerypresignedurls &&
                    gallerypresignedurls[file.name]
                ) {
                    setUploadImageProgress(0)

                    return await axios
                        .request({
                            method: 'put',
                            url: gallerypresignedurls[file.name].url,
                            data: file,
                            onUploadProgress: (p: any) => {
                                setUploadImageProgress(
                                    Math.ceil((p.loaded / p.total) * 100)
                                )
                            },
                        })
                        .then((data: any) => {
                            setUploadImageProgress(100)
                            return
                        })
                        .catch((e: any) => {
                            apiSentryErrorHandler(
                                e,
                                'Upload presigned url car image to s3 error'
                            )
                            return Promise.reject(e)
                        })
                }
                // };
            })
    }

    useEffect(() => {
        if (
            gallerypresignedurls &&
            selectedImage &&
            gallerypresignedurls[selectedImage.name]
        ) {
            upload_presignedurl_image_to_s3([selectedImage])
        }
    }, [gallerypresignedurls])

    const onSubmit = (
        car_id: string,
        img: File,
        presignedurls: IUploadGalleryImagesPreSignedUrlsPayload
    ) => {
        if (car_id && presignedurls && presignedurls[img.name]) {
            let imgPayload = {
                carid: car_id,
                presignedurl: presignedurls[img.name],
                file: img,
                noToggle: true,
            }

            dispatch(
                fileStorageActions.submitCarCoverImageFilestorageRequest(
                    imgPayload
                )
            )
        }
    }

    const submitAndGoToNextStep = () => {
        if (carid) {
            if (selectedImage && gallerypresignedurls) {
                onSubmit(carid, selectedImage, gallerypresignedurls)
            }
            goToNextStep(carid)
        }
    }

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            submitAndGoToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [selectedImage, gallerypresignedurls, step, carid])

    const removeImage = () => {
        setSelectedImage(undefined)
        setUploadImageProgress(undefined)
    }

    let bannerImg =
        carsData && carid && carsData[carid] && carsData[carid].banner_url
            ? carsData[carid].banner_url
            : undefined

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <Wrapper>
                        <JourneyWrapperLeftSideDesktop
                            title={
                                bannerImg
                                    ? 'Change banner image'
                                    : 'Add banner image'
                            }
                            sectionID="car_profile"
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={() => {
                                carid && goToPrevStep(carid)
                            }}
                        >
                            <ImageWrapper>
                                {selectedImage ? (
                                    <GreyWrapper $theme={theme}>
                                        <ImageUploadBtn
                                            variant="image_preview"
                                            image={selectedImage}
                                            sectionID="car_profile"
                                            width="100%"
                                            height="80px"
                                            removeBg={true}
                                            removeImage={
                                                selectedImage &&
                                                imageUploadProgress === 100
                                                    ? () => removeImage()
                                                    : undefined
                                            }
                                        />
                                    </GreyWrapper>
                                ) : carid ? (
                                    <JourneyImagesDropzone
                                        carid={carid}
                                        handleFilesGiven={(files: File[]) => {
                                            files && setSelectedImage(files[0])
                                        }}
                                        hasCropper={true}
                                    />
                                ) : null}
                            </ImageWrapper>
                            <RowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={submitAndGoToNextStep}
                                    bg={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_500
                                    }
                                    isDisabled={!carid}
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>
                                {carid ? (
                                    <RowContent style={{ gap: 8 }}>
                                        <IconWrapper $theme={theme}>
                                            <EnterIcon />
                                        </IconWrapper>{' '}
                                        <EnterTxt $theme={theme}>
                                            Or press Enter
                                        </EnterTxt>
                                    </RowContent>
                                ) : null}
                            </RowContent>
                        </JourneyWrapperLeftSideDesktop>
                        <RightSide>
                            <JourneyCardDesktop
                                $theme={theme}
                                $customBorderRadius="24px"
                                $customWidth="600px"
                            >
                                <CardContent>
                                    <ColumnContent style={{ gap: 24 }}>
                                        <CardImageWrapper
                                            $hasSelectedImage={
                                                selectedImage || bannerImg
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {selectedImage ? (
                                                <UploadedImagePreview
                                                    currentImagesUploaded={
                                                        selectedImage
                                                    }
                                                    uploadProgress={
                                                        imageUploadProgress ?? 0
                                                    }
                                                />
                                            ) : bannerImg ? (
                                                <Image
                                                    src={bannerImg.large_src}
                                                    srcSet={
                                                        bannerImg.large_srcset
                                                    }
                                                />
                                            ) : (
                                                <Image
                                                    src={
                                                        theme === 'dark'
                                                            ? placeholder1_dark
                                                            : placeholder1
                                                    }
                                                />
                                            )}
                                        </CardImageWrapper>
                                        <ColumnContent style={{ gap: 8 }}>
                                            <ColumnContent style={{ gap: 6 }}>
                                                <CardTitle $theme={theme}>
                                                    {carid &&
                                                    carsData &&
                                                    carsData[carid]
                                                        ? carsData[carid].title
                                                        : 'Untitled car'}
                                                </CardTitle>
                                                <CardSubtitle $theme={theme}>
                                                    <IconWrapper
                                                        $theme={theme}
                                                        style={{
                                                            backgroundColor:
                                                                colours[theme]
                                                                    .background_neutral_overlay_02,
                                                            border: `1px solid ${colours[theme].background_neutral_overlay_40}`,
                                                            borderRadius: 6,
                                                        }}
                                                    >
                                                        <UserPlainIcon
                                                            size="16"
                                                            color={
                                                                colours[theme]
                                                                    .text_neutral_default
                                                            }
                                                        />
                                                    </IconWrapper>
                                                    {`Owned by ${user?.display_name}`}
                                                </CardSubtitle>
                                            </ColumnContent>
                                            <Text $theme={theme}>
                                                Unmistakable in Lime Green, the
                                                Porsche 911 is a symbol of
                                                performance and style. With its
                                                iconic design and legendary
                                                heritage, it's more than just a
                                                car; it's a statement of
                                                sophistication and exhilarating
                                                drives.
                                            </Text>
                                        </ColumnContent>
                                    </ColumnContent>
                                </CardContent>
                            </JourneyCardDesktop>
                        </RightSide>
                    </Wrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyPageWrapperMobile
                    title={
                        bannerImg ? 'Change banner image' : 'Add banner image'
                    }
                    sectionID="car_profile"
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        carid && goToPrevStep(carid)
                    }}
                    onNext={submitAndGoToNextStep}
                >
                    <ColumnContent
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        <JourneyCardMobile
                            $theme={theme}
                            $customBorderRadius="24px"
                        >
                            <CardContent
                                style={{
                                    gap: 24,
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <CardImageWrapper
                                    $hasSelectedImage={
                                        selectedImage ? true : false
                                    }
                                >
                                    {selectedImage ? (
                                        <UploadedImagePreview
                                            currentImagesUploaded={
                                                selectedImage
                                            }
                                            uploadProgress={
                                                imageUploadProgress ?? 0
                                            }
                                        />
                                    ) : carid ? (
                                        <JourneyImagesDropzone
                                            carid={carid}
                                            handleFilesGiven={(files) => {
                                                files &&
                                                    setSelectedImage(files[0])
                                            }}
                                            isMobile
                                            isSingleImg
                                            customBorderRadius="4px"
                                            hasCropper={true}
                                        />
                                    ) : null}
                                </CardImageWrapper>
                                <ColumnContent style={{ gap: 8 }}>
                                    <ColumnContent style={{ gap: 6 }}>
                                        <CardTitle $theme={theme}>
                                            {carid &&
                                            carsData &&
                                            carsData[carid]
                                                ? carsData[carid].title
                                                : 'Untitled car'}
                                        </CardTitle>
                                        <CardSubtitle $theme={theme}>
                                            <IconWrapper
                                                $theme={theme}
                                                style={{
                                                    backgroundColor:
                                                        colours[theme]
                                                            .background_neutral_overlay_02,
                                                    border: `1px solid ${colours[theme].background_neutral_overlay_40}`,
                                                    borderRadius: 6,
                                                }}
                                            >
                                                <UserPlainIcon
                                                    size="16"
                                                    color={
                                                        colours[theme]
                                                            .text_neutral_default
                                                    }
                                                />
                                            </IconWrapper>
                                            {`Owned by ${user?.display_name}`}
                                        </CardSubtitle>
                                    </ColumnContent>
                                    <Text $theme={theme}>
                                        Unmistakable in Lime Green, the Porsche
                                        911 is a symbol of performance and
                                        style. With its iconic design and
                                        legendary heritage, it's more than just
                                        a car; it's a statement of
                                        sophistication and exhilarating drives.
                                    </Text>
                                </ColumnContent>
                            </CardContent>
                        </JourneyCardMobile>
                    </ColumnContent>
                </JourneyPageWrapperMobile>
            </IpadAndMobileDisplay>
        </div>
    )
}
