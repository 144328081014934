import * as React from 'react'
import { useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import SmallTimelineItemMobile from '../../molecules/smallTimelineItem/smallTimelineItemMobile'
import { Swiper, SwiperSlide } from 'swiper/react'
import './styles.css'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import skeletonMobile from '../../atoms/skeleton/historyFile/skeletonMobile.jpg'
import skeletonMobileDark from '../../atoms/skeleton/historyFile/skeletonMobileDark.png'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import CarInsightsBtn from '../../atoms/carInsights/btn'
import colours, {
    history_file_colours,
    ITheme,
} from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import { INormalisedAttachmentsByID } from '../../../redux/attachments/types'
import { IExternalCar, ICar } from '../../../redux/entities/cars/types'
import { ITimelineItem } from '../../../redux/timeline/types'

type IStyle = {
    $theme: ITheme
    $color?: string
}

const Wrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    align-self: center;
    justify-self: center;
`
const OverWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
`

const SmallItemOverWrapper = styled.section`
    width: 100%;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
    align-self: center;
    justify-self: center;
`

const LineContainer = styled.div`
    width: 93vw;
    position: relative;
`

const Line = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 15px;
    height: 7px;
    background-color: ${(props) => colours[props.$theme].background_muted};
`

const Buttons = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 40px;
    padding-right: 40px;
`

// const ItemCount = styled(Link)`
//     height: 26px;
//     padding: 6px;
//     background-color: var(--bg-color, #fff);
//     color: #aeaeae;
//     font-size: 12px;
//     border: 1px solid #5ec3ca;
//     border-radius: 5px;
//     position: absolute;
//     top: 25px;
//     right: 0;
//     display: flex;
//     align-items: center;
//     padding-right: 10px;
//     padding-left: 10px;
// `
const EmptyBtn = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

const LineProgress = styled.div<IStyle>`
    position: relative;
    width: 22px;
    height: 36px;
    border: 7px solid ${(props) => colours[props.$theme].background_default};
    background-color: ${(props) =>
        props.$color ? props.$color : colours[props.$theme].border_muted};
    border-radius: 10px;
    opacity: 1;
    transform: none !important;
    left: auto !important;
`

interface Props {
    entries_array: ITimelineItem[] | undefined | null
    attachmentsObj?: INormalisedAttachmentsByID
    onClick?: any
    carid: string
    onPlaceholderClick: any
    car_entries_count: number
    userCurrency: string | undefined
    readOnlyMode?: boolean
    sharedWithYouCar?: IExternalCar
    ownedCar?: ICar
}

const TimelineOverviewManagerMobile: React.FC<Props> = ({
    entries_array,
    carid,
    onPlaceholderClick,
    car_entries_count,
    userCurrency,
    readOnlyMode,
    sharedWithYouCar,
}) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const paginationRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const location = useLocation()
    const { theme } = useThemes()

    const checkIfInfoIsRestricted = useCallback(() => {
        if (
            readOnlyMode &&
            sharedWithYouCar &&
            sharedWithYouCar.policy_statements
        ) {
            if (!sharedWithYouCar.policy_statements.includes('history file')) {
                return true
            }
        }
        return false
    }, [readOnlyMode, sharedWithYouCar])

    const linkPrefix = location.pathname.includes('overview')
        ? location.pathname.replace('/overview', '')
        : location.pathname

    return (
        <OverWrapper>
            <LineContainer>
                <Line $theme={theme} />
                <Buttons>
                    <div
                        style={{
                            position: 'absolute',
                        }}
                        ref={paginationRef}
                        id="timeline-overview-carousel"
                    />
                    <div
                        style={{
                            opacity: entries_array && entries_array[0] ? 1 : 0,
                            zIndex: entries_array && entries_array[0] ? 1 : 0,
                        }}
                    >
                        <LineProgress
                            $theme={theme}
                            onClick={() => {}}
                            $color={
                                !entries_array
                                    ? undefined
                                    : entries_array &&
                                      entries_array[activeIndex] &&
                                      activeIndex === 0
                                    ? history_file_colours[theme]?.categories[
                                          entries_array[activeIndex]
                                              .categoryID as keyof (typeof history_file_colours)[typeof theme]['categories']
                                      ]?.primary_100
                                    : undefined
                            }
                        />
                    </div>
                    <div
                        style={{
                            opacity:
                                !entries_array ||
                                entries_array.length === 0 ||
                                entries_array[1]
                                    ? 1
                                    : 0,
                            zIndex:
                                !entries_array ||
                                entries_array.length === 0 ||
                                entries_array[1]
                                    ? 'auto'
                                    : -1,
                        }}
                    >
                        <LineProgress
                            $theme={theme}
                            onClick={() => {}}
                            $color={
                                !entries_array || entries_array.length === 0
                                    ? undefined
                                    : entries_array &&
                                      entries_array[activeIndex] &&
                                      activeIndex === 1
                                    ? history_file_colours[theme]?.categories[
                                          entries_array[activeIndex]
                                              .categoryID as keyof (typeof history_file_colours)[typeof theme]['categories']
                                      ]?.primary_100
                                    : undefined
                            }
                        />
                    </div>
                    <div
                        style={{
                            opacity: entries_array && entries_array[2] ? 1 : 0,
                            zIndex: entries_array && entries_array[2] ? 1 : 0,
                        }}
                    >
                        <LineProgress
                            $theme={theme}
                            onClick={() => {}}
                            $color={
                                !entries_array
                                    ? undefined
                                    : entries_array &&
                                      entries_array[activeIndex] &&
                                      activeIndex === 2
                                    ? history_file_colours[theme]?.categories[
                                          entries_array[activeIndex]
                                              .categoryID as keyof (typeof history_file_colours)[typeof theme]['categories']
                                      ]?.primary_100
                                    : undefined
                            }
                        />
                    </div>
                </Buttons>

                <div
                    style={{
                        paddingTop: '50px',
                    }}
                />

                <Wrapper>
                    {(!entries_array ||
                        car_entries_count === 0 ||
                        entries_array.length === 0) &&
                        (!readOnlyMode ? (
                            <div
                                onClick={() => onPlaceholderClick()}
                                style={{
                                    position: 'relative',
                                    height: '100%',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <EmptyBtn>
                                    <ButtonAtom
                                        width={'250px'}
                                        theme={'lowercase-blue-background'}
                                        fontSize={14}
                                        onClick={() => {}}
                                    >
                                        <div
                                            style={{
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                textTransform: 'none',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Create your car's digital history
                                        </div>
                                    </ButtonAtom>
                                </EmptyBtn>

                                <img
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        height: '180px',
                                    }}
                                    src={
                                        theme === 'light'
                                            ? skeletonMobile
                                            : skeletonMobileDark
                                    }
                                    alt="skeleton"
                                />
                            </div>
                        ) : (
                            <NoDataRectangle
                                text="no data"
                                isInfoRestricted={checkIfInfoIsRestricted()}
                            />
                        ))}

                    {entries_array &&
                        entries_array.length > 0 &&
                        car_entries_count > 0 && (
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={1}
                                loop={true}
                                direction={'horizontal'}
                                pagination={{
                                    el: paginationRef.current,
                                    clickable: true,
                                    dynamicBullets: true,
                                    bulletClass:
                                        'timeline-overview-blue-bullet-class',
                                    bulletActiveClass:
                                        'timeline-overview-active-bullet-class',
                                }}
                                onBeforeInit={(swiper) => {
                                    if (paginationRef.current) {
                                        swiper.params.pagination = {
                                            ...(swiper.params
                                                .pagination as object),
                                            el: paginationRef.current,
                                        }
                                    }
                                }}
                                scrollbar={{ draggable: true }}
                                onSlideChange={(swiper) => {
                                    setActiveIndex(swiper.realIndex)
                                    swiper.allowTouchMove =
                                        entries_array.length > 1
                                }}
                            >
                                {entries_array &&
                                entries_array.length > 0 &&
                                car_entries_count > 0
                                    ? entries_array.map(
                                          (
                                              item: ITimelineItem,
                                              index: number
                                          ) =>
                                              index < 3 && (
                                                  <SwiperSlide key={index}>
                                                      <SmallItemOverWrapper>
                                                          <SmallTimelineItemMobile
                                                              item={item}
                                                              iscaroverview
                                                              onClick={() =>
                                                                  navigate(
                                                                      `${linkPrefix}/history-file/entry?entryid=${item?.id}`
                                                                  )
                                                              }
                                                              onAddNewLabelClick={() =>
                                                                  navigate(
                                                                      `/car/${carid}/history-file/entry?entryid=${item.id}&section_id=labels`
                                                                  )
                                                              }
                                                              userCurrency={
                                                                  userCurrency
                                                              }
                                                          />
                                                      </SmallItemOverWrapper>
                                                  </SwiperSlide>
                                              )
                                      )
                                    : null}
                            </Swiper>
                        )}
                </Wrapper>
            </LineContainer>

            <div
                style={{
                    paddingTop: '32px',
                }}
            />

            {readOnlyMode ? null : <CarInsightsBtn />}

            <div
                style={{
                    paddingTop: '150px',
                }}
            />
        </OverWrapper>
    )
}

export default TimelineOverviewManagerMobile
