import { Formik, FormikHelpers, FormikProps } from 'formik'
import { SignUpSchema } from './SignInSchema'
import InputFieldLandingPage from '../../atoms/Inputfield/inputFieldLandingPage'
import Faded from '../../templates/animated/faded'
import Expander from '../../atoms/expander/expander'
import { Link, useLocation } from 'react-router-dom'
import StyledFormError from '../../templates/styledcomponents/styledformerrormessage'

import {
    FormContainer,
    Padding,
    BottomSectionSignIn,
    BlueSpan,
    NotRegisteredDiv,
} from '../../templates/styledcomponents/landingPagesFormStyles'
import LandingPageMainButton from '../../atoms/Button/landingPageMainButton'
import AppleSignInButton from '../../atoms/Button/socialAuth/apple/appleSigninButton'
import { GoogleAuthButton } from '../../atoms/Button/socialAuth/google'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IRootState } from '../../../redux/store'
import { usersActions } from '../../../redux/user/reducer'

interface Values {
    email: string
    password: string
}

const SignupForm = () => {
    const dispatch = useAppDispatch()

    let location = useLocation()
    let params = new URLSearchParams(location.search)
    let inputerror = useAppSelector((state: IRootState) => state.user.error)

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
            ) => {
                if (document.getElementById('paginator') !== null) {
                    document.getElementById('paginator')!.style.display = 'none'
                }

                dispatch(
                    usersActions.signInUserRequest({
                        email: values.email,
                        password: values.password,
                    })
                )
                setSubmitting(false)
            }}
            validationSchema={SignUpSchema}
        >
            {(formikprops: FormikProps<Values>) => (
                <FormContainer>
                    <InputFieldLandingPage
                        id="email"
                        name="email"
                        fieldLabel="your email"
                        type="email"
                        formikprops={formikprops}
                        onChange={(e: any) => {
                            formikprops.handleChange(e)
                            inputerror !== null &&
                                dispatch(usersActions.resetErrorStateSuccess())
                        }}
                        tabindex={1}
                        aria-invalid={
                            formikprops.errors.email || inputerror !== null
                                ? 'true'
                                : 'false'
                        }
                        aria-live="polite"
                        dataCyId={'signin-form-email-input-universal'}
                        width="100%"
                        fontSize="16px"
                    />

                    <Expander
                        height={
                            formikprops.errors.email &&
                            formikprops.touched.email
                                ? 'auto'
                                : 0
                        }
                        dataCyId={
                            'signin-form-expander-error-invalidemail-universal'
                        }
                    >
                        <Faded>
                            <StyledFormError>
                                {formikprops.errors.email}
                            </StyledFormError>
                        </Faded>
                    </Expander>

                    <Padding>
                        <InputFieldLandingPage
                            id="password"
                            name="password"
                            fieldLabel="password"
                            type="password"
                            formikprops={formikprops}
                            tabindex={2}
                            dataCyId={
                                'signin-form-userPassword-input-universal'
                            }
                            width="100%"
                        />

                        <Expander
                            height={
                                formikprops.errors.password &&
                                formikprops.touched.password
                                    ? 'auto'
                                    : 0
                            }
                            dataCyId={
                                'signin-form-expander-error-password-universal'
                            }
                        >
                            <Faded>
                                <StyledFormError>
                                    {formikprops.errors.password}
                                </StyledFormError>
                            </Faded>
                        </Expander>
                    </Padding>

                    <Padding>
                        <Expander
                            height={
                                inputerror !== null &&
                                formikprops.touched &&
                                formikprops.values.email !== '' &&
                                formikprops.values.password !== ''
                                    ? 'auto'
                                    : 0
                            }
                            dataCyId={
                                'signin-form-expander-error-invalidEmailPassword-universal'
                            }
                        >
                            <Faded>
                                <StyledFormError>
                                    Invalid email or password.
                                </StyledFormError>
                            </Faded>
                        </Expander>
                    </Padding>

                    <BottomSectionSignIn>
                        <LandingPageMainButton
                            type="submit"
                            disabled={
                                formikprops.isSubmitting ||
                                !!(
                                    formikprops.errors.email &&
                                    formikprops.touched.email
                                ) ||
                                !!(
                                    formikprops.errors.password &&
                                    formikprops.touched.password
                                )
                            }
                            aria-live="polite"
                            dataCyId={'signin-form-submit-universal'}
                            // normal_height={'46px'}
                            // desktop_height={'50px'}
                            // wide_screen_height={'54px'}
                        >
                            Sign In
                        </LandingPageMainButton>

                        <div style={{ marginTop: '8px' }} />
                        <AppleSignInButton
                            next_path={
                                params.get('next') &&
                                typeof params.get('next') === 'string'
                                    ? `${params.get('next')}`
                                    : undefined
                            }
                            fbclid={
                                params.get('fbclid') &&
                                typeof params.get('fbclid') === 'string'
                                    ? `${params.get('fbclid')}`
                                    : undefined
                            }
                            is_registration={false}
                        />

                        <div style={{ marginTop: '5px' }} />

                        <GoogleAuthButton
                            next_path={
                                params.get('next') &&
                                typeof params.get('next') === 'string'
                                    ? `${params.get('next')}`
                                    : undefined
                            }
                            fbclid={
                                params.get('fbclid') &&
                                typeof params.get('fbclid') === 'string'
                                    ? `${params.get('fbclid')}`
                                    : undefined
                            }
                            is_registration={false}
                        />

                        <NotRegisteredDiv>
                            <div>
                                Forgotten password? &nbsp;&nbsp;
                                <Link
                                    to="/reset-password-request"
                                    data-att={'reset-password-button'}
                                >
                                    <BlueSpan>Reset here</BlueSpan>
                                </Link>
                            </div>
                            <div style={{ paddingTop: '10px' }}>
                                Not registered? &nbsp;&nbsp;
                                <Link to="/register">
                                    <BlueSpan data-attr="register_span_link_redirect">
                                        Sign up
                                    </BlueSpan>
                                </Link>
                            </div>
                        </NotRegisteredDiv>
                    </BottomSectionSignIn>
                </FormContainer>
            )}
        </Formik>
    )
}

export default SignupForm
