import { useState, useEffect } from 'react'
import dayjs from 'dayjs'

const ua =
    !!window && window.navigator && window.navigator.userAgent
        ? window.navigator.userAgent
        : undefined

// const webkit = ua ? !!ua.match(/WebKit/i) : undefined
const isIPad = ua
    ? !!ua.match(/Mac/i) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2
    : undefined

const isIPhone = ua ? !!ua.match(/iPhone/i) : undefined
const isIOS = isIPad || isIPhone

let isLocalStorageSupported =
    typeof localStorage !== 'undefined' && localStorage !== null ? true : false

export function checkForIOS() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
        return false
    }
    const today = dayjs().toDate()
    const lastPrompt = isLocalStorageSupported
        ? localStorage
            ? dayjs(localStorage.getItem('installPrompt'), 'YYYY-MM-DD')
            : undefined
        : undefined

    const days = lastPrompt ? dayjs(today).diff(lastPrompt, 'day') : undefined

    // const isSafari = ua ? isIOS || webkit && !ua.match(/CriOS/i) : undefined

    const isSafari =
        ua && !ua.match(/Chrome/i) && (isIOS || ua.match(/Safari/i))
            ? true
            : undefined

    const prompt =
        days && ua ? (isNaN(days) || days > 30) && isIOS && isSafari : undefined

    if (!!window && prompt && typeof localStorage !== 'undefined') {
        localStorage.setItem('installPrompt', today.toString())
    }

    return { isIOS, isSafari, prompt }
}

export default function useIsIosPwaCheck() {
    const [isIOS, setIsIOS] = useState({})

    useEffect(() => {
        setIsIOS(checkForIOS())
        return
    }, [])

    return isIOS
}
