import styled from 'styled-components'
import {
    IFilterID,
    IShowroomFiltersState,
} from '../../../../redux/showroom/types'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import chevron_to_left from '../../../../public/assets/navigation/chevron_to_left.svg'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { showroomActions } from '../../../../redux/showroom/reducer'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { SearchIcon } from '../../../atoms/statefulicons'
import ShowroomFiltersFormManagerMobile from '../../../organisms/searchbar/showroom/filterManager/showroomFilterFormManagerMobile'
import FadedSlower from '../../../templates/animated/FadedSlower'
import partner_badge from '../../../../public/assets/icons/partner_badge.svg'
import close_cross_grey from '../../../../public/assets/icons/close_cross_grey.svg'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const PageWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
`
const Header = styled.div`
    font-family: Lato-Bold;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.005em;
    color: #1a1a1a;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
`

const Clear = styled.button`
    all: unset;
    color: #5ec3ca;
    font-size: 16px;
    /* font-weight: 600; */
    font-family: Lato-Semibold;
`

const Divider = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: #e5e5e5;
`

const TopBar = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 64px;
    position: fixed;
    background-color: var(--bg-color, #fff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
    padding-left: 24px;
    padding-right: 24px;
`

const EndBtn = styled.div`
    letter-spacing: 5e-5em;
    font-family: Lato;
    font-size: 16px;
    color: #1a1a1a;
`
const ReturnBtn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Lato;
    font-size: 18px;
`

const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(0%) contrast(84%);
    height: 13px;
`

const FixedBtn = styled.div`
    position: fixed;
    bottom: 30px;
    left: 30px;
    right: 30px;
    margin-left: auto;
    margin-right: auto;
`

const OwnerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    gap: 10px;
    height: 72px;
    background: #fafafa;
    position: absolute;
    left: 0px;
    right: 0px;
`
const OwnerInnerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const OwnerName = styled.div`
    letter-spacing: 5e-5em;
    font-family: 'Lato';
    color: #1a1a1a;
    font-size: 16px;
`

const ShowroomFiltersMobile = () => {
    const car_details_id_array: IFilterID[] = ['make', 'model', 'year']

    const car_advanced_id_array: IFilterID[] = [
        'mileage',
        'keyword',
        'body_type',
        'colour',
        'drive_side',
    ]
    const dispatch = useAppDispatch()

    let filtersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.editingFilters
    })

    let activeFiltersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.filters
    })

    const navigate = useNavigate()

    const location = useLocation()

    let params = new URLSearchParams(location.search)
    let soldParams: any = params.get('sold')
    let owner_display_name: any = params.get('owner_display_name')
    let owner_id: any = params.get('owner_id')
    let areDetailsFilterActive = (): boolean => {
        let ids = ['make', 'model', 'year']

        let res: boolean[] = []
        ids.forEach((id) => {
            let isTr = Object.values(filtersState).some(
                (val) => val.isActive === true
            )
            res = [...res, isTr]
        })

        if (res.includes(true)) {
            return true
        } else return false
    }

    let isThereActiveFilter = Object.values(filtersState).some(
        (val) => val.isActive === true
    )

    let isThereExistingActiveFilter = Object.values(activeFiltersState).some(
        (val) => val.isActive === true
    )

    let filterValidationError = useAppSelector(
        (state) => state.showroom.filterValidationError
    )

    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)

    useEffect(() => {
        dispatch(showroomActions.setCurrentPageNumber_sold(0))
        dispatch(showroomActions.setCurrentPageNumber_sale(0))

        dispatch(
            showroomActions.searchShowroom_setCars({
                cars: undefined,
                resultsFoundNumber: undefined,
                similarSoldCarsNumber: undefined,
                type: soldParams ? 'sold' : 'sale',
            })
        )

        dispatch(
            showroomActions.searchShowroomRequest({
                states: soldParams ? 'SOLD' : 'PUBLISHED',
                only_for_filter_results: true,
            })
        )

        return () => {
            dispatch(showroomActions.setFilterValidationError(undefined))
        }
    }, [])

    return (
        <>
            <IpadAndMobileDisplay>
                <PageWrapper>
                    <TopBar>
                        <ReturnBtn
                            onClick={() => {
                                if (!hasSubmitted) {
                                    dispatch(
                                        showroomActions.cancelShowroomFilters()
                                    )
                                }

                                dispatch(
                                    showroomActions.searchShowroomRequest({
                                        states: soldParams
                                            ? 'SOLD'
                                            : 'PUBLISHED',
                                        // only_for_filter_results: true,
                                    })
                                )

                                if (soldParams) {
                                    if (owner_display_name && owner_id) {
                                        navigate(
                                            `/showroom/sold?owner_display_name=${owner_display_name}&owner_id=${owner_id}`
                                        )
                                    } else {
                                        navigate(`/showroom/sold`)
                                    }
                                } else {
                                    if (owner_display_name && owner_id) {
                                        navigate(
                                            `/showroom/for-sale?owner_display_name=${owner_display_name}&owner_id=${owner_id}`
                                        )
                                    } else navigate('/showroom/for-sale')
                                }
                            }}
                        >
                            <Chevron src={chevron_to_left} />
                            <div style={{ paddingLeft: '20px' }} />
                            <div>Filters</div>
                        </ReturnBtn>
                        <EndBtn
                            role="button"
                            onClick={() => {
                                if (isThereActiveFilter) {
                                    dispatch(showroomActions.resetAllFilters())

                                    dispatch(
                                        showroomActions.setCurrentPageNumber_sold(
                                            0
                                        )
                                    )
                                    dispatch(
                                        showroomActions.setCurrentPageNumber_sale(
                                            0
                                        )
                                    )

                                    dispatch(
                                        showroomActions.searchShowroom_setCars({
                                            cars: undefined,
                                            resultsFoundNumber: undefined,
                                            similarSoldCarsNumber: undefined,
                                            type: 'sold',
                                        })
                                    )

                                    dispatch(
                                        showroomActions.searchShowroom_setCars({
                                            cars: undefined,
                                            resultsFoundNumber: undefined,
                                            similarSoldCarsNumber: undefined,
                                            type: 'sale',
                                        })
                                    )
                                    dispatch(
                                        showroomActions.searchShowroomRequest({
                                            states: soldParams
                                                ? 'SOLD'
                                                : 'PUBLISHED',
                                            // only_for_filter_results: true,
                                        })
                                    )

                                    setTimeout(() => {
                                        if (soldParams) {
                                            navigate('/showroom/sold')
                                        } else navigate('/showroom')
                                    }, 200)
                                }
                            }}
                        >
                            Clear All
                        </EndBtn>
                    </TopBar>

                    <div
                        style={{
                            paddingTop: '64px',
                        }}
                    />

                    {filtersState.owner && filtersState.owner.isActive && (
                        <FadedSlower>
                            <OwnerRow>
                                <OwnerInnerRow>
                                    <img src={partner_badge} alt="partner" />

                                    <div style={{ paddingLeft: '10px' }} />
                                    <OwnerName>
                                        {filtersState.owner &&
                                            filtersState.owner.displayValue}
                                    </OwnerName>
                                </OwnerInnerRow>
                                <img
                                    onClick={() => {
                                        dispatch(
                                            showroomActions.removeFilterRequest(
                                                {
                                                    //@ts-ignore
                                                    id: 'owner',
                                                    direct_search: false,
                                                }
                                            )
                                        )

                                        dispatch(
                                            showroomActions.searchShowroomRequest(
                                                {
                                                    states: soldParams
                                                        ? 'SOLD'
                                                        : 'PUBLISHED',
                                                    only_for_filter_results:
                                                        true,
                                                }
                                            )
                                        )
                                    }}
                                    src={close_cross_grey}
                                    alt="dismiss dealer filter"
                                />
                            </OwnerRow>
                        </FadedSlower>
                    )}

                    <div
                        style={{
                            paddingTop:
                                filtersState.owner &&
                                filtersState.owner.isActive
                                    ? '100px'
                                    : '26px',
                        }}
                    />

                    <Header>
                        <div>Car Details</div>
                        <Clear
                            onClick={() => {
                                if (areDetailsFilterActive()) {
                                    dispatch(
                                        showroomActions.removeFilterRequest({
                                            id: 'make',
                                        })
                                    )
                                    dispatch(
                                        showroomActions.removeFilterRequest({
                                            id: 'model',
                                        })
                                    )
                                    dispatch(
                                        showroomActions.removeFilterRequest({
                                            id: 'year',
                                        })
                                    )
                                    dispatch(
                                        showroomActions.searchShowroomRequest({
                                            states: soldParams
                                                ? 'SOLD'
                                                : 'PUBLISHED',
                                            only_for_filter_results: true,
                                        })
                                    )
                                }
                            }}
                        >
                            Clear
                        </Clear>
                    </Header>
                    <div style={{ paddingTop: '10px' }} />
                    {car_details_id_array.map(
                        (id: IFilterID, index: number) => {
                            return (
                                <ShowroomFiltersFormManagerMobile elemID={id} />
                            )
                        }
                    )}
                    <div style={{ paddingTop: '30px' }} />
                    <Divider />
                    <div style={{ paddingTop: '30px' }} />

                    <Header>
                        <div>
                            Price Range
                            <span
                                style={{
                                    fontSize: '12px',
                                    fontFamily: 'Lato-Light',
                                    paddingLeft: '8px',
                                }}
                            >
                                GBP
                            </span>
                        </div>
                        <Clear
                            onClick={() => {
                                if (filtersState.price.isActive) {
                                    dispatch(
                                        showroomActions.removeFilterRequest({
                                            id: 'price',
                                        })
                                    )

                                    dispatch(
                                        showroomActions.searchShowroomRequest({
                                            states: soldParams
                                                ? 'SOLD'
                                                : 'PUBLISHED',
                                            only_for_filter_results: true,
                                        })
                                    )
                                }
                            }}
                        >
                            Clear
                        </Clear>
                    </Header>
                    <div style={{ paddingTop: '20px' }} />

                    <ShowroomFiltersFormManagerMobile elemID={'price'} />

                    <div style={{ paddingTop: '30px' }} />
                    <Header>
                        <div>Type of sale</div>{' '}
                        <Clear
                            onClick={() => {
                                if (filtersState.type_of_sale.isActive) {
                                    dispatch(
                                        showroomActions.removeFilterRequest({
                                            id: 'type_of_sale',
                                        })
                                    )
                                    dispatch(
                                        showroomActions.searchShowroomRequest({
                                            states: soldParams
                                                ? 'SOLD'
                                                : 'PUBLISHED',
                                            only_for_filter_results: true,
                                        })
                                    )
                                }
                            }}
                        >
                            Clear
                        </Clear>
                    </Header>
                    <div style={{ paddingTop: '20px' }} />

                    <ShowroomFiltersFormManagerMobile elemID={'type_of_sale'} />

                    <div style={{ paddingTop: '30px' }} />
                    <Divider />
                    <div style={{ paddingTop: '30px' }} />

                    <Header>
                        <div>Location</div>
                        <Clear
                            onClick={() => {
                                if (filtersState.location.isActive) {
                                    dispatch(
                                        showroomActions.removeFilterRequest({
                                            id: 'location',
                                        })
                                    )
                                    dispatch(
                                        showroomActions.searchShowroomRequest({
                                            states: soldParams
                                                ? 'SOLD'
                                                : 'PUBLISHED',
                                            only_for_filter_results: true,
                                        })
                                    )
                                }
                            }}
                        >
                            Clear
                        </Clear>
                    </Header>
                    <div style={{ paddingTop: '20px' }} />

                    <ShowroomFiltersFormManagerMobile elemID={'location'} />

                    <div style={{ paddingTop: '30px' }} />
                    <Header>
                        <div>Advanced</div>
                        <Clear
                            onClick={() => {
                                dispatch(
                                    showroomActions.removeFilterRequest({
                                        id: 'mileage',
                                    })
                                )

                                dispatch(
                                    showroomActions.removeFilterRequest({
                                        id: 'body_type',
                                    })
                                )

                                dispatch(
                                    showroomActions.removeFilterRequest({
                                        id: 'colour',
                                    })
                                )

                                dispatch(
                                    showroomActions.removeFilterRequest({
                                        id: 'drive_side',
                                    })
                                )

                                if (
                                    filtersState.drive_side.isActive ||
                                    filtersState.colour.isActive ||
                                    filtersState.body_type.isActive ||
                                    filtersState.mileage.isActive
                                ) {
                                    dispatch(
                                        showroomActions.searchShowroomRequest({
                                            states: soldParams
                                                ? 'SOLD'
                                                : 'PUBLISHED',
                                            only_for_filter_results: true,
                                        })
                                    )
                                }
                            }}
                        >
                            Clear
                        </Clear>
                    </Header>
                    <div style={{ paddingTop: '20px' }} />

                    {car_advanced_id_array.map(
                        (id: IFilterID, index: number) => {
                            return (
                                <div
                                    style={{ width: '100%' }}
                                    key={`${index}-mobile`}
                                >
                                    <ShowroomFiltersFormManagerMobile
                                        elemID={id}
                                    />
                                    <div style={{ paddingTop: '10px' }} />
                                </div>
                            )
                        }
                    )}
                    <div style={{ paddingTop: '30px' }} />
                    <FixedBtn>
                        <ButtonAtom
                            theme="wide-mobile-blue-bg"
                            height="60px"
                            width="100%"
                            disabled={
                                !isThereExistingActiveFilter &&
                                !isThereActiveFilter
                                    ? true
                                    : filterValidationError &&
                                      Object.keys(filterValidationError)
                                          .length > 0
                                    ? true
                                    : false
                            }
                            onClick={() => {
                                setHasSubmitted(true)
                                dispatch(
                                    showroomActions.setCurrentPageNumber_sold(0)
                                )
                                dispatch(
                                    showroomActions.setCurrentPageNumber_sale(0)
                                )

                                dispatch(
                                    showroomActions.searchShowroom_setCars({
                                        cars: undefined,
                                        resultsFoundNumber: undefined,
                                        similarSoldCarsNumber: undefined,
                                        type: 'sale',
                                    })
                                )

                                dispatch(
                                    showroomActions.searchShowroom_setCars({
                                        cars: undefined,
                                        resultsFoundNumber: undefined,
                                        similarSoldCarsNumber: undefined,
                                        type: 'sold',
                                    })
                                )

                                dispatch(
                                    showroomActions.searchShowroomRequest({
                                        states: soldParams
                                            ? 'SOLD'
                                            : 'PUBLISHED',
                                    })
                                )
                                setTimeout(() => {
                                    if (soldParams) {
                                        navigate('/showroom/sold')
                                    } else navigate('/showroom')
                                }, 200)
                            }}
                        >
                            <SearchIcon height={16} color="white" />
                            <span style={{ paddingLeft: '10px' }} />
                            Search
                        </ButtonAtom>
                    </FixedBtn>
                </PageWrapper>
                <div style={{ paddingBottom: '150px' }} />
            </IpadAndMobileDisplay>
        </>
    )
}

export default ShowroomFiltersMobile
