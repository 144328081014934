import React, { useEffect } from 'react'
import styled from 'styled-components'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import { IThreeDotsActionsItems } from '../../atoms/menu/actionsMenu/actionsMenuDots'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import DarkThemeIcon from '../../atoms/icons/themeIcons/dark'
import LightThemeIcon from '../../atoms/icons/themeIcons/light'
import SystemThemeIcon from '../../atoms/icons/themeIcons/system'
import BottomUpSliderWithExit from '../../templates/animated/bottomUpSliderWithExit'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IUSerGeneralInfoUpdatePayload } from '../../../redux/user/types'
import { usersActions } from '../../../redux/user/reducer'

type IStyle = {
    $isActive?: boolean
    $theme: ITheme
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    border: none;
    height: 56px;
    background-color: transparent;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
    font-family: Lato;
    color: ${(props) => colours[props.$theme].text_default};
    background-color: ${(props) =>
        props.$isActive
            ? 'var(--base-overlays-neutral-alpha-50, rgba(26, 26, 26, 0.02))'
            : 'transparent'};
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    text-transform: capitalize;
`

const Handle = styled.div<IStyle>`
    height: 4px;
    width: 60px;
    background-color: ${(props) => colours[props.$theme].text_darker};
    border-radius: 10px;
`

type Props = {
    isOpen: boolean
    closeList: () => void
}

const ThemeSwitcherMobile: React.FC<Props> = ({ isOpen, closeList }) => {
    const { theme } = useThemes()
    const dispatch = useAppDispatch()
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const userPreferredTheme = userLoggedIn?.ui_appearance_mode

    useEffect(() => {
        if (isOpen) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [isOpen])

    const updateTheme = (p: 'SYSTEM' | 'DARK' | 'LIGHT') => {
        if (userLoggedIn?.id) {
            let dataToSend: IUSerGeneralInfoUpdatePayload = {
                id: userLoggedIn?.id,
                data: {
                    user_preferences: { ui_appearance_mode: p },
                },
            }

            dispatch(usersActions.updateUserGeneralInfoRequest(dataToSend))
        }

        localStorage.setItem(
            'ui_appearance_mode',
            p === 'SYSTEM' ? 'system' : p === 'DARK' ? 'dark' : 'light'
        )
    }

    const actionItems: IThreeDotsActionsItems[] = [
        {
            id: 'LIGHT',
            text: 'Light',
            actionOnClick: () => {
                updateTheme('LIGHT')
                closeList()
            },
            icon: <LightThemeIcon size="16" />,
        },
        {
            id: 'DARK',
            text: 'Dark',
            actionOnClick: () => {
                updateTheme('DARK')
                closeList()
            },
            icon: <DarkThemeIcon size="16" />,
        },
        {
            id: 'SYSTEM',
            text: 'System',
            actionOnClick: () => {
                updateTheme('SYSTEM')
                closeList()
            },
            icon: <SystemThemeIcon size="16" />,
        },
    ]

    return (
        <>
            <BottomUpSliderWithExit
                isVisible={isOpen}
                onBackdropClick={closeList}
                customHeight={'300px'}
                customZIndex={1000}
            >
                <Handle $theme={theme} />

                <Wrapper data-attr={'theme_switcher'}>
                    {actionItems.map(
                        (item: IThreeDotsActionsItems, index: number) => {
                            if (item) {
                                return (
                                    <StyledOption
                                        $theme={theme}
                                        $isActive={
                                            item.id === userPreferredTheme
                                        }
                                        key={`${item.text}-${index}-mobile`}
                                        aria-label={item.text}
                                        onClick={item.actionOnClick}
                                    >
                                        {item.icon ?? null}
                                        <ContentRow>{item.text}</ContentRow>
                                    </StyledOption>
                                )
                            } else {
                                return null
                            }
                        }
                    )}
                </Wrapper>
            </BottomUpSliderWithExit>
        </>
    )
}

export default ThemeSwitcherMobile
