import React from 'react'
import Script from 'react-load-script'

const SDK_URL = 'https://cdn.nolt.io/widgets.js'
const BOARD_URL = 'https://custodian.nolt.io/'

declare const window: any

type Props = {
    children: React.ReactNode
    jwt: any
}

const NoltWidget: React.FC<Props> = ({ children, jwt }) => {
    const handleScriptCreate = () => {
        console.log('script created')
    }

    const handleScriptError = () => {
        console.log('script error')
    }

    const handleScriptLoad = () => {
        const { nolt } = window

        // Single Sign-On
        nolt('identify', { jwt })
    }

    return (
        <div style={{ width: '100%', boxSizing: 'border-box', height: '100%' }}>
            {jwt !== null && (
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        height: '100%',
                    }}
                >
                    <a href={BOARD_URL} target="_blank">
                        {children}
                    </a>

                    <Script
                        url={SDK_URL}
                        onCreate={handleScriptCreate}
                        onError={handleScriptError}
                        onLoad={handleScriptLoad}
                    />
                </div>
            )}
        </div>
    )
}

export default NoltWidget
