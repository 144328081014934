import { IApexCarOfTheWeekState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import external_link_green from '../../../../../public/assets/icons/external_link_green.svg'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 50px;
    padding-left: 50px;

    @media ${device.ipad} {
        flex-direction: column;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
`

const LeftBox = styled(Wrapper)`
    height: 100%;
    padding-right: 50px;
`

const RightBox = styled(Wrapper)`
    background-color: var(--off-bg-color, #fafafa);
    padding: 30px;
    max-width: 33vw;

    @media ${device.smallest_laptops} {
        max-width: 34vw;
    }

    @media ${device.large_desktop} {
        width: 30vw !important;
    }

    @media ${device.ipad} {
        background-color: var(--off-bg-color, #fff);
        width: 100%;
        box-sizing: border-box;
        max-width: 100%;
        box-sizing: border-box;
        padding-left: 0px;
    }
`

const Image = styled.img`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    object-fit: cover;
    height: 100%;
    min-height: 320px;
    max-height: 340px;

    @media ${device.smallest_laptops} {
        height: 100%;
        min-height: 370px;
        max-height: 370px;
    }

    @media ${device.ipad} {
        height: 426px;
        min-height: 426px;
        max-height: 426px;
    }

    @media ${device.large_desktop} {
        height: 40vh;
        min-height: 100%;
        max-height: 352px;
    }

    @media ${device.ultrawide_desktop} {
        max-height: 310px;
        min-height: 310px;
    }

    object-fit: cover;
    border-radius: 5px;
`

const DateAndIssueNumber = styled.div`
    font-family: Lato-Light;
    font-size: 12px;
    color: var(--text-strong, #1a1a1a);
`

const Name = styled.div`
    font-family: Lato;
    font-size: 22px;
    padding-top: 10px;
    color: var(--text-strong, #1a1a1a);
`

const Title = styled.div`
    font-family: 'Lato-Light';
    font-size: 21px;
    padding-top: 3px;
    padding-bottom: 24px;
    color: var(--text-strong, #1a1a1a);
`

const Description = styled.div`
    line-height: 26px;
    font-family: Lato-Light;
    font-size: 15px;
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.ipad} {
        line-height: 30px;
        font-size: 16px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }
`

const ReadMore = styled.a`
    display: flex;
    font-family: Lato;
    font-size: 12px;
    color: var(--primary, #5ec3ca) !important;
    :focus {
        color: var(--primary, #5ec3ca) !important;
    }
    padding-top: 15px;
    padding-bottom: 30px;
    padding-left: 30px;
    text-transform: uppercase;

    @media ${device.ipad} {
        padding-top: 36px;
        font-size: 14px;
        padding-left: 0px;
    }
`

const ExternalLinkIcon = styled.img`
    width: 11px;
    height: 11px;
    margin-left: 8px;
    transform: translateY(2px);
    @media screen and (min-width: 800px) and (max-width: 1200px) {
        transform: translateY(5px);
    }
`

type Props = {
    car: IApexCarOfTheWeekState
}

const ApexMainCarOfTheWeekDesktop: React.FC<Props> = ({ car }) => {
    return (
        <Container>
            <LeftBox>
                <DateAndIssueNumber>{car.date}</DateAndIssueNumber>
                <Name>{car.name}</Name>
                <Title>{car.title}</Title>
                <Image src={car.img && car.img.url ? car.img.url : ''} />
            </LeftBox>
            <RightBox>
                <Description>{car.description}</Description>
                <ReadMore href={car.link} target="_blank">
                    Take a Closer Look
                    <ExternalLinkIcon src={external_link_green} />
                </ReadMore>
            </RightBox>
        </Container>
    )
}

export default ApexMainCarOfTheWeekDesktop
