import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import Loader from '../../../../../atoms/loader/loader'
import ButtonsStandardBottomRowMobile from '../../../buttons/buttonsStandardBottomRowMobile'
import { IwriteMultipleTechnicalInformationPayloadReq } from 'entityModels'
import InputField from '../../../../../atoms/Inputfield/inputField'
import { IYearsValidationCopy } from 'formValidationCopyModels'
import Expander from '../../../../../atoms/expander/expander'
import { useLocation, useNavigate } from 'react-router'
import { getStandaloneSearchUrl } from '../../searchselect/mobile/standaloneSearchSelectMobile'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTitle,
    Notice,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'
import ChevronDown from '../../../../../atoms/icons/components/chevronDown'

const SmallPadding = styled.div`
    padding-top: 24px;

    @media (max-width: 350px) {
        padding-top: 16px;
    }
`

const Padding8 = styled.div`
    padding-top: 8px;
`

const Padding24 = styled.div`
    padding-top: 24px;
`

const WarningText = styled.div`
    color: var(--error, #df6f6f);
    font-size: 13px;
    padding-top: 5px;
    width: 100%;
    box-sizing: border-box;
    align-items: start;
    justify-content: flex-start;
    font-style: italic;
    font-family: Lato;
    @media (max-width: 350px) {
        padding-bottom: 5px;
    }
`

const PickerButton = styled.button`
    width: 100%;
    box-sizing: border-box;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: var(--text-strong, #1a1a1a);
    border-radius: 4px;
    position: relative;

    ${(props: { noValue: boolean; disabled: boolean }) => `
        opacity: ${props.disabled ? '0.5' : '1'};

        border: ${
            props.disabled
                ? '1px solid var(--text-muted)'
                : props.noValue
                ? '1px solid var(--primary, #5ec3ca)'
                : '1px solid var(--text-muted)'
        }
    `}
`

const PickerButtonContents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 12px;
    height: 48px;
    color: var(--text-strong, #1a1a1a);

    ${(props: { disabled: boolean }) => `
        opacity: ${props.disabled ? 0.5 : 1}
    `}
`

const PickerLabel = styled.label`
    position: absolute;
    top: -8px;
    left: 8px;
    padding: 0px 8px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: var(--text-darker, #616161);
`

const PickerLink = ({ children, ...props }: any) => {
    return (
        <PickerButton {...props}>
            <PickerButtonContents disabled={props.disabled}>
                {children}
                <div style={{ transform: 'rotate(-90deg' }}>
                    <ChevronDown />
                </div>
            </PickerButtonContents>
            <PickerLabel>{props.label}</PickerLabel>
        </PickerButton>
    )
}

interface Values {
    car_make_custom_make_mobile: string
    car_model_custom_make_mobile: string
    car_year_custom_make_mobile: any
}

interface OtherProps {
    toggle: any
    make_item: any
    model_item: any
    year_item: any
    submitFunc?: any
    tech_info_id?: any
    writeMultipleTechnicalInformationRequest: (
        p: IwriteMultipleTechnicalInformationPayloadReq
    ) => any
    // makeDropDownList: any
    // modelDropdownList: any
    // tempModelDisplay: string | undefined
    // tempMakeDisplay: string | undefined

    setCustomItemInSpecifiedTargettedDropdown: any
    isLoading: boolean
    carid: string
    car_years_validation?: IYearsValidationCopy
    // setTempMakeIDSuccess: (payload: string | undefined) => void
    // setTempMakeDisplay: (payload: string | undefined) => void

    // setTempModelIDSuccess: (payload: string | undefined) => void
    // setTempModelDisplay: (payload: string | undefined) => void

    isLoadingNewMakeOrModel: boolean
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    // const dispatch = useAppDispatch()
    // const makeDropDownList = useAppSelector(
    //     (state: RootState) => state.localdata.dropdownData.carMakes
    // )
    // const modelDropdownList = useAppSelector(
    //     (state: RootState) => state.localdata.dropdownData.carModels
    // )
    // const tempMakeDisplay = useAppSelector(
    //     (state: RootState) => state.localdata.dropdownData.tempMakeDisplay
    // )
    // const tempModelDisplay = useAppSelector(
    //     (state: RootState) => state.localdata.dropdownData.tempModelDisplay
    // )
    // const isLoading = useAppSelector(
    //     (state: RootState) => state.localdata.dropdownData.loading
    // )
    // const isLoadingNewMakeOrModel = useAppSelector(
    //     (state: RootState) =>
    //         state.localdata.dropdownData.isLoadingNewMakeOrModel
    // )
    // const car_years_validation = useAppSelector(
    //     (state: RootState) =>
    //         state.localdata.form_validation_copy.years.car_year
    // )

    const {
        toggle,
        make_item,
        model_item,
        year_item,
        handleSubmit: onSubmit,
        values,
        errors,
    } = props

    let checkIfDisabled = () => {
        if (
            !values.car_make_custom_make_mobile ||
            !values.car_model_custom_make_mobile ||
            values.car_model_custom_make_mobile === '-'
        ) {
            return true
        } else if (
            errors.car_make_custom_make_mobile ||
            errors.car_model_custom_make_mobile ||
            errors.car_year_custom_make_mobile
        ) {
            return true
        } else if (props.isLoadingNewMakeOrModel) {
            return true
        } else return false
    }

    const navigate = useNavigate()
    const location = useLocation()

    const removeFormIdFromUrl = () => {
        const params = new URLSearchParams(location.search)
        params.delete('form_id')

        const query = params.toString()
        navigate(`${location.pathname}?${query}`, { replace: true })
    }

    const handleCancel = () => {
        removeFormIdFromUrl()

        toggle()
    }

    const handleSubmit = (...args: any) => {
        removeFormIdFromUrl()
        onSubmit(...args)
    }

    const make = props.values['car_make_custom_make_mobile']
    const model = props.values['car_model_custom_make_mobile']

    const makeLabel = make && make !== '-' ? make : null
    const modelLabel = model && model !== '-' ? model : null

    const makePickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}`
            : `${location.pathname}`,
        path: `/car/${props.carid}/search`,
        formId: 'makeModelYear',
        fieldId: 'make',
    })

    const modelPickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}`
            : `${location.pathname}`,
        path: `/car/${props.carid}/search`,
        formId: 'makeModelYear',
        fieldId: 'model',
    })

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <Loader isLoading={props.isLoading} />
            {make_item !== undefined &&
                model_item !== undefined &&
                year_item !== undefined && (
                    <ModalInnerContentWrapperMobile>
                        <ModalTitle $theme={theme}>
                            Make | Model | Year
                        </ModalTitle>
                        <Padding8 />
                        <Notice>
                            If you can't find your car's model or make simply
                            type the name fully, then select "
                            <span style={{ fontWeight: 'bold' }}>add</span>
                            ".
                        </Notice>
                        <SmallPadding />
                        <PickerLink
                            onClick={() =>
                                !!makePickerUrl && navigate(makePickerUrl)
                            }
                            noValue={!makeLabel}
                            label="Make"
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        >
                            {makeLabel ?? 'Pick a make'}
                        </PickerLink>
                        <Expander
                            height={
                                values['car_make_custom_make_mobile'] === null
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <WarningText>
                                Please enter the car's make.
                            </WarningText>
                        </Expander>
                        <SmallPadding />
                        <PickerLink
                            onClick={() =>
                                !!modelPickerUrl && navigate(modelPickerUrl)
                            }
                            noValue={!modelLabel}
                            disabled={!makeLabel}
                            label="Model"
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        >
                            {modelLabel ?? 'Pick a model'}
                        </PickerLink>
                        <Expander
                            height={
                                (values['car_make_custom_make_mobile'] !==
                                    null &&
                                    values['car_model_custom_make_mobile'] ===
                                        null) ||
                                values['car_model_custom_make_mobile'] === '-'
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <WarningText>
                                Please enter the car's model.
                            </WarningText>
                        </Expander>
                        <SmallPadding />
                        <InputField
                            theme={theme}
                            id="car_year_custom_make_mobile"
                            name="car_year_custom_make_mobile"
                            placeholder="Year"
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            value={values.car_year_custom_make_mobile}
                            font_family="Lato"
                            width="150px"
                            type="number"
                            disabled={
                                values['car_model_custom_make_mobile'] ===
                                    null && model_item.answer === null
                                    ? true
                                    : false
                            }
                            onEnterKeyPress={props.handleSubmit}
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        />
                        <Expander
                            height={
                                errors?.car_year_custom_make_mobile ? 'auto' : 0
                            }
                        >
                            <WarningText>
                                {`${errors.car_year_custom_make_mobile}`}
                            </WarningText>
                        </Expander>
                        <Padding24 />
                        <ButtonsStandardBottomRowMobile
                            cancel={handleCancel}
                            submit={handleSubmit}
                            confirmationtext="save"
                            disabled={checkIfDisabled()}
                            isSubmitLoading={props.isLoadingNewMakeOrModel}
                        />
                    </ModalInnerContentWrapperMobile>
                )}
        </ModalContentMobile>
    )
}

const CarMakeCustomFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        car_make_custom_make_mobile: props.make_item.answer ?? '-',
        car_model_custom_make_mobile: props.model_item.answer ?? '-',
        car_year_custom_make_mobile: props.year_item
            ? props.year_item.answer
            : '-',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                car_year_custom_make_mobile:
                    values.car_year_custom_make_mobile &&
                    values.car_year_custom_make_mobile !== '-' &&
                    props.car_years_validation &&
                    Yup.number()
                        .min(
                            props.car_years_validation.min,
                            props.car_years_validation.warning_min
                        )
                        .max(
                            props.car_years_validation.max,
                            props.car_years_validation.warning_max
                        ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let p: IwriteMultipleTechnicalInformationPayloadReq = {
            car_id: props.carid,
            tech_info_id: props.tech_info_id,
            data: [
                {
                    id: 'make',
                    text: 'make',
                    answer: values.car_make_custom_make_mobile,
                },

                {
                    id: 'model',
                    text: 'model',
                    answer: values.car_model_custom_make_mobile,
                },
                {
                    id: 'year',
                    text: 'year',
                    answer: values.car_year_custom_make_mobile,
                },
            ],
        }

        props.writeMultipleTechnicalInformationRequest(p)
        props.toggle()
    },
})(InnerForm)

export default CarMakeCustomFormMobile
