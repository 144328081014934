import * as urls from '../urls'
import { customHeader } from '../headers'
import { apiSentryErrorHandler } from '../errorHandler'
import { ICarPayload } from '../typedefinitions/apiPayloads'
import {
    IApiUpdateCar_args,
    IApiUpdateCarHighFact_args,
    IUrlShortenerAPI_request_args,
} from '../types'

export const updateCar = (p: IApiUpdateCar_args): Promise<ICarPayload> => {
    let dataToUpdateFinal: string = JSON.stringify(p.dataToUpdate)

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: dataToUpdateFinal,
        credentials: 'include',
    }

    return fetch(urls.update_car_endpoint(p.car_id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Update car error',
                            dataToUpdateFinal
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const updateCarHighlightedFactsAPI = (p: IApiUpdateCarHighFact_args) => {
    let dataToUpdateFinal: string = JSON.stringify(p.facts)

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: dataToUpdateFinal,
        credentials: 'include',
    }

    return fetch(
        urls.update_car_highlighted_facts_endpoint(p.car_id),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Update car highlighted facts error',
                        dataToUpdateFinal
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const createCarPublicShare = (car_id: string): Promise<any> => {
    let jsonBody: any = {
        resource_id: car_id,
        resource_type: 'car',
        share_type: 'link_share',
    }

    let jsonBodyConv: string = JSON.stringify(jsonBody)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
        body: jsonBodyConv,
    }

    return fetch(urls.create_share_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create car public shares error',
                            jsonBodyConv
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const createUrlShortener = (
    p: IUrlShortenerAPI_request_args
): Promise<any> => {
    const prod_env = process.env.REACT_APP_ENV
    let url_fe = 'https://share.custodian.club'

    if (prod_env === 'development' || prod_env === 'preview') {
        url_fe = 'https://devshare.custodian.club'
    }

    if (prod_env === 'local') {
        url_fe = 'http://localhost:3001'
    }

    let link = `${url_fe}/${p.url}`

    let jsonBody: any = {
        link: link,
    }

    let jsonBodyConv: string = JSON.stringify(jsonBody)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
        body: jsonBodyConv,
    }

    return fetch(urls.create_sharing_url_shortener, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create short share url error',
                            jsonBodyConv
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
