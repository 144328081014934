import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { randomCarSvgPlaceholderFunc } from '../../../../helpers/random/randomPlaceholder'
import { device } from '../../../templates/displays/devices'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import public_active_circle from '../../../../public/assets/icons/public_active_circle.svg'
import public_active_circle_dark from '../../../../public/assets/icons/public_active_circle_dark.svg'
import public_inactive_circle from '../../../../public/assets/icons/public_inactive_circle.svg'
import public_inactive_circle_dark from '../../../../public/assets/icons/public_inactive_circle_dark.svg'
import padlock from '../../../../public/assets/icons/padlock_color_fill_grey.svg'
import QrCodeBtn from '../../../atoms/Button/qrCodeBtn'
import LoaderElasticThreeDots from '../../../atoms/loader/loaderElasticThreeDots'
import { ITheme } from '../../../../providers/theme/colours'
import { IGarage } from '../../../../redux/entities/garages/types'
import { ICar } from '../../../../redux/entities/cars/types'
import useThemes from '../../../../providers/theme/hooks'

const ShadowBox = styled(Link)<IStyle>`
    box-sizing: border-box;
    margin-right: ${(props) => (props.hasMarginRight ? '20px' : '0px')};
    position: relative;
    margin-bottom: 20px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: initial;
    justify-content: center;
    font-family: Lato;
    width: 500px;
    box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-right: ${(props) => (props.hasMarginRight ? '50px' : '0px')};
    background-color: var(--shadow-cards-bg, #fff);
    transition: all 100ms;
    :hover {
        box-shadow: 2px 2px 7px 1px var(--box-shadow, rgba(0, 0, 0, 0.08));
        background-color: var(--primary_04) !important;
        transition: all 100ms;
    }

    @media (max-width: 1240px) {
        width: 60vw;
        margin-right: 0px;
    }
    @media (min-width: 1241px) and (max-width: 1310px) {
        width: 430px;
        height: 152px;
        margin-right: ${(props) => (props.hasMarginRight ? '20px' : '0px')};
    }
    @media (min-width: 1311px) and (max-width: 1600px) {
        width: 465px;
        height: 152px;
        margin-right: ${(props) => (props.hasMarginRight ? '30px' : '0px')};
    }
    @media ${device.large_desktop} {
        width: 500px;
    }

    @media ${device.large_desktop} {
        margin-right: ${(props) => (props.hasMarginRight ? '70px' : '0px')};
        width: 540px;
    }
`

const CarTitle = styled.div`
    font-size: 0.9rem;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
    max-width: 240px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`

const Col = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
`

const RowColDisplays = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: flex-start;
    height: auto;
`

const Row = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* flex-wrap: wrap; */

    @media ${device.desktop} {
        align-items: center;
    }

    @media ${device.large_desktop} {
        align-items: center;
    }

    @media ${device.smallest_laptops} {
        align-items: center;
    }
`

const InnerRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

type IImageStyle = {
    isthingloading: boolean
}

const CarProfileImage = styled.img<IImageStyle>`
    height: 100%;
    width: 168px;
    border-radius: 5px;
    object-fit: cover;
`

const CarImgWrapper = styled.div`
    width: 190px;
    height: 100%;
    object-fit: cover;
    padding: 24px;
`

const RowText = styled.div`
    font-size: 0.8rem;
    color: var(--text-strong, #1a1a1a);
`

const PrivateText = styled.div<{ $isLoading: boolean }>`
    font-family: Lato-light;
    letter-spacing: 0.8px;
    font-size: 0.7rem;
    color: var(--text-darker, #616161);
    ${(props) => props.$isLoading === false && 'transform: translateY(2px);'}
`

const HozLine = styled.div`
    border: 1px solid var(--border_muted_subtle);
    height: 1px;
    width: 30px;
    margin-bottom: 15px;
`

const Circle = styled.div<{ $isActive?: boolean }>`
    border: ${(props) =>
        props.$isActive
            ? '1px solid var(--primary, #5ec3ca)'
            : ' 1px solid var(--border-muted, #e5e5e5)'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${(props) =>
        props.$isActive
            ? 'var(--primary, #5ec3ca)'
            : 'var(--border-muted, #e5e5e5)'};
    height: 18px;
    width: 18px;
    font-size: 12px;
    line-height: 10px;
`

const ImgSize = styled.img`
    height: 18px;
    width: 18px;
`

interface IStyle {
    hasMarginRight?: boolean
}

interface Props {
    car: ICar
    hasMarginRight?: boolean
    imagesData: any
    carIndex: number
    garage: IGarage
    qrCodeRequested: (qr_code_id: string) => void
    areSharesLoading?: boolean
}

const CarSharedWithOthersCardDesktop: React.FC<Props> = ({
    car,
    hasMarginRight,
    imagesData,
    carIndex,
    garage,
    qrCodeRequested,
    areSharesLoading,
}) => {
    const [src, setSrc] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    let { theme } = useThemes()

    useEffect(() => {
        const src = get_car_cover_url(car)

        if (src.url !== undefined) {
            setSrc(src.url)
        } else {
            setSrc(randomCarSvgPlaceholderFunc('jpg', theme))
        }
    }, [car, theme])

    const get_car_cover_url = (carObj: ICar) => {
        if (carObj && carObj.gallery) {
            let imageid =
                carObj.gallery && carObj.gallery.cover && carObj.gallery.cover
                    ? carObj.gallery.cover
                    : undefined

            if (imageid && imagesData) {
                if (imagesData[imageid] && imagesData[imageid].medium_srcset) {
                    return {
                        srcset: imagesData[imageid].medium_srcset,
                        url: imagesData[imageid].url,
                    }
                } else if (imagesData[imageid] && imagesData[imageid].url) {
                    return {
                        url: imagesData[imageid].url,
                    }
                }
            }
        }

        return {
            url: undefined,
        }
    }

    let imageid =
        car.gallery && car.gallery.cover && car.gallery.cover
            ? car.gallery.cover
            : undefined

    let has_car_public_share: boolean =
        car && car.public_share_owned && car.public_share_owned.isActive
            ? car.public_share_owned.isActive
            : false

    let has_garage_public_share: boolean =
        garage &&
        garage.public_share_owned &&
        garage.public_share_owned.isActive
            ? garage.public_share_owned.isActive
            : false

    let n_of_public_shares = (): number => {
        let n = 0
        if (has_garage_public_share) {
            n = n + 1
        }

        if (has_car_public_share) {
            n = n + 1
        }

        return n
    }

    let n_of_car_private_shares: number =
        car &&
        car.private_shares_owned &&
        car.private_shares_owned.entity_owned_direct_shares_ids
            ? car.private_shares_owned.entity_owned_direct_shares_ids.length
            : 0

    let n_of_garage_private_shares =
        garage &&
        garage.private_shares_owned &&
        garage.private_shares_owned?.entity_owned_direct_shares_ids
            ? garage.private_shares_owned?.entity_owned_direct_shares_ids.length
            : 0

    let isCarPrivate =
        n_of_car_private_shares === 0 &&
        n_of_garage_private_shares === 0 &&
        !has_garage_public_share &&
        !has_car_public_share

    let isQrCodeBtnVisible =
        has_car_public_share || has_garage_public_share ? true : false

    const share_link_icon = (theme: ITheme) => {
        if (n_of_public_shares() > 0) {
            if (theme === 'dark') {
                return public_active_circle_dark
            } else {
                return public_active_circle
            }
        } else {
            if (theme === 'dark') {
                return public_inactive_circle_dark
            } else {
                return public_inactive_circle
            }
        }
    }

    return (
        <>
            {car ? (
                <ShadowBox
                    hasMarginRight={hasMarginRight}
                    to={{
                        pathname: `/sharing/with-others/car`,
                        search: `?carid=${car.id}`,
                        // state: {
                        //     prevPath:
                        //         this.props.history.location.pathname,
                        // },
                    }}
                >
                    <Row>
                        <CarImgWrapper>
                            {isLoading && (
                                <SkeletonAbsolute isthingloading={isLoading} />
                            )}

                            <CarProfileImage
                                src={src}
                                isthingloading={isLoading}
                                srcSet={
                                    imageid &&
                                    imagesData[imageid] &&
                                    imagesData[imageid].small_srcset &&
                                    imagesData[imageid].small_srcset
                                }
                                aria-label="car profile image"
                                onLoad={() => setIsLoading(false)}
                            />
                        </CarImgWrapper>
                        <Col style={{ justifyContent: 'center' }}>
                            <div>
                                <CarTitle>
                                    {car.title
                                        ? car.title
                                        : `Untitled #${carIndex + 1}`}
                                </CarTitle>
                                <HozLine />
                            </div>
                            {areSharesLoading && (
                                <Col>
                                    <LoaderElasticThreeDots />
                                </Col>
                            )}
                            {!areSharesLoading &&
                                (n_of_public_shares() > 0 ||
                                    n_of_car_private_shares > 0 ||
                                    n_of_garage_private_shares > 0) && (
                                    <>
                                        <RowColDisplays>
                                            <InnerRow>
                                                <Circle
                                                    $isActive={
                                                        n_of_public_shares() > 0
                                                    }
                                                >
                                                    <ImgSize
                                                        src={share_link_icon(
                                                            theme
                                                        )}
                                                        alt="public_link_status"
                                                    />
                                                </Circle>
                                                <div
                                                    style={{
                                                        paddingLeft: '12px',
                                                    }}
                                                />
                                                <RowText>
                                                    {n_of_public_shares() > 0
                                                        ? 'Active Shareable Link'
                                                        : 'No Shareable Link'}
                                                </RowText>
                                            </InnerRow>
                                            <div
                                                style={{
                                                    paddingTop: '5px',
                                                }}
                                            />
                                            <InnerRow>
                                                <Circle
                                                    $isActive={
                                                        n_of_car_private_shares >
                                                        0
                                                    }
                                                >
                                                    {n_of_car_private_shares}
                                                </Circle>
                                                <div
                                                    style={{
                                                        paddingLeft: '12px',
                                                    }}
                                                />
                                                <RowText>
                                                    Car Only Direct Shares
                                                </RowText>
                                            </InnerRow>
                                            <div
                                                style={{
                                                    paddingTop: '5px',
                                                }}
                                            />

                                            <InnerRow>
                                                <Circle
                                                    $isActive={
                                                        n_of_garage_private_shares >
                                                        0
                                                    }
                                                >
                                                    {n_of_garage_private_shares}
                                                </Circle>
                                                <div
                                                    style={{
                                                        paddingLeft: '12px',
                                                    }}
                                                />
                                                <RowText>
                                                    Direct shares via garage
                                                </RowText>
                                            </InnerRow>
                                        </RowColDisplays>
                                        {isQrCodeBtnVisible && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '15px',
                                                    right: '20px',
                                                    zIndex: 4,
                                                }}
                                                onClick={(e: any) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <QrCodeBtn
                                                    txt="qr code"
                                                    dropDownOptions={
                                                        has_car_public_share &&
                                                        has_garage_public_share
                                                            ? [
                                                                  {
                                                                      id: 'car',
                                                                      text: 'car',
                                                                      action: () =>
                                                                          qrCodeRequested(
                                                                              'car_only'
                                                                          ),
                                                                  },
                                                                  {
                                                                      id: 'garage',
                                                                      text: 'garage',
                                                                      action: () =>
                                                                          qrCodeRequested(
                                                                              'car_via_garage'
                                                                          ),
                                                                  },
                                                              ]
                                                            : undefined
                                                    }
                                                    onClick={() =>
                                                        qrCodeRequested(
                                                            has_car_public_share
                                                                ? 'car_only'
                                                                : 'car_via_garage'
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            {!areSharesLoading && isCarPrivate && (
                                <RowColDisplays
                                    style={{
                                        paddingBottom: '20px',
                                    }}
                                >
                                    <InnerRow>
                                        <ImgSize src={padlock} alt="private" />
                                        <div
                                            style={{
                                                paddingLeft: '12px',
                                            }}
                                        />

                                        <PrivateText
                                            $isLoading={
                                                areSharesLoading ?? false
                                            }
                                        >
                                            PRIVATE
                                        </PrivateText>
                                    </InnerRow>
                                </RowColDisplays>
                            )}
                        </Col>
                    </Row>
                </ShadowBox>
            ) : (
                <div />
            )}
        </>
    )
}

export default CarSharedWithOthersCardDesktop
