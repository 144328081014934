import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Faded from '../templates/animated/faded'
import Loader from '../atoms/loader/loader'
import {
    generateFBC,
    generateFBP,
    ICookieSetArg,
    setCookie,
} from '../../helpers/cookies'
import { GetUserIPAndCallFbTracking } from '../../helpers/ipAddress'
import { message } from 'antd'
import RegistrationNewLanding from '../organisms/registerfullpagescroll/registrationNewLanding'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'
import { usersActions } from '../../redux/user/reducer'
import { garagesActions } from '../../redux/entities/garages/reducer'
import { carActions } from '../../redux/entities/cars/reducer'

const info = (messageText: string) => {
    message.info(messageText, 5)
}

const RegisterNewLandingPage: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const hasLoggedOut = useAppSelector((state) => state.user.hasLoggedOut)

    const [redirect, setRedirect] = useState(false)
    const [hadInitialToken, setHadInitialToken] = useState<boolean | null>(null)

    const sortOutFbTracking = (ip?: string | undefined) => {
        if (
            process.env.REACT_APP_ENV !== 'local' &&
            process.env.REACT_APP_ENV !== 'development' &&
            process.env.REACT_APP_ENV !== 'preview'
        ) {
            const params = new URLSearchParams(location.search)
            const fbclid: string | null = params.get('fbclid')

            const timeNow = Date.now()
            let event_id = `view_item_${timeNow}`

            const ua =
                !!window && window.navigator && window.navigator.userAgent
                    ? window.navigator.userAgent
                    : undefined

            if (window && window.dataLayer === undefined) {
                window.dataLayer = window.dataLayer || []
            }

            if (fbclid) {
                const fbclidCookieArg: ICookieSetArg = {
                    name: 'fbclid',
                    value: fbclid,
                    days: 180,
                }
                setCookie(fbclidCookieArg)

                const FBC = generateFBC(fbclid, timeNow)
                setCookie({
                    name: '_fbc',
                    value: FBC,
                    days: 180,
                })

                const FBP = generateFBP(timeNow)
                setCookie({
                    name: '_fbp',
                    value: FBP,
                    days: 180,
                })

                if (ip) {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            fbclid: fbclid,
                            ip_override: ip,
                            event_id: event_id,
                            user_agent: ua,
                        })
                } else {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            fbclid: fbclid,
                            event_id: event_id,
                            user_agent: ua,
                        })
                }
            } else if (
                process.env.REACT_APP_ENV !== 'local' &&
                process.env.REACT_APP_ENV !== 'development' &&
                process.env.REACT_APP_ENV !== 'preview'
            ) {
                if (ip) {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            ip_override: ip,
                            event_id: event_id,
                            user_agent: ua,
                        })
                } else {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            event_id: event_id,
                            user_agent: ua,
                        })
                }
            }
        }
    }

    useEffect(() => {
        let refresh_token: string | null =
            typeof localStorage !== 'undefined' && localStorage !== null
                ? localStorage.getItem('refresh_token')
                : null

        if (refresh_token) {
            setHadInitialToken(true)
        }
        if (!refresh_token) {
            setHadInitialToken(false)
        }

        dispatch(usersActions.resetErrorStateSuccess())

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        if (userLoggedIn === null) {
            setRedirect(false)
        } else if (userLoggedIn && userLoggedIn !== null && !hasLoggedOut) {
            setRedirect(false)
        }

        GetUserIPAndCallFbTracking(sortOutFbTracking)
            .then((data: any) => {})
            .catch((err: any) => {})
    }, [])

    useEffect(() => {
        if (userLoggedIn === null) {
            setRedirect(false)
        } else if (userLoggedIn && userLoggedIn !== null && !hasLoggedOut) {
            const q_params = new URLSearchParams(location.search)
            let invite_code = q_params.get('invite_code')
            const next_path = q_params.get('next')
            const utm_source = q_params.get('utm_source')
            if (invite_code && next_path && utm_source === 'share_invite') {
                next_path.includes('garage')
                    ? dispatch(
                          garagesActions.acceptGarageShareInviteRequest({
                              invite_code: invite_code,
                              next_path: next_path,
                          })
                      )
                    : dispatch(
                          carActions.acceptCarShareInviteRequest({
                              invite_code: invite_code,
                              next_path: next_path,
                          })
                      )
            } else {
                if (
                    invite_code &&
                    next_path &&
                    utm_source === 'handover_invite'
                ) {
                    info(
                        `You have accepted a handover invite. You new car will show up in your garage shortly.`
                    )
                }
                setRedirect(true)
            }
        }
    }, [userLoggedIn])

    const checkURL = (par?: string | null) => {
        let parr2 = par ? decodeURIComponent(par) : undefined
        if (parr2 === '/insurance') {
            return '/insurance'
        } else return '/garage'
    }

    const params = new URLSearchParams(location.search)
    const invite_code = params.get('invite_code')
    const redirectq = params.get('next')
    const checkURLFInal = checkURL(redirectq)

    return (
        <Faded>
            {redirect === true &&
                hadInitialToken === true &&
                navigate(checkURLFInal)}
            <Loader />
            <RegistrationNewLanding invite_code={invite_code} />
        </Faded>
    )
}

export default RegisterNewLandingPage
