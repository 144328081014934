import React, { useState, useEffect, useRef } from 'react'
import AddAttachmentDesktop from './addAttachmentDesktop'
import { IEditOrCreateModeSingleFieldsProps } from '../../types'
import { IUploadImagesPreSignedUrlsPayload } from '../../../../../redux/services/typedefinitions/apiPayloads'
import { IAttachmentRequestPayload } from '../../../../../redux/attachments/types'

interface Props {
    data: IEditOrCreateModeSingleFieldsProps
    close?: any
    presignedurls?: IUploadImagesPreSignedUrlsPayload
    user_id?: string
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
}

const WrapperAttachmentFormDesktop: React.FC<Props> = ({
    data,
    close,
    dataCyId,
    entityID,
    carID,
    entityType,
}) => {
    const [progressPercent, setProgressPercent] = useState<number>(0)
    const [file, setFile] = useState<File | undefined>(undefined)
    const [valueStart, setValueStart] = useState<any>(data.value_start)

    const intervalRef = useRef<any>(null)

    const handlePercent = () => {
        setProgressPercent((prev) => prev + 10)
    }

    const startInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
        intervalRef.current = setInterval(handlePercent, 50)
    }

    const deleteImageUploaded = () => {
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                null,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
            setValueStart(undefined)
            setFile(undefined)
        } else {
            data.onChangeFunc(data.id, null)
            setValueStart(undefined)
            setFile(undefined)
        }
    }

    const handleFilesGivenInWrapperComponent = (file: File) => {
        const fieldValue: IAttachmentRequestPayload = {
            filename: file.name ? file.name : 'no_file_name_received',
            type: file.type ? file.type : 'no_file_type_received',
            file: file,
        }

        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                fieldValue,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, fieldValue)
        }

        startInterval()
        setFile(file)
        setProgressPercent(100)
    }

    useEffect(() => {
        if (progressPercent === 100) {
            clearInterval(intervalRef.current)
        }
        return () => clearInterval(intervalRef.current)
    }, [progressPercent])

    return (
        <div data-attr={dataCyId} style={{ width: '100%' }}>
            <AddAttachmentDesktop
                data={{ title: 'Attachment' }}
                close={close}
                deleteFileUploaded={deleteImageUploaded}
                setFileUploaded={handleFilesGivenInWrapperComponent}
                currentFileUploaded={file}
                progressPercent={progressPercent}
                entityID={entityID}
                carID={carID}
                entityType={entityType}
                initialValue={valueStart}
            />
        </div>
    )
}

export default WrapperAttachmentFormDesktop
