import { call, fork, put, take, select } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { api } from '../../services'
import { timelineActions } from '../reducer'
import { IDeleteEntryAttachmentPayloadReq } from '../../services/types'
import { IRootState } from '../../store'
import { ITimelineItem } from '../types'
import { ICustomErrorData, IReduxError } from '../../entities/cars/types'

let get_active_entry_in_state = (state: IRootState) =>
    state.timeline.activeEntry

function* DeleteEntryAttachmentSaga(
    payload: IDeleteEntryAttachmentPayloadReq
): any {
    try {
        yield call(
            api.timeline.entries.deleteEntryAttachment.deleteEntryAttachment,
            payload
        )

        let active_entry_in_state: ITimelineItem = yield select(
            get_active_entry_in_state
        )

        let ids: string[] = []

        if (
            active_entry_in_state.attachmentIDs &&
            active_entry_in_state.attachmentIDs.length > 0
        ) {
            ids = [...active_entry_in_state.attachmentIDs]

            const index = ids.indexOf(payload.attachmentID)
            if (index > -1) {
                ids = [...ids.slice(0, index), ...ids.slice(index + 1)]
            }
        }
        yield put(timelineActions.deleteEntryAttachmentSuccess(ids))
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: DeleteEntryAttachmentSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(timelineActions.deleteEntryAttachmentError(customError))
        }
    }
}

function* watcherDeleteEntryAttachment() {
    while (true) {
        const { payload } = yield take(
            timelineActions.deleteEntryAttachmentRequest
        )

        yield call(DeleteEntryAttachmentSaga, payload)
    }
}

const delete_entry_attachment: any[] = [fork(watcherDeleteEntryAttachment)]

export default delete_entry_attachment
