import * as urls from '../urls'
import { removeUndefinedfromObject } from '../../helpers/objects'
import { customHeader } from '../headers'
import { apiSentryErrorHandler } from '../errorHandler'
import * as Sentry from '@sentry/browser'
import { IRegisterData, ISocialAuthData } from 'IapiDataPayload'
import { IRefreshTokenAPI } from '../typedefinitions/apiPayloads'
import { ISignInPayloadReq, IApiVerifiedResetPassword_args } from '../types'

// document.cookie = "jwt= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

export const logout = (): Promise<any> => {
    // retrieve refresh_token from local storage
    let refresh_token: string | null =
        typeof localStorage !== 'undefined' && localStorage !== null
            ? localStorage.getItem('refresh_token')
            : null

    let refresh_token_body: IRefreshTokenAPI | null = refresh_token
        ? {
              refresh_token: refresh_token,
          }
        : null

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
        body: JSON.stringify(refresh_token_body),
    }

    return fetch(urls.logout, requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                if (response.status && response.status !== 500) {
                    apiSentryErrorHandler(response, 'Logout error')
                }

                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}

export const register = (data: IRegisterData): Promise<any> => {
    let registerData = removeUndefinedfromObject(data)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: JSON.stringify(registerData),
        credentials: 'include',
    }

    return fetch(urls.register, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(data, 'Register error')
                    return Promise.reject(data)
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const isAuthApi = (userId: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.user_endpoint(userId), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Is authenticated')
                        return Promise.reject(data)
                    }
                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const verifyEmail = (code: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.verifyEmail(code), requestOptions)
        .then((response: any) => {
            if (response.status === 200) {
                return
            }

            if (response.ok !== true) {
                if (response.status === 401) {
                    // do something
                    logout()
                } else {
                    apiSentryErrorHandler(response, 'Verify email error')
                    return Promise.reject(response)
                }
            }
            return
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}

export const login = async (p: ISignInPayloadReq): Promise<any> => {
    let loginData = { email: p.email, password: p.password }
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: JSON.stringify(loginData),
        credentials: 'include',
    }

    return fetch(urls.login, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    if (response.status === 401) {
                        // signal something
                    }

                    // excluding 403 => wrong password and 404 => wrong email
                    if (
                        response.status &&
                        response.status !== 500 &&
                        response.status !== 403 &&
                        response.status !== 404
                    ) {
                        apiSentryErrorHandler(data, 'Login error')
                    }

                    return Promise.reject(response)
                }

                if (data) {
                    // type of ILoginPayloadResAPI
                    return data
                } else return
            })
            .catch((error: any) => Promise.reject(error))
    })
}

export const resetPasswordRequestApi = (emailgiven: string): Promise<any> => {
    let emailData = { email: emailgiven }
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: JSON.stringify(emailData),
        credentials: 'include',
    }
    return fetch(urls.reset_password_request, requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                if (response.status === 401) {
                    // signal something
                } else {
                    apiSentryErrorHandler(response, 'Reset password error')
                    return Promise.reject(response)
                }
            }
            return
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}

export const resetPasswordVerifyCode = (code: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        credentials: 'include',
    }

    return fetch(urls.reset_password_verify_code(code), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Reset password verify code error'
                        )

                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const verifiedResetPasswordApi = (
    p: IApiVerifiedResetPassword_args
): Promise<any> => {
    let passwordData = {
        password: p.passwordGiven,
        confirm_password: p.passwordGiven,
    }
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: JSON.stringify(passwordData),
        credentials: 'include',
    }

    return fetch(urls.reset_password_verified(p.uid), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Verify reset password error'
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((error: any) => Promise.reject(error))
        }
    )
}

export const refreshToken = (): Promise<any> => {
    // retrieve refresh_token from local storage
    let refresh_token: string | null =
        typeof localStorage !== 'undefined' && localStorage !== null
            ? localStorage.getItem('refresh_token')
            : null

    let refresh_token_body: IRefreshTokenAPI | null = refresh_token
        ? {
              refresh_token: refresh_token,
          }
        : null

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
        body: JSON.stringify(refresh_token_body),
    }

    return fetch(urls.refreshToken, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    // excluding 401 for now since it will get logged on every logout

                    let currentPath = window?.location?.pathname

                    if (response.status === 500 || response.status === 502) {
                        Sentry.captureMessage(
                            `RefrTknErr ${
                                response.status
                            } on path ${currentPath} ${
                                data.text &&
                                JSON.stringify(data.text) !== 'undefined'
                                    ? `Text: ${JSON.stringify(data.text)}`
                                    : ''
                            }`
                        )
                    } else {
                        apiSentryErrorHandler(data, 'Refresh token error')
                    }

                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    // type of ILoginPayloadResAPI
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const socialAuthenticationAPI = (
    data: ISocialAuthData
): Promise<any> => {
    let authorisation_code = data.authorization_code

    let registerData = removeUndefinedfromObject(data)
    // i want to see on sentry if its undefined or else to be able to track!
    registerData = { ...registerData, authorization_code: authorisation_code }

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: JSON.stringify(registerData),
        credentials: 'include',
    }

    return fetch(urls.social_auth_url, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(data, 'Register error')
                    return Promise.reject(data)
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
