import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import CustomTagQuickFilterItemDesktop from '../../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import FadedSlower from '../../../templates/animated/FadedSlower'
import Expander from '../../../atoms/expander/expander'
import FullWidthLightGreyBgButton from '../../../atoms/Button/fullWidthLightGreyBg'
import AddLabelButton from '../../../atoms/Button/addLabelButton'
import { IDropdownItem } from 'entityModels'
import { are2arraysEqual } from '../../../../helpers/arrayChecks/are2arraysEqual'
import ButtonsStandardBottomRowMobile from '../../editForms/buttons/buttonsStandardBottomRowMobile'
import colours, { ITheme } from '../../../../providers/theme/colours'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import LabelIcon from '../../../atoms/icons/components/labelIcon'
import { AbsoluteBlueLabel } from './filterByMultipleDesktop'
import useThemes from '../../../../providers/theme/hooks'

export type Props = {
    items?: any
    searchFor?: any
    addTagFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    user_searchable_labels_list?: IDropdownItem[] | null | undefined
    value?: any
    handleChange?: any
    categoryData?: any
    fetchItem?: any
    labelsDataRes?: any
    addLabelButton?: React.ReactNode
    onSave?: any
    hasCreateNewLabel?: boolean
    clearField?: any
    selectedFilteringLabels: string[]
    dataCyIdSave?: string
}

type IStyle = {
    selected?: boolean
    issearchactive?: boolean
    top?: number
    fixedwidth?: boolean
}

const SearchFieldWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 10px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 20px;
`

const SearchField = styled.input<IStyle>`
    border: ${(props) =>
        props.issearchactive
            ? '1px solid var(--primary, #5ec3ca)'
            : '1px solid var(--text-muted)'};
    border-radius: 5px;
    font-family: Lato;
    font-size: 16px;
    color: var(--text-darker, #616161);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    /* height: ${(props) => (props.issearchactive ? '40px' : '38px')}; */
    height: 48px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    padding: 12px 16px;

    ::placeholder {
        color: var(--text-muted);
    }
    :active {
        border: 1px solid var(--primary, #5ec3ca);
    }
`

const DropdownOptions = styled.ul`
    position: absolute;
    z-index: 2;
    margin-top: 10px;
    width: 280px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    border-radius: 10px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 15px;
    box-shadow: 0px 4px 24px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    height: 40px;
    background-color: transparent;
    padding-left: 16px;
    cursor: pointer;
    text-transform: capitalize;

    :hover {
        background-color: var(--off-bg-color, #fafafa);
    }
`

const Wrapper = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
`

const Count = styled.div`
    padding-left: 20px;
    padding-top: 12px;
    font-style: italic;
    color: var(--text-muted);
    font-family: 'Lato-Light';
    font-size: 12px;
`

const SelectedCriteriaWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 0px;
`

const BtnText = styled.p`
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
    color: var(--text-darker, #616161);
`

type StateSelect = {
    showDropdown: boolean
    addedCriteria: string[]
    issearchactive: boolean
}

const FilterTimelineByMultipleWithSearchUIDesktop: React.FC<Props> = ({
    dataCyId,
    labelsDataRes,
    handleChange,
    fetchItem,
    value,
    addLabelButton,
    user_searchable_labels_list,
    onSave,
    hasCreateNewLabel,
    clearField,
    selectedFilteringLabels,
}) => {
    let { theme } = useThemes()

    const [state, setState] = useState<StateSelect>({
        showDropdown: false,
        addedCriteria: selectedFilteringLabels ? selectedFilteringLabels : [],
        issearchactive: false,
    })

    const dropdownRef = useRef<HTMLDivElement>(null)
    const searchFieldRef = useRef<HTMLInputElement>(null)

    const handleClickOutsideDropdown = useCallback(
        (e: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(e.target as Node)
            ) {
                setState((prev) => ({
                    ...prev,
                    showDropdown: false,
                    addedCriteria: selectedFilteringLabels
                        ? selectedFilteringLabels
                        : [],
                }))
            }
        },
        [selectedFilteringLabels]
    )

    const handleClickOutsideSearch = useCallback((e: MouseEvent) => {
        if (
            searchFieldRef.current &&
            !searchFieldRef.current.contains(e.target as Node)
        ) {
            setState((prev) => ({ ...prev, issearchactive: false }))
        }
    }, [])

    useEffect(() => {
        dropdownRef.current?.focus()
        document.addEventListener('mousedown', handleClickOutsideDropdown)
        document.addEventListener('mousedown', handleClickOutsideSearch)

        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideDropdown
            )
            document.removeEventListener('mousedown', handleClickOutsideSearch)
        }
    }, [handleClickOutsideDropdown, handleClickOutsideSearch])

    useEffect(() => {
        if (selectedFilteringLabels) {
            setState((prev) => ({
                ...prev,
                addedCriteria: selectedFilteringLabels,
            }))
        }
    }, [selectedFilteringLabels])

    const allDropdownLabelNames = user_searchable_labels_list?.map(
        (label: IDropdownItem) => label.name
    )
    const dropdownLabelNamesResults = labelsDataRes?.map(
        (label: any) => label.item.name
    )

    const handleFilterButtonClick = () => {
        setState((prev) => ({
            ...prev,
            showDropdown: !prev.showDropdown,
        }))
    }

    const currentSelectedCriteria: string[] = [...state.addedCriteria]

    const addOrRemoveCriteria = (criteria: string) => {
        const indexOfClickedCriteria = currentSelectedCriteria.indexOf(criteria)
        if (indexOfClickedCriteria < 0) {
            setState((prev) => ({
                ...prev,
                addedCriteria: [...prev.addedCriteria, criteria],
            }))
        } else {
            currentSelectedCriteria.splice(indexOfClickedCriteria, 1)
            setState((prev) => ({
                ...prev,
                addedCriteria: currentSelectedCriteria,
            }))
        }
    }

    const addedCriteria = state.addedCriteria.map(
        (criteria: string, i: number) => (
            <div style={{ padding: '3px' }} key={`${criteria} - ${i}`}>
                <FadedSlower duration={0.2}>
                    <CustomTagQuickFilterItemDesktop
                        isActive={true}
                        onCrossClick={() => addOrRemoveCriteria(criteria)}
                    >
                        {criteria}
                    </CustomTagQuickFilterItemDesktop>
                </FadedSlower>
            </div>
        )
    )

    const mappedTagsData = (theme: ITheme) =>
        labelsDataRes?.map((item: any, index: number) => {
            const label = item.item
            if (label && index < 3) {
                return (
                    <StyledOption
                        selected={currentSelectedCriteria.includes(label.name)}
                        value={label.name}
                        key={`${label.name}-tag-${index}`}
                    >
                        <ContentRow>
                            <CustomAnimatedCheckboxField
                                width="100%"
                                onClick={() => addOrRemoveCriteria(label.name)}
                                checked={currentSelectedCriteria.includes(
                                    label.name
                                )}
                                small
                                borderColor={colours[theme].text_darker}
                            >
                                <div
                                    style={{
                                        color: colours[theme].text_darker,
                                        paddingLeft: 8,
                                        transform: 'translateY(-3px)',
                                    }}
                                >
                                    {label.name}
                                </div>
                            </CustomAnimatedCheckboxField>
                        </ContentRow>
                    </StyledOption>
                )
            }
            return <StyledOption key={index} style={{ display: 'none' }} />
        })

    const manageissearchactive = (isActive: boolean) => {
        setState((prev) => ({
            ...prev,
            issearchactive: isActive,
        }))
    }

    const checkIfShowAddLabel = () => {
        if (
            !allDropdownLabelNames ||
            (!allDropdownLabelNames.includes(value[0]) &&
                !dropdownLabelNamesResults?.includes(value[0]))
        ) {
            return (
                <FadedSlower>
                    <FullWidthLightGreyBgButton
                        text={`+ Create new label: ${value[0]}`}
                        onClick={() => {
                            addOrRemoveCriteria(value[0])
                            clearField?.()
                        }}
                        nobg
                    />
                </FadedSlower>
            )
        }
    }

    const show_all_options = (theme: ITheme) => {
        return (
            <>
                {user_searchable_labels_list?.map(
                    (label: any, index: number) => {
                        if (index < 10) {
                            return (
                                <StyledOption
                                    selected={currentSelectedCriteria.includes(
                                        label.name
                                    )}
                                    value={label.name}
                                    key={`${label.name}-label-${index}`}
                                >
                                    <CustomAnimatedCheckboxField
                                        width="100%"
                                        onClick={() =>
                                            addOrRemoveCriteria(label.name)
                                        }
                                        checked={currentSelectedCriteria.includes(
                                            label.name
                                        )}
                                        small
                                        borderColor={colours.light.text_darker}
                                    >
                                        <div
                                            style={{
                                                color: colours[theme]
                                                    .text_darker,
                                                paddingLeft: 8,
                                                transform: 'translateY(-3px)',
                                            }}
                                        >
                                            {label.name}
                                        </div>
                                    </CustomAnimatedCheckboxField>
                                </StyledOption>
                            )
                        }
                        return <div style={{ display: 'none' }} />
                    }
                )}

                {user_searchable_labels_list &&
                    user_searchable_labels_list.length > 10 && (
                        <Count>
                            +{10 - user_searchable_labels_list.length - 1}
                        </Count>
                    )}
            </>
        )
    }

    return (
        <Wrapper
            data-attr={dataCyId}
            ref={dropdownRef}
            fixedwidth={addLabelButton ? true : false}
        >
            {addLabelButton ? (
                <AddLabelButton
                    height="24px"
                    onClick={handleFilterButtonClick}
                    isactive={state.showDropdown}
                />
            ) : (
                <StandardCtaBtn
                    onClick={handleFilterButtonClick}
                    boder={
                        state.showDropdown
                            ? `2px solid ${colours[theme].border_muted}`
                            : `1px solid ${colours[theme].border_muted}`
                    }
                    bg={
                        state.showDropdown
                            ? colours[theme].background_neutral_subtle
                            : 'transparent'
                    }
                    customHeight="48px"
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                            position: 'relative',
                        }}
                    >
                        <LabelIcon color={colours[theme].text_darker} />
                        <BtnText>Labels</BtnText>
                        {state.addedCriteria.length > 0 ? (
                            <AbsoluteBlueLabel>
                                {state.addedCriteria.length}
                            </AbsoluteBlueLabel>
                        ) : null}
                    </div>
                </StandardCtaBtn>
            )}

            <SlideDownWrapper
                isOpen={state.showDropdown}
                initialX={-280}
                originX={'280px'}
            >
                <DropdownOptions>
                    <Expander
                        height={state.addedCriteria.length > 0 ? 'auto' : 0}
                    >
                        <SelectedCriteriaWrapper>
                            {addedCriteria}
                        </SelectedCriteriaWrapper>
                    </Expander>

                    {user_searchable_labels_list &&
                    user_searchable_labels_list.length > 0 ? (
                        <>
                            <SearchFieldWrapper
                                onClick={() =>
                                    manageissearchactive &&
                                    manageissearchactive(true)
                                }
                            >
                                <SearchField
                                    placeholder={'Search labels'}
                                    type="search"
                                    onChange={(e: any) => {
                                        handleChange(e.target.value)
                                        fetchItem(e.target.value)
                                    }}
                                    issearchactive={state.issearchactive}
                                    value={value[0]}
                                    ref={searchFieldRef}
                                />
                            </SearchFieldWrapper>
                            <BtnText
                                style={{
                                    paddingLeft: 16,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    color: colours[theme].text_strong,
                                    textAlign: 'left',
                                    fontFamily: 'Lato-Semibold',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    fontSize: 16,
                                }}
                            >
                                Labels in use
                            </BtnText>
                        </>
                    ) : (
                        <BtnText
                            style={{
                                padding: 16,
                                fontStyle: 'italic',
                                color: colours[theme].text_muted,
                                textAlign: 'left',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        >
                            No labels in use
                        </BtnText>
                    )}

                    <FadedSlower>
                        <div
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                maxHeight: 280,
                                overflowY: 'auto',
                            }}
                        >
                            {labelsDataRes.length === 0 &&
                                show_all_options(theme)}
                            <div style={{ paddingTop: 16 }} />
                        </div>
                    </FadedSlower>

                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            maxHeight: 280,
                            overflowY: 'auto',
                        }}
                    >
                        {labelsDataRes &&
                            labelsDataRes.length > 0 &&
                            mappedTagsData(theme)}
                        {labelsDataRes && labelsDataRes.length > 0 && (
                            <div style={{ paddingTop: 16 }} />
                        )}
                    </div>
                    <div
                        style={{
                            paddingTop: hasCreateNewLabel ? '16px' : 0,
                            paddingBottom: '0px',
                        }}
                    >
                        {hasCreateNewLabel &&
                            value &&
                            value[0] !== '' &&
                            value &&
                            value[0] !== undefined &&
                            value[0].trim().length > 1 &&
                            checkIfShowAddLabel()}

                        {are2arraysEqual(
                            selectedFilteringLabels,
                            state.addedCriteria
                        ) !== true && (
                            <FadedSlower>
                                <div
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        paddingTop: 16,
                                        paddingRight: 16,
                                        borderTop: `1px solid ${colours[theme].border_muted}`,
                                    }}
                                >
                                    <ButtonsStandardBottomRowMobile
                                        removePadding
                                        cancel={() => {
                                            setState((prev) => ({
                                                ...prev,
                                                addedCriteria: [],
                                            }))
                                        }}
                                        submit={() => {
                                            onSave(currentSelectedCriteria)
                                            setState((prev) => ({
                                                ...prev,
                                                showDropdown: false,
                                            }))
                                        }}
                                        firstText="Clear"
                                        confirmationtext="Apply"
                                    />
                                </div>
                            </FadedSlower>
                        )}
                    </div>
                </DropdownOptions>
            </SlideDownWrapper>
        </Wrapper>
    )
}

export default FilterTimelineByMultipleWithSearchUIDesktop
