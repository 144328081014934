import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/shareYourCar/private_share.png'
import image1 from '../../../../public/assets/images/journey/shareYourCar/private_share_mobile_1.png'
import image2 from '../../../../public/assets/images/journey/shareYourCar/private_share_mobile_2.png'
import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
    JourneyWelcomeNewLineOnMobile,
} from '../../../templates/styledcomponents/journeyStyles'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function PrivateDirectShareYourCarJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section[
                                        'share_your_car'
                                    ].primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[
                                        `share_your_car`
                                    ].primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        What about something even more secure?
                                    </JourneyStepTitle>

                                    <div />

                                    <JourneyText $theme={theme}>
                                        Custodian supports two types of shares,
                                        Link Shares and Direct Shares. Like
                                        Shares offer greater flexibility and can
                                        be viewed by people outside the
                                        Custodian platform whereas Direct Shares
                                        offer greater security and reside
                                        completely within the Custodian
                                        platform. Direct Shares also allow
                                        sharing History file information.
                                    </JourneyText>

                                    <JourneyText $theme={theme}>
                                        To make a direct share just enter the
                                        email address of who you’d like to share
                                        your car with, and we'll handle the
                                        rest. If they're not yet on Custodian
                                        we’ll send them an invite to sign up
                                        should they want to view your car.
                                    </JourneyText>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'share_your_car'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'share_your_car'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <FadedSlower>
                                <img
                                    src={image}
                                    // placeholder={grey_placeholder}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '45vw',
                                        maxHeight: '80vh',
                                    }}
                                />
                            </FadedSlower>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingBottom: 200,
                            paddingTop: 48,
                            overflow: 'hidden',
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <JourneyStepTitle
                                        $theme={theme}
                                        style={{
                                            paddingLeft: 24,
                                            paddingRight: 24,
                                        }}
                                    >
                                        What about something even
                                        <JourneyWelcomeNewLineOnMobile />
                                        <span
                                            style={{
                                                color: journey_colours[theme]
                                                    .section.share_your_car
                                                    .primary_500,
                                                fontSize: '28px',
                                            }}
                                        >
                                            more secure?
                                        </span>
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                            paddingLeft: 24,
                                            paddingRight: 24,
                                        }}
                                        $theme={theme}
                                    >
                                        Car ownership is best enjoyed together
                                    </JourneyText>
                                </JourneyColumnContent>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                    }}
                                >
                                    Custodian supports two types of shares, Link
                                    Shares and Direct Shares. Link Shares offer
                                    greater flexibility and can be viewed by
                                    people outside the Custodian platform
                                    whereas Direct Shares offer greater security
                                    and reside completely within the Custodian
                                    platform. Direct Shares also allow sharing
                                    History file information.
                                </JourneyText>
                                <FadedSlower>
                                    <img
                                        // placeholder={grey_placeholder}
                                        src={image1}
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            height: 'auto',
                                        }}
                                    />
                                </FadedSlower>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                    }}
                                >
                                    To make a direct share just enter the email
                                    address of who you’d like to share your car
                                    with, and we'll handle the rest. If they're
                                    not yet on Custodian we’ll send them an
                                    invite to sign up should they want to view
                                    your car.
                                </JourneyText>
                                <FadedSlower>
                                    <img
                                        // placeholder={grey_placeholder}
                                        src={image2}
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            height: 'auto',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </FadedSlower>
                            </JourneyColumnContent>
                            {/* add generated qr code with downloading btns */}
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="share_your_car"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
