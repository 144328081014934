import * as React from 'react'
import { useState } from 'react'

import styled from 'styled-components'
import CarShareActionsMobile from '../menu/bottomSheetMenu/carShareActionsMobile'
import UserAccountCircle from '../user/userAccountCircle'
import checked from '../../../public/assets/icons/checked_white.svg'
import pending from '../../../public/assets/icons/pending_icon.svg'
import { shareCardConverterFormat } from '../../../redux/conversions/time'
import { generate_recipients_name } from './shareRowDesktop'
import { device } from '../../templates/displays/devices'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import ThreeDotsIcon from '../icons/components/threeDots'
import { IShareRecipient } from '../../../redux/entities/cars/types'

type IStatus = {
    $status: 'pending' | 'accepted' | 'ignored'
}

const UserImageCircleProfile = styled.div`
    transform: translateY(-25px);
    padding-right: 20px;
`

const StatusCircle = styled.div<IStatus>`
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -2px;
    margin-right: 15px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${(props) =>
        props.$status === 'accepted'
            ? 'var(--primary, #5ec3ca)'
            : 'var(--border-muted, #e5e5e5)'};
    display: flex;
    align-items: center;
    justify-content: center;
`

const TableItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
`

const TableItemInfoCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8px;
    position: relative;
    @media ${device.mobile} {
        padding-left: 0px;
    }
`

const Name = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 50vw;
`

const DateSpan = styled.span`
    color: var(--text-default, #666666);
    font-size: 12px;
    line-height: 15px;
    font-style: italic;
    display: inline-block;
`
const Email = styled.div`
    color: var(--text-darker, #616161);
    font-size: 12px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding-bottom: 5px;
    @media ${device.mobile} {
        max-width: 50vw;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    background-color: var(--off-bg-color, #fafafa);
    border: 1px solid var(--border_muted);
    height: 90px;
    width: 100%;
    box-sizing: border-box;
`

const ThreeDotsContainer = styled.div`
    width: 22px;
    height: 22px;
    transform: rotate(90deg);
    border: 1px solid var(--primary_20);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary_04);
`

type IQuickShareActions = {
    email?: any
    // copy: any
    // settings: any
    delete: any
}

type IactionsItems = {
    id: string
    action?: any
    text?: string
}

type Props = {
    recipient: IShareRecipient
    sharedOn: string
    index: number
    isLast: boolean
    actionsData: IQuickShareActions
}

const ShareRowMobile: React.FunctionComponent<Props> = (props: Props) => {
    let { recipient, sharedOn, isLast, index, actionsData } = props

    const [isCarSharesMenuOpen, toggleCarSharesMenuOpen] = useState(false)

    let actionItems: IactionsItems[] = []

    for (const [key, value] of Object.entries(actionsData)) {
        actionItems.push({
            id: key,
            action: value,
            text:
                key === 'delete'
                    ? 'Delete recipient'
                    : 'Send email notification',
        })
    }

    const { theme } = useThemes()

    return (
        <>
            <Container
                style={{
                    borderTopLeftRadius: index === 0 ? '5px' : '0px',
                    borderBottomLeftRadius: isLast ? '5px' : '0px',

                    borderTopRightRadius: index === 0 ? '5px' : '0px',
                    borderBottomRightRadius: isLast ? '5px' : '0px',
                }}
                onClick={() => toggleCarSharesMenuOpen(true)}
            >
                <TableItemContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <UserImageCircleProfile>
                            <UserAccountCircle
                                size="50px"
                                url={recipient.profile_picture?.url}
                            />
                            <StatusCircle
                                $status={
                                    recipient.approvalStatus
                                        ? recipient.approvalStatus
                                        : 'pending'
                                }
                            >
                                <img
                                    alt=""
                                    src={
                                        recipient.approvalStatus === 'accepted'
                                            ? checked
                                            : pending
                                    }
                                    width={
                                        recipient.approvalStatus === 'accepted'
                                            ? '10px'
                                            : '15px'
                                    }
                                />
                            </StatusCircle>
                        </UserImageCircleProfile>

                        <TableItemInfoCol>
                            <Name>{generate_recipients_name(recipient)}</Name>
                            {recipient.recipient_type === 'external' && (
                                <Email>{recipient.email}</Email>
                            )}
                            <DateSpan>{` Shared on ${shareCardConverterFormat(
                                sharedOn
                            )}`}</DateSpan>
                        </TableItemInfoCol>
                    </div>
                    <ThreeDotsContainer>
                        <ThreeDotsIcon
                            size={'3'}
                            color={colours[theme].primary}
                        />
                    </ThreeDotsContainer>
                </TableItemContainer>
            </Container>
            <CarShareActionsMobile
                toggle={() =>
                    isCarSharesMenuOpen
                        ? toggleCarSharesMenuOpen(false)
                        : toggleCarSharesMenuOpen(true)
                }
                entity={recipient}
                isCarSharesMenuOpen={isCarSharesMenuOpen}
                actions_items={actionItems}
                customHeight="130px"
            />
        </>
    )
}

export default ShareRowMobile
