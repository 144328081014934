import styled from 'styled-components'
import './style.css'

interface IInputFieldProps {
    name: string
    value?: string | number | undefined
    placeholder?: string
    handleChange?: (e: any) => any
    type?: any
    onBlur?: any
    onFocus?: any
    onChange?: any
    onEnterKeyPress?: any
    disabled?: boolean
    tabindex?: number | string
    font_family?: string
    font_size?: string
    width?: string
    height?: string
    noChangeAllowed?: boolean
    hasError?: boolean
    dataCyId: string
    minNumber?: number
    maxNumber?: number
}

interface StandardInputProps {
    disabled?: boolean
    tabindex?: number | string
    font_family?: string
    font_size?: string
    width?: string
    height?: string
    hasError?: boolean
}

const StandardInput = styled.input<StandardInputProps>`
    font-family: Lato;
    text-align: center !important;
    border: ${(props) =>
        props.hasError === true
            ? '1px solid var(--error, #df6f6f)'
            : '1px solid var(--text-muted)'};
    border-radius: 5px;
    color: ${(props) =>
        props.hasError === true
            ? 'var(--error, #df6f6f)'
            : 'var(--text-default, #666666)'};
    pointer-events: ${(props) => props.disabled === true && 'none !important '};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    padding: 12px;
    font-size: ${(props) => (props.font_size ? props.font_size : '16px')};
    width: ${(props) => (props.width ? props.width : '100%')};
    box-sizing: border-box;
    height: ${(props) => (props.height ? props.height : '42px')};
    background-color: transparent;

    ::placeholder {
        font-family: Lato-Light;
        color: var(--text-default, #666666);
        font-size: ${(props) => (props.font_size ? props.font_size : '16px')};
    }
    :focus {
        border: 1px solid var(--primary, #5ec3ca);
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
    }
`

function BlueBorderNumberInput({
    name,
    value,
    placeholder,
    type,
    onBlur,
    onChange,
    onEnterKeyPress,
    disabled,
    tabindex,
    font_family,
    width,
    noChangeAllowed,
    hasError,
    dataCyId,
    height,
    minNumber,
    maxNumber,
}: IInputFieldProps) {
    return (
        <StandardInput
            id={dataCyId}
            name={name}
            type={'number'}
            value={value && value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            tabindex={tabindex}
            font_family={font_family && font_family}
            width={width && width}
            height={height && height}
            onKeyPress={(e: any) =>
                e.which === 13 && onEnterKeyPress && onEnterKeyPress()
            }
            tabIndex={1}
            autoFocus={true}
            readOnly={noChangeAllowed}
            hasError={hasError}
            min={minNumber}
            max={maxNumber}
        />
    )
}

export default BlueBorderNumberInput
