import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import { Radio } from 'antd'
import { device } from '../../../templates/displays/devices'
import { IList } from 'entityModels'

import '../../../atoms/formField/antd.css'
import { IUSerGeneralInfoUpdatePayload } from '../../../../redux/user/types'

const RowWrapperUnit = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: Lato;
`
const UnitTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 17px;
    width: 160px;
`

const UnitLine = styled.div`
    height: 30px;
    width: 1px;
    background-color: var(--border-muted, #e5e5e5);
    margin-right: 50px;
`

const RadioStyled = styled(Radio)`
    font-family: Lato !important;
    align-self: flex-start !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--text-darker, #616161) !important;

    margin-right: 32px;

    .ant-radio-inner {
        background-color: var(--bg-color, #fff) !important;
    }

    @media ${device.mobile} {
    }
`
const Row = styled.div`
    align-self: flex-start !important;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
`

interface Values {
    form_unique_value: any
}

type Iobj = {
    [key: string]: IList
}

interface OtherProps {
    title: any
    item: IList
    data: Iobj
    ids_list: string[]
    field_id: string
    submitFunc: any
    userid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    // values,
    // toggle,
    // item,
    const { title, ids_list, data, setFieldValue, values, handleSubmit } = props

    return (
        <RowWrapperUnit>
            <UnitTitle>{title}</UnitTitle>

            <UnitLine />

            <Radio.Group defaultValue={values.form_unique_value}>
                <Row>
                    {ids_list.map((id: string, index: any) => {
                        if (data[id] !== undefined) {
                            return (
                                <RadioStyled
                                    key={index}
                                    id={data[id].id}
                                    type="radio"
                                    value={data[id].id}
                                    name={data[id].id}
                                    onChange={() => {
                                        setFieldValue(
                                            'form_unique_value',
                                            data[id].id
                                        )
                                        handleSubmit()
                                    }}
                                >
                                    <div
                                        style={{ transform: 'translateY(3px)' }}
                                    >
                                        {data[id].text}
                                    </div>
                                </RadioStyled>
                            )
                        } else return <div style={{ display: 'none' }} />
                    })}
                </Row>
            </Radio.Group>
        </RowWrapperUnit>
    )
}

const PreferencesInlineFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        form_unique_value: props.item.id,
    }),
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        let dataToSend: IUSerGeneralInfoUpdatePayload = {
            id: props.userid,
            data: {
                user_preferences: {
                    [props.field_id]: values.form_unique_value,
                },
            },
        }

        props.submitFunc(dataToSend)
        setSubmitting(false)
    },
})(InnerForm)

export default PreferencesInlineFormDesktop
