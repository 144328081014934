import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMenuState, IToggleTutorialmode } from './types'
import { IReduxError } from '../entities/cars/types'

export type IManageTopSubmenuSuccessArg = {
    isRemoved: boolean
}

// later on : to retrieve usertype + questionsList from back end, so to add userPagesArray from back end

const initialState: IMenuState = {
    error: null,
    loading: false,
    isDefaultNavigationRemoved: false,
    publicMobileMenu: {
        isOpen: false,
    },
    desktopMainAppMenu: {
        isCollapsed: false,
    },
    mobileAppSubMenu: {
        isOpen: false,
    },
    slideshowGallery: {
        isOpen: false,
    },
    isAddActionsOpen: {
        isOpen: false,
    },
    isTutorialOn: {
        isOpen: false,
        id: false,
    },
    pwa: {
        hasSeenAndroidPWABanner: false,
        hasSeenIosPWABanner: false,
        isNativeAndroidPWABannerActive: false,
    },
    mobileAccountMenu: {
        isOpen: false,
    },
    mobileTimelineFilterBottomSheetMenu: {
        isOpen: false,
    },
}

export const menuReducer = createSlice({
    name: 'menus',
    initialState: initialState,
    reducers: {
        manageTopSubmenu: (
            state: IMenuState,
            action: PayloadAction<IManageTopSubmenuSuccessArg>
        ) => {
            state.isDefaultNavigationRemoved = action.payload.isRemoved
        },

        togglePublicMobileMenu: (state: IMenuState) => {
            state.publicMobileMenu.isOpen = !state.publicMobileMenu.isOpen
        },

        toggleDesktopMainAppMenu: (
            state: IMenuState,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.desktopMainAppMenu.isCollapsed =
                action.payload ?? !state.desktopMainAppMenu.isCollapsed
        },

        toggleMobileAppSubMenu: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.mobileAppSubMenu.isOpen = action.payload
        },

        toggleMobileAccountMenu: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.mobileAccountMenu.isOpen = action.payload
        },

        toggleGallerySlideshow: (state: IMenuState) => {
            state.slideshowGallery.isOpen = !state.slideshowGallery.isOpen
            state.loading = false
        },

        setGallerySlideshowRequest: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.loading = true
        },
        setGallerySlideshowSuccess: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.slideshowGallery.isOpen = action.payload
            state.loading = false
        },
        setGallerySlideshowError: (
            state: IMenuState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        toggleAddActions: (
            state: IMenuState,
            action: PayloadAction<string | undefined>
        ) => {
            state.isAddActionsOpen.isOpen = !state.isAddActionsOpen.isOpen
            state.isAddActionsOpen.id = action.payload
        },

        toggleOnboardingMode: (
            state: IMenuState,
            action: PayloadAction<IToggleTutorialmode>
        ) => {
            state.isTutorialOn = {
                isOpen: action.payload.isIopen
                    ? action.payload.isIopen
                    : !state.isTutorialOn.isOpen,
                id: action.payload.id
                    ? action.payload.id
                    : state.isTutorialOn.id,
            }
        },

        toggleHasSeenIosPWABanner: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.pwa.hasSeenIosPWABanner = action.payload
        },

        toggleHasSeenAndroidPWABanner: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.pwa.hasSeenAndroidPWABanner = action.payload
        },

        toggleisNativeAndroidPWABannerActive: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.pwa.isNativeAndroidPWABannerActive = action.payload
        },

        toggleFilterBottomSheetMenu: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.mobileTimelineFilterBottomSheetMenu.isOpen = action.payload
        },
    },
})

export const menuActions = menuReducer.actions

export default menuReducer.reducer
