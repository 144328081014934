import { IPayloadCarGalleryImageFromApi } from '../../services/typedefinitions/apiPayloads'
import { IShowroomEntry, IShowroomEntryAPI } from '../../showroom/types'
import { convertToCarState } from '../entities/conversionFromAPI'
import { ConvertRawToNewImageEntity } from '../entities/ImageTypeConversions'
import { ConvertUserApitoUserState } from '../user/userConversions'

export const ConvertShowroomEntryApiToState = (
    apiPayload: IShowroomEntryAPI
): IShowroomEntry => {
    let res: IShowroomEntry = {
        ...apiPayload,
        id: apiPayload.uid,
        car: undefined,
        gallery_images: undefined,
        cover_image: undefined,
        banner_image: undefined,
        featured_image: undefined,
        owner: apiPayload.owner
            ? ConvertUserApitoUserState(apiPayload.owner)
            : undefined,

        local_price_max: apiPayload.local_price_max
            ? +apiPayload.local_price_max / 100
            : undefined,
        local_price_min: apiPayload.local_price_min
            ? +apiPayload.local_price_min / 100
            : undefined,
        local_currency: apiPayload.local_currency,

        listing_price_max: apiPayload.listing_price_max
            ? +apiPayload.listing_price_max / 100
            : undefined,
        listing_price_min: apiPayload.listing_price_min
            ? +apiPayload.listing_price_min / 100
            : undefined,
        listing_currency: apiPayload.listing_currency,
        is_watched: apiPayload.is_watched,
        undisclosed_mileage: apiPayload.undisclosed_mileage,
    }

    let img_prop_ids: ('cover_image' | 'banner_image' | 'featured_image')[] = [
        'cover_image',
        'banner_image',
        'featured_image',
    ]

    if (apiPayload.gallery_images) {
        res.gallery_images = apiPayload.gallery_images.map(
            (img: IPayloadCarGalleryImageFromApi) => {
                return ConvertRawToNewImageEntity(img)
            }
        )
    }

    for (let i = 0; i < img_prop_ids.length; i++) {
        if (apiPayload[img_prop_ids[i]]) {
            res[img_prop_ids[i]] = ConvertRawToNewImageEntity(
                apiPayload[img_prop_ids[i]]!
            )
        }
    }

    if (apiPayload.car) {
        res.car = convertToCarState(apiPayload.car)[apiPayload.car.uid]
    }

    return res
}
