import OnboardingFormLayoutMobile, {
    OnboardingText,
    PaddingUnit,
} from '../../../templates/onboarding/onboardingFormLayoutMobile'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import OnboardingImageUploadSubForm from './onboardingImageUploadSubForm'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import AnimatedTextareaShort from '../../../atoms/textarea/animatedTextareaShort'
import styled from 'styled-components'
import { fileStorageActions } from '../../../../redux/filestorage/reducer'
import { IUpdateCarGeneralPayloadReq } from '../../../../redux/entities/cars/types'
import { carActions } from '../../../../redux/entities/cars/reducer'

const CarBioTitle = styled.h3`
    margin: 0;
    padding: 0;
    color: var(--text-darker, #616161);
    font-family: Lato-bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

type Props = {
    formStep: number
    formTitle: string
    next: () => void
    carid: string
    formText: string
    formMutedText: string
    carBioPlaceholder: string
}

const OnboardingGalleryImageAndBioMobile = (props: Props) => {
    let windowWidth = useWindowSize().width
    let {
        carid,
        formStep,
        formTitle,
        next,
        formText,
        formMutedText,
        carBioPlaceholder,
    } = props

    const dispatch = useAppDispatch()

    const gallerypresignedurls = useAppSelector(
        (state) => state.fileStorage.gallerypresignedurls
    )

    let storageLoading = useAppSelector((state) => state.fileStorage.loading)

    const [formData, setFormData] = useState<{
        bio: string | undefined
        coverImage: File | undefined
    }>({ bio: undefined, coverImage: undefined })

    const onSubmit = () => {
        if (formData.coverImage && gallerypresignedurls) {
            let imgFile: File[] = []
            imgFile[0] = formData.coverImage
            let imgPayload = {
                carid: carid,
                gallerypresignedurls: gallerypresignedurls,
                files: imgFile,
                fields: {
                    caption: undefined,
                    credit: undefined,
                    location: undefined,
                },
                isFromQuickActions: true,
                showNoProgress: true,
            }

            dispatch(
                fileStorageActions.submitCarGalleryImagesFilestorageRequest(
                    imgPayload
                )
            )
        }

        if (formData.bio) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: carid,
                isOnboarding: true,
                dataListForm: [
                    {
                        id: 'short_description',
                        text: 'Short description',
                        answer: formData.bio,
                    },
                ],
            }
            dispatch(carActions.writeCarDataGeneralRequest(payload))
        }

        next()
    }

    return (
        <OnboardingFormLayoutMobile
            step={formStep}
            title={formTitle}
            submit={() => {
                storageLoading === false && onSubmit()
            }}
            skip={next}
            isBtnLoading={storageLoading ? true : false}
        >
            <div
                style={{
                    paddingTop: windowWidth && windowWidth > 700 ? 32 : 10,
                }}
            />
            <OnboardingText>{formText}</OnboardingText>
            <PaddingUnit $times={1} />
            <OnboardingText style={{ opacity: 0.6, fontSize: '14px' }}>
                {formMutedText}
            </OnboardingText>
            <PaddingUnit $times={5} />
            <OnboardingImageUploadSubForm
                carid={carid}
                imageToUpload={formData.coverImage}
                setImageToUpload={(file: File | undefined) => {
                    setFormData((prevState) => {
                        return {
                            ...prevState,
                            coverImage: file,
                        }
                    })
                }}
            />
            <PaddingUnit $times={5} />
            <CarBioTitle>Car bio</CarBioTitle>
            <PaddingUnit $times={2} />
            <AnimatedTextareaShort
                name={''}
                value={formData.bio ? `${formData.bio}` : ''}
                placeholder={carBioPlaceholder}
                handleChange={(e: any) => {
                    setFormData((prevState) => {
                        return {
                            ...prevState,
                            bio: e.target.value,
                        }
                    })
                }}
                furtherStyle={{
                    fontSize: 16,
                    fontFamily: 'Lato',
                    lineHeight: '24px',
                    color: 'var(--text-default, #666)',
                }}
                height="180px"
                maxLength={256}
            />
            <p
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    textAlign: 'right',
                    fontFamily: 'Lato',
                    fontSize: 12,
                    paddingRight: 16,
                    paddingTop: 4,
                }}
            >{`${formData.bio ? formData.bio.length : 0} / 256`}</p>
        </OnboardingFormLayoutMobile>
    )
}

export default OnboardingGalleryImageAndBioMobile
