import * as React from 'react'
import SquareDuoMobile from '../../molecules/imageGrid/squareDuoMobile'
import AddImageBanner from '../../atoms/image/addImageBanner'
import Loader from '../../atoms/loader/loader'
import { IRootState } from '../../../redux/store'
import { useAppSelector } from '../../../redux/store/hooks'

export interface IImageProps {
    url: string
}

interface Props {
    ids_list: any
    openImgForm?: any
    isExternalCar?: boolean
}

const EntryGalleryManagerMobile: React.FC<Props> = ({
    ids_list,
    openImgForm,
    isExternalCar,
}) => {
    const [isEmpty, setIsEmpty] = React.useState<boolean | null>(true)

    const imagesData = useAppSelector(
        (state: IRootState) => state.entities.galleriesData.images
    )
    const isNecessaryGalleryDataLoading = useAppSelector(
        (state: IRootState) => state.entities.galleriesData.loading
    )
    const isGettingCarDataLoading = useAppSelector(
        (state: IRootState) => state.entities.carsData.loading
    )

    React.useEffect(() => {
        if (ids_list && ids_list.length < 1) {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }
    }, [ids_list])

    return (
        <React.Fragment>
            {isEmpty !== true &&
                !isNecessaryGalleryDataLoading &&
                !isGettingCarDataLoading &&
                imagesData && (
                    <SquareDuoMobile
                        images_data_object={imagesData}
                        ids_list={ids_list}
                        onAddClick={openImgForm}
                        readOnlyMode={isExternalCar ? true : false}
                    />
                )}

            <Loader
                isLoading={
                    isGettingCarDataLoading || isNecessaryGalleryDataLoading
                }
            />

            {isEmpty === true && !isExternalCar && (
                <AddImageBanner ipadHeight="350px" onClick={openImgForm} />
            )}
        </React.Fragment>
    )
}

export default EntryGalleryManagerMobile
