import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
    IManageTopSubmenuSuccessArg,
    menuActions,
} from '../../../../redux/menus/reducer'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import Loader from '../../../atoms/loader/loader'
import ShowroomEditGalleryGrid from '../../../molecules/imageGrid/showroomEditGalleryGrid'
import CarGalleryShowroomEditBottomBar from '../../../templates/bars/showroomBars/bottomBars/carGalleryShowroomEditBottomBar'
import DescriptionImageShowroomEditTopBar from '../../../templates/bars/showroomBars/topBars/descriptionImageShowroomEditTopBar'
import { usersActions } from '../../../../redux/user/reducer'
import { ICar } from '../../../../redux/entities/cars/types'
import { carActions } from '../../../../redux/entities/cars/reducer'
import { IGalleryImagesObject } from '../../../../redux/entities/galleries/types'

type EntryParams = {
    entryid: string
}

const DescriptionImageShowroomEdit: React.FunctionComponent = () => {
    const { entryid } = useParams<EntryParams>()
    const navigate = useNavigate()

    const [selectedImage, setSelectedImage] = useState<string | undefined>(
        undefined
    )

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
    }, [])

    let userShowroomEntriesData = useAppSelector((state) => {
        return state.showroom.userShowroomEntries
    })

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let currentEntry: IShowroomEntry | undefined =
        entryid &&
        userShowroomEntriesData &&
        userShowroomEntriesData[`${entryid}`]
            ? userShowroomEntriesData[`${entryid}`]
            : undefined

    let carProfile: ICar | undefined = currentEntry
        ? currentEntry.car
        : undefined

    let dispatch = useAppDispatch()
    const removeTopSubMenu = (status: IManageTopSubmenuSuccessArg) =>
        dispatch(menuActions.manageTopSubmenu(status))

    useEffect(() => {
        // trigger the function to dispatch the top sub menu remove action
        removeTopSubMenu({ isRemoved: true })

        if (userLoggedIn) {
            if (carProfile?.id && carsData && !carsData[carProfile?.id]) {
                dispatch(carActions.getCarDataByIdRequest(carProfile.id))
            }

            if (
                (entryid && !userShowroomEntriesData) ||
                (userShowroomEntriesData &&
                    !userShowroomEntriesData[`${entryid}`])
            ) {
                dispatch(
                    showroomActions.getShowroomEntryByIDRequest({
                        is_owner: true,
                        entryID: `${entryid}`,
                    })
                )
            }

            if (
                userShowroomEntriesData &&
                userShowroomEntriesData[`${entryid}`] &&
                userShowroomEntriesData[`${entryid}`].featured_image
            ) {
                setSelectedImage(
                    userShowroomEntriesData[`${entryid}`].featured_image!.id
                )
            }
        }
        // set up nav back as before on page unmount
        return () => {
            removeTopSubMenu({ isRemoved: false })
        }
    }, [entryid, userShowroomEntriesData, userLoggedIn])

    let images_data: IGalleryImagesObject | null = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let isNecessaryGalleryDataLoading: boolean = useAppSelector((state) => {
        return state.entities.galleriesData.loading
    })

    let isGettingCarDataLoading = useAppSelector((state) => {
        return state.entities.carsData.loading
    })

    let onSelect = (id: string) => {
        setSelectedImage(id)
    }

    return (
        <React.Fragment>
            <DescriptionImageShowroomEditTopBar isMobile={false} />

            <div style={{ paddingTop: '80px' }} />

            {images_data &&
                !isGettingCarDataLoading &&
                carProfile &&
                !isNecessaryGalleryDataLoading &&
                images_data && (
                    <ShowroomEditGalleryGrid
                        images_data_object={images_data}
                        ids_list={carProfile.gallery.images}
                        onSelect={onSelect}
                        selectedImages={selectedImage ? [selectedImage] : []}
                        activeStep={'select'}
                        setSelectedImages={(p: string[]) =>
                            setSelectedImage(p[0])
                        }
                    />
                )}

            {isNecessaryGalleryDataLoading === true ||
                (isGettingCarDataLoading === true && (
                    <Loader isLoading={isNecessaryGalleryDataLoading} />
                ))}

            <CarGalleryShowroomEditBottomBar
                txt={
                    'Selected image will be displayed in Showroom next to the Car Description.'
                }
                cancel={() => {
                    navigate(`/showroom/${entryid}/edit`)
                }}
                isMobile={false}
                save={() => {
                    if (
                        currentEntry &&
                        currentEntry.id &&
                        carProfile &&
                        carProfile.id
                    ) {
                        dispatch(
                            showroomActions.updateEntryShowroomRequest({
                                data: {
                                    featured_image_id: selectedImage,
                                },
                                car_id: carProfile.id,
                                uid: currentEntry.id,
                            })
                        )
                        navigate(`/showroom/${entryid}/edit`)
                    }
                }}
            />
        </React.Fragment>
    )
}

export default DescriptionImageShowroomEdit
