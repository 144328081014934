import { IHighlightedFactsObject } from 'entityModels'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../../redux/store/hooks'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import { device } from '../../templates/displays/devices'
import KeyMomentCardDesktop from '../cards/keyFacts/keyMomentCardDesktop'
import React from 'react'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const Container = styled.div<{ $theme: ITheme }>`
    height: 430px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    overflow-x: auto;

    @media ${device.beyond_ipad_mobile} {
        padding-left: 50px;
    }
    @media (min-width: 2000px) {
        padding-left: 0px;
    }

    :hover {
        /* Track */
        ::-webkit-scrollbar-track {
            background: ${(props) =>
                colours[props.$theme].background_neutral_subtle};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: ${(props) => colours[props.$theme].border_muted};
        }
    }

    /* width */
    ::-webkit-scrollbar {
        height: 6px;
        border-radius: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 20px;
    }
`

interface Props {
    highlighted_facts_ids: string[]
    onClick?: any
    readOnlyMode?: boolean
}

type EnumerableObject = {
    id: string
    text: string
    order: number
}

export const sortList = (list: EnumerableObject[]) => {
    return list.sort(
        (a: EnumerableObject, b: EnumerableObject) => a.order - b.order
    )
}

const CarKeyMomentsfactsDesktopV2 = (props: Props) => {
    let highlighted_facts_data: IHighlightedFactsObject = useAppSelector(
        (state) => {
            return state.entities.highlightedFactsData.highlighted_facts
        }
    )

    const [items, setItems] = useState<EnumerableObject[]>([])

    let { highlighted_facts_ids } = props
    useEffect(() => {
        let list = highlighted_facts_ids.map((id: string, index) => {
            return highlighted_facts_data[id]
        })

        let ordered_list = sortList(list)

        setItems(ordered_list)
    }, [highlighted_facts_data, highlighted_facts_ids])

    const isReadonlyAndEmpty =
        props.readOnlyMode &&
        highlighted_facts_ids.filter(
            (id) => highlighted_facts_data[id].text.length === 0
        ).length === 5

    const { theme } = useThemes()

    return isReadonlyAndEmpty ? (
        <NoDataRectangle height="300px" text="No key Moments & Facts" />
    ) : (
        <Container $theme={theme}>
            {/* <SubContainer> */}
            {items &&
                items.map((obj: EnumerableObject, index: number) => {
                    return (
                        <React.Fragment key={`kmf_card_${index}_desktop`}>
                            <KeyMomentCardDesktop
                                isReadOnly={props.readOnlyMode}
                                onClick={props.onClick}
                                obj={obj}
                                isLast={index === items.length - 1}
                            />
                        </React.Fragment>
                    )
                })}
            {/* </SubContainer> */}
        </Container>
    )
}

export default CarKeyMomentsfactsDesktopV2
