import React from 'react'
import styled from 'styled-components'
import AddNewGreyButton from '../../atoms/Button/addNewGreyButton'
import { device } from '../../templates/displays/devices'
import { Link, useLocation } from 'react-router-dom'
import SectionHeaderV3 from '../../atoms/typography/sectionHeaderV3'
import NavigateSquareIcon from '../../atoms/icons/components/navigateSquareIcon'
import { ITimelineItem } from '../../../redux/timeline/types'
import { IExternalCar } from '../../../redux/entities/cars/types'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @media ${device.ipad} {
        transform: translateY(-17px);
    }
    @media (min-width: 1100px) and (max-width: 1200px) {
        padding-left: 0px;
        padding-right: 30px;
    }
`

const TitleWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

const LeftDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

const RightDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
`

const SmallPadding = styled.div`
    padding-left: 10px;
`

const VerticalLine = styled.div`
    margin-left: 10px;
    margin-right: 20px;
    height: 24px;
    width: 1px;
    background-color: var(--text-strong, #1a1a1a);
`

interface Props {
    item: ITimelineItem
    goBackToEntry?: () => void
    uploadImage: () => void
    carID: string
    readOnlyMode?: boolean
    sharedCar?: IExternalCar
}

const EntryGalleryPageTopHeaderDesktop: React.FC<Props> = ({
    item,
    goBackToEntry,
    uploadImage,
    carID,
    readOnlyMode,
    sharedCar,
}) => {
    const location = useLocation()
    const readOnlyLinkPrefix = sharedCar ? '/shared' : '/archives'

    return (
        <Wrapper>
            <LeftDiv>
                <TitleWrapper>
                    <Link
                        to={{
                            pathname: readOnlyMode
                                ? `${readOnlyLinkPrefix}/car/${carID}/history-file/entry`
                                : `/car/${carID}/history-file/entry`,
                            search: `?entryid=${item.id}`,
                        }}
                        state={{
                            prevPath: location.pathname,
                        }}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <NavigateSquareIcon />{' '}
                    </Link>
                    <SmallPadding />
                    <SmallPadding />
                    <SectionHeaderV3
                        fontsize="24px"
                        icon="v2_edit"
                        onClick={() => {}}
                        hasActionIcon={false}
                    >
                        {item.title}
                    </SectionHeaderV3>
                    <VerticalLine />{' '}
                    <span
                        style={{
                            fontFamily: 'Lato-light',
                            fontSize: '18px',
                        }}
                    >
                        Gallery
                    </span>
                </TitleWrapper>
            </LeftDiv>
            {!readOnlyMode && (
                <RightDiv>
                    <AddNewGreyButton
                        text="Upload image"
                        onClick={uploadImage}
                    />
                </RightDiv>
            )}
        </Wrapper>
    )
}

export default EntryGalleryPageTopHeaderDesktop
