import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { randomCarSvgPlaceholderFunc } from '../../../../helpers/random/randomPlaceholder'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { HistoryFilesIcon } from '../../../atoms/icons/components'
import LastUpdateIcon from '../../../atoms/icons/components/lastUpdatedICon'
import { IGalleryImagesObject } from '../../../../redux/entities/galleries/types'
import { ICar } from '../../../../redux/entities/cars/types'
import useThemes from '../../../../providers/theme/hooks'

interface IStyle {
    hasNoEntry?: boolean
    index: number
}

const ShadowBox = styled(Link)<IStyle>`
    position: relative;
    height: 100%;
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    min-width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    box-sizing: border-box;

    box-shadow: 0px 4px 24px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
    border-radius: 6px;
    background-color: var(--shadow-cards-bg, #fff);

    transition: all 100ms;
    :hover {
        background: var(--primary_04);
        transition: all 100ms;
    }
`

const CarTitle = styled.h3`
    padding: 0px;
    margin: 0px;
    font-size: 17px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    text-transform: uppercase;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`

const Col = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`
type IImageStyle = {
    isthingloading: boolean
}

const CarProfileImage = styled.img<IImageStyle>`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    object-fit: cover;
`

const CarImgWrapper = styled.div`
    height: 128px;
    min-width: 170px;
    max-width: 170px;
    border-radius: 8px;
`

const EntriesCount = styled.div`
    font-size: 14px;
    color: var(--text-default, #666666);
    font-family: Lato;
`
const LastUpdated = styled.div`
    font-size: 14px;
    color: var(--text-default, #666666);
    font-family: Lato;
`

interface Props {
    car: ICar
    garageId?: string
    index: number
    imagesData: IGalleryImagesObject | null
    carIndex: number
}

const GeneralTimelineCardDesktop: React.FC<Props> = ({
    car,
    index,
    carIndex,
    imagesData,
}) => {
    const navigate = useNavigate()
    const location = useLocation()

    let { theme } = useThemes()

    const get_car_cover_url = (carObj: ICar) => {
        if (carObj && carObj.gallery) {
            let imageid =
                carObj.gallery && carObj.gallery.cover
                    ? carObj.gallery.cover
                    : undefined

            if (imageid && imagesData) {
                if (imagesData[imageid] && imagesData[imageid].medium_srcset) {
                    return {
                        srcset: imagesData[imageid].medium_srcset,
                        url: imagesData[imageid].url,
                    }
                } else if (imagesData[imageid] && imagesData[imageid].url) {
                    return {
                        url: imagesData[imageid].url,
                    }
                }
            }
        }

        return {
            url: undefined,
        }
    }

    let imageid =
        car.gallery && car.gallery.cover ? car.gallery.cover : undefined

    const [isLoading, setIsLoading] = React.useState(true)
    const [imgSrc, setImgSrc] = useState<string | undefined>(undefined)

    useEffect(() => {
        const cover_img_src = get_car_cover_url(car)
        const imageSrc =
            cover_img_src.url || randomCarSvgPlaceholderFunc(undefined, theme)
        if (imageSrc) {
            setImgSrc(imageSrc)
        }
    }, [car, theme])

    return (
        <>
            {car ? (
                <ShadowBox
                    index={index}
                    to={{
                        pathname: `/car/${car.id}/history-file`,
                    }}
                    onClick={(e) => {
                        e.preventDefault()
                        navigate(`/car/${car.id}/history-file`, {
                            state: {
                                prevPath: location.pathname,
                            },
                        })
                    }}
                    hasNoEntry={
                        !car.car_entries_count || car.car_entries_count === 0
                    }
                >
                    <CarImgWrapper>
                        <SkeletonAbsolute isthingloading={isLoading} />
                        <CarProfileImage
                            src={imgSrc}
                            onLoad={() => setIsLoading(false)}
                            isthingloading={isLoading}
                            srcSet={
                                imageid &&
                                imagesData &&
                                imagesData[imageid] &&
                                imagesData[imageid].small_srcset
                                    ? imagesData[imageid].small_srcset
                                    : undefined
                            }
                            aria-label="car profile image"
                        />
                    </CarImgWrapper>

                    <Col style={{ minWidth: 0 }}>
                        <CarTitle>
                            {car.title.trim() !== ''
                                ? car.title
                                : `Untitled #${carIndex + 1}`}
                        </CarTitle>

                        <div style={{ paddingTop: '16px' }} />

                        <Row>
                            <HistoryFilesIcon
                                size="18"
                                color={'var(--text-default, #666666)'}
                            />

                            <EntriesCount>
                                {car.car_entries_count
                                    ? car.car_entries_count
                                    : 0}{' '}
                                {car.car_entries_count !== undefined &&
                                car.car_entries_count !== 1
                                    ? 'Entries'
                                    : 'Entry'}
                            </EntriesCount>
                        </Row>
                        <div style={{ paddingTop: '8px' }} />
                        <Row>
                            <LastUpdateIcon />

                            <LastUpdated>
                                Updated -{' '}
                                {!car.car_entries_count
                                    ? 'NA'
                                    : car.car_entries_count === 0
                                    ? 'NA'
                                    : car.last_entry_updated_at_date
                                    ? car.last_entry_updated_at_date
                                    : 'NA'}
                            </LastUpdated>
                        </Row>
                    </Col>
                </ShadowBox>
            ) : (
                <div />
            )}
        </>
    )
}

export default GeneralTimelineCardDesktop
