import * as React from 'react'
import styled from 'styled-components'
import ButtonAtom from '../Button/ButtonAtom'
import NoDataRectangle from '../noData/noDataRectangle'
import placeholderImg from '../../atoms/skeleton/description/skeleton.jpg'
import placeholderImg_dark from '../../atoms/skeleton/description/skeleton_dark.png'
import useThemes from '../../../providers/theme/hooks'

type IProps = {
    onClickAdd: any
    height?: string
    readOnlyMode?: boolean
    noDataText?: string
    bgColor?: string
    btnText?: string
    customPlaceholderImg?: string
}

const GoToBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
    color: var(--primary, #5ec3ca);
    position: relative;
`

const AddBoxWithPlaceholderAndPlusMobile: React.FunctionComponent<IProps> = (
    props: IProps
) => {
    const { theme } = useThemes()
    return !props.readOnlyMode ? (
        <GoToBox
            style={{
                backgroundColor:
                    props.bgColor ?? 'var(--off-bg-color, #fafafa)',
                height: props.height ?? '100%',
                width: '100%',
                boxSizing: 'border-box',
                minHeight: '120px',
                padding: theme === 'dark' ? 24 : 0,
            }}
            onClick={props.onClickAdd}
        >
            <img
                src={
                    props.customPlaceholderImg
                        ? props.customPlaceholderImg
                        : theme === 'dark'
                        ? placeholderImg_dark
                        : placeholderImg
                }
                alt="placeholder"
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    height: '100%',
                }}
            />

            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                    boxSizing: 'border-box',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ButtonAtom theme="lowercase-blue-background" fontSize={14}>
                    <div
                        style={{
                            textTransform: 'none',
                        }}
                    >
                        {props.btnText ?? 'Add'}
                    </div>
                </ButtonAtom>
            </div>
        </GoToBox>
    ) : (
        <NoDataRectangle
            text={props.noDataText ?? 'No data'}
            height={props.height ?? '400px'}
        />
    )
}

export default AddBoxWithPlaceholderAndPlusMobile
