import React from 'react'
import ListItemMobile from '../../atoms/list/listItemMobile'
import * as unitGenerator from '../../../helpers/units/unitConversion'

interface Props {
    readOnlyMode?: boolean
    toggle?: any
    list: string[]
    data: any
    default_fuel_unit_user_text?: string | undefined | null
    default_fuel_unit_user?: string | undefined | null
    default_distance_unit_user_text?: string | undefined | null
    default_distance_unit_user?: string | undefined | null
    requiredLabel?: boolean
}

const ListManagerMobile: React.FC<Props> = ({
    readOnlyMode,
    toggle,
    list,
    data,
    default_fuel_unit_user_text,
    default_fuel_unit_user,
    default_distance_unit_user_text,
    default_distance_unit_user,
    requiredLabel,
}) => {
    const shapeItemAccordingToUnitPreferences = (id: any) => {
        if (id === 'mileage') {
            if (
                default_distance_unit_user &&
                default_distance_unit_user !== 'kilometers' &&
                data[id].answer !== null
            ) {
                return {
                    ...data[id],
                    answer: unitGenerator.generateFrontEndValueDistanceUnit(
                        default_distance_unit_user,
                        data[id].answer
                    ),
                }
            } else {
                return data[id]
            }
        }

        if (id === 'engine_capacity') {
            if (
                data[id].answer !== null &&
                default_fuel_unit_user &&
                default_fuel_unit_user !== 'cubic_centimeters'
            ) {
                if (default_fuel_unit_user === 'liters') {
                    return {
                        ...data[id],
                        answer: unitGenerator
                            .generateFrontEndValueFuelUnit(
                                default_fuel_unit_user,
                                data[id].answer
                            )
                            .toFixed(1),
                    }
                } else {
                    return {
                        ...data[id],
                        answer: unitGenerator
                            .generateFrontEndValueFuelUnit(
                                default_fuel_unit_user,
                                data[id].answer
                            )
                            .toFixed(1),
                    }
                }
            } else {
                return data[id]
            }
        }
        return data[id]
    }

    return (
        <React.Fragment>
            {list.map((id: any, index) => {
                if (id === 'address' && data[id]) {
                    return (
                        <ListItemMobile
                            height="100px"
                            toggle={toggle}
                            key={index}
                            item={data[id]}
                            requiredLabel={requiredLabel}
                        />
                    )
                } else
                    return (
                        <ListItemMobile
                            removeEdit={
                                readOnlyMode ||
                                (data[id] &&
                                    data[id].id &&
                                    data[id].id === 'email')
                                    ? true
                                    : false
                            }
                            toggle={toggle}
                            key={index}
                            item={shapeItemAccordingToUnitPreferences(id)}
                            unit={
                                id === 'engine_capacity'
                                    ? default_fuel_unit_user_text ?? undefined
                                    : id === 'mileage'
                                    ? default_distance_unit_user_text ??
                                      undefined
                                    : undefined
                            }
                            dataCyId={`${id}-field-mobile`}
                            requiredLabel={requiredLabel}
                            readOnlyMode={readOnlyMode}
                        />
                    )
            })}
        </React.Fragment>
    )
}

export default ListManagerMobile
