import styled from 'styled-components'
import CrossIcon from '../icons/crossIcon'
import { Sheet } from 'react-modal-sheet'
import useWindowSize from '../../templates/displays/windowSizeHook'
import React from 'react'

const CustomSheet = styled(Sheet)<{ $customHeight?: string }>`
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : `212px !important`};
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
    .react-modal-sheet-header {
        height: 30px !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 30px !important;
        background-color: var(--text-darker, #616161) !important;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`

const Title = styled.div`
    font-size: 23px;
    line-height: 32px;
    font-family: 'Lato-semibold';
    color: var(--text-strong, #1a1a1a);
    max-width: 85%;
    padding-bottom: 8px;
    @media (max-width: 320px) {
        font-size: 21px;
        line-height: 28px;
    }
`
const Text = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato';
    color: var(--text-default, #666);
    padding-left: 24px;
    padding-right: 24px;
`

const Close = styled.button`
    all: unset;
    cursor: pointer;
    padding: 8px;
    padding-top: 8px;
    padding-bottom: 0px;
    border-radius: 4px;
    margin-top: 2px;
    :hover {
        background-color: var(--primary_04, rgba(94, 195, 202, 0.04));
    }
`

type Props = {
    title: string
    text: React.ReactChild
    isOpen: boolean
    toggle: (p: boolean) => void
    customHeight?: string
}

const BottomSheetWarning = (props: Props) => {
    const size = useWindowSize()
    return (
        <CustomSheet
            isOpen={
                size?.width && size.width < 881 && props.isOpen ? true : false
            }
            onClose={() => props.toggle(false)}
            $customHeight={props.customHeight}
        >
            <Sheet.Container>
                <Sheet.Header />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '100%',
                        boxSizing: 'border-box',
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingTop: 12,
                        paddingBottom: 8,
                    }}
                >
                    <Title>{props.title}</Title>
                    <Close onClick={() => props.toggle(false)}>
                        <CrossIcon />
                    </Close>
                </div>
                <Text>{props.text}</Text>
                <div style={{ paddingTop: 24 }} />
            </Sheet.Container>
            <Sheet.Backdrop onTap={() => props.toggle(false)} />
        </CustomSheet>
    )
}

export default BottomSheetWarning
