import styled from 'styled-components'
import { PaddingUnit } from '../../../templates/onboarding/onboardingFormLayoutMobile'
import { device } from '../../../templates/displays/devices'
import { message } from 'antd'
import heic2any from 'heic2any'
import { useState, useEffect } from 'react'
import img_placeholder from '../../../atoms/skeleton/gallery/2.jpg'
import img_placeholder_dark from '../../../atoms/skeleton/gallery/2_dark.png'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import useThemes from '../../../../providers/theme/hooks'

const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 592px;
    box-sizing: border-box;
    padding: 53px 64px;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--bg-color, #fff);
    box-shadow: 64px 64px 128px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
    @media ${device.ipad} {
        width: 480px;
        padding: 40px;
    }
`

const OwnerName = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-default, #666);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const Title = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    font-size: 24px;
    line-height: 40px; /* 166.667% */
`

const Bio = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-default, #666);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    letter-spacing: 0.001px;
    height: fit-content;
    transition: all 200ms;
    min-height: 140px;
`

const ShowcaseImgWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 260px;
    position: relative;
`

const ShowcaseImg = styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    object-fit: cover;
`

type Props = {
    title: string
    ownerName: string
    bio: string
    currentImagesUploaded: any
}

const ImagePreviewDesktop = (props: Props) => {
    let { title, ownerName, bio, currentImagesUploaded } = props

    const [url, setUrl] = useState<string | undefined>(undefined)

    useEffect(() => {
        const getCustomFormatImgUrl = async () => {
            try {
                // Convert HEIC/HEIF to JPEG/PNG
                const conversionResult = await heic2any({
                    blob: currentImagesUploaded,
                    toType: 'image/jpeg',
                })

                if (conversionResult instanceof Blob) {
                    // Create a URL for the converted image
                    const url = URL.createObjectURL(conversionResult)
                    setUrl(url)
                    setImgPreviewLoading(false)
                } else {
                    message.error(
                        'An error occured, please try uploading your image again.'
                    )
                }
            } catch (e) {
                message.error(
                    'An error occured, please try uploading your image again.'
                )
            }
        }

        if (currentImagesUploaded) {
            if (
                currentImagesUploaded.type === 'image/heic' ||
                currentImagesUploaded.type === 'image/heif'
            ) {
                setImgPreviewLoading(true)
                getCustomFormatImgUrl()
            } else {
                let url = URL.createObjectURL(currentImagesUploaded)

                url && setUrl(url)
            }
        }

        return () => {}
    }, [currentImagesUploaded])

    const [imgPreviewLoading, setImgPreviewLoading] = useState(false)

    const { theme } = useThemes()

    return (
        <Card>
            <Title>{title}</Title>
            <OwnerName>Owned by {ownerName}</OwnerName>
            <PaddingUnit $times={3} />
            <Bio>{bio}</Bio>
            <PaddingUnit $times={5} />
            <ShowcaseImgWrapper>
                {imgPreviewLoading ? (
                    <SkeletonAbsolute isthingloading />
                ) : (
                    <ShowcaseImg
                        src={
                            url
                                ? url
                                : theme === 'dark'
                                ? img_placeholder_dark
                                : img_placeholder
                        }
                        alt="car cover img"
                    />
                )}
            </ShowcaseImgWrapper>
        </Card>
    )
}

export default ImagePreviewDesktop
