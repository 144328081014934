import styled from 'styled-components'
import chevron_to_left from '../../../../../public/assets/navigation/chevron_to_left.svg'
import pcc_logo_topbar from '../../../../../public/assets/logo/pcc_logo_topbar.svg'
import pcc_logo_text_only_topbar from '../../../../../public/assets/logo/pcc_logo_text_only_topbar.svg'
import { device } from '../../../displays/devices'

const Container = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 80px;
    z-index: 100;
    background-color: var(--bg-color, #ffffff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    transition: all 100ms;

    @media ${device.mobile_and_ipad} {
        height: 64px;
    }
`

const SubContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 2rem;

    @media ${device.mobile_and_ipad} {
        height: 60px;
        padding: 0.75rem 1.5rem;
    }
`

const CloseBtn = styled.button`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    background: var(--bg-color, #ffffff);
    color: var(--text-default, #666666);
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 4px;
    font-family: Lato-Semibold;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;

    @media ${device.mobile_and_ipad} {
        border: none;
        height: fit-content;
        padding: 0px;
    }
    :hover {
        background-color: var(--border-muted, #e5e5e5);
    }
`

const CenteredIcon = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media ${device.mobile_and_ipad} {
        display: none;
    }
`

const PccLogo = styled.img``

const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(83%) contrast(84%);
    height: 14px;
    margin-right: 6px;
`
type Props = {
    goBack?: () => any
}
const InsuranceTopBarWithCenteredLogo = (props: Props) => {
    let { goBack } = props
    return (
        <Container>
            <SubContainer>
                <CloseBtn onClick={goBack}>
                    <Chevron src={chevron_to_left} />
                    Go back
                </CloseBtn>

                <CenteredIcon>
                    <PccLogo
                        src={pcc_logo_topbar}
                        style={{ marginRight: '5px' }}
                    />
                    <PccLogo src={pcc_logo_text_only_topbar} />
                </CenteredIcon>
            </SubContainer>
        </Container>
    )
}

export default InsuranceTopBarWithCenteredLogo
