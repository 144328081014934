import './styles.css'
import styled, { css } from 'styled-components'
import { device } from '../../templates/displays/devices'
import { useState } from 'react'
import Expander from '../expander/expander'
import { isSafari } from 'react-device-detect'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface IInputFieldProps {
    name: string
    value?: string | number | undefined
    placeholder: string
    handleChange?: (e: any) => any
    type?: React.HTMLInputTypeAttribute | undefined
    onBlur?: any
    onFocus?: any
    onChange?: any
    onEnterKeyPress?: any
    isPhone?: boolean
    disabled?: boolean
    isnarrow?: boolean
    id?: string
    tabindex?: number | string
    isNarrow?: boolean
    fontSize?: string
    font_family?: string
    width?: string
    mobile_width?: string
    ipad_width?: string
    helperText?: string
    paddingLeft?: string
    linecolor?: string
    step?: string
    min?: string
    max?: string
    leftAdornment?: React.ReactNode
    rightAdornment?: React.ReactNode
    customHeight?: string
    hasOptionalTag?: boolean
    placeholderStyle?: any
    enterKeyHint?: 'go' | 'done' | 'search' | 'send' | 'next'
    valueStart?: string | React.ReactNode
    backgroundColor?: string
    customActiveColour?: string
    readonly?: boolean
    errorText?: string
    textTransform?: string
    removeLabel?: boolean
}

interface StandardInputProps {
    disabled?: boolean
    desktop?: boolean | string
    tabindex?: number | string
    $isnarrow?: boolean
    font_family?: string
    width?: string
    mobile_width?: string
    ipad_width?: string
    fontSize?: string
    $textTransform?: string
    dataCyId?: string
    paddingleft?: string
    linecolor?: string
    placeholderStyle?: any
    backgroundColor?: string
    customActiveColour?: string
    errorText?: string
    $theme: ITheme
}

const Wrapper = styled.div``

const FormWrapper = styled.div<StandardInputProps>`
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : colours[props.$theme].background_default};
    width: ${(props) => (props.width ? props.width : '100%')};

    padding: 0;

    padding-left: ${(props) => (props.paddingleft ? props.paddingleft : '0')};

    opacity: ${(props) => (props.disabled ? '0.8' : '1')};
    display: flex;
    align-items: center;

    position: relative;
    display: flex;
    border: ${(props) =>
        `${
            props.linecolor
                ? `1px solid ${props.linecolor}`
                : `1px solid ${colours[props.$theme].text_muted}`
        }`};
    border-radius: 4px;

    @media ${device.mobile} {
        width: ${(props) => (props.mobile_width ? props.mobile_width : '100%')};
    }

    @media ${device.ipad} {
        width: ${(props) => (props.ipad_width ? props.ipad_width : '100%')};
    }

    &:focus-within {
        border-color: ${(props) =>
            props.customActiveColour ?? colours[props.$theme].primary};
    }

    & input:focus ~ label {
        top: 0%;
        left: 0;
        transform: translate(8px, -50%) scale(0.8);
        color: ${(props) =>
            props.customActiveColour ?? colours[props.$theme].primary};
        min-width: auto;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px
            ${(props) =>
                props.backgroundColor
                    ? props.backgroundColor
                    : colours[props.$theme].background_default}
            inset !important;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: ${(props) =>
            props.color
                ? props.color
                : colours[props.$theme].text_strong} !important;
    }
`

type ILabelStyle = {
    raise: boolean
    backgroundColor?: string
    $theme: ITheme
}

const Label = styled.label<ILabelStyle>`
    position: absolute;
    ${(props) => `
        top: ${props.raise ? '0%' : '50%'};
        transform-origin: top left;
        transform: ${
            props.raise
                ? `translate(8px, -50%) scale(0.8)`
                : `translate(8px, -50%) scale(1)`
        };
        left: 0;
        min-width: ${props.raise ? '30px' : '30px'};
        background-color: ${
            props.backgroundColor
                ? props.backgroundColor
                : colours[props.$theme].background_default
        };
    `}

    transition: 0.15s;

    color: ${(props) => colours[props.$theme].text_darker};
    font-size: 1.1em;
    padding: 0 8px;
    pointer-events: none;
    font-family: Lato;

    &:first-letter {
        text-transform: uppercase;
    }
`

type IThemeStyle = { $theme: ITheme }

const HelperText = styled.div<IThemeStyle>`
    color: ${(props) => colours[props.$theme].text_darker};
    font-size: 11px;
    padding-top: 8px;
    padding-left: 12px;
`

const AdornmentWrapper = styled.div<IThemeStyle>`
    padding: 0 14px;
    display: flex;
    align-items: center;
    color: ${(props) => colours[props.$theme].text_muted};

    @media (max-width: 350px) {
        padding: 0 10px;
    }
`
const OptionalTxt = styled.div<IThemeStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 14px;
    padding-right: 20px;
`

export const ErrorText = styled.div<IThemeStyle>`
    width: 100%;
    box-sizing: border-box;
    padding-top: 4px;
    font-size: 11px;
    color: ${(props) => colours[props.$theme].error};
    font-style: italic;
`
const inputStyles = css<StandardInputProps>`
    border: none;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: 100%;
    padding: 0 14px;
    padding-left: ${(props) => props.paddingleft ?? '14px'};
    background-color: transparent;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
    font-family: ${(props) => props.font_family ?? 'Lato'};
    text-transform: ${(props) => props.$textTransform ?? 'none'};
    flex: 1;
    color: var(--text-strong, #1a1a1a);

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px
            ${(props) =>
                props.backgroundColor
                    ? props.backgroundColor
                    : colours[props.$theme].background_default}
            inset !important;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: ${(props) =>
            colours[props.$theme].text_strong} !important;
    }

    ::placeholder {
        ${(props) =>
            `font-family: Lato-Light;
            color: ${colours[props.$theme].text_default};
            font-size: ${props.fontSize ? props.fontSize : '16px'};
            font-family: Lato-Light;
            color: ${colours[props.$theme].text_default};
            &:first-letter {
                text-transform: uppercase;
            }
             ${props.placeholderStyle ?? ''}
        `};
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
    }

    @media ${device.desktop} {
        font-size: ${(props) =>
            props.$textTransform
                ? props.fontSize ?? '14px'
                : props.fontSize ?? '16px'};
    }

    @media ${device.ipad} {
        font-size: ${(props) =>
            props.$textTransform
                ? props.fontSize ?? '14px'
                : props.fontSize ?? '16px'};
        width: ${(props) => (props.ipad_width ? props.ipad_width : '100%')};
    }

    @media ${device.mobile} {
        width: ${(props) => (props.mobile_width ? props.mobile_width : '100%')};
        font-size: ${(props) =>
            props.$textTransform
                ? props.fontSize ?? '12px'
                : props.fontSize ?? '14px'};
    }
`
const StandardInput = styled.input`
    ${inputStyles}
`
const ValueStart = styled.div<IThemeStyle>`
    color: ${(props) => colours[props.$theme].text_darker};
    padding-left: 16px;
    @media ${device.beyond_ipad_mobile} {
        font-size: 16px;
    }

    @media ${device.mobile_and_ipad} {
        font-size: 16px;
    }
`
function InputFieldNoFormikAnimated({
    name,
    value,
    placeholder,
    type,
    onBlur,
    onChange,
    onEnterKeyPress,
    id,
    disabled,
    tabindex,
    isNarrow,
    font_family,
    width,
    rightAdornment,
    helperText,
    linecolor,
    step,
    fontSize,
    ipad_width,
    mobile_width,
    isnarrow,
    customHeight,
    hasOptionalTag,
    placeholderStyle,
    min,
    max,
    paddingLeft,
    enterKeyHint,
    isPhone,
    valueStart,
    backgroundColor,
    readonly,
    errorText,
    textTransform,
    customActiveColour,
    removeLabel,
}: IInputFieldProps) {
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const { theme } = useThemes()

    return (
        <Wrapper style={{ width: width ?? '100%' }}>
            <FormWrapper
                $theme={theme}
                style={{
                    opacity: disabled ? 0.5 : 1,
                    width: width ?? '100%',
                    height: customHeight ? customHeight : '50px',
                }}
                backgroundColor={backgroundColor}
                linecolor={linecolor}
                customActiveColour={customActiveColour}
            >
                {valueStart && (
                    <ValueStart $theme={theme}>{valueStart}</ValueStart>
                )}

                <StandardInput
                    $theme={theme}
                    id={id}
                    name={name}
                    type={type ? type : 'text'}
                    value={value}
                    placeholder={
                        isFocused && name && !placeholder ? name : placeholder
                    }
                    onChange={(e: any) => {
                        // if (isPhone === true) {
                        // Get the input value
                        // var inputValue = e.target.value
                        // var sanitizedValue
                        // if (inputValue === 0) {
                        //     sanitizedValue = 0
                        // }
                        // // Remove any non-numeric characters, including the "+"
                        // else sanitizedValue = inputValue.replace(/[^0-9]/g, '')
                        // // Update the input value with the sanitized version
                        // e.target.value = sanitizedValue
                        // // }

                        onChange(e)
                    }}
                    onKeyDown={(e: any) => {
                        if (isPhone === true) {
                            if (e.key === '+' || e.key === '-') {
                                // Prevent the default behavior of the key press
                                e.preventDefault()
                            }
                        }
                    }}
                    onBlur={onBlur}
                    disabled={disabled}
                    tabindex={tabindex}
                    font_family={font_family && font_family}
                    width={width && width}
                    onKeyPress={(e: any) => {
                        if (e?.which === 13) {
                            if (onEnterKeyPress) {
                                onEnterKeyPress()
                            } else {
                                if (isSafari) {
                                    e.preventDefault()
                                }
                            }
                        } else if (e?.key === 'Enter') {
                            if (onEnterKeyPress) {
                                onEnterKeyPress()
                            } else {
                                if (isSafari) {
                                    e.preventDefault()
                                }
                            }
                        }
                    }}
                    onFocus={() => {
                        setIsFocused(!isFocused)
                    }}
                    fontSize={fontSize}
                    linecolor={linecolor}
                    step={step}
                    mobile_width={mobile_width && mobile_width}
                    ipad_width={ipad_width && ipad_width}
                    $isnarrow={isnarrow ? true : false}
                    placeholderStyle={placeholderStyle}
                    min={min && type === 'number' ? +min : undefined}
                    max={max && type === 'number' ? +max : undefined}
                    paddingleft={paddingLeft}
                    enterKeyHint={enterKeyHint}
                    readOnly={readonly}
                    $textTransform={textTransform}
                    customActiveColour={customActiveColour}
                />

                {hasOptionalTag && !value && (
                    <OptionalTxt $theme={theme}>optional</OptionalTxt>
                )}

                {removeLabel
                    ? null
                    : placeholder && (
                          <Label
                              $theme={theme}
                              style={{ width: 'max-content' }}
                              raise={true}
                              backgroundColor={backgroundColor}
                          >
                              {name}
                          </Label>
                      )}

                {removeLabel
                    ? null
                    : !placeholder && (
                          <Label
                              $theme={theme}
                              style={{ width: 'max-content' }}
                              raise={!!value}
                              backgroundColor={backgroundColor}
                          >
                              {name}
                          </Label>
                      )}

                {rightAdornment && (
                    <AdornmentWrapper
                        style={{ width: 'max-content' }}
                        $theme={theme}
                    >
                        {rightAdornment}
                    </AdornmentWrapper>
                )}
            </FormWrapper>
            {helperText && (
                <HelperText style={{ width: 'max-content' }} $theme={theme}>
                    {helperText}
                </HelperText>
            )}
            {errorText && (
                <Expander height={errorText ? 'auto' : 0}>
                    <ErrorText $theme={theme}>{errorText}</ErrorText>
                </Expander>
            )}
        </Wrapper>
    )
}

export default InputFieldNoFormikAnimated
