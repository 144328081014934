import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertAPIEntryToTimelineItemSingle } from '../../conversions/timeline/entryToTimelineItem'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { selectStateUserCurrency } from '../../user/sagas/getUserData'
import { ICurencyUIDsEnum } from '../timelineEnum'
import { timelineActions } from '../reducer'
import { IRootState } from '../../store'
import { IReduxError, ICustomErrorData } from '../../entities/cars/types'
import { IEntryPayloadAPI } from '../../services/typedefinitions/apiPayloads'
import { IApiGetAllEntriesByCarId_args } from '../../services/types'
import {
    IGetTimelineItemsByCarIDPayloadRequest,
    ITimelineItem,
    IGetAllTimelineItemsByCarIDPayloadSuccess,
} from '../types'

const getCarError = (state: IRootState) => state.entities.carsData.error

function* GetAllCarTimelineByCarIdSaga(
    payload: IGetTimelineItemsByCarIDPayloadRequest
): any {
    try {
        let user_current_curr: ICurencyUIDsEnum = yield call(
            selectStateUserCurrency
        )

        //Step 1 - call the api func and keep data returned in a const
        let api_payload: IApiGetAllEntriesByCarId_args = {
            car_id: payload.car_id,
            user_currency: user_current_curr,
        }
        const api_res: any = yield call(
            api.timeline.entries.getEntriesData.getAllEntriesByCarId,
            api_payload
        )

        if (
            api_res && // 👈 null and undefined check
            Object.keys(api_res).length === 0 &&
            Object.getPrototypeOf(api_res) === Object.prototype
        ) {
            yield put(
                timelineActions.getAllTimelineItemsByCarIDSuccess({
                    car_id: payload.car_id,
                    entries: [],
                })
            )
            posthog.capture('GET TIMELINE BY CAR ID')
        } else {
            let entries_from_api: IEntryPayloadAPI[] = api_res.data

            //Step 2 - creating a converted entries array
            let all_entries_by_car_id: ITimelineItem[] = []
            for (const entry of entries_from_api) {
                if (entry !== undefined) {
                    let converted_entry =
                        ConvertAPIEntryToTimelineItemSingle(entry)
                    all_entries_by_car_id.push(converted_entry)
                }
            }

            // STEP 3 . build up payload to return to reducer
            let res: IGetAllTimelineItemsByCarIDPayloadSuccess = {
                car_id: payload.car_id,
                entries: all_entries_by_car_id,
            }

            // STEP 4. dispatch action to reducer
            yield put(timelineActions.getAllTimelineItemsByCarIDSuccess(res))

            // Capture event
            posthog.capture('GET TIMELINE BY CAR ID')
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetAllCarTimelineByCarIdSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let carError: IReduxError = yield select(getCarError)
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong.`,
                custom_message_detail: 'Please try again.',
                custom_user_action_text: 'OK',
                custom_redirect_path: carError
                    ? '/garage'
                    : `/car/${payload.car_id}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(
                timelineActions.getAllTimelineItemsByCarIDError(customError)
            )
        }
    }
}

function* watcherGetAllCarTimelineByCarId() {
    while (true) {
        const { payload } = yield take(
            timelineActions.getAllTimelineItemsByCarIDRequest
        )

        yield call(GetAllCarTimelineByCarIdSaga, payload)
    }
}

const get_timeline_by_car_id: any[] = [fork(watcherGetAllCarTimelineByCarId)]

export default get_timeline_by_car_id
