import useThemes from '../../../../providers/theme/hooks'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { journey_colours } from '../../../../providers/theme/colours'
import image from '../../../../public/assets/images/journey/insurance/welcome.png'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import {
    JourneyWelcomeBtnText,
    JourneyWelcomeButtonsWrapper,
    JourneyWelcomeImage,
    JourneyWelcomeNewLineOnMobile,
    JourneyWelcomeSubWrapper,
    JourneyWelcomeSubWrapperV2,
    JourneyWelcomeText,
    JourneyWelcomeTextWrapper,
    JourneyWelcomeTitle,
    JourneyWelcomeWrapper,
} from '../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    start: () => void
    cancel: (carid: string) => void
}

const CustomImageMobile = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;

    height: auto;
    width: 1000px;

    transform: translateX(-10px);

    @media (min-width: 500px) {
        max-height: 50dvh;
    }

    @media (max-width: 450px) {
        max-height: 50dvh;
    }

    @media (max-width: 380px) {
        max-height: 40dvh;
    }
`

const JourneyWelcomeImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    /* flex: 1; */

    @media ${device.beyond_ipad_mobile} {
        margin-top: 1dvh;
        margin-bottom: 2dvh;
        height: 50dvh;
        transform: translate(10px, 0px);
    }
    @media ${device.mobile} {
        /* flex: 0; */
        width: 100dvw;

        /* margin-top: 2dvh;
        min-height: 50dvh; */
        transform: translate(30px, -10px);
    }
`

export default function JourneyInsuranceWelcome({ start, cancel }: Props) {
    const { theme } = useThemes()

    return (
        <>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneyWelcomeWrapper>
                        <JourneyWelcomeSubWrapperV2>
                            <JourneyWelcomeTextWrapper>
                                <div
                                    style={{
                                        // not adding padding everywhere
                                        // as pattern should stay above
                                        paddingTop: '1dvh',
                                    }}
                                />

                                <JourneyWelcomeTitle>
                                    Why Custodian Insurance is Different
                                </JourneyWelcomeTitle>
                                <JourneyWelcomeText>
                                    Custodian offers specialist car insurance
                                    via a panel of leading underwriters. Our
                                    goal is to create a new category of risk for
                                    underwriters so that automotive enthusiasts
                                    can have better cover for less.
                                </JourneyWelcomeText>
                            </JourneyWelcomeTextWrapper>

                            <JourneyWelcomeImageWrapper>
                                <FadedSlower>
                                    <JourneyWelcomeImage
                                        src={image}
                                        // placeholder={grey_placeholder}
                                    />
                                </FadedSlower>
                            </JourneyWelcomeImageWrapper>

                            <JourneyWelcomeButtonsWrapper>
                                <StandardCtaBtn
                                    onClick={start}
                                    bg={
                                        journey_colours[theme].section.insurance
                                            .primary_600
                                    }
                                >
                                    Let's get started
                                </StandardCtaBtn>
                                <StandardCtaBtn
                                    onClick={cancel}
                                    boder="transparent"
                                    bg="transparent"
                                    removeShadowOnHover
                                    customWidth="100%"
                                >
                                    <JourneyWelcomeBtnText
                                        style={{
                                            color: journey_colours[theme]
                                                .section.insurance.primary_600,
                                        }}
                                    >
                                        Cancel
                                    </JourneyWelcomeBtnText>
                                </StandardCtaBtn>
                            </JourneyWelcomeButtonsWrapper>
                        </JourneyWelcomeSubWrapperV2>
                    </JourneyWelcomeWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyWelcomeWrapper>
                    <JourneyWelcomeSubWrapper>
                        <JourneyWelcomeTitle
                            style={{
                                fontSize: '26px',
                            }}
                        >
                            Why Custodian Insurance{' '}
                            <JourneyWelcomeNewLineOnMobile />
                            <span
                                style={{
                                    color: journey_colours[theme].section
                                        .insurance.primary_500,
                                    fontSize: '28px',
                                }}
                            >
                                is Different
                            </span>
                        </JourneyWelcomeTitle>

                        <JourneyWelcomeImageWrapper>
                            <Faded width={'auto'}>
                                <CustomImageMobile
                                    src={image}
                                    // placeholder={grey_placeholder}
                                />
                            </Faded>
                        </JourneyWelcomeImageWrapper>
                        <JourneyWelcomeText
                            style={{
                                maxWidth: '80vw',
                            }}
                        >
                            Custodian offers specialist car insurance via a
                            panel of leading underwriters. Our goal is to create
                            a new category of risk for underwriters so that
                            automotive enthusiasts can have better cover for
                            less.
                        </JourneyWelcomeText>
                        <JourneyWelcomeButtonsWrapper>
                            <StandardCtaBtn
                                onClick={start}
                                customWidth="100%"
                                bg={
                                    journey_colours[theme].section.insurance
                                        .primary_600
                                }
                            >
                                Let's get started
                            </StandardCtaBtn>
                        </JourneyWelcomeButtonsWrapper>
                    </JourneyWelcomeSubWrapper>
                </JourneyWelcomeWrapper>
            </IpadAndMobileDisplay>
        </>
    )
}
