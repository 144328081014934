import { call, fork, put, take, all, join, select } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    IApplicationInfo,
    IApplicationInfoObj,
    IInsuranceHighLevelCarInfo,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplicationValidation,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from './../../reducer'
import {
    IInsuranceApplication_API,
    IInsuranceApplication_APIErrors,
} from '../../types'
import {
    ConvertInsuranceApplicationErrorsToReducer,
    ConvertInsuranceApplicationToReducer,
} from '../../../conversions/insuranceApplication/api/applicationToReducer'
import { calculateInsuranceStats } from '../../../helpers/calculateInsuranceStats'
import { ValidateInsuranceApplication } from './validateApplication'
import { IRootState } from '../../../store'
import { ICustomErrorData, IReduxError } from '../../../entities/cars/types'
import { push } from 'redux-first-history'

let insurance_applications_state = (state: IRootState) =>
    state.insuranceQuoteApplication

export type IGetAllUserInsuranceApplicationsProps = {
    withNavigation?: boolean
    withAllInfo?: boolean
}

export type IGetAllApplicationsSuccess = {
    applications_draft_ids: string[] | undefined
    applications_opened_ids: string[] | undefined
    applications_complete_ids: string[] | undefined
}

let title = (id: string, application: IInsuranceQuoteApplication): string => {
    if (
        application?.vehicles[id].tech_info?.make?.name ||
        application?.vehicles[id].tech_info?.model?.name ||
        application?.vehicles[id].tech_info?.manufacture_year
    ) {
        return `${
            application?.vehicles[id].tech_info?.manufacture_year
                ? `${application?.vehicles[id].tech_info?.manufacture_year} `
                : ''
        }${
            application?.vehicles[id].tech_info?.make?.name
                ? `${application?.vehicles[id].tech_info?.make?.name} `
                : ''
        }${
            application?.vehicles[id].tech_info?.model?.name
                ? application?.vehicles[id].tech_info?.model?.name
                : ''
        }`
    } else {
        return 'Untitled car'
    }
}

let carDrivers = (
    id: string,
    application: IInsuranceQuoteApplication
): string[] => {
    let driversNames: string[] = []
    Object.keys(application.other_drivers).map((driver_id) => {
        if (
            application.other_drivers[driver_id]?.drive_list &&
            application.other_drivers[driver_id].drive_list!.findIndex(
                (car) => car.carid === id
            ) > 0
        ) {
            let name = `${
                application.other_drivers[driver_id].basic_details?.given_name
                    ? application.other_drivers[driver_id].basic_details
                          ?.given_name
                    : ''
            } ${
                application.other_drivers[driver_id].basic_details?.family_name
                    ? application.other_drivers[driver_id].basic_details
                          ?.family_name
                    : ''
            }`
            driversNames.push(name)
        }
    })
    return driversNames
}

let carIdsToCarsInfo = (
    ids: string[],
    application: IInsuranceQuoteApplication
): IInsuranceHighLevelCarInfo[] => {
    let res: IInsuranceHighLevelCarInfo[] = []

    if (!ids) {
        return res
    }

    ids.forEach((id) => {
        if (application?.vehicles[id]) {
            res = [
                ...res,

                {
                    id: application?.vehicles[id].id ?? '',
                    title: title(id, application),
                    drivers: carDrivers(id, application),
                },
            ]
        }
    })

    return res
}

function* PopulateApplicationStats(
    application: IInsuranceQuoteApplication,
    applications_info: IApplicationInfoObj = {}
) {
    try {
        if (application.id) {
            let validationres: IInsuranceApplication_APIErrors = yield call(
                ValidateInsuranceApplication,
                {
                    application: application,
                }
            )

            let validationData: IInsuranceQuoteApplicationValidation =
                validationres && Object.keys(validationres).length > 0
                    ? {
                          ...ConvertInsuranceApplicationErrorsToReducer(
                              validationres
                          ),
                      }
                    : {}

            // console.log('validationData l 110', validationData)

            let payload: IApplicationInfo = {
                uid: `${application.id}`,
                stat: 0,
                carsInfoArr: carIdsToCarsInfo(
                    application?.vehicles_to_insure_ids_list ?? [],
                    application
                ),
            }

            applications_info = {
                ...applications_info,
                [`${application.id}`]: { ...payload },
            }

            if (application) {
                let appli1 = calculateInsuranceStats(
                    application,
                    validationData
                )
                if (appli1?.overallApplicationStats?.percentage) {
                    applications_info = {
                        ...applications_info,
                        [`${application.id}`]: {
                            ...applications_info[`${application.id}`],
                            stat: appli1?.overallApplicationStats?.percentage,
                        },
                    }
                }
            }
        }

        return applications_info
    } catch (error: any) {
        // console.log('error', error)
        let customErrorData: ICustomErrorData = {
            custom_message: `Something went wrong.`,
            custom_user_action_text: 'OK',
            custom_redirect_path: '/garage',
        }
        let customError: IReduxError = ConvertToReduxError(
            error,
            customErrorData,
            'insurance'
        )
        yield put(
            insuranceActions.validate_insurance_application_error(customError)
        )
    }
}

function* GetAllApplicationsStats(
    applications_to_validate: IInsuranceQuoteApplication[],
    non_validated_applications: IInsuranceQuoteApplication[]
): any {
    const sagaTasksArray = []

    for (let i = 0; i < applications_to_validate.length; i++) {
        const sagaTask = yield fork(
            PopulateApplicationStats,
            applications_to_validate[i]
        )
        sagaTasksArray.push(sagaTask)
    }
    const arrayysOfApplicationStatsObjects = yield join(sagaTasksArray)

    let applications_info_validated = arrayysOfApplicationStatsObjects.reduce(
        (acc: IApplicationInfoObj, application: IApplicationInfoObj) => {
            return { ...acc, ...application }
        },
        {}
    )

    let applications_info_non_validated = non_validated_applications.reduce(
        (acc: IApplicationInfoObj, application: IInsuranceQuoteApplication) => {
            let appli_info: IApplicationInfo = {
                uid: `${application.id}`,
                stat: 100,
                carsInfoArr: carIdsToCarsInfo(
                    application?.vehicles_to_insure_ids_list ?? [],
                    application
                ),
            }
            return { ...acc, [`${application.id}`]: appli_info }
        },
        {}
    )

    if (applications_info_validated || applications_info_non_validated) {
        yield put(
            insuranceActions.get_insurance_applications_info_success({
                ...applications_info_validated,
                ...applications_info_non_validated,
            })
        )
    }
}

function* GetAllApplicationsInfo(
    applications: IInsuranceApplication_API[]
): any {
    try {
        let arrayOfPromisses: Promise<any>[] = []

        let applications_to_validate: IInsuranceQuoteApplication[] = []
        let non_validated_applications: IInsuranceQuoteApplication[] = []

        for (let i = 0; i < applications.length; i++) {
            let applicationData = yield call(
                api.insuranceApplication.getInsuranceApplication,
                applications[i].uid
            )

            arrayOfPromisses.push(applicationData)

            let appli: IInsuranceQuoteApplication =
                ConvertInsuranceApplicationToReducer(applicationData)

            if (appli && applications[i].uid) {
                if (applications[i].status === 'DRAFT') {
                    applications_to_validate = [
                        ...applications_to_validate,
                        appli,
                    ]
                } else {
                    non_validated_applications = [
                        ...non_validated_applications,
                        appli,
                    ]
                }
            }
        }

        let allData: IInsuranceQuoteApplication[] = yield all(arrayOfPromisses)

        if (allData) {
            yield call(
                GetAllApplicationsStats,
                applications_to_validate,
                non_validated_applications
            )
        }
    } catch (error: any) {
        let customErrorData: ICustomErrorData = {
            custom_message: `Something went wrong.`,
            custom_user_action_text: 'OK',
            custom_redirect_path: '/garage',
        }
        let customError: IReduxError = ConvertToReduxError(
            error,
            customErrorData,
            'insurance'
        )
        yield put(insuranceActions.get_insurance_application_error(customError))
    }
}

export function* GetAllUserInsuranceApplications(
    p: IGetAllUserInsuranceApplicationsProps
): any {
    try {
        let res: IInsuranceApplication_API[] = yield call(
            api.insuranceApplication.getUserInsuranceApplications
        )

        let applications_draft: IInsuranceApplication_API[] = []
        let applications_open: IInsuranceApplication_API[] = []
        let applications_complete: IInsuranceApplication_API[] = []

        let applications_draft_ids: string[] = []
        let applications_opened_ids: string[] = []
        let applications_complete_ids: string[] = []

        const currentInsuranceState: IInsuranceQuoteApplication_Reducer =
            yield select(insurance_applications_state)
        let recentDeletedAppliID = currentInsuranceState.recentDeletedAppliID

        if (res) {
            // if unique application and in draft
            if (
                res[0] &&
                res.length === 1 &&
                res[0]?.uid &&
                res[0].status === 'DRAFT' &&
                (!recentDeletedAppliID || recentDeletedAppliID !== res[0].uid)
            ) {
                // if has navigation condition direct to application page and that page will take care of fetching data
                if (p.withNavigation === true) {
                    yield put(push(`/insurance/application/${res[0].uid}`))
                } else {
                    // if it doesn't have navigation condition put the id inside the corresponding array
                    applications_draft_ids = [
                        ...applications_draft_ids,
                        res[0].uid,
                    ]

                    // if needs all stats go get all info
                    if (p.withAllInfo === true) {
                        yield put(
                            insuranceActions.get_insurance_applications_info_request()
                        )
                        yield call(GetAllApplicationsInfo, [res[0]])
                    }
                } // if more than one application or if unique application is not draft
            } else if (res && res.length > 0) {
                // if has navigation true go to overview page
                if (p.withNavigation === true) {
                    yield put(push('/insurance/overview'))
                }
                // put each id inside corresponding array
                for (let i = 0; i < res.length; i++) {
                    // check that id has not just been deleted
                    if (
                        !recentDeletedAppliID ||
                        recentDeletedAppliID !== res[i].uid
                    ) {
                        if (res[i].status === 'DRAFT') {
                            applications_draft_ids = [
                                ...applications_draft_ids,
                                res[i].uid,
                            ]

                            applications_draft = [...applications_draft, res[i]]
                        } else if (res[i].status === 'COMPLETE') {
                            applications_complete_ids =
                                applications_complete_ids
                                    ? [...applications_complete_ids, res[i].uid]
                                    : [res[i].uid]
                            applications_complete = applications_complete
                                ? [...applications_complete, res[i]]
                                : [res[i]]
                        } else if (
                            res[i].status === 'REQUESTED' ||
                            res[i].status === 'QUOTING' ||
                            res[i].status === 'BROKING' ||
                            res[i].status === 'ENRICHMENT'
                        ) {
                            applications_opened_ids = applications_opened_ids
                                ? [...applications_opened_ids, res[i].uid]
                                : [res[i].uid]
                            applications_open = applications_open
                                ? [...applications_open, res[i]]
                                : [res[i]]
                        }
                    }
                }
                // if all stats info needed go get each applic stats
                if (p.withAllInfo === true) {
                    yield put(
                        insuranceActions.get_insurance_applications_info_request()
                    )
                    yield call(GetAllApplicationsInfo, [
                        ...applications_draft,
                        ...applications_complete,
                        ...applications_open,
                    ])
                }
            } else {
                // if there is a response but its' length is zero
                if (p.withNavigation === true) {
                    yield put(push('/insurance'))
                }
            }
        } else {
            // if response is undefined
            if (p.withNavigation === true) {
                yield put(push('/insurance'))
            }
        }

        let payloadSuccess: IGetAllApplicationsSuccess = {
            applications_draft_ids,
            applications_complete_ids,
            applications_opened_ids,
        }

        yield put(
            insuranceActions.get_user_insurance_applications_success(
                payloadSuccess
            )
        )
        if (recentDeletedAppliID) {
            yield put(insuranceActions.setRecentDeletedAppliID(undefined))
        }
    } catch (error: any) {
        if (error.status === 401) {
            let pp: IUnauthHandlerPayload = {
                functionToRepeat: GetAllUserInsuranceApplications,
                payload: p,
                redirectToLoginIfPublicUser: p.withNavigation ? true : false,
                initialLandingUrlAfterLogin: '/insurance',
            }

            yield call(unauthHandlerSaga, pp)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.get_user_insurance_applications_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Get_All_UserApplications() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.get_user_insurance_applications_request
        )
        yield call(GetAllUserInsuranceApplications, payload)
    }
}

const insurance_get_all_user_applications: any[] = [
    fork(Watcher_Get_All_UserApplications),
]

export default insurance_get_all_user_applications
