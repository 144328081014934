import { ITask } from 'entityModels'

export const filterByTaskStatus = (
    obj: any,
    ids: string[],
    status: boolean
) => {
    let objArray = ids.map((id: string, index: any) => {
        return {
            ...obj[id],
        }
    })

    let filtered = objArray.filter((task: ITask) => task.status === status)

    let arraySorted = Object.keys(filtered).map((id: string, index: any) => {
        return filtered[index].id
    })

    return arraySorted
}
