import React from 'react'
import styled from 'styled-components'
import Icon from '../../atoms/icons/index'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import EntryGalleryItemPreview from '../../atoms/timeline/entryGalleryItemPreview/entryGalleryItemPreview'
import { useLocation, Link } from 'react-router-dom'
import { IGalleryImagesObject } from '../../../redux/entities/galleries/types'

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    background-color: var(--bg-color, #fff);
    width: 100%;
    box-sizing: border-box;
`

const GoToBox = styled.div`
    background: var(--primary_20);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 0%;
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;
    aspect-ratio: 1.33;
    box-sizing: border-box;

    :hover {
        transition: all 200ms;
        background-color: var(--primary_30);
        cursor: pointer;
    }
`

interface Props {
    images_data_object: IGalleryImagesObject
    ids_list: string[] | null | undefined
    openImgForm?: () => void
    readOnlyMode?: boolean
}

const TrioGridFullpage: React.FC<Props> = ({
    images_data_object,
    ids_list,
    openImgForm,
    readOnlyMode,
}) => {
    const location = useLocation()

    return (
        <>
            <GridContainer>
                {ids_list &&
                    ids_list.map((id: string, index: number) => {
                        if (images_data_object[id]) {
                            return (
                                <Link
                                    to={{
                                        pathname: location.pathname,
                                        search: `?id=${id}`,
                                    }}
                                    state={{ prevPath: location.pathname }}
                                    key={index}
                                >
                                    <EntryGalleryItemPreview
                                        img={images_data_object[id]}
                                        isBestQuality
                                    />
                                </Link>
                            )
                        } else
                            return (
                                <div key={index} style={{ display: 'none' }} />
                            )
                    })}
                {!readOnlyMode && (
                    // IF USER OWN CAR DISPLAY ADD BTN
                    <GoToBox onClick={openImgForm}>
                        <Icon icon="add_plus" height={'20px'} />
                    </GoToBox>
                )}
            </GridContainer>

            {/* IF NO IMAGES IDS */}
            {(!ids_list || ids_list.length < 1) && readOnlyMode && (
                // IS EXTERNAL CAR (READ ONLY MODE) DISPLAY NO DATA RECTANGLE
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '50px',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            maxWidth: '1400px',
                        }}
                    >
                        <NoDataRectangle text="no images" />
                    </div>
                </div>
            )}
        </>
    )
}

export default TrioGridFullpage
