import { ITimelineEntryTypesAPI } from '../../services/typedefinitions/apiPayloads'
import { ITimelineEntryTypes } from '../../timeline/types'

export const categoryNameToEntryTypeArr = (
    categoryNames: ITimelineEntryTypes[]
) => {
    return categoryNames.map((elem: ITimelineEntryTypes) => {
        return categoryNameToEntryType(elem)
    })
}

export const entryTypeToCategoryNameArr = (
    categoryNames: ITimelineEntryTypesAPI[]
) => {
    return categoryNames.map((elem: ITimelineEntryTypesAPI) => {
        return entryTypeToCategoryName(elem)
    })
}

export const categoryNameToEntryType = (
    categoryName: ITimelineEntryTypes
): ITimelineEntryTypesAPI => {
    let backendName: ITimelineEntryTypesAPI =
        categoryName as ITimelineEntryTypesAPI

    if (categoryName === 'other') {
        backendName = 'others'
    }

    return backendName
}

export const entryTypeToCategoryName = (
    entryTypeName: ITimelineEntryTypesAPI
): ITimelineEntryTypes => {
    let fe_name: ITimelineEntryTypes = entryTypeName as ITimelineEntryTypes
    if (entryTypeName === 'others') {
        fe_name = 'other'
    }
    return fe_name
}
