import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Sheet } from 'react-modal-sheet'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import sort_by_created_green from '../../../../public/assets/icons/sort_by_created_green.svg'
import sort_alphabetical_green from '../../../../public/assets/icons/sort_alphabetical_green.svg'
import sort_by_updated_green from '../../../../public/assets/icons/sort_by_updated_green.svg'
import checked_green from '../../../../public/assets/icons/checked_green.svg'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { device } from '../../../templates/displays/devices'

const CustomSheet = styled(Sheet)`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: 250px !important;
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
    }
    .react-modal-sheet-header {
        height: 10px !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 0px !important;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`
const MenuItem = styled.div`
    color: var(--text-strong, #1a1a1a) !important;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    opacity: 1 !important;
    font-family: 'Lato';
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    letter-spacing: 0.09rem;
`
const MenuItemWrapper = styled.div`
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
`

const MenuItemBox = styled(motion.div)<IStyle>`
    flex-direction: row;
    color: var(--primary, #5ec3ca) !important;

    :focus {
        outline: 0;
    }
`

interface IStyle {
    is_last: boolean
}

type IactionsItems = {
    id: string
    text: string
    action?: any
}

interface IProps {
    actions_items: IactionsItems[]
    toggle: any
    isPrivateSharesSortingOpen: boolean
    activeSort: string
}

const PrivateSharesSortingMobile: React.FunctionComponent<IProps> = (props) => {
    const { actions_items, toggle, isPrivateSharesSortingOpen, activeSort } =
        props

    useEffect(() => {
        if (isPrivateSharesSortingOpen) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [isPrivateSharesSortingOpen])

    return (
        <IpadAndMobileDisplay>
            <CustomSheet isOpen={isPrivateSharesSortingOpen} onClose={toggle}>
                <Sheet.Container>
                    <Sheet.Header />
                    {/* <BottomSheetHeader> */}
                    {/* <BottomSheetHeaderClose
                            src={close_cross_grey}
                            onClick={() => onClose('return')}
                        />
                        <BottomSheetHeaderText>Account</BottomSheetHeaderText> */}
                    {/* </BottomSheetHeader> */}
                    <div
                        style={{
                            paddingTop: '0px',
                        }}
                        data-attr="gallery-image-menu"
                    >
                        {actions_items !== undefined &&
                            actions_items.map((item: any, index: any) => (
                                <MenuItemBox
                                    is_last={index === actions_items.length - 1}
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        item.action && item.action()
                                        toggle()
                                    }}
                                    key={`private_shares_sort_${item.id}_${index}_mobile`}
                                    whileTap={{
                                        opacity: 1,
                                        backgroundColor:
                                            'var(--border-muted, #e5e5e5)',
                                    }}
                                >
                                    <MenuItemWrapper>
                                        <MenuItem>
                                            <div
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    paddingLeft: '7px',
                                                    transform:
                                                        'translateY(3px)',
                                                }}
                                            >
                                                <img
                                                    src={
                                                        item.id ===
                                                            'created_at' ||
                                                        item.id === 'added_at'
                                                            ? sort_by_created_green
                                                            : item.id ===
                                                              'updated_at'
                                                            ? sort_by_updated_green
                                                            : sort_alphabetical_green
                                                    }
                                                    alt={
                                                        item.id === 'created_at'
                                                            ? 'sort_by_created'
                                                            : item.id ===
                                                              'updated_at'
                                                            ? 'sort_by_updated'
                                                            : 'sort_alphabetical'
                                                    }
                                                />
                                            </div>
                                            <span
                                                style={{
                                                    paddingRight: '15px',
                                                }}
                                            />

                                            <span
                                                style={{
                                                    color:
                                                        item.id === activeSort
                                                            ? 'var(--primary, #5ec3ca)'
                                                            : 'var(--text-strong, #1a1a1a)',
                                                }}
                                            >
                                                {item.text}
                                            </span>
                                        </MenuItem>
                                        {item.id === activeSort && (
                                            <div>
                                                <div
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                    }}
                                                >
                                                    <img
                                                        src={checked_green}
                                                        alt={'check'}
                                                        style={{
                                                            width: '15px',
                                                            height: '15px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </MenuItemWrapper>
                                </MenuItemBox>
                            ))}
                    </div>
                </Sheet.Container>
                <Sheet.Backdrop
                    data-attr="car-shares-menu-backdrop-overlay"
                    onTap={toggle}
                />
            </CustomSheet>
        </IpadAndMobileDisplay>
    )
}

export default PrivateSharesSortingMobile
