import {
    IDirectShareOwnedItem,
    IDirectShareOwnedItemsData,
    IPrivateShareReceivedItem,
    IShareRecipient,
} from '../../redux/entities/cars/types'
import { IDirectSharesReceivedObj } from '../../redux/user/types'

let sortByAlphabeticalNameFn = (x: any, y: any) => {
    return x.title.localeCompare(y.title)
}

export const sortSharesByAlphabetical = (
    ids: string[],
    data: IDirectShareOwnedItemsData
): string[] => {
    let arr = ids.map((id: string) => {
        return data[id]
    })

    let res = arr.sort(sortByAlphabeticalNameFn)

    return res.map((i: IDirectShareOwnedItem) => i.id)
}

let sortCarsReceivedSharesByAlphabeticalNameFn = (
    x: IPrivateShareReceivedItem,
    y: IPrivateShareReceivedItem
) => {
    return x.car_title && y.car_title
        ? x.car_title.localeCompare(y.car_title)
        : 0
}

let sortGaragesReceivedSharesByAlphabeticalNameFn = (
    x: IPrivateShareReceivedItem,
    y: IPrivateShareReceivedItem
) => {
    return x.owner.display_name.localeCompare(y.owner.display_name)
}

export const sortReceivedSharesByAlphabetical = (
    ids: string[],
    data: IDirectSharesReceivedObj,
    entityType: 'cars' | 'garage'
): string[] => {
    let arr = ids.map((id: string) => {
        return data[id]
    })

    let res =
        entityType === 'cars'
            ? arr.sort(sortCarsReceivedSharesByAlphabeticalNameFn)
            : arr.sort(sortGaragesReceivedSharesByAlphabeticalNameFn)

    return res.map((i: IPrivateShareReceivedItem) => i.id)
}

let sortRecipientsByAlphabeticalNameFn = (x: any, y: any) => {
    let x_compare = x.display_name
        ? x.display_name
        : x.given_name
        ? x.given_name
        : x.family_name
        ? x.family_name
        : 'Pending Account'

    let y_compare = y.display_name
        ? y.display_name
        : y.given_name
        ? y.given_name
        : y.family_name
        ? y.family_name
        : 'Pending Account'

    return x_compare.localeCompare(y_compare)
}

export const sortShareRecipientsByAlphabetical = (
    ids: string[],
    data: {
        [key: string]: IShareRecipient
    }
): string[] => {
    let arr = ids.map((id: string) => {
        return data[id]
    })

    let res = sortRecipientsByAlphabeticalNameFn
        ? arr.sort(sortRecipientsByAlphabeticalNameFn)
        : undefined

    return res ? res.map((i: IShareRecipient) => i.id) : ids
}
