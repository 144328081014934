import styled from 'styled-components'
import chevron from '../../../../public/assets/navigation/chevron_to_left.svg'
import Icon from '../../../atoms/icons'
import {
    IPublishValidationObject,
    IValidation_subObject,
} from '../../../templates/bars/showroomBars/bottomBars/carShowroomEditBottomBar'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import { useEffect, useState } from 'react'
import Expander from '../../../atoms/expander/expander'
import { message } from 'antd'
import close_cross_grey from '../../../../public/assets/icons/close_cross_grey.svg'
import InfoIcon from '../../../atoms/icons/infoIcon'
import { ShowroomValidationEntries } from './publishChecksManagerDesktop'
import React from 'react'
import { ICar } from '../../../../redux/entities/cars/types'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
`

const Title = styled.div`
    font-family: Lato-semibold;
    color: var(--text-strong, #121212);
    font-size: 18px;
    padding-bottom: 8px;
    padding-top: 12px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const SubText = styled.div`
    font-family: Lato;
    color: var(--text-default, #666666);
    font-size: 14px;
    @media (max-height: 630px) {
        max-width: 70vw;
    }
`

const ProgressBar = styled.div`
    height: 4px;
    background-color: var(--border-muted, #e5e5e5);
    width: 100%;
    box-sizing: border-box;
    border-radius: 100px;
    margin-top: 32px;
    margin-bottom: 12px;
    position: relative;
`

const ProgressBarFull = styled.div`
    height: 4px;
    background-color: var(--primary, #5ec3ca);
    border-radius: 100px;
`

const CheckContainer = styled.div<{ $isSubItem?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${(props) => (props.$isSubItem ? '40px' : '80px')};
    border-bottom: 1px solid var(--border-muted, #e5e5e5);

    @media (max-height: 700px) {
        height: ${(props) => (props.$isSubItem ? '32px' : '64px')};
    }
`

const CheckText = styled.div<{ $isDisabled?: boolean }>`
    font-family: Lato;
    color: ${(props) =>
        props.$isDisabled === true
            ? 'var(--text-muted, #b3b3b3)'
            : 'var(--text-default, #666666)'};
    font-size: 16px;
    padding-left: 16px;
    @media (max-width: 350px) {
        font-size: 15px;
    }
`

const CheckSquare = styled.div<{ isIncomplete?: boolean }>`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${(props) =>
        props.isIncomplete
            ? 'var(--error, #df6f6f)'
            : 'var(--primary, #5ec3ca)'};

    @media (max-height: 700px) {
        width: 32px;
        height: 32px;
    }
`

const Chevron = styled.img`
    height: 14px;
    justify-self: end;
    transition: all 200ms;
    transform: rotate(180deg);
`

const Close = styled.img`
    height: 14px;
    justify-self: end;
    @media (min-height: 630px) {
        display: none;
    }
`

const MinSquare = styled.div`
    position: absolute;
    z-index: 3;
    right: 24px;
    width: 42px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--off-bg-color, #f3f3f3);
    color: var(--text-muted, #b3b3b3);
    border-radius: 4px;
    @media (max-width: 350px) {
        right: 20px;
    }
`

interface Props {
    publishChecksObject: IPublishValidationObject
    currentCar: ICar | undefined
    currentCarId: string | undefined
    close: any
}

type PropsPublishCheck = {
    isIncomplete?: boolean
    text: string
    withoutBottomBorder: boolean
    onClick: any
    isSubItem?: boolean
    hasSubItems?: boolean
    isOpen?: boolean
    hasNitems?: number
    minN?: number
    tooltipText?: string
    isTooltipRemoved?: boolean
    isDisabled?: boolean
    onTooltipClick?: any
}

const PublishCheckItem = (props: PropsPublishCheck) => {
    const [messageApi, contextHolder] = message.useMessage()
    const displayMessage = (text: string | undefined) => {
        messageApi.open({
            type: 'info',
            content: text,
            icon: (
                <div
                    style={{
                        alignSelf: 'flex-start',
                        justifySelf: 'flex-start',
                        marginRight: 8,
                        marginLeft: 8,
                        marginTop: 3,
                    }}
                >
                    <InfoIcon color="var(--primary, #5EC3CA)" />
                </div>
            ),
            style: {
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                textAlign: 'left',
                color: 'var(--text-default, #666666)',
                fontFamily: 'Lato',
                fontSize: 14,
            },
        })
    }
    return (
        <CheckContainer
            style={{ borderBottomWidth: props.withoutBottomBorder ? 0 : 1 }}
            onClick={() => {
                if (!props.isDisabled) {
                    props.onClick()
                }
            }}
            $isSubItem={props.isSubItem}
            role="button"
            aria-disabled={props.isDisabled}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.isSubItem ? (
                    <div
                        style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            transform: props.isIncomplete
                                ? 'scale(0.85) translateY(0px)'
                                : 'scale(1) translateY(1px)',
                        }}
                    >
                        <CustomAnimatedCheckboxField
                            checked={!props.isIncomplete}
                            onChange={() => {}}
                            isDisabled={props.isIncomplete}
                            extra_small
                        />
                    </div>
                ) : (
                    <CheckSquare isIncomplete={props.isIncomplete}>
                        <Icon
                            icon={
                                props.isIncomplete
                                    ? 'add_plus_white'
                                    : 'white_check'
                            }
                            width={props.isIncomplete ? 'auto' : '15px'}
                        />
                    </CheckSquare>
                )}
                <CheckText
                    $isDisabled={props.isDisabled}
                    style={props.minN ? { maxWidth: '55vw' } : {}}
                >
                    {props.text}
                </CheckText>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                {contextHolder}
                {props.minN && !props.isTooltipRemoved && (
                    <MinSquare
                        onClick={(e: any) => {
                            e.preventDefault()
                            e.stopPropagation()
                            displayMessage(props.tooltipText)

                            // props.onTooltipClick()
                        }}
                    >{`${props.hasNitems}/${props.minN}`}</MinSquare>
                )}
                <Chevron
                    alt={'go_to_car'}
                    src={chevron}
                    style={{
                        filter: props.hasSubItems
                            ? 'none'
                            : 'invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg) brightness(63%) contrast(84%)',
                        transform:
                            props.hasSubItems && props.isOpen
                                ? 'rotate(-90deg)'
                                : 'rotate(180deg)',
                    }}
                />
            </div>
        </CheckContainer>
    )
}

const PublishChecksManagerMobile = (props: Props) => {
    const { currentCar, publishChecksObject } = props

    const [subItemsOpenState, setSubItemsOpenState] = useState<{
        [key: string]: boolean
    }>({})

    useEffect(() => {
        Object.entries(publishChecksObject).forEach((check) => {
            let checkName = check[0]
            let checkValues = check[1]
            if (checkValues.subItems) {
                setSubItemsOpenState({
                    ...subItemsOpenState,
                    [checkName]: false,
                })
            }
        })
    }, [publishChecksObject])

    let checksArray: ShowroomValidationEntries<IPublishValidationObject> =
        Object.entries(publishChecksObject)

    let activePropgressBarLength: number = checksArray.filter(
        (item) => item[1].check === true
    ).length

    return currentCar ? (
        <Container>
            <Title>
                Not ready to publish{' '}
                <Close
                    alt={'dismiss'}
                    src={close_cross_grey}
                    style={{ width: '12px', height: '12px' }}
                    onClick={props.close}
                />
            </Title>
            <SubText>
                Add missing information to create a perfect listing
            </SubText>
            <ProgressBar>
                <ProgressBarFull
                    style={{
                        width:
                            activePropgressBarLength < 4
                                ? `${
                                      (90 * activePropgressBarLength) /
                                      checksArray.length
                                  }vw`
                                : '100%',
                    }}
                />
            </ProgressBar>
            {checksArray.map(
                (check: [string, IPublishValidationObject[string]], i) => {
                    let checkName = check[0]
                    let checkValues = check[1]
                    if (checkValues.subItems) {
                        let subChecksArray = Object.values(checkValues.subItems)

                        return (
                            <React.Fragment
                                key={`showroom_valid_sheet_${i}_mobile`}
                            >
                                <PublishCheckItem
                                    isTooltipRemoved={
                                        checkValues.isTooltipRemoved
                                    }
                                    isIncomplete={checkValues.check === false}
                                    hasSubItems
                                    hasNitems={checkValues.hasNitems}
                                    minN={checkValues.minN}
                                    tooltipText={checkValues.tooltipText}
                                    isOpen={subItemsOpenState[checkName]}
                                    text={checkValues.text}
                                    withoutBottomBorder
                                    onClick={() => {
                                        setSubItemsOpenState((prevState) => {
                                            return {
                                                // closing all others and
                                                ...subItemsOpenState,
                                                // keeping only one open
                                                [checkName]:
                                                    !prevState[checkName],
                                            }
                                        })
                                    }}
                                />

                                <Expander
                                    height={
                                        subItemsOpenState[checkName]
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    {subChecksArray.map(
                                        (
                                            subItem: IValidation_subObject,
                                            indexx
                                        ) => (
                                            <React.Fragment
                                                key={`showroom_valid_sheet_sub_item_${indexx}_mobile`}
                                            >
                                                <PublishCheckItem
                                                    isIncomplete={
                                                        subItem.check === false
                                                    }
                                                    text={subItem.text}
                                                    isSubItem
                                                    withoutBottomBorder
                                                    onClick={() => {
                                                        subItem.action()
                                                    }}
                                                    isDisabled={
                                                        subItem.isDisabled
                                                    }
                                                    hasNitems={
                                                        subItem.hasNitems
                                                    }
                                                    minN={subItem.minN}
                                                    tooltipText={
                                                        subItem.tooltipText
                                                    }
                                                    isTooltipRemoved={
                                                        subItem.isTooltipRemoved
                                                    }
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                    <div
                                        style={{
                                            paddingTop: '16px',
                                        }}
                                    />
                                </Expander>
                                <div
                                    style={{
                                        height: 1,
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        backgroundColor:
                                            'var(--border-muted, #e5e5e5)',
                                    }}
                                />
                            </React.Fragment>
                        )
                    } else
                        return (
                            <React.Fragment
                                key={`showroom_valid_sheet_${i}_mobile`}
                            >
                                <PublishCheckItem
                                    isTooltipRemoved={
                                        checkValues.isTooltipRemoved
                                    }
                                    hasNitems={checkValues.hasNitems}
                                    minN={checkValues.minN}
                                    tooltipText={checkValues.tooltipText}
                                    isIncomplete={checkValues.check === false}
                                    text={checkValues.text}
                                    withoutBottomBorder={
                                        i === checksArray.length - 1
                                    }
                                    onClick={() => {
                                        checkValues.action()
                                        props.close()
                                    }}
                                    onTooltipClick={() => {
                                        setSubItemsOpenState(subItemsOpenState)
                                        // weird logic here.
                                    }}
                                />
                            </React.Fragment>
                        )
                }
            )}
        </Container>
    ) : (
        <div style={{ display: 'none' }} />
    )
}

export default PublishChecksManagerMobile
