import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ConvertSingleApiTask } from '../../conversions/tasks/taskTypeConversions'
import { getGarageByGarageId } from '../../entities/garages/sagas/garage'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { IGetTaskRemindersReq, getTaskRemindersSaga } from './getTaskReminders'
import { carActions } from '../../entities/cars/reducer'
import { garagesActions } from '../../entities/garages/reducer'
import { tasksActions } from '../reducer'
import { IRootState } from '../../store'
import {
    ITaskGarageIdsPayloads,
    ITasksNormalisedByGarage,
} from '../../entities/garages/types'
import { ITaskPayloadAPI } from '../../services/typedefinitions/apiPayloads'
import { ITaskNormalisedObject, ITask, IReminder } from 'entityModels'
import {
    ITasksNormalisedByCar,
    ICustomErrorData,
    IReduxError,
} from '../../entities/cars/types'

// get cars in the state
const getGarageInState = (state: IRootState) =>
    state.entities.garagesData.garages

// get cars in the state
const getCarsInState = (state: IRootState) => state.entities.carsData.cars

// get tasks in the state
const getTasksInState = (state: IRootState) => state.tasks.tasks

// get user garage id
const getUserGarageId = (state: IRootState) =>
    state.user.userLoggedIn
        ? state.user.userLoggedIn.owns_garage?.uid
        : undefined

// add tasks uids to Car entity

function* addTaskIdsToCarSaga(payload: ITasksNormalisedByCar): any {
    // later on : put API TRY CATH

    yield put(carActions.setCarTaskIdsRequest())

    yield put(carActions.setCarTaskIdsSuccess(payload))
}

// add tasks uids to Garage entity

function* addTaskIdsToGarageSaga(payload: ITaskGarageIdsPayloads): any {
    // later on : put API TRY CATH

    yield put(garagesActions.setGarageTaskIdsRequest())

    yield put(garagesActions.setGarageTaskIdsSuccess(payload))
}

// GET TASKS BY GARAGE ID

function* getTasksByGarageIdSaga(garageid: string): any {
    // later on : put API TRY CATH

    try {
        const tasks_from_api: ITaskPayloadAPI[] = yield call(
            api.tasks.getData.filterTasksByGarageId,
            garageid
        )

        let normalised_converted_tasks: ITaskNormalisedObject = {}

        let tasks_ids_normalised_by_car: ITasksNormalisedByCar = {}
        let tasks_ids_normalised_by_garage: ITasksNormalisedByGarage = {}

        let tasks_ids_garage_level_only_list: string[] = []

        if (tasks_from_api) {
            for (const task of tasks_from_api) {
                let converted_task: ITask = ConvertSingleApiTask(task)

                let taskid = converted_task.id

                let task_cars = converted_task.linkedToCar

                if (task_cars) {
                    // change this to array got from the API garage or call the get garage endpoint before this func
                    let carsInState = yield select(getCarsInState)

                    if (task_cars.length === 1) {
                        if (carsInState[task_cars[0]] !== undefined) {
                            // && this is where I remove the task from list // dont include it :
                            // need to check whether the car exists in the Cars state as a condition
                            if (
                                tasks_ids_normalised_by_car[task_cars[0]] ===
                                undefined
                            ) {
                                tasks_ids_normalised_by_car[task_cars[0]] = [
                                    taskid,
                                ]
                            } else {
                                tasks_ids_normalised_by_car[task_cars[0]] = [
                                    ...tasks_ids_normalised_by_car[
                                        task_cars[0]
                                    ],
                                    taskid,
                                ]
                            }
                            normalised_converted_tasks[taskid] = converted_task

                            if (!tasks_ids_normalised_by_garage[garageid]) {
                                tasks_ids_normalised_by_garage[garageid] = [
                                    taskid,
                                ]
                            } else if (
                                tasks_ids_normalised_by_garage[garageid]
                            ) {
                                tasks_ids_normalised_by_garage[garageid] = [
                                    ...tasks_ids_normalised_by_garage[garageid],
                                    taskid,
                                ]
                            }
                        }
                    } else {
                        for (const indexcarid in task_cars) {
                            // if (carsInState[indexcarid] !== undefined) {
                            // && this is where I remove the task from list // dont include it :
                            // need to check whether the car exists in the Cars state as a condition
                            if (
                                tasks_ids_normalised_by_car[
                                    task_cars[indexcarid]
                                ] === undefined
                            ) {
                                tasks_ids_normalised_by_car[
                                    task_cars[indexcarid]
                                ] = [taskid]
                            } else {
                                tasks_ids_normalised_by_car[
                                    task_cars[indexcarid]
                                ] = [
                                    ...tasks_ids_normalised_by_car[
                                        task_cars[indexcarid]
                                    ],
                                    taskid,
                                ]
                            }
                            // under those conditions, add it to the redux Tasks state
                            normalised_converted_tasks[taskid] = converted_task

                            if (!tasks_ids_normalised_by_garage[garageid]) {
                                tasks_ids_normalised_by_garage[garageid] = [
                                    taskid,
                                ]
                            } else if (
                                tasks_ids_normalised_by_garage[garageid]
                            ) {
                                tasks_ids_normalised_by_garage[garageid] = [
                                    ...tasks_ids_normalised_by_garage[garageid],
                                    taskid,
                                ]
                            }
                        }
                        // }
                    }
                } else if (task_cars === undefined || !task_cars) {
                    let doesArrayOfGarageLevelOnlyTasksHasTaskAlready =
                        tasks_ids_garage_level_only_list.includes(taskid)

                    if (
                        doesArrayOfGarageLevelOnlyTasksHasTaskAlready !== true
                    ) {
                        tasks_ids_garage_level_only_list.push(taskid)
                    }
                    if (!tasks_ids_normalised_by_garage[garageid]) {
                        tasks_ids_normalised_by_garage[garageid] = [taskid]
                    } else if (tasks_ids_normalised_by_garage[garageid]) {
                        tasks_ids_normalised_by_garage[garageid] = [
                            ...tasks_ids_normalised_by_garage[garageid],
                            taskid,
                        ]
                    }
                    // under those conditions, add it to the redux Tasks state
                    normalised_converted_tasks[taskid] = converted_task
                }
                // conditions do not allow for the task to be stored in state: its car is unrelated.
                // normalised_converted_tasks[taskid] = converted_task
            }

            yield fork(addTaskIdsToCarSaga, tasks_ids_normalised_by_car)
        }

        if (tasks_ids_normalised_by_garage[garageid]) {
            let payload_set_garage_tasks: ITaskGarageIdsPayloads = {
                garage_id: garageid,
                tasks_ids_garage_level_only_list:
                    tasks_ids_garage_level_only_list,
                tasks_normalised_by_garage: tasks_ids_normalised_by_garage,
            }
            yield fork(addTaskIdsToGarageSaga, payload_set_garage_tasks)
        }

        yield put(
            tasksActions.getTasksByGarageIdSuccess(normalised_converted_tasks)
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: getTasksByGarageIdSaga,
                payload: garageid,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't get your tasks`,
                custom_user_action_text: 'Return to garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(tasksActions.getTasksByGarageIdError(customError))
        }
    }
}

function* watcherGetTasksByGarageId(): Generator<any, any, any> {
    while (true) {
        const { payload } = yield take(tasksActions.getTasksByGarageIdRequest)

        let garagesInState = yield select(getGarageInState)
        let carsInState = yield select(getCarsInState)

        if (
            !garagesInState ||
            !carsInState ||
            Object.keys(garagesInState).length === 0 ||
            Object.keys(carsInState).length === 0
        ) {
            yield call(getGarageByGarageId, payload)
        }

        yield call(getTasksByGarageIdSaga, payload)
    }
}

// GET TASKS BY CAR ID

function* getTasksByCarIdSaga(carid: string): any {
    // later on : put API TRY CATH

    // for now : 0x86470a = mock car id

    try {
        const tasks_from_api: ITaskPayloadAPI[] = yield call(
            api.tasks.getData.filterTasksByCarId,
            carid
        )

        let normalised_converted_tasks: ITaskNormalisedObject = {}

        let task_ids_list: string[] = []

        for (const task of tasks_from_api) {
            let converted_task: ITask = ConvertSingleApiTask(task)

            let id = converted_task.id

            task_ids_list.push(id)

            normalised_converted_tasks[id] = converted_task
        }

        // let payload_tasks_ids: ITasksNormalisedByCar = {
        //     carid: task_ids_list,
        // }

        let payload_tasks_ids: ITasksNormalisedByCar = {}

        payload_tasks_ids[carid] = task_ids_list

        yield fork(addTaskIdsToCarSaga, payload_tasks_ids)

        yield put(
            tasksActions.getTasksByCarIdSuccess(normalised_converted_tasks)
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getTasksByCarIdSaga,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't get your car tasks`,
                custom_user_action_text: 'Return to car page',
                custom_redirect_path: `/car/${carid}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(tasksActions.getTasksByCarIdError(customError))
        }
    }
}

function* watcherGetTasksByCarId(): Generator<any, any, any> {
    while (true) {
        const { payload } = yield take(tasksActions.getTasksByCarIdRequest)

        let garageid = yield select(getUserGarageId)
        let garagesInState = yield select(getGarageInState)
        let carsInState = yield select(getCarsInState)
        if (garageid !== undefined) {
            if (
                !garagesInState ||
                !carsInState ||
                Object.keys(garagesInState).length === 0 ||
                Object.keys(carsInState).length === 0
            ) {
                yield call(getGarageByGarageId, garageid)
            }
            yield call(getTasksByCarIdSaga, payload)
        }
    }
}

// GET TASK BY TASK ID

function* getTaskByIdSaga(taskid: string): any {
    // later on : put API TRY CATH

    try {
        const task_from_api: ITaskPayloadAPI = yield call(
            api.tasks.getData.getTaskById,
            taskid
        )

        let converted_task: ITask = ConvertSingleApiTask(task_from_api)

        if (converted_task.due) {
            let pa: IGetTaskRemindersReq = {
                taskUID: taskid,
                dueDate: converted_task.due,
            }

            let tasksInState = yield select(getTasksInState)

            let currentTask: ITask | undefined =
                tasksInState && taskid && tasksInState[taskid]

            let currentTaskReminders: IReminder[] | undefined =
                currentTask && currentTask.reminders

            let reminders: IReminder[] | undefined = currentTaskReminders
                ? [...currentTaskReminders]
                : yield call(getTaskRemindersSaga, pa)

            if (reminders) {
                converted_task.reminders = reminders
                converted_task.has_reminders = true
            }
        }

        let normalised_converted_task: ITaskNormalisedObject = {}

        normalised_converted_task[converted_task.id] = converted_task

        yield put(tasksActions.getTaskByIdSuccess(normalised_converted_task))
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getTaskByIdSaga,
                payload: taskid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't get the task`,
                custom_user_action_text: 'Return to see all tasks',
                custom_redirect_path: `/tasks`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(tasksActions.getTaskByIdError(customError))
        }
    }
}

function* watcherGetTaskbyId() {
    while (true) {
        const { payload } = yield take(tasksActions.getTaskByIdRequest)

        yield call(getTaskByIdSaga, payload)
    }
}

const get_tasks: any[] = [
    fork(watcherGetTasksByGarageId),
    fork(watcherGetTasksByCarId),
    fork(watcherGetTaskbyId),
]

export default get_tasks
