import {
    IDropdownItem,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import * as React from 'react'
import styled from 'styled-components'
import DatePickerAntd from '../../atoms/datePickerAntd/datePickerAntd'
import CustomIconTagQuickFilterItemDesktop from '../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemDesktop'
import CustomTagQuickFilterItemDesktop from '../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import FilterByMultipleWSearchWFuse from '../../organisms/filterByMultiple/labels/labelsFilterByMultipleWSearchWFuseDesktop'
import MileageEditOrCreateModeDesktop from '../editOrCreateModeSingleFields/mileageEditOrCreateMode/mileageEditOrCreateModeDesktop'
import { timeline_category_data } from '../../../redux/timeline/data'
import CalendarIcon from '../../atoms/icons/components/calendar'
import colours from '../../../providers/theme/colours'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { IRootState } from '../../../redux/store'
import { timelineActions } from '../../../redux/timeline/reducer'
import { technicalInformationActions } from '../../../redux/entities/technical_information/reducer'
import { ILabel, ITimelineItem } from '../../../redux/timeline/types'
import useThemes from '../../../providers/theme/hooks'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
`

const MenuTitle = styled.h3`
    padding: 0;
    margin: 0;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 8px;
`

const CategoryWrapper = styled.div`
    cursor: not-allowed;
`

const LabelsContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

const ColumnWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const CategoryText = styled.div`
    color: var(--bg-color, #fff);
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.001px;
    text-transform: capitalize;
`

interface Props {
    carid: string
    activeEntry: ITimelineItem
    noAddLabelButton?: boolean
    onAddLabels?: any
    onRemoveLabel?: any
    onEditDate?: any
    readOnlyMode?: boolean
}

const EntryOptionsMenuBar: React.FC<Props> = ({
    carid,
    activeEntry,
    noAddLabelButton,
    onAddLabels,
    onRemoveLabel,
    onEditDate,
    readOnlyMode,
}) => {
    let { theme } = useThemes()
    const dispatch = useAppDispatch()
    const userLoggedIn = useAppSelector(
        (state: IRootState) => state.user.userLoggedIn
    )
    const technicalInformationData = useAppSelector(
        (state: IRootState) =>
            state.entities.technicalInformationData.technical_information
    )
    const user_searchable_labels_list = useAppSelector(
        (state: IRootState) => state.user.userLoggedIn?.labels
    )
    const entry_labels_list = useAppSelector(
        (state: IRootState) => state.timeline.activeEntry?.labels
    )

    React.useEffect(() => {
        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }
        const tech_info_in_state =
            technicalInformationData && technicalInformationData[`hf-${carid}`]
        if (!tech_info_in_state && !readOnlyMode) {
            dispatch(carActions.getCarDataByIdRequest(carid))
        }
    }, [userLoggedIn, technicalInformationData, carid, readOnlyMode, dispatch])

    const generatePrePopulatedLabels = (): IDropdownItem[] => {
        const user_searchable_labels_list_names =
            user_searchable_labels_list?.map(
                (label: { name: any }) => label.name
            )

        if (activeEntry && activeEntry.categoryID) {
            const pre_populated_labels: IDropdownItem[] =
                timeline_category_data[
                    activeEntry.categoryID
                ].pre_populated_labels
                    .filter(
                        (label) =>
                            !user_searchable_labels_list_names?.includes(label)
                    )
                    .map((label: string) => ({
                        uid: label,
                        name: label,
                    }))
            return pre_populated_labels
        }
        return []
    }

    const handleDateChange = (dateString: any, fieldID?: string) => {
        if (!readOnlyMode && activeEntry) {
            const editedEntry = { ...activeEntry }
            if (!fieldID) {
                if (dateString) {
                    editedEntry.occurred_at = dateString
                    editedEntry.date = dateString
                }

                dispatch(
                    timelineActions.updateTimelineEntryRequest({
                        carid: carid,
                        item: { ...editedEntry, id: activeEntry?.id },
                    })
                )
            } else {
                editedEntry[fieldID] = dateString

                dispatch(
                    timelineActions.updateTimelineEntryRequest({
                        carid: carid,
                        item: { ...editedEntry, id: activeEntry?.id },
                    })
                )
            }
        }
    }

    const handleRemoveLabel = (labelname: string) => {
        const labelToRemove =
            activeEntry &&
            activeEntry.labels?.filter(
                (label: { name: string }) => label.name === labelname
            )

        labelToRemove &&
            dispatch(
                timelineActions.removeLabelFromCarEntryRequest({
                    car_id: carid,
                    entry_id: activeEntry && activeEntry.id,
                    labels: labelToRemove,
                })
            )
    }

    const userDistanceUnit =
        userLoggedIn &&
        userLoggedIn.preferences.data.units.data.distance_unit.user_choice_id

    const carMileageFromState: number | string | undefined | null =
        carid &&
        technicalInformationData &&
        technicalInformationData[`hf-${carid}`] &&
        technicalInformationData[`hf-${carid}`].mileage &&
        technicalInformationData[`hf-${carid}`].mileage.answer

    const carMileage: number | undefined | null =
        typeof carMileageFromState === 'string'
            ? parseInt(carMileageFromState)
            : carMileageFromState

    return (
        <Wrapper>
            {activeEntry.categoryID === 'insurance' ? (
                <ColumnWrapper>
                    <MenuTitle>
                        {activeEntry.categoryID === 'insurance'
                            ? 'Start Date'
                            : 'Date'}
                    </MenuTitle>

                    <div
                        onClick={(e: any) =>
                            readOnlyMode ? e.preventDefault() : {}
                        }
                        style={{ width: '100%' }}
                    >
                        <DatePickerAntd
                            onChange={(dateStr: any) => {
                                handleDateChange(
                                    dateStr,
                                    'insurance_entry_policy_start_date'
                                )
                            }}
                            value={activeEntry.date}
                            readOnlyMode={readOnlyMode}
                            additionalStyle={` background: transparent; border-radius: 4px; box-shadow: none; padding: 8px; width: 100%;
    box-sizing: border-box;`}
                            suffixIcon={<CalendarIcon />}
                            allowClear={false}
                            clearIcon={null}
                            customFormat={['DD.MM.YYYY']}
                            allowOnlyPast
                        />
                    </div>

                    <div style={{ paddingTop: '16px' }} />

                    <MenuTitle>End Date</MenuTitle>

                    <div
                        onClick={(e: any) =>
                            readOnlyMode ? e.preventDefault() : {}
                        }
                        style={{ width: '100%' }}
                    >
                        <DatePickerAntd
                            onChange={(dateStr: any) => {
                                handleDateChange(
                                    dateStr,
                                    'insurance_entry_policy_end_date'
                                )
                            }}
                            value={activeEntry.insurance_entry_policy_end_date}
                            readOnlyMode={readOnlyMode}
                            additionalStyle={` background: transparent; border-radius: 4px; box-shadow: none; padding: 8px; width: 100%;
    box-sizing: border-box;`}
                            suffixIcon={<CalendarIcon />}
                            allowClear={false}
                            clearIcon={null}
                            customFormat={['DD.MM.YYYY']}
                        />
                    </div>
                </ColumnWrapper>
            ) : (
                <ColumnWrapper>
                    <MenuTitle>Date</MenuTitle>

                    <div
                        onClick={(e: any) =>
                            readOnlyMode ? e.preventDefault() : {}
                        }
                        style={{ width: '100%' }}
                    >
                        <DatePickerAntd
                            onChange={handleDateChange}
                            value={activeEntry.date}
                            readOnlyMode={readOnlyMode}
                            additionalStyle={` background: transparent; border-radius: 4px; box-shadow: none; padding: 8px; width: 100%;
    box-sizing: border-box;`}
                            suffixIcon={<CalendarIcon />}
                            allowClear={false}
                            clearIcon={null}
                            customFormat={['DD.MM.YYYY']}
                            allowOnlyPast
                        />
                    </div>
                </ColumnWrapper>
            )}

            <ColumnWrapper>
                <MenuTitle>Mileage</MenuTitle>

                <MileageEditOrCreateModeDesktop
                    title={'Mileage at time of entry'}
                    value_start={activeEntry.mileage}
                    onSubmit={(payload: {
                        updateCarMileage: boolean
                        entryMileage: number
                        customCarMileage: number
                        user_distance_unit: string
                    }) => {
                        const editedItem = { ...activeEntry }
                        editedItem.mileage = payload.entryMileage

                        dispatch(
                            timelineActions.updateTimelineEntryRequest({
                                carid: carid,
                                item: { ...editedItem, id: activeEntry?.id },
                            })
                        )

                        const payloadFoBe:
                            | IwriteTechnicalInformationPayloadReq
                            | undefined = carid
                            ? {
                                  tech_info_id: `hf-${carid}`,
                                  car_id: carid,
                                  data: {
                                      id: 'mileage',
                                      text: 'mileage',
                                      answer: payload.customCarMileage,
                                  },
                              }
                            : undefined

                        payload.updateCarMileage &&
                            payloadFoBe &&
                            dispatch(
                                technicalInformationActions.writeTechnicalInformationRequest(
                                    payloadFoBe
                                )
                            )
                    }}
                    carMileage={carMileage}
                    userDistanceUnits={userDistanceUnit}
                    dataCyId={'edit_entry_mileage'}
                    customMileageDataCyId={
                        'edit_car_mileage_from_entry_with_custom_value'
                    }
                    readOnlyMode={readOnlyMode}
                />
            </ColumnWrapper>

            <CategoryWrapper>
                <MenuTitle>Category</MenuTitle>
                <CustomIconTagQuickFilterItemDesktop
                    height="24px"
                    id={activeEntry.categoryID}
                >
                    <CategoryText>{activeEntry.categoryID}</CategoryText>
                </CustomIconTagQuickFilterItemDesktop>
            </CategoryWrapper>
            <ColumnWrapper>
                <MenuTitle>Labels</MenuTitle>
                <LabelsContainer>
                    {activeEntry.labels &&
                        activeEntry.labels.map((label: ILabel, i: number) => (
                            <div
                                style={{
                                    paddingRight: '10px',
                                    paddingTop: '10px',
                                }}
                                key={`label-${label} - ${i}_desktop`}
                            >
                                {readOnlyMode ? (
                                    <CustomTagQuickFilterItemDesktop
                                        bgcolor={
                                            colours[theme].background_default
                                        }
                                        height="24px"
                                    >
                                        {label.name}
                                    </CustomTagQuickFilterItemDesktop>
                                ) : (
                                    <CustomTagQuickFilterItemDesktop
                                        bgcolor={
                                            colours[theme].background_default
                                        }
                                        onCrossClick={() =>
                                            handleRemoveLabel(label.name)
                                        }
                                        height="24px"
                                        hasBoxShadow
                                    >
                                        {label.name}
                                    </CustomTagQuickFilterItemDesktop>
                                )}
                            </div>
                        ))}
                    {!noAddLabelButton && (
                        <div
                            style={{
                                paddingRight: '10px',
                                paddingTop: '10px',
                            }}
                        >
                            <FilterByMultipleWSearchWFuse
                                addLabelButton
                                category_searchable_items_list={undefined}
                                user_searchable_labels_list={
                                    user_searchable_labels_list
                                        ? user_searchable_labels_list.concat(
                                              generatePrePopulatedLabels() as ILabel[]
                                          )
                                        : (generatePrePopulatedLabels() as ILabel[])
                                }
                                onSave={(selectedLabels: ILabel[]) => {
                                    const editedEntry: ITimelineItem = {
                                        ...activeEntry,
                                    }
                                    editedEntry.labels = selectedLabels
                                    dispatch(
                                        timelineActions.updateTimelineEntryRequest(
                                            {
                                                carid: carid,
                                                item: {
                                                    ...editedEntry,
                                                    id: activeEntry?.id,
                                                },
                                            }
                                        )
                                    )
                                }}
                                hasCreateNewLabel
                                entry_labels_list={
                                    entry_labels_list ? entry_labels_list : []
                                }
                            />
                        </div>
                    )}
                </LabelsContainer>
            </ColumnWrapper>
        </Wrapper>
    )
}

export default EntryOptionsMenuBar
