import { IDropdownItem } from 'entityModels'
import { useCallback, useEffect, useState } from 'react'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import { OwnerShowroomFormActions } from '../../../../organisms/showroom/contactForm/ownerContactFormDesktop'
import OwnerBasicDetailsFormMobile from './ownerBasicDetailsFormMobile'
import { getRightSchemaAccountData } from '../../../../organisms/editForms/account/validationSchema'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { message } from 'antd'
import { IInsuranceDropdownItem } from '../../../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'
import { IUserAddressApi } from '../../../../../redux/services/typedefinitions/apiPayloads'
import { usersActions } from '../../../../../redux/user/reducer'
import { IUser } from '../../../../../redux/user/types'

type Props = {
    close: () => any
    hasInfoChange: boolean
    setHasInfoChange: (p: boolean) => any
}

export type IShowroomOwnerBasicDetailsString =
    | 'street_number'
    | 'route'
    | 'name'
    | 'phone'
    | 'postal_town'
    | 'postal_code'
    | 'country'
    | 'administrative_area_level_1'
    | 'administrative_area_level_2'

export type IGooglePlacesAddress = {
    street_number: string
    route: string
    postal_town: string
    postal_code: string
    country: string
    administrative_area_level_1: string
    administrative_area_level_2: string
}

export interface IShowroomOwnerBasicDetails extends IGooglePlacesAddress {
    name: string | undefined
    phone: string | undefined
}

const OwnerBasicDetailsManagerMobile = (props: Props) => {
    let userData: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const [street_number, setStreet_number] = useState<string>(
        userData?.address?.street_1 ? userData.address.street_1 : ''
    )
    const [route, setRoute] = useState<string>(
        userData?.address?.street_2 ? userData.address.street_2 : ''
    )
    const [postal_town, setPostal_town] = useState<string>(
        userData?.address?.locality ? userData.address.locality : ''
    )
    const [postal_code, setPostal_code] = useState<string>(
        userData?.address?.postcode ? userData.address.postcode : ''
    )

    const [country, setCountry] = useState<string>(
        userData?.address?.country ? userData.address.country : 'United Kingdom'
    )
    const [administrative_area_level_1, setAdministrative_area_level_1] =
        useState<string>(
            userData?.address?.administrative_area
                ? userData.address.administrative_area
                : ''
        )

    const [administrative_area_level_2, setAdministrative_area_level_2] =
        useState<string>(
            userData?.address?.administrative_area
                ? userData.address.administrative_area
                : ''
        )

    const [name, setName] = useState<string>(
        userData && userData.display_name ? userData.display_name : ''
    )
    const [phone, setPhone] = useState<string | undefined>(
        userData && userData.phone ? userData.phone : undefined
    )

    let { hasInfoChange, setHasInfoChange } = props

    const [isCountryChangedFromSearch, setIsCountryChangedFromSearch] =
        useState<boolean>(false)

    const [error, setError] = useState<{ [key: string]: string } | undefined>(
        undefined
    )

    let dial_code: string | undefined = useAppSelector((state) => {
        return state.editForms.user_dial_code
    })

    const validateForm = async (id: string, value: string) => {
        try {
            await getRightSchemaAccountData(id).validate({
                [id]: value,
            })
            setError(undefined)
        } catch (err: any) {
            setError((prevState) => {
                return { ...prevState, [id]: err.errors[0] }
            })
        }
    }

    let getPhoneNumber = async () => {
        try {
            let numberr = `${`${dial_code}` + phone}`
            let isPhoneNbValid = isValidPhoneNumber(numberr)
            if (isPhoneNbValid) {
                return parsePhoneNumber(numberr)
            } else {
                message.error({
                    content: `Invalid Phone Number`,
                })
                return undefined
            }
        } catch (e: any) {
            // setHasInvalidN(true)
            message.error({
                content: `Invalid Phone Number`,
            })
            return undefined
        }
    }
    const isFormValid = (): boolean => {
        if (error && Object.values(error).some((item) => item !== undefined)) {
            return false
        }
        if (!name || (name && name.length < 1)) {
            return false
        }

        // if (!phone) {
        //     return false
        // }

        if (street_number.length === 0 && route.length === 0) {
            return false
        } else if (
            country.length === 0 ||
            postal_code.length === 0 ||
            postal_town.length === 0
        ) {
            return false
        }

        return true
    }

    // generate id here with useWindow()

    let input = document.getElementById(
        `autocomplete_showroom_owner_address_mobile`
    ) as HTMLInputElement

    let dispatch = useAppDispatch()

    let onSubmit = async () => {
        let addressConv: IUserAddressApi = {
            locality: postal_town,
            postcode: postal_code,
            country: country,
            street_1: street_number,
            street_2: route,
            type: 'User Address',
            administrative_area:
                country === 'Spain'
                    ? administrative_area_level_2
                    : country === 'United States'
                    ? administrative_area_level_1
                    : undefined,
        }

        let hasAddressChange = (): boolean => {
            let hasChange = false

            if (!userData?.address) {
                hasChange = true
            }

            if (addressConv.country !== userData?.address?.country) {
                hasChange = true
            }
            if (addressConv.postcode !== userData?.address?.postcode) {
                hasChange = true
            }
            if (addressConv.locality !== userData?.address?.locality) {
                hasChange = true
            }
            if (addressConv.street_1 !== userData?.address?.street_1) {
                hasChange = true
            }
            if (addressConv.street_2 !== userData?.address?.street_2) {
                hasChange = true
            }

            if (
                addressConv.administrative_area !==
                userData?.address?.administrative_area
            ) {
                hasChange = true
            }

            return hasChange
        }

        if (hasAddressChange()) {
            dispatch(usersActions.addAddressRequest({ data: addressConv }))
        }
        if (userData && userData.id) {
            let data1: {
                display_name?: string
                phone?: string
            } = {}

            if (name && name !== userData.display_name) {
                data1['display_name'] = name
            }

            if (phone) {
                let ph = userData && userData.phone ? userData.phone : undefined

                if (!ph || (ph && ph !== phone)) {
                    let phoneNumber = await getPhoneNumber()

                    if (phoneNumber && phoneNumber?.isValid()) {
                        data1['phone'] = `${phone}`
                    } else {
                        return
                    }
                }
            }

            if (Object.keys(data1).length > 0) {
                dispatch(
                    usersActions.updateUserGeneralInfoRequest({
                        id: userData.id,
                        data: { ...data1 },
                    })
                )
            }
        }

        setHasInfoChange(false)
    }

    let usaAdminCodes: IInsuranceDropdownItem[] | null = useAppSelector(
        (state) => {
            return state.localdata.dropdownData.usaStatesCodes
        }
    )

    const googleMaps = useCallback(async () => {
        try {
            let autocomplete = new google.maps.places.Autocomplete(input, {
                types: ['geocode'],
            })

            autocomplete.setFields(['address_component'])

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    let geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }

                    let circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy,
                    })
                    autocomplete.setBounds(circle.getBounds())
                })
            }
            autocomplete.addListener('place_changed', () => {
                let place: any = autocomplete.getPlace()

                if (!hasInfoChange) {
                    setHasInfoChange(true)
                }

                let addressComponents: string[] = []

                place.address_components !== undefined &&
                    place.address_components.forEach((chunk: any) => {
                        addressComponents.push(chunk.types[0])
                        if (chunk.types[0] === 'street_number') {
                            setStreet_number(chunk.long_name)
                            validateForm('street_number', chunk.long_name)
                        }
                        if (chunk.types.includes('route')) {
                            setRoute(chunk.long_name)
                            validateForm('route', chunk.long_name)
                        }
                        if (chunk.types.includes('postal_code')) {
                            setPostal_code(
                                chunk.long_name
                                    ? `${chunk.long_name}`.toUpperCase()
                                    : ''
                            )
                            validateForm(
                                'postal_code',
                                chunk.long_name
                                    ? `${chunk.long_name}`.toUpperCase()
                                    : ''
                            )
                        }
                        if (chunk.types.includes('locality')) {
                            setPostal_town(chunk.long_name)
                        }
                        if (chunk.types.includes('postal_town')) {
                            setPostal_town(chunk.long_name)
                            validateForm('postal_town', chunk.long_name)
                        }
                        if (
                            chunk.types.includes('administrative_area_level_1')
                        ) {
                            setAdministrative_area_level_1(chunk.short_name)
                        }
                        if (
                            chunk.types.includes('administrative_area_level_2')
                        ) {
                            setAdministrative_area_level_2(chunk.short_name)
                        }
                        if (chunk.types.includes('country')) {
                            setCountry(chunk.long_name)
                            setIsCountryChangedFromSearch(true)
                        }
                    })

                if (!addressComponents.includes('street_number')) {
                    validateForm('street_number', '')
                    setStreet_number('')
                }
                if (!addressComponents.includes('route')) {
                    validateForm('route', '')
                    setRoute('')
                }
                if (!addressComponents.includes('postal_code')) {
                    validateForm('postal_code', '')
                    setPostal_code('')
                }
                if (!addressComponents.includes('postal_town')) {
                    validateForm('postal_town', '')
                    setPostal_town('')
                }
            })
        } catch (e) {
            console.error(e)
        } finally {
        }
    }, [input])

    useEffect(() => {
        if (input) {
            googleMaps()
        }
        if (country === 'United States' && !usaAdminCodes) {
            dispatch(dropDownActions.setUSAStatesDropdownRequest())
        }
    }, [input])

    let handleSwitch = (
        value: string,
        id: IShowroomOwnerBasicDetailsString
    ) => {
        switch (id) {
            case 'name':
                return setName(`${value}`)

            case 'country':
                return setCountry(`${value}`)

            case 'administrative_area_level_1':
                return setAdministrative_area_level_1(`${value}`)

            case 'phone':
                return setPhone(value)

            case 'postal_code':
                return setPostal_code(`${value}`)

            case 'postal_town':
                return setPostal_town(`${value}`)

            case 'route':
                return setRoute(`${value}`)

            case 'street_number':
                return setStreet_number(`${value}`)
        }
    }

    let handleChange = (
        value: string,
        id: IShowroomOwnerBasicDetailsString
    ) => {
        if (
            [
                'street_number',
                'route',
                'name',
                // 'phone',
                'postal_town',
                'postal_code',
            ].includes(id)
        ) {
            validateForm(id, value)
        }
        if (!hasInfoChange) {
            setHasInfoChange(true)
        }

        handleSwitch(value, id)
    }

    let handleSelectCountry = (answer: string) => {
        if (!hasInfoChange) {
            setHasInfoChange(true)
        }
        setCountry(answer)
        setIsCountryChangedFromSearch(false)

        if (answer === 'United States' && !usaAdminCodes) {
            dispatch(dropDownActions.setUSAStatesDropdownRequest())
        }

        if (answer !== 'United States' && administrative_area_level_1) {
            setAdministrative_area_level_1('')
        }

        if (answer !== 'Spain' && administrative_area_level_2) {
            setAdministrative_area_level_2('')
        }
    }

    let countriesOptions: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    useEffect(() => {
        if (!countriesOptions) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <OwnerBasicDetailsFormMobile
                formData={{
                    street_number: street_number,
                    route: route,
                    postal_town: postal_town,
                    postal_code: postal_code,
                    country: country,
                    administrative_area_level_1: administrative_area_level_1,
                    administrative_area_level_2: administrative_area_level_2,
                    name: name,
                    phone: phone,
                }}
                handleChange={handleChange}
                handleSelectCountry={handleSelectCountry}
                isCountryChangedFromSearch={isCountryChangedFromSearch}
                close={props.close}
                isFormValid={isFormValid}
                countriesOptions={countriesOptions}
            />
            <div style={{ paddingTop: '100px' }} />
            <OwnerShowroomFormActions>
                <ButtonAtom
                    theme="capitalize-white-background"
                    width="74px"
                    height="48px"
                    dataCyId={'showroom-car-share'}
                    icon={''}
                    fontSize={16}
                    onClick={() => {
                        props.close()
                    }}
                >
                    <div style={{}}>Close</div>
                </ButtonAtom>

                <ButtonAtom
                    theme="secondary"
                    width="180px"
                    height="48px"
                    textTransform="capitalize"
                    dataCyId={'showroom-car-enquire'}
                    onClick={() => {
                        onSubmit()
                    }}
                    disabled={
                        isFormValid() === false || !hasInfoChange ? true : false
                    }
                    fontSize={16}
                >
                    Save Basic Details
                </ButtonAtom>
            </OwnerShowroomFormActions>

            <div style={{ paddingTop: '20px' }} />
        </div>
    )
}

export default OwnerBasicDetailsManagerMobile
