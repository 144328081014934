import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import store, { AppDispatch, IRootState } from '.'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useThunkAppDispatch = () =>
    store.dispatch as ThunkDispatch<IRootState, Promise<void>, AnyAction>
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector
