import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const H1Styled = styled.h1`
    font-family: Lato;
    color: #5ec3ca;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    margin: 0;

    @media ${device.mobile} {
        font-size: 19px;
        line-height: 28px;
    }
    @media ${device.ipad} {
        font-size: 24px;
        line-height: 28px;
    }

    @media ${device.smallest_laptops} {
        font-size: 26px;
    }

    @media ${device.desktop} {
        font-size: 26px;
        line-height: 40px;
    }

    @media ${device.large_desktop} {
        font-size: 27px;
    }
`

export interface IHeaderProps {
    children?: React.ReactNode
}

const BlueTextHeader: React.FunctionComponent<IHeaderProps> = (props) => (
    <H1Styled>{props.children}</H1Styled>
)

export default BlueTextHeader
