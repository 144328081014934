import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'

type Props = {
    customZindex?: number
    onClick?: () => void
    children: ReactNode
}

const PortalInvisibleWrapper: React.FC<Props> = ({
    customZindex,
    onClick,
    children,
}) => {
    return createPortal(
        <div
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                zIndex: customZindex ?? 2,
            }}
            onClick={onClick}
        >
            {children}
        </div>,
        document.getElementById('modal_root') as HTMLElement
    )
}

export default PortalInvisibleWrapper
