import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { motion } from 'framer-motion'
import React from 'react'
import useThemes from '../../../../providers/theme/hooks'
import { history_file_colours } from '../../../../providers/theme/colours'
import { renderCategoryIcon } from '../../../atoms/icons/components/historyFile/categories'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import {
    ITimelineCategoryData,
    ITimelineCategoryDataItem,
    ITimelineEntryTypes,
} from '../../../../redux/timeline/types'
import { IEditOrCreateModeSingleFieldsProps } from '../types'

const WrapperMobile = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media ${device.ipad} {
        justify-content: flex-start;
    }
`

const Title = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    font-size: 16px;
    text-transform: capitalize;
    /* padding-bottom: 15px; */
`

const Description = styled.div`
    font-family: Lato;
    color: var(--text-muted);
    font-size: 14px;
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    justify-self: start;
    width: 55vw;

    /* padding-left: 20px; */
`

type IStyle = {
    $bgColor: string
}

const IconContainer = styled.div<IStyle>`
    width: 50px;
    height: 50px;
    justify-self: start;
    margin-right: 20px;
    background-color: ${(props) =>
        props.$bgColor ? props.$bgColor : 'var(--primary, #5ec3ca)'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.ipad} {
        margin-right: 30px;
    }
`

const Option = styled(motion.div)<{ isactive?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }
    background-color: ${(props) =>
        props.isactive ? 'var(--primary_08)' : 'transparent'};
`

const Separator = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

const SmallPadding = styled.div`
    padding-top: 30px;
`

interface Props {
    data: IEditOrCreateModeSingleFieldsProps
    onOptionClick: any
    dataCyId?: string
    optionsData: ITimelineCategoryData
    selectedOption?: string | undefined
    optionsIDArr: string[]
}
const OptionsWithIconAndDescription = (props: Props) => {
    const {
        data,
        onOptionClick,
        dataCyId,
        optionsIDArr,
        optionsData,
        selectedOption,
    } = props

    const { theme } = useThemes()

    const submitOption = (name: any) => {
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                name,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, name)
        }
    }

    let renderOptions = () => {
        return optionsIDArr.map((optionID: string, i: number) => {
            if (optionsData[optionID]) {
                return optionsUI(optionsData[optionID], i)
            } else
                return (
                    <div
                        key={`option_none_${i}_mobile`}
                        style={{ display: 'none' }}
                    />
                )
        })
    }
    let optionsUI = (option: ITimelineCategoryDataItem, i: number) => {
        return (
            <React.Fragment key={`option_${option.name}_option_${i}_mobile`}>
                <Option
                    isactive={selectedOption === option.name}
                    onClick={(e) => {
                        submitOption(option.name)
                        setTimeout(() => {
                            e.preventDefault()
                            onOptionClick()
                        }, 50)
                    }}
                    whileTap={{ scale: 1.05 }}
                    data-attr={`option_${i + 1}`}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <IconContainer
                            $bgColor={
                                history_file_colours[theme].categories[
                                    option.id as ITimelineEntryTypes
                                ].primary_100
                            }
                        >
                            {renderCategoryIcon(
                                option.id as ITimelineEntryTypes,
                                '18'
                            )}
                        </IconContainer>
                        <TextContainer>
                            <Title>{option.name}</Title>
                            <Description>{option.description}</Description>
                        </TextContainer>
                    </div>
                    <div style={{ transform: 'rotate(-90deg)' }}>
                        <ChevronDown color="#b3b3b3" />
                    </div>
                </Option>
                {optionsIDArr && i < optionsIDArr.length - 1 && <Separator />}
            </React.Fragment>
        )
    }

    return (
        <WrapperMobile data-attr={dataCyId}>
            {renderOptions()}
            <SmallPadding />
            <SmallPadding />
        </WrapperMobile>
    )
}
export default OptionsWithIconAndDescription
