import styled from 'styled-components'
import React, { useState } from 'react'
import UsersSearchBar from '../../carHandoverManagers/usersSearchBar'
import IconAndTextNakedButton from '../../../atoms/Button/iconsAndTextNakedButton'
import { IShareRecipientRequest_redux } from '../../../../redux/entities/cars/actions'
import { IDirectShareOwnedItem } from '../../../../redux/entities/cars/types'
import { ISearchUsersResult } from '../../../../redux/searchUsers/types'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Title = styled.div`
    font-family: Lato;
    font-size: 17px;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
`

const SubTitle = styled.div`
    font-family: Lato;
    font-size: 15px;
    color: var(--text-default, #666666);
`

const SearchWrapper = styled.div`
    width: 450px;
    padding-top: 30px;
`

interface Props {
    share: IDirectShareOwnedItem
    searchUsers: (value: string | undefined) => void
    searchResults: ISearchUsersResult[] | undefined
    resultsLoading: boolean
    addShareRecipient: (p: IShareRecipientRequest_redux) => void
    isGarage?: boolean
    selectedUsers: string[]
    onClickInvite: any
    inviteTxt?: string
}

const PrivateShareSearchFormManagerDesktop: React.FC<Props> = ({
    share,
    searchUsers,
    searchResults,
    resultsLoading,
    addShareRecipient,
    isGarage,
    selectedUsers,
    onClickInvite,
    inviteTxt,
}) => {
    const [searchFor, setSearchFor] = useState<string | undefined>(undefined)
    const [typedValue, setTypedValue] = useState<string | undefined>(undefined)

    return (
        <Container>
            <Title>Choose your Share Recipient(s)</Title>
            <SubTitle>
                {`Please search and select the recipient(s) that you would like to share your
                ${isGarage ? 'garage' : 'car'} with by entering their email.`}
            </SubTitle>
            <SearchWrapper>
                <UsersSearchBar
                    searchResults={searchResults}
                    onSearch={(value: string) => {
                        searchUsers(value)
                        setSearchFor(value)
                    }}
                    searchFor={searchFor}
                    clearSearch={() => {
                        setSearchFor(undefined)
                        setTypedValue(undefined)
                        searchUsers(undefined)
                    }}
                    onSearchResultSelect={(user: any) => {
                        setSearchFor(undefined)
                        setTypedValue(undefined)

                        addShareRecipient({
                            share_id: share.id,
                            entity_id: share.entity_id,
                            recipient_userid: user.uid,
                        })
                    }}
                    onInputChange={(value: string) => {
                        setTypedValue(value)
                    }}
                    loadingPlaceholder={
                        resultsLoading ||
                        (typedValue && searchFor !== typedValue)
                            ? true
                            : false
                    }
                    placeholder={`Search recipient's Email`}
                    selectedUsers={selectedUsers}
                    hasInviteOption={true}
                    onClickInvite={onClickInvite}
                    inviteText={inviteTxt}
                    ownEmailSearchCopy="You cannot share to yourself."
                />
            </SearchWrapper>

            <div style={{ paddingTop: '18px' }} />

            {(searchResults === undefined || searchResults?.length === 0) &&
                (typedValue === undefined || typedValue === '') && (
                    <IconAndTextNakedButton
                        onClick={() => onClickInvite()}
                        text="Invite to Custodian via Email & Share"
                        iconOnLeft
                        icon="v2_add"
                    />
                )}
        </Container>
    )
}

export default PrivateShareSearchFormManagerDesktop
