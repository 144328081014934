import React from 'react'
import styled from 'styled-components'
import DuoGridItemMobile from '../../atoms/image/duoGridItemMobile'
import { Link, useLocation } from 'react-router-dom'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import { device } from '../../templates/displays/devices'
import img_1 from '../../atoms/skeleton/garage/1.png'
import img_2 from '../../atoms/skeleton/garage/2.png'
import img_3 from '../../atoms/skeleton/garage/3.png'
import img_4 from '../../atoms/skeleton/garage/4.png'
import img_1_dark from '../../atoms/skeleton/garage/1_dark.png'
import img_2_dark from '../../atoms/skeleton/garage/2_dark.png'
import img_3_dark from '../../atoms/skeleton/garage/3_dark.png'
import img_4_dark from '../../atoms/skeleton/garage/4_dark.png'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import AddImageGrid from '../../atoms/image/addImageGrid'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const Wrapper = styled.section`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`

const PlaceholderWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    @media ${device.ipad} {
        height: 26vh;
    }
`

const Placeholder = styled.img`
    object-fit: cover !important;
    width: 100%;
    box-sizing: border-box;
    height: 126px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 400ms;

    @media ${device.ipad} {
        height: 26vh;
    }
`

type Obj = {
    [key: string]: any
}

interface Props {
    dataToMap: any[]
    carsdata: Obj
    onClick?: () => void
    imagesData: any
    isExternalGarage?: boolean
    dontShowAddBox?: boolean
    dataLoading?: boolean
    dataPHAddBox?: string
    reset_make_model_temps: () => void
}

const GarageCarsDuoGridMobile: React.FC<Props> = ({
    dataToMap,
    carsdata,
    onClick,
    imagesData,
    isExternalGarage,
    dontShowAddBox,
    dataLoading,
    dataPHAddBox,
    reset_make_model_temps,
}) => {
    let { theme } = useThemes()

    const location = useLocation()

    const car_cover_url = (carObj: any) => {
        let imageid =
            carObj &&
            carObj.gallery &&
            carObj.gallery.cover &&
            carObj.gallery.cover

        if (imageid) {
            if (imagesData[imageid]) {
                return imagesData[imageid]
            }
        } else return undefined
    }

    let placeholdersArray = [1, 2, 3, 4]
    const checkIfGarageIsEmpty = !dataToMap || dataToMap.length === 0

    const getPlaceholderImg = (i: number, theme: ITheme): string => {
        switch (i) {
            case 1:
                return theme === 'dark' ? img_1_dark : img_1
            case 2:
                return theme === 'dark' ? img_2_dark : img_2
            case 3:
                return theme === 'dark' ? img_3_dark : img_3
            case 4:
                return theme === 'dark' ? img_4_dark : img_4
            default:
                return ''
        }
    }

    return (
        <Wrapper>
            {dataToMap.map((id, index) => {
                let obj = carsdata[id]
                let cover = car_cover_url(obj)
                let placeholder = undefined

                if (cover === undefined) {
                    placeholder = randomCarSvgPlaceholderFunc(undefined, theme)
                }

                let hasShowroomEntry: string | undefined =
                    obj &&
                    obj.showroom_entry &&
                    obj.showroom_entry?.state === 'PUBLISHED'
                        ? 'for sale'
                        : obj &&
                          obj.showroom_entry &&
                          obj.showroom_entry?.state === 'SOLD'
                        ? 'sold'
                        : undefined

                return (
                    <Link
                        replace={true}
                        to={{
                            pathname: isExternalGarage
                                ? `/shared/car/${id}`
                                : `/car/${id}`,
                        }}
                        state={{
                            prevPath: location.pathname,
                            search: location.search,
                        }}
                        onClick={() => {
                            reset_make_model_temps()
                        }}
                        key={`duo_grid_${id} _${index}_mobile`}
                    >
                        <DuoGridItemMobile
                            img={cover}
                            placeholder={placeholder}
                            tag={hasShowroomEntry}
                        />
                    </Link>
                )
            })}

            {checkIfGarageIsEmpty && !isExternalGarage && !dataLoading
                ? placeholdersArray.map((item, i) => (
                      <PlaceholderWrapper
                          key={`duo_grid_placeholder_${i}_mobile`}
                      >
                          <Placeholder src={getPlaceholderImg(item, theme)} />
                      </PlaceholderWrapper>
                  ))
                : !isExternalGarage &&
                  !dontShowAddBox &&
                  !dataLoading && (
                      <AddImageGrid
                          ipadHeight="26vh"
                          mobileHeight="126px"
                          onClick={onClick && onClick}
                          dataPH={dataPHAddBox}
                      />
                  )}
            {checkIfGarageIsEmpty && !dataLoading && (
                <div
                    style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        boxSizing: 'border-box',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {!isExternalGarage ? (
                        <ButtonAtom
                            width={'230px'}
                            theme={'lowercase-blue-background'}
                            fontSize={14}
                            onClick={() => onClick && onClick()}
                            dataPH={'add_car'}
                        >
                            <div
                                style={{
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Start adding cars to your garage
                            </div>
                        </ButtonAtom>
                    ) : (
                        <div
                            style={{
                                marginTop: 200,
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        >
                            <NoDataRectangle
                                text="This garage is empty"
                                height="200px"
                            />
                        </div>
                    )}
                </div>
            )}
        </Wrapper>
    )
}

export default GarageCarsDuoGridMobile
