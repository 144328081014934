import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import TimelineEditManagerMobile from '../../organisms/timelineForms/timelineEditManager/timelineEditManagerMobile'
import TimelineEditManagerDesktop from '../../organisms/timelineForms/timelineEditManager/timelineEditManagerDesktop'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { TimelineFormTypeIDsEnum } from '../../../redux/timeline/timelineEnum'
import BottomUpSlider from '../../templates/animated/bottomUpSlider'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import DeletionConfirmationDesktop from '../../molecules/deletionConfirmationDesktop/deletionConfirmationDesktop'
import EntryOptionsMenuBar from '../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { usersActions } from '../../../redux/user/reducer'
import { carActions } from '../../../redux/entities/cars/reducer'
import { timelineActions } from '../../../redux/timeline/reducer'
import { ICostItem, ITimelineItem } from '../../../redux/timeline/types'

const EditCost: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { carid, entryid } = useParams<{ carid: string; entryid: string }>()

    const [showConfirmDeletionDesktop, setShowConfirmDeletionDesktop] =
        useState(false)
    const [showConfirmDeletionMobile, setShowConfirmDeletionMobile] =
        useState(false)
    const [showRightSide, setShowRightSide] = useState(true)

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const carsTimelineData = useAppSelector(
        (state) => state.timeline.timeline_data_normalised_by_car_id
    )
    const activeEntry = useAppSelector((state) => state.timeline.activeEntry)
    const activeCost = useAppSelector((state) => state.timeline.activeCost)
    const timelineItemEditForm = useAppSelector(
        (state) => state.editForms.timelineItemEditForm
    )

    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/car/${carid}/history-file/${entryid}/cost/edit`
            )
        )

        if (!userLoggedIn) {
            dispatch(usersActions.getCurrentUserDataRequest())
        }

        const q_params = new URLSearchParams(location.search)
        let costid: string | null = q_params.get('cost_id')

        if (!activeEntry) {
            dispatch(carActions.setCurrentCar(`${carid}`))

            let all_entries = carsTimelineData?.[`${carid}`]?.entries

            if (!all_entries) {
                dispatch(
                    timelineActions.getAllTimelineItemsByCarIDRequest({
                        car_id: `${carid}`,
                    })
                )
            }

            let activeEntryFromParams = all_entries?.find(
                (entry: ITimelineItem) => entry.id === entryid
            )

            if (activeEntryFromParams) {
                dispatch(
                    timelineActions.setActiveEntrySuccess(activeEntryFromParams)
                )
            }

            if (userLoggedIn && !activeEntry) {
                dispatch(
                    timelineActions.getTimelineItemByIDRequest({
                        entryUID: `${entryid}`,
                        car_id: `${carid}`,
                    })
                )
            }
        }

        if (activeEntry && costid && activeEntry.costItemsObj?.[costid]) {
            dispatch(
                timelineActions.setActiveCostSuccess(
                    activeEntry.costItemsObj[costid]
                )
            )
        }

        dispatch(
            editFormsActions.manageTimelineItemEditForm({
                id: TimelineFormTypeIDsEnum.cost,
                category: activeEntry?.categoryID,
                isOpen: true,
            })
        )

        window.scrollTo(0, 0)
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            dispatch(
                editFormsActions.manageTimelineItemEditForm({ isOpen: false })
            )
            window.removeEventListener('resize', handleResize)
        }
    }, [
        carid,
        entryid,
        userLoggedIn,
        activeEntry,
        carsTimelineData,
        location.search,
    ])

    const handleResize = () => {
        setShowRightSide(window.innerWidth >= 1201)
    }

    const close_edit_form = () => {
        const redirectToEntryPage = () => {
            if (activeEntry?.id) {
                navigate(
                    `/car/${carid}/history-file/entry?entryid=${activeEntry.id}`
                )
            }
        }
        setTimeout(redirectToEntryPage, 50)
        dispatch(editFormsActions.manageTimelineItemEditForm({ isOpen: false }))
    }

    const q_params = new URLSearchParams(location.search)
    let costid: string | null = q_params.get('cost_id')

    return (
        <Faded>
            {costid && carid && entryid && (
                <CenteredPageWrapper fullwidth>
                    <DesktopDisplayOnly>
                        <div style={{ position: 'relative' }}>
                            <WindowPageTemplate
                                isCollapsed={isCollapsed}
                                rightSideContent={
                                    activeEntry &&
                                    showRightSide && (
                                        <EntryOptionsMenuBar
                                            carid={carid}
                                            activeEntry={activeEntry}
                                        />
                                    )
                                }
                            >
                                <TimelineEditManagerDesktop
                                    timeline_item={activeEntry as ITimelineItem}
                                    item_to_edit={activeCost as ICostItem}
                                    timelineItemEditForm={timelineItemEditForm}
                                    savingEdit={(editedCost: ICostItem) => {
                                        if (entryid && costid && carid) {
                                            dispatch(
                                                timelineActions.updateTimelineItemCostRequest(
                                                    {
                                                        itemid: entryid,
                                                        costid: costid,
                                                        carid: carid,
                                                        cost: editedCost,
                                                    }
                                                )
                                            )
                                        }
                                    }}
                                    closeForm={close_edit_form}
                                    dataCyId={'edit-cost-entry'}
                                    entityType="cost"
                                    entityID={costid}
                                    carID={carid}
                                    onDeleteClick={() =>
                                        setShowConfirmDeletionDesktop(true)
                                    }
                                />
                            </WindowPageTemplate>

                            <DeletionConfirmationDesktop
                                isEditForm
                                isOpen={showConfirmDeletionDesktop}
                                toggle={() =>
                                    setShowConfirmDeletionDesktop(false)
                                }
                                action={() => {
                                    if (costid && carid && entryid) {
                                        dispatch(
                                            timelineActions.deleteTimelineItemCostRequest(
                                                {
                                                    itemid: entryid,
                                                    costid: costid,
                                                    carid: carid,
                                                }
                                            )
                                        )
                                    }
                                }}
                                actionCopy="Confirm deletion"
                                titleCopy="Are you sure?"
                                detailsCopy="On deletion, the data will be lost."
                            />
                        </div>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <BottomUpSlider show={timelineItemEditForm.isOpen}>
                            <TimelineEditManagerMobile
                                item={activeCost ? activeCost : null}
                                timelineItemEditForm={timelineItemEditForm}
                                savingEdit={(editedCost: ICostItem) => {
                                    if (entryid && costid && carid) {
                                        dispatch(
                                            timelineActions.updateTimelineItemCostRequest(
                                                {
                                                    itemid: entryid,
                                                    costid: costid,
                                                    carid: carid,
                                                    cost: editedCost,
                                                }
                                            )
                                        )
                                    }
                                }}
                                closeForm={close_edit_form}
                                dataCyId={'edit-cost-entry'}
                                entityType="cost"
                                entityID={costid}
                                carID={carid}
                                onDeleteClick={() =>
                                    setShowConfirmDeletionMobile(true)
                                }
                            />
                        </BottomUpSlider>
                        <DeletionConfirmationBottomSheet
                            isOpen={showConfirmDeletionMobile}
                            toggle={() => setShowConfirmDeletionMobile(false)}
                            action={() => {
                                if (costid && carid && entryid) {
                                    dispatch(
                                        timelineActions.deleteTimelineItemCostRequest(
                                            {
                                                itemid: entryid,
                                                costid: costid,
                                                carid: carid,
                                            }
                                        )
                                    )
                                }
                            }}
                            actionCopy="Confirm deletion"
                            titleCopy="Are you sure?"
                            detailsCopy="On deletion, the data will be lost."
                        />
                    </IpadAndMobileDisplay>
                </CenteredPageWrapper>
            )}
        </Faded>
    )
}

export default EditCost
