import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

interface IStyle {
    $height?: string
    $bgColor?: string
    $isInfoRestricted?: boolean
}

const Rectangle = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    height: ${(props) => (props.$height ? props.$height : '82px')};
    background-color: ${(props) =>
        props.$bgColor ? props.$bgColor : 'var(--off-bg-color, #fafafa)'};
    border: 1px solid var(--border_muted_subtle);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Lato;
    padding-left: 24px;

    @media ${device.beyond_ipad_mobile} {
        width: 42vw;
        min-width: 750px;
    }
`

const Text = styled.div`
    font-size: 16px;
    color: var(--text-muted);
    padding-left: 10px;
    @media ${device.mobile} {
        font-size: 14px;
    }
`

type IProps = {
    height?: string
    text?: string
    bgColor?: string
}

const NoIconDataRectangle: React.FunctionComponent<IProps> = (props) => {
    return (
        <Rectangle $height={props.height} $bgColor={props.bgColor}>
            <Text>{props.text ? props.text : 'No data'}</Text>
        </Rectangle>
    )
}

export default NoIconDataRectangle
