import { call, fork, put, take } from 'redux-saga/effects'
import { ResponseGenerator } from 'sagaInterfaces'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { fileStorageActions } from '../../reducer'
import { IReduxError } from '../../../entities/cars/types'
import {
    IUploadAttachmentPreSignedUrlPayloadSingle,
    IUploadImagePreSignedUrlPayloadSingle,
} from '../../types'

export function* getPreSignedUrlCostAttachment(
    payload: IUploadAttachmentPreSignedUrlPayloadSingle
) {
    try {
        const result: ResponseGenerator =
            yield api.fileStorage.get_upload_presigned_url_cost_attachment_API(
                payload
            )

        const data: IUploadImagePreSignedUrlPayloadSingle = result.data

        yield put(fileStorageActions.getPresignedUrlCostAttachmentSuccess(data))
        return data
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: getPreSignedUrlCostAttachment,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                fileStorageActions.getPresignedUrlCostAttachmentFileStorageError(
                    typedError
                )
            )
        }
    }
}

function* watchGetPreSignedUrlCostAttachment() {
    while (true) {
        const { payload } = yield take(
            fileStorageActions.getPresignedUrlCostAttachmentRequest
        )

        let p: IUploadAttachmentPreSignedUrlPayloadSingle = {
            carID: payload.carID,
            fileSuffix: payload.fileSuffix,
            entityID: payload.entityID,
        }
        yield call(getPreSignedUrlCostAttachment, p)
    }
}

const filestorage_sagas_presigned_urls_cost_attachments: any[] = [
    fork(watchGetPreSignedUrlCostAttachment),
]

export default filestorage_sagas_presigned_urls_cost_attachments
