import * as React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import CrossIcon from '../../../atoms/icons/crossIcon'
import LandingPageMainButton from '../../../atoms/Button/landingPageMainButton'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { device } from '../devices'
import CircleLoader from '../../../atoms/loader/circleLoader'
import { useAppSelector } from '../../../../redux/store/hooks'
import { isMobile, isTablet } from 'react-device-detect'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { insurance_colours } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

interface Props {
    children?: React.ReactNode
    isOpen: boolean
    cancel?: () => void
    title?: string
    footerBtn?: {
        action?: () => void
        isDisabled?: boolean
        text?: string
        isLoading?: boolean
    }
    customFooterBtns?: React.ReactNode
    sectionId?: IInsuranceApplicationSectionId
    removeBottomPadding?: boolean
}

const Wrapper = styled.div`
    z-index: 110;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 200ms;
    @media ${device.mobile_and_ipad} {
        height: 100vh;
        width: 100vw;
    }
`

const Content = styled.div`
    z-index: 11;
    position: fixed;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    height: 100%;
    width: 35%;
    max-width: 700px;
    min-width: 500px;
    top: 0;
    bottom: 0;
    right: 0;
    transition: all 200ms;
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
        border-radius: 100px;
    }

    ::-webkit-scrollbar-track {
        background: var(--scroll-bar-track-color);
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--scroll-bar-handle-color);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--scroll-bar-handle-color);
    }

    @media ${device.mobile_and_ipad} {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        max-width: unset;
        min-width: unset;
    }
`

const Title = styled.div`
    z-index: 111;
    position: fixed;
    top: 0;
    height: 100%;
    width: 35%;
    max-width: 700px;
    min-width: 500px;
    height: 88px;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    font-family: Lato-bold;
    font-size: 24px;
    line-height: 40px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile_and_ipad} {
        height: auto;
        width: 100%;
        box-sizing: border-box;
        max-width: unset;
        min-width: unset;
        padding: 12px 24px;
        font-size: 19px;
    }
    @media ${device.small_mobile} {
        font-size: 16px;
    }
`

const Footer = styled.div`
    z-index: 50;
    position: fixed;
    bottom: 0;
    height: 100%;
    width: 35%;
    max-width: 700px;
    min-width: 500px;
    height: 88px;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 32px;
    @media ${device.mobile_and_ipad} {
        height: auto;
        width: 100%;
        box-sizing: border-box;
        max-width: unset;
        min-width: unset;
    }
`

const RightSideModalDisplay: React.FunctionComponent<Props> = (props) => {
    let {
        cancel,
        isOpen,
        title,
        footerBtn,
        sectionId,
        customFooterBtns,
        removeBottomPadding,
    } = props

    let insuranceQuoteApplication = useAppSelector(
        (state) => state.insuranceQuoteApplication
    )

    let isDraft = insuranceQuoteApplication.submitted_data?.status === 'DRAFT'

    const { theme } = useThemes()

    React.useEffect(() => {
        if (isOpen && !isMobile && !isTablet) {
            disableBodyScroll(document.body)
        }

        return () => {
            if (!isMobile && !isTablet) {
                enableBodyScroll(document.body)
            }
        }
    }, [isOpen])

    return (
        <AnimatePresence>
            <Wrapper style={{ opacity: isOpen ? 1 : 0 }}>
                <motion.div
                    style={{ height: '100%' }}
                    initial={{
                        opacity: 0,
                        x: 900,
                    }}
                    animate={{
                        opacity: 1,
                        x: 0,
                    }}
                    exit={{ opacity: 0, x: 900 }}
                    transition={{
                        duration: 0.3,
                        opacity: {
                            delay: 0,
                            duration: 0.15,
                            type: 'linear',
                        },
                        x: { delay: 0.08, duration: 0.1, type: 'linear' },
                    }}
                >
                    <Content
                        style={{
                            paddingTop: title ? 88 : 0,
                            paddingBottom:
                                footerBtn || customFooterBtns ? 88 : 0,
                        }}
                    >
                        {title && (
                            <Title>
                                {title}
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                    }}
                                    onClick={cancel}
                                >
                                    <CrossIcon />
                                </div>
                            </Title>
                        )}
                        <motion.div
                            style={{ height: '100%' }}
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                            }}
                            exit={{ opacity: 0 }}
                            transition={{
                                duration: 0.2,
                                opacity: {
                                    delay: 0.4,
                                    duration: 0.2,
                                    type: 'linear',
                                },
                            }}
                        >
                            {props.children}

                            {removeBottomPadding ? null : (
                                <div style={{ paddingBottom: '100px' }} />
                            )}
                        </motion.div>
                        {isDraft && footerBtn ? (
                            <Footer>
                                <LandingPageMainButton
                                    onClick={footerBtn.action}
                                    disabled={footerBtn.isDisabled}
                                    normal_height="48px"
                                    desktop_height="48px"
                                    custom_bg={
                                        sectionId
                                            ? insurance_colours[theme].sections[
                                                  sectionId
                                              ].primary_100
                                            : undefined
                                    }
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 8,
                                            textTransform: 'none',
                                            fontFamily: 'Lato',
                                            letterSpacing: 'normal',
                                        }}
                                    >
                                        {footerBtn.text}
                                        {footerBtn.isLoading && (
                                            <CircleLoader size={20} />
                                        )}
                                    </div>
                                </LandingPageMainButton>
                            </Footer>
                        ) : null}
                        {customFooterBtns ? (
                            <Footer>{customFooterBtns}</Footer>
                        ) : null}
                    </Content>
                </motion.div>
            </Wrapper>
        </AnimatePresence>
    )
}

export default RightSideModalDisplay
