import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { IShowroomFiltersState } from '../../../../redux/showroom/types'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { SearchIcon } from '../../../atoms/statefulicons'
import Expander from '../../../atoms/expander/expander'
import { ErrorText } from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerDesktop'

import { LocationFilterValidation } from './validationSchema'

const ContentWrapper = styled.div`
    transform: translateY(10px);
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 240px;
`
type IsActive = {
    isActive?: boolean
}
const InputRangeStyled = styled.input<IsActive>`
    -webkit-appearance: none;
    appearance: none;
    background: #e5e5e5;
    outline: none;
    opacity: 1;
    width: 100%;
    box-sizing: border-box;
    height: 5px;
    border-radius: 5px;
    cursor: pointer;

    transition: all 100ms;

    :hover {
        opacity: 1;
        background: #e1eeeb;
    }

    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        border-radius: 1px;
        height: 20px;
        width: 5px;
        background: ${(props: IsActive) =>
            props.isActive ? '#5ec3ca' : '#e5e5e5'};

        cursor: pointer;

        :hover {
            background: #5ec3ca;
        }
    }

    ::-moz-range-thumb {
        border-radius: 1px;
        height: 20px;
        width: 5px;
        background: ${(props: IsActive) =>
            props.isActive ? '#5ec3ca' : '#e5e5e5'};

        cursor: pointer;
        :hover {
            background: #5ec3ca;
        }
    }
`

const Caption = styled.div`
    font-family: Lato;
    color: #666666;
    font-size: 12px;
    align-self: flex-start;
    padding-left: 5%;
`
const Row = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    position: relative;
`

const SearchField = styled.input`
    background-color: #ffffff;
    height: 50px;
    min-height: 50px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    padding-left: 15px;
    width: 100%;
    box-sizing: border-box;
`

const Label = styled.label<{ raise: boolean }>`
    position: absolute;
    ${(props) => `
        top: ${props.raise ? '0%' : '50%'};
        transform-origin: top left;
        transform: ${
            props.raise
                ? `translate(8px, -50%) scale(0.8)`
                : `translate(8px, -50%) scale(1)`
        };
        left: 0;
        min-width: ${props.raise ? 'auto' : '120px'};
    `}

    transition: 0.15s;
    background-color: var(--bg-color, #fff);
    color: #616161;
    font-size: 1.1em;
    padding: 0 8px;
    pointer-events: none;
    font-family: Lato;

    &:first-letter {
        text-transform: uppercase;
    }
`

const IconWrapper = styled.div`
    position: absolute;
    top: 16px;
    right: 20px;
`

const ShowroomFilterLocationDesktopBodyForm = () => {
    let filtersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.editingFilters
    })

    const dispatch = useAppDispatch()

    let input: any = document.getElementById(
        `showroom_location_filter_desktop_search`
    )

    const [typedVal, setTypedVal] = useState<string>(
        filtersState?.location?.displayValue
            ? filtersState?.location?.displayValue
            : ''
    )

    const gmaps2 = async () => {
        try {
            let autocomplete = new google.maps.places.Autocomplete(input, {
                types: ['postal_code', 'locality'],
                fields: ['formatted_address', 'geometry'],
            })

            autocomplete.setFields(['address_component'])

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    let geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }

                    let circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy,
                    })
                    // @ts-ignore
                    autocomplete.setBounds(circle.getBounds())
                })
            }
            autocomplete.addListener('place_changed', () => {
                let place: any = autocomplete?.getPlace()
                var lat = place?.geometry?.location?.lat()
                var lng = place?.geometry?.location?.lng()

                if (place.formatted_address) {
                    setTypedVal(place.formatted_address)
                }

                let location_values = {
                    values: {
                        lat: filtersState?.location?.values?.lat,
                        lng: filtersState?.location?.values?.lng,
                        distance: filtersState?.location?.values?.distance,
                    },
                    display_value: filtersState?.location?.displayValue,
                }

                if (lat !== undefined && lng !== undefined) {
                    if (lat !== !filtersState?.location?.values?.lat) {
                        location_values.values.lat = lat
                    }
                    if (lng !== !filtersState?.location?.values?.lng) {
                        location_values.values.lng = lng
                    }

                    if (
                        place.formatted_address !==
                        location_values.display_value
                    ) {
                        location_values.display_value = place.formatted_address
                    }
                    dispatch(
                        showroomActions.setLocation_filter(location_values)
                    )

                    setTypedVal(place.formatted_address)
                }
            })
        } catch (e) {
            console.error(e)
        } finally {
        }
    }

    const googleMaps = useMemo(async () => {
        await gmaps2()
    }, [input?.value])

    useEffect(() => {
        googleMaps
    }, [input?.value])

    const [isValFocus, setIsValFocus] = useState<boolean>(false)

    let isDistanceDisabled =
        filtersState?.location?.values?.lat === undefined ||
        filtersState?.location?.values?.lng === undefined
            ? true
            : false

    const locationError = useAppSelector(
        (state) => state.showroom.filterValidationError?.location
    )

    const [hasTyped, setHasTyped] = useState<boolean>(false)

    const validateLocation = async (value: number | undefined) => {
        try {
            await LocationFilterValidation.validate({
                min: value,
            })
            dispatch(
                showroomActions.setFilterValidationError({
                    id: 'location',
                    error: undefined,
                })
            )
            if (
                hasTyped &&
                (value === 0 || value === undefined) &&
                !isDistanceDisabled
            ) {
                dispatch(
                    showroomActions.setFilterValidationError({
                        id: 'location',
                        error: 'Warning - the minimum radius distance searched is 5+ miles.',
                    })
                )
            }
        } catch (err: any) {
            dispatch(
                showroomActions.setFilterValidationError({
                    id: 'location',
                    error: err.errors[0],
                })
            )
        }
    }

    useEffect(() => {
        if (
            filtersState.location.values &&
            (filtersState.location.values.lat ||
                filtersState.location.values.lng)
        ) {
            if (!filtersState.location.values.distance) {
                validateLocation(undefined)
            }
        }
    }, [filtersState.location.values])

    return (
        <ContentWrapper style={{ width: '100%' }}>
            <Row>
                <SearchField
                    id="showroom_location_filter_desktop_search"
                    type="text"
                    onChange={(e) => {
                        dispatch(
                            showroomActions.setLocation_filter({
                                values: undefined,
                                display_value: '',
                            })
                        )

                        setTypedVal(e.target.value)
                    }}
                    placeholder={'Postcode / City '}
                    value={typedVal}
                    autoFocus
                    onFocus={() => {
                        setIsValFocus(true)
                    }}
                    onBlur={() => {
                        setIsValFocus(false)
                    }}
                    style={{ width: '93%' }}
                />
                <Label raise={(typedVal?.length > 0 || isValFocus) && true}>
                    {'Postcode / City'}
                </Label>

                <IconWrapper>
                    {typedVal && typedVal.length > 0 ? (
                        <SearchIcon
                            height={20}
                            style={{ padding: '2px 0' }}
                            isActive={true}
                        />
                    ) : (
                        <SearchIcon
                            height={20}
                            style={{ padding: '2px 0' }}
                            isActive={false}
                        />
                    )}
                </IconWrapper>
            </Row>

            <div style={{ paddingTop: '20px' }} />

            <div style={{ width: '93%' }}>
                <InputFieldNoFormikAnimated
                    value={filtersState?.location?.values?.distance}
                    name="Distance"
                    placeholder="Distance"
                    onChange={(e: any) => {
                        setHasTyped(true)
                        validateLocation(
                            e.target.value === '' ? undefined : +e.target.value
                        )
                        dispatch(
                            showroomActions.setLocation_filter({
                                values: {
                                    lat: filtersState.location.values?.lat,
                                    lng: filtersState.location.values?.lng,
                                    distance: e.target.value
                                        ? +e.target.value
                                        : undefined,
                                },
                                display_value:
                                    filtersState.location.displayValue,
                            })
                        )
                    }}
                    disabled={isDistanceDisabled}
                    type="number"
                />
            </div>
            <Expander height={locationError?.error ? 'auto' : 0} width={'100%'}>
                <div style={{ paddingLeft: 10 }}>
                    <ErrorText>{locationError?.error}</ErrorText>
                </div>
            </Expander>
            <div style={{ paddingTop: '15px' }} />

            <Caption>Search radius in miles</Caption>
            <div style={{ paddingTop: '10px' }} />
            <div style={{ width: '93%' }}>
                <InputRangeStyled
                    type="range"
                    id="volume"
                    name="volume"
                    min="5"
                    max="100"
                    defaultValue={5}
                    value={filtersState?.location?.values?.distance}
                    isActive={
                        filtersState?.location?.values?.lat ? true : false
                    }
                    onChange={(e: any) => {
                        setHasTyped(true)
                        dispatch(
                            showroomActions.setLocation_filter({
                                values: {
                                    lat: filtersState.location.values?.lat,
                                    lng: filtersState.location.values?.lng,
                                    distance: e.target.value
                                        ? +e.target.value
                                        : undefined,
                                },
                                display_value:
                                    filtersState.location.displayValue,
                            })
                        )
                    }}
                    style={{
                        cursor: isDistanceDisabled ? 'not-allowed' : 'pointer',
                    }}
                    disabled={isDistanceDisabled}
                ></InputRangeStyled>
            </div>
        </ContentWrapper>
    )
}

export default ShowroomFilterLocationDesktopBodyForm
