import { IDropdownItem } from 'entityModels'
import Fuse from 'fuse.js'
import type { FuseResult } from 'fuse.js'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { showroomActions } from '../../../../redux/showroom/reducer'
import {
    IShowroomFiltersState,
    IFilterID,
} from '../../../../redux/showroom/types'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import NoDropdownDataBox from '../../../atoms/box/noDropdownDataBox'
import NoMatchFoundBox from '../../../atoms/box/noMatchFoundBox'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import { device } from '../../../templates/displays/devices'

const StandardInput = styled.input`
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    font-family: Lato;
    background-color: transparent !important;
    cursor: pointer;
    font-size: 15px;
    padding: 10px;
    border: 1px solid #b3b3b3;
    transition: 0.4s cubic-bezier(0.22, 1, 0.36, 1);
    border-radius: 5px;
    ::placeholder {
        font-family: Lato-Light;
        color: #747474;
        font-size: 16px;
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
    }

    @media ${device.desktop} {
    }

    @media ${device.ipad} {
    }
`

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
`

type Props = {
    placeholder?: string
    customIsCheckedFunction?: (id: string) => boolean
}
const ShowroomFilterSearchDropdownDesktop = (props: Props) => {
    const dispatch = useAppDispatch()

    let filtersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.editingFilters
    })

    let isSearchLoading: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isSearchLoading
    })
    let openedFilterID: IFilterID | undefined = useAppSelector((state) => {
        return state.showroom.openedFilterID
    })

    const [valueTyped, setValueTyped] = useState<string>('')

    const [localOptions, setLocalOptions] = useState<IDropdownItem[]>([])

    let all_options: IDropdownItem[] | undefined =
        openedFilterID &&
        // @ts-ignore
        filtersState[openedFilterID].options

    const location = useLocation()

    useEffect(() => {
        if (
            openedFilterID === 'make' &&
            (!all_options || filtersState.make.isActive === true)
        ) {
            dispatch(
                showroomActions.searchShowroomRequest({
                    states: location.pathname.split('/').includes('sold')
                        ? 'SOLD'
                        : 'PUBLISHED',
                    only_for_filter_makes_results: true,
                })
            )
        }
    }, [])

    useEffect(() => {
        if (all_options) {
            // @ts-ignore
            setLocalOptions(all_options)
        }
    }, [all_options])

    const FuseOptions = {
        includeScore: true,
        keys: ['name'],
    }

    const fuse_data: Fuse<IDropdownItem> | undefined =
        openedFilterID &&
        // @ts-ignore
        filtersState[openedFilterID].options &&
        new Fuse<IDropdownItem>(
            // @ts-ignore
            filtersState[openedFilterID].options,
            FuseOptions
        )

    const onSearch = (val: string) => {
        setValueTyped(val)
        const fuse_results: FuseResult<IDropdownItem>[] =
            fuse_data === undefined
                ? []
                : fuse_data.search(val, {
                      limit: 5,
                  })

        let res: IDropdownItem[] = []

        fuse_results.forEach((elem: FuseResult<IDropdownItem>) => {
            if (
                elem.item &&
                elem.score &&
                elem.score < 0.45
                // &&
                // !activeCategories.includes(elem.item.uid)
            ) {
                res = [...res, elem.item]
            }
        })

        setLocalOptions(res)

        return
    }

    let optionsToRender: IDropdownItem[] | undefined =
        localOptions && localOptions.length > 0
            ? localOptions
            : valueTyped && localOptions.length === 0
            ? []
            : all_options && all_options.length > 0
            ? all_options
            : undefined

    return (
        <Wrapper>
            {!isSearchLoading && !optionsToRender && (
                <NoDropdownDataBox
                    name={
                        openedFilterID && filtersState[openedFilterID].name
                            ? `${filtersState[
                                  openedFilterID
                              ].name?.toLowerCase()}`
                            : 'item'
                    }
                />
            )}

            {optionsToRender !== undefined && (
                <StandardInput
                    disabled={false}
                    id={'showroom_filter_desktop_search'}
                    name={'showroom_filter_desktop_search'}
                    placeholder={
                        props.placeholder ? props.placeholder : 'Search'
                    }
                    tabIndex={1}
                    aria-live="polite"
                    value={valueTyped}
                    onChange={(e: any) => {
                        onSearch(e.target.value)
                    }}
                    data-attr={'showroom_filter_desktop_search'}
                />
            )}

            <div
                style={{
                    paddingTop:
                        !isSearchLoading &&
                        optionsToRender &&
                        optionsToRender.length === 0
                            ? '15px'
                            : '10px',
                }}
            />

            {!isSearchLoading &&
                optionsToRender &&
                optionsToRender.length === 0 && <NoMatchFoundBox />}

            {optionsToRender !== undefined &&
                optionsToRender?.length > 0 &&
                optionsToRender.map((item: IDropdownItem, index: number) => {
                    let isChecked: boolean = props.customIsCheckedFunction
                        ? props.customIsCheckedFunction(item.uid) // @ts-ignore
                        : filtersState[ // @ts-ignore
                              openedFilterID
                              // @ts-ignore
                          ]?.values
                              ?.indexOf(item.uid) === -1
                        ? false
                        : true
                    return (
                        <CustomAnimatedCheckboxField
                            name={item.name}
                            id={item.uid}
                            checked={isChecked}
                            onChange={() => {
                                dispatch(
                                    showroomActions.setMultiSelect_filter({
                                        // @ts-ignore
                                        id: openedFilterID,
                                        value: item.uid,
                                    })
                                )
                            }}
                            rowHeight={'36px'}
                            extra_small
                            hasWhiteBg
                        >
                            <div style={{ paddingLeft: '15px' }}>
                                {item.name}
                            </div>
                        </CustomAnimatedCheckboxField>
                    )
                })}
        </Wrapper>
    )
}

export default ShowroomFilterSearchDropdownDesktop
