import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Button from '../Button/ButtonAtom'
import './styles.css'
import skeleton from '../skeleton/description/skeleton.jpg'
import NoDataRectangle from '../noData/noDataRectangle'
import ButtonAtom from '../Button/ButtonAtom'
import parse from 'html-react-parser'
import Expander from '../expander/expander'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const Wrapper = styled.div<IStyle>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-self: start;
    justify-self: start;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-self: start;
    justify-self: start;
`
const TextContainer = styled.div<{ $theme: ITheme }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-self: start;
    justify-self: start;
    overflow: hidden;
    color: ${(props) => colours[props.$theme].text_default};

    p,
    li,
    h1,
    h2,
    h3 {
        color: ${(props) => colours[props.$theme].text_default};
    }
`
const ReadMore = styled.div<IStyle>`
    z-index: 2;
    padding-top: ${(props) => (props.isOpen ? '16px' : '0px')};
    padding-left: 0px;
    @media ${device.beyond_ipad_mobile} {
        padding-left: 5px;
    }
    @media ${device.mobile_and_ipad} {
        transform: translate(-4px, -4px);
    }
`

const EmptyBtnSkeletonOverlay = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

const Reveal = styled.div<{ isOpen: boolean; $theme: ITheme }>`
    width: 100%;
    box-sizing: border-box;
    height: 30px;
    background: ${(props) =>
        `linear-gradient(180deg, rgba(255, 255, 255, 0), ${
            colours[props.$theme].top_feading_overlay_light
        })`};
    transform: translateY(-20px);
    opacity: ${(props) => (props.isOpen ? '0' : '1')};
    transition: all 300ms;
`

interface Props {
    description: string
    onClick?: any
    readOnlyMode?: boolean
}

interface IStyle {
    hasContent?: boolean
    isOpen?: boolean
}

export default function DescriptionMobile(props: Props) {
    const [isOpen, setIsOpen] = React.useState(false)

    const toggleReveal = () => {
        setIsOpen(!isOpen)
    }

    const { description, onClick, readOnlyMode } = props
    let isDescriptionShort: boolean =
        description.length > 1 && description.length < 800 ? true : false

    let expanderHeight =
        isDescriptionShort === true ? 'auto' : isOpen ? 'auto' : 120

    const { theme } = useThemes()

    const isEmpty =
        description.length < 1 || description === '<p><br></p>' ? true : false

    return (
        <Container
            style={{
                height: theme === 'dark' && isEmpty ? 160 : 'auto',
                backgroundColor:
                    readOnlyMode && isEmpty
                        ? 'transparent'
                        : theme === 'dark' && isEmpty
                        ? colours[theme].background_neutral_subtle
                        : 'transparent',
            }}
        >
            {/* IF NO DESCRIPTION */}
            {isEmpty &&
                // FOR USER'S OWN CAR (NO READ ONLY) WE DISPLAY ADD BTN
                (!readOnlyMode ? (
                    <div
                        onClick={() => onClick()}
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '100%',
                        }}
                    >
                        {theme === 'light' ? (
                            <img
                                alt="placeholder"
                                src={skeleton}
                                style={{ height: '184px', width: '100%' }}
                            />
                        ) : null}

                        <EmptyBtnSkeletonOverlay>
                            <ButtonAtom
                                width={'200px'}
                                theme={'lowercase-blue-background'}
                                fontSize={14}
                                onClick={() => {}}
                            >
                                <div
                                    style={{
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Add car’s description
                                </div>
                            </ButtonAtom>
                        </EmptyBtnSkeletonOverlay>
                    </div>
                ) : (
                    // FOR EXTERNAL CAR (READ ONLY MODE) WE DISPLAY NO DATA SQUARE
                    <NoDataRectangle text="no description" />
                ))}

            {!isEmpty && (
                <Expander height={expanderHeight}>
                    <Wrapper>
                        <TextContainer $theme={theme}>
                            {parse(description)}
                        </TextContainer>
                    </Wrapper>
                </Expander>
            )}
            {!isEmpty && (
                <Reveal
                    $theme={theme}
                    isOpen={expanderHeight === 120 ? false : true}
                />
            )}
            {!isDescriptionShort && !isEmpty && (
                <>
                    <ReadMore isOpen={isOpen}>
                        <Button
                            onClick={(e: any) => {
                                e.preventDefault()
                                toggleReveal()
                            }}
                            theme="naked-text"
                            fontSize={16}
                        >
                            {isOpen ? 'Show less' : 'Show more'}
                        </Button>
                    </ReadMore>
                </>
            )}
        </Container>
    )
}
