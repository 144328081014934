import React, { useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import NoDataRectangle from '../../../atoms/noData/noDataRectangle'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import CarOwnerBox from '../../../atoms/user/carOwnerBox'
import CardMotionDivWrapper from '../../../templates/animated/cardMotionDivWrapper'
import { device } from '../../../templates/displays/devices'
import {
    IPrivateShareReceivedItem,
    IPrivateShareReceivedItemApprovalPayload,
} from '../../../../redux/entities/cars/types'

interface Props {
    share_received: IPrivateShareReceivedItem
    hasMarginRight?: boolean
    shareApprovalAction?: (
        payload: IPrivateShareReceivedItemApprovalPayload
    ) => void
}

interface IStyle {
    hasMarginRight?: boolean
    $hasApprovalBtns?: boolean
    $allowClick?: boolean
}

const ShadowBox = styled(Link)<IStyle>`
    box-sizing: border-box;
    @media ${device.desktop} {
    }
    @media (min-width: 1241px) and (max-width: 1310px) {
        margin-right: ${(props) => (props.hasMarginRight ? '20px' : '0px')};
    }
    @media (min-width: 1311px) and (max-width: 1450px) {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: ${(props) => (props.hasMarginRight ? '30px' : '0px')};
    }
    @media ${device.large_desktop} {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: ${(props) => (props.hasMarginRight ? '30px' : '0px')};

        width: 540px;
    }
    @media ${device.ultrawide_desktop} {
    }
    height: ${(props) => (props.$hasApprovalBtns ? 'auto' : '150px')};
    margin-top: 10px;
    margin-bottom: 10px;
    width: 500px;
    margin-right: ${(props) => (props.hasMarginRight ? '20px' : '0px')};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: ${(props) =>
        props.$hasApprovalBtns ? 'flex-start' : 'center'};
    ${(props) => props.$hasApprovalBtns && 'padding: 25px;'};
    font-family: Lato;
    border: transparent;
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    background-color: var(--bg-color, #fff);
    transition: all 100ms;
    ${(props) =>
        props.$allowClick &&
        ` :hover {
        background-color: var(--primary_04) !important;
        border: 1px solid var(--primary_08);
        transition: all 100ms;
    }`}
`

const CarProfileImage = styled.img<IStyle>`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
    ${(props) =>
        props.$hasApprovalBtns &&
        `border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;`};
`

const CarImgWrapper = styled.div<IStyle>`
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    ${(props) =>
        props.$hasApprovalBtns &&
        `border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;`};
    width: 200px;
    height: ${(props) => (props.$hasApprovalBtns ? '125px' : '100%')};
    object-fit: cover;

    @media ${device.desktop} {
    }

    @media ${device.large_desktop} {
    }

    @media ${device.smallest_laptops} {
        width: 140px;
    }
`

const CarTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 5px;
    @media ${device.desktop} {
        font-size: 16px;
        line-height: 20px;
        font-family: 'Lato-Bold';
    }
    @media ${device.large_desktop} {
        font-size: 17px;
        line-height: 20px;
        font-family: 'Lato';
    }
    @media ${device.smallest_laptops} {
        font-family: 'Lato-Bold';
        font-size: 15px;
        line-height: 20px;
        overflow: hidden;
    }
`

const HozLine = styled.div`
    background-color: var(--border_muted_subtle);
    height: 1px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 12px;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 230px;
    width: 100%;
    box-sizing: border-box;
    @media ${device.smallest_laptops} {
        padding-left: 160px;
    }
`

const SharedOn = styled.div`
    font-family: Lato-light;
    font-size: 11px;
    font-style: italic;
    color: var(--text-default, #666666);
    @media ${device.mobile_and_ipad} {
        font-size: 12px;
    }
`

const FlexRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
`

const SmallPaddingH = styled.div`
    padding-right: 16px;
`
const SmallPaddingV = styled.div`
    padding-top: 30px;
`
const SmallPaddingV2 = styled.div`
    padding-top: 5px;
`

const CarsSharedWithYouCardDesktop: React.FC<Props> = ({
    share_received,
    hasMarginRight,
    shareApprovalAction,
}) => {
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()

    const imageSrc = share_received?.car_profile_pic?.url

    const hasApprovalBts =
        share_received.approvalStatus === 'pending' ||
        share_received.approvalStatus === 'ignored'

    const params = new URLSearchParams(location.search)
    const view_id = params.get('view_id')

    return (
        <CardMotionDivWrapper>
            {share_received && (
                <ShadowBox
                    to={
                        share_received.approvalStatus === 'accepted'
                            ? `/shared/car/${share_received.entity_id}`
                            : view_id
                            ? `/sharing/with-you?view_id=${view_id}`
                            : `/sharing/with-you`
                    }
                    rel="noreferrer"
                    hasMarginRight={hasMarginRight}
                    $hasApprovalBtns={hasApprovalBts}
                    $allowClick={share_received.approvalStatus === 'accepted'}
                >
                    <>
                        <CarImgWrapper $hasApprovalBtns={hasApprovalBts}>
                            {share_received.car_profile_pic ? (
                                <>
                                    <SkeletonAbsolute
                                        isthingloading={isLoading}
                                    />
                                    <CarProfileImage
                                        src={imageSrc}
                                        aria-label="shared car profile image"
                                        $hasApprovalBtns={hasApprovalBts}
                                        onLoad={() => setIsLoading(false)}
                                    />
                                </>
                            ) : (
                                <NoDataRectangle
                                    text={share_received.car_title}
                                    height="100%"
                                />
                            )}
                        </CarImgWrapper>
                        <Col>
                            <div
                                style={{
                                    height: '60px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <CarTitle>
                                    {share_received.car_title
                                        ? share_received.car_title
                                        : 'Untitled car'}
                                </CarTitle>
                                <SharedOn>
                                    shared on {share_received.created_at}
                                </SharedOn>
                            </div>
                            <HozLine />
                            <CarOwnerBox owner={share_received.owner} />
                        </Col>
                    </>
                    {hasApprovalBts && (
                        <>
                            <SmallPaddingV />
                            <FlexRow>
                                <ButtonAtom
                                    theme="secondary"
                                    width="100%"
                                    height="40px"
                                    textTransform="capitalize"
                                    fontSize={14}
                                    onClick={() => {
                                        if (shareApprovalAction) {
                                            shareApprovalAction({
                                                shareId: share_received.id,
                                                recipientId:
                                                    share_received.owner.uid,
                                                approvalId: 'accepted',
                                            })
                                            setTimeout(
                                                () =>
                                                    navigate(
                                                        `/sharing/with-you?entity_id=cars&view_id=view_all`
                                                    ),
                                                50
                                            )
                                        }
                                    }}
                                >
                                    Accept
                                </ButtonAtom>
                                <SmallPaddingH />
                                {share_received.approvalStatus !==
                                    'ignored' && (
                                    <>
                                        <ButtonAtom
                                            theme="tertiary"
                                            width="100%"
                                            height="40px"
                                            textTransform="capitalize"
                                            fontSize={14}
                                            onClick={() =>
                                                shareApprovalAction
                                                    ? shareApprovalAction({
                                                          shareId:
                                                              share_received.id,
                                                          recipientId:
                                                              share_received
                                                                  .owner.uid,
                                                          approvalId: 'ignored',
                                                      })
                                                    : {}
                                            }
                                        >
                                            Ignore
                                        </ButtonAtom>
                                        <SmallPaddingH />
                                    </>
                                )}

                                <ButtonAtom
                                    theme="lowercase-white-red-background"
                                    width="100%"
                                    height="40px"
                                    fontSize={14}
                                    noIpadMinWith
                                    onClick={() =>
                                        shareApprovalAction
                                            ? shareApprovalAction({
                                                  shareId: share_received.id,
                                                  recipientId:
                                                      share_received.owner.uid,
                                                  approvalId: 'rejected',
                                              })
                                            : {}
                                    }
                                >
                                    Decline
                                </ButtonAtom>
                            </FlexRow>
                            <SmallPaddingV2 />
                        </>
                    )}
                </ShadowBox>
            )}
        </CardMotionDivWrapper>
    )
}

export default CarsSharedWithYouCardDesktop
