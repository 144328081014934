import { IDropdownItem } from 'entityModels'
import Fuse from 'fuse.js'
import type { FuseResult } from 'fuse.js'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { showroomActions } from '../../../../redux/showroom/reducer'
import {
    IShowroomFiltersState,
    IFilterID,
} from '../../../../redux/showroom/types'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import NoDropdownDataBox from '../../../atoms/box/noDropdownDataBox'
import NoMatchFoundBox from '../../../atoms/box/noMatchFoundBox'
import CheckboxRowItem from '../../../atoms/formField/checkboxRowItem'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
`

const Caption = styled.div`
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Lato;
    color: #1a1a1a;
`

type Props = {
    placeholder?: string
    customIsCheckedFunction?: (id: string) => boolean
    customOnChangeFunction?: (id: string) => void
    name: string
    type?: 'single' | 'multi'
}
type IStyled = {
    isChecked?: boolean
}

const OptionWrap = styled.div<IStyled>`
    width: 100%;
    box-sizing: border-box;
    height: 48px;
    padding-left: 20px;
    padding-right: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    background-color: ${(props: IStyled) =>
        props.isChecked ? '#e5fdfd' : '#ffffff'};
    color: #666666;
    font-size: 16px;
    font-family: Lato;

    transition: all 100ms;

    cursor: pointer;
    :hover {
        background-color: #e5fdfd;
    }
`

const ShowroomFilterSearchDropdownMobile = (props: Props) => {
    const dispatch = useAppDispatch()

    let editingFiltersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.editingFilters
    })
    let openedFilterID: IFilterID | undefined = useAppSelector((state) => {
        return state.showroom.openedFilterID
    })

    const [valueTyped, setValueTyped] = useState<string>('')

    const [localOptions, setLocalOptions] = useState<IDropdownItem[]>([])

    let all_options: IDropdownItem[] | undefined =
        openedFilterID &&
        // @ts-ignore
        editingFiltersState[openedFilterID].options

    useEffect(() => {
        if (all_options) {
            // @ts-ignore
            setLocalOptions(all_options)
        }
    }, [all_options])

    const FuseOptions = {
        includeScore: true,
        keys: ['name'],
    }

    const fuse_data =
        openedFilterID &&
        // @ts-ignore
        editingFiltersState[openedFilterID].options &&
        new Fuse(
            // @ts-ignore
            editingFiltersState[openedFilterID].options,
            FuseOptions
        )

    const onSearch = (val: string) => {
        setValueTyped(val)
        const fuse_results: FuseResult<IDropdownItem>[] = fuse_data.search(
            val,
            {
                limit: 5,
            }
        )

        let res: IDropdownItem[] = []

        fuse_results.forEach((elem: FuseResult<IDropdownItem>) => {
            if (
                elem.item &&
                elem.score &&
                elem.score < 0.45
                // &&
                // !activeCategories.includes(elem.item.uid)
            ) {
                res = [...res, elem.item]
            }
        })

        setLocalOptions(res)

        return
    }

    let optionsToRender: IDropdownItem[] | undefined =
        localOptions && localOptions.length > 0
            ? localOptions
            : valueTyped && localOptions.length === 0
            ? []
            : all_options && all_options.length > 0
            ? all_options
            : undefined
    let isSearchLoading: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isSearchLoading
    })
    return (
        <Wrapper>
            {!isSearchLoading && !optionsToRender && (
                <NoDropdownDataBox name={props.name.toLowerCase()} />
            )}

            {optionsToRender !== undefined && (
                <InputFieldNoFormikAnimated
                    disabled={false}
                    id={'showroom_filter_desktop_search'}
                    name={props.name}
                    placeholder={
                        props.placeholder ? props.placeholder : 'Search'
                    }
                    // tabIndex={1}
                    aria-live="polite"
                    value={valueTyped}
                    onChange={(e: any) => {
                        onSearch(e.target.value)
                    }}
                    data-attr={'showroom_filter_desktop_search'}
                    placeholderStyle={'text-transform: none;'}
                />
            )}

            <div style={{ paddingTop: '30px' }} />

            {!isSearchLoading &&
                optionsToRender &&
                optionsToRender.length === 0 && <NoMatchFoundBox />}

            {optionsToRender && optionsToRender.length > 0 && (
                <Caption style={{ fontFamily: 'Lato-Bold' }}>
                    Available{' '}
                    {openedFilterID &&
                        editingFiltersState[openedFilterID].name?.toLowerCase()}
                    s
                </Caption>
            )}
            <div style={{ paddingTop: '10px' }} />
            {optionsToRender !== undefined &&
                optionsToRender?.length > 0 &&
                optionsToRender.map((item: IDropdownItem, index: number) => {
                    let isChecked: boolean = props.customIsCheckedFunction
                        ? props.customIsCheckedFunction(
                              item.uid
                                  ? item.uid
                                  : // @ts-ignore
                                  item.id
                                  ? // @ts-ignore
                                    item.id
                                  : undefined
                          )
                        : editingFiltersState[ // @ts-ignore
                              openedFilterID
                              // @ts-ignore
                          ]?.values
                              ?.indexOf(item.uid) === -1
                        ? false
                        : true

                    if (props.type === 'single') {
                        return (
                            <OptionWrap
                                key={item.uid}
                                role="button"
                                onClick={() => {
                                    if (props.customOnChangeFunction) {
                                        props.customOnChangeFunction(
                                            item.uid
                                                ? item.uid
                                                : // @ts-ignore
                                                item.id
                                                ? // @ts-ignore
                                                  item.id
                                                : 'undefined'
                                        )
                                    } else if (!props.customOnChangeFunction) {
                                        dispatch(
                                            showroomActions.setMultiSelect_filter(
                                                {
                                                    // @ts-ignore
                                                    id: openedFilterID,
                                                    value: item.uid
                                                        ? item.uid
                                                        : // @ts-ignore
                                                        item.id
                                                        ? // @ts-ignore
                                                          item.id
                                                        : undefined,
                                                }
                                            )
                                        )
                                    }
                                }}
                                isChecked={isChecked}
                            >
                                {item.name}
                            </OptionWrap>
                        )
                    } else
                        return (
                            <CheckboxRowItem
                                name={item.uid}
                                text={item.name}
                                onChange={() => {
                                    if (!props.customOnChangeFunction) {
                                        dispatch(
                                            showroomActions.setMultiSelect_filter(
                                                {
                                                    // @ts-ignore
                                                    id: openedFilterID,
                                                    value: item.uid
                                                        ? item.uid
                                                        : // @ts-ignore
                                                        item.id
                                                        ? // @ts-ignore
                                                          item.id
                                                        : undefined,
                                                }
                                            )
                                        )
                                    } else {
                                        props.customOnChangeFunction(
                                            item.uid
                                                ? item.uid
                                                : // @ts-ignore
                                                item.id
                                                ? // @ts-ignore
                                                  item.id
                                                : undefined
                                        )
                                    }
                                }}
                                checked={isChecked}
                            />
                        )
                })}
        </Wrapper>
    )
}

export default ShowroomFilterSearchDropdownMobile
