import { IApexCarOfTheWeekState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import LoaderBackground from '../../../../atoms/loader/loaderBackground'
import { device } from '../../../../templates/displays/devices'
import external_link_green from '../../../../../public/assets/icons/external_link_green.svg'

const WrapperofWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled.div`
    background: var(--bg-color, #fff);
    position: relative;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 80px;

    @media ${device.large_desktop} {
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
        margin-top: 100px;
        padding: 100px !important;
    }

    @media ${device.ipad} {
        padding-left: 0px;
        padding-right: 0px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 680px;
    }
`

const ImgStyle = styled.div`
    height: 500px;
    width: 100%;
    box-sizing: border-box;

    @media ${device.smallest_laptops} {
        height: 500px;
        width: 74vw;
        min-width: 580px;
    }

    @media ${device.large_desktop} {
        height: 60vh;
        mwx-height: 800px;
        max-width: 2000px;
    }
    @media ${device.ipad} {
        height: 388px;
        width: 100%;
        box-sizing: border-box;
        min-width: 100%;
        box-sizing: border-box;
    }
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex-direction: column;
    border-radius: 10px;
`

const Title = styled.div`
    font-family: Lato-light;
    color: var(--text-darker, #616161);
    align-self: flex-start !important;
    padding-bottom: 20px;

    @media ${device.desktop} {
        font-size: 21px;
    }

    @media ${device.smallest_laptops} {
        font-size: 20px;
    }

    @media ${device.large_desktop} {
        font-size: 22px;
    }
`

const Name = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    align-self: flex-start !important;
    padding-top: 40px;

    @media ${device.desktop} {
        font-size: 21px;
    }

    @media ${device.smallest_laptops} {
        font-size: 20px;
    }

    @media ${device.large_desktop} {
        font-size: 22px;
    }
`

const Text = styled.div`
    line-height: 28px;
    font-family: Lato-Light;
    color: var(--text-strong, #1a1a1a);
    align-self: flex-start !important;

    @media ${device.desktop} {
        font-size: 17px;
        width: 78vw;
        min-width: 1100px;
    }

    @media ${device.smallest_laptops} {
        font-size: 17px;
        width: 74vw;
        min-width: 580px;
    }

    @media ${device.large_desktop} {
        font-size: 19px;
        width: 60vw;
        max-width: 1800px;
    }

    @media ${device.ipad} {
        width: 100%;
        box-sizing: border-box;
        max-width: 100%;
        box-sizing: border-box;
    }

    @media screen and (min-width: 1600px) and (max-width: 1800px) {
        min-width: 900px;
    }
`

const ReadMoreText = styled.a`
    align-self: flex-start !important;
    color: var(--primary, #5ec3ca) !important;
    font-family: Lato;
    padding-top: 35px;
    text-transform: uppercase;

    @media ${device.desktop} {
        font-size: 16px;
    }

    @media ${device.smallest_laptops} {
        font-size: 15px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }
`

const ExternalLinkIcon = styled.img`
    width: 12px;
    height: 12px;
    margin-left: 11px;
    transform: translateY(-2px);
`

type Props = {
    car: IApexCarOfTheWeekState | undefined
}

const ApexCarOfTheWeekHomepage: React.FC<Props> = ({ car }) => {
    return (
        <WrapperofWrapper>
            <Wrapper>
                {!car && <LoaderBackground height={'500px'} width={'100%'} />}
                {car && (
                    <>
                        <ImgStyle
                            role="img"
                            aria-label="fullwidth-picture"
                            style={{
                                backgroundImage: `url(${
                                    car.img ? car.img.url : ''
                                })`,
                            }}
                        />

                        <Name>{car.name}</Name>
                        <Title>{car.title}</Title>
                        <Text>{car.description}</Text>
                        <ReadMoreText target="_blank" href={`${car.link}`}>
                            Take a closer look
                            <ExternalLinkIcon src={external_link_green} />
                        </ReadMoreText>
                    </>
                )}
            </Wrapper>
        </WrapperofWrapper>
    )
}

export default ApexCarOfTheWeekHomepage
