import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { menuActions } from '../../../../redux/menus/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import React from 'react'
import InsuranceApplicationCardDesktop from '../../../molecules/cards/insurance/insuranceApplicationCardDesktop'
import InsuranceTopBarWithProgressionRate from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import InsuranceGoBackReusableBottomBar from '../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceApplicationCardContentManager from '../../../organisms/InsuranceApplicationCardContentManager/InsuranceApplicationCardContentManager'
import {
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Car,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import InsuranceApplicationCardMobile from '../../../molecules/cards/insurance/insuranceApplicationCardMobile'
import InsuranceTopBarWithProgressionRateMobile from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../redux/store'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import { IInsuranceApplicationSectionCard } from './insuranceApplicationDashboard'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

export type CarParams = {
    carid: string
}

const InsuranceApplicationCarDashboard = () => {
    const navigate = useNavigate()

    const { carid } = useParams<CarParams>()

    let vehicles = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data.vehicles
    )

    let current =
        vehicles && carid && vehicles[carid] ? vehicles[carid] : undefined

    let vehicleToInsure: IInsuranceQuoteApplication_Car | undefined =
        vehicles && carid && vehicles[carid] ? vehicles[carid] : undefined

    let tech_info_stats: number = vehicleToInsure?.tech_info_stats ?? 0
    let mileage_and_parking_stats: number =
        vehicleToInsure?.mileage_parking_stats ?? 0
    let ownership_stats: number = vehicleToInsure?.ownership_stats ?? 0

    let everydayCarIds = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.submitted_data.everyday_car_ids
    )

    let insuredCarIds = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.submitted_data
                .vehicles_to_insure_ids_list
    )

    let isEverydayCar = everydayCarIds?.includes(`${carid}`) ? true : false

    let isEverydayCarAndNotInsurable =
        everydayCarIds?.includes(`${carid}`) &&
        !insuredCarIds.includes(`${carid}`)
            ? true
            : false

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    const car_cards: IInsuranceApplicationSectionCard[] = [
        {
            id: 'tech_info',
            sectionID: 'vehicles_to_insure',
            title: 'Technical Specifications',
            text: `Provide the technical details of your car, such as its make, model, engine size, and fuel type.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [tech_info_stats],
            onClick: () => {
                return navigate(
                    `/insurance/application/${id}/${
                        isEverydayCarAndNotInsurable ? 'everyday_car' : 'car'
                    }/${carid}/tech_info`
                )
            },
        },
        {
            id: 'is_everyday_car',
            sectionID: 'vehicles_to_insure',
            title: `Everyday car`,
            text: ``,
            isDisabled: false,
            btnTxt: '',
            numberOfProgressBars: 0,
            arrayOfProgressBarsValues: [],
            removeBtn: true,
            hasTooltip: isMobile ? false : true,
            tooltipTxt:
                'Please note that we do not provide coverage for typical everyday cars. Our team will get back to you with more information regarding the eligibility of your vehicle.',
            isToggled: isEverydayCar,
            onToggle: (v: boolean) => {
                dispatch(
                    insuranceActions.submit_set_everyday_car_id_request({
                        car_id: `${carid}`,
                        answer: v,
                    })
                )
            },
        },
        {
            id: 'mileage_parking',
            sectionID: 'vehicles_to_insure',
            title: `Mileage & Parking`,
            text: `These details help us ensure accurate communication and assess the policyholder's risk profile, allowing us to tailor the coverage accordingly.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [mileage_and_parking_stats],
            onClick: () => {
                return navigate(
                    `/insurance/application/${id}/${
                        isEverydayCarAndNotInsurable ? 'everyday_car' : 'car'
                    }/${carid}/mileage_parking`
                )
            },
        },
        {
            id: 'ownership',
            sectionID: 'vehicles_to_insure',
            title: `Ownership`,
            text: `Confirm your ownership status for the car, whether you own it outright, it's on finance, or leased.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [ownership_stats],
            onClick: () => {
                return navigate(
                    `/insurance/application/${id}/${
                        isEverydayCarAndNotInsurable ? 'everyday_car' : 'car'
                    }/${carid}/ownership`
                )
            },
        },
        // {
        //     id: 'adds_on',
        //     title: `Add-ons`,
        //     text: `Capture information about any additional drivers who will be covered by the policy. This helps us assess the overall risk and ensure appropriate coverage for all drivers involved.`,
        //     isDisabled: false,
        //     btnTxt: `Add details`,
        //     numberOfProgressBars: 0,
        //     arrayOfProgressBarsValues: [],
        //     onClick: () => {
        //         return navigate(`/insurance/application/${id}/adds_on`)
        //     },
        // },
    ]
    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const dispatch = useAppDispatch()

    const { id } = useParams<{ id?: string }>()

    let {
        setInitialInsurance_CarTechInfo,
        setInitialCarTechnicalDropdowns,
        getUserAndApplicationData,
        getFirstIncompleteSubsection_Single_InsuredVehicle,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    useEffect(() => {
        setCrossFormData(undefined)
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    useEffect(() => {
        if (userLoggedIn && carid) {
            setInitialCarTechnicalDropdowns(carid)
            setInitialInsurance_CarTechInfo(carid)
        }
    }, [current, userLoggedIn])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const generateTitle = () => {
        if (
            current &&
            (current.tech_info?.manufacture_year ||
                current.tech_info?.make?.name ||
                current.tech_info?.model?.name)
        ) {
            return `${
                current.tech_info?.manufacture_year
                    ? `${current.tech_info.manufacture_year} `
                    : ''
            }${
                current.tech_info?.make?.name
                    ? `${current.tech_info.make.name} `
                    : ''
            }${
                current.tech_info?.model?.name
                    ? current.tech_info.model.name
                    : ''
            }`
        } else {
            return 'Untitled car'
        }
    }

    let getToAny = () => {
        let ids2 = getInitialSectionWizardIds()

        if (ids2) {
            let url1 = `/insurance/application/${id}/wizard?section_id=${ids2.sectionID}`
            if (ids2.sub_sectionID) {
                url1 = url1 + `&subsection_id=${ids2.sub_sectionID}`
            }
            if (ids2.car_id) {
                url1 = url1 + `&car_id=${ids2.car_id}`
            }
            if (ids2.driver_id) {
                url1 = url1 + `&driver_id=${ids2.driver_id}`
            }
            return navigate(url1)
        } else {
            if (ids2 === null) {
                return
                // navigate(
                //     `/insurance/application/${applicationData?.id}`
                // )
            }
        }
    }

    let goToWizard = () => {
        let ids = getFirstIncompleteSubsection_Single_InsuredVehicle(`${carid}`)

        if (ids) {
            let url1 = `/insurance/application/${id}/wizard?section_id=${ids.sectionID}`
            if (ids.sub_sectionID) {
                url1 = url1 + `&subsection_id=${ids.sub_sectionID}`
            }
            if (ids.car_id) {
                url1 = url1 + `&car_id=${ids.car_id}`
            }
            if (ids.driver_id) {
                url1 = url1 + `&driver_id=${ids.driver_id}`
            }
            return navigate(url1)
        } else {
            return getToAny()
        }
    }

    const { theme } = useThemes()

    let isDraft = applicationData.status === 'DRAFT' ? true : false
    return userLoggedIn ? (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    wizard_click_play={isDraft ? goToWizard : undefined}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                navigate(`/insurance/application/${id}`),
                        },
                        { pageName: generateTitle(), isCaps: true },
                    ]}
                    progressionRate={
                        applicationData?.vehicles?.[`${carid}`]?.completion
                    }
                    sectionId={'vehicles_to_insure'}
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        boxSizing: 'border-box',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    {car_cards.map((card: IInsuranceApplicationSectionCard) => {
                        return (
                            <React.Fragment key={card.id}>
                                <InsuranceApplicationCardDesktop
                                    cardProps={card}
                                >
                                    {current && (
                                        <InsuranceApplicationCardContentManager
                                            card={card}
                                            carDashboardID={carid}
                                            applicationID={id}
                                        />
                                    )}
                                </InsuranceApplicationCardDesktop>
                            </React.Fragment>
                        )
                    })}
                </div>
                <InsuranceGoBackReusableBottomBar
                    goBack={() => navigate(`/insurance/application/${id}`)}
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <InsuranceTopBarWithProgressionRateMobile
                    wizard_click_play={isDraft ? goToWizard : undefined}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                navigate(`/insurance/application/${id}`),
                        },
                        { pageName: generateTitle(), isCaps: true },
                    ]}
                    progressionRate={
                        applicationData?.vehicles?.[`${carid}`]?.completion
                    }
                    sectionId={'vehicles_to_insure'}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        boxSizing: 'border-box',
                        paddingTop: 120,
                        paddingBottom: 120,
                        paddingLeft: 16,
                        paddingRight: 16,
                    }}
                >
                    {car_cards.map((card: IInsuranceApplicationSectionCard) => {
                        return (
                            <React.Fragment key={card.id}>
                                <InsuranceApplicationCardMobile
                                    cardProps={card}
                                >
                                    {current && (
                                        <InsuranceApplicationCardContentManager
                                            card={card}
                                            carDashboardID={carid}
                                            isMobile
                                            applicationID={id}
                                        />
                                    )}
                                </InsuranceApplicationCardMobile>
                            </React.Fragment>
                        )
                    })}
                </div>
                <InsuranceGoBackReusableBottomBar
                    isMobile
                    goBack={() => navigate(`/insurance/application/${id}`)}
                />
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceApplicationCarDashboard
