import { call, fork, put, select, take } from 'redux-saga/effects'

import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'

import { convertDateWithDayJs } from '../../../helpers/dates'
import { message } from 'antd'
import { IRootState } from '../../../store'
import {
    IDropdownItem,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import { carActions } from '../../cars/reducer'
import { technicalInformationActions } from '../reducer'
import { ICarUpdateFieldsPayload } from '../../../services/typedefinitions/apiPayloads'
import { IApiUpdateCar_args } from '../../../services/types'
import { ICarsObject, IReduxError, ICustomErrorData } from '../../cars/types'

const getAllState = (state: IRootState): IRootState => state
const getCarError = (state: IRootState) => state.entities.carsData.error
const getCarsData = (state: IRootState) => state.entities.carsData.cars

export function* writeTechnicalInformationSaga(
    payload: IwriteTechnicalInformationPayloadReq
): Generator<any, any, any> {
    try {
        let dataToSend: ICarUpdateFieldsPayload = {}
        let data = payload.data

        let carsData: ICarsObject = yield select(getCarsData)
        let currentCar = carsData[payload.car_id]
        let carTitle = currentCar?.title
            ? currentCar.title.toLowerCase()
            : undefined
        let updateCarTitle = false

        let currentYear = currentCar?.overview?.data?.year?.answer
            ? `${currentCar?.overview?.data?.year?.answer}`
            : undefined

        if (data.id === 'make' || data.id === 'model') {
            const getAllState = (state: IRootState): IRootState => state
            let store: IRootState = yield select(getAllState)

            let tempMakeID = store.localdata.dropdownData.tempMakeID
            let tempModelID = store.localdata.dropdownData.tempModelID

            let carsData: ICarsObject = yield select(getCarsData)

            let currentCar = carsData[payload.car_id]

            let currentMake = currentCar?.overview?.data?.make?.answer
                ? currentCar.overview.data.make.answer.toLowerCase()
                : undefined

            let currentModel = currentCar?.overview?.data?.model?.answer
                ? currentCar.overview.data.model.answer.toLowerCase()
                : undefined

            let currentCarMakeID = currentCar?.overview?.data.make?.current_uid

            let currentCarModelID =
                currentCar?.overview?.data.model?.current_uid

            const listMakes = store.localdata.dropdownData.carMakes
            const listModels = store.localdata.dropdownData.carModels

            let tempMakeDisplay = store.localdata.dropdownData.tempMakeDisplay
            let tempModelDisplay = store.localdata.dropdownData.tempModelDisplay

            if (data.id === 'make') {
                if (data?.answer) {
                    let item: IDropdownItem

                    for (const itemIndex in listMakes) {
                        item = listMakes[+itemIndex]

                        if (
                            typeof data?.answer === 'string' &&
                            item?.name?.toLowerCase() ===
                                data?.answer.toLowerCase()
                        ) {
                            if (
                                item.uid !== 'new_entry' &&
                                item.name !== 'new_entry'
                            ) {
                                dataToSend.make_id = item.uid
                                data.answer = item.name
                                data.uid_current = item.uid
                                break
                            }
                        }
                    }
                    // console.log('after loop 1', dataToSend)
                }

                if (
                    currentCarMakeID &&
                    tempMakeID &&
                    tempMakeID !== currentCarMakeID
                ) {
                    dataToSend.make_id = tempMakeID
                    data.answer = tempMakeDisplay
                    data.uid_current = tempMakeID
                }

                // last resort
                if (!dataToSend.make_id) {
                    dataToSend.make_id = tempMakeID
                    data.answer = tempMakeDisplay
                    data.uid_current = tempMakeID
                }

                if (
                    carTitle &&
                    currentMake &&
                    carTitle.includes(currentMake) &&
                    data?.answer
                ) {
                    carTitle = carTitle.replace(currentMake, `${data?.answer}`)

                    updateCarTitle = true
                }
            }

            if (data.id === 'model') {
                // console.log(
                //     'inside of here',
                //     'currentCarModelID',
                //     currentCarModelID,
                //     'tempModelID',
                //     tempModelID,
                //     'currentModel',
                //     currentModel
                // )

                if (tempModelID && tempModelID !== currentCarModelID) {
                    dataToSend.model_id = tempModelID
                } else {
                    let item: IDropdownItem

                    for (const itemIndex in listModels) {
                        item = listModels[+itemIndex]

                        if (
                            typeof data?.answer === 'string' &&
                            item?.name?.toLowerCase() ===
                                data?.answer?.toLowerCase()
                        ) {
                            if (
                                item.uid !== 'new_entry' &&
                                item.name !== 'new_entry'
                            ) {
                                dataToSend.model_id = item.uid
                                data.answer = item.name
                                data.uid_current = item.uid
                                break
                            }
                        }
                    }

                    // console.log('after loop 2', dataToSend)
                    if (!dataToSend.model_id && tempModelID) {
                        dataToSend.model_id = tempModelID
                        data.answer = tempModelDisplay
                        data.uid_current = tempModelID
                    }
                }

                if (
                    carTitle &&
                    currentModel &&
                    carTitle.includes(currentModel) &&
                    data?.answer
                ) {
                    carTitle = carTitle.replace(currentModel, `${data?.answer}`)

                    updateCarTitle = true
                }
            }

            if (updateCarTitle && carTitle) {
                dataToSend.title = carTitle
                yield put(
                    carActions.updatCarTitleOnCreation({
                        carid: payload.car_id,
                        title: carTitle,
                    })
                )
            }
        }

        if (data.id === 'drive_train' && data.answer && data.answer !== '-') {
            if (data.answer) {
                dataToSend.drive_train = data.answer === '4WD' ? '4WD' : '2WD'
            }
        }

        if (data.id === 'year' && data.answer && data.answer !== '-') {
            dataToSend.year = data.answer

            if (
                carTitle &&
                currentYear &&
                carTitle.includes(currentYear) &&
                data?.answer
            ) {
                carTitle = carTitle.replace(currentYear, `${data?.answer}`)

                dataToSend.title = carTitle
                yield put(
                    carActions.updatCarTitleOnCreation({
                        carid: payload.car_id,
                        title: carTitle,
                    })
                )
            }
        }

        if (
            data.id === 'color_exterior' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-' &&
            typeof data.answer === 'string'
        ) {
            dataToSend.color_exterior = data.answer
        }

        if (
            data.id === 'color_interior' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-' &&
            typeof data.answer === 'string'
        ) {
            dataToSend.color_interior = data.answer
        }

        if (
            data.id === 'fuel_type' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-' &&
            typeof data.answer === 'string'
        ) {
            dataToSend.fuel_type = data.answer
        }

        if (
            data.id === 'registration_date' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            dataToSend.registration_date = convertDateWithDayJs(data.answer)
        }

        if (data.id === 'engine_capacity' || data.id === 'engine_size') {
            if (
                data.answer !== null &&
                data.answer !== undefined &&
                data.answer !== '-'
            ) {
                if (
                    (typeof data.answer === 'number' && data.answer > 100000) ||
                    (typeof data.answer === 'string' &&
                        parseInt(data.answer) > 100000)
                ) {
                    message.error(
                        'Please add a realistic value for the engine size.'
                    )
                } else {
                    dataToSend.engine_capacity = data.answer
                }
            }
        }

        if (
            data.id === 'drive_side' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            if (data.answer !== null && data.answer !== undefined) {
                if (data.answer === 'Center') {
                    dataToSend.drive_side = 'Center'
                } else if (data.answer === 'RHD') {
                    dataToSend.drive_side = 'Right'
                } else if (data.answer === 'LHD') {
                    dataToSend.drive_side = 'Left'
                }
            }
        }

        if (
            data.id === 'last_owner_change' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            dataToSend.last_owner_change = convertDateWithDayJs(data.answer)
        }

        if (
            data.id === 'service_check_status' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            dataToSend.service_check_status = data.answer
        }

        if (data.id === 'service_check_expiry') {
            if (data.answer !== null && data.answer !== undefined) {
                dataToSend.service_check_expiry = convertDateWithDayJs(
                    data.answer
                )
            }
        }

        if (
            data.id === 'tax_due' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            dataToSend.tax_due = convertDateWithDayJs(data.answer)
        }
        if (
            data.id === 'tax_status' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            dataToSend.tax_status = data.answer
        }

        if (
            data.id === 'body_type' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            dataToSend.body_type =
                data.answer === 'SUV / 4x4' ? 'SUV' : data.answer
        }

        const results = {
            tech_info_id: payload.tech_info_id,
            id: data.id,
            data: data,
        }

        if (
            data.id === 'transmission' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-' &&
            data.answer !== 'Unknown'
        ) {
            dataToSend.transmission = data.answer
        }

        if (
            data.id === 'mileage' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            dataToSend.mileage = data.answer
        }

        if (
            data.id === 'registration_number' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            dataToSend.registration_number = data.answer
        }

        if (
            data.id === 'chassis_number' &&
            data.answer !== null &&
            data.answer !== undefined &&
            data.answer !== '-'
        ) {
            dataToSend.vin = data.answer
        }

        if (
            Object.keys(dataToSend).length > 0 &&
            Object.values(dataToSend).every(
                (val) => val !== null && val !== undefined && val !== '-'
            )
        ) {
            let api_payload: IApiUpdateCar_args = {
                car_id: payload.car_id,
                dataToUpdate: dataToSend,
            }
            yield call(api.entities.updateCar.updateCar, api_payload)
            yield put(
                technicalInformationActions.writeTechnicalInformationSuccess(
                    results
                )
            )
            if (
                data.id === 'year' ||
                data.id === 'make' ||
                data.id === 'model' ||
                data.id === 'engine_capacity' ||
                data.id === 'transmission' ||
                data.id === 'drive_side'
            ) {
                yield put(
                    carActions.updatCarOverviewGridInfo({
                        carid: payload.car_id,
                        id: data.id,
                        data: data?.answer ? data : results.data,
                    })
                )
            }
        } else {
            let store: IRootState = yield select(getAllState)
            let currentData =
                store.entities.technicalInformationData.technical_information[
                    payload.tech_info_id
                ]
            yield put(
                technicalInformationActions.writeTechnicalInformationSuccess({
                    tech_info_id: payload.tech_info_id,
                    id: data.id,
                    // @ts-ignore
                    data: currentData[data.id],
                })
            )
        }

        // yield put(dropDownActions.reset_make_model_temps())
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: writeTechnicalInformationSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let carError: IReduxError = yield select(getCarError)

            let isOnboarding =
                window.location.pathname.match(/onboarding/g) !== null
                    ? true
                    : false

            let customErrorData: ICustomErrorData = {
                custom_message: isOnboarding
                    ? 'An error occured.'
                    : `Something went wrong.`,
                custom_user_action_text: isOnboarding
                    ? 'OK'
                    : 'Return to technical informations',
                custom_redirect_path: isOnboarding
                    ? undefined
                    : !carError
                    ? `/car/${payload.car_id}/technical-information`
                    : '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )

            yield put(
                technicalInformationActions.writeTechnicalInformationError(
                    customError
                )
            )
        }
    }
}

function* watcherWriteTechnicalInformation() {
    while (true) {
        const { payload } = yield take(
            technicalInformationActions.writeTechnicalInformationRequest
        )
        yield call(writeTechnicalInformationSaga, payload)
    }
}

const write_technical_information_saga: any[] = [
    fork(watcherWriteTechnicalInformation),
]

export default write_technical_information_saga
