import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../../services'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IPublicShareSuccessPayloadRedux } from '../../garages/actions/actions'
import { ConvertSinglePublicShare } from '../../../conversions/shares/convertPublicShares'
import posthog from 'posthog-js'
import { state_select_garages, state_select_user } from './getCarPublicShares'
import { base_url } from '../../../services/urls'
import {
    IPublicShareAPIRes_item,
    IUrlShortenerAPI_request_args,
    IUrlShortenerAPI_res,
} from '../../../services/types'
import { IUser } from '../../../user/types'
import { IGarageObject } from '../../garages/types'
import { IReduxError } from '../types'
import { carActions } from '../reducer'

export function* CreateCarPublicShareSaga(carid: string) {
    try {
        const carPublicShareAPIP: IPublicShareAPIRes_item = yield call(
            api.entities.updateCar.createCarPublicShare,
            carid
        )
        let args: IUrlShortenerAPI_request_args = {
            url: `car/${carPublicShareAPIP.link_identifier}/overview`,
        }

        let code_s: IUrlShortenerAPI_res = yield call(
            api.entities.updateCar.createUrlShortener,
            args
        )

        let convertedObj: IPublicShareSuccessPayloadRedux =
            ConvertSinglePublicShare({
                id: carid,
                entity: 'car',
                api_payload: carPublicShareAPIP,
                url_shortener_code: code_s.code,
            })

        convertedObj.public_share.qr_code_url = `${base_url}/s/${code_s.code}`

        let userLoggedIn: IUser = yield select(state_select_user)

        if (userLoggedIn.owns_garage && userLoggedIn.owns_garage.uid) {
            let garages: IGarageObject = yield select(state_select_garages)

            if (
                garages[userLoggedIn.owns_garage.uid] &&
                garages[userLoggedIn.owns_garage.uid].public_share_owned &&
                garages[userLoggedIn.owns_garage.uid].public_share_owned!
                    .link_identifier
            ) {
                let argsg: IUrlShortenerAPI_request_args = {
                    url: `garage/${
                        garages[userLoggedIn.owns_garage.uid]
                            .public_share_owned!.link_identifier
                    }/car/${carid}/overview`,
                }
                let code_g: IUrlShortenerAPI_res = yield call(
                    api.entities.updateCar.createUrlShortener,
                    argsg
                )

                convertedObj.public_share.qr_code_url_via_garage = `${base_url}/s/${code_g.code}`
            }
        }

        yield put(carActions.createCarPublicShareSuccess(convertedObj))
        // Capture event
        posthog.capture('create car link-share')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateCarPublicShareSaga,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(carActions.createCarPublicShareError(typedError))
            return
        }
    }
}

function* watcherCreatePublicCarShare() {
    while (true) {
        const { payload } = yield take(carActions.createCarPublicShareRequest)
        yield call(CreateCarPublicShareSaga, payload)
    }
}

const create_public_car_shares_sagas: any[] = [
    fork(watcherCreatePublicCarShare),
]

export default create_public_car_shares_sagas
