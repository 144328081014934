import { message } from 'antd'
import heic2any from 'heic2any'
import { useState, useEffect } from 'react'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import styled from 'styled-components'
import StandardCtaBtn from '../Button/standardCtaBtn'
import BinIcon from '../icons/components/binIcon'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const OverlayProgress = styled.div<{ $progress: number }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${(props) =>
        props.$progress !== undefined ? `${100 - props.$progress}%` : 0};
    background-color: var(
        --background-overlay-light,
        rgba(255, 255, 255, 0.96)
    );
    transition: all 200ms;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: ${(props) =>
        props.$progress !== undefined && props.$progress < 90 ? `16px` : '0px'};
`

const OverlayDeletion = styled.div`
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 0;
    justify-self: flex-end;
`

const ProgressText = styled.span`
    padding: 0;
    margin: 0;
    color: var(--text-darker, #616161);
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
`

export default function UploadedImagePreview({
    currentImagesUploaded,
    uploadProgress,
    borderRadius,
    onDelete,
}: {
    currentImagesUploaded: File
    uploadProgress?: number
    borderRadius?: string
    onDelete?: () => void
}) {
    const [imgPreviewLoading, setImgPreviewLoading] = useState(false)
    const [url, setUrl] = useState('')

    // fixing infinite blob generation - putting image inside local state to avoinf re-render onChange
    useEffect(() => {
        const getCustomFormatImgUrl = async () => {
            try {
                // Convert HEIC/HEIF to JPEG/PNG
                const conversionResult = await heic2any({
                    blob: currentImagesUploaded,
                    toType: 'image/jpeg',
                })

                if (conversionResult instanceof Blob) {
                    // Create a new filename with the .jpg extension
                    const newFileName =
                        currentImagesUploaded.name.replace(/\.[^/.]+$/, '') +
                        '.jpg'
                    // Create a new File object
                    const newFile = new File([conversionResult], newFileName, {
                        type: 'image/jpeg',
                    })

                    // Create a URL for the converted image
                    const url = URL.createObjectURL(newFile)
                    setUrl(url)
                    setImgPreviewLoading(false)
                } else {
                    message.error(
                        'An error occured, please try uploading your image again.'
                    )
                }
            } catch (e) {
                message.error(
                    'An error occured, please try uploading your image again.'
                )
            }
        }

        if (currentImagesUploaded) {
            if (
                currentImagesUploaded.type === 'image/heic' ||
                currentImagesUploaded.type === 'image/heif'
            ) {
                setImgPreviewLoading(true)
                getCustomFormatImgUrl()
            } else {
                let url = URL.createObjectURL(currentImagesUploaded)

                url && setUrl(url)
            }
        }

        return () => {}
    }, [currentImagesUploaded])

    const { theme } = useThemes()
    return (
        <div
            style={{
                width: '100%',
                boxSizing: 'border-box',
                height: '100%',
                position: 'relative',
            }}
        >
            {imgPreviewLoading ? <SkeletonAbsolute isthingloading /> : null}
            {uploadProgress !== undefined && currentImagesUploaded ? (
                <OverlayProgress
                    $progress={uploadProgress}
                    style={{
                        borderTopLeftRadius: borderRadius,
                        borderTopRightRadius: borderRadius,
                    }}
                >
                    {uploadProgress !== undefined && uploadProgress < 90 ? (
                        <ProgressText> {`${uploadProgress}%`}</ProgressText>
                    ) : null}
                </OverlayProgress>
            ) : null}
            {onDelete &&
            (uploadProgress === undefined || uploadProgress === 100) ? (
                <OverlayDeletion>
                    <StandardCtaBtn
                        bg={colours[theme].background_neutral_backdrop_70}
                        removeShadowOnHover
                        removePaddings
                        customHeight="32px"
                        customWidth="32px"
                        onClick={onDelete}
                        isRound
                    >
                        <BinIcon color="var(--bg-color, #fff)" />
                    </StandardCtaBtn>
                </OverlayDeletion>
            ) : null}
            <img
                src={url}
                alt="car gallery"
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: borderRadius,
                }}
            />
        </div>
    )
}
