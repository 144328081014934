import { IJourneyShareYourCarStepID } from 'journeyInterfaces'
import { useNavigate, useLocation } from 'react-router-dom'
import JourneyShareYourCarWelcome from '../welcome'
import QrCodeShareYourCarJourneyStep from '../qrCode'
import PublicLinkShareYourCarJourneyStep from '../publicLinkShare'
import PrivateDirectShareYourCarJourneyStep from '../privateDirectShare'
import PrivateDirectSettingsShareYourCarJourneyStep from '../privateDirectShareSettings'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'
import { IUser } from '../../../../../redux/user/types'

type Props = {
    stepID: IJourneyShareYourCarStepID
    startJourney: () => void
    exitJourney: () => void
    user: IUser | null
}
export default function ShareYourCarJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
    user,
}: Props) {
    const navigate = useNavigate()
    const location = useLocation()
    let locationState: any = location.state

    switch (stepID) {
        case 'welcome':
            return (
                <JourneyShareYourCarWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )

        case 'qr_code':
            return (
                <QrCodeShareYourCarJourneyStep
                    step={1}
                    totalSteps={4}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(`/journey/share-your-car?step=welcome`)
                    }}
                    goToNextStep={() => {
                        navigate(
                            `/journey/share-your-car?step=public_link_share`
                        )
                    }}
                />
            )
        case 'public_link_share':
            return (
                <PublicLinkShareYourCarJourneyStep
                    step={2}
                    totalSteps={4}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(`/journey/share-your-car?step=qr_code`)
                    }}
                    goToNextStep={() => {
                        navigate(
                            `/journey/share-your-car?step=private_direct_share`
                        )
                    }}
                />
            )
        case 'private_direct_share':
            return (
                <PrivateDirectShareYourCarJourneyStep
                    step={3}
                    totalSteps={4}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(
                                  `/journey/share-your-car?step=public_link_share`
                              )
                    }}
                    goToNextStep={() => {
                        navigate(
                            `/journey/share-your-car?step=private_direct_share_settings`
                        )
                    }}
                />
            )
        case 'private_direct_share_settings':
            return (
                <PrivateDirectSettingsShareYourCarJourneyStep
                    step={4}
                    totalSteps={4}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? navigate(locationState?.prevPath)
                            : navigate(
                                  `/journey/share-your-car?step=private_direct_share`
                              )
                    }}
                    goToNextStep={() => {
                        navigate(`/journey/share-your-car?step=achieved`, {
                            state: {
                                prevPath: `${location.pathname}${
                                    location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )
        case 'achieved':
            return <JourneyAchievedStep sectionID="share_your_car" />

        default:
            return (
                <JourneyShareYourCarWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
