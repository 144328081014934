import 'tslib'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './redux/store/index'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import { InsuranceInitialProvider } from './providers/insurance/insuranceInitialProvider'
import ThemesProvider from './providers/theme/customThemeProvider'
import { ShowroomProvider } from './providers/showroom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect } from 'react'
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import { history } from './redux/store/index'
const env: any = process.env.REACT_APP_ENV
const version: any = process.env.REACT_APP_ENV

const sentryEnv = !env ? 'local' : env
const sentryVersion = !version ? 'unknown' : version

if (env === 'production') {
    posthog.init('85WKOsCuFh0A4NK61i42WbOfL4dRGTca17mAU4CiXxY', {
        api_host: 'https://app.posthog.com',
        // capture_pageview: false,
    })
} else {
    posthog.init('lkMjyn_bOrIV-Kahkv0j8tw-ezYbsN2SVFtg-AIHBC0', {
        api_host: 'https://app.posthog.com',
        // capture_pageview: false,
    })
}

Sentry.init({
    dsn:
        sentryEnv === 'local'
            ? undefined
            : 'https://992ca82abc424f59a0e6412ca9a6fcc8@o532609.ingest.sentry.io/5651655',
    integrations: [
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    tracePropagationTargets: ['/^https://api.clustodian.club/'],
    normalizeDepth: 10,
    debug: sentryEnv === 'production' ? false : true,
    environment: sentryEnv,
    release: 'custodian-frontend@' + sentryVersion,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
})

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = createRoot(rootElement)

root.render(
    <Router history={history}>
        <Provider store={store}>
            <GoogleOAuthProvider clientId="1074406518526-kmhb6c4p5b44vb9tu0crtlh2motecjr3.apps.googleusercontent.com">
                <ShowroomProvider>
                    <InsuranceInitialProvider>
                        <ThemesProvider>
                            <App />
                        </ThemesProvider>
                    </InsuranceInitialProvider>
                </ShowroomProvider>
            </GoogleOAuthProvider>
        </Provider>
    </Router>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
