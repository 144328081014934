import {
    ISharingPermissionIDS_api,
    ISharingPermissionIDS,
} from '../../entities/cars/types'

export const sharingPermissionsIdsAPIObj: IsharingPermissionsIdsAPIObj_item = {
    default: ['car._'],
    car_gallery: ['car._', 'car.image.*'],
    history_file: ['car.entry._', 'car.entry.image.*'],
    entry_attachments: [
        'car.entry._',
        'car.entry.image.*',
        'car.entry.attachment.*',
    ],
    entry_costs: ['car.entry._', 'car.entry.image.*', 'car.entry.cost._'],
    entry_cost_attachments: [
        'car.entry._',
        'car.entry.image.*',
        'car.entry.cost.*',
    ],
}

export type IsharingPermissionsIdsAPIObj_item = {
    [key: string]: ISharingPermissionIDS_api[]
}

export const ConvertSharePermissionIDsToState = (
    apiIDs: ISharingPermissionIDS_api[]
): ISharingPermissionIDS[] => {
    let res: ISharingPermissionIDS[] = []

    if (apiIDs.includes('car.image.*')) {
        res = [...res, 'car gallery']
    }

    if (apiIDs.includes('car.entry._')) {
        res = [...res, 'history file']
    }

    if (apiIDs.includes('car.entry.attachment.*')) {
        res = [...res, 'entry attachments']
    }

    if (apiIDs.includes('car.entry.cost._')) {
        res = [...res, 'entry costs']
    }

    if (apiIDs.includes('car.entry.cost.*')) {
        res = [...res, 'entry cost attachments']
    }

    return res
}

export const ConvertSharePermissionIDsToAPI = (
    stateIDs: ISharingPermissionIDS[]
): ISharingPermissionIDS_api[] => {
    // by default , contains :

    let res: ISharingPermissionIDS_api[] = ['car._']
    for (let i = 0; i < stateIDs.length; i++) {
        let id: string = stateIDs[i].replace(/ /g, '_')

        let matching_ids: ISharingPermissionIDS_api[] =
            sharingPermissionsIdsAPIObj[id]
        if (sharingPermissionsIdsAPIObj[id]) {
            for (let i = 0; i < matching_ids.length; i++) {
                let matching_item: ISharingPermissionIDS_api = matching_ids[i]

                if (!res.includes(matching_item)) {
                    res = [...res, matching_item]
                }
            }
        }
    }

    if (!res.includes('car.image.*')) {
        res.push('car.image._')
    }
    return res
}
