import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import back_circle_navigation from '../../../../public/assets/navigation/back_circle_navigation.svg'
import filter_blue_icon from '../../../../public/assets/navigation/filter_blue_icon.svg'
import sort_by_created_green from '../../../../public/assets/icons/sort_by_created_green.svg'
import sort_alphabetical_green from '../../../../public/assets/icons/sort_alphabetical_green.svg'
import sort_by_updated_green from '../../../../public/assets/icons/sort_by_updated_green.svg'
import Expander from '../../../atoms/expander/expander'
import { device } from '../../../templates/displays/devices'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import CarShareSearchBarMobile from '../../searchbar/carShare/carShareSearchBarMobile'

type StyledProps = {
    issearchactive?: boolean | undefined
}

const HeaderWrapper = styled.div<StyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color, #fff);
    box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: ${(props) => (props.issearchactive ? '65px' : '30px')};
    padding-top: 20px;
    top: 0;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 5;
`

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 88vw;
    padding-bottom: 20px;
`

const ReturnLogo = styled.img`
    @media ${device.mobile} {
        width: 26px;
        height: 26px;
        margin-right: 10px;
    }

    @media ${device.ipad} {
        width: 26px;
        height: 26px;
        margin-right: 10px;
    }

    @media ${device.desktop} {
        display: none;
    }
`

const Return = styled.div`
    color: #5ec3ca;
    transform: translateY(-2px);

    @media ${device.mobile} {
        font-family: 'Lato-light';
        justify-content: center;
        text-transform: lowercase;
        font-weight: 200;
        font-size: 17px;
        margin-top: 2px;
        letter-spacing: 1.05px;
    }

    @media ${device.ipad} {
        font-family: 'Lato-light';
        justify-content: center;
        text-transform: lowercase;
        font-weight: 200;
        font-size: 16px;
        margin-top: 2px;
        letter-spacing: 1.05px;
    }

    @media ${device.desktop} {
        display: none;
    }
`

const TopLeftSide = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const RightSideIcon = styled.img`
    height: 24px;
    width: 24px;
    margin-left: 20px;
`

const TopRightSide = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

const StopSearchZone = styled.div<StyledProps>`
    display: ${(props) => (!props.issearchactive ? 'none' : 'auto')};
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: black;
    left: 0;
    right: 0;
    z-index: 0;
    opacity: 0.1;
    z-index: 4;
`
type IFilterButtonProp = {
    $content?: number | undefined
}

const FilterButtonWrapper = styled.div<IFilterButtonProp>`
    position: relative;
    ::after {
        display: ${(props) => (props.content ? 'block' : 'none')};
        position: absolute;
        right: 0;
        top: 0;
        margin-right: -8px;
        margin-top: -4px;
        height: 15px;
        width: 15px;
        background-color: #5ec3ca;
        font-size: 10px;
        color: #ffffff;
        border: transparent;
        border-radius: 4px;
        text-align: center;
        content: ${(props) => (props.$content ? `'${props.$content}'` : '')};
    }
`

interface Props {
    onFilterIconClick?: any
    onReturn: any
    carid?: string
    searchFor?: string | undefined
    sendFiltersToApi?: any
    currentSearch?: string | undefined
    onSortIconClick?: any
    activeFilters?: number
    sortingCriteria?: string
    onSearch?: any
    clearSearch?: any
}

const TopHeaderFilterNavMobile: React.FC<Props> = ({
    onReturn,
    onFilterIconClick,
    onSortIconClick,
    activeFilters,
    sortingCriteria,
    onSearch,
    searchFor,
    clearSearch,
}) => {
    const [issearchactive, setIssearchactive] = useState<boolean>(
        searchFor && searchFor !== '' ? true : false
    )

    const manageissearchactive = (isActive?: boolean) => {
        setIssearchactive(isActive !== undefined ? isActive : !issearchactive)
    }

    const updateBodyScroll = () => {
        if (issearchactive) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }
    }

    useEffect(() => {
        updateBodyScroll()
        return () => {
            enableBodyScroll(document.body)
        }
    }, [issearchactive])

    return (
        <>
            <StopSearchZone
                onClick={() => {
                    manageissearchactive(false)
                }}
                issearchactive={issearchactive}
            />
            <HeaderWrapper issearchactive={issearchactive}>
                <Expander duration={80} height={!issearchactive ? 'auto' : 0}>
                    <TopRow>
                        <TopLeftSide onClick={onReturn}>
                            <ReturnLogo
                                src={back_circle_navigation}
                                alt="go-back-logo-2"
                            />
                            <Return>return</Return>
                        </TopLeftSide>
                        <TopRightSide>
                            {onSortIconClick && (
                                <RightSideIcon
                                    src={
                                        sortingCriteria === 'alphabetical'
                                            ? sort_alphabetical_green
                                            : sortingCriteria === 'updated_at'
                                            ? sort_by_updated_green
                                            : sort_by_created_green
                                    }
                                    onClick={() => onSortIconClick(true)}
                                    style={{
                                        width:
                                            sortingCriteria === 'alphabetical'
                                                ? '16px'
                                                : '20px',
                                        transform: 'translateY(2px)',
                                        marginRight:
                                            sortingCriteria === 'alphabetical'
                                                ? '5px'
                                                : '0px',
                                    }}
                                />
                            )}
                            <FilterButtonWrapper
                                $content={activeFilters && activeFilters}
                                onClick={() => onFilterIconClick(true)}
                            >
                                <RightSideIcon src={filter_blue_icon} />
                            </FilterButtonWrapper>
                        </TopRightSide>
                    </TopRow>
                </Expander>
                <CarShareSearchBarMobile
                    dataCyId={'car-shares-search-mobile'}
                    onSearch={onSearch}
                    clearSearch={clearSearch}
                    searchFor={searchFor}
                    issearchactive={issearchactive ? issearchactive : undefined}
                    manageissearchactive={manageissearchactive}
                    placeholder="Search for an email or name"
                />
            </HeaderWrapper>
        </>
    )
}

export default TopHeaderFilterNavMobile
