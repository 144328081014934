import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { device } from '../../../templates/displays/devices'
import Tour from 'reactour'
import './styles.css'
import grey_tip from '../../../../public/assets/icons/grey_tip.svg'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import TutorialWrapperDesktop from '../tutorialWrappers/TutorialWrapperDesktop'

const Title = styled.h1`
    color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 24px;
    text-align: center;

    @media ${device.mobile} {
        display: none;
    }
`

const Text = styled.p`
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 17px;
    line-height: 1.9;
    width: 100%;
    box-sizing: border-box;
    font-family: Lato-Light !important;
    font-style: italic;

    @media ${device.mobile} {
    }
`

const Padding = styled.div`
    padding-top: 25px;

    @media ${device.mobile} {
    }
`
const TextPadding = styled.div`
    padding-top: 8px;

    @media ${device.mobile} {
    }
`

const Tip = styled.img`
    width: 20px;
    @media ${device.mobile} {
    }
`

const TipText = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Light;
    padding-left: 20px;
    font-size: 16px;

    @media ${device.mobile} {
    }
`

const TipRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
    align-self: start;
    justify-self: start;
`

const TourStyled = styled((props: any) => <Tour {...props} />)`
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 450px;

    .reactour__badge {
        padding: 0 !important;
        width: 32px !important;
        height: 32px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 0 !important;
    }

    /* Override the generated class styles */
    [data-tour-elem='badge'] {
        line-height: 2 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
`
const InnerTextRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
`

const RoundWhite = styled.div`
    background-color: var(--bg-color, #fff);
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08));
    margin-left: 10px;
    transform: rotate(90deg);
`

const tutorialConfigs_Garage = (close: any) => [
    {
        selector: '[data-tut="car-gallery-image-tuto-desktop-1"]',
        content: () => (
            <TutorialWrapperDesktop
                skip={close}
                next={close}
                nextBtnText="close"
            >
                <Title>Car Gallery Image</Title>

                <Padding />

                <Text>
                    <InnerTextRow>
                        <div>By clicking on the three dots icon:</div>
                        <span style={{ paddingLeft: '15px' }} />

                        <RoundWhite
                            style={{
                                width: '30px',
                                height: '30px',
                            }}
                        >
                            <svg
                                width="3"
                                height="17"
                                viewBox="0 0 3 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.00006 15.4999C3.00006 14.6715 2.32849 13.9999 1.50006 13.9999C0.671634 13.9999 6.10352e-05 14.6715 6.10352e-05 15.4999C6.10352e-05 16.3284 0.671634 16.9999 1.50006 16.9999C2.32849 16.9999 3.00006 16.3284 3.00006 15.4999Z"
                                    fill="#5EC3CA"
                                />
                                <path
                                    d="M3.00006 1.49994C3.00006 0.671512 2.32849 -6.10352e-05 1.50006 -6.10352e-05C0.671634 -6.10352e-05 6.10352e-05 0.671512 6.10352e-05 1.49994C6.10352e-05 2.32837 0.671634 2.99994 1.50006 2.99994C2.32849 2.99994 3.00006 2.32837 3.00006 1.49994Z"
                                    fill="#5EC3CA"
                                />
                                <path
                                    d="M3.00006 8.49994C3.00006 7.67151 2.32849 6.99994 1.50006 6.99994C0.671634 6.99994 6.10352e-05 7.67151 6.10352e-05 8.49994C6.10352e-05 9.32837 0.671634 9.99994 1.50006 9.99994C2.32849 9.99994 3.00006 9.32837 3.00006 8.49994Z"
                                    fill="#5EC3CA"
                                />
                            </svg>
                        </RoundWhite>

                        <span style={{ paddingLeft: '10px' }} />

                        <div>you can...</div>
                    </InnerTextRow>
                </Text>

                <TextPadding />
                <Text>- select as Car Profile Image</Text>
                <Text>- select as Gallery Feature</Text>
                <Text>- update image details </Text>
                <Text>- delete the image</Text>

                <TipRow>
                    <Tip src={grey_tip} alt="tip" />
                    <TipText>to zoom in, click on the image.</TipText>
                </TipRow>
            </TutorialWrapperDesktop>
        ),
    },
]

const TutorialConfigsCarGalleryImageDesktop: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(0)
    const [isTourOpen, setIsTourOpen] = useState(true)

    const { carid } = useParams<{ carid: string }>()
    const location = useLocation()
    const navigate = useNavigate()

    const setCurrentStepCustom = (step: number) => {
        setCurrentStep(step)
    }

    let params = new URLSearchParams(location.search)

    let image_id_param = params.get('id')

    const setIsTourOpenCustom = () => {
        setIsTourOpen(false)
        navigate(`/car/${carid}/gallery?id=${image_id_param}`)
    }

    useEffect(() => {
        if (isTourOpen) {
            disableBodyScroll(document.body)
        } else {
            enableBodyScroll(document.body)
        }

        return () => {
            enableBodyScroll(document.body)
        }
    }, [isTourOpen])

    return (
        <>
            {window.innerWidth > 880 && (
                <TourStyled
                    steps={tutorialConfigs_Garage(setIsTourOpenCustom)}
                    isOpen={isTourOpen}
                    onRequestClose={() => undefined}
                    rounded={5}
                    accentColor="#5ec3ca"
                    getCurrentStep={(curr: number) => {
                        window.scrollTo(0, 0)
                        return setCurrentStepCustom(curr + 1)
                    }}
                    disableInteraction={true}
                    showCloseButton={false}
                    currentStep={currentStep}
                    showNavigation={false}
                />
            )}
        </>
    )
}

export default TutorialConfigsCarGalleryImageDesktop
