import styled from 'styled-components'
import EntryGalleryItemPreview from '../../atoms/timeline/entryGalleryItemPreview/entryGalleryItemPreview'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'
import { sortImagesIdsByFeatured } from '../../../helpers/sort/sortByImageFeatured'
import { IGalleryImagesObject } from '../../../redux/entities/galleries/types'

const GridContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 15px;
    display: grid;
    grid-gap: 8px;

    @media ${device.desktop} {
        grid-template-columns: 1fr 1fr 1fr;
        padding-right: 50px;
    }

    @media ${device.ipad} {
        grid-template-columns: 1fr 1fr 1fr;
        padding-top: 15px;
    }

    @media ${device.mobile} {
        padding-top: 15px;
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 950px) and (max-width: 1200px) {
        max-width: 500px;
        padding-right: 0px;
    }

    @media (min-width: 880px) and (max-width: 950px) {
        max-width: 400px;
        padding-right: 0px;
    }

    @media ${device.large_desktop} {
        grid-template-columns: 1fr 1fr 1fr;
        padding-right: 50px;
    }
`

const MoreImagesNbSquare = styled.div`
    position: relative;
    cursor: pointer;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
`

const ItemContainer = styled(motion.div)`
    cursor: pointer;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
`

const MoreImg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    font-family: Lato-semibold;
    font-size: 32px;
`

interface Props {
    images_data_object: IGalleryImagesObject
    ids_list: string[]
    onImageClick: any
    onPlaceholderClick: any
}

function TimelineEntryGalleryGrid(props: Props) {
    const { images_data_object, ids_list, onImageClick, onPlaceholderClick } =
        props

    const max_nb_of_img_preview = 6

    let moreImagesNb: number | undefined =
        ids_list && ids_list.length > max_nb_of_img_preview
            ? ids_list.length - max_nb_of_img_preview
            : undefined

    let ids_sorted = sortImagesIdsByFeatured({
        images_data: images_data_object,
        images_ids: ids_list,
        max_n: max_nb_of_img_preview,
    })

    return (
        <GridContainer>
            {ids_sorted.map((id: string, index: number) => {
                if (
                    images_data_object[id] &&
                    index < max_nb_of_img_preview - 1
                ) {
                    return (
                        <ItemContainer
                            whileTap={{ scale: 1.1 }}
                            key={index}
                            onClick={() =>
                                onImageClick(images_data_object[id].id)
                            }
                        >
                            <EntryGalleryItemPreview
                                img={images_data_object[id]}
                            />
                        </ItemContainer>
                    )
                } else return <div key={index} style={{ display: 'none' }} />
            })}

            {moreImagesNb ? (
                <MoreImagesNbSquare
                    onClick={moreImagesNb ? onPlaceholderClick : undefined}
                >
                    <ItemContainer whileTap={{ scale: 1.1 }}>
                        <EntryGalleryItemPreview
                            img={
                                images_data_object[
                                    ids_sorted[max_nb_of_img_preview - 1]
                                ]
                            }
                        />
                    </ItemContainer>
                    {moreImagesNb ? <MoreImg>+{moreImagesNb}</MoreImg> : null}
                </MoreImagesNbSquare>
            ) : null}
        </GridContainer>
    )
}

export default TimelineEntryGalleryGrid
