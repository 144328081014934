import * as React from 'react'
import styled from 'styled-components'
import BubbleCard from './bubbleCard'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import { ITimelineEntryTypes } from '../../../redux/timeline/types'

type IStyle = {
    $theme: ITheme
    $fade?: boolean
    $fadeReverse?: boolean
}

const Wrapper = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-top: 0px;
    z-index: 2;
`

const Line = styled.div<IStyle>`
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    height: 4px;
    top: 0px;
    left: 0;
    background: ${(props) =>
        props.$fade
            ? `linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, ${
                  colours[props.$theme].border_muted
              } 100%)`
            : props.$fadeReverse
            ? `linear-gradient(90deg, ${
                  colours[props.$theme].border_muted
              } 0%, rgba(26, 26, 26, 0.00) 100%)`
            : colours[props.$theme].border_muted};
`

export interface IProps {
    children?: React.ReactNode
    dataCyId?: string
    category?: ITimelineEntryTypes | undefined
    onClick?: () => void
    isFirst?: boolean
    isLast?: boolean
}

const BubbleCardWithHorizontalLine: React.FC<IProps> = (props) => {
    const { theme } = useThemes()
    return (
        <Wrapper $theme={theme}>
            <Line
                $fade={props.isFirst}
                $fadeReverse={props.isLast}
                $theme={theme}
            />
            <BubbleCard
                bubleSide="top"
                category={props.category}
                dataCyId={props.dataCyId && props.dataCyId}
                onClick={props.onClick}
            >
                {props.children}
            </BubbleCard>
        </Wrapper>
    )
}

export default BubbleCardWithHorizontalLine
