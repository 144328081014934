import * as React from 'react'
import styled from 'styled-components'
import { useRef } from 'react'
import { ChromePicker } from 'react-color'
import { ChangeStickerTheme, IStickerTheme } from '../fns'
import { useOnClickOutside } from '../../../templates/clickOutside'
import QrCodeStickerThemeBtnJourney from '../../../atoms/Button/qrCodeStickerThemeBtnJourney'
import { device } from '../../../templates/displays/devices'

type StyledProps = {
    $height?: string
    $width?: string
    $iconSize?: string
}

const OverWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
`

const SectionTitleWrapper = styled.div<StyledProps>`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 24px;
    padding-top: 40px;
`

const SectionTitle = styled.div<StyledProps>`
    color: var(--text-strong, #121212);
    font-family: Lato-Semibold;
    font-size: 18px;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    width: 100%;
    box-sizing: border-box;
    @media ${device.smallest_laptops} {
        grid-template-columns: 1fr;
    }
`

type Props = {
    qr_code_link: string
    predefinedStickerThemes: IStickerTheme[]
    state: {
        colours: {
            colour_one: string
            colour_two: string
        }
        openColourPicker?: 'one' | 'two' | undefined
    }
    dispatch: any
    activeThemeIndex: number | null
    setActiveThemeIndex: any
}

const QrCodeStickerJourneyFormDesktopLeft: React.FunctionComponent<Props> = (
    props
) => {
    let {
        state,
        dispatch,
        predefinedStickerThemes,
        activeThemeIndex,
        setActiveThemeIndex,
    } = props

    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => {
        dispatch({
            type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
            payload: {
                ...state,
                openColourPicker: undefined,
            },
        })
    })

    return (
        <OverWrapper>
            <Wrapper>
                <SectionTitleWrapper>
                    <SectionTitle>Play with custom colours</SectionTitle>
                </SectionTitleWrapper>
                <Grid>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <QrCodeStickerThemeBtnJourney
                            colourOne={state.colours.colour_one}
                            txt={'Outer ring'}
                            width="100%"
                            onClick={() =>
                                dispatch({
                                    type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
                                    payload: {
                                        ...state,
                                        openColourPicker: 'one',
                                    },
                                })
                            }
                        />
                        {state.openColourPicker === 'one' && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '70px',
                                    zIndex: 1,
                                }}
                                ref={ref}
                            >
                                <ChromePicker
                                    color={
                                        state.openColourPicker === 'one'
                                            ? state.colours.colour_one
                                            : undefined
                                    }
                                    disableAlpha
                                    onChange={(colour: any) => {
                                        setActiveThemeIndex(null)
                                        dispatch({
                                            type: ChangeStickerTheme.CHANGE_COLOUR_ONE,
                                            payload: {
                                                colours: {
                                                    ...state.colours,
                                                    colour_one: colour.hex,
                                                },
                                            },
                                        })
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        <QrCodeStickerThemeBtnJourney
                            colourOne={state.colours.colour_two}
                            txt={'background'}
                            width="100%"
                            onClick={() =>
                                dispatch({
                                    type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
                                    payload: {
                                        ...state,
                                        openColourPicker: 'two',
                                    },
                                })
                            }
                        />
                        {state.openColourPicker === 'two' && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '70px',
                                    zIndex: 1,
                                }}
                                ref={ref}
                            >
                                <ChromePicker
                                    color={
                                        state.openColourPicker === 'two'
                                            ? state.colours.colour_two
                                            : undefined
                                    }
                                    disableAlpha
                                    onChange={(colour: any) => {
                                        setActiveThemeIndex(null)
                                        dispatch({
                                            type: ChangeStickerTheme.CHANGE_COLOUR_TWO,
                                            payload: {
                                                colours: {
                                                    ...state.colours,
                                                    colour_two: colour.hex,
                                                },
                                            },
                                        })
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </Grid>

                {activeThemeIndex === null && (
                    <div
                        style={{
                            fontSize: 12,
                            color: 'var(--text-default, #666666)',
                            fontFamily: 'Lato',
                            fontStyle: 'italic',
                            paddingTop: 8,
                        }}
                    >
                        Please avoid dark colours for the background as it will
                        make the QR code unscannable.
                    </div>
                )}

                <SectionTitleWrapper>
                    <SectionTitle>Check our themes</SectionTitle>
                </SectionTitleWrapper>
                <Grid>
                    {predefinedStickerThemes &&
                        predefinedStickerThemes.map((theme, index) => (
                            <React.Fragment key={`qr_${theme}_${index}_mobile`}>
                                <QrCodeStickerThemeBtnJourney
                                    key={index}
                                    index={index}
                                    colourOne={theme.colour_one}
                                    colourTwo={theme.colour_two}
                                    txt={
                                        theme.name
                                            ? theme.name
                                            : 'UnSectiontitled'
                                    }
                                    isActiveBtn={
                                        activeThemeIndex === index
                                            ? true
                                            : false
                                    }
                                    width="100%"
                                    onClick={() => {
                                        setActiveThemeIndex(index)
                                        dispatch({
                                            type: ChangeStickerTheme.CHANGE_BOTH_COLOURS,
                                            payload: {
                                                colours: {
                                                    colour_one:
                                                        theme.colour_one,
                                                    colour_two:
                                                        theme.colour_two,
                                                },
                                            },
                                        })
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        })
                                    }}
                                />
                            </React.Fragment>
                        ))}
                </Grid>
            </Wrapper>
        </OverWrapper>
    )
}

export default QrCodeStickerJourneyFormDesktopLeft
