import {
    IDropdownItem,
    IList,
    ITechInfoData,
    IwriteMultipleTechnicalInformationPayloadReq,
} from 'entityModels'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    cubicCentimetersToLiters,
    cubicInchesToLiters,
    generateBackEndValueDistanceUnit,
    generateBackEndValueFuelUnit,
    generateFrontEndValueDistanceUnit,
    generateFrontEndValueFuelUnit,
    kmToMiles,
} from '../../../../helpers/units/unitConversion'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import Expander from '../../../atoms/expander/expander'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { Select } from '../../../atoms/select'
import SelectSearchBarCarInfoDesktop from '../../../organisms/editForms/technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import { OwnerShowroomFormActions } from '../../../organisms/showroom/contactForm/ownerContactFormDesktop'
import { device } from '../../../templates/displays/devices'
import { ErrorText } from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerDesktop'
import { CarYearValidation } from './validationSchema'
import { getRightSchemaAccountData } from '../../../organisms/editForms/account/validationSchema'
import LoaderElasticThreeDots from '../../../atoms/loader/loaderElasticThreeDots'
import SwitchButton from '../../../atoms/Button/switchButton'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { IUser } from '../../../../redux/user/types'
import { ICar } from '../../../../redux/entities/cars/types'
import { technicalInformationActions } from '../../../../redux/entities/technical_information/reducer'

const ModalContentDesktop = styled.section`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        margin-top: 5vh;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        max-width: 600px;
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
        color: var(--text-strong, #121212);
        border-radius: 4px;
        position: relative;
        margin-bottom: 50px;
        overflow: visible;
        max-height: 90vh;
        transition: all 200;
        overflow-y: scroll;
    }

    @media ${device.large_desktop} {
        margin-top: 12vh;
    }
`
const Title = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: var(--text-strong, #1a1a1a);
`

const RowForm = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`

const AdjustPosition = styled.div`
    margin-left: 20px;
    width: 100%;
    box-sizing: border-box;
`

const ModalInnerContentWrapperDesktop = styled.div`
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
`

const EquivalentConverted = styled.div`
    font-size: 12px;
    color: var(--text-muted);
    font-family: Lato;
    padding-left: 8px;
`

type Props = {
    close: () => any
    car: ICar
    entryid?: string
    showroom_entry_undisclosed_mileage?: boolean
}

export type IShowroomForm_TechInfo = {
    make: string | undefined
    model: string | undefined
    year: number | string | undefined
    engine_size: number | string | undefined
    engine_size_unit: string | undefined
    transmission: string | undefined
    drive_side: string | undefined
    mileage: string | number | undefined
    mileage_unit: string | undefined
}

const ShowroomTechInfoFormDesktop = (props: Props) => {
    let { car, showroom_entry_undisclosed_mileage } = props
    let userData: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let appDispatch = useAppDispatch()

    const [hasChange, setHasChange] = useState<boolean>(false)

    let makesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let transmissionsList = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    let techInfoData = useAppSelector((state) => {
        return state.entities.technicalInformationData.technical_information
    })

    const car_years_validation = useAppSelector(
        (state) => state.localdata.form_validation_copy.years
    )

    let carModel: string | null = car.overview.data['model'].answer
    let carMake: string | null = car.overview.data['make'].answer
    let carYear: string | number | null = car.overview.data['year'].answer
    let carDriveSide: string | null = car.overview.data['drive_side'].answer
    let carEngineSize: string | number | null =
        car.overview.data['engine_capacity'].answer

    useEffect(() => {
        if (!transmissionsList) {
            appDispatch(
                dropDownActions.setTransmissionTypesDropdownDataRequest()
            )
        }

        if (!makesDropdowndata) {
            appDispatch(dropDownActions.setMakeDropdownDataRequest())
        }

        if (!modelsDropdowndata && carMake && carMake.length > 1) {
            appDispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: carMake,
                    make_uid: car.overview.data['make'].current_uid,
                })
            )
        }
    }, [])

    let tech_info_id = car.technical_information

    let tech_car_info: ITechInfoData | undefined = techInfoData[tech_info_id]

    let carMileage: string | number | null | undefined =
        tech_car_info && tech_car_info.mileage.answer

    let transmission: string | number | null | undefined =
        tech_car_info && tech_car_info.transmission.answer

    let user_distance_unit_data =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['distance_unit'].data

    let user_distance_unit_ids_list =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['distance_unit'].ids_list

    let distance_units: IList[] | null =
        user_distance_unit_ids_list &&
        user_distance_unit_ids_list.map((id: string, index: number) => {
            return user_distance_unit_data![id]
        })

    let user_distance_unit_chosen_id =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['distance_unit'].user_choice_id

    let user_default_distance_unit: IList | null =
        user_distance_unit_data && user_distance_unit_chosen_id
            ? user_distance_unit_data[user_distance_unit_chosen_id]
            : null

    let user_fuel_unit_ids_list =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].ids_list

    let user_fuel_unit_chosen_id =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].user_choice_id

    let user_fuel_unit_data =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].data

    let user_default_fuel_unit: IList | null =
        user_fuel_unit_data &&
        user_fuel_unit_chosen_id &&
        // @ts-ignore
        user_fuel_unit_data[user_fuel_unit_chosen_id]
            ? // @ts-ignore
              user_fuel_unit_data[user_fuel_unit_chosen_id]
            : null

    let fuel_units: IList[] | null =
        user_fuel_unit_data &&
        user_fuel_unit_ids_list &&
        user_fuel_unit_ids_list.map((id: string, index: number) => {
            // @ts-ignore
            return user_fuel_unit_data[id]
        })

    let init_data = {
        make: carMake && carMake !== '-' ? carMake : undefined,
        model: carModel && carModel !== '-' ? carModel : undefined,
        year: carYear && carYear !== '-' ? carYear : undefined,
        drive_side:
            carDriveSide && carDriveSide !== '-' ? carDriveSide : undefined,
        mileage:
            carMileage !== undefined &&
            carMileage !== null &&
            carMileage !== '-' &&
            user_default_distance_unit
                ? generateFrontEndValueDistanceUnit(
                      user_default_distance_unit.id,
                      +carMileage
                  )
                : undefined,
        engine_size_unit: user_default_fuel_unit?.id,
        engine_size:
            carEngineSize && carEngineSize !== '-' && user_default_fuel_unit
                ? generateFrontEndValueFuelUnit(
                      user_default_fuel_unit.id,
                      +carEngineSize
                  )
                : undefined,
        transmission:
            transmission && transmission !== '-'
                ? `${transmission}`
                : undefined,
        mileage_unit: user_default_distance_unit?.id,
    }

    const [formData, setFormData] = useState<IShowroomForm_TechInfo>({
        make: init_data.make,
        model: init_data.model,
        year: init_data.year,
        drive_side: init_data.drive_side,
        mileage: init_data.mileage,
        engine_size_unit: user_default_fuel_unit?.id,
        engine_size: init_data.engine_size,
        transmission: init_data.transmission,
        mileage_unit: user_default_distance_unit?.id,
    })

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    const validateForm = async (id: string, value?: number) => {
        if (id === 'year') {
            try {
                await CarYearValidation(car_years_validation.car_year).validate(
                    {
                        year: value,
                    }
                )
                setError((prevState) => {
                    return { ...prevState, [id]: undefined }
                })
            } catch (err: any) {
                setError((prevState) => {
                    return { ...prevState, year: err.errors[0] }
                })
            }
        } else {
            try {
                await getRightSchemaAccountData(id).validate({
                    [id]: value,
                })
                setError((prevState) => {
                    return { ...prevState, [id]: undefined }
                })
            } catch (err: any) {
                setError((prevState) => {
                    return { ...prevState, [id]: err.errors[0] }
                })
            }
        }
    }

    const handleChange = (id: string, value: string | undefined) => {
        if (!hasChange) {
            setHasChange(true)
        }

        let newData = {
            ...formData,
            [id]: value,
        }

        if (id === 'make') {
            newData.model = undefined
        }

        setFormData({
            ...newData,
        })

        setError((prevState) => {
            return { ...prevState, [id]: undefined }
        })
        setIsSaveDisabled(false)
    }

    const drive_side_options = [
        {
            name: 'LHD',
        },
        {
            name: 'RHD',
        },
        {
            name: 'Center',
        },
    ]

    const [undisclosedMileage, setUndisclosedMileage] = useState<
        boolean | undefined
    >(showroom_entry_undisclosed_mileage)

    let arr_form_values = [
        'drive_side',
        'mileage',
        'make',
        'model',
        'transmission',
        'engine_size',
        'engine_size_unit',
        'year',
    ]

    const validateOnClick = useCallback(() => {
        let res: boolean = true

        arr_form_values.forEach((id: string) => {
            if (props.entryid) {
                if (
                    // @ts-ignore
                    id === 'mileage' &&
                    (formData[id] === undefined || formData[id] === null) &&
                    !undisclosedMileage
                ) {
                    setError((prevState) => {
                        return {
                            ...prevState,
                            [id]: 'Please enter mileage or hide it',
                        }
                    })
                    res = false // @ts-ignore
                } else if (id !== 'mileage' && !formData[id]) {
                    setError((prevState) => {
                        return { ...prevState, [id]: 'Required' }
                    })
                    res = false
                }
            } else {
                if (
                    id !== 'year' &&
                    id !== 'engine_size' &&
                    id !== 'transmission' &&
                    id !== 'drive_side' &&
                    id !== 'mileage' &&
                    // @ts-ignore
                    !formData[id]
                ) {
                    setError((prevState) => {
                        return { ...prevState, [id]: 'Required' }
                    })

                    res = false
                }
            }
        })

        return res
    }, [formData, undisclosedMileage])

    const onSubmit = () => {
        let p: IwriteMultipleTechnicalInformationPayloadReq = {
            tech_info_id: tech_info_id,
            car_id: car.id,
            data: [],
            getShowroomEntryOnSuccess: props.entryid
                ? {
                      entry_id: props.entryid,
                  }
                : undefined,
        }

        let generateAnswer = (fieldID: string) => {
            if (formData[fieldID as keyof IShowroomForm_TechInfo]) {
                if (fieldID === 'mileage' && formData.mileage_unit) {
                    return generateBackEndValueDistanceUnit(
                        formData.mileage_unit,
                        // @ts-ignore
                        +formData[fieldID as keyof IShowroomForm_TechInfo]
                    )
                } else if (
                    fieldID === 'engine_size' &&
                    formData.engine_size_unit
                ) {
                    return generateBackEndValueFuelUnit(
                        formData.engine_size_unit,
                        // @ts-ignore
                        +formData[fieldID as keyof IShowroomForm_TechInfo]
                    )
                } else if (fieldID === 'year') {
                    // @ts-ignore
                    return +formData[fieldID as keyof IShowroomForm_TechInfo]
                } else {
                    // @ts-ignore
                    return formData[fieldID]
                }
            }
        }

        arr_form_values.forEach((val: string) => {
            // @ts-ignore
            if (formData[val]) {
                // @ts-ignore
                if (!init_data[val]) {
                    let newItem: IList = {
                        id: val,
                        text: val,
                        // @ts-ignore
                        answer: generateAnswer(val),
                    }
                    p.data = [...p.data, newItem]
                }
                // @ts-ignore
                else if (init_data[val] && formData[val] !== init_data[val]) {
                    // specific case where only unit is changed for engine size
                    if (
                        val === 'engine_size_unit' &&
                        formData.engine_size_unit &&
                        formData.engine_size &&
                        formData.engine_size === init_data.engine_size
                    ) {
                        const new_engine_size_item = {
                            id: 'engine_size',
                            text: 'engine_size',
                            answer: generateBackEndValueFuelUnit(
                                formData.engine_size_unit,
                                +formData.engine_size
                            ),
                        }

                        let newItem: IList = {
                            id: val,
                            text: val,
                            // @ts-ignore
                            answer: generateAnswer(val),
                        }
                        p.data = [...p.data, newItem, new_engine_size_item]
                    } else {
                        let newItem: IList = {
                            id: val,
                            text: val,
                            // @ts-ignore
                            answer: generateAnswer(val),
                        }
                        p.data = [...p.data, newItem]
                    }
                }
            }
        })

        if (p.data.length > 0) {
            appDispatch(
                technicalInformationActions.writeMultipleTechnicalInformationRequest(
                    p
                )
            )
        }

        if (undisclosedMileage !== undefined && props.entryid) {
            appDispatch(
                showroomActions.updateEntryShowroomRequest({
                    uid: props.entryid,
                    car_id: props.car.id,
                    data: {
                        undisclosed_mileage: undisclosedMileage,
                    },
                })
            )
        }

        setHasChange(false)

        props.entryid &&
            setTimeout(() => {
                props.entryid &&
                    appDispatch(
                        showroomActions.validateEntryForPublicationRequest(
                            props.entryid
                        )
                    )
            }, 150)

        props.close()
    }

    let isLoadingNewMakeOrModel = useAppSelector((state) => {
        return state.localdata.dropdownData.isLoadingNewMakeOrModel
    })

    const toggleUndisclosedMileage = (v: boolean) => {
        setUndisclosedMileage(v)
        if (!hasChange) {
            setHasChange(true)
        }
        setIsSaveDisabled(false)
        setError((prevState) => {
            return { ...prevState, mileage: undefined }
        })
    }

    const [isSaveDisabled, setIsSaveDisabled] = useState(false)

    const { theme } = useThemes()

    return (
        <ModalContentDesktop style={{ maxWidth: 480 }}>
            <ModalInnerContentWrapperDesktop>
                <Title>Car Specification</Title>

                <div style={{ paddingTop: '30px' }} />

                <SelectSearchBarCarInfoDesktop
                    placeholder={formData.make ? 'Make *' : 'Make'}
                    handleSelectChange={(name: string, uid?: string) => {
                        handleChange('make', name)
                        setIsSaveDisabled(false)
                        appDispatch(
                            dropDownActions.setModelDropdownDataRequest({
                                makeName: name,
                                make_uid: uid,
                            })
                        )
                    }}
                    field_id={'make'}
                    addAnItemFunc={(id: string, name: string) => {
                        if (!hasChange) {
                            setHasChange(true)
                        }
                        setIsSaveDisabled(false)
                        appDispatch(
                            dropDownActions.setIsLoadingNewMakeOrModel(true)
                        )
                        appDispatch(
                            dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                {
                                    dropdownTargetId: id,
                                    name: name,
                                }
                            )
                        )
                    }}
                    item={{
                        answer: formData.make,
                        id: 'make',
                        text: 'make *',
                    }}
                    items={makesDropdowndata ? makesDropdowndata : []}
                    formCurrentValue={formData.make}
                    dropdownTargetId="carMakes"
                    allowAdd={true}
                    onlyShowMatch={true}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />

                <div style={{ paddingTop: '24px' }} />

                <SelectSearchBarCarInfoDesktop
                    handleSelectChange={(name: string, other?: string) => {
                        handleChange('model', name)
                        setIsSaveDisabled(false)
                    }}
                    field_id={'model'}
                    addAnItemFunc={(id: string, name: string) => {
                        if (!hasChange) {
                            setHasChange(true)
                        }
                        setIsSaveDisabled(false)
                        appDispatch(
                            dropDownActions.setIsLoadingNewMakeOrModel(true)
                        )

                        appDispatch(
                            dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                {
                                    dropdownTargetId: id,
                                    name: name,
                                    relevantOtherLinkedDataMakeMake:
                                        formData.make,
                                }
                            )
                        )
                    }}
                    item={{
                        answer: formData.model,
                        id: 'model',
                        text: 'model *',
                    }}
                    placeholder={formData.model ? 'Model *' : 'Model'}
                    dropdownTargetId="carModels"
                    items={modelsDropdowndata ? modelsDropdowndata : []}
                    formCurrentValue={formData.model}
                    allowAdd={true}
                    isDisabled={!formData.make ? true : false}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />

                <div style={{ paddingTop: '24px' }} />

                <InputFieldNoFormikAnimated
                    value={formData.year}
                    name={formData.year ? 'Year *' : 'Year *'}
                    placeholder={'Year'}
                    type={'number'}
                    onChange={(e: any) => {
                        handleChange('year', e.target.value)
                        validateForm('year', +e.target.value)
                    }}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <Expander height={error?.year ? 'auto' : 0} width={'100%'}>
                    <ErrorText>{error?.year}</ErrorText>
                </Expander>

                <div style={{ paddingTop: '24px' }} />

                <RowForm>
                    <InputFieldNoFormikAnimated
                        name={
                            formData.engine_size
                                ? 'Engine size'
                                : 'Engine size *'
                        }
                        placeholder={'Engine size *'}
                        tabindex={1}
                        aria-live="polite"
                        value={
                            formData.engine_size !== undefined &&
                            typeof formData.engine_size === 'number' &&
                            formData.engine_size_unit !== 'cubic_centimeters'
                                ? formData.engine_size.toFixed(1)
                                : formData.engine_size
                        }
                        font_family="Lato"
                        type="number"
                        isnarrow={true}
                        onChange={(e: any) => {
                            handleChange('engine_size', e.target.value)
                            e.target.value === ''
                                ? validateForm('engine_size', undefined)
                                : validateForm('engine_size', +e.target.value)
                        }}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />

                    <AdjustPosition>
                        <Select
                            defaultValue={formData.engine_size_unit}
                            onChange={(val: string | undefined) => {
                                handleChange('engine_size_unit', val)
                            }}
                            placeholder={'Unit'}
                            options={
                                fuel_units
                                    ? fuel_units.map((unit: IList) => {
                                          return {
                                              value: unit?.id,
                                              label: `${unit?.short}`,
                                          }
                                      })
                                    : []
                            }
                            disabled={props.entryid ? true : false}
                            hasInfoWithTooltip
                            tooltipColor={colours[theme].text_strongest}
                            tooltipText={
                                props.entryid
                                    ? 'You can change the engine size unit in your account settings.'
                                    : 'The value displayed on your car page will be converted into your preferred engine size unit. You can change the display unit in your account settings.'
                            }
                            tooltipPlacement="left"
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        />
                    </AdjustPosition>
                </RowForm>
                <Expander
                    height={error?.engine_size ? 'auto' : 0}
                    width={'100%'}
                >
                    <ErrorText>{error?.engine_size}</ErrorText>
                </Expander>

                {!error?.engine_size && (
                    <Expander
                        height={
                            formData.engine_size_unit !== 'liters' &&
                            formData.engine_size
                                ? 'auto'
                                : 0
                        }
                    >
                        <EquivalentConverted>
                            Equal to{' '}
                            {formData.engine_size &&
                                (formData.engine_size_unit === 'cc' ||
                                formData.engine_size_unit ===
                                    'cubic_centimeters'
                                    ? cubicCentimetersToLiters(
                                          +formData.engine_size
                                      ).toFixed(1)
                                    : formData.engine_size_unit === 'ci' ||
                                      formData.engine_size_unit ===
                                          'cubic_inches'
                                    ? cubicInchesToLiters(+formData.engine_size)
                                    : null)}{' '}
                            litres
                        </EquivalentConverted>
                    </Expander>
                )}

                <div style={{ paddingTop: '20px' }} />

                <Select
                    defaultValue={formData.transmission}
                    placeholder={
                        formData.transmission
                            ? 'Transmission *'
                            : 'Transmission *'
                    }
                    onChange={(val: string) => {
                        handleChange('transmission', val)
                    }}
                    options={
                        transmissionsList
                            ? transmissionsList.map((unit: IDropdownItem) => ({
                                  value: unit?.uid,
                                  label: `${unit?.name}`,
                              }))
                            : []
                    }
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <Expander
                    height={error?.transmission ? 'auto' : 0}
                    width={'100%'}
                >
                    <ErrorText>{error?.transmission}</ErrorText>
                </Expander>

                <div style={{ paddingTop: '20px' }} />

                <Select
                    options={drive_side_options.map(
                        (option: { name: string }) => ({
                            value: option.name,
                            label: option.name,
                        })
                    )}
                    placeholder={
                        formData.drive_side ? 'Drive Side' : 'Drive Side *'
                    }
                    defaultValue={formData.drive_side}
                    onChange={(name) => {
                        handleChange('drive_side', name)
                    }}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <Expander
                    height={error?.drive_side ? 'auto' : 0}
                    width={'100%'}
                >
                    <ErrorText>{error?.drive_side}</ErrorText>
                </Expander>

                <div style={{ paddingTop: '24px' }} />
                {props.entryid && (
                    <>
                        <RowForm>
                            <InputFieldNoFormikAnimated
                                name="Mileage *"
                                placeholder={'Mileage'}
                                tabindex={1}
                                aria-live="polite"
                                value={formData.mileage}
                                font_family="Lato"
                                type="number"
                                isnarrow={true}
                                onChange={(e: any) => {
                                    handleChange('mileage', e.target.value)
                                    e.target.value === ''
                                        ? validateForm('mileage', undefined)
                                        : validateForm(
                                              'mileage',
                                              +e.target.value
                                          )
                                }}
                                disabled={undisclosedMileage}
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />

                            <AdjustPosition>
                                <Select
                                    defaultValue={formData.mileage_unit}
                                    onChange={(val: string | undefined) => {
                                        if (!hasChange) {
                                            setHasChange(true)
                                        }
                                        handleChange('mileage_unit', val)
                                    }}
                                    placeholder={'Unit'}
                                    options={
                                        distance_units
                                            ? distance_units.map(
                                                  (unit: IList) => ({
                                                      value: unit.id,
                                                      label: `${unit.name}`,
                                                  })
                                              )
                                            : []
                                    }
                                    disabled={undisclosedMileage}
                                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                                />
                            </AdjustPosition>
                        </RowForm>
                        <Expander
                            height={error?.mileage ? 'auto' : 0}
                            width={'100%'}
                        >
                            <ErrorText>{error?.mileage}</ErrorText>
                        </Expander>
                    </>
                )}
                {!error?.mileage && (
                    <Expander
                        height={
                            formData.mileage_unit !== 'miles' &&
                            formData.mileage
                                ? 'auto'
                                : 0
                        }
                    >
                        <EquivalentConverted>
                            Equal to{' '}
                            {formData.mileage && kmToMiles(+formData.mileage)}{' '}
                            miles
                        </EquivalentConverted>
                    </Expander>
                )}

                {props.entryid && (
                    <div
                        style={{
                            paddingTop: '16px',
                            width: '100%',
                            boxSizing: 'border-box',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <SwitchButton
                            checked={undisclosedMileage ?? false}
                            toggle={toggleUndisclosedMileage}
                            width={34}
                            circleSize={12}
                            height={18}
                            dataCyId={'makeAddressSameAsDealership'}
                            variant="outline"
                            bg={colours[theme].modal_side_sheet_pop_over}
                        />
                        <p
                            style={{
                                paddingLeft: 8,
                                fontFamily: 'Lato',
                                fontSize: 14,
                            }}
                        >
                            Hide mileage
                        </p>
                    </div>
                )}

                <div style={{ paddingTop: '30px' }} />

                <OwnerShowroomFormActions>
                    <ButtonAtom
                        theme="capitalize-white-background"
                        width="74px"
                        height="48px"
                        dataCyId={'showroom-car-share'}
                        customBgColor={colours[theme].modal_side_sheet_pop_over}
                        icon={''}
                        fontSize={16}
                        onClick={() => {
                            props.close()
                            setError(undefined)
                            setFormData({
                                make: init_data.make,
                                model: init_data.model,
                                year: init_data.year,
                                drive_side: init_data.drive_side,
                                mileage: init_data.mileage,
                                engine_size_unit: user_default_fuel_unit?.id,
                                engine_size: init_data.engine_size,
                                transmission: init_data.transmission,
                                mileage_unit: user_default_distance_unit?.id,
                            })
                        }}
                    >
                        <div style={{}}>Close</div>
                    </ButtonAtom>

                    <ButtonAtom
                        theme="secondary"
                        width="80px"
                        height="48px"
                        textTransform="capitalize"
                        dataCyId={'showroom-car-enquire'}
                        onClick={() => {
                            if (
                                !validateOnClick() ||
                                error?.year ||
                                error?.mileage ||
                                error?.engine_size
                            ) {
                                setIsSaveDisabled(true)
                            } else if (!isLoadingNewMakeOrModel) {
                                setError({})
                                hasChange && onSubmit()
                            }
                        }}
                        disabled={
                            isSaveDisabled ||
                            isLoadingNewMakeOrModel ||
                            !hasChange ||
                            error?.year ||
                            error?.mileage ||
                            error?.engine_size
                                ? true
                                : false
                        }
                        fontSize={16}
                    >
                        {isLoadingNewMakeOrModel ? (
                            <div style={{ width: 40 }}>
                                <LoaderElasticThreeDots />
                            </div>
                        ) : (
                            'Save'
                        )}
                    </ButtonAtom>
                </OwnerShowroomFormActions>
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

export default ShowroomTechInfoFormDesktop
