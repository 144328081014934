import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { randomCarSvgPlaceholderFunc } from '../../../../helpers/random/randomPlaceholder'
import { device } from '../../../templates/displays/devices'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { HistoryFilesIcon } from '../../../atoms/icons/components'
import LastUpdateIcon from '../../../atoms/icons/components/lastUpdatedICon'
import { IGalleryImagesObject } from '../../../../redux/entities/galleries/types'
import { ICar } from '../../../../redux/entities/cars/types'
import useThemes from '../../../../providers/theme/hooks'

interface IStyle {
    hasNoEntry?: boolean
}

const ShadowBox = styled(Link)<IStyle>`
    position: relative;
    height: 112px;
    display: flex;
    flex-direction: column;
    align-items: initial;
    justify-content: center;
    font-family: Lato;
    width: 90vw;
    background-color: var(--shadow-cards-bg, #fff);
    box-shadow: 2px 1px 10px 1px var(--box-shadow, rgba(0, 0, 0, 0.08));

    @media ${device.mobile} {
        border-radius: 6px;
    }

    @media ${device.ipad} {
        border-radius: 6px;
    }
`

const CarTitle = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: var(--text-strong, #1a1a1a);
    text-transform: uppercase;
    max-width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font: Lato;

    @media (max-width: 350px) {
        font-size: 12px;
    }
`

const Col = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 164px;
    padding-right: 24px;
    @media (max-width: 350px) {
        padding-left: 136px;
        padding-right: 16px;
    }
`

const Row = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`
type IImageStyle = {
    isthingloading: boolean
}

const CarImgWrapper = styled.div`
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 140px;
    max-width: 140px;
    height: 100%;
    object-fit: cover;

    @media (max-width: 350px) {
        min-width: 120px;
        max-width: 120px;
    }
`

const CarProfileImage = styled.img<IImageStyle>`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
`

const EntriesCount = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: var(--text-default, #666666);
    font-family: Lato;

    @media (max-width: 350px) {
        font-size: 11px;
        line-height: 14px;
    }
`
const LastUpdated = styled.div`
    color: var(--text-default, #666666);
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;

    @media (max-width: 350px) {
        font-size: 11px;
        line-height: 14px;
    }
`

interface Props {
    car: ICar
    carIndex: number
    garageId?: string
    imagesData: IGalleryImagesObject | null
}

const GeneralTimelineCardMobile: React.FC<Props> = ({
    car,
    carIndex,
    imagesData,
}) => {
    const navigate = useNavigate()
    const location = useLocation()

    let { theme } = useThemes()

    const get_car_cover_url = (carObj: ICar) => {
        if (carObj && carObj.gallery) {
            let imageid =
                carObj.gallery && carObj.gallery.cover
                    ? carObj.gallery.cover
                    : undefined

            if (imageid && imagesData) {
                if (imagesData[imageid] && imagesData[imageid].medium_srcset) {
                    return {
                        srcset: imagesData[imageid].medium_srcset,
                        url: imagesData[imageid].url,
                    }
                } else if (imagesData[imageid] && imagesData[imageid].url) {
                    return {
                        url: imagesData[imageid].url,
                    }
                }
            }
        }

        return {
            url: undefined,
        }
    }

    let imageid =
        car.gallery && car.gallery.cover ? car.gallery.cover : undefined

    const [isLoading, setIsLoading] = React.useState(true)
    const [imgSrc, setImgSrc] = useState<string | undefined>(undefined)

    useEffect(() => {
        const cover_img_src = get_car_cover_url(car)
        const imageSrc =
            cover_img_src.url || randomCarSvgPlaceholderFunc(undefined, theme)
        if (imageSrc) {
            setImgSrc(imageSrc)
        }
    }, [car, theme])

    return (
        <>
            {car ? (
                <ShadowBox
                    to={{
                        pathname: `/car/${car.id}/history-file`,
                    }}
                    onClick={(e) => {
                        e.preventDefault()
                        navigate(`/car/${car.id}/history-file`, {
                            state: {
                                prevPath: location.pathname,
                            },
                        })
                    }}
                    hasNoEntry={
                        !car.car_entries_count || car.car_entries_count === 0
                    }
                >
                    <Row>
                        <CarImgWrapper>
                            <SkeletonAbsolute isthingloading={isLoading} />
                            <CarProfileImage
                                src={imgSrc}
                                onLoad={() => setIsLoading(false)}
                                isthingloading={isLoading}
                                srcSet={
                                    imageid &&
                                    imagesData &&
                                    imagesData[imageid] &&
                                    imagesData[imageid].small_srcset
                                        ? imagesData[imageid].small_srcset
                                        : undefined
                                }
                                aria-label="car profile image"
                            />
                        </CarImgWrapper>
                        <Col>
                            <CarTitle>
                                {car.title && car.title.length > 42
                                    ? `${car.title.slice(0, 42)}...`
                                    : car.title.trim() !== ''
                                    ? car.title
                                    : `Untitled #${carIndex + 1}`}
                            </CarTitle>
                            <div style={{ paddingTop: 8 }} />

                            <Row>
                                <HistoryFilesIcon
                                    size="18"
                                    color={'var(--text-default, #666666)'}
                                />
                                <EntriesCount>
                                    {car.car_entries_count
                                        ? car.car_entries_count
                                        : 0}{' '}
                                    {car.car_entries_count &&
                                    car.car_entries_count > 1
                                        ? 'Entries'
                                        : 'Entry'}
                                </EntriesCount>
                            </Row>
                            <div style={{ paddingTop: 4 }} />

                            <Row>
                                <LastUpdateIcon />
                                <LastUpdated>
                                    Updated -{' '}
                                    {!car.car_entries_count
                                        ? 'NA'
                                        : car.car_entries_count === 0
                                        ? 'NA'
                                        : car.last_entry_updated_at_date
                                        ? car.last_entry_updated_at_date
                                        : 'NA'}
                                </LastUpdated>
                            </Row>
                        </Col>
                    </Row>
                </ShadowBox>
            ) : (
                <div />
            )}
        </>
    )
}

export default GeneralTimelineCardMobile
