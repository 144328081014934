import styled from 'styled-components'
import UserPlainIcon from '../../../atoms/icons/components/userPlainIcon'
import { CarIcon } from '../../../atoms/icons/components'
import DocumentIcon from '../../../atoms/icons/components/document'
import DriverLicenceIcon from '../../../atoms/icons/components/driverLicenceIcon'
import DrivingObstacle from '../../../atoms/icons/components/drivingObstacleIcon'
import JailLock from '../../../atoms/icons/components/jailLockIcon'
import KeyIcon from '../../../atoms/icons/components/keyIcon'
import PoundIcon from '../../../atoms/icons/components/poundIcon'
import ParkingIcon from '../../../atoms/icons/components/parkingIcon'
import PoleIcon from '../../../atoms/icons/components/poleIcon'
import ClockIconThiner from '../../../atoms/icons/components/clockIconThin'

const Card = styled.div`
    display: flex;
    padding: 0px 32px 32px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    break-inside: avoid;

    @media (max-width: 744px) {
        padding: 0px 10px 20px 0px;
        gap: 3px;
    }
`

const CardTopSection = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
`

const Title = styled.p`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;

    @media (max-width: 744px) {
        font-size: 15px;
    }
`

const Text = styled.p`
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

type Props = {
    id: string
    title: string
    text: string
    is_mobile?: boolean
}

const InsuranceEligibilityCardV2 = (props: Props) => {
    let { id, text, title, is_mobile } = props

    const generateIcon = (card_id: string) => {
        switch (card_id) {
            case 'driver_age':
                return (
                    <div style={{ transform: 'translateY(2px)' }}>
                        <UserPlainIcon size={is_mobile ? '15' : '19'} />
                    </div>
                )
            case 'licence_length':
                return <DriverLicenceIcon size={is_mobile ? '19' : undefined} />
            case 'all_claims':
                return <DocumentIcon />
            case 'all_motoring_convictions':
                return <DrivingObstacle />
            case 'criminal_convictions':
                return <JailLock size={is_mobile ? '19' : undefined} />
            case 'everyday_car_specialist':
                return <CarIcon size={is_mobile ? '19' : undefined} />
            case 'non_everyday_car_specialist':
                return <CarIcon size={is_mobile ? '19' : undefined} />
            case 'ownership_experience':
                return <PoleIcon size={is_mobile ? '19' : undefined} />
            case 'parking':
                return <ParkingIcon size={is_mobile ? '19' : undefined} />
            case 'vehicle_use':
                return <KeyIcon size={is_mobile ? '17' : '21'} />
            case 'payment_type':
                return <PoundIcon size={is_mobile ? '17' : undefined} />
            case 'processing_time':
                return <ClockIconThiner size={is_mobile ? '19' : undefined} />
            default:
                return null
        }
    }

    return (
        <Card>
            <CardTopSection>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {generateIcon(id)}
                </div>{' '}
                <Title>{title}</Title>
            </CardTopSection>
            <Text>{text}</Text>
        </Card>
    )
}

export default InsuranceEligibilityCardV2
