import styled from 'styled-components'
import { Sheet } from 'react-modal-sheet'
import { device } from '../../templates/displays/devices'
import PublishChecksManagerMobile from '../../organisms/showroom/publishChecksManager/publishChecksManagerMobile'
import { IPublishValidationObject } from '../../templates/bars/showroomBars/bottomBars/carShowroomEditBottomBar'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { ICar } from '../../../redux/entities/cars/types'

const WrapperMobile = styled.div`
    @media (min-width: 880px) {
        display: none !important;
    }
`

const CustomSheet = styled(Sheet)<{ $customHeight?: string }>`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;

        @media (min-width: 880px) {
            display: none !important;
        }
    }
    .react-modal-sheet-container {
        min-height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '230px !important'};
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
        max-height: 90vh !important;
        overflow-y: scroll !important;

        @media (min-width: 880px) {
            display: none !important;
        }
    }
    .react-modal-sheet-header {
        height: 10px !important;

        @media (min-width: 880px) {
            display: none !important;
        }
    }
    .react-modal-sheet-drag-indicator {
        width: 0px !important;

        @media (min-width: 880px) {
            display: none !important;
        }
    }
    .react-modal-sheet-content {
        /* custom styles */

        @media (min-width: 880px) {
            display: none !important;
        }
    }
`

interface IProps {
    isOpen: boolean
    publishChecksObject: IPublishValidationObject
    currentCar: ICar
    currentCarId: string | undefined
    toggle: any
}

const ShowroomPublishChecksBottomSheetMobile = (props: IProps) => {
    let { currentCar, currentCarId, publishChecksObject, toggle } = props

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false
    return (
        <WrapperMobile>
            {isMobile ? (
                <CustomSheet
                    isOpen={props.isOpen}
                    onClose={toggle}
                    $customHeight={'max-content'}
                >
                    <Sheet.Container>
                        <PublishChecksManagerMobile
                            publishChecksObject={publishChecksObject}
                            currentCar={currentCar}
                            currentCarId={currentCarId}
                            close={toggle}
                        />
                    </Sheet.Container>
                    <Sheet.Backdrop
                        data-attr="showroom-validation-backdrop-overlay"
                        onTap={toggle}
                    />
                </CustomSheet>
            ) : null}
        </WrapperMobile>
    )
}

export default ShowroomPublishChecksBottomSheetMobile
