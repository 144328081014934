import { useEffect, useRef } from 'react'

export const useClickAway = (onClickAway: () => void) => {
    const targetRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handler = (event: MouseEvent) => {
            if (!targetRef.current?.contains(event.target as Node)) {
                onClickAway()
            }
        }

        document.addEventListener('mousedown', handler)

        return () => document.removeEventListener('mousedown', handler)
    })

    return targetRef
}
