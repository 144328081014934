import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
import { api } from '../../services'
import posthog from 'posthog-js'
import { searchUsersActions } from '../../searchUsers/reducer'
import { usersActions } from '../reducer'
import { carActions } from '../../entities/cars/reducer'
import { garagesActions } from '../../entities/garages/reducer'
import { timelineActions } from '../../timeline/reducer'
import { tasksActions } from '../../tasks/reducer'
import { technicalInformationActions } from '../../entities/technical_information/reducer'
import { highlightedFactsActions } from '../../entities/highlighted_facts/reducer'
import { galleriesActions } from '../../entities/galleries/reducer'
import { attachmentActions } from '../../attachments/reducer'
import { IAccDelItems } from '../../services/user_data/types'
import { IReduxError } from '../../entities/cars/types'
import { push } from 'redux-first-history'

function* deleteAccountSaga(payload: IAccDelItems): any {
    try {
        // delete account
        yield api.accountDeletion.deleteAccount()

        // record deletion reasons in Xata

        let now = new Date()
        let created_at = now.toISOString()
        let xataPayload = { ...payload, created_at: created_at }

        yield api.accountDeletion.recordAccountDeletionFeedback(xataPayload)

        // clean up Redux of all cars and garages data
        yield put(carActions.cleanUpCarDataOnLogoutSuccess())
        yield put(garagesActions.cleanUpGaragesDataOnLogoutSuccess())
        yield put(timelineActions.cleanUpTimelineDataOnLogoutSuccess())

        yield put(tasksActions.cleanUpTasksSuccess())
        yield put(
            technicalInformationActions.cleanUpTechnicalInformationSuccess()
        )
        yield put(highlightedFactsActions.cleanUpHighlightedFactsSuccess())
        yield put(galleriesActions.cleanUpGallerySuccess())
        yield put(searchUsersActions.cleanUpSearchUsersSuccess())
        yield put(attachmentActions.cleanUpAttachmentsSuccess())

        localStorage?.removeItem('refresh_token')
        localStorage?.removeItem('user_id')

        yield put(usersActions.deleteAccountSuccess())
        posthog.capture('USER ACCOUNT DELETED')

        yield put(push(`/account-deletion-confirmed`))

        return
    } catch (error: any) {
        if (error.status === 401) {
            let pa: IUnauthHandlerPayload = {
                functionToRepeat: deleteAccountSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, pa)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(usersActions.deleteAccountError(typedError))
        }
    }
}

function* watcherDeleteAccount() {
    while (true) {
        const { payload } = yield take(usersActions.deleteAccountRequest.type)
        yield call(deleteAccountSaga, payload)
    }
}

const delete_account: any[] = [fork(watcherDeleteAccount)]

export default delete_account
