import styled from 'styled-components'
import InfoIcon from '../icons/infoIcon'
import { device } from '../../templates/displays/devices'
import { useState } from 'react'
import Expander from '../expander/expander'
import Icon from '../icons'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyleProps = {
    $variant?: 'default' | 'info' | 'error'
    $theme: ITheme
}

const Wrap = styled.div<IStyleProps>`
    background-color: ${(props) =>
        props.$variant === 'error'
            ? colours[props.$theme].error_underlay
            : colours[props.$theme].background_neutral_subtle};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 40px;
    padding-right: 20px;
    border-bottom: 1px solid ${(props) => colours[props.$theme].border_muted};
    @media ${device.mobile_and_ipad} {
        padding-left: 24px;
    }
`

const Row = styled.div<IStyleProps>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

const IconWrapper = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Text = styled.div<IStyleProps>`
    color: ${(props) =>
        props.$variant === 'error'
            ? (props) => colours[props.$theme].error
            : (props) => colours[props.$theme].text_default};
    font-size: 14px;
    font-family: 'Lato';
    padding-top: 2px;
    @media ${device.mobile_and_ipad} {
        color: ${(props) => colours[props.$theme].text_default};
        /* Desktop/Text/T200 Regular */
        font-family: Lato;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.001px;
    }
`

const Close = styled.button<IStyleProps>`
    all: unset;
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding-top: 2px;

    @media ${device.mobile_and_ipad} {
        height: 24px;
        width: 24px;
    }

    :hover {
        background-color: ${(props) => colours[props.$theme].primary_01};
    }
`
type Props = {
    text?: string
    variant?: 'default' | 'info' | 'error'
    isMobile?: boolean
    noInfoIcon?: boolean
    onClose?: () => void
    customBg?: string
    children?: React.ReactNode
}

const FullWidthInfoBox = (props: Props) => {
    const [isClosed, setIsClosed] = useState(false)
    const { theme } = useThemes()
    return (
        <Expander height={isClosed ? 0 : 'auto'} width="100%">
            <Wrap
                $theme={theme}
                $variant={props.variant}
                style={
                    props.customBg ? { backgroundColor: props.customBg } : {}
                }
            >
                <Row $theme={theme}>
                    {props.noInfoIcon ? null : (
                        <IconWrapper>
                            <InfoIcon
                                size="20"
                                color={
                                    props.variant === 'error'
                                        ? colours[theme].error
                                        : props.variant === 'info'
                                        ? colours[theme].primary
                                        : undefined
                                }
                            />
                        </IconWrapper>
                    )}

                    {props.noInfoIcon ? null : (
                        <div style={{ paddingLeft: 8 }} />
                    )}

                    {props.children ?? <Text $theme={theme}>{props.text}</Text>}
                </Row>
                <Close
                    $theme={theme}
                    onClick={() => {
                        setIsClosed(true)
                    }}
                >
                    <Icon icon="grey_cross" width="10px" />
                </Close>
            </Wrap>
        </Expander>
    )
}

export default FullWidthInfoBox
